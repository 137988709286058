const eventTypesToHandle = [
	'TASK',
	'SUB_TASK',
	'TIME_REG',
	'PHASE',
	'PROJECT_PERSON',
	'PERSON',
	'PROJECT',
	'IDLE_TIME',
	'ALLOCATION',
];

export const handleNotification = (pageComponent, eventList) => {
	const projectsToUpdate = {};
	const allocationsToUpdate = {};

	eventList.forEach(event => {
		if (eventTypesToHandle.indexOf(event.eventType) > -1) {
			if (event.projectIds) {
				event.projectIds.forEach(projectId => (projectsToUpdate[projectId] = true));
			} else if (event.allocationIds) {
				event.allocationIds.forEach(allocationId => (allocationsToUpdate[allocationId] = true));
			}
		}
	});

	const projectIdsToUpdate = Object.keys(projectsToUpdate);
	const allocationIdsToUpdate = Object.keys(allocationsToUpdate);

	if (projectIdsToUpdate.length === 0 && allocationIdsToUpdate.length === 0) {
		return;
	}

	// Wait a tiny bit before fetching to ensure everything is updated on the backend
	// setTimeout(() => fetchAndProcessData(pageComponent, projectIdsToUpdate, allocationIdsToUpdate), 500);
};
