import {getAllGroupsOfType, GROUP_TYPE} from '../canvas-timeline/canvas_timeline_util';
import {getPersonIdFromPersonGroup, getPlaceholderIdFromGroup, getProjectIdFromGroup} from './CanvasCapacityOverviewDataUtil';
import {filterGroupsItems, filterGroupsItemsCached, isFilteredByIdleTimes, isProjectFilteredCached} from '../FilterUtils';
import {recalculateGroupHeatmapCache} from '../heatmap/HeatmapLogic';
import {trackPersonHeatmapTotalsPerformance} from '../canvas-timeline/canvas_timeline_performance_track';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {ROLE_GROUPING_SUB_GROUP_TYPE} from '../IDManager';

const recalculateGroupCaches = (pageComponent, groups) => {
	groups.forEach(group => {
		recalculateGroupHeatmapCache(pageComponent, group.id);
	});
};

const isResourceFiltered = (pageComponent, resourceGroup) => {
	const {filterFunctions, data} = pageComponent.state;
	const {personMap, placeholderMap, roleMap, projectMap, projectGroupMap} = data;

	const isPlaceholder = resourceGroup.groupType === GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP;

	const resource = isPlaceholder
		? placeholderMap.get(getPlaceholderIdFromGroup(resourceGroup))
		: personMap.get(getPersonIdFromPersonGroup(resourceGroup));

	// ROLE
	const role = resource.roleId ? roleMap.get(resource.roleId) : undefined;

	// SKILLS
	const resourceSkills = isPlaceholder
		? data.placeholderSkillsByPlaceholder[resource.id]
		: data.personSkillsByPersonMap[resource.id];
	const resourceSkillsFilters = (resourceSkills || []).map(resourceSkill => ({
		skill: {skillId: resourceSkill.skillId, skillLevelId: resourceSkill.skillLevelId || null},
	}));

	// TEAMS
	const resourceTeams = isPlaceholder
		? data.placeholderTeamsByPlaceholder[resource.id]
		: data.teamPersonByPersonMap[resource.id];
	const teamIds = (resourceTeams || []).map(resourceTeam => resourceTeam.teamId);

	const filterData = {
		role,
		personSkills: resourceSkillsFilters,
		departmentId: resource.departmentId,
		teamIds,
	};

	// PROJECT
	if (isPlaceholder) {
		filterData.project = resource.projectId
			? projectMap.get(resource.projectId)
			: projectGroupMap.get(resource.projectGroupId);
	} else {
		// PROJECTS
		const projectPeriodsForPerson = data.projectPersonByPersonMap[resource.id] || [];
		filterData.projects = projectPeriodsForPerson.map(entry => ({id: entry.projectId}));
	}

	return !filterFunctions.resourceFilter(filterData);
};

export const filterRoleGroupingGroups = pageComponent => {
	const {filterFunctions, groups, items} = pageComponent.state;

	if (filterFunctions) {
		const recalculateForResourceGroups = [];
		const projectFilteredCache = new Map();
		let hasFiltered = false;
		const isItemFiltered = item => isFilteredByIdleTimes(pageComponent, item);

		// get only parent groups
		const roleGroupingGroups = getAllGroupsOfType(groups, GROUP_TYPE.PERSON_GROUPING_GROUP);

		roleGroupingGroups.forEach(roleGroupingGroup => {
			const placeholderGroupingGroup = roleGroupingGroup.groups.find(
				group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND
			);
			const personGroupingGroup = roleGroupingGroup.groups.find(
				group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING
			);

			const placeholderGroups = placeholderGroupingGroup?.groups || [];
			const personGroups = personGroupingGroup?.groups || [];
			const resourcesGroups = [...personGroups, ...placeholderGroups];

			let hasFilteredProjectGroup = false;
			resourcesGroups.forEach(resourceGroup => {
				const isPersonGroup = resourceGroup.groupType === GROUP_TYPE.PERSON;
				const isPlaceholderGroup = resourceGroup.groupType === GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP;

				const isProjectGroup = group => group.groupType === GROUP_TYPE.PROJECT;
				const isNonProjectGroup = group => group.groupType === GROUP_TYPE.NON_PROJECT_TIME;

				if ((isPersonGroup || isPlaceholderGroup) && resourceGroup.data.id) {
					const subGroups = resourceGroup.groups.filter(group => isProjectGroup(group) || isNonProjectGroup(group));

					const resourceGroupFiltered = isResourceFiltered(pageComponent, resourceGroup);
					subGroups.forEach(subGroup => {
						if (isProjectGroup(subGroup)) {
							const projectOrProjectGroupId = getProjectIdFromGroup(subGroup);
							const isProjectGroupFiltered =
								resourceGroupFiltered ||
								isProjectFilteredCached(pageComponent, projectFilteredCache, projectOrProjectGroupId);

							if (isProjectGroupFiltered !== subGroup.filtered) {
								hasFiltered = true;
								hasFilteredProjectGroup = true;
							}

							subGroup.filtered = isProjectGroupFiltered;
						} else {
							subGroup.filtered = resourceGroupFiltered;
						}
					});

					filterGroupsItems(pageComponent, items, subGroups, isItemFiltered);

					const hasUnfilteredSubGroup = subGroups.some(group => !group.filtered);
					const isResourceGroupFiltered =
						resourceGroupFiltered || isPlaceholderGroup ? !hasUnfilteredSubGroup : false;

					const shouldUpdateResourceGroup = isResourceGroupFiltered !== resourceGroup.filtered;
					if (shouldUpdateResourceGroup) {
						hasFiltered = true;
						hasFilteredProjectGroup = true;
					}

					resourceGroup.filtered = isResourceGroupFiltered;

					if (shouldUpdateResourceGroup || hasFilteredProjectGroup) {
						recalculateForResourceGroups.push(resourceGroup);
					}
				}
			});

			roleGroupingGroup.filtered = !resourcesGroups.some(group => !group.filtered);
		});

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			if (hasFiltered && recalculateForResourceGroups?.length > 0) {
				const graphGroup = groups.find(group => group.groupType === GROUP_TYPE.TIMELINE_GRAPH_GROUP);
				if (graphGroup) {
					recalculateForResourceGroups.push(graphGroup);
				}

				recalculateGroupCaches(pageComponent, recalculateForResourceGroups);
				trackPersonHeatmapTotalsPerformance();
			}
		}
	}
};

export const filterRoleGroupingGroupsCached = pageComponent => {
	const {filterFunctions, groups, items} = pageComponent.state;

	if (filterFunctions) {
		const recalculateForResourceGroups = [];

		const projectFilteredCache = new Map();
		const subGroupFilteredCache = new Map();

		let hasFiltered = false;

		// get only parent groups
		const roleGroupingGroups = getAllGroupsOfType(groups, GROUP_TYPE.PERSON_GROUPING_GROUP);
		for (let i = 0; i < roleGroupingGroups.length; i++) {
			const roleGroupingGroup = roleGroupingGroups[i];

			const placeholderGroupingGroup = roleGroupingGroup.groups.find(
				group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND
			);
			const personGroupingGroup = roleGroupingGroup.groups.find(
				group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING
			);

			const placeholderGroups = placeholderGroupingGroup?.groups || [];
			const personGroups = personGroupingGroup?.groups || [];
			const resourcesGroups = [...personGroups, ...placeholderGroups];

			let hasFilteredProjectGroup = false;
			for (let j = 0; j < resourcesGroups.length; j++) {
				const resourceGroup = resourcesGroups[j];

				const isPersonGroup = resourceGroup.groupType === GROUP_TYPE.PERSON;
				const isPlaceholderGroup = resourceGroup.groupType === GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP;

				const isProjectGroup = group => group.groupType === GROUP_TYPE.PROJECT;
				const isNonProjectGroup = group => group.groupType === GROUP_TYPE.NON_PROJECT_TIME;

				if ((isPersonGroup || isPlaceholderGroup) && resourceGroup.data.id) {
					const subGroups = resourceGroup.groups.filter(group => isProjectGroup(group) || isNonProjectGroup(group));

					const resourceGroupFiltered = isResourceFiltered(pageComponent, resourceGroup);
					for (let k = 0; k < subGroups.length; k++) {
						const subGroup = subGroups[k];

						if (isProjectGroup(subGroup)) {
							const projectOrProjectGroupId = getProjectIdFromGroup(subGroup);
							const isProjectGroupFiltered =
								resourceGroupFiltered ||
								isProjectFilteredCached(pageComponent, projectFilteredCache, projectOrProjectGroupId);

							if (isProjectGroupFiltered !== subGroup.filtered) {
								hasFiltered = true;
								hasFilteredProjectGroup = true;
							}

							subGroup.filtered = isProjectGroupFiltered;
						} else {
							subGroup.filtered = resourceGroupFiltered;
						}

						subGroupFilteredCache.set(subGroup.id, subGroup.filtered);
					}

					const hasUnfilteredSubGroup = subGroups.some(group => !group.filtered);
					const isResourceGroupFiltered =
						resourceGroupFiltered || isPlaceholderGroup ? !hasUnfilteredSubGroup : false;

					const shouldUpdateResourceGroup = isResourceGroupFiltered !== resourceGroup.filtered;
					if (shouldUpdateResourceGroup) {
						hasFiltered = true;
						hasFilteredProjectGroup = true;
					}

					resourceGroup.filtered = isResourceGroupFiltered;

					if (shouldUpdateResourceGroup || hasFilteredProjectGroup) {
						recalculateForResourceGroups.push(resourceGroup);
					}
				}
			}

			roleGroupingGroup.filtered = !resourcesGroups.some(group => !group.filtered);
		}

		const isItemFiltered = item => isFilteredByIdleTimes(pageComponent, item);
		filterGroupsItemsCached(pageComponent, items, subGroupFilteredCache, isItemFiltered);

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			if (hasFiltered && recalculateForResourceGroups?.length > 0) {
				const graphGroup = groups.find(group => group.groupType === GROUP_TYPE.TIMELINE_GRAPH_GROUP);
				if (graphGroup) {
					recalculateForResourceGroups.push(graphGroup);
				}

				recalculateGroupCaches(pageComponent, recalculateForResourceGroups);
				trackPersonHeatmapTotalsPerformance();
			}
		}
	}
};

export const filterGroupsAndItems = pageComponent => {
	const useNewFiltering = hasFeatureFlag('improving_heatmap_frontend_performance');

	if (useNewFiltering) {
		filterRoleGroupingGroupsCached(pageComponent);
	} else {
		filterRoleGroupingGroups(pageComponent);
	}
};
