import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import TdLink from '../td_link';
import Util from '../../forecast-app/shared/util/util';
import util from '../../forecast-app/shared/util/util';
import {Icon} from '@forecast-it/design-system';

class OverviewProjectsGroupLineItem extends Component {
	getLinkPath() {
		let lastViewedPage = localStorage.getItem('project-group-section-last-viewed') || 'workflow';
		return '/connected/X-' + this.props.groupCompanyId + '/' + lastViewedPage;
	}
	render() {
		const {projectGroup, useFinancialService} = this.props;
		const {formatMessage, formatNumber} = this.props.intl;
		const {isFinancialDataReady, isCalculatedDataReady} = projectGroup;
		const path = this.getLinkPath();
		const hasFinancialAccess = this.props.hasFinancialAccess;
		const completion =
			projectGroup.node.completion !== null ? Math.round(projectGroup.node.completion * 100.0) / 100.0 : null;
		const useWhiteCompletedIndicator =
			completion !== null && completion === 100 && Util.calculateColorsDistance('#05e298', projectGroup.node.color) < 150;
		const textColor = Util.getTextColorV2(projectGroup.node.color);
		const rgbProjectColor = Util.hexToRgb(projectGroup.node.color);
		rgbProjectColor.a = 0.5;
		const remainingColor = `rgba(${rgbProjectColor.r}, ${rgbProjectColor.g}, ${rgbProjectColor.b}, ${rgbProjectColor.a})`;
		const completedColorRgba = rgbProjectColor;
		completedColorRgba.a = 1;
		const completedColor = `rgba(${completedColorRgba.r}, ${completedColorRgba.g}, ${completedColorRgba.b}, ${completedColorRgba.a})`;

		if (useFinancialService) {
			projectGroup.node.forecastMoney = projectGroup.node.billablePlannedTimeAndExpenses;
			projectGroup.node.reportedMoney = projectGroup.node.actualRevenue;
			projectGroup.node.remainingMoney = projectGroup.node.remainingRevenue;
			projectGroup.node.projectedMoney = projectGroup.node.forecastRevenue;
			projectGroup.node.forecastProfit = projectGroup.node.plannedProfit;
			projectGroup.node.projectedProfit = projectGroup.node.forecastProfit;
			projectGroup.node.forecastCost = projectGroup.node.plannedCost;
			projectGroup.node.currentCost = projectGroup.node.actualCost;
			projectGroup.node.projectedCost = projectGroup.node.forecastCost;
		}

		return (
			<tr
				className="project-group-table-row"
				style={{color: textColor, backgroundColor: completedColor}}
				ref={el => (this['table_row_' + projectGroup.node.id] = el)}
			>
				{this.props.columns.map((col, index) => {
					if (col.checked || col.name === 'id') {
						if (col.name === 'id') {
							return (
								<TdLink
									key={index}
									style={{backgroundColor: remainingColor}}
									path={path}
									content={
										col.checked ? (
											<div style={{display: 'flex', gap: '6px', alignItems: 'center'}}>
												<Icon
													color={textColor === '#ffffff' ? 'white' : ''}
													icon={'connectedProject'}
													size="s"
												/>
												X{projectGroup.node.companyProjectGroupId}
											</div>
										) : null
									}
									className="id-link"
								/>
							);
						} else if (col.name === 'name') {
							return (
								<TdLink
									key={index}
									style={{backgroundColor: remainingColor}}
									path={path}
									content={projectGroup.node.name}
									width={this.props.projectNameColumnWidth}
								/>
							);
						} else if (col.name === 'client') {
							return <TdLink key={index} style={{backgroundColor: remainingColor}} path={path} content={''} />;
						} else if (col.name === 'contact') {
							return <TdLink key={index} style={{backgroundColor: remainingColor}} path={path} content={''} />;
						} else if (col.name === 'completion') {
							return (
								<TdLink
									key={index}
									path={path}
									className="center-text"
									content={
										isCalculatedDataReady ? (
											completion !== null ? (
												completion === 100 ? (
													<div
														className={
															'completed-indicator' + (useWhiteCompletedIndicator ? ' white' : '')
														}
													/>
												) : (
													formatMessage({id: 'common.x_percentage'}, {percentage: completion})
												)
											) : (
												formatMessage({id: 'common.not_available'})
											)
										) : (
											'-'
										)
									}
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'priorityLevel') {
							return (
								<TdLink
									key={index}
									path={path}
									content={''}
									className="text"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'forecast') {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isCalculatedDataReady
											? projectGroup.node.isHourEstimated
												? Util.convertMinutesToFullHour(projectGroup.node.forecast, this.props.intl)
												: formatMessage({id: 'common.x_points'}, {points: projectGroup.node.forecast})
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'reported') {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									style={{backgroundColor: remainingColor}}
									content={
										isCalculatedDataReady
											? Util.convertMinutesToFullHour(projectGroup.node.reported, this.props.intl)
											: '-'
									}
								/>
							);
						} else if (col.name === 'remaining') {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isCalculatedDataReady
											? projectGroup.node.isHourEstimated
												? Util.convertMinutesToFullHour(projectGroup.node.remaining, this.props.intl)
												: formatMessage({id: 'common.x_points'}, {points: projectGroup.node.remaining})
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'startDate') {
							return (
								<TdLink
									key={index}
									path={path}
									style={{backgroundColor: remainingColor}}
									content={
										projectGroup.node.startDate !== null ? (
											<span>
												<span className="month-day">
													{projectGroup.node.startDate.format('DD MMM')}
												</span>
												<span className="year">{projectGroup.node.startDate.format('YYYY')}</span>
											</span>
										) : null
									}
									width={'110px'}
								/>
							);
						} else if (col.name === 'endDate') {
							return (
								<TdLink
									key={index}
									path={path}
									style={{backgroundColor: remainingColor}}
									content={
										projectGroup.node.endDate !== null ? (
											<span>
												<span className="month-day">{projectGroup.node.endDate.format('DD MMM')}</span>
												<span className="year">{projectGroup.node.endDate.format('YYYY')}</span>
											</span>
										) : null
									}
									width={'110px'}
								/>
							);
						} else if (col.name === 'budget' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										projectGroup.node.budget
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(Math.round(projectGroup.node.budget * 100) / 100)
											  )
											: null
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'win-chance' && hasFinancialAccess) {
							return (
								<TdLink
									minWidth={'110px'}
									key={index}
									path={path}
									content={'-'}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'forecast-money' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(Math.round(projectGroup.node.forecastMoney * 100) / 100, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'reported-money' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(Math.round(projectGroup.node.reportedMoney * 100) / 100, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'remaining-money' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(Math.round(projectGroup.node.remainingMoney * 100) / 100, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'projected-money' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(Math.round(projectGroup.node.projectedMoney * 100) / 100, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'recognition-locked-revenue' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(projectGroup.node.recognitionLockedRevenue, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'recognition-remaining-revenue' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(projectGroup.node.recognitionOpenRevenue, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'recognition-forecast-revenue' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(projectGroup.node.recognitionForecastRevenue, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'forecast-profit' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(Math.round(projectGroup.node.forecastProfit * 100) / 100, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'projected-profit' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(Math.round(projectGroup.node.projectedProfit * 100) / 100, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'cost' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(Math.round(projectGroup.node.forecastCost * 100) / 100, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'reported-cost' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(projectGroup.node.currentCost, {format: 'always_two_decimal'})
											  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'remaining-cost' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(projectGroup.node.remainingCost, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'projected-cost' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isFinancialDataReady
											? Util.getFormattedCurrencyValue(
													this.props.currencySymbol,
													formatNumber(projectGroup.node.projectedCost, {
														format: 'always_two_decimal',
													})
											  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'xero-amount-total' && hasFinancialAccess && this.props.hasXeroEnabled) {
							return (
								<TdLink
									key={index}
									style={{backgroundColor: remainingColor}}
									path={path}
									content={formatNumber(projectGroup.node.xeroAmountTotal, {format: 'always_two_decimal'})}
									className="numeric"
								/>
							);
						} else if (col.name === 'xero-paid' && hasFinancialAccess && this.props.hasXeroEnabled) {
							return (
								<TdLink
									key={index}
									style={{backgroundColor: remainingColor}}
									path={path}
									content={
										projectGroup.node.xeroPaid
											? formatNumber(projectGroup.node.xeroPaid, {format: 'always_two_decimal'})
											: null
									}
									className="numeric"
								/>
							);
						} else if (col.name === 'xero-due' && hasFinancialAccess && this.props.hasXeroEnabled) {
							return (
								<TdLink
									className="numeric"
									key={index}
									path={path}
									content={
										projectGroup.node.xeroAmountTotal && projectGroup.node.xeroPaid
											? formatNumber(projectGroup.node.xeroAmountTotal - projectGroup.node.xeroPaid, {
													format: 'always_two_decimal',
											  })
											: null
									}
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'invoice-amount-total' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									content={Util.getFormattedCurrencyValue(
										this.props.currencySymbol,
										formatNumber(projectGroup.node.invoiceAmountTotal, {format: 'always_two_decimal'})
									)}
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'invoice-paid' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									className="numeric"
									path={path}
									style={{backgroundColor: remainingColor}}
									content={Util.getFormattedCurrencyValue(
										this.props.currencySymbol,
										formatNumber(projectGroup.node.invoicePaid, {format: 'always_two_decimal'})
									)}
								/>
							);
						} else if (col.name === 'invoice-due' && hasFinancialAccess) {
							return (
								<TdLink
									key={index}
									className="numeric"
									path={path}
									content={Util.getFormattedCurrencyValue(
										this.props.currencySymbol,
										formatNumber(projectGroup.node.invoiceAmountTotal - projectGroup.node.invoicePaid, {
											format: 'always_two_decimal',
										})
									)}
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'statusColor' || col.name === 'statusColorV2') {
							return <TdLink key={index} path={path} content={''} style={{backgroundColor: remainingColor}} />;
						} else if (col.name === 'forecastTotal') {
							return (
								<TdLink
									key={index}
									path={path}
									content={
										isCalculatedDataReady
											? projectGroup.node.isHourEstimated
												? Util.convertMinutesToFullHour(
														projectGroup.node.forecastTotal,
														this.props.intl
												  )
												: formatMessage(
														{id: 'common.x_points'},
														{points: projectGroup.node.forecastTotal}
												  )
											: '-'
									}
									className="numeric"
									style={{backgroundColor: remainingColor}}
								/>
							);
						} else if (col.name === 'doneTasksCount') {
							return (
								<TdLink
									key={index}
									path={path}
									style={{backgroundColor: remainingColor}}
									className="numeric"
									content={isCalculatedDataReady ? formatNumber(projectGroup.node.doneTasksCount) : '-'}
								/>
							);
						} else if (col.name === 'tasksCount') {
							return (
								<TdLink
									key={index}
									path={path}
									style={{backgroundColor: remainingColor}}
									className="numeric"
									content={isCalculatedDataReady ? formatNumber(projectGroup.node.tasksCount) : '-'}
								/>
							);
						} else if (col.name === 'rateCard' && hasFinancialAccess) {
							return <TdLink key={index} path={path} style={{backgroundColor: remainingColor}} content={''} />;
						} else if (col.name === 'labels') {
							return <TdLink key={index} path={path} style={{backgroundColor: remainingColor}} content={''} />;
						} else if (col.name === 'projectPersonsCount') {
							return (
								<TdLink
									key={index}
									path={path}
									style={{backgroundColor: remainingColor}}
									className="numeric"
									content={isCalculatedDataReady ? formatNumber(projectGroup.node.projectPersonsCount) : '-'}
								/>
							);
						} else if (col.name === 'variance_to_forecast') {
							return (
								<TdLink
									key={index}
									path={path}
									className="numeric"
									style={{backgroundColor: remainingColor}}
									content={
										projectGroup.node.isHourEstimated
											? Util.convertMinutesToFullHour(
													Util.calculateVarianceToForecast(
														projectGroup.node,
														projectGroup.node.isHourEstimated
													) * 60,
													this.props.intl
											  )
											: formatMessage(
													{id: 'common.x_points'},
													{
														points: Util.calculateVarianceToForecast(
															projectGroup.node,
															projectGroup.node.isHourEstimated
														),
													}
											  )
									}
								/>
							);
						} else return null;
					} else return null;
				})}
			</tr>
		);
	}
}
OverviewProjectsGroupLineItem.propTypes = {
	projectGroup: PropTypes.object.isRequired,
};
export default injectIntl(OverviewProjectsGroupLineItem);
