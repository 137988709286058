import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {fetchData, fetchMapData} from '../reported_time_financials_fetch';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import {getDepartmentRow} from '../rows/department_row';
import BaseLevelWrapper from './BaselevelWrapper';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {hasSomePermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {useDidObjectChange} from '../../../../forecast-app/shared/hooks/useDidObjectChange';

const departmentRelayWrapper = ({
	tableHeader,
	relay,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
}) => {
	const departments = viewer.company.departments.edges;
	const currency = viewer.company.currency;
	const hasNextPage = viewer.company.departments.pageInfo.hasNextPage;
	const [departmentsFinancialMap, setDepartmentsFinancialMap] = useState(null);
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const [nonDepartmentTotalMinutesRegistered, setNonDepartmentTotalMinutesRegistered] = useState(null);
	const [nonDepartmentTotalFinancials, setNonDepartmentTotalFinancials] = useState(null);

	const departmentsChanged = useDidObjectChange(departments, null);
	const searchQueryChanged = useDidObjectChange(searchQuery, null);
	const shouldRefetchFinancials = departmentsChanged || searchQueryChanged;

	useEffect(() => {
		if (shouldRefetchFinancials) {
			const departmentsIds = departments.map(department => department.node.id);
			if (
				hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				])
			) {
				fetchMapData(departmentsIds, searchQuery, 'total_department_time_regs_financials').then(data => {
					const departmentsFinancialMap = new Map();
					Object.keys(data).forEach(key => {
						departmentsFinancialMap.set(key, data[key]);
					});
					setDepartmentsFinancialMap(departmentsFinancialMap);
				});
			}

			if (!hasNextPage) {
				fetchData(searchQuery, 'non_department_total_minutes_registered').then(data => {
					if (data.total !== undefined) {
						setNonDepartmentTotalMinutesRegistered(data.total);
					}
				});
				fetchData(searchQuery, 'total_non_department_time_regs_financials').then(data => {
					setNonDepartmentTotalFinancials(data.totals);
				});
			}
		}
	}, [shouldRefetchFinancials]);

	const dataRows = departments.map(department => {
		return {
			...department.node,
			totalPrice:
				departmentsFinancialMap !== null && departmentsFinancialMap.has(department.node.id)
					? departmentsFinancialMap.get(department.node.id).totalPrice
					: null,
			totalCost:
				departmentsFinancialMap !== null && departmentsFinancialMap.has(department.node.id)
					? departmentsFinancialMap.get(department.node.id).totalCost
					: null,
		};
	});

	nonDepartmentTotalMinutesRegistered !== null &&
		dataRows.push({
			isNoDepartment: true,
			name: intl.formatMessage({id: 'time_reg_report.no_department'}),
			totalMinutesRegistered: nonDepartmentTotalMinutesRegistered,
			totalPrice: nonDepartmentTotalFinancials ? nonDepartmentTotalFinancials.totalPrice : null,
			totalCost: nonDepartmentTotalFinancials ? nonDepartmentTotalFinancials.totalCost : null,
		});

	const data = {rows: dataRows};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = departments.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const departmentRows = () => {
		return getDepartmentRow(
			data,
			currency,
			intl,
			groupIndex,
			departmentsFinancialMap,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader
		);
	};

	return groupIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			data={data}
			getRows={departmentRows}
			currentLength={departments.length}
			hasNextPage={viewer.company.departments.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		departmentRows()
	);
};

const departmentRelayWrapperQuery = graphql`
	query departmentRelayWrapperQuery_Query($searchQuery: TaskSearchQueryType, $pageSize: Int) {
		viewer {
			actualPersonId
			component(name: "department_relay_wrapper")
			...departmentRelayWrapper_viewer @arguments(searchQuery: $searchQuery, pageSize: $pageSize)
		}
	}
`;
export {departmentRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			departmentRelayWrapper,
			{
				viewer: graphql`
					fragment departmentRelayWrapper_viewer on Viewer
					@argumentDefinitions(searchQuery: {type: "TaskSearchQueryType"}, pageSize: {type: "Int"}) {
						availableFeatureFlags {
							key
						}
						company {
							currency
							departments(first: $pageSize, searchQuery: $searchQuery) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										totalMinutesRegistered(searchQuery: $searchQuery)
										id
										name
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query departmentRelayWrapperRefetchQuery($pageSize: Int, $searchQuery: TaskSearchQueryType) {
					viewer {
						...departmentRelayWrapper_viewer @arguments(pageSize: $pageSize, searchQuery: $searchQuery)
					}
				}
			`
		)
	)
);
