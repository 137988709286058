import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CurrencyInput extends Component {
	onValueChange(e) {
		const value = e.target.value
			? this.props.allowDecimals
				? parseFloat(Math.round(e.target.value * 100.0) / 100.0, 10)
				: parseInt(e.target.value, 10)
			: null;

		this.props.onChange(value);
	}

	onBlur() {
		if (this.props.onBlur) {
			this.props.onBlur();
		}
	}

	handleKeyPressCurrency(e) {
		if (e.key === 'Enter') {
			if (this.props.blurOnEnter) {
				this.currencyInput.blur();
			}

			if (this.props.onEnter) {
				this.props.onEnter();
			}
		}
	}

	render() {
		//step 0.01 needed on input field with allowDecimals prop otherwise firefox marks it with red border as invalid when value has decimal numbers
		return (
			<span
				className={
					this.props.showCurrencyOnLeft
						? 'input-' + this.props.currency + ' left'
						: 'input-' + this.props.currency + ' right'
				}
			>
				<input
					ref={input => (this.currencyInput = input)}
					type="number"
					value={this.props.value !== null && this.props.value !== undefined ? this.props.value : ''}
					onChange={this.onValueChange.bind(this)}
					placeholder={this.props.placeholder}
					onBlur={this.onBlur.bind(this)}
					onKeyPress={this.handleKeyPressCurrency.bind(this)}
					step={this.props.allowDecimals ? '0.01' : '1'}
					disabled={this.props.disabled}
					autoFocus={this.props.autoFocus}
					data-cy={this.props.dataCy}
					className={this.props.className}
				/>
			</span>
		);
	}
}

CurrencyInput.propTypes = {
	placeholder: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	showCurrencyOnLeft: PropTypes.bool,
	currency: PropTypes.string.isRequired,
	allowDecimals: PropTypes.bool,
	blurOnEnter: PropTypes.bool,
	onEnter: PropTypes.func,
	disabled: PropTypes.bool,
	autoFocus: PropTypes.bool,
	dataCy: PropTypes.string,
	className: PropTypes.string,
};

export default CurrencyInput;
