import {injectIntl} from 'react-intl';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Util from '../../../forecast-app/shared/util/util';
import WarningIcon from '../../../images/warning_icon';
import Person from '../../../forecast-app/shared/components/person/person';
import LockedIcon from '../../../images/integrations/locked_icon';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {profilePicSrc} from '../../../directApi';

class TimeRegTableRow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			newTaskInput: '',
		};
		this.getElemForCol = this.getElemForCol.bind(this);
	}

	toggleContextMenu(e) {
		if (this.props.disabled || this.props.projectLocked) {
			return;
		}
		e.preventDefault();
		e.stopPropagation();
		this.props.onContextMenu(this.props.task, e);
	}

	onRowBlur(e) {
		this.setState({showContextMenu: false});
	}

	onTaskClick(e, task) {
		if (!e.shiftKey) {
			const newTarget = e.relatedTarget || e.explicitOriginalTarget;
			if (newTarget && (newTarget.tagName === 'INPUT' || newTarget.tagName === 'Button')) {
				return;
			}
			tracking.trackElementClicked('Task name');
			trackEvent('Task Name', 'Clicked');
			this.openCardModal(e, task);
		}
	}

	openCardModal(e, item) {
		e.stopPropagation();
		e.preventDefault();
		if (this.props.showTaskModal && item && item.companyTaskId) {
			this.props.showTaskModal(item.companyTaskId);
		}
	}

	getNumberOfIconsForTask(task) {
		const hasWarning = Util.GetTaskWarnings(task) != null;
		// Boolean to number conversion, woooh!
		const numIcons = task.bug + task.blocked + task.highPriority + hasWarning;
		return numIcons;
	}

	getElemForCol(col) {
		const {formatMessage, formatNumber} = this.props.intl;
		const timeReg = this.props.timeReg;

		const task = timeReg.node.task;
		const project = this.props.viewer.project;
		const taskCellMaxWidth = this.props.taskCellMaxWidth;
		const colName = col.name;
		const totalTimeInMinutes = this.props.showBillableMinutes
			? timeReg.node.billableMinutesRegistered
			: timeReg.node.minutesRegistered;

		const totalBillableMinutes = timeReg.node.billableMinutesRegistered;
		const totalNonBillableMinutes = timeReg.node.minutesRegistered - timeReg.node.billableMinutesRegistered;

		const totalPrice = timeReg.node.price;
		const disableActions = this.props.projectLocked || this.props.disabled;

		switch (colName) {
			case 'task-id':
				return (
					<div data-cy={'task-id-row-elem'} className={'task-id-row-elem'}>
						{task ? 'T' + task.companyTaskId : null}
					</div>
				);
			case 'task-name':
				// 15px icon width, 2px margin
				const iconWidth = 17;
				const warnings = task ? Util.GetTaskWarnings(task, this.props.intl) : null;
				const totalIconWidth = task ? this.getNumberOfIconsForTask(task) * iconWidth : 0;
				const groupPadding = 16;
				return (
					<div
						className={'task-group'}
						style={{
							maxWidth: taskCellMaxWidth,
							minWidth: taskCellMaxWidth,
						}}
					>
						{task && warnings && warnings[0] ? (
							<div className={'warning-icon'}>
								<WarningIcon
									title={warnings[0].message}
									danger={warnings[0].color === 'red'}
									customWidth={15}
									customHeight={15}
								/>
							</div>
						) : null}
						{task && task.highPriority ? <div className={'high-priority-flag'} /> : null}
						{task && task.blocked ? <div className={'blocked-icon'} /> : null}
						{task && task.bug ? <div className={'bug-icon'} /> : null}
						<div
							className={'task-name' + (task && task.highPriority ? ' with-flag' : '')}
							style={{
								maxWidth: taskCellMaxWidth ? taskCellMaxWidth - totalIconWidth - groupPadding : '',
								minWidth: taskCellMaxWidth ? taskCellMaxWidth - totalIconWidth - groupPadding : '',
							}}
						>
							{disableActions || !task ? (
								<div
									className={'td-text no-click' + (!task ? ' project-name' : '')}
									tabIndex={'0'}
									onKeyDown={
										task
											? e => (e.key === ' ' || e.key === 'Enter' ? this.openCardModal(e, task) : null)
											: null
									}
									onClick={e => this.onTaskClick(e, task)}
									title={task ? task.name : project.name}
								>
									{task ? task.name : formatMessage({id: 'common.project_time'})}
								</div>
							) : (
								<a
									className={'td-text'}
									href={
										window.location.href +
										(window.location.href.endsWith('/')
											? 'T' + task.companyTaskId
											: '/T' + task.companyTaskId)
									}
									tabIndex={'0'}
									onKeyDown={e => (e.key === ' ' || e.key === 'Enter' ? this.openCardModal(e, task) : null)}
									onClick={e => this.onTaskClick(e, task)}
									title={task.name}
								>
									{task.name}
								</a>
							)}
						</div>
					</div>
				);

			case 'billable-time-entries':
				return (
					<div className={'time-entry'}>{Util.convertMinutesToFullHour(totalBillableMinutes, this.props.intl)}</div>
				);
			case 'non-billable-time-entries':
				return (
					<div className={'time-entry'}>
						{Util.convertMinutesToFullHour(totalNonBillableMinutes, this.props.intl)}
					</div>
				);
			case 'time-entries':
				return <div className={'time-entry'}>{Util.convertMinutesToFullHour(totalTimeInMinutes, this.props.intl)}</div>;
			case 'price':
				return (
					<div className={'price'}>
						{Util.getFormattedCurrencyValue(
							Util.GetCurrencySymbol(
								project.rateCard ? project.rateCard.currency : this.props.viewer.company.currency
							),
							formatNumber(totalPrice, {format: 'always_two_decimal'})
						)}
					</div>
				);
			case 'date':
				return (
					<div className="phase-deadline-select">
						{this.props.hasConflict ? (
							<WarningIcon title={formatMessage({id: 'retainer.warning.confliting_time_reg'})} danger={true} />
						) : null}
						<div className={'timereg-date'}>
							{Util.CreateNonUtcMomentDate(timeReg.node.year, timeReg.node.month, timeReg.node.day).format(
								'DD MMM'
							)}
						</div>
					</div>
				);
			case 'chip-right':
				return (
					<div className={'last-cell'} style={{height: this.props.rowHeight}}>
						<div className="chip-container ">
							<div
								className={
									'chip-div' +
									(!this.props.selected && !this.props.isHighlighted && this.props.noBorderRight
										? ' no-border-right'
										: '')
								}
							/>
							<div className={'chip ' + (!this.props.emptyPhase ? 'has-phase' : '')} />
						</div>
					</div>
				);
			case 'person':
				const {person} = timeReg.node;
				return (
					<Person
						key={timeReg.node.id}
						name={person.firstName + ' ' + person.lastName}
						role={''}
						showName={true}
						imageSrc={profilePicSrc(person.profilePictureId)}
						imageSize="medium"
						showRole={false}
					/>
				);
			case 'invoiced':
				const periodLocked = this.props.tableEntity && this.props.tableEntity.node.periodLocked;
				return periodLocked ? (
					<LockedIcon
						width={14}
						height={13}
						fill="#a1a1a1"
						className="status-icon"
						title={formatMessage({id: 'retainer.cannot_edit_locked_time_reg'})}
					/>
				) : null;
			case 'harvest':
				return this.props.harvestEnabled ? (
					<div className={'harvest-cell'}>
						{Util.getHarvestStatusIcon(timeReg.node, this.props.intl)}
						<div className={'harvest-task'}>{timeReg.node.harvestTask ? timeReg.node.harvestTask.name : null}</div>
					</div>
				) : null;
			default:
				return null;
		}
	}

	render() {
		let rowElements = [];
		const disabled =
			this.props.projectLocked ||
			this.props.disabled ||
			(this.props.task && this.props.task.project.status === 'HALTED') ||
			(this.props.task && this.props.task.project.status === 'DONE');
		if (this.props.timeReg) {
			const timeReg = this.props.timeReg.node;
			const {formatMessage} = this.props.intl;
			this.estimationUnit = this.props.isEstimatedInHours
				? formatMessage({id: 'common.hours.short'})
				: formatMessage({id: 'common.points.short'});
			this.path =
				(window.location.pathname.endsWith('/') ? window.location.pathname : window.location.pathname + '/') +
				'T' +
				timeReg.companyTaskId;
			rowElements = [];
			this.props.availableColumns.forEach(col => {
				if (col.checked || col.maintainSpace) {
					rowElements.push({
						element: this.getElemForCol(col),
						minWidth: col.minWidth,
						maxWidth: col.maxWidth ? col.maxWidth : '',
						justifyContent: col.align,
					});
				}
			});
			const taskCellMaxWidth = this.props.taskCellMaxWidth;
			const content = (
				<div
					onBlur={this.onRowBlur.bind(this)}
					className={
						'table-row-v3 time-reg' +
						(disabled ? ' locked' : '') +
						(this.props.parentType ? ' ' + this.props.parentType : '') +
						(this.props.hasConflict ? ' retainer-period-conflict' : '')
					}
					data-cy={'time-reg-table-row-v3'}
					ref={this.props.innerRef}
					style={{
						height: this.props.rowHeight,
					}}
					// onContextMenu={e => this.props.onContextMenu(this.props.task, e)}
				>
					<div className={'empty-div'} />
					{rowElements.map((obj, index) => {
						return (
							<div
								key={index}
								className={
									'elem-wrapper' +
									(!obj.maxWidth ? ' growable' : '') +
									(index === rowElements.length - 1 ? ' last' : '')
								}
								style={{
									height: this.props.rowHeight,
									minWidth: obj.minWidth,
									maxWidth: obj.maxWidth ? obj.maxWidth : taskCellMaxWidth,
									justifyContent: obj.justifyContent,
								}}
							>
								{obj.element}
							</div>
						);
					})}
					{disabled || true ? null : <div className={'dot-options'} onClick={this.toggleContextMenu.bind(this)} />}
				</div>
			);
			return this.props.withMargin ? <div className={'table-row-v3-margin-wrapper'}>{content}</div> : content;
		}
	}
}

TimeRegTableRow.propTypes = {
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	task: PropTypes.object,
	index: PropTypes.number,
	tableRowElems: PropTypes.array,
	rowHeight: PropTypes.number.isRequired,
	disabled: PropTypes.bool,
	shouldHaveBorderLeft: PropTypes.bool,
	onRowSelected: PropTypes.func,
	noBorderRight: PropTypes.bool,
	isHighlighted: PropTypes.bool,
};

export default injectIntl(TimeRegTableRow);
