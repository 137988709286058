/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetTotalsLoader_viewer$ref: FragmentReference;
declare export opaque type BudgetTotalsLoader_viewer$fragmentType: BudgetTotalsLoader_viewer$ref;
export type BudgetTotalsLoader_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string
  |},
  +project: ?{|
    +budget: ?number,
    +rateCard: ?{|
      +currency: ?string
    |},
    +billable: ?boolean,
    +useBaseline: ?boolean,
    +budgetType: ?BUDGET_TYPE,
    +useFixedPriceForBaselineRevenue: ?boolean,
    +financialNumbers: ?{|
      +plannedRevenue: ?number,
      +totalActualRevenueRecognition: ?number,
      +totalForecastRevenueToComplete: ?number,
      +totalRevenueRecognition: ?number,
      +baselineVsActualRevenueRecognition: ?number,
      +baselineVsTotalRevenueAtCompletion: ?number,
      +planVsActualRevenueRecognition: ?number,
      +planVsTotalRevenueAtCompletion: ?number,
      +baselineRevenue: ?number,
      +baselineVsBillableActualTimeAndExpenses: ?number,
      +baselineVsBillableTotalTimeAndExpensesAtCompletion: ?number,
      +billableActualTimeAndExpenses: ?number,
      +billablePlannedTimeAndExpenses: ?number,
      +planVsBillableActualTimeAndExpenses: ?number,
      +planVsTotalBillableTimeAndExpensesAtCompletion: ?number,
      +billableForecastTimeAndExpensesToComplete: ?number,
      +billableTotalTimeAndExpensesAtCompletion: ?number,
      +recognitionAmount: ?number,
      +baselineTimeAndExpenses: ?number,
      +baselineCost: ?number,
      +baselineVsActualCost: ?number,
      +baselineVsTotalCostAtCompletion: ?number,
      +actualCost: ?number,
      +plannedCost: ?number,
      +planVsActualCost: ?number,
      +planVsTotalCostAtCompletion: ?number,
      +forecastCostToComplete: ?number,
      +totalCostAtCompletion: ?number,
      +baselineProfit: ?number,
      +baselineVsActualProfit: ?number,
      +baselineVsTotalProfitAtCompletion: ?number,
      +actualProfit: ?number,
      +plannedProfit: ?number,
      +planVsActualProfit: ?number,
      +planVsTotalProfitAtCompletion: ?number,
      +forecastProfitToComplete: ?number,
      +totalProfitAtCompletion: ?number,
      +baselineVsActualRevenueProfit: ?number,
      +baselineVsTotalRevenueProfitAtCompletion: ?number,
      +actualRevenueProfit: ?number,
      +plannedRevenueProfit: ?number,
      +planVsActualRevenueProfit: ?number,
      +planVsTotalRevenueProfitAtCompletion: ?number,
      +forecastRevenueProfitToComplete: ?number,
      +totalRevenueProfitAtCompletion: ?number,
      +baselineMargin: ?number,
      +actualMargin: ?number,
      +plannedMargin: ?number,
      +forecastMarginToComplete: ?number,
      +totalMarginAtCompletion: ?number,
      +baselineVsActualRevenueMargin: ?number,
      +baselineVsTotalRevenueMarginAtCompletion: ?number,
      +planVsActualRevenueMargin: ?number,
      +planVsTotalRevenueMarginAtCompletion: ?number,
      +actualRevenueMargin: ?number,
      +plannedRevenueMargin: ?number,
      +forecastRevenueMarginToComplete: ?number,
      +totalRevenueMarginAtCompletion: ?number,
      +estimatedCost: ?number,
      +recognitionProfit: ?number,
      +recognitionProfitPercentage: ?number,
    |},
  |},
  +$refType: BudgetTotalsLoader_viewer$ref,
|};
export type BudgetTotalsLoader_viewer$data = BudgetTotalsLoader_viewer;
export type BudgetTotalsLoader_viewer$key = {
  +$data?: BudgetTotalsLoader_viewer$data,
  +$fragmentRefs: BudgetTotalsLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BudgetTotalsLoader_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budget",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useBaseline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useFixedPriceForBaselineRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "convertToProjectCurrency",
              "value": true
            },
            {
              "kind": "Variable",
              "name": "endDay",
              "variableName": "endDay"
            },
            {
              "kind": "Variable",
              "name": "endMonth",
              "variableName": "endMonth"
            },
            {
              "kind": "Variable",
              "name": "endYear",
              "variableName": "endYear"
            },
            {
              "kind": "Variable",
              "name": "startDay",
              "variableName": "startDay"
            },
            {
              "kind": "Variable",
              "name": "startMonth",
              "variableName": "startMonth"
            },
            {
              "kind": "Variable",
              "name": "startYear",
              "variableName": "startYear"
            }
          ],
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "financialNumbers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalActualRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalForecastRevenueToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalRevenueAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalRevenueAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsBillableActualTimeAndExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsBillableTotalTimeAndExpensesAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableActualTimeAndExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billablePlannedTimeAndExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsBillableActualTimeAndExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalBillableTimeAndExpensesAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableForecastTimeAndExpensesToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableTotalTimeAndExpensesAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineTimeAndExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalCostAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalCostAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastCostToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCostAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastProfitToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualRevenueProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalRevenueProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualRevenueProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedRevenueProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualRevenueProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalRevenueProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastRevenueProfitToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalRevenueProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastMarginToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalMarginAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualRevenueMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalRevenueMarginAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualRevenueMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalRevenueMarginAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualRevenueMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedRevenueMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastRevenueMarginToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalRevenueMarginAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "estimatedCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionProfitPercentage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '16e792a16fa7e3062c9600fc11a20620';

module.exports = node;
