import {BUDGET_TYPE, BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../../constants';
import * as tracking from '../../../../tracking';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../../../forecast-app/shared/util/util';
import {useIntl} from 'react-intl';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';

export const getEditFixedPriceButton = (project, company, setShowFixedPriceContainer) => {
	const intl = useIntl();
	const {formatMessage, formatNumber} = intl;
	const isFixedPrice = project.budgetType === BUDGET_TYPE.FIXED_PRICE || project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2;

	const currency = project.rateCard ? project.rateCard.currency : company.currency;

	const getFormattedNumberWithCurrency = (currency, number) =>
		Util.getFormattedCurrencyValue(
			currency,
			formatNumber(Math.round(number > -0.005 && number < 0 ? 0 : number * 100) / 100, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}) //compare the value with 0.005 to prevent math.round to return -0
		);
	const showFixedPriceModal = () => {
		showModal({
			type: MODAL_TYPE.CHANGE_FIXED_PRICE,
			currency,
			isFixedPrice,
			project,
			getFormattedNumberWithCurrency,
			callback: function () {
				dispatch(EVENT_ID.BUDGET_UPDATE_PROJECT);
			},
		});

		tracking.trackElementClicked('Edit Fix Price button');
		trackEvent('Edit Fixed Price Button', 'Clicked');
	};

	return {
		type: ELEMENT_TYPE.BUTTON,
		text: formatMessage({id: 'project_budget.edit_fixed_price'}),
		callback: isFixedPrice ? setShowFixedPriceContainer : showFixedPriceModal,
		style: BUTTON_STYLE.OUTLINE,
		color: BUTTON_COLOR.VERYLIGHTGREY,
		userpilot: 'set-fixed-price-button',
		dataCy: 'set-fixed-price-button',
	};
};
