import React, {Component} from 'react';

class Chip extends Component {
	handleSelect(e) {
		this.props.handleSelect(e, this.props.el, true);
	}
	render() {
		return (
			<div
				className={'value-chip' + (this.props.globalSelect ? ' global' : '')}
				onClick={this.props.globalSelect ? this.handleSelect.bind(this) : null}
			>
				<span>{this.props.el.label}</span>
				{this.props.globalSelect ? (
					<div className="spacer" />
				) : (
					<div
						className={'remove-icon'}
						onClick={!this.props.locked && !this.globalSelect ? this.handleSelect.bind(this) : null}
					/>
				)}
			</div>
		);
	}
}
export default Chip;
