import React from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import PremiumIcon from '../../images/v2/premium_icon';

const FinancialIcon = styled.div`
	display: inline-flex;
	margin-left: 4px;
	height: 10px;
`;

const FinancialLockIcon = props => {
	const intl = useIntl();
	return (
		<FinancialIcon title={intl.formatMessage({id: 'common.requires_financial_module'})}>
			<PremiumIcon {...props} />
		</FinancialIcon>
	);
};

export default FinancialLockIcon;
