import React, {useMemo} from 'react';
import {Dropdown} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';

const sortByName = (a, b) => Util.sortAlphabetically(a.name, b.name);

const BillToDropdown = ({billFroms, selectedItems, onRemove, onSelect, ...rest}) => {
	const sortedBillFroms = useMemo(() => [...billFroms].sort(sortByName), [billFroms]);

	const billTosByBillFromId = useMemo(() => {
		const map = new Map();
		billFroms.forEach(billFrom => {
			const sortedBillTos = billFrom.billTos.edges.map(edge => edge.node).sort(sortByName);
			map.set(billFrom.id, sortedBillTos);
		});
		return map;
	}, [billFroms]);

	return (
		<>
			<Dropdown
				{...rest}
				selectedItems={selectedItems}
				onRemove={value => onRemove(value[0])}
				onSelect={value => onSelect(value[0])}
				isNested
				isMultiSelect
				hideEmptyGroups
			>
				{sortedBillFroms.map(billFrom => {
					const billTos = billTosByBillFromId.get(billFrom.id);
					if (!billTos) return null;
					return (
						<Dropdown.Group name={billFrom.name} key={`bill-to-bill-from-${billFrom.id}`} initiallyCollapsed>
							{billTos.map(billTo => (
								<Dropdown.SingleText
									key={billTo.id}
									value={billTo.id}
									searchString={billTo.name}
									cy={rest.cy + '-option'}
								>
									{billTo.name}
								</Dropdown.SingleText>
							))}
						</Dropdown.Group>
					);
				})}
			</Dropdown>
		</>
	);
};

export default BillToDropdown;
