import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import util from '../../../forecast-app/shared/util/util';
import InsightsUtil from '../insights_util';

class expenseReportProjectComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sortBy: {column: null, ascending: true},
		};
	}

	UNSAFE_componentWillMount() {
		const listHeight = InsightsUtil.GetlistHeight(this.props.viewer.insightComponentsData.expenseReportProject.data.length);
		this.props.updateComponentHeight(this.props.id, listHeight);
		if (this.props.setExportColumns) {
			let exportColumns = [];
			this.props.columns.forEach(col => {
				if (['name', 'expenseCategory', 'billable', 'createdBy', 'createdAt', 'updatedBy', 'updatedAt'].includes(col)) {
					exportColumns.push(col);
				} else {
					exportColumns.push(col + '_raw');
				}
			});
			this.props.setExportColumns(exportColumns);
		}
	}

	formatAmount(amount, currency) {
		const {formatNumber} = this.props.intl;
		const currencySymbol = util.GetCurrencySymbol(currency);
		const isSymbolInFront = util.CurrencyIsPrefixed(currencySymbol);
		return `${isSymbolInFront ? currencySymbol : ''}${formatNumber(Math.round(amount * 100) / 100)}${
			!isSymbolInFront ? currencySymbol : ''
		}`;
	}

	formatDate(epoch) {
		const dateObject = new Date(parseInt(epoch, 10));
		const dateString = dateObject.toLocaleDateString();
		const timeString = dateObject.toLocaleString([], {hour: '2-digit', minute: '2-digit'});
		return `${dateString} ${timeString}`;
	}

	getData() {
		const {formatMessage, formatNumber, formatDate} = this.props.intl;
		const globalData = this.props.viewer.insightComponentsData.expenseReportProject;
		const data = globalData.data;
		const result = [];
		const currency = globalData.currency;
		for (const expense of data) {
			const row = {};
			row.id = expense.id;
			row.name = expense.name;
			row.cost = this.formatAmount(expense.cost, currency);
			row.cost_raw = expense.cost;
			row.price = expense.price ? this.formatAmount(expense.price, currency) : '-';
			row.price_raw = expense.price;
			row.markup = expense.markupPercentage ? formatNumber(expense.markupPercentage) + '%' : '-';
			row.markup_raw = expense.markupPercentage;
			row.quantity = formatNumber(expense.quantity);
			row.quantity_raw = expense.quantity;
			row.expenseCategory = expense.expenseCategory.name;
			const expenseDate = expense.expenseYear
				? util.CreateNonUtcMomentDate(expense.expenseYear, expense.expenseMonth, expense.expenseDay)
				: null;
			row.date = expenseDate ? formatDate(expenseDate) : null;
			row.date_raw = expenseDate !== null ? expenseDate.toDate() : null;
			row.notes = expense.notes;
			row.notes_raw = expense.notes ? util.ConvertDraftJsToPlainText(expense.notes) : null;
			row.billable = formatMessage({id: expense.billable ? 'common.yes' : 'common.no'});
			row.person = expense.person;
			row.person_raw = expense.person ? `${expense.person.firstName} ${expense.person.lastName}` : null;
			row.createdAt = this.formatDate(expense.createdAt);
			row.createdAt_raw = expense.createdAt;
			row.updatedAt = this.formatDate(expense.updatedAt);
			row.updatedAt_raw = expense.updatedAt;
			row.createdBy = expense.createdBy ? `${expense.createdBy.firstName} ${expense.createdBy.lastName}` : '';
			row.updatedBy = expense.updatedBy ? `${expense.updatedBy.firstName} ${expense.updatedBy.lastName}` : '';
			result.push(row);
		}
		const sortBy = this.state.sortBy;
		if (sortBy.column) {
			result.sort((a, b) => {
				let column = sortBy.column;
				switch (column) {
					case 'id':
					case 'name':
					case 'billable':
					case 'createdBy':
					case 'updatedBy':
					case 'expenseCategory':
						break;
					default:
						column += '_raw';
						break;
				}
				let res = 0;
				if (a[column] < b[column]) res = 1;
				if (a[column] > b[column]) res = -1;

				return sortBy.ascending ? res * -1 : res;
			});
		}
		if (this.props.setExportData) {
			this.props.setExportData(result);
		}
		return result;
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	render() {
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};
		return (
			<InsightList
				componentId={this.props.id}
				data={this.getData()}
				componentName={'ExpenseReportProject'}
				activeColumns={configObject ? configObject.columns : []}
				allColumns={this.props.columns}
				sortBy={this.state.sortBy}
				setSortBy={this.setSortBy.bind(this)}
				scrollElement={this.props.scrollElement}
				scrollElementFullScreen={this.props.scrollElementFullScreen}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

const expenseReportProjectComponentQuery = graphql`
	query expenseReportProjectComponent_Query($shareKey: String, $projectId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_expense_report_project")
			...expenseReportProjectComponent_viewer
				@arguments(shareKey: $shareKey, projectId: $projectId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {expenseReportProjectComponentQuery};

export default injectIntl(
	createFragmentContainer(expenseReportProjectComponent, {
		viewer: graphql`
			fragment expenseReportProjectComponent_viewer on Viewer
			@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
				insightComponentsData(shareKey: $shareKey) {
					expenseReportProject(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
						id
						currency
						project {
							id
							name
						}
						projectGroup {
							id
							name
						}
						client {
							id
							name
						}
						data {
							name
							cost
							price
							markupPercentage
							quantity
							expenseYear
							expenseMonth
							expenseDay
							expenseCategory {
								name
							}
							notes
							billable
							person {
								firstName
								lastName
								profilePictureId
								profilePictureDefaultId
							}
							createdAt
							createdBy {
								id
								firstName
								lastName
							}

							updatedAt
							updatedBy {
								id
								firstName
								lastName
							}
						}
					}
				}
			}
		`,
	})
);
