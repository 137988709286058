/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type sprintVelocityComponent_viewer$ref = any;
export type sprintVelocityComponent_QueryVariables = {|
  shareKey?: ?string,
  projectId?: ?string,
  isProjectGroupType?: ?boolean,
|};
export type sprintVelocityComponent_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: sprintVelocityComponent_viewer$ref,
  |}
|};
export type sprintVelocityComponent_Query = {|
  variables: sprintVelocityComponent_QueryVariables,
  response: sprintVelocityComponent_QueryResponse,
|};
*/


/*
query sprintVelocityComponent_Query(
  $shareKey: String
  $projectId: ID
  $isProjectGroupType: Boolean
) {
  viewer {
    actualPersonId
    component(name: "insight_sprint_velocity")
    ...sprintVelocityComponent_viewer_2fJgLr
    id
  }
}

fragment sprintVelocityComponent_viewer_2fJgLr on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    sprintVelocity(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
      useHours
      sprints {
        title
        sprintId
        projectGroupSprintId
        value
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isProjectGroupType",
    "type": "Boolean"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "insight_sprint_velocity"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"insight_sprint_velocity\")"
},
v3 = {
  "kind": "Variable",
  "name": "isProjectGroupType",
  "variableName": "isProjectGroupType"
},
v4 = {
  "kind": "Variable",
  "name": "projectId",
  "variableName": "projectId"
},
v5 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sprintVelocityComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "sprintVelocityComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sprintVelocityComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              (v5/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "SprintVelocity",
                "kind": "LinkedField",
                "name": "sprintVelocity",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "useHours",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SprintVelocityElement",
                    "kind": "LinkedField",
                    "name": "sprints",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sprintId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectGroupSprintId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "sprintVelocityComponent_Query",
    "operationKind": "query",
    "text": "query sprintVelocityComponent_Query(\n  $shareKey: String\n  $projectId: ID\n  $isProjectGroupType: Boolean\n) {\n  viewer {\n    actualPersonId\n    component(name: \"insight_sprint_velocity\")\n    ...sprintVelocityComponent_viewer_2fJgLr\n    id\n  }\n}\n\nfragment sprintVelocityComponent_viewer_2fJgLr on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    sprintVelocity(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {\n      useHours\n      sprints {\n        title\n        sprintId\n        projectGroupSprintId\n        value\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '33cff49aeffa7bca4efd2805c6e6ef99';

module.exports = node;
