import Item from '../../canvas-timeline/canvas_timeline_item';
import {
	AUTO_SCHEDULING_PERSON_WIDTH,
	CURSOR,
	drawHexagon,
	drawRectangle,
	fillText2Colors,
	getMomentFromCanvasTimelineDate,
	getTaskProjectBackgroundColor,
	getTrimmedText,
	getVisualizationMode,
	GROUP_SECTION_TEXT_GREY_DARK,
	GROUP_SECTION_WIDTH,
	GROUP_TYPE,
	isProjectDoneOrHalted,
	isSimulationMode,
	ITEM_DRAG_POINT,
	ITEM_TYPE,
	MONTH_NAMES_SHORT,
	moveItem,
	TASK_ITEM_CHECKMARK_SIZE,
	TASK_ITEM_DONE_BACKGROUND_COLOR,
	TASK_ITEM_ICON_MARGIN,
	TASK_ITEM_ICON_WIDTH,
	TASK_ITEM_MIDDLE_MARGIN,
	TASK_ITEM_MINIMUM_TRIM,
	TASK_ITEM_TEXT_ICON_SPACING,
	TASK_ITEM_TIMELINE_BACKGROUND_COLOR,
	TIMELINE_BAR_BORDER_RADIUS,
	TIMELINE_BAR_PADDING_X,
	TIMELINE_GREY_TASK_BAR_COLOR,
	TOOLTIP_DISPLAY_DELAY_MS,
	VISUALIZATION_MODE,
} from '../../canvas-timeline/canvas_timeline_util';
import Util from '../../../../forecast-app/shared/util/util';
import {SCHEDULING_VIEW} from '../../../../constants';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import Color from 'color';
import {getRecalculationInterval, recalculateGroupHeatmapCache} from '../../heatmap/HeatmapLogic';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import IDManager, {NO_ROLE} from '../../IDManager';
import {shiftDependencyChain, updateTask} from '../../../scheduling/scheduling_mutations';
import ComposeManager from '../../ComposeManager';
import ProjectGroup from '../groups/project_group';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import EventManager from '../../EventManager';
import {getSubTasks} from '../../projects-scheduling/projects_scheduling_util';
import DataManager from '../../DataManager';
import {handleMutationSuccess} from '../../../../containers/modal/placeholder/PlaceholderAllocationUtils';
import handleDisplayDefaultWarning from '../../actions/handle_display_default_warning';
import {isTaskItem} from '../../SchedulingUtils';
import {SubTaskIcon, TaskIcon} from 'web-components/icons';
import React from 'react';
import Person from '../../../../forecast-app/shared/components/person/person';
import {profilePicSrc} from '../../../../directApi';
import {TOOLTIP_MAX_WIDTH} from '../../constants';
import {getProjectIndicatorString} from '../../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';

class TaskItem extends Item {
	constructor(pageComponent, data) {
		super(pageComponent, ITEM_TYPE.TASK, data);
		this.updateData(data);
	}

	updateData(data, updateItem = false) {
		const {schedulingView} = this.pageComponent.props;
		const {color, task} = data;

		const completionFont = '600 11px ' + Util.getFontFamily();

		const textColor = GROUP_SECTION_TEXT_GREY_DARK;
		const projectColor = color?.toLowerCase() === '#e6e6ed' ? TIMELINE_GREY_TASK_BAR_COLOR : color;
		const backgroundColor = task.done
			? TASK_ITEM_DONE_BACKGROUND_COLOR
			: schedulingView === SCHEDULING_VIEW.PROJECTS
			? TASK_ITEM_TIMELINE_BACKGROUND_COLOR
			: getTaskProjectBackgroundColor(projectColor);
		const isProjectColorDark = Color(projectColor).isDark();

		const updatedData = {
			...data,
			phaseId: data.phaseId,
			textColor,
			projectColor,
			backgroundColor,
			completionFont,
			isProjectColorDark,
		};

		if (updateItem) {
			this.updateWithData(updatedData);
		} else {
			this.data = updatedData;
		}
	}

	getTooltipData() {
		const {intl, schedulingView} = this.pageComponent.props;
		const globalData = this.pageComponent.getData();
		const {formatMessage} = intl;
		const {data} = this;
		const {task} = data;
		const {project} = data;
		const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;

		const parentTask = DataManager.getTaskById(this.pageComponent, task.parentTaskId);
		const subTaskCount = DataManager.getSubTasksByParentTaskId(this.pageComponent, task.id)?.length;
		const client = DataManager.getClientById(this.pageComponent, project?.clientId);
		const clientName = client?.name;
		const isInHours = project?.estimationUnit === 'HOURS';
		let remainingTimePerAssigned =
			task.timeLeft === 0 || task.assignedPersons.length === 0 ? 0 : task.timeLeft / task.assignedPersons.length;
		const estimate = task.estimateForecast / 60;

		const canvasTooltipData = {
			maxWidth: TOOLTIP_MAX_WIDTH.TASK_ITEM,
		};

		// items
		canvasTooltipData.items = [];

		// titles and icons
		if (parentTask) {
			canvasTooltipData.title = formatMessage({id: 'common.sub_task'});
			canvasTooltipData.titleIcon = (
				<>
					<SubTaskIcon />
				</>
			);
		} else {
			canvasTooltipData.title = formatMessage({id: 'common.task'});
			canvasTooltipData.titleIcon = (
				<>
					<TaskIcon />
				</>
			);
			if (subTaskCount) {
				canvasTooltipData.subTitle = `${subTaskCount} ${formatMessage({id: 'common.sub_tasks'})}`;
			}
		}

		// task name
		canvasTooltipData.items.push({
			subTitle: formatMessage({id: 'common.task_name'}),
			values: [`T${task.companyTaskId}${task.name ? ' - ' + task.name : ''}`],
		});

		// parent task name
		if (parentTask) {
			canvasTooltipData.items.push({
				subTitle: formatMessage({id: 'common.parent_task_name'}),
				values: [`T${parentTask.companyTaskId}${parentTask.name ? ' - ' + parentTask.name : ''}`],
			});
		}

		// client name
		if (clientName) {
			canvasTooltipData.items.push({
				subTitle: formatMessage({id: 'common.client'}),
				values: [clientName],
			});
		}

		// project name
		if (isPeopleScheduling && project) {
			canvasTooltipData.items.push({
				subTitle: formatMessage({id: 'common.project'}),
				values: [`${getProjectIndicatorString(project.companyProjectId, project.customProjectId)} - ${project.name}`],
			});
		}

		// dates
		canvasTooltipData.items.push({
			subTitle: formatMessage({id: 'common.date'}),
			values: [
				`${task.startDay} ${formatMessage({
					id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[task.startMonth - 1],
				})} ${task.startYear} - ${task.deadlineDay} ${formatMessage({
					id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[task.deadlineMonth - 1],
				})} ${task.deadlineYear}`,
			],
		});

		// estimate
		if (estimate !== 0) {
			canvasTooltipData.items.push({
				subTitle: formatMessage({id: 'common.total_estimate'}),
				values: [
					isInHours
						? Util.convertMinutesToFullHour(estimate * 60, intl, true)
						: formatMessage({id: 'common.x_points'}, {points: estimate}),
				],
			});
		}

		// remaining time per assigned
		if (remainingTimePerAssigned) {
			canvasTooltipData.items.push({
				subTitle: formatMessage({id: 'task_detail_box.remaining_per_assignee'}),
				values: [
					isInHours
						? Util.convertMinutesToFullHour(remainingTimePerAssigned, intl, true)
						: formatMessage({id: 'common.x_points'}, {points: remainingTimePerAssigned}),
				],
			});
		}

		// assigned persons
		if (task.assignedPersons.length > 0) {
			const assignedPersons = task.assignedPersons
				.map(assignedPersonId => globalData.persons.find(person => person.id === assignedPersonId))
				.map(person => {
					return person ? (
						<Person
							key={person.id}
							name={`${person.firstName} ${person.lastName}`}
							showName={false}
							showRole={false}
							imageSize={'medium'}
							imageSrc={profilePicSrc(person.profilePictureId)}
						/>
					) : null;
				});

			canvasTooltipData.items.push({
				subTitle: formatMessage({id: 'task_detail_box.assigned_persons'}),
				flexDirection: 'row',
				values: assignedPersons,
			});
		}

		return canvasTooltipData;
	}

	onMouseEnter(eventData, event) {
		EventManager.onDependencyMouseEnterOrLeave(this.pageComponent, this);

		if (hasFeatureFlag('schedule_use_new_tooltip')) {
			const {timeline} = this.pageComponent;
			const offsetTop = timeline.getCanvasOffset().top;
			const offsetLeft = timeline.getCanvasOffset().left;
			const canvasTooltipData = this.getTooltipData();

			this.pageComponent.setState({
				canvasTooltipX: event.clientX - offsetLeft,
				canvasTooltipY: event.clientY - offsetTop,
				canvasTooltipData,
				showCanvasTooltip: false,
			});

			setTimeout(() => {
				this.pageComponent.setState({
					showCanvasTooltip: true,
					canvasTooltipX: event.clientX - offsetLeft,
					canvasTooltipY: event.clientY - offsetTop,
				});
			}, TOOLTIP_DISPLAY_DELAY_MS);
		} else {
			const {task} = this.data;
			const {hoverX, hoverY} = this.pageComponent.state;
			const {clientX, clientY} = event;

			this.pageComponent.setState({
				hoverX: clientX,
				hoverY: clientY,
				showDetailBox: false,
			});

			setTimeout(() => {
				this.pageComponent.setState({
					detailBoxLeft: eventData.x,
					detailBoxY: eventData.y,
					detailBoxTop: eventData.y - 2,
					detailBoxData: {task, allocation: null},
				});

				if (hoverX === clientX && hoverY === clientY) {
					this.pageComponent.setState({showDetailBox: true, detailBoxX: clientX});
				}
			}, TOOLTIP_DISPLAY_DELAY_MS);
		}
	}

	onMouseLeave() {
		EventManager.onDependencyMouseEnterOrLeave(this.pageComponent, this);

		if (hasFeatureFlag('schedule_use_new_tooltip')) {
			this.pageComponent.setState({
				showCanvasTooltip: false,
				canvasTooltipData: null,
				canvasTooltipX: null,
				canvasTooltipY: null,
			});
		} else {
			this.pageComponent.setState({showDetailBox: false, hoverX: null, hoverY: null, detailBoxData: null});
		}
	}

	onMouseMove(eventData, event) {
		if (!hasFeatureFlag('schedule_use_new_tooltip')) {
			const {hoverX, hoverY, detailBoxData} = this.pageComponent.state;
			const {clientX, clientY} = event;

			this.pageComponent.setState({
				hoverX: clientX,
				hoverY: clientY,
				showDetailBox: false,
			});

			setTimeout(() => {
				if (hoverX === clientX && hoverY === clientY && detailBoxData) {
					const {task} = this.data;
					this.pageComponent.setState({showDetailBox: true, detailBoxX: clientX, detailBoxData: {task}});
				}
			}, TOOLTIP_DISPLAY_DELAY_MS);
		}

		this.onAutoSchedulingMouseMove(eventData, event);
	}

	onAutoSchedulingMouseMove(eventData, event) {
		const {schedulingView} = this.pageComponent.props;

		if (schedulingView === SCHEDULING_VIEW.PROJECTS && isSimulationMode()) {
			const {task} = this.data;

			// Check if the task has a person assigned
			if (task?.autoSchedulingPerson) {
				const {isOverAllocated} = task;
				const {x, width} = eventData;
				const {clientX} = event;

				// Check if the user is hovering over the assigned picture
				const itemEnd = x + width + Math.min(this.x, 0);
				if (clientX >= itemEnd && clientX <= itemEnd + AUTO_SCHEDULING_PERSON_WIDTH) {
					const {tasks} = this.pageComponent.getData();

					tasks.forEach(task => {
						task.showImageHover = false;
					});

					task.showImageHover = true;

					this.pageComponent.redrawCanvasTimeline({preventFiltering: true});
					this.pageComponent.setState({assignedPersonHoverData: {data: eventData, overAllocated: false}});
				}
				// Check if the user is hovering over the overallocation warning icon
				else if (isOverAllocated && clientX >= x + width + AUTO_SCHEDULING_PERSON_WIDTH - 4) {
					const {assignedPersonHoverData} = this.pageComponent.state;
					task.showImageHover = false;

					if (!assignedPersonHoverData || !assignedPersonHoverData.overAllocated) {
						this.pageComponent.redrawCanvasTimeline({preventFiltering: true});
						this.pageComponent.setState({assignedPersonHoverData: {data: eventData, overAllocated: true}});
					}
				} else {
					const {assignedPersonHoverData} = this.pageComponent.state;
					const {tasks} = this.pageComponent.getData();

					task.showImageHover = false;

					if (assignedPersonHoverData) {
						tasks.forEach(task => {
							task.showImageHover = false;
						});

						this.pageComponent.redrawCanvasTimeline({preventFiltering: true});
						this.pageComponent.setState({assignedPersonHoverData: null});
					}
				}
			}
		}
	}

	onMoving(onMovingItem, group, startDifference, endDifference, dragData, movedDays) {
		const {schedulingView} = this.pageComponent.props;

		if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
			const {isDraggingWholeDependencyChainModeOn, items, dependencies} = this.pageComponent.state;
			const {task} = this.data;

			// Find ids of sub tasks to move with task.
			const subTasksToMoveIds = getSubTasks(this.pageComponent, task, true).map(item => item.id);
			const dependencyChainTaskIdSet = new Set();
			const isDependencyMove = isDraggingWholeDependencyChainModeOn || this.pageComponent.isShiftDown;
			const isDragPointCenter = dragData.dragPoint === ITEM_DRAG_POINT.CENTER;

			if (isDependencyMove) {
				if (isDragPointCenter) {
					const taskDependency = dependencies.find(
						dependency => dependency.thisDependsOnTaskId === task.id || dependency.taskIdDependsOnThis === task.id
					);

					if (taskDependency) {
						this.pageComponent.taskItemMoveTotalDayDifference =
							(this.pageComponent.taskItemMoveTotalDayDifference || 0) + movedDays;

						dependencyChainTaskIdSet.add(taskDependency.thisDependsOnTaskId);
						dependencyChainTaskIdSet.add(taskDependency.taskIdDependsOnThis);

						let previousTaskIdCount = 0;
						let currentTaskIdCount = 2;
						while (previousTaskIdCount !== currentTaskIdCount) {
							for (const dependency of dependencies.filter(
								dependency =>
									dependencyChainTaskIdSet.has(dependency.thisDependsOnTaskId) ||
									dependencyChainTaskIdSet.has(dependency.taskIdDependsOnThis)
							)) {
								dependencyChainTaskIdSet.add(dependency.thisDependsOnTaskId);
								dependencyChainTaskIdSet.add(dependency.taskIdDependsOnThis);
							}

							previousTaskIdCount = currentTaskIdCount;
							currentTaskIdCount = dependencyChainTaskIdSet.size;
						}

						const itemsToMove = items.filter(
							item =>
								item.itemType === ITEM_TYPE.TASK &&
								!item.data.task.done &&
								dependencyChainTaskIdSet.has(item.data.task.id) &&
								!subTasksToMoveIds.includes(item.data.task.id) &&
								item !== this
						);

						const hideWeekend = this.pageComponent.timeline.isHideWeekendsSelected();
						for (const item of itemsToMove) {
							//Do not move dragged task since it is going to be already moved due to default behavior
							const {startDifference, endDifference} = moveItem(item, movedDays, hideWeekend);
							item.shift(movedDays, startDifference, endDifference);

							// Clear the cache for affected users
							this.pageComponent.deleteTimelineHeatmapCacheForTaskAssignees(item, startDifference, endDifference);
							this.pageComponent.updateTaskDataDates(
								item,
								item.startDate,
								item.endDate,
								startDifference,
								endDifference
							);
						}
					}
				}
			}

			// Clear the cache for affected users
			this.pageComponent.deleteTimelineHeatmapCacheForTaskAssignees(this, startDifference, endDifference);

			// Only move task once, either it's moved by a dependency or as a subTask, not both
			const subTaskItemsToMove = items.filter(
				item => ITEM_TYPE.TASK === item.itemType && subTasksToMoveIds.includes(item.data.task.id)
			);

			for (const itemToMove of subTaskItemsToMove) {
				itemToMove.startDate += startDifference;
				itemToMove.endDate += endDifference;

				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					DataManager.moveItemTemporarily(this.pageComponent, itemToMove);
				}

				// Clear the cache for affected users
				this.pageComponent.deleteTimelineHeatmapCacheForTaskAssignees(itemToMove, startDifference, endDifference);
				this.pageComponent.updateTaskDataDates(
					itemToMove,
					itemToMove.startDate,
					itemToMove.endDate,
					startDifference,
					endDifference
				);
			}

			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				DataManager.moveItemTemporarily(this.pageComponent, this);
			}

			return !isDependencyMove || !isDragPointCenter;
		} else if (schedulingView === SCHEDULING_VIEW.PEOPLE) {
			const {data, items, schedulingOptions} = this.pageComponent.state;
			const {roles} = data;

			const {task} = this.data;

			const isCombinationMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);

			const recalculateInterval = getRecalculationInterval(this, startDifference, endDifference);
			const noRoleCacheId = hasFeatureFlag('scheduling_recalculation_tree') ? NO_ROLE : null;

			if (dragData.initialGroup && dragData.initialGroup.isInCollapsableSection) {
				for (const role of roles) {
					recalculateGroupHeatmapCache(this.pageComponent, role.id, recalculateInterval);
				}

				recalculateGroupHeatmapCache(this.pageComponent, noRoleCacheId, recalculateInterval);
			} else {
				recalculateGroupHeatmapCache(this.pageComponent, task.roleId || noRoleCacheId, recalculateInterval);
			}

			for (const personId of task.assignedPersons) {
				recalculateGroupHeatmapCache(this.pageComponent, personId, recalculateInterval);
				if (isCombinationMode) {
					const projectGroupId = IDManager.getProjectGroupIdForTask(this.pageComponent, personId, task);
					recalculateGroupHeatmapCache(this.pageComponent, projectGroupId, recalculateInterval);
				}
			}

			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				DataManager.moveItemTemporarily(this.pageComponent, this);
			}

			// Dragging
			if (dragData.initialGroup) {
				let hoveredPersonGroup = group;

				while (hoveredPersonGroup.parentGroup) {
					hoveredPersonGroup = hoveredPersonGroup.parentGroup;
				}

				const personId = hoveredPersonGroup.id;
				recalculateGroupHeatmapCache(this.pageComponent, personId, recalculateInterval);
				if (isCombinationMode) {
					const projectGroupId = IDManager.getProjectGroupIdForTask(this.pageComponent, personId, task);
					recalculateGroupHeatmapCache(this.pageComponent, projectGroupId, recalculateInterval);
				}
			}

			if (!dragData.initialTaskStartDate) {
				dragData.initialTaskStartDate = this.startDate;
				dragData.initialTaskEndDate = this.endDate;
			}

			const startDate = dragData.newStartDate || this.startDate;
			const endDate = dragData.newEndDate || this.endDate;
			const startDateMoment = getMomentFromCanvasTimelineDate(startDate);
			const endDateMoment = getMomentFromCanvasTimelineDate(endDate);
			task.startYear = startDateMoment.year();
			task.startMonth = startDateMoment.month() + 1;
			task.startDay = startDateMoment.date();
			task.endYear = endDateMoment.year();
			task.endMonth = endDateMoment.month() + 1;
			task.endDay = endDateMoment.date();

			for (const item of items) {
				if (isTaskItem(item) && item.data.task.id === task.id && item !== onMovingItem) {
					item.updateData(
						{
							...item.data,
							task,
							startDate,
							endDate,
						},
						true
					);
				}
			}

			return true;
		}

		return false;
	}

	onMoveEnd(item, group, initialGroup, dragData) {
		if (!group) {
			return;
		}

		const {schedulingView} = this.pageComponent.props;
		const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;
		const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;

		if (isProjectScheduling) {
			const {taskItemMoveTotalDayDifference, timeline} = this.pageComponent;
			const {task} = this.data;

			if (taskItemMoveTotalDayDifference) {
				const {dependencies} = this.pageComponent.state;

				// Find ids of subTasks since they should not be moved by dependency moving
				const subTasksToMoveIds = getSubTasks(this.pageComponent, task, true).map(item => item.id);

				const dependencyChainTaskIdSet = new Set();
				dependencyChainTaskIdSet.add(item.groupId);

				let previousTaskIdCount = 0;
				let currentTaskIdCount = 1;
				while (previousTaskIdCount !== currentTaskIdCount) {
					for (const dependency of dependencies.filter(
						dependency =>
							(dependencyChainTaskIdSet.has(dependency.thisDependsOnTaskId) ||
								dependencyChainTaskIdSet.has(dependency.taskIdDependsOnThis)) &&
							!subTasksToMoveIds.includes(dependency.thisDependsOnTaskId) &&
							!subTasksToMoveIds.includes(dependency.taskIdDependsOnThis)
					)) {
						dependencyChainTaskIdSet.add(dependency.thisDependsOnTaskId);
						dependencyChainTaskIdSet.add(dependency.taskIdDependsOnThis);
					}

					previousTaskIdCount = currentTaskIdCount;
					currentTaskIdCount = dependencyChainTaskIdSet.size;
				}

				shiftDependencyChain({
					ids: dependencyChainTaskIdSet,
					dayDelta: taskItemMoveTotalDayDifference,
					isWeekendHidden: timeline.isHideWeekendsSelected(),
				});

				this.pageComponent.taskItemMoveTotalDayDifference = undefined;
				// TODO: Prevent this too in simulation mode?
			} else {
				this.updateTaskDates();
			}
		} else if (isPeopleScheduling) {
			const {items} = this.pageComponent.state;
			const {intl} = this.pageComponent.props;
			const {formatMessage} = intl;

			const assignedPersonIdSet = new Set();

			let roleId = this.data.task.roleId;

			const duplicateItemIndex = items.findIndex(
				item =>
					item !== this &&
					item.itemType === ITEM_TYPE.TASK &&
					item.groupId === group.id &&
					item.data.task.id === this.data.task.id
			);

			if (duplicateItemIndex >= 0) {
				items.splice(duplicateItemIndex, 1);
			}

			const sendMutation = () => {
				const assignedPersons = Array.from(assignedPersonIdSet);

				const startDate = getMomentFromCanvasTimelineDate(this.startDate);
				const endDate = getMomentFromCanvasTimelineDate(this.endDate);

				updateTask(
					{
						ids: [this.data.task.id],
						startYear: startDate.year(),
						startMonth: startDate.month() + 1,
						startDay: startDate.date(),
						deadlineYear: endDate.year(),
						deadlineMonth: endDate.month() + 1,
						deadlineDay: endDate.date(),
						assignedPersons,
						roleId,
					},
					response => {
						Util.dispatchScheduleEvent(response);

						const destinationGroupParent = dragData.destinationGroup?.parentGroup;
						const initialGroupParent = dragData.initialGroup?.parentGroup;
						const movingToNewGroup =
							destinationGroupParent &&
							initialGroupParent &&
							dragData.destinationGroup.parentGroup.id !== dragData.initialGroup.parentGroup.id;

						// expand the group that the task was moved to
						if (movingToNewGroup) {
							if (dragData.destinationGroup.parentGroup) {
								dragData.destinationGroup.parentGroup.setExpanded(true);
							}

							dragData.destinationGroup.setExpanded(true);
						}
					}
				);

				this.data.task.startYear = startDate.year();
				this.data.task.startMonth = startDate.month() + 1;
				this.data.task.startDay = startDate.date();
				this.data.task.deadlineYear = endDate.year();
				this.data.task.deadlineMonth = endDate.month() + 1;
				this.data.task.deadlineDay = endDate.date();
				if (!hasFeatureFlag('improving_heatmap_frontend_performance')) {
					this.data.task.assignedPersons = assignedPersons;
				}
			};

			const unassignedGroupTypes = [
				GROUP_TYPE.PEOPLE_SCHEDULING_UNASSIGNED_ROLE,
				GROUP_TYPE.PEOPLE_SCHEDULING_UNASSIGNED_ROLE_TASK,
			];

			if (unassignedGroupTypes.includes(group.groupType)) {
				this.data.task.roleId = group.data.roleId;
				roleId = group.data.roleId;

				if (this.data.task.assignedPersons.length > 1) {
					const revertMove = () => {
						this.groupId = initialGroup.id;

						for (const item of items) {
							if (item.itemType === ITEM_TYPE.TASK && item.data.task.id === this.data.task.id) {
								const newTaskItemData = ComposeManager.composeTaskItem(
									this.pageComponent,
									item.data.task,
									item.groupId.split('-')[0]
								);

								if (!newTaskItemData) continue;

								const {startDate, endDate, taskFragmentArray, taskDayFragmentArray, task, draggableArea} =
									newTaskItemData;

								item.startDate = startDate;
								item.endDate = endDate;
								item.draggableArea = draggableArea;
								item.data.taskFragmentArray = taskFragmentArray;
								item.data.taskDayFragmentArray = taskDayFragmentArray;
								item.data.task = task;
							}
						}

						this.pageComponent.redrawCanvasTimeline({preventFiltering: false});
					};

					const warningMessage = formatMessage(
						{id: 'scheduling.warning_assigned_to_many'},
						{
							taskName: `"T${this.data.task.companyTaskId} ${this.data.task.name}"`,
						}
					);

					handleDisplayDefaultWarning(this.pageComponent, warningMessage, revertMove, sendMutation);
				} else {
					sendMutation();
				}
			} else {
				let targetGroup = group;

				if (targetGroup.groupType === GROUP_TYPE.PERSON) {
					const project = DataManager.getProjectById(this.pageComponent, this.data.task.projectId);
					const projectGroup = project.projectGroupId
						? DataManager.getProjectGroupById(this.pageComponent, project.projectGroupId)
						: null;

					const createdProjectGroup = new ProjectGroup(
						this.pageComponent,
						ComposeManager.composeProjectGroup(
							this.pageComponent,
							group.data,
							null,
							projectGroup ? null : project,
							projectGroup
						)
					);
					this.groupId = createdProjectGroup.id;
				}

				for (const personId of this.data.task.assignedPersons) {
					assignedPersonIdSet.add(personId);
				}

				if (initialGroup && initialGroup !== targetGroup) {
					assignedPersonIdSet.delete(initialGroup.id.split('-')[0]);

					if (!unassignedGroupTypes.includes(targetGroup.groupType)) {
						assignedPersonIdSet.add(targetGroup.id.split('-')[0]);
					}
				}

				sendMutation();
			}
		}
	}

	updateTaskDates() {
		const {simulationMode} = this.pageComponent.state;
		const {task} = this.data;

		// Prevent default behaviour if we are in simulation mode
		const startDate = getMomentFromCanvasTimelineDate(this.startDate);
		const endDate = getMomentFromCanvasTimelineDate(this.endDate);

		task.startYear = startDate.year();
		task.startMonth = startDate.month() + 1;
		task.startDay = startDate.date();
		task.deadlineYear = endDate.year();
		task.deadlineMonth = endDate.month() + 1;
		task.deadlineDay = endDate.date();
		task.startFrom = null;
		task.deadlineFrom = null;

		this.pageComponent.updateSimulationChangeMap('tasks', this);

		if (!simulationMode) {
			updateTask(
				{
					ids: [this.groupId],
					startYear: startDate.year(),
					startMonth: startDate.month() + 1,
					startDay: startDate.date(),
					deadlineYear: endDate.year(),
					deadlineMonth: endDate.month() + 1,
					deadlineDay: endDate.date(),
				},
				handleMutationSuccess
			);
		}
	}

	updateFreeDragData(dragData) {
		if (!this.isDisabled()) {
			EventManager.updateFreeDragData(this, dragData);
		}
	}

	onMoveAttempt() {
		const {schedulingView} = this.pageComponent.props;

		if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
			const {project} = this.data;

			if (isProjectDoneOrHalted(project.status)) {
				EventManager.onDoneOrHaltedMoveAttempt(this.pageComponent);
			}
		}
	}

	onClick() {
		const {showTaskModal, history} = this.pageComponent.props;
		const {task} = this.data;

		showTaskModal(task.companyTaskId, history);
	}

	onMoveAnimationEnd() {
		return true;
	}

	onDraw(x, y, width, height) {
		const {schedulingView} = this.pageComponent.props;

		if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
			const {task} = this.data;

			if (task) {
				// Add cursor pointer to the person image
				if (task.autoSchedulingPerson) {
					interactionManager.addCursorStyleArea(
						x + GROUP_SECTION_WIDTH + width,
						y,
						AUTO_SCHEDULING_PERSON_WIDTH,
						height,
						CURSOR.POINTER
					);
				}

				this.pageComponent.visibleTaskData.push({id: task.id, task, x, y, width, height});
			}
		}
	}

	drawCompletion(canvasContext, x, y, width) {
		const {data, height} = this;
		const {projectColor, faded} = data;

		if (this.showCompletion) {
			//The last path drawn is the shape of item, use that as a clipping path to draw progress
			canvasContext.save();
			canvasContext.clip();
			const completionRecOptions = {
				backgroundColor: projectColor,
				backgroundOpacity: faded ? 0.2 : 1,
			};
			this.completionWidth = (width * this.completion) / 100;
			drawRectangle(canvasContext, x, y, this.completionWidth, height, completionRecOptions);
			canvasContext.restore();
		}
	}

	drawAutoSchedulingOverlay(canvasContext, x, y, width) {
		const {data, height} = this;
		const {task, onPersonImageLoad} = data;

		if (task.autoSchedulingPerson) {
			const person = task.autoSchedulingPerson;
			const cachedImage = cacheManager.get('personImage', person.id);
			let img = null;

			if (cachedImage) {
				img = cachedImage;
			} else if (person.profilePictureId) {
				img = new Image();
				img.crossOrigin = 'use-credentials';
				img.onload = () => {
					cacheManager.set('personImage', person.id, img);
					onPersonImageLoad();
				};
				img.src = profilePicSrc(person.profilePictureId);
			} else {
				const initialsXCoor = x + width;
				drawHexagon(canvasContext, initialsXCoor, y, height, '#6e0fea', 1);

				canvasContext.font = '500 11px ' + Util.getFontFamily();
				canvasContext.fillStyle = 'white';
				canvasContext.textAlign = 'center';
				canvasContext.fillText(person.initials.toUpperCase(), initialsXCoor + 12, y + 18);
				canvasContext.textAlign = 'start';
			}
			img && canvasContext.drawImage(img, x + width, y, AUTO_SCHEDULING_PERSON_WIDTH, height);

			const warningIconData = {
				size: 20,
				offsetX: AUTO_SCHEDULING_PERSON_WIDTH - 4,
				offsetY: 3,
			};

			if (task.isOverAllocated) {
				drawHexagon(canvasContext, x + width, y + 1, height - 2, '', '', '#d0021b');
				canvasContext.drawImage(
					cacheManager.getCommonImage(COMMON_IMAGE.WARNING_ICON_RED),
					x + width + warningIconData.offsetX,
					y + warningIconData.offsetY,
					warningIconData.size,
					warningIconData.size
				);
			}

			if (task.showImageHover) {
				drawHexagon(canvasContext, x + width, y, height, '#000', 0.5);
				canvasContext.strokeStyle = '#fff';
				canvasContext.lineWidth = 2;
				canvasContext.globalAlpha = 1;

				canvasContext.beginPath();
				canvasContext.moveTo(x + width + 16, y + 9);
				canvasContext.lineTo(x + width + 8, y + height - 9);
				canvasContext.closePath();
				canvasContext.stroke();

				canvasContext.beginPath();
				canvasContext.moveTo(x + width + 8, y + 9);
				canvasContext.lineTo(x + width + 16, y + height - 9);
				canvasContext.closePath();
				canvasContext.stroke();
			}
		}
	}

	drawTaskNameAndIcons(canvasContext, x, y) {
		const {data} = this;
		const {task, isHourEstimated, isProjectColorDark, textColor, completionFont} = data;
		const {intl, schedulingView} = this.pageComponent.props;
		const {formatMessage} = intl;

		const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;

		// task completion / estimate
		let taskStatusString;
		if (isPeopleScheduling) {
			// estimate
			if (isHourEstimated) {
				taskStatusString = Util.convertMinutesToFullHour(task.estimateForecast, intl);
			} else {
				taskStatusString = formatMessage({id: 'common.x_points'}, {points: task.estimateForecast});
			}
		} else {
			// completion
			taskStatusString = this.completion === 100 ? '' : `${this.completion || 0}%`;
		}

		const taskStatusStringWidth = canvasContext.measureText(taskStatusString).width;

		let iconWidthNeeded = 0;
		if (task.highPriority) {
			iconWidthNeeded += TASK_ITEM_ICON_WIDTH;
		}
		if (task.bug) {
			iconWidthNeeded += TASK_ITEM_ICON_WIDTH;
		}
		if (task.blocked) {
			iconWidthNeeded += TASK_ITEM_ICON_WIDTH;
		}

		let taskName = '';
		if (task) {
			taskName = task.name;
		}

		const taskNameFont = '500 11px ' + Util.getFontFamily();
		canvasContext.font = taskNameFont;
		const taskNameStringWidth = canvasContext.measureText(taskName).width;

		const rightSize = iconWidthNeeded + (taskStatusStringWidth + this.textMargin * 2);
		const showRight = this.visibleItemWidth - (rightSize + TASK_ITEM_MIDDLE_MARGIN) > taskNameStringWidth;

		if (!this.showTaskName && !showRight) {
			return;
		}

		// task Name
		const trimmedTaskName = getTrimmedText(canvasContext, taskName, this.textTrim);
		const taskNameX = this.textMargin + this.visibleLeftX;
		const taskNameY = y + 17;
		if (this.showTaskName) {
			canvasContext.font = taskNameFont;
			if (this.showCompletion && isProjectColorDark) {
				fillText2Colors(
					canvasContext,
					trimmedTaskName,
					taskNameX,
					taskNameY,
					x + this.completionWidth,
					'#FFFFFF',
					textColor
				);
			} else {
				canvasContext.fillStyle = textColor;
				canvasContext.fillText(trimmedTaskName, taskNameX, taskNameY);
			}
		}

		if (showRight) {
			let iconX = this.visibleRightX;

			let marginRight = TIMELINE_BAR_PADDING_X;
			if (!task.hideProgress && !task.done && this.completion < 100) {
				marginRight += taskStatusStringWidth;
			}

			if (task.highPriority) {
				canvasContext.drawImage(
					cacheManager.getCommonImage(COMMON_IMAGE.TASK_HIGH_PRIORITY_BLACK),
					iconX - TASK_ITEM_ICON_WIDTH - marginRight,
					y + 7
				);
				iconX -= TASK_ITEM_ICON_WIDTH + TASK_ITEM_ICON_MARGIN;
			}

			if (task.bug) {
				canvasContext.drawImage(
					cacheManager.getCommonImage(COMMON_IMAGE.TASK_BUG_BLACK),
					iconX - TASK_ITEM_ICON_WIDTH - marginRight,
					y + 6
				);
				iconX -= TASK_ITEM_ICON_WIDTH + TASK_ITEM_ICON_MARGIN;
			}

			if (task.blocked) {
				canvasContext.drawImage(
					cacheManager.getCommonImage(COMMON_IMAGE.TASK_BLOCKED_BLACK),
					iconX - TASK_ITEM_ICON_WIDTH - marginRight,
					y + 6
				);
			}

			// task status
			const displayProgress = !task.hideProgress && this.completion < 100;
			if (isPeopleScheduling || displayProgress) {
				canvasContext.fillStyle = textColor;
				canvasContext.font = completionFont;
				canvasContext.fillText(
					taskStatusString,
					this.visibleRightX - this.textMargin - taskStatusStringWidth,
					taskNameY
				);
			}
		}
	}

	drawDoneCheckmark(canvasContext, x, y, width) {
		const {data, height} = this;
		const {task} = data;

		const shouldShowDoneIcon = this.visibleItemWidth - this.checkmarkMargin * 2 > TASK_ITEM_CHECKMARK_SIZE;
		if (task.done && shouldShowDoneIcon) {
			const checkmarkOffsetX = this.showTaskName ? this.checkmarkMargin : width / 2 - TASK_ITEM_CHECKMARK_SIZE / 2;
			canvasContext.drawImage(
				cacheManager.getCommonImage(COMMON_IMAGE.TASK_DONE_GREEN_CHECKMARK),
				x + checkmarkOffsetX,
				y + height / 2 - TASK_ITEM_CHECKMARK_SIZE / 2,
				TASK_ITEM_CHECKMARK_SIZE,
				TASK_ITEM_CHECKMARK_SIZE
			);
		}
	}

	draw(canvasContext, x, y, width, canvasWidth) {
		const {data, height} = this;
		const {task, backgroundColor, expansionMap} = data;

		const parentTaskWithNoEstimateAndTimeRegs = task.estimateForecast === 0 && task.hasChildren;
		const taskProgress =
			(expansionMap?.get(task.id) === true && !parentTaskWithNoEstimateAndTimeRegs) ||
			task.summarizedProgress === undefined
				? task.progress
				: task.summarizedProgress;
		this.completion = taskProgress < 0 ? 0 : Math.round(taskProgress);
		this.showCompletion = !task.hideProgress && this.completion !== 0 && (this.completion !== 100 || !task.done);

		// background
		drawRectangle(canvasContext, x, y, width, height, {
			backgroundColor,
			borderRadius: TIMELINE_BAR_BORDER_RADIUS,
		});

		this.drawCompletion(canvasContext, x, y, width);

		this.drawAutoSchedulingOverlay(canvasContext, x, y, width);

		this.checkmarkMargin = task.done ? TIMELINE_BAR_PADDING_X : 0;
		this.textMargin = task.done
			? this.checkmarkMargin + TASK_ITEM_CHECKMARK_SIZE + TASK_ITEM_TEXT_ICON_SPACING
			: TIMELINE_BAR_PADDING_X;

		// Visible item stats
		this.visibleRightX = x + width > canvasWidth ? canvasWidth : x + width;
		this.visibleLeftX = x > 0 ? x : 0;
		this.visibleItemWidth = this.visibleRightX - this.visibleLeftX;

		this.textTrim = this.visibleItemWidth - this.textMargin * 2;
		this.showTaskName = this.textTrim > TASK_ITEM_MINIMUM_TRIM;

		// task done green checkmark icon
		this.drawDoneCheckmark(canvasContext, x, y, width);

		this.drawTaskNameAndIcons(canvasContext, x, y);
	}
}

export default TaskItem;
