/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type ExpenseCategoryFinancialBreakdownType = "EXPENSE_CATEGORY" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DetailsExpenseCategoryLoader_viewer$ref: FragmentReference;
declare export opaque type DetailsExpenseCategoryLoader_viewer$fragmentType: DetailsExpenseCategoryLoader_viewer$ref;
export type DetailsExpenseCategoryLoader_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string
  |},
  +project: ?{|
    +rateCard: ?{|
      +currency: ?string
    |},
    +expenseCategoryFinancialBreakdown: $ReadOnlyArray<?{|
      +breakdownType: ?ExpenseCategoryFinancialBreakdownType,
      +expenseCategory: ?{|
        +id: string,
        +name: ?string,
      |},
      +financialNumbers: ?{|
        +baselineRevenue?: ?number,
        +baselineCost?: ?number,
        +baselineProfit?: ?number,
        +billableActualTimeAndExpenses?: ?number,
        +actualCost?: ?number,
        +actualProfit?: ?number,
        +billablePlannedTimeAndExpenses?: ?number,
        +plannedCost?: ?number,
        +plannedProfit?: ?number,
        +billableForecastTimeAndExpensesToComplete?: ?number,
        +forecastCostToComplete?: ?number,
        +forecastProfitToComplete?: ?number,
        +billableTotalTimeAndExpensesAtCompletion?: ?number,
        +totalCostAtCompletion?: ?number,
        +totalProfitAtCompletion?: ?number,
        +planVsBillableActualTimeAndExpenses?: ?number,
        +planVsActualCost?: ?number,
        +planVsActualProfit?: ?number,
        +planVsTotalBillableTimeAndExpensesAtCompletion?: ?number,
        +planVsTotalCostAtCompletion?: ?number,
        +planVsTotalProfitAtCompletion?: ?number,
        +baselineVsBillableActualTimeAndExpenses?: ?number,
        +baselineVsActualCost?: ?number,
        +baselineVsActualProfit?: ?number,
        +baselineVsBillableTotalTimeAndExpensesAtCompletion?: ?number,
        +baselineVsTotalCostAtCompletion?: ?number,
        +baselineVsTotalProfitAtCompletion?: ?number,
        +invoiced?: ?number,
        +paid?: ?number,
        +baselineMinutes?: ?number,
        +baselineVsRegisteredMinutes?: ?number,
        +baselineVsTotalTimeAtCompletion?: ?number,
        +registeredMinutes?: ?number,
        +scopeApprovedMinutes?: ?number,
        +registeredVsScopeApprovedMinutes?: ?number,
        +scopeApprovedVsRegisteredMinutes?: ?number,
        +forecastTimeToComplete?: ?number,
        +totalTimeAtCompletion?: ?number,
        +totalTimeAtCompletionVsScopeApprovedMinutes?: ?number,
        +scopeApprovedVsTotalTimeAtCompletion?: ?number,
      |},
    |}>,
    +aggregatedExpenseCategoryFinancialBreakdown: $ReadOnlyArray<?{|
      +breakdownType: ?ExpenseCategoryFinancialBreakdownType,
      +expenseCategory: ?{|
        +id: string,
        +name: ?string,
      |},
      +aggregatedFinancialNumbers: ?$ReadOnlyArray<?string>,
    |}>,
  |},
  +$refType: DetailsExpenseCategoryLoader_viewer$ref,
|};
export type DetailsExpenseCategoryLoader_viewer$data = DetailsExpenseCategoryLoader_viewer;
export type DetailsExpenseCategoryLoader_viewer$key = {
  +$data?: DetailsExpenseCategoryLoader_viewer$data,
  +$fragmentRefs: DetailsExpenseCategoryLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v2 = {
  "kind": "Literal",
  "name": "convertToProjectCurrency",
  "value": true
},
v3 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v4 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v5 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v6 = {
  "kind": "Literal",
  "name": "filterRowsWithNoValues",
  "value": true
},
v7 = {
  "kind": "Variable",
  "name": "phaseId",
  "variableName": "phaseId"
},
v8 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v9 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v10 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v11 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "breakdownType",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ExpenseCategory",
  "kind": "LinkedField",
  "name": "expenseCategory",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "phaseId",
      "type": "ID!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregateLevel",
      "type": "AggregateLevel!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregate",
      "type": "FinancialAggregatedNumber!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineRevenue",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineCost",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "billableActualTimeAndExpenses",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "actualCost",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "actualProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "billablePlannedTimeAndExpenses",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannedCost",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannedProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "billableForecastTimeAndExpensesToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "forecastCostToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "forecastProfitToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "billableTotalTimeAndExpensesAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalCostAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalProfitAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsBillableActualTimeAndExpenses",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsActualCost",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsActualProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsTotalBillableTimeAndExpensesAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsTotalCostAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsTotalProfitAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsBillableActualTimeAndExpenses",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsActualCost",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsActualProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsBillableTotalTimeAndExpensesAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsTotalCostAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsTotalProfitAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "invoiced",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paid",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsRegisteredMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsTotalTimeAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "registeredMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeApprovedMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "registeredVsScopeApprovedMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeApprovedVsRegisteredMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "forecastTimeToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalTimeAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalTimeAtCompletionVsScopeApprovedMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeApprovedVsTotalTimeAtCompletion",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailsExpenseCategoryLoader_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/)
          ],
          "concreteType": "ExpenseCategoryFinancialBreakdown",
          "kind": "LinkedField",
          "name": "expenseCategoryFinancialBreakdown",
          "plural": true,
          "selections": [
            (v12/*: any*/),
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "FinancialNumbers",
              "kind": "LinkedField",
              "name": "financialNumbers",
              "plural": false,
              "selections": [
                {
                  "condition": "baselineRevenue",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineRevenue",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineCost",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineCost",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "billableActualTimeAndExpenses",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableActualTimeAndExpenses",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "actualCost",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "actualCost",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "actualProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "actualProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "billablePlannedTimeAndExpenses",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billablePlannedTimeAndExpenses",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "plannedCost",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "plannedCost",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "plannedProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "plannedProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "billableForecastTimeAndExpensesToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableForecastTimeAndExpensesToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "forecastCostToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecastCostToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "forecastProfitToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecastProfitToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "billableTotalTimeAndExpensesAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableTotalTimeAndExpensesAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalCostAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCostAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalProfitAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalProfitAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsBillableActualTimeAndExpenses",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsBillableActualTimeAndExpenses",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsActualCost",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsActualCost",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsActualProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsActualProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsTotalBillableTimeAndExpensesAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsTotalBillableTimeAndExpensesAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsTotalCostAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsTotalCostAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsTotalProfitAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsTotalProfitAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsBillableActualTimeAndExpenses",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsBillableActualTimeAndExpenses",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsActualCost",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsActualCost",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsActualProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsActualProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsBillableTotalTimeAndExpensesAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsBillableTotalTimeAndExpensesAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsTotalCostAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsTotalCostAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsTotalProfitAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsTotalProfitAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "invoiced",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invoiced",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "paid",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "paid",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsRegisteredMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsRegisteredMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsTotalTimeAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsTotalTimeAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "registeredMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "registeredMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "scopeApprovedMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "scopeApprovedMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "registeredVsScopeApprovedMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "registeredVsScopeApprovedMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "scopeApprovedVsRegisteredMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "scopeApprovedVsRegisteredMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "forecastTimeToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecastTimeToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalTimeAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalTimeAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalTimeAtCompletionVsScopeApprovedMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalTimeAtCompletionVsScopeApprovedMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "scopeApprovedVsTotalTimeAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "scopeApprovedVsTotalTimeAtCompletion",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "aggregateLevel",
              "variableName": "aggregateLevel"
            },
            {
              "items": [
                {
                  "kind": "Variable",
                  "name": "aggregates.0",
                  "variableName": "aggregate"
                }
              ],
              "kind": "ListValue",
              "name": "aggregates"
            },
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/)
          ],
          "concreteType": "AggregatedExpenseCategoryFinancialBreakdown",
          "kind": "LinkedField",
          "name": "aggregatedExpenseCategoryFinancialBreakdown",
          "plural": true,
          "selections": [
            (v12/*: any*/),
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "aggregatedFinancialNumbers",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '88043592334ccfa1d91a2c734ca15f14';

module.exports = node;
