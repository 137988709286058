import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import styled from 'styled-components';
import {Tab, Tabs} from 'web-components';

import Util from '../../../../forecast-app/shared/util/util';
import {CSS_CONSTANTS} from '../../../../css_variables';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../../constants';
import HeaderBar from '../../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {ExpenseTable} from './components/expense_table';
import {createFragmentContainer, graphql} from 'react-relay';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';
import {useProjectProgramFinancials} from '../../../../forecast-app/project-tab/programs/hooks/useProjectProgramFinancials';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';
import CompanySetupUtil from '../../../../forecast-app/shared/util/CompanySetupUtil';
import {hasModule} from '../../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../../constants';

const ExpensesPageStyle = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	.totals {
		display: flex;
		margin: 0;
		margin-bottom: 32px;

		.total {
			border: 1px solid ${CSS_CONSTANTS.app_border_color};
			color: ${CSS_CONSTANTS.v2_text_gray};
			text-align: center;
			flex: 1 1 auto;
			margin: 0 5px;

			.title {
				font-size: 14px;
				font-weight: 500;
				margin: 22px 0;

				.tooltip-container {
					margin-left: 8px;
				}
			}

			.value {
				font-size: 24px;
				margin-bottom: 22px;
			}
		}
		div:first-child {
			&.total {
				margin: 0 5px 0 0;
			}
		}
		div:last-child {
			&.total {
				margin: 0 0 0 5px;
			}
		}
	}

	.section-body {
		margin: 0;
		padding-bottom: 88px;
		display: flex;
		flex: 1;
	}

	.expense-list {
		padding: 0 16px 24px;
	}
`;

const createHeaderBar = (
	project,
	formatMessage,
	retry,
	handleNewExpense,
	newXeroEnabled,
	sageIntacctConnectionEnabled,
	isSagePSA
) => {
	const isDoneOrHalted = project.status === 'DONE' || project.status === 'HALTED';

	const rightContent = [];
	const leftContent = [];

	if (project && isDoneOrHalted) {
		const indicator = {
			type: ELEMENT_TYPE.INDICATOR,
			status: project.status,
		};
		leftContent.push(indicator);
	}

	if (newXeroEnabled) {
		rightContent.push({
			type: ELEMENT_TYPE.BUTTON,
			text: formatMessage({id: 'settings_clients.import_from'}, {systemName: 'Xero'}),
			callback: () =>
				showModal({
					type: MODAL_TYPE.IMPORT_EXPENSE_ITEM,
					projectId: project.id,
					onSave: () => retry(),
					handleNewExpense: expense => handleNewExpense(expense),
				}),
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
			dataCy: 'import-expense-from-xero',
			disabled: isDoneOrHalted,
		});
	}

	if (sageIntacctConnectionEnabled && !isSagePSA) {
		rightContent.push({
			type: ELEMENT_TYPE.BUTTON,
			text: formatMessage({id: 'settings_clients.import_from'}, {systemName: 'Sage Intacct'}),
			callback: () =>
				showModal({
					type: MODAL_TYPE.IMPORT_SAGE_INTACCT_EXPENSE_ITEM,
					projectId: project.id,
					onSave: () => retry(),
					handleNewExpense: expense => handleNewExpense(expense),
				}),
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
			dataCy: 'import-expense-from-sage_intacct',
			disabled: isDoneOrHalted,
		});
	}

	if (CompanySetupUtil.hasCreateExpense()) {
		rightContent.push({
			type: ELEMENT_TYPE.BUTTON,
			text: formatMessage({id: 'expenses.new_expense'}),
			callback: () =>
				showModal({
					type: MODAL_TYPE.EXPENSE_ITEM,
					projectId: project.id,
					onSave: () => retry(),
					handleNewExpense: expense => handleNewExpense(expense),
				}),
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
			dataCy: 'add-new-expense',
			disabled: isDoneOrHalted,
		});
	}

	return <HeaderBar parentGroup={null} leftContent={leftContent} rightContent={rightContent} noPadding={true} />;
};

const ExpensesPage = ({project, company, retry, timestamp, actualPersonId}) => {
	useTrackPage('Project Financials Expenses', null, {budgetType: project.budgetType});
	const [financialQueryTimestamp, setFinancialQueryTimestamp] = useState(timestamp);
	if (timestamp !== financialQueryTimestamp) {
		setFinancialQueryTimestamp(timestamp);
		retry();
	}

	const intl = useIntl();
	const {formatMessage} = intl;
	const {expenseItems, companyProjectId} = project;

	const {getProgramBudgetInfo} = useProjectProgramFinancials(companyProjectId);
	const {isInFixedPriceProgram, isInCappedRevenueProgram} = getProgramBudgetInfo(actualPersonId);
	const allowExpenseApprovalFromTable = !isInFixedPriceProgram && !isInCappedRevenueProgram;
	const isSagePSA = hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);

	const expenses = expenseItems.edges.map(edge => edge.node);
	const approvedExpenses = expenses.filter(expense => expense.approved);
	const unApprovedExpenses = expenses.filter(expense => !expense.approved);
	let billableExpenses = approvedExpenses.filter(expense => expense.billable);
	let nonBillableExpenses = approvedExpenses.filter(expense => !expense.billable);
	let unApprovedBillableExpenses = unApprovedExpenses.filter(expense => expense.billable);
	let unApprovedNonBillableExpenses = unApprovedExpenses.filter(expense => !expense.billable);

	if (!ProjectUtil.projectTracksRevenue(project)) {
		nonBillableExpenses = nonBillableExpenses.concat(billableExpenses);
		billableExpenses = [];
		unApprovedNonBillableExpenses = unApprovedNonBillableExpenses.concat(unApprovedBillableExpenses);
		unApprovedBillableExpenses = [];
	}

	const [selectedTab, setSelectedTab] = useState(unApprovedExpenses.length === 0 && approvedExpenses.length > 0 ? 1 : 0);

	const priceTotal = billableExpenses.reduce((total, billableExpense) => {
		return total + billableExpense.price * billableExpense.quantity;
	}, 0);

	const costTotal = approvedExpenses.reduce((total, approvedExpense) => {
		return total + approvedExpense.cost * approvedExpense.quantity;
	}, 0);

	const profitTotal = priceTotal - costTotal;

	const currencySymbol = Util.GetCurrencySymbol(project.rateCard ? project.rateCard.currency : company.currency);

	const handleNewExpense = expense => {
		if (expense.approved) {
			setSelectedTab(1);
		} else {
			setSelectedTab(0);
		}
	};

	return (
		<ExpensesPageStyle>
			{createHeaderBar(
				project,
				formatMessage,
				retry,
				handleNewExpense,
				company.newXeroEnabled,
				company.sageIntacctConnectionEnabled,
				isSagePSA
			)}
			<div className={'totals'}>
				{ProjectUtil.projectTracksRevenue(project) ? (
					<div className="total billable">
						<h5 className="title">{formatMessage({id: 'project_budget.revenue'})}</h5>
						<div data-cy={'expenses-total-revenue'} className="value">
							{Util.getFormattedNumberWithCurrency(currencySymbol, priceTotal, intl)}
						</div>
					</div>
				) : null}

				<div className="total non-billable">
					<h5 className="title">{formatMessage({id: 'common.cost'})}</h5>
					<div data-cy={'expenses-total-cost'} className="value">
						{Util.getFormattedNumberWithCurrency(currencySymbol, costTotal, intl)}
					</div>
				</div>
				<div className="total profit">
					<h5 className="title">{formatMessage({id: 'common.profit'})}</h5>
					<div data-cy={'expenses-total-profit'} className={'value'}>
						{Util.getFormattedNumberWithCurrency(currencySymbol, profitTotal, intl)}
					</div>
				</div>
			</div>

			<div className="section-body">
				<Tabs>
					<Tab
						isSelected={selectedTab === 0}
						key="unapproved-expenses"
						title={`${formatMessage({id: 'common.unapproved'})} (${unApprovedExpenses.length})`}
						cy="unapproved-expenses"
					>
						<CustomScrollDiv>
							<div className="expense-list">
								{unApprovedBillableExpenses.length > 0 ? (
									<ExpenseTable
										key="unapproved-billable-expenses"
										title={formatMessage({id: 'expenses.billable_expenses'})}
										project={project}
										currencySymbol={currencySymbol}
										expenses={unApprovedBillableExpenses}
										showApproved={allowExpenseApprovalFromTable}
										plannedEditable={true}
										showPartOfFixedPrice={
											hasFeatureFlag('Expense_Modal_Update_23Q1') &&
											ProjectUtil.isAnyKindOfFixedPrice(project)
										}
										retry={retry}
										cyPrefix="unapproved-billable-expense"
										company={company}
										handleNewExpense={handleNewExpense}
									/>
								) : null}

								{unApprovedNonBillableExpenses.length > 0 ? (
									<ExpenseTable
										key="unapproved-non-billable-expenses"
										title={formatMessage({id: 'expenses.non_billable_expenses'})}
										project={project}
										currencySymbol={currencySymbol}
										expenses={unApprovedNonBillableExpenses}
										showApproved={allowExpenseApprovalFromTable}
										plannedEditable={true}
										retry={retry}
										cyPrefix="unapproved-nonbillable-expense"
										company={company}
										handleNewExpense={handleNewExpense}
									/>
								) : null}
							</div>
						</CustomScrollDiv>
					</Tab>
					<Tab
						isSelected={selectedTab === 1}
						key="approved-expenses"
						title={`${formatMessage({id: 'common.approved'})} (${approvedExpenses.length})`}
						cy="approved-expenses"
					>
						<CustomScrollDiv>
							<div className="expense-list">
								{billableExpenses.length > 0 ? (
									<ExpenseTable
										key="approved-billable-expenses"
										title={formatMessage({id: 'expenses.billable_expenses'})}
										project={project}
										currencySymbol={currencySymbol}
										expenses={billableExpenses}
										showPartOfFixedPrice={
											hasFeatureFlag('Expense_Modal_Update_23Q1') &&
											ProjectUtil.isFixedPriceProject(project)
										}
										retry={retry}
										cyPrefix="billable-expense"
										company={company}
										handleNewExpense={handleNewExpense}
									/>
								) : null}

								{nonBillableExpenses.length > 0 ? (
									<ExpenseTable
										key="approved-non-billable-expenses"
										title={formatMessage({id: 'expenses.non_billable_expenses'})}
										project={project}
										currencySymbol={currencySymbol}
										expenses={nonBillableExpenses}
										retry={retry}
										cyPrefix="nonbillable-expense"
										company={company}
										handleNewExpense={handleNewExpense}
									/>
								) : null}
							</div>
						</CustomScrollDiv>
					</Tab>
				</Tabs>
			</div>
		</ExpensesPageStyle>
	);
};

export default createFragmentContainer(ExpensesPage, {
	project: graphql`
		fragment expensesPage_project on ProjectType {
			...expenseRow_project
			id
			companyProjectId
			billable
			budgetType
			status
			rateCard {
				id
				currency
			}
			expenseItems(first: 10000) @connection(key: "Project_expenseItems") {
				edges {
					node {
						id
						category {
							id
							name
						}
						phase {
							id
							name
						}
						name
						approved
						expenseYear
						expenseMonth
						expenseDay
						billable
						partOfFixedPrice
						price
						cost
						quantity
						name
						planned
						createdAt
						invoiced
						person {
							id
							fullName
							profilePictureId
							profilePictureDefaultId
						}
					}
				}
			}
			fixedPriceLocks(first: 1000) @connection(key: "Project_fixedPriceLocks", filters: []) {
				edges {
					node {
						id
						startDate
						endDate
						locked
					}
				}
			}
		}
	`,
	company: graphql`
		fragment expensesPage_company on Company {
			...expenseRow_company
			id
			currency
			newXeroEnabled
			sageIntacctConnectionEnabled
			expenseCategories(first: 10000) {
				edges {
					node {
						id
						name
						disabled
					}
				}
			}
		}
	`,
});
