import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {fetchData, fetchMapData} from '../reported_time_financials_fetch';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import {getRoleRow} from '../rows/role_row';
import BaseLevelWrapper from './BaselevelWrapper';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {hasSomePermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {useDidObjectChange} from '../../../../forecast-app/shared/hooks/useDidObjectChange';

const roleRelayWrapper = ({
	tableHeader,
	relay,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
}) => {
	const roles = viewer.company.allRolesGlobalSearch.edges;
	const currency = viewer.company.currency;
	const hasNextPage = viewer.company.allRolesGlobalSearch.pageInfo.hasNextPage;
	const [rolesFinancialMap, setRolesFinancialMap] = useState(null);
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const [nonRoleTotalMinutesRegistered, setNonRoleTotalMinutesRegistered] = useState(null);
	const [nonRoleTotalFinancials, setNonRoleTotalFinancials] = useState(null);

	const rolesChanged = useDidObjectChange(roles, null);
	const searchQueryChanged = useDidObjectChange(searchQuery, null);
	const shouldRefetchFinancials = rolesChanged || searchQueryChanged;

	useEffect(() => {
		if (shouldRefetchFinancials) {
			const rolesIds = roles.map(role => role.node.id);
			if (
				hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				])
			) {
				fetchMapData(rolesIds, searchQuery, 'total_role_time_regs_financials').then(data => {
					const rolesFinancialMap = new Map();
					Object.keys(data).forEach(key => {
						rolesFinancialMap.set(key, data[key]);
					});
					setRolesFinancialMap(rolesFinancialMap);
				});
			}

			if (!hasNextPage) {
				fetchData(searchQuery, 'non_role_total_minutes_registered').then(data => {
					if (data.total !== undefined) {
						setNonRoleTotalMinutesRegistered(data.total);
					}
				});
				fetchData(searchQuery, 'total_non_role_time_regs_financials').then(data => {
					setNonRoleTotalFinancials(data.totals);
				});
			}
		}
	}, [shouldRefetchFinancials]);

	const dataRows = roles.map(role => {
		return {
			...role.node,
			totalPrice:
				rolesFinancialMap !== null && rolesFinancialMap.has(role.node.id)
					? rolesFinancialMap.get(role.node.id).totalPrice
					: null,
			totalCost:
				rolesFinancialMap !== null && rolesFinancialMap.has(role.node.id)
					? rolesFinancialMap.get(role.node.id).totalCost
					: null,
		};
	});

	nonRoleTotalMinutesRegistered !== null &&
		dataRows.push({
			isNoRole: true,
			name: intl.formatMessage({id: 'time_reg_report.no_role'}),
			totalMinutesRegistered: nonRoleTotalMinutesRegistered,
			totalPrice: nonRoleTotalFinancials ? nonRoleTotalFinancials.totalPrice : null,
			totalCost: nonRoleTotalFinancials ? nonRoleTotalFinancials.totalCost : null,
		});

	const data = {rows: dataRows};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = roles.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const roleRows = () => {
		return getRoleRow(
			data,
			currency,
			intl,
			groupIndex,
			rolesFinancialMap,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader
		);
	};

	return groupIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			data={data}
			getRows={roleRows}
			currentLength={roles.length}
			hasNextPage={viewer.company.allRolesGlobalSearch.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		roleRows()
	);
};

const roleRelayWrapperQuery = graphql`
	query roleRelayWrapperQuery_Query($searchQuery: TaskSearchQueryType, $pageSize: Int) {
		viewer {
			actualPersonId
			component(name: "role_relay_wrapper")
			...roleRelayWrapper_viewer @arguments(searchQuery: $searchQuery, pageSize: $pageSize)
		}
	}
`;
export {roleRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			roleRelayWrapper,
			{
				viewer: graphql`
					fragment roleRelayWrapper_viewer on Viewer
					@argumentDefinitions(searchQuery: {type: "TaskSearchQueryType"}, pageSize: {type: "Int"}) {
						availableFeatureFlags {
							key
						}
						company {
							currency
							allRolesGlobalSearch(first: $pageSize, searchQuery: $searchQuery) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										totalMinutesRegistered(searchQuery: $searchQuery)
										id
										name
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query roleRelayWrapperRefetchQuery($pageSize: Int, $searchQuery: TaskSearchQueryType) {
					viewer {
						...roleRelayWrapper_viewer @arguments(pageSize: $pageSize, searchQuery: $searchQuery)
					}
				}
			`
		)
	)
);
