import React from 'react';
import {useIntl} from 'react-intl';
import {IconText, Table} from 'web-components';
import MonthByMonthConnectedProjectLoader, {
	monthByMonthConnectedProjectLoaderQuery,
} from './MonthByMonthConnectedProjectLoader';
import {filtersToSearchQuery} from '../ProjectPortfolioReportUtils';
import ProjectPortfolioMonthByMonthConnectedProjectDownload, {
	projectPortfolioMonthByMonthConnectedProjectDownloadQuery,
} from './ProjectPortfolioMonthByMonthConnectedProjectDownload';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {LoadMore} from '../../../loaders/LoadMore';
import ProjectPortfolioMonthByMonthRow from './ProjectPortfolioMonthByMonthRow';
import ProjectPortfolioMonthByMonthProjectRows from './ProjectPortfolioMonthByMonthProjectRows';
import {DeprecatedProjectGroupIndicatorNoFragment} from '../../../../forecast-app/shared/components/project-indicator/DeprecatedProjectGroupIndicator';

const ProjectPortfolioMonthByMonthConnectedProjectRows = ({
	setCsvDownloadFunction,
	hasBaseline,
	pageSize,
	startDate,
	endDate,
	filters,
	boolEyeOptions,
	levelProps,
	timeSearchQuery,
	expenseSearchQuery,
	selectedSingleValue,
}) => {
	const intl = useIntl();
	return (
		<LoadMore
			key="query-render-month-by-month-connected-project"
			query={monthByMonthConnectedProjectLoaderQuery}
			variables={{
				searchQuery: filtersToSearchQuery(filters),
				timeSearchQuery: timeSearchQuery,
				expenseSearchQuery: expenseSearchQuery,
				startYear: startDate.year(),
				startMonth: startDate.month() + 1,
				startDay: startDate.date(),
				endYear: endDate.year(),
				endMonth: endDate.month() + 1,
				endDay: endDate.date(),
				pageSize: pageSize,
				selectedSingleValue: selectedSingleValue,
			}}
			loader={<MonthByMonthConnectedProjectLoader />}
		>
			{({data, loadMore}) => {
				setCsvDownloadFunction(() => {
					showModal({
						type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
						componentName: 'project_portfolio_month_by_month_connected_project_csv_loader',
						query: projectPortfolioMonthByMonthConnectedProjectDownloadQuery,
						variables: {
							searchQuery: filtersToSearchQuery(filters),
							timeSearchQuery: timeSearchQuery,
							expenseSearchQuery: expenseSearchQuery,
							startYear: startDate.year(),
							startMonth: startDate.month() + 1,
							startDay: startDate.date(),
							endYear: endDate.year(),
							endMonth: endDate.month() + 1,
							endDay: endDate.date(),
							pageSize: 1000000,
							selectedSingleValue: selectedSingleValue,
						},
						createCsvDownload: <ProjectPortfolioMonthByMonthConnectedProjectDownload />,
						startDate,
						endDate,
						boolEyeOptions,
						selectedSingleValue,
					});
				});
				return (
					<Table.Rows data={data} canExpand={true} loadMore={loadMore} autoload={true} {...levelProps}>
						{({rowData, tableColumnsProps, expanded, nextLevelProps}) => {
							return (
								<>
									<ProjectPortfolioMonthByMonthRow
										rowData={rowData}
										tableColumnProps={tableColumnsProps}
										headerColumn={
											rowData.connectedProject.companyProjectGroupId ? (
												<IconText
													key={rowData.connectedProject.companyProjectGroupId}
													text={rowData.connectedProject.name}
												>
													<DeprecatedProjectGroupIndicatorNoFragment
														projectGroup={rowData.connectedProject}
													/>
												</IconText>
											) : (
												intl.formatMessage({id: 'common.no_connected_project'})
											)
										}
										selectedSingleValue={selectedSingleValue}
										hasBaseline={hasBaseline}
									/>
									{expanded && (
										<ProjectPortfolioMonthByMonthProjectRows
											setCsvDownloadFunction={setCsvDownloadFunction}
											hasBaseline={hasBaseline}
											pageSize={pageSize}
											startDate={startDate}
											endDate={endDate}
											filters={filters}
											boolEyeOptions={boolEyeOptions}
											levelProps={nextLevelProps}
											timeSearchQuery={timeSearchQuery}
											expenseSearchQuery={expenseSearchQuery}
											selectedSingleValue={selectedSingleValue}
											projectGroupId={rowData.connectedProject.id}
										></ProjectPortfolioMonthByMonthProjectRows>
									)}
								</>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default ProjectPortfolioMonthByMonthConnectedProjectRows;
