import React from 'react';
import {getQueryRenderer} from '../reported_time_page_util';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../constants';
import Util from '../../../../forecast-app/shared/util/util';
import {CalculationLoader, Table} from 'web-components';
import {getNumberOfCustomFields} from '../reported_time_table_util';

export const getSprintRow = (
	data,
	currency,
	intl,
	groupIndex,
	sprintsFinancialMap,
	searchQuery,
	groupings,
	enabledColumns,
	sortValue,
	hasNextPage,
	fetchMore,
	nextLevelProps,
	tableHeader
) => {
	const numberOfCustomFields = getNumberOfCustomFields(tableHeader);
	const getColumns = sprint => {
		const formattedPrice = sprint.totalPrice && intl.formatNumber(sprint.totalPrice, {format: 'always_two_decimal'});
		const formattedCost = sprint.totalCost && intl.formatNumber(sprint.totalCost, {format: 'always_two_decimal'});
		return [
			<Table.Column>
				<span>{sprint.name}</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>{Util.convertMinutesToFullHour(sprint.totalMinutesRegistered, intl)}</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>
				<span>
					{formattedPrice !== null ? (
						Util.GetFormattedCurrencySymbol(currency, formattedPrice)
					) : (
						<CalculationLoader />
					)}
				</span>
			</Table.Column>,
			<Table.Column>
				<span>
					{formattedCost !== null ? Util.GetFormattedCurrencySymbol(currency, formattedCost) : <CalculationLoader />}
				</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			[...Array(numberOfCustomFields)].map(_ => <Table.Column />),
		];
	};

	return (
		<Table.Rows data={data} canExpand={true} loadMore={hasNextPage && fetchMore} {...nextLevelProps}>
			{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
				const filterField =
					rowData.isNoSprint === true ? GLOBAL_FILTER_FIELD.NON_SPRINT_TIME_REG : GLOBAL_FILTER_FIELD.SPRINT_TIME_REG;
				return (
					<>
						<Table.Row {...tableColumnsProps}>{getColumns(rowData)}</Table.Row>
						{expanded &&
							getQueryRenderer(
								tableHeader,
								groupings,
								searchQuery,
								groupIndex,
								enabledColumns,
								{field: filterField, operator: GLOBAL_FILTER_OPERATOR.IS, value: [rowData.id]},
								nextLevelProps,
								sortValue
							)}
					</>
				);
			}}
		</Table.Rows>
	);
};
