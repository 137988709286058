import React from 'react';
import * as PropTypes from 'prop-types';
import {EquationDataElement, FlexColumn, FlexRow, Icon, SubHeading, Text} from '@forecast-it/design-system';
import styled from 'styled-components';

const Svg = styled.svg`
	pointer-events: none;
`;

const ellipses = {
	left: (
		<div style={{display: 'flex', width: '14px', height: '38px'}}>
			<Svg>
				<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12 37C12 37 8 28.9411 8 19C8 9.05887 12 1 12 1"
						stroke="#232334"
						stroke-width="1.4"
						stroke-linecap="round"
					/>
				</svg>
			</Svg>
		</div>
	),
	right: (
		<div style={{display: 'flex', width: '8px', height: '38px'}}>
			<Svg>
				<svg width="100" height="100" viewBox="6 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M8 0.999998C8 0.999998 12 9.05887 12 19C12 28.9411 8 37 8 37"
						stroke="#232334"
						stroke-width="1.4"
						stroke-linecap="round"
					/>
				</svg>
			</Svg>
		</div>
	),
};

export const TooltipFormulaRenderer = ({items}) => {
	return items.map((item, index) => {
		return (
			<FlexColumn gap="xl">
				{item.title && <SubHeading>{item.title}</SubHeading>}
				{item.description && <Text>{item.description}</Text>}
				{item.details && (
					<FlexColumn gap="s">
						{item.detailsHeader && <Text>{item.detailsHeader}</Text>}
						<FlexRow gap="s" wrap>
							{item.details?.map((detail, index) => {
								return item.dataWithText ? (
									index % 2 === 0 ? (
										<Text>{detail}</Text>
									) : (
										<EquationDataElement>{detail}</EquationDataElement>
									)
								) : detail === 'x' ? (
									<Icon icon="closeS"></Icon>
								) : detail === '+' ? (
									<Icon icon="plus"></Icon>
								) : detail === '-' ? (
									<Icon icon="minus"></Icon>
								) : detail === '/' ? (
									<Icon icon="divide"></Icon>
								) : detail === '(' ? (
									ellipses.left
								) : detail === ')' ? (
									ellipses.right
								) : (
									<EquationDataElement>{detail}</EquationDataElement>
								);
							})}
						</FlexRow>
					</FlexColumn>
				)}
				{items[index + 1] && <FlexRow> </FlexRow>}
			</FlexColumn>
		);
	});
};

TooltipFormulaRenderer.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
			description: PropTypes.node,
			details: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
			detailsHeader: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
			dataWithText: PropTypes.bool,
		})
	).isRequired,
};

TooltipFormulaRenderer.defaultTypes = {
	dataWithText: false,
};
