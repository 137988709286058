import React from 'react';
import Styled from 'styled-components';
import LoadingIndicator from './LoadingIndicator';

export const LOAD_MORE_DIRECTION = {
	LEFT: 'left',
	RIGHT: 'right',
};

export const LOAD_MORE = {
	IS_LOADING: 'is_loading_more_',
	DATE: 'load_more_date_',
};

export const OVERLAY_PREFIX = 'load-more-overlay-';

export default ({onWheel, isHidden}) => {
	const getLoadMoreOverlay = id => {
		return (
			<LoadMoreOverlayStyled id={id} hidden={isHidden}>
				<LoadingIndicator />
			</LoadMoreOverlayStyled>
		);
	};

	return (
		<div onWheel={onWheel}>
			{getLoadMoreOverlay(OVERLAY_PREFIX + LOAD_MORE_DIRECTION.LEFT)}
			{getLoadMoreOverlay(OVERLAY_PREFIX + LOAD_MORE_DIRECTION.RIGHT)}
		</div>
	);
};

const LoadMoreOverlayStyled = Styled.div`
    position: absolute;
    background: #ffffffcc;
    backdrop-filter: blur(5px);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`;
