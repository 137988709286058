import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CheckBox extends Component {
	handleKeyPress(e) {
		if (e.key === 'Enter' && this.props.onChange) this.props.onChange();
	}

	handleClick(e) {
		e.stopPropagation();
		this.props.onClick(e);
	}

	render() {
		return (
			<div
				className={
					'check-box' +
					(this.props.large ? ' large' : '') +
					(this.props.small ? ' small' : '') +
					(this.props.verySmall ? ' very-small' : '') +
					(this.props.green ? ' green' : '') +
					(this.props.greenInverted ? ' green-inverted' : '') +
					(this.props.isFocusable ? ' focusable' : '') +
					(this.props.customClasses ? ' ' + this.props.customClasses : '') +
					(this.props.isDisabled ? ' disabled' : '')
				}
				title={this.props.title}
			>
				<input
					onClick={this.handleClick.bind(this)}
					className={'check-mark' + (this.props.hasEntitiesChecked ? ' group-has-entities-checked' : '')}
					type="checkbox"
					ref={this.props.refCallBack}
					id={this.props.id}
					disabled={this.props.isDisabled}
					onChange={this.props.onChange}
					checked={this.props.isChecked}
					style={
						this.props.checkedColor && this.props.isChecked
							? {backgroundColor: this.props.checkedColor, borderColor: this.props.checkedColor}
							: {}
					}
					onBlur={this.props.onBlur}
					data-cy={this.props.cy}
					data-userpilot={this.props.userpilot}
					onKeyPress={this.handleKeyPress.bind(this)}
				/>
			</div>
		);
	}
}

CheckBox.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	isDisabled: PropTypes.bool,
	isChecked: PropTypes.bool,
	isFocusable: PropTypes.bool,
	onChange: PropTypes.func,
	refCallBack: PropTypes.func,
	verySmall: PropTypes.bool,
	small: PropTypes.bool,
	large: PropTypes.bool,
	green: PropTypes.bool,
	greenInverted: PropTypes.bool,
	checkedColor: PropTypes.string,
	onBlur: PropTypes.func,
	customClasses: PropTypes.string,
	hasEntitiesChecked: PropTypes.bool,
};

CheckBox.defaultProps = {
	onClick: () => false,
	onChange: () => false,
};
export default CheckBox;
