import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';

class MoveButton extends Component {
	handleMoveFile() {
		this.props.showFileLocationModal(this.props.file);
	}

	render() {
		return (
			<div className="move-link-container" onClick={this.handleMoveFile.bind(this)}>
				<FormattedMessage id="project_files.move-file-to-card" />
			</div>
		);
	}
}

MoveButton.propTypes = {
	file: PropTypes.object.isRequired,
	handleFileMoveToCard: PropTypes.func.isRequired,
	showFileLocationModal: PropTypes.func.isRequired,
};

export default injectIntl(MoveButton);
