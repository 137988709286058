import React from 'react';
import Styled from 'styled-components';
import {useIntl} from 'react-intl';
import {Checkbox} from 'web-components';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const Container = Styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

const CheckboxContainer = Styled.div`
	margin: 0 15px 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Description = Styled.span`
	font-weight: 400;
	font-size: 13px;
`;

export default ({changeIsChecked, isChecked}) => {
	const {formatMessage} = useIntl();

	const changeHandler = () => {
		trackEvent('Keep Empty Placeholder', isChecked ? 'Deselected' : 'Selected');
		changeIsChecked();
	};

	return (
		<Container>
			<CheckboxContainer>
				<Checkbox checked={isChecked} cy={'keep-empty-placeholder-checkbox'} onChange={changeHandler} />
			</CheckboxContainer>

			<Description>{formatMessage({id: 'scheduling.keep_empty_placeholder'})}</Description>
		</Container>
	);
};
