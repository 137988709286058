import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {getCanvasTimelineDateFromMoment, getSchedulingOptionVisualizationMode} from '../canvas-timeline/canvas_timeline_util';
import Moment from 'moment';
import CanvasCapacityOverviewHeader from './CanvasCapacityOverviewHeader';
import {getPlaceholdersOverviewEyeOptions} from './CanvasCapacityOverviewUtil';
import CanvasTimeline from '../canvas-timeline/canvas_timeline';
import * as tracking from '../../../tracking';
import {dispatch, EVENT_ID, subscribe, unsubscribe} from '../../../containers/event_manager';
import {fetchData} from './CanvasCapacityOverviewFetchUtil';
import {initializeData} from './CanvasCapacityOverviewDataUtil';
import CanvasCapacityOverviewTopLeftContainer from './CanvasCapacityOverviewTopLeftContainer';
import CanvasCapacityOverviewHoverEffects from './CanvasCapacityOverviewHoverEffects';
import {handleCapacityOverviewSchedulingMutation, onSavedFiltersUpdate} from './CanvasCapacityOverviewMutationSuccess';
import {trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import {
	ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN,
	ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD,
	ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT,
} from '../constants';
import {filterGroupsAndItems} from './CanvasCapacityOverviewFilterLogic';
import {
	drawIncrementalLoadMoreOverlay,
	getOverlayCanvasDate,
	preventScrollingPastLoadMoreOverlay,
} from '../loading/LoadMoreUtil';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../constants';
import {LoadingOverlay} from '../loading/LoadingOverlay';
import {trackPerformance, trackPerformanceInit} from '../canvas-timeline/canvas_timeline_performance_track';
import IncrementalLoadingOverlay, {LOAD_MORE, LOAD_MORE_DIRECTION} from '../loading/IncrementalLoadingOverlay';
import {DATA_ENTITIES} from '../DataManager';
import PlaceholderAllocationItem from '../components/items/placeholder_allocation_item';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import EventManager from '../EventManager';
import ComposeManager from '../ComposeManager';
import IDManager from '../IDManager';
import RecalculationManager from '../RecalculationManager';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import Util from '../../../forecast-app/shared/util/util';

class CanvasCapacityOverview extends Component {
	// Used for identifying minified components in cypress tests
	static displayName = 'CanvasCapacityOverview';

	constructor(props) {
		super(props);
		const pageName = 'Schedule Capacity Overview';
		trackPerformanceInit(pageName);

		const todayDate = getCanvasTimelineDateFromMoment(Moment());

		this.heatmapCache = new Map();
		RecalculationManager.clearAll();

		this.reloadData = () => fetchData(this);
		this.onSchedulingMutationSuccess = (res, args) => handleCapacityOverviewSchedulingMutation(this, res, args);
		this.onSavedFiltersUpdate = response => onSavedFiltersUpdate(this, response);

		subscribe(EVENT_ID.SCHEDULING_RELOAD_DATA, this.reloadData);
		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingMutationSuccess);
		subscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersUpdate);

		this.onClick = event => EventManager.onClick(this, event);
		this.onMouseMove = event => EventManager.onMouseMove(this, event);

		const company = props.viewer.company;
		let schedulingOptions = {
			calcWin: hasModule(MODULE_TYPES.CALC_WIN_PERCENTAGE)
				? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN) === 'true'
				: false,
			hideSoft: hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
				? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT) === 'true'
				: true,
			hideHard: hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
				? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD) === 'true'
				: false,
			visualizationMode: getSchedulingOptionVisualizationMode(company),
		};

		const heatmapFiltering =
			hasFeatureFlag('people_scheduling_enable_filter_heatmap_issue') ||
			(hasFeatureFlag('combined_heatmap_logic_extensions') && Util.isMixedAllocationModeEnabled(company));
		this.state = {
			heatmapFiltering,
			initialDataLoaded: false,
			allDataLoaded: false,
			allDataLoading: false,
			isCollapsableSectionExpanded: false,
			todayDate,
			hasInitialWorkerRun: false,
			splitPlaceholderAllocationBarData: {},
			splitAllocationBarData: {},
			eyeOptions: getPlaceholdersOverviewEyeOptions(props.weekendOptions),
			groups: [],
			items: [],
			totalMinutesMap: new Map(),
			schedulingOptions,
			weekendOptions: props.weekendOptions,
			[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.LEFT]: false,
			[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.RIGHT]: false,
			[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]: getOverlayCanvasDate(this, null, LOAD_MORE_DIRECTION.LEFT),
			[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]: getOverlayCanvasDate(this, null, LOAD_MORE_DIRECTION.RIGHT),
		};

		fetchData(this);
		this.superPropertyChecksum = trackPage(pageName);
	}

	componentDidMount() {
		tracking.trackPage('scheduling-capacity-overview');

		window.addEventListener('click', this.onClick);
		window.addEventListener('mousemove', this.onMouseMove);
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);

		this.heatmapCache.clear();

		// event listeners
		window.removeEventListener('click', this.onClick);
		window.removeEventListener('mousemove', this.onMouseMove);

		// subscriptions
		unsubscribe(EVENT_ID.SCHEDULING_RELOAD_DATA, this.reloadData);
		unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingMutationSuccess);
		unsubscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersUpdate);
	}

	setPreventFiltering(preventFiltering) {
		this.setState({preventFiltering});
	}

	activateStaffingMode(placeholderId) {
		this.props.enterStaffingMode(placeholderId);
	}

	resetRecalculateSteps() {
		this.setState({recalculateSteps: false});
	}

	getData() {
		return this.state.data;
	}

	getFilterData() {
		return this.getData();
	}

	resetState() {
		const data = this.getData();
		initializeData(this, data, true);
	}

	redrawCanvasTimeline(args) {
		if (!args.preventFiltering) {
			trackPerformance('Filtered', () => filterGroupsAndItems(this));
		}

		dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, args);
	}

	getTimeRegGroupId(timeReg) {
		const {data} = this.state;
		const {personMap} = data;

		const person = personMap.get(timeReg.personId);
		if (person) {
			return IDManager.getPersonGroupId(this, person.id);
		}

		return null;
	}

	createEntityItem(createdItems, entity, entityData) {
		switch (entity) {
			case DATA_ENTITIES.PLACEHOLDER_ALLOCATIONS:
				const placeholderAllocationItemData = ComposeManager.composePlaceholderAllocation(this, entityData);

				if (placeholderAllocationItemData) {
					createdItems.add(new PlaceholderAllocationItem(this, placeholderAllocationItemData));
				}

				break;
			case DATA_ENTITIES.ALLOCATIONS:
				const projectAllocationItemData = ComposeManager.composeProjectAllocation(this, entityData);

				if (projectAllocationItemData) {
					createdItems.add(new ProjectAllocationItem(this, projectAllocationItemData));
				}

				break;
			default:
				break;
		}
	}

	clearHeatmapCache() {
		this.heatmapCache = new Map();
	}

	getTopHeaderContent() {
		const content = [];

		content.push({
			type: TopHeaderBar.TYPE.FEEDBACK,
			link: 'https://share.hsforms.com/1WISzgI8bTw6p-BcHDsB6YA29otq',
		});

		const onboardingFlows = [
			{
				id: 'capacity-introduction',
				title: 'Introduction to the page',
				description: null,
				contentId: '1681803952rCsx2794',
			},
			{
				id: 'capacity-graph',
				title: 'How to read the graph',
				description: null,
				contentId: '1681804560aGko5116',
			},
			{
				id: 'capacity-monitor',
				title: 'How to monitor your capacity',
				description: null,
				contentId: '1681805674iDlf9325',
			},
		];
		const onboardingComponent = {
			id: 'onboarding-component',
			type: TopHeaderBar.TYPE.ONBOARDING,
			title: 'Learn to use Capacity Overview',
			options: onboardingFlows,
			helpCenterLink: 'https://support.forecast.app/hc/en-us/articles/11951561590161-About-Capacity-Planning-Plus-only-',
			subLink: 'https://support.forecast.app/hc/en-us/articles/12134140688657-Monitoring-your-resource-utilization',
		};
		content.push(onboardingComponent);

		return content;
	}

	render() {
		const {
			data,
			eyeOptions,
			recalculateSteps,
			groups,
			items,
			isCollapsableSectionExpanded,
			splitPlaceholderAllocationBarData,
			splitAllocationBarData,
			initialDataLoaded,
			weekendOptions,
		} = this.state;
		const {dayData, intl} = this.props;

		const updateTimelineRef = timeline => {
			this.props.setTimeline(timeline);
			this.timeline = timeline;
		};

		const title = intl.formatMessage({id: 'scheduling.menu.capacity_overview'});

		return (
			<>
				<div style={{backgroundColor: '#fff'}}>
					<TopHeaderBarWrapper sidePadding={24} bottomPadding={0}>
						<TopHeaderBar title={title} content={this.getTopHeaderContent()} />
					</TopHeaderBarWrapper>
				</div>
				<LoadingOverlay isLoading={!initialDataLoaded}>
					<CanvasCapacityOverviewHeader pageComponent={this} />
					<div id="capacity-overview-timeline" className={'canvas-capacity-overview-scheduling canvas-scheduling'}>
						<CanvasCapacityOverviewHoverEffects pageComponent={this} />
						<CanvasTimeline
							topLeftComponent={<CanvasCapacityOverviewTopLeftContainer />}
							ref={updateTimelineRef}
							pageComponent={this}
							groups={groups}
							items={items}
							isCollapsableSectionExpanded={isCollapsableSectionExpanded}
							onVerticalScroll={delta => EventManager.onTimelineVerticalScroll(this, delta)}
							onHorizontalScroll={() => EventManager.onTimelineHorizontalScroll(this)}
							dayData={dayData}
							initialStartDate={this.props.startDate}
							initialZoomLevel={this.props.initialZoomLevel}
							isSingleGroup={false}
							onGroupExpansionToggle={group => EventManager.onGroupExpansionToggle(this, group)}
							debugData={data}
							holidaysEntries={data?.holidaysEntriesCanvasFormat || []}
							eyeOptions={eyeOptions}
							recalculateSteps={recalculateSteps}
							resetRecalculateSteps={this.resetRecalculateSteps.bind(this)}
							onForegroundContextMenu={(event, mouseTargetData, canvasDate) =>
								EventManager.onForegroundContextMenu(this, event, mouseTargetData, canvasDate)
							}
							splitPlaceholderAllocationBarData={
								splitPlaceholderAllocationBarData.visible ? splitPlaceholderAllocationBarData : null
							}
							onBeforeHorizontalScroll={scrollAmount => preventScrollingPastLoadMoreOverlay(this, scrollAmount)}
							onDrawForegroundEnd={(canvasContext, _, stepDataArray) =>
								drawIncrementalLoadMoreOverlay(this, stepDataArray)
							}
							disableLoadMore={this.disableLoadMore}
							leftLoadMoreDate={this.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]}
							rightLoadMoreDate={this.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]}
							splitAllocationBarData={splitAllocationBarData.visible ? splitAllocationBarData : null}
							heatmapCache={this.heatmapCache}
							setPreventFiltering={this.setPreventFiltering.bind(this)}
							isPlaceholdersScheduling={true}
							isUsing35Frames={this.isUsing35Frames}
							isUsing67Frames={this.isUsing67Frames}
							isUsingInstantZoom={this.isUsingInstantZoom}
							weekendOptions={weekendOptions}
							schedulingView={this.props.schedulingView}
						/>
					</div>

					{initialDataLoaded ? (
						<IncrementalLoadingOverlay onWheel={e => this.timeline.onForegroundWheel(e)} />
					) : undefined}
				</LoadingOverlay>
			</>
		);
	}
}

CanvasCapacityOverview.propTypes = {
	viewer: PropTypes.object.isRequired,
	setTimeline: PropTypes.func.isRequired,
};

export default injectIntl(withRouter(CanvasCapacityOverview));
