import {SCHEDULING_VIEW} from '../../constants';
import {GROUP_BY} from './people-scheduling/people_scheduling_header';
import DataManager from './DataManager';
import {PLACEHOLDERS_GROUP_BY} from './placeholders-scheduling/CanvasPlaceholderSchedulingConstants';
import {PROJECT_ENTITY_GROUP_TYPE, PROJECT_SUB_GROUP_TYPE} from './constants';
import {getVisualizationMode, ITEM_TYPE, VISUALIZATION_MODE} from './canvas-timeline/canvas_timeline_util';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';

export const NON_PROJECT_TIME_GROUP = 'NON_PROJECT_TIME_GROUP';
export const NO_CONTENT_GROUP = 'NO_CONTENT_GROUP';
export const NO_ROLE = 'NO_ROLE';
export const NO_PHASE = 'NO_PHASE';
export const NO_DEPARTMENT = 'NO_DEPARTMENT';
export const TOTAL_RESOURCE_UTILIZATION_GROUP_ID = 'TOTAL_RESOURCE_UTILIZATION_GROUP';
export const PERSON_ALLOCATIONS_GROUP = 'PERSON_ALLOCATIONS_GROUP';
export const UNASSIGNED_TASK_GROUP = 'UNASSIGNED_TASK_GROUP';
export const PLACEHOLDERS_PROJECT_SUB_GROUP = 'PLACEHOLDERS_PROJECT_SUB_GROUP';
export const ENTITY_GROUPING_PLACEHOLDERS = 'PLACEHOLDERS';
export const TIMELINE_GRAPH_SUFFIX = 'TIMELINE_GRAPH';
export const ROLE_GROUPING_SUB_GROUP_TYPE = {
	PEOPLE_REMAINING: 'PEOPLE_REMAINING',
	PLACEHOLDERS_DEMAND: 'PLACEHOLDERS_DEMAND',
};
export const STAFFING_GROUPING_TYPE = {
	ALLOCATED_PEOPLE: 'ALLOCATED_PEOPLE',
	FILTERED_MATCHES: 'FILTERED_MATCHES',
};

class IDManager {
	/***
	 *
	 * @param pageComponent
	 * @param entityId - represents the entity of the project (person or placeholder)
	 * @param projectId
	 * @param projectGroupId
	 * @param programPrefix
	 * @returns {null|string}
	 */
	static getProjectGroupId(pageComponent, entityId, projectId, projectGroupId = undefined, programPrefix = undefined) {
		const {schedulingView} = pageComponent.props;

		if (projectId || projectGroupId || programPrefix) {
			switch (schedulingView) {
				case SCHEDULING_VIEW.PLACEHOLDERS:
				case SCHEDULING_VIEW.PEOPLE:
				case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
					return `${entityId}-${projectGroupId || projectId}`;
				case SCHEDULING_VIEW.PROJECTS:
					return projectId || projectGroupId || programPrefix;
				default:
					break;
			}
		} else {
			return this.getNonProjectTimeGroupId(pageComponent, entityId);
		}

		return null;
	}

	static getProjectGroupIdForTask(pageComponent, personId, task) {
		const project = DataManager.getProjectById(pageComponent, task.projectId);
		return this.getProjectGroupId(
			pageComponent,
			personId,
			project.projectGroupId ? null : project.id,
			project.projectGroupId,
			null
		);
	}

	/***
	 *
	 * @param pageComponent
	 * @param placeholder
	 * @returns {null|string}
	 */
	static getPlaceholderGroupId(pageComponent, placeholder) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PLACEHOLDERS:
			case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
				return `${placeholder.id}`;
			case SCHEDULING_VIEW.PROJECTS:
				return `${placeholder.projectId || placeholder.projectGroupId}-${placeholder.id}`;
			default:
				break;
		}

		return null;
	}

	static getPlaceholderGroupingId(pageComponent, roleId, projectId) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PLACEHOLDERS:
				const {staffingMode, groupBy} = pageComponent.state;
				return !staffingMode && groupBy === PLACEHOLDERS_GROUP_BY.ROLE ? roleId || NO_ROLE : projectId;
			case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
				return `${roleId || NO_ROLE}-${ENTITY_GROUPING_PLACEHOLDERS}`;
			default:
				break;
		}

		return null;
	}

	/***
	 *
	 * @param pageComponent
	 * @param entityId - represents the entity of the project (person or placeholder)
	 * @returns {null|string}
	 */
	static getEntityGroupId(pageComponent, entityId) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PLACEHOLDERS:
			case SCHEDULING_VIEW.PEOPLE:
			case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
				return entityId;
			default:
				break;
		}

		return null;
	}

	static getNoContentGroupId(pageComponent, person, project, phase, task = null) {
		const entityId = task?.id || phase?.id || project?.id || person?.id;

		if (entityId) {
			return `${entityId}-${NO_CONTENT_GROUP}`;
		}

		return null;
	}

	/***
	 *
	 * @param pageComponent
	 * @param person
	 * @param role
	 * @returns {null|string}
	 */
	static getPersonGroupingGroupId(pageComponent, person, role = null) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
				return `person-grouping-${role?.id || person?.roleId || NO_ROLE}`;
			case SCHEDULING_VIEW.PEOPLE:
				if (person) {
					const {groupBy} = pageComponent.state;

					if (groupBy === GROUP_BY.ROLE) {
						return person.roleId || NO_ROLE;
					} else if (groupBy === GROUP_BY.DEPARTMENT) {
						return person.departmentId || NO_DEPARTMENT;
					}
				}

				break;
			default:
				break;
		}

		return null;
	}

	static getNonProjectTimeGroupId(pageComponent, personId) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PEOPLE:
			case SCHEDULING_VIEW.PLACEHOLDERS:
			case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
			case SCHEDULING_VIEW.PROJECTS:
				return `${personId}-${NON_PROJECT_TIME_GROUP}`;
			default:
				break;
		}

		return null;
	}

	static getPersonAllocationsGroupId(pageComponent, personId) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PEOPLE:
				return `${personId}-${PERSON_ALLOCATIONS_GROUP}`;
			default:
				break;
		}

		return null;
	}

	static getPersonGroupId(pageComponent, personId, projectOrProjectGroupId = null) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PLACEHOLDERS:
			case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
			case SCHEDULING_VIEW.PEOPLE:
				return personId;
			case SCHEDULING_VIEW.PROJECTS:
				return projectOrProjectGroupId
					? `${projectOrProjectGroupId}-${personId}`
					: `${personId}-${NON_PROJECT_TIME_GROUP}`;
			default:
				break;
		}

		return null;
	}

	static getUnassignedTaskGroupId(pageComponent, roleId) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PEOPLE:
				return `${roleId ? roleId : NO_ROLE}-${UNASSIGNED_TASK_GROUP}`;
			default:
				break;
		}

		return null;
	}

	static getUnassignedRoleGroupId(pageComponent, roleId) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PEOPLE:
				return roleId || NO_ROLE;
			default:
				break;
		}

		return null;
	}

	static getTaskGroupId(pageComponent, task, personId = null) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PEOPLE:
				const {schedulingOptions} = pageComponent.state;

				const project = DataManager.getProjectById(pageComponent, task.projectId);

				if (
					hasFeatureFlag('tp_heatmap_spike') &&
					schedulingOptions.visualizationMode === VISUALIZATION_MODE.COMBINATION
				) {
					return this.getProjectEntityGroupId(
						pageComponent,
						personId,
						project.id,
						project.projectGroupId,
						PROJECT_ENTITY_GROUP_TYPE.TASK
					);
				}

				return personId
					? `${personId}-${project.isInProjectGroup ? project.projectGroupId : task.projectId}`
					: IDManager.getUnassignedTaskGroupId(pageComponent, task.roleId);
			case SCHEDULING_VIEW.PROJECTS:
				return task.id;
			default:
				break;
		}

		return null;
	}

	static getPhaseGroupId(pageComponent, phaseId, projectId) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PROJECTS:
				return phaseId || `${projectId}-${NO_PHASE}`;
			default:
				break;
		}

		return null;
	}

	static getPlaceholdersProjectSubGroupId(pageComponent, projectId) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PROJECTS:
				return `${projectId}-${PLACEHOLDERS_PROJECT_SUB_GROUP}`;
			default:
				break;
		}

		return null;
	}

	static getProjectSubGroupId(pageComponent, project, projectGroup, projectSubGroupType) {
		const projectSubGroupSuffix = PROJECT_SUB_GROUP_TYPE[projectSubGroupType];

		if (projectSubGroupSuffix !== undefined) {
			const {schedulingView} = pageComponent.props;

			switch (schedulingView) {
				case SCHEDULING_VIEW.PROJECTS:
					let prefix = project?.id;

					const projectGroupTypes = [PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM, PROJECT_SUB_GROUP_TYPE.PLACEHOLDERS];
					if (projectGroupTypes.includes(projectSubGroupType) && (projectGroup || project?.isInProjectGroup)) {
						prefix = projectGroup?.id || project.projectGroupId;
					}

					return `${prefix}-${projectSubGroupSuffix}`;
				default:
					break;
			}
		}

		return null;
	}

	static getTimelineGraphId(pageComponent) {
		const {schedulingView} = pageComponent.props;
		return `${schedulingView}-${TIMELINE_GRAPH_SUFFIX}`;
	}

	static getRoleGroupingGroupId(pageComponent, role, subGroupType) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
				if (ROLE_GROUPING_SUB_GROUP_TYPE[subGroupType] !== undefined) {
					if (subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING) {
						return this.getPersonGroupingGroupId(pageComponent, null, role);
					}

					if (subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND) {
						return this.getPlaceholderGroupingId(pageComponent, role.id, null);
					}
				}

				return role?.id || NO_ROLE;
			default:
				break;
		}

		return null;
	}

	static getProjectEntityGroupId(pageComponent, personId, projectId, projectGroupId, projectEntityGroupType) {
		const {schedulingView} = pageComponent.props;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PEOPLE:
				if (!projectId && !projectGroupId) {
					return this.getNonProjectTimeGroupId(pageComponent, personId);
				}

				return `${personId}-${projectGroupId || projectId}-${projectEntityGroupType}`;
			default:
				break;
		}

		return null;
	}

	static getTaskHeatmapGroupId(pageComponent, taskItem) {
		const {schedulingView} = pageComponent.props;
		const {schedulingOptions} = pageComponent.state;
		const {company} = pageComponent.getData();

		const isCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

		const {personId, task} = taskItem.data;
		const {assignedPersons, projectId} = task || {};

		if (assignedPersons) {
			const project = DataManager.getProjectById(pageComponent, projectId);

			if (isCombinedMode && schedulingView === SCHEDULING_VIEW.PEOPLE) {
				return this.getProjectGroupId(pageComponent, personId, project.id, project.projectGroupId);
			} else {
				return this.getPersonGroupId(pageComponent, personId, project.projectGroupId || project.id);
			}
		}

		return null;
	}

	static getAllocationHeatmapGroupId(pageComponent, allocationItem) {
		const {schedulingView} = pageComponent.props;
		const {schedulingOptions} = pageComponent.state;
		const {company} = pageComponent.getData();

		const isCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

		const {allocation} = allocationItem.data;
		const {personId, projectId, projectGroupId} = allocation || {};

		if (isCombinedMode && schedulingView === SCHEDULING_VIEW.PEOPLE) {
			if (!projectId && !projectGroupId) {
				return this.getPersonGroupId(pageComponent, personId);
			} else {
				return this.getProjectGroupId(pageComponent, personId, projectId, projectGroupId);
			}
		}

		return this.getPersonGroupId(pageComponent, personId, projectGroupId || projectId);
	}

	static getPlaceholderAllocationHeatmapGroupId(pageComponent, placeholderAllocationItem) {
		const {placeholderAllocation} = placeholderAllocationItem.data;
		const {placeholderId} = placeholderAllocation || {};

		if (placeholderId) {
			const placeholder = DataManager.getPlaceholderById(pageComponent, placeholderId);

			if (placeholder) {
				const {schedulingView} = pageComponent.props;

				if (schedulingView === SCHEDULING_VIEW.PLACEHOLDERS) {
					const {roleId, projectId, projectGroupId} = placeholder;
					return this.getPlaceholderGroupingId(pageComponent, roleId, projectGroupId || projectId);
				} else if (schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW) {
					return this.getPlaceholderGroupId(pageComponent, placeholder);
				}
			}
		}

		return null;
	}

	static getTimeRegHeatmapGroupId(pageComponent, timeReg) {
		const {schedulingView} = pageComponent.props;
		const {schedulingOptions} = pageComponent.state;
		const {company} = pageComponent.getData();

		const isCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
		const {personId, projectOrTaskProjectId} = timeReg;

		switch (schedulingView) {
			case SCHEDULING_VIEW.PEOPLE:
				if (isCombinedMode && projectOrTaskProjectId) {
					return this.getProjectGroupId(pageComponent, personId, projectOrTaskProjectId);
				}

				return this.getPersonGroupId(pageComponent, personId);
			default:
				break;
		}

		return null;
	}

	static getHeatmapGroupIdFromItem(pageComponent, item) {
		switch (item.itemType) {
			case ITEM_TYPE.TASK:
				return this.getTaskHeatmapGroupId(pageComponent, item);
			case ITEM_TYPE.PROJECT_ALLOCATION:
				return this.getAllocationHeatmapGroupId(pageComponent, item);
			case ITEM_TYPE.PLACEHOLDER_ALLOCATION:
				return this.getPlaceholderAllocationHeatmapGroupId(pageComponent, item);
			default:
				return null;
		}
	}
}

export default IDManager;
