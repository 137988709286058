/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type peopleListPortfolioComponent_viewer$ref = any;
export type peopleListPortfolioComponent_QueryVariables = {|
  shareKey?: ?string,
  componentId?: ?string,
  personIds?: ?$ReadOnlyArray<?string>,
  departmentIds?: ?$ReadOnlyArray<?string>,
  teamIds?: ?$ReadOnlyArray<?string>,
  roleIds?: ?$ReadOnlyArray<?string>,
  labelIds?: ?$ReadOnlyArray<?string>,
  skillIds?: ?$ReadOnlyArray<?string>,
|};
export type peopleListPortfolioComponent_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: peopleListPortfolioComponent_viewer$ref,
  |}
|};
export type peopleListPortfolioComponent_Query = {|
  variables: peopleListPortfolioComponent_QueryVariables,
  response: peopleListPortfolioComponent_QueryResponse,
|};
*/


/*
query peopleListPortfolioComponent_Query(
  $shareKey: String
  $componentId: ID
  $personIds: [ID]
  $departmentIds: [ID]
  $teamIds: [ID]
  $roleIds: [ID]
  $labelIds: [ID]
  $skillIds: [ID]
) {
  viewer {
    actualPersonId
    component(name: "insight_people_list_portfolio")
    ...peopleListPortfolioComponent_viewer_RaeHT
    id
  }
}

fragment peopleListPortfolioComponent_viewer_RaeHT on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    peopleListPortfolio(componentId: $componentId, personIds: $personIds, departmentIds: $departmentIds, teamIds: $teamIds, roleIds: $roleIds, labelIds: $labelIds, skillIds: $skillIds) {
      currency
      planningProjectsCountTotal
      runningProjectsCountTotal
      haltedProjectsCountTotal
      doneProjectsCountTotal
      projectsCountTotal
      todoTasksCountTotal
      inProgressTasksCountTotal
      doneTasksCountTotal
      tasksCountTotal
      reportedTotal
      remainingMinutesTotal
      remainingPointsTotal
      spendTotal
      people {
        id
        name
        profilePictureId
        profilePictureDefaultId
        defaultRole
        email
        departmentName
        labels {
          id
          name
          color
        }
        skills {
          id
          name
        }
        profiles(first: 10000) {
          edges {
            node {
              id
              name
            }
          }
        }
        tasksCount
        todoTasksCount
        progressTasksCount
        doneTasksCount
        planningProjects
        runningProjects
        haltedProjects
        doneProjects
        totalProjects
        reportedMinutes
        remainingMinutes
        remainingPoints
        spend
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "componentId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "departmentIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roleIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "labelIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skillIds",
    "type": "[ID]"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "insight_people_list_portfolio"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"insight_people_list_portfolio\")"
},
v3 = {
  "kind": "Variable",
  "name": "componentId",
  "variableName": "componentId"
},
v4 = {
  "kind": "Variable",
  "name": "departmentIds",
  "variableName": "departmentIds"
},
v5 = {
  "kind": "Variable",
  "name": "labelIds",
  "variableName": "labelIds"
},
v6 = {
  "kind": "Variable",
  "name": "personIds",
  "variableName": "personIds"
},
v7 = {
  "kind": "Variable",
  "name": "roleIds",
  "variableName": "roleIds"
},
v8 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v9 = {
  "kind": "Variable",
  "name": "skillIds",
  "variableName": "skillIds"
},
v10 = {
  "kind": "Variable",
  "name": "teamIds",
  "variableName": "teamIds"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = [
  (v11/*: any*/),
  (v12/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "peopleListPortfolioComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "peopleListPortfolioComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "peopleListPortfolioComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              (v8/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "concreteType": "PeopleList_Portfolio",
                "kind": "LinkedField",
                "name": "peopleListPortfolio",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "planningProjectsCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "runningProjectsCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "haltedProjectsCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doneProjectsCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectsCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "todoTasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inProgressTasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doneTasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reportedTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remainingMinutesTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remainingPointsTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "spendTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PeopleList_PortfolioPersonType",
                    "kind": "LinkedField",
                    "name": "people",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureDefaultId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "defaultRole",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "departmentName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Label",
                        "kind": "LinkedField",
                        "name": "labels",
                        "plural": true,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "skills",
                        "plural": true,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 10000
                          }
                        ],
                        "concreteType": "ProfileTypeConnection",
                        "kind": "LinkedField",
                        "name": "profiles",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Profile",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v13/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "profiles(first:10000)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tasksCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "todoTasksCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "progressTasksCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "doneTasksCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "planningProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "runningProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "haltedProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "doneProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalProjects",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reportedMinutes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remainingMinutes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remainingPoints",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "spend",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "peopleListPortfolioComponent_Query",
    "operationKind": "query",
    "text": "query peopleListPortfolioComponent_Query(\n  $shareKey: String\n  $componentId: ID\n  $personIds: [ID]\n  $departmentIds: [ID]\n  $teamIds: [ID]\n  $roleIds: [ID]\n  $labelIds: [ID]\n  $skillIds: [ID]\n) {\n  viewer {\n    actualPersonId\n    component(name: \"insight_people_list_portfolio\")\n    ...peopleListPortfolioComponent_viewer_RaeHT\n    id\n  }\n}\n\nfragment peopleListPortfolioComponent_viewer_RaeHT on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    peopleListPortfolio(componentId: $componentId, personIds: $personIds, departmentIds: $departmentIds, teamIds: $teamIds, roleIds: $roleIds, labelIds: $labelIds, skillIds: $skillIds) {\n      currency\n      planningProjectsCountTotal\n      runningProjectsCountTotal\n      haltedProjectsCountTotal\n      doneProjectsCountTotal\n      projectsCountTotal\n      todoTasksCountTotal\n      inProgressTasksCountTotal\n      doneTasksCountTotal\n      tasksCountTotal\n      reportedTotal\n      remainingMinutesTotal\n      remainingPointsTotal\n      spendTotal\n      people {\n        id\n        name\n        profilePictureId\n        profilePictureDefaultId\n        defaultRole\n        email\n        departmentName\n        labels {\n          id\n          name\n          color\n        }\n        skills {\n          id\n          name\n        }\n        profiles(first: 10000) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        tasksCount\n        todoTasksCount\n        progressTasksCount\n        doneTasksCount\n        planningProjects\n        runningProjects\n        haltedProjects\n        doneProjects\n        totalProjects\n        reportedMinutes\n        remainingMinutes\n        remainingPoints\n        spend\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5c667885508d0d5659b7fcd81c783a32';

module.exports = node;
