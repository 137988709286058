import React, {Component} from 'react';
import {FormattedMessage, injectIntl} from 'react-intl';
import FlexModal from '../containers/modal/flex_modal';

class MutationErrorModal extends Component {
	componentDidMount() {
		if (this.props.errorType === 'No Viewer') {
			window.location.href = '/login';
		}
	}

	defaultCallback(shouldClose) {
		if (this.props.isNonCriticalError) {
			if (shouldClose) {
				this.props.closeModal();
			}
		} else if (this.props.reload_to_upcoming) {
			window.location.href = '/';
		} else {
			window.location.reload(true);
		}
	}

	getErrorContent(errorType) {
		switch (errorType) {
			case 'No Viewer':
				return (
					<>
						<span style={{marginBottom: '8px'}}>
							<FormattedMessage id="mutation_error_modal.no_viewer_description" />
						</span>
						<span>
							<FormattedMessage id="mutation_error_modal.no_viewer_description_2" />
						</span>
					</>
				);
			case '404':
				return (
					<>
						<span style={{marginBottom: '16px'}}>
							<FormattedMessage id="mutation_error_modal.404_description" />
						</span>
						<span>
							<FormattedMessage id="mutation_error_modal.404_description_2" />
						</span>
					</>
				);

			default:
				return (
					<span>
						<FormattedMessage id="mutation_error_modal.description" />
					</span>
				);
		}
	}

	render() {
		if (this.props.isNetworkError) {
			return (
				<FlexModal
					options={[]}
					headerText={this.props.intl.formatMessage({id: 'network_error_modal.header'})}
					content={
						<p>
							<FormattedMessage id="network_error_modal.description" />
						</p>
					}
					defaultCallback={this.defaultCallback.bind(this)}
					closeModal={this.defaultCallback.bind(this)}
				/>
			);
		}
		return (
			<FlexModal
				closeModal={this.defaultCallback.bind(this)}
				headerText={this.props.intl.formatMessage({id: 'mutation_error_modal.header'})}
				content={this.getErrorContent(this.props.errorType)}
				options={[{label: this.props.intl.formatMessage({id: 'common.ok'})}]}
				defaultCallback={this.defaultCallback.bind(this, true)}
			/>
		);
	}
}

export default injectIntl(MutationErrorModal);
