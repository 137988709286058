import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown, SingleText} from 'web-components';
import React from 'react';
import {useIntl} from 'react-intl';

export const SkillLevelDropdown = ({
	company,
	dropdownAlignment,
	width,
	name,
	optionsName,
	selectedItems,
	onSelect,
	onRemove,
	selectedGroupName,
	userpilot,
	emphasizeEmptyState,
	cy,
}) => {
	const intl = useIntl();
	const skillLevels = company.skillLevels;

	const sortedSkillLevels = [...skillLevels].sort((a, b) => {
		if (a.level < b.level) return -1;
		if (a.level > b.level) return 1;
		return 0;
	});

	const getElements = () => {
		let elements = [];

		elements = elements.concat(
			sortedSkillLevels.map(skillLevel => {
				const {id, level, description} = skillLevel;
				return (
					<SingleText key={id} value={id} searchString={description}>
						{level} - {description}
					</SingleText>
				);
			})
		);

		elements.push(
			<SingleText key={null} value={null} searchString={intl.formatMessage({id: 'settings_skills.levels.no_level'})}>
				{intl.formatMessage({id: 'settings_skills.levels.no_level'})}
			</SingleText>
		);

		return elements;
	};
	return (
		<Dropdown
			isNested
			isMultiSelect
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
			cy={cy}
		>
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{getElements()}
			</Dropdown.Group>
		</Dropdown>
	);
};

export default createFragmentContainer(SkillLevelDropdown, {
	company: graphql`
		fragment SkillLevelDropdown_company on Company {
			skillLevels {
				id
				level
				description
			}
		}
	`,
});
