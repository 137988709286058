import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import ChartJsBar from '../../chartjs/chart_js_bar';

class plannedVsSpentComponent extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}

	render() {
		const {formatMessage} = this.props.intl;
		if (this.props.viewer.insightComponentsData.plannedVsSpent) {
			const data = {
				labels: [''],
				datasets: [],
			};
			data.datasets.push({
				label: formatMessage({id: 'common.estimate'}),
				backgroundColor: '#EA82FD',
				stack: 'Stack 0',
				maxBarThickness: 60,
				data: [this.props.viewer.insightComponentsData.plannedVsSpent.minutesPlanned / 60.0],
			});
			data.datasets.push({
				label: formatMessage({id: 'common.reported'}),
				backgroundColor: '#44B4FF',
				stack: 'Stack 1',
				maxBarThickness: 60,
				data: [this.props.viewer.insightComponentsData.plannedVsSpent.minutesSpent / 60.0],
			});

			return (
				<ChartJsBar data={data} yAxisLabel={formatMessage({id: 'common.hours'})} height={150} type="horizontalBar" />
			);
		} else {
			return null;
		}
	}
}

const plannedVsSpentComponentQuery = graphql`
	query plannedVsSpentComponent_Query($shareKey: String, $projectId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_planned_vs_spent")
			...plannedVsSpentComponent_viewer
				@arguments(shareKey: $shareKey, projectId: $projectId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {plannedVsSpentComponentQuery};

export default injectIntl(
	createFragmentContainer(plannedVsSpentComponent, {
		viewer: graphql`
			fragment plannedVsSpentComponent_viewer on Viewer
			@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
				insightComponentsData(shareKey: $shareKey) {
					plannedVsSpent(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
						minutesPlanned
						minutesSpent
					}
				}
			}
		`,
	})
);
