/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ExpenseRow_phaseBaselineExpense$ref = any;
type RoleRow_phaseBaselineRole$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Phase_phase$ref: FragmentReference;
declare export opaque type Phase_phase$fragmentType: Phase_phase$ref;
export type Phase_phase = {|
  +id: string,
  +name: ?string,
  +baselineTargetPrice: ?number,
  +baselineTargetMinutes: ?number,
  +baselineCost: ?number,
  +baselineProfit: ?number,
  +startYear: ?number,
  +startMonth: ?number,
  +startDay: ?number,
  +deadlineYear: ?number,
  +deadlineMonth: ?number,
  +deadlineDay: ?number,
  +baselineStartYear: ?number,
  +baselineStartMonth: ?number,
  +baselineStartDay: ?number,
  +baselineDeadlineYear: ?number,
  +baselineDeadlineMonth: ?number,
  +baselineDeadlineDay: ?number,
  +phaseBaselineRoles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: RoleRow_phaseBaselineRole$ref,
      |}
    |}>
  |},
  +phaseBaselineExpenses: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +$fragmentRefs: ExpenseRow_phaseBaselineExpense$ref,
      |}
    |}>
  |},
  +$refType: Phase_phase$ref,
|};
export type Phase_phase$data = Phase_phase;
export type Phase_phase$key = {
  +$data?: Phase_phase$data,
  +$fragmentRefs: Phase_phase$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "phaseBaselineRoles"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "phaseBaselineExpenses"
        ]
      }
    ]
  },
  "name": "Phase_phase",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineTargetPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineTargetMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deadlineYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deadlineMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deadlineDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineStartYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineStartMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineStartDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineDeadlineYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineDeadlineMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineDeadlineDay",
      "storageKey": null
    },
    {
      "alias": "phaseBaselineRoles",
      "args": null,
      "concreteType": "PhaseBaselineRoleTypeConnection",
      "kind": "LinkedField",
      "name": "__Phase_phaseBaselineRoles_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhaseBaselineRoleTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PhaseBaselineRoleType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RoleRow_phaseBaselineRole"
                }
              ],
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "phaseBaselineExpenses",
      "args": null,
      "concreteType": "PhaseBaselineExpenseTypeConnection",
      "kind": "LinkedField",
      "name": "__Phase_phaseBaselineExpenses_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PhaseBaselineExpenseTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PhaseBaselineExpenseType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ExpenseRow_phaseBaselineExpense"
                }
              ],
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "PhaseType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '97e552a2750534b55dd2549f73009f47';

module.exports = node;
