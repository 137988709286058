import React, {useState} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {cloneDeep, groupBy, partition, uniqBy} from 'lodash';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import Person from '../../../forecast-app/shared/components/person/person';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE} from '../../../constants';
import Util from '../../../forecast-app/shared/util/util';
import {
	AddPersonSection,
	DatePickerGroup,
	ExceptionsSection,
	ExceptionTable,
	LockTimeSection,
	Text,
	Title,
} from './TimeLockPage_styled';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Moment from 'moment';
import Switch from '../../../forecast-app/shared/components/switch/switch';
import UpdateTimeLockMutation from '../../../mutations/update_time_lock_mutation.modern';
import * as tracking from '../../../tracking';
import {usingTimeApproval} from '../../../forecast-app/shared/util/FeatureUtil';
import GenericModal from '../../../containers/modal/generic_modal';
import {ModalWrapper} from '../../../containers/modal/modal_styled';
import {createToast} from '../../../forecast-app/shared/components/toasts/toast';
import {PersonDropdown} from '../../../forecast-app/shared/components/dropdowns/Person_dropdown';
import {ProjectIndicatorWrapper, TableHeader} from '../../../containers/modal/time_approval/ActivateTimeApprovalModal_styled';
import {AssigneeGroup, ProjectIndicator} from 'web-components';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {profilePicSrc} from '../../../directApi';

const TimeLockModal = ({relay, intl, viewer, closeModal}) => {
	const [selectedDate, setSelectedDate] = useState(
		Util.CreateNonUtcMomentDate(
			viewer.company.lockedPeriodYear,
			viewer.company.lockedPeriodMonth,
			viewer.company.lockedPeriodDay
		)
	);
	const sortedPersons = cloneDeep(viewer.company.allPersons.edges).sort((a, b) =>
		Util.sortAlphabetically(a.node.fullName, b.node.fullName)
	);
	const personPartition = partition(sortedPersons, person => {
		return person.node.excludeFromCompanyLockedPeriod;
	});
	const useTimeApproval = usingTimeApproval(viewer.company.useTimeApproval);
	const hasUnapprovedTime = viewer.company.timeRegistrations && viewer.company.timeRegistrations.edges.length > 0;

	const timeRegsProjectGrouping = groupBy(
		viewer.company.timeRegistrations?.edges,
		timeReg => timeReg.node.task?.project.id || timeReg.node.project?.id || timeReg.node.idleTime?.id
	);

	const onSelect = personId => {
		tracking.trackPageAction('Time Locking - Exception added');
		trackEvent('Time Lock Person Exception', 'Added');

		Util.CommitMutation(UpdateTimeLockMutation, {
			companyId: viewer.company.id,
			personId: personId[0],
			excludeFromCompanyLockedPeriod: true,
		});
	};

	const onRemove = personId => {
		Util.CommitMutation(UpdateTimeLockMutation, {
			companyId: viewer.company.id,
			personId: personId,
			excludeFromCompanyLockedPeriod: false,
		});
	};

	const onDateChange = date => {
		setSelectedDate(date);
		if (useTimeApproval) {
			const dateString = date ? date.clone().format('YYYY-MM-DD') : '';
			relay.refetch({
				fetchTimeRegs: !!date,
				endDate: dateString,
			});
		}
	};

	const onSwitchToggle = () => {
		onDateChange(selectedDate ? null : Moment().add(-1, 'd'));
	};

	const handleConfirmButton = () => {
		Util.CommitMutation(
			UpdateTimeLockMutation,
			{
				companyId: viewer.company.id,
				lockedPeriodYear: selectedDate ? selectedDate.year() : null,
				lockedPeriodMonth: selectedDate ? selectedDate.month() + 1 : null,
				lockedPeriodDay: selectedDate ? selectedDate.date() : null,
			},
			() =>
				createToast({
					duration: 5000,
					message: 'Timesheets locked',
				})
		);
	};

	const formatProjectTimeData = projectTimeData => {
		if (!projectTimeData || projectTimeData.length === 0) return null;
		const data = {};
		const projectTimeEntry = projectTimeData[0].node;
		const project = projectTimeEntry.task ? projectTimeEntry.task.project : projectTimeEntry.project;
		const idleTime = projectTimeEntry.idleTime;

		const mapPerson = person => ({
			fullName: person.fullName,
			imageSource: profilePicSrc(person.profilePictureId),
		});

		if (project) {
			data.name = project.name;
			data.project = project;
			data.projectOwners = project.projectPersons.edges.map(projectOwner => mapPerson(projectOwner.node.person));
		} else if (idleTime) {
			data.name = idleTime.name;
			data.projectOwners = uniqBy(projectTimeData, 'node.person.id').map(timeReg => mapPerson(timeReg.node.person));
		}

		const sortedProjectTimeData = Util.sortTimeRegs(projectTimeData);
		const earliestTReg = sortedProjectTimeData[0].node;
		const latestTReg = sortedProjectTimeData[sortedProjectTimeData.length - 1].node;
		data.earliestTimeDate = Util.CreateMomentDate(earliestTReg.year, earliestTReg.month, earliestTReg.day).format('D MMM');
		data.latestTimeDate = Util.CreateMomentDate(latestTReg.year, latestTReg.month, latestTReg.day).format('D MMM YYYY');

		return data;
	};

	const content = (
		<ModalWrapper>
			<LockTimeSection>
				<Text margin={24}>{intl.formatMessage({id: 'time-lock.lock_desc_1'})}</Text>
				<Text>{intl.formatMessage({id: 'time-lock.lock_desc_2'})}</Text>
				<DatePickerGroup>
					<DatePicker
						startDate={selectedDate}
						handleDateRangeChange={onDateChange}
						datePickerStyle={DATE_PICKER_STYLE.STANDARD}
						isSingleDatePicker={true}
						endDateLimite={Moment()}
					/>
					<Switch onChange={onSwitchToggle} checked={selectedDate !== null} sliderWidth={38} sliderHeight={20} />
				</DatePickerGroup>
			</LockTimeSection>
			{selectedDate && !useTimeApproval ? (
				<ExceptionsSection>
					<Title>{intl.formatMessage({id: 'time-lock.exceptions'})}</Title>
					<Text margin={16}>{intl.formatMessage({id: 'time-lock.exceptions-desc'})}</Text>
					{personPartition[1].length > 0 ? (
						<AddPersonSection>
							<PersonDropdown
								persons={personPartition[1]}
								name={'Add Person'}
								optionsName={intl.formatMessage({id: 'common.persons'})}
								selectedGroupName={intl.formatMessage({id: 'common.applied'})}
								selectedItems={personPartition[0]}
								onRemove={ids => onRemove(ids)}
								onSelect={ids => onSelect(ids)}
								dropdownAlignment={'left'}
								width={275}
								usePortal
								maxHeight={255}
							/>
						</AddPersonSection>
					) : null}
					<ExceptionTable>
						{personPartition[0].length === 0 ? (
							<Text>{intl.formatMessage({id: 'time-lock.no_exceptions'})}</Text>
						) : (
							<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={200}>
								<table className="time-lock-table">
									<tbody>
										{personPartition[0].map(person => (
											<tr key={person.node.id} className="time-lock-row">
												<td className="name-column person-link-cell">
													<Person
														key={person.node.id}
														name={person.node.fullName}
														showName={true}
														showRole={false}
														imageSrc={profilePicSrc(person.node.profilePictureId)}
														imageSize="medium"
													/>
												</td>
												<td>
													<button
														className="delete-person-button"
														onClick={onRemove.bind(this, person.node.id)}
													/>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</CustomScrollDiv>
						)}
					</ExceptionTable>
				</ExceptionsSection>
			) : selectedDate && hasUnapprovedTime ? (
				<ExceptionsSection>
					<Title>{intl.formatMessage({id: 'approval.missing_approvals'})}</Title>
					<Text margin={4}>{intl.formatMessage({id: 'approval.missing_approvals_info'})}</Text>
					<Text margin={32}>{intl.formatMessage({id: 'approval.missing_approvals_info_2'})}</Text>
					<ExceptionTable flexHeight>
						<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={280}>
							<table className="time-lock-table">
								<thead>
									<tr>
										<TableHeader>{intl.formatMessage({id: 'common.project'})}</TableHeader>
										<TableHeader>{intl.formatMessage({id: 'approval.missing_approvals'})}</TableHeader>
										<TableHeader>{intl.formatMessage({id: 'common.project_owners'})}</TableHeader>
									</tr>
								</thead>
								<tbody>
									{Object.entries(timeRegsProjectGrouping).map(([key, value]) => {
										const projectTimeData = formatProjectTimeData(value);
										if (!projectTimeData) return null;
										return (
											<tr key={key} className="time-lock-row">
												<td>
													<ProjectIndicatorWrapper>
														{projectTimeData.project && (
															<ProjectIndicator
																name={projectTimeData.project.name}
																id={projectTimeData.project.id}
																project={projectTimeData.project}
															></ProjectIndicator>
														)}
														<span title={projectTimeData.name}>{projectTimeData.name}</span>
													</ProjectIndicatorWrapper>
												</td>
												<td>
													<span>
														{projectTimeData.earliestTimeDate +
															' - ' +
															projectTimeData.latestTimeDate}
													</span>
												</td>
												<td>
													<AssigneeGroup
														alignStart
														iconSpaceBetween={8}
														showCount={3}
														assignees={projectTimeData.projectOwners}
													/>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</CustomScrollDiv>
					</ExceptionTable>
				</ExceptionsSection>
			) : null}
		</ModalWrapper>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			buttons={[
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: hasUnapprovedTime ? 'baseline.approve_and_lock' : 'common.save'}),
					style: BUTTON_STYLE.FILLED,
					color: hasUnapprovedTime ? BUTTON_COLOR.RED : BUTTON_COLOR.GREEN,
					callback: handleConfirmButton.bind(this),
				},
			]}
			headerText={intl.formatMessage({id: 'time-lock.lock_time_registrations'})}
			content={content}
		/>
	);
};

const TimeLockModalQuery = graphql`
	query TimeLockModal_Query($endDate: String, $fetchTimeRegs: Boolean!) {
		viewer {
			actualPersonId
			component(name: "time-lock")
			...TimeLockModal_viewer @arguments(endDate: $endDate, fetchTimeRegs: $fetchTimeRegs)
		}
	}
`;

export {TimeLockModalQuery};

export default injectIntl(
	createRefetchContainer(
		TimeLockModal,
		{
			viewer: graphql`
				fragment TimeLockModal_viewer on Viewer
				@argumentDefinitions(endDate: {type: "String"}, fetchTimeRegs: {type: "Boolean!"}) {
					id
					actualPersonId
					availableFeatureFlags {
						key
					}
					company {
						id
						lockedPeriodYear
						lockedPeriodMonth
						lockedPeriodDay
						useTimeApproval
						modules {
							moduleType
						}
						allPersons(first: 10000, excludeClientUsers: true, onlyActive: true) {
							edges {
								...PersonDropdown_persons
								node {
									id
									fullName
									firstName
									lastName
									email
									profilePictureId
									profilePictureDefaultId
									excludeFromCompanyLockedPeriod
									submitLockedDateYear
									submitLockedDateMonth
									submitLockedDateDay
									role {
										name
									}
									isViewer
									active
								}
							}
						}
						timeRegistrations(
							first: 10000000
							endDate: $endDate
							searchQuery: {filters: [{field: APPROVAL_STATUS, value: ["NOT_SUBMITTED", "SUBMITTED"]}]}
							approvableTime: true
							approvableTimeOff: true
						)
							@include(if: $fetchTimeRegs)
							@connection(key: "LockTimeModal_Company_timeRegistrations", filters: []) {
							edges {
								node {
									id
									approvalStatus
									day
									month
									year
									person {
										id
										fullName
										profilePictureId
									}
									project {
										id
										name
										companyProjectId
										projectColor
										projectPersons(first: 100, contactsOnly: true) {
											edges {
												node {
													person {
														id
														fullName
														profilePictureId
													}
												}
											}
										}
									}
									task {
										id
										project {
											id
											name
											companyProjectId
											projectColor
											projectPersons(first: 100, contactsOnly: true) {
												edges {
													node {
														person {
															id
															fullName
															profilePictureId
														}
													}
												}
											}
										}
									}
									idleTime {
										id
										name
									}
								}
							}
						}
					}
				}
			`,
		},
		graphql`
			query TimeLockModalRefetchQuery($endDate: String, $fetchTimeRegs: Boolean!) {
				viewer {
					...TimeLockModal_viewer @arguments(endDate: $endDate, fetchTimeRegs: $fetchTimeRegs)
				}
			}
		`
	)
);
