import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ContentState, convertFromHTML, convertFromRaw, EditorState} from 'draft-js';
import Editor from 'draft-js-plugins-editor';

class TableCellRichtextItem extends Component {
	constructor(props) {
		super(props);

		let content = null;
		if (this.props.text !== undefined && this.props.text !== null && this.props.text !== '') {
			try {
				if (this.props.text.startsWith('<div>') || this.props.text.startsWith('<p>')) {
					const blocksFromHTML = convertFromHTML(this.props.text);
					content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
				} else {
					content = convertFromRaw(JSON.parse(this.props.text));
				}
			} catch (err) {
				content = ContentState.createFromText(this.props.text);
			}
		}
		this.editorState = content ? EditorState.createWithContent(content) : EditorState.createEmpty();

		this.state = {
			expanded: false,
			showToRight: true,
		};
	}

	componentDidMount() {
		if (this.props.text !== null) {
			this.calculatePlacement();
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		let content;
		if (nextProps.text !== undefined && nextProps.text !== null && nextProps.text !== '') {
			try {
				if (nextProps.text.startsWith('<div>') || nextProps.text.startsWith('<p>')) {
					const blocksFromHTML = convertFromHTML(nextProps.text);
					content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
				} else {
					content = convertFromRaw(JSON.parse(nextProps.text));
				}
			} catch (err) {
				content = ContentState.createFromText(nextProps.text);
			}
		}
		this.editorState = content ? EditorState.createWithContent(content) : EditorState.createEmpty();
	}

	onChange() {}

	onFocus() {
		if (this.props.text !== null) {
			this.setState({expanded: true});
		}
	}

	onBlur() {
		this.setState({expanded: false});
	}

	//TODO calculate if foldout should be displayed to the right or left depending on place available. Default is to the right (left:0)
	calculatePlacement() {
		this.setState({showToRight: window.innerWidth - this.description_cell.getBoundingClientRect().left > 200});
	}

	render() {
		return (
			<div
				className="table-cell-description-wrapper"
				tabIndex="0"
				onFocus={this.onFocus.bind(this)}
				onBlur={this.onBlur.bind(this)}
			>
				<div
					ref={div => (this.description_cell = div)}
					className={
						!this.state.expanded
							? this.props.text !== null
								? 'inline-description'
								: ''
							: 'foldout-description' + (this.state.showToRight ? ' shwoToRight' : ' showToLeft')
					}
				>
					{this.props.text !== null ? (
						<Editor onChange={this.onChange} readOnly={true} editorState={this.editorState} />
					) : null}
				</div>
			</div>
		);
	}
}

TableCellRichtextItem.propTypes = {
	text: PropTypes.string,
	placeholder: PropTypes.string.isRequired,
};

export default TableCellRichtextItem;
