import React, {useState} from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import CustomScrollDiv from '../../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import BudgetInternalCostLineItem from '../components/budget_internal_cost_line_item';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../../../../constants';
import HeaderBar from '../../../../../forecast-app/shared/components/headers/header-bar/header_bar';
import Util from '../../../../../forecast-app/shared/util/util';
import BudgetRoleRate from '../components/budget_role_rate';
import TooltipContainer from '../../../../../forecast-app/shared/components/tooltips/tooltip_container';
import InformationIcon from '../../../../../images/information-icon';
import * as tracking from '../../../../../tracking';
import {MODAL_TYPE, showModal} from '../../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import RateCardDropdown, {changeRateCard} from '../../../../dropdowns/rate-card-dropdown/Rate_card_dropdown';
import {RateCardUtil} from '../../../../../forecast-app/shared/util/RateCardUtil';
import moment from 'moment';
import {hasPermission, personIsClientUser} from '../../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../Permissions';
import {createFragmentContainer, graphql} from 'react-relay';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import ProjectUtil from '../../../../../forecast-app/shared/util/project_util';
import {useTrackPage} from '../../../../../tracking/amplitude/hooks/useTrackPage';

const RatesAndInternalCostPage = ({project, company, retry, timestamp}) => {
	useTrackPage('Project Financials Rates And Internal Cost', null, {budgetType: project.budgetType});
	const [financialQueryTimestamp, setFinancialQueryTimestamp] = useState(timestamp);
	if (timestamp !== financialQueryTimestamp) {
		setFinancialQueryTimestamp(timestamp);
		retry();
	}

	const [showPeopleWithMissingCost, setShowPeopleWithMissingCost] = useState(
		localStorage.getItem('internalCostExpandedSectionsBudgetV2')
			? JSON.parse(localStorage.getItem('internalCostExpandedSectionsBudgetV2')).people_with_missing_cost !== undefined
				? JSON.parse(localStorage.getItem('internalCostExpandedSectionsBudgetV2')).people_with_missing_cost
				: true
			: true
	);
	const [showPeopleWithCost, setShowPeopleWithCost] = useState(
		localStorage.getItem('internalCostExpandedSectionsBudgetV2')
			? JSON.parse(localStorage.getItem('internalCostExpandedSectionsBudgetV2')).people_with_cost !== undefined
				? JSON.parse(localStorage.getItem('internalCostExpandedSectionsBudgetV2')).people_with_cost
				: true
			: true
	);
	const [showRoleRates, setShowRoleRates] = useState(
		localStorage.getItem('internalCostExpandedSectionsBudgetV2')
			? JSON.parse(localStorage.getItem('internalCostExpandedSectionsBudgetV2')).role_rates !== undefined
				? JSON.parse(localStorage.getItem('internalCostExpandedSectionsBudgetV2')).role_rates
				: true
			: true
	);

	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();
	const hasProjectPersonUpdatePermission = hasPermission(PERMISSION_TYPE.PROJECT_PERSON_UPDATE);
	const canViewInternalCostRate = !hasRevenueWithoutCostAccess && hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);

	const isFixedPrice = !!project.budget;
	const isDoneOrHalted = project.status === 'DONE' || project.status === 'HALTED';
	const {formatMessage} = useIntl();
	const projectPersons = project.projectPersons.edges
		.map(edge => edge.node)
		.filter(projectPerson => !personIsClientUser(projectPerson.person));
	const projectPersonsWithMissingCost = projectPersons.filter(projectPerson => {
		const currentCostPeriod = Util.getCurrentCostPeriod(projectPerson.person.costPeriods.edges);
		if (currentCostPeriod && currentCostPeriod.node.cost !== 0) {
			return false;
		} else {
			return true;
		}
	});
	const projectPersonsWithCost = projectPersons.filter(projectPerson => {
		const currentCostPeriod = Util.getCurrentCostPeriod(projectPerson.person.costPeriods.edges);
		if (currentCostPeriod && currentCostPeriod.node.cost !== 0) {
			return true;
		} else {
			return false;
		}
	});

	const roles = company.roles;
	const disabledRoleIds = project && project.rateCard ? project.rateCard.disabledRoles.map(role => role.id) : [];
	const enabledRoles = roles.edges.filter(role => !disabledRoleIds.includes(role.node.id));
	const rateCardUtil = new RateCardUtil(project.rateCard, roles);
	const rolesWithRates = rateCardUtil
		? roles.edges
				.filter(role => !disabledRoleIds.includes(role.node.id))
				.map(role => {
					const rate = rateCardUtil.getRate(role.node.id, moment());
					const rolePersons = projectPersons
						.filter(projectPerson => projectPerson.role && projectPerson.role.id === role.node.id)
						.map(projectPerson => projectPerson.person);
					return {name: role.node.name, rate, rolePersons};
				})
		: [];

	const currencySymbol = Util.GetCurrencySymbol(project.rateCard ? project.rateCard.currency : company.currency);
	const {taskCostCalculationType, unassignedTaskHourlyCost} = project;

	const showCostRateModal = () => {
		showModal({
			type: MODAL_TYPE.COST_RATE,
			currencySymbol,
			taskCostCalculationType,
			unassignedTaskHourlyCost,
			project,
		});

		tracking.trackElementClicked('Change Cost Rate button');
		trackEvent('Change Cost Rate Button', 'Clicked');
	};

	const getHeader = () => {
		const rightContent = [];
		const leftContent = [];

		if (project && isDoneOrHalted) {
			const indicator = {
				type: ELEMENT_TYPE.INDICATOR,
				status: project.status,
			};
			leftContent.push(indicator);
		}

		if (project) {
			leftContent.push({
				type: ELEMENT_TYPE.CUSTOM_CONTENT,
				render: key => (
					<div style={{width: '250px'}}>
						<RateCardDropdown
							rateCards={company.rateCards.edges}
							company={company}
							project={project}
							selectedItems={project.rateCard && project.rateCard.id ? [project.rateCard.id] : []}
							dropdownAlignment="none"
							onSelect={value => changeRateCard(project.id, value)}
							onRemove={() => false}
							disabled={isDoneOrHalted}
							dataCy={'budget-rate-card-selector'}
							listDataCy={'budget-rate-card-option'}
						/>
					</div>
				),
			});
		}

		rightContent.push({
			type: ELEMENT_TYPE.BUTTON,
			style: BUTTON_STYLE.OUTLINE_THICK,
			color: BUTTON_COLOR.VERYLIGHTGREY,
			text: formatMessage({id: 'project_budget.change_cost_rate'}),
			disabled: isDoneOrHalted,
			callback: showCostRateModal,
			userpilot: 'change-cost-rate-button',
			dataCy: 'budget-change-cost-rate-button',
		});

		return (
			<HeaderBar
				parentGroup={null}
				leftContent={leftContent}
				rightContent={rightContent}
				noPadding={true}
				excludeBottomPadding={true}
			/>
		);
	};

	const handleSectionToggle = sectionName => {
		const expandedSections = localStorage.getItem('internalCostExpandedSectionsBudgetV2')
			? JSON.parse(localStorage.getItem('internalCostExpandedSectionsBudgetV2'))
			: {};
		if (sectionName === 'role_rates') {
			expandedSections[sectionName] = !showRoleRates;
			setShowRoleRates(!showRoleRates);
		} else if (sectionName === 'people_with_missing_cost') {
			expandedSections[sectionName] = !showPeopleWithMissingCost;
			setShowPeopleWithMissingCost(!showPeopleWithMissingCost);
		} else if (sectionName === 'people_with_cost') {
			expandedSections[sectionName] = !showPeopleWithCost;
			setShowPeopleWithCost(!showPeopleWithCost);
		}

		Util.localStorageSetItem('internalCostExpandedSectionsBudgetV2', JSON.stringify(expandedSections));
	};

	return (
		<div className={'budget-internal-costs-container'}>
			<CustomScrollDiv>
				{isDoneOrHalted ? getHeader() : null}
				<div className={'section-body no-margin'}>
					<div className="inner">
						{project.rateCard && ProjectUtil.projectTracksRevenue(project) ? (
							<fieldset
								className={'rates' + (project && isDoneOrHalted ? ' no-margin-top' : '')}
								data-cy="role-rates"
							>
								<div
									className={'top-container' + (showRoleRates ? '' : ' collapsed')}
									onClick={() => {
										handleSectionToggle('role_rates');
									}}
								>
									<div>
										<h2>{formatMessage({id: 'project_budget.role_rates'})}</h2>
									</div>
									<div className="controls">
										<div className={'expand-control' + (showRoleRates ? ' expand' : ' collapse')} />
									</div>
								</div>
								{showRoleRates ? (
									<table>
										<colgroup>
											<col className="column-person" />
											{isFixedPrice && project.rateCard.parentRateCardId ? (
												<col className="column-cost" />
											) : null}
											<col className="column-role" />
											<col className="column-cost" />
											<col className="column-controls" />
										</colgroup>
										<thead>
											<tr>
												<th className={'column-person person-width'} data-cy={'role_column_header'}>
													{formatMessage({id: 'common.role'})}
												</th>

												<th
													className={'column-role role-width role-width-header'}
													data-cy={'rate_column_header'}
												>
													<div className="column-name-info-wrapper">
														{isFixedPrice && project.rateCard.parentRateCardId
															? formatMessage({id: 'project_budget.original_rate'})
															: formatMessage({id: 'insights.component.list.column.rate'})}
														<TooltipContainer
															tooltipDuration={5 * 60 * 1000}
															triangleLocation={'left'}
															infoText={formatMessage({id: 'project_budget.original_rate_info'})}
														>
															<InformationIcon />
														</TooltipContainer>
													</div>
												</th>
												{isFixedPrice && project.rateCard.parentRateCardId ? (
													<th className="column-cost cost-width cost-width-header">
														<div className="column-name-info-wrapper">
															<FormattedHTMLMessage id="project_budget.fixed_price_adjusted_rate" />
															<TooltipContainer
																tooltipDuration={5 * 60 * 1000}
																triangleLocation={'left'}
																infoText={formatMessage({
																	id: 'project_budget.adjusted_rate_info',
																})}
															>
																<InformationIcon />
															</TooltipContainer>
														</div>
													</th>
												) : null}
												{canViewInternalCostRate ? (
													<th className="column-cost cost-width cost-width-header">
														<div
															className="column-name-info-wrapper"
															data-cy={'internal_hourly_cost_column_header'}
														>
															{formatMessage({id: 'settings_people.internal_hourly_cost'})}
															<TooltipContainer
																tooltipDuration={5 * 60 * 1000}
																triangleLocation={'left'}
																infoText={formatMessage({
																	id: 'project_budget.internal_hourly_cost_info',
																})}
															>
																<InformationIcon />
															</TooltipContainer>
														</div>
													</th>
												) : (
													<th className="column-controls controls-width">&nbsp;</th>
												)}
											</tr>
										</thead>

										<tbody>
											{rolesWithRates.map(role => (
												<BudgetRoleRate
													canViewInternalCostRate={canViewInternalCostRate}
													key={role.name}
													roleWithRate={role}
													project={project}
													company={company}
												/>
											))}
										</tbody>
									</table>
								) : null}
							</fieldset>
						) : null}

						{projectPersonsWithMissingCost.length === 0 ? null : (
							<fieldset className="people" data-cy="missing-hourly-cost">
								<div
									className={'top-container' + (showPeopleWithMissingCost ? '' : ' collapsed')}
									onClick={() => {
										handleSectionToggle('people_with_missing_cost');
									}}
								>
									<div>
										<h2 id="missing-hourly-cost-heading">
											{formatMessage({
												id: 'rates_internal_cost_page.team_members_with_missing_hourly_cost',
											})}
											<span>({projectPersonsWithMissingCost.length})</span>
										</h2>
									</div>
									<div className="controls">
										<div
											className={'expand-control' + (showPeopleWithMissingCost ? ' expand' : ' collapse')}
										/>
									</div>
								</div>
								{showPeopleWithMissingCost ? (
									<table>
										<colgroup>
											<col className="column-person" />
											<col className="column-role" />
											<col className="column-cost" />
											<col className="column-controls" />
										</colgroup>
										<thead>
											<tr>
												<th className={'column-person person-width'}>
													{formatMessage({id: 'common.team_member'})}
												</th>
												<th className={'column-role role-width role-width-header'}>
													{formatMessage({id: 'settings_team.project-role'})}
												</th>
												{canViewInternalCostRate ? (
													<th className="column-cost cost-width cost-width-header">
														<div className="column-name-info-wrapper">
															{formatMessage({id: 'settings_people.internal_hourly_cost'})}
															<TooltipContainer
																tooltipDuration={5 * 60 * 1000}
																triangleLocation={'left'}
																infoText={formatMessage({
																	id: 'project_budget.internal_hourly_cost_info',
																})}
															>
																<InformationIcon />
															</TooltipContainer>
														</div>
													</th>
												) : null}
												<th className="column-controls controls-width">&nbsp;</th>
											</tr>
										</thead>

										<tbody>
											{projectPersonsWithMissingCost.map(projectPerson => (
												<BudgetInternalCostLineItem
													projectPerson={projectPerson}
													key={projectPerson.id}
													project={project}
													company={company}
													enabledRoles={enabledRoles}
													disabledRoleIds={disabledRoleIds}
													canViewInternalCostRate={canViewInternalCostRate}
												/>
											))}
										</tbody>
									</table>
								) : null}
							</fieldset>
						)}

						<fieldset className="people" data-cy="team-members">
							<div
								className={'top-container' + (showPeopleWithCost ? '' : ' collapsed')}
								onClick={() => {
									handleSectionToggle('people_with_cost');
								}}
							>
								<div>
									<h2>
										{formatMessage({id: 'settings.team-members'})}
										<span>({projectPersonsWithCost.length})</span>
									</h2>
								</div>
								<div className="controls">
									<div className={'expand-control' + (showPeopleWithCost ? ' expand' : ' collapse')} />
								</div>
							</div>
							{showPeopleWithCost ? (
								<table>
									<colgroup>
										<col className="column-person" />
										<col className="column-role" />
										<col className="column-cost" />
										<col className="column-controls" />
									</colgroup>
									<thead>
										<tr>
											<th className={'column-person person-width'}>
												{formatMessage({id: 'common.team_member'})}
											</th>
											<th className={'column-role role-width role-width-header'}>
												{formatMessage({id: 'settings_team.project-role'})}
											</th>
											{canViewInternalCostRate ? (
												<th className="column-cost cost-width cost-width-header">
													<div className="column-name-info-wrapper">
														{formatMessage({id: 'settings_people.internal_hourly_cost'})}
														<TooltipContainer
															tooltipDuration={5 * 60 * 1000}
															triangleLocation={'left'}
															infoText={formatMessage({
																id: 'project_budget.internal_hourly_cost_info',
															})}
														>
															<InformationIcon />
														</TooltipContainer>
													</div>
												</th>
											) : null}
											<th className="column-controls controls-width">&nbsp;</th>
										</tr>
									</thead>

									<tbody>
										{projectPersonsWithCost.map(projectPerson => (
											<BudgetInternalCostLineItem
												projectPerson={projectPerson}
												key={projectPerson.id}
												project={project}
												company={company}
												hasProjectPersonUpdatePermission={hasProjectPersonUpdatePermission}
												enabledRoles={enabledRoles}
												disabledRoleIds={disabledRoleIds}
											/>
										))}
									</tbody>
								</table>
							) : null}
						</fieldset>
					</div>
				</div>
			</CustomScrollDiv>
		</div>
	);
};

export default createFragmentContainer(RatesAndInternalCostPage, {
	project: graphql`
		fragment ratesAndInternalCostPage_project on ProjectType {
			id
			budget
			budgetType
			taskCostCalculationType
			unassignedTaskHourlyCost
			projectPersons(first: 1000) {
				edges {
					node {
						id
						person {
							id
							isViewer
							fullName
							initials
							profilePictureId
							profilePictureDefaultId
							active
							permissions
							costPeriods(first: 10000) {
								edges {
									node {
										id
										startDay
										startMonth
										startYear
										cost
									}
								}
							}
						}
						role {
							id
							name
						}
					}
				}
			}
			rateCard {
				id
				currency
				name
				defaultRate
				currency
				parentRateCardId
				disabledRoles {
					id
				}
				rates(first: 10000) {
					edges {
						node {
							id
							rate
							defaultRate
							startDate
							role {
								id
								name
							}
						}
					}
				}
			}
			invoices(first: 100000000) {
				edges {
					node {
						status
					}
				}
			}
		}
	`,
	company: graphql`
		fragment ratesAndInternalCostPage_company on Company {
			id
			currency
			roles(first: 10000) {
				edges {
					node {
						id
						name
					}
				}
			}
			exchangeRates(first: 10000) {
				edges {
					node {
						id
						currency
						rate
					}
				}
			}
			rateCards(first: 10000) {
				edges {
					...RateCardDropdown_rateCards
					node {
						id
						name
						defaultRate
						currency
						parentRateCardId
						rates(first: 10000) {
							edges {
								node {
									id
									rate
									defaultRate
									startDate
									role {
										id
										name
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
