import {PERMISSION_TYPE} from '../../../Permissions';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';

export const theEyeOptions = () => {
	const columns = [
		{checked: false, name: 'workEstimate', translationId: 'common.work_estimate'},
		{checked: false, name: 'totalTimeReg', translationId: 'common.total_time_regs'},
	];
	if (hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL)) {
		columns.splice(0, 0, {checked: false, name: 'projectOwner', translationId: 'common.project_owner'});
	}
	return columns;
};
