import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {toPlaceholderObject} from '../placeholders-scheduling/formatters/PlaceholderFormatter';

export const handleAssignToPerson = (
	pageComponent,
	placeholder,
	placeholderAllocationIds,
	suggestedPersonIdsInput = undefined,
	initialPersonId = undefined
) => {
	const {staffingModeActive} = pageComponent.state;

	const placeholderInput = toPlaceholderObject(
		placeholder,
		pageComponent.state.data.placeholderSkills,
		pageComponent.state.data.roles,
		pageComponent.state.data.placeholderAllocations
	);

	const activateStaffingMode = () => {
		pageComponent.activateStaffingMode(placeholder.id);
	};

	const assignToPerson = () => {
		showModal({
			type: MODAL_TYPE.ASSIGN_TO_PERSON,
			staffingModeActive,
			placeholderInput: placeholderInput,
			placeholderAllocationIds,
			activateStaffingMode,
			suggestedPersonIdsInput,
			initialPersonId,
		});
	};

	assignToPerson();
};
