import React from 'react';

export default class RichTextUtil {
	static getStyleMap() {
		return {
			CODE: {
				backgroundColor: 'rgba(0, 0, 0, 0.05)',
				fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
				fontSize: 16,
				padding: 2,
			},
			SUBSCRIPT: {
				verticalAlign: 'sub',
				fontSize: 'smaller',
			},
			SUPERSCRIPT: {
				verticalAlign: 'super',
				fontSize: 'smaller',
			},
			GREY: {
				color: '#97a0af',
			},
			PURPLE: {
				color: '#6554c0',
			},
			BLUE: {
				color: '#00b8d9',
			},
			GREEN: {
				color: '#36b37e',
			},
			RED: {
				color: '#ff5630',
			},
			YELLOW: {
				color: '#ff991f',
			},
			BLACK: {
				// Use default
			},
		};
	}

	static getBlockRenderer() {
		return contentBlock => {
			const type = contentBlock.getType();
			if (
				type === 'jiraSingleMedia' ||
				type === 'jiraMediaGroup' ||
				type === 'jiraMediaGroup' ||
				type === 'jiraTable' ||
				type === 'jiraPanelStart' ||
				type === 'jiraPanelEnd'
			) {
				return {
					component: props => {
						return <div>{props.block.getText()}</div>;
					},
					editable: false,
				};
			} else if (type === 'divider') {
				return {
					component: props => {
						return <hr />;
					},
					editable: true,
				};
			}
		};
	}

	static getBlockStyleFn() {
		return contentBlock => {
			const type = contentBlock.getType();
			if (type === 'code-block') {
				return 'rich-text-field-code-block';
			}
		};
	}
}
