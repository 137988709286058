import {createPaginationContainer, graphql} from 'react-relay';
import {PROGRAM_BUDGET_TYPE} from '../../../../constants';
import {hasTopDownProgramBudgetFeature} from '../../../../forecast-app/shared/util/ProgramFinancialLogic';

const ProjectLoader = ({relay: {hasMore, isLoading, loadMore}, viewer, children, ...props}) => {
	const formatData = () => {
		return viewer.company.financialBasedProjects.edges.map(project => {
			const {financialNumbers} = project.node;
			return {
				project: {
					...project.node,
				},
				currency: viewer.company.currency,
				financialNumbers: {
					...financialNumbers,
				},
			};
		});
	};
	const programUnallocatedRevenue =
		hasTopDownProgramBudgetFeature() &&
		!props.programId &&
		viewer.company.programs.edges.reduce((acc, programEdge) => {
			return programEdge.node.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE
				? programEdge.node.budgetValue - programEdge.node.totalAllocation + acc
				: acc;
		}, 0);

	const loadMoreFunc = hasMore() ? loadMore : undefined;
	return children({
		data: {rows: formatData(), programUnallocatedRevenue: programUnallocatedRevenue, currency: viewer.company.currency},
		loadMore: loadMoreFunc,
		loading: isLoading(),
		...props,
	});
};

export const projectLoaderQuery = graphql`
	query ProjectLoader_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$searchQuery: TaskSearchQueryType!
		$pageSize: Int!
		$cursor: String
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_period_project_loader")
			...ProjectLoader_viewer
				@arguments(
					searchQuery: $searchQuery
					pageSize: $pageSize
					cursor: $cursor
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createPaginationContainer(
	ProjectLoader,
	{
		viewer: graphql`
			fragment ProjectLoader_viewer on Viewer
			@argumentDefinitions(
				searchQuery: {type: "TaskSearchQueryType!"}
				startYear: {type: "Int"}
				startMonth: {type: "Int"}
				startDay: {type: "Int"}
				endYear: {type: "Int"}
				endMonth: {type: "Int"}
				endDay: {type: "Int"}
				pageSize: {type: "Int!"}
				cursor: {type: "String"}
			) {
				id
				company {
					currency
					programs {
						edges {
							node {
								budgetType
								budgetValue
								totalAllocation
							}
						}
					}
					financialBasedProjects(
						searchQuery: $searchQuery
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
						first: $pageSize
						after: $cursor
					) @connection(key: "Company_financialBasedProjects") {
						edges {
							node {
								id
								companyProjectId
								...DeprecatedProjectIndicatorJS_project
								name
								budgetType
								budgetBaseCurrency
								defaultPeriodBudgetType
								fullAccessToProject
								readOnlyAccess
								projectColor
								status
								projectStartDay
								projectStartMonth
								projectStartYear
								projectEndDay
								projectEndMonth
								projectEndYear
								baselineWinChance
								priorityLevel {
									id
									name
								}
								useBaseline
								estimationUnit
								progressEndOfTheMonth
								completion
								flatRate
								rateCard {
									id
									name
									currency
									parentRateCardId
								}
								client {
									id
									name
									logoId
									logoDefaultId
								}
								projectLabels(first: 1000) {
									edges {
										node {
											label {
												name
												color
											}
										}
									}
								}
								projectPersons(first: 1000000, contactsOnly: true) {
									edges {
										node {
											id
											isContactPerson
											person {
												id
												fullName
												firstName
												lastName
												profilePictureId
												profilePictureDefaultId
												initials
											}
										}
									}
								}
								currentProjectStatus {
									id
									color
									description
									person {
										id
										firstName
										lastName
										fullName
										profilePictureId
									}
								}
								financialNumbers(
									startYear: $startYear
									startMonth: $startMonth
									startDay: $startDay
									endYear: $endYear
									endMonth: $endMonth
									endDay: $endDay
								) {
									...ProjectPortfolioPeriodRow_financialNumbers
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: 'forward',
		getConnectionFromProps({viewer}) {
			return viewer.company.financialBasedProjects;
		},
		getFragmentVariables(prevVars) {
			return {
				...prevVars,
			};
		},
		getVariables(_, {cursor}, fragmentVariables) {
			return {
				...fragmentVariables,
				cursor,
			};
		},
		query: graphql`
			query ProjectLoaderRefetchQuery(
				$startYear: Int
				$startMonth: Int
				$startDay: Int
				$endYear: Int
				$endMonth: Int
				$endDay: Int
				$searchQuery: TaskSearchQueryType!
				$pageSize: Int!
				$cursor: String
			) {
				viewer {
					actualPersonId
					component(name: "project_portfolio_period_project_loader")
					...ProjectLoader_viewer
						@arguments(
							searchQuery: $searchQuery
							pageSize: $pageSize
							cursor: $cursor
							startYear: $startYear
							startMonth: $startMonth
							startDay: $startDay
							endYear: $endYear
							endMonth: $endMonth
							endDay: $endDay
						)
				}
			}
		`,
	}
);
