import React from 'react';
import Util from '../../../../forecast-app/shared/util/util';
import {Table} from 'web-components';
import TaskIndicatorsColumn from './columns/TaskIndiatorsColumn';
import LabelGroup from '../../../../forecast-app/shared/components/labels/label_group';
import AssignedTableTile from '../../../../forecast-app/shared/components/table-tiles/assigned_table_tile';
import {ESTIMATION_UNIT} from '../../../../constants';

export const getTaskRow = (intl, task, currency, availableFeatureFlags, enabledColumns, fetchMore, showTaskModal) => {
	let formattedActualPrice;
	let formattedEstimatedPrice;
	let formattedRemainingPrice;
	let formattedForecastPrice;
	let formattedTotalActualRevenueRecognition;
	let formattedRecognitionLockedRevenue;
	let formattedRecognitionOpenRevenue;
	let formattedTotalForecastRevenueToComplete;
	let formattedRecognitionForecastPrice;
	let formattedRecognitionSurplus;
	const customFields = Util.hasCustomFields()
		? task.customFieldValues.edges.map(edge => edge.node).sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
		: [];
	formattedActualPrice = intl.formatNumber(task.financialNumbers.actualRevenue, {format: 'always_two_decimal'});
	formattedEstimatedPrice = intl.formatNumber(task.financialNumbers.plannedRevenue, {format: 'always_two_decimal'});
	formattedRemainingPrice = intl.formatNumber(task.financialNumbers.remainingRevenue, {format: 'always_two_decimal'});
	formattedForecastPrice = intl.formatNumber(task.financialNumbers.forecastRevenue, {format: 'always_two_decimal'});
	formattedRecognitionLockedRevenue = intl.formatNumber(task.financialNumbers.recognitionLockedRevenue, {
		format: 'always_two_decimal',
	});
	formattedTotalActualRevenueRecognition = intl.formatNumber(task.financialNumbers.totalActualRevenueRecognition, {
		format: 'always_two_decimal',
	});
	formattedRecognitionOpenRevenue = intl.formatNumber(task.financialNumbers.recognitionOpenRevenue, {
		format: 'always_two_decimal',
	});
	formattedTotalForecastRevenueToComplete = intl.formatNumber(task.financialNumbers.totalForecastRevenueToComplete, {
		format: 'always_two_decimal',
	});
	formattedRecognitionForecastPrice = intl.formatNumber(task.financialNumbers.recognitionForecastRevenue, {
		format: 'always_two_decimal',
	});
	formattedRecognitionSurplus = intl.formatNumber(task.financialNumbers.surplus, {
		format: 'always_two_decimal',
	});

	const openTaskModal = task => {
		showTaskModal(task.companyTaskId);
	};

	return [
		// *** Task name ***
		<Table.Column key={task.id + '_name'}>
			<Table.Column.Cell title={task.name} onClick={() => openTaskModal(task)}>
				{`T${task.companyTaskId} ${task.name}`}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Indicators ***
		<TaskIndicatorsColumn task={task} intl={intl} key={task.id + '_indicator'} />,

		// *** Project name ***
		<Table.Column key={task.id + '_project'}>
			<Table.Column.Cell title={task.project.name}>{task.project.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Project End date ***
		<Table.Column key={task.id + '_project_deadline'}>
			<Table.Column.Cell>
				{task.project.projectEndYear &&
					intl.formatDate(
						Util.CreateNonUtcMomentDate(
							task.project.projectEndYear,
							task.project.projectEndMonth,
							task.project.projectEndDay
						),
						{
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}
					)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Project stage ***
		<Table.Column key={task.id + '_project_stage'}>
			<Table.Column.Cell>{Util.getProjectStageTranslation(task.project.status, intl)}</Table.Column.Cell>
		</Table.Column>,

		// *** Rate card ***
		<Table.Column key={task.id + '_rateCard'}>
			<Table.Column.Cell>{task.project.rateCard?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Client name ***
		<Table.Column key={task.id + '_client'}>
			<Table.Column.Cell title={task.project.client?.name}>{task.project.client?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Task Start date ***
		<Table.Column key={task.id + 'task_startDate'}>
			<Table.Column.Cell>
				{intl.formatDate(Util.CreateNonUtcMomentDate(task.startYear, task.startMonth, task.startDay), {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Task End date ***
		<Table.Column key={task.id + '_task_endDate'}>
			<Table.Column.Cell>
				{intl.formatDate(Util.CreateNonUtcMomentDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay), {
					year: 'numeric',
					month: 'short',
					day: 'numeric',
				})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Role name ***
		<Table.Column key={task.id + '_role'}>
			<Table.Column.Cell>{task.role?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Assignees ***
		<Table.Column key={task.id + '_assignees'}>
			<Table.Column.Cell>
				<AssignedTableTile
					disabled={true}
					assignablePersons={[]}
					assignedPersons={task.assignedPersons}
					assignPerson={() => {}}
					unassignPerson={() => {}}
					useTableStyling={true}
					isMultiSelect={false}
					id={task.id}
					key={'assigned-person'}
					task={task}
					maxDisplayed={5}
					cy={'assigned-person'}
					suggestedPersonRoleFilter={task.role}
					isClientUser={false}
					intl={intl}
				/>
			</Table.Column.Cell>
		</Table.Column>,

		// *** Status ***
		<Table.Column key={task.id + '_status'}>
			<Table.Column.Cell>{task.statusColumnV2.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Phase ***
		<Table.Column key={task.id + '_phase'}>
			<Table.Column.Cell>{task.phase?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Phase Start date ***
		<Table.Column key={task.id + '_phase_startDate'}>
			<Table.Column.Cell>
				{task.phase &&
					intl.formatDate(
						Util.CreateNonUtcMomentDate(task.phase.startYear, task.phase.startMonth, task.phase.startDay),
						{
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}
					)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Phase End date ***
		<Table.Column key={task.id + '_phase_endDate'}>
			<Table.Column.Cell>
				{task.phase &&
					intl.formatDate(
						Util.CreateNonUtcMomentDate(task.phase.deadlineYear, task.phase.deadlineMonth, task.phase.deadlineDay),
						{
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}
					)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Sprint ***
		<Table.Column key={task.id + '_sprint'}>
			<Table.Column.Cell>{task.sprint?.name}</Table.Column.Cell>
		</Table.Column>,

		// *** Sprint Start date ***
		<Table.Column key={task.id + '_sprint_startDate'}>
			<Table.Column.Cell>
				{task.sprint &&
					intl.formatDate(
						Util.CreateNonUtcMomentDate(task.sprint.startYear, task.sprint.startMonth, task.sprint.startDay),
						{
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}
					)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Sprint End date ***
		<Table.Column key={task.id + '_sprint_endDate'}>
			<Table.Column.Cell>
				{task.sprint &&
					intl.formatDate(
						Util.CreateNonUtcMomentDate(task.sprint.endYear, task.sprint.endMonth, task.sprint.endDay),
						{
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						}
					)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Progress ***
		<Table.Column key={task.id + '_progress'}>
			<Table.Column.Cell>{`${task.progress}%`}</Table.Column.Cell>
		</Table.Column>,

		// *** Estimate ***
		<Table.Column key={task.id + '_estimate_hours'}>
			<Table.Column.Cell>
				{task.project.estimationUnit === ESTIMATION_UNIT.HOURS &&
					Util.convertMinutesToFullHour(task.financialNumbers.scopeApprovedMinutes, intl)}
			</Table.Column.Cell>
		</Table.Column>,
		<Table.Column key={task.id + '_estimate_points'}>
			<Table.Column.Cell>
				{task.project.estimationUnit === ESTIMATION_UNIT.POINTS && `${task.estimateForecast}p`}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Projected time ***
		<Table.Column key={task.id + '_projected'}>
			<Table.Column.Cell>
				{Util.convertMinutesToFullHour(task.financialNumbers.totalTimeAtCompletion, intl)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Reported time ***
		<Table.Column key={task.id + '_reported'}>
			<Table.Column.Cell>
				{Util.convertMinutesToFullHour(task.financialNumbers.registeredMinutes, intl)}
			</Table.Column.Cell>
		</Table.Column>,
		<Table.Column key={task.id + '_billable_reported'}>
			<Table.Column.Cell>
				{Util.convertMinutesToFullHour(task.financialNumbers.billableActualMinutes, intl)}
			</Table.Column.Cell>
		</Table.Column>,
		<Table.Column key={task.id + '_non_billable_reported'}>
			<Table.Column.Cell>
				{Util.convertMinutesToFullHour(task.financialNumbers.nonBillableActualMinutes, intl)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Remaining time ***
		<Table.Column key={task.id + '_remaining_hours'}>
			<Table.Column.Cell>
				{task.project.estimationUnit === ESTIMATION_UNIT.HOURS &&
					Util.convertMinutesToFullHour(task.financialNumbers.remainingMinutes, intl)}
			</Table.Column.Cell>
		</Table.Column>,
		<Table.Column key={task.id + '_remaining_points'}>
			<Table.Column.Cell>
				{task.project.estimationUnit === ESTIMATION_UNIT.POINTS &&
					`${intl.formatNumber(task.timeLeft, {format: 'always_two_decimal'})}p`}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Difference ***
		<Table.Column key={task.id + '_difference_hours'}>
			<Table.Column.Cell>
				{task.project.estimationUnit === ESTIMATION_UNIT.HOURS &&
					Util.convertMinutesToFullHour(
						task.financialNumbers.scopeApprovedMinutes - task.financialNumbers.remainingMinutes,
						intl
					)}
			</Table.Column.Cell>
		</Table.Column>,
		<Table.Column key={task.id + '_difference_points'}>
			<Table.Column.Cell>
				{task.project.estimationUnit === ESTIMATION_UNIT.POINTS &&
					`${intl.formatNumber(task.estimateForecast - task.timeLeft, {format: 'always_two_decimal'})}p`}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Planned revenue ***
		<Table.Column
			key={task.id + '_planned_revenue'}
			negative={task.financialNumbers.plannedRevenue && task.financialNumbers.plannedRevenue < 0}
		>
			<Table.Column.Cell>{Util.GetFormattedCurrencySymbol(currency, formattedEstimatedPrice)}</Table.Column.Cell>
		</Table.Column>,

		// *** Actual revenue ***
		<Table.Column
			key={task.id + '_actual_revenue'}
			negative={task.financialNumbers.actualRevenue && task.financialNumbers.actualRevenue < 0}
		>
			<Table.Column.Cell>{Util.GetFormattedCurrencySymbol(currency, formattedActualPrice)}</Table.Column.Cell>
		</Table.Column>,

		// *** Remaining revenue ***
		<Table.Column
			key={task.id + '_remaining_revenue'}
			negative={task.financialNumbers.remainingRevenue && task.financialNumbers.remainingRevenue < 0}
		>
			<Table.Column.Cell>
				{Util.GetFormattedCurrencySymbolOrEmptyValueIndicator(currency, formattedRemainingPrice)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Forecast revenue ***
		<Table.Column
			key={task.id + '_forecast_revenue'}
			negative={task.financialNumbers.forecastRevenue && task.financialNumbers.forecastRevenue < 0}
		>
			<Table.Column.Cell>
				{Util.GetFormattedCurrencySymbolOrEmptyValueIndicator(currency, formattedForecastPrice)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Actual revenue recognised to date ***
		<Table.Column
			key={task.id + '_recognition_locked_revenue'}
			negative={task.financialNumbers.recognitionLockedRevenue && task.financialNumbers.recognitionLockedRevenue < 0}
		>
			<Table.Column.Cell>
				{Util.GetFormattedCurrencySymbolOrEmptyValueIndicator(currency, formattedRecognitionLockedRevenue)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Actual Revenue ***
		<Table.Column
			key={task.id + '_total_actual_revenue_recognition'}
			negative={
				task.financialNumbers.totalActualRevenueRecognition && task.financialNumbers.totalActualRevenueRecognition < 0
			}
		>
			<Table.Column.Cell>
				{Util.GetFormattedCurrencySymbolOrEmptyValueIndicator(currency, formattedTotalActualRevenueRecognition)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Remaining / Forecast revenue to be recognised ***
		<Table.Column
			key={task.id + '_recognition_remaining_revenue'}
			negative={task.financialNumbers.recognitionOpenRevenue && task.financialNumbers.recognitionOpenRevenue < 0}
		>
			<Table.Column.Cell>
				{Util.GetFormattedCurrencySymbolOrEmptyValueIndicator(currency, formattedRecognitionOpenRevenue)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Remaining Work Revenue ***
		<Table.Column
			key={task.id + '_total_forecast_revenue_to_complete '}
			negative={
				task.financialNumbers.totalForecastRevenueToComplete && task.financialNumbers.totalForecastRevenueToComplete < 0
			}
		>
			<Table.Column.Cell>
				{Util.GetFormattedCurrencySymbolOrEmptyValueIndicator(currency, formattedTotalForecastRevenueToComplete)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Total revenue recognition at completion (Actual + Forecast) ***
		<Table.Column
			key={task.id + '_recognition_forecast_revenue'}
			negative={task.financialNumbers.recognitionForecastRevenue && task.financialNumbers.recognitionForecastRevenue < 0}
		>
			<Table.Column.Cell>
				{Util.GetFormattedCurrencySymbolOrEmptyValueIndicator(currency, formattedRecognitionForecastPrice)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Recognition Surplus / Variance ***
		<Table.Column
			key={task.id + '_recognition_surplus'}
			negative={task.financialNumbers.surplus && task.financialNumbers.surplus < 0}
		>
			<Table.Column.Cell>
				{Util.GetFormattedCurrencySymbolOrEmptyValueIndicator(currency, formattedRecognitionSurplus)}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Approved ***
		<Table.Column key={task.id + '_approved'}>
			<Table.Column.Cell>
				{task.approved ? intl.formatMessage({id: 'common.yes'}) : intl.formatMessage({id: 'common.no'})}
			</Table.Column.Cell>
		</Table.Column>,

		// *** Labels ***
		<Table.Column key={task.id + '_labels'}>
			<Table.Column.Cell>
				<LabelGroup labels={task.taskLabels} noWrap={true} intl={intl} />
			</Table.Column.Cell>
		</Table.Column>,

		// *** Custom Fields ***
		...customFields.map(customField => (
			<Table.Column key={task.id + '_' + customField.key}>
				<Table.Column.Cell title={customField.value}>{customField.value}</Table.Column.Cell>
			</Table.Column>
		)),
	];
};
