/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectStatusColumnDropdown_columns$ref: FragmentReference;
declare export opaque type ProjectStatusColumnDropdown_columns$fragmentType: ProjectStatusColumnDropdown_columns$ref;
export type ProjectStatusColumnDropdown_columns = $ReadOnlyArray<{|
  +node: ?{|
    +order: ?number,
    +name: ?string,
    +id: string,
    +projectGroupStatusColumnId: ?number,
  |},
  +$refType: ProjectStatusColumnDropdown_columns$ref,
|}>;
export type ProjectStatusColumnDropdown_columns$data = ProjectStatusColumnDropdown_columns;
export type ProjectStatusColumnDropdown_columns$key = $ReadOnlyArray<{
  +$data?: ProjectStatusColumnDropdown_columns$data,
  +$fragmentRefs: ProjectStatusColumnDropdown_columns$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ProjectStatusColumnDropdown_columns",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StatusColumnV2",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "order",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectGroupStatusColumnId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StatusColumnV2TypeEdge"
};
// prettier-ignore
(node/*: any*/).hash = '9e4e206e9751fabce9e60e75f24080c7';

module.exports = node;
