import {
	CANVAS_BUTTON_GROUP_SECTION_PADDING_RIGHT,
	CURSOR,
	drawBorder,
	drawBorderLines,
	drawRectangle,
	getFirstLetter,
	getMomentFromCanvasTimelineDate,
	getTextColor,
	getTrimmedText,
	GROUP_SECTION_ACTION_BUTTON_SIZE,
	GROUP_SECTION_ACTIONS_MENU,
	GROUP_SECTION_ACTIONS_MENU_LEFT_SCHEDULE_PEOPLE,
	GROUP_SECTION_BADGE_PADDING_HORIZONTAL,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_MARGIN_LEFT,
	GROUP_SECTION_PADDING_LEFT,
	GROUP_SECTION_PADDING_LEFT_SMALL,
	GROUP_SECTION_PLACEHOLDER_AVATAR_SIZE,
	GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_HEIGHT,
	GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_MARGIN_LEFT,
	GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_WIDTH,
	GROUP_SECTION_PROJECT_BORDER_WIDTH,
	GROUP_SECTION_SPACING_LEVEL_FOUR,
	GROUP_SECTION_SPACING_LEVEL_ONE,
	GROUP_SECTION_SPACING_LEVEL_TWO,
	GROUP_SECTION_TEXT_GREY,
	GROUP_SECTION_TEXT_GREY_DARK,
	GROUP_SECTION_WIDTH,
	GROUP_TYPE,
	hasViewOnlyAccess,
	isProjectDoneOrHalted,
	SCHEDULE_PEOPLE_HEATMAP_SHADOW_BLUR,
	SCHEDULE_PEOPLE_HEATMAP_SHADOW_COLOR,
	TIMELINE_BACKGROUND_COLOR,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import {drawButton, drawExpandedGroupHighlight} from '../../DrawingUtils';
import {getCachedMessage} from '../../../../translations/TranslationCache';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import {SCHEDULING_ACTION_MENU_TYPE, SCHEDULING_VIEW} from '../../../../constants';
import EventManager from '../../EventManager';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {handleAssignToPerson} from '../../actions/handle_assign_to_person';
import DataManager from '../../DataManager';

class CapacityPlaceholderGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP, data);

		this.groupId = data.groupId;

		const {schedulingView} = pageComponent.props;
		if (schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW) {
			this.renderRowLines = true;
			this.includeInRowHover = false;
		}

		this.refreshData(data);
	}

	refreshData(data) {
		const {intl} = this.pageComponent.props;
		const {staffingModeActive} = this.pageComponent.state;
		const {id, totalMinutesMap, drawAssignToButton} = data;

		const drawTotalMinutes =
			(!drawAssignToButton && !!totalMinutesMap && !hasFeatureFlag('placeholders_beta_changes')) ||
			(hasFeatureFlag('placeholders_beta_changes') && staffingModeActive);
		const totalMinutes = drawTotalMinutes && Util.convertMinutesToFullHour(totalMinutesMap.get(id) || 0, intl);
		const hasBetaChanges = hasFeatureFlag('capacity_planning_beta_2_improvements');
		this.data = {
			...data,
			totalMinutes,
			drawTotalMinutes,
			hasBetaChanges,
		};
	}

	drawAvatar(canvasContext, x, y, name) {
		const radius = GROUP_SECTION_PLACEHOLDER_AVATAR_SIZE / 2;
		const avatarX = x + radius - 0.5;
		const avatarY = y;

		canvasContext.save();

		// init position
		canvasContext.beginPath();
		canvasContext.moveTo(avatarX, avatarY);

		// border style
		canvasContext.lineWidth = 1.5;
		canvasContext.strokeStyle = '#6E0FEB';
		canvasContext.setLineDash([3, 2]);

		// set rotation axis point
		canvasContext.translate(avatarX, avatarY);

		// draw edges
		for (let i = 0; i < 7; i++) {
			canvasContext.lineTo(0, 0 - GROUP_SECTION_PLACEHOLDER_AVATAR_SIZE);
			canvasContext.rotate(Math.PI / 3);
		}

		// draw borders
		canvasContext.stroke();

		// fill
		canvasContext.fillStyle = '#F0E7FE';
		canvasContext.fill();

		// reset
		canvasContext.restore();

		// initials
		const fontSize = 13;
		if (name) {
			canvasContext.font = `600 ${fontSize}px ` + Util.getFontFamily();
			canvasContext.fillStyle = '#6E0FEB';

			canvasContext.textAlign = 'center';
			canvasContext.fillText(
				getTrimmedText(canvasContext, getFirstLetter(name).toUpperCase()),
				avatarX,
				avatarY - fontSize + 18
			);
		}
	}

	shouldDisplayMouseHover() {
		const {project} = this.data;

		if (!project) {
			return false;
		}

		return !isProjectDoneOrHalted(project.status) && !hasViewOnlyAccess(this.pageComponent);
	}

	onMouseEnter(groupData) {
		if (this.shouldDisplayMouseHover()) {
			const {showExpandedActionMenu, staffingModeActive} = this.pageComponent.state;

			if (!showExpandedActionMenu && !staffingModeActive) {
				const {project, placeholder, skills} = this.data;
				const {group, y, height} = groupData;

				this.pageComponent.setState({
					showCollapsedActionMenu: true,
					collapsedActionMenuX: group.actionMenuX - GROUP_SECTION_ACTIONS_MENU,
					collapsedActionMenuY: y + height / 2 - GROUP_SECTION_ACTIONS_MENU,
					collapsedActionMenuData: {project, placeholder, skills},
					actionMenuType: SCHEDULING_ACTION_MENU_TYPE.PLACEHOLDER,
					actionMenuOptions: [],
				});
			}
		}
	}

	onMouseLeave() {
		if (this.shouldDisplayMouseHover()) {
			const {staffingModeActive} = this.pageComponent.state;

			if (!staffingModeActive) {
				EventManager.hideCollapsedActionMenu(this.pageComponent);
			}
		}
	}

	onClick() {
		if (!this.isDisabled()) {
			const {staffingModeActive} = this.pageComponent.state;

			if (!staffingModeActive) {
				const {placeholder} = this.data;

				trackEvent('Placeholder Group', 'Clicked');

				showModal({
					type: MODAL_TYPE.NEW_PLACEHOLDER,
					placeholderId: placeholder.id,
					projectId: placeholder.projectGroupId ? null : placeholder.projectId,
					projectGroupId: placeholder.projectGroupId,
					staffingModeActive,
				});
			}
		}
	}

	onItemCreate(startDate, endDate, group) {
		if (!this.isDisabled() && !this.data.composeItems) {
			const {staffingModeActive} = this.pageComponent.state;
			const {placeholder} = this.data;

			const dragStartDate = getMomentFromCanvasTimelineDate(startDate);
			const dragEndDate = getMomentFromCanvasTimelineDate(endDate);

			showModal({
				type: MODAL_TYPE.PLACEHOLDER_ALLOCATION,
				projectId: placeholder.projectId,
				projectGroupId: placeholder.projectGroupId,
				dragStartDate,
				dragEndDate,
				staffingModeActive,
				placeholderInput: placeholder,
			});
		}
	}

	onButtonClick(event, groupData) {
		const {drawAssignToButton} = this.data;

		if (drawAssignToButton) {
			const {placeholder} = this.data;
			const placeholderAllocations = DataManager.getPlaceholderAllocationByPlaceholderId(
				this.pageComponent,
				placeholder.id
			);
			const placeholderAllocationIds = placeholderAllocations?.map(alloc => alloc.id) || [];

			trackEvent('Assign To Button', 'Clicked');

			handleAssignToPerson(this.pageComponent, placeholder, placeholderAllocationIds, undefined);
		}
	}

	draw(canvasContext, x, y) {
		const groupSectionWidth = GROUP_SECTION_WIDTH;
		const width = groupSectionWidth;
		const {data, height, expanded} = this;
		super.draw(x, y, width, height, this);
		const {
			name,
			role,
			skills,
			isInProjectGroup,
			projectGroupColor,
			color,
			staffingModeActive,
			groups,
			totalMinutes,
			drawTotalMinutes,
			drawAssignToButton,
			isInProgram,
			hasBetaChanges,
		} = data;
		const {schedulingView, isProjectTimeline, intl} = this.pageComponent.props;

		const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;
		const isPlaceholderScheduling = schedulingView === SCHEDULING_VIEW.PLACEHOLDERS;
		const isCapacityOverviewScheduling = schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;

		const borderThickness = 1;
		const hasSkills = skills?.length > 0;

		const center = y + height / 2;
		const xOffset =
			isInProgram && isProjectScheduling
				? GROUP_SECTION_SPACING_LEVEL_FOUR + GROUP_SECTION_PADDING_LEFT_SMALL
				: isPlaceholderScheduling
				? GROUP_SECTION_SPACING_LEVEL_ONE + GROUP_SECTION_PADDING_LEFT_SMALL
				: GROUP_SECTION_SPACING_LEVEL_TWO + GROUP_SECTION_PADDING_LEFT + GROUP_SECTION_EXPAND_ICON_WIDTH / 2;
		const projectColor = isInProjectGroup ? projectGroupColor : color;
		let badgeOrButtonWidth = 0;

		canvasContext.font = '500 10px ' + Util.getFontFamily();

		if (drawTotalMinutes) {
			badgeOrButtonWidth = canvasContext.measureText(totalMinutes).width + GROUP_SECTION_BADGE_PADDING_HORIZONTAL;
		}

		const totalMinutesBadgeHeight = 20;

		// Draw pointer if the onClick function is defined or there are subgroups of this group
		if (((drawAssignToButton && !this.isButtonDisabled()) || groups) && !staffingModeActive) {
			interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);
		}

		if (isCapacityOverviewScheduling && expanded && groups?.length > 0) {
			// draw person row shadow
			canvasContext.shadowColor = SCHEDULE_PEOPLE_HEATMAP_SHADOW_COLOR;
			canvasContext.shadowBlur = SCHEDULE_PEOPLE_HEATMAP_SHADOW_BLUR;
			canvasContext.fillStyle = '#ffffff';
			const shadowHeight = 10;
			canvasContext.fillRect(x, y + height - shadowHeight, width, shadowHeight);

			// reset shadow properties
			canvasContext.shadowColor = undefined;
			canvasContext.shadowBlur = 0;

			// create layer over shadow
			canvasContext.fillRect(x, y, width, height);
		}

		if (isProjectScheduling && !isProjectTimeline) {
			drawExpandedGroupHighlight(canvasContext, x, y, height, width);
		}

		if (drawAssignToButton) {
			const buttonProperties = drawButton(
				canvasContext,
				x + width - CANVAS_BUTTON_GROUP_SECTION_PADDING_RIGHT,
				y + height / 2,
				getCachedMessage(intl, hasBetaChanges ? 'common.assign' : 'common.assign_to'),
				this.isHoveringButton,
				this.isButtonDisabled()
			);

			this.buttonX = buttonProperties.buttonX;
			this.buttonY = buttonProperties.buttonY;
			this.buttonWidth = buttonProperties.buttonWidth;
			this.buttonHeight = buttonProperties.buttonHeight;

			badgeOrButtonWidth = buttonProperties.buttonWidth;
			this.actionMenuX = buttonProperties.buttonX - GROUP_SECTION_PADDING_LEFT_SMALL;
		}

		if (isCapacityOverviewScheduling) {
			// border lines
			drawBorderLines(canvasContext, 0, y, width, height, true);

			// expansion icon
			canvasContext.drawImage(
				cacheManager.getCommonImage(expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON),
				x + GROUP_SECTION_SPACING_LEVEL_TWO - GROUP_SECTION_EXPAND_ICON_WIDTH / 2,
				y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
				GROUP_SECTION_EXPAND_ICON_WIDTH,
				GROUP_SECTION_EXPAND_ICON_HEIGHT
			);
		}

		// draw project border color
		if (!isPlaceholderScheduling && !isCapacityOverviewScheduling && !isInProjectGroup) {
			const projectColoredBorderX = isInProjectGroup
				? x + GROUP_SECTION_MARGIN_LEFT - GROUP_SECTION_PROJECT_BORDER_WIDTH
				: x + width - GROUP_SECTION_PROJECT_BORDER_WIDTH;

			drawBorder(
				canvasContext,
				projectColoredBorderX,
				y - 2 - borderThickness * 2,
				height + borderThickness * 2,
				true,
				projectColor,
				GROUP_SECTION_PROJECT_BORDER_WIDTH
			);
		}

		const textColor = getTextColor();

		let fontSize = 13;
		const nameRoleGap = 3;

		// draw name
		canvasContext.fillStyle = textColor;
		canvasContext.font = `600 ${fontSize}px ` + Util.getFontFamily();
		const placeholderNameY = role ? center - nameRoleGap : center + 4;
		const placeholderNameX = x + xOffset + GROUP_SECTION_PADDING_LEFT + GROUP_SECTION_PLACEHOLDER_AVATAR_SIZE;
		const placeholderInformationWidth = hasSkills
			? GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_WIDTH + GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_MARGIN_LEFT
			: 0;
		let maxNameWidth = groupSectionWidth - placeholderNameX - GROUP_SECTION_PADDING_LEFT - GROUP_SECTION_ACTION_BUTTON_SIZE;
		if (drawTotalMinutes || drawAssignToButton) {
			maxNameWidth -= GROUP_SECTION_PADDING_LEFT + badgeOrButtonWidth + GROUP_SECTION_PADDING_LEFT;
		}
		const placeholderNameMaxWidth = maxNameWidth - placeholderInformationWidth;
		const placeholderName = getTrimmedText(canvasContext, name, placeholderNameMaxWidth);
		canvasContext.fillText(placeholderName, placeholderNameX, placeholderNameY);

		// draw placeholder information icon
		if (hasSkills) {
			this.placeholderInformationIconX =
				placeholderNameX +
				canvasContext.measureText(placeholderName).width +
				GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_MARGIN_LEFT;
			this.placeholderInformationIconY = placeholderNameY - GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_HEIGHT;

			canvasContext.drawImage(
				cacheManager.getCommonImage(COMMON_IMAGE.PLACEHOLDER_INFORMATION_ICON),
				this.placeholderInformationIconX,
				this.placeholderInformationIconY,
				GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_WIDTH,
				GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_HEIGHT
			);
		}

		// draw role
		if (role) {
			fontSize = 11;
			canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY;
			canvasContext.font = `400 ${fontSize}px ` + Util.getFontFamily();
			canvasContext.fillText(
				getTrimmedText(canvasContext, role, maxNameWidth),
				placeholderNameX,
				center + 8 + nameRoleGap
			);
		}

		// placeholder avatar
		this.drawAvatar(canvasContext, x + xOffset, center, name);

		canvasContext.textAlign = 'start';

		if (drawTotalMinutes) {
			// Total minutes badge
			const totalMinutesBadgeXPos = groupSectionWidth - GROUP_SECTION_PADDING_LEFT - badgeOrButtonWidth;
			drawRectangle(
				canvasContext,
				totalMinutesBadgeXPos,
				center - totalMinutesBadgeHeight / 2,
				badgeOrButtonWidth,
				totalMinutesBadgeHeight,
				{
					backgroundColor: TIMELINE_BACKGROUND_COLOR,
					borderRadius: 10,
				}
			);

			canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
			canvasContext.font = '500 10px ' + Util.getFontFamily();
			canvasContext.fillText(
				totalMinutes,
				totalMinutesBadgeXPos + GROUP_SECTION_BADGE_PADDING_HORIZONTAL / 2,
				center + 4
			);
			this.actionMenuX = totalMinutesBadgeXPos - GROUP_SECTION_PADDING_LEFT_SMALL;
		}

		if (!drawTotalMinutes && !drawAssignToButton) {
			this.actionMenuX = GROUP_SECTION_ACTIONS_MENU_LEFT_SCHEDULE_PEOPLE;
		}
	}
}

export default CapacityPlaceholderGroup;
