import React from 'react';
import {CardLoader} from 'web-components';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding: 0 10px;
	margin-bottom: 10px;
`;

const CardLoaderWrapper = () => {
	return (
		<div>
			<Wrapper>
				<CardLoader height={123}></CardLoader>
			</Wrapper>
			<Wrapper>
				<CardLoader height={123}></CardLoader>
			</Wrapper>
		</div>
	);
};

export default CardLoaderWrapper;
