/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExportTaskReportCSV_viewer$ref: FragmentReference;
declare export opaque type ExportTaskReportCSV_viewer$fragmentType: ExportTaskReportCSV_viewer$ref;
export type ExportTaskReportCSV_viewer = {|
  +company: ?{|
    +allTasks: ?{|
      +pageInfo: {|
        +endCursor: ?string,
        +hasNextPage: boolean,
      |},
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +customFieldValues: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +key: ?string,
                +value: ?string,
                +displayName: ?string,
              |}
            |}>
          |},
          +approved: ?boolean,
          +timeRegistrations: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +minutesRegistered: ?number,
                +price: ?number,
              |}
            |}>
          |},
          +sortOrder: ?number,
          +statusColumnV2: ?{|
            +id: string,
            +name: ?string,
          |},
          +companyTaskId: ?number,
          +name: ?string,
          +deadlineDay: ?number,
          +deadlineMonth: ?number,
          +deadlineYear: ?number,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +bug: ?boolean,
          +blocked: ?boolean,
          +highPriority: ?boolean,
          +billable: ?boolean,
          +progress: ?number,
          +estimateForecast: ?number,
          +estimateForecastPrice: ?number,
          +timeLeft: ?number,
          +projectedTime: ?number,
          +project: ?{|
            +id: string,
            +status: ?PROJECT_STATUS,
            +companyProjectId: ?number,
            +customProjectId: ?string,
            +minutesPerEstimationPoint: ?number,
            +projectEndDay: ?number,
            +projectEndMonth: ?number,
            +projectEndYear: ?number,
            +estimationUnit: ?string,
            +name: ?string,
            +client: ?{|
              +id: string,
              +name: ?string,
            |},
            +rateCard: ?{|
              +id: string,
              +name: ?string,
            |},
          |},
          +taskLabels: ?$ReadOnlyArray<?{|
            +label: ?{|
              +id: string,
              +name: ?string,
              +color: ?string,
            |}
          |}>,
          +assignedPersons: ?$ReadOnlyArray<?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
          |}>,
          +role: ?{|
            +name: ?string
          |},
          +phase: ?{|
            +id: string,
            +name: ?string,
            +deadlineDay: ?number,
            +deadlineMonth: ?number,
            +deadlineYear: ?number,
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
          |},
          +sprint: ?{|
            +id: string,
            +endDay: ?number,
            +endMonth: ?number,
            +endYear: ?number,
            +startDay: ?number,
            +startMonth: ?number,
            +startYear: ?number,
            +name: ?string,
            +isProjectGroupSprint: ?boolean,
            +projectGroupSprintId: ?string,
          |},
          +owner: ?{|
            +id: string
          |},
          +followers: ?$ReadOnlyArray<?{|
            +id: string
          |}>,
          +financialNumbers: ?{|
            +plannedRevenue: ?number,
            +actualRevenue: ?number,
            +remainingRevenue: ?number,
            +forecastRevenue: ?number,
            +recognitionLockedRevenue: ?number,
            +recognitionOpenRevenue: ?number,
            +recognitionForecastRevenue: ?number,
            +surplus: ?number,
          |},
        |}
      |}>,
    |}
  |},
  +$refType: ExportTaskReportCSV_viewer$ref,
|};
export type ExportTaskReportCSV_viewer$data = ExportTaskReportCSV_viewer;
export type ExportTaskReportCSV_viewer$key = {
  +$data?: ExportTaskReportCSV_viewer$data,
  +$fragmentRefs: ExportTaskReportCSV_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v9 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortValue",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "validateAccess",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "allTasks"
        ]
      }
    ]
  },
  "name": "ExportTaskReportCSV_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": "allTasks",
          "args": [
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            }
          ],
          "concreteType": "TaskTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_allTasks_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CustomFieldValueTypeConnection",
                      "kind": "LinkedField",
                      "name": "customFieldValues",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "CustomFieldValueTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "CustomFieldValue",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "key",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "value",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "displayName",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "approved",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 100000000
                        }
                      ],
                      "concreteType": "TimeRegTypeConnection",
                      "kind": "LinkedField",
                      "name": "timeRegistrations",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TimeRegTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TimeRegType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "minutesRegistered",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "price",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "timeRegistrations(first:100000000)"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sortOrder",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "StatusColumnV2",
                      "kind": "LinkedField",
                      "name": "statusColumnV2",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyTaskId",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v3/*: any*/),
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v7/*: any*/),
                    (v8/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "bug",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "blocked",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "highPriority",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billable",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "progress",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimateForecast",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimateForecastPrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timeLeft",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectedTime",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "companyProjectId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "customProjectId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "minutesPerEstimationPoint",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectEndDay",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectEndMonth",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectEndYear",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "estimationUnit",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Client",
                          "kind": "LinkedField",
                          "name": "client",
                          "plural": false,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "RateCard",
                          "kind": "LinkedField",
                          "name": "rateCard",
                          "plural": false,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "TaskLabel",
                      "kind": "LinkedField",
                      "name": "taskLabels",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Label",
                          "kind": "LinkedField",
                          "name": "label",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "color",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "assignedPersons",
                      "plural": true,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "firstName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lastName",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Role",
                      "kind": "LinkedField",
                      "name": "role",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PhaseType",
                      "kind": "LinkedField",
                      "name": "phase",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v8/*: any*/),
                        (v7/*: any*/),
                        (v6/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Sprint",
                      "kind": "LinkedField",
                      "name": "sprint",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endDay",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endMonth",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endYear",
                          "storageKey": null
                        },
                        (v8/*: any*/),
                        (v7/*: any*/),
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isProjectGroupSprint",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectGroupSprintId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "owner",
                      "plural": false,
                      "selections": (v9/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "followers",
                      "plural": true,
                      "selections": (v9/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FinancialNumbers",
                      "kind": "LinkedField",
                      "name": "financialNumbers",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionLockedRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionOpenRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionForecastRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "surplus",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd1b40023d2bb3ae13fb6673995cbec9f';

module.exports = node;
