import React from 'react';
import {Dropdown, Button} from 'web-components';
import {FormattedMessage} from 'react-intl';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';

const SalesforceIntegrationSection = ({
	linkedValue,
	linkToValue,
	options,
	onSelect,
	onUnlinkClick,
	dropdownPlaceholder,
	onSearchStringChanged,
	fetching,
}) => {
	return fetching ? (
		<InlineLoader />
	) : linkedValue ? (
		<div>
			<div onClick={() => (linkToValue ? window.open(linkToValue) : null)}>
				<FormattedMessage id="settings.linked_to" />
				<b>{linkedValue}</b>
			</div>
			<div className="unlink-button">
				<Button onClick={() => onUnlinkClick()} size={Button.SIZE.STANDARD} variant={Button.VARIANT.RED_OUTLINE}>
					<FormattedMessage id="common.unlink" />
				</Button>
			</div>
		</div>
	) : (
		<Dropdown
			isClearable={false}
			onSelect={v => onSelect(v)}
			name={dropdownPlaceholder ? dropdownPlaceholder : ''}
			searchStringChangedCallback={onSearchStringChanged}
		>
			{options
				.sort((o1, o2) => (o1.label < o2.label ? -1 : 1))
				.map(option => (
					<Dropdown.SingleText key={option.label} value={option.value} searchString={option.label}>
						{option.label}
					</Dropdown.SingleText>
				))}
		</Dropdown>
	);
};

export default SalesforceIntegrationSection;
