import React from 'react';
import {useIntl} from 'react-intl';
import {LoadMore} from '../../../loaders/LoadMore';
import {filtersToSearchQuery} from '../UtilizationReportUtils';
import UtilizationReportMonthByMonthRoleLoader, {
	utilizationReportMonthByMonthRoleLoaderQuery,
} from './UtilizationReportMonthByMonthRoleLoader';
import {exportToCsv} from './MonthByMonthDownload';
import {Table} from 'web-components';
import {MonthByMonthUtilizationMeter, getUtilizationNumber} from './MonthByMonthTable';
import MonthByMonthPersonRows from './MonthByMonthPersonRows';

const MonthByMonthRoleRows = ({
	filters,
	startDate,
	endDate,
	setCsvDownloadFunction,
	pageSize,
	showResourceUtilization,
	isMixedAllocationModeEnabled,
	allocationControlsOptions,
}) => {
	const intl = useIntl();
	return (
		<LoadMore
			key="query-render-month-by-month-role"
			query={utilizationReportMonthByMonthRoleLoaderQuery}
			loader={<UtilizationReportMonthByMonthRoleLoader />}
			variables={{
				pageSize,
				searchQuery: filtersToSearchQuery(filters),
				startYear: startDate ? startDate.year() : undefined,
				startMonth: startDate ? startDate.month() + 1 : undefined,
				startDay: startDate ? startDate.date() : undefined,
				endYear: endDate ? endDate.year() : undefined,
				endMonth: endDate ? endDate.month() + 1 : undefined,
				endDay: endDate ? endDate.date() : undefined,
			}}
		>
			{({data, loadMore}) => {
				setCsvDownloadFunction(() => {
					exportToCsv(
						startDate.year(),
						data.rows,
						getUtilizationNumber,
						showResourceUtilization,
						intl,
						isMixedAllocationModeEnabled,
						allocationControlsOptions
					);
				});
				return (
					<Table.Rows data={data} loadMore={loadMore} autoload={true} initiallyExpanded={false} canExpand={true}>
						{({rowData, tableColumnsProps, expanded, nextLevelProps}) => {
							return (
								<>
									<Table.Row {...tableColumnsProps} cy={'report-row'}>
										<Table.Column visible>{rowData.role.name}</Table.Column>
										{Array.from({length: 12}).map((_, index) => (
											<Table.Column visible key={index}>
												<MonthByMonthUtilizationMeter
													percentage={getUtilizationNumber(
														rowData,
														index,
														showResourceUtilization,
														isMixedAllocationModeEnabled,
														allocationControlsOptions
													)}
													theme="LIGHT"
												/>
											</Table.Column>
										))}
									</Table.Row>
									{expanded && (
										<MonthByMonthPersonRows
											filters={filters}
											startDate={startDate}
											endDate={endDate}
											pageSize={pageSize}
											showResourceUtilization={showResourceUtilization}
											roleId={rowData.role.id}
											levelProps={nextLevelProps}
											isMixedAllocationModeEnabled={isMixedAllocationModeEnabled}
											allocationControlsOptions={allocationControlsOptions}
										></MonthByMonthPersonRows>
									)}
								</>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default MonthByMonthRoleRows;
