import React from 'react';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import ProjectRelayWrapper, {projectRelayWrapperQuery} from './relay-wrappers/project_relay_wrapper';
import PersonRelayWrapper, {personRelayWrapperQuery} from './relay-wrappers/person_relay_wrapper';
import TimeRegRelayWrapper, {timeRegRelayWrapperQuery} from './relay-wrappers/time_reg_relay_wrapper';
import TaskRelayWrapper, {taskRelayWrapperQuery} from './relay-wrappers/task_relay_wrapper';
import PhaseRelayWrapper, {phaseRelayWrapperQuery} from './relay-wrappers/phase_relay_wrapper';
import SprintRelayWrapper, {sprintRelayWrapperQuery} from './relay-wrappers/sprint_relay_wrapper';
import DepartmentRelayWrapper, {departmentRelayWrapperQuery} from './relay-wrappers/department_relay_wrapper';
import RoleRelayWrapper, {roleRelayWrapperQuery} from './relay-wrappers/role_relay_wrapper';
import ClientRelayWrapper, {clientRelayWrapperQuery} from './relay-wrappers/client_relay_wrapper';
import {FILTER_TYPE, GLOBAL_FILTER_FIELD, HIDDEN_FEATURES, REPORT_GROUPINGS} from '../../../constants';
import {
	convertToGlobalFilter,
	getUnifiedFilterOperator,
	isOperator,
} from '../../../forecast-app/shared/components/filters/filter_logic';
import Util from '../../../forecast-app/shared/util/util';

export const getSecondDropdownOptionsByGroupings = (firstDropdownValue, intl) => {
	const secondDropdownOptions = [];
	switch (firstDropdownValue) {
		case REPORT_GROUPINGS.PERSON:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				{value: REPORT_GROUPINGS.TASK, label: intl.formatMessage({id: 'common.group_by_task'})},
				...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
					? [{value: REPORT_GROUPINGS.CLIENT, label: intl.formatMessage({id: 'common.group_by_client'})}]
					: []),
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.PROJECT:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
				{value: REPORT_GROUPINGS.PHASE, label: intl.formatMessage({id: 'common.group_by_phase'})},
				{value: REPORT_GROUPINGS.SPRINT, label: intl.formatMessage({id: 'common.group_by_sprint'})},
				{value: REPORT_GROUPINGS.TASK, label: intl.formatMessage({id: 'common.group_by_task'})},
				{value: null, label: 'No grouping'}
			);
			break;

		case REPORT_GROUPINGS.TASK:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.CLIENT:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.DEPARTMENT:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				//{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.ROLE:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case null:
			return null;
		default:
			return 'No second dropdown options provided!';
	}
	return secondDropdownOptions;
};

export const getSearchQueryFiltersValue = (defaultSearchQueryFilters, filters) => {
	const updatedFilters = [...defaultSearchQueryFilters];
	if (filters !== null && filters.timeRegs) {
		const timeRegFilters = filters.timeRegs;
		for (let key of Object.keys(timeRegFilters)) {
			if (isOperator(key)) {
				continue;
			}

			const value = timeRegFilters[key];
			const unifiedFilterOperator = getUnifiedFilterOperator(key, timeRegFilters);

			if (key === FILTER_TYPE.PERSON) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.PERSON_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.PROJECT) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.PROJECT_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.ROLE) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.ROLE_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.TEAM) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.TEAM_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.DEPARTMENT) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.DEPARTMENT_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.CLIENT) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.CLIENT_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.BILLABLE) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.BILLABLE_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.INTERNAL_TIME) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.INTERNAL_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.TIME_OFF) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.TIME_OFF_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.PROJECT_TYPE) {
				updatedFilters.push(convertToGlobalFilter(key, value, timeRegFilters));
			}
			if (key === FILTER_TYPE.OWNER) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.OWNER,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.INVOICED) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.INVOICED,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
			if (key === FILTER_TYPE.LABEL_TIME_REG) {
				updatedFilters.push({
					field: GLOBAL_FILTER_FIELD.LABEL_TIME_REG,
					operator: unifiedFilterOperator,
					value: value,
				});
			}
		}
	}
	return updatedFilters;
};

const addTimeRegRow = object => {
	if (object !== null) {
		return object.field === GLOBAL_FILTER_FIELD.NON_PROJECT_TIME_REG;
	}
};

export const getRelayWrapper = (
	tableHeader,
	groupings,
	searchQuery,
	groupIndex,
	enabledColumns,
	relayProps,
	object,
	nextLevelProps,
	sortValue
) => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return (
				<ProjectRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
				/>
			);
		case REPORT_GROUPINGS.PERSON:
			return (
				<PersonRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
				/>
			);
		case 'timeReg':
			return (
				<TimeRegRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
				/>
			);
		case REPORT_GROUPINGS.TASK:
			if (addTimeRegRow(object) === true) {
				return (
					<TimeRegRelayWrapper
						{...relayProps}
						tableHeader={tableHeader}
						searchQuery={searchQuery}
						groupIndex={groupIndex + 2}
						enabledColumns={enabledColumns}
						nextLevelProps={nextLevelProps}
						sortValue={sortValue}
					/>
				);
			}
			return (
				<TaskRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
				/>
			);
		case REPORT_GROUPINGS.SPRINT:
			if (addTimeRegRow(object) === true) {
				return (
					<TimeRegRelayWrapper
						{...relayProps}
						tableHeader={tableHeader}
						searchQuery={searchQuery}
						groupIndex={groupIndex + 2}
						enabledColumns={enabledColumns}
						nextLevelProps={nextLevelProps}
						sortValue={sortValue}
					/>
				);
			}
			return (
				<SprintRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
				/>
			);
		case REPORT_GROUPINGS.PHASE:
			if (addTimeRegRow(object) === true) {
				return (
					<TimeRegRelayWrapper
						{...relayProps}
						tableHeader={tableHeader}
						searchQuery={searchQuery}
						groupIndex={groupIndex + 2}
						enabledColumns={enabledColumns}
						nextLevelProps={nextLevelProps}
						sortValue={sortValue}
					/>
				);
			} else
				return (
					<PhaseRelayWrapper
						{...relayProps}
						tableHeader={tableHeader}
						groupings={groupings.slice(1)}
						searchQuery={searchQuery}
						groupIndex={groupIndex + 1}
						enabledColumns={enabledColumns}
						nextLevelProps={nextLevelProps}
						sortValue={sortValue}
					/>
				);
		case REPORT_GROUPINGS.CLIENT:
			return (
				<ClientRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
				/>
			);
		case REPORT_GROUPINGS.DEPARTMENT:
			return (
				<DepartmentRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
				/>
			);
		case REPORT_GROUPINGS.ROLE:
			return (
				<RoleRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					groupIndex={groupIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
				/>
			);
		default:
			return '(reported_time_page_util)No relay wrapper provided!';
	}
};

const getComponentName = (groupings, object) => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return 'project_relay_wrapper';
		case REPORT_GROUPINGS.PERSON:
			return 'person_relay_wrapper';
		case 'timeReg':
			return 'time_reg_relay_wrapper';
		case REPORT_GROUPINGS.TASK:
			if (addTimeRegRow(object) === true) {
				return 'time_reg_relay_wrapper';
			}
			return 'task_relay_wrapper';
		case REPORT_GROUPINGS.SPRINT:
			if (addTimeRegRow(object) === true) {
				return 'time_reg_relay_wrapper';
			}
			return 'sprint_relay_wrapper';
		case REPORT_GROUPINGS.PHASE:
			if (addTimeRegRow(object) === true) {
				return 'time_reg_relay_wrapper';
			} else return 'phase_relay_wrapper';
		case REPORT_GROUPINGS.CLIENT:
			return 'client_relay_wrapper';
		case REPORT_GROUPINGS.DEPARTMENT:
			return 'department_relay_wrapper';
		case REPORT_GROUPINGS.ROLE:
			return 'role_relay_wrapper';
		default:
			return 'No component name provided!';
	}
};

const getQuery = (groupings, object) => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return projectRelayWrapperQuery;
		case REPORT_GROUPINGS.PERSON:
			return personRelayWrapperQuery;
		case 'timeReg':
			return timeRegRelayWrapperQuery;
		case REPORT_GROUPINGS.TASK:
			if (addTimeRegRow(object) === true) {
				return timeRegRelayWrapperQuery;
			}
			return taskRelayWrapperQuery;
		case REPORT_GROUPINGS.CLIENT:
			return clientRelayWrapperQuery;
		case REPORT_GROUPINGS.DEPARTMENT:
			return departmentRelayWrapperQuery;
		case REPORT_GROUPINGS.ROLE:
			return roleRelayWrapperQuery;
		case REPORT_GROUPINGS.SPRINT:
			if (addTimeRegRow(object) === true) {
				return timeRegRelayWrapperQuery;
			}
			return sprintRelayWrapperQuery;
		case REPORT_GROUPINGS.PHASE:
			if (addTimeRegRow(object) === true) {
				return timeRegRelayWrapperQuery;
			} else return phaseRelayWrapperQuery;
		default:
			return 'No query provided!';
	}
};
const keyReducer = (accumulator, currentValue) => accumulator + currentValue.value[0];

const getQueryInfo = (groupings, searchQuery, object) => {
	const queryInfo = {};
	const keyEnding = searchQuery.filters.length > 0 && searchQuery.filters.reduce(keyReducer);
	queryInfo.key = 'query-render-relayWrapper' + keyEnding;
	queryInfo.componentName = getComponentName(groupings, object);
	queryInfo.query = getQuery(groupings, object);
	return queryInfo;
};

export const getQueryRenderer = (
	tableHeader,
	groupings,
	searchQuery,
	groupIndex,
	enabledColumns,
	object,
	nextLevelProps,
	sortValue = null
) => {
	const searchQueryFiltersCopy = [...searchQuery.filters];
	if (object !== null) {
		searchQueryFiltersCopy.push(object);
	}
	const newSearchQuery = {filters: searchQueryFiltersCopy};
	const query = getQueryInfo(groupings, searchQuery, object);
	return (
		<ForecastQueryRenderer
			key={query.key}
			query={query.query}
			variables={{
				pageSize: 30,
				searchQuery: newSearchQuery,
				sortValue: sortValue,
			}}
			render={relayProps => {
				return getRelayWrapper(
					tableHeader,
					groupings,
					newSearchQuery,
					groupIndex,
					enabledColumns,
					relayProps,
					object,
					nextLevelProps,
					sortValue
				);
			}}
		/>
	);
};
