import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {fetchMapData} from '../reported_time_financials_fetch';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import {getPersonRow} from '../rows/person_row';
import BaseLevelWrapper from './BaselevelWrapper';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {hasSomePermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {useDidObjectChange} from '../../../../forecast-app/shared/hooks/useDidObjectChange';

const personRelayWrapper = ({
	tableHeader,
	relay,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
}) => {
	const persons = viewer.company.allPersonsGlobalSearch.edges;
	const currency = viewer.company.currency;
	const hasNextPage = viewer.company.allPersonsGlobalSearch.pageInfo.hasNextPage;
	const [personsFinancialMap, setPersonsFinancialMap] = useState(null);
	const [modalTasksFetching, setModalTasksFetching] = useState(false);

	const personsChanged = useDidObjectChange(persons, null);
	const searchQueryChanged = useDidObjectChange(searchQuery, null);
	const shouldRefetchFinancials = personsChanged || searchQueryChanged;

	//get persons financial totals
	useEffect(() => {
		if (shouldRefetchFinancials) {
			const personsIds = persons.map(person => person.node.id);
			if (
				hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				])
			) {
				fetchMapData(personsIds, searchQuery, 'total_person_time_regs_financials').then(data => {
					const personsFinancialMap = new Map();
					Object.keys(data).forEach(key => {
						personsFinancialMap.set(key, data[key]);
					});

					setPersonsFinancialMap(personsFinancialMap);
				});
			}
		}
	}, [shouldRefetchFinancials]);

	//add totalPrice and totalCost to dataRows
	const dataRows = persons.map(person => {
		return {
			...person.node,
			totalPrice:
				personsFinancialMap !== null && personsFinancialMap.has(person.node.id)
					? personsFinancialMap.get(person.node.id).totalPrice
					: null,
			totalCost:
				personsFinancialMap !== null && personsFinancialMap.has(person.node.id)
					? personsFinancialMap.get(person.node.id).totalCost
					: null,
		};
	});

	const data = {rows: dataRows};

	//function for loading more data
	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = persons.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const personRows = () => {
		return getPersonRow(
			data,
			currency,
			intl,
			groupIndex,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader
		);
	};

	return groupIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={personRows}
			currentLength={persons.length}
			hasNextPage={viewer.company.allPersonsGlobalSearch.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		personRows()
	);
};

const personRelayWrapperQuery = graphql`
	query personRelayWrapperQuery_Query($searchQuery: TaskSearchQueryType, $pageSize: Int) {
		viewer {
			actualPersonId
			component(name: "person_relay_wrapper")
			...personRelayWrapper_viewer @arguments(searchQuery: $searchQuery, pageSize: $pageSize)
		}
	}
`;
export {personRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			personRelayWrapper,
			{
				viewer: graphql`
					fragment personRelayWrapper_viewer on Viewer
					@argumentDefinitions(searchQuery: {type: "TaskSearchQueryType"}, pageSize: {type: "Int"}) {
						availableFeatureFlags {
							key
						}
						company {
							currency
							allPersonsGlobalSearch(first: $pageSize, searchQuery: $searchQuery) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										totalMinutesRegistered(searchQuery: $searchQuery)
										id
										firstName
										lastName
										fullName
										profilePictureId
										department {
											name
										}
										active
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query personRelayWrapperRefetchQuery($pageSize: Int, $searchQuery: TaskSearchQueryType) {
					viewer {
						...personRelayWrapper_viewer @arguments(pageSize: $pageSize, searchQuery: $searchQuery)
					}
				}
			`
		)
	)
);
