import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {useEffect} from 'react';
import {exportToCsv} from '../ProjectPortfolioReportCsvDownload';
import {GROUP_BY, PROJECT_PORTFOLIO_REPORT_PAGES} from '../ProjectPortfolioReport';

const ProjectPortfolioPeriodClientDownload = ({viewer, closeModal, startDate, endDate, boolEyeOptions}) => {
	const intl = useIntl();
	const currency = viewer.company.currency;
	const clients = viewer.company.financialBasedClients.edges.map(edge => edge.node);

	useEffect(() => {
		if (viewer) {
			exportToCsv({
				rows: clients,
				grouping: GROUP_BY.CLIENT,
				page: PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD,
				currency,
				boolEyeOptions,
				startDate,
				endDate,
				intl,
			});
			closeModal();
		}
	}, [viewer]);

	return null;
};

export const projectPortfolioPeriodClientDownloadQuery = graphql`
	query ProjectPortfolioPeriodClientDownload_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$searchQuery: TaskSearchQueryType!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_period_client_csv_loader")
			...ProjectPortfolioPeriodClientDownload_viewer
				@arguments(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createFragmentContainer(ProjectPortfolioPeriodClientDownload, {
	viewer: graphql`
		fragment ProjectPortfolioPeriodClientDownload_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
		) {
			id
			company {
				currency
				financialBasedClients(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				) {
					edges {
						node {
							id
							name
							financialNumbers {
								billableActualTimeAndExpenses
								totalActualRevenueRecognition
								actualCost
								actualProfit
								actualRevenueProfit
								actualMargin
								actualRevenueMargin
								billablePlannedTimeAndExpenses
								plannedRevenue
								plannedCost
								plannedProfit
								plannedRevenueProfit
								plannedMargin
								plannedRevenueMargin
								billableForecastTimeAndExpensesToComplete
								totalForecastRevenueToComplete
								forecastCostToComplete
								forecastProfitToComplete
								forecastRevenueProfitToComplete
								forecastMarginToComplete
								forecastRevenueMarginToComplete
								billableTotalTimeAndExpensesAtCompletion
								nonBillableTotalTimeAndExpensesAtCompletion
								allTotalTimeAndExpensesAtCompletion
								totalRevenueRecognition
								totalCostAtCompletion
								totalProfitAtCompletion
								totalRevenueProfitAtCompletion
								totalMarginAtCompletion
								totalRevenueMarginAtCompletion
								baselineRevenue
								baselineTimeAndExpenses
								baselineCost
								baselineProfit
								baselineMargin
								baselineMinutes
								planVsBillableActualTimeAndExpenses
								planVsActualCost
								planVsActualProfit
								planVsTotalBillableTimeAndExpensesAtCompletion
								planVsTotalCostAtCompletion
								planVsTotalProfitAtCompletion
								registeredMinutes
								forecastTimeToComplete
								totalTimeAtCompletion
								scopeApprovedMinutes
								scopeTotalMinutes
								allocationMinutes
								invoiced
								paid
								accruedDeferred
								retainerPeriodTargetMinutes
								retainerPeriodTargetPrice
								recognitionLockedRevenue
								recognitionOpenRevenue
								totalRevenueRecognition
								recognitionProfit
								recognitionProfitPercentage
								projectedTotalRevenueVsProjectedTotalBillableValueOfService
								projectedTotalRevenueVsProjectedTotalValueOfService
							}
						}
					}
				}
			}
		}
	`,
});
