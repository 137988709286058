import React, {Component} from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import ChartJsBar from '../../chartjs/chart_js_bar';

class utilizationBarChartComponent extends Component {
	UNSAFE_componentWillMount() {
		if (this.props.dateSpan) {
			if (this.props.dateSpan.key === 'custom-date-range') {
				if (this.props.dateSpan.value && this.props.dateSpan.value.start && this.props.dateSpan.value.end) {
					this.props.relay.refetch({
						dateCriteria:
							this.props.dateSpan.value.start.format('DD/MM/YYYY') +
							'_' +
							this.props.dateSpan.value.end.format('DD/MM/YYYY'),
					});
				}
			} else {
				this.props.relay.refetch({
					dateCriteria: this.props.dateSpan.value,
				});
			}
		} else {
			// no date criteria default is current month
			this.props.relay.refetch({
				dateCriteria: 'current-month',
			});
		}
	}

	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.dateCriteria !== nextProps.dateCriteria) {
			this.props.relay.refetch({
				dateCriteria: nextProps.dateCriteria,
			});
		}
		if (this.props.labelFilterValue.length !== nextProps.labelFilterValue.length) {
			const labelIds = nextProps.labelFilterValue.length !== 0 ? nextProps.labelFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({labelIds});
		}
		if (this.props.teamFilterValue.length !== nextProps.teamFilterValue.length) {
			const teamIds = nextProps.teamFilterValue.length !== 0 ? nextProps.teamFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({teamIds});
		}
		if (this.props.roleFilterValue.length !== nextProps.roleFilterValue.length) {
			const roleIds = nextProps.roleFilterValue.length !== 0 ? nextProps.roleFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({roleIds});
		}
		if (this.props.personFilterValue.length !== nextProps.personFilterValue.length) {
			const personIds = nextProps.personFilterValue.length !== 0 ? nextProps.personFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({personIds});
		} else {
			// Calculate height based on amount of cards in nextProps
			const listHeight = Math.ceil(
				(150 + 60 * nextProps.viewer.insightComponentsData.utilizationBarChart.data.length) / 200
			);
			if (this.props.height !== listHeight) {
				this.props.updateComponentHeight(this.props.id, listHeight);
			}
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const data = {
			labels: [],
			datasets: [],
		};
		if (this.props.viewer.insightComponentsData.utilizationBarChart) {
			const projectTimeData = [];
			const reportedTimeData = [];
			const availableTimeData = [];
			const nonProjectTimeData = [];
			const timeOffData = [];
			this.props.viewer.insightComponentsData.utilizationBarChart.data.forEach(el => {
				data.labels.push(el.personName);
				projectTimeData.push(el.projectHours + el.plannedCardHours);
				reportedTimeData.push(el.registeredHours);
				availableTimeData.push(el.availableHours);
				nonProjectTimeData.push(el.idleHours);
				timeOffData.push(el.timeOffHours);
			});
			data.datasets.push({
				label: formatMessage({id: 'common.working_hours'}),
				backgroundColor: '#00cc66',
				stack: 'Stack 0',
				data: availableTimeData,
				maxBarThickness: 60,
			});
			data.datasets.push({
				label: formatMessage({id: 'common.time_off'}),
				backgroundColor: '#CECBF7',
				stack: 'Stack 0',
				data: timeOffData,
				maxBarThickness: 60,
			});
			data.datasets.push({
				label: formatMessage({id: 'common.allocated_project_time'}),
				backgroundColor: '#6e0fea',
				stack: 'Stack 1',
				data: projectTimeData,
				maxBarThickness: 60,
			});
			data.datasets.push({
				label: formatMessage({id: 'common.allocated_internal_time'}),
				backgroundColor: '#dbdbdb',
				stack: 'Stack 1',
				data: nonProjectTimeData,
				maxBarThickness: 60,
			});
			data.datasets.push({
				label: formatMessage({id: 'insights.component.list.column.registered'}),
				backgroundColor: '#A2DAFF',
				stack: 'Stack 2',
				data: reportedTimeData,
				maxBarThickness: 60,
			});
		}

		return this.props.viewer.insightComponentsData.utilizationBarChart ? (
			<ChartJsBar
				key={uuid.v4()}
				data={data}
				xAxisLabel={formatMessage({id: 'common.hours'})}
				height={88 + 60 * this.props.viewer.insightComponentsData.utilizationBarChart.data.length}
				type="horizontalBar"
				legendPosition="top"
				isUtilizationBarChart
			/>
		) : null;
	}
}

utilizationBarChartComponent.propTypes = {
	title: PropTypes.string,
	dateSpan: PropTypes.object,
};

const utilizationBarChartComponentQuery = graphql`
	query utilizationBarChartComponent_Query(
		$shareKey: String
		$dateCriteria: String
		$componentId: ID
		$personIds: [ID]
		$teamIds: [ID]
		$roleIds: [ID]
		$labelIds: [ID]
	) {
		viewer {
			actualPersonId
			component(name: "insight_utilization_bar_chart")
			...utilizationBarChartComponent_viewer
				@arguments(
					shareKey: $shareKey
					dateCriteria: $dateCriteria
					componentId: $componentId
					personIds: $personIds
					teamIds: $teamIds
					roleIds: $roleIds
					labelIds: $labelIds
				)
		}
	}
`;

export {utilizationBarChartComponentQuery};

export default injectIntl(
	createRefetchContainer(
		utilizationBarChartComponent,
		{
			viewer: graphql`
				fragment utilizationBarChartComponent_viewer on Viewer
				@argumentDefinitions(
					shareKey: {type: "String"}
					dateCriteria: {type: "String"}
					componentId: {type: "ID"}
					personIds: {type: "[ID]"}
					teamIds: {type: "[ID]"}
					roleIds: {type: "[ID]"}
					labelIds: {type: "[ID]"}
				) {
					insightComponentsData(shareKey: $shareKey) {
						utilizationBarChart(
							dateCriteria: $dateCriteria
							personIds: $personIds
							componentId: $componentId
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
						) {
							data {
								id
								personName
								availableHours
								projectHours
								idleHours
								registeredHours
								plannedCardHours
								timeOffHours
							}
						}
					}
				}
			`,
		},
		graphql`
			query utilizationBarChartComponentRefetchQuery(
				$shareKey: String
				$dateCriteria: String
				$componentId: ID
				$personIds: [ID]
				$teamIds: [ID]
				$roleIds: [ID]
				$labelIds: [ID]
			) {
				viewer {
					...utilizationBarChartComponent_viewer
						@arguments(
							shareKey: $shareKey
							dateCriteria: $dateCriteria
							componentId: $componentId
							personIds: $personIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
						)
				}
			}
		`
	)
);
