import React, { useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Alert, FlexColumn, FlexRow, Modal, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import ConditionalDropdown from '../../dropdowns/conditional-dropdown/ConditionalDropdown';
import { getNotNullishNodes } from '../../../forecast-app/shared/util/NotNullPredicate';
import BulkUpdateExpenseItemsMutation from '../../../mutations/bulk_update_expense_items_mutation';
import Util from '../../../forecast-app/shared/util/util';
import { FieldLabel, PlaceholderItem } from './ModalComponents';
import ProjectIndicator from '../../../forecast-app/shared/components/project-indicator/ProjectIndicator';
const ProjectItem = ({ project }) => (React.createElement(FlexRow, { gap: 'm' },
    React.createElement(ProjectIndicator, { project: project }),
    React.createElement(Text, { ellipsis: true }, project.name)));
const PhaseItem = ({ phase }) => (React.createElement(FlexRow, null,
    React.createElement(Text, { ellipsis: true }, phase.name)));
const findCommonProject = (expenseItems, projects) => {
    var _a;
    if (expenseItems.length === 0) {
        return;
    }
    const firstProjectId = (_a = expenseItems[0].project) === null || _a === void 0 ? void 0 : _a.id;
    const commonProjectId = expenseItems.every(expenseItem => { var _a; return ((_a = expenseItem.project) === null || _a === void 0 ? void 0 : _a.id) === firstProjectId; })
        ? firstProjectId
        : undefined;
    return commonProjectId ? projects.find(project => project.id === commonProjectId) : undefined;
};
const findCommonPhase = (expenseItems, phases) => {
    var _a;
    if (expenseItems.length === 0) {
        return;
    }
    const firstPhaseId = (_a = expenseItems[0].phase) === null || _a === void 0 ? void 0 : _a.id;
    const commonPhaseId = expenseItems.every(expenseItem => { var _a; return ((_a = expenseItem.phase) === null || _a === void 0 ? void 0 : _a.id) === firstPhaseId; }) ? firstPhaseId : undefined;
    return commonPhaseId ? phases.find(phase => phase.id === commonPhaseId) : undefined;
};
const ExpenseItemChangeProjectPhaseModal = ({ expenseItems, closeModal, viewer }) => {
    const { formatMessage } = useIntl();
    const updateableItems = useMemo(() => expenseItems.filter(expenseItem => !expenseItem.locked), [expenseItems]);
    const projects = useMemo(() => { var _a; return getNotNullishNodes((_a = viewer.projects) === null || _a === void 0 ? void 0 : _a.edges); }, [viewer]);
    const commonProject = useMemo(() => findCommonProject(updateableItems.length ? updateableItems : expenseItems, projects), [updateableItems, expenseItems, projects]);
    const [project, setProject] = useState(commonProject);
    const phases = useMemo(() => { var _a; return (project ? getNotNullishNodes((_a = viewer.phases) === null || _a === void 0 ? void 0 : _a.edges).filter(phase => phase.projectId === project.id) : []); }, [project, viewer]);
    const commonPhase = useMemo(() => findCommonPhase(updateableItems.length ? updateableItems : expenseItems, phases), [updateableItems, expenseItems, phases]);
    const [phase, setPhase] = useState(commonPhase);
    const onChangeProject = (newProject) => {
        setProject(newProject);
        setPhase(undefined);
    };
    const count = expenseItems.length;
    const lockedCount = expenseItems.length - updateableItems.length;
    const showAlert = lockedCount > 0;
    const allowSave = project && count > lockedCount;
    const saveChanges = () => {
        const onSuccess = () => {
            closeModal();
        };
        Util.CommitMutation(BulkUpdateExpenseItemsMutation, {
            ids: updateableItems.map(expenseItem => expenseItem.id),
            projectId: project === null || project === void 0 ? void 0 : project.id,
            phaseId: phase === null || phase === void 0 ? void 0 : phase.id,
        }, onSuccess);
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "expense_management.change_project_phase.title" })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 'xl' },
                React.createElement(FieldLabel, { labelId: 'common.project', helperTextId: 'expense_management.change_project_phase.project_helper' },
                    React.createElement(ConditionalDropdown, { options: projects, useTriggerWidth: true, autoSelect: false, renderOption: project => React.createElement(ProjectItem, { project: project }), onSelect: onChangeProject, "data-cy": 'select-project' }, project ? (React.createElement(ProjectItem, { project: project })) : (React.createElement(PlaceholderItem, { messageId: 'expense_management.change_project_phase.project_placeholder' })))),
                React.createElement(FieldLabel, { optional: true, labelId: 'common.phase', helperTextId: phases.length ? 'expense_management.change_project_phase.phase_helper' : undefined },
                    React.createElement(ConditionalDropdown, { options: phases, useTriggerWidth: true, autoSelect: false, renderOption: phase => React.createElement(PhaseItem, { phase: phase }), onSelect: setPhase, "data-cy": 'select-phase' }, phase ? (React.createElement(PhaseItem, { phase: phase })) : (React.createElement(PlaceholderItem, { messageId: 'expense_management.change_project_phase.phase_placeholder' })))),
                showAlert && (React.createElement(Alert, { text: formatMessage({ id: 'expense_management.change_project_phase.locked_alert' }, { count, lockedCount }), type: Alert.TYPE.INFORMATION })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: saveChanges, disabled: !allowSave, dataCy: 'confirm' },
                React.createElement(FormattedMessage, { id: 'expense_management.actions.confirm_changes' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal, dataCy: 'cancel' },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export const ExpenseItemChangeProjectPhaseModalQuery = graphql `
	query ExpenseItemChangeProjectPhaseModalQuery {
		viewer {
			actualPersonId
			component(name: "ExpenseItemChangeProjectPhaseModal")
			...ExpenseItemChangeProjectPhaseModal_viewer
		}
	}
`;
export default createFragmentContainer(ExpenseItemChangeProjectPhaseModal, {
    viewer: graphql `
		fragment ExpenseItemChangeProjectPhaseModal_viewer on Viewer {
			id
			company {
				id
			}
			phases {
				edges {
					node {
						id
						name
						projectId
					}
				}
			}
			projects(first: 100000) @connection(key: "Viewer_projects", filters: []) {
				edges {
					node {
						id
						name
						projectColor
						companyProjectId
						...ProjectIndicator_project
					}
				}
			}
		}
	`,
});
