import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import GenericModal from '../../../containers/modal/generic_modal';
import {
	ProjectPhaseModalStyled,
	TopSection,
	ProjectName,
	ProjectInfoSeparator,
	Table,
	HeaderTableRow,
	TdHead,
	TdBody,
	TrBody,
	UtilizationIndicator,
	EmptyState,
	Warning,
	Instruction,
	TdHeadContainer,
	ProjectId,
	ProjectIdAndName,
	InformationAndBadge,
	DatesAndMetaData,
	Information,
} from '../../../styles/sections/scheduling/project_phase_capacity_modal_styled';
import CapacityModalEmptyStateGraphic from '../../../images/graphics/capacityModalEmptyStateGraphic';

import {DeprecatedProjectGroupIndicatorComponent} from '../../../forecast-app/shared/components/project-indicator/DeprecatedProjectGroupIndicator';
import {DeprecatedProjectIndicatorComponent} from '../../../forecast-app/shared/components/project-indicator/DeprecatedProjectIndicator';

const ProjectPhaseCapacityModal = props => {
	const {formatDate, formatMessage} = props.intl;
	const {
		roleData,
		projectColor,
		customProjectId,
		startDate,
		endDate,
		mainLabel,
		projectId,
		isProjectGroup,
		subLabel,
		isPhase,
		closeModal,
	} = props;

	const companyProjectId = `${isProjectGroup ? 'X' : 'P'}${projectId}`;

	const content = (
		<ProjectPhaseModalStyled>
			<TopSection>
				<InformationAndBadge>
					{isProjectGroup ? (
						<DeprecatedProjectGroupIndicatorComponent
							companyProjectGroupId={projectId}
							projectGroupColor={projectColor}
						/>
					) : (
						<DeprecatedProjectIndicatorComponent
							companyProjectId={projectId}
							projectColor={projectColor}
							customProjectId={customProjectId}
						/>
					)}
					<Information>
						<ProjectIdAndName>
							<ProjectId>{companyProjectId}</ProjectId>
							<ProjectName>{mainLabel}</ProjectName>
						</ProjectIdAndName>

						<DatesAndMetaData>
							{`${formatDate(startDate, {
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							})} ${startDate && endDate ? '-' : ''} ${formatDate(endDate, {
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
							})}`}
							{subLabel && (startDate || endDate) && <ProjectInfoSeparator />}
							{subLabel}
						</DatesAndMetaData>
					</Information>
				</InformationAndBadge>
			</TopSection>
			{roleData.length ? (
				<Table>
					<thead>
						<HeaderTableRow>
							<TdHead>
								<TdHeadContainer isRole={true}>{formatMessage({id: 'common.role'})}</TdHeadContainer>
							</TdHead>
							<TdHead>
								<TdHeadContainer>
									{formatMessage({id: 'project_overview.remaining_capacity_tile'})}
								</TdHeadContainer>
							</TdHead>
							<TdHead>
								<TdHeadContainer>{formatMessage({id: 'scheduling.remaining_tasks'})}</TdHeadContainer>
							</TdHead>
							<TdHead>
								<TdHeadContainer>{formatMessage({id: 'scheduling.capacity_difference'})}</TdHeadContainer>
							</TdHead>
						</HeaderTableRow>
					</thead>
					<tbody>
						{roleData.map((entry, index) => (
							<TrBody key={`project-scheduling-modal-entry-${index}`}>
								<TdBody isNameColumn>
									<UtilizationIndicator isNegative={entry.isDifferenceNegative} />
									{entry.name}
								</TdBody>
								<TdBody>{entry.allocated}</TdBody>
								<TdBody>{entry.remaining}</TdBody>
								<TdBody>{entry.difference}</TdBody>
							</TrBody>
						))}
					</tbody>
				</Table>
			) : (
				<EmptyState>
					<CapacityModalEmptyStateGraphic />
					<Warning>
						{isPhase
							? formatMessage({id: 'scheduling.phase_capacity_empty_state_warning'})
							: formatMessage({id: 'scheduling.project_capacity_empty_state_warning'})}
					</Warning>
					<Instruction>{formatMessage({id: 'scheduling.project_capacity_empty_state_instruction'})}</Instruction>
				</EmptyState>
			)}
		</ProjectPhaseModalStyled>
	);

	return (
		<GenericModal
			closeModal={closeModal}
			content={content}
			headerText={formatMessage({
				id: roleData.length
					? isPhase
						? 'scheduling.phase_capacity'
						: isProjectGroup
						? 'scheduling.connected_project_capacity'
						: 'scheduling.project_capacity'
					: 'scheduling.project_capacity_empty_state_title',
			})}
			className="project-capacity-modal"
		/>
	);
};

ProjectPhaseCapacityModal.propTypes = {
	roleData: PropTypes.array.isRequired,
	closeModal: PropTypes.func.isRequired,
	mainLabel: PropTypes.string.isRequired,
	projectColor: PropTypes.string.isRequired,
	customProjectId: PropTypes.string.isRequired,
	startDate: PropTypes.object.isRequired,
	endDate: PropTypes.object.isRequired,
};

export default injectIntl(ProjectPhaseCapacityModal);
