/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectPersonDropdown_persons$ref: FragmentReference;
declare export opaque type ProjectPersonDropdown_persons$fragmentType: ProjectPersonDropdown_persons$ref;
export type ProjectPersonDropdown_persons = $ReadOnlyArray<{|
  +node: ?{|
    +id: string,
    +role: ?{|
      +name: ?string
    |},
    +person: ?{|
      +id: string,
      +firstName: ?string,
      +lastName: ?string,
      +profilePictureId: ?string,
      +role: ?{|
        +name: ?string
      |},
    |},
  |},
  +$refType: ProjectPersonDropdown_persons$ref,
|}>;
export type ProjectPersonDropdown_persons$data = ProjectPersonDropdown_persons;
export type ProjectPersonDropdown_persons$key = $ReadOnlyArray<{
  +$data?: ProjectPersonDropdown_persons$data,
  +$fragmentRefs: ProjectPersonDropdown_persons$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ProjectPersonDropdown_persons",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectPerson",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Person",
          "kind": "LinkedField",
          "name": "person",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "profilePictureId",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectPersonTypeEdge"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a644052c736d35161d5d19c5c293fe11';

module.exports = node;
