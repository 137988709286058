import React from 'react';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../css_variables';
import {CaretIcon} from 'web-components';

const HideOrShowStyles = styled.div`
	display: flex;
	align-items: center;
	font-size: 13px;
	text-align: left;
	cursor: pointer;
	height: 100%;
	color: ${CSS_CONSTANTS.v2_text_gray};
	&:hover {
		color: ${CSS_CONSTANTS.v2_branding_purple};
	}
	.text {
		margin-right: 16px;
	}
`;

export const HideOrShow = ({showId, hideId, isShowing, showText, hideText, callback, cy, userpilot}) => {
	return (
		<HideOrShowStyles data-userpilot={userpilot || ''} data-cy={cy} onClick={callback} id={isShowing ? showId : hideId}>
			<div className={'text'}>{isShowing ? showText : hideText}</div>
			<CaretIcon
				color={CSS_CONSTANTS.v2_text_gray}
				direction={isShowing ? CaretIcon.DIRECTION.DOWN : CaretIcon.DIRECTION.UP}
			/>
		</HideOrShowStyles>
	);
};
