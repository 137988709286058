/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetPageHeader_company$ref: FragmentReference;
declare export opaque type BudgetPageHeader_company$fragmentType: BudgetPageHeader_company$ref;
export type BudgetPageHeader_company = {|
  +id: string,
  +tier: ?TierType,
  +currency: ?string,
  +sageIntacctConnectionEnabled: ?boolean,
  +$refType: BudgetPageHeader_company$ref,
|};
export type BudgetPageHeader_company$data = BudgetPageHeader_company;
export type BudgetPageHeader_company$key = {
  +$data?: BudgetPageHeader_company$data,
  +$fragmentRefs: BudgetPageHeader_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BudgetPageHeader_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sageIntacctConnectionEnabled",
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = 'ec2f01bcd93df906cd64a86024566654';

module.exports = node;
