import React, {useState} from 'react';
import {
	DeprecatedCreativeModal as CreativeModal,
	FlexColumn,
	FlexRow,
	Icon,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import Update_project_mutationModern from '../../../../../mutations/update_project_mutation.modern';
import Util from '../../../../../forecast-app/shared/util/util';
import {CurrencyInput} from 'web-components';
import ProjectUtil from '../../../../../forecast-app/shared/util/project_util';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import ForecastTooltip from '../../../../../forecast-app/shared/components/tooltips/ForecastTooltip';

const UpdateRateAndInternalCostLiteModal = ({closeModal, project, company}) => {
	const {formatMessage} = useIntl();

	const [flatCost, setFlatCost] = useState(project.flatCost);
	const [flatRate, setFlatRate] = useState(project.flatRate);

	const persistRevenueEnabledSetting = () => {
		Util.CommitMutation(
			Update_project_mutationModern,
			{
				id: project.id,
				flatCost: flatCost,
				flatRate: flatRate,
			},
			() => {
				if (flatCost !== project.flatCost) {
					trackEvent('Flat Cost', 'Updated');
				}
				if (flatRate !== project.flatRate) {
					trackEvent('Flat Rate', 'Updated');
				}
				closeModal();
			}
		);
	};

	return (
		<CreativeModal
			closeModal={closeModal}
			creativeButtonText={formatMessage({id: 'common.confirm_changes'})}
			onCreateHandler={() => persistRevenueEnabledSetting(false)}
			title={formatMessage({
				id: ProjectUtil.projectTracksRevenue(project)
					? 'project_budget.edit_rate_and_cost'
					: 'project_budget.edit_cost',
			})}
		>
			<FlexColumn gap={'l'}>
				{ProjectUtil.projectTracksRevenue(project) && (
					<FlexColumn gap={'s'}>
						<FlexRow gap={'s'}>
							<Text type={'heading'} variant={'s'}>
								{formatMessage({id: 'project_budget.blended_rate'})}
							</Text>
							<ForecastTooltip
								placement={'bottom'}
								content={formatMessage({id: 'project_budget.blended_rate_tooltip'})}
							>
								<Icon icon={'help'} size={'s'} />
							</ForecastTooltip>
						</FlexRow>
						<Text>
							<CurrencyInput
								value={flatRate}
								currencySymbole={Util.GetCurrencySymbol(company.currency)}
								callback={setFlatRate}
								filterNonNumerics={true}
							/>
						</Text>
					</FlexColumn>
				)}
				<FlexColumn gap={'s'}>
					<FlexRow gap={'s'}>
						<Text type={'heading'} variant={'s'}>
							{formatMessage({id: 'project_budget.internal_hourly_cost'})}
						</Text>
						<ForecastTooltip
							placement={'bottom'}
							content={formatMessage({id: 'project_budget.internal_hourly_cost_tooltip'})}
						>
							<Icon icon={'help'} size={'s'} />
						</ForecastTooltip>
					</FlexRow>
					<Text>
						<CurrencyInput
							value={flatCost}
							currencySymbole={Util.GetCurrencySymbol(company.currency)}
							callback={setFlatCost}
							filterNonNumerics={true}
						/>
					</Text>
				</FlexColumn>
			</FlexColumn>
		</CreativeModal>
	);
};

export default UpdateRateAndInternalCostLiteModal;
