import {
	createCanvasTimelineDate,
	DAY_NAMES,
	getCombinedTaskMode,
	getHolidayCalendar,
	getVisualizationMode,
	isTimeOffAllocation,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import {getWeekDayIndexFromCanvasDate} from '../canvas-timeline/canvas-timeline-date-util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import SortedSet from 'sorted-set';
import DataManager from '../DataManager';
import {getChangeListEntities} from '../placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import {STAFFING_CHANGE_LIST_ENTITIES} from '../placeholders-scheduling/CanvasPlaceholderSchedulingConstants';
import {clearPersonWeekdaysCache} from '../../scheduling/project_allocation_logic';

export const getSortedSetRange = (sortedSet, start, end) => {
	return sortedSet.range(start, end);
};

export const getRange = (set, start, end) => {
	const range = [];
	for (let entry of set) {
		if (entry >= start && entry <= end) {
			range.push(entry);
		}
	}

	return range;
};

export const isWorkingDay = (personWorkingMinutes, canvasTimelineDate) => {
	const weekdayIndex = getWeekDayIndexFromCanvasDate(canvasTimelineDate);
	return personWorkingMinutes[weekdayIndex] > 0;
};

export const getNonWorkingDays = (start, end, nonWorkingDaysMap, personId, personWorkingMinutes) => {
	let range = new Set();

	if (!hasFeatureFlag('inverted_pto_non_working_days')) {
		const personNonWorkingDays = nonWorkingDaysMap && personId ? nonWorkingDaysMap.get(personId) : null;

		if (personNonWorkingDays) {
			// If start and end is same date, just do a direct lookup in the Set
			if (start === end) {
				const result = personNonWorkingDays.get(start);

				if (hasFeatureFlag('combined_mode_performance_improvements')) {
					if (result && (!personWorkingMinutes || isWorkingDay(personWorkingMinutes, result))) {
						range.add(result);
					}
				} else {
					if (result) {
						range.add(result);
					}
				}
			} else if (start < end) {
				if (personWorkingMinutes && hasFeatureFlag('combined_mode_performance_improvements')) {
					getSortedSetRange(personNonWorkingDays, start, end).forEach(result => {
						if (isWorkingDay(personWorkingMinutes, result)) {
							range.add(result);
						}
					});
				} else {
					getSortedSetRange(personNonWorkingDays, start, end).forEach(result => range.add(result));
				}
			}
		}
	}
	return range;
};

export const getNonWorkingDaysDistribution = (start, end, nonWorkingDaysMap, personId) => {
	const nonWorkingDaysDistribution = [0, 0, 0, 0, 0, 0, 0];

	if (nonWorkingDaysMap && personId && !hasFeatureFlag('inverted_pto_non_working_days')) {
		const range = getNonWorkingDays(start, end, nonWorkingDaysMap, personId);

		for (let dayEntry of range) {
			if (dayEntry >= start && dayEntry <= end) {
				const weekIndex = getWeekDayIndexFromCanvasDate(dayEntry);
				nonWorkingDaysDistribution[weekIndex]++;
			}
		}
	}

	return nonWorkingDaysDistribution;
};

export const getNonWorkingDaysCount = (start, end, nonWorkingDaysMap, personId, personWorkingMinutes) => {
	return getNonWorkingDays(start, end, nonWorkingDaysMap, personId, personWorkingMinutes).size;
};

const calculateForPersonAllocation = (personDatesSet, person, allocation, todayDate) => {
	const canvasStartDate = createCanvasTimelineDate(allocation.startYear, allocation.startMonth, allocation.startDay);
	const canvasEndDate = createCanvasTimelineDate(allocation.endYear, allocation.endMonth, allocation.endDay);

	// Time Off Allocation Day Loop
	for (let day = canvasStartDate; day <= canvasEndDate; day++) {
		if (todayDate === null || day >= todayDate) {
			let weekDayIndex = getWeekDayIndexFromCanvasDate(day);
			const dayName = DAY_NAMES[weekDayIndex];
			const workingMinutesOnDay = person[dayName];
			const allocationMinutesOnDay = allocation[dayName];

			const dayOff = allocationMinutesOnDay > 0 && allocationMinutesOnDay >= workingMinutesOnDay;
			if (dayOff) {
				personDatesSet.add(day);
			}
		}
	}
};

const calculateForPerson = (pageComponent, person, data, todayDate) => {
	const {schedulingOptions, staffingModeActive} = pageComponent.state;

	const personDatesSet = new SortedSet();
	const {company, holidayCalendarEntriesByCalendar} = data;
	const inCombinationActualMode =
		getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION) &&
		getCombinedTaskMode(company) === VISUALIZATION_MODE.TASK_ACTUAL;
	const inActualMode =
		getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.TASK_ACTUAL) || inCombinationActualMode;
	const holidayCalendar = getHolidayCalendar(person, data);
	const calendarEntries = holidayCalendar ? holidayCalendarEntriesByCalendar[holidayCalendar.id] : [];

	// Calendar Entries Loop
	if (calendarEntries) {
		for (let calendarEntryIndex = 0; calendarEntryIndex < calendarEntries.length; calendarEntryIndex++) {
			const calEntry = calendarEntries[calendarEntryIndex];
			const day = createCanvasTimelineDate(calEntry.year, calEntry.month, calEntry.day);
			personDatesSet.add(day);
		}
	}

	// Time Off Allocation Loop
	const calculationTodayDate = inActualMode ? todayDate : null;
	const personAllocations = DataManager.getAllocationsByPersonId(pageComponent, person.id);
	const changedPersonAllocationIds = new Set();

	if (staffingModeActive) {
		const changedAllocations = getChangeListEntities(
			pageComponent,
			STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE
		);

		for (const changedAllocation of changedAllocations) {
			const {personId} = changedAllocation;

			if (personId && personId === person.id && isTimeOffAllocation(changedAllocation)) {
				changedPersonAllocationIds.add(changedAllocation.id);
				calculateForPersonAllocation(personDatesSet, person, changedAllocation, calculationTodayDate);
			}
		}
	}

	if (personAllocations) {
		for (let allocationIndex = 0; allocationIndex < personAllocations.length; allocationIndex++) {
			const allocation = personAllocations[allocationIndex];

			if (isTimeOffAllocation(allocation) && !changedPersonAllocationIds.has(allocation.id)) {
				calculateForPersonAllocation(personDatesSet, person, allocation, calculationTodayDate);
			}
		}
	}

	return personDatesSet;
};

export const calculateNonWorkingDaysMap = (pageComponent, personId) => {
	const start = performance.now();
	const data = pageComponent.getFilterData();
	const {todayDate} = pageComponent.state;
	const nonWorkingDaysMap = data.nonWorkingDaysMap ? data.nonWorkingDaysMap : new Map();
	const person = data.personMap?.get(personId);

	// If a single person was found from personId, only update for that person
	// instead of all persons
	const persons = person ? [person] : data.persons;
	for (let personIndex = 0; personIndex < persons.length; personIndex++) {
		const person = persons[personIndex];
		const personDatesSet = calculateForPerson(pageComponent, person, data, todayDate);
		nonWorkingDaysMap.set(person.id, personDatesSet);
	}

	console.log('done constructing non working days map: ', Math.round(performance.now() - start));
	data.nonWorkingDaysMap = nonWorkingDaysMap;

	if (hasFeatureFlag('combined_mode_performance_improvements')) {
		clearPersonWeekdaysCache(personId);
	}
};

export const isPersonDayOff = (date, personId, nonWorkingDaysMap) => {
	return nonWorkingDaysMap ? nonWorkingDaysMap.get(personId)?.has(date) : false;
};
