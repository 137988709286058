/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type InvoicedPaidChart_viewer$ref: FragmentReference;
declare export opaque type InvoicedPaidChart_viewer$fragmentType: InvoicedPaidChart_viewer$ref;
export type InvoicedPaidChart_viewer = {|
  +company: ?{|
    +currency: ?string
  |},
  +project: ?{|
    +projectFirstDateYear: ?number,
    +projectFirstDateMonth: ?number,
    +projectFirstDateDay: ?number,
    +projectLastDateYear: ?number,
    +projectLastDateMonth: ?number,
    +projectLastDateDay: ?number,
    +rateCard: ?{|
      +currency: ?string
    |},
    +estimationUnit: ?string,
    +budget: ?number,
    +budgetType: ?BUDGET_TYPE,
    +aggregatedFinancialNumbers: $ReadOnlyArray<?string>,
  |},
  +$refType: InvoicedPaidChart_viewer$ref,
|};
export type InvoicedPaidChart_viewer$data = InvoicedPaidChart_viewer;
export type InvoicedPaidChart_viewer$key = {
  +$data?: InvoicedPaidChart_viewer$data,
  +$fragmentRefs: InvoicedPaidChart_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoicedPaidChart_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectFirstDateYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectFirstDateMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectFirstDateDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectLastDateYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectLastDateMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectLastDateDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimationUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budget",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "addAccumulatedNumbers",
              "value": true
            },
            {
              "kind": "Literal",
              "name": "aggregateLevel",
              "value": "DAY"
            },
            {
              "kind": "Literal",
              "name": "aggregates",
              "value": [
                "invoiced",
                "paid"
              ]
            },
            {
              "kind": "Literal",
              "name": "convertToProjectCurrency",
              "value": true
            },
            {
              "kind": "Variable",
              "name": "endDay",
              "variableName": "endDay"
            },
            {
              "kind": "Variable",
              "name": "endMonth",
              "variableName": "endMonth"
            },
            {
              "kind": "Variable",
              "name": "endYear",
              "variableName": "endYear"
            },
            {
              "kind": "Variable",
              "name": "startDay",
              "variableName": "startDay"
            },
            {
              "kind": "Variable",
              "name": "startMonth",
              "variableName": "startMonth"
            },
            {
              "kind": "Variable",
              "name": "startYear",
              "variableName": "startYear"
            }
          ],
          "kind": "ScalarField",
          "name": "aggregatedFinancialNumbers",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ffc9859824c99aa22e7dc450c4967ce';

module.exports = node;
