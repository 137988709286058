import {
	DeprecatedContentContainer as ContentContainer,
	DeprecatedText as Text,
	deprecatedTheme as theme,
	FlexColumn,
} from '@forecast-it/design-system';
import Util from '../../../forecast-app/shared/util/util';
import React from 'react';
import {useIntl} from 'react-intl';

export const ProgramBudgetInfo = ({programBudget, isInFixedPriceProgram, programAllocation, currencySymbol, width}) => {
	const {formatMessage} = useIntl();
	return (
		<ContentContainer width={width} padding={theme.spacing.xs}>
			<FlexColumn gap={'s'}>
				<Text variant={'bold'}>
					{formatMessage({id: isInFixedPriceProgram ? 'program.program_fixed_price' : 'program.program_cap'})}
				</Text>
				<Text>{Util.getFormattedCurrencyValue(currencySymbol, Util.roundToNDecimals(programBudget, 2))}</Text>
				{isInFixedPriceProgram ? (
					<>
						<Text variant={'bold'}>{formatMessage({id: 'program.unallocated_revenue'})}</Text>
						<Text>
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								Util.roundToNDecimals(programBudget - programAllocation, 2)
							)}
						</Text>
					</>
				) : (
					<>
						<Text variant={'bold'}>{formatMessage({id: 'program.confirmed_revenue'})}</Text>
						<Text>
							{Util.getFormattedCurrencyValue(currencySymbol, Util.roundToNDecimals(programAllocation, 2))} (
							{Util.getFormattedCurrencyValue(
								currencySymbol,
								Util.roundToNDecimals(programBudget - programAllocation, 2)
							)}{' '}
							{formatMessage({id: 'common.remaining'}).toLowerCase()})
						</Text>
					</>
				)}
			</FlexColumn>
		</ContentContainer>
	);
};
