import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../../forecast-app/shared/util/util';
import DeletePlaceholderMutation from '../../../mutations/delete_placeholder_mutation';

export const handleDeletePlaceholder = (placeholderId, placeholderAllocations, formatMessage) => {
	const hasAllocations = placeholderAllocations?.length > 0;

	const deletePlaceholder = () => {
		const onSuccess = response => {
			Util.dispatchScheduleEvent(response);

			if (!hasAllocations) {
				createToast({message: formatMessage({id: 'scheduling.placeholder_deleted'}), duration: 5000});
			}
		};

		Util.CommitMutation(
			DeletePlaceholderMutation,
			{
				id: placeholderId,
				trackingOptions: {
					isFromContextMenu: true,
				},
			},
			onSuccess
		);
	};

	if (hasAllocations) {
		showModal({
			type: MODAL_TYPE.DELETE_PLACEHOLDER_CONFIRMATION,
			placeholderId,
			placeholderAllocations,
			isFromContextMenu: true,
		});
	} else {
		deletePlaceholder();
	}
};
