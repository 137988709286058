import Group from '../../canvas-timeline/canvas_timeline_group';
import {
	GROUP_TYPE,
	GROUP_SECTION_WIDTH,
	GROUP_SECTION_TEXT_GREY,
	GROUP_SECTION_SPACING_LEVEL_TWO,
	CURSOR,
	drawBorderLines,
	GROUP_SECTION_SPACING_LEVEL_ONE,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_PADDING_LEFT,
	drawBackground,
	GROUP_SECTION_TEXT_GREY_DARK,
} from '../../canvas-timeline/canvas_timeline_util';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';

class CapacityEntityGroupingGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.CAPACITY_OVERVIEW_ENTITY_GROUPING_GROUP, data);
		this.includeInRowHover = false;
		this.renderRowLines = true;
		this.hideIfAllChildrenAreFiltered = false;
		this.includeInRowHover = false;
		this.subGroupType = data.subGroupType;
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height, expanded, groupType} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);
		const {id, isPeopleRemaining} = data;
		const {intl} = this.pageComponent.props;

		drawBackground(canvasContext, x, y, width, height, true);

		const cacheId = `${groupType}-${id}-${!!expanded}`;
		interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);

		const cachedData = cacheManager.get(groupType, cacheId);
		if (cachedData) {
			canvasContext.drawImage(cachedData, x, y, width, this.itemRowHeight);
			return;
		}

		drawBorderLines(canvasContext, x, y, width, height, true);

		// expand icon
		canvasContext.drawImage(
			cacheManager.getCommonImage(expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON),
			x + GROUP_SECTION_SPACING_LEVEL_ONE,
			y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
			GROUP_SECTION_EXPAND_ICON_WIDTH,
			GROUP_SECTION_EXPAND_ICON_HEIGHT
		);

		// placeholder or person icon
		const subGroupIcon = isPeopleRemaining
			? cacheManager.getCommonImage(COMMON_IMAGE.PROJECT_TEAM_BLACK)
			: cacheManager.getCommonImage(COMMON_IMAGE.PLACEHOLDER_GROUP_ICON);
		const iconHeight = isPeopleRemaining ? 19 : 20;
		const iconWidth = isPeopleRemaining ? 24 : 21;
		canvasContext.drawImage(
			subGroupIcon,
			x + GROUP_SECTION_SPACING_LEVEL_ONE + GROUP_SECTION_PADDING_LEFT + GROUP_SECTION_EXPAND_ICON_WIDTH / 2,
			y + height / 2 - iconHeight / 2,
			iconWidth,
			iconHeight
		);

		// placeholder or people title
		const fontSize = 13;
		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
		canvasContext.font = `600 ${fontSize}px ` + Util.getFontFamily();
		const title = isPeopleRemaining
			? intl.formatMessage({id: 'common.people'})
			: intl.formatMessage({id: 'common.placeholders'});
		canvasContext.fillText(
			title,
			x + GROUP_SECTION_SPACING_LEVEL_TWO + iconWidth + GROUP_SECTION_PADDING_LEFT,
			y + height / 2 + fontSize / 3
		);

		// text
		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY;
		canvasContext.font = '500 9px ' + Util.getFontFamily();
		const text = isPeopleRemaining
			? intl.formatMessage({id: 'scheduling.capacity_overview.people_remaining'})
			: intl.formatMessage({id: 'scheduling.capacity_overview.placeholders_demand'});
		const textWidth = canvasContext.measureText(text).width;
		const textMarginRight = 22;
		const xPos = width - textWidth - textMarginRight;
		canvasContext.fillText(text, xPos, y + height / 2);
	}
}

export default CapacityEntityGroupingGroup;
