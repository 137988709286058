import Util from '../../../forecast-app/shared/util/util';
import {MODULE_TYPES} from '../../../constants';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import DataManager from '../DataManager';
import {
	getProjectGroupIndicatorString,
	getProjectIndicatorString,
} from '../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';

export default class AllocationDetailUtil {
	static getParentTextFromAllocation(pageComponent, allocation) {
		if (allocation.projectGroupId) {
			const projectGroup = DataManager.getProjectGroupById(pageComponent, allocation.projectGroupId);
			return `${getProjectGroupIndicatorString(projectGroup.companyProjectGroupId)} ${projectGroup.name}`;
		} else if (allocation.projectId) {
			const project = DataManager.getProjectById(pageComponent, allocation.projectId);
			return `${getProjectIndicatorString(project.companyProjectId, project.customProjectId)} ${project.name}`;
		} else {
			const idleTime = DataManager.getIdleTimeById(pageComponent, allocation.idleTimeId);
			if (idleTime) {
				return idleTime.name;
			} else {
				// Prevents crash on some strange idle times with no name that should not be possible to create anymore but could potentially still exist somewhere
				return '';
			}
		}
	}

	static getAllocationType(allocation, formatMessage) {
		let type;
		let subType = '';

		const isProject = allocation.projectId || allocation.projectGroupId;
		if (isProject || allocation.placeholderId) {
			type = formatMessage({id: 'common.project'});
		}

		if (allocation.idleTimeId) {
			type = formatMessage({id: allocation.isIdleTimeInternal ? 'common.internal_time' : 'common.time_off'});
		}

		if (hasFeatureFlag('capacity_planning_beta_2_improvements')) {
			if (allocation.placeholderId) {
				subType = ' | ' + formatMessage({id: 'scheduling.placeholder_allocation'});
			} else if (isProject && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)) {
				subType =
					' | ' +
					formatMessage({id: allocation.isSoft ? 'allocation.soft_allocation' : 'allocation.hard_allocation'});
			}
		} else {
			if (allocation.placeholderId) {
				subType = formatMessage({id: 'scheduling.placeholder_allocation'});
			} else if (isProject) {
				subType =
					' | ' +
					formatMessage({id: allocation.isSoft ? 'allocation.soft_allocation' : 'allocation.hard_allocation'});
			}
		}

		return type + subType;
	}

	static getWinProbability(project) {
		return Math.round(project.baselineWinChance * 100) + '%';
	}

	static getAllocationTimeTextWithWinChance(total, formatMessage, winChance, allocation, company, calcWin) {
		if (winChance !== 1 && calcWin) {
			const totalEstimateWinChance = Util.convertMinutesToFullHour(total * winChance, {formatMessage}, true, true);
			const messageWinChance = Util.getAllocationTimeText(
				totalEstimateWinChance,
				allocation,
				company,
				{formatMessage},
				winChance
			).potentialText;

			return messageWinChance;
		} else {
			const totalEstimate = Util.convertMinutesToFullHour(total, {formatMessage}, true, true);
			return Util.getAllocationTimeText(totalEstimate, allocation, company, {formatMessage}, 1).potentialText;
		}
	}

	// TODO should be removed together with capacity_planning_beta_2_improvements feature flag
	static getAllocationTimeTextWithWinChanceOld(total, formatMessage, winChance, allocation, company) {
		if (winChance !== 1) {
			const hourSuffix = formatMessage({id: 'common.hours.short'});
			const totalEstimate = Util.convertMinutesToFullHour(total, {formatMessage}, true, false);
			return formatMessage(
				{id: 'scheduling.minutes_win'},
				{
					minutesWin: Math.round(totalEstimate * winChance) + hourSuffix,
					minutes: totalEstimate + hourSuffix,
				}
			);
		} else {
			const totalEstimate = Util.convertMinutesToFullHour(total, {formatMessage}, true, true);
			return Util.getAllocationTimeText(totalEstimate, allocation, company, {formatMessage}, 1).potentialText;
		}
	}
}
