import React from 'react';

import {ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE} from '../../../constants';
import HeaderBar from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import Util from '../../../forecast-app/shared/util/util';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/FilterWrapper';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {
	getVisualizationMode,
	getVisualizationModeDropdownOptions,
	UTILIZATION_FORMAT,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import {
	onToggleCalcWin,
	onToggleHideHard,
	onToggleHideSoft,
} from '../components/allocation_controls/AllocationControlsCanvasUtils';
import {PEOPLE_SCHEDULING_FILTERS} from './PeopleSchedulingConstants';
import EventManager from '../EventManager';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';

const mapFilterV4ViewerDataToGraphQLType = pageComponent => {
	const {personFilters, projectFilters} = pageComponent.getFilterData();

	return {
		filters: {edges: [...personFilters, ...projectFilters].map(filter => ({node: {...filter}}))},
	};
};
const getTopHeaderContent = pageComponent => {
	const {data} = pageComponent.state;
	const {company} = data || {};

	const content = [];

	if (Util.isMixedAllocationModeEnabled(company)) {
		content.push({
			type: TopHeaderBar.TYPE.FEEDBACK,
			link: 'https://www.forecast.app/feedback-heatmap',
		});
	}

	const onboardingFlows = [
		{
			id: 'schedule-people-introduction',
			title: 'Introduction to the page',
			description: null,
			contentId: '1681812607nSug8709',
		},
		{
			id: 'schedule-people-assign-work',
			title: 'How to assign work to people',
			description: null,
			contentId: '1681817077hSxj5031',
		},
		{
			id: 'schedule-people-filters',
			title: 'How to use the filters on this page',
			description: null,
			contentId: '1681817696cKpe7092',
		},
	];
	const onboardingComponent = {
		id: 'onboarding-component',
		type: TopHeaderBar.TYPE.ONBOARDING,
		title: 'Learn to use People Schedule',
		options: onboardingFlows,
		helpCenterLink: 'https://support.forecast.app/hc/en-us/sections/4461883938449-Managing-Resources',
		subLink:
			'https://support.forecast.app/hc/en-us/articles/4775562212753-Reviewing-your-resource-heatmap-Schedule-People-',
	};
	content.push(onboardingComponent);
	return content;
};

export const GROUP_BY = {
	NONE: 'none',
	ROLE: 'role',
	DEPARTMENT: 'department',
};

export const createTopHeader = pageComponent => {
	const title = 'People Schedule';

	return (
		pageComponent.viewCompanySchedule && (
			<>
				<TopHeaderBarWrapper sidePadding={24} bottomPadding={0}>
					<TopHeaderBar title={title} content={getTopHeaderContent(pageComponent)} />
				</TopHeaderBarWrapper>
			</>
		)
	);
};

export const createHeader = pageComponent => {
	const {eyeOptions, schedulingOptions, data} = pageComponent.state;
	const intl = pageComponent.props.intl;

	const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.ALLOCATION);
	const isUsingCombinationMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);

	const leftContent = [],
		rightContent = [];

	leftContent.push({
		type: ELEMENT_TYPE.EMPTY_SPACE,
		width: 14,
	});

	if (Util.isMixedAllocationModeEnabled(pageComponent.props.viewer.company)) {
		// visualization mode selector
		leftContent.push({
			type: ELEMENT_TYPE.DROPDOWN,
			dropdownOptions: getVisualizationModeDropdownOptions(data.company, intl.formatMessage),
			value: pageComponent.state.schedulingOptions.visualizationMode,
			callback: mode => EventManager.onVisualizationModeChange(pageComponent, mode.value),
			cy: 'visualization-mode-toggle',
		});
	}

	const utilizationFormatDropdownOptions = [
		{value: UTILIZATION_FORMAT.HOURS, label: intl.formatMessage({id: 'scheduling.heatmap_in_hours'})},
		{value: UTILIZATION_FORMAT.PERCENTAGE, label: intl.formatMessage({id: 'scheduling.heatmap_in_percentage'})},
	];

	leftContent.push({
		type: ELEMENT_TYPE.DROPDOWN,
		dropdownOptions: utilizationFormatDropdownOptions,
		value: pageComponent.state.schedulingOptions.utilizationFormat,
		callback: format => EventManager.onUtilizationFormatChange(pageComponent, format.value),
		cy: 'utilization-resource-format-changer',
	});

	leftContent.push({type: ELEMENT_TYPE.EXPAND_ALL_CANVAS, cy: 'expand-all-button'});

	leftContent.push({
		type: ELEMENT_TYPE.DATE_CHANGER,
		onlyTodayButton: true,
		handleTodayButtonClick: () => EventManager.onScrollToToday(pageComponent),
		handleMoveDateButtonClick: moveForward => EventManager.onMoveDateButtonClick(pageComponent, moveForward),
		userpilot: 'schedule-date-changer',
		cy: 'date-changer',
	});

	leftContent.push({
		type: ELEMENT_TYPE.ZOOM_MENU,
	});

	rightContent.push({
		type: ELEMENT_TYPE.SEARCH,
		value: pageComponent.state.searchFilterValue || '',
		onChange: event => EventManager.onSearchChange(pageComponent, event),
		cy: 'canvas-search',
	});

	const projectFilters = [
		FILTER_TYPE.PROJECT,
		FILTER_TYPE.CLIENT,
		FILTER_TYPE.PERSON,
		FILTER_TYPE.PROJECT_STAGE,
		FILTER_TYPE.PROJECT_STATUS,
		FILTER_TYPE.PRIORITY_LEVEL,
		FILTER_TYPE.CONTACT,
		FILTER_TYPE.LABEL,
		FILTER_TYPE.PROJECT_WIN_CHANCE,
	];
	const taskFilters = !isUsingProjectAllocation
		? [
				FILTER_TYPE.ROLE,
				FILTER_TYPE.STATUS_CATEGORY,
				FILTER_TYPE.INDICATOR,
				FILTER_TYPE.OWNER,
				FILTER_TYPE.PROJECT_FOLLOWER,
				FILTER_TYPE.RECENT_ACTIVITY,
				FILTER_TYPE.LABEL,
		  ]
		: undefined;

	const peopleFilters = pageComponent.viewCompanySchedule
		? [
				FILTER_TYPE.PROJECT,
				FILTER_TYPE.PERSON,
				FILTER_TYPE.TEAM,
				FILTER_TYPE.ROLE,
				FILTER_TYPE.CAPACITY_WORKLOAD,
				FILTER_TYPE.LABEL,
		  ]
		: undefined;

	if (peopleFilters && Util.hasSkills()) {
		peopleFilters.push(FILTER_TYPE.SKILL);
	}

	const hasFinancialModule = CompanySetupUtil.hasFinance();

	if (hasFinancialModule && projectFilters) {
		projectFilters.splice(1, 0, FILTER_TYPE.PROJECT_TYPE);
	}

	if (peopleFilters && Util.hasDepartments()) {
		peopleFilters.push(FILTER_TYPE.DEPARTMENT);
	}

	const viewer = mapFilterV4ViewerDataToGraphQLType(pageComponent);
	// Map filter options from V3 -> V4
	if (viewer.filters && viewer.filters.edges) {
		viewer.filters.edges
			.map(edge => edge.node)
			.filter(filter => filter.section === 'SCHEDULING_PEOPLE')
			.forEach(filter => {
				const filterValue = JSON.parse(filter.value);
				if (filterValue && filterValue.person && filterValue.person.team) {
					filterValue.person.team = filterValue.person.team.map(teamId => {
						if (typeof teamId === 'number') {
							return btoa(`Team:${teamId}`);
						} else {
							return teamId;
						}
					});
					filter.value = JSON.stringify(filterValue);
				}
			});
	}

	if (projectFilters) {
		projectFilters.push(FILTER_TYPE.INTERNAL_TIME);
		projectFilters.push(FILTER_TYPE.TIME_OFF);
	}
	if (peopleFilters) {
		peopleFilters.push(FILTER_TYPE.INTERNAL_TIME);
		peopleFilters.push(FILTER_TYPE.TIME_OFF);
	}

	if (projectFilters || peopleFilters || taskFilters) {
		const preselectedSection = pageComponent.viewCompanySchedule
			? FILTER_SECTIONS.PEOPLE
			: taskFilters
			? FILTER_SECTIONS.TASKS
			: FILTER_SECTIONS.PROJECTS;

		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: preselectedSection,
			projectFilters,
			peopleFilters,
			taskFilters,
			viewer,
			primaryFilters: {
				[FILTER_SECTIONS.PEOPLE]: [
					FILTER_TYPE.ROLE,
					FILTER_TYPE.TEAM,
					FILTER_TYPE.DEPARTMENT,
					FILTER_TYPE.PROJECT,
					FILTER_TYPE.SKILL,
				],
				[FILTER_SECTIONS.PROJECTS]: [
					FILTER_TYPE.PROJECT_STAGE,
					FILTER_TYPE.LABEL,
					FILTER_TYPE.CLIENT,
					FILTER_TYPE.PROJECT,
					FILTER_TYPE.TIME_OFF,
					FILTER_TYPE.PERSON,
					FILTER_TYPE.PROJECT_TYPE,
					FILTER_TYPE.CONTACT,
				],
				[FILTER_SECTIONS.TASKS]: [FILTER_TYPE.STATUS_CATEGORY, FILTER_TYPE.LABEL, FILTER_TYPE.OWNER],
			},
			appliedFiltersName: PEOPLE_SCHEDULING_FILTERS,
			filterSection: FILTER_SECTION.SCHEDULING_PEOPLE,
			onFiltersChange: (filterValues, filterFunctions) =>
				EventManager.onFilterChange(pageComponent, filterValues, filterFunctions),
			projectId: pageComponent.state.viewer.project ? pageComponent.state.viewer.project.id : null,
			projectGroupId: pageComponent.state.viewer.projectGroup ? pageComponent.state.viewer.projectGroup.id : null,
			userpilot: 'filter-button',
			cy: 'people-schedule-filters',
		});
	}

	if (isUsingCombinationMode || isUsingProjectAllocation) {
		rightContent.push({
			type: ELEMENT_TYPE.WIN_PROBABILITY,
			calcWin: pageComponent.state.schedulingOptions.calcWin,
			onToggleCalcWin: checked => onToggleCalcWin(pageComponent, checked),
			cy: 'canvas-win-probability',
		});

		rightContent.push({
			type: ELEMENT_TYPE.ALLOCATION_CONTROLS,
			hideSoft: pageComponent.state.schedulingOptions.hideSoft,
			onToggleHideSoft: checked => onToggleHideSoft(pageComponent, checked),
			hideHard: pageComponent.state.schedulingOptions.hideHard,
			onToggleHideHard: checked => onToggleHideHard(pageComponent, checked),
			cy: 'canvas-allocation-controls',
		});
	}

	const dropdownOptions = [
		{value: GROUP_BY.NONE, label: intl.formatMessage({id: 'common.no_grouping'})},
		{value: GROUP_BY.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
	];
	if (Util.hasDepartments() && data.departments && data.departments.length > 0) {
		dropdownOptions.push({
			value: GROUP_BY.DEPARTMENT,
			label: intl.formatMessage({id: 'common.group_by_department'}),
		});
	}
	if (pageComponent.viewCompanySchedule) {
		rightContent.push({
			type: ELEMENT_TYPE.DROPDOWN,
			dropdownOptions,
			value: pageComponent.state.groupBy,
			callback: group => EventManager.onGroupByChange(pageComponent, group.value),
			dataCy: 'people-schedule-group-by-dropdown',
		});
	}

	if (eyeOptions?.length > 0) {
		rightContent.push({
			type: ELEMENT_TYPE.THE_EYE,
			options: eyeOptions,
			onSelect: selected => EventManager.onEyeOptionsChange(pageComponent, selected),
			expandLeft: true,
			disableTooltip: true,
		});
	}

	return <HeaderBar scheduling={true} noPadding={true} leftContent={leftContent} rightContent={rightContent} />;
};
