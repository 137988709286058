import {
	BUDGET_MONEY_VIEWS,
	BUDGET_TYPE,
	BUDGET_VIEWS,
	BUTTON_COLOR,
	BUTTON_STYLE,
	ELEMENT_TYPE,
	PROJECT_STATUS,
} from '../../../../constants';
import * as tracking from '../../../../tracking';
import HeaderBar from '../../../../forecast-app/shared/components/headers/header-bar/header_bar';
import React from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import Util from '../../../../forecast-app/shared/util/util';
import {useEyeOptions} from '../../../../forecast-app/shared/hooks/useEyeOptions';
import {getEditFixedPriceButton} from './EditFixedPriceButton';
import {getBudgetEyeOptions} from './BudgetPage.EyeOptions';
import {BudgetHeaderWrapper} from './BudgetPageHeader.styled';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import CompanySetupUtil from '../../../../forecast-app/shared/util/CompanySetupUtil';
import {hasPermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';

const BudgetPageHeader = ({
	project,
	company,
	hasFinancials,
	onlyShowTime,
	hasBaseline,
	onAggregateLevelChange,
	selectedAggregateLevel,
	onValueTypeChange,
	selectedValueType,
	onViewTypeChange,
	selectedViewType,
	setEyeOptionMap,
	setShowFixedPriceContainer,
	setShowFixedPriceUpdateToast,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const hasNoTimeRegFeature = ProjectUtil.projectUsesManualProgress(project);
	const isDoneOrHalted = project.status === PROJECT_STATUS.DONE || project.status === PROJECT_STATUS.HALTED;
	const leftContent = [],
		rightContent = [];

	const getTimeAggregateDropdown = ({
		projectFirstDateYear,
		projectFirstDateMonth,
		projectFirstDateDay,
		projectLastDateYear,
		projectLastDateMonth,
		projectLastDateDay,
	}) => {
		const projectFirstDate = Util.CreateNonUtcMomentDate(projectFirstDateYear, projectFirstDateMonth, projectFirstDateDay);
		const projectLastDate = Util.CreateNonUtcMomentDate(projectLastDateYear, projectLastDateMonth, projectLastDateDay);

		const projectPeriodLengthInDays =
			projectFirstDate && projectLastDate ? projectLastDate.diff(projectFirstDate, 'days') : 180;
		const projectPeriodLengthInMonths =
			projectFirstDate && projectLastDate ? projectLastDate.diff(projectFirstDate, 'months') : 12;
		const defaultPeriod = projectPeriodLengthInDays < 31 ? 'DAY' : 'MONTH';

		const defaultText = formatMessage({id: 'settings.rate_card_default'});
		const defaultSuffix = ` (${defaultText.charAt(0).toLowerCase()}${defaultText.slice(1)})`;
		const periodOptions = [
			...(projectPeriodLengthInMonths < 18
				? [
						{
							value: 'DAY',
							label: `${formatMessage({id: `common.days`})}${'DAY' === defaultPeriod ? defaultSuffix : ''}`,
						},
				  ]
				: []),
			{
				value: 'MONTH',
				label: `${formatMessage({id: `common.months`})}${'MONTH' === defaultPeriod ? defaultSuffix : ''}`,
			},
		];

		return {
			type: ELEMENT_TYPE.LEFT_DROPDOWN,
			dropdownOptions: periodOptions,
			value: selectedAggregateLevel,
			callback: onAggregateLevelChange,
			placeholder: formatMessage({id: 'project_budget.select_period'}),
			userpilot: 'budget-period-dropdown',
			dataCy: 'budget-period-selector',
			listDataCy: 'budget-period-option',
		};
	};

	const moneyButton = {
		text: formatMessage({id: 'common.money'}),
		onClick: () => {
			onValueTypeChange(BUDGET_VIEWS.MONEY);
			tracking.trackElementClicked('Money Tab');
			trackEvent('Money Tab', 'Clicked');
		},
		selected: selectedValueType === BUDGET_VIEWS.MONEY,
		dataCy: 'money',
		userpilot: 'money-tab',
	};

	const timeButton = {
		text: formatMessage({id: 'project-economic.th_time'}),
		onClick: () => {
			onValueTypeChange(BUDGET_VIEWS.TIME);
			tracking.trackElementClicked('Time Tab');
			trackEvent('Time Tab', 'Clicked');
		},
		selected: selectedValueType === BUDGET_VIEWS.TIME,
		dataCy: 'time',
		userpilot: 'time-tab',
	};

	leftContent.push(getTimeAggregateDropdown(project));

	if (!hasNoTimeRegFeature) {
		if (onlyShowTime) {
			leftContent.push({
				type: ELEMENT_TYPE.UNIT_TOGGLE,
				options: [timeButton],
			});
		} else if (hasFinancials || CompanySetupUtil.hasFinanceLight()) {
			leftContent.push({
				type: ELEMENT_TYPE.UNIT_TOGGLE,
				options: [moneyButton, timeButton],
			});
		} else {
			leftContent.push({
				type: ELEMENT_TYPE.UNIT_TOGGLE,
				options: [timeButton, {...moneyButton, onClick: () => null}],
			});
		}
	}

	if (selectedValueType === BUDGET_VIEWS.MONEY) {
		const options = [
			{
				text: formatMessage({
					id: 'project_budget.time_and_expenses',
				}),
				onClick: () => {
					onViewTypeChange(BUDGET_MONEY_VIEWS.WORK_AND_EXPENSES);
					tracking.trackElementClicked('work-and-expenses');
					trackEvent('Work And Expenses', 'Clicked');
				},
				selected: selectedViewType === BUDGET_MONEY_VIEWS.WORK_AND_EXPENSES,
				dataCy: 'work-and-expenses',
				userpilot: 'work-and-expenses',
			},
			ProjectUtil.projectHasExpenses(project.budgetType)
				? {
						text: formatMessage({id: 'project_budget.expense_details'}),
						onClick: () => {
							onViewTypeChange(BUDGET_MONEY_VIEWS.EXPENSES);
							tracking.trackElementClicked('expense-details-tab');
							trackEvent('Expense Details Tab', 'Clicked');
						},
						selected: selectedViewType === BUDGET_MONEY_VIEWS.EXPENSES,
						dataCy: 'expense-details-tab',
						userpilot: 'expense-details-tab',
				  }
				: null,
			ProjectUtil.projectHasInvoicing(project.budgetType) && hasPermission(PERMISSION_TYPE.INVOICE_READ)
				? {
						text: formatMessage({id: 'project_budget.invoice_plan'}),
						onClick: () => {
							onViewTypeChange(BUDGET_MONEY_VIEWS.INVOICE_PLAN);
							tracking.trackElementClicked('invoice-plan-tab');
							trackEvent('Invoice Plan Tab', 'Clicked');
						},
						selected: selectedViewType === BUDGET_MONEY_VIEWS.INVOICE_PLAN,
						dataCy: 'invoice-plan-tab',
						userpilot: 'invoice-plan-tab',
				  }
				: null,
		].filter(Boolean);

		if (options && options.length > 1) {
			leftContent.push({
				type: ELEMENT_TYPE.UNIT_TOGGLE,
				options,
			});
		}
	}

	if (project.budgetType === BUDGET_TYPE.FIXED_PRICE) {
		const showUpdateFixedPriceModal = () => {
			showModal({
				type: MODAL_TYPE.UPDATE_FIXED_PRICE,
				project,
				callback: function () {
					setShowFixedPriceUpdateToast(true);
				},
			});

			tracking.trackElementClicked('Update Fix Price button');
			trackEvent('Update Fixed Price Button', 'Clicked');
		};

		rightContent.push({
			type: ELEMENT_TYPE.BUTTON,
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
			text: formatMessage({id: 'project_budget.update_fixed_price'}),
			disabled: isDoneOrHalted,
			callback: showUpdateFixedPriceModal,
			dataCy: 'button-update-fixed-price',
		});
	}
	const isInvoicePlan = selectedValueType === BUDGET_VIEWS.MONEY && selectedViewType === BUDGET_MONEY_VIEWS.INVOICE_PLAN;
	const eyeOptions = getBudgetEyeOptions(hasBaseline, project, isInvoicePlan);
	const [eyeOptionHeaderElement] = useEyeOptions(
		eyeOptions,
		'project_budget_options-v0.1',
		'project_budget.',
		setEyeOptionMap
	);
	eyeOptionHeaderElement.options = eyeOptions;

	const editFixedPriceHeaderElement =
		project.budgetType === BUDGET_TYPE.FIXED_PRICE
			? getEditFixedPriceButton(project, company, setShowFixedPriceContainer)
			: null;
	if (editFixedPriceHeaderElement) {
		rightContent.push(editFixedPriceHeaderElement);
	}

	rightContent.push({...eyeOptionHeaderElement, expandLeft: true});

	return (
		<>
			<BudgetHeaderWrapper>
				<HeaderBar leftContent={leftContent} rightContent={rightContent} noPadding />
			</BudgetHeaderWrapper>
		</>
	);
};
// removed forecastBillablePriceScopeBased from here - currently we do not show the update fixed price modal anyway. If / when we need
// that modal back, we should not use forecastBillablePriceScopeBased but rather a new field (that marius added for revenue recognition)
export default createFragmentContainer(BudgetPageHeader, {
	project: graphql`
		fragment BudgetPageHeader_project on ProjectType {
			id
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
			budgetType
			budget
			defaultPeriodBudgetType
			rateCard {
				currency
			}
			estimationUnit
			billable
			manualProgressOnProjectEnabled
			manualProgressOnPhasesEnabled
			manualProgressOnTasksEnabled
		}
	`,
	company: graphql`
		fragment BudgetPageHeader_company on Company {
			id
			tier
			currency # used in EditFixedPrice
			sageIntacctConnectionEnabled
		}
	`,
});
