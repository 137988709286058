/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FixedPriceContainerLoader_viewer$ref = any;
export type FixedPriceContainerLoader_QueryVariables = {|
  projectId: string
|};
export type FixedPriceContainerLoader_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: FixedPriceContainerLoader_viewer$ref,
  |}
|};
export type FixedPriceContainerLoader_Query = {|
  variables: FixedPriceContainerLoader_QueryVariables,
  response: FixedPriceContainerLoader_QueryResponse,
|};
*/


/*
query FixedPriceContainerLoader_Query(
  $projectId: ID!
) {
  viewer {
    actualPersonId
    component(name: "fixed_price_container_loader")
    ...FixedPriceContainerLoader_viewer_2CgS8h
    id
  }
}

fragment FixedPriceContainerLoader_viewer_2CgS8h on Viewer {
  project(internalId: $projectId) {
    id
    companyProjectId
    budgetType
    budget
    originalForecastBillableHoursPrice
    forecastBillableHoursPrice
    expenseFinancialNumbers: financialNumbers(searchQuery: {filters: [{field: ROLE, operator: IS, value: "Um9sZTotMQ=="}]}, convertToProjectCurrency: true) {
      billablePlannedTimeAndExpenses
    }
    rateCard {
      id
      currency
      parentRateCardId
      rates(first: 10000) {
        edges {
          node {
            id
            rate
            defaultRate
            startDate
            role {
              id
            }
          }
        }
      }
    }
  }
  company {
    id
    currency
    rateCards(first: 10000) {
      edges {
        node {
          id
          currency
          rates(first: 10000) {
            edges {
              node {
                id
                rate
                defaultRate
                startDate
                role {
                  id
                }
              }
            }
          }
        }
      }
    }
    roles(first: 10000) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "fixed_price_container_loader"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"fixed_price_container_loader\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v6 = {
  "alias": null,
  "args": (v5/*: any*/),
  "concreteType": "RateTypeConnection",
  "kind": "LinkedField",
  "name": "rates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RateTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Rate",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "defaultRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "role",
              "plural": false,
              "selections": [
                (v3/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "rates(first:10000)"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FixedPriceContainerLoader_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "FixedPriceContainerLoader_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FixedPriceContainerLoader_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "companyProjectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budgetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budget",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "originalForecastBillableHoursPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "forecastBillableHoursPrice",
                "storageKey": null
              },
              {
                "alias": "expenseFinancialNumbers",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "convertToProjectCurrency",
                    "value": true
                  },
                  {
                    "kind": "Literal",
                    "name": "searchQuery",
                    "value": {
                      "filters": [
                        {
                          "field": "ROLE",
                          "operator": "IS",
                          "value": "Um9sZTotMQ=="
                        }
                      ]
                    }
                  }
                ],
                "concreteType": "FinancialNumbers",
                "kind": "LinkedField",
                "name": "financialNumbers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billablePlannedTimeAndExpenses",
                    "storageKey": null
                  }
                ],
                "storageKey": "financialNumbers(convertToProjectCurrency:true,searchQuery:{\"filters\":[{\"field\":\"ROLE\",\"operator\":\"IS\",\"value\":\"Um9sZTotMQ==\"}]})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RateCard",
                "kind": "LinkedField",
                "name": "rateCard",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parentRateCardId",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "RateCardTypeConnection",
                "kind": "LinkedField",
                "name": "rateCards",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RateCardTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RateCard",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "rateCards(first:10000)"
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "RoleTypeConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "roles(first:10000)"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "FixedPriceContainerLoader_Query",
    "operationKind": "query",
    "text": "query FixedPriceContainerLoader_Query(\n  $projectId: ID!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"fixed_price_container_loader\")\n    ...FixedPriceContainerLoader_viewer_2CgS8h\n    id\n  }\n}\n\nfragment FixedPriceContainerLoader_viewer_2CgS8h on Viewer {\n  project(internalId: $projectId) {\n    id\n    companyProjectId\n    budgetType\n    budget\n    originalForecastBillableHoursPrice\n    forecastBillableHoursPrice\n    expenseFinancialNumbers: financialNumbers(searchQuery: {filters: [{field: ROLE, operator: IS, value: \"Um9sZTotMQ==\"}]}, convertToProjectCurrency: true) {\n      billablePlannedTimeAndExpenses\n    }\n    rateCard {\n      id\n      currency\n      parentRateCardId\n      rates(first: 10000) {\n        edges {\n          node {\n            id\n            rate\n            defaultRate\n            startDate\n            role {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n  company {\n    id\n    currency\n    rateCards(first: 10000) {\n      edges {\n        node {\n          id\n          currency\n          rates(first: 10000) {\n            edges {\n              node {\n                id\n                rate\n                defaultRate\n                startDate\n                role {\n                  id\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    roles(first: 10000) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '38b8ec169092ccbb756cd3a47c0c1170';

module.exports = node;
