import Item from '../../canvas-timeline/canvas_timeline_item';
import {ITEM_TYPE, drawRectangle} from '../../canvas-timeline/canvas_timeline_util';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import Util from '../../../../forecast-app/shared/util/util';
class UnassignedHeatmapItem extends Item {
	constructor(pageComponent, data) {
		super(pageComponent, ITEM_TYPE.PEOPLE_SCHEDULING_UNASSIGNED_HEATMAP, data);
	}

	onMouseEnter() {
		const {intl} = this.pageComponent.props;
		const {formatMessage} = intl;

		this.pageComponent.setState({
			showCanvasTooltip: true,
			canvasTooltipData: {
				maxWidth: 700,
				title: formatMessage({id: 'scheduling.tooltip.title.unassigned_task_heatmap'}),
				description: formatMessage({id: 'scheduling.tooltip.sub_title.unassigned_task_heatmap'}),
				items: [
					{
						equationItems: [
							{term: formatMessage({id: 'scheduling.tooltip.term.unassigned_task_heatmap_remaining'})},
							{text: '/'},
							{term: formatMessage({id: 'scheduling.tooltip.term.unassigned_task_heatmap_working_hours'})},
						],
					},
				],
			},
		});
	}

	onMouseLeave() {
		this.pageComponent.setState({
			showCanvasTooltip: false,
			canvasTooltipData: null,
		});
	}

	draw(canvasContext) {
		const {data, height} = this;
		const {x, y, width, requiredPersonCount, hoursAllocatedText} = data;

		if (!requiredPersonCount) return; //Don't draw anything if heatmap is empty
		drawRectangle(canvasContext, x, y, width - 1, height, {
			backgroundColor: '#a56dfd',
		});
		//Draw hours text
		canvasContext.fillStyle = '#ffffff';
		canvasContext.font = '700 13px ' + Util.getFontFamily();
		let textWidth = canvasContext.measureText(hoursAllocatedText).width;
		canvasContext.fillText(hoursAllocatedText, x + (width - textWidth) / 2, y + 28);
		//Draw required person count text
		canvasContext.font = '500 10px ' + Util.getFontFamily();
		textWidth = canvasContext.measureText(requiredPersonCount).width;
		//Don't show person icon if there is no room for it
		if (width - textWidth > 70) {
			canvasContext.fillText(requiredPersonCount, x + width - 28 + (28 - textWidth) / 2, y + 34);
			//Draw required person count icon
			canvasContext.drawImage(
				cacheManager.getCommonImage(COMMON_IMAGE.UNASSIGNED_HEATMAP_PERSON),
				x + width - 18,
				y + 12
			);
		}
	}
}
export default UnassignedHeatmapItem;
