import React from 'react';
import Styled from 'styled-components';
import {Checkbox} from 'web-components';
import {CSS_CONSTANTS} from '../../../css_variables';

const Label = Styled.label`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 12px;
	gap: 8px;
	background-color: ${CSS_CONSTANTS.background_ghost_white};
	border-radius: 4px;
	cursor: pointer;
	font-weight: 400;
	font-size 13px;
	color: #393946;
	
	&.checked {
		background-color: ${CSS_CONSTANTS.color_brand_50};
	}
`;

const RightLabel = Styled.div`
	margin-left: auto;
`;

const CheckboxRow = ({checked, highlightChecked = false, onChange, disabled, label, rightLabel = undefined, cy}) => {
	return (
		<Label data-cy={cy} className={checked && highlightChecked ? ' checked' : ''}>
			<Checkbox checked={checked} cy={'allocate-modal-soft'} onChange={() => onChange(!checked)} disabled={disabled} />
			{label}
			<RightLabel>{rightLabel}</RightLabel>
		</Label>
	);
};

export default CheckboxRow;
