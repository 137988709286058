/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type cummulativeFlowDiagramComponent_viewer$ref: FragmentReference;
declare export opaque type cummulativeFlowDiagramComponent_viewer$fragmentType: cummulativeFlowDiagramComponent_viewer$ref;
export type cummulativeFlowDiagramComponent_viewer = {|
  +insightComponentsData: ?{|
    +tasksFlow: ?{|
      +useHours: ?boolean,
      +data: ?$ReadOnlyArray<?{|
        +dateString: ?string,
        +columnsData: ?$ReadOnlyArray<?{|
          +id: string,
          +statusColumnId: string,
          +name: ?string,
          +count: ?number,
          +sortOrder: ?number,
          +forecastSum: ?number,
        |}>,
      |}>,
    |}
  |},
  +$refType: cummulativeFlowDiagramComponent_viewer$ref,
|};
export type cummulativeFlowDiagramComponent_viewer$data = cummulativeFlowDiagramComponent_viewer;
export type cummulativeFlowDiagramComponent_viewer$key = {
  +$data?: cummulativeFlowDiagramComponent_viewer$data,
  +$fragmentRefs: cummulativeFlowDiagramComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProjectGroupType",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "cummulativeFlowDiagramComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "isProjectGroupType",
              "variableName": "isProjectGroupType"
            },
            {
              "kind": "Variable",
              "name": "projectId",
              "variableName": "projectId"
            }
          ],
          "concreteType": "TasksFlow",
          "kind": "LinkedField",
          "name": "tasksFlow",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "useHours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TasksFlowElement",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dateString",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TasksFlowStatusColumn",
                  "kind": "LinkedField",
                  "name": "columnsData",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "statusColumnId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "count",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sortOrder",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecastSum",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = 'e87ec5445acc9413f9bd48eed33be5f0';

module.exports = node;
