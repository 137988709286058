import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

class DatePickerButton extends Component {
	handleOnClick = e => {
		e.stopPropagation();
		this.props.onClick(e);
	};

	render() {
		const {
			startDate,
			endDate,
			isDateRangePicker,
			locked,
			onBlur,
			showOnlyEndDate,
			customPlaceholder,
			customDateFormat,
			customClasses,
			showOnlyIcon,
		} = this.props;
		const {formatMessage, formatDate} = this.props.intl;
		const useTextFormat = this.props.useTextFormat;
		return (
			<div className="date-picker-button-container">
				{this.props.label ? <label>{this.props.label.toUpperCase()}</label> : null}
				<button
					id={this.props.id}
					data-cy={this.props.cy}
					className={
						'date-picker-button' +
						(locked ? ' locked' : '') +
						(isDateRangePicker ? ' date-range' : '') +
						(this.props.showAsText ? ' text-version' : '') +
						(this.props.hideButtonIcon ? ' hide-button-icon' : '') +
						(customClasses ? ` ${customClasses}` : '') +
						(showOnlyIcon ? ' icon-version' : '')
					}
					onMouseDown={this.handleOnClick.bind(this)}
					disabled={locked}
					onBlur={onBlur}
				>
					{showOnlyIcon ? null : startDate ? (
						customDateFormat ? (
							customPlaceholder ? (
								<span className="placeholder">{customPlaceholder}</span> ||
								formatMessage({id: 'card_modal.due_date_not_set'})
							) : (
								startDate.format(customDateFormat)
							)
						) : this.props.boldLongDayMonth ? (
							<span className="day-month">
								{this.props.hideDay ? startDate.format('MMM') : startDate.format('ddd, DD. MMM')}
								<span className="year">{startDate.format('YYYY')}</span>
							</span>
						) : this.props.boldDayMonth ? (
							<span className="day-month">
								{this.props.hideDay ? startDate.format('MMM') : startDate.format('DD. MMM')}
								<span className="year">{startDate.format('YYYY')}</span>
							</span>
						) : this.props.newWay ? (
							<span className="day-month">
								{this.props.hideDay ? startDate.format('MMM') : startDate.format('DD. MMM')}
								<span className="year">{startDate.format('YYYY')}</span>
							</span>
						) : useTextFormat ? (
							this.props.hideDay ? (
								formatDate(startDate, {
									year: 'numeric',
									month: 'long',
								})
							) : (
								formatDate(startDate, {
									weekday: 'short',
									year: 'numeric',
									month: 'long',
									day: 'numeric',
								})
							)
						) : (
							formatDate(startDate, {
								year: '2-digit',
								month: 'numeric',
								day: 'numeric',
							})
						)
					) : showOnlyEndDate ? null : (
						<span className="placeholder">{customPlaceholder}</span> ||
						formatMessage({id: 'card_modal.due_date_not_set'})
					)}
					{isDateRangePicker && startDate && endDate ? ' - ' : null}
					{(isDateRangePicker && startDate && endDate) || (showOnlyEndDate && endDate)
						? formatDate(endDate, {
								year: '2-digit',
								month: 'numeric',
								day: 'numeric',
						  })
						: null}
					{this.props.showWeekNumber ? (
						<span className={'week-number-text'}>
							{' (' + formatMessage({id: 'timeline.week'}) + ' ' + startDate.week() + ')'}
						</span>
					) : null}
				</button>
			</div>
		);
	}
}
DatePickerButton.propTypes = {
	startDate: PropTypes.object,
	endDate: PropTypes.object,
	onClick: PropTypes.func.isRequired,
	locked: PropTypes.bool,
	onBlur: PropTypes.func,
	isDateRangePicker: PropTypes.bool,
	label: PropTypes.string,
	first: PropTypes.bool,
	active: PropTypes.bool,
	cy: PropTypes.string,
	dateFormat: PropTypes.string,
};

export default injectIntl(DatePickerButton);
