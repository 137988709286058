import React, {Component} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import Moment from 'moment';
import {injectIntl} from 'react-intl';
import InsightsUtil from '../insights_util';
import Person from '../../../forecast-app/shared/components/person/person';
import Util from '../../../forecast-app/shared/util/util';
import {profilePicSrc} from '../../../directApi';

class timeRegisteredPerPersonPortfolio extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sortBy: {column: null, ascending: true},
			rowExpandedMap: new Map(),
			weekDays: this.props.viewer.insightComponentsData.timeRegisteredPerPersonPortfolio.startWeekOnMonday
				? ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
				: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
		};
	}
	UNSAFE_componentWillMount() {
		this.setData(this.props, this.state);
		const listHeight = InsightsUtil.GetlistHeight(
			this.props.viewer.insightComponentsData.timeRegisteredPerPersonPortfolio.listData.length
		);
		this.props.updateComponentHeight(this.props.id, listHeight);
		if (this.props.setExportColumns) {
			let exportColumns = ['personName', 'projectName'];
			this.props.columns.forEach(col => {
				if (col !== 'name') {
					exportColumns.push(col + '_raw');
				}
			});

			this.props.setExportColumns(exportColumns);
		}
	}
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}
	UNSAFE_componentWillReceiveProps(nextProps, nextState) {
		if (this.props.labelFilterValue.length !== nextProps.labelFilterValue.length) {
			const labelIds = nextProps.labelFilterValue.length !== 0 ? nextProps.labelFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({labelIds});
		}
		this.setData(this.props, this.state);
		const listHeight = InsightsUtil.GetlistHeight(this.listData.length);
		this.props.updateComponentHeight(this.props.id, listHeight);
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		//after sort change
		if (
			nextState.sortBy.column !== this.state.sortBy.column ||
			nextState.sortBy.ascending !== this.state.sortBy.ascending
		) {
			this.sort(nextState.sortBy);
		}
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}
	getBackgroundColor(registered, available) {
		if (registered >= available) {
			return '#99FF8C';
		} else {
			return '#FF7C75';
		}
	}
	setData(props, state) {
		const {formatMessage, formatNumber} = props.intl;
		const component = props.viewer.insightComponentsData.timeRegisteredPerPersonPortfolio;
		this.totals = {
			name_raw: formatMessage({id: 'common.total'}),
			mon:
				formatNumber(component.mondayRegistered / 60.0) +
				'/' +
				formatNumber(component.mondayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'}),
			tue:
				formatNumber(component.tuesdayRegistered / 60.0) +
				'/' +
				formatNumber(component.tuesdayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'}),
			wed:
				formatNumber(component.wednesdayRegistered / 60.0) +
				'/' +
				formatNumber(component.wednesdayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'}),
			thu:
				formatNumber(component.thursdayRegistered / 60.0) +
				'/' +
				formatNumber(component.thursdayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'}),
			fri:
				formatNumber(component.fridayRegistered / 60.0) +
				'/' +
				formatNumber(component.fridayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'}),
			sat:
				formatNumber(component.saturdayRegistered / 60.0) +
				'/' +
				formatNumber(component.saturdayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'}),
			sun:
				formatNumber(component.sundayRegistered / 60.0) +
				'/' +
				formatNumber(component.sundayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'}),
			total:
				formatNumber(component.totalRegistered / 60.0) +
				'/' +
				formatNumber(component.totalWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'}),
		};
		this.listData = [];
		const exportData = [];
		component.listData.forEach(person => {
			const obj = {};
			obj.id = person.id;
			obj.name = (
				<Person
					key={person.id}
					name={person.name}
					role={person.role ? person.role : ''}
					showName={true}
					showRole={person.role !== null && person.role !== undefined}
					imageSrc={profilePicSrc(person.profilePictureId)}
					imageSize="medium"
				/>
			);
			obj.name_raw = person.name;
			obj.mon =
				formatNumber(person.mondayRegistered / 60.0) +
				'/' +
				formatNumber(person.mondayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'});
			obj.mon_diff = person.mondayRegistered - person.mondayWorkingHours;
			obj.tue =
				formatNumber(person.tuesdayRegistered / 60.0) +
				'/' +
				formatNumber(person.tuesdayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'});
			obj.tue_diff = person.tuesdayRegistered - person.tuesdayWorkingHours;
			obj.wed =
				formatNumber(person.wednesdayRegistered / 60.0) +
				'/' +
				formatNumber(person.wednesdayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'});
			obj.wed_diff = person.wednesdayRegistered - person.wednesdayWorkingHours;
			obj.thu =
				formatNumber(person.thursdayRegistered / 60.0) +
				'/' +
				formatNumber(person.thursdayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'});
			obj.thu_diff = person.thursdayRegistered - person.thursdayWorkingHours;
			obj.fri =
				formatNumber(person.fridayRegistered / 60.0) +
				'/' +
				formatNumber(person.fridayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'});
			obj.fri_diff = person.fridayRegistered - person.fridayWorkingHours;
			obj.sat =
				formatNumber(person.saturdayRegistered / 60.0) +
				'/' +
				formatNumber(person.saturdayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'});
			obj.sat_diff = person.saturdayRegistered - person.saturdayWorkingHours;
			obj.sun =
				formatNumber(person.sundayRegistered / 60.0) +
				'/' +
				formatNumber(person.sundayWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'});
			obj.sun_diff = person.sundayRegistered - person.sundayWorkingHours;
			obj.total =
				formatNumber(person.totalRegistered / 60.0) +
				'/' +
				formatNumber(person.totalWorkingHours / 60.0) +
				' ' +
				formatMessage({id: 'common.hours.short'});
			obj.total_diff = person.totalRegistered - person.totalWorkingHours;
			obj.mon_backgroundColor = this.getBackgroundColor(person.mondayRegistered, person.mondayWorkingHours);
			obj.tue_backgroundColor = this.getBackgroundColor(person.tuesdayRegistered, person.tuesdayWorkingHours);
			obj.wed_backgroundColor = this.getBackgroundColor(person.wednesdayRegistered, person.wednesdayWorkingHours);
			obj.thu_backgroundColor = this.getBackgroundColor(person.thursdayRegistered, person.thursdayWorkingHours);
			obj.fri_backgroundColor = this.getBackgroundColor(person.fridayRegistered, person.fridayWorkingHours);
			obj.sat_backgroundColor = this.getBackgroundColor(person.saturdayRegistered, person.saturdayWorkingHours);
			obj.sun_backgroundColor = this.getBackgroundColor(person.sundayRegistered, person.sundayWorkingHours);
			obj.total_backgroundColor = this.getBackgroundColor(person.totalRegistered, person.totalWorkingHours);
			const nestedListData = [];
			person.nestedListData.forEach(nestedElement => {
				const nestedObject = {};
				nestedObject.id = nestedElement.id;
				nestedObject.personName = obj.name_raw;
				nestedObject.projectName = nestedElement.name;
				nestedObject.name = nestedElement.name;
				nestedObject.mon = Util.convertMinutesToFullHour(nestedElement.mondayRegistered, this.props.intl);
				nestedObject.mon_raw = nestedElement.mondayRegistered;
				nestedObject.tue = Util.convertMinutesToFullHour(nestedElement.tuesdayRegistered, this.props.intl);
				nestedObject.tue_raw = nestedElement.tuesdayRegistered;
				nestedObject.wed = Util.convertMinutesToFullHour(nestedElement.wednesdayRegistered, this.props.intl);
				nestedObject.wed_raw = nestedElement.wednesdayRegistered;
				nestedObject.thu = Util.convertMinutesToFullHour(nestedElement.thursdayRegistered, this.props.intl);
				nestedObject.thu_raw = nestedElement.thursdayRegistered;
				nestedObject.fri = Util.convertMinutesToFullHour(nestedElement.fridayRegistered, this.props.intl);
				nestedObject.fri_raw = nestedElement.fridayRegistered;
				nestedObject.sat = Util.convertMinutesToFullHour(nestedElement.saturdayRegistered, this.props.intl);
				nestedObject.sat_raw = nestedElement.saturdayRegistered;
				nestedObject.sun = Util.convertMinutesToFullHour(nestedElement.sundayRegistered, this.props.intl);
				nestedObject.sun_raw = nestedElement.sundayRegistered;
				nestedObject.total = Util.convertMinutesToFullHour(nestedElement.totalRegistered, this.props.intl);
				nestedObject.total_raw = nestedElement.totalRegistered;
				nestedListData.push(nestedObject);

				const exportObj = {...nestedObject};
				exportObj.mon_raw /= 60.0;
				exportObj.tue_raw /= 60.0;
				exportObj.wed_raw /= 60.0;
				exportObj.thu_raw /= 60.0;
				exportObj.fri_raw /= 60.0;
				exportObj.sat_raw /= 60.0;
				exportObj.sun_raw /= 60.0;
				exportObj.total_raw /= 60.0;

				exportData.push(exportObj);
			});
			obj.nestedListData = nestedListData;
			this.listData.push(obj);
		});
		if (state.sortBy.column !== this.state.sortBy.column || state.sortBy.ascending !== this.state.sortBy.ascending) {
			this.sort(state.sortBy);
		}
		if (props.setExportData) {
			props.setExportData(exportData);
		}
	}

	handleExpandRow(el) {
		const shouldExpand = !this.state.rowExpandedMap.has(el.id) || !this.state.rowExpandedMap.get(el.id);
		const rowExpandedMap = this.state.rowExpandedMap;
		rowExpandedMap.set(el.id, shouldExpand);
		//listdata length
		let newLength = this.props.viewer.insightComponentsData.timeRegisteredPerPersonPortfolio.listData.length;
		//add all expanded row's nested elements length
		//if there are no nested elements we need to add one for empty message row
		this.listData.forEach(listElement => {
			if (listElement.id !== el.id && rowExpandedMap.has(listElement.id) && rowExpandedMap.get(listElement.id)) {
				newLength += listElement.nestedListData.length !== 0 ? listElement.nestedListData.length : 1;
			}
		});
		const clickedElNestedElementsLength = el.nestedListData.length !== 0 ? el.nestedListData.length : 1;
		if (shouldExpand) {
			newLength += clickedElNestedElementsLength;
		}
		const listHeight = InsightsUtil.GetlistHeight(newLength);
		this.props.updateComponentHeight(this.props.id, listHeight);
		this.setState({rowExpandedMap});
	}

	getTableBodyHTML() {
		const {formatMessage} = this.props.intl;
		const elements = [];
		this.listData.forEach((el, index) => {
			elements.push(this.getTableRowHTML(el, index));
			if (this.state.rowExpandedMap.has(el.id) && this.state.rowExpandedMap.get(el.id)) {
				if (el.nestedListData.length) {
					el.nestedListData.forEach((nestedEl, index) => {
						elements.push(
							<tr className="no-hover" key={nestedEl.id}>
								<td style={{paddingLeft: '45px'}} title={nestedEl.name} className="main">
									{nestedEl.name}
								</td>
								{this.state.weekDays.map((day, index) => (
									<td key={index}>{nestedEl[day]}</td>
								))}
								<td>{nestedEl.total}</td>
							</tr>
						);
					});
				} else {
					elements.push(
						<tr className="no-hover" key={el.id + '_empty'}>
							<td style={{paddingLeft: '45px'}} className="main">
								{formatMessage({id: 'common.no_time_registered'})}
							</td>
							<td />
							<td />
							<td />
							<td />
							<td />
							<td />
							<td />
							<td />
						</tr>
					);
				}
			}
		});
		return elements;
	}

	getTableRowHTML(el, index) {
		return (
			<tr key={index} onClick={this.handleExpandRow.bind(this, el)}>
				<td className="main">
					<div className="expandable-cell-container">
						{el.name}{' '}
						<div
							className={
								'expandable-cell-icon' +
								(this.state.rowExpandedMap.has(el.id) && this.state.rowExpandedMap.get(el.id)
									? ' expanded'
									: ' collapsed')
							}
						/>
					</div>
				</td>
				{this.state.weekDays.map((day, index) => (
					<td key={index} style={{backgroundColor: el[day + '_backgroundColor']}}>
						{el[day]}
					</td>
				))}
				<td style={{backgroundColor: el.total_backgroundColor}}>{el.total}</td>
			</tr>
		);
	}

	getWeekDayLanguageKey(day) {
		let key;
		switch (day) {
			case 'mon':
				key = 'common.monday.short';
				break;
			case 'tue':
				key = 'common.tuesday.short';
				break;
			case 'wed':
				key = 'common.wednesday.short';
				break;
			case 'thu':
				key = 'common.thursday.short';
				break;
			case 'fri':
				key = 'common.friday.short';
				break;
			case 'sat':
				key = 'common.saturday.short';
				break;
			case 'sun':
				key = 'common.sunday.short';
				break;
			default:
				key = '';
		}
		return key;
	}

	getThHTML(column, startDate, index) {
		return (
			<th
				key={index ? index : column}
				className={
					'sortable ' +
					(this.state.sortBy.column === column ? (this.state.sortBy.ascending ? 'ascending' : 'descending') : '') +
					(column === 'name' ? ' main' : '')
				}
				onClick={this.setSortBy.bind(this, column)}
			>
				<div className="header-container">
					{this.props.intl.formatMessage({
						id: startDate === null ? 'common.' + column : this.getWeekDayLanguageKey(column),
					})}
					{startDate === null ? null : ' ' + startDate.clone().startOf('week').add(index, 'day').format('DD/MM')}

					<span>&nbsp;&nbsp;</span>
				</div>
				<div className="header-totals">{this.totals[column]}</div>
			</th>
		);
	}

	sort(sortBy) {
		if (sortBy.column) {
			this.listData = this.listData.sort((a, b) => {
				let column = sortBy.column;
				if (column === 'name') {
					column += '_raw';
				} else {
					column += '_diff';
				}
				let returnValue = 0;
				if (column === 'name_raw') {
					if (a[column].toLowerCase() < b[column].toLowerCase()) returnValue = 1;
					if (a[column].toLowerCase() > b[column].toLowerCase()) returnValue = -1;
					if (a[column].toLowerCase() === '') returnValue = -1;
					if (b[column].toLowerCase() === '') returnValue = 1;
				} else {
					returnValue = a[column] - b[column];
				}

				return sortBy.ascending ? returnValue * -1 : returnValue;
			});
		}
	}

	render() {
		const startDate = this.props.weekPickerSelectedDate
			? this.props.weekPickerSelectedDate.clone().startOf('week')
			: Moment();
		return (
			<div className="insight-component-table">
				<table>
					<thead>
						<tr>
							{this.getThHTML('name', null, -1)}
							{this.state.weekDays.map((column, index) => this.getThHTML(column, startDate, index))}
							{this.getThHTML('total', null, null)}
						</tr>
					</thead>
					<tbody>{this.getTableBodyHTML()}</tbody>
				</table>
			</div>
		);
	}
}

const timeRegisteredPerPersonPortfolioQuery = graphql`
	query timeRegisteredPerPersonPortfolio_Query(
		$shareKey: String
		$weekPickerSelectedDate: String
		$componentId: ID
		$personIds: [ID]
		$teamIds: [ID]
		$roleIds: [ID]
		$labelIds: [ID]
	) {
		viewer {
			actualPersonId
			component(name: "insight_time_per_person_portfolio")
			...timeRegisteredPerPersonPortfolio_viewer
				@arguments(
					shareKey: $shareKey
					weekPickerSelectedDate: $weekPickerSelectedDate
					componentId: $componentId
					personIds: $personIds
					teamIds: $teamIds
					roleIds: $roleIds
					labelIds: $labelIds
				)
		}
	}
`;

export {timeRegisteredPerPersonPortfolioQuery};

export default injectIntl(
	createRefetchContainer(
		timeRegisteredPerPersonPortfolio,
		{
			viewer: graphql`
				fragment timeRegisteredPerPersonPortfolio_viewer on Viewer
				@argumentDefinitions(
					shareKey: {type: "String"}
					weekPickerSelectedDate: {type: "String"}
					componentId: {type: "ID"}
					personIds: {type: "[ID]"}
					teamIds: {type: "[ID]"}
					roleIds: {type: "[ID]"}
					labelIds: {type: "[ID]"}
				) {
					insightComponentsData(shareKey: $shareKey) {
						timeRegisteredPerPersonPortfolio(
							weekPickerSelectedDate: $weekPickerSelectedDate
							componentId: $componentId
							personIds: $personIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
						) {
							id
							startWeekOnMonday
							mondayRegistered
							mondayWorkingHours
							tuesdayRegistered
							tuesdayWorkingHours
							wednesdayRegistered
							wednesdayWorkingHours
							thursdayRegistered
							thursdayWorkingHours
							fridayRegistered
							fridayWorkingHours
							saturdayRegistered
							saturdayWorkingHours
							sundayRegistered
							sundayWorkingHours
							totalRegistered
							totalWorkingHours
							listData {
								id
								name
								role
								profilePictureId
								profilePictureDefaultId
								mondayRegistered
								mondayWorkingHours
								tuesdayRegistered
								tuesdayWorkingHours
								wednesdayRegistered
								wednesdayWorkingHours
								thursdayRegistered
								thursdayWorkingHours
								fridayRegistered
								fridayWorkingHours
								saturdayRegistered
								saturdayWorkingHours
								sundayRegistered
								sundayWorkingHours
								totalRegistered
								totalWorkingHours
								nestedListData {
									id
									name
									mondayRegistered
									tuesdayRegistered
									wednesdayRegistered
									thursdayRegistered
									fridayRegistered
									saturdayRegistered
									sundayRegistered
									totalRegistered
								}
							}
						}
					}
				}
			`,
		},
		graphql`
			query timeRegisteredPerPersonPortfolioRefetchQuery(
				$shareKey: String
				$weekPickerSelectedDate: String
				$componentId: ID
				$personIds: [ID]
				$teamIds: [ID]
				$roleIds: [ID]
				$labelIds: [ID]
			) {
				viewer {
					...timeRegisteredPerPersonPortfolio_viewer
						@arguments(
							shareKey: $shareKey
							weekPickerSelectedDate: $weekPickerSelectedDate
							componentId: $componentId
							personIds: $personIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
						)
				}
			}
		`
	)
);
