import {showPlaceholders} from '../canvas-timeline/canvas_timeline_util';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import TaskItem from '../components/items/task_item';
import ProjectItem from '../components/items/project_item';
import PhaseGroup from '../components/groups/phase_group';
import PhaseItem from '../components/items/phase_item';
import ProjectSubGroup from '../components/groups/project_sub_group';
import CapacityPlaceholderGroup from '../components/groups/capacity_placeholder_group';
import ProjectTeamItem from '../components/items/project_team_item';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {sortPhaseGroupsByDate, sortProjectGroups} from './projects_scheduling_util';
import PlaceholderAllocationItem from '../components/items/placeholder_allocation_item';
import PersonGroup from '../components/groups/person_group';
import ProjectGroup from '../components/groups/project_group';
import ComposeManager from '../ComposeManager';
import DataManager from '../DataManager';
import ProgramGroup from '../components/groups/program_group';
import {PROJECT_SUB_GROUP_TYPE} from '../constants';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

export const createPersonGroup = (pageComponent, person, project) => {
	if (!pageComponent.getData()) {
		return;
	}

	return new PersonGroup(pageComponent, ComposeManager.composePersonGroup(pageComponent, person, project));
};

export const constructPlaceholdersProjectSubGroup = (pageComponent, project, projectGroup) => {
	const placeholders = DataManager.getPlaceholdersByProjectOrProjectGroupId(pageComponent, project?.id, projectGroup?.id);

	// placeholder group
	const placeholderGroups = [];
	for (const placeholder of placeholders) {
		const placeholderGroupData = ComposeManager.composeCapacityPlaceholderGroup(pageComponent, placeholder);
		if (placeholderGroupData) {
			placeholderGroups.push(new CapacityPlaceholderGroup(pageComponent, placeholderGroupData));
		}
	}

	const placeholdersGroup = ComposeManager.composeProjectSubGroup(
		pageComponent,
		project,
		projectGroup,
		PROJECT_SUB_GROUP_TYPE.PLACEHOLDERS,
		placeholderGroups
	);

	if (placeholdersGroup) {
		return new ProjectSubGroup(pageComponent, placeholdersGroup);
	}

	return null;
};

export const constructTeamMembersGroup = (pageComponent, items, project, projectGroup) => {
	const {sharedContext, isProjectTimeline} = pageComponent.props;

	if (!sharedContext) {
		const projectPersons = projectGroup
			? DataManager.getProjectPersonsByProjectGroupId(pageComponent, projectGroup.id)
			: DataManager.getProjectPersonsByProjectId(pageComponent, project.id);

		const personGroups = [];
		const teamProfilePictureSrcs = [];
		if (projectPersons?.length > 0) {
			for (const projectPerson of projectPersons) {
				const person = DataManager.getPersonById(pageComponent, projectPerson.personId);

				if (person && !person.client) {
					const personGroupData = ComposeManager.composePersonGroup(pageComponent, person, project || projectGroup);

					if (personGroupData) {
						personGroups.push(new PersonGroup(pageComponent, personGroupData));
						teamProfilePictureSrcs.push({
							personId: person.id,
							profilePictureId: person.profilePictureId,
							profilePictureDefaultId: person.profilePictureDefaultId,
							initials: person.initials,
							fullName: person.fullName,
						});
					}
				}
			}
		}

		if (isProjectTimeline) {
			const projectTeamItemData = ComposeManager.composeProjectTeamItem(
				pageComponent,
				project,
				projectGroup,
				teamProfilePictureSrcs
			);

			if (projectTeamItemData) {
				items.push(new ProjectTeamItem(pageComponent, projectTeamItemData));
			}
		}

		const teamMembersSubGroupData = ComposeManager.composeProjectSubGroup(
			pageComponent,
			project,
			projectGroup,
			PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM,
			personGroups
		);

		if (teamMembersSubGroupData) {
			return new ProjectSubGroup(pageComponent, teamMembersSubGroupData);
		}

		return null;
	}
};

export const constructProject = (pageComponent, items, project) => {
	const sharedContext = pageComponent.props.sharedContext;

	const projectItemData = ComposeManager.composeProjectItem(pageComponent, project, null, null);
	if (projectItemData) {
		items.push(new ProjectItem(pageComponent, projectItemData));
	}

	const phaseGroups = [];
	const projectSubGroups = [];

	const projectPhases = DataManager.getPhaseIdsByProjectId(pageComponent, project.id);
	if (projectPhases) {
		for (const phaseId of projectPhases) {
			const phase = DataManager.getPhaseById(pageComponent, phaseId);

			if (phase) {
				const phaseGroupData = ComposeManager.composePhaseGroup(pageComponent, phase);

				if (phaseGroupData) {
					phaseGroups.push(new PhaseGroup(pageComponent, phaseGroupData));
				}

				const phaseItemData = ComposeManager.composePhaseItem(pageComponent, phase);

				if (phaseItemData) {
					items.push(new PhaseItem(pageComponent, phaseItemData));
				}
			}
		}
	}

	// tasks without phase are mapped by project id. If map has project id key it means there are tasks that don't have phase
	const noPhaseGroupData = ComposeManager.composePhaseGroup(pageComponent, null, project.id);
	if (noPhaseGroupData) {
		phaseGroups.push(new PhaseGroup(pageComponent, noPhaseGroupData));
	}

	if (hasFeatureFlag('schedule_sort_task_groups')) {
		sortPhaseGroupsByDate(phaseGroups);
	}

	const phasesSubGroupData = ComposeManager.composeProjectSubGroup(
		pageComponent,
		project,
		null,
		PROJECT_SUB_GROUP_TYPE.PHASES,
		phaseGroups
	);

	if (phasesSubGroupData) {
		projectSubGroups.push(new ProjectSubGroup(pageComponent, phasesSubGroupData));
	}

	if (!project.projectGroupId && showPlaceholders(pageComponent)) {
		const placeholdersGroup = constructPlaceholdersProjectSubGroup(pageComponent, project, null);
		if (placeholdersGroup) {
			projectSubGroups.push(placeholdersGroup);
		}
	}

	if (!sharedContext && !project.projectGroupId) {
		const teamMembersGroup = constructTeamMembersGroup(pageComponent, items, project, null);
		if (teamMembersGroup) {
			projectSubGroups.push(teamMembersGroup);
		}
	}

	const projectGroupData = ComposeManager.composeProjectGroup(
		pageComponent,
		null,
		null,
		project,
		null,
		projectSubGroups,
		true
	);
	if (projectGroupData) {
		return new ProjectGroup(pageComponent, projectGroupData);
	}

	return null;
};

export const getGroupsAndItemsFromProps = (pageComponent, props, data) => {
	const {isProjectTimeline, programPrefix, groupId} = pageComponent.props;
	let groups = [];
	const items = [];

	for (const allocation of data.allocations) {
		const allocationItemData = ComposeManager.composeProjectAllocation(pageComponent, allocation);
		if (allocationItemData) {
			items.push(new ProjectAllocationItem(pageComponent, allocationItemData));
		}
	}

	if (showPlaceholders(pageComponent)) {
		for (const placeholderAllocation of data.placeholderAllocations) {
			const placeholderAllocationItemData = ComposeManager.composePlaceholderAllocation(
				pageComponent,
				placeholderAllocation
			);

			if (placeholderAllocationItemData) {
				items.push(new PlaceholderAllocationItem(pageComponent, placeholderAllocationItemData));
			}
		}
	}

	for (const task of data.tasks) {
		const taskItemData = ComposeManager.composeTaskItem(pageComponent, task);

		if (taskItemData) {
			items.push(new TaskItem(pageComponent, taskItemData));
		}
	}

	const viewerProjectIds = new Set(
		DataManager.getProjectPersonsByPersonId(pageComponent, data.viewer.actualPersonId).map(pp => pp.projectId)
	);
	const hasProjectsReadAll =
		hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) ||
		(hasFeatureFlag('scheduling_read_only_permissions') && hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL_VIEW_ONLY));
	const projectEdges = data.projects.filter(project => hasProjectsReadAll || viewerProjectIds.has(project.id));

	// construct projects and their nested content
	const connectedProjectProjectGroups = [];
	const programProjectGroups = [];
	let viewedProject;
	const viewedCompanyProjectId = pageComponent.props.projectId && parseInt(pageComponent.props.projectId, 10);
	for (const project of projectEdges) {
		if (
			project.customProjectId === pageComponent.props.projectId ||
			(viewedCompanyProjectId && project.companyProjectId === viewedCompanyProjectId)
		) {
			viewedProject = project;
		}

		if (!viewedCompanyProjectId || project.id === viewedProject?.id) {
			const projectGroup = constructProject(pageComponent, items, project);

			if (projectGroup) {
				const isProjectGroupNested = !!project.projectGroupId;
				const isProgramNested = project.programPrefix && (!isProjectTimeline || pageComponent.props.programPrefix);

				if (isProjectGroupNested) {
					connectedProjectProjectGroups.push(projectGroup);
				} else if (isProgramNested) {
					programProjectGroups.push(projectGroup);
				} else {
					groups.push(projectGroup);
				}
			}
		}
	}

	const companyProjectGroupId = isProjectTimeline && groupId ? parseInt(groupId, 10) : null;
	for (const projectGroup of data.projectGroups) {
		const isViewedProjectGroup = projectGroup.companyProjectGroupId === companyProjectGroupId;
		const isViewedChildProject = viewedProject && viewedProject.projectGroupId === projectGroup.id;

		if (!isProjectTimeline || isViewedProjectGroup || isViewedChildProject) {
			const subGroups = [];

			for (const subProjectGroup of connectedProjectProjectGroups) {
				const {project} = subProjectGroup.data;

				if (project?.projectGroupId === projectGroup.id) {
					subGroups.push(subProjectGroup);
				}
			}

			if (showPlaceholders(pageComponent)) {
				const placeholdersGroup = constructPlaceholdersProjectSubGroup(pageComponent, null, projectGroup);
				if (placeholdersGroup) {
					subGroups.push(placeholdersGroup);
				}
			}

			const teamMembersGroup = constructTeamMembersGroup(pageComponent, items, null, projectGroup);
			if (teamMembersGroup) {
				subGroups.push(teamMembersGroup);
			}

			const projectGroupData = ComposeManager.composeProjectGroup(
				pageComponent,
				null,
				null,
				null,
				projectGroup,
				subGroups
			);
			if (projectGroupData) {
				groups.push(new ProjectGroup(pageComponent, projectGroupData));
			}
		}
	}

	// Do not add program groups is this is single project timeline
	if (!isProjectTimeline || programPrefix) {
		for (const program of data.programs) {
			const subGroups = [];

			for (const subProjectGroup of programProjectGroups) {
				const {project} = subProjectGroup.data;
				if (project?.programPrefix === program.prefix) {
					subGroups.push(subProjectGroup);
				}
			}

			const programGroupData = ComposeManager.composeProgramGroup(pageComponent, program, subGroups);
			if (programGroupData) {
				groups.push(new ProgramGroup(pageComponent, programGroupData));

				// add program item
				const projectItemData = ComposeManager.composeProjectItem(pageComponent, null, null, program);
				if (projectItemData) {
					items.push(new ProjectItem(pageComponent, projectItemData));
				}
			}
		}
	}

	groups = sortProjectGroups(pageComponent, groups);

	return {groups, items};
};
