import React, {useEffect, useRef, useState} from 'react';
import Styled from 'styled-components';
import {
	DATE_STEPS_SECTION_HEIGHT,
	TOOLTIP_CURSOR_OFFSET,
	TOOLTIP_CURSOR_WINDOW_SPACING,
} from '../canvas-timeline/canvas_timeline_util';
import {EquationDataElement} from '@forecast-it/design-system';

const CANVAS_BORDER_OFFSET = 5;

export default ({mouseData, data, pageComponent}) => {
	const {title, titleIcon, subTitle, description, items, maxWidth} = data;

	const defaultTooltipStyle = {
		maxWidth: maxWidth || 'none',
		hidden: true,
		left: mouseData.x,
		top: mouseData.y,
	};

	const tooltipRef = useRef(null);
	const [tooltipPosition, setTooltipPosition] = useState(defaultTooltipStyle);
	const [hasMounted, setHasMounted] = useState(false);

	const positionTooltip = event => {
		const {timeline} = pageComponent;
		const {canvasHeight} = timeline.state;
		const {height, width} = tooltipRef.current.getBoundingClientRect();

		const offsetTop = timeline.getCanvasOffset().top;
		const offsetLeft = timeline.getCanvasOffset().left;
		const mouseX = event ? event.clientX - offsetLeft : mouseData.x;
		const defaultMouseY = event ? event.clientY - offsetTop : mouseData.y;
		const mouseY = defaultMouseY + DATE_STEPS_SECTION_HEIGHT;

		// calculate positioning in relation to the cursor
		const showRight =
			mouseX + offsetLeft + TOOLTIP_CURSOR_OFFSET + width + TOOLTIP_CURSOR_WINDOW_SPACING <= window.innerWidth;
		const showBottom =
			defaultMouseY + offsetTop + TOOLTIP_CURSOR_OFFSET + height + TOOLTIP_CURSOR_WINDOW_SPACING <= window.innerHeight;

		// setting left/right
		const left = showRight
			? Math.min(mouseX + TOOLTIP_CURSOR_OFFSET, window.innerWidth - width)
			: Math.max(mouseX - TOOLTIP_CURSOR_OFFSET - width, 0);
		const top = showBottom
			? Math.min(mouseY + TOOLTIP_CURSOR_OFFSET, canvasHeight + DATE_STEPS_SECTION_HEIGHT - height - CANVAS_BORDER_OFFSET)
			: Math.max(mouseY - TOOLTIP_CURSOR_OFFSET - height, DATE_STEPS_SECTION_HEIGHT + CANVAS_BORDER_OFFSET);

		const tooltipPosition = {
			...defaultTooltipStyle,
			hidden: false,
			left,
			top,
		};

		setTooltipPosition(tooltipPosition);
	};

	useEffect(() => {
		if (!hasMounted) {
			positionTooltip(null);
			setHasMounted(true);
		}

		window.addEventListener('mousemove', positionTooltip);

		return () => {
			window.removeEventListener('mousemove', positionTooltip);
		};
	}, []);

	return (
		<TooltipContainer>
			<Tooltip style={tooltipPosition} ref={tooltipRef}>
				<TooltipHeader>
					<TooltipHeaderTitle>
						<div>{titleIcon}</div> <div>{title && <TooltipTitle>{title}</TooltipTitle>}</div>
					</TooltipHeaderTitle>
					{subTitle && <TooltipSubTitle>{subTitle}</TooltipSubTitle>}
				</TooltipHeader>

				{description && <TooltipDescription>{description}</TooltipDescription>}

				{items && (
					<TooltipContent>
						{items.map((item, index) => (
							<TooltipContentItem key={index}>
								{item.subTitle && <TooltipSubTitle>{item.subTitle}</TooltipSubTitle>}

								{item.values && (
									<TooltipValueContainer flexDirection={item.flexDirection}>
										{item.values &&
											item.values.map((value, valueIndex) => (
												<TooltipValue boldText={item.boldValueText} key={`${index}-${valueIndex}`}>
													{value}
												</TooltipValue>
											))}
									</TooltipValueContainer>
								)}

								{item.valueWarnings &&
									item.valueWarnings.map((valueWarning, warningIndex) => (
										<ValueWarningContainer value={valueWarning.value} key={`${index}-${warningIndex}`}>
											<TooltipValue>{valueWarning.value}</TooltipValue>
											<TooltipWarning value={valueWarning.value}>{valueWarning.warning}</TooltipWarning>
										</ValueWarningContainer>
									))}

								{item.equationItems && (
									<TooltipEquationItem>
										{item.equationItems.map(equationItem => {
											if (equationItem.term) {
												return <EquationDataElement>{equationItem.term}</EquationDataElement>;
											}

											if (equationItem.text) {
												return <TooltipEquationText>{equationItem.text}</TooltipEquationText>;
											}

											return null;
										})}
									</TooltipEquationItem>
								)}
							</TooltipContentItem>
						))}
					</TooltipContent>
				)}
			</Tooltip>
		</TooltipContainer>
	);
};

const TooltipContainer = Styled.div`
	{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
`;

const padding = '16px';

const Tooltip = Styled.div`
	{
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: ${padding};
		border-radius: 4px;
		border: 1px solid #E7E7F3;
		background-color: #FFFFFF;
		min-width: 143px;
		width: auto;
	}
`;

const TooltipTitle = Styled.h1`
	{
		font-size: 13px;
		font-weight: 600;
		color: #393946;
		margin-top: 0;
	}
`;

const TooltipSubTitle = Styled.h2`
	{
		font-size: 8px;
		text-transform: uppercase;	
		font-weight: 400;
		color: #7C7D8D;
		margin-top: 0;
	}
`;

const TooltipHeader = Styled.div`
	{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		text-align: left;
		margin-bottom: 6px;
	}
`;

const TooltipHeaderTitle = Styled.div`
	{
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: left;
	}
`;

const TooltipDescription = Styled.p`
	{
		width: 100%;
		text-align: left;
		color: #232334;
		font-size: 12px;
	}
`;

const TooltipEquationItem = Styled.div`
	{
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 8px;
	}
`;

const TooltipEquationText = Styled.p`
	{
		color: #232334;
		font-size: 11px;
	}
`;

const ValueWarningContainer = Styled.div`
	{
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: ${props => (props.value ? 'space-between' : 'flex-start')};
	}
`;

const TooltipValue = Styled.span`
	{
		font-size: 11px;
		font-weight: ${props => (props.boldText === false ? '400' : '600')};
		color: #393946;
		display: -webkit-box;
		max-width: ${props => (props.maxWidth === 'none' ? 'none' : `calc(${props.maxWidth}px - ${padding} - ${padding}))`)};
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
`;

const TooltipValueContainer = Styled.span`
	{
		display: flex;
		flex-direction:  ${props => (props.flexDirection ? props.flexDirection : 'column')};
		align-items: flex-start;
		justify-content: flex-start;
		text-align: left;
	}
`;

const TooltipWarning = Styled.span`
	{
		font-size: 11px;
		font-weight: 600;
		color: #EB0A00;
		margin-left: ${props => (props.value ? '8px' : '0')};
	}
`;

const TooltipContentItem = Styled.li`
	{
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		text-align: left;
		
		& ${TooltipSubTitle} {
			margin-bottom: 2px;
		}
	}
`;

const TooltipContent = Styled.ul`
	{
		width: 100%;
		padding: 0;
		margin: 0;
		margin-top: 2px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		text-align: left;
		
		> ${TooltipContentItem}:not(:last-child) {
			margin-bottom: 8px;
		}
	}
`;
