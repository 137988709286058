import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../../forecast-app/shared/util/util';
import DeleteProjectPersonMutation from '../../../mutations/delete_project_person_mutation';
import React from 'react';
import {getVisualizationMode, VISUALIZATION_MODE} from '../canvas-timeline/canvas_timeline_util';

export const handleRemovePerson = (pageComponent, project, projectGroup, person, projectPersons) => {
	const {company} = pageComponent.getData();
	const isUsingProjectAllocation = getVisualizationMode(
		pageComponent.state.schedulingOptions,
		company,
		VISUALIZATION_MODE.ALLOCATION
	);
	const {intl} = pageComponent.props;
	const {formatMessage} = intl;

	const deleteProjectPerson = () => {
		const onSuccess = response => {
			Util.dispatchScheduleEvent(response);
			createToast({message: formatMessage({id: 'settings_team.person-has-been-deleted'}), duration: 3000});
		};

		Util.CommitMutation(
			DeleteProjectPersonMutation,
			{
				projectId: project?.id,
				projectGroupId: projectGroup?.id,
				ids: projectPersons.map(projectPersons => projectPersons.id),
			},
			onSuccess
		);
	};

	const warningInformationId = isUsingProjectAllocation
		? 'settings_team.remove-from-project-warning'
		: 'settings_team.task_allocation.remove-from-project-warning';

	showModal({
		type: MODAL_TYPE.WARNING,
		warningMessageId: 'settings_team.remove-team-member-from-project',
		warningInformation: [
			formatMessage(
				{id: warningInformationId},
				{
					personName: person.fullName,
					i: str => <i>{str}</i>,
				}
			),
		],
		buttons: [
			{
				text: formatMessage({id: 'common.cancel'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: formatMessage({id: 'common.remove'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.RED,
				cy: 'remove-person-from-project-warning-button',
				callback: deleteProjectPerson,
			},
		],
	});
};
