/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DepartmentDropdown_departments$ref: FragmentReference;
declare export opaque type DepartmentDropdown_departments$fragmentType: DepartmentDropdown_departments$ref;
export type DepartmentDropdown_departments = $ReadOnlyArray<{|
  +node: ?{|
    +id: string,
    +name: ?string,
  |},
  +$refType: DepartmentDropdown_departments$ref,
|}>;
export type DepartmentDropdown_departments$data = DepartmentDropdown_departments;
export type DepartmentDropdown_departments$key = $ReadOnlyArray<{
  +$data?: DepartmentDropdown_departments$data,
  +$fragmentRefs: DepartmentDropdown_departments$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DepartmentDropdown_departments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DepartmentType",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DepartmentTypeEdge"
};
// prettier-ignore
(node/*: any*/).hash = '7544d13ec178cb3f650ffe1bb2b14683';

module.exports = node;
