/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type expenseRow_project$ref: FragmentReference;
declare export opaque type expenseRow_project$fragmentType: expenseRow_project$ref;
export type expenseRow_project = {|
  +id: string,
  +budgetType: ?BUDGET_TYPE,
  +companyProjectId: ?number,
  +$refType: expenseRow_project$ref,
|};
export type expenseRow_project$data = expenseRow_project;
export type expenseRow_project$key = {
  +$data?: expenseRow_project$data,
  +$fragmentRefs: expenseRow_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "expenseRow_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyProjectId",
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = '2d4772e76bffab6372915dd4626e5a06';

module.exports = node;
