import React from 'react';
import styled from 'styled-components';

import {ValueCell} from './ValueCell';
import {CSS_CONSTANTS} from '../../../../css_variables';

const TableHeaderSubColumnStyles = styled.th`
	padding-right: 16px;
	background: white;
	position: sticky;
	top: 38px;
	border-bottom: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};

	${({shouldBeSticky, index}) =>
		shouldBeSticky ? {zIndex: 100, left: index === 0 ? '0px' : index === 1 ? '45px' : index === 2 ? '121px' : ''} : ''};

	&.highlighted {
		background-color: ${CSS_CONSTANTS.v2_list_background_purple_opaque};
	}

	&.drawBorder {
		border-left: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		padding-left: 16px;
	}

	&:last-child {
		border-right: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	}

	.title {
		font-size: 9px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.22;
		letter-spacing: 1px;
		text-align: left;
		white-space: nowrap;
		color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};

		&.time,
		&.percentage,
		&.price {
			text-align: right;
		}

		&::before {
			content: '';
			width: ${({shouldBeSticky}) => (shouldBeSticky ? '100%' : 0)};
			height: 37px;
			top: -37px;
			left: ${({index}) => (index !== 0 ? 'auto' : 0)};
			background: white;
			position: absolute;
		}
	}

	.total {
		height: 31px;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: ${CSS_CONSTANTS.v2_text_gray};

		&.time,
		&.percentage,
		&.price {
			text-align: right;
		}
	}
`;

export const TableHeaderSubColumn = ({currencySymbol, highlighted, drawBorder, subColumn, shouldBeSticky, index}) => {
	return (
		<TableHeaderSubColumnStyles
			shouldBeSticky={shouldBeSticky}
			index={index}
			className={`${highlighted ? 'highlighted' : ''}${drawBorder ? ' drawBorder' : ''}`}
		>
			<div className={`title ${subColumn.type}`}>{subColumn.title}</div>
			<div className={`total ${subColumn.type}`}>
				{subColumn.total !== null ? (
					<ValueCell type={subColumn.type} value={subColumn.total} currencySymbol={currencySymbol} />
				) : null}
			</div>
		</TableHeaderSubColumnStyles>
	);
};
