import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
class HelpCenterIcon extends Component {
	render() {
		return (
			<a
				onClick={this.props.onClick}
				href={this.props.href}
				target="_blank"
				className={'icon help-center-icon'}
				rel="noopener noreferrer"
			>
				<TooltipContainer infoText={this.props.intl.formatMessage({id: 'settings.app_catalog.help_button'})} />
			</a>
		);
	}
}
HelpCenterIcon.propTypes = {};
export default injectIntl(HelpCenterIcon);
