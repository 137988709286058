import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {CSS_CONSTANTS} from '../../../../css_variables';
import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';

const CachedHeaderItemStyle = styled.div`
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

export const CachedHeaderItem = ({cachedTime}) => {
	const cachedTimeMoment = moment(cachedTime);

	const createFromNowString = () => cachedTimeMoment.fromNow();

	const [fromNowString, setFromNowString] = useState(createFromNowString());

	useEffect(() => {
		const interval = setInterval(() => {
			setFromNowString(createFromNowString());
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<CachedHeaderItemStyle>
			<TooltipContainer
				canBeMiddle={true}
				infoText={[
					{
						header: 'Data is cached once an hour to make the report load faster. Disabling the cache will take many minutes to complete.',
					},
					{
						description: `Data cached at ${cachedTimeMoment.format('LL')} ${cachedTimeMoment.format('LTS')}`,
					},
				]}
				translatedMessage={false}
				tooltipDuration={5 * 60 * 1000}
			>
				Showing data cached {fromNowString}
			</TooltipContainer>
		</CachedHeaderItemStyle>
	);
};
