import React, {Component} from 'react';

class Info extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showTooltip: false,
		};
	}
	toggleShowTooltip(e) {
		e.stopPropagation();
		this.setState({showTooltip: !this.state.showTooltip});
	}
	hideTooltip() {
		this.setState({showTooltip: false});
	}

	render() {
		if (
			(!this.props.infoMessage || this.props.infoMessage === '') &&
			(!this.props.multiInfoMessage || this.props.multiInfoMessage.length === 0)
		) {
			return null;
		}

		return (
			<div
				className={
					'info-component-icon-wrapper' +
					(this.props.customClasses ? ' ' + this.props.customClasses : '') +
					(this.props.small ? ' small' : this.props.medium ? ' medium' : ' large') +
					(this.props.useNewIcon ? ' new-icon' : '')
				}
				onMouseEnter={this.toggleShowTooltip.bind(this)}
				tabIndex="0"
				onMouseLeave={this.hideTooltip.bind(this)}
				title={this.props.useDefaultTitle ? this.props.infoMessage : ''}
			>
				{this.props.useDefaultTitle ? null : (
					<>
						{this.state.showTooltip && !this.props.showOnTop ? (
							<div className={this.props.leftAligned ? 'triangle-left' : 'triangle-right'} />
						) : null}
						{this.state.showTooltip ? (
							<div
								className={
									(this.props.leftAligned ? 'tooltip-left' : 'tooltip-right') +
									(this.props.showOnTop ? ' tooltip-top' : ' tooltip-bottom') +
									(this.props.wideToopltip ? ' tooltip-wide' : '')
								}
							>
								{this.props.infoMessage
									? this.props.infoMessage
									: this.props.multiInfoMessage
									? this.props.multiInfoMessage.map((info, id) => {
											return (
												<div className={'multiline'} key={id}>
													<div className={'title'}>{info.title}</div>
													<div className={'text'}>{info.text}</div>
												</div>
											);
									  })
									: null}
							</div>
						) : null}
					</>
				)}
			</div>
		);
	}
}
export default Info;
