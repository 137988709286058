/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type clientListComponent_viewer$ref = any;
export type clientListComponent_QueryVariables = {|
  shareKey?: ?string
|};
export type clientListComponent_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: clientListComponent_viewer$ref,
  |}
|};
export type clientListComponent_Query = {|
  variables: clientListComponent_QueryVariables,
  response: clientListComponent_QueryResponse,
|};
*/


/*
query clientListComponent_Query(
  $shareKey: String
) {
  viewer {
    actualPersonId
    component(name: "insight_client_list")
    ...clientListComponent_viewer_D5pEI
    id
  }
}

fragment clientListComponent_viewer_D5pEI on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    clientList {
      currency
      projectCountTotal
      projectHaltedCountTotal
      projectPlanningCountTotal
      projectRunningCountTotal
      projectDoneCountTotal
      reportedTotal
      forecastMinutesTotal
      forecastPointsTotal
      remainingMinutesTotal
      remainingPointsTotal
      budgetTotal
      spendTotal
      progressTotal
      avgRatePrHourRegistered
      avgRatePrHourPlanned
      avgRatePrHourFromBudget
      clients {
        id
        name
        projectCount
        projectHaltedCount
        projectPlanningCount
        projectRunningCount
        projectDoneCount
        reported
        forecastMinutes
        forecastPoints
        remainingMinutes
        remainingPoints
        budget
        spend
        progress
        avgRatePrHourRegistered
        avgRatePrHourPlanned
        avgRatePrHourFromBudget
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "insight_client_list"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"insight_client_list\")"
},
v3 = [
  {
    "kind": "Variable",
    "name": "shareKey",
    "variableName": "shareKey"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgRatePrHourRegistered",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgRatePrHourPlanned",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgRatePrHourFromBudget",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clientListComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "clientListComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clientListComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClientList",
                "kind": "LinkedField",
                "name": "clientList",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectHaltedCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectPlanningCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectRunningCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "projectDoneCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reportedTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forecastMinutesTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forecastPointsTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remainingMinutesTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remainingPointsTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "budgetTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "spendTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "progressTotal",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientListClient",
                    "kind": "LinkedField",
                    "name": "clients",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectHaltedCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectPlanningCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectRunningCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectDoneCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reported",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "forecastMinutes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "forecastPoints",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remainingMinutes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remainingPoints",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "budget",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "spend",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "progress",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "clientListComponent_Query",
    "operationKind": "query",
    "text": "query clientListComponent_Query(\n  $shareKey: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"insight_client_list\")\n    ...clientListComponent_viewer_D5pEI\n    id\n  }\n}\n\nfragment clientListComponent_viewer_D5pEI on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    clientList {\n      currency\n      projectCountTotal\n      projectHaltedCountTotal\n      projectPlanningCountTotal\n      projectRunningCountTotal\n      projectDoneCountTotal\n      reportedTotal\n      forecastMinutesTotal\n      forecastPointsTotal\n      remainingMinutesTotal\n      remainingPointsTotal\n      budgetTotal\n      spendTotal\n      progressTotal\n      avgRatePrHourRegistered\n      avgRatePrHourPlanned\n      avgRatePrHourFromBudget\n      clients {\n        id\n        name\n        projectCount\n        projectHaltedCount\n        projectPlanningCount\n        projectRunningCount\n        projectDoneCount\n        reported\n        forecastMinutes\n        forecastPoints\n        remainingMinutes\n        remainingPoints\n        budget\n        spend\n        progress\n        avgRatePrHourRegistered\n        avgRatePrHourPlanned\n        avgRatePrHourFromBudget\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2331d956c6c93bf9521d8d599b3561f6';

module.exports = node;
