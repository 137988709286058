import {createPaginationContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {getAggregatedResourceNumbersByMonth, mapCompressedAggregatedResourceNumbers} from '../UtilizationReportUtils';

const MonthByMonthPersonLoader = ({relay: {hasMore, isLoading, loadMore}, viewer, children, ...props}) => {
	const formatData = () => {
		return viewer.company.resourceBasedPersons.edges
			.map(edge => edge.node)
			.map(person => {
				const {id, firstName, lastName, profilePictureId} = person;
				const aggregatedResourceNumbers = mapCompressedAggregatedResourceNumbers(person.aggregatedResourceNumbers, [
					'forecastBillableUtilization',
					'forecastBillableUtilizationWin',
					'forecastBillableUtilizationSoft',
					'forecastBillableUtilizationSoftWin',
					'forecastBillableUtilizationHard',
					'forecastResourceUtilization',
					'forecastResourceUtilizationWin',
					'forecastResourceUtilizationSoft',
					'forecastResourceUtilizationSoftWin',
					'forecastResourceUtilizationHard',
					'forecastBillableUtilizationTaskAndAllocationsCombined',
					'forecastBillableUtilizationTaskAndAllocationsCombinedWin',
					'forecastBillableUtilizationTaskAndAllocationsCombinedSoft',
					'forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin',
					'forecastBillableUtilizationTaskAndAllocationsCombinedHard',
					'forecastResourceUtilizationTaskAndAllocationsCombined',
					'forecastResourceUtilizationTaskAndAllocationsCombinedWin',
					'forecastResourceUtilizationTaskAndAllocationsCombinedSoft',
					'forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin',
					'forecastResourceUtilizationTaskAndAllocationsCombinedHard',
				]);
				const aggregatedResourceNumbersByMonth = getAggregatedResourceNumbersByMonth(aggregatedResourceNumbers);

				return {
					person: {
						id,
						firstName,
						lastName,
						profilePictureId,
					},
					aggregatedResourceNumbersByMonth,
				};
			});
	};

	const loadMoreFunc = hasMore() ? loadMore : undefined;
	return children({data: {rows: formatData()}, loadMore: loadMoreFunc, loading: isLoading(), ...props});
};

export const monthByMonthPersonLoaderQuery = graphql`
	query MonthByMonthPersonLoaderQuery_Query(
		$searchQuery: TaskSearchQueryType
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$pageSize: Int
		$cursor: String
	) {
		viewer {
			actualPersonId
			component(name: "utilization_monthbymonth_person_loader")
			...MonthByMonthPersonLoader_viewer
				@arguments(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					pageSize: $pageSize
					cursor: $cursor
				)
		}
	}
`;

export default injectIntl(
	withRouter(
		createPaginationContainer(
			MonthByMonthPersonLoader,
			{
				viewer: graphql`
					fragment MonthByMonthPersonLoader_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						startYear: {type: "Int"}
						startMonth: {type: "Int"}
						startDay: {type: "Int"}
						endYear: {type: "Int"}
						endMonth: {type: "Int"}
						endDay: {type: "Int"}
						pageSize: {type: "Int"}
						cursor: {type: "String"}
					) {
						company {
							resourceBasedPersons(
								first: $pageSize
								after: $cursor
								searchQuery: $searchQuery
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								aggregateLevel: MONTH
								aggregates: [
									"forecastBillableUtilization"
									"forecastBillableUtilizationWin"
									"forecastBillableUtilizationSoft"
									"forecastBillableUtilizationSoftWin"
									"forecastBillableUtilizationHard"
									"forecastResourceUtilization"
									"forecastResourceUtilizationWin"
									"forecastResourceUtilizationSoft"
									"forecastResourceUtilizationSoftWin"
									"forecastResourceUtilizationHard"
									"forecastBillableUtilizationTaskAndAllocationsCombined"
									"forecastBillableUtilizationTaskAndAllocationsCombinedWin"
									"forecastBillableUtilizationTaskAndAllocationsCombinedSoft"
									"forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin"
									"forecastBillableUtilizationTaskAndAllocationsCombinedHard"
									"forecastResourceUtilizationTaskAndAllocationsCombined"
									"forecastResourceUtilizationTaskAndAllocationsCombinedWin"
									"forecastResourceUtilizationTaskAndAllocationsCombinedSoft"
									"forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin"
									"forecastResourceUtilizationTaskAndAllocationsCombinedHard"
								]
							) @connection(key: "Company_resourceBasedPersons") {
								edges {
									node {
										id
										firstName
										lastName
										profilePictureId
										aggregatedResourceNumbers
									}
								}
							}
						}
					}
				`,
			},
			{
				direction: 'forward',
				getConnectionFromProps({viewer}) {
					return viewer.company.resourceBasedPersons;
				},
				getFragmentVariables(prevVars) {
					return {
						...prevVars,
					};
				},
				getVariables(_, {cursor}, fragmentVariables) {
					return {
						...fragmentVariables,
						cursor,
					};
				},
				query: graphql`
					query MonthByMonthPersonLoaderRefetchQuery(
						$pageSize: Int
						$cursor: String
						$startYear: Int
						$startMonth: Int
						$startDay: Int
						$endYear: Int
						$endMonth: Int
						$endDay: Int
						$searchQuery: TaskSearchQueryType
					) {
						viewer {
							...MonthByMonthPersonLoader_viewer
								@arguments(
									pageSize: $pageSize
									cursor: $cursor
									startYear: $startYear
									startMonth: $startMonth
									startDay: $startDay
									endYear: $endYear
									endMonth: $endMonth
									endDay: $endDay
									searchQuery: $searchQuery
								)
						}
					}
				`,
			}
		)
	)
);
