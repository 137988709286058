import React from 'react';
import styled from 'styled-components';
import {PhaseBreakdownColumn} from './PhaseBreakdownColumn';
import {CSS_CONSTANTS} from '../../../../css_variables';
import {RoleBreakdownColumn} from './RoleBreakdownColumn';
import {ROW_TYPE} from './ValueCell';

const excludedSubColumns = ['expandCollapse', 'projectId', 'name'];

const PhaseRowStyle = styled.tr`
	border-right: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};

	.phaseNameColumn {
		overflow: hidden;

		font-size: 12px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: ${CSS_CONSTANTS.v2_text_gray};

		position: sticky;
		left: 45px;
		top: ${({isSafari}) => (isSafari ? '0px' : '79px')};
		background-color: white;
	}

	.drawTopBorder {
		border-top: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		padding-top: 10px;
		padding-bottom: 3px;
	}

	.firstColumn {
		border-left: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		border-top: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		box-shadow: inset 8px 0px 16px -8px rgba(0, 0, 0, 0.05);
		position: sticky;
		left: 0;
		top: ${({isSafari}) => (isSafari ? '0px' : '79px')};
		background-color: white;
	}

	&.firstBreakdownRow {
		box-shadow: inset 0 8px 16px -8px rgba(0, 0, 0, 0.05);
		td {
			padding-top: 10px;
		}
		.firstColumn {
			/* overwrite the border top on the .firstColumn */
			border-top: 0;
			box-shadow: inset 8px 8px 16px -8px rgba(0, 0, 0, 0.05);
		}

		.phaseNameColumn {
			box-shadow: inset 0 8px 16px -8px rgba(0, 0, 0, 0.05);
		}
	}

	&.lastBreakdownRow {
		box-shadow: inset 0px -8px 16px -8px rgba(0, 0, 0, 0.05);
		td {
			padding-bottom: 10px;
		}

		.firstColumn {
			box-shadow: inset 8px -8px 16px -8px rgba(0, 0, 0, 0.05);
		}

		.phaseNameColumn {
			box-shadow: inset 0px -8px 16px -8px rgba(0, 0, 0, 0.05);
		}
	}

	td:last-child {
		box-shadow: inset -8px 0px 16px -8px rgba(0, 0, 0, 0.05);
	}
`;

const RoleRowStyle = styled.tr`
	border-right: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};

	.roleNameColumn {
		padding-left: 5px;

		overflow: hidden;

		font-size: 11px;
		font-weight: normal;
		font-stretch: normal;
		font-style: italic;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: ${CSS_CONSTANTS.v2_text_gray};

		position: sticky;
		left: 45px;
		top: ${({isSafari}) => (isSafari ? '0px' : '79px')};
		background-color: white;
	}

	.firstColumn {
		border-left: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		box-shadow: inset 8px 0px 16px -8px rgba(0, 0, 0, 0.05);
		position: sticky;
		left: 0;
		top: ${({isSafari}) => (isSafari ? '0px' : '79px')};
		background-color: white;
	}

	&.firstBreakdownRow {
		td {
			padding-top: 10px;
		}
	}

	&.lastBreakdownRow {
		box-shadow: inset 0px -8px 16px -8px rgba(0, 0, 0, 0.05);
		td {
			padding-bottom: 10px;
		}
	}

	td:last-child {
		box-shadow: inset -8px 0px 16px -8px rgba(0, 0, 0, 0.05);
	}
`;

export const BreakdownLines = ({pageStructure, projectBreakdown, project, currencySymbol}) => {
	const showExpenses = projectBreakdown.showExpenses && projectBreakdown.expenses.expenses;

	// add sticky columns fix for safari
	const userAgent = navigator.userAgent.toLowerCase();
	const isSafari = userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1;

	return (
		<>
			{projectBreakdown.sortedPhaseIds.map((phaseId, phaseIndex) => {
				return (
					<React.Fragment key={phaseIndex}>
						{projectBreakdown.showPhases ? (
							<PhaseRowStyle
								isSafari={isSafari}
								index={phaseIndex}
								className={`${phaseIndex === 0 ? 'firstBreakdownRow' : ''}${
									!projectBreakdown.showRoles &&
									!showExpenses &&
									phaseIndex === Object.keys(projectBreakdown.phases).length - 1
										? ' lastBreakdownRow'
										: ''
								}`}
								key={phaseIndex}
							>
								<td className="firstColumn">&nbsp;</td>
								<td
									className={`phaseNameColumn${phaseIndex > 0 ? ' drawTopBorder' : ''}`}
									colSpan={
										pageStructure.columns[0].subColumns.find(subColumn => subColumn.id === 'projectId')
											? 2
											: 1
									}
								>
									{projectBreakdown.phases[phaseId].name}
								</td>
								{pageStructure.columns.map((column, columnIndex) =>
									column.subColumns
										.filter(subColumn => excludedSubColumns.indexOf(subColumn.id) === -1)
										.map((subColumn, subColumnIndex) => (
											<PhaseBreakdownColumn
												key={`${phaseIndex}-${subColumn.id}`}
												currencySymbol={currencySymbol}
												highlighted={column.highlighted}
												drawBorder={columnIndex > 0 && subColumnIndex === 0}
												drawTopBorder={phaseIndex > 0}
												subColumn={subColumn}
												project={project}
												phaseId={phaseId}
												rowType={
													phaseId === 'no-phase'
														? ROW_TYPE.NO_PHASE
														: phaseId === 'project-time-regs'
														? ROW_TYPE.PROJECT_TIME_REGS
														: phaseId === 'offset'
														? ROW_TYPE.PROJECT_TIME_REGS
														: ROW_TYPE.PHASE
												}
											/>
										))
								)}
							</PhaseRowStyle>
						) : null}

						{projectBreakdown.showRoles
							? projectBreakdown.sortedRoleIds[phaseId].map((roleId, roleIndex) => (
									<RoleRowStyle
										className={`${
											!projectBreakdown.showPhases && roleIndex === 0 ? 'firstBreakdownRow' : ''
										}${
											!showExpenses &&
											roleIndex === Object.keys(projectBreakdown.roles[phaseId]).length - 1 &&
											phaseIndex === Object.keys(projectBreakdown.phases).length - 1
												? ' lastBreakdownRow'
												: ''
										}`}
										key={`${phaseIndex}-${roleIndex}`}
										isSafari={isSafari}
									>
										<td className="firstColumn">&nbsp;</td>
										<td
											className="roleNameColumn"
											colSpan={
												pageStructure.columns[0].subColumns.find(
													subColumn => subColumn.id === 'projectId'
												)
													? 2
													: 1
											}
										>
											{projectBreakdown.roles[phaseId][roleId]}
										</td>
										{pageStructure.columns.map((column, columnIndex) =>
											column.subColumns
												.filter(subColumn => excludedSubColumns.indexOf(subColumn.id) === -1)
												.map((subColumn, subColumnIndex) => (
													<RoleBreakdownColumn
														key={`${phaseIndex}-${roleIndex}-${subColumn.id}`}
														currencySymbol={currencySymbol}
														highlighted={column.highlighted}
														drawBorder={columnIndex > 0 && subColumnIndex === 0}
														subColumn={subColumn}
														project={project}
														phaseId={phaseId}
														roleId={roleId}
													/>
												))
										)}
									</RoleRowStyle>
							  ))
							: null}
					</React.Fragment>
				);
			})}
			{showExpenses ? (
				<PhaseRowStyle
					className={`lastBreakdownRow${
						!projectBreakdown.showPhases && !projectBreakdown.showRoles ? ' firstBreakdownRow' : ''
					}`}
					key={'expenses'}
					isSafari={isSafari}
				>
					<td className="firstColumn">&nbsp;</td>
					<td
						className={`phaseNameColumn drawTopBorder`}
						colSpan={pageStructure.columns[0].subColumns.find(subColumn => subColumn.id === 'projectId') ? 2 : 1}
					>
						{projectBreakdown.expenses.expenses}
					</td>
					{pageStructure.columns.map((column, columnIndex) =>
						column.subColumns
							.filter(subColumn => excludedSubColumns.indexOf(subColumn.id) === -1)
							.map((subColumn, subColumnIndex) => (
								<PhaseBreakdownColumn
									key={subColumn.id}
									currencySymbol={currencySymbol}
									highlighted={column.highlighted}
									drawBorder={columnIndex > 0 && subColumnIndex === 0}
									drawTopBorder={true}
									subColumn={subColumn}
									project={project}
									phaseId={'expenses'}
									isSafari={isSafari}
									rowType={ROW_TYPE.EXPENSE}
								/>
							))
					)}
				</PhaseRowStyle>
			) : null}
		</>
	);
};
