import {CaretIcon, TimesheetsIcon} from 'web-components';
import {PersonLineStyle, PersonNameCell, TimesheetsIconWrapper} from './timesheets_team_page_styled';
import {CSS_CONSTANTS} from '../../../css_variables';
import Person from '../../../forecast-app/shared/components/person/person';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import TooltipContainer from '../../../forecast-app/shared/components/tooltips/tooltip_container';
import Util from '../../../forecast-app/shared/util/util';
import {
	getEmailIcon,
	getHolidaysOfTheDay,
	workingHourForTheDay,
} from '../../../forecast-app/my-work-tab/my-timesheets-page/timesheets_person_data';
import React from 'react';
import {useIntl} from 'react-intl';
import TimesheetsDayCell from './TimesheetsDayCell';
import TimeRegistrationLine, {LINE_TYPE} from './TimeRegistrationLine';
import SendTimeRegEmailMutation from '../../../mutations/send_time_reg_email_mutation';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import TimesheetsTotalsCell from './TimesheetsTotalsCell';
import {
	getTimeRegistrationsOfTheDay,
	separateWorkingTimeAndTimeOff,
} from '../../../forecast-app/my-work-tab/my-timesheets-page/timesheets_time_registrations';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {profilePicSrc} from '../../../directApi';

const PersonLine = ({
	id,
	timeRegistrations,
	timeRegistrations_cpy,
	timeRegistration,
	viewer,
	parentId,
	lastOne,
	person,
	firstDayOfWeek,
	lockedDate,
	timeRegs,
	filtered,
}) => {
	const intl = useIntl();
	const [expanded, setExpanded] = React.useState(false);

	const timeRegClassName = timeRegistrations ? ' time-reg-cell' : '';

	const hasTimeRegUpdatePermission = hasPermission(PERMISSION_TYPE.TIME_REGISTRATION_UPDATE_ALL);

	const sendMail = person => {
		Util.CommitMutation(SendTimeRegEmailMutation, {
			receiverId: person.id,
			dateRange:
				firstDayOfWeek.clone().startOf('w').format('DD MMMM') +
				' - ' +
				firstDayOfWeek.clone().endOf('w').format('DD MMMM YYYY'),
		});
	};

	const openSendMailModal = (e, person) => {
		e.stopPropagation();
		showModal({
			type: MODAL_TYPE.GENERIC,
			headerText: intl.formatMessage({id: 'timesheet.reminder_modal_header'}),
			content: (
				<div className={'body-container'}>
					{intl.formatMessage(
						{id: 'timesheet.reminder_modal_body'},
						{person: person.firstName + ' ' + person.lastName}
					)}
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: intl.formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: intl.formatMessage({id: 'common.send'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: sendMail.bind(this, person),
				},
			],
		});
	};
	const timesheetsRedirect = e => {
		trackEvent('Person Time Reg Icon', 'Clicked', {
			timeRegPerson: person.id === viewer.actualPersonId ? 'myself' : person.email,
		});
		e.stopPropagation();
		const firstDayOfWeekFormatted = firstDayOfWeek.clone().format('YYYY-MM-DD');
		window.open(`/timesheets?personId=${person.id}&date=${firstDayOfWeekFormatted}`);
	};

	let totalWeekTime = 0;
	let totalWeekBillableTime = 0;
	let totalWeekTimeHoliday = 0;

	for (let i = 0; i < 7; i++) {
		const day = firstDayOfWeek.clone().add(i, 'days');
		const timeRegsOfTheDay = getTimeRegistrationsOfTheDay(day, timeRegs);
		const timeWorked = separateWorkingTimeAndTimeOff(timeRegsOfTheDay);
		const workingHours = workingHourForTheDay(person, day);
		const holidayOfTheDay = getHolidaysOfTheDay(person, day);

		totalWeekTime += timeWorked.timeOff + timeWorked.workingTime;
		totalWeekBillableTime += timeWorked.billableTime;
		totalWeekTimeHoliday += holidayOfTheDay.length > 0 ? workingHours : 0;
	}

	const NotifyPersonViaEmail = () =>
		getEmailIcon(
			firstDayOfWeek.clone().endOf('week'),
			lockedDate,
			person,
			totalWeekTime + totalWeekTimeHoliday,
			openSendMailModal,
			intl
		);

	return (
		<>
			<PersonLineStyle
				data-userpilot={id === 0 ? 'timesheets-team-first-person' : ''}
				id={timeRegistration ? `person-${parentId}-time-reg-row-${id}` : `person-row-${id}`}
				className={
					timeRegistrations_cpy.length > 0
						? ' expandable'
						: '' +
						  (timeRegistrations ? ' time-reg-row' : '') +
						  (id === 0 ? ' first-time-reg-row' : lastOne ? ' last-time-reg-row' : '')
				}
				onClick={timeRegistrations_cpy.length > 0 ? () => setExpanded(!expanded) : null}
			>
				<PersonNameCell>
					<div className={'person-name-cell-inner'}>
						<div className="arrow" data-userpilot="timesheets-team-arrow">
							{timeRegistrations_cpy.length > 0 ? (
								<CaretIcon
									color={CSS_CONSTANTS.v2_text_gray}
									direction={expanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
								/>
							) : (
								<div className="empty-arrow"></div>
							)}
						</div>
						<div className="profile-picture">
							<Person
								name={`${person.firstName} ${person.lastName}`}
								showName={false}
								showRole={false}
								imageSize="new-ui-dropdown"
								imageSrc={profilePicSrc(person.profilePictureId)}
							/>
						</div>
						<div className="name-and-role">
							<div className="name">{person.firstName + ' ' + person.lastName}</div>
							<div className="role" title={person.role ? person.role.name : ' '}>
								{person.role ? person.role.name : ' '}
							</div>
						</div>
						{!person.active ? (
							<div className={'deactivated'}>{intl.formatMessage({id: 'team.deactivated-group-title'})}</div>
						) : null}
						{person.active ? (
							<TooltipContainer
								infoText={intl.formatMessage(
									{id: 'timesheet.go_to_persons_timesheet'},
									{firstName: person.firstName}
								)}
								growable
							>
								{hasTimeRegUpdatePermission && (
									<TimesheetsIconWrapper>
										<TimesheetsIcon onClick={e => timesheetsRedirect(e, false)} />
									</TimesheetsIconWrapper>
								)}
							</TooltipContainer>
						) : null}
					</div>
				</PersonNameCell>
				{Array(7)
					.fill(0)
					.map((n, i) => (
						<TimesheetsDayCell
							viewer={viewer}
							firstDayOfWeek={firstDayOfWeek}
							timeRegs={timeRegs}
							timeRegistrations={timeRegistrations}
							timeRegClassName={timeRegClassName}
							person={person}
							lockedDate={lockedDate}
							index={i}
						/>
					))}
				<TimesheetsTotalsCell
					totalWeekTime={totalWeekTime}
					totalWeekBillableTime={totalWeekBillableTime}
					lockedDate={lockedDate}
					firstDayOfWeek={firstDayOfWeek}
					timeRegClassName={timeRegClassName}
					NotifyPersonViaEmail={NotifyPersonViaEmail}
				/>
			</PersonLineStyle>
			{expanded &&
				filtered.map((timeReg, index) => {
					//to reduce calculations the registration in the groupe are set to null in the timeRegistrations_cpy
					return (
						<TimeRegistrationLine
							key={index}
							id={index}
							viewer={viewer}
							parentId={id}
							person={person}
							lastOne={index === filtered.length - 1}
							timeRegistrations={timeReg}
							firstDayOfWeek={firstDayOfWeek}
							lockedDate={lockedDate}
							lineType={LINE_TYPE.ENTITY}
						/>
					);
				})}
		</>
	);
};

export default PersonLine;
