import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import BaseLevelWrapper from './BaselevelWrapper';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../constants';
import GroupRow from '../rows/GroupRow';

const ClientRelayWrapper = ({
	tableHeader,
	relay,
	groupings,
	searchQuery,
	viewer,
	intl,
	rowIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
	convertToProjectCurrency,
	projectCurrency,
}) => {
	const clients = viewer.company.clients.edges;
	const company = viewer.company;
	const currency = convertToProjectCurrency && projectCurrency ? projectCurrency : viewer.company.currency;
	const hasNextPage = viewer.company.clients.pageInfo.hasNextPage;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	/**
	 * Grouping filter for task with a client.
	 *
	 * @param clientID The client ID
	 */
	const clientGroupingFilter = clientID => {
		return {
			field: GLOBAL_FILTER_FIELD.CLIENT,
			operator: GLOBAL_FILTER_OPERATOR.IS,
			value: [clientID],
		};
	};

	/**
	 * Grouping filter for task without a client.
	 */
	const noClientGroupingFilter = () => {
		return {
			field: GLOBAL_FILTER_FIELD.CLIENT,
			operator: GLOBAL_FILTER_OPERATOR.IS,
		};
	};

	const dataRows = clients.map(client => {
		return {
			...client.node,
			currency: currency,
		};
	});

	if (company.taskTotals?.numberOfTasks) {
		dataRows.push({
			isNoClient: true,
			name: intl.formatMessage({id: 'common.no_client'}),
			currency,
			taskTotals: company.taskTotals,
		});
	}

	const data = {
		rows: dataRows,
		currency,
		groupingFilter: rowData => (rowData.isNoClient === true ? noClientGroupingFilter() : clientGroupingFilter(rowData.id)),
	};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = clients.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const clientRows = () => {
		return GroupRow(
			data,
			intl,
			rowIndex,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader,
			convertToProjectCurrency,
			projectCurrency
		);
	};

	return rowIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			data={data}
			getRows={clientRows}
			currentLength={clients.length}
			hasNextPage={viewer.company.clients.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		clientRows()
	);
};

const ClientRelayWrapperQuery = graphql`
	query ClientRelayWrapperQuery_Query($searchQuery: TaskSearchQueryType, $pageSize: Int, $convertToProjectCurrency: Boolean) {
		viewer {
			actualPersonId
			component(name: "client_relay_wrapper")
			...ClientRelayWrapper_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, convertToProjectCurrency: $convertToProjectCurrency)
		}
	}
`;
export {ClientRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			ClientRelayWrapper,
			{
				viewer: graphql`
					fragment ClientRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						convertToProjectCurrency: {type: "Boolean"}
					) {
						company {
							currency
							taskTotals(
								searchQuery: $searchQuery
								noClient: true
								convertToProjectCurrency: $convertToProjectCurrency
							) {
								registeredMinutes
								billableActualMinutes
								nonBillableActualMinutes
								estimatePoints
								estimateMinutes
								remainingPoints
								remainingMinutes
								plannedRevenue
								actualRevenue
								remainingRevenue
								forecastRevenue
								recognitionLockedRevenue
								recognitionOpenRevenue
								recognitionForecastRevenue
								totalActualRevenueRecognition
								totalForecastRevenueToComplete
								surplus
								numberOfTasks
							}
							clients(first: $pageSize, searchQuery: $searchQuery) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										name
										logoId
										logoDefaultId
										taskTotals(
											searchQuery: $searchQuery
											convertToProjectCurrency: $convertToProjectCurrency
										) {
											registeredMinutes
											billableActualMinutes
											nonBillableActualMinutes
											estimatePoints
											estimateMinutes
											remainingPoints
											remainingMinutes
											plannedRevenue
											actualRevenue
											remainingRevenue
											forecastRevenue
											recognitionLockedRevenue
											recognitionOpenRevenue
											recognitionForecastRevenue
											totalActualRevenueRecognition
											totalForecastRevenueToComplete
											surplus
											numberOfTasks
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query ClientRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$convertToProjectCurrency: Boolean
				) {
					viewer {
						...ClientRelayWrapper_viewer
							@arguments(
								pageSize: $pageSize
								searchQuery: $searchQuery
								convertToProjectCurrency: $convertToProjectCurrency
							)
					}
				}
			`
		)
	)
);
