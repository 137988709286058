import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import TimeChart from './TimeChart';
import TimeTotals from './TimeTotals';
import {BudgetDataWrapper, TimeChartWrapper} from '../BudgetData.styled';

const TimeBudgetData = ({project, selectedAggregateLevel, eyeOptionMap}) => {
	return (
		<>
			<BudgetDataWrapper>
				<TimeChartWrapper>
					<TimeChart project={project} selectedAggregateLevel={selectedAggregateLevel} eyeOptionMap={eyeOptionMap} />
				</TimeChartWrapper>
				<TimeTotals project={project} eyeOptionMap={eyeOptionMap} />
			</BudgetDataWrapper>
		</>
	);
};

export default createFragmentContainer(TimeBudgetData, {
	project: graphql`
		fragment TimeBudgetData_project on ProjectType {
			...TimeChart_project
			...TimeTotals_project
		}
	`,
});
