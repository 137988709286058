import {GROUP_TYPE, ITEM_TYPE} from '../../canvas-timeline/canvas_timeline_util';
import * as tracking from '../../../../tracking';
import {MODAL_TYPE} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {fetchDataPromises} from '../../scheduling_fetch';
import {personIsClientUser} from '../../../../forecast-app/shared/util/PermissionsUtil';
import DirectApi from '../../../../directApi';
import {trackComplexEvent} from '../../../../tracking/amplitude/TrackingV2';
import {createPersonGroup} from '../projects_scheduling_data';
import IDManager from '../../IDManager';
import {PROJECT_SUB_GROUP_TYPE} from '../../constants';

export const fetchPersonHeatmapData = async (pageComponent, personIds) => {
	let requestUrl = DirectApi.graphqlServerEndpoint('scheduling/heatmap');
	return fetchDataPromises([
		{
			url: requestUrl,
			method: 'POST',
			body: {excludedCompanyProjectId: pageComponent.state.project.companyProjectId, personIds: personIds},
		},
	]).then(dataArray => {
		const data = dataArray[0];
		if (data && !data.error) {
			return data;
		} else {
			pageComponent.showModal({
				type: MODAL_TYPE.MUTATION_ERROR,
				isNetworkError: false,
				reload_to_upcoming: true,
			});
		}
	});
};

const setFooterPersonsOnAutoScheduleConfig = (pageComponent, newFooterPersons) => {
	pageComponent.state.autoSchedulingConfig.autoSchedulingRequestConfig.personRole = newFooterPersons.map(footerPerson => ({
		personId: +atob(footerPerson.id).replace('Person:', ''),
		roleId: footerPerson.roleId ? +atob(footerPerson.roleId).replace('Role:', '') : null,
		active: footerPerson.active,
		isClientUser: personIsClientUser(footerPerson),
	}));
};

export const addFooterPerson = async (pageComponent, person) => {
	// Add person to project team group and fetch heatmap data
	if (pageComponent.state.autoScheduleProposalData && pageComponent.state.autoScheduleProposalData.project.id) {
		const parentGroup = pageComponent.state.groups.find(
			group => group.id === pageComponent.state.autoScheduleProposalData.project.id
		);
		if (parentGroup) {
			const personData = pageComponent.getData().persons.find(p => p.id === person.id);

			const existingFooterPerson = pageComponent.state.footerPersons.find(person => person.id === personData.id);
			if (!existingFooterPerson) {
				// fetch heatmap data
				fetchPersonHeatmapData(pageComponent, [person.id]).then(personHeatmapData => {
					if (personHeatmapData && !personHeatmapData.error) {
						// Add person group
						const project = pageComponent.state.project;
						const teamMembersGroup = parentGroup.groups.find(
							group =>
								group.groupType === GROUP_TYPE.PROJECT_SCHEDULING_PROJECT_SUB_GROUP &&
								group.id &&
								group.id.includes(PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM)
						);
						teamMembersGroup.addChildGroup(createPersonGroup(pageComponent, personData, project));
						// Merge new person heatmap data with existing heatmap data
						const currentTimelineHeatmapData = pageComponent.state.timelineHeatmapData;
						currentTimelineHeatmapData[person.id] = personHeatmapData[person.id];
						pageComponent.setState({timelineHeatmapData: currentTimelineHeatmapData}, () =>
							pageComponent.redrawCanvasTimeline({preventFiltering: false})
						);
					}
				});

				// Add new person to footer
				const newFooterPersons = [...pageComponent.state.footerPersons, personData];
				setFooterPersonsOnAutoScheduleConfig(pageComponent, newFooterPersons);
				const projectTeamItem = pageComponent.state.items.find(
					item => item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_TEAM_ITEM
				);
				if (projectTeamItem) {
					const personPicture = {
						personId: personData.id,
						profilePictureId: personData.profilePictureId,
						profilePictureDefaultId: personData.profilePictureDefaultId,
						initials: personData.initials,
					};
					projectTeamItem.data.teamProfilePictureSrcs.push(personPicture);
				}
				const currentTimelineHeatmapData = pageComponent.state.timelineHeatmapData;
				if (!currentTimelineHeatmapData[person.id]) {
					currentTimelineHeatmapData[person.id] = [[null, null, 0, 0]];
				}
				pageComponent.setState(
					{
						footerPersons: newFooterPersons,
						timelineHeatmapData: currentTimelineHeatmapData,
					},
					() => pageComponent.redrawCanvasTimeline({preventFiltering: false})
				);
				tracking.trackEvent('Add Team member from preview');
				trackComplexEvent('Team Member', 'Added', {location: 'From Preview'});
			}
		}
	}
};

export const removeFooterPerson = (pageComponent, person) => {
	const parentGroup = pageComponent.state.groups.find(
		group => group.id === pageComponent.state.autoScheduleProposalData.project.id
	);
	if (parentGroup) {
		const teamMembersGroup = parentGroup.groups.find(
			group =>
				group.groupType === GROUP_TYPE.PROJECT_SCHEDULING_PROJECT_SUB_GROUP &&
				group.id &&
				group.id.includes(PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM)
		);
		if (teamMembersGroup) {
			teamMembersGroup.groups = teamMembersGroup.groups.filter(
				group =>
					group.id !==
					IDManager.getPersonGroupId(
						pageComponent,
						person.id,
						pageComponent.state.autoScheduleProposalData.project.id
					)
			);
		}
	}

	const newFooterPersons = pageComponent.state.footerPersons.filter(footerPerson => footerPerson.id !== person.id);
	setFooterPersonsOnAutoScheduleConfig(pageComponent, newFooterPersons);
	const projectTeamItem = pageComponent.state.items.find(item => item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_TEAM_ITEM);
	if (projectTeamItem) {
		projectTeamItem.data.teamProfilePictureSrcs = projectTeamItem.data.teamProfilePictureSrcs.filter(
			personPicture => personPicture.personId !== person.id
		);
	}
	pageComponent.setState({footerPersons: newFooterPersons});
	tracking.trackEvent('Remove Team member from preview');
	trackComplexEvent('Team Member', 'Removed', {location: 'From Preview'});
	pageComponent.redrawCanvasTimeline({preventFiltering: false});
};

export const getFilteredFooterPersons = pageComponent => {
	const searchCriteria = pageComponent.state.autoSchedulingDropdownSearchCriteria;

	return pageComponent.state.footerPersons.filter(person => {
		const role = pageComponent.getData().roles.find(role => role.id === person.roleId);
		const firstName = person.firstName || '';
		const lastName = person.lastName || '';
		const roleName = role && role.name ? role.name : '';

		return (
			person.active &&
			(searchCriteria === '' ||
				firstName.toUpperCase().includes(searchCriteria) ||
				lastName.toUpperCase().includes(searchCriteria) ||
				roleName.toUpperCase().includes(searchCriteria))
		);
	});
};

export const updateDropdownSearchCriteria = (pageComponent, searchCriteria) => {
	if (pageComponent.state.autoSchedulingDropdownSearchCriteria !== searchCriteria) {
		pageComponent.setState({autoSchedulingDropdownSearchCriteria: searchCriteria});
	}
};

export const handleFooterPersonMouseEnter = (pageComponent, person, e) => {
	const targetPosition = e.target && e.target.getBoundingClientRect();

	// Don't show the tooltip when dragging
	if (pageComponent.state.autoSchedulingDragPersonId) {
		pageComponent.setState({footerPersonHoverData: null});
		return false;
	}

	pageComponent.setState({footerPersonHoverData: {person, left: 0, top: 0, opacity: 0}}, () => {
		const divPosition = pageComponent.footerHoverDiv.getBoundingClientRect();
		pageComponent.setState({
			footerPersonHoverData: {
				person,
				top: targetPosition.top - divPosition.height - 12,
				left: targetPosition.left - divPosition.width / 2 + targetPosition.width / 2,
				opacity: 1,
			},
		});
	});
};

export const handleFooterPersonMouseLeave = pageComponent => {
	if (pageComponent.state.footerPersonHoverData) {
		pageComponent.setState({footerPersonHoverData: null});
	}
};

export const onPersonMouseDown = (pageComponent, personId, e) => {
	if (pageComponent.state.footerPersonHoverData) {
		pageComponent.setState({footerPersonHoverData: null});
	}
	// Find the offset of the mouse pointer from the top left corner of the image
	const targetRect = e.target.getBoundingClientRect();

	const centerX = targetRect.left + targetRect.width / 2;
	const centerY = targetRect.top + targetRect.height / 2;

	let offsetX = e.clientX - centerX;
	let offsetY = e.clientY - centerY;

	pageComponent.mouseMoveCount = 0;

	e.preventDefault();
	pageComponent.setState({
		autoSchedulingDragPersonId: personId,
		autoSchedulingDragTranslateX: e.clientX - targetRect.width / 2 - offsetX,
		autoSchedulingDragTranslateY: e.clientY - targetRect.height / 2 - offsetY,
		offsetX,
		offsetY,
	});
};
