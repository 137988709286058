import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import ChartJsPie from '../../chartjs/chart_js_pie';

class numberOfTasksComponent extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}

	render() {
		if (this.props.viewer.insightComponentsData.cardsDone) {
			const {formatMessage} = this.props.intl;
			const doneCount = this.props.viewer.insightComponentsData.cardsDone.numOfCardsDone;
			const leftCount =
				this.props.viewer.insightComponentsData.cardsDone.numOfCards -
				this.props.viewer.insightComponentsData.cardsDone.numOfCardsDone;
			const data = {
				labels: [
					formatMessage({id: 'insights.component.task-number.cards_done'}, {count: doneCount}),
					formatMessage({id: 'insights.component.task-number.cards_left'}, {count: leftCount}),
				],
				datasets: [
					{
						data: [doneCount, leftCount],
						backgroundColor: ['#94D7FD', '#44B4FF'],
					},
				],
			};
			return <ChartJsPie data={data} type="horizontalBar" />;
		} else {
			return null;
		}
	}
}

const numberOfTasksComponentQuery = graphql`
	query numberOfTasksComponent_Query($shareKey: String, $projectId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_number_of_tasks")
			...numberOfTasksComponent_viewer
				@arguments(shareKey: $shareKey, projectId: $projectId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {numberOfTasksComponentQuery};

export default injectIntl(
	createFragmentContainer(numberOfTasksComponent, {
		viewer: graphql`
			fragment numberOfTasksComponent_viewer on Viewer
			@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
				insightComponentsData(shareKey: $shareKey) {
					cardsDone(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
						numOfCards
						numOfCardsDone
					}
				}
			}
		`,
	})
);
