import React, {useMemo, useState} from 'react';
import Moment from 'moment';
import {createFragmentContainer, graphql} from 'react-relay';
import {PageWrapper} from './TimeApprovalPage_styled';
import * as tracking from '../../../tracking';
import TimeApprovalSection, {TimeApprovalSectionQuery} from './TimeApprovalSection';
import HeaderBar from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {useIntl} from 'react-intl';
import {ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE, TIERS} from '../../../constants';
import {
	handleAdjacentPeriodClick,
	handleDateRangeClick,
	TIME_PERIOD,
} from '../../../forecast-app/my-work-tab/my-timesheets-page/timesheets_change_date_view';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/FilterWrapper';
import {getFilterFunctions} from '../../../forecast-app/shared/components/filters/filter_logic';
import {getFiltersAlphabetically} from '../../../forecast-app/shared/components/filters/filter_util';
import Util from '../../../forecast-app/shared/util/util';
import {getInitialOptions, handleChangedOptions, theEyeToColumns} from '../../../the_eye_util';
import {theEyeOptions} from './TimeApprovalTheEye';
import {GROUP_BY} from './TimeApprovalLogic';
import {getCompanyLockedDate} from '../time-lock/TimeLockUtil';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {
	usingInternalTimeApproval,
	usingTimeApproval,
	usingTimeOffApproval,
} from '../../../forecast-app/shared/util/FeatureUtil';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import {hasTimeApprovalAccess} from '../TimeManagementUtil';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';

const getSecondLevelAffectingFilters = groupingMode => {
	if (groupingMode === GROUP_BY.PERSON) {
		// Filters affecting projects or non-projects
		return [FILTER_TYPE.PROJECT, FILTER_TYPE.PROJECT_TYPE, FILTER_TYPE.CLIENT, FILTER_TYPE.INTERNAL_TIME];
	} else if (groupingMode === GROUP_BY.PROJECT) {
		// Filters affecting people
		return [FILTER_TYPE.TEAM_MEMBER, FILTER_TYPE.TEAM, FILTER_TYPE.ROLE, FILTER_TYPE.DEPARTMENT];
	}

	return [];
};

// We don't want users to accidentally approve time that is not currently shown by the filter
// As such, don't allow top-level approval when a filter is filtering out something in the second level of grouping
// This is a band-aid fix. A proper solution would be to make the approval action filter-aware.
const disableTopLevelApproval = (appliedFilters, groupingMode) => {
	if (appliedFilters) {
		const secondLevelAffectingFilters = getSecondLevelAffectingFilters(groupingMode);
		return Object.keys(appliedFilters).some(appliedFilter => secondLevelAffectingFilters.includes(appliedFilter));
	}
};

const TimeApprovalPage = ({viewer}) => {
	const intl = useIntl();
	const history = useHistory();
	if (!hasTimeApprovalAccess(viewer)) {
		history.replace('/not-authorized');
	}

	const localFilters = Util.localStorageGetItem('time-approval-filters')
		? JSON.parse(Util.localStorageGetItem('time-approval-filters'))
		: null;

	const [currentViewingDate, setCurrentViewingDate] = useState(Moment().startOf('w'));
	const [appliedFilters, setAppliedFilters] = useState(localFilters);
	const [filterFunctions, setFilterFunctions] = useState(localFilters ? getFilterFunctions(localFilters) : () => true);
	const localStorageTheEyeName = 'the-eye-time-approval';
	const [_theEyeOptions, setTheEyeOptions] = useState(getInitialOptions(theEyeOptions(), localStorageTheEyeName));
	const enabledColumns = useMemo(() => theEyeToColumns(_theEyeOptions), [_theEyeOptions]);
	const timePeriod = TIME_PERIOD.WEEK_VIEW;

	const [_groupBy, setGroupBy] = useState(Util.localStorageGetItem('time-approval-group-by') || GROUP_BY.PERSON);

	const companyLockedDate = useMemo(() => {
		return viewer?.company ? getCompanyLockedDate(viewer?.company) : undefined;
	}, [viewer?.company]);

	const companyWeekLocked = useMemo(() => {
		const companyLockedDate = getCompanyLockedDate(viewer.company);
		const endOfWeek = currentViewingDate.clone().endOf('week');
		return companyLockedDate && companyLockedDate.isSameOrAfter(endOfWeek);
	}, [currentViewingDate]);

	const topLevelApprovalDisabled = useMemo(() => {
		return disableTopLevelApproval(appliedFilters?.timeRegs, _groupBy);
	}, [appliedFilters, _groupBy]);

	useTrackPage('Time Approval');

	const goToToday = () => {
		setCurrentViewingDate(Moment().startOf('w'));
	};

	const adjacentPeriod = forward => {
		setCurrentViewingDate(handleAdjacentPeriodClick(currentViewingDate, forward, timePeriod));
	};

	const handleDateRange = date => {
		setCurrentViewingDate(handleDateRangeClick(date, timePeriod));
	};

	const onFilterChange = (filters, filterFunctions) => {
		Util.localStorageSetItem(`time-approval-filters`, JSON.stringify(filters));
		setAppliedFilters(filters);
		setFilterFunctions(filterFunctions);
	};

	const handleTheEyeOptionSelect = (_, __, ___, newOptions) => {
		setTheEyeOptions(handleChangedOptions(newOptions, localStorageTheEyeName));
	};

	const handleGroupByChange = val => {
		Util.localStorageSetItem('time-approval-group-by', val);
		tracking.trackPageAction('Group by changed', {groupBy: val});
		trackEvent('Group By', 'Changed', {groupBy: val});
		setGroupBy(val);
	};

	const getHeader = () => {
		const leftContent = [];
		const rightContent = [];

		const dateChanger = {
			type: ELEMENT_TYPE.DATE_CHANGER_V2,
			useTodayButton: true,
			timePeriod: TIME_PERIOD.WEEK_VIEW,
			currentViewingDate: currentViewingDate,
			handleTodayButtonClick: goToToday.bind(this),
			handleAdjacentDateButtonClick: adjacentPeriod.bind(this),
			updateDateRange: handleDateRange.bind(this),
			boldLongDayMonth: true,
			selectorTooltipEnabled: true,
			todayButtonLeft: true,
			selectorTooltipProps: {
				autoPlace: true,
				grey: true,
				infoText: intl.formatMessage({id: 'common.select_date'}),
			},
			tooltipEnabled: true,
			tooltipProps: {
				autoPlace: true,
				infoText: Moment().format('ddd, DD. MMM') + ' ' + Moment().format('YYYY'),
				grey: true,
			},
		};
		leftContent.push(dateChanger);

		const peopleFilters = [];
		const projectFilters = [];
		const timeRegFilters = [
			FILTER_TYPE.PROJECT,
			FILTER_TYPE.PROJECT_TYPE,
			FILTER_TYPE.CLIENT,
			FILTER_TYPE.TEAM_MEMBER,
			FILTER_TYPE.TEAM,
			FILTER_TYPE.ROLE,
		];
		if (usingInternalTimeApproval(viewer.company.useInternalTimeApproval)) {
			timeRegFilters.push(FILTER_TYPE.INTERNAL_TIME);
		}
		if ([TIERS.ENTERPRISE, TIERS.FREE_FOREVER, TIERS.TRIAL].includes(viewer.company.tier)) {
			timeRegFilters.push(FILTER_TYPE.DEPARTMENT);
		}
		const filter = {
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: FILTER_SECTIONS.TIMEREGS,
			projectFilters: getFiltersAlphabetically(projectFilters, intl.formatMessage),
			peopleFilters: getFiltersAlphabetically(peopleFilters, intl.formatMessage),
			timeRegFilters: getFiltersAlphabetically(timeRegFilters, intl.formatMessage),
			viewer: viewer,
			filterSection: FILTER_SECTION.TIME_APPROVAL,
			appliedFiltersName: 'time-approval-filters',
			onFiltersChange: onFilterChange,
		};
		rightContent.push(filter);

		if (companyWeekLocked) {
			leftContent.push({
				type: ELEMENT_TYPE.HEADER_LABEL,
				text: intl.formatMessage({id: 'time_lock.week_locked'}),
			});
		}

		const groupingOptions = [
			{
				value: GROUP_BY.PERSON,
				dataCy: 'grouping-dropdown-option-person',
				label: intl.formatMessage({id: 'common.group_by_person'}),
			},
			{
				value: GROUP_BY.PROJECT,
				dataCy: 'grouping-dropdown-option-project',
				label: intl.formatMessage({id: 'common.group_by_project'}),
			},
		];

		const groupingDropdown = {
			type: ELEMENT_TYPE.DROPDOWN,
			dropdownOptions: groupingOptions,
			value: _groupBy,
			callback: group => handleGroupByChange(group.value),
			placeholder: intl.formatMessage({id: 'project_budget.select_period'}),
			userpilot: 'budget-period-dropdown',
			dataCy: 'grouping-dropdown',
		};
		rightContent.push(groupingDropdown);

		const theEye = {
			type: ELEMENT_TYPE.THE_EYE,
			options: _theEyeOptions,
			onSelect: handleTheEyeOptionSelect,
			expandLeft: true,
			userpilot: 'eye-selector',
		};
		rightContent.push(theEye);

		return <HeaderBar leftContent={leftContent} rightContent={rightContent} tlnPadding={true} />;
	};

	const filterOptions = {teams: viewer.company.teams.edges};

	return (
		<PageWrapper>
			<TopHeaderBarWrapper sidePadding={24}>
				<TopHeaderBar title="Timesheet Approval" />
			</TopHeaderBarWrapper>
			{getHeader()}
			<ForecastQueryRenderer
				key="query-render-time-approval-section"
				query={TimeApprovalSectionQuery}
				authorizeAccessRoute={'time-approval'}
				variables={{
					startDateString: Moment().clone().startOf('week').format('YYYY-MM-DD'),
					endDateString: Moment().clone().endOf('week').format('YYYY-MM-DD'),
					searchQuery: {filters: []},
					approvableTime: usingTimeApproval(viewer.company.useTimeApproval),
					approvableTimeOff: usingTimeOffApproval(viewer.company.useTimeOffApproval),
				}}
				render={(relayProps, retry) => {
					return (
						<TimeApprovalSection
							retry={retry}
							{...relayProps}
							filterFunctions={filterFunctions}
							currentViewingDate={currentViewingDate}
							intl={intl}
							enabledColumns={enabledColumns}
							group={_groupBy}
							actualPersonId={viewer.actualPersonId}
							personFilter={filterFunctions.timeRegFilter}
							filterOptions={filterOptions}
							topLevelApprovalDisabled={topLevelApprovalDisabled}
							companyLockedDate={companyLockedDate}
						/>
					);
				}}
			/>
		</PageWrapper>
	);
};

const TimeApprovalPageQuery = graphql`
	query TimeApprovalPage_Query {
		viewer {
			actualPersonId
			component(name: "time-approval")
			projectOwner
			...TimeApprovalPage_viewer
		}
	}
`;

export {TimeApprovalPageQuery};

export default createFragmentContainer(TimeApprovalPage, {
	viewer: graphql`
		fragment TimeApprovalPage_viewer on Viewer {
			component(name: "time-approval")
			id
			harvestUser
			actualPersonId
			email
			projectOwner
			availableFeatureFlags {
				key
			}
			language
			filters(first: 10000, filterSection: TIME_APPROVAL) @connection(key: "Viewer_filters", filters: []) {
				edges {
					node {
						id
						name
						section
						value
						updatedAt
					}
				}
			}
			company {
				id
				useTimeApproval
				useTimeOffApproval
				harvestEnabled
				characterLimit
				lockedPeriodYear
				lockedPeriodMonth
				lockedPeriodDay
				tier
				useInternalTimeApproval
				modules {
					moduleType
				}
				teams(first: 1000000) @connection(key: "Company_teams", filters: []) {
					edges {
						node {
							id
							name
							teamPersons(first: 1000) @connection(key: "Team_teamPersons", filters: []) {
								edges {
									node {
										id
										person {
											id
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`,
});
