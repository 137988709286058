import React, {useMemo} from 'react';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {Dropdown, IconText, InputLabel} from 'web-components';
import PropTypes from 'prop-types';
import Util from '../../../forecast-app/shared/util/util';
import {useIntl} from 'react-intl';
import {isProjectDoneOrHalted} from '../canvas-timeline/canvas_timeline_util';
import {DeprecatedProjectIndicatorNoFragment} from '../../../forecast-app/shared/components/project-indicator/DeprecatedProjectIndicator';
import {DeprecatedProjectGroupIndicatorNoFragment} from '../../../forecast-app/shared/components/project-indicator/DeprecatedProjectGroupIndicator';

const AllocationProjectDropdown = ({
	label,
	cy,
	projects = [],
	projectGroups = [],
	projectPersons = [],
	onChange,
	selectedValue,
	actualPersonId,
	includeChildProjects = false,
	includeConnectedProjects = false,
	includeJiraProjects = false,
	sortAlphabetically = false,
	disabled = false,
}) => {
	const {formatMessage} = useIntl();

	const options = useMemo(() => {
		const options = [];
		const projectGroupIdSet = new Set();
		for (const project of projects) {
			//Do not include halted and done projects
			if (isProjectDoneOrHalted(project.status)) continue;
			//Do not include jira projects
			if (!includeJiraProjects && project.isJiraProject) continue;

			if (
				!hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) &&
				!(hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL_VIEW_ONLY) && disabled) &&
				!projectPersons.find(
					projectPerson => projectPerson.projectId === project.id && projectPerson.personId === actualPersonId
				)
			) {
				continue;
			}

			if (project.isInProjectGroup) {
				projectGroupIdSet.add(project.projectGroupId);
			}

			if (!project.isInProjectGroup || includeChildProjects) {
				options.push({
					id: project.id,
					companyProjectId: project.companyProjectId,
					customProjectId: project.customProjectId,
					color: project.projectColor,
					name: project.name,
					project: project,
				});
			}
		}

		if (includeConnectedProjects) {
			// users should not be able to create tasks in a connected project
			for (const projectGroup of projectGroups) {
				if (projectGroupIdSet.has(projectGroup.id)) {
					options.push({
						id: projectGroup.id,
						companyProjectId: projectGroup.companyProjectGroupId,
						color: projectGroup.color,
						name: projectGroup.name,
						isConnectedProject: true,
						projectGroup: projectGroup,
					});
				}
			}
		}

		if (sortAlphabetically) {
			options.sort((a, b) => Util.sortAlphabetically(a.name, b.name));
		}
		return options;
	}, [
		actualPersonId,
		includeChildProjects,
		includeConnectedProjects,
		projectGroups,
		projectPersons,
		projects,
		sortAlphabetically,
	]);

	function onSelect(project) {
		onChange(project[0]);
	}

	const selectedItem = options.find(option => option.id === selectedValue);

	function getFullName(option) {
		return `${option.isConnectedProject ? 'X' : 'P'}${option.companyProjectId} ${option.name}`;
	}

	const selectName = selectedItem ? getFullName(selectedItem) : formatMessage({id: 'task_location_modal.select_project'});

	return (
		<InputLabel
			text={label}
			child={
				<Dropdown
					cy={cy}
					onSelect={onSelect}
					selectedItems={[selectedValue]}
					name={selectName}
					size={'large'}
					usePortal
					disabled={disabled}
				>
					{options.map(option => (
						<IconText
							cy={`option-${option.id}`}
							key={option.id}
							value={option.id}
							text={option.name}
							searchString={getFullName(option)}
						>
							{option.isConnectedProject ? (
								<DeprecatedProjectGroupIndicatorNoFragment
									disableLink
									noWidthLimit={false}
									projectGroup={option.projectGroup}
								/>
							) : (
								<DeprecatedProjectIndicatorNoFragment
									disableLink
									noWidthLimit={false}
									project={option.project}
								/>
							)}
						</IconText>
					))}
				</Dropdown>
			}
		/>
	);
};

AllocationProjectDropdown.propTypes = {
	actualPersonId: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	selectedValue: PropTypes.string,
	cy: PropTypes.string,
	projects: PropTypes.array,
	projectGroups: PropTypes.array,
	projectPersons: PropTypes.array,
	includeChildProjects: PropTypes.bool,
	includeConnectedProjects: PropTypes.bool,
	sortAlphabetically: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default AllocationProjectDropdown;
