/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query ExpenseItemChangeProjectPhaseModalQuery {
  viewer {
    actualPersonId
    component(name: "ExpenseItemChangeProjectPhaseModal")
    ...ExpenseItemChangeProjectPhaseModal_viewer
    id
  }
}

fragment ExpenseItemChangeProjectPhaseModal_viewer on Viewer {
  id
  company {
    id
  }
  phases {
    edges {
      node {
        id
        name
        projectId
      }
    }
  }
  projects(first: 100000) {
    edges {
      node {
        id
        name
        projectColor
        companyProjectId
        ...ProjectIndicator_project
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ProjectIndicator_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "ExpenseItemChangeProjectPhaseModal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"ExpenseItemChangeProjectPhaseModal\")"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100000
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ExpenseItemChangeProjectPhaseModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ExpenseItemChangeProjectPhaseModal_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ExpenseItemChangeProjectPhaseModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PhaseTypeConnection",
                            "kind": "LinkedField",
                            "name": "phases",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PhaseTypeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PhaseType",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "projectId",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v4 /*: any*/),
                            "concreteType": "ProjectTypeConnection",
                            "kind": "LinkedField",
                            "name": "projects",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ProjectTypeEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ProjectType",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                (v3 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "projectColor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "companyProjectId",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "customProjectId",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "projects(first:100000)"
                        },
                        {
                            "alias": null,
                            "args": (v4 /*: any*/),
                            "filters": [],
                            "handle": "connection",
                            "key": "Viewer_projects",
                            "kind": "LinkedHandle",
                            "name": "projects"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "ExpenseItemChangeProjectPhaseModalQuery",
            "operationKind": "query",
            "text": "query ExpenseItemChangeProjectPhaseModalQuery {\n  viewer {\n    actualPersonId\n    component(name: \"ExpenseItemChangeProjectPhaseModal\")\n    ...ExpenseItemChangeProjectPhaseModal_viewer\n    id\n  }\n}\n\nfragment ExpenseItemChangeProjectPhaseModal_viewer on Viewer {\n  id\n  company {\n    id\n  }\n  phases {\n    edges {\n      node {\n        id\n        name\n        projectId\n      }\n    }\n  }\n  projects(first: 100000) {\n    edges {\n      node {\n        id\n        name\n        projectColor\n        companyProjectId\n        ...ProjectIndicator_project\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ProjectIndicator_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n"
        }
    };
})();
node.hash = '10f778f0b7cdac883174b4ab70bef601';
export default node;
