import React from 'react';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import styled from 'styled-components';

const BuyNowFooter = styled.div`
	display: flex;
	z-index: 2;
	justify-content: center;
	margin-top: auto;
	height: 40px;
	width: 100%;
	background-color: white;
	box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.08);
	padding: 7px 0;
	opacity: 1;
	transition: opacity 1000ms linear;
	&.opaque {
		opacity: 0;
	}
	button {
		height: 26px;
	}
	.buy-now-text {
		padding: 5px 16px;
		font-size: 13px;
		font-weight: 500;
	}
`;

const buyNowFooter = ({intl, buyNowTime}) => {
	return (
		<BuyNowFooter>
			<div className="buy-now-text">
				{moment.utc(buyNowTime).diff(moment.utc(), 'days') === 0
					? intl.formatMessage({id: 'trial_end.last_day'})
					: intl.formatMessage(
							{id: 'header.upgrade_button_trial_days_left'},
							{value: moment.utc(buyNowTime).diff(moment.utc(), 'days') + 1}
					  )}
			</div>
		</BuyNowFooter>
	);
};

export default injectIntl(buyNowFooter);
