import React, {useEffect, useRef, useState} from 'react';
import {injectIntl} from 'react-intl';
import uuid from 'uuid';
import Moment from 'moment';
import PropTypes from 'prop-types';

import CreateTimeRegistration from '../../mutations/create_time_registration_mutation.modern';

import Util from '../../forecast-app/shared/util/util';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE} from '../../constants';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import Button from '../../forecast-app/shared/components/buttons/button/button';
import DatePicker from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import TimeRegWSuggestionsInput from '../../forecast-app/shared/components/inputs/time-registration-input/time_reg_w_sugestions_input';
import TimeRegContainer from '../../styles/components/time_reg_styled';
import Dropdown from '../../forecast-app/shared/components/dropdowns/dropdown';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import DirectApi from '../../directApi';
import {updateTaskForScoping} from '../../forecast-app/project-tab/projects/scoping-page/ProjectScopingUtil';
import {canLoggedInPersonManageProgram} from '../../forecast-app/shared/util/ProgramFinancialLogic';
import {ACTION} from '../../forecast-app/shared/components/modals/program-management/ProgramBudgetErrorMessage';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';
import {
	isBillableSplitAllowed,
	isRolesAllowed,
	isTimeRegistrationNoteRequired,
} from '../../forecast-app/shared/util/cache/TimeRegistrationSettingsUtil';
import {isTimeRegistrationNoteInvalid} from '../../forecast-app/shared/util/time-registration/time-registration-settings/TimeRegistrationNoteFilter';
import LabelWithTooltip from '../../forecast-app/shared/components/add-time-entry/validation-info/LabelWithTooltip';
import {
	NoteRequiredError,
	NoteRequiredInfoIcon,
} from '../../forecast-app/shared/components/add-time-entry/validation-info/NoteRequiredInfo';
import {TimeRegGranularityInfoIcon} from '../../forecast-app/shared/components/add-time-entry/validation-info/TimeRegGranularityInfo';
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {convertIntoFloatHoursFormatWithGranularity} from '../../forecast-app/shared/components/inputs/hours-input/hours_input_logic';
import {workingHourForTheDay} from '../../forecast-app/my-work-tab/my-timesheets-page/timesheets_person_data';
import {dispatch, EVENT_ID} from '../../containers/event_manager';
import {createDateFromMoment} from '../../forecast-app/shared/util/DateUtil';
import {
	getDateRestrictionInfo,
	getIsDateDisabledForTaskAndProject,
	getProjectDates,
	getTaskDates,
	validateDateReturnErrorMessage,
} from '../../forecast-app/shared/util/time-registration/time-registration-settings/TimeRegistrationDateUtil_BackwardsCompatibility';
import {useTaskEstimateValidation} from '../../forecast-app/shared/util/time-registration/time-registration-settings/useTaskEstimateValidation';
import {RemainingTimeAndError} from '../../forecast-app/shared/components/add-time-entry/validation-info/TimeExceedsEstimateInfo';
import {DateInvalidError} from '../../forecast-app/shared/components/add-time-entry/validation-info/DateInvalidError';
import {DateRestrictionInfoIcon} from '../../forecast-app/shared/components/add-time-entry/validation-info/DateRestrictionInfo';
import RoleDropdownRelayWrapper from '../../forecast-app/shared/components/dropdowns/role-dropdown/RoleDropdownRelayWrapper';
import {getFormattedValue} from '../../forecast-app/my-work-tab/my-timesheets-page/bottom-section/timesheets-time-registration-table/timesheet-table-util/TimeRegUtil';
import Spinner from '../../forecast-app/shared/components/spinner/Spinner';
import {useSpinner} from '../../forecast-app/shared/components/spinner/useSpinner';

function getBillableTimeInputValue_timeRegInputConversion(targetEntityBillable, billableTime, inputValue) {
	if (!targetEntityBillable) {
		return 0;
	}
	if (billableTime != null) {
		return `${billableTime}m`;
	}
	if (inputValue && inputValue !== '') {
		return `${inputValue}m`;
	}
	return 0;
}

export const TimeReg = ({viewer, task, intl, onDone, isTaskModal, focusOnMount, harvestTaskOptions, lockedDate}) => {
	const formatMessage = intl.formatMessage;
	const noteRequired = isTimeRegistrationNoteRequired();
	const today = Moment();
	const todayOrEarliestSelectableDate =
		lockedDate && lockedDate.isSameOrAfter(today, 'day') ? lockedDate.clone().add(1, 'd') : today;

	const [id] = useState(uuid.v4());
	const [inputValue, setInputValue] = useState('');
	const [billableTime, setBillableTime] = useState(0);
	const [selectedDate, setSelectedDate] = useState(todayOrEarliestSelectableDate);
	const [notes, setNotes] = useState('');
	const [noteError, setNoteError] = useState(false);
	const [timeError, setTimeError] = useState('');
	const [dateError, setDateError] = useState('');
	const [selectedHarvestTask, setSelectedHarvestTask] = useState(null);
	const [selectedUnit4Activity, setSelectedUnit4Activity] = useState(null);
	const [unit4Loading, setUnit4Loading] = useState(false);
	const [unit4Activities, setUnit4Activities] = useState([]);
	const [waitingForMutation, setWaitingForMutation] = useState(false);
	const {spinning} = useSpinner({waiting: waitingForMutation});

	const inputRef = useRef(null);
	const billableMinutesUpdated = useRef(false);

	const targetEntityBillable = task.billable;
	const billableSplitAllowed = isBillableSplitAllowed();

	const billableTimeInputValue = getBillableTimeInputValue_timeRegInputConversion(
		targetEntityBillable,
		billableTime,
		inputValue
	);

	let {remainingInfoMessage, validateTimeInputValue} = useTaskEstimateValidation(
		task?.estimateForecastMinutes,
		task?.totalMinutesRegistered,
		0,
		intl
	);

	const dateInfo = getDateRestrictionInfo(task, task?.project);

	const workingHoursForDay = workingHourForTheDay({...viewer, id: viewer.actualPersonId}, selectedDate || Moment());

	const rolesAllowed = isRolesAllowed();

	const [selectedRole, setSelectedRole] = useState();

	// Focus input on mount if prop is passed
	useEffect(() => {
		if (focusOnMount && inputRef) {
			inputRef.current.focus();
		}
	}, []);

	useEffect(() => {
		if (lockedDate && lockedDate.isSameOrAfter(selectedDate, 'day')) {
			setSelectedDate(lockedDate.clone().add(1, 'd'));
		}
	}, [lockedDate]);

	useEffect(() => {
		const timeValidationError = validateTimeInputValue(inputValue);
		if (timeValidationError) {
			setTimeError(timeValidationError);
		} else {
			setTimeError(null);
		}
	}, [inputValue]);

	const setValueToInput = value => {
		setInputValue(Util.convertTimeInputToMinutes(value, true));

		if (!billableMinutesUpdated.current) {
			setBillableTime(null);
		}
	};

	const onBillableHoursInputChange = value => {
		const formattedValue = getFormattedValue(value);
		trackEvent('Time Entry Modal', 'Billable Time Input Changed', {
			from: billableTimeInputValue,
			to: formattedValue / 60,
		});

		setBillableTime(formattedValue);

		billableMinutesUpdated.current = true;
	};

	const handleDateSelect = date => {
		setSelectedDate(date);
	};

	useEffect(() => {
		const dateInvalidReason = validateDateReturnErrorMessage(selectedDate, task, task?.project);
		if (!dateInvalidReason) {
			setDateError('');
		}
	}, [selectedDate]);

	const handleNotesChange = e => {
		const note = e.target ? e.target.value : '';
		setNoteError(false);
		setNotes(note);
	};

	const handleHarvestTaskChange = task => {
		setSelectedHarvestTask(task);
	};

	const handleUnit4ActivityChange = activity => {
		setSelectedUnit4Activity(activity);
	};

	const handleCancelButtonPress = () => {
		trackEvent('New Time Entry Cancel Button', 'Clicked');
		onDone();
	};

	const resetFields = () => {
		setInputValue('');
		setBillableTime(null);
		setSelectedDate(todayOrEarliestSelectableDate);
		setNoteError(false);
		setNotes('');
		setTimeError(null);

		dispatch(EVENT_ID.RESET_INPUT);
	};

	const showProgramRevenueLockedMessage = () => {
		const program = viewer.task.project.program;
		const loggedInPersonId = viewer.actualPersonId;
		const canManageProgram = canLoggedInPersonManageProgram(program?.members, loggedInPersonId);

		showModal({
			type: MODAL_TYPE.PROGRAM_BUDGET_ERROR_MESSAGE,
			action: ACTION.ADD_TIME_ENTRY,
			programName: program?.name,
			canManageProgram,
			programPrefix: program?.prefix,
		});

		trackEvent('Program Budget Error Message Modal', 'Shown');
	};

	const handleAddButtonPress = e => {
		setWaitingForMutation(true);

		const dateInvalidReason = validateDateReturnErrorMessage(selectedDate, task, task?.project);
		if (dateInvalidReason) {
			setDateError(dateInvalidReason);
		} else {
			setDateError('');
		}

		const noteInvalid = isTimeRegistrationNoteInvalid(notes);
		if (noteInvalid) {
			setNoteError(true);
		}

		if (dateInvalidReason || noteInvalid) {
			setWaitingForMutation(false);
			return;
		}

		trackEvent('New Time Entry Add Button', 'Clicked', {
			taskId: task.companyTaskId,
			taskType: !!task.parentTaskId ? 'subTask' : 'task',
			projectId: task.project.companyProjectId,
			timeRegPerson: 'myself',
			date: selectedDate.format('YYYY-MM-DD'),
			notes: notes.length ? notes : '',
			role: selectedRole?.name,
		});

		const onSuccess = () => {
			createToast({
				duration: 5000,
				message: formatMessage({id: 'common.time_entry_added'}),
			});

			resetFields();
			setWaitingForMutation(false);
			inputRef.current.focus();
		};

		const handleResult = result => {
			if (result.createTimeReg.errors && result.createTimeReg.errors.length === 1) {
				setWaitingForMutation(false);
				if (result.createTimeReg.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD') {
					showModal({
						type: MODAL_TYPE.WARNING_DATE_LOCKED,
					});
				} else if (Util.checkForSageErrorAndShowModal(result.createTimeReg.errors, false)) {
				} else if (result.createTimeReg.errors[0] === 'PROGRAM_REVENUE_LOCKED') {
					showProgramRevenueLockedMessage(task.project.companyProjectId);
				}
			} else {
				updateTaskForScoping(task?.id, onSuccess);
			}
		};

		if (!isNaN(inputValue) && inputValue !== null && inputValue !== '') {
			const billableMinutesRegistered = targetEntityBillable ? billableTime : undefined;

			Util.CommitSchedulingModalUpdate(
				CreateTimeRegistration,
				{
					viewer: viewer,
					projectId: task.project.id,
					taskId: task.id,
					subTask: !!task.parentTaskId,
					source: 'Task-modal',
					idleTimeId: null,
					minutesRegistered: inputValue,
					billableMinutesRegistered: billableMinutesRegistered,
					day: selectedDate.date(),
					month: selectedDate.month() + 1,
					year: selectedDate.year(),
					personId: viewer.actualPersonId,
					notes: notes.length ? notes : null,
					harvestTaskId: selectedHarvestTask ? selectedHarvestTask.value : null,
					unit4ActivityId: selectedUnit4Activity ? selectedUnit4Activity.value : null,
					unit4ActivityName: selectedUnit4Activity ? selectedUnit4Activity.label : null,
					roleId: selectedRole?.id,
				},
				handleResult
			);
		} else {
			setWaitingForMutation(false);
			inputRef.current.focus();
		}
	};

	const handleAddButtonEnterPress = e => {
		if (e.key === 'Enter') handleAddButtonPress();
	};

	const handleCancelButtonEnterPress = e => {
		trackEvent('New Time Entry Cancel Button', 'Clicked');
		if (e.key === 'Enter') onDone();
	};

	const onKeyDown = e => {
		if (e.key === 'Enter') {
			handleAddButtonPress();
		}
	};

	const fetchUnit4Activities = () => {
		//If project dropdown is not empty
		if (task.project) {
			//If a project is selected, show only tasks that belong to that project
			if (task.project.id && task.project.unit4Project) {
				if (unit4Loading === true) return;
				if (unit4Activities.length === 0) {
					setUnit4Loading(true);
				}

				DirectApi.Fetch('unit4_activities/' + task.project.id).then(activities => {
					setUnit4Activities(activities.map(activity => ({label: activity.name, value: activity.id})));
					setUnit4Loading(false);
				});
			} else {
				setUnit4Activities([]);
				setUnit4Loading(false);
			}
		} else {
			setUnit4Activities([]);
			setUnit4Loading(false);
		}
	};

	function getPersonFromViewer() {
		return {
			id: viewer.actualPersonId,
			startDate: viewer.startDate,
			endDate: viewer.endDate,
			createdAt: viewer.createdAt,
			monday: viewer.monday,
			tuesday: viewer.tuesday,
			wednesday: viewer.wednesday,
			thursday: viewer.thursday,
			friday: viewer.friday,
			saturday: viewer.saturday,
			sunday: viewer.sunday,
		};
	}

	const isDateInvalid = date => {
		const datesObject = {
			date: createDateFromMoment(date),
			...getTaskDates(task),
			...getProjectDates(task.project),
		};
		return getIsDateDisabledForTaskAndProject(datesObject);
	};

	return (
		<TimeRegContainer isTaskModal={isTaskModal}>
			<div className="popup-wrapper">
				<div className="entry-wrapper">
					<div className="title">{formatMessage({id: 'common.new_time_entry_popup_title'})}</div>
				</div>
				<div style={{display: 'flex', gap: '24px'}}>
					<div className="entry-wrapper">
						<TimeRegWSuggestionsInput
							id={id}
							initialValue={inputValue ? inputValue / 60 : null}
							onInputChange={value => {
								setValueToInput(value);
							}}
							date={selectedDate}
							task={task}
							personId={viewer.actualPersonId}
							person={getPersonFromViewer()}
							innerRef={div => (inputRef.current = div)}
							parentKeyDown={e => onKeyDown(e)}
							clearable
							isTimePage={true}
						/>
					</div>
					{billableSplitAllowed && (
						<div className="entry-wrapper">
							<div className="input-title">
								<LabelWithTooltip label={'Billable time'} isTimePage={true}>
									<TimeRegGranularityInfoIcon />
								</LabelWithTooltip>
							</div>

							<div style={{width: '90px'}}>
								<HoursInput
									customClassName={'hour-input'}
									cy={'new-billable-time-entry-input'}
									value={billableTimeInputValue}
									mutation={onBillableHoursInputChange}
									disabled={!targetEntityBillable}
									replicateDesignSystem={false}
									granularityFormatter={(val, intl, minuteLimit, showAsterisk) =>
										convertIntoFloatHoursFormatWithGranularity(
											val,
											intl,
											minuteLimit,
											showAsterisk,
											workingHoursForDay
										)
									}
									isTimeregInput
								/>
							</div>
						</div>
					)}
				</div>
				<RemainingTimeAndError
					remainingInfoMessage={remainingInfoMessage}
					timeError={timeError}
					marginTop={'0'}
					marginBottom={'12px'}
					textSize={'2'}
				/>
				{task.project && task.project.harvestProject && viewer.harvestUser && viewer.company.harvestEnabled ? (
					<div className="entry-wrapper">
						<Dropdown
							inputClassName="harvest-dropdown"
							focusOnClick={true}
							options={harvestTaskOptions || []}
							onChange={handleHarvestTaskChange}
							value={selectedHarvestTask ? selectedHarvestTask.value : ''}
							label={formatMessage({id: 'card_modal.harvest_task'})}
							multi={false}
							clearable={true}
							customHeight={30}
							placeholder={formatMessage({id: 'card_modal.harvest_task_placeholder'})}
						/>
					</div>
				) : null}
				{task.project &&
				task.project.unit4Project &&
				task.project.unit4Project.activitiesEnabled &&
				viewer.unit4User &&
				viewer.company.unit4Enabled ? (
					<div className="entry-wrapper">
						<DropdownV2
							value={selectedUnit4Activity ? selectedUnit4Activity.value : null}
							options={unit4Activities}
							onChange={handleUnit4ActivityChange}
							label={formatMessage({id: 'integrations.unit4_activity'})}
							placeholder={formatMessage({id: 'integrations.unit4_activity_placeholder'})}
							customHeight={30}
							onClick={fetchUnit4Activities}
							integrationDropdown={true}
							integrationLoading={unit4Loading}
							integrationRetryToFetch={fetchUnit4Activities}
						/>
					</div>
				) : null}
				<div className="entry-wrapper">
					<div className="datepicker">
						<div className="input-title">
							<LabelWithTooltip
								label={formatMessage({id: 'common.date'})}
								alwaysShowTooltip={!!dateInfo}
								isTimePage={true}
							>
								<DateRestrictionInfoIcon message={dateInfo} />
							</LabelWithTooltip>
						</div>
						<DatePicker
							startDate={selectedDate}
							handleDateRangeChange={handleDateSelect}
							datePickerStyle={DATE_PICKER_STYLE.STANDARD}
							isSingleDatePicker={true}
							disabled={false}
							startDateLimite={lockedDate && lockedDate.clone().add(1, 'd')}
							disabledDates={isDateInvalid}
						/>
					</div>
					<DateInvalidError dateError={dateError} />
				</div>
				<div className="entry-wrapper">
					<div className="notes">
						<div className="input-title">
							{noteRequired ? (
								<LabelWithTooltip label={'notes'} isRequired>
									<NoteRequiredInfoIcon />
								</LabelWithTooltip>
							) : (
								formatMessage({id: 'common.notes'})
							)}
						</div>
						<textarea
							value={notes}
							maxLength={viewer.company.characterLimit > -1 ? viewer.company.characterLimit : 999}
							rows={5}
							cols={40}
							placeholder={formatMessage({id: 'overview_time.write_your_notes'})}
							onChange={handleNotesChange.bind(this)}
						/>
						{noteError ? <NoteRequiredError /> : null}
					</div>
				</div>
				{rolesAllowed ? (
					<div className="entry-wrapper">
						<div className="roles">
							<RoleDropdownRelayWrapper
								onSelect={role => setSelectedRole(role)}
								projectId={task.project.id}
								taskId={task.id}
								personId={viewer.actualPersonId}
								showLabel={true}
							/>
						</div>
					</div>
				) : null}
				<div className="buttons">
					<Button
						text={formatMessage({id: 'common.cancel'})}
						isDisabled={waitingForMutation}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={BUTTON_COLOR.WHITE}
						onKeyDown={handleCancelButtonEnterPress}
						onClick={handleCancelButtonPress}
						focusOnMouseDown={true} // need to focus on the button when clicking down to trigger the blur effect on the input to tranform string to hours amount
						focusOnClick={true}
					/>
					<Button
						text={<Spinner spinning={spinning} children={formatMessage({id: 'common.add'})} />}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={BUTTON_COLOR.GREEN}
						onClick={handleAddButtonPress}
						onKeyDown={handleAddButtonEnterPress}
						focusOnMouseDown={true}
						focusOnClick={true}
						cy="task-modal-entries-button-add-button"
						isDisabled={
							(task.project &&
								task.project.harvestProject &&
								viewer.company.harvestEnabled &&
								viewer.harvestUser &&
								!selectedHarvestTask) ||
							!!timeError ||
							waitingForMutation
						}
					/>
				</div>
			</div>
		</TimeRegContainer>
	);
};

TimeReg.propTypes = {
	onDone: PropTypes.func,
	viewer: PropTypes.any.isRequired,
	task: PropTypes.any.isRequired,
	harvestTaskOptions: PropTypes.array,
	unit4ActivityOptions: PropTypes.array,
};

TimeReg.defaultProps = {
	onDone: () => true,
};
export default injectIntl(TimeReg);
