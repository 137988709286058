import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';

const UtilizationReportPeriodDepartmentLoader = ({relay, viewer, children, ...props}) => {
	const formatData = () => {
		return viewer.company.resourceBasedDepartments.edges.map(department => {
			const {id, name, resourceNumbers} = department.node;
			return {
				department: {
					id,
					name,
				},
				resourceNumbers: {
					...resourceNumbers,
				},
			};
		});
	};

	return children({data: {rows: formatData()}, ...props});
};

export const utilizationReportPeriodDepartmentLoaderQuery = graphql`
	query UtilizationReportPeriodDepartmentLoader_Query(
		$searchQuery: TaskSearchQueryType
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$pageSize: Int
	) {
		viewer {
			actualPersonId
			component(name: "utilization_period_person_loader")
			...UtilizationReportPeriodDepartmentLoader_viewer
				@arguments(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					pageSize: $pageSize
				)
		}
	}
`;

export default injectIntl(
	withRouter(
		createRefetchContainer(
			UtilizationReportPeriodDepartmentLoader,
			{
				viewer: graphql`
					fragment UtilizationReportPeriodDepartmentLoader_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						startYear: {type: "Int"}
						startMonth: {type: "Int"}
						startDay: {type: "Int"}
						endYear: {type: "Int"}
						endMonth: {type: "Int"}
						endDay: {type: "Int"}
						pageSize: {type: "Int"}
					) {
						company {
							resourceBasedDepartments(
								first: $pageSize
								searchQuery: $searchQuery
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
							) {
								edges {
									node {
										id
										name
										resourceNumbers {
											# Actuals
											actualBillableProjectMinutes
											actualNonBillableProjectMinutes
											actualInternalMinutes
											actualTotalMinutes
											actualOverMinutes
											actualDoneTaskMinutes
											actualBillableUtilization
											actualNonBillableUtilization
											actualInternalUtilization
											actualResourceUtilization

											# Plan
											plannedBillableProjectMinutes
											plannedBillableProjectMinutesWin
											plannedBillableProjectMinutesSoft
											plannedBillableProjectMinutesSoftWin
											plannedBillableProjectMinutesHard
											plannedNonBillableProjectMinutes
											plannedNonBillableProjectMinutesWin
											plannedNonBillableProjectMinutesSoft
											plannedNonBillableProjectMinutesSoftWin
											plannedNonBillableProjectMinutesHard
											plannedInternalMinutes
											plannedTotalMinutes
											plannedTotalMinutesWin
											plannedTotalMinutesSoft
											plannedTotalMinutesSoftWin
											plannedTotalMinutesHard
											plannedOverMinutes
											plannedOverMinutesWin
											plannedOverMinutesSoft
											plannedOverMinutesSoftWin
											plannedOverMinutesHard
											plannedDoneTaskMinutes
											plannedBillableUtilization
											plannedBillableUtilizationWin
											plannedBillableUtilizationSoft
											plannedBillableUtilizationSoftWin
											plannedBillableUtilizationHard
											plannedNonBillableUtilization
											plannedNonBillableUtilizationWin
											plannedNonBillableUtilizationSoft
											plannedNonBillableUtilizationSoftWin
											plannedNonBillableUtilizationHard
											plannedInternalUtilization
											plannedResourceUtilization
											plannedResourceUtilizationWin
											plannedResourceUtilizationSoft
											plannedResourceUtilizationSoftWin
											plannedResourceUtilizationHard
											plannedBillableTaskAndAllocationsCombined
											plannedNonBillableTaskAndAllocationsCombined
											plannedInternalTimeTaskAndAllocationsCombined
											plannedTotalTimeTaskAndAllocationsCombined
											plannedBillableUtilizationTaskAndAllocationsCombined
											plannedNonBillableUtilizationTaskAndAllocationsCombined
											plannedInternalUtilizationTaskAndAllocationsCombined
											plannedResourceUtilizationTaskAndAllocationsCombined
											plannedBillableTaskAndAllocationsCombinedWin
											plannedNonBillableTaskAndAllocationsCombinedWin
											plannedTotalTimeTaskAndAllocationsCombinedWin
											plannedBillableUtilizationTaskAndAllocationsCombinedWin
											plannedNonBillableUtilizationTaskAndAllocationsCombinedWin
											plannedResourceUtilizationTaskAndAllocationsCombinedWin
											plannedBillableTaskAndAllocationsCombinedSoft
											plannedNonBillableTaskAndAllocationsCombinedSoft
											plannedTotalTimeTaskAndAllocationsCombinedSoft
											plannedBillableUtilizationTaskAndAllocationsCombinedSoft
											plannedNonBillableUtilizationTaskAndAllocationsCombinedSoft
											plannedResourceUtilizationTaskAndAllocationsCombinedSoft
											plannedBillableTaskAndAllocationsCombinedSoftWin
											plannedNonBillableTaskAndAllocationsCombinedSoftWin
											plannedTotalTimeTaskAndAllocationsCombinedSoftWin
											plannedBillableUtilizationTaskAndAllocationsCombinedSoftWin
											plannedNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
											plannedResourceUtilizationTaskAndAllocationsCombinedSoftWin
											plannedBillableTaskAndAllocationsCombinedHard
											plannedNonBillableTaskAndAllocationsCombinedHard
											plannedTotalTimeTaskAndAllocationsCombinedHard
											plannedBillableUtilizationTaskAndAllocationsCombinedHard
											plannedNonBillableUtilizationTaskAndAllocationsCombinedHard
											plannedResourceUtilizationTaskAndAllocationsCombinedHard
											remainingBillableTaskAndAllocationsCombinedWin
											remainingNonBillableTaskAndAllocationsCombinedWin
											remainingTotalTimeTaskAndAllocationsCombinedWin
											remainingBillableUtilizationTaskAndAllocationsCombinedWin
											remainingNonBillableUtilizationTaskAndAllocationsCombinedWin
											remainingResourceUtilizationTaskAndAllocationsCombinedWin
											remainingBillableTaskAndAllocationsCombinedSoft
											remainingNonBillableTaskAndAllocationsCombinedSoft
											remainingTotalTimeTaskAndAllocationsCombinedSoft
											remainingBillableUtilizationTaskAndAllocationsCombinedSoft
											remainingNonBillableUtilizationTaskAndAllocationsCombinedSoft
											remainingResourceUtilizationTaskAndAllocationsCombinedSoft
											remainingBillableTaskAndAllocationsCombinedSoftWin
											remainingNonBillableTaskAndAllocationsCombinedSoftWin
											remainingTotalTimeTaskAndAllocationsCombinedSoftWin
											remainingBillableUtilizationTaskAndAllocationsCombinedSoftWin
											remainingNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
											remainingResourceUtilizationTaskAndAllocationsCombinedSoftWin
											remainingBillableTaskAndAllocationsCombinedHard
											remainingNonBillableTaskAndAllocationsCombinedHard
											remainingTotalTimeTaskAndAllocationsCombinedHard
											remainingBillableUtilizationTaskAndAllocationsCombinedHard
											remainingNonBillableUtilizationTaskAndAllocationsCombinedHard
											remainingResourceUtilizationTaskAndAllocationsCombinedHard
											forecastBillableTaskAndAllocationsCombinedWin
											forecastNonBillableTaskAndAllocationsCombinedWin
											forecastTotalTimeTaskAndAllocationsCombinedWin
											forecastBillableUtilizationTaskAndAllocationsCombinedWin
											forecastNonBillableUtilizationTaskAndAllocationsCombinedWin
											forecastResourceUtilizationTaskAndAllocationsCombinedWin
											forecastBillableTaskAndAllocationsCombinedSoft
											forecastNonBillableTaskAndAllocationsCombinedSoft
											forecastTotalTimeTaskAndAllocationsCombinedSoft
											forecastBillableUtilizationTaskAndAllocationsCombinedSoft
											forecastNonBillableUtilizationTaskAndAllocationsCombinedSoft
											forecastResourceUtilizationTaskAndAllocationsCombinedSoft
											forecastBillableTaskAndAllocationsCombinedSoftWin
											forecastNonBillableTaskAndAllocationsCombinedSoftWin
											forecastTotalTimeTaskAndAllocationsCombinedSoftWin
											forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin
											forecastNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
											forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin
											forecastBillableTaskAndAllocationsCombinedHard
											forecastNonBillableTaskAndAllocationsCombinedHard
											forecastTotalTimeTaskAndAllocationsCombinedHard
											forecastBillableUtilizationTaskAndAllocationsCombinedHard
											forecastNonBillableUtilizationTaskAndAllocationsCombinedHard
											forecastResourceUtilizationTaskAndAllocationsCombinedHard

											# Actuals vs. Plan
											billableActualVsPlan
											billableActualVsPlanWin
											billableActualVsPlanSoft
											billableActualVsPlanSoftWin
											billableActualVsPlanHard
											nonBillableActualVsPlan
											nonBillableActualVsPlanWin
											nonBillableActualVsPlanSoft
											nonBillableActualVsPlanSoftWin
											nonBillableActualVsPlanHard
											internalActualVsPlan
											totalActualVsPlan
											totalActualVsPlanWin
											totalActualVsPlanSoft
											totalActualVsPlanSoftWin
											totalActualVsPlanHard

											# Remaining
											remainingBillableProjectMinutes
											remainingBillableProjectMinutesWin
											remainingBillableProjectMinutesSoft
											remainingBillableProjectMinutesSoftWin
											remainingBillableProjectMinutesHard
											remainingNonBillableProjectMinutes
											remainingNonBillableProjectMinutesWin
											remainingNonBillableProjectMinutesSoft
											remainingNonBillableProjectMinutesSoftWin
											remainingNonBillableProjectMinutesHard
											remainingInternalMinutes
											remainingTotalMinutes
											remainingTotalMinutesWin
											remainingTotalMinutesSoft
											remainingTotalMinutesSoftWin
											remainingTotalMinutesHard
											remainingOverMinutes
											remainingBillableUtilization
											remainingBillableUtilizationWin
											remainingBillableUtilizationSoft
											remainingBillableUtilizationSoftWin
											remainingBillableUtilizationHard
											remainingNonBillableUtilization
											remainingNonBillableUtilizationWin
											remainingNonBillableUtilizationSoft
											remainingNonBillableUtilizationSoftWin
											remainingNonBillableUtilizationHard
											remainingInternalUtilization
											remainingResourceUtilization
											remainingResourceUtilizationWin
											remainingResourceUtilizationSoft
											remainingResourceUtilizationSoftWin
											remainingResourceUtilizationHard
											remainingBillableTaskAndAllocationsCombined
											remainingNonBillableTaskAndAllocationsCombined
											remainingInternalTimeTaskAndAllocationsCombined
											remainingTotalTimeTaskAndAllocationsCombined
											remainingBillableUtilizationTaskAndAllocationsCombined
											remainingBillableUtilizationTaskAndAllocationsCombined
											remainingInternalUtilizationTaskAndAllocationsCombined
											remainingResourceUtilizationTaskAndAllocationsCombined

											# Forecast
											forecastBillableProjectMinutes
											forecastBillableProjectMinutesWin
											forecastBillableProjectMinutesSoft
											forecastBillableProjectMinutesSoftWin
											forecastBillableProjectMinutesHard
											forecastNonBillableProjectMinutes
											forecastNonBillableProjectMinutesWin
											forecastNonBillableProjectMinutesSoft
											forecastNonBillableProjectMinutesSoftWin
											forecastNonBillableProjectMinutesHard
											forecastInternalMinutes
											forecastTotalMinutes
											forecastTotalMinutesWin
											forecastTotalMinutesSoft
											forecastTotalMinutesSoftWin
											forecastTotalMinutesHard
											forecastOverMinutes
											forecastOverMinutesWin
											forecastOverMinutesSoft
											forecastOverMinutesSoftWin
											forecastOverMinutesHard
											forecastBillableUtilization
											forecastBillableUtilizationWin
											forecastBillableUtilizationSoft
											forecastBillableUtilizationSoftWin
											forecastBillableUtilizationHard
											forecastNonBillableUtilization
											forecastNonBillableUtilizationWin
											forecastNonBillableUtilizationSoft
											forecastNonBillableUtilizationSoftWin
											forecastNonBillableUtilizationHard
											forecastInternalUtilization
											forecastResourceUtilization
											forecastResourceUtilizationWin
											forecastResourceUtilizationSoft
											forecastResourceUtilizationSoftWin
											forecastResourceUtilizationHard
											forecastBillableTaskAndAllocationsCombined
											forecastNonBillableTaskAndAllocationsCombined
											forecastInternalTimeTaskAndAllocationsCombined
											forecastTotalTimeTaskAndAllocationsCombined
											forecastBillableUtilizationTaskAndAllocationsCombined
											forecastNonBillableUtilizationTaskAndAllocationsCombined
											forecastInternalUtilizationTaskAndAllocationsCombined
											forecastResourceUtilizationTaskAndAllocationsCombined

											# Tasks estimates (Planned)
											tasksEstimateBillableMinutes
											tasksEstimateNonBillableMinutes
											tasksEstimateTotalMinutes
											tasksBillableUtilization
											tasksNonBillableUtilization
											tasksResourceUtilization
											tasksOverMinutes

											# Tasks Remaining
											tasksRemainingBillableMinutes
											tasksRemainingNonBillableMinutes
											tasksRemainingTotalMinutes
											tasksRemainingOverMinutes
											tasksRemainingBillableUtilization
											tasksRemainingNonBillableUtilization
											tasksRemainingResourceUtilization

											# Tasks Forecast
											tasksForecastBillableMinutes
											tasksForecastNonBillableMinutes
											tasksForecastTotalMinutes
											tasksForecastOverMinutes
											tasksForecastBillableUtilization
											tasksForecastNonBillableUtilization
											tasksForecastResourceUtilization

											# Tasks Actual vs. Plan
											tasksBillableActualVsPlan
											tasksNonBillableActualVsPlan
											tasksTotalActualVsPlan

											# Planned Allocation vs. Task
											plannedBillableAllocationVsTask
											plannedBillableAllocationVsTaskWin
											plannedBillableAllocationVsTaskSoft
											plannedBillableAllocationVsTaskSoftWin
											plannedBillableAllocationVsTaskHard
											plannedNonBillableAllocationVsTask
											plannedNonBillableAllocationVsTaskWin
											plannedNonBillableAllocationVsTaskSoft
											plannedNonBillableAllocationVsTaskSoftWin
											plannedNonBillableAllocationVsTaskHard
											plannedAllocationVsTask
											plannedAllocationVsTaskWin
											plannedAllocationVsTaskSoft
											plannedAllocationVsTaskSoftWin
											plannedAllocationVsTaskHard

											# Remaining Allocation vs. Task
											remainingBillableAllocationVsTask
											remainingBillableAllocationVsTaskWin
											remainingBillableAllocationVsTaskSoft
											remainingBillableAllocationVsTaskSoftWin
											remainingBillableAllocationVsTaskHard
											remainingNonBillableAllocationVsTask
											remainingNonBillableAllocationVsTaskWin
											remainingNonBillableAllocationVsTaskSoft
											remainingNonBillableAllocationVsTaskSoftWin
											remainingNonBillableAllocationVsTaskHard
											remainingAllocationVsTask
											remainingAllocationVsTaskWin
											remainingAllocationVsTaskSoft
											remainingAllocationVsTaskSoftWin
											remainingAllocationVsTaskHard

											# Availability
											workingMinutes
											timeOffMinutes
											availableMinutes

											#Performance
											performance
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query UtilizationReportPeriodDepartmentLoaderRefetchQuery(
					$pageSize: Int
					$startYear: Int
					$startMonth: Int
					$startDay: Int
					$endYear: Int
					$endMonth: Int
					$endDay: Int
					$searchQuery: TaskSearchQueryType
				) {
					viewer {
						...UtilizationReportPeriodDepartmentLoader_viewer
							@arguments(
								pageSize: $pageSize
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								searchQuery: $searchQuery
							)
					}
				}
			`
		)
	)
);
