import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import RichTextItem from '../../../components/richtext_item';
import {injectIntl} from 'react-intl';

class freeTextFieldComponent extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}
	render() {
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};
		return (
			<div className="insight-free-text-component">
				<RichTextItem
					alwaysShowControls={false}
					text={configObject.text ? configObject.text : null}
					placeholder={this.props.intl.formatMessage({id: 'insight.free_text_field_placeholder'})}
					readOnly={true}
					fixedHeight={true}
				/>
			</div>
		);
	}
}

const freeTextFieldComponentQuery = graphql`
	query freeTextFieldComponent_Query {
		viewer {
			actualPersonId
			component(name: "insight_free_text")
			...freeTextFieldComponent_viewer
		}
	}
`;

export {freeTextFieldComponentQuery};

export default injectIntl(
	createFragmentContainer(freeTextFieldComponent, {
		viewer: graphql`
			fragment freeTextFieldComponent_viewer on Viewer {
				id
			}
		`,
	})
);
