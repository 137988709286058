import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {Button, NewTabIcon} from 'web-components';
import Util from '../../forecast-app/shared/util/util';
import CreateSisenseUserMutation from '../../mutations/CreateSisenseUserMutation';
import CheckSisenseSeatsMutation from '../../mutations/CheckSisenseSeatsMutation';
import {getModuleEndDate, hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import {BUTTON_COLOR, BUTTON_STYLE, MODULE_TYPES} from '../../constants';
import {hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {hideLoader, showLoader} from '../../containers/global_loader';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import EmptyState from '../../forecast-app/shared/components/empty-states/empty_state';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import Person from '../../forecast-app/shared/components/person/person';
import link from '../../images/v2/components/link.svg';
import {CSS_CONSTANTS} from '../../css_variables';
import moment from 'moment';
import warning from '../../images/warning.svg';
import {profilePicSrc} from '../../directApi';
import {useTrackPage} from '../../tracking/amplitude/hooks/useTrackPage';

const AdvancedAnalyticsPageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;
const AdvancedAnalyticsHeader = styled.div`
	flex: 0 0 68px;
`;
const AdvancedAnalyticsContent = styled.div`
	display: flex;
	flex-direction: row;
	align-self: stretch;
	flex: auto;
	border-top: 1px solid #e6e6ed;
`;
const SidePanel = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 0 0 333px;
	padding: 28px 35px 28px 35px;
	border-left: 1px solid #e6e6ed;
`;
const SidePanelTrialWarning = styled.div`
	margin-bottom: 32px;
	font-size: 12px;
	font-weight: 400;
	border: 1px solid #e57700;
	border-radius: 4px;
	padding: 4px 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 32px;
	gap: 8px;
`;
const SidePanelTrialWarningIcon = styled.div`
	width: 18px;
	height: 18px;
	background-size: 18px;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(${warning});
`;
const SidePanelSection = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: ${props => props.paddingTop}px;
	color: #4c4c58;
	line-height: 20px;
`;
const SidePanelTitle = styled.div`
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 12px;
`;
const SidePanelText = styled.div`
	font-size: 13px;
`;
const DashboardsSection = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background: #f7f7fe;
	padding: 32px 26px 32px 32px;
`;
const DashboardsTitle = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.2px;
	color: #4c4c58;
	margin-bottom: 24px;
`;
const DashboardsContent = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: auto;
	align-content: flex-start;
	gap: 16px;
`;
const Dashboard = styled.a`
	position: relative;
	flex: 0 0 250px;
	max-width: 250px;
	height: 150px;
	background: white;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px 16px 20px 24px;
	box-shadow: 0px 3px 8px rgba(26, 26, 51, 0.11);
	border-radius: 6px;
	color: #4c4c58;
	cursor: pointer;
	text-decoration: none;

	&:hover,
	&:focus {
		box-shadow: 0px 3px 4px rgba(26, 26, 51, 0.02), 0px 8px 18px rgba(26, 26, 51, 0.14);
	}
`;
const DashboardTitle = styled.div`
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.2px;
	white-space: nowrap;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis;

	${Dashboard}:hover &, ${Dashboard}:focus & {
		max-width: 90%;
	}
`;
const DashboardLastEdit = styled.div`
	font-size: 11px;
	line-height: 16px;
	flex: 1 0 auto;
`;

const NewTabIconWrapper = styled.div`
	position: absolute;
	visibility: hidden;
	width: 24px;
	height: 24px;
	right: 16px;
	top: 16px;

	${Dashboard}:hover &, ${Dashboard}:focus & {
		visibility: visible;
	}
`;

const PersonWrapper = styled.div`
	.name-inner {
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		line-height: 20px;
		color: #4c4c58;
	}
`;

const LinkIcon = styled.div`
	margin-left: 8px;
	height: 19px;
	width: 18px;
	background: url(${link});
	background-size: 18px;
	background-position: center;
	background-repeat: no-repeat;
	display: none;
`;

const FeedbackLink = styled.a`
	display: flex;
	font-size: 13px;
	font-weight: 400;
	color: ${CSS_CONSTANTS.v2_branding_purple};
	text-decoration: none;
	margin-top: 12px;

	&:hover {
		color: #6f49c5;

		${LinkIcon} {
			display: block;
		}
	}
`;

export const CreateUserErrorMessage = errors => {
	let errorMessage = '';
	if (errors.includes('NOT_ENOUGH_VIEWER_SEATS')) {
		errorMessage = 'Your company does not have any more Advanced Analytics viewer seats. Please contact an administrator.';
	} else if (errors.includes('NOT_ENOUGH_DESIGNER_SEATS')) {
		errorMessage =
			'Your company does not have any more Advanced Analytics designer seats. Please contact an administrator.';
	} else if (errors.includes('ERROR_CREATING_USER')) {
		errorMessage =
			'An error occurred when trying to create a new user in Advanced Analytics. Please contact an administrator.';
	} else if (errors.includes('USER_DOES_NOT_HAVE_PERMISSION')) {
		errorMessage =
			'You do not have the required permissions to access Advanced Analytics. Please contact an administrator.';
	}
	return errorMessage;
};

export const AdvancedAnalyticsPage = ({viewer}) => {
	const intl = useIntl();
	const [dashboards, setDashboards] = useState([]);
	const [usedDesignerSeats, setUsedDesignerSeats] = useState('');
	const [usedViewerSeats, setUsedViewerSeats] = useState('');

	useEffect(() => {
		document.title = `Advanced Analytics - Forecast`;
	});
	useTrackPage('Advanced Analytics');

	const sendToSisense = () => {
		window.open(
			process.env.CIRCLE_BRANCH === 'production'
				? 'https://forecast.sisense.com/app/main#/home'
				: 'https://forecast-dev.sisense.com/app/main#/home',
			'_blank'
		);
	};

	const checkUser = () => {
		// Check if users is Sisense user
		if (viewer.sisenseUserId) {
			// Send to Sisense
			sendToSisense();
		} else {
			// Attempt to create Sisense user
			showLoader();
			const input = {
				email: viewer.email,
				firstName: viewer.firstName,
				lastName: viewer.lastName,
				type: hasPermission(PERMISSION_TYPE.SISENSE_CREATE) ? 'designer' : 'viewer',
			};
			const onSuccess = response => {
				hideLoader();
				const {errors} = response.createSisenseUser;
				if (errors?.length) {
					let errorMessage = CreateUserErrorMessage(errors);
					showModal({
						type: MODAL_TYPE.WARNING,
						warningMessageId: 'common.error_colon',
						warningInformation: [errorMessage],
						useInfoIcon: true,
						buttons: [
							{
								text: intl.formatMessage({id: 'common.ok'}),
								style: BUTTON_STYLE.FILLED,
								color: BUTTON_COLOR.WHITE,
							},
						],
					});
				} else {
					sendToSisense();
				}
			};
			const onError = () => {
				hideLoader();
				//TODO: Display error
			};
			Util.CommitMutation(CreateSisenseUserMutation, input, onSuccess, false, null, onError);
		}
	};

	const getDashboardHref = id => {
		return process.env.CIRCLE_BRANCH === 'production'
			? `https://forecast.sisense.com/app/main#/dashboards/${id}`
			: `https://forecast-dev.sisense.com/app/main#/dashboards/${id}`;
	};

	const getDashBoards = () => {
		let queryParams = '?fields=title,owner&expand=owner';
		const apiUrl =
			process.env.CIRCLE_BRANCH === 'production'
				? `https://forecast.sisense.com/api/v1/dashboards${queryParams}`
				: `https://forecast-dev.sisense.com/api/v1/dashboards${queryParams}`;

		fetch(apiUrl, {credentials: 'include'})
			.then(result => {
				if (result.status === 200) {
					return result.json();
				}
				return [];
			})
			.then(dashboards => {
				setDashboards(dashboards);
			})
			.catch(e => {
				// error
			});
	};

	useEffect(() => {
		// Fetch Sisense dashboards for logged in user
		getDashBoards();
		// Fetch Used Seats
		const onSuccess = response => {
			const {designerSeats, viewerSeats} = response.checkSisenseSeats;
			setUsedDesignerSeats(designerSeats);
			setUsedViewerSeats(viewerSeats);
		};
		Util.CommitMutation(CheckSisenseSeatsMutation, {}, onSuccess, false);
	}, []);

	const moduleEndDate = getModuleEndDate(MODULE_TYPES.SISENSE);
	let endText = '';
	if (moduleEndDate && moduleEndDate.endYear) {
		const endTime = moment
			.utc({y: moduleEndDate.endYear, M: moduleEndDate.endMonth - 1, d: moduleEndDate.endDay})
			.add(1, 'day');
		endText =
			endTime.diff(moment.utc(), 'days') === 0
				? 'Your trial ends today'
				: 'Your trial ends in ' + (endTime.diff(moment.utc(), 'days') + 1) + ' days';
	}

	return hasModule(MODULE_TYPES.SISENSE) ? (
		<AdvancedAnalyticsPageWrapper>
			<AdvancedAnalyticsHeader>
				<TopHeaderBarWrapper>
					<TopHeaderBar title="Advanced Analytics" />
				</TopHeaderBarWrapper>
			</AdvancedAnalyticsHeader>
			<AdvancedAnalyticsContent>
				<DashboardsSection>
					{viewer.sisenseUserId ? (
						<>
							<DashboardsTitle>Dashboards</DashboardsTitle>
							<DashboardsContent>
								{dashboards.map(dashboard => {
									const forecastOwner = viewer.company.allPersons.edges.find(
										edge => edge.node.sisenseUserId === dashboard.owner._id
									);
									return (
										<Dashboard href={getDashboardHref(dashboard.oid)} target="_blank" key={dashboard.oid}>
											<DashboardTitle>{dashboard.title}</DashboardTitle>
											<NewTabIconWrapper>
												<NewTabIcon color="#4C4C58" />
											</NewTabIconWrapper>
											<DashboardLastEdit>{`Last published ${
												dashboard.lastPublish
													? intl.formatDate(Date.parse(dashboard.lastPublish), {
															day: 'numeric',
															month: 'short',
															year: 'numeric',
													  })
													: 'N/A'
											}`}</DashboardLastEdit>
											<PersonWrapper>
												<Person
													name={
														forecastOwner?.fullName ||
														`${dashboard.owner.firstName} ${dashboard.owner.lastName || ''}`
													}
													imageSize="new-ui-dropdown"
													imageSrc={profilePicSrc(forecastOwner?.profilePictureId)}
													showName={true}
													showRole={false}
												/>
											</PersonWrapper>
										</Dashboard>
									);
								})}
							</DashboardsContent>
						</>
					) : (
						<EmptyState pageName={EmptyState.EMPTY_STATE.SISENSE_NO_USER} />
					)}
				</DashboardsSection>
				<SidePanel>
					{endText && (
						<SidePanelTrialWarning>
							<SidePanelTrialWarningIcon />
							<div>{endText}</div>
						</SidePanelTrialWarning>
					)}
					<Button
						onClick={checkUser}
						size={Button.SIZE.STANDARD}
						variant={Button.VARIANT.PURPLE_FILLED}
						iconPosition="last"
						icon={color => <NewTabIcon color={color} />}
					>
						Go to Analytics
					</Button>
					<SidePanelSection paddingTop={32}>
						<SidePanelTitle>Plan</SidePanelTitle>
						<SidePanelText>
							You are currently using
							<br />
							<b>{`${usedDesignerSeats} out of ${
								viewer.company.sisenseDesignerSeats || 0
							} available designer seats.`}</b>
							<br />
							<b>{`${usedViewerSeats} out of ${
								viewer.company.sisenseViewerSeats || 0
							} avaliable viewer seats.`}</b>
						</SidePanelText>
					</SidePanelSection>
					<FeedbackLink
						href="https://www.forecast.app/feedback-advanced-analytics"
						target="_blank"
						rel="noopener noreferrer"
					>
						Give feedback
						<LinkIcon />
					</FeedbackLink>
				</SidePanel>
			</AdvancedAnalyticsContent>
		</AdvancedAnalyticsPageWrapper>
	) : null;
};

export const AdvancedAnalyticsQuery = graphql`
	query AdvancedAnalyticsPage_Query {
		viewer {
			actualPersonId
			component(name: "advanced-analytics")
			...AdvancedAnalyticsPage_viewer
		}
	}
`;

export default createFragmentContainer(AdvancedAnalyticsPage, {
	viewer: graphql`
		fragment AdvancedAnalyticsPage_viewer on Viewer {
			sisenseUserId
			email
			firstName
			lastName
			availableFeatureFlags {
				key
			}
			company {
				sisenseViewerSeats
				sisenseDesignerSeats
				allPersons(first: 10000, onlyActive: false, excludeClientUsers: true) {
					edges {
						node {
							id
							fullName
							profilePictureId
							profilePictureDefaultId
							sisenseUserId
						}
					}
				}
			}
		}
	`,
});
