import {getTimestampCorrelationId} from '../canvas-timeline/canvas_timeline_performance_track';
import {fetchDataPromises} from '../scheduling_fetch';
import DirectApi from '../../../directApi';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {getFetchDates} from '../loading/LoadMoreUtil';
import Util from '../../../forecast-app/shared/util/util';

export const fetchData = async (pageComponent, props, companyProjectId, companyProjectGroupId, programPrefix) => {
	let requestUrl = 'scheduling';

	if (props.isProjectTimeline) {
		if (props.programPrefix) {
			requestUrl += `?programPrefix=${props.programPrefix}`;
		} else if (props.groupId) {
			requestUrl += `?companyProjectGroupId=${props.groupId}`;
		} else if (!props.match.params.shareUrl) {
			requestUrl += `?customProjectId=${props.projectId}`;
		} else {
			requestUrl += `?companyProjectId=${props.projectId}`;
		}

		requestUrl += `&timestampCorrelationId=${getTimestampCorrelationId()}`;
	} else {
		requestUrl += `?timestampCorrelationId=${getTimestampCorrelationId()}`;
		requestUrl += `&preventFetchingIdleTimeAllocations=true`;

		if (companyProjectId) {
			requestUrl += `&companyProjectId=${companyProjectId}`;
		} else if (companyProjectGroupId) {
			requestUrl += `&companyProjectGroupId=${companyProjectGroupId}`;
		} else if (programPrefix) {
			requestUrl += `&programPrefix=${programPrefix}`;
		}
	}

	const headers = new Headers();
	headers.append('Content-Type', 'application/json');

	if (props.match.params.shareUrl) {
		headers.append('X-FORECAST-PROJECT-SCHEDULING-SHARE', props.match.params.shareUrl);
	}

	let reqBody = {isProjectTimeline: props.isProjectTimeline};
	if (props.isProjectTimeline && !props.match.params.shareUrl && hasFeatureFlag('incremental_load_more_include_items')) {
		reqBody = {
			...reqBody,
			...getFetchDates(pageComponent),
		};
	}

	const fetchList = [
		DirectApi.Fetch_WithErrorHandling(requestUrl, {
			method: 'POST',
			headers,
			credentials: !props.match.params.shareUrl ? 'include' : undefined,
			body: JSON.stringify(reqBody),
		}).then(response => response.json()),
	];

	const {company} = pageComponent.props.viewer;
	const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(company);

	// If we are showing the project timeline (not in shared view), and we are not showing for a project group, and the project uses task allocations.
	if (props.isProjectTimeline && !isMixedAllocationModeEnabled && !props.match.params.shareUrl && !props.programPrefix) {
		const headers = new Headers();

		headers.append('Content-Type', 'application/json');

		const init = {
			headers,
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify({
				excludedCompanyProjectId: props.projectId,
				excludedCompanyProjectGroupId: props.groupId,
				excludeIdleTimes: true,
			}),
		};

		fetchList.push(
			DirectApi.Fetch_WithErrorHandling('scheduling/heatmap?isProjectTimeline=true', init).then(response =>
				response.json()
			)
		);
	}

	return Promise.all(fetchList);
};

const createNotificationDataByProjectRequest = projectIds => {
	const requestUrl = DirectApi.graphqlServerEndpoint('scheduling/notifications/projects');
	const body = {
		projectIds,
	};

	return {url: requestUrl, method: 'POST', body};
};

const createNotificationDataByAllocationRequest = allocationIds => {
	const requestUrl = DirectApi.graphqlServerEndpoint('scheduling/notifications/allocations');
	const body = {
		allocationIds,
	};

	return {url: requestUrl, method: 'POST', body};
};

export const fetchNotificationData = (projectIds, allocationIds) => {
	const requestUrls = [];
	if (projectIds.length > 0) {
		requestUrls.push(createNotificationDataByProjectRequest(projectIds));
	}
	if (allocationIds.length > 0) {
		requestUrls.push(createNotificationDataByAllocationRequest(allocationIds));
	}

	return fetchDataPromises(requestUrls).then(results => {
		let returnValue = {
			projects: [],
			tasks: [],
			phases: [],
			allocations: [],
			projectPersons: [],
		};
		if (projectIds.length > 0) {
			returnValue = Object.assign(returnValue, results[0]);
		}
		if (allocationIds.length > 0) {
			const index = projectIds.length > 0 ? 1 : 0;
			returnValue = Object.assign(returnValue, results[index]);
		}
		return returnValue;
	});
};

export const fetchLightWeightData = (props, companyProjectId, companyProjectGroupId) => {
	let requestUrl = 'scheduling/projects/minimal';

	if (props.isProjectTimeline) {
		if (props.groupId) {
			requestUrl += `?companyProjectGroupId=${props.groupId}`;
		} else {
			requestUrl += `?customProjectId=${props.projectId}`;
		}
		requestUrl += `&timestampCorrelationId=${getTimestampCorrelationId()}`;
	} else {
		requestUrl += `?timestampCorrelationId=${getTimestampCorrelationId()}`;
		if (companyProjectId) {
			requestUrl += `&companyProjectId=${companyProjectId}`;
		} else if (companyProjectGroupId) {
			requestUrl += `&companyProjectGroupId=${companyProjectGroupId}`;
		}
	}

	const headers = new Headers();
	if (props.match.params.shareUrl) {
		headers.append('X-FORECAST-PROJECT-SCHEDULING-SHARE', props.match.params.shareUrl);
	}
	const fetchList = [
		DirectApi.Fetch_WithErrorHandling(requestUrl, {
			method: 'GET',
			credentials: !props.match.params.shareUrl ? 'include' : undefined,
			headers,
		}).then(response => response.json()),
	];

	// If we are showing the project timeline (not in shared view), and we are not showing for a project group, and the project uses task allocations.
	if (!props.match.params.shareUrl && props.isProjectTimeline) {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		fetchList.push(
			DirectApi.Fetch_WithErrorHandling('scheduling/heatmap', {
				headers,
				method: 'POST',
				credentials: !props.match.params.shareUrl ? 'include' : undefined,
				body: JSON.stringify({excludedCompanyProjectId: props.projectId, excludeIdleTimes: true}),
			}).then(response => response.json())
		);
	}

	return Promise.all(fetchList);
};

export const fetchProgressData = projectId => {
	let requestUrl = 'scheduling/projects/progress';
	if (projectId) {
		requestUrl += '?projectId=' + projectId;
	}

	const headers = new Headers();
	headers.append('Content-Type', 'application/json');

	return DirectApi.Fetch_WithErrorHandling(requestUrl, {
		method: 'GET',
		credentials: 'include',
		headers,
	}).then(response => response.json());
};
