import React from 'react';
import * as PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import styled from 'styled-components';
import {Collapsible} from 'web-components';

import {CSS_CONSTANTS} from '../../../../../css_variables';
import {ExpenseRow} from './expense_row';

const TableStyle = styled.div`
	margin-top: 24px;

	table {
		margin-top: 24px;
		width: 100%;

		thead {
			th {
				font-size: 9px;
				text-transform: uppercase;
				font-weight: 500;
				color: ${CSS_CONSTANTS.v2_text_light_gray};
				text-align: left;
				letter-spacing: 1px;
				border-bottom: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
				height: 30px;
				width: 1%;
				padding-left: 16px;
				white-space: nowrap;

				&.growable {
					width: 87%;
					overflow-x: hidden;
				}

				.header-wrapper {
					display: flex;
					align-items: center;
				}
			}
		}

		tbody {
			tr {
				td {
					height: 48px;
					border-bottom: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
					color: ${CSS_CONSTANTS.v2_text_gray};
					font-size: 13px;
					vertical-align: center;
					padding-left: 16px;
					width: 1%;
					white-space: nowrap;
					max-width: 200px;
					text-overflow: ellipsis;
					overflow: hidden;

					&.growable {
						width: 87%;
						overflow-x: hidden;
					}

					&.clickable {
						cursor: pointer;
					}

					&.date-column {
						font-weight: 500;
						text-transform: uppercase;
						font-size: 11px;
					}

					.value-wrapper {
						display: flex;
						align-items: center;

						.element {
							margin-right: 8px;
						}
					}
				}

				&:hover {
					td {
						background-color: ${CSS_CONSTANTS.v2_list_background_purple};
					}
				}
			}
		}
	}

	.yes-no-dropdown {
		width: 80px;
	}
`;

export const ExpenseTable = injectIntl(
	({
		intl,
		title,
		currencySymbol,
		project,
		expenses,
		showApproved,
		plannedEditable,
		showPartOfFixedPrice,
		retry,
		cyPrefix,
		company,
		handleNewExpense,
	}) => {
		const {formatMessage} = intl;
		return (
			<TableStyle>
				<Collapsible title={`${title} (${expenses.length})`}>
					<table data-cy={`${cyPrefix}s-table`}>
						<thead>
							<tr>
								<th className="growable">{formatMessage({id: 'common.name'})}</th>
								<th>{formatMessage({id: 'common.date'})}</th>
								<th>{formatMessage({id: 'common.person'})}</th>
								<th>{formatMessage({id: 'common.phase'})}</th>
								<th>{formatMessage({id: 'insights.category'})}</th>
								<th>{formatMessage({id: 'common.quantity_short'})}</th>
								<th>{formatMessage({id: 'expense_item_modal.unit_cost'})}</th>
								<th>{formatMessage({id: 'expense_item_modal.total_cost'})}</th>
								<th>{formatMessage({id: 'common.markup'})}</th>
								<th>{formatMessage({id: 'common.unit_price'})}</th>
								<th>{formatMessage({id: 'expense_item_modal.total_price'})}</th>
								<th>{formatMessage({id: 'common.planned'})}</th>
								{showApproved && <th>{formatMessage({id: 'common.approved'})}</th>}
								{showPartOfFixedPrice && <th>{formatMessage({id: 'expense_table.part_of_fixed_price'})}</th>}
								<th />
							</tr>
						</thead>
						<tbody>
							{expenses.map(expense => (
								<tr key={expense.id} data-cy={`${cyPrefix}-table-row`}>
									<ExpenseRow
										expense={expense}
										plannedEditable={plannedEditable}
										showApproved={showApproved}
										showPartOfFixedPrice={showPartOfFixedPrice}
										currencySymbol={currencySymbol}
										project={project}
										retry={retry}
										cyPrefix={cyPrefix}
										company={company}
										handleNewExpense={handleNewExpense}
									/>
								</tr>
							))}
						</tbody>
					</table>
				</Collapsible>
			</TableStyle>
		);
	}
);

ExpenseTable.propTypes = {
	expenses: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			category: PropTypes.shape({
				name: PropTypes.string,
			}),
			person: PropTypes.shape({
				fullName: PropTypes.string,
			}),
			name: PropTypes.string,
			expenseYear: PropTypes.number,
			expenseMonth: PropTypes.number,
			expenseDay: PropTypes.number,
			quantity: PropTypes.number,
			cost: PropTypes.number,
			price: PropTypes.number,
			billable: PropTypes.bool,
			planned: PropTypes.bool,
			approved: PropTypes.bool,
		})
	).isRequired,
	currencySymbol: PropTypes.string.isRequired,
	showApproved: PropTypes.bool,
	plannedEditable: PropTypes.bool,
};
