import {hasSomePermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {optionTraverser} from '../../../the_eye_util';
import Util from '../../../forecast-app/shared/util/util';
import {HIDDEN_FEATURES} from '../../../constants';
import {
	CUSTOM_FIELD_PREFIX,
	getCustomFieldColumnName,
} from '../../../forecast-app/project-tab/projects/scoping-page/ProjectScopingUtil';
import CompanySetupUtil, {isFeatureHidden} from '../../../forecast-app/shared/util/CompanySetupUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

const getFinancialOptions = (financialSourceSettings, isProjectTaskReport) => {
	const financialOptions = [];

	if (
		hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE]) &&
		CompanySetupUtil.hasFinance() &&
		!isFeatureHidden(HIDDEN_FEATURES.REVENUE)
	) {
		const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');
		const timeMaterialOptions = [];
		if (!isProjectTaskReport || financialSourceSettings.plannedRevenue !== 'ALLOCATION') {
			timeMaterialOptions.push({
				name: 'plannedRevenue',
				translationId: 'project_budget.planned_billable_time',
				checked: true,
				nestedOptions: null,
			});
		}
		if (!isProjectTaskReport || financialSourceSettings.forecastRevenue !== 'ALLOCATION') {
			timeMaterialOptions.push({
				name: 'remainingRevenue',
				translationId: hasFinancialCategoriesUpdate
					? 'common.remaining_work_billable_value_of_service'
					: 'project_budget.forecast_billable_time_and_expenses',
				checked: true,
				nestedOptions: null,
			});
		}
		if (!isProjectTaskReport || financialSourceSettings.actualRevenue !== 'ALLOCATION') {
			timeMaterialOptions.push({
				name: 'actualRevenue',
				translationId: hasFinancialCategoriesUpdate
					? 'common.actual_billable_value_of_service'
					: 'project_budget.actual_billable_time',
				checked: true,
				nestedOptions: null,
			});
		}
		if (
			!isProjectTaskReport ||
			(financialSourceSettings.actualRevenue !== 'ALLOCATION' && financialSourceSettings.forecastRevenue !== 'ALLOCATION')
		) {
			timeMaterialOptions.push({
				name: 'forecastRevenue',
				translationId: hasFinancialCategoriesUpdate
					? 'common.projected_total_billable_value_of_service'
					: 'project_budget.total_billable_time',
				checked: true,
				nestedOptions: null,
			});
		}
		const showRevenueRecognition =
			!isProjectTaskReport ||
			(financialSourceSettings.actualRevenue !== 'ALLOCATION' &&
				financialSourceSettings.forecastRevenue !== 'ALLOCATION');

		if (timeMaterialOptions.length > 0 || showRevenueRecognition) {
			financialOptions.push({
				name: 'financials',
				checked: true,
				disabled: false,
				translationId: 'common.financials',
				nestedOptions: [
					...(timeMaterialOptions.length
						? [
								{
									name: 'timeMaterial',
									checked: true,
									disabled: false,
									translationId: hasFinancialCategoriesUpdate
										? 'common.billable_value_of_service'
										: 'common.time_material',
									nestedOptions: timeMaterialOptions,
								},
						  ]
						: []),
					...(showRevenueRecognition
						? [
								{
									name: 'revenueRecognition',
									checked: true,
									disabled: false,
									translationId: hasFinancialCategoriesUpdate
										? 'project_budget.revenue'
										: 'project_budget.revenue_recognition',
									nestedOptions: [
										hasFinancialCategoriesUpdate
											? {
													name: 'actualRevenue',
													translationId: 'project_budget.actual_revenue',
													checked: false,
													nestedOptions: null,
											  }
											: {
													name: 'recognitionLockedRevenue',
													translationId: 'project_budget.revenue_recognition_from_locked_months',
													checked: false,
													nestedOptions: null,
											  },
										hasFinancialCategoriesUpdate
											? {
													name: 'remainingWorkRevenue',
													translationId: 'project_budget.remaining_work_revenue',
													checked: false,
													nestedOptions: null,
											  }
											: {
													name: 'recognitionOpenRevenue',
													translationId: 'project_budget.revenue_recognition_from_open_months',
													checked: false,
													nestedOptions: null,
											  },
										{
											name: 'recognitionForecastRevenue',
											translationId: hasFinancialCategoriesUpdate
												? 'common.projected_total_revenue'
												: 'project_budget.recognition_forecast_revenue',
											checked: true,
											nestedOptions: null,
										},
										{
											name: 'recognitionSurplus',
											translationId: 'project_budget.variance',
											checked: true,
											nestedOptions: null,
										},
									].filter(Boolean),
								},
						  ]
						: []),
				],
			});
		}
	}
	return financialOptions;
};

export const getTheEyeOptionsNoGrouping = (financialSourceSettings, customFieldDefinitions, isProjectTaskReport) => {
	return [
		{
			name: 'task',
			checked: true,
			translationId: null,
			hide: true,
			nestedOptions: null,
		},
		{
			name: 'taskDates',
			checked: false,
			translationId: 'common.task_dates',
			nestedOptions: [
				{
					name: 'startDate',
					checked: false,
					translationId: 'common.start_date',
					nestedOptions: null,
				},
				{
					name: 'deadline',
					checked: false,
					translationId: 'common.deadline',
					nestedOptions: null,
				},
			],
		},
		{
			name: 'project',
			checked: true,
			translationId: 'common.project',
			nestedOptions: null,
		},
		{
			name: 'projectDeadline',
			checked: false,
			translationId: 'insights.component.list.column.projectDeadline',
			nestedOptions: null,
		},
		{
			name: 'projectStage',
			checked: false,
			translationId: 'insights.component.list.column.projectStage',
			nestedOptions: null,
		},
		...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
			? [
					{
						name: 'clientName',
						checked: true,
						translationId: 'common.client',
						nestedOptions: null,
					},
			  ]
			: []),
		{
			name: 'status',
			checked: true,
			translationId: 'common.status',
			nestedOptions: null,
		},
		{
			name: 'roleName',
			checked: true,
			translationId: 'common.role',
			nestedOptions: null,
		},
		{
			name: 'assignees',
			checked: true,
			translationId: 'common.assignees',
			hide: true,
			nestedOptions: null,
		},
		{
			name: 'phase',
			checked: true,
			translationId: 'common.phase',
			nestedOptions: null,
		},
		{
			name: 'phaseDates',
			checked: false,
			translationId: 'common.phase_dates',
			nestedOptions: [
				{
					name: 'startDate',
					checked: false,
					translationId: 'common.start_date',
					nestedOptions: null,
				},
				{
					name: 'deadline',
					checked: false,
					translationId: 'common.deadline',
					nestedOptions: null,
				},
			],
		},
		{
			name: 'sprint',
			checked: true,
			translationId: 'common.sprint',
			nestedOptions: null,
		},
		{
			name: 'sprintDates',
			checked: false,
			translationId: 'common.sprint_dates',
			nestedOptions: [
				{
					name: 'startDate',
					checked: false,
					translationId: 'common.start_date',
					nestedOptions: null,
				},
				{
					name: 'deadline',
					checked: false,
					translationId: 'common.deadline',
					nestedOptions: null,
				},
			],
		},
		{
			name: 'work',
			checked: true,
			disabled: false,
			translationId: 'common.work',
			nestedOptions: [
				{
					name: 'estimate',
					checked: true,
					disabled: false,
					translationId: 'common.estimate',
					nestedOptions: [
						{
							name: 'hours',
							checked: true,
							disabled: false,
							translationId: 'common.hours',
							nestedOptions: null,
						},
						{
							name: 'points',
							checked: false,
							disabled: false,
							translationId: 'common.points',
							nestedOptions: null,
						},
					],
				},
				{
					name: 'remaining',
					checked: true,
					disabled: false,
					translationId: 'common.remaining',
					nestedOptions: [
						{
							name: 'hours',
							checked: true,
							disabled: false,
							translationId: 'common.hours',
							nestedOptions: null,
						},
						{
							name: 'points',
							checked: false,
							disabled: false,
							translationId: 'common.points',
							nestedOptions: null,
						},
					],
				},
				...(!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)
					? [
							{
								name: 'difference',
								checked: false,
								disabled: false,
								translationId: 'common.difference',
								nestedOptions: [
									{
										name: 'hours',
										checked: false,
										disabled: false,
										translationId: 'common.hours',
										nestedOptions: null,
									},
									{
										name: 'points',
										checked: false,
										disabled: false,
										translationId: 'common.points',
										nestedOptions: null,
									},
								],
							},
					  ]
					: []),
			],
		},
		...(!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)
			? [
					{
						name: 'reported',
						checked: true,
						translationId: 'common.reported',
						nestedOptions: [
							{
								name: 'reportedTime',
								checked: true,
								translationId: 'common.time_entries',
								nestedOptions: null,
							},
							{
								name: 'reportedBillableTime',
								checked: true,
								translationId: 'utilization.billable_time',
								nestedOptions: null,
							},
							{
								name: 'reportedNonBillableTime',
								checked: true,
								translationId: 'utilization.non_billable_time',
								nestedOptions: null,
							},
							{
								name: 'projected',
								checked: true,
								translationId: 'task.total_time_at_completion',
								nestedOptions: null,
							},
						],
					},
			  ]
			: []),
		{
			name: 'progress',
			checked: true,
			translationId: 'common.progress',
			nestedOptions: null,
		},
		...(hasSomePermission([
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
			PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
		]) &&
		CompanySetupUtil.hasFinance() &&
		isFeatureHidden(HIDDEN_FEATURES.REVENUE)
			? []
			: [
					{
						name: 'rateCard',
						checked: false,
						translationId: 'common.rate-card',
						nestedOptions: null,
					},
			  ]),
		...getFinancialOptions(financialSourceSettings, isProjectTaskReport),
		{
			name: 'indicators',
			translationId: 'common.indicators',
			checked: true,
			nestedOptions: null,
		},
		{
			name: 'approved',
			translationId: 'common.approved',
			checked: true,
			nestedOptions: null,
		},
		{
			name: 'labels',
			translationId: 'common.labels',
			checked: false,
			nestedOptions: null,
		},
		...(Util.hasCustomFields() && customFieldDefinitions && customFieldDefinitions.length > 0
			? [
					{
						name: CUSTOM_FIELD_PREFIX,
						checked: true,
						translationId: 'settings.custom_fields.title',
						nestedOptions: [
							...customFieldDefinitions.map(customFieldDefinition => ({
								name: getCustomFieldColumnName('TASK', customFieldDefinition.key),
								displayName: customFieldDefinition.displayName,
								checked: false,
								nestedOptions: null,
							})),
						],
					},
			  ]
			: []),
	];
};
// name: `${customFieldDefinition.key}.${customFieldDefinition.displayName}`,

/**
 * Available Eye options can change with permissions and feature flags, the option state is saved with the report.
 * Here we compare the eyeOptions persisted state with the available eye options to ensure that we:
 * - show new options, not already saved with default visibility
 * - hide columns that are available in eye options
 * */
export const getEnabledColumns = (theEyeOptionsCheckedState, financialSourceSettings, customFieldDefinitions) => {
	const availableOptionPaths = [];
	const addOptionPath = (eyeOption, optionPath) => {
		availableOptionPaths.push(optionPath); // store all available option paths
		if (theEyeOptionsCheckedState[optionPath] === undefined) {
			// if an available option is not in the state, show the column if the option is shown by default.
			theEyeOptionsCheckedState[optionPath] = eyeOption.checked;
		}
	};
	optionTraverser(getTheEyeOptionsNoGrouping(financialSourceSettings, customFieldDefinitions), addOptionPath);

	for (const optionName in theEyeOptionsCheckedState) {
		if (theEyeOptionsCheckedState.hasOwnProperty(optionName)) {
			theEyeOptionsCheckedState[optionName] =
				theEyeOptionsCheckedState[optionName] && availableOptionPaths.includes(optionName);
		}
	}
	return theEyeOptionsCheckedState;
};
