import {Table} from 'web-components';
import React from 'react';
import Util from '../../../forecast-app/shared/util/util';
import {useIntl} from 'react-intl';
import {ForecastTooltipFormulaRenderer} from '../../ForecastTooltipFormulaRenderer';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';

export const CurrencyColumn = ({
	value,
	currency,
	tooltip,
	hasLowerWinChance = false,
	lowerWinChanceTooltip,
	valuePostfix = '',
	showZero = false,
}) => {
	const intl = useIntl();
	const currencySymbol = Util.GetCurrencySymbol(currency);
	if (hasLowerWinChance) {
		showZero = true;
		valuePostfix = '*';
		tooltip = lowerWinChanceTooltip;
	}
	if (showZero && isNaN(value)) {
		value = 0;
	}
	const renderValue = (value, showZero) => {
		if (typeof value === 'string') {
			return value;
		} else if (!isNaN(value) && (value || showZero)) {
			return Util.getFormattedNumberWithCurrency(currencySymbol, value, intl);
		} else {
			return '–';
		}
	};

	return (
		<Table.Column align={'right'} usePadding negative={value < 0}>
			{tooltip ? (
				<ForecastTooltip
					maxWidth="600px"
					content={<ForecastTooltipFormulaRenderer items={tooltip} translatedMessage={true} />}
				>
					{renderValue(value, showZero) + valuePostfix}
				</ForecastTooltip>
			) : (
				renderValue(value, showZero) + valuePostfix
			)}
		</Table.Column>
	);
};
