import React from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {AssigneeGroup, Dropdown, HexagonText} from 'web-components';
import {profilePicSrc} from '../../../directApi';
export const ProjectPersonDropdown = ({
	persons,
	dropdownAlignment,
	width,
	name,
	selectedItems,
	selectedItemsPersons,
	showRole,
	onRemove,
	onSelect,
	optionsName,
	selectedGroupName,
	userpilot,
	emphasizeEmptyState,
	projectPersonValue,
	isMultiSelect,
	usePortal,
	maxHeight,
	headerLines,
}) => {
	const sortedPersons = [...persons].sort((a, b) => {
		if (a.node.id === null) return -1;
		if (b.node.id === null) return 1;
		if (
			`${a.node.person.firstName ? a.node.person.firstName.toLowerCase() : ''} ${
				a.node.person.lastName ? a.node.person.lastName.toLowerCase() : ''
			}` <
			`${b.node.person.firstName ? b.node.person.firstName.toLowerCase() : ''} ${
				b.node.person.lastName ? b.node.person.lastName.toLowerCase() : ''
			}`
		)
			return -1;
		if (
			`${a.node.person.firstName ? a.node.person.firstName.toLowerCase() : ''} ${
				a.node.person.lastName ? a.node.person.lastName.toLowerCase() : ''
			}` >
			`${b.node.person.firstName ? b.node.person.firstName.toLowerCase() : ''} ${
				b.node.person.lastName ? b.node.person.lastName.toLowerCase() : ''
			}`
		)
			return 1;
		return 0;
	});

	return (
		<Dropdown
			isNested
			headerLines={headerLines}
			isMultiSelect={isMultiSelect}
			onSelect={onSelect}
			onRemove={onRemove}
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
			usePortal={usePortal}
			maxHeight={maxHeight}
			customPlaceholder={
				selectedItemsPersons?.length > 0 ? (
					<AssigneeGroup alignStart showCount={5} assignees={selectedItemsPersons} />
				) : null
			}
		>
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{sortedPersons.map(edge => {
					const projectPersonId = edge.node.id;
					const {id, firstName, lastName, profilePictureId} = edge.node.person;
					return (
						<HexagonText
							key={projectPersonValue ? projectPersonId : id}
							value={projectPersonValue ? projectPersonId : id}
							searchString={`${firstName} ${lastName}`}
							text={`${firstName} ${lastName}`}
							subText={showRole ? (edge.node.role ? edge.node.role.name : ' ') : null}
							imgUrl={profilePicSrc(profilePictureId)}
						/>
					);
				})}
			</Dropdown.Group>
		</Dropdown>
	);
};

ProjectPersonDropdown.propTypes = {
	persons: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				person: PropTypes.shape({
					id: PropTypes.string.isRequired,
					firstName: PropTypes.string.isRequired,
					lastName: PropTypes.string.isRequired,
					profilePictureId: PropTypes.string,
					role: PropTypes.shape({
						name: PropTypes.string,
					}),
				}),
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	selectedItemsPerson: PropTypes.array, // Pass selected objects with properties instead of simply a list of Ids, used to display custom placeholder
	isMultiSelect: PropTypes.bool,
	projectPersonValue: PropTypes.bool, // Used to return the actual projectPersonId from the dropdown instead of the id of the person
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	showRole: PropTypes.bool,
	selectedGroupName: PropTypes.string,
	usePortal: PropTypes.bool,
	headerLines: PropTypes.element,
};

ProjectPersonDropdown.defaultProps = {
	optionsName: 'Project Persons',
	name: 'Project Persons',
	selectedGroupName: 'Selected',
	showRole: false,
	isMultiSelect: true,
	projectPersonValue: false,
	usePortal: false,
	onSelect: () => false,
	onRemove: () => false,
};

export default createFragmentContainer(ProjectPersonDropdown, {
	persons: graphql`
		fragment ProjectPersonDropdown_persons on ProjectPersonTypeEdge @relay(plural: true) {
			node {
				id
				role {
					name
				}
				person {
					id
					firstName
					lastName
					profilePictureId
					role {
						name
					}
				}
			}
		}
	`,
});
