import {GROUP_BY, PROJECT_PORTFOLIO_REPORT_PAGES} from './ProjectPortfolioReport';
import Util from '../../../forecast-app/shared/util/util';
import ProjectFormatter from '../../../forecast-app/shared/util/export-formatters/ProjectFormatter';
import {useRemappingFormatMessage} from '../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import {BUDGET_TYPE} from '../../../constants';
import ProgramUtil from '../../../forecast-app/shared/util/ProgramUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

export const exportToCsv = ({
	rows: unfilteredRows,
	grouping,
	page,
	currency,
	boolEyeOptions,
	startDate,
	endDate,
	intl,
	selectedSingleValue,
}) => {
	const formatMessage = useRemappingFormatMessage(intl);
	const format = new ProjectFormatter(intl);
	const year = startDate ? startDate.year() : 'all';
	const isPeriodPage = page === PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD;
	const rows = unfilteredRows.filter(
		rowData =>
			!!rowData.financialNumbers ||
			!!rowData.timeAggregatedFinancialNumbers ||
			!!rowData.expenseAggregatedFinancialNumbers ||
			!!rowData.projectsFinancialsTotals
	);
	const hasFinancialCategories = hasFeatureFlag('financial_categories_update');

	const formatColumnName = name => {
		return name.replaceAll(' ', '_').replaceAll('(h)', '(min)').toLowerCase();
	};

	const addColumn = (row, visible, name, value) => {
		if (visible) {
			row[formatColumnName(name)] = value;
		}
	};

	const addProjectColumns = (row, project) => {
		const isFixedPriceProject = [BUDGET_TYPE.FIXED_PRICE, BUDGET_TYPE.FIXED_PRICE_V2].includes(project.budgetType);

		addColumn(row, true, 'Project ID', project.customProjectId || '');
		addColumn(row, true, 'Project', project.name);
		// Project overview
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.priorityLevel : boolEyeOptions.mbm_priorityLevel,
			formatMessage({id: 'project_settings.priority_level'}),
			project.priorityLevel ? project.priorityLevel.name : ''
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.winProbability : boolEyeOptions.mbm_winProbability,
			'Win Probability',
			format._formatPercentageFromFraction(project.baselineWinChance)
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.dates : boolEyeOptions.mbm_dates,
			'Start Date',
			format._formatDate(project.projectStartDay, project.projectStartMonth, project.projectStartYear)
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.dates : boolEyeOptions.mbm_dates,
			'End Date',
			format._formatDate(project.projectEndDay, project.projectEndMonth, project.projectEndYear)
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.client : boolEyeOptions.mbm_client,
			formatMessage({id: 'insights.component.list.column.client'}),
			project.client ? project.client.name : ''
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.status : boolEyeOptions.mbm_status,
			formatMessage({id: 'common.status'}),
			project.currentProjectStatus ? Util.getProjectStatusColorName(project.currentProjectStatus.color) : ''
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.statusDescription : boolEyeOptions.mbm_statusDescription,
			formatMessage({id: 'common.statusDescription'}),
			project.currentProjectStatus ? Util.parseStatusDescription(project.currentProjectStatus.description) : ''
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.projectOwner : boolEyeOptions.mbm_projectOwner,
			formatMessage({id: 'common.project_owner'}),
			format._formatProjectOwner(project.projectPersons)
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.stage : boolEyeOptions.mbm_stage,
			formatMessage({id: 'insights.component.list.column.status'}),
			Util.getProjectStageTranslation(project.status, intl)
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.labels : boolEyeOptions.mbm_labels,
			formatMessage({id: 'insights.component.list.column.labels'}),
			format._formatLabels(project.projectLabels)
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.progress : boolEyeOptions.mbm_progress,
			formatMessage({id: 'common.progress'}),
			format._formatPercentageFromFraction(project.completion / 100)
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.progressEom : boolEyeOptions.mbm_progressEom,
			formatMessage({id: 'project_portfolio_report.progress_eom'}),
			format._formatPercentageFromFraction(project.progressEndOfTheMonth / 100)
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.budgetType : boolEyeOptions.mbm_budgetType,
			formatMessage({id: 'new_project_modal.budget_type'}),
			format._formatBudgetType(project)
		);
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.rateCard : boolEyeOptions.mbm_rateCard,
			formatMessage({id: 'insights.component.list.column.rateCard'}),
			project.rateCard ? project.rateCard.name : ''
		);
		// Fixed Price
		addColumn(
			row,
			isPeriodPage ? boolEyeOptions.fixedPriceRevenue : boolEyeOptions.mbm_fixedPriceRevenue,
			'Fixed Price',
			format._formatCurrency(isFixedPriceProject ? project.budgetBaseCurrency : 0)
		);
	};

	const addProgramColumns = (row, program) => {
		addColumn(
			row,
			true,
			'Program Name',
			program.name || formatMessage({id: 'common.no_program'}, {program: ProgramUtil.programText(formatMessage)})
		);
	};

	const addConnectedProjectColumns = (row, connectedProject) => {
		addColumn(
			row,
			true,
			'Connected Project ID',
			connectedProject.companyProjectGroupId
				? `X${connectedProject.companyProjectGroupId}`
				: intl.formatMessage({id: 'common.no_connected_project'})
		);
	};

	const addClientColumns = (row, client) => {
		addColumn(row, true, 'Client', client.name || formatMessage({id: 'common.no_client'}));
	};

	const addPeriodFinancialColumns = (row, financialNumbers) => {
		addColumn(
			row,
			boolEyeOptions.allocations,
			formatMessage({id: 'scheduling.menu.allocations'}),
			format._formatTime(financialNumbers.allocationMinutes)
		);
		// Project Details
		addColumn(row, boolEyeOptions.baselineHours, 'Baseline hrs', format._formatTime(financialNumbers.baselineMinutes));
		addColumn(
			row,
			boolEyeOptions.totalScope,
			formatMessage({id: 'project_portfolio_report.total_scope'}),
			format._formatTime(financialNumbers.scopeTotalMinutes)
		);
		addColumn(
			row,
			boolEyeOptions.approvedScope,
			formatMessage({id: 'project_portfolio_report.approved_scope'}),
			format._formatTime(financialNumbers.scopeApprovedMinutes)
		);
		addColumn(
			row,
			boolEyeOptions.registeredHours,
			formatMessage({id: 'project_portfolio_report.registered_hours'}),
			format._formatTime(financialNumbers.registeredMinutes)
		);
		addColumn(
			row,
			boolEyeOptions.remainingHours,
			formatMessage({id: 'project_portfolio_report.remaining_hours'}),
			format._formatTime(financialNumbers.forecastTimeToComplete)
		);
		addColumn(
			row,
			boolEyeOptions.forecastedHours,
			formatMessage({id: 'project_portfolio_report.forecasted_hours'}),
			format._formatTime(financialNumbers.totalTimeAtCompletion)
		);
		// Baseline
		addColumn(
			row,
			boolEyeOptions.baselineRevenue,
			formatMessage({id: 'project_budget.baseline'}) + ' ' + formatMessage({id: 'project_budget.revenue'}),
			format._formatCurrency(financialNumbers.baselineRevenue)
		);
		addColumn(row, boolEyeOptions.baselineCost, 'Baseline Cost', format._formatCurrency(financialNumbers.baselineCost));
		addColumn(
			row,
			boolEyeOptions.baselineProfit,
			'Baseline Profit',
			format._formatCurrency(financialNumbers.baselineProfit)
		);
		addColumn(
			row,
			boolEyeOptions.baselineMargin,
			'Baseline Margin',
			format._formatPercentageFromFraction(financialNumbers.baselineMargin)
		);
		// Plan
		addColumn(
			row,
			boolEyeOptions.estimatedRevenue,
			formatMessage({id: 'common.estimated_revenue'}),
			format._formatCurrency(financialNumbers.plannedRevenue)
		);
		addColumn(
			row,
			boolEyeOptions.planRevenue,
			formatMessage({
				id: hasFinancialCategories ? 'common.estimated_billable_value_of_service' : 'project_budget.planned_revenue',
			}),
			format._formatCurrency(financialNumbers.billablePlannedTimeAndExpenses)
		);
		addColumn(row, boolEyeOptions.planCost, 'Planned Cost', format._formatCurrency(financialNumbers.plannedCost));
		addColumn(row, boolEyeOptions.planProfit, 'Planned Profit', format._formatCurrency(financialNumbers.plannedProfit));
		addColumn(
			row,
			boolEyeOptions.planMargin,
			'Plan Margin',
			format._formatPercentageFromFraction(financialNumbers.plannedMargin)
		);
		// Actuals
		addColumn(
			row,
			boolEyeOptions.actualRevenue,
			formatMessage({id: 'project_budget.actual_revenue'}),
			format._formatCurrency(financialNumbers.totalActualRevenueRecognition)
		);
		addColumn(
			row,
			boolEyeOptions.billableActualTimeAndExpenses,
			formatMessage({id: 'project_budget.actual_billable_time_and_expenses'}),
			format._formatCurrency(financialNumbers.billableActualTimeAndExpenses)
		);
		addColumn(row, boolEyeOptions.actualCost, 'Actual Cost', format._formatCurrency(financialNumbers.actualCost));
		addColumn(row, boolEyeOptions.actualProfit, 'Actual Profit', format._formatCurrency(financialNumbers.actualProfit));
		addColumn(
			row,
			boolEyeOptions.actualMargin,
			'Actual Margin',
			format._formatPercentageFromFraction(financialNumbers.actualMargin)
		);
		// Remaining
		addColumn(
			row,
			boolEyeOptions.remainingWorkRevenue,
			formatMessage({id: 'project_budget.remaining_work_revenue'}),
			format._formatCurrency(financialNumbers.totalForecastRevenueToComplete)
		);
		addColumn(
			row,
			boolEyeOptions.billableForecastTimeAndExpensesToComplete,
			formatMessage({
				id: hasFinancialCategories
					? 'common.remaining_work_billable_value_of_service'
					: 'project_budget.remaining_revenue',
			}),
			format._formatCurrency(financialNumbers.billableForecastTimeAndExpensesToComplete)
		);
		addColumn(
			row,
			boolEyeOptions.forecastCostToComplete,
			formatMessage({id: 'project_budget.remaining_cost'}),
			format._formatCurrency(financialNumbers.forecastCostToComplete)
		);
		addColumn(
			row,
			boolEyeOptions.forecastProfitToComplete,
			formatMessage({id: 'project_budget.remaining_profit'}),
			format._formatCurrency(financialNumbers.forecastProfitToComplete)
		);
		addColumn(
			row,
			boolEyeOptions.forecastMarginToComplete,
			formatMessage({id: 'project_budget.remaining_margin'}),
			format._formatPercentageFromFraction(financialNumbers.forecastMarginToComplete)
		);
		// Forecasted
		addColumn(
			row,
			boolEyeOptions.projectTotalRevenue,
			formatMessage({
				id: 'common.projected_total_revenue',
			}),
			format._formatCurrency(financialNumbers.totalRevenueRecognition)
		);
		addColumn(
			row,
			boolEyeOptions.forecastedRevenue,
			formatMessage({
				id: hasFinancialCategories
					? 'common.projected_total_billable_value_of_service'
					: 'project_budget.forecast_revenue',
			}),
			format._formatCurrency(financialNumbers.billableTotalTimeAndExpensesAtCompletion)
		);
		addColumn(
			row,
			boolEyeOptions.forecastedCost,
			formatMessage({id: 'project_budget.forecast_cost'}),
			format._formatCurrency(financialNumbers.totalCostAtCompletion)
		);
		addColumn(
			row,
			boolEyeOptions.forecastedProfit,
			formatMessage({id: 'project_budget.forecast_profit'}),
			format._formatCurrency(financialNumbers.totalProfitAtCompletion)
		);
		addColumn(
			row,
			boolEyeOptions.forecastedMargin,
			formatMessage({id: 'project_budget.forecast_margin'}),
			format._formatPercentageFromFraction(financialNumbers.totalMarginAtCompletion)
		);
		/* Over/Under Servicing */
		addColumn(
			row,
			boolEyeOptions.projectedTotalRevenue,
			formatMessage({id: 'common.projected_total_revenue'}),
			format._formatCurrency(financialNumbers.totalRevenueRecognition)
		);
		addColumn(
			row,
			boolEyeOptions.projectedTotalBillableValueOfService,
			formatMessage({id: 'common.projected_total_billable_value_of_service'}),
			format._formatCurrency(financialNumbers.billableTotalTimeAndExpensesAtCompletion)
		);
		addColumn(
			row,
			boolEyeOptions.projectedTotalNonBillableValueOfService,
			formatMessage({id: 'common.projected_total_non_billable_value_of_service'}),
			format._formatCurrency(financialNumbers.nonBillableTotalTimeAndExpensesAtCompletion)
		);
		addColumn(
			row,
			boolEyeOptions.projectedTotalValueOfService,
			formatMessage({id: 'common.projected_total_value_of_service'}),
			format._formatCurrency(financialNumbers.allTotalTimeAndExpensesAtCompletion)
		);
		addColumn(
			row,
			boolEyeOptions.projectedBudgetRemaining,
			formatMessage({id: 'common.projected_budget_remaining'}),
			format._formatCurrency(financialNumbers.projectedTotalRevenueVsProjectedTotalBillableValueOfService)
		);
		addColumn(
			row,
			boolEyeOptions.projectedOverUnderServicing,
			formatMessage({id: 'project_portfolio_report.projected_over_under_servicing'}),
			format._formatCurrency(financialNumbers.projectedTotalRevenueVsProjectedTotalValueOfService)
		);
		// Invoiced
		addColumn(row, boolEyeOptions.invoiced, 'Invoiced', format._formatCurrency(financialNumbers.invoiced));
		addColumn(row, boolEyeOptions.paid, 'Invoice Paid', format._formatCurrency(financialNumbers.paid));
		// Accrued / Deferred
		addColumn(
			row,
			boolEyeOptions.invoicedActualInvoiced,
			formatMessage({id: 'project_budget.invoiced'}),
			format._formatCurrency(financialNumbers.invoiced)
		);
		addColumn(
			row,
			boolEyeOptions.invoicedBillableActualTimeAndExpenses,
			formatMessage({id: 'project_budget.billable_time_and_expenses'}),
			format._formatCurrency(financialNumbers.billableActualTimeAndExpenses)
		);
		addColumn(
			row,
			boolEyeOptions.invoicedRecognitionLockedRevenue,
			formatMessage({id: 'project_budget.revenue_recognition_from_locked_months'}),
			format._formatCurrency(financialNumbers.recognitionLockedRevenue)
		);
		addColumn(
			row,
			boolEyeOptions.accruedDeferred,
			formatMessage({id: 'project_budget.accrued'}),
			format._formatCurrency(financialNumbers.accruedDeferred)
		);
		// Revenue Recognition
		addColumn(
			row,
			boolEyeOptions.recognitionLockedRevenue,
			formatMessage({id: 'project_budget.revenue_recognition_from_locked_months'}),
			format._formatCurrency(financialNumbers.recognitionLockedRevenue)
		);
		addColumn(
			row,
			boolEyeOptions.recognitionOpenRevenue,
			formatMessage({id: 'project_budget.recognition_remaining_revenue'}),
			format._formatCurrency(financialNumbers.recognitionOpenRevenue)
		);
		addColumn(
			row,
			boolEyeOptions.recognitionAmount,
			formatMessage({id: 'project_budget.recognition_amount'}),
			format._formatCurrency(financialNumbers.totalRevenueRecognition)
		);
		addColumn(
			row,
			boolEyeOptions.recognitionProfit,
			formatMessage({id: 'project_budget.portfolio_report.recognition_profit'}),
			format._formatCurrency(financialNumbers.recognitionProfit)
		);
		addColumn(
			row,
			boolEyeOptions.recognitionMargin,
			formatMessage({id: 'project_budget.recognition_margin'}),
			format._formatPercentageFromFraction(financialNumbers.recognitionProfitPercentage)
		);
		// Plan vs. Actual
		addColumn(
			row,
			boolEyeOptions.planBillableActualTimeAndExpenses,
			formatMessage({id: 'project_budget.plan_vs_actual'}) + ' ' + formatMessage({id: 'project_budget.revenue'}),
			format._formatCurrency(financialNumbers.planVsBillableActualTimeAndExpenses)
		);
		addColumn(
			row,
			boolEyeOptions.planActualCost,
			'Planned vs Actual Cost',
			format._formatCurrency(financialNumbers.planVsActualCost)
		);
		addColumn(
			row,
			boolEyeOptions.planActualProfit,
			'Planned vs Actual Profit',
			format._formatCurrency(financialNumbers.planVsActualProfit)
		);
		// Plan vs. Forecasted
		addColumn(
			row,
			boolEyeOptions.planForecastedRevenue,
			formatMessage({id: 'project_budget.plan_vs_forecast'}) + ' ' + formatMessage({id: 'project_budget.revenue'}),
			format._formatCurrency(financialNumbers.planVsTotalBillableTimeAndExpensesAtCompletion)
		);
		addColumn(
			row,
			boolEyeOptions.planForecastedCost,
			formatMessage({id: 'project_budget.plan_vs_forecast'}) + ' ' + formatMessage({id: 'project_budget.cost'}),
			format._formatCurrency(financialNumbers.planVsTotalCostAtCompletion)
		);
		addColumn(
			row,
			boolEyeOptions.planForecastedProfit,
			formatMessage({id: 'project_budget.plan_vs_forecast'}) + ' ' + formatMessage({id: 'project_budget.profit'}),
			format._formatCurrency(financialNumbers.planVsTotalProfitAtCompletion)
		);
		addColumn(
			row,
			boolEyeOptions.retainerPeriodTargetMinutes,
			'Retainer Period Target Minutes',
			format._formatNumber(financialNumbers.retainerPeriodTargetMinutes || 0)
		);
		addColumn(
			row,
			boolEyeOptions.retainerPeriodTargetPrice,
			'Retainer Period Target Price',
			format._formatCurrency(financialNumbers.retainerPeriodTargetPrice)
		);
	};

	const addMonthByMonthFinancialColumns = (row, expenseData, timeData, totalData) => {
		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.jan'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[0])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.jan'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[0])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.jan'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[0])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.feb'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[1])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.feb'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[1])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.feb'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[1])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.mar'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[2])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.mar'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[2])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.mar'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[2])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.apr'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[3])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.apr'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[3])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.apr'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[3])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.may'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[4])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.may'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[4])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.may'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[4])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.jun'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[5])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.jun'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[5])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.jun'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[5])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.jul'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[6])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.jul'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[6])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.jul'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[6])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.aug'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[7])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.aug'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[7])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.aug'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[7])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.sep'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[8])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.sep'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[8])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.sep'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[8])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.oct'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[9])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.oct'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[9])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.oct'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[9])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.nov'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[10])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.nov'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[10])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.nov'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[10])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_expense,
			`${formatMessage({id: 'insights.component.list.column.dec'})} ${year} ${formatMessage({
				id: 'common.expenses',
			})}`,
			format._formatCurrency(expenseData[11])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_time,
			`${formatMessage({id: 'insights.component.list.column.dec'})} ${year} ${formatMessage({id: 'common.time'})}`,
			format._formatCurrency(timeData[11])
		);

		addColumn(
			row,
			boolEyeOptions.mbm_total,
			`${formatMessage({id: 'insights.component.list.column.dec'})} ${year} ${formatMessage({id: 'common.total'})}`,
			format._formatCurrency(totalData[11])
		);
	};

	const data = rows.map(rowData => {
		const row = {};

		if (isPeriodPage) {
			switch (grouping) {
				case GROUP_BY.NO_GROUPING:
					addProjectColumns(row, rowData);
					addPeriodFinancialColumns(row, rowData.financialNumbers);
					break;
				case GROUP_BY.PROGRAM:
					addProgramColumns(row, rowData);
					addPeriodFinancialColumns(row, rowData.projectsFinancialsTotals);
					break;
				case GROUP_BY.CONNECTED_PROJECT:
					addConnectedProjectColumns(row, rowData);
					addPeriodFinancialColumns(row, rowData.financialNumbers);
					break;
				case GROUP_BY.CLIENT:
					addClientColumns(row, rowData);
					addPeriodFinancialColumns(row, rowData.financialNumbers);
					break;
			}
		} else {
			const expenseData = [];
			const timeData = [];
			const totalData = [];

			for (const period of Object.values(rowData.expenseAggregatedFinancialNumbers)) {
				const periodStartDate = period.split(',')[0];
				let startMonth = parseInt(periodStartDate.split('-')[1], 10);
				const periodEndDate = period.split(',')[1];
				const endMonth = parseInt(periodEndDate.split('-')[1], 10);
				while (startMonth <= endMonth) {
					expenseData.push(parseFloat(period.split(',')[2]));
					startMonth += 1;
				}
			}

			for (const period of Object.values(rowData.timeAggregatedFinancialNumbers)) {
				const periodStartDate = period.split(',')[0];
				let startMonth = parseInt(periodStartDate.split('-')[1], 10);
				const periodEndDate = period.split(',')[1];
				const endMonth = parseInt(periodEndDate.split('-')[1], 10);
				while (startMonth <= endMonth) {
					timeData.push(parseFloat(period.split(',')[2]));
					startMonth += 1;
				}
			}

			for (const period of Object.values(rowData.aggregatedFinancialNumbers)) {
				const periodStartDate = period.split(',')[0];
				let startMonth = parseInt(periodStartDate.split('-')[1], 10);
				const periodEndDate = period.split(',')[1];
				const endMonth = parseInt(periodEndDate.split('-')[1], 10);
				while (startMonth <= endMonth) {
					totalData.push(parseFloat(period.split(',')[2]));
					startMonth += 1;
				}
			}
			console.log(grouping, GROUP_BY.CLIENT);
			switch (grouping) {
				case GROUP_BY.NO_GROUPING:
					addProjectColumns(row, rowData);
					addMonthByMonthFinancialColumns(row, expenseData, timeData, totalData);
					break;
				case GROUP_BY.PROGRAM:
					addProgramColumns(row, rowData);
					addMonthByMonthFinancialColumns(row, expenseData, timeData, totalData);
					break;
				case GROUP_BY.CONNECTED_PROJECT:
					addConnectedProjectColumns(row, rowData);
					addMonthByMonthFinancialColumns(row, expenseData, timeData, totalData);
					break;
				case GROUP_BY.CLIENT:
					addClientColumns(row, rowData);
					addMonthByMonthFinancialColumns(row, expenseData, timeData, totalData);
					break;
			}
		}

		// Currency
		addColumn(row, true, 'Currency', currency);

		return row;
	});

	if (data && data[0]) {
		const headerRow = Object.keys(data[0]).reduce((map, column) => {
			map[column] = column;
			return map;
		}, {});
		data.splice(0, 0, headerRow);

		if (isPeriodPage) {
			const csv = Util.JSONToCSV(data);
			Util.exportToCSV(
				csv,
				`project_portfolio_period_${startDate ? startDate.format('YYYY-MM-DD') : 'all'}-${
					endDate ? endDate.format('YYYY-MM-DD') : 'time'
				}.csv`
			);
		} else {
			const reportName = formatMessage({id: Util.getTranslationByFinancialNumberType(selectedSingleValue)});
			data.splice(0, 0, [`Report type: ${reportName}`]);

			const csv = Util.JSONToCSV(data);
			Util.exportToCSV(
				csv,
				`project_portfolio_month_by_month_${startDate.format('YYYY-MM-DD')}-${endDate.format('YYYY-MM-DD')}.csv`
			);
		}
	}
};
