import React, {useEffect, useState} from 'react';
import {ProgramBudgetInfo} from './ProgramBudgetInfo';
import {
	DeprecatedCreativeModal as CreativeModal,
	FlexColumn,
	FlexItem,
	DeprecatedText as Text,
} from '@forecast-it/design-system';
import {useIntl} from 'react-intl';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import Input from '../input_field';
import {CAP_SETTING, PERIOD_BUDGET_TYPE} from '../../../constants';
import {ProgramTotalAllocation} from './ProgramTotalAllocation';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import Util from '../../../forecast-app/shared/util/util';
import {buildRetainerPeriodProgramBudgetInfo} from '../../../forecast-app/project-tab/programs/hooks/useProjectProgramFinancials';
import {ProgramBudgetExceededWarning} from '../ProgramBudgetExceededWarning';
import {useHistory} from 'react-router-dom';
import ProgramUtil from '../../../forecast-app/shared/util/ProgramUtil';

export const ChangePeriodTargetModal = ({
	periodTarget,
	periodBudgetType,
	onChangePeriodTarget,
	programBudgetInfo,
	currency,
	closeModal,
	periods,
	project,
}) => {
	const {formatMessage} = useIntl();
	const history = useHistory();
	const [newPeriodTargetValue, setNewPeriodTargetValue] = useState(periodTarget);
	const [showOverAllocatedError, setShowOverAllocatedError] = useState(false);
	const [showLockedRevenueError, setShowLockedRevenueError] = useState(false);
	const oldTargetTotal = periods.reduce((total, period) => total + period.periodPriceAmount, 0);
	const newTargetTotal = newPeriodTargetValue * periods.length;
	const currencySymbol = Util.GetCurrencySymbol(currency);

	const retainerProgramBudgetInfo = buildRetainerPeriodProgramBudgetInfo(
		newTargetTotal - oldTargetTotal,
		programBudgetInfo,
		project
	);
	const validate = () => {
		if (retainerProgramBudgetInfo.showRevenueDisabled) {
			if (retainerProgramBudgetInfo.showCannotIncreasedPeriodTarget) {
				setShowLockedRevenueError(true);
				return false;
			}
			return true;
		}
		setShowOverAllocatedError(retainerProgramBudgetInfo.showOverAllocatedError);
		if (retainerProgramBudgetInfo.showOverAllocatedError) {
			trackEvent('Retainer Period Target Change Button', 'Clicked', {
				error: 'Program Over-Allocated',
				isOwner: retainerProgramBudgetInfo.canManageProgram,
			});
		}
		return !retainerProgramBudgetInfo.showOverAllocatedError;
	};
	const validateAndChange = () => {
		if (retainerProgramBudgetInfo.revenueSetting === CAP_SETTING.ALWAYS_PERMITTED || validate()) {
			onChangePeriodTarget(newPeriodTargetValue);
			trackEvent('Retainer Period Target Change Button', 'Clicked');
			closeModal();
		}
	};

	const handleEditProgramBudgetClick = () => {
		trackEvent('Edit Program Budget Link', 'Clicked', {location: 'From Change Period Target Modal'});
		history.push(`${ProgramUtil.programLink()}/${retainerProgramBudgetInfo.program.prefix}/budget/edit`);
		closeModal();
	};

	useEffect(() => {
		if (retainerProgramBudgetInfo.revenueSetting === CAP_SETTING.ALWAYS_PERMITTED) {
			return;
		}
		if (showOverAllocatedError) {
			validate();
		}
	}, [newPeriodTargetValue]);

	return (
		<CreativeModal
			title={formatMessage({id: 'retainer.change_target'})}
			closeModal={closeModal}
			onCreateHandler={validateAndChange}
			creativeButtonText={formatMessage({id: 'common.update'})}
			size={'s'}
			alignItems={'left'}
		>
			{(showOverAllocatedError || showLockedRevenueError) && retainerProgramBudgetInfo.isInCappedRevenueProgram && (
				<ProgramBudgetExceededWarning
					canManageProgram={retainerProgramBudgetInfo.canManageProgram}
					program={retainerProgramBudgetInfo.program}
					onEditProgramBudgetClick={handleEditProgramBudgetClick}
					valueType={'period target'}
					showLockedRevenueError={showLockedRevenueError}
				></ProgramBudgetExceededWarning>
			)}
			{retainerProgramBudgetInfo.validateAgainstProgramBudget && retainerProgramBudgetInfo.canManageProgram && (
				<ProgramBudgetInfo {...{...programBudgetInfo, currencySymbol}} width={'100%'} />
			)}
			<FlexColumn gap={'m'}>
				<Text variant={'bold'}>{formatMessage({id: 'retainer.period_target'})}</Text>
				{(retainerProgramBudgetInfo.isInFixedPriceProgram || retainerProgramBudgetInfo.isInCappedRevenueProgram) && (
					<Text color="secondary">
						{formatMessage({
							id: retainerProgramBudgetInfo.isInFixedPriceProgram
								? 'retainer.total_allocated_revenue_description.fixed_price'
								: 'retainer.total_allocated_revenue_description.capped',
						})}
					</Text>
				)}
				<FlexItem>
					{periodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS ||
					project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS ? (
						<HoursInput
							cy={'user-input-modal-input'}
							value={newPeriodTargetValue}
							focusOnMount={true}
							mutation={setNewPeriodTargetValue}
						/>
					) : (
						<Input
							value={newPeriodTargetValue}
							type={'number'}
							onChange={setNewPeriodTargetValue}
							autoFocus={true}
							onEnter={() => validateAndChange(newPeriodTargetValue)}
							currency={currency}
							cy={'user-input-modal-input'}
							invalidInput={showOverAllocatedError}
						/>
					)}
				</FlexItem>
				{(showOverAllocatedError || showLockedRevenueError) && (
					<Text color={'destructive'}>
						{formatMessage({
							id: retainerProgramBudgetInfo.isInFixedPriceProgram
								? 'retainer.total_allocated_revenue_error.fixed_price'
								: showLockedRevenueError
								? 'retainer.locked_revenue_setting_error.capped'
								: 'retainer.total_allocated_revenue_error.capped',
						})}
					</Text>
				)}
			</FlexColumn>
			{retainerProgramBudgetInfo.validateAgainstProgramBudget && programBudgetInfo.programAllocation !== undefined && (
				<ProgramTotalAllocation
					isInFixedPriceProgram={retainerProgramBudgetInfo.isInFixedPriceProgram}
					totalProgramAllocation={programBudgetInfo.programAllocation + (newTargetTotal - oldTargetTotal)}
					currencySymbol={currencySymbol}
					showOverAllocatedError={showOverAllocatedError}
					width={'100%'}
					showRevenueExceedInfo={false}
				/>
			)}
		</CreativeModal>
	);
};
