import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {GROUP_BY, PROJECT_PORTFOLIO_REPORT_PAGES} from '../ProjectPortfolioReport';
import {exportToCsv} from '../ProjectPortfolioReportCsvDownload';

const ProjectPortfolioMonthByMonthDownload = ({
	viewer,
	closeModal,
	startDate,
	endDate,
	boolEyeOptions,
	selectedSingleValue,
}) => {
	const intl = useIntl();
	const currency = viewer.company.currency;
	const projects = viewer.company.financialBasedProjects.edges.map(edge => edge.node);

	useEffect(() => {
		if (viewer) {
			exportToCsv({
				rows: projects,
				grouping: GROUP_BY.NO_GROUPING,
				page: PROJECT_PORTFOLIO_REPORT_PAGES.MONTH_BY_MONTH,
				currency,
				boolEyeOptions,
				startDate,
				endDate,
				intl,
				selectedSingleValue,
			});
			closeModal();
		}
	}, [viewer]);

	return null;
};

export const projectPortfolioMonthByMonthDownloadQuery = graphql`
	query ProjectPortfolioMonthByMonthDownload_Query(
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
		$searchQuery: TaskSearchQueryType!
		$timeSearchQuery: TaskSearchQueryType!
		$expenseSearchQuery: TaskSearchQueryType!
		$pageSize: Int!
		$cursor: String
		$selectedSingleValue: FinancialAggregatedNumber!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_month_by_month_project_csv_loader")
			...ProjectPortfolioMonthByMonthDownload_viewer
				@arguments(
					searchQuery: $searchQuery
					timeSearchQuery: $timeSearchQuery
					expenseSearchQuery: $expenseSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					pageSize: $pageSize
					cursor: $cursor
					selectedSingleValue: $selectedSingleValue
				)
		}
	}
`;

export default createFragmentContainer(ProjectPortfolioMonthByMonthDownload, {
	viewer: graphql`
		fragment ProjectPortfolioMonthByMonthDownload_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType!"}
			timeSearchQuery: {type: "TaskSearchQueryType!"}
			expenseSearchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
			selectedSingleValue: {type: "FinancialAggregatedNumber!"}
			pageSize: {type: "Int!"}
			cursor: {type: "String"}
		) {
			id
			company {
				currency
				financialBasedProjects(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: MONTH
					aggregates: [$selectedSingleValue]
					first: $pageSize
					after: $cursor
				) {
					edges {
						node {
							id
							companyProjectId
							customProjectId
							name
							fullAccessToProject
							readOnlyAccess
							projectColor
							useBaseline
							status
							baselineWinChance
							priorityLevel {
								id
								name
							}
							budgetType
							budgetBaseCurrency
							projectStartDay
							projectStartMonth
							projectStartYear
							projectEndDay
							projectEndMonth
							projectEndYear
							client {
								id
								name
							}
							completion
							projectPersons(first: 1000000, contactsOnly: true) {
								edges {
									node {
										id
										isContactPerson
										person {
											id
											fullName
											firstName
											lastName
										}
									}
								}
							}
							rateCard {
								id
								name
							}
							currentProjectStatus {
								id
								color
								description
							}
							aggregatedFinancialNumbers(
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								aggregateLevel: MONTH
								aggregates: [$selectedSingleValue]
							)
							timeAggregatedFinancialNumbers: aggregatedFinancialNumbers(
								searchQuery: $timeSearchQuery
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								aggregateLevel: MONTH
								aggregates: [$selectedSingleValue]
								fetchOwnData: true
							)
							expenseAggregatedFinancialNumbers: aggregatedFinancialNumbers(
								searchQuery: $expenseSearchQuery
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								aggregateLevel: MONTH
								aggregates: [$selectedSingleValue]
								fetchOwnData: true
							)
						}
					}
				}
			}
		}
	`,
});
