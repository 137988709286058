import {
	GROUP_SECTION_TEXT_GREY_DARK,
	HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_COMPLETION_BACKGROUND_COLOR,
	HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_OVERALLOCATED_BACKGROUND_COLOR,
} from '../../../canvas-timeline/canvas_timeline_util';

export default class HeatmapItemConfig {
	backgroundColor;
	backgroundOverAllocatedColor;
	barColor;
	barOverAllocatedColor;
	softStripeBackgroundColor;
	softStripBackgroundOverAllocatedColor;
	fontColor;

	constructor(backgroundColor, backgroundOverAllocatedColor, barColor, barOverAllocatedColor) {
		this.backgroundColor = backgroundColor;
		this.backgroundOverAllocatedColor = backgroundOverAllocatedColor;
		this.barColor = barColor;
		this.barOverAllocatedColor = barOverAllocatedColor;

		this.setDefaultValues();
	}

	setDefaultValues() {
		this.fontColor = GROUP_SECTION_TEXT_GREY_DARK;
		this.setSoftAllocationColors(
			HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_COMPLETION_BACKGROUND_COLOR,
			HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_OVERALLOCATED_BACKGROUND_COLOR
		);
	}

	useOverAllocatedColor(item) {
		return item.isOverAllocated && !item.isWithinCheckmarkRange;
	}

	getBackgroundColor(item) {
		return this.useOverAllocatedColor(item) ? this.backgroundOverAllocatedColor : this.backgroundColor;
	}

	getBarColor(item) {
		return this.useOverAllocatedColor(item) ? this.barOverAllocatedColor : this.barColor;
	}

	setFontColor(color) {
		this.fontColor = color;
	}

	getFontColor() {
		return this.fontColor;
	}

	setSoftAllocationColors(backgroundColor, backgroundOverAllocatedColor) {
		this.softStripeBackgroundColor = backgroundColor;
		this.softStripBackgroundOverAllocatedColor = backgroundOverAllocatedColor;
	}

	getSoftStripeBackgroundColor(item) {
		return this.useOverAllocatedColor(item) ? this.softStripBackgroundOverAllocatedColor : this.softStripeBackgroundColor;
	}
}
