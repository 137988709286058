import Moment from 'moment';
import {getProjectAllocationData, getProjectGroupAllocationData} from '../../scheduling/project_allocation_logic';
import {getVisualizationMode, ITEM_TYPE, VISUALIZATION_MODE} from '../canvas-timeline/canvas_timeline_util';
import DataManager from '../DataManager';

const addToRoleDataMap = (task, roleDataMap) => {
	const roleId = task.role ? task.role.id : null;
	if (!roleDataMap.has(roleId)) {
		roleDataMap.set(roleId, {
			allocated: 0,
			remaining: 0,
			minutesRegistered: 0,
			taskCount: 0,
			doneTaskCount: 0,
		});
	}
	const data = roleDataMap.get(roleId);
	let allocated = data.allocated;
	let remaining = data.remaining;
	let minutesRegistered = data.minutesRegistered;
	let taskCount = data.taskCount;
	let doneTaskCount = data.doneTaskCount;
	remaining += task.done ? 0 : task.timeLeft;
	if (task.assignedPersons.length) {
		allocated += task.done ? 0 : task.timeLeft;
	}
	minutesRegistered += task.totalMinutesRegistered;
	taskCount += 1;
	doneTaskCount += task.done ? 1 : 0;
	roleDataMap.set(roleId, {allocated, remaining, minutesRegistered, taskCount, doneTaskCount});
};

export const getAllocationData = (pageComponent, item) => {
	const data = pageComponent.getFilterData();
	const {company} = data;
	const isUsingProjectAllocation = getVisualizationMode(
		pageComponent.state.schedulingOptions,
		company,
		VISUALIZATION_MODE.ALLOCATION
	);
	let allocationData;

	const {projectGroup, project, phase} = item.data;
	const isProjectGroup = item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PROJECT && !!projectGroup;

	//Create data structure that can be used in project_allocation_logic
	if (isProjectGroup) {
		const childProjects = DataManager.getProjectsByProjectGroupId(pageComponent, projectGroup.id);
		const firstProject = childProjects[0];
		const {estimationUnit, minutesPerEstimationPoint} = firstProject;
		const roles = data.roles;

		const projectPersons = DataManager.getProjectPersonsByProjectId(pageComponent, firstProject.id).map(projectPerson => {
			return {
				node: {
					person: {
						id: projectPerson.personId,
					},
					role: projectPerson.roleId ? {id: projectPerson.roleId} : null,
				},
			};
		});

		const projects = {edges: []};
		for (const project of childProjects) {
			const {projectStartYear, projectStartMonth, projectStartDay, projectEndYear, projectEndMonth, projectEndDay} =
				project;

			const tasks = data.tasks
				.filter(task => task.projectId === project.id)
				.map(task => {
					return {
						node: {
							done: task.done,
							phase: task.phaseId ? {id: task.phaseId} : null,
							timeLeft: task.done ? 0 : task.timeLeft,
							totalMinutesRegistered: task.totalMinutesRegistered,
							role: task.roleId ? {id: task.roleId} : null,
							assignedPersons: task.assignedPersons.map(assignedPersonId => {
								return {id: assignedPersonId};
							}),
						},
					};
				});
			projects.edges.push({
				node: {
					id: project.id,
					estimationUnit,
					minutesPerEstimationPoint,
					projectStartYear,
					projectStartMonth,
					projectStartDay,
					projectEndYear,
					projectEndMonth,
					projectEndDay,
					tasks: {edges: tasks},
					projectPersons: {
						edges: projectPersons,
					},
				},
			});
		}

		const allocations = DataManager.getAllocationsByProject(pageComponent, null, projectGroup.id).map(allocation => {
			const person = DataManager.getPersonById(pageComponent, allocation.personId);
			return {
				monday: allocation.monday,
				tuesday: allocation.tuesday,
				wednesday: allocation.wednesday,
				thursday: allocation.thursday,
				friday: allocation.friday,
				saturday: allocation.saturday,
				sunday: allocation.sunday,
				startYear: allocation.startYear,
				startMonth: allocation.startMonth,
				startDay: allocation.startDay,
				endYear: allocation.endYear,
				endMonth: allocation.endMonth,
				endDay: allocation.endDay,
				person: {
					id: person.id,
					role: person.roleId ? {id: person.roleId} : null,
				},
				projectGroupId: allocation.projectGroupId,
			};
		});

		const fakeProjectGroup = {id: projectGroup.id, projects};

		if (isUsingProjectAllocation) {
			allocationData = getProjectGroupAllocationData(fakeProjectGroup, allocations, roles, Moment());
		} else {
			const roleDataMap = new Map();
			for (const projectEdge of fakeProjectGroup.projects.edges) {
				// if (childId && projectEdge.node.id !== childId) continue;
				for (const taskEdge of projectEdge.node.tasks.edges) {
					const task = taskEdge.node;
					addToRoleDataMap(task, roleDataMap);
				}
			}

			allocationData = {projectGroup: {roleDataMap}}; // childId ? {projects: [{id: childId, roleDataMap}]} : {projectGroup: {roleDataMap}};
		}
	} else {
		const {
			estimationUnit,
			minutesPerEstimationPoint,
			projectStartYear,
			projectStartMonth,
			projectStartDay,
			projectEndYear,
			projectEndMonth,
			projectEndDay,
		} = project;

		const phaseIds = DataManager.getPhaseIdsByProjectId(pageComponent, project.id);
		const phases = phaseIds.map(phaseId => {
			const phase = DataManager.getPhaseById(pageComponent, phaseId);
			return {node: phase};
		});

		const tasks = data.tasks
			.filter(task => task.projectId === project.id)
			.map(task => {
				return {
					node: {
						done: task.done,
						phase: task.phaseId ? {id: task.phaseId} : null,
						timeLeft: task.done ? 0 : task.timeLeft,
						totalMinutesRegistered: task.totalMinutesRegistered,
						role: task.roleId ? {id: task.roleId} : null,
						assignedPersons: task.assignedPersons.map(assignedPersonId => {
							return {id: assignedPersonId};
						}),
					},
				};
			});
		const roles = data.roles;
		const projectPersons = data.projectPersons
			.filter(projectPerson => projectPerson.projectId === project.id)
			.map(projectPerson => {
				return {
					node: {
						person: {
							id: projectPerson.personId,
						},
						role: projectPerson.roleId ? {id: projectPerson.roleId} : null,
					},
				};
			});
		const allocations = data.allocations
			.filter(allocation => allocation.projectId === project.id)
			.map(allocation => {
				const person = data.persons.find(person => person.id === allocation.personId);
				return {
					monday: allocation.monday,
					tuesday: allocation.tuesday,
					wednesday: allocation.wednesday,
					thursday: allocation.thursday,
					friday: allocation.friday,
					saturday: allocation.saturday,
					sunday: allocation.sunday,
					startYear: allocation.startYear,
					startMonth: allocation.startMonth,
					startDay: allocation.startDay,
					endYear: allocation.endYear,
					endMonth: allocation.endMonth,
					endDay: allocation.endDay,
					person: {
						id: person.id,
						role: person.roleId ? {id: person.roleId} : null,
					},
					project: {
						id: project.id,
						projectPersons: {edges: projectPersons},
					},
				};
			});
		const fakeProject = {
			id: project.id,
			estimationUnit,
			minutesPerEstimationPoint,
			projectStartYear,
			projectStartMonth,
			projectStartDay,
			projectEndYear,
			projectEndMonth,
			projectEndDay,
			tasks: {edges: tasks},
			phases: {edges: phases},
		};

		if (isUsingProjectAllocation) {
			allocationData = getProjectAllocationData(fakeProject, allocations, roles, Moment());
		} else {
			if (item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PHASE) {
				const roleDataMap = new Map();
				for (const taskEdge of fakeProject.tasks.edges) {
					const task = taskEdge.node;
					const taskPhaseId = task.phase ? task.phase.id : null;
					if (taskPhaseId !== phase.id) continue;
					addToRoleDataMap(task, roleDataMap);
				}
				allocationData = {phases: [{id: phase.id, roleDataMap}]};
			} else {
				const roleDataMap = new Map();
				for (const taskEdge of fakeProject.tasks.edges) {
					const task = taskEdge.node;
					addToRoleDataMap(task, roleDataMap);
				}
				allocationData = {project: {roleDataMap}};
			}
		}
	}

	return allocationData;
};
