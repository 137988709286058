import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';
import * as d3 from 'd3';

class PieChart extends Component {
	componentDidMount() {
		this.createChart(ReactDOM.findDOMNode(this), this.props);
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		this.createChart(ReactDOM.findDOMNode(this), nextProps);
	}

	createChart(dom, props) {
		// Remove old chart
		d3.select(dom).select('svg').remove();

		// const dataset = [
		//   { name: 'Abulia', count: 10 },
		//   { name: 'Betelfdsafdasdfasffasdfasdfasdfadsfasdfasdfasdfdsgeuse', count: 20 },
		//   { name: 'Cantaloupe', count: 30 },
		//   { name: 'Dijkstra', count: 40 },
		//   { name: 'fadsfdas', count: 50 },
		//   { name: 'vcx', count: 60 },
		//   { name: 'wefds', count: 70 }
		// ];

		const width = props.width;
		const height = props.height;
		const data = props.data;
		const sum = data.reduce(function (total, num) {
			return total + num.count;
		}, 0);

		// If sum is zero, add 1 to 'left' data so component shows 0% done chart
		if (sum === 0) data[1].count = 1;

		const chart = d3
			.select(dom)
			.append('svg')
			.attr('width', '100%')
			.attr('height', '100%')
			.append('g')
			.attr('transform', 'translate(' + (width / 2 + 10) + ',' + (height / 2 + 10) + ')');
		const outerRadius = width / 2;
		const arc = d3.arc().outerRadius(outerRadius).innerRadius(0);

		const colors = this.props.colors;
		const opacities = this.props.opacities;

		const pie = d3
			.pie()
			.value(function (d) {
				return d.count;
			})
			.sort(null);

		const g = chart
			.selectAll('.arc')
			.data(pie(data))
			.enter()
			.filter(function (d) {
				return d.value > 0;
			})
			.append('g')
			.attr('class', 'arc');
		// .on('mouseover', function (d, i) {
		//     // Bounces slice of the pie chart out
		//     d3.select(this)
		//         .transition()
		//         .duration(500)
		//         .ease(d3.easeBounce)
		//         .attr('transform', function (d) {
		//             var dist = 10;
		//             d.midAngle = ((d.endAngle - d.startAngle) / 2) + d.startAngle;
		//             var x = Math.sin(d.midAngle) * dist;
		//             var y = -Math.cos(d.midAngle) * dist;
		//             return 'translate(' + x + ',' + y + ')';
		//         });
		//     // Writes text in the middle of the pie chart with the % value of the hovered slice
		//     d3.select(this).append("text").style("fill", "black").attr("id", "percent")
		//         .attr('transform', "translate(0,-5)")
		//         .attr("text-anchor", "middle").attr("dy", ".35em").style("font", "bold 15px Arial")
		//         .text(function(d) { return (((d.value / sum) * 100).toFixed(1) + " %");
		//     });
		//     g.filter(function(e) { return e.value != d.value; } ).style('opacity', 0.5);
		// }).on('mouseout', function (d, i) {
		//     d3.select(this)
		//         .transition()
		//         .duration(500)
		//         .ease(d3.easeBounce)
		//         .attr('transform', 'translate(0,0)');
		//     d3.select("#percent").remove();
		//     g.filter(function(e) { return e.value != d.value; }).style('opacity', 1);
		// })

		// Draw Pie Chart without animation
		// g.append("path")
		//     .style("fill", function(d, i) { return colors[i]; })
		//     .attr("d", arc);

		// Draw Pie Chart with animation
		g.append('path')
			.style('fill', function (d) {
				return colors[d.index];
			})
			.style('opacity', function (d) {
				return opacities[d.index];
			})
			.style('stroke', 'white')
			.style('stroke-width', 1)
			.transition()
			.delay(function (d, i) {
				const percent = d.startAngle / (2 * Math.PI);
				return percent * 300;
			})
			.duration(function (d, i) {
				const angle = d.endAngle - d.startAngle;
				const percent = angle / (2 * Math.PI);
				return percent * 300;
			})
			.ease(d3.easeLinear)
			.attrTween('d', function (d) {
				var i = d3.interpolate(d.startAngle, d.endAngle);
				return function (t) {
					d.endAngle = i(t);
					return arc(d);
				};
			});

		// Writes % value on each pie slice
		g.append('text')
			// Translate the %-value text to the middle of its pie slice unless we are showing a whole pie.
			.attr('transform', function (d) {
				return sum === 0 || d.value === sum ? null : 'translate(' + arc.centroid(d) + ')';
			})
			.text(function (d, i) {
				if (sum === 0) return '0%';
				return (d.index === 0 || d.value !== sum ? (d.value / sum) * 100 : 0).toFixed(0) + '%';
			})
			.attr('text-anchor', 'middle')
			.attr('alignment-baseline', 'middle')
			.style('font-family', 'neue-haas-grotesk-text');

		const legend = chart
			.selectAll('.legend')
			.data(data)
			.enter()
			.append('g')
			.attr('class', 'legend')
			.attr('transform', function (d, i) {
				return 'translate(' + (width / 2 + 10) + ',' + (i * 20 - data.length * 10) + ')';
			});

		const rect = legend
			.append('rect')
			.attr('width', 18)
			.attr('height', 18)
			.style('fill', function (d, i) {
				return colors[i];
			})
			.style('opacity', 0);

		const name = legend
			.append('text')
			.attr('x', 24)
			.attr('y', 12)
			.text(function (d) {
				// If the sum is 0, show 0 instead of count so we don't show the '1' we added to the data earlier.
				let text = ' ' + d.name;
				if (text.length > 25) {
					text = text.substring(0, 24);
					text += '...';
				}
				return text;
			})
			.style('opacity', 0)
			.style('font-family', 'neue-haas-grotesk-text')
			.style('font-size', '0.7rem');
		rect.transition()
			.delay(function (d, i) {
				return (i / data.length) * 300;
			})
			.duration(200)
			.style('opacity', function (d, i) {
				return opacities[i];
			});
		name.transition()
			.delay(function (d, i) {
				return (i / data.length) * 300;
			})
			.duration(200)
			.style('opacity', 1);
	}

	render() {
		return <div />;
	}
}

PieChart.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	title: PropTypes.string,
	data: PropTypes.array.isRequired,
};

export default PieChart;
