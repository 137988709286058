import {projectFilter} from './financial_report_page_filter';
import {graphql} from 'react-relay';
import {cloneDeep} from 'lodash';
import util from '../../../forecast-app/shared/util/util';
import DirectApi from '../../../directApi';

const fetchCalculatedFields = async projectStage => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	let abortController;
	if (window.AbortController) {
		abortController = new window.AbortController();
	}
	const signal = abortController ? abortController.signal : undefined;
	const init = {headers, signal, credentials: 'include', method: 'POST', body: JSON.stringify({status: projectStage})};

	let requestUrl = DirectApi.graphqlServerEndpoint('calculated_fields_by_status');

	return await fetch(requestUrl, init)
		.then(response => response.json())
		.catch(error => {
			//Most likely aborted in unmount
			if (!('' + error).includes('abort')) {
				throw error;
			}
		});
};

const fetchFinancialData = async (filteredProjectIds, dateRangeItem, ignoreCache) => {
	const parameters = {ids: filteredProjectIds, ignoreCache};
	if (dateRangeItem && dateRangeItem.startDate && dateRangeItem.endDate) {
		parameters.startYear = dateRangeItem.startDate.year();
		parameters.startMonth = dateRangeItem.startDate.month() + 1;
		parameters.startDay = dateRangeItem.startDate.date();
		parameters.endYear = dateRangeItem.endDate.year();
		parameters.endMonth = dateRangeItem.endDate.month() + 1;
		parameters.endDay = dateRangeItem.endDate.date();
	}

	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	let abortController;
	if (window.AbortController) {
		abortController = new window.AbortController();
	}
	const signal = abortController ? abortController.signal : undefined;
	const init = {headers, signal, credentials: 'include', method: 'POST', body: JSON.stringify(parameters)};

	let requestUrl = DirectApi.graphqlServerEndpoint('financial_portfolio_report');

	return await fetch(requestUrl, init).then(response => response.json());
};

const mergeRevCosProMarOnGroup = (projectGroup, fullProject, fieldName) => {
	const projectField = fullProject[fieldName];

	if (projectField) {
		let projectGroupField = projectGroup[fieldName];
		if (!projectGroupField) {
			projectGroupField = {
				rev: 0,
				cos: 0,
			};
			projectGroup[fieldName] = projectGroupField;
		}
		if (projectField.rev) {
			projectGroupField.rev += projectField.rev;
		}
		if (projectField.cos) {
			projectGroupField.cos += projectField.cos;
		}
		projectGroupField.pro = projectGroupField.rev - projectGroupField.cos;
		if (projectGroupField.rev !== 0) {
			projectGroupField.mar = projectGroupField.pro / projectGroupField.rev;
		} else {
			projectGroupField.mar = 0.0;
		}

		return projectGroupField;
	}
	return {rev: 0};
};

const mergeRevCosProMarPerTotOnGroup = (projectGroup, fullProject, fieldName) => {
	const projectField = fullProject[fieldName];

	if (projectField) {
		let projectGroupField = projectGroup[fieldName];
		if (!projectGroupField) {
			projectGroupField = {
				rev: {
					per: 0,
					tot: 0,
				},
				cos: {
					per: 0,
					tot: 0,
				},
				pro: {},
				mar: {},
			};
			projectGroup[fieldName] = projectGroupField;
		}
		if (projectField.rev && projectField.rev.per) {
			projectGroupField.rev.per += projectField.rev.per;
		}
		if (projectField.cos && projectField.cos.per) {
			projectGroupField.cos.per += projectField.cos.per;
		}
		if (projectField.rev && projectField.rev.tot) {
			projectGroupField.rev.tot += projectField.rev.tot;
		}
		if (projectField.cos && projectField.cos.tot) {
			projectGroupField.cos.tot += projectField.cos.tot;
		}

		projectGroupField.pro.per = projectGroupField.rev.per - projectGroupField.cos.per;
		projectGroupField.pro.tot = projectGroupField.rev.tot - projectGroupField.cos.tot;
		if (projectGroupField.rev.per !== 0) {
			projectGroupField.mar.per = projectGroupField.pro.per / projectGroupField.rev.per;
		} else {
			projectGroupField.mar.per = 0.0;
		}
		if (projectGroupField.rev.tot !== 0) {
			projectGroupField.mar.tot = projectGroupField.pro.tot / projectGroupField.rev.tot;
		} else {
			projectGroupField.mar.tot = 0.0;
		}
	}
};

const mergePerTotOnGroup = (projectGroup, fullProject, fieldName) => {
	const projectField = fullProject[fieldName];

	if (projectField) {
		let projectGroupField = projectGroup[fieldName];
		if (!projectGroupField) {
			projectGroupField = {
				per: 0,
				tot: 0,
			};
			projectGroup[fieldName] = projectGroupField;
		}
		if (projectField.per) {
			projectGroupField.per += projectField.per;
		}
		if (projectField.tot) {
			projectGroupField.tot += projectField.tot;
		}
	}
};

const mergeAppAllPerTotOnGroup = (projectGroup, fullProject, fieldName) => {
	const projectField = fullProject[fieldName];

	if (projectField) {
		let projectGroupField = projectGroup[fieldName];
		if (!projectGroupField) {
			projectGroupField = {
				app: {
					per: 0,
					tot: 0,
				},
				all: {
					per: 0,
					tot: 0,
				},
			};
			projectGroup[fieldName] = projectGroupField;
		}
		if (projectField.app && projectField.app.per) {
			projectGroupField.app.per += projectField.app.per;
		}
		if (projectField.all && projectField.all.per) {
			projectGroupField.all.per += projectField.all.per;
		}
		if (projectField.app && projectField.app.tot) {
			projectGroupField.app.tot += projectField.app.tot;
		}
		if (projectField.all && projectField.all.tot) {
			projectGroupField.all.tot += projectField.all.tot;
		}
	}
};

const mergeInvoiceOnGroup = (projectGroup, fullProject, fieldName, fixedPrice) => {
	const projectField = fullProject[fieldName];

	if (projectField) {
		let projectGroupField = projectGroup[fieldName];
		const setInvoicedOnFixedPrice = projectGroupField => {
			projectGroupField.invoicedOnFixedPrice = {
				per: 0,
				tot: 0,
			};
		};
		if (!projectGroupField) {
			projectGroupField = {
				invoicedPrice: {
					per: 0,
					tot: 0,
				},
				invoicedPaid: {
					per: 0,
					tot: 0,
				},
			};
			setInvoicedOnFixedPrice(projectGroupField);
			projectGroup[fieldName] = projectGroupField;
		}
		if (projectField.invoicedPrice && projectField.invoicedPrice.per) {
			projectGroupField.invoicedPrice.per += projectField.invoicedPrice.per;
		}
		if (projectField.invoicedPaid && projectField.invoicedPaid.per) {
			projectGroupField.invoicedPaid.per += projectField.invoicedPaid.per;
		}
		if (projectField.invoicedPrice && projectField.invoicedPrice.tot) {
			projectGroupField.invoicedPrice.tot += projectField.invoicedPrice.tot;
		}
		if (projectField.invoicedPaid && projectField.invoicedPaid.tot) {
			projectGroupField.invoicedPaid.tot += projectField.invoicedPaid.tot;
		}
		if (fixedPrice && projectGroupField.invoicedPrice.per > 0) {
			projectGroupField.invoicedOnFixedPrice.per = projectGroupField.invoicedPrice.per / fixedPrice;
		}
		if (fixedPrice && projectGroupField.invoicedPrice.tot > 0) {
			if (!projectGroupField.invoicedOnFixedPrice) {
				setInvoicedOnFixedPrice(projectGroupField);
			}
			projectGroupField.invoicedOnFixedPrice.tot = projectGroupField.invoicedPrice.tot / fixedPrice;
		}
	}
};

const aggregateOnProjectGroups = (projectGroups, fullProjects) => {
	const fullProjectMap = fullProjects.reduce((lookupMap, fullProject) => {
		lookupMap[fullProject.id] = fullProject;
		return lookupMap;
	}, {});

	projectGroups.edges
		.map(projectGroup => projectGroup.node)
		.forEach(projectGroup => {
			projectGroup.isConnectedProject = true;
			projectGroup.details = projectGroup;
			projectGroup.projectStats = {};
			projectGroup.startDate = null;
			projectGroup.endDate = null;
			projectGroup.fixedPrice = null;
			projectGroup.actualPrice = null;
			projectGroup.planPrice = null;
			projectGroup.remainingPrice = null;
			projectGroup.forecastPrice = null;
			projectGroup.actualVarianceToPlanPrice = null;
			projectGroup.forecastVarianceToPlanPrice = null;
			projectGroup.companyFinancials = null;
			projectGroup.registeredHours = null;
			projectGroup.remainingHours = null;
			projectGroup.forecastHours = null;
			projectGroup.estimatedHours = null;
			projectGroup.lowHours = null;
			projectGroup.highHours = null;
			projectGroup.invoice = null;
			projectGroup.baseline = null;

			projectGroup.projects.edges
				.map(project => project.node)
				.forEach(project => {
					const fullProject = fullProjectMap[project.id];

					// Only process projects that are in the filter
					if (fullProject) {
						// DATES
						if (fullProject.details.startDate) {
							if (
								projectGroup.startDate === null ||
								projectGroup.startDate.isAfter(fullProject.details.startDate)
							) {
								projectGroup.startDate = fullProject.details.startDate;
							}
						}
						if (fullProject.details.endDate) {
							if (projectGroup.endDate === null || projectGroup.endDate.isBefore(fullProject.details.endDate)) {
								projectGroup.endDate = fullProject.details.endDate;
							}
						}

						const projectGroupFixedPrice = mergeRevCosProMarOnGroup(projectGroup, fullProject, 'fixedPrice');
						mergeRevCosProMarPerTotOnGroup(projectGroup, fullProject, 'actualPrice');
						mergeRevCosProMarPerTotOnGroup(projectGroup, fullProject, 'planPrice');
						mergeRevCosProMarPerTotOnGroup(projectGroup, fullProject, 'remainingPrice');
						mergeRevCosProMarPerTotOnGroup(projectGroup, fullProject, 'forecastPrice');
						mergeRevCosProMarPerTotOnGroup(projectGroup, fullProject, 'actualVarianceToPlanPrice');
						mergeRevCosProMarPerTotOnGroup(projectGroup, fullProject, 'forecastVarianceToPlanPrice');
						mergeRevCosProMarPerTotOnGroup(projectGroup, fullProject, 'baseline');
						mergeRevCosProMarOnGroup(projectGroup, fullProject, 'companyFinancials');
						mergePerTotOnGroup(projectGroup, fullProject, 'registeredHours');
						mergePerTotOnGroup(projectGroup, fullProject, 'remainingHours');
						mergePerTotOnGroup(projectGroup, fullProject, 'forecastHours');
						mergeAppAllPerTotOnGroup(projectGroup, fullProject, 'estimatedHours');
						mergeAppAllPerTotOnGroup(projectGroup, fullProject, 'lowHours');
						mergeAppAllPerTotOnGroup(projectGroup, fullProject, 'highHours');
						mergeInvoiceOnGroup(projectGroup, fullProject, 'invoice', projectGroupFixedPrice.rev);
					}
				});
		});
};

export const fetchData = async (company, projects, projectGroups, filters, dateRangeItem, setReportData, ignoreCache) => {
	// Load calculated project data
	let projectStages = null;
	if (filters.project && filters.project.projectStage && filters.project.projectStage.length > 0) {
		projectStages = filters.project.projectStage;
	} else {
		projectStages = ['PLANNING', 'RUNNING', 'HALTED', 'DONE', 'OPPORTUNITY'];
	}

	const calculatedDataMap = new Map();
	for (const projectStage of projectStages) {
		const projectsWithCalculatedFields = await fetchCalculatedFields(projectStage);
		for (const projectData of projectsWithCalculatedFields) {
			calculatedDataMap.set(projectData.id, projectData);
		}
	}

	// Filter projects
	const filteredProjects = projects.edges
		.map(project => project.node)
		.filter(project => projectFilter(project, filters, calculatedDataMap));
	const filteredProjectMap = cloneDeep(filteredProjects).reduce((lookupMap, project) => {
		project.startDate = util.CreateNonUtcMomentDate(
			project.projectStartYear,
			project.projectStartMonth,
			project.projectStartDay
		);
		project.endDate = util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay);
		lookupMap[project.id] = project;
		return lookupMap;
	}, {});
	const filteredProjectIds = Object.keys(filteredProjectMap);

	// Load project financial details
	const reportData = await fetchFinancialData(filteredProjectIds, dateRangeItem, ignoreCache);

	const roleMap = company.roles.edges.reduce((lookupMap, role) => {
		lookupMap[role.node.id] = role.node.name;
		return lookupMap;
	}, {});

	const fullProjects = reportData.projects.map(project => {
		project.id = project.projectId;
		project.details = filteredProjectMap[project.id];
		project.details.origIsInProjectGroup = project.details.isInProjectGroup;
		project.calculatedFields = calculatedDataMap.get(project.id);
		project.expanded = false;
		project.roleMap = roleMap;
		project.phaseMap = project.details.phases.edges
			.map(phase => {
				phase.node.startDate = util.CreateNonUtcMomentDate(
					phase.node.startYear,
					phase.node.startMonth,
					phase.node.startDay
				);
				phase.node.endDate = util.CreateNonUtcMomentDate(
					phase.node.deadlineYear,
					phase.node.deadlineMonth,
					phase.node.deadlineDay
				);
				return phase.node;
			})
			.reduce((lookupMap, phase) => {
				return {...lookupMap, [phase.id]: phase};
			}, {});
		return project;
	});

	// Aggregate project data on project group level
	aggregateOnProjectGroups(projectGroups, fullProjects);

	setReportData({
		loading: false,
		cachedTime: reportData.cachedTime,
		totals: reportData.totals,
		projects: fullProjects,
		projectGroups,
	});
};

export const financialReportPageDataFetch = graphql`
	fragment financialReportPageDataFetch_viewer on Viewer {
		id
		backendId
		firstName
		actualPersonId
		availableFeatureFlags {
			key
		}
		client {
			id
		}
		filters(first: 10000, filterSection: FINANCIAL_PORTFOLIO_REPORT) @connection(key: "Viewer_filters", filters: []) {
			edges {
				node {
					id
					name
					section
					value
					updatedAt
				}
			}
		}
		company {
			currency
			xeroEnabled
			isUsingSchedulingPlanMode
			isUsingProjectAllocation
			isUsingMixedAllocation
			modules {
				moduleType
			}
			labels(first: 1000) @connection(key: "Company_labels", filters: []) {
				edges {
					node {
						id
						name
						color
						...LabelDropdown_labels
					}
				}
			}
			clients(first: 1000) {
				edges {
					...ClientDropdown_clients
					node {
						id
						name
						logoId
						logoDefaultId
					}
				}
			}
			rateCards(first: 1000) {
				edges {
					...RateCardDropdown_rateCards
					node {
						id
						name
						parentRateCardId
					}
				}
			}
			roles(first: 1000) {
				edges {
					node {
						id
						name
					}
				}
			}
			allPersons(first: 10000, onlyActive: true, excludeClientUsers: true) {
				edges {
					node {
						id
						firstName
						lastName
						profilePictureId
						profilePictureDefaultId
						role {
							id
							name
						}
					}
				}
			}
			teams(first: 1000) {
				edges {
					node {
						id
						name
					}
				}
			}
		}
		projectGroups(first: 100000) {
			edges {
				node {
					id
					companyProjectGroupId
					name
					color
					projects(first: 100000) {
						edges {
							node {
								id
								name
								budgetType
								status
								description
								statusColor
								companyProjectId
								budget
								budgetBaseCurrency
								projectColor
								estimationUnit
								minutesPerEstimationPoint
								projectStartDay
								projectStartMonth
								projectStartYear
								projectEndDay
								projectEndMonth
								projectEndYear
								sprintTimeBox
								currentProjectStatus {
									id
									color
									person {
										id
										firstName
										lastName
									}
								}
								client {
									id
									name
									logoId
									logoDefaultId
								}
								rateCard {
									id
									name
									currency
									parentRateCardId
								}
								projectLabels(first: 10000) {
									edges {
										node {
											id
											label {
												id
												name
												color
											}
										}
									}
								}
								projectPersons(first: 10000) {
									edges {
										node {
											id
											isContactPerson
											person {
												id
												firstName
												lastName
												profilePictureId
												profilePictureDefaultId
												initials
											}
										}
									}
								}
								xeroInvoices {
									id
									amountTotal
									amountPaid
								}
								invoices(first: 100000000) {
									edges {
										node {
											id
											invoiceReference
											companyInvoiceId
											name
											invoiceType
											status
											dueDay
											dueMonth
											dueYear
											createdDay
											createdMonth
											createdYear
											notes
											quickbooksId
											xeroId
											economicId
											entries(first: 100000000) {
												edges {
													node {
														id
														name
														quantity
														unitPrice
														discount
														tax
														description
													}
												}
											}
											payments(first: 100000000) {
												edges {
													node {
														id
														notes
														amount
														day
														month
														year
														createdAt
														createdBy {
															fullName
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		projects(first: 1000000) @connection(key: "Viewer_projects", filters: []) {
			edges {
				...ProjectContactDropdown_projects
				node {
					id
					demo
					name
					budgetType
					defaultPeriodBudgetType
					status
					description
					companyProjectId
					budget
					budgetBaseCurrency
					projectColor
					estimationUnit
					minutesPerEstimationPoint
					projectStartDay
					projectStartMonth
					projectStartYear
					projectEndDay
					projectEndMonth
					projectEndYear
					sprintTimeBox
					isInProjectGroup
					baselineWinChance
					useBaseline
					currentProjectStatus {
						id
						color
						person {
							id
							firstName
							lastName
							fullName
							profilePictureId
						}
					}
					client {
						id
						name
						logoId
						logoDefaultId
					}
					rateCard {
						id
						name
						currency
						parentRateCardId
					}
					projectLabels(first: 10000) {
						edges {
							node {
								id
								label {
									id
									name
									color
								}
							}
						}
					}
					projectPersons(first: 1000000, contactsOnly: true) {
						edges {
							node {
								id
								isContactPerson
								person {
									id
									fullName
									firstName
									lastName
									profilePictureId
									profilePictureDefaultId
									initials
								}
							}
						}
					}
					phases(first: 10000) {
						edges {
							node {
								id
								name
								startDay
								startMonth
								startYear
								deadlineDay
								deadlineMonth
								deadlineYear
							}
						}
					}
				}
			}
		}
	}
`;
