import {useRemappingFormatMessage} from '../../../forecast-app/shared/util/FinancialInternationalisationUtil';

export const PROJECT_PORTFOLIO_SINGLE_NUMBER = {
	BILLABLE_ACTUAL_TIME_AND_EXPENSES: 'billableActualTimeAndExpenses',
	ACTUAL_COST: 'actualCost',
	ACTUAL_PROFIT: 'actualProfit',
	PLAN_REVENUE: 'billablePlannedTimeAndExpenses',
	PLAN_COST: 'plannedCost',
	PLAN_PROFIT: 'plannedProfit',
	BILLABLE_FORECAST_TIME_AND_EXPENSES_TO_COMPLETE: 'billableForecastTimeAndExpensesToComplete',
	FORECAST_COST_TO_COMPLETE: 'forecastCostToComplete',
	FORECAST_PROFIT_TO_COMPLETE: 'forecastProfitToComplete',
	BILLABLE_TOTAL_TIME_AND_EXPENSES_AT_COMPLETION: 'billableTotalTimeAndExpensesAtCompletion',
	TOTAL_COST_AT_COMPLETION: 'totalCostAtCompletion',
	TOTAL_PROFIT_AT_COMPLETION: 'totalProfitAtCompletion',
	INVOICED: 'invoiced',
	INVOICE_PAID: 'paid',
	RECOGNITION_AMOUNT: 'recognitionAmount',
};

export const COST_VALUES = [
	PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_COST,
	PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_PROFIT,
	PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_COST,
	PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_PROFIT,
	PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_COST_TO_COMPLETE,
	PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_PROFIT_TO_COMPLETE,
	PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_COST_AT_COMPLETION,
	PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_PROFIT_AT_COMPLETION,
];

export const getSingleNumberLabel = (singleNumber, intl) => {
	const formatMessage = useRemappingFormatMessage(intl);
	switch (singleNumber) {
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_ACTUAL_TIME_AND_EXPENSES:
			return formatMessage({id: 'project_budget.actual_revenue'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_COST:
			return formatMessage({id: 'common.actual_cost'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_PROFIT:
			return formatMessage({id: 'project_budget.actual_profit'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_REVENUE:
			return formatMessage({id: 'project_budget.plan_revenue'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_COST:
			return formatMessage({id: 'project_budget.plan_cost'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_PROFIT:
			return formatMessage({id: 'project_budget.plan_profit'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_FORECAST_TIME_AND_EXPENSES_TO_COMPLETE:
			return formatMessage({id: 'project_budget.remaining_revenue'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_COST_TO_COMPLETE:
			return formatMessage({id: 'project_budget.remaining_cost'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_PROFIT_TO_COMPLETE:
			return formatMessage({id: 'project_budget.remaining_profit'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_TOTAL_TIME_AND_EXPENSES_AT_COMPLETION:
			return formatMessage({id: 'project_budget.forecast_revenue'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_COST_AT_COMPLETION:
			return formatMessage({id: 'project_budget.forecast_cost'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_PROFIT_AT_COMPLETION:
			return formatMessage({id: 'project_budget.forecast_profit'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.INVOICED:
			return formatMessage({id: 'common.invoiced'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.INVOICE_PAID:
			return formatMessage({id: 'project_budget.invoice_paid'});
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.RECOGNITION_AMOUNT:
			return formatMessage({id: 'project_budget.recognition_amount'});
	}
};
