import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import {fetchData, fetchMapData} from '../reported_time_financials_fetch';
import {getSprintRow} from '../rows/sprint_row';
import BaseLevelWrapper from './BaselevelWrapper';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {hasSomePermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {useDidObjectChange} from '../../../../forecast-app/shared/hooks/useDidObjectChange';

const sprintRelayWrapper = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
}) => {
	const sprints = viewer.company.allSprintsGlobalSearch.edges;
	const currency = viewer.company.currency;
	const hasNextPage = viewer.company.allSprintsGlobalSearch.pageInfo.hasNextPage;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const [sprintsFinancialMap, setSprintsFinancialMap] = useState(null);
	const [nonSprintTotalMinutesRegistered, setNonSprintTotalMinutesRegistered] = useState(null);
	const [nonSprintTotalFinancials, setNonSprintTotalFinancials] = useState(null);

	const sprintsChanged = useDidObjectChange(sprints, null);
	const searchQueryChanged = useDidObjectChange(searchQuery, null);
	const shouldRefetchFinancials = sprintsChanged || searchQueryChanged;

	useEffect(() => {
		if (shouldRefetchFinancials) {
			const sprintsIds = sprints.map(sprint => sprint.node.id);
			if (
				hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				])
			) {
				fetchMapData(sprintsIds, searchQuery, 'total_sprint_time_regs_financials').then(data => {
					const sprintsFinancialMap = new Map();
					Object.keys(data).forEach(key => {
						sprintsFinancialMap.set(key, data[key]);
					});

					setSprintsFinancialMap(sprintsFinancialMap);
				});
			}
			if (!hasNextPage) {
				fetchData(searchQuery, 'non_sprint_total_minutes_registered').then(data => {
					if (data.total !== undefined) {
						setNonSprintTotalMinutesRegistered(data.total);
					}
				});
				if (
					hasSomePermission([
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
					])
				) {
					fetchData(searchQuery, 'total_non_sprint_time_regs_financials').then(data => {
						setNonSprintTotalFinancials(data.totals);
					});
				}
			}
		}
	}, [shouldRefetchFinancials]);

	const dataRows = sprints.map(sprint => {
		return {
			...sprint.node,
			totalPrice:
				sprintsFinancialMap !== null && sprintsFinancialMap.has(sprint.node.id)
					? sprintsFinancialMap.get(sprint.node.id).totalPrice
					: null,
			totalCost:
				sprintsFinancialMap !== null && sprintsFinancialMap.has(sprint.node.id)
					? sprintsFinancialMap.get(sprint.node.id).totalCost
					: null,
		};
	});

	nonSprintTotalMinutesRegistered !== null &&
		dataRows.push({
			isNoSprint: true,
			name: intl.formatMessage({id: 'time_reg_report.no_sprint'}),
			totalMinutesRegistered: nonSprintTotalMinutesRegistered,
			totalPrice: nonSprintTotalFinancials ? nonSprintTotalFinancials.totalPrice : null,
			totalCost: nonSprintTotalFinancials ? nonSprintTotalFinancials.totalCost : null,
		});

	const data = {rows: dataRows};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = sprints.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const sprintRows = () => {
		return getSprintRow(
			data,
			currency,
			intl,
			groupIndex,
			sprintsFinancialMap,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader
		);
	};

	return groupIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={sprintRows}
			currentLength={sprints.length}
			hasNextPage={viewer.company.allSprintsGlobalSearch.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		sprintRows()
	);
};

const sprintRelayWrapperQuery = graphql`
	query sprintRelayWrapperQuery_Query($searchQuery: TaskSearchQueryType, $pageSize: Int) {
		viewer {
			actualPersonId
			component(name: "sprint_relay_wrapper")
			...sprintRelayWrapper_viewer @arguments(searchQuery: $searchQuery, pageSize: $pageSize)
		}
	}
`;
export {sprintRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			sprintRelayWrapper,
			{
				viewer: graphql`
					fragment sprintRelayWrapper_viewer on Viewer
					@argumentDefinitions(searchQuery: {type: "TaskSearchQueryType"}, pageSize: {type: "Int"}) {
						company {
							currency
							allSprintsGlobalSearch(first: $pageSize, searchQuery: $searchQuery) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										name
										totalMinutesRegistered(searchQuery: $searchQuery)
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query sprintRelayWrapperRefetchQuery($pageSize: Int, $searchQuery: TaskSearchQueryType) {
					viewer {
						...sprintRelayWrapper_viewer @arguments(pageSize: $pageSize, searchQuery: $searchQuery)
					}
				}
			`
		)
	)
);
