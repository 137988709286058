import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Filter from '../../filters/filter';

class CardListConfig extends Component {
	constructor(props) {
		super(props);

		this.inputs = {};
		const config = JSON.parse(props.config || '{}');
		this.state = {
			labelFilterValue: config.labelFilterValue || [],
			clientFilterValue: config.clientFilterValue || [],
			statusColumnFilterValue: config.statusColumnFilterValue || [],
			includeDeactivatedFilterValue: config.includeDeactivatedFilterValue || false,
		};
	}

	handleLabelFilterChange(value) {
		this.setState({labelFilterValue: value});
		const config = JSON.parse(this.props.config || '{}');
		config.labelFilterValue = value;
		if (this.props.updateComponentConfiguration) {
			this.props.updateComponentConfiguration(this.props.id, config);
		}
	}

	handleClientFilterChange(value) {
		this.setState({clientFilterValue: value});
		const config = JSON.parse(this.props.config || '{}');
		config.clientFilterValue = value;
		if (this.props.updateComponentConfiguration) {
			this.props.updateComponentConfiguration(this.props.id, config);
		}
	}

	handleStatusColumnFilterChange(value) {
		this.setState({statusColumnFilterValue: value});
		const config = JSON.parse(this.props.config || '{}');
		config.statusColumnFilterValue = value;
		if (this.props.updateComponentConfiguration) {
			this.props.updateComponentConfiguration(this.props.id, config);
		}
	}

	handleIncludeDeactivatedFilterChange(value) {
		const booleanValue = value && value[0] ? value[0].value : false;
		this.setState({includeDeactivatedFilterValue: booleanValue});
		const config = JSON.parse(this.props.config || '{}');
		config.includeDeactivatedFilterValue = booleanValue;
		if (this.props.updateComponentConfiguration) {
			this.props.updateComponentConfiguration(this.props.id, config);
		}
	}

	clearFilters() {
		this.setState({labelFilterValue: [], statusColumnFilterValue: [], includeDeactivatedFilterValue: false});
		const config = JSON.parse(this.props.config || '{}');
		config.labelFilterValue = [];
		config.statusColumnFilterValue = [];
		if (this.props.updateComponentConfiguration) {
			this.props.updateComponentConfiguration(this.props.id, config);
		}
	}

	render() {
		const {formatMessage} = this.props.intl;

		const filters = [];
		if (this.props.useLabelsFilter) {
			const labelFilterOptions = this.props.labelsOptions.map(label => {
				return {value: label.id, label: label.name};
			});
			const labelFilters = {
				options: labelFilterOptions,
				onChange: this.handleLabelFilterChange.bind(this),
				value: this.state.labelFilterValue,
				placeholder: formatMessage({id: 'common.filter-by-label'}),
				label: formatMessage({id: 'common.label'}),
			};
			filters.push(labelFilters);
		}
		if (this.props.useClientsFilter && this.props.clientsOptions) {
			const clientFilterOptions = this.props.clientsOptions.map(client => {
				return {value: client.id, label: client.name};
			});
			const clientFilters = {
				options: clientFilterOptions,
				onChange: this.handleClientFilterChange.bind(this),
				value: this.state.clientFilterValue,
				placeholder: formatMessage({id: 'common.filter-by-client'}),
				label: formatMessage({id: 'common.client'}),
			};
			filters.push(clientFilters);
		}
		if (this.props.useColumnsFilter) {
			const statusColumnFilterOptions = [];
			const addedColumnKeys = [];
			if (this.props.statusColumnsOptions) {
				this.props.statusColumnsOptions.forEach(statusCol => {
					const colKey = statusCol.name.toLowerCase() + '_' + statusCol.category;
					if (!addedColumnKeys.includes(colKey)) {
						statusColumnFilterOptions.push({
							value: colKey,
							label: statusCol.name,
							name: statusCol.name,
							category: statusCol.category,
						});
						addedColumnKeys.push(colKey);
					}
				});
			}
			const statusColumnFilters = {
				options: statusColumnFilterOptions,
				onChange: this.handleStatusColumnFilterChange.bind(this),
				value: this.state.statusColumnFilterValue,
				placeholder: formatMessage({id: 'common.filter-by-status'}),
				label: formatMessage({id: 'common.status'}),
			};
			filters.push(statusColumnFilters);
		}
		if (this.props.useIncludeDeactivatedPeopleOption) {
			const includeDeactivatedPeopleString = formatMessage({id: 'insight.include_deactivated_people'});
			const includeDeactivatedFilters = {
				options: [{value: true, label: includeDeactivatedPeopleString}],
				onChange: this.handleIncludeDeactivatedFilterChange.bind(this),
				value: this.state.includeDeactivatedFilterValue ? [{value: true, label: includeDeactivatedPeopleString}] : [],
				placeholder: includeDeactivatedPeopleString,
				label: includeDeactivatedPeopleString,
			};
			filters.push(includeDeactivatedFilters);
		}

		return (
			<div className="forecast-actions-insights">
				{this.state.labelFilterValue.length > 0 ||
				this.state.statusColumnFilterValue.length > 0 ||
				this.state.includeDeactivatedFilterValue.length > 0 ? (
					<h3 className="clear-filters" onClick={this.clearFilters.bind(this)}>
						{formatMessage({id: 'common.clear-filters'})}
					</h3>
				) : null}
				<Filter filters={filters} />
			</div>
		);
	}
}

CardListConfig.propTypes = {
	id: PropTypes.string.isRequired,
	updateComponentConfiguration: PropTypes.func.isRequired,
	labelsOptions: PropTypes.array.isRequired,
	clientsOptions: PropTypes.array,
	useLabelsFilter: PropTypes.bool,
	useColumnsFilter: PropTypes.bool,
	useClientsFilter: PropTypes.bool,
};

export default injectIntl(CardListConfig);
