import {createFragmentContainer, graphql} from 'react-relay';
import {decompressData} from '../util/DataDecompressUtil';
import {getCurrency} from '../util/BudgetUtils';

const DetailsLoader = ({relay, viewer, children, aggregateLevel, aggregates, ...props}) => {
	// "aggregatedFinancialNumbers" used in top level breakdown
	// "financialNumbers" used in right column totals
	const {aggregatedFinancialNumbers, financialNumbers} = viewer.project;
	const currency = getCurrency(viewer.company, viewer.project);
	const processedData = decompressData(
		aggregatedFinancialNumbers,
		financialNumbers,
		currency,
		aggregateLevel,
		aggregates,
		false
	);
	const result = {data: {rows: [...processedData.rows], columns: [...processedData.columns]}, ...props};
	return children(result);
};

export const detailsLoaderQuery = graphql`
	query DetailsLoader_Query(
		$projectId: ID!
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$aggregateLevel: AggregateLevel!
		$aggregates: [FinancialAggregatedNumber]!
		$searchQuery: TaskSearchQueryType
	) {
		viewer {
			actualPersonId
			component(name: "details_loader")
			...DetailsLoader_viewer
				@arguments(
					projectId: $projectId
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: $aggregates
				)
		}
	}
`;

export default createFragmentContainer(DetailsLoader, {
	viewer: graphql`
		fragment DetailsLoader_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID!"}
			searchQuery: {type: "TaskSearchQueryType"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
			aggregateLevel: {type: "AggregateLevel!"}
			aggregates: {type: "[FinancialAggregatedNumber]!"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				id
				useFixedPriceForBaselineRevenue
				rateCard {
					currency
				}
				aggregatedFinancialNumbers(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: $aggregates
					convertToProjectCurrency: true
				)
				financialNumbers(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					convertToProjectCurrency: true
				) {
					baselineRevenue
					baselineCost
					baselineProfit
					baselineMargin
					baselineVsBillableActualTimeAndExpenses
					baselineVsActualCost
					baselineVsActualProfit
					baselineVsActualMargin
					baselineVsActualRevenueProfit
					baselineVsActualRevenueMargin
					baselineVsActualRevenue
					baselineVsActualRevenueRecognition
					baselineVsTotalRevenueAtCompletion
					baselineVsBillableTotalTimeAndExpensesAtCompletion
					baselineVsTotalCostAtCompletion
					baselineVsTotalProfitAtCompletion
					baselineVsTotalRevenueProfitAtCompletion
					baselineVsTotalRevenueMarginAtCompletion
					billableActualTimeAndExpenses
					baselineTimeAndExpenses

					totalActualRevenueRecognition
					actualRevenue
					actualCost
					actualProfit
					actualMargin
					actualRevenueProfit
					actualRevenueMargin
					billablePlannedTimeAndExpenses
					plannedRevenue
					plannedCost
					plannedProfit
					plannedMargin
					plannedRevenueProfit
					plannedRevenueMargin
					billableForecastTimeAndExpensesToComplete
					totalForecastRevenueToComplete
					forecastCostToComplete
					forecastProfitToComplete
					forecastMarginToComplete
					forecastRevenueProfitToComplete
					forecastRevenueMarginToComplete
					billableTotalTimeAndExpensesAtCompletion
					totalRevenueRecognition
					totalCostAtCompletion
					totalProfitAtCompletion
					totalMarginAtCompletion
					totalRevenueProfitAtCompletion
					totalRevenueMarginAtCompletion
					planVsBillableActualTimeAndExpenses
					planVsActualRevenueRecognition
					planVsActualCost
					planVsActualProfit
					planVsActualMargin
					planVsActualRevenueProfit
					planVsActualRevenueMargin
					planVsTotalBillableTimeAndExpensesAtCompletion
					planVsTotalRevenueAtCompletion
					planVsTotalCostAtCompletion
					planVsTotalProfitAtCompletion
					planVsTotalMarginAtCompletion
					planVsTotalRevenueProfitAtCompletion
					planVsTotalRevenueMarginAtCompletion
					invoiced
					paid
					accruedDeferred
					baselineMinutes
					registeredMinutes
					billableActualMinutes
					nonBillableActualMinutes
					scopeApprovedMinutes
					forecastTimeToComplete
					totalTimeAtCompletion
					baselineVsRegisteredMinutes
					baselineVsTotalTimeAtCompletion
					registeredVsScopeApprovedMinutes
					scopeApprovedVsRegisteredMinutes
					totalTimeAtCompletionVsScopeApprovedMinutes
					scopeApprovedVsTotalTimeAtCompletion
				}
			}
		}
	`,
});
