import React from 'react';
import {Table} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';

export const getBaseLevelTableHeaderTotals = tableHeader => {
	const numberOfCustomFields =
		Util.hasCustomFields() && tableHeader.customFieldDefinitions ? tableHeader.customFieldDefinitions.length : 0;
	//change it to columns.length
	const totalNumbers = new Array(14 + numberOfCustomFields);
	return (
		<Table.Rows data={{rows: [totalNumbers]}} canExpand={false} hasHeader>
			{({rowData, tableColumnsProps}) => {
				return (
					<Table.Row {...tableColumnsProps}>
						<Table.Column></Table.Column>
						<Table.Column></Table.Column>
						<Table.Column></Table.Column>
						<Table.Column>{tableHeader.wholeTableReportedTime}</Table.Column>
						<Table.Column></Table.Column>
						<Table.Column></Table.Column>
						<Table.Column></Table.Column>
						<Table.Column></Table.Column>
						<Table.Column></Table.Column>
						<Table.Column></Table.Column>
						<Table.Column>{tableHeader.wholeTableTotalPrice}</Table.Column>
						<Table.Column>{tableHeader.wholeTableTotalCost}</Table.Column>
						<Table.Column></Table.Column>
						<Table.Column></Table.Column>
						{[...Array(numberOfCustomFields)].map(_ => (
							<Table.Column />
						))}
					</Table.Row>
				);
			}}
		</Table.Rows>
	);
};
