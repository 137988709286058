/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type peopleListProjectsComponent_viewer$ref: FragmentReference;
declare export opaque type peopleListProjectsComponent_viewer$fragmentType: peopleListProjectsComponent_viewer$ref;
export type peopleListProjectsComponent_viewer = {|
  +insightComponentsData: ?{|
    +peopleListProjects: ?{|
      +currency: ?string,
      +useHours: ?boolean,
      +todoTasksCountTotal: ?number,
      +progressTasksCountTotal: ?number,
      +doneTasksCountTotal: ?number,
      +tasksCountTotal: ?number,
      +remainingTotal: ?number,
      +reportedTotal: ?number,
      +spendTotal: ?number,
      +people: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
        +defaultRole: ?string,
        +projectRole: ?string,
        +email: ?string,
        +labels: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
          +color: ?string,
        |}>,
        +skills: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
        |}>,
        +profiles: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
            |}
          |}>
        |},
        +tasksCount: ?number,
        +todoTasksCount: ?number,
        +progressTasksCount: ?number,
        +doneTasksCount: ?number,
        +reportedMinutes: ?number,
        +remaining: ?number,
        +spend: ?number,
      |}>,
    |}
  |},
  +$refType: peopleListProjectsComponent_viewer$ref,
|};
export type peopleListProjectsComponent_viewer$data = peopleListProjectsComponent_viewer;
export type peopleListProjectsComponent_viewer$key = {
  +$data?: peopleListProjectsComponent_viewer$data,
  +$fragmentRefs: peopleListProjectsComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "componentId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProjectGroupType",
      "type": "Boolean"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "roleIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "labelIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skillIds",
      "type": "[ID]"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "peopleListProjectsComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "componentId",
              "variableName": "componentId"
            },
            {
              "kind": "Variable",
              "name": "isProjectGroupType",
              "variableName": "isProjectGroupType"
            },
            {
              "kind": "Variable",
              "name": "labelIds",
              "variableName": "labelIds"
            },
            {
              "kind": "Variable",
              "name": "personIds",
              "variableName": "personIds"
            },
            {
              "kind": "Variable",
              "name": "projectId",
              "variableName": "projectId"
            },
            {
              "kind": "Variable",
              "name": "roleIds",
              "variableName": "roleIds"
            },
            {
              "kind": "Variable",
              "name": "skillIds",
              "variableName": "skillIds"
            },
            {
              "kind": "Variable",
              "name": "teamIds",
              "variableName": "teamIds"
            }
          ],
          "concreteType": "PeopleList_Project",
          "kind": "LinkedField",
          "name": "peopleListProjects",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "useHours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "todoTasksCountTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "progressTasksCountTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "doneTasksCountTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tasksCountTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remainingTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reportedTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "spendTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PeopleList_ProjectPersonType",
              "kind": "LinkedField",
              "name": "people",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureDefaultId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "defaultRole",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectRole",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "labels",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Skill",
                  "kind": "LinkedField",
                  "name": "skills",
                  "plural": true,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 10000
                    }
                  ],
                  "concreteType": "ProfileTypeConnection",
                  "kind": "LinkedField",
                  "name": "profiles",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProfileTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Profile",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "profiles(first:10000)"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tasksCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "todoTasksCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "progressTasksCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "doneTasksCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reportedMinutes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remaining",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "spend",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '5284525a4717f592a8b24aaad2ab7e0c';

module.exports = node;
