import React from 'react';
import {
	GROUP_SECTION_WIDTH,
	HEATMAP_CELL_DETAIL_BOX_HEIGHT,
	HEATMAP_CELL_DETAIL_BOX_WIDTH,
	HEATMAP_CELL_PADDING_X,
	HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE,
	UTILIZATION_FORMAT,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import Util from '../../../forecast-app/shared/util/util';
import completionFullIcon from '../../../images/components/scheduling/task-done.svg';
import {useIntl} from 'react-intl';
import {EYE_OPTION_NAME} from '../constants';
import {getMinutesAllocated, getMinutesAllocatedVariation} from '../heatmap/MinutesAllocatedVariationsUtils';

export const getHeatmapCellDetailBoxCoords = heatmapData => {
	if (!heatmapData) return {};

	const cellCenterX = heatmapData ? heatmapData.data.x + heatmapData.width / 2 : null;

	const showHeatmapDetailBoxCenter = heatmapData
		? cellCenterX - HEATMAP_CELL_DETAIL_BOX_WIDTH / 2 > GROUP_SECTION_WIDTH &&
		  cellCenterX + HEATMAP_CELL_DETAIL_BOX_WIDTH / 2 < window.innerWidth - 24
		: null;

	// subtracting 24 for scroll width / height
	const showHeatmapDetailBoxRight =
		!heatmapData || showHeatmapDetailBoxCenter
			? null
			: heatmapData.data.x + heatmapData.width + 10 + HEATMAP_CELL_DETAIL_BOX_WIDTH < window.innerWidth - 24;
	const showHeatmapDetailBoxBottom =
		heatmapData && showHeatmapDetailBoxRight === null
			? heatmapData.data.y + heatmapData.data.height + 10 + HEATMAP_CELL_DETAIL_BOX_HEIGHT < window.innerHeight - 24
			: null;

	const heatmapCellVisibleWidth = heatmapData ? heatmapData.data.x + heatmapData.width - GROUP_SECTION_WIDTH : null;

	const heatmapCellDetailBoxX = heatmapData
		? showHeatmapDetailBoxCenter
			? cellCenterX - HEATMAP_CELL_DETAIL_BOX_WIDTH / 2
			: showHeatmapDetailBoxRight
			? heatmapData.data.x + heatmapCellVisibleWidth + 10
			: heatmapData.data.x - HEATMAP_CELL_DETAIL_BOX_WIDTH - 10
		: null;

	const heatmapCellDetailBoxY = heatmapData
		? showHeatmapDetailBoxRight === null
			? showHeatmapDetailBoxBottom
				? heatmapData.data.y + heatmapData.data.height + 10
				: heatmapData.data.y - HEATMAP_CELL_DETAIL_BOX_HEIGHT - 10
			: heatmapData.data.y + heatmapData.data.height / 2 - HEATMAP_CELL_DETAIL_BOX_HEIGHT / 2
		: null;

	return {
		heatmapCellDetailBoxX,
		heatmapCellDetailBoxY,
		showHeatmapDetailBoxRight,
		showHeatmapDetailBoxCenter,
		showHeatmapDetailBoxBottom,
	};
};

export default ({heatmapData, showRight, showCenter, showBottom, isPlaceholdersScheduling}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	const {minutesAvailable, eyeOptions, schedulingOptions, isPast, isInActualMode, width} = heatmapData;
	const {utilizationFormat, calcWin, hideHard, hideSoft} = schedulingOptions;

	const isTaskMode = [VISUALIZATION_MODE.TASK_ACTUAL, VISUALIZATION_MODE.TASK_PLAN].includes(
		schedulingOptions.visualizationMode
	);
	const minutesAllocated = isTaskMode
		? heatmapData.minutesAllocated
		: heatmapData.minutesAllocatedVariations
		? getMinutesAllocatedVariation(heatmapData.minutesAllocatedVariations, hideSoft, hideHard, calcWin)
		: getMinutesAllocated(heatmapData, hideSoft, hideHard, calcWin);

	const showInPercentage = utilizationFormat === UTILIZATION_FORMAT.PERCENTAGE;
	let showFullyAllocatedIcon = false;

	const isOverallocated = minutesAllocated > minutesAvailable;
	const isWithinCheckmarkRange = isOverallocated
		? minutesAllocated / minutesAvailable - 1 < HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE / 100
		: minutesAllocated / minutesAvailable - 1 > -HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE / 100;

	// Don't show detail box without working hours and any assignments
	if (!minutesAllocated && !minutesAvailable) return null;

	if (
		!isPlaceholdersScheduling &&
		eyeOptions &&
		eyeOptions.find(option => option.name === EYE_OPTION_NAME.SHOW_HEATMAP) &&
		!eyeOptions.find(option => option.name === EYE_OPTION_NAME.SHOW_HEATMAP).checked
	)
		return;

	const isAllTextVisibleInCell = text => {
		const maxTextWidth = width - HEATMAP_CELL_PADDING_X;

		// get canvas context
		const canvas = document.getElementById('foreground-canvas');
		let canvasContext = canvas.getContext('2d');
		const previousCanvasFont = canvasContext.font;

		// set heatmap cell font and get width
		canvasContext.font = '700 13px ' + Util.getFontFamily();
		const textWidth = canvasContext.measureText(text).width;

		// set font back to original canvas font
		canvasContext.font = previousCanvasFont;

		return textWidth < maxTextWidth;
	};

	let text = '';

	if (isWithinCheckmarkRange) {
		text = formatMessage({id: 'scheduling.heatmap_completion_full'});
		showFullyAllocatedIcon = true;
	} else if (isOverallocated) {
		let heatmapNumber = showInPercentage
			? Math.round((minutesAllocated / minutesAvailable) * 100)
			: isPast && isInActualMode
			? minutesAllocated
			: minutesAllocated - minutesAvailable;

		if (showInPercentage) {
			heatmapNumber = heatmapNumber > 200 ? '200+%' : heatmapNumber + '%';
		}

		const allocationTime = showInPercentage ? heatmapNumber : Util.convertMinutesToFullHour(heatmapNumber, intl);

		const timeOverText = formatMessage({id: 'scheduling.heatmap_time_over'});
		text = `${allocationTime}`;

		if (!showInPercentage) {
			text += ` ${timeOverText}`;
		}
	} else {
		const timeLeftText = formatMessage({id: 'scheduling.heatmap_time_left'});

		let heatmapNumber = showInPercentage
			? Math.round((minutesAllocated / minutesAvailable) * 100)
			: minutesAvailable - minutesAllocated;

		if (showInPercentage) {
			heatmapNumber = heatmapNumber > 200 ? '200+%' : heatmapNumber + '%';
		}

		const allocationNumbers = showInPercentage ? heatmapNumber : Util.convertMinutesToFullHour(heatmapNumber, intl);

		text = `${allocationNumbers}`;

		if (!showInPercentage) {
			text += ` ${timeLeftText}`;
		}
	}

	const innerContainerStyle = {
		left: showRight === true ? 0 : null,
		right: showRight === false ? 0 : null,
	};

	return isAllTextVisibleInCell(text) ? null : (
		<>
			<div
				className={
					'light-triangle' +
					(showCenter ? ' center' : showRight ? ' right' : ' left') +
					(showBottom === null ? '' : showBottom ? ' top' : ' bottom')
				}
			/>
			<div className="detail-box-inner-container" style={innerContainerStyle}>
				{showFullyAllocatedIcon ? (
					<img src={completionFullIcon} style={{marginRight: 6, width: 13}} alt="Fully allocated" />
				) : null}
				<span>{text}</span>
			</div>
		</>
	);
};
