import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../../../css_variables';

export const RevenueRecognitionTableHeadline = Styled.div`
    font-size: 13px;
    font-weight: 600;
	color: ${CSS_CONSTANTS.brand500};
`;

export const RevenueRecognitionTableContainer = Styled.div`
    margin-top: 25px;
`;

export const ManualRecogntionResetColumn = Styled.div`
    padding-top: 20px;
`;

export const HalfLockIconContainer = Styled.div`
    margin-right: 10px;
`;

export const LockIconContainer = Styled.div`
    margin-right: 10px;
`;

export const HeaderText = Styled.div`
    line-height: 16px;
`;

export const LockHeaderContainer = Styled.div`
    display: flex;
    cursor: pointer;
    ${HalfLockIconContainer} {
        display: none;
    }
    :hover ${HalfLockIconContainer} {
        display: unset;
    }
    ${LockIconContainer} {
        display: unset;
    }
    :hover ${LockIconContainer} {
        display: none;
    }
`;

export const HeaderContainer = Styled.div`
    display: flex;
`;
