import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Person from '../../../forecast-app/shared/components/person/person';
import Util from '../../../forecast-app/shared/util/util';

class TimeRegisteredPerPersonPortfolioDummy extends Component {
	getBackgroundColor(registered, available) {
		if (registered >= available) {
			return '#99FF8C';
		} else {
			return '#FF7C75';
		}
	}
	createDummy(name, role, sun, mon, tue, wed, thu, fri, sat) {
		const el = {};
		el.name = <Person name={name} role={role} showName={true} showRole={true} imageSrc={null} imageSize="medium" />;
		el.sun = sun + '/' + Util.convertMinutesToFullHour(0, this.props.intl);
		el.mon = mon + '/' + Util.convertMinutesToFullHour(8, this.props.intl);
		el.tue = tue + '/' + Util.convertMinutesToFullHour(8, this.props.intl);
		el.wed = wed + '/' + Util.convertMinutesToFullHour(8, this.props.intl);
		el.thu = thu + '/' + Util.convertMinutesToFullHour(8, this.props.intl);
		el.fri = fri + '/' + Util.convertMinutesToFullHour(8, this.props.intl);
		el.sat = sat + '/' + Util.convertMinutesToFullHour(0, this.props.intl);
		const total = sun + mon + tue + wed + thu + fri + sat;
		el.total = total + '/' + Util.convertMinutesToFullHour(40, this.props.intl);
		el.sun_backgroundColor = this.getBackgroundColor(sun, 0);
		el.mon_backgroundColor = this.getBackgroundColor(mon, 8);
		el.tue_backgroundColor = this.getBackgroundColor(tue, 8);
		el.wed_backgroundColor = this.getBackgroundColor(wed, 8);
		el.thu_backgroundColor = this.getBackgroundColor(thu, 8);
		el.fri_backgroundColor = this.getBackgroundColor(fri, 8);
		el.sat_backgroundColor = this.getBackgroundColor(sat, 0);
		el.total_backgroundColor = this.getBackgroundColor(total, 40);
		return el;
	}
	render() {
		let data = [];
		data.push(this.createDummy('Example Person 1', 'Developer', 0, 8, 8, 8, 8, 8, 0));
		data.push(this.createDummy('Example Person 2', 'Developer', 0, 4, 8, 8, 8, 8, 0));
		data.push(this.createDummy('Example Person 3', 'Product Owner', 0, 8, 8, 8, 8, 8, 0));
		data.push(this.createDummy('Example Person 4', 'Project Manager', 0, 8, 0, 0, 8, 8, 0));
		return (
			<InsightList
				data={data}
				componentName={this.props.componentName}
				allColumns={this.props.allColumns}
				columnSettings={[{width: '20%'}]}
				activeColumns={['name', 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'total']}
				scrollElement={this.props.scrollElement}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}
export default injectIntl(TimeRegisteredPerPersonPortfolioDummy);
