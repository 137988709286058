import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Person from '../../../forecast-app/shared/components/person/person';
import InsightsUtil from '../insights_util';
import Select from '../../../components/inputs/select_input';
import ReactDOM from 'react-dom';
import {profilePicSrc} from '../../../directApi';

class UtilizationTypeDropdown extends Component {
	render() {
		const {componentId, formatMessage} = this.props;
		const portalDestination = document.getElementById('insight-component-header-portal-target-' + componentId);
		if (!portalDestination) return null;

		return ReactDOM.createPortal(
			<Select
				options={[
					{label: formatMessage({id: 'common.forecast_resource_utilization'}), value: false},
					{label: formatMessage({id: 'common.forecast_billable_utilization'}), value: true},
				]}
				onChange={this.props.onUtilizationTypeChange}
				multi={false}
				label=""
				hideLabel={true}
				value={this.props.billable}
				clearable={false}
				disableRenderCalculations={true}
			/>,
			portalDestination
		);
	}
}

class utilizationMonthlyComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sortBy: {column: null, ascending: true},
			billable: false,
		};
	}

	UNSAFE_componentWillMount() {
		const listHeight = InsightsUtil.GetlistHeight(this.props.viewer.insightComponentsData.utilizationMonthly.people.length);
		if (this.props.height !== listHeight) {
			this.props.updateComponentHeight(this.props.id, listHeight);
		}
		if (this.props.setExportColumns) {
			let exportColumns = [];
			this.props.columns.forEach(col => {
				exportColumns.push(col + '_raw');
			});
			this.props.setExportColumns(exportColumns);
		}
		this.setData(this.props, this.state);
	}

	componentDidMount() {
		this.forceUpdate(); //Make the utilization type switch apppear
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.year !== nextProps.year) {
			this.props.relay.refetch({year: nextProps.year});
		}
		if (this.props.selectValue !== nextProps.selectValue) {
			this.props.relay.refetch({selectValue: nextProps.selectValue});
		}
		if (this.props.personFilterValue.length !== nextProps.personFilterValue.length) {
			const personIds = nextProps.personFilterValue.length !== 0 ? nextProps.personFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({personIds});
		}
		if (this.props.roleFilterValue.length !== nextProps.roleFilterValue.length) {
			const roleIds = nextProps.roleFilterValue.length !== 0 ? nextProps.roleFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({roleIds});
		}
		if (this.props.teamFilterValue.length !== nextProps.teamFilterValue.length) {
			const teamIds = nextProps.teamFilterValue.length !== 0 ? nextProps.teamFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({teamIds});
		}
		// Calculate height based on amount of cards in nextProps
		const listHeight = InsightsUtil.GetlistHeight(nextProps.viewer.insightComponentsData.utilizationMonthly.people.length);
		if (this.props.height !== listHeight) {
			this.props.updateComponentHeight(this.props.id, listHeight);
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		this.setData(nextProps, nextState);
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	setData(props, state) {
		const {formatNumber} = props.intl;
		const component = props.viewer.insightComponentsData.utilizationMonthly;
		const {billable} = state;
		this.totals = {
			name_raw: 'Average',
			jan: formatNumber(billable ? component.janBillable : component.jan, {style: 'percent', maximumFractionDigits: 0}),
			feb: formatNumber(billable ? component.febBillable : component.feb, {style: 'percent', maximumFractionDigits: 0}),
			mar: formatNumber(billable ? component.marBillable : component.mar, {style: 'percent', maximumFractionDigits: 0}),
			apr: formatNumber(billable ? component.aprBillable : component.apr, {style: 'percent', maximumFractionDigits: 0}),
			may: formatNumber(billable ? component.mayBillable : component.may, {style: 'percent', maximumFractionDigits: 0}),
			jun: formatNumber(billable ? component.junBillable : component.jun, {style: 'percent', maximumFractionDigits: 0}),
			jul: formatNumber(billable ? component.julBillable : component.jul, {style: 'percent', maximumFractionDigits: 0}),
			aug: formatNumber(billable ? component.augBillable : component.aug, {style: 'percent', maximumFractionDigits: 0}),
			sep: formatNumber(billable ? component.sepBillable : component.sep, {style: 'percent', maximumFractionDigits: 0}),
			oct: formatNumber(billable ? component.octBillable : component.oct, {style: 'percent', maximumFractionDigits: 0}),
			nov: formatNumber(billable ? component.novBillable : component.nov, {style: 'percent', maximumFractionDigits: 0}),
			dec: formatNumber(billable ? component.decBillable : component.dec, {style: 'percent', maximumFractionDigits: 0}),
			jan_raw: billable ? component.janBillable : component.jan,
			feb_raw: billable ? component.febBillable : component.feb,
			mar_raw: billable ? component.marBillable : component.mar,
			apr_raw: billable ? component.aprBillable : component.apr,
			may_raw: billable ? component.mayBillable : component.may,
			jun_raw: billable ? component.junBillable : component.jun,
			jul_raw: billable ? component.julBillable : component.jul,
			aug_raw: billable ? component.augBillable : component.aug,
			sep_raw: billable ? component.sepBillable : component.sep,
			oct_raw: billable ? component.octBillable : component.oct,
			nov_raw: billable ? component.novBillable : component.nov,
			dec_raw: billable ? component.decBillable : component.dec,
		};

		const formatMonthValue = value => {
			//Backend will return -1 if person has 0 available minutes which would result in the number being infinity
			return value === -1 ? 'N/A' : formatNumber(value, {style: 'percent', maximumFractionDigits: 0});
		};
		this.data = component.people.map(p => {
			const person = {};
			person.name_raw = p.firstName + (p.lastName ? ' ' + p.lastName : '');
			person.jan_raw = billable ? p.janBillable : p.jan;
			person.feb_raw = billable ? p.febBillable : p.feb;
			person.mar_raw = billable ? p.marBillable : p.mar;
			person.apr_raw = billable ? p.aprBillable : p.apr;
			person.may_raw = billable ? p.mayBillable : p.may;
			person.jun_raw = billable ? p.junBillable : p.jun;
			person.jul_raw = billable ? p.julBillable : p.jul;
			person.aug_raw = billable ? p.augBillable : p.aug;
			person.sep_raw = billable ? p.sepBillable : p.sep;
			person.oct_raw = billable ? p.octBillable : p.oct;
			person.nov_raw = billable ? p.novBillable : p.nov;
			person.dec_raw = billable ? p.decBillable : p.dec;
			person.jan = formatMonthValue(billable ? p.janBillable : p.jan);
			person.feb = formatMonthValue(billable ? p.febBillable : p.feb);
			person.mar = formatMonthValue(billable ? p.marBillable : p.mar);
			person.apr = formatMonthValue(billable ? p.aprBillable : p.apr);
			person.may = formatMonthValue(billable ? p.mayBillable : p.may);
			person.jun = formatMonthValue(billable ? p.junBillable : p.jun);
			person.jul = formatMonthValue(billable ? p.julBillable : p.jul);
			person.aug = formatMonthValue(billable ? p.augBillable : p.aug);
			person.sep = formatMonthValue(billable ? p.sepBillable : p.sep);
			person.oct = formatMonthValue(billable ? p.octBillable : p.oct);
			person.nov = formatMonthValue(billable ? p.novBillable : p.nov);
			person.dec = formatMonthValue(billable ? p.decBillable : p.dec);
			person.jan_backgroundColor = this.getBackgroundColor(billable ? p.janBillable : p.jan);
			person.feb_backgroundColor = this.getBackgroundColor(billable ? p.febBillable : p.feb);
			person.mar_backgroundColor = this.getBackgroundColor(billable ? p.marBillable : p.mar);
			person.apr_backgroundColor = this.getBackgroundColor(billable ? p.aprBillable : p.apr);
			person.may_backgroundColor = this.getBackgroundColor(billable ? p.mayBillable : p.may);
			person.jun_backgroundColor = this.getBackgroundColor(billable ? p.junBillable : p.jun);
			person.jul_backgroundColor = this.getBackgroundColor(billable ? p.julBillable : p.jul);
			person.aug_backgroundColor = this.getBackgroundColor(billable ? p.augBillable : p.aug);
			person.sep_backgroundColor = this.getBackgroundColor(billable ? p.sepBillable : p.sep);
			person.oct_backgroundColor = this.getBackgroundColor(billable ? p.octBillable : p.oct);
			person.nov_backgroundColor = this.getBackgroundColor(billable ? p.novBillable : p.nov);
			person.dec_backgroundColor = this.getBackgroundColor(billable ? p.decBillable : p.dec);
			person.name = (
				<Person
					key={p.id}
					name={person.name_raw}
					role={p.role}
					showName={true}
					showRole={true}
					imageSrc={profilePicSrc(p.profilePictureId)}
					imageSize="medium"
				/>
			);

			return person;
		});

		if (state.sortBy.column) {
			this.data = this.data.sort((a, b) => {
				let column = state.sortBy.column + '_raw';
				let returnValue = 0;
				if (a[column] < b[column]) returnValue = 1;
				if (a[column] > b[column]) returnValue = -1;
				if (a[column] === '') returnValue = -1;

				return state.sortBy.ascending ? returnValue * -1 : returnValue;
			});
		}
		const exportData = this.data.slice();
		exportData.splice(0, 0, this.totals);
		if (props.setExportData) {
			props.setExportData(exportData);
		}
	}

	getBackgroundColor(value) {
		try {
			const config = JSON.parse(this.props.config);
			const floatValue = parseFloat(value * 100);
			if (floatValue < parseInt(config.tooLow, 10)) {
				return config.tooLowColor;
			} else if (floatValue <= parseInt(config.low, 10)) {
				return config.lowColor;
			} else if (floatValue <= parseInt(config.optimal, 10)) {
				return config.optimalColor;
			} else if (floatValue <= parseInt(config.high, 10)) {
				return config.highColor;
			} else {
				return config.tooHighColor;
			}
		} catch (err) {}
	}

	onUtilizationTypeChange() {
		this.setState({billable: !this.state.billable});
	}

	render() {
		const columns = this.props.projectGroupId
			? this.props.columns
			: this.props.columns.filter(col => col !== 'projectName' && col !== 'companyProjectId');
		return (
			<>
				{this.props.canViewFinancial ? (
					<UtilizationTypeDropdown
						componentId={this.props.id}
						onUtilizationTypeChange={this.onUtilizationTypeChange.bind(this)}
						billable={this.state.billable}
						formatMessage={this.props.intl.formatMessage}
					/>
				) : null}
				<InsightList
					componentId={this.props.id}
					data={this.data}
					componentName={'utilizationMonthly'}
					activeColumns={columns}
					allColumns={columns}
					sortBy={this.state.sortBy}
					setSortBy={this.setSortBy.bind(this)}
					columnSettings={[{width: '20%'}]}
					totals={this.totals}
					scrollElement={this.props.scrollElement}
					scrollElementFullScreen={this.props.scrollElementFullScreen}
					notifyOnReady={this.props.notifyOnReady}
				/>
			</>
		);
	}
}

utilizationMonthlyComponent.propTypes = {
	title: PropTypes.string,
};

const utilizationMonthlyComponentQuery = graphql`
	query utilizationMonthlyComponent_Query(
		$shareKey: String
		$year: Int
		$selectValue: String
		$componentId: ID
		$personIds: [ID]
		$teamIds: [ID]
		$roleIds: [ID]
		$labelIds: [ID]
		$projectStatusesBackend: [ID]
	) {
		viewer {
			actualPersonId
			component(name: "insight_utilization_monthly")
			...utilizationMonthlyComponent_viewer
				@arguments(
					shareKey: $shareKey
					year: $year
					selectValue: $selectValue
					componentId: $componentId
					personIds: $personIds
					teamIds: $teamIds
					roleIds: $roleIds
					labelIds: $labelIds
					projectStatusesBackend: $projectStatusesBackend
				)
		}
	}
`;

export {utilizationMonthlyComponentQuery};

export default injectIntl(
	createRefetchContainer(
		utilizationMonthlyComponent,
		{
			viewer: graphql`
				fragment utilizationMonthlyComponent_viewer on Viewer
				@argumentDefinitions(
					shareKey: {type: "String"}
					year: {type: "Int"}
					selectValue: {type: "String"}
					componentId: {type: "ID"}
					personIds: {type: "[ID]"}
					teamIds: {type: "[ID]"}
					roleIds: {type: "[ID]"}
					labelIds: {type: "[ID]"}
					projectStatusesBackend: {type: "[ID]"}
				) {
					insightComponentsData(shareKey: $shareKey) {
						utilizationMonthly(
							year: $year
							selectValue: $selectValue
							personIds: $personIds
							roleIds: $roleIds
							teamIds: $teamIds
							componentId: $componentId
							labelIds: $labelIds
							projectStatusesBackend: $projectStatusesBackend
						) {
							jan
							feb
							mar
							apr
							may
							jun
							jul
							aug
							sep
							oct
							nov
							dec
							janBillable
							febBillable
							marBillable
							aprBillable
							mayBillable
							junBillable
							julBillable
							augBillable
							sepBillable
							octBillable
							novBillable
							decBillable
							people {
								id
								profilePictureId
								profilePictureDefaultId
								firstName
								lastName
								role
								jan
								feb
								mar
								apr
								may
								jun
								jul
								aug
								sep
								oct
								nov
								dec
								janBillable
								febBillable
								marBillable
								aprBillable
								mayBillable
								junBillable
								julBillable
								augBillable
								sepBillable
								octBillable
								novBillable
								decBillable
							}
						}
					}
				}
			`,
		},
		graphql`
			query utilizationMonthlyComponentRefetchQuery(
				$shareKey: String
				$year: Int
				$selectValue: String
				$componentId: ID
				$personIds: [ID]
				$teamIds: [ID]
				$roleIds: [ID]
				$labelIds: [ID]
				$projectStatusesBackend: [ID]
			) {
				viewer {
					...utilizationMonthlyComponent_viewer
						@arguments(
							shareKey: $shareKey
							year: $year
							selectValue: $selectValue
							componentId: $componentId
							personIds: $personIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
							projectStatusesBackend: $projectStatusesBackend
						)
				}
			}
		`
	)
);
