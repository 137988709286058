import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {
	TableSectionWrapper,
	TableSectionTitle,
	CollapsibleSectionWrapper,
	TableSectionTitleHeadline,
} from '../money/work_and_expenses/TableSection.styled';
import {LockIcon, Table, Collapsible, CustomScrollDiv} from 'web-components';
import {UnlockIcon} from 'web-components/icons';
import {useIntl} from 'react-intl';
import getAggregateRowTitle from '../util/UseAggregateIntl';
import RevenueRecognitionTableRow from './RevenueRecognitionTableRow';
import {LoadMore} from '../../../../loaders/LoadMore';
import RevenueRecognitionLoader, {revenueRecognitionLoaderQuery} from '../loaders/RevenueRecognitionLoader';
import {filterAggregateFields, TABLE_TYPE, isColumnLocked} from '../util/BudgetUtils';
import CloseMonthButtons from '../money/work_and_expenses/CloseMonthButtons';
import {SPECIAL_ROLE} from '../../../../../constants';
import {
	HalfLockIconContainer,
	LockIconContainer,
	LockHeaderContainer,
	HeaderContainer,
	HeaderText,
} from './RevenueRecognition.styled';
import {showFixedPriceLockModal, showFixedPriceOpenLockModal} from '../util/PeriodLocks';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';

const RevenueRecognition = ({project, retry, timestamp, hideLocking, hasExpenses}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const hasManualRecognition = hasFeatureFlag('manual_revenue_recognition');

	const aggregates = filterAggregateFields(project, [
		'recognitionAmount',
		'estimatedCost',
		'recognitionProfit',
		'recognitionProfitPercentage',
	]);

	const searchQuery = {
		filters: [
			{
				field: 'ROLE',
				operator: 'NOT_IN',
				value: SPECIAL_ROLE.EXPENSE,
			},
		],
	};
	const expenseSearchQuery = {
		filters: [
			{
				field: 'ROLE',
				operator: 'IN',
				value: SPECIAL_ROLE.EXPENSE,
			},
		],
	};

	return (
		<>
			<CollapsibleSectionWrapper>
				<Collapsible
					title={formatMessage({id: 'project_budget.revenue_recognition'})}
					initiallyCollapsed={false}
					localStorageKey={'project_budget.collapse_revenue_recognition_table'}
					cy={'revenue-recognition-collapse'}
				>
					<TableSectionWrapper>
						<LoadMore
							key="query-render-revenue-recognition"
							query={revenueRecognitionLoaderQuery}
							loader={
								<RevenueRecognitionLoader
									aggregateLevel={'MONTH'}
									aggregates={aggregates}
									expenseAggregates={[
										'billableTotalTimeAndExpensesAtCompletion',
										'allTotalTimeAndExpensesAtCompletion',
									]}
									retry={retry}
									timestamp={timestamp}
									hasExpenses={hasExpenses}
								/>
							}
							variables={{
								projectId: project.id,
								startYear: project.projectFirstDateYear,
								startMonth: project.projectFirstDateMonth,
								startDay: project.projectFirstDateDay,
								endYear: project.projectLastDateYear,
								endMonth: project.projectLastDateMonth,
								endDay: project.projectLastDateDay,
								aggregateLevel: 'MONTH',
								aggregates: aggregates,
								searchQuery: searchQuery,
								expenseAggregates: [
									'billableTotalTimeAndExpensesAtCompletion',
									'allTotalTimeAndExpensesAtCompletion',
								],
								expenseSearchQuery: expenseSearchQuery,
								hasExpenses: hasExpenses,
							}}
						>
							{({data}) => {
								const recognitionTotalAmounts = {
									time: data.rows
										.find(row => row.aggregate === (hasExpenses ? 'recognitionAmount' : 'recognitionTotal'))
										.dataArray.map((month, index) => {
											return {startDate: data.columns[index].startDate, amount: month.value};
										}),
									total: data.rows
										.find(row => row.aggregate === 'recognitionTotal')
										.dataArray.map((month, index) => {
											return {startDate: data.columns[index].startDate, amount: month.value};
										}),
								};
								return (
									<>
										<TableSectionTitle>
											<TableSectionTitleHeadline />
											{!hideLocking && (
												<CloseMonthButtons
													project={project}
													isFixedPriceV2={true}
													lockedAmounts={recognitionTotalAmounts}
												/>
											)}
										</TableSectionTitle>
										<CustomScrollDiv
											horizontalContent
											autoHeight={true}
											autoHeightMin={data.rows.length * 48 + 53 + 15}
										>
											<Table paddedCells cy="budget-details">
												<Table.Header>
													<Table.HeaderColumn visible flex={1} width="EXTRA_LARGE" />
													{data.columns.map((column, index) => (
														<Table.HeaderColumn
															key={
																formatMessage({id: 'common.revenue_recognition'}) + column.name
															}
															usePadding
															align="right"
															visible={true}
															width="MEDIUM_SMALL"
														>
															<Table.HeaderColumn.Title bold={index === data.columns.length - 1}>
																{hasManualRecognition ? (
																	hideLocking ? (
																		<HeaderContainer>
																			{isColumnLocked(project.fixedPriceLocks, column) ? (
																				<LockIcon
																					color={'#C4C4CC'}
																					size={LockIcon.SIZE.STANDARD}
																				/>
																			) : null}
																			<HeaderText>{column.name}</HeaderText>
																		</HeaderContainer>
																	) : (
																		<LockHeaderContainer
																			onClick={() =>
																				isColumnLocked(project.fixedPriceLocks, column)
																					? showFixedPriceOpenLockModal(
																							project,
																							column.startDate.format('YYYY-MM')
																					  )
																					: showFixedPriceLockModal(
																							project,
																							column.startDate.format('YYYY-MM'),
																							recognitionTotalAmounts
																					  )
																			}
																		>
																			{isColumnLocked(project.fixedPriceLocks, column) ? (
																				<>
																					<HalfLockIconContainer>
																						<UnlockIcon
																							size="small"
																							color={'#C4C4CC'}
																							hoverColor={null}
																						/>
																					</HalfLockIconContainer>
																					<LockIconContainer>
																						<LockIcon
																							color={'#C4C4CC'}
																							size={LockIcon.SIZE.STANDARD}
																						/>
																					</LockIconContainer>
																				</>
																			) : (
																				<HalfLockIconContainer>
																					<UnlockIcon
																						size="small"
																						color={'#C4C4CC'}
																						hoverColor={null}
																					/>
																				</HalfLockIconContainer>
																			)}
																			<HeaderText>{column.name}</HeaderText>
																		</LockHeaderContainer>
																	)
																) : (
																	<>
																		{isColumnLocked(project.fixedPriceLocks, column) && (
																			<div style={{marginRight: 10}}>
																				<LockIcon
																					color={'#C4C4CC'}
																					size={LockIcon.SIZE.STANDARD}
																				/>
																			</div>
																		)}
																		<HeaderText>{column.name}</HeaderText>
																	</>
																)}
															</Table.HeaderColumn.Title>
														</Table.HeaderColumn>
													))}
												</Table.Header>
												<Table.Rows data={data} canExpand={false}>
													{({rowData, tableColumnsProps}) => {
														return (
															<RevenueRecognitionTableRow
																data={data}
																rowData={rowData}
																tableColumnProps={tableColumnsProps}
																project={project}
																headerColumn={
																	<Table.Column>
																		{getAggregateRowTitle(
																			intl,
																			rowData.aggregate,
																			TABLE_TYPE.REVENUE_RECOGNITION
																		)}
																	</Table.Column>
																}
															/>
														);
													}}
												</Table.Rows>
											</Table>
										</CustomScrollDiv>
									</>
								);
							}}
						</LoadMore>
					</TableSectionWrapper>
				</Collapsible>
			</CollapsibleSectionWrapper>
		</>
	);
};

export default createFragmentContainer(RevenueRecognition, {
	project: graphql`
		fragment RevenueRecognition_project on ProjectType {
			id
			budgetType
			billable
			defaultPeriodBudgetType
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
			estimationUnit
			fixedPriceLocks(first: 1000) @connection(key: "Project_fixedPriceLocks", filters: []) {
				edges {
					node {
						id
						locked
						startDate
						endDate
					}
				}
			}
			rateCard {
				currency
			}
			...CloseMonthButtons_project
		}
	`,
});
