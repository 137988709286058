import {getTimestampCorrelationId} from '../canvas-timeline/canvas_timeline_performance_track';
import {fetchDataPromises} from '../scheduling_fetch';
import DirectApi from '../../../directApi';
import {getFetchDates} from '../loading/LoadMoreUtil';
import {useItemsLazyLoading} from '../canvas-timeline/canvas_timeline_util';

export const fetchData = (pageComponent, loadAllData) => {
	const req = {method: 'POST'};

	let requestUrl = DirectApi.graphqlServerEndpoint('scheduling/people/full');
	requestUrl += `?timestampCorrelationId=${getTimestampCorrelationId()}`;
	if (loadAllData) {
		requestUrl += '&loadAllData=true';
	}

	req.url = requestUrl;

	const requestUrls = [req];

	if (useItemsLazyLoading(pageComponent)) {
		req.body = {
			...getFetchDates(pageComponent),
		};
	}

	return fetchDataPromises(requestUrls);
};

const createNotificationDataByProjectRequest = projectIds => {
	const requestUrl = DirectApi.graphqlServerEndpoint('scheduling/notifications/projects');
	const body = {
		projectIds,
	};

	return {url: requestUrl, method: 'POST', body};
};

const createNotificationDataByAllocationRequest = allocationIds => {
	const requestUrl = DirectApi.graphqlServerEndpoint('scheduling/notifications/allocations');
	const body = {
		allocationIds,
	};

	return {url: requestUrl, method: 'POST', body};
};

export const fetchNotificationData = (projectIds, allocationIds) => {
	const requestUrls = [];
	if (projectIds.length > 0) {
		requestUrls.push(createNotificationDataByProjectRequest(projectIds));
	}
	if (allocationIds.length > 0) {
		requestUrls.push(createNotificationDataByAllocationRequest(allocationIds));
	}

	return fetchDataPromises(requestUrls).then(results => {
		let returnValue = {
			projects: [],
			projectPersons: [],
			tasks: [],
			allocations: [],
		};
		if (projectIds.length > 0) {
			returnValue = Object.assign(returnValue, results[0]);
		}
		if (allocationIds.length > 0) {
			const index = projectIds.length > 0 ? 1 : 0;
			returnValue = Object.assign(returnValue, results[index]);
		}
		return returnValue;
	});
};
