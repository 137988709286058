import React from 'react';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';
import Util from '../../../../../../forecast-app/shared/util/util';
import {renderCurrency, renderMinutes, renderPercentage, TIME_AGGREGATES} from '../../util/BudgetUtils';
import {DetailsValue} from '../../DetailsTable.styled';
import {Tooltip, Text} from '@forecast-it/design-system';
import {TooltipFormulaRenderer} from '../../../../../TooltipFormulaRenderer';

const DetailsTableRow = ({data, rowData, tableColumnProps, headerColumn, canExpand, tooltips}) => {
	const intl = useIntl();
	const aggregate = rowData.aggregate;
	const currencySymbol = Util.GetCurrencySymbol(rowData.currency);
	const showNegativesInRed = !aggregate.includes('Vs');
	if (canExpand !== undefined) {
		tableColumnProps.canExpand = canExpand;
	}

	const renderValue = object => {
		if (object) {
			if (aggregate === 'margin' || aggregate.includes('Margin')) {
				return renderPercentage(object.value, intl);
			} else if (TIME_AGGREGATES.includes(aggregate)) {
				return renderMinutes(object.value, intl);
			} else {
				return renderCurrency(currencySymbol, object.value, intl);
			}
		} else {
			return null;
		}
	};

	const getTooltipForAggregate = (tooltips, aggregate) => {
		const tooltipItems = tooltips ? tooltips[aggregate] : null;
		return tooltipItems ? tooltipItems : null;
	};

	const tooltipItem = getTooltipForAggregate(tooltips, aggregate);

	return (
		<Table.Row cy={'budget-details-row-' + aggregate} key={aggregate} {...tableColumnProps}>
			{tooltipItem ? (
				<Table.Column width="LARGE">
					<Tooltip width={'xl'} content={<TooltipFormulaRenderer items={tooltipItem} />} interactable={false}>
						<Text size={'3'}>{headerColumn}</Text>
					</Tooltip>
				</Table.Column>
			) : (
				<Table.Column width="LARGE">{headerColumn}</Table.Column>
			)}
			{rowData.dataArray.map((cell, index) => (
				<Table.Column
					cy={'budget-details-row-' + aggregate + '-column-' + data.columns[index]?.name}
					key={aggregate}
					negative={showNegativesInRed && cell && cell.value < 0}
					bold={index === rowData.dataArray.length - 1}
				>
					<DetailsValue>{renderValue(cell)}</DetailsValue>
				</Table.Column>
			))}
		</Table.Row>
	);
};

export default DetailsTableRow;
