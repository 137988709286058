import {useDataExport} from '../../../forecast-app/shared/hooks/useDataExport';
import ProjectFormatter from '../../../forecast-app/shared/util/export-formatters/ProjectFormatter';

export const FIXED_PRICE = 'fixed_price';
export const FIXED_PRICE_V2 = 'fixed_price_v2';
export const TIME_MATERIAL = 'time_material';
export const RETAINER = 'retainer';

const isFixedPrice = projectType => {
	return projectType === FIXED_PRICE || projectType === FIXED_PRICE_V2;
};

const getVisibleColumns = (theEyeOptions, projectType) => {
	let visibleColumns = [];

	theEyeOptions.forEach(option => {
		switch (option.name) {
			case 'project':
				visibleColumns.push('projectId');
				visibleColumns.push('projectName');
				break;
			case 'uninvoiced_time_registrations':
				if (projectType === TIME_MATERIAL) {
					visibleColumns.push('uninvoicedTimeRegistrationsTime');
					visibleColumns.push('uninvoicedTimeRegistrationsAmount');
				}
				break;
			case 'uninvoiced_fixed_price':
				if (isFixedPrice(projectType)) {
					visibleColumns.push('uninvoiced_fixed_price');
				}
				break;
			case 'uninvoiced_locked_periods':
				if (projectType === RETAINER) {
					visibleColumns.push('uninvoicedLockedPeriodsTime');
					visibleColumns.push('uninvoicedLockedPeriodsAmount');
				}
				break;
			case 'total_uninvoiced_time_material':
				if (projectType === TIME_MATERIAL) {
					visibleColumns.push('total_uninvoiced');
				}
				break;
			case 'total_uninvoiced_fixed_price':
				if (isFixedPrice(projectType)) {
					visibleColumns.push('total_uninvoiced');
				}
				break;
			case 'total_uninvoiced_retainer':
				if (projectType === RETAINER) {
					visibleColumns.push('total_uninvoiced');
				}
				break;
			default:
				visibleColumns.push(option.name);
		}
	});
	visibleColumns.push('currency');
	return visibleColumns;
};

function getFileName(projectType, timeMaterialDateRange) {
	let fileName = 'invoice_overview_' + projectType;
	if (projectType === TIME_MATERIAL) {
		fileName +=
			'_' +
			timeMaterialDateRange.startDate.format('D-MMM-YYYY') +
			'_' +
			timeMaterialDateRange.endDate.format('D-MMM-YYYY');
	}
	return fileName;
}

export function exportType(projectType, data, intl, availableFeatureFlags) {
	const exportData = useDataExport(
		new ProjectFormatter(intl, [], [], true),
		null,
		d => d,
		_ => getFileName(projectType, data.timeMaterialDateRange)
	);

	const visibleColumns = getVisibleColumns(data.theEyeOptions, projectType).map(name => {
		return {
			name: name,
			checked: true,
		};
	});

	exportData(visibleColumns, data.projectData[projectType].projects, null, null, 'CSV');
}

export const exportAllTypes = (data, intl) => {
	exportType(TIME_MATERIAL, data, intl);
	setTimeout(() => exportType(FIXED_PRICE, data, intl), 100);
	setTimeout(() => exportType(RETAINER, data, intl), 200);
};
