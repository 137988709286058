import React from 'react';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import Util from '../../../../forecast-app/shared/util/util';
import util from '../../../../forecast-app/shared/util/util';
import {ProjectStatusColor, TextColumn} from './ProjectPortfolioReport.styled';
import Person from '../../../../forecast-app/shared/components/person/person';
import {BUDGET_TYPE, ESTIMATION_UNIT, PROGRAM_BUDGET_TYPE, PROJECT_STATUS} from '../../../../constants';
import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';
import {ProjectContact} from './ProjectContact.styled';
import {ProjectPortfolioReportTooltips} from '../ProjectPortfolioReportTooltips';
import LabelGroup from '../../../../forecast-app/shared/components/labels/label_group';
import {CurrencyColumn} from '../CurrencyColumn';
import {PercentageColumn} from '../PercentageColumn';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import {profilePicSrc} from '../../../../directApi';
import CompanySetupUtil from '../../../../forecast-app/shared/util/CompanySetupUtil';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';

export const renderStatus = status => {
	if (status) {
		return <ProjectStatusColor style={{backgroundColor: status.color}} />;
	} else {
		return null;
	}
};

export const renderDescription = status => {
	let text = '';

	if (status && status.description) {
		try {
			const description = JSON.parse(status.description);
			text = description.blocks ? description.blocks.map(block => block.text).join('\n') : text;
		} catch (e) {
			text = status.description;
		}
	}

	return (
		<TooltipContainer infoText={text} tooltipInfinteDuration={true}>
			<TextColumn
				style={{
					WebkitLineClamp: '2',
					WebkitBoxOrient: 'vertical',
					whiteSpace: 'break-spaces',
					display: '-webkit-box',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					justifyContent: 'center',
					lineHeight: '1.5',
				}}
			>
				{text}
			</TextColumn>
		</TooltipContainer>
	);
};

export const renderStage = stage => {
	return <TextColumn>{Util.getProjectStageTranslation(stage, useIntl())}</TextColumn>;
};

export const renderRateCard = rateCard => {
	return <TextColumn>{rateCard && rateCard.name}</TextColumn>;
};

export const renderClient = client => {
	return <TextColumn>{client && client.name}</TextColumn>;
};

export const renderPriorityLevel = priorityLevel => {
	return <TextColumn>{priorityLevel && priorityLevel.name}</TextColumn>;
};

export const renderDate = date => {
	let dateString = '';
	if (date) {
		dateString = `${useIntl().formatDate(date.toDate(), {year: 'numeric', month: 'short', day: '2-digit'})}`;
	}
	return dateString;
};

export const renderOwners = people => {
	let contactPersons = [];
	if (people && people.edges) {
		contactPersons = people.edges
			.map(edge => edge.node)
			.filter(projectPerson => projectPerson.isContactPerson)
			.map(projectPerson => projectPerson.person);
	}

	return (
		<>
			{contactPersons.map((contactPerson, index) => (
				<ProjectContact key={index}>
					<TooltipContainer translatedMessage={true} infoText={contactPerson.fullName} timer={5 * 60 * 1000}>
						<Person
							name={contactPerson.fullName}
							imageSrc={profilePicSrc(contactPerson.profilePictureId)}
							showName={contactPersons.length === 1}
							showRole={false}
							hideTitle={true}
						/>
					</TooltipContainer>
				</ProjectContact>
			))}
		</>
	);
};

export const renderBudgetType = value => {
	let label = '';
	const intl = useIntl();
	if (value) {
		const {budgetType, defaultPeriodBudgetType} = value;
		label = Util.getBudgetTypeTranslation(budgetType, intl);
		if (budgetType === BUDGET_TYPE.RETAINER && defaultPeriodBudgetType) {
			label += ` (${Util.getPeriodBudgetTypeTranslation(defaultPeriodBudgetType, intl)})`;
		}
	}
	return <div>{label}</div>;
};

export const renderLabels = value => {
	if (value) {
		const labels = value.edges.map(e => e.node);
		return <LabelGroup labels={labels} noWrap={true} />;
	} else {
		return null;
	}
};

export const renderPercentage = percentage => {
	if (!!percentage && !isNaN(percentage)) {
		return Util.getFormattedPercentage(percentage, useIntl());
	} else {
		return '-';
	}
};

export const getProjectData = rowData => {
	const projectData = rowData.project ? rowData.project : {};

	projectData.startDate = util.CreateNonUtcMomentDate(
		projectData.projectStartYear,
		projectData.projectStartMonth,
		projectData.projectStartDay
	);
	projectData.endDate = util.CreateNonUtcMomentDate(
		projectData.projectEndYear,
		projectData.projectEndMonth,
		projectData.projectEndDay
	);
	return projectData;
};

export const getPhaseData = rowData => {
	const phaseData = rowData.phase ? {...rowData.phase} : {};

	if (rowData.phase) {
		phaseData.startDate = util.CreateNonUtcMomentDate(phaseData.startYear, phaseData.startMonth, phaseData.startDay);
		phaseData.endDate = util.CreateNonUtcMomentDate(phaseData.deadlineYear, phaseData.deadlineMonth, phaseData.deadlineDay);
	}
	return phaseData;
};

const ProjectPortfolioPeriodRow = ({financialNumbers, rowData, tableColumnProps, headerColumn, canExpand, hasBaseline}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const projectData = getProjectData(rowData);
	const phaseData = getPhaseData(rowData);
	const hasLowerWinChance =
		hasBaseline &&
		rowData.project?.useBaseline &&
		rowData.project?.status === PROJECT_STATUS.OPPORTUNITY &&
		rowData.project?.baselineWinChance < 1;
	const isEstimatedInPoints = rowData.project?.estimationUnit === ESTIMATION_UNIT.POINTS;
	const hasFinancialCategories = hasFeatureFlag('financial_categories_update');

	const renderMinutes = minutes => {
		if (minutes) {
			return Util.convertMinutesToFullHour(minutes, intl);
		} else {
			return '–';
		}
	};

	const isFixedPriceProject = () => {
		return [BUDGET_TYPE.FIXED_PRICE, BUDGET_TYPE.FIXED_PRICE_V2].includes(projectData.budgetType);
	};

	const getTotalRevRecWithTotalUnallocatedRevenue = () => {
		// Used for totals row
		return financialNumbers.totalRevenueRecognition + rowData.unallocatedRevenueTotal;
	};

	const getTotalProfitWithTotalUnallocatedRevenue = () => {
		// Used for totals row
		return financialNumbers.recognitionProfit + rowData.unallocatedRevenueTotal;
	};

	const getTotalProfitMargin = () => {
		// Used for totals row
		return (
			getTotalProfitWithTotalUnallocatedRevenue(financialNumbers, rowData) /
			getTotalRevRecWithTotalUnallocatedRevenue(financialNumbers, rowData)
		);
	};

	const getProgramTotalRevRecWithProjecUnallocatedRevenue = () => {
		// Used for fixed price program row
		return financialNumbers.recognitionProfit + financialNumbers.unallocatedRevenue;
	};

	if (canExpand !== undefined) {
		tableColumnProps.canExpand = canExpand;
	}

	const showProgramBudgetValueAsTotalRevRec =
		!!rowData.program && rowData.program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE;
	const showZerosWhereApplicable = !rowData.isFooter;
	const isFixedPriceProgramtRow = rowData?.program?.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE && !rowData.isFooter;
	const isFixedPriceOrFixedPriceRetainerProject = ProjectUtil.isFixedPriceProject(projectData);

	return (
		<Table.Row {...tableColumnProps}>
			<Table.Column>{headerColumn}</Table.Column>
			{/* Project Overview */}
			<Table.Column>{renderPriorityLevel(projectData.priorityLevel)}</Table.Column>
			<Table.Column>{renderPercentage(projectData.baselineWinChance)}</Table.Column>
			<Table.Column>{renderDate(projectData.startDate || phaseData.startDate)}</Table.Column>
			<Table.Column>{renderDate(projectData.endDate || phaseData.endDate)}</Table.Column>
			<Table.Column>{renderClient(projectData.client)}</Table.Column>
			<Table.Column>{renderStatus(projectData.currentProjectStatus)}</Table.Column>
			<Table.Column>{renderDescription(projectData.currentProjectStatus)}</Table.Column>
			<Table.Column>{renderOwners(projectData.projectPersons)}</Table.Column>
			<Table.Column>{renderStage(projectData.status)}</Table.Column>
			<Table.Column>{renderMinutes(financialNumbers.allocationMinutes)}</Table.Column>
			<Table.Column>{renderBudgetType(projectData)}</Table.Column>
			{CompanySetupUtil.hasFinance() ? (
				<Table.Column>{renderRateCard(projectData.rateCard)}</Table.Column>
			) : (
				<CurrencyColumn value={projectData.flatRate} currency={rowData.currency} />
			)}
			<Table.Column>{renderPercentage((projectData.completion || phaseData.progress) / 100)}</Table.Column>
			<Table.Column>{renderPercentage(projectData.progressEndOfTheMonth / 100)}</Table.Column>
			{/* Project Details */}
			<Table.Column>{renderMinutes(financialNumbers.baselineMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(financialNumbers.scopeTotalMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(financialNumbers.scopeApprovedMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(financialNumbers.registeredMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(financialNumbers.forecastTimeToComplete)}</Table.Column>
			<Table.Column>{renderMinutes(financialNumbers.totalTimeAtCompletion)}</Table.Column>
			{/* Baseline */}
			<CurrencyColumn value={financialNumbers.baselineRevenue} currency={rowData.currency} />
			<CurrencyColumn value={financialNumbers.baselineTimeAndExpenses} currency={rowData.currency} />
			<CurrencyColumn value={financialNumbers.baselineCost} currency={rowData.currency} />
			<CurrencyColumn value={financialNumbers.baselineProfit} currency={rowData.currency} />
			<PercentageColumn value={financialNumbers.baselineMargin} />
			{/* Planned */}
			<CurrencyColumn value={financialNumbers.plannedRevenue} currency={rowData.currency} />
			<CurrencyColumn
				value={financialNumbers.billablePlannedTimeAndExpenses}
				hasLowerWinChance={hasLowerWinChance && !isEstimatedInPoints}
				lowerWinChanceTooltip={ProjectPortfolioReportTooltips.PlanRevenueLowerWinChanceTooltip(formatMessage)}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.plannedCost}
				hasLowerWinChance={hasLowerWinChance && !isEstimatedInPoints}
				lowerWinChanceTooltip={ProjectPortfolioReportTooltips.PlanCostLowerWinChanceTooltip(formatMessage)}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={hasFinancialCategories ? financialNumbers.plannedRevenueProfit : financialNumbers.plannedProfit}
				currency={rowData.currency}
			/>
			<PercentageColumn
				value={hasFinancialCategories ? financialNumbers.plannedRevenueMargin : financialNumbers.plannedMargin}
			/>
			{/* Actuals */}
			<CurrencyColumn value={financialNumbers.totalActualRevenueRecognition} currency={rowData.currency} />
			<CurrencyColumn
				value={hasLowerWinChance ? 0 : financialNumbers.billableActualTimeAndExpenses}
				hasLowerWinChance={hasLowerWinChance}
				lowerWinChanceTooltip={ProjectPortfolioReportTooltips.ActualLowerWinChanceTooltip(formatMessage)}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={hasLowerWinChance ? 0 : financialNumbers.actualCost}
				lowerWinChanceTooltip={ProjectPortfolioReportTooltips.ActualLowerWinChanceTooltip(formatMessage)}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={hasFinancialCategories ? financialNumbers.actualRevenueProfit : financialNumbers.actualProfit}
				currency={rowData.currency}
			/>
			<PercentageColumn
				value={hasFinancialCategories ? financialNumbers.actualRevenueMargin : financialNumbers.actualMargin}
			/>
			{/* Remaining */}
			<CurrencyColumn value={financialNumbers.totalForecastRevenueToComplete} currency={rowData.currency} />
			<CurrencyColumn
				value={financialNumbers.billableForecastTimeAndExpensesToComplete}
				hasLowerWinChance={hasLowerWinChance}
				lowerWinChanceTooltip={ProjectPortfolioReportTooltips.billableForecastTimeAndExpensesToCompleteLowerWinChanceTooltip(
					formatMessage
				)}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.forecastCostToComplete}
				hasLowerWinChance={hasLowerWinChance}
				lowerWinChanceTooltip={ProjectPortfolioReportTooltips.ForecastCostToCompleteLowerWinChanceTooltip(
					formatMessage
				)}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={
					hasFinancialCategories
						? financialNumbers.forecastRevenueProfitToComplete
						: financialNumbers.forecastProfitToComplete
				}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<PercentageColumn
				value={
					hasFinancialCategories
						? financialNumbers.forecastRevenueMarginToComplete
						: financialNumbers.forecastMarginToComplete
				}
			/>
			{/* Forecasted */}
			<CurrencyColumn value={financialNumbers.totalRevenueRecognition} currency={rowData.currency} />
			<CurrencyColumn
				value={financialNumbers.billableTotalTimeAndExpensesAtCompletion}
				hasLowerWinChance={hasLowerWinChance}
				lowerWinChanceTooltip={ProjectPortfolioReportTooltips.BillableTotalTimeAndExpensesAtCompletionLowerWinChanceTooltip(
					formatMessage
				)}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.totalCostAtCompletion}
				hasLowerWinChance={hasLowerWinChance}
				lowerWinChanceTooltip={ProjectPortfolioReportTooltips.TotalCostAtCompletionLowerWinChanceTooltip(formatMessage)}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={
					hasFinancialCategories
						? financialNumbers.totalRevenueProfitAtCompletion
						: financialNumbers.totalProfitAtCompletion
				}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<PercentageColumn
				value={
					hasFinancialCategories
						? financialNumbers.totalRevenueMarginAtCompletion
						: financialNumbers.totalMarginAtCompletion
				}
			/>
			{/* Invoiced */}
			<CurrencyColumn value={financialNumbers.invoiced} currency={rowData.currency} />
			<CurrencyColumn value={financialNumbers.paid} currency={rowData.currency} />
			{/* Revenue Recognition */}
			<CurrencyColumn value={financialNumbers.recognitionLockedRevenue} currency={rowData.currency} />
			<CurrencyColumn value={financialNumbers.recognitionOpenRevenue} currency={rowData.currency} />
			<CurrencyColumn
				value={
					showProgramBudgetValueAsTotalRevRec
						? financialNumbers.programBudgetValue
						: rowData.isTotalsRow
						? getTotalRevRecWithTotalUnallocatedRevenue()
						: financialNumbers.totalRevenueRecognition
				}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={
					isFixedPriceProgramtRow
						? getProgramTotalRevRecWithProjecUnallocatedRevenue()
						: rowData.isTotalsRow
						? getTotalProfitWithTotalUnallocatedRevenue()
						: financialNumbers.recognitionProfit
				}
				tooltip={ProjectPortfolioReportTooltips.RecognitionProfitTooltip(formatMessage)}
				currency={rowData.currency}
			/>
			<PercentageColumn
				value={
					isFixedPriceProgramtRow
						? getProgramTotalRevRecWithProjecUnallocatedRevenue() / financialNumbers.programBudgetValue
						: rowData.isTotalsRow
						? getTotalProfitMargin()
						: financialNumbers.recognitionProfitPercentage
				}
				tooltip={ProjectPortfolioReportTooltips.RecognitionMarginTooltip(formatMessage)}
			/>
			{/* Fixed Price */}
			<CurrencyColumn
				value={
					isFixedPriceProject()
						? hasFeatureFlag('Expense_Modal_Update_22Q3')
							? financialNumbers.totalSuggestedRevenue
							: projectData.budgetBaseCurrency
						: null
				}
				currency={rowData.currency}
			/>
			{/* Actual vs Plan */}
			<CurrencyColumn
				value={financialNumbers.planVsBillableActualTimeAndExpenses}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.planVsActualCost}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.planVsActualProfit}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			{/* Forecasted vs Plan */}
			<CurrencyColumn
				value={financialNumbers.planVsTotalBillableTimeAndExpensesAtCompletion}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.planVsTotalCostAtCompletion}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.planVsTotalProfitAtCompletion}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			{/* Over/Under Servicing */}
			<CurrencyColumn
				value={financialNumbers.totalRevenueRecognition}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.billableTotalTimeAndExpensesAtCompletion}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.nonBillableTotalTimeAndExpensesAtCompletion}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.allTotalTimeAndExpensesAtCompletion}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={
					isFixedPriceOrFixedPriceRetainerProject
						? financialNumbers.projectedTotalRevenueVsProjectedTotalBillableValueOfService
						: '-'
				}
				showZero={isFixedPriceOrFixedPriceRetainerProject}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.projectedTotalRevenueVsProjectedTotalValueOfService}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			{/* Invoiced vs. Actual */}
			<CurrencyColumn value={financialNumbers.invoiced} showZero={showZerosWhereApplicable} currency={rowData.currency} />
			<CurrencyColumn
				value={financialNumbers.billableActualTimeAndExpenses}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.recognitionLockedRevenue}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			<CurrencyColumn
				value={financialNumbers.accruedDeferred}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
			{/* Labels */}
			<Table.Column>{renderLabels(projectData.projectLabels)}</Table.Column>
			{/* Retainer period target */}
			<Table.Column>{renderMinutes(financialNumbers.retainerPeriodTargetMinutes)}</Table.Column>
			<CurrencyColumn
				value={financialNumbers.retainerPeriodTargetPrice}
				showZero={showZerosWhereApplicable}
				currency={rowData.currency}
			/>
		</Table.Row>
	);
};

export default createFragmentContainer(ProjectPortfolioPeriodRow, {
	financialNumbers: graphql`
		fragment ProjectPortfolioPeriodRow_financialNumbers on FinancialNumbers {
			billableActualTimeAndExpenses
			totalActualRevenueRecognition
			actualCost
			actualProfit
			actualRevenueProfit
			actualMargin
			actualRevenueMargin
			billablePlannedTimeAndExpenses
			plannedRevenue
			plannedCost
			plannedProfit
			plannedRevenueProfit
			plannedMargin
			plannedRevenueMargin
			billableForecastTimeAndExpensesToComplete
			totalForecastRevenueToComplete
			forecastCostToComplete
			forecastProfitToComplete
			forecastRevenueProfitToComplete
			forecastMarginToComplete
			forecastRevenueMarginToComplete
			billableTotalTimeAndExpensesAtCompletion
			nonBillableTotalTimeAndExpensesAtCompletion
			allTotalTimeAndExpensesAtCompletion
			totalRevenueRecognition
			totalCostAtCompletion
			totalProfitAtCompletion
			totalRevenueProfitAtCompletion
			totalMarginAtCompletion
			totalRevenueMarginAtCompletion
			baselineRevenue
			baselineTimeAndExpenses
			baselineCost
			baselineProfit
			baselineMargin
			baselineMinutes
			planVsBillableActualTimeAndExpenses
			planVsActualCost
			planVsActualProfit
			planVsTotalBillableTimeAndExpensesAtCompletion
			planVsTotalCostAtCompletion
			planVsTotalProfitAtCompletion
			registeredMinutes
			forecastTimeToComplete
			totalTimeAtCompletion
			totalSuggestedRevenue
			scopeApprovedMinutes
			scopeTotalMinutes
			allocationMinutes
			invoiced
			paid
			accruedDeferred
			retainerPeriodTargetMinutes
			retainerPeriodTargetPrice
			recognitionLockedRevenue
			recognitionOpenRevenue
			totalRevenueRecognition
			unallocatedRevenue
			programBudgetValue
			recognitionProfit
			recognitionProfitPercentage
			projectedTotalRevenueVsProjectedTotalBillableValueOfService
			projectedTotalRevenueVsProjectedTotalValueOfService
		}
	`,
});
