import {
	drawRectangle,
	drawTimelineGraphBottomBar,
	GROUP_SECTION_SPACING_LEVEL_ONE,
	GROUP_SECTION_TEXT_GREY_DARK,
	GROUP_SECTION_WIDTH,
	GROUP_TYPE,
	TIMELINE_GRAPH_BAR_ELEMENT_TYPE,
	TIMELINE_GRAPH_COLORS,
	TIMELINE_GRAPH_ENTITY_TYPE,
	TIMELINE_GRAPH_GROUP_ENTITY_TYPE_HEIGHT,
	TIMELINE_GRAPH_GROUP_LEGEND_SPACING,
	TIMELINE_GRAPH_GROUP_LEGEND_WIDTH,
	TIMELINE_GRAPH_GROUP_PADDING,
	TIMELINE_GRAPH_GROUP_TITLE_FONT_SIZE,
	TIMELINE_GRAPH_GROUP_VERTICAL_SPACING,
	TIMELINE_GRAPH_HEIGHT,
	TIMELINE_GRAPH_HIGHLIGHTER_TYPE,
	TIMELINE_GRAPH_LINE_TYPE,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import Util from '../../../../forecast-app/shared/util/util';

class TimelineGraphGroup extends Group {
	constructor(pageComponent, data) {
		if (data.sections?.length > 0) {
			const spacing = TIMELINE_GRAPH_GROUP_PADDING * 3;
			const sectionsHeight = TIMELINE_GRAPH_GROUP_VERTICAL_SPACING + TIMELINE_GRAPH_GROUP_ENTITY_TYPE_HEIGHT;
			const allSectionsHeight = data.sections.length * sectionsHeight;
			const calculatedHeight = TIMELINE_GRAPH_GROUP_TITLE_FONT_SIZE + spacing + allSectionsHeight;
			data.height = Math.max(TIMELINE_GRAPH_HEIGHT, calculatedHeight);
		}

		super(pageComponent, GROUP_TYPE.TIMELINE_GRAPH_GROUP, data);
		this.groupId = data.groupId;
	}

	drawLegendName(canvasContext, x, y, name) {
		const fontSize = 11;
		const xOffset = TIMELINE_GRAPH_GROUP_LEGEND_WIDTH + TIMELINE_GRAPH_GROUP_LEGEND_SPACING;
		const yOffset = fontSize / 2 - 1;

		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
		canvasContext.font = `400 ${fontSize}px ` + Util.getFontFamily();

		canvasContext.fillText(name, x + xOffset, y + yOffset);
	}

	drawLineLegend(canvasContext, intl, x, y, strokeColor, height, name) {
		const yOffset = height / 2;

		drawRectangle(canvasContext, x, y - yOffset, TIMELINE_GRAPH_GROUP_LEGEND_WIDTH, height, {
			backgroundColor: strokeColor,
			borderTopLeftRadius: 2,
			borderBottomLeftRadius: 2,
			borderTopRightRadius: 2,
			borderBottomRightRadius: 2,
		});

		this.drawLegendName(canvasContext, x, y, name);
	}

	drawBarHighlightLegend(canvasContext, intl, x, y, stroke, height, name) {
		const yOffset = height / 2;

		drawRectangle(canvasContext, x, y - yOffset, TIMELINE_GRAPH_GROUP_LEGEND_WIDTH, height, {
			backgroundColor: stroke,
			borderTopLeftRadius: 2,
			borderBottomLeftRadius: 2,
			borderTopRightRadius: 2,
			borderBottomRightRadius: 2,
		});

		this.drawLegendName(canvasContext, x, y, name);
	}

	drawBarElementLegend(canvasContext, intl, x, y, name, options) {
		const yOffset = TIMELINE_GRAPH_GROUP_LEGEND_WIDTH / 2;

		drawRectangle(canvasContext, x, y - yOffset, TIMELINE_GRAPH_GROUP_LEGEND_WIDTH, TIMELINE_GRAPH_GROUP_LEGEND_WIDTH, {
			borderThickness: 1,
			borderTopLeftRadius: 2,
			borderBottomLeftRadius: 2,
			borderTopRightRadius: 2,
			borderBottomRightRadius: 2,
			...options,
		});

		this.drawLegendName(canvasContext, x, y, name);
	}

	drawSection(canvasContext, intl, section, index, groupX, groupY) {
		const schedulingOptions = this.data.getSchedulingOptions();
		const {hideSoft} = schedulingOptions;

		if (Object.values(TIMELINE_GRAPH_ENTITY_TYPE).includes(section.graphType)) {
			const x = groupX + GROUP_SECTION_SPACING_LEVEL_ONE;
			const y =
				groupY +
				TIMELINE_GRAPH_GROUP_PADDING +
				index * (TIMELINE_GRAPH_GROUP_ENTITY_TYPE_HEIGHT + TIMELINE_GRAPH_GROUP_VERTICAL_SPACING);

			switch (section.entity) {
				case TIMELINE_GRAPH_LINE_TYPE.TOTAL_AVAILABILITY:
					this.drawLineLegend(
						canvasContext,
						intl,
						x,
						y,
						TIMELINE_GRAPH_COLORS[TIMELINE_GRAPH_LINE_TYPE.TOTAL_AVAILABILITY].STROKE,
						2,
						intl.formatMessage({id: 'scheduling.graph.total_availability'})
					);
					break;
				case TIMELINE_GRAPH_HIGHLIGHTER_TYPE.ROLE_OVERALLOCATED:
					this.drawBarHighlightLegend(
						canvasContext,
						intl,
						x,
						y,
						TIMELINE_GRAPH_COLORS[TIMELINE_GRAPH_HIGHLIGHTER_TYPE.ROLE_OVERALLOCATED].STROKE,
						6,
						intl.formatMessage({id: 'scheduling.graph.role_overallocated'})
					);
					break;
				case TIMELINE_GRAPH_BAR_ELEMENT_TYPE.REMAINING_AVAILABILITY:
					this.drawBarElementLegend(
						canvasContext,
						intl,
						x,
						y,
						intl.formatMessage({id: 'scheduling.graph.remaining_availability'}),
						{
							backgroundColor:
								TIMELINE_GRAPH_COLORS[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.REMAINING_AVAILABILITY].BACKGROUND,
							borderColor: TIMELINE_GRAPH_COLORS[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.REMAINING_AVAILABILITY].BORDER,
						}
					);
					break;
				case TIMELINE_GRAPH_BAR_ELEMENT_TYPE.PLACEHOLDER:
					this.drawBarElementLegend(
						canvasContext,
						intl,
						x,
						y,
						intl.formatMessage({id: 'scheduling.graph.placeholders'}),
						{
							backgroundColor: TIMELINE_GRAPH_COLORS[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.PLACEHOLDER].BACKGROUND,
							borderColor: TIMELINE_GRAPH_COLORS[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.PLACEHOLDER].BORDER,
							dashedBorder: true,
							dashedBorderPattern: [1, 2],
						}
					);
					break;
				case TIMELINE_GRAPH_BAR_ELEMENT_TYPE.SOFT_ALLOCATION:
					this.drawBarElementLegend(
						canvasContext,
						intl,
						x,
						y,
						intl.formatMessage({id: 'scheduling.graph.peoples_soft_allocations_with_parentes'}),
						{
							backgroundColor: TIMELINE_GRAPH_COLORS[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.SOFT_ALLOCATION].BACKGROUND,
							borderColor: TIMELINE_GRAPH_COLORS[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.SOFT_ALLOCATION].BORDER,
						}
					);
					break;
				case TIMELINE_GRAPH_BAR_ELEMENT_TYPE.ALLOCATION:
					this.drawBarElementLegend(
						canvasContext,
						intl,
						x,
						y,
						!hideSoft
							? intl.formatMessage({id: 'scheduling.graph.peoples_hard_allocations_with_parentes'})
							: intl.formatMessage({id: 'scheduling.graph.peoples_allocations'}),
						{
							backgroundColor: TIMELINE_GRAPH_COLORS[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.ALLOCATION].BACKGROUND,
							borderColor: TIMELINE_GRAPH_COLORS[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.ALLOCATION].BORDER,
						}
					);
					break;
				default:
					break;
			}
		}
	}

	draw(canvasContext, x, y) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height} = this;
		super.draw(x, y, width, height, this);

		const {intl} = this.pageComponent.props;
		const {title, sections, eyeOptions} = data;

		const showGraphEyeOption = eyeOptions?.find(option => option.name === 'show-graph');
		const isHiddenByEyeOption = showGraphEyeOption && !showGraphEyeOption.checked;

		if (!sections || isHiddenByEyeOption) return;

		const visibleSections = sections.filter(section => !section.isHidden || section.isHidden() === false);

		// graph title
		const titleY = y + TIMELINE_GRAPH_GROUP_PADDING + TIMELINE_GRAPH_GROUP_TITLE_FONT_SIZE;
		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
		canvasContext.font = `600 ${TIMELINE_GRAPH_GROUP_TITLE_FONT_SIZE}px ` + Util.getFontFamily();
		canvasContext.fillText(title, x + GROUP_SECTION_SPACING_LEVEL_ONE, titleY);

		// sections
		const sectionsY = titleY + TIMELINE_GRAPH_GROUP_PADDING / 2;
		let sectionsCreated = 0;
		visibleSections.forEach(graphSection => {
			this.drawSection(canvasContext, intl, graphSection, sectionsCreated, x, sectionsY);
			sectionsCreated++;
		});

		// BOTTOM BAR
		drawTimelineGraphBottomBar(canvasContext, x, y, height, width);
	}
}

export default TimelineGraphGroup;
