import React from 'react';
import Moment from 'moment';
import {
	TableHeaderStyle,
	TableHeaderColumnStyle,
	WeekRangeHeaderCell,
	WeekDayHeaderCell,
	WeekTotalHeaderCell,
} from './timesheets_team_page_styled';

export const TableHeader = ({intl, firstDayOfWeek}) => {
	const {formatMessage} = intl;

	const getWeekDays = () => {
		const weekDays = Moment.weekdays(true);

		return weekDays.map((weekDay, index) => {
			const day = firstDayOfWeek.clone().add(index, 'day');
			const today = Moment(day).isSame(Moment(), 'day');

			return (
				<TableHeaderColumnStyle className={today ? ' today' : ''}>
					<WeekDayHeaderCell>
						<div className={'weekday'}>{weekDay}</div>
						<div className={'day'}>{day.format('DD MMM')}</div>
					</WeekDayHeaderCell>
				</TableHeaderColumnStyle>
			);
		});
	};

	return (
		<TableHeaderStyle>
			<tr>
				<TableHeaderColumnStyle>
					<WeekRangeHeaderCell>
						<div className={'range'}>
							{firstDayOfWeek.date() + ' - ' + firstDayOfWeek.clone().endOf('week').format('DD MMM YYYY')}
						</div>
						<div className={'week-number'}>
							&nbsp;({formatMessage({id: 'common.week'}, {week: firstDayOfWeek.week()})}){' '}
						</div>
					</WeekRangeHeaderCell>
				</TableHeaderColumnStyle>
				{getWeekDays()}
				<TableHeaderColumnStyle>
					<WeekTotalHeaderCell>{formatMessage({id: 'timesheet.week_total'})}</WeekTotalHeaderCell>
				</TableHeaderColumnStyle>
			</tr>
		</TableHeaderStyle>
	);
};
