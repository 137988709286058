import React from 'react';
import {useIntl} from 'react-intl';
import {Table} from 'web-components';
import MonthByMonthClientLoader, {monthByMonthClientLoaderQuery} from './MonthByMonthClientLoader';
import {filtersToSearchQuery} from '../ProjectPortfolioReportUtils';
import ProjectPortfolioMonthByMonthClientDownload, {
	projectPortfolioMonthByMonthClientDownloadQuery,
} from './ProjectPortfolioMonthByMonthClientDownload';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {LoadMore} from '../../../loaders/LoadMore';
import ProjectPortfolioMonthByMonthRow from './ProjectPortfolioMonthByMonthRow';
import ProjectPortfolioMonthByMonthProjectRows from './ProjectPortfolioMonthByMonthProjectRows';

const ProjectPortfolioMonthByMonthClientRows = ({
	setCsvDownloadFunction,
	hasBaseline,
	pageSize,
	startDate,
	endDate,
	filters,
	boolEyeOptions,
	levelProps,
	timeSearchQuery,
	expenseSearchQuery,
	selectedSingleValue,
}) => {
	const intl = useIntl();

	return (
		<LoadMore
			key="query-render-month-by-month-client"
			query={monthByMonthClientLoaderQuery}
			variables={{
				searchQuery: filtersToSearchQuery(filters),
				timeSearchQuery: timeSearchQuery,
				expenseSearchQuery: expenseSearchQuery,
				startYear: startDate.year(),
				startMonth: startDate.month() + 1,
				startDay: startDate.date(),
				endYear: endDate.year(),
				endMonth: endDate.month() + 1,
				endDay: endDate.date(),
				pageSize: pageSize,
				selectedSingleValue: selectedSingleValue,
			}}
			loader={<MonthByMonthClientLoader />}
		>
			{({data, loadMore}) => {
				setCsvDownloadFunction(() => {
					showModal({
						type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
						componentName: 'project_portfolio_month_by_month_client_csv_loader',
						query: projectPortfolioMonthByMonthClientDownloadQuery,
						variables: {
							searchQuery: filtersToSearchQuery(filters),
							timeSearchQuery: timeSearchQuery,
							expenseSearchQuery: expenseSearchQuery,
							startYear: startDate.year(),
							startMonth: startDate.month() + 1,
							startDay: startDate.date(),
							endYear: endDate.year(),
							endMonth: endDate.month() + 1,
							endDay: endDate.date(),
							pageSize: 1000000,
							selectedSingleValue: selectedSingleValue,
						},
						createCsvDownload: <ProjectPortfolioMonthByMonthClientDownload />,
						startDate,
						endDate,
						boolEyeOptions,
						selectedSingleValue,
					});
				});
				return (
					<Table.Rows data={data} canExpand={true} loadMore={loadMore} autoload={true} {...levelProps}>
						{({rowData, tableColumnsProps, expanded, nextLevelProps}) => {
							const fixedPriceLocks = rowData?.project?.fixedPriceLocks;
							return (
								<>
									<ProjectPortfolioMonthByMonthRow
										rowData={rowData}
										tableColumnProps={tableColumnsProps}
										headerColumn={
											rowData.client.name
												? rowData.client.name
												: intl.formatMessage({id: 'common.no_client'})
										}
										selectedSingleValue={selectedSingleValue}
										hasBaseline={hasBaseline}
										fixedPriceLocks={fixedPriceLocks}
									/>
									{expanded && (
										<ProjectPortfolioMonthByMonthProjectRows
											setCsvDownloadFunction={setCsvDownloadFunction}
											hasBaseline={hasBaseline}
											pageSize={pageSize}
											startDate={startDate}
											endDate={endDate}
											filters={filters}
											boolEyeOptions={boolEyeOptions}
											levelProps={nextLevelProps}
											timeSearchQuery={timeSearchQuery}
											expenseSearchQuery={expenseSearchQuery}
											selectedSingleValue={selectedSingleValue}
											clientId={rowData.client.id}
										></ProjectPortfolioMonthByMonthProjectRows>
									)}
								</>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default ProjectPortfolioMonthByMonthClientRows;
