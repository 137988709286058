import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import BgPic from './images/sections/not-found/bg.png';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import {MODAL_TYPE, showModal} from './forecast-app/shared/components/modals/generic_modal_conductor';
import 'whatwg-fetch';
import DirectApi from './directApi';
import {hasFeatureFlag} from './forecast-app/shared/util/FeatureUtil'; //IE 11 fetch polyfill
import {getTeamFromPathname} from './sentry/GetTeamFromPathname';

window.lastErrorBoundaryError = null;
const getPrettyErrorMessage = (error, stackLimit = 3) => {
	const errorType = error?.name || '';
	const errorMessage = error?.message || '';
	const stack = error?.stack || '';
	const stackContainsMessage = stack.includes(errorMessage);
	const shortStack = stack.split('\n', stackContainsMessage ? stackLimit + 1 : stackLimit).join('\n');
	let message = '';
	if (!stackContainsMessage) {
		message += `${errorType}: ${errorMessage}\n`;
	}
	message += shortStack;
	return message.substring(0, 300);
};

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {error: null};

		this.onUnhandledRejection = this.onUnhandledRejection.bind(this);
	}

	componentDidMount() {
		window.addEventListener('unhandledrejection', this.onUnhandledRejection);
	}

	componentDidCatch(error, errorInfo) {
		this.handleError(error);
	}

	componentWillUnmount() {
		window.removeEventListener('unhandledrejection', this.onUnhandledRejection);
	}

	onUnhandledRejection(promiseRejectionEvent) {
		if (hasFeatureFlag('use_custom_error_boundary') && false) {
			// Temporarily disabled until we can find a solution that doesn't show unnessasary error to users.
			const reason = promiseRejectionEvent.reason;
			if (reason instanceof Error) {
				this.handleError(reason);
			}
		}
	}

	handleError(error) {
		if (window.lastErrorBoundaryError + '' === error + '') {
			// eslint-disable-next-line no-console
			console.log('ErrorBoundary filtered out duplicate error' + error);
		} else {
			if (this.props.modal) {
				showModal({
					type: MODAL_TYPE.BOUNDARY_ERROR,
					modalType: this.props.modalType,
				});
			}
			const errorId = Sentry.captureException(error, {
				tags: {bugduty: true, team: getTeamFromPathname(window.location.pathname)},
			});
			// eslint-disable-next-line no-console
			console.log('ErrorBoundary reported new error: ' + error);
			window.lastErrorBoundaryError = error;

			const init = {credentials: 'include'};

			const prettyError = getPrettyErrorMessage(error);
			let options = '?url=' + encodeURI(window.location.pathname) + '&note=' + encodeURI(prettyError);

			if (errorId) {
				options += `&sentryEventId=${errorId}`;
			}

			fetch(DirectApi.graphqlServerEndpoint('error_modal_shown' + options), init);
			this.setState({error});
		}
	}
	reload() {
		window.lastErrorBoundaryError = null;
		window.location.href = '/';
	}

	render() {
		if (this.state.error) {
			//render fallback UI
			if (this.props.modal) {
				return null;
			}
			if (this.props.header) {
				return <div>Menu unavailable</div>;
			}
			return (
				<div className="error-boundary">
					<div className="text-container">
						<h1>
							<FormattedMessage id="not_found.header" />
						</h1>
						<p>
							<FormattedMessage id="error_boundary.message1" />
						</p>
						<p>
							<FormattedHTMLMessage id="error_boundary.message2" />
						</p>
						{this.props.showReloadButton ? (
							<button className="reload-button" onClick={this.reload}>
								<FormattedMessage id="modal.new_version_available.header.button" />
							</button>
						) : null}
					</div>
					<div className="image-container">
						<img src={BgPic} alt="" />
					</div>
				</div>
			);
		} else {
			//when there's not an error, render children untouched
			return this.props.children;
		}
	}
}

export default ErrorBoundary;
