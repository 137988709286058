/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetPageHeader_project$ref: FragmentReference;
declare export opaque type BudgetPageHeader_project$fragmentType: BudgetPageHeader_project$ref;
export type BudgetPageHeader_project = {|
  +id: string,
  +projectFirstDateYear: ?number,
  +projectFirstDateMonth: ?number,
  +projectFirstDateDay: ?number,
  +projectLastDateYear: ?number,
  +projectLastDateMonth: ?number,
  +projectLastDateDay: ?number,
  +budgetType: ?BUDGET_TYPE,
  +budget: ?number,
  +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
  +rateCard: ?{|
    +currency: ?string
  |},
  +estimationUnit: ?string,
  +billable: ?boolean,
  +manualProgressOnProjectEnabled: ?boolean,
  +manualProgressOnPhasesEnabled: ?boolean,
  +manualProgressOnTasksEnabled: ?boolean,
  +$refType: BudgetPageHeader_project$ref,
|};
export type BudgetPageHeader_project$data = BudgetPageHeader_project;
export type BudgetPageHeader_project$key = {
  +$data?: BudgetPageHeader_project$data,
  +$fragmentRefs: BudgetPageHeader_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BudgetPageHeader_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultPeriodBudgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RateCard",
      "kind": "LinkedField",
      "name": "rateCard",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimationUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manualProgressOnProjectEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manualProgressOnPhasesEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manualProgressOnTasksEnabled",
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = 'd57e44c1a15bd4993fc43d5ad4b74237';

module.exports = node;
