import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {findDOMNode} from 'react-dom';
import CustomScrollDiv from '../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {profilePicSrc} from '../directApi';

class PersonSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isExpanded: false,
			inputValue: '',
			marked: null,
		};
	}

	handleOptionMouseEnter(index) {
		this.setState({marked: index});
	}

	handleOptionMouseLeave() {
		this.setState({marked: null});
	}

	handleArrowClick(e) {
		e.stopPropagation();
		if (this.state.isExpanded && this.props.onBlur) {
			this.props.onBlur();
		} else if (!this.state.isExpanded && this.props.onExpand) {
			this.props.onExpand();
		}
		this.setState({isExpanded: !this.state.isExpanded});
	}

	handleExpand(e) {
		e.stopPropagation();
		this.setState({isExpanded: true});
		if (this.props.onExpand) {
			this.props.onExpand();
		}
	}

	handleFocus() {
		if (this.props.openOnFocus && !this.state.isExpanded) {
			this.setState({isExpanded: true});
			if (this.props.onExpand) {
				this.props.onExpand();
			}
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11

		//marginRight -17 thing is to make it work in internet explorer
		if (
			newTarget &&
			(newTarget.id === 'person-selector-input' ||
				newTarget.id === 'person-selector-container' ||
				(newTarget.style && newTarget.style.marginRight === '-17px'))
		)
			return;
		this.setState({isExpanded: false});
		if (this.props.onBlur) {
			this.props.onBlur();
		}
	}

	handleSelectItem(itemId, e) {
		if (e) {
			e.stopPropagation();
		}
		if (this.props.handleSelectItem) {
			this.props.handleSelectItem(itemId);
			this.setState({inputValue: ''});
		}
		if (!this.props.inline) {
			this.setState({isExpanded: false, inputValue: ''});
		}
	}

	handleKeyPress(e) {
		if (this.state.isExpanded) {
			let options = this.props.people;
			if (this.state.inputValue) {
				options = options.filter(person => {
					return (person.firstName + ' ' + person.lastName + ' ' + person.email)
						.toUpperCase()
						.includes(this.state.inputValue.trim().toUpperCase());
				});
			}
			if (e.key === 'Enter') {
				if (this.state.marked !== null) {
					if (!options[this.state.marked]) return;
					this.handleSelectItem(options[this.state.marked].id);
				} else if (options.length === 1) {
					this.handleSelectItem(options[0].id);
				}
			} else if (e.key === 'ArrowDown') {
				if (this.state.marked === null) {
					this.setState({marked: 0});
				} else {
					let markIndex = this.state.marked + 1;
					if (markIndex > options.length - 1) return;
					this.setState({marked: markIndex});
					this.scrollElementIntoView();
				}
			} else if (e.key === 'ArrowUp' && this.state.marked !== null) {
				if (this.state.marked === 0) {
					this.setState({marked: null});
				} else {
					this.setState({marked: this.state.marked - 1});
					this.scrollElementIntoView();
				}
			}
		}
	}

	scrollElementIntoView() {
		const markedElements = findDOMNode(this.refs.dropdown).getElementsByClassName('marked');
		if (markedElements.length) {
			markedElements[0].scrollIntoView(false);
			this.refs.dropdown.scrollTop += 53;
		}
	}

	render() {
		const {formatMessage} = this.props.intl;

		let people = this.props.people;
		if (this.state.inputValue) {
			people = people.filter(person => {
				return (person.firstName + ' ' + person.lastName + ' ' + person.email)
					.toUpperCase()
					.includes(this.state.inputValue.trim().toUpperCase());
			});
		}
		//if there is parent role first sort by role having elements with same role on top, after sort by name
		const parentRole = this.props.parentRole;
		people.sort((a, b) => {
			let roleAMatch = false,
				roleBMatch = false;
			if (parentRole !== null && parentRole !== undefined) {
				roleAMatch = a.roleId === parentRole.id;
				roleBMatch = b.roleId === parentRole.id;
			}
			const nameA = (a.firstName + ' ' + a.lastName).toLowerCase();
			const nameB = (b.firstName + ' ' + b.lastName).toLowerCase();
			if (roleAMatch && !roleBMatch) return -2;
			if (!roleAMatch && roleBMatch) return 1;
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}
			return a.id < b.id ? -1 : 1;
		});

		return (
			<div
				className="person-selector-container flow-sample-team-add"
				tabIndex="0"
				onBlur={this.handleBlur.bind(this)}
				id="person-selector-container"
			>
				<div className={'input-container ' + (this.state.isExpanded ? 'focused' : '')}>
					<input
						type="text"
						autoFocus={this.props.openOnFocus}
						placeholder={
							this.state.isExpanded
								? ''
								: this.props.helpText
								? this.props.helpText
								: formatMessage({id: 'person_selector.placeholder'})
						}
						ref={input => (this.input = input)}
						onFocus={this.handleFocus.bind(this)}
						onClick={this.handleExpand.bind(this)}
						id="person-selector-input"
						value={this.state.inputValue}
						onChange={e => this.setState({inputValue: e.target.value, marked: null})}
						onKeyDown={this.handleKeyPress.bind(this)}
					/>
					<div
						className={'arrow ' + (this.state.isExpanded ? 'expanded' : 'collapsed')}
						onClick={this.handleArrowClick.bind(this)}
					/>
				</div>
				<div
					className={
						'dropdown ' +
						(this.state.isExpanded ? 'expanded' : 'collapsed') +
						(this.props.showOnTop ? ' showOnTop' : ' showOnBottom')
					}
				>
					<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={300} onClick={e => e.stopPropagation()}>
						<div className="dropdown-inner" ref="dropdown">
							{people.map((person, index) => (
								<div
									className={'person ' + (index === this.state.marked ? 'marked' : '')}
									key={person.id}
									onMouseDown={this.handleSelectItem.bind(this, person.id)}
									onMouseEnter={this.handleOptionMouseEnter.bind(this, index)}
									onMouseLeave={this.handleOptionMouseLeave.bind(this)}
								>
									<div className="image-container">
										{person.profilePictureId ? (
											<img
												alt="profile"
												crossOrigin="use-credentials"
												src={profilePicSrc(person.profilePictureId)}
												width="36"
												height="36"
											/>
										) : (
											'TODO'
										)}
									</div>
									<div className="text-container">
										<div className="name">{person.firstName + ' ' + person.lastName}</div>
										{this.props.showRole ? (
											person.role && person.role !== '' ? (
												<div className="email">{person.role}</div>
											) : null
										) : person.email && person.email !== '' ? (
											<div className="email">{person.email}</div>
										) : null}
									</div>
								</div>
							))}
						</div>
					</CustomScrollDiv>
				</div>
			</div>
		);
	}
}

PersonSelector.propTypes = {
	people: PropTypes.array.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	inline: PropTypes.bool,
	onBlur: PropTypes.func,
	onExpand: PropTypes.func,
	openOnFocus: PropTypes.bool,
	showRole: PropTypes.bool,
};

export default injectIntl(PersonSelector);
