import {fetchNotificationData} from './CanvasPlaceholdersFetchUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {
	createOrUpdatePlaceholder,
	createOrUpdatePlaceholderAllocation,
	deleteMultiplePlaceholderAllocations,
	deletePlaceholder,
} from './PlaceholdersSchedulingSuccessLogic';

const eventTypesToHandle = ['PLACEHOLDER_ALLOCATION', 'PLACEHOLDER', 'ALLOCATION'];

const processData = (data, updatedData) => {
	const projectByProjectId = {};
	const addedProjects = [];
	const addedProjectPersons = [];
	const placeholderAllocationById = {};
	const placeholderAllocations = [];
	const placeholdersById = {};
	const placeholders = [];
	if (updatedData.projects.length > 0) {
		updatedData.projects.forEach(updatedProject => {
			const project = data.projects.find(project => project.id === updatedProject.id);
			if (project) {
				Object.assign(project, updatedProject);
			} else {
				addedProjects.push(updatedProject);
				data.projects.push(updatedProject);
			}
			projectByProjectId[updatedProject.id] = updatedProject;
		});
	}

	if (updatedData.projectPersons.length > 0) {
		updatedData.projectPersons.forEach(updatedProjectPerson => {
			const projectPerson = data.projectPersons.find(projectPerson => projectPerson.id === updatedProjectPerson.id);
			if (projectPerson) {
				Object.assign(projectPerson, updatedProjectPerson);
			} else {
				addedProjectPersons.push(updatedProjectPerson);
				data.projectPersons.push(updatedProjectPerson);
			}
		});
	}

	if (updatedData.placeholderAllocations.length > 0) {
		// Go through allocations to find added/updated allocations
		updatedData.placeholderAllocations.forEach(updatedPlaceholderAllocation => {
			const placeholderAllocation = data.placeholderAllocations.find(
				placeholderAllocation => placeholderAllocation.id === updatedPlaceholderAllocation.id
			);
			if (placeholderAllocation) {
				Object.assign(placeholderAllocation, updatedPlaceholderAllocation);
				placeholderAllocations.push(placeholderAllocation);
			} else {
				placeholderAllocations.push(updatedPlaceholderAllocation);
			}

			placeholderAllocationById[updatedPlaceholderAllocation.id] = updatedPlaceholderAllocation;
		});
	}

	if (updatedData.placeholders.length > 0) {
		updatedData.placeholders.forEach(updatedPlaceholder => {
			const placeholder = data.placeholders.find(placeholder => placeholder.id === updatedPlaceholder.id);
			if (placeholder) {
				Object.assign(placeholder, updatedPlaceholder);
				placeholders.push(placeholder);
			} else {
				placeholders.push(updatedPlaceholder);
			}

			placeholdersById[updatedPlaceholder.id] = updatedPlaceholder;
		});
	}

	return {
		projectByProjectId,
		addedProjects,
		addedProjectPersons,
		placeholderAllocationById,
		placeholderAllocations,
		placeholdersById,
		placeholders,
		placeholderSkills: updatedData.placeholderSkills,
	};
};

const processPlaceholders = (pageComponent, processedData) => {
	const {placeholders, placeholderSkills} = processedData;

	for (const placeholder of placeholders) {
		const placeholderSkillNodes = placeholderSkills
			.filter(placeholderSkill => placeholderSkill.placeholderId === placeholder.id)
			.map(placeholderSkill => {
				return {node: {id: placeholderSkill.skillId}};
			});

		const formattedPlaceholder = {
			id: placeholder.id,
			project: {id: placeholder.projectId},
			projectGroupId: placeholder.projectGroupId,
			name: placeholder.name,
			role: {id: placeholder.roleId},
			skills: {edges: placeholderSkillNodes},
		};
		createOrUpdatePlaceholder(pageComponent, formattedPlaceholder);
	}
};

const processDeletedPlaceholders = (pageComponent, placeholderIdsToDelete) => {
	if (placeholderIdsToDelete) {
		placeholderIdsToDelete.forEach(placeholderId => {
			deletePlaceholder(pageComponent, placeholderId);
		});
	}
};

const processPlaceholderAllocations = (pageComponent, processedData) => {
	const {placeholderAllocations} = processedData;
	for (const placeholderAllocation of placeholderAllocations) {
		const startDate = new Date(placeholderAllocation.startDate);
		const endDate = new Date(placeholderAllocation.endDate);

		const formattedAllocation = {
			id: placeholderAllocation.id,
			description: placeholderAllocation.description,
			monday: placeholderAllocation.monday,
			tuesday: placeholderAllocation.tuesday,
			wednesday: placeholderAllocation.wednesday,
			thursday: placeholderAllocation.thursday,
			friday: placeholderAllocation.friday,
			saturday: placeholderAllocation.saturday,
			sunday: placeholderAllocation.sunday,
			startDate: placeholderAllocation.startDate,
			endDate: placeholderAllocation.endDate,
			startYear: startDate.getUTCFullYear(),
			startMonth: startDate.getUTCMonth() + 1,
			startDay: startDate.getUTCDate(),
			endYear: endDate.getUTCFullYear(),
			endMonth: endDate.getUTCMonth() + 1,
			endDay: endDate.getUTCDate(),
			placeholderId: placeholderAllocation.placeholderId,
		};
		createOrUpdatePlaceholderAllocation(pageComponent, formattedAllocation);
	}
};

const processDeletedPlaceholderAllocations = (pageComponent, placeholderAllocationIdsToDelete) => {
	deleteMultiplePlaceholderAllocations(pageComponent, placeholderAllocationIdsToDelete);
};

// eslint-disable-next-line
const fetchAndProcessData = (
	pageComponent,
	projectIdsToUpdate,
	projectIdsToDelete,
	placeholderAllocationIdsToUpdate,
	placeholderAllocationIdsToDelete,
	placeholderIdsToUpdate,
	placeholderIdsToDelete
) => {
	fetchNotificationData(projectIdsToUpdate, placeholderAllocationIdsToUpdate, placeholderIdsToUpdate).then(updatedData => {
		const data = pageComponent.getData();
		if (data) {
			const processedData = processData(data, updatedData);
			processPlaceholderAllocations(pageComponent, processedData);
			processDeletedPlaceholderAllocations(pageComponent, placeholderAllocationIdsToDelete);

			processPlaceholders(pageComponent, processedData);
			processDeletedPlaceholders(pageComponent, placeholderIdsToDelete);
		}

		pageComponent.redrawCanvasTimeline({preventFiltering: false});
	});
};

const getChangedEntities = state => {
	const {placeholderAllocationCreatesAndUpdates, placeholderAllocationDeletes} = state;

	const updatedAndCreatedPlaceholderAllocationIds = placeholderAllocationCreatesAndUpdates
		? placeholderAllocationCreatesAndUpdates.map(p => p.id)
		: [];
	const deletedPlaceholderAllocationIds = placeholderAllocationDeletes ? placeholderAllocationDeletes.map(p => p.id) : [];

	return {
		changedPlaceholderAllocationIds: updatedAndCreatedPlaceholderAllocationIds.concat(deletedPlaceholderAllocationIds),
	};
};

export const handleNotification = (pageComponent, eventList) => {
	if (hasFeatureFlag('use_push_on_placeholder_scheduling')) {
		const projectsToUpdate = {};
		const placeholderAllocationsToUpdate = {};
		const projectsToDelete = {};
		const placeholderAllocationsToDelete = {};
		const placeholdersToUpdate = {};
		const placeholdersToDelete = {};

		const {changedPlaceholderAllocationIds} = getChangedEntities(pageComponent.state);

		eventList.forEach(event => {
			if (eventTypesToHandle.includes(event.eventType)) {
				if (event.eventAction !== 'DELETE') {
					if (event.projectIds) {
						event.projectIds.forEach(projectId => (projectsToUpdate[projectId] = true));
					} else if (event.placeholderAllocationIds) {
						event.placeholderAllocationIds.forEach(placeholderAllocationId => {
							if (
								!changedPlaceholderAllocationIds.includes(
									btoa('PlaceholderAllocation:' + placeholderAllocationId)
								)
							) {
								placeholderAllocationsToUpdate[placeholderAllocationId] = true;
							}
						});
					} else if (event.placeholderIds) {
						event.placeholderIds.forEach(placeholderId => (placeholdersToUpdate[placeholderId] = true));
					}
				} else {
					if (event.projectIds) {
						event.projectIds.forEach(projectId => (projectsToDelete[btoa('Project:' + projectId)] = true));
					} else if (event.placeholderAllocationIds) {
						event.placeholderAllocationIds.forEach(placeholderAllocationId => {
							const id = btoa('PlaceholderAllocation:' + placeholderAllocationId);
							if (!changedPlaceholderAllocationIds.includes(id)) {
								placeholderAllocationsToDelete[id] = true;
							}
						});
					} else if (event.placeholderIds) {
						event.placeholderIds.forEach(
							placeholderId => (placeholdersToDelete[btoa('Placeholder:' + placeholderId)] = true)
						);
					}
				}
			}
		});

		const projectIdsToUpdate = Object.keys(projectsToUpdate);
		const projectIdsToDelete = Object.keys(projectsToDelete);

		const placeholderIdsToUpdate = Object.keys(placeholdersToUpdate);
		const placeholderIdsToDelete = Object.keys(placeholdersToDelete);

		const placeholderAllocationIdsToUpdate = Object.keys(placeholderAllocationsToUpdate);
		const placeholderAllocationIdsToDelete = Object.keys(placeholderAllocationsToDelete);

		if (
			projectIdsToUpdate.length === 0 &&
			placeholderAllocationIdsToUpdate.length === 0 &&
			projectIdsToDelete.length === 0 &&
			placeholderAllocationIdsToDelete.length === 0 &&
			placeholderIdsToUpdate.length === 0
		) {
			return;
		}

		// Wait a tiny bit before fetching to ensure everything is updated on the backend
		setTimeout(
			() =>
				fetchAndProcessData(
					pageComponent,
					projectIdsToUpdate,
					projectIdsToDelete,
					placeholderAllocationIdsToUpdate,
					placeholderAllocationIdsToDelete,
					placeholderIdsToUpdate,
					placeholderIdsToDelete
				),
			500
		);
	}
};
