import {
	GROUP_TYPE,
	getTextColor,
	GROUP_SECTION_WIDTH,
	drawBorderLines,
	drawArrows,
	ITEM_TYPE,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import Util from '../../../../forecast-app/shared/util/util';

class UnassignedRoleTaskGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PEOPLE_SCHEDULING_UNASSIGNED_ROLE_TASK, data);
	}

	onMouseEnter(eventData) {
		const {items} = this.pageComponent.state;

		const showArrowClickableArea = items.some(
			item => item.itemType === ITEM_TYPE.TASK && !item.data.task.done && item.groupId === this.id
		);

		this.pageComponent.setState({
			showArrowClickableArea,
			arrowClickableAreaY: eventData.y + 7,
			hoveredTaskGroupId: this.id,
		});
	}

	onMouseLeave() {
		this.pageComponent.setState({showArrowClickableArea: false, arrowClickableAreaY: null, hoveredTaskGroupId: null});
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height, groupType} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);
		const {id} = data;
		const cacheId = `${groupType}-${id}`;

		const textColor = getTextColor();

		drawArrows(
			canvasContext,
			textColor,
			x + 241,
			y + 17,
			cacheManager.getCommonImage(COMMON_IMAGE[textColor === '#ffffff' ? 'GO_TO_PREVIOUS_WHITE' : 'GO_TO_PREVIOUS']),
			cacheManager.getCommonImage(COMMON_IMAGE[textColor === '#ffffff' ? 'GO_TO_NEXT_WHITE' : 'GO_TO_NEXT'])
		);

		drawBorderLines(canvasContext, x, y, width, height, true);

		const cachedData = cacheManager.get(groupType, cacheId);
		if (cachedData) {
			canvasContext.drawImage(cachedData, x, y, width, this.itemRowHeight);
			return;
		}

		canvasContext.fillStyle = textColor;
		canvasContext.font = 'normal 12px ' + Util.getFontFamily();
		canvasContext.fillText('Tasks', x + 58, y + 27);
	}
}

export default UnassignedRoleTaskGroup;
