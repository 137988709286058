import HeatmapItem, {HEATMAP_TYPE} from '../components/items/Heatmap/HeatmapItem';
import {getCachedHeatmapData, getGroupCache, getProjectHeatmapData, getStepCache, setStepCachedItem} from './HeatmapLogic';
import DataManager from '../DataManager';
import RecalculationManager from '../RecalculationManager';
import {getPersonHeatmapMetaData, getStepDataArrayStartAndEndDate} from '../canvas-timeline/canvas_timeline_util';
import {addMinutesAllocatedVariations} from './MinutesAllocatedVariationsUtils';

const constructProjectGroupHeatmapItem = (pageComponent, group, stepData, heatmapData) => {
	return new HeatmapItem(pageComponent, HEATMAP_TYPE.PROJECT_ENTITY, {
		y: group.screenY,
		x: stepData.position,
		width: stepData.width,
		groupHeight: group.totalHeight,
		...heatmapData,
	});
};

const insertEmptyHeatmapDataIntoCache = (groupId, stepCache, timelineMinorStep, startDate, endDate) => {
	const minutesAllocatedVariations = {};

	addMinutesAllocatedVariations(minutesAllocatedVariations, {
		plannedTotalMinutesHard: 0,
		plannedTotalMinutesSoft: 0,
		plannedTotalMinutesSoftWin: 0,
		taskMinutesAllocated: 0,
	});

	// cache item
	const cachedItem = {
		minutesAllocatedVariations,
		distributionMap: new Map(),
		taskMinutesAllocated: 0,
		timeRegMinutes: 0,
	};

	setStepCachedItem(groupId, stepCache, timelineMinorStep, cachedItem, startDate, endDate);
};

export const composeProjectGroupHeatmap = (pageComponent, group, stepDataArray, timelineStartDate, timelineMinorStep) => {
	if (RecalculationManager.groupNeedsRecalculation(pageComponent, group.id)) {
		group.calculateHeatmapCache(group, stepDataArray, timelineMinorStep);
	}

	const heatmapItems = [];
	for (const stepData of stepDataArray) {
		let {startDate, endDate} = stepData;
		const heatmapData = getCachedHeatmapData(pageComponent, group, timelineMinorStep, startDate, endDate);
		heatmapItems.push(constructProjectGroupHeatmapItem(pageComponent, group, stepData, heatmapData));
	}

	return heatmapItems;
};

export const calculateProjectGroupHeatmapCache = (
	pageComponent,
	group,
	stepDataArray,
	timelineMinorStep,
	setHasCalculated,
	getCalculationMetaData
) => {
	if (RecalculationManager.groupNeedsRecalculation(pageComponent, group.id)) {
		const {overallStartDate, overallEndDate} = getStepDataArrayStartAndEndDate(stepDataArray);

		let visibleItemsData;
		const fetchVisibleItemsData = () => {
			if (!visibleItemsData) {
				visibleItemsData = DataManager.getVisibleItemsData(pageComponent, group, overallStartDate, overallEndDate);
			}

			return visibleItemsData;
		};

		let calculationMetaDataCache = null;
		const getCalculationMetaDataOverride = (startDate, endDate) => {
			if (!calculationMetaDataCache) {
				calculationMetaDataCache = new Map();
			}

			let calculationMetaData = calculationMetaDataCache.get(startDate);
			if (!calculationMetaData) {
				calculationMetaData = getPersonHeatmapMetaData(
					pageComponent,
					group.parentGroup,
					timelineMinorStep,
					startDate,
					endDate
				);
				calculationMetaDataCache.set(startDate, calculationMetaData);
			}

			return calculationMetaData;
		};

		// Update cache for step
		let groupCache;
		let stepCache;
		for (const stepData of stepDataArray) {
			let {startDate, endDate, recalculationNeededInStep} = stepData;

			if (recalculationNeededInStep === false) {
				continue;
			}

			if (!stepCache) {
				groupCache = getGroupCache(pageComponent, group.id);
				stepCache = getStepCache(groupCache, timelineMinorStep);
			}

			if (!DataManager.hasAnyHeatmapRelevantDataInStep(pageComponent, group.id, startDate, endDate)) {
				insertEmptyHeatmapDataIntoCache(group.id, stepCache, timelineMinorStep, startDate, endDate);
				continue;
			}

			getProjectHeatmapData(
				pageComponent,
				group,
				groupCache,
				stepCache,
				startDate,
				endDate,
				timelineMinorStep,
				fetchVisibleItemsData,
				getCalculationMetaData || getCalculationMetaDataOverride
			);
		}

		if (stepCache && setHasCalculated) {
			setHasCalculated();
		}
	}
};
