/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectHeader_project$ref = any;
type ProjectHeader_projectGroup$ref = any;
type ProjectHeader_psProject$ref = any;
type SecondaryNavigation_project$ref = any;
type SecondaryNavigation_projectGroup$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type canvasScheduling_viewer$ref: FragmentReference;
declare export opaque type canvasScheduling_viewer$fragmentType: canvasScheduling_viewer$ref;
export type canvasScheduling_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +company: ?{|
    +id: string,
    +weekendDisplayPerUser: ?boolean,
    +weekendDisplayShowAlways: ?boolean,
    +isUsingProjectAllocation: ?boolean,
    +isUsingSchedulingPlanMode: ?boolean,
    +isUsingMixedAllocation: ?boolean,
  |},
  +project: ?{|
    +id: string,
    +$fragmentRefs: ProjectHeader_project$ref & SecondaryNavigation_project$ref,
  |},
  +projectGroup: ?{|
    +$fragmentRefs: ProjectHeader_projectGroup$ref & SecondaryNavigation_projectGroup$ref
  |},
  +psProject: ?{|
    +$fragmentRefs: ProjectHeader_psProject$ref
  |},
  +$refType: canvasScheduling_viewer$ref,
|};
export type canvasScheduling_viewer$data = canvasScheduling_viewer;
export type canvasScheduling_viewer$key = {
  +$data?: canvasScheduling_viewer$data,
  +$fragmentRefs: canvasScheduling_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "groupId",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "canvasScheduling_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weekendDisplayPerUser",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weekendDisplayShowAlways",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingProjectAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingSchedulingPlanMode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingMixedAllocation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_project"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_project"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "groupId"
        }
      ],
      "concreteType": "ProjectGroupType",
      "kind": "LinkedField",
      "name": "projectGroup",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_projectGroup"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SecondaryNavigation_projectGroup"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "companyProjectId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectServiceProjectType",
      "kind": "LinkedField",
      "name": "psProject",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectHeader_psProject"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ed106191d94769c56c32ae9da0846c7c';

module.exports = node;
