import Group from '../../canvas-timeline/canvas_timeline_group';
import {GROUP_TYPE, GROUP_SECTION_WIDTH, GROUP_SECTION_TEXT_GREY} from '../../canvas-timeline/canvas_timeline_util';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import Util from '../../../../forecast-app/shared/util/util';

class StaffingGroupingGroup extends Group {
	constructor(pageComponent, data, sortFunc) {
		super(pageComponent, GROUP_TYPE.PLACEHOLDERS_SCHEDULING_STAFFING_GROUPING_GROUP, data);
		this.renderRowLines = false;
		this.hideIfAllChildrenAreFiltered = false;
		this.includeInRowHover = false;
		this.expanded = true;
		this.sortFunc = sortFunc;
	}

	sort(sortBy) {
		this.groups.sort(this.sortFunc(sortBy));
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {height, data} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);
		const {isAllocatedPeople, groups} = data;
		const {intl} = this.pageComponent.props;

		const filteredItemsCount = groups.filter(group => !group.filtered).length;

		// positioning
		const iconOffsetX = 25;
		const iconTextGap = 6;

		// icon
		const iconSize = 14;
		canvasContext.drawImage(
			cacheManager.getCommonImage(isAllocatedPeople ? COMMON_IMAGE.PEOPLE_GREY_ICON : COMMON_IMAGE.FILTER_GREY_ICON),
			x + iconOffsetX,
			y + height / 2 - iconSize / 2,
			iconSize,
			iconSize
		);

		// text
		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY;
		canvasContext.font = '500 9px ' + Util.getFontFamily();
		const text = isAllocatedPeople
			? intl.formatMessage({id: 'scheduling.placeholders.staffing.allocated_people'})
			: intl.formatMessage({id: 'scheduling.placeholders.staffing.filtered_matches'});
		canvasContext.fillText(text + ` (${filteredItemsCount})`, x + iconOffsetX + iconSize + iconTextGap, y + height / 2 + 3);
	}
}

export default StaffingGroupingGroup;
