import React from 'react';
import Styled from 'styled-components';
import {Switch} from 'web-components';
import {HelpIcon} from 'web-components/icons';
import ForecastTooltip from '../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {useIntl} from 'react-intl';
import {hasModule} from '../../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../../constants';

const ToggleWinProbability = ({disabled, calcWin, onToggleCalcWin}) => {
	const hasCalcWin = onToggleCalcWin && hasModule(MODULE_TYPES.CALC_WIN_PERCENTAGE);

	if (!hasCalcWin) {
		return null;
	}

	const intl = useIntl();
	const onChange = () => {
		onToggleCalcWin(!calcWin);
	};

	return (
		<Container>
			<FlexContainer>
				<Switch onChange={onChange} checked={calcWin && !disabled} locked={disabled} />
				<Title>{intl.formatMessage({id: 'placeholder.win_probability.title'})}</Title>
				<ForecastTooltip
					placement={'bottom'}
					content={intl.formatMessage(
						{id: 'placeholder.win_probability.tooltip'},
						{
							p: msg => <p>{msg}</p>,
						}
					)}
				>
					<HelpIcon />
				</ForecastTooltip>
			</FlexContainer>
		</Container>
	);
};

const Container = Styled.div`
	cursor: pointer;
	box-sizing: border-box;
	position: relative;
	font-size: 13px;
	height: 30px;
	background-color: ${props => (props.active ? '#F0E7FE' : '#fff')};
	font-family: ${({theme}) => theme.fontFamily};
	color: ${props => (props.active ? '#6e0fea' : '#535353')};
`;

const Title = Styled.div`
	font-size: 12px;
	flex: 1;
	padding: 6px;
`;

const FlexContainer = Styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;
export default ToggleWinProbability;
