import React from 'react';
import {useIntl} from 'react-intl';

export default ({staffingModeActive}) => {
	const {formatMessage} = useIntl();

	return (
		<div className="placeholders-scheduling-top-left">
			{staffingModeActive && (
				<div className="staffing-mode-top-left-container">
					<span>{formatMessage({id: 'scheduling.placeholders.filtering_resources_for'})}:</span>
				</div>
			)}
		</div>
	);
};
