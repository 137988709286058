import {
	CANVAS_BUTTON_DEFAULT_BORDER_COLOR,
	CANVAS_BUTTON_DEFAULT_BORDER_RADIUS,
	CANVAS_BUTTON_DEFAULT_PADDING_LEFT_RIGHT,
	CANVAS_BUTTON_DEFAULT_PADDING_TOP_BOTTOM,
	drawRectangle,
	HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_GAP,
	HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_WIDTH,
	TIMELINE_HEATMAP_BACKGROUND_COLOR,
	CANVAS_BUTTON_DEFAULT_BORDER_THICKNESS,
	getFontHeight,
	CANVAS_BUTTON_DEFAULT_TEXT_COLOR,
	CANVAS_BUTTON_DEFAULT_BORDER_COLOR_HOVER,
	CANVAS_BUTTON_DEFAULT_TEXT_COLOR_HOVER,
	CANVAS_BUTTON_DEFAULT_DISABLED_BORDER_COLOR,
	GROUP_SECTION_PROJECT_BADGE_BORDER_RADIUS,
	GROUP_SECTION_PROJECT_BADGE_WIDTH,
	GROUP_SECTION_CONNECTED_PROJECT_BADGE_GAP,
	GROUP_SECTION_PROJECT_BADGE_HEIGHT,
	drawLine,
	GROUP_SECTION_PROGRAM_BADGE_GAP,
	TIMELINE_EXPANDED_GROUND_HIGHLIGHT_COLOR,
	SCHEDULE_PEOPLE_HEATMAP_TIME_OFF_BACKGROUND_COLOR,
} from './canvas-timeline/canvas_timeline_util';
import Util from '../../forecast-app/shared/util/util';
import {cacheManager} from './canvas-timeline/canvas_timeline_cache_manager';

export const drawDoneIcon = (canvasContext, x, y, iconSize, color, lineWidth = 1) => {
	const radius = iconSize / 2;

	// init
	canvasContext.beginPath();

	// style
	canvasContext.lineWidth = lineWidth;
	canvasContext.strokeStyle = color;

	// circle
	const centerX = x + radius;
	const centerY = y + radius;
	canvasContext.arc(centerX, centerY, radius, 0, 2 * Math.PI);
	canvasContext.stroke();

	// reset path
	canvasContext.closePath();
	canvasContext.beginPath();

	// checkmark positions
	const checkmarkX1 = centerX - radius / 2;
	const checkmarkY1 = centerY;
	const checkmarkX2 = checkmarkX1 + (centerX - checkmarkX1) * 0.8;
	const checkmarkY2 = checkmarkY1 + (centerX - checkmarkX1) * 0.8;
	const checkmarkX3 = centerX + (radius / 2) * 1.1;
	const checkmarkY3 = centerY - (radius / 2) * 0.8;

	// draw checkmark
	canvasContext.moveTo(checkmarkX1, checkmarkY1);
	canvasContext.lineTo(checkmarkX2, checkmarkY2);
	canvasContext.lineTo(checkmarkX3, checkmarkY3);
	canvasContext.stroke();
};

export const drawWarningIcon = (canvasContext, x, y, iconSize, color, lineWidth = 1) => {
	const radius = iconSize / 2;
	const centerX = x + radius;
	const centerY = y + radius;

	// init
	canvasContext.beginPath();

	// style
	canvasContext.lineWidth = lineWidth;
	canvasContext.strokeStyle = color;

	// warning square
	let squareX = centerX;
	let squareY = centerY + radius;
	canvasContext.moveTo(squareX, squareY);
	squareX += radius;
	squareY -= radius;
	canvasContext.lineTo(squareX, squareY);
	squareX -= radius;
	squareY -= radius;
	canvasContext.lineTo(squareX, squareY);
	squareX -= radius;
	squareY += radius;
	canvasContext.lineTo(squareX, squareY);
	squareX += radius;
	squareY += radius;
	canvasContext.lineTo(squareX, squareY);
	canvasContext.stroke();

	// reset path
	canvasContext.closePath();
	canvasContext.beginPath();

	// exclamation mark dot
	const dotX = centerX;
	const dotY = centerY + radius * 0.5;
	canvasContext.arc(dotX, dotY, lineWidth, 0, 2 * Math.PI);
	canvasContext.fillStyle = color;
	canvasContext.fill();

	// reset path
	canvasContext.closePath();
	canvasContext.beginPath();

	// exclamation mark line
	const lineX = centerX;
	const lineYStart = centerY - radius * 0.5;
	const lineYEnd = centerY + radius * 0.2;
	canvasContext.moveTo(lineX, lineYStart);
	canvasContext.lineTo(lineX, lineYEnd);
	canvasContext.stroke();
};

const getSoftAllocatedStripeAngleHeight = height => {
	return (height / 100) * 65;
};

const drawSoftAllocatedStripe = (canvasContext, x, y, height, color) => {
	// draw stripe
	let stripeX = x;
	let stripeY = y + height;

	// init
	canvasContext.moveTo(stripeX, stripeY);

	// top left
	stripeX += getSoftAllocatedStripeAngleHeight(height);
	stripeY -= height;
	canvasContext.moveTo(stripeX, stripeY);

	// top right
	stripeX += HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_WIDTH;
	canvasContext.lineTo(stripeX, stripeY);

	// bottom right
	stripeX -= getSoftAllocatedStripeAngleHeight(height);
	stripeY += height;
	canvasContext.lineTo(stripeX, stripeY);

	// bottom left
	stripeX -= HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_WIDTH;
	canvasContext.lineTo(stripeX, stripeY);
};

export const drawSoftAllocatedArea = (
	canvasContext,
	x,
	y,
	height,
	width,
	strokeColor,
	backgroundColor = TIMELINE_HEATMAP_BACKGROUND_COLOR
) => {
	if (backgroundColor) {
		drawRectangle(canvasContext, x, y, width, height, {
			backgroundColor,
		});
	}

	canvasContext.save();
	canvasContext.beginPath();

	let region = new Path2D();
	region.rect(x, y, width, height);
	canvasContext.clip(region);

	// create crossing lines pattern
	let stripeX = x - getSoftAllocatedStripeAngleHeight(height);
	while (stripeX < x + width) {
		drawSoftAllocatedStripe(canvasContext, stripeX, y, height, strokeColor);
		stripeX += HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_WIDTH + HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_GAP;
	}

	// fill
	canvasContext.fillStyle = strokeColor;
	canvasContext.fill();

	canvasContext.restore();
};

export const drawExpandedGroupHighlight = (canvasContext, x, y, height, width) => {
	drawRectangle(canvasContext, x, y, width, height, {
		backgroundColor: TIMELINE_EXPANDED_GROUND_HIGHLIGHT_COLOR,
	});
};

export const drawProgramBadge = (canvasContext, x, y, options = {}) => {
	const borderRadius = GROUP_SECTION_PROJECT_BADGE_BORDER_RADIUS / 2;
	const badgeOptions = {
		borderRadius,
		...options,
	};

	const badgeWidth = GROUP_SECTION_PROJECT_BADGE_WIDTH / 2 - GROUP_SECTION_PROGRAM_BADGE_GAP;
	const badgeHeight = GROUP_SECTION_PROJECT_BADGE_HEIGHT / 2 - GROUP_SECTION_PROGRAM_BADGE_GAP;

	// top left
	drawRectangle(canvasContext, x, y, badgeWidth, badgeHeight, badgeOptions);

	// top right
	drawRectangle(canvasContext, x + badgeWidth + GROUP_SECTION_PROGRAM_BADGE_GAP, y, badgeWidth, badgeHeight, badgeOptions);

	// bottom right
	drawRectangle(
		canvasContext,
		x + badgeWidth + GROUP_SECTION_PROGRAM_BADGE_GAP,
		y + badgeHeight + GROUP_SECTION_PROGRAM_BADGE_GAP,
		badgeWidth,
		badgeHeight,
		badgeOptions
	);

	// bottom left
	drawRectangle(canvasContext, x, y + badgeHeight + GROUP_SECTION_PROGRAM_BADGE_GAP, badgeWidth, badgeHeight, badgeOptions);
};

export const drawConnectedProjectBadge = (canvasContext, x, y, options = {}) => {
	const borderRadius = GROUP_SECTION_PROJECT_BADGE_BORDER_RADIUS / 2;
	const badgeOptions = {
		borderRadius,
		...options,
	};

	const badgeWidth = GROUP_SECTION_PROJECT_BADGE_WIDTH / 2 - GROUP_SECTION_CONNECTED_PROJECT_BADGE_GAP / 2;
	const badgeHeight = GROUP_SECTION_PROJECT_BADGE_HEIGHT / 2 - GROUP_SECTION_CONNECTED_PROJECT_BADGE_GAP / 2;

	// top left
	drawRectangle(canvasContext, x, y, badgeWidth, badgeHeight, badgeOptions);

	// bottom right
	drawRectangle(
		canvasContext,
		x + badgeWidth + GROUP_SECTION_CONNECTED_PROJECT_BADGE_GAP,
		y + badgeHeight + GROUP_SECTION_CONNECTED_PROJECT_BADGE_GAP,
		badgeWidth,
		badgeHeight,
		badgeOptions
	);

	// draw connecting line
	const x1 = x + badgeWidth - borderRadius;
	const y1 = y + badgeHeight - borderRadius;
	const x2 = x1 + GROUP_SECTION_CONNECTED_PROJECT_BADGE_GAP + borderRadius * 2;
	const y2 = y1 + GROUP_SECTION_CONNECTED_PROJECT_BADGE_GAP + borderRadius * 2;
	drawLine(canvasContext, x1, y1, x2, y2, {
		color: options.backgroundColor,
		lineWidth: 2,
	});
};

export const drawProjectBadge = (canvasContext, x, y, options = {}) => {
	const badgeOptions = {
		borderRadius: GROUP_SECTION_PROJECT_BADGE_BORDER_RADIUS,
		...options,
	};

	drawRectangle(canvasContext, x, y, GROUP_SECTION_PROJECT_BADGE_WIDTH, GROUP_SECTION_PROJECT_BADGE_HEIGHT, badgeOptions);
};

export const drawButton = (canvasContext, x, y, text, isHovering, isDisabled, alignRight = true, options = {}) => {
	// extract options
	const paddingTopBottom = options.paddingTopBottom || CANVAS_BUTTON_DEFAULT_PADDING_TOP_BOTTOM;
	const paddingLeftRight = options.paddingLeftRight || CANVAS_BUTTON_DEFAULT_PADDING_LEFT_RIGHT;
	const borderRadius = options.borderRadius || CANVAS_BUTTON_DEFAULT_BORDER_RADIUS;
	const borderThickness = options.borderThickness || CANVAS_BUTTON_DEFAULT_BORDER_THICKNESS;
	const borderColor =
		options.borderColor || isDisabled
			? CANVAS_BUTTON_DEFAULT_DISABLED_BORDER_COLOR
			: isHovering
			? CANVAS_BUTTON_DEFAULT_BORDER_COLOR_HOVER
			: CANVAS_BUTTON_DEFAULT_BORDER_COLOR;
	const backgroundColor = options.backgroundColor || null;
	const textColor =
		options.textColor || isDisabled
			? CANVAS_BUTTON_DEFAULT_DISABLED_BORDER_COLOR
			: isHovering
			? CANVAS_BUTTON_DEFAULT_TEXT_COLOR_HOVER
			: CANVAS_BUTTON_DEFAULT_TEXT_COLOR;

	const fontSize = 12;
	const font = `400 ${fontSize}px ` + Util.getFontFamily();

	// font
	canvasContext.fillStyle = textColor;
	canvasContext.font = font;
	const fontHeight = getFontHeight(canvasContext);

	// measure button size
	const textSize = canvasContext.measureText(text);
	const height = fontHeight + paddingTopBottom * 2;
	const width = textSize.width + paddingLeftRight * 2;

	// determine start x and y positioning
	const buttonX = alignRight ? x - width + borderThickness * 2 : x - borderThickness;
	const buttonY = y - height / 2 - borderThickness;

	// setting options for drawing rectangle
	const rectangleOptions = {
		borderRadius,
		borderThickness,
		borderColor,
	};

	if (backgroundColor) {
		rectangleOptions.backgroundColor = backgroundColor;
	}

	// draw border / background color
	drawRectangle(canvasContext, buttonX, buttonY, width, height, rectangleOptions);

	// draw text
	const textX = buttonX + borderThickness + paddingLeftRight;
	const textY = y + getFontHeight(canvasContext) / 3 - borderThickness;
	canvasContext.fillStyle = textColor;
	canvasContext.font = font;
	canvasContext.fillText(text, textX, textY);

	return {
		buttonX,
		buttonY,
		buttonWidth: width,
		buttonHeight: height,
	};
};

export const drawTimeOffSection = (canvasContext, section) => {
	const firstItem = section[0];
	const lastItem = section[section.length - 1];
	const startX = firstItem.data.x;
	const endX = lastItem.data.x + lastItem.data.width;
	const width = endX - startX;

	const {height, data} = firstItem;
	const {y, groupHeight} = data;

	// draw background
	drawRectangle(canvasContext, startX + 1, y + height, width - 2, groupHeight - height, {
		backgroundColor: SCHEDULE_PEOPLE_HEATMAP_TIME_OFF_BACKGROUND_COLOR,
	});
};

export const drawCanvasImage = (canvasContext, mapId, cacheId, x, y, width, height) => {
	const cachedData = mapId ? cacheManager.get(mapId, cacheId) : cacheManager.getCommonImage(cacheId);

	if (cachedData) {
		canvasContext.drawImage(cachedData, x, y, width, height);
	}
};
