import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {
	getCanvasTimelineDateFromMoment,
	getSchedulingOptionVisualizationMode,
	getInitialEyeOptions,
	UTILIZATION_FORMAT,
} from '../canvas-timeline/canvas_timeline_util';
import {trackPerformance, trackPerformanceInit} from '../canvas-timeline/canvas_timeline_performance_track';
import Moment from 'moment';
import {
	calculateStartDateFromPlaceholder,
	getStaffingFilters,
	initializeData,
	initializeStaffingData,
} from './CanvasPlaceholdersSchedulingUtil';
import {
	PLACEHOLDERS_GROUP_BY,
	PLACEHOLDERS_SCHEDULING_GROUP_BY_STORAGE_KEY,
	PLACEHOLDERS_SCHEDULING_SORT_BY_STORAGE_KEY,
	PLACEHOLDERS_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY,
	PLACEHOLDERS_STAFFING_SORT_BY,
} from './CanvasPlaceholderSchedulingConstants';
import CanvasTimeline from '../canvas-timeline/canvas_timeline';
import CanvasPlaceholdersTopLeftContainer from './CanvasPlaceholdersTopLeftContainer';
import {fetchInitialData, fetchStaffingData, handleSocketNotify, saveStaffingData} from './CanvasPlaceholdersFetchUtil';
import Util from '../../../forecast-app/shared/util/util';
import {createHeaderBar, getTopHeaderContent} from './PlaceholdersSchedulingHeader';
import {dispatch, EVENT_ID, subscribe, unsubscribe} from '../../../containers/event_manager';
import {onSavedFiltersUpdate, onSchedulingMutationSuccess} from './PlaceholdersSchedulingMutationSuccess';
import PlaceholderSchedulingHoverEffects from './PlaceholderSchedulingHoverEffects';
import {filterGroupsAndItems} from './PlaceholderSchedulingFilterLogic';
import * as tracking from '../../../tracking';
import {
	drawIncrementalLoadMoreOverlay,
	getOverlayCanvasDate,
	preventScrollingPastLoadMoreOverlay,
} from '../loading/LoadMoreUtil';
import {LoadingOverlay} from '../loading/LoadingOverlay';
import {onSavedFiltersStaffingUpdate, onSchedulingStaffingSuccess} from './PlaceholdersSchedulingStaffingSuccess';
import PlaceholdersSchedulingStaffingFooter from './PlaceholdersSchedulingStaffingFooter';
import {handleUnsavedChanges} from '../actions/handle_unsaved_changes';
import PlaceholdersSchedulingStaffingBackground from './PlaceholdersSchedulingStaffingBackground';
import {trackPlaceholderStaffed} from '../../../tracking/tracking_types/TrackPlaceholderStaffed';
import EmptyState, {EMPTY_STATE} from '../../../forecast-app/shared/components/empty-states/empty_state';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import {
	ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN,
	ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD,
	ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT,
	KEEP_PLACEHOLDER_STORAGE_KEY,
} from '../constants';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../constants';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {handleDeletePlaceholder} from '../actions/handle_delete_placeholder';
import IncrementalLoadingOverlay, {LOAD_MORE, LOAD_MORE_DIRECTION} from '../loading/IncrementalLoadingOverlay';
import {DATA_ENTITIES} from '../DataManager';
import PlaceholderAllocationItem from '../components/items/placeholder_allocation_item';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import EventManager from '../EventManager';
import IDManager from '../IDManager';
import ComposeManager from '../ComposeManager';
import RecalculationManager from '../RecalculationManager';

class CanvasPlaceholdersScheduling extends Component {
	// Used for identifying minified components in cypress tests
	static displayName = 'CanvasPlaceholdersScheduling';

	constructor(props) {
		super(props);
		const pageName = 'Schedule Placeholders';
		trackPerformanceInit(pageName);

		const todayDate = getCanvasTimelineDateFromMoment(Moment());

		// group by local storage
		const groupByLocal = localStorage.getItem(PLACEHOLDERS_SCHEDULING_GROUP_BY_STORAGE_KEY);
		const isLocalGroupByValid = groupByLocal ? Object.values(PLACEHOLDERS_GROUP_BY).includes(groupByLocal) : false;

		// sort-by local storage
		const sortByLocal = localStorage.getItem(PLACEHOLDERS_SCHEDULING_SORT_BY_STORAGE_KEY);
		const isLocalSortByValid = sortByLocal ? Object.values(PLACEHOLDERS_STAFFING_SORT_BY).includes(sortByLocal) : false;

		this.heatmapCache = new Map();
		RecalculationManager.clearAll();

		this.onSchedulingMutationSuccess = (response, args) => onSchedulingMutationSuccess(this, response, args);
		this.reloadData = () => fetchInitialData(this);
		this.onSavedFiltersUpdate = response => onSavedFiltersUpdate(this, response);
		this.onSchedulingStaffingSuccess = (response, args) => onSchedulingStaffingSuccess(this, response, args);
		this.onSavedFiltersStaffingUpdate = response => onSavedFiltersStaffingUpdate(this, response);

		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingMutationSuccess);
		subscribe(EVENT_ID.SCHEDULING_RELOAD_DATA, this.reloadData);
		subscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersUpdate);

		this.onClick = event => EventManager.onClick(this, event);
		this.onMouseMove = event => EventManager.onMouseMove(this, event);

		const company = props.viewer.company;
		const heatmapFiltering =
			hasFeatureFlag('people_scheduling_enable_filter_heatmap_issue') ||
			(hasFeatureFlag('combined_heatmap_logic_extensions') && Util.isMixedAllocationModeEnabled(company));
		this.state = {
			heatmapFiltering,
			enteringStaffingMode: !!props.staffingPlaceholderId,
			staffingModeActive: false,
			staffingPlaceholder: null,
			staffingInitialDate: null,
			placeholderAllocationCreatesAndUpdates: [],
			placeholderAllocationDeletes: [],
			placeholderAllocationGhosts: [],
			projectAllocationCreatesAndUpdates: [],
			projectAllocationDeletes: [],
			items: [],
			groups: [],
			totalMinutesMap: new Map(),
			initialDataLoaded: false,
			isCollapsableSectionExpanded: false,
			todayDate,
			searchFilterValue: '',
			hasInitialWorkerRun: false,
			splitPlaceholderAllocationBarData: {},
			splitAllocationBarData: {},
			groupBy: isLocalGroupByValid ? groupByLocal : PLACEHOLDERS_GROUP_BY.ROLE,
			sortBy: isLocalSortByValid ? sortByLocal : PLACEHOLDERS_STAFFING_SORT_BY.SUITABILITY,
			eyeOptions: getInitialEyeOptions(this),
			weekendOptions: props.weekendOptions,
			[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.LEFT]: false,
			[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.RIGHT]: false,
			[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]: getOverlayCanvasDate(this, null, LOAD_MORE_DIRECTION.LEFT),
			[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]: getOverlayCanvasDate(this, null, LOAD_MORE_DIRECTION.RIGHT),
		};
		this.state.schedulingOptions = this.getSchedulingOptions();

		fetchInitialData(this);

		subscribe(EVENT_ID.SOCKET_NOTIFY, dataList => handleSocketNotify(this, dataList));

		this.superPropertyChecksum = trackPage(pageName);
		this.disableLoadMore = true;
	}

	componentDidMount() {
		tracking.trackPage('scheduling-placeholders');

		window.addEventListener('click', this.onClick);
		window.addEventListener('mousemove', this.onMouseMove);
	}

	componentDidUpdate(_prevProps, prevState, _snapshot) {
		if (prevState.staffingModeActive !== this.state.staffingModeActive) {
			this.setState({
				schedulingOptions: this.getSchedulingOptions(),
			});
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);

		this.heatmapCache.clear();

		// event listeners
		window.removeEventListener('click', this.onClick);
		window.removeEventListener('mousemove', this.onMouseMove);

		// subscriptions
		if (this.state.staffingModeActive) {
			unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingStaffingSuccess);
			unsubscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersStaffingUpdate);
		} else {
			unsubscribe(EVENT_ID.SCHEDULING_RELOAD_DATA, this.reloadData);
			unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingMutationSuccess);
			unsubscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersUpdate);
		}

		unsubscribe(EVENT_ID.SOCKET_NOTIFY, handleSocketNotify);
	}

	getSchedulingOptions() {
		const staffingModeActive = this.state.staffingModeActive;
		// utilization format local storage
		const utilizationFormatLocal = localStorage.getItem(PLACEHOLDERS_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY);
		const isLocalUtilizationFormatValid = Object.values(UTILIZATION_FORMAT).includes(utilizationFormatLocal);
		return {
			utilizationFormat: isLocalUtilizationFormatValid ? utilizationFormatLocal : UTILIZATION_FORMAT.HOURS,
			calcWin:
				!staffingModeActive && hasModule(MODULE_TYPES.CALC_WIN_PERCENTAGE)
					? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN) === 'true'
					: false,
			hideSoft:
				staffingModeActive && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
					? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT) === 'true'
					: true,
			hideHard:
				staffingModeActive && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
					? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD) === 'true'
					: false,
			visualizationMode: getSchedulingOptionVisualizationMode(this.props.viewer.company),
		};
	}

	getData() {
		return this.state.data;
	}

	getFilterData() {
		return this.getData();
	}

	resetState() {
		const {data, staffingModeActive} = this.state;

		if (staffingModeActive) {
			initializeStaffingData(this, data, true);
		} else {
			initializeData(this, data);
		}
	}

	redrawCanvasTimeline(args) {
		const {placeholders} = this.getData();
		const hasPlaceholders = !!placeholders?.length > 0;

		if (!hasPlaceholders) {
			this.setState({hasPlaceholders});
		} else {
			if (!args.preventFiltering) {
				trackPerformance('Filtered', () => filterGroupsAndItems(this));
			}

			dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, args);
		}
	}

	resetRecalculateSteps() {
		this.setState({recalculateSteps: false});
	}

	activateStaffingMode(placeholderId) {
		const {staffingModeActive, enteringStaffingMode, data} = this.state;

		if (staffingModeActive) return;

		const placeholder = data.placeholders.find(placeholder => placeholder.id === placeholderId);
		const staffingInitialDate = calculateStartDateFromPlaceholder(this, placeholder);

		const loadMoreMomentLeft = staffingInitialDate
			? getCanvasTimelineDateFromMoment(staffingInitialDate.clone().subtract(3, 'months'))
			: null;
		const loadMoreMomentRight = staffingInitialDate
			? getCanvasTimelineDateFromMoment(staffingInitialDate.clone().add(6, 'months'))
			: null;

		const loadMoreDateLeft = getOverlayCanvasDate(this, loadMoreMomentLeft, LOAD_MORE_DIRECTION.LEFT);
		const loadMoreDateRight = getOverlayCanvasDate(this, loadMoreMomentRight, LOAD_MORE_DIRECTION.RIGHT);
		this.disableLoadMore = false;

		const staffingModeTimelineStartDate = this.timeline.getDateToCentralizeDate(
			getCanvasTimelineDateFromMoment(staffingInitialDate)
		);

		const keepPlaceholderLocalStorage = Util.localStorageGetItem(KEEP_PLACEHOLDER_STORAGE_KEY);
		const keepPlaceholderDefault = keepPlaceholderLocalStorage ? JSON.parse(keepPlaceholderLocalStorage) : false;
		const deletePlaceholderOnSave = hasFeatureFlag('capacity_planning_beta_2_improvements')
			? !keepPlaceholderDefault
			: true;

		if (!enteringStaffingMode) {
			this.props.enterStaffingMode(undefined);
		}

		RecalculationManager.clearAll();

		this.setState(
			{
				staffingModeActive: true,
				enteringStaffingMode: false,
				filters: getStaffingFilters(this, placeholder),
				placeholderAllocationGhosts: [],
				loadingStaffingData: true,
				staffingDataLoaded: false,
				staffingPlaceholder: placeholder,
				staffingInitialDate,
				deletePlaceholderOnSave,
				keepEmptyPlaceholder: keepPlaceholderDefault,
				placeholderAllocationCreatesAndUpdates: [],
				placeholderAllocationDeletes: [],
				projectAllocationCreatesAndUpdates: [],
				projectAllocationDeletes: [],
				[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.LEFT]: false,
				[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.RIGHT]: false,
				[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]: loadMoreDateLeft,
				[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]: loadMoreDateRight,
				staffingModeTimelineStartDate,
			},
			() => {
				this.props.loadMoreHistory.clear();
				// handle mutation success
				unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingMutationSuccess);
				subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingStaffingSuccess);

				// handle filters
				unsubscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersUpdate);
				subscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersStaffingUpdate);

				unsubscribe(EVENT_ID.SCHEDULING_RELOAD_DATA, this.reloadData);

				this.heatmapCache.clear();
				fetchStaffingData(this);

				tracking.trackPage('scheduling-placeholders-staffing');
				trackPage('Schedule Placeholders Staffing');

				this.setState({
					eyeOptions: getInitialEyeOptions(this),
				});
			}
		);
	}

	doStaffingModeExit() {
		this.props.exitStaffingMode();
		this.disableLoadMore = true;

		RecalculationManager.clearAll();

		this.setState(
			{
				staffingModeActive: false,
				staffingPlaceholder: null,
				totalMinutesMap: new Map(),
				placeholderAllocationCreatesAndUpdates: [],
				placeholderAllocationDeletes: [],
				projectAllocationCreatesAndUpdates: [],
				projectAllocationDeletes: [],
				savingStaffingData: false,
				deletePlaceholderOnSave: false,
				initialDataLoaded: false,
				hasPlaceholders: undefined,
				[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.LEFT]: false,
				[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.RIGHT]: false,
				[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]: getOverlayCanvasDate(this, null, LOAD_MORE_DIRECTION.LEFT),
				[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]: getOverlayCanvasDate(this, null, LOAD_MORE_DIRECTION.RIGHT),
			},
			() => {
				// handle mutation success
				unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingStaffingSuccess);
				subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingMutationSuccess);

				// handle filters
				unsubscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersStaffingUpdate);
				subscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersUpdate);

				subscribe(EVENT_ID.SCHEDULING_RELOAD_DATA, this.reloadData);

				this.heatmapCache.clear();
				// fetch data and re-create groups and items
				fetchInitialData(this);

				this.setState({showCollapsedActionMenu: false, eyeOptions: getInitialEyeOptions(this)});

				trackPage('Schedule Placeholders');
			}
		);
	}

	exitWithoutSave() {
		this.doStaffingModeExit();
		trackEvent('Staffing Mode', 'Closed Without Save');
	}

	exitStaffingMode() {
		if (this.changesHasBeenMade()) {
			handleUnsavedChanges(this.exitWithoutSave.bind(this), this.saveStaffingMode.bind(this));
		} else {
			this.exitWithoutSave();
		}
	}

	saveStaffingMode() {
		const keepEmptyPlaceholder = this.state.keepEmptyPlaceholder;
		saveStaffingData(this, keepEmptyPlaceholder).then(_ => {
			const {
				data,
				staffingPlaceholder,
				placeholderAllocationDeletes,
				projectAllocationCreatesAndUpdates,
				deletePlaceholderOnSave,
			} = this.state;
			const placeholderAllocations = data.placeholderAllocationsByPlaceholder[staffingPlaceholder.id];

			// check if any placeholder allocations where staffed
			const placeholderAllocationWasStaffed =
				projectAllocationCreatesAndUpdates?.length > 0
					? projectAllocationCreatesAndUpdates.some(allocation => allocation.placeholderId)
					: false;

			if (placeholderAllocationWasStaffed) {
				trackPlaceholderStaffed(this.state);
			}

			if (
				!hasFeatureFlag('capacity_planning_beta_2_improvements') &&
				hasFeatureFlag('placeholders_beta_changes') &&
				placeholderAllocations?.length === 0
			) {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({
						id: 'scheduling.placeholders.staffing.staffed_toast',
					}),
					actionText: this.props.intl.formatMessage({
						id: 'placeholder.delete_placeholder',
					}),
					actionCallback: () => {
						handleDeletePlaceholder(staffingPlaceholder.id, null, this.props.intl.formatMessage);
					},
				});
			} else if (
				placeholderAllocationDeletes?.length > 0 &&
				placeholderAllocations?.length === 0 &&
				placeholderAllocationWasStaffed
			) {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({
						id: 'scheduling.placeholder_replaced_with_multiple_people',
					}),
				});
			}

			const trackingOptions = {};
			if (hasFeatureFlag('capacity_planning_beta_2_improvements')) {
				const keepPlaceholderLocalStorage = Util.localStorageGetItem(KEEP_PLACEHOLDER_STORAGE_KEY);
				const keepPlaceholderDefault = keepPlaceholderLocalStorage ? JSON.parse(keepPlaceholderLocalStorage) : null;

				if (
					placeholderAllocations?.length === 0 &&
					placeholderAllocationWasStaffed &&
					keepPlaceholderDefault !== null &&
					keepPlaceholderDefault !== keepEmptyPlaceholder
				) {
					Util.localStorageSetItem(KEEP_PLACEHOLDER_STORAGE_KEY, keepEmptyPlaceholder);
				}

				trackingOptions.keepEmptyPlaceholder = keepEmptyPlaceholder;
				trackingOptions.placeholderWasDeleted = placeholderAllocations?.length === 0 && !keepEmptyPlaceholder;
			} else {
				trackingOptions.deletePlaceholderOnSave = deletePlaceholderOnSave;
			}

			this.doStaffingModeExit();

			trackEvent('Staffing Mode', 'Closed With Save', trackingOptions);
		});
	}

	getTimeRegGroupId(timeReg) {
		if (timeReg.personId) {
			return IDManager.getPersonGroupId(this, timeReg.personId);
		}

		return null;
	}

	createEntityItem(createdItems, entity, entityData) {
		const {data} = this.state;
		const {placeholderMap} = data;

		switch (entity) {
			case DATA_ENTITIES.PLACEHOLDER_ALLOCATIONS:
				const placeholder = placeholderMap.get(entityData.placeholderId);

				if (placeholder) {
					const itemData = ComposeManager.composePlaceholderAllocation(this, entityData);

					if (itemData) {
						createdItems.add(new PlaceholderAllocationItem(this, itemData));
					}
				}

				break;
			case DATA_ENTITIES.ALLOCATIONS:
				const itemData = ComposeManager.composeProjectAllocation(this, entityData);

				if (itemData) {
					createdItems.add(new ProjectAllocationItem(this, itemData));
				}

				break;
			default:
				break;
		}
	}

	changesHasBeenMade() {
		const {
			placeholderAllocationCreatesAndUpdates,
			placeholderAllocationDeletes,
			projectAllocationCreatesAndUpdates,
			projectAllocationDeletes,
		} = this.state;

		const placeholderAllocationsCreatesOrUpdated = placeholderAllocationCreatesAndUpdates?.length > 0;
		const placeholderAllocationsDeleted = placeholderAllocationDeletes?.length > 0;
		const projectAllocationsCreatesOrUpdated = projectAllocationCreatesAndUpdates?.length > 0;
		const projectAllocationsDeleted = projectAllocationDeletes?.length > 0;

		return (
			placeholderAllocationsCreatesOrUpdated ||
			placeholderAllocationsDeleted ||
			projectAllocationsCreatesOrUpdated ||
			projectAllocationsDeleted
		);
	}

	isLoading() {
		const {
			staffingModeActive,
			loadingStaffingData,
			savingStaffingData,
			staffingDataLoaded,
			initialDataLoaded,
			hasPlaceholders,
			updatingSuggestedPersons,
		} = this.state;

		if (staffingModeActive) {
			return (
				updatingSuggestedPersons ||
				((loadingStaffingData || savingStaffingData) && (savingStaffingData || !staffingDataLoaded))
			);
		}

		return !initialDataLoaded || hasPlaceholders === undefined;
	}

	render() {
		const {
			data,
			splitPlaceholderAllocationBarData,
			splitAllocationBarData,
			staffingModeActive,
			enteringStaffingMode,
			staffingDataLoaded,
			hasPlaceholders,
			staffingModeTimelineStartDate,
		} = this.state;

		const displayEmptyState = hasPlaceholders === false;

		const showStaffingMode = staffingModeActive || enteringStaffingMode;
		const updateTimelineRef = timeline => {
			this.props.setTimeline(timeline);
			this.timeline = timeline;
		};

		let title = this.props.intl.formatMessage({
			id: showStaffingMode
				? hasFeatureFlag('capacity_planning_beta_2_improvements')
					? 'scheduling.find_available_team_member'
					: 'scheduling.placeholders.staffing.advanced_placeholder_staffing'
				: 'scheduling.menu.schedule_people',
		});

		// Temporary solution for new navigation. Refactor when old navigation has been deprecated.
		if (!showStaffingMode) {
			title = 'Demand';
		}

		const loading = this.isLoading();

		return (
			<div
				id="placeholders-timeline"
				className={'canvas-placeholders-scheduling canvas-scheduling' + (showStaffingMode ? ' staffing-mode' : '')}
			>
				<div style={{backgroundColor: '#fff'}}>
					<TopHeaderBarWrapper sidePadding={24} bottomPadding={0}>
						<TopHeaderBar title={title} content={getTopHeaderContent()} />
					</TopHeaderBarWrapper>
					{showStaffingMode && (
						<PlaceholdersSchedulingStaffingBackground exitStaffingMode={this.exitStaffingMode.bind(this)} />
					)}
					{!displayEmptyState && (
						<div style={{visibility: loading ? 'hidden' : 'visible'}} className="control-bar">
							{createHeaderBar(this)}
						</div>
					)}
				</div>

				<LoadingOverlay isLoading={loading}>
					{!displayEmptyState && (
						<div className={'hover-area'}>
							<PlaceholderSchedulingHoverEffects pageComponent={this} />
							<CanvasTimeline
								ref={updateTimelineRef}
								pageComponent={this}
								groups={this.state.groups}
								items={this.state.items}
								topLeftComponent={
									<CanvasPlaceholdersTopLeftContainer staffingModeActive={staffingModeActive} />
								}
								isCollapsableSectionExpanded={this.state.isCollapsableSectionExpanded}
								onVerticalScroll={delta => EventManager.onTimelineVerticalScroll(this, delta)}
								onHorizontalScroll={() => EventManager.onTimelineHorizontalScroll(this)}
								dayData={this.props.dayData}
								initialStartDate={staffingModeActive ? staffingModeTimelineStartDate : this.props.startDate}
								initialZoomLevel={staffingModeActive ? undefined : this.props.initialZoomLevel}
								isSingleGroup={false}
								onGroupExpansionToggle={group => EventManager.onGroupExpansionToggle(this, group)}
								debugData={this.state.data}
								holidaysEntries={data?.holidaysEntriesCanvasFormat || []}
								eyeOptions={this.state.eyeOptions}
								recalculateSteps={this.state.recalculateSteps}
								resetRecalculateSteps={this.resetRecalculateSteps.bind(this)}
								onForegroundContextMenu={(event, mouseTargetData, canvasDate) =>
									EventManager.onForegroundContextMenu(this, event, mouseTargetData, canvasDate)
								}
								splitPlaceholderAllocationBarData={
									splitPlaceholderAllocationBarData.visible ? splitPlaceholderAllocationBarData : null
								}
								splitAllocationBarData={splitAllocationBarData.visible ? splitAllocationBarData : null}
								heatmapCache={this.heatmapCache}
								onBeforeHorizontalScroll={scrollAmount =>
									preventScrollingPastLoadMoreOverlay(this, scrollAmount, staffingModeActive)
								}
								onDrawForegroundEnd={(canvasContext, _, stepDataArray) =>
									drawIncrementalLoadMoreOverlay(
										this,
										stepDataArray,
										() => hasFeatureFlag('incremental_load_more_include_items') || staffingModeActive
									)
								}
								disableLoadMore={this.disableLoadMore}
								leftLoadMoreDate={this.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]}
								rightLoadMoreDate={this.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]}
								isPlaceholdersScheduling={true}
								isUsing35Frames={this.isUsing35Frames}
								isUsing67Frames={this.isUsing67Frames}
								isUsingInstantZoom={this.isUsingInstantZoom}
								weekendOptions={this.state.weekendOptions}
								schedulingView={this.props.schedulingView}
							/>

							{staffingModeActive && staffingDataLoaded && (
								<PlaceholdersSchedulingStaffingFooter pageComponent={this} />
							)}
						</div>
					)}

					{displayEmptyState && (
						<EmptyState id="placeholders-scheduling-empty-state" pageName={EMPTY_STATE.PLACEHOLDERS_SCHEDULING} />
					)}

					<IncrementalLoadingOverlay
						isHidden={hasFeatureFlag('incremental_load_more_include_items') ? false : !staffingModeActive}
						onWheel={e => this.timeline.onForegroundWheel(e)}
					/>
				</LoadingOverlay>
			</div>
		);
	}
}

CanvasPlaceholdersScheduling.propTypes = {
	viewer: PropTypes.object.isRequired,
	setTimeline: PropTypes.func.isRequired,
};

export default injectIntl(withRouter(CanvasPlaceholdersScheduling));
