import React, {useState} from 'react';
import {InvoiceRow as InvoiceRowComponent} from 'web-components';
import ContextMenu from '../../forecast-app/shared/components/context-menus/context_menu';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import {useIntl} from 'react-intl';
import DeprecatedProjectIndicatorJS from '../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';
import {FlexRow} from '@forecast-it/design-system';

const InvoiceRow = ({
	columns,
	amounts,
	currencySymbol,
	customProjectField,
	project,
	client,
	invoice,
	onInvoiceClick,
	status,
	name,
	description,
	hasDescriptionError,
	quantity,
	unitPrice,
	tax,
	discount,
	totalPrice,
	date,
	dueDate,
	simpleDate,
	datePickerDate,
	notes,
	contextMenuOptions,
	isSelected,
	onSelect,
	addedWidth,
	isFirstRow,
	isEntryRow,
	isOverviewTab,
	isInvoicesTab,
	isInModal,
	xeroAccount,
	quickbooksItem,
	economicProduct,
	sageIntacctLocation,
	sageIntacctAccount,
	integrationLinks,
	cy,
	customHeight,
	customWidth,
	showMeatballMenu,
	customHoverBackground,
	projectNameTooltip,
}) => {
	const intl = useIntl();

	const [contextMenuPosition, setContextMenuPosition] = useState(null);

	const openContextMenu = e => {
		e.preventDefault();
		const contextMenuPosition = {};
		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			contextMenuPosition.x = btnBounds.right - btn.clientWidth - 75 - (addedWidth || 0);
			contextMenuPosition.y = btnBounds.y + 25;
		} else {
			contextMenuPosition.x = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				contextMenuPosition.y = e.pageY - 250;
			} else {
				contextMenuPosition.y = e.pageY;
			}
		}
		setContextMenuPosition(contextMenuPosition);
	};

	const closeContextMenu = () => {
		setContextMenuPosition(null);
	};

	const projectColumn = project && (
		<FlexRow gap="xs">
			<FlexRow flex="0 0 auto">
				<DeprecatedProjectIndicatorJS project={project} disableLink={isInModal} clearSubPath={true} />
			</FlexRow>
			<TooltipContainer infoText={project.fullAccessToProject && projectNameTooltip ? project.name : null}>
				<span>{project.fullAccessToProject ? project.name : intl.formatMessage({id: 'common.no_project_access'})}</span>
			</TooltipContainer>
		</FlexRow>
	);

	return (
		<InvoiceRowComponent
			columns={columns}
			customProjectField={customProjectField}
			projectId={project ? project.id : null}
			projectName={project ? project.name : null}
			projectColumn={projectColumn}
			invoiceName={invoice ? invoice.name : null}
			invoiceId={invoice ? invoice.companyInvoiceId : null}
			onInvoiceClick={onInvoiceClick ? onInvoiceClick : null}
			client={project && project.client ? project.client.name : client ? client : ''}
			status={status}
			name={name}
			description={description}
			hasDescriptionError={hasDescriptionError}
			unitPrice={unitPrice}
			tax={tax}
			discount={discount}
			totalPrice={totalPrice}
			quantity={quantity}
			amounts={amounts}
			date={date}
			dueDate={dueDate}
			simpleDate={simpleDate}
			datePickerDate={datePickerDate}
			notes={notes}
			currencySymbol={currencySymbol}
			onContextMenu={contextMenuOptions ? openContextMenu : () => null}
			contextMenuElement={() =>
				contextMenuPosition && contextMenuOptions ? (
					<ContextMenu
						options={contextMenuOptions}
						contextMenuPosition={contextMenuPosition}
						closeContextMenu={closeContextMenu}
						userpilot={'context-menu-wrapper'}
					/>
				) : null
			}
			isSelected={isSelected}
			onSelect={onSelect}
			isFirstRow={isFirstRow}
			isEntryRow={isEntryRow}
			isOverviewTab={isOverviewTab}
			isInvoicesTab={isInvoicesTab}
			integrationLinks={integrationLinks}
			xeroAccount={xeroAccount}
			cy={cy}
			quickbooksItem={quickbooksItem}
			economicProduct={economicProduct}
			sageIntacctLocation={sageIntacctLocation}
			sageIntacctAccount={sageIntacctAccount}
			customHeight={customHeight}
			customWidth={customWidth}
			showMeatballMenu={showMeatballMenu}
			customHoverBackground={customHoverBackground}
		/>
	);
};

export default InvoiceRow;
