import {
	DeprecatedText as Text,
	deprecatedTheme as theme,
	FlexColumn,
	DeprecatedLink as Link,
	DeprecatedNoticeBanner as NoticeBanner,
} from '@forecast-it/design-system';
import React from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';

export const ProgramBudgetExceededWarning = ({
	canManageProgram,
	program,
	onEditProgramBudgetClick,
	valueType,
	showLockedRevenueError,
}) => {
	const {formatMessage} = useIntl();

	return (
		<div
			style={{
				marginBottom: theme.spacing.m,
			}}
		>
			<NoticeBanner
				type={'warning'}
				title={formatMessage({
					id: showLockedRevenueError
						? 'project_settings.capped_revenue_locked_program_cap.header'
						: 'project_settings.capped_revenue_program_cap_exceeded.header',
				})}
			>
				<FlexColumn gap={'s'}>
					<Text>
						{
							<FormattedHTMLMessage
								id={
									canManageProgram
										? showLockedRevenueError
											? 'project_settings.capped_revenue_locked_program_revenue_cap.description.withName'
											: 'project_settings.capped_revenue_program_cap_exceeded.description.withName'
										: showLockedRevenueError
										? 'project_settings.capped_revenue_locked_program_revenue_cap.description.withoutName'
										: 'project_settings.capped_revenue_program_cap_exceeded.description.withoutName'
								}
								values={canManageProgram ? {programName: program.name, valueType} : {}}
							/>
						}
					</Text>
					{canManageProgram && (
						<Link onClick={onEditProgramBudgetClick}>{formatMessage({id: 'common.edit_program_budget'})}</Link>
					)}
				</FlexColumn>
			</NoticeBanner>
		</div>
	);
};
