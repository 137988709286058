import {graphql} from 'graphql';
import {createFragmentContainer} from 'react-relay';

const FixedPriceContainerLoader = ({viewer, children}) => {
	return children({viewer});
};

export const fixedPriceContainerLoaderQuery = graphql`
	query FixedPriceContainerLoader_Query($projectId: ID!) {
		viewer {
			actualPersonId
			component(name: "fixed_price_container_loader")
			...FixedPriceContainerLoader_viewer @arguments(projectId: $projectId)
		}
	}
`;
// originalForecastBillableHoursPrice is quite heavy and should not be used
// the weird ROLE id "Um9sZTotMQ==" is -1, meaning expenses
export default createFragmentContainer(FixedPriceContainerLoader, {
	viewer: graphql`
		fragment FixedPriceContainerLoader_viewer on Viewer @argumentDefinitions(projectId: {type: "ID!"}) {
			project(internalId: $projectId) {
				id
				companyProjectId
				budgetType
				budget
				originalForecastBillableHoursPrice
				forecastBillableHoursPrice
				expenseFinancialNumbers: financialNumbers(
					searchQuery: {filters: [{field: ROLE, operator: IS, value: "Um9sZTotMQ=="}]}
					convertToProjectCurrency: true
				) {
					billablePlannedTimeAndExpenses
				}
				rateCard {
					id
					currency
					parentRateCardId
					rates(first: 10000) {
						edges {
							node {
								id
								rate
								defaultRate
								startDate
								role {
									id
								}
							}
						}
					}
				}
			}
			company {
				id
				currency
				rateCards(first: 10000) {
					edges {
						node {
							id
							currency
							rates(first: 10000) {
								edges {
									node {
										id
										rate
										defaultRate
										startDate
										role {
											id
										}
									}
								}
							}
						}
					}
				}
				roles(first: 10000) {
					edges {
						node {
							id
							name
						}
					}
				}
			}
		}
	`,
});
