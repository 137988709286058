import React from 'react';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../css_variables';

const TitleElement = styled.div`
	font-size: 16px;
	text-transform: capitalize;
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-weight: 500;
	margin: 24px 66px 0;
	position: sticky;
	left: 66px;
`;

const Title = ({intl: {formatMessage}, textId}) => {
	return <TitleElement>{formatMessage({id: textId})}</TitleElement>;
};

export default Title;
