import React, {Component} from 'react';
import Moment from 'moment';
import {injectIntl} from 'react-intl';
import UpdateViewerMutation from '../mutations/update_viewer_mutation.modern';
import {createToast} from '../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../forecast-app/shared/util/util';
import {BUTTON_STYLE, BUTTON_COLOR} from '../constants';
import {showModal, MODAL_TYPE} from '../forecast-app/shared/components/modals/generic_modal_conductor';
import PlayIcon from '../images/cross-section-icons/actions/timer_play';
import PauseIcon from '../images/cross-section-icons/actions/timer_pause';
import StopIcon from '../images/cross-section-icons/actions/timer_stop';
import Warning from '../components/warning';
import {trackEvent} from '../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../forecast-app/shared/util/FeatureUtil';

class Timer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timerStart: null,
			timerDuration: 0,
			isTimerRunning: false,
			canChangeTimer: true,
			pausedAt: null,
		};
	}

	UNSAFE_componentWillMount() {
		const {timerStartDate, timerEndDate} = this.props.viewer;
		if (timerStartDate) {
			let momentTimerStartDate = Moment.utc(timerStartDate);
			let momentTimerEndDate = timerEndDate !== null ? Moment.utc(timerEndDate) : null;
			if (
				!(
					this.props.viewer.task &&
					this.props.viewer.timerTask &&
					this.props.viewer.task.id !== this.props.viewer.timerTask.id
				)
			) {
				//	const pauseLength = momentTimerEndDate !== null ? Moment.utc().diff(momentTimerEndDate) : 0;
				//	const momentTimerStartDateWithPause = momentTimerStartDate.subtract(pauseLength, 'milliseconds');
				this.setState({
					timerDuration:
						momentTimerEndDate !== null
							? momentTimerEndDate.diff(momentTimerStartDate, 'seconds')
							: Moment.utc().diff(momentTimerStartDate, 'seconds'),
					timerStart: momentTimerStartDate,
					isTimerRunning: true,
					pausedAt: momentTimerEndDate,
				});
			}
		}
	}

	componentDidMount() {
		if (!this.props.viewer.timerEndDate) {
			this.timer = setInterval(this.incrementTimer.bind(this), 500);
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		//If time was reported, reset time. Otherwise make sure it keeps running so it is synced between header and task modal (unless viewing task that the timer wasn't started on)
		if (!nextProps.viewer.timerStartDate) {
			this.setState({
				isTimerRunning: false,
				timerDuration: 0,
			});
		} else if (this.state.pausedAt === null && nextProps.viewer.timerEndDate) {
			if (this.timer) {
				clearInterval(this.timer);
			}
			this.setState({
				pausedAt: nextProps.viewer.timerEndDate,
			});
		} else if (this.state.pausedAt !== null && !nextProps.viewer.timerEndDate) {
			this.timer = setInterval(this.incrementTimer.bind(this), 500);
			this.setState({
				pausedAt: null,
			});
		} else {
			if (
				this.props.viewer.task &&
				this.props.viewer.timerTask &&
				this.props.viewer.task.id !== this.props.viewer.timerTask.id
			)
				return;
			this.setState({
				isTimerRunning: true,
			});
		}
	}

	componentWillUnmount() {
		if (this.timer !== undefined) {
			clearInterval(this.timer);
		}
	}

	getTimerLocation() {
		return this.props.taskModal ? 'Task Modal' : 'Top Navigation';
	}

	incrementTimer() {
		if (this.state.isTimerRunning && !this.state.pausedAt) {
			this.setState({timerDuration: Moment.utc().diff(this.props.viewer.timerStartDate, 'seconds')});
		}
	}

	startTimer() {
		//If previous timer mutation is still pending, go away
		if (!this.state.canChangeTimer) return;
		// If there is a start date and this function is executed, it means timer is running but you are viewing task which hasn't started the timer
		if (this.props.viewer.timerStartDate) {
			if (
				this.props.taskModal &&
				this.props.viewer.timerTask &&
				this.props.viewer.task &&
				this.props.viewer.timerTask.id !== this.props.viewer.task.id
			) {
				if (hasFeatureFlag('new_time_registration_modal')) {
					showModal({
						type: MODAL_TYPE.TIMER_TIME_REGISTRATION,
						timerActionTaskId: this.props.viewer.task?.id,
					});
				} else {
					showModal({
						type: MODAL_TYPE.TIMER_V3,
						isPreviousTimerReminder: false,
						projectId: null,
						taskId: this.props.viewer.task.id,
						taskModal: this.props.taskModal,
						isResetingAndStartingTimer: true, // if the user wants to start timer on a task but the timer is already running on a different task
						startTimer: this.startTimer.bind(this),
						personId: this.props.viewer.actualPersonId,
					});
				}
			}
		} else {
			trackEvent(`${this.getTimerLocation()} Timer Start Button`, 'Clicked');
			this.setState({
				canChangeTimer: false,
			});
			const onSuccess = result => {
				this.setState({
					timerDuration: Moment.utc().diff(Moment.utc(result.updateViewer.viewer.timerStartDate), 'seconds'),
					isTimerRunning: true,
					canChangeTimer: true,
				});
			};
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					timerStartDate: Moment.utc().format(),
					timerEndDate: null,
					timerTaskId: this.props.viewer.task ? this.props.viewer.task.id : null,
				},
				onSuccess
			);
		}
	}

	stopTimer() {
		trackEvent(`${this.getTimerLocation()} Timer Stop Button`, 'Clicked');
		//If previous timer mutation is still pending, go away
		if (!this.state.canChangeTimer) return;
		if (hasFeatureFlag('new_time_registration_modal')) {
			showModal({
				type: MODAL_TYPE.TIMER_TIME_REGISTRATION,
				timerActionTaskId: this.props.viewer.task?.id,
			});
		} else {
			showModal({
				type: MODAL_TYPE.TIMER_V3,
				isPreviousTimerReminder:
					this.props.viewer.timerStartDate !== null && this.props.viewer.timerStartDate !== undefined,
				timerTask: this.props.taskModal
					? this.props.viewer.timerTask
						? this.props.viewer.timerTask
						: this.props.viewer.task
					: null,
				projectId: null,
				taskId: null,
				taskModal: this.props.taskModal,
				personId: this.props.viewer.actualPersonId,
			});
		}
	}

	pauseTimer() {
		trackEvent(`${this.getTimerLocation()} Timer Pause Button`, 'Clicked');
		const pausedAt = Moment.utc().format();
		clearInterval(this.timer);
		this.setState({pausedAt});

		Util.CommitMutation(UpdateViewerMutation, {
			timerEndDate: pausedAt,
		});
	}

	resumeTimer() {
		trackEvent(`${this.getTimerLocation()} Timer Resume Button`, 'Clicked');
		const onSuccess = res => {
			let momentTimerStartDate =
				res.updateViewer.viewer && res.updateViewer.viewer.timerStartDate
					? Moment.utc(res.updateViewer.viewer.timerStartDate)
					: null;
			this.setState({
				pausedAt: null,
				timerStart: momentTimerStartDate,
				timerDuration: Moment.utc().diff(momentTimerStartDate, 'seconds'),
			});
		};
		this.timer = setInterval(this.incrementTimer.bind(this), 500);
		Util.CommitMutation(
			UpdateViewerMutation,
			{
				timerStartDate: Moment.utc().format(),
				timerEndDate: null,
			},
			onSuccess
		);
	}

	clearTimer() {
		const {formatMessage} = this.props.intl;
		this.setState({
			canChangeTimer: false,
		});

		const onSuccess = result => {
			this.setState({
				isTimerRunning: false,
				canChangeTimer: true,
			});
			createToast({duration: 5000, message: formatMessage({id: 'timer_modal.reset_toast_text'})});
		};
		const callbackOnCancel = () => {
			if (!this.state.canChangeTimer) {
				this.setState({canChangeTimer: true});
			}
		};
		const callbackPositive = () => {
			Util.CommitMutation(
				UpdateViewerMutation,
				{
					timerStartDate: null,
					timerEndDate: null,
					timerTaskId: null,
				},
				onSuccess
			);
		};

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="common.delete-confirmation-title" />
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					callback: callbackOnCancel,
				},
				{
					text: formatMessage({id: 'timer_modal.reset_timer'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: callbackPositive,
				},
			],
		});
	}

	getTimerText() {
		let timerDuration = this.state.timerDuration;
		let timerText = '';

		if (
			this.props.taskModal &&
			this.props.viewer.timerTask &&
			this.props.viewer.task &&
			this.props.viewer.timerTask.id !== this.props.viewer.task.id
		)
			return '00:00:00';

		let timerHours = (timerDuration / 3600) | 0;
		timerDuration -= timerHours * 3600;
		timerHours = timerHours.toString();
		timerText += timerHours.length === 1 ? '0' + timerHours + ':' : timerHours + ':';

		let timerMinutes = (timerDuration / 60) | 0;
		timerDuration -= timerMinutes * 60;
		timerMinutes = timerMinutes.toString();
		timerText += timerMinutes.length === 1 ? '0' + timerMinutes + ':' : timerMinutes + ':';

		let timerSeconds = (timerDuration | 0).toString();
		timerText += timerSeconds.length === 1 ? '0' + timerSeconds : timerSeconds;

		return timerText;
	}

	getTimerButtonsHTML() {
		const buttons = [];
		if (this.state.isTimerRunning) {
			buttons.push(
				this.state.pausedAt === null ? (
					<button key={0} className="timer-button timer-pause" onClick={this.pauseTimer.bind(this)}>
						<PauseIcon />
					</button>
				) : (
					<button key={0} className="timer-button timer-play" onClick={this.resumeTimer.bind(this)}>
						<PlayIcon />
					</button>
				)
			);
			buttons.push(
				<button key={1} className="timer-button timer-stop" onClick={this.stopTimer.bind(this)}>
					<StopIcon />
				</button>
			);
		} else {
			buttons.push(
				<button key={0} className="timer-button timer-play" onClick={this.startTimer.bind(this)}>
					<PlayIcon />
				</button>
			);
		}
		return buttons;
	}

	render() {
		const timerText = this.getTimerText();
		return (
			<div className="timer-component">
				<div className="timer-container" data-userpilot={this.props.userpilot}>
					<div className="timer-time-container">
						<div>{timerText}</div>
					</div>
					{this.getTimerButtonsHTML()}
				</div>
			</div>
		);
	}
}

export default injectIntl(Timer);
