import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import BaseLevelWrapper from './BaselevelWrapper';
import GroupRow from '../rows/GroupRow';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../constants';

const personRelayWrapper = ({
	tableHeader,
	relay,
	groupings,
	searchQuery,
	viewer,
	intl,
	rowIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
	convertToProjectCurrency,
	projectCurrency,
}) => {
	const persons = viewer.company.allPersonsGlobalSearch.edges;
	const companyPersonsIds = viewer.company.allPersons.edges.map(person => person.node.id);
	const currency = convertToProjectCurrency && projectCurrency ? projectCurrency : viewer.company.currency;
	const hasNextPage = viewer.company.allPersonsGlobalSearch.pageInfo.hasNextPage;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);

	/**
	 * Grouping filter for task assigned to a person
	 *
	 * @param personId The person ID
	 */
	const personGroupingFilter = personId => {
		return {
			field: GLOBAL_FILTER_FIELD.ASSIGNED,
			operator: GLOBAL_FILTER_OPERATOR.IN,
			value: [personId],
		};
	};

	/**
	 * Grouping filter for an unassigned tasks
	 */
	const unassignedGroupingFilter = () => {
		return {
			field: GLOBAL_FILTER_FIELD.ASSIGNED,
			operator: GLOBAL_FILTER_OPERATOR.NOT_IN,
			value: companyPersonsIds,
		};
	};

	const dataRows = persons.map(person => {
		return {
			...person.node,
			isPerson: true,
		};
	});

	/**
	 * Row for unassigned tasks
	 */
	if (viewer.company.taskTotals?.numberOfTasks) {
		dataRows.push({
			unassigned: true,
			name: intl.formatMessage({id: 'cards.unassigned'}),
			taskTotals: viewer.company.taskTotals,
		});
	}

	const data = {
		rows: dataRows,
		currency,
		groupingFilter: rowData => (rowData.unassigned ? unassignedGroupingFilter() : personGroupingFilter(rowData.id)),
	};

	//function for loading more data
	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = persons.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const personRows = () => {
		// return getPersonRow(data, currency, intl, rowIndex, searchQuery, groupings, enabledColumns, sortValue, hasNextPage, fetchMore, nextLevelProps);
		return GroupRow(
			data,
			intl,
			rowIndex,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader,
			convertToProjectCurrency,
			projectCurrency,
			true
		);
	};

	return rowIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={personRows}
			currentLength={persons.length}
			hasNextPage={viewer.company.allPersonsGlobalSearch.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		personRows()
	);
};

const PersonRelayWrapperQuery = graphql`
	query PersonRelayWrapperQuery_Query($searchQuery: TaskSearchQueryType, $pageSize: Int, $convertToProjectCurrency: Boolean) {
		viewer {
			actualPersonId
			component(name: "person_relay_wrapper")
			...PersonRelayWrapper_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, convertToProjectCurrency: $convertToProjectCurrency)
		}
	}
`;
export {PersonRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			personRelayWrapper,
			{
				viewer: graphql`
					fragment PersonRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						convertToProjectCurrency: {type: "Boolean"}
					) {
						availableFeatureFlags {
							key
						}
						company {
							currency
							taskTotals(
								searchQuery: $searchQuery
								convertToProjectCurrency: $convertToProjectCurrency
								noPerson: true
							) {
								registeredMinutes
								billableActualMinutes
								nonBillableActualMinutes
								estimatePoints
								estimateMinutes
								remainingPoints
								remainingMinutes
								plannedRevenue
								actualRevenue
								numberOfTasks
								remainingRevenue
								forecastRevenue
								recognitionLockedRevenue
								recognitionOpenRevenue
								recognitionForecastRevenue
								totalActualRevenueRecognition
								totalForecastRevenueToComplete
								surplus
							}
							allPersons {
								edges {
									node {
										id
									}
								}
							}
							allPersonsGlobalSearch(first: $pageSize, searchQuery: $searchQuery) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										firstName
										lastName
										fullName
										profilePictureId
										department {
											name
										}
										active
										taskTotals(
											searchQuery: $searchQuery
											convertToProjectCurrency: $convertToProjectCurrency
										) {
											registeredMinutes
											billableActualMinutes
											nonBillableActualMinutes
											estimatePoints
											estimateMinutes
											remainingPoints
											remainingMinutes
											plannedRevenue
											actualRevenue
											remainingRevenue
											forecastRevenue
											recognitionLockedRevenue
											recognitionOpenRevenue
											recognitionForecastRevenue
											totalActualRevenueRecognition
											totalForecastRevenueToComplete
											surplus
											numberOfTasks
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query PersonRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$convertToProjectCurrency: Boolean
				) {
					viewer {
						...PersonRelayWrapper_viewer
							@arguments(
								pageSize: $pageSize
								searchQuery: $searchQuery
								convertToProjectCurrency: $convertToProjectCurrency
							)
					}
				}
			`
		)
	)
);
