import React, {Component} from 'react';
import 'draft-js-emoji-plugin/lib/plugin.css';
import {ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState, RichUtils} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, {defaultSuggestionsFilter} from 'draft-js-mention-plugin';
import createLinkifyPlugin from '../components/new-ui/link_plugin';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import PropTypes from 'prop-types';
import RichTextUtil from './rich_text_util';

var INLINE_STYLES = [
	{label: 'bold', style: 'BOLD'},
	{label: 'italic', style: 'ITALIC'},
	{label: 'underline', style: 'UNDERLINE'},
	{label: 'strikethrough', style: 'STRIKETHROUGH'},
];

const BLOCK_TYPES = [
	{label: 'ul', style: 'unordered-list-item'},
	{label: 'ol', style: 'ordered-list-item'},
];

class DescriptionItem extends Component {
	constructor(props) {
		super(props);

		this.mentionPlugin = createMentionPlugin();
		this.labelPlugin = createMentionPlugin({
			mentionPrefix: '#',
			mentionTrigger: '#',
			entityMutability: 'IMMUTABLE',
		});
		this.linkifyPlugin = createLinkifyPlugin(props.readOnly);
		this.emojiPlugin = createEmojiPlugin({
			useNativeArt: true,
		});
		this.plugins = [this.mentionPlugin, this.labelPlugin, this.linkifyPlugin, this.emojiPlugin];

		let content = null;
		if (props.text !== undefined && props.text !== null && props.text !== '') {
			try {
				if (props.text.startsWith('<div>') || props.text.startsWith('<p>')) {
					const blocksFromHTML = convertFromHTML(props.text);
					content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
				} else {
					const json = JSON.parse(props.text);
					if (json.blocks.length > 0) {
						content = convertFromRaw(json);
					} else {
						content = ContentState.createFromText('');
					}
				}
			} catch (err) {
				content = ContentState.createFromText(props.text);
			}
		}

		this.state = {
			editorState:
				props.text === undefined || props.text === null || props.text === ''
					? this.createEmptyWithFocus()
					: EditorState.createWithContent(content),
			suggestions: this.props.mentions !== null ? this.props.mentions : [],
			labels: this.props.mentionsLabels && !this.props.readOnly ? this.props.mentionsLabels : [],
			isEmojiModalOpen: false,
			hasFocus: false,
			isShowingNewMentionSuggestion: false,
		};
		this.listener = this.listener.bind(this);
		this.onChange = editorState => {
			this.setState({editorState});
		};
		this.onChange = this.onChange.bind(this);
		this.handleKeyCommand = command => this._handleKeyCommand(command);
		this.onSearchChange = ({value}) => {
			this.setState({
				suggestions: this.props.mentions !== null ? defaultSuggestionsFilter(value, this.props.mentions) : [],
			});
		};
		this.onLabelSearchChange = ({value}) => {
			this.setState({
				labels: this.props.mentionsLabels ? defaultSuggestionsFilter(value, this.props.mentionsLabels) : [],
			});
		};
		this.toggleInlineStyle = style => this._toggleInlineStyle(style);
		this.toggleBlockType = type => this._toggleBlockType(type);
	}

	componentDidMount() {
		document.body.addEventListener('mousedown', this.listener);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!nextProps.text && !nextProps.handleFocusChange) {
			//Prevent clearing comment editor first time when new props are received because it happens when time reg accordion finishes fetching and it clears the editor for no reason
			if (this.props.propsReceived || !nextProps.propsReceived || !this.props.isCommentEditor) {
				const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
				this.setState({editorState: editorState});
			}
		} else if (nextProps.text !== this.props.text) {
			let content = null;
			try {
				if (nextProps.text.startsWith('<div>') || nextProps.text.startsWith('<p>')) {
					const blocksFromHTML = convertFromHTML(nextProps.text);
					content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
				} else {
					content = convertFromRaw(JSON.parse(nextProps.text));
				}
			} catch (err) {
				content = ContentState.createFromText(nextProps.text ? nextProps.text : '');
			}
			const editorState = EditorState.createWithContent(content);
			this.setState({editorState: editorState});
		}
		//Remove label from dropdown list if user starts typing another hashtag before the previous mutation is finished
		// DL: Removed because this breaks the up and down arrow keys.
		// this.setState({
		// 	labels: nextProps.mentionsLabels && !nextProps.readOnly ? this.filterLabels(this.state.labelsSearchValue, nextProps.mentionsLabels) : []
		// });
	}

	componentWillUnmount() {
		document.body.removeEventListener('mousedown', this.listener);
	}

	//region Limit Characters code
	_getLengthOfSelectedText = () => {
		const currentSelection = this.state.editorState.getSelection();
		const isCollapsed = currentSelection.isCollapsed();

		let length = 0;

		if (!isCollapsed) {
			const currentContent = this.state.editorState.getCurrentContent();
			const startKey = currentSelection.getStartKey();
			const endKey = currentSelection.getEndKey();
			const startBlock = currentContent.getBlockForKey(startKey);
			const isStartAndEndBlockAreTheSame = startKey === endKey;
			const startBlockTextLength = startBlock.getLength();
			const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
			const endSelectedTextLength = currentSelection.getEndOffset();
			const keyAfterEnd = currentContent.getKeyAfter(endKey);
			if (isStartAndEndBlockAreTheSame) {
				length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
			} else {
				let currentKey = startKey;

				while (currentKey && currentKey !== keyAfterEnd) {
					if (currentKey === startKey) {
						length += startSelectedTextLength + 1;
					} else if (currentKey === endKey) {
						length += endSelectedTextLength;
					} else {
						length += currentContent.getBlockForKey(currentKey).getLength() + 1;
					}

					currentKey = currentContent.getKeyAfter(currentKey);
				}
			}
		}

		return length;
	};

	_handleBeforeInput = () => {
		if (this.props.characterLimit <= 0) {
			return;
		}
		const currentContent = this.state.editorState.getCurrentContent();
		const currentContentLength = currentContent.getPlainText('').length;
		const selectedTextLength = this._getLengthOfSelectedText();

		if (currentContentLength - selectedTextLength > this.props.characterLimit - 1) {
			// eslint-disable-next-line no-console
			console.log(`you can type max ${this.props.characterLimit} characters`);

			return 'handled';
		}
	};
	_handlePastedText = pastedText => {
		if (this.props.characterLimit <= 0) {
			return;
		}
		const currentContent = this.state.editorState.getCurrentContent();
		const currentContentLength = currentContent.getPlainText('').length;
		const selectedTextLength = this._getLengthOfSelectedText();

		if (currentContentLength + pastedText.length - selectedTextLength > this.props.characterLimit) {
			// eslint-disable-next-line no-console
			console.log(`you can type max ${this.props.characterLimit} characters`);

			return 'handled';
		}
	};

	//endregion

	filterLabels(searchValue, suggestions) {
		const value = searchValue.toLowerCase();
		if (suggestions) {
			let filteredSuggestions = suggestions.filter(function (suggestion) {
				return !value || (suggestion.name && suggestion.name.toLowerCase().indexOf(value) > -1);
			});
			const size = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
			if (size > 0 || searchValue.trim() === '') {
				this.setState({isShowingNewMentionSuggestion: false});
				return filteredSuggestions.slice(0, 5);
			} else {
				this.setState({isShowingNewMentionSuggestion: true});
				return [{id: 'richtext-new-label', name: searchValue}];
			}
		}
		return [];
	}

	createEmptyWithFocus() {
		const empty = EditorState.createEmpty();
		return empty;
		//return EditorState.moveFocusToEnd(empty);
	}

	_toggleInlineStyle(inlineStyle) {
		this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
	}

	_toggleBlockType(blockType) {
		this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
	}

	_handleKeyCommand(command) {
		if (command === 'bold' || command === 'underline' || command === 'italic') {
			this._toggleInlineStyle(command.toUpperCase());
		}
		const {editorState} = this.state;
		RichUtils.handleKeyCommand(editorState, command);
	}

	listener(event) {
		if (
			(event.target &&
				typeof event.target.className === 'string' &&
				(event.target.className === 'draftJsEmojiPlugin__emojiSelectButton__3sPol' ||
					event.target.className.includes('emojiSelectPopoverEntry'))) ||
			(event.target && typeof event.target.className === 'object')
		) {
			event.preventDefault();
		}
	}

	handleBlur(e) {
		if (this.props.handleTextChange)
			this.props.handleTextChange(JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())));
		this.setState({
			hasFocus: false,
		});
		if (this.props.handleFocusChange) {
			this.props.handleFocusChange(false);
		}
	}

	handleFocus() {
		if (this.refs.editor) {
			this.refs.editor.focus();
		}
	}

	handleFocusState() {
		this.setState({
			hasFocus: true,
		});
		if (this.props.handleFocusChange) {
			this.props.handleFocusChange(true);
		}
	}

	openLink(href) {
		if (!this.props.readOnly) {
			window.open(href);
		}
	}

	onTab(event) {
		this.onChange(RichUtils.onTab(event, this.state.editorState, 6));
	}

	render() {
		const {editorState} = this.state;
		const alwaysShowControls = this.props.alwaysShowControls === undefined || this.props.alwaysShowControls;

		const {EmojiSelect} = this.emojiPlugin;
		const {MentionSuggestions} = this.mentionPlugin;
		const LabelSuggestions = this.labelPlugin.MentionSuggestions;
		const isListStyleOn =
			editorState.getCurrentContent().getBlockMap().first().getType() === 'ordered-list-item' ||
			editorState.getCurrentContent().getBlockMap().first().getType() === 'unordered-list-item';

		const entry = props => {
			const {mention, theme, ...parentProps} = props;
			return (
				<div {...parentProps}>
					<div className={theme.mentionSuggestionsEntryContainer + ' label-suggestion-new-entry'}>
						<div className={theme.mentionSuggestionsEntryContainerRight}>
							<div className={theme.mentionSuggestionsEntryText}>{mention.name}</div>
						</div>
					</div>
				</div>
			);
		};

		return (
			<div
				className={
					'editor richtext-item' +
					(!alwaysShowControls && !this.state.hasFocus ? ' hide-controls' : '') +
					(this.props.readOnly ? ' readOnly' : '') +
					(this.props.clientNotesEditor ? ' client-notes-editor' : '')
				}
			>
				<div onClick={this.handleFocus.bind(this)}>
					<div
						className={
							'editor-textArea' +
							(this.props.fixedHeight ? ' fixed-height-text-area' : '') +
							(this.props.placeSuggestionsOver ? ' place-over' : '')
						}
					>
						<div
							data-cy={this.props.cy}
							className={
								'editor-wrapper' +
								(this.props.readOnly || this.props.projectLocked ? '' : ' project-status-edit') +
								(this.props.fixedSize ? ' fixed-size' : '')
							}
						>
							<Editor
								readOnly={this.props.readOnly || this.props.projectLocked}
								onBlur={this.handleBlur.bind(this)}
								onFocus={this.handleFocusState.bind(this)}
								editorState={this.state.editorState}
								placeholder={isListStyleOn ? '' : this.props.placeholder}
								handleKeyCommand={this.handleKeyCommand}
								handleBeforeInput={this._handleBeforeInput.bind(this)}
								handlePastedText={this._handlePastedText.bind(this)}
								onChange={this.onChange}
								plugins={this.plugins}
								ref="editor"
								blockRendererFn={RichTextUtil.getBlockRenderer()}
								blockStyleFn={RichTextUtil.getBlockStyleFn()}
								customStyleMap={RichTextUtil.getStyleMap()}
							/>
						</div>
						{this.state.suggestions ? (
							<MentionSuggestions onSearchChange={this.onSearchChange} suggestions={this.state.suggestions} />
						) : null}
						{this.state.labels ? (
							<LabelSuggestions
								onSearchChange={this.onLabelSearchChange}
								suggestions={this.state.labels}
								entryComponent={this.state.isShowingNewMentionSuggestion ? entry : null}
							/>
						) : null}
						<div className="editor-controls">
							<div>
								<BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
								<InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
							</div>
							{this.props.hideEmojiPicker ? null : <EmojiSelect />}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

class StyleButton extends React.Component {
	constructor() {
		super();
		this.onToggle = e => {
			e.preventDefault();
			this.props.onToggle(this.props.style);
		};
	}

	render() {
		return <div className={'controls ' + this.props.label} onMouseDown={this.onToggle} />;
	}
}

const InlineStyleControls = props => {
	var currentStyle = props.editorState.getCurrentInlineStyle();
	return (
		<div className="RichEditor-controls">
			{INLINE_STYLES.map(type => (
				<StyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={currentStyle.has(type.style) ? type.label + ' active' : type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
};

const BlockStyleControls = props => {
	const {editorState} = props;
	const selection = editorState.getSelection();
	const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

	return (
		<div className="RichEditor-controls">
			{BLOCK_TYPES.map(type => (
				<StyleButton
					key={type.label}
					active={type.style === blockType}
					label={type.style === blockType ? type.label + ' active' : type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
};

DescriptionItem.propTypes = {
	text: PropTypes.string,
	mentions: PropTypes.array,
	mentionsLabels: PropTypes.array,
	handleFocusChange: PropTypes.func,
	handleTextChange: PropTypes.func,
	clearCommentEditor: PropTypes.bool,
	isCommentEditor: PropTypes.bool,
	alwaysShowControls: PropTypes.bool,
	placeholder: PropTypes.string,
	readOnly: PropTypes.bool,
	fixedHeight: PropTypes.bool,
	hideEmojiPicker: PropTypes.bool,
	characterLimit: PropTypes.number,
	placeSuggestionsOver: PropTypes.bool,
};

export default DescriptionItem;
