import React from 'react';
import styled from 'styled-components';

import {TableHeaderColumn} from './TableHeaderColumn';
import {TableHeaderSubColumn} from './TableHeaderSubColumn';

const TableHeaderStyle = styled.thead`
	background: white;
`;

export const TableHeader = ({currencySymbol, pageStructure, stickyColumnsIndexes}) => {
	return (
		<TableHeaderStyle>
			<tr>
				{pageStructure.columns.map(column => (
					<TableHeaderColumn key={column.id} currencySymbol={currencySymbol} column={column} />
				))}
			</tr>
			<tr>
				{pageStructure.columns.map((column, columnIndex) =>
					column.subColumns.map((subColumn, index) => (
						<TableHeaderSubColumn
							key={subColumn.id}
							highlighted={column.highlighted}
							drawBorder={index === 0}
							currencySymbol={currencySymbol}
							subColumn={subColumn}
							shouldBeSticky={column.id === 'projectOverview' && stickyColumnsIndexes.includes(index)}
							index={index}
						/>
					))
				)}
			</tr>
		</TableHeaderStyle>
	);
};
