import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ForecastTooltip from '../../forecast-app/shared/components/tooltips/ForecastTooltip';

//Significantly different from the usual component so cannot just use that one
class ActionMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {expandUp: false};
	}
	componentDidMount() {
		if (this.props.expanded && this.actionsDropdownRef.children.length) {
			this.actionsDropdownRef.children[0].focus();
		}

		if (this.actionsDropdownRef) {
			let expandUp = false;
			const {height, top} = this.actionsDropdownRef.getBoundingClientRect();
			const {innerHeight} = window;
			if (top + height > innerHeight) {
				expandUp = true;
			}
			this.setState({expandUp});
		}
	}

	handleActionsBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (newTarget && newTarget.className && newTarget.className.includes('actions-option')) return;
	}

	handleActionsKeyPress(e) {
		if (e.key === 'ArrowDown') {
			// Down arrow
			e.preventDefault();
			this.focusNextChild(this.actionsDropdownRef.children);
		} else if (e.key === 'ArrowUp' && this.props.expanded) {
			// Up arrow
			e.preventDefault();
			this.focusPreviousChild(this.actionsDropdownRef.children);
		} else if (e.key === 'Enter' || e.key === ' ') {
			// Enter / Space
			const focusedElement = document.activeElement;
			if (focusedElement !== this.buttonRef) {
				e.preventDefault();
				focusedElement.click();
			}
		}
	}

	focusHovered(index) {
		if (this['actionsDropdownOptionRef' + index]) {
			this['actionsDropdownOptionRef' + index].focus();
		}
	}

	focusNextChild(children) {
		const focusedElement = document.activeElement;
		for (let i = 0; i < children.length; i++) {
			if (focusedElement === children[i]) {
				if (i + 1 >= children.length) {
					children[0].focus();
				} else {
					children[i + 1].focus();
				}
				return;
			}
		}
		children[0].focus();
	}

	focusPreviousChild(children) {
		const focusedElement = document.activeElement;
		for (let i = 0; i < children.length; i++) {
			if (focusedElement === children[i]) {
				if (i === 0) {
					children[children.length - 1].focus();
				} else {
					children[i - 1].focus();
				}
				return;
			}
		}
		children[children.length - 1].focus();
	}

	handleOptionClick(e, option) {
		e.stopPropagation();
		e.preventDefault();

		if (!option.disabled) {
			option.onClick();
		}
	}

	render() {
		return (
			<div className="canvas-scheduling-action-menu" ref={div => (this.wrapperDivRef = div)}>
				<button
					id={'actions-btn'}
					data-cy="actions-menu-btn"
					className={
						'actions' + (this.props.expanded ? ' expanded' : '') + (this.props.useWhiteIcon ? ' white-icon' : '')
					}
					aria-haspopup="menu"
					aria-expanded={this.props.expanded}
					onBlur={this.handleActionsBlur.bind(this)}
					onKeyDown={this.handleActionsKeyPress.bind(this)}
					ref={button => (this.actionsButtonRef = button)}
					onClick={this.props.onExpansionToggle}
				/>
				{this.props.expanded ? (
					<ul
						className={
							'actions-dropdown expanded' +
							(this.state.expandUp ? ' expand-up' : ' expand-down') +
							(this.props.whiteInner ? ' white' : '')
						}
						aria-hidden={false}
						aria-labelledby="actions-btn"
						role="menu"
						ref={ul => (this.actionsDropdownRef = ul)}
					>
						{this.props.options.map((option, index) => (
							<ForecastTooltip
								key={index}
								disabled={!option.tooltip}
								content={option.tooltip}
								placement={'bottom'}
								maxWidth={300}
							>
								<li
									ref={li => (this['actionsDropdownOptionRef' + index] = li)}
									data-cy={option.cy}
									className={`actions-option ${option.disabled && 'action-menu-disabled'}`}
									role="menuitem"
									tabIndex="-1"
									onClick={e => this.handleOptionClick(e, option)}
									onBlur={this.handleActionsBlur.bind(this)}
									onKeyDown={this.handleActionsKeyPress.bind(this)}
									onMouseEnter={this.focusHovered.bind(this, index)}
								>
									<span>{option.text}</span>
								</li>
							</ForecastTooltip>
						))}
					</ul>
				) : null}
			</div>
		);
	}
}

ActionMenu.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
		})
	).isRequired,
	useWhiteIcon: PropTypes.bool,
};

export default ActionMenu;
