import React from 'react';
import ForecastQueryRenderer from '../../ForecastQueryRenderer';

export const LoadMore = ({query, loader, children, variables, alwaysRender, ...props}) => {
	return (
		<ForecastQueryRenderer
			query={query}
			showLoader={false}
			variables={variables}
			alwaysRender={alwaysRender}
			render={(relayProps, retry) => {
				return React.cloneElement(loader, {retry, ...relayProps, ...props}, children);
			}}
		/>
	);
};
