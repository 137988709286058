import React from 'react';
import PersonLoader, {personLoaderQuery} from './PersonLoader';
import ResourceNumbersLoader, {resourceNumbersBreakdownLoaderQuery} from './ResourceNumbersBreakdownLoader';
import {LoadMore} from '../../../loaders/LoadMore';
import {filtersToSearchQuery} from '../UtilizationReportUtils';
import {HexagonText, Table} from 'web-components';
import PeriodDownload, {periodDownloadQuery} from './PeriodDownload';
import PeriodRow from './PeriodRow';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import ProgramHeaderColumn from '../components/ProgramHeaderColumn';
import ProjectHeaderColumn from '../components/ProjectHeaderColumn';
import ConnectedProjectHeaderColumn from '../components/ConnectedProjectHeaderColumn';
import {profilePicSrc} from '../../../../directApi';

const PeriodPersonRows = ({
	filters,
	startDate,
	endDate,
	setCsvDownloadFunction,
	pageSize,
	roleId,
	departmentId,
	levelProps,
	boolEyeOptions,
	allocationControlsOptions,
}) => {
	const addedFilters = [];
	if (roleId) {
		addedFilters.push({key: 'role', value: roleId});
	}
	if (departmentId) {
		addedFilters.push({key: 'department', value: departmentId});
	}

	return (
		<LoadMore
			key="query-render-person"
			query={personLoaderQuery}
			variables={{
				pageSize,
				searchQuery: filtersToSearchQuery(filters, addedFilters),
				startYear: startDate ? startDate.year() : undefined,
				startMonth: startDate ? startDate.month() + 1 : undefined,
				startDay: startDate ? startDate.date() : undefined,
				endYear: endDate ? endDate.year() : undefined,
				endMonth: endDate ? endDate.month() + 1 : undefined,
				endDay: endDate ? endDate.date() : undefined,
			}}
			loader={<PersonLoader />}
		>
			{({data, loadMore, projectToProgramMap}) => {
				if (setCsvDownloadFunction) {
					setCsvDownloadFunction(() => {
						showModal({
							type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
							componentName: 'utilization_report_period_person_csv_loader',
							query: periodDownloadQuery,
							variables: {
								pageSize: 1000000,
								searchQuery: filtersToSearchQuery(filters, addedFilters),
								startYear: startDate ? startDate.year() : undefined,
								startMonth: startDate ? startDate.month() + 1 : undefined,
								startDay: startDate ? startDate.date() : undefined,
								endYear: endDate ? endDate.year() : undefined,
								endMonth: endDate ? endDate.month() + 1 : undefined,
								endDay: endDate ? endDate.date() : undefined,
							},
							createCsvDownload: <PeriodDownload />,
							startDate,
							endDate,
							boolEyeOptions,
							allocationControlsOptions,
						});
					});
				}
				return (
					<Table.Rows data={data} canExpand={true} loadMore={loadMore} autoload={true} {...levelProps}>
						{({rowData, tableColumnsProps, nextLevelProps: breakdownLevelProps, expanded}) => {
							return (
								<>
									<PeriodRow
										data={rowData}
										resourceData={rowData.resourceNumbers}
										tableColumnProps={tableColumnsProps}
										headerColumn={
											<HexagonText
												key={rowData.person.id}
												text={`${rowData.person.firstName} ${rowData.person.lastName}`}
												imgUrl={profilePicSrc(rowData.person.profilePictureId)}
											/>
										}
										canExpandRow={true}
										allocationControlsOptions={allocationControlsOptions}
										isPersonRow={true}
									/>
									{expanded && (
										<LoadMore
											key="query-render-resource-numbers-breakdown"
											query={resourceNumbersBreakdownLoaderQuery}
											loader={<ResourceNumbersLoader projectToProgramMap={projectToProgramMap} />}
											variables={{
												count: 1000,
												cursor: null,
												personId: rowData.person.id,
												startYear: startDate ? startDate.year() : undefined,
												startMonth: startDate ? startDate.month() + 1 : undefined,
												startDay: startDate ? startDate.date() : undefined,
												endYear: endDate ? endDate.year() : undefined,
												endMonth: endDate ? endDate.month() + 1 : undefined,
												endDay: endDate ? endDate.date() : undefined,
												searchQuery: filtersToSearchQuery(filters, addedFilters),
											}}
										>
											{({data: breakdownData}) => (
												<Table.Rows data={breakdownData} canExpand={true} {...breakdownLevelProps}>
													{({
														rowData: breakdownRowData,
														tableColumnsProps: breakdownTableColumnsProps,
														expanded,
														nextLevelProps: nestedBreakDownLevelProps,
													}) => {
														return (
															<>
																<PeriodRow
																	data={breakdownRowData}
																	resourceData={breakdownRowData.resourceNumbers}
																	tableColumnProps={breakdownTableColumnsProps}
																	allocationControlsOptions={allocationControlsOptions}
																	headerColumn={
																		breakdownRowData.project ? (
																			<ProjectHeaderColumn
																				project={breakdownRowData.project}
																			/>
																		) : breakdownRowData.projectGroup ? (
																			<ConnectedProjectHeaderColumn
																				projectGroup={breakdownRowData.projectGroup}
																			/>
																		) : breakdownRowData.program ? (
																			<ProgramHeaderColumn
																				program={breakdownRowData.program}
																			/>
																		) : breakdownRowData.idleTime ? (
																			breakdownRowData.idleTime.name
																		) : null
																	}
																/>
																{expanded && (
																	<Table.Rows
																		data={breakdownRowData}
																		{...nestedBreakDownLevelProps}
																	>
																		{({
																			rowData: breakdownNestedRowData,
																			tableColumnsProps: breakdownNestedTableColumnsProps,
																		}) => {
																			return (
																				<PeriodRow
																					data={breakdownNestedRowData}
																					resourceData={
																						breakdownNestedRowData.resourceNumbers
																					}
																					tableColumnProps={
																						breakdownNestedTableColumnsProps
																					}
																					allocationControlsOptions={
																						allocationControlsOptions
																					}
																					headerColumn={
																						breakdownNestedRowData.project ? (
																							<ProjectHeaderColumn
																								project={
																									breakdownNestedRowData.project
																								}
																							/>
																						) : null
																					}
																				/>
																			);
																		}}
																	</Table.Rows>
																)}
															</>
														);
													}}
												</Table.Rows>
											)}
										</LoadMore>
									)}
								</>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default PeriodPersonRows;
