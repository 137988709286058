import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {ChartWrapper} from '../work_and_expenses/Chart.styled';
import ForecastQueryRenderer from '../../../../../../ForecastQueryRenderer';
import InvoiceViewChart, {invoiceChartsQuery} from './charts/InvoicedPaidChart';
import InlineLoader from '../../../../../../forecast-app/shared/components/inline-loader/inline_loader';
import {getProjectDatesOrDefault} from '../../util/ChartsUtil';

const InvoiceChart = ({project, selectedAggregateLevel, eyeOptionMap}) => {
	const {
		projectFirstDateYear,
		projectFirstDateMonth,
		projectFirstDateDay,
		projectLastDateYear,
		projectLastDateMonth,
		projectLastDateDay,
	} = getProjectDatesOrDefault(project);
	return (
		<>
			<ChartWrapper>
				<ForecastQueryRenderer
					query={invoiceChartsQuery}
					variables={{
						projectId: project.id,
						startYear: projectFirstDateYear,
						startMonth: projectFirstDateMonth,
						startDay: projectFirstDateDay,
						endYear: projectLastDateYear,
						endMonth: projectLastDateMonth,
						endDay: projectLastDateDay,
					}}
					authorizeAccessRoute={'project-budget'}
					customLoader={() => <InlineLoader />}
					render={relayProps => {
						return (
							<InvoiceViewChart
								key={'InvoiceViewChart'}
								{...relayProps}
								aggregateLevel={selectedAggregateLevel}
								eyeOptionMap={eyeOptionMap}
							/>
						);
					}}
				/>
			</ChartWrapper>
		</>
	);
};

export default createFragmentContainer(InvoiceChart, {
	project: graphql`
		fragment InvoiceChart_project on ProjectType {
			id
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
		}
	`,
});
