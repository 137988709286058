import {SOCKET_EVENT_TYPE, SOCKET_ACTION} from '../../constants';

export const getSocketConfig = projectId => {
	return [
		{
			type: SOCKET_EVENT_TYPE.PROJECT,
			action: SOCKET_ACTION.DELETE,
			projectIds: projectId,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectId,
		},
		{
			type: SOCKET_EVENT_TYPE.PROJECT_FINANCIALS,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectId,
		},
		{
			type: SOCKET_EVENT_TYPE.PHASE,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectId,
		},
		{
			type: SOCKET_EVENT_TYPE.PHASE,
			action: SOCKET_ACTION.CREATE,
			projectIds: projectId,
		},
		{
			type: SOCKET_EVENT_TYPE.PHASE,
			action: SOCKET_ACTION.DELETE,
			projectIds: projectId,
		},
		{
			type: SOCKET_EVENT_TYPE.BASELINE_ITEM,
			action: SOCKET_ACTION.CREATE,
			projectIds: projectId,
		},
		{
			type: SOCKET_EVENT_TYPE.BASELINE_ITEM,
			action: SOCKET_ACTION.UPDATE,
			projectIds: projectId,
		},
		{
			type: SOCKET_EVENT_TYPE.BASELINE_ITEM,
			action: SOCKET_ACTION.DELETE,
			projectIds: projectId,
		},
	];
};
