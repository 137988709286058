import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import DetailsLoader, {detailsLoaderQuery} from '../loaders/DetailsLoader';
import {getTotalsParams, TABLE_TYPE} from '../util/BudgetUtils';
import {LoadMore} from '../../../../loaders/LoadMore';
import DetailsTable from '../money/work_and_expenses/DetailsTable';
import {BudgetEyeOptions} from '../BudgetPage.EyeOptions';
import {BudgetTooltips} from '../util/BudgetTooltips';
import {BUDGET_VIEWS} from '../../../../../constants';
import {useRemappingFormatMessage} from '../../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';

const TimeDetails = ({company, project, viewer, selectedAggregateLevel, eyeOptionMap}) => {
	const formatMessage = useRemappingFormatMessage();
	const aggregates = [
		'baselineMinutes',
		'baselineVsRegisteredMinutes',
		'baselineVsTotalTimeAtCompletion',
		'registeredMinutes',
		'billableActualMinutes',
		'nonBillableActualMinutes',
		'scopeApprovedMinutes',
		'scopeApprovedVsRegisteredMinutes',
		'forecastTimeToComplete',
		'totalTimeAtCompletion',
		'scopeApprovedVsTotalTimeAtCompletion',
	];
	const settings = project.financialSourceSettings;
	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');
	return (
		<>
			<LoadMore
				key="time-query-render-details"
				query={detailsLoaderQuery}
				variables={{
					projectId: project.id,
					startYear: project.projectFirstDateYear,
					startMonth: project.projectFirstDateMonth,
					startDay: project.projectFirstDateDay,
					endYear: project.projectLastDateYear,
					endMonth: project.projectLastDateMonth,
					endDay: project.projectLastDateDay,
					aggregateLevel: selectedAggregateLevel,
					aggregates: aggregates,
					...getTotalsParams(aggregates),
				}}
				loader={<DetailsLoader aggregateLevel={selectedAggregateLevel} aggregates={aggregates} />}
			>
				{({data}) => {
					return (
						<>
							{eyeOptionMap[BudgetEyeOptions.BASELINE] && (
								<DetailsTable
									key={`time-baseline-${selectedAggregateLevel}`}
									cy={'details-baseline'}
									title={formatMessage({id: 'project_budget.baseline'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={['baselineMinutes']}
									tableType={TABLE_TYPE.TIME_DETAILS}
									data={data}
									tooltips={BudgetTooltips.BaselineTooltips(formatMessage, BUDGET_VIEWS.TIME)}
								/>
							)}
							{eyeOptionMap[BudgetEyeOptions.PLAN] && (
								<DetailsTable
									key={`time-plan-${selectedAggregateLevel}`}
									cy={'details-plan'}
									title={formatMessage({id: 'project_budget.plan'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={['scopeApprovedMinutes']}
									tableType={TABLE_TYPE.TIME_DETAILS}
									data={data}
									tooltips={BudgetTooltips.PlanTooltips(
										formatMessage,
										BUDGET_VIEWS.TIME,
										null,
										settings,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
							{eyeOptionMap[BudgetEyeOptions.ACTUAL] && (
								<DetailsTable
									key={`time-actual-${selectedAggregateLevel}`}
									cy={'details-actual'}
									title={formatMessage({id: 'project_budget.actual'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={['registeredMinutes', 'billableActualMinutes', 'nonBillableActualMinutes']}
									tableType={TABLE_TYPE.TIME_DETAILS}
									data={data}
									tooltips={BudgetTooltips.ActualTooltips(
										formatMessage,
										BUDGET_VIEWS.TIME,
										null,
										settings,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
							{eyeOptionMap[BudgetEyeOptions.REMAINING] && (
								<DetailsTable
									key={`time-remaining-${selectedAggregateLevel}`}
									cy={'details-forecast_to_complete'}
									title={formatMessage({id: 'project_budget.forecast_to_complete'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={['forecastTimeToComplete']}
									tableType={TABLE_TYPE.TIME_DETAILS}
									data={data}
									tooltips={BudgetTooltips.RemainingTooltips(
										formatMessage,
										BUDGET_VIEWS.TIME,
										null,
										settings,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
							{eyeOptionMap[BudgetEyeOptions.FORECAST] && (
								<DetailsTable
									key={`time-forecast-${selectedAggregateLevel}`}
									cy={'details-total_at_completion'}
									title={formatMessage({id: 'project_budget.total_at_completion'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={['totalTimeAtCompletion']}
									tableType={TABLE_TYPE.TIME_DETAILS}
									data={data}
									tooltips={BudgetTooltips.ForecastTooltips(
										formatMessage,
										BUDGET_VIEWS.TIME,
										settings,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
							{eyeOptionMap[BudgetEyeOptions.BASELINE_VS_ACTUAL] && (
								<DetailsTable
									key={`time-baseline-vs-actual-${selectedAggregateLevel}`}
									cy={'details-baseline_vs_actual'}
									title={formatMessage({id: 'project_budget.baseline_vs_actual'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={['baselineVsRegisteredMinutes']}
									tableType={TABLE_TYPE.TIME_DETAILS}
									data={data}
									tooltips={BudgetTooltips.BaselineVsActualTooltips(
										formatMessage,
										BUDGET_VIEWS.TIME,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
							{eyeOptionMap[BudgetEyeOptions.BASELINE_VS_FORECAST] && (
								<DetailsTable
									key={`time-baseline-vs-forecast-${selectedAggregateLevel}`}
									cy={'details-baseline_vs_forecast'}
									title={formatMessage({id: 'project_budget.baseline_vs_forecast'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={['baselineVsTotalTimeAtCompletion']}
									tableType={TABLE_TYPE.TIME_DETAILS}
									data={data}
									tooltips={BudgetTooltips.BaselineVsForecastTooltips(
										formatMessage,
										BUDGET_VIEWS.TIME,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
							{eyeOptionMap[BudgetEyeOptions.PLAN_VS_ACTUAL] && (
								<DetailsTable
									key={`time-plan-vs-actual-${selectedAggregateLevel}`}
									cy={'details-plan_vs_actual'}
									title={formatMessage({id: 'project_budget.plan_vs_actual'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={['scopeApprovedVsRegisteredMinutes']}
									tableType={TABLE_TYPE.TIME_DETAILS}
									data={data}
									tooltips={BudgetTooltips.PlanVsActualTooltips(
										formatMessage,
										BUDGET_VIEWS.TIME,
										false,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
							{eyeOptionMap[BudgetEyeOptions.PLAN_VS_FORECAST] && (
								<DetailsTable
									key={`time-plan-vs-forecast-${selectedAggregateLevel}`}
									cy={'details-planned_vs_total_at_completion'}
									title={formatMessage({id: 'project_budget.planned_vs_total_at_completion'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={['scopeApprovedVsTotalTimeAtCompletion']}
									tableType={TABLE_TYPE.TIME_DETAILS}
									data={data}
									tooltips={BudgetTooltips.PlanVsForecastTooltips(
										formatMessage,
										BUDGET_VIEWS.TIME,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
						</>
					);
				}}
			</LoadMore>
		</>
	);
};

export default createFragmentContainer(TimeDetails, {
	project: graphql`
		fragment TimeDetails_project on ProjectType {
			id
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
			...DetailsTable_project
			financialSourceSettings {
				plannedHours
				plannedRevenue
				plannedCost
				forecastHours
				forecastRevenue
				forecastCost
				actualHours
				actualRevenue
				actualCost
			}
		}
	`,
});
