import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {
	areItemDatesValid,
	createCanvasTimelineDate,
	getVisualizationMode,
	isProjectDoneOrHalted,
	ITEM_TYPE,
	useItemsLazyLoading,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import {isProjectFilteredCached} from '../FilterUtils';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import TaskItem from '../components/items/task_item';
import ComposeManager from '../ComposeManager';
import IDManager, {NO_ROLE} from '../IDManager';
import {GROUP_BY} from './people_scheduling_header';
import TotalResourceUtilizationGroup from '../components/groups/total_resource_utilization_group';
import DataManager from '../DataManager';
import ProjectGroup from '../components/groups/project_group';
import NonProjectTimeGroup from '../components/groups/non_project_time_group';
import PersonAllocationsGroup from '../components/groups/person_allocations_group';
import NoContentGroup from '../components/groups/no_content_group';
import PersonGroup from '../components/groups/person_group';
import PersonGroupingGroup from '../components/groups/PersonGroupingGroup';
import ProjectAllocationItem from '../components/items/project_allocation_item';

export const getProjectIdFromProjectGroup = projectGroup => {
	return projectGroup?.id.split('-')[1];
};

const getTaskFilterData = function (pageComponent, task) {
	// maps data to match the structure the filter expects
	const statusColumn = pageComponent.getFilterData().statusColumnMap.get(task.statusColumnId);
	const ownerId = task.ownerId ? task.ownerId : null;
	const taskLabelsOnTask = task.taskLabels || [];
	const taskLabels = taskLabelsOnTask.map(label => ({label: {id: label.labelId}}));
	const followers = task.followers ? task.followers.map(fId => ({id: fId})) : [];

	const taskFilterData = {
		role: {
			id: task.roleId,
		},
		statusColumnV2: {
			category: statusColumn && statusColumn.category ? statusColumn.category : null,
		},
		project: {
			id: task.projectId,
		},
		owner: {id: ownerId},
		followers,
		taskLabels,
		bug: task.bug,
		blocked: task.blocked,
		hasDependency: task.hasDependency,
		highPriority: task.highPriority,
		billable: task.billable,
		favoured: task.favoured,
	};

	if (!useItemsLazyLoading(pageComponent)) {
		taskFilterData.latestUiUpdateAt = task.latestUiUpdateAt;
	}

	return taskFilterData;
};

export const getPersonGroupVisibleItemTypes = pageComponent => {
	const {company} = pageComponent.state.data;
	const {schedulingOptions} = pageComponent.state;
	const {isProjectTimeline} = pageComponent.props;
	let validHeatmapItemTypes = new Set();

	validHeatmapItemTypes.add(ITEM_TYPE.PROJECT_ALLOCATION);

	const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);
	const isInCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

	const combinedHeatmapOnTimeline =
		isInCombinedMode && isProjectTimeline && hasFeatureFlag('combined_heatmap_logic_extensions');
	if (!isUsingProjectAllocation && (!isInCombinedMode || combinedHeatmapOnTimeline)) {
		validHeatmapItemTypes.add(ITEM_TYPE.TASK);
	}

	return validHeatmapItemTypes;
};

export const getProjectGroupVisibleItemTypes = pageComponent => {
	const {schedulingOptions} = pageComponent.state;
	const {company} = pageComponent.getFilterData();
	let validHeatmapItemTypes = new Set();

	if (getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION)) {
		validHeatmapItemTypes.add(ITEM_TYPE.PROJECT_ALLOCATION);
		validHeatmapItemTypes.add(ITEM_TYPE.TASK);
	}

	return validHeatmapItemTypes;
};

export const getUnassignedCountMap = pageComponent => {
	const {data, filterFunctions} = pageComponent.state;
	const roleTaskCountMap = new Map();
	if (!data) {
		return roleTaskCountMap;
	}

	const hasProjectsReadAll =
		hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) ||
		(hasFeatureFlag('scheduling_read_only_permissions') && hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL_VIEW_ONLY));
	const haltedOrDoneProjectIds = new Set(
		data.projects.filter(project => isProjectDoneOrHalted(project.status)).map(project => project.id)
	);
	const ownProjectIds = hasProjectsReadAll
		? null
		: new Set(data.projectPersonByPersonMap[data.viewer.actualPersonId]?.map(projectPerson => projectPerson.projectId));

	const projectFilteredCache = new Map();
	const isFiltered = task =>
		isProjectFilteredCached(pageComponent, projectFilteredCache, task.projectId) ||
		!filterFunctions.taskFilter(getTaskFilterData(pageComponent, task));

	// filter the tasks that are not assigned to anybody, not done and that belong to a project that is not HALTED or DONE
	let totalCount = 0;
	data.tasks.forEach(task => {
		if (
			task.startYear != null &&
			task.deadlineYear != null &&
			task.assignedPersons.length === 0 &&
			task.done === false &&
			!haltedOrDoneProjectIds.has(task.projectId) &&
			(hasProjectsReadAll || ownProjectIds.has(task.projectId)) &&
			!isFiltered(task)
		) {
			totalCount++;
			const mapKey = task.roleId || NO_ROLE;
			const taskCount = (roleTaskCountMap.get(mapKey) || 0) + 1;
			roleTaskCountMap.set(mapKey, taskCount);
		}
	});
	roleTaskCountMap.set(null, totalCount);
	return roleTaskCountMap;
};

export const createTaskItems = (pageComponent, data) => {
	const taskItems = [];

	if (!data) {
		return taskItems;
	}

	const {schedulingOptions} = pageComponent.state;
	const isCombinedMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);

	let cachedOwnProjectIds;
	const hasProjectAccess = projectId => {
		if (
			hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) ||
			(hasFeatureFlag('scheduling_read_only_permissions') && hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL_VIEW_ONLY))
		) {
			return true;
		}

		if (!cachedOwnProjectIds) {
			cachedOwnProjectIds = new Set(
				data.projectPersonByPersonMap[data.viewer.actualPersonId]?.map(projectPerson => projectPerson.projectId)
			);
		}

		return cachedOwnProjectIds.has(projectId);
	};

	for (const task of data.tasks) {
		if (!task.approved) {
			continue;
		}

		if (Array.isArray(task.assignedPersons)) {
			const startDate = createCanvasTimelineDate(task.startYear, task.startMonth, task.startDay);
			const endDate = createCanvasTimelineDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay);

			for (const assignedPersonId of task.assignedPersons) {
				// if no dates create each time reg on task as separate item else include in task item
				if (areItemDatesValid(startDate, endDate)) {
					const taskItemData = ComposeManager.composeTaskItem(pageComponent, task, assignedPersonId);

					if (!taskItemData) {
						continue;
					}

					taskItems.push(new TaskItem(pageComponent, taskItemData));
				}
			}
		}

		//if no ppl assigned add item to the appropriate unassigned tasks role group
		if (!task.assignedPersons || task.assignedPersons.length === 0) {
			if (isCombinedMode) {
				continue;
			}

			const taskProject = data.projectMap.get(task.projectId);
			const isTaskValid = task.startYear && task.deadlineYear && !task.done;

			if (isTaskValid && !isProjectDoneOrHalted(taskProject.status) && hasProjectAccess(task.projectId)) {
				const taskItemData = ComposeManager.composeTaskItem(pageComponent, task, null);

				if (!taskItemData) {
					continue;
				}

				taskItems.push(new TaskItem(pageComponent, taskItemData));
			}
		}
	}

	return taskItems;
};

/*
 * The main function. This processes the props and returns the groups and items.
 */
export const getGroupsAndItemsFromProps = (pageComponent, props, data) => {
	const {viewer, company} = data;
	const {groupBy, schedulingOptions} = pageComponent.state;

	const groupByRole = groupBy === GROUP_BY.ROLE;
	const groupByDepartment = groupBy === GROUP_BY.DEPARTMENT;

	const isCombinationMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
	const isAllocationMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);

	const roleGroupsByRoleId = new Map();
	const firstLevelGroups = [];
	let items = [];

	const persons = data.persons.sort((a, b) => {
		const aName = `${a.firstName || ''} ${a.lastName || ''}`.toLowerCase();
		const bName = `${b.firstName || ''} ${b.lastName || ''}`.toLowerCase();
		if (aName < bName) return -1;
		if (bName < aName) return 1;
		return 0;
	});

	if (pageComponent.viewCompanySchedule) {
		firstLevelGroups.push(
			new TotalResourceUtilizationGroup(pageComponent, ComposeManager.composeTotalResourceUtilizationGroup(pageComponent))
		);
	}

	for (const person of persons) {
		// only show the viewer if a person is a collaborator
		if (!pageComponent.viewCompanySchedule && person.id !== viewer.actualPersonId) continue;

		// do not show client users and inactive people
		if (person.clientId || !person.active) continue;

		const personGroupIds = [IDManager.getNonProjectTimeGroupId(pageComponent, person.id)];

		const projectPersons = DataManager.getProjectPersonsByPersonId(pageComponent, person.id);
		const projectIds = new Set();
		const projectGroupIds = new Set();

		const projectGroups = [];
		for (const projectPerson of projectPersons) {
			const project = DataManager.getProjectById(pageComponent, projectPerson.projectId);

			if (!project) {
				continue;
			}

			projectIds.add(project.id);

			// person group ids
			personGroupIds.push(`${person.id}-${project.id}`);
			personGroupIds.push(`${person.id}-${project.id}-task`);

			if (project.isInProjectGroup) {
				projectGroupIds.add(project.projectGroupId);
				continue;
			}

			const projectGroup = new ProjectGroup(
				pageComponent,
				ComposeManager.composeProjectGroup(pageComponent, person, null, project, null)
			);
			projectGroups.push(projectGroup);
		}

		for (const projectGroupId of projectGroupIds) {
			const projectGroup = DataManager.getProjectGroupById(pageComponent, projectGroupId);

			// person group ids
			personGroupIds.push(`${person.id}-${projectGroupId}`);
			personGroupIds.push(`${person.id}-${projectGroupId}-task`);

			const projectGroupGroup = new ProjectGroup(
				pageComponent,
				ComposeManager.composeProjectGroup(pageComponent, person, null, null, projectGroup)
			);
			projectGroups.push(projectGroupGroup);
		}

		projectGroups.push(
			new NonProjectTimeGroup(pageComponent, ComposeManager.composeNonProjectTimeGroup(pageComponent, person))
		);

		// Allocation group used when not grouping by project, shows all project and non-project allocations for person
		projectGroups.push(
			new PersonAllocationsGroup(pageComponent, ComposeManager.composePersonAllocationsGroup(pageComponent, person))
		);

		projectGroups.push(
			new NoContentGroup(pageComponent, ComposeManager.composeNoContentGroup(pageComponent, person, null, null))
		);

		const personGroup = new PersonGroup(
			pageComponent,
			ComposeManager.composePersonGroup(pageComponent, person, null, projectGroups, personGroupIds)
		);

		if (groupByRole || groupByDepartment) {
			personGroup.marginX = 16;
			for (const child of personGroup.groups) {
				child.marginX = 32;
			}

			const groupId = IDManager.getPersonGroupingGroupId(pageComponent, person);
			if (!roleGroupsByRoleId.get(groupId)) {
				const personRoleGroup = new PersonGroupingGroup(
					pageComponent,
					ComposeManager.composePersonGroupingGroup(pageComponent, person, [personGroup])
				);
				roleGroupsByRoleId.set(groupId, personRoleGroup);
			} else {
				roleGroupsByRoleId.get(groupId).addChildGroup(personGroup);
			}
		} else {
			firstLevelGroups.push(personGroup);
		}

		// creating allocation items
		const allocations = DataManager.getAllocationsByPersonId(pageComponent, person.id);

		if (allocations) {
			for (const allocation of allocations) {
				const allocationItemData = ComposeManager.composeProjectAllocation(pageComponent, allocation);

				if (allocationItemData && (allocation.idleTimeId || isAllocationMode || isCombinationMode)) {
					const projectAllocationItem = new ProjectAllocationItem(pageComponent, allocationItemData);

					if (projectAllocationItem.isValid()) {
						items.push(projectAllocationItem);
					}
				}
			}
		}
	}

	roleGroupsByRoleId.forEach(roleGroup => {
		firstLevelGroups.push(roleGroup);
	});

	firstLevelGroups.sort((a, b) => {
		const aName = (a.data.name || '').toLowerCase();
		const bName = (b.data.name || '').toLowerCase();
		if (aName < bName) {
			return -1;
		}
		if (bName < aName) {
			return 1;
		}
		return 0;
	});

	// add task items
	if (!isAllocationMode) {
		items = items.concat(createTaskItems(pageComponent, data));
	}

	return {groups: firstLevelGroups, items};
};
