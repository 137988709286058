import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {DropdownWrapperStyled, RoleRow as RoleRowStyle, RoleRowItem, IconWrapper} from './InitialPlan.styled';
import ActionsMenu from '../../forecast-app/shared/components/action-menu/actions_menu';
import {changeRoleRoleRow, deleteBaselineRole, editBaselineRoleEstimate} from './InitialPlanLogic';
import {ValueCell} from './ValueCell';
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {AffixedInput, Dropdown} from 'web-components';
import {DeprecatedIcon} from '@forecast-it/design-system';
import ForecastTooltip from '../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {FormattedHTMLMessage} from 'react-intl';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';

const RoleRow = ({
	intl,
	minutesPerDay,
	phaseBaselineRole,
	projectId,
	currencySymbol,
	enabledColumnEntries,
	theEyeOptions,
	roles,
	disabledRoleIds,
	roleFinancials,
	rateCardUtil,
	phaseStartDate,
	phaseDeadlineDate,
}) => {
	const estimateMinutesInput = phaseBaselineRole.baselineMinutes;
	const enabledRoles = roles.filter(role => !disabledRoleIds.includes(role.node.id));
	const isSelectedRoleDisabled = disabledRoleIds.includes(phaseBaselineRole.role.id);

	const handleEditEstimate = value => {
		if (value * 60 !== phaseBaselineRole.baselineMinutes && value >= 0) {
			editBaselineRoleEstimate(phaseBaselineRole.id, value * 60);
		}
	};

	const deleteRole = () => {
		deleteBaselineRole(phaseBaselineRole.id, projectId);
	};

	const actionsMenuOptions = [
		{
			text: intl.formatMessage({id: 'common.delete'}),
			onClick: deleteRole.bind(this),
			cy: 'delete-btn',
		},
	];

	const getElemProps = value => {
		return theEyeOptions[value - 1];
	};

	const getContentForRoleCol = column => {
		switch (column) {
			case 'estimate':
				if (minutesPerDay !== null) {
					return (
						<AffixedInput
							value={Math.round((estimateMinutesInput / minutesPerDay) * 100) / 100}
							callback={value => handleEditEstimate((value * minutesPerDay) / 60)}
							affix={` ${intl
								.formatMessage({
									id:
										Math.abs(estimateMinutesInput / minutesPerDay) <= 1 &&
										estimateMinutesInput / minutesPerDay !== 0
											? 'overview_time.day'
											: 'common.days',
								})
								.toLowerCase()}`}
						/>
					);
				} else {
					return (
						<HoursInput
							value={estimateMinutesInput / 60}
							mutation={value => handleEditEstimate(value)}
							mutationValidChange
						/>
					);
				}
			case 'ratePH':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={
							hasFeatureFlag('baseline_financial_service')
								? roleFinancials && phaseBaselineRole.baselineMinutes
									? roleFinancials.baselineRatePerHour
									: undefined
								: phaseBaselineRole.baselineRatePerHour || 0
						}
						currencySymbol={currencySymbol}
					></ValueCell>
				);
			case 'value_of_service':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={roleFinancials ? roleFinancials.baselineTimeAndExpenses || 0 : 0}
						currencySymbol={currencySymbol}
					></ValueCell>
				);
			case 'revenue':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={
							hasFeatureFlag('baseline_financial_service')
								? roleFinancials
									? roleFinancials.baselineRevenue || 0
									: 0
								: phaseBaselineRole.baselinePrice || 0
						}
						currencySymbol={currencySymbol}
					></ValueCell>
				);
			case 'costPH':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={
							hasFeatureFlag('baseline_financial_service')
								? roleFinancials && phaseBaselineRole.baselineMinutes
									? roleFinancials.baselineCostPerHour
									: undefined
								: phaseBaselineRole.baselineCostPerHour || 0
						}
						currencySymbol={currencySymbol}
					></ValueCell>
				);
			case 'cost':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={
							hasFeatureFlag('baseline_financial_service')
								? roleFinancials
									? roleFinancials.baselineCost || 0
									: 0
								: phaseBaselineRole.baselineCost || 0
						}
						currencySymbol={currencySymbol}
					></ValueCell>
				);
			case 'profit':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={
							hasFeatureFlag('baseline_financial_service')
								? roleFinancials
									? roleFinancials.baselineProfit || 0
									: 0
								: phaseBaselineRole.baselineProfit || 0
						}
						currencySymbol={currencySymbol}
					></ValueCell>
				);
			case 'margin':
				// Calculate margin
				let margin = hasFeatureFlag('baseline_financial_service')
					? roleFinancials
						? roleFinancials.baselineMargin
						: 0.0
					: phaseBaselineRole.baselineProfit / phaseBaselineRole.baselinePrice;

				// Ensure pretty format
				if (isNaN(margin) || !isFinite(margin)) {
					margin = 0;
				}
				return <ValueCell type={ValueCell.VALUE_TYPE.PERCENTAGE} value={margin}></ValueCell>;
			default:
				return null;
		}
	};

	const getElemForRoleCol = (column, value, index) => {
		if (!value) return null;
		const elemProps = getElemProps(value);
		return (
			<RoleRowItem key={index} align={elemProps.align} width={elemProps.width}>
				{getContentForRoleCol(column, value)}
			</RoleRowItem>
		);
	};

	const handleChangeRole = roleId => {
		changeRoleRoleRow(phaseBaselineRole.id, roleId[0]);
	};

	const getRoleOptions = (roles, selectedRole) => {
		const options = roles.map(option => {
			return (
				<Dropdown.SingleText key={option.node.id} value={option.node.id} searchString={option.node.name}>
					{option.node.name}
				</Dropdown.SingleText>
			);
		});
		if (isSelectedRoleDisabled) {
			options.push(
				<Dropdown.SingleText
					key={selectedRole.id}
					value={selectedRole.id}
					searchString={selectedRole.name}
					disabled={true}
				>
					{selectedRole.name}
				</Dropdown.SingleText>
			);
		}
		return options;
	};

	return (
		<RoleRowStyle data-cy="role-row">
			<RoleRowItem width={220} growable>
				<DropdownWrapperStyled>
					<Dropdown
						name={intl.formatMessage({id: 'common.select_role'})}
						selectedItems={[phaseBaselineRole.role.id]}
						onSelect={handleChangeRole}
						usePortal
						cy={'baseline-role-dropdown-button'}
					>
						{getRoleOptions(enabledRoles, phaseBaselineRole.role)}
					</Dropdown>
					{isSelectedRoleDisabled && (
						<ForecastTooltip content={<FormattedHTMLMessage id="project_person.deactivated_role" />}>
							<IconWrapper>
								<DeprecatedIcon icon="warning" size="m" color="error" />
							</IconWrapper>
						</ForecastTooltip>
					)}
				</DropdownWrapperStyled>
			</RoleRowItem>
			{enabledColumnEntries.map(([col, val], index) => getElemForRoleCol(col, val, index))}
			<RoleRowItem align="center" width={20}></RoleRowItem>
			<RoleRowItem align="center" width={20}>
				<ActionsMenu whiteInner options={actionsMenuOptions} />
			</RoleRowItem>
		</RoleRowStyle>
	);
};

export default createFragmentContainer(RoleRow, {
	phaseBaselineRole: graphql`
		fragment RoleRow_phaseBaselineRole on PhaseBaselineRoleType {
			id
			phaseId
			role {
				id
				name
			}

			baselineMinutes
			baselinePrice
			baselineRatePerHour
			baselineCost
			baselineCostPerHour
			baselineProfit
			startYear
			startMonth
			startDay
			deadlineYear
			deadlineMonth
			deadlineDay
		}
	`,
});
