import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import DatePicker from './inputs/date_picker';

const options = [
	{label: 'date_span_picker.current_month', value: 'current-month', key: 'current-month'},
	{label: 'date_span_picker.last_month', value: 'last-month', key: 'last-month'},
	{label: 'date_span_picker.last_30_days', value: 'last-30-days', key: 'last-30-days'},
	{label: 'date_span_picker.current_year', value: 'current-year', key: 'current-year'},
	{label: 'date_span_picker.date_range', value: 'custom-date-range', key: 'custom-date-range'},
];

const futureOptionsIncluded = [
	{label: 'date_span_picker.current_month', value: 'current-month', key: 'current-month'},
	{label: 'date_span_picker.last_month', value: 'last-month', key: 'last-month'},
	{label: 'date_span_picker.last_30_days', value: 'last-30-days', key: 'last-30-days'},
	{label: 'date_span_picker.current_year', value: 'current-year', key: 'current-year'},
	{label: 'date_span_picker.next_month', value: 'next-month', key: 'next-month'},
	{label: 'date_span_picker.next_30_days', value: 'next-30-days', key: 'next-30-days'},
	{label: 'date_span_picker.date_range', value: 'custom-date-range', key: 'custom-date-range'},
];

class DateSpanPicker extends Component {
	constructor(props) {
		super(props);

		let startDate = null;
		let endDate = null;
		if (this.props.value && this.props.value.key === 'custom-date-range' && this.props.value.value) {
			startDate = this.props.value.value.start;
			endDate = this.props.value.value.end;
		}

		this.state = {
			value: this.props.value,
			showOptions: false,
			startDate: startDate,
			endDate: endDate,
			showDateRange: this.props.value && this.props.value.key === 'custom-date-range',
			//merged from 'select_input'
			isInitialRender: true,
			renderOnTop: false,
			dropdownHeight: 0,
		};
	}

	//merged from 'select_input'
	componentDidUpdate(prevProps, prevState) {
		if (
			(!prevState.showOptions && this.state.showOptions) ||
			(!prevState.showDateRange && this.state.showDateRange && this.dropdown)
		) {
			const dropdownNode = this.dropdown.getBoundingClientRect();
			const windowHeight = window.innerHeight;
			this.setState({
				isInitialRender: false,
				renderOnTop:
					dropdownNode.top > 400 && dropdownNode.bottom - windowHeight > -10 && !this.props.disableRenderCalculations, //70 is to not make it end exactly at the bottom but have some space instead
				dropdownHeight: dropdownNode.height,
			});
		}
		if (prevState.showOptions && !this.state.showOptions) {
			this.setState({
				renderOnTop: false,
			});
		}
		if (prevState.inputValue !== this.state.inputValue && this.dropdown) {
			const dropdownNode = this.dropdown.getBoundingClientRect();
			this.setState({
				dropdownHeight: dropdownNode.height,
			});
		}
	}

	handleSelectOption(selected) {
		if (selected.value !== 'custom-date-range') {
			this.props.onChange(selected);
			this.setState({showOptions: false, showDateRange: false, startDate: null, endDate: null});
			this.date_span_input.blur();
		} else {
			if (this.state.startDate && this.state.endDate) {
				this.props.onChange({
					key: 'custom-date-range',
					label: 'date_span_picker.date_range',
					value: {start: this.state.startDate, end: this.state.endDate},
				});
			}
			this.setState({showDateRange: true, value: selected});
			this.date_span_input.focus();
		}
	}

	handleChangeStart(start) {
		this.setState({startDate: start});
	}

	handleChangeEnd(end) {
		this.setState({endDate: end});
	}

	handleFocus() {
		this.setState({showOptions: true});
	}

	handleClose() {
		this.setState({showOptions: false, showDateRange: false});
		this.date_span_input.blur();
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (
			(e.target && e.target.className === 'date-span-picker-container') ||
			(newTarget &&
				(newTarget.className === 'date-picker-wrapper' ||
					newTarget.className === 'date-span-picker-container' ||
					newTarget.className === 'date-span-picker' ||
					newTarget.className === 'options' ||
					newTarget.className === 'day-selected'))
		) {
			return;
		}
		if (this.state.value && this.state.value.key === 'custom-date-range' && this.state.startDate && this.state.endDate) {
			this.props.onChange({
				label: 'date_span_picker.date_range',
				value: {start: this.state.startDate, end: this.state.endDate},
				key: 'custom-date-range',
			});
			this.setState({showOptions: false, showDateRange: true});
		} else {
			this.setState({showOptions: false, showDateRange: false, value: this.props.value});
		}

		this.date_span_input.blur();
	}

	handleFocusFromClear() {
		this.props.onChange(null);
		this.setState({showOptions: false, showDateRange: false, startDate: null, endDate: null});
		this.date_span_input.focus();
	}

	render() {
		const {formatMessage} = this.props.intl;
		const key = this.props.value ? this.props.value.key : null;
		const selectOptions = this.props.showFutureOptions ? futureOptionsIncluded : options;

		//merged from 'select_input'
		const dropdownStyle = this.state.renderOnTop ? {top: 12 - this.state.dropdownHeight} : {};
		let value = '';
		if (this.props.value) {
			if (this.props.value.label === 'date_span_picker.date_range') {
				let start = '';
				let end = '';
				if (this.props.value.value.start) {
					start = this.props.value.value.start.format('l');
				}
				if (this.props.value.value.end) {
					end = this.props.value.value.end.format('l');
				}
				value = start + ' - ' + end;
			} else {
				value = formatMessage({id: this.props.value.label});
			}
		}
		return (
			<div className="date-span-picker-container" tabIndex="0" onBlur={this.handleBlur.bind(this)}>
				<input
					onChange={i => i} // Identity function to prevent console from complaining.
					className={this.state.showOptions ? 'input-focused' : 'input'}
					onFocus={this.handleFocus.bind(this)}
					value={value}
					ref={input => {
						this.date_span_input = input;
					}}
					placeholder={this.props.placeholder ? this.props.placeholder : ''}
				/>
				{this.props.value ? <div className="input-clear-icon" onClick={this.handleFocusFromClear.bind(this)} /> : null}
				{this.state.showOptions ? (
					<div className="input-icon-expanded" onClick={this.handleClose.bind(this)} />
				) : (
					<div className="input-icon-collapsed" onClick={this.handleFocus.bind(this)} />
				)}
				{this.state.showOptions ? (
					<div
						//merged from 'select_input'
						ref={e => (this.dropdown = e)}
						style={dropdownStyle}
						className={this.state.showDateRange ? 'select-range' : 'select'}
					>
						<div className="options">
							{selectOptions.map((option, index) => (
								<div
									key={index}
									className={option.key === key ? 'option-selected' : 'option'}
									onMouseDown={this.handleSelectOption.bind(this, option)}
								>
									<FormattedMessage id={option.label} />
								</div>
							))}
						</div>
						{this.state.showDateRange ? (
							<div className="date-pickers" tabIndex="0">
								<DatePicker
									inline={true}
									onConfirm={this.handleChangeStart.bind(this)}
									value={this.state.startDate}
									startDate={this.state.startDate}
									endDate={this.state.endDate}
									locale={this.props.locale ? this.props.locale : ''}
								/>
								<DatePicker
									inline={true}
									onConfirm={this.handleChangeEnd.bind(this)}
									value={this.state.endDate}
									startDate={this.state.startDate}
									endDate={this.state.endDate}
									locale={this.props.locale ? this.props.locale : ''}
								/>
							</div>
						) : null}
					</div>
				) : null}
			</div>
		);
	}
}
DateSpanPicker.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.object,
	locale: PropTypes.string,
	showFutureOptions: PropTypes.bool,
};
export default injectIntl(DateSpanPicker);
