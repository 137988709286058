import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {fetchData, fetchMapData} from '../reported_time_financials_fetch';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import {getProjectRow} from '../rows/project_row';
import BaseLevelWrapper from './BaselevelWrapper';
import {hasSomePermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {useDidObjectChange} from '../../../../forecast-app/shared/hooks/useDidObjectChange';

const projectRelayWrapper = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	timeRegRowEnabledColumns,
	nextLevelProps,
	sortValue,
}) => {
	const projects = viewer.company.allProjects.edges;
	const currency = viewer.company.currency;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const [projectsFinancialMap, setProjectsFinancialMap] = useState(null);
	const hasNextPage = viewer.company.allProjects.pageInfo.hasNextPage;
	const [nonProjectTotalMinutesRegistered, setNonProjectTotalMinutesRegistered] = useState(null);
	const [nonProjectTotalFinancials, setNonProjectTotalFinancials] = useState(null);

	const projectsChanged = useDidObjectChange(projects, null);
	const searchQueryChanged = useDidObjectChange(searchQuery, null);
	const shouldRefetchFinancials = projectsChanged || searchQueryChanged;

	useEffect(() => {
		if (shouldRefetchFinancials) {
			const projectsReducer = (accumulator, currentValue) => {
				if (currentValue.node.fullAccessToProject) accumulator.push(currentValue.node.id);
				return accumulator;
			};
			const projectsIds = projects.reduce(projectsReducer, []);
			if (
				hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				]) &&
				projectsIds.length > 0
			) {
				fetchMapData(projectsIds, searchQuery, 'total_project_time_regs_financials').then(data => {
					const projectsFinancialMap = new Map();
					Object.keys(data).forEach(key => {
						projectsFinancialMap.set(key, data[key]);
					});
					setProjectsFinancialMap(projectsFinancialMap);
				});
			}
			if (!hasNextPage) {
				fetchData(searchQuery, 'non_project_total_minutes_registered').then(data => {
					if (data.total !== undefined) {
						setNonProjectTotalMinutesRegistered(data.total);
					}
				});
				if (
					hasSomePermission([
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
					])
				) {
					fetchData(searchQuery, 'total_non_project_time_regs_financials').then(data => {
						setNonProjectTotalFinancials(data.totals);
					});
				}
			}
		}
	}, [shouldRefetchFinancials]);

	const dataRows = projects.map(project => {
		const hasAccessToProject = project.node.fullAccessToProject;
		return {
			...project.node,
			canExpand: hasAccessToProject,
			totalPrice:
				projectsFinancialMap !== null && projectsFinancialMap.has(project.node.id)
					? projectsFinancialMap.get(project.node.id).totalPrice
					: null,
			totalCost:
				projectsFinancialMap !== null && projectsFinancialMap.has(project.node.id)
					? projectsFinancialMap.get(project.node.id).totalCost
					: null,
		};
	});

	nonProjectTotalMinutesRegistered !== null &&
		dataRows.push({
			isNoProject: true,
			canExpand: true,
			name: intl.formatMessage({id: 'time_reg_report.no_project'}),
			totalMinutesRegistered: nonProjectTotalMinutesRegistered,
			totalPrice: nonProjectTotalFinancials ? nonProjectTotalFinancials.totalPrice : null,
			totalCost: nonProjectTotalFinancials ? nonProjectTotalFinancials.totalCost : null,
		});

	const data = {rows: dataRows};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = projects.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const projectRows = () => {
		return getProjectRow(
			data,
			currency,
			intl,
			groupIndex,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader
		);
	};

	return groupIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={projectRows}
			currentLength={projects.length}
			hasNextPage={viewer.company.allProjects.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		projectRows()
	);
};

const projectRelayWrapperQuery = graphql`
	query projectRelayWrapperQuery_Query($searchQuery: TaskSearchQueryType, $pageSize: Int, $validateAccess: Boolean) {
		viewer {
			actualPersonId
			component(name: "project_relay_wrapper")
			...projectRelayWrapper_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, validateAccess: $validateAccess)
		}
	}
`;
export {projectRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			projectRelayWrapper,
			{
				viewer: graphql`
					fragment projectRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						validateAccess: {type: "Boolean"}
					) {
						company {
							currency
							allProjects(first: $pageSize, searchQuery: $searchQuery, validateAccess: false) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										fullAccessToProject
										readOnlyAccess
										totalMinutesRegistered(searchQuery: $searchQuery)
										id
										name
										projectColor
										companyProjectId
										client {
											name
										}
										projectPersons {
											edges {
												node {
													role {
														name
													}
													person {
														fullName
														role {
															name
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query projectRelayWrapperRefetchQuery($pageSize: Int, $searchQuery: TaskSearchQueryType) {
					viewer {
						...projectRelayWrapper_viewer @arguments(pageSize: $pageSize, searchQuery: $searchQuery)
					}
				}
			`
		)
	)
);
