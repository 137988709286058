import React, {Component} from 'react';
import PropTypes from 'prop-types';

class TextTile extends Component {
	render() {
		return (
			<div className="text-tile">
				{this.props.additionalValue ? (
					<div style={{textAlign: this.props.textAlign}} className="first-line">
						{this.props.additionalValue}
					</div>
				) : (
					''
				)}
				<div title={this.props.value} style={{textAlign: this.props.textAlign}} className="second-line">
					{this.props.value}
				</div>
			</div>
		);
	}
}

TextTile.propTypes = {
	value: PropTypes.string,
	additionalValue: PropTypes.string,
};

export default TextTile;
