const FixedPriceRevenueTooltip = formatMessage => [
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [formatMessage({id: 'common.fixed_price'})],
	},
];

const FixedPriceCostTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.cost'}),
		details: [formatMessage({id: 'insights.component.list.column.costForecast'})],
	},
];

const FixedPriceProfitTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'common.fixed_price'}),
			'-',
			formatMessage({id: 'insights.component.list.column.costForecast'}),
		],
	},
];

const FixedPriceMarginTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.fixed_price_profit'}),
			'/',
			formatMessage({id: 'project_budget.fixed_price_revenue'}),
			'X',
			'100',
		],
	},
];

const BaselineRevenueTooltip = formatMessage => [
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [
			formatMessage({id: 'common.role'}),
			'x',
			formatMessage({id: 'project_budget.role_rate'}),
			'+',
			formatMessage({id: 'project_budget.expense_revenue'}),
		],
	},
];

const BaselineCostTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.cost'}),
		details: [
			formatMessage({id: 'project_budget.total_estimate'}),
			'x',
			formatMessage({id: 'common.role'}),
			'x',
			formatMessage({id: 'project_budget.adjusted_cost_rate'}),
			'+',
			formatMessage({id: 'project_budget.expense_cost'}),
		],
	},
];

const BaselineProfitTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'project_budget.baseline_revenue'}),
			'-',
			formatMessage({id: 'project_budget.baseline_cost'}),
		],
	},
];

const BaselineMarginTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.baseline_profit'}),
			'/',
			formatMessage({id: 'project_budget.baseline_revenue'}),
			'X',
			'100',
		],
	},
];

const BillableTotalTimeAndExpensesAtCompletionTooltip = formatMessage => [
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [
			formatMessage({id: 'project_budget.actual_revenue'}),
			'+',
			formatMessage({id: 'project_budget.remaining_revenue_all_projects'}),
		],
	},
];

const TotalCostAtCompletionTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.cost'}),
		details: [
			formatMessage({id: 'insights.component.list.column.actualCost'}),
			'+',
			formatMessage({id: 'project_budget.remaining_cost_all_projects'}),
		],
	},
];

const TotalProfitAtCompletionTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'project_budget.forecast_revenue'}),
			'-',
			formatMessage({id: 'project_budget.forecast_cost'}),
		],
	},
];

const TotalMarginAtCompletionTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.forecast_profit'}),
			'/',
			formatMessage({id: 'project_budget.forecast_revenue'}),
			'X',
			'100',
		],
	},
];

const BillableActualTimeAndExpensesTooltip = formatMessage => [
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [formatMessage({id: 'project_budget.total_actual_revenue_all_projects'})],
	},
];

const ActualCostTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.cost'}),
		details: [formatMessage({id: 'project_budget.total_actual_cost_all_projects'})],
	},
];

const ActualProfitTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'project_budget.actual_revenue'}),
			'-',
			formatMessage({id: 'insights.component.list.column.actualCost'}),
		],
	},
];

const ActualMarginTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.actual_profit'}),
			'/',
			formatMessage({id: 'project_budget.actual_revenue'}),
			'X',
			'100',
		],
	},
];

const PlanRevenueTooltip = formatMessage => [
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [formatMessage({id: 'project_budget.total_planned_revenue_all_projects'})],
	},
];

const PlanCostTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.cost'}),
		details: [formatMessage({id: 'project_budget.total_planned_cost_all_projects'})],
	},
];

const PlanProfitTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'project_budget.planned_revenue'}),
			'-',
			formatMessage({id: 'project_budget.planned_cost'}),
		],
	},
];

const PlanMarginTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.planned_profit'}),
			'/',
			formatMessage({id: 'project_budget.planned_revenue'}),
			'X',
			'100',
		],
	},
];

const BillableForecastTimeAndExpensesToCompleteTooltip = formatMessage => [
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [formatMessage({id: 'project_budget.total_remaining_revenue_all_projects'})],
	},
];

const ForecastCostToCompleteTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.cost'}),
		details: [formatMessage({id: 'project_budget.total_remaining_cost_all_projects'})],
	},
];

const ForecastProfitToCompleteTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'project_budget.remaining_revenue'}),
			'-',
			formatMessage({id: 'project_budget.remaining_cost'}),
		],
	},
];

const ForecastMarginToCompleteTooltip = formatMessage => [
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.remaining_profit'}),
			'/',
			formatMessage({id: 'project_budget.remaining_revenue'}),
			'X',
			'100',
		],
	},
];

const ForecastedHoursTooltip = formatMessage => [
	{
		details: [
			formatMessage({id: 'project_budget.all_time_entries'}),
			'+',
			formatMessage({id: 'project_budget.all_remaining_hours'}),
		],
	},
];

const TotalScopeTooltip = formatMessage => [
	{
		details: [formatMessage({id: 'project_portfolio_report.total_scope_tooltip'})],
	},
];

const ApprovedScopeTooltip = formatMessage => [
	{
		details: [formatMessage({id: 'project_portfolio_report.approved_scope_tooltip'})],
	},
];

const RegisteredHoursTooltip = formatMessage => [[formatMessage({id: 'project_budget.all_time_entries'})]];

const RemainingHoursTooltip = formatMessage => [formatMessage({id: 'project_budget.all_remaining_hours'})];

const BaselineHoursTooltip = formatMessage => [formatMessage({id: 'project_budget.all_estimated_hours'})];

const WinProbabilityTooltip = formatMessage => [formatMessage({id: 'project_portfolio_report.win_probability'})];

const ActualLowerWinChanceTooltip = formatMessage => [
	{
		title: formatMessage({id: 'project_budget.actual_revenue'}),
		description: [
			formatMessage({id: 'insights.baseline_tooltip_actual_text_1'}) +
				' ' +
				formatMessage({id: 'insights.baseline_tooltip_actual_text_2'}),
		],
	},
];

const PlanRevenueLowerWinChanceTooltip = formatMessage => [
	{
		title: formatMessage({id: 'insights.baseline_tooltip_plan_revenue'}),
		details: [formatMessage({id: 'project.win_chance'}), 'x', formatMessage({id: 'project_budget.baseline_revenue'})],
	},
	{
		description: formatMessage({id: 'insights.baseline_tooltip_text_revenue'}),
	},
];
const billableForecastTimeAndExpensesToCompleteLowerWinChanceTooltip = formatMessage => [
	{
		title: formatMessage({id: 'insights.baseline_tooltip_remaining_revenue'}),
		details: [formatMessage({id: 'project.win_chance'}), 'x', formatMessage({id: 'project_budget.baseline_revenue'})],
	},
	{
		description: formatMessage({id: 'insights.baseline_tooltip_text_revenue'}),
	},
];
const BillableTotalTimeAndExpensesAtCompletionLowerWinChanceTooltip = formatMessage => [
	{
		title: formatMessage({id: 'insights.baseline_tooltip_forecast_revenue'}),
		details: [formatMessage({id: 'project.win_chance'}), 'x', formatMessage({id: 'project_budget.baseline_revenue'})],
	},
	{
		description: formatMessage({id: 'insights.baseline_tooltip_text_revenue'}),
	},
];

const PlanCostLowerWinChanceTooltip = formatMessage => [
	{
		title: formatMessage({id: 'insights.baseline_tooltip_plan_cost'}),
		details: [formatMessage({id: 'project.win_chance'}), 'x', formatMessage({id: 'project_budget.baseline_cost'})],
	},
	{
		description: formatMessage({id: 'insights.baseline_tooltip_text_cost'}),
	},
];
const ForecastCostToCompleteLowerWinChanceTooltip = formatMessage => [
	{
		title: formatMessage({id: 'insights.baseline_tooltip_remaining_cost'}),
		details: [formatMessage({id: 'project.win_chance'}), 'x', formatMessage({id: 'project_budget.baseline_cost'})],
	},
	{
		description: formatMessage({id: 'insights.baseline_tooltip_text_cost'}),
	},
];
const TotalCostAtCompletionLowerWinChanceTooltip = formatMessage => [
	{
		title: formatMessage({id: 'insights.baseline_tooltip_forecast_cost'}),
		details: [formatMessage({id: 'project.win_chance'}), 'x', formatMessage({id: 'project_budget.baseline_cost'})],
	},
	{
		description: formatMessage({id: 'insights.baseline_tooltip_text_cost'}),
	},
];
const RecognitionProfitTooltip = formatMessage => [
	{
		title: formatMessage({id: 'project_budget.profit'}),
		details: [
			formatMessage({id: 'project_budget.recognition_amount'}),
			'-',
			formatMessage({id: 'project_budget.forecast_cost'}),
		],
	},
];
const RecognitionMarginTooltip = formatMessage => [
	{
		title: formatMessage({id: 'project_budget.margin'}),
		details: [formatMessage({id: 'project_budget.profit'}), '/', formatMessage({id: 'project_budget.recognition_amount'})],
	},
];

export const ProjectPortfolioReportTooltips = {
	RegisteredHoursTooltip,
	RemainingHoursTooltip,
	FixedPriceRevenueTooltip,
	FixedPriceCostTooltip,
	FixedPriceProfitTooltip,
	FixedPriceMarginTooltip,
	BaselineRevenueTooltip,
	BaselineCostTooltip,
	BaselineProfitTooltip,
	BaselineMarginTooltip,
	BillableTotalTimeAndExpensesAtCompletionTooltip,
	TotalCostAtCompletionTooltip,
	TotalProfitAtCompletionTooltip,
	TotalMarginAtCompletionTooltip,
	BillableActualTimeAndExpensesTooltip,
	ActualCostTooltip,
	ActualProfitTooltip,
	ActualMarginTooltip,
	PlanRevenueTooltip,
	PlanCostTooltip,
	PlanProfitTooltip,
	PlanMarginTooltip,
	BillableForecastTimeAndExpensesToCompleteTooltip,
	ForecastCostToCompleteTooltip,
	ForecastProfitToCompleteTooltip,
	ForecastMarginToCompleteTooltip,
	ForecastedHoursTooltip,
	BaselineHoursTooltip,
	WinProbabilityTooltip,
	ActualLowerWinChanceTooltip,
	PlanRevenueLowerWinChanceTooltip,
	billableForecastTimeAndExpensesToCompleteLowerWinChanceTooltip,
	BillableTotalTimeAndExpensesAtCompletionLowerWinChanceTooltip,
	PlanCostLowerWinChanceTooltip,
	ForecastCostToCompleteLowerWinChanceTooltip,
	TotalCostAtCompletionLowerWinChanceTooltip,
	TotalScopeTooltip,
	ApprovedScopeTooltip,
	RecognitionProfitTooltip,
	RecognitionMarginTooltip,
};
