import React from 'react';
import {ShowMore} from '../../../forecast-app/shared/components/dropdowns/skill-dropdown/ShowMore';
import SkillBadge from '../../../forecast-app/shared/components/skills/skill_badge';

export default ({placeholder, top, left}) => {
	return (
		<div className="placeholder-information-tooltip" style={{top, left}}>
			<div className="placeholder-information-content">
				<ShowMore noExpand>
					{placeholder.skills.map(placeholderSkill => {
						const skill = placeholderSkill.skill;
						const skillLevel = placeholderSkill.level?.level;
						return <SkillBadge key={skill.id + ':' + skillLevel} skill={skill} skillLevel={skillLevel} />;
					})}
				</ShowMore>
			</div>
		</div>
	);
};
