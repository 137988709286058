import React from 'react';
import LoadingIndicator from './LoadingIndicator';
import Styled from 'styled-components';

export const LoadingOverlay = ({children, isLoading}) => {
	return isLoading ? (
		<LoadingDataOverlayStyled>
			<LoadingIndicator />
		</LoadingDataOverlayStyled>
	) : (
		<>{children}</>
	);
};

const LoadingDataOverlayStyled = Styled.div`
    height: 100%;
    width: 100%;
    background: #ffffffcc;
    backdrop-filter: blur(5px);
    z-index: 9;
    display: grid;
    align-content: space-evenly;
    justify-items: center;
`;
