import React, {useState} from 'react';
import styled from 'styled-components';
import {createRefetchContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {Table, KebabMenuIcon} from 'web-components';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import ContextMenu from '../../forecast-app/shared/components/context-menus/context_menu';
import Util from '../../forecast-app/shared/util/util';
import RetryUnit4LogItemMutation from '../../mutations/RetryUnit4LogItemMutation';
import {hideLoader, showLoader} from '../../containers/global_loader';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE} from '../../constants';
import {buildHeaderBar} from '../../forecast-app/shared/components/headers/header-bar/header_bar';

const Unit4LogPageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 0 30px 0 10px;
`;
const PageContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 30px 30px 0 30px;
`;
const ErrorMessageWrapper = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0px:
`;

export const Unit4LogPage = ({viewer, relay: {refetch}}) => {
	const intl = useIntl();
	let dataClone = [...viewer.unit4LogItems];
	const [contextMenuData, setContextMenuData] = useState(null);
	const [sort, setSort] = useState({id: 'date', direction: 'DESC'});

	const headerBarContent = [];

	const handleOpenContextMenu = (e, rowData) => {
		e.preventDefault();
		const newContextMenuData = {
			id: rowData?.id,
			type: rowData?.type,
			unit4Identifier: rowData?.unit4Identifier,
			date: rowData?.date,
			taskId: rowData?.taskId,
			personId: rowData?.personId,
			projectId: rowData?.projectId,
		};
		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			newContextMenuData.posX = btnBounds.right - btn.clientWidth - 75;
			newContextMenuData.posY = btnBounds.y + 25;
		} else {
			newContextMenuData.posX = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				newContextMenuData.posY = e.pageY - 250;
			} else {
				newContextMenuData.posY = e.pageY;
			}
		}
		setContextMenuData(newContextMenuData);
	};

	const handleCloseContextMenu = () => {
		setContextMenuData(null);
	};

	const errorTypeToMethod = errorType => {
		switch (errorType) {
			case 'unit4_time_reg_create_error':
				return 'CREATE';
			case 'unit4_time_reg_update_error':
				return 'UPDATE';
			case 'unit4_time_reg_delete_error':
				return 'DELETE';
			default:
				return 'UNKNOWN';
		}
	};

	const handleRetry = data => {
		showLoader();
		const onSuccess = res => {
			hideLoader();
			if (res.retryUnit4LogItem.errors?.length) {
				createToast({
					duration: 5000,
					message: 'Retry failed',
				});
			}
		};
		const retryRequest = {
			viewerId: viewer.id,
			type: 'TIME_REG',
			method: errorTypeToMethod(data.type),
			forecastId: parseInt(data.id),
			unit4Identifier: data.unit4Identifier,
			date: data.date,
			taskId: data.taskId,
			personId: data.personId,
			projectId: data.projectId,
		};

		Util.CommitMutation(RetryUnit4LogItemMutation, retryRequest, onSuccess);
	};

	const getHeader = () => {
		const leftContent = [],
			rightContent = [];

		const exportColumns = [
			'type',
			'person',
			'project',
			'workorder',
			'task',
			'date',
			'minutesRegistered',
			'notes',
			'errorMessage',
		];

		const csv = {
			type: ELEMENT_TYPE.CSV,
			callback: () =>
				Util.exportTable(
					exportColumns,
					dataClone.map(o => Object.fromEntries(Object.entries(o).filter(e => exportColumns.includes(e[0])))),
					'unit4-error-log'
				),
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.LIGHTGREY,
			text: intl.formatMessage({id: 'common.export-csv'}),
			tooltipEnabled: true,
			tooltipProps: {
				autoPlace: true,
				grey: true,
				infoText: intl.formatMessage({id: 'common.export-csv'}),
			},
		};
		rightContent.push(csv);

		return buildHeaderBar(leftContent, rightContent);
	};

	const sortData = () => {
		if (sort && sort.direction !== null && sort.id) {
			dataClone.sort((a, b) => {
				const aValue = a[sort.id] || '';
				const bValue = b[sort.id] || '';
				const res = aValue.localeCompare(bValue, 'en', {sensitivity: 'base'});
				return sort.direction === 'DESC' ? res * -1 : res;
			});
		}
	};

	sortData();

	return (
		<Unit4LogPageWrapper>
			<TopHeaderBarWrapper>
				<TopHeaderBar title="Unit4 Error Log" content={headerBarContent} />
			</TopHeaderBarWrapper>
			{getHeader()}
			{!!contextMenuData && (
				<div style={{zIndex: 100}}>
					<ContextMenu
						options={[
							{
								text: 'Retry',
								onClick: () => handleRetry(contextMenuData),
							},
						]}
						contextMenuPosition={{x: contextMenuData.posX, y: contextMenuData.posY}}
						closeContextMenu={handleCloseContextMenu}
						userpilot={'context-menu-wrapper'}
					/>
				</div>
			)}
			<PageContentWrapper>
				<CustomScrollDiv>
					<div>
						<Table onSort={sort => setSort(sort)}>
							<Table.Header>
								<Table.HeaderColumn visible width="SMALL" sortId="type">
									<Table.HeaderColumn.Title>Type</Table.HeaderColumn.Title>
								</Table.HeaderColumn>
								<Table.HeaderColumn usePadding visible width="MEDIUM" sortId="person">
									<Table.HeaderColumn.Title>Person</Table.HeaderColumn.Title>
								</Table.HeaderColumn>
								<Table.HeaderColumn usePadding flex={1} visible width="MEDIUM" sortId="project">
									<Table.HeaderColumn.Title>Project</Table.HeaderColumn.Title>
								</Table.HeaderColumn>
								<Table.HeaderColumn usePadding flex={1} visible width="MEDIUM" sortId="workorder">
									<Table.HeaderColumn.Title>Workorder</Table.HeaderColumn.Title>
								</Table.HeaderColumn>
								<Table.HeaderColumn usePadding flex={1} visible width="MEDIUM" sortId="task">
									<Table.HeaderColumn.Title>Task</Table.HeaderColumn.Title>
								</Table.HeaderColumn>
								<Table.HeaderColumn usePadding visible width="SMALL" sortId="date">
									<Table.HeaderColumn.Title>Date</Table.HeaderColumn.Title>
								</Table.HeaderColumn>
								<Table.HeaderColumn usePadding visible width="SMALL" sortId="minutesRegistered">
									<Table.HeaderColumn.Title>Hours</Table.HeaderColumn.Title>
								</Table.HeaderColumn>
								<Table.HeaderColumn usePadding flex={1} visible width="MEDIUM" sortId="notes">
									<Table.HeaderColumn.Title>Notes</Table.HeaderColumn.Title>
								</Table.HeaderColumn>
								<Table.HeaderColumn usePadding flex={3} visible width="MEDIUM" sortId="errorMessage">
									<Table.HeaderColumn.Title>Error message</Table.HeaderColumn.Title>
								</Table.HeaderColumn>
								<Table.HeaderColumn usePadding visible width="EXTRA_SMALL">
									<Table.HeaderColumn.Title></Table.HeaderColumn.Title>
								</Table.HeaderColumn>
							</Table.Header>
							<Table.Rows
								data={{
									rows: dataClone,
								}}
								canExpand={false}
							>
								{({rowData, tableColumnsProps}) => {
									return (
										<Table.Row {...tableColumnsProps}>
											<Table.Column>{errorTypeToMethod(rowData?.type)}</Table.Column>
											<Table.Column>{rowData?.person}</Table.Column>
											<Table.Column>{rowData?.project}</Table.Column>
											<Table.Column>{rowData?.workorder}</Table.Column>
											<Table.Column>{rowData?.task}</Table.Column>
											<Table.Column>{rowData?.date?.substring(0, 10)}</Table.Column>
											<Table.Column>
												{Util.convertMinutesToFullHour(rowData?.minutesRegistered || 0, intl)}
											</Table.Column>
											<Table.Column>{rowData?.notes}</Table.Column>
											<Table.Column>
												<ErrorMessageWrapper title={rowData?.errorMessage}>
													{rowData?.errorMessage}
												</ErrorMessageWrapper>
											</Table.Column>
											<Table.Column>
												<KebabMenuIcon onClick={e => handleOpenContextMenu(e, rowData)} />
											</Table.Column>
										</Table.Row>
									);
								}}
							</Table.Rows>
						</Table>
					</div>
				</CustomScrollDiv>
			</PageContentWrapper>
		</Unit4LogPageWrapper>
	);
};

const unit4LogQuery = graphql`
	query Unit4LogPageQuery {
		viewer {
			actualPersonId
			component(name: "unit4_log_page")
			...Unit4LogPage_viewer
		}
	}
`;

export {unit4LogQuery};

export default createRefetchContainer(
	Unit4LogPage,
	{
		viewer: graphql`
			fragment Unit4LogPage_viewer on Viewer {
				unit4LogItems {
					id
					type
					unit4Identifier
					personId
					person
					projectId
					project
					workorder
					taskId
					task
					date
					minutesRegistered
					notes
					errorMessage
				}
			}
		`,
	},
	graphql`
		query Unit4LogPageRefetchQuery {
			viewer {
				id
				...Unit4LogPage_viewer
			}
		}
	`
);
