import Util from '../../../../forecast-app/shared/util/util';
import CreateProjectSchedulingShareMutation from '../../../../mutations/create_project_scheduling_share_mutation';
import DirectApi from '../../../../directApi';

export const onCreateShare = (filters, eyeOptions, zoomLevel, companyProjectId, companyProjectGroupId, currentDay) => {
	Util.CommitMutation(
		CreateProjectSchedulingShareMutation,
		{
			filterApplied: JSON.stringify(filters),
			eyeApplied: JSON.stringify(eyeOptions),
			zoomLevel,
			companyProjectId,
			companyProjectGroupId,
			initialPosition: currentDay,
		},
		data => {
			const url = data?.createProjectSchedulingShare?.projectSchedulingShare?.url;
			if (url) {
				window.open(`/project-scheduling-share/${url}?dc=${DirectApi.getDatacenter()}`, '_blank');
			}
		}
	);
};
