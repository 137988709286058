/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FinancialCalculationTrigger_viewer$ref: FragmentReference;
declare export opaque type FinancialCalculationTrigger_viewer$fragmentType: FinancialCalculationTrigger_viewer$ref;
export type FinancialCalculationTrigger_viewer = {|
  +project: ?{|
    +id: string,
    +financialCalculationTrigger: ?{|
      +timestamp: ?number,
      +duration: ?number,
      +isSmallProject: ?boolean,
    |},
  |},
  +$refType: FinancialCalculationTrigger_viewer$ref,
|};
export type FinancialCalculationTrigger_viewer$data = FinancialCalculationTrigger_viewer;
export type FinancialCalculationTrigger_viewer$key = {
  +$data?: FinancialCalculationTrigger_viewer$data,
  +$fragmentRefs: FinancialCalculationTrigger_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinancialCalculationTrigger_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FinancialCalculationTrigger",
          "kind": "LinkedField",
          "name": "financialCalculationTrigger",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "timestamp",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isSmallProject",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '0c635187570cf003cfe9df1651b09431';

module.exports = node;
