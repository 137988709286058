import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import Moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker from '../inputs/date_picker';

class ZoomMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			jumpToMenuExpanded: false,
			showDatePicker: false,
		};
	}

	zoomOut() {
		let timeline = this.props.getTimeline();
		if (this.props.useV2) {
			timeline.zoom(2);
			return;
		}
		timeline.zoomOut(0.5);
		timeline.timeline.redraw();
	}

	zoomIn() {
		let timeline = this.props.getTimeline();
		if (this.props.useV2) {
			timeline.zoom(0.5);
			return;
		}
		timeline.zoomIn(0.5);
		timeline.timeline.redraw();
	}

	previous() {
		//move by one third of visible area
		let timeline = this.props.getTimeline();
		if (this.props.useV2) {
			timeline.scroll(-0.33);
			return;
		}
		const start = Moment(timeline.getWindow().start);
		const end = Moment(timeline.getWindow().end);
		const days_diff = end.diff(start, 'days');
		start.subtract(days_diff / 3, 'days');
		end.subtract(days_diff / 3, 'days');
		timeline.setWindow(start.toDate(), end.toDate());
		timeline.resetScroller();
	}

	next() {
		//move by one third of visible area
		let timeline = this.props.getTimeline();
		if (this.props.useV2) {
			timeline.scroll(0.33);
			return;
		}
		const start = Moment(timeline.getWindow().start);
		const end = Moment(timeline.getWindow().end);
		const days_diff = end.diff(start, 'days');
		start.add(days_diff / 3, 'days');
		end.add(days_diff / 3, 'days');
		timeline.setWindow(start.toDate(), end.toDate());
		timeline.resetScroller();
	}

	todayView() {
		//move start to today, keep same amount of days visible
		this.hideJumpToMenu();
		if (this.props.useV2) {
			this.props.getTimeline().scrollToMoment(Moment());
			return;
		}
		const start_new = Moment.utc().startOf('day');
		this.setTimelineDates(start_new);
	}

	showDatePicker() {
		this.setState({showDatePicker: true});
		this.setState({jumpToMenuExpanded: true});
	}

	toggleJumpToMenu() {
		this.setState({jumpToMenuExpanded: !this.state.jumpToMenuExpanded, showDatePicker: false});
	}

	hideJumpToMenu() {
		this.setState({jumpToMenuExpanded: false, showDatePicker: false});
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (newTarget && newTarget.className === 'option') {
			return;
		} else {
			this.hideJumpToMenu();
		}
	}

	customDateView(date) {
		this.hideJumpToMenu();
		this.setTimelineDates(date);
	}

	setTimelineDates(startDate) {
		const timeline = this.props.getTimeline();
		if (this.props.useV2) {
			timeline.scrollToMoment(startDate);
			return;
		}
		const start = Moment(timeline.getWindow().start);
		const end = Moment(timeline.getWindow().end);
		const days_diff = end.diff(start, 'days');
		const start_new = startDate;
		const end_new = start_new.clone().add(days_diff, 'days');
		timeline.setWindow(start_new.toDate(), end_new.toDate());
		timeline.resetScroller();
	}

	render() {
		return (
			<div className="zoom-menu">
				<div className="zoom-button-wrapper">
					<div className="previous" tabIndex="0" onClick={this.previous.bind(this)} />
					<button className="zoom-out" onClick={this.zoomOut.bind(this)} />
					<div className="jump-to-container" onBlur={this.handleBlur.bind(this)}>
						<button className="jump-to" onClick={this.toggleJumpToMenu.bind(this)}>
							<FormattedMessage id="scheduling.controls.zoom.jump_to" />
						</button>
						<div
							className={
								'zoom-menu-drop-down ' +
								(this.state.jumpToMenuExpanded ? 'expanded ' : 'collapsed ') +
								(this.state.showDatePicker ? 'custom-date-wrapper' : 'zoom-wrapper')
							}
						>
							{!this.state.showDatePicker ? (
								<ul className="zoom-drop-down">
									<li className="option" tabIndex="0" onClick={this.todayView.bind(this)}>
										<FormattedMessage id="common.today" />
									</li>
									<li className="option" tabIndex="0" onClick={this.showDatePicker.bind(this)}>
										<FormattedMessage id="common.date" />
									</li>
								</ul>
							) : (
								<DatePicker value={null} inline={true} onConfirm={this.customDateView.bind(this)} />
							)}
						</div>
					</div>
					<button className="zoom-in" onClick={this.zoomIn.bind(this)} />
					<div className="next" tabIndex="0" onClick={this.next.bind(this)} />
				</div>
			</div>
		);
	}
}

ZoomMenu.propTypes = {
	getTimeline: PropTypes.func.isRequired,
};

export default ZoomMenu;
