import React from 'react';
import * as PropTypes from 'prop-types';
import {FormattedHTMLMessage} from 'react-intl';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../css_variables';
import {theme} from '@forecast-it/design-system';

const ForecastTooltipFormulaRendererStyle = styled.div`
	display: flex;
	flex-direction: column;
	padding: 5px 7px 5px 0;
	color: ${() => CSS_CONSTANTS.v2_text_gray};
	font-size: ${({matchDesignSystemFonts}) => (matchDesignSystemFonts ? '' : '11px')};
	font-weight: ${({matchDesignSystemFonts}) => (matchDesignSystemFonts ? '' : '500')};
	${({matchDesignSystemFonts}) => (matchDesignSystemFonts ? theme.fonts.body[1] : '')};
	.wrapper {
		display: flex;

		&:not(:last-child) {
			margin-bottom: 16px;
		}

		&.small-margin {
			margin-bottom: 4px;
		}

		.header {
			margin-bottom: 5px;
			font-size: ${({matchDesignSystemFonts}) => (matchDesignSystemFonts ? '' : '12px')};
			font-weight: ${({matchDesignSystemFonts}) => (matchDesignSystemFonts ? '' : '500')};
			${({matchDesignSystemFonts}) => (matchDesignSystemFonts ? theme.fonts.subheading[1] : '')};
			&:not(.first) {
				border-top: 1px solid ${() => CSS_CONSTANTS.v2_text_gray};
				padding-top: 16px;
			}
		}

		.footer {
			padding-top: 8px;
			margin-left: 8px;
			font-weight: 400;
		}

		.title {
			margin-bottom: 5px;
			display: flex;

			.title-text {
				margin-left: 8px;
				font-size: ${({matchDesignSystemFonts}) => (matchDesignSystemFonts ? '' : 'inherit')};
				font-weight: ${({matchDesignSystemFonts}) => (matchDesignSystemFonts ? '' : 'inherit')};
				${({matchDesignSystemFonts}) => (matchDesignSystemFonts ? theme.fonts.subheading[1] : '')};
			}
		}

		.details {
			display: flex;
			align-items: center;
			font-weight: 400;
			margin-left: 8px;

			.detail {
				padding: ${({matchDesignSystemFonts}) => (matchDesignSystemFonts ? '5px 12px' : '5px 8px')};
				white-space: nowrap;

				&.boxed {
					border: 1px solid ${() => CSS_CONSTANTS.app_border_color};
					background-color: #fff;
				}
			}
		}

		.description {
			font-weight: normal;

			.description-text {
				margin-left: 8px;
			}
		}
	}
`;

export const ForecastTooltipFormulaRenderer = ({items, translatedMessage, matchDesignSystemFonts}) => {
	return (
		<ForecastTooltipFormulaRendererStyle matchDesignSystemFonts={matchDesignSystemFonts}>
			{items.map((item, index) => {
				return item.hide ? null : (
					<div key={index} className={`wrapper${item.smallMargin ? ' small-margin' : ''}`}>
						{item.header ? (
							<div className={`header${index === 0 ? ' first' : ''}`}>{item.header}</div>
						) : (
							<>
								{item.title ? (
									<div className="title">
										{item.titleIcon ? <div className="title-icon">{item.titleIcon}</div> : null}
										<div className="title-text">
											{translatedMessage ? (
												<span>{item.title}</span>
											) : (
												<FormattedHTMLMessage id={item.title} />
											)}
										</div>
									</div>
								) : null}
								{item.description ? (
									<div className="description">
										<div className="description-text">{item.description}</div>
									</div>
								) : (
									<div className="details">
										{item.details ? (
											item.details.map((detail, index) => {
												return (
													<div
														key={`${detail}-${index}`}
														className={'detail' + (index % 2 === 0 && !item.noBox ? ' boxed' : '')}
													>
														{translatedMessage ? (
															<span>{detail}</span>
														) : (
															<FormattedHTMLMessage id={detail} />
														)}
													</div>
												);
											})
										) : item.list ? (
											<ul>
												{item.list.map(listItem => (
													<li>{listItem}</li>
												))}
											</ul>
										) : item.unstyledList ? (
											<ul
												style={{listStyleType: 'none', margin: '0', padding: '8px', overflow: 'hidden'}}
											>
												{item.unstyledList.map(listItem => (
													<li>{listItem}</li>
												))}
											</ul>
										) : null}
									</div>
								)}
								{item.footer && <div className="footer">{item.footer}</div>}
							</>
						)}
					</div>
				);
			})}
		</ForecastTooltipFormulaRendererStyle>
	);
};

ForecastTooltipFormulaRenderer.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.shape({
			hide: PropTypes.bool,
			header: PropTypes.node,
			title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
			titleIcon: PropTypes.node,
			description: PropTypes.node,
			details: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
			noBox: PropTypes.bool,
			list: PropTypes.arrayOf(PropTypes.node),
			unstyledList: PropTypes.arrayOf(PropTypes.node),
		})
	).isRequired,
	translatedMessage: PropTypes.bool.isRequired,
	matchDesignSystemFonts: PropTypes.bool,
};

ForecastTooltipFormulaRenderer.defaultTypes = {
	translatedMessage: false,
};
