export const SCHEDULE_ITEM_GHOST = '_GHOST';
export const ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT = 'allocationControl_hideSoft';
export const ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD = 'allocationControl_hideHard';
export const ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN = 'allocationControl_calcWin';
export const EVENT_FROM_SCHEDULING = 'event_from_scheduling';
export const KEEP_PLACEHOLDER_STORAGE_KEY = 'scheduling_keep_placeholder';
export const EYE_OPTION_NAME = {
	SHOW_WEEKENDS: 'show-weekends',
	SHOW_HEATMAP: 'show-heatmap',
};

export const ACTION_MENU_OPTION_TYPE = {
	PLACEHOLDER_FIND_AVAILABLE_TEAM_MEMBER: 'PLACEHOLDER_FIND_AVAILABLE_TEAM_MEMBER',
	PLACEHOLDER_ASSIGN_TO_PERSON: 'PLACEHOLDER_ASSIGN_TO_PERSON',
	PLACEHOLDER_REPLACE_WITH_PERSON: 'PLACEHOLDER_REPLACE_WITH_PERSON',
	PLACEHOLDER_EDIT: 'PLACEHOLDER_EDIT',
	PLACEHOLDER_DELETE: 'PLACEHOLDER_DELETE',
	PLACEHOLDER_DUPLICATE: 'PLACEHOLDER_DUPLICATE',
	PERSON_ADD_TO_PROJECT: 'PERSON_ADD_TO_PROJECT',
	PERSON_REMOVE_FROM_PROJECT: 'PERSON_REMOVE_FROM_PROJECT',
	PROJECT_VIEW_PROJECT: 'PROJECT_VIEW_PROJECT',
	PROGRAM_VIEW_PROGRAM: 'PROGRAM_VIEW_PROGRAM',
	PROJECT_REMOVE_FROM_PROJECT: 'PROJECT_REMOVE_FROM_PROJECT',
	PROJECT_SET_STATUS_RUNNING: 'PROJECT_SET_STATUS_RUNNING',
	PROJECT_DUPLICATE: 'PROJECT_DUPLICATE',
	PROJECT_DELETE: 'PROJECT_DELETE',
};
export const PROJECT_SUB_GROUP_TYPE = {
	PHASES: 'PHASES',
	PLACEHOLDERS: 'PLACEHOLDERS',
	PROJECT_TEAM: 'PROJECT_TEAM',
};

export const NO_ACCESS_DISTRIBUTION_TYPE = 'NoAccess';
export const NO_ACCESS_DISTRIBUTION_MAP_ID = btoa(NO_ACCESS_DISTRIBUTION_TYPE + ':-1');
export const NO_ACCESS_DISTRIBUTION_UNIQUE = anonymizedId => btoa(NO_ACCESS_DISTRIBUTION_TYPE + ':' + anonymizedId);
export const DISTRIBUTION_TYPE = {
	PHASE: 'PHASE',
	PROJECT: 'PROJECT',
	PROGRAM: 'PROGRAM',
};
export const DATE_FROM_PRIORITY = {
	TASK: 1,
	PHASE: 2,
	PROJECT: 3,
};
export const PROJECT_ENTITY_GROUP_TYPE = {
	TASK: 'TASK',
	ALLOCATION: 'ALLOCATION',
};

export const TOOLTIP_MAX_WIDTH = {
	TASK_ITEM: 340,
	PROJECT_ALLOCATION_ITEM: 340,
};
