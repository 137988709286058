import React from 'react';
import {Gauge} from 'web-components';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FlexRow, OutlineBox, Section} from './UtilizationReport.styled';
import {BillableUtilizationChart} from './BillableUtilizationChart';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {useIntl} from 'react-intl';
import {HashLink} from 'react-router-hash-link';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {selectValueWithOptions} from './UtilizationReportUtils';

export const UtilizationCharts = ({
	showResourceUtilization,
	totalResourceNumbers,
	companyTarget,
	startDate,
	endDate,
	aggregatedResourceNumbersByDay,
	isMixedAllocationModeEnabled,
	allocationControlsOptions,
}) => {
	const intl = useIntl();
	const utilization = showResourceUtilization
		? isMixedAllocationModeEnabled
			? selectValueWithOptions(
					totalResourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombined,
					totalResourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombinedWin,
					totalResourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombinedSoft,
					totalResourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin,
					totalResourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombinedHard,
					allocationControlsOptions
			  )
			: selectValueWithOptions(
					totalResourceNumbers.forecastResourceUtilization,
					totalResourceNumbers.forecastResourceUtilizationWin,
					totalResourceNumbers.forecastResourceUtilizationSoft,
					totalResourceNumbers.forecastResourceUtilizationSoftWin,
					totalResourceNumbers.forecastResourceUtilizationHard,
					allocationControlsOptions
			  )
		: isMixedAllocationModeEnabled
		? selectValueWithOptions(
				totalResourceNumbers.forecastBillableUtilizationTaskAndAllocationsCombined,
				totalResourceNumbers.forecastBillableUtilizationTaskAndAllocationsCombinedWin,
				totalResourceNumbers.forecastBillableUtilizationTaskAndAllocationsCombinedSoft,
				totalResourceNumbers.forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin,
				totalResourceNumbers.forecastBillableUtilizationTaskAndAllocationsCombinedHard,
				allocationControlsOptions
		  )
		: selectValueWithOptions(
				totalResourceNumbers.forecastBillableUtilization,
				totalResourceNumbers.forecastBillableUtilizationWin,
				totalResourceNumbers.forecastBillableUtilizationSoft,
				totalResourceNumbers.forecastBillableUtilizationSoftWin,
				totalResourceNumbers.forecastBillableUtilizationHard,
				allocationControlsOptions
		  );
	const gaugeValue = Math.round(utilization * 100);
	const gaugeMax = Math.max(100, gaugeValue);
	const gaugeTarget = showResourceUtilization ? undefined : companyTarget * 100;

	return (
		<Section sticky height={338} marginLeft={24} marginRight={24}>
			<CustomScrollDiv style={{flexGrow: 1}}>
				<FlexRow>
					<OutlineBox data-userpilot={'utilization-gauge'}>
						<Gauge
							value={gaugeValue}
							max={gaugeMax}
							target={gaugeTarget}
							targetContent={
								<ForecastTooltip
									placement={'right'}
									delay={[500, 2000]}
									interactive={hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS)}
									content={
										<div>
											<div>
												{intl.formatMessage({id: 'settings.billable_utilization_target.tooltip'})}
											</div>
											<HashLink smooth to="/settings/details#resource-management">
												{intl.formatMessage({id: 'header.admin'})}
											</HashLink>
										</div>
									}
								>
									{intl.formatMessage({id: 'settings.billable_utilization_target'})}
								</ForecastTooltip>
							}
							valueText={
								showResourceUtilization
									? intl.formatMessage({id: 'common.forecast_resource_utilization'})
									: intl.formatMessage({id: 'common.forecast_billable_utilization'})
							}
							affix="%"
							width={300}
						/>
					</OutlineBox>
					<OutlineBox growable marginLeft={24} data-userpilot={'utilization-graph'}>
						<div style={{height: '286px', minWidth: '826px', display: 'flex', flexDirection: 'column'}}>
							<div style={{position: 'relative', flexGrow: '1'}}>
								<BillableUtilizationChart
									startDate={startDate}
									endDate={endDate}
									companyTarget={companyTarget}
									aggregatedResourceNumbersByDay={aggregatedResourceNumbersByDay}
									showResourceUtilization={showResourceUtilization}
									isMixedAllocationModeEnabled={isMixedAllocationModeEnabled}
									allocationControlsOptions={allocationControlsOptions}
								/>
							</div>
						</div>
					</OutlineBox>
				</FlexRow>
			</CustomScrollDiv>
		</Section>
	);
};
