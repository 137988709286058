import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
class RadialProgressBar extends Component {
	render() {
		return (
			<div className={'radial-progress-bar-wrapper'}>
				<CircularProgressbar
					styles={buildStyles({
						// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
						strokeLinecap: 'butt',
						// Text size
						textSize: '16px',
						transition: 'stroke-dashoffset 0.5s ease 0s',
						// How long animation takes to go from one percentage to another, in seconds
						pathTransitionDuration: 0.5,
						// Colors
						pathColor: this.props.pathColor,
						trailColor: this.props.trailColor,
						textColor: this.props.textColor,
					})}
					value={this.props.progress}
					text={`${this.props.progress}%`}
				/>
			</div>
		);
	}
}
RadialProgressBar.propTypes = {
	progress: PropTypes.number.isRequired,
};
export default RadialProgressBar;
