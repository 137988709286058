import {ITEM_TYPE} from '../canvas-timeline/canvas_timeline_util';
import {PROJECT_STATUS} from '../../../constants';
import {updateProject} from '../../scheduling/scheduling_mutations';

export const handleSetProjectStatus = (pageComponent, projectId, newStatus) => {
	const onSuccess = result => {
		if (result?.updateProject?.project) {
			const {groups, items} = pageComponent.state;

			const itemToUpdate = items.find(
				item =>
					item.groupId === projectId &&
					item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PROJECT &&
					item.data.project?.id === projectId
			);

			const stateObject = {};

			if (itemToUpdate) {
				const itemIndex = items.indexOf(itemToUpdate);
				itemToUpdate.data.isPlanning =
					result.updateProject.project.status === PROJECT_STATUS.PLANNING ||
					result.updateProject.project.status === PROJECT_STATUS.OPPORTUNITY;
				items[itemIndex] = itemToUpdate;
				stateObject.items = items;
			}

			const groupToUpdate = groups.find(group => group.id === projectId);
			const groupIndex = groups.indexOf(groupToUpdate);

			if (groupToUpdate) {
				groupToUpdate.refreshData(result.updateProject);
				groups[groupIndex] = groupToUpdate;
				stateObject.groups = groups;
			}

			if (stateObject.groups !== undefined || stateObject.items !== undefined) {
				pageComponent.setState(stateObject);
			}

			pageComponent.redrawCanvasTimeline({preventFiltering: false});
		}
	};

	updateProject(
		{
			id: projectId,
			status: newStatus,
		},
		onSuccess
	);
};
