import moment from 'moment';

const CANVAS = document.createElement('canvas');

export const getTimeUnit = (startDate, endDate) => {
	let timeUnit = 'week';
	if (endDate && startDate) {
		if (endDate.diff(startDate, 'months', true) > 2) {
			timeUnit = 'month';
		} else {
			timeUnit = 'day';
		}
	}
	return timeUnit;
};

export const getTimeTick = (minutes, intl) => {
	const hours = Math.round(minutes / 60);
	return intl.formatMessage({id: 'common.x_hours'}, {hours: hours});
};

export const getDateToValueMap = data => {
	const dateToValueMap = new Map();
	data.forEach(dateData => {
		dateToValueMap.set(dateData.date, dateData.value);
	});
	return dateToValueMap;
};

export const getChartDataByDay = (firstDate, lastDate, data, options = {}) => {
	if (!firstDate || !lastDate) return [];
	const dateValueMap = getDateToValueMap(data);
	const {excludePast, excludeFuture} = options;

	const accumulatedDataByDay = [];
	let accumulation = 0;
	const todayDate = moment();
	const yesterdayDate = moment().subtract(1, 'days');
	let date = firstDate.clone();
	while (date.isSameOrBefore(lastDate, 'days')) {
		const formattedDate = date.format('YYYY-MM-DD');
		if (dateValueMap.has(formattedDate)) {
			accumulation += dateValueMap.get(formattedDate);
		}
		if (
			(!excludePast || date.isSameOrAfter(yesterdayDate, 'days')) &&
			(!excludeFuture || date.isBefore(todayDate, 'days'))
		) {
			accumulatedDataByDay.push({
				date: date.clone(),
				value: accumulation,
			});
		} else {
			accumulatedDataByDay.push({
				date: date.clone(),
				value: null,
			});
		}
		date.add(1, 'days');
	}
	return accumulatedDataByDay;
};

export function getGradients() {
	const ctx = CANVAS.getContext('2d');
	const fillGradient = [0.0, 500.0, 0.0, 0.0];

	const gradientStroke = 'rgba(127, 229, 178)';

	const gradientFill = ctx.createLinearGradient(...fillGradient);
	gradientFill.addColorStop(0, 'rgba(217, 248, 232, 0.2)');
	gradientFill.addColorStop(1, 'rgba(217, 248, 232, 1)');

	const gradientStrokeActual = 'rgb(173, 227, 255)';

	const gradientFillActual = ctx.createLinearGradient(...fillGradient);
	gradientFillActual.addColorStop(1, 'rgba(234, 248, 255, 0.6)');
	gradientFillActual.addColorStop(1, 'rgba(234, 248, 255, 1)');

	const invoicedGradientFill = ctx.createLinearGradient(...fillGradient);
	invoicedGradientFill.addColorStop(0, 'rgba(253, 248, 209, 0.4)');
	invoicedGradientFill.addColorStop(1, 'rgba(253, 248, 209, 1)');

	const invoicedGradientStroke = 'rgba(246, 218, 26, 0.5)';

	const paidGradientFill = ctx.createLinearGradient(...fillGradient);
	paidGradientFill.addColorStop(0, 'rgba(255, 243, 251, 0.6)');
	paidGradientFill.addColorStop(1, 'rgba(255, 243, 251, 1)');

	const paidGradientStroke = 'rgb(255, 206, 239)';

	const gradientStrokeFixedPrice = 'rgba(154,208,255, 1)';

	const gradientFillFixedPrice = ctx.createLinearGradient(...fillGradient);
	gradientFillFixedPrice.addColorStop(0, 'rgba(154,208,255, 0.2)');
	gradientFillFixedPrice.addColorStop(1, 'rgba(154,208,255, 0)');

	const gradientStrokeBaseline = 'rgba(47,97,213, 1)';

	const gradientStrokeVariance = 'rgb(191, 162, 252)';

	return {
		gradientStroke,
		gradientFill,
		gradientStrokeActual,
		gradientFillActual,
		invoicedGradientFill,
		invoicedGradientStroke,
		paidGradientFill,
		paidGradientStroke,
		gradientStrokeFixedPrice,
		gradientFillFixedPrice,
		gradientStrokeBaseline,
		gradientStrokeVariance,
	};
}

export const getProjectDatesOrDefault = project => {
	const missingProjectDates = !project.projectFirstDateYear || !project.projectLastDateYear;
	const now = moment();
	return {
		projectFirstDateYear: missingProjectDates ? now.year() : project.projectFirstDateYear,
		projectFirstDateMonth: missingProjectDates ? now.month() : project.projectFirstDateMonth,
		projectFirstDateDay: missingProjectDates ? now.date() : project.projectFirstDateDay,
		projectLastDateYear: missingProjectDates ? now.year() : project.projectLastDateYear,
		projectLastDateMonth: missingProjectDates ? now.month() : project.projectLastDateMonth,
		projectLastDateDay: missingProjectDates ? now.date() : project.projectLastDateDay,
	};
};
