import Group from '../../canvas-timeline/canvas_timeline_group';
import {
	GROUP_SECTION_SPACING_LEVEL_TWO,
	GROUP_SECTION_WIDTH,
	GROUP_TYPE,
	GROUP_SECTION_TEXT_GREY_DARK,
	drawBorder,
} from '../../canvas-timeline/canvas_timeline_util';
import Util from '../../../../forecast-app/shared/util/util';
import EventManager from '../../EventManager';
import {PROJECT_ENTITY_GROUP_TYPE} from '../../constants';

export class ProjectEntityGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PROJECT_ENTITY_GROUP, data);
	}

	onItemCreate(canvasStartDate, canvasEndDate) {
		EventManager.onItemCreate(this.pageComponent, this, canvasStartDate, canvasEndDate);
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {height} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);

		const {intl} = this.pageComponent.props;
		const {formatMessage} = intl;
		const {projectEntityGroupType} = this.data;

		// top border
		drawBorder(canvasContext, x, y, width, false);

		// bottom border
		drawBorder(canvasContext, x, y + height, width, false);

		const fontSize = 11;
		canvasContext.font = `600 ${fontSize}px ` + Util.getFontFamily();
		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;

		const translationId =
			projectEntityGroupType === PROJECT_ENTITY_GROUP_TYPE.ALLOCATION
				? 'scheduling.project_entity.allocations'
				: 'scheduling.project_entity.tasks';
		canvasContext.fillText(
			formatMessage({id: translationId}),
			x + GROUP_SECTION_SPACING_LEVEL_TWO,
			y + this.itemRowHeight / 2 + fontSize / 2
		);
	}
}

export default ProjectEntityGroup;
