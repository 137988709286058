import {
	GROUP_TYPE,
	GROUP_SECTION_WIDTH,
	drawBorderLines,
	drawBackground,
	GROUP_SECTION_PADDING_LEFT,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import Util from '../../../../forecast-app/shared/util/util';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';

class TotalResourceUtilizationGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.TOTAL_RESOURCE_UTILIZATION, data);
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {height, expanded, preventExpansion} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);

		// background
		drawBackground(canvasContext, x, y, width, height, true);

		// text
		canvasContext.fillStyle = '#535353';
		canvasContext.font = '500 12px ' + Util.getFontFamily();
		const textMarginX = preventExpansion ? 15 : 40;
		canvasContext.fillText(this.data.formatMessage({id: 'scheduling.total_resource_utilization'}), x + textMarginX, y + 28);

		// borders
		drawBorderLines(canvasContext, 0, y, width, height, true);

		// expand
		if (!preventExpansion) {
			const expandIconsGap = 2;

			// draw top expand icon
			canvasContext.drawImage(
				cacheManager.getCommonImage(expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON),
				x + GROUP_SECTION_PADDING_LEFT,
				y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2 - expandIconsGap,
				GROUP_SECTION_EXPAND_ICON_WIDTH,
				GROUP_SECTION_EXPAND_ICON_HEIGHT
			);

			// draw bottom expand icon
			canvasContext.drawImage(
				cacheManager.getCommonImage(expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON),
				x + GROUP_SECTION_PADDING_LEFT,
				y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2 + expandIconsGap,
				GROUP_SECTION_EXPAND_ICON_WIDTH,
				GROUP_SECTION_EXPAND_ICON_HEIGHT
			);
		}
	}
}

export default TotalResourceUtilizationGroup;
