import React from 'react';
import Button from '../../forecast-app/shared/components/buttons/button/button';
import propTypes from 'prop-types';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';

const unitToggle = ({options}) => (
	<div className="unit-toggle">
		{options.map(({id, text, onClick, selected, userpilot, locked, tooltipProps, dataCy}, i) => (
			<Button
				id={`unit-toggle-${id}`}
				key={i}
				text={text}
				onClick={onClick}
				locked={locked}
				isDisabled={locked}
				tooltipProps={tooltipProps}
				buttonStyle={BUTTON_STYLE.FILLED}
				colorTheme={selected ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.MEDIUMGREY}
				className={'unit-toggle-button'}
				userpilot={userpilot}
				cy={`unit-toggle-${dataCy}`}
			/>
		))}
	</div>
);

unitToggle.propTypes = {
	options: propTypes.arrayOf(propTypes.object).isRequired,
	dataCy: propTypes.string,
};

export default unitToggle;
