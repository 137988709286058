import {
	createCanvasTimelineDate,
	getPhaseItems,
	getVisualizationMode,
	GROUP_TYPE,
	isTimeOffAllocation,
	ITEM_TYPE,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import TaskGroup from '../components/groups/task_group';
import TaskItem from '../components/items/task_item';
import PhaseGroup from '../components/groups/phase_group';
import PhaseItem from '../components/items/phase_item';
import ProjectSubGroup from '../components/groups/project_sub_group';
import ProjectItem from '../components/items/project_item';
import {createPersonGroup} from './projects_scheduling_data';
import {
	addDependenciesFromTasks,
	addSubTaskToMap,
	changeSubTaskDates,
	findTaskGroupInTree,
	getPhaseCompletion,
	getProgramCompletion,
	getProjectCompletion,
	getProjectGroups,
	insertTaskInGroupTree,
	sortPhaseGroupsByDate,
	updateTaskSummarizedProgress,
} from './projects_scheduling_util';
import {isEventFromScheduling, removeFromArray} from '../utils';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import PlaceholderAllocationItem from '../components/items/placeholder_allocation_item';
import CapacityPlaceholderGroup from '../components/groups/capacity_placeholder_group';
import DataManager, {DATA_ENTITIES} from '../DataManager';
import {interval} from '../RecalculationManager';
import {getAllocationInterval, getTimeRegInterval} from '../heatmap/HeatmapLogic';
import ProjectGroup from '../components/groups/project_group';
import ComposeManager from '../ComposeManager';
import IDManager from '../IDManager';
import {DATE_FROM_PRIORITY, DISTRIBUTION_TYPE, PROJECT_SUB_GROUP_TYPE} from '../constants';

function removeFromPreviousPhase(pageComponent, previousPhaseId, updatedTask, phasesGroup, phaseGroup) {
	const previousPhaseIdOrNoPhaseId = IDManager.getPhaseGroupId(pageComponent, previousPhaseId, updatedTask.projectId);
	const previousPhaseGroup = phasesGroup.groups.find(g => g.id === previousPhaseIdOrNoPhaseId);
	if (previousPhaseGroup && previousPhaseGroup !== phaseGroup) {
		previousPhaseGroup.groups = previousPhaseGroup.groups.filter(group => group.id !== updatedTask.id);
	}
}

const updateParentTaskSummarizedProgress = (pageComponent, task, parentId) => {
	if (pageComponent.props.isProjectTimeline && (parentId || task?.parentTaskId || task?.id)) {
		const {data} = pageComponent.state;
		const parentTask = data.taskMap.get(parentId || task.parentTaskId || task?.id);

		if (parentTask) {
			updateTaskSummarizedProgress(pageComponent, parentTask);
		}
	}
};

const updatePhaseCompletion = (pageComponent, phaseId, completion = undefined) => {
	if (phaseId) {
		const {items} = pageComponent.state;
		const phaseItem = items.find(item => item.groupId === phaseId);
		if (phaseItem) {
			phaseItem.data.completion = completion ?? getPhaseCompletion(pageComponent, phaseId);
		}
	}
};

const updateProgramCompletion = (pageComponent, programId) => {
	if (programId) {
		const {items} = pageComponent.state;
		const programItem = items.find(item => item.data?.program?.id === programId);
		if (programItem) {
			programItem.data.completion = getProgramCompletion(pageComponent, programId);
		}
	}
};

const getProjectSubGroup = (groups, projectOrProjectGroupId, programPrefix, groupIdKey) => {
	const projectGroups = programPrefix ? groups.find(group => group.data.program?.prefix === programPrefix)?.groups : groups;
	const parentGroup = projectGroups?.find(group => group.id === projectOrProjectGroupId);

	if (parentGroup) {
		return parentGroup.groups.find(
			group => group.groupType === GROUP_TYPE.PROJECT_SCHEDULING_PROJECT_SUB_GROUP && group.id?.includes(groupIdKey)
		);
	}
};

const addDependency = (data, dependency) => {
	data.dependencies.push({
		id: dependency.id,
		thisDependsOnTaskId: dependency.thisDependsOnTask.id,
		taskIdDependsOnThis: dependency.taskDependsOnThis.id,
		type: dependency.type,
	});
};

const onCreateTaskProgress = (pageComponent, response) => {
	const {project, phase, task} = response.createTaskProgress;
	const data = pageComponent.getData();
	if (project?.id && project?.progress !== undefined) {
		const affectedProject = data.projectMap.get(project.id);
		if (affectedProject) {
			affectedProject.completion = project.progress;
		}
		updateProgramCompletion(pageComponent, affectedProject.programId);
	}
	if (phase?.id && phase?.progress !== undefined) {
		const phaseId = phase.id;
		const affectedPhase = data.phases.find(phase => phase.id === phaseId);
		if (affectedPhase) {
			affectedPhase.completion = phase.progress;
		}
		// We also need to update the item, since progress has been copied from data to item
		updatePhaseCompletion(pageComponent, phaseId, phase.progress);
	}
	if (task?.id && task?.progressDetails?.progress !== undefined) {
		const affectedTask = data.taskMap.get(task.id);
		if (affectedTask) {
			affectedTask.progress = task.progressDetails.progress;
		}
	}
};

const onTaskUpdated = (response, pageComponent, responseTask) => {
	const data = pageComponent.getData();
	const {items, groups} = pageComponent.state;
	let changedTask;

	if (!responseTask.project?.id) {
		// Unsupported task response. Maybe from delayed non-scheduling mutation (e.g. ReorderProjectBoardTaskMutation)
		return;
	}

	const updatedTask = {
		approved: true,
		assignedPersons: responseTask.assignedPersons
			? responseTask.assignedPersons.map(assignedPerson => {
					return assignedPerson.id;
			  })
			: [],
		billable: responseTask.billable,
		blocked: responseTask.blocked,
		bug: responseTask.bug,
		companyTaskId: responseTask.companyTaskId,
		deadlineDay: responseTask.deadlineDay,
		deadlineMonth: responseTask.deadlineMonth,
		deadlineYear: responseTask.deadlineYear,
		deadlineFrom: responseTask.deadlineFrom,
		startFrom: responseTask.startFrom,
		done: responseTask.done,
		estimateForecast: responseTask.estimateForecast,
		estimateForecastMinutes: responseTask.estimateForecastMinutes,
		totalMinutesRegistered: responseTask.totalMinutesRegistered || 0,
		highPriority: responseTask.highPriority,
		id: responseTask.id,
		taskLabels: responseTask.taskLabels?.map(t => ({labelId: t.label.id})),
		projectId: responseTask.project.id,
		roleId: responseTask.role ? responseTask.role.id : undefined,
		phaseId: responseTask.phase ? responseTask.phase.id : undefined,
		statusColumnId: responseTask.statusColumnV2?.id,
		progress: responseTask.progress || 0,
		startDay: responseTask.startDay,
		startMonth: responseTask.startMonth,
		startYear: responseTask.startYear,
		timeLeft: responseTask.timeLeft,
		timeLeftMinutesWithoutFutureTimeRegs: responseTask.timeLeftMinutesWithoutFutureTimeRegs,
		name: responseTask.name,
		fullAccessToProject: true,
		readOnly: {
			isReadOnly: responseTask?.isReadOnly,
			reasons: responseTask?.reasons,
		},
		userCanDeleteTask: responseTask.userCanDeleteTask ? responseTask.userCanDeleteTask : undefined,
		userCantDeleteTaskReason: responseTask.userCantDeleteTaskReason,
		parentTaskId: responseTask.parentTaskId,
		hasChildren: responseTask.hasChildren,
	};

	const startDate = createCanvasTimelineDate(responseTask.startYear, responseTask.startMonth, responseTask.startDay);
	const endDate = createCanvasTimelineDate(responseTask.deadlineYear, responseTask.deadlineMonth, responseTask.deadlineDay);

	const affectedProject = data.projects.find(project => project.id === updatedTask.projectId);
	if (affectedProject) {
		affectedProject.completion = responseTask.project.completion;
		affectedProject.remaining = responseTask.project.remaining;
		affectedProject.forecast = responseTask.project.forecast;
		updateProgramCompletion(pageComponent, affectedProject.programId);

		const teamMembersGroup = getProjectSubGroup(
			groups,
			affectedProject.projectGroupId || affectedProject.id,
			affectedProject?.programPrefix,
			PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM
		);

		for (const personId of updatedTask.assignedPersons) {
			const groupId = IDManager.getPersonGroupId(
				pageComponent,
				personId,
				affectedProject.projectGroupId || affectedProject.id
			);
			if (teamMembersGroup) {
				const personGroup = teamMembersGroup.groups.find(group => group.id === groupId);
				if (!personGroup) {
					const person = data.persons.find(person => person.id === personId);
					if (!person.clientId) {
						teamMembersGroup.addChildGroup(createPersonGroup(pageComponent, person, affectedProject));
					}
				}
			}
		}
	}

	const projectGroups = getProjectGroups(groups);
	if (response.updateTask || response.updateTasksBulk) {
		changedTask = data.tasks.find(task => task.id === responseTask.id);
	}
	let recalculateInterval;

	if (changedTask) {
		const previousProjectId = changedTask.projectId;
		const previousPhaseId = changedTask.phaseId;
		data.tasks.splice(data.tasks.indexOf(changedTask), 1, updatedTask);
		data.taskMap.set(updatedTask.id, updatedTask);

		// If we didn't get totalMinutesRegistered from the response, just copy it from the original task, since a task-update won't affect time-registrations.
		updatedTask.totalMinutesRegistered = updatedTask.totalMinutesRegistered || changedTask.totalMinutesRegistered;

		if (previousProjectId !== updatedTask.projectId) {
			//Delete dependencies when moving task to another project since it is not possible to have dependencies between tasks in different projects
			let dependencyToDeleteIndex = data.dependencies.findIndex(dependency =>
				[dependency.thisDependsOnTaskId, dependency.taskIdDependsOnThis].includes(updatedTask.id)
			);
			while (dependencyToDeleteIndex >= 0) {
				data.dependencies.splice(dependencyToDeleteIndex, 1);
				dependencyToDeleteIndex = data.dependencies.findIndex(dependency =>
					[dependency.thisDependsOnTaskId, dependency.taskIdDependsOnThis].includes(updatedTask.id)
				);
			}
		}

		if (previousProjectId !== updatedTask.projectId || previousPhaseId !== updatedTask.phaseId) {
			const taskItem = pageComponent.state.items.find(item => item.groupId === updatedTask.id);
			taskItem.data.color = affectedProject?.projectColor;
			const projectGroup = projectGroups.find(projectGroup => projectGroup.id === previousProjectId);
			if (projectGroup) {
				const phasesGroup = projectGroup.groups.find(subGroup => subGroup.id.includes(PROJECT_SUB_GROUP_TYPE.PHASES));
				const phaseGroupId = IDManager.getPhaseGroupId(pageComponent, previousPhaseId, previousProjectId);
				const phaseGroup = phasesGroup.groups.find(phaseGroup => phaseGroup.id === phaseGroupId);
				if (phaseGroup) {
					phaseGroup.removeChildGroup(updatedTask.id);
				}
			}
			updatePhaseCompletion(pageComponent, previousPhaseId);
		}

		const projectGroup = projectGroups.find(projectGroup => projectGroup.id === updatedTask.projectId);
		if (projectGroup) {
			const phasesGroup = projectGroup.groups.find(subGroup => subGroup.id.includes(PROJECT_SUB_GROUP_TYPE.PHASES));
			const phaseGroupId = IDManager.getPhaseGroupId(pageComponent, updatedTask.phaseId, updatedTask.projectId);
			const phaseGroup = phasesGroup.groups.find(phaseGroup => phaseGroup.id === phaseGroupId);
			removeFromPreviousPhase(pageComponent, previousPhaseId, updatedTask, phasesGroup, phaseGroup);

			if (phaseGroup) {
				const taskGroup = findTaskGroupInTree(phaseGroup, updatedTask.id);

				if (taskGroup) {
					taskGroup.data.name = updatedTask.name;
					taskGroup.data.isStarted = pageComponent.isTaskStarted(updatedTask.statusColumnId);
					taskGroup.data.task = updatedTask;
				} else {
					const composedTaskGroup = ComposeManager.composeTaskGroup(pageComponent, updatedTask, 0, true);
					const updatedTaskGroup = new TaskGroup(pageComponent, composedTaskGroup);

					if (!updatedTask.parentTaskId) {
						phaseGroup.addChildGroup(updatedTaskGroup);
					} else {
						insertTaskInGroupTree(phaseGroup, updatedTaskGroup);
					}
				}

				if (!phaseGroup.expanded) {
					phaseGroup.toggleExpansion();
					pageComponent.props.expansionMap.set(phaseGroup.id, phaseGroup.expanded);
				}
			}

			if (!phasesGroup.expanded) {
				phasesGroup.toggleExpansion();
				pageComponent.props.expansionMap.set(phasesGroup.id, phasesGroup.expanded);
			}
		}

		const taskItem = items.find(item => item.itemType === ITEM_TYPE.TASK && item.groupId === updatedTask.id);

		if (taskItem) {
			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				const deleteTasksForPersons = taskItem.data.task.assignedPersons.filter(
					assignedPerson => !updatedTask.assignedPersons.includes(assignedPerson)
				);
				const updateTasksForPersons = taskItem.data.task.assignedPersons.filter(assignedPerson =>
					updatedTask.assignedPersons.includes(assignedPerson)
				);
				const createTasksForPersons = updatedTask.assignedPersons.filter(
					assignedPerson => !taskItem.data.task.assignedPersons.includes(assignedPerson)
				);

				const personGroupIds = [...deleteTasksForPersons, ...updateTasksForPersons, ...createTasksForPersons].map(
					personId => IDManager.getPersonGroupId(pageComponent, personId, updatedTask.projectId)
				);
				const personGroups = DataManager.findHeatmapGroups(groups, group => personGroupIds.includes(group.id));

				const getPersonGroup = personId => {
					return personGroups.find(
						group => group.id === IDManager.getPersonGroupId(pageComponent, personId, updatedTask.projectId)
					);
				};

				deleteTasksForPersons.forEach(assignedPerson => {
					const personGroup = getPersonGroup(assignedPerson);

					if (personGroup) {
						DataManager.removeVisibleItem(pageComponent, personGroup, taskItem);
					}
				});

				// update task item position
				recalculateInterval = interval(Math.min(taskItem.startDate, startDate), Math.max(taskItem.endDate, endDate));
				taskItem.startDate = startDate;
				taskItem.endDate = endDate;

				taskItem.updateData({...taskItem.data, phaseId: updatedTask.phaseId, task: updatedTask});

				changeSubTaskDates(pageComponent, taskItem);

				createTasksForPersons.forEach(assignedPerson => {
					const personGroup = getPersonGroup(assignedPerson);

					if (personGroup) {
						DataManager.addVisibleItem(pageComponent, personGroup, taskItem, false);
					}
				});

				updateTasksForPersons.forEach(assignedPerson => {
					const personGroup = getPersonGroup(assignedPerson);

					if (personGroup) {
						DataManager.updateVisibleItemKey(pageComponent, personGroup, taskItem, false);
					}
				});

				taskItem.updateForVisibility();
			} else {
				taskItem.startDate = startDate;
				taskItem.endDate = endDate;
				taskItem.data.phaseId = updatedTask.phaseId;

				//Seems like mutation response is giving wrong progress if nothing relevant to progress is updated
				taskItem.data.task = updatedTask;
				if (taskItem.data.task.progress === undefined) {
					taskItem.data.task.progress = taskItem.data.task.progress || 0;
				}

				changeSubTaskDates(pageComponent, taskItem);
			}
		} else {
			const taskItemData = ComposeManager.composeTaskItem(pageComponent, updatedTask);

			if (taskItemData) {
				const newTaskItem = new TaskItem(pageComponent, taskItemData);

				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					DataManager.addItem(pageComponent, newTaskItem);
				} else {
					items.push(newTaskItem);
				}
			}
		}
	} else {
		data.tasks.push(updatedTask);
		data.taskMap.set(updatedTask.id, updatedTask);
		if (updatedTask.progress === undefined) {
			updatedTask.progress = 0;
		}
		const project = data.projects.find(project => project.id === updatedTask.projectId);
		const projectGroup = projectGroups.find(projectGroup => projectGroup.id === updatedTask.projectId);
		if (!projectGroup) return;
		const phasesGroup = projectGroup.groups.find(subGroup => subGroup.id.includes(PROJECT_SUB_GROUP_TYPE.PHASES));
		const phaseGroupId = IDManager.getPhaseGroupId(pageComponent, updatedTask.phaseId, updatedTask.projectId);
		let phaseGroup = phasesGroup.groups.find(phaseGroup => phaseGroup.id === phaseGroupId);

		if (!phaseGroup) {
			if (!updatedTask.phaseId) {
				const noPhaseGroupData = ComposeManager.composePhaseGroup(pageComponent, null, project.id);
				if (noPhaseGroupData) {
					phasesGroup.addChildGroup(new PhaseGroup(pageComponent, noPhaseGroupData));
				}
			}
			if (!phaseGroup.expanded) {
				phaseGroup.toggleExpansion();
				pageComponent.props.expansionMap.set(phaseGroup.id, phaseGroup.expanded);
			}
		}

		if (!phasesGroup.expanded) {
			phasesGroup.toggleExpansion();
			pageComponent.props.expansionMap.set(phasesGroup.id, phasesGroup.expanded);
		}

		const newTaskGroup = new TaskGroup(pageComponent, ComposeManager.composeTaskGroup(pageComponent, updatedTask));
		if (!updatedTask.parentTaskId) {
			phaseGroup.addChildGroup(newTaskGroup);
		} else {
			addSubTaskToMap(pageComponent, updatedTask);
			insertTaskInGroupTree(phaseGroup, newTaskGroup);
		}

		const taskItemData = ComposeManager.composeTaskItem(pageComponent, updatedTask);
		if (taskItemData) {
			const newTaskItem = new TaskItem(pageComponent, taskItemData);

			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				const personGroupIds = updatedTask.assignedPersons.map(assignedPerson =>
					IDManager.getPersonGroupId(pageComponent, assignedPerson, updatedTask.projectId)
				);
				const heatmapGroupsPredicate = group => personGroupIds.includes(group.id);
				DataManager.addItemForMultipleGroups(pageComponent, newTaskItem, heatmapGroupsPredicate);
			} else {
				items.push(newTaskItem);
			}
		}
	}

	updatePhaseCompletion(pageComponent, updatedTask.phaseId);

	// update task summarized progress
	updateParentTaskSummarizedProgress(
		pageComponent,
		null,
		updatedTask.parentTaskId ? updatedTask.parentTaskId : updatedTask.id
	);

	if (response.duplicateTask) {
		// Duplicate dependencies
		const dependsOnThisTask = responseTask.dependsOnThisTask?.edges;
		if (dependsOnThisTask) {
			dependsOnThisTask.forEach(dependencyEdge => addDependency(data, dependencyEdge.node));
		}
		const thisTaskDependsOn = responseTask.thisTaskDependsOn?.edges;
		if (thisTaskDependsOn) {
			thisTaskDependsOn.forEach(dependencyEdge => addDependency(data, dependencyEdge.node));
		}
		if (dependsOnThisTask || thisTaskDependsOn) {
			pageComponent.setState({dependencies: data.dependencies});
		}
	}

	// Update timeline heatmap cache
	if (pageComponent.props.isProjectTimeline) {
		const affectedPersons = [...updatedTask.assignedPersons];
		if (changedTask) {
			changedTask.assignedPersons.forEach(personId => {
				if (!affectedPersons.includes(personId)) {
					affectedPersons.push(personId);
				}
			});
		}

		// delete cache for affected persons
		if (!hasFeatureFlag('scheduling_recalculation_tree') || recalculateInterval) {
			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				affectedPersons.forEach(personId => {
					const cacheKey = IDManager.getPersonGroupId(pageComponent, personId, changedTask.projectId);
					pageComponent.clearHeatmapCache(cacheKey, recalculateInterval);
				});
			} else {
				affectedPersons.forEach(personId => {
					const cacheKey = IDManager.getPersonGroupId(
						pageComponent,
						personId,
						affectedProject.projectGroupId || affectedProject.id
					);
					pageComponent.clearHeatmapCache(cacheKey, recalculateInterval);
				});
			}
		}
	}
};

export const handleProjectsSchedulingMutation = (pageComponent, response, args) => {
	const data = pageComponent.getData();
	const {items, groups} = pageComponent.state;

	if (!isEventFromScheduling(args)) {
		return;
	}

	function addPersonGroup(personId, projectOrProjectGroupId) {
		const groupId = IDManager.getPersonGroupId(pageComponent, personId, projectOrProjectGroupId);
		const project =
			data.projects.find(project => project.id === projectOrProjectGroupId) ||
			data.projectGroups.find(pg => pg.id === projectOrProjectGroupId);
		const teamMembersGroup = getProjectSubGroup(
			groups,
			projectOrProjectGroupId,
			project?.programPrefix,
			PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM
		);

		if (teamMembersGroup) {
			const personGroup = teamMembersGroup.groups.find(group => group.id === groupId);
			if (!personGroup) {
				const person = DataManager.getPersonById(pageComponent, personId);
				if (!person.clientId) {
					teamMembersGroup.addChildGroup(createPersonGroup(pageComponent, person, project));
				}
			}
		}
	}

	function createOrUpdateAllocation(responseAllocation) {
		const formattedAllocation = {
			id: responseAllocation.id,
			description: responseAllocation.description,
			monday: responseAllocation.monday,
			tuesday: responseAllocation.tuesday,
			wednesday: responseAllocation.wednesday,
			thursday: responseAllocation.thursday,
			friday: responseAllocation.friday,
			saturday: responseAllocation.saturday,
			sunday: responseAllocation.sunday,
			startYear: responseAllocation.startYear,
			startMonth: responseAllocation.startMonth,
			startDay: responseAllocation.startDay,
			endYear: responseAllocation.endYear,
			endMonth: responseAllocation.endMonth,
			endDay: responseAllocation.endDay,
			personId: responseAllocation.person.id,
			projectGroupId: responseAllocation.projectGroupId,
			projectGroupColor: responseAllocation.projectGroupColor,
			projectId: responseAllocation.project ? responseAllocation.project.id : null,
			idleTimeId: responseAllocation.idleTime ? responseAllocation.idleTime.id : null,
			idleTimeName: responseAllocation.idleTime ? responseAllocation.idleTime.name : null,
			isIdleTimeInternal: responseAllocation.idleTime ? responseAllocation.idleTime.isInternalTime : null,
			registerTime: responseAllocation.registerTime,
			isSoft: responseAllocation.isSoft,
		};

		const allocationItemData = ComposeManager.composeProjectAllocation(pageComponent, formattedAllocation);
		if (allocationItemData) {
			const changedAllocation = data.allocations.find(allocation => allocation.id === responseAllocation.id);

			if (changedAllocation) {
				data.allocations.splice(data.allocations.indexOf(changedAllocation), 1, formattedAllocation);

				if (!hasFeatureFlag('inverted_pto_non_working_days')) {
					DataManager.removeFromLookupMaps(pageComponent, DATA_ENTITIES.ALLOCATIONS, changedAllocation);
					DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.ALLOCATIONS, formattedAllocation);
				}

				// Remove for old person
				const allocationsForOldPerson = data.personAllocationMap[changedAllocation.personId] || [];
				data.personAllocationMap[changedAllocation.personId] = allocationsForOldPerson.filter(
					allocation => allocation.id !== changedAllocation.id
				);

				// Add for new person
				const allocationsForNewPerson = data.personAllocationMap[formattedAllocation.personId] || [];
				data.personAllocationMap[formattedAllocation.personId] = allocationsForNewPerson;
				allocationsForNewPerson.push(formattedAllocation);

				const allocationItem = items.find(
					item => item.data.allocation && item.data.allocation.id === responseAllocation.id
				);

				if (allocationItem) {
					if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
						const {groups} = pageComponent.state;
						const currentGroup = DataManager.findHeatmapGroupById(allocationItem.groupId, groups);
						DataManager.moveItem(pageComponent, allocationItem, allocationItemData, currentGroup, null, true);
					} else {
						allocationItem.resetItemRow();
						allocationItem.updateData(allocationItemData, true);
					}
				}
			} else {
				data.allocations.push(formattedAllocation);

				if (hasFeatureFlag('inverted_pto_non_working_days')) {
					const allocationsForPerson = data.personAllocationMap[formattedAllocation.personId] || [];
					data.personAllocationMap[formattedAllocation.personId] = allocationsForPerson;
					allocationsForPerson.push(formattedAllocation);
				} else {
					DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.ALLOCATIONS, formattedAllocation);
					if (isTimeOffAllocation(formattedAllocation)) {
						const interval = getAllocationInterval(formattedAllocation);
						const personGroupId = IDManager.getPersonGroupId(pageComponent, formattedAllocation.personId);
						DataManager.updateItemsAffectedByTimeOff(
							pageComponent,
							formattedAllocation.personId,
							personGroupId,
							interval
						);
					}
				}

				const allocationItem = new ProjectAllocationItem(pageComponent, allocationItemData);
				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					DataManager.addItem(pageComponent, allocationItem);
				} else {
					items.push(allocationItem);
				}
			}

			addPersonGroup(formattedAllocation.personId, formattedAllocation.projectGroupId || formattedAllocation.projectId);
			return formattedAllocation;
		}

		return null;
	}

	function deleteAllocation(allocationToDelete) {
		const deletedAllocationId = allocationToDelete.id;
		data.allocations.splice(data.allocations.indexOf(allocationToDelete), 1);

		if (hasFeatureFlag('inverted_pto_non_working_days')) {
			const allocationsForPerson = data.personAllocationMap[allocationToDelete.personId] || [];
			data.personAllocationMap[allocationToDelete.personId] = allocationsForPerson.filter(
				allocation => allocation.id !== allocationToDelete.id
			);
		} else {
			DataManager.removeFromLookupMaps(pageComponent, DATA_ENTITIES.ALLOCATIONS, allocationToDelete);
			if (isTimeOffAllocation(allocationToDelete)) {
				const interval = getAllocationInterval(allocationToDelete);
				const personGroupId = IDManager.getPersonGroupId(pageComponent, allocationToDelete.personId);
				DataManager.updateItemsAffectedByTimeOff(pageComponent, allocationToDelete.personId, personGroupId, interval);
			}
		}

		if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
			DataManager.removeItem(pageComponent, item => item.data.allocation?.id === deletedAllocationId);
		} else {
			const itemIndex = items.findIndex(item => item.data.allocation && item.data.allocation.id === deletedAllocationId);
			items.splice(itemIndex, 1);
		}
	}

	function createProjectPerson(projectOrProjectGroupId, projectPerson) {
		const personId = projectPerson.person.id;
		const projectId = projectPerson.project.id;
		const person = DataManager.getPersonById(pageComponent, personId);
		const newProjectPerson = {
			id: projectPerson.id,
			personId: person.id,
			roleId: person.roleId,
			projectId: projectId,
		};

		const alreadyExists = DataManager.getProjectPersonsByPersonId(pageComponent, person.id).some(
			pp => pp.projectId === projectId
		);
		if (!alreadyExists) {
			DataManager.mergeEntity(pageComponent, newProjectPerson, DATA_ENTITIES.PROJECT_PERSONS);
		}

		addPersonGroup(personId, projectOrProjectGroupId);
	}

	function deleteProjectPerson(projectId, projectPerson) {
		const projectPersonId = projectPerson.id;
		const personId = projectPerson.personId;

		DataManager.removeFromLookupMaps(pageComponent, DATA_ENTITIES.PROJECT_PERSONS, projectPerson);
		removeFromArray(data.projectPersons, projectPerson => projectPerson.id === projectPersonId);

		const project = data.projects.find(project => project.id === projectId);
		const teamMembersGroup = getProjectSubGroup(
			groups,
			project.projectGroupId || project.id,
			project?.programPrefix,
			PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM
		);

		if (teamMembersGroup?.groups) {
			removeFromArray(
				teamMembersGroup.groups,
				group => group.id === IDManager.getPersonGroupId(pageComponent, personId, project.projectGroupId || project.id)
			);
		}
	}

	const addCapacityPlaceholderGroup = formattedPlaceholder => {
		const groupId = `${formattedPlaceholder.projectGroupId || formattedPlaceholder.projectId}-${formattedPlaceholder.id}`;

		const project = formattedPlaceholder.projectGroupId
			? data.projectGroups.find(projectGroup => projectGroup.id === formattedPlaceholder.projectGroupId)
			: data.projects.find(project => project.id === formattedPlaceholder.projectId);

		const placeholdersProjectSubGroup = getProjectSubGroup(
			groups,
			formattedPlaceholder.projectGroupId || formattedPlaceholder.projectId,
			project?.programPrefix,
			PROJECT_SUB_GROUP_TYPE.PLACEHOLDERS
		);

		if (placeholdersProjectSubGroup) {
			const placeholderGroupToAdd = new CapacityPlaceholderGroup(
				pageComponent,
				ComposeManager.composeCapacityPlaceholderGroup(pageComponent, formattedPlaceholder)
			);

			const placeholderGroup = placeholdersProjectSubGroup.groups.find(group => group.id === groupId);
			if (!placeholderGroup) {
				placeholdersProjectSubGroup.addChildGroup(placeholderGroupToAdd);
			} else {
				placeholdersProjectSubGroup.replaceChildGroup(placeholderGroup, placeholderGroupToAdd);
			}

			placeholdersProjectSubGroup.setExpanded(true);
		}
	};

	const createOrUpdatePlaceholderAllocation = responsePlaceholderAllocation => {
		const startDate = new Date(responsePlaceholderAllocation.startDate);
		const endDate = new Date(responsePlaceholderAllocation.endDate);

		const formattedAllocation = {
			id: responsePlaceholderAllocation.id,
			description: responsePlaceholderAllocation.description,
			monday: responsePlaceholderAllocation.monday,
			tuesday: responsePlaceholderAllocation.tuesday,
			wednesday: responsePlaceholderAllocation.wednesday,
			thursday: responsePlaceholderAllocation.thursday,
			friday: responsePlaceholderAllocation.friday,
			saturday: responsePlaceholderAllocation.saturday,
			sunday: responsePlaceholderAllocation.sunday,
			startDate: responsePlaceholderAllocation.startDate,
			endDate: responsePlaceholderAllocation.endDate,
			startYear: startDate.getUTCFullYear(),
			startMonth: startDate.getUTCMonth() + 1,
			startDay: startDate.getUTCDate(),
			endYear: endDate.getUTCFullYear(),
			endMonth: endDate.getUTCMonth() + 1,
			endDay: endDate.getUTCDate(),
			placeholderId: responsePlaceholderAllocation.placeholder.id,
		};

		const placeholderAllocationItemData = ComposeManager.composePlaceholderAllocation(pageComponent, formattedAllocation);

		if (placeholderAllocationItemData) {
			const changedAllocation = data.placeholderAllocations.find(
				placeholderAllocation => placeholderAllocation.id === responsePlaceholderAllocation.id
			);

			if (changedAllocation) {
				data.placeholderAllocations.splice(
					data.placeholderAllocations.indexOf(changedAllocation),
					1,
					formattedAllocation
				);

				const allocationsForOldPlaceholder =
					data.placeholderAllocationsByPlaceholder[changedAllocation.placeholderId] || [];
				data.placeholderAllocationsByPlaceholder[changedAllocation.placeholderId] = allocationsForOldPlaceholder.filter(
					allocation => allocation.id !== changedAllocation.id
				);

				const placeholderAllocationItem = items.find(
					item =>
						item.data.placeholderAllocation &&
						item.data.placeholderAllocation.id === responsePlaceholderAllocation.id
				);

				if (placeholderAllocationItem) {
					placeholderAllocationItem.resetItemRow();
					placeholderAllocationItem.updateData(placeholderAllocationItemData, true);
				}
			} else {
				data.placeholderAllocations.push(formattedAllocation);
				items.push(new PlaceholderAllocationItem(pageComponent, placeholderAllocationItemData));
			}
			const allocationsForNewPlaceholder =
				data.placeholderAllocationsByPlaceholder[formattedAllocation.placeholderId] || [];
			data.placeholderAllocationsByPlaceholder[formattedAllocation.placeholderId] = allocationsForNewPlaceholder;
			allocationsForNewPlaceholder.push(formattedAllocation);
		}
	};

	const createOrUpdateMultiplePlaceholderAllocations = placeholderAllocations => {
		if (!placeholderAllocations) return;
		placeholderAllocations.forEach(placeholderAllocation => createOrUpdatePlaceholderAllocation(placeholderAllocation));
	};

	const deletePlaceholderAllocation = placeholderAllocationId => {
		// remove from placeholderAllocationsByPlaceholder map
		const allocationToDelete = data.placeholderAllocations.find(allocation => allocation.id === placeholderAllocationId);

		// remove from lookup array
		const allocationsByPlaceholderId = data.placeholderAllocationsByPlaceholder[allocationToDelete.placeholderId] || [];
		data.placeholderAllocationsByPlaceholder[allocationToDelete.placeholderId] = allocationsByPlaceholderId.filter(
			allocation => allocation.id !== placeholderAllocationId
		);

		// remove from placeholder allocations data
		data.placeholderAllocations = data.placeholderAllocations.filter(
			allocation => allocation.id !== placeholderAllocationId
		);

		// remove from items
		removeFromArray(items, item => item.data.placeholderAllocation?.id === placeholderAllocationId);
	};

	const deletePlaceholder = placeholderId => {
		// find deleted placeholder
		const placeholderToDelete = data.placeholders.find(placeholder => placeholder.id === placeholderId);

		// remove placeholder allocations
		const placeholderAllocations = data.placeholderAllocationsByPlaceholder[placeholderId];
		if (placeholderAllocations) {
			placeholderAllocations.forEach(allocation => deletePlaceholderAllocation(allocation.id));
		}

		// remove from data
		removeFromArray(data.placeholders, placeholder => placeholder.id === placeholderId);
		removeFromArray(data.placeholderSkills, placeholderSkill => placeholderSkill.placeholderId === placeholderId);

		// remove from placeholder allocations map
		const placeholderToDeleteAllocations = data.placeholderAllocationsByPlaceholder[placeholderId];
		if (placeholderToDeleteAllocations) {
			delete data.placeholderAllocationsByPlaceholder[placeholderId];
		}

		// remove from skills map
		const placeholderToDeleteSkills = data.placeholderSkillsByPlaceholder[placeholderId];
		if (placeholderToDeleteSkills) {
			delete data.placeholderSkillsByPlaceholder[placeholderId];
		}

		const project = placeholderToDelete.projectGroupId
			? data.projectGroups.find(projectGroup => projectGroup.id === placeholderToDelete.projectGroupId)
			: data.projects.find(project => project.id === placeholderToDelete.projectId);

		// remove from groups
		const placeholdersSubGroup = getProjectSubGroup(
			groups,
			placeholderToDelete.projectGroupId || placeholderToDelete.projectId,
			project?.programPrefix,
			PROJECT_SUB_GROUP_TYPE.PLACEHOLDERS
		);
		if (placeholdersSubGroup?.groups) {
			removeFromArray(
				placeholdersSubGroup.groups,
				group => group.id === `${placeholderToDelete.projectGroupId || placeholderToDelete.projectId}-${placeholderId}`
			);
		}
	};

	const createOrUpdatePlaceholder = responsePlaceholder => {
		const formattedPlaceholder = {
			id: responsePlaceholder.id,
			projectId: responsePlaceholder.project?.id,
			projectGroupId: responsePlaceholder.projectGroupId,
			name: responsePlaceholder.name,
			roleId: responsePlaceholder.role?.id,
			departmentId: responsePlaceholder.departmentId,
		};

		const project = formattedPlaceholder.projectId
			? data.projects.find(project => project.id === formattedPlaceholder.projectId)
			: data.projectGroups.find(projectGroup => projectGroup.id === formattedPlaceholder.projectGroupId);
		if (!project) return;

		const alreadyExists = data.placeholders.find(placeholder => placeholder.id === formattedPlaceholder.id);

		const placeholderSkills = responsePlaceholder.skillPlaceholders.map(sp => ({
			skillId: sp.skill.id,
			skillLevelId: sp.level?.id,
			placeholderId: formattedPlaceholder.id,
		}));

		if (!alreadyExists) {
			DataManager.mergeEntity(pageComponent, formattedPlaceholder, DATA_ENTITIES.PLACEHOLDERS);
		}

		// replace skills
		data.placeholderSkills = data.placeholderSkills
			.filter(ps => ps.placeholderId !== formattedPlaceholder.id)
			.concat(...placeholderSkills);

		// add to skills map
		data.placeholderSkillsByPlaceholder[formattedPlaceholder.id] = placeholderSkills;

		addCapacityPlaceholderGroup(formattedPlaceholder);

		const placeholderAllocations = responsePlaceholder.placeholderAllocations?.edges.map(edge => edge.node);
		createOrUpdateMultiplePlaceholderAllocations(placeholderAllocations);
	};

	if (response.splitPlaceholderAllocation || response.bulkPlaceholderAllocations) {
		const placeholderAllocations =
			response.splitPlaceholderAllocation?.placeholderAllocations ||
			response.bulkPlaceholderAllocations.placeholderAllocations;
		createOrUpdateMultiplePlaceholderAllocations(placeholderAllocations);
	} else if (response.replacePlaceholder) {
		const placeholderId = response.replacePlaceholder.placeholderId;
		const shouldDeletePlaceholder = response.replacePlaceholder.deletePlaceholder;
		const deletedPlaceholderAllocationIds = response.replacePlaceholder.deletedPlaceholderAllocationIds;
		const allocations = response.replacePlaceholder.allocations?.map(edge => edge.node);
		let lastAllocation;

		if (allocations) {
			for (const allocation of allocations) {
				createOrUpdateAllocation(allocation);
				lastAllocation = allocation;
			}
		}

		if (shouldDeletePlaceholder) {
			deletePlaceholder(placeholderId);
		} else {
			deletedPlaceholderAllocationIds.forEach(deletedPlaceholderAllocationId =>
				deletePlaceholderAllocation(deletedPlaceholderAllocationId)
			);
		}

		let projectId = lastAllocation.projectGroupId || lastAllocation.project.id;
		if (projectId) {
			const groupId = IDManager.getPersonGroupId(pageComponent, lastAllocation.person.id, projectId);
			pageComponent.clearHeatmapCache(groupId);
		}
	} else if (response.assignPlaceholderAllocationsToPerson) {
		const {
			allocations: allocations1,
			placeholderWasDeleted,
			placeholderId,
			deletedPlaceholderAllocationIds: deletedPlaceholderAllocationIds1,
		} = response.assignPlaceholderAllocationsToPerson;
		const deletedPlaceholderAllocationIds = deletedPlaceholderAllocationIds1;
		const allocations = allocations1?.map(edge => edge.node);
		let lastAllocation;
		for (const allocation of allocations) {
			createOrUpdateAllocation(allocation);
			lastAllocation = allocation;
		}
		deletedPlaceholderAllocationIds.forEach(deletedPlaceholderAllocationId =>
			deletePlaceholderAllocation(deletedPlaceholderAllocationId)
		);
		if (placeholderWasDeleted) {
			deletePlaceholder(placeholderId);
		}
		let projectId = lastAllocation?.projectGroupId || lastAllocation?.project.id;
		if (projectId) {
			const groupId = IDManager.getPersonGroupId(pageComponent, lastAllocation.person.id, projectId);
			pageComponent.clearHeatmapCache(groupId);
		}
	} else if (
		response.createPlaceholderAllocation ||
		response.updatePlaceholderAllocation ||
		response.duplicatePlaceholderAllocation
	) {
		const operation =
			response.createPlaceholderAllocation ||
			response.updatePlaceholderAllocation ||
			response.duplicatePlaceholderAllocation;
		const placeholderAllocation = operation.placeholderAllocation.node || operation.placeholderAllocation;
		createOrUpdatePlaceholderAllocation(placeholderAllocation);
	} else if (response.deletePlaceholderAllocation) {
		deletePlaceholderAllocation(response.deletePlaceholderAllocation.deletedAllocationId);
	} else if (response.createPlaceholder || response.updatePlaceholder || response.duplicatePlaceholder) {
		const operation = response.updatePlaceholder || response.createPlaceholder || response.duplicatePlaceholder;
		const responsePlaceholder = operation.placeholder.node;
		createOrUpdatePlaceholder(responsePlaceholder);
	} else if (response.deletePlaceholder) {
		const deletedPlaceholderId = response.deletePlaceholder.deletedPlaceholderId;
		deletePlaceholder(deletedPlaceholderId);
	} else if (response.transferPlaceholder) {
		if (response.transferPlaceholder.placeholderAllocation) {
			const placeholderAllocation = response.transferPlaceholder.placeholderAllocation;
			createOrUpdatePlaceholderAllocation(placeholderAllocation);
		} else if (response.transferPlaceholder.deletedPlaceholderAllocationId) {
			const deletedPlaceholderAllocationId = response.transferPlaceholder.deletedPlaceholderAllocationId;
			deletePlaceholderAllocation(deletedPlaceholderAllocationId);
		}

		// create project allocation
		const allocation = response.transferPlaceholder.personAllocation;
		if (allocation) {
			createOrUpdateAllocation(allocation);

			let projectId = allocation.projectGroupId || allocation.project.id;
			if (projectId) {
				const groupId = IDManager.getPersonGroupId(pageComponent, allocation.person.id, projectId);
				pageComponent.clearHeatmapCache(groupId);
			}
		}
	} else if (response.createAllocation || response.updateAllocation) {
		let responseAllocation = response.updateAllocation
			? response.updateAllocation.allocation
			: response.createAllocation.allocation.node;
		const allocation = createOrUpdateAllocation(responseAllocation);

		let projectId = allocation.projectGroupId || allocation.projectId;

		if (!projectId) {
			// idle time allocations
			const project = groups[0];
			projectId = project?.id;
		}

		if (projectId && !hasFeatureFlag('scheduling_recalculation_tree')) {
			const groupId = IDManager.getPersonGroupId(pageComponent, allocation.personId, projectId);
			pageComponent.clearHeatmapCache(groupId);
		}
	} else if (response.deleteAllocation) {
		if (response.deleteAllocation.errors) return;
		const allocationToDelete = data.allocations.find(
			allocation => allocation.id === response.deleteAllocation.deletedAllocationId
		);
		deleteAllocation(allocationToDelete);

		const cacheKey = IDManager.getPersonGroupId(
			pageComponent,
			allocationToDelete.personId,
			allocationToDelete.projectGroupId || allocationToDelete.projectId
		);

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			pageComponent.clearHeatmapCache(cacheKey);
		}
	} else if (response.createMultipleAllocations) {
		const project = response.createMultipleAllocations.project || response.createMultipleAllocations.projectGroup;
		if (project?.allocations?.edges) {
			for (const allocationEdge of project.allocations.edges) {
				createOrUpdateAllocation(allocationEdge.node);
			}
		}
	} else if (response.createProjectPerson) {
		const projectId = response.createProjectPerson.project?.id || response.createProjectPerson.projectGroup?.id;
		if (response.createProjectPerson.projectPersons) {
			for (const projectPerson of response.createProjectPerson.projectPersons) {
				createProjectPerson(projectId, projectPerson);
			}
		}
	} else if (response.deleteProjectPerson) {
		const deletedProjectPersonIds = response.deleteProjectPerson.deletedProjectPersonIds;
		const deletedProjectPersons = data.projectPersons.filter(projectPerson =>
			deletedProjectPersonIds.includes(projectPerson.id)
		);
		for (const deletedProjectPerson of deletedProjectPersons) {
			const projectGroupId = response.deleteProjectPerson.projectGroup?.id;
			const allocationsToDelete = data.allocations.filter(
				allocation =>
					allocation.personId === deletedProjectPerson.personId &&
					(allocation.projectId === deletedProjectPerson.projectId ||
						(projectGroupId && allocation.projectGroupId === projectGroupId))
			);
			for (const allocationToDelete of allocationsToDelete) {
				deleteAllocation(allocationToDelete);
			}
			deleteProjectPerson(deletedProjectPerson.projectId, deletedProjectPerson);
		}
	} else if (response.updateTask || response.createTask || response.duplicateTask) {
		const responseTask =
			response.updateTask && response.updateTask.tasks
				? response.updateTask.tasks[0]
				: response.updateTask && response.updateTask.taskEdge
				? response.updateTask.taskEdge.node
				: response.duplicateTask
				? response.duplicateTask.task.node
				: response.createTask.task.node;
		onTaskUpdated(response, pageComponent, responseTask);
	} else if (response.updateTasksBulk) {
		const updatedTasks = response.updateTasksBulk.tasks;
		for (const responseTask of updatedTasks) {
			onTaskUpdated(response, pageComponent, responseTask);
		}
	} else if (response.deleteTask) {
		const taskToDelete = data.tasks.find(task => task.id === response.deleteTask.deletedTasksIds[0]);
		const taskParentId = taskToDelete.parentTaskId;
		data.tasks.splice(data.tasks.indexOf(taskToDelete), 1);
		data.taskMap.delete(response.deleteTask.deletedTasksIds[0]);

		if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
			const personGroupIds = taskToDelete.assignedPersons.map(assignedPerson =>
				IDManager.getPersonGroupId(pageComponent, assignedPerson, taskToDelete.projectId)
			);
			const heatmapGroupsPredicate = group => personGroupIds.includes(group.id);
			const removeItemPredicate = item => item.groupId === response.deleteTask.deletedTasksIds[0];
			DataManager.removeItemForMultipleGroups(pageComponent, removeItemPredicate, heatmapGroupsPredicate);
		} else {
			const itemIndex = items.findIndex(
				item => item.itemType === ITEM_TYPE.TASK && item.groupId === response.deleteTask.deletedTasksIds[0]
			);
			items.splice(itemIndex, 1);
		}

		const affectedProject = data.projects.find(project => project.id === taskToDelete.projectId);
		affectedProject.completion = response.deleteTask.project.completion;
		affectedProject.remaining = response.deleteTask.project.remaining;
		affectedProject.forecast = response.deleteTask.project.forecast;
		updateProgramCompletion(pageComponent, affectedProject.programId);
		updatePhaseCompletion(pageComponent, taskToDelete.phaseId);

		if (taskParentId) {
			updateParentTaskSummarizedProgress(pageComponent, null, taskParentId);
		}

		// Update timeline heatmap cache
		if (pageComponent.props.isProjectTimeline && !hasFeatureFlag('scheduling_recalculation_tree')) {
			const affectedPersons = taskToDelete.assignedPersons;
			// delete cache for affected persons
			affectedPersons.forEach(personId => {
				const cacheKey = IDManager.getPersonGroupId(
					pageComponent,
					personId,
					affectedProject.projectGroupId || affectedProject.id
				);
				pageComponent.clearHeatmapCache(cacheKey);
			});
		}

		const projectGroup = getProjectGroups(groups).find(projectGroup => projectGroup.id === taskToDelete.projectId);
		if (!projectGroup) return;
		const phaseGroup = projectGroup.groups[0].groups.find(
			phaseGroup =>
				phaseGroup.id === IDManager.getPhaseGroupId(pageComponent, taskToDelete.phaseId, taskToDelete.projectId)
		);
		if (!phaseGroup) return;
		phaseGroup.removeChildGroup(taskToDelete.id);
	} else if (response.createTimeReg || response.updateTimeReg) {
		const responseTimeRegistration = response.updateTimeReg
			? response.updateTimeReg.timeReg
			: response.createTimeReg.timeReg.node;
		const updatedTimeRegistration = {
			id: responseTimeRegistration.id,
			day: responseTimeRegistration.day,
			month: responseTimeRegistration.month,
			year: responseTimeRegistration.year,
			minutesRegistered: responseTimeRegistration.minutesRegistered,
			personId: responseTimeRegistration.person.id,
			taskId: responseTimeRegistration.task && responseTimeRegistration.task.id,
			projectId: responseTimeRegistration.project && responseTimeRegistration.project.id,
			idleTimeId: responseTimeRegistration.idleTime && responseTimeRegistration.idleTime.id,
			isIdleTimeInternal: responseTimeRegistration.idleTime ? responseTimeRegistration.idleTime.isInternalTime : null,
			canvasTimelineDate: responseTimeRegistration.canvasTimelineDate,
		};

		const changedTimeRegistration = response.updateTimeReg
			? data.timeRegistrations.find(timeRegistration => timeRegistration.id === responseTimeRegistration.id)
			: undefined;

		const changedTask = data.taskMap.get(updatedTimeRegistration.taskId);
		// Since we're changing an existing time-reg, remove the old minutes from the project, before updating
		const minutesRegisteredDiff =
			responseTimeRegistration.minutesRegistered - (changedTimeRegistration?.minutesRegistered || 0);
		const timeLeftDiff = responseTimeRegistration.task.timeLeft - (changedTask.timeLeft || 0);
		changedTask.progress = responseTimeRegistration.task.progress;
		changedTask.timeLeft = responseTimeRegistration.task.timeLeft;
		changedTask.totalMinutesRegistered += minutesRegisteredDiff;

		const affectedProject = data.projectMap.get(changedTask.projectId);
		const cacheKey =
			pageComponent.props.isProjectTimeline && !data.company.isUsingSchedulingPlanMode
				? IDManager.getPersonGroupId(
						pageComponent,
						updatedTimeRegistration.personId,
						affectedProject.projectGroupId || affectedProject.id
				  )
				: undefined;

		if (updatedTimeRegistration.taskId) {
			affectedProject.remaining -= timeLeftDiff;
			affectedProject.reported += minutesRegisteredDiff;
			affectedProject.completion = getProjectCompletion(pageComponent, affectedProject.id);
			updateProgramCompletion(pageComponent, affectedProject.programId);

			const timeRegsByTask = data.timeRegsByTaskMap[changedTask.id];
			const timeRegsByPerson = data.timeRegsByPersonMap[updatedTimeRegistration.personId];

			if (response.updateTimeReg) {
				removeFromArray(timeRegsByTask, timeReg => timeReg.id === responseTimeRegistration.id, updatedTimeRegistration);
				removeFromArray(
					timeRegsByPerson,
					timeReg => timeReg.id === responseTimeRegistration.id,
					updatedTimeRegistration
				);
			} else {
				if (timeRegsByTask) {
					timeRegsByTask.push(updatedTimeRegistration);
				} else {
					data.timeRegsByTaskMap[changedTask.id] = [updatedTimeRegistration];
				}

				if (timeRegsByPerson) {
					timeRegsByPerson.push(updatedTimeRegistration);
				} else {
					data.timeRegsByPersonMap[updatedTimeRegistration.personId] = [updatedTimeRegistration];
				}
			}

			updatePhaseCompletion(pageComponent, changedTask.phaseId);
		}

		if (changedTimeRegistration) {
			if (cacheKey) {
				pageComponent.clearHeatmapCache(cacheKey, getTimeRegInterval(changedTimeRegistration));
			}
			data.timeRegistrations.splice(data.timeRegistrations.indexOf(changedTimeRegistration), 1, updatedTimeRegistration);
		} else {
			data.timeRegistrations.push(updatedTimeRegistration);
		}

		// update task summarized progress
		updateParentTaskSummarizedProgress(pageComponent, changedTask);

		// Update timeline heatmap cache
		if (cacheKey) {
			pageComponent.clearHeatmapCache(cacheKey, getTimeRegInterval(updatedTimeRegistration));
		}
	} else if (response.deleteTimeReg) {
		const timeRegistrationToDelete = data.timeRegistrations.find(
			timeRegistration => timeRegistration.id === response.deleteTimeReg.deletedTimeRegId
		);

		if (!timeRegistrationToDelete) return;
		data.timeRegistrations.splice(data.timeRegistrations.indexOf(timeRegistrationToDelete), 1);

		const changedTask = data.tasks.find(task => task.id === timeRegistrationToDelete.taskId);
		const affectedProject = data.projects.find(project => project.id === changedTask.projectId);
		if (changedTask) {
			changedTask.progress = response.deleteTimeReg.task.progress;
			changedTask.timeLeft = response.deleteTimeReg.task.timeLeft;
			changedTask.totalMinutesRegistered -= timeRegistrationToDelete.minutesRegistered;
			affectedProject.completion = response.deleteTimeReg.task.project.completion;
			affectedProject.remaining = response.deleteTimeReg.task.project.remaining;
			affectedProject.forecast = response.deleteTimeReg.task.project.forecast;
			affectedProject.reported -= timeRegistrationToDelete.minutesRegistered;
			updateProgramCompletion(pageComponent, affectedProject.programId);

			const timeRegsByTask = data.timeRegsByTaskMap[changedTask.id] || [];
			removeFromArray(timeRegsByTask, timeReg => timeReg.id === timeRegistrationToDelete.id);

			const timeRegsByPerson = data.timeRegsByPersonMap[timeRegistrationToDelete.personId] || [];
			removeFromArray(timeRegsByPerson, timeReg => timeReg.id === timeRegistrationToDelete.id);

			updatePhaseCompletion(pageComponent, changedTask.phaseId);
		}

		// update task summarized progress
		updateParentTaskSummarizedProgress(pageComponent, changedTask);

		// Update timeline heatmap cache
		if (pageComponent.props.isProjectTimeline && !data.company.isUsingSchedulingPlanMode) {
			const cacheKey = IDManager.getPersonGroupId(
				pageComponent,
				timeRegistrationToDelete.personId,
				affectedProject.projectGroupId || affectedProject.id
			);
			pageComponent.clearHeatmapCache(cacheKey, getTimeRegInterval(timeRegistrationToDelete));
		}
	} else if (response.createPhase) {
		const responsePhase = response.createPhase.phase.node;

		const phase = {
			id: responsePhase.id,
			deadlineDay: responsePhase.deadlineDay,
			deadlineMonth: responsePhase.deadlineMonth,
			deadlineYear: responsePhase.deadlineYear,
			startDay: responsePhase.startDay,
			startMonth: responsePhase.startMonth,
			startYear: responsePhase.startYear,
			name: responsePhase.name,
			projectId: response.createPhase.project.id,
		};

		data.phases.push(phase);
		DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PHASES, phase);

		const projectGroup = getProjectGroups(groups).find(projectGroup => projectGroup.id === phase.projectId);
		if (projectGroup) {
			const phasesGroup = projectGroup.groups.find(subGroup => subGroup.id.includes(PROJECT_SUB_GROUP_TYPE.PHASES));
			const phaseGroup = new PhaseGroup(pageComponent, ComposeManager.composePhaseGroup(pageComponent, phase));
			phasesGroup.addChildGroup(phaseGroup);

			if (!phasesGroup.expanded) {
				phasesGroup.toggleExpansion();
				pageComponent.props.expansionMap.set(phasesGroup.id, phasesGroup.expanded);
			}

			phaseGroup.toggleExpansion();
			pageComponent.props.expansionMap.set(phaseGroup.id, phaseGroup.expanded);

			const phaseItemData = ComposeManager.composePhaseItem(pageComponent, phase);
			if (phaseItemData) {
				items.push(new PhaseItem(pageComponent, phaseItemData));
			}
		}
	} else if (response.deleteDependency) {
		const index = data.dependencies.findIndex(
			dependency => dependency.id === response.deleteDependency.deletedDependencyId
		);
		if (index >= 0) {
			data.dependencies.splice(index, 1);
		}
		pageComponent.setState({dependencies: data.dependencies});
	} else if (response.createDependency) {
		if (response.createDependency.errors) return;
		addDependency(data, response.createDependency.dependency.node);
		pageComponent.setState({dependencies: data.dependencies});
	} else if (response.updateDependency) {
		if (response.updateDependency.errors) return;
		const dependency = data.dependencies.find(dependency => dependency.id === response.updateDependency.dependency.id);
		if (!dependency) return;
		dependency.type = response.updateDependency.dependency.type;
		dependency.thisDependsOnTaskId = response.updateDependency.dependency.thisDependsOnTask.id;
		dependency.taskIdDependsOnThis = response.updateDependency.dependency.taskDependsOnThis.id;
		pageComponent.setState({dependencies: data.dependencies});
	} else if (response.createClient) {
		const client = response.createClient.client.node;
		data.clients.push({
			id: client.id,
			name: client.name,
		});
	} else if (response.createMultipleAllocations) {
		const project = response.createMultipleAllocations.project;
		if (project?.allocations?.edges) {
			for (const allocationEdge of project.allocations.edges) {
				createOrUpdateAllocation(allocationEdge.node);
			}
		}
	} else if (response.createProject || response.duplicateProject) {
		if (pageComponent.props.isProjectTimeline) return;
		const project = response.duplicateProject
			? response.duplicateProject.project.node
			: response.createProject.project.node;

		// We get the response twice when creating project allocation project, ensure not to add things twice
		if (data.projects.find(dataProject => dataProject.id === project.id)) return;

		const formattedProject = {
			id: project.id,
			status: project.status,
			companyProjectId: project.companyProjectId,
			customProjectId: project.customProjectId,
			name: project.name,
			projectColor: project.projectColor,
			useManualAllocations: project.useManualAllocations,
			completion: project.completion,
			remaining: project.remaining,
			projectStartDay: project.projectStartDay,
			projectStartMonth: project.projectStartMonth,
			projectStartYear: project.projectStartYear,
			projectEndDay: project.projectEndDay,
			projectEndMonth: project.projectEndMonth,
			projectEndYear: project.projectEndYear,
			estimationUnit: project.estimationUnit,
			minutesPerEstimationPoint: project.minutesPerEstimationPoint,
			isInProjectGroup: project.isInProjectGroup,
			projectGroupId: project.projectGroupId,
			forecast: project.forecast,
			clientId: project.client ? project.client.id : null,
		};

		data.projects.push(formattedProject);
		DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECTS, formattedProject);

		const addedProjectStatus = {
			color: '#33cc33',
			projectId: project.id,
		};
		data.projectStatuses.push(addedProjectStatus);
		DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECT_STATUSES, addedProjectStatus);

		const projectSubGroups = [];
		const phaseGroups = [];

		if (project.projectLabels) {
			for (const projectLabelEdge of project.projectLabels.edges) {
				const projectLabel = projectLabelEdge.node;
				const addedProjectLabel = {
					id: projectLabel.id,
					labelId: projectLabel.label.id,
					projectId: project.id,
				};
				data.projectLabels.push(addedProjectLabel);
				DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECT_LABELS, addedProjectLabel);
			}
		}

		if (project.tasks) {
			addDependenciesFromTasks(project.tasks.edges, data.dependencies);

			for (const taskEdge of project.tasks.edges) {
				const task = taskEdge.node;
				const taskData = {
					projectId: project.id,
					approved: true,
					assignedPersons: task.assignedPersons.map(assignedPerson => {
						return assignedPerson.id;
					}),
					billable: task.billable,
					blocked: task.blocked,
					bug: task.bug,
					companyTaskId: task.companyTaskId,
					deadlineDay: task.deadlineDay,
					deadlineMonth: task.deadlineMonth,
					deadlineYear: task.deadlineYear,
					deadlineFrom: task.deadlineFrom,
					startFrom: task.startFrom,
					done: task.done,
					estimateForecast: task.estimateForecast,
					estimateForecastMinutes: task.estimateForecastMinutes,
					highPriority: task.highPriority,
					id: task.id,
					taskLabels: task.taskLabels?.map(t => ({labelId: t.label.id})),
					roleId: task.role ? task.role.id : undefined,
					phaseId: task.phase ? task.phase.id : undefined,
					startDay: task.startDay,
					startMonth: task.startMonth,
					startYear: task.startYear,
					timeLeft: task.timeLeft,
					name: task.name,
					fullAccessToProject: true,
					parentTaskId: task.parentTaskId,
				};

				data.tasks.push(taskData);
				DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.TASKS, taskData);

				const taskItemData = ComposeManager.composeTaskItem(pageComponent, taskData);
				if (taskItemData) {
					items.push(new TaskItem(pageComponent, taskItemData));
				}
			}
		}

		if (project.phases) {
			for (const phaseEdge of project.phases.edges) {
				const phase = phaseEdge.node;

				const updatedPhase = {
					id: phase.id,
					deadlineDay: phase.deadlineDay,
					deadlineMonth: phase.deadlineMonth,
					deadlineYear: phase.deadlineYear,
					deadlineFrom: phase.deadlineFrom,
					startDay: phase.startDay,
					startMonth: phase.startMonth,
					startYear: phase.startYear,
					startFrom: phase.startFrom,
					name: phase.name,
					projectId: project.id,
				};
				data.phases.push(updatedPhase);
				DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PHASES, updatedPhase);

				const phaseGroupData = ComposeManager.composePhaseGroup(pageComponent, updatedPhase);
				if (phaseGroupData) {
					phaseGroups.push(new PhaseGroup(pageComponent, phaseGroupData));

					const phaseItemData = ComposeManager.composePhaseItem(pageComponent, updatedPhase);
					if (phaseItemData) {
						items.push(new PhaseItem(pageComponent, phaseItemData));
					}
				}
			}
		}

		sortPhaseGroupsByDate(phaseGroups);

		if (project.phases && project.tasks?.edges.find(taskEdge => !taskEdge.node.phase)) {
			const noPhaseGroupData = ComposeManager.composePhaseGroup(pageComponent, null, project.id);
			if (noPhaseGroupData) {
				phaseGroups.push(new PhaseGroup(pageComponent, noPhaseGroupData));
			}
		}

		const phaseGroupData = ComposeManager.composeProjectSubGroup(
			pageComponent,
			project,
			null,
			PROJECT_SUB_GROUP_TYPE.PHASES,
			phaseGroups
		);

		if (phaseGroupData) {
			projectSubGroups.push(new ProjectSubGroup(pageComponent, phaseGroupData));
		}

		const isUsingProjectAllocation = getVisualizationMode(
			pageComponent.state.schedulingOptions,
			data.company,
			VISUALIZATION_MODE.ALLOCATION
		);
		if (isUsingProjectAllocation) {
			const placeholderGroups = [];

			const placeholders = project.placeholders?.edges?.map(edge => edge.node);
			if (placeholders?.length > 0) {
				placeholders.forEach(placeholder => {
					const placeholderSkillIds = placeholder.skills?.edges?.map(edge => edge.node?.id);
					const placeholderSkills = [];
					placeholderSkillIds.forEach(placeholderSkillId => {
						placeholderSkills.push({
							placeholderId: placeholder.id,
							skillId: placeholderSkillId,
						});
					});
					const formattedPlaceholder = {
						id: placeholder.id,
						projectId: placeholder.project?.id,
						projectGroupId: placeholder.projectGroupId,
						name: placeholder.name,
						roleId: placeholder.role?.id,
					};

					// update data
					data.placeholders.push(formattedPlaceholder);
					DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PLACEHOLDERS, formattedPlaceholder);
					placeholderSkills.forEach(placeholderSkill => {
						data.placeholderSkills.push(placeholderSkill);
						DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PLACEHOLDER_SKILLS, placeholderSkill);
					});

					// placeholder allocations
					const placeholderAllocations = placeholder.placeholderAllocations
						? placeholder.placeholderAllocations.edges.map(edge => edge.node)
						: [];
					for (let i = 0; i < placeholderAllocations.length; i++) {
						createOrUpdatePlaceholderAllocation(placeholderAllocations[i]);
					}

					// add group
					placeholderGroups.push(
						new CapacityPlaceholderGroup(
							pageComponent,
							ComposeManager.composeCapacityPlaceholderGroup(pageComponent, formattedPlaceholder)
						)
					);
				});
			}

			projectSubGroups.push(
				new ProjectSubGroup(
					pageComponent,
					ComposeManager.composeProjectSubGroup(
						pageComponent,
						project,
						null,
						PROJECT_SUB_GROUP_TYPE.PLACEHOLDERS,
						placeholderGroups
					)
				)
			);
		}

		const teamMemberGroups = [];
		if (project.projectPersons) {
			for (const projectPersonEdge of project.projectPersons.edges) {
				const person = data.persons.find(person => person.id === projectPersonEdge.node.person.id);
				if (person && !person.clientId) {
					const addedProjectPerson = {
						id: projectPersonEdge.node.id,
						personId: person.id,
						roleId: person.roleId,
						projectId: project.id,
					};
					data.projectPersons.push(addedProjectPerson);
					DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECT_PERSONS, addedProjectPerson);
					teamMemberGroups.push(createPersonGroup(pageComponent, person, project));
				}
			}
		}

		projectSubGroups.push(
			new ProjectSubGroup(
				pageComponent,
				ComposeManager.composeProjectSubGroup(
					pageComponent,
					project,
					null,
					PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM,
					teamMemberGroups
				)
			)
		);

		const projectGroup = project.projectGroupId
			? DataManager.getProjectGroupById(pageComponent, project.projectGroupId)
			: null;
		const createdProjectGroup = new ProjectGroup(
			pageComponent,
			ComposeManager.composeProjectGroup(
				pageComponent,
				null,
				null,
				projectGroup ? null : project,
				projectGroup,
				projectSubGroups
			)
		);

		pageComponent.state.groups.push(createdProjectGroup);

		const projectItemData = ComposeManager.composeProjectItem(pageComponent, formattedProject, null, null);
		if (projectItemData) {
			pageComponent.state.items.push(new ProjectItem(pageComponent, projectItemData));
		}
	} else if (response.deleteProject) {
		const project = data.projects.find(project => project.id === response.deleteProject.deletedProjectId);
		if (project) {
			data.projectPersons = data.projectPersons.filter(projectPerson => projectPerson.projectId !== project.id);
			delete data.projectPersonByProjectMap[project.id];
			data.allocations
				.filter(allocation => allocation.projectId === project.id)
				.forEach(allocationToDelete => {
					const allocationsForPerson = data.personAllocationMap[allocationToDelete.personId] || [];
					data.personAllocationMap[allocationToDelete.personId] = allocationsForPerson.filter(
						allocation => allocation.id !== allocationToDelete.id
					);
				});
			data.allocations = data.allocations.filter(allocation => allocation.projectId !== project.id);
			data.timeRegistrations = data.timeRegistrations.filter(
				timeRegistration =>
					timeRegistration.taskId || timeRegistration.idleTimeId || timeRegistration.projectId !== project.id
			);
			data.tasks = data.tasks.filter(task => task.projectId !== project.id);
			const index = data.projects.indexOf(project);
			if (index >= 0) {
				data.projects.splice(index, 1);
			}
			data.projectMap.delete(project.id);
			if (project.projectGroupId) {
				const projectGroupProjects = data.projects.filter(p => p.projectGroupId === project.projectGroupId);
				if (projectGroupProjects.length === 0) {
					const projectGroupIndex = data.projectGroups.findIndex(
						projectGroup => projectGroup.id === project.projectGroupId
					);
					if (projectGroupIndex >= 0) {
						data.projectGroups.splice(projectGroupIndex, 1);
					}
				}
			}
		}
	} else if (response.createPerson) {
		const person = response.createPerson.person.node;
		const addedPerson = {
			id: person.id,
			firstName: person.firstName,
			lastName: person.lastName,
			monday: person.monday,
			tuesday: person.tuesday,
			wednesday: person.wednesday,
			thursday: person.thursday,
			friday: person.friday,
			saturday: person.saturday,
			sunday: person.sunday,
			profilePictureDefaultId: person.profilePictureDefaultId,
			profilePictureId: null,
			roleId: person.role ? person.role.id : null,
			active: true,
			personLabels: [],
		};
		data.persons.push(addedPerson);
		data.personMap.set(person.id, addedPerson);
	} else if (response.createTaskProgress) {
		onCreateTaskProgress(pageComponent, response);
	} else if (response.movePhaseOnTimeline) {
		const responsePhase = response.movePhaseOnTimeline.phase;
		const phaseItem = items.find(item => item.groupId === IDManager.getPhaseGroupId(pageComponent, responsePhase.id));
		if (phaseItem) {
			phaseItem.data.phase.startFrom = responsePhase.startFrom;
			phaseItem.data.phase.deadlineFrom = responsePhase.deadlineFrom;
			const phaseStartFree = !responsePhase.startFrom;
			const phaseDeadlineFree = !responsePhase.deadlineFrom;
			if (phaseStartFree || phaseDeadlineFree) {
				const tasksToMove = getPhaseItems(pageComponent, phaseItem);
				for (const taskToMove of tasksToMove) {
					const task = taskToMove.data.task;
					const startIsDependent = DATE_FROM_PRIORITY[task.startFrom] > DATE_FROM_PRIORITY.PHASE;
					const endIsDependent = DATE_FROM_PRIORITY[task.deadlineFrom] > DATE_FROM_PRIORITY.PHASE;
					if (phaseStartFree && startIsDependent) {
						task.startFrom = DISTRIBUTION_TYPE.PHASE;
					}
					if (phaseDeadlineFree && endIsDependent) {
						task.deadlineFrom = DISTRIBUTION_TYPE.PHASE;
					}
				}
			}
		}
	}

	pageComponent.redrawCanvasTimeline({preventFiltering: false});
	requestAnimationFrame(() => {
		pageComponent.redrawCanvasTimeline({preventFiltering: false});
	});
};
