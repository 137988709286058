import React, { useMemo, useState } from 'react';
import { Alert, FlexColumn, FlexRow, Modal, RadioGroupHandler, SubHeading, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExpenseBoolean } from '../ExpenseTypes';
import BulkUpdateExpenseItemsMutation from '../../../mutations/bulk_update_expense_items_mutation';
import Util from '../../../forecast-app/shared/util/util';
const ChangeExpenseBillableStatusModal = ({ expenseItems, closeModal, }) => {
    const { formatMessage } = useIntl();
    const updateableItems = useMemo(() => expenseItems.filter(expenseItem => !expenseItem.locked), [expenseItems]);
    const [newBillableStatus, setNewBillableStatus] = useState('');
    const count = expenseItems.length;
    const lockedCount = expenseItems.length - updateableItems.length;
    const showAlert = lockedCount > 0;
    const allowSave = newBillableStatus && count > lockedCount;
    const saveChanges = () => {
        const onSuccess = () => {
            closeModal();
        };
        Util.CommitMutation(BulkUpdateExpenseItemsMutation, {
            ids: updateableItems.map(expenseItem => expenseItem.id),
            billable: newBillableStatus === ExpenseBoolean.TRUE,
        }, onSuccess);
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "expense_management.mark_as_billable" })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 's' },
                React.createElement(FlexRow, null,
                    React.createElement(SubHeading, null,
                        React.createElement(FormattedMessage, { id: 'expense_management.billing_details' }))),
                React.createElement(FlexRow, null,
                    React.createElement(Text, { color: 'medium' },
                        React.createElement(FormattedMessage, { id: 'expense_management.set_billing_option' })))),
            React.createElement(FlexColumn, { gap: 'xl' },
                React.createElement(RadioGroupHandler, { onValueChange: setNewBillableStatus, value: newBillableStatus },
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(RadioGroupHandler.Item, { value: ExpenseBoolean.TRUE }),
                        React.createElement(FlexColumn, null,
                            React.createElement(Text, null, formatMessage({ id: 'expense_management.change_billable_status.option_1' })),
                            React.createElement(Text, { color: 'medium' }, formatMessage({ id: 'expense_management.change_billable_status.option_1_subheading' })))),
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(RadioGroupHandler.Item, { value: ExpenseBoolean.FALSE }),
                        React.createElement(Text, null, formatMessage({ id: 'expense_management.change_billable_status.option_2' })))),
                showAlert && (React.createElement(Alert, { text: formatMessage({ id: 'expense_management.change_billable_status.locked_alert' }, { count, lockedCount }), type: Alert.TYPE.INFORMATION })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: saveChanges, disabled: !allowSave, dataCy: 'confirm' },
                React.createElement(FormattedMessage, { id: 'expense_management.actions.confirm_changes' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal, dataCy: 'cancel' },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default ChangeExpenseBillableStatusModal;
