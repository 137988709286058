import React from 'react';

export default ({exitStaffingMode}) => {
	return (
		<>
			<div className="staffing-mode-bar left" />
			<div className="staffing-mode-bar right" />
			<div className="staffing-mode-bar bottom" />
			<div className="staffing-mode-header" data-cy="staffing-mode-header">
				<div className="title">{'Resourcing'}</div>
				<div className="exit-staffing-mode close-button" onClick={exitStaffingMode} />
			</div>
		</>
	);
};
