import {BUDGET_MONEY_VIEWS, BUDGET_TYPE, BUDGET_VIEWS} from '../../../../../constants';

const isTask = setting => setting.toLowerCase() === 'task';

const ActualTooltips = (formatMessage, budgetView, budgetMoneyView, settings, hasFinancialCategoriesUpdate) => {
	if (budgetView === BUDGET_VIEWS.MONEY) {
		switch (budgetMoneyView) {
			case BUDGET_MONEY_VIEWS.EXPENSES:
				return {
					billableActualTimeAndExpenses: [
						{
							title: formatMessage({id: 'project_budget.revenue'}),
							description: [formatMessage({id: 'project_budget.price_past_planned_expenses'})],
						},
					],
					actualCost: [
						{
							title: formatMessage({id: 'common.cost'}),
							description: [formatMessage({id: 'project_budget.cost_past_expenses'})],
						},
					],
					actualProfit: [
						{
							title: formatMessage({id: 'common.profit'}),
							details: [
								formatMessage({id: 'project_budget.actual_revenue'}),
								'-',
								formatMessage({id: 'insights.component.list.column.actualCost'}),
							],
						},
					],
				};
			case BUDGET_MONEY_VIEWS.WORK_AND_EXPENSES:
				return {
					totalActualRevenueRecognition: [
						{
							title: formatMessage({id: 'project_budget.actual_revenue'}),
							description: [
								formatMessage({
									id: isTask(settings.actualRevenue)
										? 'project_budget.actual_revenue_tooltip_task'
										: 'project_budget.actual_revenue_tooltip_allocation',
								}),
							],
							details: [
								formatMessage({
									id: isTask(settings.actualRevenue)
										? 'common.actual_billable_hours'
										: 'finance_tooltip_components.actual_allocation_hours',
								}),
								'x',
								'(',
								formatMessage({id: 'project_budget.total_revenue_time'}),
								'/',
								formatMessage({id: 'common.projected_total_billable_value_of_service_time'}),
								')',
								'+',
								formatMessage({id: 'finance_tooltip_components.price_of_actual_expenses'}),
							],
						},
					],
					billableActualTimeAndExpenses: [
						{
							title: formatMessage({
								id: hasFinancialCategoriesUpdate
									? 'common.actual_billable_value_of_service'
									: 'project_budget.actual_billable_time_and_expenses_short',
							}),
							description: hasFinancialCategoriesUpdate
								? formatMessage({
										id: isTask(settings.actualHours)
											? 'project_budget.actual_billable_value_of_service_tooltip_task'
											: 'project_budget.actual_billable_value_of_service_tooltip_allocation',
								  })
								: undefined,
							details: [
								formatMessage({
									id: isTask(settings.actualHours)
										? hasFinancialCategoriesUpdate
											? 'project_budget.billable_hours_registered'
											: 'finance_tooltip_components.actual_task_hours'
										: 'finance_tooltip_components.actual_allocation_hours',
								}),
								'x',
								formatMessage({id: 'project_budget.rate'}),
								'+',
								formatMessage({id: 'finance_tooltip_components.price_of_actual_expenses'}),
							],
						},
					],
					actualCost: [
						{
							title: formatMessage({id: 'insights.component.list.column.actualCost'}),
							description: hasFinancialCategoriesUpdate
								? formatMessage({
										id: isTask(settings.actualCost)
											? 'project_budget.actual_cost_task'
											: 'project_budget.actual_cost_allocation',
								  })
								: undefined,
							details: [
								formatMessage({
									id: isTask(settings.actualCost)
										? 'finance_tooltip_components.actual_task_hours'
										: 'finance_tooltip_components.actual_allocation_hours',
								}),
								'x',
								formatMessage({id: 'project_budget.internal_hourly_cost'}),
								'+',
								formatMessage({id: 'finance_tooltip_components.cost_of_actual_expenses'}),
							],
						},
					],
					actualProfit: [
						{
							title: formatMessage({id: 'common.profit'}),
							details: [
								formatMessage({
									id: 'project_budget.actual_billable_time_and_expenses',
								}),
								'-',
								formatMessage({id: 'insights.component.list.column.actualCost'}),
							],
						},
					],
					actualRevenueProfit: [
						{
							title: formatMessage({id: 'project_budget.actual_profit'}),
							description: formatMessage({id: 'project_budget.actual_profit_tooltip'}),
							details: [
								formatMessage({
									id: 'project_budget.actual_revenue',
								}),
								'-',
								formatMessage({id: 'insights.component.list.column.actualCost'}),
							],
						},
					],
					actualMargin: [
						{
							title: formatMessage({id: 'common.margin'}),
							details: [
								formatMessage({id: 'project_budget.actual_profit'}),
								'/',
								formatMessage({id: 'project_budget.actual_revenue'}),
								'x',
								'100',
							],
						},
					],
					actualRevenueMargin: [
						{
							title: formatMessage({id: 'project_budget.actual_margin'}),
							description: formatMessage({id: 'project_budget.actual_margin_tooltip'}),
							details: [
								formatMessage({id: 'project_budget.actual_profit'}),
								'/',
								formatMessage({id: 'project_budget.actual_revenue'}),
								'x',
								'100',
							],
						},
					],
				};
		}
	} else {
		return {
			registeredMinutes: [
				{
					title: [formatMessage({id: 'project_settings.financials.value_calculations.actual_hours'})],
					description: [
						formatMessage({
							id: isTask(settings.actualHours)
								? 'project_settings.financials.value_calculations.actual_task_hours_tooltip'
								: 'project_settings.financials.value_calculations.actual_allocation_hours_tooltip',
						}),
					],
				},
			],
			billableActualMinutes: [
				{
					title: [formatMessage({id: 'project_settings.financials.value_calculations.actual_hours'})],
					description: [
						formatMessage({
							id: 'project_settings.financials.value_calculations.actual_task_billable_hours_tooltip',
						}),
					],
				},
			],
			nonBillableActualMinutes: [
				{
					title: [formatMessage({id: 'project_settings.financials.value_calculations.actual_hours'})],
					description: [
						formatMessage({
							id: 'project_settings.financials.value_calculations.actual_task_non_billable_hours_tooltip',
						}),
					],
				},
			],
		};
	}
};

const PlanTooltips = (formatMessage, budgetView, budgetMoneyView, settings, hasFinancialCategoriesUpdate) => {
	if (budgetView === BUDGET_VIEWS.MONEY) {
		switch (budgetMoneyView) {
			case BUDGET_MONEY_VIEWS.EXPENSES:
				return {
					billablePlannedTimeAndExpenses: [
						{
							title: formatMessage({id: 'project_budget.planned_billable_time_and_expenses'}),
							description: [formatMessage({id: 'project_budget.price_all_planned_expenses'})],
						},
					],
					plannedCost: [
						{
							title: formatMessage({id: 'common.cost'}),
							description: [formatMessage({id: 'project_budget.cost_all_planned_expenses'})],
						},
					],
					plannedProfit: [
						{
							title: formatMessage({id: 'common.profit'}),
							details: [
								formatMessage({id: 'project_budget.plan_revenue'}),
								'-',
								formatMessage({id: 'project_budget.plan_cost'}),
							],
						},
					],
				};
			case BUDGET_MONEY_VIEWS.WORK_AND_EXPENSES:
				return {
					plannedRevenue: [
						{
							title: formatMessage({id: 'common.estimated_revenue'}),
							details: [
								formatMessage({id: 'common.fixed_price'}),
								'+',
								formatMessage({id: 'project_budget.planned_expenses_billable_on_top_of_fixed_price'}),
							],
						},
					],
					billablePlannedTimeAndExpenses: [
						// {hide: true},
						{
							title: formatMessage({id: 'project_budget.planned_billable_time_and_expenses'}),
							description: hasFinancialCategoriesUpdate
								? formatMessage({id: 'project_budget.estimated_billable_value_of_service_tooltip'})
								: undefined,
							details: [
								formatMessage({
									id: isTask(settings.plannedHours)
										? 'project_budget.planned_billable_time_and_expenses_task'
										: 'project_budget.allocated_hours',
								}),
								'x',
								formatMessage({id: 'project_budget.rate'}),
								'+',
								formatMessage({
									id: hasFinancialCategoriesUpdate
										? 'project_budget.price_of_billable_expenses'
										: 'project_budget.price_planned_expenses',
								}),
							],
						},
					],
					plannedCost: [
						{
							title: formatMessage({
								id: hasFinancialCategoriesUpdate ? 'common.estimated_cost' : 'project_budget.planned_cost',
							}),
							description: hasFinancialCategoriesUpdate
								? formatMessage({
										id: isTask(settings.plannedCost)
											? 'project_budget.estimated_cost_tooltip_task'
											: 'project_budget.estimated_cost_tooltip_allocation',
								  })
								: undefined,
							details: [
								formatMessage({
									id: isTask(settings.plannedCost)
										? hasFinancialCategoriesUpdate
											? 'project_budget.task_estimates'
											: 'project_budget.planned_billable_time_and_expenses_task'
										: 'project_budget.allocated_hours',
								}),
								'x',
								formatMessage({
									id: hasFinancialCategoriesUpdate
										? 'project_budget.internal_hourly_cost'
										: 'project_budget.internal_cost',
								}),
								'+',
								formatMessage({
									id: hasFinancialCategoriesUpdate
										? 'project_budget.cost_of_planed_expenses_tooltip'
										: 'finance_tooltip_components.cost_of_planned_expenses',
								}),
							],
						},
					],
					plannedProfit: [
						{
							title: formatMessage({id: 'common.profit'}),
							details: [
								formatMessage({id: 'project_budget.plan_revenue'}),
								'-',
								formatMessage({id: 'project_budget.plan_cost'}),
							],
						},
					],
					plannedRevenueProfit: [
						{
							title: formatMessage({id: 'common.estimated_profit'}),
							description: formatMessage({id: 'project_budget.estimated_profit_tooltip'}),
							details: [
								formatMessage({id: 'common.estimated_revenue'}),
								'-',
								formatMessage({id: 'common.estimated_cost'}),
							],
						},
					],
					plannedMargin: [
						{
							title: formatMessage({id: 'common.margin'}),
							details: [
								formatMessage({id: 'project_budget.planned_profit'}),
								'/',
								formatMessage({id: 'project_budget.planned_revenue'}),
								'x',
								'100',
							],
						},
					],
					plannedRevenueMargin: [
						{
							title: formatMessage({id: 'common.estimated_margin'}),
							description: formatMessage({id: 'project_budget.estimated_margin_tooltip'}),
							details: [
								formatMessage({id: 'common.estimated_profit'}),
								'/',
								formatMessage({id: 'common.estimated_revenue'}),
								'x',
								'100',
							],
						},
					],
				};
		}
	} else {
		return {
			scopeApprovedMinutes: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.estimated_hours'
							: 'project_settings.financials.value_calculations.planned_hours',
					}),
					description: [
						formatMessage({
							id: isTask(settings.plannedHours)
								? 'project_settings.financials.value_calculations.planned_task_hours_tooltip'
								: 'project_settings.financials.value_calculations.planned_allocation_hours_tooltip',
						}),
					],
				},
			],
		};
	}
};

const RemainingTooltips = (formatMessage, budgetView, budgetMoneyView, settings, hasFinancialCategoriesUpdate) => {
	if (budgetView === BUDGET_VIEWS.MONEY) {
		switch (budgetMoneyView) {
			case BUDGET_MONEY_VIEWS.EXPENSES:
				return {
					billableForecastTimeAndExpensesToComplete: [
						{
							title: formatMessage({id: 'project_budget.revenue'}),
							description: [formatMessage({id: 'project_budget.price_future_planned_expenses'})],
						},
					],
					forecastCostToComplete: [
						{
							title: formatMessage({id: 'common.cost'}),
							description: [formatMessage({id: 'project_budget.cost_future_planned_expenses'})],
						},
					],
					forecastProfitToComplete: [
						{
							title: formatMessage({id: 'common.profit'}),
							details: [
								formatMessage({id: 'project_budget.remaining_revenue'}),
								'-',
								formatMessage({id: 'project_budget.remaining_cost'}),
							],
						},
					],
				};
			case BUDGET_MONEY_VIEWS.WORK_AND_EXPENSES:
				return {
					totalForecastRevenueToComplete: [
						{
							title: formatMessage({id: 'project_budget.remaining_work_revenue'}),
							description: formatMessage({
								id: isTask(settings.forecastHours)
									? 'project_budget.remaining_work_revenue_tooltip_task'
									: 'project_budget.remaining_work_revenue_tooltip_allocation',
							}),
							details: [
								formatMessage({
									id: isTask(settings.forecastHours)
										? 'finance_tooltip_components.forecast_billable_task_hours'
										: 'finance_tooltip_components.forecast_allocation_hours',
								}),
								'x',
								'(',
								formatMessage({id: 'project_budget.total_revenue_time'}),
								'/',
								formatMessage({id: 'common.projected_total_billable_value_of_service_time'}),
								')',
								'+',
								formatMessage({id: 'finance_tooltip_components.price_of_future_expenses'}),
							],
						},
					],
					billableForecastTimeAndExpensesToComplete: [
						{
							title: formatMessage({
								id: hasFinancialCategoriesUpdate
									? 'common.remaining_work_billable_value_of_service'
									: 'project_budget.forecast_billable_time_and_expenses',
							}),
							description: hasFinancialCategoriesUpdate
								? formatMessage({
										id: isTask(settings.forecastHours)
											? 'project_budget.remaining_work_billable_value_of_service_task'
											: 'project_budget.remaining_work_billable_value_of_service_allocation',
								  })
								: undefined,
							details: [
								formatMessage({
									id: isTask(settings.forecastHours)
										? 'finance_tooltip_components.forecast_billable_task_hours'
										: 'finance_tooltip_components.forecast_allocation_hours',
								}),
								'x',
								formatMessage({id: 'project_budget.rate'}),
								'+',
								formatMessage({id: 'project_budget.price_future_expenses'}),
							],
						},
					],
					forecastCostToComplete: [
						{
							title: formatMessage({
								id: hasFinancialCategoriesUpdate
									? 'project_budget.remaining_work_cost'
									: 'project_budget.forecast_cost_to_complete',
							}),
							details: [
								formatMessage({
									id: isTask(settings.forecastCost)
										? 'finance_tooltip_components.forecast_task_hours'
										: 'finance_tooltip_components.forecast_allocation_hours',
								}),
								'x',
								formatMessage({id: 'project_budget.internal_hourly_cost'}),
								'+',
								formatMessage({id: 'finance_tooltip_components.cost_of_future_expenses'}),
							],
						},
					],
					forecastProfitToComplete: [
						{
							title: formatMessage({id: 'common.profit'}),
							details: [
								formatMessage({id: 'project_budget.remaining_revenue'}),
								'-',
								formatMessage({id: 'project_budget.remaining_cost'}),
							],
						},
					],
					forecastRevenueProfitToComplete: [
						{
							title: formatMessage({id: 'project_budget.remaining_work_profit'}),
							description: formatMessage({id: 'project_budget.remaining_work_profit_tooltip'}),
							details: [
								formatMessage({id: 'project_budget.remaining_work_revenue'}),
								'-',
								formatMessage({id: 'project_budget.remaining_work_cost'}),
							],
						},
					],
					forecastMarginToComplete: [
						{
							title: formatMessage({id: 'common.margin'}),
							details: [
								formatMessage({id: 'project_budget.remaining_profit'}),
								'/',
								formatMessage({id: 'project_budget.remaining_revenue'}),
								'x',
								'100',
							],
						},
					],
					forecastRevenueMarginToComplete: [
						{
							title: formatMessage({id: 'project_budget.remaining_work_margin'}),
							description: formatMessage({id: 'project_budget.remaining_work_margin_tooltip'}),
							details: [
								formatMessage({id: 'project_budget.remaining_work_profit'}),
								'/',
								formatMessage({id: 'project_budget.remaining_work_revenue'}),
								'x',
								'100',
							],
						},
					],
				};
		}
	} else {
		return {
			forecastTimeToComplete: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.remaining_work'
							: 'project_settings.financials.value_calculations.forecast_hours_to_complete',
					}),
					description: [
						formatMessage({
							id: isTask(settings.forecastHours)
								? 'project_settings.financials.value_calculations.forecast_task_hours_tooltip'
								: 'project_settings.financials.value_calculations.forecast_allocation_hours_tooltip',
						}),
					],
				},
			],
		};
	}
};

const ForecastTooltips = (formatMessage, budgetView, settings, hasFinancialCategoriesUpdate) => {
	if (budgetView === BUDGET_VIEWS.MONEY) {
		return {
			totalRevenueRecognition: [
				{
					title: formatMessage({id: 'common.projected_total_revenue'}),
					details: [
						formatMessage({id: 'common.fixed_price'}),
						'+',
						formatMessage({id: 'project_budget.planned_expenses_billable_on_top_of_fixed_price'}),
					],
				},
			],
			billableTotalTimeAndExpensesAtCompletion: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.projected_total_billable_value_of_service'
							: 'project_budget.total_billable_time_and_expenses',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.projected_total_billable_value_of_service_tooltip'})
						: undefined,
					details: [
						formatMessage({
							id: hasFinancialCategoriesUpdate
								? 'common.actual_billable_value_of_service'
								: 'project_budget.actual_billable_time_and_expenses_short',
						}),
						'+',
						formatMessage({
							id: hasFinancialCategoriesUpdate
								? 'common.remaining_work_billable_value_of_service'
								: 'project_budget.forecast_billable_time_and_expenses',
						}),
					],
				},
			],
			totalCostAtCompletion: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.projected_total_cost'
							: 'project_budget.total_cost_at_completion',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.projected_total_cost_tooltip'})
						: undefined,
					details: [
						formatMessage({id: 'insights.component.list.column.actualCost'}),
						'+',
						formatMessage({
							id: hasFinancialCategoriesUpdate
								? 'project_budget.remaining_work_cost'
								: 'project_budget.forecast_cost_to_complete',
						}),
					],
				},
			],
			totalProfitAtCompletion: [
				{
					title: formatMessage({id: 'common.profit'}),
					details: [
						formatMessage({id: 'project_budget.forecast_revenue'}),
						'-',
						formatMessage({id: 'project_budget.forecast_cost'}),
					],
				},
			],
			totalRevenueProfitAtCompletion: [
				{
					title: formatMessage({id: 'common.projected_total_profit'}),
					description: formatMessage({id: 'project_budget.projected_total_profit_tooltip'}),
					details: [
						formatMessage({id: 'common.projected_total_revenue'}),
						'-',
						formatMessage({id: 'common.projected_total_cost'}),
					],
				},
			],
			totalMarginAtCompletion: [
				{
					title: formatMessage({id: 'common.margin'}),
					details: [
						formatMessage({id: 'project_budget.forecast_profit'}),
						'/',
						formatMessage({id: 'project_budget.forecast_revenue'}),
						'x',
						'100',
					],
				},
			],
			totalRevenueMarginAtCompletion: [
				{
					title: formatMessage({id: 'common.projected_total_margin'}),
					description: formatMessage({id: 'project_budget.projected_total_margin_tooltip'}),
					details: [
						formatMessage({id: 'common.projected_total_profit'}),
						'/',
						formatMessage({id: 'common.projected_total_revenue'}),
						'x',
						'100',
					],
				},
			],
		};
	} else {
		return {
			totalTimeAtCompletion: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.projected_total_hours'
							: 'project_budget.total_hours_at_completion',
					}),
					description: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'project_budget.projected_total_hours_tooltip'
							: 'project_budget.total_hours_at_completion_description',
					}),
					details: [
						formatMessage({id: 'project_settings.financials.value_calculations.actual_hours'}),
						'+',
						formatMessage({
							id: hasFinancialCategoriesUpdate
								? 'common.remaining_work_hours'
								: 'project_settings.financials.value_calculations.forecast_hours_to_complete',
						}),
					],
				},
			],
		};
	}
};

const PlanVsActualTooltips = (formatMessage, budgetView, hasExpenses, hasFinancialCategoriesUpdate) => {
	if (budgetView === BUDGET_VIEWS.MONEY) {
		return {
			planVsActualRevenueRecognition: [
				{
					title: formatMessage({id: 'project_budget.estimated_vs_actual_revenue'}),
					description: formatMessage({id: 'project_budget.estimated_vs_actual_revenue_tooltip'}),
					details: [
						formatMessage({id: 'common.estimated_revenue'}),
						'-',
						formatMessage({id: 'project_budget.actual_revenue'}),
					],
				},
			],
			planVsBillableActualTimeAndExpenses: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'project_budget.estimated_vs_actual_billable_value_of_service'
							: 'project_budget.revenue',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.estimated_vs_actual_billable_value_of_service_tooltip'})
						: undefined,
					details: hasFinancialCategoriesUpdate
						? [
								formatMessage({id: 'common.estimated_billable_value_of_service'}),
								'-',
								formatMessage({id: 'common.actual_billable_value_of_service'}),
						  ]
						: !hasExpenses
						? [
								formatMessage({id: 'project_budget.planned_revenue'}),
								'-',
								formatMessage({id: 'project_budget.actual_billable_time'}),
						  ]
						: [
								formatMessage({id: 'project_budget.planned_revenue'}),
								'-',
								formatMessage({id: 'project_budget.actual_revenue'}),
						  ],
				},
			],
			planVsActualCost: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate ? 'project_budget.estimated_vs_actual_cost' : 'common.cost',
					}),

					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.estimated_vs_actual_cost_tooltip'})
						: undefined,
					details: [
						formatMessage({id: 'project_budget.planned_cost'}),
						'-',
						formatMessage({id: 'insights.component.list.column.actualCost'}),
					],
				},
			],
			planVsActualProfit: [
				{
					title: formatMessage({id: 'common.profit'}),
					details: [
						formatMessage({id: 'project_budget.variance_cost'}),
						'-',
						formatMessage({id: 'project_budget.variance_revenue'}),
					],
				},
			],
			planVsActualRevenueProfit: [
				{
					title: formatMessage({id: 'project_budget.estimated_vs_actual_profit'}),
					description: formatMessage({id: 'project_budget.estimated_vs_actual_profit_tooltip'}),
					details: [
						formatMessage({id: 'common.estimated_profit'}),
						'-',
						formatMessage({id: 'project_budget.actual_profit'}),
					],
				},
			],
			planVsActualRevenueMargin: [
				{
					title: formatMessage({id: 'project_budget.estimated_vs_actual_margin'}),
					description: formatMessage({id: 'project_budget.estimated_vs_actual_margin_tooltip'}),
					details: [
						formatMessage({id: 'common.estimated_margin'}),
						'-',
						formatMessage({id: 'project_budget.actual_margin'}),
					],
				},
			],
		};
	} else {
		return {
			scopeApprovedVsRegisteredMinutes: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'project_budget.estimated_vs_actual_hours'
							: 'project_budget.plan_vs_actual_hours',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.estimated_vs_actual_hours_tooltip'})
						: undefined,
					details: [
						formatMessage({id: hasFinancialCategoriesUpdate ? 'common.estimated_hours' : 'common.planned'}),
						'-',
						formatMessage({id: hasFinancialCategoriesUpdate ? 'common.actual_hours' : 'project_budget.actuals'}),
					],
				},
			],
		};
	}
};

const PlanVsForecastTooltips = (formatMessage, budgetView, hasFinancialCategoriesUpdate) => {
	if (budgetView === BUDGET_VIEWS.MONEY) {
		return {
			planVsTotalRevenueAtCompletion: [
				{
					title: formatMessage({id: 'project_budget.estimated_vs_projected_total_revenue'}),
					description: formatMessage({id: 'project_budget.estimated_vs_projected_total_revenue_tooltip'}),
					details: [
						formatMessage({id: 'common.estimated_revenue'}),
						'-',
						formatMessage({id: 'common.projected_total_revenue'}),
					],
				},
			],
			planVsTotalBillableTimeAndExpensesAtCompletion: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'project_budget.estimated_vs_projected_total_billable_value_of_service'
							: 'project_budget.revenue',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.estimated_vs_projected_total_billable_value_of_service_tooltip'})
						: undefined,
					details: [
						formatMessage({
							id: hasFinancialCategoriesUpdate
								? 'common.estimated_billable_value_of_service'
								: 'project_budget.planned_revenue',
						}),
						'-',
						formatMessage({
							id: hasFinancialCategoriesUpdate
								? 'common.projected_total_billable_value_of_service'
								: 'project_budget.forecast_revenue',
						}),
					],
				},
			],
			planVsTotalCostAtCompletion: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate ? 'project_budget.estimated_vs_projected_total_cost' : 'common.cost',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.estimated_vs_projected_total_cost_tooltip'})
						: undefined,
					details: [
						formatMessage({
							id: hasFinancialCategoriesUpdate ? 'common.estimated_cost' : 'project_budget.planned_cost',
						}),
						'-',
						formatMessage({
							id: hasFinancialCategoriesUpdate ? 'common.projected_total_cost' : 'project_budget.forecast_cost',
						}),
					],
				},
			],
			planVsTotalProfitAtCompletion: [
				{
					title: formatMessage({id: 'common.profit'}),
					details: [
						formatMessage({id: 'project_budget.variance_revenue'}),
						'-',
						formatMessage({id: 'project_budget.variance_cost'}),
					],
				},
			],
			planVsTotalRevenueProfitAtCompletion: [
				{
					title: formatMessage({id: 'project_budget.estimated_vs_projected_total_profit'}),
					description: formatMessage({id: 'project_budget.estimated_vs_projected_total_profit_tooltip'}),
					details: [
						formatMessage({id: 'common.estimated_profit'}),
						'-',
						formatMessage({id: 'common.projected_total_profit'}),
					],
				},
			],
			planVsTotalRevenueMarginAtCompletion: [
				{
					title: formatMessage({id: 'project_budget.estimated_vs_projected_total_margin'}),
					description: formatMessage({id: 'project_budget.estimated_vs_projected_total_margin_tooltip'}),
					details: [
						formatMessage({id: 'common.estimated_margin'}),
						'-',
						formatMessage({id: 'common.projected_total_margin'}),
					],
				},
			],
		};
	} else {
		return {
			scopeApprovedVsTotalTimeAtCompletion: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'project_budget.estimated_vs_projected_total_hours'
							: 'project_budget.planned_vs_total_hours_at_completion',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.estimated_vs_projected_total_hours_tooltip'})
						: undefined,
					details: hasFinancialCategoriesUpdate
						? [
								formatMessage({id: 'common.estimated_hours'}),
								'-',
								formatMessage({id: 'common.projected_total_hours'}),
						  ]
						: [
								formatMessage({id: 'common.planned'}),
								'-',
								formatMessage({id: 'project_budget.actuals'}),
								'+',
								formatMessage({id: 'common.remaining'}),
						  ],
				},
			],
		};
	}
};

const BaselineTooltips = (
	formatMessage,
	budgetView,
	budgetMoneyView,
	isFixedPrice,
	useFixedPriceCalculation,
	hasFinancialCategoriesUpdate
) => {
	if (budgetView === BUDGET_VIEWS.MONEY) {
		switch (budgetMoneyView) {
			case BUDGET_MONEY_VIEWS.EXPENSES:
				return {
					baselineRevenue: [
						{
							description: formatMessage({id: 'baseline.total_baseline_revenue_tooltip'}),
						},
					],
					baselineCost: [
						{
							details: [
								formatMessage({id: 'baseline.sum_of_hours'}),
								'x',
								formatMessage({id: 'common.cost'}),
								'+',
								formatMessage({id: 'baseline.cost_of_expenses'}),
							],
						},
					],
					baselineProfit: [
						{
							title: formatMessage({id: 'common.profit'}),
							details: [
								formatMessage({id: 'baseline.baseline_revenue'}),
								'-',
								formatMessage({id: 'baseline.baseline_cost'}),
							],
						},
					],
				};
			case BUDGET_MONEY_VIEWS.WORK_AND_EXPENSES:
				return {
					baselineRevenue: [
						{
							title: formatMessage({
								id:
									isFixedPrice && !useFixedPriceCalculation && !hasFinancialCategoriesUpdate
										? 'baseline.baseline_billable_time_and_expenses'
										: 'baseline.baseline_revenue',
							}),
							description: formatMessage({
								id: isFixedPrice
									? useFixedPriceCalculation
										? 'baseline.baseline_revenue_tooltip_fixed_price_enabled'
										: hasFinancialCategoriesUpdate
										? 'project_budget.baseline_revenue_time_and_expenses'
										: 'baseline.baseline_revenue_tooltip_fixed_price_disabled'
									: 'baseline.revenue_description',
							}),
							details: !isFixedPrice
								? [
										formatMessage({id: 'baseline.hours'}),
										'x',
										formatMessage({id: 'baseline.rate_per_hour'}),
										'+',
										formatMessage({id: 'project_budget.revenue_from_expenses'}),
								  ]
								: isFixedPrice && !useFixedPriceCalculation && hasFinancialCategoriesUpdate
								? [
										formatMessage({id: 'baseline.hours'}),
										'x',
										formatMessage({id: 'baseline.rate_per_hour'}),
										'+',
										formatMessage({id: 'project_budget.revenue_from_baseline_expenses'}),
								  ]
								: null,
						},
					],
					baselineCost: [
						{
							title: formatMessage({id: 'baseline.baseline_cost'}),
							description: formatMessage({id: 'baseline.cost_tooltip_description'}),
							details: [
								formatMessage({id: 'baseline.hours'}),
								'x',
								formatMessage({id: 'common.cost_per_hour'}),
								'+',
								formatMessage({id: 'baseline.expenses_cost'}),
							],
						},
					],
					baselineProfit: [
						{
							title: formatMessage({id: 'baseline.baseline_profit'}),
							description: hasFinancialCategoriesUpdate
								? formatMessage({id: 'project_budget.baseline_profit_tooltip'})
								: undefined,
							details: [
								formatMessage({
									id:
										isFixedPrice && !useFixedPriceCalculation && !hasFinancialCategoriesUpdate
											? 'baseline.baseline_billable_time_and_expenses'
											: 'baseline.baseline_revenue',
								}),
								'-',
								formatMessage({id: 'baseline.baseline_cost'}),
							],
						},
					],
					baselineMargin: [
						{
							title: formatMessage({id: 'baseline.baseline_margin'}),
							description: hasFinancialCategoriesUpdate
								? formatMessage({id: 'project_budget.baseline_margin_tooltip'})
								: undefined,
							details: [
								formatMessage({id: 'project_budget.baseline_profit'}),
								'/',
								formatMessage({
									id:
										isFixedPrice && !useFixedPriceCalculation && !hasFinancialCategoriesUpdate
											? 'baseline.baseline_billable_time_and_expenses'
											: 'baseline.baseline_revenue',
								}),
								'x',
								'100',
							],
						},
					],
					baselineTimeAndExpenses: [
						{
							title: formatMessage({id: 'project_budget.baseline_billable_value_of_service'}),
							description: formatMessage({id: 'project_budget.baseline_billable_value_of_service_tooltip'}),
							details: [
								formatMessage({id: 'baseline.hours'}),
								'x',
								formatMessage({id: 'baseline.rate_per_hour'}),
								'+',
								formatMessage({id: 'project_budget.revenue_from_baseline_expenses'}),
							],
						},
					],
				};
			default:
				break;
		}
	} else {
		return {
			baselineMinutes: [
				{
					title: [formatMessage({id: 'baseline.hours'})],
					description: [formatMessage({id: 'baseline.total_work_estimate_tooltip'})],
				},
			],
		};
	}
};

const BaselineVsForecastTooltips = (formatMessage, budgetView, hasFinancialCategoriesUpdate) => {
	if (budgetView === BUDGET_VIEWS.MONEY) {
		return {
			baselineVsTotalRevenueAtCompletion: [
				{
					title: formatMessage({
						id: 'project_budget.baseline_vs_projected_total_revenue',
					}),
					description: formatMessage({id: 'project_budget.baseline_vs_projected_total_revenue_tooltip'}),
					details: [
						formatMessage({id: 'project_budget.baseline_revenue'}),
						'-',
						formatMessage({id: 'common.projected_total_revenue'}),
					],
				},
			],
			baselineVsBillableTotalTimeAndExpensesAtCompletion: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'project_budget.baseline_vs_projected_total_billable_value_of_service'
							: 'project_budget.revenue',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.baseline_vs_projected_total_billable_value_of_service_tooltip'})
						: undefined,
					details: [
						formatMessage({
							id: hasFinancialCategoriesUpdate
								? 'project_budget.baseline_billable_value_of_service'
								: 'project_budget.baseline_revenue',
						}),
						'-',
						formatMessage({
							id: hasFinancialCategoriesUpdate
								? 'common.projected_total_billable_value_of_service'
								: 'project_budget.forecast_revenue',
						}),
					],
				},
			],
			baselineVsTotalCostAtCompletion: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate ? 'project_budget.baseline_vs_projected_total_cost' : 'common.cost',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.baseline_vs_projected_total_cost_tooltip'})
						: undefined,
					details: [
						formatMessage({id: 'project_budget.baseline_cost'}),
						'-',
						formatMessage({
							id: hasFinancialCategoriesUpdate ? 'common.projected_total_cost' : 'project_budget.forecast_cost',
						}),
					],
				},
			],
			baselineVsTotalProfitAtCompletion: [
				{
					title: formatMessage({id: 'common.profit'}),
					details: [
						formatMessage({id: 'project_budget.variance_revenue'}),
						'-',
						formatMessage({id: 'project_budget.variance_cost'}),
					],
				},
			],
			baselineVsTotalRevenueProfitAtCompletion: [
				{
					title: formatMessage({id: 'project_budget.baseline_vs_projected_total_profit'}),
					description: formatMessage({id: 'project_budget.baseline_vs_projected_total_profit_tooltip'}),
					details: [
						formatMessage({id: 'project_budget.baseline_profit'}),
						'-',
						formatMessage({id: 'common.projected_total_profit'}),
					],
				},
			],
			baselineVsTotalRevenueMarginAtCompletion: [
				{
					title: formatMessage({id: 'project_budget.baseline_vs_projected_total_margin'}),
					description: formatMessage({id: 'project_budget.baseline_vs_projected_total_margin_tooltip'}),
					details: [
						formatMessage({id: 'baseline.baseline_margin'}),
						'-',
						formatMessage({id: 'common.projected_total_margin'}),
					],
				},
			],
		};
	} else {
		return {
			baselineVsTotalTimeAtCompletion: [
				{
					title: formatMessage({id: 'project_budget.baseline_vs_projected_total_hours'}),
					description: formatMessage({id: 'project_budget.baseline_vs_projected_total_hours_tooltip'}),
					details: hasFinancialCategoriesUpdate
						? [formatMessage({id: 'baseline.hours'}), '-', formatMessage({id: 'common.projected_total_hours'})]
						: [
								formatMessage({id: 'common.total_estimate'}),
								'-',
								formatMessage({id: 'project_budget.actuals'}),
								'+',
								formatMessage({id: 'common.remaining'}),
						  ],
				},
			],
		};
	}
};

const BaselineVsActualTooltips = (formatMessage, budgetView, hasFinancialCategoriesUpdate) => {
	if (budgetView === BUDGET_VIEWS.MONEY) {
		return {
			baselineVsActualRevenueRecognition: [
				{
					title: formatMessage({id: 'project_budget.baseline_vs_actual_revenue'}),
					description: formatMessage({id: 'project_budget.baseline_vs_actual_revenue_tooltip'}),
					details: [
						formatMessage({id: 'project_budget.baseline_revenue'}),
						'-',
						formatMessage({id: 'project_budget.actual_revenue'}),
					],
				},
			],
			baselineVsBillableActualTimeAndExpenses: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'project_budget.baseline_vs_actual_billable_value_of_service'
							: 'project_budget.revenue',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.baseline_vs_actual_billable_value_of_service_tooltip'})
						: undefined,
					details: [
						formatMessage({
							id: hasFinancialCategoriesUpdate
								? 'project_budget.baseline_billable_value_of_service'
								: 'project_budget.baseline_revenue',
						}),
						'-',
						formatMessage({
							id: hasFinancialCategoriesUpdate
								? 'common.actual_billable_value_of_service'
								: 'project_budget.actual_revenue',
						}),
					],
				},
			],
			baselineVsActualCost: [
				{
					title: formatMessage({
						id: hasFinancialCategoriesUpdate ? 'project_budget.baseline_vs_actual_cost' : 'common.cost',
					}),
					description: hasFinancialCategoriesUpdate
						? formatMessage({id: 'project_budget.baseline_vs_actual_cost_tooltip'})
						: undefined,
					details: [
						formatMessage({id: 'project_budget.baseline_cost'}),
						'-',
						formatMessage({id: 'common.actual_cost'}),
					],
				},
			],
			baselineVsActualProfit: [
				{
					title: formatMessage({id: 'common.profit'}),
					details: [
						formatMessage({id: 'project_budget.variance_revenue'}),
						'-',
						formatMessage({id: 'project_budget.variance_cost'}),
					],
				},
			],
			baselineVsActualRevenueProfit: [
				{
					title: formatMessage({id: 'project_budget.baseline_vs_actual_profit'}),
					description: formatMessage({id: 'project_budget.baseline_vs_actual_profit_tooltip'}),
					details: [
						formatMessage({id: 'project_budget.baseline_profit'}),
						'-',
						formatMessage({id: 'project_budget.actual_profit'}),
					],
				},
			],
			baselineVsActualRevenueMargin: [
				{
					title: formatMessage({id: 'project_budget.baseline_vs_actual_margin'}),
					description: formatMessage({id: 'project_budget.baseline_vs_actual_margin_tooltip'}),
					details: [
						formatMessage({id: 'project_budget.baseline_margin'}),
						'-',
						formatMessage({id: 'project_budget.actual_margin'}),
					],
				},
			],
		};
	} else {
		return {
			baselineVsRegisteredMinutes: [
				{
					title: formatMessage({id: 'project_budget.baseline_vs_actual_hours'}),
					description: formatMessage({id: 'project_budget.baseline_vs_actual_hours_tooltip'}),
					details: [
						formatMessage({id: hasFinancialCategoriesUpdate ? 'baseline.hours' : 'common.total_estimate'}),
						'-',
						formatMessage({id: hasFinancialCategoriesUpdate ? 'common.actual_hours' : 'project_budget.actuals'}),
					],
				},
			],
		};
	}
};

const InvoicedVsActualTooltips = (formatMessage, project) => {
	return {
		accruedDeferred: [
			{
				title: formatMessage({id: 'common.variance'}),
				details: [
					formatMessage({id: 'project_section.invoice'}),
					'-',
					formatMessage({
						id:
							project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2
								? 'project_budget.revenue_recognition_from_locked_months'
								: 'project_budget.billable_time_and_expenses',
					}),
				],
			},
		],
	};
};

const TotalsTooltips = (
	formatMessage,
	budgetView,
	budgetMoneyView,
	settings,
	isFixedPrice,
	useFixedPriceCalculation,
	hasFinancialCategoriesUpdate
) => {
	return {
		...BaselineTooltips(
			formatMessage,
			budgetView,
			budgetMoneyView,
			isFixedPrice,
			useFixedPriceCalculation,
			hasFinancialCategoriesUpdate
		),
		...BaselineVsActualTooltips(formatMessage, budgetView, hasFinancialCategoriesUpdate),
		...BaselineVsForecastTooltips(formatMessage, budgetView, hasFinancialCategoriesUpdate),
		...ActualTooltips(formatMessage, budgetView, budgetMoneyView, settings, hasFinancialCategoriesUpdate),
		...PlanTooltips(formatMessage, budgetView, budgetMoneyView, settings, hasFinancialCategoriesUpdate),
		...PlanVsActualTooltips(formatMessage, budgetView, false, hasFinancialCategoriesUpdate),
		...PlanVsForecastTooltips(formatMessage, budgetView, hasFinancialCategoriesUpdate),
		...RemainingTooltips(formatMessage, budgetView, budgetMoneyView, settings, hasFinancialCategoriesUpdate),
		...ForecastTooltips(formatMessage, budgetView, settings, hasFinancialCategoriesUpdate),
	};
};

export const BudgetTooltips = {
	BaselineTooltips,
	ActualTooltips,
	PlanTooltips,
	PlanVsActualTooltips,
	RemainingTooltips,
	ForecastTooltips,
	PlanVsForecastTooltips,
	BaselineVsActualTooltips,
	BaselineVsForecastTooltips,
	InvoicedVsActualTooltips,
	TotalsTooltips,
};
