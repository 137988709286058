import {ITEM_TYPE} from './canvas-timeline/canvas_timeline_util';
import {MODULE_TYPES, PROJECT_STATUS, SCHEDULING_ACTION_MENU_TYPE, SCHEDULING_VIEW} from '../../constants';
import {
	PLACEHOLDERS_FILTER_STORAGE_KEY,
	PLACEHOLDERS_GROUP_BY,
	PLACEHOLDERS_GROUP_BY_TRACKING_MESSAGE,
	PLACEHOLDERS_SCHEDULING_EYE_STORAGE_KEY,
	PLACEHOLDERS_SCHEDULING_GROUP_BY_STORAGE_KEY,
	PLACEHOLDERS_SCHEDULING_SORT_BY_STORAGE_KEY,
	PLACEHOLDERS_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY,
	STAFFING_MODE_ROLE_FILTER_REMOVED_TRACKING_MESSAGE,
} from './placeholders-scheduling/CanvasPlaceholderSchedulingConstants';
import {
	CAPACITY_OVERVIEW_EYE_STORAGE_KEY,
	CAPACITY_OVERVIEW_FILTER_STORAGE_KEY,
} from './capacity-overview/CanvasCapacityOverviewConstants';
import {
	PEOPLE_SCHEDULING_EYE_OPTIONS_STORAGE_KEY,
	PEOPLE_SCHEDULING_FILTER_CHANGE_TRACKING_MESSAGE,
	PEOPLE_SCHEDULING_FILTERS,
	PEOPLE_SCHEDULING_GROUP_BY,
	PEOPLE_SCHEDULING_GROUP_BY_STORAGE_KEY,
	PEOPLE_SCHEDULING_GROUP_BY_TRACKING_MESSAGE,
	PEOPLE_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY,
	PEOPLE_SCHEDULING_VISUALIZATION_MODE_STORAGE_KEY,
} from './people-scheduling/PeopleSchedulingConstants';
import {
	PROJECTS_SCHEDULING_EYE_OPTIONS_STORAGE_KEY,
	PROJECTS_SCHEDULING_FILTER_STRING,
	PROJECTS_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY,
	PROJECTS_SCHEDULING_VISUALIZATION_MODE_STORAGE_KEY,
} from './projects-scheduling/ProjectsSchedulingConstants';
import {EVENT_TYPE} from './EventManager';
import {ACTION_MENU_OPTION_TYPE} from './constants';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {canApproveAllocation, hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import DataManager from './DataManager';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';

export const isProjectAllocationItem = item => {
	return item.itemType === ITEM_TYPE.PROJECT_ALLOCATION;
};

export const isPlaceholderAllocationItem = item => {
	return item.itemType === ITEM_TYPE.PLACEHOLDER_ALLOCATION;
};

export const isTaskItem = item => {
	return item.itemType === ITEM_TYPE.TASK;
};

export const isPhaseItem = item => {
	return item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PHASE;
};

export const getEyeOptionStorageKey = pageComponent => {
	const {schedulingView} = pageComponent.props;

	switch (schedulingView) {
		case SCHEDULING_VIEW.PLACEHOLDERS:
			return PLACEHOLDERS_SCHEDULING_EYE_STORAGE_KEY;
		case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
			return CAPACITY_OVERVIEW_EYE_STORAGE_KEY;
		case SCHEDULING_VIEW.PEOPLE:
			return PEOPLE_SCHEDULING_EYE_OPTIONS_STORAGE_KEY;
		case SCHEDULING_VIEW.PROJECTS:
			return PROJECTS_SCHEDULING_EYE_OPTIONS_STORAGE_KEY;
		default:
			break;
	}

	return null;
};

export const getFilterStorageKey = pageComponent => {
	const {schedulingView} = pageComponent.props;

	switch (schedulingView) {
		case SCHEDULING_VIEW.PLACEHOLDERS:
			return PLACEHOLDERS_FILTER_STORAGE_KEY;
		case SCHEDULING_VIEW.CAPACITY_OVERVIEW:
			return CAPACITY_OVERVIEW_FILTER_STORAGE_KEY;
		case SCHEDULING_VIEW.PEOPLE:
			return PEOPLE_SCHEDULING_FILTERS;
		case SCHEDULING_VIEW.PROJECTS:
			const {projectId, groupId} = pageComponent.props;
			return projectId
				? `${PROJECTS_SCHEDULING_FILTER_STRING}-projects-projectId-${projectId}`
				: groupId
				? `${PROJECTS_SCHEDULING_FILTER_STRING}-projects-groupId-${groupId}`
				: `${PROJECTS_SCHEDULING_FILTER_STRING}-projects`;
		default:
			break;
	}

	return '';
};

export const getVisualizationModeStorageKey = pageComponent => {
	const {schedulingView} = pageComponent.props;

	switch (schedulingView) {
		case SCHEDULING_VIEW.PEOPLE:
			return PEOPLE_SCHEDULING_VISUALIZATION_MODE_STORAGE_KEY;
		case SCHEDULING_VIEW.PROJECTS:
			return PROJECTS_SCHEDULING_VISUALIZATION_MODE_STORAGE_KEY;
		default:
			break;
	}

	return null;
};

export const getUtilizationFormatStorageKey = pageComponent => {
	const {schedulingView} = pageComponent.props;

	switch (schedulingView) {
		case SCHEDULING_VIEW.PLACEHOLDERS:
			return PLACEHOLDERS_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY;
		case SCHEDULING_VIEW.PEOPLE:
			return PEOPLE_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY;
		case SCHEDULING_VIEW.PROJECTS:
			return PROJECTS_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY;
		default:
			break;
	}

	return null;
};

export const getSortByStorageKey = pageComponent => {
	const {schedulingView} = pageComponent.props;

	switch (schedulingView) {
		case SCHEDULING_VIEW.PLACEHOLDERS:
			return PLACEHOLDERS_SCHEDULING_SORT_BY_STORAGE_KEY;
		default:
			break;
	}

	return null;
};

export const getGroupByStorageKey = pageComponent => {
	const {schedulingView} = pageComponent.props;

	switch (schedulingView) {
		case SCHEDULING_VIEW.PLACEHOLDERS:
			return PLACEHOLDERS_SCHEDULING_GROUP_BY_STORAGE_KEY;
		case SCHEDULING_VIEW.PEOPLE:
			return PEOPLE_SCHEDULING_GROUP_BY_STORAGE_KEY;
		default:
			break;
	}

	return '';
};

export const getGroupByOptions = pageComponent => {
	const {schedulingView} = pageComponent.props;

	switch (schedulingView) {
		case SCHEDULING_VIEW.PLACEHOLDERS:
			return PLACEHOLDERS_GROUP_BY;
		case SCHEDULING_VIEW.PEOPLE:
			return PEOPLE_SCHEDULING_GROUP_BY;
		default:
			break;
	}

	return {};
};

export const getEventTrackingMessage = (pageComponent, eventType) => {
	const {schedulingView} = pageComponent.props;
	const {staffingModeActive} = pageComponent.state;

	switch (eventType) {
		case EVENT_TYPE.STAFFING_ROLE_FILTER_REMOVED:
			switch (schedulingView) {
				case SCHEDULING_VIEW.PLACEHOLDERS:
					if (staffingModeActive) {
						return STAFFING_MODE_ROLE_FILTER_REMOVED_TRACKING_MESSAGE;
					}
					break;
				default:
					break;
			}

			break;

		case EVENT_TYPE.ON_FILTER_CHANGE:
			switch (schedulingView) {
				case SCHEDULING_VIEW.PEOPLE:
					return PEOPLE_SCHEDULING_FILTER_CHANGE_TRACKING_MESSAGE;
				default:
					break;
			}

			break;

		case EVENT_TYPE.ON_GROUP_BY_CHANGE:
			switch (schedulingView) {
				case SCHEDULING_VIEW.PLACEHOLDERS:
					return PLACEHOLDERS_GROUP_BY_TRACKING_MESSAGE;
				case SCHEDULING_VIEW.PEOPLE:
					return PEOPLE_SCHEDULING_GROUP_BY_TRACKING_MESSAGE;
				default:
					break;
			}

			break;
		default:
			break;
	}

	return '';
};

export const shouldDisplayActionMenu = (pageComponent, expandedActionMenuData, actionMenuType, actionMenuOptionType) => {
	const {schedulingView, isProjectTimeline, projectId} = pageComponent.props;
	const {placeholder, hasInvoice} = expandedActionMenuData;

	const placeholderHasAllocations = () => {
		if (placeholder) {
			const placeholderAllocations = DataManager.getPlaceholderAllocationByPlaceholderId(pageComponent, placeholder.id);
			return placeholderAllocations?.length > 0;
		}

		return false;
	};

	const requirements = {
		[SCHEDULING_ACTION_MENU_TYPE.PLACEHOLDER]: {
			[ACTION_MENU_OPTION_TYPE.PLACEHOLDER_FIND_AVAILABLE_TEAM_MEMBER]: {
				isMeetingGlobalRequirements: () => {
					return canApproveAllocation() && placeholderHasAllocations();
				},
				[SCHEDULING_VIEW.PLACEHOLDERS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.CAPACITY_OVERVIEW]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
			},
			[ACTION_MENU_OPTION_TYPE.PLACEHOLDER_REPLACE_WITH_PERSON]: {
				isMeetingGlobalRequirements: () => {
					return canApproveAllocation();
				},
				[SCHEDULING_VIEW.PLACEHOLDERS]: {
					isMeetingRequirement: () => {
						return !hasFeatureFlag('placeholders_beta_changes');
					},
				},
				[SCHEDULING_VIEW.CAPACITY_OVERVIEW]: {
					isMeetingRequirement: () => {
						return !hasFeatureFlag('placeholders_beta_changes') && placeholderHasAllocations();
					},
				},
			},
			[ACTION_MENU_OPTION_TYPE.PLACEHOLDER_EDIT]: {
				isMeetingGlobalRequirements: () => {
					return hasPermission(PERMISSION_TYPE.SCHEDULING_ACCESS);
				},
				[SCHEDULING_VIEW.PLACEHOLDERS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.CAPACITY_OVERVIEW]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
			},
			[ACTION_MENU_OPTION_TYPE.PLACEHOLDER_DELETE]: {
				isMeetingGlobalRequirements: () => {
					return hasPermission(PERMISSION_TYPE.SCHEDULING_ACCESS);
				},
				[SCHEDULING_VIEW.PLACEHOLDERS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.CAPACITY_OVERVIEW]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
			},
			[ACTION_MENU_OPTION_TYPE.PLACEHOLDER_ASSIGN_TO_PERSON]: {
				isMeetingGlobalRequirements: () => {
					return canApproveAllocation() && placeholderHasAllocations();
				},
				[SCHEDULING_VIEW.PLACEHOLDERS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.CAPACITY_OVERVIEW]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
			},
			[ACTION_MENU_OPTION_TYPE.PLACEHOLDER_DUPLICATE]: {
				[SCHEDULING_VIEW.PLACEHOLDERS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.CAPACITY_OVERVIEW]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
			},
		},
		[SCHEDULING_ACTION_MENU_TYPE.PERSON]: {
			[ACTION_MENU_OPTION_TYPE.PERSON_ADD_TO_PROJECT]: {
				isMeetingGlobalRequirements: () => {
					return hasPermission(PERMISSION_TYPE.PROJECT_PERSON_CREATE);
				},
				[SCHEDULING_VIEW.PEOPLE]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.PLACEHOLDERS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.CAPACITY_OVERVIEW]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
			},
			[ACTION_MENU_OPTION_TYPE.PERSON_REMOVE_FROM_PROJECT]: {
				isMeetingGlobalRequirements: () => {
					return hasPermission(PERMISSION_TYPE.PROJECT_PERSON_DELETE);
				},
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
			},
		},
		[SCHEDULING_ACTION_MENU_TYPE.PROJECT]: {
			[ACTION_MENU_OPTION_TYPE.PROJECT_VIEW_PROJECT]: {
				[SCHEDULING_VIEW.PEOPLE]: {
					isMeetingRequirement: () => {
						return true;
					},
				},
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						const isProjectOnTimeline = isProjectTimeline && projectId;
						const isProjectGroup = expandedActionMenuData.companyProjectGroupId;
						return !isProjectOnTimeline || isProjectGroup;
					},
				},
			},
			[ACTION_MENU_OPTION_TYPE.PROJECT_REMOVE_FROM_PROJECT]: {
				[SCHEDULING_VIEW.PEOPLE]: {
					isMeetingRequirement: () => {
						return hasPermission(PERMISSION_TYPE.PROJECT_PERSON_DELETE);
					},
				},
			},
			[ACTION_MENU_OPTION_TYPE.PROJECT_SET_STATUS_RUNNING]: {
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						const {status, project} = expandedActionMenuData;
						const {companyProjectId} = project || {};
						const validStatuses = [PROJECT_STATUS.PLANNING, PROJECT_STATUS.OPPORTUNITY];
						return companyProjectId !== undefined && validStatuses.includes(status);
					},
				},
			},
			[ACTION_MENU_OPTION_TYPE.PROJECT_DUPLICATE]: {
				isMeetingGlobalRequirements: () => {
					return hasPermission(PERMISSION_TYPE.PROJECTS_CREATE) && !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);
				},
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						const {project} = expandedActionMenuData;
						const {companyProjectId} = project || {};
						return companyProjectId !== undefined;
					},
				},
			},
			[ACTION_MENU_OPTION_TYPE.PROJECT_DELETE]: {
				isMeetingGlobalRequirements: () => {
					return hasPermission(PERMISSION_TYPE.PROJECTS_DELETE) && !hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED);
				},
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						const {project} = expandedActionMenuData;
						const {companyProjectId} = project || {};
						return companyProjectId !== undefined && !hasInvoice;
					},
				},
			},
		},
		[SCHEDULING_ACTION_MENU_TYPE.PROGRAM]: {
			[ACTION_MENU_OPTION_TYPE.PROGRAM_VIEW_PROGRAM]: {
				isMeetingGlobalRequirements: () => {
					const {prefix} = expandedActionMenuData;
					return prefix !== undefined;
				},
				[SCHEDULING_VIEW.PROJECTS]: {
					isMeetingRequirement: () => {
						const {isProjectTimeline, sharedContext} = pageComponent.props;
						return !isProjectTimeline && !sharedContext;
					},
				},
			},
		},
	};

	const actionMenuTypeRequirements = requirements[actionMenuType];
	if (actionMenuTypeRequirements) {
		const optionRequirement = actionMenuTypeRequirements[actionMenuOptionType];

		if (optionRequirement) {
			const isMeetingGlobalRequirements =
				!optionRequirement.isMeetingGlobalRequirements || optionRequirement.isMeetingGlobalRequirements();
			const schedulingViewRequirements = optionRequirement[schedulingView];

			if (schedulingViewRequirements && isMeetingGlobalRequirements) {
				return schedulingViewRequirements.isMeetingRequirement();
			}
		}
	}

	return false;
};
