import React from 'react';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import CustomScrollDiv from '../../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {
	DeprecatedButtonIconLeft as ButtonIconLeft,
	DeprecatedContentContainer as ContentContainer,
	FlexColumn,
	FlexRow,
	DeprecatedText as Text,
	Icon,
} from '@forecast-it/design-system';
import {MODAL_TYPE, showModal} from '../../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createFragmentContainer, graphql} from 'react-relay';
import Util from '../../../../../forecast-app/shared/util/util';
import {renderCurrency} from '../util/BudgetUtils';
import ProjectUtil from '../../../../../forecast-app/shared/util/project_util';
import * as tracking from '../../../../../tracking';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';
import ForecastTooltip from '../../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {useTrackPage} from '../../../../../tracking/amplitude/hooks/useTrackPage';

const openEditorModal = (project, company) => {
	showModal({
		type: MODAL_TYPE.PROJECT_SETTINGS_EDIT_RATE_AND_COST_LITE,
		project: project,
		company: company,
	});
};

const UnlockRatesLearnMoreTracking = () => {
	tracking.trackEvent('Budget rates & internal cost - user clicked on "Learn more here"');
	trackEvent("Budget Rates And Internal Cost's Learn More Here", 'Clicked');
};

const RatesAndInternalCostLitePage = React.memo(({company, project}) => {
	useTrackPage('Project Financials Rates And Internal Cost', null, {budgetType: project.budgetType});
	const intl = useIntl();
	const {formatMessage} = intl;

	const currencySymbol = Util.GetCurrencySymbol(company.currency);

	return (
		<CustomScrollDiv>
			<FlexColumn gap={'l'}>
				<ContentContainer>
					<FlexColumn gap={'l'}>
						<FlexRow gap={'s'}>
							<Text type={'heading'} variant={'xl'}>
								{formatMessage({
									id: ProjectUtil.projectTracksRevenue(project)
										? 'project_budget.rate_and_cost'
										: 'project_budget.internal_cost',
								})}
							</Text>
							<ButtonIconLeft icon={'edit'} type={'ghost'} onClick={() => openEditorModal(project, company)}>
								{formatMessage({id: 'common.edit'})}
							</ButtonIconLeft>
						</FlexRow>
						{ProjectUtil.projectTracksRevenue(project) && (
							<FlexColumn gap={'s'}>
								<FlexRow gap={'s'}>
									<Text type={'heading'} variant={'s'}>
										{formatMessage({id: 'project_budget.blended_rate'})}
									</Text>
									<ForecastTooltip
										placement={'bottom'}
										content={formatMessage({id: 'project_budget.blended_rate_tooltip'})}
									>
										<Icon icon={'help'} size={'s'} />
									</ForecastTooltip>
								</FlexRow>
								<Text>{renderCurrency(currencySymbol, project.flatRate, intl)}</Text>
							</FlexColumn>
						)}
						<FlexColumn gap={'s'}>
							<FlexRow gap={'s'}>
								<Text type={'heading'} variant={'s'}>
									{formatMessage({id: 'project_budget.internal_hourly_cost'})}
								</Text>
								<ForecastTooltip
									placement={'bottom'}
									content={formatMessage({id: 'project_budget.internal_hourly_cost_tooltip'})}
								>
									<Icon icon={'help'} size={'s'} />
								</ForecastTooltip>
							</FlexRow>
							<Text>{renderCurrency(currencySymbol, project.flatCost, intl)}</Text>
						</FlexColumn>
					</FlexColumn>
				</ContentContainer>
				<Text>
					<FormattedHTMLMessage id={'project_budget.ratecard_unlock_teaser'} />
					<a
						href={'https://support.forecast.app/hc/en-us/articles/4787074726545-Managing-rate-cards-in-projects'}
						target="_blank"
						rel="noopener noreferrer"
						style={{cursor: 'pointer'}}
						onClick={() => {
							UnlockRatesLearnMoreTracking();
						}}
					>
						{formatMessage({id: 'common.learn_more_here'})}
					</a>
				</Text>
			</FlexColumn>
		</CustomScrollDiv>
	);
});

export default createFragmentContainer(RatesAndInternalCostLitePage, {
	project: graphql`
		fragment RatesAndInternalCostLitePage_project on ProjectType {
			id
			budgetType
			flatRate
			flatCost
		}
	`,
	company: graphql`
		fragment RatesAndInternalCostLitePage_company on Company {
			id
			currency
		}
	`,
});
