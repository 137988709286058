import React from 'react';
import {Button} from 'web-components';
import {ShareIcon} from 'web-components/icons';

import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';
import {FormattedMessage} from 'react-intl';

export const ShareMenuItem = ({onClick, userpilot, disabled}) => {
	return (
		<TooltipContainer infoText={<FormattedMessage id={disabled ? 'common.shared.no.access' : 'common.shared'} />}>
			<Button
				disabled={disabled}
				icon={color => <ShareIcon color={color} size="large" />}
				isSquare
				iconPosition={Button.ICON_POSITION.FIRST}
				variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
				userpilot={userpilot}
				onClick={onClick}
			/>
		</TooltipContainer>
	);
};
