import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {filterAggregateFields, isColumnLocked, TABLE_TYPE} from '../util/BudgetUtils';
import {createFragmentContainer, graphql} from 'react-relay';
import {CollapsibleSectionWrapper, TableSectionWrapper} from '../money/work_and_expenses/TableSection.styled';
import RevenueRecognitionTableRow from './RevenueRecognitionTableRow';
import {LoadMore} from '../../../../loaders/LoadMore';
import RevenueRecognitionDetailsLoader, {
	revenueRecognitionDetailsLoaderQuery,
} from '../loaders/RevenueRecognitionDetailsLoader';
import {Collapsible, CustomScrollDiv, LockIcon, Table, useCollapsible} from 'web-components';
import {SPECIAL_ROLE} from '../../../../../constants';
import getAggregateRowTitle from '../util/UseAggregateIntl';
import {RevenueRecognitionTableContainer, RevenueRecognitionTableHeadline} from './RevenueRecognition.styled';
import ForecastTooltip from '../../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {ForecastTooltipFormulaRenderer} from '../../../../ForecastTooltipFormulaRenderer';
import {RevenueRecognitionTooltips} from './RevenueRecognitionTooltips';
import Util from '../../../../../forecast-app/shared/util/util';

const ExpenseRevenueRecognition = ({project}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	const [tableHeight, setTableHeight] = useState(400);

	const revenueAggregates = filterAggregateFields(project, [
		'recognitionAmount',
		'expenseRevenuePercentage',
		'recognitionProfit',
		'recognitionProfitPercentage',
	]);
	const costAggregates = filterAggregateFields(project, ['estimatedCost', 'expenseCostPercentage']);
	const allAggregates = revenueAggregates.concat(costAggregates);

	const searchQuery = {
		filters: [
			{
				field: 'ROLE',
				operator: 'IN',
				value: SPECIAL_ROLE.EXPENSE,
			},
		],
	};

	return (
		<CollapsibleSectionWrapper>
			<Collapsible
				initiallyCollapsed={true}
				title={formatMessage({id: 'project_budget.recognition_revenue_table_headline.recognition_expense_table'})}
				localStorageKey={'project_budget.colapse_expense_revenue_recognition_table'}
				cy={'expense-revenue-recognition-collapse'}
			>
				<TableSectionWrapper>
					<LoadMore
						key="query-render-revenue-recognition-details"
						query={revenueRecognitionDetailsLoaderQuery}
						loader={
							<RevenueRecognitionDetailsLoader
								aggregateLevel={'MONTH'}
								revenueAggregates={revenueAggregates}
								billableTimeAggregates={[]}
								varianceAndAdjustmentAggregates={[]}
								costAggregates={costAggregates}
								allAggregates={allAggregates}
								tableType={TABLE_TYPE.EXPENSE_REVENUE_RECOGNITION}
							/>
						}
						variables={{
							projectId: project.id,
							startYear: project.projectFirstDateYear,
							startMonth: project.projectFirstDateMonth,
							startDay: project.projectFirstDateDay,
							endYear: project.projectLastDateYear,
							endMonth: project.projectLastDateMonth,
							endDay: project.projectLastDateDay,
							aggregateLevel: 'MONTH',
							aggregates: allAggregates,
							searchQuery: searchQuery,
						}}
					>
						{data => {
							const calculateTableHeight = () => {
								const isTableShown = table => {
									const isShown = Util.localStorageGetItem(table.headlineTextId) !== 'true';
									return isShown;
								};

								const rowAmount = data.tables.reduce(
									(total, table) => total + (isTableShown(table) ? table.rows.length : 0),
									0
								);
								return data.tables.length * 78 + rowAmount * 48 + 15;
							};
							setTableHeight(calculateTableHeight());

							return (
								<CustomScrollDiv horizontalContent autoHeight={true} autoHeightMin={tableHeight}>
									{data.tables.map(table => {
										const title = (
											<RevenueRecognitionTableHeadline>
												{formatMessage({id: table.headlineTextId})}
											</RevenueRecognitionTableHeadline>
										);
										const {Collapser, collapsed} = useCollapsible({
											initiallyCollapsed: false,
											localStorageKey: table.headlineTextId,
											title,
										});
										useEffect(() => {
											setTableHeight(calculateTableHeight());
										}, [collapsed]);
										return (
											<CollapsibleSectionWrapper>
												<RevenueRecognitionTableContainer>
													<Table paddedCells>
														<Table.Header>
															<Table.HeaderColumn visible flex={1} width="EXTRA_LARGE">
																<Collapser />
															</Table.HeaderColumn>
															{table.columns.map((column, index) => (
																<Table.HeaderColumn
																	key={
																		formatMessage({id: 'common.revenue_recognition'}) +
																		column.name
																	}
																	usePadding
																	align="right"
																	width="MEDIUM_SMALL"
																	visible
																	wrapText={true}
																>
																	<Table.HeaderColumn.Title bold={index === table.length - 1}>
																		{table.showHeaderRow ? (
																			<>
																				{isColumnLocked(
																					project.fixedPriceLocks,
																					column
																				) && (
																					<div style={{marginRight: 10}}>
																						<LockIcon
																							color={'#C4C4CC'}
																							size={LockIcon.SIZE.STANDARD}
																						/>
																					</div>
																				)}
																				<div>{column.name}</div>
																			</>
																		) : null}
																	</Table.HeaderColumn.Title>
																</Table.HeaderColumn>
															))}
														</Table.Header>
														<Table.Rows data={collapsed ? [] : table} canExpand={false}>
															{({rowData, tableColumnsProps}) => {
																const tooltipItems =
																	RevenueRecognitionTooltips
																		.ExpenseRevenueRecognitionTooltips[rowData.aggregate];
																return (
																	<RevenueRecognitionTableRow
																		data={table}
																		rowData={rowData}
																		tableColumnProps={tableColumnsProps}
																		project={project}
																		headerColumn={
																			<Table.Column wrapText={true}>
																				<ForecastTooltip
																					maxWidth={600}
																					content={
																						tooltipItems ? (
																							<ForecastTooltipFormulaRenderer
																								items={tooltipItems}
																								translatedMessage={true}
																							/>
																						) : null
																					}
																				>
																					{getAggregateRowTitle(
																						intl,
																						rowData.aggregate,
																						TABLE_TYPE.EXPENSE_REVENUE_RECOGNITION
																					)}
																				</ForecastTooltip>
																			</Table.Column>
																		}
																	/>
																);
															}}
														</Table.Rows>
													</Table>
												</RevenueRecognitionTableContainer>
											</CollapsibleSectionWrapper>
										);
									})}
								</CustomScrollDiv>
							);
						}}
					</LoadMore>
				</TableSectionWrapper>
			</Collapsible>
		</CollapsibleSectionWrapper>
	);
};

export default createFragmentContainer(ExpenseRevenueRecognition, {
	project: graphql`
		fragment ExpenseRevenueRecognition_project on ProjectType {
			id
			billable
			plannedBillableExpenses
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
			fixedPriceLocks(first: 1000) @connection(key: "Project_fixedPriceLocks", filters: []) {
				edges {
					node {
						id
						startDate
						endDate
					}
				}
			}
		}
	`,
});
