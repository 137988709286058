import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
class ProjectStatusIndicator extends Component {
	render() {
		const {formatMessage} = this.props.intl;
		return (
			<TooltipContainer
				infoText={formatMessage(
					{id: 'project_section.locked-info'},
					{status: this.props.status ? this.props.status.toLowerCase() : ''}
				)}
				width={188}
			>
				<div className={'project-status-indicator'}>
					{this.props.status === 'DONE' ? (
						<div className={'done-indicator'}>
							<div className={'indicator-icon'} />
							<div className={'text'}>{formatMessage({id: 'project_status.done'})}</div>
						</div>
					) : this.props.status === 'HALTED' ? (
						<div className={'halted-indicator'}>
							<div className={'indicator-icon'} />
							<div className={'text'}>{formatMessage({id: 'project_status.halted'})}</div>
						</div>
					) : null}
				</div>
			</TooltipContainer>
		);
	}
}
ProjectStatusIndicator.propTypes = {
	status: PropTypes.oneOf(['DONE', 'HALTED']),
};
export default injectIntl(ProjectStatusIndicator);
