/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MonthByMonthProjectLoader_viewer$ref: FragmentReference;
declare export opaque type MonthByMonthProjectLoader_viewer$fragmentType: MonthByMonthProjectLoader_viewer$ref;
export type MonthByMonthProjectLoader_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string,
    +financialBasedProjects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +companyProjectId: ?number,
          +name: ?string,
          +readOnlyAccess: ?boolean,
          +fullAccessToProject: ?boolean,
          +projectColor: ?string,
          +useBaseline: ?boolean,
          +status: ?PROJECT_STATUS,
          +baselineWinChance: ?number,
          +priorityLevel: ?{|
            +id: string,
            +name: ?string,
          |},
          +budgetType: ?BUDGET_TYPE,
          +budgetBaseCurrency: ?number,
          +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
          +projectStartDay: ?number,
          +projectStartMonth: ?number,
          +projectStartYear: ?number,
          +projectEndDay: ?number,
          +projectEndMonth: ?number,
          +projectEndYear: ?number,
          +estimationUnit: ?string,
          +completion: ?number,
          +rateCard: ?{|
            +id: string,
            +name: ?string,
            +currency: ?string,
            +parentRateCardId: ?string,
          |},
          +client: ?{|
            +id: string,
            +name: ?string,
            +logoId: ?string,
            +logoDefaultId: ?number,
          |},
          +projectLabels: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +label: ?{|
                  +name: ?string,
                  +color: ?string,
                |}
              |}
            |}>
          |},
          +projectPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +isContactPerson: ?boolean,
                +person: ?{|
                  +id: string,
                  +fullName: ?string,
                  +firstName: ?string,
                  +lastName: ?string,
                  +profilePictureId: ?string,
                  +profilePictureDefaultId: ?number,
                  +initials: ?string,
                |},
              |}
            |}>
          |},
          +currentProjectStatus: ?{|
            +id: string,
            +color: ?string,
            +description: ?string,
            +person: ?{|
              +id: string,
              +firstName: ?string,
              +lastName: ?string,
              +fullName: ?string,
              +profilePictureId: ?string,
            |},
          |},
          +aggregatedFinancialNumbers: $ReadOnlyArray<?string>,
          +timeAggregatedFinancialNumbers: $ReadOnlyArray<?string>,
          +expenseAggregatedFinancialNumbers: $ReadOnlyArray<?string>,
          +fixedPriceLocks: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +startDate: ?string,
                +endDate: ?string,
                +locked: ?boolean,
              |}
            |}>
          |},
          +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
        |}
      |}>
    |},
  |},
  +$refType: MonthByMonthProjectLoader_viewer$ref,
|};
export type MonthByMonthProjectLoader_viewer$data = MonthByMonthProjectLoader_viewer;
export type MonthByMonthProjectLoader_viewer$key = {
  +$data?: MonthByMonthProjectLoader_viewer$data,
  +$fragmentRefs: MonthByMonthProjectLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "aggregateLevel",
  "value": "MONTH"
},
v3 = {
  "items": [
    {
      "kind": "Variable",
      "name": "aggregates.0",
      "variableName": "selectedSingleValue"
    }
  ],
  "kind": "ListValue",
  "name": "aggregates"
},
v4 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v5 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v6 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v7 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v8 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v9 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v17 = {
  "kind": "Literal",
  "name": "fetchOwnData",
  "value": true
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "timeSearchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "expenseSearchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "selectedSingleValue",
      "type": "FinancialAggregatedNumber!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "company",
          "financialBasedProjects"
        ]
      }
    ]
  },
  "name": "MonthByMonthProjectLoader_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": "financialBasedProjects",
          "args": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            },
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/)
          ],
          "concreteType": "ProjectTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_financialBasedProjects_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyProjectId",
                      "storageKey": null
                    },
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "readOnlyAccess",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullAccessToProject",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectColor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "useBaseline",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineWinChance",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PriorityLevel",
                      "kind": "LinkedField",
                      "name": "priorityLevel",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "budgetType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "budgetBaseCurrency",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "defaultPeriodBudgetType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectStartDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectStartMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectStartYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectEndDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectEndMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectEndYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "estimationUnit",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "completion",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RateCard",
                      "kind": "LinkedField",
                      "name": "rateCard",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v10/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "parentRateCardId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Client",
                      "kind": "LinkedField",
                      "name": "client",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v10/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "logoId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "logoDefaultId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": (v11/*: any*/),
                      "concreteType": "ProjectLabelTypeConnection",
                      "kind": "LinkedField",
                      "name": "projectLabels",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectLabelTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectLabel",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Label",
                                  "kind": "LinkedField",
                                  "name": "label",
                                  "plural": false,
                                  "selections": [
                                    (v10/*: any*/),
                                    (v12/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "projectLabels(first:1000)"
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "contactsOnly",
                          "value": true
                        },
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 1000000
                        }
                      ],
                      "concreteType": "ProjectPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "projectPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isContactPerson",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v13/*: any*/),
                                    (v14/*: any*/),
                                    (v15/*: any*/),
                                    (v16/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "profilePictureDefaultId",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "initials",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "projectPersons(contactsOnly:true,first:1000000)"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectStatus",
                      "kind": "LinkedField",
                      "name": "currentProjectStatus",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v12/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Person",
                          "kind": "LinkedField",
                          "name": "person",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v14/*: any*/),
                            (v15/*: any*/),
                            (v13/*: any*/),
                            (v16/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/)
                      ],
                      "kind": "ScalarField",
                      "name": "aggregatedFinancialNumbers",
                      "storageKey": null
                    },
                    {
                      "alias": "timeAggregatedFinancialNumbers",
                      "args": [
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v17/*: any*/),
                        {
                          "kind": "Variable",
                          "name": "searchQuery",
                          "variableName": "timeSearchQuery"
                        },
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/)
                      ],
                      "kind": "ScalarField",
                      "name": "aggregatedFinancialNumbers",
                      "storageKey": null
                    },
                    {
                      "alias": "expenseAggregatedFinancialNumbers",
                      "args": [
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v17/*: any*/),
                        {
                          "kind": "Variable",
                          "name": "searchQuery",
                          "variableName": "expenseSearchQuery"
                        },
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/)
                      ],
                      "kind": "ScalarField",
                      "name": "aggregatedFinancialNumbers",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": (v11/*: any*/),
                      "concreteType": "ProjectFixedPriceLockTypeConnection",
                      "kind": "LinkedField",
                      "name": "fixedPriceLocks",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectFixedPriceLockTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectFixedPriceLockType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "startDate",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "endDate",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "locked",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "fixedPriceLocks(first:1000)"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "DeprecatedProjectIndicatorJS_project"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f3425d61b0884aa5998cb4da755fe76f';

module.exports = node;
