import React from 'react';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FlexRow, OutlineBox, PageWrapper, Section} from '../UtilizationReport.styled';
import {PeriodUtilizationTable} from './PeriodUtilizationTable';
import {UtilizationCharts} from '../UtilizationCharts';

export const PeriodPage = ({
	theEyeOptionsNestedTree,
	startDate,
	endDate,
	filters,
	aggregatedResourceNumbersByDay,
	totalResourceNumbers,
	companyTarget,
	showResourceUtilization,
	isUsingAllocations,
	setCsvDownloadFunction,
	groupBy,
	allocationControlsOptions,
	isMixedAllocationModeEnabled,
}) => {
	return (
		<PageWrapper>
			<CustomScrollDiv horizontalandverticalcontent>
				<UtilizationCharts
					startDate={startDate}
					endDate={endDate}
					aggregatedResourceNumbersByDay={aggregatedResourceNumbersByDay}
					companyTarget={companyTarget}
					showResourceUtilization={showResourceUtilization}
					totalResourceNumbers={totalResourceNumbers}
					isMixedAllocationModeEnabled={isMixedAllocationModeEnabled}
					allocationControlsOptions={allocationControlsOptions}
				/>
				<Section marginTop={28} data-userpilot={'utilization-period-table'} data-cy={'utilization-period-table'}>
					<FlexRow growable>
						<OutlineBox growable marginRight={24} marginLeft={24} marginBottom={24}>
							<PeriodUtilizationTable
								theEyeOptionsNestedTree={theEyeOptionsNestedTree}
								filters={filters}
								startDate={startDate}
								endDate={endDate}
								totalResourceNumbers={totalResourceNumbers}
								isUsingAllocations={isUsingAllocations}
								setCsvDownloadFunction={setCsvDownloadFunction}
								groupBy={groupBy}
								allocationControlsOptions={allocationControlsOptions}
							/>
						</OutlineBox>
					</FlexRow>
				</Section>
			</CustomScrollDiv>
		</PageWrapper>
	);
};
