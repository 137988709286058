import React from 'react';
import Info from '../../components/info';

const ProjectIntegrationSection = ({icon, title, description, children}) => (
	<fieldset className="project-integration-section">
		<div className="integration-title-row">
			{icon ? icon : ''}
			<h3>{title}</h3>
			<Info infoMessage={description} />
		</div>
		<div className="integration-section-body">{children}</div>
	</fieldset>
);

export default ProjectIntegrationSection;
