import uuid from 'uuid';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import Util from '../../../forecast-app/shared/util/util';

let timestampCorrelationId,
	timestampDataLoaded,
	timestampCanvasRendered,
	trackingPersonHeatmapTotalsPerformance,
	pageName,
	initialized;
const performanceRecords = new Map();
const alreadyRecalculatedPersonIds = new Set();

export const trackPerformanceInit = _pageName => {
	timestampCorrelationId = uuid.v4();
	pageName = _pageName;
	timestampDataLoaded = null;
	timestampCanvasRendered = null;
	trackingPersonHeatmapTotalsPerformance = true;
	initialized = true;
};

const timeInMs = (start, end) => end - start;

export const trackPersonHeatmapTotalsPerformance = () => {
	if (!trackingPersonHeatmapTotalsPerformance) {
		performanceRecords.clear();
		trackingPersonHeatmapTotalsPerformance = true;
	}
};

const storePerformanceMetric = (metricName, value) => {
	if (initialized) {
		Util.storeCustomMetric(pageName, metricName, value);
	}
};

export const addPersonHeatmapPerformanceRecord = (id, duration) => {
	if (!performanceRecords.has(id)) {
		performanceRecords.set(id, duration);
	}
};

export const trackPerformanceDataLoad = loadDataPromise => {
	if (!timestampDataLoaded) {
		const timestampInitialized = performance.now();
		return loadDataPromise.then(result => {
			timestampDataLoaded = performance.now();
			const duration = timeInMs(timestampInitialized, timestampDataLoaded);
			trackEvent('Canvas Timeline', 'Loaded', {
				duration,
			});
			storePerformanceMetric('DataLoaded', duration);
			return result;
		});
	} else {
		return loadDataPromise;
	}
};

export const trackPerformanceInitialRender = () => {
	if (timestampDataLoaded && !timestampCanvasRendered) {
		timestampCanvasRendered = performance.now();
		const duration = timeInMs(timestampDataLoaded, timestampCanvasRendered);
		trackEvent('Canvas Timeline', 'Rendered', {
			duration,
		});
		storePerformanceMetric('Rendered', duration);
	}
	if (trackingPersonHeatmapTotalsPerformance) {
		if (performanceRecords.size > 0) {
			const duration = Array.from(performanceRecords.values()).reduce((a, b) => a + b, 0);
			trackEvent('Person Heatmap', 'Totals', {
				persons: performanceRecords.size,
				duration,
			});
			storePerformanceMetric('HeatmapTotals', duration);
		}
		trackingPersonHeatmapTotalsPerformance = false;
	}
};

const getItemsStats = visibleItems => {
	if (visibleItems && Object.keys(visibleItems).length > 0) {
		return Object.entries(visibleItems).reduce((acc, [key, value]) => {
			acc[key] = value?.length;
			return acc;
		}, {});
	}
	return {};
};

export const trackPerformancePersonHeatmap = (personId, recalculated, duration, items) => {
	addPersonHeatmapPerformanceRecord(personId, duration);
	if (recalculated && !trackingPersonHeatmapTotalsPerformance && !alreadyRecalculatedPersonIds.has(personId)) {
		alreadyRecalculatedPersonIds.add(personId);
		trackEvent('Person Heatmap', 'Recalculated', {
			person: personId,
			duration,
			...getItemsStats(items),
		});
	}
};

export const trackPerformance = (action, trackedAction) => {
	const start = performance.now();
	trackedAction();
	const end = performance.now();
	const duration = timeInMs(start, end);
	trackEvent('Canvas Timeline', action, {
		duration,
	});
	storePerformanceMetric(action, duration);
};

export const getTimestampCorrelationId = () => timestampCorrelationId;
