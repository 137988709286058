import React from 'react';
import { Modal, Text, FlexColumn, FlexRow, Frame, SubHeading } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { createToast } from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import DeleteExpenseItemMutation from '../../mutations/delete_expense_item_mutation';
import Util from '../../forecast-app/shared/util/util';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
const DeleteExpenseModal = ({ selectedRow, companyId, closeModal }) => {
    var _a;
    const { formatMessage } = useIntl();
    const onDelete = () => {
        var _a, _b;
        const onSuccess = () => {
            closeModal();
            createToast({ duration: 5000, message: formatMessage({ id: 'expense_item.has_been_deleted' }) });
        };
        Util.CommitMutation(DeleteExpenseItemMutation, {
            id: selectedRow.id,
            companyId,
            projectId: (_a = selectedRow.project) === null || _a === void 0 ? void 0 : _a.id,
            companyProjectId: (_b = selectedRow.project) === null || _b === void 0 ? void 0 : _b.companyProjectId,
        }, onSuccess);
        trackEvent('Expense Actions Delete', 'Clicked', { expenseId: selectedRow.id });
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "expense_management.modal.delete_expense" })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 'xxl' },
                React.createElement(FlexRow, null,
                    React.createElement(Text, null,
                        React.createElement(FormattedMessage, { id: "expense_management.modal.are_you_sure_delete_expense" }))),
                React.createElement(Frame, null,
                    React.createElement(FlexRow, { gap: 'xl' },
                        React.createElement(FlexColumn, { flex: 'none', gap: 's' },
                            React.createElement(Text, null, formatMessage({ id: 'expense_management.modal.expense_name' })),
                            React.createElement(Text, null, formatMessage({ id: 'expense_management.modal.project_name' }))),
                        React.createElement(FlexColumn, { gap: 's' },
                            React.createElement(SubHeading, { ellipsis: true }, selectedRow.name),
                            React.createElement(SubHeading, { ellipsis: true }, (_a = selectedRow.project) === null || _a === void 0 ? void 0 : _a.name)))))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: onDelete },
                React.createElement(FormattedMessage, { id: "expense_management.modal.delete_expense" })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default DeleteExpenseModal;
