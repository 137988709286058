import Item from '../../canvas-timeline/canvas_timeline_item';
import {
	ITEM_TYPE,
	getTextColor,
	drawRectangle,
	getTrimmedText,
	TIMELINE_BAR_BORDER_RADIUS,
	TIMELINE_BAR_PADDING_X,
	TIMELINE_GREY_TASK_BAR_COLOR,
	TOOLTIP_DISPLAY_DELAY_MS,
	hasViewOnlyAccess,
} from '../../canvas-timeline/canvas_timeline_util';
import Util from '../../../../forecast-app/shared/util/util';
import Color from 'color';
import EventManager from '../../EventManager';
import DataManager from '../../DataManager';
import {getAllocationData} from '../../projects-scheduling/projects_scheduling_allocation_data';

import {DISTRIBUTION_TYPE} from '../../constants';

class PhaseItem extends Item {
	constructor(pageComponent, data) {
		super(pageComponent, ITEM_TYPE.PROJECT_SCHEDULING_PHASE, data);
	}

	async onMouseEnter(eventData, event) {
		const {sharedContext} = this.pageComponent.props;

		if (!sharedContext) {
			const {clients} = this.pageComponent.getData();
			const {phase} = this.data;

			const project = DataManager.getProjectById(this.pageComponent, phase.projectId);
			const projectGroup = project.isInProjectGroup
				? DataManager.getProjectGroupById(this.pageComponent, project.projectGroupId)
				: null;
			const allocationData = getAllocationData(this.pageComponent, this);

			const {y} = eventData;
			const {clientX, clientY} = event;

			this.pageComponent.setState({
				hoverX: clientX,
				hoverY: clientY,
				showDistributionBox: false,
			});

			setTimeout(() => {
				this.pageComponent.setState({
					showDistributionBox: true,
					distributionBoxX: clientX,
					distributionBoxY: y,
					distributionBoxData: {
						project,
						clients,
						projectGroup,
						phase,
						allocationData,
					},
				});
			}, TOOLTIP_DISPLAY_DELAY_MS);
		}
	}

	onMouseLeave() {
		this.pageComponent.setState({showDistributionBox: false, hoverX: null, hoverY: null, distributionBoxData: null});
	}

	onMoving(movedItem, group, startDifference, endDifference, dragData, movedDays) {
		return EventManager.onDistributionItemMoving(
			this.pageComponent,
			this,
			DISTRIBUTION_TYPE.PHASE,
			movedItem,
			group,
			startDifference,
			endDifference,
			dragData,
			movedDays
		);
	}

	onMoveEnd(movedItem, group, initialGroup, dragData) {
		EventManager.onDistributionItemMoveEnd(
			this.pageComponent,
			this,
			DISTRIBUTION_TYPE.PHASE,
			group,
			initialGroup,
			dragData
		);
	}

	onMoveAttempt(item) {
		if (!hasViewOnlyAccess(this.pageComponent, item.data.projectId, null)) {
			const project = DataManager.getProjectById(this.pageComponent, item.data.projectId);
			if (['DONE', 'HALTED'].includes(project.status)) {
				EventManager.onDoneOrHaltedMoveAttempt(this.pageComponent);
			}
		}
	}

	onMouseMove(eventData, event) {
		const {clientX, clientY} = event;

		this.pageComponent.setState({
			hoverX: clientX,
			hoverY: clientY,
			showDistributionBox: false,
		});

		setTimeout(() => {
			const {hoverX, hoverY, distributionBoxData} = this.pageComponent.state;
			if (hoverX === clientX && hoverY === clientY && distributionBoxData) {
				this.pageComponent.setState({showDistributionBox: true, distributionBoxX: clientX});
			}
		}, TOOLTIP_DISPLAY_DELAY_MS);
	}

	onClick() {
		const {sharedContext} = this.pageComponent.props;
		const {phase} = this.data;
		const project = DataManager.getProjectById(this.pageComponent, phase.projectId);

		if (!project.isInProjectGroup && !sharedContext) {
			EventManager.onDistributionItemClick(this.pageComponent, this, project.id, phase.id);
		}
	}

	draw(canvasContext, x, y, width, canvasWidth) {
		const {data, height} = this;
		const {color, faded, phaseName} = data;
		const modifiedProjectColor = color.toLowerCase() === '#e6e6ed' ? TIMELINE_GREY_TASK_BAR_COLOR : color;

		const completion = data.completion < 0 ? 0 : Math.round(data.completion);
		const textColor = getTextColor(modifiedProjectColor);
		const phaseColor = Color(modifiedProjectColor);
		const minimumTrim = 45;
		const middlePadding = 20;
		const phaseNameFont = '600 11px ' + Util.getFontFamily();
		const phaseCompletionFont = '600 11px ' + Util.getFontFamily();

		// Visible item stats
		const visibleRightX = x + width > canvasWidth ? canvasWidth : x + width;
		const visibleLeftX = x > 0 ? x : 0;
		const visibleItemWidth = visibleRightX - visibleLeftX;

		// Phase Name
		canvasContext.font = phaseNameFont;
		const phaseNameTextWidth = canvasContext.measureText(phaseName).width;

		// Completion
		const completionString = data.completion ? `${completion}%` : '';
		const completionStringWidth = canvasContext.measureText(completionString).width;

		const textTrim = visibleItemWidth - TIMELINE_BAR_PADDING_X * 2;
		const rightSize = completionStringWidth + TIMELINE_BAR_PADDING_X * 2;

		const showLeft = textTrim > minimumTrim;
		const showRight = visibleItemWidth - (rightSize + middlePadding) > phaseNameTextWidth;

		//Draw background
		const options = {
			backgroundColor: phaseColor,
			backgroundOpacity: faded ? 0.2 : completion === 100 ? 1 : 0.7,
			borderRadius: TIMELINE_BAR_BORDER_RADIUS,
		};
		drawRectangle(canvasContext, x, y, width, height, options);

		//Draw progress bar if needed
		if (completion !== 0 && completion !== 100) {
			//The last path drawn is the shape of item, use that as a clipping path to draw progress
			canvasContext.save();
			canvasContext.clip();

			//draw completion rectangle
			const completionRecOptions = {
				backgroundColor: phaseColor,
				backgroundOpacity: faded ? 0.2 : 1,
			};
			const completionWidth = (width * completion) / 100;
			drawRectangle(canvasContext, x, y, completionWidth, height, completionRecOptions);
			canvasContext.restore();
		}

		if (!showLeft && !showRight) {
			return;
		}

		if (showLeft) {
			//Phase Name
			canvasContext.fillStyle = textColor;
			canvasContext.font = phaseNameFont;
			canvasContext.fillText(
				getTrimmedText(canvasContext, phaseName, textTrim),
				visibleLeftX + TIMELINE_BAR_PADDING_X,
				y + 21
			);
		}

		if (showRight) {
			// Completion
			canvasContext.fillStyle = textColor;
			canvasContext.font = phaseCompletionFont;
			canvasContext.fillText(completionString, visibleRightX - TIMELINE_BAR_PADDING_X - completionStringWidth, y + 21);
		}
	}
}
export default PhaseItem;
