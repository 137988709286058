import React, {Component} from 'react';
import PropTypes from 'prop-types';
import GenericModal from '../../../containers/modal/generic_modal';
import {BUTTON_COLOR} from '../../../constants';
import {injectIntl} from 'react-intl';
import Input from '../../../components/new-ui/input_field';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import DateRangePicker from '../../../components/new-ui/date-controls/date_range_picker';
import Util from '../../../forecast-app/shared/util/util';
import {createPhase, createTask} from '../../scheduling/scheduling_mutations';
import AssignedDropdown from '../../../forecast-app/shared/components/dropdowns/assigned-dropdown/assigned_dropdown';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {AffixedInputWrapper} from '../../../forecast-app/shared/components/inputs/AffixedInputWrapper';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import AllocationProjectDropdown from '../components/allocation_project_dropdown';
import {isDateDisabled} from '../../../forecast-app/shared/util/DateUtil';

class SchedulingCreateNewModal extends Component {
	constructor(props) {
		super(props);
		const {startDate, endDate, selectedRole, rateCards} = props;

		const assignablePersons = this.getAssignablePersons();

		this.disabledRolesByRateCardId = new Map();
		if (rateCards) {
			rateCards.forEach(rateCard => {
				this.disabledRolesByRateCardId.set(rateCard.id, rateCard.disabledRoles);
			});
		}

		this.state = {
			newEntityName: '',
			estimateForecast: 0,
			startDate,
			endDate,
			isPhaseTabSelected:
				props.isProjectScheduling && props.isPhaseTabSelected && hasPermission(PERMISSION_TYPE.PHASE_CREATE),
			assignedPersons: props.selectedPersonId
				? [
						assignablePersons.find(projectPerson => {
							return projectPerson.node.person.id === props.selectedPersonId;
						}).node.person,
				  ]
				: [],
			assignedRole: selectedRole,
			selectedProjectId: props.selectedProjectId || null,
			selectedPhaseId: props.selectedPhaseId || null,
			assignableRoles: this.getAssignableRoles(props.selectedProjectId || null),
			assignablePersons,
			phaseOptionsMap: this.getPhaseOptions(),
			projectPersonOptionsMap: this.getProjectPersonOptions(),
		};
	}

	getAssignableRoles(projectId) {
		if (projectId) {
			const project = this.props.projects.find(project => project.id === projectId);
			const rateCardId = project.rateCardId;
			const roles = this.props.roles.filter(
				role =>
					!this.disabledRolesByRateCardId.has(rateCardId) ||
					!this.disabledRolesByRateCardId.get(rateCardId).includes(role.id)
			);
			return roles.map(role => ({node: {name: role.name, id: role.id}}));
		} else {
			return this.props.roles.map(role => ({node: {name: role.name, id: role.id}}));
		}
	}

	getAssignablePersons() {
		let personDropdownOptions = [];
		for (const person of this.props.persons) {
			if (!person.active || person.clientId !== undefined) continue;
			const role = this.props.roles.find(role => person.roleId === role.id);
			personDropdownOptions.push({
				node: {
					person,
					role,
				},
			});
		}
		return personDropdownOptions;
	}

	getPhaseOptions() {
		const projects = this.props.projects;
		let phaseDropdownOptionsMap = new Map();

		for (const project of projects) {
			const phaseOptions = [];
			phaseOptions.push({value: null, label: this.props.intl.formatMessage({id: 'project_scopes.no-scope'})});
			const phases = this.props.phases;
			for (const phase of phases) {
				if (phase.projectId === project.id) {
					phaseOptions.push({value: phase.id, label: phase.name});
				}
			}
			phaseDropdownOptionsMap.set(project.id, phaseOptions);
		}
		return phaseDropdownOptionsMap;
	}

	getProjectPersonOptions() {
		const projects = this.props.projects;
		let projectPersonDropdownMap = new Map();

		for (const project of projects) {
			const projectPersons = this.props.projectPersons.filter(projectPerson => projectPerson.projectId === project.id);
			const personOptions = [];
			for (const projectPerson of projectPersons) {
				const person = this.props.persons.find(person => person.id === projectPerson.personId);
				if (!person || !person.active || person.clientId !== undefined) continue;
				const role = this.props.roles.find(role => role.id === projectPerson.roleId);
				personOptions.push({node: {person, role}});
			}
			projectPersonDropdownMap.set(project.id, personOptions);
		}
		return projectPersonDropdownMap;
	}

	handleNameChange(newEntityName) {
		this.setState({newEntityName});
	}

	handleEstimateForecastChange(estimateForecast) {
		this.setState({estimateForecast});
	}

	handleDateRangeChange(startDate, endDate) {
		this.setState({startDate, endDate});
	}

	createTask() {
		const {
			newEntityName,
			estimateForecast,
			startDate,
			endDate,
			isPhaseTabSelected,
			assignedRole,
			assignedPersons,
			selectedProjectId,
			selectedPhaseId,
		} = this.state;
		const {estimationUnit} = this.props.projects.find(project => project.id === selectedProjectId);

		const onSuccess = response => {
			Util.dispatchScheduleEvent(response);
		};

		const mutationObject = {
			projectId: selectedProjectId,
			name: newEntityName,
			deadlineDay: endDate.date(),
			deadlineMonth: endDate.month() + 1,
			deadlineYear: endDate.year(),
			startDay: startDate.date(),
			startMonth: startDate.month() + 1,
			startYear: startDate.year(),
			csrfToken: Util.getCsrfValue(),
			socketClientId: Util.getClientId(),
		};

		if (isPhaseTabSelected) {
			createPhase(mutationObject, onSuccess);
		} else {
			const estimate =
				estimationUnit === 'HOURS'
					? Util.getHourEstimate(estimateForecast * 60)
					: Math.round(parseFloat(estimateForecast * 100)) / 100;
			mutationObject.forecast = estimate;
			mutationObject.phaseId = selectedPhaseId;
			mutationObject.roleId = assignedRole ? assignedRole.id : null;
			mutationObject.assignedPersons = assignedPersons.map(person => person.id);

			createTask(mutationObject, onSuccess);
		}
		this.props.closeModal();
	}

	onNameFieldEnter() {
		if (!this.state.newEntityName.trim()) return;
		this.createTask();
	}

	assignPerson(personIds) {
		this.setState({
			assignedPersons: personIds.map(
				personId =>
					this.state.assignablePersons.find(projectPerson => {
						return projectPerson.node.person.id === personId;
					}).node.person
			),
		});
	}

	unassignPerson(personId) {
		this.setState({assignedPersons: this.state.assignedPersons.filter(person => person.id !== personId)});
	}

	assignRole(roleId) {
		this.setState({
			assignedRole: roleId ? this.state.assignableRoles.find(roleEdge => roleEdge.node.id === roleId).node : null,
		});
	}

	handleProjectDropdownChange(selectedId) {
		const assignableRoles = this.getAssignableRoles(selectedId);
		this.setState({
			selectedProjectId: selectedId,
			selectedPhaseId: null,
			assignableRoles: assignableRoles,
			assignedRole:
				this.state.assignedRole && !assignableRoles.some(role => role.node.id === this.state.assignedRole.id)
					? null
					: this.state.assignedRole,
		});
	}

	handlePhaseDropdownChange(option) {
		this.setState({selectedPhaseId: option.value});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {isProjectScheduling} = this.props;
		const {
			projectPersonOptionsMap,
			phaseOptionsMap,
			assignablePersons,
			assignableRoles,
			newEntityName,
			estimateForecast,
			startDate,
			endDate,
			isPhaseTabSelected,
			assignedPersons,
			assignedRole,
			selectedProjectId,
			selectedPhaseId,
		} = this.state;

		const selectedProject = this.props.projects.find(project => project.id === selectedProjectId);
		const estimationUnit = selectedProject ? selectedProject.estimationUnit : null;

		const content = (
			<div className="scheduling-create-new-modal-content">
				{isProjectScheduling ? (
					<div className="tabs-container">
						<div
							className={'tab ' + (isPhaseTabSelected ? '' : 'selected')}
							onClick={() => {
								this.setState({isPhaseTabSelected: false});
							}}
						>
							{formatMessage({id: 'common.task'})}
						</div>
						<div
							className={'tab ' + (isPhaseTabSelected ? 'selected' : '')}
							onClick={
								!hasPermission(PERMISSION_TYPE.PHASE_CREATE)
									? undefined
									: () => {
											this.setState({isPhaseTabSelected: true});
									  }
							}
						>
							{formatMessage({id: 'common.scope-group'})}
						</div>
					</div>
				) : null}
				<AllocationProjectDropdown
					label={formatMessage({id: 'common.project'})}
					projects={this.props.projects}
					projectPersons={this.props.projectPersons}
					selectedValue={selectedProjectId}
					onChange={this.handleProjectDropdownChange.bind(this)}
					actualPersonId={this.props.viewerId}
					includeChildProjects
					sortAlphabetically
				/>
				<div className="dates-phase-row">
					{isPhaseTabSelected ? null : (
						<Dropdown
							onChange={this.handlePhaseDropdownChange.bind(this)}
							options={phaseOptionsMap ? phaseOptionsMap.get(selectedProjectId) || [] : []}
							value={selectedPhaseId || null}
							label={formatMessage({id: 'common.scope-group'})}
							placeholder=""
							disabled={!selectedProjectId}
						/>
					)}
					<DateRangePicker
						startDate={startDate}
						endDate={endDate}
						handleDateRangeChange={this.handleDateRangeChange.bind(this)}
						label={formatMessage({id: 'common.dates'})}
						disabledDates={isDateDisabled}
					/>
				</div>
				<Input
					id="scheduling-create-new-entity-name"
					placeholder={
						isPhaseTabSelected
							? formatMessage({id: 'scheduling.phase_name'})
							: formatMessage({id: 'common.task_name'})
					}
					type="text"
					label={isPhaseTabSelected ? formatMessage({id: 'common.scope-group'}) : formatMessage({id: 'common.task'})}
					value={newEntityName}
					onChange={this.handleNameChange.bind(this)}
					autoFocus={true}
					onEnter={this.onNameFieldEnter.bind(this)}
					required={true}
					maxLength={191}
				/>
				{isPhaseTabSelected ? null : (
					<div className="task-data-row">
						<div className="person-dropdown-container">
							<AssignedDropdown
								assignablePersons={
									!hasPermission(PERMISSION_TYPE.PROJECT_PERSON_CREATE)
										? selectedProjectId
											? projectPersonOptionsMap.get(selectedProjectId) || []
											: []
										: assignablePersons
								}
								assignableRoles={assignableRoles}
								assignedRole={assignedRole}
								assignedPersons={assignedPersons}
								assignPerson={this.assignPerson.bind(this)}
								assignRole={this.assignRole.bind(this)}
								unassignPerson={this.unassignPerson.bind(this)}
								isMultiSelect={true}
								label={formatMessage({id: 'common.assigned'})}
								disabled={!hasPermission(PERMISSION_TYPE.PROJECT_PERSON_CREATE) && !selectedProjectId}
								projectId={selectedProjectId}
								showSuggestions={false}
							/>
						</div>
						{estimationUnit === 'POINTS' ? (
							<AffixedInputWrapper
								label={formatMessage({id: 'common.estimate'})}
								modalView={true}
								value={estimateForecast}
								callback={value => this.handleEstimateForecastChange(value)}
								affix={formatMessage({id: 'common.points.short'})}
							/>
						) : (
							<HoursInput
								placeholder="0"
								label={formatMessage({id: 'common.estimate'})}
								value={estimateForecast}
								mutation={value => this.handleEstimateForecastChange(value)}
								modalView={true}
								onClick={e => e.focus()}
								noMaxWidth={true}
							></HoursInput>
						)}
					</div>
				)}
			</div>
		);
		return (
			<GenericModal
				className="scheduling-create-new-modal"
				closeModal={this.props.closeModal}
				buttons={[
					{text: formatMessage({id: 'common.cancel'}), color: BUTTON_COLOR.WHITE},
					{
						text: formatMessage({id: 'common.create'}),
						disabled: !newEntityName.trim() || !selectedProject,
						callback: this.createTask.bind(this),
					},
				]}
				content={content}
				headerText={
					isProjectScheduling
						? formatMessage({id: 'scheduling.create_new'})
						: formatMessage({id: 'scheduling.create_task'})
				}
			/>
		);
	}
}

SchedulingCreateNewModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
};

export default injectIntl(SchedulingCreateNewModal);
