import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import CurrencyInput from '../../components/inputs/currency_input';
import ActionsMenu from '../../forecast-app/shared/components/action-menu/actions_menu';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import DeleteCostPeriodMutation from '../../mutations/delete_cost_period_mutation';
import UpdateCostPeriodMutationModern from '../../mutations/update_cost_period_mutation.modern';
import FixedRangePicker from '../../components/new-ui/date-controls/fixed_range_picker';
import Util from '../../forecast-app/shared/util/util';
import {dispatch, EVENT_ID} from '../../containers/event_manager';

class CostPeriod extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cost: this.props.period.cost,
			showActionMenu: false,
		};
	}

	updateDateRange(date) {
		if (date) {
			const startDay = date.date();
			const startMonth = date.month() + 1;
			const startYear = date.year();

			// Mutation
			Util.CommitMutation(
				UpdateCostPeriodMutationModern,
				{id: this.props.period.id, startDay, startMonth, startYear},
				() => {
					createToast({duration: 2000, message: this.props.intl.formatMessage({id: 'common.changes_saved'})});
					this.updateProject();
				}
			);
		}
	}

	updateCost() {
		const {cost} = this.state;
		const isCostValid = Util.isNumber(cost);

		if (isCostValid) {
			Util.CommitMutation(UpdateCostPeriodMutationModern, {id: this.props.period.id, cost}, () => {
				createToast({duration: 2000, message: this.props.intl.formatMessage({id: 'common.changes_saved'})});
				this.updateProject();
			});
		}
	}

	updateProject() {
		dispatch(EVENT_ID.BUDGET_UPDATE_PROJECT, {noToast: true});
	}

	deleteCostPeriod() {
		Util.CommitMutation(
			DeleteCostPeriodMutation,
			{
				id: this.props.period.id,
				personId: this.props.person.id,
			},
			() => this.updateProject()
		);
	}

	handleCostChange(cost) {
		this.setState({cost});
	}

	handleMouseEnter() {
		if (!this.state.showActionMenu) {
			this.setState({showActionMenu: true});
		}
	}

	handleMouseLeave() {
		if (this.state.showActionMenu) {
			this.setState({showActionMenu: false});
		}
	}

	getActionsMenuOptions() {
		const {formatMessage} = this.props.intl;
		return [{text: formatMessage({id: 'common.delete'}), onClick: this.deleteCostPeriod.bind(this)}];
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {periods, period, index, currentCostPeriod, viewer} = this.props;
		const {showActionMenu, cost} = this.state;
		const currencySymbol = Util.GetCurrencySymbol(viewer.company.currency);
		const isDollarOrEuro = Util.CurrencyIsPrefixed(currencySymbol);

		const periodsStartDates = periods.map(period => period.startDate);
		return (
			<div
				className={'cost-period' + (currentCostPeriod && currentCostPeriod.node.id === period.id ? ' active' : '')}
				onMouseEnter={this.handleMouseEnter.bind(this)}
				onMouseLeave={this.handleMouseLeave.bind(this)}
			>
				<div className="start-date date">
					{period.startDate ? (
						<FixedRangePicker
							customDateFormat={'D. MMM YYYY'}
							disabledDates={periodsStartDates}
							showAsText={true}
							usePropsDate={true}
							useCompactStyle={true}
							calendarOffsetX={0}
							hideLabel={true}
							startDate={period.startDate.clone()}
							locale={this.props.locale}
							handleDateRangeChange={val => this.updateDateRange(val)}
							cy={'periods-start-date-picker'}
						/>
					) : (
						<div className="no-start-date">{formatMessage({id: 'cost_period.initial'})}</div>
					)}
				</div>
				<div className="line">-</div>
				<div className="end-date date">
					{periods.length > 1 && index !== periods.length - 1 && periods[index + 1] ? (
						<FixedRangePicker
							customDateFormat={'D. MMM YYYY'}
							showAsText={true}
							usePropsDate={true}
							useCompactStyle={true}
							calendarOffsetX={0}
							hideLabel={true}
							startDate={periods[index + 1].startDate.clone().subtract(1, 'days')}
							locale={this.props.locale}
							handleDateRangeChange={val => this.updateDateRange(val)}
							locked={true}
							cy={'periods-end-date-picker'}
						/>
					) : (
						<div className="no-end-date">{formatMessage({id: 'cost_period.future'})}</div>
					)}
				</div>
				<div className="internal-cost">
					<CurrencyInput
						value={cost}
						showCurrencyOnLeft={isDollarOrEuro}
						onBlur={this.updateCost.bind(this)}
						onChange={this.handleCostChange.bind(this)}
						placeholder={'0'}
						currency={viewer.company.currency}
						allowDecimals={true}
						blurOnEnter={true}
						sendValueOnBlur={true}
						dataCy={'periods-cost-input'}
					/>
				</div>
				<div className="action-menu">
					{/* Don't show the action menu on the first cost period*/}
					{index === 0 ? null : (
						<ActionsMenu
							customClass={showActionMenu ? '' : 'hidden'}
							customWidth={20}
							whiteInner={true}
							isWhite={false}
							options={this.getActionsMenuOptions()}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default injectIntl(CostPeriod);
