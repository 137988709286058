import {cloneDeep} from 'lodash';
import {TIME_REG_GROUPINGS} from './invoicing_generate_lines';
import {
	addQuantityDecimals,
	convertPriceToInvoiceCurrency,
	formatDate,
	groupByProperties,
	visitGroups,
} from './invoicing_generate_lines_utils';
import Util from '../../../../forecast-app/shared/util/util';
import {BUDGET_TYPE, PERIOD_BUDGET_TYPE, WorkflowCategories} from '../../../../constants';

const getAllTimeRegs = (config, unInvoicedTimeRegistrations, projects, company, projectPersonsMap, intl) => {
	const timeRegs = [];

	const {formatMessage} = intl;

	const personMap = company.allPersons.edges
		.map(edge => edge.node)
		.reduce((map, origPerson) => {
			const person = cloneDeep(origPerson);
			person.cachedProjectRoles = {};
			map[person.id] = person;
			return map;
		}, {});

	const projectMap = projects.reduce((map, project) => {
		map[project.id] = project;
		return map;
	}, {});

	const groupingFiltering = timeReg => {
		if (config.timeRegGrouping === TIME_REG_GROUPINGS.DONE_TASK) {
			return timeReg.task?.statusColumnV2?.category === WorkflowCategories.DONE;
		} else {
			return true;
		}
	};

	unInvoicedTimeRegistrations
		.filter(timeReg => {
			const projectId =
				timeReg.project && timeReg.project.id
					? timeReg.project.id
					: timeReg.task && timeReg.task.project && timeReg.task.project.id
					? timeReg.task.project.id
					: null;
			const project = projects.find(project => project.id === projectId);
			if (project && project.budgetType === BUDGET_TYPE.TIME_AND_MATERIALS) {
				const timeRegDate = Util.CreateNonUtcMomentDate(timeReg.year, timeReg.month, timeReg.day);

				const greaterOrEqualToStartDate = !config.startDate || !timeRegDate.isBefore(config.startDate, 'days');
				const lessOrEqualToEndDate = !config.endDate || !timeRegDate.isAfter(config.endDate, 'days');
				return greaterOrEqualToStartDate && lessOrEqualToEndDate;
			} else {
				return true;
			}
		})
		.filter(groupingFiltering)
		.filter(timeReg => {
			const projectId =
				timeReg.project && timeReg.project.id
					? timeReg.project.id
					: timeReg.task && timeReg.task.project && timeReg.task.project.id
					? timeReg.task.project.id
					: null;
			return config.selectedProjectsIds.indexOf(projectId) > -1;
		})
		.forEach(origTimeReg => {
			const timeReg = cloneDeep(origTimeReg);

			// Check if timeReg is in a retainer period
			let skipTimeReg = false;
			const projectId =
				timeReg.project && timeReg.project.id
					? timeReg.project.id
					: timeReg.task && timeReg.task.project && timeReg.task.project.id
					? timeReg.task.project.id
					: null;
			const project = projects.find(project => project.id === projectId);
			if (project && project.budgetType === BUDGET_TYPE.RETAINER) {
				const periods = project.retainerPeriods.edges;
				for (let i = 0; i < periods.length; i++) {
					const {startDay, startMonth, startYear, endDay, endMonth, endYear} = periods[i].node;
					const periodStartDate = Util.CreateMomentDate(startYear, startMonth, startDay);
					const periodEndDate = Util.CreateMomentDate(endYear, endMonth, endDay);
					const timeRegDate = Util.CreateMomentDate(timeReg.year, timeReg.month, timeReg.day);
					if (timeRegDate >= periodStartDate && timeRegDate <= periodEndDate) {
						if (periods[i].node.periodBudgetType !== PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS) {
							skipTimeReg = true;
						} else {
							// Adds period id because no invoice line is created for time & material periods
							timeReg.periodId = periods[i].node.id;
						}
					}
				}
			}

			if (!skipTimeReg) {
				const person = personMap[timeReg.person.id];
				timeReg.personId = timeReg.person.id;
				timeReg.personName = `${person.firstName} ${person.lastName}`;
				if (timeReg.project && timeReg.project.id) {
					timeReg.projectId = timeReg.project.id;
				}
				if (timeReg.task && timeReg.task.id) {
					timeReg.taskId = timeReg.task.id;
					timeReg.taskName = timeReg.task.name;

					if (timeReg.task.project && timeReg.task.project.id) {
						timeReg.projectId = timeReg.task.project.id;
					}
					if (timeReg.task.phase && timeReg.task.phase.id) {
						timeReg.phaseId = timeReg.task.phase.id;
						timeReg.phaseName = timeReg.task.phase.name;
					} else {
						timeReg.phaseId = 'no-phase';
						timeReg.phaseName = formatMessage({id: 'card_modal.no-scope'});
					}
					if (timeReg.task.sprint && timeReg.task.sprint.id) {
						timeReg.sprintId = timeReg.task.sprint.id;
						timeReg.sprintName = timeReg.task.sprint.name;
					} else {
						timeReg.sprintId = 'no-sprint';
						timeReg.sprintName = formatMessage({id: 'overview_upcoming.no_sprint'});
					}
				} else {
					timeReg.taskId = 'no-task';
					timeReg.taskName = formatMessage({id: 'invoicing.no_task'});
					timeReg.phaseId = 'no-phase';
					timeReg.phaseName = formatMessage({id: 'card_modal.no-scope'});
					timeReg.sprintId = 'no-sprint';
					timeReg.sprintName = formatMessage({id: 'overview_upcoming.no_sprint'});
				}
				if (timeReg.projectId) {
					const project = projectMap[timeReg.projectId];
					if (project) {
						timeReg.projectName = project.name;
						if (project.rateCard) {
							timeReg.currency = project.rateCard.currency;
						}
					}

					const role = timeReg.role;
					timeReg.roleId = role ? role.id : 'no-role';
					timeReg.roleName = role ? role.name : formatMessage({id: 'team.no-role-group-title'});
				}
				if (!timeReg.currency) {
					timeReg.currency = company.currency;
				}
				timeRegs.push(timeReg);
			}
		});
	return timeRegs;
};

const getGroupByPropertiesForConfig = config => {
	switch (config.timeRegGrouping) {
		case TIME_REG_GROUPINGS.NO_GROUPING:
			return ['id'];
		case TIME_REG_GROUPINGS.PROJECT:
			return ['projectId'];
		case TIME_REG_GROUPINGS.PERSON:
			return ['projectId', 'personId'];
		case TIME_REG_GROUPINGS.ROLE:
			return ['projectId', 'roleId'];
		case TIME_REG_GROUPINGS.PHASE:
			return ['projectId', 'phaseId'];
		case TIME_REG_GROUPINGS.SPRINTS:
			return ['projectId', 'sprintId'];
		case TIME_REG_GROUPINGS.TASK:
		case TIME_REG_GROUPINGS.DONE_TASK:
			return ['projectId', 'taskId'];
		case TIME_REG_GROUPINGS.NO_GROUPING_NOTES:
			return ['id'];
		default:
			throw new Error(`Unsupported time-reg grouping ${config.timeRegGrouping}`);
	}
};

const createDescription = (timeRegs, config, intl) => {
	const firstTimeReg = timeRegs[0];

	let firstDate = Util.CreateNonUtcMomentDate(firstTimeReg.year, firstTimeReg.month, firstTimeReg.day).startOf('day'),
		lastDate = Util.CreateNonUtcMomentDate(firstTimeReg.year, firstTimeReg.month, firstTimeReg.day).startOf('day');
	timeRegs.forEach(timeReg => {
		const date = Util.CreateNonUtcMomentDate(timeReg.year, timeReg.month, timeReg.day).startOf('day');
		if (date.isBefore(firstDate)) {
			firstDate = date;
		}
		if (date.isAfter(lastDate)) {
			lastDate = date;
		}
	});

	let dates = `${formatDate(intl, firstDate)}`;
	if (lastDate.isAfter(firstDate)) {
		dates = `${dates} - ${formatDate(intl, lastDate)}`;
	}

	switch (config.timeRegGrouping) {
		case TIME_REG_GROUPINGS.NO_GROUPING:
			return `${firstTimeReg.roleName}, ${firstTimeReg.personName} (${dates})`;
		case TIME_REG_GROUPINGS.PROJECT:
			return dates;
		case TIME_REG_GROUPINGS.PERSON:
			return `${firstTimeReg.personName} (${dates})`;
		case TIME_REG_GROUPINGS.ROLE:
			return `${firstTimeReg.roleName} (${dates})`;
		case TIME_REG_GROUPINGS.PHASE:
			return `${firstTimeReg.phaseName} (${dates})`;
		case TIME_REG_GROUPINGS.SPRINTS:
			return `${firstTimeReg.sprintName} (${dates})`;
		case TIME_REG_GROUPINGS.TASK:
		case TIME_REG_GROUPINGS.DONE_TASK:
			return `${firstTimeReg.taskName} (${dates})`;
		case TIME_REG_GROUPINGS.NO_GROUPING_NOTES:
			let description = `${firstTimeReg.roleName}, ${firstTimeReg.personName} (${dates})`;
			if (firstTimeReg.notes) {
				description += '\n\nNote: ' + firstTimeReg.notes;
			}
			return description;
		default:
			throw new Error(`Unsupported time-reg grouping ${config.timeRegGrouping}`);
	}
};

const createQuantityAndUnitPrice = (timeRegs, company, invoice) => {
	const quantityDecimals = company.invoiceQuantityDecimals || 2;

	const getTimeRegHours = timeReg => {
		let timeRegHours = timeReg.billableMinutesRegistered / 60;
		const timeRegDate = Util.CreateNonUtcMomentDate(timeReg.year, timeReg.month, timeReg.day);

		/**************************
		 *
		 * Please remove this date check if you're in the year 2024 or later
		 *
		 * See the comment in service-company TimeRegLogic.getTimeRegPrice for more info and also remove the same date check there
		 *
		 *************************/
		if (timeRegDate.isAfter(Util.CreateMomentDate(2022, 4, 1))) {
			timeRegHours = Util.roundToNDecimals(timeRegHours, quantityDecimals);
		}
		return timeRegHours;
	};

	const firstTimeReg = timeRegs[0];
	const totalQuantity = timeRegs.reduce((totalQuantity, timeReg) => totalQuantity + getTimeRegHours(timeReg), 0);
	const totalUnitPrice = timeRegs.reduce(
		(totalUnitPrice, timeReg) =>
			totalUnitPrice + (totalQuantity ? getTimeRegHours(timeReg) / totalQuantity : 0) * timeReg.rate,
		0
	);
	const convertedUnitPrice = convertPriceToInvoiceCurrency(totalUnitPrice, firstTimeReg.currency, company, invoice);

	return {
		quantity: totalQuantity,
		unitPrice: convertedUnitPrice,
	};
};

export const generateLinesForTimeRegs = (
	config,
	intl,
	invoice,
	unInvoicedTimeRegistrations,
	projects,
	company,
	projectPersonsMap
) => {
	const timeRegs = getAllTimeRegs(config, unInvoicedTimeRegistrations, projects, company, projectPersonsMap, intl);
	const groupByPropertyList = getGroupByPropertiesForConfig(config);
	const groupedTimeRegs = groupByProperties(timeRegs, groupByPropertyList);

	const invoiceLines = [];

	visitGroups(groupedTimeRegs, timeRegs => {
		const description = createDescription(timeRegs, config, intl);
		const {quantity, unitPrice} = createQuantityAndUnitPrice(timeRegs, company, invoice);
		const projectId = timeRegs[0].projectId;
		const tax = invoice.tax;
		const discount = invoice.discount;
		const timeRegIds = timeRegs.map(timeReg => timeReg.id);

		const periodIdMap = {};
		timeRegs.forEach(timeReg => {
			if (timeReg.periodId) {
				periodIdMap[timeReg.periodId] = timeReg.id;
			}
		});
		const periodIds = Object.keys(periodIdMap);

		invoiceLines.push({
			description,
			quantity: addQuantityDecimals(quantity, company),
			projectId,
			tax,
			discount,
			unitPrice,
			timeRegIds,
			periodIds,
		});
	});

	return invoiceLines;
};
