import React, {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {KebabMenuIcon, Table} from 'web-components';
import Util from '../../../../forecast-app/shared/util/util';
import DeprecatedProjectIndicatorJS from '../../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

export const TimeMaterialTable = ({theEyeOptions, projectData, companyCurrency, onContextMenuClick, hasFirstRow}) => {
	const intl = useIntl();

	const headerData = {
		rows: [
			{
				totalUninvoicedMinutes: projectData.totalUninvoicedMinutes,
				totalUninvoicedPrice: projectData.totalUninvoicedPrice,
				totalUninvoicedExpenses: projectData.totalUninvoicedExpenses,
				totalInvoicedAmount: projectData.totalInvoicedAmount,
			},
		],
	};

	const tableData = {
		rows: projectData.projects.map(project => ({
			uninvoicedMinutes: project.uninvoicedMinutes,
			uninvoicedPrice: project.uninvoicedPrice,
			uninvoicedExpenses: project.uninvoicedExpenses,
			invoicedAmount: project.invoicedAmount,
			projectId: project.id,
			companyProjectId: project.companyProjectId,
			projectName: project.name,
			projectColor: project.projectColor,
			projectGroupId: project.companyProjectGroupId,
			clientName: project.client ? project.client.name : '',
			clientId: project.client ? project.client.id : null,
			currency: project.currency,
			name: project.name,
			relayProject: project.relayProject,
		})),
	};
	const boolEyeOptions = useMemo(() => {
		const bools = {};
		theEyeOptions.forEach(eyeOption => {
			bools[eyeOption.name] = eyeOption.checked;
			if (eyeOption.nestedOptions) {
				eyeOption.nestedOptions.forEach(nestedOption => {
					bools[nestedOption.name] = nestedOption.checked;
				});
			}
		});
		return bools;
	}, [theEyeOptions]);

	return (
		<Table cy={'time-material-table'}>
			<Table.Header>
				<Table.HeaderColumn align={'left'} visible={boolEyeOptions.project} flex={1} width={'LARGE'}>
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.project'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align={'left'} visible={boolEyeOptions.client} width={'MEDIUM'}>
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.client'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn
					usePadding
					align={'right'}
					visible={boolEyeOptions.uninvoiced_time_registrations}
					width={'MEDIUM'}
				>
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'common.uninvoiced_time_registrations'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn
					usePadding
					align={'right'}
					visible={boolEyeOptions.uninvoiced_time_registrations}
					width={'MEDIUM'}
				>
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'common.uninvoiced_time_registrations'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align={'right'} visible={boolEyeOptions.uninvoiced_expenses} width={'LARGE'}>
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'common.uninvoiced_expenses'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn
					usePadding
					align={'right'}
					visible={boolEyeOptions.total_uninvoiced_time_material}
					width={'MEDIUM'}
				>
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.total_uninvoiced'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align={'right'} visible={boolEyeOptions.total_invoiced} width={'MEDIUM'}>
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.total_invoiced'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align={'center'} visible={true} width={'EXTRA_SMALL'}>
					{/* context  menu*/}
				</Table.HeaderColumn>
			</Table.Header>
			{/* HEADER COlUMN */}
			<Table.Rows data={headerData} canExpand={false} hasHeader>
				{({rowData, tableColumnsProps}) => {
					return (
						<Table.Row {...tableColumnsProps}>
							<Table.Column>{intl.formatMessage({id: 'common.totals'})}</Table.Column>
							<Table.Column></Table.Column>
							<Table.Column>{Util.convertMinutesToFullHour(rowData.totalUninvoicedMinutes, intl)}</Table.Column>
							<Table.Column>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(companyCurrency),
									rowData.totalUninvoicedPrice,
									intl
								)}
							</Table.Column>
							<Table.Column>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(companyCurrency),
									rowData.totalUninvoicedExpenses,
									intl
								)}
							</Table.Column>
							<Table.Column>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(companyCurrency),
									rowData.totalUninvoicedPrice + rowData.totalUninvoicedExpenses,
									intl
								)}
							</Table.Column>

							<Table.Column>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(companyCurrency),
									rowData.totalInvoicedAmount,
									intl
								)}
							</Table.Column>
							{/* room for context menu */}
							<Table.Column></Table.Column>
						</Table.Row>
					);
				}}
			</Table.Rows>
			<Table.Rows data={tableData} canExpand={false}>
				{({rowData, tableColumnsProps}) => {
					return (
						<Table.Row {...tableColumnsProps} cy={'time-material-row-' + tableColumnsProps.index}>
							<Table.Column>
								<DeprecatedProjectIndicatorJS project={rowData.relayProject} showProjectName={true} />
							</Table.Column>
							<Table.Column>{rowData.clientName}</Table.Column>
							<Table.Column>{Util.convertMinutesToFullHour(rowData.uninvoicedMinutes, intl)}</Table.Column>
							<Table.Column>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(rowData.currency),
									rowData.uninvoicedPrice,
									intl
								)}
							</Table.Column>
							<Table.Column>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(rowData.currency),
									rowData.uninvoicedExpenses,
									intl
								)}
							</Table.Column>
							<Table.Column>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(rowData.currency),
									rowData.uninvoicedExpenses + rowData.uninvoicedPrice,
									intl
								)}
							</Table.Column>
							<Table.Column>
								{Util.getFormattedNumberWithCurrency(
									Util.GetCurrencySymbol(rowData.currency),
									rowData.invoicedAmount,
									intl
								)}
							</Table.Column>
							<Table.Column>
								<KebabMenuIcon
									onClick={e => onContextMenuClick(e, rowData.clientId, rowData.projectId)}
									cy={'time-material-row-' + tableColumnsProps.index + '-context-menu'}
									userpilot={
										hasFirstRow && tableColumnsProps.index === 0 ? 'context-menu-wrapper-overview' : null
									}
								/>
							</Table.Column>
						</Table.Row>
					);
				}}
			</Table.Rows>
		</Table>
	);
};
