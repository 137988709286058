import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, SingleText} from 'web-components';

export const InvoicedDropdown = ({
	options,
	dropdownAlignment,
	intl,
	width,
	name,
	optionsName,
	selectedItems,
	onSelect,
	onRemove,
	selectedGroupName,
	userpilot,
	showBulkActions,
	emphasizeEmptyState,
}) => {
	const sortedOptions = [...options].sort((a, b) => {
		if (a === null) return -1;
		if (b === null) return 1;
		if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
		if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
		return 0;
	});

	const getElements = () => {
		let elements = [];

		return elements.concat(
			sortedOptions.map(option => {
				return (
					<SingleText key={option.value} value={option.value}>
						{option.label}
					</SingleText>
				);
			})
		);
	};
	return (
		<Dropdown
			isNested
			isMultiSelect
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
		>
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{getElements()}
			</Dropdown.Group>
		</Dropdown>
	);
};

InvoicedDropdown.propTypes = {
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
};

InvoicedDropdown.defaultProps = {
	optionsName: 'Invoiced',
	name: 'Invoiced',
	selectedGroupName: 'Selected',
	onSelect: () => false,
	onRemove: () => false,
};

export default InvoicedDropdown;
