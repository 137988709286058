/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type TimeChart_project$ref = any;
type TimeTotals_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TimeBudgetData_project$ref: FragmentReference;
declare export opaque type TimeBudgetData_project$fragmentType: TimeBudgetData_project$ref;
export type TimeBudgetData_project = {|
  +$fragmentRefs: TimeChart_project$ref & TimeTotals_project$ref,
  +$refType: TimeBudgetData_project$ref,
|};
export type TimeBudgetData_project$data = TimeBudgetData_project;
export type TimeBudgetData_project$key = {
  +$data?: TimeBudgetData_project$data,
  +$fragmentRefs: TimeBudgetData_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeBudgetData_project",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeChart_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeTotals_project"
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = '1c8ccfd7d196c7469689906f137dc984';

module.exports = node;
