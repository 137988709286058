import {
	checkNeedsRecalculation,
	clearNeedsRecalculation,
	getGroupCache,
	getPersonHeatMapData,
	getPersonWorkingHoursAndMinutes,
	getStepCache,
	getVisibleItemsData,
	handlePersonGroupHeatmapClick,
	initializeHeatmapCache,
} from './HeatmapLogic';
import {
	getHolidayDates,
	getVisualizationMode,
	isAllocatedHoursNaN,
	ITEM_TYPE,
	VISUALIZATION_MODE,
	GROUP_TYPE,
	isGlobalRecalculationNeeded,
	getStepDataArrayStartAndEndDate,
	getPersonHeatmapMetaData,
} from '../canvas-timeline/canvas_timeline_util';
import {constructPersonGroupHeatmapItem} from './people-scheduling/HeatmapUtil';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import * as tracking from '../../../tracking';
import {trackPerformancePersonHeatmap} from '../canvas-timeline/canvas_timeline_performance_track';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {isStepHiddenBehindLoadMore} from '../loading/LoadMoreUtil';
import DataManager from '../DataManager';
import RecalculationManager from '../RecalculationManager';
import {getMinutesAllocatedVariation} from './MinutesAllocatedVariationsUtils';

const STEP_HIDDEN_HEATMAP_DATA = {
	minutesAllocated: 0,
	plannedTotalMinutesHard: 0,
	plannedTotalMinutesSoft: 0,
	plannedTotalMinutesSoftWin: 0,
	minutesAvailable: 480,
};

export const calculateCombinedModeHeatmapCache = (
	pageComponent,
	personGroup,
	stepDataArray,
	timelineMinorStep,
	setHasCalculated
) => {
	let hasCalculatedChildGroup = false;

	const setHasCalculatedSubGroup = () => {
		hasCalculatedChildGroup = true;
	};

	let calculationMetaDataCache = null;
	const getCalculationMetaData = (startDate, endDate) => {
		if (!calculationMetaDataCache) {
			calculationMetaDataCache = new Map();
		}

		let calculationMetaData = calculationMetaDataCache.get(startDate);
		if (!calculationMetaData) {
			calculationMetaData = getPersonHeatmapMetaData(pageComponent, personGroup, timelineMinorStep, startDate, endDate);
			calculationMetaDataCache.set(startDate, calculationMetaData);
		}

		return calculationMetaData;
	};

	// calculate all project group caches
	for (const childGroup of personGroup.groups) {
		if (childGroup.groupType === GROUP_TYPE.PROJECT) {
			childGroup.calculateHeatmapCache(
				childGroup,
				stepDataArray,
				timelineMinorStep,
				setHasCalculatedSubGroup,
				getCalculationMetaData
			);
		}
	}

	if (hasCalculatedChildGroup || RecalculationManager.groupNeedsRecalculation(pageComponent, personGroup.id)) {
		let visibleItemsData;
		const fetchVisibleItemsData = () => {
			const {overallStartDate, overallEndDate} = getStepDataArrayStartAndEndDate(stepDataArray);

			if (!visibleItemsData) {
				visibleItemsData = DataManager.getVisibleItemsData(
					pageComponent,
					personGroup,
					overallStartDate,
					overallEndDate
				);
			}

			return visibleItemsData;
		};

		let personCache;
		let stepCache;
		const initPersonGroupStepCache = () => {
			if (!stepCache) {
				personCache = getGroupCache(pageComponent, personGroup.id);
				stepCache = getStepCache(personCache, timelineMinorStep);
			}
		};

		// calculate person group cache
		for (const stepData of stepDataArray) {
			if (isStepHiddenBehindLoadMore(pageComponent, stepData)) {
				continue;
			}

			let {startDate, endDate, recalculationNeededInStep} = stepData;

			if (recalculationNeededInStep === false) {
				continue;
			}

			initPersonGroupStepCache();

			// calculate cache for person group
			getPersonHeatMapData(
				pageComponent,
				personGroup,
				startDate,
				endDate,
				timelineMinorStep,
				fetchVisibleItemsData,
				getCalculationMetaData,
				personCache,
				stepCache
			);
		}

		if (stepCache && setHasCalculated) {
			setHasCalculated();
		}

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			clearNeedsRecalculation(personCache, timelineMinorStep);
		}
	}
};

export const calculatePersonHeatmapCache = (
	pageComponent,
	group,
	stepDataArray,
	timelineMinorStep,
	itemTypes = null,
	visibleItemsPredicate = null,
	setHasCalculated
) => {
	if (isGlobalRecalculationNeeded(pageComponent)) {
		const {data, schedulingOptions} = pageComponent.state;

		const isInCombinedMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);
		if (isInCombinedMode) {
			calculateCombinedModeHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep, setHasCalculated);
			return;
		}

		const childGroupIds = new Set(group.groups?.map(childGroup => childGroup.id) || []);
		const personId = group.id;
		const defaultItemPredicate = item => childGroupIds.has(item.groupId);
		const defaultItemTypes = [ITEM_TYPE.PROJECT_ALLOCATION];

		const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.ALLOCATION);
		if (!isUsingProjectAllocation) {
			defaultItemTypes.push(ITEM_TYPE.TASK);
		}

		const {overallStartDate, overallEndDate} = getStepDataArrayStartAndEndDate(stepDataArray);
		let visibleItemsData;

		const fetchVisibleItemsData = () => {
			if (!visibleItemsData) {
				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					visibleItemsData = DataManager.getVisibleItemsData(pageComponent, group, overallStartDate, overallEndDate);
				} else {
					visibleItemsData = getVisibleItemsData(
						pageComponent,
						overallStartDate,
						overallEndDate,
						itemTypes || defaultItemTypes,
						visibleItemsPredicate ? visibleItemsPredicate : defaultItemPredicate
					);
				}
			}

			return visibleItemsData;
		};

		let calculationMetaDataCache = null;
		const getCalculationMetaData = (startDate, endDate) => {
			if (!calculationMetaDataCache) {
				calculationMetaDataCache = new Map();
			}

			let calculationMetaData = calculationMetaDataCache.get(startDate);
			if (!calculationMetaData) {
				calculationMetaData = getPersonHeatmapMetaData(pageComponent, group, timelineMinorStep, startDate, endDate);
				calculationMetaDataCache.set(startDate, calculationMetaData);
			}

			return calculationMetaData;
		};

		const groupCache = pageComponent.heatmapCache.get(personId);
		const perfStart = performance.now();
		if (!groupCache) {
			initializeHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep, fetchVisibleItemsData);
		}

		let stepWasHidden = false;
		const personCache = getGroupCache(pageComponent, group.id);
		const stepCache = getStepCache(personCache, timelineMinorStep);
		for (const stepData of stepDataArray) {
			if (isStepHiddenBehindLoadMore(pageComponent, stepData)) {
				stepWasHidden = true;
				continue;
			}

			let {startDate, endDate, recalculationNeededInStep} = stepData;

			if (recalculationNeededInStep === false) {
				continue;
			}

			// Update cache for step
			getPersonHeatMapData(
				pageComponent,
				group,
				startDate,
				endDate,
				timelineMinorStep,
				fetchVisibleItemsData,
				getCalculationMetaData,
				groupCache,
				stepCache
			);

			if (setHasCalculated) {
				setHasCalculated();
			}
		}

		const duration = performance.now() - perfStart;

		if (!stepWasHidden) {
			const needsRecalculation = hasFeatureFlag('scheduling_recalculation_tree')
				? RecalculationManager.needsRecalculation(personId, timelineMinorStep, overallStartDate, overallEndDate)
				: groupCache && checkNeedsRecalculation(groupCache, timelineMinorStep);
			trackPerformancePersonHeatmap(personId, needsRecalculation, duration, visibleItemsData);
		}

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			clearNeedsRecalculation(groupCache, timelineMinorStep);
		}
	}
};

export const composePersonGroupHeatmapItems = (
	pageComponent,
	group,
	stepDataArray,
	timelineStartDate,
	timelineMinorStep,
	useDefaultEyeOptions = false
) => {
	const {data, schedulingOptions} = pageComponent.state;
	const {isProjectTimeline} = pageComponent.props;
	const {calcWin, hideSoft, hideHard} = schedulingOptions;
	const person = group.data;

	const disableHeatmap =
		isProjectTimeline &&
		hasFeatureFlag('combined_heatmap_logic_extensions') &&
		getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);

	if (isGlobalRecalculationNeeded(pageComponent)) {
		group.calculateHeatmapCache(group, stepDataArray, timelineMinorStep);
	}

	const heatmapItems = [];

	const groupCache = getGroupCache(pageComponent, group.id);
	const stepCache = getStepCache(groupCache, timelineMinorStep);
	for (const stepData of stepDataArray) {
		let {startDate, endDate} = stepData;

		const heatmapItemOptions = {
			group,
			stepData,
			onClick: disableHeatmap
				? undefined
				: () => {
						handlePersonGroupHeatmapClick(
							pageComponent,
							group,
							startDate,
							endDate,
							timelineMinorStep,
							stepDataArray
						);
				  },
			useDefaultEyeOptions,
		};

		if (isStepHiddenBehindLoadMore(pageComponent, stepData)) {
			const stepHiddenItemOptions = {
				...heatmapItemOptions,
				...STEP_HIDDEN_HEATMAP_DATA,
			};

			heatmapItems.push(constructPersonGroupHeatmapItem(pageComponent, 'utilization', stepHiddenItemOptions));

			continue;
		}

		const heatmapData = stepCache.get(startDate);
		const plannedTotalMinutes = getMinutesAllocatedVariation(
			heatmapData.minutesAllocatedVariations,
			hideSoft,
			hideHard,
			calcWin
		);
		const totalMinutesAllocated = isAllocatedHoursNaN(plannedTotalMinutes)
			? heatmapData.minutesAllocated
			: plannedTotalMinutes;

		if (
			(person.startDate !== null && person.startDate > endDate) ||
			(person.endDate !== null && person.endDate < startDate)
		) {
			heatmapItems.push(constructPersonGroupHeatmapItem(pageComponent, 'freeday', heatmapItemOptions));
		} else {
			const noVisibleAllocatedMinutes = totalMinutesAllocated <= 0;
			if (heatmapData.minutesAvailable <= 0 && noVisibleAllocatedMinutes) {
				const {personWorkingMinutes} = getPersonWorkingHoursAndMinutes(person);
				const {holidaysExcludingDaysOff} = getHolidayDates(person, data, personWorkingMinutes);

				if (stepData.isWeekend || (timelineMinorStep === 'day' && holidaysExcludingDaysOff.includes(startDate))) {
					// if the date is weekend or it is a public holiday show the freeday item
					heatmapItems.push(constructPersonGroupHeatmapItem(pageComponent, 'freeday', heatmapItemOptions));
				} else {
					// if not weekend nor freeday its timeoff
					heatmapItems.push(constructPersonGroupHeatmapItem(pageComponent, 'timeoff', heatmapItemOptions));
				}
			} else {
				heatmapItemOptions.minutesAllocated = heatmapData.minutesAllocated;
				heatmapItemOptions.plannedTotalMinutesHard = heatmapData.plannedTotalMinutesHard;
				heatmapItemOptions.plannedTotalMinutesSoft = heatmapData.plannedTotalMinutesSoft;
				heatmapItemOptions.plannedTotalMinutesSoftWin = heatmapData.plannedTotalMinutesSoftWin;
				heatmapItemOptions.minutesAvailable = heatmapData.minutesAvailable;
				heatmapItemOptions.taskMinutesAllocated = heatmapData.taskMinutesAllocated;
				heatmapItemOptions.minutesAllocatedVariations = heatmapData.minutesAllocatedVariations;
				heatmapItems.push(constructPersonGroupHeatmapItem(pageComponent, 'utilization', heatmapItemOptions));
			}
		}
	}

	return heatmapItems;
};

// PERSON_GROUP
export const onPersonGroupHeatmapItemClick = (pageComponent, person, startDate, endDate, getHeatMapData, readOnly = true) => {
	const heatMapData = getHeatMapData();
	const {
		workingMinutes,
		minutesAllocated,
		minutesAvailable,
		plannedTotalMinutesHard,
		plannedTotalMinutesSoft,
		plannedTotalMinutesSoftWin,
		distributionMap,
		minutesAllocatedVariations,
	} = heatMapData;

	const {
		viewer,
		projects,
		allProjects,
		projectGroups,
		projectPersons,
		idleTimes,
		persons,
		company,
		holidayCalendarEntries,
		holidayCalendars,
		phases,
		roles,
		teams,
		teamPersons,
	} = pageComponent.getData();

	const {timeline} = pageComponent;
	const {todayDate, schedulingOptions, project} = pageComponent.state;
	const isProjectTimeline = pageComponent.props.isProjectTimeline;

	const isUsingCombinationMode = getVisualizationMode(
		schedulingOptions,
		pageComponent.getData().company,
		VISUALIZATION_MODE.COMBINATION
	);

	showModal({
		type: isUsingCombinationMode
			? MODAL_TYPE.SCHEDULING_UTILIZATION
			: isProjectTimeline
			? MODAL_TYPE.STANDALONE_CANVAS_UTILIZATION
			: MODAL_TYPE.CANVAS_UTILIZATION,
		pageComponent,
		startDate,
		endDate,
		stepLabel: timeline.getStepLabelFromCanvasDate(startDate),
		minorStep: timeline.getMinorStep(),
		person,
		company,
		project: isProjectTimeline ? project : null,
		projects: isProjectTimeline ? allProjects : projects,
		projectGroups,
		persons,
		projectPersons,
		holidayCalendars,
		holidayCalendarEntries,
		actualPersonId: viewer.actualPersonId,
		availableFeatureFlags: viewer.availableFeatureFlags,
		idleTimes,
		readOnly,
		disableLinks: true,
		phases,
		roles,
		teams,
		teamPersons,
		isPast: endDate < todayDate,
		workingMinutes,
		minutesAllocated,
		minutesAvailable,
		minutesAllocatedVariations,
		distributionMap,
		schedulingOptions,
		plannedTotalMinutesHard,
		plannedTotalMinutesSoft,
		plannedTotalMinutesSoftWin,
	});

	tracking.trackEvent('Opened Utilization Modal');
};
