/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectPortfolioPeriodRow_financialNumbers$ref: FragmentReference;
declare export opaque type ProjectPortfolioPeriodRow_financialNumbers$fragmentType: ProjectPortfolioPeriodRow_financialNumbers$ref;
export type ProjectPortfolioPeriodRow_financialNumbers = {|
  +billableActualTimeAndExpenses: ?number,
  +totalActualRevenueRecognition: ?number,
  +actualCost: ?number,
  +actualProfit: ?number,
  +actualRevenueProfit: ?number,
  +actualMargin: ?number,
  +actualRevenueMargin: ?number,
  +billablePlannedTimeAndExpenses: ?number,
  +plannedRevenue: ?number,
  +plannedCost: ?number,
  +plannedProfit: ?number,
  +plannedRevenueProfit: ?number,
  +plannedMargin: ?number,
  +plannedRevenueMargin: ?number,
  +billableForecastTimeAndExpensesToComplete: ?number,
  +totalForecastRevenueToComplete: ?number,
  +forecastCostToComplete: ?number,
  +forecastProfitToComplete: ?number,
  +forecastRevenueProfitToComplete: ?number,
  +forecastMarginToComplete: ?number,
  +forecastRevenueMarginToComplete: ?number,
  +billableTotalTimeAndExpensesAtCompletion: ?number,
  +nonBillableTotalTimeAndExpensesAtCompletion: ?number,
  +allTotalTimeAndExpensesAtCompletion: ?number,
  +totalRevenueRecognition: ?number,
  +totalCostAtCompletion: ?number,
  +totalProfitAtCompletion: ?number,
  +totalRevenueProfitAtCompletion: ?number,
  +totalMarginAtCompletion: ?number,
  +totalRevenueMarginAtCompletion: ?number,
  +baselineRevenue: ?number,
  +baselineTimeAndExpenses: ?number,
  +baselineCost: ?number,
  +baselineProfit: ?number,
  +baselineMargin: ?number,
  +baselineMinutes: ?number,
  +planVsBillableActualTimeAndExpenses: ?number,
  +planVsActualCost: ?number,
  +planVsActualProfit: ?number,
  +planVsTotalBillableTimeAndExpensesAtCompletion: ?number,
  +planVsTotalCostAtCompletion: ?number,
  +planVsTotalProfitAtCompletion: ?number,
  +registeredMinutes: ?number,
  +forecastTimeToComplete: ?number,
  +totalTimeAtCompletion: ?number,
  +totalSuggestedRevenue: ?number,
  +scopeApprovedMinutes: ?number,
  +scopeTotalMinutes: ?number,
  +allocationMinutes: ?number,
  +invoiced: ?number,
  +paid: ?number,
  +accruedDeferred: ?number,
  +retainerPeriodTargetMinutes: ?number,
  +retainerPeriodTargetPrice: ?number,
  +recognitionLockedRevenue: ?number,
  +recognitionOpenRevenue: ?number,
  +unallocatedRevenue: ?number,
  +programBudgetValue: ?number,
  +recognitionProfit: ?number,
  +recognitionProfitPercentage: ?number,
  +projectedTotalRevenueVsProjectedTotalBillableValueOfService: ?number,
  +projectedTotalRevenueVsProjectedTotalValueOfService: ?number,
  +$refType: ProjectPortfolioPeriodRow_financialNumbers$ref,
|};
export type ProjectPortfolioPeriodRow_financialNumbers$data = ProjectPortfolioPeriodRow_financialNumbers;
export type ProjectPortfolioPeriodRow_financialNumbers$key = {
  +$data?: ProjectPortfolioPeriodRow_financialNumbers$data,
  +$fragmentRefs: ProjectPortfolioPeriodRow_financialNumbers$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPortfolioPeriodRow_financialNumbers",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billableActualTimeAndExpenses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalActualRevenueRecognition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualRevenueProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualRevenueMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billablePlannedTimeAndExpenses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plannedRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plannedCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plannedProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plannedRevenueProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plannedMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plannedRevenueMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billableForecastTimeAndExpensesToComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalForecastRevenueToComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forecastCostToComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forecastProfitToComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forecastRevenueProfitToComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forecastMarginToComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forecastRevenueMarginToComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billableTotalTimeAndExpensesAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nonBillableTotalTimeAndExpensesAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allTotalTimeAndExpensesAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalRevenueRecognition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCostAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalProfitAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalRevenueProfitAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalMarginAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalRevenueMarginAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineTimeAndExpenses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineMargin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planVsBillableActualTimeAndExpenses",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planVsActualCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planVsActualProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planVsTotalBillableTimeAndExpensesAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planVsTotalCostAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planVsTotalProfitAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forecastTimeToComplete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalTimeAtCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalSuggestedRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scopeApprovedMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scopeTotalMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allocationMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiced",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accruedDeferred",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retainerPeriodTargetMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "retainerPeriodTargetPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recognitionLockedRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recognitionOpenRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unallocatedRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "programBudgetValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recognitionProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recognitionProfitPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectedTotalRevenueVsProjectedTotalBillableValueOfService",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectedTotalRevenueVsProjectedTotalValueOfService",
      "storageKey": null
    }
  ],
  "type": "FinancialNumbers"
};
// prettier-ignore
(node/*: any*/).hash = '5c7f1e3fc998e3d154a46f79a93f5819';

module.exports = node;
