import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import Util from '../forecast-app/shared/util/util';
import {BUTTON_STYLE, BUTTON_COLOR} from '../constants';
import Button from '../forecast-app/shared/components/buttons/button/button';

class MeetingCreator extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
		};
	}

	expand() {
		this.setState({
			expanded: true,
		});
	}

	collapse() {
		this.setState({
			expanded: false,
		});
	}

	iCalExport() {
		Util.iCalExport(this.props);
		this.collapse();
	}

	googleCalendarExport() {
		Util.googleCalendarExport(this.props);
		this.collapse();
	}

	render() {
		const {formatMessage} = this.props.intl;
		return this.props.useBurgerMenuStyling ? (
			[
				<li key="google-calendar" onClick={() => this.googleCalendarExport()}>
					{formatMessage({id: 'meeting_creator.create_google_meeting'})}
				</li>,
				<li key="client-calendar" onClick={() => this.iCalExport()}>
					{formatMessage({id: 'meeting_creator.create_client_meeting'})}
				</li>,
			]
		) : (
			<div className="meeting-creator">
				<Button
					text={formatMessage({id: 'meeting_creator.create_meeting'})}
					buttonStyle={BUTTON_STYLE.OUTLINE}
					colorTheme={BUTTON_COLOR.PURPLE}
					onClick={this.props.disabled ? null : () => (this.state.expanded ? this.collapse() : this.expand())}
					onBlur={() => this.collapse()}
					isDisabled={this.props.disabled}
				/>
				{this.state.expanded ? (
					<div className="meeting-export-options">
						<div tabIndex={0} onMouseDown={() => this.googleCalendarExport()}>
							{formatMessage({id: 'common.google_calendar'})}
						</div>
						<div tabIndex={0} onMouseDown={() => this.iCalExport()}>
							{formatMessage({id: 'meeting_creator.local_client'})}
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

MeetingCreator.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	startTime: PropTypes.object,
	endTime: PropTypes.object,
	attendees: PropTypes.arrayOf(PropTypes.string),
	organizerName: PropTypes.string,
	organizerEmail: PropTypes.string,
	useBurgerMenuStyling: PropTypes.bool,
};

export default injectIntl(MeetingCreator);
