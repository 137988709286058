import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {TableSectionTitleHeadline, TableSectionWrapper, TableWrapper} from './TableSection.styled';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';
import {LoadMore} from '../../../../../loaders/LoadMore';
import BudgetTotalsLoader, {budgetTotalsLoaderQuery} from '../../loaders/BudgetTotalsLoader';
import TotalsTableRow from './TotalsTableRow';
import getAggregateRowTitle from '../../util/UseAggregateIntl';
import {BudgetEyeOptions} from '../../BudgetPage.EyeOptions';
import {BudgetTooltips} from '../../util/BudgetTooltips';
import {BUDGET_MONEY_VIEWS, BUDGET_TYPE} from '../../../../../../constants';
import {hasFeatureFlag} from '../../../../../../forecast-app/shared/util/FeatureUtil';
import {useRemappingFormatMessage} from '../../../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import ProjectUtil from '../../../../../../forecast-app/shared/util/project_util';

const Totals = ({project, eyeOptionMap, selectValueType, hasBaseline}) => {
	const intl = useIntl();
	const formatMessage = useRemappingFormatMessage();
	const isFixedPriceV1 = ProjectUtil.isFixedPriceV1(project);
	const isFixedPrice = project.budgetType === BUDGET_TYPE.FIXED_PRICE || project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2;
	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');
	const useFixedPriceCalculation =
		isFixedPrice && project.useFixedPriceForBaselineRevenue && hasFeatureFlag('baseline_financial_service');
	const settings = project.financialSourceSettings;

	return (
		<>
			<TableSectionWrapper>
				<TableSectionTitleHeadline>{formatMessage({id: 'common.totals'})}</TableSectionTitleHeadline>
				<TableWrapper>
					<Table paddedCells cy="budget-totals">
						<Table.Header>
							<Table.HeaderColumn visible flex={1} width="LARGE" />
							<Table.HeaderColumn
								key={'fixedPrice'}
								usePadding
								align="right"
								visible={isFixedPriceV1 && eyeOptionMap[BudgetEyeOptions.FIXED_PRICE]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.fixed_price'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'baseline'}
								usePadding
								align="right"
								visible={hasBaseline && eyeOptionMap[BudgetEyeOptions.BASELINE]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.baseline'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'plan'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.PLAN]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.plan'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'actuals'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.ACTUAL]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.actuals'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'remaining'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.REMAINING]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({
										id: 'project_budget.forecast_to_complete',
									})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'forecast'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.FORECAST]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({
										id: 'project_budget.total_at_completion',
									})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'baselineVsActual'}
								usePadding
								align="right"
								visible={hasBaseline && eyeOptionMap[BudgetEyeOptions.BASELINE_VS_ACTUAL]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.baseline_vs_actual'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'baselineVsForecast'}
								usePadding
								align="right"
								visible={hasBaseline && eyeOptionMap[BudgetEyeOptions.BASELINE_VS_FORECAST]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.baseline_vs_forecast'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'plan_vs_actual'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.PLAN_VS_ACTUAL]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.plan_vs_actual'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'plan_vs_forecast'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.PLAN_VS_FORECAST]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.plan_vs_forecast'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'revenueRecognition'}
								usePadding
								align="right"
								visible={
									eyeOptionMap[BudgetEyeOptions.REVENUE_RECOGNITION] &&
									!hasFeatureFlag('financial_categories_update') &&
									(project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 ||
										hasFeatureFlag('revenue_recognition_with_all_budget_types'))
								}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'common.revenue_recognition'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
						</Table.Header>

						<LoadMore
							key="query-render-totals"
							query={budgetTotalsLoaderQuery}
							loader={<BudgetTotalsLoader />}
							variables={{
								projectId: project.id,
								startYear: project.projectFirstDateYear,
								startMonth: project.projectFirstDateMonth,
								startDay: project.projectFirstDateDay,
								endYear: project.projectLastDateYear,
								endMonth: project.projectLastDateMonth,
								endDay: project.projectLastDateDay,
							}}
						>
							{({data: totalsData}) => {
								return (
									<Table.Rows data={totalsData} canExpand={false}>
										{({rowData, tableColumnsProps}) => {
											return (
												<TotalsTableRow
													rowData={rowData}
													tableColumnProps={tableColumnsProps}
													headerColumn={
														<Table.Column width="LARGE">
															{getAggregateRowTitle(intl, rowData.rowType)}
														</Table.Column>
													}
													tooltips={BudgetTooltips.TotalsTooltips(
														formatMessage,
														selectValueType,
														BUDGET_MONEY_VIEWS.WORK_AND_EXPENSES,
														settings,
														isFixedPrice,
														useFixedPriceCalculation,
														hasFinancialCategoriesUpdate
													)}
												/>
											);
										}}
									</Table.Rows>
								);
							}}
						</LoadMore>
					</Table>
				</TableWrapper>
			</TableSectionWrapper>
		</>
	);
};

export default createFragmentContainer(Totals, {
	project: graphql`
		fragment Totals_project on ProjectType {
			id
			budgetType
			defaultPeriodBudgetType
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
			estimationUnit
			financialSourceSettings {
				plannedHours
				plannedRevenue
				plannedCost
				forecastHours
				forecastRevenue
				forecastCost
				actualHours
				actualRevenue
				actualCost
			}
			useFixedPriceForBaselineRevenue
		}
	`,
});
