import React, {useEffect, useState} from 'react';
import {Dropdown, Switch} from 'web-components';
import styled from 'styled-components';
import {useIntl} from 'react-intl';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';

import DirectApi from '../../directApi';

import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../forecast-app/shared/util/util';
import SetProjectSlackChannelMutation from '../../mutations/slack_integration/SetProjectSlackChannelMutation';

import ForecastTooltip from '../../forecast-app/shared/components/tooltips/ForecastTooltip';

const NotificationSettingRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 8px;
`;

const SlackIntegrationSection = ({project}) => {
	const intl = useIntl();

	const [slackChannels, setSlackChannels] = useState(null);
	const [slackNotificationSettings, setSlackNotificationSettings] = useState(null);

	const getAvailableSlackChannels = () => {
		DirectApi.Fetch(`slack/available_channels/${Util.getIdFromBase64String(project.id)}`).then(channels =>
			setSlackChannels(channels.map(c => ({value: c.id, label: c.name})))
		);
	};

	const getSlackNotificationSettings = () => {
		DirectApi.Fetch(`slack/project_notification_settings/${Util.getIdFromBase64String(project.id)}`).then(settings =>
			setSlackNotificationSettings(settings)
		);
	};

	useEffect(() => {
		getAvailableSlackChannels();
		if (project.slackChannelId) getSlackNotificationSettings();
	}, []);

	const setProjectSlackChannel = selected => {
		const channelId = selected ? selected[0] : null;
		Util.CommitMutation(
			SetProjectSlackChannelMutation,
			{
				projectId: project.id,
				channelId,
			},
			() => {
				getSlackNotificationSettings(); // Might be a default setup configured. Fetch it.
				createToast({
					duration: 5000,
					message: intl.formatMessage({
						id:
							channelId == null
								? 'settings.slack.project_unlinked_from_slack_channel'
								: 'settings.slack.project_linked_to_slack_channel',
					}),
				});
			}
		);
	};

	const saveProjectSlackNotifications = notificationSettings => {
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const init = {headers, credentials: 'include', method: 'PUT', body: JSON.stringify(notificationSettings)};
		fetch(
			DirectApi.graphqlServerEndpoint(`slack/project_notification_settings/${Util.getIdFromBase64String(project.id)}`),
			init
		).then(result => result.json());

		createToast({
			duration: 5000,
			message: intl.formatMessage({
				id: 'settings.slack.project_notification_settings_saved',
			}),
		});
	};

	const setProjectSlackNotification = val => {
		const updatedSettings = {...slackNotificationSettings, [val]: !slackNotificationSettings[val]};

		setSlackNotificationSettings(updatedSettings);
		saveProjectSlackNotifications(updatedSettings);
	};

	const slackChannelsContent =
		slackChannels == null ? (
			<InlineLoader />
		) : (
			<Dropdown
				isClearable={true}
				onSelect={id => setProjectSlackChannel(id)}
				onRemove={() => setProjectSlackChannel(null)}
				name={intl.formatMessage({id: 'settings.slack.link_project_to_channel'})}
				selectedItems={[project.slackChannelId]}
			>
				{slackChannels
					.sort((o1, o2) => (o1.label < o2.label ? -1 : 1))
					.map(option => (
						<Dropdown.SingleText key={option.label} value={option.value} searchString={option.label}>
							{option.label}
						</Dropdown.SingleText>
					))}
			</Dropdown>
		);

	const notificationSettingsContent =
		project.slackChannelId == null || slackChannels == null ? (
			''
		) : slackNotificationSettings ? (
			<div>
				<ForecastTooltip content={intl.formatMessage({id: 'settings.slack.task_creation.tooltip'})} placement={'right'}>
					<NotificationSettingRow>
						{intl.formatMessage({id: 'settings.slack.task_creation'})}
						<Switch
							onChange={() => setProjectSlackNotification('taskCreation')}
							checked={slackNotificationSettings && slackNotificationSettings['taskCreation']}
						/>
					</NotificationSettingRow>
				</ForecastTooltip>
				<ForecastTooltip content={intl.formatMessage({id: 'settings.slack.task_deletion.tooltip'})} placement={'right'}>
					<NotificationSettingRow>
						{intl.formatMessage({id: 'settings.slack.task_deletion'})}
						<Switch
							onChange={() => setProjectSlackNotification('taskDeletion')}
							checked={slackNotificationSettings && slackNotificationSettings['taskDeletion']}
						/>
					</NotificationSettingRow>
				</ForecastTooltip>
				<ForecastTooltip
					content={intl.formatMessage({id: 'settings.slack.task_status_changes.tooltip'})}
					placement={'right'}
				>
					<NotificationSettingRow>
						{intl.formatMessage({id: 'settings.slack.task_status_changes'})}
						<Switch
							onChange={() => setProjectSlackNotification('taskStatusChanges')}
							checked={slackNotificationSettings && slackNotificationSettings['taskStatusChanges']}
						/>
					</NotificationSettingRow>
				</ForecastTooltip>
				<ForecastTooltip content={intl.formatMessage({id: 'settings.slack.task_comments.tooltip'})} placement={'right'}>
					<NotificationSettingRow>
						{intl.formatMessage({id: 'settings.slack.task_comments'})}
						<Switch
							onChange={() => setProjectSlackNotification('taskComments')}
							checked={slackNotificationSettings && slackNotificationSettings['taskComments']}
						/>
					</NotificationSettingRow>
				</ForecastTooltip>
				<ForecastTooltip
					content={intl.formatMessage({id: 'settings.slack.project_status_changes.tooltip'})}
					placement={'right'}
				>
					<NotificationSettingRow>
						{intl.formatMessage({id: 'settings.slack.project_status_changes'})}
						<Switch
							onChange={() => setProjectSlackNotification('projectStatusChanges')}
							checked={slackNotificationSettings && slackNotificationSettings['projectStatusChanges']}
						/>
					</NotificationSettingRow>
				</ForecastTooltip>
				<ForecastTooltip
					content={intl.formatMessage({id: 'settings.slack.project_updates.tooltip'})}
					placement={'right'}
				>
					<NotificationSettingRow>
						{intl.formatMessage({id: 'settings.slack.project_updates'})}
						<Switch
							onChange={() => setProjectSlackNotification('projectUpdates')}
							checked={slackNotificationSettings && slackNotificationSettings['projectUpdates']}
						/>
					</NotificationSettingRow>
				</ForecastTooltip>
			</div>
		) : (
			<InlineLoader />
		);

	return (
		<div>
			{slackChannelsContent}
			{notificationSettingsContent}
		</div>
	);
};

export default SlackIntegrationSection;
