import React from 'react';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';
import {renderMinutes} from '../util/BudgetUtils';
import {Tooltip, Text} from '@forecast-it/design-system';
import {TooltipFormulaRenderer} from '../../../../TooltipFormulaRenderer';

const TimeTotalsTableRow = ({rowData, tableColumnProps, headerColumn, canExpand, tooltips}) => {
	const intl = useIntl();

	if (canExpand !== undefined) {
		tableColumnProps.canExpand = canExpand;
	}

	const getTooltipForAggregate = (tooltips, aggregate) => {
		const tooltipItems = tooltips ? tooltips[aggregate] : null;
		return tooltipItems ? tooltipItems : null;
	};

	return (
		<Table.Row cy={'row-' + rowData.rowType} key={rowData.rowType} {...tableColumnProps}>
			<Table.Column>{headerColumn}</Table.Column>
			{rowData.dataArray.map(object => {
				const tooltipItem = getTooltipForAggregate(tooltips, object.type);
				return tooltipItem ? (
					<Table.Column cy={'column-' + object.type} key={rowData.rowType}>
						<Tooltip width={'xl'} content={<TooltipFormulaRenderer items={tooltipItem} />} interactable={false}>
							<Text size={'3'}>{renderMinutes(object.value, intl)}</Text>
						</Tooltip>
					</Table.Column>
				) : (
					<Table.Column cy={'column-' + object.type} key={rowData.rowType}>
						<Text>{renderMinutes(object.value, intl)}</Text>
					</Table.Column>
				);
			})}
		</Table.Row>
	);
};

export default TimeTotalsTableRow;
