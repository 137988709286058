import whiteCheckmark from '../../../images/components/scheduling/perfect-allocation.svg';
import greenCheckmark from '../../../images/components/scheduling/green-checkmark.svg';
import connectedProjectIconBlack from '../../../images/sections/scheduling-canvas/project-group-icon.svg';
import connectedProjectIconWhite from '../../../images/sections/scheduling-canvas/project-group-icon-white.svg';
import phaseIconBlack from '../../../images/sections/scheduling-canvas/phase-icon.svg';
import phaseIconWhite from '../../../images/sections/scheduling-canvas/phase-icon-white.svg';
import projectTeamIconBlack from '../../../images/sections/scheduling-canvas/project-team-icon.svg';
import projectTeamIconWhite from '../../../images/sections/scheduling-canvas/project-team-icon-white.svg';
import goToNextIconWhite from '../../../images/components/scheduling/go-to-next-white.svg';
import goToNextIcon from '../../../images/components/scheduling/go-to-next.svg';
import goToPreviousIcon from '../../../images/components/scheduling/go-to-previous.svg';
import goToPreviousIconWhite from '../../../images/components/scheduling/go-to-previous-white.svg';
import unassignedHeatmapPerson from '../../../images/components/scheduling/person.svg';
import highPriorityFlagWhite from '../../../images/cross-section-icons/high-priority-white.svg';
import dependencyHandleLeft from '../../../images/sections/scheduling-canvas/dependency-handle-left.svg';
import dependencyHandleRight from '../../../images/sections/scheduling-canvas/dependency-handle-right.svg';
import warningIconRed from '../../../images/sections/scheduling-canvas/warning-icon-red.svg';
import hexagonPlusGreen from '../../../images/components/scheduling/hexaplus.svg';
import bug from '../../../images/v2/components/workflow-task/bugs-icon-white.svg';
import blocked from '../../../images/cross-section-icons/blocked-white-icon.svg';
import warning from '../../../images/components/scheduling/warning-icon.svg';
import greenCheckmarkV2 from '../../../images/components/scheduling/green-checkmark-v2.svg';
import freeDayPattern from '../../../images/diagonal-lines-pattern.svg';
import freeDayPatternHeatmapOkAllocation from '../../../images/diagonal-lines-pattern_heatmap_ok_allocation.svg';
import freeDayPatternHeatmapOverAllocation from '../../../images/diagonal-lines-pattern_heatmap_over_allocation.svg';
import expand from '../../../images/components/scheduling/expand.svg';
import collapse from '../../../images/components/scheduling/collapse.svg';
import expandAll from '../../../images/components/scheduling/expand-all.svg';
import collapseAll from '../../../images/components/scheduling/collapse-all.svg';
import phases from '../../../images/components/scheduling/phases.svg';
import projectTeam from '../../../images/components/scheduling/project-team.svg';
import task from '../../../images/components/scheduling/task.svg';
import subTask from '../../../images/components/scheduling/sub-task.svg';
import taskDone from '../../../images/components/scheduling/task-done.svg';
import taskNotStarted from '../../../images/components/scheduling/task-not-started.svg';
import subTaskNotStarted from '../../../images/components/scheduling/sub-task-not-yet-started.svg';
import connectedProjectWhite from '../../../images/components/scheduling/connected-project-white.svg';
import taskDoneGreenCheckmark from '../../../images/components/scheduling/task-done-green-checkmark.svg';
import taskBlockedBlack from '../../../images/components/scheduling/task-blocked-black.svg';
import taskBugBlack from '../../../images/components/scheduling/task-bug-black.svg';
import taskFlagBlack from '../../../images/components/scheduling/task-flag-black.svg';
import expandGroupIcon from '../../../images/components/scheduling/expand-group.svg';
import expandGroupClosedIcon from '../../../images/components/scheduling/expand-group-closed.svg';
import allocationNoteIcon from '../../../images/components/scheduling/allocation-note-icon.svg';
import heatmapCompletionFullIcon from '../../../images/components/scheduling/heatmap-completion-full-icon.svg';
import heatmapCompletionFullActualIcon from '../../../images/components/scheduling/heatmap-completion-full-actual-icon.svg';
import heatmapCompletionFullBlackIcon from '../../../images/components/scheduling/heatmap-completion-full-black-icon.svg';
import overallocatedWwarningIcon from '../../../images/components/scheduling/overallocated-warning-icon.svg';
import placeholderGroup from '../../../images/components/scheduling/placeholder-group.svg';
import placeholderAvatarBackground from '../../../images/components/scheduling/placeholder-avatar-background.svg';
import placeholderInformationIcon from '../../../images/components/scheduling/placeholder-information-icon.svg';
import peopleGreyIcon from '../../../images/components/scheduling/people-grey-icon.svg';
import filterGreyIcon from '../../../images/components/scheduling/filter-grey-icon.svg';

//mapId should be the same for all elements of the same type (ex: Project Groups), it is used to narrow down the search and avoid looping through a huge map every time
//for example for groups, we use group type

export const COMMON_IMAGE = {
	WHITE_CHECKMARK: 'white-checkmark',
	GREEN_CHECKMARK: 'green-checkmark',
	GREEN_CHECKMARK_V2: 'green-checkmark-v2',
	CONNECTED_PROJECT_BLACK: 'connected-project-black',
	CONNECTED_PROJECT_WHITE: 'connected-project-white',
	PHASE_ICON_BLACK: 'phase-icon-black',
	PHASE_ICON_WHITE: 'phase-icon-white',
	PROJECT_TEAM_ICON_BLACK: 'project-team-icon-black',
	PROJECT_TEAM_ICON_WHITE: 'project-team-icon-white',
	GO_TO_NEXT_WHITE: 'go-to-next-icon-white',
	GO_TO_NEXT: 'go-to-next-icon',
	GO_TO_PREVIOUS_WHITE: 'go-to-previous-icon-white',
	GO_TO_PREVIOUS: 'go-to-previous-icon',
	UNASSIGNED_HEATMAP_PERSON: 'unassigned-person-heatmap',
	HIGH_PRIORITY_FLAG_WHITE: 'high-priority-flag-white',
	DEPENDENCY_HANDLE_LEFT: 'dependency-handle-left',
	DEPENDENCY_HANDLE_RIGHT: 'dependency-handle-right',
	WARNING_ICON_RED: 'warning-icon-red',
	HEXAGON_PLUS_GREEN: 'hexagon-plus-green',
	BUG: 'bug',
	BLOCKED: 'blocked',
	WARNING: 'warning',
	FREEDAY: 'freeday',
	FREEDAY_HEATMAP_OK_ALLOCATION: 'freeday_heatmap_ok_allocation',
	FREEDAY_HEATMAP_OVER_ALLOCATION: 'freeday_heatmap_over_allocation',
	EXPAND: 'expand',
	COLLAPSE: 'collapse',
	EXPAND_ALL: 'expand-all',
	COLLAPSE_ALL: 'collapse-all',
	PHASES_BLACK: 'phases',
	PROJECT_TEAM_BLACK: 'project-team',
	TASK_BLACK: 'task',
	SUB_TASK_BLACK: 'sub-task',
	TASK_DONE_BLACK: 'task-done',
	TASK_NOT_STARTED: 'task-not-started',
	SUB_TASK_NOT_STARTED: 'sub-task-not-started',
	CONNECTED_PROJECT_WHITE_NEW: 'connected-project-white-new',
	TASK_DONE_GREEN_CHECKMARK: 'task-done-green-checkmark',
	TASK_BLOCKED_BLACK: 'task-blocked-black',
	TASK_BUG_BLACK: 'task-bug-black',
	TASK_HIGH_PRIORITY_BLACK: 'task-high-priority-black',
	EXPAND_GROUP_ICON: 'expand-group-icon',
	EXPAND_GROUP_CLOSED_ICON: 'expand-group-icon-closed',
	ALLOCATION_NOTE_ICON: 'allocation-note-icon',
	HEATMAP_COMPLETION_FULL_ICON: 'heatmap-completion-full-icon',
	HEATMAP_COMPLETION_FULL_ACTUAL_ICON: 'heatmap-completion-full-actual-icon',
	HEATMAP_COMPLETION_FULL_BLACK_ICON: 'heatmap-completion-full-black-icon',
	OVERALLOCATED_WARNING_ICON: 'overallocated-warning-icon',
	PLACEHOLDER_GROUP_ICON: 'placeholder-group',
	PLACEHOLDER_AVATAR_BACKGROUND: 'placeholder-avatar-background',
	PLACEHOLDER_INFORMATION_ICON: 'placeholder-information-icon',
	PEOPLE_GREY_ICON: 'people-grey-icon',
	FILTER_GREY_ICON: 'filter-grey-icon',
};

//cacheId should be different for every specific element you need cached
//for example if a group looks different when it is expanded than when it is not you will need two separate ids that store two different things
class CacheManager {
	constructor() {
		this.cache = new Map();
		this.commonMapId = 'canvas-timeline-cache-manager-common-map-id';
		this.cacheCommonImages();
	}

	cacheCommonImages() {
		const imageMap = {
			WHITE_CHECKMARK: whiteCheckmark,
			GREEN_CHECKMARK: greenCheckmark,
			GREEN_CHECKMARK_V2: greenCheckmarkV2,
			CONNECTED_PROJECT_BLACK: connectedProjectIconBlack,
			CONNECTED_PROJECT_WHITE: connectedProjectIconWhite,
			PHASE_ICON_BLACK: phaseIconBlack,
			PHASE_ICON_WHITE: phaseIconWhite,
			PROJECT_TEAM_ICON_BLACK: projectTeamIconBlack,
			PROJECT_TEAM_ICON_WHITE: projectTeamIconWhite,
			GO_TO_NEXT_WHITE: goToNextIconWhite,
			GO_TO_NEXT: goToNextIcon,
			GO_TO_PREVIOUS_WHITE: goToPreviousIconWhite,
			GO_TO_PREVIOUS: goToPreviousIcon,
			UNASSIGNED_HEATMAP_PERSON: unassignedHeatmapPerson,
			HIGH_PRIORITY_FLAG_WHITE: highPriorityFlagWhite,
			DEPENDENCY_HANDLE_LEFT: dependencyHandleLeft,
			DEPENDENCY_HANDLE_RIGHT: dependencyHandleRight,
			WARNING_ICON_RED: warningIconRed,
			HEXAGON_PLUS_GREEN: hexagonPlusGreen,
			BUG: bug,
			BLOCKED: blocked,
			WARNING: warning,
			FREEDAY: freeDayPattern,
			FREEDAY_HEATMAP_OK_ALLOCATION: freeDayPatternHeatmapOkAllocation,
			FREEDAY_HEATMAP_OVER_ALLOCATION: freeDayPatternHeatmapOverAllocation,
			EXPAND: expand,
			COLLAPSE: collapse,
			EXPAND_ALL: expandAll,
			COLLAPSE_ALL: collapseAll,
			PHASES_BLACK: phases,
			PROJECT_TEAM_BLACK: projectTeam,
			TASK_BLACK: task,
			SUB_TASK_BLACK: subTask,
			TASK_DONE_BLACK: taskDone,
			TASK_NOT_STARTED: taskNotStarted,
			SUB_TASK_NOT_STARTED: subTaskNotStarted,
			CONNECTED_PROJECT_WHITE_NEW: connectedProjectWhite,
			TASK_DONE_GREEN_CHECKMARK: taskDoneGreenCheckmark,
			TASK_BLOCKED_BLACK: taskBlockedBlack,
			TASK_BUG_BLACK: taskBugBlack,
			TASK_HIGH_PRIORITY_BLACK: taskFlagBlack,
			EXPAND_GROUP_ICON: expandGroupIcon,
			EXPAND_GROUP_CLOSED_ICON: expandGroupClosedIcon,
			ALLOCATION_NOTE_ICON: allocationNoteIcon,
			HEATMAP_COMPLETION_FULL_ICON: heatmapCompletionFullIcon,
			HEATMAP_COMPLETION_FULL_ACTUAL_ICON: heatmapCompletionFullActualIcon,
			HEATMAP_COMPLETION_FULL_BLACK_ICON: heatmapCompletionFullBlackIcon,
			OVERALLOCATED_WARNING_ICON: overallocatedWwarningIcon,
			PLACEHOLDER_GROUP_ICON: placeholderGroup,
			PLACEHOLDER_AVATAR_BACKGROUND: placeholderAvatarBackground,
			PLACEHOLDER_INFORMATION_ICON: placeholderInformationIcon,
			PEOPLE_GREY_ICON: peopleGreyIcon,
			FILTER_GREY_ICON: filterGreyIcon,
		};

		for (const name in COMMON_IMAGE) {
			if (Object.prototype.hasOwnProperty.call(COMMON_IMAGE, name)) {
				const image = new Image();
				image.onload = () => {
					this.set(this.commonMapId, COMMON_IMAGE[name], image);
				};
				image.src = imageMap[name];
			}
		}
	}

	set(mapId, cacheId, data) {
		let map = this.cache.get(mapId);
		if (!map) {
			map = new Map();
			map.set(cacheId, data);
			this.cache.set(mapId, map);
		} else {
			map.set(cacheId, data);
		}
	}

	get(mapId, cacheId) {
		const map = this.cache.get(mapId);
		if (!map) return null;
		return map.get(cacheId) || null;
	}

	getCommonImage(cacheId) {
		return this.get(this.commonMapId, cacheId);
	}

	/* Safari cannot create patterns from svgs, so draw in the SVG to an adapter canvas
	 *  This can be replaced with getCommonImage once (if) this bug (from 2010) is resolved https://bugs.webkit.org/show_bug.cgi?id=45277 */
	getCommonImageSafariWorkaround(cacheId) {
		const map = this.cache.get(this.commonMapId);

		if (map) {
			const adaptedImageCacheId = cacheId + 'adapter';

			if (!map.get(adaptedImageCacheId)) {
				const patternImage = this.getCommonImage(cacheId);
				const svgCanvas = document.createElement('canvas');
				svgCanvas.width = patternImage.width;
				svgCanvas.height = patternImage.height;
				const svgCtx = svgCanvas.getContext('2d');
				svgCtx.drawImage(patternImage, 0, 0, patternImage.width, patternImage.height);
				map.set(adaptedImageCacheId, svgCanvas);
			}
			return map.get(adaptedImageCacheId);
		}

		return null;
	}

	clear() {
		for (const key of this.cache.keys()) {
			if (key === this.commonMapId) continue;
			this.cache.delete(key);
		}
	}
}

export const cacheManager = new CacheManager();
