import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown, CircleText} from 'web-components';

export const CircleTextDropdown = ({
	showCircleText,
	emphasizeEmptyState,
	options,
	dropdownAlignment,
	selectedItems,
	width,
	name,
	selectedGroupName,
	optionsName,
	onSelect,
	onRemove,
	userpilot,
	headerLines,
	cy,
}) => {
	const getSearchString = option => {
		return option.label.toLowerCase();
	};

	return (
		<Dropdown
			isNested
			isMultiSelect
			headerLines={headerLines}
			selectedItems={selectedItems}
			selectedGroupName={selectedGroupName}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
			cy={cy}
		>
			<Dropdown.Group name={optionsName} key={'circle-text-options'}>
				{options.map(option => {
					return (
						<CircleText
							key={option.value}
							value={option.value}
							searchString={getSearchString(option)}
							color={option.color}
							noCircleText={!showCircleText}
						>
							{option.label}
						</CircleText>
					);
				})}
			</Dropdown.Group>
		</Dropdown>
	);
};

CircleTextDropdown.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any.isRequired,
			label: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired,
		})
	),
	showCircleText: PropTypes.bool.isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	headerLines: PropTypes.element,
};

CircleTextDropdown.defaultProps = {
	onSelect: () => false,
	onRemove: () => false,
	showCircleText: true,
};

export default CircleTextDropdown;
