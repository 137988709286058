import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import InsightList from './list';

class ExpenseReportProjectDummy extends Component {
	createDummy(
		name,
		cost,
		price,
		markup,
		quantity,
		expenseCategory,
		date,
		billable,
		notes,
		createdAt,
		createdBy,
		updatedAt,
		updatedBy
	) {
		const res = {};
		res.name = name;
		res.cost = '$' + this.props.intl.formatNumber(cost);
		res.price = price ? '$' + this.props.intl.formatNumber(price) : '';
		res.markup = markup ? this.props.intl.formatNumber(markup) + '%' : '';
		res.quantity = this.props.intl.formatNumber(quantity);
		res.expenseCategory = expenseCategory;
		res.billable = billable ? 'Yes' : 'No';
		res.approved = 'Yes';
		res.date = date;
		res.person = {firstName: 'John', lastName: 'Doe', profilePictureDefaultId: 0, profilePictureId: null};
		res.notes = notes;
		res.billable = billable ? 'Yes' : 'No';
		res.createdAt = createdAt;
		res.createdBy = createdBy;
		res.updatedAt = updatedAt;
		res.updatedBy = updatedBy;
		return res;
	}

	render() {
		let data = [];
		data.push(
			this.createDummy(
				'First expense',
				250,
				500,
				100,
				1,
				'Lodging',
				'01/01/2018',
				true,
				'Notes about expense',
				'01/01/2018',
				'John Doe',
				'01/01/2018',
				'John Doe'
			)
		);
		data.push(
			this.createDummy(
				'Second expense',
				100,
				null,
				null,
				2,
				'Meals',
				null,
				false,
				null,
				'01/01/2018',
				'John Doe',
				'01/01/2018',
				'John Doe'
			)
		);
		data.push(
			this.createDummy(
				'Third expense',
				250,
				500,
				100,
				1,
				'Entertainment',
				'01/01/2018',
				true,
				null,
				'01/01/2018',
				'John Doe',
				'01/01/2018',
				'John Doe'
			)
		);
		data.push(
			this.createDummy(
				'Fourth expense',
				250,
				null,
				null,
				1,
				'Entertainment',
				'01/01/2018',
				false,
				null,
				'01/01/2018',
				'John Doe',
				'01/01/2018',
				'John Doe'
			)
		);
		data.push(
			this.createDummy(
				'Fifth expense',
				200,
				null,
				null,
				1,
				'Mileage',
				'01/01/2018',
				false,
				null,
				'01/01/2018',
				'John Doe',
				'01/01/2018',
				'John Doe'
			)
		);
		return (
			<InsightList
				data={data}
				componentName={this.props.componentName}
				allColumns={this.props.allColumns}
				activeColumns={this.props.activeColumns}
				scrollElement={this.props.scrollElement}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

ExpenseReportProjectDummy.propTypes = {
	title: PropTypes.string,
};

export default injectIntl(ExpenseReportProjectDummy);
