import React from 'react';
import {useIntl} from 'react-intl';
import {Table, UtilizationMeter, WarningIcon} from 'web-components';
import ForecastTooltip from '../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import MonthByMonthPersonRows from './MonthByMonthPersonRows';
import MonthByMonthRoleRows from './MonthByMonthRoleRows';
import MonthByMonthDepartmentRows from './MonthByMonthDepartmentRows';
import {GROUP_BY} from '../UtilizationReport';
import {getAggregatedResourceNumbersByMonth, selectValueWithOptions} from '../UtilizationReportUtils';

export const MonthByMonthUtilizationMeter = ({percentage, theme}) => {
	let content = null;

	if (percentage !== 0) {
		if (percentage > 2) {
			const toolTip = (
				<div style={{padding: 8}}>
					<div>Utilization is over 200%</div>
					<div style={{fontWeight: 'normal', fontStyle: 'italic', marginTop: 4}}>
						Please double check if this is intended.
					</div>
				</div>
			);

			content = (
				<ForecastTooltip maxWidth="none" content={toolTip}>
					<WarningIcon updated />
				</ForecastTooltip>
			);
		}

		return (
			<UtilizationMeter
				percentage={percentage}
				theme={theme}
				content={content}
				checkmarkValue={1}
				redThreshold={1}
				warningThreshold={2}
			/>
		);
	} else {
		return '–';
	}
};

export const getUtilizationNumber = (
	rowData,
	month,
	showResourceUtilization,
	isMixedAllocationModeEnabled,
	allocationControlsOptions
) => {
	const aggregatedResourceNumbers = rowData.aggregatedResourceNumbersByMonth[`${month}`] || {
		forecastBillableUtilization: 0,
		forecastBillableUtilizationWin: 0,
		forecastBillableUtilizationSoft: 0,
		forecastBillableUtilizationSoftWin: 0,
		forecastBillableUtilizationHard: 0,
		forecastResourceUtilization: 0,
		forecastResourceUtilizationWin: 0,
		forecastResourceUtilizationSoft: 0,
		forecastResourceUtilizationSoftWin: 0,
		forecastResourceUtilizationHard: 0,
		forecastBillableUtilizationTaskAndAllocationsCombined: 0,
		forecastBillableUtilizationTaskAndAllocationsCombinedWin: 0,
		forecastBillableUtilizationTaskAndAllocationsCombinedSoft: 0,
		forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin: 0,
		forecastBillableUtilizationTaskAndAllocationsCombinedHard: 0,
		forecastResourceUtilizationTaskAndAllocationsCombined: 0,
		forecastResourceUtilizationTaskAndAllocationsCombinedWin: 0,
		forecastResourceUtilizationTaskAndAllocationsCombinedSoft: 0,
		forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin: 0,
		forecastResourceUtilizationTaskAndAllocationsCombinedHard: 0,
	};
	if (showResourceUtilization) {
		return isMixedAllocationModeEnabled
			? selectValueWithOptions(
					aggregatedResourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombined,
					aggregatedResourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombinedWin,
					aggregatedResourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombinedSoft,
					aggregatedResourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin,
					aggregatedResourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombinedHard,
					allocationControlsOptions
			  )
			: selectValueWithOptions(
					aggregatedResourceNumbers.forecastResourceUtilization,
					aggregatedResourceNumbers.forecastResourceUtilizationWin,
					aggregatedResourceNumbers.forecastResourceUtilizationSoft,
					aggregatedResourceNumbers.forecastResourceUtilizationSoftWin,
					aggregatedResourceNumbers.forecastResourceUtilizationHard,
					allocationControlsOptions
			  );
	} else {
		return isMixedAllocationModeEnabled
			? selectValueWithOptions(
					aggregatedResourceNumbers.forecastBillableUtilizationTaskAndAllocationsCombined,
					aggregatedResourceNumbers.forecastBillableUtilizationTaskAndAllocationsCombinedWin,
					aggregatedResourceNumbers.forecastBillableUtilizationTaskAndAllocationsCombinedSoft,
					aggregatedResourceNumbers.forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin,
					aggregatedResourceNumbers.forecastBillableUtilizationTaskAndAllocationsCombinedHard,
					allocationControlsOptions
			  )
			: selectValueWithOptions(
					aggregatedResourceNumbers.forecastBillableUtilization,
					aggregatedResourceNumbers.forecastBillableUtilizationWin,
					aggregatedResourceNumbers.forecastBillableUtilizationSoft,
					aggregatedResourceNumbers.forecastBillableUtilizationSoftWin,
					aggregatedResourceNumbers.forecastBillableUtilizationHard,
					allocationControlsOptions
			  );
	}
};

const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export const MonthByMonthUtilizationTable = ({
	filters,
	showResourceUtilization,
	aggregatedResourceNumbersByMonth,
	startDate,
	endDate,
	setCsvDownloadFunction,
	groupBy,
	isMixedAllocationModeEnabled,
	allocationControlsOptions,
}) => {
	const year = startDate.year();

	const getTotalsData = () => {
		return {
			rows: [
				{
					aggregatedResourceNumbersByMonth: getAggregatedResourceNumbersByMonth(aggregatedResourceNumbersByMonth),
				},
			],
		};
	};

	const intl = useIntl();
	const {formatMessage} = intl;
	const pageSize = 200;

	return (
		<Table>
			<Table.Header>
				<Table.HeaderColumn visible flex={1} width="EXTRA_LARGE" />
				{months.map(month => (
					<Table.HeaderColumn key={month} align="center" visible width="SMALL">
						<Table.HeaderColumn.Title>
							{`${formatMessage({id: `insights.component.list.column.${month}`})} ${year}`}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				))}
			</Table.Header>

			<Table.Rows visible initiallyExpanded={false} data={getTotalsData()} canExpand={false} hasHeader>
				{({rowData, tableColumnsProps}) => (
					<Table.Row {...tableColumnsProps} cy={'report-row'}>
						<Table.Column visible>Totals</Table.Column>
						{months.map((month, index) => (
							<Table.Column visible={true} key={month}>
								<MonthByMonthUtilizationMeter
									percentage={getUtilizationNumber(
										rowData,
										index,
										showResourceUtilization,
										isMixedAllocationModeEnabled,
										allocationControlsOptions
									)}
									theme="DARK"
								/>
							</Table.Column>
						))}
					</Table.Row>
				)}
			</Table.Rows>
			{groupBy === GROUP_BY.NONE ? (
				<MonthByMonthPersonRows
					filters={filters}
					startDate={startDate}
					endDate={endDate}
					setCsvDownloadFunction={setCsvDownloadFunction}
					pageSize={pageSize}
					showResourceUtilization={showResourceUtilization}
					isMixedAllocationModeEnabled={isMixedAllocationModeEnabled}
					allocationControlsOptions={allocationControlsOptions}
				/>
			) : groupBy === GROUP_BY.ROLE ? (
				<MonthByMonthRoleRows
					filters={filters}
					startDate={startDate}
					endDate={endDate}
					setCsvDownloadFunction={setCsvDownloadFunction}
					pageSize={null}
					showResourceUtilization={showResourceUtilization}
					isMixedAllocationModeEnabled={isMixedAllocationModeEnabled}
					allocationControlsOptions={allocationControlsOptions}
				/>
			) : (
				<MonthByMonthDepartmentRows
					filters={filters}
					startDate={startDate}
					endDate={endDate}
					setCsvDownloadFunction={setCsvDownloadFunction}
					pageSize={null}
					showResourceUtilization={showResourceUtilization}
					isMixedAllocationModeEnabled={isMixedAllocationModeEnabled}
					allocationControlsOptions={allocationControlsOptions}
				/>
			)}
		</Table>
	);
};
