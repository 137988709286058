import {cloneDeep} from 'lodash';
import Util from '../../../forecast-app/shared/util/util';

export const defaultOptions = (availableFeatureFlags, hasFinancials, hasBaseline, hasTimeApproval) => {
	const financialFields = [
		'BudgetType',
		'fixedPrice',
		'actuals',
		'plan',
		'remainingMoney',
		'forecastMoney',
		'actualsVPlan',
		'forecastVPlan',
		'invoice',
		'companyFinancials',
		'rateCards',
		'expenses',
	];
	const financialFieldFilter = item => {
		if (!hasFinancials) {
			return !financialFields.includes(item.name);
		}
		return true;
	};

	return [
		{
			name: 'projectId',
			checked: true,
			disabled: false,
			translationId: 'common.id',
		},
		{
			name: 'dates',
			checked: true,
			disabled: false,
			translationId: 'project_overview.project_dates',
		},
		{
			name: 'client',
			checked: false,
			disabled: false,
			translationId: 'common.client',
		},
		{
			name: 'progress_EOM',
			checked: false,
			disabled: false,
			translationId: 'insights.component.list.column.progressEndOfMonth',
		},
	]
		.concat([{name: 'progress', checked: true, disabled: false, translationId: 'common.completion', suffix: '%'}])
		.concat(
			[{name: 'win_chance', checked: true, disabled: false, translationId: 'project.win_chance', suffix: '%'}].filter(
				() => hasBaseline
			)
		)
		.concat(
			[
				{
					name: 'BudgetType',
					checked: false,
					disabled: false,
					translationId: 'new_project_modal.budget_type',
				},
				{
					name: 'fixedPrice',
					checked: true,
					disabled: false,
					translationId: 'common.fixed_price',
					nestedOptions: [
						{name: 'revenue', checked: true, disabled: false, translationId: 'project_budget.revenue'},
						{name: 'cost', checked: true, disabled: false, translationId: 'common.cost'},
						{name: 'profit', checked: true, disabled: false, translationId: 'common.profit'},
						{name: 'margin', checked: false, disabled: false, translationId: 'reports.margin', suffix: '%'},
					],
				},
				{
					name: 'periodTotals',
					checked: true,
					disabled: false,
					translationId: 'reports.period_totals',
					nestedOptions: [
						{name: 'progress', checked: true, disabled: false, translationId: 'common.completion', suffix: '%'},
					].concat(
						[
							{
								name: 'baseline',
								checked: false,
								disabled: false,
								translationId: 'baseline.baseline',
								nestedOptions: [
									{
										name: 'revenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: false, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: false, disabled: false, translationId: 'common.profit'},
									{
										name: 'margin',
										checked: false,
										disabled: false,
										translationId: 'reports.margin',
										suffix: '%',
									},
								],
							},
							{
								name: 'actuals',
								checked: true,
								disabled: false,
								translationId: 'project_budget.actuals',
								nestedOptions: [
									{
										name: 'revenue',
										checked: true,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: true, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: true, disabled: false, translationId: 'common.profit'},
									{
										name: 'margin',
										checked: false,
										disabled: false,
										translationId: 'reports.margin',
										suffix: '%',
									},
								],
							},
							{
								name: 'plan',
								checked: false,
								disabled: false,
								translationId: 'common.planned',
								nestedOptions: [
									{
										name: 'revenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: false, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: false, disabled: false, translationId: 'common.profit'},
									{
										name: 'margin',
										checked: false,
										disabled: false,
										translationId: 'reports.margin',
										suffix: '%',
									},
								],
							},
							{
								name: 'remainingMoney',
								checked: true,
								disabled: false,
								translationId: 'project_budget.remaining',
								suffix: '$',
								nestedOptions: [
									{
										name: 'revenue',
										checked: true,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: true, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: true, disabled: false, translationId: 'common.profit'},
									{
										name: 'margin',
										checked: false,
										disabled: false,
										translationId: 'reports.margin',
										suffix: '%',
									},
								],
							},
							{
								name: 'forecastMoney',
								checked: false,
								disabled: false,
								translationId: 'project_budget.forecast',
								suffix: '$',
								nestedOptions: [
									{
										name: 'revenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: false, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: false, disabled: false, translationId: 'common.profit'},
									{
										name: 'margin',
										checked: false,
										disabled: false,
										translationId: 'reports.margin',
										suffix: '%',
									},
								],
							},
							{
								name: 'actualsVPlan',
								checked: false,
								disabled: false,
								translationId: 'project_budget.actual_vs_plan',
								nestedOptions: [
									{
										name: 'revenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: false, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: false, disabled: false, translationId: 'common.profit'},
								],
							},
							{
								name: 'forecastVPlan',
								checked: false,
								disabled: false,
								translationId: 'project_budget.forecast_vs_plan',
								nestedOptions: [
									{
										name: 'revenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: false, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: false, disabled: false, translationId: 'common.profit'},
								],
							},
							{
								name: 'invoice',
								checked: false,
								disabled: false,
								translationId: 'project_section.invoice',
								nestedOptions: [
									{
										name: 'invoicedMoney',
										checked: false,
										disabled: false,
										translationId: 'reports.invoiced',
										suffix: '$',
									},
									// Not implemented
									//{name: 'invoicedTime', checked: false, disabled: false, translationId: 'reports.invoiced', suffix: 'h'},
									{
										name: 'invoicedOnFixedPrice',
										checked: false,
										disabled: false,
										translationId: 'reports.invoiced_on_fixed_price',
										suffix: '%',
									},
									{
										name: 'invoicePaid',
										checked: false,
										disabled: false,
										translationId: 'project_budget.invoice_paid',
									},
									// Not implemented
									//{name: 'uninvoiced', checked: false, disabled: false, translationId: 'project_budget.uninvoiced'}
								],
							},
							{
								name: 'projectAllocation',
								checked: false,
								disabled: false,
								translationId: 'scheduling.project_allocation',
							},
							// Not implemented
							//{name: 'allocationMargin', checked: false, disabled: false, translationId: 'reports.allocation_margin', suffix: '%'},
							{
								name: 'registeredTime',
								checked: true,
								disabled: false,
								translationId: 'reports.registered',
								suffix: 'h',
							},
							{
								name: 'remainingTime',
								checked: true,
								disabled: false,
								translationId: 'project_budget.remaining',
								suffix: 'h',
							},
							{
								name: 'forecastTime',
								checked: false,
								disabled: false,
								translationId: 'project_budget.forecast',
								suffix: 'h',
							},
							{
								name: 'scopeTime',
								checked: true,
								disabled: false,
								translationId: 'reports.scope',
								suffix: 'h',
								nestedOptions: [
									{name: 'approved', checked: false, disabled: false, translationId: 'common.approved'},
									{name: 'total', checked: true, disabled: false, translationId: 'common.total'},
								],
							},
						]
							.filter(financialFieldFilter)
							.filter(item => hasBaseline || item.name !== 'baseline')
					),
				},
				{
					name: 'projectTotals',
					checked: true,
					disabled: false,
					translationId: 'project_budget.project_totals',
					nestedOptions: [
						{name: 'progress', checked: true, disabled: false, translationId: 'common.completion', suffix: '%'},
					].concat(
						[
							{
								name: 'baseline',
								checked: false,
								disabled: false,
								translationId: 'baseline.baseline',
								nestedOptions: [
									{
										name: 'revenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: false, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: false, disabled: false, translationId: 'common.profit'},
									{
										name: 'margin',
										checked: false,
										disabled: false,
										translationId: 'reports.margin',
										suffix: '%',
									},
								],
							},
							{
								name: 'actuals',
								checked: true,
								disabled: false,
								translationId: 'project_budget.actuals',
								nestedOptions: [
									{
										name: 'revenue',
										checked: true,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: true, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: true, disabled: false, translationId: 'common.profit'},
									{
										name: 'margin',
										checked: false,
										disabled: false,
										translationId: 'reports.margin',
										suffix: '%',
									},
								],
							},
							{
								name: 'plan',
								checked: false,
								disabled: false,
								translationId: 'common.planned',
								nestedOptions: [
									{
										name: 'revenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: false, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: false, disabled: false, translationId: 'common.profit'},
									{
										name: 'margin',
										checked: false,
										disabled: false,
										translationId: 'reports.margin',
										suffix: '%',
									},
								],
							},
							{
								name: 'remainingMoney',
								checked: true,
								disabled: false,
								translationId: 'project_budget.remaining',
								suffix: '$',
								nestedOptions: [
									{
										name: 'revenue',
										checked: true,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: true, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: true, disabled: false, translationId: 'common.profit'},
									{
										name: 'margin',
										checked: false,
										disabled: false,
										translationId: 'reports.margin',
										suffix: '%',
									},
								],
							},
							{
								name: 'forecastMoney',
								checked: false,
								disabled: false,
								translationId: 'project_budget.forecast',
								suffix: '$',
								nestedOptions: [
									{
										name: 'revenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: false, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: false, disabled: false, translationId: 'common.profit'},
									{
										name: 'margin',
										checked: false,
										disabled: false,
										translationId: 'reports.margin',
										suffix: '%',
									},
								],
							},
							{
								name: 'actualsVPlan',
								checked: false,
								disabled: false,
								translationId: 'project_budget.actual_vs_plan',
								nestedOptions: [
									{
										name: 'revenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: false, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: false, disabled: false, translationId: 'common.profit'},
								],
							},
							{
								name: 'forecastVPlan',
								checked: false,
								disabled: false,
								translationId: 'project_budget.forecast_vs_plan',
								nestedOptions: [
									{
										name: 'revenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
									{name: 'cost', checked: false, disabled: false, translationId: 'common.cost'},
									{name: 'profit', checked: false, disabled: false, translationId: 'common.profit'},
								],
							},
							{
								name: 'invoice',
								checked: false,
								disabled: false,
								translationId: 'project_section.invoice',
								nestedOptions: [
									{
										name: 'invoicedMoney',
										checked: false,
										disabled: false,
										translationId: 'reports.invoiced',
										suffix: '$',
									},
									// Not implemented
									//{name: 'invoicedTime', checked: false, disabled: false, translationId: 'reports.invoiced', suffix: 'h'},
									{
										name: 'invoicedOnFixedPrice',
										checked: false,
										disabled: false,
										translationId: 'reports.invoiced_on_fixed_price',
										suffix: '%',
									},
									{
										name: 'invoicePaid',
										checked: false,
										disabled: false,
										translationId: 'project_budget.invoice_paid',
									},
									// Not implemented
									//{name: 'uninvoiced', checked: false, disabled: false, translationId: 'project_budget.uninvoiced'}
								],
							},
							{
								name: 'future_progress',
								checked: false,
								disabled: false,
								translationId: 'insights.component.list.column.progressFutureArray',
							},
							{
								name: 'projectAllocation',
								checked: false,
								disabled: false,
								translationId: 'scheduling.project_allocation',
							},
							// Not implemented
							//{name: 'allocationMargin', checked: false, disabled: false, translationId: 'reports.allocation_margin', suffix: '%'},
							{
								name: 'registeredTime',
								checked: true,
								disabled: false,
								translationId: 'reports.registered',
								suffix: 'h',
							},
							{
								name: 'remainingTime',
								checked: true,
								disabled: false,
								translationId: 'project_budget.remaining',
								suffix: 'h',
							},
							{
								name: 'forecastTime',
								checked: false,
								disabled: false,
								translationId: 'project_budget.forecast',
								suffix: 'h',
							},
							{
								name: 'scopeTime',
								checked: true,
								disabled: false,
								translationId: 'reports.scope',
								suffix: 'h',
								nestedOptions: [
									{name: 'approved', checked: false, disabled: false, translationId: 'common.approved'},
									{name: 'total', checked: true, disabled: false, translationId: 'common.total'},
								],
							},
						]
							.filter(financialFieldFilter)
							.filter(item => hasBaseline || item.name !== 'baseline')
					),
				},
				{
					name: 'companyFinancials',
					checked: true,
					disabled: false,
					translationId: 'project_budget.projected_company_financials',
					nestedOptions: [
						{name: 'revenue', checked: true, disabled: false, translationId: 'project_budget.revenue'},
						{name: 'cost', checked: true, disabled: false, translationId: 'common.cost'},
						{name: 'profit', checked: true, disabled: false, translationId: 'common.profit'},
						{name: 'margin', checked: true, disabled: false, translationId: 'reports.margin', suffix: '%'},
					].filter(item => hasBaseline || item.name !== 'win_chance'),
				},
				{
					name: 'status',
					checked: false,
					disabled: false,
					translationId: 'common.status',
				},
				{
					name: 'projectContact',
					checked: false,
					disabled: false,
					translationId: 'common.project_owner',
				},
				{
					name: 'stage',
					checked: false,
					disabled: false,
					translationId: 'insights.component.list.column.status',
				},
				{
					name: 'Labels',
					checked: false,
					disabled: false,
					translationId: 'common.labels',
				},
				{
					name: 'rateCards',
					checked: false,
					disabled: false,
					translationId: 'settings.rate-cards',
				},
				{
					name: 'projectBreakdown',
					checked: true,
					disabled: false,
					translationId: 'common.project_breakdown',
					nestedOptions: [
						{name: 'phases', checked: true, disabled: false, translationId: 'common.phases'},
						{name: 'roles', checked: true, disabled: false, translationId: 'settings.roles'},
						{name: 'expenses', checked: true, disabled: false, translationId: 'project_budget.expenses'},
					].filter(financialFieldFilter),
				},
				{
					name: 'connectedProjects',
					checked: true,
					disabled: false,
					translationId: 'common.connected_projects',
				},
				// {
				// 	name: 'description',
				// 	checked: false,
				// 	disabled: false,
				// 	translationId: 'common.description'
				// },
			].filter(financialFieldFilter)
		);
};

const optionVisitor = (option, callback, parentPath) => {
	const path = parentPath ? `${parentPath}.${option.name}` : option.name;

	callback(option, path);
	if (option.nestedOptions) {
		option.nestedOptions.forEach(nestedOption => {
			optionVisitor(nestedOption, callback, path);
		});
	}
};

const optionTraverser = (options, callback) => {
	options.forEach(option => {
		optionVisitor(option, callback, '');
	});
};

export const getInitialOptions = (availableFeatureFlags, hasFinancials, hasBaseline, savedReport, hasTimeApproval) => {
	const options = cloneDeep(
		savedReport
			? JSON.parse(savedReport.eyeApplied)
			: defaultOptions(availableFeatureFlags, hasFinancials, hasBaseline, hasTimeApproval)
	);

	const locallySavedOptionsString = localStorage.getItem('financial-budget-report-options');
	if (locallySavedOptionsString) {
		const savedOptionsValues = JSON.parse(locallySavedOptionsString);
		if (typeof savedOptionsValues === 'object' && !Array.isArray(savedOptionsValues)) {
			optionTraverser(options, (option, path) => {
				if (savedOptionsValues[path] !== undefined) {
					option.checked = savedOptionsValues[path];
				}
			});
		}
	}

	return options;
};

export const handleChangedOptions = (changedOptions, savedReport) => {
	const savedOptionValues = {};
	optionTraverser(changedOptions, (option, path) => {
		savedOptionValues[path] = option.checked;
	});

	!savedReport && Util.localStorageSetItem('financial-budget-report-options', JSON.stringify(savedOptionValues));
	return changedOptions;
};
