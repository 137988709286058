import React, {useEffect, useRef, useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {graphql, createRefetchContainer} from 'react-relay';
import {getTimeRegRow} from '../rows/time_reg_row';
import BaseLevelWrapper from './BaselevelWrapper';
import {Table} from 'web-components';

const timeRegRelayWrapper = ({
	viewer,
	intl,
	relay,
	tableHeader,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
	searchQuery,
}) => {
	const timeRegs = viewer.company.timeRegistrations.edges;

	const currency = viewer.company.currency;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const didMount = useRef(false);
	const hasNextPage = viewer.company.timeRegistrations.pageInfo.hasNextPage;

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = timeRegs.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	useEffect(() => {
		if (!didMount.current) {
			didMount.current = true;
		} else if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	}, [sortValue]);

	const dataRows = timeRegs.map((timeReg, index) => {
		return timeReg.node;
	});
	const data = {rows: dataRows};

	const timeRegRows = () => {
		return (
			<Table.Rows
				data={data}
				canExpand={false}
				autoload={hasNextPage && groupIndex === 1}
				loadMore={hasNextPage ? fetchMore : false}
				{...nextLevelProps}
			>
				{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
					return (
						<Table.Row {...tableColumnsProps}>
							{getTimeRegRow(currency, intl, rowData, enabledColumns, fetchMore)}
						</Table.Row>
					);
				}}
			</Table.Rows>
		);
	};

	return groupIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={timeRegRows}
			currentLength={timeRegs.length}
			hasNextPage={viewer.company.timeRegistrations.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		timeRegRows()
	);
};

const timeRegRelayWrapperQuery = graphql`
	query timeRegRelayWrapperQuery_Query(
		$pageSize: Int
		$searchQuery: TaskSearchQueryType
		$sortValue: String
		$validateAccess: Boolean
	) {
		viewer {
			actualPersonId
			component(name: "time_reg_relay_wrapper")
			...timeRegRelayWrapper_viewer
				@arguments(
					pageSize: $pageSize
					searchQuery: $searchQuery
					sortValue: $sortValue
					validateAccess: $validateAccess
				)
		}
	}
`;

export {timeRegRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			timeRegRelayWrapper,
			{
				viewer: graphql`
					fragment timeRegRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						sortValue: {type: "String"}
						validateAccess: {type: "Boolean"}
					) {
						company {
							currency
							timeRegistrations(
								first: $pageSize
								searchQuery: $searchQuery
								sortValue: $sortValue
								validateAccess: false
							) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										day
										month
										year
										notes
										billable
										invoiced
										approvalStatus
										person {
											id
											fullName
											firstName
											lastName
											profilePictureId
											department {
												name
											}
											role {
												name
											}
										}
										minutesRegistered
										task {
											id
											name
											role {
												id
												name
											}
											phase {
												name
											}
											project {
												id
												name
												projectColor
												companyProjectId
												readOnlyAccess
												fullAccessToProject
												client {
													name
												}
												projectPersons {
													edges {
														node {
															role {
																name
															}
															person {
																fullName
																role {
																	name
																}
															}
														}
													}
												}
											}
										}
										project {
											id
											name
											projectColor
											companyProjectId
											readOnlyAccess
											fullAccessToProject
											client {
												name
											}
											projectPersons {
												edges {
													node {
														role {
															name
														}
														person {
															fullName
															role {
																name
															}
														}
													}
												}
											}
										}
										idleTime {
											isInternalTime
											name
										}
										priceBaseCurrency
										cost
										customFieldValues {
											edges {
												node {
													id
													key
													value
													displayName
												}
											}
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query timeRegRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$sortValue: String
					$validateAccess: Boolean
				) {
					viewer {
						...timeRegRelayWrapper_viewer
							@arguments(
								pageSize: $pageSize
								searchQuery: $searchQuery
								sortValue: $sortValue
								validateAccess: $validateAccess
							)
					}
				}
			`
		)
	)
);
