import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {getFiltersAlphabetically} from '../../../forecast-app/shared/components/filters/filter_util';
import {DATE_PICKER_STYLE, ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE, HIDDEN_FEATURES} from '../../../constants';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/FilterWrapper';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {GROUP_BY, UTILIZATION_DROPDOWN, UTILIZATION_PAGES} from './UtilizationReport';
import * as tracking from '../../../tracking';
import {getPeriodEyeOptions} from './Period';
import Util from '../../../forecast-app/shared/util/util';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {isFeatureHidden} from '../../../forecast-app/shared/util/CompanySetupUtil';
import {PRESET_DATE_RANGE_PICKER_OPTIONS} from '../../../forecast-app/shared/components/date-picker/preset_date_range_picker';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {useWindowSize} from '../../../forecast-app/shared/hooks/useWindowSize';

export const HeaderBar = React.memo(
	({
		viewer,
		activePage,
		showResourceUtilization,
		handleSetFirstDropdownValue,
		groupBy,
		handleSetSecondDropdownValue,
		handlePresetDateRangeChange,
		initialPresetDateRange,
		showDatePicker,
		onChangeDates,
		selectedDates,
		selectedYear,
		onChangeYear,
		handleCsvDownload,
		getBottomReportHeader,
		hideSoft,
		onToggleHideSoft,
		hideHard,
		onToggleHideHard,
		calcWin,
		onToggleCalcWin,
		isReportService,
		theEyeOptionsNestedTree,
		handleSetFilterValue,
	}) => {
		const intl = useIntl();
		const getHeaderLeftContent = () => {
			const content = [];
			const utilizationNumberOptions = [
				{
					value: false,
					label: intl.formatMessage({id: 'common.billable_utilization'}),
				},
				{
					value: true,
					label: intl.formatMessage({id: 'common.resource_utilization'}),
				},
			];

			if (!isFeatureHidden(HIDDEN_FEATURES.REVENUE)) {
				const getContextDropdownValueToUpdate = showResourceUtilization => {
					return showResourceUtilization
						? UTILIZATION_DROPDOWN.RESOURCE_UTILIZATION
						: UTILIZATION_DROPDOWN.BILLABLE_UTILIZATION;
				};

				content.push({
					type: ELEMENT_TYPE.LEFT_DROPDOWN,
					dropdownOptions: utilizationNumberOptions,
					value: showResourceUtilization,
					callback: selectedUtilizationNumberOption => {
						tracking.trackElementClicked('Utilization type changed', {
							utilization_type: selectedUtilizationNumberOption.value
								? intl.formatMessage({id: 'common.resource_utilization'})
								: intl.formatMessage({id: 'common.billable_utilization'}),
						});
						trackEvent('Utilization Type', 'Changed', {
							utilizationType: selectedUtilizationNumberOption.value
								? intl.formatMessage({id: 'common.resource_utilization'})
								: intl.formatMessage({id: 'common.billable_utilization'}),
						});
						handleSetFirstDropdownValue(getContextDropdownValueToUpdate(selectedUtilizationNumberOption.value));
					},
					placeholder: '',
					userpilot: 'utilization-number-dropdown',
				});
			}

			if (activePage === UTILIZATION_PAGES.PERIOD) {
				if (showDatePicker) {
					content.push({
						type: ELEMENT_TYPE.DATE_PICKER,
						isNewDateRange: true,
						startDate: selectedDates.startDate,
						endDate: selectedDates.endDate,
						handleDateRangeChange: onChangeDates,
						colorInherited: true,
						compactShowYear: true,
						datePickerStyle: DATE_PICKER_STYLE.SLIM,
						calendarOffsetX: 0,
						clearable: false,
						userpilot: 'utilization-date-picker',
						cy: 'ut-date-picker',
					});
				}
			} else {
				content.push({
					type: ELEMENT_TYPE.YEAR_STEPPER,
					startYear: selectedYear,
					onYearChanged: onChangeYear,
				});
			}

			if (activePage === UTILIZATION_PAGES.PERIOD) {
				content.push({
					type: ELEMENT_TYPE.PRESET_DATE_RANGE_PICKER,
					handleDateRangeChange: handlePresetDateRangeChange,
					initialPresetDateRange: initialPresetDateRange,
					options: [
						PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME,
						PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_MONTH,
						PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_MONTH,
						PRESET_DATE_RANGE_PICKER_OPTIONS.NEXT_MONTH,
						PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_YEAR,
						PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_YEAR,
						PRESET_DATE_RANGE_PICKER_OPTIONS.YEAR_TO_DATE,
						PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE,
					],
					userpilot: 'utilization-preset-date-picker',
				});
			}

			return content;
		};

		const getHeaderRightContent = () => {
			const peopleFilters = [
				FILTER_TYPE.PROJECT,
				FILTER_TYPE.PERSON,
				FILTER_TYPE.INTERNAL_TIME,
				FILTER_TYPE.TIME_OFF,
				FILTER_TYPE.ROLE,
				FILTER_TYPE.TEAM,
				FILTER_TYPE.PROJECT_STAGE,
				FILTER_TYPE.LABEL,
			];

			if (Util.hasSkills()) {
				peopleFilters.push(FILTER_TYPE.SKILL);
			}

			if (Util.hasDepartments(viewer.company.modules)) {
				peopleFilters.push(FILTER_TYPE.DEPARTMENT);
			}
			const projectFilters = hasFeatureFlag('ut_report_align_filters')
				? [FILTER_TYPE.INTERNAL_TIME, FILTER_TYPE.PROJECT, FILTER_TYPE.PROJECT_STAGE, FILTER_TYPE.TIME_OFF]
				: [];
			const taskFilters = [];
			const filterProps = {
				defaultSection: FILTER_SECTIONS.PEOPLE,
				projectFilters,
				peopleFilters: getFiltersAlphabetically(peopleFilters, intl.formatMessage),
				taskFilters,
				appliedFiltersName: `filters-utilization-report`,
				filterSection: FILTER_SECTION.UTILIZATION_REPORT,

				primaryFilters: {
					[FILTER_SECTIONS.PEOPLE]: [
						FILTER_TYPE.PERSON,
						FILTER_TYPE.PROJECT,
						FILTER_TYPE.ROLE,
						FILTER_TYPE.DEPARTMENT,
						FILTER_TYPE.TEAM,
					],
				},
				viewer,
				operatorOptions: {
					allowExclude: hasFeatureFlag('unified_filters_reports'),
					allowRequireAll: hasFeatureFlag('unified_filters_reports'),
				},
			};

			const additionalProps = {filterProps: filterProps};

			if (hasFeatureFlag('reports_redesign') && isReportService) {
				additionalProps.onFiltersChange = handleSetFilterValue;
			}

			const isUsingAllocations =
				viewer.company.isUsingProjectAllocation || Util.isMixedAllocationModeEnabled(viewer.company);

			if (activePage === UTILIZATION_PAGES.PERIOD) {
				additionalProps.eyeProps = {
					eyeOptions: hasFeatureFlag('reports_redesign')
						? theEyeOptionsNestedTree
						: getPeriodEyeOptions(
								isUsingAllocations,
								viewer.company.isUsingSchedulingPlanMode,
								Util.isMixedAllocationModeEnabled(viewer.company)
						  ),
				};
			}

			const content = [];

			const filteredHeaderElements = getBottomReportHeader(additionalProps);

			content.push(...filteredHeaderElements);

			const groupingOptions = [
				{value: GROUP_BY.NONE, label: intl.formatMessage({id: 'common.no_grouping'})},
				{value: GROUP_BY.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
			];
			if (Util.hasDepartments(viewer.company.modules)) {
				groupingOptions.push({
					value: GROUP_BY.DEPARTMENT,
					label: intl.formatMessage({id: 'common.group_by_department'}),
				});
			}
			content.push({
				type: ELEMENT_TYPE.DROPDOWN,
				dropdownOptions: groupingOptions,
				value: groupBy,
				callback: selectedGroupingOption => {
					trackEvent('Grouping', 'Changed', {value: selectedGroupingOption?.value});
					handleSetSecondDropdownValue(selectedGroupingOption.value);
				},
				buttonCy: 'grouping-dropdown',
				listDataCy: 'grouping-dropdown-item',
			});

			content.push({
				type: ELEMENT_TYPE.CSV,
				callback: handleCsvDownload,
			});

			if (hasFeatureFlag('placeholders') && isUsingAllocations) {
				if (hasFeatureFlag('capacity_planning_beta_2_improvements')) {
					content.push({
						type: ELEMENT_TYPE.WIN_PROBABILITY,
						calcWin: calcWin,
						onToggleCalcWin: onToggleCalcWin,
						cy: 'utilization-win-probability',
					});

					content.push({
						type: ELEMENT_TYPE.ALLOCATION_CONTROLS,
						hideSoft: hideSoft,
						onToggleHideSoft: onToggleHideSoft,
						hideHard: hideHard,
						onToggleHideHard: onToggleHideHard,
						cy: 'utilization-allocation-controls',
					});
				} else {
					content.push({
						type: ELEMENT_TYPE.ALLOCATION_CONTROLS,
						hideSoft: hideSoft,
						onToggleHideSoft: onToggleHideSoft,
						hideHard: hideHard,
						onToggleHideHard: onToggleHideHard,
						calcWin: calcWin,
						onToggleCalcWin: onToggleCalcWin,
						cy: 'utilization-allocation-controls',
					});
				}
			}

			return content;
		};

		const size = useWindowSize();

		if (size?.width && size?.width < 1850) {
			return (
				<>
					{buildHeaderBar(getHeaderLeftContent(), [], {
						noPadding: true,
						tlnPadding: false,
						excludeBottomPadding: true,
					})}
					{buildHeaderBar([], getHeaderRightContent(), {noPadding: true, tlnPadding: false})}
				</>
			);
		}

		return buildHeaderBar(getHeaderLeftContent(), getHeaderRightContent(), {noPadding: true, tlnPadding: false});
	}
);

HeaderBar.propTypes = {
	activePage: PropTypes.string.isRequired,
};
