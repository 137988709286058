import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import UpdateRetainerPeriodBulkMutation from '../../../mutations/update_retainer_period_bulk_mutation';
import DeleteRetainerPeriodBulkMutation from '../../../mutations/delete_retainer_period_bulk_mutation';
import UpdateRetainerPeriodMutation from '../../../mutations/update_retainer_period_mutation';
import UpdateTimeRegistrationRetainerConflict from '../../../mutations/update_time_registration_retainer_conflict_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Util from '../../../forecast-app/shared/util/util';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE, PERIOD_BUDGET_TYPE} from '../../../constants';
import {
	addPeriodIdToTimeReg,
	getRevertableRolloversFromPeriods,
	getRolloversFromPeriods,
	getTimeRegsForPeriod,
	handleCsvDownloadPress,
} from './RetainerPeriodUtil';
import {hasTopDownProgramBudgetFeature} from '../../../forecast-app/shared/util/ProgramFinancialLogic';
import {dispatch, EVENT_ID} from '../../../containers/event_manager';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

export const bulkChangePeriodTarget = ({periods, project, currency, programBudgetInfo, resetSelectedPeriods, intl}) => {
	const {formatMessage} = intl;
	const lastPeriod = periods[periods.length - 1];
	const hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');

	const handleConfirm = value => {
		if (value || value === 0) {
			const onSuccess = () => {
				const oldTargetTotal = periods.reduce((total, period) => total + period.periodPriceAmountt, 0);
				const newTargetTotal = value * periods.length;
				if (project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE && oldTargetTotal !== newTargetTotal) {
					trackEvent('Retainer Price Target', 'Changed', {increased: oldTargetTotal < newTargetTotal});
				}
				if (hasFinancialServiceFlag) {
					dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
				}
				createToast({duration: 5000, message: formatMessage({id: 'retainer.period_target_updated'})});
				resetSelectedPeriods();
			};
			const mutationObject = {
				ids: periods.map(period => period.id),
			};
			if (project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE) {
				mutationObject.periodPriceAmount = +value;
			} else {
				mutationObject.periodHoursAmount = +value;
			}
			Util.CommitMutation(UpdateRetainerPeriodBulkMutation, mutationObject, onSuccess);
		}
	};
	if (hasTopDownProgramBudgetFeature()) {
		if (programBudgetInfo.isInFixedPriceProgram && !programBudgetInfo.canManageProgram) {
			showModal({
				type: MODAL_TYPE.NOT_PERMITTED_PROJECT_BUDGET_CHANGE,
				programName: programBudgetInfo.programName,
				isInFixedPriceProgram: programBudgetInfo.isInFixedPriceProgram,
				messageTranslationTag: 'program.retainer.not_permitted_modal_message',
			});
			trackEvent('Retainer Change Period Target Button', 'Clicked', {error: 'Not permitted'});
			return;
		} else {
			trackEvent('Retainer Change Period Target Button', 'Clicked');
		}

		showModal({
			type: MODAL_TYPE.CHANGE_PERIOD,
			currency: currency,
			periodBudgetType: project.defaultPeriodBudgetType,
			programBudgetInfo,
			periodTarget:
				project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE
					? lastPeriod.periodPriceAmount
					: Util.convertMinutesToFullHour(lastPeriod.periodHoursAmount * 60, intl),
			onChangePeriodTarget: handleConfirm,
			periods: periods,
			project: project,
		});
	} else {
		showModal({
			type: MODAL_TYPE.USER_INPUT,
			titleText: formatMessage({id: 'retainer.change_target'}),
			subHeaderText: periods.length === 1 ? periods[0].name : undefined,
			inputType: 'number',
			confirmText: formatMessage({id: 'common.update'}),
			currency: project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE ? currency : null,
			focusOnMount: true,
			placeholder:
				project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE
					? lastPeriod.periodPriceAmount
					: Util.convertMinutesToFullHour(lastPeriod.periodHoursAmount * 60, intl),
			handleConfirm: handleConfirm,
			hoursInputType: project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS,
			disableOnInvalidInput: true,
		});
	}
};

export const bulkCreateInvoices = ({periods, project, currency, onStay}) => {
	const projectPeriodIds = periods.map(period => period.id);

	showModal({
		type: MODAL_TYPE.RETAINER_BULK_INVOICE_CREATE,
		projectId: project.id,
		projectPeriodIds: projectPeriodIds,
		currency: currency,
		onStay: onStay,
	});
};

export const bulkLockPeriod = ({periods, project, resetSelectedPeriods, intl}) => {
	const {formatMessage} = intl;
	const hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');
	const timeRegs = project.timeRegistrations.edges.filter(timeReg => (timeReg.node.task ? timeReg.node.task.billable : true));
	const inHours =
		project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS ||
		project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS;
	const rollOverByPeriodId = new Map();
	periods.forEach(period => {
		let periodRollover = null;
		const timeRegsForPeriod = getTimeRegsForPeriod(timeRegs, period);
		if (inHours) {
			const totalTime = timeRegsForPeriod.reduce((acc, timeReg) => acc + timeReg.node.billableMinutesRegistered / 60, 0);
			periodRollover =
				period.periodDifferenceHoursAmount +
				period.periodHoursAmount -
				totalTime +
				period.sharedPeriodDifferenceHoursAmount;
		} else {
			const totalCost = timeRegsForPeriod.reduce((acc, timeReg) => acc + timeReg.node.price, 0);
			periodRollover =
				period.periodDifferencePriceAmount +
				period.periodPriceAmount -
				totalCost +
				period.sharedPeriodDifferencePriceAmount;
		}
		rollOverByPeriodId.set(period.id, periodRollover);
	});
	const hasRollOver = periods.some(period => rollOverByPeriodId.has(period.id) && rollOverByPeriodId.get(period.id) !== 0);

	const callbackPositive = () => {
		periods.forEach(period => {
			if (!period.locked) {
				const periodRollover = rollOverByPeriodId.get(period.id) || 0;
				const mutationObject = {
					id: period.id,
					periodLocked: true,
				};
				if (project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS) {
					mutationObject.ignoredRolloverHours = periodRollover;
				} else if (project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE) {
					mutationObject.ignoredRolloverPrice = periodRollover;
				}

				const onSuccess = () => {
					if (hasFinancialServiceFlag) {
						dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
					}
					trackEvent('Retainer Period', 'Locked', {invoiced: period.invoiced});
				};
				Util.CommitMutation(UpdateRetainerPeriodMutation, mutationObject, onSuccess);
			}
		});
		trackEvent('Bulk Retainer Periods', 'Locked', {count: periods.length});
		createToast({duration: 5000, message: 'Periods Locked'});
		resetSelectedPeriods();
	};

	if (project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS || !hasRollOver) {
		callbackPositive();
	} else {
		const hasNegativeRollOver = periods.some(
			period => rollOverByPeriodId.has(period.id) && rollOverByPeriodId.get(period.id) < 0
		);
		showModal({
			type: MODAL_TYPE.WARNING_RADIO_BUTTON_MODAL,
			//warningMessageId: 'common.warning',
			warningInformation: [
				hasNegativeRollOver ? (
					<FormattedHTMLMessage id={'retainer.warning.lock_period_subtract'} />
				) : (
					<FormattedHTMLMessage id={'retainer.warning.lock_period_roll'} />
				),
			],
			callbackPositive: callbackPositive,
			selectedOptionKey: 'ignore',
			radioOptions: [
				{
					text: hasNegativeRollOver
						? formatMessage({id: 'retainer.subtract_next'})
						: formatMessage({id: 'retainer.roll_next'}),
					key: 'roll-next',
					locked: true,
				},
				{
					text: hasNegativeRollOver
						? formatMessage({id: 'retainer.subtract_split'})
						: formatMessage({id: 'retainer.roll_split'}),
					key: 'roll-split',
					locked: true,
				},
				{
					text: formatMessage({id: 'retainer.ignore'}),
					key: 'ignore',
				},
			],
		});
	}
};

export const bulkUnlockPeriod = (project, periods, timeRegsByPeriodId, resetSelectedPeriods, currency, intl) => {
	const {formatMessage} = intl;
	const hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');
	const hasRolloverFlag = hasFeatureFlag('rollover_values_on_retainer_periods');
	let allTimeRegs = [];
	let conflictTimeRegs = [];
	const periodIds = periods.map(period => period.id);
	const rollovers = getRevertableRolloversFromPeriods(project.retainerPeriods, periodIds);
	periods.forEach(period => {
		allTimeRegs = allTimeRegs
			.concat(timeRegsByPeriodId.get(period.id).conflictTimeRegs)
			.concat(timeRegsByPeriodId.get(period.id).nonConflictTimeRegs);
		conflictTimeRegs = conflictTimeRegs.concat(timeRegsByPeriodId.get(period.id).conflictTimeRegs);
	});

	const unlockPeriodMutations = rolloverIdsToRevert => {
		const revertRollovers = !!rolloverIdsToRevert;

		const onSuccess = response => {
			trackEvent('Retainer Period', 'Unlocked', {
				revertRollovers,
				periodBudgetType: project.defaultPeriodBudgetType,
			});

			if (hasFinancialServiceFlag) {
				dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
			}
			if (response.updateRetainerPeriodBulk.errors?.length) {
				createToast({
					duration: 5000,
					message: formatMessage({id: 'retainer.unlocked_invoiced_period_error'}),
					callback: () => {
						location.reload();
					},
				});
				return;
			}
			const onRetainerUpdateSuccess = () => {
				createToast({duration: 5000, message: 'Periods Unlocked'});
				resetSelectedPeriods();
			};

			const conflictHandledTimeRegs = allTimeRegs.filter(timeReg => timeReg.retainerConflictHandled === true);
			if (conflictHandledTimeRegs.length > 0) {
				Util.CommitMutation(
					UpdateTimeRegistrationRetainerConflict,
					{
						ids: conflictHandledTimeRegs.map(timeReg => timeReg.node.id),
						retainerConflictHandled: false,
						overrideInvoiced: true,
					},
					onRetainerUpdateSuccess
				);
			} else {
				onRetainerUpdateSuccess();
			}
		};
		const callbackPositive = () => {
			Util.CommitMutation(
				UpdateRetainerPeriodBulkMutation,
				{
					ids: periods.map(period => period.id),
					periodLocked: false,
					periodLockedTime: null,
					rolloverIdsToRevert: rolloverIdsToRevert,
				},
				onSuccess
			);
		};
		if (conflictTimeRegs && conflictTimeRegs.length > 0) {
			showModal({
				type: MODAL_TYPE.WARNING,
				warningInformation: [
					'Unlocking a period will cause all conflicting time registrations to be added to the period. Do you still wish to unlock the period?',
				],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'retainer.unlock_period'}),
						callback: callbackPositive,
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
					},
				],
			});
		} else {
			callbackPositive();
		}
	};

	if (rollovers.length && hasRolloverFlag) {
		showModal({
			type: MODAL_TYPE.REVERT_ROLLOVER_MODAL,
			rollovers: rollovers,
			periodBudgetType: project.defaultPeriodBudgetType,
			currency: currency,
			unlockPeriodMutations: unlockPeriodMutations,
			isBulkUnlock: true,
		});
	} else {
		unlockPeriodMutations(null);
	}
};

export const bulkDeletePeriod = (periods, project, resetSelectedPeriods, currency, intl, retry) => {
	const {formatMessage} = intl;
	const hasFinancialServiceFlag = hasFeatureFlag('planned_values_on_retainer_periods');
	const periodIds = periods.map(period => period.id);

	const onSuccess = () => {
		resetSelectedPeriods();
		if (hasFinancialServiceFlag) {
			dispatch(EVENT_ID.RETAINER_UPDATE_PROJECT);
		} else {
			retry();
		}
		createToast({duration: 5000, message: formatMessage({id: 'retainer.period-deleted'})});
	};
	const callbackPositive = () => {
		Util.CommitMutation(DeleteRetainerPeriodBulkMutation, {ids: periodIds, projectId: project.id}, onSuccess);
	};

	const hasRolloverFlag = hasFeatureFlag('rollover_values_on_retainer_periods');
	if (hasRolloverFlag) {
		const rollovers = getRolloversFromPeriods(project.retainerPeriods, periodIds);
		showModal({
			type: MODAL_TYPE.DELETE_RETAINER_PERIOD_MODAL,
			rollovers,
			periodIds,
			projectId: project.id,
			periodBudgetType: project.defaultPeriodBudgetType,
			currency,
		});
	} else {
		showModal({
			type: MODAL_TYPE.WARNING,
			warningMessageId: 'retainer.delete_confirmation',
			warningInformation: [formatMessage({id: 'retainer.delete_confirmation.warning'})],
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					callback: callbackPositive,
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					cy: 'modal-btn-confirm-delete',
				},
			],
		});
	}
};

export const bulkCSVDownload = (periods, timeRegsByPeriodId, currency, intl) => {
	let timeRegs = [];
	periods.forEach(period => {
		timeRegs = timeRegs
			.concat(timeRegsByPeriodId.get(period.id).conflictTimeRegs.map(timeReg => addPeriodIdToTimeReg(timeReg, period)))
			.concat(
				timeRegsByPeriodId.get(period.id).nonConflictTimeRegs.map(timeReg => addPeriodIdToTimeReg(timeReg, period))
			);
	});
	handleCsvDownloadPress(timeRegs, currency, intl);
};
