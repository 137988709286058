/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type timesheetsTeam_viewer$ref: FragmentReference;
declare export opaque type timesheetsTeam_viewer$fragmentType: timesheetsTeam_viewer$ref;
export type timesheetsTeam_viewer = {|
  +component: ?string,
  +company: ?{|
    +id: string
  |},
  +$refType: timesheetsTeam_viewer$ref,
|};
export type timesheetsTeam_viewer$data = timesheetsTeam_viewer;
export type timesheetsTeam_viewer$key = {
  +$data?: timesheetsTeam_viewer$data,
  +$fragmentRefs: timesheetsTeam_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "timesheetsTeam_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "team-timesheet"
        }
      ],
      "kind": "ScalarField",
      "name": "component",
      "storageKey": "component(name:\"team-timesheet\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = 'e5034a3c27cb49e198f62a9fdd3a5b64';

module.exports = node;
