import React from 'react';
import {useIntl} from 'react-intl';
import {IconText, Table} from 'web-components';
import MonthByMonthProjectLoader, {monthByMonthProjectLoaderQuery} from './MonthByMonthProjectLoader';
import {filtersToSearchQuery, getPhaseName, getRoleName} from '../ProjectPortfolioReportUtils';
import MonthByMonthPhaseLoader, {monthByMonthPhaseLoaderQuery} from './MonthByMonthPhaseLoader';
import MonthByMonthRoleLoader, {monthByMonthRoleLoaderQuery} from './MonthByMonthRoleLoader';
import ProjectPortfolioMonthByMonthDownload, {
	projectPortfolioMonthByMonthDownloadQuery,
} from './ProjectPortfolioMonthByMonthDownload';
import {PHASE_BREAKDOWN_TYPE} from '../../../../constants';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {LoadMore} from '../../../loaders/LoadMore';
import ProjectPortfolioMonthByMonthRow from './ProjectPortfolioMonthByMonthRow';
import Util from '../../../../forecast-app/shared/util/util';
import DeprecatedProjectIndicatorJS from '../../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

const ProjectPortfolioMonthByMonthProjectRows = ({
	setCsvDownloadFunction,
	hasBaseline,
	pageSize,
	startDate,
	endDate,
	filters,
	boolEyeOptions,
	levelProps,
	timeSearchQuery,
	expenseSearchQuery,
	selectedSingleValue,
	projectGroupId,
	programId,
	clientId,
}) => {
	const intl = useIntl();

	const addedFilters = [];
	if (projectGroupId) {
		const isNoConnectedProject = isNaN(Util.getIdFromBase64String(projectGroupId));
		addedFilters.push({key: 'projectGroup', operator: 'IS', value: isNoConnectedProject ? [null] : projectGroupId});
	}
	if (programId) {
		const isNoProgram = !Util.getUUIdFromBase64String(programId).length;
		addedFilters.push({key: 'program', operator: 'IS', value: isNoProgram ? [null] : programId});
	}
	if (clientId) {
		const isNoClient = !Util.getUUIdFromBase64String(clientId).length;
		addedFilters.push({key: 'client', operator: 'IS', value: isNoClient ? [null] : clientId});
	}

	return (
		<LoadMore
			key="query-render-month-by-month-project"
			query={monthByMonthProjectLoaderQuery}
			variables={{
				searchQuery: filtersToSearchQuery(filters, addedFilters),
				timeSearchQuery: timeSearchQuery,
				expenseSearchQuery: expenseSearchQuery,
				startYear: startDate.year(),
				startMonth: startDate.month() + 1,
				startDay: startDate.date(),
				endYear: endDate.year(),
				endMonth: endDate.month() + 1,
				endDay: endDate.date(),
				pageSize: pageSize,
				selectedSingleValue: selectedSingleValue,
			}}
			loader={<MonthByMonthProjectLoader />}
		>
			{({data, loadMore}) => {
				if (!projectGroupId && !programId && !clientId) {
					setCsvDownloadFunction(() => {
						showModal({
							type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
							componentName: 'project_portfolio_month_by_month_project_csv_loader',
							query: projectPortfolioMonthByMonthDownloadQuery,
							variables: {
								searchQuery: filtersToSearchQuery(filters),
								timeSearchQuery: timeSearchQuery,
								expenseSearchQuery: expenseSearchQuery,
								startYear: startDate.year(),
								startMonth: startDate.month() + 1,
								startDay: startDate.date(),
								endYear: endDate.year(),
								endMonth: endDate.month() + 1,
								endDay: endDate.date(),
								pageSize: 1000000,
								selectedSingleValue: selectedSingleValue,
							},
							createCsvDownload: <ProjectPortfolioMonthByMonthDownload />,
							startDate,
							endDate,
							boolEyeOptions,
							selectedSingleValue,
						});
					});
				}
				return (
					<Table.Rows data={data} canExpand={true} loadMore={loadMore} autoload={true} {...levelProps}>
						{({rowData, tableColumnsProps, expanded, nextLevelProps: phaseLevelProps}) => {
							const fullAccessToProject = rowData?.project?.fullAccessToProject;
							const fixedPriceLocks = rowData?.project?.fixedPriceLocks;
							return (
								<>
									<ProjectPortfolioMonthByMonthRow
										rowData={rowData}
										tableColumnProps={tableColumnsProps}
										headerColumn={
											<IconText
												key={rowData.project.id}
												text={
													fullAccessToProject
														? rowData.project.name
														: intl.formatMessage({id: 'common.no_project_access'})
												}
											>
												<DeprecatedProjectIndicatorJS
													project={rowData.project}
													disableLink={rowData.project.readOnlyAccess}
												/>
											</IconText>
										}
										selectedSingleValue={selectedSingleValue}
										hasBaseline={hasBaseline}
										fixedPriceLocks={fixedPriceLocks}
									/>
									{expanded && (
										<LoadMore
											key="query-render-month-by-month-phase"
											query={monthByMonthPhaseLoaderQuery}
											loader={<MonthByMonthPhaseLoader />}
											variables={{
												projectId: rowData.project.id,
												timeSearchQuery: timeSearchQuery,
												expenseSearchQuery: expenseSearchQuery,
												startYear: startDate.year(),
												startMonth: startDate.month() + 1,
												startDay: startDate.date(),
												endYear: endDate.year(),
												endMonth: endDate.month() + 1,
												endDay: endDate.date(),
												selectedSingleValue: selectedSingleValue,
											}}
										>
											{({data: phaseBreakdownData}) => {
												return (
													<Table.Rows data={phaseBreakdownData} {...phaseLevelProps} canExpand={true}>
														{({
															rowData: phaseRowData,
															tableColumnsProps: phaseTableColumnProps,
															expanded: phaseExpanded,
															nextLevelProps: roleLevelProps,
														}) => {
															return (
																<>
																	<ProjectPortfolioMonthByMonthRow
																		rowData={phaseRowData}
																		tableColumnProps={phaseTableColumnProps}
																		headerColumn={getPhaseName(intl, phaseRowData)}
																		canExpand={
																			phaseRowData.breakdownType ===
																				PHASE_BREAKDOWN_TYPE.PHASE ||
																			phaseRowData.breakdownType ===
																				PHASE_BREAKDOWN_TYPE.NO_PHASE
																		}
																		selectedSingleValue={selectedSingleValue}
																		fixedPriceLocks={fixedPriceLocks}
																	/>
																	{phaseExpanded && (
																		<LoadMore
																			key="query-render-month-by-month-role"
																			query={monthByMonthRoleLoaderQuery}
																			loader={<MonthByMonthRoleLoader />}
																			variables={{
																				timeSearchQuery: timeSearchQuery,
																				expenseSearchQuery: expenseSearchQuery,
																				projectId: rowData.project.id,
																				phaseId:
																					phaseRowData.breakdownType ===
																					PHASE_BREAKDOWN_TYPE.PHASE
																						? phaseRowData.phase.id
																						: phaseRowData.breakdownType ===
																						  PHASE_BREAKDOWN_TYPE.NO_PHASE
																						? 0
																						: null,
																				startYear: startDate.year(),
																				startMonth: startDate.month() + 1,
																				startDay: startDate.date(),
																				endYear: endDate.year(),
																				endMonth: endDate.month() + 1,
																				endDay: endDate.date(),
																				selectedSingleValue: selectedSingleValue,
																			}}
																		>
																			{({data: roleBreakdownData}) => {
																				return (
																					<Table.Rows
																						data={roleBreakdownData}
																						{...roleLevelProps}
																						canExpand={false}
																					>
																						{({
																							rowData: roleRowData,
																							tableColumnsProps:
																								roleTableColumnProps,
																						}) => {
																							return (
																								<ProjectPortfolioMonthByMonthRow
																									rowData={roleRowData}
																									tableColumnProps={
																										roleTableColumnProps
																									}
																									headerColumn={getRoleName(
																										intl,
																										roleRowData
																									)}
																									selectedSingleValue={
																										selectedSingleValue
																									}
																									fixedPriceLocks={
																										fixedPriceLocks
																									}
																								/>
																							);
																						}}
																					</Table.Rows>
																				);
																			}}
																		</LoadMore>
																	)}
																</>
															);
														}}
													</Table.Rows>
												);
											}}
										</LoadMore>
									)}
								</>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default ProjectPortfolioMonthByMonthProjectRows;
