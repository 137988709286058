import React from 'react';
import {Button} from 'web-components';
import {PrintIcon} from 'web-components/icons';

import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';

export const PrintMenuItem = ({onClick}) => {
	return (
		<TooltipContainer infoText="Print">
			<Button
				icon={color => <PrintIcon color={color} size={'medium'} />}
				isSquare
				iconPosition={Button.ICON_POSITION.FIRST}
				variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE}
				onClick={onClick}
			/>
		</TooltipContainer>
	);
};
