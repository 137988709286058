import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {toPlaceholderObject} from '../placeholders-scheduling/formatters/PlaceholderFormatter';

export const handleTransferToPerson = (pageComponent, placeholder, placeholderAllocation) => {
	const {staffingModeActive} = pageComponent.state;

	const placeholderInput = toPlaceholderObject(
		placeholder,
		pageComponent.state.data.placeholderSkills,
		pageComponent.state.data.roles
	);

	const transferToPerson = () => {
		showModal({
			type: MODAL_TYPE.TRANSFER_PLACEHOLDER_ALLOCATION_TO_PERSON,
			staffingModeActive,
			placeholderInput: placeholderInput,
			placeholderAllocationInput: placeholderAllocation,
		});
	};

	transferToPerson();
};
