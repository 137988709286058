import {createFragmentContainer, graphql} from 'react-relay';
import {decompressData, getTotalsForBreakdown} from '../util/DataDecompressUtil';
import {getCurrency} from '../util/BudgetUtils';

const DetailsExpenseCategoryLoader = ({relay, viewer, children, aggregateLevel, aggregate, ...props}) => {
	const {aggregatedExpenseCategoryFinancialBreakdown, expenseCategoryFinancialBreakdown} = viewer.project;
	const currency = getCurrency(viewer.company, viewer.project);
	const rows = [];

	const totalsBreakdown = processTotals(expenseCategoryFinancialBreakdown);

	for (const breakdownType of Object.values(aggregatedExpenseCategoryFinancialBreakdown)) {
		const financialNumberTotals = getTotalsForBreakdown(
			totalsBreakdown,
			breakdownType.expenseCategory ? breakdownType.expenseCategory.id : breakdownType.breakdownType
		);
		const processedData = decompressData(
			breakdownType.aggregatedFinancialNumbers,
			financialNumberTotals,
			currency,
			aggregateLevel,
			[aggregate]
		);
		const row = {
			...processedData.rows[0],
			breakdownType: breakdownType.breakdownType,
			expenseCategory: breakdownType.expenseCategory,
		};
		rows.push(row);
	}

	const result = {data: {rows: [...rows]}, ...props};
	return children(result);
};

function processTotals(financialBreakdown) {
	const result = [];
	for (const totals of Object.values(financialBreakdown)) {
		const o = {...totals};
		o['lookupId'] = totals.expenseCategory ? totals.expenseCategory.id : totals.breakdownType;
		result.push(o);
	}
	return result;
}

export const detailsExpenseLoaderQuery = graphql`
	query DetailsExpenseCategoryLoader_Query(
		$projectId: ID!
		$phaseId: ID!
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
		$aggregateLevel: AggregateLevel!
		$aggregate: FinancialAggregatedNumber!
		$searchQuery: TaskSearchQueryType
		$baselineRevenue: Boolean!
		$baselineCost: Boolean!
		$baselineProfit: Boolean!
		$billableActualTimeAndExpenses: Boolean!
		$actualCost: Boolean!
		$actualProfit: Boolean!
		$billablePlannedTimeAndExpenses: Boolean!
		$plannedCost: Boolean!
		$plannedProfit: Boolean!
		$billableForecastTimeAndExpensesToComplete: Boolean!
		$forecastCostToComplete: Boolean!
		$forecastProfitToComplete: Boolean!
		$billableTotalTimeAndExpensesAtCompletion: Boolean!
		$totalCostAtCompletion: Boolean!
		$totalProfitAtCompletion: Boolean!
		$planVsBillableActualTimeAndExpenses: Boolean!
		$planVsActualCost: Boolean!
		$planVsActualProfit: Boolean!
		$planVsTotalBillableTimeAndExpensesAtCompletion: Boolean!
		$planVsTotalCostAtCompletion: Boolean!
		$planVsTotalProfitAtCompletion: Boolean!
		$baselineVsBillableActualTimeAndExpenses: Boolean!
		$baselineVsActualCost: Boolean!
		$baselineVsActualProfit: Boolean!
		$baselineVsBillableTotalTimeAndExpensesAtCompletion: Boolean!
		$baselineVsTotalCostAtCompletion: Boolean!
		$baselineVsTotalProfitAtCompletion: Boolean!
		$invoiced: Boolean!
		$paid: Boolean!
		$baselineMinutes: Boolean!
		$baselineVsRegisteredMinutes: Boolean!
		$baselineVsTotalTimeAtCompletion: Boolean!
		$registeredMinutes: Boolean!
		$scopeApprovedMinutes: Boolean!
		$registeredVsScopeApprovedMinutes: Boolean!
		$scopeApprovedVsRegisteredMinutes: Boolean!
		$forecastTimeToComplete: Boolean!
		$totalTimeAtCompletion: Boolean!
		$totalTimeAtCompletionVsScopeApprovedMinutes: Boolean!
		$scopeApprovedVsTotalTimeAtCompletion: Boolean!
	) {
		viewer {
			actualPersonId
			component(name: "details_expense_loader")
			...DetailsExpenseCategoryLoader_viewer
				@arguments(
					projectId: $projectId
					phaseId: $phaseId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregate: $aggregate
					searchQuery: $searchQuery
					baselineRevenue: $baselineRevenue
					baselineCost: $baselineCost
					baselineProfit: $baselineProfit
					billableActualTimeAndExpenses: $billableActualTimeAndExpenses
					actualCost: $actualCost
					actualProfit: $actualProfit
					billablePlannedTimeAndExpenses: $billablePlannedTimeAndExpenses
					plannedCost: $plannedCost
					plannedProfit: $plannedProfit
					billableForecastTimeAndExpensesToComplete: $billableForecastTimeAndExpensesToComplete
					forecastCostToComplete: $forecastCostToComplete
					forecastProfitToComplete: $forecastProfitToComplete
					billableTotalTimeAndExpensesAtCompletion: $billableTotalTimeAndExpensesAtCompletion
					totalCostAtCompletion: $totalCostAtCompletion
					totalProfitAtCompletion: $totalProfitAtCompletion
					planVsBillableActualTimeAndExpenses: $planVsBillableActualTimeAndExpenses
					planVsActualCost: $planVsActualCost
					planVsActualProfit: $planVsActualProfit
					planVsTotalBillableTimeAndExpensesAtCompletion: $planVsTotalBillableTimeAndExpensesAtCompletion
					planVsTotalCostAtCompletion: $planVsTotalCostAtCompletion
					planVsTotalProfitAtCompletion: $planVsTotalProfitAtCompletion
					baselineVsBillableActualTimeAndExpenses: $baselineVsBillableActualTimeAndExpenses
					baselineVsActualCost: $baselineVsActualCost
					baselineVsActualProfit: $baselineVsActualProfit
					baselineVsBillableTotalTimeAndExpensesAtCompletion: $baselineVsBillableTotalTimeAndExpensesAtCompletion
					baselineVsTotalCostAtCompletion: $baselineVsTotalCostAtCompletion
					baselineVsTotalProfitAtCompletion: $baselineVsTotalProfitAtCompletion
					invoiced: $invoiced
					paid: $paid
					baselineMinutes: $baselineMinutes
					baselineVsRegisteredMinutes: $baselineVsRegisteredMinutes
					baselineVsTotalTimeAtCompletion: $baselineVsTotalTimeAtCompletion
					registeredMinutes: $registeredMinutes
					scopeApprovedMinutes: $scopeApprovedMinutes
					registeredVsScopeApprovedMinutes: $registeredVsScopeApprovedMinutes
					scopeApprovedVsRegisteredMinutes: $scopeApprovedVsRegisteredMinutes
					forecastTimeToComplete: $forecastTimeToComplete
					totalTimeAtCompletion: $totalTimeAtCompletion
					totalTimeAtCompletionVsScopeApprovedMinutes: $totalTimeAtCompletionVsScopeApprovedMinutes
					scopeApprovedVsTotalTimeAtCompletion: $scopeApprovedVsTotalTimeAtCompletion
				)
		}
	}
`;

export default createFragmentContainer(DetailsExpenseCategoryLoader, {
	viewer: graphql`
		fragment DetailsExpenseCategoryLoader_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID!"}
			phaseId: {type: "ID!"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
			aggregateLevel: {type: "AggregateLevel!"}
			aggregate: {type: "FinancialAggregatedNumber!"}
			searchQuery: {type: "TaskSearchQueryType"}

			baselineRevenue: {type: "Boolean!"}
			baselineCost: {type: "Boolean!"}
			baselineProfit: {type: "Boolean!"}
			billableActualTimeAndExpenses: {type: "Boolean!"}
			actualCost: {type: "Boolean!"}
			actualProfit: {type: "Boolean!"}
			billablePlannedTimeAndExpenses: {type: "Boolean!"}
			plannedCost: {type: "Boolean!"}
			plannedProfit: {type: "Boolean!"}
			billableForecastTimeAndExpensesToComplete: {type: "Boolean!"}
			forecastCostToComplete: {type: "Boolean!"}
			forecastProfitToComplete: {type: "Boolean!"}
			billableTotalTimeAndExpensesAtCompletion: {type: "Boolean!"}
			totalCostAtCompletion: {type: "Boolean!"}
			totalProfitAtCompletion: {type: "Boolean!"}
			planVsBillableActualTimeAndExpenses: {type: "Boolean!"}
			planVsActualCost: {type: "Boolean!"}
			planVsActualProfit: {type: "Boolean!"}
			planVsTotalBillableTimeAndExpensesAtCompletion: {type: "Boolean!"}
			planVsTotalCostAtCompletion: {type: "Boolean!"}
			planVsTotalProfitAtCompletion: {type: "Boolean!"}
			baselineVsBillableActualTimeAndExpenses: {type: "Boolean!"}
			baselineVsActualCost: {type: "Boolean!"}
			baselineVsActualProfit: {type: "Boolean!"}
			baselineVsBillableTotalTimeAndExpensesAtCompletion: {type: "Boolean!"}
			baselineVsTotalCostAtCompletion: {type: "Boolean!"}
			baselineVsTotalProfitAtCompletion: {type: "Boolean!"}
			invoiced: {type: "Boolean!"}
			paid: {type: "Boolean!"}
			baselineMinutes: {type: "Boolean!"}
			baselineVsRegisteredMinutes: {type: "Boolean!"}
			baselineVsTotalTimeAtCompletion: {type: "Boolean!"}
			registeredMinutes: {type: "Boolean!"}
			scopeApprovedMinutes: {type: "Boolean!"}
			registeredVsScopeApprovedMinutes: {type: "Boolean!"}
			scopeApprovedVsRegisteredMinutes: {type: "Boolean!"}
			forecastTimeToComplete: {type: "Boolean!"}
			totalTimeAtCompletion: {type: "Boolean!"}
			totalTimeAtCompletionVsScopeApprovedMinutes: {type: "Boolean!"}
			scopeApprovedVsTotalTimeAtCompletion: {type: "Boolean!"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				rateCard {
					currency
				}
				expenseCategoryFinancialBreakdown(
					phaseId: $phaseId
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					convertToProjectCurrency: true
					filterRowsWithNoValues: true
				) {
					breakdownType
					expenseCategory {
						id
						name
					}
					financialNumbers {
						baselineRevenue @include(if: $baselineRevenue)
						baselineCost @include(if: $baselineCost)
						baselineProfit @include(if: $baselineProfit)
						billableActualTimeAndExpenses @include(if: $billableActualTimeAndExpenses)
						actualCost @include(if: $actualCost)
						actualProfit @include(if: $actualProfit)
						billablePlannedTimeAndExpenses @include(if: $billablePlannedTimeAndExpenses)
						plannedCost @include(if: $plannedCost)
						plannedProfit @include(if: $plannedProfit)
						billableForecastTimeAndExpensesToComplete @include(if: $billableForecastTimeAndExpensesToComplete)
						forecastCostToComplete @include(if: $forecastCostToComplete)
						forecastProfitToComplete @include(if: $forecastProfitToComplete)
						billableTotalTimeAndExpensesAtCompletion @include(if: $billableTotalTimeAndExpensesAtCompletion)
						totalCostAtCompletion @include(if: $totalCostAtCompletion)
						totalProfitAtCompletion @include(if: $totalProfitAtCompletion)
						planVsBillableActualTimeAndExpenses @include(if: $planVsBillableActualTimeAndExpenses)
						planVsActualCost @include(if: $planVsActualCost)
						planVsActualProfit @include(if: $planVsActualProfit)
						planVsTotalBillableTimeAndExpensesAtCompletion
							@include(if: $planVsTotalBillableTimeAndExpensesAtCompletion)
						planVsTotalCostAtCompletion @include(if: $planVsTotalCostAtCompletion)
						planVsTotalProfitAtCompletion @include(if: $planVsTotalProfitAtCompletion)
						baselineVsBillableActualTimeAndExpenses @include(if: $baselineVsBillableActualTimeAndExpenses)
						baselineVsActualCost @include(if: $baselineVsActualCost)
						baselineVsActualProfit @include(if: $baselineVsActualProfit)
						baselineVsBillableTotalTimeAndExpensesAtCompletion
							@include(if: $baselineVsBillableTotalTimeAndExpensesAtCompletion)
						baselineVsTotalCostAtCompletion @include(if: $baselineVsTotalCostAtCompletion)
						baselineVsTotalProfitAtCompletion @include(if: $baselineVsTotalProfitAtCompletion)
						invoiced @include(if: $invoiced)
						paid @include(if: $paid)
						baselineMinutes @include(if: $baselineMinutes)
						baselineVsRegisteredMinutes @include(if: $baselineVsRegisteredMinutes)
						baselineVsTotalTimeAtCompletion @include(if: $baselineVsTotalTimeAtCompletion)
						registeredMinutes @include(if: $registeredMinutes)
						scopeApprovedMinutes @include(if: $scopeApprovedMinutes)
						registeredVsScopeApprovedMinutes @include(if: $registeredVsScopeApprovedMinutes)
						scopeApprovedVsRegisteredMinutes @include(if: $scopeApprovedVsRegisteredMinutes)
						forecastTimeToComplete @include(if: $forecastTimeToComplete)
						totalTimeAtCompletion @include(if: $totalTimeAtCompletion)
						totalTimeAtCompletionVsScopeApprovedMinutes @include(if: $totalTimeAtCompletionVsScopeApprovedMinutes)
						scopeApprovedVsTotalTimeAtCompletion @include(if: $scopeApprovedVsTotalTimeAtCompletion)
					}
				}
				aggregatedExpenseCategoryFinancialBreakdown(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					phaseId: $phaseId
					aggregateLevel: $aggregateLevel
					aggregates: [$aggregate]
					searchQuery: $searchQuery
					convertToProjectCurrency: true
					filterRowsWithNoValues: true
				) {
					breakdownType
					expenseCategory {
						id
						name
					}
					aggregatedFinancialNumbers
				}
			}
		}
	`,
});
