import Util from '../../../../forecast-app/shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {useEffect} from 'react';
import {getAdjustedValues} from '../UtilizationReportUtils';
import DataExportFormatter from '../../../../forecast-app/shared/util/export-formatters/DataExportFormatter';

export const exportToCsv = (startDate, endDate, resourceData, boolEyeOptions, allocationControlsOptions, intl) => {
	const format = new DataExportFormatter(intl);

	const formatColumnName = name => {
		return name.replaceAll(' ', '_').toLowerCase();
	};

	const addColumn = (row, visible, name, value) => {
		if (visible) {
			row[formatColumnName(name)] = value;
		}
	};

	if (resourceData.length > 0) {
		const periodData = resourceData.map(dataRow => {
			const row = {};
			const firstColumnName = dataRow.person ? 'Person' : dataRow.role ? 'Role' : dataRow.department ? 'Department' : '';
			const firstColumnValue = dataRow.person
				? `${dataRow.person.firstName} ${dataRow.person.lastName}`
				: dataRow.role
				? dataRow.role.name
				: dataRow.department
				? dataRow.department.name
				: '';

			const {
				plannedOverMinutes,
				plannedBillableUtilization,
				plannedNonBillableUtilization,
				plannedResourceUtilization,
				plannedBillableProjectMinutes,
				plannedNonBillableProjectMinutes,
				plannedTotalMinutes,
				remainingBillableUtilization,
				remainingNonBillableUtilization,
				remainingResourceUtilization,
				remainingBillableProjectMinutes,
				remainingNonBillableProjectMinutes,
				remainingTotalMinutes,
				remainingOverMinutes,
				billableActualVsPlan,
				nonBillableActualVsPlan,
				totalActualVsPlan,
				forecastBillableProjectMinutes,
				forecastNonBillableProjectMinutes,
				forecastTotalMinutes,
				forecastOverMinutes,
				forecastBillableUtilization,
				forecastNonBillableUtilization,
				forecastResourceUtilization,
				plannedBillableAllocationVsTask,
				plannedNonBillableAllocationVsTask,
				plannedAllocationVsTask,
				remainingBillableAllocationVsTask,
				remainingNonBillableAllocationVsTask,
				remainingAllocationVsTask,
				plannedBillableTaskAndAllocationsCombined,
				plannedNonBillableTaskAndAllocationsCombined,
				plannedTotalTimeTaskAndAllocationsCombined,
				plannedBillableUtilizationTaskAndAllocationsCombined,
				plannedNonBillableUtilizationTaskAndAllocationsCombined,
				plannedResourceUtilizationTaskAndAllocationsCombined,
				remainingBillableTaskAndAllocationsCombined,
				remainingNonBillableTaskAndAllocationsCombined,
				remainingTotalTimeTaskAndAllocationsCombined,
				remainingBillableUtilizationTaskAndAllocationsCombined,
				remainingNonBillableUtilizationTaskAndAllocationsCombined,
				remainingResourceUtilizationTaskAndAllocationsCombined,
				forecastBillableTaskAndAllocationsCombined,
				forecastNonBillableTaskAndAllocationsCombined,
				forecastTotalTimeTaskAndAllocationsCombined,
				forecastBillableUtilizationTaskAndAllocationsCombined,
				forecastNonBillableUtilizationTaskAndAllocationsCombined,
				forecastResourceUtilizationTaskAndAllocationsCombined,
			} = getAdjustedValues(dataRow.resourceNumbers, allocationControlsOptions);

			// Actuals
			addColumn(row, true, firstColumnName, firstColumnValue);
			addColumn(
				row,
				boolEyeOptions.billableProjectHours,
				'Actual Billable Project Time',
				format._formatTime(dataRow.resourceNumbers.actualBillableProjectMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.nonBillableProjectHours,
				'Actual Non-billable Project Time',
				format._formatTime(dataRow.resourceNumbers.actualNonBillableProjectMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.internalHours,
				'Actual Internal Time',
				format._formatTime(dataRow.resourceNumbers.actualInternalMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.totalRegisteredHours,
				'Actual Total Time',
				format._formatTime(dataRow.resourceNumbers.actualTotalMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.overHours,
				'Actual Over Time',
				format._formatTime(dataRow.resourceNumbers.actualOverMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.actualBillableUtilization,
				'Actual Billable Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.actualBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.actualNonBillableUtilization,
				'Actual Non-billable Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.actualNonBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.actualInternalUtilization,
				'Actual Internal Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.actualInternalUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.actualResourceUtilization,
				'Actual Resource Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.actualResourceUtilization)
			);
			// Performance
			addColumn(row, boolEyeOptions.performance, 'Performance', format._formatTime(dataRow.resourceNumbers.performance));
			// Plan
			addColumn(
				row,
				boolEyeOptions.billableProjectAllocation,
				'Planned Billable Project Time',
				format._formatTime(plannedBillableProjectMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.nonBillableProjectAllocation,
				'Planned Non-billable Project Time',
				format._formatTime(plannedNonBillableProjectMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.internalAllocation,
				'Planned Internal Time',
				format._formatTime(dataRow.resourceNumbers.plannedInternalMinutes)
			);
			addColumn(row, boolEyeOptions.totalAllocation, 'Planned Total Time', format._formatTime(plannedTotalMinutes));
			addColumn(row, boolEyeOptions.overAllocation, 'Planned Over Time', format._formatTime(plannedOverMinutes));
			addColumn(
				row,
				boolEyeOptions.plannedBillableUtilization,
				'Planned Billable Utilization',
				format._formatPercentageFromFraction(plannedBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.plannedNonBillableUtilization,
				'Planned Non-billable Utilization',
				format._formatPercentageFromFraction(plannedNonBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.plannedInternalUtilization,
				'Planned Internal Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.plannedInternalUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.plannedResourceUtilization,
				'Planned Resource Utilization',
				format._formatPercentageFromFraction(plannedResourceUtilization)
			);
			// Plan Combined
			addColumn(
				row,
				boolEyeOptions.plannedCombinedBillableProjectHours,
				'Planned Billable Project Time Tasks and Allocations Combined',
				format._formatTime(plannedBillableTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.plannedCombinedNonBillableProjectHours,
				'Planned Non-billable Project Time Tasks and Allocations Combined',
				format._formatTime(plannedNonBillableTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.plannedCombinedInternalHours,
				'Planned Internal Time Tasks and Allocations Combined',
				format._formatTime(dataRow.resourceNumbers.plannedTotalTimeTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.plannedCombinedTotalRegisteredHours,
				'Planned Total Time Tasks and Allocations Combined',
				format._formatTime(plannedTotalTimeTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.plannedCombinedBillableUtilization,
				'Planned Billable Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(plannedBillableUtilizationTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.plannedCombinedNonBillableUtilization,
				'Planned Non-billable Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(plannedNonBillableUtilizationTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.plannedCombinedInternalUtilization,
				'Planned Internal Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(
					dataRow.resourceNumbers.plannedResourceUtilizationTaskAndAllocationsCombined
				)
			);
			addColumn(
				row,
				boolEyeOptions.plannedCombinedResourceUtilization,
				'Planned Resource Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(plannedResourceUtilizationTaskAndAllocationsCombined)
			);
			// Actual vs. Plan
			addColumn(
				row,
				boolEyeOptions.billableActualVsPlan,
				'Billable - Actual vs Plan',
				format._formatTime(billableActualVsPlan)
			);
			addColumn(
				row,
				boolEyeOptions.nonBillableActualVsPlan,
				'Non-billable - Actual vs Plan',
				format._formatTime(nonBillableActualVsPlan)
			);
			addColumn(
				row,
				boolEyeOptions.internalActualVsPlan,
				'Internal - Actual vs Plan',
				format._formatTime(dataRow.resourceNumbers.internalActualVsPlan)
			);
			addColumn(row, boolEyeOptions.totalActualVsPlan, 'Total - Actual vs Plan', format._formatTime(totalActualVsPlan));
			// Remaining
			addColumn(
				row,
				boolEyeOptions.billableRemaining,
				'Remaining Billable Project Time',
				format._formatTime(remainingBillableProjectMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.nonBillableRemaining,
				'Remaining Non-billable Project Time',
				format._formatTime(remainingNonBillableProjectMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.internalRemaining,
				'Remaining Internal Time',
				format._formatTime(dataRow.resourceNumbers.remainingInternalMinutes)
			);
			addColumn(row, boolEyeOptions.totalRemaining, 'Remaining Total Time', format._formatTime(remainingTotalMinutes));
			addColumn(row, boolEyeOptions.overtimeRemaining, 'Remaining Over Time', format._formatTime(remainingOverMinutes));
			addColumn(
				row,
				boolEyeOptions.remainingBillableUtilization,
				'Remaining Billable Utilization',
				format._formatPercentageFromFraction(remainingBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.remainingNonBillableUtilization,
				'Remaining Non-billable Utilization',
				format._formatPercentageFromFraction(remainingNonBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.remainingInternalUtilization,
				'Remaining Internal Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.remainingInternalUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.remainingResourceUtilization,
				'Remaining Resource Utilization',
				format._formatPercentageFromFraction(remainingResourceUtilization)
			);
			// Remaining Combined
			addColumn(
				row,
				boolEyeOptions.remainingCombinedBillableProjectHours,
				'Remaining Billable Project Time Tasks and Allocations Combined',
				format._formatTime(remainingBillableTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.remainingCombinedNonBillableProjectHours,
				'Remaining Non-billable Project Time Tasks and Allocations Combined',
				format._formatTime(remainingNonBillableTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.remainingCombinedInternalHours,
				'Remaining Internal Time Tasks and Allocations Combined',
				format._formatTime(dataRow.resourceNumbers.remainingTotalTimeTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.remainingCombinedTotalRegisteredHours,
				'Remaining Total Time Tasks and Allocations Combined',
				format._formatTime(remainingTotalTimeTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.remainingCombinedBillableUtilization,
				'Remaining Billable Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(remainingBillableUtilizationTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.remainingCombinedNonBillableUtilization,
				'Remaining Non-billable Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(remainingNonBillableUtilizationTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.remainingCombinedInternalUtilization,
				'Remaining Internal Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(
					dataRow.resourceNumbers.remainingResourceUtilizationTaskAndAllocationsCombined
				)
			);
			addColumn(
				row,
				boolEyeOptions.remainingCombinedResourceUtilization,
				'Remaining Resource Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(remainingResourceUtilizationTaskAndAllocationsCombined)
			);
			// Forecast
			addColumn(
				row,
				boolEyeOptions.billableForecast,
				'Forecasted Billable Project Time',
				format._formatTime(forecastBillableProjectMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.nonBillableForecast,
				'Forecasted Non-billable Project Time',
				format._formatTime(forecastNonBillableProjectMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.internalForecast,
				'Forecasted Internal Time',
				format._formatTime(dataRow.resourceNumbers.forecastInternalMinutes)
			);
			addColumn(row, boolEyeOptions.totalForecast, 'Forecasted Total Time', format._formatTime(forecastTotalMinutes));
			addColumn(row, boolEyeOptions.overtimeForecast, 'Forecasted Over Time', format._formatTime(forecastOverMinutes));
			addColumn(
				row,
				boolEyeOptions.forecastBillableUtilization,
				'Forecasted Billable Utilization',
				format._formatPercentageFromFraction(forecastBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.forecastNonBillableUtilization,
				'Forecasted Non-billable Utilization',
				format._formatPercentageFromFraction(forecastNonBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.forecastInternalUtilization,
				'Forecasted Internal Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.forecastInternalUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.forecastResourceUtilization,
				'Forecasted Resource Utilization',
				format._formatPercentageFromFraction(forecastResourceUtilization)
			);
			// Forecast Combined
			addColumn(
				row,
				boolEyeOptions.forecastCombinedBillableProjectHours,
				'Forecast Billable Project Time Tasks and Allocations Combined',
				format._formatTime(forecastBillableTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.forecastCombinedNonBillableProjectHours,
				'Forecast Non-billable Project Time Tasks and Allocations Combined',
				format._formatTime(forecastNonBillableTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.forecastCombinedInternalHours,
				'Forecast Internal Time Tasks and Allocations Combined',
				format._formatTime(dataRow.resourceNumbers.forecastTotalTimeTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.forecastCombinedTotalRegisteredHours,
				'Forecast Total Time Tasks and Allocations Combined',
				format._formatTime(forecastTotalTimeTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.forecastCombinedBillableUtilization,
				'Forecast Billable Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(forecastBillableUtilizationTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.forecastCombinedNonBillableUtilization,
				'Forecast Non-billable Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(forecastNonBillableUtilizationTaskAndAllocationsCombined)
			);
			addColumn(
				row,
				boolEyeOptions.forecastCombinedInternalUtilization,
				'Forecast Internal Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(
					dataRow.resourceNumbers.forecastResourceUtilizationTaskAndAllocationsCombined
				)
			);
			addColumn(
				row,
				boolEyeOptions.forecastCombinedResourceUtilization,
				'Forecast Resource Utilization Tasks and Allocations Combined',
				format._formatPercentageFromFraction(forecastResourceUtilizationTaskAndAllocationsCombined)
			);
			// Tasks estimates (Planned)
			addColumn(
				row,
				boolEyeOptions.billableProjectTasks,
				'Planned Tasks Billable Time',
				format._formatTime(dataRow.resourceNumbers.tasksEstimateBillableMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.nonBillableProjectTasks,
				'Planned Tasks Non-Billable Time',
				format._formatTime(dataRow.resourceNumbers.tasksEstimateNonBillableMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.internalAllocationTasks,
				'Planned Tasks Internal Time',
				format._formatTime(dataRow.resourceNumbers.plannedInternalMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.totalTasks,
				'Planned Tasks Total Time',
				format._formatTime(dataRow.resourceNumbers.tasksEstimateTotalMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.plannedBillableTasksUtilization,
				'Planned Tasks Billable Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.tasksBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.plannedNonBillableTasksUtilization,
				'Planned Tasks Non-Billable Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.tasksNonBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.plannedInternalUtilizationTasks,
				'Planned Tasks Internal Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.plannedInternalUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.plannedResourceTasksUtilization,
				'Planned Tasks Resource Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.tasksResourceUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.overTasks,
				'Planned Tasks Over Minutes',
				format._formatTime(dataRow.resourceNumbers.tasksOverMinutes)
			);
			// Tasks Remaining
			addColumn(
				row,
				boolEyeOptions.billableRemainingTasks,
				'Remaining Tasks Billable Time',
				format._formatTime(dataRow.resourceNumbers.tasksRemainingBillableMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.nonBillableRemainingTasks,
				'Remaining Tasks Non-Billable Time',
				format._formatTime(dataRow.resourceNumbers.tasksRemainingNonBillableMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.internalRemainingTasks,
				'Remaining Tasks Internal Time',
				format._formatTime(dataRow.resourceNumbers.remainingInternalMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.totalRemainingTasks,
				'Remaining Tasks Total Time',
				format._formatTime(dataRow.resourceNumbers.tasksRemainingTotalMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.overtimeRemainingTasks,
				'Remaining Tasks Over Time',
				format._formatTime(dataRow.resourceNumbers.tasksRemainingOverMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.remainingBillableTasksUtilization,
				'Remaining Tasks Billable Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.tasksRemainingBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.remainingNonBillableTasksUtilization,
				'Remaining Tasks Non-Billable Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.tasksRemainingNonBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.remainingInternalUtilizationTasks,
				'Remaining Tasks Internal Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.remainingInternalUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.remainingResourceTasksUtilization,
				'Task Remaining Resource Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.tasksRemainingResourceUtilization)
			);
			// Tasks Forecast
			addColumn(
				row,
				boolEyeOptions.billableForecastTasks,
				'Forecasted Tasks Billable Time',
				format._formatTime(dataRow.resourceNumbers.tasksForecastBillableMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.nonBillableForecastTasks,
				'Forecasted Tasks Non-Billable Time',
				format._formatTime(dataRow.resourceNumbers.tasksForecastNonBillableMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.internalForecastTasks,
				'Forecasted Tasks Internal Time',
				format._formatTime(dataRow.resourceNumbers.forecastInternalMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.totalForecastTasks,
				'Forecasted Tasks Total Time',
				format._formatTime(dataRow.resourceNumbers.tasksForecastTotalMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.overtimeForecastTasks,
				'Forecasted Tasks Over Time',
				format._formatTime(dataRow.resourceNumbers.tasksForecastOverMinutes)
			);
			addColumn(
				row,
				boolEyeOptions.forecastBillableTasksUtilization,
				'Forecasted Tasks Billable Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.tasksForecastBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.forecastNonBillableTasksUtilization,
				'Forecasted Tasks Non-Billable Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.tasksForecastNonBillableUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.forecastInternalUtilizationTasks,
				'Forecasted Tasks Internal Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.forecastInternalUtilization)
			);
			addColumn(
				row,
				boolEyeOptions.forecastResourceTasksUtilization,
				'Forecasted Tasks Resource Utilization',
				format._formatPercentageFromFraction(dataRow.resourceNumbers.tasksForecastResourceUtilization)
			);
			// Tasks Actual vs. Plan
			addColumn(
				row,
				boolEyeOptions.billableActualVsPlanTask,
				'Billable Actual vs Planned Tasks',
				format._formatTime(dataRow.resourceNumbers.tasksBillableActualVsPlan)
			);
			addColumn(
				row,
				boolEyeOptions.nonBillableActualVsPlanTask,
				'Non-Billable Actual vs Planned Tasks',
				format._formatTime(dataRow.resourceNumbers.tasksNonBillableActualVsPlan)
			);
			addColumn(
				row,
				boolEyeOptions.totalActualVsPlanTask,
				'Total Actual vs Planned Tasks',
				format._formatTime(dataRow.resourceNumbers.tasksTotalActualVsPlan)
			);
			// Availability
			addColumn(
				row,
				boolEyeOptions.workinghours,
				'Working Time',
				format._formatTime(dataRow.resourceNumbers.workingMinutes)
			);
			addColumn(row, boolEyeOptions.timeOff, 'Time Off', format._formatTime(dataRow.resourceNumbers.timeOffMinutes));
			addColumn(
				row,
				boolEyeOptions.availableHours,
				'Available Time',
				format._formatTime(dataRow.resourceNumbers.availableMinutes)
			);
			// Planned Allocations vs Tasks
			addColumn(
				row,
				boolEyeOptions.plannedBillableAllocationVsTask,
				'Planned Billable Allocations vs Tasks',
				format._formatTime(plannedBillableAllocationVsTask)
			);
			addColumn(
				row,
				boolEyeOptions.plannedNonBillableAllocationVsTask,
				'Planned Non-Billable Allocations vs Tasks',
				format._formatTime(plannedNonBillableAllocationVsTask)
			);
			addColumn(
				row,
				boolEyeOptions.plannedTotalAllocationVsTask,
				'Planned Allocations vs Tasks',
				format._formatTime(plannedAllocationVsTask)
			);
			// Remaining Allocations vs Tasks
			addColumn(
				row,
				boolEyeOptions.remainingBillableAllocationVsTask,
				'Remaining Billable Allocations vs Tasks',
				format._formatTime(remainingBillableAllocationVsTask)
			);
			addColumn(
				row,
				boolEyeOptions.remainingNonBillableAllocationVsTask,
				'Remaining Non-Billable Allocations vs Tasks',
				format._formatTime(remainingNonBillableAllocationVsTask)
			);
			addColumn(
				row,
				boolEyeOptions.remainingTotalAllocationVsTask,
				'Remaining Allocations vs Tasks',
				format._formatTime(remainingAllocationVsTask)
			);
			return row;
		});

		const headerRow = Object.keys(periodData[0]).reduce((map, column) => {
			map[column] = column;
			return map;
		}, {});
		periodData.splice(0, 0, headerRow);

		const csv = Util.JSONToCSV(periodData);
		const filename = startDate
			? `utilization_period_${startDate.format('YYYY-MM-DD')}-${endDate.format('YYYY-MM-DD')}.csv`
			: 'utilization_period.csv';
		Util.exportToCSV(csv, filename);
	}
};

const PeriodDownload = ({viewer, closeModal, startDate, endDate, boolEyeOptions, allocationControlsOptions}) => {
	const intl = useIntl();
	const formatData = viewer => {
		const resourceData =
			viewer.company.resourceBasedPersons || viewer.company.resourceBasedRoles || viewer.company.resourceBasedDepartments;
		const dataType = viewer.company.resourceBasedPersons
			? 'Person'
			: viewer.company.resourceBasedRoles
			? 'Role'
			: viewer.company.resourceBasedDepartments
			? 'Departments'
			: '';

		return resourceData.edges
			.map(edge => edge.node)
			.map(row => {
				const formattedData = {resourceNumbers: row.resourceNumbers};
				switch (dataType) {
					case 'Person':
						formattedData.person = {
							id: row.id,
							firstName: row.firstName,
							lastName: row.lastName,
							profilePictureId: row.profilePictureId,
						};
						break;
					case 'Role':
						formattedData.role = {
							id: row.id,
							name: row.name,
						};
						break;
					case 'Department':
						formattedData.department = {
							id: row.id,
							name: row.name,
						};
						break;
				}
				return formattedData;
			});
	};

	useEffect(() => {
		if (viewer) {
			exportToCsv(startDate, endDate, formatData(viewer), boolEyeOptions, allocationControlsOptions, intl);
			closeModal();
		}
	}, [viewer]);

	return null;
};

export const periodDownloadQuery = graphql`
	query PeriodDownload_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$searchQuery: TaskSearchQueryType!
		$pageSize: Int!
		$cursor: String
	) {
		viewer {
			actualPersonId
			component(name: "utilization_report_period_person_csv_loader")
			...PeriodDownload_viewer
				@arguments(
					searchQuery: $searchQuery
					pageSize: $pageSize
					cursor: $cursor
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createFragmentContainer(PeriodDownload, {
	viewer: graphql`
		fragment PeriodDownload_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
			pageSize: {type: "Int!"}
			cursor: {type: "String"}
		) {
			company {
				resourceBasedPersons(
					first: $pageSize
					after: $cursor
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				) {
					edges {
						node {
							id
							firstName
							lastName
							profilePictureId
							resourceNumbers {
								# Actuals
								actualBillableProjectMinutes
								actualNonBillableProjectMinutes
								actualInternalMinutes
								actualTotalMinutes
								actualOverMinutes
								actualBillableUtilization
								actualNonBillableUtilization
								actualInternalUtilization
								actualResourceUtilization

								# Plan
								plannedBillableProjectMinutes
								plannedBillableProjectMinutesWin
								plannedBillableProjectMinutesSoft
								plannedBillableProjectMinutesSoftWin
								plannedBillableProjectMinutesHard
								plannedNonBillableProjectMinutes
								plannedNonBillableProjectMinutesWin
								plannedNonBillableProjectMinutesSoft
								plannedNonBillableProjectMinutesSoftWin
								plannedNonBillableProjectMinutesHard
								plannedInternalMinutes
								plannedTotalMinutes
								plannedTotalMinutesWin
								plannedTotalMinutesSoft
								plannedTotalMinutesSoftWin
								plannedTotalMinutesHard
								plannedOverMinutes
								plannedOverMinutesWin
								plannedOverMinutesSoft
								plannedOverMinutesSoftWin
								plannedOverMinutesHard
								plannedBillableUtilization
								plannedBillableUtilizationWin
								plannedBillableUtilizationSoft
								plannedBillableUtilizationSoftWin
								plannedBillableUtilizationHard
								plannedNonBillableUtilization
								plannedNonBillableUtilizationWin
								plannedNonBillableUtilizationSoft
								plannedNonBillableUtilizationSoftWin
								plannedNonBillableUtilizationHard
								plannedInternalUtilization
								plannedResourceUtilization
								plannedResourceUtilizationWin
								plannedResourceUtilizationSoft
								plannedResourceUtilizationSoftWin
								plannedResourceUtilizationHard
								plannedBillableTaskAndAllocationsCombined
								plannedNonBillableTaskAndAllocationsCombined
								plannedInternalTimeTaskAndAllocationsCombined
								plannedTotalTimeTaskAndAllocationsCombined
								plannedBillableUtilizationTaskAndAllocationsCombined
								plannedNonBillableUtilizationTaskAndAllocationsCombined
								plannedInternalUtilizationTaskAndAllocationsCombined
								plannedResourceUtilizationTaskAndAllocationsCombined
								plannedBillableTaskAndAllocationsCombinedWin
								plannedNonBillableTaskAndAllocationsCombinedWin
								plannedTotalTimeTaskAndAllocationsCombinedWin
								plannedBillableUtilizationTaskAndAllocationsCombinedWin
								plannedNonBillableUtilizationTaskAndAllocationsCombinedWin
								plannedResourceUtilizationTaskAndAllocationsCombinedWin
								plannedBillableTaskAndAllocationsCombinedSoft
								plannedNonBillableTaskAndAllocationsCombinedSoft
								plannedTotalTimeTaskAndAllocationsCombinedSoft
								plannedBillableUtilizationTaskAndAllocationsCombinedSoft
								plannedNonBillableUtilizationTaskAndAllocationsCombinedSoft
								plannedResourceUtilizationTaskAndAllocationsCombinedSoft
								plannedBillableTaskAndAllocationsCombinedSoftWin
								plannedNonBillableTaskAndAllocationsCombinedSoftWin
								plannedTotalTimeTaskAndAllocationsCombinedSoftWin
								plannedBillableUtilizationTaskAndAllocationsCombinedSoftWin
								plannedNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
								plannedResourceUtilizationTaskAndAllocationsCombinedSoftWin
								plannedBillableTaskAndAllocationsCombinedHard
								plannedNonBillableTaskAndAllocationsCombinedHard
								plannedTotalTimeTaskAndAllocationsCombinedHard
								plannedBillableUtilizationTaskAndAllocationsCombinedHard
								plannedNonBillableUtilizationTaskAndAllocationsCombinedHard
								plannedResourceUtilizationTaskAndAllocationsCombinedHard

								# Actuals vs. Plan
								billableActualVsPlan
								billableActualVsPlanWin
								billableActualVsPlanSoft
								billableActualVsPlanSoftWin
								billableActualVsPlanHard
								nonBillableActualVsPlan
								nonBillableActualVsPlanWin
								nonBillableActualVsPlanSoft
								nonBillableActualVsPlanSoftWin
								nonBillableActualVsPlanHard
								internalActualVsPlan
								totalActualVsPlan
								totalActualVsPlanWin
								totalActualVsPlanSoft
								totalActualVsPlanSoftWin
								totalActualVsPlanHard

								# Remaining
								remainingBillableProjectMinutes
								remainingBillableProjectMinutesWin
								remainingBillableProjectMinutesSoft
								remainingBillableProjectMinutesSoftWin
								remainingBillableProjectMinutesHard
								remainingNonBillableProjectMinutes
								remainingNonBillableProjectMinutesWin
								remainingNonBillableProjectMinutesSoft
								remainingNonBillableProjectMinutesSoftWin
								remainingNonBillableProjectMinutesHard
								remainingInternalMinutes
								remainingTotalMinutes
								remainingTotalMinutesWin
								remainingTotalMinutesSoft
								remainingTotalMinutesSoftWin
								remainingTotalMinutesHard
								remainingOverMinutes
								remainingBillableUtilization
								remainingBillableUtilizationWin
								remainingBillableUtilizationSoft
								remainingBillableUtilizationSoftWin
								remainingBillableUtilizationHard
								remainingNonBillableUtilization
								remainingNonBillableUtilizationWin
								remainingNonBillableUtilizationSoft
								remainingNonBillableUtilizationSoftWin
								remainingNonBillableUtilizationHard
								remainingInternalUtilization
								remainingResourceUtilization
								remainingResourceUtilizationWin
								remainingResourceUtilizationSoft
								remainingResourceUtilizationSoftWin
								remainingResourceUtilizationHard
								remainingBillableTaskAndAllocationsCombined
								remainingNonBillableTaskAndAllocationsCombined
								remainingInternalTimeTaskAndAllocationsCombined
								remainingTotalTimeTaskAndAllocationsCombined
								remainingBillableUtilizationTaskAndAllocationsCombined
								remainingNonBillableUtilizationTaskAndAllocationsCombined
								remainingInternalUtilizationTaskAndAllocationsCombined
								remainingResourceUtilizationTaskAndAllocationsCombined
								remainingBillableTaskAndAllocationsCombinedWin
								remainingNonBillableTaskAndAllocationsCombinedWin
								remainingTotalTimeTaskAndAllocationsCombinedWin
								remainingBillableUtilizationTaskAndAllocationsCombinedWin
								remainingNonBillableUtilizationTaskAndAllocationsCombinedWin
								remainingResourceUtilizationTaskAndAllocationsCombinedWin
								remainingBillableTaskAndAllocationsCombinedSoft
								remainingNonBillableTaskAndAllocationsCombinedSoft
								remainingTotalTimeTaskAndAllocationsCombinedSoft
								remainingBillableUtilizationTaskAndAllocationsCombinedSoft
								remainingNonBillableUtilizationTaskAndAllocationsCombinedSoft
								remainingResourceUtilizationTaskAndAllocationsCombinedSoft
								remainingBillableTaskAndAllocationsCombinedSoftWin
								remainingNonBillableTaskAndAllocationsCombinedSoftWin
								remainingTotalTimeTaskAndAllocationsCombinedSoftWin
								remainingBillableUtilizationTaskAndAllocationsCombinedSoftWin
								remainingNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
								remainingResourceUtilizationTaskAndAllocationsCombinedSoftWin
								remainingBillableTaskAndAllocationsCombinedHard
								remainingNonBillableTaskAndAllocationsCombinedHard
								remainingTotalTimeTaskAndAllocationsCombinedHard
								remainingBillableUtilizationTaskAndAllocationsCombinedHard
								remainingNonBillableUtilizationTaskAndAllocationsCombinedHard
								remainingResourceUtilizationTaskAndAllocationsCombinedHard

								# Forecast
								forecastBillableProjectMinutes
								forecastBillableProjectMinutesWin
								forecastBillableProjectMinutesSoft
								forecastBillableProjectMinutesSoftWin
								forecastBillableProjectMinutesHard
								forecastNonBillableProjectMinutes
								forecastNonBillableProjectMinutesWin
								forecastNonBillableProjectMinutesSoft
								forecastNonBillableProjectMinutesSoftWin
								forecastNonBillableProjectMinutesHard
								forecastInternalMinutes
								forecastTotalMinutes
								forecastTotalMinutesWin
								forecastTotalMinutesSoft
								forecastTotalMinutesSoftWin
								forecastTotalMinutesHard
								forecastOverMinutes
								forecastOverMinutesWin
								forecastOverMinutesSoft
								forecastOverMinutesSoftWin
								forecastOverMinutesHard
								forecastBillableUtilization
								forecastBillableUtilizationWin
								forecastBillableUtilizationSoft
								forecastBillableUtilizationSoftWin
								forecastBillableUtilizationHard
								forecastNonBillableUtilization
								forecastNonBillableUtilizationWin
								forecastNonBillableUtilizationSoft
								forecastNonBillableUtilizationSoftWin
								forecastNonBillableUtilizationHard
								forecastInternalUtilization
								forecastResourceUtilization
								forecastResourceUtilizationWin
								forecastResourceUtilizationSoft
								forecastResourceUtilizationSoftWin
								forecastResourceUtilizationHard
								forecastBillableTaskAndAllocationsCombined
								forecastNonBillableTaskAndAllocationsCombined
								forecastInternalTimeTaskAndAllocationsCombined
								forecastTotalTimeTaskAndAllocationsCombined
								forecastBillableUtilizationTaskAndAllocationsCombined
								forecastNonBillableUtilizationTaskAndAllocationsCombined
								forecastInternalUtilizationTaskAndAllocationsCombined
								forecastResourceUtilizationTaskAndAllocationsCombined
								forecastBillableTaskAndAllocationsCombinedWin
								forecastNonBillableTaskAndAllocationsCombinedWin
								forecastTotalTimeTaskAndAllocationsCombinedWin
								forecastBillableUtilizationTaskAndAllocationsCombinedWin
								forecastNonBillableUtilizationTaskAndAllocationsCombinedWin
								forecastResourceUtilizationTaskAndAllocationsCombinedWin
								forecastBillableTaskAndAllocationsCombinedSoft
								forecastNonBillableTaskAndAllocationsCombinedSoft
								forecastTotalTimeTaskAndAllocationsCombinedSoft
								forecastBillableUtilizationTaskAndAllocationsCombinedSoft
								forecastNonBillableUtilizationTaskAndAllocationsCombinedSoft
								forecastResourceUtilizationTaskAndAllocationsCombinedSoft
								forecastBillableTaskAndAllocationsCombinedSoftWin
								forecastNonBillableTaskAndAllocationsCombinedSoftWin
								forecastTotalTimeTaskAndAllocationsCombinedSoftWin
								forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin
								forecastNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
								forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin
								forecastBillableTaskAndAllocationsCombinedHard
								forecastNonBillableTaskAndAllocationsCombinedHard
								forecastTotalTimeTaskAndAllocationsCombinedHard
								forecastBillableUtilizationTaskAndAllocationsCombinedHard
								forecastNonBillableUtilizationTaskAndAllocationsCombinedHard
								forecastResourceUtilizationTaskAndAllocationsCombinedHard

								# Tasks estimates (Planned)
								tasksEstimateBillableMinutes
								tasksEstimateNonBillableMinutes
								tasksEstimateTotalMinutes
								tasksBillableUtilization
								tasksNonBillableUtilization
								tasksResourceUtilization
								tasksOverMinutes

								# Tasks Remaining
								tasksRemainingBillableMinutes
								tasksRemainingNonBillableMinutes
								tasksRemainingTotalMinutes
								tasksRemainingOverMinutes
								tasksRemainingBillableUtilization
								tasksRemainingNonBillableUtilization
								tasksRemainingResourceUtilization

								# Tasks Forecast
								tasksForecastBillableMinutes
								tasksForecastNonBillableMinutes
								tasksForecastTotalMinutes
								tasksForecastOverMinutes
								tasksForecastBillableUtilization
								tasksForecastNonBillableUtilization
								tasksForecastResourceUtilization

								# Tasks Actual vs. Plan
								tasksBillableActualVsPlan
								tasksNonBillableActualVsPlan
								tasksTotalActualVsPlan

								# Planned Allocation vs. Task
								plannedBillableAllocationVsTask
								plannedBillableAllocationVsTaskWin
								plannedBillableAllocationVsTaskSoft
								plannedBillableAllocationVsTaskSoftWin
								plannedBillableAllocationVsTaskHard
								plannedNonBillableAllocationVsTask
								plannedNonBillableAllocationVsTaskWin
								plannedNonBillableAllocationVsTaskSoft
								plannedNonBillableAllocationVsTaskSoftWin
								plannedNonBillableAllocationVsTaskHard
								plannedAllocationVsTask
								plannedAllocationVsTaskWin
								plannedAllocationVsTaskSoft
								plannedAllocationVsTaskSoftWin
								plannedAllocationVsTaskHard

								# Remaining Allocation vs. Task
								remainingBillableAllocationVsTask
								remainingBillableAllocationVsTaskWin
								remainingBillableAllocationVsTaskSoft
								remainingBillableAllocationVsTaskSoftWin
								remainingBillableAllocationVsTaskHard
								remainingNonBillableAllocationVsTask
								remainingNonBillableAllocationVsTaskWin
								remainingNonBillableAllocationVsTaskSoft
								remainingNonBillableAllocationVsTaskSoftWin
								remainingNonBillableAllocationVsTaskHard
								remainingAllocationVsTask
								remainingAllocationVsTaskWin
								remainingAllocationVsTaskSoft
								remainingAllocationVsTaskSoftWin
								remainingAllocationVsTaskHard

								# Availability
								workingMinutes
								timeOffMinutes
								availableMinutes

								#Performance
								performance
							}
						}
					}
				}
			}
		}
	`,
});
