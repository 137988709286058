import {MODULE_TYPES} from '../../../constants';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import DirectApi, {projectGroupUrl, projectUrl} from '../../../directApi';

export const handleViewProject = (pageComponent, projectId, customProjectId, projectGroupId, programPrefix) => {
	const {history} = pageComponent.props;

	if (projectId || projectGroupId) {
		const link = projectGroupId ? projectGroupUrl(projectGroupId) : projectUrl(projectId, customProjectId);
		const lastViewedPage =
			(projectGroupId
				? localStorage.getItem('project-group-section-last-viewed')
				: localStorage.getItem('project-section-last-viewed')) || 'workflow';

		history.push(`${link}/${lastViewedPage}`);
	} else if (programPrefix) {
		window.open(DirectApi.programUrl(programPrefix, hasModule(MODULE_TYPES.PROGRAMS_PLUS)), '_blank');
	}
};
