/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RevenueRecognitionDetailsLoader_viewer$ref: FragmentReference;
declare export opaque type RevenueRecognitionDetailsLoader_viewer$fragmentType: RevenueRecognitionDetailsLoader_viewer$ref;
export type RevenueRecognitionDetailsLoader_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string
  |},
  +project: ?{|
    +rateCard: ?{|
      +currency: ?string
    |},
    +aggregatedFinancialNumbers: $ReadOnlyArray<?string>,
    +expenseAggregatedFinancialNumbers: $ReadOnlyArray<?string>,
    +financialNumbers: ?{|
      +recognitionAmount: ?number,
      +recognitionPercentage: ?number,
      +expenseRevenuePercentage: ?number,
      +recognitionProfit: ?number,
      +recognitionProfitPercentage: ?number,
      +billableTime: ?number,
      +surplus: ?number,
      +surplusPercentage: ?number,
      +estimatedCost: ?number,
      +estimatedCostPercentage: ?number,
      +expenseCostPercentage: ?number,
      +suggestedRevenue: ?number,
      +suggestedRevenuePercentage: ?number,
      +suggestedProfit: ?number,
      +suggestedProfitPercentage: ?number,
    |},
  |},
  +$refType: RevenueRecognitionDetailsLoader_viewer$ref,
|};
export type RevenueRecognitionDetailsLoader_viewer$data = RevenueRecognitionDetailsLoader_viewer;
export type RevenueRecognitionDetailsLoader_viewer$key = {
  +$data?: RevenueRecognitionDetailsLoader_viewer$data,
  +$fragmentRefs: RevenueRecognitionDetailsLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v1 = {
  "kind": "Literal",
  "name": "addAccumulatedNumbers",
  "value": true
},
v2 = {
  "kind": "Variable",
  "name": "aggregateLevel",
  "variableName": "aggregateLevel"
},
v3 = {
  "kind": "Literal",
  "name": "convertToProjectCurrency",
  "value": true
},
v4 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v5 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v6 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v7 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v8 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v9 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v10 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregateLevel",
      "type": "AggregateLevel!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregates",
      "type": "[FinancialAggregatedNumber]!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "expenseSearchQuery",
      "type": "TaskSearchQueryType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RevenueRecognitionDetailsLoader_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "Variable",
              "name": "aggregates",
              "variableName": "aggregates"
            },
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "aggregatedFinancialNumbers",
          "storageKey": null
        },
        {
          "alias": "expenseAggregatedFinancialNumbers",
          "args": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "Literal",
              "name": "aggregates",
              "value": [
                "billableTotalTimeAndExpensesAtCompletion"
              ]
            },
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "expenseSearchQuery"
            },
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "aggregatedFinancialNumbers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "financialNumbers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expenseRevenuePercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionProfitPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "surplus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "surplusPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "estimatedCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "estimatedCostPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expenseCostPercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "suggestedRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "suggestedRevenuePercentage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "suggestedProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "suggestedProfitPercentage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c8eb14dbad1224962f03974362e96208';

module.exports = node;
