import React, {Component} from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';

class NumericTile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			id: uuid.v4(),
		};
	}

	render() {
		return (
			<div
				className={'numeric-tile' + (this.props.useBigStyle ? ' big' : '') + (this.props.isV2 ? ' v2' : '')}
				data-cy={this.props.cy}
			>
				{this.props.tooltip ? (
					<div className={'numeric-tile v2' + (this.props.groupedValues ? ' grouped' : '')}>
						<label className="numeric-tile-label" htmlFor={this.state.id}>
							{this.props.label}
						</label>
						{this.props.timeRegPopup ? this.props.timeRegPopup : null}
						<TooltipContainer infoText={this.props.infoText}>
							{this.props.placeUnitBeforeValue ? (
								<div
									className={
										'value-container' +
										(this.props.isV2 ? '-v2' : '') +
										(this.props.warning ? ' warning' : '')
									}
								>
									<div className="unit-tile">{this.props.unit}</div>
									<div className={'value-tile'} id={this.state.id} data-cy={this.props.cy + '-value'}>
										{this.props.value}
									</div>
									<div className="unit-tile" style={{paddingLeft: 0}}>
										{this.props.groupedValues ? '*' : ''}
									</div>
								</div>
							) : (
								<div
									className={
										'value-container' +
										(this.props.isV2 ? '-v2' : '') +
										(this.props.warning ? ' warning' : '')
									}
								>
									<div className={'value-tile'} id={this.state.id} data-cy={this.props.cy + '-value'}>
										{this.props.value}
									</div>
									<div className="unit-tile">
										{this.props.groupedValues ? this.props.unit + '*' : this.props.unit}
									</div>
								</div>
							)}
						</TooltipContainer>
					</div>
				) : (
					<div className={'numeric-tile v2'}>
						<label className="numeric-tile-label" htmlFor={this.state.id}>
							{this.props.label}
						</label>
						{this.props.timeRegPopup ? this.props.timeRegPopup : null}

						{this.props.placeUnitBeforeValue ? (
							<div
								className={
									'value-container' + (this.props.isV2 ? '-v2' : '') + (this.props.warning ? ' warning' : '')
								}
							>
								<div className="unit-tile">{this.props.unit}</div>
								<div className={'value-tile'} id={this.state.id} data-cy={this.props.cy + '-value'}>
									{this.props.value}
								</div>
							</div>
						) : (
							<div
								className={
									'value-container' + (this.props.isV2 ? '-v2' : '') + (this.props.warning ? ' warning' : '')
								}
							>
								<div className={'value-tile'} id={this.state.id} data-cy={this.props.cy + '-value'}>
									{this.props.value}
								</div>
								<div className="unit-tile">{this.props.unit}</div>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

NumericTile.propTypes = {
	label: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	unit: PropTypes.string,
	valueColor: PropTypes.string,
	useBigStyle: PropTypes.bool,
	placeUnitBeforeValue: PropTypes.bool,
};

export default NumericTile;
