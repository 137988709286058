import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl, FormattedMessage} from 'react-intl';
import moment from 'moment';
import ChartJsLineBurndown from '../../chartjs/chart_js_line_burndown';
import util from '../../../forecast-app/shared/util/util';

class sprintBurndownV2Component extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}

	getValueFromData(data) {
		const useHours = this.props.viewer.insightComponentsData.sprintBurndownV2.useHours;
		let value = 0;
		if (
			this.props.selectValue === null ||
			this.props.selectValue === undefined ||
			this.props.selectValue === 'cards-number'
		) {
			value = data.totalTasks - data.doneTasks;
		} else if (this.props.selectValue === 'forecast-sum') {
			value = useHours ? data.forecast / 60.0 : data.forecast;
		} else {
			value = useHours ? data.remaining / 60.0 : data.remaining;
		}
		return value;
	}

	getChanges(data) {
		const useHours = this.props.viewer.insightComponentsData.sprintBurndownV2.useHours;
		let value = [];
		if (
			this.props.selectValue === null ||
			this.props.selectValue === undefined ||
			this.props.selectValue === 'cards-number'
		) {
			const changes = data.changes.filter(
				change =>
					change.action === 'ADDED' ||
					change.action === 'REMOVED' ||
					change.currentStatusColumn === 'DONE' ||
					change.previousStatusColumn === 'DONE'
			);
			if (changes.length > 0) {
				let totalChange = 0;
				const addedChanges = changes.filter(change => change.action === 'ADDED');
				if (addedChanges.length > 0) {
					value.push('');
					value.push('Tasks added:');
				}

				addedChanges.forEach(change => {
					totalChange++;
					value.push(`T${change.companyTaskId}`);
				});

				const removedChanges = changes.filter(change => change.action === 'REMOVED');
				if (removedChanges.length > 0) {
					value.push('');
					value.push('Tasks removed:');
				}

				removedChanges.forEach(change => {
					totalChange--;
					value.push(`T${change.companyTaskId}`);
				});

				const closedChanges = changes.filter(change => change.currentStatusColumn === 'DONE');
				if (closedChanges.length > 0) {
					value.push('');
					value.push('Tasks closed:');
				}

				closedChanges.forEach(change => {
					totalChange--;
					value.push(`T${change.companyTaskId}`);
				});

				const reopenedChanges = changes.filter(change => change.previousStatusColumn === 'DONE');
				if (reopenedChanges.length > 0) {
					value.push('');
					value.push('Tasks reopened:');
				}

				reopenedChanges.forEach(change => {
					totalChange++;
					value.push(`T${change.companyTaskId}`);
				});

				value.push('');
				value.push('Total change: ' + totalChange);
			}
		} else if (this.props.selectValue === 'forecast-sum') {
			const changes = data.changes.filter(change => change.forecastChange !== 0);
			if (changes.length > 0) {
				let totalChange = 0;
				const addedChanges = changes.filter(change => change.action === 'ADDED');
				if (addedChanges.length > 0) {
					value.push('');
					value.push('Tasks added:');
				}

				addedChanges.forEach(change => {
					totalChange += change.forecastChange;
					value.push(
						`T${change.companyTaskId} - Forecast change: ${
							useHours ? change.forecastChange / 60 + 'h' : change.forecastChange + 'p'
						}`
					);
				});

				const updatedChanges = changes.filter(change => change.action === 'UPDATED');
				if (updatedChanges.length > 0) {
					value.push('');
					value.push('Tasks updated:');
				}

				updatedChanges.forEach(change => {
					totalChange += change.forecastChange;
					value.push(
						`T${change.companyTaskId} - Forecast change: ${
							useHours ? change.forecastChange / 60 + 'h' : change.forecastChange + 'p'
						}`
					);
				});

				const removedChanges = changes.filter(change => change.action === 'REMOVED');
				if (removedChanges.length > 0) {
					value.push('');
					value.push('Tasks removed:');
				}

				removedChanges.forEach(change => {
					totalChange += change.forecastChange;
					value.push(
						`T${change.companyTaskId} - Forecast change: ${
							useHours ? change.forecastChange / 60 + 'h' : change.forecastChange + 'p'
						}`
					);
				});

				value.push('');
				value.push(`Total change: ${useHours ? totalChange / 60 + 'h' : totalChange + 'p'}`);
			}
		} else {
			// Remaining
			const changes = data.changes.filter(change => change.remainingChange !== 0);
			if (changes.length > 0) {
				let totalChange = 0;
				const addedChanges = changes.filter(change => change.action === 'ADDED');
				if (addedChanges.length > 0) {
					value.push('');
					value.push('Tasks added:');
				}

				addedChanges.forEach(change => {
					totalChange += change.remainingChange;
					value.push(
						`T${change.companyTaskId} - Remaining change: ${
							useHours ? change.remainingChange / 60 + 'h' : change.remainingChange + 'p'
						}`
					);
				});

				const updatedChanges = changes.filter(change => change.action === 'UPDATED');
				if (updatedChanges.length > 0) {
					value.push('');
					value.push('Tasks updated:');
				}

				updatedChanges.forEach(change => {
					totalChange += change.remainingChange;
					value.push(
						`T${change.companyTaskId} - Remaining change: ${
							useHours ? change.remainingChange / 60 + 'h' : change.remainingChange + 'p'
						}`
					);
				});

				const removedChanges = changes.filter(change => change.action === 'REMOVED');
				if (removedChanges.length > 0) {
					value.push('');
					value.push('Tasks removed:');
				}

				removedChanges.forEach(change => {
					totalChange += change.remainingChange;
					value.push(
						`T${change.companyTaskId} - Remaining change: ${
							useHours ? change.remainingChange / 60 + 'h' : change.remainingChange + 'p'
						}`
					);
				});

				value.push('');
				value.push(`Total change: ${useHours ? totalChange / 60 + 'h' : totalChange + 'p'}`);
			}
		}
		return value;
	}

	render() {
		const viewer = this.props.viewer;
		if (
			this.props.viewer.insightComponentsData.sprintBurndownV2 == null ||
			!this.props.viewer.insightComponentsData.sprintBurndownV2.sprintTimeBox
		) {
			return <div className="ai-empty-message" />;
		}
		const data = {
			datasets: [],
		};
		let yAxesTitle, valueLegendTitle, tooltipSuffix;
		if (
			viewer.insightComponentsData.sprintBurndownV2 == null ||
			viewer.insightComponentsData.sprintBurndownV2.sprintBurndownV2Data == null
		) {
			return (
				<div className="ai-empty-message">
					<FormattedMessage id={'insights.component.burndown.empty_state'} />
				</div>
			);
		}
		const useHours = viewer.insightComponentsData.sprintBurndownV2.useHours;

		const {formatMessage} = this.props.intl;
		const valueArray = [];

		if (
			this.props.selectValue === null ||
			this.props.selectValue === undefined ||
			this.props.selectValue === 'cards-number'
		) {
			yAxesTitle = formatMessage({id: 'insights.component.number.sprintBurndown'});
			valueLegendTitle = formatMessage({id: 'insights.component.burndown.legend_cards_count'});
		} else if (this.props.selectValue === 'forecast-sum') {
			yAxesTitle = useHours ? formatMessage({id: 'common.hours'}) : formatMessage({id: 'common.points'});
			valueLegendTitle = formatMessage({id: 'insights.component.burndown.legend_forecast_sum'});
			tooltipSuffix = useHours ? formatMessage({id: 'common.hours.short'}) : formatMessage({id: 'common.points.short'});
		} else {
			yAxesTitle = useHours ? formatMessage({id: 'common.hours'}) : formatMessage({id: 'common.points'});
			valueLegendTitle = formatMessage({id: 'insights.component.burndown.legend_remaining'});
			tooltipSuffix = useHours ? formatMessage({id: 'common.hours.short'}) : formatMessage({id: 'common.points.short'});
		}

		const sprintData = viewer.insightComponentsData.sprintBurndownV2;
		const start = util.CreateNonUtcMomentDate(sprintData.startYear, sprintData.startMonth, sprintData.startDay);
		const end = util.CreateNonUtcMomentDate(sprintData.endYear, sprintData.endMonth, sprintData.endDay).add(1, 'days');

		// Find the point closest to the start day
		const startPoint = sprintData.sprintBurndownV2Data.reduce((previousValue, currentValue) => {
			if (previousValue == null) {
				return currentValue;
			}

			const previousTime = moment.utc(previousValue.time, 'YYYY-MM-DD HH:mm:ss').local();
			const currentTime = moment.utc(currentValue.time, 'YYYY-MM-DD HH:mm:ss').local();
			if (previousTime.isSameOrBefore(start)) {
				// Something was found before the sprint start date. Check if new is also before, but closer
				if (currentTime.isSameOrBefore(start) && currentTime.isAfter(previousTime)) {
					return currentValue;
				}
			} else {
				// Nothing is found before the start date yet. Check if current is closer
				if (currentTime.isSameOrBefore(start) || currentTime.isBefore(previousTime)) {
					return currentValue;
				}
			}
			return previousValue;
		}, null);

		// Add the start point to the graph
		if (startPoint != null) {
			valueArray.push({y: this.getValueFromData(startPoint), x: start.toDate(), changes: this.getChanges(startPoint)});
		}

		// Add remaining data
		sprintData.sprintBurndownV2Data.forEach(dataRecord => {
			const momentDate = moment.utc(dataRecord.time, 'YYYY-MM-DD HH:mm:ss').local();
			if (momentDate.isAfter(start) && momentDate.isBefore(end)) {
				const changes = this.getChanges(dataRecord);
				if (changes.length > 0) {
					valueArray.push({y: this.getValueFromData(dataRecord), x: momentDate.toDate(), changes});
				}
			}
		});

		// Add end point to graph if needed
		if (end.isBefore(moment.now())) {
			// Sprint has ended. Add an end data value
			let value = 0;
			let date;
			let endRecord;
			sprintData.sprintBurndownV2Data.forEach(dataRecord => {
				const momentDate = moment.utc(dataRecord.time, 'YYYY-MM-DD HH:mm:ss').local();
				if (momentDate.isBefore(end)) {
					endRecord = dataRecord;
					value = this.getValueFromData(dataRecord);
					date = momentDate;
				}
			});
			if (date) {
				valueArray.push({y: value, x: end.toDate(), changes: this.getChanges(endRecord)});
			}
		}

		data.datasets.push({
			steppedLine: true,
			label: valueLegendTitle,
			borderColor: '#28AEFB',
			fill: false,
			stack: 'Stack 0',
			lineTension: 0,
			data: valueArray,
		});

		// Find ideal line

		// Find start number
		const startNumber = sprintData.sprintBurndownV2Data.reduce((previousValue, currentValue) => {
			const currentTime = moment.utc(currentValue.time, 'YYYY-MM-DD HH:mm:ss').local();
			if (currentTime.isAfter(start) && currentTime.diff(start, 'hours') < 24) {
				const valueFromData = this.getValueFromData(currentValue);
				if (valueFromData > previousValue) {
					return valueFromData;
				}
			}
			return previousValue;
		}, this.getValueFromData(startPoint));

		// Find working days for the company
		const weekdayCountArray = [1, 2, 3, 4, 5, 6, 7].map(dayIndex =>
			util.weekdaysBetween(start.clone().add(1, 'days'), end, dayIndex)
		);
		let totalWorkingDays = 0;
		if (sprintData.monday > 0) {
			totalWorkingDays += weekdayCountArray[0];
		}
		if (sprintData.tuesday > 0) {
			totalWorkingDays += weekdayCountArray[1];
		}
		if (sprintData.wednesday > 0) {
			totalWorkingDays += weekdayCountArray[2];
		}
		if (sprintData.thursday > 0) {
			totalWorkingDays += weekdayCountArray[3];
		}
		if (sprintData.friday > 0) {
			totalWorkingDays += weekdayCountArray[4];
		}
		if (sprintData.saturday > 0) {
			totalWorkingDays += weekdayCountArray[5];
		}
		if (sprintData.sunday > 0) {
			totalWorkingDays += weekdayCountArray[6];
		}

		// Create the line data
		if (totalWorkingDays > 0) {
			const idealVelocityArray = [];
			const currentIdealDate = start.clone();
			let workingDaysPassed = totalWorkingDays;
			while (currentIdealDate.isSameOrBefore(end)) {
				idealVelocityArray.push({
					y: (startNumber / totalWorkingDays) * workingDaysPassed,
					x: currentIdealDate.toDate(),
				});
				if (
					(currentIdealDate.isoWeekday() === 1 && sprintData.monday > 0) ||
					(currentIdealDate.isoWeekday() === 2 && sprintData.tuesday > 0) ||
					(currentIdealDate.isoWeekday() === 3 && sprintData.wednesday > 0) ||
					(currentIdealDate.isoWeekday() === 4 && sprintData.thursday > 0) ||
					(currentIdealDate.isoWeekday() === 5 && sprintData.friday > 0) ||
					(currentIdealDate.isoWeekday() === 6 && sprintData.saturday > 0) ||
					(currentIdealDate.isoWeekday() === 7 && sprintData.sunday > 0)
				) {
					workingDaysPassed--;
				}
				currentIdealDate.add(1, 'days');
			}

			data.datasets.push({
				label: formatMessage({id: 'insights.component.burnUp.ideal_velocity'}),
				borderColor: '#F6C4FC',
				fill: false,
				stack: 'Stack 1',
				lineTension: 0,
				data: idealVelocityArray,
			});
		}

		return (
			<ChartJsLineBurndown
				min={start.toDate()}
				max={end.toDate()}
				data={data}
				height={360}
				yAxesTitle={yAxesTitle}
				tooltipSuffix={tooltipSuffix}
			/>
		);
	}
}

const sprintBurndownV2ComponentQuery = graphql`
	query sprintBurndownV2Component_Query($shareKey: String, $sprintId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_sprint_burndown_v2")
			...sprintBurndownV2Component_viewer
				@arguments(shareKey: $shareKey, sprintId: $sprintId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {sprintBurndownV2ComponentQuery};

export default injectIntl(
	createFragmentContainer(sprintBurndownV2Component, {
		viewer: graphql`
			fragment sprintBurndownV2Component_viewer on Viewer
			@argumentDefinitions(shareKey: {type: "String"}, sprintId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
				insightComponentsData(shareKey: $shareKey) {
					sprintBurndownV2(sprintId: $sprintId, isProjectGroupType: $isProjectGroupType) {
						name
						startYear
						startMonth
						startDay
						endYear
						endMonth
						endDay
						useHours
						sprintTimeBox
						monday
						tuesday
						wednesday
						thursday
						friday
						saturday
						sunday
						sprintBurndownV2Data {
							time
							remaining
							forecast
							totalTasks
							doneTasks
							changes {
								taskId
								companyTaskId
								action
								forecastChange
								remainingChange
								currentStatusColumn
								previousStatusColumn
							}
						}
					}
				}
			}
		`,
	})
);
