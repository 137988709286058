/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClientDropdown_clients$ref: FragmentReference;
declare export opaque type ClientDropdown_clients$fragmentType: ClientDropdown_clients$ref;
export type ClientDropdown_clients = $ReadOnlyArray<{|
  +node: ?{|
    +id: string,
    +name: ?string,
    +logoId: ?string,
  |},
  +$refType: ClientDropdown_clients$ref,
|}>;
export type ClientDropdown_clients$data = ClientDropdown_clients;
export type ClientDropdown_clients$key = $ReadOnlyArray<{
  +$data?: ClientDropdown_clients$data,
  +$fragmentRefs: ClientDropdown_clients$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ClientDropdown_clients",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logoId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClientTypeEdge"
};
// prettier-ignore
(node/*: any*/).hash = '7b7436a5a10c6e4fee567fcfc92b395e';

module.exports = node;
