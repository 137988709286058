import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Toggle extends Component {
	onChange(e) {
		e.stopPropagation();
		this.props.onChange(!this.props.checked);
	}

	render() {
		return (
			<label className={'toggle-component' + (this.props.locked ? ' locked' : '')}>
				<input
					type="checkbox"
					onChange={this.props.locked ? null : this.onChange.bind(this)}
					checked={this.props.checked}
				/>
				<span className="slider round" />
			</label>
		);
	}
}
Toggle.propTypes = {
	onChange: PropTypes.func.isRequired,
	checked: PropTypes.bool.isRequired,
	locked: PropTypes.bool,
};
export default Toggle;
