/**
 * Configuration object, containing all environment/deployment specific configurations,
 * such as URL's to GraphQL server, client version, URL's to various 3rd parties etc.
 *
 * These configurations are injected at deployment time.
 *
 * Transition note:
 * These variables have previously been injected at build time, making the web-front
 * artifacts being locked to a specific deployment, and couldn't be carried all
 * the way from local to test to production. But by injecting the variables at
 * deployment time we can do that.
 * This transition note can be deleted, when the transition is done :-)
 */
export const config = {
	// Environment
	environment: 'FORECAST_VAR_ENVIRONMENT',
	// Version
	clientVersionMajor: 'FORECAST_VAR_CLIENT_VERSION_MAJOR',
	clientVersionMinor: 'FORECAST_VAR_CLIENT_VERSION_MINOR',
	// Timestamp
	clientVersionTimestamp: 'FORECAST_VAR_CLIENT_VERSION_TIMESTAMP',
	// version string
	clientVersionString: 'FORECAST_VAR_CLIENT_VERSION_STRING',
};
