import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import ChartJsBar from '../../chartjs/chart_js_bar';

class UtilizationBarCharttDummy extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}
	render() {
		const {formatMessage} = this.props.intl;
		const data = {
			labels: ['First Person', 'Second Person', 'Third Person', 'Fourth Person'],
			datasets: [],
		};
		const projectTimeData = [30, 35, 14, 20];
		const reportedTimeData = [5, 35, 35, 15];
		const availableTimeData = [40, 40, 20, 40];
		const nonProjectTimeData = [10, 5, 10, 0];
		const timeOffData = [0, 0, 20, 0];
		data.datasets.push({
			label: formatMessage({id: 'common.working_hours'}),
			backgroundColor: '#00cc66',
			stack: 'Stack 0',
			data: availableTimeData,
			maxBarThickness: 60,
		});
		data.datasets.push({
			label: formatMessage({id: 'common.time_off'}),
			backgroundColor: '#CECBF7',
			stack: 'Stack 0',
			data: timeOffData,
			maxBarThickness: 60,
		});
		data.datasets.push({
			label: formatMessage({id: 'common.allocated_project_time'}),
			backgroundColor: '#6e0fea',
			stack: 'Stack 1',
			data: projectTimeData,
			maxBarThickness: 60,
		});
		data.datasets.push({
			label: formatMessage({id: 'common.allocated_internal_time'}),
			backgroundColor: '#dbdbdb',
			stack: 'Stack 1',
			data: nonProjectTimeData,
			maxBarThickness: 60,
		});
		data.datasets.push({
			label: formatMessage({id: 'insights.component.list.column.registered'}),
			backgroundColor: '#A2DAFF',
			stack: 'Stack 2',
			data: reportedTimeData,
			maxBarThickness: 60,
		});

		return (
			<ChartJsBar
				data={data}
				xAxisLabel={formatMessage({id: 'common.hours'})}
				height={300}
				type="horizontalBar"
				legendPosition="top"
				isUtilizationBarChart
			/>
		);
	}
}

UtilizationBarCharttDummy.propTypes = {
	title: PropTypes.string,
};

export default injectIntl(UtilizationBarCharttDummy);
