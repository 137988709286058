import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown, SingleText} from 'web-components';

export const TeamDropdown = ({
	teams,
	dropdownAlignment,
	width,
	name,
	optionsName,
	selectedItems,
	onSelect,
	onRemove,
	selectedGroupName,
	usePortal,
	userpilot,
	emphasizeEmptyState,
	cy,
	headerLines,
}) => {
	const sortedTeams = [...teams].sort((a, b) => {
		if (a.node.name.toLowerCase() < b.node.name.toLowerCase()) return -1;
		if (a.node.name.toLowerCase() > b.node.name.toLowerCase()) return 1;
		return 0;
	});
	const getElements = () => {
		return sortedTeams.map(edge => {
			const {id, name} = edge.node;
			return (
				<SingleText key={id} value={id} searchString={name}>
					{name}
				</SingleText>
			);
		});
	};
	return (
		<Dropdown
			isNested
			isMultiSelect
			usePortal={usePortal}
			selectedGroupName={selectedGroupName}
			headerLines={headerLines}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			cy={cy}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
		>
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{getElements()}
			</Dropdown.Group>
		</Dropdown>
	);
};

TeamDropdown.propTypes = {
	teams: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				name: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	usePortal: PropTypes.bool,
	optionsName: PropTypes.string,
	cy: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	headerLines: PropTypes.element,
};

TeamDropdown.defaultProps = {
	optionsName: 'Teams',
	name: 'Teams',
	selectedGroupName: 'Selected',
	usePortal: false,
	onSelect: () => false,
	onRemove: () => false,
};

export default injectIntl(
	createFragmentContainer(TeamDropdown, {
		teams: graphql`
			fragment TeamDropdown_teams on TeamTypeEdge @relay(plural: true) {
				node {
					name
					id
				}
			}
		`,
	})
);
