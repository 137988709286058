import Item from '../../canvas-timeline/canvas_timeline_item';
import {
	DISABLED_ITEM_COLOR,
	drawRectangle,
	getMomentFromCanvasTimelineDate,
	getProjectColors,
	getTextColor,
	getTrimmedText,
	GROUP_SECTION_TEXT_GREY_DARK,
	GROUP_TYPE,
	isTimeOffAllocation,
	ITEM_TYPE,
	MONTH_NAMES_SHORT,
	TOOLTIP_DISPLAY_DELAY_MS,
} from '../../canvas-timeline/canvas_timeline_util';
import Util from '../../../../forecast-app/shared/util/util';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import AllocationItemUtil from '../../allocation_item_util';
import {getCachedMessage} from '../../../../translations/TranslationCache';
import {getAllocationTotal, getAllocationTotalNew} from '../../../scheduling/project_allocation_logic';
import DataManager from '../../DataManager';
import {getRecalculationInterval} from '../../heatmap/HeatmapLogic';
import {updateAllocation} from '../../../scheduling/scheduling_mutations';
import EventManager from '../../EventManager';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import tracking from '../../../../tracking';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import IDManager from '../../IDManager';
import {MODULE_TYPES, SCHEDULING_VIEW} from '../../../../constants';
import ComposeManager from '../../ComposeManager';
import ProjectGroup from '../groups/project_group';
import AllocationDetailUtil from '../allocation_detail_util';
import {hasModule} from '../../../../forecast-app/shared/util/ModuleUtil';
import ProgramUtil from '../../../../forecast-app/shared/util/ProgramUtil';
import {getChangeListEntityById} from '../../placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import {STAFFING_CHANGE_LIST_ENTITIES} from '../../placeholders-scheduling/CanvasPlaceholderSchedulingConstants';
import {TOOLTIP_MAX_WIDTH} from '../../constants';

class ProjectAllocationItem extends Item {
	constructor(pageComponent, data) {
		super(pageComponent, ITEM_TYPE.PROJECT_ALLOCATION, data);
		this.updateData(data);
	}

	isValid() {
		return this.data?.allocation;
	}

	isTimeOffAffectingItem() {
		const {schedulingView, isProjectTimeline} = this.pageComponent.props;
		const {allocation} = this.data;
		const hasInvertedPTONonWorkingDays = hasFeatureFlag('inverted_pto_non_working_days');
		const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;

		return !hasInvertedPTONonWorkingDays && isTimeOffAllocation(allocation) && (!isProjectScheduling || isProjectTimeline);
	}

	updateData(data, updateItem = false) {
		const hasWinAndSoftFeatureFlag = hasFeatureFlag('placeholders');
		const {allocation, color, defaultWorkingDays, getSchedulingOptions, projectWinChance, totalHours} = data;
		const {intl} = this.pageComponent.props;

		const isSoft = hasWinAndSoftFeatureFlag && allocation?.isSoft;
		const textColor = getTextColor(color);
		const projectColors = getProjectColors(color);

		const schedulingOptions = getSchedulingOptions();
		const hasBetaChanges = hasFeatureFlag('capacity_planning_beta_2_improvements');
		const useWinPercentage = isSoft && schedulingOptions.calcWin;
		const winPercentageToUse = useWinPercentage ? projectWinChance : 1;
		const asterisk = useWinPercentage && !hasBetaChanges ? '*' : '';
		const winProbText = getCachedMessage(intl, 'scheduling.win_prob_text');
		const totalTime = asterisk + Util.convertMinutesToFullHour(totalHours * 60 * winPercentageToUse, intl);
		const allocationTimeText = Util.getAllocationTimeText(
			totalTime,
			allocation,
			{defaultWorkingDays},
			intl,
			winPercentageToUse
		);

		const getUpdatedData = data => {
			data.tetextColor = textColor;
			data.projectColors = projectColors;
			data.isSoft = isSoft;
			data.allocationTimeText = allocationTimeText;
			data.totalTime = totalTime;
			data.winProbText = winProbText;
			data.schedulingOptions = schedulingOptions;
			return data;
		};

		if (updateItem) {
			this.updateWithData(getUpdatedData(data));
		} else {
			this.data = getUpdatedData(data);
		}
	}

	getTooltipData() {
		const {intl} = this.pageComponent.props;
		const {allocation, project, taskAllocationTotalEstimate, schedulingOptions} = this.data;
		let totalEstimate = taskAllocationTotalEstimate;
		let totalEstimateWin;
		const {company} = this.pageComponent.getData();
		const {formatMessage} = intl;
		const client = DataManager.getClientById(this.pageComponent, project?.clientId);
		const clientName = client?.name;
		const program = DataManager.getProgram(this.pageComponent, project?.programId);
		const calcWin = schedulingOptions.calcWin;
		const useWinChance = allocation.isSoft && project?.baselineWinChance && project.baselineWinChance < 1;
		const allocationLabelExtension =
			useWinChance && calcWin ? formatMessage({id: 'scheduling.calc_win_probability'}) : null;

		if (
			(totalEstimate === undefined || totalEstimate === null) &&
			allocation.wednesday !== null &&
			allocation.wednesday !== undefined
		) {
			const total = getAllocationTotal(allocation);
			const winChance = useWinChance ? project.baselineWinChance : 1;

			totalEstimate = AllocationDetailUtil.getAllocationTimeTextWithWinChance(
				total,
				formatMessage,
				winChance,
				allocation,
				company,
				calcWin
			);

			totalEstimateWin = AllocationDetailUtil.getAllocationTimeTextWithWinChance(
				total,
				formatMessage,
				winChance,
				allocation,
				company,
				false
			);
		}

		const canvasTooltipData = {
			maxWidth: TOOLTIP_MAX_WIDTH.PROJECT_ALLOCATION_ITEM,
		};

		// items
		canvasTooltipData.items = [];

		// title
		canvasTooltipData.title = formatMessage({id: 'common.allocation'});

		// project name
		canvasTooltipData.items.push({
			subTitle: formatMessage({id: 'common.project-name'}),
			values: [AllocationDetailUtil.getParentTextFromAllocation(this.pageComponent, allocation)],
		});

		// program
		if (program) {
			canvasTooltipData.items.push({
				subTitle: formatMessage({id: 'scheduling.part_of_program'}, {program: ProgramUtil.programText(formatMessage)}),
				values: [`${program.prefix} ${program.name}`],
			});
		}

		// client
		if (clientName) {
			canvasTooltipData.items.push({
				subTitle: formatMessage({id: 'common.client'}),
				values: [clientName],
			});
		}

		// dates
		canvasTooltipData.items.push({
			subTitle: formatMessage({id: 'common.date'}),
			values: [
				`${allocation.startDay} ${formatMessage({
					id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[allocation.startMonth - 1],
				})} ${allocation.startYear} - ${allocation.endDay} ${formatMessage({
					id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[allocation.endMonth - 1],
				})} ${allocation.endYear}`,
			],
		});

		// allocation type
		canvasTooltipData.items.push({
			subTitle: formatMessage({id: 'common.allocation_type'}),
			values: [AllocationDetailUtil.getAllocationType(allocation, formatMessage)],
		});

		// win %
		if (calcWin && project && hasModule(MODULE_TYPES.CALC_WIN_PERCENTAGE)) {
			canvasTooltipData.items.push({
				subTitle: formatMessage({id: 'project.win_chance'}),
				values: [AllocationDetailUtil.getWinProbability(project)],
			});
		}

		// estimate
		if (totalEstimate !== null && totalEstimate !== undefined) {
			canvasTooltipData.items.push({
				subTitle: formatMessage({id: 'common.allocation'}),
				values: [totalEstimate],
			});
		}

		if (allocationLabelExtension) {
			canvasTooltipData.items.push({
				subTitle: allocationLabelExtension,
				values: [formatMessage({id: 'scheduling.minutes_if_win'}, {minutes: totalEstimateWin})],
			});
		}

		return canvasTooltipData;
	}

	onMouseEnter(positionData, event) {
		const {sharedContext} = this.pageComponent.props;
		const {timeline} = this.pageComponent;

		if (!sharedContext) {
			const {allocation, project, projectGroup} = this.data;

			if (!allocation) {
				return false;
			}

			if (hasFeatureFlag('schedule_use_new_tooltip')) {
				const offsetTop = timeline.getCanvasOffset().top;
				const offsetLeft = timeline.getCanvasOffset().left;
				const canvasTooltipData = this.getTooltipData();

				this.pageComponent.setState({
					canvasTooltipX: event.clientX - offsetLeft,
					canvasTooltipY: event.clientY - offsetTop,
					canvasTooltipData,
					showCanvasTooltip: false,
				});

				setTimeout(() => {
					this.pageComponent.setState({
						showCanvasTooltip: true,
						canvasTooltipX: event.clientX - offsetLeft,
						canvasTooltipY: event.clientY - offsetTop,
					});
				}, TOOLTIP_DISPLAY_DELAY_MS);
			} else {
				this.pageComponent.setState({
					hoverX: event.clientX,
					hoverY: event.clientY,
					showDetailBox: false,
				});

				setTimeout(() => {
					this.pageComponent.setState({
						showDetailBox: true,
						detailBoxLeft: positionData.x,
						detailBoxX: event.clientX,
						detailBoxY: positionData.y,
						detailBoxTop: positionData.y - 2,
						detailBoxData: {allocation, project, projectGroup},
					});
				}, TOOLTIP_DISPLAY_DELAY_MS);
			}
		}
	}

	onMouseLeave() {
		const {sharedContext} = this.pageComponent.props;

		if (!sharedContext) {
			if (hasFeatureFlag('schedule_use_new_tooltip')) {
				this.pageComponent.setState({
					showCanvasTooltip: false,
					canvasTooltipData: null,
					canvasTooltipX: null,
					canvasTooltipY: null,
				});
			} else {
				this.pageComponent.setState({showDetailBox: false, hoverX: null, hoverY: null, detailBoxData: null});
			}
		}
	}

	onMouseMove(positionData, event) {
		if (!hasFeatureFlag('schedule_use_new_tooltip')) {
			const {sharedContext} = this.pageComponent.props;

			if (!sharedContext) {
				this.pageComponent.setState({
					hoverX: event.clientX,
					hoverY: event.clientY,
				});

				setTimeout(() => {
					this.pageComponent.setState({detailBoxX: event.clientX + 10});
				}, TOOLTIP_DISPLAY_DELAY_MS);
			}
		}
	}

	onMoving(item, group, startDifference, endDifference, dragData, movedDays) {
		const {sharedContext} = this.pageComponent.props;

		if (!this.isDisabled() && !sharedContext) {
			const {staffingModeActive} = this.pageComponent.state;
			const data = this.pageComponent.getData();
			const {nonWorkingDaysMap} = data;

			const startDate = getMomentFromCanvasTimelineDate(dragData.newStartDate || this.startDate);
			const endDate = getMomentFromCanvasTimelineDate(dragData.newEndDate || this.endDate);

			const {allocation, recalculateHeatmapCache} = this.data;

			allocation.startYear = startDate.year();
			allocation.startMonth = startDate.month() + 1;
			allocation.startDay = startDate.date();
			allocation.startDate = new Date(startDate.year(), startDate.month(), startDate.date());
			allocation.endYear = endDate.year();
			allocation.endMonth = endDate.month() + 1;
			allocation.endDay = endDate.date();
			allocation.endDate = new Date(endDate.year(), endDate.month(), endDate.date());
			this.data.totalHours = getAllocationTotalNew(allocation, nonWorkingDaysMap) / 60;

			this.updateData({
				...this.data,
				allocation,
			});

			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				DataManager.moveItemTemporarily(this.pageComponent, this);
			}

			const deltaInterval = getRecalculationInterval(this, startDifference, endDifference);
			const personId = allocation.personId;

			if (this.isTimeOffAffectingItem()) {
				if (staffingModeActive) {
					// update change list dates
					const changeListEntity = getChangeListEntityById(
						this.pageComponent,
						STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE,
						allocation.id
					);

					if (changeListEntity) {
						changeListEntity.startYear = allocation.startYear;
						changeListEntity.startMonth = allocation.startMonth;
						changeListEntity.startDay = allocation.startDay;
						changeListEntity.endYear = allocation.endYear;
						changeListEntity.endMonth = allocation.endMonth;
						changeListEntity.endDay = allocation.endDay;
					}
				}

				DataManager.updateItemsAffectedByTimeOff(this.pageComponent, personId, this.groupId, deltaInterval);
			}

			const recalculationInterval = [deltaInterval[0], deltaInterval[1]];

			if (recalculateHeatmapCache) {
				recalculateHeatmapCache(recalculationInterval);
			} else {
				DataManager.recalculateItemGroupHeatmap(this.pageComponent, this, recalculationInterval);
			}

			return true;
		}

		return false;
	}

	onMoveEnd(moveEndItem, group, initialGroup, dragData) {
		const {sharedContext} = this.pageComponent.props;

		if (!this.isDisabled() && !sharedContext && group) {
			const {staffingModeActive} = this.pageComponent.state;
			const {allocation} = this.data;

			const targetGroup = this.getTargetGroup(dragData, group);

			const startDate = getMomentFromCanvasTimelineDate(this.startDate);
			const endDate = getMomentFromCanvasTimelineDate(this.endDate);

			let personId = allocation.personId;
			if (targetGroup.groupType === GROUP_TYPE.PERSON) {
				personId = targetGroup.data.personId;
			} else if (targetGroup.groupType === GROUP_TYPE.PROJECT || targetGroup.groupType === GROUP_TYPE.NON_PROJECT_TIME) {
				const personGroup = targetGroup.parentGroup;

				if (personGroup) {
					personId = personGroup.data.personId;
				}
			}

			const onSuccess = res => {
				if (!staffingModeActive) {
					this.removeUpdateLock();
				}

				if (res) {
					Util.dispatchScheduleEvent(res);

					const destinationGroupParent = dragData.destinationGroup?.parentGroup;
					const destinationGroup = dragData?.destinationGroup;
					const movingToNewGroup = initialGroup && destinationGroup && initialGroup.id !== destinationGroup.id;

					// expand the parent group of the parent group the allocation was moved to
					if (movingToNewGroup && destinationGroupParent) {
						destinationGroupParent.setExpanded(true);
					}
				}
			};

			if (staffingModeActive) {
				const res = {
					updateAllocation: {
						allocation: {
							...allocation,
							personId: targetGroup.data.personId ? targetGroup.data.personId : allocation.personId,
							startDate,
							endDate,
						},
					},
				};

				onSuccess(res);
			} else {
				const updateData = {
					id: allocation.id,
					personId,
					startYear: startDate.year(),
					startMonth: startDate.month() + 1,
					startDay: startDate.date(),
					endYear: endDate.year(),
					endMonth: endDate.month() + 1,
					endDay: endDate.date(),
				};

				this.applyUpdateLock();

				updateAllocation(updateData, onSuccess);
			}
		}
	}

	updateFreeDragData(dragData) {
		if (!this.isDisabled()) {
			EventManager.updateFreeDragData(this, dragData);
		}
	}

	onClick() {
		if (this.isOnClickEnabled()) {
			const {data, staffingModeActive, schedulingOptions} = this.pageComponent.state;
			const {
				projectGroups,
				projects,
				idleTimes,
				projectPersons,
				viewer,
				persons,
				phases,
				company,
				holidayCalendars,
				holidayCalendarEntries,
				roles,
				teams,
				teamPersons,
			} = data;
			const {allocation} = this.data;

			showModal({
				type: MODAL_TYPE.CANVAS_CREATE,
				projectGroups,
				projects,
				idleTimes,
				projectPersons,
				actualPersonId: viewer.actualPersonId,
				persons,
				selectedPersonId: allocation.personId,
				milestones: phases,
				company,
				holidayCalendars,
				holidayCalendarEntries,
				allocation,
				roles,
				teams,
				teamPersons,
				staffingModeActive,
				schedulingOptions,
			});

			tracking.trackEvent('Open Allocation Modal');
			trackEvent('Allocation Modal', 'Opened');
		}
	}

	getTargetGroup(dragData, group) {
		const {allocation} = this.data;
		let targetGroup = group;

		const isNotDestinationGroup = this.hasFreeDragDefined() && this.isFreeDragOuterGroup(group);
		if (isNotDestinationGroup && !allocation.idleTimeId) {
			const person = DataManager.getPersonById(this.pageComponent, allocation.personId);
			const project = allocation.projectId ? DataManager.getProjectById(this.pageComponent, allocation.projectId) : null;
			const projectGroup = allocation.projectGroupId
				? DataManager.getProjectGroupById(this.pageComponent, allocation.projectGroupId)
				: null;

			const targetGroupId = IDManager.getProjectGroupId(
				this.pageComponent,
				person.id,
				project?.id,
				projectGroup?.id,
				null
			);
			const existingProjectGroup = group.groups.find(childGroup => childGroup.id === targetGroupId);

			if (existingProjectGroup) {
				targetGroup = existingProjectGroup;
			} else {
				const projectGroupData = ComposeManager.composeProjectGroup(
					this.pageComponent,
					person,
					null,
					project,
					projectGroup
				);

				if (projectGroupData) {
					targetGroup = new ProjectGroup(this.pageComponent, projectGroupData);
				}
			}

			if (targetGroup.id !== group.id) {
				dragData.innermostVisibleParentGroup = group.expanded ? targetGroup : group;
				this.groupId = targetGroup.id;
			}
		}

		return targetGroup;
	}

	draw(canvasContext, x, y, width, canvasWidth) {
		const {data, height} = this;
		const {
			color,
			allocation,
			allocationTimeText,
			totalTime,
			projectColors,
			isSoft,
			textColor,
			schedulingOptions,
			winProbText,
			project,
			projectGroup,
		} = data;
		const {intl} = this.pageComponent.props;

		const allocationNoteIconSize = 12;
		const textContainerPaddingX = 8;
		const textContainerSpacing = 2;
		const textSpacing = 4;
		const isTimeOffAllocation = !!data.allocation.idleTimeId;
		const isConnectedProject = !!data.allocation.projectGroupId;
		const drawAllocationBackground = !isTimeOffAllocation && !isConnectedProject && !isSoft;
		const minProjectColorWidth = 8;

		// visible stats
		const textMargin = 16;
		const itemStartOverflow = x < 0;
		const visibleItemStartX = itemStartOverflow ? 0 : x;
		const visibleItemEndX = x + width > canvasWidth ? canvasWidth : x + width;
		const visibleItemWidth = visibleItemEndX - visibleItemStartX;

		if (visibleItemWidth <= 0) return;

		// allocation bar
		let drawOptions;
		if (isSoft) {
			drawOptions = {
				backgroundColor: projectColors.background,
				backgroundOpacity: 0.9,
				borderRadius: 12,
				borderThickness: 1,
				borderColor: projectColors.border,
			};
		} else {
			drawOptions = {
				backgroundColor: this.updateLock ? DISABLED_ITEM_COLOR : color,
				backgroundOpacity: 0.9,
				borderRadius: 12,
			};
		}

		drawRectangle(canvasContext, x, y, width, height, drawOptions);

		canvasContext.fillStyle = textColor;
		canvasContext.font = '500 11px ' + Util.getFontFamily();

		const iconTextSpacing = textSpacing + 2;
		const allocationNoteIconWidth = allocation.description ? allocationNoteIconSize + iconTextSpacing : 0;

		let text;
		if (this.updateLock) {
			text = intl.formatMessage({id: 'scheduling.updating_time_off_allocation'});
		} else {
			text = AllocationItemUtil.getItemText(
				canvasContext,
				totalTime,
				allocation,
				allocationNoteIconSize,
				iconTextSpacing,
				visibleItemWidth,
				allocationTimeText,
				schedulingOptions,
				winProbText
			);
		}

		const pureTextWidth = canvasContext.measureText(text).width;

		// text container bar properties
		let textContainerMaxWidth = pureTextWidth + allocationNoteIconWidth + textContainerPaddingX * 2;
		let textContainerWidth =
			textContainerMaxWidth + textContainerSpacing * 2 >= visibleItemWidth ? visibleItemWidth : textContainerMaxWidth;
		const showDescriptionIcon = textContainerWidth < visibleItemWidth * 0.7;

		let textContainerContentWidth = pureTextWidth + allocationNoteIconWidth;
		if (!showDescriptionIcon) {
			textContainerMaxWidth = pureTextWidth + textContainerPaddingX * 2;
			textContainerWidth =
				textContainerMaxWidth + textContainerSpacing * 2 >= visibleItemWidth ? visibleItemWidth : textContainerMaxWidth;
			textContainerContentWidth = pureTextWidth;
		}

		const shouldDisplayTextContainer =
			textContainerMaxWidth + textContainerSpacing * 2 <= visibleItemWidth - minProjectColorWidth;

		// set property for displaying left drag handle in white / project color in post processor
		this.isDisplayingAllocatedHours = shouldDisplayTextContainer;

		// text container bar
		if (shouldDisplayTextContainer) {
			if (drawAllocationBackground) {
				drawRectangle(
					canvasContext,
					x + textContainerSpacing,
					y + textContainerSpacing,
					textContainerWidth + (visibleItemStartX - x),
					height - textContainerSpacing * 2,
					{
						backgroundColor: '#ffffff',
						borderRadius: 10,
					}
				);
			}

			const textXCoord = visibleItemStartX + textContainerPaddingX + textContainerSpacing;

			// note (description) icon
			if (allocation.description && showDescriptionIcon) {
				canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
				canvasContext.drawImage(
					cacheManager.getCommonImage(COMMON_IMAGE.ALLOCATION_NOTE_ICON),
					textXCoord + pureTextWidth + textSpacing,
					y + height / 2 - allocationNoteIconSize / 2,
					allocationNoteIconSize,
					allocationNoteIconSize
				);
			}

			// draw allocation hours
			canvasContext.fillStyle = drawAllocationBackground ? GROUP_SECTION_TEXT_GREY_DARK : projectColors.text;
			const formattedTaskName = getTrimmedText(canvasContext, text, width - 32);
			canvasContext.fillText(formattedTaskName, textXCoord, y + height / 2 + 4);

			// Show name of allocation
			const projectName = project?.name || projectGroup?.name;
			if (allocation.idleTimeName || projectName) {
				const allocationName = getTrimmedText(
					canvasContext,
					allocation.idleTimeName || projectName,
					visibleItemWidth - textMargin * 2 - textContainerContentWidth - 20
				);
				canvasContext.fillStyle = projectColors.text;
				const textWidth = canvasContext.measureText(allocationName).width;
				const textXCoord =
					x + width > canvasWidth ? canvasWidth - (textMargin + textWidth) : x + width - (textMargin + textWidth);
				canvasContext.fillText(allocationName, textXCoord, y + height / 2 + 4);
			}
		}
	}
}

export default ProjectAllocationItem;
