import {isProjectAllocationItem} from '../../SchedulingUtils';
import {
	ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN,
	ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD,
	ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT,
} from '../../constants';
import {ITEM_TYPE, updateAllItemsData, VISUALIZATION_MODE} from '../../canvas-timeline/canvas_timeline_util';
import {trackComplexEvent} from '../../../../tracking/amplitude/TrackingV2';
import {dispatch, EVENT_ID} from '../../../../containers/event_manager';

export const isVisibleAfterHideSoftToggled = (pageComponent, allocation) =>
	!allocation.isSoft || !pageComponent.state.schedulingOptions.hideSoft;

export const isVisibleAfterHideHardToggled = (pageComponent, allocation) =>
	allocation.isSoft ||
	!pageComponent.state.schedulingOptions.hideHard ||
	(allocation.idleTimeId && !allocation.isIdleTimeInternal);

export const toggleItemVisibility = (pageComponent, item) => {
	if (isProjectAllocationItem(item)) {
		item.visible =
			isVisibleAfterHideHardToggled(pageComponent, item.data.allocation) &&
			isVisibleAfterHideSoftToggled(pageComponent, item.data.allocation);
		item.resetItemRow();
	}
};

export const updateAllocationItemsVisibility = pageComponent => {
	const {items} = pageComponent.state;
	for (let i = 0; i < items.length; i++) {
		toggleItemVisibility(pageComponent, items[i]);
	}
};

// ALLOCATION CONTROL BOX EVENTS
export const onToggleHideSoft = (pageComponent, checked) => {
	trackComplexEvent('Hide Soft Allocation', 'Toggled', {checked: checked ? 'On' : 'Off'});
	pageComponent.setState(
		{
			schedulingOptions: {...pageComponent.state.schedulingOptions, hideSoft: checked},
		},
		() => {
			localStorage.setItem(ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT, checked);
			updateAllocationItemsVisibility(pageComponent);
			dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, {preventFiltering: true});
		}
	);
};

export const onToggleHideHard = (pageComponent, checked) => {
	trackComplexEvent('Hide Hard Allocation', 'Toggled', {checked: checked ? 'On' : 'Off'});
	pageComponent.setState(
		{
			schedulingOptions: {...pageComponent.state.schedulingOptions, hideHard: checked},
		},
		() => {
			localStorage.setItem(ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD, checked);
			updateAllocationItemsVisibility(pageComponent);
			dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, {preventFiltering: true});
		}
	);
};

export const onToggleCalcWin = (pageComponent, checked) => {
	trackComplexEvent('Calc Win Chance', 'Toggled', {checked: checked ? 'On' : 'Off'});
	pageComponent.setState(
		{
			schedulingOptions: {...pageComponent.state.schedulingOptions, calcWin: checked},
		},
		() => {
			localStorage.setItem(ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN, checked);

			// update items data
			const isSoftAllocation = item => item.data?.isSoft || item.itemType === ITEM_TYPE.PLACEHOLDER_ALLOCATION;
			updateAllItemsData(pageComponent, isSoftAllocation);

			dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, {preventFiltering: true});
		}
	);
};

export const canUseAllocationControls = visualizationMode =>
	visualizationMode === VISUALIZATION_MODE.COMBINATION || visualizationMode === VISUALIZATION_MODE.ALLOCATION;
