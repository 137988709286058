import {
	getPersonGroups,
	getVisualizationMode,
	isTimeOffAllocation,
	ITEM_TYPE,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import NonProjectTimeGroup from '../components/groups/non_project_time_group';
import {dispatch, EVENT_ID} from '../../../containers/event_manager';
import {isEventFromScheduling, removeFromArray} from '../utils';
import {getAllocationInterval, getTimeRegInterval, recalculateGroupHeatmapCache} from '../heatmap/HeatmapLogic';
import DataManager, {DATA_ENTITIES} from '../DataManager';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import NoContentGroup from '../components/groups/no_content_group';
import PersonGroup from '../components/groups/person_group';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import ComposeManager from '../ComposeManager';
import TaskItem from '../components/items/task_item';
import ProjectGroup from '../components/groups/project_group';
import IDManager from '../IDManager';

function getProjectGroup(pageComponent, personGroup, projectId, projectGroupId) {
	const allocationGroupId = IDManager.getProjectGroupId(
		pageComponent,
		personGroup.id,
		projectGroupId ? null : projectId,
		projectGroupId,
		null
	);
	return personGroup.groups.find(group => group.id === allocationGroupId);
}

export const handlePeopleSchedulingMutation = (pageComponent, response, args) => {
	if (!isEventFromScheduling(args)) {
		return;
	}

	const responseMutation = response?.createAllocation || response?.updateAllocation || response?.deleteAllocation;
	if (!response || responseMutation?.errors) return null;

	const data = pageComponent.getData();
	if (!data) {
		pageComponent.mutationResponse = response;
		pageComponent.setState({savingMutation: true});
		return;
	}
	pageComponent.mutationResponse = undefined;
	const {items} = pageComponent.state;
	const isUsingProjectAllocation = getVisualizationMode(
		pageComponent.state.schedulingOptions,
		data.company,
		VISUALIZATION_MODE.ALLOCATION
	);
	const isUsingCombinationMode = getVisualizationMode(
		pageComponent.state.schedulingOptions,
		data.company,
		VISUALIZATION_MODE.COMBINATION
	);

	function createOrUpdateAllocation(responseAllocation) {
		const formattedAllocation = {
			id: responseAllocation.id,
			description: responseAllocation.description,
			monday: responseAllocation.monday,
			tuesday: responseAllocation.tuesday,
			wednesday: responseAllocation.wednesday,
			thursday: responseAllocation.thursday,
			friday: responseAllocation.friday,
			saturday: responseAllocation.saturday,
			sunday: responseAllocation.sunday,
			startYear: responseAllocation.startYear,
			startMonth: responseAllocation.startMonth,
			startDay: responseAllocation.startDay,
			endYear: responseAllocation.endYear,
			endMonth: responseAllocation.endMonth,
			endDay: responseAllocation.endDay,
			personId: responseAllocation.person?.id,
			projectId: responseAllocation.project?.id,
			projectGroupId: responseAllocation.projectGroupId,
			projectGroupColor: responseAllocation.projectGroupColor,
			idleTimeId: responseAllocation.idleTime?.id,
			isIdleTimeInternal: responseAllocation.idleTime?.isInternalTime,
			idleTimeName: responseAllocation.idleTime?.name,
			registerTime: responseAllocation.registerTime,
			isSoft: responseAllocation.isSoft,
		};

		const person = data.persons.find(person => person.id === formattedAllocation.personId);
		const {projectId, projectGroupId} = formattedAllocation;

		const changedAllocation = data.allocations.find(allocation => allocation.id === responseAllocation.id);

		if (!formattedAllocation.idleTimeId) {
			formattedAllocation.displayName = projectId
				? data.projects.find(project => project.id === projectId).name
				: projectGroupId
				? data.projectGroups.find(projectGroup => projectGroup.id === projectGroupId).name
				: null;
			const personGroup = getPersonGroups(pageComponent.state.groups).find(
				group => group.id === formattedAllocation.personId
			);
			const allocationGroup = personGroup.groups.find(
				group => group.id === `${formattedAllocation.personId}-${projectGroupId || projectId}`
			);

			if (!allocationGroup) {
				const project = projectId ? DataManager.getProjectById(pageComponent, projectId) : null;
				const projectGroup = projectGroupId ? DataManager.getProjectGroupById(pageComponent, projectGroupId) : null;

				const {personId} = formattedAllocation;
				const person = data.persons.find(person => person.id === personId);
				const newAllocationProjectId = projectId
					? projectId
					: projectGroupId
					? data.projects.find(p => p.projectGroupId === projectGroupId).id
					: null;

				// if the person is not in the team of the project add him to be able to draw the allocation
				if (
					newAllocationProjectId &&
					!data.projectPersons.find(
						projectPerson =>
							projectPerson.personId === person.id && projectPerson.projectId === newAllocationProjectId
					)
				) {
					const newProjectPerson = {
						personId: person.id,
						projectId: newAllocationProjectId,
					};

					data.projectPersons.push(newProjectPerson);
				}

				// Create a UI ProjectGroup for the project or connected project
				const newProjectGroup = new ProjectGroup(
					pageComponent,
					ComposeManager.composeProjectGroup(pageComponent, personGroup.data, null, project, projectGroup)
				);
				personGroup.addChildGroup(newProjectGroup, -3);
			}
		}

		if (changedAllocation) {
			data.allocations.splice(data.allocations.indexOf(changedAllocation), 1, formattedAllocation);

			if (!hasFeatureFlag('inverted_pto_non_working_days')) {
				DataManager.removeFromLookupMaps(pageComponent, DATA_ENTITIES.ALLOCATIONS, changedAllocation);
				DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.ALLOCATIONS, formattedAllocation);
				if (isTimeOffAllocation(formattedAllocation)) {
					const interval = getAllocationInterval(formattedAllocation);
					DataManager.updateItemsAffectedByTimeOff(
						pageComponent,
						formattedAllocation.personId,
						formattedAllocation.personId,
						interval
					);
				}
			}

			const allocationItem = items.find(
				item => item.data.allocation && item.data.allocation.id === responseAllocation.id
			);

			const composedItemData = ComposeManager.composeProjectAllocation(pageComponent, formattedAllocation);

			if (composedItemData) {
				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					const personGroup = getPersonGroups(pageComponent.state.groups).find(group => group.id === person.id);
					const allocationGroup = isUsingCombinationMode
						? getProjectGroup(pageComponent, personGroup, projectId, projectGroupId)
						: personGroup;
					DataManager.moveItem(pageComponent, allocationItem, composedItemData, allocationGroup, null, true);
				} else {
					allocationItem.resetItemRow();
					allocationItem.updateData(composedItemData, true);
				}
			}
		} else {
			data.allocations.push(formattedAllocation);

			if (!hasFeatureFlag('inverted_pto_non_working_days')) {
				DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.ALLOCATIONS, formattedAllocation);
				if (isTimeOffAllocation(formattedAllocation)) {
					const interval = getAllocationInterval(formattedAllocation);
					DataManager.updateItemsAffectedByTimeOff(
						pageComponent,
						formattedAllocation.personId,
						formattedAllocation.personId,
						interval
					);
				}
			}

			const allocationItemData = ComposeManager.composeProjectAllocation(pageComponent, formattedAllocation);
			if (allocationItemData) {
				const projectAllocationItem = new ProjectAllocationItem(pageComponent, allocationItemData);

				if (projectAllocationItem.isValid()) {
					if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
						DataManager.addItem(pageComponent, projectAllocationItem);
					} else {
						pageComponent.state.items.push(projectAllocationItem);
					}
				}
			}
		}

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			recalculateGroupHeatmapCache(pageComponent, person.id);
		}
	}

	function handleAllocationTimeRegistrations(responseAllocation, responseTimeRegistrations) {
		const oldTimeRegs = data.timeRegistrations?.filter(timeReg => timeReg.allocationId === responseAllocation.id);

		if (oldTimeRegs) {
			for (const timeReg of oldTimeRegs) {
				deleteTimeReg(timeReg.id);
			}
		}

		if (responseTimeRegistrations) {
			for (const timeReg of responseTimeRegistrations) {
				createTimeReg(timeReg);
			}
		}
		const recalculateInterval = getAllocationInterval(responseAllocation);
		const personId = responseAllocation.person?.id || responseAllocation.personId;
		recalculateGroupHeatmapCache(pageComponent, personId, recalculateInterval);
	}

	function deleteAllocation(allocationToDelete) {
		const deletedAllocationId = allocationToDelete.id;
		const personId = allocationToDelete.personId;

		data.allocations.splice(data.allocations.indexOf(allocationToDelete), 1);
		if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
			DataManager.removeItem(pageComponent, item => item.data.allocation?.id === deletedAllocationId);
		} else {
			const itemIndex = items.findIndex(item => item.data.allocation && item.data.allocation.id === deletedAllocationId);
			items.splice(itemIndex, 1);
		}

		if (!hasFeatureFlag('inverted_pto_non_working_days')) {
			DataManager.removeFromLookupMaps(pageComponent, DATA_ENTITIES.ALLOCATIONS, allocationToDelete);
			if (isTimeOffAllocation(allocationToDelete)) {
				const interval = getAllocationInterval(allocationToDelete);
				DataManager.updateItemsAffectedByTimeOff(pageComponent, personId, personId, interval);
			}
		}

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			recalculateGroupHeatmapCache(pageComponent, personId);
		}
	}

	function createProjectPerson(projectOrProjectGroupId, projectPerson) {
		const personId = projectPerson.person.id;
		const projectId = projectPerson.project.id;
		const person = data.persons.find(person => person.id === personId);
		if (person) {
			const newProjectPerson = {
				id: projectPerson.id,
				personId: person.id,
				roleId: person.roleId,
				projectId: projectId,
			};

			const alreadyExists = data.projectPersons.some(pp => pp.personId === person.id && pp.projectId === projectId);

			if (!alreadyExists) {
				data.projectPersons.push(newProjectPerson);
				DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECT_PERSONS, newProjectPerson);
			}

			addProjectGroup(personId, projectOrProjectGroupId);
		}
	}

	function deleteProjectPerson(projectId, projectPerson) {
		const projectPersonId = projectPerson.id;
		const personId = projectPerson.personId;

		DataManager.removeFromLookupMaps(pageComponent, DATA_ENTITIES.PROJECT_PERSONS, projectPerson);
		removeFromArray(data.projectPersons, projectPerson => projectPerson.id === projectPersonId);
		removeFromArray(data.projectPersonByProjectMap[projectId], projectPerson => projectPerson.id === projectPersonId);
		removeFromArray(data.projectPersonByPersonMap[personId], projectPerson => projectPerson.id === projectPersonId);

		const project = data.projects.find(project => project.id === projectId);
		const personGroup = getPersonGroups(pageComponent.state.groups).find(group => group.id === personId);
		if (personGroup) {
			personGroup.removeChildGroup(
				IDManager.getProjectGroupId(pageComponent, personId, project.id, project.projectGroupId)
			);
		}

		recalculateGroupHeatmapCache(pageComponent, personId);
		pageComponent.clearCachedUnassignedTasksCount();
	}

	function addProjectGroup(personId, projectOrGroupProjectId) {
		const personGroup = getPersonGroups(pageComponent.state.groups).find(group => group.id === personId);
		if (!personGroup) {
			// E.g. client users, etc.
			return;
		}
		const project = DataManager.getProjectById(pageComponent, projectOrGroupProjectId);
		let projectGroup = project ? undefined : DataManager.getProjectGroupById(pageComponent, projectOrGroupProjectId);
		if (project?.projectGroupId) {
			projectGroup = DataManager.getProjectGroupById(pageComponent, project.projectGroupId);
		}

		const projectGroupId = IDManager.getProjectGroupId(pageComponent, personId, project?.id, projectGroup?.id);
		const allocationGroup = personGroup.groups.find(group => group.id === projectGroupId);
		if (!allocationGroup) {
			// Create a UI ProjectGroup for the project or connected project
			const newProjectGroup = new ProjectGroup(
				pageComponent,
				ComposeManager.composeProjectGroup(pageComponent, personGroup.data, null, project, projectGroup)
			);
			personGroup.addChildGroup(newProjectGroup, -3);
			recalculateGroupHeatmapCache(pageComponent, personId);
		}
	}

	function updateTaskAssignees(updatedTask) {
		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			recalculateGroupHeatmapCache(pageComponent, updatedTask.roleId);
		}

		if (updatedTask.assignedPersons.length) {
			for (const personId of updatedTask.assignedPersons) {
				recalculateGroupHeatmapCache(pageComponent, personId);
				const taskItemData = ComposeManager.composeTaskItem(pageComponent, updatedTask, personId);

				if (!taskItemData) {
					continue;
				}

				addProjectGroup(personId, updatedTask.projectId);

				const taskItem = new TaskItem(pageComponent, taskItemData);
				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					DataManager.addItem(pageComponent, taskItem);
				} else {
					items.push(taskItem);
				}
			}
		} else {
			const taskItemData = ComposeManager.composeTaskItem(pageComponent, updatedTask, null);
			if (taskItemData) {
				const taskItem = new TaskItem(pageComponent, taskItemData);

				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					DataManager.addItem(pageComponent, taskItem);
				} else {
					items.push(taskItem);
				}
			}
		}
	}

	function deleteTaskItems(taskId) {
		// delete the task from all assigned people and unassigned section
		if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
			DataManager.removeMultipleItems(pageComponent, item => item.data.task?.id === taskId);
		} else {
			const getTaskItemIndex = () => {
				return items.findIndex(item => item.itemType === ITEM_TYPE.TASK && item.data.task.id === taskId);
			};

			let itemIndex = getTaskItemIndex();
			while (itemIndex > -1) {
				items.splice(itemIndex, 1);
				itemIndex = getTaskItemIndex();
			}
		}
	}

	function updateTimeReg(responseTimeRegistration) {
		const task =
			responseTimeRegistration.task?.id && !responseTimeRegistration.project?.id
				? DataManager.getTaskById(pageComponent, responseTimeRegistration.task?.id)
				: null;
		const projectOrTaskProjectId = responseTimeRegistration.project?.id || task?.projectId;

		const updatedTimeRegistration = {
			id: responseTimeRegistration.id,
			day: responseTimeRegistration.day,
			month: responseTimeRegistration.month,
			year: responseTimeRegistration.year,
			minutesRegistered: responseTimeRegistration.minutesRegistered,
			personId: responseTimeRegistration.person.id,
			taskId: responseTimeRegistration.task && responseTimeRegistration.task.id,
			projectId: responseTimeRegistration.project && responseTimeRegistration.project.id,
			idleTimeId: responseTimeRegistration.idleTime && responseTimeRegistration.idleTime.id,
			isIdleTimeInternal: responseTimeRegistration.idleTime ? responseTimeRegistration.idleTime.isInternalTime : null,
			isTimeOff: responseTimeRegistration.idleTime ? responseTimeRegistration.idleTime.isInternalTime === false : false,
			canvasTimelineDate: responseTimeRegistration.canvasTimelineDate,
			projectOrTaskProjectId,
		};

		const recalculateInterval = getTimeRegInterval(updatedTimeRegistration);

		DataManager.updateTimeRegInLookupMaps(pageComponent, updatedTimeRegistration);

		const changedTimeRegistration = data.timeRegistrations.find(
			timeRegistration => timeRegistration.id === responseTimeRegistration.id
		);
		data.timeRegistrations.splice(data.timeRegistrations.indexOf(changedTimeRegistration), 1, updatedTimeRegistration);

		const taskTimeRegsMap = data.timeRegsByTaskMap[changedTimeRegistration.taskId];
		removeFromArray(taskTimeRegsMap, timeReg => timeReg.id === changedTimeRegistration.id, updatedTimeRegistration);

		const personTimeRegsMap = data.timeRegsByPersonMap[changedTimeRegistration.personId];
		removeFromArray(personTimeRegsMap, timeReg => timeReg.id === changedTimeRegistration.id, updatedTimeRegistration);

		if (updatedTimeRegistration.taskId) {
			const changedTask = data.taskMap.get(updatedTimeRegistration.taskId);
			changedTask.timeLeft = responseTimeRegistration.task.timeLeft;
			changedTask.timeLeftMinutesWithoutFutureTimeRegs =
				responseTimeRegistration.task.timeLeftMinutesWithoutFutureTimeRegs;

			//Delete the task from all assigned people and unassigned section
			deleteTaskItems(updatedTimeRegistration.taskId);

			const recalculateIntervalOld = getTimeRegInterval(changedTimeRegistration);

			if (!hasFeatureFlag('tp_heatmap_spike')) {
				recalculateGroupHeatmapCache(pageComponent, changedTask.roleId, recalculateInterval);
				recalculateGroupHeatmapCache(pageComponent, changedTask.roleId, recalculateIntervalOld);
			}

			if (changedTask.assignedPersons.length) {
				pageComponent.clearCachedUnassignedTasksCount();
				for (const personId of changedTask.assignedPersons) {
					if (!hasFeatureFlag('tp_heatmap_spike')) {
						recalculateGroupHeatmapCache(pageComponent, personId, recalculateInterval);
						recalculateGroupHeatmapCache(pageComponent, personId, recalculateIntervalOld);
					}

					const taskItemData = ComposeManager.composeTaskItem(pageComponent, changedTask, personId);
					if (!taskItemData) continue;
					const taskItem = new TaskItem(pageComponent, taskItemData);
					if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
						DataManager.addItem(pageComponent, taskItem);
					} else {
						items.push(taskItem);
					}
				}
			} else {
				const taskItemData = ComposeManager.composeTaskItem(pageComponent, changedTask, null);
				if (taskItemData) {
					const taskItem = new TaskItem(pageComponent, taskItemData);
					if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
						DataManager.addItem(pageComponent, taskItem);
					} else {
						items.push(taskItem);
					}
				}
			}

			if (hasFeatureFlag('tp_heatmap_spike')) {
				recalculateHeatmapCacheForTimeReg(updatedTimeRegistration);
				recalculateHeatmapCacheForTimeReg(changedTimeRegistration);
			}
		}
	}

	function createTimeReg(responseTimeRegistration) {
		const task =
			responseTimeRegistration.task?.id && !responseTimeRegistration.project?.id
				? DataManager.getTaskById(pageComponent, responseTimeRegistration.task?.id)
				: null;
		const projectOrTaskProjectId = responseTimeRegistration.project?.id || task?.projectId;

		const updatedTimeRegistration = {
			id: responseTimeRegistration.id,
			day: responseTimeRegistration.day,
			month: responseTimeRegistration.month,
			year: responseTimeRegistration.year,
			minutesRegistered: responseTimeRegistration.minutesRegistered,
			personId: responseTimeRegistration.person.id,
			taskId: responseTimeRegistration.task && responseTimeRegistration.task.id,
			projectId: responseTimeRegistration.project && responseTimeRegistration.project.id,
			idleTimeId: responseTimeRegistration.idleTime && responseTimeRegistration.idleTime.id,
			isIdleTimeInternal: responseTimeRegistration.idleTime ? responseTimeRegistration.idleTime.isInternalTime : null,
			isTimeOff: responseTimeRegistration.idleTime ? responseTimeRegistration.idleTime.isInternalTime === false : false,
			canvasTimelineDate: responseTimeRegistration.canvasTimelineDate,
			allocationId: responseTimeRegistration.allocationId,
			projectOrTaskProjectId,
		};

		const recalculateInterval = getTimeRegInterval(updatedTimeRegistration);

		data.timeRegistrations.push(updatedTimeRegistration);

		const personTimeRegsMap = data.timeRegsByPersonMap[updatedTimeRegistration.personId];
		if (personTimeRegsMap) {
			personTimeRegsMap.push(updatedTimeRegistration);
		} else {
			data.timeRegsByPersonMap[updatedTimeRegistration.personId] = [updatedTimeRegistration];
		}

		DataManager.addTimeRegToLookupMaps(pageComponent, updatedTimeRegistration);

		let changedTask;
		if (updatedTimeRegistration.taskId) {
			const taskTimeRegsMap = data.timeRegsByTaskMap[updatedTimeRegistration.taskId];
			if (taskTimeRegsMap) {
				taskTimeRegsMap.push(updatedTimeRegistration);
			} else {
				data.timeRegsByTaskMap[updatedTimeRegistration.taskId] = [updatedTimeRegistration];
			}

			changedTask = data.taskMap.get(updatedTimeRegistration.taskId);
			changedTask.timeLeft = responseTimeRegistration.task.timeLeft;
			changedTask.timeLeftMinutesWithoutFutureTimeRegs =
				responseTimeRegistration.task.timeLeftMinutesWithoutFutureTimeRegs;

			deleteTaskItems(updatedTimeRegistration.taskId);

			// role cache
			if (!hasFeatureFlag('tp_heatmap_spike')) {
				recalculateGroupHeatmapCache(pageComponent, changedTask.roleId, recalculateInterval);
			}

			if (changedTask.assignedPersons.length) {
				pageComponent.clearCachedUnassignedTasksCount();

				for (const personId of changedTask.assignedPersons) {
					if (!hasFeatureFlag('tp_heatmap_spike')) {
						recalculateGroupHeatmapCache(pageComponent, personId, recalculateInterval);
					}

					const taskItemData = ComposeManager.composeTaskItem(pageComponent, changedTask, personId);

					if (taskItemData) {
						const taskItem = new TaskItem(pageComponent, taskItemData);

						if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
							DataManager.addItem(pageComponent, taskItem);
						} else {
							items.push(taskItem);
						}
					}
				}

				if (
					!hasFeatureFlag('tp_heatmap_spike') &&
					!changedTask.assignedPersons.includes(updatedTimeRegistration.personId)
				) {
					recalculateGroupHeatmapCache(pageComponent, updatedTimeRegistration.personId, recalculateInterval);
				}
			} else {
				const taskItemData = ComposeManager.composeTaskItem(pageComponent, changedTask, null);
				if (taskItemData) {
					const taskItem = new TaskItem(pageComponent, taskItemData);
					if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
						DataManager.addItem(pageComponent, taskItem);
					} else {
						items.push(taskItem);
					}
				}
			}
		} else if (!hasFeatureFlag('tp_heatmap_spike')) {
			recalculateGroupHeatmapCache(pageComponent, updatedTimeRegistration.personId, recalculateInterval);
		}

		if (hasFeatureFlag('tp_heatmap_spike')) {
			recalculateHeatmapCacheForTimeReg(updatedTimeRegistration);
		}
	}

	function deleteTimeReg(deletedTimeRegId) {
		const timeRegistrationToDelete = data.timeRegistrations.find(
			timeRegistration => timeRegistration.id === deletedTimeRegId
		);
		if (timeRegistrationToDelete) {
			data.timeRegistrations.splice(data.timeRegistrations.indexOf(timeRegistrationToDelete), 1);

			DataManager.removeTimeRegFromLookupMaps(pageComponent, timeRegistrationToDelete);

			if (timeRegistrationToDelete.taskId) {
				const changedTask = data.taskMap.get(timeRegistrationToDelete.taskId);
				changedTask.timeLeft = response.deleteTimeReg.task.timeLeft;
				changedTask.timeLeftMinutesWithoutFutureTimeRegs =
					response.deleteTimeReg.task.timeLeftMinutesWithoutFutureTimeRegs;

				const taskTimeRegsMap = data.timeRegsByTaskMap[timeRegistrationToDelete.taskId];
				removeFromArray(taskTimeRegsMap, timeReg => timeReg.id === timeRegistrationToDelete.id);

				const personTimeRegsMap = data.timeRegsByPersonMap[timeRegistrationToDelete.personId];
				removeFromArray(personTimeRegsMap, timeReg => timeReg.id === timeRegistrationToDelete.id);

				deleteTaskItems(changedTask.id);

				const recalculateInterval = getTimeRegInterval(timeRegistrationToDelete);
				if (!hasFeatureFlag('tp_heatmap_spike')) {
					recalculateGroupHeatmapCache(pageComponent, changedTask.roleId, recalculateInterval);
				}

				if (changedTask.assignedPersons.length) {
					pageComponent.clearCachedUnassignedTasksCount();
					for (const personId of changedTask.assignedPersons) {
						if (!hasFeatureFlag('tp_heatmap_spike')) {
							recalculateGroupHeatmapCache(pageComponent, personId, recalculateInterval);
						}

						const taskItemData = ComposeManager.composeTaskItem(pageComponent, changedTask, personId);
						if (!taskItemData) continue;
						const taskItem = new TaskItem(pageComponent, taskItemData);
						if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
							DataManager.addItem(pageComponent, taskItem);
						} else {
							items.push(taskItem);
						}
					}
				} else {
					const taskItemData = ComposeManager.composeTaskItem(pageComponent, changedTask, null);
					if (taskItemData) {
						const taskItem = new TaskItem(pageComponent, taskItemData);
						if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
							DataManager.addItem(pageComponent, taskItem);
						} else {
							items.push(taskItem);
						}
					}
				}
			} else {
				const personTimeRegsMap = data.timeRegsByPersonMap[timeRegistrationToDelete.personId];
				removeFromArray(personTimeRegsMap, timeReg => timeReg.id === timeRegistrationToDelete.id);

				if (!hasFeatureFlag('tp_heatmap_spike')) {
					recalculateGroupHeatmapCache(pageComponent, timeRegistrationToDelete.personId, [
						timeRegistrationToDelete.canvasTimelineDate,
						timeRegistrationToDelete.canvasTimelineDate,
					]);
				}
			}

			if (hasFeatureFlag('tp_heatmap_spike')) {
				recalculateHeatmapCacheForTimeReg(timeRegistrationToDelete);
			}
		}
	}

	function recalculateHeatmapCacheForTimeReg(timeReg) {
		const task = DataManager.getTaskById(pageComponent, timeReg.taskId);
		const personIds = new Set([...(task?.assignedPersons || []), timeReg.personId]);
		const recalculationInterval = [timeReg.canvasTimelineDate, timeReg.canvasTimelineDate];

		for (const personId of personIds) {
			const personGroupId = IDManager.getPersonGroupId(pageComponent, personId);
			if (personGroupId) {
				recalculateGroupHeatmapCache(pageComponent, personGroupId, recalculationInterval);
			}

			if (task) {
				const project = DataManager.getProjectById(pageComponent, task.projectId);

				if (project) {
					const projectId = project.projectGroupId ? null : project.id;
					const projectGroupId = IDManager.getProjectGroupId(
						pageComponent,
						personId,
						projectId,
						project.projectGroupId,
						null
					);

					if (projectGroupId) {
						recalculateGroupHeatmapCache(pageComponent, projectGroupId, recalculationInterval);
					}
				}
			}
		}
	}

	if (response.createAllocation || response.updateAllocation) {
		const responseAllocation = response.updateAllocation
			? response.updateAllocation.allocation
			: response.createAllocation.allocation.node;
		const responseTimeRegistrations = response.updateAllocation
			? response.updateAllocation.timeRegistrations
			: response.createAllocation.timeRegistrations;

		const payload = response.updateAllocation || response.createAllocation;
		const projectOrGroupId = payload.project?.id || payload.projectGroup?.id;
		const projectPersons =
			payload.project?.projectPersons.edges ||
			payload.projectGroup?.projects?.edges.map(edge => edge.node.projectPersons.edges).flat() ||
			[];
		for (const projectPerson of projectPersons) {
			createProjectPerson(projectOrGroupId, projectPerson.node);
		}
		createOrUpdateAllocation(responseAllocation);
		handleAllocationTimeRegistrations(responseAllocation, responseTimeRegistrations);
	} else if (response.deleteAllocation) {
		const allocationToDelete = data.allocations.find(
			allocation => allocation.id === response.deleteAllocation.deletedAllocationId
		);
		deleteAllocation(allocationToDelete);
		handleAllocationTimeRegistrations(allocationToDelete);
	} else if (response.createProjectPerson) {
		const projectId = response.createProjectPerson.project?.id || response.createProjectPerson.projectGroup?.id;
		if (response.createProjectPerson.projectPersons) {
			for (const projectPerson of response.createProjectPerson.projectPersons) {
				createProjectPerson(projectId, projectPerson);
			}
		}
	} else if (response.deleteProjectPerson) {
		const deletedProjectPersonIds = response.deleteProjectPerson.deletedProjectPersonIds;
		const deletedProjectPersons = data.projectPersons.filter(projectPerson =>
			deletedProjectPersonIds.includes(projectPerson.id)
		);
		for (const deletedProjectPerson of deletedProjectPersons) {
			// In scheduling, for connected projects, allocations are stored on projectGroup instead of project (which is how it is in service-company).
			const projectGroupId = response.deleteProjectPerson.projectGroup?.id;
			const allocationsToDelete = data.allocations.filter(
				allocation =>
					allocation.personId === deletedProjectPerson.personId &&
					(allocation.projectId === deletedProjectPerson.projectId ||
						(projectGroupId && allocation.projectGroupId === projectGroupId))
			);
			for (const allocationToDelete of allocationsToDelete) {
				deleteAllocation(allocationToDelete);
			}

			const tasksToUnassign = data.tasks.filter(
				task =>
					task.projectId === deletedProjectPerson.projectId &&
					task.assignedPersons.includes(deletedProjectPerson.personId)
			);
			for (const task of tasksToUnassign) {
				task.assignedPersons = task.assignedPersons.filter(assignee => assignee !== deletedProjectPerson.personId);
				deleteTaskItems(task.id);
				updateTaskAssignees(task);
			}

			deleteProjectPerson(deletedProjectPerson.projectId, deletedProjectPerson);
		}
		pageComponent.clearCachedUnassignedTasksCount();
	} else if (response.updateTask || response.createTask || response.duplicateTask) {
		const responseTask =
			response.updateTask && response.updateTask.tasks
				? response.updateTask.tasks[0]
				: response.updateTask && response.updateTask.taskEdge
				? response.updateTask.taskEdge.node
				: response.duplicateTask
				? response.duplicateTask.task.node
				: response.createTask.task.node;
		const projectId = responseTask.project?.id;
		if (!projectId) {
			// Unsupported task response. Maybe from delayed non-scheduling mutation (e.g. ReorderProjectBoardTaskMutation)
			return;
		}

		const project = DataManager.getProjectById(pageComponent, projectId);
		const projectGroupId = project?.projectGroupId;

		const updatedTask = {
			approved: true,
			assignedPersons: responseTask.assignedPersons.map(assignedPerson => {
				return assignedPerson.id;
			}),
			followers: responseTask.followers
				? responseTask.followers.map(followerPerson => {
						return followerPerson.id;
				  })
				: [],
			billable: responseTask.billable,
			blocked: responseTask.blocked,
			bug: responseTask.bug,
			companyTaskId: responseTask.companyTaskId,
			deadlineDay: responseTask.deadlineDay,
			deadlineMonth: responseTask.deadlineMonth,
			deadlineYear: responseTask.deadlineYear,
			deadlineFrom: responseTask.deadlineFrom,
			startFrom: responseTask.startFrom,
			done: responseTask.done,
			estimateForecast: responseTask.estimateForecast,
			estimateForecastMinutes: responseTask.estimateForecastMinutes,
			highPriority: responseTask.highPriority,
			id: responseTask.id,
			taskLabels: responseTask.taskLabels?.map(t => ({labelId: t.label.id})),
			projectId,
			projectGroupId,
			roleId: responseTask.role ? responseTask.role.id : undefined,
			phaseId: responseTask.phase ? responseTask.phase.id : undefined,
			startDay: responseTask.startDay,
			startMonth: responseTask.startMonth,
			startYear: responseTask.startYear,
			timeLeft: responseTask.timeLeft,
			timeLeftMinutesWithoutFutureTimeRegs: responseTask.timeLeftMinutesWithoutFutureTimeRegs,
			statusColumnId: responseTask.statusColumnV2.id,
			name: responseTask.name,
			ownerId: responseTask.owner ? responseTask.owner.id : undefined,
			fullAccessToProject: true,
			readOnly: {
				isReadOnly: responseTask.isReadOnly,
				reasons: responseTask.reasons,
			},
			userCanDeleteTask: responseTask.userCanDeleteTask,
			userCantDeleteTaskReason: responseTask.userCantDeleteTaskReason,
			progress: responseTask.progress || 0,
		};

		if (response.updateTask) {
			const changedTask = DataManager.getTaskById(pageComponent, responseTask.id);
			data.tasks.splice(data.tasks.indexOf(changedTask), 1, updatedTask);
			DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.TASKS, updatedTask);

			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				const {groups} = pageComponent.state;

				const assignedPersons = updatedTask.assignedPersons;
				const previousAssignedPersons = changedTask.assignedPersons;
				const createTasksForPersons = assignedPersons.filter(
					assignedPerson => !previousAssignedPersons.includes(assignedPerson)
				);

				const deleteTasksForPersons = previousAssignedPersons.filter(
					assignedPerson => !assignedPersons.includes(assignedPerson)
				);

				const updateTasksForPersons = assignedPersons.filter(assignedPerson =>
					previousAssignedPersons.includes(assignedPerson)
				);

				const personGroups =
					getPersonGroups(groups)?.filter(group =>
						[...createTasksForPersons, ...deleteTasksForPersons, ...updateTasksForPersons].includes(group.id)
					) || [];

				const isInSubGroups = (parentGroup, item) => parentGroup.groups.some(group => group.id === item.groupId);
				const isSameTask = item => item.data?.task?.id === responseTask.id;

				const isUsingCombinationMode = getVisualizationMode(
					pageComponent.state.schedulingOptions,
					data.company,
					VISUALIZATION_MODE.COMBINATION
				);

				const sameTaskItems = items.filter(item => isSameTask(item));
				createTasksForPersons.forEach(assignedPerson => {
					const personGroup = personGroups.find(group => group.id === assignedPerson);

					if (personGroup) {
						const allocationGroup = isUsingCombinationMode
							? getProjectGroup(pageComponent, personGroup, projectId, projectGroupId)
							: personGroup;
						const duplicateTaskItems = sameTaskItems.filter(item => isInSubGroups(allocationGroup, item));
						// Cleanup old items with old data, replace with the updated task
						if (duplicateTaskItems) {
							DataManager.removeItem(pageComponent, item => duplicateTaskItems.includes(item));
						}
						const taskItemData = ComposeManager.composeTaskItem(pageComponent, updatedTask, assignedPerson);

						if (taskItemData) {
							const taskItem = new TaskItem(pageComponent, taskItemData);
							DataManager.addItem(pageComponent, taskItem);
						}
					}

					if (!hasFeatureFlag('scheduling_recalculation_tree')) {
						recalculateGroupHeatmapCache(pageComponent, assignedPerson);
					}
				});

				deleteTasksForPersons.forEach(assignedPerson => {
					const personGroup = personGroups.find(group => group.id === assignedPerson);

					if (personGroup) {
						const allocationGroup = isUsingCombinationMode
							? getProjectGroup(pageComponent, personGroup, projectId, projectGroupId)
							: personGroup;
						DataManager.removeItem(pageComponent, item => isSameTask(item) && isInSubGroups(allocationGroup, item));
					}

					if (!hasFeatureFlag('scheduling_recalculation_tree')) {
						recalculateGroupHeatmapCache(pageComponent, assignedPerson);
					}
				});

				updateTasksForPersons.forEach(assignedPerson => {
					const personGroup = personGroups.find(group => group.id === assignedPerson);

					if (personGroup) {
						const allocationGroup = isUsingCombinationMode
							? getProjectGroup(pageComponent, personGroup, projectId, projectGroupId)
							: personGroup;
						const [taskItem, ...duplicateTaskItems] = sameTaskItems.filter(item =>
							isInSubGroups(allocationGroup, item)
						);

						if (taskItem) {
							DataManager.moveItem(
								pageComponent,
								taskItem,
								ComposeManager.composeTaskItem(pageComponent, updatedTask, assignedPerson),
								allocationGroup,
								null,
								true
							);
						}
						// After a free drag event, clean up the old dragged item.
						if (duplicateTaskItems) {
							DataManager.removeItem(pageComponent, item => duplicateTaskItems.includes(item));
						}
					}

					if (!hasFeatureFlag('scheduling_recalculation_tree')) {
						recalculateGroupHeatmapCache(pageComponent, assignedPerson);
					}
				});

				const isUnassigned = assignedPersons.length === 0;
				const wasUnassigned = previousAssignedPersons.length === 0;
				if (wasUnassigned) {
					const oldRoleGroupId = IDManager.getUnassignedTaskGroupId(pageComponent, changedTask.roleId);
					DataManager.removeItem(pageComponent, item => isSameTask(item) && item.groupId === oldRoleGroupId);
				}
				if (isUnassigned) {
					const taskItemData = ComposeManager.composeTaskItem(pageComponent, updatedTask, null);

					if (taskItemData) {
						const taskItem = new TaskItem(pageComponent, taskItemData);
						DataManager.addItem(pageComponent, taskItem);
					}
				}
			} else {
				// Find one of the old task items to get assigned person before the update
				const item = items.find(item => item.itemType === ITEM_TYPE.TASK && item.data.task.id === responseTask.id);

				if (item && item.data && item.data.task && item.data.task.assignedPersons) {
					const oldAssigned = item.data.task.assignedPersons;

					// Delete the heatmap cache for the old assigned persons that are no longer in assignedPersons
					for (const oldAssignedId of oldAssigned) {
						if (!updatedTask.assignedPersons.includes(oldAssignedId)) {
							recalculateGroupHeatmapCache(pageComponent, oldAssignedId);
						}
					}
				}

				deleteTaskItems(responseTask.id);
			}
		} else {
			data.tasks.push(updatedTask);
			DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.TASKS, updatedTask);

			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				updateTaskAssignees(updatedTask);
			}
		}

		if (responseTask.project.projectPersons?.edges) {
			for (const projectPerson of responseTask.project?.projectPersons?.edges) {
				createProjectPerson(projectId, projectPerson.node);
			}
		}

		if (!hasFeatureFlag('improving_heatmap_frontend_performance')) {
			updateTaskAssignees(updatedTask);
		}

		pageComponent.clearCachedUnassignedTasksCount();
	} else if (response.deleteTask) {
		const taskToDelete = data.taskMap.get(response.deleteTask.deletedTasksIds[0]);
		if (taskToDelete) {
			taskToDelete.assignedPersons.forEach(person => {
				recalculateGroupHeatmapCache(pageComponent, person);
			});
			data.tasks.splice(data.tasks.indexOf(taskToDelete), 1);
			data.taskMap.delete(response.deleteTask.deletedTasksIds[0]);

			deleteTaskItems(response.deleteTask.deletedTasksIds[0]);
		}
		pageComponent.clearCachedUnassignedTasksCount();
	} else if ((response.createTimeReg || response.updateTimeReg) && !isUsingProjectAllocation) {
		const responseTimeRegistration = response.updateTimeReg
			? response.updateTimeReg.timeReg
			: response.createTimeReg.timeReg.node;

		if (response.updateTimeReg) {
			updateTimeReg(responseTimeRegistration);
		} else {
			createTimeReg(responseTimeRegistration);
		}
	} else if (response.deleteTimeReg) {
		deleteTimeReg(response.deleteTimeReg.deletedTimeRegId);
	} else if (response.deleteDependency) {
		// If we don't plan on showing dependancies on People Schedule, this should be removed along with dependancy data
		const index = data.dependencies.findIndex(
			dependency => dependency.id === response.deleteDependency.deletedDependencyId
		);
		if (index >= 0) {
			data.dependencies.splice(index, 1);
		}
	} else if (response.createDependency) {
		// If we don't plan on showing dependancies on People Schedule, this should be removed along with dependancy data
		if (response.createDependency.errors) return;
		const responseDependency = response.createDependency.dependency.node;
		data.dependencies.push({
			id: responseDependency.id,
			thisDependsOnTaskId: responseDependency.thisDependsOnTask.id,
			taskIdDependsOnThis: responseDependency.taskDependsOnThis.id,
			type: responseDependency.type,
		});
	} else if (response.updateDependency) {
		// If we don't plan on showing dependancies on People Schedule, this should be removed along with dependancy data
		if (response.updateDependency.errors) return;
		const dependency = data.dependencies.find(dependency => dependency.id === response.updateDependency.dependency.id);
		if (!dependency) return;
		const responseDependency = response.createDependency.dependency.node;
		dependency.type = responseDependency.type;
		dependency.thisDependsOnTaskId = responseDependency.thisDependsOnTask.id;
		dependency.taskIdDependsOnThis = responseDependency.taskDependsOnThis.id;
	} else if (response.createClient) {
		const client = response.createClient.client.node;
		data.clients.push({
			id: client.id,
			name: client.name,
		});
	} else if (response.createMultipleAllocations) {
		const project = response.createMultipleAllocations.project || response.createMultipleAllocations.projectGroup;
		if (project?.allocations?.edges) {
			for (const allocationEdge of project.allocations.edges) {
				createOrUpdateAllocation(allocationEdge.node);
			}
		}
	} else if (response.createProject || response.duplicateProject) {
		const project = response.duplicateProject
			? response.duplicateProject.project.node
			: response.createProject.project.node;

		// we get the response twice when creating project allocation project, ensure not to add things twice
		const alreadyExists = data.projects.find(dataProject => dataProject.id === project.id);

		if (alreadyExists) return;

		const formattedProject = {
			id: project.id,
			status: project.status,
			companyProjectId: project.companyProjectId,
			customProjectId: project.customProjectId,
			name: project.name,
			projectColor: project.projectColor,
			useManualAllocations: project.useManualAllocations,
			completion: project.completion,
			remaining: project.remaining,
			projectStartDay: project.projectStartDay,
			projectStartMonth: project.projectStartMonth,
			projectStartYear: project.projectStartYear,
			projectEndDay: project.projectEndDay,
			projectEndMonth: project.projectEndMonth,
			projectEndYear: project.projectEndYear,
			estimationUnit: project.estimationUnit,
			minutesPerEstimationPoint: project.minutesPerEstimationPoint,
			isInProjectGroup: project.isInProjectGroup,
			projectGroupId: project.projectGroupId,
			forecast: project.forecast,
			clientId: project.client ? project.client.id : null,
			fullAccessToProject: true,
		};
		data.projects.push(formattedProject);
		const newProjectStatus = {
			color: '#33cc33',
			projectId: project.id,
		};
		DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECTS, formattedProject);
		data.projectStatuses.push(newProjectStatus);
		DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECT_STATUSES, newProjectStatus);

		if (project.projectLabels) {
			for (const projectLabelEdge of project.projectLabels.edges) {
				const projectLabel = projectLabelEdge.node;
				const newProjectLabel = {
					id: projectLabel.id,
					labelId: projectLabel.label.id,
					projectId: project.id,
				};
				data.projectLabels.push(newProjectLabel);
				const projectLabelsOnProject = data.projectLabelByProjectMap[project.id] || [];
				data.projectLabelByProjectMap[project.id] = projectLabelsOnProject;
				projectLabelsOnProject.push(newProjectLabel);
			}
		}

		if (project.tasks) {
			for (const taskEdge of project.tasks.edges) {
				const task = taskEdge.node;
				const taskData = {
					projectId: project.id,
					approved: true,
					assignedPersons: task.assignedPersons.map(assignedPerson => {
						return assignedPerson.id;
					}),
					billable: task.billable,
					blocked: task.blocked,
					bug: task.bug,
					companyTaskId: task.companyTaskId,
					deadlineDay: task.deadlineDay,
					deadlineMonth: task.deadlineMonth,
					deadlineYear: task.deadlineYear,
					deadlineFrom: task.deadlineFrom,
					startFrom: task.startFrom,
					done: task.done,
					estimateForecast: task.estimateForecast,
					estimateForecastMinutes: task.estimateForecastMinutes,
					highPriority: task.highPriority,
					id: task.id,
					taskLabels: task.taskLabels.map(t => ({labelId: t.label.id})),
					roleId: task.role ? task.role.id : undefined,
					phaseId: task.phase ? task.phase.id : undefined,
					startDay: task.startDay,
					startMonth: task.startMonth,
					startYear: task.startYear,
					timeLeft: task.timeLeft,
					timeLeftMinutesWithoutFutureTimeRegs: task.timeLeftMinutesWithoutFutureTimeRegs,
					name: task.name,
					fullAccessToProject: true,
				};
				data.tasks.push(taskData);
				DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.TASKS, taskData);
			}
		}

		if (project.phases) {
			for (const phaseEdge of project.phases.edges) {
				const phase = phaseEdge.node;

				const updatedPhase = {
					id: phase.id,
					deadlineDay: phase.deadlineDay,
					deadlineMonth: phase.deadlineMonth,
					deadlineYear: phase.deadlineYear,
					startDay: phase.startDay,
					startMonth: phase.startMonth,
					startYear: phase.startYear,
					name: phase.name,
					projectId: project.id,
				};
				data.phases.push(updatedPhase);
				DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PHASES, updatedPhase);
			}
		}

		if (project.projectPersons) {
			for (const projectPersonEdge of project.projectPersons.edges) {
				createProjectPerson(project.id, projectPersonEdge.node);
			}
		}
	} else if (response.createPerson) {
		const person = response.createPerson.person.node;
		const personSubGroups = [];
		personSubGroups.push(
			new NonProjectTimeGroup(pageComponent, ComposeManager.composeNonProjectTimeGroup(pageComponent, person))
		);

		personSubGroups.push(
			new NoContentGroup(pageComponent, ComposeManager.composeNoContentGroup(pageComponent, person, null, null))
		);

		const personGroupData = ComposeManager.composePersonGroup(pageComponent, person, null, personSubGroups);
		const personGroup = new PersonGroup(pageComponent, personGroupData);

		pageComponent.state.groups.splice(-1, 0, personGroup);

		data.persons.push({
			id: person.id,
			firstName: person.firstName,
			lastName: person.lastName,
			monday: person.monday,
			tuesday: person.tuesday,
			wednesday: person.wednesday,
			thursday: person.thursday,
			friday: person.friday,
			saturday: person.saturday,
			sunday: person.sunday,
			profilePictureDefaultId: person.profilePictureDefaultId,
			profilePictureId: null,
			roleId: person.role ? person.role.id : null,
			active: true,
			personLabels: [],
		});
	}
	pageComponent.forceUpdate();
	dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, {preventFiltering: false});
	requestAnimationFrame(() => {
		dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, {preventFiltering: false});
	});
};
