/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DetailsLoader_viewer$ref: FragmentReference;
declare export opaque type DetailsLoader_viewer$fragmentType: DetailsLoader_viewer$ref;
export type DetailsLoader_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string
  |},
  +project: ?{|
    +id: string,
    +useFixedPriceForBaselineRevenue: ?boolean,
    +rateCard: ?{|
      +currency: ?string
    |},
    +aggregatedFinancialNumbers: $ReadOnlyArray<?string>,
    +financialNumbers: ?{|
      +baselineRevenue: ?number,
      +baselineCost: ?number,
      +baselineProfit: ?number,
      +baselineMargin: ?number,
      +baselineVsBillableActualTimeAndExpenses: ?number,
      +baselineVsActualCost: ?number,
      +baselineVsActualProfit: ?number,
      +baselineVsActualMargin: ?number,
      +baselineVsActualRevenueProfit: ?number,
      +baselineVsActualRevenueMargin: ?number,
      +baselineVsActualRevenue: ?number,
      +baselineVsActualRevenueRecognition: ?number,
      +baselineVsTotalRevenueAtCompletion: ?number,
      +baselineVsBillableTotalTimeAndExpensesAtCompletion: ?number,
      +baselineVsTotalCostAtCompletion: ?number,
      +baselineVsTotalProfitAtCompletion: ?number,
      +baselineVsTotalRevenueProfitAtCompletion: ?number,
      +baselineVsTotalRevenueMarginAtCompletion: ?number,
      +billableActualTimeAndExpenses: ?number,
      +baselineTimeAndExpenses: ?number,
      +totalActualRevenueRecognition: ?number,
      +actualRevenue: ?number,
      +actualCost: ?number,
      +actualProfit: ?number,
      +actualMargin: ?number,
      +actualRevenueProfit: ?number,
      +actualRevenueMargin: ?number,
      +billablePlannedTimeAndExpenses: ?number,
      +plannedRevenue: ?number,
      +plannedCost: ?number,
      +plannedProfit: ?number,
      +plannedMargin: ?number,
      +plannedRevenueProfit: ?number,
      +plannedRevenueMargin: ?number,
      +billableForecastTimeAndExpensesToComplete: ?number,
      +totalForecastRevenueToComplete: ?number,
      +forecastCostToComplete: ?number,
      +forecastProfitToComplete: ?number,
      +forecastMarginToComplete: ?number,
      +forecastRevenueProfitToComplete: ?number,
      +forecastRevenueMarginToComplete: ?number,
      +billableTotalTimeAndExpensesAtCompletion: ?number,
      +totalRevenueRecognition: ?number,
      +totalCostAtCompletion: ?number,
      +totalProfitAtCompletion: ?number,
      +totalMarginAtCompletion: ?number,
      +totalRevenueProfitAtCompletion: ?number,
      +totalRevenueMarginAtCompletion: ?number,
      +planVsBillableActualTimeAndExpenses: ?number,
      +planVsActualRevenueRecognition: ?number,
      +planVsActualCost: ?number,
      +planVsActualProfit: ?number,
      +planVsActualMargin: ?number,
      +planVsActualRevenueProfit: ?number,
      +planVsActualRevenueMargin: ?number,
      +planVsTotalBillableTimeAndExpensesAtCompletion: ?number,
      +planVsTotalRevenueAtCompletion: ?number,
      +planVsTotalCostAtCompletion: ?number,
      +planVsTotalProfitAtCompletion: ?number,
      +planVsTotalMarginAtCompletion: ?number,
      +planVsTotalRevenueProfitAtCompletion: ?number,
      +planVsTotalRevenueMarginAtCompletion: ?number,
      +invoiced: ?number,
      +paid: ?number,
      +accruedDeferred: ?number,
      +baselineMinutes: ?number,
      +registeredMinutes: ?number,
      +billableActualMinutes: ?number,
      +nonBillableActualMinutes: ?number,
      +scopeApprovedMinutes: ?number,
      +forecastTimeToComplete: ?number,
      +totalTimeAtCompletion: ?number,
      +baselineVsRegisteredMinutes: ?number,
      +baselineVsTotalTimeAtCompletion: ?number,
      +registeredVsScopeApprovedMinutes: ?number,
      +scopeApprovedVsRegisteredMinutes: ?number,
      +totalTimeAtCompletionVsScopeApprovedMinutes: ?number,
      +scopeApprovedVsTotalTimeAtCompletion: ?number,
    |},
  |},
  +$refType: DetailsLoader_viewer$ref,
|};
export type DetailsLoader_viewer$data = DetailsLoader_viewer;
export type DetailsLoader_viewer$key = {
  +$data?: DetailsLoader_viewer$data,
  +$fragmentRefs: DetailsLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v2 = {
  "kind": "Literal",
  "name": "convertToProjectCurrency",
  "value": true
},
v3 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v4 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v5 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v6 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v7 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v8 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v9 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregateLevel",
      "type": "AggregateLevel!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregates",
      "type": "[FinancialAggregatedNumber]!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailsLoader_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useFixedPriceForBaselineRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "aggregateLevel",
              "variableName": "aggregateLevel"
            },
            {
              "kind": "Variable",
              "name": "aggregates",
              "variableName": "aggregates"
            },
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "aggregatedFinancialNumbers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/)
          ],
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "financialNumbers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsBillableActualTimeAndExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualRevenueProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualRevenueMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsActualRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalRevenueAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsBillableTotalTimeAndExpensesAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalCostAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalRevenueProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalRevenueMarginAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableActualTimeAndExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineTimeAndExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalActualRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualRevenueProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualRevenueMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billablePlannedTimeAndExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedRevenueProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedRevenueMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableForecastTimeAndExpensesToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalForecastRevenueToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastCostToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastProfitToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastMarginToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastRevenueProfitToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastRevenueMarginToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableTotalTimeAndExpensesAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCostAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalMarginAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalRevenueProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalRevenueMarginAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsBillableActualTimeAndExpenses",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualRevenueProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsActualRevenueMargin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalBillableTimeAndExpensesAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalRevenueAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalCostAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalMarginAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalRevenueProfitAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "planVsTotalRevenueMarginAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invoiced",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paid",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accruedDeferred",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "registeredMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableActualMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "nonBillableActualMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scopeApprovedMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastTimeToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalTimeAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsRegisteredMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalTimeAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "registeredVsScopeApprovedMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scopeApprovedVsRegisteredMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalTimeAtCompletionVsScopeApprovedMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scopeApprovedVsTotalTimeAtCompletion",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e00a77e416a3df492032cb2c4a21833';

module.exports = node;
