/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type utilizationListComponent_viewer$ref = any;
export type utilizationListComponentRefetchQueryVariables = {|
  shareKey?: ?string,
  dateCriteria?: ?string,
  componentId?: ?string,
  personIds?: ?$ReadOnlyArray<?string>,
  teamIds?: ?$ReadOnlyArray<?string>,
  roleIds?: ?$ReadOnlyArray<?string>,
  labelIds?: ?$ReadOnlyArray<?string>,
  projectStatusesBackend?: ?$ReadOnlyArray<?string>,
|};
export type utilizationListComponentRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: utilizationListComponent_viewer$ref
  |}
|};
export type utilizationListComponentRefetchQuery = {|
  variables: utilizationListComponentRefetchQueryVariables,
  response: utilizationListComponentRefetchQueryResponse,
|};
*/


/*
query utilizationListComponentRefetchQuery(
  $shareKey: String
  $dateCriteria: String
  $componentId: ID
  $personIds: [ID]
  $teamIds: [ID]
  $roleIds: [ID]
  $labelIds: [ID]
  $projectStatusesBackend: [ID]
) {
  viewer {
    ...utilizationListComponent_viewer_1IcmZU
    id
  }
}

fragment utilizationListComponent_viewer_1IcmZU on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    utilizationList(dateCriteria: $dateCriteria, personIds: $personIds, componentId: $componentId, teamIds: $teamIds, roleIds: $roleIds, labelIds: $labelIds, projectStatusesBackend: $projectStatusesBackend) {
      id
      availableMinutesTotal
      scheduledMinutesTotal
      scheduledProjectTimeMinutes
      scheduledNonProjectTimeMinutes
      reportedTotal
      assignedTasksForecastTotal
      overTimeMinutesTotal
      billableMinutesRegisteredTotal
      billableMinutesPlannedTotal
      utilizationListData {
        id
        name
        profilePictureId
        profilePictureDefaultId
        roleName
        availableMinutes
        scheduledMinutes
        scheduledNonProjectTimeMinutes
        scheduledProjectTimeMinutes
        reported
        billableMinutesRegistered
        billableMinutesPlanned
        cardMinutesPlanned
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateCriteria",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "componentId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roleIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "labelIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectStatusesBackend",
    "type": "[ID]"
  }
],
v1 = {
  "kind": "Variable",
  "name": "componentId",
  "variableName": "componentId"
},
v2 = {
  "kind": "Variable",
  "name": "dateCriteria",
  "variableName": "dateCriteria"
},
v3 = {
  "kind": "Variable",
  "name": "labelIds",
  "variableName": "labelIds"
},
v4 = {
  "kind": "Variable",
  "name": "personIds",
  "variableName": "personIds"
},
v5 = {
  "kind": "Variable",
  "name": "projectStatusesBackend",
  "variableName": "projectStatusesBackend"
},
v6 = {
  "kind": "Variable",
  "name": "roleIds",
  "variableName": "roleIds"
},
v7 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v8 = {
  "kind": "Variable",
  "name": "teamIds",
  "variableName": "teamIds"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledProjectTimeMinutes",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledNonProjectTimeMinutes",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "utilizationListComponentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "utilizationListComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "utilizationListComponentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v7/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/)
                ],
                "concreteType": "UtilizationList",
                "kind": "LinkedField",
                "name": "utilizationList",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "availableMinutesTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scheduledMinutesTotal",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reportedTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assignedTasksForecastTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overTimeMinutesTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billableMinutesRegisteredTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "billableMinutesPlannedTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UtilizationListPerson",
                    "kind": "LinkedField",
                    "name": "utilizationListData",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureDefaultId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "roleName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "availableMinutes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scheduledMinutes",
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reported",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billableMinutesRegistered",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billableMinutesPlanned",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cardMinutesPlanned",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "utilizationListComponentRefetchQuery",
    "operationKind": "query",
    "text": "query utilizationListComponentRefetchQuery(\n  $shareKey: String\n  $dateCriteria: String\n  $componentId: ID\n  $personIds: [ID]\n  $teamIds: [ID]\n  $roleIds: [ID]\n  $labelIds: [ID]\n  $projectStatusesBackend: [ID]\n) {\n  viewer {\n    ...utilizationListComponent_viewer_1IcmZU\n    id\n  }\n}\n\nfragment utilizationListComponent_viewer_1IcmZU on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    utilizationList(dateCriteria: $dateCriteria, personIds: $personIds, componentId: $componentId, teamIds: $teamIds, roleIds: $roleIds, labelIds: $labelIds, projectStatusesBackend: $projectStatusesBackend) {\n      id\n      availableMinutesTotal\n      scheduledMinutesTotal\n      scheduledProjectTimeMinutes\n      scheduledNonProjectTimeMinutes\n      reportedTotal\n      assignedTasksForecastTotal\n      overTimeMinutesTotal\n      billableMinutesRegisteredTotal\n      billableMinutesPlannedTotal\n      utilizationListData {\n        id\n        name\n        profilePictureId\n        profilePictureDefaultId\n        roleName\n        availableMinutes\n        scheduledMinutes\n        scheduledNonProjectTimeMinutes\n        scheduledProjectTimeMinutes\n        reported\n        billableMinutesRegistered\n        billableMinutesPlanned\n        cardMinutesPlanned\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ee1677e3d9b94eaf58f3f4b0ea70ec6f';

module.exports = node;
