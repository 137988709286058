import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import ColorsPicker from '../../colors_picker';

class UtilizationMonthlyColorConfig extends Component {
	constructor(props) {
		super(props);

		this.inputs = {};
		const config = JSON.parse(props.config || '{}');
		this.state = {
			tooLow: config.tooLow || 74,
			low: config.low || 90,
			optimal: config.optimal || 105,
			high: config.high || 110,
			lowInvalid: false,
			optimalInvalid: false,
			highInvalid: false,
			tooLowColor: config.tooLowColor || '#FF7C75',
			lowColor: config.lowColor || '#A56DFD',
			optimalColor: config.optimalColor || '#99FF8C',
			highColor: config.highColor || '#FFD761',
			tooHighColor: config.tooHighColor || '#E93A65',
		};
	}

	handleChange(type) {
		const value = this.inputs[type].value;
		const state = {};
		state[type] = value ? parseInt(Math.min(Math.max(value, 0), 999), 10) : value;
		state.lowInvalid = parseInt(this.inputs['tooLow'].value, 10) >= parseInt(this.inputs['low'].value, 10);
		state.optimalInvalid = parseInt(this.inputs['low'].value, 10) >= parseInt(this.inputs['optimal'].value, 10);
		state.highInvalid = parseInt(this.inputs['optimal'].value, 10) >= parseInt(this.inputs['high'].value, 10);
		this.setState(state);

		if (!state.lowInvalid && !state.optimalInvalid && !state.highInvalid) {
			// No invalid values, save config
			if (this.props.updateComponentConfiguration) {
				this.props.updateComponentConfiguration(this.props.id, this.getConfigObject());
			}
		}
	}

	getConfigObject(type, color) {
		return {
			tooLow: this.inputs['tooLow'].value,
			low: this.inputs['low'].value,
			optimal: this.inputs['optimal'].value,
			high: this.inputs['high'].value,
			tooLowColor: type === 'tooLowColor' ? color : this.state.tooLowColor,
			lowColor: type === 'lowColor' ? color : this.state.lowColor,
			optimalColor: type === 'optimalColor' ? color : this.state.optimalColor,
			highColor: type === 'highColor' ? color : this.state.highColor,
			tooHighColor: type === 'tooHighColor' ? color : this.state.tooHighColor,
		};
	}

	onColorSelect(type, color) {
		const state = {};
		state[type] = color;
		this.setState(state);
		if (this.props.updateComponentConfiguration) {
			this.props.updateComponentConfiguration(this.props.id, this.getConfigObject());
		}
	}

	handleKeyPress(type, e) {
		if (e.key === 'Enter') {
			this.inputs[type].blur();
		}
	}

	render() {
		return (
			<div className="utilization-color-config">
				<div className="line-container">
					<span className="config-title line-height24">
						<FormattedMessage id="insights.component.utilizationMonthly.too_low" />:
					</span>
					<span className="line-height24">&lt;&nbsp;</span>
					<input
						type="number"
						value={this.state.tooLow}
						placeholder="0"
						ref={input => (this.inputs['tooLow'] = input)}
						onChange={this.handleChange.bind(this, 'tooLow')}
						min="0"
						max="999"
						step="1"
						onKeyPress={this.handleKeyPress.bind(this, 'tooLow')}
					/>
					<span className="line-height24">%&nbsp;</span>
					<div className="select-wrapper">
						<ColorsPicker
							value={this.state.tooLowColor}
							size={20}
							onColorSelect={this.onColorSelect.bind(this, 'tooLowColor')}
						/>
					</div>
				</div>
				<div className="line-container">
					<span className="config-title line-height24">
						<FormattedMessage id="insights.component.utilizationMonthly.low" />:
					</span>
					<span className="line-height24">{parseInt(this.state.tooLow, 10) + 1 || '0'}% -&nbsp;</span>
					<input
						className={this.state.lowInvalid ? 'invalid' : undefined}
						type="number"
						value={this.state.low}
						placeholder="0"
						ref={input => (this.inputs['low'] = input)}
						onChange={this.handleChange.bind(this, 'low')}
						min="0"
						max="999"
						step="1"
						onKeyPress={this.handleKeyPress.bind(this, 'low')}
					/>
					%&nbsp;
					<ColorsPicker
						value={this.state.lowColor}
						size={20}
						onColorSelect={this.onColorSelect.bind(this, 'lowColor')}
					/>
				</div>
				<div className="line-container">
					<span className="config-title line-height24">
						<FormattedMessage id="insights.component.utilizationMonthly.optimal" />:
					</span>
					<span className="line-height24">{parseInt(this.state.low, 10) + 1 || '0'}% -&nbsp;</span>
					<input
						className={this.state.optimalInvalid ? 'invalid' : undefined}
						type="number"
						value={this.state.optimal}
						placeholder="0"
						ref={input => (this.inputs['optimal'] = input)}
						onChange={this.handleChange.bind(this, 'optimal')}
						min="0"
						max="999"
						step="1"
						onKeyPress={this.handleKeyPress.bind(this, 'optimal')}
					/>
					%&nbsp;
					<ColorsPicker
						value={this.state.optimalColor}
						size={20}
						onColorSelect={this.onColorSelect.bind(this, 'optimalColor')}
					/>
				</div>
				<div className="line-container">
					<span className="config-title line-height24">
						<FormattedMessage id="insights.component.utilizationMonthly.high" />:
					</span>
					<span className="line-height24">{parseInt(this.state.optimal, 10) + 1 || '0'}% -&nbsp;</span>
					<input
						className={this.state.highInvalid ? 'invalid' : undefined}
						type="number"
						value={this.state.high}
						placeholder="0"
						ref={input => (this.inputs['high'] = input)}
						onChange={this.handleChange.bind(this, 'high')}
						min="0"
						max="999"
						step="1"
						onKeyPress={this.handleKeyPress.bind(this, 'high')}
					/>
					%&nbsp;
					<ColorsPicker
						value={this.state.highColor}
						size={20}
						onColorSelect={this.onColorSelect.bind(this, 'highColor')}
					/>
				</div>
				<div className="line-container">
					<span className="config-title line-height24">
						<FormattedMessage id="insights.component.utilizationMonthly.too_high" />:
					</span>
					<span className="line-height24">
						&gt;&nbsp;
						{parseInt(this.state.high, 10) + 1 || '0'}
						%&nbsp;
					</span>
					<ColorsPicker
						value={this.state.tooHighColor}
						size={20}
						onColorSelect={this.onColorSelect.bind(this, 'tooHighColor')}
					/>
				</div>
			</div>
		);
	}
}

UtilizationMonthlyColorConfig.propTypes = {};

export default UtilizationMonthlyColorConfig;
