/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type reportedTimeTable_viewer$ref: FragmentReference;
declare export opaque type reportedTimeTable_viewer$fragmentType: reportedTimeTable_viewer$ref;
export type reportedTimeTable_viewer = {|
  +id: string,
  +company: ?{|
    +totalMinutesRegistered: ?number,
    +currency: ?string,
  |},
  +$refType: reportedTimeTable_viewer$ref,
|};
export type reportedTimeTable_viewer$data = reportedTimeTable_viewer;
export type reportedTimeTable_viewer$key = {
  +$data?: reportedTimeTable_viewer$data,
  +$fragmentRefs: reportedTimeTable_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "reportedTimeTable_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            }
          ],
          "kind": "ScalarField",
          "name": "totalMinutesRegistered",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '08f30e5bb4510e6dcfd81211db47d7b7';

module.exports = node;
