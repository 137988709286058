import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Util from '../../../forecast-app/shared/util/util';
import Person from '../../../forecast-app/shared/components/person/person';
import InsightsUtil from '../insights_util';
import {profilePicSrc} from '../../../directApi';

class peopleListPortfolioComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sortBy: {column: null, ascending: true},
		};
	}
	UNSAFE_componentWillMount() {
		const listHeight = InsightsUtil.GetlistHeight(
			this.props.viewer.insightComponentsData.peopleListPortfolio.people.length
		);

		if (this.props.height !== listHeight) {
			this.props.updateComponentHeight(this.props.id, listHeight);
		}
		if (this.props.setExportColumns) {
			const configObject = this.props.config ? JSON.parse(this.props.config) : {};
			let exportColumns = [];
			if (configObject) {
				this.props.columns.forEach(col => {
					if (configObject.columns.indexOf(col) > -1) {
						if (col === 'name' || col === 'labels' || col === 'reported' || col === 'spend') {
							exportColumns.push(col + '_raw');
						} else if (col === 'remaining') {
							exportColumns.push(col + '_hours');
							exportColumns.push(col + '_points');
						} else {
							exportColumns.push(col);
						}
					}
				});
			}
			this.props.setExportColumns(exportColumns);
		}
		this.setData(this.props, this.state);
	}
	UNSAFE_componentWillReceiveProps(nextProps, nextState) {
		if (this.props.labelFilterValue.length !== nextProps.labelFilterValue.length) {
			const labelIds = nextProps.labelFilterValue.length !== 0 ? nextProps.labelFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({labelIds});
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		this.setData(nextProps, nextState);
		const listHeight = InsightsUtil.GetlistHeight(this.people.length);
		this.props.updateComponentHeight(this.props.id, listHeight);
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	setData(props, state) {
		const {formatMessage, formatNumber} = props.intl;
		const peopleList = props.viewer.insightComponentsData.peopleListPortfolio;
		this.people = [];

		const hasHours = peopleList.remainingMinutesTotal > 0;
		const hasPoints = peopleList.remainingPointsTotal > 0;
		let remainingTotal;
		if (hasHours && hasPoints) {
			remainingTotal =
				Util.convertMinutesToFullHour(peopleList.remainingMinutesTotal, props.intl) +
				' & ' +
				formatMessage({id: 'common.x_points'}, {points: peopleList.remainingPointsTotal});
		} else if (hasPoints) {
			remainingTotal = formatMessage({id: 'common.x_points'}, {points: peopleList.remainingPointsTotal});
		} else {
			remainingTotal = Util.convertMinutesToFullHour(peopleList.remainingMinutesTotal, props.intl);
		}

		this.totals = {
			planningProjects: formatNumber(peopleList.planningProjectsCountTotal),
			runningProjects: formatNumber(peopleList.runningProjectsCountTotal),
			haltedProjects: formatNumber(peopleList.haltedProjectsCountTotal),
			doneProjects: formatNumber(peopleList.doneProjectsCountTotal),
			totalProjects: formatNumber(peopleList.projectsCountTotal),
			todoCards: formatNumber(peopleList.todoTasksCountTotal),
			inProgressCards: formatNumber(peopleList.inProgressTasksCountTotal),
			doneCards: formatNumber(peopleList.doneTasksCountTotal),
			totalCards: formatNumber(peopleList.tasksCountTotal),
			reported: Util.convertMinutesToFullHour(peopleList.reportedTotal, props.intl),
			remaining: remainingTotal,
			spend: Util.getFormattedCurrencyValue(
				Util.GetCurrencySymbol(props.viewer.insightComponentsData.peopleListPortfolio.currency),
				formatNumber(Math.round(peopleList.spendTotal * 100.0) / 100.0)
			),
		};
		props.viewer.insightComponentsData.peopleListPortfolio.people.forEach(element => {
			const person = {};
			person.name = (
				<Person
					key={element.id}
					name={element.name}
					showName={true}
					showRole={false}
					imageSrc={profilePicSrc(element.profilePictureId)}
					imageSize="medium"
				/>
			);
			person.name_raw = element.name;
			person.defaultRole = element.defaultRole;
			person.email = element.email;
			person.department = element.departmentName;
			person.labels = element.labels;
			person.labels_raw = element.labels.map(label => label.name);
			person.skills = element.skills.map(skill => skill.name);

			person.permission = element.profiles.edges.map(e => e.node.name).join(', ');
			person.planningProjects = element.planningProjects;
			person.runningProjects = element.runningProjects;
			person.haltedProjects = element.haltedProjects;
			person.doneProjects = element.doneProjects;
			person.totalProjects = element.totalProjects;
			person.todoCards = element.todoTasksCount;
			person.inProgressCards = element.progressTasksCount;
			person.doneCards = element.doneTasksCount;
			person.totalCards = element.tasksCount;
			person.reported = Util.convertMinutesToFullHour(element.reportedMinutes, props.intl);
			person.reported_raw = Math.round((element.reportedMinutes / 60.0) * 100) / 100;
			const remainingMinutes = element.remainingMinutes;
			const remainingPoints = element.remainingPoints;
			let remaining;
			if (remainingMinutes !== 0 && remainingPoints === 0) {
				remaining = Util.convertMinutesToFullHour(remainingMinutes, props.intl);
			} else if (remainingMinutes === 0 && remainingPoints !== 0) {
				remaining = formatMessage({id: 'common.x_points'}, {points: remainingPoints});
			} else if (remainingPoints !== 0 && remainingMinutes !== 0) {
				remaining =
					Util.convertMinutesToFullHour(remainingMinutes, props.intl) +
					'&' +
					formatMessage({id: 'common.x_points'}, {points: remainingPoints});
			} else {
				remaining = Util.convertMinutesToFullHour(0, props.intl);
			}
			person.remaining = remaining;
			person.remaining_hours = Math.round((remainingMinutes / 60.0) * 100) / 100;
			person.remaining_points = remainingPoints;
			person.remaining_raw = remainingMinutes !== 0 ? remainingMinutes : remainingPoints;
			if (this.canViewFinancial()) {
				person.spend_raw = element.spend;
				person.spend = Util.getFormattedCurrencyValue(
					Util.GetCurrencySymbol(props.viewer.insightComponentsData.peopleListPortfolio.currency),
					formatNumber(Math.round(element.spend * 100.0) / 100.0)
				);
			}
			this.people.push(person);
		});
		if (state.sortBy.column !== this.state.sortBy.column || state.sortBy.ascending !== this.state.sortBy.ascending) {
			this.sort(state.sortBy);
		}
		if (props.setExportData) {
			props.setExportData(this.people);
		}
	}

	canViewFinancial() {
		return this.props.canViewFinancial !== false;
	}

	sort(sortBy) {
		if (sortBy.column) {
			this.people = this.people.sort((a, b) => {
				let column = sortBy.column;
				if (column === 'skills') {
					return null;
				}
				switch (column) {
					case 'remaining':
					case 'spend':
					case 'reported':
					case 'skills':
						column += '_raw';
						break;
					case 'progress':
						column = 'percentDone';
						break;
				}

				let returnValue = 0;
				if (column === 'name') {
					if (a.name_raw.toLowerCase() < b.name_raw.toLowerCase()) returnValue = 1;
					if (a.name_raw.toLowerCase() > b.name_raw.toLowerCase()) returnValue = -1;
					if (a.name_raw === '') returnValue = -1;
				} else if (column === 'department') {
					const aVal = a[column] || '';
					const bVal = b[column] || '';
					returnValue = aVal.toLowerCase() < bVal.toLowerCase() ? 1 : -1;
				} else {
					if (a[column] < b[column]) returnValue = 1;
					if (a[column] > b[column]) returnValue = -1;
					if (a[column] === '') returnValue = -1;
				}
				return sortBy.ascending ? returnValue * -1 : returnValue;
			});
		}
	}

	render() {
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};

		return (
			<InsightList
				componentId={this.props.id}
				data={this.people}
				componentName={'peopleListPortfolio'}
				activeColumns={configObject ? configObject.columns : []}
				allColumns={this.props.columns}
				sortBy={this.state.sortBy}
				setSortBy={this.setSortBy.bind(this)}
				disableSort="skills"
				totals={this.totals}
				scrollElement={this.props.scrollElement}
				scrollElementFullScreen={this.props.scrollElementFullScreen}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

peopleListPortfolioComponent.propTypes = {
	title: PropTypes.string,
};

const peopleListPortfolioComponentQuery = graphql`
	query peopleListPortfolioComponent_Query(
		$shareKey: String
		$componentId: ID
		$personIds: [ID]
		$departmentIds: [ID]
		$teamIds: [ID]
		$roleIds: [ID]
		$labelIds: [ID]
		$skillIds: [ID]
	) {
		viewer {
			actualPersonId
			component(name: "insight_people_list_portfolio")
			...peopleListPortfolioComponent_viewer
				@arguments(
					shareKey: $shareKey
					componentId: $componentId
					personIds: $personIds
					departmentIds: $departmentIds
					teamIds: $teamIds
					roleIds: $roleIds
					labelIds: $labelIds
					skillIds: $skillIds
				)
		}
	}
`;

export {peopleListPortfolioComponentQuery};

export default injectIntl(
	createRefetchContainer(
		peopleListPortfolioComponent,
		{
			viewer: graphql`
				fragment peopleListPortfolioComponent_viewer on Viewer
				@argumentDefinitions(
					shareKey: {type: "String"}
					componentId: {type: "ID"}
					personIds: {type: "[ID]"}
					departmentIds: {type: "[ID]"}
					teamIds: {type: "[ID]"}
					roleIds: {type: "[ID]"}
					labelIds: {type: "[ID]"}
					skillIds: {type: "[ID]"}
				) {
					insightComponentsData(shareKey: $shareKey) {
						peopleListPortfolio(
							componentId: $componentId
							personIds: $personIds
							departmentIds: $departmentIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
							skillIds: $skillIds
						) {
							currency
							planningProjectsCountTotal
							runningProjectsCountTotal
							haltedProjectsCountTotal
							doneProjectsCountTotal
							projectsCountTotal
							todoTasksCountTotal
							inProgressTasksCountTotal
							doneTasksCountTotal
							tasksCountTotal
							reportedTotal
							remainingMinutesTotal
							remainingPointsTotal
							spendTotal
							people {
								id
								name
								profilePictureId
								profilePictureDefaultId
								defaultRole
								email
								departmentName
								labels {
									id
									name
									color
								}
								skills {
									id
									name
								}
								profiles(first: 10000) {
									edges {
										node {
											id
											name
										}
									}
								}
								tasksCount
								todoTasksCount
								progressTasksCount
								doneTasksCount
								planningProjects
								runningProjects
								haltedProjects
								doneProjects
								totalProjects
								reportedMinutes
								remainingMinutes
								remainingPoints
								spend
							}
						}
					}
				}
			`,
		},
		graphql`
			query peopleListPortfolioComponentRefetchQuery(
				$shareKey: String
				$componentId: ID
				$personIds: [ID]
				$departmentIds: [ID]
				$teamIds: [ID]
				$roleIds: [ID]
				$labelIds: [ID]
				$skillIds: [ID]
			) {
				viewer {
					...peopleListPortfolioComponent_viewer
						@arguments(
							shareKey: $shareKey
							componentId: $componentId
							personIds: $personIds
							departmentIds: $departmentIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
							skillIds: $skillIds
						)
				}
			}
		`
	)
);
