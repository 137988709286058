import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import ChartJsLine from '../../chartjs/chart_js_line';

class SprintVelocityDummy extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}
	render() {
		const {formatMessage} = this.props.intl;
		const data = {
			labels: ['Sprint 1', 'Sprint 2', 'Sprint 3', 'Sprint 4', 'Sprint 5'],
			datasets: [
				{
					data: [42, 36, 34, 40, 37],
					label: formatMessage({id: 'insights.component.sprintVelocity.hours-burned'}),
					borderColor: '#44B4FF',
					backgroundColor: '#44B4FF',
					fill: false,
					lineTension: 0,
					pointRadius: 4,
					pointBackgroundColor: '#44B4FF',
					pointHoverRadius: 6,
				},
			],
		};
		return <ChartJsLine data={data} height={360} yAxesTitle={formatMessage({id: 'common.hours'})} />;
	}
}
export default injectIntl(SprintVelocityDummy);
