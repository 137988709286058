import Item from '../../canvas-timeline/canvas_timeline_item';
import {
	drawRectangle,
	getTextColor,
	getTrimmedText,
	ITEM_TYPE,
	throttledRedraw,
	TIMELINE_BACKGROUND_COLOR,
	TIMELINE_BAR_BORDER_RADIUS,
	TIMELINE_GREY_TASK_BAR_COLOR,
	TOOLTIP_DISPLAY_DELAY_MS,
} from '../../canvas-timeline/canvas_timeline_util';
import Util from '../../../../forecast-app/shared/util/util';
import EventManager from '../../EventManager';
import DataManager from '../../DataManager';
import {getAllocationData} from '../../projects-scheduling/projects_scheduling_allocation_data';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import {DISTRIBUTION_TYPE} from '../../constants';

class ProjectItem extends Item {
	constructor(pageComponent, data) {
		super(pageComponent, ITEM_TYPE.PROJECT_SCHEDULING_PROJECT, data);
	}

	afterLazyLoad(progressData) {
		this.applyProgressData(progressData.projects[0]);
		throttledRedraw();
	}

	applyProgressData(project) {
		this.data.project = {
			...this.data.project,
			...project,
		};
	}

	onClick() {
		const {sharedContext} = this.pageComponent.props;

		if (!sharedContext) {
			const {program, project, projectGroup} = this.data;

			if (!program) {
				EventManager.onDistributionItemClick(
					this.pageComponent,
					this,
					projectGroup?.id || project.projectGroupId || project.id,
					!projectGroup && project.projectGroupId ? project.id : null
				);
			}
		}
	}

	async onMouseEnter(eventData, event) {
		const {sharedContext} = this.pageComponent.props;

		if (!sharedContext) {
			const {program, project} = this.data;

			if (!program && !project?.programId) {
				const {dataLoaded, loadingProject, groups} = this.pageComponent.state;
				const {isUsingNewLazyLoad} = this.pageComponent;

				if (dataLoaded || isUsingNewLazyLoad) {
					const {project, projectGroup} = this.data;

					const parentId = projectGroup?.id || project.projectGroupId || project.id;
					const childId = !projectGroup && project.projectGroupId ? project.id : null;

					if (isUsingNewLazyLoad) {
						this.pageComponent.setState({
							lastDistributionItemMouseEnterParams: {
								itemType: this.itemType,
								parentId,
								childId,
								itemData: this.data,
								e: event,
							},
						});
					}

					if (!isUsingNewLazyLoad || !loadingProject) {
						const {clientX, clientY} = event;

						this.pageComponent.setState({
							hoverX: clientX,
							hoverY: clientY,
							showDistributionBox: false,
						});

						if ((isUsingNewLazyLoad && projectGroup) || (project && !project.projectGroupId)) {
							const group = groups.find(group => group.id === this.groupId);

							if (!group?.fullDataLoaded) {
								const {lastDistributionItemMouseEnterParams: params} = this.pageComponent.state;

								if (!hasFeatureFlag('all_timelines_duplication_hover_fix') || !project?.isInProgram) {
									await this.pageComponent.fetchProjectData(
										this.pageComponent.props,
										project?.companyProjectId,
										projectGroup?.companyProjectGroupId,
										program?.prefix
									);
								}

								if (params.parentId !== parentId || params.childId !== childId) {
									this.onMouseEnter(
										params.itemType,
										params.parentId,
										params.childId,
										params.itemData,
										params.e
									);
									return;
								}

								if (projectGroup) {
									this.data.projectGroup = DataManager.getProjectGroupById(
										this.pageComponent,
										projectGroup.id
									);
								} else if (project) {
									this.data.project = DataManager.getProjectById(this.pageComponent, project.id);
								}
							}
						}

						const allocationData = getAllocationData(this.pageComponent, this);

						setTimeout(() => {
							const {hoverX, hoverY} = this.pageComponent.state;
							const data = this.pageComponent.getData();
							const {clients} = data;
							const {y} = eventData;

							this.pageComponent.setState({
								distributionBoxX: clientX,
								distributionBoxY: y,
								distributionBoxData: {
									project,
									projectGroup,
									clients,
									allocationData,
								},
							});

							if (hoverX === clientX && hoverY === clientY) {
								this.pageComponent.setState({showDistributionBox: true, distributionBoxX: clientX});
							}
						}, TOOLTIP_DISPLAY_DELAY_MS);
					}
				}
			}
		}
	}

	onMoveEnd(movedItem, group, initialGroup, dragData) {
		EventManager.onDistributionItemMoveEnd(
			this.pageComponent,
			this,
			this.data.program ? DISTRIBUTION_TYPE.PROGRAM : DISTRIBUTION_TYPE.PROJECT,
			group,
			initialGroup,
			dragData
		);
	}

	onMouseLeave() {
		const {sharedContext} = this.pageComponent.props;

		if (!sharedContext) {
			this.pageComponent.setState({showDistributionBox: false, hoverX: null, hoverY: null, distributionBoxData: null});
		}
	}

	onMouseMove(eventData, event) {
		const {sharedContext} = this.pageComponent.props;

		if (!sharedContext) {
			const {clientX, clientY} = event;

			this.pageComponent.setState({
				hoverX: clientX,
				hoverY: clientY,
				showDistributionBox: false,
			});

			setTimeout(() => {
				const {hoverX, hoverY, distributionBoxData} = this.pageComponent.state;

				if (hoverX === clientX && hoverY === clientY && distributionBoxData) {
					this.pageComponent.setState({showDistributionBox: true, distributionBoxX: clientX});
				}
			}, TOOLTIP_DISPLAY_DELAY_MS);
		}
	}

	onMoving(movedItem, group, startDifference, endDifference, dragData, movedDays) {
		return EventManager.onDistributionItemMoving(
			this.pageComponent,
			this,
			DISTRIBUTION_TYPE.PROJECT,
			movedItem,
			group,
			startDifference,
			endDifference,
			dragData,
			movedDays
		);
	}

	draw(canvasContext, x, y, width, canvasWidth) {
		this.beforeDraw(this.afterLazyLoad.bind(this));
		const {data, height} = this;
		const {isHourEstimated, color, isPlanning, planningText, project, projects, program} = data;
		const {intl} = this.pageComponent.props;
		const modifiedProjectColor = color.toLowerCase() === '#e6e6ed' ? TIMELINE_GREY_TASK_BAR_COLOR : color;

		let completion;
		if (project) {
			completion = project.completion && project.completion > 0 ? project.completion : 0;
			if (project.manualProgressEnabled) {
				completion = Math.round(completion);
			} else {
				completion = Math.floor(completion);
			}
		} else if (program) {
			completion = data.completion;
		}

		let remaining;
		if (project?.manualProgressEnabled) {
			const preciseCompletion = project.completion || 0;
			remaining = project.forecast - project.forecast * (preciseCompletion / 100);
		} else {
			if (project) {
				remaining = project.remaining && project.remaining > 0 ? project.remaining : 0;
			} else {
				remaining = projects.reduce((total, project) => total + project.remaining, 0);
			}
		}

		const backgroundColor = isPlanning ? TIMELINE_BACKGROUND_COLOR : modifiedProjectColor;
		const textColor = getTextColor(backgroundColor);

		//Draw background colored rectangle
		const options = {
			backgroundColor,
			backgroundOpacity: isPlanning || completion === 100 ? 1 : 0.7,
			borderRadius: TIMELINE_BAR_BORDER_RADIUS,
			borderThickness: isPlanning ? 2 : undefined,
			borderColor: isPlanning ? modifiedProjectColor : undefined,
		};
		drawRectangle(canvasContext, x, y, width, height, options);

		const textMargin = 12;
		const minimumTrim = 45;
		const middlePadding = 20;

		const visibleRightX = x + width > canvasWidth ? canvasWidth : x + width;
		const visibleLeftX = x > 0 ? x : 0;
		const visibleItemWidth = visibleRightX - visibleLeftX;

		const textTrim = visibleItemWidth - textMargin * 2;

		canvasContext.font = '600 13px ' + Util.getFontFamily();
		let projectName = '';
		if (project) {
			projectName = project.name;
		}
		if (program) {
			projectName = program.name;
		}
		const projectNameTextWidth = canvasContext.measureText(projectName).width;

		let hoursLeftString;
		let rightSize;
		let hoursLeftWidth;
		let completionStringWidth;
		const completionString = `${completion}%`;

		if (isPlanning) {
			canvasContext.font = '600 13px ' + Util.getFontFamily();
			rightSize = canvasContext.measureText(planningText).width;
		} else {
			if (remaining === 0 || program) {
				hoursLeftWidth = 0;
			} else {
				if (isHourEstimated) {
					hoursLeftString = Util.convertMinutesToFullHour(remaining, intl);
				} else {
					hoursLeftString = intl.formatMessage({id: 'common.x_points'}, {points: remaining});
				}
				hoursLeftString += ` ${intl.formatMessage({id: 'common.left'}).toLowerCase()}`;
				hoursLeftString = `(${hoursLeftString})`;
				canvasContext.font = '400 13px ' + Util.getFontFamily();
				hoursLeftWidth = canvasContext.measureText(`${hoursLeftString}`).width;
			}
			canvasContext.font = '600 13px ' + Util.getFontFamily();
			completionStringWidth = canvasContext.measureText(completionString).width;
			rightSize = hoursLeftWidth + completionStringWidth;
		}

		const showLeft = textTrim > minimumTrim || projectNameTextWidth < minimumTrim;
		const showRight = visibleItemWidth - (rightSize + textMargin + middlePadding) > projectNameTextWidth + textMargin;

		if (!showLeft && !showRight) {
			return;
		}

		if (!isPlanning) {
			//Draw progress bar if needed
			if (completion !== 0 && completion !== 100) {
				//The last path drawn is the shape of item, use that as a clipping path to draw progress
				canvasContext.save();
				canvasContext.clip();
				//draw completion rectangle
				const completionRecOptions = {
					backgroundColor: modifiedProjectColor,
				};
				const progressWidth = (width * completion) / 100;
				drawRectangle(canvasContext, x, y, progressWidth, height, completionRecOptions);
				canvasContext.restore();
			}
		}

		//Planning projects just have the planning text on them
		if (showRight) {
			if (isPlanning) {
				canvasContext.fillStyle = '#535353';
				canvasContext.font = '600 13px ' + Util.getFontFamily();
				canvasContext.fillText(planningText, visibleRightX - textMargin - rightSize, y + 24);
			} else {
				if ((project && !project.noCompletionData) || (program && completion)) {
					const progressTextOffsetY = y + 24;

					// percentage
					canvasContext.fillStyle = textColor;
					canvasContext.fillText(
						completionString,
						visibleRightX - textMargin - completionStringWidth - hoursLeftWidth - 6,
						progressTextOffsetY
					);

					if (hoursLeftString) {
						// hours left
						canvasContext.font = '400 13px ' + Util.getFontFamily();
						canvasContext.fillText(
							hoursLeftString,
							visibleRightX - textMargin - hoursLeftWidth,
							progressTextOffsetY
						);
					}
				}
			}
		}

		//Project Name
		if (showLeft) {
			canvasContext.fillStyle = textColor;
			canvasContext.font = '600 13px ' + Util.getFontFamily();
			canvasContext.fillText(getTrimmedText(canvasContext, projectName, textTrim), textMargin + visibleLeftX, y + 24);
		}
	}
}

export default ProjectItem;
