import {createFragmentContainer, graphql} from 'react-relay';
import {decompressData, getTotalsForBreakdown} from '../util/DataDecompressUtil';
import {getCurrency} from '../util/BudgetUtils';
import {PHASE_BREAKDOWN_TYPE} from '../../../../../constants';

const DetailsPhaseLoader = ({relay, viewer, children, aggregateLevel, aggregate, onlyAccumulated, ...props}) => {
	// "aggregatedPhaseFinancialBreakdown" used in phase breakdown
	// "aggregatedFinancialNumbers" used in accumulated rows
	// "phaseFinancialBreakdown" used in right column totals
	const {aggregatedPhaseFinancialBreakdown, aggregatedFinancialNumbers, phaseFinancialBreakdown} = viewer.project;
	const currency = getCurrency(viewer.company, viewer.project);
	const rows = [];

	const totalsBreakdown = processTotals(phaseFinancialBreakdown);

	if (!onlyAccumulated) {
		for (const breakdownType of Object.values(aggregatedPhaseFinancialBreakdown)) {
			const financialNumberTotals = getTotalsForBreakdown(
				totalsBreakdown,
				breakdownType.phase ? breakdownType.phase.id : breakdownType.breakdownType
			);
			const processedData = decompressData(
				breakdownType.aggregatedFinancialNumbers,
				financialNumberTotals,
				currency,
				aggregateLevel,
				[aggregate]
			);
			const row = {
				...processedData.rows[0],
				breakdownType: breakdownType.breakdownType,
				phase: breakdownType.phase,
			};
			if (filterTimeBasedAggregates(breakdownType.breakdownType, aggregate)) {
				rows.push(row);
			}
		}
	}

	const addAccumulatedTotals = !aggregate.includes('Margin');
	if (addAccumulatedTotals) {
		const processedData = decompressData(aggregatedFinancialNumbers, null, currency, aggregateLevel, [aggregate], true);
		const accumulatedTotalsRow = {
			...processedData.rows[0],
			breakdownType: 'ACCUMULATED_TOTALS',
		};
		rows.push(accumulatedTotalsRow);
	}

	const result = {data: {rows: [...rows]}, ...props};
	return children(result);
};

function processTotals(financialBreakdown) {
	const result = [];
	for (const totals of Object.values(financialBreakdown)) {
		const o = {...totals};
		o['lookupId'] = totals.phase ? totals.phase.id : totals.breakdownType;
		result.push(o);
	}
	return result;
}

function filterTimeBasedAggregates(breakdownType, aggregate) {
	// Do not show offset breakdown for time based aggregates
	return !(breakdownType === PHASE_BREAKDOWN_TYPE.OFFSET && aggregate.endsWith('Minutes'));
}

export const detailsPhaseLoaderQuery = graphql`
	query DetailsPhaseLoader_Query(
		$projectId: ID!
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
		$aggregateLevel: AggregateLevel!
		$aggregate: FinancialAggregatedNumber!
		$searchQuery: TaskSearchQueryType
		$baselineRevenue: Boolean!
		$baselineCost: Boolean!
		$baselineProfit: Boolean!
		$billableActualTimeAndExpenses: Boolean!
		$actualCost: Boolean!
		$actualProfit: Boolean!
		$billablePlannedTimeAndExpenses: Boolean!
		$plannedCost: Boolean!
		$plannedProfit: Boolean!
		$billableForecastTimeAndExpensesToComplete: Boolean!
		$forecastCostToComplete: Boolean!
		$forecastProfitToComplete: Boolean!
		$billableTotalTimeAndExpensesAtCompletion: Boolean!
		$totalCostAtCompletion: Boolean!
		$totalProfitAtCompletion: Boolean!
		$planVsBillableActualTimeAndExpenses: Boolean!
		$planVsActualCost: Boolean!
		$planVsActualProfit: Boolean!
		$planVsTotalBillableTimeAndExpensesAtCompletion: Boolean!
		$planVsTotalCostAtCompletion: Boolean!
		$planVsTotalProfitAtCompletion: Boolean!
		$baselineVsBillableActualTimeAndExpenses: Boolean!
		$baselineVsActualCost: Boolean!
		$baselineVsActualProfit: Boolean!
		$baselineVsBillableTotalTimeAndExpensesAtCompletion: Boolean!
		$baselineVsTotalCostAtCompletion: Boolean!
		$baselineVsTotalProfitAtCompletion: Boolean!
		$invoiced: Boolean!
		$paid: Boolean!
		$baselineMinutes: Boolean!
		$baselineVsRegisteredMinutes: Boolean!
		$baselineVsTotalTimeAtCompletion: Boolean!
		$registeredMinutes: Boolean!
		$billableActualMinutes: Boolean!
		$nonBillableActualMinutes: Boolean!
		$scopeApprovedMinutes: Boolean!
		$registeredVsScopeApprovedMinutes: Boolean!
		$scopeApprovedVsRegisteredMinutes: Boolean!
		$forecastTimeToComplete: Boolean!
		$totalTimeAtCompletion: Boolean!
		$totalTimeAtCompletionVsScopeApprovedMinutes: Boolean!
		$scopeApprovedVsTotalTimeAtCompletion: Boolean!
		$baselineTimeAndExpenses: Boolean!
		$plannedRevenueMargin: Boolean!
		$plannedRevenueProfit: Boolean!
		$totalRevenueProfitAtCompletion: Boolean!
		$forecastRevenueProfitToComplete: Boolean!
		$actualRevenueProfit: Boolean!
		$baselineMargin: Boolean!
		$forecastRevenueMarginToComplete: Boolean!
		$totalForecastRevenueToComplete: Boolean!
		$actualRevenueMargin: Boolean!
		$totalActualRevenueRecognition: Boolean!
		$baselineVsActualMargin: Boolean!
		$baselineVsTotalRevenueAtCompletion: Boolean!
		$baselineVsTotalMarginAtCompletion: Boolean!
		$planVsActualMargin: Boolean!
		$planVsTotalRevenueAtCompletion: Boolean!
		$baselineVsActualRevenueMargin: Boolean!
		$baselineVsActualRevenueProfit: Boolean!
		$baselineVsTotalRevenueMarginAtCompletion: Boolean!
		$baselineVsTotalRevenueProfitAtCompletion: Boolean!
		$planVsActualRevenueMargin: Boolean!
		$planVsActualRevenueProfit: Boolean!
		$planVsTotalRevenueMarginAtCompletion: Boolean!
		$planVsTotalRevenueProfitAtCompletion: Boolean!
		$baselineVsActualRevenueRecognition: Boolean!
		$planVsActualRevenueRecognition: Boolean!
	) {
		viewer {
			actualPersonId
			component(name: "details_phase_loader")
			...DetailsPhaseLoader_viewer
				@arguments(
					projectId: $projectId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregate: $aggregate
					searchQuery: $searchQuery
					baselineRevenue: $baselineRevenue
					baselineCost: $baselineCost
					baselineProfit: $baselineProfit
					billableActualTimeAndExpenses: $billableActualTimeAndExpenses
					actualCost: $actualCost
					actualProfit: $actualProfit
					billablePlannedTimeAndExpenses: $billablePlannedTimeAndExpenses
					plannedCost: $plannedCost
					plannedProfit: $plannedProfit
					billableForecastTimeAndExpensesToComplete: $billableForecastTimeAndExpensesToComplete
					forecastCostToComplete: $forecastCostToComplete
					forecastProfitToComplete: $forecastProfitToComplete
					billableTotalTimeAndExpensesAtCompletion: $billableTotalTimeAndExpensesAtCompletion
					totalCostAtCompletion: $totalCostAtCompletion
					totalProfitAtCompletion: $totalProfitAtCompletion
					planVsBillableActualTimeAndExpenses: $planVsBillableActualTimeAndExpenses
					planVsActualCost: $planVsActualCost
					planVsActualProfit: $planVsActualProfit
					planVsTotalBillableTimeAndExpensesAtCompletion: $planVsTotalBillableTimeAndExpensesAtCompletion
					planVsTotalCostAtCompletion: $planVsTotalCostAtCompletion
					planVsTotalProfitAtCompletion: $planVsTotalProfitAtCompletion
					baselineVsBillableActualTimeAndExpenses: $baselineVsBillableActualTimeAndExpenses
					baselineVsActualCost: $baselineVsActualCost
					baselineVsActualProfit: $baselineVsActualProfit
					baselineVsBillableTotalTimeAndExpensesAtCompletion: $baselineVsBillableTotalTimeAndExpensesAtCompletion
					baselineVsTotalCostAtCompletion: $baselineVsTotalCostAtCompletion
					baselineVsTotalProfitAtCompletion: $baselineVsTotalProfitAtCompletion
					invoiced: $invoiced
					paid: $paid
					baselineMinutes: $baselineMinutes
					baselineVsRegisteredMinutes: $baselineVsRegisteredMinutes
					baselineVsTotalTimeAtCompletion: $baselineVsTotalTimeAtCompletion
					registeredMinutes: $registeredMinutes
					billableActualMinutes: $billableActualMinutes
					nonBillableActualMinutes: $nonBillableActualMinutes
					scopeApprovedMinutes: $scopeApprovedMinutes
					registeredVsScopeApprovedMinutes: $registeredVsScopeApprovedMinutes
					scopeApprovedVsRegisteredMinutes: $scopeApprovedVsRegisteredMinutes
					forecastTimeToComplete: $forecastTimeToComplete
					totalTimeAtCompletion: $totalTimeAtCompletion
					totalTimeAtCompletionVsScopeApprovedMinutes: $totalTimeAtCompletionVsScopeApprovedMinutes
					scopeApprovedVsTotalTimeAtCompletion: $scopeApprovedVsTotalTimeAtCompletion
					baselineTimeAndExpenses: $baselineTimeAndExpenses
					plannedRevenueProfit: $plannedRevenueProfit
					plannedRevenueMargin: $plannedRevenueMargin
					totalRevenueProfitAtCompletion: $totalRevenueProfitAtCompletion
					forecastRevenueProfitToComplete: $forecastRevenueProfitToComplete
					actualRevenueProfit: $actualRevenueProfit
					baselineMargin: $baselineMargin
					forecastRevenueMarginToComplete: $forecastRevenueMarginToComplete
					totalForecastRevenueToComplete: $totalForecastRevenueToComplete
					actualRevenueMargin: $actualRevenueMargin
					totalActualRevenueRecognition: $totalActualRevenueRecognition
					baselineVsActualMargin: $baselineVsActualMargin
					baselineVsTotalRevenueAtCompletion: $baselineVsTotalRevenueAtCompletion
					baselineVsTotalMarginAtCompletion: $baselineVsTotalMarginAtCompletion
					planVsActualMargin: $planVsActualMargin
					planVsTotalRevenueAtCompletion: $planVsTotalRevenueAtCompletion
					baselineVsActualRevenueMargin: $baselineVsActualRevenueMargin
					baselineVsActualRevenueProfit: $baselineVsActualRevenueProfit
					baselineVsTotalRevenueMarginAtCompletion: $baselineVsTotalRevenueMarginAtCompletion
					baselineVsTotalRevenueProfitAtCompletion: $baselineVsTotalRevenueProfitAtCompletion
					planVsActualRevenueMargin: $planVsActualRevenueMargin
					planVsActualRevenueProfit: $planVsActualRevenueProfit
					planVsTotalRevenueMarginAtCompletion: $planVsTotalRevenueMarginAtCompletion
					planVsTotalRevenueProfitAtCompletion: $planVsTotalRevenueProfitAtCompletion
					baselineVsActualRevenueRecognition: $baselineVsActualRevenueRecognition
					planVsActualRevenueRecognition: $planVsActualRevenueRecognition
				)
		}
	}
`;

export default createFragmentContainer(DetailsPhaseLoader, {
	viewer: graphql`
		fragment DetailsPhaseLoader_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID!"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
			aggregateLevel: {type: "AggregateLevel!"}
			aggregate: {type: "FinancialAggregatedNumber!"}
			searchQuery: {type: "TaskSearchQueryType"}

			baselineRevenue: {type: "Boolean!"}
			baselineCost: {type: "Boolean!"}
			baselineProfit: {type: "Boolean!"}
			billableActualTimeAndExpenses: {type: "Boolean!"}
			actualCost: {type: "Boolean!"}
			actualProfit: {type: "Boolean!"}
			billablePlannedTimeAndExpenses: {type: "Boolean!"}
			plannedCost: {type: "Boolean!"}
			plannedProfit: {type: "Boolean!"}
			billableForecastTimeAndExpensesToComplete: {type: "Boolean!"}
			forecastCostToComplete: {type: "Boolean!"}
			forecastProfitToComplete: {type: "Boolean!"}
			billableTotalTimeAndExpensesAtCompletion: {type: "Boolean!"}
			totalCostAtCompletion: {type: "Boolean!"}
			totalProfitAtCompletion: {type: "Boolean!"}
			planVsBillableActualTimeAndExpenses: {type: "Boolean!"}
			planVsActualCost: {type: "Boolean!"}
			planVsActualProfit: {type: "Boolean!"}
			planVsTotalBillableTimeAndExpensesAtCompletion: {type: "Boolean!"}
			planVsTotalCostAtCompletion: {type: "Boolean!"}
			planVsTotalProfitAtCompletion: {type: "Boolean!"}
			baselineVsBillableActualTimeAndExpenses: {type: "Boolean!"}
			baselineVsActualCost: {type: "Boolean!"}
			baselineVsActualProfit: {type: "Boolean!"}
			baselineVsBillableTotalTimeAndExpensesAtCompletion: {type: "Boolean!"}
			baselineVsTotalCostAtCompletion: {type: "Boolean!"}
			baselineVsTotalProfitAtCompletion: {type: "Boolean!"}
			invoiced: {type: "Boolean!"}
			paid: {type: "Boolean!"}
			baselineMinutes: {type: "Boolean!"}
			baselineVsRegisteredMinutes: {type: "Boolean!"}
			baselineVsTotalTimeAtCompletion: {type: "Boolean!"}
			registeredMinutes: {type: "Boolean!"}
			billableActualMinutes: {type: "Boolean!"}
			nonBillableActualMinutes: {type: "Boolean!"}
			scopeApprovedMinutes: {type: "Boolean!"}
			registeredVsScopeApprovedMinutes: {type: "Boolean!"}
			scopeApprovedVsRegisteredMinutes: {type: "Boolean!"}
			forecastTimeToComplete: {type: "Boolean!"}
			totalTimeAtCompletion: {type: "Boolean!"}
			totalTimeAtCompletionVsScopeApprovedMinutes: {type: "Boolean!"}
			scopeApprovedVsTotalTimeAtCompletion: {type: "Boolean!"}
			baselineTimeAndExpenses: {type: "Boolean!"}
			plannedRevenueProfit: {type: "Boolean!"}
			plannedRevenueMargin: {type: "Boolean!"}
			totalRevenueProfitAtCompletion: {type: "Boolean!"}
			forecastRevenueProfitToComplete: {type: "Boolean!"}
			actualRevenueProfit: {type: "Boolean!"}
			baselineMargin: {type: "Boolean!"}
			forecastRevenueMarginToComplete: {type: "Boolean!"}
			totalForecastRevenueToComplete: {type: "Boolean!"}
			actualRevenueMargin: {type: "Boolean!"}
			totalActualRevenueRecognition: {type: "Boolean!"}
			baselineVsActualMargin: {type: "Boolean!"}
			baselineVsTotalRevenueAtCompletion: {type: "Boolean!"}
			baselineVsTotalMarginAtCompletion: {type: "Boolean!"}
			planVsActualMargin: {type: "Boolean!"}
			planVsTotalRevenueAtCompletion: {type: "Boolean!"}
			baselineVsActualRevenueMargin: {type: "Boolean!"}
			baselineVsActualRevenueProfit: {type: "Boolean!"}
			baselineVsTotalRevenueMarginAtCompletion: {type: "Boolean!"}
			baselineVsTotalRevenueProfitAtCompletion: {type: "Boolean!"}
			planVsActualRevenueMargin: {type: "Boolean!"}
			planVsActualRevenueProfit: {type: "Boolean!"}
			planVsTotalRevenueMarginAtCompletion: {type: "Boolean!"}
			planVsTotalRevenueProfitAtCompletion: {type: "Boolean!"}
			baselineVsActualRevenueRecognition: {type: "Boolean!"}
			planVsActualRevenueRecognition: {type: "Boolean!"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				phaseFinancialBreakdown(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					convertToProjectCurrency: true
				) {
					breakdownType
					phase {
						id
						name
					}
					financialNumbers {
						baselineRevenue @include(if: $baselineRevenue)
						baselineCost @include(if: $baselineCost)
						baselineProfit @include(if: $baselineProfit)
						billableActualTimeAndExpenses @include(if: $billableActualTimeAndExpenses)
						actualCost @include(if: $actualCost)
						actualProfit @include(if: $actualProfit)
						billablePlannedTimeAndExpenses @include(if: $billablePlannedTimeAndExpenses)
						plannedCost @include(if: $plannedCost)
						plannedProfit @include(if: $plannedProfit)
						billableForecastTimeAndExpensesToComplete @include(if: $billableForecastTimeAndExpensesToComplete)
						forecastCostToComplete @include(if: $forecastCostToComplete)
						forecastProfitToComplete @include(if: $forecastProfitToComplete)
						billableTotalTimeAndExpensesAtCompletion @include(if: $billableTotalTimeAndExpensesAtCompletion)
						totalCostAtCompletion @include(if: $totalCostAtCompletion)
						totalProfitAtCompletion @include(if: $totalProfitAtCompletion)
						planVsBillableActualTimeAndExpenses @include(if: $planVsBillableActualTimeAndExpenses)
						planVsActualCost @include(if: $planVsActualCost)
						planVsActualProfit @include(if: $planVsActualProfit)
						planVsTotalBillableTimeAndExpensesAtCompletion
							@include(if: $planVsTotalBillableTimeAndExpensesAtCompletion)
						planVsTotalCostAtCompletion @include(if: $planVsTotalCostAtCompletion)
						planVsTotalProfitAtCompletion @include(if: $planVsTotalProfitAtCompletion)
						baselineVsBillableActualTimeAndExpenses @include(if: $baselineVsBillableActualTimeAndExpenses)
						baselineVsActualCost @include(if: $baselineVsActualCost)
						baselineVsActualProfit @include(if: $baselineVsActualProfit)
						baselineVsBillableTotalTimeAndExpensesAtCompletion
							@include(if: $baselineVsBillableTotalTimeAndExpensesAtCompletion)
						baselineVsTotalCostAtCompletion @include(if: $baselineVsTotalCostAtCompletion)
						baselineVsTotalProfitAtCompletion @include(if: $baselineVsTotalProfitAtCompletion)
						invoiced @include(if: $invoiced)
						paid @include(if: $paid)
						baselineMinutes @include(if: $baselineMinutes)
						baselineVsRegisteredMinutes @include(if: $baselineVsRegisteredMinutes)
						baselineVsTotalTimeAtCompletion @include(if: $baselineVsTotalTimeAtCompletion)
						baselineVsTotalTimeAtCompletion @include(if: $baselineVsTotalTimeAtCompletion)
						registeredMinutes @include(if: $registeredMinutes)
						billableActualMinutes @include(if: $billableActualMinutes)
						nonBillableActualMinutes @include(if: $nonBillableActualMinutes)
						scopeApprovedMinutes @include(if: $scopeApprovedMinutes)
						registeredVsScopeApprovedMinutes @include(if: $registeredVsScopeApprovedMinutes)
						scopeApprovedVsRegisteredMinutes @include(if: $scopeApprovedVsRegisteredMinutes)
						forecastTimeToComplete @include(if: $forecastTimeToComplete)
						totalTimeAtCompletion @include(if: $totalTimeAtCompletion)
						totalTimeAtCompletionVsScopeApprovedMinutes @include(if: $totalTimeAtCompletionVsScopeApprovedMinutes)
						scopeApprovedVsTotalTimeAtCompletion @include(if: $scopeApprovedVsTotalTimeAtCompletion)
						baselineTimeAndExpenses @include(if: $baselineTimeAndExpenses)
						plannedRevenueProfit @include(if: $plannedRevenueProfit)
						plannedRevenueMargin @include(if: $plannedRevenueMargin)
						totalRevenueProfitAtCompletion @include(if: $totalRevenueProfitAtCompletion)
						forecastRevenueProfitToComplete @include(if: $forecastRevenueProfitToComplete)
						actualRevenueProfit @include(if: $actualRevenueProfit)
						baselineMargin @include(if: $baselineMargin)
						forecastRevenueMarginToComplete @include(if: $forecastRevenueMarginToComplete)
						totalForecastRevenueToComplete @include(if: $totalForecastRevenueToComplete)
						actualRevenueMargin @include(if: $actualRevenueMargin)
						totalActualRevenueRecognition @include(if: $totalActualRevenueRecognition)
						baselineVsActualMargin @include(if: $baselineVsActualMargin)
						baselineVsTotalRevenueAtCompletion @include(if: $baselineVsTotalRevenueAtCompletion)
						baselineVsTotalMarginAtCompletion @include(if: $baselineVsTotalMarginAtCompletion)
						planVsActualMargin @include(if: $planVsActualMargin)
						planVsTotalRevenueAtCompletion @include(if: $planVsTotalRevenueAtCompletion)
						baselineVsActualRevenueMargin @include(if: $baselineVsActualRevenueMargin)
						baselineVsActualRevenueProfit @include(if: $baselineVsActualRevenueProfit)
						baselineVsTotalRevenueMarginAtCompletion @include(if: $baselineVsTotalRevenueMarginAtCompletion)
						baselineVsTotalRevenueProfitAtCompletion @include(if: $baselineVsTotalRevenueProfitAtCompletion)
						planVsActualRevenueMargin @include(if: $planVsActualRevenueMargin)
						planVsActualRevenueProfit @include(if: $planVsActualRevenueProfit)
						planVsTotalRevenueMarginAtCompletion @include(if: $planVsTotalRevenueMarginAtCompletion)
						planVsTotalRevenueProfitAtCompletion @include(if: $planVsTotalRevenueProfitAtCompletion)
						baselineVsActualRevenueRecognition @include(if: $baselineVsActualRevenueRecognition)
						planVsActualRevenueRecognition @include(if: $planVsActualRevenueRecognition)
					}
				}
				rateCard {
					currency
				}
				aggregatedFinancialNumbers(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: [$aggregate]
					addAccumulatedNumbers: true
					convertToProjectCurrency: true
					filterRowsWithNoValues: true
				)
				aggregatedPhaseFinancialBreakdown(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: [$aggregate]
					searchQuery: $searchQuery
					convertToProjectCurrency: true
					filterRowsWithNoValues: true
				) {
					breakdownType
					phase {
						id
						name
					}
					aggregatedFinancialNumbers
				}
			}
		}
	`,
});
