import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import {colors} from '../../../constants';
import ChartJsStackedArea from '../../chartjs/chart_js_stacked_area';

class CummulativeFlowDiagramDummy extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}
	render() {
		const data = {
			labels: [],
			datasets: [],
		};
		const dates = ['20180101', '20180110', '20180120', '20180130', '20180210', '20180220'];
		dates.forEach(dateString => {
			const momentDate = moment(dateString, 'YYYYMMDD');
			const date = momentDate.toDate();
			data.labels.push(date);
		});
		data.datasets.push({
			label: 'Todo',
			lineTension: 0,
			backgroundColor: colors[0],
			data: [9, 13, 10, 6, 3, 1],
			spanGaps: true,
		});
		data.datasets.push({
			label: 'In progress',
			backgroundColor: colors[1],
			lineTension: 0,
			data: [2, 11, 17, 15, 14, 8],
			spanGaps: true,
		});
		data.datasets.push({
			label: 'Done',
			backgroundColor: colors[2],
			lineTension: 0,
			data: [0, 1, 6, 15, 23, 32],
			spanGaps: true,
		});

		return (
			<div>
				<ChartJsStackedArea data={data} height={360} />
			</div>
		);
	}
}

CummulativeFlowDiagramDummy.propTypes = {};

export default injectIntl(CummulativeFlowDiagramDummy);
