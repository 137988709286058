import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Person from '../../../../forecast-app/shared/components/person/person';
import {profilePicSrc} from '../../../../directApi';

class AutoSchedulingDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isManagingFocus: false,
			searchCriteria: '',
			selected: null,
			dropdownExpanded: false,
			renderOnTop: false,
		};
	}

	componentDidMount() {
		this.offset = this.wrapperDivRef.getBoundingClientRect();
		if (this.customScrollRef) {
			this.customScrollRef.setScrollTop(0);
		}
	}

	updateOffset() {
		if (this.wrapperDivRef) {
			this.offset = this.wrapperDivRef.getBoundingClientRect();
		}
	}

	handleInputFocus() {
		if (!this.props.disabled) {
			this.updateOffset();
			this.setState({dropdownExpanded: true});
		}
	}

	handleSearchCriteriaChange(e) {
		const searchCriteria = e.target.value || '';

		if (this.props.updateDropdownSearchCriteria) {
			this.props.updateDropdownSearchCriteria(searchCriteria.toUpperCase());
		}

		this.setState({searchCriteria, selected: 0});
	}

	clearInput() {
		if (this.props.updateDropdownSearchCriteria) {
			this.props.updateDropdownSearchCriteria('');
		}
		this.setState({searchCriteria: ''}, () => {
			this.focusInput();
		});
	}

	focusInput(e) {
		if (this.inputRef) {
			this.inputRef.focus();
		}
	}

	toggleDropdown(e) {
		e.stopPropagation();
		if (this.state.dropdownExpanded) {
			this.inputRef.blur();
			this.props.onCollapse && this.props.onCollapse();
		} else {
			this.inputRef.focus();
			this.updateOffset();
		}

		this.setState({dropdownExpanded: !this.state.dropdownExpanded});
		if (this.props.giveParentFocus) {
			this.props.giveParentFocus();
		}
	}

	getPersonOptions() {
		const searchCriteria = this.state.searchCriteria.toUpperCase();

		return this.props.assignablePersons
			.filter(person => {
				const role = this.props.assignableRoles.find(role => role.id === person.roleId);
				const fullName = (person.fullName || (person.firstName || '') + ' ' + (person.lastName || '')).trim();
				const roleName = role && role.name ? role.name : '';
				return (
					person.active &&
					(searchCriteria === '' ||
						fullName.toUpperCase().includes(searchCriteria) ||
						roleName.toUpperCase().includes(searchCriteria))
				);
			})
			.sort((a, b) => {
				const aFullName = (a.fullName || a.firstName || '' + a.lastName || '').toUpperCase();
				const bFullName = (b.fullName || b.firstName || '' + b.lastName || '').toUpperCase();
				return aFullName < bFullName ? -1 : aFullName > bFullName ? 1 : 0;
			});
	}

	handleKeyPress(e) {
		if (this.state.dropdownExpanded) {
			if (e.key === 'Enter' || e.key === ' ') {
				//Enter / Space
				e.preventDefault();
				const domNode = ReactDOM.findDOMNode(this['option_' + this.state.selected]);
				if (domNode) domNode.click();
			} else if (e.keyCode === 40) {
				// Down arrow
				const optionsLength = this.getPersonOptions().length + 3;
				let selected = 0;
				if (this.state.selected !== null) {
					selected = this.state.selected + 1;
				}
				if (selected >= optionsLength) {
					// Back to start
					selected = 0;
				}
				this.setState({selected: selected});
				if (this.customScrollRef) {
					const domNode = ReactDOM.findDOMNode(this['option_' + selected]);
					if (domNode) {
						this.customScrollRef.setScrollTop(domNode.offsetTop - 50);
					}
				}
			} else if (e.keyCode === 38) {
				e.preventDefault();
				// Alt + Up arrow closes the dropdown
				if (e.altKey) {
					if (this.state.dropdownExpanded) {
						this.props.onCollapse && this.props.onCollapse();
						this.setState({dropdownExpanded: false, selected: null, searchCriteria: ''});
						return;
					}
				}
				// Up arrow
				const optionsLength = this.getPersonOptions().length + 3;
				if (!this.state.renderOnTop && this.state.selected === null) return;

				let selected = 0;
				if (this.state.renderOnTop && this.state.selected === null) {
					selected = optionsLength - 1;
				} else if (this.state.selected > 0) {
					selected = this.state.selected - 1;
				} else {
					selected = optionsLength - 1;
				}
				this.setState({selected: selected});
				if (this.customScrollRef) {
					const domNode = ReactDOM.findDOMNode(this['option_' + selected]);
					if (domNode) {
						this.customScrollRef.setScrollTop(domNode.offsetTop - 200);
					}
				}
			}
		} else if (e.keyCode === 40 && e.altKey) {
			e.preventDefault();
			// Alt + Down arrow opens the dropdown
			if (!this.state.dropdownExpanded && !this.props.disabled) {
				this.updateOffset();
				this.setState({dropdownExpanded: true});
				return;
			}
		}
	}

	onFocus(e) {
		// Clear the timeout so we dont collapse the dropdown
		clearTimeout(this._timeoutID);
		if (!this.state.isManagingFocus) {
			this.setState({
				isManagingFocus: true,
			});
		}
	}

	onBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement;
		if (
			newTarget.id === 'people-list' ||
			newTarget.className.includes('person-option') ||
			newTarget.className.includes('clear-input') ||
			newTarget.className.includes('arrow')
		)
			return;

		if (this.props.updateDropdownSearchCriteria) {
			this.props.updateDropdownSearchCriteria('');
		}
		this.props.onCollapse && this.props.onCollapse();
		this.setState({dropdownExpanded: false, searchCriteria: ''});
		// Set a timeout to collapse the dropdown if we dont get focus within this tick
		this._timeoutID = setTimeout(() => {
			if (this.state.isManagingFocus) {
				this.setState({
					isManagingFocus: false,
				});
			}
		}, 0);
	}

	assignPerson(person, e) {
		e.stopPropagation();

		this.props.assignPerson(person, e);
		this.props.onPersonsChange && this.props.onPersonsChange();
	}

	unassignPerson(person, e) {
		e.stopPropagation();
		this.props.unassignPerson(person, e);
		this.props.onPersonsChange && this.props.onPersonsChange();
	}

	render() {
		const {disabled} = this.props;
		const {formatMessage} = this.props.intl;

		const person_options = this.getPersonOptions().map(person => {
			const roleName = person.role
				? person.role.name
				: person.roleId
				? this.props.assignableRoles.find(role => role.id === person.roleId).name
				: '';
			return {
				id: person.id,
				firstName: person.firstName,
				lastName: person.lastName,
				active: person.active,
				role: roleName,
				profilePictureId: person.profilePictureId,
				profilePictureDefaultId: person.profilePictureDefaultId,
			};
		});

		let style = {};
		let opensUpwards = false;
		if (this.offset) {
			if (this.offset.top + 350 > window.innerHeight) {
				opensUpwards = true;
				style = {
					bottom: window.innerHeight - this.offset.top - 1,
					left: this.offset.left,
					// According to design
					width: this.offset.width < 190 ? this.offset.width + 48 : this.offset.width,
					marginBottom: 0,
				};
			} else {
				style = {
					top: this.offset.top + this.offset.height - 1,
					left: this.offset.left,
					// According to design
					width: this.offset.width < 190 ? this.offset.width + 48 : this.offset.width,
				};
			}
		}

		return (
			<div
				className={
					'auto-scheduling-dropdown no-drag' +
					(this.props.useSmallerStyling ? ' small' : '') +
					(disabled ? ' disabled' : '')
				}
				onFocus={this.onFocus.bind(this)}
				onBlur={this.onBlur.bind(this)}
				ref={div => (this.wrapperDivRef = div)}
				id="auto-schedule-add-member-dropdown"
			>
				{this.props.label ? <label htmlFor="assigned-input">{this.props.label}</label> : null}
				<input
					id="assigned-input"
					autoComplete="off"
					className={
						'subtask-assigned-input' +
						(this.state.dropdownExpanded ? ' expanded' : '') +
						(opensUpwards ? ' opens-upwards' : ' opens-downwards')
					}
					type="text"
					value={this.state.dropdownExpanded ? this.state.searchCriteria : ''}
					onChange={this.handleSearchCriteriaChange.bind(this)}
					ref={input => {
						this.inputRef = input;
					}}
					placeholder={this.props.placeholder || 'Add or remove Team Members'}
					onFocus={this.handleInputFocus.bind(this)}
					onKeyDown={this.handleKeyPress.bind(this)}
					role="combobox"
					aria-autocomplete="list"
					aria-owns="assigned-menu"
					aria-expanded={this.state.dropdownExpanded}
					aria-activedescendant={this.state.selected != null ? 'select_option_' + this.state.selected : ''}
					autoFocus={this.props.autoFocus}
					disabled={disabled}
					data-cy={`${this.props.cy}-input`}
				/>

				{this.state.dropdownExpanded && this.state.searchCriteria.length ? (
					<div
						className="clear-input"
						onClick={this.clearInput.bind(this)}
						tabIndex={-1}
						onKeyDown={this.handleKeyPress.bind(this)}
					/>
				) : null}

				<div
					className={'arrow no-drag' + (this.state.dropdownExpanded ? ' expanded' : '')}
					aria-hidden="true"
					onClick={!disabled ? this.toggleDropdown.bind(this) : null}
					tabIndex={-1}
					onKeyDown={this.handleKeyPress.bind(this)}
					data-cy={'dropdown-close-arrow'}
				/>
				{this.state.dropdownExpanded && !disabled
					? ReactDOM.createPortal(
							<ul
								data-cy={'assigned-dropdown-scrollbar'}
								style={style}
								aria-hidden={!this.state.dropdownExpanded}
								aria-labelledby="assigned-input"
								className={
									'subtask-assigned-dropdown auto-scheduling-dropdown no-drag' +
									(opensUpwards ? ' opens-upwards' : ' opens-downwards')
								}
								role="menu"
								id="assigned-menu"
							>
								<CustomScrollDiv
									autoHeight={true}
									autoHeightMin={1}
									autoHeightMax={300}
									ref={div => (this.customScrollRef = div)}
								>
									<li
										id="people-list"
										role="menuitem"
										className={'no-drag'}
										aria-expanded={true}
										aria-haspopup="menu"
										tabIndex="-1"
									>
										<div className={'list-header no-drag'}>
											<div className="title">{`${formatMessage({id: 'common.people'})} (${
												person_options.length
											})`}</div>
										</div>
										<ul
											className={'auto-scheduling-inner-list no-drag'}
											aria-labelledby="people-list"
											role="menu"
										>
											{person_options.map((person, index) => {
												const personAlreadyAssigned = this.props.assignedPersons.find(
													ap => ap.id === person.id
												);
												return (
													<li
														key={person.id}
														role="menuitem"
														tabIndex="-1"
														className={
															'person-option no-drag' +
															(this.state.selected === index ? ' selected' : '')
														}
														onClick={
															personAlreadyAssigned
																? this.unassignPerson.bind(this, person)
																: this.assignPerson.bind(this, person)
														}
														ref={li => (this['option_' + index] = li)}
														data-cy={'assigned-dropdown-person-option-' + index}
													>
														<Person
															name={`${person.firstName} ${person.lastName || ''}`}
															role={person.role}
															showName={true}
															showRole={true}
															imageSize="new-ui-dropdown"
															imageSrc={profilePicSrc(person.profilePictureId)}
														/>
														{personAlreadyAssigned ? (
															<div className="assigned" />
														) : (
															<div className="assigned-placeholder" />
														)}
													</li>
												);
											})}
										</ul>
									</li>
								</CustomScrollDiv>
							</ul>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</div>
		);
	}
}

AutoSchedulingDropdown.propTypes = {
	disabled: PropTypes.bool,
	assignedPersons: PropTypes.arrayOf(PropTypes.object),
	viewer: PropTypes.object,
	assignablePersons: PropTypes.arrayOf(PropTypes.object).isRequired,
	assignableRoles: PropTypes.arrayOf(PropTypes.object).isRequired,
	isMultiSelect: PropTypes.bool,
	assignPerson: PropTypes.func.isRequired,
	onPersonsChange: PropTypes.func,
	onCollapse: PropTypes.func,
};
export default injectIntl(AutoSchedulingDropdown);
