import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {findDOMNode} from 'react-dom';

class TimelineProjectItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showPercentage: true,
			showDone: true,
			showTriangles: true,
		};
	}

	componentDidMount() {
		//need to do this because items are not updated correctly when they are out of view and then user scrolls to them
		setInterval(this.updateProjectItemText.bind(this), 500);
	}

	UNSAFE_componentWillReceiveProps() {
		if (!this.state.isInitialized) {
			//Launch immediately at start so numbers show up as soon as you expand project
			this.updateProjectItemText();
		}
	}

	updateProjectItemText() {
		const projectItemContainerBoundingRect = findDOMNode(this.projectItemContainer).getBoundingClientRect();
		const projectItemContainerWidth = Math.abs(
			projectItemContainerBoundingRect.left - projectItemContainerBoundingRect.right
		);
		if (projectItemContainerWidth === 0) return;
		let showDone = projectItemContainerWidth > 160;
		let showPercentage = projectItemContainerWidth > 80;
		let showTriangles = projectItemContainerWidth > 50;
		this.setState({showDone, showPercentage, showTriangles, isInitialized: true});
	}

	render() {
		const item = this.props.item;
		const rightTriangleOpacity = item.project.completion === 100 ? 1 : 0.5;
		return (
			<div
				className="project-timeline-project-item"
				ref={e => {
					this.projectItemContainer = e;
				}}
			>
				<div className="outer-container">
					<div className="inner-container">
						<div className="text-container">
							<h3>{this.state.showPercentage ? item.project.completion + '%' : ''}</h3>
							<h3>{this.state.showDone ? 'done' : ''}</h3>
						</div>
						<div className="progress-container">
							<div
								className="progress"
								style={{width: item.project.completion + '%', backgroundColor: item.project.projectColor}}
							/>
							<div className="fill" style={{backgroundColor: item.project.projectColor}} />
						</div>
						{this.state.showTriangles ? (
							<div className="triangle-container">
								<div className="triangle" style={{borderTopColor: item.project.projectColor}} />
								<div
									className="triangle"
									style={{borderTopColor: item.project.projectColor, opacity: rightTriangleOpacity}}
								/>
							</div>
						) : (
							<div className="triangle-placeholder" />
						)}
					</div>
				</div>
			</div>
		);
	}
}

TimelineProjectItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default TimelineProjectItem;
