import React from 'react';
import uuid from 'uuid';
import {ITEM_TYPE} from '../canvas-timeline/canvas_timeline_util';
import {BUTTON_COLOR, BUTTON_STYLE, DependencyType} from '../../../constants';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../warning';
import {createDependency, deleteDependency} from '../../scheduling/scheduling_mutations';

export const getRelevantDependencyTaskIdSet = pageComponent => {
	if (pageComponent.relevantDependencyTaskIdSet) {
		return pageComponent.relevantDependencyTaskIdSet;
	}
	const visibleTaskIdSet = new Set();
	for (const entry of pageComponent.visibleTaskData) {
		visibleTaskIdSet.add(entry.id);
	}
	const relevantDependencies = pageComponent.state.dependencies.filter(
		dependency =>
			visibleTaskIdSet.has(dependency.taskIdDependsOnThis) || visibleTaskIdSet.has(dependency.thisDependsOnTaskId)
	);
	const relevantDependencyTaskIdSet = new Set();
	for (const dependency of relevantDependencies) {
		relevantDependencyTaskIdSet.add(dependency.taskIdDependsOnThis);
		relevantDependencyTaskIdSet.add(dependency.thisDependsOnTaskId);
	}
	pageComponent.relevantDependencyTaskIdSet = relevantDependencyTaskIdSet;
	return relevantDependencyTaskIdSet;
};

export const getDependencyChainTaskIdSet = (pageComponent, taskId) => {
	const dependencyChainTaskIdSet = new Set();
	dependencyChainTaskIdSet.add(taskId);
	let previousTaskIdCount = 0;
	let currentTaskIdCount = 1;
	while (previousTaskIdCount !== currentTaskIdCount) {
		for (const dependency of pageComponent.state.dependencies.filter(
			dependency =>
				dependencyChainTaskIdSet.has(dependency.thisDependsOnTaskId) ||
				dependencyChainTaskIdSet.has(dependency.taskIdDependsOnThis)
		)) {
			dependencyChainTaskIdSet.add(dependency.thisDependsOnTaskId);
			dependencyChainTaskIdSet.add(dependency.taskIdDependsOnThis);
		}
		previousTaskIdCount = currentTaskIdCount;
		currentTaskIdCount = dependencyChainTaskIdSet.size;
	}
	return dependencyChainTaskIdSet;
};

export const getDependencyData = (pageComponent, dragData) => {
	const dependencyData = [];
	const visibleTaskIdSet = new Set();

	const isCreatingDependency = dragData && dragData.itemData && dragData.itemData.item.itemType === ITEM_TYPE.TASK;

	for (const entry of pageComponent.visibleTaskData) {
		visibleTaskIdSet.add(entry.id);
	}
	const relevantDependencies = pageComponent.state.dependencies.filter(
		dependency =>
			visibleTaskIdSet.has(dependency.taskIdDependsOnThis) || visibleTaskIdSet.has(dependency.thisDependsOnTaskId)
	);
	for (const dependency of relevantDependencies) {
		const sourceTaskData = pageComponent.visibleTaskData.find(task => task.id === dependency.taskIdDependsOnThis);
		const targetTaskData = pageComponent.visibleTaskData.find(task => task.id === dependency.thisDependsOnTaskId);
		if (sourceTaskData && targetTaskData) {
			dependencyData.push({
				sourceTaskId: sourceTaskData.id,
				targetTaskId: targetTaskData.id,
				fromX: sourceTaskData.x + (dependency.type === DependencyType.CANNOT_START ? 0 : sourceTaskData.width),
				fromY: sourceTaskData.y + sourceTaskData.height / 2,
				toX: targetTaskData.x + targetTaskData.width,
				toY: targetTaskData.y + targetTaskData.height / 2,
				isCannotStartType: dependency.type === DependencyType.CANNOT_START,
			});
		}
	}
	if (isCreatingDependency) {
		for (const taskData of pageComponent.visibleTaskData) {
			taskData.allowSnapping = taskData.task.projectId === dragData.itemData.item.data.task.projectId;
		}
	}
	return {
		dependencies: dependencyData,
		visibleTaskData: pageComponent.visibleTaskData,
		isDraggingWholeDependencyChainModeOn:
			pageComponent.state.isDraggingWholeDependencyChainModeOn || pageComponent.isShiftDown,
	};
};

export const onDependencyCreation = (pageComponent, thisDependsOnTaskId, taskIdDependsOnThis, isFinishToStartDependency) => {
	const type = isFinishToStartDependency ? DependencyType.CANNOT_START : DependencyType.CANNOT_BE_COMPLETED;
	const temporaryId = uuid.v4();

	const {formatMessage} = pageComponent.props.intl;

	const onSuccess = response => {
		if (response.createDependency.errors) {
			const error = response.createDependency.errors[0];

			const callback = () => {
				if (error === 'CIRCULAR_DEPENDENCY_DETECTED') {
					const newDependecies = pageComponent.state.dependencies.filter(dependency => dependency.id !== temporaryId);
					pageComponent.setState({dependencies: newDependecies}, () => {
						pageComponent.redrawCanvasTimeline({preventFiltering: false});
					});
					pageComponent.getData().dependencies = newDependecies;
				}
			};

			let message = '';
			switch (error) {
				case 'DEPENDING_TASK_ALREADY_STARTED':
					message = formatMessage(
						{id: 'dependency.not_to_do_warning'},
						{
							taskId:
								'T' + pageComponent.getData().tasks.find(task => task.id === taskIdDependsOnThis).companyTaskId,
						}
					);
					break;
				case 'DEPENDING_TASK_IS_ALREADY_DONE':
					message = formatMessage(
						{id: 'dependency.is_done_warning'},
						{
							taskId:
								'T' + pageComponent.getData().tasks.find(task => task.id === taskIdDependsOnThis).companyTaskId,
						}
					);
					break;
				case 'CIRCULAR_DEPENDENCY_DETECTED':
					message = formatMessage({id: 'dependency.custom_validation_error_CIRCULAR_DEPENDENCY_DETECTED'});
					break;
			}

			showModal({
				type: MODAL_TYPE.GENERIC,
				content: (
					<div className="default-warning-modal">
						<Warning messageId="common.invalid_action_modal_title" />
						<div className="warning-part-2">{message}</div>
					</div>
				),
				buttons: [
					{
						text: formatMessage({id: 'common.ok'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
						callback,
					},
				],
			});
		} else {
			const index = pageComponent.state.dependencies.findIndex(dependency => dependency.id === temporaryId);
			const updatedDependencies = pageComponent.state.dependencies.map((d, i) =>
				i === index ? {...d, id: response.createDependency.dependency.node.id, hasTemporaryId: false} : d
			);
			pageComponent.setState({dependencies: updatedDependencies});

			// update the props to show the same dependencies in autoschedule. Should be revisited
			pageComponent.getData().dependencies[index].id = response.createDependency.dependency.node.id;
		}
	};

	createDependency(
		{
			thisDependsOnTaskId,
			taskIdDependsOnThis,
			type,
		},
		onSuccess
	);

	const newDepenencies = pageComponent.state.dependencies.concat({
		id: temporaryId,
		thisDependsOnTaskId,
		taskIdDependsOnThis,
		type,
		hasTemporaryId: true,
	});

	pageComponent.setState({dependencies: newDepenencies});

	// update the props to show the same dependencies in autoschedule. Should be revisited
	// Modify the props only when adding and removing dependencies because this caused issues in the autoschedule preview which uses the same props for dependencies.
	pageComponent.getData().dependencies.push({
		id: temporaryId,
		thisDependsOnTaskId,
		taskIdDependsOnThis,
		type,
	});
};

export const onDependencyDeleted = (pageComponent, dependencyId) => {
	if (!pageComponent.state.simulationMode) {
		const onSuccess = response => {
			if (!response.deleteDependency.errors) {
				const newDepenencies = pageComponent.state.dependencies.filter(dependency => dependency.id !== dependencyId);
				pageComponent.setState({dependencies: newDepenencies});

				// update props to update the canvas wierd flex
				// update the props to show the same dependencies in autoschedule. Should be revisited
				pageComponent.getData().dependencies = pageComponent
					.getData()
					.dependencies.filter(dependency => dependency.id !== dependencyId);

				pageComponent.redrawCanvasTimeline({preventFiltering: true});
			}
		};

		if (dependencyId) {
			deleteDependency(
				{
					id: dependencyId,
				},
				onSuccess
			);
		}
	} else {
		// remove dependency in simulation mode without sending a mutation
		const newDepenencies = pageComponent.state.dependencies.filter(dependency => dependency.id !== dependencyId);
		pageComponent.setState({dependencies: newDepenencies});
		pageComponent.redrawCanvasTimeline({preventFiltering: true});
	}
};
