import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const INDICATOR_COLOR = {
	GREEN: 'green',
	BLUE: 'blue',
	GREY: 'grey',
};

const getColoredStyle = color => {
	let style = {};
	switch (color) {
		case INDICATOR_COLOR.GREEN:
			style.backgroundColor = '#E1FAED';
			style.border = '1px solid #00BF5F';
			style.color = '#00BF5F';
			break;
		case INDICATOR_COLOR.BLUE:
			style.backgroundColor = '#E2F1FF';
			style.border = '1px solid #008EFF';
			style.color = '#008EFF';
			break;
		default:
			//NDICATOR_COLOR.GREY
			style.backgroundColor = '#F7F7FE';
			style.border = '1px solid #E6E6ED';
			style.color = '#4C4C58';
			break;
	}
	return style;
};

const ApprovalStatusIndicator = styled.div`
	display: flex;
	border-radius: 22px;
	padding: 8px 12px;
	margin-left: 8px;
	font-size: 10px;
	line-height: 10px;
	font-weight: 500;
	${props => getColoredStyle(props.color)}
`;

const HeaderLabel = ({text, color}) => {
	return <ApprovalStatusIndicator color={color}>{text}</ApprovalStatusIndicator>;
};

HeaderLabel.INDICATOR_COLOR = INDICATOR_COLOR;

HeaderLabel.propTypes = {
	text: PropTypes.string.isRequired,
	color: PropTypes.oneOf(Object.keys(INDICATOR_COLOR)).isRequired,
};

HeaderLabel.defaultProps = {
	color: INDICATOR_COLOR.GREY,
};

export default HeaderLabel;
