import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown, SingleText} from 'web-components';

export const DepartmentDropdown = ({
	departments,
	dropdownAlignment,
	intl,
	width,
	name,
	optionsName,
	selectedItems,
	onSelect,
	onRemove,
	selectedGroupName,
	noDepartmentOption,
	userpilot,
	headerLines,
	showBulkActions,
	emphasizeEmptyState,
	cy,
}) => {
	const sortedDepartments = [...departments].sort((a, b) => {
		if (a.node.id === null) return -1;
		if (b.node.id === null) return 1;
		if (a.node.name.toLowerCase() < b.node.name.toLowerCase()) return -1;
		if (a.node.name.toLowerCase() > b.node.name.toLowerCase()) return 1;
		return 0;
	});

	const getElements = () => {
		let elements = [];
		if (noDepartmentOption) {
			elements.push(
				<SingleText
					key={null}
					value={null}
					searchString={intl.formatMessage({id: 'department_selector.no_department_text'})}
				>
					{intl.formatMessage({id: 'department_selector.no_department_text'})}
				</SingleText>
			);
		}

		return elements.concat(
			sortedDepartments.map(edge => {
				const {id, name} = edge.node;
				return (
					<SingleText key={id} value={id} searchString={name}>
						{name}
					</SingleText>
				);
			})
		);
	};
	return (
		<Dropdown
			isNested
			isMultiSelect
			headerLines={headerLines}
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
			cy={cy}
		>
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{getElements()}
			</Dropdown.Group>
		</Dropdown>
	);
};

DepartmentDropdown.propTypes = {
	departments: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				name: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	cy: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	headerLines: PropTypes.element,
};

DepartmentDropdown.defaultProps = {
	optionsName: 'Departments',
	name: 'Departments',
	selectedGroupName: 'Selected',
	onSelect: () => false,
	onRemove: () => false,
};

export default injectIntl(
	createFragmentContainer(DepartmentDropdown, {
		departments: graphql`
			fragment DepartmentDropdown_departments on DepartmentTypeEdge @relay(plural: true) {
				node {
					id
					name
				}
			}
		`,
	})
);
