import Util from '../../../forecast-app/shared/util/util';
import {isPersonFiltered, isPersonFilteredNew, isProjectFiltered, isTaskFiltered} from '../FilterUtils';
import {GROUP_TYPE} from '../canvas-timeline/canvas_timeline_util';
import {getProjectIdFromProjectGroup} from './projects_scheduling_util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {PROJECT_SUB_GROUP_TYPE} from '../constants';
import {customAndCompanyDoesNotMatch, customOrCompanyIdMatches} from '../utils';

const filterPersonGroups = pageComponent => {
	const {filterFunctions, groups} = pageComponent.state;
	const useNewFiltering = hasFeatureFlag('improving_heatmap_frontend_performance');

	const isFilteredBySearch = group => {
		const {searchFilterValue} = pageComponent.state;
		let visibleBySearch = true;

		if (searchFilterValue?.length > 0) {
			const name = group.data?.name || '';
			const role = group.data?.role || group.data?.roleName || '';
			const origin = name + role;
			visibleBySearch = Util.normalizedIncludes(origin, searchFilterValue);
		}

		return !visibleBySearch;
	};

	const filterProjectTeamGroup = projectTeamGroup => {
		if (projectTeamGroup?.groups?.length > 0) {
			const personGroups = projectTeamGroup.groups;

			for (const personGroup of personGroups) {
				const isPersonGroupFiltered = useNewFiltering
					? isPersonFilteredNew(pageComponent, personGroup)
					: isPersonFiltered(pageComponent, personGroup);
				personGroup.filtered = isPersonGroupFiltered || isFilteredBySearch(personGroup);
			}
		}
	};

	if (filterFunctions) {
		for (const group of groups) {
			const isProgramGroup = group.groupType === GROUP_TYPE.PROGRAM;

			const findAndFilterProjectTeam = projectGroup => {
				const teamMemberPredicate = childGroup =>
					childGroup.groupType === GROUP_TYPE.PROJECT_SCHEDULING_PROJECT_SUB_GROUP &&
					childGroup.id.includes(PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM);

				const projectTeamGroup = projectGroup.groups.find(teamMemberPredicate);

				if (projectTeamGroup) {
					filterProjectTeamGroup(projectTeamGroup);
				}
			};

			if (isProgramGroup) {
				group.groups.forEach(projectGroup => {
					findAndFilterProjectTeam(projectGroup);
				});
			} else {
				findAndFilterProjectTeam(group);
			}
		}
	}
};

const getTaskGroupsRecursive = (groups, taskGroups = []) => {
	for (const group of groups) {
		if (group.groupType === GROUP_TYPE.TASK) {
			taskGroups.push(group);
		} else if (group.groups?.length > 0) {
			getTaskGroupsRecursive(group.groups, taskGroups);
		}
	}

	return taskGroups;
};

const filterTasksInTaskGroup = (pageComponent, group, isProjectFiltered) => {
	const {items} = pageComponent.state;

	const taskItem = items.find(item => item.groupId === group.id);
	const taskMatchesFilter = taskItem ? isProjectFiltered || !isTaskFiltered(pageComponent, taskItem) : false;

	let subtaskMatchesFilter = false;
	group.groups.forEach(child => {
		if (child.groupType === GROUP_TYPE.TASK && filterTasksInTaskGroup(pageComponent, child, isProjectFiltered)) {
			subtaskMatchesFilter = true;
		}
	});

	const matchesFilter = taskMatchesFilter || subtaskMatchesFilter;

	const isFilteredBySearch = group => {
		const {searchFilterValue} = pageComponent.state;
		let visibleBySearch = true;

		if (searchFilterValue?.length > 0) {
			const taskName = group.data.task.name;
			const companyTaskId = `T${group.data.task.companyTaskId}`;
			const taskValues = `${taskName}${companyTaskId}`;

			const searchString = `${taskValues}`;

			visibleBySearch = Util.normalizedIncludes(searchString, searchFilterValue);
		}

		return !visibleBySearch;
	};

	group.filtered = !matchesFilter || isFilteredBySearch(group);

	return matchesFilter;
};

const filterTasksForProject = (pageComponent, projectGroup, isProjectFiltered) => {
	const projectSubGroups = projectGroup?.groups;

	if (projectSubGroups?.length > 0) {
		const taskGroups = getTaskGroupsRecursive(projectSubGroups);

		for (const group of taskGroups) {
			filterTasksInTaskGroup(pageComponent, group, isProjectFiltered);
		}
	}
};

const filterTaskGroupsAndItems = pageComponent => {
	const {filterFunctions, groups} = pageComponent.state;

	if (filterFunctions) {
		for (const group of groups) {
			let isParentFiltered = group.filtered;

			if (group.data.isConnectedProject || group.data.program) {
				for (const childGroup of group.groups) {
					if (childGroup.groupType === GROUP_TYPE.PROJECT_SCHEDULING_PROJECT_SUB_GROUP) continue;

					if (!isParentFiltered) {
						isParentFiltered = childGroup.filtered;
					}

					filterTasksForProject(pageComponent, childGroup, isParentFiltered);
				}
			} else {
				filterTasksForProject(pageComponent, group, group.filtered);
			}
		}
	}
};

const filterProjectGroups = pageComponent => {
	const {isProjectTimeline} = pageComponent.props;
	const {filterFunctions, filters, groups} = pageComponent.state;

	const isFilteredBySearch = (group, groupingGroup) => {
		const {searchFilterValue} = pageComponent.state;
		let visibleBySearch = true;

		if (searchFilterValue?.length > 0) {
			const companyProjectId = `P-${group.data.companyProjectId}P${group.data.companyProjectId}`;
			const projectName = `${group.data.name}`;
			const projectClientName = `${group.data.clientName}`;
			const projectStatusName = `${group.data.status}`;
			const projectProgramPrefix = group.data.programPrefix || '';
			const projectValues = `${companyProjectId}${projectName}${projectClientName}${projectStatusName}${projectProgramPrefix}`;

			const programPrefix = groupingGroup?.data.program?.prefix || '';
			const companyProjectGroupId = groupingGroup?.data.companyProjectGroupId
				? `X-${groupingGroup.data.companyProjectGroupId}X${groupingGroup.data.companyProjectGroupId}`
				: '';
			const groupingGroupName = groupingGroup ? `${groupingGroup.data.name}` : '';
			const projectGroupValues = `${companyProjectGroupId}${groupingGroupName}${programPrefix}`;

			const searchString = `${projectValues}${projectGroupValues}`;

			visibleBySearch = Util.normalizedIncludes(searchString, searchFilterValue);
		}

		return !visibleBySearch;
	};

	if (filterFunctions) {
		for (const group of groups) {
			const groupIsConnectedProject = group.data.isConnectedProject;
			const groupIsProgram = group.data.program;

			if (groupIsConnectedProject || groupIsProgram) {
				let allProjectsFiltered = true;

				for (const childGroup of group.groups) {
					if (childGroup.groupType !== GROUP_TYPE.PROJECT) continue;

					childGroup.filtered =
						isProjectFiltered(pageComponent, getProjectIdFromProjectGroup(childGroup)) ||
						(isFilteredBySearch(childGroup, group) && !isProjectTimeline);

					if (!childGroup.filtered) {
						allProjectsFiltered = false;
					}
				}

				group.filtered = !isProjectTimeline && filters?.project && allProjectsFiltered;
			} else {
				group.filtered =
					isProjectFiltered(pageComponent, getProjectIdFromProjectGroup(group)) ||
					(isFilteredBySearch(group, null) && !isProjectTimeline);
			}
		}
	}
};

const filterConnectedProjects = pageComponent => {
	const {projectId, groupId, shareUrl} = pageComponent.props;
	const {data, lightWeightData, groups} = pageComponent.state;
	const availableData = data || lightWeightData;

	const timelineForProjectFromProjectGroup =
		projectId !== undefined &&
		availableData.projects.some(project => customOrCompanyIdMatches(project, projectId) && project.isInProjectGroup);
	// In Project Timeline we need to filter out groups from other projects.
	groups
		.filter(group => group.groupType !== GROUP_TYPE.PROGRAM && !group.filtered)
		.forEach(group => {
			if (projectId !== undefined) {
				if (timelineForProjectFromProjectGroup && group.data.isConnectedProject) {
					group.filtered = !group.groups || !group.groups.some(gr => customOrCompanyIdMatches(gr.data, projectId));

					group.groups
						.filter(nestedGroup => !nestedGroup.filtered)
						.forEach(nestedGroup => {
							nestedGroup.filtered =
								nestedGroup.groupType === GROUP_TYPE.PROJECT &&
								customAndCompanyDoesNotMatch(nestedGroup.data, projectId);
						});
				} else {
					group.filtered = customAndCompanyDoesNotMatch(group.data, projectId);
				}
			} else if (groupId !== undefined) {
				const projectGroupId = availableData.projectGroups.find(
					projectGroup => projectGroup.companyProjectGroupId === parseInt(pageComponent.props.groupId)
				).id;
				const projectIds = availableData.projects
					.filter(project => project.projectGroupId === projectGroupId)
					.map(project => project.companyProjectId);
				group.filtered =
					group.data.companyProjectGroupId !== parseInt(pageComponent.props.groupId) &&
					!projectIds.includes(group.data.companyProjectId);
			} else if (shareUrl !== undefined) {
				group.filtered = group.data.companyProjectId !== availableData.sharedProjectCompanyProjectId;
			}
		});
};

export const filterGroupsAndItems = pageComponent => {
	const {lightweightDataLoaded, dataLoaded} = pageComponent.state;

	if (lightweightDataLoaded || dataLoaded) {
		const {isProjectTimeline} = pageComponent.props;

		filterProjectGroups(pageComponent);
		filterPersonGroups(pageComponent);
		filterTaskGroupsAndItems(pageComponent);

		if (isProjectTimeline) {
			filterConnectedProjects(pageComponent);
		}
	}
};
