import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { FlexRow, MultiCombobox, WorkSignifier } from '@forecast-it/design-system';
import Util from '../../../forecast-app/shared/util/util';
import { useIntl } from 'react-intl';
import { DesignSystemMultiComboBoxBorderAdapter } from '../../../forecast-app/shared/components/dropdowns/DesignSystemComboBoxOldLookAdapter';
const sortByName = (a, b) => {
    if (!a.id)
        return 1;
    if (!b.id)
        return -1;
    return Util.sortAlphabetically(a.name, b.name);
};
/* Based on Label_dropdown.js. The use case was the project label dropdown so there is no support for suggestions yet*/
export const LabelDropdown = ({ labels, selectedItems, setSelectedItems, 'data-cy': dataCy, }) => {
    const { formatMessage } = useIntl();
    const sortedLabels = labels.sort(sortByName);
    const dropdownItems = sortedLabels.map(label => {
        var _a, _b, _c;
        return Object.assign(Object.assign({}, label), { key: label.id, textValue: label.name || undefined, color: label.color || '', section: ((_a = label.category) === null || _a === void 0 ? void 0 : _a.id)
                ? { key: (_b = label.category) === null || _b === void 0 ? void 0 : _b.id, name: ((_c = label.category) === null || _c === void 0 ? void 0 : _c.name) || '' }
                : { key: 'no-category', name: formatMessage({ id: 'settings_labels.no_category' }) } });
    });
    return (React.createElement(FlexRow, null,
        React.createElement(DesignSystemMultiComboBoxBorderAdapter, null,
            React.createElement(MultiCombobox, { height: 'small', width: 'flex', items: dropdownItems, selectedKeys: selectedItems, setSelectedKeys: setSelectedItems, "data-cy": dataCy }, label => React.createElement(WorkSignifier, { workId: label.name || '', color: label.color || '' })))));
};
export default createFragmentContainer(LabelDropdown, {
    labels: graphql `
		fragment LabelDropdown_labels on Label @relay(plural: true) {
			color
			name
			id
			category {
				id
				name
			}
		}
	`,
});
