import React from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown, HexagonText} from 'web-components';
import * as Sentry from '@sentry/browser';
import {profilePicSrc} from '../../../directApi';

export const ProjectContactDropdown = ({
	projects,
	dropdownAlignment,
	width,
	name,
	selectedItems,
	showRole,
	onRemove,
	onSelect,
	optionsName,
	selectedGroupName,
	userpilot,
	emphasizeEmptyState,
	headerLines,
	cy,
}) => {
	const contactPersons = [];
	projects.forEach(projectEdge => {
		if (projectEdge.node.projectPersons) {
			const contacts = projectEdge.node.projectPersons.edges.filter(person => person.node.isContactPerson);
			contactPersons.push(...contacts);
		} else {
			Sentry.captureMessage('Missing projectPersons on project: ' + projectEdge.node.id);
		}
	});

	const contactPersonIds = contactPersons.map(contactPerson => contactPerson.node.person.id);
	const uniqueContactPersonIds = [...new Set(contactPersonIds)];
	const options = uniqueContactPersonIds.map(contactPersonId => {
		const contactPerson = contactPersons.find(contactPerson => contactPerson.node.person.id === contactPersonId);

		return contactPerson.node.person;
	});

	const sortedOptions = [...options].sort((a, b) => {
		if (a.id === null) return -1;
		if (b.id === null) return 1;
		if (
			`${a.firstName ? a.firstName.toLowerCase() : ''} ${a.lastName ? a.lastName.toLowerCase() : ''}` <
			`${b.firstName ? b.firstName.toLowerCase() : ''} ${b.lastName ? b.lastName.toLowerCase() : ''}`
		)
			return -1;
		if (
			`${a.firstName ? a.firstName.toLowerCase() : ''} ${a.lastName ? a.lastName.toLowerCase() : ''}` >
			`${b.firstName ? b.firstName.toLowerCase() : ''} ${b.lastName ? b.lastName.toLowerCase() : ''}`
		)
			return 1;
		return 0;
	});
	return (
		<Dropdown
			isNested
			isMultiSelect
			headerLines={headerLines}
			onRemove={onRemove}
			onSelect={onSelect}
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
			cy={cy}
		>
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{sortedOptions.map(option => {
					const {id, firstName, lastName, profilePictureId} = option;
					return (
						<HexagonText
							key={id}
							value={id}
							searchString={`${firstName} ${lastName}`}
							text={`${firstName} ${lastName}`}
							imgUrl={profilePicSrc(profilePictureId)}
						/>
					);
				})}
			</Dropdown.Group>
		</Dropdown>
	);
};

ProjectContactDropdown.propTypes = {
	projects: PropTypes.array.isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	showRole: PropTypes.bool,
	selectedGroupName: PropTypes.string,
	headerLines: PropTypes.element,
};

ProjectContactDropdown.defaultProps = {
	selectedGroupName: 'Selected',
	showRole: false,
	onSelect: () => false,
	onRemove: () => false,
};

export default createFragmentContainer(ProjectContactDropdown, {
	projects: graphql`
		fragment ProjectContactDropdown_projects on ProjectTypeEdge @relay(plural: true) {
			node {
				id
				projectPersons(first: 1000000, contactsOnly: true) {
					edges {
						node {
							id
							isContactPerson
							person {
								id
								firstName
								lastName
								profilePictureId
							}
						}
					}
				}
			}
		}
	`,
});
