/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BudgetData_project$ref = any;
type BudgetPageHeader_project$ref = any;
type Details_project$ref = any;
type ExpenseDetails_project$ref = any;
type InvoiceBudgetData_project$ref = any;
type InvoiceDetails_project$ref = any;
type TimeBudgetData_project$ref = any;
type TimeDetails_project$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetPage_project$ref: FragmentReference;
declare export opaque type BudgetPage_project$fragmentType: BudgetPage_project$ref;
export type BudgetPage_project = {|
  +id: string,
  +financialDataAvailability: ?number,
  +manualProgressOnProjectEnabled: ?boolean,
  +manualProgressOnPhasesEnabled: ?boolean,
  +manualProgressOnTasksEnabled: ?boolean,
  +budgetType: ?BUDGET_TYPE,
  +$fragmentRefs: BudgetPageHeader_project$ref & BudgetData_project$ref & TimeBudgetData_project$ref & Details_project$ref & InvoiceDetails_project$ref & TimeDetails_project$ref & ExpenseDetails_project$ref & InvoiceBudgetData_project$ref,
  +$refType: BudgetPage_project$ref,
|};
export type BudgetPage_project$data = BudgetPage_project;
export type BudgetPage_project$key = {
  +$data?: BudgetPage_project$data,
  +$fragmentRefs: BudgetPage_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentMonthLastDay",
      "type": "Int"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BudgetPage_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "financialDataAvailability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manualProgressOnProjectEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manualProgressOnPhasesEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "manualProgressOnTasksEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BudgetPageHeader_project"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "currentMonth",
          "variableName": "currentMonth"
        },
        {
          "kind": "Variable",
          "name": "currentMonthLastDay",
          "variableName": "currentMonthLastDay"
        },
        {
          "kind": "Variable",
          "name": "currentYear",
          "variableName": "currentYear"
        }
      ],
      "kind": "FragmentSpread",
      "name": "BudgetData_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeBudgetData_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Details_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceDetails_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeDetails_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExpenseDetails_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceBudgetData_project"
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = '61b149cdbf324acf0b68298a451910b0';

module.exports = node;
