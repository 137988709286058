import {
	GROUP_TYPE,
	TIMELINE_HEATMAP_ITEM_HEIGHT,
	GROUP_SECTION_CAPACITY_PLACEHOLDER_GROUP_HEIGHT,
	TIMELINE_GRAPH_HEIGHT,
	SCHEDULE_PEOPLE_HEATMAP_ITEM_SIZE,
} from './canvas_timeline_util';
import {interactionManager} from './canvas_timeline_interaction_manager';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import IntervalTree from 'node-interval-tree';
import {removeFromArray} from '../utils';
import {SCHEDULING_VIEW} from '../../../constants';
import {PROJECT_SUB_GROUP_TYPE} from '../constants';
import DataManager from '../DataManager';

class Group {
	constructor(pageComponent, groupType, data) {
		this.pageComponent = pageComponent;

		this.data = data;
		this.groupType = groupType;
		this.itemRowHeight = data.height || this.getItemRowHeightFromGroupType(groupType);
		this.expansionMarginBottom = this.getExpansionMarginBottomFromGroupType(groupType);
		this.marginBottom = data.marginBottom || this.getMarginBottomFromGroupType(groupType, data);
		this.marginTop = data.marginTop || this.getMarginTopFromGroupType(groupType, data);
		this.height = this.itemRowHeight;
		this.totalHeight = this.height;
		this.marginX = data.marginX | 0;
		this.expanded = false;
		this.id = data.id;
		this.groups = data.groups || [];
		this.itemRowCount = 1;
		this.items = [];
		this.composeItems = data.composeItems;
		this.calculateHeatmapCache = data.calculateHeatmapCache;
		this.hideIfEmpty = data.hideIfEmpty;
		this.showOnlyIfNoSiblingsVisible = data.showOnlyIfNoSiblingsVisible;
		this.drawIfAnyChildVisible = typeof data.drawIfAnyChildVisible === 'boolean' ? data.drawIfAnyChildVisible : true;
		this.dragGroupId = data.dragGroupId;
		this.droppableItemTypes = data.droppableItemTypes || [];
		this.createdByFreeDrag = data.createdByFreeDrag || false;
		this.isButtonDisabled = data.isButtonDisabled !== undefined ? data.isButtonDisabled : () => false;
		this.preventExpansion = data.preventExpansion || false;
		this.onExpand = data.onExpand;
		this.hideCondition = data.hideCondition;
		this.filtered = data.filtered || false;
		this.forceDrawCondition = data.forceDrawCondition;
		this.renderRowLines = data.renderRowLines;
		this.hideIfAllChildrenAreFiltered = data.hideIfAllChildrenAreFiltered;
		this.includeInRowHover = true;
		this.fullDataLoaded = data.fullDataLoaded !== false;
		this.validHeatmapItemTypes = data.validHeatmapItemTypes || null;
		this.visibleItemsTree = null;
		this.isHeatmapCombinedWithItems = data.isHeatmapCombinedWithItems || false;
		this.heatmapItemPredicate = data.heatmapItemPredicate || null;
		this.displayItemsWithHeatmap = data.displayItemsWithHeatmap || false;
		this.hasItemCreate = data.hasItemCreate || false;

		DataManager.recalculateDrawnStepsForNewGroup(pageComponent, this);

		this.constructChildGroupMap();
		this.setEventListeners(data);
	}

	isDisabled() {
		return this.data?.disabled;
	}

	setEventListeners(data) {
		if (data.onMouseEnter) {
			this.onMouseEnter = data.onMouseEnter;
		}

		if (data.onMouseLeave) {
			this.onMouseLeave = data.onMouseLeave;
		}

		if (data.onClick) {
			this.onClick = data.onClick;
		}

		if (data.onItemCreate) {
			this.onItemCreate = data.onItemCreate;
		}

		if (data.onButtonClick) {
			this.onButtonClick = data.onButtonClick;
		}
	}

	getItemRowHeightFromGroupType(groupType) {
		const {schedulingView} = this.pageComponent?.props;
		const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;

		switch (groupType) {
			case GROUP_TYPE.NON_PROJECT_TIME:
				return 40;
			case GROUP_TYPE.NO_CONTENT:
				return 40;
			case GROUP_TYPE.PEOPLE_SCHEDULING_TASK:
				return 48;
			case GROUP_TYPE.PEOPLE_SCHEDULING_UNASSIGNED_ROLE:
				return 48;
			case GROUP_TYPE.PEOPLE_SCHEDULING_UNASSIGNED_ROLE_TASK:
				return 48;
			case GROUP_TYPE.PHASE:
				return 45;
			case GROUP_TYPE.TASK:
				return 34;
			case GROUP_TYPE.PROJECT_SCHEDULING_PROJECT_SUB_GROUP:
				return 50;
			case GROUP_TYPE.TOTAL_RESOURCE_UTILIZATION:
				return 48;
			case GROUP_TYPE.PEOPLE_SCHEDULING_PERSON_ALLOCATIONS:
				return 48;
			case GROUP_TYPE.LOADING:
				return 28;
			case GROUP_TYPE.PLACEHOLDERS_SCHEDULING_PLACEHOLDER_GROUPING_GROUP:
				return 48;
			case GROUP_TYPE.PLACEHOLDERS_SCHEDULING_STAFFING_GROUPING_GROUP:
				return 32;
			case GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP:
				return GROUP_SECTION_CAPACITY_PLACEHOLDER_GROUP_HEIGHT;
			case GROUP_TYPE.CAPACITY_OVERVIEW_ENTITY_GROUPING_GROUP:
				return 48;
			case GROUP_TYPE.TIMELINE_GRAPH_GROUP:
				return TIMELINE_GRAPH_HEIGHT;
			case GROUP_TYPE.PROGRAM:
				return 56;
			case GROUP_TYPE.PERSON:
				return isProjectScheduling ? TIMELINE_HEATMAP_ITEM_HEIGHT : SCHEDULE_PEOPLE_HEATMAP_ITEM_SIZE;
			case GROUP_TYPE.PROJECT:
				return isProjectScheduling ? 56 : 48;
			case GROUP_TYPE.PERSON_GROUPING_GROUP:
				return 48;
			case GROUP_TYPE.PROJECT_ENTITY_GROUP:
				return 48;
			default:
				// eslint-disable-next-line no-console
				console.warn(
					'Unrecognized group type. >' +
						groupType +
						'< Using default height of 40px. Check getItemRowHeightFromGroupType function in canvas_timeline_group.'
				);
				return 40;
		}
	}

	refreshData(updatedData) {}

	getExpansionMarginBottomFromGroupType(groupType) {
		switch (groupType) {
			default:
				return 0;
		}
	}

	getMarginBottomFromGroupType(groupType, data) {
		switch (groupType) {
			default:
				return 0;
		}
	}

	getMarginTopFromGroupType(groupType, data) {
		switch (groupType) {
			default:
				return 0;
		}
	}

	getHeatmapHeight() {
		if (this.displayItemsWithHeatmap) {
			switch (this.groupType) {
				case GROUP_TYPE.PERSON:
					if (this.pageComponent) {
						const {schedulingView} = this.pageComponent.props;

						if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
							return TIMELINE_HEATMAP_ITEM_HEIGHT;
						}
					}

					break;
				default:
					break;
			}
		}

		return 0;
	}

	toggleExpansion() {
		this.setExpanded(!this.expanded);
	}

	setExpanded(expanded) {
		if (this.preventExpansion) return;
		switch (this.groupType) {
			case 0:
				tracking.trackEvent((expanded ? 'Expanding' : 'Collapsing') + ' People Scheduling, Person');
				trackEvent('People Scheduling Person', expanded ? 'Expanded' : 'Collapsed');
				break;
			case 8:
				tracking.trackEvent((expanded ? 'Expanding' : 'Collapsing') + ' Project Scheduling, Project');
				trackEvent('Project Scheduling Project', expanded ? 'Expanded' : 'Collapsed');
				break;
			case 9:
				tracking.trackEvent((expanded ? 'Expanding' : 'Collapsing') + ' Project Scheduling, Phase');
				trackEvent('Project Scheduling Phase', expanded ? 'Expanded' : 'Collapsed');
				break;
			case 12:
				if (this.data.id.includes(PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM)) {
					tracking.trackEvent((expanded ? 'Expanding' : 'Collapsing') + ' Project Scheduling, Project Team');
					trackEvent('Project Scheduling Project Team', expanded ? 'Expanded' : 'Collapsed');
				} else if (this.data.id.includes(PROJECT_SUB_GROUP_TYPE.PHASES)) {
					tracking.trackEvent((expanded ? 'Expanding' : 'Collapsing') + ' Project Scheduling, Phase List');
					trackEvent('Project Scheduling Phase List', expanded ? 'Expanded' : 'Collapsed');
				} else if (this.data.id.includes(PROJECT_SUB_GROUP_TYPE.PLACEHOLDERS)) {
					tracking.trackEvent((expanded ? 'Expanding' : 'Collapsing') + ' Project Scheduling, Placeholders');
					trackEvent('Project Scheduling Placeholder', expanded ? 'Expanded' : 'Collapsed');
				}
				break;
			case 22:
				tracking.trackEvent((expanded ? 'Expanding' : 'Collapsing') + ' People Scheduling, Person Group');
				trackEvent('People Scheduling Person Group', expanded ? 'Expanded' : 'Collapsed');

				break;
		}
		this.expanded = expanded;
		if (this.onExpand) {
			this.onExpand(this.expanded);
		}
	}

	// Assigns the correct itemRow to each item, and assigns itemRow count to the group
	composeItemRows(items = []) {
		this.items = items;
		const itemRows = [];
		const itemsWithRows = [];
		const itemsWithoutRows = [];
		const itemRowPropName = this.groupIds ? 'groupedItemRow' : 'itemRow';
		for (const item of items) {
			if (item.filtered || (!item.visible && !item.isBeingDragged) || item.isBeingGhosted) continue;
			if (item[itemRowPropName] !== undefined && item[itemRowPropName] >= 0) {
				itemsWithRows.push(item);
			} else {
				itemsWithoutRows.push(item);
			}
		}
		for (const item of itemsWithRows) {
			while (itemRows.length <= item[itemRowPropName]) {
				itemRows.push([]);
			}
			itemRows[item[itemRowPropName]].push(item);
		}
		for (const item of itemsWithoutRows) {
			const {startDate, endDate} = item;
			let needsNewRow = true;
			for (let i = 0; i < itemRows.length; i++) {
				let canAddToRow = true;
				for (const rowItem of itemRows[i]) {
					if (startDate < rowItem.endDate + 1 && rowItem.startDate <= endDate) {
						canAddToRow = false;
						break;
					}
				}
				if (canAddToRow) {
					itemRows[i].push(item);
					item[itemRowPropName] = i;
					needsNewRow = false;
					break;
				}
			}
			if (needsNewRow) {
				itemRows.push([item]);
				item[itemRowPropName] = itemRows.length - 1;
			}
		}
		while (itemRows.length > 1 && !itemRows[0].length) {
			itemRows.shift();
			for (const item of items) {
				item[itemRowPropName]--;
			}
		}
		this.itemRowCount = itemRows.length || 1;
		if (this.minimumItemRowCount && this.itemRowCount < this.minimumItemRowCount) {
			this.itemRowCount = this.minimumItemRowCount;
		}
	}

	adjustItemY(item, y) {
		return Math.floor(y + (this.itemRowHeight - 1 - item.height) / 2);
	}

	constructChildGroupMap() {
		if (this.groups) {
			this.childGroupMap = new Map();

			for (let i = 0; i < this.groups.length; i++) {
				const childGroup = this.groups[i];
				this.childGroupMap.set(childGroup.id, childGroup);
			}
		}
	}

	addToChildGroupMap(childGroup) {
		this.childGroupMap.set(childGroup.id, childGroup);
	}

	removeFromChildGroupMap(childGroupId) {
		this.childGroupMap.delete(childGroupId);
	}

	childGroupMapHasGroupId(groupId) {
		return this.childGroupMap.has(groupId);
	}

	isHeatmapItemType(item) {
		return this.validHeatmapItemTypes.has(item.itemType);
	}

	isCombinedWithGroupHeatmap(groupId) {
		return this.isHeatmapCombinedWithItems && (groupId === this.id || this.groupIds?.includes(groupId));
	}

	followsHeatmapPredicate(item) {
		return this.heatmapItemPredicate !== null ? this.heatmapItemPredicate(item) : false;
	}

	isHeatmapItem(item) {
		if (!this.isHeatmapItemType(item)) {
			return false;
		}

		return (
			this.childGroupMapHasGroupId(item.getVisibleItemsTreeGroupId()) ||
			this.isCombinedWithGroupHeatmap(item.groupId) ||
			this.followsHeatmapPredicate(item)
		);
	}

	setVisibleItemsTree(tree) {
		this.visibleItemsTree = tree;
	}

	getVisibleItemsTree() {
		return this.visibleItemsTree;
	}

	isVisibleItemsTreeSet() {
		return this.visibleItemsTree !== null;
	}

	searchVisibleItemsTree(startDate, endDate, includeChildGroups = false) {
		let visibleItems = [];
		if (this.isVisibleItemsTreeSet()) {
			visibleItems = this.getVisibleItemsTree().search(startDate, endDate);
		}

		let childVisibleItems = [];
		if (includeChildGroups) {
			this.groups.forEach(childGroup => {
				if (childGroup.isVisibleItemsTreeSet()) {
					childVisibleItems = childVisibleItems.concat(childGroup.getVisibleItemsTree().search(startDate, endDate));
				}
			});
		}

		return visibleItems.concat(childVisibleItems);
	}

	insertVisibleItem(item, updateItem) {
		if (this.isHeatmapItem(item)) {
			if (!this.isVisibleItemsTreeSet()) {
				this.setVisibleItemsTree(new IntervalTree());
			}

			const insertSuccess = this.getVisibleItemsTree().insert(item.startDate, item.endDate, item);
			if (insertSuccess && updateItem) {
				item.updateForVisibility();
			}
		}
	}

	removeVisibleItem(item) {
		if (this.isVisibleItemsTreeSet() && this.isHeatmapItem(item)) {
			const {startDate, endDate} = item.getVisibleItemsTreeDates();
			return this.getVisibleItemsTree().remove(startDate, endDate, item);
		}

		return false;
	}

	updateVisibleItemKey(item, updateItem) {
		if (this.isVisibleItemsTreeSet() && this.isHeatmapItem(item)) {
			const {startDate, endDate} = item.getVisibleItemsTreeDates();
			this.getVisibleItemsTree().remove(startDate, endDate, item);
			this.insertVisibleItem(item, updateItem);
		}
	}

	addChildGroup(childGroup, position = undefined) {
		if (position === undefined) {
			this.groups.push(childGroup);
		} else {
			this.groups.splice(position, 0, childGroup);
		}
		childGroup.parentGroup = this;

		if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
			this.addToChildGroupMap(childGroup);
		}
	}

	removeChildGroup(childGroupId) {
		const removedGroups = removeFromArray(this.groups, group => group.id === childGroupId);
		this.recursiveRemoveGroupsFromCache(removedGroups);

		if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
			this.removeFromChildGroupMap(childGroupId);
		}
	}

	recursiveRemoveGroupsFromCache(groups) {
		if (Array.isArray(groups)) {
			for (const group of groups) {
				DataManager.removeGroupFromCache(group.id);
				this.recursiveRemoveGroupsFromCache(group.groups);
			}
		}
	}

	replaceChildGroup(oldChildGroup, newChildGroup) {
		const removedGroups = removeFromArray(this.groups, group => group.id === oldChildGroup.id, newChildGroup);
		this.recursiveRemoveGroupsFromCache(removedGroups);

		if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
			this.removeFromChildGroupMap(oldChildGroup.id);
			this.addToChildGroupMap(newChildGroup);
		}
	}

	draw(x, y, width, height, group, collapsableSectionHeight) {
		//Only add the part of the group that is sticking out from below the collapsable section if they overlap
		if (!group.isInCollapsableSection && y < collapsableSectionHeight) {
			interactionManager.addGroup(group, collapsableSectionHeight, group.height + y - collapsableSectionHeight);
		} else {
			interactionManager.addGroup(group, y, group.height);
		}
	}
}

export default Group;
