import React from 'react';
import Styled from 'styled-components';
import {useIntl} from 'react-intl';
import {CSS_CONSTANTS} from '../../../css_variables';

const Container = Styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	background-color: ${CSS_CONSTANTS.background_ghost_white};
	border: 1px solid ${CSS_CONSTANTS.schedule_border_color_primary};
	border-radius: 8px;
	padding 20px;
`;

const Header = Styled.h4`
	margin: 0 0 8px 0;
	font-weight: 600;
	font-size: 13px;
`;

const Description = Styled.span`
	margin: 0 0 8px 0;
	font-weight: 400;
	font-size: 12px;
`;

const TextButton = Styled.button`
	border: none;
	background: none;
	text-decoration: underline;
    display: inline-block;
    color: #0768C0;
    font-size: 13px;
    font-weight: 400;
    padding: 0;
`;

export default ({activateStaffingMode}) => {
	const {formatMessage} = useIntl();

	return (
		<Container>
			<Header>{formatMessage({id: 'scheduling.find_available_team_member'})}</Header>
			<Description>{formatMessage({id: 'scheduling.find_available_team_member_description'})}</Description>
			<TextButton onClick={activateStaffingMode}>{formatMessage({id: 'scheduling.find_team_member'})}</TextButton>
		</Container>
	);
};
