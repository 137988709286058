import DirectApi from '../../../directApi';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

export const fetchData = (startDate, endDate, personId) => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const body = {
		method: 'POST',
		headers,
		credentials: 'include',
		body: JSON.stringify({
			startYear: startDate.year(),
			startMonth: startDate.month() + 1,
			startDay: startDate.date(),
			endYear: endDate.year(),
			endMonth: endDate.month() + 1,
			endDay: endDate.date(),
			personIds: [personId],
		}),
	};
	return fetch(
		DirectApi.graphqlServerEndpoint(
			hasFeatureFlag('use_new_endpoint_for_standalone_utilization_modal')
				? 'scheduling/utilization_v2'
				: 'scheduling/utilization'
		),
		body
	)
		.then(response => response.json())
		.then(data => {
			const distributionMap = new Map();
			Object.keys(data[personId].distribution).forEach(id => {
				distributionMap.set(id, data[personId].distribution[id]);
			});

			let minutesAllocated = data[personId].minutesAllocated;
			let minutesAllocatedHard = data[personId].minutesAllocatedHard;
			let minutesAllocatedSoft = data[personId].minutesAllocatedSoft;
			let minutesAllocatedSoftWin = data[personId].minutesAllocatedSoftWin;

			return {
				distributionMap,
				minutesAllocated,
				minutesAllocatedHard,
				minutesAllocatedSoft,
				minutesAllocatedSoftWin,
			};
		});
};
