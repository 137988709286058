import React, {Component} from 'react';
import {categoryColors} from '../constants';
import PropTypes from 'prop-types';

class HeaderTitleDropdown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			didFocusOnOpen: false,
		};

		this.filteredElements = this.filteredElements.bind(this);
	}

	componentDidUpdate() {
		if (this.props.expanded) {
			if (!this.state.didFocusOnOpen) {
				this.inputField.focus();
				this.setState({didFocusOnOpen: true});
			}
		} else if (this.state.didFocusOnOpen) {
			this.setState({didFocusOnOpen: false});
		}
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			const elements = this.filteredElements();
			if (elements.length === 1) {
				this.props.handleSelectElement(elements[0].node);
			}
		}
	}

	filteredElements() {
		let elements = this.props.elements;
		if (this.props.searchValue.length) {
			elements = elements.filter(e => {
				return e.node.name && e.node.name.toLowerCase().includes(this.props.searchValue.toLowerCase());
			});
		}
		return elements;
	}

	render() {
		const selectedElement = this.props.selectedElement;
		const elements = this.filteredElements();
		return (
			<div
				className="info"
				data-cy={this.props.cy}
				data-class="selector"
				tabIndex="0"
				//onBlur={this.props.handleCollapse.bind(this)}
				onClick={this.props.handleClick.bind(this)}
				name="dropdown-element"
			>
				{this.props.isInsightSelector ? (
					<h2 className="name">
						{selectedElement
							? selectedElement.name && selectedElement.name.trim() !== ''
								? (this.props.titlePrefix ? this.props.titlePrefix : '') + selectedElement.name
								: this.props.emptyTitle
							: this.props.defaultTitle}
					</h2>
				) : (
					<h1 className="name">
						{this.props.isProjectDropdown && selectedElement
							? selectedElement.companyProjectId !== undefined
								? 'P-' + selectedElement.companyProjectId + ' '
								: 'X-' + selectedElement.companyProjectGroupId + ' '
							: ''}
						{selectedElement
							? selectedElement.name && selectedElement.name.trim() !== ''
								? (this.props.titlePrefix ? this.props.titlePrefix : '') + selectedElement.name
								: this.props.emptyTitle
							: this.props.defaultTitle}
					</h1>
				)}
				<div className={'expandArrow ' + (this.props.expanded ? 'expanded' : 'collapsed')} />
				<div className={'drop-down ' + (this.props.expanded ? 'expanded' : 'collapsed')}>
					<div className="dropdown-input-container" name="dropdown-element" onClick={e => e.stopPropagation()}>
						<input
							type="text"
							ref={input => {
								this.inputField = input;
							}}
							placeholder={this.props.inputPlaceholder}
							value={this.props.searchValue}
							autoComplete={'off'}
							onChange={this.props.changeDropdownSearchValue.bind(this)}
							name="dropdown-element"
							onBlur={this.props.handleInputBlur.bind(this)}
							onKeyPress={this.handleKeyPress.bind(this)}
						/>
					</div>
					<ul className="global">
						{this.props.topElement ? this.props.topElement : null}
						{elements.map(e => (
							<li
								className={
									'dropdown-item' +
									(!this.props.isClientUser && e.node.isInProjectGroup ? ' nested-element' : '')
								}
								name="dropdown-element"
								key={e.node.id}
								onMouseDown={this.props.handleSelectElement.bind(this, e.node)}
							>
								{this.props.isInsightDropdown ? (
									<div className="color" style={{backgroundColor: categoryColors[e.node.category].dark}} />
								) : (
									''
								)}
								{this.props.isProjectDropdown ? (
									<div
										className="color"
										style={{backgroundColor: e.node.projectColor ? e.node.projectColor : e.node.color}}
									/>
								) : (
									''
								)}
								{this.props.isProjectDropdown
									? e.node.companyProjectId !== undefined
										? 'P-' + e.node.companyProjectId + ' '
										: 'X-' + e.node.companyProjectGroupId + ' '
									: ''}
								{e.node.name ? e.node.name : this.props.emptyTitle}
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}
}

HeaderTitleDropdown.propTypes = {
	selectedElement: PropTypes.object,
	elements: PropTypes.array,
	expanded: PropTypes.bool,
	handleSelectElement: PropTypes.func.isRequired,
	handleInputBlur: PropTypes.func.isRequired,
	changeDropdownSearchValue: PropTypes.func.isRequired,
	handleClick: PropTypes.func.isRequired,
	handleCollapse: PropTypes.func.isRequired,
	topElement: PropTypes.any,
	defaultTitle: PropTypes.string.isRequired,
	inputPlaceholder: PropTypes.string.isRequired,
	isProjectDropdown: PropTypes.bool,
};

export default HeaderTitleDropdown;
