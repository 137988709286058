/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AdvancedAnalyticsPage_viewer$ref = any;
export type AdvancedAnalyticsPage_QueryVariables = {||};
export type AdvancedAnalyticsPage_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: AdvancedAnalyticsPage_viewer$ref,
  |}
|};
export type AdvancedAnalyticsPage_Query = {|
  variables: AdvancedAnalyticsPage_QueryVariables,
  response: AdvancedAnalyticsPage_QueryResponse,
|};
*/


/*
query AdvancedAnalyticsPage_Query {
  viewer {
    actualPersonId
    component(name: "advanced-analytics")
    ...AdvancedAnalyticsPage_viewer
    id
  }
}

fragment AdvancedAnalyticsPage_viewer on Viewer {
  sisenseUserId
  email
  firstName
  lastName
  availableFeatureFlags {
    key
    id
  }
  company {
    sisenseViewerSeats
    sisenseDesignerSeats
    allPersons(first: 10000, onlyActive: false, excludeClientUsers: true) {
      edges {
        node {
          id
          fullName
          profilePictureId
          profilePictureDefaultId
          sisenseUserId
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "advanced-analytics"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"advanced-analytics\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sisenseUserId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdvancedAnalyticsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdvancedAnalyticsPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdvancedAnalyticsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sisenseViewerSeats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sisenseDesignerSeats",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "excludeClientUsers",
                    "value": true
                  },
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10000
                  },
                  {
                    "kind": "Literal",
                    "name": "onlyActive",
                    "value": false
                  }
                ],
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "allPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureDefaultId",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "allPersons(excludeClientUsers:true,first:10000,onlyActive:false)"
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "AdvancedAnalyticsPage_Query",
    "operationKind": "query",
    "text": "query AdvancedAnalyticsPage_Query {\n  viewer {\n    actualPersonId\n    component(name: \"advanced-analytics\")\n    ...AdvancedAnalyticsPage_viewer\n    id\n  }\n}\n\nfragment AdvancedAnalyticsPage_viewer on Viewer {\n  sisenseUserId\n  email\n  firstName\n  lastName\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    sisenseViewerSeats\n    sisenseDesignerSeats\n    allPersons(first: 10000, onlyActive: false, excludeClientUsers: true) {\n      edges {\n        node {\n          id\n          fullName\n          profilePictureId\n          profilePictureDefaultId\n          sisenseUserId\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eab3cffb55177b15304ac5c2212a259b';

module.exports = node;
