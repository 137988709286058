import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown, IconText, ProjectIndicator} from 'web-components';

export const AllPhasesDropdown = ({
	projects,
	phases,
	dropdownAlignment,
	width,
	name,
	optionsName,
	selectedItems,
	onSelect,
	onRemove,
	selectedGroupName,
	userpilot,
	emphasizeEmptyState,
	headerLines,
}) => {
	const projectByIdMap = useMemo(() => {
		return projects.reduce((accMap, project) => accMap.set(project.node.id, project.node), new Map());
	}, [projects]);

	const getProjectPhaseElements = () => {
		return phases.map(edge => {
			const phase = edge.node;
			const project = projectByIdMap.get(phase.projectId);
			return (
				<IconText
					cy={`option-${project.name}-${phase.name}`}
					key={phase.id}
					value={phase.id}
					text={phase.name}
					title={project.name}
					searchString={`P${project.companyProjectId} ${phase.name}`}
				>
					<ProjectIndicator
						name={project.name}
						id={project.companyProjectId}
						disableLink
						color={project.projectColor}
					/>
				</IconText>
			);
		});
	};

	const elements = useMemo(getProjectPhaseElements, [phases, projectByIdMap]);

	return (
		<Dropdown
			isNested
			isMultiSelect
			headerLines={headerLines}
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
		>
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{elements}
			</Dropdown.Group>
		</Dropdown>
	);
};

AllPhasesDropdown.propTypes = {
	projects: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				name: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
				projectColor: PropTypes.string.isRequired,
				companyProjectId: PropTypes.number.isRequired,
				status: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	phases: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				name: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	headerLines: PropTypes.element,
};

AllPhasesDropdown.defaultProps = {
	optionsName: 'Phases',
	name: 'Phases',
	selectedGroupName: 'Selected',
	onSelect: () => false,
	onRemove: () => false,
};

export default createFragmentContainer(AllPhasesDropdown, {
	projects: graphql`
		fragment AllPhasesDropdown_projects on ProjectTypeEdge @relay(plural: true) {
			node {
				id
				name
				projectColor
				companyProjectId
				status
			}
		}
	`,
	phases: graphql`
		fragment AllPhasesDropdown_phases on PhaseTypeEdge @relay(plural: true) {
			node {
				id
				name
				projectId
			}
		}
	`,
});
