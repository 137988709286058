/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Chart_project$ref = any;
type HighLevelBudgetNumbers_project$ref = any;
type RevenueRecognition_project$ref = any;
type Totals_project$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetData_project$ref: FragmentReference;
declare export opaque type BudgetData_project$fragmentType: BudgetData_project$ref;
export type BudgetData_project = {|
  +id: string,
  +billable: ?boolean,
  +budgetType: ?BUDGET_TYPE,
  +$fragmentRefs: Chart_project$ref & HighLevelBudgetNumbers_project$ref & Totals_project$ref & RevenueRecognition_project$ref,
  +$refType: BudgetData_project$ref,
|};
export type BudgetData_project$data = BudgetData_project;
export type BudgetData_project$key = {
  +$data?: BudgetData_project$data,
  +$fragmentRefs: BudgetData_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentMonthLastDay",
      "type": "Int"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BudgetData_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Chart_project"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "currentMonth",
          "variableName": "currentMonth"
        },
        {
          "kind": "Variable",
          "name": "currentMonthLastDay",
          "variableName": "currentMonthLastDay"
        },
        {
          "kind": "Variable",
          "name": "currentYear",
          "variableName": "currentYear"
        }
      ],
      "kind": "FragmentSpread",
      "name": "HighLevelBudgetNumbers_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Totals_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RevenueRecognition_project"
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = 'ad7dff358bf12e84617c0360eebce8b6';

module.exports = node;
