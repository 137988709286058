import {profileLocales, USER_LOCALE_STORAGE_KEY} from '../../../constants';
import day_data_type_json from './auto_generated_day_data_type.json';
import Util from '../../../forecast-app/shared/util/util';

const avro = require('avsc');

const getDataType = value => {
	const dataType = typeof value;

	if (dataType === 'number') {
		return 'int';
	}

	return dataType;
};

export const getDayDataTypeFields = dayDataObject => {
	const dayDataTypeFields = [];

	for (let [key, value] of Object.entries(dayDataObject)) {
		dayDataTypeFields.push({name: key, type: getDataType(value)});
	}

	return {
		type: 'array',
		name: 'DayData',
		items: {type: 'record', fields: dayDataTypeFields},
	};
};

export const getStaticSchedulingPath = fileName => {
	return `./src/components/canvas-scheduling/static/${fileName}`;
};

export const getValidLocale = locale => {
	for (let profileLocale of Object.values(profileLocales)) {
		const {formatting_locale, moment_locale} = profileLocale;

		if (locale === formatting_locale) {
			return moment_locale;
		}
	}

	return profileLocales.ENGLISH_US.moment_locale;
};

let dayDataSchema;
let staticDayData;

export const readAvroDayData = arrayBuffer => {
	if (!dayDataSchema) {
		dayDataSchema = avro.Type.forSchema(day_data_type_json);
	}

	staticDayData = dayDataSchema.fromBuffer(new Buffer(arrayBuffer));
	return staticDayData;
};

export const readStaticDayData = locale => {
	let dayDataPromise = Promise.resolve(staticDayData);

	if (!staticDayData) {
		dayDataPromise = import(`./auto_generated_day_data_${getValidLocale(locale)}.avro`)
			.then(data => {
				const fileName = data.default;

				return fetch(fileName)
					.then(response => {
						if (!response.ok) {
							throw new Error(`HTTP error, status = ${response.status}`);
						}

						return response.arrayBuffer();
					})
					.then(arrayBuffer => {
						return readAvroDayData(arrayBuffer);
					})
					.catch(error => {
						console.log(`Error occurred when trying to read static day data file for '${locale}': `, error);
						return null;
					});
			})
			.catch(error => {
				console.log(`Error occurred when trying to import static day data file for '${locale}': `, error);
				return null;
			});
	}

	return dayDataPromise;
};

export const getStaticDayData = () => {
	return staticDayData;
};

export const initializeStaticDayData = handleResult => {
	readStaticDayData(Util.localStorageGetItem(USER_LOCALE_STORAGE_KEY))
		.then(result => {
			handleResult(result);
		})
		.catch(error => {
			console.log('Error occurred reading static day data: ', error);
		});
};
