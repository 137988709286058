import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useMemo, useRef } from 'react';
import { Accordion, EmptyAccordion, FlexColumn, FlexRow, Detail, Heading, Link, Modal, SubHeading, Text, theme, Tooltip, IconTooltip, } from '@forecast-it/design-system';
import { getCombinedTaskMode, getDateFromCanvasTimelineDate, HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE, VISUALIZATION_MODE, } from '../canvas-timeline/canvas_timeline_util';
import { getMinutesAllocatedVariation } from '../heatmap/MinutesAllocatedVariationsUtils';
import DataManager from '../DataManager';
import Util from '../../../forecast-app/shared/util/util';
import { hasPermission } from '../../../forecast-app/shared/util/PermissionsUtil';
import { INITIALS_SIZE } from '../../../constants';
import { NO_ACCESS_DISTRIBUTION_TYPE } from '../constants';
import PersonInitials from '../../../forecast-app/shared/components/person/person_initials';
import styled from 'styled-components';
import { getProjectGroupLink, getProjectLinkAppendLastViewed } from '../../../forecast-app/shared/util/LinkUtil';
import { PERMISSION_TYPE } from '../../../Permissions';
import { ContentWarning } from './UtilizationModalWarnings';
import { trackEvent } from '../../../tracking/amplitude/TrackingV2';
import { UtilizationModalV2Table } from './UtilizationModalV2Table';
import { hasFeatureFlag } from '../../../forecast-app/shared/util/FeatureUtil';
import { profilePicSrc } from '../../../directApi';
const UtilizationSubHeader = styled.div `
	font: ${theme.fonts.heading[3]};
	font-weight: 400;
`;
const gridColumns = ({ columns }) => {
    if (columns === 3) {
        return 'auto 21% 15%';
    }
    else if (columns === 4) {
        return 'auto 21% 25% 20%';
    }
    else {
        return 'auto 18% 15% 26% 9%';
    }
};
const GridRow = styled.div `
	display: grid;
	grid-template-columns: ${gridColumns};
	column-gap: ${theme.spacing[400]};
	align-items: center;
	width: 100%;
	border-bottom: solid 1px ${theme.colors.border.separator};
	padding: ${({ narrow }) => theme.spacing[narrow ? 400 : 600]} ${theme.spacing[200]}
		${({ narrow }) => theme.spacing[narrow ? 400 : 600]} ${theme.spacing[200]};

	&:first-child {
		padding-top: ${theme.spacing[200]};
	}

	&:last-child {
		padding-bottom: ${({ narrow }) => theme.spacing[narrow ? 0 : 200]};
		border-bottom: none;
	}
`;
const ActualHoursWrapper = styled.div `
	display: flex;
	flex-direction: column;
	align-items: end;
`;
function Avatar({ person }) {
    return (React.createElement(React.Fragment, null, person.profilePictureId ? (React.createElement("img", { crossOrigin: "use-credentials", height: "32", src: profilePicSrc(person.profilePictureId), alt: "" })) : (React.createElement(PersonInitials, { size: INITIALS_SIZE.LARGE, initials: person.initials ? person.initials : Util.getInitials(`${person.name}`) }))));
}
const CellRightAligned = ({ children }) => (React.createElement(FlexRow, { alignItems: 'center', justifyContent: 'end', gap: 's' }, children));
function isAboveThreshold(capacity, demand) {
    const isOverallocated = demand > capacity;
    return isOverallocated && (!capacity || capacity / demand - 1 < HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE / 100);
}
function UtilizationModalV2({ pageComponent, person, company, startDate: canvasTimelineStartDate, endDate: canvasTimelineEndDate, stepLabel, distributionMap, actualPersonId, closeModal, workingMinutes, minutesAvailable, schedulingOptions, minutesAllocatedVariations, }) {
    const scrollRef = useRef(null);
    const hideSoft = schedulingOptions ? schedulingOptions.hideSoft : false;
    const hideHard = schedulingOptions ? schedulingOptions.hideHard : false;
    const calcWin = schedulingOptions ? schedulingOptions.calcWin : false;
    const minutesAllocated = getMinutesAllocatedVariation(minutesAllocatedVariations, hideSoft, hideHard, calcWin);
    useEffect(() => {
        trackEvent('Utilization Modal', 'Opened');
    }, []);
    const intl = useIntl();
    const { formatMessage, formatDate } = intl;
    const isInActualMode = getCombinedTaskMode(company) === VISUALIZATION_MODE.TASK_ACTUAL;
    const dateFormatted = useMemo(() => {
        const startDate = getDateFromCanvasTimelineDate(canvasTimelineStartDate);
        const endDate = getDateFromCanvasTimelineDate(canvasTimelineEndDate);
        const isSameDay = canvasTimelineStartDate === canvasTimelineEndDate;
        let dateFormatted = formatDate(startDate);
        if (!isSameDay) {
            dateFormatted += ' - ' + formatDate(endDate);
        }
        return dateFormatted;
    }, [canvasTimelineStartDate, canvasTimelineEndDate]);
    const taskEstimatesLabel = formatMessage({ id: 'scheduling.utilization.task_estimates' });
    const allocationsLabel = formatMessage({ id: 'scheduling.utilization.allocations' });
    const hasAccessToProject = project => {
        return (hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) ||
            (project &&
                DataManager.getProjectPersonsByProjectId(pageComponent, project.id).find(projectPerson => projectPerson.personId === actualPersonId)));
    };
    const remaining = minutesAvailable - minutesAllocated;
    const showWarning = isAboveThreshold(minutesAvailable, minutesAllocated);
    const { timeOff, entries, hasRemaining, hasActuals } = useMemo(() => {
        let timeOff = 0;
        let hasRemaining = false;
        let hasActuals = false;
        const entries = [];
        for (const [id, value] of distributionMap.entries()) {
            const idType = id ? Util.getTypeFromGlobalID(id) : '';
            let name;
            let link;
            let companyProjectId;
            let customProjectId;
            let projectColor;
            let status;
            let allocationMinutes = 0;
            const allocationMinutesHard = value.allocationMinutes - value.minutesSoft;
            let plannedAllocationMinutes = 0;
            if (!hideSoft && hideHard) {
                allocationMinutes = calcWin ? value.minutesSoftWin : value.minutesSoft;
                plannedAllocationMinutes = calcWin ? value.plannedAllocationMinutesSoftWin : value.plannedAllocationMinutesSoft;
            }
            else if (hideSoft && !hideHard) {
                allocationMinutes = allocationMinutesHard;
                plannedAllocationMinutes = value.plannedAllocationMinutesHard;
            }
            else if (!hideSoft && !hideHard) {
                allocationMinutes = allocationMinutesHard + (calcWin ? value.minutesSoftWin : value.minutesSoft);
                plannedAllocationMinutes = calcWin ? value.plannedAllocationMinutesWin : value.plannedAllocationMinutes;
            }
            const actual = value.timeRegMinutes;
            const taskMinutes = isInActualMode ? value.actualTaskMinutes : value.plannedTaskMinutes;
            let useTaskMinutesForRemaining, remaining, total;
            if (hasFeatureFlag('combined_heatmap_logic_extensions')) {
                useTaskMinutesForRemaining = taskMinutes > 0 && taskMinutes > allocationMinutes;
                remaining = useTaskMinutesForRemaining ? taskMinutes : allocationMinutes;
                total = remaining + actual;
            }
            else {
                useTaskMinutesForRemaining = taskMinutes > 0 && taskMinutes + actual > allocationMinutes;
                remaining = useTaskMinutesForRemaining ? taskMinutes : allocationMinutes - actual;
                total = useTaskMinutesForRemaining ? actual + remaining : Math.max(actual, allocationMinutes);
            }
            let source = useTaskMinutesForRemaining ? taskEstimatesLabel : allocationsLabel;
            if (remaining > 0) {
                hasRemaining = true;
            }
            if (actual) {
                hasActuals = true;
            }
            let planned;
            let over;
            if (remaining <= 0) {
                const useTaskMinutesForPlanned = value.plannedTaskMinutes > plannedAllocationMinutes;
                planned = useTaskMinutesForPlanned ? value.plannedTaskMinutes : plannedAllocationMinutes;
                source = useTaskMinutesForPlanned ? taskEstimatesLabel : allocationsLabel;
                over = actual - planned;
            }
            if (!remaining && !actual && !planned) {
                continue;
            }
            const isHardAllocation = value.plannedAllocationMinutesHard || allocationMinutesHard;
            const isSoftAllocation = value.plannedAllocationMinutesSoft ||
                value.plannedAllocationMinutesSoftWin ||
                value.minutesSoft ||
                value.minutesSoftWin;
            if (!useTaskMinutesForRemaining) {
                if (isHardAllocation && isSoftAllocation) {
                    source += ' (Hard & Soft)';
                }
                else if (isHardAllocation) {
                    source += ' (Hard)';
                }
                else if (isSoftAllocation) {
                    source += ' (Soft)';
                }
            }
            const warning = isAboveThreshold(planned, actual);
            if (idType === 'ProjectGroupType') {
                const projectGroup = DataManager.getProjectGroupById(pageComponent, id);
                const firstProject = DataManager.getProjectsByProjectGroupId(pageComponent, projectGroup.id)[0];
                name = projectGroup.name;
                companyProjectId = projectGroup.companyProjectGroupId;
                projectColor = projectGroup.color;
                link = hasAccessToProject(firstProject) ? getProjectGroupLink(projectGroup.companyProjectGroupId) : undefined;
            }
            else if (idType === 'ProjectType') {
                const project = DataManager.getProjectById(pageComponent, id);
                name = project.name;
                companyProjectId = project.companyProjectId;
                customProjectId = project.customProjectId;
                projectColor = project.projectColor;
                status = project.status;
                link = hasAccessToProject(project)
                    ? getProjectLinkAppendLastViewed(project.companyProjectId, project.customProjectId)
                    : undefined;
            }
            else if (idType === 'IdleTime') {
                const idleTime = DataManager.getIdleTimeById(pageComponent, id);
                name = idleTime.name;
                source = allocationsLabel;
                const isTimeOff = !idleTime.isInternalTime;
                if (isTimeOff) {
                    timeOff += value.minutes;
                    continue;
                }
            }
            else if (idType === NO_ACCESS_DISTRIBUTION_TYPE) {
                name = formatMessage({ id: 'scheduling.no_access' });
            }
            else {
                // Unknown entity
                continue;
            }
            entries.push({
                id,
                name,
                link,
                planned,
                actual,
                remaining,
                source,
                total,
                over,
                warning,
                companyProjectId,
                customProjectId,
                projectColor,
                status,
            });
        }
        return {
            timeOff,
            entries,
            hasRemaining,
            hasActuals,
        };
    }, [distributionMap, workingMinutes, minutesAllocated, minutesAvailable]);
    const actualsAndRemainingPeriod = hasActuals && hasRemaining;
    const actualsPeriod = !hasRemaining;
    const remainingPeriod = !hasActuals && hasRemaining;
    if (entries.length === 0) {
        entries.push({
            companyProjectId: undefined,
            customProjectId: undefined,
            projectColor: undefined,
            status: undefined,
            name: 'N/A',
            source: 'N/A',
            planned: 0,
            actual: 0,
            over: 0,
        });
    }
    const trackExpand = value => {
        trackEvent('Utilization Modal - ' + value, 'Expanded');
    };
    const trackCollapse = value => {
        trackEvent('Utilization Modal - ' + value, 'Collapsed');
    };
    const getEntrySource = entry => {
        if (actualsPeriod && entry.planned <= 0) {
            return '-';
        }
        if (actualsAndRemainingPeriod && entry.remaining <= 0) {
            return '-';
        }
        return entry.source;
    };
    const getCorrectModalSize = () => {
        return actualsAndRemainingPeriod || actualsPeriod ? Modal.SIZE.EXTRA_EXTRA_LARGE : Modal.SIZE.LARGE;
    };
    const demandLabel = formatMessage({ id: 'scheduling.utilization.demand' });
    const timeEntriesLabel = formatMessage({ id: 'scheduling.utilization.time_entries' });
    const entriesLabel = actualsPeriod ? timeEntriesLabel : demandLabel;
    const entriesValue = actualsPeriod ? 'Time Entries' : 'Demand';
    const columns = remainingPeriod ? 3 : actualsPeriod ? 4 : 5;
    return (React.createElement(Modal, { size: !hasFeatureFlag('combined_heatmap_logic_extensions') ? Modal.SIZE.LARGE : getCorrectModalSize(), onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FlexRow, { gap: 'm' },
                React.createElement(Avatar, { person: person }),
                React.createElement(Heading, { size: 3 }, person.name),
                React.createElement(UtilizationSubHeader, null,
                    React.createElement(FormattedMessage, { id: 'scheduling.utilization' })))),
        React.createElement(Modal.Content, { ref: scrollRef },
            React.createElement(FlexRow, { gap: 'xl' },
                React.createElement(Text, { color: 'medium' },
                    React.createElement(FormattedMessage, { id: 'scheduling.utilization_date_range' })),
                React.createElement(SubHeading, null,
                    stepLabel,
                    " (",
                    dateFormatted,
                    ")")),
            React.createElement(FlexColumn, { gap: 'xs' },
                React.createElement(Accordion, { selectionType: Accordion.SelectionType.SINGLE, collapsible: true, background: true, border: true, disabled: false, defaultValue: entriesValue, onOpen: trackExpand, onClose: trackCollapse },
                    React.createElement(Accordion.Item, { alignment: Accordion.Alignment.LEFT, content: Util.convertMinutesToFullHour(minutesAvailable, intl, true), contentAlignment: Accordion.Alignment.RIGHT, label: formatMessage({ id: 'common.capacity' }), value: 'Capacity' },
                        React.createElement(React.Fragment, null,
                            React.createElement(GridRow, { columns: 3 },
                                React.createElement(SubHeading, null,
                                    React.createElement(FormattedMessage, { id: 'common.working_hours' })),
                                React.createElement(CellRightAligned, null,
                                    React.createElement(SubHeading, null,
                                        React.createElement(FormattedMessage, { id: 'common.time_off' }))),
                                React.createElement(CellRightAligned, null,
                                    React.createElement(SubHeading, null,
                                        React.createElement(FormattedMessage, { id: 'common.total' })))),
                            React.createElement(GridRow, { columns: 3 },
                                React.createElement(Text, null, Util.convertMinutesToFullHour(workingMinutes, intl, true)),
                                React.createElement(CellRightAligned, null,
                                    React.createElement(Text, null, Util.convertMinutesToFullHour(timeOff, intl, true))),
                                React.createElement(CellRightAligned, null,
                                    React.createElement(Text, null, Util.convertMinutesToFullHour(minutesAvailable, intl, true)))))),
                    React.createElement(Accordion.Item, { alignment: Accordion.Alignment.LEFT, content: React.createElement(FlexRow, { gap: 'xs' },
                            React.createElement(ContentWarning, { showWarning: showWarning, hasRemaining: hasRemaining }, Util.convertMinutesToFullHour(minutesAllocated, intl, true))), contentAlignment: Accordion.Alignment.RIGHT, label: entriesLabel, value: entriesValue }, !hasFeatureFlag('combined_heatmap_logic_extensions') ? (React.createElement(React.Fragment, null,
                        React.createElement(GridRow, { columns: columns },
                            React.createElement(SubHeading, null,
                                React.createElement(FormattedMessage, { id: 'common.name' })),
                            actualsPeriod && (React.createElement(React.Fragment, null,
                                React.createElement(CellRightAligned, null,
                                    React.createElement(SubHeading, null,
                                        React.createElement(FormattedMessage, { id: 'scheduling.utilization.planned_hours' }))),
                                React.createElement(SubHeading, null,
                                    React.createElement(FormattedMessage, { id: "scheduling.utilization.planned_source" })))),
                            actualsAndRemainingPeriod && (React.createElement(React.Fragment, null,
                                React.createElement(CellRightAligned, null,
                                    React.createElement(SubHeading, null,
                                        React.createElement(FormattedMessage, { id: 'scheduling.utilization.time_entries' }))),
                                React.createElement(CellRightAligned, null,
                                    React.createElement(SubHeading, null,
                                        React.createElement(FormattedMessage, { id: 'scheduling.utilization.remaining' }))),
                                React.createElement(SubHeading, null,
                                    React.createElement(FormattedMessage, { id: "scheduling.utilization.remaining_source" })))),
                            remainingPeriod && (React.createElement(SubHeading, null,
                                React.createElement(FormattedMessage, { id: "scheduling.utilization.source" }))),
                            React.createElement(CellRightAligned, null,
                                React.createElement(SubHeading, null,
                                    React.createElement(FormattedMessage, { id: actualsPeriod ? 'scheduling.utilization.actual_hours' : 'common.total' })))),
                        entries.map(entry => (React.createElement(GridRow, { key: entry.id, columns: columns },
                            React.createElement(Tooltip, { content: entry.name }, entry.link ? (React.createElement(Link, { ellipsis: true, href: entry.link }, entry.name)) : (React.createElement(Text, { ellipsis: true }, entry.name))),
                            actualsPeriod && (React.createElement(CellRightAligned, null,
                                React.createElement(Text, null, Util.convertMinutesToFullHour(entry.planned > 0 ? entry.planned : 0, intl, true)))),
                            actualsAndRemainingPeriod && (React.createElement(React.Fragment, null,
                                React.createElement(CellRightAligned, null,
                                    React.createElement(Text, null, Util.convertMinutesToFullHour(entry.actual, intl, true))),
                                React.createElement(CellRightAligned, null,
                                    React.createElement(Text, null, Util.convertMinutesToFullHour((entry === null || entry === void 0 ? void 0 : entry.remaining) && (entry === null || entry === void 0 ? void 0 : entry.remaining) > 0 ? entry.remaining : 0, intl, true))))),
                            React.createElement(Text, null, getEntrySource(entry)),
                            React.createElement(CellRightAligned, null, actualsPeriod ? (React.createElement(ActualHoursWrapper, null,
                                React.createElement(Text, { color: entry.warning ? 'error' : undefined }, Util.convertMinutesToFullHour(entry.actual, intl, true)),
                                React.createElement(Detail, { color: entry.warning ? 'error' : undefined },
                                    "(",
                                    Util.convertMinutesToFullHour(Math.abs(entry.over), intl, true),
                                    ' ',
                                    entry.over > 0 ? 'over' : 'under',
                                    ")"))) : (React.createElement(Text, null, Util.convertMinutesToFullHour(entry.total, intl, true))))))))) : (React.createElement(UtilizationModalV2Table, { actualsAndRemainingPeriod: actualsAndRemainingPeriod, remainingPeriod: remainingPeriod, actualsPeriod: actualsPeriod, entries: entries, closeModal: closeModal, scrollRef: scrollRef })))),
                React.createElement(EmptyAccordion, { label: formatMessage({ id: 'scheduling.utilization.remaining_capacity' }), contentAlignment: Accordion.Alignment.RIGHT, tooltip: React.createElement(IconTooltip.Item, { title: formatMessage({ id: 'scheduling.utilization.remaining_capacity' }), details: formatMessage({ id: 'utilization.table.remaining_capacity.tooltip' }) }), content: React.createElement(FlexRow, { gap: 'xs', justifyContent: 'end' },
                        React.createElement(ContentWarning, { showWarning: showWarning, hasRemaining: hasRemaining }, Util.convertMinutesToFullHour(remaining, intl, true))) }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.close' })))));
}
export default UtilizationModalV2;
