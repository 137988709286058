import React from 'react';
import styled from 'styled-components';
import InformationIcon from '../../../../images/information-icon';
import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';

const TitleWithTooltipStyles = styled.div`
	display: flex;
	flex-direction: row;
	height: 37px;
	align-items: center;
	justify-content: center;

	.title {
		font-size: 12px;
		font-weight: 500;
		white-space: nowrap;
	}

	.tooltip {
		margin-left: 8px;
	}
`;

export const TitleWithTooltip = ({title, tooltip}) => {
	return (
		<TitleWithTooltipStyles>
			<div className="title">{title ? title : <>&nbsp;</>}</div>
			{tooltip ? (
				<div className="tooltip">
					<TooltipContainer infoText={tooltip} tooltipDuration={5 * 60 * 1000} translatedMessage={true} showOnClick>
						<InformationIcon />
					</TooltipContainer>
				</div>
			) : null}
		</TitleWithTooltipStyles>
	);
};
