import {
	GROUP_TYPE,
	CURSOR,
	getTextColor,
	GROUP_SECTION_WIDTH,
	getTrimmedText,
	GROUP_SECTION_PADDING_LEFT,
	GROUP_SECTION_SPACING_LEVEL_THREE,
	drawBorder,
	GROUP_SECTION_PROJECT_BORDER_WIDTH,
	GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	isProjectDoneOrHalted,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {drawExpandedGroupHighlight} from '../../DrawingUtils';
import EventManager from '../../EventManager';

class PhaseGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PHASE, data);
	}

	refreshData(updatedPhase) {
		this.data.name = updatedPhase.name;
	}

	onItemCreate(startDate, endDate) {
		const {sharedContext} = this.pageComponent.props;
		const {project} = this.data;

		if (!sharedContext && !isProjectDoneOrHalted(project.status)) {
			EventManager.onItemCreate(this.pageComponent, this, startDate, endDate);
		}
	}

	onMouseEnter(groupData) {
		const {phase} = this.data;

		if (phase) {
			const {height, width, x, y} = groupData;

			const targetOffsetY = height / 2 - GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE / 2;
			const targetOffsetX = width - GROUP_SECTION_PADDING_LEFT - GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE;

			this.pageComponent.setState({
				showTargetPhaseButton: true,
				targetButtonY: y + targetOffsetY,
				targetButtonX: x + targetOffsetX,
				targetPhase: phase,
			});
		}
	}

	onMouseLeave() {
		const {phase} = this.data;

		if (phase) {
			this.pageComponent.setState({showTargetPhaseButton: false, targetPhase: null});
		}
	}

	draw(canvasContext, x, y) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height, expanded, groupType} = this;
		super.draw(x, y, width, height, this);
		const {isProjectTimeline} = this.pageComponent.props;
		const {name, id, faded, color, isInProjectGroup, isInProgram} = data;
		const cacheId = `${groupType}-${id}-${!!expanded}`;
		const borderThickness = 1;
		const groupingGroupOffsetX = isInProjectGroup || isInProgram ? GROUP_SECTION_PADDING_LEFT : 0;

		interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);

		const cachedData = cacheManager.get(groupType, cacheId);
		if (cachedData) {
			canvasContext.drawImage(cachedData, x, y, width, this.itemRowHeight);
			return;
		}

		const drawGroup = () => {
			if (!isProjectTimeline) {
				drawExpandedGroupHighlight(canvasContext, x, y, height, width);
			}

			// right project border
			drawBorder(
				canvasContext,
				x + width - GROUP_SECTION_PROJECT_BORDER_WIDTH,
				y - 2 - borderThickness,
				height + borderThickness * 2 + 1,
				true,
				color,
				GROUP_SECTION_PROJECT_BORDER_WIDTH
			);

			const textColor = getTextColor();

			canvasContext.drawImage(
				cacheManager.getCommonImage(expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON),
				x + GROUP_SECTION_SPACING_LEVEL_THREE + groupingGroupOffsetX - GROUP_SECTION_EXPAND_ICON_WIDTH / 2,
				y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
				GROUP_SECTION_EXPAND_ICON_WIDTH,
				GROUP_SECTION_EXPAND_ICON_HEIGHT
			);

			const fontSize = 13;
			canvasContext.fillStyle = `${textColor}${faded ? 'a1' : ''}`;
			canvasContext.font = `600 ${fontSize}px ` + Util.getFontFamily();
			const maxNameLength =
				GROUP_SECTION_WIDTH -
				(groupingGroupOffsetX +
					GROUP_SECTION_SPACING_LEVEL_THREE +
					GROUP_SECTION_PADDING_LEFT * 3 +
					GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE);

			canvasContext.fillText(
				getTrimmedText(canvasContext, name, maxNameLength),
				x + groupingGroupOffsetX + GROUP_SECTION_SPACING_LEVEL_THREE + GROUP_SECTION_PADDING_LEFT,
				y + height / 2 + fontSize / 2 - borderThickness
			);
		};

		drawGroup();
	}
}

export default PhaseGroup;
