import Util from '../../forecast-app/shared/util/util';
/**
 * Check if input value is a valid number for a percentage value
 * Aka. a number > 0 and <= 100
 * @param value Input value
 * @returns true or false
 */
export const isValidValue = (value, decimals) => {
	const numberVal = Number(value);
	if (!isNaN(numberVal)) {
		return typeof numberVal === 'number' && numberVal >= 0 && numberVal <= 100;
	} else {
		return false;
	}
};

/**
 * convert value into percentage format
 * @param value input value
 * @param intl
 * @param {Number} decimals
 * @returns Result
 */
export const convertToPercentageFormat = (value, intl, decimals = 0) => {
	if (typeof value === 'string') {
		value = value.replace('%', '');
		value = value.trim();
	}

	const res = {
		float: null,
		full: null,
		error: false,
	};

	const validInput = isValidValue(value);

	if (validInput) {
		const rounded = Math.round(value) / 100;
		const percent = Util.getFormattedPercentage(rounded, intl, decimals);
		res.float = rounded;
		res.full = percent;
	} else if (value === null || value === undefined || value === '') {
		res.full = '';
		res.error = true;
	} else {
		res.full = value;
		res.error = true;
	}

	return res;
};

// No change, do nothing
export const noChange = (a, b, intl) => {
	const aVal = convertToPercentageFormat(a, intl).percentage;
	const bVal = convertToPercentageFormat(b, intl).percentage;

	// We always want mutation to be fired if user only inputs 0 and nothing else
	return aVal === bVal;
};
