import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import {MONTH_NAMES_SHORT} from '../canvas-timeline/canvas_timeline_util';

class DistributionBox extends Component {
	constructor(props) {
		super(props);
		this.boxRef = React.createRef();
		this.state = {
			showPopupOnTheRight: true,
			positionHasBeenCalculated: false,
		};
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {positionX, positionY, distributionBoxData, getPhaseCompletion} = this.props;
		const {project, projectGroup, phase, allocationData, clients} = distributionBoxData;
		const roleDataArray = [];
		const showCapacitySection = false;

		let roleDataMap = null;
		if (phase) {
			const phaseData = allocationData.phases.find(phaseData => phaseData.id === phase.id);
			if (phaseData) {
				roleDataMap = phaseData.roleDataMap;
			}
		} else if (projectGroup) {
			if (project) {
				const projectData = allocationData.projects.find(projectData => projectData.id === project.id);
				if (projectData) {
					roleDataMap = projectData.roleDataMap;
				}
			} else {
				roleDataMap = allocationData.projectGroup && allocationData.projectGroup.roleDataMap;
			}
		} else {
			roleDataMap = allocationData.project.roleDataMap;
		}

		if (!roleDataMap) return null; //Can happen when you are mousing over a project that has just been deleted etcs

		let remaining = 0;
		let minutesRegistered = 0;

		let taskCount = 0;
		let doneTaskCount = 0;

		for (const entry of roleDataMap.entries()) {
			// If there is no allocated, remaining and minutesRegistered, progress is
			// based on total tasks and done tasks
			taskCount += entry[1].taskCount;
			doneTaskCount += entry[1].doneTaskCount;

			if (!entry[1].allocated && !entry[1].remaining && !entry[1].minutesRegistered) continue;

			remaining += entry[1].remaining || 0;
			minutesRegistered += entry[1].minutesRegistered || 0;
			const capacity = entry[1].remaining ? entry[1].allocated - entry[1].remaining : 0;
			const capacityClassName = capacity < 0 ? 'negative' : 'positive';
			roleDataArray.push({
				name: entry[0]
					? this.props.data.roles.find(role => {
							return role.id === entry[0];
					  }).name
					: formatMessage({id: 'scheduling.project_scheduling.any_role'}),
				capacity,
				capacityClassName,
			});
		}

		const openTaskCount = taskCount - doneTaskCount;
		const minutesTotal = minutesRegistered + remaining;

		const hoursRegistered = Util.convertMinutesToFullHour(minutesRegistered, this.props.intl);
		const hoursTotal = Util.convertMinutesToFullHour(minutesTotal, this.props.intl);

		let completionDescription;
		if (minutesRegistered !== 0 && remaining === 0 && openTaskCount !== 0) {
			// If time is being registered and no time is remaining, and we still have open tasks
			// then display how many open tasks there are, in this case completion will be 99 %,
			// and we want to give information about why it si not 100 %
			completionDescription = `- ${openTaskCount} open tasks`;
		} else if (minutesRegistered === 0 && remaining === 0) {
			// If no time is registered and no time is on tasks, just show
			// how many tasks are done
			completionDescription = `- ${doneTaskCount} of ${taskCount}`;
		} else {
			// Default is just to show the hours used for the progress calculation
			completionDescription = `- ${hoursRegistered} of ${hoursTotal}`;
		}

		let completion = 0;
		if (phase) {
			completion = phase.completion || getPhaseCompletion(phase.id);
		} else if (project) {
			completion = project.completion;
		} else if (projectGroup) {
			completion = Math.round(((projectGroup.forecast - remaining) / projectGroup.forecast) * 100);
			if (projectGroup.forecast === remaining) {
				completion = 0;
			}
		}

		const clientName = project
			? project.clientId
				? clients.find(client => client.id === project.clientId).name
				: null
			: null;
		const name = phase ? phase.name : projectGroup && !project ? projectGroup.name : project.name;
		const startDay = phase ? phase.startDay : project ? project.projectStartDay : null;
		const startMonth = phase ? phase.startMonth : project ? project.projectStartMonth : null;
		const startYear = phase ? phase.startYear : project ? project.projectStartYear : null;
		const endDay = phase ? phase.deadlineDay : project ? project.projectEndDay : null;
		const endMonth = phase ? phase.deadlineMonth : project ? project.projectEndMonth : null;
		const endYear = phase ? phase.deadlineYear : project ? project.projectEndYear : null;
		const showRight = positionX + 200 < window.innerWidth;
		const showTop = positionY + 350 < window.innerHeight;

		requestAnimationFrame(() => {
			if (this.boxRef && this.boxRef.current && !this.state.positionHasBeenCalculated) {
				this.setState({
					positionHasBeenCalculated: true,
					showPopupOnTheRight: this.boxRef.current.getBoundingClientRect().width + positionX < window.innerWidth,
					positionX: positionX - this.boxRef.current.getBoundingClientRect().width + 20,
				});
			}
		});
		const distributionBoxStyle = {
			left: showRight ? positionX + 10 : positionX - 235,
		};
		showTop
			? (distributionBoxStyle.top = positionY - 5)
			: (distributionBoxStyle.bottom = window.innerHeight - positionY - 40);

		return (
			<div ref={this.boxRef} className="distribution-box-container" style={distributionBoxStyle}>
				<div className={'white-triangle ' + (showRight ? 'right ' : 'left ') + (showTop ? 'top' : 'bottom')} />
				<div className="inner-container">
					<div className="inner-headline">
						{formatMessage({id: phase ? 'common.phase' : project || projectGroup ? 'common.project' : ''})}
					</div>
					{clientName ? (
						<div className={'inner-container-item'}>
							<div className={'label'}>{formatMessage({id: 'common.client'})}</div>
							<div className={'content'}>{clientName}</div>
						</div>
					) : null}
					<div className={'inner-container-item'}>
						<div className={'label'}>
							{formatMessage({
								id: phase ? 'scheduling.phase_name' : project || projectGroup ? 'common.project-name' : '',
							})}
						</div>
						<div className={'content'}>{name}</div>
					</div>
					{phase || project ? (
						<div className={'inner-container-item'}>
							<div className={'label'}>{formatMessage({id: 'common.date'})}</div>
							<div className={'content'}>{`${startDay} ${formatMessage({
								id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[startMonth - 1],
							})} ${startYear} - ${endDay} ${formatMessage({
								id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[endMonth - 1],
							})} ${endYear}`}</div>
						</div>
					) : null}
					{phase || project || projectGroup ? (
						<div className={'inner-container-item'}>
							<div className={'label'}>{formatMessage({id: 'common.completion'})}</div>
							<div className={'content'}>
								<span className={'progress-percentage'}>{`${completion?.toFixed(0) || 0} %`}</span>
								{project && !project.manualProgressEnabled && (
									<span>
										&ensp;
										{completionDescription}
									</span>
								)}
							</div>
						</div>
					) : null}
					{showCapacitySection && (phase || project || projectGroup) && roleDataArray.length > 0 ? (
						<div className={'inner-container-item'}>
							<div className={'label'}>{formatMessage({id: 'common.capacity'})}</div>
							<div className={'content capacity-content'}>
								<div className="capacity-content-container rectangle-container">
									{roleDataArray.map(roleData => (
										<div key={roleData.name} className={'rectangle ' + roleData.capacityClassName}></div>
									))}
								</div>
								<div className="capacity-content-container capacity-container">
									{roleDataArray.map(roleData => (
										<div key={roleData.name} className="capacity">
											{Util.convertMinutesToFullHour(roleData.capacity, this.props.intl)}
										</div>
									))}
								</div>
								<div className="capacity-content-container name-container">
									{roleDataArray.map(roleData => (
										<div key={roleData.name} className="name">
											{roleData.name}
										</div>
									))}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

DistributionBox.propTypes = {
	distributionBoxData: PropTypes.object.isRequired,
};

export default injectIntl(DistributionBox);
