import HeatmapItem, {HEATMAP_TYPE} from '../../components/items/Heatmap/HeatmapItem';
import {
	getVisualizationMode,
	GROUP_SECTION_TEXT_GREY_DARK,
	HEATMAP_ACTUAL_BACKGROUND_COLOR,
	HEATMAP_ACTUAL_TEXT_COLOR,
	HEATMAP_OVER_ALLOCATED_BACKGROUND_COLOR,
	HEATMAP_OVER_ALLOCATED_COMPLETION_COLOR,
	isDayOffStep,
	SCHEDULE_PEOPLE_PERSON_GROUP_ACTUAL_TIME,
	SCHEDULE_PEOPLE_PERSON_GROUP_COMPLETION_COLOR,
	TIMELINE_HEATMAP_BACKGROUND_COLOR,
	VISUALIZATION_MODE,
} from '../../canvas-timeline/canvas_timeline_util';
import {SCHEDULING_VIEW} from '../../../../constants';
import HeatmapItemConfig from '../../components/items/Heatmap/HeatmapItemConfig';

const onHeatmapItemMouseEnter = (pageComponent, item, event, data) => {
	const detailBoxData = {
		heatmapData: {
			...item,
			data,
		},
	};

	pageComponent.setState(
		{
			hoverX: event.clientX,
			hoverY: event.clientY,
			showDetailBox: false,
		},
		() => {
			setTimeout(() => {
				pageComponent.setState({
					detailBoxLeft: data.x,
					detailBoxY: data.y,
					detailBoxTop: data.y - 2,
					detailBoxData,
				});

				const {hoverX, hoverY} = pageComponent.state;

				if (hoverX === event.clientX && hoverY === event.clientY) {
					pageComponent.setState({showDetailBox: true, detailBoxX: event.clientX});
				}
			}, 1000);
		}
	);
};

const onHeatmapItemMouseLeave = pageComponent => {
	pageComponent.setState({
		showDetailBox: false,
		hoverX: null,
		hoverY: null,
		detailBoxData: null,
		detailBoxLeft: null,
		detailBoxY: null,
		detailBoxTop: null,
	});
};

export const constructPersonGroupHeatmapItem = (pageComponent, type, options) => {
	const {schedulingView} = pageComponent.props;
	const isProjectTimeline = schedulingView === SCHEDULING_VIEW.PROJECTS;
	const {data, eyeOptions, schedulingOptions, todayDate} = pageComponent.state;
	const {company} = data;
	const isUsingCombinedMode = getVisualizationMode(
		pageComponent.state.schedulingOptions,
		company,
		VISUALIZATION_MODE.COMBINATION
	);
	const isInActualMode =
		getVisualizationMode(pageComponent.state.schedulingOptions, company, VISUALIZATION_MODE.TASK_ACTUAL) ||
		(isUsingCombinedMode && !company.isUsingSchedulingPlanMode);

	const {
		group,
		stepData,
		onClick,
		minutesAllocated,
		minutesAvailable,
		plannedTotalMinutesHard,
		plannedTotalMinutesSoft,
		plannedTotalMinutesSoftWin,
		useDefaultEyeOptions,
		taskMinutesAllocated,
		minutesAllocatedVariations,
	} = options;
	const heatmapEyeOptions = eyeOptions || [];

	const {personId} = group.data;
	const isDayOffItem = type === 'freeday' || isDayOffStep(pageComponent, stepData, personId);

	const isPast = stepData.endDate < todayDate;
	const isActualPast = isPast && isInActualMode;

	const barColor = isActualPast
		? isProjectTimeline
			? HEATMAP_ACTUAL_BACKGROUND_COLOR
			: SCHEDULE_PEOPLE_PERSON_GROUP_ACTUAL_TIME
		: SCHEDULE_PEOPLE_PERSON_GROUP_COMPLETION_COLOR;

	const heatmapItemConfig = new HeatmapItemConfig(
		isProjectTimeline ? TIMELINE_HEATMAP_BACKGROUND_COLOR : '#ffffff',
		HEATMAP_OVER_ALLOCATED_BACKGROUND_COLOR,
		barColor,
		HEATMAP_OVER_ALLOCATED_COMPLETION_COLOR
	);

	heatmapItemConfig.setFontColor(isPast && isInActualMode ? HEATMAP_ACTUAL_TEXT_COLOR : GROUP_SECTION_TEXT_GREY_DARK);

	if (type === 'freeday') {
		return new HeatmapItem(
			pageComponent,
			HEATMAP_TYPE.UTILIZATION,
			{
				y: group.screenY,
				x: stepData.position,
				width: stepData.width,
				groupHeight: group.totalHeight,
				onClick,
				isDayOffItem,
			},
			heatmapItemConfig
		);
	} else if (type === 'timeoff') {
		return new HeatmapItem(
			pageComponent,
			HEATMAP_TYPE.UTILIZATION,
			{
				y: group.screenY,
				x: stepData.position,
				width: stepData.width,
				groupHeight: group.totalHeight,
				onClick,
				isTimeOffItem: true,
				isDayOffItem,
			},
			heatmapItemConfig
		);
	} else if (type === 'utilization') {
		const isPast = stepData.endDate < todayDate;

		const tooltipItemData = {
			minutesAllocated,
			minutesAvailable,
			plannedTotalMinutesHard,
			plannedTotalMinutesSoft,
			plannedTotalMinutesSoftWin,
			minutesAllocatedVariations,
			width: stepData.width,
			eyeOptions: heatmapEyeOptions,
			schedulingOptions,
			isPast,
			isInActualMode,
		};

		const itemData = {
			y: group.screenY,
			x: stepData.position,
			width: stepData.width,
			minutesAllocated,
			plannedTotalMinutesHard,
			plannedTotalMinutesSoft,
			plannedTotalMinutesSoftWin,
			taskMinutesAllocated,
			minutesAvailable,
			minutesAllocatedVariations,
			groupHeight: group.totalHeight,
			onClick,
			eyeOptions: heatmapEyeOptions,
			isDayOffItem,
			onMouseEnter: (event, item) => onHeatmapItemMouseEnter(pageComponent, tooltipItemData, item, event),
			onMouseLeave: () => onHeatmapItemMouseLeave(pageComponent),
			useDefaultEyeOptions,
		};

		return new HeatmapItem(pageComponent, HEATMAP_TYPE.UTILIZATION, itemData, heatmapItemConfig);
	}
};
