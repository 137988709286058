import React from 'react';
import { Table } from '@forecast-it/design-system';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import { TooltipFormulaRenderer } from '../../TooltipFormulaRenderer';
import { Tooltip } from '@forecast-it/design-system';
import { getProjectIndicatorString } from '../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';
export const UtilizationModalV2Table = ({ actualsAndRemainingPeriod, actualsPeriod, remainingPeriod, entries, closeModal, scrollRef, }) => {
    const history = useHistory();
    const intl = useIntl();
    const { formatMessage } = useIntl();
    const navigateToProject = link => {
        closeModal();
        history.push(link);
    };
    const formatActual = entry => {
        return actualsPeriod
            ? Util.convertMinutesToFullHour(entry.actual, intl, true) +
                ' (' +
                Util.convertMinutesToFullHour(Math.abs(entry.over), intl, true) +
                (entry.over > 0 ? ' over)' : ' under)')
            : entry.actual;
    };
    const formattedEntries = entries.map(entry => (Object.assign(Object.assign({}, entry), { status: entry.status ? Util.getProjectStageTranslation(entry.status, intl) : 'N/A', actual: formatActual(entry), remaining: (entry === null || entry === void 0 ? void 0 : entry.remaining) && (entry === null || entry === void 0 ? void 0 : entry.remaining) > 0 ? entry.remaining : 0, planned: (entry === null || entry === void 0 ? void 0 : entry.planned) && (entry === null || entry === void 0 ? void 0 : entry.planned) > 0 ? entry.planned : 0 })));
    return (React.createElement(Table, { data: formattedEntries, scrollRef: scrollRef, width: "100%" },
        React.createElement(Table.ColoredIdIconColumn, { accessorKey: 'companyProjectId', "data-cy": 'project-id', header: formatMessage({ id: 'common.id' }), renderProps: row => ({
                color: row.projectColor,
                text: getProjectIndicatorString(row.companyProjectId, row.customProjectId) || 'N/A',
            }) }),
        React.createElement(Table.TextColumn, { header: formatMessage({ id: 'common.name' }), accessorKey: 'name', onClick: row => (row.link ? () => navigateToProject(row.link) : undefined), ellipsis: true }),
        React.createElement(Table.TextColumn, { header: formatMessage({ id: 'common.stage' }), accessorKey: 'status' }),
        actualsPeriod ? (React.createElement(Table.TimeColumn, { header: formatMessage({ id: 'scheduling.utilization.planned_hours' }), accessorKey: 'planned', align: Table.Align.right, headerTooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'scheduling.utilization.planned_hours' }), details: formatMessage({ id: 'utilization.table.actuals_planned.planned_hours.tooltip' }) }) })) : undefined,
        actualsPeriod ? (React.createElement(Table.TextColumn, { header: formatMessage({ id: 'scheduling.utilization.planned_source' }), accessorKey: 'source', headerTooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'scheduling.utilization.planned_source' }), details: formatMessage({ id: 'utilization.table.actuals_planned.planned_source.tooltip' }) }) })) : undefined,
        actualsPeriod ? (React.createElement(Table.TextColumn, { header: formatMessage({ id: 'scheduling.utilization.actual_hours' }), accessorKey: 'actual', align: Table.Align.right, alert: row => row.warning, headerTooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'scheduling.utilization.actual_hours' }), details: formatMessage({ id: 'utilization.table.actuals_planned.actual_hours.tooltip' }) }) })) : undefined,
        actualsAndRemainingPeriod ? (React.createElement(Table.TimeColumn, { header: formatMessage({ id: 'scheduling.utilization.time_entries' }), accessorKey: 'actual', align: Table.Align.right, headerTooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'scheduling.utilization.time_entries' }), details: formatMessage({ id: 'utilization.table.actuals_remaining.time_entries.tooltip' }) }) })) : undefined,
        actualsAndRemainingPeriod ? (React.createElement(Table.TimeColumn, { header: formatMessage({ id: 'scheduling.utilization.remaining_time' }), accessorKey: 'remaining', align: Table.Align.right, headerTooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'scheduling.utilization.remaining_time' }), details: formatMessage({ id: 'utilization.table.actuals_remaining.remaining_time.tooltip' }) }) })) : undefined,
        actualsAndRemainingPeriod ? (React.createElement(Table.TextColumn, { header: formatMessage({ id: 'scheduling.utilization.remaining_source' }), accessorKey: 'source', headerTooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'scheduling.utilization.remaining_source' }), details: formatMessage({ id: 'utilization.table.actuals_remaining.remaining_source.tooltip' }) }) })) : undefined,
        actualsAndRemainingPeriod ? (React.createElement(Table.TimeColumn, { header: formatMessage({ id: 'common.total' }), accessorKey: 'total', align: Table.Align.right, headerTooltip: React.createElement(TooltipFormulaRenderer, { items: [
                    {
                        title: formatMessage({ id: 'common.total' }),
                        description: formatMessage({ id: 'utilization.table.actuals_remaining.total.tooltip' }),
                        details: [
                            formatMessage({ id: 'scheduling.utilization.time_entries' }),
                            '+',
                            formatMessage({ id: 'scheduling.utilization.remaining_time' }),
                        ],
                    },
                ] }) })) : undefined,
        remainingPeriod ? (React.createElement(Table.TextColumn, { header: formatMessage({ id: 'scheduling.utilization.source' }), accessorKey: 'source', headerTooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'scheduling.utilization.source' }), details: formatMessage({ id: 'utilization.table.remaining_only.source.tooltip' }) }) })) : undefined,
        remainingPeriod ? (React.createElement(Table.TimeColumn, { header: formatMessage({ id: 'common.total' }), accessorKey: 'total', align: Table.Align.right, headerTooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'common.total' }), details: formatMessage({ id: 'utilization.table.remaining_only.total.tooltip' }) }) })) : undefined));
};
