import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import ActionsMenu from '../../../forecast-app/shared/components/action-menu/actions_menu';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import * as tracking from '../../../tracking';
import TooltipContainer from '../../../forecast-app/shared/components/tooltips/tooltip_container';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

class RetainerPeriodWarningTab extends Component {
	constructor(props) {
		super(props);

		this.getActionsMenuOptions = this.getActionsMenuOptions.bind(this);
	}

	getActionsMenuOptions() {
		const {timeRegsByPeriodId, periodsWithConflict} = this.props;
		const options = [];

		if (periodsWithConflict) {
			periodsWithConflict.forEach(period => {
				options.push({
					text: period.node.name,
					onClick: () => {
						tracking.trackElementClicked('Time entry conflicts');
						trackEvent('Time Entry Conflicts Modal', 'Shown');
						showModal({
							type: MODAL_TYPE.RETAINER_TIME_ENTRY_CONFLICT_HANDLING,
							period: period.node,
							conflictedTimeEntries: timeRegsByPeriodId.get(period.node.id).conflictTimeRegs,
							inHours: this.props.inHours,
							currency: this.props.currency,
							viewer: this.props.viewer,
							useTimeApproval: this.props.useTimeApproval,
						});
					},
				});
			});
		}

		return options;
	}

	render() {
		const actionsMenuOptions = this.getActionsMenuOptions();
		const {formatMessage} = this.props.intl;
		if (!actionsMenuOptions.length) return null;

		return (
			<div className="tab warning">
				<TooltipContainer infoText={formatMessage({id: 'project_budget.click_here'})}>
					<ActionsMenu
						title={formatMessage({id: 'retainer.periods_conflicts_message'})}
						description={formatMessage({id: 'retainer.periods_conflicts_click_message'})}
						customWidth={20}
						whiteInner={true}
						isWhite={false}
						showOnRight={true}
						options={actionsMenuOptions}
						label={formatMessage({id: 'common.warning'})}
					/>
				</TooltipContainer>
			</div>
		);
	}
}

export default injectIntl(RetainerPeriodWarningTab);
