import {getTimestampCorrelationId, trackPerformanceDataLoad} from '../canvas-timeline/canvas_timeline_performance_track';
import DirectApi from '../../../directApi';
import {fetchDataPromises} from '../scheduling_fetch';
import {initializeData} from './CanvasCapacityOverviewDataUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {getFetchDates} from '../loading/LoadMoreUtil';

// DATA PROMISES
const getDataPromises = (pageComponent, fetchFull) => {
	const req = {method: 'POST'};
	let requestUrl = DirectApi.graphqlServerEndpoint('scheduling/capacity_overview');
	requestUrl += `?timestampCorrelationId=${getTimestampCorrelationId()}`;

	if (fetchFull) {
		requestUrl += '&loadAllData=true';
	}

	req.url = requestUrl;

	if (hasFeatureFlag('incremental_load_more_include_items')) {
		req.body = {
			...getFetchDates(pageComponent),
		};
	}

	return fetchDataPromises([req]);
};

// FETCH DATA
export function fetchData(pageComponent, fetchFull = false) {
	trackPerformanceDataLoad(getDataPromises(pageComponent, fetchFull)).then(dataArray => {
		const data = dataArray[0];
		initializeData(pageComponent, data, false);
	});
}
