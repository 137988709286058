import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Line} from 'react-chartjs-2';
import {defaultTimeDisplayFormats} from './constants';

class ChartJsLine extends Component {
	render() {
		const tooltipSuffix = this.props.tooltipSuffix ? this.props.tooltipSuffix : '';
		let options = {
			plugins: {
				title: {
					display: false,
				},
				tooltip: {
					mode: 'index',
					intersect: false,
					callbacks: {
						label: ctx => ctx.dataset.label + ': ' + +parseFloat(ctx.parsed.y).toFixed(2) + tooltipSuffix,
					},
				},
				datalabels: {
					display: false,
				},
			},
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				position: 'bottom',
			},
			scales: {
				y: {
					title: {
						display: this.props.yAxesTitle !== null && this.props.yAxesTitle !== undefined,
						text: this.props.yAxesTitle,
					},
					ticks: {
						min: 0,
					},
				},
			},
		};
		if (this.props.useTimeLineChart) {
			options.scales.x = {
				type: 'time',
				time: {
					displayFormats: defaultTimeDisplayFormats,
				},
				ticks: {
					autoSkip: true,
					maxTicksLimit: this.props.data.labels.length,
				},
			};
		}
		return (
			<div style={{height: this.props.height + 'px'}}>
				<Line data={this.props.data} options={options} />
			</div>
		);
	}
}
export default injectIntl(ChartJsLine);
