/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type reportedTimeComponent_viewer$ref: FragmentReference;
declare export opaque type reportedTimeComponent_viewer$fragmentType: reportedTimeComponent_viewer$ref;
export type reportedTimeComponent_viewer = {|
  +insightComponentsData: ?{|
    +reportedTimeList: ?{|
      +id: string,
      +currency: ?string,
      +timeRegistrations: ?$ReadOnlyArray<?{|
        +id: string,
        +personName: ?string,
        +departmentName: ?string,
        +projectName: ?string,
        +roleNameOrNonProjTime: ?string,
        +taskNameOrNonProjTime: ?string,
        +groupingElementName: ?string,
        +minutesRegistered: ?number,
        +dateYear: ?number,
        +dateMonth: ?number,
        +dateDay: ?number,
        +price: ?number,
        +cost: ?number,
        +billable: ?boolean,
        +notes: ?string,
        +clientName: ?string,
      |}>,
    |}
  |},
  +$refType: reportedTimeComponent_viewer$ref,
|};
export type reportedTimeComponent_viewer$data = reportedTimeComponent_viewer;
export type reportedTimeComponent_viewer$key = {
  +$data?: reportedTimeComponent_viewer$data,
  +$fragmentRefs: reportedTimeComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "clientId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProjectGroupType",
      "type": "Boolean"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateCriteria",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "groupBy",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "billableFilterValue",
      "type": "Boolean"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "departmentIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "roleIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "labelIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skillIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "componentId",
      "type": "ID"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "reportedTimeComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "billableFilterValue",
              "variableName": "billableFilterValue"
            },
            {
              "kind": "Variable",
              "name": "clientId",
              "variableName": "clientId"
            },
            {
              "kind": "Variable",
              "name": "componentId",
              "variableName": "componentId"
            },
            {
              "kind": "Variable",
              "name": "dateCriteria",
              "variableName": "dateCriteria"
            },
            {
              "kind": "Variable",
              "name": "departmentIds",
              "variableName": "departmentIds"
            },
            {
              "kind": "Variable",
              "name": "groupBy",
              "variableName": "groupBy"
            },
            {
              "kind": "Variable",
              "name": "isProjectGroupType",
              "variableName": "isProjectGroupType"
            },
            {
              "kind": "Variable",
              "name": "labelIds",
              "variableName": "labelIds"
            },
            {
              "kind": "Variable",
              "name": "personId",
              "variableName": "personId"
            },
            {
              "kind": "Variable",
              "name": "personIds",
              "variableName": "personIds"
            },
            {
              "kind": "Variable",
              "name": "projectId",
              "variableName": "projectId"
            },
            {
              "kind": "Variable",
              "name": "projectIds",
              "variableName": "projectIds"
            },
            {
              "kind": "Variable",
              "name": "roleIds",
              "variableName": "roleIds"
            },
            {
              "kind": "Variable",
              "name": "skillIds",
              "variableName": "skillIds"
            },
            {
              "kind": "Variable",
              "name": "teamIds",
              "variableName": "teamIds"
            }
          ],
          "concreteType": "ReportedTimeList",
          "kind": "LinkedField",
          "name": "reportedTimeList",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ReportedTimeListTimeRegType",
              "kind": "LinkedField",
              "name": "timeRegistrations",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "personName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "departmentName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "roleNameOrNonProjTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "taskNameOrNonProjTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "groupingElementName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "minutesRegistered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dateYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dateMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dateDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cost",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billable",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "notes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "clientName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ec14659079f22c72515bfcf31f4283e';

module.exports = node;
