import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Notification from './notification';
import Moment from 'moment';
import Util from '../../../forecast-app/shared/util/util';
import UpdatePersonNotificationMutation from './../../../mutations/update_person_notification_mutation';
import SettingsIcon from './settings_icon';
import Filter from '../filter';
import EmptyState from '../../../forecast-app/shared/components/empty-states/empty_state';
import {EMPTY_STATE} from '../../../constants';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

class NotificationContainer extends Component {
	navigateToSettings() {
		this.props.history.push('/my-profile/notifications');
		this.props.onBlur();
	}

	navigateToAll() {
		this.props.history.push('/my-profile/all-notifications');
		this.props.onBlur();
	}

	markAllAsRead() {
		tracking.trackElementClicked(`Notifications-markAllAsRead`);
		trackEvent('All Notifications', 'Marked As Read');
		Util.CommitMutation(UpdatePersonNotificationMutation, {
			ids: [],
			updateAll: true,
			read: true,
		});
	}

	// Don't show old notifications for disabled notification flags
	filterByWantedNotifications(notifications) {
		if (this.props.showAll) {
			return notifications;
		}
		return notifications.filter(
			notification => this.props.viewer[Util.getNotificationFlagFromType(notification.publisherAction)]
		);
	}

	render() {
		let notifications = this.filterByWantedNotifications(this.props.notifications);
		const {formatMessage} = this.props.intl;
		const todayNotifications = [];
		const earlierNotifications = [];
		const today = Moment();

		if (this.props.teammemberFilterValue && this.props.teammemberFilterValue.length > 0) {
			const teammemberFilterValues = this.props.teammemberFilterValue.map(filterValue => filterValue.value);
			notifications = notifications.filter(n => Util.teammemberNotiFilterValue(n, teammemberFilterValues));
		}
		if (this.props.actionFilterValue && this.props.actionFilterValue.length > 0) {
			const actionFilterValues = this.props.actionFilterValue.map(filterValue => filterValue.value);
			notifications = notifications.filter(n => Util.actionNotiFilterValue(n, actionFilterValues));
		}
		if (this.props.readStatusFilterValue && this.props.readStatusFilterValue.length > 0) {
			const readStatusFilterValues = this.props.readStatusFilterValue.map(filterValue => filterValue.value);
			let filteredNotifications = [];
			if (readStatusFilterValues.includes('read')) {
				filteredNotifications = filteredNotifications.concat(notifications.filter(n => n.read));
			}
			if (readStatusFilterValues.includes('unread')) {
				filteredNotifications = filteredNotifications.concat(notifications.filter(n => !n.read));
			}
			notifications = filteredNotifications;
		}
		if (this.props.projectFilterValue && this.props.projectFilterValue.length > 0) {
			const projectFilterValue = this.props.projectFilterValue.map(filterValue => filterValue.value);
			notifications = notifications.filter(n => Util.projectNotiFilterValue(n, projectFilterValue));
		}
		notifications = notifications.sort((a, b) => b.createdAt - a.createdAt);
		notifications.forEach(notification => {
			const date = Moment(Number(notification.createdAt));
			if (today.isSame(date, 'day')) {
				todayNotifications.push(notification);
			} else {
				earlierNotifications.push(notification);
			}
		});
		const isEmpty = notifications.length === 0;

		return (
			<div
				className={'notification-container' + (this.props.fullscreenView ? ' all-notifications' : '')}
				tabIndex={1}
				onBlur={this.props.onBlur}
			>
				<div className={'notification-container-header' + (this.props.fullscreenView ? ' full-page' : '')}>
					<div className="notification-header-notifications">
						{!this.props.fullscreenView && formatMessage({id: 'header.all_notifications'})}
					</div>
					{this.props.fullscreenView ? (
						<div className={'notification-header-filter'}>
							<Filter availableFilters={this.props.filters} />
						</div>
					) : null}
					<div
						className={'notification-header-mark-read' + (isEmpty ? ' empty' : '')}
						onClick={this.markAllAsRead.bind(this)}
						onKeyDown={this.props.onKeyDown}
						tabIndex={isEmpty ? -1 : 0}
					>
						{formatMessage({id: 'notifications.mark_all_as_read'})}
					</div>
					{this.props.fullscreenView ? null : (
						<div
							className="notification-header-mute"
							onClick={this.props.toggleMuteNotifications}
							onKeyDown={this.props.onKeyDown}
							tabIndex={0}
						>
							{this.props.muteNotifications
								? formatMessage({id: 'notifications.unmute'})
								: formatMessage({id: 'notifications.mute'})}
						</div>
					)}
					{this.props.fullscreenView ? null : (
						<div
							className={
								'notification-header-settings-button' +
								(window.location.href.endsWith('my-profile/notifications') ? ' active' : '')
							}
							onClick={this.navigateToSettings.bind(this)}
							tabIndex={0}
						>
							<SettingsIcon className="settings-icon" title={formatMessage({id: 'header.notifications'})} />
						</div>
					)}
				</div>
				{!isEmpty ? (
					<div className="notification-wrapper">
						<CustomScrollDiv ref={scrollDiv => (this.scrollBars = scrollDiv)} style={{height: '95%'}}>
							<div className={'notification-body' + (this.props.fullscreenView ? ' full-page' : '')}>
								<div
									className={'notification-date-splitter' + (this.props.fullscreenView ? ' spacing-top' : '')}
								>
									{formatMessage({id: 'common.today'})}
								</div>
								{todayNotifications.map((notification, index) => (
									<Notification
										shouldTakeFocus={index === this.props.focusedIndex}
										closeNotifications={this.props.closeNotifications}
										disableNotificationType={this.props.disableNotificationType}
										key={'today' + index}
										notification={notification}
										onBlur={this.props.onBlur}
										onKeyDown={this.props.onKeyDown}
										fullscreenView={this.props.fullscreenView}
										projects={this.props.projects}
										viewer={this.props.viewer}
										// Only last if below list is empty
										isLast={earlierNotifications.length === 0 && index === todayNotifications.length - 1}
									/>
								))}
								<div
									className={'notification-date-splitter' + (this.props.fullscreenView ? ' spacing-top' : '')}
								>
									{formatMessage({id: 'common.earlier'})}
								</div>
								{earlierNotifications.map((notification, index) => (
									<Notification
										closeNotifications={this.props.closeNotifications}
										disableNotificationType={this.props.disableNotificationType}
										key={'earlier' + index}
										notification={notification}
										onBlur={this.props.onBlur}
										onKeyDown={this.props.onKeyDown}
										fullscreenView={this.props.fullscreenView}
										projects={this.props.projects}
										viewer={this.props.viewer}
										isLast={index === earlierNotifications.length - 1}
									/>
								))}
							</div>
						</CustomScrollDiv>
						{this.props.fullscreenView ? null : (
							<div
								className="notification-container-footer"
								onClick={this.navigateToAll.bind(this)}
								onKeyDown={this.props.onKeyDown}
								tabIndex={0}
							>
								{formatMessage({id: 'notifications.view_all'})}
							</div>
						)}
					</div>
				) : (
					<EmptyState pageName={EMPTY_STATE.NOTIFICATIONS} />
				)}
			</div>
		);
	}
}
NotificationContainer.propTypes = {
	notifications: PropTypes.array.isRequired,
	onBlur: PropTypes.func.isRequired,
	onKeyDown: PropTypes.func.isRequired,
	muteNotifications: PropTypes.bool,
	fullscreenView: PropTypes.bool,
	toggleMuteNotifications: PropTypes.func,
};

export default injectIntl(withRouter(NotificationContainer));
