import {createFragmentContainer, graphql} from 'react-relay';
import {sortBreakdownPhases} from '../ProjectPortfolioReportUtils';
import {useIntl} from 'react-intl';
import {PHASE_BREAKDOWN_TYPE} from '../../../../constants';

const MonthByMonthPhaseLoader = ({relay, viewer, children, ...props}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const getAggregatedPhaseFinancialBreakdown = () => {
		const currency = viewer.company.currency;
		const aggregatedPhaseFinancialBreakdown = viewer.project.aggregatedPhaseFinancialBreakdown.map(
			aggregatedPhaseFinancialBreakdown => {
				const data = {...aggregatedPhaseFinancialBreakdown, currency};
				if (!data.phase) {
					data.phase = {
						name: formatMessage({id: 'project_portfolio_report.deleted_phase'}),
					};
				}
				return data;
			}
		);

		sortBreakdownPhases(aggregatedPhaseFinancialBreakdown);
		return aggregatedPhaseFinancialBreakdown;
	};

	let getTimeAggregatedFinancialNumbers;
	let getExpenseAggregatedFinancialNumbers;

	let timeAggregatedFinancialNumbers;
	let expenseAggregatedFinancialNumbers;

	let timeBreakdownPhases;
	let expenseBreakdownPhases;

	getTimeAggregatedFinancialNumbers = () => {
		const currency = viewer.company.currency;
		const timeAggregatedFinancialNumbers = viewer.project.timeAggregatedFinancialNumbers.map(
			timeAggregatedFinancialNumbers => {
				const data = {...timeAggregatedFinancialNumbers, currency};
				if (!data.phase) {
					data.phase = {
						name: formatMessage({id: 'project_portfolio_report.deleted_phase'}),
					};
				}
				return data;
			}
		);

		sortBreakdownPhases(timeAggregatedFinancialNumbers);
		return timeAggregatedFinancialNumbers;
	};

	getExpenseAggregatedFinancialNumbers = () => {
		const currency = viewer.company.currency;
		const expenseAggregatedFinancialNumbers = viewer.project.expenseAggregatedFinancialNumbers.map(
			expenseAggregatedFinancialNumbers => {
				const data = {...expenseAggregatedFinancialNumbers, currency};
				if (!data.phase) {
					data.phase = {
						name: formatMessage({id: 'project_portfolio_report.deleted_phase'}),
					};
				}
				return data;
			}
		);

		sortBreakdownPhases(expenseAggregatedFinancialNumbers);
		return expenseAggregatedFinancialNumbers;
	};

	timeAggregatedFinancialNumbers = getTimeAggregatedFinancialNumbers();
	expenseAggregatedFinancialNumbers = getExpenseAggregatedFinancialNumbers();

	timeBreakdownPhases = timeAggregatedFinancialNumbers.map(phase =>
		phase.breakdownType === PHASE_BREAKDOWN_TYPE.PHASE ? `${phase.breakdownType}-${phase.phase.id}` : phase.breakdownType
	);
	expenseBreakdownPhases = expenseAggregatedFinancialNumbers.map(phase =>
		phase.breakdownType === PHASE_BREAKDOWN_TYPE.PHASE ? `${phase.breakdownType}-${phase.phase.id}` : phase.breakdownType
	);

	const aggregatedPhaseFinancialBreakdown = getAggregatedPhaseFinancialBreakdown();
	const breakdownPhases = aggregatedPhaseFinancialBreakdown.map(phase =>
		phase.breakdownType === PHASE_BREAKDOWN_TYPE.PHASE ? `${phase.breakdownType}-${phase.phase.id}` : phase.breakdownType
	);

	let year;

	if (breakdownPhases.length > 0) {
		// Get year for later use
		year = aggregatedPhaseFinancialBreakdown[0].aggregatedFinancialNumbers[0].split(',')[0].split('-')[0];
	}

	// timeAggregatedFinancialNumbers and expenseAggregatedFinancialNumbers sometimes have less breakdown types than aggregatedPhaseFinancialBreakdown due to filtering.
	// If for instance timeAggregatedFinancialNumbers and aggregatedPhaseFinancialBreakdown have the same breakdown types, we just add a new object to aggregatedPhaseFinancialBreakdown
	// that contains timeAggregatedFinancialNumbers. If they do not have the same types, for the missing ones we add a 0 value period.
	for (let i = 0; i < breakdownPhases.length; i++) {
		if (timeBreakdownPhases.includes(breakdownPhases[i])) {
			aggregatedPhaseFinancialBreakdown[breakdownPhases.indexOf(breakdownPhases[i])].timeAggregatedFinancialNumbers =
				timeAggregatedFinancialNumbers[timeBreakdownPhases.indexOf(breakdownPhases[i])].timeAggregatedFinancialNumbers;
		} else {
			aggregatedPhaseFinancialBreakdown[breakdownPhases.indexOf(breakdownPhases[i])].timeAggregatedFinancialNumbers = [
				`${year}-01,${year}-12,0`,
			];
		}

		if (expenseBreakdownPhases.includes(breakdownPhases[i])) {
			aggregatedPhaseFinancialBreakdown[breakdownPhases.indexOf(breakdownPhases[i])].expenseAggregatedFinancialNumbers =
				expenseAggregatedFinancialNumbers[
					expenseBreakdownPhases.indexOf(breakdownPhases[i])
				].expenseAggregatedFinancialNumbers;
		} else {
			aggregatedPhaseFinancialBreakdown[breakdownPhases.indexOf(breakdownPhases[i])].expenseAggregatedFinancialNumbers = [
				`${year}-01,${year}-12,0`,
			];
		}
	}

	return children({data: {rows: aggregatedPhaseFinancialBreakdown}, ...props});
};

export const monthByMonthPhaseLoaderQuery = graphql`
	query MonthByMonthPhaseLoader_Query(
		$timeSearchQuery: TaskSearchQueryType!
		$expenseSearchQuery: TaskSearchQueryType!
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
		$projectId: ID!
		$selectedSingleValue: FinancialAggregatedNumber!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_month_by_month_phase_loader")
			...MonthByMonthPhaseLoader_viewer
				@arguments(
					timeSearchQuery: $timeSearchQuery
					expenseSearchQuery: $expenseSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					projectId: $projectId
					selectedSingleValue: $selectedSingleValue
				)
		}
	}
`;

export default createFragmentContainer(MonthByMonthPhaseLoader, {
	viewer: graphql`
		fragment MonthByMonthPhaseLoader_viewer on Viewer
		@argumentDefinitions(
			timeSearchQuery: {type: "TaskSearchQueryType!"}
			expenseSearchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
			projectId: {type: "ID!"}
			selectedSingleValue: {type: "FinancialAggregatedNumber!"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				aggregatedPhaseFinancialBreakdown(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: MONTH
					aggregates: [$selectedSingleValue]
					filterRowsWithNoValues: true
				) {
					breakdownType
					phase {
						id
						name
						startDay
						startMonth
						startYear
					}
					aggregatedFinancialNumbers
				}
				timeAggregatedFinancialNumbers: aggregatedPhaseFinancialBreakdown(
					searchQuery: $timeSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: MONTH
					aggregates: [$selectedSingleValue]
					filterRowsWithNoValues: true
				) {
					breakdownType
					phase {
						id
						name
						startDay
						startMonth
						startYear
					}
					timeAggregatedFinancialNumbers: aggregatedFinancialNumbers
				}
				expenseAggregatedFinancialNumbers: aggregatedPhaseFinancialBreakdown(
					searchQuery: $expenseSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: MONTH
					aggregates: [$selectedSingleValue]
					filterRowsWithNoValues: true
				) {
					breakdownType
					phase {
						id
						name
						startDay
						startMonth
						startYear
					}
					expenseAggregatedFinancialNumbers: aggregatedFinancialNumbers
				}
			}
		}
	`,
});
