import {
	createCanvasTimelineDate,
	getCombinedTaskMode,
	getHolidayDates,
	getMinutesInWeekdays,
	getMinutesInWeekdaysNew,
	getPersonGroups,
	getVisualizationMode,
	getWeekdaysBetweenDates,
	GROUP_TYPE,
	HEATMAP_ACTUAL_BACKGROUND_COLOR,
	HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_COMPLETION_BACKGROUND_COLOR_DARK,
	HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_OVERALLOCATED_BACKGROUND_COLOR_DARK,
	HEATMAP_FULL_BACKGROUND_COLOR_DARK,
	HEATMAP_OVER_ALLOCATED_COMPLETION_COLOR_DARK,
	HEATMAP_OVER_ALLOCATED_PROGRESS_COLOR,
	isAllocatedHoursNaN,
	isDateWithinPersonStartEndDate,
	isDayOffStep,
	isGlobalRecalculationNeeded,
	isTimeOffAllocation,
	TIMELINE_BACKGROUND_COLOR,
	VISUALIZATION_MODE,
	withinTimePeriod,
} from '../canvas-timeline/canvas_timeline_util';
import {isPlaceholderAllocationItem, isProjectAllocationItem, isTaskItem} from '../SchedulingUtils';
import {onPersonGroupHeatmapItemClick} from './PersonGroupHeatmap';
import {SCHEDULING_VIEW} from '../../../constants';
import {getRolePlaceholderDemand} from './capacity-overview/HeatmapLogic';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {SCALE_SETTINGS} from '../canvas-timeline/canvas_timeline';
import {isStepHiddenBehindLoadMore} from '../loading/LoadMoreUtil';
import {getWeekdaysBetweenDatesNew} from '../../scheduling/project_allocation_logic';
import RecalculationManager, {interval} from '../RecalculationManager';
import HeatmapItem, {HEATMAP_TYPE} from '../components/items/Heatmap/HeatmapItem';
import EventManager from '../EventManager';
import ComposeManager from '../ComposeManager';
import HeatmapItemConfig from '../components/items/Heatmap/HeatmapItemConfig';
import {getNonWorkingDays, getNonWorkingDaysCount, isPersonDayOff, isWorkingDay} from './NonWorkingDaysLogic';
import DataManager from '../DataManager';
import {NO_ACCESS_DISTRIBUTION_MAP_ID, NO_ACCESS_DISTRIBUTION_UNIQUE} from '../constants';
import {
	addMinutesAllocatedVariations,
	addMinutesAllocatedVariationsNew,
	initMinutesAllocatedVariations,
	mergeMinutesAllocatedVariations,
} from './MinutesAllocatedVariationsUtils';
import Util from '../../../forecast-app/shared/util/util';
import CacheIntervalManager from '../CacheIntervalManager';

const getNeedsRecalculationObject = (needsRecalculation = true) => {
	return Object.values(SCALE_SETTINGS).reduce((acc, scaleSetting) => {
		acc[scaleSetting.minorStep] = needsRecalculation;
		return acc;
	}, {});
};

export const checkNeedsRecalculation = (cache, timelineMinorStep) => {
	return !!cache?.needsRecalculation?.[timelineMinorStep];
};

export const clearNeedsRecalculation = (cache, timelineMinorStep) => {
	if (cache?.needsRecalculation) {
		cache.needsRecalculation[timelineMinorStep] = false;
	}
};

export const setNeedsRecalculation = cache => {
	if (cache) {
		cache.needsRecalculation = getNeedsRecalculationObject();
	}
};

export const getGroupCache = (pageComponent, groupId) => {
	let cache = pageComponent.heatmapCache.get(groupId);

	if (!cache) {
		cache = new Map();
		pageComponent.heatmapCache.set(groupId, cache);
	}

	return cache;
};

export const getStepCache = (groupCache, timelineMinorStep) => {
	let cache = groupCache.get(timelineMinorStep);

	if (!cache) {
		cache = new Map();
		groupCache.set(timelineMinorStep, cache);
	}

	return cache;
};

export const setStepCachedItem = (groupId, stepCache, timelineMinorStep, cachedItem, startDate, endDate) => {
	CacheIntervalManager.addCacheInterval(groupId, timelineMinorStep, startDate, endDate);
	stepCache.set(startDate, cachedItem);
};

export const isRecalculationNeeded = (groupId, groupCache, timelineMinorStep, overallStartDate, overallEndDate) => {
	if (hasFeatureFlag('scheduling_recalculation_tree')) {
		return RecalculationManager.needsRecalculation(groupId, timelineMinorStep, overallStartDate, overallEndDate);
	}

	return groupCache && checkNeedsRecalculation(groupCache, timelineMinorStep);
};

export const recalculateGroupHeatmapCache = (pageComponent, groupId, interval) => {
	if (hasFeatureFlag('scheduling_recalculation_tree')) {
		RecalculationManager.setNeedsRecalculation(groupId, interval);
	} else {
		const cache = getGroupCache(pageComponent, groupId);
		setNeedsRecalculation(cache);
	}

	if (hasFeatureFlag('combined_mode_performance_improvements')) {
		const {timeline} = pageComponent;

		if (timeline) {
			timeline.setRedrawRecalculationProperties();
		}
	}
};

export const recalculateGroupHeatmapCaches = (pageComponent, interval, ...groupIds) => {
	groupIds.forEach(groupId => recalculateGroupHeatmapCache(pageComponent, groupId, interval));
};

export const getRecalculationInterval = (item, deltaStart, deltaEnd) => {
	if (deltaStart || deltaEnd) {
		return interval(item.startDate - Math.abs(deltaStart), item.endDate + Math.abs(deltaEnd));
	}
	return interval(item.startDate, item.endDate);
};

export const getAllocationInterval = allocation => {
	const startDate = createCanvasTimelineDate(allocation.startYear, allocation.startMonth, allocation.startDay);
	const endDate = createCanvasTimelineDate(allocation.endYear, allocation.endMonth, allocation.endDay);
	return interval(startDate, endDate);
};

export const getTimeRegInterval = ({canvasTimelineDate}) => {
	return interval(canvasTimelineDate, canvasTimelineDate);
};

export const clearPagecomponentHeatmapCache = pageComponent => {
	pageComponent.heatmapCache = new Map();
	RecalculationManager.clearAll();
};

const addToMap = (map, id, cb) => {
	const existingItem = map.get(id);
	const item = existingItem || {
		minutes: 0,
		minutesWin: 0,
		minutesHard: 0,
		minutesSoft: 0,
		minutesSoftWin: 0,
		actualTaskMinutes: 0,
		plannedTaskMinutes: 0,
		timeRegMinutes: 0,
		allocationMinutes: 0,
		plannedAllocationMinutes: 0,
		plannedAllocationMinutesWin: 0,
		plannedAllocationMinutesHard: 0,
		plannedAllocationMinutesSoft: 0,
		plannedAllocationMinutesSoftWin: 0,
	};
	cb(item);
	if (!existingItem) {
		map.set(id, item);
	}
};

const addAllocationMinutesToMap = (map, id, allocationMinutes, plannedAllocationMinutes = 0) => {
	addToMap(map, id, item => {
		item.minutes += allocationMinutes;
		item.minutesWin += allocationMinutes;
		item.minutesHard += allocationMinutes;
		item.allocationMinutes += allocationMinutes;
		item.plannedAllocationMinutes += plannedAllocationMinutes;
		item.plannedAllocationMinutesWin += plannedAllocationMinutes;
		item.plannedAllocationMinutesHard += plannedAllocationMinutes;
	});
};

const addActualTaskMinutesToMap = (map, id, taskMinutes) => {
	addToMap(map, id, item => {
		item.minutes += taskMinutes;
		item.minutesWin += taskMinutes;
		item.minutesHard += taskMinutes;
		item.actualTaskMinutes += taskMinutes;
	});
};
const addPlannedTaskMinutesToMap = (map, id, taskMinutes) => {
	addToMap(map, id, item => {
		item.minutes += taskMinutes;
		item.minutesWin += taskMinutes;
		item.minutesHard += taskMinutes;
		item.plannedTaskMinutes += taskMinutes;
	});
};
const addTimeRegMinutesToMap = (map, id, timeRegMinutes) => {
	addToMap(map, id, item => {
		item.minutes += timeRegMinutes;
		item.minutesWin += timeRegMinutes;
		item.minutesHard += timeRegMinutes;
		item.timeRegMinutes += timeRegMinutes;
	});
};

const getHolidayCountInPeriod = (holidaysExcludingDaysOff, periodStartDate, periodEndDate, personWorkingMinutes) => {
	if (personWorkingMinutes && hasFeatureFlag('combined_mode_performance_improvements')) {
		return holidaysExcludingDaysOff.filter(date => {
			return date >= periodStartDate && date <= periodEndDate && isWorkingDay(personWorkingMinutes, date);
		}).length;
	} else {
		return holidaysExcludingDaysOff.filter(date => {
			return date >= periodStartDate && date <= periodEndDate;
		}).length;
	}
};

export const getPersonWorkingHoursAndMinutes = person => {
	const personWorkingHours = person.workingHours || {
		monday: person.monday,
		tuesday: person.tuesday,
		wednesday: person.wednesday,
		thursday: person.thursday,
		friday: person.friday,
		saturday: person.saturday,
		sunday: person.sunday,
	};

	const personWorkingMinutes = [
		personWorkingHours.monday,
		personWorkingHours.tuesday,
		personWorkingHours.wednesday,
		personWorkingHours.thursday,
		personWorkingHours.friday,
		personWorkingHours.saturday,
		personWorkingHours.sunday,
	];

	return {personWorkingHours, personWorkingMinutes};
};

export const removeHolidaysFromWeekdays = (weekdaysInPeriod, holidaysIncludingDaysOff, startDate, endDate, dayData) => {
	for (const holidayCalendarEntryDate of holidaysIncludingDaysOff.filter(date => date >= startDate && date <= endDate)) {
		weekdaysInPeriod[dayData[holidayCalendarEntryDate].isoWeekday - 1]--;
	}
};

export const removeWeekdaysOutsidePersonTimePeriod = (person, startDate, endDate, weekdaysInPeriod, dayData) => {
	for (let canvasDate = startDate; canvasDate <= endDate; canvasDate++) {
		if (!isDateWithinPersonStartEndDate(person, canvasDate)) {
			weekdaysInPeriod[dayData[canvasDate].isoWeekday - 1] = Math.max(
				weekdaysInPeriod[dayData[canvasDate].isoWeekday - 1] - 1,
				0
			);
		}
	}
};

export const isItemWithinStep = (item, stepStartDate, stepEndDate) => {
	const {startDate, endDate} = item;
	return startDate <= stepEndDate && stepStartDate <= endDate;
};

export const processTask = (
	person,
	taskItemData,
	minutesAllocated,
	distributionMap,
	startDate,
	endDate,
	taskStartDate,
	taskEndDate,
	personWorkingMinutes,
	holidaysExcludingDaysOff,
	nonWorkingDaysMap,
	isUsingCombinedMode
) => {
	//Check if task is in current loop period
	if (taskStartDate > endDate || taskEndDate < startDate - 1) return [minutesAllocated, distributionMap];

	const hasHeatmapImprovements = hasFeatureFlag('improving_heatmap_frontend_performance');

	const task = taskItemData.task;
	let {projectId, estimateForecastMinutes, assignedPersons} = task;

	//Tasks with no forecast do not contribute anything
	if (!estimateForecastMinutes) return [minutesAllocated, distributionMap];

	const distributionMapId = isUsingCombinedMode ? taskItemData.projectGroupId || projectId : projectId;

	let taskMinutesAllocated;

	//If a task is outside the employment period, calculate it as having a shorter duration
	let taskPersonActualStartDate = taskStartDate;
	if (person.startDate && taskStartDate < person.startDate) {
		taskPersonActualStartDate = person.startDate;
	}

	let taskPersonActualEndDate = taskEndDate;
	if (person.endDate && taskEndDate > person.endDate) {
		taskPersonActualEndDate = person.endDate;
	}

	//How many weekdays are in the task (ex: 1 monday, 1 tuesday, 1 wednesday)
	const taskTotalWeekdays = hasHeatmapImprovements
		? getWeekdaysBetweenDatesNew(taskPersonActualStartDate, taskPersonActualEndDate)
		: getWeekdaysBetweenDates(taskPersonActualStartDate, taskPersonActualEndDate);

	//Weekdays which are within the current loop period
	const taskLoopWeekdays = hasHeatmapImprovements
		? getWeekdaysBetweenDatesNew(Math.max(taskPersonActualStartDate, startDate), Math.min(taskPersonActualEndDate, endDate))
		: getWeekdaysBetweenDates(Math.max(taskPersonActualStartDate, startDate), Math.min(taskPersonActualEndDate, endDate));

	let taskWorkingDayCount;
	if (hasFeatureFlag('inverted_pto_non_working_days')) {
		//Total length of task in days minus days on which the person is supposed to work 0 minutes
		taskWorkingDayCount =
			taskTotalWeekdays.reduce((total, weekdayCount, index) => {
				return total + (personWorkingMinutes[index] ? weekdayCount : 0);
			}, 0) -
			getHolidayCountInPeriod(
				holidaysExcludingDaysOff,
				taskPersonActualStartDate,
				taskPersonActualEndDate,
				personWorkingMinutes
			);
	} else {
		const nonWorking = getNonWorkingDaysCount(
			taskPersonActualStartDate,
			taskPersonActualEndDate,
			nonWorkingDaysMap,
			person.id,
			personWorkingMinutes
		);

		//Total length of task in days minus days on which the person is supposed to work 0 minutes
		taskWorkingDayCount =
			taskTotalWeekdays.reduce((total, weekdayCount, index) => {
				return total + (personWorkingMinutes[index] ? weekdayCount : 0);
			}, 0) - nonWorking;
	}

	//If there are any working days during the task's period, spread forecast evenly among working days only
	//If there are no working days during the task's period, spread forecast evenly among all days
	//Always divide by amount of people assigned
	if (taskWorkingDayCount) {
		const daysWithinCurrentLoop = taskLoopWeekdays.reduce((total, weekdayCount, index) => {
			return total + (personWorkingMinutes[index] ? weekdayCount : 0);
		}, 0);

		let nonWorkingDaysCount;
		if (hasFeatureFlag('inverted_pto_non_working_days')) {
			nonWorkingDaysCount = getHolidayCountInPeriod(
				holidaysExcludingDaysOff,
				Math.max(taskPersonActualStartDate, startDate),
				Math.min(taskPersonActualEndDate, endDate),
				personWorkingMinutes
			);
		} else {
			nonWorkingDaysCount = getNonWorkingDaysCount(
				Math.max(taskPersonActualStartDate, startDate),
				Math.min(taskPersonActualEndDate, endDate),
				nonWorkingDaysMap,
				person.id,
				personWorkingMinutes
			);
		}

		//Number of days within current loop period minus days on which the person is supposed to work 0 minutes
		const taskLoopWorkingDayCount = daysWithinCurrentLoop - nonWorkingDaysCount;

		taskMinutesAllocated =
			(estimateForecastMinutes * taskLoopWorkingDayCount) / taskWorkingDayCount / (assignedPersons.length || 1);
		minutesAllocated += taskMinutesAllocated;
	} else {
		//Total length of task in days
		const taskTotalDayCount = taskTotalWeekdays.reduce((total, weekdayCount) => total + weekdayCount, 0);

		//Number of days within current loop period
		const taskLoopTotalDayCount = taskLoopWeekdays.reduce((total, weekdayCount) => total + weekdayCount, 0);
		taskMinutesAllocated =
			(estimateForecastMinutes * taskLoopTotalDayCount) / taskTotalDayCount / (assignedPersons.length || 1);

		minutesAllocated += taskMinutesAllocated;
	}

	// Distribution map is not maintained for no-access heatmaps
	if (distributionMap && distributionMapId) {
		addPlannedTaskMinutesToMap(distributionMap, distributionMapId, taskMinutesAllocated);
	}

	return [minutesAllocated, distributionMap];
};

export const getTaskHeatmapData = (
	minutesAllocated,
	personGroupData,
	visibleItemsData,
	distributionMap,
	startDate,
	endDate,
	personWorkingMinutes,
	holidaysExcludingDaysOff,
	nonWorkingDaysMap,
	isUsingCombinedMode
) => {
	const taskItems = visibleItemsData.taskItems || [];

	for (const taskItem of taskItems) {
		if (!taskItem) continue;

		const {startDate: taskStartDate, endDate: taskEndDate} = taskItem;

		[minutesAllocated, distributionMap] = processTask(
			{id: personGroupData.personId, startDate: personGroupData.startDate, endDate: personGroupData.endDate},
			taskItem.data,
			minutesAllocated,
			distributionMap,
			startDate,
			endDate,
			taskStartDate,
			taskEndDate,
			personWorkingMinutes,
			holidaysExcludingDaysOff,
			nonWorkingDaysMap,
			isUsingCombinedMode
		);
	}

	return [minutesAllocated, distributionMap];
};
export const updatePlannedTaskDistributionMap = (
	personGroupData,
	visibleItemsData,
	distributionMap,
	startDate,
	endDate,
	personWorkingMinutes,
	holidaysExcludingDaysOff,
	nonWorkingDaysMap,
	isUsingCombinedMode
) => {
	const taskItems = visibleItemsData.taskItems || [];

	for (const taskItem of taskItems) {
		if (!taskItem) continue;

		const {startDate: taskStartDate, endDate: taskEndDate} = taskItem;

		processTask(
			{id: personGroupData.personId, startDate: personGroupData.startDate, endDate: personGroupData.endDate},
			taskItem.data,
			0,
			distributionMap,
			startDate,
			endDate,
			taskStartDate,
			taskEndDate,
			personWorkingMinutes,
			holidaysExcludingDaysOff,
			nonWorkingDaysMap,
			isUsingCombinedMode
		);
	}
};

export const processTaskActualMode = (
	taskItemData,
	minutesAllocated,
	distributionMap,
	startDate,
	endDate,
	taskStartDate,
	taskEndDate,
	todayDate,
	personWorkingMinutes,
	holidaysExcludingDaysOff,
	nonWorkingDaysMap,
	person,
	isUsingCombinedMode
) => {
	const task = taskItemData.task;
	if (task.done) return [minutesAllocated, distributionMap];

	const distributionMapId = isUsingCombinedMode ? taskItemData.projectGroupId || task.projectId : task.projectId;

	const timeLeft =
		isUsingCombinedMode && !hasFeatureFlag('combined_heatmap_logic_extensions')
			? task.timeLeft
			: task.timeLeftMinutesWithoutFutureTimeRegs;
	const personId = person.id;

	//Peace out if task has no remaining since it will not affect heatmap regardless of its position
	if (timeLeft <= 0) return [minutesAllocated, distributionMap];

	//Check if task is within current loop period (can get to this point if only a time registration outside of task is within looped period)
	if (!(taskStartDate > endDate || taskEndDate < startDate - 1)) {
		//We do not care about tasks in the past
		const isInThePast = taskEndDate < todayDate;
		if (isInThePast) return [minutesAllocated, distributionMap];

		const hasHeatmapImprovements = hasFeatureFlag('improving_heatmap_frontend_performance');

		let taskPersonActualStartDate = taskStartDate;
		if (person.startDate && taskStartDate < person.startDate) {
			taskPersonActualStartDate = person.startDate;
		}

		let taskPersonActualEndDate = taskEndDate;
		if (person.endDate && taskEndDate > person.endDate) {
			taskPersonActualEndDate = person.endDate;
		}

		//If task is partially in the past and partially in the future, use today as the starting date
		const calculationStartDate = Math.max(todayDate, taskPersonActualStartDate);
		const calculationEndDate = taskPersonActualEndDate;

		//How many weekdays are in the task (ex: 1 monday, 1 tuesday, 1 wednesday)
		const taskTotalWeekdays = hasHeatmapImprovements
			? getWeekdaysBetweenDatesNew(calculationStartDate, calculationEndDate)
			: getWeekdaysBetweenDates(calculationStartDate, calculationEndDate);

		//Weekdays which are within the current loop period
		const taskLoopWeekdays = hasHeatmapImprovements
			? getWeekdaysBetweenDatesNew(Math.max(calculationStartDate, startDate), Math.min(calculationEndDate, endDate))
			: getWeekdaysBetweenDates(Math.max(calculationStartDate, startDate), Math.min(calculationEndDate, endDate));

		let taskWorkingDayCount;
		if (hasFeatureFlag('inverted_pto_non_working_days')) {
			//Total length of task in days minus days on which the person is supposed to work 0 minutes
			taskWorkingDayCount =
				taskTotalWeekdays.reduce((total, weekdayCount, index) => {
					return total + (personWorkingMinutes[index] ? weekdayCount : 0);
				}, 0) -
				getHolidayCountInPeriod(
					holidaysExcludingDaysOff,
					calculationStartDate,
					calculationEndDate,
					personWorkingMinutes
				);
		} else {
			//Total length of task in days minus days on which the person is supposed to work 0 minutes
			taskWorkingDayCount =
				taskTotalWeekdays.reduce((total, weekdayCount, index) => {
					return total + (personWorkingMinutes[index] ? weekdayCount : 0);
				}, 0) -
				getNonWorkingDaysCount(
					calculationStartDate,
					calculationEndDate,
					nonWorkingDaysMap,
					personId,
					personWorkingMinutes
				);
		}

		//If there are any working days during the task's period, spread timeLeft evenly among working days only
		//If there are no working days during the task's period, spread timeLeft evenly among all days
		//Always divide by amount of people assigned
		let taskMinutesAllocated;
		if (taskWorkingDayCount) {
			const daysWithinCurrentLoop = taskLoopWeekdays.reduce((total, weekdayCount, index) => {
				return total + (personWorkingMinutes[index] ? weekdayCount : 0);
			}, 0);
			let nonWorkingDaysCount;
			if (hasFeatureFlag('inverted_pto_non_working_days')) {
				nonWorkingDaysCount = getHolidayCountInPeriod(
					holidaysExcludingDaysOff,
					Math.max(calculationStartDate, startDate),
					Math.min(calculationEndDate, endDate),
					personWorkingMinutes
				);
			} else {
				nonWorkingDaysCount = getNonWorkingDaysCount(
					Math.max(calculationStartDate, startDate),
					Math.min(calculationEndDate, endDate),
					nonWorkingDaysMap,
					personId,
					personWorkingMinutes
				);
			}

			//Number of days within current loop period minus days on which the person is supposed to work 0 minutes
			const taskLoopWorkingDayCount = daysWithinCurrentLoop - nonWorkingDaysCount;

			taskMinutesAllocated =
				(timeLeft * taskLoopWorkingDayCount) / taskWorkingDayCount / (task.assignedPersons.length || 1);

			minutesAllocated += taskMinutesAllocated;
		} else {
			//Total length of task in days
			const taskTotalDayCount = taskTotalWeekdays.reduce((total, weekdayCount) => total + weekdayCount, 0);

			if (taskTotalDayCount > 0) {
				//Number of days within current loop period
				const taskLoopTotalDayCount = taskLoopWeekdays.reduce((total, weekdayCount) => total + weekdayCount, 0);

				taskMinutesAllocated =
					(timeLeft * taskLoopTotalDayCount) / taskTotalDayCount / (task.assignedPersons.length || 1);

				minutesAllocated += taskMinutesAllocated;
			}
		}
		// Distribution map is not maintained for no-access heatmaps
		if (distributionMap && distributionMapId) {
			addActualTaskMinutesToMap(distributionMap, distributionMapId, taskMinutesAllocated);
		}
	}

	return [minutesAllocated, distributionMap];
};

const getDayCounts = (
	startDate,
	endDate,
	calculationStartDate,
	calculationEndDate,
	personWorkingMinutes,
	holidaysExcludingDaysOff,
	nonWorkingDaysMap,
	personId
) => {
	//How many weekdays are in the task (ex: 1 monday, 1 tuesday, 1 wednesday)
	const taskTotalWeekdays = getWeekdaysBetweenDatesNew(calculationStartDate, calculationEndDate);

	//Weekdays which are within the current loop period
	const taskLoopWeekdays = getWeekdaysBetweenDatesNew(
		Math.max(calculationStartDate, startDate),
		Math.min(calculationEndDate, endDate)
	);

	let taskWorkingDayCount;
	if (hasFeatureFlag('inverted_pto_non_working_days')) {
		//Total length of task in days minus days on which the person is supposed to work 0 minutes
		taskWorkingDayCount =
			taskTotalWeekdays.reduce((total, weekdayCount, index) => {
				return total + (personWorkingMinutes[index] ? weekdayCount : 0);
			}, 0) -
			getHolidayCountInPeriod(holidaysExcludingDaysOff, calculationStartDate, calculationEndDate, personWorkingMinutes);
	} else {
		//Total length of task in days minus days on which the person is supposed to work 0 minutes
		taskWorkingDayCount =
			taskTotalWeekdays.reduce((total, weekdayCount, index) => {
				return total + (personWorkingMinutes[index] ? weekdayCount : 0);
			}, 0) -
			getNonWorkingDaysCount(calculationStartDate, calculationEndDate, nonWorkingDaysMap, personId, personWorkingMinutes);
	}

	if (taskWorkingDayCount) {
		const daysWithinCurrentLoop = taskLoopWeekdays.reduce((total, weekdayCount, index) => {
			return total + (personWorkingMinutes[index] ? weekdayCount : 0);
		}, 0);
		let nonWorkingDaysCount;
		if (hasFeatureFlag('inverted_pto_non_working_days')) {
			nonWorkingDaysCount = getHolidayCountInPeriod(
				holidaysExcludingDaysOff,
				Math.max(calculationStartDate, startDate),
				Math.min(calculationEndDate, endDate),
				personWorkingMinutes
			);
		} else {
			nonWorkingDaysCount = getNonWorkingDaysCount(
				Math.max(calculationStartDate, startDate),
				Math.min(calculationEndDate, endDate),
				nonWorkingDaysMap,
				personId,
				personWorkingMinutes
			);
		}

		//Number of days within current loop period minus days on which the person is supposed to work 0 minutes
		const taskLoopWorkingDayCount = daysWithinCurrentLoop - nonWorkingDaysCount;

		return [taskLoopWorkingDayCount, taskWorkingDayCount];
	} else {
		//Total length of task in days
		const taskTotalDayCount = taskTotalWeekdays.reduce((total, weekdayCount) => total + weekdayCount, 0);

		//Number of days within current loop period
		const taskLoopTotalDayCount =
			taskTotalDayCount > 0 ? taskLoopWeekdays.reduce((total, weekdayCount) => total + weekdayCount, 0) : 0;

		return [taskLoopTotalDayCount, taskTotalDayCount];
	}
};

export const processTaskCombinedActualMode = (
	taskItemData,
	minutesAllocated,
	distributionMap,
	startDate,
	endDate,
	taskStartDate,
	taskEndDate,
	todayDate,
	personWorkingMinutes,
	holidaysExcludingDaysOff,
	nonWorkingDaysMap,
	person
) => {
	const task = taskItemData.task;
	let {projectId, estimateForecastMinutes} = task;
	const timeLeft = task.timeLeftMinutesWithoutFutureTimeRegs;

	// Check if task is in current loop period
	if (taskStartDate > endDate || taskEndDate < startDate - 1 || task.done || !estimateForecastMinutes)
		return [minutesAllocated, distributionMap];

	const distributionMapId = taskItemData.projectGroupId || projectId;

	//If a task is outside the employment period, calculate it as having a shorter duration
	let taskPersonActualStartDate = taskStartDate;
	if (person.startDate && taskStartDate < person.startDate) {
		taskPersonActualStartDate = person.startDate;
	}

	let taskPersonActualEndDate = taskEndDate;
	if (person.endDate && taskEndDate > person.endDate) {
		taskPersonActualEndDate = person.endDate;
	}

	let [loopDayCount, dayCount] = getDayCounts(
		startDate,
		endDate,
		taskPersonActualStartDate,
		taskPersonActualEndDate,
		personWorkingMinutes,
		holidaysExcludingDaysOff,
		nonWorkingDaysMap,
		person.id
	);

	const taskMinutesAllocated = (estimateForecastMinutes * loopDayCount) / dayCount / (task.assignedPersons.length || 1);

	// Distribution map is not maintained for no-access heatmaps
	if (distributionMap && distributionMapId) {
		addPlannedTaskMinutesToMap(distributionMap, distributionMapId, taskMinutesAllocated);
	}

	//Check if task is within current loop period (can get to this point if only a time registration outside of task is within looped period)
	if (!(taskStartDate > endDate || taskEndDate < startDate - 1) && timeLeft > 0) {
		//We do not care about tasks in the past
		const isInThePast = taskEndDate < todayDate;
		if (isInThePast) return [minutesAllocated, distributionMap];

		if (todayDate > taskPersonActualStartDate) {
			[loopDayCount, dayCount] = getDayCounts(
				startDate,
				endDate,
				todayDate,
				taskPersonActualEndDate,
				personWorkingMinutes,
				holidaysExcludingDaysOff,
				nonWorkingDaysMap,
				person.id
			);
		}

		const taskMinutesAllocated = (timeLeft * loopDayCount) / dayCount / (task.assignedPersons.length || 1);
		minutesAllocated += taskMinutesAllocated;

		// Distribution map is not maintained for no-access heatmaps
		if (distributionMap && distributionMapId) {
			addActualTaskMinutesToMap(distributionMap, distributionMapId, taskMinutesAllocated);
		}
	}

	return [minutesAllocated, distributionMap];
};

export const getTaskHeatmapDataCombinedActualMode = (
	minutesAllocated,
	visibleItemsData,
	distributionMap,
	holidaysExcludingDaysOff,
	startDate,
	endDate,
	todayDate,
	personWorkingMinutes,
	nonWorkingDaysMap,
	person
) => {
	const taskItems = visibleItemsData.taskItems || [];

	for (const taskItem of taskItems) {
		if (!taskItem) continue;

		const {startDate: taskStartDate, endDate: taskEndDate} = taskItem;

		[minutesAllocated, distributionMap] = processTaskCombinedActualMode(
			taskItem.data,
			minutesAllocated,
			distributionMap,
			startDate,
			endDate,
			taskStartDate,
			taskEndDate,
			todayDate,
			personWorkingMinutes,
			holidaysExcludingDaysOff,
			nonWorkingDaysMap,
			person
		);
	}

	return [minutesAllocated, distributionMap];
};

export const getTaskHeatmapDataActualMode = (
	minutesAllocated,
	visibleItemsData,
	distributionMap,
	holidaysExcludingDaysOff,
	startDate,
	endDate,
	todayDate,
	personWorkingMinutes,
	nonWorkingDaysMap,
	person,
	isUsingCombinedMode
) => {
	const taskItems = visibleItemsData.taskItems;

	if (taskItems?.length > 0) {
		for (const taskItem of taskItems) {
			if (!taskItem) continue;

			const {startDate: taskStartDate, endDate: taskEndDate} = taskItem;

			[minutesAllocated, distributionMap] = processTaskActualMode(
				taskItem.data,
				minutesAllocated,
				distributionMap,
				startDate,
				endDate,
				taskStartDate,
				taskEndDate,
				todayDate,
				personWorkingMinutes,
				holidaysExcludingDaysOff,
				nonWorkingDaysMap,
				person,
				isUsingCombinedMode
			);
		}
	}

	return [minutesAllocated, distributionMap];
};

export const processTimeReg = (
	pageComponent,
	timeReg,
	minutesAllocated,
	distributionMap,
	startDate,
	endDate,
	todayDate,
	isCombinedMode,
	timeOffMinutesMap
) => {
	const timeRegDate = timeReg.canvasTimelineDate;

	const isInPast = timeRegDate < todayDate;
	const isWithinStep = timeRegDate <= endDate && timeRegDate >= startDate;

	if (isWithinStep && (isInPast || (isCombinedMode && !hasFeatureFlag('combined_heatmap_logic_extensions')))) {
		let isDayOff = false;
		if (!hasFeatureFlag('inverted_pto_non_working_days')) {
			const {nonWorkingDaysMap} = pageComponent.state.data;
			const nonWorkingDays = getNonWorkingDays(startDate, endDate, nonWorkingDaysMap, timeReg.personId);
			isDayOff = nonWorkingDays.has(timeRegDate);
		}

		let isTimeOff = false;
		if (timeReg.idleTimeId) {
			isTimeOff = DataManager.getIdleTimeById(pageComponent, timeReg.idleTimeId)?.isInternalTime === false;
		}

		if (!isDayOff) {
			if (isInPast && isTimeOff && !!timeOffMinutesMap) {
				const timeOffMinutesOnDay = timeOffMinutesMap.get(timeRegDate) || 0;
				timeOffMinutesMap.set(timeRegDate, timeOffMinutesOnDay + timeReg.minutesRegistered);
			} else if (!isTimeOff) {
				minutesAllocated += timeReg.minutesRegistered;
			}
		}

		const timeRegMinutesRegistered = (isInPast && isTimeOff) || (!isTimeOff && !isDayOff) ? timeReg.minutesRegistered : 0;
		const timeRegTask = timeReg.taskId ? DataManager.getTaskById(pageComponent, timeReg.taskId) : null;
		const distributionMapId = timeReg.projectId || timeReg.idleTimeId || (timeReg.taskId ? timeRegTask?.projectId : null);

		// Distribution map is not maintained for no-access heatmaps
		if (distributionMap && distributionMapId) {
			addTimeRegMinutesToMap(distributionMap, distributionMapId, timeRegMinutesRegistered);
		}
	}

	return [minutesAllocated, distributionMap];
};

export const processTimeRegNew = (
	pageComponent,
	timeReg,
	minutesAllocated,
	distributionMap,
	startDate,
	endDate,
	todayDate,
	isCombinedMode,
	timeOffMinutesMap,
	nonWorkingDays,
	hasPtoInverted
) => {
	const timeRegDate = timeReg.canvasTimelineDate;

	let isDayOff = false;
	if (!hasPtoInverted) {
		isDayOff = nonWorkingDays.has(timeRegDate);
	}

	if (!isDayOff) {
		if (timeReg.isTimeOff && !!timeOffMinutesMap) {
			const timeOffMinutesOnDay = timeOffMinutesMap.get(timeRegDate) || 0;
			timeOffMinutesMap.set(timeRegDate, timeOffMinutesOnDay + timeReg.minutesRegistered);
		} else if (!timeReg.isTimeOff) {
			minutesAllocated += timeReg.minutesRegistered;
		}
	}

	// Distribution map is not maintained for no-access heatmaps
	const distributionMapId = timeReg.projectOrTaskProjectId || timeReg.idleTimeId || null;
	if (distributionMap && distributionMapId) {
		const timeRegMinutesRegistered = timeReg.isTimeOff || !isDayOff ? timeReg.minutesRegistered : 0;
		addTimeRegMinutesToMap(distributionMap, distributionMapId, timeRegMinutesRegistered);
	}

	return [minutesAllocated, distributionMap];
};

export const getTimeRegistrationHeatmapData = (
	pageComponent,
	personId,
	projectGroupData,
	distributionMap,
	startDate,
	endDate,
	todayDate,
	minutesAllocated,
	minutesAvailable,
	personWorkingMinutes
) => {
	const {schedulingOptions} = pageComponent.state;
	const {isProjectTimeline, dayData} = pageComponent.props;
	const {company} = pageComponent.getFilterData();
	const isCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
	const personTimeRegs = DataManager.getTimeRegsByPersonId(pageComponent, personId);

	const {project, projectIds} = projectGroupData || {};
	const projectId = project?.id;
	const isProjectGroup = !!projectGroupData;

	const isPartOfProject = timeReg => {
		if (timeReg.projectId) {
			return projectId ? timeReg.projectId === projectId : projectIds.has(timeReg.projectId);
		}

		const task = DataManager.getTaskById(pageComponent, timeReg.taskId);
		if (task) {
			if (projectId) {
				return task?.projectId === projectId;
			} else {
				return projectIds.has(task?.projectId);
			}
		}

		return false;
	};

	const timeOffMinutesMap = new Map();
	for (let i = 0; i < personTimeRegs.length; i++) {
		const timeReg = personTimeRegs[i];

		if (isCombinedMode && !isProjectTimeline) {
			if ((timeReg.taskId || timeReg.projectId) && (!isProjectGroup || !isPartOfProject(timeReg))) {
				continue;
			} else if (timeReg.idleTimeId && isProjectGroup) {
				continue;
			}
		}

		[minutesAllocated, distributionMap] = processTimeReg(
			pageComponent,
			timeReg,
			minutesAllocated,
			distributionMap,
			startDate,
			endDate,
			todayDate,
			isCombinedMode,
			timeOffMinutesMap
		);
	}

	for (const [day, minutesRegistered] of timeOffMinutesMap.entries()) {
		minutesAvailable -= Math.min(minutesRegistered, personWorkingMinutes[dayData[day].isoWeekday - 1]);
	}

	return [minutesAllocated, minutesAvailable, distributionMap];
};

export const getPersonTimeRegistrationHeatmapData = (
	pageComponent,
	personId,
	projectGroupData,
	calculationMetaData,
	distributionMap,
	startDate,
	endDate,
	todayDate,
	minutesAllocated,
	minutesAvailable,
	personWorkingMinutes
) => {
	if (todayDate > startDate) {
		const {personTimeRegSearchTreeMap} = calculationMetaData;
		const searchEndDate = Math.min(todayDate, endDate);

		let timeRegs;
		if (projectGroupData) {
			const {project, projectIds} = projectGroupData || {};
			const projectId = project?.id;

			timeRegs = projectId
				? DataManager.searchTimeRegsSearchTree(
						pageComponent,
						personId,
						projectId,
						startDate,
						searchEndDate,
						personTimeRegSearchTreeMap
				  )
				: [];

			if (projectIds) {
				for (const projectId of projectIds) {
					timeRegs = timeRegs.concat(
						DataManager.searchTimeRegsSearchTree(
							pageComponent,
							personId,
							projectId,
							startDate,
							searchEndDate,
							personTimeRegSearchTreeMap
						)
					);
				}
			}
		} else {
			timeRegs = DataManager.searchTimeRegsSearchTree(
				pageComponent,
				personId,
				null,
				startDate,
				searchEndDate,
				personTimeRegSearchTreeMap
			);
		}

		if (timeRegs.length > 0) {
			const {schedulingOptions} = pageComponent.state;
			const {dayData} = pageComponent.props;
			const {company} = pageComponent.getFilterData();
			const isCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
			const hasPtoInverted = hasFeatureFlag('inverted_pto_non_working_days');
			const {nonWorkingDays} = calculationMetaData;

			const timeOffMinutesMap = !projectGroupData ? new Map() : null;
			for (const timeReg of timeRegs) {
				[minutesAllocated, distributionMap] = processTimeRegNew(
					pageComponent,
					timeReg,
					minutesAllocated,
					distributionMap,
					startDate,
					endDate,
					todayDate,
					isCombinedMode,
					timeOffMinutesMap,
					nonWorkingDays,
					hasPtoInverted
				);
			}

			if (!projectGroupData) {
				for (const [day, minutesRegistered] of timeOffMinutesMap.entries()) {
					minutesAvailable -= Math.min(minutesRegistered, personWorkingMinutes[dayData[day].isoWeekday - 1]);
				}
			}
		}
	}

	return [minutesAllocated, minutesAvailable, distributionMap];
};

const getNoAccessHeatmapStepData = (
	pageComponent,
	disableHeatmapFetchFeatureNoAccess,
	data,
	heatmapData,
	distributionMap,
	minutesAvailableForDay,
	personWorkingMinutes,
	dayData,
	minutesAllocated,
	minutesAvailable,
	plannedTotalMinutesSoft,
	plannedTotalMinutesSoftWin,
	plannedTotalMinutesHard,
	taskMinutesAllocated,
	minutesAllocatedVariations,
	anonymizedId = undefined
) => {
	const {schedulingOptions} = pageComponent.state;
	const {company} = data;
	const isInCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
	const isInActualMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.TASK_ACTUAL);
	const isInCombinedActualMode = isInCombinedMode && getCombinedTaskMode(company) === VISUALIZATION_MODE.TASK_ACTUAL;

	const [
		,
		,
		// startDate
		// endDate
		heatmapDataMinutesAllocated,
		heatmapDataMinutesTimeOff,
		// If noAccessHeatmapData is from resource-service and isUsingProjectAllocation=false, everything after this is undefined
		minutesAllocatedSoftDelta,
		minutesAllocatedSoftWinDelta,
		minutesAllocatedHardDelta,
		timeRegMinutes,
		// If noAccessHeatmapData is from resource-service and isUsingProjectAllocation=true, everything after this is undefined
		minutesPlannedSoftDelta,
		minutesPlannedSoftWinDelta,
		minutesPlannedHardDelta,
		heatmapTaskMinutesAllocated,
		plannedTaskMinutes,
	] = heatmapData;

	const actualMinutes = isInActualMode || isInCombinedActualMode ? timeRegMinutes || 0 : 0;
	const taskMinutesAllocatedDelta = heatmapTaskMinutesAllocated + actualMinutes;
	const useTaskMinutes = taskMinutesAllocatedDelta > heatmapDataMinutesAllocated;
	const noAccessAllocated = disableHeatmapFetchFeatureNoAccess
		? heatmapDataMinutesAllocated + heatmapTaskMinutesAllocated + actualMinutes
		: heatmapDataMinutesAllocated;
	const plannedHardDeltaTaskOrAllocation = useTaskMinutes ? taskMinutesAllocatedDelta : minutesAllocatedHardDelta;
	const minutes = plannedHardDeltaTaskOrAllocation;
	minutesAllocated += noAccessAllocated;

	if (minutesAllocatedVariations && isInCombinedMode) {
		addMinutesAllocatedVariations(minutesAllocatedVariations, {
			plannedTotalMinutesHard: minutesAllocatedHardDelta,
			plannedTotalMinutesSoft: minutesAllocatedSoftDelta,
			plannedTotalMinutesSoftWin: minutesAllocatedSoftWinDelta,
			taskMinutesAllocated: taskMinutesAllocatedDelta,
		});
	} else {
		plannedTotalMinutesSoft += useTaskMinutes ? 0 : minutesAllocatedSoftDelta;
		plannedTotalMinutesSoftWin += useTaskMinutes ? 0 : minutesAllocatedSoftWinDelta;
		plannedTotalMinutesHard += plannedHardDeltaTaskOrAllocation;
	}

	if (isAllocatedHoursNaN(minutesAllocatedHardDelta)) {
		addAllocationMinutesToMap(distributionMap, NO_ACCESS_DISTRIBUTION_MAP_ID, noAccessAllocated);
	} else {
		const distributionMapId = isInCombinedMode
			? NO_ACCESS_DISTRIBUTION_UNIQUE(anonymizedId)
			: NO_ACCESS_DISTRIBUTION_MAP_ID;
		addToMap(distributionMap, distributionMapId, item => {
			item.minutes += minutes + minutesAllocatedSoftDelta;
			item.minutesWin += minutes + minutesAllocatedSoftWinDelta;
			item.minutesHard += minutes;
			item.minutesSoft += minutesAllocatedSoftDelta;
			item.minutesSoftWin += minutesAllocatedSoftWinDelta;
			item.allocationMinutes += minutesAllocatedHardDelta + minutesAllocatedSoftDelta;
			item.actualTaskMinutes += heatmapTaskMinutesAllocated;
			item.plannedAllocationMinutes += minutesPlannedHardDelta + minutesPlannedSoftDelta;
			item.plannedAllocationMinutesSoft += minutesPlannedSoftDelta;
			item.plannedAllocationMinutesSoftWin += minutesPlannedSoftWinDelta;
			item.plannedAllocationMinutesWin += minutesPlannedHardDelta + minutesPlannedSoftWinDelta;
			item.plannedAllocationMinutesHard += minutesPlannedHardDelta;
			item.timeRegMinutes += timeRegMinutes;
			item.plannedTaskMinutes += plannedTaskMinutes;
		});
	}

	// Subtract timeoff from available. Limit to minutesAvailableForDay.
	minutesAvailable -= Math.min(heatmapDataMinutesTimeOff, minutesAvailableForDay);

	return [
		minutesAllocated,
		minutesAvailable,
		plannedTotalMinutesSoft,
		plannedTotalMinutesSoftWin,
		plannedTotalMinutesHard,
		taskMinutesAllocated,
		minutesAllocatedVariations,
		distributionMap,
	];
};

export const getNoAccessHeatmapData = (
	pageComponent,
	data,
	personId,
	distributionMap,
	startDate,
	endDate,
	minutesAllocated,
	minutesAvailable,
	holidaysIncludingDaysOff,
	personWorkingMinutes,
	dayData,
	plannedTotalMinutesSoft,
	plannedTotalMinutesSoftWin,
	plannedTotalMinutesHard,
	taskMinutesAllocated,
	minutesAllocatedVariations
) => {
	const {schedulingView} = pageComponent.props;
	const {noAccessHeatMaps, company} = data;

	const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(company);
	const isFrontendSupportedPage =
		schedulingView === SCHEDULING_VIEW.PEOPLE ||
		schedulingView === SCHEDULING_VIEW.PLACEHOLDERS ||
		schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;
	const hasCorrectFeatureFlags =
		hasFeatureFlag('people_scheduling_disable_heatmap_fetch') &&
		hasFeatureFlag('people_scheduling_disable_heatmap_fetch_no_access');
	const disableHeatmapFetchFeatureNoAccess =
		isFrontendSupportedPage && (isMixedAllocationModeEnabled || hasCorrectFeatureFlags);

	let personNoAccessHeatmapData;
	if (disableHeatmapFetchFeatureNoAccess) {
		personNoAccessHeatmapData = noAccessHeatMaps.get(personId);
		if (!personNoAccessHeatmapData) {
			personNoAccessHeatmapData = new Map();
			noAccessHeatMaps.set(personId, personNoAccessHeatmapData);
		}
	} else {
		personNoAccessHeatmapData = noAccessHeatMaps[personId];
		if (!personNoAccessHeatmapData) {
			personNoAccessHeatmapData = [];
			noAccessHeatMaps[personId] = personNoAccessHeatmapData;
		}
	}

	let relevantDataRange;
	for (let i = startDate; i <= endDate; i++) {
		// Calc the persons minutes available for this day
		const minutesAvailableForDay = holidaysIncludingDaysOff.includes(i)
			? 0
			: personWorkingMinutes[dayData[i].isoWeekday - 1];

		if (disableHeatmapFetchFeatureNoAccess) {
			const personProjectsHeatmapData = personNoAccessHeatmapData.get(i);

			if (personProjectsHeatmapData) {
				for (const [anonymizedId, projectHeatmapData] of personProjectsHeatmapData) {
					[
						minutesAllocated,
						minutesAvailable,
						plannedTotalMinutesSoft,
						plannedTotalMinutesSoftWin,
						plannedTotalMinutesHard,
						taskMinutesAllocated,
						minutesAllocatedVariations,
						distributionMap,
					] = getNoAccessHeatmapStepData(
						pageComponent,
						disableHeatmapFetchFeatureNoAccess,
						data,
						projectHeatmapData,
						distributionMap,
						minutesAvailableForDay,
						personWorkingMinutes,
						dayData,
						minutesAllocated,
						minutesAvailable,
						plannedTotalMinutesSoft,
						plannedTotalMinutesSoftWin,
						plannedTotalMinutesHard,
						taskMinutesAllocated,
						minutesAllocatedVariations,
						anonymizedId
					);
				}
			}
		} else {
			// If we dont have a relevant date range yet, or the current date range's enddate is before our loop date "i"
			if (!relevantDataRange || (relevantDataRange[1] !== null && relevantDataRange[1] < i)) {
				// Find new relevant date range
				relevantDataRange = personNoAccessHeatmapData.find(
					dataArray =>
						(dataArray[1] >= i && dataArray[0] === null) ||
						(dataArray[1] >= i && dataArray[0] <= i) ||
						(dataArray[1] === null && dataArray[0] <= i)
				);
			}
			if (relevantDataRange) {
				[
					minutesAllocated,
					minutesAvailable,
					plannedTotalMinutesSoft,
					plannedTotalMinutesSoftWin,
					plannedTotalMinutesHard,
					taskMinutesAllocated,
					minutesAllocatedVariations,
					distributionMap,
				] = getNoAccessHeatmapStepData(
					pageComponent,
					disableHeatmapFetchFeatureNoAccess,
					data,
					relevantDataRange,
					distributionMap,
					minutesAvailableForDay,
					personWorkingMinutes,
					dayData,
					minutesAllocated,
					minutesAvailable,
					plannedTotalMinutesSoft,
					plannedTotalMinutesSoftWin,
					plannedTotalMinutesHard,
					taskMinutesAllocated,
					minutesAllocatedVariations
				);
			}
		}
	}

	return [
		minutesAllocated,
		minutesAvailable,
		plannedTotalMinutesSoft,
		plannedTotalMinutesSoftWin,
		plannedTotalMinutesHard,
		taskMinutesAllocated,
		minutesAllocatedVariations,
		distributionMap,
	];
};

export const getIdleTimeHeatmapData = (
	idleTimeDataMap,
	distributionMap,
	minutesAllocated,
	plannedTotalMinutesHard,
	minutesAvailable,
	holidaysIncludingDaysOff,
	personWorkingMinutes,
	dayData,
	isInActualMode,
	todayDate,
	nonWorkingDaysMap,
	personId
) => {
	const hasInvertedPtoNonWorkingDays = hasFeatureFlag('inverted_pto_non_working_days');
	let totalIdleTimeMinutes = 0;
	// Looping through idleTimeDataMap to calc minutes allocated,
	// add them to the distribution map, and to subtract from available time.
	for (const day of idleTimeDataMap.keys()) {
		const mapById = idleTimeDataMap.get(day).reduce((acc, cur) => {
			if (acc.has(cur.id)) {
				const idleTime = acc.get(cur.id);
				idleTime.idleTimeMinutesRegistered += cur.idleTimeMinutesRegistered;
				idleTime.idleTimeMinutesAllocated += cur.idleTimeMinutesAllocated;
				idleTime.timeOffMinutesRegistered += cur.timeOffMinutesRegistered;
				idleTime.timeOffMinutesAllocated += cur.timeOffMinutesAllocated;
				acc.set(cur.id, idleTime);
			} else {
				acc.set(cur.id, cur);
			}

			return acc;
		}, new Map());

		// Check if this day is a holiday/day off
		let isDayOff;
		if (hasInvertedPtoNonWorkingDays) {
			isDayOff = holidaysIncludingDaysOff.includes(day);
		} else {
			isDayOff = isPersonDayOff(day, personId, nonWorkingDaysMap);
		}

		// Calc the persons minutes available for this day
		const minutesAvailableForDay = isDayOff ? 0 : personWorkingMinutes[dayData[day].isoWeekday - 1];

		let totalTimeOffForDay = 0;
		for (const idleTime of mapById.values()) {
			const {idleTimeMinutesRegistered, idleTimeMinutesAllocated, timeOffMinutesRegistered, timeOffMinutesAllocated, id} =
				idleTime;

			const useTimeReg = isInActualMode && day < todayDate;

			let _idleTimeMinutesAllocated = 0;
			if (!isDayOff || hasInvertedPtoNonWorkingDays) {
				_idleTimeMinutesAllocated = useTimeReg ? idleTimeMinutesRegistered : idleTimeMinutesAllocated;
			}
			minutesAllocated += _idleTimeMinutesAllocated;
			plannedTotalMinutesHard += _idleTimeMinutesAllocated;
			totalIdleTimeMinutes += _idleTimeMinutesAllocated;

			const _timeOffMinutesAllocated = useTimeReg ? timeOffMinutesRegistered : timeOffMinutesAllocated;

			if (_idleTimeMinutesAllocated || _idleTimeMinutesAllocated === 0) {
				addAllocationMinutesToMap(distributionMap, id, _idleTimeMinutesAllocated, idleTimeMinutesAllocated);
			}
			if (_timeOffMinutesAllocated) {
				addAllocationMinutesToMap(distributionMap, id, _timeOffMinutesAllocated);
			}

			totalTimeOffForDay += _timeOffMinutesAllocated;
		}

		// External idle time subtracts from available time
		minutesAvailable -= Math.min(minutesAvailableForDay, totalTimeOffForDay);
	}

	return [minutesAllocated, plannedTotalMinutesHard, minutesAvailable, totalIdleTimeMinutes, distributionMap];
};

export const getAllocationHeatmapData = (
	schedulingOptions,
	data,
	visibleItemsData,
	startDate,
	endDate,
	minutesAllocated,
	plannedTotalMinutesHard,
	plannedTotalMinutesSoft,
	plannedTotalMinutesSoftWin
) => {
	const {company, timeRegsByTaskMap, projectMap, nonWorkingDaysMap} = data;
	const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);
	const isUsingCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

	const useProjectAllocations = isUsingProjectAllocation || isUsingCombinedMode;

	const distributionMap = new Map();
	const idleTimeDataMap = new Map();
	const taskItemMap = new Map();

	const projectAllocationItems = visibleItemsData.allocationItems || [];
	const placeholderAllocationItems = visibleItemsData.placeholderAllocationItems || [];
	const taskItems = visibleItemsData.taskItems || [];

	const allocationItems = [...projectAllocationItems, ...placeholderAllocationItems, ...taskItems];

	for (let i = 0; i < allocationItems.length; i++) {
		const item = allocationItems[i];

		const isIdleTimeAllocation = item.data?.allocation?.idleTimeId;

		if (isProjectAllocationItem(item) && isIdleTimeAllocation) {
			const allocation = item.data.allocation;

			if (!item.data.isHoliday) {
				//Need to loop through every single day of allocation even if not on day view
				let idleTimeAllocationLoopDate = Math.max(startDate, item.startDate);
				const idleTimeAllocationLoopEndDate = Math.min(endDate, item.endDate);

				while (idleTimeAllocationLoopDate <= idleTimeAllocationLoopEndDate) {
					const allocationMinutes = getMinutesInWeekdays(
						allocation,
						getWeekdaysBetweenDates(
							idleTimeAllocationLoopDate,
							idleTimeAllocationLoopDate,
							null,
							null,
							null,
							nonWorkingDaysMap,
							allocation.personId
						)
					);

					//If there is something on this day already, update values, otherwise insert new value
					if (!idleTimeDataMap.has(idleTimeAllocationLoopDate)) {
						idleTimeDataMap.set(idleTimeAllocationLoopDate, []);
					}

					idleTimeDataMap.get(idleTimeAllocationLoopDate).push({
						id: allocation.idleTimeId,
						idleTimeMinutesAllocated: allocation.isIdleTimeInternal ? allocationMinutes : 0,
						timeOffMinutesAllocated: allocation.isIdleTimeInternal ? 0 : allocationMinutes,
						idleTimeMinutesRegistered: 0,
						timeOffMinutesRegistered: 0,
					});

					idleTimeAllocationLoopDate++;
				}
			}
		} else if (useProjectAllocations && (isProjectAllocationItem(item) || isPlaceholderAllocationItem(item))) {
			const allocation = item.data?.allocation || item.data?.placeholderAllocation;

			const weekdaysInPeriod = getWeekdaysBetweenDates(
				Math.max(startDate, item.startDate),
				Math.min(endDate, item.endDate),
				null,
				null,
				null,
				nonWorkingDaysMap,
				allocation.personId
			);

			let allocationMinutes = getMinutesInWeekdays(allocation, weekdaysInPeriod);

			const distributionMapId = allocation.projectGroupId || allocation.projectId;
			if (allocation.isSoft || (isPlaceholderAllocationItem(item) && allocation.isSoft === undefined)) {
				plannedTotalMinutesSoft += allocationMinutes;

				let projectId = allocation.projectId;

				if (!projectId && isPlaceholderAllocationItem(item)) {
					projectId = item.data.placeholder.projectId;
				}

				const project = projectMap.get(projectId);
				const winPercentage = project ? project.baselineWinChance : 1;
				const plannedTotalMinutesSoftWinDelta = allocationMinutes * winPercentage;
				plannedTotalMinutesSoftWin += plannedTotalMinutesSoftWinDelta;
				addToMap(distributionMap, distributionMapId, item => {
					item.minutes += allocationMinutes;
					item.minutesWin += plannedTotalMinutesSoftWinDelta;
					item.minutesSoft += allocationMinutes;
					item.minutesSoftWin += plannedTotalMinutesSoftWinDelta;
				});
			} else {
				plannedTotalMinutesHard += allocationMinutes;
				addAllocationMinutesToMap(distributionMap, distributionMapId, allocationMinutes);
			}
			minutesAllocated += allocationMinutes;
		} else if (isTaskItem(item) && (!isUsingProjectAllocation || isUsingCombinedMode)) {
			const task = item.data.task;

			if (task) {
				if (taskItemMap.has(task.id)) {
					taskItemMap.get(task.id).taskItem = item;
				} else {
					taskItemMap.set(task.id, {
						taskItem: item,
						timeRegistrationItems: timeRegsByTaskMap[task.id] || [],
					});
				}
			}
		}
	}

	return [
		minutesAllocated,
		plannedTotalMinutesHard,
		plannedTotalMinutesSoft,
		plannedTotalMinutesSoftWin,
		distributionMap,
		idleTimeDataMap,
		taskItemMap,
	];
};

const addAllocationAllocatedMinutes = (
	item,
	startDate,
	endDate,
	todayDate,
	nonWorkingDaysMap,
	distributionMap,
	projectMap,
	minutesAllocated,
	plannedTotalMinutesHard,
	plannedTotalMinutesSoft,
	plannedTotalMinutesSoftWin,
	isPlaceholderAllocation = false,
	isInCombinedActualMode = false
) => {
	const allocation = isPlaceholderAllocation ? item.data.placeholderAllocation : item.data.allocation;
	if (!isTimeOffAllocation(allocation) || hasFeatureFlag('inverted_pto_non_working_days')) {
		let calculationStartDate = Math.max(startDate, item.startDate);
		const calculationEndDate = Math.min(endDate, item.endDate);
		if (isInCombinedActualMode && todayDate > calculationStartDate) {
			calculationStartDate = todayDate;
		}

		const weekdaysInPeriod = getWeekdaysBetweenDatesNew(
			calculationStartDate,
			calculationEndDate,
			nonWorkingDaysMap,
			allocation.personId
		);

		const plannedWeekdaysInPeriod = getWeekdaysBetweenDatesNew(
			Math.max(startDate, item.startDate),
			calculationEndDate,
			nonWorkingDaysMap,
			allocation.personId
		);

		let allocationMinutes = getMinutesInWeekdaysNew(allocation, weekdaysInPeriod);
		let plannedAllocationMinutes = getMinutesInWeekdaysNew(allocation, plannedWeekdaysInPeriod);

		const distributionMapId = allocation.projectGroupId || allocation.projectId;
		if (allocation.isSoft || isPlaceholderAllocation) {
			plannedTotalMinutesSoft += allocationMinutes;

			let projectId = allocation.projectId;

			if (!projectId && isPlaceholderAllocation) {
				projectId = item.data.placeholder.projectId;
			}

			const project = projectMap.get(projectId);
			const winPercentage = project ? project.baselineWinChance : 1;
			const plannedTotalMinutesSoftWinDelta = allocationMinutes * winPercentage;

			plannedTotalMinutesSoftWin += plannedTotalMinutesSoftWinDelta;

			if (distributionMap) {
				addToMap(distributionMap, distributionMapId, item => {
					item.minutes += allocationMinutes;
					item.minutesWin += plannedTotalMinutesSoftWinDelta;
					item.minutesSoft += allocationMinutes;
					item.minutesSoftWin += plannedTotalMinutesSoftWinDelta;
					item.allocationMinutes += allocationMinutes;
					item.plannedAllocationMinutes += plannedAllocationMinutes;
					item.plannedAllocationMinutesSoft += plannedAllocationMinutes;
					item.plannedAllocationMinutesWin += plannedAllocationMinutes * winPercentage;
					item.plannedAllocationMinutesSoftWin += plannedAllocationMinutes * winPercentage;
				});
			}
		} else {
			plannedTotalMinutesHard += allocationMinutes;

			if (distributionMap) {
				addAllocationMinutesToMap(distributionMap, distributionMapId, allocationMinutes, plannedAllocationMinutes);
			}
		}

		minutesAllocated += allocationMinutes;

		return [minutesAllocated, plannedTotalMinutesHard, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin];
	}
};

const addIdleTimeData = (item, startDate, endDate, idleTimeDataMap) => {
	const {allocation} = item.data;

	// Need to loop through every single day of allocation even if not on day view
	let idleTimeAllocationLoopDate = Math.max(startDate, item.startDate);
	const idleTimeAllocationLoopEndDate = Math.min(endDate, item.endDate);

	while (idleTimeAllocationLoopDate <= idleTimeAllocationLoopEndDate) {
		const allocationMinutes = getMinutesInWeekdaysNew(
			allocation,
			getWeekdaysBetweenDatesNew(idleTimeAllocationLoopDate, idleTimeAllocationLoopDate)
		);

		//If there is something on this day already, update values, otherwise insert new value
		let idleTimeDateValue = idleTimeDataMap.get(idleTimeAllocationLoopDate);
		if (!idleTimeDateValue) {
			idleTimeDateValue = [];
			idleTimeDataMap.set(idleTimeAllocationLoopDate, idleTimeDateValue);
		}

		idleTimeDateValue.push({
			id: allocation.idleTimeId,
			idleTimeMinutesAllocated: allocation.isIdleTimeInternal ? allocationMinutes : 0,
			timeOffMinutesAllocated: allocation.isIdleTimeInternal ? 0 : allocationMinutes,
			idleTimeMinutesRegistered: 0,
			timeOffMinutesRegistered: 0,
		});

		idleTimeAllocationLoopDate++;
	}
};

export const getAllocationHeatmapDataNew = (
	schedulingOptions,
	data,
	visibleItemsData,
	startDate,
	endDate,
	todayDate,
	minutesAllocated,
	plannedTotalMinutesHard,
	plannedTotalMinutesSoft,
	plannedTotalMinutesSoftWin,
	idleTimeDataMap,
	distributionMap
) => {
	const {company, projectMap, nonWorkingDaysMap} = data;

	const isUsingCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
	const isInCombinedActualMode = isUsingCombinedMode && getCombinedTaskMode(company) === VISUALIZATION_MODE.TASK_ACTUAL;
	const includeProjectAllocations =
		isUsingCombinedMode ||
		getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION) ||
		!hasFeatureFlag('combined_heatmap_logic_extensions');

	const projectAllocationItems = visibleItemsData.allocationItems || [];
	const placeholderAllocationItems = visibleItemsData.placeholderAllocationItems || [];

	// Project Allocations
	if (projectAllocationItems.length > 0) {
		for (const item of projectAllocationItems) {
			const {allocation, isHoliday} = item.data;

			if (!allocation) {
				continue;
			}

			if (!isItemWithinStep(item, startDate, endDate)) {
				continue;
			}

			if (allocation.idleTimeId && !isHoliday) {
				addIdleTimeData(item, startDate, endDate, idleTimeDataMap);
			}

			if (!allocation.idleTimeId && includeProjectAllocations) {
				[minutesAllocated, plannedTotalMinutesHard, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin] =
					addAllocationAllocatedMinutes(
						item,
						startDate,
						endDate,
						todayDate,
						nonWorkingDaysMap,
						distributionMap,
						projectMap,
						minutesAllocated,
						plannedTotalMinutesHard,
						plannedTotalMinutesSoft,
						plannedTotalMinutesSoftWin,
						false,
						isInCombinedActualMode
					);
			}
		}
	}

	// Placeholder Allocations
	if (placeholderAllocationItems.length > 0) {
		for (let i = 0; i < placeholderAllocationItems.length; i++) {
			const item = placeholderAllocationItems[i];
			const {placeholderAllocation, placeholder} = item.data;

			if (!placeholderAllocation || !placeholder) {
				continue;
			}

			if (!isItemWithinStep(item, startDate, endDate)) {
				continue;
			}

			[minutesAllocated, plannedTotalMinutesHard, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin] =
				addAllocationAllocatedMinutes(
					item,
					startDate,
					endDate,
					todayDate,
					null,
					distributionMap,
					projectMap,
					minutesAllocated,
					plannedTotalMinutesHard,
					plannedTotalMinutesSoft,
					plannedTotalMinutesSoftWin,
					true
				);
		}
	}

	return [minutesAllocated, plannedTotalMinutesHard, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin];
};

export const getPersonHeatmapFromData = (
	pageComponent,
	personHeatMapData,
	holidaysIncludingDaysOff,
	personWorkingMinutes,
	dayData,
	minutesAllocated,
	minutesAvailable,
	overallStartDate,
	overallEndDate,
	personGroupData,
	plannedTotalMinutesSoft,
	plannedTotalMinutesSoftWin,
	plannedTotalMinutesHard,
	nonWorkingDaysMap,
	personId
) => {
	let heatmapDataStartDate,
		heatmapDataEndDate = null,
		heatmapDataMinutesAllocated,
		heatmapDataMinutesTimeOff,
		plannedTotalMinutesSoftDelta,
		plannedTotalMinutesSoftWinDelta,
		plannedTotalMinutesHardDelta,
		taskMinutesDelta,
		combinedMinutesDelta,
		combinedMinutesWinDelta,
		combinedMinutesSoftDelta,
		combinedMinutesSoftWinDelta,
		combinedMinutesHardDelta;
	let taskMinutes = 0;
	let combinedMinutes = 0;
	let combinedMinutesWin = 0;
	let combinedMinutesSoft = 0;
	let combinedMinutesSoftWin = 0;
	let combinedMinutesHard = 0;

	const startDate = personGroupData.startDate ? Math.max(overallStartDate, personGroupData.startDate) : overallStartDate;
	const endDate = personGroupData.endDate ? Math.min(overallEndDate, personGroupData.endDate) : overallEndDate;

	const hasInvertedPtoNonWorkingDays = hasFeatureFlag('inverted_pto_non_working_days');

	for (let i = startDate; i <= endDate; i++) {
		let isDayOff;
		if (hasInvertedPtoNonWorkingDays) {
			isDayOff = holidaysIncludingDaysOff.includes(i);
		} else {
			isDayOff = isPersonDayOff(i, personId, nonWorkingDaysMap);
		}

		// If we dont have a relevant date range yet, or the current date range's endDate is before our loop date "i"
		if ((!heatmapDataStartDate && !heatmapDataEndDate) || (heatmapDataEndDate !== null && heatmapDataEndDate < i)) {
			// Find new relevant date range
			const foundHeatMap = personHeatMapData.find(
				dataArray =>
					(dataArray[1] >= i && dataArray[0] === null) ||
					(dataArray[1] >= i && dataArray[0] <= i) ||
					(dataArray[1] === null && dataArray[0] <= i)
			);

			if (foundHeatMap) {
				[
					heatmapDataStartDate,
					heatmapDataEndDate,
					heatmapDataMinutesAllocated,
					heatmapDataMinutesTimeOff,
					plannedTotalMinutesSoftDelta,
					plannedTotalMinutesSoftWinDelta,
					plannedTotalMinutesHardDelta,
					,
					//actualTotalMinutesDelta
					taskMinutesDelta,
					combinedMinutesDelta,
					combinedMinutesWinDelta,
					combinedMinutesSoftDelta,
					combinedMinutesSoftWinDelta,
					combinedMinutesHardDelta,
				] = foundHeatMap;
			}
		}

		if (heatmapDataStartDate || heatmapDataEndDate) {
			const isWorkingDay = !isDayOff || hasInvertedPtoNonWorkingDays;
			if (isWorkingDay) {
				minutesAllocated += heatmapDataMinutesAllocated;
				plannedTotalMinutesSoft += plannedTotalMinutesSoftDelta;
				plannedTotalMinutesSoftWin += plannedTotalMinutesSoftWinDelta;
				plannedTotalMinutesHard += plannedTotalMinutesHardDelta;
			}

			taskMinutes += taskMinutesDelta;
			combinedMinutes += combinedMinutesDelta;
			combinedMinutesWin += combinedMinutesWinDelta;
			combinedMinutesSoft += combinedMinutesSoftDelta;
			combinedMinutesSoftWin += combinedMinutesSoftWinDelta;
			combinedMinutesHard += combinedMinutesHardDelta;

			// Calc the persons minutes available for this day
			const minutesAvailableForDay = isDayOff ? 0 : personWorkingMinutes[dayData[i].isoWeekday - 1];

			// Subtract time off from available. Limit to minutesAvailableForDay.
			minutesAvailable -= Math.min(heatmapDataMinutesTimeOff, minutesAvailableForDay);
		}
	}

	return [
		minutesAllocated,
		minutesAvailable,
		plannedTotalMinutesSoft,
		plannedTotalMinutesSoftWin,
		plannedTotalMinutesHard,
		taskMinutes,
		combinedMinutes,
		combinedMinutesWin,
		combinedMinutesSoft,
		combinedMinutesSoftWin,
		combinedMinutesHard,
	];
};

export const getPlaceholderHeatmapFromData = (
	heatMapData,
	startDate,
	endDate,
	minutesAllocated,
	plannedTotalMinutesSoft,
	plannedTotalMinutesSoftWin
) => {
	let heatmapDataStartDate,
		heatmapDataEndDate = null,
		heatmapDataMinutesAllocated;
	let plannedTotalMinutesWinDelta;

	for (let i = startDate; i <= endDate; i++) {
		// If we dont have a relevant date range yet, or the current date range's endDate is before our loop date "i"
		if ((!heatmapDataStartDate && !heatmapDataEndDate) || (heatmapDataEndDate !== null && heatmapDataEndDate < i)) {
			// Find new relevant date range
			const foundHeatMap = heatMapData.find(
				dataArray =>
					(dataArray[1] >= i && dataArray[0] === null) ||
					(dataArray[1] >= i && dataArray[0] <= i) ||
					(dataArray[1] === null && dataArray[0] <= i)
			);

			if (foundHeatMap) {
				[heatmapDataStartDate, heatmapDataEndDate, heatmapDataMinutesAllocated, plannedTotalMinutesWinDelta] =
					foundHeatMap;
			}
		}

		if (heatmapDataStartDate || heatmapDataEndDate) {
			minutesAllocated += heatmapDataMinutesAllocated;
			plannedTotalMinutesSoft += heatmapDataMinutesAllocated;
			plannedTotalMinutesSoftWin += plannedTotalMinutesWinDelta;
		}
	}
	return [minutesAllocated, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin];
};

function calculateHeatmapFromItems(
	pageComponent,
	group,
	startDate,
	endDate,
	visibleItemsData,
	calculationMetaData,
	holidaysIncludingDaysOff,
	holidaysExcludingDaysOff,
	personWorkingMinutes,
	minutesAvailable,
	minutesAllocated,
	plannedTotalMinutesHard,
	plannedTotalMinutesSoft,
	plannedTotalMinutesSoftWin,
	noAccessMinutesAllocatedVariations
) {
	const {isProjectTimeline} = pageComponent.props;
	const {data, todayDate, schedulingOptions} = pageComponent.state;
	const {nonWorkingDaysMap} = data;
	const {dayData} = pageComponent.props;

	let isInActualMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.TASK_ACTUAL);
	let isInPlanMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.TASK_PLAN);
	const isUsingCombinedMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);
	const combinedTaskMode = isUsingCombinedMode && getCombinedTaskMode(data.company);

	const isPersonGroup = group.groupType === GROUP_TYPE.PERSON;
	const personGroupData = isPersonGroup ? group.data : group.parentGroup?.data;
	const personId = personGroupData.personId || personGroupData.id;

	const projectGroup = group.groupType === GROUP_TYPE.PROJECT ? group : null;
	const projectGroupData = projectGroup?.data;

	let distributionMap = new Map();
	let idleTimeDataMap = new Map();

	let taskMinutesAllocated = 0;
	let timeRegMinutes = 0;
	let idleTimeMinutesAllocated = 0;

	const hasProjectAllocations = visibleItemsData.allocationItems?.length > 0;
	const hasPlaceholderAllocationItems = visibleItemsData.placeholderAllocationItems?.length > 0;

	if (hasProjectAllocations || hasPlaceholderAllocationItems) {
		[minutesAllocated, plannedTotalMinutesHard, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin] =
			getAllocationHeatmapDataNew(
				schedulingOptions,
				data,
				visibleItemsData,
				startDate,
				endDate,
				todayDate,
				minutesAllocated,
				plannedTotalMinutesHard,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin,
				idleTimeDataMap,
				distributionMap
			);
	}

	// Task modes
	if (isInActualMode) {
		[minutesAllocated, minutesAvailable, distributionMap] = getTimeRegistrationHeatmapData(
			pageComponent,
			personId,
			null,
			distributionMap,
			startDate,
			endDate,
			todayDate,
			minutesAllocated,
			minutesAvailable,
			personWorkingMinutes
		);

		[minutesAllocated, distributionMap] = getTaskHeatmapDataActualMode(
			minutesAllocated,
			visibleItemsData,
			distributionMap,
			holidaysExcludingDaysOff,
			startDate,
			endDate,
			todayDate,
			personWorkingMinutes,
			nonWorkingDaysMap,
			personGroupData.person,
			isUsingCombinedMode
		);
	} else if (isInPlanMode) {
		[minutesAllocated, distributionMap] = getTaskHeatmapData(
			minutesAllocated,
			personGroupData,
			visibleItemsData,
			distributionMap,
			startDate,
			endDate,
			personWorkingMinutes,
			holidaysExcludingDaysOff,
			nonWorkingDaysMap,
			isUsingCombinedMode
		);
	}

	// Combined mode
	if (isUsingCombinedMode) {
		if (combinedTaskMode === VISUALIZATION_MODE.TASK_ACTUAL) {
			const hasCombinedPerformanceImprovements = hasFeatureFlag('combined_mode_performance_improvements');

			if (hasCombinedPerformanceImprovements) {
				[timeRegMinutes, minutesAvailable, distributionMap] = getPersonTimeRegistrationHeatmapData(
					pageComponent,
					personId,
					projectGroupData,
					calculationMetaData,
					distributionMap,
					startDate,
					endDate,
					todayDate,
					timeRegMinutes,
					minutesAvailable,
					personWorkingMinutes
				);
			} else {
				[timeRegMinutes, minutesAvailable, distributionMap] = getTimeRegistrationHeatmapData(
					pageComponent,
					personId,
					projectGroupData,
					distributionMap,
					startDate,
					endDate,
					todayDate,
					timeRegMinutes,
					minutesAvailable,
					personWorkingMinutes
				);
			}

			if (hasCombinedPerformanceImprovements) {
				[taskMinutesAllocated, distributionMap] = getTaskHeatmapDataCombinedActualMode(
					taskMinutesAllocated,
					visibleItemsData,
					distributionMap,
					holidaysExcludingDaysOff,
					startDate,
					endDate,
					todayDate,
					personWorkingMinutes,
					nonWorkingDaysMap,
					personGroupData.person
				);
			} else {
				[taskMinutesAllocated, distributionMap] = getTaskHeatmapDataActualMode(
					taskMinutesAllocated,
					visibleItemsData,
					distributionMap,
					holidaysExcludingDaysOff,
					startDate,
					endDate,
					todayDate,
					personWorkingMinutes,
					nonWorkingDaysMap,
					personGroupData.person,
					isUsingCombinedMode
				);

				// Just for planned task distributionMap
				updatePlannedTaskDistributionMap(
					personGroupData,
					visibleItemsData,
					distributionMap,
					startDate,
					endDate,
					personWorkingMinutes,
					holidaysExcludingDaysOff,
					nonWorkingDaysMap,
					isUsingCombinedMode
				);
			}
		} else {
			[taskMinutesAllocated, distributionMap] = getTaskHeatmapData(
				taskMinutesAllocated,
				personGroupData,
				visibleItemsData,
				distributionMap,
				startDate,
				endDate,
				personWorkingMinutes,
				holidaysExcludingDaysOff,
				nonWorkingDaysMap,
				isUsingCombinedMode
			);
		}
	}

	if (isPersonGroup) {
		[minutesAllocated, plannedTotalMinutesHard, minutesAvailable, idleTimeMinutesAllocated, distributionMap] =
			getIdleTimeHeatmapData(
				idleTimeDataMap,
				distributionMap,
				minutesAllocated,
				plannedTotalMinutesHard,
				minutesAvailable,
				holidaysIncludingDaysOff,
				personWorkingMinutes,
				dayData,
				isInActualMode || combinedTaskMode === VISUALIZATION_MODE.TASK_ACTUAL,
				todayDate,
				nonWorkingDaysMap,
				personId
			);

		if (!isProjectTimeline) {
			[
				minutesAllocated,
				minutesAvailable,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin,
				plannedTotalMinutesHard,
				taskMinutesAllocated,
				noAccessMinutesAllocatedVariations,
				distributionMap,
			] = getNoAccessHeatmapData(
				pageComponent,
				data,
				personGroupData.personId,
				distributionMap,
				startDate,
				endDate,
				minutesAllocated,
				minutesAvailable,
				holidaysIncludingDaysOff,
				personWorkingMinutes,
				dayData,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin,
				plannedTotalMinutesHard,
				taskMinutesAllocated,
				noAccessMinutesAllocatedVariations
			);
		}
	}

	// Available minutes cannot be less than zero
	minutesAvailable = Math.max(0, minutesAvailable);

	return [
		minutesAvailable,
		minutesAllocated,
		plannedTotalMinutesHard,
		plannedTotalMinutesSoft,
		plannedTotalMinutesSoftWin,
		distributionMap,
		taskMinutesAllocated,
		timeRegMinutes,
		idleTimeMinutesAllocated,
		noAccessMinutesAllocatedVariations,
	];
}

const calculateCombinedNumbers = (
	pageComponent,
	projectGroup,
	startDate,
	endDate,
	visibleItemsData,
	calculationMetaData,
	holidaysIncludingDaysOff,
	holidaysExcludingDaysOff,
	personWorkingMinutes,
	minutesAvailable,
	combinedMinutes,
	combinedMinutesWin,
	combinedMinutesSoft,
	combinedMinutesSoftWin,
	combinedMinutesHard,
	taskMinutes
) => {
	const {isProjectTimeline} = pageComponent.props;
	let distributionMap;
	let deltaMinutesAllocated = 0;
	let deltaPlannedTotalMinutesHard = 0;
	let deltaPlannedTotalMinutesSoft = 0;
	let deltaPlannedTotalMinutesSoftWin = 0;
	let deltaTaskMinutesAllocated = 0;
	let timeRegMinutes = 0;
	let idleTimeMinutesAllocated = 0;

	[
		minutesAvailable,
		,
		//deltaMinutesAllocated
		deltaPlannedTotalMinutesHard,
		deltaPlannedTotalMinutesSoft,
		deltaPlannedTotalMinutesSoftWin,
		distributionMap,
		deltaTaskMinutesAllocated,
		timeRegMinutes,
		idleTimeMinutesAllocated,
	] = calculateHeatmapFromItems(
		pageComponent,
		projectGroup,
		startDate,
		endDate,
		visibleItemsData,
		calculationMetaData,
		holidaysIncludingDaysOff,
		holidaysExcludingDaysOff,
		personWorkingMinutes,
		minutesAvailable,
		deltaMinutesAllocated,
		deltaPlannedTotalMinutesHard,
		deltaPlannedTotalMinutesSoft,
		deltaPlannedTotalMinutesSoftWin
	);

	const taskMinutesAllocated = timeRegMinutes + deltaTaskMinutesAllocated;
	const minutesAllocatedVariations = {};

	if (hasFeatureFlag('combined_mode_performance_improvements')) {
		addMinutesAllocatedVariationsNew(minutesAllocatedVariations, {
			plannedTotalMinutesHard: deltaPlannedTotalMinutesHard - idleTimeMinutesAllocated,
			plannedTotalMinutesSoft: deltaPlannedTotalMinutesSoft,
			plannedTotalMinutesSoftWin: deltaPlannedTotalMinutesSoftWin,
			taskMinutesAllocated,
			timeRegMinutes,
		});
	} else {
		addMinutesAllocatedVariations(minutesAllocatedVariations, {
			plannedTotalMinutesHard: deltaPlannedTotalMinutesHard - idleTimeMinutesAllocated,
			plannedTotalMinutesSoft: deltaPlannedTotalMinutesSoft,
			plannedTotalMinutesSoftWin: deltaPlannedTotalMinutesSoftWin,
			taskMinutesAllocated,
			timeRegMinutes,
		});
	}

	if (isProjectTimeline) {
		const minutesAllocatedVariationsFromData = initMinutesAllocatedVariations(
			combinedMinutes,
			combinedMinutesWin,
			combinedMinutesSoft,
			combinedMinutesSoftWin,
			combinedMinutesHard,
			taskMinutes
		);

		const minutesAllocatedVariationsFromIdleTime = {};
		addMinutesAllocatedVariations(minutesAllocatedVariationsFromIdleTime, {
			plannedTotalMinutesHard: idleTimeMinutesAllocated,
			plannedTotalMinutesSoft: 0,
			plannedTotalMinutesSoftWin: 0,
			taskMinutesAllocated: 0,
		});

		mergeMinutesAllocatedVariations(minutesAllocatedVariations, minutesAllocatedVariationsFromData);
		mergeMinutesAllocatedVariations(minutesAllocatedVariations, minutesAllocatedVariationsFromIdleTime);
	}

	// cache item
	return {
		minutesAllocatedVariations,
		distributionMap,
		idleTimeMinutesAllocated,
		taskMinutesAllocated,
		minutesAvailable,
		timeRegMinutes,
	};
};

export const calculateAllocationHeatmapStepData = (
	pageComponent,
	group,
	fetchVisibleItemsData,
	timelineMinorStep,
	startDate,
	endDate
) => {
	const data = pageComponent.getData();
	const {nonWorkingDaysMap} = data;
	const {dayData} = pageComponent.props;
	const {schedulingOptions, todayDate} = pageComponent.state;
	const groupCache = getGroupCache(pageComponent, group.id);
	let minutesAllocated = 0;
	let minutesAvailable = 0;
	let plannedTotalMinutesSoft = 0;
	let plannedTotalMinutesSoftWin = 0;
	let plannedTotalMinutesHard = 0;
	let taskMinutes = 0;
	let combinedMinutes = 0;
	let combinedMinutesWin = 0;
	let combinedMinutesSoft = 0;
	let combinedMinutesSoftWin = 0;
	let combinedMinutesHard = 0;
	let cachedItem = undefined;

	const hasHeatmapImprovements = hasFeatureFlag('improving_heatmap_frontend_performance');

	if (!group.filtered) {
		const person = group.data?.personId ? group.data : null;
		const personId = person ? person.personId : null;
		const placeholderId = group.groupType === GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP ? group.data.id : null;

		if (personId) {
			const isProjectTimeline = pageComponent.props.isProjectTimeline;
			const heatmapData = isProjectTimeline ? pageComponent.state.timelineHeatmapData : data.heatMaps;

			if (!heatmapData[personId]) {
				if (isProjectTimeline) {
					heatmapData[personId] = [];
					pageComponent.setState({timelineHeatmapData: heatmapData});
				} else {
					return;
				}
			}

			const {personWorkingHours, personWorkingMinutes} = getPersonWorkingHoursAndMinutes(person);

			const {holidaysIncludingDaysOff, holidaysExcludingDaysOff} = getHolidayDates(person, data, personWorkingMinutes);

			const weekdaysInPeriod = hasHeatmapImprovements
				? getWeekdaysBetweenDatesNew(startDate, endDate, nonWorkingDaysMap, personId)
				: getWeekdaysBetweenDates(startDate, endDate, null, null, null, nonWorkingDaysMap, personId);

			if (hasFeatureFlag('inverted_pto_non_working_days')) {
				removeHolidaysFromWeekdays(weekdaysInPeriod, holidaysIncludingDaysOff, startDate, endDate, dayData);
			}

			removeWeekdaysOutsidePersonTimePeriod(person, startDate, endDate, weekdaysInPeriod, dayData);

			minutesAvailable = hasHeatmapImprovements
				? getMinutesInWeekdaysNew(personWorkingHours, weekdaysInPeriod)
				: getMinutesInWeekdays(personWorkingHours, weekdaysInPeriod);

			[
				minutesAllocated,
				minutesAvailable,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin,
				plannedTotalMinutesHard,
				taskMinutes,
				combinedMinutes,
				combinedMinutesWin,
				combinedMinutesSoft,
				combinedMinutesSoftWin,
				combinedMinutesHard,
			] = getPersonHeatmapFromData(
				pageComponent,
				heatmapData[personId],
				holidaysIncludingDaysOff,
				personWorkingMinutes,
				dayData,
				minutesAllocated,
				minutesAvailable,
				startDate,
				endDate,
				person,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin,
				plannedTotalMinutesHard,
				nonWorkingDaysMap,
				personId
			);

			// Project timelines fetches heatmapData excluding current project and fetched only project items,
			// so both heatmap and items data always needs to be added.
			if (isProjectTimeline) {
				const visibleItemsData = fetchVisibleItemsData();

				const isUsingCombinedMode = getVisualizationMode(
					schedulingOptions,
					data.company,
					VISUALIZATION_MODE.COMBINATION
				);

				if (isUsingCombinedMode && hasFeatureFlag('combined_heatmap_logic_extensions')) {
					cachedItem = calculateCombinedNumbers(
						pageComponent,
						group,
						startDate,
						endDate,
						visibleItemsData,
						null,
						holidaysIncludingDaysOff,
						holidaysExcludingDaysOff,
						personWorkingMinutes,
						minutesAvailable,
						combinedMinutes,
						combinedMinutesWin,
						combinedMinutesSoft,
						combinedMinutesSoftWin,
						combinedMinutesHard,
						taskMinutes
					);
				} else {
					[
						minutesAvailable,
						minutesAllocated,
						plannedTotalMinutesHard,
						plannedTotalMinutesSoft,
						plannedTotalMinutesSoftWin,
					] = calculateHeatmapFromItems(
						pageComponent,
						group,
						startDate,
						endDate,
						visibleItemsData,
						null,
						holidaysIncludingDaysOff,
						holidaysExcludingDaysOff,
						personWorkingMinutes,
						minutesAvailable,
						minutesAllocated,
						plannedTotalMinutesHard,
						plannedTotalMinutesSoft,
						plannedTotalMinutesSoftWin
					);
				}
			}

			// availability may never be negative
			minutesAvailable = Math.max(minutesAvailable, 0);
		} else if (placeholderId) {
			const heatmapData = data.placeholderHeatMaps[placeholderId];
			if (!heatmapData) {
				return;
			}
			[minutesAllocated, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin] = getPlaceholderHeatmapFromData(
				heatmapData,
				startDate,
				endDate,
				minutesAllocated,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin
			);
		} else {
			const visibleItemsData = fetchVisibleItemsData();
			if (!visibleItemsData) {
				return;
			}

			const hasHeatmapImprovements = hasFeatureFlag('improving_heatmap_frontend_performance');

			if (hasHeatmapImprovements) {
				[minutesAllocated, plannedTotalMinutesHard, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin] =
					getAllocationHeatmapDataNew(
						schedulingOptions,
						data,
						visibleItemsData,
						startDate,
						endDate,
						todayDate,
						minutesAllocated,
						plannedTotalMinutesHard,
						plannedTotalMinutesSoft,
						plannedTotalMinutesSoftWin
					);
			} else {
				[minutesAllocated, plannedTotalMinutesHard, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin] =
					getAllocationHeatmapData(
						schedulingOptions,
						data,
						visibleItemsData,
						startDate,
						endDate,
						minutesAllocated,
						plannedTotalMinutesHard,
						plannedTotalMinutesSoft,
						plannedTotalMinutesSoftWin
					);
			}
		}
	}

	const heatmapData = cachedItem ?? {
		minutesAllocated,
		minutesAvailable,
		plannedTotalMinutesSoft,
		plannedTotalMinutesSoftWin,
		plannedTotalMinutesHard,
	};

	// save in cache
	if (!group.filtered) {
		const stepCache = getStepCache(groupCache, timelineMinorStep);
		setStepCachedItem(group.id, stepCache, timelineMinorStep, heatmapData, startDate, endDate);
		RecalculationManager.clearNeedsRecalculation(group.id, timelineMinorStep, startDate, endDate);
	}

	return heatmapData;
};

export const getVisibleItemsData = (
	pageComponent,
	canvasTimelineDateStart,
	canvasTimelineDateEnd,
	itemTypes,
	itemPredicate = null
) => {
	const {items, heatmapFiltering} = pageComponent.state;

	const includeFilteredItems = !heatmapFiltering && pageComponent.props.isPeopleScheduling;
	const itemTypesSet = new Set(itemTypes);
	const taskItems = [];
	const allocationItems = [];
	const placeholderAllocationItems = [];

	items.forEach(item => {
		// TODO: T44882 - temporarily disabled to align with initial loaded heatmap data (we don't exclude filtered items)
		if (
			(includeFilteredItems || !item.filtered) &&
			itemTypesSet.has(item.itemType) &&
			withinTimePeriod(item, canvasTimelineDateStart, canvasTimelineDateEnd) &&
			(!itemPredicate || itemPredicate(item))
		) {
			if (isTaskItem(item)) {
				taskItems.push(item);
			} else if (isProjectAllocationItem(item)) {
				allocationItems.push(item);
			} else if (isPlaceholderAllocationItem(item)) {
				placeholderAllocationItems.push(item);
			}
		}
	});

	return {
		allocationItems,
		placeholderAllocationItems,
		taskItems,
	};
};

export const initializeHeatmapCache = (pageComponent, group, stepDataArray, timelineMinorStep, fetchVisibleItemsData) => {
	const {person} = group.data || {};

	for (const stepData of stepDataArray) {
		let {startDate, endDate} = stepData;

		if (person && ((person.startDate && endDate < person.startDate) || (person.endDate && startDate > person.endDate))) {
			continue;
		}

		if (isStepHiddenBehindLoadMore(pageComponent, stepData)) {
			continue;
		}

		calculateAllocationHeatmapStepData(pageComponent, group, fetchVisibleItemsData, timelineMinorStep, startDate, endDate);
	}
};

export const getCachedHeatmapData = (pageComponent, group, timelineMinorStep, startDate, endDate) => {
	const groupCache = pageComponent.heatmapCache.get(group.id);

	if (!groupCache || isRecalculationNeeded(group.id, groupCache, timelineMinorStep, startDate, endDate)) {
		return null;
	}

	return getStepCache(groupCache, timelineMinorStep)?.get(startDate) || null;
};

export const getGroupingUtilizationData = (
	pageComponent,
	groups,
	timelineMinorStep,
	startDate,
	endDate,
	minutesAllocatedVariations = undefined
) => {
	let minutesAllocated = 0;
	let minutesAvailable = 0;
	let plannedTotalMinutesSoft = 0;
	let plannedTotalMinutesSoftWin = 0;
	let plannedTotalMinutesHard = 0;
	let taskMinutesAllocated = 0;
	let distributionMaps = [];

	groups.forEach(subGroup => {
		if (!subGroup.filtered) {
			const sectionCache = getCachedHeatmapData(pageComponent, subGroup, timelineMinorStep, startDate, endDate);

			if (sectionCache) {
				minutesAllocated += sectionCache.minutesAllocated;
				minutesAvailable += sectionCache.minutesAvailable;
				plannedTotalMinutesSoft += sectionCache.plannedTotalMinutesSoft;
				plannedTotalMinutesSoftWin += sectionCache.plannedTotalMinutesSoftWin;
				plannedTotalMinutesHard += sectionCache.plannedTotalMinutesHard;

				if (sectionCache.minutesAllocatedVariations) {
					if (!minutesAllocatedVariations) {
						minutesAllocatedVariations = {};
					}
					mergeMinutesAllocatedVariations(minutesAllocatedVariations, sectionCache.minutesAllocatedVariations);
				} else if (minutesAllocatedVariations) {
					addMinutesAllocatedVariations(minutesAllocatedVariations, sectionCache);
				}

				distributionMaps.push(sectionCache.distributionMap);

				if (sectionCache.taskMinutesAllocated) {
					taskMinutesAllocated += sectionCache.taskMinutesAllocated;
				}
			}
		}
	});

	return {
		minutesAllocated,
		minutesAvailable,
		plannedTotalMinutesSoft,
		plannedTotalMinutesSoftWin,
		plannedTotalMinutesHard,
		taskMinutesAllocated,
		minutesAllocatedVariations,
		distributionMaps,
	};
};

export const getProjectHeatmapData = (
	pageComponent,
	projectGroup,
	groupCache,
	stepCache,
	overallStartDate,
	overallEndDate,
	timelineMinorStep,
	fetchVisibleItemsData,
	getCalculationMetaData
) => {
	const hasCombinedModePerformanceImprovements = hasFeatureFlag('combined_mode_performance_improvements');

	let cachedItem = stepCache.get(overallStartDate);
	let recalculationNeeded = !hasCombinedModePerformanceImprovements;

	if (cachedItem) {
		recalculationNeeded = isRecalculationNeeded(
			projectGroup.id,
			groupCache,
			timelineMinorStep,
			overallStartDate,
			overallEndDate
		);

		if (!recalculationNeeded) {
			return cachedItem;
		}
	}

	const {dayData} = pageComponent.props;
	const data = pageComponent.getData();
	const {nonWorkingDaysMap} = data;

	if (hasCombinedModePerformanceImprovements) {
		const personGroup = projectGroup.parentGroup;
		const personGroupData = personGroup.data;
		const startDate = personGroupData.startDate ? Math.max(overallStartDate, personGroupData.startDate) : overallStartDate;
		const endDate = personGroupData.endDate ? Math.min(overallEndDate, personGroupData.endDate) : overallEndDate;

		const calculationMetaData = getCalculationMetaData(startDate, endDate);
		const {holidaysIncludingDaysOff, holidaysExcludingDaysOff, personWorkingMinutes} = calculationMetaData;
		let {minutesAvailable} = calculationMetaData;

		const visibleItemsData = fetchVisibleItemsData();

		cachedItem = calculateCombinedNumbers(
			pageComponent,
			projectGroup,
			startDate,
			endDate,
			visibleItemsData,
			calculationMetaData,
			holidaysIncludingDaysOff,
			holidaysExcludingDaysOff,
			personWorkingMinutes,
			minutesAvailable
		);
	} else {
		const hasHeatmapImprovements = hasFeatureFlag('improving_heatmap_frontend_performance');

		const personGroup = projectGroup.parentGroup;
		const personGroupData = personGroup.data;
		const startDate = personGroupData.startDate ? Math.max(overallStartDate, personGroupData.startDate) : overallStartDate;
		const endDate = personGroupData.endDate ? Math.min(overallEndDate, personGroupData.endDate) : overallEndDate;

		const {personWorkingMinutes, personWorkingHours} = getPersonWorkingHoursAndMinutes(personGroupData);

		const {holidaysIncludingDaysOff, holidaysExcludingDaysOff} = getHolidayDates(
			personGroupData,
			data,
			personWorkingMinutes
		);

		const weekdaysInPeriod = hasHeatmapImprovements
			? getWeekdaysBetweenDatesNew(startDate, endDate, nonWorkingDaysMap, personGroupData.personId)
			: getWeekdaysBetweenDates(startDate, endDate, null, null, null, nonWorkingDaysMap, personGroupData.personId);

		if (hasFeatureFlag('inverted_pto_non_working_days')) {
			removeHolidaysFromWeekdays(weekdaysInPeriod, holidaysIncludingDaysOff, startDate, endDate, dayData);
		}

		removeWeekdaysOutsidePersonTimePeriod(personGroupData, startDate, endDate, weekdaysInPeriod, dayData);

		let minutesAvailable = hasHeatmapImprovements
			? getMinutesInWeekdaysNew(personWorkingHours, weekdaysInPeriod)
			: getMinutesInWeekdays(personWorkingHours, weekdaysInPeriod);

		const visibleItemsData = fetchVisibleItemsData();

		if (hasFeatureFlag('combined_heatmap_logic_extensions')) {
			cachedItem = calculateCombinedNumbers(
				pageComponent,
				projectGroup,
				startDate,
				endDate,
				visibleItemsData,
				null,
				holidaysIncludingDaysOff,
				holidaysExcludingDaysOff,
				personWorkingMinutes,
				minutesAvailable
			);
		} else {
			let distributionMap;
			let deltaMinutesAllocated = 0;
			let deltaPlannedTotalMinutesHard = 0;
			let deltaPlannedTotalMinutesSoft = 0;
			let deltaPlannedTotalMinutesSoftWin = 0;
			let deltaTaskMinutesAllocated = 0;
			let timeRegMinutes = 0;

			[
				,
				,
				//minutesAvailable
				//deltaMinutesAllocated
				deltaPlannedTotalMinutesHard,
				deltaPlannedTotalMinutesSoft,
				deltaPlannedTotalMinutesSoftWin,
				distributionMap,
				deltaTaskMinutesAllocated,
				timeRegMinutes,
			] = calculateHeatmapFromItems(
				pageComponent,
				projectGroup,
				startDate,
				endDate,
				visibleItemsData,
				null,
				holidaysIncludingDaysOff,
				holidaysExcludingDaysOff,
				personWorkingMinutes,
				minutesAvailable,
				deltaMinutesAllocated,
				deltaPlannedTotalMinutesHard,
				deltaPlannedTotalMinutesSoft,
				deltaPlannedTotalMinutesSoftWin
			);

			const taskMinutesAllocated = deltaTaskMinutesAllocated + timeRegMinutes;
			const minutesAllocatedVariations = {};

			addMinutesAllocatedVariations(minutesAllocatedVariations, {
				plannedTotalMinutesHard: deltaPlannedTotalMinutesHard,
				plannedTotalMinutesSoft: deltaPlannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin: deltaPlannedTotalMinutesSoftWin,
				taskMinutesAllocated,
			});

			// cache item
			cachedItem = {
				minutesAllocatedVariations,
				distributionMap,
				taskMinutesAllocated,
				timeRegMinutes,
			};
		}
	}

	setStepCachedItem(projectGroup.id, stepCache, timelineMinorStep, cachedItem, overallStartDate, overallEndDate);

	if (recalculationNeeded) {
		RecalculationManager.clearNeedsRecalculation(projectGroup.id, timelineMinorStep, overallStartDate, overallEndDate);
	}

	return cachedItem;
};

export const getPersonHeatMapData = (
	pageComponent,
	personGroup,
	overallStartDate,
	overallEndDate,
	timelineMinorStep,
	fetchVisibleItemsData,
	getCalculationMetaData,
	groupCache,
	stepCache
) => {
	let cachedItem = stepCache.get(overallStartDate);

	if (!cachedItem || isRecalculationNeeded(personGroup.id, groupCache, timelineMinorStep, overallStartDate, overallEndDate)) {
		const {data, schedulingOptions} = pageComponent.state;
		const {nonWorkingDaysMap} = data;
		const {dayData, isProjectTimeline} = pageComponent.props;
		const isUsingCombinedMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);
		const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.ALLOCATION);
		const hasHeatmapFetchDisabled =
			hasFeatureFlag('people_scheduling_disable_heatmap_fetch') &&
			hasFeatureFlag('people_scheduling_disable_heatmap_fetch_no_access');
		const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(data.company);

		const calculationMetaData = getCalculationMetaData(overallStartDate, overallEndDate);
		const {holidaysIncludingDaysOff, holidaysExcludingDaysOff, personWorkingMinutes, workingMinutes} = calculationMetaData;
		let {minutesAvailable} = calculationMetaData;

		const personGroupData = personGroup.data;
		const startDate = personGroupData.startDate ? Math.max(overallStartDate, personGroupData.startDate) : overallStartDate;
		const endDate = personGroupData.endDate ? Math.min(overallEndDate, personGroupData.endDate) : overallEndDate;

		let minutesAllocated = 0;
		let plannedTotalMinutesHard = 0;
		let plannedTotalMinutesSoft = 0;
		let plannedTotalMinutesSoftWin = 0;
		let taskMinutes = 0;
		let combinedMinutes = 0;
		let combinedMinutesWin = 0;
		let combinedMinutesSoft = 0;
		let combinedMinutesSoftWin = 0;
		let combinedMinutesHard = 0;
		let distributionMap;
		let internalTimeRegMinutes;
		let noAccessMinutesAllocatedVariations = {};

		if (isProjectTimeline) {
			// Project timelines fetches heatmapData excluding current project and fetched only project items,
			// so both heatmap and items data always needs to be added.
			const heatmapData = pageComponent.state.timelineHeatmapData;
			[
				minutesAllocated,
				minutesAvailable,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin,
				plannedTotalMinutesHard,
				taskMinutes,
				combinedMinutes,
				combinedMinutesWin,
				combinedMinutesSoft,
				combinedMinutesSoftWin,
				combinedMinutesHard,
			] = getPersonHeatmapFromData(
				pageComponent,
				heatmapData[personGroupData.personId],
				holidaysIncludingDaysOff,
				personWorkingMinutes,
				dayData,
				minutesAllocated,
				minutesAvailable,
				startDate,
				endDate,
				personGroupData,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin,
				plannedTotalMinutesHard,
				nonWorkingDaysMap,
				personGroupData.personId
			);
		}

		const visibleItemsData = fetchVisibleItemsData();

		if (isProjectTimeline && isUsingCombinedMode && hasFeatureFlag('combined_heatmap_logic_extensions')) {
			cachedItem = calculateCombinedNumbers(
				pageComponent,
				personGroup,
				startDate,
				endDate,
				visibleItemsData,
				calculationMetaData,
				holidaysIncludingDaysOff,
				holidaysExcludingDaysOff,
				personWorkingMinutes,
				minutesAvailable,
				combinedMinutes,
				combinedMinutesWin,
				combinedMinutesSoft,
				combinedMinutesSoftWin,
				combinedMinutesHard,
				taskMinutes
			);

			distributionMap = cachedItem.distributionMap;
			cachedItem.workingMinutes = workingMinutes;
		} else {
			[
				minutesAvailable,
				minutesAllocated,
				plannedTotalMinutesHard,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin,
				distributionMap,
				,
				//taskMinutesAllocated
				internalTimeRegMinutes,
				,
				//idleTimeMinutesAllocated
				noAccessMinutesAllocatedVariations,
			] = calculateHeatmapFromItems(
				pageComponent,
				personGroup,
				startDate,
				endDate,
				visibleItemsData,
				calculationMetaData,
				holidaysIncludingDaysOff,
				holidaysExcludingDaysOff,
				personWorkingMinutes,
				minutesAvailable,
				minutesAllocated,
				plannedTotalMinutesHard,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin,
				noAccessMinutesAllocatedVariations
			);
			cachedItem = {
				workingMinutes,
				minutesAllocated,
				minutesAvailable,
				distributionMap,
			};
		}

		if ((isUsingProjectAllocation || isUsingCombinedMode) && !isProjectTimeline) {
			cachedItem.plannedTotalMinutesHard = Math.max(plannedTotalMinutesHard, internalTimeRegMinutes);
			cachedItem.plannedTotalMinutesSoft = plannedTotalMinutesSoft;
			cachedItem.plannedTotalMinutesSoftWin = plannedTotalMinutesSoftWin;

			if (hasHeatmapFetchDisabled || isMixedAllocationModeEnabled) {
				cachedItem.minutesAllocatedVariations = noAccessMinutesAllocatedVariations;
				addMinutesAllocatedVariations(noAccessMinutesAllocatedVariations, cachedItem);
			}

			if (isUsingCombinedMode) {
				const groupingUtilizationData = getGroupingUtilizationData(
					pageComponent,
					personGroup.groups,
					timelineMinorStep,
					overallStartDate,
					overallEndDate,
					cachedItem.minutesAllocatedVariations
				);

				if (groupingUtilizationData.distributionMaps.length > 0) {
					// Merge all distributionMaps of child groups (projects) and current group (non-project time)
					cachedItem.distributionMap = new Map(
						groupingUtilizationData.distributionMaps.concat(distributionMap).flatMap(e => [...e])
					);
				} else {
					cachedItem.distributionMap = distributionMap;
				}
			}
		}

		setStepCachedItem(personGroup.id, stepCache, timelineMinorStep, cachedItem, overallStartDate, overallEndDate);

		RecalculationManager.clearNeedsRecalculation(personGroup.id, timelineMinorStep, overallStartDate, overallEndDate);
	}

	return cachedItem;
};

export const handlePersonGroupHeatmapClick = (pageComponent, group, startDate, endDate, timelineMinorStep, stepDataArray) => {
	recalculateGroupHeatmapCache(pageComponent, group.id, interval(startDate, endDate));

	group.calculateHeatmapCache(group, stepDataArray, timelineMinorStep);

	onPersonGroupHeatmapItemClick(
		pageComponent,
		group.data,
		startDate,
		endDate,
		() => getCachedHeatmapData(pageComponent, group, timelineMinorStep, startDate, endDate),
		false
	);
};

// Used to allow cypress tests to open UT modal
export const openPersonUtilizationModal = (pageComponent, personId, stepIndex) => {
	const personGroup = getPersonGroups(pageComponent.state.groups).find(group => group.id === personId);
	if (personGroup && pageComponent.timeline) {
		const stepDataArray = pageComponent.timeline.minorStepDataArray;
		if (stepDataArray?.length) {
			const stepData = stepDataArray[stepIndex];
			const minorStep = pageComponent.timeline.minorStep;
			handlePersonGroupHeatmapClick(
				pageComponent,
				personGroup,
				stepData.startDate,
				stepData.endDate,
				minorStep,
				stepDataArray
			);
		}
	}
};

export const composePersonGroupingGroupItems = (
	pageComponent,
	group,
	stepDataArray,
	timelineMinorStep,
	isPeopleGroup = false
) => {
	const data = pageComponent.getData();
	const {schedulingOptions} = pageComponent.state;
	const items = [];
	let isInActualMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.TASK_ACTUAL);
	const isUsingCombinedMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);
	const {intl} = pageComponent.props;

	if (pageComponent.isCalculationSwitched) {
		isInActualMode = !isInActualMode;
	}

	const isCapacityOverview = pageComponent.props.schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;

	if (isGlobalRecalculationNeeded(pageComponent)) {
		group.calculateHeatmapCache(group, stepDataArray, timelineMinorStep);
	}

	for (const stepData of stepDataArray) {
		if (isStepHiddenBehindLoadMore(pageComponent, stepData)) {
			continue;
		}

		let {startDate, endDate} = stepData;

		const heatmapData = getCachedHeatmapData(pageComponent, group, timelineMinorStep, startDate, endDate);
		let minutesAvailable = heatmapData.minutesAvailable;

		let placeholderDemand = 0;
		let roleName = '';
		let hideTooltip = false;
		if (isCapacityOverview) {
			if (!isPeopleGroup) {
				// Only get placeholder demand on placeholder role item
				placeholderDemand = getRolePlaceholderDemand(pageComponent, group.parentGroup, timelineMinorStep, startDate);
				const parentHeatmapData = getCachedHeatmapData(
					pageComponent,
					group.parentGroup,
					timelineMinorStep,
					startDate,
					endDate
				);
				if (parentHeatmapData) {
					minutesAvailable = parentHeatmapData.minutesAvailable - parentHeatmapData.minutesAllocated;
				} else {
					hideTooltip = true;
				}
			}
			roleName =
				group.parentGroup.data?.name +
				' ' +
				(isPeopleGroup ? intl.formatMessage({id: 'common.people'}) : intl.formatMessage({id: 'common.placeholder'}));
		}

		const heatmapType = isCapacityOverview ? HEATMAP_TYPE.DEMAND : HEATMAP_TYPE.UTILIZATION;
		const itemData = {
			startDate,
			endDate,
			y: group.screenY,
			x: stepData.position,
			width: stepData.width,
			minutesAllocated: heatmapData.minutesAllocated,
			plannedTotalMinutesHard: heatmapData.plannedTotalMinutesHard,
			plannedTotalMinutesSoft: heatmapData.plannedTotalMinutesSoft,
			plannedTotalMinutesSoftWin: heatmapData.plannedTotalMinutesSoftWin,
			taskMinutesAllocated: heatmapData.taskMinutesAllocated,
			minutesAllocatedVariations: heatmapData.minutesAllocatedVariations,
			minutesAvailable: minutesAvailable,
			placeholderDemand,
			isPeopleGroup,
			roleName,
			isPast: endDate < pageComponent.state.todayDate,
			hideTooltip,
			isDayOffItem: isDayOffStep(pageComponent, stepData),
		};

		let heatmapItemConfig;
		if (!isCapacityOverview) {
			itemData.displayInPercentage = !isCapacityOverview;

			const {todayDate} = pageComponent.state;
			const isPast = stepData.endDate < todayDate;
			const isActualPast = isPast && (isInActualMode || (isUsingCombinedMode && !data.company.isUsingSchedulingPlanMode));

			const backgroundColor = TIMELINE_BACKGROUND_COLOR;
			const overAllocatedBackgroundColor = HEATMAP_OVER_ALLOCATED_PROGRESS_COLOR;
			const barColor = isActualPast ? HEATMAP_ACTUAL_BACKGROUND_COLOR : HEATMAP_FULL_BACKGROUND_COLOR_DARK;

			heatmapItemConfig = new HeatmapItemConfig(
				backgroundColor,
				overAllocatedBackgroundColor,
				barColor,
				HEATMAP_OVER_ALLOCATED_COMPLETION_COLOR_DARK
			);

			heatmapItemConfig.setSoftAllocationColors(
				HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_COMPLETION_BACKGROUND_COLOR_DARK,
				HEATMAP_CELL_SOFT_ALLOCATED_STRIPE_OVERALLOCATED_BACKGROUND_COLOR_DARK
			);
		}

		const tooltipData = ComposeManager.composeDemandHeatmapTooltip(pageComponent, group, itemData, stepData);

		itemData.onMouseEnter = event => EventManager.onDemandHeatmapItemMouseEnter(pageComponent, event, group, tooltipData);
		itemData.onMouseLeave = event => EventManager.onDemandHeatmapItemMouseLeave(pageComponent, event);

		items.push(new HeatmapItem(pageComponent, heatmapType, itemData, heatmapItemConfig));
	}

	return items;
};
