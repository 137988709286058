import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import GenericModal from '../../../containers/modal/generic_modal';
import Button from '../../../forecast-app/shared/components/buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_FORMAT_DAY, INITIALS_SIZE, MODULE_TYPES} from '../../../constants';
import Util from '../../../forecast-app/shared/util/util';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {
	getMomentFromCanvasTimelineDate,
	getVisualizationMode,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import PersonInitials from '../../../forecast-app/shared/components/person/person_initials';
import UtilizationModalEmptyStateGraphic from '../../../images/graphics/utilizationModalEmptyStateGraphic';
import {
	ComponentValueDetails,
	DateRangeSection,
	DistributionSection,
	EmptyState,
	Graph,
	GraphAxis,
	GraphBlock,
	GraphDifferenceText,
	GraphFooter,
	GraphLegend,
	GraphLegendsContainer,
	GraphLegendTitle,
	GraphSection,
	GraphStep,
	GraphStepInfinitySign,
	GraphStepInfinitySignContainer,
	GraphStepLine,
	GraphStepLineContainer,
	GraphWorkingHoursLine,
	ScrollContent,
	StatsSection,
	Subtitle,
	TopSection,
	UtilizationAllocationTypeRow,
	UtilizationDataRow,
	UtilizationDataRowLink,
	UtilizationDataRowMinutesAllocated,
	UtilizationDataRowName,
	UtilizationDataSection,
	UtilizationDataSectionHeader,
	UtilizationDataSectionRowContainer,
	UtilizationDataSectionTitle,
	UtilizationModalStyled,
	UtilizationRange,
	UtilizationStatsSection,
	ZeroAvailabilityMessageContainer,
} from '../../../styles/v2/schedule_utilization_modal_styled';
import * as tracking from '../../../tracking';
import * as Sentry from '@sentry/browser';
import {CSS_CONSTANTS} from '../../../css_variables';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {trackComplexEvent} from '../../../tracking/amplitude/TrackingV2';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import {profilePicSrc, projectUrl} from '../../../directApi';
import {DeprecatedProjectIndicatorComponent} from '../../../forecast-app/shared/components/project-indicator/DeprecatedProjectIndicator';
import {DeprecatedProjectGroupIndicatorComponent} from '../../../forecast-app/shared/components/project-indicator/DeprecatedProjectGroupIndicator';

class UtilizationModal extends Component {
	constructor(props) {
		super(props);

		const {holidayCalendarEntries, holidayCalendars, person, company, startDate, endDate, schedulingOptions} = props;

		const momentStartDate = getMomentFromCanvasTimelineDate(startDate);
		const momentEndDate = getMomentFromCanvasTimelineDate(endDate);

		const holidayCalendar = person.holidayCalendarId
			? holidayCalendars.find(holidayCalendar => person.holidayCalendarId === holidayCalendar.id)
			: company.defaultHolidayCalendarId
			? holidayCalendars.find(holidayCalendar => company.defaultHolidayCalendarId === holidayCalendar.id)
			: holidayCalendars.length === 1
			? holidayCalendars[0]
			: null;
		this.holidayEntries = [];
		if (holidayCalendar) {
			this.holidayEntries = holidayCalendarEntries.filter(entry => {
				if (entry.holidayCalendarId !== holidayCalendar.id) return false;

				const holidayDate = Util.CreateNonUtcMomentDate(entry.year, entry.month, entry.day);
				return momentStartDate.isSameOrBefore(holidayDate, 'day') && momentEndDate.isSameOrAfter(holidayDate, 'day');
			});
		}
		this.schedulingOptions = schedulingOptions;

		this.hasSoftAllocationModule = hasFeatureFlag('capacity_planning_beta_2_improvements')
			? hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
			: true;
	}

	onAddAllocationClick() {
		const {
			person,
			startDate,
			endDate,
			project,
			projectGroups,
			projects,
			idleTimes,
			projectPersons,
			actualPersonId,
			persons,
			holidayCalendarEntries,
			holidayCalendars,
			company,
			phases,
			roles,
			teams,
			teamPersons,
			schedulingOptions,
		} = this.props;
		const momentStartDate = getMomentFromCanvasTimelineDate(startDate);
		const momentEndDate = getMomentFromCanvasTimelineDate(endDate);
		const workingHours = person.workingHours || person;
		const personId = person.personId || person.id;

		const commonModalData = {
			projectGroups,
			projects: project ? [project] : projects,
			idleTimes,
			projectPersons,
			actualPersonId,
			persons,
			selectedPersonId: personId,
			selectedProject: project,
			allocation: {
				personId,
				startDay: momentStartDate.date(),
				startMonth: momentStartDate.month() + 1,
				startYear: momentStartDate.year(),
				endDay: momentEndDate.date(),
				endMonth: momentEndDate.month() + 1,
				endYear: momentEndDate.year(),
				monday: workingHours.monday,
				tuesday: workingHours.tuesday,
				wednesday: workingHours.wednesday,
				thursday: workingHours.thursday,
				friday: workingHours.friday,
				saturday: workingHours.saturday,
				sunday: workingHours.sunday,
			},
			holidayCalendarEntries,
			holidayCalendars,
			company,
			schedulingOptions,
		};

		showModal(
			{
				type: MODAL_TYPE.CANVAS_CREATE,
				...commonModalData,
				phases,
				roles,
				teams,
				teamPersons,
			},
			true
		);

		tracking.trackEvent('Create Allocation from Utilization Modal');
		trackComplexEvent('Allocation', 'Created', {location: 'From Utilization Modal'});
	}

	isAllocationParentDropdownEmpty(projectGroups, projects, idleTimes) {
		if (idleTimes.length) return false;
		let isAllocationParentDropdownEmpty = true;
		for (const projectGroup of projectGroups) {
			if (!projects.find(project => project.projectGroupId === projectGroup.id).useManualAllocations) continue;
			isAllocationParentDropdownEmpty = false;
			break;
		}
		if (!isAllocationParentDropdownEmpty) return false;
		for (const project of projects) {
			if (!project.useManualAllocations) continue;
			isAllocationParentDropdownEmpty = false;
			break;
		}
		return isAllocationParentDropdownEmpty;
	}

	getRowKey(rowData) {
		return rowData.isProjectGroup ? 'X-' + rowData.id : rowData.id;
	}

	getUtilizationRange(momentStartDate, momentEndDate) {
		const startDateFormatted = momentStartDate.format(DATE_FORMAT_DAY);
		const endDateFormatted = momentEndDate.format(DATE_FORMAT_DAY);

		const isDayStep = this.props.minorStep === 'DAY';
		const range = isDayStep ? `(${startDateFormatted})` : `(${startDateFormatted} - ${endDateFormatted})`;

		return `${this.props.stepLabel} ${range}`;
	}

	getGraphSteps(graphStepArray) {
		return (
			<>
				{graphStepArray.map((stepData, index) => (
					<GraphStep key={index} style={{bottom: stepData.position}}>
						{stepData.showInfinitySign ? (
							<GraphStepInfinitySignContainer>
								<GraphStepInfinitySign />
							</GraphStepInfinitySignContainer>
						) : (
							<div>{stepData.value}</div>
						)}

						<GraphStepLineContainer>
							<GraphStepLine />
						</GraphStepLineContainer>
					</GraphStep>
				))}
			</>
		);
	}

	getStripedBackground(backgroundColor, stripeColor, options = {}) {
		const angle = options.angle || 135;
		const gap = options.gap || 3;
		const stripeWidth = options.stripeWidth || 6;

		return `repeating-linear-gradient(${angle}deg, ${backgroundColor}, ${backgroundColor} ${gap}px, ${stripeColor} ${gap}px, ${stripeColor} ${stripeWidth}px)`;
	}

	getGraphBlocks(
		workingHoursLinePosition,
		hardAllocationsHeight,
		shouldShowTimeRegs,
		overAllocatedHeight,
		softAllocationsHeight,
		overAllocatedSoftAllocationsHeight
	) {
		const blocks = [];

		blocks.push(
			<GraphBlock
				key={'total'}
				blockColor={CSS_CONSTANTS.v2_button_very_light_grey}
				blockHeight={workingHoursLinePosition}
				blockPosition={'absolute'}
				blockLeftOffset={73}
				blockBottomOffset={0}
			/>
		);

		if (hardAllocationsHeight > 0) {
			blocks.push(
				<GraphBlock
					key={'hard-alloc'}
					blockColor={shouldShowTimeRegs ? CSS_CONSTANTS.schedule_actual_blue : CSS_CONSTANTS.schedule_v2_green}
					blockHeight={hardAllocationsHeight}
					blockPosition={'absolute'}
					blockLeftOffset={73}
					blockBottomOffset={0}
				/>
			);
		}

		if (softAllocationsHeight > 0) {
			blocks.push(
				<GraphBlock
					key={'soft-alloc'}
					background={this.getStripedBackground(
						CSS_CONSTANTS.schedule_legend_soft_background,
						CSS_CONSTANTS.schedule_legend_soft_stripe,
						{gap: 5, stripeWidth: 10}
					)}
					blockHeight={softAllocationsHeight}
					blockPosition={'absolute'}
					blockLeftOffset={73}
					blockBottomOffset={hardAllocationsHeight}
				/>
			);
		}

		if (overAllocatedHeight > 0) {
			blocks.push(
				<GraphBlock
					key={'over-alloc'}
					blockColor={
						shouldShowTimeRegs
							? CSS_CONSTANTS.schedule_actual_blue
							: CSS_CONSTANTS.schedule_legend_soft_over_background
					}
					blockHeight={overAllocatedHeight}
					blockPosition={'absolute'}
					blockLeftOffset={73}
					blockBottomOffset={workingHoursLinePosition}
				/>
			);
		}

		if (overAllocatedSoftAllocationsHeight > 0) {
			blocks.push(
				<GraphBlock
					key={'over-soft-alloc'}
					background={this.getStripedBackground(
						CSS_CONSTANTS.schedule_legend_soft_over_background,
						CSS_CONSTANTS.schedule_legend_soft_over_stripe,
						{gap: 5, stripeWidth: 10}
					)}
					blockHeight={overAllocatedSoftAllocationsHeight}
					blockPosition={'absolute'}
					blockLeftOffset={73}
					blockBottomOffset={workingHoursLinePosition + overAllocatedHeight - overAllocatedSoftAllocationsHeight}
				/>
			);
		}

		return blocks;
	}

	getGraphDifferenceText(minutesAvailable, minutesAllocatedValue, workingHoursLinePosition, graphMaxValue, graphHeight) {
		if (!(minutesAvailable && minutesAllocatedValue !== minutesAvailable)) {
			return null;
		}

		const {formatMessage} = this.props.intl;

		const differenceTextStyle = {
			bottom:
				minutesAvailable > minutesAllocatedValue
					? workingHoursLinePosition
					: workingHoursLinePosition +
					  ((minutesAllocatedValue - minutesAvailable) / 60 / graphMaxValue) * graphHeight,
		};

		const differencePercentage =
			minutesAvailable > minutesAllocatedValue
				? Math.round((1 - 1 / (minutesAvailable / minutesAllocatedValue)) * 100)
				: Math.round((minutesAllocatedValue / minutesAvailable - 1) * 100);

		const overOrUnder =
			minutesAvailable > minutesAllocatedValue
				? formatMessage({id: 'scheduling.under'})
				: formatMessage({id: 'scheduling.over'});

		return (
			<GraphDifferenceText style={differenceTextStyle}>
				{differencePercentage}% <span>{overOrUnder}</span>
			</GraphDifferenceText>
		);
	}

	getProjectAllocationDistribution(distributionData) {
		const {company, intl} = this.props;
		const {formatMessage} = intl;
		const {projectAllocation, taskAllocation} = distributionData;
		const calcWin = this.schedulingOptions?.calcWin;
		const isUsingProjectAllocation = getVisualizationMode(this.schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);

		if (projectAllocation.length < 1) {
			return null;
		}

		if (hasFeatureFlag('capacity_planning_beta_2_improvements')) {
			const utilizationRows = [];

			projectAllocation.forEach(rowData => {
				const rowKey = this.getRowKey(rowData);
				const rowMinutesAllocated = calcWin ? rowData.minutesAllocatedWin : rowData.minutesAllocated;

				const utilizationDataRow = (
					<UtilizationDataRow key={rowKey} title={rowData.name}>
						{!rowData.isProjectGroup ? (
							<DeprecatedProjectIndicatorComponent
								projectColor={rowData.color}
								companyProjectId={rowData.id}
								customProjectId={rowData.customProjectId}
							/>
						) : (
							<DeprecatedProjectGroupIndicatorComponent
								companyProjectGroupId={rowData.id}
								projectGroupColor={rowData.color}
							/>
						)}
						<UtilizationDataRowName>{rowData.name}</UtilizationDataRowName>
						<UtilizationDataRowMinutesAllocated>{rowMinutesAllocated}</UtilizationDataRowMinutesAllocated>
					</UtilizationDataRow>
				);

				if (rowData.isLink) {
					const linkUrl = rowData.isProjectGroup
						? `/connected/X-'${rowData.id}`
						: projectUrl(rowData.id, rowData.customProjectId);

					utilizationRows.push(
						<UtilizationDataRowLink key={rowKey} href={linkUrl}>
							{utilizationDataRow}
						</UtilizationDataRowLink>
					);
				} else {
					utilizationRows.push(utilizationDataRow);
				}

				if (isUsingProjectAllocation) {
					const {minutesAllocatedHard, minutesAllocatedSoft, minutesAllocatedSoftWin} = rowData;

					const softMinutes = calcWin ? minutesAllocatedSoftWin : minutesAllocatedSoft;
					if (softMinutes > 0 && this.hasSoftAllocationModule) {
						utilizationRows.push(
							<UtilizationAllocationTypeRow key={rowKey + '-soft'} title={rowData.name}>
								<span>{formatMessage({id: 'allocation.soft_allocation'})}</span>
								<span>{Util.convertMinutesToFullHour(softMinutes, intl)}</span>
							</UtilizationAllocationTypeRow>
						);
					}

					if (minutesAllocatedHard > 0 && this.hasSoftAllocationModule) {
						utilizationRows.push(
							<UtilizationAllocationTypeRow key={rowKey + '-hard'} title={rowData.name}>
								<span>{formatMessage({id: 'allocation.hard_allocation'})}</span>
								<span>{Util.convertMinutesToFullHour(minutesAllocatedHard, intl)}</span>
							</UtilizationAllocationTypeRow>
						);
					}
				}
			});

			return (
				<UtilizationDataSection>
					<UtilizationDataSectionHeader>
						<UtilizationDataSectionTitle>
							{formatMessage({id: 'scheduling.project_allocation'})}
						</UtilizationDataSectionTitle>

						{calcWin && <Subtitle>({formatMessage({id: 'scheduling.calculated_by_win_probability'})})</Subtitle>}
					</UtilizationDataSectionHeader>
					<UtilizationDataSectionRowContainer>{utilizationRows}</UtilizationDataSectionRowContainer>
				</UtilizationDataSection>
			);
		}

		return (
			<div className="utilization-data-section" style={{borderTop: taskAllocation.length ? 'none' : undefined}}>
				<div className="utilization-data-section-header">
					<div className="utilization-data-section-header-text">
						{formatMessage({id: 'scheduling.project_allocation'})}
					</div>
				</div>

				<div className="utilization-data-section-row-container">
					{projectAllocation.map(rowData =>
						rowData.isLink ? (
							<a
								key={this.getRowKey(rowData)}
								href={
									rowData.isProjectGroup
										? `/connected/X-${rowData.id}`
										: projectUrl(rowData.id, rowData.customProjectId)
								}
							>
								<div
									key={this.getRowKey(rowData)}
									className="utilization-data-section-row"
									title={rowData.name}
								>
									{!rowData.isProjectGroup ? (
										<DeprecatedProjectIndicatorComponent
											projectColor={rowData.color}
											companyProjectId={rowData.id}
											customProjectId={rowData.customProjectId}
										/>
									) : (
										<DeprecatedProjectGroupIndicatorComponent
											companyProjectGroupId={rowData.id}
											projectGroupColor={rowData.color}
										/>
									)}
									<div className="name">{rowData.name}</div>
									<div className="minutes-allocated">{rowData.minutesAllocated}</div>
								</div>
							</a>
						) : (
							<div key={this.getRowKey(rowData)}>
								<div
									key={this.getRowKey(rowData)}
									className="utilization-data-section-row"
									title={rowData.name}
								>
									{!rowData.isProjectGroup ? (
										<DeprecatedProjectIndicatorComponent
											projectColor={rowData.color}
											companyProjectId={rowData.id}
											customProjectId={rowData.customProjectId}
										/>
									) : (
										<DeprecatedProjectGroupIndicatorComponent
											companyProjectGroupId={rowData.id}
											projectGroupColor={rowData.color}
										/>
									)}
									<div className="name">{rowData.name}</div>
									<div className="minutes-allocated">
										{rowData.minutesAllocated !== rowData.minutesAllocatedWin
											? formatMessage(
													{id: 'scheduling.minutes_win'},
													{
														minutesWin: rowData.minutesAllocatedWin,
														minutes: rowData.minutesAllocated,
													}
											  )
											: rowData.minutesAllocatedWin}
									</div>
								</div>
							</div>
						)
					)}
				</div>
			</div>
		);
	}

	render() {
		const {
			person,
			minutesAllocated,
			minutesAvailable,
			plannedTotalMinutesHard,
			plannedTotalMinutesSoft,
			plannedTotalMinutesSoftWin,
			startDate,
			endDate,
			actualPersonId,
			projects,
			projectGroups,
			projectPersons,
			distributionMap,
			idleTimes,
			isPast,
			company,
			disableLinks,
		} = this.props;

		const isAllocationParentDropdownEmpty = this.isAllocationParentDropdownEmpty(projectGroups, projects, idleTimes);
		const hideSoft = this.schedulingOptions && this.schedulingOptions.hideSoft;
		const calcWin = this.schedulingOptions && this.schedulingOptions.calcWin;
		const hideHard = this.schedulingOptions && this.schedulingOptions.hideHard;
		let minutesAllocatedValue = minutesAllocated;
		let minutesAllocatedValueSoft = plannedTotalMinutesSoft;
		const isUsingProjectAllocation = getVisualizationMode(this.schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);
		if (isUsingProjectAllocation) {
			minutesAllocatedValue = hideHard ? 0 : plannedTotalMinutesHard;
			if (hideSoft) {
				minutesAllocatedValueSoft = 0;
			} else {
				if (calcWin) {
					minutesAllocatedValue += plannedTotalMinutesSoftWin;
					minutesAllocatedValueSoft = plannedTotalMinutesSoftWin;
				} else {
					minutesAllocatedValue += plannedTotalMinutesSoft;
					minutesAllocatedValueSoft = plannedTotalMinutesSoft;
				}
			}
		}

		const momentStartDate = getMomentFromCanvasTimelineDate(startDate);
		const momentEndDate = getMomentFromCanvasTimelineDate(endDate);

		const {formatMessage, formatDate} = this.props.intl;

		const isInActualMode = getVisualizationMode(this.schedulingOptions, company, VISUALIZATION_MODE.TASK_ACTUAL);
		const shouldShowTimeRegs = isPast && isInActualMode;
		const showHoursInDateRangeGraph = minutesAllocatedValue !== 0 && minutesAvailable !== 0;
		const showUtilizationPercent = !shouldShowTimeRegs && minutesAllocatedValue !== 0 && minutesAvailable !== 0;
		const showRemainingAvailableTime = minutesAvailable > minutesAllocated && minutesAllocated !== 0;
		const showZeroAvailabilityMessage = minutesAllocatedValue === 0 && minutesAvailable === 0;

		const hoursAvailable = minutesAvailable / 60;
		const graphStepCount = 10;
		const graphHeight = 200;
		let graphMaxValue = 10;
		if (minutesAllocatedValue / 60 > graphMaxValue) {
			graphMaxValue = minutesAllocatedValue / 60 < 50 ? 50 : Math.ceil(minutesAllocatedValue / 6000) * 100;
		}
		if (graphMaxValue < hoursAvailable) {
			graphMaxValue = hoursAvailable < 50 ? 50 : Math.ceil(hoursAvailable / 100) * 100;
		}
		const workingHoursLinePosition = (hoursAvailable / graphMaxValue) * graphHeight;

		const overAllocatedHeight = minutesAvailable
			? ((minutesAllocatedValue - minutesAvailable) / 60 / graphMaxValue) * graphHeight
			: graphHeight;

		const hardAllocationsHeight =
			plannedTotalMinutesHard > minutesAvailable
				? workingHoursLinePosition
				: (plannedTotalMinutesHard / 60 / graphMaxValue) * graphHeight;

		const softAllocationsHeight = (minutesAllocatedValueSoft / 60 / graphMaxValue) * graphHeight;

		let overAllocatedSoftAllocationsHeight = 0;

		if (!isNaN(softAllocationsHeight)) {
			overAllocatedSoftAllocationsHeight =
				softAllocationsHeight < overAllocatedHeight ? softAllocationsHeight : overAllocatedHeight;
		}

		const graphStepArray = [];
		for (let i = 1; i <= graphStepCount; i++) {
			graphStepArray.push({
				value: Math.round((i / graphStepCount) * graphMaxValue),
				position: (i / graphStepCount) * graphHeight - 8,
				showInfinitySign: !minutesAvailable && i === graphStepCount,
			});
		}

		const distributionArrays = [[], [], [], [], []];
		for (const entry of distributionMap.entries()) {
			if (!entry[1]) continue; //Don't show the row if the allocation is 0 mintues (can be 0h time registration and some other fuckery)
			const decodedId = entry[0] ? atob(entry[0]) : '';
			let color, id, customProjectId, isProjectGroup, name, arrayIndex, isLink;

			if (decodedId.startsWith('ProjectGroup')) {
				const projectGroup = projectGroups.find(projectGroup => {
					return projectGroup.id === entry[0];
				});
				const firstProject = projects.find(project => {
					return project.projectGroupId === projectGroup.id;
				});

				const breadcrumb = {
					message: 'Utilization modal',
					category: 'schedule',
					level: 'info',
					data: {
						projectGroup,
						projectGroupId: projectGroup.id,
						projects: projects.map(p => ({id: p.id, projectGroupId: p.projectGroupId})),
					},
				};
				Sentry.addBreadcrumb(breadcrumb);

				id = projectGroup.companyProjectGroupId;
				isProjectGroup = true;
				color = projectGroup.color;
				name = projectGroup.name;
				arrayIndex = isUsingProjectAllocation ? 1 : 0;
				isLink =
					hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) ||
					projectPersons
						.filter(projectPerson => projectPerson.projectId === firstProject.id)
						.find(projectPerson => projectPerson.personId === actualPersonId);
			} else if (decodedId.startsWith('ProjectType')) {
				const project = projects.find(project => {
					return project.id === entry[0];
				});
				if (project) {
					id = project.companyProjectId;
					customProjectId = project.customProjectId;
					isProjectGroup = false;
					color = project.projectColor;
					name = project.name;
					arrayIndex = isUsingProjectAllocation ? 1 : 0;
					isLink =
						hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) ||
						projectPersons
							.filter(projectPerson => projectPerson.projectId === project.id)
							.find(projectPerson => projectPerson.personId === actualPersonId);
				}
			} else if (decodedId.startsWith('IdleTime')) {
				const idleTime = idleTimes.find(idleTime => {
					return idleTime.id === entry[0];
				});
				id = '';
				color = '#efefef';
				name = idleTime.name;
				arrayIndex = idleTime.isInternalTime ? 2 : 3;
				isLink = false;
			} else if (decodedId.startsWith('NoAccess')) {
				id = '';
				color = '#efefef';
				name = formatMessage({id: 'scheduling.no_access'});
				arrayIndex = 4;
				isLink = false;
			}

			if (disableLinks) {
				isLink = false;
			}

			let hardMinutes = hideHard ? 0 : entry[1].minutesHard;
			if (hardMinutes === undefined) {
				hardMinutes = entry[1].minutes;
			}
			const softMinutes = hideSoft ? 0 : entry[1].minutesSoft || 0;
			const softMinutesWin = calcWin && !hideSoft ? entry[1].minutesSoftWin || 0 : softMinutes;
			const minutes = hardMinutes + softMinutes;
			const minutesWin = hardMinutes + softMinutesWin;

			const minutesText = Util.convertMinutesToFullHour(minutes, this.props.intl);
			const minutesWinText = Util.convertMinutesToFullHour(minutesWin, this.props.intl);

			const minutesAllocatedHard = entry[1].minutesHard;
			const minutesAllocatedSoft = entry[1].minutesSoft;

			if (id != null) {
				distributionArrays[arrayIndex].push({
					id,
					customProjectId,
					isProjectGroup,
					color,
					name,
					minutesAllocated: minutesText,
					minutesAllocatedWin: minutesWinText,
					minutesAllocatedHard,
					minutesAllocatedSoft,
					minutesAllocatedSoftWin: entry[1].minutesSoftWin,
					isLink,
				});
			}
		}
		const daySpan = momentEndDate.diff(momentStartDate, 'days');
		const distributionData = {
			taskAllocation: distributionArrays[0],
			projectAllocation: distributionArrays[1],
			internalTime: distributionArrays[2],
			timeOff: distributionArrays[3],
			otherTime: distributionArrays[4],
		};

		const content = (
			<UtilizationModalStyled>
				<TopSection>
					<div className="person-name-container">
						{person.profilePictureId ? (
							<img
								crossOrigin="use-credentials"
								height="32"
								src={profilePicSrc(person.profilePictureId)}
								alt=""
							/>
						) : (
							<PersonInitials
								size={INITIALS_SIZE.LARGE}
								initials={person.initials ? person.initials : Util.getInitials(`${person.name}`)}
							/>
						)}
						<div className="person-name">
							{person.name}{' '}
							<span className="utilization">
								{shouldShowTimeRegs
									? formatMessage({id: 'common.time_registrations'})
									: formatMessage({id: 'scheduling.utilization'})}
							</span>
						</div>
					</div>
					{!shouldShowTimeRegs &&
					!this.props.readOnly &&
					(hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE) || !isUsingProjectAllocation) &&
					!isAllocationParentDropdownEmpty ? (
						<Button
							buttonStyle={BUTTON_STYLE.OUTLINE}
							colorTheme={BUTTON_COLOR.PURPLE}
							text={
								isUsingProjectAllocation
									? formatMessage({id: 'scheduling.add_allocation'})
									: formatMessage({id: 'public_crete_task_form.title'})
							}
							onClick={this.onAddAllocationClick.bind(this)}
						/>
					) : null}
				</TopSection>

				{hasFeatureFlag('capacity_planning_beta_2_improvements') && (
					<DateRangeSection>
						<Subtitle>{formatMessage({id: 'scheduling.utilization_date_range'})}</Subtitle>
						<UtilizationRange>{this.getUtilizationRange(momentStartDate, momentEndDate)}</UtilizationRange>
					</DateRangeSection>
				)}

				{hasFeatureFlag('capacity_planning_beta_2_improvements') && (
					<UtilizationStatsSection>
						<div className="stats-component">
							<Subtitle>{formatMessage({id: 'scheduling.total_hours_allocated'})}</Subtitle>

							<div className="component-value">
								{Util.convertMinutesToFullHour(minutesAllocatedValue, this.props.intl)}
							</div>

							{calcWin && (
								<ComponentValueDetails>
									({formatMessage({id: 'scheduling.calculated_by_win_probability'})})
								</ComponentValueDetails>
							)}
						</div>

						<div className="separator"></div>
						<div className="stats-component">
							<Subtitle>{formatMessage({id: 'scheduling.utilization_date_range_total_availability'})}</Subtitle>

							<div className="component-value">
								{Util.convertMinutesToFullHour(minutesAvailable, this.props.intl)}
							</div>
						</div>

						{showUtilizationPercent ? (
							<>
								<div className="separator"></div>
								<div className="stats-component">
									<Subtitle>{formatMessage({id: 'scheduling.utilization_percent'})}</Subtitle>

									<div className="component-value">
										{minutesAllocatedValue === 0 && minutesAvailable === 0
											? 0
											: minutesAvailable
											? Math.round((minutesAllocatedValue / minutesAvailable) * 100)
											: '200'}
										%{minutesAllocatedValue && !minutesAvailable ? ' +' : ''}
									</div>

									{calcWin && (
										<ComponentValueDetails>
											({formatMessage({id: 'scheduling.calculated_by_win_probability'})})
										</ComponentValueDetails>
									)}
								</div>
							</>
						) : null}
					</UtilizationStatsSection>
				)}

				{!hasFeatureFlag('capacity_planning_beta_2_improvements') && (
					<StatsSection>
						<div className="stats-component">
							<Subtitle>
								{isUsingProjectAllocation
									? formatMessage({id: 'scheduling.total_allocated_h'})
									: shouldShowTimeRegs
									? formatMessage({id: 'scheduling.total_time_reg_h'})
									: formatMessage({id: 'scheduling.total_assigned_h'})}
							</Subtitle>
							<div className="component-value">
								{calcWin ? '* ' : ''}
								{Util.convertMinutesToFullHour(minutesAllocatedValue, this.props.intl)}
								<span className="available">
									{' '}
									/ {Util.convertMinutesToFullHour(minutesAvailable, this.props.intl)}
								</span>
							</div>
						</div>
						{!shouldShowTimeRegs && (
							<>
								<div className="separator"></div>
								<div className="stats-component">
									<Subtitle>{formatMessage({id: 'scheduling.utilization_percent'})}</Subtitle>
									<div className="component-value">
										{calcWin ? '* ' : ''}
										{minutesAllocatedValue === 0 && minutesAvailable === 0
											? 0
											: minutesAvailable
											? Math.round((minutesAllocatedValue / minutesAvailable) * 100)
											: '200'}
										%{minutesAllocatedValue && !minutesAvailable ? ' +' : ''}
									</div>
								</div>
							</>
						)}
					</StatsSection>
				)}

				<DistributionSection>
					{distributionArrays.some(array => array.length) || this.holidayEntries.length ? (
						<>
							<Subtitle>{formatMessage({id: 'common.distribution'})}</Subtitle>
							<div className="data-graph-container">
								<div className="flex-container">
									<CustomScrollDiv autoHide={false} autoHeight={true} autoHeightMin={1} autoHeightMax={500}>
										<ScrollContent>
											{distributionData.taskAllocation.length ? (
												<div className="utilization-data-section">
													<div className="utilization-data-section-header">
														<div className="utilization-data-section-header-text">
															{formatMessage({id: 'scheduling.task_allocation'})}
														</div>
													</div>
													<div className="utilization-data-section-row-container">
														{distributionData.taskAllocation.map(rowData =>
															rowData.isLink ? (
																<a
																	key={this.getRowKey(rowData)}
																	href={
																		rowData.isProjectGroup
																			? `/connected/X-${rowData.id}`
																			: projectUrl(rowData.id, rowData.customProjectId)
																	}
																>
																	<div
																		className="utilization-data-section-row"
																		title={rowData.name}
																	>
																		{!rowData.isProjectGroup ? (
																			<DeprecatedProjectIndicatorComponent
																				projectColor={rowData.color}
																				companyProjectId={rowData.id}
																				customProjectId={rowData.customProjectId}
																			/>
																		) : (
																			<DeprecatedProjectGroupIndicatorComponent
																				companyProjectGroupId={rowData.id}
																				projectGroupColor={rowData.color}
																			/>
																		)}
																		<div className="name">{rowData.name}</div>
																		<div className="minutes-allocated">
																			{rowData.minutesAllocated}
																		</div>
																	</div>
																</a>
															) : (
																<div key={this.getRowKey(rowData)}>
																	<div
																		className="utilization-data-section-row"
																		title={rowData.name}
																	>
																		{!rowData.isProjectGroup ? (
																			<DeprecatedProjectIndicatorComponent
																				projectColor={rowData.color}
																				companyProjectId={rowData.id}
																				customProjectId={rowData.customProjectId}
																			/>
																		) : (
																			<DeprecatedProjectGroupIndicatorComponent
																				companyProjectGroupId={rowData.id}
																				projectGroupColor={rowData.color}
																			/>
																		)}
																		<div className="name">{rowData.name}</div>
																		<div className="minutes-allocated">
																			{rowData.minutesAllocated}
																		</div>
																	</div>
																</div>
															)
														)}
													</div>
												</div>
											) : null}

											{this.getProjectAllocationDistribution(distributionData)}

											{distributionData.internalTime.length ? (
												<div
													className="utilization-data-section"
													style={{
														borderTop:
															distributionData.taskAllocation.length ||
															distributionData.projectAllocation.length
																? 'none'
																: undefined,
													}}
												>
													<div className="utilization-data-section-header">
														<div className="utilization-data-section-header-text">
															{formatMessage({id: 'common.internal_time'})}
														</div>
													</div>
													<div className="utilization-data-section-row-container idle">
														{distributionData.internalTime.map((rowData, index) => (
															<div
																key={'idle-time-' + index}
																className="utilization-data-section-row"
																title={rowData.name}
															>
																<div className="name">{rowData.name}</div>
																<div className="minutes-allocated">
																	{rowData.minutesAllocated}
																</div>
															</div>
														))}
													</div>
												</div>
											) : null}
											{distributionData.timeOff.length ? (
												<div
													className="utilization-data-section"
													style={{
														borderTop:
															distributionData.taskAllocation.length ||
															distributionData.projectAllocation.length ||
															distributionData.internalTime.length
																? 'none'
																: undefined,
													}}
												>
													<div className="utilization-data-section-header">
														<div className="utilization-data-section-header-text">
															{formatMessage({id: 'common.time_off'})}
														</div>
													</div>
													<div className="utilization-data-section-row-container time-off">
														{distributionData.timeOff.map((rowData, index) => (
															<div
																key={'time-off-' + index}
																className="utilization-data-section-row"
																title={rowData.name}
															>
																<div className="name">{rowData.name}</div>
																<div className="minutes-allocated">
																	{rowData.minutesAllocated}
																</div>
															</div>
														))}
													</div>
												</div>
											) : null}
											{distributionData.otherTime.length ? (
												<div
													className="utilization-data-section"
													style={{
														borderTop:
															distributionData.taskAllocation.length ||
															distributionData.projectAllocation.length ||
															distributionData.internalTime.length ||
															distributionData.timeOff.length
																? 'none'
																: undefined,
													}}
												>
													<div className="utilization-data-section-header">
														<div className="utilization-data-section-header-text">
															{formatMessage({id: 'common.select-other'})}
														</div>
													</div>
													<div className="utilization-data-section-row-container other-time">
														{distributionData.otherTime.map((rowData, index) => (
															<div
																key={'other-time-' + index}
																className="utilization-data-section-row"
																title={rowData.name}
															>
																<div className="name">{rowData.name}</div>
																<div className="minutes-allocated">
																	{rowData.minutesAllocated !== rowData.minutesAllocatedWin
																		? formatMessage(
																				{id: 'scheduling.minutes_win'},
																				{
																					minutesWin: rowData.minutesAllocatedWin,
																					minutes: rowData.minutesAllocated,
																				}
																		  )
																		: rowData.minutesAllocatedWin}
																</div>
															</div>
														))}
													</div>
												</div>
											) : null}
											{this.holidayEntries.length ? (
												<div className="utilization-data-section">
													<div className="utilization-data-section-header">
														<div className="utilization-data-section-header-text">
															{formatMessage({id: 'common.holidays'})}
														</div>
													</div>
													<div className="utilization-data-section-row-container time-off">
														{this.holidayEntries.map(entry => {
															const date = Util.CreateNonUtcMomentDate(
																entry.year,
																entry.month,
																entry.day
															);
															const workingHours = person.workingHours || person;
															const minutesAllocated = Util.calculateAvilableMinutes(
																date,
																date,
																workingHours.monday,
																workingHours.tuesday,
																workingHours.wednesday,
																workingHours.thursday,
																workingHours.friday,
																workingHours.saturday,
																workingHours.sunday
															);
															return (
																<div key={'holiday-' + entry.id}>
																	<div
																		className="utilization-data-section-row"
																		title={entry.name}
																	>
																		<div className="name">{entry.name}</div>
																		<div className="minutes-allocated">
																			{Util.convertMinutesToFullHour(
																				minutesAllocated,
																				this.props.intl
																			)}
																		</div>
																	</div>
																</div>
															);
														})}
													</div>
												</div>
											) : null}
										</ScrollContent>
									</CustomScrollDiv>
								</div>

								{hasFeatureFlag('capacity_planning_beta_2_improvements') && (
									<GraphSection>
										{showZeroAvailabilityMessage && (
											<ZeroAvailabilityMessageContainer>
												<Text color={'secondary'}>
													{formatMessage({id: 'scheduling.zero_availability_message'})}
												</Text>
											</ZeroAvailabilityMessageContainer>
										)}
										{showHoursInDateRangeGraph ? (
											<Graph>
												<GraphWorkingHoursLine style={{bottom: workingHoursLinePosition}} />
												<GraphAxis></GraphAxis>
												{this.getGraphBlocks(
													workingHoursLinePosition,
													hardAllocationsHeight,
													shouldShowTimeRegs,
													overAllocatedHeight,
													softAllocationsHeight,
													overAllocatedSoftAllocationsHeight
												)}
												{this.getGraphSteps(graphStepArray)}
												{this.getGraphDifferenceText(
													minutesAvailable,
													minutesAllocatedValue,
													workingHoursLinePosition,
													graphMaxValue,
													graphHeight
												)}
												<GraphFooter>
													{formatMessage({id: 'scheduling.hours_in_date_range'})}
												</GraphFooter>
											</Graph>
										) : null}
										<GraphLegendsContainer>
											{showRemainingAvailableTime ? (
												<GraphLegend>
													<GraphBlock
														blockColor={CSS_CONSTANTS.v2_button_very_light_grey}
														blockBorderRadius={2}
														blockHeight={16}
														blockWidth={16}
													/>
													<GraphLegendTitle>
														{formatMessage({id: 'scheduling.remaining_available_time'})}
													</GraphLegendTitle>
												</GraphLegend>
											) : null}

											{softAllocationsHeight > 0 && plannedTotalMinutesHard <= minutesAvailable ? (
												<GraphLegend>
													<GraphBlock
														background={this.getStripedBackground(
															CSS_CONSTANTS.schedule_legend_soft_background,
															CSS_CONSTANTS.schedule_legend_soft_stripe
														)}
														blockBorderRadius={2}
														blockHeight={16}
														blockWidth={16}
													/>
													<GraphLegendTitle>
														{formatMessage({id: 'allocation.soft_allocation'})}
													</GraphLegendTitle>
												</GraphLegend>
											) : null}

											{plannedTotalMinutesHard > 0 && minutesAvailable > 0 && (
												<GraphLegend>
													<GraphBlock
														blockColor={
															shouldShowTimeRegs
																? CSS_CONSTANTS.schedule_actual_blue
																: CSS_CONSTANTS.schedule_v2_green
														}
														blockBorderRadius={2}
														blockHeight={16}
														blockWidth={16}
													/>
													<GraphLegendTitle>
														{formatMessage({
															id: this.hasSoftAllocationModule
																? 'allocation.hard_allocation'
																: 'common.allocation',
														})}
													</GraphLegendTitle>
												</GraphLegend>
											)}

											{overAllocatedSoftAllocationsHeight > 0 && (
												<GraphLegend>
													<GraphBlock
														background={this.getStripedBackground(
															CSS_CONSTANTS.schedule_legend_soft_over_background,
															CSS_CONSTANTS.schedule_legend_soft_over_stripe
														)}
														blockBorderRadius={2}
														blockHeight={16}
														blockWidth={16}
													/>
													<GraphLegendTitle>
														{formatMessage({id: 'allocation.overallocated_soft'})}
													</GraphLegendTitle>
												</GraphLegend>
											)}

											{plannedTotalMinutesHard > minutesAvailable && (
												<GraphLegend>
													<GraphBlock
														blockColor={CSS_CONSTANTS.schedule_legend_soft_over_background}
														blockBorderRadius={2}
														blockHeight={16}
														blockWidth={16}
													/>
													<GraphLegendTitle>
														{formatMessage({id: 'allocation.overallocated_hard'})}
													</GraphLegendTitle>
												</GraphLegend>
											)}
										</GraphLegendsContainer>
									</GraphSection>
								)}

								{!hasFeatureFlag('capacity_planning_beta_2_improvements') && (
									<div className="graph-section">
										<div className="graph-header">
											<div className="graph-header-date">
												{formatDate(momentStartDate, {
													year: 'numeric',
													month: 'numeric',
													day: 'numeric',
												}) +
													(momentStartDate.isSame(momentEndDate, 'day')
														? ''
														: ' - ' +
														  formatDate(momentEndDate, {
																year: 'numeric',
																month: 'numeric',
																day: 'numeric',
														  }))}
											</div>
											{softAllocationsHeight > 0 || overAllocatedSoftAllocationsHeight > 0 ? (
												<>
													<div className="graph-striped-grey-block"></div>
													<div className="graph-header-soft-desc">Soft allocation</div>
												</>
											) : null}
										</div>
										<div className="graph">
											<div className="graph-axis" />
											{graphStepArray.map((stepData, index) => (
												<div key={index} className="graph-step" style={{bottom: stepData.position}}>
													{stepData.showInfinitySign ? (
														<div className="infinity-sign-container">
															<div className="infinity-sign" />
														</div>
													) : (
														<div>{stepData.value}</div>
													)}
													<div className="line-container">
														<div className="line" />
													</div>
												</div>
											))}
											<div className="working-hours-line" style={{bottom: workingHoursLinePosition}} />
											<div className="graph-grey-block" style={{height: workingHoursLinePosition}} />
											<div
												className="graph-green-block"
												style={{
													height: hardAllocationsHeight,
													bottom: 0,
													backgroundColor: shouldShowTimeRegs
														? CSS_CONSTANTS.schedule_actual_blue
														: CSS_CONSTANTS.schedule_v2_green,
												}}
											/>
											{softAllocationsHeight > 0 ? (
												<div
													className="graph-striped-green-block"
													style={{
														bottom: hardAllocationsHeight,
														height: softAllocationsHeight,
													}}
												/>
											) : null}
											{overAllocatedSoftAllocationsHeight > 0 ? (
												<div
													className="graph-striped-red-block"
													style={{
														bottom:
															workingHoursLinePosition +
															overAllocatedHeight -
															overAllocatedSoftAllocationsHeight,
														height: overAllocatedSoftAllocationsHeight,
													}}
												/>
											) : null}
											{overAllocatedHeight > 0 ? (
												<div
													className="graph-red-block"
													style={{
														bottom: workingHoursLinePosition,
														height: overAllocatedHeight,
														backgroundColor: shouldShowTimeRegs
															? CSS_CONSTANTS.schedule_actual_blue
															: CSS_CONSTANTS.schedule_v2_red,
													}}
												/>
											) : null}
											{minutesAvailable && minutesAllocatedValue !== minutesAvailable ? (
												<div
													className="difference-text"
													style={{
														bottom:
															minutesAvailable > minutesAllocatedValue
																? workingHoursLinePosition
																: workingHoursLinePosition +
																  ((minutesAllocatedValue - minutesAvailable) /
																		60 /
																		graphMaxValue) *
																		graphHeight,
													}}
												>
													{minutesAvailable > minutesAllocatedValue
														? Math.round((1 - 1 / (minutesAvailable / minutesAllocatedValue)) * 100)
														: Math.round((minutesAllocatedValue / minutesAvailable - 1) * 100)}
													%{' '}
													<span>
														{minutesAvailable > minutesAllocatedValue
															? formatMessage({id: 'scheduling.under'})
															: formatMessage({id: 'scheduling.over'})}
													</span>
												</div>
											) : null}
											<div className="graph-footer">
												{calcWin ? '* ' : ''}
												{daySpan > 10
													? formatMessage({id: 'scheduling.hours_month'})
													: daySpan < 4
													? formatMessage({id: 'scheduling.hours_day'})
													: formatMessage({id: 'scheduling.hours_week'})}
											</div>
										</div>
									</div>
								)}
							</div>
						</>
					) : (
						<EmptyState>
							<UtilizationModalEmptyStateGraphic />
							<span className="message">
								{formatMessage(
									{
										id: isUsingProjectAllocation
											? 'scheduling.person_has_nothing_allocated'
											: shouldShowTimeRegs
											? 'scheduling.person_has_no_time_reg'
											: 'scheduling.person_has_nothing_assigned',
									},
									{personName: person.name}
								)}
							</span>
							{!shouldShowTimeRegs &&
							!this.props.readOnly &&
							(hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE) || !isUsingProjectAllocation) &&
							!isAllocationParentDropdownEmpty ? (
								<span>
									{isUsingProjectAllocation
										? formatMessage({id: 'scheduling.add_allocations_using_button'})
										: formatMessage({id: 'scheduling.add_tasks_using_button'})}
								</span>
							) : null}
						</EmptyState>
					)}
				</DistributionSection>
			</UtilizationModalStyled>
		);
		return (
			<GenericModal
				closeModal={this.props.closeModal}
				content={content}
				className="utilization-modal"
				userPilot={'utilization-modal'}
			/>
		);
	}
}

UtilizationModal.propTypes = {
	person: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
	startDate: PropTypes.number.isRequired,
	endDate: PropTypes.number.isRequired,
	stepLabel: PropTypes.string.isRequired,
	minorStep: PropTypes.string.isRequired,
	project: PropTypes.object,
	minutesAllocated: PropTypes.number.isRequired,
	minutesAvailable: PropTypes.number.isRequired,
	distributionMap: PropTypes.object.isRequired,
	actualPersonId: PropTypes.string.isRequired,
	projects: PropTypes.array.isRequired,
	projectGroups: PropTypes.array.isRequired,
	projectPersons: PropTypes.array.isRequired,
	idleTimes: PropTypes.array.isRequired,
	persons: PropTypes.array,
	readOnly: PropTypes.bool.isRequired,
	disableLinks: PropTypes.bool,
	schedulingOptions: PropTypes.object,
	plannedTotalMinutesHard: PropTypes.number,
	plannedTotalMinutesSoft: PropTypes.number,
	plannedTotalMinutesSoftWin: PropTypes.number,
};

UtilizationModal.defaultPropTypes = {
	readOnly: false,
};

export default injectIntl(UtilizationModal);
