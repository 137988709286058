/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type plannedVsSpentComponent_viewer$ref: FragmentReference;
declare export opaque type plannedVsSpentComponent_viewer$fragmentType: plannedVsSpentComponent_viewer$ref;
export type plannedVsSpentComponent_viewer = {|
  +insightComponentsData: ?{|
    +plannedVsSpent: ?{|
      +minutesPlanned: ?number,
      +minutesSpent: ?number,
    |}
  |},
  +$refType: plannedVsSpentComponent_viewer$ref,
|};
export type plannedVsSpentComponent_viewer$data = plannedVsSpentComponent_viewer;
export type plannedVsSpentComponent_viewer$key = {
  +$data?: plannedVsSpentComponent_viewer$data,
  +$fragmentRefs: plannedVsSpentComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProjectGroupType",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "plannedVsSpentComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "isProjectGroupType",
              "variableName": "isProjectGroupType"
            },
            {
              "kind": "Variable",
              "name": "projectId",
              "variableName": "projectId"
            }
          ],
          "concreteType": "PlannedVsSpent",
          "kind": "LinkedField",
          "name": "plannedVsSpent",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minutesPlanned",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minutesSpent",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '3acd86073a9dfd7059b4882f42edd624';

module.exports = node;
