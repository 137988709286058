/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectPortfolioPeriodDownload_viewer$ref: FragmentReference;
declare export opaque type ProjectPortfolioPeriodDownload_viewer$fragmentType: ProjectPortfolioPeriodDownload_viewer$ref;
export type ProjectPortfolioPeriodDownload_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string,
    +programs: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +budgetType: ?PROGRAM_BUDGET_TYPE,
          +budgetValue: ?number,
          +totalAllocation: ?number,
        |}
      |}>
    |},
    +financialBasedProjects: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +companyProjectId: ?number,
          +customProjectId: ?string,
          +name: ?string,
          +budgetType: ?BUDGET_TYPE,
          +budgetBaseCurrency: ?number,
          +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
          +fullAccessToProject: ?boolean,
          +projectColor: ?string,
          +status: ?PROJECT_STATUS,
          +projectStartDay: ?number,
          +projectStartMonth: ?number,
          +projectStartYear: ?number,
          +projectEndDay: ?number,
          +projectEndMonth: ?number,
          +projectEndYear: ?number,
          +baselineWinChance: ?number,
          +priorityLevel: ?{|
            +id: string,
            +name: ?string,
          |},
          +completion: ?number,
          +progressEndOfTheMonth: ?number,
          +useBaseline: ?boolean,
          +rateCard: ?{|
            +id: string,
            +name: ?string,
            +currency: ?string,
            +parentRateCardId: ?string,
          |},
          +client: ?{|
            +id: string,
            +name: ?string,
            +logoId: ?string,
            +logoDefaultId: ?number,
          |},
          +projectLabels: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +label: ?{|
                  +name: ?string,
                  +color: ?string,
                |}
              |}
            |}>
          |},
          +projectPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +isContactPerson: ?boolean,
                +person: ?{|
                  +id: string,
                  +fullName: ?string,
                  +firstName: ?string,
                  +lastName: ?string,
                  +profilePictureId: ?string,
                  +profilePictureDefaultId: ?number,
                  +initials: ?string,
                |},
              |}
            |}>
          |},
          +currentProjectStatus: ?{|
            +id: string,
            +color: ?string,
            +description: ?string,
            +person: ?{|
              +id: string,
              +firstName: ?string,
              +lastName: ?string,
              +fullName: ?string,
              +profilePictureId: ?string,
            |},
          |},
          +financialNumbers: ?{|
            +billableActualTimeAndExpenses: ?number,
            +totalActualRevenueRecognition: ?number,
            +actualCost: ?number,
            +actualProfit: ?number,
            +actualRevenueProfit: ?number,
            +actualMargin: ?number,
            +actualRevenueMargin: ?number,
            +billablePlannedTimeAndExpenses: ?number,
            +plannedRevenue: ?number,
            +plannedCost: ?number,
            +plannedProfit: ?number,
            +plannedRevenueProfit: ?number,
            +plannedMargin: ?number,
            +plannedRevenueMargin: ?number,
            +billableForecastTimeAndExpensesToComplete: ?number,
            +totalForecastRevenueToComplete: ?number,
            +forecastCostToComplete: ?number,
            +forecastProfitToComplete: ?number,
            +forecastRevenueProfitToComplete: ?number,
            +forecastMarginToComplete: ?number,
            +forecastRevenueMarginToComplete: ?number,
            +billableTotalTimeAndExpensesAtCompletion: ?number,
            +nonBillableTotalTimeAndExpensesAtCompletion: ?number,
            +allTotalTimeAndExpensesAtCompletion: ?number,
            +totalRevenueRecognition: ?number,
            +totalCostAtCompletion: ?number,
            +totalProfitAtCompletion: ?number,
            +totalRevenueProfitAtCompletion: ?number,
            +totalMarginAtCompletion: ?number,
            +totalRevenueMarginAtCompletion: ?number,
            +baselineRevenue: ?number,
            +baselineTimeAndExpenses: ?number,
            +baselineCost: ?number,
            +baselineProfit: ?number,
            +baselineMargin: ?number,
            +baselineMinutes: ?number,
            +planVsBillableActualTimeAndExpenses: ?number,
            +planVsActualCost: ?number,
            +planVsActualProfit: ?number,
            +planVsTotalBillableTimeAndExpensesAtCompletion: ?number,
            +planVsTotalCostAtCompletion: ?number,
            +planVsTotalProfitAtCompletion: ?number,
            +registeredMinutes: ?number,
            +forecastTimeToComplete: ?number,
            +totalTimeAtCompletion: ?number,
            +scopeApprovedMinutes: ?number,
            +scopeTotalMinutes: ?number,
            +allocationMinutes: ?number,
            +invoiced: ?number,
            +paid: ?number,
            +accruedDeferred: ?number,
            +retainerPeriodTargetMinutes: ?number,
            +retainerPeriodTargetPrice: ?number,
            +recognitionLockedRevenue: ?number,
            +recognitionOpenRevenue: ?number,
            +recognitionProfit: ?number,
            +recognitionProfitPercentage: ?number,
            +projectedTotalRevenueVsProjectedTotalBillableValueOfService: ?number,
            +projectedTotalRevenueVsProjectedTotalValueOfService: ?number,
          |},
        |}
      |}>
    |},
  |},
  +$refType: ProjectPortfolioPeriodDownload_viewer$ref,
|};
export type ProjectPortfolioPeriodDownload_viewer$data = ProjectPortfolioPeriodDownload_viewer;
export type ProjectPortfolioPeriodDownload_viewer$key = {
  +$data?: ProjectPortfolioPeriodDownload_viewer$data,
  +$fragmentRefs: ProjectPortfolioPeriodDownload_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v3 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v4 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v5 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v6 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v7 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v8 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPortfolioPeriodDownload_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectServiceProgramTypeConnection",
          "kind": "LinkedField",
          "name": "programs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProgramType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "budgetValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalAllocation",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "after",
              "variableName": "cursor"
            },
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "pageSize"
            },
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "concreteType": "ProjectTypeConnection",
          "kind": "LinkedField",
          "name": "financialBasedProjects",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyProjectId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "customProjectId",
                      "storageKey": null
                    },
                    (v9/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "budgetBaseCurrency",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "defaultPeriodBudgetType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullAccessToProject",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectColor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectStartDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectStartMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectStartYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectEndDay",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectEndMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectEndYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineWinChance",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PriorityLevel",
                      "kind": "LinkedField",
                      "name": "priorityLevel",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v9/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "completion",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "progressEndOfTheMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "useBaseline",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RateCard",
                      "kind": "LinkedField",
                      "name": "rateCard",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v9/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "parentRateCardId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Client",
                      "kind": "LinkedField",
                      "name": "client",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "logoId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "logoDefaultId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 1000
                        }
                      ],
                      "concreteType": "ProjectLabelTypeConnection",
                      "kind": "LinkedField",
                      "name": "projectLabels",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectLabelTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectLabel",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Label",
                                  "kind": "LinkedField",
                                  "name": "label",
                                  "plural": false,
                                  "selections": [
                                    (v9/*: any*/),
                                    (v10/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "projectLabels(first:1000)"
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "contactsOnly",
                          "value": true
                        },
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 1000000
                        }
                      ],
                      "concreteType": "ProjectPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "projectPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isContactPerson",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v11/*: any*/),
                                    (v12/*: any*/),
                                    (v13/*: any*/),
                                    (v14/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "profilePictureDefaultId",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "initials",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "projectPersons(contactsOnly:true,first:1000000)"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectStatus",
                      "kind": "LinkedField",
                      "name": "currentProjectStatus",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v10/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Person",
                          "kind": "LinkedField",
                          "name": "person",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v12/*: any*/),
                            (v13/*: any*/),
                            (v11/*: any*/),
                            (v14/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/)
                      ],
                      "concreteType": "FinancialNumbers",
                      "kind": "LinkedField",
                      "name": "financialNumbers",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableActualTimeAndExpenses",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalActualRevenueRecognition",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualCost",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualRevenueProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualMargin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualRevenueMargin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billablePlannedTimeAndExpenses",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedCost",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedRevenueProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedMargin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedRevenueMargin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableForecastTimeAndExpensesToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalForecastRevenueToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastCostToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastProfitToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastRevenueProfitToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastMarginToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastRevenueMarginToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableTotalTimeAndExpensesAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nonBillableTotalTimeAndExpensesAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "allTotalTimeAndExpensesAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalRevenueRecognition",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalCostAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalProfitAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalRevenueProfitAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalMarginAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalRevenueMarginAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineTimeAndExpenses",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineCost",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineMargin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsBillableActualTimeAndExpenses",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsActualCost",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsActualProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsTotalBillableTimeAndExpensesAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsTotalCostAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsTotalProfitAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "registeredMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTimeToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalTimeAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "scopeApprovedMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "scopeTotalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "allocationMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "invoiced",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "paid",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "accruedDeferred",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "retainerPeriodTargetMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "retainerPeriodTargetPrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionLockedRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionOpenRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionProfitPercentage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectedTotalRevenueVsProjectedTotalBillableValueOfService",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectedTotalRevenueVsProjectedTotalValueOfService",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0e5109ae7a96ca9a532cfbc3a6497b4';

module.exports = node;
