export function isEventFromScheduling(args) {
	return args?.EVENT_FROM_SCHEDULING;
}

export function removeFromArray(array, predicate, replaceWith = null) {
	if (!Array.isArray(array)) {
		return;
	}
	const index = array.findIndex(predicate);
	if (index > -1) {
		if (replaceWith) {
			return array.splice(index, 1, replaceWith);
		} else {
			return array.splice(index, 1);
		}
	}
	return [];
}

export function customAndCompanyDoesNotMatch(project, projectId) {
	return project.customProjectId !== projectId && project.companyProjectId !== parseInt(projectId, 10);
}

export function customOrCompanyIdMatches(project, projectId) {
	return project.customProjectId === projectId || project.companyProjectId === parseInt(projectId, 10);
}

export function findProject(projects, projectId) {
	return projects.find(p => customOrCompanyIdMatches(p, projectId));
}
