import React, { useEffect, useMemo, useRef } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { Breadcrumbs, FlexColumn, Heading, PinnableSummaryCards, TopNavigation } from '@forecast-it/design-system';
import { FormattedMessage } from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import { useIntl } from 'react-intl';
import Styled from 'styled-components';
import Util from '../../forecast-app/shared/util/util';
import { BUDGET_TYPE } from '../../constants';
import { hasFeatureFlag } from '../../forecast-app/shared/util/FeatureUtil';
import { getGroupedFinancialNumbersByPhaseAndBaselineId } from '../initial-plan/InitialPlanUtil';
import ProjectIndicator from '../../forecast-app/shared/components/project-indicator/ProjectIndicator';
import ActionBar from '../../forecast-app/navigation/header/ActionBar';
import { useHistory } from 'react-router-dom';
import { projectUrl } from '../../directApi';
import { showModal, MODAL_TYPE } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
const PageWrapper = Styled.div `
  padding: 32px;
`;
const BreadcrumbsWrapper = Styled.div `
  padding-bottom: 24px;	
`;
const BaselineEditPage = ({ viewer }) => {
    var _a, _b;
    const intl = useIntl();
    const { formatMessage } = intl;
    const history = useHistory();
    const scrollRef = useRef(null);
    const { company, project } = viewer;
    const isFixedPrice = (project === null || project === void 0 ? void 0 : project.budgetType) === BUDGET_TYPE.FIXED_PRICE || (project === null || project === void 0 ? void 0 : project.budgetType) === BUDGET_TYPE.FIXED_PRICE_V2;
    const { totalFinancialNumbers } = getGroupedFinancialNumbersByPhaseAndBaselineId(project === null || project === void 0 ? void 0 : project.groupedFinancialNumbers);
    useEffect(() => {
        document.title = 'Baseline - Edit';
    }, []);
    const currencySymbol = Util.GetCurrencySymbol((project === null || project === void 0 ? void 0 : project.rateCard) ? project === null || project === void 0 ? void 0 : project.rateCard.currency : company === null || company === void 0 ? void 0 : company.currency);
    const baselineTotalCost = useMemo(() => {
        return hasFeatureFlag('baseline_financial_service') ? totalFinancialNumbers.totalCost : project === null || project === void 0 ? void 0 : project.baselineCost;
    }, [totalFinancialNumbers.totalCost, project === null || project === void 0 ? void 0 : project.baselineCost]);
    const baselineTotalProfit = useMemo(() => {
        const budget = (project === null || project === void 0 ? void 0 : project.budget) || 0;
        return hasFeatureFlag('baseline_financial_service')
            ? isFixedPrice && (project === null || project === void 0 ? void 0 : project.useFixedPriceForBaselineRevenue)
                ? budget - totalFinancialNumbers.totalCost
                : totalFinancialNumbers.totalProfit
            : project === null || project === void 0 ? void 0 : project.baselineProfit;
    }, [project === null || project === void 0 ? void 0 : project.budget, project === null || project === void 0 ? void 0 : project.baselineProfit, project === null || project === void 0 ? void 0 : project.useFixedPriceForBaselineRevenue, totalFinancialNumbers]);
    const baselineTotalMargin = useMemo(() => {
        const budget = (project === null || project === void 0 ? void 0 : project.budget) || 0;
        return (project === null || project === void 0 ? void 0 : project.useFixedPriceForBaselineRevenue) && isFixedPrice
            ? (budget - totalFinancialNumbers.totalCost) / budget
            : isNaN(totalFinancialNumbers.totalMargin)
                ? 0
                : totalFinancialNumbers.totalMargin;
    }, [project === null || project === void 0 ? void 0 : project.useFixedPriceForBaselineRevenue, totalFinancialNumbers]);
    const onClickSave = () => {
        return null;
    };
    const onClickCancel = () => {
        showModal({
            type: MODAL_TYPE.EXIT_ACTION_BAR_MODAL,
            callback: () => {
                history.push(project ? `${projectUrl(project.companyProjectId, project.customProjectId)}/baseline` : ``);
            },
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TopNavigation, null,
            React.createElement(ActionBar, { onClickSave: onClickSave, onClickCancel: onClickCancel })),
        React.createElement(CustomScrollDiv, { setScrollbarsRef: node => {
                scrollRef.current = node;
            } },
            React.createElement(PageWrapper, null,
                React.createElement(BreadcrumbsWrapper, null,
                    React.createElement(Breadcrumbs, null,
                        React.createElement(Breadcrumbs.Item, null,
                            React.createElement(FormattedMessage, { id: "common.all_projects" })),
                        project && (React.createElement(Breadcrumbs.Item, null,
                            React.createElement("div", null,
                                project.name,
                                React.createElement(ProjectIndicator, { project: project })))),
                        React.createElement(Breadcrumbs.Item, null,
                            React.createElement(FormattedMessage, { id: "baseline.edit_baseline.title" })))),
                React.createElement(FlexColumn, { gap: 'l' },
                    React.createElement(Heading, { size: '3' },
                        React.createElement(FormattedMessage, { id: "baseline.edit_baseline.title" })),
                    React.createElement(PinnableSummaryCards, { topOffset: 57, summaryData: [
                            {
                                title: formatMessage({ id: 'common.total_hours' }),
                                value: (project === null || project === void 0 ? void 0 : project.baselineEstimationMinutesPerDay) !== null
                                    ? Util.convertMinutesToDays((_a = project === null || project === void 0 ? void 0 : project.baselineTargetMinutes) !== null && _a !== void 0 ? _a : 0, project === null || project === void 0 ? void 0 : project.baselineEstimationMinutesPerDay, intl)
                                    : Util.convertMinutesToFullHour(((_b = project === null || project === void 0 ? void 0 : project.baselineTargetMinutes) !== null && _b !== void 0 ? _b : 0) * 60, intl),
                            },
                            {
                                title: formatMessage({ id: 'common.total_value_of_service' }),
                                value: Util.getFormattedNumberWithCurrency(currencySymbol, totalFinancialNumbers.totalBaselineTimeAndExpenses, intl),
                            },
                            {
                                title: formatMessage({ id: 'common.total_revenue_capitalized' }),
                                value: Util.getFormattedNumberWithCurrency(currencySymbol, totalFinancialNumbers.totalBaselineRevenue, intl),
                            },
                            {
                                title: formatMessage({ id: 'common.total_cost' }),
                                value: Util.getFormattedNumberWithCurrency(currencySymbol, baselineTotalCost, intl),
                            },
                            {
                                title: formatMessage({ id: 'common.total_profit' }),
                                value: Util.getFormattedNumberWithCurrency(currencySymbol, baselineTotalProfit, intl),
                            },
                            {
                                title: formatMessage({ id: 'common.total_margin' }),
                                value: Util.getFormattedPercentage(baselineTotalMargin, intl),
                            },
                        ] }))))));
};
export default createRefetchContainer(BaselineEditPage, {
    viewer: graphql `
			fragment BaselineEditPage_viewer on Viewer @argumentDefinitions(projectId: {type: "String"}) {
				id
				company {
					id
					name
					currency
				}
				project(id: $projectId) {
					...ProjectIndicator_project
					useBaseline
					useDeliverables
					budgetType
					budget
					id
					status
					companyProjectId
					customProjectId
					projectColor
					name
					baselineEstimationMinutesPerDay
					projectStartYear
					projectStartMonth
					projectStartDay
					projectEndYear
					projectEndMonth
					projectEndDay
					baselineTargetMinutes
					baselineTarget
					baselineCost
					baselineProfit
					baselineAdjustPercentage
					baselineAdjustPrice
					baselineTargetPrice
					useFixedPriceForBaselineRevenue
					rateCard {
						id
						currency
						disabledRoles {
							id
						}
					}
					groupedFinancialNumbers(
						convertToProjectCurrency: true
						groupBy: ["PHASE_BASELINE_ROLE", "PHASE_BASELINE_EXPENSE", "PHASE"]
					) {
						baselineMinutes
						baselineRevenue
						baselineTimeAndExpenses
						baselineCost
						baselineProfit
						baselineMargin
						baselineRatePerHour
						baselineCostPerHour
						phaseBaselineRoleId
						phaseBaselineExpenseId
						phaseId
					}
				}
			}
		`,
}, graphql `
		query BaselineEditPageRefetchQuery($projectId: String) {
			viewer {
				component(name: "edit_baseline")
				...BaselineEditPage_viewer @arguments(projectId: $projectId)
			}
		}
	`);
