import React, {useEffect, useRef, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {CaretIcon} from 'web-components';
import {DATE_PICKER_STYLE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import DatePicker from '../../forecast-app/shared/components/date-picker/date_picker_v3';
import {InitialPlanAddItem} from './InitialPlanAddItem';
import {
	CaretWrapper,
	CategoryTitleWrapper,
	CategoryWrapper,
	ColumnTitleItem,
	InnerPhaseContainer,
	PhaseContainer,
	PhaseHeader,
	PhaseHeaderItem,
	PhaseHeaderSection,
	PhaseSubSeciontTitle,
	RoleRowContainer,
} from './InitialPlan.styled';
import RoleRow from './RoleRow';
import {ValueCell} from './ValueCell';
import {ITEM_CATEGORY, showModalDeletePhase, togglePhase} from './InitialPlanUtil';
import {changePhaseDates} from './InitialPlanLogic';
import UpdatePhaseMutation from '../../mutations/update_phase_mutation';
import ExpenseRow from './ExpenseRow';
import ActionsMenu from '../../forecast-app/shared/components/action-menu/actions_menu';
import ProjectUtil from '../../forecast-app/shared/util/project_util';
import {isDateDisabled} from '../../forecast-app/shared/util/DateUtil';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {idFromGlobalId} from '../../forecast-app/shared/util/GlobalIdUtil';
import CompanySetupUtil from '../../forecast-app/shared/util/CompanySetupUtil';

const subSectionTitleTopPosition = 50;

const PhaseSection = ({intl, titleId, isExpanded, toggleExpanded, children}) => {
	return (
		<CategoryWrapper>
			<CategoryTitleWrapper topPosition={subSectionTitleTopPosition} isExpanded={isExpanded}>
				<PhaseSubSeciontTitle>{intl.formatMessage({id: titleId})}</PhaseSubSeciontTitle>
				<CaretWrapper>
					<CaretIcon
						size={CaretIcon.SIZE.STANDARD}
						direction={isExpanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
						onClick={toggleExpanded}
					/>
				</CaretWrapper>
			</CategoryTitleWrapper>
			<RoleRowContainer>{children}</RoleRowContainer>
		</CategoryWrapper>
	);
};

const Phase = ({
	intl,
	project,
	phase,
	projectColor,
	roles,
	disabledRoleIds,
	expenseCategories,
	currencySymbol,
	enabledColumnEntries,
	theEyeOptions,
	isCollapsed,
	toggleCollapseAll,
	phaseFinacialNumbers,
	rateCardUtil,
}) => {
	const [isCollapsedState, setIsCollapsedState] = useState(isCollapsed);
	const [inputPhaseTitle, setInputPhaseTitle] = useState(phase.name || '');
	const [roleCategoryExpanded, setRoleCategoryExpanded] = useState(true);
	const [expenseCategoryExpanded, setExpenseCategoryExpanded] = useState(true);

	const nameInputRef = useRef(null);

	const expenseCategoryOptions = expenseCategories
		.filter(expense => !expense.node.disabled)
		.map(expense => {
			return {value: expense.node.id, label: expense.node.name};
		});

	useEffect(() => {
		setIsCollapsedState(isCollapsed);
	}, [isCollapsed]);

	const handleExpansion = e => {
		// Calendar is fuuuuun
		if (
			e.target &&
			(e.target.tagName === 'INPUT' ||
				e.target.tagName === 'TH' ||
				(e.target.parentNode &&
					e.target.parentNode.className &&
					e.target.parentNode.className.toLowerCase &&
					e.target.parentNode.className.toLowerCase().includes('calendar')) ||
				(e.target.className &&
					e.target.className.toLowerCase &&
					(e.target.className.toLowerCase().includes('calendar') ||
						e.target.className.toLowerCase().includes('-control') ||
						e.target.className.toLowerCase().includes('clear-dates'))))
		)
			return;
		togglePhase(project.id, phase.id);
		toggleCollapseAll();
		setIsCollapsedState(!isCollapsedState);
	};

	const handleDateChange = (startDate, endDate) => {
		const mutationObject = {
			id: phase.id,
			baselineStartYear: startDate.year(),
			baselineStartMonth: startDate.month() + 1,
			baselineStartDay: startDate.date(),
			baselineDeadlineYear: endDate.year(),
			baselineDeadlineMonth: endDate.month() + 1,
			baselineDeadlineDay: endDate.date(),
		};
		if (project.synchBaselineAndScopingDates) {
			mutationObject.startYear = startDate.year();
			mutationObject.startMonth = startDate.month() + 1;
			mutationObject.startDay = startDate.date();
			mutationObject.deadlineYear = endDate.year();
			mutationObject.deadlineMonth = endDate.month() + 1;
			mutationObject.deadlineDay = endDate.date();
		}
		changePhaseDates(mutationObject);
	};

	const handleClearDates = () => {
		const mutationObject = {
			id: phase.id,
			baselineStartYear: null,
			baselineStartMonth: null,
			baselineStartDay: null,
			baselineDeadlineYear: null,
			baselineDeadlineMonth: null,
			baselineDeadlineDay: null,
		};
		if (project.synchBaselineAndScopingDates) {
			mutationObject.startYear = null;
			mutationObject.startMonth = null;
			mutationObject.startDay = null;
			mutationObject.deadlineYear = null;
			mutationObject.deadlineMonth = null;
			mutationObject.deadlineDay = null;
		}
		changePhaseDates(mutationObject);
	};

	const handleSavePhaseTitle = () => {
		if (inputPhaseTitle !== phase.name) {
			Util.CommitMutation(UpdatePhaseMutation, {
				id: phase.id,
				name: inputPhaseTitle,
			});
		}
	};

	const handleKeyPressName = e => {
		if (e.key === 'Enter' && nameInputRef.current) {
			nameInputRef.current.blur();
		}
	};

	const phaseStartDate = phase.baselineStartYear
		? Util.CreateNonUtcMomentDate(phase.baselineStartYear, phase.baselineStartMonth, phase.baselineStartDay)
		: Util.CreateNonUtcMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay);
	const phaseDeadlineDate = phase.baselineDeadlineYear
		? Util.CreateNonUtcMomentDate(phase.baselineDeadlineYear, phase.baselineDeadlineMonth, phase.baselineDeadlineDay)
		: Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay);
	const getElemProps = value => {
		return theEyeOptions[value - 1];
	};

	const getContentForHeaderCol = column => {
		switch (column) {
			case 'dates':
				return (
					<DatePicker
						calendarOffsetX={-215}
						id={phase.id}
						isNewDateRange={true}
						startDate={phaseStartDate}
						endDate={phaseDeadlineDate}
						handleDateRangeChange={handleDateChange}
						clearable={true}
						datePickerStyle={DATE_PICKER_STYLE.BASELINE_PHASE_DATE}
						colorInherited={true}
						compactShowYear={true}
						overwriteDates={true}
						clearBothDates={handleClearDates}
						isCalculated={!phase.baselineStartYear || !phase.baselineDeadlineYear}
						disabledDates={isDateDisabled}
					/>
				);
			case 'estimate':
				return (
					<ValueCell
						cy={'phase-hours-sum'}
						type={
							project.baselineEstimationMinutesPerDay !== null
								? ValueCell.VALUE_TYPE.DAYS
								: ValueCell.VALUE_TYPE.TIME
						}
						value={phase.baselineTargetMinutes / 60}
						minutesPerDay={project.baselineEstimationMinutesPerDay}
					></ValueCell>
				);
			case 'value_of_service':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={phaseFinacialNumbers ? phaseFinacialNumbers.totalBaselineTimeAndExpenses : 0}
						currencySymbol={currencySymbol}
					></ValueCell>
				);
			case 'revenue':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={
							hasFeatureFlag('baseline_financial_service')
								? phaseFinacialNumbers
									? phaseFinacialNumbers.totalBaselineRevenue
									: 0
								: phase.baselineTargetPrice
						}
						currencySymbol={currencySymbol}
					></ValueCell>
				);
			case 'cost':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={
							hasFeatureFlag('baseline_financial_service')
								? phaseFinacialNumbers
									? phaseFinacialNumbers.totalCost
									: 0
								: phase.baselineCost
						}
						currencySymbol={currencySymbol}
					></ValueCell>
				);
			case 'profit':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={
							hasFeatureFlag('baseline_financial_service')
								? phaseFinacialNumbers
									? phaseFinacialNumbers.totalProfit
									: 0
								: phase.baselineProfit
						}
						currencySymbol={currencySymbol}
					></ValueCell>
				);
			case 'margin':
				// Calculate margin
				let margin = hasFeatureFlag('baseline_financial_service')
					? phaseFinacialNumbers
						? phaseFinacialNumbers.totalMargin
						: 0.0
					: phase.baselineProfit / phase.baselineTargetPrice;

				// Ensure pretty format
				if (isNaN(margin) || !isFinite(margin)) {
					margin = 0;
				}
				return <ValueCell type={ValueCell.VALUE_TYPE.PERCENTAGE} value={margin}></ValueCell>;
			default:
				return null;
		}
	};

	const getElemForHeaderCol = (column, value, index, projectTextColor) => {
		if (!value) return null;
		const elemProps = getElemProps(value);
		return (
			<PhaseHeaderItem
				key={`header-${column}`}
				align={elemProps.align}
				width={elemProps.width}
				projectTextColor={projectTextColor}
				projectColor={projectColor}
			>
				{getContentForHeaderCol(column, value)}
			</PhaseHeaderItem>
		);
	};

	const getElemForTableHeaderCol = (column, value, type) => {
		if (!value) return null;
		const elemProps = getElemProps(value);

		return (
			<ColumnTitleItem
				key={`table-header-${column}`}
				align={elemProps.align}
				width={elemProps.width}
				paddingLeft={elemProps.leftOffset}
			>
				{column === 'dates' ||
				(type === 'expense' && column === 'costPH') ||
				(type === 'expense' && column === 'ratePH') ||
				(type === 'expense' && column === 'estimate') ||
				(type === 'role' && column === 'markup')
					? null
					: intl.formatMessage({id: elemProps.translationId})}
			</ColumnTitleItem>
		);
	};

	const getActionMenuOptions = () => {
		const actionMenu = [];
		const formatMessage = intl.formatMessage;

		actionMenu.push({
			text: formatMessage({id: 'common.delete'}),
			onClick: () => showModalDeletePhase(phase.id, project.id, formatMessage),
			callback: () => showModalDeletePhase(phase.id, project.id, formatMessage),
			cy: 'delete-btn',
		});

		return actionMenu;
	};

	const projectTextColor = Util.getTextColorV2(projectColor);

	return (
		<PhaseContainer data-userpilot={'baseline-phase'} data-cy={'baseline-phase'}>
			<PhaseHeaderSection
				backgroundColor={projectColor}
				color={projectTextColor}
				topPosition={16}
				onClick={handleExpansion}
			>
				<PhaseHeader>
					<PhaseHeaderItem width={150} minWidth={245} growable projectTextColor={projectTextColor}>
						<input
							type="text"
							value={inputPhaseTitle}
							onClick={e => e.stopPropagation()}
							onChange={e => setInputPhaseTitle(e.target.value)}
							onBlur={handleSavePhaseTitle.bind(this)}
							onKeyDown={e => handleKeyPressName(e)}
							maxLength={191}
							spellCheck={false}
							ref={nameInputRef}
						/>
					</PhaseHeaderItem>
					{enabledColumnEntries.map(([col, val], index) => getElemForHeaderCol(col, val, index, projectTextColor))}
					<PhaseHeaderItem
						data-userpilot="baseline-phase-toggle"
						data-cy="baseline-phase-toggle"
						align="right"
						width={45}
					>
						<CaretIcon
							size={CaretIcon.SIZE.STANDARD}
							color={projectTextColor}
							direction={isCollapsedState ? CaretIcon.DIRECTION.DOWN : CaretIcon.DIRECTION.UP}
						/>
					</PhaseHeaderItem>
					<PhaseHeaderItem align="right" width={20}>
						<ActionsMenu
							useBlackIcon={projectTextColor === '#000000'}
							whiteInner={true}
							isWhite={projectTextColor === '#ffffff'}
							options={getActionMenuOptions()}
						/>
					</PhaseHeaderItem>
				</PhaseHeader>
			</PhaseHeaderSection>
			{!isCollapsedState && (
				<InnerPhaseContainer data-userpilot={'baseline-phase-inner-section'}>
					<PhaseSection
						key={`${phase.id}-role-section`}
						intl={intl}
						type="role"
						titleId="baseline.work"
						enabledColumnEntries={enabledColumnEntries}
						getElemForTableHeaderCol={getElemForTableHeaderCol}
						isExpanded={roleCategoryExpanded}
						toggleExpanded={() => setRoleCategoryExpanded(!roleCategoryExpanded)}
					>
						{roleCategoryExpanded && (
							<>
								{phase.phaseBaselineRoles.edges.map(phaseBaselineRole => (
									<RoleRow
										theEyeOptions={theEyeOptions}
										key={phaseBaselineRole.node.id}
										intl={intl}
										phaseBaselineRole={phaseBaselineRole.node}
										currencySymbol={currencySymbol}
										projectId={project.id}
										minutesPerDay={project.baselineEstimationMinutesPerDay}
										enabledColumnEntries={enabledColumnEntries}
										roles={roles}
										disabledRoleIds={disabledRoleIds}
										roleFinancials={phaseFinacialNumbers?.roles.get(
											idFromGlobalId(phaseBaselineRole.node.id)
										)}
									/>
								))}
								<InitialPlanAddItem
									intl={intl}
									minutesPerDay={project.baselineEstimationMinutesPerDay}
									projectId={project.id}
									nonBillable={!ProjectUtil.projectTracksRevenue(project)}
									phaseId={phase.id}
									roles={roles.filter(role => !disabledRoleIds.includes(role.node.id))}
									expenseCategoryOptions={expenseCategoryOptions}
									currencySymbol={currencySymbol}
									rateCard={project.rateCard}
									itemCategory={ITEM_CATEGORY.ROLE}
								></InitialPlanAddItem>
							</>
						)}
					</PhaseSection>

					{CompanySetupUtil.hasFinance() && (
						<PhaseSection
							key={`${phase.id}-expense-section`}
							intl={intl}
							type="expense"
							titleId="project_budget.expenses"
							enabledColumnEntries={enabledColumnEntries}
							getElemForTableHeaderCol={getElemForTableHeaderCol}
							isExpanded={expenseCategoryExpanded}
							toggleExpanded={() => setExpenseCategoryExpanded(!expenseCategoryExpanded)}
						>
							{expenseCategoryExpanded && (
								<>
									{phase.phaseBaselineExpenses.edges.map(phaseBaselineExpense => (
										<ExpenseRow
											theEyeOptions={theEyeOptions}
											key={phaseBaselineExpense.node.id}
											intl={intl}
											phaseBaselineExpense={phaseBaselineExpense.node}
											currencySymbol={currencySymbol}
											projectId={project.id}
											enabledColumnEntries={enabledColumnEntries}
											expenseCategoryOptions={expenseCategories}
											expenseFinancials={phaseFinacialNumbers?.expenses.get(
												idFromGlobalId(phaseBaselineExpense.node.id)
											)}
										/>
									))}
									<InitialPlanAddItem
										intl={intl}
										projectId={project.id}
										nonBillable={!ProjectUtil.projectTracksRevenue(project)}
										phaseId={phase.id}
										roles={roles}
										expenseCategoryOptions={expenseCategoryOptions}
										currencySymbol={currencySymbol}
										rateCard={project.rateCard}
										itemCategory={ITEM_CATEGORY.EXPENSE}
									></InitialPlanAddItem>
								</>
							)}
						</PhaseSection>
					)}
				</InnerPhaseContainer>
			)}
		</PhaseContainer>
	);
};

export default createFragmentContainer(Phase, {
	phase: graphql`
		fragment Phase_phase on PhaseType {
			id
			name
			baselineTargetPrice
			baselineTargetMinutes
			baselineCost
			baselineProfit
			startYear
			startMonth
			startDay
			deadlineYear
			deadlineMonth
			deadlineDay
			baselineStartYear
			baselineStartMonth
			baselineStartDay
			baselineDeadlineYear
			baselineDeadlineMonth
			baselineDeadlineDay
			phaseBaselineRoles(first: 100000) @connection(key: "Phase_phaseBaselineRoles", filters: []) {
				edges {
					node {
						id
						...RoleRow_phaseBaselineRole
					}
				}
			}
			phaseBaselineExpenses(first: 100000) @connection(key: "Phase_phaseBaselineExpenses", filters: []) {
				edges {
					node {
						id
						...ExpenseRow_phaseBaselineExpense
					}
				}
			}
		}
	`,
});
