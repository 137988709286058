/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ClientDropdown_clients$ref = any;
type LabelDropdown_labels$ref = any;
type ProjectContactDropdown_projects$ref = any;
type RateCardDropdown_rateCards$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type FILTER_SECTION = "FINANCIAL_PORTFOLIO_REPORT" | "INVOICING_INVOICES" | "INVOICING_OVERVIEW" | "LABELS" | "MY_LIST" | "MY_TASKS" | "MY_TIMESHEETS" | "MY_WORK" | "PROJECT" | "PROJECTS_OVERVIEW" | "PROJECT_TIMELINE" | "REPORTED_TIME_TABLE" | "SCHEDULING_CAPACITY_OVERVIEW" | "SCHEDULING_PEOPLE" | "SCHEDULING_PLACEHOLDERS" | "SCHEDULING_PROJECTS" | "SCOPING" | "SKILLS" | "SPRINT" | "SPRINT_BACKLOG" | "TASK_REPORT" | "TEAM_TIMESHEET" | "TIME_APPROVAL" | "UTILIZATION_REPORT" | "WORKFLOW" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type financialReportPage_viewer$ref: FragmentReference;
declare export opaque type financialReportPage_viewer$fragmentType: financialReportPage_viewer$ref;
export type financialReportPage_viewer = {|
  +id: string,
  +backendId: ?number,
  +firstName: ?string,
  +actualPersonId: ?string,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +client: ?{|
    +id: string
  |},
  +filters: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +section: ?FILTER_SECTION,
        +value: ?string,
        +updatedAt: ?string,
      |}
    |}>
  |},
  +company: ?{|
    +currency: ?string,
    +xeroEnabled: ?boolean,
    +isUsingSchedulingPlanMode: ?boolean,
    +isUsingProjectAllocation: ?boolean,
    +isUsingMixedAllocation: ?boolean,
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +labels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +color: ?string,
          +$fragmentRefs: LabelDropdown_labels$ref,
        |}
      |}>
    |},
    +clients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +logoId: ?string,
          +logoDefaultId: ?number,
        |},
        +$fragmentRefs: ClientDropdown_clients$ref,
      |}>
    |},
    +rateCards: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +parentRateCardId: ?string,
        |},
        +$fragmentRefs: RateCardDropdown_rateCards$ref,
      |}>
    |},
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
        |}
      |}>
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
  |},
  +projectGroups: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +companyProjectGroupId: ?number,
        +name: ?string,
        +color: ?string,
        +projects: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +budgetType: ?BUDGET_TYPE,
              +status: ?PROJECT_STATUS,
              +description: ?string,
              +statusColor: ?string,
              +companyProjectId: ?number,
              +budget: ?number,
              +budgetBaseCurrency: ?number,
              +projectColor: ?string,
              +estimationUnit: ?string,
              +minutesPerEstimationPoint: ?number,
              +projectStartDay: ?number,
              +projectStartMonth: ?number,
              +projectStartYear: ?number,
              +projectEndDay: ?number,
              +projectEndMonth: ?number,
              +projectEndYear: ?number,
              +sprintTimeBox: ?boolean,
              +currentProjectStatus: ?{|
                +id: string,
                +color: ?string,
                +person: ?{|
                  +id: string,
                  +firstName: ?string,
                  +lastName: ?string,
                |},
              |},
              +client: ?{|
                +id: string,
                +name: ?string,
                +logoId: ?string,
                +logoDefaultId: ?number,
              |},
              +rateCard: ?{|
                +id: string,
                +name: ?string,
                +currency: ?string,
                +parentRateCardId: ?string,
              |},
              +projectLabels: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +label: ?{|
                      +id: string,
                      +name: ?string,
                      +color: ?string,
                    |},
                  |}
                |}>
              |},
              +projectPersons: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +isContactPerson: ?boolean,
                    +person: ?{|
                      +id: string,
                      +firstName: ?string,
                      +lastName: ?string,
                      +profilePictureId: ?string,
                      +profilePictureDefaultId: ?number,
                      +initials: ?string,
                    |},
                  |}
                |}>
              |},
              +xeroInvoices: ?$ReadOnlyArray<?{|
                +id: string,
                +amountTotal: ?number,
                +amountPaid: ?number,
              |}>,
              +invoices: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +id: string,
                    +invoiceReference: ?string,
                    +companyInvoiceId: ?number,
                    +name: ?string,
                    +invoiceType: ?string,
                    +status: ?string,
                    +dueDay: ?number,
                    +dueMonth: ?number,
                    +dueYear: ?number,
                    +createdDay: ?number,
                    +createdMonth: ?number,
                    +createdYear: ?number,
                    +notes: ?string,
                    +quickbooksId: ?number,
                    +xeroId: ?string,
                    +economicId: ?number,
                    +entries: ?{|
                      +edges: ?$ReadOnlyArray<?{|
                        +node: ?{|
                          +id: string,
                          +name: ?string,
                          +quantity: ?number,
                          +unitPrice: ?number,
                          +discount: ?number,
                          +tax: ?number,
                          +description: ?string,
                        |}
                      |}>
                    |},
                    +payments: ?{|
                      +edges: ?$ReadOnlyArray<?{|
                        +node: ?{|
                          +id: string,
                          +notes: ?string,
                          +amount: ?number,
                          +day: ?number,
                          +month: ?number,
                          +year: ?number,
                          +createdAt: ?string,
                          +createdBy: ?{|
                            +fullName: ?string
                          |},
                        |}
                      |}>
                    |},
                  |}
                |}>
              |},
            |}
          |}>
        |},
      |}
    |}>
  |},
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +demo: ?boolean,
        +name: ?string,
        +budgetType: ?BUDGET_TYPE,
        +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
        +status: ?PROJECT_STATUS,
        +description: ?string,
        +companyProjectId: ?number,
        +budget: ?number,
        +budgetBaseCurrency: ?number,
        +projectColor: ?string,
        +estimationUnit: ?string,
        +minutesPerEstimationPoint: ?number,
        +projectStartDay: ?number,
        +projectStartMonth: ?number,
        +projectStartYear: ?number,
        +projectEndDay: ?number,
        +projectEndMonth: ?number,
        +projectEndYear: ?number,
        +sprintTimeBox: ?boolean,
        +isInProjectGroup: ?boolean,
        +baselineWinChance: ?number,
        +useBaseline: ?boolean,
        +currentProjectStatus: ?{|
          +id: string,
          +color: ?string,
          +person: ?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +fullName: ?string,
            +profilePictureId: ?string,
          |},
        |},
        +client: ?{|
          +id: string,
          +name: ?string,
          +logoId: ?string,
          +logoDefaultId: ?number,
        |},
        +rateCard: ?{|
          +id: string,
          +name: ?string,
          +currency: ?string,
          +parentRateCardId: ?string,
        |},
        +projectLabels: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +label: ?{|
                +id: string,
                +name: ?string,
                +color: ?string,
              |},
            |}
          |}>
        |},
        +projectPersons: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +isContactPerson: ?boolean,
              +person: ?{|
                +id: string,
                +fullName: ?string,
                +firstName: ?string,
                +lastName: ?string,
                +profilePictureId: ?string,
                +profilePictureDefaultId: ?number,
                +initials: ?string,
              |},
            |}
          |}>
        |},
        +phases: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +name: ?string,
              +startDay: ?number,
              +startMonth: ?number,
              +startYear: ?number,
              +deadlineDay: ?number,
              +deadlineMonth: ?number,
              +deadlineYear: ?number,
            |}
          |}>
        |},
      |},
      +$fragmentRefs: ProjectContactDropdown_projects$ref,
    |}>
  |},
  +$refType: financialReportPage_viewer$ref,
|};
export type financialReportPage_viewer$data = financialReportPage_viewer;
export type financialReportPage_viewer$key = {
  +$data?: financialReportPage_viewer$data,
  +$fragmentRefs: financialReportPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v9 = [
  (v0/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logoId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logoDefaultId",
    "storageKey": null
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentRateCardId",
  "storageKey": null
},
v11 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v12 = {
  "kind": "Literal",
  "name": "first",
  "value": 10000
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureDefaultId",
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budget",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetBaseCurrency",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimationUnit",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minutesPerEstimationPoint",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartDay",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartMonth",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectStartYear",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndDay",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndMonth",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectEndYear",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sprintTimeBox",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "concreteType": "RateCard",
  "kind": "LinkedField",
  "name": "rateCard",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v2/*: any*/),
    (v6/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v35 = [
  (v12/*: any*/)
],
v36 = {
  "alias": null,
  "args": (v35/*: any*/),
  "concreteType": "ProjectLabelTypeConnection",
  "kind": "LinkedField",
  "name": "projectLabels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectLabelTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectLabel",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Label",
              "kind": "LinkedField",
              "name": "label",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "projectLabels(first:10000)"
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isContactPerson",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "initials",
  "storageKey": null
},
v39 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000000
  }
],
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "financialReportPage_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backendId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Client",
      "kind": "LinkedField",
      "name": "client",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "filters",
      "args": null,
      "concreteType": "FilterTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_filters_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Filter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "section",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "xeroEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingSchedulingPlanMode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingProjectAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUsingMixedAllocation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "labels",
          "args": null,
          "concreteType": "LabelTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_labels_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    (v7/*: any*/),
                    (v3/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "LabelDropdown_labels"
                    }
                  ],
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "ClientTypeConnection",
          "kind": "LinkedField",
          "name": "clients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v9/*: any*/),
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ClientDropdown_clients"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "clients(first:1000)"
        },
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "RateCardTypeConnection",
          "kind": "LinkedField",
          "name": "rateCards",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RateCardTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RateCard",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v2/*: any*/),
                    (v10/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RateCardDropdown_rateCards"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "rateCards(first:1000)"
        },
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v11/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(first:1000)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "excludeClientUsers",
              "value": true
            },
            (v12/*: any*/),
            {
              "kind": "Literal",
              "name": "onlyActive",
              "value": true
            }
          ],
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "allPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v13/*: any*/),
                    (v14/*: any*/),
                    (v15/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Role",
                      "kind": "LinkedField",
                      "name": "role",
                      "plural": false,
                      "selections": (v11/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allPersons(excludeClientUsers:true,first:10000,onlyActive:true)"
        },
        {
          "alias": null,
          "args": (v8/*: any*/),
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v11/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "teams(first:1000)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v16/*: any*/),
      "concreteType": "ProjectGroupTypeConnection",
      "kind": "LinkedField",
      "name": "projectGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectGroupTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectGroupType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyProjectGroupId",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": (v16/*: any*/),
                  "concreteType": "ProjectTypeConnection",
                  "kind": "LinkedField",
                  "name": "projects",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v17/*: any*/),
                            (v18/*: any*/),
                            (v19/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "statusColor",
                              "storageKey": null
                            },
                            (v20/*: any*/),
                            (v21/*: any*/),
                            (v22/*: any*/),
                            (v23/*: any*/),
                            (v24/*: any*/),
                            (v25/*: any*/),
                            (v26/*: any*/),
                            (v27/*: any*/),
                            (v28/*: any*/),
                            (v29/*: any*/),
                            (v30/*: any*/),
                            (v31/*: any*/),
                            (v32/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectStatus",
                              "kind": "LinkedField",
                              "name": "currentProjectStatus",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v7/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v1/*: any*/),
                                    (v13/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            (v33/*: any*/),
                            (v34/*: any*/),
                            (v36/*: any*/),
                            {
                              "alias": null,
                              "args": (v35/*: any*/),
                              "concreteType": "ProjectPersonTypeConnection",
                              "kind": "LinkedField",
                              "name": "projectPersons",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ProjectPersonTypeEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "ProjectPerson",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        (v37/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "Person",
                                          "kind": "LinkedField",
                                          "name": "person",
                                          "plural": false,
                                          "selections": [
                                            (v0/*: any*/),
                                            (v1/*: any*/),
                                            (v13/*: any*/),
                                            (v14/*: any*/),
                                            (v15/*: any*/),
                                            (v38/*: any*/)
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "projectPersons(first:10000)"
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "XeroInvoiceType",
                              "kind": "LinkedField",
                              "name": "xeroInvoices",
                              "plural": true,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "amountTotal",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "amountPaid",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": (v39/*: any*/),
                              "concreteType": "InvoiceTypeConnection",
                              "kind": "LinkedField",
                              "name": "invoices",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "InvoiceTypeEdge",
                                  "kind": "LinkedField",
                                  "name": "edges",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "InvoiceType",
                                      "kind": "LinkedField",
                                      "name": "node",
                                      "plural": false,
                                      "selections": [
                                        (v0/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "invoiceReference",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "companyInvoiceId",
                                          "storageKey": null
                                        },
                                        (v2/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "invoiceType",
                                          "storageKey": null
                                        },
                                        (v18/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "dueDay",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "dueMonth",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "dueYear",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "createdDay",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "createdMonth",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "createdYear",
                                          "storageKey": null
                                        },
                                        (v40/*: any*/),
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "quickbooksId",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "xeroId",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "economicId",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": (v39/*: any*/),
                                          "concreteType": "InvoiceEntryTypeConnection",
                                          "kind": "LinkedField",
                                          "name": "entries",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "InvoiceEntryTypeEdge",
                                              "kind": "LinkedField",
                                              "name": "edges",
                                              "plural": true,
                                              "selections": [
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "concreteType": "InvoiceEntryType",
                                                  "kind": "LinkedField",
                                                  "name": "node",
                                                  "plural": false,
                                                  "selections": [
                                                    (v0/*: any*/),
                                                    (v2/*: any*/),
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "kind": "ScalarField",
                                                      "name": "quantity",
                                                      "storageKey": null
                                                    },
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "kind": "ScalarField",
                                                      "name": "unitPrice",
                                                      "storageKey": null
                                                    },
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "kind": "ScalarField",
                                                      "name": "discount",
                                                      "storageKey": null
                                                    },
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "kind": "ScalarField",
                                                      "name": "tax",
                                                      "storageKey": null
                                                    },
                                                    (v19/*: any*/)
                                                  ],
                                                  "storageKey": null
                                                }
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": "entries(first:100000000)"
                                        },
                                        {
                                          "alias": null,
                                          "args": (v39/*: any*/),
                                          "concreteType": "InvoicePaymentTypeConnection",
                                          "kind": "LinkedField",
                                          "name": "payments",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "InvoicePaymentTypeEdge",
                                              "kind": "LinkedField",
                                              "name": "edges",
                                              "plural": true,
                                              "selections": [
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "concreteType": "InvoicePaymentType",
                                                  "kind": "LinkedField",
                                                  "name": "node",
                                                  "plural": false,
                                                  "selections": [
                                                    (v0/*: any*/),
                                                    (v40/*: any*/),
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "kind": "ScalarField",
                                                      "name": "amount",
                                                      "storageKey": null
                                                    },
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "kind": "ScalarField",
                                                      "name": "day",
                                                      "storageKey": null
                                                    },
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "kind": "ScalarField",
                                                      "name": "month",
                                                      "storageKey": null
                                                    },
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "kind": "ScalarField",
                                                      "name": "year",
                                                      "storageKey": null
                                                    },
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "kind": "ScalarField",
                                                      "name": "createdAt",
                                                      "storageKey": null
                                                    },
                                                    {
                                                      "alias": null,
                                                      "args": null,
                                                      "concreteType": "Person",
                                                      "kind": "LinkedField",
                                                      "name": "createdBy",
                                                      "plural": false,
                                                      "selections": [
                                                        (v41/*: any*/)
                                                      ],
                                                      "storageKey": null
                                                    }
                                                  ],
                                                  "storageKey": null
                                                }
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": "payments(first:100000000)"
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": "invoices(first:100000000)"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "projects(first:100000)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projectGroups(first:100000)"
    },
    {
      "alias": "projects",
      "args": null,
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_projects_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "demo",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v17/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "defaultPeriodBudgetType",
                  "storageKey": null
                },
                (v18/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v21/*: any*/),
                (v22/*: any*/),
                (v23/*: any*/),
                (v24/*: any*/),
                (v25/*: any*/),
                (v26/*: any*/),
                (v27/*: any*/),
                (v28/*: any*/),
                (v29/*: any*/),
                (v30/*: any*/),
                (v31/*: any*/),
                (v32/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isInProjectGroup",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "baselineWinChance",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "useBaseline",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectStatus",
                  "kind": "LinkedField",
                  "name": "currentProjectStatus",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v7/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        (v13/*: any*/),
                        (v41/*: any*/),
                        (v14/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v33/*: any*/),
                (v34/*: any*/),
                (v36/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "contactsOnly",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1000000
                    }
                  ],
                  "concreteType": "ProjectPersonTypeConnection",
                  "kind": "LinkedField",
                  "name": "projectPersons",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectPersonTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectPerson",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v37/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Person",
                              "kind": "LinkedField",
                              "name": "person",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v41/*: any*/),
                                (v1/*: any*/),
                                (v13/*: any*/),
                                (v14/*: any*/),
                                (v15/*: any*/),
                                (v38/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "projectPersons(contactsOnly:true,first:1000000)"
                },
                {
                  "alias": null,
                  "args": (v35/*: any*/),
                  "concreteType": "PhaseTypeConnection",
                  "kind": "LinkedField",
                  "name": "phases",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PhaseTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PhaseType",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "startDay",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "startMonth",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "startYear",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "deadlineDay",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "deadlineMonth",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "deadlineYear",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "phases(first:10000)"
                },
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v4/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectContactDropdown_projects"
            }
          ],
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb9eb6b324736536b1776a754ea81012';

module.exports = node;
