/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type BudgetData_company$ref = any;
type BudgetPageHeader_company$ref = any;
type Details_company$ref = any;
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetPage_company$ref: FragmentReference;
declare export opaque type BudgetPage_company$fragmentType: BudgetPage_company$ref;
export type BudgetPage_company = {|
  +tier: ?TierType,
  +$fragmentRefs: BudgetPageHeader_company$ref & BudgetData_company$ref & Details_company$ref,
  +$refType: BudgetPage_company$ref,
|};
export type BudgetPage_company$data = BudgetPage_company;
export type BudgetPage_company$key = {
  +$data?: BudgetPage_company$data,
  +$fragmentRefs: BudgetPage_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BudgetPage_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tier",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BudgetPageHeader_company"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BudgetData_company"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Details_company"
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = 'b7d7f1135250d55dc50983cf868a5ec3';

module.exports = node;
