import React from 'react';
import Styled from 'styled-components/macro';
import {BUDGET_TYPE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import {ValueCell} from './ValueCell';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import ForecastTooltip from '../../forecast-app/shared/components/tooltips/ForecastTooltip';
import ProjectUtil from '../../forecast-app/shared/util/project_util';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {Heading, IconTooltip, FlexRow} from '@forecast-it/design-system';
import {TooltipFormulaRenderer} from '../../components/TooltipFormulaRenderer';
import {getTooltipForHeader} from './InitialPlanUtil';

export const HeaderSection = ({currencySymbol, project, totalFinancialNumbers}) => {
	const intl = useIntl();
	const isFixedPrice = project.budgetType === BUDGET_TYPE.FIXED_PRICE || project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2;
	const useFixedPriceCalculation =
		isFixedPrice && project.useFixedPriceForBaselineRevenue && hasFeatureFlag('baseline_financial_service');
	const isUsingBaselineTarget = project.baselineTarget && !isFixedPrice;
	const startDate = Util.CreateNonUtcMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay);
	const endDate = Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay);

	const showRevenue = ProjectUtil.projectTracksRevenue(project);
	const isBaselineAdjusted = () => {
		// return true;
		return !!project.baselineAdjustPercentage || !!project.baselineAdjustPrice;
	};
	const getAdjustmentTitle = () => {
		if (project.baselineAdjustPrice && project.baselineAdjustPrice > 0) {
			return intl.formatMessage({id: 'common.contingency'});
		} else if (project.baselineAdjustPrice && project.baselineAdjustPrice < 0) {
			return intl.formatMessage({id: 'project_section.xero_modal_table_discount'});
		} else if (project.baselineAdjustPercentage && project.baselineAdjustPercentage > 0) {
			return `${intl.formatMessage({id: 'common.contingency'})} (${Math.abs(project.baselineAdjustPercentage) * 100}%)`;
		} else if (project.baselineAdjustPercentage && project.baselineAdjustPercentage < 0) {
			return `${intl.formatMessage({id: 'project_section.xero_modal_table_discount'})} (${
				Math.abs(project.baselineAdjustPercentage) * 100
			}%)`;
		}
	};

	const calculateBaselineAdjustment = () => {
		if (project.baselineAdjustPrice) {
			return project.baselineAdjustPrice;
		}
		if (project.baselineAdjustPercentage) {
			return project.baselineAdjustPercentage * project.baselineTargetPrice;
		}
		// return 0;
		throw new Error('baseline should be adjusted to call this');
	};

	const calculateTotalBaseline = () => {
		if (hasFeatureFlag('baseline_financial_service')) {
			return totalFinancialNumbers.totalBaselineRevenue;
		} else {
			if (isBaselineAdjusted() && project.baselineAdjustPrice) {
				return project.baselineTargetPrice + project.baselineAdjustPrice;
			} else if (isBaselineAdjusted() && project.baselineAdjustPercentage) {
				return project.baselineTargetPrice + project.baselineTargetPrice * project.baselineAdjustPercentage;
			} else {
				return project.baselineTargetPrice;
			}
		}
	};

	const getBoxes = () => {
		const boxes = [];
		boxes.push(
			<Box key="dates-estimate-box">
				<Row bottomMargin={8}>
					<Element split>
						<Title>{intl.formatMessage({id: 'project_overview.project_dates'})}</Title>
						<Data>
							{`${intl.formatDate(startDate, {day: 'numeric', month: 'short'})}, ${intl.formatDate(startDate, {
								year: 'numeric',
							})} - ${intl.formatDate(endDate, {day: 'numeric', month: 'short'})}, ${intl.formatDate(endDate, {
								year: 'numeric',
							})}`}
						</Data>
					</Element>
				</Row>
				<Row bottomMargin={showRevenue ? 16 : undefined}>
					<Element split>
						<FlexRow>
							<Title>{intl.formatMessage({id: 'baseline.total_work_estimate'})}</Title>
							<IconTooltip iconSize={'s'}>
								<TooltipFormulaRenderer
									items={[
										{
											title: intl.formatMessage({id: 'baseline.total_work_estimate'}),
											description: intl.formatMessage({id: 'baseline.total_work_estimate_tooltip'}),
										},
									]}
								/>
							</IconTooltip>
						</FlexRow>
						<Data>
							<ValueCell
								noJustify
								type={
									project.baselineEstimationMinutesPerDay !== null
										? ValueCell.VALUE_TYPE.DAYS
										: ValueCell.VALUE_TYPE.TIME
								}
								minutesPerDay={project.baselineEstimationMinutesPerDay}
								value={project.baselineTargetMinutes / 60}
							/>
						</Data>
					</Element>
				</Row>
				{showRevenue && (
					<>
						<Row bottomMargin={16}>
							<HorizontalBar />
						</Row>
						{hasFeatureFlag('baseline_financial_service') &&
							(!isFixedPrice || (isFixedPrice && project.useFixedPriceForBaselineRevenue) ? (
								<Row bottomMargin={hasFeatureFlag('baseline_financial_service') ? 4 : 8}>
									<Element split>
										<FlexRow>
											<Title>{intl.formatMessage({id: 'project_budget.baseline_revenue'})}</Title>
											<IconTooltip iconSize={'s'} width={'xl'}>
												{getTooltipForHeader(
													'revenue',
													intl.formatMessage,
													isFixedPrice,
													useFixedPriceCalculation,
													true
												)}
											</IconTooltip>
										</FlexRow>
										<Data>
											<ValueCell
												noJustify
												type={ValueCell.VALUE_TYPE.PRICE}
												value={totalFinancialNumbers.totalBaselineRevenue}
												currencySymbol={currencySymbol}
											/>
										</Data>
									</Element>
								</Row>
							) : (
								<Row bottomMargin={hasFeatureFlag('baseline_financial_service') ? 4 : 8}>
									<Element split>
										<FlexRow>
											<Title>{intl.formatMessage({id: 'baseline.value_of_service'})}</Title>
											<IconTooltip iconSize={'s'}>
												{getTooltipForHeader(
													'value_of_service',
													intl.formatMessage,
													isFixedPrice,
													useFixedPriceCalculation,
													true
												)}
											</IconTooltip>
										</FlexRow>
										<Data>
											<ValueCell
												noJustify
												type={ValueCell.VALUE_TYPE.PRICE}
												value={totalFinancialNumbers.totalBaselineTimeAndExpenses}
												currencySymbol={currencySymbol}
											/>
										</Data>
									</Element>
								</Row>
							))}

						<Row bottomMargin={hasFeatureFlag('baseline_financial_service') ? 4 : 8}>
							<Element split>
								<FlexRow>
									<Title>{intl.formatMessage({id: 'baseline.baseline_cost'})}</Title>
									<IconTooltip iconSize={'s'} width={'xl'}>
										{getTooltipForHeader(
											'cost',
											intl.formatMessage,
											isFixedPrice,
											useFixedPriceCalculation,
											true
										)}
									</IconTooltip>
								</FlexRow>
								<Data>
									<ValueCell
										noJustify
										type={ValueCell.VALUE_TYPE.PRICE}
										value={
											hasFeatureFlag('baseline_financial_service')
												? totalFinancialNumbers.totalCost
												: project.baselineCost
										}
										currencySymbol={currencySymbol}
									/>
								</Data>
							</Element>
						</Row>

						<Row bottomMargin={hasFeatureFlag('baseline_financial_service') ? 4 : 0}>
							<Element split>
								<FlexRow>
									<Title>{intl.formatMessage({id: 'baseline.baseline_profit'})}</Title>
									<IconTooltip iconSize={'s'} width={'l'}>
										{getTooltipForHeader(
											'profit',
											intl.formatMessage,
											isFixedPrice,
											useFixedPriceCalculation,
											true
										)}
									</IconTooltip>
								</FlexRow>
								<Data>
									<ValueCell
										noJustify
										type={ValueCell.VALUE_TYPE.PRICE}
										value={
											hasFeatureFlag('baseline_financial_service')
												? isFixedPrice && project.useFixedPriceForBaselineRevenue
													? project.budget - totalFinancialNumbers.totalCost
													: totalFinancialNumbers.totalProfit
												: project.baselineProfit
										}
										currencySymbol={currencySymbol}
									/>
								</Data>
							</Element>
						</Row>
						{hasFeatureFlag('baseline_financial_service') && (
							<Row>
								<Element split>
									<FlexRow>
										<Title>{intl.formatMessage({id: 'baseline.baseline_margin'})}</Title>
										<IconTooltip width={'l'} iconSize={'s'}>
											{getTooltipForHeader(
												'margin',
												intl.formatMessage,
												isFixedPrice,
												useFixedPriceCalculation,
												true
											)}
										</IconTooltip>
									</FlexRow>
									<Data>
										<ValueCell
											noJustify
											type={ValueCell.VALUE_TYPE.PERCENTAGE}
											value={
												project.useFixedPriceForBaselineRevenue && isFixedPrice
													? (project.budget - totalFinancialNumbers.totalCost) / project.budget
													: isNaN(totalFinancialNumbers.totalMargin)
													? 0
													: totalFinancialNumbers.totalMargin
											}
											currencySymbol={currencySymbol}
										/>
									</Data>
								</Element>
							</Row>
						)}
					</>
				)}
			</Box>
		);
		if (
			showRevenue &&
			(isFixedPrice || (project.budgetType === BUDGET_TYPE.TIME_AND_MATERIALS && project.baselineTarget !== null))
		) {
			const difference =
				isFixedPrice && hasFeatureFlag('baseline_financial_service')
					? project.budget - totalFinancialNumbers.totalBaselineTimeAndExpenses
					: project.baselineTarget - calculateTotalBaseline();
			let percentage =
				isFixedPrice && hasFeatureFlag('baseline_financial_service')
					? totalFinancialNumbers.totalBaselineTimeAndExpenses / totalFinancialNumbers.totalBaselineRevenue
					: calculateTotalBaseline() / project.baselineTarget;
			if (percentage < 0) {
				percentage = 0;
			}
			boxes.push(
				<Box key="last-box">
					{isBaselineAdjusted() && (
						<>
							<Row bottomMargin={8}>
								<Element split>
									<Title>
										<ForecastTooltip
											placement={'left'}
											content={intl.formatMessage({id: 'baseline.total_revenue_tooltip_fixed_price'})}
										>
											{intl.formatMessage({id: 'baseline.original_baseline'})}
										</ForecastTooltip>
									</Title>
									<Data /*warning={difference < 0}*/>
										<ValueCell
											noJustify
											type={ValueCell.VALUE_TYPE.PRICE}
											value={
												hasFeatureFlag('baseline_financial_service')
													? totalFinancialNumbers.totalBaselineRevenue
													: project.baselineTargetPrice
											}
											currencySymbol={currencySymbol}
										/>
									</Data>
								</Element>
							</Row>

							<Row bottomMargin={8}>
								<Element split>
									<Title>{getAdjustmentTitle()}</Title>
									<Data /*warning={difference < 0}*/>
										<ValueCell
											noJustify
											type={ValueCell.VALUE_TYPE.PRICE}
											value={calculateBaselineAdjustment()}
											currencySymbol={currencySymbol}
										/>
									</Data>
								</Element>
							</Row>
						</>
					)}
					<Row bottomMargin={isBaselineAdjusted() ? 24 : 80}>
						<Element split>
							<Heading size={'m'}>
								<ForecastTooltip
									placement={'left'}
									content={intl.formatMessage({id: 'baseline.total_revenue_tooltip'})}
									disabled={
										hasFeatureFlag('baseline_financial_service') && (isFixedPrice || isUsingBaselineTarget)
									}
								>
									{intl.formatMessage({
										id:
											isFixedPrice && hasFeatureFlag('baseline_financial_service')
												? 'common.fixed_price'
												: isUsingBaselineTarget && hasFeatureFlag('baseline_financial_service')
												? 'baseline.baseline_target'
												: 'baseline.total_baseline_revenue',
									})}
								</ForecastTooltip>
							</Heading>
							<Data bold>
								<ValueCell
									noJustify
									type={ValueCell.VALUE_TYPE.PRICE}
									value={
										isUsingBaselineTarget && hasFeatureFlag('baseline_financial_service')
											? project.baselineTarget
											: isFixedPrice && hasFeatureFlag('baseline_financial_service')
											? project.budget
											: calculateTotalBaseline()
									}
									currencySymbol={currencySymbol}
								/>
							</Data>
						</Element>
					</Row>
					<Row bottomMargin={8}>
						<Bar percent={percentage} />
					</Row>
					<Row split>
						<Element fixedWidth>
							<Title rightMargin={8}>
								<ForecastTooltip
									placement={'left'}
									content={intl.formatMessage({
										id: isFixedPrice ? 'baseline.fixed_price_tooltip' : 'baseline.target_price_tooltip',
									})}
									disabled={
										hasFeatureFlag('baseline_financial_service') && (isFixedPrice || isUsingBaselineTarget)
									}
								>
									{intl.formatMessage({
										id: isFixedPrice
											? isFixedPrice && hasFeatureFlag('baseline_financial_service')
												? 'baseline.value_of_service'
												: 'new_project_modal.budget_type_fixed_price'
											: isUsingBaselineTarget && hasFeatureFlag('baseline_financial_service')
											? 'project_budget.baseline_revenue'
											: 'baseline.baseline_target',
									})}
								</ForecastTooltip>
							</Title>
							<Title>
								<ValueCell
									noJustify
									type={ValueCell.VALUE_TYPE.PRICE}
									value={
										isFixedPrice && hasFeatureFlag('baseline_financial_service')
											? totalFinancialNumbers.totalBaselineTimeAndExpenses
											: isUsingBaselineTarget && hasFeatureFlag('baseline_financial_service')
											? totalFinancialNumbers.totalBaselineRevenue
											: project.baselineTarget
									}
									currencySymbol={currencySymbol}
								/>
							</Title>
						</Element>
						<Element fixedWidth>
							<Title rightMargin={8}>
								<ForecastTooltip
									placement={'right'}
									content={intl.formatMessage({
										id: hasFeatureFlag('baseline_financial_service')
											? 'baseline.difference_tooltip_value_of_service'
											: 'baseline.difference_tooltip',
									})}
									disabled={useFixedPriceCalculation}
								>
									{intl.formatMessage({id: 'common.difference'})}
								</ForecastTooltip>
							</Title>
							<Title>
								{difference < 0 && <span style={{marginRight: '4px'}}>+</span>}
								<ValueCell
									noJustify
									type={ValueCell.VALUE_TYPE.PRICE}
									value={Math.abs(difference)}
									currencySymbol={currencySymbol}
								/>
							</Title>
						</Element>
					</Row>
				</Box>
			);
		} else if (showRevenue && project.budgetType === BUDGET_TYPE.TIME_AND_MATERIALS) {
			boxes.push(
				<Box center key="revenue-box">
					<Title>
						<ForecastTooltip
							placement={'left'}
							content={intl.formatMessage({id: 'baseline.total_revenue_tooltip'})}
						>
							{intl.formatMessage({id: 'baseline.total_baseline_revenue'})}
						</ForecastTooltip>
					</Title>
					<Data large>
						<ValueCell
							noJustify
							type={ValueCell.VALUE_TYPE.PRICE}
							value={
								hasFeatureFlag('baseline_financial_service')
									? totalFinancialNumbers.totalBaselineRevenue
									: project.baselineTargetPrice
							}
							currencySymbol={currencySymbol}
						/>
					</Data>
				</Box>
			);
		} else if (!showRevenue) {
			boxes.push(
				<Box center key="cost-box">
					<Title>
						<ForecastTooltip placement={'right'} content={intl.formatMessage({id: 'baseline.total_cost_tooltip'})}>
							{intl.formatMessage({id: 'baseline.baseline_cost'})}
						</ForecastTooltip>
					</Title>
					<Data large>
						<ValueCell
							noJustify
							type={ValueCell.VALUE_TYPE.PRICE}
							value={project.baselineCost}
							currencySymbol={currencySymbol}
						/>
					</Data>
				</Box>
			);
		}
		return boxes;
	};
	return (
		<GridContainer isNonBillable={!showRevenue} hasBaselineTarget={!!project.baselineTarget}>
			{getBoxes()}
		</GridContainer>
	);
};

export default createFragmentContainer(HeaderSection, {
	project: graphql`
		fragment HeaderSection_project on ProjectType {
			baselineEstimationMinutesPerDay
			projectStartYear
			projectStartMonth
			projectStartDay
			projectEndYear
			projectEndMonth
			projectEndDay
			baselineTargetMinutes
			baselineTarget
			baselineCost
			baselineProfit
			baselineAdjustPercentage
			baselineAdjustPrice
			baselineTargetPrice
			budgetType
			budget
			useFixedPriceForBaselineRevenue
		}
	`,
});

export const Bar = ({percent}) => {
	return (
		<BackgroundBar>
			<FillBar percent={percent} />
		</BackgroundBar>
	);
};

export const FillBar = Styled.div`
    
    height: 100%;
    background-color: ${({percent}) => (percent > 1 ? '#E4253B' : '#05e298')};
    
    width: ${props => `${props.percent * 100}%`};
`;

export const BackgroundBar = Styled.div`
    margin-top: 16px;
    overflow: hidden;
    width: 100%;
    height: 6px;
    background-color: #e6e6e6;
    border-radius: 3px;
`;

export const HorizontalBar = Styled.hr`
	border: none;
	height: 1px;
	width: 100%;
	color: #E6E6ED;
	background-color: #E6E6ED;
`;

export const GridContainer = Styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: ${({hasBaselineTarget, isNonBillable}) =>
		hasBaselineTarget && !isNonBillable ? '208px' : '186px'}; */
    grid-gap: 24px;
`;
export const Container = Styled.div`
    width: 100%;
    height: ${({hasBaselineTarget}) => (hasBaselineTarget ? '208px' : '186px')}
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

`;

export const Element = Styled.div`
    /* margin-right: 16px; */
    display: flex;
	align-items: center;
	justify-content: ${props => props.split && 'space-between'};
    overflow: ${props => props.fixedWidth && 'hidden'};
	width: ${props => !props.fixedWidth && '100%'};
`;

export const Data = Styled.div`

    white-space: nowrap;
    display: flex;
    flex-direction: row;
    font-size: ${({large}) => (large ? '24px' : '16px')};
    font-weight: ${({large, bold}) => (large || bold ? '500' : '400')};
    color: ${({warning}) => (warning ? '#E4253B' : '#4C4C58')};
`;

export const Row = Styled.div`
    display: flex;
    flex-direction: row;
	justify-content: ${props => props.split && 'space-between'};
	margin-bottom: ${props => props.bottomMargin && `${props.bottomMargin}px`};
`;
export const Title = Styled.div`
	margin-right: ${props => props.rightMargin && `${props.rightMargin}px`};
    font-size: 13px;
    color: #a1a1a1;
	display: flex;
	flex-direction: row;
	text-transform: capitalize;
`;
export const Box = Styled.div`
    overflow:hidden;
    height: 100%;
    border: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    padding: 26px 24px;
    /* justify-content: ${({center}) => (center ? 'center' : 'space-between')}; */
    align-items: ${({center}) => center && 'center'};
	justify-content: ${({center}) => center && 'center'};
    flex-grow: 1;
	border-radius: 4px;
`;
