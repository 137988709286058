import Util from '../../forecast-app/shared/util/util';
import UpdateInitialPlanProjectMutation from '../../mutations/UpdateInitialPlanProjectMutation';
import CreatePhaseBaselineRoleMutation from '../../mutations/CreatePhaseBaselineRoleMutation';
import UpdatePhaseBaselineRoleMutation from '../../mutations/UpdatePhaseBaselineRoleMutation';
import CreatePhaseBaselineMutation from '../../mutations/CreatePhaseBaselineMutation';
import UpdatePhaseBaselineMutation from '../../mutations/UpdatePhaseBaselineMutation';
import DeletePhaseBaselineRoleMutation from '../../mutations/DeletePhaseBaselineRoleMutation';
import CreatePhaseBaselineExpenseMutation from '../../mutations/CreatePhaseBaselineExpenseMutation';
import DeletePhaseBaselineExpenseMutation from '../../mutations/DeletePhaseBaselineExpenseMutation';
import UpdatePhaseBaselineExpenseMutation from '../../mutations/UpdatePhaseBaselineExpenseMutation';
import DeletePhaseBaselineMutation from '../../mutations/DeletePhaseBaselineMutation';

export const adjustBaseline = (adjustedPrice = null, adjustedPercentage = null, projectId, onSuccess = () => null) => {
	Util.CommitMutation(
		UpdateInitialPlanProjectMutation,
		{
			id: projectId,
			baselineAdjustPrice: adjustedPrice,
			baselineAdjustPercentage: adjustedPercentage,
		},
		onSuccess
	);
};

export const changeBaselineWinChance = (winChance, projectId, onSuccess) => {
	Util.CommitMutation(
		UpdateInitialPlanProjectMutation,
		{
			id: projectId,
			baselineWinChance: winChance,
		},
		onSuccess
	);
};

export const changeBaselineProjectDates = (startDate, endDate, projectId, onSuccess) => {
	Util.CommitMutation(
		UpdateInitialPlanProjectMutation,
		{
			id: projectId,
			projectStartYear: startDate.year(),
			projectStartMonth: startDate.month() + 1,
			projectStartDay: startDate.date(),
			projectEndYear: endDate.year(),
			projectEndMonth: endDate.month() + 1,
			projectEndDay: endDate.date(),
		},
		onSuccess
	);
};

export const createNewPhase = (projectId, formatMessage, onSuccess) => {
	Util.CommitMutation(
		CreatePhaseBaselineMutation,
		{
			projectId: projectId,
			name: formatMessage({id: 'project_scoping.new-scope-group'}),
		},
		onSuccess
	);
};

export const changePhaseDates = (mutationObject, onSuccess) => {
	Util.CommitMutation(UpdatePhaseBaselineMutation, mutationObject, onSuccess);
};

export const handleAddRoleRow = (projectId, phaseId, roleId, minutes, success) => {
	Util.CommitMutation(
		CreatePhaseBaselineRoleMutation,
		{
			projectId: projectId,
			phaseId: phaseId,
			roleId: roleId,
			baselineMinutes: Math.round(minutes),
		},
		success
	);
};

export const handleAddExpenseRow = (projectId, phaseId, expenseCategoryId, expenseCost, expenseRevenue, success) => {
	Util.CommitMutation(
		CreatePhaseBaselineExpenseMutation,
		{
			projectId: projectId,
			phaseId: phaseId,
			expenseCategoryId: expenseCategoryId,
			expenseCost: expenseCost,
			expenseRevenue: expenseRevenue,
		},
		success
	);
};

export const changeRoleRoleRow = (baselineId, roleId, onSuccess) => {
	Util.CommitMutation(
		UpdatePhaseBaselineRoleMutation,
		{
			id: baselineId,
			roleId: roleId,
		},
		onSuccess
	);
};

export const editBaselineRoleEstimate = (baselineId, minutes, onSuccess) => {
	Util.CommitMutation(
		UpdatePhaseBaselineRoleMutation,
		{
			id: baselineId,
			baselineMinutes: Math.round(minutes),
		},
		onSuccess
	);
};

export const editExpenseCost = (baselineId, expenseCost, onSuccess) => {
	Util.CommitMutation(
		UpdatePhaseBaselineExpenseMutation,
		{
			id: baselineId,
			expenseCost: expenseCost,
		},
		onSuccess
	);
};
export const changeExpenseCategory = (baselineId, expenseCategoryId, onSuccess) => {
	Util.CommitMutation(
		UpdatePhaseBaselineExpenseMutation,
		{
			id: baselineId,
			expenseCategoryId: expenseCategoryId,
		},
		onSuccess
	);
};

export const editExpenseRevenue = (baselineId, expenseRevenue, onSuccess) => {
	Util.CommitMutation(
		UpdatePhaseBaselineExpenseMutation,
		{
			id: baselineId,
			expenseRevenue: expenseRevenue,
		},
		onSuccess
	);
};

export const editExpenseMarkup = (baselineId, expenseMarkup, onSuccess) => {
	Util.CommitMutation(
		UpdatePhaseBaselineExpenseMutation,
		{
			id: baselineId,
			expenseMarkup: expenseMarkup,
		},
		onSuccess
	);
};

export const deleteBaselineRole = (baselineRoleId, projectId, onSuccess) => {
	Util.CommitMutation(
		DeletePhaseBaselineRoleMutation,
		{
			id: baselineRoleId,
			projectId: projectId,
		},
		onSuccess
	);
};

export const deleteBaselineExpense = (baselineExpenseId, projectId, onSuccess) => {
	Util.CommitMutation(
		DeletePhaseBaselineExpenseMutation,
		{
			id: baselineExpenseId,
			projectId: projectId,
		},
		onSuccess
	);
};

export const deletePhase = (phaseId, projectId, onSuccess) => {
	Util.CommitMutation(
		DeletePhaseBaselineMutation,
		{
			id: phaseId,
			projectId: projectId,
		},
		onSuccess
	);
};
