import {FlexItem} from '@forecast-it/design-system';
import React from 'react';
import HeaderColumnContainer from './HeaderColumnContainer';
import {DeprecatedProjectGroupIndicatorNoFragment} from '../../../../forecast-app/shared/components/project-indicator/DeprecatedProjectGroupIndicator';

const ConnectedProjectHeaderColumn = ({projectGroup}) => {
	const readOnlyAccess = projectGroup.readOnlyAccess;
	return (
		<HeaderColumnContainer>
			<FlexItem flex="0 0 auto">
				<DeprecatedProjectGroupIndicatorNoFragment projectGroup={projectGroup} disableLink={readOnlyAccess} />
			</FlexItem>
			<FlexItem>{projectGroup.name}</FlexItem>
		</HeaderColumnContainer>
	);
};

export default ConnectedProjectHeaderColumn;
