import {createRefetchContainer, graphql} from 'react-relay';

const ConnectedProjectLoader = ({viewer, children, ...props}) => {
	const formatData = () => {
		return viewer.company.financialBasedConnectedProjects.edges.map(connectedProject => {
			const {financialNumbers} = connectedProject.node;
			return {
				connectedProject: {
					...connectedProject.node,
				},
				currency: viewer.company.currency,
				financialNumbers: {
					...financialNumbers,
				},
			};
		});
	};

	return children({data: {rows: formatData()}, ...props});
};

export const connectedProjectLoaderQuery = graphql`
	query ConnectedProjectLoader_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$searchQuery: TaskSearchQueryType!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_period_connected_project_loader")
			...ConnectedProjectLoader_viewer
				@arguments(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createRefetchContainer(ConnectedProjectLoader, {
	viewer: graphql`
		fragment ConnectedProjectLoader_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
		) {
			id
			company {
				id
				currency
				financialBasedConnectedProjects(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				) {
					edges {
						node {
							id
							name
							companyProjectGroupId
							color
							financialNumbers {
								...ProjectPortfolioPeriodRow_financialNumbers
							}
						}
					}
				}
			}
		}
	`,
});
