import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Person from '../../../forecast-app/shared/components/person/person';

class UtilizationMonthlyDummy extends Component {
	createDummyPerson(name, role, jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec) {
		const person = {};
		person.name = <Person name={name} role={role} showName={true} showRole={true} imageSrc={null} imageSize="medium" />;
		person.jan = jan + '%';
		person.feb = feb + '%';
		person.mar = mar + '%';
		person.apr = apr + '%';
		person.may = may + '%';
		person.jun = jun + '%';
		person.jul = jul + '%';
		person.aug = aug + '%';
		person.sep = sep + '%';
		person.oct = oct + '%';
		person.nov = nov + '%';
		person.dec = dec + '%';
		person.jan_backgroundColor = this.getBackgroundColor(jan);
		person.feb_backgroundColor = this.getBackgroundColor(feb);
		person.mar_backgroundColor = this.getBackgroundColor(mar);
		person.apr_backgroundColor = this.getBackgroundColor(apr);
		person.may_backgroundColor = this.getBackgroundColor(may);
		person.jun_backgroundColor = this.getBackgroundColor(jun);
		person.jul_backgroundColor = this.getBackgroundColor(jul);
		person.aug_backgroundColor = this.getBackgroundColor(aug);
		person.sep_backgroundColor = this.getBackgroundColor(sep);
		person.oct_backgroundColor = this.getBackgroundColor(oct);
		person.nov_backgroundColor = this.getBackgroundColor(nov);
		person.dec_backgroundColor = this.getBackgroundColor(dec);
		return person;
	}

	getBackgroundColor(value) {
		try {
			const config = JSON.parse(this.props.config);
			const intValue = parseInt(value, 10);
			if (intValue < parseInt(config.tooLow, 10)) {
				return config.tooLowColor;
			} else if (intValue <= parseInt(config.low, 10)) {
				return config.lowColor;
			} else if (intValue <= parseInt(config.optimal, 10)) {
				return config.optimalColor;
			} else if (intValue <= parseInt(config.high, 10)) {
				return config.highColor;
			} else {
				return config.tooHighColor;
			}
		} catch (err) {}
	}

	render() {
		let people = [];
		people.push(this.createDummyPerson('Example person 1', 'Developer', 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120));
		people.push(
			this.createDummyPerson('Example person 2', 'Project Manager', 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140)
		);
		people.push(
			this.createDummyPerson('Example person 3', 'Product Owner', 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160)
		);
		return (
			<InsightList
				data={people}
				componentName={this.props.componentName}
				allColumns={this.props.allColumns}
				activeColumns={this.props.allColumns}
				columnSettings={[{width: '20%'}]}
				scrollElement={this.props.scrollElement}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

UtilizationMonthlyDummy.propTypes = {
	title: PropTypes.string,
};

export default injectIntl(UtilizationMonthlyDummy);
