import ChangeViewButton from '../../forecast-app/shared/components/changeview/ChangeViewButton';
import ChangeViewGroupings from '../../forecast-app/shared/components/changeview/ChangeViewGroupings';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DropdownList } from '@forecast-it/design-system';
import { MODAL_TYPE, showModal } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import { EXPENSE_MANAGEMENT_COLUMNS } from './Constants';
const EXPENSE_MANAGEMENT_GROUPING = [
    EXPENSE_MANAGEMENT_COLUMNS.APPROVAL_STATUS,
    EXPENSE_MANAGEMENT_COLUMNS.BILLING_OPTION,
    EXPENSE_MANAGEMENT_COLUMNS.CATEGORY,
    EXPENSE_MANAGEMENT_COLUMNS.CLIENT,
    EXPENSE_MANAGEMENT_COLUMNS.PERSON,
    EXPENSE_MANAGEMENT_COLUMNS.PROJECT,
    EXPENSE_MANAGEMENT_COLUMNS.MONTH,
];
const EYE_OPTION_COLUMNS = [
    EXPENSE_MANAGEMENT_COLUMNS.APPROVAL_STATUS,
    EXPENSE_MANAGEMENT_COLUMNS.BILLING_OPTION,
    EXPENSE_MANAGEMENT_COLUMNS.CATEGORY,
    EXPENSE_MANAGEMENT_COLUMNS.DATE,
    EXPENSE_MANAGEMENT_COLUMNS.FILES,
    EXPENSE_MANAGEMENT_COLUMNS.MARKUP,
    EXPENSE_MANAGEMENT_COLUMNS.PERSON,
    EXPENSE_MANAGEMENT_COLUMNS.PROJECT,
    EXPENSE_MANAGEMENT_COLUMNS.TOTAL_COST,
    EXPENSE_MANAGEMENT_COLUMNS.TOTAL_PRICE,
];
export const EXPENSE_MANAGEMENT_EYE_OPTIONS = EYE_OPTION_COLUMNS.map(column => ({
    name: column,
    translationId: 'expense_management.column.' + column,
    disabled: false,
    checked: true,
}));
const ExpenseManagementChangeViewButton = ({ groupings, onGroupingsChange, useProjectCurrency, onUseProjectCurrencyChange, eyeOptions, onEyeOptionsChange, }) => {
    const { formatMessage } = useIntl();
    const openShowHideColumnsModal = () => {
        showModal({
            type: MODAL_TYPE.SHOW_HIDE_COLUMNS,
            eyeOptions,
            onEyeOptionsChange,
        });
    };
    return (React.createElement(ChangeViewButton, null,
        React.createElement(ChangeViewGroupings, { maxLevel: 3, groupings: groupings, onChange: onGroupingsChange }, EXPENSE_MANAGEMENT_GROUPING.map(grouping => (React.createElement(ChangeViewGroupings.Grouping, { key: grouping, value: grouping },
            React.createElement(FormattedMessage, { id: 'expense_management.column.' + grouping }))))),
        React.createElement(DropdownList.ContentGroup, { title: formatMessage({ id: 'common.data_options' }) },
            React.createElement(DropdownList.ToggleItem, { checked: useProjectCurrency, onChange: onUseProjectCurrencyChange },
                React.createElement(FormattedMessage, { id: 'expense_management.project_currency_toggle_label' }))),
        React.createElement(DropdownList.ContentGroup, { title: formatMessage({ id: 'change_view.table_visibility' }) },
            React.createElement(DropdownList.Item, { onClick: openShowHideColumnsModal },
                React.createElement(FormattedMessage, { id: 'change_view.show_hide_columns' })))));
};
export default ExpenseManagementChangeViewButton;
