import React from 'react';
import PropTypes from 'prop-types';

import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown, SingleText} from 'web-components';

export const PhasesDropdown = ({
	phases,
	dropdownAlignment,
	width,
	name,
	optionsName,
	selectedItems,
	onSelect,
	onRemove,
	selectedGroupName,
	userpilot,
	emphasizeEmptyState,
	headerLines,
}) => {
	const getElements = () => {
		return phases.map(edge => {
			const {id, name} = edge.node;
			return (
				<SingleText key={id} value={id} searchString={name}>
					{name}
				</SingleText>
			);
		});
	};
	return (
		<Dropdown
			isNested
			isMultiSelect
			headerLines={headerLines}
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
		>
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{getElements()}
			</Dropdown.Group>
		</Dropdown>
	);
};

PhasesDropdown.propTypes = {
	phases: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				name: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	headerLines: PropTypes.element,
};

PhasesDropdown.defaultProps = {
	optionsName: 'Phases',
	name: 'Phases',
	selectedGroupName: 'Selected',
	onSelect: () => false,
	onRemove: () => false,
};

export default createFragmentContainer(PhasesDropdown, {
	phases: graphql`
		fragment PhasesDropdown_phases on PhaseTypeEdge @relay(plural: true) {
			node {
				name
				id
			}
		}
	`,
});
