/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RevenueCostProfitCharts_viewer$ref: FragmentReference;
declare export opaque type RevenueCostProfitCharts_viewer$fragmentType: RevenueCostProfitCharts_viewer$ref;
export type RevenueCostProfitCharts_viewer = {|
  +company: ?{|
    +tier: ?TierType,
    +currency: ?string,
  |},
  +project: ?{|
    +projectFirstDateYear: ?number,
    +projectFirstDateMonth: ?number,
    +projectFirstDateDay: ?number,
    +projectLastDateYear: ?number,
    +projectLastDateMonth: ?number,
    +projectLastDateDay: ?number,
    +rateCard: ?{|
      +currency: ?string
    |},
    +estimationUnit: ?string,
    +budget: ?number,
    +budgetType: ?BUDGET_TYPE,
    +billable: ?boolean,
    +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
    +aggregatedFinancialNumbers: $ReadOnlyArray<?string>,
  |},
  +$refType: RevenueCostProfitCharts_viewer$ref,
|};
export type RevenueCostProfitCharts_viewer$data = RevenueCostProfitCharts_viewer;
export type RevenueCostProfitCharts_viewer$key = {
  +$data?: RevenueCostProfitCharts_viewer$data,
  +$fragmentRefs: RevenueCostProfitCharts_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregateLevel",
      "type": "AggregateLevel!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RevenueCostProfitCharts_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectFirstDateYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectFirstDateMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectFirstDateDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectLastDateYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectLastDateMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectLastDateDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimationUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budget",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultPeriodBudgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "addAccumulatedNumbers",
              "value": true
            },
            {
              "kind": "Variable",
              "name": "aggregateLevel",
              "variableName": "aggregateLevel"
            },
            {
              "kind": "Literal",
              "name": "aggregates",
              "value": [
                "billableActualTimeAndExpenses",
                "billablePlannedTimeAndExpenses",
                "billableTotalTimeAndExpensesAtCompletion",
                "baselineRevenue",
                "baselineTimeAndExpenses",
                "actualCost",
                "plannedCost",
                "totalCostAtCompletion",
                "baselineCost",
                "actualProfit",
                "plannedProfit",
                "totalProfitAtCompletion",
                "baselineProfit",
                "totalRevenueRecognition",
                "recognitionProfit"
              ]
            },
            {
              "kind": "Literal",
              "name": "convertToProjectCurrency",
              "value": true
            },
            {
              "kind": "Variable",
              "name": "endDay",
              "variableName": "endDay"
            },
            {
              "kind": "Variable",
              "name": "endMonth",
              "variableName": "endMonth"
            },
            {
              "kind": "Variable",
              "name": "endYear",
              "variableName": "endYear"
            },
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            },
            {
              "kind": "Variable",
              "name": "startDay",
              "variableName": "startDay"
            },
            {
              "kind": "Variable",
              "name": "startMonth",
              "variableName": "startMonth"
            },
            {
              "kind": "Variable",
              "name": "startYear",
              "variableName": "startYear"
            }
          ],
          "kind": "ScalarField",
          "name": "aggregatedFinancialNumbers",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1da2c62e6b483d1adf86813e4f0b6904';

module.exports = node;
