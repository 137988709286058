import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

import {CSS_CONSTANTS} from '../../../../css_variables';
import {TitleWithTooltip} from './TitleWithTooltip';

const TableHeaderColumnStyle = styled.th`
	padding: 0 16px 0;
	border-top: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	border-left: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	height: 31px;
	background: white;
	position: sticky;
	top: 0px;

	&.highlighted {
		background-color: ${CSS_CONSTANTS.v2_list_background_purple_opaque};
	}

	&:last-child {
		border-right: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	}
`;

export const TableHeaderColumn = ({column: {title, highlighted, toolTip, subColumns}}) => {
	return (
		<TableHeaderColumnStyle className={highlighted ? 'highlighted' : ''} colSpan={subColumns.length}>
			<TitleWithTooltip title={title} tooltip={toolTip} />
		</TableHeaderColumnStyle>
	);
};

TableHeaderColumn.propTypes = {
	column: PropTypes.shape({
		title: PropTypes.string,
		tooltip: PropTypes.string,
		highlighted: PropTypes.bool,
	}),
};
