import React from 'react';
import styled from 'styled-components';
import ForecastTooltip from '../forecast-app/shared/components/tooltips/ForecastTooltip';

import {DeprecatedIcon} from '@forecast-it/design-system';
import {FormattedHTMLMessage} from 'react-intl';

const RoleWrapper = styled.div`
	display: flex;
	margin: 7px 0 7px 0;
`;

const IconWrapper = styled.div`
	padding-top: 1px;
`;

const RoleName = styled.div`
	padding: 4px;
	font-size: 13px;
	font-weight: 600;
	&.no-icon {
		padding-left: 6px;
	}
`;

const RoleTaskAmount = styled.div`
	padding: 4px;
	font-size: 13px;
	font-weight: 300;
`;

const Role = ({role, isRoleDisabled, taskAmount}) => {
	return (
		<RoleWrapper>
			{isRoleDisabled && (
				<IconWrapper>
					<ForecastTooltip content={<FormattedHTMLMessage id="project_person.deactivated_role" />}>
						<IconWrapper>
							<DeprecatedIcon icon="warning" size="m" color="error" />
						</IconWrapper>
					</ForecastTooltip>
				</IconWrapper>
			)}
			<RoleName className={'no-icon'}>{role.name}</RoleName>
			{taskAmount !== null && taskAmount !== undefined ? <RoleTaskAmount>{'(' + taskAmount + ')'}</RoleTaskAmount> : null}
		</RoleWrapper>
	);
};

export default Role;
