import React from 'react';
import {useIntl} from 'react-intl';
import {Table, CrossIcon, SIZE} from 'web-components';
import {columnHasUnlockedRecognitionAmount} from '../util/BudgetUtils';
import {ManualRecogntionResetColumn} from './RevenueRecognition.styled';
import ForecastTooltip from '../../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import Util from '../../../../../forecast-app/shared/util/util';
import DeleteFixedPriceLockMutation from '../../../../../mutations/delete_fixed_price_lock_mutation';
import {dispatch, EVENT_ID} from '../../../../../containers/event_manager';
import Styled from 'styled-components/macro';
import * as tracking from '../../../../../tracking';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';

const ResetButton = Styled.div`
	width: 20px;
	height: 20px;
  background: #E7E7F3;
	border-radius: 4px 4px 0px 0px;
  padding: 2px;
  position: relative;
  top: 3px;
  z-index: 1;
`;

const ManualRevenueRecognitionResetRow = ({data, rowData, project, tableColumnProps}) => {
	const intl = useIntl();

	const resetManualRecognition = (object, fixedPriceLocks) => {
		tracking.trackElementClicked('Manual Recognition Reset Button');
		trackEvent('Manual Recognition Reset Button', 'Clicked', {budgetType: project.budgetType});
		const fixedPriceLock = fixedPriceLocks.edges.find(
			lock => lock.node.startDate === object.startDate.format('YYYY-MM-DD')
		);
		const onSuccess = () => {
			dispatch(EVENT_ID.BUDGET_UPDATE_PROJECT);
		};
		Util.CommitMutation(DeleteFixedPriceLockMutation, {fixedPriceLockId: fixedPriceLock.node.id}, onSuccess);
	};

	return (
		<Table.Row cy={'reset-row'} {...tableColumnProps}>
			<Table.Column flex={1}></Table.Column>
			{rowData.dataArray.map((object, index) => {
				const hasUnlockedRecognitionAmount =
					columnHasUnlockedRecognitionAmount && columnHasUnlockedRecognitionAmount(project.fixedPriceLocks, object);

				return (
					<Table.Column cy={'manual-revenue-recognition-reset-column-' + data.columns[index].name}>
						{hasUnlockedRecognitionAmount ? (
							<ManualRecogntionResetColumn>
								<ForecastTooltip content={intl.formatMessage({id: 'project_budget.reset_manual_recognition'})}>
									<ResetButton>
										<CrossIcon
											size={SIZE.SMALL}
											onClick={() => resetManualRecognition(object, project.fixedPriceLocks)}
										/>
									</ResetButton>
								</ForecastTooltip>
							</ManualRecogntionResetColumn>
						) : null}
					</Table.Column>
				);
			})}
		</Table.Row>
	);
};

export default ManualRevenueRecognitionResetRow;
