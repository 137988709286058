/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TimeApprovalLogicQueryVariables = {|
  personId?: ?string
|};
export type TimeApprovalLogicQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +actualPersonId: ?string,
    +company: ?{|
      +id: string,
      +person: ?{|
        +id: string,
        +ownedProjectPersons: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +firstName: ?string,
              +lastName: ?string,
              +fullName: ?string,
              +profilePictureId: ?string,
              +department: ?{|
                +id: string
              |},
              +role: ?{|
                +id: string
              |},
              +permissions: ?$ReadOnlyArray<?string>,
            |}
          |}>
        |},
      |},
    |},
  |}
|};
export type TimeApprovalLogicQuery = {|
  variables: TimeApprovalLogicQueryVariables,
  response: TimeApprovalLogicQueryResponse,
|};
*/


/*
query TimeApprovalLogicQuery(
  $personId: ID
) {
  viewer {
    id
    actualPersonId
    company {
      id
      person(id: $personId) {
        id
        ownedProjectPersons(first: 1000000) {
          edges {
            node {
              id
              firstName
              lastName
              fullName
              profilePictureId
              department {
                id
              }
              role {
                id
              }
              permissions
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Viewer",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "personId"
              }
            ],
            "concreteType": "Person",
            "kind": "LinkedField",
            "name": "person",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1000000
                  }
                ],
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "ownedProjectPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "DepartmentType",
                            "kind": "LinkedField",
                            "name": "department",
                            "plural": false,
                            "selections": (v2/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "role",
                            "plural": false,
                            "selections": (v2/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "permissions",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "ownedProjectPersons(first:1000000)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeApprovalLogicQuery",
    "selections": (v3/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeApprovalLogicQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "TimeApprovalLogicQuery",
    "operationKind": "query",
    "text": "query TimeApprovalLogicQuery(\n  $personId: ID\n) {\n  viewer {\n    id\n    actualPersonId\n    company {\n      id\n      person(id: $personId) {\n        id\n        ownedProjectPersons(first: 1000000) {\n          edges {\n            node {\n              id\n              firstName\n              lastName\n              fullName\n              profilePictureId\n              department {\n                id\n              }\n              role {\n                id\n              }\n              permissions\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f6a2ec9617d874de264f736fab8bb60a';

module.exports = node;
