import React from 'react';
import {uniqueId} from 'lodash';
import {Table} from 'web-components';
import GroupColumns from './rows/GroupColumns';

export const getBaseLevelTableHeaderTotals = (tableHeader, intl) => {
	const columns = GroupColumns(
		{
			name: intl.formatMessage({id: 'common.totals'}),
			taskTotals: tableHeader.taskTotals,
		},
		intl,
		tableHeader.currency,
		tableHeader.revenueRecognitionNotEqualToBudget,
		tableHeader.customFieldDefinitions?.length
	);
	return (
		<Table.Rows data={{rows: [columns.length]}} canExpand={false} hasHeader>
			{({rowData, tableColumnsProps}) => {
				return (
					<Table.Row {...tableColumnsProps} key={uniqueId('key_')}>
						{columns}
					</Table.Row>
				);
			}}
		</Table.Rows>
	);
};
