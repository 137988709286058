import React from 'react';
import {useIntl} from 'react-intl';
import {IconText, Table} from 'web-components';
import ConnectedProjectLoader, {connectedProjectLoaderQuery} from './ConnectedProjectLoader';
import {filtersToSearchQuery} from '../ProjectPortfolioReportUtils';
import ProjectPortfolioPeriodConnectedProjectDownload, {
	projectPortfolioPeriodConnectedProjectDownloadQuery,
} from './ProjectPortfolioPeriodConnectedProjectDownload';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {LoadMore} from '../../../loaders/LoadMore';
import ProjectPortfolioPeriodRow from './ProjectPortfolioPeriodRow';
import ProjectPortfolioPeriodProjectRows from './ProjectPortfolioPeriodProjectRows';
import {DeprecatedProjectGroupIndicatorNoFragment} from '../../../../forecast-app/shared/components/project-indicator/DeprecatedProjectGroupIndicator';

const ProjectPortfolioPeriodConnectedProjectRows = ({
	setCsvDownloadFunction,
	hasBaseline,
	startDate,
	endDate,
	filters,
	boolEyeOptions,
	pageSize,
}) => {
	const intl = useIntl();

	return (
		<LoadMore
			key="query-render-connected-project"
			query={connectedProjectLoaderQuery}
			variables={{
				searchQuery: filtersToSearchQuery(filters),
				startYear: startDate ? startDate.year() : undefined,
				startMonth: startDate ? startDate.month() + 1 : undefined,
				startDay: startDate ? startDate.date() : undefined,
				endYear: endDate ? endDate.year() : undefined,
				endMonth: endDate ? endDate.month() + 1 : undefined,
				endDay: endDate ? endDate.date() : undefined,
			}}
			loader={<ConnectedProjectLoader />}
		>
			{({data, loadMore}) => {
				setCsvDownloadFunction(() => {
					showModal({
						type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
						componentName: 'project_portfolio_period_connected_project_csv_loader',
						query: projectPortfolioPeriodConnectedProjectDownloadQuery,
						variables: {
							searchQuery: filtersToSearchQuery(filters),
							startYear: startDate ? startDate.year() : undefined,
							startMonth: startDate ? startDate.month() + 1 : undefined,
							startDay: startDate ? startDate.date() : undefined,
							endYear: endDate ? endDate.year() : undefined,
							endMonth: endDate ? endDate.month() + 1 : undefined,
							endDay: endDate ? endDate.date() : undefined,
						},
						createCsvDownload: <ProjectPortfolioPeriodConnectedProjectDownload />,
						startDate,
						endDate,
						boolEyeOptions,
					});
				});
				return (
					<Table.Rows data={data} canExpand={true} loadMore={loadMore} autoload={true}>
						{({rowData, tableColumnsProps, expanded, nextLevelProps}) => {
							return (
								<>
									<ProjectPortfolioPeriodRow
										financialNumbers={rowData.financialNumbers}
										rowData={rowData}
										tableColumnProps={tableColumnsProps}
										headerColumn={
											rowData.connectedProject.companyProjectGroupId ? (
												<IconText
													key={rowData.connectedProject.companyProjectGroupId}
													text={rowData.connectedProject.name}
												>
													<DeprecatedProjectGroupIndicatorNoFragment
														projectGroup={rowData.connectedProject}
													/>
												</IconText>
											) : (
												intl.formatMessage({id: 'common.no_connected_project'})
											)
										}
										hasBaseline={hasBaseline}
									/>
									{expanded && (
										<ProjectPortfolioPeriodProjectRows
											setCsvDownloadFunction={setCsvDownloadFunction}
											hasBaseline={hasBaseline}
											startDate={startDate}
											endDate={endDate}
											filters={filters}
											boolEyeOptions={boolEyeOptions}
											levelProps={nextLevelProps}
											pageSize={pageSize}
											projectGroupId={rowData.connectedProject.id}
										></ProjectPortfolioPeriodProjectRows>
									)}
								</>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default ProjectPortfolioPeriodConnectedProjectRows;
