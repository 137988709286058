import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedMessage} from 'react-intl';
import Calendar from '../../../forecast-app/shared/components/date-picker/calendar';
import DatePickerButton from './date_picker_button';
import ReactDOM from 'react-dom';
import TooltipContainer from '../../../forecast-app/shared/components/tooltips/tooltip_container';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import Util from '../../../forecast-app/shared/util/util';

class FixedRangePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCalendar: false,
			selectingStartDate: true,
			startDate: this.props.startDate.clone().startOf(this.props.rangeType),
			viewedMonth: this.props.startDate.clone().month(),
			viewedYear: this.props.startDate.clone().year(),
			hoveredDate: null,
			calendarRefsMap: null,
			cancelUpdate: false,
			keyCodeUsed: null,
			previouslyFocusedDate: null,
		};
	}

	componentDidMount() {
		/*if (this.calendar) {
			this.calendar.focus();
		}*/
		this.offset = this.date_range_picker_v2.getBoundingClientRect();
		document.addEventListener('scroll', this.collapseCalendar);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// if component receives new dates from props update state
		if (
			(nextProps.startDate && !this.props.startDate) ||
			(!nextProps.startDate && this.props.startDate) ||
			(nextProps.startDate && this.props.startDate && !nextProps.startDate.isSame(this.props.startDate))
		) {
			this.setState({startDate: nextProps.startDate});
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// if caused by pressing escapse dont update
		if (!nextState.showCalendar && this.state.showCalendar && !nextState.cancelUpdate) {
			if (nextState.startDate) {
				this.props.handleDateRangeChange(nextState.startDate);
			} else {
				this.setState({startDate: nextProps.startDate});
			}
		}
		if (nextState.cancelUpdate) {
			this.setState({cancelUpdate: false, startDate: nextProps.startDate});
		}
		if (this.props.usePropsDate && !nextState.startDate.isSame(this.props.startDate)) {
			this.setState({startDate: this.props.startDate});
		}
	}

	componentDidUpdate() {
		/*if (this.calendar) {
			this.calendar.focus();
		}*/
		this.offset = this.date_range_picker_v2.getBoundingClientRect();
	}

	resetPreviouslyFocusedDate() {
		this.setState({previouslyFocusedDate: null, keyCodeUsed: null});
	}

	toggleCalendar(buttonClicked, e) {
		e.preventDefault();
		e.stopPropagation();
		trackEvent('Date Changer Update Date Button', 'Clicked');
		const newState = {
			showCalendar: !this.state.showCalendar,
			viewedMonth: this.state.startDate.clone().startOf(this.props.rangeType).month(),
			viewedYear: this.state.startDate.clone().startOf(this.props.rangeType).year(),
		};
		//reset viewed dates when closing calendar
		if (this.state.showCalendar) {
			newState.selectingStartDate = true;
		} else {
			if (buttonClicked === 'start') {
				newState.selectingStartDate = true;
			} else if (buttonClicked === 'end') {
				newState.selectingStartDate = false;
			}
		}
		this.setState(newState);
	}

	handleDateClick(moment) {
		if (this.props.setHoveredDay) {
			this.props.setHoveredDay(null);
		}
		this.setState({
			startDate: moment.clone().startOf(this.props.rangeType),
			viewedMonth: moment.clone().startOf(this.props.rangeType).month(),
			viewedYear: moment.clone().startOf(this.props.rangeType).year(),
			showCalendar: false,
			hoveredDate: null,
		});
	}

	addContiguousDate(moment, addAsStartDate) {
		if (addAsStartDate) {
			this.setState({startDate: moment});
			if (this.state.endDate) {
			}
		} else {
			this.setState({endDate: moment});
		}
	}

	onDayMouseEnter(day) {
		if (this.props.setHoveredDay) {
			this.props.setHoveredDay(day);
		}
		this.setState({hoveredDate: day});
	}

	onTableBodyMouseLeave() {
		if (this.props.setHoveredDay) {
			this.props.setHoveredDay(null);
		}
		this.setState({hoveredDate: null});
	}

	setRefs(refsMap) {
		this.setState({calendarRefsMap: refsMap});
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		// return if element being focused is part of calendar
		if (
			newTarget &&
			(newTarget.className === 'previous-control' ||
				newTarget.className === 'next-control' ||
				newTarget.className === 'calendars-wrapper' ||
				(newTarget.className.includes('date-picker-button') &&
					!newTarget.className.includes('date-picker-button-standard')) ||
				newTarget.className.includes('calendar-day'))
		) {
			return;
		}
		const newState = {showCalendar: false};
		this.setState(newState);
	}

	handleKeyDown(e) {
		// close calendar on escapse click
		if (e && e.keyCode === 27) {
			const newState = {showCalendar: false, cancelUpdate: true};
			this.setState(newState);
		} else if (
			e &&
			e.keyCode === 13 &&
			this.state.selectingStartDate &&
			((this.state.startDate && !this.props.startDate) ||
				(!this.state.startDate && this.props.startDate) ||
				(this.state.startDate && this.props.startDate && !this.props.startDate.isSame(this.state.startDate)))
		) {
			// if both start and end date selected and different from initial value enter works as confirm button. Save changes and close calendar
			this.setState({showCalendar: false});
		}
	}

	viewPreviousMonth(keyCodeUsed, previouslyFocusedDate) {
		const previousMonth = Util.getMonthStringWithMomentMonth(this.state.viewedMonth, 'MMMM');
		let viewedMonth = this.state.viewedMonth,
			viewedYear = this.state.viewedYear;
		if (viewedMonth === 0) {
			viewedMonth = 11;
			viewedYear--;
		} else {
			viewedMonth--;
		}
		this.setState({viewedMonth, viewedYear, previouslyFocusedDate, keyCodeUsed});
		const currentMonth = Util.getMonthStringWithMomentMonth(viewedMonth, 'MMMM');
		trackEvent('Date Changer Calender Previous Month Button', 'Clicked', {
			previousMonth: previousMonth,
			currentMonth: currentMonth,
		});
	}

	viewNextMonth(keyCodeUsed, previouslyFocusedDate) {
		const previousMonth = Util.getMonthStringWithMomentMonth(this.state.viewedMonth, 'MMMM');
		let viewedMonth = this.state.viewedMonth,
			viewedYear = this.state.viewedYear;
		if (viewedMonth === 11) {
			viewedMonth = 0;
			viewedYear++;
		} else {
			viewedMonth++;
		}

		this.setState({viewedMonth, viewedYear, previouslyFocusedDate, keyCodeUsed});
		const currentMonth = Util.getMonthStringWithMomentMonth(viewedMonth, 'MMMM');
		trackEvent('Date Changer Calender Next Month Button', 'Clicked', {
			previousMonth: previousMonth,
			currentMonth: currentMonth,
		});
	}

	viewNextYear(keyCodeUsed, previouslyFocusedDate) {
		this.setState({viewedYear: this.state.viewedYear + 1, keyCodeUsed, previouslyFocusedDate});
	}

	viewPreviousYear(keyCodeUsed, previouslyFocusedDate) {
		this.setState({viewedYear: this.state.viewedYearFirst - 1, keyCodeUsed, previouslyFocusedDate});
	}

	clearStart(e) {
		e.stopPropagation();
		this.props.handleDateRangeChange(null, this.props.endFrom ? null : this.state.endDate);
	}

	clearEnd(e) {
		e.stopPropagation();
		this.props.handleDateRangeChange(this.props.startFrom ? null : this.state.startDate, null);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const isWeek = this.props.rangeType === 'week';
		const style = {};
		let calendarPlacement = '';
		let calendarIgnoreOffset = false;

		if (this.offset) {
			if (this.offset.top + 312 > window.innerHeight) {
				style.bottom = window.innerHeight - this.offset.top + 15;
				calendarPlacement = 'top';
			} else {
				style.top = this.offset.top + this.offset.height + 15;
				calendarPlacement = 'bottom';
			}
			if (this.offset.left + this.props.calendarOffsetX < 0) {
				style.left = this.offset.left;
				calendarIgnoreOffset = true;
			} else {
				style.left = this.offset.left + this.props.calendarOffsetX;
			}
		}
		return (
			<div
				className={'fixed-range-picker-wrapper-new' + (this.props.useCompactStyle ? ' compact' : '')}
				ref={div => (this.date_range_picker_v2 = div)}
				id="date_range_picker_v2"
			>
				<TooltipContainer {...this.props.tooltipProps}>
					<div className="buttons-wrapper">
						<DatePickerButton
							id={this.props.id}
							cy={this.props.cy}
							onClick={e => this.toggleCalendar('start', e)}
							value={
								this.state.hoveredDate
									? this.state.hoveredDate.clone().startOf(this.props.rangeType)
									: this.state.startDate.clone().startOf(this.props.rangeType)
							}
							isDateRangePicker={false}
							headerText={formatMessage({id: 'common.from'}).toUpperCase()}
							locked={this.props.locked}
							hideLabel={this.props.hideLabel}
							customBackgroundColor={this.props.customBackgroundColor}
							customWidth={isWeek ? null : '100%'}
							whiteText={this.props.whiteText}
							showEmptyDate={this.props.showEmptyDate}
							onBlur={this.props.handleBlur}
							onClear={this.props.clearable ? this.clearEnd.bind(this) : undefined}
							useCompactStyle={this.props.useCompactStyle}
							customHTML={this.props.customPickerHTML}
							startDate={
								this.props.usePropsDate
									? this.props.startDate.clone().startOf(this.props.rangeType)
									: this.state.startDate
							}
							useTextFormat={this.props.useTextFormat}
							showWeekNumber={this.props.showWeekNumber}
							showAsText={this.props.showAsText}
							hideDay={this.props.hideDay}
							formatAsWeekNumber={this.props.formatAsWeekNumber}
							boldLongDayMonth={this.props.boldLongDayMonth}
							customDateFormat={this.props.customDateFormat}
							customPlaceholder={this.props.customPlaceholder}
							customClasses={this.state.showCalendar ? 'expanded' : ''}
							showOnlyIcon={this.props.showOnlyIcon}
						/>
						{isWeek && !this.props.useSinglePicker ? (
							<DatePickerButton
								cy={this.props.cy}
								onClick={e => this.toggleCalendar('start', e)}
								value={
									this.state.hoveredDate
										? this.state.hoveredDate.clone().startOf(this.props.rangeType)
										: this.state.startDate.clone().startOf(this.props.rangeType)
								}
								isDateRangePicker={false}
								headerText={formatMessage({id: 'common.from'}).toUpperCase()}
								locked={this.props.locked}
								hideLabel={this.props.hideLabel}
								customBackgroundColor={this.props.customBackgroundColor}
								customWidth={isWeek ? null : '100%'}
								whiteText={this.props.whiteText}
								showEmptyDate={this.props.showEmptyDate}
								onBlur={this.props.handleBlur}
								onClear={this.props.clearable ? this.clearEnd.bind(this) : undefined}
								useCompactStyle={this.props.useCompactStyle}
								customHTML={this.props.customPickerHTML}
								startDate={
									this.props.usePropsDate
										? this.props.startDate.clone().startOf(this.props.rangeType)
										: this.state.startDate
								}
								useTextFormat={this.props.useTextFormat}
								showWeekNumber={this.props.showWeekNumber}
								showAsText={this.props.showAsText}
								hideDay={this.props.hideDay}
								formatAsWeekNumber={this.props.formatAsWeekNumber}
								boldLongDayMonth={this.props.boldLongDayMonth}
								customDateFormat={this.props.customDateFormat}
								customPlaceholder={this.props.customPlaceholder}
								customClasses={this.state.showCalendar ? 'expanded' : ''}
							/>
						) : null}
					</div>
				</TooltipContainer>
				{this.state.showCalendar
					? ReactDOM.createPortal(
							<div className="calendars-dropdown-wrapper" style={style}>
								<div
									className="calendars-wrapper"
									tabIndex="0"
									onBlur={this.handleBlur.bind(this)}
									ref={div => (this.calendar = div ? div : null)}
									onKeyDown={this.handleKeyDown.bind(this)}
								>
									{this.props.hidePointerArrow ? null : calendarPlacement === 'bottom' ? (
										<div
											className={
												'triangle-up fixed-range-picker' +
												(this.props.wideButton
													? ' wide' + (calendarIgnoreOffset ? ' ignore-offset' : '')
													: '')
											}
										>
											<svg width="24" height="10">
												<polygon
													style={{
														fill: 'white',
														stroke: '#e6e6e6',
														strokeWidth: 1,
														fillRule: 'evenodd',
													}}
													points="0,10 12,0 24,10"
												/>
											</svg>
											<div className="border-cover" />
										</div>
									) : (
										<div
											className={
												'triangle-down fixed-range-picker' +
												(this.props.wideButton
													? ' wide' + (calendarIgnoreOffset ? ' ignore-offset' : '')
													: '')
											}
										>
											<svg width="24" height="10">
												<polygon
													style={{
														fill: 'white',
														stroke: '#e6e6e6',
														strokeWidth: 1,
														fillRule: 'evenodd',
													}}
													points="0,0 12,10 24,0"
												/>
											</svg>
											<div className="border-cover" />
										</div>
									)}
									<Calendar
										addContiguousDate={
											this.props.rangeType === 'day' ? null : this.addContiguousDate.bind(this)
										}
										first={true}
										disableHoverColor={this.props.disableHoverColor}
										hoveredDate={this.state.hoveredDate}
										keyCodeUsed={this.state.keyCodeUsed}
										onDateClick={this.handleDateClick.bind(this)}
										onDayMouseEnter={this.onDayMouseEnter.bind(this)}
										onTableBodyMouseLeave={this.onTableBodyMouseLeave.bind(this)}
										previouslyFocusedDate={this.state.previouslyFocusedDate}
										refsMap={this.state.calendarRefsMap}
										resetPreviouslyFocusedDate={this.resetPreviouslyFocusedDate.bind(this)}
										setRefs={this.setRefs.bind(this)}
										startDate={this.state.startDate.clone().startOf(this.props.rangeType)}
										endDate={this.state.startDate.clone().endOf(this.props.rangeType)}
										selectingStartDate={this.state.selectingStartDate}
										viewedMonth={this.state.viewedMonth}
										viewedYear={this.state.viewedYear}
										isFixedRangePicker={isWeek || this.props.rangeType === 'month'}
										viewPreviousMonth={this.viewPreviousMonth.bind(this)}
										viewNextMonth={this.viewNextMonth.bind(this)}
										viewNextYear={this.viewNextYear.bind(this)}
										viewPreviousYear={this.viewPreviousYear.bind(this)}
										showAllDays={false}
										rangeType={this.props.rangeType}
										disabledDates={this.props.disabledDates}
										startDateLimite={this.props.startDateLimite}
									/>
								</div>
								{this.props.startFrom || this.props.endFrom ? (
									<div className="dates-from">
										<span className="star">*</span>
										<FormattedMessage
											id="card_modal.dates_from"
											values={{name: this.props.startFrom || this.props.endFrom}}
										/>
									</div>
								) : null}
							</div>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</div>
		);
	}
}
FixedRangePicker.propTypes = {
	rangeType: PropTypes.oneOf(['day', 'week', 'month']),
	startDate: PropTypes.object.isRequired,
	handleDateRangeChange: PropTypes.func.isRequired,
	locked: PropTypes.bool,
	calendarOffsetX: PropTypes.number,
	hideLabel: PropTypes.bool,
	customBackgroundColor: PropTypes.string,
	whiteText: PropTypes.bool,
	showEmptyDate: PropTypes.bool,
	handleBlur: PropTypes.func,
	clearable: PropTypes.bool,
	useCompactStyle: PropTypes.bool,
	hidePointerArrow: PropTypes.bool,
	customPickerHTML: PropTypes.func,
	usePropsDate: PropTypes.bool,
	cy: PropTypes.string,
};
export default injectIntl(FixedRangePicker);
