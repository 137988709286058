import styled from 'styled-components';

export const GithubRepositoriesWrapper = styled.div`
	margin-top: 12px;
`;

export const RadioButtonWrapper = styled.div`
	margin-left: 24px;
`;

export const WarningWrapper = styled.div`
	margin-top: 12px;
`;
