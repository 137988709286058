/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type LabelDropdown_labels$ref: FragmentReference;
declare export opaque type LabelDropdown_labels$fragmentType: LabelDropdown_labels$ref;
export type LabelDropdown_labels = $ReadOnlyArray<{|
  +color: ?string,
  +name: ?string,
  +id: string,
  +category: ?{|
    +id: string,
    +name: ?string,
  |},
  +$refType: LabelDropdown_labels$ref,
|}>;
export type LabelDropdown_labels$data = LabelDropdown_labels;
export type LabelDropdown_labels$key = $ReadOnlyArray<{
  +$data?: LabelDropdown_labels$data,
  +$fragmentRefs: LabelDropdown_labels$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LabelDropdown_labels",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "LabelCategory",
      "kind": "LinkedField",
      "name": "category",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Label"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bb65af02bff90a4782cdd103727b3a83';

module.exports = node;
