import {useDataExport} from '../../../forecast-app/shared/hooks/useDataExport';
import TaskFormatter from '../../../forecast-app/shared/util/export-formatters/TaskFormatter';
import {createFragmentContainer, graphql} from 'react-relay';
import {closeModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {useEffect} from 'react';

const exportToCSV = (viewer, enabledColumns, intl, reportName) => {
	const availableColumns = Object.keys(enabledColumns).map(column => {
		return {name: column, checked: enabledColumns[column]};
	});

	const exportData = useDataExport(
		new TaskFormatter(
			intl,
			[],
			[
				'taskDates',
				'phaseDates',
				'sprintDates',
				'work',
				'reported',
				'work.estimate.hours',
				'work.estimate.points',
				'work.remaining.hours',
				'work.remaining.points',
				'work.difference.hours',
				'work.difference.points',
				'financials',
				'financials.revenueRecognition',
				'financials.timeMaterial',
				'customField_',
			],
			true
		),
		null,
		data => data,
		_ => `${reportName ? reportName.replace(',', '') : 'untitled_task_report'}`
	);

	const tasks = viewer.company.allTasks.edges.map(task => task.node);
	const projects = tasks.map(task => task.project);

	exportData(availableColumns, tasks, {projects: projects}, null, 'CSV');
};

const ExportTaskReportCSV = ({viewer, enabledColumns, intl, reportName}) => {
	useEffect(() => {
		if (viewer) {
			exportToCSV(viewer, enabledColumns, intl, reportName);
			closeModal();
		}
	}, [viewer]);

	return null;
};

const ExportTaskReportCSVQuery = graphql`
	query ExportTaskReportCSV_Query(
		$pageSize: Int
		$searchQuery: TaskSearchQueryType
		$sortValue: String
		$validateAccess: Boolean
	) {
		viewer {
			actualPersonId
			availableFeatureFlags {
				key
			}
			component(name: "export_task_report_csv")
			...ExportTaskReportCSV_viewer
				@arguments(
					pageSize: $pageSize
					searchQuery: $searchQuery
					sortValue: $sortValue
					validateAccess: $validateAccess
				)
		}
	}
`;

export {ExportTaskReportCSVQuery};

export default createFragmentContainer(ExportTaskReportCSV, {
	viewer: graphql`
		fragment ExportTaskReportCSV_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType"}
			pageSize: {type: "Int"}
			sortValue: {type: "String"}
			validateAccess: {type: "Boolean"}
		) {
			company {
				allTasks(first: $pageSize, searchQuery: $searchQuery)
					@connection(key: "Company_allTasks", filters: ["searchQuery"]) {
					pageInfo {
						endCursor
						hasNextPage
					}
					edges {
						node {
							id
							customFieldValues {
								edges {
									node {
										id
										key
										value
										displayName
									}
								}
							}
							approved
							timeRegistrations(first: 100000000) {
								edges {
									node {
										id
										minutesRegistered
										price
									}
								}
							}
							sortOrder
							statusColumnV2 {
								id
								name
							}
							# filter and sort start
							companyTaskId
							name
							deadlineDay
							deadlineMonth
							deadlineYear
							startYear
							startMonth
							startDay
							bug
							blocked
							highPriority
							billable
							progress
							estimateForecast
							estimateForecastPrice
							timeLeft
							projectedTime
							project {
								id
								status
								companyProjectId
								customProjectId
								minutesPerEstimationPoint
								projectEndDay
								projectEndMonth
								projectEndYear
								estimationUnit
								name
								client {
									id
									name
								}
								rateCard {
									id
									name
								}
							}
							taskLabels {
								label {
									id
									name
									color
								}
							}
							assignedPersons {
								id
								firstName
								lastName
							}
							role {
								name
							}
							phase {
								id
								name
								deadlineDay
								deadlineMonth
								deadlineYear
								startDay
								startMonth
								startYear
							}
							sprint {
								id
								endDay
								endMonth
								endYear
								startDay
								startMonth
								startYear
								#start -sprint section
								name
								isProjectGroupSprint
								projectGroupSprintId
								#end - sprint section
							}
							owner {
								id
							}
							followers {
								id
							}
							financialNumbers {
								plannedRevenue
								actualRevenue
								remainingRevenue
								forecastRevenue
								recognitionLockedRevenue
								recognitionOpenRevenue
								recognitionForecastRevenue
								surplus
							}
							# filter and sort end
						}
					}
				}
			}
		}
	`,
});
