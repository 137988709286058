import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import InvoiceDetailsTable from './InvoiceDetailsTable';
import {BudgetTooltips} from '../../util/BudgetTooltips';
import {BudgetEyeOptions} from '../../BudgetPage.EyeOptions';

const InvoiceDetails = ({project, selectedAggregateLevel, eyeOptionMap}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	return (
		<>
			{eyeOptionMap[BudgetEyeOptions.INVOICE] && (
				<InvoiceDetailsTable
					key={`invoice-${selectedAggregateLevel}`}
					cy={'details-invoice'}
					title={intl.formatMessage({id: 'project_budget.invoice'})}
					project={project}
					selectedAggregateLevel={selectedAggregateLevel}
					aggregates={['invoiced', 'paid']}
				/>
			)}
			{eyeOptionMap[BudgetEyeOptions.INVOICE_VS_ACTUAL] && (
				<InvoiceDetailsTable
					key={`invoice-actual-${selectedAggregateLevel}`}
					cy={'details-accrued'}
					title={intl.formatMessage({id: 'project_budget.accrued'})}
					project={project}
					selectedAggregateLevel={selectedAggregateLevel}
					aggregates={['invoiced', 'recognitionLockedRevenue', 'accruedDeferred']}
					tooltips={BudgetTooltips.InvoicedVsActualTooltips(formatMessage, project)}
				/>
			)}
		</>
	);
};

export default createFragmentContainer(InvoiceDetails, {
	project: graphql`
		fragment InvoiceDetails_project on ProjectType {
			...InvoiceDetailsTable_project
			budgetType
		}
	`,
});
