import React, {useEffect, useState} from 'react';
import Moment from 'moment';
import {createFragmentContainer, graphql} from 'react-relay';
import {PageWrapper} from '../../../forecast-app/my-work-tab/my-timesheets-page/timesheets_styled';
import {
	handleAdjacentPeriodClick,
	handleDateRangeClick,
	TIME_PERIOD,
} from '../../../forecast-app/my-work-tab/my-timesheets-page/timesheets_change_date_view';

import TimesheetsTeamPage, {timesheetsTeamPageQuery} from './timesheets_team_page';
import Util from '../../../forecast-app/shared/util/util';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import {getTimeOffSearchQuery} from '../TimeManagementUtil';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {getTaskUrl, pathIncludesTask} from '../../../forecast-app/shared/util/UrlUtil';

const timesheetsTeam = ({viewer}) => {
	const history = useHistory();
	if (!viewer.company) return <div></div>;
	if (!Util.AuthorizeViewerAccess('team-time')) {
		const path = history.location.pathname;
		if (pathIncludesTask(path)) {
			history.push(getTaskUrl(path));
		} else {
			// if user doesnt have access rights to view this page redirect to no access page
			history.push('/not-authorized');
		}
		return <div></div>;
	}

	const [currentViewingDate, setCurrentViewingDate] = useState(Moment());
	const timePeriod = TIME_PERIOD.WEEK_VIEW;

	useEffect(() => {
		document.title = 'Team Timesheets - Forecast';
	}, []);

	const goToToday = () => {
		setCurrentViewingDate(Moment());
	};

	const adjacentPeriod = (calendareShown, forward) => {
		setCurrentViewingDate(handleAdjacentPeriodClick(currentViewingDate, forward, timePeriod));
	};

	const handleDateRange = (calendareShown, date) => {
		setCurrentViewingDate(handleDateRangeClick(date, timePeriod));
	};

	useTrackPage('Team Timesheets');

	return (
		<>
			<TopHeaderBarWrapper sidePadding={24}>
				<TopHeaderBar title="Timesheet Entries" />
			</TopHeaderBarWrapper>
			<PageWrapper data-cy={'company-timesheets-page'}>
				<ForecastQueryRenderer
					key="query-render-timesheetsTeamPage"
					query={timesheetsTeamPageQuery}
					authorizeAccessRoute={'team-time'}
					variables={{
						startDateString: Moment().clone().startOf('week').format('YYYY-MM-DD'),
						endDateString: Moment().clone().endOf('week').format('YYYY-MM-DD'),
						searchQuery: getTimeOffSearchQuery(viewer),
					}}
					render={(relayProps, retry) => {
						return (
							<TimesheetsTeamPage
								retry={retry}
								{...relayProps}
								goToToday={goToToday}
								adjacentPeriod={adjacentPeriod}
								currentViewingDate={currentViewingDate}
								handleDateRange={handleDateRange}
							/>
						);
					}}
				/>
			</PageWrapper>
		</>
	);
};

const timesheetsTeamQuery = graphql`
	query timesheetsTeam_Query {
		viewer {
			actualPersonId
			component(name: "team-timesheet")
			...timesheetsTeam_viewer
			company {
				id
			}
		}
	}
`;

export {timesheetsTeamQuery};

export default createFragmentContainer(timesheetsTeam, {
	viewer: graphql`
		fragment timesheetsTeam_viewer on Viewer {
			component(name: "team-timesheet")
			company {
				id
			}
		}
	`,
});
