/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PlanActualTimeChart_viewer$ref = any;
export type PlanActualTimeChart_QueryVariables = {|
  projectId?: ?string,
  startYear: number,
  startMonth: number,
  startDay: number,
  endYear: number,
  endMonth: number,
  endDay: number,
|};
export type PlanActualTimeChart_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: PlanActualTimeChart_viewer$ref,
  |}
|};
export type PlanActualTimeChart_Query = {|
  variables: PlanActualTimeChart_QueryVariables,
  response: PlanActualTimeChart_QueryResponse,
|};
*/


/*
query PlanActualTimeChart_Query(
  $projectId: ID
  $startYear: Int!
  $startMonth: Int!
  $startDay: Int!
  $endYear: Int!
  $endMonth: Int!
  $endDay: Int!
) {
  viewer {
    id
    actualPersonId
    component(name: "project_budget_v3_time_charts")
    ...PlanActualTimeChart_viewer_3oGw83
  }
}

fragment PlanActualTimeChart_viewer_3oGw83 on Viewer {
  project(internalId: $projectId) {
    projectFirstDateYear
    projectFirstDateMonth
    projectFirstDateDay
    projectLastDateYear
    projectLastDateMonth
    projectLastDateDay
    estimationUnit
    budget
    budgetType
    aggregatedFinancialNumbers(startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, aggregates: [scopeApprovedMinutes, registeredMinutes, forecastMinutes, baselineMinutes], aggregateLevel: DAY, addAccumulatedNumbers: true, convertToProjectCurrency: true)
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startYear",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startMonth",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startDay",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endYear",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endMonth",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endDay",
    "type": "Int!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "project_budget_v3_time_charts"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"project_budget_v3_time_charts\")"
},
v4 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v5 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v6 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v7 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v8 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v9 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlanActualTimeChart_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "PlanActualTimeChart_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlanActualTimeChart_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectFirstDateYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectFirstDateMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectFirstDateDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectLastDateYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectLastDateMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectLastDateDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimationUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budget",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budgetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "addAccumulatedNumbers",
                    "value": true
                  },
                  {
                    "kind": "Literal",
                    "name": "aggregateLevel",
                    "value": "DAY"
                  },
                  {
                    "kind": "Literal",
                    "name": "aggregates",
                    "value": [
                      "scopeApprovedMinutes",
                      "registeredMinutes",
                      "forecastMinutes",
                      "baselineMinutes"
                    ]
                  },
                  {
                    "kind": "Literal",
                    "name": "convertToProjectCurrency",
                    "value": true
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "kind": "ScalarField",
                "name": "aggregatedFinancialNumbers",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "PlanActualTimeChart_Query",
    "operationKind": "query",
    "text": "query PlanActualTimeChart_Query(\n  $projectId: ID\n  $startYear: Int!\n  $startMonth: Int!\n  $startDay: Int!\n  $endYear: Int!\n  $endMonth: Int!\n  $endDay: Int!\n) {\n  viewer {\n    id\n    actualPersonId\n    component(name: \"project_budget_v3_time_charts\")\n    ...PlanActualTimeChart_viewer_3oGw83\n  }\n}\n\nfragment PlanActualTimeChart_viewer_3oGw83 on Viewer {\n  project(internalId: $projectId) {\n    projectFirstDateYear\n    projectFirstDateMonth\n    projectFirstDateDay\n    projectLastDateYear\n    projectLastDateMonth\n    projectLastDateDay\n    estimationUnit\n    budget\n    budgetType\n    aggregatedFinancialNumbers(startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, aggregates: [scopeApprovedMinutes, registeredMinutes, forecastMinutes, baselineMinutes], aggregateLevel: DAY, addAccumulatedNumbers: true, convertToProjectCurrency: true)\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5de611bff89611fc552f1304296439c4';

module.exports = node;
