import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {CaretIcon} from 'web-components';
import {BUTTON_STYLE, BUTTON_COLOR, TIME_VIEW} from '../../../constants';
import Button from '../../../forecast-app/shared/components/buttons/button/button';
import FixedRangePicker from './fixed_range_picker';

class DateChanger extends Component {
	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className={'date-change-section'}>
				<div
					className={'day-changer ' + this.props.selectedTab}
					data-userpilot={this.props.userpilot}
					data-cy="new-ui-time-day-changer"
				>
					{this.props.onlyTodayButton ? null : (
						<FixedRangePicker
							useTextFormat={true}
							showWeekNumber={
								this.props.selectedTab === TIME_VIEW.WEEK || this.props.selectedTab === TIME_VIEW.DAY
							}
							hideDay={this.props.selectedTab === TIME_VIEW.WEEK || this.props.selectedTab === TIME_VIEW.MONTH}
							showAsText={true}
							useSinglePicker={true}
							usePropsDate={true}
							disableHoverColor={true}
							rangeType={this.props.selectedTab}
							customBackgroundColor={'#f6f6f6'}
							useCompactStyle={true}
							calendarOffsetX={-84}
							hideLabel={true}
							startDate={this.props.currentViewingDate.clone()}
							locale={this.props.locale}
							showEmptyDate={true}
							handleDateRangeChange={start => this.props.updateDateRange(start)}
							customPickerHTML={
								this.props.selectedTab === TIME_VIEW.WEEK ? null : date => this.props.createPickerHTML(date)
							}
							boldLongDayMonth={this.props.boldLongDayMonth}
							tooltipEnabled={this.props.selectorTooltipEnabled}
							tooltipProps={this.props.selectorTooltipProps}
						/>
					)}
					<button
						title={formatMessage({id: 'common.previous'})}
						className="change-date-button previous-date"
						onClick={() => this.props.handleMoveDateButtonClick(false)}
						data-cy="new-ui-time-change-date-button-previous-date"
					>
						<CaretIcon direction={CaretIcon.DIRECTION.LEFT} color={'#535353'} />
					</button>
					<Button
						text={formatMessage({id: 'common.today'})}
						className="today-button"
						onClick={this.props.handleTodayButtonClick}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={BUTTON_COLOR.WHITE}
						tooltipEnabled={this.props.tooltipEnabled}
						tooltipProps={this.props.tooltipProps}
						cy={'new-ui-time-change-date-button-today'}
					/>
					<button
						title={formatMessage({id: 'common.next'})}
						className="change-date-button next-date"
						onClick={() => this.props.handleMoveDateButtonClick(true)}
						data-cy="new-ui-time-change-date-button-next-date"
					>
						<CaretIcon direction={CaretIcon.DIRECTION.RIGHT} color={'#535353'} />
					</button>
				</div>
			</div>
		);
	}
}
DateChanger.propTypes = {};
export default injectIntl(DateChanger);
