import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import ChartJsPie from '../../chartjs/chart_js_pie';

class sprintsCompletedComponent extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}

	render() {
		if (this.props.viewer.insightComponentsData.sprintsCompleted) {
			const {formatMessage} = this.props.intl;
			const doneCount = this.props.viewer.insightComponentsData.sprintsCompleted.doneSprints;
			const leftCount = this.props.viewer.insightComponentsData.sprintsCompleted.remainingSprints;
			const data = {
				labels: [
					formatMessage({id: 'insights.component.sprint.sprints_done'}, {count: doneCount}),
					formatMessage({id: 'insights.component.sprint.sprints_left'}, {count: leftCount}),
				],
				datasets: [
					{
						data: [doneCount, leftCount],
						backgroundColor: ['#FF7C75', '#FFBAB6'],
					},
				],
			};

			return <ChartJsPie data={data} />;
		} else {
			return null;
		}
	}
}

const sprintsCompletedComponentQuery = graphql`
	query sprintsCompletedComponent_Query($shareKey: String, $projectId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_sprints_completed")
			...sprintsCompletedComponent_viewer
				@arguments(shareKey: $shareKey, projectId: $projectId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {sprintsCompletedComponentQuery};

export default injectIntl(
	createFragmentContainer(sprintsCompletedComponent, {
		viewer: graphql`
			fragment sprintsCompletedComponent_viewer on Viewer
			@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
				insightComponentsData(shareKey: $shareKey) {
					sprintsCompleted(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
						doneSprints
						remainingSprints
					}
				}
			}
		`,
	})
);
