import TimelineGraphItem from '../../components/items/timeline_graph_item';
import {
	getStepDataArrayStartAndEndDate,
	GROUP_TYPE,
	isDayOffStep,
	isGlobalRecalculationNeeded,
	TIMELINE_GRAPH_BAR_ELEMENT_TYPE,
	TIMELINE_GRAPH_BAR_HIGHLIGHTER_HEIGHT,
	TIMELINE_GRAPH_HIGHLIGHTER_TYPE,
	TIMELINE_GRAPH_LINE_TYPE,
} from '../../canvas-timeline/canvas_timeline_util';
import {clearNeedsRecalculation, getCachedHeatmapData, getGroupCache, getStepCache, setStepCachedItem} from '../HeatmapLogic';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import HeatmapItem, {HEATMAP_TYPE} from '../../components/items/Heatmap/HeatmapItem';
import ComposeManager from '../../ComposeManager';
import EventManager from '../../EventManager';
import {NO_ROLE, ROLE_GROUPING_SUB_GROUP_TYPE} from '../../IDManager';
import RecalculationManager from '../../RecalculationManager';

export const calculateRoleCapacityHeatmapCache = (pageComponent, group, stepDataArray, timelineMinorStep) => {
	if (isGlobalRecalculationNeeded(pageComponent)) {
		const {calcWin, hideSoft, hideHard} = pageComponent.state.schedulingOptions;

		const peopleGroup = group.groups.find(group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING);
		const placeholdersGroup = group.groups.find(
			group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND
		);

		// We depend on the cache generated by child groups, so ensure we compose them before fetching from cache
		peopleGroup.calculateHeatmapCache(peopleGroup, stepDataArray, timelineMinorStep);
		placeholdersGroup.calculateHeatmapCache(placeholdersGroup, stepDataArray, timelineMinorStep);

		// cache for graph
		const graphCache = getGroupCache(pageComponent, group.id);
		const stepCache = getStepCache(graphCache, timelineMinorStep);

		for (const stepData of stepDataArray) {
			let {startDate, endDate} = stepData;

			const peopleGroupHeatmap = getCachedHeatmapData(pageComponent, peopleGroup, timelineMinorStep, startDate, endDate);
			const placeholdersGroupHeatmap = getCachedHeatmapData(
				pageComponent,
				placeholdersGroup,
				timelineMinorStep,
				startDate,
				endDate
			);

			const minutesAvailable = peopleGroupHeatmap?.minutesAvailable || 0;
			let peopleDemand = hideHard ? 0 : peopleGroupHeatmap?.plannedTotalMinutesHard || 0;
			const placeholderSoftAllocation = calcWin
				? placeholdersGroupHeatmap?.plannedTotalMinutesSoftWin
				: placeholdersGroupHeatmap?.plannedTotalMinutesSoft;
			const peopleSoftAllocation = hideSoft
				? 0
				: calcWin
				? peopleGroupHeatmap?.plannedTotalMinutesSoftWin
				: peopleGroupHeatmap?.plannedTotalMinutesSoft;
			peopleDemand += peopleSoftAllocation || 0;
			const placeholderDemand = placeholderSoftAllocation || 0;

			const heatmapData = {
				peopleDemand,
				placeholderDemand,
				minutesAvailable,
			};

			// cache heatmap data
			setStepCachedItem(group.id, stepCache, timelineMinorStep, heatmapData, startDate, endDate);
		}

		if (hasFeatureFlag('scheduling_recalculation_tree')) {
			const {overallStartDate, overallEndDate} = getStepDataArrayStartAndEndDate(stepDataArray);
			RecalculationManager.clearNeedsRecalculation(group.id, timelineMinorStep, overallStartDate, overallEndDate);
		} else {
			clearNeedsRecalculation(graphCache, timelineMinorStep);
		}
	}
};

const getRoleOverAllocation = (pageComponent, roleGroup, timelineMinorStep, startDate) => {
	const groupCache = pageComponent.heatmapCache.get(roleGroup.id);

	if (groupCache) {
		const stepCache = getStepCache(groupCache, timelineMinorStep);
		const heatmapData = stepCache.get(startDate);

		if (heatmapData) {
			const {peopleDemand, placeholderDemand, minutesAvailable} = heatmapData;
			const totalDemand = peopleDemand + placeholderDemand;

			if (totalDemand > minutesAvailable) {
				return totalDemand - minutesAvailable;
			}
		}
	}
	return 0;
};

const isRoleOverAllocated = (pageComponent, roleGroupingGroups, timelineMinorStep, startDate) => {
	let roleOverAllocated = false;

	for (const roleGroup of roleGroupingGroups) {
		const roleOverAllocation = getRoleOverAllocation(pageComponent, roleGroup, timelineMinorStep, startDate);

		if (roleOverAllocation) {
			roleOverAllocated = true;
			break;
		}
	}

	return roleOverAllocated;
};

const getOverAllocatedRoles = (pageComponent, roleGroupingGroups, timelineMinorStep, startDate) => {
	const overAllocatedRoles = [];

	roleGroupingGroups.forEach(roleGroup => {
		const roleOverAllocation = getRoleOverAllocation(pageComponent, roleGroup, timelineMinorStep, startDate);

		if (roleOverAllocation) {
			overAllocatedRoles.push({
				name: roleGroup.data.name,
				value: roleOverAllocation,
			});
		}
	});

	return overAllocatedRoles;
};

const getTotalAvailability = (pageComponent, startDate, roleGroupingGroups, timelineMinorStep) => {
	let totalAvailability = 0;

	for (const roleGroup of roleGroupingGroups) {
		const groupCache = pageComponent.heatmapCache.get(roleGroup.id);

		if (groupCache) {
			const stepCache = getStepCache(groupCache, timelineMinorStep);
			const heatmapData = stepCache.get(startDate);

			if (heatmapData) {
				const {minutesAvailable} = heatmapData;
				totalAvailability += minutesAvailable;
			}
		}
	}

	return totalAvailability;
};

export const getRolePlaceholderDemand = (pageComponent, roleGroup, timelineMinorStep, startDate) => {
	const {schedulingOptions} = pageComponent.state;
	const {calcWin} = schedulingOptions;
	let placeholdersDemand = 0;

	const placeholderGroupingGroup = roleGroup.groups.find(
		subGroup => subGroup.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND
	);
	const groupCache = pageComponent.heatmapCache.get(placeholderGroupingGroup.id);

	if (groupCache) {
		const stepCache = groupCache.get(timelineMinorStep);
		const heatmapData = stepCache.get(startDate);

		if (heatmapData) {
			const {plannedTotalMinutesSoft, plannedTotalMinutesSoftWin} = heatmapData;
			placeholdersDemand += calcWin ? plannedTotalMinutesSoftWin : plannedTotalMinutesSoft;
		}
	}

	return placeholdersDemand;
};

const getPlaceholdersDemand = (pageComponent, startDate, roleGroupingGroups, timelineMinorStep) => {
	let placeholdersDemand = 0;

	for (const roleGroup of roleGroupingGroups) {
		placeholdersDemand += getRolePlaceholderDemand(pageComponent, roleGroup, timelineMinorStep, startDate);
	}

	return placeholdersDemand;
};

const getPeopleDemand = (pageComponent, startDate, roleGroupingGroups, timelineMinorStep) => {
	let peopleDemand = 0;

	for (const roleGroup of roleGroupingGroups) {
		const peopleRemainingGroup = roleGroup.groups.find(
			subGroup => subGroup.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING
		);
		const groupCache = pageComponent.heatmapCache.get(peopleRemainingGroup.id);

		if (groupCache) {
			const stepCache = getStepCache(groupCache, timelineMinorStep);
			const heatmapData = stepCache.get(startDate);

			if (heatmapData) {
				const {plannedTotalMinutesHard} = heatmapData;
				peopleDemand += plannedTotalMinutesHard;
			}
		}
	}

	return peopleDemand;
};

const getPeopleSoftDemand = (pageComponent, startDate, roleGroupingGroups, timelineMinorStep) => {
	const {schedulingOptions} = pageComponent.state;
	const {calcWin} = schedulingOptions;

	let peopleSoftDemand = 0;

	for (const roleGroup of roleGroupingGroups) {
		const peopleRemainingGroup = roleGroup.groups.find(
			subGroup => subGroup.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING
		);
		const groupCache = pageComponent.heatmapCache.get(peopleRemainingGroup.id);

		if (groupCache) {
			const stepCache = getStepCache(groupCache, timelineMinorStep);
			const heatmapData = stepCache.get(startDate);

			if (heatmapData) {
				const {plannedTotalMinutesSoft, plannedTotalMinutesSoftWin} = heatmapData;
				peopleSoftDemand += calcWin ? plannedTotalMinutesSoftWin : plannedTotalMinutesSoft;
			}
		}
	}

	return peopleSoftDemand;
};

export const composeTimelineGraphRow = (
	pageComponent,
	heatmapCache,
	group,
	stepDataArray,
	timelineStartDate,
	pixelsPerDay,
	timelineMinorStep,
	sections
) => {
	const {groups, eyeOptions} = pageComponent.state;
	const graphItems = [];

	const visibleSections = sections.filter(section => !section.isHidden || section.isHidden() === false);

	// get sections
	const roleOverAllocatedSection = visibleSections.find(
		section => section.entity === TIMELINE_GRAPH_HIGHLIGHTER_TYPE.ROLE_OVERALLOCATED
	);
	const totalAvailabilitySection = visibleSections.find(
		section => section.entity === TIMELINE_GRAPH_LINE_TYPE.TOTAL_AVAILABILITY
	);
	const placeholdersSection = visibleSections.find(section => section.entity === TIMELINE_GRAPH_BAR_ELEMENT_TYPE.PLACEHOLDER);
	const allocationSection = visibleSections.find(section => section.entity === TIMELINE_GRAPH_BAR_ELEMENT_TYPE.ALLOCATION);
	const softAllocationSection = visibleSections.find(
		section => section.entity === TIMELINE_GRAPH_BAR_ELEMENT_TYPE.SOFT_ALLOCATION
	);

	const roleGroupingGroups = groups.filter(group => group.groupType === GROUP_TYPE.PERSON_GROUPING_GROUP);

	// calculate role grouping heatmap caches
	roleGroupingGroups.forEach(roleGrouping => {
		roleGrouping.calculateHeatmapCache(roleGrouping, stepDataArray, timelineMinorStep);
	});

	const graphValues = {};
	let index = 0;
	for (const stepData of stepDataArray) {
		let {startDate} = stepData;

		const sectionValues = {};

		// ROLE OVER ALLOCATED
		if (roleOverAllocatedSection) {
			const noRoleFilteredGroups = roleGroupingGroups.filter(group => group.id !== NO_ROLE);
			sectionValues[TIMELINE_GRAPH_HIGHLIGHTER_TYPE.ROLE_OVERALLOCATED] = {
				shouldHighlight: isRoleOverAllocated(pageComponent, noRoleFilteredGroups, timelineMinorStep, startDate),
			};
		}

		// TOTAL AVAILABILITY
		if (totalAvailabilitySection) {
			sectionValues[TIMELINE_GRAPH_LINE_TYPE.TOTAL_AVAILABILITY] = {
				previous: stepDataArray[index - 1]
					? getTotalAvailability(
							pageComponent,
							stepDataArray[index - 1].startDate,
							roleGroupingGroups,
							timelineMinorStep
					  )
					: null,
				current: getTotalAvailability(pageComponent, startDate, roleGroupingGroups, timelineMinorStep),
				next: stepDataArray[index + 1]
					? getTotalAvailability(
							pageComponent,
							stepDataArray[index + 1].startDate,
							roleGroupingGroups,
							timelineMinorStep
					  )
					: null,
			};
		}

		// PLACEHOLDERS
		if (placeholdersSection) {
			sectionValues[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.PLACEHOLDER] = {
				value: getPlaceholdersDemand(pageComponent, startDate, roleGroupingGroups, timelineMinorStep),
			};
		}

		// ALLOCATIONS
		if (allocationSection) {
			sectionValues[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.ALLOCATION] = {
				value: getPeopleDemand(pageComponent, startDate, roleGroupingGroups, timelineMinorStep),
			};
		}

		// SOFT ALLOCATIONS
		if (softAllocationSection) {
			sectionValues[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.SOFT_ALLOCATION] = {
				value: getPeopleSoftDemand(pageComponent, startDate, roleGroupingGroups, timelineMinorStep),
			};
		}

		graphValues[startDate] = sectionValues;
		index++;
	}

	// determine maximum section value across step data array
	let maximumLineValue = 0;
	let maximumBarElementsValue = 0;
	Object.entries(graphValues).forEach(graphStep => {
		const sectionValues = graphStep[1];

		let barValues = 0;
		Object.entries(sectionValues).forEach(entry => {
			const sectionValue = entry[1];

			// maximum line value
			if (sectionValue.current && sectionValue.current > maximumLineValue) {
				maximumLineValue = sectionValue.current;
			}

			// maximum bar elements value
			if (sectionValue.value) {
				barValues += sectionValue.value;
			}

			// bar element highlighter
			if (sectionValue.shouldHighlight === true) {
				barValues += TIMELINE_GRAPH_BAR_HIGHLIGHTER_HEIGHT;
			}
		});

		// maximum bar elements value
		if (barValues > maximumBarElementsValue) {
			maximumBarElementsValue = barValues;
		}
	});

	// determine maximum value
	const maximumGraphValue = Math.max(maximumLineValue, maximumBarElementsValue);

	for (const stepData of stepDataArray) {
		let {startDate, endDate, isWeekend} = stepData;

		let overAllocatedRoles;
		if (roleOverAllocatedSection) {
			const noRoleFilteredGroups = roleGroupingGroups.filter(group => group.id !== NO_ROLE);
			overAllocatedRoles = getOverAllocatedRoles(pageComponent, noRoleFilteredGroups, timelineMinorStep, startDate);
		}

		const itemData = {
			startDate,
			endDate,
			y: group.screenY,
			x: stepData.position,
			isWeekend,
			width: stepData.width,
			height: group.height,
			sections: visibleSections,
			sectionValues: graphValues[startDate],
			maximumGraphValue,
			overAllocatedRoles,
			eyeOptions,
			getSchedulingOptions: () => pageComponent.state.schedulingOptions,
		};

		const graphItem = new TimelineGraphItem(pageComponent, itemData);

		graphItems.push(graphItem);

		index++;
	}

	return graphItems;
};

export const composeRoleCapacityItems = (pageComponent, group, stepDataArray, timelineMinorStep) => {
	const items = [];

	group.calculateHeatmapCache(group, stepDataArray, timelineMinorStep);

	for (const stepData of stepDataArray) {
		let {startDate, endDate} = stepData;
		const isDayOffItem = isDayOffStep(pageComponent, stepData);

		const heatmapData = getCachedHeatmapData(pageComponent, group, timelineMinorStep, startDate, endDate);
		const {peopleDemand, placeholderDemand} = heatmapData;

		const roleName = group.data?.name;

		const itemData = {
			startDate,
			endDate,
			y: group.screenY,
			x: stepData.position,
			width: stepData.width,
			minutesAllocated: peopleDemand + placeholderDemand,
			minutesAvailable: heatmapData.minutesAvailable,
			isDayOffItem,
			roleName,
			peopleDemand,
			placeholderDemand,
		};

		const tooltipData = ComposeManager.composeUtilizationHeatmapTooltip(pageComponent, group, itemData, stepData);

		itemData.onMouseEnter = event => EventManager.onUtilizationHeatmapItemMouseEnter(pageComponent, event, tooltipData);
		itemData.onMouseLeave = event => EventManager.onUtilizationHeatmapItemMouseLeave(pageComponent, event);

		items.push(new HeatmapItem(pageComponent, HEATMAP_TYPE.UTILIZATION, itemData));
	}

	return items;
};
