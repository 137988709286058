import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class TdLink extends Component {
	handleClick() {
		if (this.props.showTaskModal) {
			this.props.showTaskModal();
		}
	}

	render() {
		return (
			<td
				data-cy={this.props.dataCy}
				className={'td-link-component' + (this.props.className ? ' ' + this.props.className : '')}
				style={this.props.style ? this.props.style : null}
				title={this.props.title ? this.props.title : null}
			>
				{!this.props.locked ? (
					<Link
						onClick={this.handleClick.bind(this)}
						className={'react-router-link' + (this.props.alignRight ? ' align-right' : '')}
						to={this.props.locked ? null : this.props.path}
					>
						<div
							style={{
								position: 'relative',
								zIndex: '2',
								overflow: this.props.overflowHidden ? 'hidden' : 'visible',
								width: this.props.width ? this.props.width : '',
								maxWidth: this.props.width ? this.props.width : '',
								minWidth: this.props.minWidth ? this.props.minWidth : '',
							}}
							data-cy={this.props.cy}
						>
							{this.props.content}
						</div>
						{this.props.outsideSpanContent ? this.props.outsideSpanContent : null}
					</Link>
				) : (
					<div
						style={{
							position: 'relative',
							zIndex: '2',
							overflow: this.props.overflowHidden ? 'hidden' : 'visible',
							width: this.props.width ? this.props.width : '',
							maxWidth: this.props.width ? this.props.width : '',
						}}
						data-cy={this.props.cy}
					>
						{this.props.content}
					</div>
				)}
			</td>
		);
	}
}
TdLink.propTypes = {
	path: PropTypes.string,
	className: PropTypes.string,
	alignRight: PropTypes.bool,
	cy: PropTypes.string,
};
export default TdLink;
