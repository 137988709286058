import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../../constants';
import Button from '../../../forecast-app/shared/components/buttons/button/button';

class BulkEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			selectedListItem: 0,
		};
		this.listItems = [];
	}

	componentDidUpdate() {
		if (!this.props.selectionModeEnabled && this.state.expanded) {
			this.setState({expanded: false});
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (newTarget && newTarget.className.includes('bulk-edit-option')) return;
		else this.setState({expanded: false});
	}
	toggleExpand() {
		this.setState(prevState => {
			return {expanded: !prevState.expanded, selectedListItem: 0};
		});
		this.button.focus();
	}

	callOption(option) {
		if (option.callback && this.props.numTasksSelected > 0 && !option.disabled && !option.locked) {
			option.callback();
			this.setState({expanded: false});
		}
	}

	buttonHandleKeyPress(e) {
		if (e.keyCode === 40) {
			if (this.listItems && this.state.expanded) {
				this.listItems[0].focus();
			}
		}
	}

	toggleSelectionMode(e) {
		//we don't want to toggle the select mode when pressing the spacebar, because of the 'assign on space'
		if (!this.props.toggleSelectionMode || (e.key && e.key === ' ')) {
			return;
		}

		if ((e.key && e.key === 'Enter') || e.type === 'click') {
			this.props.toggleSelectionMode();
		}
	}

	handleKeyPress(e) {
		let tempIndex = this.state.selectedListItem;
		switch (e.keyCode) {
			//Up arrow
			case 38:
				if (e.altKey) {
					this.toggleExpand();
					break;
				}
				if (this.state.selectedListItem > 0) {
					this.setState(prevState => {
						if (prevState.selectedListItem > 0) {
							tempIndex = prevState.selectedListItem - 1;
							this.listItems[tempIndex].focus();
							return {selectedListItem: tempIndex};
						}
					});
				} else this.toggleExpand();

				break;
			//Down arrow
			case 40:
				if (e.altKey) {
					this.toggleExpand();
					break;
				}
				this.setState(prevState => {
					tempIndex =
						prevState.selectedListItem < this.listItems.length - 1
							? prevState.selectedListItem + 1
							: this.listItems.length - 1;
					this.listItems[tempIndex].focus();

					return {selectedListItem: tempIndex};
				});
				break;
			//Enter
			case 13:
				this.callOption(this.props.dropdownOptions[this.state.selectedListItem]);
				break;
			default:
				break;
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className={'bulk-edit-container '}>
				{this.props.selectionModeEnabled ? (
					<Button
						className={
							'bulk-edit-selection' + (this.props.toggleSelectionMode ? ' has-toggle-mode' : ' no-toggle-mode')
						}
						isDisabled={this.props.disabled}
						preventDefault={true}
						onKeyDown={e => this.buttonHandleKeyPress(e)}
						ref={button => (this.button = button)}
						onBlur={e => this.handleBlur(e)}
						onClick={this.toggleExpand.bind(this)}
						text={
							formatMessage({id: 'common.bulk_edit'}) +
							(this.props.numTasksSelected != null ? ' (' + this.props.numTasksSelected + ')' : '')
						}
						buttonStyle={BUTTON_STYLE.FILLED}
						colorTheme={this.props.numTasksSelected > 0 ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.FADEDGREY}
					/>
				) : null}
				{this.props.toggleSelectionMode ? (
					<Button
						isDisabled={this.props.disabled}
						preventDefault={true}
						onClick={e => this.toggleSelectionMode(e)}
						onKeyDown={e => this.toggleSelectionMode(e)}
						text={
							this.props.selectionModeEnabled
								? formatMessage({id: 'common.cancel'})
								: formatMessage({id: 'common.select'})
						}
						buttonStyle={this.props.selectionModeEnabled ? BUTTON_STYLE.OUTLINE : BUTTON_STYLE.OUTLINE_THICK}
						colorTheme={this.props.selectionModeEnabled ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.VERYLIGHTGREY}
						tooltipEnabled={!this.props.selectionModeEnabled}
						tooltipProps={{
							grey: true,
							autoPlace: true,
							infoText: formatMessage({id: 'common.bulk_select'}),
						}}
					/>
				) : null}
				{this.state.expanded ? (
					<ul
						className={
							'bulk-edit-options-list' + (this.props.toggleSelectionMode ? ' has-toggle-mode' : ' no-toggle-mode')
						}
					>
						{this.props.numTasksSelected === 0 ? (
							<li className={'options-list-empty'}>{formatMessage({id: 'bulk-edit.options-list-empty'})}</li>
						) : (
							this.props.dropdownOptions.map((option, index) => (
								<li
									onBlur={e => this.handleBlur(e)}
									onKeyDown={e => this.handleKeyPress(e)}
									ref={li => (this.listItems[index] = li)}
									tabIndex={'0'}
									title={
										option.disabled || option.locked
											? formatMessage({id: 'common.time_reg_option_disabled'})
											: option.label
									}
									key={index}
									className={'bulk-edit-option' + (option.disabled || option.locked ? ' disabled' : '')}
									onClick={() => this.callOption(option)}
								>
									{option.href ? (
										<a
											onMouseDown={e => {
												e.preventDefault();
											}}
											key={index}
											href={option.href}
										>
											<span className="bulk-edit-text">{option.label}</span>
										</a>
									) : (
										<p className={'bulk-edit-text'}>{option.label}</p>
									)}
								</li>
							))
						)}
					</ul>
				) : null}
			</div>
		);
	}
}
BulkEdit.propTypes = {
	dropdownOptions: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			onClick: PropTypes.func,
			disabled: PropTypes.bool,
		})
	).isRequired,
	numTasksSelected: PropTypes.number,
	selectionModeEnabled: PropTypes.bool.isRequired,
};
export default injectIntl(BulkEdit);
