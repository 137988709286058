import React from 'react';
import {useIntl} from 'react-intl';
import {Table} from 'web-components';
import {
	CUSTOM_FIELD_PREFIX,
	getCustomFieldColumnName,
} from '../../../forecast-app/project-tab/projects/scoping-page/ProjectScopingUtil';
import Util from '../../../forecast-app/shared/util/util';
import {TooltipFormulaRenderer} from '../../TooltipFormulaRenderer';
import {Tooltip} from '@forecast-it/design-system';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

export const getBaseLevelTableHeader = (columns, customFieldDefinitions) => {
	const {formatMessage} = useIntl();
	const customFields = Util.hasCustomFields()
		? customFieldDefinitions
				.map(cfd => {
					const key = Object.keys(columns).find(
						key => key === CUSTOM_FIELD_PREFIX + '.' + getCustomFieldColumnName('TASK', cfd.key)
					);
					return {
						displayName: cfd.displayName,
						visible: columns[key] || false,
					};
				})
				.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
		: [];

	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');

	return (
		<Table.Header>
			<Table.HeaderColumn usePadding flex={1} align="left" visible={true} width="LARGE">
				<Table.HeaderColumn.Title>&nbsp;</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.indicators} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.indicators'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="left" visible={columns.project} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.project'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.projectDeadline} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'insights.component.list.column.projectDeadline'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.projectStage} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'insights.component.list.column.projectStage'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.rateCard} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.rate-card'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.clientName} width="SMALL">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.client'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['taskDates.startDate']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.task'})} {formatMessage({id: 'common.start_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['taskDates.deadline']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.task'})} {formatMessage({id: 'common.end_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.roleName} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.role'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.assignees} width="LARGE">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.assignees'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.status} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.status'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.phase} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.phase'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['phaseDates.startDate']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.phase'})} {formatMessage({id: 'common.start_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['phaseDates.deadline']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.phase'})} {formatMessage({id: 'common.end_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.sprint} width="MEDIUM">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.sprint'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['sprintDates.startDate']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.sprint'})} {formatMessage({id: 'common.start_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns['sprintDates.deadline']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.sprint'})} {formatMessage({id: 'common.end_date'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns.progress} width="SMALL">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.progress'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="right" visible={columns['work.estimate.hours']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.estimate'})}
					<br />
					{formatMessage({id: 'common.hours'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="right" visible={columns['work.estimate.points']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.estimate'})}
					<br />
					{formatMessage({id: 'common.points'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="right" visible={columns['reported.projected']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: 'task.total_time_at_completion',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="right" visible={columns['reported.reportedTime']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: 'common.time_entries',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="right" visible={columns['reported.reportedBillableTime']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: 'utilization.billable_time',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="right" visible={columns['reported.reportedNonBillableTime']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: 'utilization.non_billable_time',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['work.remaining.hours']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.remaining'})}
					<br />
					{formatMessage({id: 'common.hours'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['work.remaining.points']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.remaining'})}
					<br />
					{formatMessage({id: 'common.points'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['work.difference.hours']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.difference'})}
					<br />
					{formatMessage({id: 'common.hours'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns['work.difference.points']} width="MEDIUM">
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'common.difference'})}
					<br />
					{formatMessage({id: 'common.points'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['financials.timeMaterial.plannedRevenue']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: 'project_budget.planned_billable_time',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['financials.timeMaterial.actualRevenue']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.actual_billable_value_of_service'
							: 'project_budget.actual_billable_time',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['financials.timeMaterial.remainingRevenue']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.remaining_work_billable_value_of_service'
							: 'project_budget.forecast_billable_time_and_expenses',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['financials.timeMaterial.forecastRevenue']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.projected_total_billable_value_of_service'
							: 'project_budget.total_billable_time',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['financials.revenueRecognition.recognitionLockedRevenue']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'project_budget.revenue_recognition_from_locked_months'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['financials.revenueRecognition.actualRevenue']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>{formatMessage({id: 'project_budget.actual_revenue'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['financials.revenueRecognition.recognitionOpenRevenue']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'project_budget.revenue_recognition_from_open_months'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['financials.revenueRecognition.remainingWorkRevenue']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>
					{formatMessage({id: 'project_budget.remaining_work_revenue'})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['financials.revenueRecognition.recognitionForecastRevenue']}
				width="MEDIUM"
			>
				<Table.HeaderColumn.Title>
					{formatMessage({
						id: hasFinancialCategoriesUpdate
							? 'common.projected_total_revenue'
							: 'project_budget.recognition_forecast_revenue',
					})}
				</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn
				align="right"
				usePadding
				visible={columns['financials.revenueRecognition.recognitionSurplus']}
				width="MEDIUM"
			>
				{hasFinancialCategoriesUpdate ? (
					<Tooltip
						width={'xl'}
						content={
							<TooltipFormulaRenderer
								items={[
									{
										title: formatMessage({
											id: 'project_budget.variance',
										}),
										details: [
											formatMessage({id: 'common.projected_total_revenue'}),
											'-',
											formatMessage({id: 'common.projected_total_billable_value_of_service'}),
										],
									},
								]}
							/>
						}
						interactable={false}
					>
						<Table.HeaderColumn.Title>{formatMessage({id: 'project_budget.variance'})}</Table.HeaderColumn.Title>
					</Tooltip>
				) : (
					<Table.HeaderColumn.Title>{formatMessage({id: 'project_budget.variance'})}</Table.HeaderColumn.Title>
				)}
			</Table.HeaderColumn>
			<Table.HeaderColumn align="center" usePadding visible={columns.approved} width="SMALL">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.approved'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.labels} width="EXTRA_LARGE">
				<Table.HeaderColumn.Title>{formatMessage({id: 'common.labels'})}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			{customFields.map(customField => (
				<Table.HeaderColumn align="center" usePadding visible={customField.visible} width="MEDIUM">
					<Table.HeaderColumn.Title>{customField.displayName}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			))}
		</Table.Header>
	);
};
