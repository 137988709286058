import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import TaskTable from '../task-table-v3/task_table';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

class RetainerPeriodTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			collapsed: this.props.timeRegs && this.props.timeRegs.length >= 20,
		};
	}
	toggleCollapsed() {
		this.setState(prevState => {
			return {collapsed: !prevState.collapsed};
		});

		tracking.trackElementClicked('Time registrations' + (this.state.collapsed ? ' expanded' : ' collapsed'));
		trackEvent('Time Registrations', this.state.collapsed ? 'Expanded' : 'Collapsed');
	}
	render() {
		return (
			<div className={'retainer-task-table-wrapper'}>
				<div className={'table-expand-bar'} onClick={this.toggleCollapsed.bind(this)}>
					<div className={'bar-title'}>{this.props.intl.formatMessage({id: 'retainer.see_time_regs'})}</div>
					<div className={'expand-icon' + (this.state.collapsed ? ' collapsed' : '')}></div>
				</div>
				{this.state.collapsed ? null : (
					<TaskTable
						disabled={true}
						// BEGIN - If we want to show financial data here some day, we should change this
						hasFinancialAccess={this.props.hasFinancialAccess}
						conflictedTimeEntries={this.props.conflictedTimeEntries}
						hasRateCard={false}
						isEstimatedInHours={false}
						// END
						collapsed={false}
						isEmpty={this.props.timeRegs.length === 0}
						rowHeight={40}
						listHeaderHeight={40}
						phase={this.props.period}
						cy={this.props.cy}
						isConnectedParent={false}
						minutesPerEstimationPoint={this.props.project.minutesPerEstimationPoint}
						taskCellMaxWidth={this.props.taskCellMaxWidth}
						currencySymbol={this.props.currencySymbol}
						emptyPhase={false}
						forceHidden={this.props.forceHidden}
						showTaskModal={this.props.showTaskModal}
						projectLocked={this.props.projectLocked}
						isJiraProject={false}
						isHarvestProject={this.props.isHarvestProject}
						projectPersons={this.props.projectPersons}
						roles={[]}
						containerWidth={this.props.containerWidth}
						phaseHeader={this.props.phaseHeader}
						id={this.props.period.node.id}
						tasks={[]}
						timeRegs={this.props.timeRegs}
						projectId={this.props.project.id}
						placeUnitBeforeValue={this.props.placeUnitBeforeValue}
						language={this.props.language}
						selectedRows={[]}
						phaseIndex={this.props.phaseIndex}
						viewer={this.props.viewer}
						availableColumns={this.props.availableColumns}
						onContextMenu={this.props.onContextMenu}
						parentType={'retainer'}
						showBillableMinutes={true}
					/>
				)}
			</div>
		);
	}
}
RetainerPeriodTable.propTypes = {};
export default injectIntl(RetainerPeriodTable);
