/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type burnUpComponent_viewer$ref = any;
export type burnUpComponent_QueryVariables = {|
  shareKey?: ?string,
  projectId?: ?string,
  isProjectGroupType?: ?boolean,
|};
export type burnUpComponent_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: burnUpComponent_viewer$ref,
  |}
|};
export type burnUpComponent_Query = {|
  variables: burnUpComponent_QueryVariables,
  response: burnUpComponent_QueryResponse,
|};
*/


/*
query burnUpComponent_Query(
  $shareKey: String
  $projectId: ID
  $isProjectGroupType: Boolean
) {
  viewer {
    actualPersonId
    component(name: "insight_burn_up")
    ...burnUpComponent_viewer_2fJgLr
    id
  }
}

fragment burnUpComponent_viewer_2fJgLr on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    burnUp(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
      useHours
      data {
        dateString
        scope
        velocity
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isProjectGroupType",
    "type": "Boolean"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "insight_burn_up"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"insight_burn_up\")"
},
v3 = {
  "kind": "Variable",
  "name": "isProjectGroupType",
  "variableName": "isProjectGroupType"
},
v4 = {
  "kind": "Variable",
  "name": "projectId",
  "variableName": "projectId"
},
v5 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "burnUpComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "burnUpComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "burnUpComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              (v5/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "BurnUp",
                "kind": "LinkedField",
                "name": "burnUp",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "useHours",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BurnUpElement",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dateString",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scope",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "velocity",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "burnUpComponent_Query",
    "operationKind": "query",
    "text": "query burnUpComponent_Query(\n  $shareKey: String\n  $projectId: ID\n  $isProjectGroupType: Boolean\n) {\n  viewer {\n    actualPersonId\n    component(name: \"insight_burn_up\")\n    ...burnUpComponent_viewer_2fJgLr\n    id\n  }\n}\n\nfragment burnUpComponent_viewer_2fJgLr on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    burnUp(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {\n      useHours\n      data {\n        dateString\n        scope\n        velocity\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1efee087646c6ff2a339a2e15950be76';

module.exports = node;
