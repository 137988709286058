/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type utilizationBarChartComponent_viewer$ref: FragmentReference;
declare export opaque type utilizationBarChartComponent_viewer$fragmentType: utilizationBarChartComponent_viewer$ref;
export type utilizationBarChartComponent_viewer = {|
  +insightComponentsData: ?{|
    +utilizationBarChart: ?{|
      +data: ?$ReadOnlyArray<?{|
        +id: string,
        +personName: ?string,
        +availableHours: ?number,
        +projectHours: ?number,
        +idleHours: ?number,
        +registeredHours: ?number,
        +plannedCardHours: ?number,
        +timeOffHours: ?number,
      |}>
    |}
  |},
  +$refType: utilizationBarChartComponent_viewer$ref,
|};
export type utilizationBarChartComponent_viewer$data = utilizationBarChartComponent_viewer;
export type utilizationBarChartComponent_viewer$key = {
  +$data?: utilizationBarChartComponent_viewer$data,
  +$fragmentRefs: utilizationBarChartComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateCriteria",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "componentId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "roleIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "labelIds",
      "type": "[ID]"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "utilizationBarChartComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "componentId",
              "variableName": "componentId"
            },
            {
              "kind": "Variable",
              "name": "dateCriteria",
              "variableName": "dateCriteria"
            },
            {
              "kind": "Variable",
              "name": "labelIds",
              "variableName": "labelIds"
            },
            {
              "kind": "Variable",
              "name": "personIds",
              "variableName": "personIds"
            },
            {
              "kind": "Variable",
              "name": "roleIds",
              "variableName": "roleIds"
            },
            {
              "kind": "Variable",
              "name": "teamIds",
              "variableName": "teamIds"
            }
          ],
          "concreteType": "UtilizationBarChart",
          "kind": "LinkedField",
          "name": "utilizationBarChart",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "UtilizationBarChartElement",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "personName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "availableHours",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectHours",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "idleHours",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "registeredHours",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "plannedCardHours",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timeOffHours",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '06b8561e2ffff79e20e89b6f7647f339';

module.exports = node;
