import {CAPACITY_OVERVIEW_EYE_STORAGE_KEY} from './CanvasCapacityOverviewConstants';
import {applyLocalStorageEyeOptions, GROUP_TYPE} from '../canvas-timeline/canvas_timeline_util';
import {getPersonIdFromPersonGroup, getPlaceholderIdFromGroup} from './CanvasCapacityOverviewDataUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {EYE_OPTION_NAME} from '../constants';
import {ROLE_GROUPING_SUB_GROUP_TYPE} from '../IDManager';

export const getPlaceholdersOverviewEyeOptions = weekendOptions => {
	const createDefaultEyeOptions = weekendOptions => {
		const eyeOptions = [];

		if (weekendOptions.weekendDisplayPerUser !== false) {
			eyeOptions.push({name: EYE_OPTION_NAME.SHOW_WEEKENDS, checked: false, translationId: 'scheduling.show_weekends'});
		}

		if (hasFeatureFlag('placeholders')) {
			eyeOptions.push({name: 'show-graph', checked: true, translationId: 'scheduling.graph'});
		}

		return eyeOptions;
	};

	const eyeOptions = createDefaultEyeOptions(weekendOptions);
	applyLocalStorageEyeOptions(eyeOptions, CAPACITY_OVERVIEW_EYE_STORAGE_KEY);

	return eyeOptions;
};

export const getPersonGroup = (pageComponent, person) => {
	const {groups} = pageComponent.state;

	const roleGroupingGroups = groups.filter(group => group.groupType === GROUP_TYPE.PERSON_GROUPING_GROUP);
	for (const roleGroupingGroup of roleGroupingGroups) {
		const personGroupingGroup = roleGroupingGroup.groups.find(
			group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING
		);

		for (const personGroup of personGroupingGroup.groups) {
			if (getPersonIdFromPersonGroup(personGroup) === person.id) {
				return personGroup;
			}
		}
	}

	return null;
};

export const getPlaceholderGroup = (pageComponent, placeholder) => {
	const {groups} = pageComponent.state;

	const roleGroupingGroups = groups.filter(group => group.groupType === GROUP_TYPE.PERSON_GROUPING_GROUP);
	for (const roleGroupingGroup of roleGroupingGroups) {
		const placeholderGroupingGroup = roleGroupingGroup.groups.find(
			group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND
		);

		for (const placeholderGroup of placeholderGroupingGroup.groups) {
			if (getPlaceholderIdFromGroup(placeholderGroup) === placeholder.id) {
				return placeholderGroup;
			}
		}
	}

	return null;
};
