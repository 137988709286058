import Item from '../../canvas-timeline/canvas_timeline_item';
import {ITEM_TYPE, drawRectangle} from '../../canvas-timeline/canvas_timeline_util';
import {cacheManager} from '../../canvas-timeline/canvas_timeline_cache_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {profilePicSrc} from '../../../../directApi';

class ProjectTeamItem extends Item {
	constructor(pageComponent, data) {
		super(pageComponent, ITEM_TYPE.PROJECT_SCHEDULING_TEAM_ITEM, data);

		data.onMouseMove = this.onMouseMove.bind(this);
		this.renderedImages = [];
	}

	onMouseMove(itemMoveData, e) {
		const {layerX: x, layerY: y} = e;
		const renderedImageHover = this.renderedImages.find(renderedImage => {
			const {x1, x2, y1, y2} = renderedImage;
			return x >= x1 && x <= x2 && y >= y1 && y <= y2;
		});
		if (renderedImageHover) {
			// Here we can trigger a tooltip for the person. I have run out of time for this time around, but most things should be available now to do that.
		}
	}

	draw(canvasContext, x, y, width, canvasWidth) {
		const {data, height} = this;
		const {color, teamProfilePictureSrcs, onPersonImageLoad} = data;
		const drawItem = images => {
			drawRectangle(canvasContext, x, y, width, height, {
				backgroundColor: color,
				borderRadius: 9,
			});
			if (images) {
				/*
				 * 1. check width passed as param of draw function
				 * 2. calculate how many 34px images can fit including a bit of margin on each side (remember to save one spot for image showing how many more members are there)
				 * 3. now u know the max index allowed
				 * 4. calculate x value for each image (xCoor)
				 * 5. draw image
				 */
				let visibleX = x < 0 ? 0 : x;
				let visibleWidth = width;
				if (x < 0) {
					visibleWidth += x;
				}
				if (visibleX + visibleWidth > canvasWidth) {
					visibleWidth = canvasWidth - visibleX;
				}
				//Modify values to create padding
				visibleX += 16;
				visibleWidth -= 32;
				//Due to padding creation above, visibleWidth can be negative, pretend it is 0 if that happens
				const maxImageCount = visibleWidth < 0 ? 0 : Math.floor(visibleWidth / 34);
				const visibleImageCount = maxImageCount < images.length ? maxImageCount : images.length;
				const firstImageX =
					visibleX + visibleWidth / 2 - visibleImageCount * 17 - (maxImageCount < images.length ? 17 : 0);
				this.renderedImages = [];
				for (let i = 0; i < visibleImageCount; i++) {
					const {data, personInfo} = images[i];
					const xCoor = firstImageX + i * 34;
					this.renderedImages.push({
						x1: xCoor,
						y1: y - 8,
						x2: xCoor + 34,
						y2: y - 8 + 34,
						personInfo,
					});
					if (typeof data === 'string') {
						const initialsXCoor = xCoor + 16;
						//img is initials in this case
						canvasContext.beginPath();
						const yOrigin = y - 8;
						canvasContext.moveTo(initialsXCoor, yOrigin);
						canvasContext.lineTo(initialsXCoor, yOrigin);
						canvasContext.lineTo(initialsXCoor + 15, yOrigin + 8);
						canvasContext.lineTo(initialsXCoor + 15, yOrigin + 25);
						canvasContext.lineTo(initialsXCoor, yOrigin + 34);
						canvasContext.lineTo(initialsXCoor, yOrigin + 34);
						canvasContext.lineTo(initialsXCoor - 15, yOrigin + 25);
						canvasContext.lineTo(initialsXCoor - 15, yOrigin + 8);
						canvasContext.fillStyle = '#6e0fea';
						canvasContext.fill();
						canvasContext.font = '500 12px ' + Util.getFontFamily();
						canvasContext.fillStyle = 'white';
						const initials = data;
						canvasContext.textAlign = 'center';
						canvasContext.fillText(initials.toUpperCase(), initialsXCoor, yOrigin + 21);
						canvasContext.textAlign = 'start';
					} else {
						canvasContext.drawImage(data, xCoor, y - 8, 34, 34);
					}
				}

				if (maxImageCount < images.length) {
					//there are more images than allowed
					//draw remaining number of team members
					const xCoor = firstImageX + 34 * visibleImageCount + 17;
					canvasContext.fillStyle = '#4A5968';
					canvasContext.beginPath();
					canvasContext.moveTo(xCoor, y - 8);
					canvasContext.lineTo(xCoor + 15, y);
					canvasContext.lineTo(xCoor + 15, y + 19);
					canvasContext.lineTo(xCoor, y + 27);
					canvasContext.lineTo(xCoor - 15, y + 19);
					canvasContext.lineTo(xCoor - 15, y);
					canvasContext.lineTo(xCoor, y - 8);
					canvasContext.fill();
					canvasContext.fillStyle = '#fff';
					canvasContext.font = '500 12px ' + Util.getFontFamily();
					canvasContext.fillText(`+${images.length - visibleImageCount}`, xCoor - 7, y + 13);
				}
			}
		};
		const imgs = [];
		if (teamProfilePictureSrcs === null || teamProfilePictureSrcs === undefined || teamProfilePictureSrcs.length === 0) {
			drawItem();
		} else {
			let loadedImages = 0;
			teamProfilePictureSrcs.forEach((obj, index) => {
				const cachedImage = cacheManager.get('personImage', obj.personId);
				if (cachedImage && obj.profilePictureId) {
					loadedImages++;
					imgs.push({
						data: cachedImage,
						personInfo: obj,
					});
					if (index === teamProfilePictureSrcs.length - 1) {
						drawItem(imgs);
					}
				} else {
					if (obj.profilePictureId) {
						const personImage = new Image();
						personImage.crossOrigin = 'use-credentials';
						personImage.onload = () => {
							cacheManager.set('personImage', obj.personId, personImage);
							loadedImages++;
							if (loadedImages === teamProfilePictureSrcs.length) {
								onPersonImageLoad();
							}
						};
						personImage.src = profilePicSrc(obj.profilePictureId);
						imgs.push({
							data: personImage,
							personInfo: obj,
						});
					} else {
						loadedImages++;
						//use person initials
						imgs.push({
							data: obj.initials,
							personInfo: obj,
						});
					}
					if (index === teamProfilePictureSrcs.length - 1 && loadedImages === teamProfilePictureSrcs.length) {
						drawItem(imgs);
					}
				}
			});
		}
	}
}
export default ProjectTeamItem;
