import React from 'react';
import {CurrencyInput} from 'web-components';
import Util from '../../forecast-app/shared/util/util';

export const CurrencyInputWrapper = ({
	intl,
	value,
	currency,
	currencySymbol,
	callback,
	showAsterisk,
	placeholder,
	innerRef,
	forceUpdate,
	width,
	taskModalStyle,
	hasError,
	disabled,
	modalView,
	label,
	autoFocus,
	className,
}) => {
	const currencySymbolParsed = currencySymbol || Util.GetCurrencySymbol(currency);
	const currencyPlaceholder = placeholder;

	const roundedValue = value != null ? Math.round(value * 100) / 100 : null;

	return (
		<CurrencyInput
			modalView={modalView}
			value={roundedValue}
			currencySymbole={currencySymbolParsed}
			callback={callback}
			showAsterisk={showAsterisk}
			placeholder={currencyPlaceholder}
			forceUpdate={forceUpdate}
			width={width}
			taskModalStyle={taskModalStyle}
			hasError={hasError}
			innerRef={innerRef}
			intl={intl}
			disabled={disabled}
			label={label}
			autoFocus={autoFocus}
			className={className}
		/>
	);
};
