import {PERMISSION_TYPE} from '../../../Permissions';
import {hasSomePermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import Util from '../../../forecast-app/shared/util/util';
import {
	CUSTOM_FIELD_PREFIX,
	getCustomFieldColumnName,
} from '../../../forecast-app/project-tab/projects/scoping-page/ProjectScopingUtil';
import {HIDDEN_FEATURES} from '../../../constants';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';

export const theEyeOptionsNoGrouping = customFieldDefinitions => {
	return [
		{
			name: 'project',
			checked: true,
			translationId: null,
			hide: true,
		},
		{
			name: 'task',
			checked: true,
			translationId: null,
			hide: true,
		},
		{
			name: 'phaseName',
			checked: false,
			translationId: 'common.phase',
			nestedOptions: null,
		},
		{
			name: 'reportedTime',
			checked: true,
			translationId: 'common.reported',
			nestedOptions: null,
			hide: true,
		},
		{
			name: 'notes',
			checked: false,
			translationId: 'common.notes',
			nestedOptions: null,
		},
		{
			name: 'date',
			checked: true,
			translationId: 'common.date',
			nestedOptions: null,
		},
		{
			name: 'personIcon',
			checked: true,
			translationId: 'common.person',
			hide: true,
		},
		{
			name: 'roleName',
			checked: true,
			translationId: 'common.role',
			nestedOptions: null,
		},
		...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
			? [
					{
						name: 'clientName',
						checked: true,
						translationId: 'common.client',
						nestedOptions: null,
					},
			  ]
			: []),
		...(CompanySetupUtil.isFeatureHidden(HIDDEN_FEATURES.REVENUE)
			? []
			: [
					{
						name: 'billable',
						checked: false,
						translationId: 'common.billable',
						nestedOptions: null,
					},
			  ]),
		...(CompanySetupUtil.isFeatureHidden(HIDDEN_FEATURES.REVENUE) || !CompanySetupUtil.hasFinance()
			? []
			: [
					{
						name: 'price',
						checked: false,
						translationId: 'project_budget.actual_billable_time',
						nestedOptions: null,
					},
			  ]),
		...(CompanySetupUtil.isFeatureHidden(HIDDEN_FEATURES.REVENUE) || !CompanySetupUtil.hasFinance()
			? []
			: [
					{
						name: 'invoiced',
						checked: false,
						translationId: 'project_budget.invoiced',
						nestedOptions: null,
					},
			  ]),
		{
			name: 'approvalStatus',
			checked: false,
			translationId: 'common.approved',
			nestedOptions: null,
		},
		...(Util.hasCustomFields() && customFieldDefinitions && customFieldDefinitions.length > 0
			? [
					{
						name: CUSTOM_FIELD_PREFIX,
						checked: true,
						translationId: 'settings.custom_fields.title',
						nestedOptions: [
							...customFieldDefinitions.map(customFieldDefinition => ({
								name: getCustomFieldColumnName('TIME_REG', customFieldDefinition.key),
								displayName: customFieldDefinition.displayName,
								checked: false,
								nestedOptions: null,
							})),
						],
					},
			  ]
			: []),
	];
};

export const filterObjectEyeOptions = initialEyeOptions => {
	const financialFields = ['price', 'cost'];
	const filteredEyeOptions = {...initialEyeOptions};
	const hasFinancialAccess = hasSomePermission([
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
	]);
	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();
	if (!hasFinancialAccess) {
		financialFields.forEach(field => {
			delete filteredEyeOptions[field];
		});
	}
	if (hasRevenueWithoutCostAccess) {
		delete filteredEyeOptions['cost'];
	}
	if (Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)) {
		delete filteredEyeOptions['clientName'];
	}

	return filteredEyeOptions;
};
export const theEyeOptionsFiltered = initialEyeOptions => {
	const financialFields = ['price', 'cost'];
	const hasFinancialAccess = hasSomePermission([
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
		PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
	]);
	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();
	const financialFieldFilter = item => {
		if (!hasFinancialAccess) {
			return !financialFields.includes(item.name);
		}
		if (hasRevenueWithoutCostAccess) {
			return item.name !== 'cost';
		}

		return true;
	};
	const clientFilter = item => {
		if (Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)) {
			return item.name !== 'clientName';
		}
	};

	const currentEyeOptions = [...initialEyeOptions];
	return currentEyeOptions.filter(financialFieldFilter).filter(clientFilter);
};
