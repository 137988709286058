import React from 'react';
import {PersonLineStyle, RoleNameCell} from './timesheets_team_page_styled';
import TimesheetsDayCell from './TimesheetsDayCell';
import TimesheetsTotalsCell from './TimesheetsTotalsCell';
import {getTotalWeekTime} from './TimesheetsUtil';

const RoleLine = ({
	id,
	viewer,
	timeRegistrations,
	timeRegistrations_cpy,
	timeRegs,
	timeRegistration,
	parentId,
	lastOne,
	role,
	firstDayOfWeek,
	lockedDate,
}) => {
	const timeRegClassName = timeRegistrations ? ' time-reg-cell' : '';
	const {totalWeekTime, totalWeekBillableTime} = getTotalWeekTime(timeRegistrations, firstDayOfWeek);

	return (
		<PersonLineStyle
			data-userpilot={id === 0 ? 'timesheets-team-first-person' : ''}
			id={timeRegistration ? `person-${parentId}-time-reg-row-${id}` : `person-row-${id}`}
			className={
				timeRegistrations_cpy.length > 0
					? ' expandable'
					: '' +
					  (timeRegistrations ? ' time-reg-row' : '') +
					  (id === 0 ? ' first-time-reg-row' : lastOne ? ' last-time-reg-row' : '')
			}
		>
			<RoleNameCell>
				<div className={'role'}>{role}</div>
			</RoleNameCell>
			{Array(7)
				.fill(0)
				.map((n, i) => (
					<TimesheetsDayCell
						viewer={viewer}
						firstDayOfWeek={firstDayOfWeek}
						timeRegs={timeRegs}
						timeRegistrations={timeRegistrations}
						timeRegClassName={timeRegClassName}
						lockedDate={lockedDate}
						index={i}
					/>
				))}
			<TimesheetsTotalsCell
				totalWeekTime={totalWeekTime}
				totalWeekBillableTime={totalWeekBillableTime}
				lockedDate={lockedDate}
				firstDayOfWeek={firstDayOfWeek}
				timeRegClassName={timeRegClassName}
			/>
		</PersonLineStyle>
	);
};

export default RoleLine;
