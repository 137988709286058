import React from 'react';
import styled from 'styled-components';

import {ROW_TYPE, ValueCell} from './ValueCell';
import {CSS_CONSTANTS} from '../../../../css_variables';

const RoleBreakdownColumnStyles = styled.td`
	padding-right: 16px;
	padding-top: 5px;
	padding-bottom: 5px;
	overflow: hidden;

	&.highlighted {
		background-color: ${CSS_CONSTANTS.v2_list_background_purple};
	}

	&.drawBorder {
		border-left: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		padding-left: 16px;
	}

	&:last-child {
		border-right: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	}

	.value {
		font-size: 11px;
		font-weight: normal;
		font-stretch: normal;
		font-style: italic;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: ${CSS_CONSTANTS.v2_text_gray};

		&.time,
		&.percentage,
		&.price,
		&.progress {
			text-align: right;
		}
	}
`;

export const RoleBreakdownColumn = ({currencySymbol, subColumn, highlighted, drawBorder, project, phaseId, roleId}) => {
	const type = subColumn.type;
	let value;
	const projectBreakdownValues = subColumn.breakdownValues[project.id];
	if (projectBreakdownValues) {
		const phaseBreakdownValues = projectBreakdownValues[phaseId];
		if (phaseBreakdownValues) {
			value = phaseBreakdownValues.roleBreakdown[roleId];
		}
	}

	return (
		<RoleBreakdownColumnStyles className={`${highlighted ? 'highlighted' : ''}${drawBorder ? ' drawBorder' : ''}`}>
			<div className={`value ${type}`}>
				<ValueCell currencySymbol={currencySymbol} type={type} value={value} rowType={ROW_TYPE.ROLE} />
			</div>
		</RoleBreakdownColumnStyles>
	);
};
