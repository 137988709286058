import React from 'react';
import {Title} from './ProjectPortfolioPeriodTitle.styled';
import {useIntl} from 'react-intl';
import {PROJECT_PORTFOLIO_DATE_RANGE, getDateRangeLabel} from '../ProjectPortfolioDateRangeOptions';

export const ProjectPortfolioPeriodTitle = ({dateRangeLabel, dateRange}) => {
	const intl = useIntl();
	let tableTitle = '';
	let dateRangeString = '';

	switch (dateRangeLabel) {
		case PROJECT_PORTFOLIO_DATE_RANGE.ALL_TIME:
			tableTitle = intl.formatMessage({id: 'common.totals'});
			break;
		case PROJECT_PORTFOLIO_DATE_RANGE.YEAR_TO_DATE:
			tableTitle = intl.formatMessage({id: 'reports.period_totals'});
			dateRangeString = `(${dateRange.startDate.format('DD MMMM YYYY')} - Today)`;
			break;
		default:
			tableTitle = intl.formatMessage({id: 'reports.period_totals'});
			dateRangeString = `(${dateRange.startDate.format('DD MMMM YYYY')} - ${dateRange.endDate.format('DD MMMM YYYY')})`;
			break;
	}

	// Get label translation
	dateRangeLabel = getDateRangeLabel(dateRangeLabel, intl);

	return (
		<Title>
			{tableTitle} — {dateRangeLabel} {dateRangeString}
		</Title>
	);
};
