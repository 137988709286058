import {createToast} from '../../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {
	areItemDatesValid,
	createCanvasTimelineDate,
	getMomentFromCanvasTimelineDate,
	GROUP_TYPE,
	ITEM_TYPE,
} from '../../canvas-timeline/canvas_timeline_util';
import TaskItem from '../../components/items/task_item';
import PhaseItem from '../../components/items/phase_item';
import ProjectItem from '../../components/items/project_item';
import {sortPhaseGroupsByDate} from '../projects_scheduling_util';
import {recalculateGroupHeatmapCache} from '../../heatmap/HeatmapLogic';
import {createPersonGroup} from '../projects_scheduling_data';
import ComposeManager from '../../ComposeManager';
import {PROJECT_SUB_GROUP_TYPE} from '../../constants';

export const prepareAutoScheduleData = (pageComponent, autoScheduleProposalData, autoScheduleProjectTeam, groups, items) => {
	const {formatMessage} = pageComponent.props.intl;

	let autoSchedulingErrors = [];
	let footerPersons = [];
	const footerWarnings = [];
	const simulationChangeMap = new Map([
		['persons', new Map()],
		['tasks', new Map()],
		['phases', new Map()],
		['allocations', new Map()],
		['projects', new Map()],
	]);

	if (autoScheduleProposalData.errors && autoScheduleProposalData.errors.length > 0) {
		if (process.env.CIRCLE_BRANCH !== 'production') {
			// If not on prod and there are auto sched errors, print them and notify user
			// eslint-disable-next-line no-console
			console.error('Auto Scheduling Errors', autoScheduleProposalData.errors);
			createToast({
				duration: 5000,
				message: 'DEBUG NOTICE: Auto scheduling failed for some elements. Check console log for errors.',
			});
		}
		autoSchedulingErrors = autoScheduleProposalData.errors;
		// Remove task items for errors
		const taskIdsWithError = autoScheduleProposalData.errors.reduce((taskIds, error) => {
			taskIds.push(...error.affectedTaskIds);
			return taskIds;
		}, []);
		taskIdsWithError.forEach(taskId => {
			const item = items.find(item => item.itemType === ITEM_TYPE.TASK && item.data.task.id === taskId);
			if (item) {
				items.splice(items.indexOf(item), 1);
			}
		});
	}

	for (const task of autoScheduleProposalData.taskData) {
		const item = items.find(item => item.itemType === ITEM_TYPE.TASK && item.data.task.id === task.id);
		// task is excluded if it is not scheduled or the phase it belongs to was not scheduled
		const isTaskExcluded = autoScheduleProposalData.excludedTaskIds.includes(task.id);
		if (!item) {
			const startDate = createCanvasTimelineDate(task.startYear, task.startMonth, task.startDay);
			const endDate = createCanvasTimelineDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay);
			if (areItemDatesValid(startDate, endDate)) {
				const assignedPerson = pageComponent.getData().persons.find(person => person.id === task.assignedPersons[0]);
				simulationChangeMap.get('persons').set(task.id, assignedPerson ? [assignedPerson.id] : []);
				simulationChangeMap.get('tasks').set(task.id, {
					initialStartDate: startDate,
					initialEndDate: endDate,
					startDate,
					endDate,
				});
				task.autoSchedulingPerson = assignedPerson;
				task.startFrom = 'TASK';
				task.deadlineFrom = 'TASK';

				const taskItemData = ComposeManager.composeTaskItem(pageComponent, task);
				if (taskItemData) {
					items.push(new TaskItem(pageComponent, taskItemData));
				}
			}
			continue;
		}

		const startDate = createCanvasTimelineDate(task.startYear, task.startMonth, task.startDay);
		const endDate = createCanvasTimelineDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay);
		if (areItemDatesValid(startDate, endDate)) {
			item.startDate = startDate;
			item.endDate = endDate;
			const assignedPerson = pageComponent.getData().persons.find(person => person.id === task.assignedPersons[0]);
			simulationChangeMap.get('persons').set(item.data.task.id, assignedPerson ? [assignedPerson.id] : []);
			simulationChangeMap.get('tasks').set(item.data.task.id, {
				initialStartDate: item.data.startDate,
				initialEndDate: item.data.endDate,
				startDate,
				endDate,
			});
			item.data.task.autoSchedulingPerson = assignedPerson;
			item.allowDependencyCreation = false;
			item.horizontalExtension = assignedPerson ? 26 : 0;

			const momentStartDate = getMomentFromCanvasTimelineDate(startDate);
			const momentEndDate = getMomentFromCanvasTimelineDate(endDate);

			item.data.task.startYear = momentStartDate.year();
			item.data.task.startMonth = momentStartDate.month() + 1;
			item.data.task.startDay = momentStartDate.date();
			item.data.task.deadlineYear = momentEndDate.year();
			item.data.task.deadlineMonth = momentEndDate.month() + 1;
			item.data.task.deadlineDay = momentEndDate.date();
			item.data.task.startFrom = 'TASK';
			item.data.task.deadlineFrom = 'TASK';
			// fade task if it has not been scheduled but we are still showing it in the preview
			item.data.faded = isTaskExcluded;
		} else {
			items.splice(items.indexOf(item), 1);
		}
	}

	// fade tasks of phases that are excluded
	const excludedPhasesTaskItems = items.filter(
		item =>
			item.itemType === ITEM_TYPE.TASK &&
			autoScheduleProposalData.excludedPhasesIds.some(mId => {
				if (+atob(mId).replace('PhaseType:', '') === -1) {
					//if task is in a no phase
					return !item.data.task.phaseId;
				} else {
					return mId === item.data.task.phaseId;
				}
			})
	);

	for (let item of excludedPhasesTaskItems) {
		item.data.faded = true;
	}

	let earliestStartDate = null;
	let latestEndDate = null;
	const phaseMap = new Map();
	for (const item of items) {
		if (item.itemType !== ITEM_TYPE.TASK) {
			continue;
		}
		if (item.data.task.projectId !== autoScheduleProposalData.project.id) {
			continue;
		}
		const phaseId = item.data.task.phaseId || null;

		const currentDateValues = phaseMap.get(phaseId) || {
			startDate: null,
			endDate: null,
		};

		if (!currentDateValues.startDate || item.startDate < currentDateValues.startDate) {
			currentDateValues.startDate = item.startDate;
		}
		if (!currentDateValues.endDate || item.endDate > currentDateValues.endDate) {
			currentDateValues.endDate = item.endDate;
		}
		if (!earliestStartDate || item.startDate < earliestStartDate) {
			earliestStartDate = item.startDate;
		}
		if (!latestEndDate || item.endDate > latestEndDate) {
			latestEndDate = item.endDate;
		}
		phaseMap.set(phaseId, currentDateValues);
	}

	autoScheduleProposalData.earliestStartDate = earliestStartDate;
	autoScheduleProposalData.latestEndDate = latestEndDate;

	for (const phaseMapEntry of phaseMap.entries()) {
		let item = items.find(item => item.groupId === phaseMapEntry[0]);
		const dateValues = phaseMapEntry[1];

		if (!areItemDatesValid(dateValues.startDate, dateValues.endDate)) {
			continue;
		}

		const phase = pageComponent.getData().phases.find(phase => phase.id === phaseMapEntry[0]);
		const isPhaseExcluded = phase ? autoScheduleProposalData.excludedPhasesIds.includes(phase.id) : false;
		if (!item) {
			if (!phase) {
				continue;
			}
			item = new PhaseItem(pageComponent, ComposeManager.composePhaseItem(pageComponent, phase));
			items.push(item);
			simulationChangeMap.get('phases').set(phaseMapEntry[0], {
				initialHidden: true,
				initialStartDate: null,
				initialEndDate: null,
				startDate: dateValues.startDate,
				endDate: dateValues.endDate,
			});
		} else {
			simulationChangeMap.get('phases').set(phaseMapEntry[0], {
				initialStartDate: item.startDate,
				initialEndDate: item.endDate,
				startDate: dateValues.startDate,
				endDate: dateValues.endDate,
			});
		}
		item.startDate = dateValues.startDate;
		item.endDate = dateValues.endDate;
		// fade phase if it has not been scheduled but we are still showing it in the preview
		item.data.faded = isPhaseExcluded;

		const momentStartDate = getMomentFromCanvasTimelineDate(dateValues.startDate);
		const momentEndDate = getMomentFromCanvasTimelineDate(dateValues.endDate);

		phase.startYear = momentStartDate.year();
		phase.startMonth = momentStartDate.month() + 1;
		phase.startDay = momentStartDate.date();
		phase.deadlineYear = momentEndDate.year();
		phase.deadlineMonth = momentEndDate.month() + 1;
		phase.deadlineDay = momentEndDate.date();
	}
	const projectItem = items.find(item => item.groupId === autoScheduleProposalData.project.id);
	if (projectItem) {
		simulationChangeMap.get('projects').set(projectItem.data.project.id, {
			initialStartDate: projectItem.startDate,
			initialEndDate: projectItem.endDate,
			startDate: earliestStartDate,
			endDate: latestEndDate,
		});
		if (areItemDatesValid(earliestStartDate, latestEndDate)) {
			projectItem.startDate = earliestStartDate;
			projectItem.endDate = latestEndDate;
			const momentStartDate = getMomentFromCanvasTimelineDate(earliestStartDate);
			const momentEndDate = getMomentFromCanvasTimelineDate(latestEndDate);

			projectItem.data.project.projectStartYear = momentStartDate.year();
			projectItem.data.project.projectStartMonth = momentStartDate.month() + 1;
			projectItem.data.project.projectStartDay = momentStartDate.date();
			projectItem.data.project.projectEndYear = momentEndDate.year();
			projectItem.data.project.projectEndMonth = momentEndDate.month() + 1;
			projectItem.data.project.projectEndDay = momentEndDate.date();

			const teamItem = items.find(item => item.groupId === `${autoScheduleProposalData.project.id}team-members`);
			if (teamItem) {
				teamItem.startDate = earliestStartDate;
				teamItem.endDate = latestEndDate;
			}
		}
	} else {
		simulationChangeMap.get('projects').set(autoScheduleProposalData.project.id, {
			initialHidden: true,
			initialStartDate: null,
			initialEndDate: null,
			startDate: earliestStartDate,
			endDate: latestEndDate,
		});

		if (areItemDatesValid(earliestStartDate, latestEndDate)) {
			const projectItemData = ComposeManager.composeProjectItem(
				pageComponent,
				autoScheduleProposalData.project,
				null,
				null
			);
			if (projectItemData) {
				items.push(new ProjectItem(pageComponent, projectItemData));
			}
		}
	}

	// if the project we are autoscheduling is in a group use the group id, otherwise use its id to find the group
	if (autoScheduleProposalData.project.isInProjectGroup) {
		sortPhaseGroupsByDate(
			groups.find(group => {
				return group.id === autoScheduleProposalData.project.projectGroupId;
			}).groups[0].groups[0].groups
		);
	} else {
		sortPhaseGroupsByDate(
			groups.find(group => {
				return group.id === autoScheduleProposalData.project.id;
			}).groups[0].groups
		);
	}

	const personIds = autoScheduleProjectTeam.map(projectPerson => projectPerson.personId);
	footerPersons = pageComponent
		.getData()
		.persons.filter(person => person.active && !person.clientId && personIds.includes(person.id))
		.map(person => {
			const projectPerson = autoScheduleProjectTeam.find(pp => pp.personId === person.id);
			if (projectPerson && projectPerson.roleId) {
				person.roleId = projectPerson.roleId;
			}
			return person;
		});
	const projectGroup = groups.find(group => group.id === autoScheduleProposalData.project.id);
	const teamMembersGroup = projectGroup.groups.find(
		group =>
			group.groupType === GROUP_TYPE.PROJECT_SCHEDULING_PROJECT_SUB_GROUP &&
			group.id &&
			group.id.includes(PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM)
	);
	teamMembersGroup.originalSubGroups = [...teamMembersGroup.groups];
	teamMembersGroup.groups = [];

	footerPersons.forEach(person => {
		teamMembersGroup.addChildGroup(createPersonGroup(pageComponent, person, autoScheduleProposalData.project));

		// Update heatmap values in cache
		recalculateGroupHeatmapCache(pageComponent, person.id);
	});

	if (autoScheduleProposalData.errors && autoScheduleProposalData.errors.length > 0) {
		const noTimeInPhasesError = autoScheduleProposalData.errors.filter(e => e.errorType === 'NO_TIME_IN_THE_PHASE');
		if (noTimeInPhasesError.length > 0) {
			const failedTaskRoleIds = new Set(
				pageComponent
					.getData()
					.tasks.filter(t =>
						noTimeInPhasesError.some(e => e.affectedTaskIds.includes(+atob(t.id).replace('Task:', '')))
					)
					.map(t => t.roleId)
			);
			const roleNames = pageComponent
				.getData()
				.roles.filter(r => failedTaskRoleIds.has(r.id))
				.reduce((acc, curr) => `${curr.name},${acc}`, '')
				.slice(0, -1);
			footerWarnings.push(
				formatMessage(
					{id: 'auto_scheduling.insufficient_people_of_role'},
					{
						roles: roleNames,
						roleCount: failedTaskRoleIds.size,
						taskCount: noTimeInPhasesError.length,
					}
				)
			);
		}
	}

	// collapse all phases that are not selected, contain only done tasks or are empty
	const collapsePhaseIds = autoScheduleProposalData.doneOrEmptyPhasesIds.concat(autoScheduleProposalData.excludedPhasesIds);
	for (const phaseMapEntry of phaseMap.entries()) {
		const phaseId = phaseMapEntry[0];

		pageComponent.props.expansionMap.set(phaseId, collapsePhaseIds.indexOf(phaseId) < 0);
	}

	return {
		autoSchedulingErrors,
		footerPersons,
		footerWarnings,
		simulationChangeMap,
	};
};
