import React from 'react';
import {useIntl} from 'react-intl';
import {Table} from 'web-components';
import ClientLoader, {clientLoaderQuery} from './ClientLoader';
import {filtersToSearchQuery} from '../ProjectPortfolioReportUtils';
import ProjectPortfolioPeriodClientDownload, {
	projectPortfolioPeriodClientDownloadQuery,
} from './ProjectPortfolioPeriodClientDownload';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {LoadMore} from '../../../loaders/LoadMore';
import ProjectPortfolioPeriodRow from './ProjectPortfolioPeriodRow';
import ProjectPortfolioPeriodProjectRows from './ProjectPortfolioPeriodProjectRows';

const ProjectPortfolioPeriodClientRows = ({
	setCsvDownloadFunction,
	hasBaseline,
	startDate,
	endDate,
	filters,
	boolEyeOptions,
	pageSize,
}) => {
	const intl = useIntl();

	return (
		<LoadMore
			key="query-render-client"
			query={clientLoaderQuery}
			variables={{
				searchQuery: filtersToSearchQuery(filters),
				startYear: startDate ? startDate.year() : undefined,
				startMonth: startDate ? startDate.month() + 1 : undefined,
				startDay: startDate ? startDate.date() : undefined,
				endYear: endDate ? endDate.year() : undefined,
				endMonth: endDate ? endDate.month() + 1 : undefined,
				endDay: endDate ? endDate.date() : undefined,
			}}
			loader={<ClientLoader />}
		>
			{({data, loadMore}) => {
				setCsvDownloadFunction(() => {
					showModal({
						type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
						componentName: 'project_portfolio_period_client_csv_loader',
						query: projectPortfolioPeriodClientDownloadQuery,
						variables: {
							searchQuery: filtersToSearchQuery(filters),
							startYear: startDate ? startDate.year() : undefined,
							startMonth: startDate ? startDate.month() + 1 : undefined,
							startDay: startDate ? startDate.date() : undefined,
							endYear: endDate ? endDate.year() : undefined,
							endMonth: endDate ? endDate.month() + 1 : undefined,
							endDay: endDate ? endDate.date() : undefined,
						},
						createCsvDownload: <ProjectPortfolioPeriodClientDownload />,
						startDate,
						endDate,
						boolEyeOptions,
					});
				});
				return (
					<Table.Rows data={data} canExpand={true} loadMore={loadMore} autoload={true}>
						{({rowData, tableColumnsProps, expanded, nextLevelProps}) => {
							return (
								<>
									<ProjectPortfolioPeriodRow
										financialNumbers={rowData.financialNumbers}
										rowData={rowData}
										tableColumnProps={tableColumnsProps}
										headerColumn={
											rowData.client.name
												? rowData.client.name
												: intl.formatMessage({id: 'common.no_client'})
										}
										hasBaseline={hasBaseline}
									/>
									{expanded && (
										<ProjectPortfolioPeriodProjectRows
											setCsvDownloadFunction={setCsvDownloadFunction}
											hasBaseline={hasBaseline}
											startDate={startDate}
											endDate={endDate}
											filters={filters}
											boolEyeOptions={boolEyeOptions}
											levelProps={nextLevelProps}
											pageSize={pageSize}
											clientId={rowData.client.id}
										></ProjectPortfolioPeriodProjectRows>
									)}
								</>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default ProjectPortfolioPeriodClientRows;
