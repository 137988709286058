/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Unit4LogPage_viewer$ref: FragmentReference;
declare export opaque type Unit4LogPage_viewer$fragmentType: Unit4LogPage_viewer$ref;
export type Unit4LogPage_viewer = {|
  +unit4LogItems: ?$ReadOnlyArray<?{|
    +id: ?string,
    +type: ?string,
    +unit4Identifier: ?string,
    +personId: ?number,
    +person: ?string,
    +projectId: ?number,
    +project: ?string,
    +workorder: ?string,
    +taskId: ?number,
    +task: ?string,
    +date: ?string,
    +minutesRegistered: ?string,
    +notes: ?string,
    +errorMessage: ?string,
  |}>,
  +$refType: Unit4LogPage_viewer$ref,
|};
export type Unit4LogPage_viewer$data = Unit4LogPage_viewer;
export type Unit4LogPage_viewer$key = {
  +$data?: Unit4LogPage_viewer$data,
  +$fragmentRefs: Unit4LogPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Unit4LogPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Unit4LogItemType",
      "kind": "LinkedField",
      "name": "unit4LogItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit4Identifier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "personId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "person",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "project",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workorder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taskId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "task",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minutesRegistered",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "errorMessage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '7fa382c57d3146fc2b70f227af929996';

module.exports = node;
