import {FlexItem} from '@forecast-it/design-system';
import React from 'react';
import HeaderColumnContainer from './HeaderColumnContainer';
import {useIntl} from 'react-intl';
import {DeprecatedProjectIndicatorNoFragment} from '../../../../forecast-app/shared/components/project-indicator/DeprecatedProjectIndicator';

const ProjectHeaderColumn = ({project}) => {
	const {formatMessage} = useIntl();
	const fullAccessToProject = project?.fullAccessToProject;
	const readOnlyAccess = project?.readOnlyAccess;
	return (
		<HeaderColumnContainer>
			<FlexItem flex="0 0 auto">
				<DeprecatedProjectIndicatorNoFragment project={project} disableLink={readOnlyAccess} />
			</FlexItem>
			<FlexItem>
				{fullAccessToProject
					? project.name
					: formatMessage({
							id: 'common.no_project_access',
					  })}
			</FlexItem>
		</HeaderColumnContainer>
	);
};

export default ProjectHeaderColumn;
