import React from 'react';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import {HIDDEN_FEATURES, PROJECT_TASK_REPORT_GROUPINGS, REPORT_GROUPINGS} from '../../../constants';
import ProjectRelayWrapper, {ProjectRelayWrapperQuery} from './relay-wrappers/ProjectRelayWrapper';
import PhaseRelayWrapper, {PhaseRelayWrapperQuery} from './relay-wrappers/PhaseRelayWrapper';
import ClientRelayWrapper, {ClientRelayWrapperQuery} from './relay-wrappers/ClientRelayWrapper';
import TaskReportRelayWrapper, {TaskRelayWrapperQuery} from './relay-wrappers/TaskRelayWrapper';
import RoleRelayWrapper, {RoleRelayWrapperQuery} from './relay-wrappers/RoleRelayWrapper';
import {parseTaskFilters} from '../../../forecast-app/shared/components/filters/filter_logic';
import SprintRelayWrapper, {SprintRelayWrapperQuery} from './relay-wrappers/SprintRelayWrapper';
import PersonRelayWrapper, {PersonRelayWrapperQuery} from './relay-wrappers/PersonRelayWrapper';
import Util from '../../../forecast-app/shared/util/util';
import {idFromGlobalId} from '../../../forecast-app/shared/util/GlobalIdUtil';

export const getSecondDropdownOptionsByGroupings = (firstDropdownValue, intl, isProjectLevel = false) => {
	const secondDropdownOptions = [];
	switch (firstDropdownValue) {
		case PROJECT_TASK_REPORT_GROUPINGS.PHASE:
		case REPORT_GROUPINGS.PHASE:
			if (isProjectLevel) {
				secondDropdownOptions.push(
					{
						value: PROJECT_TASK_REPORT_GROUPINGS.ROLE,
						label: intl.formatMessage({id: 'common.group_by_role'}),
					},
					{value: null, label: 'No grouping'}
				);
			} else {
				secondDropdownOptions.push(
					{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
					...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
						? [{value: REPORT_GROUPINGS.CLIENT, label: intl.formatMessage({id: 'common.group_by_client'})}]
						: []),
					{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
					{value: null, label: 'No grouping'}
				);
			}
			break;
		case REPORT_GROUPINGS.PROJECT:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PHASE, label: intl.formatMessage({id: 'common.group_by_phase'})},
				{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
				{value: REPORT_GROUPINGS.SPRINT, label: intl.formatMessage({id: 'common.group_by_sprint'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case REPORT_GROUPINGS.CLIENT:
			secondDropdownOptions.push(
				{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
				{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
				{value: REPORT_GROUPINGS.PHASE, label: intl.formatMessage({id: 'common.group_by_phase'})},
				{value: REPORT_GROUPINGS.SPRINT, label: intl.formatMessage({id: 'common.group_by_sprint'})},
				{value: null, label: 'No grouping'}
			);
			break;
		case PROJECT_TASK_REPORT_GROUPINGS.ROLE:
		case REPORT_GROUPINGS.ROLE:
			if (isProjectLevel) {
				secondDropdownOptions.push(
					{
						value: PROJECT_TASK_REPORT_GROUPINGS.PHASE,
						label: intl.formatMessage({id: 'common.group_by_phase'}),
					},
					{value: null, label: 'No grouping'}
				);
			} else {
				secondDropdownOptions.push(
					{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
					...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
						? [{value: REPORT_GROUPINGS.CLIENT, label: intl.formatMessage({id: 'common.group_by_client'})}]
						: []),
					{value: REPORT_GROUPINGS.PHASE, label: intl.formatMessage({id: 'common.group_by_phase'})},
					{value: REPORT_GROUPINGS.SPRINT, label: intl.formatMessage({id: 'common.group_by_sprint'})},
					{value: null, label: 'No grouping'}
				);
			}
			break;
		case PROJECT_TASK_REPORT_GROUPINGS.PERSON:
		case null:
			return null;
		default:
			return 'No second dropdown options provided!';
	}
	return secondDropdownOptions;
};

/**
 * This will convert the selected task filters into Global Task filters
 * used in the search query filters.
 *
 * @param defaultSearchQueryFilters The default search query filters.
 * @param filters The task filters
 * @returns {*[]} An array of Global Task filters
 */
export const getSearchQueryFiltersValue = (defaultSearchQueryFilters, filters) => {
	const parsedFilters = parseTaskFilters(filters, false);
	return [...defaultSearchQueryFilters, ...parsedFilters];
};

export const getRelayWrapper = (
	tableHeader,
	groupings,
	searchQuery,
	rowIndex,
	enabledColumns,
	relayProps,
	object,
	nextLevelProps,
	sortValue,
	convertToProjectCurrency,
	projectCurrency
) => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return (
				<ProjectRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
					convertToProjectCurrency={convertToProjectCurrency}
					projectCurrency={projectCurrency}
				/>
			);
		case REPORT_GROUPINGS.TASK:
			return (
				<TaskReportRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
					convertToProjectCurrency={convertToProjectCurrency}
					projectCurrency={projectCurrency}
				/>
			);
		case REPORT_GROUPINGS.PHASE:
			return (
				<PhaseRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
					convertToProjectCurrency={convertToProjectCurrency}
					projectCurrency={projectCurrency}
				/>
			);
		case REPORT_GROUPINGS.CLIENT:
			return (
				<ClientRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
					convertToProjectCurrency={convertToProjectCurrency}
					projectCurrency={projectCurrency}
				/>
			);
		case REPORT_GROUPINGS.ROLE:
			return (
				<RoleRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
					convertToProjectCurrency={convertToProjectCurrency}
					projectCurrency={projectCurrency}
				/>
			);
		case REPORT_GROUPINGS.SPRINT:
			return (
				<SprintRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
					convertToProjectCurrency={convertToProjectCurrency}
					projectCurrency={projectCurrency}
				/>
			);
		case PROJECT_TASK_REPORT_GROUPINGS.PERSON:
			return (
				<PersonRelayWrapper
					{...relayProps}
					tableHeader={tableHeader}
					groupings={groupings.slice(1)}
					searchQuery={searchQuery}
					rowIndex={rowIndex + 1}
					enabledColumns={enabledColumns}
					nextLevelProps={nextLevelProps}
					sortValue={sortValue}
					convertToProjectCurrency={convertToProjectCurrency}
					projectCurrency={projectCurrency}
				/>
			);
		default:
			return '(TaskReportUtil)No relay wrapper provided!';
	}
};

const getComponentName = (groupings, object) => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return 'project_relay_wrapper';
		case REPORT_GROUPINGS.TASK:
			return 'task_relay_wrapper';
		case REPORT_GROUPINGS.ROLE:
			return 'role_relay_wrapper';
		case REPORT_GROUPINGS.PHASE:
			return 'phase_relay_wrapper';
		case REPORT_GROUPINGS.CLIENT:
			return 'client_relay_wrapper';
		case REPORT_GROUPINGS.SPRINT:
			return 'sprint_relay_wrapper';
		case PROJECT_TASK_REPORT_GROUPINGS.PERSON:
			return 'person_relay_wrapper';
		default:
			return 'No component name provided!';
	}
};

const getQuery = (groupings, object) => {
	switch (groupings[0]) {
		case REPORT_GROUPINGS.PROJECT:
			return ProjectRelayWrapperQuery;
		case PROJECT_TASK_REPORT_GROUPINGS.TASK:
		case REPORT_GROUPINGS.TASK:
			return TaskRelayWrapperQuery;
		case REPORT_GROUPINGS.CLIENT:
			return ClientRelayWrapperQuery;
		case PROJECT_TASK_REPORT_GROUPINGS.ROLE:
		case REPORT_GROUPINGS.ROLE:
			return RoleRelayWrapperQuery;
		case PROJECT_TASK_REPORT_GROUPINGS.PHASE:
		case REPORT_GROUPINGS.PHASE:
			return PhaseRelayWrapperQuery;
		case REPORT_GROUPINGS.SPRINT:
			return SprintRelayWrapperQuery;
		case PROJECT_TASK_REPORT_GROUPINGS.PERSON:
			return PersonRelayWrapperQuery;
		default:
			return 'No query provided!';
	}
};

const keyReducer = (accumulator, currentValue) => {
	if (currentValue.value && currentValue.value.length) {
		return accumulator + currentValue.value[0];
	} else {
		return accumulator + 'null';
	}
};

const getQueryInfo = (groupings, searchQuery, object) => {
	const queryInfo = {};
	const keyEnding = searchQuery.filters.length > 0 && searchQuery.filters.reduce(keyReducer);
	const keyEnding2 = object && object.field + object.value;

	queryInfo.key = `query-render-relayWrapper-${keyEnding}-${keyEnding2}`;
	queryInfo.componentName = getComponentName(groupings, object);
	queryInfo.query = getQuery(groupings, object);
	return queryInfo;
};

export const getQueryRenderer = (
	tableHeader,
	groupings,
	searchQuery,
	rowIndex,
	enabledColumns,
	groupingFilter,
	nextLevelProps,
	sortValue = null,
	convertToProjectCurrency,
	projectCurrency,
	isInPersonGroup,
	rowData
) => {
	const searchQueryFiltersCopy = [...searchQuery.filters];
	if (groupingFilter !== null) {
		searchQueryFiltersCopy.push(groupingFilter);
	}

	const filteredSearchQuery = {};
	if (groupings[0] === 'project') {
		filteredSearchQuery.filters = searchQueryFiltersCopy.filter(sqf => sqf.field !== 'LABELS');
	} else {
		filteredSearchQuery.filters = searchQueryFiltersCopy;
	}

	const newSearchQuery = {filters: searchQueryFiltersCopy};

	const query = getQueryInfo(groupings, searchQuery, groupingFilter);

	const personId = isInPersonGroup && rowData.id ? rowData.id : null;

	const financeServiceSearchQuery = personId
		? {
				filters: [
					{
						field: 'PERSON',
						operator: 'IN',
						value: [idFromGlobalId(personId).toString()],
					},
				],
		  }
		: null;

	return (
		<ForecastQueryRenderer
			key={query.key}
			query={query.query}
			variables={{
				pageSize: 30,
				searchQuery: filteredSearchQuery,
				sortValue: sortValue,
				convertToProjectCurrency: convertToProjectCurrency,
				personId: personId,
				financeServiceSearchQuery: financeServiceSearchQuery,
			}}
			render={relayProps => {
				return getRelayWrapper(
					tableHeader,
					groupings,
					newSearchQuery,
					rowIndex,
					enabledColumns,
					relayProps,
					groupingFilter,
					nextLevelProps,
					sortValue,
					convertToProjectCurrency,
					projectCurrency
				);
			}}
		/>
	);
};

/**
 *  Return the options for the first grouping.
 *
 * @param intl The I18n object
 * @param exclude array of type string to remove from the available groupings
 * @param additionalGroupings array of type { value: string, label: string } to add to the available groupings
 * @returns Array of available groupings
 */
export const noGroupingFirstDropdownOptions = (intl, exclude = [], additionalGroupings = []) => {
	let groupings = [
		{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
		{value: REPORT_GROUPINGS.PHASE, label: intl.formatMessage({id: 'common.group_by_phase'})},
		...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
			? [{value: REPORT_GROUPINGS.CLIENT, label: intl.formatMessage({id: 'common.group_by_client'})}]
			: []),
		{value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})},
	];

	return [
		...groupings,
		...additionalGroupings,
		{value: null, label: intl.formatMessage({id: 'project_sprints.group_by_people_disable'})},
	].filter(group => !exclude.includes(group.value));
};

export const getTotalTimeEntries = timeRegistrations => {
	if (timeRegistrations !== undefined) {
		return timeRegistrations.reduce((acc, elem) => acc + elem.node.minutesRegistered, 0);
	}
	return 0;
};

export const getTotalBillableTimeEntries = timeRegistrations => {
	if (timeRegistrations !== undefined) {
		return timeRegistrations.reduce((acc, elem) => acc + elem.node.billableMinutesRegistered, 0);
	}
	return 0;
};
