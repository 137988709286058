import {ITEM_TYPE, TIMELINE_GRAPH_HEIGHT, TIMELINE_HEATMAP_ITEM_HEIGHT} from './canvas_timeline_util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {SCHEDULING_VIEW} from '../../../constants';

class Item {
	constructor(pageComponent, itemType, data) {
		this.pageComponent = pageComponent;
		this.schedulingView = this.pageComponent.props.schedulingView;
		this.itemType = itemType;
		this.heatmapGroupIds = new Set();
		this.hideTooltip = data.hideTooltip || false;

		if (data) {
			this.updateWithData(data);
		}
	}

	beforeDraw(afterLazyLoad) {
		if (this.lazyLoadOnDraw) {
			this.lazyLoadOnDraw = false;
			this.lazyLoadData().then(data => afterLazyLoad(data));
		}
	}

	updateWithData(data) {
		this.height = data.height || this.getItemHeightFromItemType(this.itemType);
		this.groupId = data.groupId;
		this.startDate = data.startDate;
		this.endDate = data.endDate;
		this.visible = data.visible === null || data.visible === undefined ? true : data.visible;
		this.draggableType = data.draggableType;
		this.draggableArea = data.draggableArea;
		this.freeDragDestinationGroupTypes = data.freeDragDestinationGroupTypes || new Set();
		this.freeDragOuterGroupTypes = data.freeDragOuterGroupTypes || new Set();
		this.dropOffHoverEffectGroupType = data.dropOffHoverEffectGroupType;
		this.data = data;
		this.canGhost = data.canGhost;
		this.isGhost = data.isGhost;
		this.startGhost = data.startGhost;
		this.endGhost = data.endGhost;
		this.allowDependencyCreation = data.allowDependencyCreation;
		this.horizontalExtension = data.horizontalExtension || (data.allowDependencyCreation ? 26 : 0);
		this.handlesUpdateLock = data.handlesUpdateLock;
		this.lazyLoadOnDraw = !!data.lazyLoadData;
		this.lazyLoadData = data.lazyLoadData;

		this.setVisibleItemsTreeGroupId();
		this.setEventListeners(data);
	}

	setEventListeners(data) {
		if (data.onDraw) {
			this.onDraw = data.onDraw;
		}

		if (data.onClick) {
			this.onClick = data.onClick;
		}

		if (data.onMoveAttempt) {
			this.onMoveAttempt = data.onMoveAttempt;
		}

		if (data.onMouseEnter) {
			this.onMouseEnter = data.onMouseEnter;
		}

		if (data.onMouseLeave) {
			this.onMouseLeave = data.onMouseLeave;
		}

		if (data.onMouseMove) {
			this.onMouseMove = data.onMouseMove;
		}

		if (data.onMoveStart) {
			this.onMoveStart = data.onMoveStart;
		}

		if (data.onMoving) {
			this.onMoving = data.onMoving;
		}

		if (data.onMoveEnd) {
			this.onMoveEnd = data.onMoveEnd;
		}

		if (data.onMoveAnimationEnd) {
			this.onMoveAnimationEnd = data.onMoveAnimationEnd;
		}

		if (data.updateFreeDragData) {
			this.updateFreeDragData = data.updateFreeDragData;
		}
	}

	isDisabled() {
		return !!(this.data?.disabled || this.updateLock);
	}

	isOnClickEnabled() {
		if (this.itemType === ITEM_TYPE.HEATMAP_ITEM) {
			return true;
		}

		return this.data?.onClickEnabled;
	}

	getItemHeightFromItemType(itemType) {
		const isProjectScheduling = this.schedulingView === SCHEDULING_VIEW.PROJECTS;

		switch (itemType) {
			case ITEM_TYPE.PROJECT_SCHEDULING_PROJECT:
				return 40;
			case ITEM_TYPE.PROJECT_SCHEDULING_PHASE:
				return 33;
			case ITEM_TYPE.TASK:
				return 27;
			case ITEM_TYPE.PROJECT_ALLOCATION:
				return 24;
			case ITEM_TYPE.PLACEHOLDER_ALLOCATION:
				return 24;
			case ITEM_TYPE.PROJECT_SCHEDULING_TEAM_ITEM:
				return 18;
			case ITEM_TYPE.PEOPLE_SCHEDULING_UNASSIGNED_TASK:
				return 47;
			case ITEM_TYPE.PEOPLE_SCHEDULING_UNASSIGNED_HEATMAP:
				return 47;
			case ITEM_TYPE.HEATMAP_ITEM:
				return isProjectScheduling ? TIMELINE_HEATMAP_ITEM_HEIGHT : 48;
			case ITEM_TYPE.TIMELINE_GRAPH_ITEM:
				return TIMELINE_GRAPH_HEIGHT;
			default:
				// eslint-disable-next-line no-console
				console.warn(
					'Unrecognized itemType type. Using default height of 40px. Check getItemHeightFromItemType function in canvas_timeline_item.'
				);
				return 40;
		}
	}

	hide() {
		this.visible = false;
	}

	show() {
		this.visible = true;
	}

	resetItemRow() {
		this.itemRow = undefined;
		this.groupedItemRow = undefined;
	}

	shift(dayDifference, startDifference, endDifference) {
		//Need to make sure difference is an integer
		const parsedDayDifference = parseInt(dayDifference, 10);
		const parsedStartDayDifference = startDifference ? parseInt(startDifference, 10) : null;
		const parsedEndDayDifference = endDifference ? parseInt(endDifference, 10) : null;
		if (!parsedDayDifference) return;
		this.startDate += parsedStartDayDifference || parsedDayDifference;
		this.endDate += parsedEndDayDifference || parsedDayDifference;
	}

	updateData(data, updateItem = false) {
		if (updateItem) {
			this.updateWithData(data);
		} else {
			this.data = data;
		}
	}

	refreshData() {
		this.updateData(this.data);
	}

	setVisibleItemsTreeDates() {
		this.visibleItemsTreeStartDate = this.startDate;
		this.visibleItemsTreeEndDate = this.endDate;
	}

	updateForVisibility() {
		this.setVisibleItemsTreeDates();
		this.setVisibleItemsTreeGroupId();
	}

	getVisibleItemsTreeDates() {
		return {
			startDate: this.visibleItemsTreeStartDate,
			endDate: this.visibleItemsTreeEndDate,
		};
	}

	setVisibleItemsTreeGroupId() {
		this.visibleItemsTreeGroupId = this.groupId;
	}

	getVisibleItemsTreeGroupId() {
		return this.visibleItemsTreeGroupId;
	}

	addHeatmapGroupId(id) {
		this.heatmapGroupIds.add(id);
	}

	getHeatmapGroupIds() {
		return this.heatmapGroupIds;
	}

	removeHeatmapGroupId(id) {
		if (this.heatmapGroupIds) {
			this.heatmapGroupIds.delete(id);
		}
	}

	applyUpdateLock() {
		if (hasFeatureFlag('pto_timesheet_allocation_linking')) {
			if (this.handlesUpdateLock) {
				this.onMouseLeave();
				this.updateLock = true;
			}
		}
	}

	removeUpdateLock() {
		if (hasFeatureFlag('pto_timesheet_allocation_linking')) {
			if (this.handlesUpdateLock) {
				this.updateLock = false;
			}
		}
	}

	isFreeDragOuterGroup(group) {
		return this.freeDragOuterGroupTypes.has(group.groupType);
	}

	isFreeDragDestinationGroup(group) {
		return this.freeDragDestinationGroupTypes.has(group.groupType);
	}

	isFreeDragDropOffGroup(group) {
		return this.isFreeDragOuterGroup(group) || this.isFreeDragDestinationGroup(group);
	}

	hasFreeDragDefined() {
		return this.freeDragDestinationGroupTypes.size > 0 || this.freeDragOuterGroupTypes.size > 0;
	}
}

export default Item;
