import {createPaginationContainer, graphql} from 'react-relay';
import Util from '../../../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';

const sumAllValues = objects => {
	return objects.reduce((acc, object) => {
		Object.keys(object).forEach(k => (acc[k] = (acc[k] || 0) + object[k]));
		return acc;
	}, {});
};

const sortResourceNumbers = (a, b) => {
	if (a.program && b.program) {
		return Util.sortAlphabetically(a.program.prefix, b.program.prefix);
	} else if (a.projectGroup && b.projectGroup) {
		return a.projectGroup.companyProjectGroupId - b.projectGroup.companyProjectGroupId;
	} else if (a.project && b.project) {
		return a.project.companyProjectId - b.project.companyProjectId;
	} else if (a.idleTime && b.idleTime) {
		return Util.sortAlphabetically(a.idleTime.name, b.idleTime.name);
	} else if (a.program || b.program) {
		return a.program ? -1 : 1;
	} else if (a.projectGroup || b.projectGroup) {
		return a.projectGroup ? -1 : 1;
	} else if (a.project || b.project) {
		return a.project ? -1 : 1;
	}
	return 0;
};

const ResourceNumbersBreakdownLoader = ({relay, viewer, person, projectToProgramMap, children, ...props}) => {
	const formatData = () => {
		const breakdowns = viewer.company.person.resourceNumbersBreakdown.edges.filter(
			row => !row.node.project?.projectGroupId && !projectToProgramMap.has(row.node.project?.id)
		);
		const nestedBreakdowns = viewer.company.person.resourceNumbersBreakdown.edges.filter(
			row => row.node.project?.projectGroupId || projectToProgramMap.has(row.node.project?.id)
		);

		const resourceNumbersBreakdown = breakdowns
			.map(edge => edge.node)
			.map(breakdown => {
				const {id, resourceNumbers, project, projectGroup, idleTime} = breakdown;
				return {
					id: atob(id),
					resourceNumbers,
					project,
					projectGroup,
					idleTime,
				};
			});

		const projectGroupMap = new Map(
			viewer.projectGroups.edges.map(projectGroup => [projectGroup.node.id, projectGroup.node])
		);

		nestedBreakdowns.forEach(breakdown => {
			const {id, resourceNumbers, project, projectGroup, idleTime} = breakdown.node;
			const rowData = {
				id: atob(id),
				resourceNumbers,
				project,
				projectGroup,
				idleTime,
			};

			const projectGroupId = project.projectGroupId;
			const program = projectToProgramMap.get(project.id);
			let groupRow = resourceNumbersBreakdown.find(row =>
				program ? row.program?.id === program.id : row.projectGroup?.id === projectGroupId
			);
			if (!groupRow) {
				if (program) {
					groupRow = {
						id: `PROGRAM-${program.id}`,
						idleTime: null,
						project: null,
						resourceNumbers,
						program,
						rows: [rowData],
					};
				} else {
					groupRow = {
						id: `PROJECT_GROUP-${projectGroupId}`,
						idleTime: null,
						project: null,
						resourceNumbers,
						projectGroup: projectGroupMap.get(projectGroupId),
						rows: [rowData],
					};
				}

				resourceNumbersBreakdown.push(groupRow);
			} else {
				if (groupRow.rows) {
					groupRow.rows.push(rowData);
				} else {
					groupRow.rows = [rowData];
				}
				if (!hasFeatureFlag('combined_heatmap_logic_extensions') || program) {
					groupRow.resourceNumbers = sumAllValues([groupRow.resourceNumbers, rowData.resourceNumbers]);
				}
			}
		});

		resourceNumbersBreakdown.sort(sortResourceNumbers);

		return resourceNumbersBreakdown;
	};

	return children({
		data: {
			rows: formatData(),
			totalCount: viewer.company.person.resourceNumbersBreakdown.totalCount,
			loadMoreFunc: () => relay.hasMore() && !relay.isLoading() && relay.loadMore(10),
		},
		...props,
	});
};

export const resourceNumbersBreakdownLoaderQuery = graphql`
	query ResourceNumbersBreakdownLoaderQuery_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$personId: ID
		$count: Int
		$cursor: String
		$searchQuery: TaskSearchQueryType
	) {
		viewer {
			actualPersonId
			component(name: "ResourceNumbersBreakdownLoader")
			...ResourceNumbersBreakdownLoader_viewer
				@arguments(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					count: $count
					cursor: $cursor
					personId: $personId
					searchQuery: $searchQuery
				)
		}
	}
`;

export default createPaginationContainer(
	ResourceNumbersBreakdownLoader,
	{
		viewer: graphql`
			fragment ResourceNumbersBreakdownLoader_viewer on Viewer
			@argumentDefinitions(
				startYear: {type: "Int"}
				startMonth: {type: "Int"}
				startDay: {type: "Int"}
				endYear: {type: "Int"}
				endMonth: {type: "Int"}
				endDay: {type: "Int"}
				pageSize: {type: "Int"}
				count: {type: "Int"}
				cursor: {type: "String"}
				personId: {type: "ID"}
				searchQuery: {type: "TaskSearchQueryType"}
			)
			@relay(mask: false) {
				company {
					person(id: $personId) {
						id
						resourceNumbersBreakdown(
							startYear: $startYear
							startMonth: $startMonth
							startDay: $startDay
							endYear: $endYear
							endMonth: $endMonth
							endDay: $endDay
							first: $count
							after: $cursor
							searchQuery: $searchQuery
						) @connection(key: "ResourceNumbersBreakdown_resourceNumbersBreakdown") {
							totalCount
							edges {
								node {
									id
									idleTime {
										id
										name
										isInternalTime
									}
									project {
										id
										companyProjectId
										customProjectId
										name
										projectColor
										projectGroupId
										readOnlyAccess
										fullAccessToProject
										projectOwner {
											id
											fullName
										}
									}
									projectGroup {
										id
										companyProjectGroupId
										name
										color
										readOnlyAccess
									}
									resourceNumbers {
										# Actuals
										actualBillableProjectMinutes
										actualNonBillableProjectMinutes
										actualInternalMinutes
										actualTotalMinutes
										# actualOverMinutes
										# actualDoneTaskMinutes
										actualBillableUtilization
										actualNonBillableUtilization
										actualInternalUtilization
										actualResourceUtilization

										# Plan
										plannedBillableProjectMinutes
										plannedBillableProjectMinutesWin
										plannedBillableProjectMinutesSoft
										plannedBillableProjectMinutesSoftWin
										plannedBillableProjectMinutesHard
										plannedNonBillableProjectMinutes
										plannedNonBillableProjectMinutesWin
										plannedNonBillableProjectMinutesSoft
										plannedNonBillableProjectMinutesSoftWin
										plannedNonBillableProjectMinutesHard
										plannedInternalMinutes
										plannedTotalMinutes
										plannedTotalMinutesWin
										plannedTotalMinutesSoft
										plannedTotalMinutesSoftWin
										plannedTotalMinutesHard
										# plannedOverMinutes
										# plannedOverMinutesWin
										# plannedOverMinutesSoft
										# plannedOverMinutesSoftWin
										# plannedOverMinutesHard
										# plannedDoneTaskMinutes
										plannedBillableUtilization
										plannedBillableUtilizationWin
										plannedBillableUtilizationSoft
										plannedBillableUtilizationSoftWin
										plannedBillableUtilizationHard
										plannedNonBillableUtilization
										plannedNonBillableUtilizationWin
										plannedNonBillableUtilizationSoft
										plannedNonBillableUtilizationSoftWin
										plannedNonBillableUtilizationHard
										plannedInternalUtilization
										plannedResourceUtilization
										plannedResourceUtilizationWin
										plannedResourceUtilizationSoft
										plannedResourceUtilizationSoftWin
										plannedResourceUtilizationHard
										plannedBillableTaskAndAllocationsCombined
										plannedNonBillableTaskAndAllocationsCombined
										plannedInternalTimeTaskAndAllocationsCombined
										plannedTotalTimeTaskAndAllocationsCombined
										plannedBillableUtilizationTaskAndAllocationsCombined
										plannedNonBillableUtilizationTaskAndAllocationsCombined
										plannedInternalUtilizationTaskAndAllocationsCombined
										plannedResourceUtilizationTaskAndAllocationsCombined
										plannedBillableTaskAndAllocationsCombinedWin
										plannedNonBillableTaskAndAllocationsCombinedWin
										plannedTotalTimeTaskAndAllocationsCombinedWin
										plannedBillableUtilizationTaskAndAllocationsCombinedWin
										plannedNonBillableUtilizationTaskAndAllocationsCombinedWin
										plannedResourceUtilizationTaskAndAllocationsCombinedWin
										plannedBillableTaskAndAllocationsCombinedSoft
										plannedNonBillableTaskAndAllocationsCombinedSoft
										plannedTotalTimeTaskAndAllocationsCombinedSoft
										plannedBillableUtilizationTaskAndAllocationsCombinedSoft
										plannedNonBillableUtilizationTaskAndAllocationsCombinedSoft
										plannedResourceUtilizationTaskAndAllocationsCombinedSoft
										plannedBillableTaskAndAllocationsCombinedSoftWin
										plannedNonBillableTaskAndAllocationsCombinedSoftWin
										plannedTotalTimeTaskAndAllocationsCombinedSoftWin
										plannedBillableUtilizationTaskAndAllocationsCombinedSoftWin
										plannedNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
										plannedResourceUtilizationTaskAndAllocationsCombinedSoftWin
										plannedBillableTaskAndAllocationsCombinedHard
										plannedNonBillableTaskAndAllocationsCombinedHard
										plannedTotalTimeTaskAndAllocationsCombinedHard
										plannedBillableUtilizationTaskAndAllocationsCombinedHard
										plannedNonBillableUtilizationTaskAndAllocationsCombinedHard
										plannedResourceUtilizationTaskAndAllocationsCombinedHard
										remainingBillableTaskAndAllocationsCombinedWin
										remainingNonBillableTaskAndAllocationsCombinedWin
										remainingTotalTimeTaskAndAllocationsCombinedWin
										remainingBillableUtilizationTaskAndAllocationsCombinedWin
										remainingNonBillableUtilizationTaskAndAllocationsCombinedWin
										remainingResourceUtilizationTaskAndAllocationsCombinedWin
										remainingBillableTaskAndAllocationsCombinedSoft
										remainingNonBillableTaskAndAllocationsCombinedSoft
										remainingTotalTimeTaskAndAllocationsCombinedSoft
										remainingBillableUtilizationTaskAndAllocationsCombinedSoft
										remainingNonBillableUtilizationTaskAndAllocationsCombinedSoft
										remainingResourceUtilizationTaskAndAllocationsCombinedSoft
										remainingBillableTaskAndAllocationsCombinedSoftWin
										remainingNonBillableTaskAndAllocationsCombinedSoftWin
										remainingTotalTimeTaskAndAllocationsCombinedSoftWin
										remainingBillableUtilizationTaskAndAllocationsCombinedSoftWin
										remainingNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
										remainingResourceUtilizationTaskAndAllocationsCombinedSoftWin
										remainingBillableTaskAndAllocationsCombinedHard
										remainingNonBillableTaskAndAllocationsCombinedHard
										remainingTotalTimeTaskAndAllocationsCombinedHard
										remainingBillableUtilizationTaskAndAllocationsCombinedHard
										remainingNonBillableUtilizationTaskAndAllocationsCombinedHard
										remainingResourceUtilizationTaskAndAllocationsCombinedHard
										forecastBillableTaskAndAllocationsCombinedWin
										forecastNonBillableTaskAndAllocationsCombinedWin
										forecastTotalTimeTaskAndAllocationsCombinedWin
										forecastBillableUtilizationTaskAndAllocationsCombinedWin
										forecastNonBillableUtilizationTaskAndAllocationsCombinedWin
										forecastResourceUtilizationTaskAndAllocationsCombinedWin
										forecastBillableTaskAndAllocationsCombinedSoft
										forecastNonBillableTaskAndAllocationsCombinedSoft
										forecastTotalTimeTaskAndAllocationsCombinedSoft
										forecastBillableUtilizationTaskAndAllocationsCombinedSoft
										forecastNonBillableUtilizationTaskAndAllocationsCombinedSoft
										forecastResourceUtilizationTaskAndAllocationsCombinedSoft
										forecastBillableTaskAndAllocationsCombinedSoftWin
										forecastNonBillableTaskAndAllocationsCombinedSoftWin
										forecastTotalTimeTaskAndAllocationsCombinedSoftWin
										forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin
										forecastNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
										forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin
										forecastBillableTaskAndAllocationsCombinedHard
										forecastNonBillableTaskAndAllocationsCombinedHard
										forecastTotalTimeTaskAndAllocationsCombinedHard
										forecastBillableUtilizationTaskAndAllocationsCombinedHard
										forecastNonBillableUtilizationTaskAndAllocationsCombinedHard
										forecastResourceUtilizationTaskAndAllocationsCombinedHard

										# Actuals vs. Plan
										billableActualVsPlan
										billableActualVsPlanWin
										billableActualVsPlanSoft
										billableActualVsPlanSoftWin
										billableActualVsPlanHard
										nonBillableActualVsPlan
										nonBillableActualVsPlanWin
										nonBillableActualVsPlanSoft
										nonBillableActualVsPlanSoftWin
										nonBillableActualVsPlanHard
										internalActualVsPlan
										totalActualVsPlan
										totalActualVsPlanWin
										totalActualVsPlanSoft
										totalActualVsPlanSoftWin
										totalActualVsPlanHard

										# Remaining
										remainingBillableProjectMinutes
										remainingBillableProjectMinutesWin
										remainingBillableProjectMinutesSoft
										remainingBillableProjectMinutesSoftWin
										remainingBillableProjectMinutesHard
										remainingNonBillableProjectMinutes
										remainingNonBillableProjectMinutesWin
										remainingNonBillableProjectMinutesSoft
										remainingNonBillableProjectMinutesSoftWin
										remainingNonBillableProjectMinutesHard
										remainingInternalMinutes
										remainingTotalMinutes
										remainingTotalMinutesWin
										remainingTotalMinutesSoft
										remainingTotalMinutesSoftWin
										remainingTotalMinutesHard
										# remainingOverMinutes
										remainingBillableUtilization
										remainingBillableUtilizationWin
										remainingBillableUtilizationSoft
										remainingBillableUtilizationSoftWin
										remainingBillableUtilizationHard
										remainingNonBillableUtilization
										remainingNonBillableUtilizationWin
										remainingNonBillableUtilizationSoft
										remainingNonBillableUtilizationSoftWin
										remainingNonBillableUtilizationHard
										remainingInternalUtilization
										remainingResourceUtilization
										remainingResourceUtilizationWin
										remainingResourceUtilizationSoft
										remainingResourceUtilizationSoftWin
										remainingResourceUtilizationHard
										remainingBillableTaskAndAllocationsCombined
										remainingNonBillableTaskAndAllocationsCombined
										remainingInternalTimeTaskAndAllocationsCombined
										remainingTotalTimeTaskAndAllocationsCombined
										remainingBillableUtilizationTaskAndAllocationsCombined
										remainingBillableUtilizationTaskAndAllocationsCombined
										remainingInternalUtilizationTaskAndAllocationsCombined
										remainingResourceUtilizationTaskAndAllocationsCombined

										# Forecast
										forecastBillableProjectMinutes
										forecastBillableProjectMinutesWin
										forecastBillableProjectMinutesSoft
										forecastBillableProjectMinutesSoftWin
										forecastBillableProjectMinutesHard
										forecastNonBillableProjectMinutes
										forecastNonBillableProjectMinutesWin
										forecastNonBillableProjectMinutesSoft
										forecastNonBillableProjectMinutesSoftWin
										forecastNonBillableProjectMinutesHard
										forecastInternalMinutes
										forecastTotalMinutes
										forecastTotalMinutesWin
										forecastTotalMinutesSoft
										forecastTotalMinutesSoftWin
										forecastTotalMinutesHard
										# forecastOverMinutes
										# forecastOverMinutesWin
										# forecastOverMinutesSoft
										# forecastOverMinutesSoftWin
										# forecastOverMinutesHard
										forecastBillableUtilization
										forecastBillableUtilizationWin
										forecastBillableUtilizationSoft
										forecastBillableUtilizationSoftWin
										forecastBillableUtilizationHard
										forecastNonBillableUtilization
										forecastNonBillableUtilizationWin
										forecastNonBillableUtilizationSoft
										forecastNonBillableUtilizationSoftWin
										forecastNonBillableUtilizationHard
										forecastInternalUtilization
										forecastResourceUtilization
										forecastResourceUtilizationWin
										forecastResourceUtilizationSoft
										forecastResourceUtilizationSoftWin
										forecastResourceUtilizationHard
										forecastBillableTaskAndAllocationsCombined
										forecastNonBillableTaskAndAllocationsCombined
										forecastInternalTimeTaskAndAllocationsCombined
										forecastTotalTimeTaskAndAllocationsCombined
										forecastBillableUtilizationTaskAndAllocationsCombined
										forecastNonBillableUtilizationTaskAndAllocationsCombined
										forecastInternalUtilizationTaskAndAllocationsCombined
										forecastResourceUtilizationTaskAndAllocationsCombined

										# Tasks estimates (Planned)
										tasksEstimateBillableMinutes
										tasksEstimateNonBillableMinutes
										tasksEstimateTotalMinutes
										tasksBillableUtilization
										tasksNonBillableUtilization
										tasksResourceUtilization
										# tasksOverMinutes

										# Tasks Remaining
										tasksRemainingBillableMinutes
										tasksRemainingNonBillableMinutes
										tasksRemainingTotalMinutes
										# tasksRemainingOverMinutes
										tasksRemainingBillableUtilization
										tasksRemainingNonBillableUtilization
										tasksRemainingResourceUtilization

										# Tasks Forecast
										tasksForecastBillableMinutes
										tasksForecastNonBillableMinutes
										tasksForecastTotalMinutes
										# tasksForecastOverMinutes
										tasksForecastBillableUtilization
										tasksForecastNonBillableUtilization
										tasksForecastResourceUtilization

										# Tasks Actual vs. Plan
										tasksBillableActualVsPlan
										tasksNonBillableActualVsPlan
										tasksTotalActualVsPlan

										# Planned Allocation vs. Task
										plannedBillableAllocationVsTask
										plannedBillableAllocationVsTaskWin
										plannedBillableAllocationVsTaskSoft
										plannedBillableAllocationVsTaskSoftWin
										plannedBillableAllocationVsTaskHard
										plannedNonBillableAllocationVsTask
										plannedNonBillableAllocationVsTaskWin
										plannedNonBillableAllocationVsTaskSoft
										plannedNonBillableAllocationVsTaskSoftWin
										plannedNonBillableAllocationVsTaskHard
										plannedAllocationVsTask
										plannedAllocationVsTaskWin
										plannedAllocationVsTaskSoft
										plannedAllocationVsTaskSoftWin
										plannedAllocationVsTaskHard

										# Remaining Allocation vs. Task
										remainingBillableAllocationVsTask
										remainingBillableAllocationVsTaskWin
										remainingBillableAllocationVsTaskSoft
										remainingBillableAllocationVsTaskSoftWin
										remainingBillableAllocationVsTaskHard
										remainingNonBillableAllocationVsTask
										remainingNonBillableAllocationVsTaskWin
										remainingNonBillableAllocationVsTaskSoft
										remainingNonBillableAllocationVsTaskSoftWin
										remainingNonBillableAllocationVsTaskHard
										remainingAllocationVsTask
										remainingAllocationVsTaskWin
										remainingAllocationVsTaskSoft
										remainingAllocationVsTaskSoftWin
										remainingAllocationVsTaskHard

										# Availability
										#workingMinutes
										timeOffMinutes
										#availableMinutes

										#Performance
										# performance
									}
								}
							}
						}
					}
				}
				projectGroups(first: 100000) {
					edges {
						node {
							id
							companyProjectGroupId
							name
							color
							readOnlyAccess
						}
					}
				}
			}
		`,
	},
	{
		direction: 'forward',
		getConnectionFromProps({viewer}) {
			return viewer.company.person && viewer.company.person.resourceNumbersBreakdown;
		},
		getFragmentVariables(prevVars, totalCount) {
			return {
				...prevVars,
				count: totalCount,
			};
		},
		getVariables(_, {count, cursor}, fragmentVariables) {
			return {
				count,
				cursor,
				personId: fragmentVariables.personId,
			};
		},
		query: graphql`
			query ResourceNumbersBreakdownLoaderQuery(
				$startYear: Int
				$startMonth: Int
				$startDay: Int
				$endYear: Int
				$endMonth: Int
				$endDay: Int
				$count: Int
				$cursor: String
				$personId: ID!
			) {
				viewer {
					actualPersonId
					component(name: "ResourceNumbersBreakdownLoader")
					...ResourceNumbersBreakdownLoader_viewer
						@arguments(
							startYear: $startYear
							startMonth: $startMonth
							startDay: $startDay
							endYear: $endYear
							endMonth: $endMonth
							endDay: $endDay
							count: $count
							cursor: $cursor
							personId: $personId
						)
				}
			}
		`,
	}
);
