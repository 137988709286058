import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {FormattedMessage, injectIntl} from 'react-intl';
import {categoryColors} from '../../constants';

class InsightsDashboardCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			actionsMenuExpanded: false,
		};
	}

	isReportShared = () => {
		return this.props.insight.shares
			? this.props.insight.shares.edges && this.props.insight.shares.edges.length > 0
			: false;
	};

	toggleActionsMenu(e) {
		e.stopPropagation();
		this.setState({
			actionsMenuExpanded: !this.state.actionsMenuExpanded,
		});
	}

	closeActionsMenu() {
		if (this.state.actionsMenuExpanded)
			this.setState({
				actionsMenuExpanded: false,
			});
	}

	render() {
		const {formatMessage} = this.props.intl;
		return (
			<div className="card" data-cy={this.props.cy} data-class="card">
				<svg height="250px" width="300px">
					<path
						d="M0 220 L0 5 C0 5 0 0 5 0 L295 0 C295 0 300 0 300 5 L300 150 L218 103 Z"
						style={{fill: categoryColors[this.props.insight.category].dark}}
					/>
					<polygon
						points="218,0 240,0 300,40 300,56 218,103"
						style={{fill: categoryColors[this.props.insight.category].darkest}}
					/>
				</svg>
				{this.props.canEdit ? (
					<div
						className="actions"
						onBlur={this.closeActionsMenu.bind(this)}
						onClick={this.toggleActionsMenu.bind(this)}
						tabIndex="0"
					>
						<div className={'actions-drop-down ' + (this.state.actionsMenuExpanded ? 'expanded' : 'collapsed')}>
							<ul>
								{
									// <li>
									//     <FormattedMessage id='common.duplicate'/>
									// </li>
								}
								{
									<li onClick={this.props.deleteInsight}>
										<FormattedMessage id="common.delete" />
									</li>
								}
								{
									<li onClick={this.props.editInsight}>
										<FormattedMessage id="common.edit" />
									</li>
								}
							</ul>
						</div>
					</div>
				) : null}
				<Link
					className="react-router-link"
					to={window.location.pathname + (window.location.pathname.endsWith('/') ? '' : '/') + this.props.insight.id}
				>
					<div className="card-data">
						<div className="header">
							<h2 className="title">
								{this.props.insight.name ? this.props.insight.name : formatMessage({id: 'common.untitled'})}
							</h2>
						</div>
						<div className="category-name" data-cy={this.props.insight.category}>
							{formatMessage({id: 'insights.category.' + this.props.insight.category.toLowerCase()})}
						</div>
					</div>
				</Link>
				{this.isReportShared() && (
					<div style={{position: 'absolute', bottom: '10px', right: '10px', fontSize: 'small'}}>
						{formatMessage({id: 'insights.report_is_shared'})}
					</div>
				)}
			</div>
		);
	}
}

InsightsDashboardCard.propTypes = {
	insight: PropTypes.object.isRequired,
	editInsight: PropTypes.func.isRequired,
	deleteInsight: PropTypes.func.isRequired,
	canEdit: PropTypes.bool.isRequired,
};

export default injectIntl(InsightsDashboardCard);
