import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import uuid from 'uuid';
import ReactDOM from 'react-dom';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Util from '../../forecast-app/shared/util/util';
import {INITIALS_SIZE} from '../../constants';
import Chip from '../chip';
import PersonInitials from '../../forecast-app/shared/components/person/person_initials';
import {profilePicSrc} from '../../directApi';

class SelectInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFocused: false,
			searchCriteria: '',
			marked: null,
			isInitialRender: true,
			renderOnTop: false,
			dropdownHeight: 0,
		};
		this.uuid = uuid.v4();
	}

	componentDidMount() {
		if (this.props.focusOnMount) {
			this.select_input.focus();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.isFocused && this.state.isFocused) {
			const dropdownNode = this.dropdown.getBoundingClientRect();
			const domNode = ReactDOM.findDOMNode(this.dropdown);
			const windowHeight = window.innerHeight;
			const scrollableElement = Util.getScrollableParent(domNode);
			let renderOnTop = false;
			if (scrollableElement && !this.props.disableRenderCalculations) {
				renderOnTop =
					dropdownNode.bottom - windowHeight > -20 &&
					dropdownNode.y - scrollableElement.getBoundingClientRect().y > 350;
			} else {
				renderOnTop = dropdownNode.bottom - windowHeight > -20 && !this.props.disableRenderCalculations; //20 is to not make it end exactly at the bottom but have some space instead
			}
			this.setState({
				isInitialRender: false,
				renderOnTop,
				dropdownHeight: dropdownNode.height,
			});
		}
		if (prevState.isFocused && !this.state.isFocused) {
			this.setState({
				renderOnTop: false,
			});
		}
		if (this.dropdown && prevState.searchCriteria !== this.state.searchCriteria) {
			const dropdownNode = this.dropdown.getBoundingClientRect();
			this.setState({
				dropdownHeight: dropdownNode.height,
			});
		}
	}

	handleFocus() {
		if (!this.props.locked) {
			if (!this.state.isFocused) {
				this.select_input.focus();
			}
			this.setState({isFocused: !this.state.isFocused});
		}
	}

	handleExpand() {
		if (!this.props.locked) {
			this.select_input.focus();
			this.setState({isFocused: true});
		}
	}

	handleClose() {
		this.select_input.blur();
		this.setState({isFocused: false});
	}

	handleScroll(e) {
		e.preventDefault();
	}

	handleFocusFromClear() {
		if (!this.props.locked) {
			this.handleFocus();
			this.props.onChange(this.props.multi ? [] : null, true);
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (
			(e.target && e.target.className === 'select-wrapper ' + this.uuid) ||
			(newTarget && newTarget.className === 'select-wrapper ' + this.uuid)
		) {
			return;
		}
		this.setState({isFocused: false, marked: null, searchCriteria: ''});
		this.select_input.blur();
	}

	handleSelect(e, option, isSelected) {
		if (e) {
			e.preventDefault();
		}
		if (option && !option.disabled) {
			if (!this.props.multi) {
				this.props.onChange(option);
				this.setState({isFocused: false, searchCriteria: ''});
				this.select_input.blur();
			} else {
				//multi select item
				//if option already selected remove from selected else add to selected
				if (isSelected) {
					let newValue = this.props.value.filter(el => {
						return el.value !== option.value;
					});
					this.props.onChange(newValue);
				} else {
					let newValue = this.props.value.slice();
					newValue.push(option);
					this.props.onChange(newValue);
				}
				this.setState({searchCriteria: ''});
			}
		}
	}

	handleSeachCriteriaChange(e) {
		if (this.props.showValuesInline) {
			let valueLabel = '';
			this.props.value.forEach((val, index) => {
				valueLabel += val.label;
				if (index + 1 !== this.props.value.length) {
					valueLabel += ', ';
				}
			});
			const searchCriteria = e.target.value.substring(valueLabel.length + 1, e.target.value.length);
			this.setState({searchCriteria: searchCriteria});
		} else {
			this.setState({searchCriteria: e.target.value, marked: null});
		}
	}

	focusInputOnScroll() {
		this.select_input.focus();
	}

	handleOptionMouseEnter(index) {
		this.setState({marked: index});
	}

	handleOptionMouseLeave() {
		this.setState({marked: null});
	}

	handleKeyPress(e) {
		if (this.state.isFocused) {
			if (e.keyCode === 27) {
				this.handleBlur(e);
			} else if (e.keyCode === 13) {
				//Enter
				const options = this.props.options.filter(option => {
					if (typeof option.label === 'string') {
						return (
							this.state.searchCriteria === '' ||
							Util.normalizedIndexOf(option.label, this.state.searchCriteria) >= 0
						);
					}
					return (
						this.state.searchCriteria === '' ||
						Util.normalizedIndexOf(option.label.props.id, this.state.searchCriteria) >= 0
					);
				});
				if (this.props.creatable && this.state.searchCriteria !== '' && options.length === 0) {
					this.handleCreatableClick();
				} else {
					const valuesIds = this.props.multi
						? this.props.value.map(el => {
								return el.value;
						  })
						: null;
					if (this.state.marked !== null) {
						const option = options[this.state.marked];
						this.handleSelect(null, option, this.props.multi ? valuesIds.includes(option.value) : null);
					} else if (this.state.marked === null && this.state.searchCriteria !== '') {
						this.handleSelect(null, options[0], this.props.multi ? valuesIds.includes(options[0]) : null);
					}
				}
			} else if (e.keyCode === 40) {
				//down arrow
				const optionsLength =
					this.state.searchCriteria === ''
						? this.props.options.length
						: this.props.options.filter(option => {
								if (typeof option.label === 'string') {
									return (
										this.state.searchCriteria === '' ||
										Util.normalizedIndexOf(option.label, this.state.searchCriteria) >= 0
									);
								}
								return (
									this.state.searchCriteria === '' ||
									Util.normalizedIndexOf(option.label.props.id, this.state.searchCriteria) >= 0
								);
						  }).length;
				if (this.props.creatable && this.state.searchCriteria !== '' && optionsLength === 0) {
					this.setState({marked: 0});
				} else {
					// if single select add 2 because value was removed from options
					if ((this.props.multi ? this.state.marked - 1 : this.state.marked + 1) !== optionsLength) {
						if (this.state.marked === null) {
							if (this.state.renderOnTop) return;
							this.setState({marked: 0});
						} else {
							this.setState({marked: this.state.marked + 1});
						}
					}
				}
			} else if (e.keyCode === 38) {
				//up arrow
				if (this.state.marked === null) return;
				if (this.state.marked === 0) {
					this.setState({marked: null});
				} else {
					this.setState({marked: this.state.marked - 1});
				}
				if (this.state.renderOnTop) {
					if (this.state.marked === null) {
						this.setState({marked: 0});
					}
				}
			}
		}
	}

	handleCreatableClick() {
		if (this.props.onCreatableCallback) {
			this.props.onCreatableCallback(this.state.searchCriteria);
			this.setState({searchCriteria: '', isFocused: false, marked: null});
			this.select_input.blur();
		}
	}

	render() {
		const valuesIds = this.props.multi
			? this.props.value.map(el => {
					return el.value;
			  })
			: null;
		let extension = this.props.required ? ' *' : this.props.optional ? ' (optional)' : '';
		let valueLabel = '';
		if (!this.props.multi) {
			this.props.options.forEach(option => {
				if (option.value === this.props.value) {
					valueLabel = option.label;
				}
			});
		} else if (this.props.multi && this.props.showValuesInline) {
			this.props.value.forEach((val, index) => {
				valueLabel += val.label;
				if (index + 1 !== this.props.value.length) {
					valueLabel += ', ';
				}
			});
		}
		const options = this.props.options.filter(option => {
			if (typeof option.label === 'string') {
				return this.state.searchCriteria === '' || Util.normalizedIndexOf(option.label, this.state.searchCriteria) >= 0;
			} else if (option.label && option.label.props && option.label.props.id) {
				return (
					this.state.searchCriteria === '' ||
					Util.normalizedIndexOf(option.label.props.id, this.state.searchCriteria) >= 0
				);
			} else {
				return true;
			}
		});

		const dropdownStyle = this.state.renderOnTop ? {top: 0 - this.state.dropdownHeight} : {};
		return (
			<div
				className={this.props.locked ? 'select-wrapper-locked ' + this.uuid : 'select-wrapper ' + this.uuid}
				onScroll={this.focusInputOnScroll.bind(this)}
				onBlur={this.handleBlur.bind(this)}
				title={this.props.title ? this.props.title : null}
			>
				{this.props.hideLabel ? null : (
					<label onClick={this.handleClose.bind(this)} className={this.state.isFocused ? 'label-focused' : 'label'}>
						{this.props.label} {extension}
					</label>
				)}
				<div className="input-container">
					<input
						data-cy={this.props.cy}
						className={
							this.props.tableCellSelect ? 'input-table-cell' : this.state.isFocused ? 'input-focused' : 'input'
						}
						value={
							this.state.isFocused
								? this.props.showValuesInline
									? valueLabel + ' ' + this.state.searchCriteria
									: this.state.searchCriteria
								: valueLabel
						}
						onFocus={this.handleFocus.bind(this)}
						disabled={this.props.locked}
						onChange={this.handleSeachCriteriaChange.bind(this)}
						ref={input => {
							this.select_input = input;
						}}
						onKeyDown={this.handleKeyPress.bind(this)}
						placeholder={this.props.placeholder}
						type="text"
					/>
					{this.props.clearable && this.props.value && !this.props.locked ? (
						<div className="input-clear-icon" onClick={this.handleFocusFromClear.bind(this)} />
					) : null}
					{this.state.isFocused ? (
						<div className="input-icon-expanded" onClick={this.handleClose.bind(this)} />
					) : (
						<div className="input-icon-collapsed" onClick={this.handleExpand.bind(this)} />
					)}
				</div>
				{this.props.multi && !this.props.showValuesInline && !this.props.hideValues ? (
					<div className="multi-values">
						{this.props.value.map((el, index) => (
							<Chip key={index} el={el} handleSelect={this.handleSelect.bind(this)} locked={this.props.locked} />
						))}
					</div>
				) : null}
				{this.state.isFocused ? (
					<div
						ref={div => (this.dropdown = div)}
						className={
							(this.props.tableCellSelect ? 'select-table-cell' : 'select') +
							(this.state.isInitialRender ? ' initial-render' : '') +
							(this.props.hideLabel ? ' label-hidden' : '')
						}
						onMouseDown={this.handleScroll.bind(this)}
						style={dropdownStyle}
					>
						<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={300}>
							{options.length > 0 ? (
								options.map(
									(option, index) =>
										!option.hidden && (
											<div
												data-cy-class={this.props.listDataCy}
												id={'select_option_' + index}
												key={index}
												className={
													(this.props.multi
														? valuesIds.includes(option.value)
															? 'option-selected-multi'
															: 'option-multi'
														: this.props.value === option.value
														? 'option-selected'
														: 'option') +
													(this.state.marked === index ? ' marked' : '') +
													(option.disabled ? ' disabled' : '')
												}
												onMouseDown={e => {
													this.handleSelect(
														e,
														option,
														this.props.multi ? valuesIds.includes(option.value) : null
													);
												}}
												onMouseEnter={this.handleOptionMouseEnter.bind(this, index)}
												onMouseLeave={this.handleOptionMouseLeave.bind(this)}
											>
												{this.props.xeroChartLinesSelect ? (
													<span className="project-color" style={{backgroundColor: option.color}} />
												) : null}
												{this.props.personSelect ? (
													<span className="profile-picture">
														{option.profilePictureId ? (
															<img
																crossOrigin="use-credentials"
																alt="profile_pic"
																src={profilePicSrc(option.profilePictureId)}
																width="36"
																height="36"
															/>
														) : (
															<PersonInitials
																size={INITIALS_SIZE.LARGE}
																initials={
																	option.initials
																		? option.initials
																		: Util.getInitials(
																				`${option.firstName} ${option.lastName}`
																		  )
																}
															/>
														)}
													</span>
												) : null}
												{this.props.projectSelect ? (
													<span className="project-color" style={{backgroundColor: option.color}} />
												) : null}
												{this.props.projectStatusFilter ? (
													<span
														className="status-color"
														style={{backgroundColor: Util.getProjectStatusColorHex(option.value)}}
													/>
												) : null}
												<span>{option.label}</span>
											</div>
										)
								)
							) : (
								<div className={'dropdown-empty-text'}>
									{this.props.emptyText
										? this.props.emptyText
										: this.props.intl.formatMessage({id: 'common.none'})}
								</div>
							)}
							{this.props.creatable && this.state.searchCriteria !== '' && options.length === 0 ? (
								<div
									className={'option' + (this.state.marked === 0 ? ' marked' : '')}
									onClick={this.handleCreatableClick.bind(this)}
								>
									<span>
										{this.props.intl.formatMessage({id: 'select_input.create_new'}) +
											this.state.searchCriteria}
									</span>
								</div>
							) : null}
						</CustomScrollDiv>
					</div>
				) : null}
			</div>
		);
	}
}

SelectInput.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object, PropTypes.bool]),
	options: PropTypes.array,
	multi: PropTypes.bool,
	label: PropTypes.string,
	required: PropTypes.bool,
	optional: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	locked: PropTypes.bool,
	clearable: PropTypes.bool,
	hideLabel: PropTypes.bool,
	tableCellSelect: PropTypes.bool,
	placeholder: PropTypes.string,
	creatable: PropTypes.bool,
	onCreatableCallback: PropTypes.func,
	hideValues: PropTypes.bool,
	title: PropTypes.string,
};
export default injectIntl(SelectInput, {forwardRef: true});
