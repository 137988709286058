import {cloneDeep} from 'lodash';
import Util from '../../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

const defaultOptions = (tab, hasIntegrations) => {
	const options = [];
	if (tab === 'overview') {
		options.push(
			{
				name: 'project',
				checked: true,
				translationId: 'common.project',
			},
			{
				name: 'client',
				checked: true,
				translationId: 'common.client',
			}
		);
		options.push({
			name: 'uninvoiced_time_registrations',
			checked: true,
			translationId: 'common.uninvoiced_time_registrations',
		});
		options.push({
			name: 'uninvoiced_fixed_price',
			checked: true,
			translationId: 'common.uninvoiced_fixed_price',
		});
		options.push({
			name: 'uninvoiced_locked_periods',
			checked: true,
			translationId: 'common.uninvoiced_locked_periods',
		});
		options.push({
			name: 'uninvoiced_expenses',
			checked: true,
			translationId: 'common.uninvoiced_expenses',
		});
		options.push({
			name: 'total_uninvoiced_time_material',
			checked: true,
			translationId: 'common.total_uninvoiced_time_material',
		});
		options.push({
			name: 'total_uninvoiced_fixed_price',
			checked: true,
			translationId: 'common.total_uninvoiced_fixed_price',
		});
		options.push({
			name: 'total_uninvoiced_retainer',
			checked: true,
			translationId: 'common.total_uninvoiced_retainer',
		});
		options.push({
			name: 'total_invoiced',
			checked: true,
			translationId: 'common.total_invoiced',
		});
		return options;
	}
	if (tab === 'invoices') {
		options.push(
			{
				name: 'client',
				checked: true,
				translationId: 'common.client',
			},
			{
				name: 'projects',
				checked: true,
				translationId: 'common.projects',
			}
		);
	}
	if (tab === 'invoices' || tab === 'project') {
		options.push(
			{
				name: 'status',
				checked: true,
				translationId: 'common.status',
			},
			{
				name: 'reference',
				checked: false,
				translationId: 'common.reference',
			},
			{
				name: 'invoiceDate',
				checked: true,
				translationId: 'invoicing.invoice_date',
			},
			{
				name: 'total_invoiced',
				checked: true,
				translationId: 'common.total_invoiced',
			},
			{
				name: 'paid',
				checked: true,
				translationId: 'settings_subscription.paid',
			},
			{
				name: 'unpaid',
				checked: true,
				translationId: 'settings_subscription.unpaid',
			},
			{
				name: 'dueDate',
				checked: false,
				translationId: 'common.due_date',
			}
		);
		if (hasIntegrations) {
			options.push({
				name: 'integrationLink',
				checked: true,
				translationId: 'settings_account.section-integrations',
			});

			if (tab === 'invoices' && hasFeatureFlag('invoicing_pathways')) {
				options.push(
					{
						name: 'billTo',
						checked: true,
						translationId: 'filter_type.bill_to',
					},
					{
						name: 'billFrom',
						checked: true,
						translationId: 'filter_type.bill_from',
					}
				);
			}
		}
		return options;
	}
};

const optionVisitor = (option, callback, parentPath) => {
	const path = parentPath ? `${parentPath}.${option.name}` : option.name;

	callback(option, path);
	if (option.nestedOptions) {
		option.nestedOptions.forEach(nestedOption => {
			optionVisitor(nestedOption, callback, path);
		});
	}
};

const optionTraverser = (options, callback) => {
	options.forEach(option => {
		optionVisitor(option, callback, '');
	});
};

export const getInitialOptions = (tab, hasIntegrations) => {
	const options = cloneDeep(defaultOptions(tab, hasIntegrations));
	const locallySavedOptionsString = localStorage.getItem(
		tab === 'project'
			? 'project_invoices_eye_options'
			: tab === 'overview'
			? 'invoice_overview_eye_options'
			: 'invoices_eye_options'
	);
	if (locallySavedOptionsString) {
		const savedOptionsValues = JSON.parse(locallySavedOptionsString);
		if (typeof savedOptionsValues === 'object' && !Array.isArray(savedOptionsValues)) {
			optionTraverser(options, (option, path) => {
				if (savedOptionsValues[path] !== undefined) {
					option.checked = savedOptionsValues[path];
				}
			});
		}
	}

	return options;
};

export const handleChangedOptions = (changedOptions, tab) => {
	const savedOptionValues = {};
	optionTraverser(changedOptions, (option, path) => {
		savedOptionValues[path] = option.checked;
	});

	Util.localStorageSetItem(
		tab === 'project'
			? 'project_invoices_eye_options'
			: tab === 'overview'
			? 'invoice_overview_eye_options'
			: 'invoices_eye_options',
		JSON.stringify(savedOptionValues)
	);
	return changedOptions;
};
