import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {getMomentFromCanvasTimelineDate, ITEM_TYPE} from '../canvas-timeline/canvas_timeline_util';
import DataManager from '../DataManager';
import Util from '../../../forecast-app/shared/util/util';
import {getAllocationData} from '../projects-scheduling/projects_scheduling_allocation_data';

const handleDisplayProjectPhaseCapacity = (pageComponent, item, parentId, childId) => {
	const {intl} = pageComponent.props;
	const {formatMessage} = intl;
	const data = pageComponent.getFilterData();

	const decodedParentId = atob(parentId);
	const isProjectGroupItem = decodedParentId.startsWith('ProjectGroupType') && !childId;
	const isPhaseItem = item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PHASE;
	const isProjectItem = !isProjectGroupItem && item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PROJECT;

	let phase, project, projectGroup;
	if (isPhaseItem) {
		phase = item.data.phase;
		project = DataManager.getProjectById(pageComponent, phase.projectId);
	} else {
		project = item.data.project;
		projectGroup = item.data.projectGroup;
	}

	const allocationData = getAllocationData(pageComponent, item);
	const roleDataMap = isPhaseItem
		? allocationData.phases.find(phaseData => phaseData.id === childId).roleDataMap
		: isProjectItem
		? allocationData.project.roleDataMap
		: allocationData.projectGroup.roleDataMap;

	const roleDataArray = [];
	for (const entry of roleDataMap.entries()) {
		const {allocated, remaining} = entry[1];

		if (allocated || remaining) {
			const role = DataManager.getRoleById(pageComponent, entry[0]);

			roleDataArray.push({
				name: role?.name || formatMessage({id: 'scheduling.project_scheduling.any_role'}),
				allocated: Util.convertMinutesToFullHour(allocated, intl),
				remaining: Util.convertMinutesToFullHour(remaining, intl),
				difference: Util.convertMinutesToFullHour(allocated - remaining, intl),
				isDifferenceNegative: allocated - remaining < 0,
			});
		}
	}

	let statusColor;
	if (isPhaseItem || isProjectItem) {
		statusColor = data.projectStatuses.find(projectStatus => projectStatus.projectId === project.id)?.color;
	}

	let subLabel;
	if (isProjectItem && project.clientId) {
		subLabel = DataManager.getClientById(pageComponent, project.clientId)?.name || '';
	}

	showModal({
		type: MODAL_TYPE.PROJECT_PHASE_CAPACITY,
		roleData: roleDataArray,
		statusColor,
		mainLabel: isProjectItem ? project.name : isPhaseItem ? phase.name : projectGroup.name,
		subLabel,
		projectColor: isProjectItem || isPhaseItem ? project.projectColor : projectGroup.color,
		startDate: getMomentFromCanvasTimelineDate(item.startDate),
		endDate: getMomentFromCanvasTimelineDate(item.endDate),
		projectId: isProjectGroupItem ? projectGroup.companyProjectGroupId : project.companyProjectId,
		customProjectId: isProjectGroupItem ? undefined : project.customProjectId,
		isProjectGroup: isProjectGroupItem,
		isPhase: isPhaseItem,
	});
};

export default handleDisplayProjectPhaseCapacity;
