import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import ComponentRelayRenderer from '../insights/component_relay_renderer';
import ComponentPlaceholder from '../insights/component_placeholder';
import NumberOfTasksComponent, {numberOfTasksComponentQuery} from './components/number_of_tasks_component';
import NumberOfTasksDummy from './components/number_of_tasks_dummy';
import SprintsCompletedComponent, {sprintsCompletedComponentQuery} from './components/sprints_completed_component';
import SprintsCompletedDummy from './components/sprints_completed_dummy';
import OverallVelocityComponent, {overallVelocityComponentQuery} from './components/overall_velocity_component';
import SprintVelocityComponent, {sprintVelocityComponentQuery} from './components/sprint_velocity_component';
import SprintVelocityDummy from './components/sprint_velocity_dummy';
import PlannedVsSpentComponent, {plannedVsSpentComponentQuery} from './components/planned_vs_spent_component';
import PlannedVsSpentDummy from './components/planned_vs_spent_dummy';
import BurnUpComponent, {burnUpComponentQuery} from './components/burn_up_component';
import BurnUpDummy from './components/burn_up_dummy';
import CardListComponent, {cardListComponentQuery} from './components/card_list_component';
import CardListDummy from './components/card_list_dummy';
import CardListPortfolioComponent, {cardListPortfolioComponentQuery} from './components/card_list_portfolio_component';
import CardListPortfolioDummy from './components/card_list_portfolio_dummy';
import SprintListComponent, {sprintListComponentQuery} from './components/sprint_list_component';
import SprintListDummy from './components/sprint_list_dummy';
import ReportedTimeComponent, {reportedTimeComponentQuery} from './components/reported_time_component';
import ReportedTimeDummy from './components/reported_time_dummy';
import PeopleListPortfolioComponent, {peopleListPortfolioComponentQuery} from './components/people_list_portfolio_component';
import PeopleListPortfolioDummy from './components/people_list_portfolio_dummy';
import PeopleListProjectsComponent, {peopleListProjectsComponentQuery} from './components/people_list_projects_component';
import PeopleListProjectsDummy from './components/people_list_projects_dummy';
import ClientListComponent, {clientListComponentQuery} from './components/client_list_component';
import ClientListDummy from './components/client_list_dummy';
import WorkflowStatusComponent, {workflowStatusComponentQuery} from './components/workflow_status_component';
import UtilizationListComponent, {utilizationListComponentQuery} from './components/utilization_list_component';
import UtilizationListDummy from './components/utilization_list_dummy';
import CummulativeFlowDiagramComponent, {
	cummulativeFlowDiagramComponentQuery,
} from './components/cummulative_flow_diagram_component';
import CummulativeFlowDiagramDummy from './components/cummulative_flow_diagram_dummy';
import FreeTextFieldComponent, {freeTextFieldComponentQuery} from './components/free_text_field_component';
import UtilizationbarChartComponent, {utilizationBarChartComponentQuery} from './components/utilization_bar_chart_component';
import UtilizationbarChartDummy from './components/utilization_bar_chart_dummy';
import ProjectTimelineComponent, {projectTimelineComponentQuery} from './components/project_timeline_component';
import SprintBurndownV2Component, {sprintBurndownV2ComponentQuery} from './components/sprint_burndown_v2_component';
import SprintBurndownDummy from './components/sprint_burndown_dummy';
import UtilizationMonthlyComponent, {utilizationMonthlyComponentQuery} from './components/utilization_monthly_component';
import UtilizationMonthlyDummy from './components/utilization_monthly_dummy';
import UtilizationMonthlyColorConfig from './components/utilization_monthly_color_config';
import ExpenseReportProjectComponent, {expenseReportProjectComponentQuery} from './components/expense_report_project_component';
import ExpenseReportProjectDummy from './components/expense_report_project_dummy';
import ExpenseReportPortfolioComponent, {
	expenseReportPortfolioComponentQuery,
} from './components/expense_report_portfolio_component';
import ExpenseReportPortfolioDummy from './components/expense_report_portfolio_dummy';
import CardListConfig from './components/card_list_config';
import IncludeDeactivatedPeopleFilterConfig from './components/include_deactivated_people_filter_config';
import TimeRegisteredPerPersonPortfolio, {
	timeRegisteredPerPersonPortfolioQuery,
} from './components/time_registered_per_person_portfolio';
import TimeRegisteredPerPersonPortfolioDummy from './components/time_registered_per_person_portfolio_dummy';
import Util from '../../forecast-app/shared/util/util';

class InsightsComponent extends Component {
	constructor(props) {
		super(props);

		this.findComponent = this.findComponent.bind(this);
		this.state = {
			canViewFinancial: this.props.canViewFinancial !== false,
			selectedSprint: null,
		};
	}

	/*
        Sets variables based on the component id
    */
	findComponent() {
		const {formatMessage} = this.props.intl;
		this.canConfigure = false;
		this.canUseDatePicker = false;
		this.canUseWeekPicker = false;
		this.isTasksFlowComponent = false;
		this.isBudgetComponent = false;
		this.isProjectBudgetComponent = false;
		this.canUseFilter = false;
		switch (this.props.componentName) {
			case 'cardsDone':
				this.renderFunc = (relayProps, componentProps) => (
					<NumberOfTasksComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.componentDummy = <NumberOfTasksDummy {...this.props} />;
				this.query = numberOfTasksComponentQuery;
				this.componentName = 'insight_number_of_tasks';
				break;
			case 'sprintsCompleted':
				this.renderFunc = (relayProps, componentProps) => (
					<SprintsCompletedComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.componentDummy = <SprintsCompletedDummy {...this.props} />;
				this.query = sprintsCompletedComponentQuery;
				this.componentName = 'insight_sprints_completed';
				break;
			case 'overallVelocity':
				this.renderFunc = (relayProps, componentProps) => (
					<OverallVelocityComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.query = overallVelocityComponentQuery;
				this.componentName = 'insight_overall_velocity';
				break;
			case 'sprintVelocity':
				this.renderFunc = (relayProps, componentProps) => (
					<SprintVelocityComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.componentDummy = <SprintVelocityDummy {...this.props} />;
				this.query = sprintVelocityComponentQuery;
				this.componentName = 'insight_sprint_velocity';
				break;
			case 'burnUp':
				this.renderFunc = (relayProps, componentProps) => (
					<BurnUpComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.componentDummy = <BurnUpDummy {...this.props} />;
				this.query = burnUpComponentQuery;
				this.componentName = 'insight_burn_up';
				break;
			case 'plannedVsSpent':
				this.renderFunc = (relayProps, componentProps) => (
					<PlannedVsSpentComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.componentDummy = <PlannedVsSpentDummy {...this.props} />;
				this.query = plannedVsSpentComponentQuery;
				this.componentName = 'insight_planned_vs_spent';
				break;
			case 'cardList':
				this.columns = [
					'id',
					'name',
					'companyProjectId',
					'projectName',
					'sprint',
					'phase',
					'progress',
					'cardStatus',
					'low',
					'high',
					'forecast',
					'costForecast',
					'reported',
					'remaining',
					'projected',
					'diff',
					'startDate',
					'deadline',
					'assigned',
					'approved',
					'bug',
					'blocked',
					'billable',
					'labels',
				];
				if (!this.state.canViewFinancial) {
					this.columns.splice(
						this.columns.findIndex(col => col === 'costForecast'),
						1
					);
					this.columns.splice(
						this.columns.findIndex(col => col === 'billable'),
						1
					);
				}
				this.renderFunc = (relayProps, componentProps) => (
					<CardListComponent {...this.props} columns={this.columns} {...relayProps} {...componentProps} />
				);
				this.canSelectColumns = true;
				this.canExport = true;
				this.canUseFilter = true;
				this.componentDummy = <CardListDummy {...this.props} />;
				this.backendFilter = <CardListConfig {...this.props} useLabelsFilter={true} />;
				this.query = cardListComponentQuery;
				this.componentName = 'insight_card_list';
				break;
			case 'cardListPortfolio':
				this.columns = [
					'id',
					'name',
					'companyProjectId',
					'projectName',
					'projectStartDate',
					'projectDeadline',
					'projectStage',
					'projectStatusColor',
					'projectStatusDescription',
					'rateCard',
					'client',
					'sprint',
					'sprintStartDate',
					'sprintDeadline',
					'phase',
					'phaseStartDate',
					'phaseDeadline',
					'progress',
					'cardStatus',
					'role',
					'low',
					'high',
					'forecast',
					'reported',
					'remaining',
					'projected',
					'startDate',
					'deadline',
					'assigned',
					'approved',
					'bug',
					'blocked',
					'billable',
					'labels',
				];
				if (!this.state.canViewFinancial) {
					this.columns.splice(
						this.columns.findIndex(col => col === 'rateCard'),
						1
					);
					this.columns.splice(
						this.columns.findIndex(col => col === 'costForecast'),
						1
					);
					this.columns.splice(
						this.columns.findIndex(col => col === 'billable'),
						1
					);
				}
				this.renderFunc = (relayProps, componentProps) => (
					<CardListPortfolioComponent {...this.props} columns={this.columns} {...relayProps} {...componentProps} />
				);
				this.query = cardListPortfolioComponentQuery;
				this.componentName = 'insight_card_list_portfolio';
				this.canSelectColumns = true;
				this.canExport = true;
				this.canUseFilter = true;
				this.componentDummy = <CardListPortfolioDummy {...this.props} />;
				this.backendFilter = (
					<CardListConfig {...this.props} useLabelsFilter={true} useColumnsFilter={true} useClientsFilter={true} />
				);
				break;
			case 'sprintList':
				this.columns = [
					'name',
					'startDate',
					'endDate',
					'percentDone',
					'progress',
					'cardsTodo',
					'cardsInProgress',
					'cardsDone',
					'cardsTotal',
					'forecast',
					'reported',
					'remaining',
					'diff',
				];
				if (this.state.canViewFinancial) {
					this.columns.push('forecastPrice');
					this.columns.push('actualPrice');
				}
				// TODO: Use this when calculation of allocated time on a sprint is available
				// this.columns = ["name", "startDate", "endDate", "percentDone", "progress", "cardsTodo", "cardsInProgress", "cardsDone", "cardsTotal", "forecast", "remaining", "totalAllocated", "remainingAllocated", "hoursDiff"];
				this.renderFunc = (relayProps, componentProps) => (
					<SprintListComponent {...this.props} columns={this.columns} {...relayProps} {...componentProps} />
				);
				this.query = sprintListComponentQuery;
				this.componentName = 'insight_sprint_list';
				this.canSelectColumns = true;
				this.canExport = true;
				this.componentDummy = <SprintListDummy {...this.props} />;
				break;
			case 'reportedTimePortfolio':
			case 'reportedTimePeople':
			case 'reportedTimeBusiness':
			case 'reportedTimeProject':
				const financialCols = ['price', 'cost', 'billable'];
				this.columns =
					this.props.componentName === 'reportedTimeProject' || this.props.componentName === 'reportedTimeBusiness'
						? ['date', 'person', 'department', 'projectName', 'cardName', 'role', 'notes', 'reported', 'client']
						: [
								'date',
								'person',
								'department',
								'projectOrIdleTime',
								'cardOrIdleTime',
								'roleOrIdleTime',
								'notes',
								'reported',
								'client',
						  ];
				if (this.state.canViewFinancial) {
					const pushAtIndex = this.columns.findIndex(col => col === 'reported');
					this.columns.splice(pushAtIndex + 1, 0, ...financialCols);
				}
				this.renderFunc = (relayProps, componentProps) => (
					<ReportedTimeComponent {...this.props} columns={this.columns} {...relayProps} {...componentProps} />
				);
				this.query = reportedTimeComponentQuery;
				this.componentName = 'insight_reported_time';
				this.canSelectColumns = true;
				this.componentDummy = <ReportedTimeDummy {...this.props} />;
				this.canExport = true;
				this.canUseDatePicker = true;
				this.canUseFilter = true;
				if (this.props.componentName !== 'reportedTimePeople') {
					this.backendFilter = <IncludeDeactivatedPeopleFilterConfig {...this.props} />;
				}
				this.backendGroupOptions = [
					{value: 'card', label: formatMessage({id: 'common.card'})},
					{value: 'role', label: formatMessage({id: 'common.role'})},
					{value: 'phase', label: formatMessage({id: 'common.phase'})},
				];
				if (
					(this.props.projectId === null || this.props.projectId === undefined) &&
					this.props.projectGroupId !== null &&
					this.props.projectGroupId !== undefined
				) {
					this.backendGroupOptions.push({value: 'project', label: formatMessage({id: 'common.project'})});
				}
				if (this.props.componentName !== 'reportedTimeProject') {
					this.backendGroupOptions.splice(0, 0, {value: 'project', label: formatMessage({id: 'common.project'})});
				}
				if (this.props.componentName === 'reportedTimePortfolio') {
					this.backendGroupOptions.splice(0, 0, {value: 'person', label: formatMessage({id: 'common.person'})});
				}
				break;
			case 'workflowStatus':
				this.renderFunc = (relayProps, componentProps) => (
					<WorkflowStatusComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.query = workflowStatusComponentQuery;
				this.componentName = 'insight_workflow_status';
				break;
			case 'peopleListPortfolio':
				this.columns = [
					'name',
					'defaultRole',
					'email',
					'department',
					'labels',
					'skills',
					'permission',
					'planningProjects',
					'runningProjects',
					'haltedProjects',
					'doneProjects',
					'totalProjects',
					'todoCards',
					'inProgressCards',
					'doneCards',
					'totalCards',
					'remaining',
					'reported',
				];
				if (this.state.canViewFinancial) {
					this.columns.push('spend');
				}
				this.renderFunc = (relayProps, componentProps) => (
					<PeopleListPortfolioComponent {...this.props} columns={this.columns} {...relayProps} {...componentProps} />
				);
				this.query = peopleListPortfolioComponentQuery;
				this.componentName = 'insight_people_list_portfolio';
				this.canSelectColumns = true;
				this.canExport = true;
				this.componentDummy = <PeopleListPortfolioDummy {...this.props} />;
				this.canUseFilter = true;
				this.backendFilter = <IncludeDeactivatedPeopleFilterConfig {...this.props} />;
				break;
			case 'peopleListProjects':
				this.columns = [
					'name',
					'defaultRole',
					'projectRole',
					'email',
					'department',
					'labels',
					'skills',
					'permission',
					'todoCards',
					'inProgressCards',
					'doneCards',
					'totalCards',
					'remaining',
					'reported',
				];
				if (this.state.canViewFinancial) {
					this.columns.push('spend');
				}
				this.renderFunc = (relayProps, componentProps) => (
					<PeopleListProjectsComponent {...this.props} columns={this.columns} {...relayProps} {...componentProps} />
				);
				this.query = peopleListProjectsComponentQuery;
				this.componentName = 'insight_people_list_project';
				this.canSelectColumns = true;
				this.canExport = true;
				this.canUseFilter = true;
				this.componentDummy = <PeopleListProjectsDummy {...this.props} />;
				this.backendFilter = <IncludeDeactivatedPeopleFilterConfig {...this.props} />;
				break;
			case 'clientList':
				this.columns = this.state.canViewFinancial
					? [
							'name',
							'totalProjects',
							'haltedProjects',
							'planningProjects',
							'runningProjects',
							'doneProjects',
							'spend',
							'budget',
							'registered',
							'forecast',
							'remaining',
							'progress',
							'avgRatePrHourRegistered',
							'avgRatePrHourPlanned',
							'avgRatePrHourFromBudget',
					  ]
					: [
							'name',
							'totalProjects',
							'haltedProjects',
							'planningProjects',
							'runningProjects',
							'doneProjects',
							'registered',
							'forecast',
							'remaining',
							'progress',
					  ];
				this.renderFunc = (relayProps, componentProps) => (
					<ClientListComponent {...this.props} columns={this.columns} {...relayProps} {...componentProps} />
				);
				this.query = clientListComponentQuery;
				this.componentName = 'insight_client_list';
				this.canSelectColumns = true;
				this.canExport = true;
				this.componentDummy = <ClientListDummy {...this.props} />;
				break;
			case 'utilizationList':
				this.columns = [
					'name',
					'role',
					'availableHours',
					'scheduledHours',
					'scheduledPercentage',
					'actualBillableHours',
					'actualBillablePercentage',
					'projectTimeHours',
					'projectTimePercentage',
					'nonProjectTimeHours',
					'nonProjectTimePercentage',
					'registered',
					'billabilityPercentage',
					'billabilityHours',
					//'cardUtilization',
					'overtimeHours',
					'overtimePercentage',
				];
				if (!this.state.canViewFinancial) {
					this.columns.splice(
						this.columns.findIndex(col => col === 'actualBillableHours'),
						2
					);
					this.columns.splice(
						this.columns.findIndex(col => col === 'billabilityPercentage'),
						2
					);
				}
				this.renderFunc = (relayProps, componentProps) => (
					<UtilizationListComponent {...this.props} columns={this.columns} {...relayProps} {...componentProps} />
				);
				this.query = utilizationListComponentQuery;
				this.componentName = 'insight_utilization_list';
				this.canSelectColumns = true;
				this.canExport = true;
				this.canUseDatePicker = true;
				this.componentDummy = <UtilizationListDummy {...this.props} />;
				this.canUseFilter = true;
				this.backendFilter = <IncludeDeactivatedPeopleFilterConfig {...this.props} />;
				break;
			case 'freeTextField':
				this.renderFunc = (relayProps, componentProps) => (
					<FreeTextFieldComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.query = freeTextFieldComponentQuery;
				this.componentName = 'insight_free_text';
				break;
			case 'cummulativeFlow':
				this.renderFunc = (relayProps, componentProps) => (
					<CummulativeFlowDiagramComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.query = cummulativeFlowDiagramComponentQuery;
				this.componentName = 'insight_cummulative_flow_diagram';
				this.componentDummy = <CummulativeFlowDiagramDummy {...this.props} />;
				this.selectOptions = [
					{value: 'count', label: formatMessage({id: 'insights_header_select.cards_count'})},
					{value: 'forecast', label: formatMessage({id: 'insights_header_select.cards_forecast'})},
				];
				this.selectDefaultValue = 'count';
				break;
			case 'utilizationBarChart':
				this.renderFunc = (relayProps, componentProps) => (
					<UtilizationbarChartComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.query = utilizationBarChartComponentQuery;
				this.componentName = 'insight_utilization_bar_chart';
				this.componentDummy = <UtilizationbarChartDummy {...this.props} />;
				this.canUseDatePicker = true;
				this.canUseFilter = true;
				this.backendFilter = <IncludeDeactivatedPeopleFilterConfig {...this.props} />;
				break;
			case 'projectTimeline':
				this.renderFunc = (relayProps, componentProps) => (
					<ProjectTimelineComponent {...this.props} {...relayProps} {...componentProps} />
				);
				this.query = projectTimelineComponentQuery;
				this.componentName = 'insight_project_timeline';
				break;
			case 'sprintBurndownV2':
				this.renderFunc = (relayProps, componentProps) => (
					<SprintBurndownV2Component {...this.props} {...relayProps} {...componentProps} />
				);
				this.query = sprintBurndownV2ComponentQuery;
				this.componentName = 'insight_sprint_burndown_v2';
				this.componentDummy = <SprintBurndownDummy {...this.props} />;
				this.selectOptions = [
					{value: 'remaining-sum', label: formatMessage({id: 'common.remaining'})},
					{value: 'cards-number', label: formatMessage({id: 'insights.component.number.sprintBurndown'})},
					{value: 'forecast-sum', label: formatMessage({id: 'insights.component.size.sprintBurndown'})},
				];
				this.selectDefaultValue = 'remaining-sum';
				break;
			case 'utilizationMonthly':
				this.columns = ['name', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
				this.renderFunc = (relayProps, componentProps) => (
					<UtilizationMonthlyComponent
						{...this.props}
						canViewFinancial={this.state.canViewFinancial}
						columns={this.columns}
						{...relayProps}
						{...componentProps}
					/>
				);
				this.query = utilizationMonthlyComponentQuery;
				this.componentName = 'insight_utilization_monthly';
				this.canSelectColumns = false;
				this.canExport = true;
				this.componentDummy = <UtilizationMonthlyDummy {...this.props} />;
				//this.selectOptions = [{value: 'cards', label: formatMessage({id: 'scheduling.menu.cards'})}, {value: 'allocations', label: formatMessage({id: 'scheduling.menu.allocations'})}];
				this.selectDefaultValue = 'cards';
				this.configContent = <UtilizationMonthlyColorConfig {...this.props} />;
				this.canUseFilter = true;
				this.backendFilter = <IncludeDeactivatedPeopleFilterConfig {...this.props} />;
				break;
			case 'expenseReportProject':
				this.columns = [
					'name',
					'cost',
					'price',
					'markup',
					'quantity',
					'expenseCategory',
					'person',
					'date',
					'billable',
					'notes',
					'createdAt',
					'createdBy',
					'updatedAt',
					'updatedBy',
				];
				this.renderFunc = (relayProps, componentProps) => (
					<ExpenseReportProjectComponent {...this.props} columns={this.columns} {...relayProps} {...componentProps} />
				);
				this.componentDummy = <ExpenseReportProjectDummy {...this.props} />;
				this.query = expenseReportProjectComponentQuery;
				this.componentName = 'insight_expense_report_project';
				this.canSelectColumns = true;
				this.canExport = true;
				break;
			case 'expenseReportPortfolio':
				this.columns = [
					'name',
					'companyProjectId',
					'projectName',
					'client',
					'cost',
					'price',
					'markup',
					'quantity',
					'expenseCategory',
					'person',
					'date',
					'billable',
					'notes',
					'createdAt',
					'createdBy',
					'updatedAt',
					'updatedBy',
				];
				this.renderFunc = (relayProps, componentProps) => (
					<ExpenseReportPortfolioComponent
						{...this.props}
						columns={this.columns}
						{...relayProps}
						{...componentProps}
					/>
				);
				this.componentDummy = <ExpenseReportPortfolioDummy {...this.props} />;
				this.query = expenseReportPortfolioComponentQuery;
				this.componentName = 'insight_expense_report_portfolio';
				this.canSelectColumns = true;
				this.canExport = true;
				break;
			case 'timeRegisteredPerPersonPortfolio':
				this.columns = ['name', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'total'];
				this.renderFunc = (relayProps, componentProps) => (
					<TimeRegisteredPerPersonPortfolio
						{...this.props}
						columns={this.columns}
						{...relayProps}
						{...componentProps}
					/>
				);
				this.componentDummy = <TimeRegisteredPerPersonPortfolioDummy {...this.props} />;
				this.query = timeRegisteredPerPersonPortfolioQuery;
				this.componentName = 'insight_time_per_person_portfolio';
				this.canExport = true;
				this.canSelectColumns = false;
				this.canUseWeekPicker = true;
				this.canUseFilter = true;
				this.backendFilter = <IncludeDeactivatedPeopleFilterConfig {...this.props} />;
				break;
			default:
				break;
		}
	}

	onSprintSelect(selected) {
		this.setState({selectedSprint: selected});
	}

	refresh() {
		if (this.relayRenderer && this.relayRenderer.refresh) {
			this.relayRenderer.refresh();
		}
	}

	verifyAccess() {
		return (
			this.state.canViewFinancial !== false ||
			(this.props.componentName !== 'expenseReportProject' && this.props.componentName !== 'expenseReportPortfolio')
		);
	}

	render() {
		if (this.verifyAccess()) {
			this.findComponent();

			// Check for feature flagged columns here
			if (this.columns && !this.props.shareKey && !Util.hasDepartments()) {
				this.columns = this.columns.filter(col => col.toLowerCase() !== 'department');
			}
			if (this.columns && !this.props.shareKey && !Util.hasSkills()) {
				this.columns = this.columns.filter(col => col.toLowerCase() !== 'skills');
			}
			if (this.columns && !this.props.shareKey) {
				const filterColumns = ['low', 'high'];
				this.columns = this.columns.filter(col => !filterColumns.includes(col.toLowerCase()));
			}

			if (this.props.editMode) {
				return (
					<ComponentPlaceholder
						{...this.props}
						remove={this.props.remove}
						canConfigure={this.canConfigure}
						canSelectColumns={this.canSelectColumns}
						config={this.props.config ? JSON.parse(this.props.config) : {}}
						configContent={this.configContent}
						backendFilter={this.backendFilter}
						columns={this.columns}
						updateComponentConfiguration={this.props.updateComponentConfiguration}
						canViewFinancial={this.state.canViewFinancial}
						currency={this.props.currency}
						sprintOptions={this.props.sprintOptions}
						selectedSprint={this.state.selectedSprint}
						onSprintSelect={this.props.componentName === 'sprintBurndownV2' ? this.onSprintSelect.bind(this) : null}
					>
						{this.componentDummy}
					</ComponentPlaceholder>
				);
			} else {
				return (
					<ComponentRelayRenderer
						{...this.props}
						ref={relayRenderer => {
							if (relayRenderer !== null) this.relayRenderer = relayRenderer;
						}}
						canExport={this.canExport}
						canUseDatePicker={this.canUseDatePicker}
						canUseWeekPicker={this.canUseWeekPicker}
						isTasksFlowComponent={this.isTasksFlowComponent}
						isBudgetComponent={this.isBudgetComponent}
						isProjectBudgetComponent={this.isProjectBudgetComponent}
						canUseFilter={this.canUseFilter}
						canViewFinancial={this.state.canViewFinancial}
						selectOptions={this.selectOptions}
						selectDefaultValue={this.selectDefaultValue || null}
						backendGroupOptions={this.backendGroupOptions}
						initialGroupBy={this.initialGroupBy}
						defaultDateCriteria={this.defaultDateCriteria}
						query={this.query}
						componentQueryName={this.componentName}
						render={this.renderFunc}
					/>
				);
			}
		} else {
			if (this.props.editMode) {
				return (
					<ComponentPlaceholder
						{...this.props}
						remove={this.props.remove}
						canConfigure={this.canConfigure}
						canSelectColumns={this.canSelectColumns}
						config={this.props.config ? JSON.parse(this.props.config) : {}}
						columns={this.columns}
						updateComponentConfiguration={this.props.updateComponentConfiguration}
						canViewFinancial={this.state.canViewFinancial}
						projectOptions={this.props.projectOptions}
						projectGroupOptions={this.props.projectGroupOptions}
						noAccess={true}
					>
						<div className="no-access-layout">
							<h1>
								<FormattedMessage id="insight_component.no_access" />
							</h1>
						</div>
					</ComponentPlaceholder>
				);
			} else {
				return (
					<div className="no-access-layout">
						<h1>
							<FormattedMessage id="insight_component.no_access" />
						</h1>
					</div>
				);
			}
		}
	}
}

InsightsComponent.propTypes = {
	componentName: PropTypes.string.isRequired,
	projectId: PropTypes.string,
	projectGroupId: PropTypes.string,
	personId: PropTypes.string,
	config: PropTypes.string,
	editMode: PropTypes.bool,
	remove: PropTypes.func,
	dateSpan: PropTypes.object,
};

export default injectIntl(InsightsComponent, {forwardRef: true});
