import {Environment, RecordSource, Store} from 'relay-runtime';
import {RelayNetworkLayer, urlMiddleware, loggerMiddleware, retryMiddleware} from 'react-relay-network-modern';
import * as tracking from './tracking';
import LogRocket from 'logrocket';
import DirectApi from './directApi';

const __DEV__ = true;

const RelayEnvironment = (function () {
	let instance;
	let instanceMC;

	function createInstance(isMC) {
		// Another option for disabling the garbage collection
		const store = new Store(new RecordSource());
		//store.__disableGC(); // Found this method in the source code of relay :). Will properly break in newer versions
		store.holdGC(); // Disable GC on the store. (For newer versions)

		const network = new RelayNetworkLayer([
			// Custom middleware to add LogRocket sessionUrl as a header
			next => async req => {
				const logRocketSessionUrl = LogRocket.sessionURL;
				if (
					logRocketSessionUrl !== null &&
					typeof logRocketSessionUrl === 'string' &&
					logRocketSessionUrl.startsWith('http')
				) {
					req.fetchOpts.headers['X-LogRocket-URL'] = logRocketSessionUrl;
				}
				return await next(req);
			},
			// Custom middleware to handle relay file upload -> apollo-upload
			next => async req => {
				const {
					id,
					operation: {text: query},
					variables,
					uploadables,
				} = req;

				if (uploadables) {
					const graphQLMultipart = new FormData();

					const fileMap = [];

					const writeMapFromFileAndMarkVariable = (searchable, parents) => {
						Object.keys(searchable).forEach(key => {
							if (key === 'id') {
								return;
							}
							const currentValue = searchable[key];
							if (
								typeof currentValue === 'object' &&
								(currentValue.constructor === Object || currentValue.constructor === Array)
							) {
								// Recursive
								writeMapFromFileAndMarkVariable(currentValue, [...parents, key]);
							} else {
								// Consider the non-plain objects inside uplodables as File data.

								fileMap.push({
									operationPath: ['variables', ...parents, key].join('.'),
									file: currentValue,
								});

								// Synchronize variable with uploadables.

								let currentDepthVariable = variables;

								parents.forEach(parent => {
									if (!currentDepthVariable[parent]) {
										currentDepthVariable[parent] = {};
									}

									currentDepthVariable = currentDepthVariable[parent];
								});
							}
						});
					};
					writeMapFromFileAndMarkVariable(uploadables, []);

					const operations = {
						id,
						query,
						variables,
					};

					graphQLMultipart.append('operations', JSON.stringify(operations));
					graphQLMultipart.append(
						'map',
						JSON.stringify(
							fileMap.reduce((reducedMap, value, index) => ({...reducedMap, [index]: [value.operationPath]}), {})
						)
					);

					fileMap.forEach((mapValue, index) => {
						graphQLMultipart.append(index, mapValue.file);
					});

					req.fetchOpts.body = graphQLMultipart;
				}
				const res = await next(req);

				return res;
			},
			urlMiddleware({
				url: () => Promise.resolve(isMC ? process.env.GRAPHQL_ENDPOINT : DirectApi.graphqlServerEndpoint('graphql')),
				credentials: 'include',
			}),
			__DEV__ ? loggerMiddleware() : null,
			retryMiddleware({
				fetchTimeout: 120000,
				retryDelays: () => 1000,
				beforeRetry: ({forceRetry, abort, delay, attempt, lastError, req}) => {
					if (attempt > 4) abort(lastError);
					tracking.trackEvent('Relay Retry');
					window.forceRelayRetry = forceRetry;
					// eslint-disable-next-line no-console
					console.log('call `forceRelayRetry()` for immediately retry! Or wait ' + delay + ' ms.');
				},
			}),
		]);

		return new Environment({
			network: network, //Network.create(fetchQuery),
			store: store,
		});
	}

	return {
		getInstance: function () {
			if (!instance) {
				console.log('Creating RelayEnvironment instance');
				instance = createInstance(false);
			}
			return instance;
		},
		getInstanceMC: function () {
			if (!instanceMC) {
				console.log('Creating RelayEnvironment instance for MC');
				instanceMC = createInstance(true);
			}
			return instanceMC;
		},
	};
})();

export default RelayEnvironment;
