import {isFeatureHidden} from '../../../../forecast-app/shared/util/CompanySetupUtil';
import {HIDDEN_FEATURES} from '../../../../constants';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';

export const getPeriodEyeOptions = (isUsingAllocations, isUsingSchedulingPlanMode, isUsingMixedMode) => {
	const eyeOptions = [
		{
			name: 'actualUtilization',
			translationId: 'project_budget.actuals',
			disabled: false,
			checked: true,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'billableProjectHours',
								parent: 'actualUtilization',
								translationId: 'utilization.billable_time',
								checked: true,
								disabled: false,
							},
							{
								name: 'nonBillableProjectHours',
								parent: 'actualUtilization',
								translationId: 'utilization.non_billable_time',
								checked: true,
								disabled: false,
							},
					  ]),
				{
					name: 'internalHours',
					parent: 'actualUtilization',
					translationId: 'common.internal_time',
					checked: true,
					disabled: false,
				},
				{
					name: 'totalRegisteredHours',
					parent: 'actualUtilization',
					translationId: 'utilization.total_time',
					checked: true,
					disabled: false,
				},
				{
					name: 'overHours',
					parent: 'actualUtilization',
					translationId: 'utilization.over_time',
					checked: true,
					disabled: false,
				},
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'actualBillableUtilization',
								parent: 'actualUtilization',
								translationId: 'common.billable_utilization',
								checked: true,
								disabled: false,
							},
							{
								name: 'actualNonBillableUtilization',
								parent: 'actualUtilization',
								translationId: 'common.non_billable_utilization',
								checked: false,
								disabled: false,
							},
					  ]),
				{
					name: 'performance',
					parent: 'actualUtilization',
					translationId: 'common.performance',
					checked: false,
					disabled: false,
				},
				{
					name: 'actualInternalUtilization',
					parent: 'actualUtilization',
					translationId: 'utilization.internal_utilization',
					checked: false,
					disabled: false,
				},
				{
					name: 'actualResourceUtilization',
					parent: 'actualUtilization',
					translationId: 'common.resource_utilization',
					checked: true,
					disabled: false,
				},
			],
		},
		{
			name: 'plannedUtilization',
			translationId: isUsingAllocations ? 'common.planned_allocation_time' : 'common.planned',
			disabled: false,
			checked: isUsingAllocations || isUsingSchedulingPlanMode,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'billableProjectAllocation',
								parent: 'plannedUtilization',
								translationId: 'utilization.billable_time',
								checked: isUsingAllocations || isUsingSchedulingPlanMode,
								disabled: false,
							},
							{
								name: 'nonBillableProjectAllocation',
								parent: 'plannedUtilization',
								translationId: 'utilization.non_billable_time',
								checked: isUsingAllocations || isUsingSchedulingPlanMode,
								disabled: false,
							},
					  ]),
				{
					name: 'internalAllocation',
					parent: 'plannedUtilization',
					translationId: 'common.internal_time',
					checked: isUsingAllocations || isUsingSchedulingPlanMode,
					disabled: false,
				},
				{
					name: 'totalAllocation',
					parent: 'plannedUtilization',
					translationId: 'utilization.total_time',
					checked: isUsingAllocations || isUsingSchedulingPlanMode,
					disabled: false,
				},
				{
					name: 'overAllocation',
					parent: 'plannedUtilization',
					translationId: 'utilization.over_time',
					checked: isUsingAllocations || isUsingSchedulingPlanMode,
					disabled: false,
				},
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'plannedBillableUtilization',
								parent: 'plannedUtilization',
								translationId: 'common.billable_utilization',
								checked: isUsingAllocations || isUsingSchedulingPlanMode,
								disabled: false,
							},
							{
								name: 'plannedNonBillableUtilization',
								parent: 'plannedUtilization',
								translationId: 'common.non_billable_utilization',
								checked: false,
								disabled: false,
							},
					  ]),
				{
					name: 'plannedInternalUtilization',
					parent: 'plannedUtilization',
					translationId: 'utilization.internal_utilization',
					checked: false,
					disabled: false,
				},
				{
					name: 'plannedResourceUtilization',
					parent: 'plannedUtilization',
					translationId: 'common.resource_utilization',
					checked: isUsingAllocations || isUsingSchedulingPlanMode,
					disabled: false,
				},
			],
		},
		{
			name: 'actualVsPlan',
			translationId: isUsingAllocations ? 'utilization.actual_vs_plan_allocation_time' : 'utilization.actual_vs_plan',
			disabled: false,
			checked: false,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'billableActualVsPlan',
								parent: 'actualVsPlan',
								translationId: 'utilization.billable_actual_vs_plan',
								checked: false,
								disabled: false,
							},
							{
								name: 'nonBillableActualVsPlan',
								parent: 'actualVsPlan',
								translationId: 'utilization.non_billable_actual_vs_plan',
								checked: false,
								disabled: false,
							},
					  ]),
				{
					name: 'internalActualVsPlan',
					parent: 'actualVsPlan',
					translationId: 'utilization.internal_actual_vs_plan',
					checked: false,
					disabled: false,
				},
				{
					name: 'totalActualVsPlan',
					parent: 'actualVsPlan',
					translationId: 'utilization.total_actual_vs_plan',
					checked: false,
					disabled: false,
				},
			],
		},
		{
			name: 'remaining',
			translationId: isUsingAllocations ? 'common.remaining_allocation_time' : 'common.remaining',
			disabled: false,
			checked: false,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'billableRemaining',
								parent: 'remaining',
								translationId: 'utilization.billable_time',
								checked: !isUsingAllocations && !isUsingSchedulingPlanMode,
								disabled: false,
							},
							{
								name: 'nonBillableRemaining',
								parent: 'remaining',
								translationId: 'utilization.non_billable_time',
								checked: !isUsingAllocations && !isUsingSchedulingPlanMode,
								disabled: false,
							},
					  ]),
				{
					name: 'internalRemaining',
					parent: 'remaining',
					translationId: 'common.internal_time',
					checked: !isUsingAllocations && !isUsingSchedulingPlanMode,
					disabled: false,
				},
				{
					name: 'totalRemaining',
					parent: 'remaining',
					translationId: 'utilization.total_time',
					checked: !isUsingAllocations && !isUsingSchedulingPlanMode,
					disabled: false,
				},
				{
					name: 'overtimeRemaining',
					parent: 'remaining',
					translationId: 'utilization.over_time',
					checked: false,
					disabled: false,
				},
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'remainingBillableUtilization',
								parent: 'remaining',
								translationId: 'common.billable_utilization',
								checked: !isUsingAllocations && !isUsingSchedulingPlanMode,
								disabled: false,
							},
							{
								name: 'remainingNonBillableUtilization',
								parent: 'remaining',
								translationId: 'common.non_billable_utilization',
								checked: false,
								disabled: false,
							},
					  ]),
				{
					name: 'remainingInternalUtilization',
					parent: 'remaining',
					translationId: 'utilization.internal_utilization',
					checked: false,
					disabled: false,
				},
				{
					name: 'remainingResourceUtilization',
					parent: 'remaining',
					translationId: 'common.resource_utilization',
					checked: !isUsingAllocations && !isUsingSchedulingPlanMode,
					disabled: false,
				},
			],
		},
		{
			name: 'forecast',
			translationId: isUsingAllocations ? 'common.forecasted_allocation_time' : 'common.forecasted',
			disabled: false,
			checked: false,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'billableForecast',
								parent: 'forecast',
								translationId: 'utilization.billable_time',
								checked: false,
								disabled: false,
							},
							{
								name: 'nonBillableForecast',
								parent: 'forecast',
								translationId: 'utilization.non_billable_time',
								checked: false,
								disabled: false,
							},
					  ]),
				{
					name: 'internalForecast',
					parent: 'forecast',
					translationId: 'common.internal_time',
					checked: false,
					disabled: false,
				},
				{
					name: 'totalForecast',
					parent: 'forecast',
					translationId: 'utilization.total_time',
					checked: false,
					disabled: false,
				},
				{
					name: 'overtimeForecast',
					parent: 'forecast',
					translationId: 'utilization.over_time',
					checked: false,
					disabled: false,
				},
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'forecastBillableUtilization',
								parent: 'forecast',
								translationId: 'common.billable_utilization',
								checked: true,
								disabled: false,
							},
							{
								name: 'forecastNonBillableUtilization',
								parent: 'forecast',
								translationId: 'common.non_billable_utilization',
								checked: false,
								disabled: false,
							},
					  ]),
				{
					name: 'forecastInternalUtilization',
					parent: 'forecast',
					translationId: 'utilization.internal_utilization',
					checked: false,
					disabled: false,
				},
				{
					name: 'forecastResourceUtilization',
					parent: 'forecast',
					translationId: 'common.resource_utilization',
					checked: false,
					disabled: false,
				},
			],
		},
		{
			name: 'availability',
			translationId: 'utilization.availability',
			disabled: false,
			checked: true,
			nestedOptions: [
				{
					name: 'workinghours',
					parent: 'availability',
					translationId: 'utilization.working_time',
					checked: false,
					disabled: false,
				},
				{
					name: 'timeOff',
					parent: 'availability',
					translationId: 'common.time_off',
					checked: true,
					disabled: false,
				},
				{
					name: 'availableHours',
					parent: 'availability',
					translationId: 'utilization.available_time',
					checked: true,
					disabled: false,
				},
			],
		},
	];

	if (isUsingAllocations) {
		eyeOptions.push({
			name: 'actualVsPlanTask',
			translationId: 'utilization.actual_vs_plan_task_time',
			disabled: false,
			checked: false,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'billableActualVsPlanTask',
								parent: 'actualVsPlanTask',
								translationId: 'utilization.billable_actual_vs_plan',
								disabled: false,
								checked: false,
							},
							{
								name: 'nonBillableActualVsPlanTask',
								parent: 'actualVsPlanTask',
								translationId: 'utilization.non_billable_actual_vs_plan',
								disabled: false,
								checked: false,
							},
					  ]),
				{
					name: 'totalActualVsPlanTask',
					parent: 'actualVsPlanTask',
					translationId: 'utilization.total_actual_vs_plan',
					disabled: false,
					checked: false,
				},
			],
		});

		eyeOptions.push({
			name: 'plannedTaskUtilization',
			translationId: 'common.planned_task_time',
			disabled: false,
			checked: false,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'billableProjectTasks',
								parent: 'plannedTaskUtilization',
								translationId: 'utilization.billable_time',
								disabled: false,
								checked: false,
							},
							{
								name: 'nonBillableProjectTasks',
								parent: 'plannedTaskUtilization',
								translationId: 'utilization.non_billable_time',
								disabled: false,
								checked: false,
							},
					  ]),
				{
					name: 'internalAllocationTasks',
					parent: 'plannedTaskUtilization',
					translationId: 'common.internal_time',
					checked: false,
					disabled: false,
				},
				{
					name: 'totalTasks',
					parent: 'plannedTaskUtilization',
					translationId: 'utilization.total_time',
					disabled: false,
					checked: false,
				},
				{
					name: 'overTasks',
					parent: 'plannedTaskUtilization',
					translationId: 'utilization.over_time',
					disabled: false,
					checked: false,
				},
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'plannedBillableTasksUtilization',
								parent: 'plannedTaskUtilization',
								translationId: 'common.billable_utilization',
								disabled: false,
								checked: false,
							},
							{
								name: 'plannedNonBillableTasksUtilization',
								parent: 'plannedTaskUtilization',
								translationId: 'common.non_billable_utilization',
								disabled: false,
								checked: false,
							},
					  ]),
				{
					name: 'plannedInternalUtilizationTasks',
					parent: 'plannedUtilization',
					translationId: 'utilization.internal_utilization',
					checked: false,
					disabled: false,
				},
				{
					name: 'plannedResourceTasksUtilization',
					parent: 'plannedTaskUtilization',
					translationId: 'common.resource_utilization',
					disabled: false,
					checked: false,
				},
			],
		});

		eyeOptions.push({
			name: 'remainingTask',
			translationId: 'common.remaining_task_time',
			disabled: false,
			checked: false,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'billableRemainingTasks',
								parent: 'remainingTask',
								translationId: 'utilization.billable_time',
								disabled: false,
								checked: false,
							},
							{
								name: 'nonBillableRemainingTasks',
								parent: 'remainingTask',
								translationId: 'utilization.non_billable_time',
								disabled: false,
								checked: false,
							},
					  ]),
				{
					name: 'internalRemainingTasks',
					parent: 'remainingTask',
					translationId: 'common.internal_time',
					checked: false,
					disabled: false,
				},
				{
					name: 'totalRemainingTasks',
					parent: 'remainingTask',
					translationId: 'utilization.total_time',
					disabled: false,
					checked: false,
				},
				{
					name: 'overtimeRemainingTasks',
					parent: 'remainingTask',
					translationId: 'utilization.over_time',
					disabled: false,
					checked: false,
				},
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'remainingBillableTasksUtilization',
								parent: 'remainingTask',
								translationId: 'common.billable_utilization',
								disabled: false,
								checked: false,
							},
							{
								name: 'remainingNonBillableTasksUtilization',
								parent: 'remainingTask',
								translationId: 'common.non_billable_utilization',
								disabled: false,
								checked: false,
							},
					  ]),
				{
					name: 'remainingInternalUtilizationTasks',
					parent: 'remaining',
					translationId: 'utilization.internal_utilization',
					checked: false,
					disabled: false,
				},
				{
					name: 'remainingResourceTasksUtilization',
					parent: 'remainingTask',
					translationId: 'common.resource_utilization',
					disabled: false,
					checked: false,
				},
			],
		});

		eyeOptions.push({
			name: 'forecastTask',
			translationId: 'common.forecasted_task_time',
			disabled: false,
			checked: false,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'billableForecastTasks',
								parent: 'forecastTask',
								translationId: 'utilization.billable_time',
								disabled: false,
								checked: false,
							},
							{
								name: 'nonBillableForecastTasks',
								parent: 'forecastTask',
								translationId: 'utilization.non_billable_time',
								disabled: false,
								checked: false,
							},
					  ]),
				{
					name: 'internalForecastTasks',
					parent: 'forecastTask',
					translationId: 'common.internal_time',
					checked: false,
					disabled: false,
				},
				{
					name: 'totalForecastTasks',
					parent: 'forecastTask',
					translationId: 'utilization.total_time',
					disabled: false,
					checked: false,
				},
				{
					name: 'overtimeForecastTasks',
					parent: 'forecastTask',
					translationId: 'utilization.over_time',
					disabled: false,
					checked: false,
				},
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'forecastBillableTasksUtilization',
								parent: 'forecastTask',
								translationId: 'common.billable_utilization',
								disabled: false,
								checked: false,
							},
							{
								name: 'forecastNonBillableTasksUtilization',
								parent: 'forecastTask',
								translationId: 'common.non_billable_utilization',
								disabled: false,
								checked: false,
							},
					  ]),
				{
					name: 'forecastInternalUtilizationTasks',
					parent: 'forecast',
					translationId: 'utilization.internal_utilization',
					checked: false,
					disabled: false,
				},
				{
					name: 'forecastResourceTasksUtilization',
					parent: 'forecastTask',
					translationId: 'common.resource_utilization',
					disabled: false,
					checked: false,
				},
			],
		});

		eyeOptions.push({
			name: 'plannedAllocationVsTask',
			translationId: 'utilization.planned_allocation_vs_task',
			disabled: false,
			checked: false,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'plannedBillableAllocationVsTask',
								parent: 'plannedAllocationVsTask',
								translationId: 'utilization.billable_allocation_vs_task',
								checked: false,
								disabled: false,
							},
							{
								name: 'plannedNonBillableAllocationVsTask',
								parent: 'plannedAllocationVsTask',
								translationId: 'utilization.non_billable_allocation_vs_task',
								checked: false,
								disabled: false,
							},
					  ]),
				{
					name: 'plannedTotalAllocationVsTask',
					parent: 'plannedAllocationVsTask',
					translationId: 'utilization.total_allocation_vs_task',
					checked: false,
					disabled: false,
				},
			],
		});

		eyeOptions.push({
			name: 'remainingAllocationVsTask',
			translationId: 'utilization.remaining_allocation_vs_task',
			disabled: false,
			checked: false,
			nestedOptions: [
				...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
					? []
					: [
							{
								name: 'remainingBillableAllocationVsTask',
								parent: 'remainingAllocationVsTask',
								translationId: 'utilization.billable_allocation_vs_task',
								checked: false,
								disabled: false,
							},
							{
								name: 'remainingNonBillableAllocationVsTask',
								parent: 'remainingAllocationVsTask',
								translationId: 'utilization.non_billable_allocation_vs_task',
								checked: false,
								disabled: false,
							},
					  ]),
				{
					name: 'remainingTotalAllocationVsTask',
					parent: 'remainingAllocationVsTask',
					translationId: 'utilization.total_allocation_vs_task',
					checked: false,
					disabled: false,
				},
			],
		});

		if (hasFeatureFlag('combined_heatmap_logic_extensions') && isUsingMixedMode) {
			eyeOptions.push({
				name: 'plannedCombined',
				translationId: 'utilization.planned_combined',
				disabled: false,
				checked: false,
				nestedOptions: [
					...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
						? []
						: [
								{
									name: 'plannedCombinedBillableProjectHours',
									parent: 'plannedCombined',
									translationId: 'utilization.billable_time',
									checked: true,
									disabled: false,
								},
								{
									name: 'plannedCombinedNonBillableProjectHours',
									parent: 'plannedCombined',
									translationId: 'utilization.non_billable_time',
									checked: true,
									disabled: false,
								},
						  ]),
					{
						name: 'plannedCombinedInternalHours',
						parent: 'plannedCombined',
						translationId: 'common.internal_time',
						checked: true,
						disabled: false,
					},
					{
						name: 'plannedCombinedTotalRegisteredHours',
						parent: 'plannedCombined',
						translationId: 'utilization.total_time',
						checked: true,
						disabled: false,
					},
					...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
						? []
						: [
								{
									name: 'plannedCombinedBillableUtilization',
									parent: 'plannedCombined',
									translationId: 'common.billable_utilization',
									checked: true,
									disabled: false,
								},
								{
									name: 'plannedCombinedNonBillableUtilization',
									parent: 'plannedCombined',
									translationId: 'common.non_billable_utilization',
									checked: false,
									disabled: false,
								},
						  ]),
					{
						name: 'plannedCombinedInternalUtilization',
						parent: 'plannedCombined',
						translationId: 'utilization.internal_utilization',
						checked: false,
						disabled: false,
					},
					{
						name: 'plannedCombinedResourceUtilization',
						parent: 'plannedCombined',
						translationId: 'common.resource_utilization',
						checked: true,
						disabled: false,
					},
				],
			});
			eyeOptions.push({
				name: 'remainingCombined',
				translationId: 'utilization.remaining_combined',
				disabled: false,
				checked: false,
				nestedOptions: [
					...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
						? []
						: [
								{
									name: 'remainingCombinedBillableProjectHours',
									parent: 'remainingCombined',
									translationId: 'utilization.billable_time',
									checked: true,
									disabled: false,
								},
								{
									name: 'remainingCombinedNonBillableProjectHours',
									parent: 'remainingCombined',
									translationId: 'utilization.non_billable_time',
									checked: true,
									disabled: false,
								},
						  ]),
					{
						name: 'remainingCombinedInternalHours',
						parent: 'remainingCombined',
						translationId: 'common.internal_time',
						checked: true,
						disabled: false,
					},
					{
						name: 'remainingCombinedTotalRegisteredHours',
						parent: 'remainingCombined',
						translationId: 'utilization.total_time',
						checked: true,
						disabled: false,
					},
					...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
						? []
						: [
								{
									name: 'remainingCombinedBillableUtilization',
									parent: 'remainingCombined',
									translationId: 'common.billable_utilization',
									checked: true,
									disabled: false,
								},
								{
									name: 'remainingCombinedNonBillableUtilization',
									parent: 'remainingCombined',
									translationId: 'common.non_billable_utilization',
									checked: false,
									disabled: false,
								},
						  ]),
					{
						name: 'remainingCombinedInternalUtilization',
						parent: 'remainingCombined',
						translationId: 'utilization.internal_utilization',
						checked: false,
						disabled: false,
					},
					{
						name: 'remainingCombinedResourceUtilization',
						parent: 'remainingCombined',
						translationId: 'common.resource_utilization',
						checked: true,
						disabled: false,
					},
				],
			});
			eyeOptions.push({
				name: 'forecastedCombined',
				translationId: 'utilization.forecasted_combined',
				disabled: false,
				checked: false,
				nestedOptions: [
					...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
						? []
						: [
								{
									name: 'forecastedCombinedBillableProjectHours',
									parent: 'forecastedCombined',
									translationId: 'utilization.billable_time',
									checked: true,
									disabled: false,
								},
								{
									name: 'forecastedCombinedNonBillableProjectHours',
									parent: 'forecastedCombined',
									translationId: 'utilization.non_billable_time',
									checked: true,
									disabled: false,
								},
						  ]),
					{
						name: 'forecastedCombinedInternalHours',
						parent: 'forecastedCombined',
						translationId: 'common.internal_time',
						checked: true,
						disabled: false,
					},
					{
						name: 'forecastedCombinedTotalRegisteredHours',
						parent: 'forecastedCombined',
						translationId: 'utilization.total_time',
						checked: true,
						disabled: false,
					},
					...(isFeatureHidden(HIDDEN_FEATURES.REVENUE)
						? []
						: [
								{
									name: 'forecastedCombinedBillableUtilization',
									parent: 'forecastedCombined',
									translationId: 'common.billable_utilization',
									checked: true,
									disabled: false,
								},
								{
									name: 'forecastedCombinedNonBillableUtilization',
									parent: 'forecastedCombined',
									translationId: 'common.non_billable_utilization',
									checked: false,
									disabled: false,
								},
						  ]),
					{
						name: 'forecastedCombinedInternalUtilization',
						parent: 'forecastedCombined',
						translationId: 'utilization.internal_utilization',
						checked: false,
						disabled: false,
					},
					{
						name: 'forecastedCombinedResourceUtilization',
						parent: 'forecastedCombined',
						translationId: 'common.resource_utilization',
						checked: true,
						disabled: false,
					},
				],
			});
		}
	}

	return eyeOptions;
};
