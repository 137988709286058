import React, {Component} from 'react';
import {Line} from 'react-chartjs-2';
import {defaultTimeDisplayFormat} from './constants';

class ChartJsStackedArea extends Component {
	render() {
		const tooltipSuffix = this.props.tooltipSuffix ? this.props.tooltipSuffix : '';
		const options = {
			scales: {
				y: {
					stacked: true,
					title: {
						display: this.props.yAxesTitle !== null && this.props.yAxesTitle !== undefined,
						text: this.props.yAxesTitle,
					},
					ticks: {
						min: 0,
					},
				},
				x: {
					type: 'time',
					time: {
						displayFormats: {
							hour: defaultTimeDisplayFormat,
							millisecond: defaultTimeDisplayFormat,
						},
					},
					ticks: {
						autoSkip: true,
						maxTicksLimit: this.props.data.labels.length,
					},
				},
			},
			plugins: {
				tooltip: {
					mode: 'index',
					intersect: false,
					callbacks: {
						label: ctx => ctx.dataset.label + ': ' + +parseFloat(ctx.parsed.y).toFixed(2) + tooltipSuffix,
					},
				},
				legend: {
					position: 'bottom',
				},
				datalabels: {
					display: false,
				},
			},
			responsive: true,
			maintainAspectRatio: false,

			elements: {
				point: {
					radius: 0,
					hoverRadius: 0,
				},
			},
		};
		return (
			<div style={{height: this.props.height + 'px'}}>
				{' '}
				<Line options={options} data={this.props.data} />
			</div>
		);
	}
}
export default ChartJsStackedArea;
