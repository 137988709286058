import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import util from '../../../../forecast-app/shared/util/util';
import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';

class TaskTableInputField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFocused: false,
			value: this.props.value,
		};
	}

	// This is an anti-pattern, but whatever, the other solutions didn't work.
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.state.value !== nextProps.value && nextProps.value !== 0) {
			this.setState({value: nextProps.value});
		}
	}

	handleBlur() {
		this.setState({isFocused: false});
		this.props.onBlur();
	}

	handleChange(e) {
		this.setState({value: e.target.value});
		const value = this.props.validateHours ? util.getMinuteEstimate(this._input_field_.value) : this._input_field_.value;
		if (value > 2147483646 || value < -2147483646) {
			return;
		}

		if (this.props.onChange) {
			this.props.onChange(e.target.value);
		}
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			this.setState({isFocused: false});
			this._input_field_.blur();
		}
	}

	focusInputField() {
		this.setState({isFocused: true});
		if (this._input_field_) {
			this._input_field_.focus();
		}
	}

	render() {
		return (
			<div
				className={
					'task-table-input-field no-drag' +
					(this.props.invalidInput ? ' invalid' : '') +
					(this.props.alignText ? ' align-' + this.props.alignText : '') +
					(this.state.isFocused ? ' focused' : '') +
					(this.props.disabled ? ' disabled' : '')
				}
			>
				<TooltipContainer
					disabled={!this.props.groupedValues}
					infoText={this.props.tooltipMessage}
					autoPlaceOffset={{top: 0, left: 24}}
				>
					<input
						id={this.props.id ? this.props.id : ''}
						data-cy={this.props.cy + '-input'}
						onClick={this.focusInputField.bind(this)}
						onFocus={this.focusInputField.bind(this)}
						onBlur={this.handleBlur.bind(this)} //{this.props.onBlur}
						onKeyPress={this.handleKeyPress.bind(this)}
						invalid={'' + this.props.invalidInput}
						ref={input => (this._input_field_ = input)}
						title={this.props.invalidInput ? this.props.errorMessage : ''}
						type={this.props.type}
						className={'input-value no-drag' + (this.props.groupedValues ? ' grouped-values' : '')}
						disabled={this.props.disabled}
						placeholder={this.props.placeholder || ''}
						onChange={this.handleChange.bind(this)}
						value={this.state.value}
						max={this.props.maxNumber !== undefined ? this.props.maxNumber : 2147483646}
						min={this.props.minNumber !== undefined ? this.props.minNumber : -2147483646}
					/>
				</TooltipContainer>
				<div className="input-value-unit">
					{this.props.groupedValues ? this.props.companyEstimationUnit + '*' : this.props.companyEstimationUnit}
				</div>
			</div>
		);
	}
}
TaskTableInputField.propTypes = {
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	value: PropTypes.number,
	companyEstimationUnit: PropTypes.string,
	groupedValues: PropTypes.bool,
	tooltipMessage: PropTypes.string,
	disabled: PropTypes.bool,
};
export default injectIntl(TaskTableInputField);
