import React, { useMemo, useState } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Alert, ButtonDropdownField, DropdownList, FlexColumn, FlexRow, Modal, SubHeading } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { getNotNullishNodes } from '../../../forecast-app/shared/util/NotNullPredicate';
import BulkUpdateExpenseItemsMutation from '../../../mutations/bulk_update_expense_items_mutation';
import Util from '../../../forecast-app/shared/util/util';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
const ChangeExpenseCategoryModal = ({ closeModal, viewer, selectedRows }) => {
    var _a, _b;
    const { formatMessage } = useIntl();
    const countLockedRows = (selectedRows) => {
        return selectedRows.filter(row => row.locked).length;
    };
    const lockedRowsCount = useMemo(() => countLockedRows(selectedRows), [selectedRows]);
    const categories = getNotNullishNodes((_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.expenseCategories) === null || _b === void 0 ? void 0 : _b.edges).filter(category => !(category === null || category === void 0 ? void 0 : category.disabled));
    const [selectedCategory, setSelectedCategory] = useState();
    const setSelected = (current) => {
        if (current) {
            setSelectedCategory(current);
        }
    };
    const handleBulkItemsCategoryChange = (expenseIds, category) => {
        const onSuccess = () => {
            createToast({ duration: 5000, message: formatMessage({ id: 'expense_category.has-been-updated' }) });
        };
        Util.CommitMutation(BulkUpdateExpenseItemsMutation, {
            ids: expenseIds,
            expenseCategoryId: category.id,
        }, onSuccess);
    };
    const confirmChanges = (selectedCategory) => {
        const expenseIds = selectedRows.filter(row => !row.locked).map(row => row.id);
        if (expenseIds && selectedCategory) {
            handleBulkItemsCategoryChange(expenseIds, selectedCategory);
        }
        closeModal();
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "expense_management.change_expense_category" })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 's' },
                React.createElement(FlexRow, null,
                    React.createElement(SubHeading, null,
                        React.createElement(FormattedMessage, { id: 'expense_management.expense_category' }))),
                React.createElement(FlexRow, null,
                    React.createElement(FormattedMessage, { id: 'expense_management.change_category_assign_long' })),
                React.createElement(FlexRow, null,
                    React.createElement(DropdownList, null,
                        React.createElement(DropdownList.Trigger, null,
                            React.createElement(ButtonDropdownField, { "data-cy": 'select-new-expense-category-dropdown' }, !selectedCategory ? (React.createElement(FormattedMessage, { id: 'expense_management.change_category_assign' })) : (selectedCategory.name))),
                        React.createElement(DropdownList.Content, { align: 'start' }, categories.map(current => current.name && (React.createElement(DropdownList.Item, { onClick: () => setSelected(current) },
                            React.createElement(FlexRow, null, current.name)))))))),
            lockedRowsCount > 0 && (React.createElement(FlexRow, null,
                React.createElement(Alert, { text: formatMessage({ id: 'expense_management.change_expense_category_alert' }, { count: selectedRows.length, lockedCount: lockedRowsCount }), type: Alert.TYPE.INFORMATION })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { disabled: !selectedCategory, onPress: () => {
                    confirmChanges(selectedCategory);
                }, dataCy: 'confirm-new-expense-category' },
                React.createElement(FormattedMessage, { id: 'common.confirm_changes' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export const ChangeExpenseCategoryModalQuery = graphql `
	query ChangeExpenseCategoryModalQuery {
		viewer {
			actualPersonId
			component(name: "ChangeExpenseCategoryModal")
			...ChangeExpenseCategoryModal_viewer
		}
	}
`;
export default createFragmentContainer(ChangeExpenseCategoryModal, {
    viewer: graphql `
		fragment ChangeExpenseCategoryModal_viewer on Viewer {
			id
			company {
				id
				expenseCategories(first: 10000) @connection(key: "Company_expenseCategories", filters: []) {
					edges {
						node {
							id
							name
							disabled
						}
					}
				}
			}
		}
	`,
});
