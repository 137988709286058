import {createFragmentContainer, graphql} from 'react-relay';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Chart} from 'web-components';
import Util from '../../../../../../../forecast-app/shared/util/util';
import {getCurrency} from '../../../util/BudgetUtils';
import {decompressAggregatedData} from '../../../util/DataDecompressUtil';
import {getGradients, getTimeUnit} from '../../../util/ChartsUtil';
import {ChartStyle, ChartTabOption, ChartTabs} from '../../work_and_expenses/Chart.styled';

const InvoicedPaidChart = ({viewer, aggregateLevel, eyeOptionMap}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {project} = viewer;
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const aggregatedFinancialNumbers = decompressAggregatedData(project.aggregatedFinancialNumbers, ['invoiced', 'paid']);

	const data = {
		invoicedChartData: [],
		paidChartData: [],
	};

	aggregatedFinancialNumbers.forEach(financialNumbers => {
		data.invoicedChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.invoicedAccumulated,
		});
		data.paidChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.paidAccumulated,
		});
	});

	const {invoicedChartData, paidChartData} = data;
	const {invoicedGradientFill, invoicedGradientStroke, paidGradientFill, paidGradientStroke} = getGradients();

	const dataSets = [];
	if (eyeOptionMap.invoice) {
		dataSets.push({
			name: formatMessage({id: 'project_budget.invoiced'}),
			color: invoicedGradientStroke,
			filled: true,
			backgroundColor: invoicedGradientFill,
			data: invoicedChartData,
		});
	}
	dataSets.push({
		name: formatMessage({id: 'project_budget.paid'}),
		color: paidGradientStroke,
		filled: true,
		backgroundColor: paidGradientFill,
		data: paidChartData,
	});

	const currencySymbol = Util.GetCurrencySymbol(getCurrency(viewer.company, viewer.project));
	const projectFirstDate = Util.CreateNonUtcMomentDate(
		project.projectFirstDateYear,
		project.projectFirstDateMonth,
		project.projectFirstDateDay
	);
	const projectLastDate = Util.CreateNonUtcMomentDate(
		project.projectLastDateYear,
		project.projectLastDateMonth,
		project.projectLastDateDay
	);

	const modifyChartOptions = options => {
		options.animation = undefined;
		options.scales.x.time.unit = getTimeUnit(projectFirstDate, projectLastDate);
		options.scales.y.ticks.callback = label => Util.getFormattedNumberWithCurrency(currencySymbol, label, intl);
		options.plugins.tooltip.callbacks.label = ctx => {
			const item = ctx.dataset;
			const dataItem = ctx.parsed;
			if (dataItem.customData && dataItem.customData.hideLabel) {
				return '';
			} else {
				const dataSetLabel = item.label ? `${item.label}: ` : '';
				return `${dataSetLabel}${Util.getFormattedNumberWithCurrency(currencySymbol, dataItem.y, intl)}`;
			}
		};
		return options;
	};

	const charts = [
		{
			name: formatMessage({id: `project_budget.invoice`}),
			render: () => <Chart dataSets={dataSets} modifyOptions={modifyChartOptions} />,
		},
	];

	return (
		<>
			<ChartTabs>
				{charts.map((item, index) => {
					return (
						<ChartTabOption
							key={'invoice_chart_' + index}
							active={index === activeTabIndex}
							onClick={() => setActiveTabIndex(index)}
						>
							{item.name}
						</ChartTabOption>
					);
				})}
			</ChartTabs>
			<ChartStyle>{charts[activeTabIndex].render()}</ChartStyle>
		</>
	);
};

const invoiceChartsQuery = graphql`
	query InvoicedPaidChart_Query(
		$projectId: ID
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
	) {
		viewer {
			id
			actualPersonId
			component(name: "project_budget_v3_invoice_charts")
			...InvoicedPaidChart_viewer
				@arguments(
					projectId: $projectId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export {invoiceChartsQuery};

export default createFragmentContainer(InvoicedPaidChart, {
	viewer: graphql`
		fragment InvoicedPaidChart_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
		) {
			company {
				currency
			}
			project(internalId: $projectId) {
				projectFirstDateYear
				projectFirstDateMonth
				projectFirstDateDay
				projectLastDateYear
				projectLastDateMonth
				projectLastDateDay
				rateCard {
					currency
				}
				estimationUnit
				budget
				budgetType
				aggregatedFinancialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregates: [invoiced, paid]
					aggregateLevel: DAY
					addAccumulatedNumbers: true
					convertToProjectCurrency: true
				)
			}
		}
	`,
});
