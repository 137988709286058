import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {GROUP_BY, PROJECT_PORTFOLIO_REPORT_PAGES} from '../ProjectPortfolioReport';
import {exportToCsv} from '../ProjectPortfolioReportCsvDownload';

const ProjectPortfolioMonthByMonthProgramDownload = ({
	viewer,
	closeModal,
	startDate,
	endDate,
	boolEyeOptions,
	selectedSingleValue,
}) => {
	const intl = useIntl();
	const currency = viewer.company.currency;
	const programs = viewer.company.financialBasedPrograms.edges.map(edge => edge.node);

	useEffect(() => {
		if (viewer) {
			exportToCsv({
				rows: programs,
				grouping: GROUP_BY.PROGRAM,
				page: PROJECT_PORTFOLIO_REPORT_PAGES.MONTH_BY_MONTH,
				currency,
				boolEyeOptions,
				startDate,
				endDate,
				intl,
				selectedSingleValue,
			});
			closeModal();
		}
	}, [viewer]);

	return null;
};

export const projectPortfolioMonthByMonthProgramDownloadQuery = graphql`
	query ProjectPortfolioMonthByMonthProgramDownload_Query(
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
		$searchQuery: TaskSearchQueryType!
		$timeSearchQuery: TaskSearchQueryType!
		$expenseSearchQuery: TaskSearchQueryType!
		$selectedSingleValue: FinancialAggregatedNumber!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_month_by_month_program_csv_loader")
			...ProjectPortfolioMonthByMonthProgramDownload_viewer
				@arguments(
					searchQuery: $searchQuery
					timeSearchQuery: $timeSearchQuery
					expenseSearchQuery: $expenseSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					selectedSingleValue: $selectedSingleValue
				)
		}
	}
`;

export default createFragmentContainer(ProjectPortfolioMonthByMonthProgramDownload, {
	viewer: graphql`
		fragment ProjectPortfolioMonthByMonthProgramDownload_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType!"}
			timeSearchQuery: {type: "TaskSearchQueryType!"}
			expenseSearchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
			selectedSingleValue: {type: "FinancialAggregatedNumber!"}
		) {
			id
			company {
				currency
				financialBasedPrograms(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: MONTH
					aggregates: [$selectedSingleValue]
				) {
					edges {
						node {
							id
							name
							color
							aggregatedFinancialNumbers(
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								aggregateLevel: MONTH
								aggregates: [$selectedSingleValue]
							)
							timeAggregatedFinancialNumbers: aggregatedFinancialNumbers(
								searchQuery: $timeSearchQuery
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								aggregateLevel: MONTH
								aggregates: [$selectedSingleValue]
								fetchOwnData: true
							)
							expenseAggregatedFinancialNumbers: aggregatedFinancialNumbers(
								searchQuery: $expenseSearchQuery
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								aggregateLevel: MONTH
								aggregates: [$selectedSingleValue]
								fetchOwnData: true
							)
						}
					}
				}
			}
		}
	`,
});
