/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type Details_company$ref: FragmentReference;
declare export opaque type Details_company$fragmentType: Details_company$ref;
export type Details_company = {|
  +id: string,
  +isUsingProjectAllocation: ?boolean,
  +$refType: Details_company$ref,
|};
export type Details_company$data = Details_company;
export type Details_company$key = {
  +$data?: Details_company$data,
  +$fragmentRefs: Details_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Details_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUsingProjectAllocation",
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = 'b012cccfdfd58b5bebc8f073a4ec1d2b';

module.exports = node;
