import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';
import {LoadMore} from '../../../../loaders/LoadMore';
import TimeTotalsTableRow from './TimeTotalsTableRow';
import getAggregateRowTitle from '../util/UseAggregateIntl';
import {BudgetEyeOptions} from '../BudgetPage.EyeOptions';
import TimeTotalsLoader, {timeTotalsLoaderQuery} from '../loaders/TimeTotalsLoader';
import {TableSectionWrapper, TableWrapper} from '../money/work_and_expenses/TableSection.styled';
import {getProjectDatesOrDefault} from '../util/ChartsUtil';
import {BudgetTooltips} from '../util/BudgetTooltips';
import {BUDGET_VIEWS} from '../../../../../constants';
import {useRemappingFormatMessage} from '../../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';

const TimeTotals = ({project, eyeOptionMap}) => {
	const intl = useIntl();
	const formatMessage = useRemappingFormatMessage();
	const {
		projectFirstDateYear,
		projectFirstDateMonth,
		projectFirstDateDay,
		projectLastDateYear,
		projectLastDateMonth,
		projectLastDateDay,
	} = getProjectDatesOrDefault(project);
	const settings = project.financialSourceSettings;
	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');

	return (
		<>
			<TableSectionWrapper>
				<h3>{formatMessage({id: 'common.totals'})}</h3>
				<TableWrapper>
					<Table paddedCells cy="budget-time-totals">
						<Table.Header>
							<Table.HeaderColumn visible flex={1} width="SMALL" />
							<Table.HeaderColumn
								key={'baselineMinutes'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.BASELINE]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.baseline'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'scopeApprovedMinutes'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.PLAN]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.plan'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'registeredMinutes'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.ACTUAL]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.actual'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'forecastTimeToComplete'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.REMAINING]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({
										id: 'project_budget.forecast_to_complete',
									})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'totalTimeAtCompletion'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.FORECAST]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({
										id: 'project_budget.total_at_completion',
									})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'baselineVsRegisteredMinutes'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.BASELINE_VS_ACTUAL]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.baseline_vs_actual'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'baselineVsTotalTimeAtCompletion'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.BASELINE_VS_FORECAST]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.baseline_vs_forecast'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'scopeApprovedVsRegisteredMinutes'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.PLAN_VS_ACTUAL]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.plan_vs_actual'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
							<Table.HeaderColumn
								key={'scopeApprovedVsTotalTimeAtCompletion'}
								usePadding
								align="right"
								visible={eyeOptionMap[BudgetEyeOptions.PLAN_VS_FORECAST]}
								width="MEDIUM"
							>
								<Table.HeaderColumn.Title>
									{formatMessage({id: 'project_budget.plan_vs_forecast'})}
								</Table.HeaderColumn.Title>
							</Table.HeaderColumn>
						</Table.Header>

						<LoadMore
							key="time-query-render-totals"
							query={timeTotalsLoaderQuery}
							loader={<TimeTotalsLoader />}
							variables={{
								projectId: project.id,
								startYear: projectFirstDateYear,
								startMonth: projectFirstDateMonth,
								startDay: projectFirstDateDay,
								endYear: projectLastDateYear,
								endMonth: projectLastDateMonth,
								endDay: projectLastDateDay,
							}}
						>
							{({data: totalsData}) => {
								return (
									<Table.Rows data={totalsData} canExpand={false}>
										{({rowData, tableColumnsProps}) => {
											return (
												<TimeTotalsTableRow
													rowData={rowData}
													tableColumnProps={tableColumnsProps}
													headerColumn={
														<Table.Column>
															{getAggregateRowTitle(intl, rowData.rowType)}
														</Table.Column>
													}
													tooltips={BudgetTooltips.TotalsTooltips(
														formatMessage,
														BUDGET_VIEWS.TIME,
														null,
														settings,
														undefined,
														undefined,
														hasFinancialCategoriesUpdate
													)}
												/>
											);
										}}
									</Table.Rows>
								);
							}}
						</LoadMore>
					</Table>
				</TableWrapper>
			</TableSectionWrapper>
		</>
	);
};

export default createFragmentContainer(TimeTotals, {
	project: graphql`
		fragment TimeTotals_project on ProjectType {
			id
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
			financialSourceSettings {
				plannedHours
				plannedRevenue
				plannedCost
				forecastHours
				forecastRevenue
				forecastCost
				actualHours
				actualRevenue
				actualCost
			}
		}
	`,
});
