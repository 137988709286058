import {isEqual} from 'lodash';
import {fetchNotificationData} from './people_scheduling_fetch';
import {dispatch, EVENT_ID} from '../../../containers/event_manager';
import {visitGroups} from '../canvas-timeline/canvas_timeline_visitor';
import {GROUP_TYPE, ITEM_TYPE} from '../canvas-timeline/canvas_timeline_util';
import {recalculateGroupHeatmapCache} from '../heatmap/HeatmapLogic';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import ComposeManager from '../ComposeManager';
import TaskItem from '../components/items/task_item';
import ProjectGroup from '../components/groups/project_group';

const eventTypesToHandle = [
	'TASK',
	'SUB_TASK',
	'TIME_REG',
	'PHASE',
	'PROJECT_PERSON',
	'PERSON',
	'PROJECT',
	'IDLE_TIME',
	'ALLOCATION',
];

const isCompanyUsingProjectAllocations = pageComponent => {
	const {
		company: {isUsingProjectAllocation},
	} = pageComponent.getData();
	return isUsingProjectAllocation;
};

const processData = (data, updatedData) => {
	const projectByProjectId = {};
	const addedProjects = [];
	const addedProjectPersons = [];
	const taskById = {};
	const addedTasks = [];
	const updatedTasks = [];
	const oldTasksBeforeUpdating = {};
	const deletedTaskIds = [];
	const allocationById = {};
	const addedAllocations = [];
	const updatedAllocations = [];
	const deletedAllocationIds = [];
	const updatedHeatMaps = [];

	if (updatedData.projects.length > 0) {
		updatedData.projects.forEach(updatedProject => {
			const project = data.projects.find(project => project.id === updatedProject.id);
			if (project) {
				Object.assign(project, updatedProject);
			} else {
				addedProjects.push(updatedProject);
				data.projects.push(updatedProject);
			}

			projectByProjectId[updatedProject.id] = updatedProject;
		});
	}

	if (updatedData.projectPersons.length > 0) {
		updatedData.projectPersons.forEach(updatedProjectPerson => {
			const projectPerson = data.projectPersons.find(projectPerson => projectPerson.id === updatedProjectPerson.id);
			if (projectPerson) {
				Object.assign(projectPerson, updatedProjectPerson);
			} else {
				addedProjectPersons.push(updatedProjectPerson);
				data.projectPersons.push(updatedProjectPerson);
			}
		});
	}

	if (updatedData.tasks.length > 0) {
		// Go through tasks to find added/updated tasks
		updatedData.tasks.forEach(updatedTask => {
			const task = data.tasks.find(task => task.id === updatedTask.id);
			if (task) {
				oldTasksBeforeUpdating[task.id] = JSON.parse(JSON.stringify(task));
				Object.assign(task, updatedTask);
				updatedTasks.push(task);
			} else {
				addedTasks.push(updatedTask);
				data.tasks.push(updatedTask);
			}

			taskById[updatedTask.id] = updatedTask;
		});

		// Find deleted tasks
		deletedTaskIds.push(
			...data.tasks
				.filter(existingTask => projectByProjectId[existingTask.projectId])
				.filter(existingTask => !taskById[existingTask.id])
				.map(existingTask => existingTask.id)
		);
	}

	if (updatedData.allocations.length > 0) {
		// Go through allocations to find added/updated allocations
		updatedData.allocations.forEach(updatedAllocation => {
			const allocation = data.allocations.find(allocation => allocation.id === updatedAllocation.id);
			if (allocation) {
				Object.assign(allocation, updatedAllocation);
				updatedAllocations.push(allocation);
			} else {
				addedAllocations.push(updatedAllocation);
				data.allocations.push(updatedAllocation);
			}

			allocationById[updatedAllocation.id] = updatedAllocation;
		});

		// Find deleted allocations
		deletedAllocationIds.push(
			...data.allocations
				.filter(existingAllocation => !allocationById[existingAllocation.id])
				.map(existingAllocation => existingAllocation.id)
		);
	}

	if (updatedData.noAccessHeatMaps) {
		Object.keys(updatedData.noAccessHeatMaps).forEach(personId => {
			const updatedPersonHeatMaps = updatedData.noAccessHeatMaps[personId];
			const existingPersonHeatMaps = data.noAccessHeatMaps[personId];

			if (!isEqual(updatedPersonHeatMaps, existingPersonHeatMaps)) {
				updatedHeatMaps.push({
					personId,
					heatMaps: updatedPersonHeatMaps,
				});
			}
		});
	}

	return {
		projectByProjectId,
		addedProjects,
		addedProjectPersons,
		taskById,
		addedTasks,
		updatedTasks,
		oldTasksBeforeUpdating,
		deletedTaskIds,
		allocationById,
		addedAllocations,
		updatedAllocations,
		deletedAllocationIds,
		updatedHeatMaps,
	};
};

const processGroups = (pageComponent, data, processedData) => {
	const {addedProjectPersons, projectByProjectId} = processedData;

	const existingProjectGroupIds = [];

	visitGroups(pageComponent.state.groups, group => {
		if (group.groupType === GROUP_TYPE.PROJECT) {
			const match = group.id.match(/^[a-zA-Z0-9=]+-([a-zA-Z0-9=]+)$/);
			if (match) {
				const projectId = match[1];
				const updatedProject = projectByProjectId[projectId];
				if (updatedProject) {
					updatedProject.client = data.clients.find(client => client.id === updatedProject.clientId);
					group.refreshData(updatedProject);
					existingProjectGroupIds.push(group.id);
				}
			}
		} else if (group.groupType === GROUP_TYPE.PEOPLE_SCHEDULING_TASK) {
			const match = group.id.match(/^[a-zA-Z0-9=]+-([a-zA-Z0-9=]+)-task$/);
			if (match) {
				const projectId = match[1];
				const updatedProject = projectByProjectId[projectId];
				if (updatedProject) {
					group.refreshData(updatedProject);
				}
			}
		}
	});

	// Go through the added project person and make sure that they have a corresponding project group
	addedProjectPersons.forEach(addedProjectPerson => {
		const groupId = `${addedProjectPerson.personId}-${addedProjectPerson.projectId}`;

		// If group doesn't exist then create it
		if (existingProjectGroupIds.indexOf(groupId) === -1) {
			const personGroup = pageComponent.state.groups.find(
				group => group.groupType === GROUP_TYPE.PERSON && group.id === addedProjectPerson.personId
			);
			const person = data.persons.find(person => person.id === addedProjectPerson.personId);
			const project = data.projects.find(project => project.id === addedProjectPerson.projectId);

			if (personGroup && person && project) {
				const projectGroup = new ProjectGroup(
					pageComponent,
					ComposeManager.composeProjectGroup(pageComponent, person, null, project, null)
				);
				personGroup.addChildGroup(projectGroup);
			}
		}
	});
};

const deleteHeatMapCacheForPerson = (pageComponent, personId) => {
	recalculateGroupHeatmapCache(pageComponent, personId);
};

const deleteHeatMapCacheForRole = (pageComponent, roleId) => {
	recalculateGroupHeatmapCache(pageComponent, roleId);
};

const deleteHeatMapCacheForTask = (pageComponent, task) => {
	task.assignedPersons.forEach(assignedPersonId => {
		deleteHeatMapCacheForPerson(pageComponent, assignedPersonId);
	});
	deleteHeatMapCacheForRole(pageComponent, task.roleId);
};

const deleteHeatMapCacheForAllocation = (pageComponent, allocation) => {
	deleteHeatMapCacheForPerson(pageComponent, allocation.personId);
};

const processAddedTasks = (pageComponent, processedData) => {
	const {addedTasks} = processedData;
	addedTasks.forEach(addedTask => {
		if (addedTask.assignedPersons.length) {
			pageComponent.clearCachedUnassignedTasksCount();
			for (const personId of addedTask.assignedPersons) {
				const taskItemData = ComposeManager.composeTaskItem(pageComponent, addedTask, personId);
				if (!taskItemData) continue;
				const taskItem = new TaskItem(pageComponent, taskItemData);
				pageComponent.state.items.push(taskItem);
			}
		} else {
			const taskItemData = ComposeManager.composeTaskItem(pageComponent, addedTask, null);
			if (taskItemData) {
				const taskItem = new TaskItem(pageComponent, taskItemData);
				pageComponent.state.items.push(taskItem);
			}
		}
		deleteHeatMapCacheForTask(pageComponent, addedTask);
	});
};

const addAllocationItem = (pageComponent, allocation) => {
	const allocationItem = new ProjectAllocationItem(
		pageComponent,
		ComposeManager.composeProjectAllocation(pageComponent, allocation)
	);
	if (allocationItem.isValid()) {
		pageComponent.state.items.push(allocationItem);
	}
};

const processAddedAllocations = (pageComponent, processedData) => {
	const {addedAllocations} = processedData;
	addedAllocations.forEach(addedAllocation => {
		addAllocationItem(pageComponent, addedAllocation);
		deleteHeatMapCacheForAllocation(pageComponent, addedAllocation);
	});
};

const deleteItem = (pageComponent, itemToDelete) => {
	const itemToDeleteIndex = pageComponent.state.items.indexOf(itemToDelete);
	pageComponent.state.items.splice(itemToDeleteIndex, 1);
};

const addTaskItem = (pageComponent, task, personId) => {
	const taskItemData = ComposeManager.composeTaskItem(pageComponent, task, personId);
	if (taskItemData) {
		const taskItem = new TaskItem(pageComponent, taskItemData);
		pageComponent.state.items.push(taskItem);
	}
};

const processUpdatedTasks = (pageComponent, processedData) => {
	const {updatedTasks, oldTasksBeforeUpdating} = processedData;

	updatedTasks.forEach(updatedTask => {
		const taskItems = pageComponent.state.items.filter(
			item => item.itemType === ITEM_TYPE.TASK && item.data.task.id === updatedTask.id
		);
		// Delete all the items first
		taskItems.forEach(taskItem => {
			deleteItem(pageComponent, taskItem);

			const oldTaskBeforeUpdating = oldTasksBeforeUpdating[updatedTask.id];
			if (oldTaskBeforeUpdating) {
				deleteHeatMapCacheForTask(pageComponent, oldTaskBeforeUpdating);
			}
		});

		// Then add them again, based on if they have assignees or not

		// Add items for all assignees if the exist
		if (updatedTask.assignedPersons.length > 0) {
			updatedTask.assignedPersons.forEach(assignedPersonId => {
				addTaskItem(pageComponent, updatedTask, assignedPersonId);
			});
		}
		// If task has no assignees, then add an unassigned item
		else {
			addTaskItem(pageComponent, updatedTask, null);
		}

		deleteHeatMapCacheForTask(pageComponent, updatedTask);
	});
};

const processUpdatedAllocations = (pageComponent, processedData) => {
	const {updatedAllocations} = processedData;

	updatedAllocations.forEach(updatedAllocation => {
		const allocationItems = pageComponent.state.items.filter(
			item => item.itemType === ITEM_TYPE.PROJECT_ALLOCATION && item.data.allocation.id === updatedAllocation.id
		);
		// Delete all the items first
		allocationItems.forEach(allocationItem => {
			deleteItem(pageComponent, allocationItem);
			deleteHeatMapCacheForAllocation(pageComponent, allocationItem.data.allocation);
		});

		// Add allocation again
		addAllocationItem(pageComponent, updatedAllocation);

		deleteHeatMapCacheForAllocation(pageComponent, updatedAllocation);
	});
};

const processDeletedTasks = (pageComponent, processedData) => {
	const {deletedTaskIds} = processedData;
	deletedTaskIds.forEach(deletedTaskId => {
		const itemToDelete = pageComponent.state.items.find(
			item => item.itemType === ITEM_TYPE.TASK && item.data.task.id === deletedTaskId
		);
		if (itemToDelete) {
			deleteItem(pageComponent, itemToDelete);
		}
		const taskToDelete = pageComponent.getData().tasks.find(task => task.id === deletedTaskId);
		if (taskToDelete) {
			const taskToDeleteIndex = pageComponent.getData().tasks.indexOf(taskToDelete);
			pageComponent.getData().tasks.splice(taskToDeleteIndex, 1);

			deleteHeatMapCacheForTask(pageComponent, taskToDelete);
		}
	});
};

const processDeletedAllocations = (pageComponent, processedData) => {
	const {deletedAllocationIds} = processedData;
	deletedAllocationIds.forEach(deletedAllocationId => {
		const itemToDelete = pageComponent.state.items.find(
			item => item.itemType === ITEM_TYPE.PROJECT_ALLOCATION && item.data.allocation.id === deletedAllocationId
		);
		if (itemToDelete) {
			deleteItem(pageComponent, itemToDelete);
		}
		const allocationToDelete = pageComponent
			.getData()
			.allocations.find(allocation => allocation.id === deletedAllocationId);
		if (allocationToDelete) {
			const allocationToDeleteIndex = pageComponent.getData().allocations.indexOf(allocationToDelete);
			pageComponent.getData().allocations.splice(allocationToDeleteIndex, 1);

			deleteHeatMapCacheForAllocation(pageComponent, allocationToDelete);
		}
	});
};

const processNoAccessHeatMaps = (pageComponent, processedData) => {
	const {updatedHeatMaps} = processedData;

	updatedHeatMaps.forEach(updatedPersonHeatMaps => {
		const {personId, heatMaps} = updatedPersonHeatMaps;

		const data = pageComponent.getData();
		data.noAccessHeatMaps[personId] = heatMaps;
		recalculateGroupHeatmapCache(pageComponent, personId);
	});
};

// eslint-disable-next-line
const fetchAndProcessData = (pageComponent, projectIdsToUpdate, allocationIdsToUpdate) => {
	fetchNotificationData(projectIdsToUpdate, allocationIdsToUpdate).then(updatedData => {
		const data = pageComponent.getData();
		if (data) {
			// If company is using project allocations, we empty the task array.
			if (isCompanyUsingProjectAllocations(pageComponent)) {
				updatedData.tasks = [];
			}

			const processedData = processData(data, updatedData);
			processGroups(pageComponent, data, processedData);
			processAddedTasks(pageComponent, processedData);
			processAddedAllocations(pageComponent, processedData);
			processUpdatedTasks(pageComponent, processedData);
			processUpdatedAllocations(pageComponent, processedData);
			processDeletedTasks(pageComponent, processedData);
			processDeletedAllocations(pageComponent, processedData);
			processNoAccessHeatMaps(pageComponent, processedData);

			pageComponent.clearCachedUnassignedTasksCount();
		}

		dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, {preventFiltering: false});
	});
};

export const handleNotification = (pageComponent, eventList) => {
	const projectsToUpdate = {};
	const allocationsToUpdate = {};

	// Filter the event types to listen to, so we don't update if there has been updates to task, sub_tasks or time regs, if we are in project allocation mode.
	const filteredEventTypesToHandle = eventTypesToHandle.filter(
		eventTypeToHandle =>
			!(
				isCompanyUsingProjectAllocations(pageComponent) === true &&
				['TASK', 'SUB_TASK', 'TIME_REG'].indexOf(eventTypeToHandle) > -1
			)
	);

	eventList.forEach(event => {
		if (filteredEventTypesToHandle.indexOf(event.eventType) > -1) {
			if (event.projectIds) {
				event.projectIds.forEach(projectId => (projectsToUpdate[projectId] = true));
			} else if (event.allocationIds) {
				event.allocationIds.forEach(allocationId => (allocationsToUpdate[allocationId] = true));
			}
		}
	});

	const projectIdsToUpdate = Object.keys(projectsToUpdate);
	const allocationIdsToUpdate = Object.keys(allocationsToUpdate);

	if (projectIdsToUpdate.length === 0 && allocationIdsToUpdate.length === 0) {
		return;
	}

	// Wait a tiny bit before fetching to ensure everything is updated on the backend
	// setTimeout(() => fetchAndProcessData(pageComponent, projectIdsToUpdate, allocationIdsToUpdate), 500);
};
