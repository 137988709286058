import {getTimestampCorrelationId, trackPerformanceDataLoad} from '../canvas-timeline/canvas_timeline_performance_track';
import DirectApi from '../../../directApi';
import {fetchDataPromises} from '../scheduling_fetch';
import {initializeData, initializeStaffingData, isStaffingGeneratedId} from './CanvasPlaceholdersSchedulingUtil';
import {handleNotification} from './PlaceholderSchedulingSocket';
import {DATE_FORMAT_DAY} from '../../../constants';
import moment from 'moment';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {getFetchDates} from '../loading/LoadMoreUtil';

// DATA PROMISES
const getInitialDataPromises = pageComponent => {
	const req = {method: 'POST'};

	let requestUrl = DirectApi.graphqlServerEndpoint('scheduling/placeholders');
	requestUrl += `?timestampCorrelationId=${getTimestampCorrelationId()}`;

	req.url = requestUrl;

	if (hasFeatureFlag('incremental_load_more_include_items')) {
		req.body = {
			...getFetchDates(pageComponent),
		};
	}

	const requestUrls = [req];
	return fetchDataPromises(requestUrls);
};

export function handleSocketNotify(pageComponent, dataList) {
	handleNotification(pageComponent, dataList);
}

const getStaffingDataPromises = pageComponent => {
	const {staffingInitialDate, staffingPlaceholder, totalMinutesMap, filters} = pageComponent.state;

	const fetchSuggestedPersons = !!totalMinutesMap.get(staffingPlaceholder.id);

	const req = {method: 'POST'};

	let requestUrl = DirectApi.graphqlServerEndpoint('scheduling/placeholders/staffing');
	requestUrl += `?timestampCorrelationId=${getTimestampCorrelationId()}`;

	// timeline current step date for heatmap fetching
	requestUrl += `&timelineYear=${staffingInitialDate.year()}`;
	requestUrl += `&timelineMonth=${staffingInitialDate.month()}`;
	requestUrl += `&timelineDay=${staffingInitialDate.date()}`;
	requestUrl += `&staffingPlaceholderId=${staffingPlaceholder?.id}`;

	if (fetchSuggestedPersons) {
		requestUrl += `&fetchSuggestedPersons=true`;
	}

	req.url = requestUrl;

	let reqBody = {
		filters,
	};

	if (hasFeatureFlag('incremental_load_more_include_items')) {
		const fetchDates = getFetchDates(pageComponent);
		reqBody = {
			...reqBody,
			...fetchDates,
		};
	}

	req.body = reqBody;

	const requestUrls = [req];
	return fetchDataPromises(requestUrls);
};

// FETCH DATA
export function fetchInitialData(pageComponent) {
	trackPerformanceDataLoad(getInitialDataPromises(pageComponent)).then(dataArray => {
		const data = dataArray[0];
		const hasPlaceholders = !!data.placeholders?.length;

		pageComponent.setState({hasPlaceholders}, () => {
			if (hasPlaceholders) {
				initializeData(pageComponent, data);
			} else {
				pageComponent.setState({
					initialDataLoaded: true,
					allDataLoaded: false,
					allDataLoading: false,
				});
			}
		});
	});
}

export const getSuggestedPersonsByFilter = async (pageComponent, filters) => {
	const {staffingModeActive, staffingPlaceholder} = pageComponent.state;

	let suggestedPersonsPromise = Promise.resolve(null);
	if (staffingModeActive && staffingPlaceholder) {
		const placeholderId = staffingPlaceholder.id;

		let requestUrl = 'scheduling/placeholders/staffing/suggested_persons';
		requestUrl += `?timestampCorrelationId=${getTimestampCorrelationId()}`;

		const headers = new Headers();
		headers.append('Content-Type', 'application/json');

		const reqBody = {
			filters,
			placeholderId,
		};

		suggestedPersonsPromise = DirectApi.Fetch_WithErrorHandling(requestUrl, {
			method: 'POST',
			headers,
			credentials: 'include',
			body: JSON.stringify(reqBody),
		}).then(response => response.json());
	}

	return suggestedPersonsPromise;
};

export function fetchStaffingData(pageComponent) {
	const {initialDataLoaded} = pageComponent.state;

	if (!initialDataLoaded) return;

	trackPerformanceDataLoad(getStaffingDataPromises(pageComponent)).then(dataArray => {
		const data = dataArray[0];
		initializeStaffingData(pageComponent, data, false);
	});
}

function dateAsString(date) {
	return typeof date === 'string' ? date : moment(date).format(DATE_FORMAT_DAY);
}

export function saveStaffingData(pageComponent, keepEmptyPlaceholder) {
	const {deletePlaceholderOnSave, placeholderAllocationDeletes, projectAllocationDeletes, staffingPlaceholder} =
		pageComponent.state;

	pageComponent.setState({savingStaffingData: true});

	const placeholderAllocationCreatesAndUpdates = pageComponent.state.placeholderAllocationCreatesAndUpdates.map(
		placeholderAllocation => ({
			...placeholderAllocation,
			id: isStaffingGeneratedId(placeholderAllocation.id) ? undefined : placeholderAllocation.id,
			startDate: dateAsString(placeholderAllocation.startDate),
			endDate: dateAsString(placeholderAllocation.endDate),
			placeholderId: placeholderAllocation.placeholder?.id || placeholderAllocation.placeholderId,
		})
	);
	const projectAllocationCreatesAndUpdates = pageComponent.state.projectAllocationCreatesAndUpdates.map(projectAllocation => {
		return {
			...projectAllocation,
			id: isStaffingGeneratedId(projectAllocation.id) ? undefined : projectAllocation.id,
		};
	});

	const body = {
		placeholderId: staffingPlaceholder.id,
		deletePlaceholderOnSave,
		placeholderAllocationCreatesAndUpdates,
		placeholderAllocationDeletedIds: placeholderAllocationDeletes.map(allocation => allocation.id),
		projectAllocationCreatesAndUpdates,
		projectAllocationDeletedIds: projectAllocationDeletes.map(allocation => allocation.id),
		keepEmptyPlaceholder,
	};

	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	return DirectApi.Fetch_WithErrorHandling('placeholder/staffPlaceholder', {
		method: 'POST',
		credentials: 'include',
		headers,
		body: JSON.stringify(body),
	});
}

const createNotificationDataByProjectRequest = projectIds => {
	const requestUrl = DirectApi.graphqlServerEndpoint('scheduling/notifications/projects');
	const body = {
		projectIds,
	};

	return {url: requestUrl, method: 'POST', body};
};

const createNotificationDataByPlaceholderAllocationRequest = placeholderAllocationIds => {
	const requestUrl = DirectApi.graphqlServerEndpoint('scheduling/notifications/placeholder_allocations');
	const body = {
		placeholderAllocationIds,
	};

	return {url: requestUrl, method: 'POST', body};
};

const createNotificationDataByPlaceholderRequest = placeholderIds => {
	const requestUrl = DirectApi.graphqlServerEndpoint('scheduling/notifications/placeholder');
	const body = {
		placeholderIds,
	};

	return {url: requestUrl, method: 'POST', body};
};

export function fetchNotificationData(projectIds, placeholderAllocationIds, placeholderIds) {
	const requestUrls = [];
	if (projectIds.length > 0) {
		requestUrls.push(createNotificationDataByProjectRequest(projectIds));
	}
	if (placeholderAllocationIds.length > 0) {
		requestUrls.push(createNotificationDataByPlaceholderAllocationRequest(placeholderAllocationIds));
	}
	if (placeholderIds.length > 0) {
		requestUrls.push(createNotificationDataByPlaceholderRequest(placeholderIds));
	}

	return fetchDataPromises(requestUrls).then(results => {
		let returnValue = {
			projects: [],
			projectPersons: [],
			placeholderAllocations: [],
			placeholders: [],
			placeholderSkills: [],
		};

		for (const result of results) {
			returnValue = Object.assign(returnValue, result);
		}

		return returnValue;
	});
}
