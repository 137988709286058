import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ActionMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			actionMenuExpanded: false,
		};
	}

	closeActionMenu() {
		this.setState({actionMenuExpanded: false});
	}

	toggleActionMenu() {
		this.setState({actionMenuExpanded: !this.state.actionMenuExpanded});
	}

	render() {
		return (
			<div
				className={
					'action-menu-container' +
					(this.props.horizontalIcon ? ' horizontal-icon' : ' vertical-icon') +
					(this.props.small ? ' small' : ' regular')
				}
				onBlur={this.closeActionMenu.bind(this)}
				onClick={this.toggleActionMenu.bind(this)}
				tabIndex="0"
				data-cy={this.props.cy}
			>
				{this.props.useAtionLabel ? <span className="action-label">{this.props.actionLabelTitle}</span> : null}
				<div
					className={
						'action-dropdown ' +
						(this.state.actionMenuExpanded ? 'expanded' : 'collapsed') +
						(this.props.showOnTop ? ' show-on-top' : ' show-on-bottom')
					}
				>
					<ul>
						{this.props.options.map((option, index) => (
							<li data-cy={option.buttonCy} key={index} onClick={option.onClick}>
								{option.label}
							</li>
						))}
					</ul>
				</div>
			</div>
		);
	}
}

ActionMenu.propTypes = {
	horizontalIcon: PropTypes.bool,
	small: PropTypes.bool,
	useAtionLabel: PropTypes.bool,
	actionLabelTitle: PropTypes.string,
	showOnTop: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			onClick: PropTypes.func,
		})
	),
};

export default ActionMenu;
