import {FlexItem, DeprecatedMultiColorIndicator as MultiColorIndicator} from '@forecast-it/design-system';
import React from 'react';
import HeaderColumnContainer from './HeaderColumnContainer';
import ProjectPrefixFlexItem from './ProjectPrefixFlexItem';
import ProgramUtil from '../../../../forecast-app/shared/util/ProgramUtil';
import {hasModule} from '../../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../../constants';
import {hasSomePermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {Link} from 'react-router-dom';

const ProgramHeaderColumn = ({program}) => {
	const hasAccessToPrograms =
		hasModule(MODULE_TYPES.PROGRAMS) &&
		hasSomePermission([
			PERMISSION_TYPE.PROJECTS_READ_ALL,
			PERMISSION_TYPE.PROGRAMS_CREATE,
			PERMISSION_TYPE.PROGRAMS_UPDATE,
			PERMISSION_TYPE.PROGRAMS_DELETE,
		]);

	return (
		<HeaderColumnContainer>
			<FlexItem flex="0 0 28px">
				{hasAccessToPrograms ? (
					<Link to={`${ProgramUtil.programLink()}/${program.prefix}/overview`}>
						<MultiColorIndicator size={'s'} color={program.color} />
					</Link>
				) : (
					<MultiColorIndicator size={'s'} color={program.color} />
				)}
			</FlexItem>
			<ProjectPrefixFlexItem>{program.prefix}</ProjectPrefixFlexItem>
			<FlexItem>{program.name}</FlexItem>
		</HeaderColumnContainer>
	);
};

export default ProgramHeaderColumn;
