/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type overallVelocityComponent_viewer$ref: FragmentReference;
declare export opaque type overallVelocityComponent_viewer$fragmentType: overallVelocityComponent_viewer$ref;
export type overallVelocityComponent_viewer = {|
  +insightComponentsData: ?{|
    +overallVelocity: ?{|
      +velocity: ?number
    |}
  |},
  +$refType: overallVelocityComponent_viewer$ref,
|};
export type overallVelocityComponent_viewer$data = overallVelocityComponent_viewer;
export type overallVelocityComponent_viewer$key = {
  +$data?: overallVelocityComponent_viewer$data,
  +$fragmentRefs: overallVelocityComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProjectGroupType",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "overallVelocityComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "isProjectGroupType",
              "variableName": "isProjectGroupType"
            },
            {
              "kind": "Variable",
              "name": "projectId",
              "variableName": "projectId"
            }
          ],
          "concreteType": "OverallVelocity",
          "kind": "LinkedField",
          "name": "overallVelocity",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "velocity",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '8fc18e7504a0717c4aeffc801fd25753';

module.exports = node;
