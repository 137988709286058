import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../../../../css_variables';

export const CollapsibleSectionWrapper = Styled.div`
    margin-top: 26px;
`;

export const TableSectionWrapper = Styled.div`
    padding: 26px;
    margin-top: 26px;
    background-color: ${CSS_CONSTANTS.color_white};
    border: 1px solid #e6e6ed;
    border-radius: 4px;
`;

export const TableWrapper = Styled.div`
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
`;

export const TableSectionTitle = Styled.div`
  display: flex;
  .titleButtons {
    display: flex;
  }
  height: 30px;
`;

export const TableSectionTitleHeadline = Styled.div`
    flex-grow: 1;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #4C4C58;
`;
