import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactGridLayout, {WidthProvider} from 'react-grid-layout';
import InsightsComponent from '../../components/insights/insights_component';
import {injectIntl} from 'react-intl';

const AutoWidthGridLayout = WidthProvider(ReactGridLayout);
class GridLayout extends Component {
	constructor(props) {
		super(props);
		const isComponentReadyMap = new Map();
		this.state = {
			alternativeHeights: [],
			isComponentReadyMap,
			shouldListsRecalculatePosition: false,
		};
	}

	updateComponentHeight(id, height) {
		const alternativeHeights = this.state.alternativeHeights;
		alternativeHeights[id] = height;
		this.setState({alternativeHeights});
	}

	refresh() {
		for (let i = 0; i < this.components.length; i++) {
			this.components[i].refresh();
		}
	}

	notifyOnReady(componentId, updateScroller) {
		const isComponentReadyMap = this.state.isComponentReadyMap;
		isComponentReadyMap.set(componentId, updateScroller);
		let shouldListsRecalculatePosition = true;
		this.props.components.forEach(component => {
			if (shouldListsRecalculatePosition) {
				shouldListsRecalculatePosition = isComponentReadyMap.has(component.id);
			}
		});
		if (shouldListsRecalculatePosition) {
			isComponentReadyMap.forEach((value, key, map) => {
				if (value) {
					value();
				}
			});
		}
		this.setState({isComponentReadyMap});
	}

	recalculateListsPosition() {
		this.setState({shouldListsRecalculatePosition: true});
	}

	render() {
		this.components = [];
		let layout = [];
		for (let i = this.props.components.length - 1; i >= 0; i--) {
			const component = this.props.components[i];
			const id = component.id ? component.id : component.i;
			layout.push({
				i: id,
				componentName: component.componentName,
				x: component.x,
				y: component.y,
				w: component.w,
				h: this.state.alternativeHeights[id] ? this.state.alternativeHeights[id] : component.h,
				config: component.config,
			});
		}
		// Sort layout based on y value, this is important so that the components are put into the grid in the right stack order
		layout.sort((a, b) => {
			return a.y < b.y;
		});
		return (
			<AutoWidthGridLayout
				className="layout"
				layout={layout}
				cols={4}
				rowHeight={200}
				isDraggable={this.props.editMode}
				isResizable={false}
				verticalCompact={true}
				onLayoutChange={this.props.onLayoutChange}
			>
				{layout
					? layout.map(component => (
							<div key={component.i}>
								<InsightsComponent
									id={component.i}
									ref={comp => {
										if (comp !== null) this.components.push(comp);
									}}
									componentName={component.componentName}
									title={this.props.intl.formatMessage({
										id: 'insights.component.title.' + component.componentName,
									})}
									infoText={this.props.intl.formatMessage({
										id: 'insights.component.info.' + component.componentName,
									})}
									height={
										this.state.alternativeHeights[component.i]
											? this.state.alternativeHeights[component.i]
											: component.h
									}
									config={component.config}
									editMode={this.props.editMode}
									projectId={this.props.projectId}
									projectGroupId={this.props.projectGroupId}
									personId={this.props.personId}
									clientId={this.props.clientId}
									remove={this.props.removeComponent}
									updateComponentConfiguration={this.props.updateComponentConfiguration}
									updateComponentHeight={this.updateComponentHeight.bind(this)}
									dateSpan={this.props.dateSpan}
									shareKey={this.props.shareKey}
									showTaskModal={this.props.showTaskModal}
									labelsOptions={this.props.labelsOptions}
									skillsOptions={this.props.skillsOptions}
									clientOptions={this.props.clientOptions}
									contactPersonOptions={this.props.contactPersonOptions}
									rateCardOptions={this.props.rateCardOptions}
									statusColumnsOptions={this.props.statusColumnsOptions}
									clientsOptions={this.props.clientsOptions}
									projectOptions={this.props.projectOptions}
									projectGroupOptions={this.props.projectGroupOptions}
									sprintOptions={this.props.sprintOptions}
									personOptions={this.props.personOptions}
									departmentOptions={this.props.departmentOptions}
									roleOptions={this.props.roleOptions}
									teamOptions={this.props.teamOptions}
									canViewFinancial={this.props.canViewFinancial}
									currency={this.props.currency}
									scrollElement={this.props.scrollElement}
									scrollElementFullScreen={this.props.scrollElementFullScreen}
									notifyOnReady={this.notifyOnReady.bind(this)}
									shouldListsRecalculatePosition={this.state.shouldListsRecalculatePosition}
									recalculateListsPosition={this.recalculateListsPosition.bind(this)}
									xeroEnabled={this.props.xeroEnabled}
									availableFeatureFlags={this.props.availableFeatureFlags}
								/>
							</div>
					  ))
					: null}
			</AutoWidthGridLayout>
		);
	}
}

GridLayout.propTypes = {
	components: PropTypes.array.isRequired,
	editMode: PropTypes.bool.isRequired,
	projectId: PropTypes.string,
	insightId: PropTypes.string,
	dateSpan: PropTypes.object,
};

export default injectIntl(GridLayout, {forwardRef: true});
