import { cloneDeep } from 'lodash';
import { idFromGlobalId } from '../../../forecast-app/shared/util/GlobalIdUtil';
import Util from '../../../forecast-app/shared/util/util';
const formatPhaseBaselineRole = ({ phaseBaselineRole, roleFinancials, currencySymbol, intl, }) => {
    var _a, _b, _c, _d;
    const baselineRatePerHour = (_a = roleFinancials === null || roleFinancials === void 0 ? void 0 : roleFinancials.baselineRatePerHour) !== null && _a !== void 0 ? _a : 0;
    const baselineRevenue = (_b = roleFinancials === null || roleFinancials === void 0 ? void 0 : roleFinancials.baselineRevenue) !== null && _b !== void 0 ? _b : 0;
    return {
        id: phaseBaselineRole.id,
        name: (_d = (_c = phaseBaselineRole.role) === null || _c === void 0 ? void 0 : _c.name) !== null && _d !== void 0 ? _d : '',
        hours: phaseBaselineRole.baselineMinutes ? String(phaseBaselineRole.baselineMinutes / 60) : '0',
        hourlyRate: Util.getFormattedCurrencyValue(currencySymbol, intl.formatNumber(baselineRatePerHour, { format: 'always_two_decimal' })),
        totalPrice: Util.getFormattedCurrencyValue(currencySymbol, intl.formatNumber(baselineRevenue, { format: 'always_two_decimal' })),
    };
};
const formatPhaseBaselineExpense = ({ phaseBaselineExpense, expenseFinancials, currencySymbol, intl, }) => {
    var _a, _b, _c;
    const baselineRevenue = (_a = expenseFinancials === null || expenseFinancials === void 0 ? void 0 : expenseFinancials.baselineRevenue) !== null && _a !== void 0 ? _a : 0;
    return {
        id: phaseBaselineExpense.id,
        name: (_c = `Expense: ${(_b = phaseBaselineExpense.expenseCategory) === null || _b === void 0 ? void 0 : _b.name}`) !== null && _c !== void 0 ? _c : '',
        hours: '-',
        hourlyRate: '-',
        totalPrice: Util.getFormattedCurrencyValue(currencySymbol, intl.formatNumber(baselineRevenue, { format: 'always_two_decimal' })),
    };
};
const formatPhaseBaselineItems = ({ phaseBaselineRoles, phaseBaselineExpenses, phaseFinancialNumbers, currencySymbol, intl, }) => {
    const phaseBaselineRoleItems = phaseBaselineRoles
        ? phaseBaselineRoles.map(phaseBaselineRoleNode => {
            const phaseBaselineRole = phaseBaselineRoleNode.node;
            const phaseBaselineRoleId = idFromGlobalId(phaseBaselineRole.id);
            const roleFinancials = phaseFinancialNumbers === null || phaseFinancialNumbers === void 0 ? void 0 : phaseFinancialNumbers.roles.get(phaseBaselineRoleId);
            return formatPhaseBaselineRole({ phaseBaselineRole, roleFinancials, currencySymbol, intl });
        })
        : [];
    const phaseBaselineExpenseItems = phaseBaselineExpenses
        ? phaseBaselineExpenses.map(phaseBaselineExpenseNode => {
            const phaseBaselineExpense = phaseBaselineExpenseNode.node;
            const phaseBaselineExpenseId = idFromGlobalId(phaseBaselineExpense.id);
            const expenseFinancials = phaseFinancialNumbers === null || phaseFinancialNumbers === void 0 ? void 0 : phaseFinancialNumbers.expenses.get(phaseBaselineExpenseId);
            return formatPhaseBaselineExpense({ phaseBaselineExpense, expenseFinancials, currencySymbol, intl });
        })
        : [];
    return [...phaseBaselineRoleItems, ...phaseBaselineExpenseItems];
};
export const formatPhases = ({ phases, groupedFinancialNumbers, phaseSortOrder, currencySymbol, intl, }) => {
    return cloneDeep(phases)
        .sort((a, b) => phaseSortOrder.indexOf(a.node.id) - phaseSortOrder.indexOf(b.node.id))
        .map(phaseNode => {
        var _a, _b, _c, _d;
        const phase = phaseNode.node;
        const phaseId = idFromGlobalId(phase.id);
        const phaseFinancialNumbers = groupedFinancialNumbers.get(phaseId);
        const phaseTotal = (_a = phaseFinancialNumbers === null || phaseFinancialNumbers === void 0 ? void 0 : phaseFinancialNumbers.totalBaselineRevenue) !== null && _a !== void 0 ? _a : 0;
        return {
            id: phase.id,
            name: (_b = phase.name) !== null && _b !== void 0 ? _b : '',
            total: Util.getFormattedCurrencyValue(currencySymbol, intl.formatNumber(phaseTotal, { format: 'always_two_decimal' })),
            items: formatPhaseBaselineItems({
                phaseBaselineRoles: (_c = phase.phaseBaselineRoles) === null || _c === void 0 ? void 0 : _c.edges,
                phaseBaselineExpenses: (_d = phase.phaseBaselineExpenses) === null || _d === void 0 ? void 0 : _d.edges,
                phaseFinancialNumbers,
                currencySymbol,
                intl,
            }),
        };
    });
};
export const formatProject = ({ project, totalFinancialNumbers, currencySymbol, intl, }) => {
    var _a;
    const projectTotal = (_a = totalFinancialNumbers === null || totalFinancialNumbers === void 0 ? void 0 : totalFinancialNumbers.totalBaselineRevenue) !== null && _a !== void 0 ? _a : 0;
    return {
        name: project.name,
        total: Util.getFormattedCurrencyValue(currencySymbol, intl.formatNumber(projectTotal, { format: 'always_two_decimal' })),
    };
};
