import React from 'react';
import { FlexRow, Modal, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
const DeleteDeliverableConfirmationModal = ({ closeModal, handleDelete, }) => {
    const intl = useIntl();
    const baselineEstimates = false; // WE NEED TO ADD THE LOGIC FOR THIS
    const confirmationMessage = baselineEstimates
        ? intl.formatMessage({ id: 'deliverables.delete_confirmation.modal.warning_1' })
        : intl.formatMessage({ id: 'deliverables.delete_confirmation.modal.warning_2' });
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null, intl.formatMessage({ id: 'deliverables.delete_confirmation.modal.delete_deliverable' })),
        React.createElement(Modal.Content, null,
            React.createElement(Text, null, confirmationMessage),
            React.createElement(FlexRow, null,
                React.createElement(Modal.PrimaryFooterButton, { onPress: handleDelete, dataCy: "delete" },
                    React.createElement(FormattedMessage, { id: "deliverables.delete_confirmation.modal.confirm_delete" })),
                React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal, dataCy: "cancel" },
                    React.createElement(FormattedMessage, { id: "common.cancel" }))))));
};
export default DeleteDeliverableConfirmationModal;
