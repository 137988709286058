import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

class ChartJsBar extends Component {
	render() {
		const type = this.props.type;
		const tooltipSuffix = this.props.tooltipSuffix ? this.props.tooltipSuffix : 'h';
		const options = {
			plugins: {
				legend: {
					position: this.props.legendPosition || 'bottom',
				},
				tooltip: {
					mode: 'index',
					intersect: false,
					callbacks: {
						title: function () {
							return '';
						},
						label: ctx => ctx.dataset.label + ': ' + parseFloat(ctx.parsed.x).toFixed(2) + tooltipSuffix,
					},
				},
				datalabels: {
					display: false,
				},
			},
			responsive: true,
			scales: {
				x: {
					beginAtZero: true,
					stacked: true,
					title: {
						display: this.props.xAxisLabel !== undefined,
						text: this.props.xAxisLabel,
					},
					position: this.props.isUtilizationBarChart ? 'top' : undefined,
				},
				y: {
					beginAtZero: true,
					stacked: true,
					title: {
						display: this.props.yAxisLabel !== undefined,
						text: this.props.yAxisLabel,
					},
				},
			},
			maintainAspectRatio: false,
		};
		if (type && type === 'horizontalBar') {
			options.indexAxis = 'y';
			options.scales.x.ticks = {min: 0};
		}

		return (
			<div>
				<Bar data={this.props.data} options={options} height={this.props.height} />
			</div>
		);
	}
}

ChartJsBar.propTypes = {
	data: PropTypes.shape({
		labels: PropTypes.array,
		datasets: PropTypes.array,
	}).isRequired,
};
export default injectIntl(ChartJsBar);
