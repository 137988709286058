import {createFragmentContainer, graphql} from 'react-relay';
import {sortBreakdownRoles} from '../ProjectPortfolioReportUtils';
import {useIntl} from 'react-intl';
import {ROLE_BREAKDOWN_TYPE} from '../../../../constants';

const MonthByMonthRoleLoader = ({relay, viewer, children, ...props}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const getAggregatedRoleFinancialBreakdown = () => {
		const currency = viewer.company.currency;
		const aggregatedRoleFinancialBreakdown = viewer.project.aggregatedRoleFinancialBreakdown.map(
			aggregatedRoleFinancialBreakdown => {
				const data = {...aggregatedRoleFinancialBreakdown, currency};
				if (!data.role) {
					data.role = {
						name: formatMessage({id: 'project_portfolio_report.deleted_role'}),
					};
				}
				return data;
			}
		);

		sortBreakdownRoles(aggregatedRoleFinancialBreakdown);
		return aggregatedRoleFinancialBreakdown;
	};

	let getTimeAggregatedFinancialNumbers;
	let getExpenseAggregatedFinancialNumbers;

	let timeAggregatedFinancialNumbers;
	let expenseAggregatedFinancialNumbers;

	let timeBreakdownRoles;
	let expenseBreakdownRoles;

	getTimeAggregatedFinancialNumbers = () => {
		const currency = viewer.company.currency;
		const timeAggregatedFinancialNumbers = viewer.project.timeAggregatedFinancialNumbers.map(
			timeAggregatedFinancialNumbers => {
				const data = {...timeAggregatedFinancialNumbers, currency};
				if (!data.role) {
					data.role = {
						name: formatMessage({id: 'project_portfolio_report.deleted_role'}),
					};
				}
				return data;
			}
		);
		sortBreakdownRoles(timeAggregatedFinancialNumbers);
		return timeAggregatedFinancialNumbers;
	};

	getExpenseAggregatedFinancialNumbers = () => {
		const currency = viewer.company.currency;
		const expenseAggregatedFinancialNumbers = viewer.project.expenseAggregatedFinancialNumbers.map(
			expenseAggregatedFinancialNumbers => {
				const data = {...expenseAggregatedFinancialNumbers, currency};
				if (!data.role) {
					data.role = {
						name: formatMessage({id: 'project_portfolio_report.deleted_role'}),
					};
				}
				return data;
			}
		);
		sortBreakdownRoles(expenseAggregatedFinancialNumbers);
		return expenseAggregatedFinancialNumbers;
	};

	timeAggregatedFinancialNumbers = getTimeAggregatedFinancialNumbers();
	expenseAggregatedFinancialNumbers = getExpenseAggregatedFinancialNumbers();

	timeBreakdownRoles = timeAggregatedFinancialNumbers.map(role =>
		role.breakdownType === ROLE_BREAKDOWN_TYPE.ROLE ? `${role.breakdownType}-${role.role.id}` : role.breakdownType
	);
	expenseBreakdownRoles = expenseAggregatedFinancialNumbers.map(role =>
		role.breakdownType === ROLE_BREAKDOWN_TYPE.ROLE ? `${role.breakdownType}-${role.role.id}` : role.breakdownType
	);

	const aggregatedRoleFinancialBreakdown = getAggregatedRoleFinancialBreakdown();
	const breakdownRoles = aggregatedRoleFinancialBreakdown.map(role =>
		role.breakdownType === ROLE_BREAKDOWN_TYPE.ROLE ? `${role.breakdownType}-${role.role.id}` : role.breakdownType
	);

	let year;

	if (breakdownRoles.length > 0) {
		// Get year for later use
		year = aggregatedRoleFinancialBreakdown[0].aggregatedFinancialNumbers[0].split(',')[0].split('-')[0];
	}

	// timeAggregatedFinancialNumbers and expenseAggregatedFinancialNumbers sometimes have less breakdown types than aggregatedRoleFinancialBreakdown due to filtering.
	// If for instance timeAggregatedFinancialNumbers and aggregatedRoleFinancialBreakdown have the same breakdown types, we just add a new object to aggregatedRoleFinancialBreakdown
	// that contains timeAggregatedFinancialNumbers. If they do not have the same types, for the missing ones we add a 0 value period.
	for (let i = 0; i < breakdownRoles.length; i++) {
		if (timeBreakdownRoles.includes(breakdownRoles[i])) {
			aggregatedRoleFinancialBreakdown[breakdownRoles.indexOf(breakdownRoles[i])].timeAggregatedFinancialNumbers =
				timeAggregatedFinancialNumbers[timeBreakdownRoles.indexOf(breakdownRoles[i])].timeAggregatedFinancialNumbers;
		} else {
			aggregatedRoleFinancialBreakdown[breakdownRoles.indexOf(breakdownRoles[i])].timeAggregatedFinancialNumbers = [
				`${year}-01,${year}-12,0`,
			];
		}

		if (expenseBreakdownRoles.includes(breakdownRoles[i])) {
			aggregatedRoleFinancialBreakdown[breakdownRoles.indexOf(breakdownRoles[i])].expenseAggregatedFinancialNumbers =
				expenseAggregatedFinancialNumbers[
					expenseBreakdownRoles.indexOf(breakdownRoles[i])
				].expenseAggregatedFinancialNumbers;
		} else {
			aggregatedRoleFinancialBreakdown[breakdownRoles.indexOf(breakdownRoles[i])].expenseAggregatedFinancialNumbers = [
				`${year}-01,${year}-12,0`,
			];
		}
	}

	return children({data: {rows: aggregatedRoleFinancialBreakdown}, ...props});
};

export const monthByMonthRoleLoaderQuery = graphql`
	query MonthByMonthRoleLoader_Query(
		$timeSearchQuery: TaskSearchQueryType!
		$expenseSearchQuery: TaskSearchQueryType!
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
		$projectId: ID!
		$phaseId: ID!
		$selectedSingleValue: FinancialAggregatedNumber!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_month_by_month_role_loader")
			...MonthByMonthRoleLoader_viewer
				@arguments(
					timeSearchQuery: $timeSearchQuery
					expenseSearchQuery: $expenseSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					projectId: $projectId
					phaseId: $phaseId
					selectedSingleValue: $selectedSingleValue
				)
		}
	}
`;

export default createFragmentContainer(MonthByMonthRoleLoader, {
	viewer: graphql`
		fragment MonthByMonthRoleLoader_viewer on Viewer
		@argumentDefinitions(
			timeSearchQuery: {type: "TaskSearchQueryType!"}
			expenseSearchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
			projectId: {type: "ID!"}
			selectedSingleValue: {type: "FinancialAggregatedNumber!"}
			phaseId: {type: "ID!"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				aggregatedRoleFinancialBreakdown(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					phaseId: $phaseId
					aggregateLevel: MONTH
					aggregates: [$selectedSingleValue]
					filterRowsWithNoValues: true
				) {
					breakdownType
					role {
						id
						name
					}
					aggregatedFinancialNumbers
				}
				timeAggregatedFinancialNumbers: aggregatedRoleFinancialBreakdown(
					searchQuery: $timeSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					phaseId: $phaseId
					aggregateLevel: MONTH
					aggregates: [$selectedSingleValue]
					filterRowsWithNoValues: true
				) {
					breakdownType
					role {
						id
						name
					}
					timeAggregatedFinancialNumbers: aggregatedFinancialNumbers
				}
				expenseAggregatedFinancialNumbers: aggregatedRoleFinancialBreakdown(
					searchQuery: $expenseSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					phaseId: $phaseId
					aggregateLevel: MONTH
					aggregates: [$selectedSingleValue]
					filterRowsWithNoValues: true
				) {
					breakdownType
					role {
						id
						name
					}
					expenseAggregatedFinancialNumbers: aggregatedFinancialNumbers
				}
			}
		}
	`,
});
