import styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const HighLevelNumberStyled = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	justify-content: space-between;
	gap: 8px;
	align-items: center;
	min-height: 43px;
	font-weight: ${({bold}) => (bold ? '700' : '400')};
`;

export const HighLevelBudgetNumbersBox = styled.div`
	width: ${({wide}) => (wide ? 400 : 375)}px;
	border: 1px solid #e6e6ed;
	border-radius: 4px;
	background-color: #f7f7fe;

	display: flex;
	flex-direction: column;

	${HighLevelNumberStyled} {
		border-bottom: 1px solid #e6e6ed;
	}

	${HighLevelNumberStyled}:last-child {
		border-bottom: 0;
	}
	&:not(:last-child) {
		margin-bottom: 12px;
	}
`;

export const HighLevelBudgetNumbersContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 16px;
`;

export const Label = styled.div`
	padding-left: 25px;
	font-size: 13px;
	max-width: 265px;
`;

export const Value = styled.div`
	padding-right: 25px;
	font-size: 13px;
	color: ${({negative}) => negative && CSS_CONSTANTS.negative_value};
	white-space: nowrap;
`;
