import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedDate, injectIntl} from 'react-intl';
import flow from 'lodash/flow';
import {DragSource, DropTarget} from 'react-dnd';
import {BUTTON_COLOR, BUTTON_STYLE, ItemTypes} from '../../constants';
import UpdateFileMutation from '../../mutations/update_file_mutation.modern';
import UpdateGoogleDriveFileMutation from '../../mutations/update_google_drive_file_mutation';
import UpdateFolderMutation from '../../mutations/update_folder_mutation';
import ActionMenu from '../action_menu';
import Util from '../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import Warning from '../../components/warning';

const folderTarget = {
	drop(props, monitor) {
		if (
			(monitor.getItem().parentFolder === null && props.folder.value === null) ||
			(monitor.getItem().parentFolder !== null &&
				props.folder.value !== null &&
				monitor.getItem().parentFolder.id === props.folder.node.id)
		)
			return;
		const isDragginMultipleFiles = monitor.getItem().files && monitor.getItem().files.length > 1;
		let source_name = '';
		const parentFolder = monitor.getItem().parentFolder ? monitor.getItem().parentFolder : null;
		const onSuccess = () => {
			let message;
			if (parentFolder === null) {
				// moved from top level to folder
				if (isDragginMultipleFiles) {
					message = props.intl.formatMessage(
						{id: 'project_files.drop_toast_folder_message_multiple'},
						{
							source: source_name,
							new: props.folder.node.name,
						}
					);
				} else {
					message = props.intl.formatMessage(
						{id: 'project_files.drop_toast_folder_message'},
						{
							source: source_name,
							new: props.folder.node.name,
						}
					);
				}
			} else {
				// moved from folder to another folder
				if (isDragginMultipleFiles) {
					message = props.intl.formatMessage(
						{id: 'project_files.drop_toast_message_multiple'},
						{
							source: source_name,
							initial: parentFolder.name,
							new: props.folder.node.name,
						}
					);
				} else {
					message = props.intl.formatMessage(
						{id: 'project_files.drop_toast_message'},
						{
							source: source_name,
							initial: parentFolder.name,
							new: props.folder.node.name,
						}
					);
				}
			}
			props.removeAllFromBulkUpdate();
			createToast({
				duration: 5000,
				message: message,
			});
		};
		if (monitor.getItem().isGoogleDriveFile) {
			const file = monitor.getItem().file;
			source_name = props.intl.formatMessage({id: 'common.file'});
			Util.CommitMutation(
				UpdateGoogleDriveFileMutation,
				{
					id: file.node.id,
					folderId: props.folder.node.id,
					projectId: props.folder.node.project.id,
					taskId: file.node.taskId,
					personId: file.node.person.id,
					name: file.node.name,
					link: file.node.link,
					thumb: file.node.thumb,
					createdAt: file.node.createdAt,
				},
				onSuccess
			);
		} else if (monitor.getItem().folder) {
			//folder dropped on folder
			if (monitor.getItem().folder.node.id !== props.folder.node.id) {
				const draggedFolder = monitor.getItem().folder;
				source_name = monitor.getItem().folder.node.name;
				Util.CommitMutation(
					UpdateFolderMutation,
					{
						id: draggedFolder.node.id,
						folderId: props.folder.node.id,
					},
					onSuccess
				);
			}
		} else {
			const files = monitor.getItem().files;
			source_name =
				files.length > 1
					? files.length + ' ' + props.intl.formatMessage({id: 'common.files'})
					: props.intl.formatMessage({id: 'common.file'});
			Util.CommitMutation(
				UpdateFileMutation,
				{
					ids: files,
					folderId: props.folder.node.id,
				},
				onSuccess
			);
		}
	},
};

function collectTarget(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
	};
}

const folderSource = {
	beginDrag(props) {
		props.removeAllFromBulkUpdate();
		return {
			folder: props.folder,
			isGoogleDriveFile: false,
			parentFolder: props.folder.node.folder ? props.folder.node.folder : null,
		};
	},
	isDragging(props, monitor) {
		return monitor.getItem().folder && monitor.getItem().folder.node.id === props.folder.node.id;
	},
};

function collectSource(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		isDragging: monitor.isDragging(),
	};
}

class FilesTableFolderLineItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			actionMenuExpanded: false,
		};
	}

	selectFolder() {
		this.props.selectFolder(this.props.folder);
	}

	renameFolder() {
		this.props.renameFolder(this.props.folder);
		/*const callbackPositive = value => {
			const onSuccess = () => {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'project_files.folder_renamed'})
				});
			};
			Util.RelayCommitUpdate(
				new UpdateFolderMutation({
					id: this.props.folder.node.id,
					name: value
				}),
				onSuccess
			);
		};
		showModal({
			type: MODAL_TYPE.USER_INPUT,
			initialValue: this.props.folder.node.name,
			handleConfirm: callbackPositive,
			titleText: <FormattedMessage id="project_files.edit_folder" />,
			confirmText: <FormattedMessage id="common.save" />,
			placeholder: this.props.intl.formatMessage({id: 'project_files.enter-folder-name'}),
			label: this.props.intl.formatMessage({id: 'project_files.folder_name'})
		});*/
	}

	deleteFolder() {
		const {formatMessage} = this.props.intl;
		const callbackPositive = () => {
			this.props.deleteFolder(this.props.folder);
		};
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="common.delete-confirmation-title" />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: callbackPositive,
				},
			],
		});
	}

	closeActionMenu(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (newTarget && newTarget.id === 'download-link') {
			return;
		}
		this.setState({actionMenuExpanded: false});
	}

	toggleActionMenu() {
		this.setState({actionMenuExpanded: !this.state.actionMenuExpanded});
	}

	render() {
		const folder = this.props.folder;
		const {connectDropTarget, connectDragSource, isOver, isDragging} = this.props;
		const style = {opacity: isDragging ? 0 : 1};
		return connectDropTarget(
			connectDragSource(
				<tr className={'table-row' + (isOver ? ' marked' : '')} style={style}>
					<td />
					<td className="drag-handle" />
					<td className={'file-format folder'} onClick={this.selectFolder.bind(this)} />
					<td className="name" onClick={this.selectFolder.bind(this)}>
						{folder.node.name}
					</td>
					<td className="added-date" onClick={this.selectFolder.bind(this)}>
						<FormattedDate
							value={Util.CreateMomentDate(
								folder.node.yearCreated,
								folder.node.monthCreated,
								folder.node.dayCreated
							)}
						/>
					</td>
					<td onClick={this.selectFolder.bind(this)} />
					{/* Empty cell for card column */}
					<td
						className="gear-menu"
						onBlur={this.closeActionMenu.bind(this)}
						onClick={this.toggleActionMenu.bind(this)}
						tabIndex="0"
					>
						<ActionMenu
							options={[
								{
									label: this.props.intl.formatMessage({id: 'common.rename'}),
									onClick: this.renameFolder.bind(this),
								},
								{
									label: this.props.intl.formatMessage({id: 'common.delete'}),
									onClick: this.deleteFolder.bind(this),
								},
							]}
						/>
					</td>
				</tr>
			)
		);
	}
}

FilesTableFolderLineItem.propTypes = {
	folder: PropTypes.object.isRequired,
	selectFolder: PropTypes.func.isRequired,
	deleteFolder: PropTypes.func.isRequired,
};
export default injectIntl(
	flow(
		DragSource(ItemTypes.PROJECT_FILE, folderSource, collectSource),
		DropTarget(ItemTypes.PROJECT_FILE, folderTarget, collectTarget)
	)(FilesTableFolderLineItem)
);
