import Item from '../../canvas-timeline/canvas_timeline_item';
import {
	drawRectangle,
	getMomentFromCanvasTimelineDate,
	getProjectColors,
	getTrimmedText,
	hasViewOnlyAccess,
	ITEM_TYPE,
	TOOLTIP_DISPLAY_DELAY_MS,
} from '../../canvas-timeline/canvas_timeline_util';
import Util from '../../../../forecast-app/shared/util/util';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import AllocationItemUtil from '../../allocation_item_util';
import {getCachedMessage} from '../../../../translations/TranslationCache';
import {adjustTotalMinutesMap} from '../../placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import DataManager from '../../DataManager';
import {getRecalculationInterval, recalculateGroupHeatmapCache} from '../../heatmap/HeatmapLogic';
import {DATE_FORMAT_DAY, MODULE_TYPES} from '../../../../constants';
import UpdatePlaceholderAllocationMutation from '../../../../mutations/update_placeholder_allocation_mutation';
import {handleMutationSuccess} from '../../../../containers/modal/placeholder/PlaceholderAllocationUtils';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {hasModule} from '../../../../forecast-app/shared/util/ModuleUtil';
import EventManager from '../../EventManager';

class PlaceholderAllocationItem extends Item {
	constructor(pageComponent, data) {
		super(pageComponent, ITEM_TYPE.PLACEHOLDER_ALLOCATION, data);
		this.updateData(data);
	}

	updateData(data, updateItem = false) {
		const {color, placeholderAllocation, defaultWorkingDays, totalMinutesMap, getSchedulingOptions, projectWinChance} =
			data;
		const {intl} = this.pageComponent.props;

		const projectColors = getProjectColors(color);
		const schedulingOptions = getSchedulingOptions();
		const hasWinAndSoftFeatureFlag = hasFeatureFlag('placeholders');
		const hasBetaChanges = hasFeatureFlag('capacity_planning_beta_2_improvements');
		const useWinPercentage = hasWinAndSoftFeatureFlag && schedulingOptions.calcWin;
		const winPercentageToUse = useWinPercentage ? projectWinChance : 1;
		const asterisk = useWinPercentage && !hasBetaChanges ? '﹡' : '';
		const winProbText = getCachedMessage(intl, 'scheduling.win_prob_text');
		const totalTime =
			asterisk + Util.convertMinutesToFullHour(totalMinutesMap.get(placeholderAllocation.id) * winPercentageToUse, intl);

		const allocationTimeText = Util.getAllocationTimeText(
			totalTime,
			placeholderAllocation,
			{defaultWorkingDays},
			intl,
			winPercentageToUse
		);

		const getUpdateData = data => {
			data.projectColors = projectColors;
			data.allocationTimeText = allocationTimeText;
			data.totalTime = totalTime;
			data.schedulingOptions = schedulingOptions;
			data.winProbText = winProbText;
			return data;
		};

		if (updateItem) {
			this.updateWithData(getUpdateData(data));
		} else {
			this.data = getUpdateData(data);
		}
	}

	onMoveAttempt(item) {
		if (this.isDisabled() && !hasViewOnlyAccess(this.pageComponent, item.data.project.id, null)) {
			const project = DataManager.getProjectById(this.pageComponent, item.data.project.id);
			if (['DONE', 'HALTED'].includes(project.status)) {
				EventManager.onDoneOrHaltedMoveAttempt(this.pageComponent);
			}
		}
	}

	onMoving(item, group, startDifference, endDifference, dragData, movedDays) {
		if (!this.isDisabled()) {
			const {totalMinutesMap} = this.pageComponent.state;

			const startDate = getMomentFromCanvasTimelineDate(dragData.newStartDate || this.startDate);
			const endDate = getMomentFromCanvasTimelineDate(dragData.newEndDate || this.endDate);

			const {recalculateHeatmapCache, placeholderAllocation, placeholder} = this.data;

			placeholderAllocation.startYear = startDate.year();
			placeholderAllocation.startMonth = startDate.month() + 1;
			placeholderAllocation.startDay = startDate.date();
			placeholderAllocation.startDate = new Date(startDate.year(), startDate.month(), startDate.date());
			placeholderAllocation.endYear = endDate.year();
			placeholderAllocation.endMonth = endDate.month() + 1;
			placeholderAllocation.endDay = endDate.date();
			placeholderAllocation.endDate = new Date(endDate.year(), endDate.month(), endDate.date());

			adjustTotalMinutesMap(placeholder, placeholderAllocation, totalMinutesMap, false, false);

			this.updateData({
				...this.data,
				placeholderAllocation,
			});

			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				DataManager.moveItemTemporarily(this.pageComponent, this);
			}

			const recalculationInterval = getRecalculationInterval(item, startDifference, endDifference);
			if (placeholderAllocation.personId) {
				recalculateGroupHeatmapCache(this.pageComponent, placeholderAllocation.personId, recalculationInterval);
			}

			if (recalculateHeatmapCache) {
				recalculateHeatmapCache(getRecalculationInterval(this, startDifference, endDifference));
			}

			return true;
		}

		return false;
	}

	onMoveEnd(item, group, initialGroup, dragData) {
		if (!this.isDisabled()) {
			const {staffingModeActive} = this.pageComponent.state;

			const startDate = getMomentFromCanvasTimelineDate(this.startDate).format(DATE_FORMAT_DAY);
			const endDate = getMomentFromCanvasTimelineDate(this.endDate).format(DATE_FORMAT_DAY);

			const {placeholderAllocation} = this.data;

			if (staffingModeActive) {
				const targetGroup = group;
				const targetGroupParent = targetGroup?.parentGroup;
				const movingToNewGroup = initialGroup && targetGroup && initialGroup.id !== targetGroup.id;

				if (this.isBeingGhosted) {
					EventManager.onEndGhost(this.pageComponent, this, movingToNewGroup);
				}

				if (!targetGroup) {
					return;
				}

				const placeholder = DataManager.getPlaceholderById(this.pageComponent, placeholderAllocation.placeholderId);

				const isConnectedProject = !!placeholder.projectGroupId;

				const project = isConnectedProject
					? DataManager.getProjectGroupById(this.pageComponent, placeholder.projectGroupId)
					: DataManager.getProjectById(this.pageComponent, placeholder.projectId);

				if (this.isFreeDragDropOffGroup(targetGroup)) {
					let res;

					if (dragData.hasItemDislodged) {
						res = {
							staffingPlaceholderAllocation: {
								placeholderAllocation: {
									...placeholderAllocation,
									startDate,
									endDate,
									personId: targetGroup.data.personId,
									isSoft: hasModule(MODULE_TYPES.SOFT_ALLOCATIONS),
									projectId: isConnectedProject ? undefined : project.id,
									projectGroupId: isConnectedProject ? project.id : undefined,
								},
							},
						};
					} else {
						res = {
							updatePlaceholderAllocation: {
								placeholderAllocation: {
									...placeholderAllocation,
									personId: targetGroup.data.personId,
									startDate,
									endDate,
								},
							},
						};
					}

					Util.dispatchScheduleEvent(res);

					// expand the parent group of the parent group the allocation was moved to
					if (movingToNewGroup && targetGroupParent) {
						dragData.innermostVisibleParentGroup = targetGroup;
						targetGroupParent.setExpanded(true);
					}
				}
			} else {
				const input = {
					id: placeholderAllocation.id,
					startDate: startDate,
					endDate: endDate,
				};

				Util.CommitMutation(UpdatePlaceholderAllocationMutation, input, handleMutationSuccess);
			}
		}
	}

	onMouseEnter(positionData, event) {
		const {placeholderAllocation, project, projectGroup, hideTooltip} = this.data;

		if (!hideTooltip) {
			if (placeholderAllocation) {
				this.pageComponent.setState({
					hoverX: event.clientX,
					hoverY: event.clientY,
					showDetailBox: false,
				});

				setTimeout(() => {
					this.pageComponent.setState({
						showDetailBox: true,
						detailBoxLeft: positionData.x,
						detailBoxX: event.clientX,
						detailBoxY: positionData.y,
						detailBoxTop: positionData.y - 2,
						detailBoxData: {placeholderAllocation, project, projectGroup},
					});
				}, TOOLTIP_DISPLAY_DELAY_MS);
			}
		}
	}

	onMouseLeave() {
		const {hideTooltip} = this.data;
		if (!hideTooltip) {
			this.pageComponent.setState({showDetailBox: false, hoverX: null, hoverY: null, detailBoxData: null});
		}
	}

	onMouseMove(positionData, event) {
		if (!this.isDisabled()) {
			this.pageComponent.setState({
				hoverX: event.clientX,
				hoverY: event.clientY,
			});

			setTimeout(() => {
				this.pageComponent.setState({detailBoxX: event.clientX + 10});
			}, TOOLTIP_DISPLAY_DELAY_MS);
		}
	}

	updateFreeDragData(dragData) {
		const {staffingModeActive} = this.pageComponent.state;

		if (!this.isDisabled() && staffingModeActive) {
			EventManager.updateFreeDragData(this, dragData);
		}
	}

	onClick() {
		if (this.isOnClickEnabled()) {
			const {staffingModeActive} = this.pageComponent.state;
			const {placeholder, placeholderAllocation} = this.data;

			showModal({
				type: MODAL_TYPE.PLACEHOLDER_ALLOCATION,
				projectId: placeholder.projectId,
				projectGroupId: placeholder.projectGroupId,
				staffingModeActive,
				placeholderInput: placeholder,
				placeholderAllocationInput: placeholderAllocation,
			});
		}
	}

	draw(canvasContext, x, y, width, canvasWidth) {
		const {data, height} = this;
		const {
			projectName,
			placeholderAllocation,
			projectColors,
			allocationTimeText,
			totalTime,
			schedulingOptions,
			winProbText,
		} = data;

		// sizing
		const allocationNoteIconSize = 12;
		const textContainerPaddingX = 8;
		const textContainerSpacing = 2;
		const textSpacing = 4;
		const minProjectColorWidth = 8;

		// visible stats
		const textMargin = 16;
		const visibleItemStartX = x > 0 ? x : 0;
		const visibleItemEndX = x + width > canvasWidth ? canvasWidth : x + width;
		const visibleItemWidth = visibleItemEndX - visibleItemStartX;

		if (visibleItemWidth <= 0) return;

		// placeholderAllocation bar
		drawRectangle(canvasContext, x, y, width, height, {
			backgroundColor: projectColors.background,
			backgroundOpacity: this.isGhost ? 0.25 : 0.9,
			borderRadius: 12,
			borderThickness: 1,
			dashedBorder: true,
			dashedBorderPattern: [3, 3],
			borderColor: projectColors.border,
		});

		canvasContext.fillStyle = projectColors.text;
		canvasContext.font = '400 11px ' + Util.getFontFamily();

		const iconTextSpacing = textSpacing + 2;
		const allocationNoteIconWidth = placeholderAllocation.description ? allocationNoteIconSize + iconTextSpacing : 0;

		const text = AllocationItemUtil.getItemText(
			canvasContext,
			totalTime,
			placeholderAllocation,
			allocationNoteIconSize,
			iconTextSpacing,
			visibleItemWidth,
			allocationTimeText,
			schedulingOptions,
			winProbText
		);

		const pureTextWidth = canvasContext.measureText(text).width;

		// text container bar properties
		let textContainerMaxWidth = pureTextWidth + allocationNoteIconWidth + textContainerPaddingX * 2;
		let textContainerWidth =
			textContainerMaxWidth + textContainerSpacing * 2 >= visibleItemWidth ? visibleItemWidth : textContainerMaxWidth;
		const showDescriptionIcon = textContainerWidth < visibleItemWidth * 0.7;

		let textContainerContentWidth = pureTextWidth + allocationNoteIconWidth;
		if (!showDescriptionIcon) {
			textContainerMaxWidth = pureTextWidth + textContainerPaddingX * 2;
			textContainerContentWidth = pureTextWidth;
		}

		const shouldDisplayTextContainer =
			textContainerMaxWidth + textContainerSpacing * 2 <= visibleItemWidth - minProjectColorWidth;

		// text container bar
		if (shouldDisplayTextContainer) {
			if (this.isGhost) {
				canvasContext.globalAlpha = 0.25;
			}

			// draw allocation hours
			const textXCoord = visibleItemStartX + textContainerPaddingX + textContainerSpacing;
			const formattedTaskName = getTrimmedText(canvasContext, text, width - 32);

			// note (description) icon
			if (placeholderAllocation.description && showDescriptionIcon) {
				canvasContext.drawImage(
					cacheManager.getCommonImage(COMMON_IMAGE.ALLOCATION_NOTE_ICON),
					textXCoord + pureTextWidth + textSpacing,
					y + height / 2 - allocationNoteIconSize / 2,
					allocationNoteIconSize,
					allocationNoteIconSize
				);
			}

			// Show name of allocation
			canvasContext.fillText(formattedTaskName, textXCoord, y + height / 2 + 4);
			if (placeholderAllocation.idleTimeName || placeholderAllocation.displayName) {
				const allocationName = getTrimmedText(
					canvasContext,
					placeholderAllocation.idleTimeName || placeholderAllocation.displayName,
					visibleItemWidth - textMargin * 2 - textContainerContentWidth - 20
				);
				canvasContext.fillText(
					allocationName,
					x + width - canvasContext.measureText(allocationName).width - textMargin,
					y + height / 2 + 4
				);
			}
		}

		// project name
		if (projectName) {
			const textMargin = 16;
			const visibleItemStartX = x > 0 ? x : 0;
			const visibleItemEndX = x + width > canvasWidth ? canvasWidth : x + width;
			const visibleItemWidth = visibleItemEndX - visibleItemStartX;

			canvasContext.fillStyle = projectColors.text;
			canvasContext.font = '400 11px ' + Util.getFontFamily();

			const trimmedProjectName = getTrimmedText(
				canvasContext,
				projectName,
				visibleItemWidth - textMargin * 2 - textContainerContentWidth - 20
			);

			const textWidth = canvasContext.measureText(trimmedProjectName).width;
			const textXCoord =
				x + width > canvasWidth ? canvasWidth - (textMargin + textWidth) : x + width - (textMargin + textWidth);

			canvasContext.fillText(trimmedProjectName, textXCoord, y + height / 2 + 4);
		}
		if (this.isGhost) {
			canvasContext.globalAlpha = 1;
		}
	}
}

export default PlaceholderAllocationItem;
