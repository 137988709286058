import React from 'react';
import * as PropTypes from 'prop-types';
import Moment from 'moment';

import DateRangePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import {DATE_PICKER_STYLE, DATE_RANGE_QUICK_RANGE} from '../../../constants';
import styled from 'styled-components';

const DateRangePickerWithQuickRangesStyle = styled.div`
	.date-picker-button-container-v3 {
		.date-picker-button.date-picker-button-standard {
			height: 30px;
			border-radius: 0;

			.date-picker-icon {
				background-size: 15px;
			}
		}
	}
`;

/**
 * Not fully implemented yet. For now we just use a standard date range picker, but we should implement the new quick range picker when we have design for it.
 */
export const DateRangePickerWithQuickRanges = ({
	defaultRangeItem,
	onRangeItemChange,
	clearable,
	userpilot,
	calendarOffsetX,
	clearBothDates,
}) => {
	// by default take the current month as date range
	let startDate = new Moment().startOf('month');
	let endDate = new Moment().endOf('month');

	if (defaultRangeItem.type === DATE_RANGE_QUICK_RANGE.CURRENT_YEAR) {
		startDate = new Moment().startOf('year');
		endDate = new Moment().endOf('year');
	} else if (defaultRangeItem.type === DATE_RANGE_QUICK_RANGE.LAST_MONTH) {
		startDate = new Moment().subtract(1, 'month').startOf('month');
		endDate = new Moment().subtract(1, 'month').endOf('month');
	} else if (defaultRangeItem.type === DATE_RANGE_QUICK_RANGE.LAST_30_DAYS) {
		startDate = new Moment().subtract(30, 'days');
		endDate = new Moment();
	} else if (defaultRangeItem.type === DATE_RANGE_QUICK_RANGE.CUSTOM) {
		startDate = defaultRangeItem.startDate;
		endDate = defaultRangeItem.endDate;
	}

	const handleDateRangeChange = (startDate, endDate) => {
		onRangeItemChange({
			type: DATE_RANGE_QUICK_RANGE.CUSTOM,
			startDate,
			endDate,
		});
	};

	return (
		<DateRangePickerWithQuickRangesStyle data-userpilot={userpilot}>
			<DateRangePicker
				startDate={startDate}
				endDate={endDate}
				datePickerStyle={DATE_PICKER_STYLE.STANDARD}
				handleDateRangeChange={handleDateRangeChange}
				calendarOffsetX={calendarOffsetX ?? -200}
				clearable={clearable}
				clearBothDates={clearBothDates}
			/>
		</DateRangePickerWithQuickRangesStyle>
	);
};

DateRangePickerWithQuickRanges.propTypes = {
	defaultRangeItem: PropTypes.shape({
		type: PropTypes.oneOf([
			DATE_RANGE_QUICK_RANGE.CURRENT_MONTH,
			DATE_RANGE_QUICK_RANGE.CURRENT_YEAR,
			DATE_RANGE_QUICK_RANGE.LAST_MONTH,
			DATE_RANGE_QUICK_RANGE.LAST_30_DAYS,
			DATE_RANGE_QUICK_RANGE.CUSTOM,
		]).isRequired,
		clearable: PropTypes.bool,
	}),
	onRangeItemChange: PropTypes.func.isRequired,
};
