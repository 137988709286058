import React from 'react';
import {injectIntl} from 'react-intl';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import Util from '../../forecast-app/shared/util/util';

const ValueCellStyles = styled.div`
	display: flex;
	white-space: nowrap;
	width: 100%;
	color: ${props => props.hasError && '#f40000'};
	justify-content: ${({noJustify}) => !noJustify && 'flex-end'};
`;

const VALUE_TYPE = {
	PRICE: 'price',
	TIME: 'time',
	POINTS: 'points',
	PERCENTAGE: 'percentage',
	DAYS: 'days',
};

export const ValueCell = injectIntl(({type, value, currencySymbol, intl, hasError, cy, noJustify, minutesPerDay}) => {
	switch (type) {
		case VALUE_TYPE.PRICE:
			return (
				<ValueCellStyles noJustify={noJustify} data-cy={cy} hasError={hasError}>
					{value !== undefined && value !== null
						? Util.getFormattedCurrencyValue(
								currencySymbol,
								intl.formatNumber(value, {format: 'always_two_decimal'})
						  )
						: null}
				</ValueCellStyles>
			);
		case VALUE_TYPE.PERCENTAGE:
			return (
				<ValueCellStyles noJustify={noJustify} data-cy={cy} hasError={hasError}>
					{value !== undefined && value !== null ? Util.getFormattedPercentage(value, intl, 0) : null}
				</ValueCellStyles>
			);
		case VALUE_TYPE.TIME:
			return (
				<ValueCellStyles noJustify={noJustify} data-cy={cy} hasError={hasError}>
					{value !== undefined ? Util.convertMinutesToFullHour(value * 60, intl) : null}
				</ValueCellStyles>
			);
		case VALUE_TYPE.POINTS:
			return (
				<ValueCellStyles noJustify={noJustify} data-cy={cy} hasError={hasError}>
					{value !== undefined ? Util.roundPoints(value, intl) : null}
				</ValueCellStyles>
			);
		case VALUE_TYPE.DAYS:
			return (
				<ValueCellStyles noJustify={noJustify} data-cy={cy} hasError={hasError}>
					{value !== undefined ? Util.convertMinutesToDays(value * 60, minutesPerDay, intl) : null}
				</ValueCellStyles>
			);
		default:
			return (
				<ValueCellStyles noJustify={noJustify} data-cy={cy} hasError={hasError}>
					{value}
				</ValueCellStyles>
			);
	}
});

ValueCell.propTypes = {
	type: PropTypes.oneOf([VALUE_TYPE.PRICE, VALUE_TYPE.PERCENTAGE, VALUE_TYPE.TIME]),
	value: PropTypes.any,
	currencySymbol: PropTypes.string,
};

ValueCell.VALUE_TYPE = VALUE_TYPE;
