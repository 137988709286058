import {
	DRAG_HANDLE_VISIBILITY_THRESHOLD,
	DRAGGABLE_TYPE,
	drawRectangle,
	getMomentFromCanvasTimelineDate,
	getTextColor,
	GROUP_HIGHLIGHT_ANIMATION_DURATION,
	GROUP_SECTION_WIDTH,
	isSimulationMode,
	ITEM_DRAG_POINT,
	ITEM_POSITION_CHANGE_ANIMATION_DURATION,
	ITEM_TYPE,
	SECTION_SPLITTER_HEIGHT,
	TIMELINE_BAR_BORDER_RADIUS,
} from './canvas_timeline_util';
import {cacheManager, COMMON_IMAGE} from './canvas_timeline_cache_manager';
import Util from '../../../forecast-app/shared/util/util';
import {projectHoverColors, SCHEDULING_VIEW} from '../../../constants';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import ColorManipulation from 'color';

class PostProcessingRenderer {
	constructor(canvasContext, schedulingView) {
		this.canvasContext = canvasContext;
		this.isInUse = false;

		this.shouldDrawDependencyHandle = !isSimulationMode() && schedulingView === SCHEDULING_VIEW.PROJECTS;
	}

	getCanvasHeight() {
		return this.canvasContext.canvas.height / window.devicePixelRatio;
	}

	getCanvasWidth() {
		return this.canvasContext.canvas.width / window.devicePixelRatio;
	}

	clear() {
		this.canvasContext.clearRect(
			0,
			0,
			this.canvasContext.canvas.width / window.devicePixelRatio,
			this.canvasContext.canvas.height / window.devicePixelRatio
		);
	}

	processMouseMoveData(data, dragData, collapsableSectionHeight) {
		if (data.groupData) {
			this.drawRowHoverEffect(data.groupData, collapsableSectionHeight);
			this.isInUse = true;
		}
		if (dragData) return;
		if (data.itemData) {
			if (data.itemData.item.draggableType) {
				const useAllocationDragHandles =
					data.itemData.item?.data?.allocation || data.itemData.item?.data?.placeholderAllocation;
				this.drawDragHandles(data.itemData, useAllocationDragHandles);
				this.isInUse = true;
			}
		}
	}

	processDragData(dragData, collapsableSectionHeight, timelineData, showHeatmap) {
		const {
			cacheCanvas,
			itemData,
			initialX,
			initialY,
			currentX,
			currentY,
			groupData,
			newItemX,
			newItemWidth,
			dragPoint,
			hasItemDislodged,
			hasDateChanged,
			isCreatingDependency,
			toX,
			toY,
			visibleTaskData,
			isMoveAttempt,
		} = dragData;

		const hasItemCreate = groupData?.group?.data?.hasItemCreate;
		if (isMoveAttempt || !hasItemCreate) return;

		//Dragging or resizing item
		if (itemData) {
			const {item} = itemData;

			if (
				groupData &&
				dragPoint === ITEM_DRAG_POINT.CENTER &&
				item.draggableType === DRAGGABLE_TYPE.DATE_AND_GROUP &&
				hasItemDislodged
			) {
				this.drawDragRowHoverEffect(groupData, dragData, collapsableSectionHeight);
			}

			//Create clipping path that does not include group section so that items do not appear above it while being dragged
			this.canvasContext.save();
			this.canvasContext.beginPath();
			this.canvasContext.moveTo(GROUP_SECTION_WIDTH, 0);
			this.canvasContext.lineTo(this.getCanvasWidth(), 0);
			this.canvasContext.lineTo(this.getCanvasWidth(), this.getCanvasHeight());
			this.canvasContext.lineTo(GROUP_SECTION_WIDTH, this.getCanvasHeight());
			this.canvasContext.closePath();
			this.canvasContext.clip();

			if (isCreatingDependency) {
				if (isSimulationMode()) return;

				this.canvasContext.strokeStyle = '#a1a1a1';
				this.canvasContext.fillStyle = '#a1a1a1';
				this.canvasContext.lineWidth = 1.5;

				let fromX = item.x + GROUP_SECTION_WIDTH + item.width;
				if (item.endDate < timelineData.startDate) {
					fromX = GROUP_SECTION_WIDTH - 10;
				} else if (item.startDate > timelineData.endDate) {
					fromX = GROUP_SECTION_WIDTH + timelineData.canvasWidth + 10;
				}
				const fromY = item.y + item.height / 2;

				let snappedToX = toX;
				let snappedToY = toY;
				let snappedToEnd = false;
				let lowestDistance = Infinity;
				let lowestDistanceTask = null;
				for (const visibleTask of visibleTaskData || []) {
					if (!visibleTask.allowSnapping || visibleTask.y + visibleTask.height / 2 === fromY) continue;
					const toStartDistance =
						Math.pow(visibleTask.x - toX + GROUP_SECTION_WIDTH, 2) +
						Math.pow(visibleTask.y + visibleTask.height / 2 - toY, 2);
					if (toStartDistance < lowestDistance) {
						lowestDistance = toStartDistance;
						lowestDistanceTask = visibleTask;
						snappedToEnd = false;
					}
					const toFinishDistance =
						Math.pow(visibleTask.x + visibleTask.width - toX + GROUP_SECTION_WIDTH, 2) +
						Math.pow(visibleTask.y + visibleTask.height / 2 - toY, 2);
					if (toFinishDistance < lowestDistance) {
						lowestDistance = toFinishDistance;
						lowestDistanceTask = visibleTask;
						snappedToEnd = true;
					}
				}

				const isSnapped = lowestDistanceTask && lowestDistance < 900;
				dragData.isDependencyCreationSnapped = isSnapped;
				dragData.dependencyTargetTask = isSnapped ? lowestDistanceTask : null;
				if (isSnapped) {
					snappedToX = lowestDistanceTask.x + (snappedToEnd ? lowestDistanceTask.width : 0) + GROUP_SECTION_WIDTH;
					snappedToY = lowestDistanceTask.y + lowestDistanceTask.height / 2;
				}

				if (isSnapped) {
					dragData.isFinishToStartDependency = !snappedToEnd;

					if (snappedToEnd) {
						this.canvasContext.moveTo(snappedToX, snappedToY);
						this.canvasContext.bezierCurveTo(snappedToX + 20, snappedToY, snappedToX + 20, fromY, fromX, fromY);
						this.canvasContext.stroke();

						this.canvasContext.beginPath();

						const distance = Math.sqrt(Math.pow(snappedToX - fromX, 2) + Math.pow(snappedToY - fromY, 2));

						const yDifferenceFactor = Util.clamp(Math.sqrt(20 * Math.abs(snappedToY - fromY)) / 100, 0, 1);
						const lowDistanceFactor = Util.clamp(150 - distance, 0, 150) / 150;

						const t = 0.8 - 0.3 * yDifferenceFactor - 0.3 * lowDistanceFactor;
						const centerX =
							Math.pow(1 - t, 3) * snappedToX +
							3 * Math.pow(1 - t, 2) * t * (snappedToX + 20) +
							3 * (1 - t) * Math.pow(t, 2) * (snappedToX + 20) +
							Math.pow(t, 3) * fromX;
						const centerY =
							Math.pow(1 - t, 3) * snappedToY +
							3 * Math.pow(1 - t, 2) * t * snappedToY +
							3 * (1 - t) * Math.pow(t, 2) * fromY +
							Math.pow(t, 3) * fromY;

						const angle = Math.atan2(fromY - snappedToY, fromX - snappedToX) + (Math.PI * 3) / 4;
						this.canvasContext.beginPath();
						this.canvasContext.moveTo(centerX, centerY);
						this.canvasContext.lineTo(centerX - 8 * Math.cos(angle), centerY - 8 * Math.sin(angle));
						this.canvasContext.lineTo(centerX + 8 * Math.sin(angle), centerY - 8 * Math.cos(angle));
						this.canvasContext.closePath();
						this.canvasContext.fill();
					} else {
						const middleX = (snappedToX + fromX) / 2;
						const middleY = (snappedToY + fromY) / 2;
						this.canvasContext.moveTo(snappedToX, snappedToY);
						this.canvasContext.quadraticCurveTo(snappedToX - 20, snappedToY, middleX, middleY);
						this.canvasContext.quadraticCurveTo(fromX + 20, fromY, fromX, fromY);
						this.canvasContext.stroke();

						const angle = Math.atan2(fromY - snappedToY - 20, fromX + 20 - snappedToX) + (Math.PI * 3) / 4;
						this.canvasContext.beginPath();
						this.canvasContext.moveTo(middleX, middleY);
						this.canvasContext.lineTo(middleX - 8 * Math.cos(angle), middleY - 8 * Math.sin(angle));
						this.canvasContext.lineTo(middleX + 8 * Math.sin(angle), middleY - 8 * Math.cos(angle));
						this.canvasContext.closePath();
						this.canvasContext.fill();
					}
				} else {
					this.canvasContext.beginPath();
					this.canvasContext.moveTo(fromX, fromY);
					this.canvasContext.lineTo(toX, toY);
					this.canvasContext.stroke();
				}

				this.canvasContext.beginPath();
				this.canvasContext.arc(fromX, fromY, 5, 0, Math.PI * 2);
				this.canvasContext.fill();
			} //Dragging item
			else if (cacheCanvas) {
				const x = itemData.x + (currentX - initialX);
				const y = itemData.y + (currentY - initialY);
				//Divide size by devicePixelRatio because canvas size is multiplied by that value on creation
				this.canvasContext.drawImage(
					cacheCanvas,
					x,
					y,
					cacheCanvas.width / window.devicePixelRatio,
					cacheCanvas.height / window.devicePixelRatio
				);
				if (hasDateChanged) {
					this.drawDateIndicators(
						x,
						y,
						item.width * (item.draggableArea ? item.draggableArea.end - item.draggableArea.start : 1),
						item.height,
						item.startDate,
						item.endDate
					);
				}
			} else {
				if (hasDateChanged) {
					this.drawDateIndicators(
						item.x + GROUP_SECTION_WIDTH,
						item.y,
						item.width,
						item.height,
						item.startDate,
						item.endDate
					);
				}
			}
			this.canvasContext.restore();
			this.isInUse = true;
		} else {
			const {group} = groupData;
			this.setGroupSectionClippingPath(group.isInCollapsableSection, collapsableSectionHeight);
			const heatmapMarginTop = showHeatmap === false || !group?.displayItemsWithHeatmap ? 0 : group.getHeatmapHeight();

			// creating new item
			drawRectangle(
				this.canvasContext,
				newItemX,
				group.screenY + 4 + heatmapMarginTop,
				newItemWidth,
				group.height - 8 - heatmapMarginTop,
				{
					backgroundOpacity: 0.3,
					backgroundColor: '#a56dfd',
					borderRadius: TIMELINE_BAR_BORDER_RADIUS,
				}
			);

			this.canvasContext.restore();
			this.canvasContext.clearRect(0, group.screenY, GROUP_SECTION_WIDTH, groupData.height);
			this.isInUse = true;
		}
	}

	drawDateIndicators(itemX, itemY, itemWidth, itemHeight, startDate, endDate) {
		let startDateRectangleX = itemX - 75;
		if (startDateRectangleX < GROUP_SECTION_WIDTH) {
			startDateRectangleX = GROUP_SECTION_WIDTH;
		} else if (startDateRectangleX > this.getCanvasWidth() - 150) {
			startDateRectangleX = this.getCanvasWidth() - 150;
		}
		let endDateRectangleX = itemX + itemWidth;
		if (endDateRectangleX > this.getCanvasWidth() - 75) {
			endDateRectangleX = this.getCanvasWidth() - 75;
		} else if (endDateRectangleX < GROUP_SECTION_WIDTH + 75) {
			endDateRectangleX = GROUP_SECTION_WIDTH + 75;
		}
		this.canvasContext.fillStyle = '#ebebee';
		this.canvasContext.fillRect(startDateRectangleX, itemY, 75, itemHeight);
		this.canvasContext.fillRect(endDateRectangleX, itemY, 75, itemHeight);
		this.canvasContext.fillStyle = '#535353';
		this.canvasContext.font = '400 11px ' + Util.getFontFamily();

		const topTextLineY = itemY + itemHeight / 2 - 4;
		const bottomTextLineY = itemY + itemHeight / 2 + 10;

		const momentStartDate = getMomentFromCanvasTimelineDate(startDate);
		const momentEndDate = getMomentFromCanvasTimelineDate(endDate);
		const startWeekdayText = this.formatDate(momentStartDate, {
			weekday: 'long',
		});
		const startWeekdayTextWidth = this.canvasContext.measureText(startWeekdayText).width;
		const endWeekdayText = this.formatDate(momentEndDate, {
			weekday: 'long',
		});
		const endWeekdayTextWidth = this.canvasContext.measureText(endWeekdayText).width;

		this.canvasContext.fillText(startWeekdayText, startDateRectangleX + (75 - startWeekdayTextWidth) / 2, topTextLineY);
		this.canvasContext.fillText(endWeekdayText, endDateRectangleX + (75 - endWeekdayTextWidth) / 2, topTextLineY);

		this.canvasContext.font = '700 11px ' + Util.getFontFamily();

		const startDateText = this.formatDate(momentStartDate, {
			month: 'short',
			day: '2-digit',
		});
		const startDateTextWidth = this.canvasContext.measureText(startDateText).width;
		const endDateText = this.formatDate(momentEndDate, {
			month: 'short',
			day: '2-digit',
		});
		const endDateTextWidth = this.canvasContext.measureText(endDateText).width;

		this.canvasContext.fillText(startDateText, startDateRectangleX + (75 - startDateTextWidth) / 2, bottomTextLineY);
		this.canvasContext.fillText(endDateText, endDateRectangleX + (75 - endDateTextWidth) / 2, bottomTextLineY);
	}

	animateItemPositionChange(
		item,
		cacheCanvas,
		initialX,
		initialY,
		destinationX,
		destinationY,
		innermostVisibleParentGroup,
		collapsableSectionHeight,
		onAnimationComplete
	) {
		let initialTime = new Date().valueOf();
		const deltaX = destinationX - initialX;
		const deltaY = destinationY - initialY;
		const isTargetGroupVisible =
			innermostVisibleParentGroup.id === item.groupId ||
			(innermostVisibleParentGroup.groupIds && innermostVisibleParentGroup.groupIds.includes(item.groupId));
		const {screenY, totalHeight, isInCollapsableSection} = innermostVisibleParentGroup;
		const shouldHighlightWholeCollapsableSection =
			isInCollapsableSection && (screenY > collapsableSectionHeight || screenY + totalHeight < 0);

		const animateGroupHighlight = () => {
			this.clear();
			const currentTime = new Date().valueOf();
			const currentAnimationTime = currentTime - initialTime;
			if (currentAnimationTime > GROUP_HIGHLIGHT_ANIMATION_DURATION) {
				this.canvasContext.globalAlpha = 1;
				onAnimationComplete();
				return;
			}
			const easeFactor =
				((0.5 * currentAnimationTime) / GROUP_HIGHLIGHT_ANIMATION_DURATION) *
				(currentAnimationTime / GROUP_HIGHLIGHT_ANIMATION_DURATION);
			this.setGroupSectionClippingPath(isInCollapsableSection, collapsableSectionHeight); //Create clipping path so that hover effects do not overflow between collapsable and main section

			if (shouldHighlightWholeCollapsableSection) {
				drawRectangle(
					this.canvasContext,
					0,
					0,
					this.getCanvasWidth(),
					collapsableSectionHeight - SECTION_SPLITTER_HEIGHT,
					{
						backgroundOpacity: 0.5 - easeFactor,
						backgroundColor: '#6e0fea',
					}
				);
			} else {
				drawRectangle(this.canvasContext, 0, screenY, this.getCanvasWidth(), totalHeight, {
					backgroundOpacity: 0.5 - easeFactor,
					backgroundColor: '#6e0fea',
				});
			}

			this.canvasContext.restore(); //Remove clipping path
			requestAnimationFrame(animateGroupHighlight);
		};

		const animate = () => {
			this.clear();
			const currentTime = new Date().valueOf();
			const currentAnimationTime = currentTime - initialTime;
			if (currentAnimationTime > ITEM_POSITION_CHANGE_ANIMATION_DURATION) {
				if (isTargetGroupVisible && !shouldHighlightWholeCollapsableSection) {
					onAnimationComplete();
				} else {
					initialTime = new Date().valueOf();
					requestAnimationFrame(animateGroupHighlight);
				}
				return;
			}
			const easeFactor =
				(currentAnimationTime / ITEM_POSITION_CHANGE_ANIMATION_DURATION) *
				(2 - currentAnimationTime / ITEM_POSITION_CHANGE_ANIMATION_DURATION);
			//Create clipping path that does not include group section so that items do not appear above it while being dragged
			this.canvasContext.save();
			this.canvasContext.beginPath();
			this.canvasContext.moveTo(GROUP_SECTION_WIDTH, isInCollapsableSection ? 0 : collapsableSectionHeight);
			this.canvasContext.lineTo(this.getCanvasWidth(), isInCollapsableSection ? 0 : collapsableSectionHeight);
			this.canvasContext.lineTo(
				this.getCanvasWidth(),
				isInCollapsableSection ? collapsableSectionHeight - SECTION_SPLITTER_HEIGHT : this.getCanvasHeight()
			);
			this.canvasContext.lineTo(
				GROUP_SECTION_WIDTH,
				isInCollapsableSection ? collapsableSectionHeight - SECTION_SPLITTER_HEIGHT : this.getCanvasHeight()
			);
			this.canvasContext.closePath();
			this.canvasContext.clip();
			this.canvasContext.drawImage(
				cacheCanvas,
				initialX + deltaX * easeFactor,
				initialY + deltaY * easeFactor,
				cacheCanvas.width / window.devicePixelRatio,
				cacheCanvas.height / window.devicePixelRatio
			);
			this.canvasContext.restore(); //Remove clipping path
			requestAnimationFrame(animate);
		};
		requestAnimationFrame(animate);
	}

	setGroupSectionClippingPath(isInCollapsableSection, collapsableSectionHeight) {
		this.canvasContext.save();
		this.canvasContext.beginPath();
		this.canvasContext.moveTo(0, isInCollapsableSection ? 0 : collapsableSectionHeight);
		this.canvasContext.lineTo(this.getCanvasWidth(), isInCollapsableSection ? 0 : collapsableSectionHeight);
		this.canvasContext.lineTo(
			this.getCanvasWidth(),
			isInCollapsableSection ? collapsableSectionHeight - SECTION_SPLITTER_HEIGHT : this.getCanvasHeight()
		);
		this.canvasContext.lineTo(
			0,
			isInCollapsableSection ? collapsableSectionHeight - SECTION_SPLITTER_HEIGHT : this.getCanvasHeight()
		);
		this.canvasContext.closePath();
		this.canvasContext.clip();
	}

	drawDragRowHoverEffect(groupData, dragData, collapsableSectionHeight) {
		const {group} = groupData;
		const item = dragData.itemData.item;
		let {innermostVisibleParentGroup} = dragData;
		let screenY, totalHeight, isInCollapsableSection, backgroundColor, backgroundOpacity;

		//TODO: Remake
		//Need to do some fuckery for dragging tasks to unassigned, don't have time to find a good solution
		const isDraggingTaskToUnassigned =
			!dragData.initialGroup.isInCollapsableSection &&
			group.isInCollapsableSection &&
			dragData.itemData.item.itemType === ITEM_TYPE.TASK;
		if (isDraggingTaskToUnassigned) {
			innermostVisibleParentGroup = null;
		}

		if (!innermostVisibleParentGroup) {
			backgroundColor = isDraggingTaskToUnassigned ? '#6e0fea' : '#e4253b';
			backgroundOpacity = isDraggingTaskToUnassigned ? 0.1 : 0.5;
			isInCollapsableSection = group.isInCollapsableSection;
			totalHeight = isInCollapsableSection
				? collapsableSectionHeight - SECTION_SPLITTER_HEIGHT
				: this.getCanvasHeight() - collapsableSectionHeight;
			screenY = isInCollapsableSection ? 0 : collapsableSectionHeight;
		} else {
			backgroundColor = '#6e0fea';
			backgroundOpacity = 0.1;

			let targetGroup = innermostVisibleParentGroup;

			while (targetGroup.parentGroup?.includeInRowHover) {
				targetGroup = targetGroup.parentGroup;
			}

			const mouseTargetGroup = dragData.groupData?.group;
			const isOriginalGroup = item.originalItem && mouseTargetGroup && item.originalItem.groupId === mouseTargetGroup.id;

			if (isOriginalGroup) {
				targetGroup = mouseTargetGroup;
			} else {
				// dragging items onto other allowed group types
				const itemDropOffHoverEffectGroupType = item.dropOffHoverEffectGroupType;

				if (item.hasFreeDragDefined() && typeof itemDropOffHoverEffectGroupType === 'number') {
					let dragDataTargetGroup = mouseTargetGroup;

					if (dragDataTargetGroup && item.isFreeDragDropOffGroup(dragDataTargetGroup)) {
						const isHoverEffectGroupType = itemDropOffHoverEffectGroupType === dragDataTargetGroup.groupType;

						if (isHoverEffectGroupType) {
							targetGroup = dragDataTargetGroup;
						} else {
							let rootGroup = dragDataTargetGroup;
							while (rootGroup.groupType !== itemDropOffHoverEffectGroupType && rootGroup.parentGroup) {
								rootGroup = rootGroup.parentGroup;
							}

							if (rootGroup?.groupType === itemDropOffHoverEffectGroupType) {
								targetGroup = rootGroup;
							}
						}
					}
				}
			}

			screenY = targetGroup.screenY;
			totalHeight = targetGroup.totalHeight;
			isInCollapsableSection = targetGroup.isInCollapsableSection;
		}

		//Create clipping path so that hover effects do not overflow between collapsable and main section
		this.setGroupSectionClippingPath(isInCollapsableSection, collapsableSectionHeight);
		drawRectangle(this.canvasContext, 0, screenY, this.getCanvasWidth(), totalHeight, {
			backgroundColor,
			backgroundOpacity,
		});

		// remove clipping path
		this.canvasContext.restore();
	}

	drawRowHoverEffect(groupData, collapsableSectionHeight) {
		const {y, height, group} = groupData;

		const {isInCollapsableSection} = group;
		//Create clipping path so that hover effects do not overflow between collapsable and main section
		this.setGroupSectionClippingPath(isInCollapsableSection, collapsableSectionHeight);

		this.canvasContext.beginPath();
		this.canvasContext.lineWidth = 1;
		this.canvasContext.strokeStyle = group.renderRowLines ? '#dadadd' : '#ebebee';
		this.canvasContext.moveTo(group.renderRowLines ? 0 : GROUP_SECTION_WIDTH, y - 0.5);
		this.canvasContext.lineTo(this.getCanvasWidth(), y - 0.5);
		this.canvasContext.moveTo(group.renderRowLines ? 0 : GROUP_SECTION_WIDTH, y + height - 0.5);
		this.canvasContext.lineTo(this.getCanvasWidth(), y + height - 0.5);
		this.canvasContext.stroke();
		this.canvasContext.restore(); //Remove clipping path
	}

	drawDragHandles(itemData, allocationDragHandles) {
		const {x, y, width, height, item} = itemData;
		if (this.shouldDrawDependencyHandle && item && item.itemType === ITEM_TYPE.TASK && item.data.allowDependencyCreation) {
			const dependencyHandleRight = cacheManager.getCommonImage(COMMON_IMAGE.DEPENDENCY_HANDLE_RIGHT);
			this.canvasContext.drawImage(dependencyHandleRight, x + width + 5, y + 10, 15, 7);
		}
		if (width < DRAG_HANDLE_VISIBILITY_THRESHOLD) return;
		this.canvasContext.fillStyle = getTextColor(item.data.color);

		this.canvasContext.globalAlpha = 1;
		const isTask = item.data?.task;
		const taskDone = item.data?.task?.done;
		const isSoft = hasFeatureFlag('placeholders') && item.data?.allocation?.isSoft;

		if (allocationDragHandles && !isTask) {
			const radius = 2;
			const radians = 360 * 0.0175; // degrees x 1 degree-radian conversion rate
			const itemAllocation = itemData.item.data.allocation;
			const itemPlaceholderAllocation = itemData.item.data.placeholderAllocation;

			const showLeftDragHandleInProjectColor = itemAllocation
				? !isSoft &&
				  ((itemAllocation && !itemAllocation.projectId) || (itemAllocation && !item.isDisplayingAllocatedHours))
				: false;

			const handleColor = itemAllocation
				? item.data.color
				: ColorManipulation(item.data.color).darken(0.5).saturate(0.4).hex();

			// left drag handle
			this.canvasContext.beginPath();
			this.canvasContext.fillStyle = showLeftDragHandleInProjectColor ? '#ffffff' : handleColor;
			this.canvasContext.arc(x + 6, y + height / 2, radius, 0, radians, false);

			this.canvasContext.fill();

			// right drag handle
			this.canvasContext.beginPath();
			this.canvasContext.fillStyle = itemPlaceholderAllocation || isSoft ? handleColor : '#ffffff';
			this.canvasContext.arc(x + width - 8, y + height / 2, radius, 0, radians, false);

			this.canvasContext.fill();
		} else {
			// set drag handles color
			this.canvasContext.fillStyle = taskDone
				? '#2D6C33'
				: projectHoverColors[item.data.color.toLowerCase()] || '#AEAEBC';

			const handleHeight = height / 3;

			// left drag handle
			this.canvasContext.fillRect(x + 6, y + height / 2 - handleHeight / 2, 1, handleHeight);

			// right drag handle
			this.canvasContext.fillRect(x + width - 8, y + height / 2 - handleHeight / 2, 1, handleHeight);
		}

		this.canvasContext.globalAlpha = 1;
		this.canvasContext.clearRect(0, y, GROUP_SECTION_WIDTH, height);
	}

	drawSplitAllocationBar(x, y, height, allocationColor) {
		this.canvasContext.strokeStyle = getTextColor(allocationColor);
		this.canvasContext.beginPath();
		this.canvasContext.lineWidth = 2;
		this.canvasContext.setLineDash([3, 3]);
		this.canvasContext.moveTo(x + GROUP_SECTION_WIDTH, y + 2);
		this.canvasContext.lineTo(x + GROUP_SECTION_WIDTH, y + height - 2);
		this.canvasContext.stroke();
		this.canvasContext.setLineDash([]);
	}
}

export default PostProcessingRenderer;
