/*global vis*/
import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import Timeline from '../../../components/scheduling/timeline';
import Util from '../../../forecast-app/shared/util/util';
import TimelineTaskItem from '../../../components/project-timeline/timeline_task_item';
import TimelinePhaseItem from '../../../components/project-timeline/timeline_phase_item';
import TimelineProjectItem from '../../../components/project-timeline/timeline_project_item';
import {oldColors} from '../../../constants';
import ReactDOM from 'react-dom';
import ZoomMenu from '../../../components/scheduling/zoom_menu';
import {cloneDeep} from 'lodash';

class projectTimelineComponent extends Component {
	constructor(props) {
		super(props);
		this.taskItemRefs = {};

		let canBeDisplayed = true;

		if (
			this.props.projectGroupId ||
			!this.props.viewer.insightComponentsData ||
			!this.props.viewer.insightComponentsData.projectTimeline ||
			!this.props.viewer.insightComponentsData.projectTimeline.project
		) {
			canBeDisplayed = false;
		}

		if (canBeDisplayed) {
			const project = this.props.viewer.insightComponentsData.projectTimeline.project;
			if (project.projectStartYear && project.projectEndYear) {
				this.initializeTimeline();
			} else {
				canBeDisplayed = false;
			}
		}

		this.state = {canBeDisplayed};
	}

	componentDidMount() {
		this.resizeComponent();
		this.props.notifyOnReady(this.props.id);
	}

	resizeComponent(delay) {
		const node = ReactDOM.findDOMNode(this.timeline);
		if (node) {
			setTimeout(
				() => {
					const height = Math.ceil((node.getBoundingClientRect().height + 80) / 200);
					//+80 height is for padding and zoommenu/title
					if (this.props.height !== height) {
						this.props.updateComponentHeight(this.props.id, height);
					}
				},
				delay ? delay : 0
			);
		}
	}

	initializeTimeline() {
		const projectTimeline = cloneDeep(this.props.viewer.insightComponentsData.projectTimeline);
		const project = projectTimeline.project;
		this.groups = new vis.DataSet();
		this.items = new vis.DataSet();

		this.groups.add({
			id: 'project',
			order: 100000,
			visible: true,
			className: 'resource-front-wrapper',
		});

		this.items.add({
			id: 'project-item',
			group: 'project',
			editable: false,
			project: cloneDeep(project),
			start: Util.CreateMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay).toDate(),
			end: Util.CreateMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay)
				.add(1, 'days')
				.toDate(),
		});

		const sortTasksByDate = (a, b) => {
			const aStartDate = Util.CreateMomentDate(a.startYear, a.startMonth, a.startDay);
			const aEndDate = Util.CreateMomentDate(a.deadlineYear, a.deadlineMonth, a.deadlineDay);
			const bStartDate = Util.CreateMomentDate(b.startYear, b.startMonth, b.startDay);
			const bEndDate = Util.CreateMomentDate(b.deadlineYear, b.deadlineMonth, b.deadlineDay);
			if (aStartDate.isAfter(bStartDate)) return 1;
			if (aStartDate.isBefore(bStartDate)) return -1;
			if (aEndDate.isAfter(bEndDate)) return -1;
			if (aEndDate.isBefore(bEndDate)) return 1;
			return 0;
		};

		let phases = projectTimeline.phases
			.map(phase => {
				const group_phase = phase;
				group_phase.tasks = projectTimeline.tasks
					.filter(task => task.phase !== null && task.phase.id === group_phase.id)
					.map(task => task)
					.sort(sortTasksByDate);
				return group_phase;
				//Sort phases by their deadline, if there is no deadline, use project date
			})
			.sort((a, b) => {
				const aEndDate = a.deadlineYear
					? Util.CreateMomentDate(a.deadlineYear, a.deadlineMonth, a.deadlineDay)
					: Util.CreateMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay).add(
							1,
							'days'
					  );
				const bEndDate = b.deadlineYear
					? Util.CreateMomentDate(b.deadlineYear, b.deadlineMonth, b.deadlineDay)
					: Util.CreateMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay).add(
							1,
							'days'
					  );
				const aStartDate = a.startYear
					? Util.CreateMomentDate(a.startYear, a.startMonth, a.startDay)
					: Util.CreateMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay);
				const bStartDate = b.startYear
					? Util.CreateMomentDate(b.startYear, b.startMonth, b.startDay)
					: Util.CreateMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay);
				if (aStartDate.isAfter(bStartDate)) return 1;
				if (aStartDate.isBefore(bStartDate)) return -1;
				if (aEndDate.isAfter(bEndDate)) return -1;
				if (aEndDate.isBefore(bEndDate)) return 1;
				return 0;
			});
		//Add out of scope phase if it is not empty
		const defaultPhase = {
			id: 'out-of-scope',
			name: this.props.intl.formatMessage({id: 'project_scopes.no-scope'}),
		};
		defaultPhase.tasks = cloneDeep(projectTimeline.tasks)
			.filter(task => !task.phase)
			.map(task => task)
			.sort(sortTasksByDate);
		phases.unshift(defaultPhase);

		let phaseCounter = 2;
		phases.forEach(phase => {
			const nestedGroups = [];
			let counter = 1;
			phase.tasks.forEach(task => {
				this.groups.add({
					id: 'task-group-' + task.id,
					className: 'resource-front-wrapper',
					order: phaseCounter * 100000 + counter,
					task: task,
				});
				nestedGroups.push('task-group-' + task.id);
				this.items.add({
					id: 'task-' + task.id,
					editable: false,
					group: 'task-group-' + task.id,
					task,
					phaseOrder: phaseCounter,
					start: Util.CreateMomentDate(task.startYear, task.startMonth, task.startDay).toDate(),
					end: Util.CreateMomentDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay).add(1, 'days').toDate(),
					startFrom: task.startFrom,
					deadlineFrom: task.deadlineFrom,
				});

				counter++;
			});

			this.groups.add({
				id: 'phase-' + phase.id,
				visible: phase.id === 'out-of-scope' ? nestedGroups.length !== 0 : true,
				phase: phase,
				order: phaseCounter * 100000,
				nestedGroups,
				showNested: false,
				className: phase.id === 'out-of-scope' ? 'resource-front-wrapper out-of-scope' : 'resource-front-wrapper',
			});
			//If phase doesnt have start or end date, use project dates
			this.items.add({
				id: 'phase-item-' + phase.id,
				editable: false,
				group: 'phase-' + phase.id,
				phaseOrder: phaseCounter,
				phase,
				start: phase.startYear
					? Util.CreateMomentDate(phase.startYear, phase.startMonth, phase.startDay).toDate()
					: Util.CreateMomentDate(
							project.projectStartYear,
							project.projectStartMonth,
							project.projectStartDay
					  ).toDate(),
				end: phase.deadlineYear
					? Util.CreateMomentDate(phase.deadlineYear, phase.deadlineMonth, phase.deadlineDay).add(1, 'days').toDate()
					: Util.CreateMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay)
							.add(1, 'days')
							.toDate(),
			});
			phaseCounter++;
		});
		this.phaseColors = oldColors;

		this.resizeComponent();
	}

	groupTemplate(group, element, data) {
		//This prevents app from breaking when navigating to different page
		if (!group) return null;

		let template = null;
		if (group.id === 'project') {
			template = (
				<div className="front-only project">
					<h3>{this.props.intl.formatMessage({id: 'project_timeline.project_progress'})}</h3>
				</div>
			);
		} else if (group.phase) {
			const phaseColorStyle = {
				backgroundColor:
					group.phase.id === 'out-of-scope'
						? '#bdbdbd'
						: this.phaseColors[(group.order / 100000) % this.phaseColors.length],
			};
			template = (
				<div className="front-only phase">
					<div className="phase-color" style={phaseColorStyle} />
					<div className="phase-name-container">
						<h4 className="phase-name">{group.phase.name}</h4>
					</div>
				</div>
			);
		} else if (group.task) {
			template = (
				<div className="front-only task">
					<h5 className="task-id">{'T' + group.task.companyTaskId}</h5>
					<h5 className="task-name">{group.task.name}</h5>
				</div>
			);
		}
		return ReactDOM.createPortal(ReactDOM.render(template, element), element);
	}

	visibleFrameTemplate(item, element) {
		let template = null; //template of item
		if (item.task) {
			template = (
				<TimelineTaskItem
					flipPopup={this.state.flipPopup}
					popupX={this.state.popupX}
					popupY={this.state.popupY}
					ref={e => {
						this.taskItemRefs[item.id] = e;
					}}
					item={item}
					showWarnings={false}
				/>
			);
		} else if (item.phase) {
			const tasks = this.props.viewer.insightComponentsData.projectTimeline.tasks
				.filter(task => task.phase !== null && task.phase.id === item.phase.id)
				.map(task => task);
			template = <TimelinePhaseItem tasks={tasks} item={item} />;
		} else if (item.project) {
			template = <TimelineProjectItem item={item} />;
		}
		return ReactDOM.createPortal(ReactDOM.render(template, element), element);
	}

	onClick(properties) {
		//When expanding/collapsing
		if (properties.what === 'group-label' && properties.group && properties.group.startsWith('phase-')) {
			this.resizeComponent();
		}
	}

	getTimeline() {
		return this.timeline;
	}

	onWheel(e) {
		//This prevents browser from going back/forward in history when swiping left/right on macbook without breaking normal mouse wheel scrolling
		if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
			e.preventDefault();
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		if (
			this.props.projectGroupId ||
			this.props.viewer.insightComponentsData.projectTimeline.project === null ||
			this.props.viewer.insightComponentsData.projectTimeline.project === undefined
		) {
			return (
				<div className="ai-empty-message">
					{this.props.intl.formatMessage({id: 'insights.component.projectTimeline.project_group_disabled'})}
				</div>
			);
		}
		if (!this.state.canBeDisplayed)
			return (
				<div className="ai-empty-message">
					{formatMessage({id: 'insights.component.projectTimeline.no_dates_warning'})}
				</div>
			);

		const project = this.props.viewer.insightComponentsData.projectTimeline.project;
		const start = Util.CreateMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay)
			.subtract(2, 'days')
			.toDate();
		const end = Util.CreateMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay)
			.add(1 + 2, 'days')
			.toDate();

		this.resizeComponent();

		return (
			<div className="project-timeline-insight-component" onWheel={this.onWheel.bind(this)}>
				<div className="section-body">
					<div className="zoom-menu-container">
						<ZoomMenu getTimeline={this.getTimeline.bind(this)} />
					</div>
					<Timeline
						ref={item => (this.timeline = item)}
						groups={this.groups}
						items={this.items}
						scroll={true}
						startDate={start}
						endDate={end}
						visibleFrameTemplate={this.visibleFrameTemplate.bind(this)}
						groupTemplate={this.groupTemplate.bind(this)}
						onClick={this.onClick.bind(this)}
						moveable={true}
					/>
				</div>
			</div>
		);
	}
}

const projectTimelineComponentQuery = graphql`
	query projectTimelineComponent_Query($shareKey: String, $projectId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_project_timeline")
			...projectTimelineComponent_viewer
				@arguments(shareKey: $shareKey, projectId: $projectId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {projectTimelineComponentQuery};

export default injectIntl(
	createFragmentContainer(projectTimelineComponent, {
		viewer: graphql`
			fragment projectTimelineComponent_viewer on Viewer
			@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
				insightComponentsData(shareKey: $shareKey) {
					projectTimeline(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
						project {
							name
							projectColor
							projectEndYear
							projectEndMonth
							projectEndDay
							projectStartYear
							projectStartMonth
							projectStartDay
							completion
						}
						phases {
							id
							name
							startYear
							startMonth
							startDay
							deadlineYear
							deadlineMonth
							deadlineDay
						}
						tasks {
							id
							companyTaskId
							name
							startYear
							startMonth
							startDay
							startFrom
							deadlineYear
							deadlineMonth
							deadlineDay
							deadlineFrom
							estimateForecast
							timeLeft
							phase {
								id
								name
								startYear
								startMonth
								startDay
								deadlineYear
								deadlineMonth
								deadlineDay
							}
							assignedPersons {
								id
								firstName
								lastName
								profilePictureId
								profilePictureDefaultId
							}
							statusColumn {
								category
							}
						}
					}
				}
			}
		`,
	})
);
