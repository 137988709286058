/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type HighLevelBudgetNumbers_project$ref: FragmentReference;
declare export opaque type HighLevelBudgetNumbers_project$fragmentType: HighLevelBudgetNumbers_project$ref;
export type HighLevelBudgetNumbers_project = {|
  +id: string,
  +budget: ?number,
  +budgetType: ?BUDGET_TYPE,
  +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
  +timeRegBasedPlusDoneScopeCompletion: ?number,
  +rateCard: ?{|
    +currency: ?string
  |},
  +financialNumbers: ?{|
    +billableActualTimeAndExpenses: ?number,
    +actualCost: ?number,
    +billableForecastTimeAndExpensesToComplete: ?number,
    +forecastCostToComplete: ?number,
    +totalCostAtCompletion: ?number,
    +billableTotalTimeAndExpensesAtCompletion: ?number,
    +invoiced: ?number,
    +paid: ?number,
    +recognitionProfit: ?number,
    +recognitionProfitPercentage: ?number,
    +totalRevenueRecognition: ?number,
    +projectTotalForecastTimeAndExpensesToCompleteInPast: ?number,
    +projectTotalForecastCostToCompleteInPast: ?number,
  |},
  +financialNumbersToCurrentMonth: ?{|
    +recognitionLockedProfit: ?number,
    +recognitionLockedProfitPercentage: ?number,
    +recognitionLockedRevenue: ?number,
  |},
  +expenseItems: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +approved: ?boolean,
        +billable: ?boolean,
        +partOfFixedPrice: ?boolean,
        +expenseYear: ?number,
        +expenseMonth: ?number,
        +expenseDay: ?number,
        +price: ?number,
        +cost: ?number,
        +quantity: ?number,
      |}
    |}>
  |},
  +$refType: HighLevelBudgetNumbers_project$ref,
|};
export type HighLevelBudgetNumbers_project$data = HighLevelBudgetNumbers_project;
export type HighLevelBudgetNumbers_project$key = {
  +$data?: HighLevelBudgetNumbers_project$data,
  +$fragmentRefs: HighLevelBudgetNumbers_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Literal",
  "name": "convertToProjectCurrency",
  "value": true
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "currentMonthLastDay",
      "type": "Int"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "expenseItems"
        ]
      }
    ]
  },
  "name": "HighLevelBudgetNumbers_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultPeriodBudgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeRegBasedPlusDoneScopeCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RateCard",
      "kind": "LinkedField",
      "name": "rateCard",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "FinancialNumbers",
      "kind": "LinkedField",
      "name": "financialNumbers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billableActualTimeAndExpenses",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billableForecastTimeAndExpensesToComplete",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastCostToComplete",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCostAtCompletion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billableTotalTimeAndExpensesAtCompletion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiced",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recognitionProfit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recognitionProfitPercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalRevenueRecognition",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectTotalForecastTimeAndExpensesToCompleteInPast",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectTotalForecastCostToCompleteInPast",
          "storageKey": null
        }
      ],
      "storageKey": "financialNumbers(convertToProjectCurrency:true)"
    },
    {
      "alias": "financialNumbersToCurrentMonth",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "endDay",
          "variableName": "currentMonthLastDay"
        },
        {
          "kind": "Variable",
          "name": "endMonth",
          "variableName": "currentMonth"
        },
        {
          "kind": "Variable",
          "name": "endYear",
          "variableName": "currentYear"
        }
      ],
      "concreteType": "FinancialNumbers",
      "kind": "LinkedField",
      "name": "financialNumbers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recognitionLockedProfit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recognitionLockedProfitPercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recognitionLockedRevenue",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "expenseItems",
      "args": null,
      "concreteType": "ExpenseItemTypeConnection",
      "kind": "LinkedField",
      "name": "__Project_expenseItems_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExpenseItemTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExpenseItem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "approved",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billable",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "partOfFixedPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cost",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '760e767ebf9ddab27de82e678209577e';

module.exports = node;
