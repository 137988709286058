/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type peopleListProjectsComponent_viewer$ref = any;
export type peopleListProjectsComponentRefetchQueryVariables = {|
  shareKey?: ?string,
  componentId?: ?string,
  isProjectGroupType?: ?boolean,
  personIds?: ?$ReadOnlyArray<?string>,
  teamIds?: ?$ReadOnlyArray<?string>,
  roleIds?: ?$ReadOnlyArray<?string>,
  labelIds?: ?$ReadOnlyArray<?string>,
  skillIds?: ?$ReadOnlyArray<?string>,
|};
export type peopleListProjectsComponentRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: peopleListProjectsComponent_viewer$ref
  |}
|};
export type peopleListProjectsComponentRefetchQuery = {|
  variables: peopleListProjectsComponentRefetchQueryVariables,
  response: peopleListProjectsComponentRefetchQueryResponse,
|};
*/


/*
query peopleListProjectsComponentRefetchQuery(
  $shareKey: String
  $componentId: ID
  $isProjectGroupType: Boolean
  $personIds: [ID]
  $teamIds: [ID]
  $roleIds: [ID]
  $labelIds: [ID]
  $skillIds: [ID]
) {
  viewer {
    ...peopleListProjectsComponent_viewer_2UG7J4
    id
  }
}

fragment peopleListProjectsComponent_viewer_2UG7J4 on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    peopleListProjects(componentId: $componentId, isProjectGroupType: $isProjectGroupType, personIds: $personIds, teamIds: $teamIds, roleIds: $roleIds, labelIds: $labelIds, skillIds: $skillIds) {
      currency
      useHours
      todoTasksCountTotal
      progressTasksCountTotal
      doneTasksCountTotal
      tasksCountTotal
      remainingTotal
      reportedTotal
      spendTotal
      people {
        id
        name
        profilePictureId
        profilePictureDefaultId
        defaultRole
        projectRole
        email
        labels {
          id
          name
          color
        }
        skills {
          id
          name
        }
        profiles(first: 10000) {
          edges {
            node {
              id
              name
            }
          }
        }
        tasksCount
        todoTasksCount
        progressTasksCount
        doneTasksCount
        reportedMinutes
        remaining
        spend
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "componentId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isProjectGroupType",
    "type": "Boolean"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roleIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "labelIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skillIds",
    "type": "[ID]"
  }
],
v1 = {
  "kind": "Variable",
  "name": "componentId",
  "variableName": "componentId"
},
v2 = {
  "kind": "Variable",
  "name": "isProjectGroupType",
  "variableName": "isProjectGroupType"
},
v3 = {
  "kind": "Variable",
  "name": "labelIds",
  "variableName": "labelIds"
},
v4 = {
  "kind": "Variable",
  "name": "personIds",
  "variableName": "personIds"
},
v5 = {
  "kind": "Variable",
  "name": "roleIds",
  "variableName": "roleIds"
},
v6 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v7 = {
  "kind": "Variable",
  "name": "skillIds",
  "variableName": "skillIds"
},
v8 = {
  "kind": "Variable",
  "name": "teamIds",
  "variableName": "teamIds"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = [
  (v9/*: any*/),
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "peopleListProjectsComponentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "peopleListProjectsComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "peopleListProjectsComponentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v6/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "concreteType": "PeopleList_Project",
                "kind": "LinkedField",
                "name": "peopleListProjects",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "useHours",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "todoTasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "progressTasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doneTasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remainingTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reportedTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "spendTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PeopleList_ProjectPersonType",
                    "kind": "LinkedField",
                    "name": "people",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureDefaultId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "defaultRole",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectRole",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Label",
                        "kind": "LinkedField",
                        "name": "labels",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Skill",
                        "kind": "LinkedField",
                        "name": "skills",
                        "plural": true,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 10000
                          }
                        ],
                        "concreteType": "ProfileTypeConnection",
                        "kind": "LinkedField",
                        "name": "profiles",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfileTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Profile",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v11/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "profiles(first:10000)"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tasksCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "todoTasksCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "progressTasksCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "doneTasksCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reportedMinutes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remaining",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "spend",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "peopleListProjectsComponentRefetchQuery",
    "operationKind": "query",
    "text": "query peopleListProjectsComponentRefetchQuery(\n  $shareKey: String\n  $componentId: ID\n  $isProjectGroupType: Boolean\n  $personIds: [ID]\n  $teamIds: [ID]\n  $roleIds: [ID]\n  $labelIds: [ID]\n  $skillIds: [ID]\n) {\n  viewer {\n    ...peopleListProjectsComponent_viewer_2UG7J4\n    id\n  }\n}\n\nfragment peopleListProjectsComponent_viewer_2UG7J4 on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    peopleListProjects(componentId: $componentId, isProjectGroupType: $isProjectGroupType, personIds: $personIds, teamIds: $teamIds, roleIds: $roleIds, labelIds: $labelIds, skillIds: $skillIds) {\n      currency\n      useHours\n      todoTasksCountTotal\n      progressTasksCountTotal\n      doneTasksCountTotal\n      tasksCountTotal\n      remainingTotal\n      reportedTotal\n      spendTotal\n      people {\n        id\n        name\n        profilePictureId\n        profilePictureDefaultId\n        defaultRole\n        projectRole\n        email\n        labels {\n          id\n          name\n          color\n        }\n        skills {\n          id\n          name\n        }\n        profiles(first: 10000) {\n          edges {\n            node {\n              id\n              name\n            }\n          }\n        }\n        tasksCount\n        todoTasksCount\n        progressTasksCount\n        doneTasksCount\n        reportedMinutes\n        remaining\n        spend\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '38f6bc02552fb446d3f15783e0c75f69';

module.exports = node;
