import React, {Component} from 'react';
import {SECTION_SPLITTER_HEIGHT} from './canvas_timeline_util';

export default class SectionSplitter extends Component {
	constructor(props) {
		super(props);

		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);

		if (window.PointerEvent) {
			window.addEventListener('pointerup', this.onMouseUp);
		} else {
			window.addEventListener('mouseup', this.onMouseUp);
		}
		window.addEventListener('mousemove', this.onMouseMove);
	}

	onMouseDown(e) {
		e.preventDefault();
		e.stopPropagation();
		this.props.onSplitterMouseDown();
	}

	onMouseUp(e) {
		e.preventDefault();
		e.stopPropagation();
		const {isBeingDragged} = this.props;
		if (!isBeingDragged) return;
		this.props.onSplitterMouseUp();
	}

	onMouseMove(e) {
		const {isBeingDragged} = this.props;
		if (!isBeingDragged) return;
		this.props.onPositionChange(e.clientY);
	}

	render() {
		const {isCollapsableSectionExpanded, collapsableSectionHeight} = this.props;

		return (
			<div
				onMouseDown={this.onMouseDown.bind(this)}
				className="canvas-timeline-section-splitter"
				style={{
					top: collapsableSectionHeight - SECTION_SPLITTER_HEIGHT,
					height: `${SECTION_SPLITTER_HEIGHT}px`,
					visibility: isCollapsableSectionExpanded ? 'visible' : 'hidden',
				}}
			/>
		);
	}
}
