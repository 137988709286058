/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type freeTextFieldComponent_viewer$ref = any;
export type freeTextFieldComponent_QueryVariables = {||};
export type freeTextFieldComponent_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: freeTextFieldComponent_viewer$ref,
  |}
|};
export type freeTextFieldComponent_Query = {|
  variables: freeTextFieldComponent_QueryVariables,
  response: freeTextFieldComponent_QueryResponse,
|};
*/


/*
query freeTextFieldComponent_Query {
  viewer {
    actualPersonId
    component(name: "insight_free_text")
    ...freeTextFieldComponent_viewer
    id
  }
}

fragment freeTextFieldComponent_viewer on Viewer {
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "insight_free_text"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"insight_free_text\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "freeTextFieldComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "freeTextFieldComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "freeTextFieldComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "freeTextFieldComponent_Query",
    "operationKind": "query",
    "text": "query freeTextFieldComponent_Query {\n  viewer {\n    actualPersonId\n    component(name: \"insight_free_text\")\n    ...freeTextFieldComponent_viewer\n    id\n  }\n}\n\nfragment freeTextFieldComponent_viewer on Viewer {\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'af937d51690c1e5b329e91a8a0d54682';

module.exports = node;
