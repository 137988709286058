import React from 'react';
import {getQueryRenderer} from '../reported_time_page_util';

import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../constants';

import {CalculationLoader, HexagonText, Table} from 'web-components';
import Util from '../../../../forecast-app/shared/util/util';
import {getNumberOfCustomFields} from '../reported_time_table_util';
import {profilePicSrc} from '../../../../directApi';

export const getPersonRow = (
	data,
	currency,
	intl,
	groupIndex,
	searchQuery,
	groupings,
	enabledColumns,
	sortValue,
	hasNextPage,
	fetchMore,
	nextLevelProps,
	tableHeader
) => {
	const numberOfCustomFields = getNumberOfCustomFields(tableHeader);
	const getColumns = person => {
		const formattedPrice = person.totalPrice && intl.formatNumber(person.totalPrice, {format: 'always_two_decimal'});
		const formattedCost = person.totalCost && intl.formatNumber(person.totalCost, {format: 'always_two_decimal'});
		return [
			<Table.Column>
				<HexagonText
					intl={intl}
					active={person.active}
					text={person.fullName}
					imgUrl={profilePicSrc(person.profilePictureId)}
				/>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>{Util.convertMinutesToFullHour(person.totalMinutesRegistered, intl)}</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>
				<span>
					{formattedPrice !== null ? (
						Util.GetFormattedCurrencySymbol(currency, formattedPrice)
					) : (
						<CalculationLoader />
					)}
				</span>
			</Table.Column>,
			<Table.Column>
				<span>
					{formattedCost !== null ? Util.GetFormattedCurrencySymbol(currency, formattedCost) : <CalculationLoader />}
				</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			[...Array(numberOfCustomFields)].map(_ => <Table.Column />),
		];
	};

	return (
		<Table.Rows
			data={data}
			canExpand={true}
			autoload={hasNextPage && groupIndex === 1}
			loadMore={hasNextPage ? fetchMore : false}
			{...nextLevelProps}
		>
			{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
				return (
					<>
						<Table.Row {...tableColumnsProps}>{getColumns(rowData)}</Table.Row>
						{expanded &&
							getQueryRenderer(
								tableHeader,
								groupings,
								searchQuery,
								groupIndex,
								enabledColumns,
								{
									field: GLOBAL_FILTER_FIELD.PERSON_TIME_REG,
									operator: GLOBAL_FILTER_OPERATOR.IS,
									value: [rowData.id],
								},
								nextLevelProps,
								sortValue
							)}
					</>
				);
			}}
		</Table.Rows>
	);
};
