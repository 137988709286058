import moment from 'moment';
import Util from '../../../../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';
import {MODAL_TYPE, showModal} from '../../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import * as tracking from '../../../../../tracking';
import {dispatch, EVENT_ID} from '../../../../../containers/event_manager';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';

const formatDateISO = date => (date ? date.format('YYYY-MM-DD') : '');

export function getNextAvailableLock(project) {
	let latestPeriodValue = null;
	// Next lock is either the period after the latest locked period on the project
	// or the period containing the project start date if no locks exist yet.
	if (project.fixedPriceLocks.edges.some(fixedPriceLock => fixedPriceLock.node.locked)) {
		project.fixedPriceLocks.edges.forEach(lock => {
			if (lock.node.locked && (latestPeriodValue == null || latestPeriodValue < lock.node.startDate)) {
				latestPeriodValue = lock.node.startDate;
			}
		});
	} else {
		return Util.CreateNonUtcMomentDate(
			project.projectFirstDateYear,
			project.projectFirstDateMonth,
			project.projectFirstDateDay
		)?.startOf('month');
	}
	return moment(latestPeriodValue).add(1, 'month').startOf('month');
}

export function calculateOptions(project, nextAvailableLock, isLockingMonth) {
	const hasManualRecognition = hasFeatureFlag('manual_revenue_recognition');
	const options = [];
	if (project) {
		const projectFirstDate = Util.CreateNonUtcMomentDate(
			project.projectFirstDateYear,
			project.projectFirstDateMonth,
			project.projectFirstDateDay
		);
		const projectLastDate = Util.CreateNonUtcMomentDate(
			project.projectLastDateYear,
			project.projectLastDateMonth,
			project.projectLastDateDay
		)?.endOf('month');
		if (hasManualRecognition) {
			const lockDate = projectFirstDate;
			const lockedPeriods = project.fixedPriceLocks.edges
				.filter(lockedPeriod => lockedPeriod.node.locked)
				.map(lockedPeriod => lockedPeriod.node);
			while (lockDate.isBefore(projectLastDate)) {
				if (
					isLockingMonth
						? !isDateInLockedPeriod(lockDate, lockedPeriods)
						: isDateInLockedPeriod(lockDate, lockedPeriods)
				) {
					options.push({
						value: formatDateISO(lockDate),
						label: lockDate.format('MMM YYYY'),
					});
				}
				lockDate.add(1, 'month').startOf('month');
			}
		} else if (nextAvailableLock) {
			const nextAvailableLockClone = nextAvailableLock.clone();
			const now = moment().startOf('month');
			const lastLockablePeriod = now.isBefore(projectLastDate) ? now : projectLastDate;
			while (nextAvailableLockClone.isBefore(lastLockablePeriod)) {
				options.push({value: formatDateISO(nextAvailableLockClone), label: nextAvailableLockClone.format('MMM YYYY')});
				nextAvailableLockClone.add(1, 'month').startOf('month');
			}
		}
	}
	return options;
}

export function calculatePeriodLockIndexes(columnPeriods, locks) {
	const lockIndexMap = new Map();
	const {firstLockStartDate, lastLockEndDate} = getGlobalLockPeriod(locks);
	if (firstLockStartDate && lastLockEndDate) {
		// Loop over all periods
		for (let i = 0; i < columnPeriods.length; i++) {
			if (columnPeriods[i][0]) {
				const periodStart = moment(columnPeriods[i][0]).startOf('day');
				const periodEnd = moment(columnPeriods[i][1]).endOf('day');

				// Fully locked
				if (firstLockStartDate.isSameOrBefore(periodStart) && lastLockEndDate.isSameOrAfter(periodEnd)) {
					// Find lock for this period index
					locks.forEach(lock => {
						if (lock.node.locked) {
							const lockStart = moment(lock.node.startDate).startOf('day');
							const lockEnd = moment(lock.node.endDate).endOf('day');

							// Find the lock that is active for this periods start date
							if (periodStart.isBetween(lockStart, lockEnd, null, '[]')) {
								// Add lock to map and mark it as a full lock
								lockIndexMap.set(i, {...lock.node, fullLock: true});
							}
						}
					});
					// Partially locked
				} else if (
					periodStart.isBetween(firstLockStartDate, lastLockEndDate, null, '[]') ||
					periodEnd.isBetween(firstLockStartDate, lastLockEndDate, null, '[]')
				) {
					// Find lock for this period index
					locks.forEach(lock => {
						if (lock.node.locked) {
							const lockStart = moment(lock.node.startDate).startOf('day');
							const lockEnd = moment(lock.node.endDate).endOf('day');

							// Find the lock that is active for this periods start date or end date
							if (
								periodStart.isBetween(lockStart, lockEnd, null, '[]') ||
								periodEnd.isBetween(lockStart, lockEnd, null, '[]')
							) {
								// Add lock to map and mark it as a partial lock
								lockIndexMap.set(i, {...lock.node, fullLock: false});
							}
						}
					});
				}
			}
		}
	}
	return lockIndexMap;
}

function getGlobalLockPeriod(locks) {
	let firstLockStartDate = null;
	let lastLockEndDate = null;
	if (locks) {
		locks.forEach(lock => {
			if (lock && lock.node.locked) {
				const lockStart = moment(lock.node.startDate).startOf('day');
				const lockEnd = moment(lock.node.endDate).endOf('day');

				if (firstLockStartDate === null || lockStart.isBefore(firstLockStartDate)) {
					firstLockStartDate = lockStart.clone();
				}

				if (lastLockEndDate === null || lockEnd.isAfter(lastLockEndDate)) {
					lastLockEndDate = lockEnd.clone();
				}
			}
		});
	}

	return {firstLockStartDate, lastLockEndDate};
}

export function getLockedAmountTotal(locks, phaseBreakdowns) {
	const {firstLockStartDate, lastLockEndDate} = getGlobalLockPeriod(locks);
	if (!firstLockStartDate && !lastLockEndDate) {
		return {lockedWorkTotal: 0, lockedExpenseTotal: 0, lockedOffsetTotal: 0};
	}

	const workBreakdowns = phaseBreakdowns
		.filter(phaseBreakdown => phaseBreakdown.phaseId !== 'offset')
		.map(phaseBreakdown => phaseBreakdown.roleBreakdown)
		.flat()
		.filter(roleBreakdown => roleBreakdown.roleId !== 'expense')
		.map(roleBreakdown => roleBreakdown.breakdown)
		.flat();
	const expenseBreakdowns = phaseBreakdowns
		.filter(phaseBreakdown => phaseBreakdown.phaseId !== 'offset')
		.map(phaseBreakdown => phaseBreakdown.roleBreakdown)
		.flat()
		.filter(roleBreakdown => roleBreakdown.roleId === 'expense')
		.map(roleBreakdown => roleBreakdown.breakdown)
		.flat();
	const offsetBreakdowns = phaseBreakdowns
		.filter(phaseBreakdown => phaseBreakdown.phaseId === 'offset')
		.map(phaseBreakdown => phaseBreakdown.roleBreakdown)
		.flat()
		.map(roleBreakdown => roleBreakdown.breakdown)
		.flat();

	let lockedWorkTotal = 0,
		lockedExpenseTotal = 0,
		lockedOffsetTotal = 0;

	workBreakdowns.forEach(breakdown => {
		const date = Util.CreateUtcMomentDateFromString(breakdown.date);
		if (!date.isBefore(firstLockStartDate) && !date.isAfter(lastLockEndDate)) {
			lockedWorkTotal += breakdown.value;
		}
	});
	expenseBreakdowns.forEach(breakdown => {
		const date = Util.CreateUtcMomentDateFromString(breakdown.date);
		if (!date.isBefore(firstLockStartDate) && !date.isAfter(lastLockEndDate)) {
			lockedExpenseTotal += breakdown.value;
		}
	});
	offsetBreakdowns.forEach(breakdown => {
		const date = Util.CreateUtcMomentDateFromString(breakdown.date);
		if (!date.isBefore(firstLockStartDate) && !date.isAfter(lastLockEndDate)) {
			lockedOffsetTotal += breakdown.value;
		}
	});

	return {lockedWorkTotal, lockedExpenseTotal, lockedOffsetTotal, isUsingLocks: !!locks};
}

export function isDateInLockedPeriod(date, lockedPeriods) {
	return !!getLockedPeriodByDate(date, lockedPeriods);
}

export function getLockedPeriodByDate(date, lockedPeriods) {
	return lockedPeriods.find(lockedPeriod => date && date.isBetween(lockedPeriod.startDate, lockedPeriod.endDate, null, '[]'));
}

export function showFixedPriceLockModal(project, month, lockedAmounts) {
	showModal({
		type: MODAL_TYPE.FIXED_PRICE_LOCK,
		project,
		month,
		lockedAmounts,
	});

	tracking.trackElementClicked('Fixed Price Lock button');
	trackEvent('Fixed Price Lock Button', 'Clicked', {budgetType: project.budgetType});
}

export function showFixedPriceOpenLockModal(project, month) {
	showModal({
		type: MODAL_TYPE.FIXED_PRICE_OPEN_LOCK,
		project,
		month,
		callback: function () {
			dispatch(EVENT_ID.BUDGET_UPDATE_PROJECT);
		},
	});

	tracking.trackElementClicked('Fixed Price Open Lock button');
	trackEvent('Fixed Price Open Lock Button', 'Clicked', {budgetType: project.budgetType});
}
