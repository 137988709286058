import React from 'react';
import {GROUP_SECTION_WIDTH} from '../canvas-timeline/canvas_timeline_util';
import LoadingIndicator from './LoadingIndicator';
import Styled from 'styled-components';

export const TimelineLoading = ({shouldShow, timeline, style = null}) => {
	let loadingOverlayStyle;

	if (style) {
		loadingOverlayStyle = style;
	} else {
		const foregroundCanvas = document.getElementById('background-canvas');
		const canvasBounds = foregroundCanvas?.getBoundingClientRect();

		if (!canvasBounds) return null;

		let timelineCanvas = {};
		if (timeline?.state) {
			const {canvasOffsetLeft, canvasWidth} = timeline.state;
			timelineCanvas.left = canvasOffsetLeft;
			timelineCanvas.width = canvasWidth + GROUP_SECTION_WIDTH;
		}

		loadingOverlayStyle = {
			top: canvasBounds.y,
			height: `${canvasBounds.height}px`,
			...timelineCanvas,
		};
	}

	return shouldShow ? (
		<LoadingDataOverlayStyled style={loadingOverlayStyle}>
			<LoadingIndicator />
		</LoadingDataOverlayStyled>
	) : null;
};

const LoadingDataOverlayStyled = Styled.div`
    position: absolute;
    left: 0;
    width: 100%;
    background: #ffffffcc;
    backdrop-filter: blur(5px);
    z-index: 9;
    display: grid;
    align-content: space-evenly;
    justify-items: center;
`;
