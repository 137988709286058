import {createPaginationContainer, graphql} from 'react-relay';

const MonthByMonthProjectLoader = ({relay: {hasMore, isLoading, loadMore}, viewer, children, ...props}) => {
	const formatData = () => {
		return viewer.company.financialBasedProjects.edges.map(project => {
			const {aggregatedFinancialNumbers, timeAggregatedFinancialNumbers, expenseAggregatedFinancialNumbers} =
				project.node;
			return {
				project: {
					...project.node,
				},
				currency: viewer.company.currency,
				aggregatedFinancialNumbers: {
					...aggregatedFinancialNumbers,
				},
				timeAggregatedFinancialNumbers: {
					...timeAggregatedFinancialNumbers,
				},
				expenseAggregatedFinancialNumbers: {
					...expenseAggregatedFinancialNumbers,
				},
			};
		});
	};
	const loadMoreFunc = hasMore() ? loadMore : undefined;
	return children({data: {rows: formatData()}, loadMore: loadMoreFunc, loading: isLoading(), ...props});
};

export const monthByMonthProjectLoaderQuery = graphql`
	query MonthByMonthProjectLoader_Query(
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
		$searchQuery: TaskSearchQueryType!
		$timeSearchQuery: TaskSearchQueryType!
		$expenseSearchQuery: TaskSearchQueryType!
		$pageSize: Int!
		$cursor: String
		$selectedSingleValue: FinancialAggregatedNumber!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_month_by_month_project_loader")
			...MonthByMonthProjectLoader_viewer
				@arguments(
					searchQuery: $searchQuery
					timeSearchQuery: $timeSearchQuery
					expenseSearchQuery: $expenseSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					pageSize: $pageSize
					cursor: $cursor
					selectedSingleValue: $selectedSingleValue
				)
		}
	}
`;

export default createPaginationContainer(
	MonthByMonthProjectLoader,
	{
		viewer: graphql`
			fragment MonthByMonthProjectLoader_viewer on Viewer
			@argumentDefinitions(
				searchQuery: {type: "TaskSearchQueryType!"}
				timeSearchQuery: {type: "TaskSearchQueryType!"}
				expenseSearchQuery: {type: "TaskSearchQueryType!"}
				startYear: {type: "Int!"}
				startMonth: {type: "Int!"}
				startDay: {type: "Int!"}
				endYear: {type: "Int!"}
				endMonth: {type: "Int!"}
				endDay: {type: "Int!"}
				selectedSingleValue: {type: "FinancialAggregatedNumber!"}
				pageSize: {type: "Int!"}
				cursor: {type: "String"}
			) {
				id
				company {
					currency
					financialBasedProjects(
						searchQuery: $searchQuery
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
						aggregateLevel: MONTH
						aggregates: [$selectedSingleValue]
						first: $pageSize
						after: $cursor
					) @connection(key: "Company_financialBasedProjects") {
						edges {
							node {
								id
								companyProjectId
								...DeprecatedProjectIndicatorJS_project
								name
								readOnlyAccess
								fullAccessToProject
								projectColor
								useBaseline
								status
								baselineWinChance
								priorityLevel {
									id
									name
								}
								budgetType
								budgetBaseCurrency
								defaultPeriodBudgetType
								projectStartDay
								projectStartMonth
								projectStartYear
								projectEndDay
								projectEndMonth
								projectEndYear
								estimationUnit
								completion
								rateCard {
									id
									name
									currency
									parentRateCardId
								}
								client {
									id
									name
									logoId
									logoDefaultId
								}
								projectLabels(first: 1000) {
									edges {
										node {
											label {
												name
												color
											}
										}
									}
								}
								projectPersons(first: 1000000, contactsOnly: true) {
									edges {
										node {
											id
											isContactPerson
											person {
												id
												fullName
												firstName
												lastName
												profilePictureId
												profilePictureDefaultId
												initials
											}
										}
									}
								}
								currentProjectStatus {
									id
									color
									description
									person {
										id
										firstName
										lastName
										fullName
										profilePictureId
									}
								}
								aggregatedFinancialNumbers(
									startYear: $startYear
									startMonth: $startMonth
									startDay: $startDay
									endYear: $endYear
									endMonth: $endMonth
									endDay: $endDay
									aggregateLevel: MONTH
									aggregates: [$selectedSingleValue]
								)
								timeAggregatedFinancialNumbers: aggregatedFinancialNumbers(
									searchQuery: $timeSearchQuery
									startYear: $startYear
									startMonth: $startMonth
									startDay: $startDay
									endYear: $endYear
									endMonth: $endMonth
									endDay: $endDay
									aggregateLevel: MONTH
									aggregates: [$selectedSingleValue]
									fetchOwnData: true
								)
								expenseAggregatedFinancialNumbers: aggregatedFinancialNumbers(
									searchQuery: $expenseSearchQuery
									startYear: $startYear
									startMonth: $startMonth
									startDay: $startDay
									endYear: $endYear
									endMonth: $endMonth
									endDay: $endDay
									aggregateLevel: MONTH
									aggregates: [$selectedSingleValue]
									fetchOwnData: true
								)
								fixedPriceLocks(first: 1000) {
									edges {
										node {
											id
											startDate
											endDate
											locked
										}
									}
								}
							}
						}
					}
				}
			}
		`,
	},
	{
		direction: 'forward',
		getConnectionFromProps({viewer}) {
			return viewer.company.financialBasedProjects;
		},
		getFragmentVariables(prevVars) {
			return {
				...prevVars,
			};
		},
		getVariables(_, {cursor}, fragmentVariables) {
			return {
				...fragmentVariables,
				cursor,
			};
		},
		query: graphql`
			query MonthByMonthProjectLoaderRefetchQuery(
				$startYear: Int!
				$startMonth: Int!
				$startDay: Int!
				$endYear: Int!
				$endMonth: Int!
				$endDay: Int!
				$searchQuery: TaskSearchQueryType!
				$timeSearchQuery: TaskSearchQueryType!
				$expenseSearchQuery: TaskSearchQueryType!
				$pageSize: Int!
				$cursor: String
				$selectedSingleValue: FinancialAggregatedNumber!
			) {
				viewer {
					actualPersonId
					component(name: "project_portfolio_month_by_month_project_loader")
					...MonthByMonthProjectLoader_viewer
						@arguments(
							searchQuery: $searchQuery
							timeSearchQuery: $timeSearchQuery
							expenseSearchQuery: $expenseSearchQuery
							pageSize: $pageSize
							cursor: $cursor
							startYear: $startYear
							startMonth: $startMonth
							startDay: $startDay
							endYear: $endYear
							endMonth: $endMonth
							endDay: $endDay
							pageSize: $pageSize
							cursor: $cursor
							selectedSingleValue: $selectedSingleValue
						)
				}
			}
		`,
	}
);
