import React from 'react';
import Util from '../../../forecast-app/shared/util/util';
import {PersonDayCell, PersonDayCellInner, BillableTimeText, HiddenText} from './timesheets_team_page_styled';
import {useIntl} from 'react-intl';
import {isBillableSplitAllowed} from '../../../forecast-app/shared/util/cache/TimeRegistrationSettingsUtil';

const TimesheetsTotalsCell = ({
	totalWeekTime,
	totalWeekBillableTime,
	timeRegClassName,
	firstDayOfWeek,
	lockedDate,
	NotifyPersonViaEmail,
}) => {
	const intl = useIntl();

	const billableSplitAllowed = isBillableSplitAllowed();
	const lockedWeek = lockedDate && lockedDate.isSameOrAfter(firstDayOfWeek.clone().endOf('week'), 'd');

	return (
		<PersonDayCell className={timeRegClassName}>
			<PersonDayCellInner>
				<div className={'time-worked'}>
					<div className={'total' + (lockedWeek ? ' locked' : '')}>
						<span style={{display: 'flex', justifyContent: 'flex-end'}}>
							{NotifyPersonViaEmail && <NotifyPersonViaEmail />}
							{Util.convertMinutesToFullHour(totalWeekTime, intl, true)}
							{billableSplitAllowed ? <HiddenText> bil.</HiddenText> : null}
						</span>
						{billableSplitAllowed ? (
							<BillableTimeText>{`${Util.convertMinutesToFullHour(
								totalWeekBillableTime,
								intl,
								true
							)} bil.`}</BillableTimeText>
						) : null}
					</div>
				</div>
			</PersonDayCellInner>
		</PersonDayCell>
	);
};

export default TimesheetsTotalsCell;
