import DiscreteIntervalTree from './discrete-interval-tree/discrete-interval-tree';
import {getRecalculationInterval} from './heatmap/HeatmapLogic';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {SCALE_SETTINGS} from './canvas-timeline/canvas_timeline';
import {
	clearDrawnStepDataArrayMap,
	clearDrawnStepsMap,
	clearPersonHeatmapMetaData,
} from './canvas-timeline/canvas_timeline_util';
import {clearPersonWeekdaysCache} from '../scheduling/project_allocation_logic';
import CacheIntervalManager from './CacheIntervalManager';

// Eventually not needed, when setNeedsRecalculation is never called with defaults (unknown interval)
export const MIN_CANVAS_DATE = 0;
export const MAX_CANVAS_DATE = 18627;

// recalculation maps
const needsRecalculation = new Map();
const globalNeedsRecalculation = new Map();
const groupNeedsRecalculation = new Set();

export const interval = (start, end) => [start, end];

export default class RecalculationManager {
	static clearAll() {
		needsRecalculation.clear();
		globalNeedsRecalculation.clear();
		groupNeedsRecalculation.clear();
		clearDrawnStepDataArrayMap();
		clearDrawnStepsMap();
		clearPersonHeatmapMetaData();
		clearPersonWeekdaysCache();
		CacheIntervalManager.clearAll();
	}

	static setNeedsRecalculationForMinorStep(groupId, minorStep, startCanvasDate, endCanvasDate) {
		let tree;

		if (hasFeatureFlag('combined_mode_performance_improvements')) {
			tree = this.getNeedsRecalculationTree(groupId, minorStep, true);
		} else {
			const mapId = this.getMapId(groupId, minorStep);

			tree = needsRecalculation.get(mapId);
			if (!tree) {
				tree = new DiscreteIntervalTree();
				needsRecalculation.set(mapId, tree);
			}
		}

		let globalTree = globalNeedsRecalculation.get(minorStep);
		if (!globalTree) {
			globalTree = new DiscreteIntervalTree();
			globalNeedsRecalculation.set(minorStep, globalTree);
		}

		tree.add(startCanvasDate, endCanvasDate);
		globalTree.add(startCanvasDate, endCanvasDate);
		groupNeedsRecalculation.add(groupId);
	}

	static getMapId(groupId, minorStep) {
		return groupId + '-' + minorStep;
	}

	static getNeedsRecalculationTree(groupId, minorStep, initIfEmpty = false) {
		if (hasFeatureFlag('combined_mode_performance_improvements')) {
			let groupCache = needsRecalculation.get(groupId);
			if (!groupCache) {
				groupCache = new Map();
				needsRecalculation.set(groupId, groupCache);

				if (!initIfEmpty) {
					return null;
				}
			}

			if (!initIfEmpty && groupCache.size === 0) {
				return null;
			}

			let tree = groupCache.get(minorStep) || null;
			if (!tree && initIfEmpty) {
				tree = new DiscreteIntervalTree();
				groupCache.set(minorStep, tree);
			}

			return tree;
		} else {
			return needsRecalculation.get(this.getMapId(groupId, minorStep));
		}
	}

	static recalculateItemHeatmap(item, interval) {
		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			return;
		}
		interval = interval || getRecalculationInterval(item);
		const heatmapGroupIds = item.getHeatmapGroupIds()?.values();
		if (heatmapGroupIds) {
			for (const heatmapGroupId of heatmapGroupIds) {
				this.setNeedsRecalculation(heatmapGroupId, interval);
			}
		}
	}

	static setNeedsRecalculation(groupId, interval = [MIN_CANVAS_DATE, MAX_CANVAS_DATE]) {
		const [startCanvasDate, endCanvasDate] = interval;

		if (hasFeatureFlag('combined_mode_performance_improvements')) {
			for (const scaleSetting of Object.values(SCALE_SETTINGS)) {
				const {minorStep} = scaleSetting;

				const intervals = CacheIntervalManager.getCacheIntervalsInRange(
					groupId,
					minorStep,
					startCanvasDate,
					endCanvasDate
				);

				for (const interval of intervals) {
					const {start, end} = interval;
					this.setNeedsRecalculationForMinorStep(groupId, minorStep, start, end);
				}
			}
		} else {
			this.setNeedsRecalculationForMinorStep(groupId, SCALE_SETTINGS.DAY.minorStep, startCanvasDate, endCanvasDate);
			this.setNeedsRecalculationForMinorStep(groupId, SCALE_SETTINGS.WEEK.minorStep, startCanvasDate, endCanvasDate);
			this.setNeedsRecalculationForMinorStep(groupId, SCALE_SETTINGS.MONTH.minorStep, startCanvasDate, endCanvasDate);
			this.setNeedsRecalculationForMinorStep(groupId, SCALE_SETTINGS.QUARTER.minorStep, startCanvasDate, endCanvasDate);
		}
	}

	static clearNeedsRecalculation(groupId, minorStep, canvasStartDate, canvasDateEnd) {
		if (needsRecalculation.size > 0) {
			const tree = this.getNeedsRecalculationTree(groupId, minorStep);

			if (tree) {
				const wasRemoved = tree.remove(canvasStartDate, canvasDateEnd);

				if (
					wasRemoved &&
					!this.needsRecalculation(groupId, SCALE_SETTINGS.DAY.minorStep, MIN_CANVAS_DATE, MAX_CANVAS_DATE)
				) {
					groupNeedsRecalculation.delete(groupId);
				}
			}
		}
	}

	static clearGlobalNeedsRecalculation(minorStep, canvasStartDate, canvasDateEnd) {
		const globalTree = globalNeedsRecalculation.get(minorStep);

		if (globalTree) {
			globalTree.remove(canvasStartDate, canvasDateEnd);
		}
	}

	static printIntervals(groupId, minorStep) {
		const tree = this.getNeedsRecalculationTree(groupId, minorStep);

		if (tree) {
			return tree.toString();
		}

		return '';
	}

	static checkConsistency(groupId, minorStep) {
		const tree = this.getNeedsRecalculationTree(groupId, minorStep);

		if (tree) {
			return tree.checkConsistency();
		}
	}

	static needsRecalculation(groupId, minorStep, canvasStartDate, canvasDateEnd) {
		const tree = this.getNeedsRecalculationTree(groupId, minorStep);

		if (tree) {
			return tree.overlaps(canvasStartDate, canvasDateEnd);
		}

		return false;
	}

	static anyGroupNeedsRecalculation(minorStep, canvasStartDate, canvasDateEnd) {
		let globalTree = globalNeedsRecalculation.get(minorStep);

		if (globalTree) {
			return globalTree.overlaps(canvasStartDate, canvasDateEnd);
		}

		return false;
	}

	static groupNeedsRecalculation(pageComponent, groupId) {
		const {hasDrawnStepDataArray, anyGroupNeedsRecalculation} = pageComponent.timeline;
		return !hasDrawnStepDataArray || (anyGroupNeedsRecalculation && groupNeedsRecalculation.has(groupId));
	}
}
