import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Person from '../../../forecast-app/shared/components/person/person';

class UtilizationListDummy extends Component {
	createDummySprint(
		index,
		name,
		role,
		availableHours,
		scheduledHours,
		scheduledPercentage,
		actualBillableHours,
		actualBillablePercentage,
		projectTimeHours,
		projectTimePercentage,
		nonProjectTimeHours,
		nonProjectTimePercentage,
		registered,
		overtimeHours,
		overtimePercentage,
		billabilityPercentage,
		billabilityHours,
		cardUtilization
	) {
		const person = {};
		person.name = (
			<Person key={index} name={name} role={''} showName={true} showRole={false} imageSrc={null} imageSize="medium" />
		);
		person.role = role;
		person.availableHours = this.props.intl.formatMessage({id: 'common.x_hours'}, {hours: availableHours});
		person.scheduledHours = this.props.intl.formatMessage({id: 'common.x_hours'}, {hours: scheduledHours});
		person.scheduledPercentage = this.props.intl.formatMessage(
			{id: 'common.x_percentage'},
			{percentage: scheduledPercentage}
		);
		person.actualBillableHours = this.props.intl.formatMessage({id: 'common.x_hours'}, {hours: actualBillableHours});
		person.actualBillablePercentage = this.props.intl.formatMessage(
			{id: 'common.x_percentage'},
			{percentage: actualBillablePercentage}
		);
		person.projectTimeHours = this.props.intl.formatMessage({id: 'common.x_hours'}, {hours: projectTimeHours});
		person.projectTimePercentage = this.props.intl.formatMessage(
			{id: 'common.x_percentage'},
			{percentage: projectTimePercentage}
		);
		person.nonProjectTimeHours = this.props.intl.formatMessage({id: 'common.x_hours'}, {hours: nonProjectTimeHours});
		person.nonProjectTimePercentage = this.props.intl.formatMessage(
			{id: 'common.x_percentage'},
			{percentage: nonProjectTimePercentage}
		);
		person.registered = this.props.intl.formatMessage({id: 'common.x_hours'}, {hours: registered});
		person.overtimeHours = this.props.intl.formatMessage({id: 'common.x_hours'}, {hours: overtimeHours});
		person.overtimePercentage = this.props.intl.formatMessage(
			{id: 'common.x_percentage'},
			{percentage: overtimePercentage}
		);
		person.billabilityPercentage = this.props.intl.formatMessage(
			{id: 'common.x_percentage'},
			{percentage: billabilityPercentage}
		);
		person.billabilityHours = this.props.intl.formatMessage({id: 'common.x_hours'}, {hours: billabilityHours});
		person.cardUtilization = this.props.intl.formatMessage({id: 'common.x_percentage'}, {percentage: cardUtilization});
		return person;
	}

	render() {
		let data = [];
		data.push(
			this.createDummySprint(1, 'first person', 'Developer', 100, 100, 100, 80, 80, 20, 20, 40, 0, 0, 100, 100, 40)
		);
		data.push(this.createDummySprint(2, 'second person', 'Designer', 100, 100, 100, 80, 80, 20, 20, 40, 0, 0, 50, 50, 45));
		data.push(this.createDummySprint(3, 'third person', 'Tester', 100, 100, 100, 80, 80, 20, 20, 40, 0, 0, 100, 100, 55));
		return (
			<InsightList
				data={data}
				componentName={this.props.componentName}
				allColumns={this.props.allColumns}
				activeColumns={this.props.activeColumns}
				scrollElement={this.props.scrollElement}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

UtilizationListDummy.propTypes = {
	title: PropTypes.string,
};

export default injectIntl(UtilizationListDummy);
