/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TimeTotalsLoader_viewer$ref: FragmentReference;
declare export opaque type TimeTotalsLoader_viewer$fragmentType: TimeTotalsLoader_viewer$ref;
export type TimeTotalsLoader_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string
  |},
  +project: ?{|
    +rateCard: ?{|
      +currency: ?string
    |},
    +financialNumbers: ?{|
      +baselineMinutes: ?number,
      +baselineVsRegisteredMinutes: ?number,
      +baselineVsTotalTimeAtCompletion: ?number,
      +registeredMinutes: ?number,
      +scopeApprovedMinutes: ?number,
      +scopeApprovedVsRegisteredMinutes: ?number,
      +forecastTimeToComplete: ?number,
      +totalTimeAtCompletion: ?number,
      +scopeApprovedVsTotalTimeAtCompletion: ?number,
    |},
  |},
  +$refType: TimeTotalsLoader_viewer$ref,
|};
export type TimeTotalsLoader_viewer$data = TimeTotalsLoader_viewer;
export type TimeTotalsLoader_viewer$key = {
  +$data?: TimeTotalsLoader_viewer$data,
  +$fragmentRefs: TimeTotalsLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeTotalsLoader_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "endDay",
              "variableName": "endDay"
            },
            {
              "kind": "Variable",
              "name": "endMonth",
              "variableName": "endMonth"
            },
            {
              "kind": "Variable",
              "name": "endYear",
              "variableName": "endYear"
            },
            {
              "kind": "Variable",
              "name": "startDay",
              "variableName": "startDay"
            },
            {
              "kind": "Variable",
              "name": "startMonth",
              "variableName": "startMonth"
            },
            {
              "kind": "Variable",
              "name": "startYear",
              "variableName": "startYear"
            }
          ],
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "financialNumbers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsRegisteredMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "baselineVsTotalTimeAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "registeredMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scopeApprovedMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scopeApprovedVsRegisteredMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastTimeToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalTimeAtCompletion",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scopeApprovedVsTotalTimeAtCompletion",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ded4765b90ae7c10612114158f410b88';

module.exports = node;
