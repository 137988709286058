import React, { useMemo, useState } from 'react';
import { Alert, FlexRow, Modal, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import BulkUpdateExpenseItemsMutation from '../../../mutations/bulk_update_expense_items_mutation';
import Util from '../../../forecast-app/shared/util/util';
import { partitionArray } from '../../../forecast-app/shared/util/array-utils';
import { FieldLabel, PlaceholderItem } from './ModalComponents';
import ConditionalDropdown from '../../dropdowns/conditional-dropdown/ConditionalDropdown';
import { getNotNullishNodes } from '../../../forecast-app/shared/util/NotNullPredicate';
import { createFragmentContainer, graphql } from 'react-relay';
const PersonOption = (props) => {
    const { fullName } = props.person || {};
    return (React.createElement(FlexRow, { justifyContent: 'flex-start' },
        React.createElement(Text, { size: '2', noWrap: true }, fullName || '')));
};
const findCommonPerson = (expenseItems) => {
    var _a;
    if (expenseItems.length === 0) {
        return null;
    }
    const firstPersonId = (_a = expenseItems[0].person) === null || _a === void 0 ? void 0 : _a.id;
    return expenseItems.every(({ person }) => (person === null || person === void 0 ? void 0 : person.id) === firstPersonId) ? expenseItems[0].person : null;
};
const ExpenseItemChangePersonModal = ({ selectedRows, closeModal, viewer }) => {
    const { formatMessage } = useIntl();
    const [selectedLockedRows, selectedUnlockedRows] = useMemo(() => partitionArray(selectedRows, item => item.locked), [selectedRows]);
    const possiblePersons = useMemo(() => { var _a, _b; return getNotNullishNodes((_b = (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.allPersons) === null || _b === void 0 ? void 0 : _b.edges); }, [viewer]);
    const commonPerson = useMemo(() => findCommonPerson(selectedUnlockedRows), [selectedUnlockedRows]);
    const [selectedPerson, setSelectedPerson] = useState(commonPerson);
    const numLockedExpenses = selectedLockedRows.length;
    const numTotalExpenses = selectedRows.length;
    const onUpdate = () => {
        closeModal();
        if (selectedPerson === null || (selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.id) === (commonPerson === null || commonPerson === void 0 ? void 0 : commonPerson.id)) {
            return;
        }
        const onSuccess = () => {
            createToast({
                duration: 5000,
                message: formatMessage({
                    id: selectedUnlockedRows.length > 1
                        ? 'expense_management.has_been_updated_bulk'
                        : 'expense_management.has_been_updated',
                }),
            });
        };
        Util.CommitMutation(BulkUpdateExpenseItemsMutation, {
            ids: selectedUnlockedRows.map(({ id }) => id),
            personId: selectedPerson === null || selectedPerson === void 0 ? void 0 : selectedPerson.id,
        }, onSuccess);
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "expense_management.modal.change_person.change_person" })),
        React.createElement(Modal.Content, null,
            React.createElement(FieldLabel, { helperTextId: "expense_management.modal.change_person.assign_person_all_selected", labelId: "expense_management.modal.change_person.person" },
                React.createElement(ConditionalDropdown, { options: possiblePersons, useTriggerWidth: true, autoSelect: false, renderOption: person => React.createElement(PersonOption, { person: person }), onSelect: person => person && setSelectedPerson(person), "data-cy": 'select-person' }, selectedPerson ? (React.createElement(PersonOption, { person: selectedPerson })) : (React.createElement(PlaceholderItem, { messageId: 'expense_management.modal.change_person.assign_person' })))),
            selectedLockedRows.length > 0 && (React.createElement(Alert, { text: formatMessage({ id: 'expense_management.modal.change_person.locked_alert' }, { numLockedExpenses, numTotalExpenses }), type: Alert.TYPE.INFORMATION }))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: onUpdate },
                React.createElement(FormattedMessage, { id: "expense_management.actions.confirm_changes" })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export const ExpenseItemChangePersonModalQuery = graphql `
	query ExpenseItemChangePersonModalQuery {
		viewer {
			actualPersonId
			component(name: "ExpenseItemChangePersonModal")
			...ExpenseItemChangePersonModal_viewer
		}
	}
`;
export default createFragmentContainer(ExpenseItemChangePersonModal, {
    viewer: graphql `
		fragment ExpenseItemChangePersonModal_viewer on Viewer {
			company {
				allPersons(first: 10000, excludeClientUsers: true) @connection(key: "Company_allPersons", filters: []) {
					edges {
						node {
							id
							fullName
							profilePictureId
						}
					}
				}
			}
		}
	`,
});
