/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HighLevelBudgetNumbers_company$ref = any;
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BudgetData_company$ref: FragmentReference;
declare export opaque type BudgetData_company$fragmentType: BudgetData_company$ref;
export type BudgetData_company = {|
  +tier: ?TierType,
  +$fragmentRefs: HighLevelBudgetNumbers_company$ref,
  +$refType: BudgetData_company$ref,
|};
export type BudgetData_company$data = BudgetData_company;
export type BudgetData_company$key = {
  +$data?: BudgetData_company$data,
  +$fragmentRefs: BudgetData_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BudgetData_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tier",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HighLevelBudgetNumbers_company"
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '8eb87cf1eb410c763f34620fbc3e621a';

module.exports = node;
