import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import ChartJsBar from '../../chartjs/chart_js_bar';

class PlannedVsSpendDummy extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}
	render() {
		const {formatMessage} = this.props.intl;
		const data = {
			labels: [],
			datasets: [],
		};
		data.datasets.push({
			label: formatMessage({id: 'common.forecast'}),
			backgroundColor: '#EA82FD',
			stack: 'Stack 0',
			data: [48],
			maxBarThickness: 60,
		});
		data.datasets.push({
			label: formatMessage({id: 'common.reported'}),
			backgroundColor: '#44B4FF',
			stack: 'Stack 1',
			data: [32],
			maxBarThickness: 60,
		});
		return <ChartJsBar data={data} yAxisLabel={formatMessage({id: 'common.hours'})} height={150} type="horizontalBar" />;
	}
}
export default injectIntl(PlannedVsSpendDummy);
