import {getFilterFunctions} from '../../../forecast-app/shared/components/filters/filter_logic';

export const getTeamTimeshetsPreappliedFilter = (projectId, persons) => {
	const personIds = persons
		.filter(person => person.node.assignedProjectIds?.some(assignedProjectId => assignedProjectId === projectId))
		.map(person => person.node.id);
	return {
		person: {
			teamMember: personIds,
		},
	};
};

export const getTeamTimesheetsFilters = preAppliedFilters => {
	let filtersKey = 'timesheets-team-filter-v4';

	if (preAppliedFilters) {
		return getFilterFunctions(preAppliedFilters);
	} else if (localStorage.getItem(`${filtersKey}`)) {
		const filters = JSON.parse(localStorage.getItem(`${filtersKey}`));
		return getFilterFunctions(filters);
	} else {
		const filters = {
			person: {},
		};
		return getFilterFunctions(filters);
	}
};
