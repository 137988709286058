import React from 'react';
import {useIntl} from 'react-intl';
import {IconText, Table} from 'web-components';
import ProjectLoader, {projectLoaderQuery} from './ProjectLoader';
import {filtersToSearchQuery, getPhaseName, getRoleName} from '../ProjectPortfolioReportUtils';
import PhaseLoader, {phaseLoaderQuery} from './PhaseLoader';
import RoleLoader, {roleLoaderQuery} from './RoleLoader';
import ProjectPortfolioPeriodDownload, {projectPortfolioPeriodDownloadQuery} from './ProjectPortfolioPeriodDownload';
import {NO_ID, PHASE_BREAKDOWN_TYPE} from '../../../../constants';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {LoadMore} from '../../../loaders/LoadMore';
import ProjectPortfolioPeriodRow from './ProjectPortfolioPeriodRow';
import Util from '../../../../forecast-app/shared/util/util';
import DeprecatedProjectIndicatorJS from '../../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';

const ProjectPortfolioPeriodProjectRows = ({
	setCsvDownloadFunction,
	hasBaseline,
	pageSize,
	startDate,
	endDate,
	filters,
	boolEyeOptions,
	levelProps,
	projectGroupId,
	programId,
	clientId,
	footerRowData,
}) => {
	const intl = useIntl();

	const addedFilters = [];
	if (projectGroupId) {
		const isNoConnectedProject = Util.getUUIdFromBase64String(projectGroupId) === NO_ID;
		addedFilters.push({key: 'projectGroup', operator: 'IS', value: isNoConnectedProject ? [null] : projectGroupId});
	}
	if (programId) {
		const isNoProgram = Util.getUUIdFromBase64String(programId) === NO_ID;
		addedFilters.push({key: 'program', operator: 'IS', value: isNoProgram ? [null] : programId});
	}
	if (clientId) {
		const isNoClient = Util.getUUIdFromBase64String(clientId) === NO_ID;
		addedFilters.push({key: 'client', operator: 'IS', value: isNoClient ? [null] : clientId});
	}

	return (
		<LoadMore
			key="query-render-project"
			query={projectLoaderQuery}
			variables={{
				searchQuery: filtersToSearchQuery(filters, addedFilters),
				startYear: startDate ? startDate.year() : undefined,
				startMonth: startDate ? startDate.month() + 1 : undefined,
				startDay: startDate ? startDate.date() : undefined,
				endYear: endDate ? endDate.year() : undefined,
				endMonth: endDate ? endDate.month() + 1 : undefined,
				endDay: endDate ? endDate.date() : undefined,
				pageSize: pageSize,
			}}
			loader={<ProjectLoader programId={programId} />}
		>
			{({data, loadMore}) => {
				if (!projectGroupId && !programId && !clientId) {
					setCsvDownloadFunction(() => {
						showModal({
							type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
							componentName: 'project_portfolio_period_project_csv_loader',
							query: projectPortfolioPeriodDownloadQuery,
							variables: {
								searchQuery: filtersToSearchQuery(filters),
								startYear: startDate ? startDate.year() : undefined,
								startMonth: startDate ? startDate.month() + 1 : undefined,
								startDay: startDate ? startDate.date() : undefined,
								endYear: endDate ? endDate.year() : undefined,
								endMonth: endDate ? endDate.month() + 1 : undefined,
								endDay: endDate ? endDate.date() : undefined,
								pageSize: 1000000,
							},
							createCsvDownload: <ProjectPortfolioPeriodDownload />,
							startDate,
							endDate,
							boolEyeOptions,
						});
					});
				}
				if (footerRowData) {
					data.rows.push({...footerRowData, isFooter: true});
				}
				if (data.programUnallocatedRevenue && !filters?.project?.length < 1) {
					data.rows.push({
						name: 'Unallocated program revenue',
						currency: data.currency,
						financialNumbers: {
							totalRevenueRecognition: data.programUnallocatedRevenue,
						},
						isFooter: true,
					});
				}
				return (
					<Table.Rows data={data} canExpand={true} loadMore={loadMore} autoload={true} {...levelProps}>
						{({rowData, tableColumnsProps, expanded, nextLevelProps: phaseLevelProps}) => {
							const fullAccessToProject = rowData?.project?.fullAccessToProject;
							return !rowData.isFooter ? (
								<>
									<ProjectPortfolioPeriodRow
										financialNumbers={rowData.financialNumbers}
										rowData={rowData}
										tableColumnProps={tableColumnsProps}
										canExpand={true}
										headerColumn={
											<IconText
												key={rowData.project.id}
												text={
													fullAccessToProject
														? rowData.project.name
														: intl.formatMessage({id: 'common.no_project_access'})
												}
											>
												<DeprecatedProjectIndicatorJS
													project={rowData.project}
													disableLink={rowData.project.readOnlyAccess}
												/>
											</IconText>
										}
										hasBaseline={hasBaseline}
									/>
									{expanded && (
										<LoadMore
											key="query-render-phase"
											query={phaseLoaderQuery}
											loader={<PhaseLoader />}
											variables={{
												projectId: rowData.project.id,
												startYear: startDate ? startDate.year() : undefined,
												startMonth: startDate ? startDate.month() + 1 : undefined,
												startDay: startDate ? startDate.date() : undefined,
												endYear: endDate ? endDate.year() : undefined,
												endMonth: endDate ? endDate.month() + 1 : undefined,
												endDay: endDate ? endDate.date() : undefined,
											}}
										>
											{({data: phaseBreakdownData}) => {
												return (
													<Table.Rows data={phaseBreakdownData} {...phaseLevelProps} canExpand={true}>
														{({
															rowData: phaseRowData,
															tableColumnsProps: phaseTableColumnProps,
															expanded: phaseExpanded,
															nextLevelProps: roleLevelProps,
														}) => {
															return (
																<>
																	<ProjectPortfolioPeriodRow
																		financialNumbers={phaseRowData.financialNumbers}
																		rowData={phaseRowData}
																		tableColumnProps={phaseTableColumnProps}
																		headerColumn={getPhaseName(intl, phaseRowData)}
																		canExpand={
																			(phaseRowData.breakdownType ===
																				PHASE_BREAKDOWN_TYPE.PHASE &&
																				phaseRowData.phase.id) ||
																			phaseRowData.breakdownType ===
																				PHASE_BREAKDOWN_TYPE.NO_PHASE
																		}
																	/>
																	{phaseExpanded && (
																		<LoadMore
																			key="query-render-role"
																			query={roleLoaderQuery}
																			loader={<RoleLoader />}
																			variables={{
																				projectId: rowData.project.id,
																				phaseId:
																					phaseRowData.breakdownType ===
																						PHASE_BREAKDOWN_TYPE.PHASE &&
																					phaseRowData.phase.id
																						? phaseRowData.phase.id
																						: phaseRowData.breakdownType ===
																						  PHASE_BREAKDOWN_TYPE.NO_PHASE
																						? 0
																						: null,
																				startYear: startDate
																					? startDate.year()
																					: undefined,
																				startMonth: startDate
																					? startDate.month() + 1
																					: undefined,
																				startDay: startDate
																					? startDate.date()
																					: undefined,
																				endYear: endDate ? endDate.year() : undefined,
																				endMonth: endDate
																					? endDate.month() + 1
																					: undefined,
																				endDay: endDate ? endDate.date() : undefined,
																			}}
																		>
																			{({data: roleBreakdownData}) => {
																				return (
																					<Table.Rows
																						data={roleBreakdownData}
																						{...roleLevelProps}
																						canExpand={false}
																					>
																						{({
																							rowData: roleRowData,
																							tableColumnsProps:
																								roleTableColumnProps,
																						}) => {
																							return (
																								<ProjectPortfolioPeriodRow
																									financialNumbers={
																										roleRowData.financialNumbers
																									}
																									rowData={roleRowData}
																									tableColumnProps={
																										roleTableColumnProps
																									}
																									headerColumn={getRoleName(
																										intl,
																										roleRowData
																									)}
																								/>
																							);
																						}}
																					</Table.Rows>
																				);
																			}}
																		</LoadMore>
																	)}
																</>
															);
														}}
													</Table.Rows>
												);
											}}
										</LoadMore>
									)}
								</>
							) : (
								<ProjectPortfolioPeriodRow
									financialNumbers={rowData.financialNumbers}
									rowData={rowData}
									tableColumnProps={tableColumnsProps}
									canExpand={false}
									headerColumn={rowData.name || ''}
									hasBaseline={hasBaseline}
								/>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default ProjectPortfolioPeriodProjectRows;
