import {
	getAnonymizedId,
	getCombinedTaskMode,
	getHolidayDates,
	getMinutesInWeekdaysNew,
	getVisualizationMode,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import {getPersonWorkingHoursAndMinutes, processTask, processTaskActualMode, processTimeReg} from './HeatmapLogic';
import {getWeekdaysBetweenDatesNew} from '../../scheduling/project_allocation_logic';
import {trackPerformance} from '../canvas-timeline/canvas_timeline_performance_track';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import DataManager from '../DataManager';
import Util from '../../../forecast-app/shared/util/util';

const processAnonymizedAllocations = (pageComponent, noAccessHeatMaps, isInCombinedActualMode, todayDate) => {
	const {anonymizedAllocations, nonWorkingDaysMap} = pageComponent.state.data;

	trackPerformance('ProcessAnonymizedAllocations', () => {
		if (anonymizedAllocations) {
			for (let allocationIndex = 0; allocationIndex < anonymizedAllocations.length; allocationIndex++) {
				const allocation = anonymizedAllocations[allocationIndex];
				const {
					canvasTimelineStartDate: startDate,
					canvasTimelineEndDate: endDate,
					isSoft,
					baselineWinChance,
				} = allocation;

				const winPercentage = baselineWinChance ? baselineWinChance : 1;
				const anonymizedId = getAnonymizedId(allocation);

				let personHeatMapData = noAccessHeatMaps.get(allocation.personId);

				if (!personHeatMapData) {
					personHeatMapData = new Map();
				}

				for (let iteratorDate = startDate; iteratorDate <= endDate; iteratorDate++) {
					const onlyPlanned = isInCombinedActualMode && todayDate > iteratorDate;
					let minutesAllocated = getMinutesInWeekdaysNew(
						allocation,
						getWeekdaysBetweenDatesNew(iteratorDate, iteratorDate, nonWorkingDaysMap, allocation.personId)
					);
					let minutesAllocatedSoft = isSoft ? minutesAllocated : 0;
					let minutesAllocatedSoftWin = minutesAllocatedSoft * winPercentage;
					let minutesAllocatedHard = !isSoft ? minutesAllocated : 0;

					let heatmapDeltaData = {
						minutesPlannedSoft: minutesAllocatedSoft,
						minutesPlannedSoftWin: minutesAllocatedSoftWin,
						minutesPlannedHard: minutesAllocatedHard,
					};
					if (!onlyPlanned) {
						heatmapDeltaData = {
							...heatmapDeltaData,
							minutesAllocated,
							minutesAllocatedSoft,
							minutesAllocatedSoftWin,
							minutesAllocatedHard,
						};
					}

					updateNoAccessHeatmapCache(personHeatMapData, iteratorDate, anonymizedId, heatmapDeltaData);
				}

				noAccessHeatMaps.set(allocation.personId, personHeatMapData);
			}
		}
	});
};

const processAnonymizedTasks = (pageComponent, noAccessHeatMaps) => {
	const {data} = pageComponent.state;
	const {anonymizedTasks, personMap, nonWorkingDaysMap} = data;

	trackPerformance('ProcessAnonymizedTasks', () => {
		if (anonymizedTasks) {
			for (let taskIndex = 0; taskIndex < anonymizedTasks.length; taskIndex++) {
				const task = anonymizedTasks[taskIndex];
				const anonymizedId = getAnonymizedId(task);
				const {assignedPersons} = task;

				for (let personIndex = 0; personIndex < assignedPersons.length; personIndex++) {
					const person = personMap.get(assignedPersons[personIndex]);

					let personHeatMapData = noAccessHeatMaps.get(person.id);

					if (!personHeatMapData) {
						personHeatMapData = new Map();
					}

					const taskStartDate = task.canvasTimelineStartDate;
					const taskEndDate = task.canvasTimelineEndDate;

					//If a task is outside the employment period, calculate it as having a shorter duration
					let taskPersonActualStartDate = taskStartDate;
					if (person.startDate && taskStartDate < person.startDate) {
						taskPersonActualStartDate = person.startDate;
					}

					let taskPersonActualEndDate = taskEndDate;
					if (person.endDate && taskEndDate > person.endDate) {
						taskPersonActualEndDate = person.endDate;
					}

					const {personWorkingMinutes} = getPersonWorkingHoursAndMinutes(person);
					const {holidaysExcludingDaysOff} = getHolidayDates(person, data, personWorkingMinutes);

					for (
						let iteratorDate = taskPersonActualStartDate;
						iteratorDate <= taskPersonActualEndDate;
						iteratorDate++
					) {
						let taskMinutesAllocated = 0;

						[taskMinutesAllocated] = processTask(
							person,
							{task},
							taskMinutesAllocated,
							null,
							iteratorDate,
							iteratorDate,
							taskStartDate,
							taskEndDate,
							personWorkingMinutes,
							holidaysExcludingDaysOff,
							nonWorkingDaysMap,
							null
						);

						updateNoAccessHeatmapCache(personHeatMapData, iteratorDate, anonymizedId, {
							taskMinutesAllocated,
							plannedTaskMinutes: taskMinutesAllocated,
						});
					}

					noAccessHeatMaps.set(person.id, personHeatMapData);
				}
			}
		}
	});
};

const processAnonymizedTasksActualMode = (pageComponent, noAccessHeatMaps) => {
	const {data, todayDate, schedulingOptions} = pageComponent.state;
	const {anonymizedTasks, personMap, nonWorkingDaysMap, company} = data;
	const isInCombinationMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

	trackPerformance('ProcessAnonymizedTasksActualMode', () => {
		if (anonymizedTasks) {
			for (let taskIndex = 0; taskIndex < anonymizedTasks.length; taskIndex++) {
				const task = anonymizedTasks[taskIndex];
				const anonymizedId = getAnonymizedId(task);
				const {assignedPersons} = task;

				for (let personIndex = 0; personIndex < assignedPersons.length; personIndex++) {
					const person = personMap.get(assignedPersons[personIndex]);
					let personHeatMapData = noAccessHeatMaps.get(person.id);

					if (!personHeatMapData) {
						personHeatMapData = new Map();
					}

					const taskStartDate = task.canvasTimelineStartDate;
					const taskEndDate = task.canvasTimelineEndDate;

					//If a task is outside the employment period, calculate it as having a shorter duration
					let taskPersonActualStartDate = taskStartDate;
					if (person.startDate && taskStartDate < person.startDate) {
						taskPersonActualStartDate = person.startDate;
					}

					let taskPersonActualEndDate = taskEndDate;
					if (person.endDate && taskEndDate > person.endDate) {
						taskPersonActualEndDate = person.endDate;
					}

					const {personWorkingMinutes} = getPersonWorkingHoursAndMinutes(person);
					const {holidaysExcludingDaysOff} = getHolidayDates(person, data, personWorkingMinutes);

					for (
						let iteratorDate = taskPersonActualStartDate;
						iteratorDate <= taskPersonActualEndDate;
						iteratorDate++
					) {
						let taskMinutesAllocated = 0;
						let plannedTaskMinutes = 0;

						const isInThePast = iteratorDate < todayDate;

						if (isInThePast) {
							[plannedTaskMinutes] = processTask(
								person,
								{task},
								plannedTaskMinutes,
								null,
								iteratorDate,
								iteratorDate,
								taskStartDate,
								taskEndDate,
								personWorkingMinutes,
								holidaysExcludingDaysOff,
								nonWorkingDaysMap,
								isInCombinationMode
							);
						} else {
							[taskMinutesAllocated] = processTaskActualMode(
								{task},
								taskMinutesAllocated,
								null,
								iteratorDate,
								iteratorDate,
								taskStartDate,
								taskEndDate,
								todayDate,
								personWorkingMinutes,
								holidaysExcludingDaysOff,
								nonWorkingDaysMap,
								person,
								isInCombinationMode
							);
						}

						updateNoAccessHeatmapCache(personHeatMapData, iteratorDate, anonymizedId, {
							taskMinutesAllocated,
							plannedTaskMinutes,
						});
					}

					noAccessHeatMaps.set(person.id, personHeatMapData);
				}
			}
		}
	});
};

function updateNoAccessHeatmapCache(personHeatMapData, canvasTimelineDate, anonymizedId, heatmapDeltaData) {
	let personProjectsHeatmapMap = personHeatMapData.get(canvasTimelineDate);
	if (!personProjectsHeatmapMap) {
		personProjectsHeatmapMap = new Map();
	}

	let [
		,
		,
		// startDate
		// endDate
		minutesAllocated,
		minutesAvailable,
		minutesAllocatedSoft,
		minutesAllocatedSoftWin,
		minutesAllocatedHard,
		timeRegMinutes,
		minutesPlannedSoft,
		minutesPlannedSoftWin,
		minutesPlannedHard,
		taskMinutesAllocated,
		plannedTaskMinutes,
	] = personProjectsHeatmapMap.get(anonymizedId) || [undefined, undefined, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

	minutesAllocated += heatmapDeltaData.minutesAllocated || 0;
	minutesAvailable += heatmapDeltaData.minutesAvailable || 0;
	minutesAllocatedSoft += heatmapDeltaData.minutesAllocatedSoft || 0;
	minutesAllocatedSoftWin += heatmapDeltaData.minutesAllocatedSoftWin || 0;
	minutesAllocatedHard += heatmapDeltaData.minutesAllocatedHard || 0;
	minutesPlannedSoft += heatmapDeltaData.minutesPlannedSoft || 0;
	minutesPlannedSoftWin += heatmapDeltaData.minutesPlannedSoftWin || 0;
	minutesPlannedHard += heatmapDeltaData.minutesPlannedHard || 0;
	taskMinutesAllocated += heatmapDeltaData.taskMinutesAllocated || 0;
	timeRegMinutes += heatmapDeltaData.timeRegMinutes || 0;
	plannedTaskMinutes += heatmapDeltaData.plannedTaskMinutes || 0;

	const relevantDataRange = [
		undefined,
		undefined,
		minutesAllocated,
		minutesAvailable,
		minutesAllocatedSoft,
		minutesAllocatedSoftWin,
		minutesAllocatedHard,
		timeRegMinutes,
		minutesPlannedSoft,
		minutesPlannedSoftWin,
		minutesPlannedHard,
		taskMinutesAllocated,
		plannedTaskMinutes,
	];

	personProjectsHeatmapMap.set(anonymizedId, relevantDataRange);
	personHeatMapData.set(canvasTimelineDate, personProjectsHeatmapMap);
}

const processAnonymizedTimeRegistrations = (pageComponent, noAccessHeatMaps) => {
	const {data, todayDate, schedulingOptions} = pageComponent.state;
	const {company} = pageComponent.getFilterData();
	const {anonymizedTimeRegistrationsByPersonMap} = data;
	const isCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

	trackPerformance('ProcessAnonymizedTimeRegistrations', () => {
		if (anonymizedTimeRegistrationsByPersonMap) {
			for (const [personId, personTimeRegs] of Object.entries(anonymizedTimeRegistrationsByPersonMap)) {
				let personHeatMapData = noAccessHeatMaps.get(personId);

				if (!personHeatMapData) {
					personHeatMapData = new Map();
				}

				for (let timeRegIndex = 0; timeRegIndex < personTimeRegs.length; timeRegIndex++) {
					let timeRegMinutes = 0;

					const timeReg = personTimeRegs[timeRegIndex];
					const anonymizedTask = DataManager.getAnonymizedTaskById(pageComponent, timeReg.taskId);

					if (anonymizedTask) {
						const anonymizedId = getAnonymizedId(anonymizedTask);
						const {canvasTimelineDate} = timeReg;

						[timeRegMinutes] = processTimeReg(
							pageComponent,
							timeReg,
							timeRegMinutes,
							null,
							canvasTimelineDate,
							canvasTimelineDate,
							todayDate,
							isCombinedMode
						);

						updateNoAccessHeatmapCache(personHeatMapData, canvasTimelineDate, anonymizedId, {
							timeRegMinutes,
						});
					}
				}

				noAccessHeatMaps.set(personId, personHeatMapData);
			}
		}
	});
};

export const generateNoAccessHeatmap = pageComponent => {
	const {todayDate, schedulingOptions} = pageComponent.state;
	const data = pageComponent.getFilterData();
	const {company} = data;
	const hasHeatmapFetchDisabled =
		hasFeatureFlag('people_scheduling_disable_heatmap_fetch') &&
		hasFeatureFlag('people_scheduling_disable_heatmap_fetch_no_access');
	const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(company);

	if (isMixedAllocationModeEnabled || hasHeatmapFetchDisabled) {
		trackPerformance('GenerateNoAccessHeatmap', () => {
			const start = performance.now();

			const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);
			const isInActualMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.TASK_ACTUAL);
			const isInCombinationMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
			const isInCombinedActualMode =
				isInCombinationMode && getCombinedTaskMode(company) === VISUALIZATION_MODE.TASK_ACTUAL;

			const noAccessHeatMaps = new Map();

			if (isInCombinationMode) {
				processAnonymizedAllocations(pageComponent, noAccessHeatMaps, isInCombinedActualMode, todayDate);

				if (getCombinedTaskMode(company) === VISUALIZATION_MODE.TASK_ACTUAL) {
					processAnonymizedTimeRegistrations(pageComponent, noAccessHeatMaps);
					processAnonymizedTasksActualMode(pageComponent, noAccessHeatMaps);
				} else {
					processAnonymizedTasks(pageComponent, noAccessHeatMaps);
				}
			} else if (isUsingProjectAllocation) {
				processAnonymizedAllocations(pageComponent, noAccessHeatMaps);
			} else if (isInActualMode) {
				processAnonymizedTimeRegistrations(pageComponent, noAccessHeatMaps);
				processAnonymizedTasksActualMode(pageComponent, noAccessHeatMaps);
			} else {
				processAnonymizedTasks(pageComponent, noAccessHeatMaps);
			}

			data.noAccessHeatMaps = noAccessHeatMaps;

			const end = performance.now();
			console.log('generateNoAccessHeatmap took : ' + (end - start) + ' ms.');
		});
	}
};
