import Util from '../../forecast-app/shared/util/util';
import {ESTIMATION_UNIT, MODULE_TYPES, PROJECT_STATUS, SCHEDULING_VIEW} from '../../constants';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import {getCachedMessage} from '../../translations/TranslationCache';
import {
	ALLOCATION_TIMEOFF_OR_INTERNAL_TIME_ITEM_COLOR,
	areItemDatesValid,
	canViewClientName,
	createCanvasTimelineDate,
	DRAGGABLE_TYPE,
	getCompanyDefaultWorkingDays,
	getProjectGroupColor,
	getProjectItemDates,
	getVisualizationMode,
	GROUP_TYPE,
	hasPageViewOnlyAccess,
	hasSchedulingAccess,
	hasViewOnlyAccess,
	isDayOffStep,
	isPhaseAutoScheduled,
	isProjectDoneOrHalted,
	isSimulationMode,
	isTimeOffAllocation,
	ITEM_TYPE,
	mapPlaceholderSkillsGroupData,
	STAFFING_MODE_MARGIN_TOP_BOTTOM,
	TIMELINE_GRAPH_BAR_ELEMENT_TYPE,
	TIMELINE_GRAPH_ENTITY_TYPE,
	TIMELINE_GRAPH_HIGHLIGHTER_TYPE,
	TIMELINE_GRAPH_LINE_TYPE,
	VISUALIZATION_MODE,
} from './canvas-timeline/canvas_timeline_util';
import {canApproveAllocation, hasPermission, hasSomePermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {
	isVisibleAfterHideHardToggled,
	isVisibleAfterHideSoftToggled,
} from './components/allocation_controls/AllocationControlsCanvasUtils';
import {getAllocationTotalNew} from '../scheduling/project_allocation_logic';
import DataManager from './DataManager';
import {GROUP_BY} from './people-scheduling/people_scheduling_header';
import EventManager from './EventManager';
import {getPersonGroupVisibleItemTypes, getProjectGroupVisibleItemTypes} from './people-scheduling/PeopleSchedulingUtils';
import IDManager, {
	NO_ROLE,
	ROLE_GROUPING_SUB_GROUP_TYPE,
	STAFFING_GROUPING_TYPE,
	TOTAL_RESOURCE_UTILIZATION_GROUP_ID,
} from './IDManager';
import {calculatePersonHeatmapCache, composePersonGroupHeatmapItems} from './heatmap/PersonGroupHeatmap';
import {calculateGroupingGroupHeatmapCache} from './heatmap/people-scheduling/HeatmapLogic';
import {
	composePersonGroupingGroupItems,
	recalculateGroupHeatmapCache,
	recalculateGroupHeatmapCaches,
} from './heatmap/HeatmapLogic';
import {adjustTotalMinutesMap} from './placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import TaskGroup from './components/groups/task_group';
import {getRelevantDependencyTaskIdSet} from './projects-scheduling/projects_scheduling_dependencies';
import {getPhaseCompletion, getProgramCompletion, sortTaskGroupsByDate} from './projects-scheduling/projects_scheduling_util';
import NoContentGroup from './components/groups/no_content_group';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {fetchProgressData} from './projects-scheduling/projects_scheduling_fetch';
import {
	calculatePlaceholderHeatmapCache,
	composePlaceholderGroupingHeatmapItems,
} from './heatmap/PlaceholderGroupingGroupHeatmap';
import {
	calculateRoleCapacityHeatmapCache,
	composeRoleCapacityItems,
	composeTimelineGraphRow,
} from './heatmap/capacity-overview/HeatmapLogic';
import HeatmapItem, {HEATMAP_TYPE} from './components/items/Heatmap/HeatmapItem';
import {PROJECT_ENTITY_GROUP_TYPE, PROJECT_SUB_GROUP_TYPE} from './constants';
import {calculateProjectGroupHeatmapCache, composeProjectGroupHeatmap} from './heatmap/ProjectGroupHeatmap';
import ProjectEntityGroup from './components/groups/ProjectEntityGroup';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';
import {findProject} from './utils';

const heatmapTooltipCache = new Map();

export default class ComposeManager {
	static getTooltipCacheKey(group, stepData) {
		const {startDate, endDate} = stepData;
		return `${group.id}-${startDate}-${endDate}`;
	}

	static composeUtilizationHeatmapTooltip(pageComponent, group, itemData, stepData) {
		const {props, timeline} = pageComponent;
		const {schedulingView, intl} = props;
		const {schedulingOptions} = pageComponent.state;
		const {startDate} = stepData;

		if (schedulingView !== SCHEDULING_VIEW.CAPACITY_OVERVIEW) {
			return {};
		}

		const {minutesAvailable, peopleDemand, placeholderDemand} = itemData;
		const cacheKey = this.getTooltipCacheKey(group, stepData);

		if (!heatmapTooltipCache.has(cacheKey)) {
			const tooltipData = {};

			if (!timeline) {
				return tooltipData;
			}

			const {roleName} = itemData;
			const {calcWin} = schedulingOptions;

			// Title
			tooltipData.title = roleName;

			// sub title
			tooltipData.subTitle = timeline.getStepLabelFromCanvasDate(startDate);

			// items
			tooltipData.items = [];

			const totalDemand = peopleDemand + placeholderDemand;
			const peopleRemaining = minutesAvailable - peopleDemand;
			const totalRemaining = minutesAvailable - totalDemand;

			// PEOPLE REMAINING
			const calcWinPrefix = calcWin ? '*' : '';
			tooltipData.items.push({
				subTitle: getCachedMessage(intl, 'common.people_remaining'),
				values: [calcWinPrefix + Util.convertMinutesToFullHour(peopleRemaining, intl)],
			});

			// PLACEHOLDER DEMAND
			tooltipData.items.push({
				subTitle: getCachedMessage(intl, 'scheduling.placeholder_allocations'),
				values: [calcWinPrefix + Util.convertMinutesToFullHour(placeholderDemand, intl)],
			});

			// TOTAL REMAINING
			const overAllocated = totalRemaining < 0;
			tooltipData.items.push({
				subTitle: getCachedMessage(intl, 'scheduling.graph.remaining'),
				values: !overAllocated ? [calcWinPrefix + `${Util.convertMinutesToFullHour(totalRemaining, intl)}`] : null,
				valueWarnings: overAllocated
					? [
							{
								value: null,
								warning: calcWinPrefix + Util.convertMinutesToFullHour(totalRemaining, intl),
							},
					  ]
					: null,
			});

			heatmapTooltipCache.set(cacheKey, tooltipData);
		}

		return heatmapTooltipCache.get(cacheKey);
	}

	static composeDemandHeatmapTooltip(pageComponent, group, itemData, stepData) {
		const {props, timeline} = pageComponent;
		const {schedulingView, intl} = props;
		const {schedulingOptions} = pageComponent.state;
		const {startDate} = stepData;

		if (schedulingView !== SCHEDULING_VIEW.CAPACITY_OVERVIEW) {
			return {};
		}

		const cacheKey = this.getTooltipCacheKey(group, stepData);

		if (!heatmapTooltipCache.has(cacheKey)) {
			const tooltipData = {};

			if (!timeline) {
				return tooltipData;
			}

			const {calcWin, hideSoft, hideHard} = schedulingOptions;
			const {
				plannedTotalMinutesHard,
				minutesAvailable,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin,
				placeholderDemand,
				roleName,
				isPeopleGroup,
			} = itemData;

			// Title
			tooltipData.title = roleName;

			// sub title
			tooltipData.subTitle = timeline.getStepLabelFromCanvasDate(startDate);

			// items
			tooltipData.items = [];

			const totalAvailability = minutesAvailable;
			const peopleAllocations = plannedTotalMinutesHard;
			const plannedTotalMinutesSoftShow = calcWin ? plannedTotalMinutesSoftWin : plannedTotalMinutesSoft;

			let remaining;
			if (isPeopleGroup) {
				// Person group item
				remaining =
					totalAvailability -
					(plannedTotalMinutesSoftShow && !hideSoft ? plannedTotalMinutesSoftShow : 0) -
					peopleAllocations -
					(placeholderDemand ? placeholderDemand : 0);
			} else {
				// Placeholder group item
				remaining = totalAvailability - (calcWin ? plannedTotalMinutesSoftWin : placeholderDemand);
			}

			const numberPrefix = calcWin ? '*' : '';

			// TOTAL AVAILABILITY
			if (isPeopleGroup) {
				tooltipData.items.push({
					subTitle:
						(!isPeopleGroup ? getCachedMessage(intl, 'common.role') + ` ` : ``) +
						intl.formatMessage({id: 'utilization.availability'}),
					values: [Util.convertMinutesToFullHour(totalAvailability, intl)],
				});
			}

			// ALLOCATIONS (PEOPLE)
			if (isPeopleGroup && !hideHard) {
				tooltipData.items.push({
					subTitle:
						hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) && !hideSoft
							? getCachedMessage(intl, 'scheduling.hard_allocations_with_parentes')
							: getCachedMessage(intl, 'scheduling.menu.allocations'),
					values: [`${Util.convertMinutesToFullHour(peopleAllocations, intl)}`],
				});
			}

			// Soft Allocations
			if (isPeopleGroup && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) && !hideSoft) {
				tooltipData.items.push({
					subTitle: getCachedMessage(intl, 'scheduling.soft_allocations_with_parentes'),
					values: [numberPrefix + Util.convertMinutesToFullHour(plannedTotalMinutesSoftShow, intl)],
				});
			}

			// PLACEHOLDER ALLOCATIONS
			if (!isPeopleGroup) {
				const valueShown = calcWin ? plannedTotalMinutesSoftWin : placeholderDemand;
				tooltipData.items.push({
					subTitle: getCachedMessage(intl, 'scheduling.graph.placeholders'),
					values: [numberPrefix + Util.convertMinutesToFullHour(valueShown, intl)],
				});
			}

			// REMAINING
			if (isPeopleGroup) {
				tooltipData.items.push({
					subTitle: getCachedMessage(intl, 'scheduling.graph.remaining'),
					values: remaining >= 0 ? [`${Util.convertMinutesToFullHour(remaining, intl)}`] : null,
					valueWarnings:
						remaining < 0
							? [
									{
										value: null,
										warning: numberPrefix + `${Util.convertMinutesToFullHour(remaining, intl)}`,
									},
							  ]
							: null,
				});
			}

			heatmapTooltipCache.set(cacheKey, tooltipData);
		}

		return heatmapTooltipCache.get(cacheKey);
	}

	static composeProjectAllocation(pageComponent, allocation, fetchedData = null) {
		const startDate = createCanvasTimelineDate(allocation.startYear, allocation.startMonth, allocation.startDay);
		const endDate = createCanvasTimelineDate(allocation.endYear, allocation.endMonth, allocation.endDay);

		if (areItemDatesValid(startDate, endDate)) {
			const data = fetchedData || pageComponent.getData();
			const {sharedContext, schedulingView, isProjectTimeline} = pageComponent.props;
			const {schedulingOptions} = pageComponent.state;

			const isCapacityOverview = schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;
			const isPlaceholdersScheduling = schedulingView === SCHEDULING_VIEW.PLACEHOLDERS;
			const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;

			const totalHours = getAllocationTotalNew(allocation, data.nonWorkingDaysMap) / 60;
			const project = allocation.projectId ? DataManager.getProjectById(pageComponent, allocation.projectId) : null;
			const projectGroup = allocation.projectGroupId
				? DataManager.getProjectGroupById(pageComponent, allocation.projectGroupId)
				: null;

			const isIdleTime = !!allocation.idleTimeId;
			const isIdleTimeOrHasProject = isIdleTime || project || projectGroup;
			if (isIdleTimeOrHasProject) {
				const projectWinChance = project?.baselineWinChance || 1;
				const isDoneOrHalted = project && isProjectDoneOrHalted(project.status);

				let groupId;
				if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
					groupId = IDManager.getPersonGroupId(
						pageComponent,
						allocation.personId,
						allocation.projectId || allocation.projectGroupId
					);
				} else if (
					schedulingView === SCHEDULING_VIEW.PEOPLE &&
					getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION)
				) {
					groupId = IDManager.getProjectEntityGroupId(
						pageComponent,
						allocation.personId,
						allocation.projectId,
						allocation.projectGroupId,
						PROJECT_ENTITY_GROUP_TYPE.ALLOCATION
					);
				} else {
					groupId = IDManager.getProjectGroupId(
						pageComponent,
						allocation.personId,
						allocation.projectId,
						allocation.projectGroupId,
						null
					);
				}

				const color = project
					? project.projectColor
					: allocation.projectGroupId
					? projectGroup.color
					: ALLOCATION_TIMEOFF_OR_INTERNAL_TIME_ITEM_COLOR;

				const isOwnTimeOffAndCanModify =
					isTimeOffAllocation(allocation) &&
					data.company.allUsersModifyTimeOff &&
					hasFeatureFlag('pto_all_users_can_modify_time_off');

				const disabled =
					(!hasPermission(PERMISSION_TYPE.ALLOCATION_UPDATE) ||
						isDoneOrHalted ||
						sharedContext ||
						allocation.bambooHRLinked ||
						hasViewOnlyAccess(pageComponent, project?.id, projectGroup?.id)) &&
					!isOwnTimeOffAndCanModify;

				const visible =
					isVisibleAfterHideSoftToggled(pageComponent, allocation) ||
					isVisibleAfterHideHardToggled(pageComponent, allocation);

				const allowEdit = isIdleTime || allocation.isSoft || canApproveAllocation();
				let draggableType = allowEdit && !disabled ? DRAGGABLE_TYPE.DATE_AND_GROUP : undefined;
				if (draggableType) {
					if (isCapacityOverview && !project) {
						draggableType = DRAGGABLE_TYPE.DATE_ONLY;
					}

					if (isProjectScheduling) {
						draggableType = DRAGGABLE_TYPE.DATE_ONLY;
					}
				}
				if (isOwnTimeOffAndCanModify) {
					draggableType = DRAGGABLE_TYPE.DATE_ONLY;
				}

				let freeDragOuterGroupTypes, freeDragDestinationGroupTypes;
				if (!isProjectScheduling) {
					freeDragOuterGroupTypes = new Set([GROUP_TYPE.PERSON]);
					if (isIdleTime) {
						freeDragDestinationGroupTypes = new Set([GROUP_TYPE.NON_PROJECT_TIME]);
					} else {
						freeDragDestinationGroupTypes = new Set([GROUP_TYPE.PROJECT]);
					}
				}

				let recalculateHeatmapCache;
				if (isCapacityOverview || isPlaceholdersScheduling) {
					const person = DataManager.getPersonById(pageComponent, allocation.personId);
					const recalculateGroupId = isCapacityOverview
						? IDManager.getPersonGroupingGroupId(pageComponent, person)
						: IDManager.getPersonGroupId(pageComponent, person.id);
					recalculateHeatmapCache = interval =>
						recalculateGroupHeatmapCaches(pageComponent, interval, allocation.personId, recalculateGroupId);
				} else if (isProjectTimeline && isIdleTime) {
					const {projectId, groupId} = pageComponent.props;
					const timelineProject = projectId ? findProject(data.projects, projectId) : null;
					const timelineProjectGroup = groupId
						? data.projectGroups.find(projectGroup => projectGroup.companyProjectGroupId === parseInt(groupId, 10))
						: null;

					recalculateHeatmapCache = interval =>
						recalculateGroupHeatmapCache(
							pageComponent,
							IDManager.getPersonGroupId(
								pageComponent,
								allocation.personId,
								timelineProject?.id || timelineProjectGroup?.id
							),
							interval
						);
				}

				return {
					groupId,
					startDate,
					endDate,
					color,
					totalHours,
					allocation,
					project,
					projectGroup,
					getSchedulingOptions: () => pageComponent.state.schedulingOptions,
					projectWinChance,
					visible,
					disabled,
					draggableType,
					defaultWorkingDays: getCompanyDefaultWorkingDays(data.company),
					handlesUpdateLock: isTimeOffAllocation(allocation),
					freeDragOuterGroupTypes,
					freeDragDestinationGroupTypes,
					recalculateHeatmapCache,
					onClickEnabled:
						hasPageViewOnlyAccess(pageComponent) ||
						!disabled ||
						(isTimeOffAllocation(allocation) &&
							data.company.allUsersModifyTimeOff &&
							hasFeatureFlag('pto_all_users_can_modify_time_off')),
				};
			}
		}

		return null;
	}

	static composeProjectGroup(
		pageComponent,
		person,
		placeholder,
		project,
		projectGroup = null,
		groups = [],
		fullDataLoaded = null
	) {
		const {schedulingView} = pageComponent.props;
		const {schedulingOptions, staffingModeActive, groupBy} = pageComponent.state;
		const data = pageComponent.getFilterData();
		const isCombinedMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);
		const isAllocationMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.ALLOCATION);
		const isTaskMode =
			getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.TASK_PLAN) ||
			getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.TASK_ACTUAL);

		const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;
		const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;
		const isCapacityOverview = schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;
		const isPlaceholdersScheduling = schedulingView === SCHEDULING_VIEW.PLACEHOLDERS;

		const projects = projectGroup ? DataManager.getProjectsByProjectGroupId(pageComponent, projectGroup.id) : null;

		if ((projectGroup && projects.length > 0) || project) {
			const projectPersonProjectId = projectGroup ? projects[0].id : project.id;
			const projectPersons = DataManager.getProjectPersonsByProjectId(pageComponent, projectPersonProjectId);

			const isDisabled = hasViewOnlyAccess(pageComponent, project?.id, projectGroup?.id);
			const hasItemCreatePermission =
				(isAllocationMode && hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE)) || isTaskMode;

			const hideCondition = () => {
				if (!isPeopleScheduling) {
					return false;
				}

				return pageComponent.isProjectGroupingDisabled();
			};

			const entityId = person?.id || placeholder?.id;
			const groupId = IDManager.getEntityGroupId(pageComponent, entityId);

			let hideIfEmpty = true;
			if (isCapacityOverview) {
				hideIfEmpty = !!person;
			} else if (isProjectScheduling) {
				hideIfEmpty = false;
			}

			const groupData = {};

			if (isPeopleScheduling) {
				const noGrouping = !groupBy || groupBy === GROUP_BY.NONE;
				groupData.marginX = noGrouping ? 0 : 32;

				if (isCombinedMode) {
					groupData.validHeatmapItemTypes = getProjectGroupVisibleItemTypes(pageComponent);
					groupData.calculateHeatmapCache = (
						group,
						stepDataArray,
						timelineMinorStep,
						setHasCalculated,
						getCalculationMetaData
					) =>
						calculateProjectGroupHeatmapCache(
							pageComponent,
							group,
							stepDataArray,
							timelineMinorStep,
							setHasCalculated,
							getCalculationMetaData
						);
					groupData.composeItems = (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
						composeProjectGroupHeatmap(pageComponent, group, stepDataArray, timelineStartDate, timelineMinorStep);
				}
			}

			if (
				isCapacityOverview ||
				(isPlaceholdersScheduling && staffingModeActive) ||
				(isPeopleScheduling && !isCombinedMode)
			) {
				groupData.preventExpansion = true;
			}

			let projectRelatedData = {};
			if (project) {
				let clientName = project.client?.name || DataManager.getClientById(pageComponent, project.clientId)?.name || '';

				// if the project is a greyed out project in project manager view, show project contact name instead of client name if there is one
				if (!canViewClientName(pageComponent, project?.id, projectGroup?.id)) {
					const projectContact = projectPersons?.find(projectPerson => projectPerson.isContactPerson);

					if (projectContact) {
						const projectContactPerson = DataManager.getPersonById(pageComponent, projectContact.personId);
						clientName = `Ct: ${projectContactPerson?.firstName || ''} ${projectContactPerson?.lastName || ''}`;
					} else {
						clientName = '';
					}
				}

				if (isProjectScheduling) {
					groupData.projectGroupColor = getProjectGroupColor(pageComponent, project);
				}

				projectRelatedData = {
					name: project.name,
					status: project.status,
					color: project.projectColor,
					clientName,
					client: project.clientId,
					clientId: project.clientId,
					priorityLevelId: project.priorityLevelId,
					estimationUnit: project.estimationUnit,
					budgetType: project.budgetType,
					baselineWinChance: project.baselineWinChance,
					isInProjectGroup: project.isInProjectGroup,
					isInProgram: project.isInProgram,
					programPrefix: project.programPrefix,
					projectId: project?.id,
					projectGroupId: projectGroup?.id,
				};
			}

			if (projectGroup) {
				projectRelatedData = {
					name: projectGroup.name,
					color: projectGroup.color,
					estimationUnit: projectGroup.estimationUnit,
				};
			}

			if (isPeopleScheduling && isCombinedMode) {
				groups.push(
					new ProjectEntityGroup(
						pageComponent,
						ComposeManager.composeProjectEntityGroup(
							pageComponent,
							person,
							project,
							projectGroup,
							PROJECT_ENTITY_GROUP_TYPE.ALLOCATION
						)
					)
				);

				groups.push(
					new ProjectEntityGroup(
						pageComponent,
						ComposeManager.composeProjectEntityGroup(
							pageComponent,
							person,
							project,
							projectGroup,
							PROJECT_ENTITY_GROUP_TYPE.TASK
						)
					)
				);
			}

			const defaultGroupData = {
				id: IDManager.getProjectGroupId(pageComponent, entityId, project?.id, projectGroup?.id, null),
				groupId,
				groups,
				companyProjectId: project?.companyProjectId,
				customProjectId: project?.customProjectId,
				companyProjectGroupId: projectGroup?.companyProjectGroupId,
				project,
				projectGroup,
				projectIds: projects ? new Set(projects.map(project => project.id)) : null,
				isConnectedProject: !!projectGroup,
				person,
				personId: person?.id,
				placeholder,
				isDisabled,
				hideIfEmpty,
				hideCondition,
				fullDataLoaded,
				hasItemCreate: (person || placeholder) && !isDisabled && hasItemCreatePermission,
				onExpand: expanded => trackEvent('Project Group', expanded ? 'Expanded' : 'Collapsed'),
				...projectRelatedData,
			};

			return {
				...defaultGroupData,
				...groupData,
			};
		}

		return null;
	}

	static composeTaskItem(pageComponent, task, personId = null) {
		if (task.startYear && task.deadlineYear) {
			const {sharedContext, expansionMap, schedulingView, isProjectTimeline, isMySchedule} = pageComponent.props;

			const startDate = createCanvasTimelineDate(task.startYear, task.startMonth, task.startDay);
			const endDate = createCanvasTimelineDate(task.deadlineYear, task.deadlineMonth, task.deadlineDay);

			if (areItemDatesValid(startDate, endDate)) {
				const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;
				const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;

				const project = DataManager.getProjectById(pageComponent, task.projectId);
				const groupId = IDManager.getTaskGroupId(pageComponent, task, personId);

				let allowDependencyCreation = false;
				if (isProjectScheduling) {
					allowDependencyCreation = !isSimulationMode() && !project.isJiraProject && !sharedContext;
				}

				let draggableType, freeDragOuterGroupTypes, freeDragDestinationGroupTypes;
				if (!task.readOnly?.isReadOnly && !sharedContext) {
					if (isPeopleScheduling) {
						draggableType = DRAGGABLE_TYPE.DATE_AND_GROUP;
						freeDragOuterGroupTypes = new Set([GROUP_TYPE.PERSON]);
						freeDragDestinationGroupTypes = new Set([GROUP_TYPE.PROJECT]);
					} else if (isProjectScheduling) {
						draggableType = DRAGGABLE_TYPE.DATE_ONLY;
					}
				}

				const disabled = !isMySchedule && !isProjectTimeline && hasViewOnlyAccess(pageComponent, project.id, null);

				return {
					groupId,
					disabled,
					expansionMap,
					startDate,
					endDate,
					draggableType,
					freeDragOuterGroupTypes,
					freeDragDestinationGroupTypes,
					color: project.projectColor,
					task,
					isHourEstimated: project.estimationUnit === ESTIMATION_UNIT.HOURS,
					minutesPerEstimationPoint: project.minutesPerEstimationPoint,
					projectId: project.id,
					projectGroupId: project.projectGroupId,
					project,
					phaseId: task.phaseId,
					allowDependencyCreation,
					personId,
					parentId: project.isInProjectGroup ? project.projectGroupId : task.projectId,
					onPersonImageLoad: () => EventManager.onPersonImageLoad(pageComponent),
					onClickEnabled: hasPageViewOnlyAccess(pageComponent) || !disabled,
				};
			}
		}

		return null;
	}

	static composeNoContentGroup(pageComponent, person, project, phase, task = null) {
		if (person || project) {
			const {schedulingView, isProjectTimeline, intl} = pageComponent.props;
			const {schedulingOptions} = pageComponent.state;

			const groupData = {};

			if (person) {
				groupData.name = getCachedMessage(intl, 'scheduling.no_current_projects');
				groupData.personId = person.id;
				groupData.person = person;

				if (schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW) {
					groupData.groupId = IDManager.getPersonGroupingGroupId(pageComponent, person);
				}

				if (schedulingView === SCHEDULING_VIEW.PEOPLE) {
					groupData.hideCondition = () => {
						const {showTasks, showAllocations} = schedulingOptions;
						const isProjectGroupDisabled =
							pageComponent.isProjectGroupingDisabled && pageComponent.isProjectGroupingDisabled();
						return isProjectGroupDisabled && !showTasks && !showAllocations;
					};
				}
			}

			if (project) {
				groupData.name = task
					? getCachedMessage(intl, 'scheduling.no_sub_tasks_in_date_range')
					: getCachedMessage(intl, 'scheduling.no_current_tasks');
				groupData.color = project.projectColor;
				groupData.isInProjectGroup = project.isInProjectGroup;
				groupData.isInProgram = project.isInProgram;
				groupData.projectGroupColor = project.projectGroupColor;
				groupData.project = project;

				if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
					groupData.isProjectTimeline = isProjectTimeline;
				}
			}

			if (phase) {
				groupData.phase = phase;
			}

			if (task) {
				groupData.task = task;
			}

			return {
				id: IDManager.getNoContentGroupId(pageComponent, person, project, phase, task),
				showOnlyIfNoSiblingsVisible: true,
				hasItemCreate: !person || hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE),
				...groupData,
			};
		}

		return null;
	}

	static composeNonProjectTimeGroup(pageComponent, person) {
		const {schedulingView, intl} = pageComponent.props;
		const data = pageComponent.getData();
		const {company} = data;

		const hideCondition = () => {
			if (schedulingView === SCHEDULING_VIEW.PEOPLE) {
				return pageComponent.isProjectGroupingDisabled();
			}

			return false;
		};

		const canModifyOwnTimeOffAndIsOwnTimeOff =
			company.allUsersModifyTimeOff &&
			hasFeatureFlag('pto_all_users_can_modify_time_off') &&
			data.viewer.actualPersonId === person.id;

		return {
			id: IDManager.getNonProjectTimeGroupId(pageComponent, person.id),
			personId: person.id,
			person,
			name: getCachedMessage(intl, 'common.non-project-time'),
			hideIfEmpty: true,
			hideCondition,
			preventExpansion: true,
			hasItemCreate: hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE) || canModifyOwnTimeOffAndIsOwnTimeOff,
		};
	}

	static composePersonAllocationsGroup(pageComponent, person) {
		const {schedulingView, intl} = pageComponent.props;
		const {data} = pageComponent.state;

		const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;

		const hideCondition = () => {
			if (isPeopleScheduling) {
				return !pageComponent.isProjectGroupingDisabled();
			}

			return false;
		};

		const isHeatMapDisabled = () => {
			if (isPeopleScheduling) {
				return pageComponent.isHeatMapDisabled();
			}

			return false;
		};

		const groupIds = data.projects
			.map(project => `${person.id}-${project.id}`)
			.concat(data.projectGroups.map(projectGroup => `${person.id}-${projectGroup.id}`))
			.concat([IDManager.getNonProjectTimeGroupId(pageComponent, person.id)]);

		return {
			id: IDManager.getPersonAllocationsGroupId(pageComponent, person.id),
			personId: person.id,
			person,
			groupIds,
			name: getCachedMessage(intl, 'scheduling.allocations_and_task'),
			hideCondition,
			isHeatMapDisabled,
			hasItemCreate: true,
		};
	}

	static composePersonGroup(pageComponent, person, projectOrProjectGroup, groups = [], groupIds = []) {
		const {schedulingView, isProjectTimeline, programPrefix} = pageComponent.props;
		const data = pageComponent.getData();
		const {company} = data;

		const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;
		const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;
		const isCapacityOverview = schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;
		const isPlaceholdersScheduling = schedulingView === SCHEDULING_VIEW.PLACEHOLDERS;

		const groupData = {};

		const roleName = (person.roleId && DataManager.getRoleById(pageComponent, person.roleId)?.name) || '';
		const labelIds = DataManager.getPersonLabelsByPersonId(pageComponent, person.id).map(
			personLabel => personLabel.labelId
		);
		const skillIds = DataManager.getPersonSkillsByPersonId(pageComponent, person.id).map(
			personSkill => personSkill.skillId
		);
		const isHeatMapDisabled = () => {
			if (isPeopleScheduling) {
				return pageComponent.isHeatMapDisabled();
			}

			return isProjectScheduling;
		};

		let project, projectGroup;
		if (projectOrProjectGroup) {
			project = projectOrProjectGroup.companyProjectGroupId ? null : projectOrProjectGroup;
			projectGroup = projectOrProjectGroup.companyProjectGroupId ? projectOrProjectGroup : null;
		}

		const personGroupId = IDManager.getPersonGroupId(pageComponent, person.id, project?.id || projectGroup?.id);

		if (isPeopleScheduling) {
			groupData.validHeatmapItemTypes = getPersonGroupVisibleItemTypes(pageComponent);
			groupData.calculateHeatmapCache = (group, stepDataArray, timelineMinorStep) =>
				calculatePersonHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep);
			groupData.composeItems = (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
				composePersonGroupHeatmapItems(pageComponent, group, stepDataArray, timelineStartDate, timelineMinorStep);
		} else if (isProjectScheduling) {
			const shouldSeeHeatmap =
				isProjectTimeline &&
				!Util.isMixedAllocationModeEnabled(company) &&
				hasSchedulingAccess(pageComponent) &&
				!programPrefix;

			if (shouldSeeHeatmap) {
				groupData.validHeatmapItemTypes = getPersonGroupVisibleItemTypes(pageComponent);
				groupData.composeItems = (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
					composePersonGroupHeatmapItems(pageComponent, group, stepDataArray, timelineStartDate, timelineMinorStep);

				const isUsingProjectAllocation = getVisualizationMode(
					pageComponent.state.schedulingOptions,
					company,
					VISUALIZATION_MODE.ALLOCATION
				);

				if (!isUsingProjectAllocation) {
					groupData.heatmapItemPredicate = item => item.data?.task?.assignedPersons?.includes(person.id) || false;
				}

				const itemsPredicate = item => {
					if (item.data.task) {
						return item.data.task.assignedPersons.includes(person.id);
					} else {
						return item.data.allocation.personId === person.id;
					}
				};

				groupData.calculateHeatmapCache = (group, stepDataArray, timelineMinorStep) =>
					calculatePersonHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep, null, itemsPredicate);
			}

			const projectGroupColor = getProjectGroupColor(pageComponent, projectOrProjectGroup);

			groupData.projectGroupColor = projectGroupColor;
			groupData.color = projectOrProjectGroup.isInProjectGroup ? projectGroupColor : projectOrProjectGroup.projectColor;
			groupData.preventExpansion = true;
			groupData.isHeatmapCombinedWithItems = true;
			groupData.displayItemsWithHeatmap = shouldSeeHeatmap;
			groupData.isInProjectGroup = projectOrProjectGroup.isInProjectGroup;
			groupData.isInProgram = projectOrProjectGroup.isInProgram;
			groupIds = [IDManager.getPersonGroupId(pageComponent, person.id), personGroupId];
		} else if (isCapacityOverview) {
			groupData.groupId = IDManager.getPersonGroupingGroupId(pageComponent, person);
			groupData.groupIds = groups.map(group => group.data.id);
			const itemTypes = [ITEM_TYPE.PROJECT_ALLOCATION];
			groupData.validHeatmapItemTypes = new Set(itemTypes);
			groupData.calculateHeatmapCache = (group, stepDataArray, timelineMinorStep) =>
				calculatePersonHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep, itemTypes);
			groupData.composeItems = (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
				composePersonGroupHeatmapItems(pageComponent, group, stepDataArray, timelineStartDate, timelineMinorStep, true);
		} else if (isPlaceholdersScheduling) {
			const {staffingModeActive} = pageComponent.state;
			const itemTypes = [ITEM_TYPE.PROJECT_ALLOCATION, ITEM_TYPE.PLACEHOLDER_ALLOCATION];
			const validHeatmapItemTypes = new Set(itemTypes);

			groupData.groupId = STAFFING_GROUPING_TYPE.FILTERED_MATCHES;
			groupData.isStaffingMode = true;
			groupData.groupIds = groups.map(group => group.data.id);
			groupData.drawAssignButton = staffingModeActive && hasFeatureFlag('placeholders_beta_changes');
			groupData.validHeatmapItemTypes = validHeatmapItemTypes;
			groupData.calculateHeatmapCache = (group, stepDataArray, timelineMinorStep) =>
				calculatePersonHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep, itemTypes);
			groupData.composeItems = (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
				composePersonGroupHeatmapItems(pageComponent, group, stepDataArray, timelineStartDate, timelineMinorStep);
		}

		const defaultData = {
			id: personGroupId,
			groupIds,
			groups,
			person,
			project,
			projectGroup,
			personId: person.id,
			name: Util.getPersonFullName(person),
			startDate: person.startDate,
			endDate: person.endDate,
			roleId: person.roleId || null,
			roleName,
			profilePictureId: person.profilePictureId,
			profilePictureDefaultId: person.profilePictureDefaultId,
			labelIds,
			skillIds,
			monday: person.monday,
			tuesday: person.tuesday,
			wednesday: person.wednesday,
			thursday: person.thursday,
			friday: person.friday,
			saturday: person.saturday,
			sunday: person.sunday,
			departmentId: person.departmentId,
			holidayCalendarId: person.holidayCalendarId,
			onPersonImageLoad: () => EventManager.onPersonImageLoad(pageComponent),
			isHeatMapDisabled,
			hasItemCreate: !hasViewOnlyAccess(pageComponent, project?.id, projectGroup?.id),
			onExpand: expanded => trackEvent('Person Group', expanded ? 'Expanded' : 'Collapsed'),
		};

		return {
			...defaultData,
			...groupData,
		};
	}

	static composePersonGroupingGroup(pageComponent, person, groups = []) {
		const {schedulingView} = pageComponent.props;
		const {groupBy} = pageComponent.state;
		const groupByRole = groupBy === GROUP_BY.ROLE;

		const groupId = IDManager.getPersonGroupingGroupId(pageComponent, person);
		if (groupId) {
			const roleName = (person.roleId && DataManager.getRoleById(pageComponent, person.roleId)?.name) || 'No Role';
			const departmentName =
				(person.departmentId && DataManager.getDepartmentById(pageComponent, person.departmentId)?.name) ||
				'No Department';
			const groupName = groupByRole ? roleName : departmentName;

			return {
				id: groupId,
				name: groupName,
				groups,
				schedulingView,
				calculateHeatmapCache: (group, stepDataArray, timelineMinorStep) =>
					calculateGroupingGroupHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep),
				composeItems: (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
					composePersonGroupingGroupItems(pageComponent, group, stepDataArray, timelineMinorStep),
			};
		}

		return null;
	}

	static composeUnassignedRoleTaskGroup(pageComponent, role) {
		const roleId = role?.id;

		return {
			id: IDManager.getUnassignedTaskGroupId(pageComponent, roleId),
			roleId,
			role,
		};
	}

	static composeUnassignedRoleGroup(pageComponent, role, groups = []) {
		const {intl} = pageComponent.props;
		const {formatMessage} = intl;

		const roleId = role?.id;
		const roleName = role?.name;

		return {
			id: IDManager.getUnassignedRoleGroupId(pageComponent, roleId),
			name: roleName || formatMessage({id: 'card_modal.no-role'}),
			role,
			roleId: roleId || NO_ROLE,
			groups,
			composeItems: (visibleGroup, minorStepDataArray, startDate, pixelsPerDay, minorStep) =>
				pageComponent.composeUnassignedHeatmapRow(visibleGroup, minorStepDataArray, startDate, pixelsPerDay, minorStep),
			validHeatmapItemTypes: new Set([ITEM_TYPE.TASK]),
		};
	}

	static composeUnassignedHeatmapItem(pageComponent, group, stepData, heatmapCachedItem) {
		const {screenY} = group;
		const {position, width} = stepData;
		const {hoursAllocatedText, requiredPersonCount} = heatmapCachedItem;

		return {
			y: screenY,
			x: position,
			width,
			hoursAllocatedText,
			requiredPersonCount,
		};
	}

	static composeTotalResourceUtilizationGroup(pageComponent) {
		const {formatMessage} = pageComponent.props.intl;
		const {groupBy} = pageComponent.state;
		const noGrouping = groupBy === GROUP_BY.NONE;

		const subGroupPredicate = group =>
			noGrouping ? group.groupType === GROUP_TYPE.PERSON : group.groupType === GROUP_TYPE.PERSON_GROUPING_GROUP;

		return {
			id: TOTAL_RESOURCE_UTILIZATION_GROUP_ID,
			// filtered: !eyeOptions.find(option => option.name === TOTAL_RESOURCE_UTILIZATION_GROUP_ID).checked,
			calculateHeatmapCache: (group, stepDataArray, timelineMinorStep) =>
				calculateGroupingGroupHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep, subGroupPredicate),
			composeItems: (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
				composePersonGroupingGroupItems(pageComponent, group, stepDataArray, timelineMinorStep),
			formatMessage,
			preventExpansion: noGrouping,
			onExpand: expanded => EventManager.onTotalResourceUtilizationGroupExpand(pageComponent, expanded),
		};
	}

	static composePlaceholderAllocation(pageComponent, placeholderAllocation, isGhost = false) {
		const {staffingModeActive, staffingPlaceholder} = pageComponent.state;
		const placeholder = staffingModeActive
			? staffingPlaceholder
			: DataManager.getPlaceholderById(pageComponent, placeholderAllocation.placeholderId);

		if (placeholder) {
			const project = DataManager.getProjectById(pageComponent, placeholder.projectId);
			const projectGroup = DataManager.getProjectGroupById(pageComponent, placeholder.projectGroupId);
			const startDate = createCanvasTimelineDate(
				placeholderAllocation.startYear,
				placeholderAllocation.startMonth,
				placeholderAllocation.startDay
			);
			const endDate = createCanvasTimelineDate(
				placeholderAllocation.endYear,
				placeholderAllocation.endMonth,
				placeholderAllocation.endDay
			);

			if (areItemDatesValid(startDate, endDate) && (project || projectGroup)) {
				const {totalMinutesMap} = pageComponent.state;
				const data = pageComponent.getData();
				const {sharedContext, schedulingView} = pageComponent.props;

				const isCapacityOverview = schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;
				const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;
				const isPlaceholdersScheduling = schedulingView === SCHEDULING_VIEW.PLACEHOLDERS;

				adjustTotalMinutesMap(placeholder, placeholderAllocation, totalMinutesMap);

				const isDoneOrHalted = project && isProjectDoneOrHalted(project.status);
				const disabled =
					isGhost ||
					isDoneOrHalted ||
					sharedContext ||
					!hasPermission(PERMISSION_TYPE.ALLOCATION_UPDATE) ||
					hasViewOnlyAccess(pageComponent, project?.id, projectGroup?.id);
				const hideTooltip =
					isGhost ||
					isDoneOrHalted ||
					sharedContext ||
					!hasSomePermission([PERMISSION_TYPE.ALLOCATION_UPDATE, PERMISSION_TYPE.PROJECTS_READ_ALL_VIEW_ONLY]);
				const color = project?.projectColor || projectGroup?.color;
				const projectWinChance = project?.baselineWinChance || 1;
				const projectName = project?.name || projectGroup?.name || '';
				const canGhost = isPlaceholdersScheduling && staffingModeActive;

				let recalculateHeatmapCache,
					groupId,
					freeDragOuterGroupTypes,
					freeDragDestinationGroupTypes,
					dropOffHoverEffectGroupType,
					isStaffed,
					startGhost;

				if (isCapacityOverview) {
					groupId = IDManager.getProjectGroupId(
						pageComponent,
						placeholder.id,
						placeholder.projectId,
						placeholder.projectGroupId,
						null
					);
					recalculateHeatmapCache = interval =>
						recalculateGroupHeatmapCaches(
							pageComponent,
							interval,
							IDManager.getPlaceholderGroupId(pageComponent, placeholder),
							IDManager.getPlaceholderGroupingId(pageComponent, placeholder.roleId, null)
						);
				}

				if (isProjectScheduling) {
					groupId = IDManager.getPlaceholderGroupId(pageComponent, placeholder);
				}

				if (isPlaceholdersScheduling) {
					isStaffed =
						staffingModeActive &&
						placeholderAllocation.personId &&
						(placeholderAllocation.projectId || placeholderAllocation.projectGroupId);

					freeDragOuterGroupTypes = new Set([GROUP_TYPE.PERSON, GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP]);
					freeDragDestinationGroupTypes = new Set([GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP, GROUP_TYPE.PROJECT]);
					dropOffHoverEffectGroupType = GROUP_TYPE.PERSON;

					const recalculationGroupId = isStaffed
						? IDManager.getPersonGroupId(pageComponent, placeholderAllocation.personId)
						: IDManager.getPlaceholderGroupingId(
								pageComponent,
								placeholder.roleId,
								placeholder.projectId || placeholder.projectGroupId
						  );
					recalculateHeatmapCache = interval =>
						recalculateGroupHeatmapCache(pageComponent, recalculationGroupId, interval);

					if (isStaffed) {
						groupId = IDManager.getProjectGroupId(
							pageComponent,
							placeholderAllocation.personId,
							placeholderAllocation.projectId,
							placeholderAllocation.projectGroupId,
							null
						);
					} else {
						groupId = IDManager.getPlaceholderGroupId(pageComponent, placeholder);

						if (!disabled) {
							startGhost = (item, items) => EventManager.onStartGhost(pageComponent, item, items);
						}
					}
				}

				return {
					groupId,
					startDate,
					endDate,
					color,
					totalMinutesMap,
					placeholderAllocation,
					placeholder,
					project,
					projectGroup,
					projectName,
					getSchedulingOptions: () => pageComponent.state.schedulingOptions,
					projectWinChance,
					disabled,
					defaultWorkingDays: getCompanyDefaultWorkingDays(data.company),
					recalculateHeatmapCache,
					freeDragOuterGroupTypes,
					freeDragDestinationGroupTypes,
					dropOffHoverEffectGroupType,
					startGhost,
					canGhost,
					isGhost,
					draggableType: disabled
						? undefined
						: staffingModeActive
						? DRAGGABLE_TYPE.DATE_AND_GROUP
						: DRAGGABLE_TYPE.DATE_ONLY,
					hasItemCreate: !disabled && !hasViewOnlyAccess(pageComponent, project?.id, projectGroup?.id),
					hideTooltip,
					onClickEnabled: hasPageViewOnlyAccess(pageComponent) || !disabled,
				};
			}
		}

		return null;
	}

	static composeTaskGroup(pageComponent, task, subTaskLevel = 0, includeSubTasks = false) {
		const {isProjectTimeline} = pageComponent.props;
		const project = DataManager.getProjectById(pageComponent, task.projectId);
		const hasViewOnly = hasViewOnlyAccess(pageComponent, project?.id, null);

		const subTaskGroups = [];
		if (includeSubTasks) {
			const parentSubTaskIds = DataManager.getSubTasksByParentTaskId(pageComponent, task.id);

			if (parentSubTaskIds) {
				for (const subTaskId of parentSubTaskIds) {
					const subTask = DataManager.getTaskById(pageComponent, subTaskId);

					if (subTask) {
						subTaskGroups.push(
							new TaskGroup(
								pageComponent,
								this.composeTaskGroup(pageComponent, subTask, subTaskLevel + 1, includeSubTasks)
							)
						);
					}
				}
			}
		}

		const hasSubTasks = subTaskGroups.length > 0;
		if (hasSubTasks) {
			if (hasFeatureFlag('schedule_sort_task_groups')) {
				sortTaskGroupsByDate(subTaskGroups);
			}

			subTaskGroups.push(
				new NoContentGroup(
					pageComponent,
					ComposeManager.composeNoContentGroup(pageComponent, null, project, null, task)
				)
			);
		}

		return {
			task,
			id: task.id,
			companyTaskId: task.companyTaskId,
			name: task.name,
			project,
			color: project.projectColor,
			isDone: task.done,
			isStarted: pageComponent.isTaskStarted(task.statusColumnId),
			isInProjectGroup: project.isInProjectGroup,
			isProjectTimeline: isProjectTimeline,
			hideIfEmpty: !isSimulationMode(),
			expandable: hasSubTasks,
			subTaskLevel,
			groups: subTaskGroups,
			projectGroupColor: getProjectGroupColor(pageComponent, project),
			forceDrawCondition: () => {
				return getRelevantDependencyTaskIdSet(pageComponent).has(task.id);
			},
			disabled: hasViewOnly,
			hasItemCreate: !hasViewOnly,
		};
	}

	static composePhaseGroup(pageComponent, phase, projectId = null) {
		const {intl} = pageComponent.props;
		const {formatMessage} = intl;

		if (phase || projectId) {
			const project = DataManager.getProjectById(pageComponent, phase?.projectId || projectId);
			const projectGroupColor = getProjectGroupColor(pageComponent, project);
			project.projectGroupColor = projectGroupColor;

			const taskGroups = [];

			const phaseTasks = DataManager.getTasksByPhaseId(pageComponent, phase?.id || projectId);
			if (phaseTasks) {
				for (const task of phaseTasks) {
					taskGroups.push(new TaskGroup(pageComponent, this.composeTaskGroup(pageComponent, task, 0, true)));
				}
			}

			taskGroups.push(
				new NoContentGroup(pageComponent, ComposeManager.composeNoContentGroup(pageComponent, null, project, phase))
			);

			let name = phase?.name || formatMessage({id: 'project_scopes.no-scope'});

			const isPhaseNotAutoScheduled = isPhaseAutoScheduled(pageComponent, phase);
			if (isPhaseNotAutoScheduled) {
				name += ` (${formatMessage({id: 'auto_scheduling.not_autoscheduled'})})`;
			}

			if (phase || taskGroups.length > 0) {
				return {
					id: IDManager.getPhaseGroupId(pageComponent, phase?.id, projectId),
					groups: taskGroups,
					phase,
					phaseId: phase?.id,
					name,
					faded: isPhaseNotAutoScheduled,
					color: project.projectColor,
					isInProjectGroup: project.isInProjectGroup,
					isInProgram: project.isInProgram,
					projectGroupColor,
					project,
					hasItemCreate: !hasViewOnlyAccess(pageComponent, project?.id, null),
				};
			}
		}

		return null;
	}

	static composePhaseItem(pageComponent, phase) {
		const project = DataManager.getProjectById(pageComponent, phase.projectId);
		Util.setPhaseDatesFromProject(phase, project);
		const isDisabled = hasViewOnlyAccess(pageComponent, project.id, null);

		if (phase.startYear && phase.deadlineYear) {
			const startDate = createCanvasTimelineDate(phase.startYear, phase.startMonth, phase.startDay);
			const endDate = createCanvasTimelineDate(phase.deadlineYear, phase.deadlineMonth, phase.deadlineDay);

			if (areItemDatesValid(startDate, endDate)) {
				const {sharedContext} = pageComponent.props;
				const isDoneOrHalted = isProjectDoneOrHalted(project.status);
				const isHourEstimated = project.estimationUnit === ESTIMATION_UNIT.HOURS;

				let completion;
				if (project.manualProgressOnPhasesEnabled || project.manualProgressOnTasksEnabled) {
					completion = phase.completion;
				} else {
					completion = getPhaseCompletion(pageComponent, phase.id);
				}

				let draggableType;
				if (hasPermission(PERMISSION_TYPE.PHASE_UPDATE) && !sharedContext && !isDoneOrHalted) {
					draggableType = DRAGGABLE_TYPE.DATE_ONLY;
				}

				return {
					groupId: IDManager.getPhaseGroupId(pageComponent, phase.id),
					phaseName: phase.name,
					phase,
					project,
					startDate,
					endDate,
					draggableType,
					color: project.projectColor,
					completion,
					projectId: project.id,
					isHourEstimated,
					disabled: isDisabled,
					onClickEnabled: hasPageViewOnlyAccess(pageComponent) || !isDisabled,
				};
			}
		}

		return null;
	}

	static composeProjectSubGroup(pageComponent, project, projectGroup, projectSubGroupType, groups = []) {
		if (PROJECT_SUB_GROUP_TYPE[projectSubGroupType] !== undefined) {
			const {intl} = pageComponent.props;
			const {formatMessage} = intl;

			const isPhaseSubGroup = projectSubGroupType === PROJECT_SUB_GROUP_TYPE.PHASES;
			const isPlaceholdersSubGroup = projectSubGroupType === PROJECT_SUB_GROUP_TYPE.PLACEHOLDERS;
			const isTeamMembersSubGroup = projectSubGroupType === PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM;

			const projects = projectGroup ? DataManager.getProjectsByProjectGroupId(pageComponent, projectGroup.id) : null;
			const projectGroupColor = projectGroup ? projectGroup.color : getProjectGroupColor(pageComponent, project);
			const hasViewOnly = hasViewOnlyAccess(pageComponent, project?.id, projectGroup?.id);

			let disabled = false;
			if (project) {
				disabled = isProjectDoneOrHalted(project.status) || hasViewOnly;
			}

			let name = '';
			if (isPhaseSubGroup) {
				name = formatMessage({id: 'common.phases'});
			} else if (isPlaceholdersSubGroup) {
				name = formatMessage({id: 'common.placeholders'});
			} else if (isTeamMembersSubGroup) {
				name = formatMessage({id: 'scheduling.project_scheduling.project_team'});
			}

			const showProjectTeam = hasSchedulingAccess(pageComponent);

			return {
				id: IDManager.getProjectSubGroupId(pageComponent, project, projectGroup, projectSubGroupType),
				groups: isTeamMembersSubGroup && !showProjectTeam ? undefined : groups,
				projectSubGroupType,
				disabled,
				name,
				project,
				projectGroup,
				projects,
				color: project?.projectColor,
				isInProjectGroup: project?.isInProjectGroup,
				isPartOfProjectGroupGroup: !isPhaseSubGroup,
				isInProgram: project?.isInProgram,
				projectGroupColor,
				expanded: isPhaseSubGroup,
				preventExpansion: isTeamMembersSubGroup ? !showProjectTeam : false,
				hasItemCreate: isPhaseSubGroup && !disabled && !hasViewOnly,
			};
		}

		return null;
	}

	static composeProjectItem(pageComponent, project, projectGroup, program) {
		let projects;
		if (projectGroup) {
			projects = DataManager.getProjectsByProjectGroupId(pageComponent, projectGroup.id);
		} else if (program) {
			projects = DataManager.getProjectsByProgramPrefix(pageComponent, program.prefix);
		}

		const {startDate, endDate} = getProjectItemDates(pageComponent, project, projectGroup, projects, program);

		if (areItemDatesValid(startDate, endDate)) {
			const {sharedContext, intl} = pageComponent.props;
			let draggableType, planningText, isPlanning, isHourEstimated, color, completion, lazyLoadData;
			const isDisabled = hasViewOnlyAccess(pageComponent, project?.id, projectGroup?.id);

			if (project) {
				const {isUsingNewLazyLoad} = pageComponent;
				const {formatMessage} = intl;
				const hasLazyProgress = hasFeatureFlag('lazy_project_scheduling_progress');
				const isDoneOrHalted = isProjectDoneOrHalted(project.status);

				if (hasPermission(PERMISSION_TYPE.PROJECTS_UPDATE) && !isDoneOrHalted && !sharedContext) {
					draggableType = DRAGGABLE_TYPE.DATE_ONLY;
				}

				isPlanning = project.status === PROJECT_STATUS.PLANNING || project.status === PROJECT_STATUS.OPPORTUNITY;
				planningText =
					project.status === PROJECT_STATUS.OPPORTUNITY
						? formatMessage({id: 'project_status.opportunity'})
						: formatMessage({id: 'project_status.planning'});

				if (!isPlanning && hasLazyProgress && isUsingNewLazyLoad) {
					lazyLoadData = () => fetchProgressData(project.id);
				}

				isHourEstimated = project.estimationUnit === ESTIMATION_UNIT.HOURS;
				color = project.projectColor;
			}

			if (projectGroup) {
				isHourEstimated = projects[0].estimationUnit === ESTIMATION_UNIT.HOURS;
				color = projectGroup.color;
			}

			if (program) {
				const isDoneOrHalted = isProjectDoneOrHalted(program.stage.name);

				if (hasPermission(PERMISSION_TYPE.PROGRAMS_UPDATE) && !isDoneOrHalted && !sharedContext) {
					draggableType = DRAGGABLE_TYPE.DATE_ONLY;
				}

				completion = getProgramCompletion(pageComponent, program.id);
				color = program.color;
			}

			return {
				groupId: IDManager.getProjectGroupId(pageComponent, null, project?.id, projectGroup?.id, program?.prefix),
				startDate,
				endDate,
				draggableType,
				lazyLoadData,
				color,
				completion,
				project,
				projectGroup,
				projects,
				program,
				isHourEstimated,
				isPlanning,
				planningText,
				projectGroupId: project?.projectGroupId,
				disabled: isDisabled,
				onClickEnabled: hasPageViewOnlyAccess(pageComponent) || !isDisabled,
			};
		}

		return null;
	}

	static composeProgramGroup(pageComponent, program, groups = []) {
		const {isProjectTimeline} = pageComponent.props;

		let fullDataLoaded;
		if (!isProjectTimeline) {
			fullDataLoaded = false;
		}

		return {
			id: IDManager.getProjectGroupId(pageComponent, null, null, null, program.prefix),
			program,
			fullDataLoaded,
			groups,
		};
	}

	static composeCapacityPlaceholderGroup(pageComponent, placeholder, groups = []) {
		const {staffingModeActive, totalMinutesMap} = pageComponent.state;
		const {sharedContext, schedulingView} = pageComponent.props;
		const hasBetaChanges = hasFeatureFlag('capacity_planning_beta_2_improvements');

		const isCapacityOverview = schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;
		const isPlaceholdersScheduling = schedulingView === SCHEDULING_VIEW.PLACEHOLDERS;

		const project = DataManager.getProjectById(pageComponent, placeholder.projectId);
		const projectGroup = DataManager.getProjectGroupById(pageComponent, placeholder.projectGroupId);
		const hasViewOnly = hasViewOnlyAccess(pageComponent, project?.id, projectGroup?.id);

		const isDoneOrHalted = project ? isProjectDoneOrHalted(project.status) : false;
		const disabled = isDoneOrHalted || sharedContext || !hasPermission(PERMISSION_TYPE.SCHEDULING_ACCESS) || hasViewOnly;

		const placeholderSkills = DataManager.getPlaceholderSkillsByPlaceholder(pageComponent, placeholder.id).map(
			mapPlaceholderSkillsGroupData(pageComponent.getData())
		);
		const role = DataManager.getRoleById(pageComponent, placeholder.roleId);

		const isButtonDisabled = () => {
			if (!hasBetaChanges) {
				return true;
			}

			const placeholderAllocations = DataManager.getPlaceholderAllocationByPlaceholderId(pageComponent, placeholder.id);
			return !(placeholderAllocations?.length > 0);
		};

		let groupId, calculateHeatmapCache, composeItems, validHeatmapItemTypes, marginBottom, marginTop;
		let expandable = false;

		if (isCapacityOverview) {
			groupId = IDManager.getPlaceholderGroupingId(pageComponent, role?.id, null);
			expandable = true;
			composeItems = (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
				composePlaceholderGroupingHeatmapItems(pageComponent, group, stepDataArray, timelineMinorStep);
			calculateHeatmapCache = (group, stepDataArray, timelineMinorStep) =>
				calculatePlaceholderHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep);
			validHeatmapItemTypes = new Set([ITEM_TYPE.PLACEHOLDER_ALLOCATION]);
		}

		if (isPlaceholdersScheduling) {
			if (!staffingModeActive) {
				groupId = IDManager.getPlaceholderGroupingId(
					pageComponent,
					placeholder.roleId,
					placeholder.projectId || placeholder.projectGroupId
				);
			} else {
				marginBottom = STAFFING_MODE_MARGIN_TOP_BOTTOM;
				marginTop = STAFFING_MODE_MARGIN_TOP_BOTTOM;
			}
		}

		const drawAssignToButton = !staffingModeActive && !disabled && hasBetaChanges && canApproveAllocation();

		return {
			id: IDManager.getPlaceholderGroupId(pageComponent, placeholder),
			groupId,
			groups,
			companyTaskId: placeholder.companyTaskId,
			name: placeholder.name,
			placeholder,
			project: projectGroup || project,
			color: project?.projectColor,
			projectGroupColor: projectGroup?.color,
			isInProjectGroup: placeholder.projectGroupId,
			role: role?.name || '',
			skills: placeholderSkills,
			totalMinutesMap,
			isInProgram: project?.isInProgram,
			disabled,
			expandable,
			drawAssignToButton,
			isButtonDisabled,
			calculateHeatmapCache,
			composeItems,
			validHeatmapItemTypes,
			marginBottom,
			marginTop,
			preventExpansion: staffingModeActive,
			hasItemCreate: !disabled && !hasViewOnly,
		};
	}

	static composeProjectTeamItem(pageComponent, project, projectGroup, teamProfilePictureSrcs) {
		const projects = projectGroup ? DataManager.getProjectsByProjectGroupId(pageComponent, projectGroup.id) : null;
		const {startDate, endDate} = getProjectItemDates(pageComponent, project, projectGroup, projects, null);

		if (areItemDatesValid(startDate, endDate)) {
			let color;

			if (project) {
				color = project.projectColor;
			}

			if (projectGroup) {
				color = projectGroup.color;
			}

			return {
				groupId: IDManager.getProjectSubGroupId(
					pageComponent,
					project,
					projectGroup,
					PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM
				),
				project,
				projectGroup,
				startDate,
				endDate,
				color,
				teamProfilePictureSrcs,
				onPersonImageLoad: () => EventManager.onPersonImageLoad(pageComponent),
			};
		}

		return null;
	}

	static composeTimelineGraph(pageComponent) {
		const {eyeOptions} = pageComponent.state;
		const {intl} = pageComponent.props;
		const {formatMessage} = intl;

		const sections = [
			{
				graphType: TIMELINE_GRAPH_ENTITY_TYPE.LINE,
				entity: TIMELINE_GRAPH_LINE_TYPE.TOTAL_AVAILABILITY,
			},
			{
				graphType: TIMELINE_GRAPH_ENTITY_TYPE.BAR_HIGHLIGHTER,
				entity: TIMELINE_GRAPH_HIGHLIGHTER_TYPE.ROLE_OVERALLOCATED,
			},
			{
				graphType: TIMELINE_GRAPH_ENTITY_TYPE.BAR_ELEMENT,
				entity: TIMELINE_GRAPH_BAR_ELEMENT_TYPE.PLACEHOLDER,
			},
			{
				graphType: TIMELINE_GRAPH_ENTITY_TYPE.BAR_ELEMENT,
				entity: TIMELINE_GRAPH_BAR_ELEMENT_TYPE.SOFT_ALLOCATION,
				isHidden: () => pageComponent.state.schedulingOptions?.hideSoft,
			},
			{
				graphType: TIMELINE_GRAPH_ENTITY_TYPE.BAR_ELEMENT,
				entity: TIMELINE_GRAPH_BAR_ELEMENT_TYPE.ALLOCATION,
				isHidden: () => pageComponent.state.schedulingOptions?.hideHard,
			},
		];

		return {
			id: IDManager.getTimelineGraphId(pageComponent),
			preventExpansion: true,
			expanded: true,
			drawIfAnyChildVisible: false,
			title: formatMessage({id: 'scheduling.graph.capacity'}),
			sections,
			eyeOptions,
			getSchedulingOptions: () => pageComponent.state.schedulingOptions,
			composeItems: (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
				composeTimelineGraphRow(
					pageComponent,
					pageComponent.heatmapCache,
					group,
					stepDataArray,
					timelineStartDate,
					pixelsPerDay,
					timelineMinorStep,
					sections
				),
		};
	}

	static composeRoleGroupingGroup(pageComponent, role, groups = [], subGroupType = null) {
		let isPeopleRemaining, calculateHeatmapCache, composeItems;

		if (subGroupType && ROLE_GROUPING_SUB_GROUP_TYPE[subGroupType] !== undefined) {
			isPeopleRemaining = subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING;
			calculateHeatmapCache = (group, stepDataArray, timelineMinorStep) =>
				calculateGroupingGroupHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep);
			composeItems = (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
				composePersonGroupingGroupItems(pageComponent, group, stepDataArray, timelineMinorStep, isPeopleRemaining);
		} else {
			calculateHeatmapCache = (group, stepDataArray, timelineMinorStep) =>
				calculateRoleCapacityHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep);
			composeItems = (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
				composeRoleCapacityItems(pageComponent, group, stepDataArray, timelineMinorStep);
		}

		return {
			id: IDManager.getRoleGroupingGroupId(pageComponent, role, subGroupType),
			groups,
			subGroupType,
			isPeopleRemaining,
			roleId: role.id,
			name: role?.name || '',
			calculateHeatmapCache,
			composeItems,
		};
	}

	static composeStaffingGroupingGroup(pageComponent, staffingGroupingType, groups = []) {
		if (staffingGroupingType && STAFFING_GROUPING_TYPE[staffingGroupingType] !== undefined) {
			return {
				id: staffingGroupingType,
				isAllocatedPeople: staffingGroupingType === STAFFING_GROUPING_TYPE.ALLOCATED_PEOPLE,
				groups,
				preventExpansion: true,
				expanded: true,
			};
		}

		return null;
	}

	static composeHeatmapItem(pageComponent, group, stepData, heatmapData) {
		const {startDate, endDate} = stepData;
		const {minutesAllocated, plannedTotalMinutesHard, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin} = heatmapData;

		return {
			startDate,
			endDate,
			y: group.screenY,
			x: stepData.position,
			width: stepData.width,
			groupHeight: group.totalHeight,
			minutesAllocated,
			plannedTotalMinutesHard,
			plannedTotalMinutesSoft,
			plannedTotalMinutesSoftWin,
			isDayOffItem: isDayOffStep(pageComponent, stepData),
		};
	}

	static createHeatmapItem(pageComponent, heatmapType, group, stepData, heatmapData) {
		if (Object.values(HEATMAP_TYPE).includes(heatmapType)) {
			const heatmapItemData = this.composeHeatmapItem(pageComponent, group, stepData, heatmapData);
			return new HeatmapItem(pageComponent, heatmapType, heatmapItemData);
		}

		return null;
	}

	static composePlaceholderGroupingGroup(pageComponent, project, role, groups = []) {
		const {totalMinutesMap, groupBy} = pageComponent.state;

		if (project?.clientId) {
			project.client = DataManager.getClientById(pageComponent, project.clientId);
		}

		return {
			id: IDManager.getPlaceholderGroupingId(pageComponent, role?.id, project?.id),
			groups,
			project,
			projectGroup: project?.companyProjectGroupId ? project : null,
			role,
			groupBy,
			totalMinutesMap,
			calculateHeatmapCache: (group, stepDataArray, timelineMinorStep) =>
				calculatePlaceholderHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep),
			composeItems: (group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) =>
				composePlaceholderGroupingHeatmapItems(pageComponent, group, stepDataArray, timelineMinorStep),
			validHeatmapItemTypes: new Set([ITEM_TYPE.PLACEHOLDER_ALLOCATION]),
		};
	}

	static composeProjectEntityGroup(pageComponent, person, project, projectGroup, projectEntityGroupType) {
		const hasItemCreatePermission =
			projectEntityGroupType !== PROJECT_ENTITY_GROUP_TYPE.ALLOCATION || hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE);

		return {
			id: IDManager.getProjectEntityGroupId(
				pageComponent,
				person?.id,
				project?.id,
				projectGroup?.id,
				projectEntityGroupType
			),
			groupId: IDManager.getProjectGroupId(pageComponent, person.id, project?.id, projectGroup?.id, null),
			person,
			project,
			projectGroup,
			projectEntityGroupType,
			preventExpansion: true,
			renderRowLines: true,
			hasItemCreate: hasItemCreatePermission,
		};
	}
}
