export const PLACEHOLDERS_GROUP_BY = {
	ROLE: 'role',
	PROJECT: 'project',
};

export const PLACEHOLDERS_STAFFING_SORT_BY = {
	SUITABILITY: 'suitability',
	ALPHABETICALLY: 'alphabetically',
};

export const PLACEHOLDERS_EYE_OPTIONS = {
	SHOW_PLACEHOLDERS_WITH_NO_ALLOCATIONS: 'show-placeholders-with-no-allocation',
	SHOW_WEEKENDS: 'show-weekends',
};

export const STAFFING_CREATED_ENTITY_ID_PREFIX = 'STAFFING_CREATED_ENTITY';

// STAFFING MODE CHANGE LIST ENTITIES
export const STAFFING_CHANGE_LIST_ENTITIES = {
	PLACEHOLDER_ALLOCATIONS_CREATE_UPDATE: 'placeholderAllocationCreatesAndUpdates',
	PLACEHOLDER_ALLOCATIONS_DELETE: 'placeholderAllocationDeletes',
	PLACEHOLDER_ALLOCATIONS_GHOSTS: 'placeholderAllocationGhosts',
	PROJECT_ALLOCATIONS_CREATE_UPDATE: 'projectAllocationCreatesAndUpdates',
	PROJECT_ALLOCATIONS_DELETE: 'projectAllocationDeletes',
};

// STAFFING MODE OPERATIONS
export const STAFFING_OPERATION = {
	DRAG: 'DRAG',
	TRANSFER: 'TRANSFER',
};

// local storage keys
export const PLACEHOLDERS_FILTER_STORAGE_KEY = `placeholders_scheduling_filters`;
export const PLACEHOLDERS_STAFFING_FILTER_STORAGE_KEY = `placeholders_staffing_scheduling_filters`;
export const PLACEHOLDERS_SCHEDULING_GROUP_BY_STORAGE_KEY = 'placeholders_scheduling_group_by';
export const PLACEHOLDERS_SCHEDULING_EYE_STORAGE_KEY = 'placeholders_scheduling_eye_options';
export const PLACEHOLDERS_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY = 'placeholders_scheduling_utilization_format';
export const PLACEHOLDERS_SCHEDULING_SORT_BY_STORAGE_KEY = 'placeholders_scheduling_sort_by';

// TRACKING
export const PLACEHOLDERS_GROUP_BY_TRACKING_MESSAGE = 'Schedule Placeholders Group By';
export const STAFFING_MODE_ROLE_FILTER_REMOVED_TRACKING_MESSAGE = 'Staffing Mode Role Filter';
