import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {fetchData, fetchMapData} from '../reported_time_financials_fetch';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import {getClientRow} from '../rows/client_row';
import BaseLevelWrapper from './BaselevelWrapper';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {hasSomePermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {useDidObjectChange} from '../../../../forecast-app/shared/hooks/useDidObjectChange';

const clientRelayWrapper = ({
	tableHeader,
	relay,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
}) => {
	const clients = viewer.company.clients.edges;
	const currency = viewer.company.currency;
	const hasNextPage = viewer.company.clients.pageInfo.hasNextPage;
	const [clientsFinancialMap, setClientsFinancialMap] = useState(null);
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const [nonClientTotalMinutesRegistered, setNonClientTotalMinutesRegistered] = useState(null);
	const [nonClientTotalFinancials, setNonClientTotalFinancials] = useState(null);

	const clientsChanged = useDidObjectChange(clients, null);
	const searchQueryChanged = useDidObjectChange(searchQuery, null);
	const shouldRefetchFinancials = clientsChanged || searchQueryChanged;

	useEffect(() => {
		if (shouldRefetchFinancials) {
			const clientsIds = clients.map(client => client.node.id);
			if (
				hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				])
			) {
				fetchMapData(clientsIds, searchQuery, 'total_client_time_regs_financials').then(data => {
					const clientsFinancialMap = new Map();
					Object.keys(data).forEach(key => {
						clientsFinancialMap.set(key, data[key]);
					});
					setClientsFinancialMap(clientsFinancialMap);
				});
			}
			if (!hasNextPage) {
				fetchData(searchQuery, 'non_client_total_minutes_registered').then(data => {
					if (data.total !== undefined) {
						setNonClientTotalMinutesRegistered(data.total);
					}
				});
				fetchData(searchQuery, 'total_non_client_time_regs_financials').then(data => {
					setNonClientTotalFinancials(data.totals);
				});
			}
		}
	}, [shouldRefetchFinancials]);

	const dataRows = clients.map(client => {
		return {
			...client.node,
			totalPrice:
				clientsFinancialMap !== null && clientsFinancialMap.has(client.node.id)
					? clientsFinancialMap.get(client.node.id).totalPrice
					: 0,
			totalCost:
				clientsFinancialMap !== null && clientsFinancialMap.has(client.node.id)
					? clientsFinancialMap.get(client.node.id).totalCost
					: 0,
		};
	});

	nonClientTotalMinutesRegistered !== null &&
		dataRows.push({
			isNoClient: true,
			name: intl.formatMessage({id: 'time_reg_report.no_client'}),
			totalMinutesRegistered: nonClientTotalMinutesRegistered,
			totalPrice: nonClientTotalFinancials ? nonClientTotalFinancials.totalPrice : null,
			totalCost: nonClientTotalFinancials ? nonClientTotalFinancials.totalCost : null,
		});

	const data = {rows: dataRows};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = clients.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const clientRows = () => {
		return getClientRow(
			data,
			currency,
			intl,
			groupIndex,
			clientsFinancialMap,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader
		);
	};

	return groupIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			data={data}
			getRows={clientRows}
			currentLength={clients.length}
			hasNextPage={viewer.company.clients.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		clientRows()
	);
};

const clientRelayWrapperQuery = graphql`
	query clientRelayWrapperQuery_Query($searchQuery: TaskSearchQueryType, $pageSize: Int) {
		viewer {
			actualPersonId
			component(name: "client_relay_wrapper")
			...clientRelayWrapper_viewer @arguments(searchQuery: $searchQuery, pageSize: $pageSize)
		}
	}
`;
export {clientRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			clientRelayWrapper,
			{
				viewer: graphql`
					fragment clientRelayWrapper_viewer on Viewer
					@argumentDefinitions(searchQuery: {type: "TaskSearchQueryType"}, pageSize: {type: "Int"}) {
						availableFeatureFlags {
							key
						}
						company {
							currency
							clients(first: $pageSize, searchQuery: $searchQuery) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										totalMinutesRegistered(searchQuery: $searchQuery)
										id
										name
										logoId
										logoDefaultId
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query clientRelayWrapperRefetchQuery($pageSize: Int, $searchQuery: TaskSearchQueryType) {
					viewer {
						...clientRelayWrapper_viewer @arguments(pageSize: $pageSize, searchQuery: $searchQuery)
					}
				}
			`
		)
	)
);
