/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectPortfolioReportHeaderBar_viewer$ref = any;
type ProjectPortfolioReportTitleBar_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectPortfolioReport_viewer$ref: FragmentReference;
declare export opaque type ProjectPortfolioReport_viewer$fragmentType: ProjectPortfolioReport_viewer$ref;
export type ProjectPortfolioReport_viewer = {|
  +id: string,
  +actualPersonId: ?string,
  +company: ?{|
    +createdAt: ?string,
    +isFinancialServiceAvailable: ?boolean,
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +priorityLevels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string
        |}
      |}>
    |},
  |},
  +$fragmentRefs: ProjectPortfolioReportTitleBar_viewer$ref & ProjectPortfolioReportHeaderBar_viewer$ref,
  +$refType: ProjectPortfolioReport_viewer$ref,
|};
export type ProjectPortfolioReport_viewer$data = ProjectPortfolioReport_viewer;
export type ProjectPortfolioReport_viewer$key = {
  +$data?: ProjectPortfolioReport_viewer$data,
  +$fragmentRefs: ProjectPortfolioReport_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPortfolioReport_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isFinancialServiceAvailable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PriorityLevelTypeConnection",
          "kind": "LinkedField",
          "name": "priorityLevels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriorityLevelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriorityLevel",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectPortfolioReportTitleBar_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectPortfolioReportHeaderBar_viewer"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '73b8b10a309752c57248d7a7ae457482';

module.exports = node;
