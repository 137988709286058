import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SECTION_SPLITTER_HEIGHT} from './canvas_timeline_util';

class VerticalScrollbar extends Component {
	constructor(props) {
		super(props);

		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);

		if (window.PointerEvent) {
			window.addEventListener('pointerup', this.onMouseUp);
		} else {
			window.addEventListener('mouseup', this.onMouseUp);
		}
		window.addEventListener('mousemove', this.onMouseMove);

		this.state = {
			isScrolling: false,
			isTargetingCollapsableSection: false,
		};
	}

	componentWillUnmount() {
		if (window.PointerEvent) {
			window.removeEventListener('pointerup', this.onMouseUp);
		} else {
			window.removeEventListener('mouseup', this.onMouseUp);
		}
		window.removeEventListener('mousemove', this.onMouseMove);
	}

	onMouseDown(isTargetingCollapsableSection, e) {
		e.preventDefault();
		e.stopPropagation();
		this.initialMousePosition = e.clientY;
		this.setState({isScrolling: true, isTargetingCollapsableSection});
	}

	onMouseMove(e) {
		const {isScrolling, isTargetingCollapsableSection} = this.state;
		if (!isScrolling) return;
		const {scroll, mainSectionHeight, collapsableSectionHeight, mainSectionContentHeight, collapsableSectionContentHeight} =
			this.props;
		const sectionHeight = isTargetingCollapsableSection ? collapsableSectionHeight : mainSectionHeight;
		const contentHeight = isTargetingCollapsableSection ? collapsableSectionContentHeight : mainSectionContentHeight;
		const ratio = (sectionHeight + (contentHeight - sectionHeight)) / sectionHeight;
		const scrollReturnValue = scroll((e.clientY - this.initialMousePosition) * ratio, isTargetingCollapsableSection);
		//Scrolling will return false if browser can't keep up with animation
		if (!scrollReturnValue) return;
		this.initialMousePosition = e.clientY;
	}

	onMouseUp(e) {
		if (!this.state.isScrolling) return;
		this.setState({isScrolling: false});
		if (this.props.onScrollEnd) this.props.onScrollEnd();
	}

	render() {
		const {
			mainSectionScrollTop,
			collapsableSectionScrollTop,
			mainSectionHeight,
			collapsableSectionHeight,
			mainSectionContentHeight,
			collapsableSectionContentHeight,
			dateStepsSectionHeight,
		} = this.props;
		const {isScrolling, isTargetingCollapsableSection} = this.state;

		const adjustedCollapsableSectionHeight = collapsableSectionHeight - SECTION_SPLITTER_HEIGHT;
		const adjustedCollapsableSectionContentHeight = collapsableSectionContentHeight - SECTION_SPLITTER_HEIGHT;
		const collapsableSectionMaxScrollTop = adjustedCollapsableSectionContentHeight - adjustedCollapsableSectionHeight;
		const collapsableSectionThumbHeight = Math.round(
			(adjustedCollapsableSectionHeight / (adjustedCollapsableSectionHeight + collapsableSectionMaxScrollTop)) *
				adjustedCollapsableSectionHeight
		);
		const collapsableSectionThumbTop =
			(collapsableSectionScrollTop / collapsableSectionMaxScrollTop) *
				(adjustedCollapsableSectionHeight - collapsableSectionThumbHeight) +
			dateStepsSectionHeight;

		const mainSectionMaxScrollTop = mainSectionContentHeight - mainSectionHeight;
		const mainSectionThumbHeight = Math.round(
			(mainSectionHeight / (mainSectionHeight + mainSectionMaxScrollTop)) * mainSectionHeight
		);
		const mainSectionThumbTop =
			(mainSectionScrollTop / mainSectionMaxScrollTop) * (mainSectionHeight - mainSectionThumbHeight) +
			dateStepsSectionHeight +
			collapsableSectionHeight;

		return (
			<div className="canvas-timeline-horizontal-scrollbar-container">
				{collapsableSectionHeight && collapsableSectionMaxScrollTop > 0 ? (
					<div
						className={'scroll-thumb ' + (isScrolling && isTargetingCollapsableSection ? 'scrolling' : '')}
						style={{
							height: collapsableSectionThumbHeight,
							transform: `translateY(${collapsableSectionThumbTop}px)`,
						}}
						onMouseDown={this.onMouseDown.bind(this, true)}
					/>
				) : null}
				{mainSectionMaxScrollTop > 0 ? (
					<div
						className={'scroll-thumb ' + (isScrolling && !isTargetingCollapsableSection ? 'scrolling' : '')}
						style={{height: mainSectionThumbHeight, transform: `translateY(${mainSectionThumbTop}px)`}}
						onMouseDown={this.onMouseDown.bind(this, false)}
					/>
				) : null}
			</div>
		);
	}
}

VerticalScrollbar.propTypes = {
	mainSectionScrollTop: PropTypes.number.isRequired,
	collapsableSectionScrollTop: PropTypes.number.isRequired,
	mainSectionHeight: PropTypes.number.isRequired,
	collapsableSectionHeight: PropTypes.number.isRequired,
	mainSectionContentHeight: PropTypes.number.isRequired,
	collapsableSectionContentHeight: PropTypes.number.isRequired,
	scroll: PropTypes.func.isRequired,
	dateStepsSectionHeight: PropTypes.number.isRequired,
};

export default VerticalScrollbar;
