import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '../../forecast-app/shared/components/buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';

class GenericEmptyState extends Component {
	render() {
		return (
			<div className={'generic-empty-state-v2' + (this.props.smallComponent ? ' small' : '')}>
				<div className={'empty-state-elements'}>
					<div className={'empty-state-image'} />
					<div className={'empty-state-header'}>{this.props.header}</div>
					<div className={'empty-state-text'}>{this.props.text}</div>
					<Button
						colorTheme={BUTTON_COLOR.PURPLE}
						buttonStyle={BUTTON_STYLE.FILLED}
						className="action-button"
						text={this.props.actionText}
						onClick={this.props.callback.bind(this)}
						buttonTitle={this.props.actionTitle}
					/>
				</div>
			</div>
		);
	}
}

GenericEmptyState.propTypes = {
	header: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	callback: PropTypes.func.isRequired,
	actionText: PropTypes.string,
	smallComponent: PropTypes.bool,
};

export default GenericEmptyState;
