import React from 'react';
import styled from 'styled-components/macro';

import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import ProjectPortfolioReportCharts, {ProjectPortfolioReportChartsQuery} from './ProjectPortfolioReportCharts';
import {filtersToSearchQuery} from './ProjectPortfolioReportUtils';
import {OutlineBox, Section} from './ProjectPortfolioReport.styled';
import {isFeatureHidden} from '../../../forecast-app/shared/util/CompanySetupUtil';
import {HIDDEN_FEATURES} from '../../../constants';
import {PROJECT_PORTFOLIO_SINGLE_NUMBER, COST_VALUES} from './ProjectPortfolioSingleNumberOptions';

const ProjectPortfolioReportChartsQueryRendererStyle = styled.div`
	height: 300px;
`;

export const ProjectPortfolioReportChartsQueryRenderer = ({
	selectedSingleValue,
	filterValue,
	selectedDateRange: {startDate, endDate},
	hasFinance,
}) => {
	if (!hasFinance) {
		return null;
	}

	let aggregateLevel;
	if ((!startDate && !endDate) || endDate.clone().add(1, 'day').diff(startDate, 'months') > 6) {
		aggregateLevel = 'MONTH';
	} else {
		aggregateLevel = 'DAY';
	}

	// Change selected value (if it is not cost related already) for reports that were created and saved before revenue was turned off
	if (isFeatureHidden(HIDDEN_FEATURES.REVENUE) && !COST_VALUES.includes(selectedSingleValue)) {
		selectedSingleValue = PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_COST;
	}

	let aggregates = [];
	switch (selectedSingleValue) {
		case 'billableActualTimeAndExpenses':
			aggregates.push('billableActualTimeAndExpenses');
			break;
		case 'actualCost':
			aggregates.push('actualCost');
			break;
		case 'actualProfit':
			aggregates.push('actualProfit');
			break;
		case 'billablePlannedTimeAndExpenses':
			aggregates.push('billablePlannedTimeAndExpenses');
			break;
		case 'plannedCost':
			aggregates.push('plannedCost');
			break;
		case 'plannedProfit':
			aggregates.push('plannedProfit');
			break;
		case 'billableForecastTimeAndExpensesToComplete':
			aggregates.push('billableForecastTimeAndExpensesToComplete');
			break;
		case 'forecastCostToComplete':
			aggregates.push('forecastCostToComplete');
			break;
		case 'forecastProfitToComplete':
			aggregates.push('forecastProfitToComplete');
			break;
		case 'billableTotalTimeAndExpensesAtCompletion':
			aggregates.push('billableActualTimeAndExpenses', 'billableTotalTimeAndExpensesAtCompletion');
			break;
		case 'totalCostAtCompletion':
			aggregates.push('actualCost', 'totalCostAtCompletion');
			break;
		case 'totalProfitAtCompletion':
			aggregates.push('actualProfit', 'totalProfitAtCompletion');
			break;
		case 'invoiced':
			aggregates.push('invoiced');
			break;
		case 'paid':
			aggregates.push('paid');
			break;
		case 'recognitionAmount':
			aggregates.push('recognitionAmount');
			break;
		default:
			throw new Error(`Unsupported selectedSingleValue ${selectedSingleValue}`);
	}

	return (
		<Section sticky marginLeft={24} marginRight={24}>
			<OutlineBox growable data-userpilot={'project-portfolio-report-charts'}>
				<ProjectPortfolioReportChartsQueryRendererStyle>
					<ForecastQueryRenderer
						key="query-render-projectPortfolioReportChartsQuery"
						query={ProjectPortfolioReportChartsQuery}
						variables={{
							searchQuery: filtersToSearchQuery(filterValue),
							startYear: startDate ? startDate.year() : undefined,
							startMonth: startDate ? startDate.month() + 1 : undefined,
							startDay: startDate ? startDate.date() : undefined,
							endYear: endDate ? endDate.year() : undefined,
							endMonth: endDate ? endDate.month() + 1 : undefined,
							endDay: endDate ? endDate.date() : undefined,
							aggregateLevel,
							aggregates,
						}}
						render={relayProps => {
							return (
								<ProjectPortfolioReportCharts
									{...relayProps}
									selectedSingleValue={selectedSingleValue}
									aggregates={aggregates}
									aggregateLevel={aggregateLevel}
									startDate={startDate}
									endDate={endDate}
								/>
							);
						}}
					/>
				</ProjectPortfolioReportChartsQueryRendererStyle>
			</OutlineBox>
		</Section>
	);
};
