/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type STATUS_CATEGORY = "DONE" | "INPROGRESS" | "TODO" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type projectTimelineComponent_viewer$ref: FragmentReference;
declare export opaque type projectTimelineComponent_viewer$fragmentType: projectTimelineComponent_viewer$ref;
export type projectTimelineComponent_viewer = {|
  +insightComponentsData: ?{|
    +projectTimeline: ?{|
      +project: ?{|
        +name: ?string,
        +projectColor: ?string,
        +projectEndYear: ?number,
        +projectEndMonth: ?number,
        +projectEndDay: ?number,
        +projectStartYear: ?number,
        +projectStartMonth: ?number,
        +projectStartDay: ?number,
        +completion: ?number,
      |},
      +phases: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +startYear: ?number,
        +startMonth: ?number,
        +startDay: ?number,
        +deadlineYear: ?number,
        +deadlineMonth: ?number,
        +deadlineDay: ?number,
      |}>,
      +tasks: ?$ReadOnlyArray<?{|
        +id: string,
        +companyTaskId: ?number,
        +name: ?string,
        +startYear: ?number,
        +startMonth: ?number,
        +startDay: ?number,
        +startFrom: ?string,
        +deadlineYear: ?number,
        +deadlineMonth: ?number,
        +deadlineDay: ?number,
        +deadlineFrom: ?string,
        +estimateForecast: ?number,
        +timeLeft: ?number,
        +phase: ?{|
          +id: string,
          +name: ?string,
          +startYear: ?number,
          +startMonth: ?number,
          +startDay: ?number,
          +deadlineYear: ?number,
          +deadlineMonth: ?number,
          +deadlineDay: ?number,
        |},
        +assignedPersons: ?$ReadOnlyArray<?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
        |}>,
        +statusColumn: ?{|
          +category: ?STATUS_CATEGORY
        |},
      |}>,
    |}
  |},
  +$refType: projectTimelineComponent_viewer$ref,
|};
export type projectTimelineComponent_viewer$data = projectTimelineComponent_viewer;
export type projectTimelineComponent_viewer$key = {
  +$data?: projectTimelineComponent_viewer$data,
  +$fragmentRefs: projectTimelineComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v8 = [
  (v1/*: any*/),
  (v0/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProjectGroupType",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectTimelineComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "isProjectGroupType",
              "variableName": "isProjectGroupType"
            },
            {
              "kind": "Variable",
              "name": "projectId",
              "variableName": "projectId"
            }
          ],
          "concreteType": "ProjectTimeline",
          "kind": "LinkedField",
          "name": "projectTimeline",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTimelineProjectType",
              "kind": "LinkedField",
              "name": "project",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectColor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectEndYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectEndMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectEndDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectStartYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectStartMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "projectStartDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "completion",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTimelinePhaseType",
              "kind": "LinkedField",
              "name": "phases",
              "plural": true,
              "selections": (v8/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTimelineTaskType",
              "kind": "LinkedField",
              "name": "tasks",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyTaskId",
                  "storageKey": null
                },
                (v0/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startFrom",
                  "storageKey": null
                },
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deadlineFrom",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "estimateForecast",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timeLeft",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PhaseType",
                  "kind": "LinkedField",
                  "name": "phase",
                  "plural": false,
                  "selections": (v8/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "assignedPersons",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureDefaultId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "StatusColumn",
                  "kind": "LinkedField",
                  "name": "statusColumn",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "category",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '36a32ae1aab7ff54a472a4f1a9469fc0';

module.exports = node;
