/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PersonLoader_viewer$ref: FragmentReference;
declare export opaque type PersonLoader_viewer$fragmentType: PersonLoader_viewer$ref;
export type PersonLoader_viewer = {|
  +company: ?{|
    +resourceBasedPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
          +resourceNumbers: ?{|
            +actualBillableProjectMinutes: ?number,
            +actualNonBillableProjectMinutes: ?number,
            +actualInternalMinutes: ?number,
            +actualTotalMinutes: ?number,
            +actualOverMinutes: ?number,
            +actualDoneTaskMinutes: ?number,
            +actualBillableUtilization: ?number,
            +actualNonBillableUtilization: ?number,
            +actualInternalUtilization: ?number,
            +actualResourceUtilization: ?number,
            +plannedBillableProjectMinutes: ?number,
            +plannedBillableProjectMinutesWin: ?number,
            +plannedBillableProjectMinutesSoft: ?number,
            +plannedBillableProjectMinutesSoftWin: ?number,
            +plannedBillableProjectMinutesHard: ?number,
            +plannedNonBillableProjectMinutes: ?number,
            +plannedNonBillableProjectMinutesWin: ?number,
            +plannedNonBillableProjectMinutesSoft: ?number,
            +plannedNonBillableProjectMinutesSoftWin: ?number,
            +plannedNonBillableProjectMinutesHard: ?number,
            +plannedInternalMinutes: ?number,
            +plannedTotalMinutes: ?number,
            +plannedTotalMinutesWin: ?number,
            +plannedTotalMinutesSoft: ?number,
            +plannedTotalMinutesSoftWin: ?number,
            +plannedTotalMinutesHard: ?number,
            +plannedOverMinutes: ?number,
            +plannedOverMinutesWin: ?number,
            +plannedOverMinutesSoft: ?number,
            +plannedOverMinutesSoftWin: ?number,
            +plannedOverMinutesHard: ?number,
            +plannedDoneTaskMinutes: ?number,
            +plannedBillableUtilization: ?number,
            +plannedBillableUtilizationWin: ?number,
            +plannedBillableUtilizationSoft: ?number,
            +plannedBillableUtilizationSoftWin: ?number,
            +plannedBillableUtilizationHard: ?number,
            +plannedNonBillableUtilization: ?number,
            +plannedNonBillableUtilizationWin: ?number,
            +plannedNonBillableUtilizationSoft: ?number,
            +plannedNonBillableUtilizationSoftWin: ?number,
            +plannedNonBillableUtilizationHard: ?number,
            +plannedInternalUtilization: ?number,
            +plannedResourceUtilization: ?number,
            +plannedResourceUtilizationWin: ?number,
            +plannedResourceUtilizationSoft: ?number,
            +plannedResourceUtilizationSoftWin: ?number,
            +plannedResourceUtilizationHard: ?number,
            +plannedBillableTaskAndAllocationsCombined: ?number,
            +plannedNonBillableTaskAndAllocationsCombined: ?number,
            +plannedInternalTimeTaskAndAllocationsCombined: ?number,
            +plannedTotalTimeTaskAndAllocationsCombined: ?number,
            +plannedBillableUtilizationTaskAndAllocationsCombined: ?number,
            +plannedNonBillableUtilizationTaskAndAllocationsCombined: ?number,
            +plannedInternalUtilizationTaskAndAllocationsCombined: ?number,
            +plannedResourceUtilizationTaskAndAllocationsCombined: ?number,
            +plannedBillableTaskAndAllocationsCombinedWin: ?number,
            +plannedNonBillableTaskAndAllocationsCombinedWin: ?number,
            +plannedTotalTimeTaskAndAllocationsCombinedWin: ?number,
            +plannedBillableUtilizationTaskAndAllocationsCombinedWin: ?number,
            +plannedNonBillableUtilizationTaskAndAllocationsCombinedWin: ?number,
            +plannedResourceUtilizationTaskAndAllocationsCombinedWin: ?number,
            +plannedBillableTaskAndAllocationsCombinedSoft: ?number,
            +plannedNonBillableTaskAndAllocationsCombinedSoft: ?number,
            +plannedTotalTimeTaskAndAllocationsCombinedSoft: ?number,
            +plannedBillableUtilizationTaskAndAllocationsCombinedSoft: ?number,
            +plannedNonBillableUtilizationTaskAndAllocationsCombinedSoft: ?number,
            +plannedResourceUtilizationTaskAndAllocationsCombinedSoft: ?number,
            +plannedBillableTaskAndAllocationsCombinedSoftWin: ?number,
            +plannedNonBillableTaskAndAllocationsCombinedSoftWin: ?number,
            +plannedTotalTimeTaskAndAllocationsCombinedSoftWin: ?number,
            +plannedBillableUtilizationTaskAndAllocationsCombinedSoftWin: ?number,
            +plannedNonBillableUtilizationTaskAndAllocationsCombinedSoftWin: ?number,
            +plannedResourceUtilizationTaskAndAllocationsCombinedSoftWin: ?number,
            +plannedBillableTaskAndAllocationsCombinedHard: ?number,
            +plannedNonBillableTaskAndAllocationsCombinedHard: ?number,
            +plannedTotalTimeTaskAndAllocationsCombinedHard: ?number,
            +plannedBillableUtilizationTaskAndAllocationsCombinedHard: ?number,
            +plannedNonBillableUtilizationTaskAndAllocationsCombinedHard: ?number,
            +plannedResourceUtilizationTaskAndAllocationsCombinedHard: ?number,
            +billableActualVsPlan: ?number,
            +billableActualVsPlanWin: ?number,
            +billableActualVsPlanSoft: ?number,
            +billableActualVsPlanSoftWin: ?number,
            +billableActualVsPlanHard: ?number,
            +nonBillableActualVsPlan: ?number,
            +nonBillableActualVsPlanWin: ?number,
            +nonBillableActualVsPlanSoft: ?number,
            +nonBillableActualVsPlanSoftWin: ?number,
            +nonBillableActualVsPlanHard: ?number,
            +internalActualVsPlan: ?number,
            +totalActualVsPlan: ?number,
            +totalActualVsPlanWin: ?number,
            +totalActualVsPlanSoft: ?number,
            +totalActualVsPlanSoftWin: ?number,
            +totalActualVsPlanHard: ?number,
            +remainingBillableProjectMinutes: ?number,
            +remainingBillableProjectMinutesWin: ?number,
            +remainingBillableProjectMinutesSoft: ?number,
            +remainingBillableProjectMinutesSoftWin: ?number,
            +remainingBillableProjectMinutesHard: ?number,
            +remainingNonBillableProjectMinutes: ?number,
            +remainingNonBillableProjectMinutesWin: ?number,
            +remainingNonBillableProjectMinutesSoft: ?number,
            +remainingNonBillableProjectMinutesSoftWin: ?number,
            +remainingNonBillableProjectMinutesHard: ?number,
            +remainingInternalMinutes: ?number,
            +remainingTotalMinutes: ?number,
            +remainingTotalMinutesWin: ?number,
            +remainingTotalMinutesSoft: ?number,
            +remainingTotalMinutesSoftWin: ?number,
            +remainingTotalMinutesHard: ?number,
            +remainingOverMinutes: ?number,
            +remainingBillableUtilization: ?number,
            +remainingBillableUtilizationWin: ?number,
            +remainingBillableUtilizationSoft: ?number,
            +remainingBillableUtilizationSoftWin: ?number,
            +remainingBillableUtilizationHard: ?number,
            +remainingNonBillableUtilization: ?number,
            +remainingNonBillableUtilizationWin: ?number,
            +remainingNonBillableUtilizationSoft: ?number,
            +remainingNonBillableUtilizationSoftWin: ?number,
            +remainingNonBillableUtilizationHard: ?number,
            +remainingInternalUtilization: ?number,
            +remainingResourceUtilization: ?number,
            +remainingResourceUtilizationWin: ?number,
            +remainingResourceUtilizationSoft: ?number,
            +remainingResourceUtilizationSoftWin: ?number,
            +remainingResourceUtilizationHard: ?number,
            +remainingBillableTaskAndAllocationsCombined: ?number,
            +remainingNonBillableTaskAndAllocationsCombined: ?number,
            +remainingInternalTimeTaskAndAllocationsCombined: ?number,
            +remainingTotalTimeTaskAndAllocationsCombined: ?number,
            +remainingBillableUtilizationTaskAndAllocationsCombined: ?number,
            +remainingNonBillableUtilizationTaskAndAllocationsCombined: ?number,
            +remainingInternalUtilizationTaskAndAllocationsCombined: ?number,
            +remainingResourceUtilizationTaskAndAllocationsCombined: ?number,
            +remainingBillableTaskAndAllocationsCombinedWin: ?number,
            +remainingNonBillableTaskAndAllocationsCombinedWin: ?number,
            +remainingTotalTimeTaskAndAllocationsCombinedWin: ?number,
            +remainingBillableUtilizationTaskAndAllocationsCombinedWin: ?number,
            +remainingNonBillableUtilizationTaskAndAllocationsCombinedWin: ?number,
            +remainingResourceUtilizationTaskAndAllocationsCombinedWin: ?number,
            +remainingBillableTaskAndAllocationsCombinedSoft: ?number,
            +remainingNonBillableTaskAndAllocationsCombinedSoft: ?number,
            +remainingTotalTimeTaskAndAllocationsCombinedSoft: ?number,
            +remainingBillableUtilizationTaskAndAllocationsCombinedSoft: ?number,
            +remainingNonBillableUtilizationTaskAndAllocationsCombinedSoft: ?number,
            +remainingResourceUtilizationTaskAndAllocationsCombinedSoft: ?number,
            +remainingBillableTaskAndAllocationsCombinedSoftWin: ?number,
            +remainingNonBillableTaskAndAllocationsCombinedSoftWin: ?number,
            +remainingTotalTimeTaskAndAllocationsCombinedSoftWin: ?number,
            +remainingBillableUtilizationTaskAndAllocationsCombinedSoftWin: ?number,
            +remainingNonBillableUtilizationTaskAndAllocationsCombinedSoftWin: ?number,
            +remainingResourceUtilizationTaskAndAllocationsCombinedSoftWin: ?number,
            +remainingBillableTaskAndAllocationsCombinedHard: ?number,
            +remainingNonBillableTaskAndAllocationsCombinedHard: ?number,
            +remainingTotalTimeTaskAndAllocationsCombinedHard: ?number,
            +remainingBillableUtilizationTaskAndAllocationsCombinedHard: ?number,
            +remainingNonBillableUtilizationTaskAndAllocationsCombinedHard: ?number,
            +remainingResourceUtilizationTaskAndAllocationsCombinedHard: ?number,
            +forecastBillableProjectMinutes: ?number,
            +forecastBillableProjectMinutesWin: ?number,
            +forecastBillableProjectMinutesSoft: ?number,
            +forecastBillableProjectMinutesSoftWin: ?number,
            +forecastBillableProjectMinutesHard: ?number,
            +forecastNonBillableProjectMinutes: ?number,
            +forecastNonBillableProjectMinutesWin: ?number,
            +forecastNonBillableProjectMinutesSoft: ?number,
            +forecastNonBillableProjectMinutesSoftWin: ?number,
            +forecastNonBillableProjectMinutesHard: ?number,
            +forecastInternalMinutes: ?number,
            +forecastTotalMinutes: ?number,
            +forecastTotalMinutesWin: ?number,
            +forecastTotalMinutesSoft: ?number,
            +forecastTotalMinutesSoftWin: ?number,
            +forecastTotalMinutesHard: ?number,
            +forecastOverMinutes: ?number,
            +forecastOverMinutesWin: ?number,
            +forecastOverMinutesSoft: ?number,
            +forecastOverMinutesSoftWin: ?number,
            +forecastOverMinutesHard: ?number,
            +forecastBillableUtilization: ?number,
            +forecastBillableUtilizationWin: ?number,
            +forecastBillableUtilizationSoft: ?number,
            +forecastBillableUtilizationSoftWin: ?number,
            +forecastBillableUtilizationHard: ?number,
            +forecastNonBillableUtilization: ?number,
            +forecastNonBillableUtilizationWin: ?number,
            +forecastNonBillableUtilizationSoft: ?number,
            +forecastNonBillableUtilizationSoftWin: ?number,
            +forecastNonBillableUtilizationHard: ?number,
            +forecastInternalUtilization: ?number,
            +forecastResourceUtilization: ?number,
            +forecastResourceUtilizationWin: ?number,
            +forecastResourceUtilizationSoft: ?number,
            +forecastResourceUtilizationSoftWin: ?number,
            +forecastResourceUtilizationHard: ?number,
            +forecastBillableTaskAndAllocationsCombined: ?number,
            +forecastNonBillableTaskAndAllocationsCombined: ?number,
            +forecastInternalTimeTaskAndAllocationsCombined: ?number,
            +forecastTotalTimeTaskAndAllocationsCombined: ?number,
            +forecastBillableUtilizationTaskAndAllocationsCombined: ?number,
            +forecastNonBillableUtilizationTaskAndAllocationsCombined: ?number,
            +forecastInternalUtilizationTaskAndAllocationsCombined: ?number,
            +forecastResourceUtilizationTaskAndAllocationsCombined: ?number,
            +forecastBillableTaskAndAllocationsCombinedWin: ?number,
            +forecastNonBillableTaskAndAllocationsCombinedWin: ?number,
            +forecastTotalTimeTaskAndAllocationsCombinedWin: ?number,
            +forecastBillableUtilizationTaskAndAllocationsCombinedWin: ?number,
            +forecastNonBillableUtilizationTaskAndAllocationsCombinedWin: ?number,
            +forecastResourceUtilizationTaskAndAllocationsCombinedWin: ?number,
            +forecastBillableTaskAndAllocationsCombinedSoft: ?number,
            +forecastNonBillableTaskAndAllocationsCombinedSoft: ?number,
            +forecastTotalTimeTaskAndAllocationsCombinedSoft: ?number,
            +forecastBillableUtilizationTaskAndAllocationsCombinedSoft: ?number,
            +forecastNonBillableUtilizationTaskAndAllocationsCombinedSoft: ?number,
            +forecastResourceUtilizationTaskAndAllocationsCombinedSoft: ?number,
            +forecastBillableTaskAndAllocationsCombinedSoftWin: ?number,
            +forecastNonBillableTaskAndAllocationsCombinedSoftWin: ?number,
            +forecastTotalTimeTaskAndAllocationsCombinedSoftWin: ?number,
            +forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin: ?number,
            +forecastNonBillableUtilizationTaskAndAllocationsCombinedSoftWin: ?number,
            +forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin: ?number,
            +forecastBillableTaskAndAllocationsCombinedHard: ?number,
            +forecastNonBillableTaskAndAllocationsCombinedHard: ?number,
            +forecastTotalTimeTaskAndAllocationsCombinedHard: ?number,
            +forecastBillableUtilizationTaskAndAllocationsCombinedHard: ?number,
            +forecastNonBillableUtilizationTaskAndAllocationsCombinedHard: ?number,
            +forecastResourceUtilizationTaskAndAllocationsCombinedHard: ?number,
            +tasksEstimateBillableMinutes: ?number,
            +tasksEstimateNonBillableMinutes: ?number,
            +tasksEstimateTotalMinutes: ?number,
            +tasksBillableUtilization: ?number,
            +tasksNonBillableUtilization: ?number,
            +tasksResourceUtilization: ?number,
            +tasksOverMinutes: ?number,
            +tasksRemainingBillableMinutes: ?number,
            +tasksRemainingNonBillableMinutes: ?number,
            +tasksRemainingTotalMinutes: ?number,
            +tasksRemainingOverMinutes: ?number,
            +tasksRemainingBillableUtilization: ?number,
            +tasksRemainingNonBillableUtilization: ?number,
            +tasksRemainingResourceUtilization: ?number,
            +tasksForecastBillableMinutes: ?number,
            +tasksForecastNonBillableMinutes: ?number,
            +tasksForecastTotalMinutes: ?number,
            +tasksForecastOverMinutes: ?number,
            +tasksForecastBillableUtilization: ?number,
            +tasksForecastNonBillableUtilization: ?number,
            +tasksForecastResourceUtilization: ?number,
            +tasksBillableActualVsPlan: ?number,
            +tasksNonBillableActualVsPlan: ?number,
            +tasksTotalActualVsPlan: ?number,
            +plannedBillableAllocationVsTask: ?number,
            +plannedBillableAllocationVsTaskWin: ?number,
            +plannedBillableAllocationVsTaskSoft: ?number,
            +plannedBillableAllocationVsTaskSoftWin: ?number,
            +plannedBillableAllocationVsTaskHard: ?number,
            +plannedNonBillableAllocationVsTask: ?number,
            +plannedNonBillableAllocationVsTaskWin: ?number,
            +plannedNonBillableAllocationVsTaskSoft: ?number,
            +plannedNonBillableAllocationVsTaskSoftWin: ?number,
            +plannedNonBillableAllocationVsTaskHard: ?number,
            +plannedAllocationVsTask: ?number,
            +plannedAllocationVsTaskWin: ?number,
            +plannedAllocationVsTaskSoft: ?number,
            +plannedAllocationVsTaskSoftWin: ?number,
            +plannedAllocationVsTaskHard: ?number,
            +remainingBillableAllocationVsTask: ?number,
            +remainingBillableAllocationVsTaskWin: ?number,
            +remainingBillableAllocationVsTaskSoft: ?number,
            +remainingBillableAllocationVsTaskSoftWin: ?number,
            +remainingBillableAllocationVsTaskHard: ?number,
            +remainingNonBillableAllocationVsTask: ?number,
            +remainingNonBillableAllocationVsTaskWin: ?number,
            +remainingNonBillableAllocationVsTaskSoft: ?number,
            +remainingNonBillableAllocationVsTaskSoftWin: ?number,
            +remainingNonBillableAllocationVsTaskHard: ?number,
            +remainingAllocationVsTask: ?number,
            +remainingAllocationVsTaskWin: ?number,
            +remainingAllocationVsTaskSoft: ?number,
            +remainingAllocationVsTaskSoftWin: ?number,
            +remainingAllocationVsTaskHard: ?number,
            +workingMinutes: ?number,
            +timeOffMinutes: ?number,
            +availableMinutes: ?number,
            +performance: ?number,
          |},
        |}
      |}>
    |},
    +programs: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +color: ?string,
          +prefix: ?string,
          +name: ?string,
          +projects: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +serviceCompanyId: ?number,
              |}
            |}>
          |},
        |}
      |}>
    |},
  |},
  +$refType: PersonLoader_viewer$ref,
|};
export type PersonLoader_viewer$data = PersonLoader_viewer;
export type PersonLoader_viewer$key = {
  +$data?: PersonLoader_viewer$data,
  +$fragmentRefs: PersonLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "company",
          "resourceBasedPersons"
        ]
      }
    ]
  },
  "name": "PersonLoader_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": "resourceBasedPersons",
          "args": [
            {
              "kind": "Variable",
              "name": "endDay",
              "variableName": "endDay"
            },
            {
              "kind": "Variable",
              "name": "endMonth",
              "variableName": "endMonth"
            },
            {
              "kind": "Variable",
              "name": "endYear",
              "variableName": "endYear"
            },
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            },
            {
              "kind": "Variable",
              "name": "startDay",
              "variableName": "startDay"
            },
            {
              "kind": "Variable",
              "name": "startMonth",
              "variableName": "startMonth"
            },
            {
              "kind": "Variable",
              "name": "startYear",
              "variableName": "startYear"
            }
          ],
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_resourceBasedPersons_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ResourceNumbers",
                      "kind": "LinkedField",
                      "name": "resourceNumbers",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualBillableProjectMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualNonBillableProjectMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualInternalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualTotalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualOverMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualDoneTaskMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualNonBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualInternalUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualResourceUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableProjectMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableProjectMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableProjectMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableProjectMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableProjectMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableProjectMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableProjectMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableProjectMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableProjectMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableProjectMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedInternalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedTotalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedTotalMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedTotalMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedTotalMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedTotalMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedOverMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedOverMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedOverMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedOverMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedOverMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedDoneTaskMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableUtilizationWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableUtilizationSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableUtilizationSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableUtilizationHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableUtilizationWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableUtilizationSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableUtilizationSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableUtilizationHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedInternalUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedResourceUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedResourceUtilizationWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedResourceUtilizationSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedResourceUtilizationSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedResourceUtilizationHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedInternalTimeTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedTotalTimeTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedInternalUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedResourceUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedTotalTimeTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableUtilizationTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableUtilizationTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedResourceUtilizationTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedTotalTimeTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableUtilizationTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableUtilizationTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedResourceUtilizationTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedTotalTimeTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableUtilizationTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableUtilizationTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedResourceUtilizationTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedTotalTimeTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableUtilizationTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableUtilizationTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedResourceUtilizationTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableActualVsPlan",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableActualVsPlanWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableActualVsPlanSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableActualVsPlanSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableActualVsPlanHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nonBillableActualVsPlan",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nonBillableActualVsPlanWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nonBillableActualVsPlanSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nonBillableActualVsPlanSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nonBillableActualVsPlanHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "internalActualVsPlan",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalActualVsPlan",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalActualVsPlanWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalActualVsPlanSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalActualVsPlanSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalActualVsPlanHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableProjectMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableProjectMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableProjectMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableProjectMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableProjectMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableProjectMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableProjectMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableProjectMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableProjectMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableProjectMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingInternalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingTotalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingTotalMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingTotalMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingTotalMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingTotalMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingOverMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableUtilizationWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableUtilizationSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableUtilizationSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableUtilizationHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableUtilizationWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableUtilizationSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableUtilizationSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableUtilizationHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingInternalUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingResourceUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingResourceUtilizationWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingResourceUtilizationSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingResourceUtilizationSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingResourceUtilizationHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingInternalTimeTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingTotalTimeTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingInternalUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingResourceUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingTotalTimeTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableUtilizationTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableUtilizationTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingResourceUtilizationTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingTotalTimeTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableUtilizationTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableUtilizationTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingResourceUtilizationTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingTotalTimeTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableUtilizationTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableUtilizationTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingResourceUtilizationTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingTotalTimeTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableUtilizationTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableUtilizationTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingResourceUtilizationTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableProjectMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableProjectMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableProjectMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableProjectMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableProjectMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableProjectMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableProjectMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableProjectMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableProjectMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableProjectMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastInternalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTotalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTotalMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTotalMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTotalMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTotalMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastOverMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastOverMinutesWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastOverMinutesSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastOverMinutesSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastOverMinutesHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableUtilizationWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableUtilizationSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableUtilizationSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableUtilizationHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableUtilizationWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableUtilizationSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableUtilizationSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableUtilizationHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastInternalUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastResourceUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastResourceUtilizationWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastResourceUtilizationSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastResourceUtilizationSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastResourceUtilizationHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastInternalTimeTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTotalTimeTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastInternalUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastResourceUtilizationTaskAndAllocationsCombined",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTotalTimeTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableUtilizationTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableUtilizationTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastResourceUtilizationTaskAndAllocationsCombinedWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTotalTimeTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableUtilizationTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableUtilizationTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastResourceUtilizationTaskAndAllocationsCombinedSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTotalTimeTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableUtilizationTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTotalTimeTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastBillableUtilizationTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastNonBillableUtilizationTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastResourceUtilizationTaskAndAllocationsCombinedHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksEstimateBillableMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksEstimateNonBillableMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksEstimateTotalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksNonBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksResourceUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksOverMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksRemainingBillableMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksRemainingNonBillableMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksRemainingTotalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksRemainingOverMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksRemainingBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksRemainingNonBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksRemainingResourceUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksForecastBillableMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksForecastNonBillableMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksForecastTotalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksForecastOverMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksForecastBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksForecastNonBillableUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksForecastResourceUtilization",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksBillableActualVsPlan",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksNonBillableActualVsPlan",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tasksTotalActualVsPlan",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableAllocationVsTask",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableAllocationVsTaskWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableAllocationVsTaskSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableAllocationVsTaskSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedBillableAllocationVsTaskHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableAllocationVsTask",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableAllocationVsTaskWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableAllocationVsTaskSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableAllocationVsTaskSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedNonBillableAllocationVsTaskHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedAllocationVsTask",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedAllocationVsTaskWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedAllocationVsTaskSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedAllocationVsTaskSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedAllocationVsTaskHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableAllocationVsTask",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableAllocationVsTaskWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableAllocationVsTaskSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableAllocationVsTaskSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingBillableAllocationVsTaskHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableAllocationVsTask",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableAllocationVsTaskWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableAllocationVsTaskSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableAllocationVsTaskSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingNonBillableAllocationVsTaskHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingAllocationVsTask",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingAllocationVsTaskWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingAllocationVsTaskSoft",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingAllocationVsTaskSoftWin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "remainingAllocationVsTaskHard",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "workingMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "timeOffMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "availableMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "performance",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 100000
            }
          ],
          "concreteType": "ProjectServiceProgramTypeConnection",
          "kind": "LinkedField",
          "name": "programs",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProgramType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "prefix",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectServiceProjectTypeConnection",
                      "kind": "LinkedField",
                      "name": "projects",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectServiceProjectTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectServiceProjectType",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "serviceCompanyId",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "programs(first:100000)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c755709407415ed913feaa58577c74ec';

module.exports = node;
