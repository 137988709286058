/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TimeTotals_project$ref: FragmentReference;
declare export opaque type TimeTotals_project$fragmentType: TimeTotals_project$ref;
export type TimeTotals_project = {|
  +id: string,
  +projectFirstDateYear: ?number,
  +projectFirstDateMonth: ?number,
  +projectFirstDateDay: ?number,
  +projectLastDateYear: ?number,
  +projectLastDateMonth: ?number,
  +projectLastDateDay: ?number,
  +financialSourceSettings: ?{|
    +plannedHours: ?string,
    +plannedRevenue: ?string,
    +plannedCost: ?string,
    +forecastHours: ?string,
    +forecastRevenue: ?string,
    +forecastCost: ?string,
    +actualHours: ?string,
    +actualRevenue: ?string,
    +actualCost: ?string,
  |},
  +$refType: TimeTotals_project$ref,
|};
export type TimeTotals_project$data = TimeTotals_project;
export type TimeTotals_project$key = {
  +$data?: TimeTotals_project$data,
  +$fragmentRefs: TimeTotals_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TimeTotals_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialSourceSettings",
      "kind": "LinkedField",
      "name": "financialSourceSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualCost",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = '511b0316f4201d7db636da12969e3363';

module.exports = node;
