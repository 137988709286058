import React from 'react';
import {getQueryRenderer} from '../reported_time_page_util';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../constants';
import Util from '../../../../forecast-app/shared/util/util';
import {CalculationLoader, Table} from 'web-components';
import {getNumberOfCustomFields} from '../reported_time_table_util';

export const getTaskRow = (
	data,
	currency,
	intl,
	groupIndex,
	tasksFinancialMap,
	searchQuery,
	groupings,
	enabledColumns,
	sortValue,
	hasNextPage,
	fetchMore,
	nextLevelProps,
	tableHeader
) => {
	const numberOfCustomFields = getNumberOfCustomFields(tableHeader);
	const getColumns = task => {
		const hasAccessToTask = task.hasAccessToTask;
		const phaseName = task.phase?.name;
		const formattedPrice =
			task.totalPrice !== null ? intl.formatNumber(task.totalPrice, {format: 'always_two_decimal'}) : null;
		const formattedCost =
			task.totalCost !== null ? intl.formatNumber(task.totalCost, {format: 'always_two_decimal'}) : null;
		return [
			<Table.Column>
				<span>{task.name}</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column>{phaseName}</Table.Column>,
			<Table.Column>{Util.convertMinutesToFullHour(task.totalMinutesRegistered, intl)}</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>
				<span>
					{hasAccessToTask ? (
						formattedPrice ? (
							Util.GetFormattedCurrencySymbol(currency, formattedPrice)
						) : (
							<CalculationLoader />
						)
					) : (
						'-'
					)}
				</span>
			</Table.Column>,
			<Table.Column>
				<span>
					{hasAccessToTask ? (
						formattedCost ? (
							Util.GetFormattedCurrencySymbol(currency, formattedCost)
						) : (
							<CalculationLoader />
						)
					) : (
						'-'
					)}
				</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			[...Array(numberOfCustomFields)].map(_ => <Table.Column />),
		];
	};

	return (
		<Table.Rows
			data={data}
			canExpand={true}
			autoload={hasNextPage && groupIndex === 1}
			loadMore={hasNextPage ? fetchMore : false}
			{...nextLevelProps}
		>
			{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
				const filterField =
					rowData.isNoTask === true ? GLOBAL_FILTER_FIELD.NON_TASK_TIME_REG : GLOBAL_FILTER_FIELD.TASK_TIME_REG;
				return (
					<>
						<Table.Row canExpandRow={rowData.canExpand} {...tableColumnsProps}>
							{getColumns(rowData)}
						</Table.Row>
						{expanded &&
							getQueryRenderer(
								tableHeader,
								groupings,
								searchQuery,
								groupIndex,
								enabledColumns,
								{field: filterField, operator: GLOBAL_FILTER_OPERATOR.IS, value: [rowData.id]},
								nextLevelProps,
								sortValue
							)}
					</>
				);
			}}
		</Table.Rows>
	);
};
