export const defaultTimeDisplayFormat = 'DD MMM';
export const defaultTimeDisplayFormats = {
    day: defaultTimeDisplayFormat,
    week: defaultTimeDisplayFormat,
    month: defaultTimeDisplayFormat,
    quarter: defaultTimeDisplayFormat,
    year: defaultTimeDisplayFormat,
    hour: defaultTimeDisplayFormat,
    minute: defaultTimeDisplayFormat,
    second: defaultTimeDisplayFormat,
    millisecond: defaultTimeDisplayFormat,
};
