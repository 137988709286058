/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type timesheetsTeam_viewer$ref = any;
export type timesheetsTeam_QueryVariables = {||};
export type timesheetsTeam_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +company: ?{|
      +id: string
    |},
    +$fragmentRefs: timesheetsTeam_viewer$ref,
  |}
|};
export type timesheetsTeam_Query = {|
  variables: timesheetsTeam_QueryVariables,
  response: timesheetsTeam_QueryResponse,
|};
*/


/*
query timesheetsTeam_Query {
  viewer {
    actualPersonId
    component(name: "team-timesheet")
    ...timesheetsTeam_viewer
    company {
      id
    }
    id
  }
}

fragment timesheetsTeam_viewer on Viewer {
  component(name: "team-timesheet")
  company {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "team-timesheet"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"team-timesheet\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "company",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "timesheetsTeam_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "timesheetsTeam_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "timesheetsTeam_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "timesheetsTeam_Query",
    "operationKind": "query",
    "text": "query timesheetsTeam_Query {\n  viewer {\n    actualPersonId\n    component(name: \"team-timesheet\")\n    ...timesheetsTeam_viewer\n    company {\n      id\n    }\n    id\n  }\n}\n\nfragment timesheetsTeam_viewer on Viewer {\n  component(name: \"team-timesheet\")\n  company {\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd3e964a07b155ca87786522caf807dac';

module.exports = node;
