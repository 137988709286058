import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import ChartJsLine from '../../chartjs/chart_js_line';
import {cloneDeep} from 'lodash';

class burnUpComponent extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}

	render() {
		if (this.props.viewer.insightComponentsData.burnUp) {
			const {formatMessage} = this.props.intl;
			const yAxesTitle = this.props.viewer.insightComponentsData.burnUp.useHours
				? formatMessage({id: 'common.hours'})
				: formatMessage({id: 'common.points'});
			const tooltipSuffix = this.props.viewer.insightComponentsData.burnUp.useHours
				? formatMessage({id: 'common.hours.short'})
				: formatMessage({id: 'common.points.short'});
			const data = {
				labels: [],
				datasets: [],
			};
			const idealVelocityArray = [];
			const scopeArray = [];
			const completedWorkArray = [];
			cloneDeep(this.props.viewer.insightComponentsData.burnUp.data)
				.sort((a, b) => {
					const a_moment_date = moment(a.dateString, 'YYYYMMDD');
					const b_moment_date = moment(b.dateString, 'YYYYMMDD');
					if (a_moment_date.isBefore(b_moment_date)) {
						return -1;
					} else if (a_moment_date.isAfter(b_moment_date)) {
						return 1;
					} else {
						return 0;
					}
				})
				.forEach((dataRecord, index) => {
					const momentDate = moment(dataRecord.dateString, 'YYYYMMDD');
					const date = momentDate.toDate();
					data.labels.push(date);
					completedWorkArray.push(dataRecord.velocity);
					scopeArray.push(dataRecord.scope);
					if (index === 0 || index === this.props.viewer.insightComponentsData.burnUp.data.length - 1) {
						//for first and last element of array we need to set values for ideal velocity
						if (index === 0) {
							idealVelocityArray.push(0);
						} else {
							idealVelocityArray.push(Math.max(...scopeArray));
						}
					} else {
						idealVelocityArray.push(null);
					}
				});
			data.datasets.push({
				label: formatMessage({id: 'insights.component.burnUp.scope'}),
				borderColor: '#28AEFB',
				fill: false,
				lineTension: 0,
				stack: 'Stack 0',
				data: scopeArray,
			});
			data.datasets.push({
				label: formatMessage({id: 'insights.component.burnUp.work_completed'}),
				borderColor: '#FF746C',
				fill: false,
				lineTension: 0,
				stack: 'Stack 1',
				data: completedWorkArray,
			});
			data.datasets.push({
				label: formatMessage({id: 'insights.component.burnUp.ideal_velocity'}),
				borderColor: '#F6C4FC',
				fill: false,
				lineTension: 0,
				stack: 'Stack 0',
				spanGaps: true,
				data: idealVelocityArray,
			});
			return (
				<ChartJsLine
					data={data}
					height={360}
					useTimeLineChart={true}
					yAxesTitle={yAxesTitle}
					tooltipSuffix={tooltipSuffix}
				/>
			);
		} else {
			return null;
		}
	}
}

const burnUpComponentQuery = graphql`
	query burnUpComponent_Query($shareKey: String, $projectId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_burn_up")
			...burnUpComponent_viewer
				@arguments(shareKey: $shareKey, projectId: $projectId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {burnUpComponentQuery};

export default injectIntl(
	createFragmentContainer(burnUpComponent, {
		viewer: graphql`
			fragment burnUpComponent_viewer on Viewer
			@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
				insightComponentsData(shareKey: $shareKey) {
					burnUp(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
						useHours
						data {
							dateString
							scope
							velocity
						}
					}
				}
			}
		`,
	})
);
