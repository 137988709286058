import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
	HoursInputStyle as InputStyle,
	HoursInputContainerStyle as InputContainerStyle,
} from '../../forecast-app/shared/components/inputs/hours-input/hours_input_styled';
import ForecastTooltip from '../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {convertToPercentageFormat, noChange} from './PercentageInputLogic';

export const PercentageInput = ({
	id,
	intl,
	value,
	placeholder,
	mutationValidChange,
	mutationChangeOnly,
	mutation, // function which is called when a valid change has happened
	onFocus,
	onClick,
	onBlur,
	disabled,
	nonWorkingDay,
	innerRef,
	focusOnMount,
	forceUpdate,
	infoText,
	hasError,
	customClassName,
	invalidInput,
	noMaxWidth,
	modalView,
	taskModalStyle,
	showAsterisk,
	phaseHeaderStyle,
	withInputExtension,
}) => {
	const inputElem = useRef(null);
	const formattedValue = useMemo(() => convertToPercentageFormat(value, intl), [value]);
	const [currentInputValue, setCurrentInputValue] = useState(formattedValue.full);
	const [error, setError] = useState(formattedValue.error);
	const [isDirty, setIsDirty] = useState(false);
	const [dirtyWithError, setDirtyWithError] = useState(false);

	const setErrorIfDirty = () => {
		if (dirtyWithError) {
			setError(true);
		}
	};

	// Focus input on mount if prop is passed
	useEffect(() => {
		if (focusOnMount) {
			inputElem.current.focus();
		}
	}, []);

	// If explicitly requesting forceUpdate, set all state elements back to default.
	useEffect(() => {
		setCurrentInputValue(formattedValue.full);
		setError(formattedValue.error);
	}, [forceUpdate]);

	useEffect(() => {
		setDirtyWithError(true);
		const formattedValue = convertToPercentageFormat(value, intl);
		setCurrentInputValue(formattedValue.full);
		if (formattedValue.error !== error) {
			setError(formattedValue.error);
		}
	}, [value, showAsterisk, forceUpdate]);

	const handleOnChange = e => {
		setIsDirty(true);
		setCurrentInputValue(e.target.value);
	};

	const handleValidate = e => {
		const inputValue = e.target.value;
		if (onBlur) {
			onBlur(e);
		}

		const validValue = convertToPercentageFormat(inputValue, intl);

		if (validValue.error) {
			setErrorIfDirty();
		} else {
			setError(false);
			setCurrentInputValue(validValue.full);
		}

		if (mutation) {
			// Check if mutation should only be called on valid change
			if (mutationValidChange) {
				if (!validValue.error && !noChange(inputValue, value, intl)) {
					mutation(validValue.full, e, isDirty);
					setIsDirty(false);
				}
			} else if (mutationChangeOnly) {
				if (!noChange(inputValue, value, intl)) {
					mutation(validValue.full, e, isDirty);
					setIsDirty(false);
				}
			} else {
				// else we just call the mutation;
				mutation(validValue.full, e, isDirty);
				setIsDirty(false);
			}
		}
	};

	const handleKeyPressInput = e => {
		if (e.key === 'Enter') {
			e.target.blur();
		}
	};

	const handleOnClick = e => {
		if (onClick) {
			onClick(e.target);
		}
	};

	const handleOnFocus = e => {
		if (onFocus) {
			onFocus(e.target);
		} else {
			e.target.select();
		}
	};

	const inputState = disabled ? '' : error || hasError ? ' error' : nonWorkingDay ? ' non-working-day' : '';
	return (
		<InputContainerStyle
			className={customClassName}
			modalView={modalView}
			taskModalStyle={taskModalStyle}
			noMaxWidth={noMaxWidth}
			phaseHeaderStyle={phaseHeaderStyle}
		>
			<ForecastTooltip content={infoText} maxWidth={250}>
				<InputStyle
					id={id}
					ref={innerRef ? innerRef : inputElem}
					className={inputState || ''}
					type={'text'}
					placeholder={placeholder}
					value={currentInputValue}
					onFocus={e => handleOnFocus(e)}
					onClick={e => handleOnClick(e)}
					onChange={e => handleOnChange(e)}
					onBlur={e => handleValidate(e)}
					onKeyDown={e => handleKeyPressInput(e)}
					disabled={disabled}
					modalView={modalView}
					taskModalStyle={taskModalStyle}
					phaseHeaderStyle={phaseHeaderStyle}
					invalid={invalidInput}
					withInputExtension={withInputExtension}
				/>
			</ForecastTooltip>
		</InputContainerStyle>
	);
};
