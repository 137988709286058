import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
	BUTTON_COLOR,
	BUTTON_STYLE,
	DATE_PICKER_STYLE,
	ELEMENT_TYPE,
	FILTER_SECTION,
	FILTER_TYPE,
	GLOBAL_FILTER_FIELD,
	GLOBAL_FILTER_OPERATOR,
} from '../../../constants';
import {createFragmentContainer, graphql} from 'react-relay';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {getSearchQueryFiltersValue, getSecondDropdownOptionsByGroupings} from './reported_time_page_util';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {HeaderContainer} from './styling/reported_time_page_styled';
import Util from '../../../forecast-app/shared/util/util';
import ReportedTimeTable, {reportedTimeTableQuery} from './reported_time_table';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/FilterWrapper';
import * as tracking from '../../../tracking';
import {useGenericReportContext} from '../../../forecast-app/reports-tab/saved-report/GenericReportContext';
import {getFiltersAlphabetically} from '../../../forecast-app/shared/components/filters/filter_util';
import {filterObjectEyeOptions, theEyeOptionsFiltered, theEyeOptionsNoGrouping} from './reported_time_page_the_eye_util';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {noGroupingFirstDropdownOptions} from './reported_time_table_util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';

const reportedTimePage = ({intl, viewer, history}) => {
	//state variables
	const genericTable = useRef(null);
	const {
		reportName,
		getHasOwnerPermission,
		handleSetReportName,
		startDate,
		handleSetStartDate,
		endDate,
		handleSetEndDate,
		theEyeOptionsChecked,
		filterValue,
		firstDropdownValue,
		handleSetFirstDropdownValue,
		secondDropdownValue,
		handleSetSecondDropdownValue,
		handleSetFilterValue,
		getBottomReportHeader,
	} = useGenericReportContext();

	const hasOwnerPermission = getHasOwnerPermission(viewer.actualPersonId);
	const firstDropdownOptions = noGroupingFirstDropdownOptions(intl);
	const [secondDropdownOptions, setSecondDropdownOptions] = useState(
		getSecondDropdownOptionsByGroupings(firstDropdownValue, intl)
	);

	const defaultSearchQueryFilters = [
		{
			field: GLOBAL_FILTER_FIELD.WITH_TIME_REG,
			operator: GLOBAL_FILTER_OPERATOR.IS,
			value: true.toString(),
		},
		{
			field: GLOBAL_FILTER_FIELD.START_DATE_TIME_REG,
			operator: GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL,
			value: [startDate.format('YYYY-MM-DD')],
		},
		{
			field: GLOBAL_FILTER_FIELD.END_DATE_TIME_REG,
			operator: GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL,
			value: [endDate.format('YYYY-MM-DD')],
		},
		{
			field: GLOBAL_FILTER_FIELD.TASK_APPROVED,
			operator: GLOBAL_FILTER_OPERATOR.IS,
			value: true.toString(),
		},
	];
	const initialSearchQueryFilters = getSearchQueryFiltersValue(defaultSearchQueryFilters, filterValue);
	const [searchQueryFilters, setSearchQueryFilters] = useState(initialSearchQueryFilters);

	const customFieldDefinitions = viewer.company.customFieldDefinitions.edges
		.map(e => e.node)
		.filter(n => n.entityType === 'TIME_REG');

	const enabledColumns = filterObjectEyeOptions(theEyeOptionsChecked);

	// Authorization. Same restrictions as project-budget
	useEffect(() => {
		if (!Util.AuthorizeViewerAccess('time-report')) {
			history.push('/not-authorized');
			Util.localStorageSetItem('project-section-last-viewed', 'workflow');
		} else {
			tracking.trackPage('Time Registrations Report');
		}
	}, []);
	const groupings = [];

	let searchQuery = useMemo(() => {
		return {
			filters: searchQueryFilters,
		};
	}, searchQueryFilters);

	if (firstDropdownValue !== null) {
		groupings.push(firstDropdownValue);
		if (secondDropdownValue !== null) {
			groupings.push(secondDropdownValue);
		}
	}
	groupings.push('timeReg');

	const handleFirstDropdownChange = option => {
		handleSetFirstDropdownValue(option.value);
		handleSetSecondDropdownValue(null);
		setSecondDropdownOptions(getSecondDropdownOptionsByGroupings(option.value, intl));
	};

	const handleSecondDropdownChange = option => {
		handleSetSecondDropdownValue(option.value);
	};

	const handleDateRangeChange = (startDate, endDate) => {
		const updatedSearchQueryFilters = searchQueryFilters.map(filter => {
			const updatedFilterObject = {};
			if (filter.field === GLOBAL_FILTER_FIELD.START_DATE_TIME_REG) {
				updatedFilterObject.field = GLOBAL_FILTER_FIELD.START_DATE_TIME_REG;
				updatedFilterObject.operator = GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL;
				updatedFilterObject.value = [startDate.format('YYYY-MM-DD')];
			} else if (filter.field === GLOBAL_FILTER_FIELD.END_DATE_TIME_REG) {
				updatedFilterObject.field = GLOBAL_FILTER_FIELD.END_DATE_TIME_REG;
				updatedFilterObject.operator = GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL;
				updatedFilterObject.value = [endDate.format('YYYY-MM-DD')];
			} else {
				updatedFilterObject.field = filter.field;
				updatedFilterObject.operator = filter.operator;
				updatedFilterObject.value = filter.value;
			}
			return updatedFilterObject;
		});

		handleSetStartDate(startDate);
		handleSetEndDate(endDate);
		setSearchQueryFilters(updatedSearchQueryFilters);
	};

	const onFilterChange = filters => {
		const updatedFilters = getSearchQueryFiltersValue(defaultSearchQueryFilters, filters);

		handleSetFilterValue(filters);
		setSearchQueryFilters(updatedFilters);
	};

	const handleReportNameChange = newName => {
		handleSetReportName(newName);
	};

	const handleCsvDownload = () => {
		showModal({
			type: MODAL_TYPE.DOWNLOADING_QUERY_DATA,
			searchQuery: searchQuery,
			viewer: viewer,
			enabledColumns: enabledColumns,
			legacyDownload: true,
		});
	};

	const getTopHeader = () => {
		let content = [];
		const defaultTitle =
			intl.formatMessage({id: 'reported_time_table.time_registered'}) +
			', ' +
			intl.formatMessage({id: 'common.untitled'});

		if (hasOwnerPermission) {
			content.push({
				type: TopHeaderBar.TYPE.INPUT_REPORT_TITLE,
				defaultTitle,
				value: reportName,
				onChange: handleReportNameChange,
				placeholder: intl.formatMessage({id: 'time_reg_report.report_name'}),
				userpilot: 'timereg-rename-input',
			});
		}

		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={hasOwnerPermission ? null : reportName} content={content} />
			</TopHeaderBarWrapper>
		);
	};

	const getBottomHeader = () => {
		let leftContent = [];
		let rightContent = [];

		leftContent.push({
			type: ELEMENT_TYPE.DATE_PICKER,
			userpilot: 'timereg-date-picker',
			isNewDateRange: true,
			startDate: startDate,
			endDate: endDate,
			handleDateRangeChange: (startDate, endDate) => handleDateRangeChange(startDate, endDate),
			colorInherited: true,
			compactShowYear: true,
			datePickerStyle: DATE_PICKER_STYLE.SLIM,
			calendarOffsetX: 0,
			clearable: false,
		});

		rightContent.push({
			type: ELEMENT_TYPE.CSV,
			callback: handleCsvDownload,
		});

		rightContent.push({
			sameTypeOrder: 1,
			type: ELEMENT_TYPE.DROPDOWN,
			userpilot: 'timereg-first-grouping',
			dropdownOptions: firstDropdownOptions,
			value: firstDropdownValue,
			callback: option => {
				handleFirstDropdownChange(option);
			},
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
		});

		if (secondDropdownOptions !== null) {
			rightContent.push({
				sameTypeOrder: 2,
				type: ELEMENT_TYPE.DROPDOWN,
				dropdownOptions: secondDropdownOptions,
				value: secondDropdownValue,
				callback: option => {
					handleSecondDropdownChange(option);
				},
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		const eyeProps = {
			eyeOptions: theEyeOptionsFiltered(theEyeOptionsNoGrouping(customFieldDefinitions)),
		};

		const additionalProps = {eyeProps: eyeProps};

		const filteredHeaderElements = getBottomReportHeader(additionalProps);

		rightContent.push(...filteredHeaderElements);

		const projectFilters = [],
			peopleFilters = [],
			taskFilters = [];
		const timeRegFilters = [
			FILTER_TYPE.BILLABLE,
			FILTER_TYPE.CLIENT,
			FILTER_TYPE.DEPARTMENT,
			FILTER_TYPE.PERSON,
			FILTER_TYPE.PROJECT,
			FILTER_TYPE.ROLE,
			FILTER_TYPE.TEAM,
			FILTER_TYPE.INTERNAL_TIME,
			FILTER_TYPE.TIME_OFF,
			FILTER_TYPE.LABEL_TIME_REG,
			FILTER_TYPE.PROJECT_OWNER_TIME_REG_REPORT,
			FILTER_TYPE.PROJECT_TYPE,
			FILTER_TYPE.INVOICED,
		];

		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			operatorOptions: {
				allowExclude: hasFeatureFlag('unified_filters_reports'),
				allowRequireAll: hasFeatureFlag('unified_filters_reports'),
			},
			defaultSection: FILTER_SECTIONS.TIMEREGS,
			projectFilters,
			peopleFilters,
			timeRegFilters: getFiltersAlphabetically(timeRegFilters, intl.formatMessage),
			primaryFilters: {
				[FILTER_SECTIONS.TIMEREGS]: [
					FILTER_TYPE.PROJECT,
					FILTER_TYPE.PERSON,
					FILTER_TYPE.CLIENT,
					FILTER_TYPE.BILLABLE,
					FILTER_TYPE.INTERNAL_TIME,
					FILTER_TYPE.TIME_OFF,
				],
			},
			taskFilters,
			viewer: viewer,
			filterSection: FILTER_SECTION.REPORTED_TIME_TABLE,
			appliedFiltersName: `reported-time-filter-v4`,
			noMenu: false,
			useSavedReport: true,
			preAppliedFilters: filterValue,
			onFiltersChange: onFilterChange.bind(this),
			userpilot: 'timereg-filter-button',
		});

		return buildHeaderBar(leftContent, rightContent);
	};

	useTrackPage('Time Registration Report');

	return (
		// <PageWrapper>
		<>
			<HeaderContainer ref={genericTable}>{getTopHeader()}</HeaderContainer>
			<HeaderContainer ref={genericTable}>{getBottomHeader()}</HeaderContainer>

			<ForecastQueryRenderer
				key="query-render-reportedTimeTable"
				query={reportedTimeTableQuery}
				// This is basically initialVariables
				variables={{
					searchQuery: searchQuery,
				}}
				render={relayProps => {
					return (
						<ReportedTimeTable
							{...relayProps}
							relayProps={relayProps}
							enabledColumns={enabledColumns}
							groupings={groupings}
							searchQuery={searchQuery}
							customFieldDefinitions={customFieldDefinitions}
						/>
					);
				}}
			/>
		</>
		// </PageWrapper>
	);
};

const reportedTimePageQuery = graphql`
	query reportedTimePageQuery_Query($savedReportId: String) {
		viewer {
			actualPersonId
			component(name: "reported_time_page")
			...reportedTimePage_viewer @arguments(savedReportId: $savedReportId)
		}
	}
`;

export {reportedTimePageQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(reportedTimePage, {
			viewer: graphql`
				fragment reportedTimePage_viewer on Viewer @argumentDefinitions(savedReportId: {type: "String"}) {
					actualPersonId
					id
					email
					company {
						customFieldDefinitions(first: 1000) @connection(key: "Company_customFieldDefinitions") {
							edges {
								node {
									id
									key
									entityType
									displayName
								}
							}
						}
					}
					availableFeatureFlags {
						key
					}
					filters(first: 10000, filterSection: REPORTED_TIME_TABLE) @connection(key: "Viewer_filters", filters: []) {
						edges {
							node {
								id
								name
								section
								value
								updatedAt
							}
						}
					}
				}
			`,
		})
	)
);
