import React from 'react';
import {useIntl} from 'react-intl';

import Util from '../../../../../forecast-app/shared/util/util';
import Person from '../../../../../forecast-app/shared/components/person/person';
import Dropdown from '../../../../../forecast-app/shared/components/dropdowns/dropdown';
import UpdateExpenseItemMutation from '../../../../../mutations/update_expense_item_mutation';
import {MODAL_TYPE, showModal} from '../../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import ActionsMenu from '../../../../../forecast-app/shared/components/action-menu/actions_menu';
import {LockIcon} from 'web-components';
import {CSS_CONSTANTS} from '../../../../../css_variables';
import TooltipContainer from '../../../../../forecast-app/shared/components/tooltips/tooltip_container';
import {BUDGET_TYPE} from '../../../../../constants';
import {createToast} from '../../../../../forecast-app/shared/components/toasts/another-toast/toaster';
import DeleteExpenseItemMutation from '../../../../../mutations/delete_expense_item_mutation';
import DuplicateExpenseItemMutation from '../../../../../mutations/duplicate_expense_item_mutation';
import {createFragmentContainer, graphql} from 'react-relay';
import {isDateInLockedPeriod} from '../util/PeriodLocks';
import {profilePicSrc} from '../../../../../directApi';

export const ExpenseRow = ({
	expense,
	plannedEditable,
	showApproved,
	showPartOfFixedPrice,
	currencySymbol,
	retry,
	project,
	cyPrefix,
	company,
	handleNewExpense,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const expenseInvoiced = expense.invoiced;
	const expenseCategoryDisabled = company.expenseCategories.edges.some(
		expenseCategory => expenseCategory.node.disabled && expenseCategory.node.id === expense.category.id
	);
	const expenseDate = Util.CreateMomentDate(expense.expenseYear, expense.expenseMonth, expense.expenseDay);
	const lockedPeriods = project.fixedPriceLocks.edges.map(period => period.node).filter(period => period.locked);
	const isExpenseInLockedPeriod = isDateInLockedPeriod(expenseDate, lockedPeriods);
	const expenseLocked = expenseInvoiced || isExpenseInLockedPeriod;

	const handleApprovedChanged = option => {
		const onSuccess = () => {
			if (handleNewExpense) {
				handleNewExpense({approved: option.value});
			}
		};
		Util.CommitMutation(
			UpdateExpenseItemMutation,
			{
				id: expense.id,
				approved: option.value,
			},
			onSuccess
		);
	};

	const handlePlannedChanged = option => {
		const mutationObject = {};
		if (
			(project.budgetType === BUDGET_TYPE.FIXED_PRICE || project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2) &&
			!option.value
		) {
			mutationObject.billable = false;
		}
		mutationObject.id = expense.id;
		mutationObject.planned = option.value;
		Util.CommitMutation(UpdateExpenseItemMutation, mutationObject);
	};

	const openExpenseModal = expenseLocked => {
		showModal({
			type: MODAL_TYPE.EXPENSE_ITEM,
			expenseItemId: expense.id,
			projectId: project.id,
			onSave: retry,
			handleNewExpense: handleNewExpense,
			locked: expenseLocked,
		});
	};

	const duplicateExpense = () => {
		const onSuccess = () => {
			if (handleNewExpense) {
				handleNewExpense({approved: false});
			}
			createToast({duration: 5000, message: intl.formatMessage({id: 'expense_item.has_been_duplicated'})});
		};
		Util.CommitMutation(
			DuplicateExpenseItemMutation,
			{
				expenseItemId: expense.id,
				projectId: project.id,
				companyId: company.id,
			},
			onSuccess
		);
	};

	const deleteExpense = () => {
		const onSuccess = () => {
			createToast({duration: 5000, message: formatMessage({id: 'expense_item.has_been_deleted'})});
		};
		Util.CommitMutation(
			DeleteExpenseItemMutation,
			{
				id: expense.id,
				projectId: project.id,
				companyProjectId: project.companyProjectId,
			},
			onSuccess
		);
	};

	return (
		<>
			<td onClick={() => openExpenseModal(expenseLocked)} className={'growable clickable'}>
				<div className="value-wrapper">
					<div className="element" data-cy={`${cyPrefix}-name`}>
						{expense.name}
					</div>
					{expenseInvoiced && (
						<TooltipContainer tooltipDuration={1000 * 60} infoText={formatMessage({id: 'expenses.invoiced'})}>
							<LockIcon size={LockIcon.SIZE.STANDARD} color={CSS_CONSTANTS.v2_text_gray} />
						</TooltipContainer>
					)}
				</div>
			</td>
			<td
				onClick={() => openExpenseModal(expenseLocked)}
				className={'date-column clickable'}
				data-cy={`${cyPrefix}-date`}
			>
				{expenseDate ? expenseDate.format('D. MMM') : null}
			</td>
			<td onClick={() => openExpenseModal(expenseLocked)} className={'clickable'}>
				{expense.person ? (
					<Person
						name={expense.person.fullName}
						cy={`${cyPrefix}-person-name`}
						imageSrc={profilePicSrc(expense.person.profilePictureId)}
						showRole={false}
						showName={true}
					/>
				) : null}
			</td>
			<td onClick={() => openExpenseModal(expenseLocked)} className={'clickable'}>
				{expense.phase ? expense.phase.name : ''}
			</td>
			<td onClick={() => openExpenseModal(expenseLocked)} className={'clickable'}>
				{expense.category.name}
			</td>
			<td onClick={() => openExpenseModal(expenseLocked)} className={'clickable'} data-cy={`${cyPrefix}-quantity`}>
				{expense.quantity}
			</td>
			<td onClick={() => openExpenseModal(expenseLocked)} className={'clickable'} data-cy={`${cyPrefix}-unit-cost`}>
				{Util.getFormattedNumberWithCurrency(currencySymbol, expense.cost, intl)}
			</td>
			<td onClick={() => openExpenseModal(expenseLocked)} className={'clickable'} data-cy={`${cyPrefix}-total-cost`}>
				{Util.getFormattedNumberWithCurrency(currencySymbol, expense.cost * expense.quantity, intl)}
			</td>
			<td onClick={() => openExpenseModal(expenseLocked)} className={'clickable'} data-cy={`${cyPrefix}-markup`}>
				{expense.billable
					? Util.getFormattedPercentage(expense.cost !== 0 ? expense.price / expense.cost - 1 : 0, intl, 0)
					: null}
			</td>
			<td onClick={() => openExpenseModal(expenseLocked)} className={'clickable'} data-cy={`${cyPrefix}-unit-price`}>
				{expense.billable ? Util.getFormattedNumberWithCurrency(currencySymbol, expense.price, intl) : null}
			</td>
			<td onClick={() => openExpenseModal(expenseLocked)} className={'clickable'} data-cy={`${cyPrefix}-total-price`}>
				{expense.billable
					? Util.getFormattedNumberWithCurrency(currencySymbol, expense.price * expense.quantity, intl)
					: null}
			</td>
			<td>
				{plannedEditable ? (
					<Dropdown
						customClasses={'yes-no-dropdown'}
						focusOnClick={true}
						optionClickEvent={true}
						onChange={handlePlannedChanged}
						options={[
							{
								label: formatMessage({id: 'common.yes'}),
								value: true,
							},
							{
								label: formatMessage({id: 'common.no'}),
								value: false,
							},
						]}
						disabled={expenseLocked}
						value={expense.planned}
						hideLabel={true}
						restrictWidth={true}
						customHeight={30}
						inputCy={`${cyPrefix}-planned-dropdown`}
					/>
				) : expense.planned ? (
					formatMessage({id: 'common.yes'})
				) : (
					formatMessage({id: 'common.no'})
				)}
			</td>
			{showApproved && (
				<td>
					<Dropdown
						customClasses={'yes-no-dropdown'}
						focusOnClick={true}
						optionClickEvent={true}
						onChange={handleApprovedChanged}
						options={[
							{
								label: formatMessage({id: 'common.yes'}),
								value: true,
							},
							{
								label: formatMessage({id: 'common.no'}),
								value: false,
							},
						]}
						disabled={expenseLocked}
						value={expense.approved}
						hideLabel={true}
						restrictWidth={true}
						customHeight={30}
						inputCy={`${cyPrefix}-approved-dropdown`}
					/>
				</td>
			)}
			{showPartOfFixedPrice && (
				<td>{expense.partOfFixedPrice ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'})}</td>
			)}
			<td>
				<ActionsMenu
					whiteInner={true}
					cy={'expense-row'}
					options={[
						{
							text: formatMessage({
								id: expenseInvoiced || isExpenseInLockedPeriod ? 'common.view' : 'common.edit',
							}),
							onClick: () => openExpenseModal(expenseInvoiced || isExpenseInLockedPeriod),
							cy: formatMessage({id: expenseInvoiced || isExpenseInLockedPeriod ? 'common.view' : 'common.edit'}),
						},
						{
							text: formatMessage({id: 'common.duplicate'}),
							locked: expenseCategoryDisabled || isExpenseInLockedPeriod,
							onClick: duplicateExpense,
							disabledTitle: isExpenseInLockedPeriod
								? formatMessage({id: 'expenses.locked_period'})
								: formatMessage({id: 'expenses.category_disabled'}),
							cy: formatMessage({id: 'common.duplicate'}),
						},
						{
							text: formatMessage({id: 'common.delete'}),
							onClick: deleteExpense,
							locked: expenseInvoiced || isExpenseInLockedPeriod,
							disabledTitle: expenseInvoiced
								? formatMessage({id: 'expenses.invoiced'})
								: formatMessage({id: 'expenses.locked_period'}),
							cy: formatMessage({id: 'common.delete'}),
						},
					]}
				/>
			</td>
		</>
	);
};

export default createFragmentContainer(ExpenseRow, {
	project: graphql`
		fragment expenseRow_project on ProjectType {
			id
			budgetType
			companyProjectId
		}
	`,
	company: graphql`
		fragment expenseRow_company on Company {
			id
			expenseCategories(first: 10000) {
				edges {
					node {
						id
						name
						disabled
					}
				}
			}
		}
	`,
});
