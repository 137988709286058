import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Warning from '../warning';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../constants';

import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';
import {showModal, MODAL_TYPE} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../../forecast-app/shared/util/util';
import DirectApi from '../../directApi';
import {Button} from 'web-components';

import SetADOProjectSettingsMutation from '../../mutations/SetADOProjectSettingsMutation';

const AzureDevOpsIntegrationSection = ({project, companyId}) => {
	const intl = useIntl();

	const [adoProjects, setADOProjects] = useState(null);
	const [areas, setAreas] = useState(null);

	useEffect(() => {
		DirectApi.Fetch('vsts_projects').then(projects => {
			setADOProjects(projects);

			if (project.vstsProject) {
				DirectApi.Fetch(
					`vsts_areas_by_account?account=${project.vstsAccount}&vsts_project_id=${project.vstsProject}`
				).then(json => setAreas(json));
			}
		});
	}, []);

	const openLinkADOProjectModal = () => {
		showModal({
			type: MODAL_TYPE.LINK_ADO_PROJECT,
			project,
			companyId: Number.parseInt(atob(companyId).split(':')[1]),
		});
	};

	const unlinkADOProject = () => {
		const {formatMessage} = intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			className: 'default-warning-modal',
			content: <Warning messageId="settings.ado.unlink_project" />,
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.continue'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () =>
						Util.CommitMutation(SetADOProjectSettingsMutation, {
							projectId: project.id,
							vstsProject: null,
						}),
				},
			],
		});
	};

	const toggleADOTwoWaySync = () => {
		const {formatMessage} = intl;
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="common.delete-confirmation-title" />
					<div className="warning-part-2">
						<FormattedMessage
							id={
								project.vstsTwoWaySync
									? 'settings.ado_disable_two_way_sync'
									: 'settings.ado_enable_two_way_sync'
							}
						/>
					</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.ok'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => {
						Util.CommitMutation(SetADOProjectSettingsMutation, {
							projectId: project.id,
							vstsTwoWaySync: !project.vstsTwoWaySync,
						});
					},
				},
			],
		});
	};

	const toggleADOUseNewSubtasks = () => {
		const {formatMessage} = intl;
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="common.delete-confirmation-title" />
					<div className="warning-part-2">
						<FormattedMessage
							id={
								project.vstsUseNewSubtasks
									? 'settings.ado_disable_use_new_subtasks'
									: 'settings.ado_enable_use_new_subtasks'
							}
						/>
					</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.ok'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					callback: () => {
						Util.CommitMutation(SetADOProjectSettingsMutation, {
							projectId: project.id,
							vstsUseNewSubtasks: !project.vstsUseNewSubtasks,
						});
					},
				},
			],
		});
	};

	const getADOProjectName = () => {
		if (adoProjects == null || adoProjects.length === 0) return project.vstsProject;

		const proj = adoProjects.find(p => p.id === project.vstsProject);
		return proj ? proj.name : project.vstsProject;
	};

	const getADOAreaName = () => {
		if (!areas || areas.length === 0) {
			return project.vstsArea;
		}
		const area = areas.find(a => a.value === project.vstsArea);
		return area ? (
			area.label
		) : (
			<span
				style={{color: 'red'}}
			>{`Area with id: ${project.vstsArea} not found! Please unlink project in Forecast.`}</span>
		);
	};

	const viewVSTSProject = () => {
		window.open(`https://${project.vstsAccount}.visualstudio.com/${project.vstsProject}`);
	};

	const content = project.vstsProject ? (
		<div className="ado-current-link-container">
			<span className="ado-current-link">
				<FormattedMessage id="settings.currently_linked_ado" />
			</span>
			<div className="ado-current-link-line">
				<span className="ado-current-link">
					<FormattedMessage id="settings.ado_account" />
				</span>
				<b>{project.vstsAccount}</b>
			</div>
			<div className="ado-current-link-line">
				<span className="ado-current-link">
					<FormattedMessage id="common.project" />:
				</span>
				<b>{getADOProjectName()}</b>
			</div>
			{project.vstsArea ? (
				<div className="ado-current-link-line">
					<span className="ado-current-link">
						<FormattedMessage id="settings.ado_area" />
					</span>
					<b>{getADOAreaName()}</b>
				</div>
			) : (
				''
			)}

			<div className="ado-current-link-line">
				<span>{intl.formatMessage({id: 'settings.ado_sync_bugs'})}:</span>
				<input disabled={true} type="checkbox" id="ado_sync_bugs" checked={project.vstsSyncBugs} />
			</div>
			<div className="ado-current-link-line">
				<span>{intl.formatMessage({id: 'settings.ado_use_epics'})}:</span>
				<input disabled={true} type="checkbox" id="ado_use_epics" checked={project.vstsUseEpics} />
			</div>
			<div className="ado-current-link-line">
				<span>{intl.formatMessage({id: 'settings.two_way_sync'})}:</span>
				<input
					disabled={false}
					type="checkbox"
					onClick={() => toggleADOTwoWaySync()}
					checked={project.vstsTwoWaySync}
				/>
			</div>
			<div className="ado-current-link-line">
				<span>{intl.formatMessage({id: 'settings.use_new_subtasks'})}:</span>
				<input
					disabled={false}
					type="checkbox"
					onClick={() => toggleADOUseNewSubtasks()}
					checked={project.vstsUseNewSubtasks}
				/>
			</div>
		</div>
	) : null;

	return (
		<div>
			{adoProjects === null ? <InlineLoader /> : content}
			<div className="ado-button-wrapper">
				{project.vstsProject ? (
					<>
						<Button onClick={() => viewVSTSProject()}>
							<FormattedMessage id="settings.view_ado_project" />
						</Button>
						<Button onClick={() => unlinkADOProject()} variant={Button.VARIANT.RED_OUTLINE}>
							<FormattedMessage id="common.unlink" />
						</Button>
					</>
				) : (
					<Button onClick={() => openLinkADOProjectModal()} variant={Button.VARIANT.PURPLE_OUTLINE}>
						{intl.formatMessage({id: 'common.link'})}
					</Button>
				)}
			</div>
		</div>
	);
};

export default AzureDevOpsIntegrationSection;
