/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
export type PERIOD_BUDGET_TYPE = "FIXED_HOURS" | "FIXED_PRICE" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Totals_project$ref: FragmentReference;
declare export opaque type Totals_project$fragmentType: Totals_project$ref;
export type Totals_project = {|
  +id: string,
  +budgetType: ?BUDGET_TYPE,
  +defaultPeriodBudgetType: ?PERIOD_BUDGET_TYPE,
  +projectFirstDateYear: ?number,
  +projectFirstDateMonth: ?number,
  +projectFirstDateDay: ?number,
  +projectLastDateYear: ?number,
  +projectLastDateMonth: ?number,
  +projectLastDateDay: ?number,
  +estimationUnit: ?string,
  +financialSourceSettings: ?{|
    +plannedHours: ?string,
    +plannedRevenue: ?string,
    +plannedCost: ?string,
    +forecastHours: ?string,
    +forecastRevenue: ?string,
    +forecastCost: ?string,
    +actualHours: ?string,
    +actualRevenue: ?string,
    +actualCost: ?string,
  |},
  +useFixedPriceForBaselineRevenue: ?boolean,
  +$refType: Totals_project$ref,
|};
export type Totals_project$data = Totals_project;
export type Totals_project$key = {
  +$data?: Totals_project$data,
  +$fragmentRefs: Totals_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Totals_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultPeriodBudgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectFirstDateDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectLastDateDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimationUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialSourceSettings",
      "kind": "LinkedField",
      "name": "financialSourceSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "plannedCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastCost",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualHours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualRevenue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "actualCost",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useFixedPriceForBaselineRevenue",
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = '5678afe52fabd0d2b8e69cb03af9186b';

module.exports = node;
