import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {DropdownWrapperStyled, RoleRow as RoleRowStyle, RoleRowItem} from './InitialPlan.styled';
import ActionsMenu from '../../forecast-app/shared/components/action-menu/actions_menu';
import {PercentageInputWrapper} from '../inputs/percentage_input_wrapper';
import {
	deleteBaselineExpense,
	editExpenseMarkup,
	editExpenseCost,
	editExpenseRevenue,
	changeExpenseCategory,
} from './InitialPlanLogic';
import {ValueCell} from './ValueCell';
import {CurrencyInputWrapper} from '../inputs/currency_input_wrapper';
import {Dropdown} from 'web-components';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
const ExpenseRow = ({
	intl,
	phaseBaselineExpense,
	projectId,
	currencySymbol,
	enabledColumnEntries,
	theEyeOptions,
	expenseCategoryOptions,
	expenseFinancials,
}) => {
	const expenseCost = phaseBaselineExpense.expenseCost;
	const expenseRevenue = phaseBaselineExpense.expenseRevenue;
	const expenseMarkup = phaseBaselineExpense.expenseMarkup;

	const deleteExpense = () => {
		deleteBaselineExpense(phaseBaselineExpense.id, projectId);
	};

	const handleEditRevenue = value => {
		if (phaseBaselineExpense.expenseRevenue === value || value < 0) return;
		editExpenseRevenue(phaseBaselineExpense.id, value || 0);
	};

	const handleEditCost = value => {
		if (value === null) {
			deleteExpense();
		} else {
			if (phaseBaselineExpense.expenseCost === value || value < 0) return;
			editExpenseCost(phaseBaselineExpense.id, value || 0);
		}
	};

	const handleEditMarkup = value => {
		if (phaseBaselineExpense.expenseMarkup === value) return;
		editExpenseMarkup(phaseBaselineExpense.id, value || 0);
	};

	const actionsMenuOptions = [
		{
			text: 'Delete',
			onClick: deleteExpense.bind(this),
		},
	];

	const getElemProps = value => {
		return theEyeOptions[value - 1];
	};

	const getContentForExpenseCol = column => {
		switch (column) {
			case 'value_of_service':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={expenseFinancials ? expenseFinancials.baselineTimeAndExpenses || 0 : 0}
						currencySymbol={currencySymbol}
					/>
				);
			case 'revenue':
				return (
					<CurrencyInputWrapper
						intl={intl}
						value={
							hasFeatureFlag('baseline_financial_service')
								? expenseFinancials
									? expenseFinancials.baselineRevenue || 0
									: 0
								: expenseRevenue || 0
						}
						callback={handleEditRevenue}
						currencySymbol={currencySymbol}
					/>
				);
			case 'cost':
				return (
					<CurrencyInputWrapper
						intl={intl}
						value={
							hasFeatureFlag('baseline_financial_service')
								? expenseFinancials
									? expenseFinancials.baselineCost || 0
									: 0
								: expenseCost || 0
						}
						callback={handleEditCost}
						currencySymbol={currencySymbol}
					/>
				);
			case 'markup':
				return (
					<PercentageInputWrapper
						intl={intl}
						value={expenseMarkup * 100}
						callback={value => handleEditMarkup(value)}
					/>
				);
			case 'profit':
				return (
					<ValueCell
						type={ValueCell.VALUE_TYPE.PRICE}
						value={
							hasFeatureFlag('baseline_financial_service')
								? expenseFinancials
									? expenseFinancials.baselineProfit || 0
									: 0
								: phaseBaselineExpense.expenseProfit || 0
						}
						currencySymbol={currencySymbol}
					/>
				);
			case 'margin':
				// Calculate margin
				let margin = hasFeatureFlag('baseline_financial_service')
					? expenseFinancials
						? expenseFinancials.baselineMargin
						: 0.0
					: phaseBaselineExpense.expenseProfit / expenseRevenue;

				// Ensure pretty format
				if (isNaN(margin) || !isFinite(margin)) {
					margin = 0;
				}
				return <ValueCell type={ValueCell.VALUE_TYPE.PERCENTAGE} value={margin} />;
			default:
				return null;
		}
	};

	const getElemForExpenseCol = (column, value, index) => {
		if (!value) return null;
		const elemProps = getElemProps(value);
		return (
			<RoleRowItem key={index} align={elemProps.align} width={elemProps.width}>
				{getContentForExpenseCol(column, value)}
			</RoleRowItem>
		);
	};

	const handleChangeExpenseCategory = expenseCategoryId => {
		changeExpenseCategory(phaseBaselineExpense.id, expenseCategoryId[0]);
	};

	const filteredExpenseCategories = expenseCategoryOptions
		.filter(expense => {
			return !expense.node.disabled || expense.node.id === phaseBaselineExpense.expenseCategory.id;
		})
		.map(expense => ({value: expense.node.id, label: expense.node.name}));
	return (
		<RoleRowStyle>
			<RoleRowItem width={220} growable>
				<DropdownWrapperStyled>
					<Dropdown
						name={intl.formatMessage({id: 'common.select_expense_category'})}
						selectedItems={[phaseBaselineExpense.expenseCategory.id]}
						onSelect={handleChangeExpenseCategory}
						usePortal
					>
						{filteredExpenseCategories.map(option => (
							<Dropdown.SingleText key={option.value} value={option.value} searchString={option.label}>
								{option.label}
							</Dropdown.SingleText>
						))}
					</Dropdown>
				</DropdownWrapperStyled>
			</RoleRowItem>
			{enabledColumnEntries.map(([col, val], index) => getElemForExpenseCol(col, val, index))}
			<RoleRowItem align="center" width={20}></RoleRowItem>
			<RoleRowItem align="center" width={20}>
				<ActionsMenu whiteInner options={actionsMenuOptions} />
			</RoleRowItem>
		</RoleRowStyle>
	);
};

export default createFragmentContainer(ExpenseRow, {
	phaseBaselineExpense: graphql`
		fragment ExpenseRow_phaseBaselineExpense on PhaseBaselineExpenseType {
			id
			phaseId
			expenseCategory {
				id
				name
			}
			expenseCost
			expenseMarkup
			expenseRevenue
			expenseProfit
		}
	`,
});
