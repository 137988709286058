import React from 'react';
import { TextMain, ViewLine } from './layout/PDFLayout';
const BaselinePDFItem = ({ item }) => {
    return (React.createElement(ViewLine, null,
        React.createElement(TextMain, { style: { width: '40%' } }, item.name),
        React.createElement(TextMain, { style: { textAlign: 'right', width: '20%' } }, item.hours),
        React.createElement(TextMain, { style: { textAlign: 'right', width: '20%' } }, item.hourlyRate),
        React.createElement(TextMain, { bold: true, style: { textAlign: 'right', width: '20%' } }, item.totalPrice)));
};
export default BaselinePDFItem;
