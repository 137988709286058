import {createFragmentContainer, graphql} from 'react-relay';
import {sortBreakdownPhases} from '../ProjectPortfolioReportUtils';
import {useIntl} from 'react-intl';

const PhaseLoader = ({relay, viewer, children, ...props}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const formatData = () => {
		const currency = viewer.company.currency;
		const phaseBreakdownData = viewer.project.phaseFinancialBreakdown.map(phaseFinancialBreakdown => {
			const data = {...phaseFinancialBreakdown, currency};
			if (!data.phase) {
				data.phase = {
					name: formatMessage({id: 'project_portfolio_report.deleted_phase'}),
				};
			}
			return data;
		});

		sortBreakdownPhases(phaseBreakdownData);
		return phaseBreakdownData;
	};
	return children({data: {rows: formatData()}, ...props});
};

export const phaseLoaderQuery = graphql`
	query PhaseLoader_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$projectId: ID!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_period_phase_loader")
			...PhaseLoader_viewer
				@arguments(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					projectId: $projectId
				)
		}
	}
`;

export default createFragmentContainer(PhaseLoader, {
	viewer: graphql`
		fragment PhaseLoader_viewer on Viewer
		@argumentDefinitions(
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
			projectId: {type: "ID!"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				budgetBaseCurrency
				phaseFinancialBreakdown(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					filterRowsWithNoValues: true
					ignoreBaselineWinChance: false
				) {
					breakdownType
					phase {
						id
						name
						startDay
						startMonth
						startYear
						deadlineDay
						deadlineMonth
						deadlineYear
						progress
					}
					financialNumbers {
						...ProjectPortfolioPeriodRow_financialNumbers
					}
				}
			}
		}
	`,
});
