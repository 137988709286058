import React, {useState} from 'react';
import moment from 'moment';
import {createRefetchContainer, graphql} from 'react-relay';

import {
	FlexRow,
	OutlineBox,
	PageWrapper,
	Section,
	SplitSection,
	TablesSection,
	TableToggleContainer,
} from './InvoicingPage.styled';

import * as tracking from '../../../tracking';
import UpdateProjectMutation from '../../../mutations/update_project_mutation.modern';
import Util from '../../../forecast-app/shared/util/util';
import * as OverviewCsvExport from './OverviewCsvExport';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import ContextMenu from '../../../forecast-app/shared/components/context-menus/context_menu';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import {useIntl} from 'react-intl';
import {getFilterFunctions} from '../../../forecast-app/shared/components/filters/filter_logic';
import {prepareProjectData} from './invoicing_data_prepare';
import {FixedPriceTable} from './components/FixedPriceTable';
import {getInitialOptions, handleChangedOptions} from './invoicing_eye_options';
import {CaretIcon} from 'web-components';
import {TimeMaterialTable} from './components/TimeMaterialTable';
import {RetainerTable} from './components/RetainerTable';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE, DATE_PICKER_STYLE, ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE} from '../../../constants';
import {getOrderedFilters} from '../../../forecast-app/shared/components/filters/filter_util';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/FilterWrapper';
import {hasSomePermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {trackComplexEvent, trackCSVExport, trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import PresetDateRangePicker, {
	PRESET_DATE_RANGE_PICKER_OPTIONS,
} from '../../../forecast-app/shared/components/date-picker/preset_date_range_picker';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';
import {updateProjectOnSuccess} from '../../../containers/project/project_settings/ProjectSettingsMutations';

export const TableToggle = ({children, expanded, onClick}) => (
	<TableToggleContainer onClick={onClick}>
		{children}
		<span style={{marginLeft: '4px'}}>
			<CaretIcon
				direction={expanded ? CaretIcon.DIRECTION.UP : CaretIcon.DIRECTION.DOWN}
				color="#535353"
				size={CaretIcon.SIZE.STANDARD}
			/>
		</span>
	</TableToggleContainer>
);

const OverviewPage = ({
	viewer,
	relay: {refetch},
	timeMaterialStartDate,
	timeMaterialEndDate,
	dateRangeOption,
	setDate,
	onInvoiceCreation,
}) => {
	const LOCALSTORAGE_FILTERS_KEY = 'invoicing-overview-filter';
	const LOCALSTORAGE_DATE_RANGE_KEY = 'invoicing_overview_date_range';
	const STANDARD_MARGIN = 24;
	const intl = useIntl();
	const [theEyeOptions, setTheEyeOptions] = useState(getInitialOptions('overview', null));
	const [filters, setFilters] = useState(
		localStorage.getItem(LOCALSTORAGE_FILTERS_KEY) ? JSON.parse(localStorage.getItem(LOCALSTORAGE_FILTERS_KEY)) : ''
	);
	const [filterFunctions, setFilterFunctions] = useState(getFilterFunctions(filters));
	const [searchFilterValue, setSearchFilterValue] = useState('');
	const [fixedPriceExpanded, setFixedPriceExpanded] = useState(true);
	const [timeMaterialExpanded, setTimeMaterialExpanded] = useState(true);
	const [retainerExpanded, setRetainerExpanded] = useState(true);
	const [contextMenuData, setContextMenuData] = useState(null);
	const [showDatePicker, setShowDatePicker] = useState(true);

	const presetDateRangeOptions = [
		PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME,
		PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_MONTH,
		PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_MONTH,
		PRESET_DATE_RANGE_PICKER_OPTIONS.NEXT_MONTH,
		PRESET_DATE_RANGE_PICKER_OPTIONS.PREVIOUS_YEAR,
		PRESET_DATE_RANGE_PICKER_OPTIONS.CURRENT_YEAR,
		PRESET_DATE_RANGE_PICKER_OPTIONS.YEAR_TO_DATE,
		PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE,
	];

	const filteredProjects = viewer.projects.edges
		.map(edge => edge.node)
		.filter(project => {
			return filterFunctions.projectFilter(project);
		})
		.sort((a, b) => Util.sortAlphabetically(a.name, b.name));

	const projectData = prepareProjectData(
		filteredProjects,
		viewer.company,
		viewer.company.currency,
		timeMaterialStartDate,
		timeMaterialEndDate,
		searchFilterValue,
		false
	);

	const onFilterChange = (filters, filterFunctions) => {
		setFilters(filters);
		setFilterFunctions(filterFunctions);
	};

	const getHeaderTitle = () => {
		const content = [];

		const onboardingFlows = [
			{
				id: 'invoicing-intro',
				title: 'Introduction to the page',
				description: null,
				contentId: '1681733526lOuv9195',
			},
			{
				id: 'invoicing-create',
				title: 'How to create a billable hours/fixed price invoice',
				description: null,
				contentId: '1681734397mFei7279',
			},
			{
				id: 'invoicing-manual-invoice-deposit',
				title: 'How to create a manual invoice or deposit',
				description: null,
				contentId: '1681735924nGmu0849',
			},
		];

		const pageTitle = 'Invoice Creation';

		const onboardingComponent = {
			id: 'onboarding-component',
			type: TopHeaderBar.TYPE.ONBOARDING,
			title: `Learn to use ${pageTitle}`,
			options: onboardingFlows,
			helpCenterLink: 'https://support.forecast.app/hc/en-us/categories/4418778811281-Financial-Management',
			subLink:
				'https://support.forecast.app/hc/en-us/articles/4838003063185-Creating-invoices-for-your-customers-Pro-and-Plus-only-',
		};

		content.push(onboardingComponent);

		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={pageTitle} content={content} />
			</TopHeaderBarWrapper>
		);
	};

	const getHeader = () => {
		let leftContent = [],
			rightContent = [];

		const handleEyeOptionsSelect = (_, __, ___, newOptions) => {
			setTheEyeOptions(handleChangedOptions(newOptions, 'overview'));
		};

		const handleCsvDownloadPress = () => {
			tracking.trackEvent('download invoice overview');
			trackCSVExport('Invoice Overview');
			const data = {
				projectData: projectData,
				theEyeOptions: theEyeOptions,
				timeMaterialDateRange: {
					startDate: timeMaterialStartDate,
					endDate: timeMaterialEndDate,
				},
			};
			OverviewCsvExport.exportAllTypes(data, intl);
		};

		const onNewInvoiceButtonPress = () => {
			tracking.trackEvent('Create new invoice modal');
			trackEvent('New Invoice Modal', 'Shown');
			showModal({
				type: MODAL_TYPE.CREATE_INVOICE_V2_STANDALONE,
				startDate: timeMaterialStartDate,
				endDate: timeMaterialEndDate,
				onInvoiceCreation: onInvoiceCreation,
				refetch,
				isProjectInvoicing: false,
			});
		};

		const onSearchChange = value => {
			setSearchFilterValue(value);
		};

		leftContent.push({
			type: ELEMENT_TYPE.THE_EYE,
			options: theEyeOptions,
			onSelect: handleEyeOptionsSelect,
			openRight: true,
			userpilot: 'eye-selector',
			cy: 'eye-selector',
		});

		leftContent.push({
			type: ELEMENT_TYPE.CSV,
			callback: handleCsvDownloadPress,
			userpilot: 'csv-button',
			cy: 'csv-button',
		});

		rightContent.push({
			type: ELEMENT_TYPE.SEARCH,
			placeholder: intl.formatMessage({id: 'invoicing.search_project_name'}),
			value: searchFilterValue || '',
			onChange: onSearchChange,
			userpilot: 'search-button',
			cy: 'search-button',
		});

		const projectFilters = [FILTER_TYPE.PROJECT_STAGE];
		const projectAdvancedFilters = [
			FILTER_TYPE.DEADLINE,
			FILTER_TYPE.CLIENT,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.PROJECT_STATUS,
			FILTER_TYPE.CONTACT,
		];

		if (
			CompanySetupUtil.hasFinance() &&
			hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE])
		) {
			projectAdvancedFilters.push(FILTER_TYPE.PROJECT_TYPE);
			projectAdvancedFilters.push(FILTER_TYPE.RATE_CARD);
		}

		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: FILTER_SECTIONS.PROJECTS,
			projectFilters: getOrderedFilters(projectFilters, projectAdvancedFilters, intl.formatMessage),
			viewer: viewer,
			filterSection: FILTER_SECTION.INVOICING_OVERVIEW,
			appliedFiltersName: 'invoicing-overview-filter',
			onFiltersChange: onFilterChange,
			userpilot: 'filter-button',
			cy: 'filter-button',
		});

		rightContent.push({
			type: ELEMENT_TYPE.BUTTON,
			text: intl.formatMessage({id: 'invoicing.new_invoice'}),
			callback: onNewInvoiceButtonPress,
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
			userpilot: 'new-invoice-button',
			dataCy: 'new-invoice-button',
		});

		return buildHeaderBar(leftContent, rightContent, {parentGroup: null});
	};

	const openCreateInvoiceModal = (clientId, projectId, projectSelected) => {
		showModal({
			type: MODAL_TYPE.CREATE_INVOICE_V2_STANDALONE,
			startDate: timeMaterialStartDate,
			endDate: timeMaterialEndDate,
			clientId,
			projectId: projectSelected ? projectId : null,
			refetch,
			onInvoiceCreation: onInvoiceCreation,
			isProjectInvoicing: false,
		});
	};

	const handleOpenContextMenu = (e, clientId, projectId) => {
		e.preventDefault();
		const newContextMenuData = {
			clientId,
			projectId,
		};
		const btn = document.elementFromPoint(e.pageX, e.pageY);
		if (btn != null && e.type !== 'contextmenu') {
			const btnBounds = btn.getBoundingClientRect();
			newContextMenuData.posX = btnBounds.right - btn.clientWidth - 75 - (110 || 0);
			newContextMenuData.posY = btnBounds.y + 25;
		} else {
			newContextMenuData.posX = e.pageX;
			//check if there is place for menu underneath cursor
			if (window.innerHeight - e.pageY < 250) {
				newContextMenuData.posY = e.pageY - 250;
			} else {
				newContextMenuData.posY = e.pageY;
			}
		}
		setContextMenuData(newContextMenuData);
	};

	const handleTableToggle = tableName => {
		switch (tableName) {
			case 'timeMaterial':
				return setTimeMaterialExpanded(!timeMaterialExpanded);
			case 'fixedPrice':
				return setFixedPriceExpanded(!fixedPriceExpanded);
			case 'retainer':
				return setRetainerExpanded(!retainerExpanded);
			default:
				return setTimeMaterialExpanded(!timeMaterialExpanded);
		}
	};

	const getContextMenuOptions = (clientId, projectId) => {
		const options = [];
		if (clientId) {
			options.push({
				text: intl.formatMessage({id: 'invoicing.new_invoice_client'}),
				onClick: () => {
					tracking.trackEvent('New invoice for this client');
					trackComplexEvent('New Invoice Modal', 'Shown', {location: 'For Client'});
					if (!clientId) {
						return showModal({
							type: MODAL_TYPE.SELECT_V2,
							title: intl.formatMessage({id: 'empty_state_project_client.select-placeholder'}),
							defaultCallback: ({value: selectedClientId}) => {
								Util.CommitMutation(
									UpdateProjectMutation,
									{
										project: viewer.projects.edges.find(project => project.node.id === projectId).node,
										clientId: selectedClientId,
									},
									() => {
										openCreateInvoiceModal(selectedClientId, projectId, false);
									}
								);
							},
							options: viewer.company.clients.edges
								.map(edge => edge.node)
								.map(client => ({
									value: client.id,
									label: client.name,
								}))
								.sort(function (a, b) {
									return a.label.localeCompare(b.label);
								}),
							label: intl.formatMessage({id: 'common.client'}),
							multi: false,
							initialValue: null,
						});
					} else {
						return openCreateInvoiceModal(clientId);
					}
				},
				cy: 'invoice-client',
			});
		}

		options.push({
			text: intl.formatMessage({id: 'invoicing.new_invoice_project'}),
			onClick: () => {
				tracking.trackEvent('New invoice for this project');
				trackComplexEvent('New Invoice Modal', 'Shown', {location: 'For Project'});
				if (!clientId) {
					return showModal({
						type: MODAL_TYPE.SELECT_V2,
						title: intl.formatMessage({id: 'empty_state_project_client.select-placeholder'}),
						defaultCallback: ({value: selectedClientId}) => {
							Util.CommitMutation(
								UpdateProjectMutation,
								{
									project: viewer.projects.edges.find(project => project.node.id === projectId).node,
									clientId: selectedClientId,
								},
								res => {
									updateProjectOnSuccess(res);
									openCreateInvoiceModal(selectedClientId, projectId, true);
								}
							);
						},
						options: viewer.company.clients.edges
							.map(edge => edge.node)
							.map(client => ({
								value: client.id,
								label: client.name,
							}))
							.sort(function (a, b) {
								return a.label.localeCompare(b.label);
							}),
						label: intl.formatMessage({id: 'common.client'}),
						multi: false,
						initialValue: null,
					});
				} else {
					return openCreateInvoiceModal(clientId, projectId, true);
				}
			},
			cy: 'invoice-project',
		});

		return options;
	};

	const handleCloseContextMenu = () => {
		setContextMenuData(null);
	};

	const refetchData = (selectedStartDate, selectedEndDate) => {
		refetch({
			startYear: selectedStartDate.year(),
			startMonth: selectedStartDate.month() + 1,
			startDay: selectedStartDate.date(),
			endYear: selectedEndDate.year(),
			endMonth: selectedEndDate.month() + 1,
			endDay: selectedEndDate.date(),
		});
	};

	const handleDateRangeChange = (selectedStartDate, selectedEndDate) => {
		setDate({
			startDate: selectedStartDate,
			endDate: selectedEndDate,
			option: PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE,
		});
		refetchData(selectedStartDate, selectedEndDate);

		Util.localStorageSetItem(
			LOCALSTORAGE_DATE_RANGE_KEY,
			JSON.stringify({
				startDate: moment(selectedStartDate).format(),
				endDate: moment(selectedEndDate).format(),
				option: PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE,
			})
		);
	};

	const handlePresetDateRangeChange = selectedDateRange => {
		if (selectedDateRange == null) {
			return;
		}

		trackEvent('Dropdown Date Range', 'Changed', {
			dropdownDateRangeType: selectedDateRange.option,
		});

		let selectedDateRangeModified = selectedDateRange;

		// If it's ALL TIME, set the dates to 50 years in the past and future, as we cannot pass
		// null.
		if (selectedDateRange.option === PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME) {
			const startDate = moment().subtract(50, 'year');
			const endDate = moment().add(50, 'year');
			selectedDateRangeModified = {option: selectedDateRange.option, startDate: startDate, endDate: endDate};
		}

		const newDate = {
			startDate: timeMaterialStartDate,
			endDate: timeMaterialEndDate,
			option: selectedDateRangeModified.option,
		};
		if (selectedDateRange.option !== PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE) {
			newDate.startDate = selectedDateRangeModified.startDate;
			newDate.endDate = selectedDateRangeModified.endDate;
		} else if (dateRangeOption === PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME) {
			newDate.startDate = moment().local().subtract(1, 'month').startOf('month');
			newDate.endDate = moment().local().subtract(1, 'month').endOf('month');
		}
		setDate(newDate);

		if (selectedDateRange && selectedDateRange.option !== PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME) {
			setShowDatePicker(true);
		} else {
			setShowDatePicker(false);
		}

		Util.localStorageSetItem(LOCALSTORAGE_DATE_RANGE_KEY, JSON.stringify(newDate));
	};

	useTrackPage('Invoicing Overview');

	return (
		<PageWrapper data-cy="invoice-creation-page">
			{getHeaderTitle()}
			{getHeader()}
			{!!contextMenuData && (
				<div style={{zIndex: 100}}>
					<ContextMenu
						options={getContextMenuOptions(contextMenuData.clientId, contextMenuData.projectId)}
						contextMenuPosition={{x: contextMenuData.posX, y: contextMenuData.posY}}
						closeContextMenu={handleCloseContextMenu}
						userpilot={'context-menu-wrapper'}
					/>
				</div>
			)}
			<CustomScrollDiv>
				<SplitSection marginRight={STANDARD_MARGIN} marginLeft={STANDARD_MARGIN} sticky marginBottom={16}>
					<TableToggle expanded={timeMaterialExpanded} onClick={() => handleTableToggle('timeMaterial')}>
						{intl.formatMessage({id: 'common.time_material'})}
					</TableToggle>
				</SplitSection>
				{timeMaterialExpanded && (
					<div
						style={{margin: '0 10px 20px 25px', display: 'flex', gap: '10px'}}
						data-userpilot={'tm-date-range-container'}
					>
						<PresetDateRangePicker
							handleDateRangeChange={handlePresetDateRangeChange}
							initialPresetDateRange={dateRangeOption}
							options={presetDateRangeOptions}
							userpilot={'invoicing-overview-single-value-dropdown'}
							presetDateRange={dateRangeOption}
						/>
						{showDatePicker && (
							<DatePicker
								isNewDateRange={true}
								startDate={timeMaterialStartDate}
								endDate={timeMaterialEndDate}
								handleDateRangeChange={handleDateRangeChange}
								colorInherited={true}
								compactShowYear={true}
								datePickerStyle={DATE_PICKER_STYLE.SLIM}
								calendarOffsetX={-200}
								clearable={false}
							/>
						)}
					</div>
				)}

				<TablesSection data-userpilot="overview-tables">
					{timeMaterialExpanded && (
						<Section>
							<FlexRow growable>
								<OutlineBox growable marginRight={24} marginLeft={24} marginBottom={24}>
									<TimeMaterialTable
										theEyeOptions={theEyeOptions}
										projectData={projectData.time_material}
										companyCurrency={viewer.company.currency}
										onContextMenuClick={handleOpenContextMenu}
										hasFirstRow
									/>
								</OutlineBox>
							</FlexRow>
						</Section>
					)}
					<SplitSection marginRight={STANDARD_MARGIN} marginLeft={STANDARD_MARGIN} marginBottom={16} sticky>
						<TableToggle expanded={fixedPriceExpanded} onClick={() => handleTableToggle('fixedPrice')}>
							{intl.formatMessage({id: 'common.fixed_price'})}
						</TableToggle>
					</SplitSection>
					{fixedPriceExpanded && (
						<Section>
							<FlexRow growable>
								<OutlineBox growable marginRight={24} marginLeft={24} marginBottom={24}>
									<FixedPriceTable
										theEyeOptions={theEyeOptions}
										projectData={projectData.fixed_price}
										companyCurrency={viewer.company.currency}
										onContextMenuClick={handleOpenContextMenu}
										hasFirstRow={!projectData.time_material.projects?.length > 0}
									/>
								</OutlineBox>
							</FlexRow>
						</Section>
					)}
					<>
						<SplitSection marginRight={STANDARD_MARGIN} marginLeft={STANDARD_MARGIN} sticky marginBottom={16}>
							<TableToggle expanded={retainerExpanded} onClick={() => handleTableToggle('retainer')}>
								{intl.formatMessage({id: 'common.retainer'})}
							</TableToggle>
						</SplitSection>
						{retainerExpanded && (
							<Section marginBottom={55}>
								<FlexRow growable>
									<OutlineBox growable marginRight={24} marginLeft={24} marginBottom={24}>
										<RetainerTable
											theEyeOptions={theEyeOptions}
											projectData={projectData.retainer}
											companyCurrency={viewer.company.currency}
											onContextMenuClick={handleOpenContextMenu}
											hasFirstRow={
												!(
													projectData.time_material.projects?.length > 0 &&
													projectData.fixed_price.projects?.length > 0
												)
											}
										/>
									</OutlineBox>
								</FlexRow>
							</Section>
						)}
					</>
				</TablesSection>
			</CustomScrollDiv>
		</PageWrapper>
	);
};

const overviewPageQuery = graphql`
	query OverviewPageQuery(
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
	) {
		viewer {
			actualPersonId
			component(name: "invoicing_overview_page")
			...OverviewPage_viewer
				@arguments(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export {overviewPageQuery};

export default createRefetchContainer(
	OverviewPage,
	{
		viewer: graphql`
			fragment OverviewPage_viewer on Viewer
			@argumentDefinitions(
				startYear: {type: "Int!"}
				startMonth: {type: "Int!"}
				startDay: {type: "Int!"}
				endYear: {type: "Int!"}
				endMonth: {type: "Int!"}
				endDay: {type: "Int!"}
			) {
				id
				filters(first: 1000000, filterSection: INVOICING_OVERVIEW) @connection(key: "Viewer_filters", filters: []) {
					edges {
						node {
							id
							name
							section
							value
						}
					}
				}
				availableFeatureFlags {
					key
				}
				company {
					modules {
						moduleType
					}
					currency
					exchangeRates {
						edges {
							node {
								rate
								currency
							}
						}
					}
					clients {
						edges {
							node {
								id
								name
							}
						}
					}
				}
				projects(excludeDoneOrHalted: true) {
					edges {
						node {
							id
							companyProjectId
							customProjectId
							name
							projectColor
							isInProjectGroup
							status
							projectEndYear
							projectEndMonth
							projectEndDay
							budget
							budgetType
							defaultPeriodBudgetType
							rateCard {
								id
								currency
								defaultRate
							}
							client {
								id
								name
							}
							projectLabels(first: 1000000) {
								edges {
									node {
										label {
											id
										}
									}
								}
							}
							currentProjectStatus {
								id
								color
							}
							invoiceTotals {
								createdDay
								createdMonth
								createdYear
								invoicedWithoutTax
								invoicedWithoutTaxBaseCurrency
								invoicedWithoutTaxProjectCurrency
							}
							unInvoicedTotal(
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
							) {
								unInvoicedMinutes
								unInvoicedPrice
							}
							expenseItems {
								edges {
									node {
										expenseYear
										expenseMonth
										expenseDay
										price
										billable
										partOfFixedPrice
										approved
										quantity
										invoiced
									}
								}
							}
							projectPersons(first: 100000, excludeClientUsers: true) {
								edges {
									node {
										id
										isContactPerson
										person {
											id
										}
									}
								}
							}
							...DeprecatedProjectIndicatorJS_project
						}
					}
				}
			}
		`,
	},
	graphql`
		query OverviewPageRefetchQuery(
			$startYear: Int!
			$startMonth: Int!
			$startDay: Int!
			$endYear: Int!
			$endMonth: Int!
			$endDay: Int!
		) {
			viewer {
				...OverviewPage_viewer
					@arguments(
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
					)
			}
		}
	`
);
