import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {findDOMNode} from 'react-dom';
import {oldColors, INITIALS_SIZE} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import PersonInitials from '../../forecast-app/shared/components/person/person_initials';
import {profilePicSrc} from '../../directApi';

class TimelineTaskItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showDone: true,
			showPercentage: true,
			isInitialized: false,
		};
	}

	componentDidMount() {
		//need to do this because items are not updated correctly when they are out of view and then user scrolls to them
		setInterval(this.updateTaskItemText.bind(this), 500);
	}

	UNSAFE_componentWillReceiveProps() {
		if (!this.state.isInitialized) {
			//Launch immediately at start so numbers show up as soon as you expand phase
			this.updateTaskItemText();
		}
	}

	updateTaskItemText() {
		const taskItemContainerBoundingRect = findDOMNode(this.taskItemContainer).getBoundingClientRect();
		const taskItemContainerWidth = Math.abs(taskItemContainerBoundingRect.left - taskItemContainerBoundingRect.right);
		if (taskItemContainerWidth === 0) return;
		let showDone = taskItemContainerWidth > 160;
		let showPercentage = taskItemContainerWidth > 80;
		this.setState({showDone, showPercentage, isInitialized: true});
	}

	showTaskModal() {
		if (this.props.showTaskModal) {
			this.props.showTaskModal(this.props.item.task.companyTaskId);
		}
	}

	render() {
		const item = this.props.item;
		let percent_complete = '0%';
		if (item.task.estimateForecast !== 0) {
			percent_complete =
				Math.round(((item.task.estimateForecast - item.task.timeLeft) / item.task.estimateForecast) * 100) + '%';
		}
		if (item.task.statusColumn.category === 'DONE') {
			percent_complete = '100%';
		}

		const warningsStartdate = Util.GetWarningsForTaskStartDate(item.task, this.props.intl);

		let warning = [];
		if (this.props.showWarnings) {
			if (warningsStartdate.showWarning) {
				warning.push(
					<div key="1" className="info-warning">
						<div className="task-warning red">!</div>
						<div className="warning-text">{warningsStartdate.message}</div>
					</div>
				);
			}

			const warnings = Util.GetWarningsForTaskDeadline(item.task, item.task.project, this.props.intl);

			if (warnings.showWarning) {
				warning.push(
					<div key="2" className="info-warning">
						<div className="task-warning red">!</div>
						<div className="warning-text">{warnings.message}</div>
					</div>
				);
			}

			const minutesRegistered = item.task.timeRegistrations.edges.reduce(
				(total, task) => total + task.node.minutesRegistered,
				0
			);
			if (
				item.task.project.estimationUnit === 'HOURS'
					? minutesRegistered > item.task.estimateForecast
					: minutesRegistered > item.task.estimateForecast * item.task.project.minutesPerEstimationPoint
			) {
				warning.push(
					<div key="4" className="info-warning">
						<div className="task-warning yellow">!</div>
						<div className="warning-text">{this.props.intl.formatMessage({id: 'card.over_forecast.message'})}</div>
					</div>
				);
			}
		}

		const backgroundColor = oldColors[item.phaseOrder % oldColors.length];
		return (
			<div
				className="project-timeline-task-item"
				onClick={this.showTaskModal.bind(this)}
				ref={e => {
					this.taskItemContainer = e;
				}}
			>
				{item.task.phase ? (
					<div className="progress-wrapper task">
						<div className="progress" style={{width: percent_complete, backgroundColor}} />
						<div className="fill" style={{backgroundColor}} />
						<div className="progress-label">
							{this.state.showPercentage ? (
								<h4 className="progress-label-percentage">{percent_complete}</h4>
							) : null}
							{this.state.showDone ? <h4 className="progress-label-done">{'done'}</h4> : null}
						</div>
					</div>
				) : (
					<div className="progress-wrapper task no-scope">
						<div className="progress" style={{width: percent_complete}} />
						<div className="fill" />
						<div className="progress-label">
							{this.state.showPercentage ? (
								<h4 className="progress-label-percentage">{percent_complete}</h4>
							) : null}
							{this.state.showDone ? <h4 className="progress-label-done">{'done'}</h4> : null}
						</div>
					</div>
				)}
				{this.props.mouseOverTaskId === item.task.id ? (
					<div
						className={'info-dropdown ' + (this.props.flipPopup ? 'flip' : '')}
						style={{left: this.props.popupX, top: this.props.popupY}}
					>
						<div className="triangle-container">
							<div className="triangle" />
						</div>
						<small className="task-id">{'T' + item.task.companyTaskId}</small>
						<div className="task-name">{item.task.name}</div>
						<div className="task-assigned-container">
							{item.task.assignedPersons.map((person, index) => (
								<div key={index} className="assigned-person">
									{person.profilePictureId ? (
										<img
											alt="assigned-person-img"
											crossOrigin="use-credentials"
											title={person.firstName + ' ' + person.lastName}
											key={'task-item-image' + item.task.id + index}
											src={profilePicSrc(person.profilePictureId)}
											width="24"
											height="24"
										/>
									) : (
										<PersonInitials
											initials={Util.getInitials(`${person.firstName} ${person.lastName}`)}
											size={INITIALS_SIZE.SMALL}
										/>
									)}
									<span>{person.firstName + ' ' + person.lastName}</span>
								</div>
							))}
						</div>
						{warning.map(e => e)}
					</div>
				) : null}
			</div>
		);
	}
}

TimelineTaskItem.propTypes = {
	item: PropTypes.object.isRequired,
	showWarnings: PropTypes.bool.isRequired,
};

export default TimelineTaskItem;
