/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type cardListComponent_viewer$ref = any;
export type cardListComponentRefetchQueryVariables = {|
  shareKey?: ?string,
  componentId?: ?string,
  projectId?: ?string,
  isProjectGroupType?: ?boolean,
  sprintIds?: ?$ReadOnlyArray<?string>,
  approvedFilterValue?: ?boolean,
|};
export type cardListComponentRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: cardListComponent_viewer$ref
  |}
|};
export type cardListComponentRefetchQuery = {|
  variables: cardListComponentRefetchQueryVariables,
  response: cardListComponentRefetchQueryResponse,
|};
*/


/*
query cardListComponentRefetchQuery(
  $shareKey: String
  $componentId: ID
  $projectId: ID
  $isProjectGroupType: Boolean
  $sprintIds: [ID]
  $approvedFilterValue: Boolean
) {
  viewer {
    ...cardListComponent_viewer_1QOSyC
    id
  }
}

fragment cardListComponent_viewer_1QOSyC on Viewer {
  availableFeatureFlags {
    key
    id
  }
  insightComponentsData(shareKey: $shareKey) {
    cardList(componentId: $componentId, projectId: $projectId, isProjectGroupType: $isProjectGroupType, sprintIds: $sprintIds, approvedFilterValue: $approvedFilterValue) {
      useHours
      minutesPerPoint
      forecastTotal
      reportedTotal
      remainingTotal
      differenceTotal
      currency
      tasks {
        id
        approved
        companyTaskId
        projectName
        companyProjectId
        name
        description
        timeLeft
        diff
        startYear
        startMonth
        startDay
        deadlineDay
        deadlineMonth
        deadlineYear
        estimateForecast
        costForecast
        blocked
        bug
        billable
        sprintName
        phaseName
        assignedPersons {
          id
          firstName
          lastName
          profilePictureId
          profilePictureDefaultId
        }
        statusColumnName
        statusDone
        subTaskCount
        doneSubTaskCount
        fileCount
        commentCount
        reportedMinutes
        labels {
          id
          name
          color
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "componentId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isProjectGroupType",
    "type": "Boolean"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sprintIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "approvedFilterValue",
    "type": "Boolean"
  }
],
v1 = {
  "kind": "Variable",
  "name": "approvedFilterValue",
  "variableName": "approvedFilterValue"
},
v2 = {
  "kind": "Variable",
  "name": "componentId",
  "variableName": "componentId"
},
v3 = {
  "kind": "Variable",
  "name": "isProjectGroupType",
  "variableName": "isProjectGroupType"
},
v4 = {
  "kind": "Variable",
  "name": "projectId",
  "variableName": "projectId"
},
v5 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v6 = {
  "kind": "Variable",
  "name": "sprintIds",
  "variableName": "sprintIds"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "cardListComponentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "cardListComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "cardListComponentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v5/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/)
                ],
                "concreteType": "CardList",
                "kind": "LinkedField",
                "name": "cardList",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "useHours",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minutesPerPoint",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forecastTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reportedTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remainingTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "differenceTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CardListCard",
                    "kind": "LinkedField",
                    "name": "tasks",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "approved",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyTaskId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyProjectId",
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeLeft",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "diff",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startMonth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deadlineDay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deadlineMonth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deadlineYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "estimateForecast",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "costForecast",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "blocked",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "bug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sprintName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phaseName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CardListCardPerson",
                        "kind": "LinkedField",
                        "name": "assignedPersons",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureDefaultId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusColumnName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusDone",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "subTaskCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "doneSubTaskCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fileCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "commentCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reportedMinutes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Label",
                        "kind": "LinkedField",
                        "name": "labels",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "cardListComponentRefetchQuery",
    "operationKind": "query",
    "text": "query cardListComponentRefetchQuery(\n  $shareKey: String\n  $componentId: ID\n  $projectId: ID\n  $isProjectGroupType: Boolean\n  $sprintIds: [ID]\n  $approvedFilterValue: Boolean\n) {\n  viewer {\n    ...cardListComponent_viewer_1QOSyC\n    id\n  }\n}\n\nfragment cardListComponent_viewer_1QOSyC on Viewer {\n  availableFeatureFlags {\n    key\n    id\n  }\n  insightComponentsData(shareKey: $shareKey) {\n    cardList(componentId: $componentId, projectId: $projectId, isProjectGroupType: $isProjectGroupType, sprintIds: $sprintIds, approvedFilterValue: $approvedFilterValue) {\n      useHours\n      minutesPerPoint\n      forecastTotal\n      reportedTotal\n      remainingTotal\n      differenceTotal\n      currency\n      tasks {\n        id\n        approved\n        companyTaskId\n        projectName\n        companyProjectId\n        name\n        description\n        timeLeft\n        diff\n        startYear\n        startMonth\n        startDay\n        deadlineDay\n        deadlineMonth\n        deadlineYear\n        estimateForecast\n        costForecast\n        blocked\n        bug\n        billable\n        sprintName\n        phaseName\n        assignedPersons {\n          id\n          firstName\n          lastName\n          profilePictureId\n          profilePictureDefaultId\n        }\n        statusColumnName\n        statusDone\n        subTaskCount\n        doneSubTaskCount\n        fileCount\n        commentCount\n        reportedMinutes\n        labels {\n          id\n          name\n          color\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a261edf867819363dd88da74ee80f8ab';

module.exports = node;
