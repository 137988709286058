import React from 'react';
import Styled from 'styled-components';
import CheckboxRow from './CheckboxRow';
import {useIntl} from 'react-intl';
import Util from '../../../forecast-app/shared/util/util';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {getAllocationTotalNew} from '../../scheduling/project_allocation_logic';

const Container = Styled.div`
	display: grid;
	row-gap: 3px;
`;

const AllocationCheckboxList = ({allocations, onChange, selected}) => {
	const intl = useIntl();

	const toggleSelection = allocation => {
		const removeSelection = selected.includes(allocation.id);
		trackEvent('Assign To Modal Allocation Checklist', 'Changed');
		if (removeSelection) {
			onChange(selected.filter(selectedAllocationId => selectedAllocationId !== allocation.id));
		} else {
			onChange([...selected, allocation.id]);
		}
	};

	const prettyDate = date => {
		return intl.formatDate(date, {
			year: '2-digit',
			month: 'numeric',
			day: 'numeric',
		});
	};

	return (
		<Container>
			{allocations.map(allocation => {
				return (
					<CheckboxRow
						cy={allocation.id}
						key={allocation.id}
						checked={selected.includes(allocation.id)}
						onChange={() => toggleSelection(allocation)}
						label={Util.convertMinutesToFullHour(getAllocationTotalNew(allocation), intl)}
						rightLabel={prettyDate(allocation.startDate) + ' - ' + prettyDate(allocation.endDate)}
					/>
				);
			})}
		</Container>
	);
};

export default AllocationCheckboxList;
