import React from 'react';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {FlexRow, OutlineBox, PageWrapper, Section} from '../UtilizationReport.styled';
import {MonthByMonthUtilizationTable} from './MonthByMonthTable';
import {UtilizationCharts} from '../UtilizationCharts';
import {MonthByMonthTitle} from './MonthByMonthTitle';

export const MonthByMonthPage = ({
	startDate,
	endDate,
	filters,
	aggregatedResourceNumbersByDay,
	aggregatedResourceNumbersByMonth,
	totalResourceNumbers,
	companyTarget,
	showResourceUtilization,
	setCsvDownloadFunction,
	groupBy,
	isMixedAllocationModeEnabled,
	allocationControlsOptions,
}) => {
	return (
		<PageWrapper>
			<CustomScrollDiv horizontalandverticalcontent>
				<UtilizationCharts
					startDate={startDate}
					endDate={endDate}
					aggregatedResourceNumbersByDay={aggregatedResourceNumbersByDay}
					companyTarget={companyTarget}
					showResourceUtilization={showResourceUtilization}
					totalResourceNumbers={totalResourceNumbers}
					isMixedAllocationModeEnabled={isMixedAllocationModeEnabled}
					allocationControlsOptions={allocationControlsOptions}
				/>
				<Section marginTop={28} data-userpilot={'utilization-month-by-month-table'}>
					<FlexRow growable>
						<OutlineBox growable marginRight={24} marginLeft={24} marginBottom={24}>
							<MonthByMonthTitle showResourceUtilization={showResourceUtilization} />
							<MonthByMonthUtilizationTable
								filters={filters}
								showResourceUtilization={showResourceUtilization}
								aggregatedResourceNumbersByMonth={aggregatedResourceNumbersByMonth}
								startDate={startDate}
								endDate={endDate}
								setCsvDownloadFunction={setCsvDownloadFunction}
								groupBy={groupBy}
								isMixedAllocationModeEnabled={isMixedAllocationModeEnabled}
								allocationControlsOptions={allocationControlsOptions}
							/>
						</OutlineBox>
					</FlexRow>
				</Section>
			</CustomScrollDiv>
		</PageWrapper>
	);
};
