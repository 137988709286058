/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectPortfolioPeriodRow_financialNumbers$ref = any;
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProgramLoader_viewer$ref: FragmentReference;
declare export opaque type ProgramLoader_viewer$fragmentType: ProgramLoader_viewer$ref;
export type ProgramLoader_viewer = {|
  +id: string,
  +company: ?{|
    +id: string,
    +currency: ?string,
    +financialBasedPrograms: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +budgetType: ?PROGRAM_BUDGET_TYPE,
          +color: ?string,
          +projectsFinancialsTotals: ?{|
            +unallocatedRevenue: ?number,
            +$fragmentRefs: ProjectPortfolioPeriodRow_financialNumbers$ref,
          |},
        |}
      |}>
    |},
  |},
  +$refType: ProgramLoader_viewer$ref,
|};
export type ProgramLoader_viewer$data = ProgramLoader_viewer;
export type ProgramLoader_viewer$key = {
  +$data?: ProgramLoader_viewer$data,
  +$fragmentRefs: ProgramLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProgramLoader_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "endDay",
              "variableName": "endDay"
            },
            {
              "kind": "Variable",
              "name": "endMonth",
              "variableName": "endMonth"
            },
            {
              "kind": "Variable",
              "name": "endYear",
              "variableName": "endYear"
            },
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            },
            {
              "kind": "Variable",
              "name": "startDay",
              "variableName": "startDay"
            },
            {
              "kind": "Variable",
              "name": "startMonth",
              "variableName": "startMonth"
            },
            {
              "kind": "Variable",
              "name": "startYear",
              "variableName": "startYear"
            }
          ],
          "concreteType": "ProjectServiceProgramTypeConnection",
          "kind": "LinkedField",
          "name": "financialBasedPrograms",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProgramType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "budgetType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FinancialNumbers",
                      "kind": "LinkedField",
                      "name": "projectsFinancialsTotals",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "unallocatedRevenue",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "ProjectPortfolioPeriodRow_financialNumbers"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '9706f2bdf6b18abb9faa8cd641b1c556';

module.exports = node;
