/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ProjectPortfolioPeriodRow_financialNumbers$ref = any;
export type PhaseFinancialBreakdownType = "NO_PHASE" | "OFFSET" | "PHASE" | "PROJECT_TIME_REG" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PhaseLoader_viewer$ref: FragmentReference;
declare export opaque type PhaseLoader_viewer$fragmentType: PhaseLoader_viewer$ref;
export type PhaseLoader_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string
  |},
  +project: ?{|
    +budgetBaseCurrency: ?number,
    +phaseFinancialBreakdown: $ReadOnlyArray<?{|
      +breakdownType: ?PhaseFinancialBreakdownType,
      +phase: ?{|
        +id: string,
        +name: ?string,
        +startDay: ?number,
        +startMonth: ?number,
        +startYear: ?number,
        +deadlineDay: ?number,
        +deadlineMonth: ?number,
        +deadlineYear: ?number,
        +progress: ?number,
      |},
      +financialNumbers: ?{|
        +$fragmentRefs: ProjectPortfolioPeriodRow_financialNumbers$ref
      |},
    |}>,
  |},
  +$refType: PhaseLoader_viewer$ref,
|};
export type PhaseLoader_viewer$data = PhaseLoader_viewer;
export type PhaseLoader_viewer$key = {
  +$data?: PhaseLoader_viewer$data,
  +$fragmentRefs: PhaseLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PhaseLoader_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetBaseCurrency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "endDay",
              "variableName": "endDay"
            },
            {
              "kind": "Variable",
              "name": "endMonth",
              "variableName": "endMonth"
            },
            {
              "kind": "Variable",
              "name": "endYear",
              "variableName": "endYear"
            },
            {
              "kind": "Literal",
              "name": "filterRowsWithNoValues",
              "value": true
            },
            {
              "kind": "Literal",
              "name": "ignoreBaselineWinChance",
              "value": false
            },
            {
              "kind": "Variable",
              "name": "startDay",
              "variableName": "startDay"
            },
            {
              "kind": "Variable",
              "name": "startMonth",
              "variableName": "startMonth"
            },
            {
              "kind": "Variable",
              "name": "startYear",
              "variableName": "startYear"
            }
          ],
          "concreteType": "PhaseFinancialBreakdown",
          "kind": "LinkedField",
          "name": "phaseFinancialBreakdown",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "breakdownType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PhaseType",
              "kind": "LinkedField",
              "name": "phase",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deadlineDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deadlineMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deadlineYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "progress",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FinancialNumbers",
              "kind": "LinkedField",
              "name": "financialNumbers",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectPortfolioPeriodRow_financialNumbers"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d047229755133602781497cf637a7c5';

module.exports = node;
