import {ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE} from '../../../constants';
import {
	PLACEHOLDERS_FILTER_STORAGE_KEY,
	PLACEHOLDERS_GROUP_BY,
	PLACEHOLDERS_STAFFING_SORT_BY,
} from './CanvasPlaceholderSchedulingConstants';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/dropdown_section';
import HeaderBar from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import React from 'react';
import {UTILIZATION_FORMAT} from '../canvas-timeline/canvas_timeline_util';
import {getStaffingLocalStorageKey} from './CanvasPlaceholdersSchedulingUtil';
import {TopHeaderBar} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {
	onToggleCalcWin,
	onToggleHideHard,
	onToggleHideSoft,
} from '../components/allocation_controls/AllocationControlsCanvasUtils';
import EventManager from '../EventManager';

const mapFiltersToGraphQLType = pageComponent => {
	const {staffingModeActive, data} = pageComponent.state;
	const {placeholderFilters, peopleFilters} = data;

	const filters = staffingModeActive
		? {edges: peopleFilters.map(filter => ({node: filter}))}
		: {edges: placeholderFilters.map(filter => ({node: filter}))};

	return {
		filters,
	};
};

export const createHeaderBar = pageComponent => {
	const leftContent = [],
		rightContent = [];

	const {formatMessage} = pageComponent.props.intl;
	const {
		initialDataLoaded,
		searchFilterValue,
		groupBy,
		sortBy,
		schedulingOptions,
		eyeOptions,
		staffingModeActive,
		enteringStaffingMode,
		filters,
	} = pageComponent.state;
	const {utilizationFormat} = schedulingOptions;

	leftContent.push({
		type: ELEMENT_TYPE.EMPTY_SPACE,
		width: 14,
	});

	leftContent.push({
		type: ELEMENT_TYPE.DATE_CHANGER,
		onlyTodayButton: true,
		handleTodayButtonClick: () => EventManager.onScrollToToday(pageComponent),
		handleMoveDateButtonClick: moveForward => EventManager.onMoveDateButtonClick(pageComponent, moveForward),
		userpilot: 'schedule-date-changer',
		cy: 'date-changer',
	});

	leftContent.push({
		type: ELEMENT_TYPE.ZOOM_MENU,
	});

	rightContent.push({
		type: ELEMENT_TYPE.SEARCH,
		value: searchFilterValue || '',
		onChange: event => EventManager.onSearchChange(pageComponent, event),
		cy: 'canvas-search',
	});

	if (initialDataLoaded && !enteringStaffingMode) {
		const data = pageComponent.getData();
		const {viewer} = data;

		const filterV4Viewer = mapFiltersToGraphQLType(pageComponent);

		const filterContent = {
			type: ELEMENT_TYPE.FILTER_V4,
			defaultSection: staffingModeActive ? FILTER_SECTIONS.PEOPLE : FILTER_SECTIONS.PLACEHOLDERS,
			viewer: filterV4Viewer,
			appliedFiltersName: staffingModeActive
				? getStaffingLocalStorageKey(pageComponent)
				: PLACEHOLDERS_FILTER_STORAGE_KEY,
			filterSection: FILTER_SECTION.SCHEDULING_PLACEHOLDERS,
			onFiltersChange: (filterValues, filterFunctions) =>
				EventManager.onFilterChange(pageComponent, filterValues, filterFunctions),
			projectId: viewer.project ? viewer.project.id : null,
			projectGroupId: viewer.projectGroup ? viewer.projectGroup.id : null,
			userpilot: 'filter-button',
			cy: 'placeholders-schedule-filters',
			preAppliedFilters: staffingModeActive && filters ? filters : undefined,
			enableFilterMode: true,
		};

		if (staffingModeActive) {
			filterContent.peopleFilters = [
				FILTER_TYPE.PERSON,
				FILTER_TYPE.ROLE,
				FILTER_TYPE.TEAM,
				FILTER_TYPE.PROJECT,
				FILTER_TYPE.DEPARTMENT,
				FILTER_TYPE.CAPACITY_WORKLOAD,
				FILTER_TYPE.SKILL,
				FILTER_TYPE.LABEL,
			];
		} else {
			filterContent.placeholderFilters = [FILTER_TYPE.PROJECT, FILTER_TYPE.ROLE, FILTER_TYPE.SKILL];
			if (hasFeatureFlag('capacity_planning_beta_2_improvements')) {
				filterContent.placeholderFilters.push(FILTER_TYPE.DEPARTMENT, FILTER_TYPE.TEAM);
			}

			filterContent.projectFilters = [
				FILTER_TYPE.PROJECT,
				FILTER_TYPE.PROJECT_TYPE,
				FILTER_TYPE.CLIENT,
				FILTER_TYPE.PERSON,
				FILTER_TYPE.PROJECT_STAGE,
				FILTER_TYPE.PROJECT_STATUS,
				FILTER_TYPE.PROJECT_OWNER,
				FILTER_TYPE.LABEL,
				FILTER_TYPE.PROJECT_WIN_CHANCE,
			];
		}

		rightContent.push(filterContent);
	}

	if (hasFeatureFlag('placeholders')) {
		if (hasFeatureFlag('capacity_planning_beta_2_improvements')) {
			rightContent.push({
				type: ELEMENT_TYPE.WIN_PROBABILITY,
				calcWin: !staffingModeActive && schedulingOptions.calcWin,
				onToggleCalcWin: staffingModeActive ? undefined : checked => onToggleCalcWin(pageComponent, checked),
				cy: 'canvas-win-probability',
			});

			rightContent.push({
				type: ELEMENT_TYPE.ALLOCATION_CONTROLS,
				hideSoft: staffingModeActive && schedulingOptions.hideSoft,
				onToggleHideSoft: staffingModeActive ? checked => onToggleHideSoft(pageComponent, checked) : undefined,
				hideHard: staffingModeActive && schedulingOptions.hideHard,
				onToggleHideHard: staffingModeActive ? checked => onToggleHideHard(pageComponent, checked) : undefined,
				cy: 'canvas-allocation-controls',
			});
		} else {
			rightContent.push({
				type: ELEMENT_TYPE.ALLOCATION_CONTROLS,
				hideSoft: staffingModeActive && schedulingOptions.hideSoft,
				onToggleHideSoft: staffingModeActive ? checked => onToggleHideSoft(pageComponent, checked) : undefined,
				hideHard: staffingModeActive && schedulingOptions.hideHard,
				onToggleHideHard: staffingModeActive ? checked => onToggleHideHard(pageComponent, checked) : undefined,
				calcWin: !staffingModeActive && schedulingOptions.calcWin,
				onToggleCalcWin: staffingModeActive ? undefined : checked => onToggleCalcWin(pageComponent, checked),
				cy: 'canvas-allocation-controls',
			});
		}
	}

	if (!staffingModeActive) {
		// GROUP BY
		const groupByOptions = [
			{value: PLACEHOLDERS_GROUP_BY.ROLE, label: formatMessage({id: 'common.group_by_role'})},
			{value: PLACEHOLDERS_GROUP_BY.PROJECT, label: formatMessage({id: 'common.group_by_project'})},
		];

		rightContent.push({
			type: ELEMENT_TYPE.DROPDOWN,
			dropdownOptions: groupByOptions,
			value: groupBy,
			callback: group => EventManager.onGroupByChange(pageComponent, group.value),
			cy: 'canvas-search',
		});
	} else {
		// SORT BY
		const sortByOptions = [
			{
				value: PLACEHOLDERS_STAFFING_SORT_BY.SUITABILITY,
				label: formatMessage({id: 'scheduling.sort_by_suitability'}),
			},
			{
				value: PLACEHOLDERS_STAFFING_SORT_BY.ALPHABETICALLY,
				label: formatMessage({id: 'scheduling.sort_alphabetically'}),
			},
		];

		rightContent.push({
			type: ELEMENT_TYPE.DROPDOWN,
			dropdownOptions: sortByOptions,
			value: sortBy,
			callback: option => EventManager.onSortByChange(pageComponent, option.value),
			cy: 'canvas-sort-by',
		});

		// UTILIZATION FORMAT
		const utilizationFormatOptions = [
			{value: UTILIZATION_FORMAT.HOURS, label: formatMessage({id: 'scheduling.heatmap_in_hours'})},
			{value: UTILIZATION_FORMAT.PERCENTAGE, label: formatMessage({id: 'scheduling.heatmap_in_percentage'})},
		];

		rightContent.push({
			type: ELEMENT_TYPE.DROPDOWN,
			dropdownOptions: utilizationFormatOptions,
			value: utilizationFormat,
			callback: format => EventManager.onUtilizationFormatChange(pageComponent, format.value),
			cy: 'canvas-utilization-format',
		});
	}

	rightContent.push({type: ELEMENT_TYPE.EXPAND_ALL_CANVAS, cy: 'expand-all-button'});

	if (eyeOptions?.length > 0) {
		rightContent.push({
			type: ELEMENT_TYPE.THE_EYE,
			options: eyeOptions,
			onSelect: selected => EventManager.onEyeOptionsChange(pageComponent, selected),
			expandLeft: true,
			disableTooltip: true,
		});
	}

	return <HeaderBar scheduling={true} noPadding={true} leftContent={leftContent} rightContent={rightContent} />;
};

export function getTopHeaderContent() {
	const content = [];

	content.push({
		type: TopHeaderBar.TYPE.FEEDBACK,
		link: 'https://share.hsforms.com/1WISzgI8bTw6p-BcHDsB6YA29otq',
	});

	const onboardingFlows = [
		{
			id: 'demand-introduction',
			title: 'Introduction to the page',
			description: null,
			contentId: '1681809169xKjo4367',
		},
		{
			id: 'demand-assign-placeholder',
			title: 'How to assign a placeholder allocation',
			description: null,
			contentId: '1681810095aIti4168',
		},
		{
			id: 'demand-manage-placeholder',
			title: 'How to manage placeholder allocations',
			description: null,
			contentId: '1681811553hJzo659',
		},
	];
	const onboardingComponent = {
		id: 'onboarding-component',
		type: TopHeaderBar.TYPE.ONBOARDING,
		title: 'Learn to use Demand',
		options: onboardingFlows,
		helpCenterLink: 'https://support.forecast.app/hc/en-us/articles/11951561590161-About-Capacity-Planning-Plus-only-',
		subLink: 'https://support.forecast.app/hc/en-us/articles/12134108360337-Creating-and-managing-placeholders-Plus-only-',
	};
	content.push(onboardingComponent);

	return content;
}
