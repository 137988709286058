import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const BudgetHeaderWrapper = Styled.div`   
    background-color: ${CSS_CONSTANTS.color_white};
    position: sticky;
    top: 0;
    z-index: 20;
    
    .board-card-details-selector div {
        border-width: 2px;
    }
`;
