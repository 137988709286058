import Util from '../../../../forecast-app/shared/util/util';
import {ESTIMATION_UNIT} from '../../../../constants';

const getHeaderValue = (taskTotals, estimationUnit, hourString, pointString) => {
	if (taskTotals) {
		if (estimationUnit === ESTIMATION_UNIT.HOURS) {
			return hourString;
		} else if (estimationUnit === ESTIMATION_UNIT.POINTS) {
			return pointString;
		}
	} else {
		return '-';
	}
};

export const totalsEstimate = (taskTotals, intl, estimationUnit) => {
	const hoursString = `${Util.convertMinutesToFullHour(taskTotals.estimateMinutes, intl)}`;
	const pointsString = `${taskTotals.estimatePoints}p`;
	return getHeaderValue(taskTotals, estimationUnit, hoursString, pointsString);
};

export const totalsRemaining = (taskTotals, intl, estimationUnit) => {
	const hoursString = `${Util.convertMinutesToFullHour(taskTotals.remainingMinutes, intl)}`;
	const pointsString = `${intl.formatNumber(taskTotals.remainingPoints, {format: 'always_two_decimal'})}p`;
	return getHeaderValue(taskTotals, estimationUnit, hoursString, pointsString);
};

export const totalsDifference = (taskTotals, intl, estimationUnit) => {
	const hoursString = `${Util.convertMinutesToFullHour(taskTotals.estimateMinutes - taskTotals.remainingMinutes, intl)}`;
	const pointsString = `${intl.formatNumber(taskTotals.estimatePoints - taskTotals.remainingPoints, {
		format: 'always_two_decimal',
	})}p`;
	return getHeaderValue(taskTotals, estimationUnit, hoursString, pointsString);
};

export const totalsReported = (taskTotals, intl) => {
	return taskTotals ? Util.convertMinutesToFullHour(taskTotals.registeredMinutes, intl) : '-';
};

export const totalsBillableReported = (taskTotals, intl) => {
	return taskTotals ? Util.convertMinutesToFullHour(taskTotals.billableActualMinutes, intl) : '-';
};

export const totalsNonBillableReported = (taskTotals, intl) => {
	return taskTotals ? Util.convertMinutesToFullHour(taskTotals.nonBillableActualMinutes, intl) : '-';
};

export const totalsProjected = (taskTotals, intl) => {
	return taskTotals ? Util.convertMinutesToFullHour(taskTotals.registeredMinutes + taskTotals.remainingMinutes, intl) : '-';
};

export const totals = (value, currency, intl) =>
	value ? Util.GetFormattedCurrencySymbol(currency, intl.formatNumber(value, {format: 'always_two_decimal'})) : '-';
