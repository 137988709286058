import React, {useEffect, useRef, useState} from 'react';
import {TwitterPicker} from 'react-color';
import {projectColors} from '../constants';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

/* temporary solution until we have a color picker in the design system */
export const ColorsPickerDesignSystemSizeWrapper = styled.div`
	.colors-picker-container {
		padding: 0;
		.color-picker,
		.color-option {
			&.rectangle {
				width: 42px;
				height: 42px;
				border-radius: 6px;
			}
		}
	}
`;
const ColorsPicker = ({value, onColorSelect, projectLocked, size, usePortal, rectangle, cy}) => {
	const [showWindow, setShowWindow] = useState(false);
	const [above, setAbove] = useState(false);

	// portal settings
	const containerRef = useRef(null);
	const colorPickerRef = useRef(null);
	const maxHeight = 100;

	const handleClose = () => {
		setShowWindow(false);
	};
	const handleChangeComplete = color => {
		onColorSelect(color.hex);
	};

	const handleClick = () => {
		setShowWindow(!showWindow);
		containerRef.current && containerRef.current.focus();
	};

	const handleWrapperClick = event => {
		event.stopPropagation();
	};

	const handleScrollEvent = event => {
		const target = event.target;
		const isTargetInDropdown = (target, level) => {
			if (
				target &&
				target.className &&
				(target.className.includes('colors-picker-container') || target.className.includes('options-window'))
			) {
				return true;
			} else if (target && level > 0) {
				return isTargetInDropdown(target.parentNode, level - 1);
			} else {
				return false;
			}
		};

		if (target) {
			if (!isTargetInDropdown(target, 3)) {
				handleClose();
			}
		}
	};

	const shouldHaveBorder = hexa => {
		const code = hexa.replace('#', '');
		const r = parseInt(code.substring(0, 2), 16);
		const g = parseInt(code.substring(2, 4), 16);
		const b = parseInt(code.substring(4, 6), 16);

		const y = 0.2126 * r + 0.7152 * g + 0.0722 * b;
		return y > 240; //assume that at this point a border is necessary
	};

	const getColorPickerContent = styling => {
		return (
			<div className={'options-window twitter-picker '} style={styling} ref={colorPickerRef}>
				<div className="cover" onClick={handleClose} />
				<TwitterPicker
					color={value}
					triangle={usePortal ? 'top-left' : 'top-right'}
					colors={projectColors}
					onChangeComplete={handleChangeComplete}
					width={312}
				/>
			</div>
		);
	};

	const getPortalPicker = () => {
		if (!showWindow) {
			return null;
		}

		let style = {};
		let positionAbove = false;
		const offset = containerRef.current.getBoundingClientRect();
		const maxDropdownBottomPosition = offset.bottom + maxHeight;
		const haveSpaceAbove = offset.top - maxHeight >= 0;
		if (maxDropdownBottomPosition > window.innerHeight && haveSpaceAbove) {
			positionAbove = true;
			if (above !== positionAbove) {
				setAbove(positionAbove);
			}
		}

		if (offset) {
			style = {
				position: 'absolute',
				zIndex: 999,
				left: offset.left - 2.5, // 5px padding on container
			};

			if (positionAbove) {
				style.bottom = window.innerHeight - offset.top - 1;
			} else {
				style.top = offset.top + offset.height - 1;
			}
		}

		return ReactDOM.createPortal(getColorPickerContent(style), document.querySelector('#root-portal-container'));
	};

	const getColorPicker = () => {
		return usePortal ? getPortalPicker() : getColorPickerContent();
	};

	const handleClickOutside = event => {
		if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				handleClose();
			}
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (usePortal) {
			if (showWindow) {
				document.addEventListener('scroll', handleScrollEvent, true);
			} else {
				document.removeEventListener('scroll', handleScrollEvent, true);
			}
		}
	}, [showWindow]);

	return (
		<div className="colors-picker-container" tabIndex="0" ref={containerRef} onClick={handleWrapperClick}>
			{rectangle ? (
				<div
					className={'color-picker rectangle' + (projectLocked ? ' locked' : '')}
					style={{
						backgroundColor: value,
						border: shouldHaveBorder(value) ? '1px solid #e6e6e6' : 'none',
					}}
					onClick={handleClick}
				/>
			) : (
				<svg
					className={'color-picker' + (projectLocked ? ' locked' : '')}
					height={size ? size + 'px' : '36px'}
					width={size ? size + 'px' : '36px'}
					onClick={handleClick}
				>
					<circle
						data-cy={cy}
						cx={size / 2 || '16'}
						cy={size / 2 || '16'}
						r={size / 2 || '15'}
						style={{fill: value}}
					/>
				</svg>
			)}

			{showWindow && !projectLocked ? getColorPicker() : ''}
		</div>
	);
};

export default ColorsPicker;
