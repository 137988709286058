import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import Util from '../../forecast-app/shared/util/util';
import * as tracking from '../../tracking';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';

class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSearchExpanded: this.props.alwaysExpanded ? this.props.alwaysExpanded : false,
		};
		this.handleShortcutSearch = this.handleShortcutSearch.bind(this);
	}

	componentDidMount() {
		if (this.props.useShortcut) {
			document.addEventListener('keydown', this.handleShortcutSearch);
		}
		if (this.props.startFocused && this.searchInput) {
			this.searchInput.focus();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.isSearchExpanded && !prevState.isSearchExpanded && this.searchInput) {
			this.searchInput.focus();
		}
	}

	componentWillUnmount() {
		if (this.props.useShortcut) {
			document.removeEventListener('keydown', this.handleShortcutSearch);
		}
	}

	handleShortcutSearch(e) {
		const taskModalOpen = Util.getPathIsTaskModal();
		const activeElement = document.activeElement;
		const focusIsInput =
			activeElement &&
			(activeElement.tagName === 'INPUT' ||
				activeElement.tagName === 'TEXTAREA' ||
				activeElement.isContentEditable ||
				(activeElement.className && activeElement.className.toLowerCase().includes('drafteditor')) ||
				(activeElement.className && activeElement.className.toLowerCase().includes('switch')));
		const focusIsSearch = activeElement && activeElement.id === 'search';
		if (focusIsSearch && !taskModalOpen && e.key === 'Escape') {
			this.clearSearch();
			this.toggleSearchBar();
		}
		if (taskModalOpen || focusIsInput || e.repeat) {
			return;
		}
		if (e.shiftKey && e.keyCode === 70) {
			e.preventDefault();
			e.stopPropagation();
			this.toggleSearchBar();
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11

		if (this.search_container && this.search_container.contains(newTarget)) {
			return;
		} else {
			if (this.props.value === '') {
				if (this.props.setSearchState) {
					this.props.setSearchState(false);
				}
				this.setState({isSearchExpanded: false});
			}
		}
		tracking.trackEvent('Searched', {searchString: e.target && e.target.value ? e.target.value : ''});
		trackEvent('Items', 'Searched', {searchString: e.target && e.target.value ? e.target.value : ''});
	}

	toggleSearchBar() {
		this.setState({isSearchExpanded: !this.state.isSearchExpanded});
		if (this.props.setSearchState) {
			this.props.setSearchState(!this.state.isSearchExpanded);
		}
		if (this.state.isSearchExpanded && this.props.onClose) {
			this.props.onClose();
		}
	}

	clearSearch() {
		this.props.onChange('');
		this.searchInput.focus();
	}

	render() {
		const isSearchExpanded = this.state.isSearchExpanded || this.props.alwaysExpanded;
		const isDisabled = this.props.disabled;
		const isSearchEmpty = this.props.value === '';
		return (
			<TooltipContainer
				disabled={this.props.disableTooltip || isSearchExpanded}
				infoText={this.props.intl.formatMessage({id: 'common.search'}) + (this.props.useShortcut ? ' (shift + F)' : '')}
			>
				<div
					tabIndex={this.props.customTabIndex ? this.props.customTabIndex : '0'}
					className={
						'action search-V2' +
						(isSearchExpanded ? ' expanded' : '') +
						(isSearchEmpty ? ' empty' : ' filled') +
						(isDisabled ? ' disabled' : '')
					}
					onClick={!isSearchEmpty && isSearchExpanded ? this.clearSearch.bind(this) : this.toggleSearchBar.bind(this)}
					ref={div => (this.search_container = div)}
					data-cy={this.props.dataCy ? this.props.dataCy : ''}
					data-userpilot={this.props.userpilot}
				>
					{isSearchExpanded ? (
						<input
							id="search"
							type="text"
							ref={input => {
								this.searchInput = input;
							}}
							onClick={e => {
								e.stopPropagation();
							}}
							onChange={e => this.props.onChange(e.target.value)}
							placeholder={this.props.placeholder || ''}
							onBlur={e => this.handleBlur(e)}
							onKeyUp={this.props.onKeyUp ? e => this.props.onKeyUp(e) : null}
							value={this.props.value}
							disabled={isDisabled}
							data-cy={this.props.dataCy ? this.props.dataCy + '-text-input' : ''}
							autoComplete="off"
						/>
					) : null}
				</div>
			</TooltipContainer>
		);
	}
}

Search.propTypes = {
	placeholder: PropTypes.string,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onKeyUp: PropTypes.func,
	alwaysExpanded: PropTypes.bool,
	disableTooltip: PropTypes.bool,
};

export default injectIntl(Search);
