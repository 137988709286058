/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type PROJECT_STATUS = "DONE" | "HALTED" | "OPPORTUNITY" | "PLANNING" | "RUNNING" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type JiraIntegrationSection_project$ref: FragmentReference;
declare export opaque type JiraIntegrationSection_project$fragmentType: JiraIntegrationSection_project$ref;
export type JiraIntegrationSection_project = {|
  +id: string,
  +name: ?string,
  +jiraCloudProjectSettings: ?{|
    +syncRemaining: ?boolean,
    +cutoffDate: ?string,
    +isSyncDone: ?boolean,
    +syncSprints: ?boolean,
    +estimateInTime: ?boolean,
    +estimateInPointsCustomFieldName: ?string,
    +mapJiraSubTasksToToDo: ?boolean,
    +mapJiraVersionsToPhases: ?boolean,
  |},
  +jiraCloudEpicIds: ?$ReadOnlyArray<?string>,
  +jiraCloudProject: ?{|
    +id: string,
    +name: ?string,
    +style: ?string,
  |},
  +jiraErrors: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +createdAt: ?string,
        +errorCode: ?string,
        +task: ?{|
          +id: string,
          +companyTaskId: ?number,
        |},
        +timeRegId: ?string,
      |}
    |}>
  |},
  +tasks: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +parentTaskId: ?string,
      |}
    |}>
  |},
  +useTaskHierarchy: ?boolean,
  +status: ?PROJECT_STATUS,
  +$refType: JiraIntegrationSection_project$ref,
|};
export type JiraIntegrationSection_project$data = JiraIntegrationSection_project;
export type JiraIntegrationSection_project$key = {
  +$data?: JiraIntegrationSection_project$data,
  +$fragmentRefs: JiraIntegrationSection_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "JiraIntegrationSection_project",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "JiraCloudProjectSettingsType",
      "kind": "LinkedField",
      "name": "jiraCloudProjectSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "syncRemaining",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cutoffDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSyncDone",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "syncSprints",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimateInTime",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "estimateInPointsCustomFieldName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mapJiraSubTasksToToDo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mapJiraVersionsToPhases",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jiraCloudEpicIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JiraCloudProjectType",
      "kind": "LinkedField",
      "name": "jiraCloudProject",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "style",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "JiraErrorTypeConnection",
      "kind": "LinkedField",
      "name": "jiraErrors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "JiraErrorTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "JiraErrorType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "errorCode",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Task",
                  "kind": "LinkedField",
                  "name": "task",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyTaskId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "timeRegId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100000
        }
      ],
      "concreteType": "TaskTypeConnection",
      "kind": "LinkedField",
      "name": "tasks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Task",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parentTaskId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "tasks(first:100000)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useTaskHierarchy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '025f9683157bc6f980329c564412efc1';

module.exports = node;
