import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focused: false,
		};
	}
	toggleFocus() {
		this.setState({focused: !this.state.focused});
	}

	onChange(e) {
		if (this.props.onChange) {
			this.props.onChange(e);
		}
	}
	render() {
		return (
			<div className="md-checkbox">
				<input
					id={this.props.id}
					className={'checkbox-input' + (this.props.hasEntitiesChecked ? ' group-has-entities-checked' : '')}
					type="checkbox"
					checked={this.props.checked}
					disabled={this.props.disabled}
					onChange={this.onChange.bind(this)}
					onFocus={this.toggleFocus.bind(this)}
					onBlur={this.toggleFocus.bind(this)}
					data-cy={this.props.dataCy}
				/>
				<label
					onClick={this.props.disabled ? null : this.onChange.bind(this)}
					className={(this.state.focused ? 'focused' : '') + (this.props.disabled ? ' locked' : '')}
				/>
			</div>
		);
	}
}
Checkbox.propTypes = {
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	id: PropTypes.string,
};
export default Checkbox;
