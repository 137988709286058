/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LabelDropdownQueryVariables = {|
  taskId?: ?string
|};
export type LabelDropdownQueryResponse = {|
  +viewer: ?{|
    +component: ?string,
    +company: ?{|
      +availableMLModels: ?{|
        +labelModel: ?boolean
      |}
    |},
    +task: ?{|
      +labelSuggestion: ?$ReadOnlyArray<?string>
    |},
  |}
|};
export type LabelDropdownQuery = {|
  variables: LabelDropdownQueryVariables,
  response: LabelDropdownQueryResponse,
|};
*/


/*
query LabelDropdownQuery(
  $taskId: ID
) {
  viewer {
    component(name: "suggested_label_dropdown")
    company {
      availableMLModels {
        labelModel
      }
      id
    }
    task(id: $taskId) {
      labelSuggestion
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "suggested_label_dropdown"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"suggested_label_dropdown\")"
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AvailableMLModels",
  "kind": "LinkedField",
  "name": "availableMLModels",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "labelModel",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "taskId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "labelSuggestion",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LabelDropdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LabelDropdownQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "LabelDropdownQuery",
    "operationKind": "query",
    "text": "query LabelDropdownQuery(\n  $taskId: ID\n) {\n  viewer {\n    component(name: \"suggested_label_dropdown\")\n    company {\n      availableMLModels {\n        labelModel\n      }\n      id\n    }\n    task(id: $taskId) {\n      labelSuggestion\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '39d9f6bab85513cfb1fd95dabd9251d8';

module.exports = node;
