import React from 'react';
import {HighLevelNumberStyled, Label, Value} from './HighLevelBudgetNumbers.styled';
import {useIntl} from 'react-intl';
import {NUMBER_TYPE} from './util/BudgetUtils';
import Util from '../../../../forecast-app/shared/util/util';
import {TooltipFormulaRenderer} from '../../../TooltipFormulaRenderer';
import {Tooltip} from '@forecast-it/design-system';

export const HighLevelNumber = ({currencySymbol, labelTranslationId, value, numberType, cy, tooltipItems, bold, red}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const cyPrefix = 'budget-high-level';

	const formatNumber = (value, numberType) => {
		switch (numberType) {
			case NUMBER_TYPE.MONEY:
				return Util.getFormattedNumberWithCurrency(currencySymbol, value, intl);
			case NUMBER_TYPE.PERCENTAGE:
				return Util.getFormattedPercentage(value, intl);
			case NUMBER_TYPE.TIME:
				return Util.convertMinutesToFullHour(value, intl);
			default:
				break;
		}
		return value;
	};

	const TooltipWrapper = ({children}) =>
		tooltipItems && tooltipItems.length ? (
			<Tooltip width={Tooltip.WIDTH.xl} content={<TooltipFormulaRenderer items={tooltipItems} />} interactable={false}>
				{children}
			</Tooltip>
		) : (
			children
		);

	return (
		<HighLevelNumberStyled bold={bold}>
			<Label>
				<TooltipWrapper>{formatMessage({id: labelTranslationId})}</TooltipWrapper>
			</Label>
			<Value data-cy={cyPrefix + '-' + cy} negative={red || value < 0}>
				<TooltipWrapper>{formatNumber(value, numberType)}</TooltipWrapper>
			</Value>
		</HighLevelNumberStyled>
	);
};
