import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AutosizeInput from 'react-input-autosize';
import NumberFormat from 'react-number-format';
import util from '../../forecast-app/shared/util/util';

class InputField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFocused: false,
			value: this.props.value,
			focusAfterRender: false,
			filled: this.props.value && this.props.value !== '',
		};
	}

	componentDidMount() {
		if (this.props.shouldFocus) {
			this._input_field_.focus();
			this.setState({focusAfterRender: false});
		}
		if (this.props.handleRef) {
			this.props.handleRef(this._input_field_);
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.type === 'formatted') {
			return this.props.value !== nextProps.value || this.state.isFocused !== nextState.isFocused;
		}
		const shouldUpdate =
			this.props.hideLabel !== nextProps.hideLabel ||
			this.props.placeholder !== nextProps.placeholder ||
			this.props.shouldFocus !== nextProps.shouldFocus ||
			this.props.value !== nextProps.value ||
			this.state.filled !== nextState.filled ||
			this.state.focusAfterRender !== nextState.focusAfterRender ||
			this.state.isFocused !== nextState.isFocused ||
			this.state.value !== nextState.value ||
			this.props.errorMessage !== nextProps.errorMessage ||
			this.props.locked !== nextProps.locked ||
			this.props.invalidInput !== nextProps.invalidInput ||
			this.props.icon !== nextProps.icon ||
			this.props.taskId !== nextProps.taskId ||
			this.props.customClass !== nextProps.customClass ||
			this.props.suffix !== nextProps.suffix;
		return shouldUpdate;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.focusAfterRender || (prevProps.shouldFocus !== this.props.shouldFocus && this.props.shouldFocus)) {
			this._input_field_.focus();
			this.setState({focusAfterRender: false});
		}

		if (this.props.selectInput) {
			this.selectInput();
		}
	}

	handleFocus(e) {
		if (this.props.readOnly) {
			return;
		}
		this.setState({isFocused: true});
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
		/*if (this._input_field_) {
			this._input_field_.select();
		}*/
	}

	focus() {
		this._input_field_.focus();
	}

	selectInput() {
		this._input_field_.select();
	}

	handleBlur(e) {
		this.setState({isFocused: false, filled: !this.props.invalidInput && this.state.value && this.state.value !== ''});
		if (this.props.type === 'email' && this.props.checkEmailValidity) {
			const valid = this._input_field_.checkValidity();
			this.props.checkEmailValidity(valid);
			if (this.props.onBlur) {
				this.props.onBlur(e, valid);
			}
		} else if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	}

	handleChange(e) {
		this.setState({value: e.target.value});
		if (this.props.type === 'number') {
			const value = this.props.validateHours
				? util.getMinuteEstimate(this._input_field_.value)
				: this._input_field_.value;
			if (value > 2147483646 || value < -2147483646) {
				return;
			}
		}
		if (this.props.onChange) {
			this.props.onChange(e.target.value);
		}
	}

	handleChangeFormatted(values, e) {
		if (!this.props.locked && this.props.onChange) {
			this.props.onChange(values.floatValue || null);
		}
	}

	handleOnClick(e) {
		e.stopPropagation();
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}

	handleKeyPress(e, target) {
		if (this.props.onKeyPress) {
			this.props.onKeyPress(target);
		}
		if (this.props.onEnter && e.key === 'Enter') {
			this.props.onEnter();
		}
		if (this.props.blurOnEnter && e.key === 'Enter') {
			this._input_field_.blur();
		}
	}

	setFocusAfterRender(value) {
		this.setState({focusAfterRender: value});
	}

	handleListCellClick(e) {
		e.stopPropagation();
		this.handleFocus(e);
	}

	render() {
		const extension = this.props.required ? ' *' : this.props.optional ? ' (optional)' : '';
		return (
			<div
				key={this.props.innerKey}
				className={
					'input-container-v2' +
					(this.props.growable ? ' growable' : '') +
					(this.props.negative ? ' negative' : '') +
					(this.props.placeLabelInside ? ' internal' : '') +
					(this.props.shortInput ? ' short' : '') +
					(this.props.customClass ? ' ' + this.props.customClass : '')
				}
				style={this.props.style ? this.props.style : null}
				title={this.props.title || undefined}
				data-userpilot={this.props.userpilot || null}
			>
				{this.props.hideLabel ? null : (
					<label className="input-title" htmlFor={this.props.id ? this.props.id : ''}>
						{this.props.label}
						<span className={this.props.required ? 'required-extension' : ''}>{extension}</span>
					</label>
				)}

				<div
					className={
						'input-value-container' +
						(this.props.useBorderStyle ? ' bordered' : '') +
						(this.props.addSearchIconPlaceholder ? ' search-icon-placeholder' : '') +
						(this.props.currency ? ' currency-placeholder ' + this.props.currency : '') +
						(this.props.invalidInput ? ' invalid' : '') +
						(this.state.isFocused ? ' focused' : '') +
						(this.props.alignTextRight ? ' align-right' : '') +
						(this.props.locked ? ' locked' : '')
					}
				>
					{this.props.taskId ? (
						<div className="task-id" title={this.props.taskId}>
							{this.props.taskId}
						</div>
					) : null}

					{this.props.icon ? (
						<div className="input-icon" data-cy={`${this.props.cy}-icon`}>
							{this.props.icon}
						</div>
					) : null}

					{this.props.type === 'formatted' ? (
						<NumberFormat
							id={this.props.id ? this.props.id : null}
							decimalScale={2}
							value={this.props.value}
							thousandSeparator={this.props.language === 'ENGLISH_EU' ? '.' : ','}
							decimalSeparator={this.props.language === 'ENGLISH_EU' ? ',' : '.'}
							onFocus={this.handleFocus.bind(this)}
							onValueChange={this.handleChangeFormatted.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							placeholder={this.props.placeholder}
							className="input-value"
							disabled={this.props.locked}
							data-cy={this.props.cy}
						/>
					) : this.props.type === 'number' ? (
						<input
							id={this.props.id ? this.props.id : null}
							ref={input => (this._input_field_ = input)}
							type={this.props.type}
							className="input-value"
							step={this.props.step ? this.props.step : 'any'}
							onFocus={this.handleFocus.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							disabled={this.props.locked ? this.props.locked : false}
							placeholder={this.props.placeholder}
							onChange={this.handleChange.bind(this)}
							value={this.props.value}
							onClick={this.handleOnClick.bind(this)}
							autoFocus={this.props.autoFocus}
							onKeyPress={this.handleKeyPress.bind(this)}
							max={this.props.maxNumber !== undefined ? this.props.maxNumber : 2147483646}
							min={this.props.minNumber !== undefined ? this.props.minNumber : -2147483646}
							readOnly={this.props.readOnly}
							data-cy={this.props.cy}
							maxLength={this.props.maxLength}
							autoComplete="off"
							onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : undefined}
							onKeyUp={this.props.onKeyUp ? this.props.onKeyUp : undefined}
						/>
					) : this.props.autosize ? (
						<AutosizeInput
							id={this.props.id ? this.props.id : null}
							ref={input => (this._input_field_ = input)}
							type={this.props.type}
							className="input-value"
							onFocus={this.handleFocus.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							disabled={this.props.locked ? this.props.locked : false}
							placeholder={this.props.placeholder}
							placeholderIsMinWidth={this.props.placeholderIsMinWidth || false}
							onChange={this.handleChange.bind(this)}
							value={this.props.value}
							onClick={this.handleOnClick.bind(this)}
							autoFocus={this.props.autoFocus}
							onKeyPress={this.handleKeyPress.bind(this)}
							maxLength={this.props.maxLength ? this.props.maxLength : 191}
							data-cy={this.props.cy}
						/>
					) : (
						<input
							id={this.props.id ? this.props.id : null}
							ref={input => (this._input_field_ = input)}
							type={this.props.type}
							className={'input-value' + (this.props.locked ? ' disabled' : '')}
							onFocus={this.handleFocus.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							disabled={this.props.locked ? this.props.locked : false}
							placeholder={this.props.placeholder}
							onChange={this.handleChange.bind(this)}
							value={this.props.value}
							onClick={this.handleOnClick.bind(this)}
							autoFocus={this.props.autoFocus}
							onKeyPress={this.handleKeyPress.bind(this)}
							maxLength={this.props.maxLength}
							readOnly={this.props.readOnly}
							data-cy={this.props.cy}
							autoComplete="off"
							onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : undefined}
							onKeyUp={this.props.onKeyUp ? this.props.onKeyUp : undefined}
						/>
					)}
					{this.props.suffix ? <div className="input-value-unit">{this.props.suffix}</div> : null}
				</div>
				{this.props.invalidInput && this.props.errorMessage ? (
					<span className="error-message" data-cy={'error-message'}>
						{this.props.errorMessage}
					</span>
				) : null}
			</div>
		);
	}
}

InputField.propTypes = {
	id: PropTypes.string,
	useListStyling: PropTypes.bool,
	label: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	locked: PropTypes.bool,
	placeholder: PropTypes.string,
	placeholderIsMinWidth: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onClick: PropTypes.func,
	onFocus: PropTypes.func,
	errorMessage: PropTypes.string,
	invalidInput: PropTypes.bool,
	focusOnRender: PropTypes.bool,
	autoFocus: PropTypes.bool,
	optional: PropTypes.bool,
	required: PropTypes.bool,
	onKeyPress: PropTypes.func,
	onEnter: PropTypes.func,
	shouldFocus: PropTypes.bool,
	validateHours: PropTypes.bool,
	hideLabel: PropTypes.bool,
	autosize: PropTypes.bool,
	maxLength: PropTypes.number,
	readOnly: PropTypes.bool,
	minNumber: PropTypes.number,
	maxNumber: PropTypes.number,
	blurOnEnter: PropTypes.bool,
	handleRef: PropTypes.func,
	suffix: PropTypes.string,
	negative: PropTypes.bool,
	placeLabelInside: PropTypes.bool,
	useBorderStyle: PropTypes.bool,
	outlineStyle: PropTypes.bool,
	addSearchIconPlaceholder: PropTypes.bool,
	customClass: PropTypes.string,
	alignTextRight: PropTypes.bool,
	title: PropTypes.string,
	selectInput: PropTypes.bool, // select content in the input if true
	onKeyDown: PropTypes.func,
	onKeyUp: PropTypes.func,
	icon: PropTypes.element,
	style: PropTypes.object,
};

export default InputField;
