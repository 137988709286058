import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import ChartJsPie from '../../chartjs/chart_js_pie';

class NumberOfTasksDummy extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}
	render() {
		const {formatMessage} = this.props.intl;
		const data = {
			labels: [
				formatMessage({id: 'insights.component.task-number.cards_done'}, {count: 3}),
				formatMessage({id: 'insights.component.task-number.cards_left'}, {count: 5}),
			],
			datasets: [
				{
					data: [3, 5],
					backgroundColor: ['#94D7FD', '#44B4FF'],
				},
			],
		};
		return <ChartJsPie data={data} />;
	}
}
export default injectIntl(NumberOfTasksDummy);
