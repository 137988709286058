import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import DetailsLoader, {detailsLoaderQuery} from '../../loaders/DetailsLoader';
import {LoadMore} from '../../../../../loaders/LoadMore';
import {BudgetEyeOptions} from '../../BudgetPage.EyeOptions';
import {BUDGET_VIEWS, SPECIAL_ROLE} from '../../../../../../constants';
import {BudgetTooltips} from '../../util/BudgetTooltips';
import ExpenseDetailsTable from './ExpenseDetailsTable';
import {filterAggregateFields} from '../../util/BudgetUtils';
import {useRemappingFormatMessage} from '../../../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import ProjectUtil from '../../../../../../forecast-app/shared/util/project_util';
import {hasFeatureFlag} from '../../../../../../forecast-app/shared/util/FeatureUtil';

const ExpenseDetails = ({project, selectedAggregateLevel, selectedViewType, eyeOptionMap}) => {
	const formatMessage = useRemappingFormatMessage();
	const {useBaseline} = project;
	const settings = project.financialSourceSettings;

	const searchQuery = {
		filters: [
			{
				field: 'ROLE',
				operator: 'IN',
				value: SPECIAL_ROLE.EXPENSE,
			},
		],
	};

	const isFixedPrice = ProjectUtil.isAnyKindOfFixedPrice(project);
	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');

	const baselineAggregates = filterAggregateFields(
		project,
		hasFinancialCategoriesUpdate
			? ['baselineRevenue', 'baselineCost', 'baselineProfit', 'baselineMargin']
			: ['baselineRevenue', 'baselineCost', 'baselineProfit']
	);
	const baselineVsActualAggregates = filterAggregateFields(
		project,
		hasFinancialCategoriesUpdate
			? [
					'baselineVsActualRevenueRecognition',
					'baselineVsActualCost',
					'baselineVsActualRevenueProfit',
					'baselineVsActualRevenueMargin',
					'baselineVsBillableActualTimeAndExpenses',
			  ]
			: ['baselineVsBillableActualTimeAndExpenses', 'baselineVsActualCost', 'baselineVsActualProfit']
	);
	const baselineVsForecastAggregates = filterAggregateFields(
		project,
		hasFinancialCategoriesUpdate
			? [
					'baselineVsTotalRevenueAtCompletion',
					'baselineVsTotalCostAtCompletion',
					'baselineVsTotalRevenueProfitAtCompletion',
					'baselineVsTotalRevenueMarginAtCompletion',
					'baselineVsBillableTotalTimeAndExpensesAtCompletion',
			  ]
			: [
					'baselineVsBillableTotalTimeAndExpensesAtCompletion',
					'baselineVsTotalCostAtCompletion',
					'baselineVsTotalProfitAtCompletion',
			  ]
	);
	const actualAggregates = filterAggregateFields(
		project,
		hasFinancialCategoriesUpdate
			? [
					'totalActualRevenueRecognition',
					'actualCost',
					'actualRevenueProfit',
					'actualRevenueMargin',
					'billableActualTimeAndExpenses',
			  ]
			: ['billableActualTimeAndExpenses', 'actualCost', 'actualProfit']
	);
	const planAggregates = filterAggregateFields(
		project,
		hasFinancialCategoriesUpdate
			? [
					'plannedRevenue',
					'plannedCost',
					'plannedRevenueProfit',
					'plannedRevenueMargin',
					'billablePlannedTimeAndExpenses',
			  ]
			: ['billablePlannedTimeAndExpenses', 'plannedCost', 'plannedProfit']
	);
	const planVsActualAggregates = filterAggregateFields(
		project,
		hasFinancialCategoriesUpdate
			? [
					'planVsActualRevenue',
					'planVsActualCost',
					'planVsActualRevenueProfit',
					'planVsActualRevenueMargin',
					'planVsBillableActualTimeAndExpenses',
			  ]
			: ['planVsBillableActualTimeAndExpenses', 'planVsActualCost', 'planVsActualProfit']
	);
	const remainingAggregates = filterAggregateFields(
		project,
		hasFinancialCategoriesUpdate
			? [
					'totalForecastRevenueToComplete',
					'forecastCostToComplete',
					'forecastRevenueProfitToComplete',
					'forecastRevenueMarginToComplete',
					'billableForecastTimeAndExpensesToComplete',
			  ]
			: ['billableForecastTimeAndExpensesToComplete', 'forecastCostToComplete', 'forecastProfitToComplete']
	);
	const forecastAggregates = filterAggregateFields(
		project,
		hasFinancialCategoriesUpdate
			? [
					'totalRevenueRecognition',
					'totalCostAtCompletion',
					'totalRevenueProfitAtCompletion',
					'totalRevenueMarginAtCompletion',
					'billableTotalTimeAndExpensesAtCompletion',
			  ]
			: ['billableTotalTimeAndExpensesAtCompletion', 'totalCostAtCompletion', 'totalProfitAtCompletion']
	);
	const planVsForecastAggregates = filterAggregateFields(
		project,
		hasFinancialCategoriesUpdate
			? [
					'planVsTotalRevenueAtCompletion',
					'planVsTotalCostAtCompletion',
					'planVsTotalRevenueProfitAtCompletion',
					'planVsTotalRevenueMarginAtCompletion',
					'planVsTotalBillableTimeAndExpensesAtCompletion',
			  ]
			: ['planVsTotalBillableTimeAndExpensesAtCompletion', 'planVsTotalCostAtCompletion', 'planVsTotalProfitAtCompletion']
	);

	const aggregates = [
		...baselineAggregates,
		...baselineVsActualAggregates,
		...baselineVsForecastAggregates,
		...actualAggregates,
		...planAggregates,
		...planVsActualAggregates,
		...remainingAggregates,
		...forecastAggregates,
		...planVsForecastAggregates,
	];

	return (
		<>
			<LoadMore
				key="query-render-details"
				query={detailsLoaderQuery}
				variables={{
					projectId: project.id,
					startYear: project.projectFirstDateYear,
					startMonth: project.projectFirstDateMonth,
					startDay: project.projectFirstDateDay,
					endYear: project.projectLastDateYear,
					endMonth: project.projectLastDateMonth,
					endDay: project.projectLastDateDay,
					searchQuery: searchQuery,
					aggregateLevel: selectedAggregateLevel,
					aggregates: aggregates,
				}}
				loader={<DetailsLoader aggregateLevel={selectedAggregateLevel} aggregates={aggregates} />}
			>
				{({data}) => {
					return (
						<>
							{useBaseline && eyeOptionMap[BudgetEyeOptions.BASELINE] && (
								<ExpenseDetailsTable
									key={`baseline-${selectedAggregateLevel}`}
									cy={'details-baseline'}
									title={formatMessage({id: 'baseline.baseline'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									data={data}
									aggregates={baselineAggregates}
									selectedViewType={selectedViewType}
									searchQuery={searchQuery}
									tooltips={BudgetTooltips.BaselineTooltips(
										formatMessage,
										BUDGET_VIEWS.MONEY,
										selectedViewType,
										undefined,
										undefined,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
							{eyeOptionMap[BudgetEyeOptions.PLAN] ? (
								<ExpenseDetailsTable
									key={`plan-${selectedAggregateLevel}`}
									cy={'details-planned'}
									title={formatMessage({id: 'common.planned'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={planAggregates}
									selectedViewType={selectedViewType}
									data={data}
									searchQuery={searchQuery}
									tooltips={BudgetTooltips.PlanTooltips(
										formatMessage,
										BUDGET_VIEWS.MONEY,
										selectedViewType,
										isFixedPrice,
										hasFinancialCategoriesUpdate
									)}
								/>
							) : (
								''
							)}
							{eyeOptionMap[BudgetEyeOptions.ACTUAL] ? (
								<ExpenseDetailsTable
									key={`actual-${selectedAggregateLevel}`}
									cy={'details-actual'}
									title={formatMessage({id: 'project_budget.actual'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={actualAggregates}
									selectedViewType={selectedViewType}
									data={data}
									searchQuery={searchQuery}
									tooltips={BudgetTooltips.ActualTooltips(
										formatMessage,
										BUDGET_VIEWS.MONEY,
										selectedViewType,
										settings,
										hasFinancialCategoriesUpdate
									)}
								/>
							) : (
								''
							)}
							{eyeOptionMap[BudgetEyeOptions.REMAINING] ? (
								<ExpenseDetailsTable
									key={`remaining-${selectedAggregateLevel}`}
									cy={'details-forecast_to_complete'}
									title={formatMessage({
										id: 'project_budget.forecast_to_complete',
									})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={remainingAggregates}
									selectedViewType={selectedViewType}
									data={data}
									searchQuery={searchQuery}
									tooltips={BudgetTooltips.RemainingTooltips(
										formatMessage,
										BUDGET_VIEWS.MONEY,
										selectedViewType,
										settings,
										hasFinancialCategoriesUpdate
									)}
								/>
							) : (
								''
							)}
							{eyeOptionMap[BudgetEyeOptions.FORECAST] ? (
								<ExpenseDetailsTable
									key={`forecast-${selectedAggregateLevel}`}
									cy={'details-total_at_completion'}
									title={formatMessage({
										id: 'project_budget.total_at_completion',
									})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={forecastAggregates}
									selectedViewType={selectedViewType}
									data={data}
									searchQuery={searchQuery}
									tooltips={BudgetTooltips.ForecastTooltips(
										formatMessage,
										BUDGET_VIEWS.MONEY,
										settings,
										hasFinancialCategoriesUpdate
									)}
								/>
							) : (
								''
							)}
							{useBaseline && eyeOptionMap[BudgetEyeOptions.BASELINE_VS_ACTUAL] && (
								<ExpenseDetailsTable
									key={`baseline-vs-actual-${selectedAggregateLevel}`}
									cy={'details-baseline_vs_actual'}
									title={formatMessage({id: 'project_budget.baseline_vs_actual'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									data={data}
									aggregates={baselineVsActualAggregates}
									selectedViewType={selectedViewType}
									searchQuery={searchQuery}
									tooltips={BudgetTooltips.BaselineVsActualTooltips(
										formatMessage,
										BUDGET_VIEWS.MONEY,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
							{useBaseline && eyeOptionMap[BudgetEyeOptions.BASELINE_VS_FORECAST] && (
								<ExpenseDetailsTable
									key={`baseline-vs-forecast-${selectedAggregateLevel}`}
									cy={'details-baseline_vs_forecast'}
									title={formatMessage({id: 'project_budget.baseline_vs_forecast'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									data={data}
									aggregates={baselineVsForecastAggregates}
									selectedViewType={selectedViewType}
									searchQuery={searchQuery}
									tooltips={BudgetTooltips.BaselineVsForecastTooltips(
										formatMessage,
										BUDGET_VIEWS.MONEY,
										hasFinancialCategoriesUpdate
									)}
								/>
							)}
							{eyeOptionMap[BudgetEyeOptions.PLAN_VS_ACTUAL] ? (
								<ExpenseDetailsTable
									key={`actualVsPlan-${selectedAggregateLevel}`}
									cy={'details-plan_vs_actual'}
									title={formatMessage({id: 'project_budget.plan_vs_actual'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={planVsActualAggregates}
									selectedViewType={selectedViewType}
									data={data}
									searchQuery={searchQuery}
									tooltips={BudgetTooltips.PlanVsActualTooltips(
										formatMessage,
										BUDGET_VIEWS.MONEY,
										hasFinancialCategoriesUpdate
									)}
								/>
							) : (
								''
							)}
							{eyeOptionMap[BudgetEyeOptions.PLAN_VS_FORECAST] ? (
								<ExpenseDetailsTable
									key={`planVsForecast-${selectedAggregateLevel}`}
									cy={'details-plan_vs_forecast'}
									title={formatMessage({id: 'project_budget.plan_vs_forecast'})}
									project={project}
									selectedAggregateLevel={selectedAggregateLevel}
									aggregates={planVsForecastAggregates}
									selectedViewType={selectedViewType}
									data={data}
									searchQuery={searchQuery}
									tooltips={BudgetTooltips.PlanVsForecastTooltips(
										formatMessage,
										BUDGET_VIEWS.MONEY,
										hasFinancialCategoriesUpdate
									)}
								/>
							) : (
								''
							)}
						</>
					);
				}}
			</LoadMore>
		</>
	);
};

export default createFragmentContainer(ExpenseDetails, {
	project: graphql`
		fragment ExpenseDetails_project on ProjectType {
			id
			useBaseline
			budgetType
			defaultPeriodBudgetType
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
			billable
			financialSourceSettings {
				plannedHours
				plannedRevenue
				plannedCost
				forecastHours
				forecastRevenue
				forecastCost
				actualHours
				actualRevenue
				actualCost
			}
			...ExpenseDetailsTable_project
		}
	`,
});
