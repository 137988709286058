/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v7 = [
        (v1 /*: any*/),
        (v3 /*: any*/)
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "startDate",
                "type": "String"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "endDate",
                "type": "String"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": [
                        "company",
                        "expenseCategories"
                    ]
                },
                (v0 /*: any*/),
                (v0 /*: any*/),
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": [
                        "company",
                        "expenseItems"
                    ]
                }
            ]
        },
        "name": "ExpenseManagementPage_viewer",
        "selections": [
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                    (v1 /*: any*/),
                    (v2 /*: any*/),
                    {
                        "alias": "expenseCategories",
                        "args": null,
                        "concreteType": "ExpenseCategoryTypeConnection",
                        "kind": "LinkedField",
                        "name": "__Company_expenseCategories_connection",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ExpenseCategoryTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ExpenseCategory",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v1 /*: any*/),
                                            (v3 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "disabled",
                                                "storageKey": null
                                            },
                                            (v4 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    (v5 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            (v6 /*: any*/)
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": "expenseItems",
                        "args": null,
                        "concreteType": "ExpenseItemTypeConnection",
                        "kind": "LinkedField",
                        "name": "__Company_expenseItems_connection",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ExpenseItemTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ExpenseItem",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v1 /*: any*/),
                                            (v3 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "cost",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "costBaseCurrency",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "price",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "priceBaseCurrency",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "quantity",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "approved",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "billable",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "partOfFixedPrice",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "invoiced",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "expenseYear",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "expenseMonth",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "expenseDay",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": "files",
                                                "args": null,
                                                "concreteType": "FileTypeConnection",
                                                "kind": "LinkedField",
                                                "name": "__ExpenseItem_files_connection",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "FileTypeEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "File",
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v1 /*: any*/),
                                                                    (v3 /*: any*/),
                                                                    (v4 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            (v5 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    (v6 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Person",
                                                "kind": "LinkedField",
                                                "name": "person",
                                                "plural": false,
                                                "selections": [
                                                    (v1 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "fullName",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "profilePictureId",
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ProjectType",
                                                "kind": "LinkedField",
                                                "name": "project",
                                                "plural": false,
                                                "selections": [
                                                    (v1 /*: any*/),
                                                    (v3 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "projectColor",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "companyProjectId",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "customProjectId",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "ProjectStatus",
                                                        "kind": "LinkedField",
                                                        "name": "currentProjectStatus",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "color",
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Client",
                                                        "kind": "LinkedField",
                                                        "name": "client",
                                                        "plural": false,
                                                        "selections": (v7 /*: any*/),
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "budgetType",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "defaultPeriodBudgetType",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": [
                                                            {
                                                                "kind": "Literal",
                                                                "name": "contactsOnly",
                                                                "value": true
                                                            },
                                                            {
                                                                "kind": "Literal",
                                                                "name": "first",
                                                                "value": 1000
                                                            }
                                                        ],
                                                        "concreteType": "ProjectPersonTypeConnection",
                                                        "kind": "LinkedField",
                                                        "name": "projectPersons",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ProjectPersonTypeEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "ProjectPerson",
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "isContactPerson",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "concreteType": "Person",
                                                                                "kind": "LinkedField",
                                                                                "name": "person",
                                                                                "plural": false,
                                                                                "selections": [
                                                                                    (v1 /*: any*/)
                                                                                ],
                                                                                "storageKey": null
                                                                            }
                                                                        ],
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": "projectPersons(contactsOnly:true,first:1000)"
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "RateCard",
                                                        "kind": "LinkedField",
                                                        "name": "rateCard",
                                                        "plural": false,
                                                        "selections": [
                                                            (v1 /*: any*/),
                                                            (v2 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "fullAccessToProject",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "readOnlyAccess",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": "fixedPriceLocks",
                                                        "args": null,
                                                        "concreteType": "ProjectFixedPriceLockTypeConnection",
                                                        "kind": "LinkedField",
                                                        "name": "__Project_fixedPriceLocks_connection",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "ProjectFixedPriceLockTypeEdge",
                                                                "kind": "LinkedField",
                                                                "name": "edges",
                                                                "plural": true,
                                                                "selections": [
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "ProjectFixedPriceLockType",
                                                                        "kind": "LinkedField",
                                                                        "name": "node",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            (v1 /*: any*/),
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "startDate",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "endDate",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "locked",
                                                                                "storageKey": null
                                                                            },
                                                                            (v4 /*: any*/)
                                                                        ],
                                                                        "storageKey": null
                                                                    },
                                                                    (v5 /*: any*/)
                                                                ],
                                                                "storageKey": null
                                                            },
                                                            (v6 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ExpenseCategory",
                                                "kind": "LinkedField",
                                                "name": "category",
                                                "plural": false,
                                                "selections": (v7 /*: any*/),
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "PhaseType",
                                                "kind": "LinkedField",
                                                "name": "phase",
                                                "plural": false,
                                                "selections": (v7 /*: any*/),
                                                "storageKey": null
                                            },
                                            (v4 /*: any*/)
                                        ],
                                        "storageKey": null
                                    },
                                    (v5 /*: any*/)
                                ],
                                "storageKey": null
                            },
                            (v6 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ExpenseManagementFilterButton_viewer"
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '3900b73f61a3a56249dbfe34cea43933';
export default node;
