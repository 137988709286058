import Item from '../../canvas-timeline/canvas_timeline_item';
import {
	drawLine,
	drawRectangle,
	drawTimelineGraphBottomBar,
	ITEM_TYPE,
	TIMELINE_GRAPH_BACKGROUND_COLOR,
	TIMELINE_GRAPH_BAR_ELEMENT_TYPE,
	TIMELINE_GRAPH_BAR_HIGHLIGHTER_HEIGHT,
	TIMELINE_GRAPH_COLORS,
	TIMELINE_GRAPH_ENTITY_TYPE,
	TIMELINE_GRAPH_GROUP_BOTTOM_BAR_BORDER_WIDTH,
	TIMELINE_GRAPH_GROUP_BOTTOM_BAR_HEIGHT,
	TIMELINE_GRAPH_HIGHLIGHTER_TYPE,
	TIMELINE_GRAPH_ITEM_BAR_ELEMENT_BORDER_THICKNESS,
	TIMELINE_GRAPH_ITEM_BAR_ELEMENT_SPACING,
	TIMELINE_GRAPH_LINE_TYPE,
	TIMELINE_GRAPH_LINE_WIDTH,
	TIMELINE_GRAPH_MAX_HEIGHT_PERCENTAGE,
	TOOLTIP_DISPLAY_DELAY_MS,
} from '../../canvas-timeline/canvas_timeline_util';
import Util from '../../../../forecast-app/shared/util/util';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';

class TimelineGraphItem extends Item {
	constructor(pageComponent, data) {
		super(pageComponent, ITEM_TYPE.TIMELINE_GRAPH_ITEM, data);

		this.updateWithData({
			...data,
			onMouseEnter: this.onTimelineGraphItemMouseEnter.bind(this),
			onMouseLeave: this.onTimelineGraphItemMouseLeave.bind(this),
		});
	}

	getTooltipData() {
		const canvasTooltipData = {};

		const {data} = this;
		const {sectionValues, overAllocatedRoles, getSchedulingOptions} = data;
		const {timeline, props} = this.pageComponent;
		const {intl} = props;
		const schedulingOptions = getSchedulingOptions();
		const {calcWin, hideSoft, hideHard} = schedulingOptions;

		// sub title
		canvasTooltipData.subTitle = timeline.getStepLabelFromCanvasDate(this.startDate);

		// items
		canvasTooltipData.items = [];

		const totalAvailability = sectionValues[TIMELINE_GRAPH_LINE_TYPE.TOTAL_AVAILABILITY]?.current || 0;
		const softAllocations = sectionValues[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.SOFT_ALLOCATION]?.value || 0;
		const peopleAllocations = sectionValues[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.ALLOCATION]?.value || 0;
		const placeholderAllocations = sectionValues[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.PLACEHOLDER]?.value || 0;
		const remaining = totalAvailability - softAllocations - peopleAllocations - placeholderAllocations;

		const numberPrefix = calcWin ? '*' : '';

		// TOTAL AVAILABILITY
		if (sectionValues[TIMELINE_GRAPH_LINE_TYPE.TOTAL_AVAILABILITY]) {
			canvasTooltipData.items.push({
				subTitle: intl.formatMessage({id: 'scheduling.graph.total_availability'}),
				values: [Util.convertMinutesToFullHour(totalAvailability, intl)],
			});
		}

		// PEOPLE ALLOCATION HARD
		if (!hideHard && sectionValues[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.ALLOCATION]) {
			canvasTooltipData.items.push({
				subTitle: !hideSoft
					? intl.formatMessage({id: 'scheduling.graph.peoples_hard_allocations_with_parentes'})
					: intl.formatMessage({id: 'scheduling.graph.peoples_allocations'}),
				values: [`${Util.convertMinutesToFullHour(peopleAllocations, intl)}`],
			});
		}

		// PEOPLE ALLOCATION SOFT
		if (!hideSoft && sectionValues[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.SOFT_ALLOCATION]) {
			canvasTooltipData.items.push({
				subTitle: intl.formatMessage({id: 'scheduling.graph.peoples_soft_allocations_with_parentes'}),
				values: [numberPrefix + `${Util.convertMinutesToFullHour(softAllocations, intl)}`],
			});
		}

		// PLACEHOLDER
		if (sectionValues[TIMELINE_GRAPH_BAR_ELEMENT_TYPE.PLACEHOLDER]) {
			canvasTooltipData.items.push({
				subTitle: intl.formatMessage({id: 'scheduling.placeholder_allocations'}),
				values: [numberPrefix + `${Util.convertMinutesToFullHour(placeholderAllocations, intl)}`],
			});
		}

		// REMAINING
		canvasTooltipData.items.push({
			subTitle: intl.formatMessage({id: 'scheduling.graph.remaining'}),
			values: remaining >= 0 ? [numberPrefix + `${Util.convertMinutesToFullHour(remaining, intl)}`] : null,
			valueWarnings:
				remaining < 0
					? [
							{
								value: null,
								warning: numberPrefix + `${Util.convertMinutesToFullHour(remaining, intl)}`,
							},
					  ]
					: null,
		});

		// ROLES OVER ALLOCATED
		if (sectionValues[TIMELINE_GRAPH_HIGHLIGHTER_TYPE.ROLE_OVERALLOCATED] && overAllocatedRoles?.length > 0) {
			const maxOverAllocatedRolesDisplayed = 4;
			let valueWarnings = [];
			let restOverAllocatedMinutes = 0;

			const displayAllOverAllocatedRoles = overAllocatedRoles.length <= maxOverAllocatedRolesDisplayed;

			overAllocatedRoles.forEach(overAllocatedRole => {
				const shouldDisplay = displayAllOverAllocatedRoles || valueWarnings.length < maxOverAllocatedRolesDisplayed - 1;

				if (shouldDisplay) {
					valueWarnings.push({
						value: `${overAllocatedRole.name} ${intl.formatMessage({id: 'common.by'})}`,
						warning: numberPrefix + Util.convertMinutesToFullHour(overAllocatedRole.value, intl),
					});
				} else {
					restOverAllocatedMinutes += overAllocatedRole.value;
				}
			});

			if (restOverAllocatedMinutes > 0) {
				valueWarnings.push({
					value: '...',
					warning: Util.convertMinutesToFullHour(restOverAllocatedMinutes, intl),
				});
			}

			canvasTooltipData.items.push({
				subTitle: intl.formatMessage({id: 'scheduling.graph.roles_overallocated'}),
				valueWarnings,
			});
		}

		return canvasTooltipData;
	}

	onTimelineGraphItemMouseEnter(eventData, event) {
		const {pageComponent} = this;
		const {timeline} = pageComponent;

		const offsetTop = timeline.getCanvasOffset().top;
		const canvasTooltipData = this.getTooltipData();

		pageComponent.setState({
			canvasTooltipX: event.clientX,
			canvasTooltipY: event.clientY - offsetTop,
			canvasTooltipData,
			showCanvasTooltip: false,
		});

		setTimeout(() => {
			pageComponent.setState({
				showCanvasTooltip: true,
				canvasTooltipX: event.clientX,
				canvasTooltipY: event.clientY - offsetTop,
			});
		}, TOOLTIP_DISPLAY_DELAY_MS);
	}

	onTimelineGraphItemMouseLeave() {
		this.pageComponent.setState({
			showCanvasTooltip: false,
			canvasTooltipData: null,
			canvasTooltipX: null,
			canvasTooltipY: null,
		});
	}

	containsOnlyValidSections(sections) {
		const entities = [
			...Object.values(TIMELINE_GRAPH_LINE_TYPE),
			...Object.values(TIMELINE_GRAPH_HIGHLIGHTER_TYPE),
			...Object.values(TIMELINE_GRAPH_BAR_ELEMENT_TYPE),
		];

		return !sections.find(section => !entities.includes(section.entity));
	}

	getGraphContentOrigin(origin, value) {
		return origin - TIMELINE_GRAPH_GROUP_BOTTOM_BAR_HEIGHT - TIMELINE_GRAPH_GROUP_BOTTOM_BAR_BORDER_WIDTH - value;
	}

	getMaxValueHeight(graphHeight, value) {
		return this.getGraphContentOrigin(graphHeight, value) * (TIMELINE_GRAPH_MAX_HEIGHT_PERCENTAGE / 100);
	}

	getGraphLineYPositionDifference(valueDifference, yPerValue) {
		return valueDifference ? valueDifference * yPerValue * -1 : 0;
	}

	getGraphLineYPosition(maximumGraphValue, value, y, graphHeight, valueDifference) {
		const yPerValue = this.getMaxValueHeight(graphHeight, TIMELINE_GRAPH_LINE_WIDTH) / maximumGraphValue;
		const yOrigin = this.getGraphContentOrigin(y, TIMELINE_GRAPH_LINE_WIDTH);
		return yOrigin + graphHeight - value * yPerValue + this.getGraphLineYPositionDifference(valueDifference, yPerValue);
	}

	getGraphBarElementHeight(maximumGraphValue, y, graphHeight, value) {
		const heightPerValue = this.getMaxValueHeight(graphHeight, 0) / maximumGraphValue;
		return value * heightPerValue;
	}

	drawBarHighlighter(canvasContext, section, x, y, width) {
		const options = {
			backgroundColor: TIMELINE_GRAPH_COLORS[section.entity].STROKE,
		};

		drawRectangle(canvasContext, x, y, width, TIMELINE_GRAPH_BAR_HIGHLIGHTER_HEIGHT, options);
	}

	drawGraphLine(canvasContext, section, x, y, width, graphHeight, value, maximumGraphValue) {
		const xCenter = x + width / 2;
		const xEnd = x + width;

		const previousValue = typeof value.previous === 'number' ? value.previous : value.current;
		const nextValue = typeof value.next === 'number' ? value.next : value.current;

		const yStartValueDifference = (value.current - previousValue) / 2;
		const yEndValueDifference = (value.current - nextValue) / 2;

		const yStart = this.getGraphLineYPosition(maximumGraphValue, previousValue, y, graphHeight, yStartValueDifference);
		const yCenter = this.getGraphLineYPosition(maximumGraphValue, value.current, y, graphHeight, null);
		const yEnd = this.getGraphLineYPosition(maximumGraphValue, nextValue, y, graphHeight, yEndValueDifference);

		const lineOptions = {
			color: TIMELINE_GRAPH_COLORS[section.entity].STROKE,
			lineWidth: TIMELINE_GRAPH_LINE_WIDTH,
		};

		drawLine(canvasContext, x, yStart, xCenter, yCenter, lineOptions);
		drawLine(canvasContext, xCenter, yCenter, xEnd, yEnd, lineOptions);
	}

	drawBarElement(
		canvasContext,
		section,
		x,
		y,
		value,
		width,
		previousSection,
		nextSection,
		graphHeight,
		maximumGraphValue,
		sectionValues
	) {
		const sectionOptions = TIMELINE_GRAPH_COLORS[section.entity];
		const backgroundOptions = {
			backgroundColor: sectionOptions.BACKGROUND,
		};

		const barElementHeight = this.getGraphBarElementHeight(maximumGraphValue, y, graphHeight, value);

		y -= barElementHeight;

		drawRectangle(canvasContext, x, y, width, barElementHeight, backgroundOptions);

		const displayBorder = sectionOptions.BORDER && !sectionOptions.HIDE_BAR_ELEMENT_BORDER;
		if (displayBorder) {
			const borderOptions = {
				color: sectionOptions.BORDER,
				lineWidth: TIMELINE_GRAPH_ITEM_BAR_ELEMENT_BORDER_THICKNESS,
			};

			const isNextSectionBarHighlighter = nextSection
				? nextSection.graphType === TIMELINE_GRAPH_ENTITY_TYPE.BAR_HIGHLIGHTER
				: false;
			const displayingNextSectionHighlighter = isNextSectionBarHighlighter
				? sectionValues[nextSection.entity]?.shouldHighlight === true
				: false;

			const displayTopBorder = !nextSection || !isNextSectionBarHighlighter || !displayingNextSectionHighlighter;
			const displayBottomBorder = !previousSection;

			const bottomBorderOffset = displayBottomBorder ? borderOptions.lineWidth : 0;

			const xLeft = x + borderOptions.lineWidth;
			const xRight = x + width - borderOptions.lineWidth;
			const yBottom = y + barElementHeight - bottomBorderOffset;
			const yTop = y;

			if (sectionOptions.IS_BORDER_DASHED) {
				borderOptions.dashedLine = sectionOptions.IS_BORDER_DASHED;
				borderOptions.dashedLinePattern = [4, 4];
			}

			// left border
			drawLine(canvasContext, xLeft, yTop, xLeft, yBottom, borderOptions);

			// right border
			drawLine(canvasContext, xRight, yTop, xRight, yBottom, borderOptions);

			// top border
			if (displayTopBorder) {
				drawLine(canvasContext, xLeft, yTop, xRight, yTop, borderOptions);
			}

			// bottom border
			if (displayBottomBorder) {
				drawLine(canvasContext, xLeft, yBottom, xRight, yBottom, borderOptions);
			}
		}

		return barElementHeight;
	}

	getBarElementsPreviousSection(sections, startingIndex, sectionValues) {
		if (!sections.length || startingIndex > sections.length - 1) {
			return null;
		}

		for (let index = startingIndex; index < sections.length; index++) {
			const section = sections[index];

			const isBarElement = TIMELINE_GRAPH_BAR_ELEMENT_TYPE[section.entity];
			const sectionValue = sectionValues[section.entity]?.value;
			const hasValueAboveZero = sectionValue ? sectionValue > 0 : false;

			if (isBarElement && hasValueAboveZero) {
				return section;
			}
		}

		return null;
	}

	getBarElementsNextSection(sections, startingIndex, sectionValues) {
		if (!sections.length || startingIndex < 1) {
			return null;
		}

		for (let index = startingIndex; index >= 0; index--) {
			const section = sections[index];

			const isBarHighlighter = TIMELINE_GRAPH_HIGHLIGHTER_TYPE[section.entity];
			if (isBarHighlighter) {
				return section;
			}

			const isBarElement = TIMELINE_GRAPH_BAR_ELEMENT_TYPE[section.entity];
			const sectionValue = sectionValues[section.entity]?.value;
			const hasValueAboveZero = sectionValue ? sectionValue > 0 : false;

			if (isBarElement && hasValueAboveZero) {
				return section;
			}
		}

		return null;
	}

	drawSections(canvasContext, x, y, height, width, sections, sectionValues, maximumGraphValue) {
		const barElementX = x + TIMELINE_GRAPH_ITEM_BAR_ELEMENT_SPACING;
		const barElementWidth = width - TIMELINE_GRAPH_ITEM_BAR_ELEMENT_SPACING * 2;

		let sectionY = y + height - TIMELINE_GRAPH_GROUP_BOTTOM_BAR_HEIGHT - TIMELINE_GRAPH_GROUP_BOTTOM_BAR_BORDER_WIDTH * 2;

		for (let index = sections.length - 1; index >= 0; index--) {
			const section = sections[index];

			switch (section.graphType) {
				case TIMELINE_GRAPH_ENTITY_TYPE.LINE:
					const lineValue = sectionValues[section.entity];
					this.drawGraphLine(canvasContext, section, x, y, width, height, lineValue, maximumGraphValue);
					break;
				case TIMELINE_GRAPH_ENTITY_TYPE.BAR_ELEMENT:
					const barElementValue = sectionValues[section.entity]?.value || 0;

					if (barElementValue > 0) {
						const previousSection = this.getBarElementsPreviousSection(sections, index + 1, sectionValues);
						const nextSection = this.getBarElementsNextSection(sections, index - 1, sectionValues);

						const barHeight = this.drawBarElement(
							canvasContext,
							section,
							barElementX,
							sectionY,
							barElementValue,
							barElementWidth,
							previousSection,
							nextSection,
							height,
							maximumGraphValue,
							sectionValues
						);

						sectionY -= barHeight;
					}

					break;
				case TIMELINE_GRAPH_ENTITY_TYPE.BAR_HIGHLIGHTER:
					if (sectionValues[section.entity].shouldHighlight === true) {
						sectionY -= TIMELINE_GRAPH_BAR_HIGHLIGHTER_HEIGHT;

						this.drawBarHighlighter(
							canvasContext,
							section,
							barElementX,
							sectionY,
							barElementWidth,
							height,
							maximumGraphValue
						);
					}
					break;
				default:
					break;
			}
		}
	}

	draw(canvasContext) {
		const {data, height} = this;
		const {x, y, width, sections, sectionValues, maximumGraphValue, eyeOptions, isWeekend} = data;

		const showGraphEyeOption = eyeOptions?.find(option => option.name === 'show-graph');
		const isHiddenByEyeOption = showGraphEyeOption && !showGraphEyeOption.checked;

		if (isHiddenByEyeOption || !this.containsOnlyValidSections(sections)) return;

		// draw background color
		drawRectangle(canvasContext, x + 1, y, width - 2, height, {
			backgroundColor: TIMELINE_GRAPH_BACKGROUND_COLOR,
		});

		if (isWeekend) {
			canvasContext.fillStyle = canvasContext.createPattern(
				cacheManager.getCommonImageSafariWorkaround(COMMON_IMAGE.FREEDAY),
				'repeat'
			);
			canvasContext.fillRect(x, y, width, height - 1);
		}

		this.drawSections(canvasContext, x, y, height, width, sections, sectionValues, maximumGraphValue);

		drawTimelineGraphBottomBar(canvasContext, x, y, height, width);
	}
}

export default TimelineGraphItem;
