import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Person from '../../../forecast-app/shared/components/person/person';
import Util from '../../../forecast-app/shared/util/util';

class PeopleListProjectsDummy extends Component {
	createDummyList(
		id,
		name,
		defaultRole,
		projectRole,
		email,
		labels,
		skills,
		permission,
		todoCards,
		inProgressCards,
		doneCards,
		totalCards,
		remaining,
		reported,
		spend
	) {
		const {formatNumber} = this.props.intl;
		const person = {};
		person.name = <Person key={id} name={name} showName={true} showRole={false} imageSrc={null} imageSize="medium" />;
		person.defaultRole = defaultRole;
		person.projectRole = projectRole;
		person.email = email;
		person.labels = labels;
		person.skills = skills;
		person.permission = permission;
		person.todoCards = formatNumber(todoCards);
		person.inProgressCards = formatNumber(inProgressCards);
		person.doneCards = formatNumber(doneCards);
		person.totalCards = formatNumber(totalCards);
		person.remaining = Util.convertMinutesToFullHour(remaining * 60, this.props.intl);
		person.reported = Util.convertMinutesToFullHour(reported * 60, this.props.intl);
		person.spend = '$' + formatNumber(spend);
		return person;
	}

	render() {
		let people = [];
		people.push(
			this.createDummyList(
				1,
				'Person one',
				'Developer',
				' Developer',
				'mail@mymail.com',
				[
					{label: 'UK Office', color: '#4B9600'},
					{label: 'US Office', color: '#48B8FF'},
				],
				['Java', 'Python'],
				'Controller',
				12,
				20,
				5,
				37,
				40,
				10,
				500
			)
		);
		people.push(
			this.createDummyList(
				2,
				'Person two',
				'Project Manager',
				'Project Manager',
				'mail@mymail.com',
				[
					{label: 'AU Office', color: '#ED7D1A'},
					{label: 'DK Office', color: '#F99693'},
				],
				['SCRUM', 'Agile'],
				'Admin',
				12,
				20,
				5,
				37,
				40,
				10,
				500
			)
		);
		people.push(
			this.createDummyList(
				3,
				'Person three',
				'Developer',
				'Tester',
				'mail@mymail.com',
				[
					{label: 'US Office', color: '#4B9600'},
					{label: 'UK Office', color: '#48B8FF'},
				],
				['Java', 'Python'],
				'Controller',
				12,
				20,
				5,
				37,
				40,
				10,
				500
			)
		);
		return (
			<InsightList
				data={people}
				componentName={this.props.componentName}
				allColumns={this.props.allColumns}
				activeColumns={this.props.activeColumns}
				disableSort="skills"
				scrollElement={this.props.scrollElement}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

PeopleListProjectsDummy.propTypes = {};
export default injectIntl(PeopleListProjectsDummy);
