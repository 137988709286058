import { graphql } from 'react-relay';
export const BaselineEditPageQuery = graphql `
	query BaselineEditPageQuery($projectId: String) {
		viewer {
			actualPersonId
			component(name: "edit_baseline")
			project(id: $projectId) {
				id
			}
			...BaselineEditPage_viewer @arguments(projectId: $projectId)
		}
	}
`;
