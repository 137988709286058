import moment from 'moment';

export const decompressAggregatedData = (aggregatedFinancialNumbersList, aggregates) => {
	const getAggregateLevel = dateString => {
		const dateLength = dateString.length;
		if (dateLength === 4) {
			return 'YEAR';
		} else if (dateLength >= 6 && dateLength <= 7) {
			return 'MONTH';
		} else if (dateLength >= 8 && dateLength <= 10) {
			return 'DAY';
		} else {
			throw new Error(`Unknown date string format: ${dateString}`);
		}
	};

	const getDate = (dateString, aggregateLevel) => {
		if (aggregateLevel === 'YEAR') {
			return moment(dateString, 'YYYY-01-01');
		} else if (aggregateLevel === 'MONTH') {
			return moment(dateString, 'YYYY-MM-01');
		} else if (aggregateLevel === 'DAY') {
			return moment(dateString, 'YYYY-MM-DD');
		} else {
			throw new Error(`Unknown aggregate level: ${aggregateLevel}`);
		}
	};

	const getValues = splitAggregatedFinancialNumbers => {
		const valueObject = {};

		// Add normal aggregates
		for (let i = 0; i < aggregates.length; i++) {
			valueObject[aggregates[i]] = +splitAggregatedFinancialNumbers[i + 2];
		}

		// Check if there are also accumulated values as well
		if (splitAggregatedFinancialNumbers.length === aggregates.length * 2 + 2) {
			for (let i = 0; i < aggregates.length; i++) {
				valueObject[`${aggregates[i]}Accumulated`] = +splitAggregatedFinancialNumbers[i + aggregates.length + 2];
			}
		}

		return valueObject;
	};

	return aggregatedFinancialNumbersList.flatMap(aggregatedFinancialNumbers => {
		const mappedObjects = [];

		const splitAggregatedFinancialNumbers = aggregatedFinancialNumbers.split(',');
		const aggregateLevel = getAggregateLevel(splitAggregatedFinancialNumbers[0]);
		const startDate = getDate(splitAggregatedFinancialNumbers[0], aggregateLevel);
		const endDate = getDate(splitAggregatedFinancialNumbers[1], aggregateLevel);
		while (startDate <= endDate) {
			mappedObjects.push({
				date: startDate.clone(),
				...getValues(splitAggregatedFinancialNumbers),
			});

			startDate.add(1, aggregateLevel);
		}

		return mappedObjects;
	});
};

export function getTotalsForBreakdown(financialNumberTotals, lookupId) {
	for (let i = 0; i < financialNumberTotals.length; i++) {
		if (financialNumberTotals[i].lookupId === lookupId) {
			return {...financialNumberTotals[i].financialNumbers};
		}
	}
}

export function decompressData(
	aggregatedFinancialNumbers,
	financialNumberTotals,
	currency,
	aggregateLevel,
	aggregates,
	returnAccumulatedValues
) {
	const columns = [];
	const rows = [];
	const dateFormat = aggregateLevel === 'MONTH' ? 'MMM YYYY' : 'DD MMM YYYY';

	// Create a row array for each aggregate
	for (let i = 0; i < aggregates.length; i++) {
		rows[i] = {dataArray: []};
		rows[i].currency = currency;
		if (aggregates) {
			rows[i].aggregate = aggregates[i];
		}
	}

	/**
	 * Compressed row structure. A comma seperated list a values
	 *
	 *    START_DATE, END_DATE, AGGREGATE_VALUE_1, AGGREGATE_VALUE_2, AGGREGATE_VALUE_3, AGGREGATE_VALUE_1_ACCUMULATED, AGGREGATE_VALUE_2_ACCUMULATED, AGGREGATE_VALUE_3_ACCUMULATED,...
	 *
	 *    2021-04,2021-07,0,0,0,0,0,0
	 *
	 */
	if (aggregatedFinancialNumbers) {
		for (const compressedRow of Object.values(aggregatedFinancialNumbers)) {
			const compressedArray = compressedRow.split(',');
			const startDateString = compressedArray[0];
			const startDate = moment(startDateString);
			const endDateString = compressedArray[1];
			const endDate = moment(endDateString);
			while (startDate <= endDate) {
				addValuesAggregateRows(rows, compressedArray, aggregates.length, returnAccumulatedValues, startDate);
				columns.push({
					name: startDate.format(dateFormat),
					startDate: startDate.clone(),
				});
				startDate.add(1, aggregateLevel);
			}
		}
	}

	// Add totals to rows
	addTotalsColumn(rows, financialNumberTotals, aggregates, returnAccumulatedValues);

	// Add totals column
	columns.push({
		name: 'Totals',
		startDate: null,
	});

	return {rows: rows, columns: columns};
}

function addTotalsColumn(rows, financialNumberTotals, aggregates, returnAccumulatedValues) {
	if (returnAccumulatedValues) {
		for (let i = 0; i < aggregates.length; i++) {
			rows[i].dataArray.push(rows[i].dataArray[rows[i].dataArray.length - 1]);
		}
	} else if (financialNumberTotals && aggregates.length > 0) {
		for (let i = 0; i < aggregates.length; i++) {
			const totalValueForAggregate = financialNumberTotals[aggregates[i]];
			rows[i].dataArray.push({
				value: parseFloat(totalValueForAggregate),
				startDate: null,
			});
		}
	} else {
		for (let i = 0; i < rows.length; i++) {
			rows[i].dataArray.push(null);
		}
	}
}

function addValuesAggregateRows(rows, compressedArray, numAggregates, useAccumulatedValues, startDate) {
	const startIndex = useAccumulatedValues ? 2 + numAggregates : 2;
	const values = compressedArray.slice(startIndex); //slice away data we do not need (anything before startIndex)
	for (let i = 0; i < numAggregates; i++) {
		rows[i].dataArray.push({
			value: parseFloat(values[i]),
			startDate: startDate.clone(),
		});
	}
}
