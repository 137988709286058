import Styled from 'styled-components/macro';

export const ProjectStatusColor = Styled.div`
		display: flex;
		justify-content: center;
        border-radius: 50%;
        width: 12px;
        height: 12px;
`;

export const TextColumn = Styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
