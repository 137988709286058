import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { Breadcrumbs, BulkSelector, FlexColumn, FlexRow, Heading, Icon, Table, Text, Link, useTableFunctions, } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Styled from 'styled-components';
import { getNotNullishNodes } from '../../forecast-app/shared/util/NotNullPredicate';
import { trackEvent, trackPage } from '../../tracking/amplitude/TrackingV2';
import { isDateInLockedPeriod } from '../../components/new-ui/project/project-budget-v3/util/PeriodLocks';
import PredefinedDateRangePicker from './PredefinedDateRangePicker';
import { dateObjectToIsoDate, getPreviousMonthRange } from '../../forecast-app/shared/util/DateUtil';
import ExpenseManagementFilterButton, { getInitialExpenseManagementFilters } from './ExpenseManagementFilterButton';
import { getFilterFunctions } from '../../forecast-app/shared/components/filters/filter_logic.js';
import { ExpenseManagementTotals } from './ExpenseManagementTotals';
import ExpenseManagementChangeViewButton, { EXPENSE_MANAGEMENT_EYE_OPTIONS } from './ExpenseManagementChangeViewButton';
import { startOfMonth } from 'date-fns';
import Util from '../../forecast-app/shared/util/util';
import ExpenseManagementExportButton from './ExpenseManagementExportButton';
import { useEyeOptions } from '../../forecast-app/shared/hooks/useEyeOptions';
import { EXPENSE_MANAGEMENT_COLUMNS } from './Constants';
import { downloadExpenseFiles } from './ExpenseManagementUtil';
import { showModal, MODAL_TYPE } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import moment from 'moment';
import useMeasureRender from '../../forecast-app/shared/hooks/useMeasureRender';
import { profilePicRectSrc } from '../../directApi';
const GROUP_BY_LOCALSTORAGE_KEY = 'expense-management-group-by';
const PROJECT_CURRENCY_LOCALSTORAGE_KEY = 'expense-management-project-currency';
const EYE_OPTIONS_LOCALSTORAGE_KEY = 'expense-management-eye-options';
const DATE_RANGE_LOCALSTORAGE_KEY = 'expense-management-date-range';
export const getDefaultDateRange = () => {
    const storedDateRange = Util.localStorageGetItem(DATE_RANGE_LOCALSTORAGE_KEY);
    if (storedDateRange) {
        return JSON.parse(storedDateRange);
    }
    const date = new Date();
    const range = getPreviousMonthRange(date);
    return { startDate: dateObjectToIsoDate(range === null || range === void 0 ? void 0 : range.startDate), endDate: dateObjectToIsoDate(range === null || range === void 0 ? void 0 : range.endDate) };
};
const PageWrapper = Styled.div `
  padding: ${({ buyNowTime }) => (buyNowTime ? '64px' : '32px')};
`;
const BreadcrumbsWrapper = Styled.div `
  padding-bottom: 24px;
 `;
const Toolbar = ({ children }) => React.createElement(FlexRow, null, children);
Toolbar.Left = ({ children }) => React.createElement(FlexRow, { gap: 's' }, children);
Toolbar.Right = ({ children }) => (React.createElement(FlexRow, { gap: 's', justifyContent: 'end' }, children));
const getExpenseDate = (expense) => new Date(expense.expenseYear || 0, (expense.expenseMonth || 0) - 1, expense.expenseDay || 0);
export const isLocked = (expense) => {
    var _a, _b;
    const lockedPeriods = getNotNullishNodes((_b = (_a = expense.project) === null || _a === void 0 ? void 0 : _a.fixedPriceLocks) === null || _b === void 0 ? void 0 : _b.edges).filter(period => period.locked);
    return isDateInLockedPeriod(moment(getExpenseDate(expense)), lockedPeriods);
};
const PAGE_NAME = 'Expense Management';
const ExpenseManagementPage = ({ viewer, relay: { refetch }, buyNowTime }) => {
    var _a;
    const { formatMessage } = useIntl();
    const scrollRef = useRef(null);
    useEffect(() => {
        document.title = 'Finance - Expenses';
        trackPage(PAGE_NAME);
    }, []);
    const loadUseProjectCurrency = () => {
        const useProjectCurrencyOption = Util.localStorageGetItem(PROJECT_CURRENCY_LOCALSTORAGE_KEY) === 'true';
        trackEvent('Use Project Currency Option', 'Loaded', {
            value: useProjectCurrencyOption,
        });
        return useProjectCurrencyOption;
    };
    const defaultDateRange = useMemo(() => getDefaultDateRange(), []);
    const [startDate, setStartDate] = useState(defaultDateRange.startDate);
    const [endDate, setEndDate] = useState(defaultDateRange.endDate);
    const [groupings, setGroupings] = useState(() => JSON.parse(Util.localStorageGetItem(GROUP_BY_LOCALSTORAGE_KEY) || '[]'));
    const [filterFunctions, setFilterFunctions] = useState(() => getFilterFunctions(getInitialExpenseManagementFilters()));
    const [useProjectCurrency, setUseProjectCurrency] = useState(() => loadUseProjectCurrency());
    const [tableRef, table] = useTableFunctions();
    const onGroupingsChange = (groupings) => {
        Util.localStorageSetItem(GROUP_BY_LOCALSTORAGE_KEY, JSON.stringify(groupings));
        setGroupings(groupings);
        const groupingObj = groupings.reduce((result, item, index) => {
            result[`level_${index + 1}`] = item;
            return result;
        }, {});
        trackEvent('Group By', 'Changed', Object.assign({ groupBy: groupings }, groupingObj));
    };
    const [eyeOptionMap, setEyeOptionMap] = useState({});
    const [, , eyeOptions, handleEyeOptionsChange] = useEyeOptions(EXPENSE_MANAGEMENT_EYE_OPTIONS, EYE_OPTIONS_LOCALSTORAGE_KEY, undefined, setEyeOptionMap);
    const onUseProjectCurrencyChange = checked => {
        Util.localStorageSetItem(PROJECT_CURRENCY_LOCALSTORAGE_KEY, checked);
        trackEvent('Use Project Currency', checked ? 'Selected' : 'Deselected');
        setUseProjectCurrency(checked);
    };
    const billableText = formatMessage({ id: 'expense_item_modal.billable' });
    const nonBillableText = formatMessage({ id: 'expense_item_modal.non_billable' });
    const approvedText = formatMessage({ id: 'common.approved' });
    const unapprovedText = formatMessage({ id: 'common.unapproved' });
    const companyCurrency = ((_a = viewer.company) === null || _a === void 0 ? void 0 : _a.currency) || '';
    const onDatePickerChange = (onChangeEvent) => {
        setStartDate(onChangeEvent.startDate);
        setEndDate(onChangeEvent.endDate);
        refetch(onChangeEvent);
        Util.localStorageSetItem(DATE_RANGE_LOCALSTORAGE_KEY, JSON.stringify(onChangeEvent));
        trackEvent('Date Picker Dates', 'Changed', {
            startDate: onChangeEvent.startDate,
            endDate: onChangeEvent.endDate,
        });
    };
    const filterByFilters = useCallback((expense) => filterFunctions.expenseFilter(expense) && filterFunctions.projectFilter(expense.project), [filterFunctions]);
    const expenses = useMemo(() => {
        var _a, _b;
        const mapExpenseToRow = (expense) => {
            var _a, _b;
            const expenseDate = getExpenseDate(expense);
            const cost = useProjectCurrency ? expense.cost : expense.costBaseCurrency;
            const price = useProjectCurrency ? expense.price : expense.priceBaseCurrency;
            return Object.assign(Object.assign({}, expense), { totalCost: (expense.quantity || 0) * (cost || 0), totalPrice: expense.billable ? (expense.quantity || 0) * (price || 0) : undefined, billingOption: expense.billable ? billableText : nonBillableText, markup: expense.billable && cost ? Math.round((((price || 0) - cost) / cost) * 100) / 100 : undefined, expenseDate, status: expense.approved ? approvedText : unapprovedText, attachedFiles: getNotNullishNodes((_a = expense.files) === null || _a === void 0 ? void 0 : _a.edges).map(file => file.name || '') || [], locked: expense.invoiced || isLocked(expense), month: startOfMonth(expenseDate), readOnly: !!((_b = expense.project) === null || _b === void 0 ? void 0 : _b.readOnlyAccess) });
        };
        return getNotNullishNodes((_b = (_a = viewer === null || viewer === void 0 ? void 0 : viewer.company) === null || _a === void 0 ? void 0 : _a.expenseItems) === null || _b === void 0 ? void 0 : _b.edges).filter(filterByFilters).map(mapExpenseToRow);
    }, [viewer, filterByFilters, useProjectCurrency]);
    const [selectedRowIds, setSelectedRowIds] = useState([]);
    useMeasureRender(PAGE_NAME, 'Rendered', [expenses]);
    const selectedRows = useMemo(() => expenses.filter(expense => selectedRowIds.includes(expense.id)), [selectedRowIds, expenses]);
    const numLockedRows = useMemo(() => selectedRows.filter(row => row.locked).length, [selectedRows]);
    const totalFilesToDownload = useMemo(() => selectedRows.reduce((total, expense) => { var _a; return total + getNotNullishNodes((_a = expense.files) === null || _a === void 0 ? void 0 : _a.edges).length; }, 0), [selectedRows]);
    const hideBulkAction = numLockedRows > 0 && numLockedRows === selectedRows.length;
    const onSelectionChange = useCallback((rows) => setSelectedRowIds(rows.map(row => row.id)), []);
    const groupingColumnHeader = groupings.length
        ? formatMessage({ id: 'expense_management.grouping' }) +
            ': ' +
            groupings.map(grouping => formatMessage({ id: 'expense_management.column.' + grouping })).join(' / ')
        : formatMessage({ id: 'expense_management.expense_name' });
    const showPersonAvatar = groupings.includes(EXPENSE_MANAGEMENT_COLUMNS.PERSON);
    const initialSorting = [
        { id: EXPENSE_MANAGEMENT_COLUMNS.DATE, desc: true },
        { id: EXPENSE_MANAGEMENT_COLUMNS.MONTH, desc: true },
    ];
    const handleItemDelete = (row) => {
        var _a;
        showModal({
            type: MODAL_TYPE.EXPENSE_MANAGEMENT_DELETE_EXPENSE,
            selectedRow: row,
            companyId: (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.id,
        });
    };
    const openExpenseModal = (expense, clickedOn, openFilesTab) => {
        showModal({
            type: MODAL_TYPE.EXPENSE_ITEM,
            expenseItemId: expense.id,
            projectId: expense.project ? expense.project.id : null,
            locked: expense.locked,
            readOnly: expense.readOnly,
            selectedTab: openFilesTab ? 'files' : undefined,
            onDateChange: () => refetch({ startDate, endDate }),
        });
        trackEvent('Expense Modal By Clicked On ' + clickedOn, 'Viewed', { expenseId: expense.id });
    };
    const selectedLockedRows = useMemo(() => selectedRows.filter(item => item.locked), [selectedRows]);
    const selectedlockedRowIds = useMemo(() => selectedLockedRows.map(row => row.id), [selectedLockedRows]);
    const handleBulkItemDelete = () => {
        var _a;
        if (selectedRows.length < 1)
            return;
        showModal({
            type: MODAL_TYPE.EXPENSE_MANAGEMENT_DELETE_EXPENSES,
            selectedRows,
            companyId: (_a = viewer.company) === null || _a === void 0 ? void 0 : _a.id,
            onSuccessfulDelete: () => {
                table.setSelections(selectedlockedRowIds);
            },
        });
    };
    const openChangeProjectPhaseModal = () => {
        showModal({
            type: MODAL_TYPE.EXPENSE_ITEM_CHANGE_PROJECT_PHASE_MODAL,
            expenseItems: selectedRows,
        });
    };
    const openChangeApprovalModal = () => {
        showModal({
            type: MODAL_TYPE.CHANGE_EXPENSE_APPROVAL,
            expenseItems: selectedRows,
        });
    };
    const openChangeBillableStatusModal = () => {
        showModal({
            type: MODAL_TYPE.CHANGE_EXPENSE_BILLABLE_STATUS,
            expenseItems: selectedRows,
        });
    };
    const openChangeFixedPriceBillingModal = () => {
        showModal({
            type: MODAL_TYPE.CHANGE_EXPENSE_FIXED_PRICE_BILLING,
            expenseItems: selectedRows,
        });
    };
    const openChangeMarkupModal = () => {
        showModal({
            type: MODAL_TYPE.EXPENSE_MANAGEMENT_CHANGE_MARKUP,
            selectedRows,
        });
    };
    const openChangeExpenseCategoryModal = () => {
        showModal({
            type: MODAL_TYPE.CHANGE_EXPENSE_CATEGORY,
            viewer: viewer,
            selectedRows,
        });
    };
    const openChangePersonModal = () => {
        showModal({
            type: MODAL_TYPE.EXPENSE_ITEM_CHANGE_PERSON_MODAL,
            selectedRows,
        });
    };
    const rowCurrency = row => (useProjectCurrency && row.project.rateCard ? row.project.rateCard.currency : companyCurrency);
    return (React.createElement(CustomScrollDiv, { setScrollbarsRef: node => {
            scrollRef.current = node;
        } },
        React.createElement(PageWrapper, { buyNowTime: buyNowTime },
            React.createElement(BreadcrumbsWrapper, null,
                React.createElement(Breadcrumbs, null,
                    React.createElement(Breadcrumbs.Item, { isDisabled: true },
                        React.createElement(FormattedMessage, { id: "settings_finance.menuTitle" })),
                    React.createElement(Breadcrumbs.Item, null,
                        React.createElement(FormattedMessage, { id: "expense_management.title" })))),
            React.createElement(FlexColumn, { gap: 'l' },
                React.createElement(FlexRow, { gap: 's' },
                    React.createElement(Heading, { size: '3' },
                        React.createElement(FormattedMessage, { id: "expense_management.title" })),
                    React.createElement(Link, { href: "https://www.forecast.app/feedback-expense-management-page", size: "1", target: "_blank" }, "Give feedback")),
                React.createElement(Toolbar, null,
                    React.createElement(Toolbar.Left, null,
                        React.createElement(PredefinedDateRangePicker, { startDate: startDate, endDate: endDate, onChange: onDatePickerChange })),
                    React.createElement(Toolbar.Right, null,
                        React.createElement(ExpenseManagementFilterButton, { viewer: viewer, setFilterFunctions: setFilterFunctions }),
                        React.createElement(ExpenseManagementChangeViewButton, { groupings: groupings, onGroupingsChange: onGroupingsChange, useProjectCurrency: useProjectCurrency, onUseProjectCurrencyChange: onUseProjectCurrencyChange, eyeOptions: eyeOptions, onEyeOptionsChange: handleEyeOptionsChange }),
                        React.createElement(ExpenseManagementExportButton, { exportData: table.exportData, includeColumns: [EXPENSE_MANAGEMENT_COLUMNS.NAME, ...groupings] }))),
                React.createElement(ExpenseManagementTotals, { expenses: expenses, currency: companyCurrency }),
                React.createElement(Heading, { size: '4' },
                    React.createElement(FormattedMessage, { id: "expense_management.expense_list" })),
                React.createElement(Table, { "data-cy": 'expenses-table', data: expenses, grouping: groupings, width: '100%', scrollRef: scrollRef, initialSorting: initialSorting, stickyHeader: true, tableRef: tableRef, enableRowSelection: row => !row.original.readOnly, onSelectionChange: onSelectionChange, getRowId: (row) => row.id },
                    React.createElement(Table.GroupingColumn, { header: groupingColumnHeader, defaultColumnKey: 'name', allowSorting: true },
                        React.createElement(Table.TextColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.NAME, accessorKey: 'name', header: formatMessage({ id: 'expense_management.expense_name' }), allowSorting: true, ellipsis: true, icon: row => row.locked && React.createElement(Icon, { icon: 'lockClosed' }), alignIconRight: true, onClick: (row) => () => openExpenseModal(row, 'Expense Name') }),
                        React.createElement(Table.TextColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.CLIENT, accessorKey: 'project.client.name', "data-cy": 'client-name', defaultGroupingValue: formatMessage({ id: 'common.no_client' }), header: formatMessage({ id: 'common.client' }) }),
                        React.createElement(Table.DateColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.MONTH, accessorKey: 'month', "data-cy": 'month', header: formatMessage({ id: 'common.month' }), formatOptions: { month: 'short', year: 'numeric' } })),
                    React.createElement(Table.ColoredIdIconColumn, { visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.PROJECT], accessorKey: 'project.customProjectId', "data-cy": 'project-id', header: 'P-ID', exportFormatter: value => value, renderProps: row => ({
                            color: row.project.projectColor,
                            text: row.project.customProjectId,
                        }) }),
                    React.createElement(Table.TextColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.PROJECT, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.PROJECT], accessorKey: 'project.name', "data-cy": 'project-name', header: formatMessage({ id: 'common.project' }), allowSorting: true, ellipsis: true }),
                    React.createElement(Table.TextColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.CATEGORY, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.CATEGORY], accessorKey: 'category.name', "data-cy": 'category', header: formatMessage({ id: 'insights.category' }), allowSorting: true, ellipsis: true }),
                    React.createElement(Table.CurrencyColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.TOTAL_COST, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.TOTAL_COST], accessorKey: 'totalCost', "data-cy": 'total-cost', header: formatMessage({ id: 'expense_item_modal.total_cost' }), rowCurrency: rowCurrency, allowSorting: true }),
                    React.createElement(Table.TextColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.BILLING_OPTION, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.BILLING_OPTION], accessorKey: 'billingOption', header: formatMessage({ id: 'expense_management.billing_option' }), allowSorting: true }),
                    React.createElement(Table.CurrencyColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.TOTAL_PRICE, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.TOTAL_PRICE], accessorKey: 'totalPrice', "data-cy": 'total-price', header: formatMessage({ id: 'expense_item_modal.total_price' }), rowCurrency: rowCurrency, allowSorting: true }),
                    React.createElement(Table.PercentageColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.MARKUP, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.MARKUP], accessorKey: 'markup', "data-cy": 'markup', header: formatMessage({ id: 'common.markup' }), allowSorting: true }),
                    showPersonAvatar ? (React.createElement(Table.AvatarColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.PERSON, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.PERSON], accessorKey: 'person.fullName', groupingValue: row => { var _a; return (_a = row.person) === null || _a === void 0 ? void 0 : _a.id; }, image: row => { var _a, _b; return ((_a = row.person) === null || _a === void 0 ? void 0 : _a.profilePictureId) ? profilePicRectSrc((_b = row.person) === null || _b === void 0 ? void 0 : _b.profilePictureId) : undefined; }, defaultGroupingValue: formatMessage({ id: 'common.no_person' }), "data-cy": 'person', header: formatMessage({ id: 'common.person' }) })) : (React.createElement(Table.TextColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.PERSON, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.PERSON], accessorKey: 'person.fullName', "data-cy": 'person', header: formatMessage({ id: 'common.person' }), allowSorting: true, ellipsis: true })),
                    React.createElement(Table.DateColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.DATE, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.DATE], accessorKey: 'expenseDate', "data-cy": 'date', header: formatMessage({ id: 'common.date' }), allowSorting: true }),
                    React.createElement(Table.TextColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.APPROVAL_STATUS, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.APPROVAL_STATUS], accessorKey: 'status', "data-cy": 'status', header: formatMessage({ id: 'common.status' }), icon: row => row.approved && React.createElement(Icon, { icon: 'checkCircle', color: 'success' }), alignIconRight: true, allowSorting: true }),
                    React.createElement(Table.ButtonColumn, { id: EXPENSE_MANAGEMENT_COLUMNS.FILES, visible: eyeOptionMap[EXPENSE_MANAGEMENT_COLUMNS.FILES], "data-cy": 'attached-files', header: formatMessage({ id: 'common.files' }), button: row => ({
                            label: row.attachedFiles.length,
                            onClick: () => openExpenseModal(row, 'Files Icon', true),
                            icon: 'paperclip',
                            tooltip: (React.createElement(FlexColumn, null, row.attachedFiles.map((fileName, index) => (React.createElement(Text, { key: index }, fileName))))),
                        }) }),
                    React.createElement(Table.ActionColumn, { header: formatMessage({ id: 'common.actions' }) },
                        React.createElement(Table.ActionColumn.Option, { "data-cy": 'edit', onClick: row => openExpenseModal(row, 'Edit Expense'), hidden: row => row.readOnly },
                            React.createElement(FormattedMessage, { id: 'expense_management.edit_expense' })),
                        React.createElement(Table.ActionColumn.Option, { "data-cy": 'view', onClick: row => openExpenseModal(row, 'View Expense'), hidden: row => !row.readOnly },
                            React.createElement(FormattedMessage, { id: 'expense_management.view_expense' })),
                        React.createElement(Table.ActionColumn.Option, { "data-cy": 'download-files', onClick: row => downloadExpenseFiles([row]), hidden: row => row.files.edges.length === 0 },
                            React.createElement(FormattedMessage, { id: 'expense_management.download_files' })),
                        React.createElement(Table.ActionColumn.Option, { "data-cy": 'delete', onClick: handleItemDelete, disabled: row => row.locked || row.readOnly },
                            React.createElement(FormattedMessage, { id: 'expense_management.delete_expense' }))),
                    useProjectCurrency ? (React.createElement(Table.ExportOnlyColumn, { header: formatMessage({ id: 'common.currency' }), value: rowCurrency })) : undefined))),
        React.createElement(BulkSelector, { label: formatMessage({ id: 'expense_management.bulk_selector_label' }, { count: selectedRows.length }), onClear: table.clearSelections, show: selectedRows.length > 0, "data-cy": 'bulk-selector', bottomOffset: buyNowTime ? 40 : undefined },
            React.createElement(BulkSelector.MainAction, { onClick: openChangeExpenseCategoryModal, "data-cy": 'bulk-change-expense-category' }, formatMessage({ id: 'expense_management.change_category' })),
            React.createElement(BulkSelector.MainAction, { onClick: openChangeApprovalModal, "data-cy": 'change-approval-status' }, formatMessage({ id: 'expense_management.change_approval' })),
            React.createElement(BulkSelector.MainAction, { onClick: () => downloadExpenseFiles(selectedRows, true), "data-cy": 'bulk-download-expenses', disabled: totalFilesToDownload < 1 },
                formatMessage({ id: 'expense_management.download_all_files' }),
                " (",
                totalFilesToDownload,
                ")"),
            React.createElement(BulkSelector.ExtraAction, { onClick: openChangePersonModal, "data-cy": 'action-change-project-person' },
                React.createElement(FormattedMessage, { id: 'expense_management.actions.change_person' })),
            React.createElement(BulkSelector.ExtraAction, { onClick: openChangeProjectPhaseModal, "data-cy": 'action-change-project-phase' },
                React.createElement(FormattedMessage, { id: 'expense_management.actions.change_project_phase' })),
            React.createElement(BulkSelector.ExtraAction, { onClick: handleBulkItemDelete, "data-cy": 'bulk-expense-item-delete', hidden: hideBulkAction }, formatMessage({ id: 'expense_management.delete_expenses' })),
            React.createElement(BulkSelector.ExtraAction, { onClick: openChangeFixedPriceBillingModal, "data-cy": 'bulk-change-fixed-price-billing' },
                React.createElement(FormattedMessage, { id: 'expense_management.actions.change_fixed_price_billing' })),
            React.createElement(BulkSelector.ExtraAction, { onClick: openChangeBillableStatusModal, "data-cy": 'change-billable-status' }, formatMessage({ id: 'expense_management.mark_as_billable_label' })),
            React.createElement(BulkSelector.ExtraAction, { onClick: openChangeMarkupModal, "data-cy": 'bulk-change-markup' },
                React.createElement(FormattedMessage, { id: 'expense_management.actions.change_markup' })))));
};
export default createRefetchContainer(ExpenseManagementPage, {
    viewer: graphql `
			fragment ExpenseManagementPage_viewer on Viewer
			@argumentDefinitions(startDate: {type: "String"}, endDate: {type: "String"}) {
				id
				...ExpenseManagementFilterButton_viewer
				company {
					id
					currency
					expenseCategories(first: 10000) @connection(key: "Company_expenseCategories", filters: []) {
						edges {
							node {
								id
								name
								disabled
							}
						}
					}
					expenseItems(first: 100000, startDate: $startDate, endDate: $endDate)
						@connection(key: "Company_expenseItems", filters: []) {
						edges {
							node {
								id
								name
								cost
								costBaseCurrency
								price
								priceBaseCurrency
								quantity
								approved
								billable
								partOfFixedPrice
								invoiced
								expenseYear
								expenseMonth
								expenseDay
								files(first: 1000) @connection(key: "ExpenseItem_files") {
									edges {
										node {
											id
											name
										}
									}
								}
								person {
									id
									fullName
									profilePictureId
								}
								project {
									id
									name
									projectColor
									companyProjectId
									customProjectId
									currentProjectStatus {
										color
									}
									client {
										id
										name
									}
									budgetType
									defaultPeriodBudgetType
									projectPersons(first: 1000, contactsOnly: true) {
										edges {
											node {
												isContactPerson
												person {
													id
												}
											}
										}
									}
									rateCard {
										id
										currency
									}
									fullAccessToProject
									readOnlyAccess
									fixedPriceLocks(first: 1000) @connection(key: "Project_fixedPriceLocks", filters: []) {
										edges {
											node {
												id
												startDate
												endDate
												locked
											}
										}
									}
								}
								category {
									id
									name
								}
								phase {
									id
									name
								}
							}
						}
					}
				}
			}
		`,
}, graphql `
		query ExpenseManagementPageRefetchQuery($startDate: String, $endDate: String) {
			viewer {
				component(name: "expense_management")
				...ExpenseManagementPage_viewer @arguments(startDate: $startDate, endDate: $endDate)
			}
		}
	`);
