import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';
import * as d3 from 'd3';

class Gauge extends Component {
	constructor(props) {
		super(props);
		this.updateSize = this.updateSize.bind(this);
	}

	componentDidMount() {
		this.createChart(ReactDOM.findDOMNode(this), this.props);
		window.addEventListener('resize', this.updateSize);
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		this.createChart(ReactDOM.findDOMNode(this), nextProps);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateSize);
	}

	updateSize() {
		this.createChart(ReactDOM.findDOMNode(this), this.props);
	}

	createChart(dom, props) {
		// Remove old chart
		d3.select(dom).select('svg').remove();

		const config = {
			ringInset: 20,
			ringWidth: 1,
			tickLength: 30,

			pointerWidth: 3,
			pointerTailLengthPercent: 0.15,
			pointerHeadLengthPercent: 0.65,
			pointerColor: '#FF7C75',
			pointerCircleRadius: 10,

			minValue: 0.5,
			maxValue: 2,

			minAngle: -140,
			maxAngle: 140,

			majorTicks: 3,
			labelFormat: d3.format(''),
			labelInset: 55,
			rotateLabels: false,
		};

		const deg2rad = function (deg) {
			return (deg * Math.PI) / 180;
		};

		const width = Math.min(props.height, dom.offsetWidth);
		const height = props.height;
		// const data = props.data;
		const svg = d3.select(dom).append('svg').attr('class', 'gauge').attr('width', dom.offsetWidth).attr('height', height);

		const r = width / 2;
		const pointerHeadLength = Math.round(r * config.pointerHeadLengthPercent);
		const pointerTailLength = Math.round(r * config.pointerTailLengthPercent);

		const range = config.maxAngle - config.minAngle;

		const scale = d3.scaleLog().range([0, 1]).domain([config.minValue, config.maxValue]);

		// The ticks we wat to show on the gauge
		const ticks = ['0.5', '0.6', '0.7', '0.8', '0.9', '1', '1.2', '1.4', '1.6', '1.8', '2'];
		const tickData = [1, config.maxValue].map(function (d) {
			return scale(d);
		});
		const arc = d3
			.arc()
			.innerRadius(r - config.ringWidth - config.ringInset)
			.outerRadius(r - config.ringInset)
			.startAngle(function (d, i) {
				const ratio = i > 0 ? tickData[i - 1] : 0;
				return deg2rad(config.minAngle + ratio * range);
			})
			.endAngle(function (d, i) {
				const ratio = tickData[i];
				return deg2rad(config.minAngle + ratio * range);
			});

		const centerTx = 'translate(' + dom.offsetWidth / 2 + ', ' + height / 2 + ')';

		const arcs = svg.append('g').attr('class', 'arc').attr('transform', centerTx);

		arcs.selectAll('path')
			.data(tickData)
			.enter()
			.append('path')
			.attr('stroke', '#666')
			.attr('stroke-width', 4)
			.attr('d', arc);

		const lg = svg.append('g').attr('class', 'label').attr('transform', centerTx);
		lg.selectAll('text')
			.data(ticks)
			.enter()
			.append('text')
			.attr('transform', function (d) {
				if (d === '') return null;

				const ratio = scale(d);

				if (config.rotateLabels) {
					const newAngle = config.minAngle + ratio * range;
					return 'rotate(' + newAngle + ') translate(0,' + (config.labelInset - r) + ')';
				} else {
					const newAngle = deg2rad(config.minAngle + ratio * range),
						y = (config.labelInset - r) * Math.cos(newAngle),
						x = -1 * (config.labelInset - r) * Math.sin(newAngle);

					return 'translate(' + x + ',' + y + ')';
				}
			})
			.text(config.labelFormat)
			.attr('text-anchor', 'middle')
			.attr('alignment-baseline', 'middle');

		// Tick lines
		lg.selectAll('line')
			.data(ticks)
			.enter()
			.append('line')
			.attr('class', 'tickline')
			.attr('x1', 0)
			.attr('y1', 10)
			.attr('x2', 0)
			.attr('y2', config.tickLength)
			.attr('transform', function (d) {
				if (d === '') return null;

				const ratio = scale(d),
					newAngle = config.minAngle + ratio * range;
				return 'rotate(' + newAngle + ') translate(0,' + (10 + config.ringWidth - r) + ')';
			})
			.style('stroke', '#666')
			.style('stroke-width', function (d) {
				if (d === '0.5' || d === '1' || d === '2') return '4px';
				return '1px';
			});

		// Calculate the pointer angle based on props.value clamping between minValue and maxValue
		const ratio = scale(Math.max(Math.min(props.value, config.maxValue), config.minValue));
		let newAngle = config.minAngle + ratio * range;
		// If value exceedes max or min value, add or subtract 10 degrees to show the pointer going beyound the gauge scale.
		if (props.value > config.maxValue) newAngle += 10;
		else if (props.value < config.minValue) newAngle -= 10;

		// Pointer
		svg.append('line')
			.attr('x1', 0)
			.attr('y1', -pointerHeadLength)
			.attr('x2', 0)
			.attr('y2', pointerTailLength)
			.attr('stroke-width', config.pointerWidth)
			.attr('stroke', config.pointerColor)
			.attr('class', 'pointer')
			.attr('transform', centerTx + ' rotate(' + newAngle + ')');

		svg.append('circle')
			.attr('cx', 0)
			.attr('cy', 0)
			.attr('r', config.pointerCircleRadius)
			.attr('fill', config.pointerColor)
			.attr('transform', centerTx);

		svg.append('text')
			.attr('transform', 'translate(' + dom.offsetWidth / 2 + ', ' + (height / 2 + 60) + ')') // TODO: Base translation distance on component width
			.text(d3.format('.2f')(props.value))
			.attr('fill', config.pointerColor)
			.attr('font-size', '24px') // TODO: Base font size on component width
			.attr('text-anchor', 'middle')
			.attr('alignment-baseline', 'middle');
	}

	render() {
		return <div />;
	}
}

Gauge.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	title: PropTypes.string,
	value: PropTypes.number.isRequired,
};

export default Gauge;
