/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MonthByMonthPersonLoader_viewer$ref = any;
export type GLOBAL_FILTER_CONTEXT = "INVOICES" | "LABELS" | "PEOPLE" | "PLACEHOLDERS" | "PROJECTS" | "RESOURCES" | "SKILLS" | "TASKS" | "TIME_REGS" | "%future added value";
export type GLOBAL_FILTER_FIELD = "APPROVAL_STATUS" | "ASSIGNED" | "ASSIGNED_SELF" | "BILLABLE" | "BILLABLE_TIME_REG" | "BILL_FROM" | "BILL_TO" | "BLOCKED" | "BUG" | "CLIENT" | "CLIENT_GUEST_USERS" | "CLIENT_TIME_REG" | "COMPANY_TASK_ID" | "CONTACT" | "CREATED_AT" | "DEADLINE" | "DEPARTMENT" | "DEPARTMENT_TIME_REG" | "DEPENDENCIES" | "DESCRIPTION" | "END_DATE" | "END_DATE_PROJECT" | "END_DATE_TIME_REG" | "FILTER_BY_TIME_REG_SETTINGS" | "FOLLOWER" | "FORECAST" | "HIGH_PRIORITY" | "INTERNAL_TIME" | "INTERNAL_TIME_REG" | "INVOICED" | "LABELS" | "LABEL_TIME_REG" | "NAME" | "NON_CLIENT_TIME_REG" | "NON_DEPARTMENT_TIME_REG" | "NON_PHASE_TIME_REG" | "NON_PROJECT_TIME_REG" | "NON_ROLE_TIME_REG" | "NON_SPRINT_TIME_REG" | "NON_TASK_TIME_REG" | "NO_CONTACT" | "OWNER" | "PERMISSION_LEVEL" | "PERSON" | "PERSON_TIME_REG" | "PHASE_CATEGORY" | "PHASE_TIME_REG" | "PRIORITY_LEVEL" | "PROGRAM" | "PROJECT" | "PROJECT_BUDGET_TYPE" | "PROJECT_DEFAULT_PERIOD_BUDGET_TYPE" | "PROJECT_FOLLOWER" | "PROJECT_GROUP" | "PROJECT_GROUP_SPRINT" | "PROJECT_GROUP_STATUS" | "PROJECT_LABELS" | "PROJECT_MANUAL_PROGRESS" | "PROJECT_OWNER" | "PROJECT_PERSON" | "PROJECT_PHASE" | "PROJECT_SPRINT" | "PROJECT_STAGE" | "PROJECT_STATUS" | "PROJECT_TIME_REG" | "RATE_CARD" | "RECENT_ACTIVITY" | "RECENT_TIME" | "REMAINING" | "RETAINER_PERIOD" | "ROLE" | "ROLE_TIME_REG" | "SET_DONE_AT" | "SKILL" | "SPRINT_CATEGORY" | "SPRINT_TIME_REG" | "STARRED" | "START_DATE" | "START_DATE_PROJECT" | "START_DATE_TIME_REG" | "STATUS" | "SUB_TASKS" | "TASK" | "TASK_APPROVED" | "TASK_HIERARCHY" | "TASK_LEVEL" | "TASK_TIME_REG" | "TEAM" | "TEAM_MEMBER" | "TEAM_TIME_REG" | "TIME_OFF" | "TIME_OFF_TIME_REG" | "TIME_REGS" | "UPDATED_AT" | "WITH_TASKS" | "WITH_TIME_REG" | "%future added value";
export type GLOBAL_FILTER_LOGICAL_OPERATOR = "AND" | "OR" | "%future added value";
export type GLOBAL_FILTER_OPERATOR = "ALL_IN" | "CONTAINS" | "CONTAINS_NOT" | "GREATER" | "GREATER_OR_EQUAL" | "IN" | "IS" | "IS_ALL" | "IS_NOT" | "IS_NOT_ALL" | "LESS" | "LESS_OR_EQUAL" | "NOT_ALL_IN" | "NOT_IN" | "%future added value";
export type TaskSearchQueryType = {|
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>
|};
export type GlobalSearchFilterType = {|
  field?: ?GLOBAL_FILTER_FIELD,
  operator?: ?GLOBAL_FILTER_OPERATOR,
  value?: ?$ReadOnlyArray<?string>,
  context?: ?GLOBAL_FILTER_CONTEXT,
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>,
  logicalOperator?: ?GLOBAL_FILTER_LOGICAL_OPERATOR,
|};
export type MonthByMonthPersonLoaderRefetchQueryVariables = {|
  pageSize?: ?number,
  cursor?: ?string,
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  endYear?: ?number,
  endMonth?: ?number,
  endDay?: ?number,
  searchQuery?: ?TaskSearchQueryType,
|};
export type MonthByMonthPersonLoaderRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: MonthByMonthPersonLoader_viewer$ref
  |}
|};
export type MonthByMonthPersonLoaderRefetchQuery = {|
  variables: MonthByMonthPersonLoaderRefetchQueryVariables,
  response: MonthByMonthPersonLoaderRefetchQueryResponse,
|};
*/


/*
query MonthByMonthPersonLoaderRefetchQuery(
  $pageSize: Int
  $cursor: String
  $startYear: Int
  $startMonth: Int
  $startDay: Int
  $endYear: Int
  $endMonth: Int
  $endDay: Int
  $searchQuery: TaskSearchQueryType
) {
  viewer {
    ...MonthByMonthPersonLoader_viewer_2Tl75
    id
  }
}

fragment MonthByMonthPersonLoader_viewer_2Tl75 on Viewer {
  company {
    resourceBasedPersons(first: $pageSize, after: $cursor, searchQuery: $searchQuery, startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, aggregateLevel: MONTH, aggregates: ["forecastBillableUtilization", "forecastBillableUtilizationWin", "forecastBillableUtilizationSoft", "forecastBillableUtilizationSoftWin", "forecastBillableUtilizationHard", "forecastResourceUtilization", "forecastResourceUtilizationWin", "forecastResourceUtilizationSoft", "forecastResourceUtilizationSoftWin", "forecastResourceUtilizationHard", "forecastBillableUtilizationTaskAndAllocationsCombined", "forecastBillableUtilizationTaskAndAllocationsCombinedWin", "forecastBillableUtilizationTaskAndAllocationsCombinedSoft", "forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin", "forecastBillableUtilizationTaskAndAllocationsCombinedHard", "forecastResourceUtilizationTaskAndAllocationsCombined", "forecastResourceUtilizationTaskAndAllocationsCombinedWin", "forecastResourceUtilizationTaskAndAllocationsCombinedSoft", "forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin", "forecastResourceUtilizationTaskAndAllocationsCombinedHard"]) {
      edges {
        node {
          id
          firstName
          lastName
          profilePictureId
          aggregatedResourceNumbers
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startYear",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startMonth",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startDay",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endYear",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endMonth",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endDay",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchQuery",
    "type": "TaskSearchQueryType"
  }
],
v1 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v2 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v3 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v4 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v5 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v6 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v7 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Literal",
    "name": "aggregateLevel",
    "value": "MONTH"
  },
  {
    "kind": "Literal",
    "name": "aggregates",
    "value": [
      "forecastBillableUtilization",
      "forecastBillableUtilizationWin",
      "forecastBillableUtilizationSoft",
      "forecastBillableUtilizationSoftWin",
      "forecastBillableUtilizationHard",
      "forecastResourceUtilization",
      "forecastResourceUtilizationWin",
      "forecastResourceUtilizationSoft",
      "forecastResourceUtilizationSoftWin",
      "forecastResourceUtilizationHard",
      "forecastBillableUtilizationTaskAndAllocationsCombined",
      "forecastBillableUtilizationTaskAndAllocationsCombinedWin",
      "forecastBillableUtilizationTaskAndAllocationsCombinedSoft",
      "forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin",
      "forecastBillableUtilizationTaskAndAllocationsCombinedHard",
      "forecastResourceUtilizationTaskAndAllocationsCombined",
      "forecastResourceUtilizationTaskAndAllocationsCombinedWin",
      "forecastResourceUtilizationTaskAndAllocationsCombinedSoft",
      "forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin",
      "forecastResourceUtilizationTaskAndAllocationsCombinedHard"
    ]
  },
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MonthByMonthPersonLoaderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Variable",
                "name": "pageSize",
                "variableName": "pageSize"
              },
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "MonthByMonthPersonLoader_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MonthByMonthPersonLoaderRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "resourceBasedPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "aggregatedResourceNumbers",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "searchQuery",
                  "startYear",
                  "startMonth",
                  "startDay",
                  "endYear",
                  "endMonth",
                  "endDay",
                  "aggregateLevel",
                  "aggregates"
                ],
                "handle": "connection",
                "key": "Company_resourceBasedPersons",
                "kind": "LinkedHandle",
                "name": "resourceBasedPersons"
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "MonthByMonthPersonLoaderRefetchQuery",
    "operationKind": "query",
    "text": "query MonthByMonthPersonLoaderRefetchQuery(\n  $pageSize: Int\n  $cursor: String\n  $startYear: Int\n  $startMonth: Int\n  $startDay: Int\n  $endYear: Int\n  $endMonth: Int\n  $endDay: Int\n  $searchQuery: TaskSearchQueryType\n) {\n  viewer {\n    ...MonthByMonthPersonLoader_viewer_2Tl75\n    id\n  }\n}\n\nfragment MonthByMonthPersonLoader_viewer_2Tl75 on Viewer {\n  company {\n    resourceBasedPersons(first: $pageSize, after: $cursor, searchQuery: $searchQuery, startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, aggregateLevel: MONTH, aggregates: [\"forecastBillableUtilization\", \"forecastBillableUtilizationWin\", \"forecastBillableUtilizationSoft\", \"forecastBillableUtilizationSoftWin\", \"forecastBillableUtilizationHard\", \"forecastResourceUtilization\", \"forecastResourceUtilizationWin\", \"forecastResourceUtilizationSoft\", \"forecastResourceUtilizationSoftWin\", \"forecastResourceUtilizationHard\", \"forecastBillableUtilizationTaskAndAllocationsCombined\", \"forecastBillableUtilizationTaskAndAllocationsCombinedWin\", \"forecastBillableUtilizationTaskAndAllocationsCombinedSoft\", \"forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin\", \"forecastBillableUtilizationTaskAndAllocationsCombinedHard\", \"forecastResourceUtilizationTaskAndAllocationsCombined\", \"forecastResourceUtilizationTaskAndAllocationsCombinedWin\", \"forecastResourceUtilizationTaskAndAllocationsCombinedSoft\", \"forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin\", \"forecastResourceUtilizationTaskAndAllocationsCombinedHard\"]) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          profilePictureId\n          aggregatedResourceNumbers\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c419074030a1a1ba787ecf4891b22100';

module.exports = node;
