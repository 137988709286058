import React, { useCallback, useEffect } from 'react';
import { ButtonDropdownField, DropdownList } from '@forecast-it/design-system';
const ConditionalDropdown = ({ options, useTriggerWidth = false, autoSelect = true, renderOption, onSelect, 'data-cy': dataCy, children, }) => {
    const handleSelect = useCallback((option) => {
        onSelect(option);
    }, [onSelect]);
    useEffect(() => {
        if (autoSelect) {
            if (options.length === 1) {
                onSelect(options[0]);
            }
            else {
                onSelect(undefined);
            }
        }
    }, [autoSelect, onSelect, options]);
    if (options.length === 0) {
        return React.createElement("div", null, "-");
    }
    else if (autoSelect && options.length === 1) {
        return React.createElement("div", null, renderOption(options[0]));
    }
    else {
        const dropdownItems = options.map((option, index) => (React.createElement(DropdownList.Item, { key: index, onClick: () => handleSelect(option) }, renderOption(option))));
        return (React.createElement(DropdownList, null,
            React.createElement(DropdownList.Trigger, null,
                React.createElement(ButtonDropdownField, { "data-cy": dataCy }, children)),
            React.createElement(DropdownList.Content, { offset: 5, useTriggerWidth: useTriggerWidth }, dropdownItems)));
    }
};
export default ConditionalDropdown;
