/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type sprintListComponent_viewer$ref = any;
export type sprintListComponent_QueryVariables = {|
  shareKey?: ?string,
  projectId?: ?string,
  isProjectGroupType?: ?boolean,
|};
export type sprintListComponent_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: sprintListComponent_viewer$ref,
  |}
|};
export type sprintListComponent_Query = {|
  variables: sprintListComponent_QueryVariables,
  response: sprintListComponent_QueryResponse,
|};
*/


/*
query sprintListComponent_Query(
  $shareKey: String
  $projectId: ID
  $isProjectGroupType: Boolean
) {
  viewer {
    actualPersonId
    component(name: "insight_sprint_list")
    ...sprintListComponent_viewer_2fJgLr
    id
  }
}

fragment sprintListComponent_viewer_2fJgLr on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    sprintList(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
      useHours
      currency
      todoTasksCountTotal
      progressTasksCountTotal
      doneTasksCountTotal
      tasksCountTotal
      forecastTotal
      remainingTotal
      forecastedPriceTotal
      actualPriceTotal
      reportedTotal
      sprints {
        id
        name
        startYear
        startMonth
        startDay
        endYear
        endMonth
        endDay
        completion
        cardsTodo
        cardsInProgress
        cardsDone
        cardsTotal
        forecast
        remaining
        actualCost
        forecastCost
        reported
        diff
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isProjectGroupType",
    "type": "Boolean"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "insight_sprint_list"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"insight_sprint_list\")"
},
v3 = {
  "kind": "Variable",
  "name": "isProjectGroupType",
  "variableName": "isProjectGroupType"
},
v4 = {
  "kind": "Variable",
  "name": "projectId",
  "variableName": "projectId"
},
v5 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sprintListComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "sprintListComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sprintListComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              (v5/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "SprintList",
                "kind": "LinkedField",
                "name": "sprintList",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "useHours",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "todoTasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "progressTasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "doneTasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tasksCountTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forecastTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "remainingTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forecastedPriceTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "actualPriceTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reportedTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SprintListSprint",
                    "kind": "LinkedField",
                    "name": "sprints",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startMonth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endMonth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completion",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cardsTodo",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cardsInProgress",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cardsDone",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cardsTotal",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "forecast",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "remaining",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actualCost",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "forecastCost",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "reported",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "diff",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "sprintListComponent_Query",
    "operationKind": "query",
    "text": "query sprintListComponent_Query(\n  $shareKey: String\n  $projectId: ID\n  $isProjectGroupType: Boolean\n) {\n  viewer {\n    actualPersonId\n    component(name: \"insight_sprint_list\")\n    ...sprintListComponent_viewer_2fJgLr\n    id\n  }\n}\n\nfragment sprintListComponent_viewer_2fJgLr on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    sprintList(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {\n      useHours\n      currency\n      todoTasksCountTotal\n      progressTasksCountTotal\n      doneTasksCountTotal\n      tasksCountTotal\n      forecastTotal\n      remainingTotal\n      forecastedPriceTotal\n      actualPriceTotal\n      reportedTotal\n      sprints {\n        id\n        name\n        startYear\n        startMonth\n        startDay\n        endYear\n        endMonth\n        endDay\n        completion\n        cardsTodo\n        cardsInProgress\n        cardsDone\n        cardsTotal\n        forecast\n        remaining\n        actualCost\n        forecastCost\n        reported\n        diff\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b870739beea19475cf6aca64fa3daf7c';

module.exports = node;
