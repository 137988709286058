import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import util from '../../../forecast-app/shared/util/util';
import Util from '../../../forecast-app/shared/util/util';
import InsightsUtil from '../insights_util';

class clientListComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sortBy: {column: null, ascending: true},
		};
	}

	UNSAFE_componentWillMount() {
		const listHeight = InsightsUtil.GetlistHeight(this.props.viewer.insightComponentsData.clientList.clients.length);
		if (this.props.height !== listHeight) {
			this.props.updateComponentHeight(this.props.id, listHeight);
		}
		if (this.props.setExportColumns) {
			let exportColumns = [];
			const configObject = this.props.config ? JSON.parse(this.props.config) : {};
			if (configObject) {
				this.props.columns.forEach(col => {
					if (configObject.columns.indexOf(col) > -1) {
						if (
							col === 'spend' ||
							col === 'budget' ||
							col === 'registered' ||
							col === 'progress' ||
							col === 'avgRatePrHourRegistered' ||
							col === 'avgRatePrHourPlanned' ||
							col === 'avgRatePrHourFromBudget'
						) {
							exportColumns.push(col + '_raw');
						} else if (col === 'remaining' || col === 'forecast') {
							exportColumns.push(col + '_hours');
							exportColumns.push(col + '_points');
						} else {
							exportColumns.push(col);
						}
					}
				});
			}
			this.props.setExportColumns(exportColumns);
		}
		this.setData(this.props, this.state);
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		this.setData(nextProps, nextState);
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	setData(props, state) {
		const {formatNumber, formatMessage} = props.intl;
		this.clients = [];
		const clientList = props.viewer.insightComponentsData.clientList;
		this.totals = {
			totalProjects: formatNumber(clientList.projectCountTotal),
			haltedProjects: formatNumber(clientList.projectHaltedCountTotal),
			planningProjects: formatNumber(clientList.projectPlanningCountTotal),
			runningProjects: formatNumber(clientList.projectRunningCountTotal),
			doneProjects: formatNumber(clientList.projectDoneCountTotal),
			spend: util.getFormattedCurrencyValue(
				util.GetCurrencySymbol(props.viewer.insightComponentsData.clientList.currency),
				formatNumber(Math.round(clientList.spendTotal * 100.0) / 100.0)
			),
			budget: util.getFormattedCurrencyValue(
				util.GetCurrencySymbol(props.viewer.insightComponentsData.clientList.currency),
				formatNumber(clientList.budgetTotal)
			),
			registered: Util.convertMinutesToFullHour(clientList.reportedTotal, props.intl),
			forecast:
				Util.convertMinutesToFullHour(clientList.forecastMinutesTotal, props.intl) +
				' & ' +
				formatMessage({id: 'common.x_points'}, {points: clientList.forecastPointsTotal}),
			remaining:
				Util.convertMinutesToFullHour(clientList.remainingMinutesTotal, props.intl) +
				' & ' +
				formatMessage({id: 'common.x_points'}, {points: clientList.remainingPointsTotal}),
		};
		props.viewer.insightComponentsData.clientList.clients.forEach(element => {
			const client = {};
			client.id = element.id;
			client.name = element.name;
			client.totalProjects = formatNumber(element.projectCount);
			client.totalProjects_raw = element.projectCount;
			client.haltedProjects = formatNumber(element.projectHaltedCount);
			client.haltedProjects_raw = element.projectHaltedCount;
			client.planningProjects = formatNumber(element.projectPlanningCount);
			client.planningProjects_raw = element.projectPlanningCount;
			client.runningProjects = formatNumber(element.projectRunningCount);
			client.runningProjects_raw = element.projectRunningCount;
			client.doneProjects = formatNumber(element.projectDoneCount);
			client.doneProjects_raw = element.projectDoneCount;
			client.registered = Util.convertMinutesToFullHour(element.reported, props.intl);
			client.registered_raw = Math.round((element.reported / 60.0) * 100) / 100;
			let forecast;
			if (element.forecastPoints !== 0 && element.forecastMinutes !== 0) {
				//show both hours and point
				forecast =
					Util.convertMinutesToFullHour(element.forecastMinutes, props.intl) +
					' & ' +
					formatMessage({id: 'common.x_points'}, {points: element.forecastPoints});
			} else if (element.forecastPoints === 0 && element.forecastMinutes !== 0) {
				//show only hours
				forecast = Util.convertMinutesToFullHour(element.forecastMinutes, props.intl);
			} else if (element.forecastPoints !== 0 && element.forecastMinutes === 0) {
				//show only points
				forecast = formatMessage({id: 'common.x_points'}, {points: element.forecastPoints});
			} else {
				//both are 0 show empty string
				forecast = '0';
			}
			client.forecast = forecast;
			client.forecast_raw = element.forecastMinutes !== 0 ? element.forecastMinutes : element.forecastPoints;
			client.forecast_hours = Math.round((element.forecastMinutes / 60) * 100) / 100;
			client.forecast_points = element.forecastPoints;
			let remaining;
			if (element.remainingPoints !== 0 && element.remainingMinutes !== 0) {
				//show both hours and point
				remaining =
					Util.convertMinutesToFullHour(element.remainingMinutes, props.intl) +
					' & ' +
					formatMessage({id: 'common.x_points'}, {points: element.remainingPoints});
			} else if (element.remainingPoints === 0 && element.remainingMinutes !== 0) {
				//show only hours
				remaining = Util.convertMinutesToFullHour(element.remainingMinutes, props.intl);
			} else if (element.remainingPoints !== 0 && element.remainingMinutes === 0) {
				//show only points
				remaining = formatMessage({id: 'common.x_points'}, {points: element.remainingPoints});
			} else {
				//both are 0
				remaining = '0';
			}
			client.remaining = remaining;
			client.remaining_raw = element.remainingMinutes !== 0 ? element.remainingMinutes : element.remainingPoints;
			client.remaining_hours = Math.round((element.remainingMinutes / 60) * 100) / 100;
			client.remaining_points = element.remainingPoints;
			client.progress = formatMessage({id: 'common.x_percentage'}, {percentage: element.progress});
			client.progress_raw = element.progress;
			if (this.canViewFinancial()) {
				client.budget = util.getFormattedCurrencyValue(
					util.GetCurrencySymbol(props.viewer.insightComponentsData.clientList.currency),
					formatNumber(element.budget)
				);
				client.budget_raw = element.budget;
				client.spend = util.getFormattedCurrencyValue(
					util.GetCurrencySymbol(props.viewer.insightComponentsData.clientList.currency),
					formatNumber(Math.round(element.spend * 100.0) / 100.0)
				);
				client.spend_raw = Math.round(element.spend * 100) / 100;
				client.avgRatePrHourRegistered = util.getFormattedCurrencyValue(
					util.GetCurrencySymbol(props.viewer.insightComponentsData.clientList.currency),
					formatNumber(element.avgRatePrHourRegistered)
				);
				client.avgRatePrHourRegistered_raw = element.avgRatePrHourRegistered;
				client.avgRatePrHourPlanned = util.getFormattedCurrencyValue(
					util.GetCurrencySymbol(props.viewer.insightComponentsData.clientList.currency),
					formatNumber(element.avgRatePrHourPlanned)
				);
				client.avgRatePrHourPlanned_raw = element.avgRatePrHourPlanned;
				client.avgRatePrHourFromBudget = util.getFormattedCurrencyValue(
					util.GetCurrencySymbol(props.viewer.insightComponentsData.clientList.currency),
					formatNumber(element.avgRatePrHourFromBudget)
				);
				client.avgRatePrHourFromBudget_raw = element.avgRatePrHourFromBudget;
			}

			this.clients.push(client);
		});
		if (state.sortBy.column !== this.state.sortBy.column || state.sortBy.ascending !== this.state.sortBy.ascending) {
			this.sort(state.sortBy);
		}
		if (props.setExportData) {
			props.setExportData(this.clients);
		}
	}

	sort(sortBy) {
		if (sortBy.column) {
			this.clients = this.clients.sort((a, b) => {
				let column = sortBy.column;
				switch (column) {
					case 'name':
						break;
					default:
						column += '_raw';
						break;
				}
				let returnValue = 0;
				if (a[column] < b[column]) returnValue = 1;
				if (a[column] > b[column]) returnValue = -1;
				if (a[column] === '') returnValue = -1;
				if (a[column] === '') returnValue = -1;

				return sortBy.ascending ? returnValue * -1 : returnValue;
			});
		}
	}

	canViewFinancial() {
		return this.props.canViewFinancial !== false;
	}

	render() {
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};
		return (
			<InsightList
				componentId={this.props.id}
				data={this.clients}
				componentName={'clientList'}
				activeColumns={configObject ? configObject.columns : []}
				allColumns={this.props.columns}
				sortBy={this.state.sortBy}
				setSortBy={this.setSortBy.bind(this)}
				totals={this.totals}
				scrollElement={this.props.scrollElement}
				scrollElementFullScreen={this.props.scrollElementFullScreen}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

const clientListComponentQuery = graphql`
	query clientListComponent_Query($shareKey: String) {
		viewer {
			actualPersonId
			component(name: "insight_client_list")
			...clientListComponent_viewer @arguments(shareKey: $shareKey)
		}
	}
`;

export {clientListComponentQuery};

export default injectIntl(
	createFragmentContainer(clientListComponent, {
		viewer: graphql`
			fragment clientListComponent_viewer on Viewer @argumentDefinitions(shareKey: {type: "String"}) {
				insightComponentsData(shareKey: $shareKey) {
					clientList {
						currency
						projectCountTotal
						projectHaltedCountTotal
						projectPlanningCountTotal
						projectRunningCountTotal
						projectDoneCountTotal
						reportedTotal
						forecastMinutesTotal
						forecastPointsTotal
						remainingMinutesTotal
						remainingPointsTotal
						budgetTotal
						spendTotal
						progressTotal
						avgRatePrHourRegistered
						avgRatePrHourPlanned
						avgRatePrHourFromBudget
						clients {
							id
							name
							projectCount
							projectHaltedCount
							projectPlanningCount
							projectRunningCount
							projectDoneCount
							reported
							forecastMinutes
							forecastPoints
							remainingMinutes
							remainingPoints
							budget
							spend
							progress
							avgRatePrHourRegistered
							avgRatePrHourPlanned
							avgRatePrHourFromBudget
						}
					}
				}
			}
		`,
	})
);
