export default class AllocationItemUtil {
	static getItemText(
		canvasContext,
		totalTime,
		allocation,
		allocationNoteIconSize,
		iconTextSpacing,
		visibleItemWidth,
		allocationTimeText,
		schedulingOptions,
		winProbText
	) {
		const {potentialText, showPerDayText, timePerDay} = allocationTimeText;
		const allocationNoteIconWidth = allocation.description ? allocationNoteIconSize + iconTextSpacing : 0;

		const winProb = schedulingOptions.calcWin && allocation.isSoft ? winProbText : '';
		let text;
		if (
			!showPerDayText ||
			canvasContext.measureText(potentialText).width + allocationNoteIconWidth >= visibleItemWidth / 2
		) {
			text = totalTime;
		} else if (
			canvasContext.measureText(potentialText + ' ' + winProb).width + allocationNoteIconWidth >=
			visibleItemWidth / 2
		) {
			text = totalTime + ' ' + winProb;
		} else {
			text = totalTime + timePerDay + winProb;
		}

		return text;
	}
}
