import React, {useEffect, useState} from 'react';
import {Redirect, useRouteMatch} from 'react-router-dom';
import styled from 'styled-components';

import CanvasProjectsScheduling from '../canvas_projects_scheduling';
import {getDayData} from '../../canvas-timeline/canvas_timeline_util';
import {profileLocales, SCHEDULING_VIEW, USER_LOCALE_STORAGE_KEY} from '../../../../constants';
import {fetchInit} from '../../scheduling_fetch';
import {hasFeatureFlag, setAvailableFeatureFlags} from '../../../../forecast-app/shared/util/FeatureUtil';
import {initializeStaticDayData} from '../../static/serializationUtil';
import moment from 'moment/moment';
import Util from '../../../../forecast-app/shared/util/util';

const CanvasProjectSchedulingShareStyle = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
`;

export const CanvasProjectSchedulingShare = () => {
	const match = useRouteMatch();
	const shareUrl = match.params.shareUrl;
	const [initData, setInitData] = useState(undefined);
	const [dayData, setDayData] = useState(undefined);

	const setLocale = () => {
		const localStorageLocale = Util.localStorageGetItem(USER_LOCALE_STORAGE_KEY);

		let profileLocale;
		for (const profile of Object.values(profileLocales)) {
			if (
				localStorageLocale === profile.formatting_locale ||
				(!localStorageLocale && navigator.language.toLowerCase() === profile.html_lang_attribute.toLowerCase())
			) {
				profileLocale = profile;
				break;
			}
		}

		const {html_lang_attribute, formatting_locale, moment_locale} = profileLocale || profileLocales.ENGLISH_US;
		document.documentElement.setAttribute('lang', html_lang_attribute);
		moment.locale(moment_locale);

		if (!localStorageLocale) {
			Util.localStorageSetItem(USER_LOCALE_STORAGE_KEY, formatting_locale);
		}
	};

	useEffect(() => {
		fetchInit(shareUrl).then(initDataResult => {
			setAvailableFeatureFlags(initDataResult.featureFlags);
			setInitData(initDataResult);
			setLocale();

			if (hasFeatureFlag('static_day_data_generation')) {
				initializeStaticDayData(result => {
					setDayData(result);
				});
			} else {
				setDayData(getDayData());
			}
		});
	}, []);

	if (!shareUrl) {
		return <Redirect to="/" />;
	}

	const isProjectTimeline =
		!!initData?.projectSchedulingShare?.companyProjectId || !!initData?.projectSchedulingShare?.groupId;

	const weekendOptions = {
		weekendDisplayPerUser: initData?.company.weekendDisplayPerUser,
		weekendDisplayShowAlways: initData?.company.weekendDisplayShowAlways,
	};

	return (
		<>
			{initData && dayData && (
				<CanvasProjectSchedulingShareStyle>
					<CanvasProjectsScheduling
						viewer={{company: initData?.company}}
						match={match}
						setTimeline={() => {}}
						searchFilterValue={''}
						dayData={dayData}
						showTaskModal={() => {}}
						isProjectTimeline={isProjectTimeline}
						projectId={initData?.projectSchedulingShare?.companyProjectId}
						groupId={initData?.projectSchedulingShare?.groupId}
						timelineHeatmapData={undefined}
						shareUrl={shareUrl}
						heatmapCache={new Map()}
						expansionMap={new Map()}
						onTabClick={() => {}}
						isProjectSchedulingSelected={true}
						schedulingView={SCHEDULING_VIEW.PROJECTS}
						sharedContext={true}
						weekendOptions={weekendOptions}
					/>
				</CanvasProjectSchedulingShareStyle>
			)}
		</>
	);
};
