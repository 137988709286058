import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE, INITIALS_SIZE} from '../../constants';
import {withRouter} from 'react-router-dom';
import SelectInput from '../../components/inputs/select_input';
import UpdateProjectPersonMutation from '../../mutations/update_project_person_mutation';
import DeleteProjectPersonMutation from '../../mutations/delete_project_person_mutation';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import ActionMenu from '../../components/action_menu';
import Util from '../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import Checkbox from '../../components/new-ui/check_box';
import HarvestIcon from '../../images/integrations/harvest-logo.png';
import PersonInitials from '../../forecast-app/shared/components/person/person_initials';
import {hasPermission, personIsVirtualUser} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {DeprecatedIcon} from '@forecast-it/design-system';
import ForecastTooltip from '../../forecast-app/shared/components/tooltips/ForecastTooltip';
import ProgramUtil from '../../forecast-app/shared/util/ProgramUtil';
import {profilePicSrc} from '../../directApi';

class ProjectTeamLineItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedRole: this.props.projectPerson.role ? this.props.projectPerson.role.id : null,
			showProjectsContactList: false,
			isUpdating: false,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			!prevState.showProjectsContactList &&
			this.state.showProjectsContactList &&
			this.project_group_contacts_list_wrapper
		) {
			this.project_group_contacts_list_wrapper.focus();
		}
	}

	handleRoleChange(person) {
		const onSuccess = async () => {
			this.props.retry();
		};
		this.setState({selectedRole: person ? person.value : null, isUpdating: true}, () => {
			Util.CommitMutation(
				UpdateProjectPersonMutation,
				{
					id: this.props.projectPerson.id,
					roleId: person ? person.value : null,
					isProjectGroupUpdate: this.props.isProjectGroupPage,
				},
				onSuccess
			);
		});
	}

	handleRemovePerson() {
		const {useManualAllocations} = this.props;
		const {formatMessage} = this.props.intl;

		if (!hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) && this.props.projectPerson.person.isViewer) {
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'common.invalid_action_modal_title',
				warningInformation: [formatMessage({id: 'settings_team.remove_self_desctiption'})],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
				],
			});
			return;
		}

		const deleteProjectPerson = () => {
			const onSuccess = () => {
				createToast({message: formatMessage({id: 'settings_team.person-has-been-deleted'}), duration: 3000});
			};
			Util.CommitMutation(
				DeleteProjectPersonMutation,
				{
					projectId: this.props.projectId,
					projectGroupId: this.props.projectGroupId,
					ids: [this.props.projectPerson.id],
				},
				onSuccess
			);
		};

		if (useManualAllocations) {
			showModal({
				type: MODAL_TYPE.WARNING,
				warningMessageId: 'settings_team.remove-team-member-from-project',
				warningInformation: [
					formatMessage(
						{id: 'settings_team.remove-from-project-warning'},
						{personName: this.props.projectPerson.person.fullName, i: str => <i>{str}</i>}
					),
				],
				buttons: [
					{
						text: formatMessage({id: 'common.cancel'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: formatMessage({id: 'common.remove'}),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.RED,
						cy: 'remove-person-from-project-warning-button',
						callback: deleteProjectPerson,
					},
				],
			});
		} else {
			deleteProjectPerson();
		}
	}

	newPage() {
		if (this.props.projectPerson.person.isViewer) {
			this.props.history.push('/my-profile/account-configuration');
		} else {
			this.props.history.push('/admin/team/view-user/' + this.props.projectPerson.person.id);
		}
	}

	handleIsContactPersonChange(projectPersonId, isCurrentlyContact) {
		if (this.props.isProjectGroup && projectPersonId === null) {
			this.setState({showProjectsContactList: !this.state.showProjectsContactList});
		} else {
			Util.CommitMutation(UpdateProjectPersonMutation, {
				id: projectPersonId !== null && projectPersonId !== undefined ? projectPersonId : this.props.projectPerson.id,
				isContactPerson:
					isCurrentlyContact !== null && isCurrentlyContact !== undefined
						? !isCurrentlyContact
						: !this.props.projectPerson.isContactPerson,
			});
		}
	}

	handleContactsListBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11

		if (
			(newTarget && newTarget.className === 'project-group-contacts-list-wrapper') ||
			(this.project_group_contacts_list_wrapper && this.project_group_contacts_list_wrapper.contains(newTarget))
		) {
			return;
		}
		this.setState({showProjectsContactList: false});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const person = this.props.projectPerson.person;
		const mainRole = this.props.projectPerson.person.role;
		const roles = this.props.roles.map(role => ({label: role.node.name, value: role.node.id}));

		const hasMultipleRoles = this.props.projectPerson.hasMultipleRoles && !this.state.isUpdating;
		if (hasMultipleRoles) {
			roles.push({value: null, label: 'Multiple Roles', hidden: true});
		}
		const isCurrentRoleDeactivated =
			this.props.projectPerson.role && !roles.some(role => role.value === this.props.projectPerson.role.id);
		if (isCurrentRoleDeactivated) {
			roles.push({label: this.props.projectPerson.role.name, value: this.props.projectPerson.role.id, disabled: true});
		}
		const action_menu_options = [];

		const inheritedFromProgram = this.props.projectPerson.relationType === 'PROGRAM';
		if (!inheritedFromProgram) {
			action_menu_options.push({
				buttonCy: 'remove-person',
				label: formatMessage({id: 'settings_team.remove-from-project'}),
				onClick: this.handleRemovePerson.bind(this),
			});
		}

		if (this.props.isAdmin) {
			action_menu_options.push({
				buttonCy: 'edit-person',
				label: formatMessage({id: 'common.edit'}),
				onClick: this.newPage.bind(this),
			});
		}
		const permissionLevels = person.profiles.edges.map(e => e.node);
		const isContactInAnyProjectFromGroup =
			this.props.isProjectGroup &&
			this.props.projectGroupProjectPersonsMap.get(person.id).filter(pp => pp.node.isContactPerson).length !== 0;
		const isContactInAllProjectsFromGroup =
			this.props.isProjectGroup &&
			this.props.projectGroupProjectPersonsMap.get(person.id).filter(pp => pp.node.isContactPerson).length ===
				this.props.projectGroupProjectPersonsMap.get(person.id).length;

		return (
			<tr className="row-member" data-cy="row-member">
				<td
					className={this.props.isAdmin ? 'person-admin' : 'person'}
					onClick={this.props.isAdmin ? this.newPage.bind(this) : null}
				>
					<div className="image-container">
						<div className="profile-picture">
							{person.profilePictureId ? (
								<img
									crossOrigin="use-credentials"
									alt={person.fullName}
									src={profilePicSrc(person.profilePictureId)}
									width="36"
									height="36"
								/>
							) : (
								<PersonInitials initials={person.initials} size={INITIALS_SIZE.LARGE} />
							)}
						</div>
					</div>
					<div className="name-tile">
						<div className="name" data-cy="member-name">
							{person.fullName}
						</div>
						<div className="email" data-cy="member-email">
							{person.email}
						</div>
					</div>
				</td>
				<td data-cy="member-main-role">{mainRole ? mainRole.name : ''}</td>
				<td className="project-role">
					<div className="icon-wrapper">
						{isCurrentRoleDeactivated && (
							<ForecastTooltip content={<FormattedHTMLMessage id="project_person.deactivated_role" />}>
								<DeprecatedIcon icon="warning" size="m" color="error" />
							</ForecastTooltip>
						)}
					</div>
					<SelectInput
						listDataCy={'project-role-option'}
						cy={'member-role-dropdown'}
						options={roles}
						onChange={this.handleRoleChange.bind(this)}
						value={hasMultipleRoles ? null : this.state.selectedRole}
						multi={false}
						label={''}
						clearable={false}
						locked={
							this.props.projectLocked ||
							(this.props.isInGroup && !hasFeatureFlag('disable_rate_card_roles')) ||
							this.props.isClient ||
							!this.props.hasProjectPersonUpdateAccess
						}
						tableCellSelect={true}
						hideLabel={true}
					/>
				</td>
				<td data-cy="member-permission-level">
					{permissionLevels ? permissionLevels.map(p => p.name).join(', ') : null}
				</td>
				<td data-cy="member-permission-level">
					{this.props.projectPerson.relationType === 'PROGRAM'
						? ProgramUtil.programText(formatMessage)
						: formatMessage({id: 'project_person.relation_type.' + this.props.projectPerson.relationType})}
				</td>
				<td className="cell-contact-person">
					{!personIsVirtualUser(person) ? (
						<div className="contact-person-checkbox-wrapper">
							<Checkbox
								cy="member-project-contact"
								isDisabled={
									this.props.projectLocked || this.props.isClient || !this.props.hasProjectPersonUpdateAccess
								}
								onChange={this.handleIsContactPersonChange.bind(this, null, null)}
								isChecked={
									this.props.isProjectGroup
										? isContactInAnyProjectFromGroup
										: this.props.projectPerson.isContactPerson
										? this.props.projectPerson.isContactPerson
										: false
								}
								hasEntitiesChecked={
									this.props.isProjectGroup &&
									isContactInAnyProjectFromGroup &&
									!isContactInAllProjectsFromGroup
								}
							/>
							{this.props.isProjectGroup &&
							this.state.showProjectsContactList &&
							this.props.projectGroupProjectPersonsMap ? (
								<div
									className="project-group-contacts-list-wrapper"
									ref={div => (this.project_group_contacts_list_wrapper = div)}
									onBlur={this.handleContactsListBlur.bind(this)}
									tabIndex="0"
								>
									<ul>
										{this.props.projectGroupProjectPersonsMap.has(person.id)
											? this.props.projectGroupProjectPersonsMap.get(person.id).map((pp, index) => (
													<li
														key={index}
														onClick={this.handleIsContactPersonChange.bind(
															this,
															pp.node.id,
															pp.node.isContactPerson !== null &&
																pp.node.isContactPerson !== undefined
																? pp.node.isContactPerson
																: false
														)}
														className={
															(index === 0 ? 'first' : '') +
															(index ===
															this.props.projectGroupProjectPersonsMap.get(person.id).length - 1
																? ' last'
																: '')
														}
													>
														<div className="list-element-wrapper">
															<div
																className="project-indicator"
																style={{
																	color: Util.getTextColorV2(
																		this.props.projectPersonProjectMap.get(pp.node.id)
																			.projectColor
																	),
																	backgroundColor: this.props.projectPersonProjectMap.get(
																		pp.node.id
																	).projectColor,
																}}
															>{`P${
																this.props.projectPersonProjectMap.get(pp.node.id)
																	.companyProjectId
															}`}</div>
															<div className="project-name">
																{this.props.projectPersonProjectMap.get(pp.node.id).name}
															</div>
															<Checkbox
																onChange={this.handleIsContactPersonChange.bind(
																	this,
																	pp.node.id,
																	pp.node.isContactPerson !== null &&
																		pp.node.isContactPerson !== undefined
																		? pp.node.isContactPerson
																		: false
																)}
																isChecked={
																	pp.node.isContactPerson !== null &&
																	pp.node.isContactPerson !== undefined
																		? pp.node.isContactPerson
																		: false
																}
															/>
														</div>
													</li>
											  ))
											: null}
									</ul>
								</div>
							) : null}
						</div>
					) : null}
				</td>
				<td className="cell-teams" data-cy="member-teams">
					{this.props.personTeams.toString()}
				</td>
				{this.props.company.harvestEnabled && this.props.projectPerson.person.harvestUser ? (
					<td className="harvest-icon">
						<img width={30} height={30} src={HarvestIcon} alt="Harvest" />
					</td>
				) : (
					<td className="harvest-icon" />
				)}
				{!this.props.projectLocked &&
				!this.props.isInGroup &&
				!this.props.isClient &&
				this.props.hasProjectPersonUpdateAccess ? (
					<td>
						{action_menu_options.length > 0 && (
							<ActionMenu cy={'member-action-menu'} options={action_menu_options} horizontalIcon={true} />
						)}
					</td>
				) : (
					<td />
				)}
			</tr>
		);
	}
}

ProjectTeamLineItem.propTypes = {
	projectPerson: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
	projectLocked: PropTypes.bool.isRequired,
	isAdmin: PropTypes.bool.isRequired,
	isInGroup: PropTypes.bool,
	projectPersonProjectMap: PropTypes.instanceOf(Map).isRequired,
	projectGroupProjectPersonsMap: PropTypes.instanceOf(Map).isRequired,
	useManualAllocations: PropTypes.bool,
};
export default injectIntl(withRouter(ProjectTeamLineItem));
