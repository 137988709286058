/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RatesAndInternalCostLitePage_project$ref: FragmentReference;
declare export opaque type RatesAndInternalCostLitePage_project$fragmentType: RatesAndInternalCostLitePage_project$ref;
export type RatesAndInternalCostLitePage_project = {|
  +id: string,
  +budgetType: ?BUDGET_TYPE,
  +flatRate: ?number,
  +flatCost: ?number,
  +$refType: RatesAndInternalCostLitePage_project$ref,
|};
export type RatesAndInternalCostLitePage_project$data = RatesAndInternalCostLitePage_project;
export type RatesAndInternalCostLitePage_project$key = {
  +$data?: RatesAndInternalCostLitePage_project$data,
  +$fragmentRefs: RatesAndInternalCostLitePage_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RatesAndInternalCostLitePage_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flatRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flatCost",
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = 'aaafbc8bdcd73a1b4d2c4bf6b99fb5b2';

module.exports = node;
