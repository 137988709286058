import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {injectIntl} from 'react-intl';
import {PlusIcon, MinusIcon} from 'web-components';
import {ForecastThemeProvider} from '../../../forecast-app/shared/ForecastThemeProvider';

class ZoomMenu extends Component {
	onZoomInButtonClick(e) {
		e.stopPropagation();
		this.props.setZoomLevel(this.props.zoomLevel + 1);
	}

	onZoomOutButtonClick(e) {
		e.stopPropagation();
		this.props.setZoomLevel(this.props.zoomLevel - 1);
	}

	render() {
		const portalDestination = document.getElementById('zoom-menu-wrapper');
		if (!portalDestination) return null;
		return ReactDOM.createPortal(
			<ForecastThemeProvider>
				<div className="canvas-timeline-zoom-menu">
					<button
						className="zoom-button zoom-out-button"
						onClick={this.onZoomOutButtonClick.bind(this)}
						data-cy={'zoom-out-button'}
					>
						<MinusIcon size={MinusIcon.SIZE.MEDIUM} color={'#535353'} />
					</button>
					<button
						className="zoom-button zoom-in-button"
						onClick={this.onZoomInButtonClick.bind(this)}
						data-cy={'zoom-in-button'}
					>
						<PlusIcon size={PlusIcon.SIZE.MEDIUM} color={'#535353'} />
					</button>
				</div>
			</ForecastThemeProvider>,
			portalDestination
		);
	}
}

ZoomMenu.propTypes = {
	zoomLevel: PropTypes.number.isRequired,
	setZoomLevel: PropTypes.func.isRequired,
};

export default injectIntl(ZoomMenu);
