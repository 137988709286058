import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {Table} from 'web-components';
import {Scrollbar} from '@forecast-it/design-system';
import DetailsTableRow from './DetailsTableRow';
import {LoadMore} from '../../../../../loaders/LoadMore';
import DetailsPhaseLoader, {detailsPhaseLoaderQuery} from '../../loaders/DetailsPhaseLoader';
import {getPhaseName, getRoleName} from '../../../../../insights/project-portfolio-report/ProjectPortfolioReportUtils';
import {PHASE_BREAKDOWN_TYPE} from '../../../../../../constants';
import {useIntl} from 'react-intl';
import {DetailsHeaderColumn, DetailsTableWrapper, DetailsTitleWrapper, DetailsWrapper} from '../../DetailsTable.styled';
import {getTotalsParams} from '../../util/BudgetUtils';
import getAggregateRowTitle from '../../util/UseAggregateIntl';
import DetailsRoleLoader, {detailsRoleLoaderQuery} from '../../loaders/DetailsRoleLoader';
import {getProjectDatesOrDefault} from '../../util/ChartsUtil';
import CompanySetupUtil from '../../../../../../forecast-app/shared/util/CompanySetupUtil';

const DetailsTable = ({project, selectedAggregateLevel, aggregates, title, data, tooltips, tableType, cy}) => {
	const intl = useIntl();
	const filteredData = {...data, rows: data.rows.filter(row => aggregates.includes(row.aggregate))};
	const {
		projectFirstDateYear,
		projectFirstDateMonth,
		projectFirstDateDay,
		projectLastDateYear,
		projectLastDateMonth,
		projectLastDateDay,
	} = getProjectDatesOrDefault(project);

	return (
		<>
			<DetailsWrapper data-cy={cy}>
				<DetailsTitleWrapper>
					<h3>{title}</h3>
				</DetailsTitleWrapper>
				<DetailsTableWrapper>
					<Scrollbar>
						<Table paddedCells cy="budget-details">
							<Table.Header>
								<Table.HeaderColumn visible flex={1} width="EXTRA_LARGE" />
								{filteredData.columns.map((column, index) => (
									<Table.HeaderColumn
										key={title + column.name}
										usePadding
										align="right"
										visible={true}
										width="MEDIUM_SMALL"
									>
										<Table.HeaderColumn.Title bold={index === filteredData.columns.length - 1}>
											<DetailsHeaderColumn>
												<div>{column.name}</div>
											</DetailsHeaderColumn>
										</Table.HeaderColumn.Title>
									</Table.HeaderColumn>
								))}
							</Table.Header>
							<Table.Rows data={filteredData} canExpand={CompanySetupUtil.hasFinance()}>
								{({rowData, tableColumnsProps, expanded, nextLevelProps: phaseLevelProps}) => {
									return (
										<>
											<DetailsTableRow
												data={data}
												rowData={rowData}
												tableColumnProps={tableColumnsProps}
												aggregateLevel={selectedAggregateLevel}
												headerColumn={
													<Table.Column width="LARGE">
														{getAggregateRowTitle(intl, rowData.aggregate, tableType)}
													</Table.Column>
												}
												tooltips={tooltips}
											/>
											{expanded && (
												<LoadMore
													key="query-render-details-phase"
													query={detailsPhaseLoaderQuery}
													loader={
														<DetailsPhaseLoader
															aggregateLevel={selectedAggregateLevel}
															aggregate={rowData.aggregate}
														/>
													}
													variables={{
														projectId: project.id,
														startYear: projectFirstDateYear,
														startMonth: projectFirstDateMonth,
														startDay: projectFirstDateDay,
														endYear: projectLastDateYear,
														endMonth: projectLastDateMonth,
														endDay: projectLastDateDay,
														aggregateLevel: selectedAggregateLevel,
														aggregate: rowData.aggregate,
														...getTotalsParams([rowData.aggregate]),
													}}
												>
													{({data: phaseBreakdownData}) => {
														return (
															<Table.Rows
																data={phaseBreakdownData}
																{...phaseLevelProps}
																canExpand={true}
															>
																{({
																	rowData: phaseRowData,
																	tableColumnsProps: phaseTableColumnProps,
																	expanded: phaseExpanded,
																	nextLevelProps: roleLevelProps,
																}) => {
																	return (
																		<>
																			<DetailsTableRow
																				data={data}
																				rowData={phaseRowData}
																				tableColumnProps={phaseTableColumnProps}
																				headerColumn={getPhaseName(intl, phaseRowData)}
																				canExpand={
																					phaseRowData.breakdownType ===
																						PHASE_BREAKDOWN_TYPE.PHASE ||
																					phaseRowData.breakdownType ===
																						PHASE_BREAKDOWN_TYPE.NO_PHASE
																				}
																			/>
																			{phaseExpanded && (
																				<LoadMore
																					key="query-render-details-role"
																					query={detailsRoleLoaderQuery}
																					loader={
																						<DetailsRoleLoader
																							aggregateLevel={
																								selectedAggregateLevel
																							}
																							aggregate={phaseRowData.aggregate}
																						/>
																					}
																					variables={{
																						projectId: project.id,
																						startYear: projectFirstDateYear,
																						startMonth: projectFirstDateMonth,
																						startDay: projectFirstDateDay,
																						endYear: projectLastDateYear,
																						endMonth: projectLastDateMonth,
																						endDay: projectLastDateDay,
																						phaseId:
																							phaseRowData.breakdownType ===
																							PHASE_BREAKDOWN_TYPE.PHASE
																								? phaseRowData.phase.id
																								: phaseRowData.breakdownType ===
																								  PHASE_BREAKDOWN_TYPE.NO_PHASE
																								? 0
																								: null,
																						aggregateLevel: selectedAggregateLevel,
																						aggregate: phaseRowData.aggregate,
																						...getTotalsParams([rowData.aggregate]),
																					}}
																				>
																					{({data: roleBreakdownData}) => {
																						return (
																							<Table.Rows
																								data={roleBreakdownData}
																								{...roleLevelProps}
																								canExpand={false}
																							>
																								{({
																									rowData: roleRowData,
																									tableColumnsProps:
																										roleTableColumnProps,
																								}) => {
																									return (
																										<DetailsTableRow
																											data={data}
																											rowData={
																												roleRowData
																											}
																											tableColumnProps={
																												roleTableColumnProps
																											}
																											headerColumn={getRoleName(
																												intl,
																												roleRowData
																											)}
																										/>
																									);
																								}}
																							</Table.Rows>
																						);
																					}}
																				</LoadMore>
																			)}
																		</>
																	);
																}}
															</Table.Rows>
														);
													}}
												</LoadMore>
											)}
										</>
									);
								}}
							</Table.Rows>
						</Table>
					</Scrollbar>
				</DetailsTableWrapper>
			</DetailsWrapper>
		</>
	);
};

export default createFragmentContainer(DetailsTable, {
	project: graphql`
		fragment DetailsTable_project on ProjectType {
			id
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
		}
	`,
});
