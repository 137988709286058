import React, {useEffect, useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import {fetchData, fetchMapData} from '../reported_time_financials_fetch';
import {getPhaseRow} from '../rows/phase_row';
import BaseLevelWrapper from './BaselevelWrapper';
import {hasSomePermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {useDidObjectChange} from '../../../../forecast-app/shared/hooks/useDidObjectChange';

const phaseRelayWrapper = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
}) => {
	const phases = viewer.company.allPhasesGlobalSearch.edges;
	const currency = viewer.company.currency;
	const hasNextPage = viewer.company.allPhasesGlobalSearch.pageInfo.hasNextPage;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const [phasesFinancialMap, setPhasesFinancialMap] = useState(null);
	const [nonPhaseTotalMinutesRegistered, setNonPhaseTotalMinutesRegistered] = useState(null);
	const [nonPhaseTotalFinancials, setNonPhaseTotalFinancials] = useState(null);

	const phasesChanged = useDidObjectChange(phases, null);
	const searchQueryChanged = useDidObjectChange(searchQuery, null);
	const shouldRefetchFinancials = phasesChanged || searchQueryChanged;

	useEffect(() => {
		if (shouldRefetchFinancials) {
			const phasesIds = phases.map(phase => phase.node.id);
			if (
				hasSomePermission([
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
					PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
				])
			) {
				fetchMapData(phasesIds, searchQuery, 'total_phase_time_regs_financials').then(data => {
					const phasesFinancialMap = new Map();
					Object.keys(data).forEach(key => {
						phasesFinancialMap.set(key, data[key]);
					});

					setPhasesFinancialMap(phasesFinancialMap);
				});
			}
			if (!hasNextPage) {
				fetchData(searchQuery, 'non_phase_total_minutes_registered').then(data => {
					if (data.total !== undefined) {
						setNonPhaseTotalMinutesRegistered(data.total);
					}
				});
				if (
					hasSomePermission([
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
					])
				) {
					fetchData(searchQuery, 'total_non_phase_time_regs_financials').then(data => {
						setNonPhaseTotalFinancials(data.totals);
					});
				}
			}
		}
	}, [shouldRefetchFinancials]);

	const dataRows = phases.map(phase => {
		return {
			...phase.node,
			totalPrice:
				phasesFinancialMap !== null && phasesFinancialMap.has(phase.node.id)
					? phasesFinancialMap.get(phase.node.id).totalPrice
					: null,
			totalCost:
				phasesFinancialMap !== null && phasesFinancialMap.has(phase.node.id)
					? phasesFinancialMap.get(phase.node.id).totalCost
					: null,
		};
	});

	nonPhaseTotalMinutesRegistered !== null &&
		dataRows.push({
			isNoPhase: true,
			name: intl.formatMessage({id: 'time_reg_report.no_phase'}),
			totalMinutesRegistered: nonPhaseTotalMinutesRegistered,
			totalPrice: nonPhaseTotalFinancials ? nonPhaseTotalFinancials.totalPrice : null,
			totalCost: nonPhaseTotalFinancials ? nonPhaseTotalFinancials.totalCost : null,
		});

	const data = {rows: dataRows};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = phases.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const phaseRows = () => {
		return getPhaseRow(
			data,
			currency,
			intl,
			groupIndex,
			phasesFinancialMap,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader
		);
	};

	return groupIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={phaseRows}
			currentLength={phases.length}
			hasNextPage={viewer.company.allPhasesGlobalSearch.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		phaseRows()
	);
};

const phaseRelayWrapperQuery = graphql`
	query phaseRelayWrapperQuery_Query($searchQuery: TaskSearchQueryType, $pageSize: Int) {
		viewer {
			actualPersonId
			component(name: "phase_relay_wrapper")
			...phaseRelayWrapper_viewer @arguments(searchQuery: $searchQuery, pageSize: $pageSize)
		}
	}
`;
export {phaseRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			phaseRelayWrapper,
			{
				viewer: graphql`
					fragment phaseRelayWrapper_viewer on Viewer
					@argumentDefinitions(searchQuery: {type: "TaskSearchQueryType"}, pageSize: {type: "Int"}) {
						company {
							currency
							allPhasesGlobalSearch(first: $pageSize, searchQuery: $searchQuery) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										name
										totalMinutesRegistered(searchQuery: $searchQuery)
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query phaseRelayWrapperRefetchQuery($pageSize: Int, $searchQuery: TaskSearchQueryType) {
					viewer {
						...phaseRelayWrapper_viewer @arguments(pageSize: $pageSize, searchQuery: $searchQuery)
					}
				}
			`
		)
	)
);
