import Moment from 'moment';
import {hasTimeLocking, hasTimeApproval} from '../../../forecast-app/shared/util/FeatureUtil';

export const getLockedDate = (company, person) => {
	if (hasTimeLocking(company.modules)) {
		const companyLockDate =
			!person?.excludeFromCompanyLockedPeriod &&
			company.lockedPeriodYear &&
			Moment({y: company.lockedPeriodYear, M: company.lockedPeriodMonth - 1, d: company.lockedPeriodDay});
		if (hasTimeApproval(company.modules)) {
			const personLockDate =
				person?.submitLockedDateYear &&
				Moment({y: person.submitLockedDateYear, M: person.submitLockedDateMonth - 1, d: person.submitLockedDateDay});
			if (!personLockDate && !companyLockDate) return undefined;
			if (!personLockDate || !companyLockDate) return personLockDate || companyLockDate;

			return personLockDate.isAfter(companyLockDate) ? personLockDate : companyLockDate;
		}

		return companyLockDate;
	}
};

export const getCompanyLockedDate = company => {
	if (hasTimeLocking(company.modules)) {
		return (
			company.lockedPeriodYear &&
			Moment({y: company.lockedPeriodYear, M: company.lockedPeriodMonth - 1, d: company.lockedPeriodDay})
		);
	}
};
