import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../../../../css_variables';

export const ChartWrapper = Styled.div`
    min-width: 500px;
    min-height: 350px;
    background-color: ${CSS_CONSTANTS.color_white};
    padding-left: 24px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 24px;

    flex-grow: 1;
    border: 1px solid #E6E6ED;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
`;

export const ChartTabs = Styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const ChartStyle = Styled.div`
    flex-grow: 1;
`;

export const ChartTabOption = Styled.div`
    &:not(:first-child) {
        margin-left: 32px;
    }
    font-family: ${CSS_CONSTANTS.text_font_family_v2};
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${props => (props.active ? '#6E0FEB' : '#A5A5AE')};
    cursor: pointer;

    &:hover {
        color: #6E0FEB;
    }
    &.active {
        color: #6E0FEB;
    }
`;
