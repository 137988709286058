import {SOCKET_EVENT_TYPE, SOCKET_ACTION} from '../../../constants';

export const getSocketConfig = () => {
	const socketEvents = [
		{
			type: SOCKET_EVENT_TYPE.TIME_REG,
			action: SOCKET_ACTION.CREATE,
		},
		{
			type: SOCKET_EVENT_TYPE.TIME_REG,
			action: SOCKET_ACTION.UPDATE,
		},
		{
			type: SOCKET_EVENT_TYPE.TIME_REG,
			action: SOCKET_ACTION.DELETE,
		},
	];

	return socketEvents;
};
