import React, {Component} from 'react';
import PropTypes from 'prop-types';

class HorizontalScrollbar extends Component {
	constructor(props) {
		super(props);

		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);

		window.addEventListener('mousemove', this.onMouseMove);
		if (window.PointerEvent) {
			window.addEventListener('pointerup', this.onMouseUp);
		} else {
			window.addEventListener('mouseup', this.onMouseUp);
		}

		this.state = {
			scrollPosition: props.canvasWidth / 2 - props.scrollbarThumbWidth / 2,
			isScrolling: false,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.canvasWidth !== nextProps.canvasWidth) {
			this.setState({scrollPosition: nextProps.canvasWidth / 2 - nextProps.scrollbarThumbWidth / 2});
		}
	}

	componentWillUnmount() {
		if (window.PointerEvent) {
			window.removeEventListener('pointerup', this.onMouseUp);
		} else {
			window.removeEventListener('mouseup', this.onMouseUp);
		}
		window.removeEventListener('mousemove', this.onMouseMove);
	}

	onMouseDown(e) {
		e.preventDefault();
		e.stopPropagation();
		this.initialMousePosition = e.clientX;
		this.setState({isScrolling: true});
	}

	onMouseMove(e) {
		const {scrollPosition, isScrolling} = this.state;
		if (!isScrolling || e.clientX === this.initialMousePosition) return;
		const deltaX = e.clientX - this.initialMousePosition;
		this.initialMousePosition = e.clientX;
		let newPosition = scrollPosition + deltaX;
		const maxPosition = this.props.canvasWidth - this.props.scrollbarThumbWidth;
		newPosition = newPosition <= 0 ? 0 : newPosition >= maxPosition ? maxPosition : newPosition;
		if (scrollPosition === newPosition) return;
		this.setState({scrollPosition: newPosition});
		const scrollAmount = (deltaX / this.props.canvasWidth) * this.props.scrollbarSensitivity;
		this.props.scroll(scrollAmount, {applyEasing: false});
	}

	onMouseUp(e) {
		if (!this.state.isScrolling) return;
		this.setState({isScrolling: false, scrollPosition: this.props.canvasWidth / 2 - this.props.scrollbarThumbWidth / 2});
		if (this.props.onScrollEnd) this.props.onScrollEnd();
	}

	render() {
		const {scrollPosition, isScrolling} = this.state;
		return (
			<div className="canvas-timeline-horizontal-scrollbar-container">
				<div
					className={'scroll-thumb ' + (isScrolling ? 'scrolling' : 'not-scrolling')}
					style={{width: this.props.scrollbarThumbWidth, left: scrollPosition}}
					onMouseDown={this.onMouseDown.bind(this)}
				/>
			</div>
		);
	}
}

HorizontalScrollbar.propTypes = {
	scroll: PropTypes.func.isRequired,
	canvasWidth: PropTypes.number.isRequired,
	scrollbarThumbWidth: PropTypes.number.isRequired,
	scrollbarSensitivity: PropTypes.number.isRequired,
};

export default HorizontalScrollbar;
