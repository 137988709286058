import {usingTimeApproval, usingTimeOffApproval} from '../../forecast-app/shared/util/FeatureUtil';
import {hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';

export const hasTimeApprovalAccess = viewer =>
	(usingTimeApproval(viewer.company.useTimeApproval) &&
		(hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS) || viewer.projectOwner)) ||
	(usingTimeOffApproval(viewer.company.useTimeOffApproval) && hasPermission(PERMISSION_TYPE.MANAGE_TIME_OFF));

export const getTimeOffSearchQuery = viewer => {
	let filters = [];
	// If the user has permission to manage time off, but not time registration, we need to filter the time off
	if (hasPermission('MANAGE_TIME_OFF') && !hasPermission('TIME_REGISTRATION_UPDATE_ALL')) {
		const timeOffIds = viewer.company.idleTimes?.edges.filter(edge => !edge.node.isInternalTime).map(edge => edge.node.id);
		if (timeOffIds && timeOffIds.length) {
			const timeOffFilter = {
				field: 'TIME_OFF_TIME_REG',
				operator: 'IN',
				value: timeOffIds,
			};
			filters.push(timeOffFilter);
		}
	}
	return {filters: filters};
};
