import React, {useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import styled from 'styled-components';
import RevenueRecognition from './revenue_recognition/RevenueRecognition';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Util from '../../../../forecast-app/shared/util/util';
import {getCurrency} from './util/BudgetUtils';
import TimeRevenueRecognition from './revenue_recognition/TimeRevenueRecognition';
import ExpenseRevenueRecognition from './revenue_recognition/ExpenseRevenueRecognition';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';

const RevenueRecognitionPage = ({company, project, useThirdLevelNav, retry, timestamp, hasExpenses}) => {
	useTrackPage('Project Financials Revenue Recognition', null, {budgetType: project.budgetType});
	const [financialQueryTimestamp, setFinancialQueryTimestamp] = useState(timestamp);
	if (timestamp !== financialQueryTimestamp) {
		setFinancialQueryTimestamp(timestamp);
		retry();
	}

	const isBudgetV3 = hasFeatureFlag('project_budget_v3');
	const currency = getCurrency(company, project);
	const currencySymbol = Util.GetCurrencySymbol(currency);

	const [isManualProgressUpdating, setManualProgressUpdating] = useState(false);

	return (
		<RecognitionPageStyled useThirdLevelNav={useThirdLevelNav} isBudgetV3={isBudgetV3}>
			<CustomScrollDiv>
				<RevenueRecognition
					project={project}
					retry={retry}
					timestamp={timestamp}
					hasExpenses={hasExpenses}
					hideLocking={isManualProgressUpdating}
				/>
				<TimeRevenueRecognition
					project={project}
					currencySymbol={currencySymbol}
					retry={retry}
					timestamp={timestamp}
					isManualProgressUpdating={isManualProgressUpdating}
					setManualProgressUpdating={setManualProgressUpdating}
				/>
				{hasExpenses && <ExpenseRevenueRecognition project={project} currencySymbol={currencySymbol} />}
			</CustomScrollDiv>
		</RecognitionPageStyled>
	);
};

export default createFragmentContainer(RevenueRecognitionPage, {
	project: graphql`
		fragment RevenueRecognitionPage_project on ProjectType {
			id
			rateCard {
				id
				currency
			}
			budgetType
			...RevenueRecognition_project
			...TimeRevenueRecognition_project
			...ExpenseRevenueRecognition_project
		}
	`,
	company: graphql`
		fragment RevenueRecognitionPage_company on Company {
			id
			currency
		}
	`,
});

const RecognitionPageStyled = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;
