import {
	GROUP_TYPE,
	CURSOR,
	getTextColor,
	GROUP_SECTION_WIDTH,
	GROUP_SECTION_SPACING_LEVEL_TWO,
	GROUP_SECTION_PADDING_LEFT,
	drawBorder,
	GROUP_SECTION_PROJECT_BORDER_WIDTH,
	GROUP_SECTION_TEXT_GREY_DARK,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	isProjectDoneOrHalted,
	GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {drawExpandedGroupHighlight} from '../../DrawingUtils';
import {hasPermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import EventManager from '../../EventManager';
import DataManager from '../../DataManager';
import {PROJECT_SUB_GROUP_TYPE} from '../../constants';

class ProjectSubGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PROJECT_SCHEDULING_PROJECT_SUB_GROUP, data);

		this.expanded = !data.isInProgram && data.id.includes('phase');
		this.setPhaseCount();
		this.setTeamMembersCount();
	}

	refreshData(updatedProject) {
		this.data.color = updatedProject.projectColor;
		this.setPhaseCount();
		this.setTeamMembersCount();
	}

	setPhaseCount() {
		const {project, projectSubGroupType} = this.data;

		if (projectSubGroupType === PROJECT_SUB_GROUP_TYPE.PHASES) {
			this.phaseCount = DataManager.getPhaseIdsByProjectId(this.pageComponent, project.id)?.length || 0;
		}
	}

	setTeamMembersCount() {
		const {project, projects, projectGroup, projectSubGroupType} = this.data;
		let teamMembersCount = 0;

		if (projectSubGroupType === PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM) {
			const projectPersonProjectId = projectGroup && projects ? projects[0]?.id : project?.id;
			const projectPersons = DataManager.getProjectPersonsByProjectId(this.pageComponent, projectPersonProjectId);
			if (projectPersons) {
				for (const projectPerson of projectPersons) {
					const person = DataManager.getPersonById(this.pageComponent, projectPerson.personId);

					if (!person.clientId) {
						teamMembersCount++;
					}
				}
			}
		}

		this.teamMembersCount = teamMembersCount;
	}

	onItemCreate(startDate, endDate) {
		const {projectSubGroupType} = this.data;

		if (projectSubGroupType === PROJECT_SUB_GROUP_TYPE.PHASES) {
			const {sharedContext} = this.pageComponent.props;
			const {project} = this.data;

			const isDoneOrHalted = project ? isProjectDoneOrHalted(project.status) : false;
			if (!sharedContext && !isDoneOrHalted && hasPermission(PERMISSION_TYPE.PHASE_CREATE)) {
				EventManager.onItemCreate(this.pageComponent, this, startDate, endDate);
			}
		}
	}

	onMouseEnter(groupData) {
		const {disabled} = this.data;

		if (!disabled) {
			const {project, projectGroup, projects, projectSubGroupType} = this.data;

			const showCreatePlaceholderButton = projectSubGroupType === PROJECT_SUB_GROUP_TYPE.PLACEHOLDERS;
			const showAddPeopleButton =
				projectSubGroupType === PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM &&
				hasPermission(PERMISSION_TYPE.PROJECT_PERSON_CREATE);
			const hasOnHover = showCreatePlaceholderButton || showAddPeopleButton;
			if (hasOnHover) {
				const {height, width, y, x} = groupData;

				const targetOffsetY = height / 2 - GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE / 2;
				const targetOffsetX = width - GROUP_SECTION_PADDING_LEFT - GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE;

				const groupTypeData = {};
				if (showCreatePlaceholderButton) {
					groupTypeData.showCreatePlaceholderButton = true;
					groupTypeData.createPlaceholderProject = project || projectGroup;
				} else if (showAddPeopleButton) {
					groupTypeData.showAddPeopleButton = true;
					groupTypeData.addPeopleData = projectGroup ? projects[0] : project;
				}

				this.pageComponent.setState({
					targetButtonY: y + targetOffsetY,
					targetButtonX: x + targetOffsetX,
					...groupTypeData,
				});
			}
		}
	}

	onMouseLeave() {
		const {disabled} = this.data;

		if (!disabled) {
			const {showCreatePlaceholderButton, showAddPeopleButton} = this.pageComponent.state;

			if (showCreatePlaceholderButton) {
				this.pageComponent.setState({showCreatePlaceholderButton: false});
			} else if (showAddPeopleButton) {
				this.pageComponent.setState({showAddPeopleButton: false});
			}
		}
	}

	draw(canvasContext, x, y) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height, expanded, groupType, preventExpansion} = this;
		super.draw(x, y, width, height, this);
		const {
			name,
			id,
			color,
			groups,
			isInProjectGroup,
			isInProgram,
			projectGroupColor,
			projectGroup,
			isPartOfProjectGroupGroup,
		} = data;
		const {isProjectTimeline} = this.pageComponent.props;

		const cacheId = `${groupType}-${id}-${!!expanded}`;
		const isPhaseSubGroup = data.id.includes(PROJECT_SUB_GROUP_TYPE.PHASES);
		const isPlaceholderSubGroup = data.id.includes(PROJECT_SUB_GROUP_TYPE.PLACEHOLDERS);
		interactionManager.addCursorStyleArea(x, y, width, height, preventExpansion ? CURSOR.DEFAULT : CURSOR.POINTER, true);

		const textColor = getTextColor(color);
		const cachedData = cacheManager.get(groupType, cacheId);
		if (cachedData) {
			canvasContext.drawImage(cachedData, x, y, width, this.itemRowHeight);
			canvasContext.fillStyle = textColor;
			canvasContext.fillText(
				`${name} (${
					isPhaseSubGroup
						? this.phaseCount
						: groups
						? groups.filter(group => !group.filtered).length
						: this.teamMembersCount !== undefined
						? this.teamMembersCount
						: '-'
				})`,
				x + 53,
				y + 24
			);
			return;
		}

		const drawGroup = () => {
			const iconHeight = isPlaceholderSubGroup ? 20 : 19;
			const iconWidth = isPlaceholderSubGroup ? 21 : 24;
			const topBottomBorderThickness = 1;
			let fontSize = 13;
			const groupingGroupOffsetX = (isInProjectGroup && isPhaseSubGroup) || isInProgram ? GROUP_SECTION_PADDING_LEFT : 0;

			if (!isProjectTimeline) {
				drawExpandedGroupHighlight(canvasContext, x, y, height, width);
			}

			const topBottomBorderOffset = 0;
			const topBottomBorderWidth = width - topBottomBorderOffset;

			// top border
			drawBorder(canvasContext, x + topBottomBorderOffset, y, topBottomBorderWidth, false, '#E7E7F3');

			// bottom border
			drawBorder(canvasContext, x + topBottomBorderOffset, y - 1 + height, topBottomBorderWidth, false, '#E7E7F3');

			// right project color
			if ((!isInProjectGroup && !projectGroup) || !isPartOfProjectGroupGroup) {
				drawBorder(
					canvasContext,
					x + width - GROUP_SECTION_PROJECT_BORDER_WIDTH,
					y - 2 - topBottomBorderThickness,
					height,
					true,
					color || projectGroupColor,
					GROUP_SECTION_PROJECT_BORDER_WIDTH
				);
			}

			canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;

			if (!preventExpansion) {
				// expansion icon
				canvasContext.drawImage(
					cacheManager.getCommonImage(
						expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON
					),
					x + groupingGroupOffsetX + GROUP_SECTION_SPACING_LEVEL_TWO - GROUP_SECTION_EXPAND_ICON_WIDTH / 2,
					y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
					GROUP_SECTION_EXPAND_ICON_WIDTH,
					GROUP_SECTION_EXPAND_ICON_HEIGHT
				);
			}

			const subGroupIcon = isPhaseSubGroup
				? cacheManager.getCommonImage(COMMON_IMAGE.PHASES_BLACK)
				: isPlaceholderSubGroup
				? cacheManager.getCommonImage(COMMON_IMAGE.PLACEHOLDER_GROUP_ICON)
				: cacheManager.getCommonImage(COMMON_IMAGE.PROJECT_TEAM_BLACK);

			// phases / placeholders / project team icon
			canvasContext.drawImage(
				subGroupIcon,
				x +
					groupingGroupOffsetX +
					GROUP_SECTION_SPACING_LEVEL_TWO +
					GROUP_SECTION_PADDING_LEFT +
					GROUP_SECTION_EXPAND_ICON_WIDTH / 2,
				y + height / 2 - iconHeight / 2,
				iconWidth,
				iconHeight
			);

			// title 'Project Team (x)' / 'Phase (x)'
			canvasContext.font = `600 ${fontSize}px ` + Util.getFontFamily();
			const sum = isPhaseSubGroup
				? this.phaseCount
				: groups
				? groups.filter(group => !group.filtered).length
				: this.teamMembersCount !== undefined
				? this.teamMembersCount
				: '-';
			const title = `${name} (${sum})`;
			canvasContext.fillText(
				title,
				x + groupingGroupOffsetX + GROUP_SECTION_SPACING_LEVEL_TWO + iconWidth + GROUP_SECTION_PADDING_LEFT * 2,
				y + height / 2 + fontSize / 3
			);
		};
		drawGroup();
	}
}

export default ProjectSubGroup;
