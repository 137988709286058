import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Person from '../../../forecast-app/shared/components/person/person';
import Util from '../../../forecast-app/shared/util/util';

class CardListDummy extends Component {
	createDummyCard(
		id,
		name,
		sprint,
		phase,
		cardStatus,
		forecast,
		remaining,
		reported,
		startDate,
		deadline,
		assigned,
		bug,
		blocked,
		billable
	) {
		const card = {};
		card.id = id;
		card.name = name;
		card.sprint = sprint;
		card.phase = phase;
		card.cardStatus = cardStatus;
		card.low = Util.convertMinutesToFullHour((forecast - 1) * 60, this.props.intl);
		card.high = Util.convertMinutesToFullHour((forecast + 2) * 60, this.props.intl);
		card.forecast = Util.convertMinutesToFullHour(forecast * 60, this.props.intl);
		card.remaining = Util.convertMinutesToFullHour(remaining * 60, this.props.intl);
		card.reported = Util.convertMinutesToFullHour(reported * 60, this.props.intl);
		card.diff = Util.convertMinutesToFullHour(
			(forecast - (remaining + reported) > 0 ? forecast - (remaining + reported) : 0) * 60,
			this.props.intl
		);
		card.startDate = this.props.intl.formatDate(new Date(startDate));
		card.deadline = this.props.intl.formatDate(new Date(deadline));
		card.assigned = (
			<Person name={assigned} role="Developer" showName={false} showRole={false} imageSrc={null} imageSize="medium" />
		);
		card.bug = bug;
		card.blocked = blocked;
		card.billable = billable;
		card.labels = [{id: 1, color: '#392664', name: 'Label 1'}];

		const progress = forecast > 0 ? Math.min(Math.max(((forecast - remaining) / forecast) * 100, 0), 100) : 0;

		card.progress = (
			<div className="progress-container" style={{border: '1px solid rgb(68, 180, 255)'}}>
				<div className="progress-indicator" style={{width: progress + '%', backgroundColor: 'rgb(68, 180, 255)'}} />
			</div>
		);
		return card;
	}

	render() {
		const {formatMessage} = this.props.intl;
		let cards = [];
		cards.push(
			this.createDummyCard(
				1,
				'Example card 1',
				formatMessage({id: 'project_sprints.backlog'}),
				'Phase 1',
				formatMessage({id: 'common.to_do'}),
				5,
				5,
				0,
				'5 8 2017',
				'12 8 2017',
				'John Smith',
				formatMessage({id: 'common.yes'}),
				formatMessage({id: 'common.no'}),
				formatMessage({id: 'common.yes'})
			)
		);
		cards.push(
			this.createDummyCard(
				2,
				'Example card 2',
				'Sprint 1',
				'Phase2',
				formatMessage({id: 'project_settings.workflow-done'}),
				3,
				0,
				3,
				'11 20 2017',
				'11 25 2017',
				'John Smith',
				formatMessage({id: 'common.no'}),
				formatMessage({id: 'common.yes'}),
				formatMessage({id: 'common.no'})
			)
		);
		cards.push(
			this.createDummyCard(
				3,
				'Example card 3',
				'Sprint 2',
				'',
				formatMessage({id: 'common.in_progress'}),
				2.5,
				1,
				1.5,
				'1 7 2017',
				'4 7 2017',
				'John Smith',
				formatMessage({id: 'common.no'}),
				formatMessage({id: 'common.no'}),
				formatMessage({id: 'common.yes'})
			)
		);
		const columns = this.props.allColumns.filter(col => col !== 'projectName' && col !== 'companyProjectId');
		return (
			<InsightList
				data={cards}
				componentName={this.props.componentName}
				allColumns={columns}
				activeColumns={this.props.activeColumns}
				scrollElement={this.props.scrollElement}
			/>
		);
	}
}

CardListDummy.propTypes = {
	title: PropTypes.string,
};

export default injectIntl(CardListDummy);
