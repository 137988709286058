/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RoleFinancialBreakdownType = "EXPENSES" | "NO_ROLE" | "ROLE" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DetailsRoleLoader_viewer$ref: FragmentReference;
declare export opaque type DetailsRoleLoader_viewer$fragmentType: DetailsRoleLoader_viewer$ref;
export type DetailsRoleLoader_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string
  |},
  +project: ?{|
    +rateCard: ?{|
      +currency: ?string
    |},
    +roleFinancialBreakdown: $ReadOnlyArray<?{|
      +breakdownType: ?RoleFinancialBreakdownType,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +financialNumbers: ?{|
        +baselineRevenue?: ?number,
        +baselineCost?: ?number,
        +baselineProfit?: ?number,
        +billableActualTimeAndExpenses?: ?number,
        +actualCost?: ?number,
        +actualProfit?: ?number,
        +billablePlannedTimeAndExpenses?: ?number,
        +plannedCost?: ?number,
        +plannedProfit?: ?number,
        +billableForecastTimeAndExpensesToComplete?: ?number,
        +forecastCostToComplete?: ?number,
        +forecastProfitToComplete?: ?number,
        +billableTotalTimeAndExpensesAtCompletion?: ?number,
        +totalCostAtCompletion?: ?number,
        +totalProfitAtCompletion?: ?number,
        +planVsBillableActualTimeAndExpenses?: ?number,
        +planVsActualCost?: ?number,
        +planVsActualProfit?: ?number,
        +planVsTotalBillableTimeAndExpensesAtCompletion?: ?number,
        +planVsTotalCostAtCompletion?: ?number,
        +planVsTotalProfitAtCompletion?: ?number,
        +baselineVsBillableActualTimeAndExpenses?: ?number,
        +baselineVsActualCost?: ?number,
        +baselineVsActualProfit?: ?number,
        +baselineVsBillableTotalTimeAndExpensesAtCompletion?: ?number,
        +baselineVsTotalCostAtCompletion?: ?number,
        +baselineVsTotalProfitAtCompletion?: ?number,
        +invoiced?: ?number,
        +paid?: ?number,
        +baselineMinutes?: ?number,
        +baselineVsRegisteredMinutes?: ?number,
        +baselineVsTotalTimeAtCompletion?: ?number,
        +registeredMinutes?: ?number,
        +billableActualMinutes?: ?number,
        +nonBillableActualMinutes?: ?number,
        +scopeApprovedMinutes?: ?number,
        +registeredVsScopeApprovedMinutes?: ?number,
        +scopeApprovedVsRegisteredMinutes?: ?number,
        +forecastTimeToComplete?: ?number,
        +totalTimeAtCompletion?: ?number,
        +totalTimeAtCompletionVsScopeApprovedMinutes?: ?number,
        +scopeApprovedVsTotalTimeAtCompletion?: ?number,
        +baselineTimeAndExpenses?: ?number,
        +plannedRevenueProfit?: ?number,
        +plannedRevenueMargin?: ?number,
        +totalRevenueProfitAtCompletion?: ?number,
        +forecastRevenueProfitToComplete?: ?number,
        +actualRevenueProfit?: ?number,
        +baselineMargin?: ?number,
        +forecastRevenueMarginToComplete?: ?number,
        +totalForecastRevenueToComplete?: ?number,
        +actualRevenueMargin?: ?number,
        +totalActualRevenueRecognition?: ?number,
        +baselineVsActualMargin?: ?number,
        +baselineVsTotalRevenueAtCompletion?: ?number,
        +baselineVsTotalMarginAtCompletion?: ?number,
        +planVsActualMargin?: ?number,
        +planVsTotalRevenueAtCompletion?: ?number,
        +baselineVsActualRevenueMargin?: ?number,
        +baselineVsActualRevenueProfit?: ?number,
        +baselineVsTotalRevenueMarginAtCompletion?: ?number,
        +baselineVsTotalRevenueProfitAtCompletion?: ?number,
        +planVsActualRevenueMargin?: ?number,
        +planVsActualRevenueProfit?: ?number,
        +planVsTotalRevenueMarginAtCompletion?: ?number,
        +planVsTotalRevenueProfitAtCompletion?: ?number,
        +baselineVsActualRevenueRecognition?: ?number,
        +planVsActualRevenueRecognition?: ?number,
      |},
    |}>,
    +aggregatedRoleFinancialBreakdown: $ReadOnlyArray<?{|
      +breakdownType: ?RoleFinancialBreakdownType,
      +role: ?{|
        +id: string,
        +name: ?string,
      |},
      +aggregatedFinancialNumbers: ?$ReadOnlyArray<?string>,
    |}>,
  |},
  +$refType: DetailsRoleLoader_viewer$ref,
|};
export type DetailsRoleLoader_viewer$data = DetailsRoleLoader_viewer;
export type DetailsRoleLoader_viewer$key = {
  +$data?: DetailsRoleLoader_viewer$data,
  +$fragmentRefs: DetailsRoleLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v2 = {
  "kind": "Literal",
  "name": "convertToProjectCurrency",
  "value": true
},
v3 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v4 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v5 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v6 = {
  "kind": "Variable",
  "name": "phaseId",
  "variableName": "phaseId"
},
v7 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v8 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v9 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v10 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "breakdownType",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "phaseId",
      "type": "ID!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregateLevel",
      "type": "AggregateLevel!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregate",
      "type": "FinancialAggregatedNumber!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineRevenue",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineCost",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "billableActualTimeAndExpenses",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "actualCost",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "actualProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "billablePlannedTimeAndExpenses",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannedCost",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannedProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "billableForecastTimeAndExpensesToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "forecastCostToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "forecastProfitToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "billableTotalTimeAndExpensesAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalCostAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalProfitAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsBillableActualTimeAndExpenses",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsActualCost",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsActualProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsTotalBillableTimeAndExpensesAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsTotalCostAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsTotalProfitAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsBillableActualTimeAndExpenses",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsActualCost",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsActualProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsBillableTotalTimeAndExpensesAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsTotalCostAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsTotalProfitAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "invoiced",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "paid",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsRegisteredMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsTotalTimeAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "registeredMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "billableActualMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "nonBillableActualMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeApprovedMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "registeredVsScopeApprovedMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeApprovedVsRegisteredMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "forecastTimeToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalTimeAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalTimeAtCompletionVsScopeApprovedMinutes",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scopeApprovedVsTotalTimeAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineTimeAndExpenses",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannedRevenueProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "plannedRevenueMargin",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalRevenueProfitAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "forecastRevenueProfitToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "actualRevenueProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineMargin",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "forecastRevenueMarginToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalForecastRevenueToComplete",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "actualRevenueMargin",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "totalActualRevenueRecognition",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsActualMargin",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsTotalRevenueAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsTotalMarginAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsActualMargin",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsTotalRevenueAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsActualRevenueMargin",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsActualRevenueProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsTotalRevenueMarginAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsTotalRevenueProfitAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsActualRevenueMargin",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsActualRevenueProfit",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsTotalRevenueMarginAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsTotalRevenueProfitAtCompletion",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "baselineVsActualRevenueRecognition",
      "type": "Boolean!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planVsActualRevenueRecognition",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailsRoleLoader_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "concreteType": "RoleFinancialBreakdown",
          "kind": "LinkedField",
          "name": "roleFinancialBreakdown",
          "plural": true,
          "selections": [
            (v11/*: any*/),
            (v12/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "FinancialNumbers",
              "kind": "LinkedField",
              "name": "financialNumbers",
              "plural": false,
              "selections": [
                {
                  "condition": "baselineRevenue",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineRevenue",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineCost",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineCost",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "billableActualTimeAndExpenses",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableActualTimeAndExpenses",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "actualCost",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "actualCost",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "actualProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "actualProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "billablePlannedTimeAndExpenses",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billablePlannedTimeAndExpenses",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "plannedCost",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "plannedCost",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "plannedProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "plannedProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "billableForecastTimeAndExpensesToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableForecastTimeAndExpensesToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "forecastCostToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecastCostToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "forecastProfitToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecastProfitToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "billableTotalTimeAndExpensesAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableTotalTimeAndExpensesAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalCostAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCostAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalProfitAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalProfitAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsBillableActualTimeAndExpenses",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsBillableActualTimeAndExpenses",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsActualCost",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsActualCost",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsActualProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsActualProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsTotalBillableTimeAndExpensesAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsTotalBillableTimeAndExpensesAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsTotalCostAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsTotalCostAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsTotalProfitAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsTotalProfitAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsBillableActualTimeAndExpenses",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsBillableActualTimeAndExpenses",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsActualCost",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsActualCost",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsActualProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsActualProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsBillableTotalTimeAndExpensesAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsBillableTotalTimeAndExpensesAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsTotalCostAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsTotalCostAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsTotalProfitAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsTotalProfitAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "invoiced",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invoiced",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "paid",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "paid",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsRegisteredMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsRegisteredMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsTotalTimeAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsTotalTimeAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "registeredMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "registeredMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "billableActualMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableActualMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "nonBillableActualMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "nonBillableActualMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "scopeApprovedMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "scopeApprovedMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "registeredVsScopeApprovedMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "registeredVsScopeApprovedMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "scopeApprovedVsRegisteredMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "scopeApprovedVsRegisteredMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "forecastTimeToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecastTimeToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalTimeAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalTimeAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalTimeAtCompletionVsScopeApprovedMinutes",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalTimeAtCompletionVsScopeApprovedMinutes",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "scopeApprovedVsTotalTimeAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "scopeApprovedVsTotalTimeAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineTimeAndExpenses",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineTimeAndExpenses",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "plannedRevenueProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "plannedRevenueProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "plannedRevenueMargin",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "plannedRevenueMargin",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalRevenueProfitAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalRevenueProfitAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "forecastRevenueProfitToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecastRevenueProfitToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "actualRevenueProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "actualRevenueProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineMargin",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineMargin",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "forecastRevenueMarginToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecastRevenueMarginToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalForecastRevenueToComplete",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalForecastRevenueToComplete",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "actualRevenueMargin",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "actualRevenueMargin",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "totalActualRevenueRecognition",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalActualRevenueRecognition",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsActualMargin",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsActualMargin",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsTotalRevenueAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsTotalRevenueAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsTotalMarginAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsTotalMarginAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsActualMargin",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsActualMargin",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsTotalRevenueAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsTotalRevenueAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsActualRevenueMargin",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsActualRevenueMargin",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsActualRevenueProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsActualRevenueProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsTotalRevenueMarginAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsTotalRevenueMarginAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsTotalRevenueProfitAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsTotalRevenueProfitAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsActualRevenueMargin",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsActualRevenueMargin",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsActualRevenueProfit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsActualRevenueProfit",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsTotalRevenueMarginAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsTotalRevenueMarginAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsTotalRevenueProfitAtCompletion",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsTotalRevenueProfitAtCompletion",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "baselineVsActualRevenueRecognition",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "baselineVsActualRevenueRecognition",
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "planVsActualRevenueRecognition",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "planVsActualRevenueRecognition",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "aggregateLevel",
              "variableName": "aggregateLevel"
            },
            {
              "items": [
                {
                  "kind": "Variable",
                  "name": "aggregates.0",
                  "variableName": "aggregate"
                }
              ],
              "kind": "ListValue",
              "name": "aggregates"
            },
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "kind": "Literal",
              "name": "filterRowsWithNoValues",
              "value": true
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "concreteType": "AggregatedRoleFinancialBreakdown",
          "kind": "LinkedField",
          "name": "aggregatedRoleFinancialBreakdown",
          "plural": true,
          "selections": [
            (v11/*: any*/),
            (v12/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "aggregatedFinancialNumbers",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '16129f6e0f54f8437edde316b3f1a441';

module.exports = node;
