import React, {useMemo} from 'react';
import {Table} from 'web-components';
import TotalsLoader, {totalsLoaderQuery} from './TotalsLoader';
import ProjectPortfolioPeriodRow from './ProjectPortfolioPeriodRow';
import ProjectPortfolioPeriodProjectRows from './ProjectPortfolioPeriodProjectRows';
import ProjectPortfolioPeriodProgramRows from './ProjectPortfolioPeriodProgramRows';
import {filtersToSearchQuery} from '../ProjectPortfolioReportUtils';
import {ForecastTooltipFormulaRenderer} from '../../../ForecastTooltipFormulaRenderer';
import ForecastTooltip from '../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {ProjectPortfolioReportTooltips} from '../ProjectPortfolioReportTooltips';
import {LoadMore} from '../../../loaders/LoadMore';
import {remapAndFormatFinancialMessage} from '../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import {GROUP_BY} from '../ProjectPortfolioReport';
import ProjectPortfolioPeriodConnectedProjectRows from './ProjectPortfolioPeriodConnectedProjectRows';
import ProjectPortfolioPeriodClientRows from './ProjectPortfolioPeriodClientRows';
import CompanySetupUtil from '../../../../forecast-app/shared/util/CompanySetupUtil';

export const ProjectPortfolioPeriodTable = ({
	theEyeOptionsNestedTree,
	filters,
	selectedDateRange: {startDate, endDate},
	setCsvDownloadFunction,
	hasBaseline,
	groupBy,
}) => {
	const formatMessage = remapAndFormatFinancialMessage;
	const pageSize = 100;

	const boolEyeOptions = useMemo(() => {
		const bools = {};
		theEyeOptionsNestedTree.forEach(eyeOption => {
			bools[eyeOption.name] = eyeOption.checked;
			if (eyeOption.nestedOptions) {
				eyeOption.nestedOptions.forEach(nestedOption => {
					bools[nestedOption.name] = nestedOption.checked;
					if (nestedOption.nestedOptions) {
						nestedOption.nestedOptions.forEach(subNestedOption => {
							bools[subNestedOption.name] = subNestedOption.checked;
						});
					}
				});
			}
		});
		return bools;
	}, [theEyeOptionsNestedTree]);

	const numSelectedNestedOptions = useMemo(() => {
		const numSelectedNestedOpts = {};
		theEyeOptionsNestedTree.forEach(eyeOption => {
			numSelectedNestedOpts[eyeOption.name] = 0;
			if (eyeOption.nestedOptions) {
				eyeOption.nestedOptions.forEach(nestedOption => {
					numSelectedNestedOpts[eyeOption.name] += nestedOption.checked ? 1 : 0;
					numSelectedNestedOpts[nestedOption.name] = 0;
					if (nestedOption.nestedOptions) {
						nestedOption.nestedOptions.forEach(subNestedOption => {
							numSelectedNestedOpts[nestedOption.name] += subNestedOption.checked ? 1 : 0;
						});
					}
				});
			}
		});
		return numSelectedNestedOpts;
	}, [theEyeOptionsNestedTree]);

	return (
		<Table paddedCells>
			<Table.Header>
				<Table.HeaderColumn flex={1} visible width="EXTRA_LARGE" />
				{/* Project Overview */}
				<Table.HeaderGrouping name={formatMessage({id: 'project_budget.project_overview'})}>
					<Table.HeaderColumn visible={boolEyeOptions.priorityLevel} usePadding align="left" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'project_settings.priority_level'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.winProbability} usePadding align="right" width="SMALL">
						<ForecastTooltip content={ProjectPortfolioReportTooltips.WinProbabilityTooltip(formatMessage)}>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'project_portfolio_report.win_prob'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.dates} usePadding align="left" width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.start'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.dates} usePadding align="left" width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.end'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.client} usePadding align="left" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'insights.component.list.column.client'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.status} usePadding align="center" width="EXTRA_SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.status'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.statusDescription} usePadding align="left" width="SUPER_LARGE">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.statusDescription'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.projectOwner} usePadding align="left" width="MEDIUM">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.project_owner'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.stage} usePadding align="left" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'insights.component.list.column.status'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.allocations} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'scheduling.menu.allocations'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.budgetType} usePadding align="left" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'new_project_modal.budget_type'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					{CompanySetupUtil.hasFinance() ? (
						<Table.HeaderColumn visible={boolEyeOptions.rateCard} usePadding align="left" width={'LARGE'}>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'insights.component.list.column.rateCard'})}
							</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
					) : (
						<Table.HeaderColumn visible={boolEyeOptions.rateCard} usePadding align="right" width={'MEDIUM'}>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.rate'})}</Table.HeaderColumn.Title>
						</Table.HeaderColumn>
					)}
					<Table.HeaderColumn visible={boolEyeOptions.progress} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.progress'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.progressEom} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'project_portfolio_report.progress_eom'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Project Hours */}
				<Table.HeaderGrouping name={formatMessage({id: 'project_portfolio_report.project_hours'})}>
					<Table.HeaderColumn visible={boolEyeOptions.baselineHours} usePadding align="right" width="MEDIUM">
						<ForecastTooltip content={ProjectPortfolioReportTooltips.BaselineHoursTooltip(formatMessage)}>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'project_portfolio_report.baseline_hours'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.totalScope} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.TotalScopeTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'project_portfolio_report.total_scope'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.approvedScope} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.ApprovedScopeTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'project_portfolio_report.approved_scope'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.registeredHours} usePadding align="right" width="MEDIUM">
						<ForecastTooltip content={ProjectPortfolioReportTooltips.RegisteredHoursTooltip(formatMessage)}>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'project_portfolio_report.registered_hours'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.remainingHours} usePadding align="right" width="MEDIUM">
						<ForecastTooltip content={ProjectPortfolioReportTooltips.RemainingHoursTooltip(formatMessage)}>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'project_portfolio_report.remaining_hours'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.forecastedHours} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.ForecastedHoursTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'project_portfolio_report.forecasted_hours'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Baseline */}
				<Table.HeaderGrouping
					name={numSelectedNestedOptions.baseline < 2 ? '' : formatMessage({id: 'project_section.baseline'})}
				>
					<Table.HeaderColumn visible={boolEyeOptions.baselineRevenue} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.BaselineRevenueTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.baseline < 2
											? 'project_budget.baseline_revenue'
											: 'baseline.revenue',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.baselineValueOfService} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({
								id: 'common.billable_value_of_service',
							})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.baselineCost} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.BaselineCostTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id: numSelectedNestedOptions.baseline < 2 ? 'project_budget.baseline_cost' : 'common.cost',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.baselineProfit} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.BaselineProfitTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.baseline < 2
											? 'project_budget.baseline_profit'
											: 'common.profit',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.baselineMargin} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.BaselineMarginTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.baseline < 2
											? 'project_budget.baseline_margin'
											: 'common.margin',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Plan */}
				<Table.HeaderGrouping name={numSelectedNestedOptions.planned < 2 ? '' : formatMessage({id: 'common.planned'})}>
					<Table.HeaderColumn visible={boolEyeOptions.estimatedRevenue} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({
								id:
									numSelectedNestedOptions.planned < 2
										? 'common.estimated_revenue'
										: 'project_budget.revenue',
							})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.planRevenue} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.PlanRevenueTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.planned < 2
											? 'project_budget.planned_billable_time_and_expenses'
											: 'project_budget.billable_time_and_expenses',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.planCost} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.PlanCostTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id: numSelectedNestedOptions.planned < 2 ? 'project_budget.planned_cost' : 'common.cost',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.planProfit} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.PlanProfitTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.planned < 2
											? 'project_budget.planned_profit'
											: 'common.profit',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.planMargin} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.PlanMarginTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.planned < 2
											? 'project_budget.planned_margin'
											: 'common.margin',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Actuals */}
				<Table.HeaderGrouping
					name={numSelectedNestedOptions.actual < 2 ? '' : formatMessage({id: 'project_budget.actuals'})}
				>
					<Table.HeaderColumn visible={boolEyeOptions.actualRevenue} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({
								id:
									numSelectedNestedOptions.actual < 2
										? 'project_budget.actual_revenue'
										: 'project_budget.revenue',
							})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.billableActualTimeAndExpenses}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.BillableActualTimeAndExpensesTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.actual < 2
											? 'project_budget.actual_billable_time_and_expenses'
											: 'project_budget.billable_time_and_expenses',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.actualCost} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.ActualCostTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id: numSelectedNestedOptions.actual < 2 ? 'common.actual_cost' : 'common.cost',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.actualProfit} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.ActualProfitTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id: numSelectedNestedOptions.actual < 2 ? 'project_budget.actual_profit' : 'common.profit',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.actualMargin} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.ActualMarginTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id: numSelectedNestedOptions.actual < 2 ? 'project_budget.actual_margin' : 'common.margin',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Forecast to complete */}
				<Table.HeaderGrouping
					name={
						numSelectedNestedOptions.remaining < 2
							? ''
							: formatMessage({
									id: 'project_budget.forecast_to_complete',
							  })
					}
				>
					<Table.HeaderColumn visible={boolEyeOptions.remainingWorkRevenue} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({
								id:
									numSelectedNestedOptions.remaining < 2
										? 'project_budget.remaining_work_revenue'
										: 'project_budget.revenue',
							})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.billableForecastTimeAndExpensesToComplete}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.BillableForecastTimeAndExpensesToCompleteTooltip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.remaining < 2
											? 'project_budget.forecast_billable_time'
											: 'project_budget.billable_time_and_expenses',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.forecastCostToComplete} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.ForecastCostToCompleteTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.remaining < 2
											? 'project_budget.forecast_cost_to_complete'
											: 'common.cost',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.forecastProfitToComplete}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.ForecastProfitToCompleteTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.remaining < 2
											? 'project_budget.forecast_profit_to_complete'
											: 'common.profit',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.forecastMarginToComplete}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.ForecastMarginToCompleteTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.remaining < 2
											? 'project_budget.forecast_margin_to_complete'
											: 'common.margin',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Forecasted */}
				<Table.HeaderGrouping
					name={
						numSelectedNestedOptions.forecasted < 2
							? ''
							: formatMessage({
									id: 'project_budget.total_at_completion',
							  })
					}
				>
					<Table.HeaderColumn visible={boolEyeOptions.projectTotalRevenue} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({
								id:
									numSelectedNestedOptions.forecasted < 2
										? 'common.projected_total_revenue'
										: 'project_budget.revenue',
							})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.forecastedRevenue} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.BillableTotalTimeAndExpensesAtCompletionTooltip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.forecasted < 2
											? 'project_budget.revenue_recognition.total_billable_tm_time_completion'
											: 'project_budget.billable_time_and_expenses',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.forecastedCost} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.TotalCostAtCompletionTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.forecasted < 2
											? 'project_budget.total_cost_at_completion'
											: 'common.cost',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.forecastedProfit} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.TotalProfitAtCompletionTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.forecasted < 2
											? 'project_budget.total_profit_at_completion'
											: 'common.profit',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.forecastedMargin} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.TotalMarginAtCompletionTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({
									id:
										numSelectedNestedOptions.forecasted < 2
											? 'project_budget.total_margin_at_completion'
											: 'common.margin',
								})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Invoiced */}
				<Table.HeaderGrouping name={numSelectedNestedOptions.invoice < 2 ? '' : formatMessage({id: 'common.invoiced'})}>
					<Table.HeaderColumn visible={boolEyeOptions.invoiced} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'project_portfolio_report.invoiced'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.paid} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'project_portfolio_report.invoiced_paid'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={formatMessage({id: 'project_budget.revenue_recognition'})}>
					<Table.HeaderColumn
						visible={boolEyeOptions.recognitionLockedRevenue}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'project_budget.from_locked_months'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.recognitionOpenRevenue} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'project_budget.from_open_months'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.recognitionAmount} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.recognitionProfit} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.RecognitionProfitTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'project_budget.profit'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.recognitionMargin} usePadding align="right" width="MEDIUM">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={ProjectPortfolioReportTooltips.RecognitionMarginTooltip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'project_budget.margin'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Fixed Price */}
				<Table.HeaderGrouping name={formatMessage({id: 'project_budget.fixed_price'})}>
					<Table.HeaderColumn visible={boolEyeOptions.fixedPriceRevenue} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>{formatMessage({id: 'project_budget.fixed_price'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Planned vs. Actual */}
				<Table.HeaderGrouping
					name={numSelectedNestedOptions.planActual < 2 ? '' : formatMessage({id: 'project_budget.plan_vs_actual'})}
				>
					<Table.HeaderColumn
						visible={boolEyeOptions.planBillableActualTimeAndExpenses}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{numSelectedNestedOptions.planActual < 2
								? formatMessage({id: 'project_budget.plan_vs_actual'}) + ' '
								: ''}
							{formatMessage({id: 'project_budget.revenue'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.planActualCost} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{numSelectedNestedOptions.planActual < 2
								? formatMessage({id: 'project_budget.plan_vs_actual'}) + ' '
								: ''}
							{formatMessage({id: 'common.cost'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.planActualProfit} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{numSelectedNestedOptions.planActual < 2
								? formatMessage({id: 'project_budget.plan_vs_actual'}) + ' '
								: ''}
							{formatMessage({id: 'common.profit'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Plan vs. Forecasted */}
				<Table.HeaderGrouping
					name={
						numSelectedNestedOptions.planForecasted < 2
							? ''
							: formatMessage({id: 'project_budget.plan_vs_forecast'})
					}
				>
					<Table.HeaderColumn visible={boolEyeOptions.planForecastedRevenue} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{numSelectedNestedOptions.planForecasted < 2
								? formatMessage({id: 'project_budget.plan_vs_forecast'}) + ' '
								: ''}
							{formatMessage({id: 'project_budget.revenue'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.planForecastedCost} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{numSelectedNestedOptions.planForecasted < 2
								? formatMessage({id: 'project_budget.plan_vs_forecast'}) + ' '
								: ''}
							{formatMessage({id: 'common.cost'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.planForecastedProfit} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{numSelectedNestedOptions.planForecasted < 2
								? formatMessage({id: 'project_budget.plan_vs_forecast'}) + ' '
								: ''}
							{formatMessage({id: 'common.profit'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Over/Under Servicing */}
				<Table.HeaderGrouping name={formatMessage({id: 'project_portfolio_report.over_under_servicing'})}>
					<Table.HeaderColumn visible={boolEyeOptions.projectedTotalRevenue} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'common.projected_total_revenue'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.projectedTotalBillableValueOfService}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'common.projected_total_billable_value_of_service'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.projectedTotalNonBillableValueOfService}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'common.projected_total_non_billable_value_of_service'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.projectedTotalValueOfService}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'common.projected_total_value_of_service'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.projectedBudgetRemaining}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'common.projected_budget_remaining'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.projectedOverUnderServicing}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'project_portfolio_report.projected_over_under_servicing'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Invoiced vs. Actual */}
				<Table.HeaderGrouping name={formatMessage({id: 'project_budget.accrued'})}>
					<Table.HeaderColumn visible={boolEyeOptions.invoicedActualInvoiced} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>{formatMessage({id: 'project_budget.invoiced'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.invoicedBillableActualTimeAndExpenses}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'project_budget.actual_billable_time_and_expenses'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.invoicedRecognitionLockedRevenue}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'project_budget.revenue_recognition_from_locked_months'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.accruedDeferred} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>{formatMessage({id: 'project_budget.accrued'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Labels */}
				<Table.HeaderGrouping name={formatMessage({id: 'common.labels'})}>
					<Table.HeaderColumn visible={boolEyeOptions.labels} usePadding align="center" width="EXTRA_LARGE">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.labels'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Retainer period target */}
				<Table.HeaderGrouping
					name={
						numSelectedNestedOptions.retainerPeriodTarget < 2
							? ''
							: formatMessage({id: 'project_budget.retainer_period_target'})
					}
				>
					<Table.HeaderColumn
						visible={boolEyeOptions.retainerPeriodTargetMinutes}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{numSelectedNestedOptions.retainerPeriodTarget < 2
								? formatMessage({
										id: 'project_budget.retainer_period_target',
								  }) + ' '
								: ''}
							{formatMessage({id: 'common.time'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.retainerPeriodTargetPrice}
						usePadding
						align="right"
						width="MEDIUM"
					>
						<Table.HeaderColumn.Title>
							{numSelectedNestedOptions.retainerPeriodTarget < 2
								? formatMessage({
										id: 'project_budget.retainer_period_target',
								  }) + ' '
								: ''}
							{formatMessage({id: 'project_budget.retainer_period_target_price'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
			</Table.Header>
			<LoadMore
				key="query-render-totals"
				query={totalsLoaderQuery}
				variables={{
					searchQuery: filtersToSearchQuery(filters),
					startYear: startDate ? startDate.year() : undefined,
					startMonth: startDate ? startDate.month() + 1 : undefined,
					startDay: startDate ? startDate.date() : undefined,
					endYear: endDate ? endDate.year() : undefined,
					endMonth: endDate ? endDate.month() + 1 : undefined,
					endDay: endDate ? endDate.date() : undefined,
				}}
				loader={<TotalsLoader />}
			>
				{({data}) => {
					return (
						<Table.Rows data={data} canExpand={false} hasHeader>
							{({rowData, tableColumnsProps}) => {
								return (
									<ProjectPortfolioPeriodRow
										financialNumbers={rowData.financialNumbers}
										rowData={rowData}
										tableColumnProps={tableColumnsProps}
										headerColumn={<Table.Column>Totals</Table.Column>}
									/>
								);
							}}
						</Table.Rows>
					);
				}}
			</LoadMore>
			{groupBy === GROUP_BY.PROGRAM ? (
				<ProjectPortfolioPeriodProgramRows
					setCsvDownloadFunction={setCsvDownloadFunction}
					hasBaseline={hasBaseline}
					pageSize={pageSize}
					startDate={startDate}
					endDate={endDate}
					filters={filters}
					boolEyeOptions={boolEyeOptions}
				></ProjectPortfolioPeriodProgramRows>
			) : groupBy === GROUP_BY.CONNECTED_PROJECT ? (
				<ProjectPortfolioPeriodConnectedProjectRows
					setCsvDownloadFunction={setCsvDownloadFunction}
					hasBaseline={hasBaseline}
					pageSize={pageSize}
					startDate={startDate}
					endDate={endDate}
					filters={filters}
					boolEyeOptions={boolEyeOptions}
				></ProjectPortfolioPeriodConnectedProjectRows>
			) : groupBy === GROUP_BY.CLIENT ? (
				<ProjectPortfolioPeriodClientRows
					setCsvDownloadFunction={setCsvDownloadFunction}
					hasBaseline={hasBaseline}
					pageSize={pageSize}
					startDate={startDate}
					endDate={endDate}
					filters={filters}
					boolEyeOptions={boolEyeOptions}
				></ProjectPortfolioPeriodClientRows>
			) : (
				<ProjectPortfolioPeriodProjectRows
					setCsvDownloadFunction={setCsvDownloadFunction}
					hasBaseline={hasBaseline}
					pageSize={pageSize}
					startDate={startDate}
					endDate={endDate}
					filters={filters}
					boolEyeOptions={boolEyeOptions}
				></ProjectPortfolioPeriodProjectRows>
			)}
		</Table>
	);
};
