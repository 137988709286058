import styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../css_variables';
import warningIcon from '../../../images/warning_icon_red.svg';
import emailIcon from '../../../images/email-action-unread-inactive.png';
import emailIconPurple from '../../../images/email-action-unread.png';

export const PageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex: 1;
	height: initial;
`;

export const PageContent = styled.div`
	padding: 0px; // 24px 0px 24px;
	color: ${CSS_CONSTANTS.v2_text_gray};
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`;

export const PageTitle = styled.div`
	padding: 24px 24px 0px 24px;
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 600;
	color: ${CSS_CONSTANTS.v2_text_gray};
	position: sticky;
	left: 66px;
`;

export const HeaderContainer = styled.div`
	padding: 16px 24px 24px;
	background: white;
	width: 100%;
	position: sticky;
	top: 0px;
	left: 0;
	z-index: 4;
	.header-bar {
		padding: 0;
	}
`;

export const TableWrapper = styled.div`
	padding: 0 24px 84px 24px;
	table {
		border-collapse: separate;
		th,
		td {
			text-align: right;
			height: 60px;
			min-width: 125px;
			max-width: 135px;
			&:first-child {
				min-width: 360px;
				//max-width: 400px;
			}
			&.time-reg-cell {
				height: 55px;
			}
			border-top: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
			border-left: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		}
		th {
			border-bottom: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		}

		&:last-child {
			border-bottom: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		}
	}
`;

export const TableHeaderStyle = styled.thead`
	padding-top: 24px;
`;

export const TableHeaderColumnStyle = styled.th`
	background: white;
	position: sticky;
	padding: 0 8px 0 16px;
	top: 0px;
	width: 100%;
	z-index: 3;

	&.highlighted {
		background-color: ${CSS_CONSTANTS.v2_list_background_purple_opaque};
	}

	&:last-child {
		background-color: ${CSS_CONSTANTS.v2_fixed_element_grey};
		border-right: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	}
	&.today {
		//background: #f8f2fe;
		border-top: 2px solid ${CSS_CONSTANTS.v2_branding_purple};
		border-left: 1px solid ${CSS_CONSTANTS.v2_branding_purple};
		border-right: 1px solid ${CSS_CONSTANTS.v2_branding_purple};
	}
`;

export const WeekRangeHeaderCell = styled.div`
	display: flex;
	flex-direction: row;
	font-size: 16px;
	.week-number {
		font-weight: 400;
	}
`;

export const WeekDayHeaderCell = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 9px;
	font-weight: 400;
	text-align: right;
	.weekday {
		text-transform: uppercase;
		margin-bottom: 3px;
		letter-spacing: 1px;
		color: ${CSS_CONSTANTS.v2_text_light_gray};
		font-weight: 600;
	}
	.day {
		font-size: 14px;
		font-weight: 600;
	}
`;

export const WeekTotalHeaderCell = styled.div`
	font-size: 14px;
	font-weight: 600;
	text-align: right;
`;

export const TimesheetsIconWrapper = styled.div`
	opacity: 0;
	align-self: center;
	cursor: pointer;
	margin-left: 4px;
	min-width: 24px;
	height: 24px;
	background-size: 24px;
	&:hover {
		opacity: 1;
	}
`;

export const PersonLineStyle = styled.tr`
	border-right: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	&:hover ${TimesheetsIconWrapper} {
		opacity: 1;
	}
	&.expandable {
		cursor: pointer;
	}
	&:first-child {
		td {
			border-top: none;
		}
	}

	&.first-time-reg-row {
		box-shadow: inset 0 8px 16px -8px rgba(0, 0, 0, 0.05);
		td {
			&:last-child {
				box-shadow: inset -8px 8px 16px -8px rgba(0, 0, 0, 0.05);
			}
		}
	}
	&.last-time-reg-row {
		box-shadow: inset 0 -8px 16px -8px rgba(0, 0, 0, 0.05);
		td {
			&:last-child {
				box-shadow: inset -8px -8px 16px -8px rgba(0, 0, 0, 0.05);
			}
		}
	}
	.warning-email-icon {
		cursor: pointer;
		height: 20px;
		width: 20px;
		background-size: contain;
		background-image: url(${warningIcon});
		background-position: center;
		background-repeat: no-repeat;
		&.hidden {
			opacity: 0;
		}
	}
	&:hover {
		.warning-email-icon {
			opacity: 1;
			margin-right: 5px;
			background-image: url(${emailIcon});
			&:hover {
				background-image: url(${emailIconPurple});
			}
		}
	}
	&:last-of-type {
		.today {
			border-bottom: 1px solid ${CSS_CONSTANTS.v2_branding_purple};
		}
	}
`;

export const PersonNameCell = styled.td`
	padding: 0 16px;
	.person-name-cell-inner {
		text-align: left;
		display: flex;
		flex-direction: row;
		.arrow {
			display: flex;
		}
		.empty-arrow {
			width: 12px;
		}
		.profile-picture {
			margin: 0 10px 0 16px;
		}
		.name-and-role {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			flex-grow: 1;
			.name {
				font-size: 13px;
				font-weight: 500;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			.role {
				font-size: 11px;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
		.deactivated {
			font-size: 11px;
			border: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
			border-radius: 11px;
			padding: 4px 8px;
			font-weight: 500;
			align-self: center;
		}
	}
`;

export const TimeRegistrationNameCell = styled.td`
	padding: 0 16px 0 42px;
	font-size: 13px;
	box-shadow: inset 8px 0px 10px -3px rgba(0, 0, 0, 0.05);
	//	box-shadow: inset 8px 0px 16px -8px rgba(0, 0, 0, 0.05);
	&:first-child {
	}

	.name-and-type {
		text-align: left;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;
		.name {
			display: flex;
			flex-grow: 1;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
			margin-right: 16px;
			align-items: center;
			gap: 4px;

			&.project {
				font-weight: 600;
				a {
					color: ${CSS_CONSTANTS.v2_text_gray};
					text-decoration: none;
					&:hover {
						color: ${CSS_CONSTANTS.v2_branding_purple};
					}
				}
			}
			&.project,
			&.task {
				cursor: pointer;
				.time-name:hover {
					color: ${CSS_CONSTANTS.v2_branding_purple};
				}
			}
			&.no-access {
				font-style: italic;
			}
			.time-name {
				align-self: center;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.project-indicator-component {
				margin-right: 9px;
			}
		}
		.type {
			text-align: right;
			font-style: italic;
			color: ${CSS_CONSTANTS.v2_text_light_gray};
		}
	}
`;

export const RoleNameCell = styled.td`
	padding: 0 80px;

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	.role {
		text-align: left;

		color: #a1a1a1;
		flex-grow: 1;
		font-size: 11px;
	}
`;

export const PersonDayCell = styled.td`
	padding: 0;
	&:last-child {
		background-color: ${CSS_CONSTANTS.v2_fixed_element_grey};
		border-right: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	}
	&.time-reg-cell {
		&:last-child {
			box-shadow: inset -8px -0px 10px -3px rgba(0, 0, 0, 0.05);
			//box-shadow: inset -8px 0px 16px -8px rgba(0, 0, 0, 0.05);
		}
	}
	&.today {
		border-left: 1px solid ${CSS_CONSTANTS.v2_branding_purple};
		border-right: 1px solid ${CSS_CONSTANTS.v2_branding_purple};
		//background: ${CSS_CONSTANTS.v2_list_background_purple};
	}
	&.non-working-day {
		background-image: linear-gradient(
			-45deg,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0) 40%,
			rgba(0, 0, 0, 0.05) 40%,
			rgba(0, 0, 0, 0.05) 50%,
			rgba(0, 0, 0, 0) 50%,
			rgba(0, 0, 0, 0) 90%,
			rgba(0, 0, 0, 0.05) 90%,
			rgba(0, 0, 0, 0.05)
		);
		background-size: 12px 12px;
	}

	.edit-button {
		opacity: 0;
	}
	&:hover .edit-button {
		opacity: 1;
	}
`;

export const PersonDayCellInner = styled.div`
	position: relative;
	height: 100%;
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-size: 13px;
	font-weight: 600;
	display: flex;
	justify-content: flex-end;
	padding-right: 8px;
	.time-worked {
		z-index: 2;
		align-self: center;
		display: flex;
		&.time-reg-cell {
			font-weight: 400;
			bottom: 12px;
		}

		.checkmark-icon,
		.warning-icon-title {
			align-self: center;
			svg {
				margin-top: 2px;
			}
			margin-right: 8px;
		}
		.total {
			align-self: center;
		}
		.locked {
			color: ${CSS_CONSTANTS.v2_text_light_gray};
		}
	}
	.background {
		z-index: 1;
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		height: 100%;
		transition: height 0.3s ease-in-out;
		.white-space-background {
			transition: height 0.3s ease-in-out;
			height: ${props => props.whiteSpaceHeight}%;
			background: transparent;
		}
		.time-off-background {
			transition: height 0.3s ease-in-out;
			height: ${props => props.timeOffHeight}%;
			background: #f3f3f3;
		}
		.time-worked-background {
			transition: height 0.3s ease-in-out;
			height: ${props => props.timeWorkedHeight}%;
			background: #eaf8ff;
		}
	}
`;

export const BillableTimeText = styled.div`
	margin-top: 4px;
	color: #727483;
	font-weight: 400;
`;

export const HiddenText = styled.span`
	opacity: 0;
	font-weight: 400;
	white-space: pre;
`;
