/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type timeRegisteredPerPersonPortfolio_viewer$ref = any;
export type timeRegisteredPerPersonPortfolioRefetchQueryVariables = {|
  shareKey?: ?string,
  weekPickerSelectedDate?: ?string,
  componentId?: ?string,
  personIds?: ?$ReadOnlyArray<?string>,
  teamIds?: ?$ReadOnlyArray<?string>,
  roleIds?: ?$ReadOnlyArray<?string>,
  labelIds?: ?$ReadOnlyArray<?string>,
|};
export type timeRegisteredPerPersonPortfolioRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: timeRegisteredPerPersonPortfolio_viewer$ref
  |}
|};
export type timeRegisteredPerPersonPortfolioRefetchQuery = {|
  variables: timeRegisteredPerPersonPortfolioRefetchQueryVariables,
  response: timeRegisteredPerPersonPortfolioRefetchQueryResponse,
|};
*/


/*
query timeRegisteredPerPersonPortfolioRefetchQuery(
  $shareKey: String
  $weekPickerSelectedDate: String
  $componentId: ID
  $personIds: [ID]
  $teamIds: [ID]
  $roleIds: [ID]
  $labelIds: [ID]
) {
  viewer {
    ...timeRegisteredPerPersonPortfolio_viewer_2fpBGs
    id
  }
}

fragment timeRegisteredPerPersonPortfolio_viewer_2fpBGs on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    timeRegisteredPerPersonPortfolio(weekPickerSelectedDate: $weekPickerSelectedDate, componentId: $componentId, personIds: $personIds, teamIds: $teamIds, roleIds: $roleIds, labelIds: $labelIds) {
      id
      startWeekOnMonday
      mondayRegistered
      mondayWorkingHours
      tuesdayRegistered
      tuesdayWorkingHours
      wednesdayRegistered
      wednesdayWorkingHours
      thursdayRegistered
      thursdayWorkingHours
      fridayRegistered
      fridayWorkingHours
      saturdayRegistered
      saturdayWorkingHours
      sundayRegistered
      sundayWorkingHours
      totalRegistered
      totalWorkingHours
      listData {
        id
        name
        role
        profilePictureId
        profilePictureDefaultId
        mondayRegistered
        mondayWorkingHours
        tuesdayRegistered
        tuesdayWorkingHours
        wednesdayRegistered
        wednesdayWorkingHours
        thursdayRegistered
        thursdayWorkingHours
        fridayRegistered
        fridayWorkingHours
        saturdayRegistered
        saturdayWorkingHours
        sundayRegistered
        sundayWorkingHours
        totalRegistered
        totalWorkingHours
        nestedListData {
          id
          name
          mondayRegistered
          tuesdayRegistered
          wednesdayRegistered
          thursdayRegistered
          fridayRegistered
          saturdayRegistered
          sundayRegistered
          totalRegistered
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "weekPickerSelectedDate",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "componentId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roleIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "labelIds",
    "type": "[ID]"
  }
],
v1 = {
  "kind": "Variable",
  "name": "componentId",
  "variableName": "componentId"
},
v2 = {
  "kind": "Variable",
  "name": "labelIds",
  "variableName": "labelIds"
},
v3 = {
  "kind": "Variable",
  "name": "personIds",
  "variableName": "personIds"
},
v4 = {
  "kind": "Variable",
  "name": "roleIds",
  "variableName": "roleIds"
},
v5 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v6 = {
  "kind": "Variable",
  "name": "teamIds",
  "variableName": "teamIds"
},
v7 = {
  "kind": "Variable",
  "name": "weekPickerSelectedDate",
  "variableName": "weekPickerSelectedDate"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mondayRegistered",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mondayWorkingHours",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesdayRegistered",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesdayWorkingHours",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesdayRegistered",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesdayWorkingHours",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursdayRegistered",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursdayWorkingHours",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fridayRegistered",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fridayWorkingHours",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturdayRegistered",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturdayWorkingHours",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sundayRegistered",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sundayWorkingHours",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalRegistered",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalWorkingHours",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "timeRegisteredPerPersonPortfolioRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "timeRegisteredPerPersonPortfolio_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "timeRegisteredPerPersonPortfolioRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v5/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "concreteType": "TimeRegisteredPerPersonPortfolio",
                "kind": "LinkedField",
                "name": "timeRegisteredPerPersonPortfolio",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startWeekOnMonday",
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeRegisteredPerPersonPortfolioListElement",
                    "kind": "LinkedField",
                    "name": "listData",
                    "plural": true,
                    "selections": [
                      (v8/*: any*/),
                      (v25/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureDefaultId",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimeRegisteredPerPersonPortfolioListElement",
                        "kind": "LinkedField",
                        "name": "nestedListData",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          (v25/*: any*/),
                          (v9/*: any*/),
                          (v11/*: any*/),
                          (v13/*: any*/),
                          (v15/*: any*/),
                          (v17/*: any*/),
                          (v19/*: any*/),
                          (v21/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "timeRegisteredPerPersonPortfolioRefetchQuery",
    "operationKind": "query",
    "text": "query timeRegisteredPerPersonPortfolioRefetchQuery(\n  $shareKey: String\n  $weekPickerSelectedDate: String\n  $componentId: ID\n  $personIds: [ID]\n  $teamIds: [ID]\n  $roleIds: [ID]\n  $labelIds: [ID]\n) {\n  viewer {\n    ...timeRegisteredPerPersonPortfolio_viewer_2fpBGs\n    id\n  }\n}\n\nfragment timeRegisteredPerPersonPortfolio_viewer_2fpBGs on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    timeRegisteredPerPersonPortfolio(weekPickerSelectedDate: $weekPickerSelectedDate, componentId: $componentId, personIds: $personIds, teamIds: $teamIds, roleIds: $roleIds, labelIds: $labelIds) {\n      id\n      startWeekOnMonday\n      mondayRegistered\n      mondayWorkingHours\n      tuesdayRegistered\n      tuesdayWorkingHours\n      wednesdayRegistered\n      wednesdayWorkingHours\n      thursdayRegistered\n      thursdayWorkingHours\n      fridayRegistered\n      fridayWorkingHours\n      saturdayRegistered\n      saturdayWorkingHours\n      sundayRegistered\n      sundayWorkingHours\n      totalRegistered\n      totalWorkingHours\n      listData {\n        id\n        name\n        role\n        profilePictureId\n        profilePictureDefaultId\n        mondayRegistered\n        mondayWorkingHours\n        tuesdayRegistered\n        tuesdayWorkingHours\n        wednesdayRegistered\n        wednesdayWorkingHours\n        thursdayRegistered\n        thursdayWorkingHours\n        fridayRegistered\n        fridayWorkingHours\n        saturdayRegistered\n        saturdayWorkingHours\n        sundayRegistered\n        sundayWorkingHours\n        totalRegistered\n        totalWorkingHours\n        nestedListData {\n          id\n          name\n          mondayRegistered\n          tuesdayRegistered\n          wednesdayRegistered\n          thursdayRegistered\n          fridayRegistered\n          saturdayRegistered\n          sundayRegistered\n          totalRegistered\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8f8ad9f23748d1c20cfa8d83e5126bba';

module.exports = node;
