/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type sprintBurndownV2Component_viewer$ref: FragmentReference;
declare export opaque type sprintBurndownV2Component_viewer$fragmentType: sprintBurndownV2Component_viewer$ref;
export type sprintBurndownV2Component_viewer = {|
  +insightComponentsData: ?{|
    +sprintBurndownV2: ?{|
      +name: ?string,
      +startYear: ?number,
      +startMonth: ?number,
      +startDay: ?number,
      +endYear: ?number,
      +endMonth: ?number,
      +endDay: ?number,
      +useHours: ?boolean,
      +sprintTimeBox: ?boolean,
      +monday: ?number,
      +tuesday: ?number,
      +wednesday: ?number,
      +thursday: ?number,
      +friday: ?number,
      +saturday: ?number,
      +sunday: ?number,
      +sprintBurndownV2Data: ?$ReadOnlyArray<?{|
        +time: ?string,
        +remaining: ?number,
        +forecast: ?number,
        +totalTasks: ?number,
        +doneTasks: ?number,
        +changes: ?$ReadOnlyArray<?{|
          +taskId: ?number,
          +companyTaskId: ?number,
          +action: ?string,
          +forecastChange: ?number,
          +remainingChange: ?number,
          +currentStatusColumn: ?string,
          +previousStatusColumn: ?string,
        |}>,
      |}>,
    |}
  |},
  +$refType: sprintBurndownV2Component_viewer$ref,
|};
export type sprintBurndownV2Component_viewer$data = sprintBurndownV2Component_viewer;
export type sprintBurndownV2Component_viewer$key = {
  +$data?: sprintBurndownV2Component_viewer$data,
  +$fragmentRefs: sprintBurndownV2Component_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sprintId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProjectGroupType",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "sprintBurndownV2Component_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "isProjectGroupType",
              "variableName": "isProjectGroupType"
            },
            {
              "kind": "Variable",
              "name": "sprintId",
              "variableName": "sprintId"
            }
          ],
          "concreteType": "SprintBurndownV2",
          "kind": "LinkedField",
          "name": "sprintBurndownV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDay",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "useHours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sprintTimeBox",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tuesday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "wednesday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thursday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "friday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "saturday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sunday",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "sprintBurndownV2DataType",
              "kind": "LinkedField",
              "name": "sprintBurndownV2Data",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "time",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "remaining",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "forecast",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "totalTasks",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "doneTasks",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SprintBurndownV2ChangesType",
                  "kind": "LinkedField",
                  "name": "changes",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "taskId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyTaskId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "action",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "forecastChange",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "remainingChange",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "currentStatusColumn",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "previousStatusColumn",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = 'eb37b9c056807ab12abdd58fcb055c7c';

module.exports = node;
