import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CanvasTimeline from './../canvas-timeline/canvas_timeline';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import Moment from 'moment';
import UnassignedRoleGroup from '../components/groups/unassigned_role_group';
import UnassignedRoleTaskGroup from '../components/groups/unassigned_role_task_group';
import {
	getCanvasTimelineDateFromMoment,
	getCanvasTimelineDateFromStringDate,
	getInitialEyeOptions,
	getMinutesInWeekdays,
	getMinutesInWeekdaysNew,
	getPersonGroups,
	getSchedulingOptionVisualizationMode,
	getVisualizationMode,
	getWeekdaysBetweenDates,
	GROUP_SECTION_ACTIONS_MENU_LEFT_SCHEDULE_PEOPLE,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_PADDING_LEFT,
	hasSchedulingAccess,
	hasViewOnlyAccess,
	HEATMAP_CELL_DETAIL_BOX_HEIGHT,
	HEATMAP_CELL_DETAIL_BOX_WIDTH,
	isPeopleScheduleCombinedModeWithLazyLoading,
	setInitialExpansion,
	UTILIZATION_FORMAT,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import {
	trackPerformance,
	trackPerformanceDataLoad,
	trackPerformanceInit,
} from '../canvas-timeline/canvas_timeline_performance_track';
import Util from '../../../forecast-app/shared/util/util';
import ActionMenu from '../action_menu';
import {MODULE_TYPES} from '../../../constants';
import UnassignedHeatmapItem from '../components/items/unassigned_heatmap_item';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {dispatch, EVENT_ID, subscribe, unsubscribe} from '../../../containers/event_manager';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import TaskDetailBox from '../components/task_detail_box';
import AllocationDetailBox from '../components/allocation_detail_box';
import * as tracking from '../../../tracking';
import {createHeader, createTopHeader, GROUP_BY} from './people_scheduling_header';
import {fetchData} from './people_scheduling_fetch';
import {handleNotification} from './people_scheduling_socket';
import ContextMenu from '../../../forecast-app/shared/components/context-menus/context_menu';
import {handlePeopleSchedulingMutation} from './people_scheduling_mutation_success';
import {getFilterFunctions} from '../../../forecast-app/shared/components/filters/filter_logic';
import {isClientUser} from '../../../forecast-app/shared/util/PermissionsUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import expandGroupIcon from '../../../images/components/scheduling/expand-group.svg';
import expandGroupClosedIcon from '../../../images/components/scheduling/expand-group-closed.svg';
import HeatmapCellDetailBox, {getHeatmapCellDetailBoxCoords} from '../components/heatmap_cell_detail_box';

import {
	drawIncrementalLoadMoreOverlay,
	getOverlayCanvasDate,
	preventScrollingPastLoadMoreOverlay,
} from '../loading/LoadMoreUtil';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {
	getGroupCache,
	isRecalculationNeeded,
	openPersonUtilizationModal,
	recalculateGroupHeatmapCache,
} from '../heatmap/HeatmapLogic';
import {trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import {
	ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN,
	ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD,
	ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT,
	EYE_OPTION_NAME,
} from '../constants';
import {findProject, isEventFromScheduling} from '../utils';
import {
	canUseAllocationControls,
	updateAllocationItemsVisibility,
} from '../components/allocation_controls/AllocationControlsCanvasUtils';
import {filterGroupsAndItems} from './PeopleSchedulingFilterLogic';
import {
	PEOPLE_SCHEDULING_FILTERS,
	PEOPLE_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY,
	PEOPLE_SCHEDULING_VISUALIZATION_MODE_STORAGE_KEY,
} from './PeopleSchedulingConstants';
import CanvasTooltip from '../components/CanvasTooltip';
import {getGroupsAndItemsFromProps, getUnassignedCountMap} from './PeopleSchedulingUtils';
import DataManager, {DATA_ENTITIES} from '../DataManager';
import IncrementalLoadingOverlay, {LOAD_MORE, LOAD_MORE_DIRECTION} from '../loading/IncrementalLoadingOverlay';
import RecalculationManager from '../RecalculationManager';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import ComposeManager from '../ComposeManager';
import TaskItem from '../components/items/task_item';
import EventManager from '../EventManager';
import {generateNoAccessHeatmap} from '../heatmap/AnonymizedHeatmapLogic';
import IDManager, {NO_ROLE, PERSON_ALLOCATIONS_GROUP} from '../IDManager';

class CanvasPeopleScheduling extends Component {
	// Used for identifying minified components in cypress tests
	static displayName = 'CanvasPeopleScheduling';

	constructor(props) {
		super(props);
		const pageName = 'Schedule People';
		trackPerformanceInit(pageName);

		const todayDate = getCanvasTimelineDateFromMoment(Moment());

		const labelFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-label')) || [];
		const projectFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-project')) || [];
		const clientFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-client')) || [];
		const roleFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-role')) || [];
		const projectStatusFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-projectStatus')) || [];
		const projectStageFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-projectStage')) || [];
		const personFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-person')) || [];
		const teamFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-team')) || [];

		const company = props.viewer.company;
		const visualizationMode = getSchedulingOptionVisualizationMode(
			company,
			PEOPLE_SCHEDULING_VISUALIZATION_MODE_STORAGE_KEY,
			false
		);

		const schedulingOptions = {
			hideEmpty: localStorage.getItem('peopleScheduling_hideEmpty')
				? localStorage.getItem('peopleScheduling_hideEmpty') === 'true'
				: false,
			utilizationFormat: localStorage.getItem(PEOPLE_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY)
				? localStorage.getItem(PEOPLE_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY)
				: UTILIZATION_FORMAT.HOURS,
			calcWin:
				canUseAllocationControls(visualizationMode) && hasModule(MODULE_TYPES.CALC_WIN_PERCENTAGE)
					? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN) === 'true'
					: false,
			hideSoft:
				canUseAllocationControls(visualizationMode) && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
					? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT) === 'true'
					: true,
			hideHard:
				canUseAllocationControls(visualizationMode) && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
					? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD) === 'true'
					: false,
			visualizationMode: visualizationMode,
		};
		const heatmapFiltering =
			hasFeatureFlag('people_scheduling_enable_filter_heatmap_issue') ||
			(hasFeatureFlag('combined_heatmap_logic_extensions') && Util.isMixedAllocationModeEnabled(company));
		this.state = {
			loading: true,
			heatmapFiltering,
			dataLoaded: false,
			isCollapsableSectionExpanded: false,
			todayDate,
			labelFilterValue,
			projectFilterValue,
			clientFilterValue,
			roleFilterValue,
			projectStatusFilterValue,
			projectStageFilterValue,
			personFilterValue,
			teamFilterValue,
			searchFilterValue: '',
			hasInitialWorkerRun: false,
			splitAllocationBarData: {},
			[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.LEFT]: false,
			[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.RIGHT]: false,
			[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]: getOverlayCanvasDate(this, null, LOAD_MORE_DIRECTION.LEFT),
			[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]: getOverlayCanvasDate(this, null, LOAD_MORE_DIRECTION.RIGHT),
			schedulingOptions,
			groupBy:
				localStorage.getItem('peopleScheduling_groupBy') && !props.isMySchedule
					? localStorage.getItem('peopleScheduling_groupBy')
					: GROUP_BY.NONE,
		};

		this.disableLoadMore =
			Util.isMixedAllocationModeEnabled(company) && !hasFeatureFlag('combined_mode_performance_improvements');
		this.disableUnassignedTaskCount = isPeopleScheduleCombinedModeWithLazyLoading(this);

		this.cachedUnassignedTasksCount = new Map();
		this.heatmapCache = new Map();
		RecalculationManager.clearAll();

		this.onSchedulingModalMutationSuccess = this.onSchedulingModalMutationSuccess.bind(this);
		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingModalMutationSuccess);

		this.reloadData = this.reloadData.bind(this);
		subscribe(EVENT_ID.SCHEDULING_RELOAD_DATA, this.reloadData);

		this.onClick = event => EventManager.onClick(this, event);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.onCypressUTModalEvent = this.onCypressUTModalEvent.bind(this);
		this.onMouseMove = event => EventManager.onMouseMove(this, event);

		this.onSavedFiltersUpdate = this.onSavedFiltersUpdate.bind(this);
		subscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersUpdate);
		this.handleSocketNotify = this.handleSocketNotify.bind(this);

		this.doFetchFullData(props, false);

		this.superPropertyChecksum = trackPage(pageName, undefined, {visualizationMode});
		this.viewCompanySchedule = hasSchedulingAccess(this) && !props.isMySchedule;
	}

	componentDidMount() {
		tracking.trackPage('scheduling-people');

		window.addEventListener('click', this.onClick);
		window.addEventListener('keypress', this.onKeyPress);
		window.addEventListener('mousemove', this.onMouseMove);
		window.addEventListener('cypress_ut_modal', this.onCypressUTModalEvent);

		subscribe(EVENT_ID.SOCKET_NOTIFY, this.handleSocketNotify);
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);

		this.heatmapCache.clear();

		window.removeEventListener('click', this.onClick.bind(this));
		window.removeEventListener('keypress', this.onKeyPress);
		window.removeEventListener('mousemove', this.onMouseMove);
		window.removeEventListener('cypress_ut_modal', this.onCypressUTModalEvent);

		unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingModalMutationSuccess);
		unsubscribe(EVENT_ID.SCHEDULING_RELOAD_DATA, this.reloadData);
		unsubscribe(EVENT_ID.SOCKET_NOTIFY, this.handleSocketNotify);
	}

	doFetchFullData(props, loadAllData = false) {
		trackPerformanceDataLoad(fetchData(this, loadAllData)).then(dataArray => {
			const data = dataArray[0];
			if (!data || data.error) {
				showModal({
					type: MODAL_TYPE.MUTATION_ERROR,
					isNetworkError: false,
					reload_to_upcoming: true,
				});
			} else {
				data.persons.forEach(person => {
					person.startDate = getCanvasTimelineDateFromStringDate(person.startDate);
					person.endDate = getCanvasTimelineDateFromStringDate(person.endDate);
				});

				this.resetState(props, data, loadAllData);
			}
		});
	}

	reloadData() {
		this.clearCachedUnassignedTasksCount();
		this.doFetchFullData(this.props, this.state.allDataLoaded);
	}

	resetState(props, data, loadAllData = false) {
		const resetProps = props || this.props;
		const resetData = data || this.state.data;

		if (isClientUser()) {
			this.props.history.push('/');
			return;
		}

		// Lookup maps
		DataManager.setLookupMaps(this, resetData);

		this.setState(
			{
				viewer: resetData.viewer,
				data: resetData,
			},
			() => {
				if (!hasFeatureFlag('inverted_pto_non_working_days')) {
					DataManager.constructNonWorkingDaysMap(this);
				}

				generateNoAccessHeatmap(this);

				const {groups, items} = getGroupsAndItemsFromProps(this, resetProps, resetData);

				setInitialExpansion(this, groups);

				const filters = this.getInitFilters(resetData);
				const filterFunctions = getFilterFunctions(filters);

				this.setState(
					{
						collapsableSectionGroups: this.getCollapsableSectionGroupsFromProps(resetProps, resetData),
						dataLoaded: true,
						savingMutation: false,
						groups,
						items,
						filters,
						project: findProject(resetData.projects, this.props.projectId),
						dependencies: resetData.dependencies,
						isShowingProjectTimelineSimulationMode: false,
						eyeOptions: getInitialEyeOptions(this, resetData),
						filterFunctions,
						allDataLoaded: loadAllData,
						allDataLoading: false,
						heatmapInitializedByPerson: new Map(),
					},
					() => {
						if (loadAllData) {
							resetData.persons.forEach(person => {
								recalculateGroupHeatmapCache(this, person.id);
							});
							resetData.roles.forEach(role => {
								recalculateGroupHeatmapCache(this, role.id);
							});
							this.clearCachedUnassignedTasksCount();
						}

						updateAllocationItemsVisibility(this);

						if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
							DataManager.constructVisibleItemTrees(this);
						}

						this.setState({items: this.state.items, loading: false}, () =>
							this.redrawCanvasTimeline({preventFiltering: false, isInitialLoad: true, allowRedraw: true})
						);

						if (this.mutationResponse) {
							this.onSchedulingModalMutationSuccess(this.mutationResponse);
						}
					}
				);
			}
		);
	}

	getInitFilters(data) {
		let filters;

		if (localStorage.getItem(PEOPLE_SCHEDULING_FILTERS) === 'undefined') {
			localStorage.removeItem(PEOPLE_SCHEDULING_FILTERS);
		}
		if (localStorage.getItem(PEOPLE_SCHEDULING_FILTERS)) {
			const localStorageFilters = JSON.parse(localStorage.getItem(PEOPLE_SCHEDULING_FILTERS));

			if (localStorageFilters && Object.keys(localStorageFilters).length > 0) {
				filters = localStorageFilters;
			}
		}

		const isUsingProjectAllocation = getVisualizationMode(
			this.state.schedulingOptions,
			data.company,
			VISUALIZATION_MODE.ALLOCATION
		);
		if (isUsingProjectAllocation && filters?.task) {
			// if the  isUsingProjectAllocation ignore the task filters saved in the localStorage
			filters.task = {};
		}

		return filters;
	}

	handleSocketNotify(dataList) {
		if (this.state.data) {
			handleNotification(this, dataList);
		}
	}

	getData() {
		return this.state.data;
	}

	getFilterData() {
		return this.state.data;
	}

	getViewer() {
		return this.state.viewer;
	}

	onSavedFiltersUpdate(response) {
		const stateData = this.getFilterData();
		if (response.createFilter) {
			stateData.personFilters.push(response.createFilter.filter.node);
			this.setState({data: stateData});
		} else if (response.updateFilter) {
			this.setState({data: stateData});
		} else if (response.deleteFilter) {
			stateData.personFilters = stateData.personFilters.filter(
				filter => filter.id !== response.deleteFilter.deletedFilterId
			);
			this.setState({data: stateData});
		}
	}

	onKeyPress(e) {
		if (!this.getData()) {
			return;
		}

		const isCalculationSwitchEnabled =
			this.getData().viewer.email &&
			typeof this.getData().viewer.email === 'string' &&
			this.getData().viewer.email.endsWith('@forecast.it') &&
			(this.getData().viewer.email.toLowerCase().includes('j') ||
				this.getData().viewer.email.toLowerCase().includes('n'));
		if (isCalculationSwitchEnabled && e.key === 'j') {
			this.isCalculationSwitched = !this.isCalculationSwitched;
			let isInActualMode = getVisualizationMode(
				this.state.schedulingOptions,
				this.getData().company,
				VISUALIZATION_MODE.TASK_ACTUAL
			);
			if (this.isCalculationSwitched) {
				isInActualMode = !isInActualMode;
			}
			createToast({
				duration: 5000,
				message: isInActualMode ? 'Switched to actual calculation mode' : 'Switched to planned calculation mode',
			});
			this.redrawCanvasTimeline({preventFiltering: false});
		}
	}

	onCypressUTModalEvent(e) {
		openPersonUtilizationModal(this, e.detail.person, e.detail.step);
	}

	onSchedulingModalMutationSuccess(response, args) {
		if (!isEventFromScheduling(args)) {
			return;
		}
		const data = this.getData();
		if (!data) {
			this.mutationResponse = response;
			this.setState({savingMutation: true});
			return;
		}

		handlePeopleSchedulingMutation(this, response, args);
	}

	isProjectGroupingDisabled() {
		return false;
	}

	isHeatMapDisabled() {
		return !this.state.eyeOptions?.find(option => option.name === EYE_OPTION_NAME.SHOW_HEATMAP)?.checked;
	}

	onTaskGroupArrowClick(isPrevious) {
		const {hoveredTaskGroupId} = this.state;
		if (!hoveredTaskGroupId) return;

		const isPersonTasksGroup = hoveredTaskGroupId.includes('-alltask');
		const isPersonAllocationsGroup = hoveredTaskGroupId.includes(PERSON_ALLOCATIONS_GROUP);

		let placeholderGroupIds = [];
		if (isPersonTasksGroup || isPersonAllocationsGroup) {
			const personGroup = getPersonGroups(this.state.groups).find(group => group.id === hoveredTaskGroupId.split('-')[0]);
			placeholderGroupIds = personGroup.groups.find(group => group.id === hoveredTaskGroupId).groupIds;
		}

		const timeline = this.timeline;
		const screenCenterDate = timeline.startDate + timeline.state.canvasWidth / timeline.pixelsPerDay / 2;
		//Make minimum scroll distance 3 days because it makes little sense to fire the animation just to scroll by a few pixels
		const items = this.state.items.filter(
			item =>
				!item.filtered &&
				// removed conditions to make this work for the project allocations and task allocations too, if there is a good reason for having them we need to add logic for handling project allocations too
				// item.itemType === ITEM_TYPE.TASK &&
				// !item.data.task.done &&
				(isPersonTasksGroup || isPersonAllocationsGroup
					? placeholderGroupIds.includes(item.groupId)
					: item.groupId === hoveredTaskGroupId) &&
				(isPrevious ? item.startDate - screenCenterDate < -2 : item.startDate - screenCenterDate > 2)
		);

		if (!items.length) return; //Already scrolled to furthermost task
		//Sort by startDate descending, we want to scroll to the item that is the closest to the center in the scrolling direction
		items.sort((a, b) => {
			if (a.startDate < b.startDate) return isPrevious ? 1 : -1;
			if (b.startDate < a.startDate) return isPrevious ? -1 : 1;
			return 0;
		});
		timeline.scrollToCanvasDate(items[0].startDate);
		tracking.trackEvent('Scheduling left/right arrow navigation used');
		trackEvent('Scheduling Arrow Navigation', 'Used');
	}

	getCollapsableSectionGroupsFromProps(props, data) {
		let groups = [];

		const noRoleTaskGroups = [new UnassignedRoleTaskGroup(this, ComposeManager.composeUnassignedRoleTaskGroup(this, null))];

		groups.push(new UnassignedRoleGroup(this, ComposeManager.composeUnassignedRoleGroup(this, null, noRoleTaskGroups)));

		for (const role of data.roles.sort((a, b) => a.name.localeCompare(b.name))) {
			const taskGroups = [new UnassignedRoleTaskGroup(this, ComposeManager.composeUnassignedRoleTaskGroup(this, role))];

			groups.push(new UnassignedRoleGroup(this, ComposeManager.composeUnassignedRoleGroup(this, role, taskGroups)));
		}

		return groups;
	}

	getTimeRegGroupId(timeReg) {
		const {schedulingOptions} = this.state;
		const {company} = this.getData();

		const isCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
		if (isCombinedMode) {
			if (timeReg.taskId) {
				const task = DataManager.getTaskById(this, timeReg.taskId);
				return IDManager.getProjectGroupId(this, timeReg.personId, task.projectId);
			} else {
				return IDManager.getNonProjectTimeGroupId(this, timeReg.personId);
			}
		}

		return timeReg.personId;
	}

	createEntityItem(createdItems, entity, entityData) {
		switch (entity) {
			case DATA_ENTITIES.ALLOCATIONS:
				const projectAllocationItemData = ComposeManager.composeProjectAllocation(this, entityData);

				if (projectAllocationItemData) {
					const item = new ProjectAllocationItem(this, projectAllocationItemData);

					if (item.isValid()) {
						createdItems.add(item);
					}
				}

				break;
			case DATA_ENTITIES.TASKS:
				const addTaskItem = (assignedPersonId, task) => {
					const taskItemData = ComposeManager.composeTaskItem(this, task, assignedPersonId);

					if (taskItemData) {
						createdItems.add(new TaskItem(this, taskItemData));
					}
				};

				if (entityData.assignedPersons?.length > 0) {
					for (const personId of entityData.assignedPersons) {
						addTaskItem(personId, entityData);
					}
				} else {
					addTaskItem(null, entityData);
				}

				break;
			default:
				break;
		}
	}

	composeUnassignedHeatmapRow(group, stepDataArray, timelineStartDate, pixelsPerDay, timelineMinorStep) {
		const roleId = hasFeatureFlag('scheduling_recalculation_tree') ? group.id : group.id === NO_ROLE ? null : group.id;
		const {company} = this.getData();
		const {items, todayDate} = this.state;
		//Company default working time for each week day
		const companyWorkingMinutes = [
			company.monday,
			company.tuesday,
			company.wednesday,
			company.thursday,
			company.friday,
			company.saturday,
			company.sunday,
		];

		const cachedRoleItem = getGroupCache(this, roleId);

		const hasHeatmapImprovements = hasFeatureFlag('improving_heatmap_frontend_performance');

		const heatmapItems = [];
		for (const stepData of stepDataArray) {
			let minutesAllocated = 0;
			let {startDate, endDate} = stepData;
			const dateString = `${roleId}-${timelineMinorStep}-${startDate}`;

			if (
				cachedRoleItem.has(dateString) &&
				!isRecalculationNeeded(roleId, cachedRoleItem, timelineMinorStep, startDate, endDate)
			) {
				const cachedItem = cachedRoleItem.get(dateString);
				//cachedItem will be null if heatmap is empty
				if (cachedItem) {
					heatmapItems.push(
						new UnassignedHeatmapItem(
							this,
							ComposeManager.composeUnassignedHeatmapItem(this, group, stepData, cachedItem)
						)
					);
				}
				continue;
			}

			for (const item of items) {
				const {task, isHourEstimated, minutesPerEstimationPoint} = item.data;
				if (
					item.filtered ||
					item.startDate > endDate ||
					item.endDate < startDate - 1 ||
					!task ||
					item.groupId !== IDManager.getUnassignedTaskGroupId(this, roleId)
				)
					continue;

				let {timeLeftMinutesWithoutFutureTimeRegs} = task;
				//Peace out if task has no remaining since it will not affect heatmap regardless of its position
				if (timeLeftMinutesWithoutFutureTimeRegs <= 0) continue;

				if (!isHourEstimated) {
					timeLeftMinutesWithoutFutureTimeRegs *= minutesPerEstimationPoint;
				}

				const isInThePast = item.endDate < todayDate;

				//If task is completely in the past or completely in the future, use normal task start date for calculation
				//If task is partially in the past and partially in the future, use today as the starting date
				const calculationStartDate = isInThePast ? item.startDate : Math.max(todayDate, item.startDate);

				//How many weekdays are in the task (ex: 1 monday, 1 tuesday, 1 wednesday)
				const taskTotalWeekdays = getWeekdaysBetweenDates(calculationStartDate, item.endDate);

				//Weekdays which are within the current loop period
				const taskLoopWeekdays = getWeekdaysBetweenDates(
					Math.max(calculationStartDate, startDate),
					Math.min(item.endDate, endDate)
				);

				//Total length of task in days
				const taskTotalDayCount = taskTotalWeekdays.reduce((total, weekdayCount) => {
					return total + weekdayCount;
				}, 0);

				//Total length of task in days minus days on which the company has 0 work minutes
				const taskWorkingDayCount = taskTotalWeekdays.reduce((total, weekdayCount, index) => {
					return total + (companyWorkingMinutes[index] ? weekdayCount : 0);
				}, 0);

				//Number of days within current loop period
				const taskLoopTotalDayCount = taskLoopWeekdays.reduce((total, weekdayCount) => {
					return total + weekdayCount;
				}, 0);

				//Number of days within current loop period minus days on which the company has 0 work minutes
				const taskLoopWorkingDayCount = taskLoopWeekdays.reduce((total, weekdayCount, index) => {
					return total + (companyWorkingMinutes[index] ? weekdayCount : 0);
				}, 0);

				//If there are any working days during the task's period, spread timeLeft evenly among working days only
				//If there are no working days during the task's period, spread timeLeft evenly among all days
				if (taskWorkingDayCount) {
					minutesAllocated += (timeLeftMinutesWithoutFutureTimeRegs * taskLoopWorkingDayCount) / taskWorkingDayCount;
				} else {
					minutesAllocated += (timeLeftMinutesWithoutFutureTimeRegs * taskLoopTotalDayCount) / taskTotalDayCount;
				}
			}

			const minutesAvailable = hasHeatmapImprovements
				? getMinutesInWeekdaysNew(company, getWeekdaysBetweenDates(startDate, endDate))
				: getMinutesInWeekdays(company, getWeekdaysBetweenDates(startDate, endDate));
			const requiredPersonCount = Math.ceil(minutesAllocated / minutesAvailable);

			const hoursAllocatedText = Util.convertMinutesToFullHour(minutesAllocated, this.props.intl);
			const roleCacheData = {requiredPersonCount, hoursAllocatedText};
			cachedRoleItem.set(dateString, minutesAllocated ? roleCacheData : null);
			RecalculationManager.clearNeedsRecalculation(roleId, timelineMinorStep, startDate, endDate);
			heatmapItems.push(
				new UnassignedHeatmapItem(
					this,
					ComposeManager.composeUnassignedHeatmapItem(this, group, stepData, roleCacheData)
				)
			);
		}
		return heatmapItems;
	}

	clearCachedUnassignedTasksCount() {
		this.cachedUnassignedTasksCount = getUnassignedCountMap(this);
	}

	getUnassignedTaskCount(roleId = null) {
		return this.cachedUnassignedTasksCount.get(roleId) || 0;
	}

	resetRecalculateSteps() {
		this.setState({recalculateSteps: false});
	}

	redrawCanvasTimeline(args) {
		if (!args.preventFiltering) {
			trackPerformance('Filtered', () => filterGroupsAndItems(this));
		}

		dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, args);
	}

	render() {
		if (!this.state.dataLoaded) {
			return (
				<div key={'c1'} className="canvas-scheduling">
					{createTopHeader(this)}
					<div className="is-loading" />
				</div>
			);
		}

		const {
			data,
			groups,
			collapsableSectionGroups,
			items,
			showCollapsedActionMenu,
			collapsedActionMenuY,
			showExpandedActionMenu,
			expandedActionMenuY,
			actionMenuOptions,
			showArrowClickableArea,
			arrowClickableAreaY,
			collapsedActionMenuData,
			showDetailBox,
			detailBoxX,
			detailBoxY,
			detailBoxData,
			eyeOptions,
			recalculateSteps,
			contextMenuX,
			contextMenuY,
			contextMenuOptions,
			splitAllocationBarData,
			showCanvasTooltip,
			canvasTooltipData,
			canvasTooltipX,
			canvasTooltipY,
			schedulingOptions,
		} = this.state;

		const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.ALLOCATION);
		const isUsingCombinationMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);

		const {dayData, startDate} = this.props;

		const {formatMessage} = this.props.intl;

		const unassignedEyeOption = this.state.eyeOptions.find(option => option.name === 'unassigned-tasks');
		const showUnassignedTasks =
			!isUsingProjectAllocation && !isUsingCombinationMode && this.viewCompanySchedule && unassignedEyeOption?.checked;
		const isCollapsableSectionExpanded = showUnassignedTasks && this.state.isCollapsableSectionExpanded;
		const topLeftComponent = !showUnassignedTasks ? (
			<div className="unassigned-section-controller collaborator" data-userpilot={'schedule-unassigned-section'} />
		) : (
			<div
				className={'unassigned-section-controller-restyled'}
				data-userpilot={'schedule-unassigned-section'}
				onClick={() => {
					const expanded = !isCollapsableSectionExpanded;
					trackEvent('People Scheduling Unassigned Tasks', expanded ? 'Expanded' : 'Collapsed');
					this.setState({isCollapsableSectionExpanded: expanded});
				}}
			>
				<img
					src={isCollapsableSectionExpanded ? expandGroupIcon : expandGroupClosedIcon}
					alt={isCollapsableSectionExpanded ? 'Collapse section' : 'Expand section'}
					style={{marginLeft: GROUP_SECTION_PADDING_LEFT}}
					className={'group-expand-arrow-restyled'}
					width={GROUP_SECTION_EXPAND_ICON_WIDTH}
				/>
				{!this.disableUnassignedTaskCount && <div className="task-count-text">{this.getUnassignedTaskCount()}</div>}
				<div className="text-container">
					<div className="unassigned-text">{formatMessage({id: 'scheduling.unassigned_tasks'})}</div>
				</div>
			</div>
		);

		let width, height, top, left;
		if (this.timeline) {
			width = window.innerWidth;
			height = window.innerHeight - this.timeline.state.canvasOffsetTop;
			top = this.timeline.state.canvasOffsetTop;
			left = 0;
		}

		// heatmap cell detail box
		const {
			heatmapCellDetailBoxX,
			heatmapCellDetailBoxY,
			showHeatmapDetailBoxRight,
			showHeatmapDetailBoxCenter,
			showHeatmapDetailBoxBottom,
		} = getHeatmapCellDetailBoxCoords(detailBoxData?.heatmapData);

		const updateTimelineRef = timeline => {
			if (timeline && this.state.loading) {
				// Don't draw canvas while initializing data
				if (this.state.loading) {
					timeline.preventRedraw = true;
				}

				this.props.setTimeline(timeline);
				this.timeline = timeline;
			}
		};

		return (
			<>
				{createTopHeader(this)}
				<div className="control-bar">{createHeader(this)}</div>
				<div
					className="canvas-people-scheduling"
					onClick={e => {
						//this.setState({showActionMenu: false, actionMenuExpanded: false});
					}}
				>
					<div className="hover-effects-container">
						{contextMenuX && contextMenuY && contextMenuOptions && contextMenuOptions.length ? (
							// we are setting the key to be the sum of the coordinates because this will cause a rerender of the context menu if the user right clicks on another item
							// solves the issue of the context menu not updating the position
							<ContextMenu
								key={contextMenuX + contextMenuY}
								cy="canvas-people-scheduling-context-menu"
								options={contextMenuOptions}
								contextMenuPosition={{x: contextMenuX, y: contextMenuY}}
							/>
						) : null}
						{showCollapsedActionMenu &&
						(!showExpandedActionMenu || collapsedActionMenuY !== expandedActionMenuY) &&
						(!collapsedActionMenuData.project ||
							!hasViewOnlyAccess(
								this,
								collapsedActionMenuData.project?.id,
								collapsedActionMenuData.projectGroup?.id
							)) ? (
							<div
								className="people-scheduling-actions-menu"
								style={{
									top: collapsedActionMenuY,
									left: GROUP_SECTION_ACTIONS_MENU_LEFT_SCHEDULE_PEOPLE,
								}}
							>
								<ActionMenu
									options={[]}
									onExpansionToggle={() => EventManager.onExpandActionMenu(this)}
									expanded={false}
									useGreyIcon={true}
								/>
							</div>
						) : null}
						{showExpandedActionMenu &&
						actionMenuOptions &&
						(!collapsedActionMenuData.project ||
							!hasViewOnlyAccess(
								this,
								collapsedActionMenuData.project?.id,
								collapsedActionMenuData.projectGroup?.id
							)) ? (
							<div
								className="people-scheduling-actions-menu"
								style={{
									top: expandedActionMenuY,
									left: GROUP_SECTION_ACTIONS_MENU_LEFT_SCHEDULE_PEOPLE,
								}}
							>
								<ActionMenu
									options={actionMenuOptions}
									onExpansionToggle={() => EventManager.hideExpandedActionMenu(this)}
									expanded={true}
									useGreyIcon={true}
								/>
							</div>
						) : null}
						{showArrowClickableArea ? (
							<div
								className="people-scheduling-arrow-clickable-area previous"
								onClick={this.onTaskGroupArrowClick.bind(this, true)}
								style={{top: arrowClickableAreaY + 2}}
							/>
						) : null}
						{showArrowClickableArea ? (
							<div
								className="people-scheduling-arrow-clickable-area next"
								onClick={this.onTaskGroupArrowClick.bind(this, false)}
								style={{top: arrowClickableAreaY + 2}}
							/>
						) : null}
						{showDetailBox && detailBoxData && (detailBoxData.task || detailBoxData.allocation) ? (
							<div
								className="people-scheduling-detail-box"
								style={{
									left: detailBoxX + 200 < window.innerWidth ? detailBoxX + 10 : detailBoxX - 235,
									top: detailBoxY + 350 < window.innerHeight ? detailBoxY - 5 : 'unset',
									bottom:
										detailBoxY + 350 < window.innerHeight ? 'unset' : window.innerHeight - detailBoxY - 40,
								}}
							>
								{detailBoxData.task ? (
									<TaskDetailBox
										task={detailBoxData.task}
										globalData={data}
										positionX={detailBoxX}
										positionY={detailBoxY}
										pageComponent={this}
									/>
								) : (
									<AllocationDetailBox
										pageComponent={this}
										taskAllocationTotalEstimate={detailBoxData.totalEstimates}
										allocation={detailBoxData.allocation}
										globalData={data}
										positionX={detailBoxX}
										positionY={detailBoxY}
										schedulingOptions={schedulingOptions}
									/>
								)}
							</div>
						) : showDetailBox && detailBoxData?.heatmapData ? (
							<div
								className="heatmap-cell-detail-box"
								style={{
									width: HEATMAP_CELL_DETAIL_BOX_WIDTH,
									height: HEATMAP_CELL_DETAIL_BOX_HEIGHT,
									left: heatmapCellDetailBoxX,
									top: heatmapCellDetailBoxY,
								}}
							>
								<HeatmapCellDetailBox
									heatmapData={detailBoxData.heatmapData}
									globalData={data}
									positionX={detailBoxX}
									positionY={detailBoxY}
									showCenter={showHeatmapDetailBoxCenter}
									showBottom={showHeatmapDetailBoxBottom}
									showRight={showHeatmapDetailBoxRight}
								/>
							</div>
						) : null}
						{showCanvasTooltip && canvasTooltipData && (
							<CanvasTooltip
								mouseData={{x: canvasTooltipX, y: canvasTooltipY}}
								data={canvasTooltipData}
								pageComponent={this}
							/>
						)}
					</div>

					<CanvasTimeline
						pageComponent={this}
						ref={updateTimelineRef}
						groups={groups}
						items={items}
						topLeftComponent={topLeftComponent}
						isCollapsableSectionExpanded={isCollapsableSectionExpanded}
						collapsableSectionGroups={collapsableSectionGroups}
						onVerticalScroll={delta => EventManager.onTimelineVerticalScroll(this, delta)}
						onHorizontalScroll={() => EventManager.onTimelineHorizontalScroll(this)}
						dayData={dayData}
						initialStartDate={startDate}
						isSingleGroup={!this.viewCompanySchedule}
						onGroupExpansionToggle={group => EventManager.onGroupExpansionToggle(this, group)}
						debugData={data}
						holidaysEntries={data?.holidaysEntriesCanvasFormat || []}
						isProjectTimeline={this.props.isProjectTimeline}
						eyeOptions={eyeOptions}
						recalculateSteps={recalculateSteps}
						resetRecalculateSteps={this.resetRecalculateSteps.bind(this)}
						onForegroundContextMenu={(event, mouseTargetData, canvasDate) =>
							EventManager.onForegroundContextMenu(this, event, mouseTargetData, canvasDate)
						}
						splitAllocationBarData={splitAllocationBarData.visible ? splitAllocationBarData : null}
						heatmapCache={this.heatmapCache}
						onBeforeHorizontalScroll={scrollAmount =>
							preventScrollingPastLoadMoreOverlay(this, scrollAmount, !this.disableLoadMore)
						}
						onDrawForegroundEnd={(canvasContext, _, stepDataArray) =>
							this.disableLoadMore ? undefined : drawIncrementalLoadMoreOverlay(this, stepDataArray)
						}
						disableLoadMore={this.disableLoadMore}
						leftLoadMoreDate={this.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]}
						rightLoadMoreDate={this.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]}
						isPeopleScheduling={true}
						isHeatMapDisabled={this.isHeatMapDisabled()}
						isUsing35Frames={this.isUsing35Frames}
						isUsing67Frames={this.isUsing67Frames}
						isUsingInstantZoom={this.isUsingInstantZoom}
						weekendOptions={this.props.weekendOptions}
						schedulingView={this.props.schedulingView}
					/>
					{this.state.savingMutation ? (
						<div className="is-saving">
							<div className="backdrop" style={{width, height, top, left}} />
							<div className="description">Saving. Please wait until it is finished.</div>
						</div>
					) : null}
				</div>

				{!this.disableLoadMore && <IncrementalLoadingOverlay onWheel={e => this.timeline.onForegroundWheel(e)} />}

				{/* userpilot targets */}
				<div
					className="onboarding-person-target"
					id="onboarding-scheduling-person-div"
					data-userpilot={'onboarding-scheduling-person-div'}
				/>
				<div
					className="onboarding-person-expand-target"
					id="onboarding-scheduling-person-expand-div"
					data-userpilot={'onboarding-scheduling-person-expand-div'}
				/>
				<div className="userpilot-target unassingned-tasks" data-userpilot={'unassingned-tasks'} />
				<div
					className="userpilot-target unassingned-tasks-expand-icon"
					data-userpilot={'unassingned-tasks-expand-icon'}
				/>
				<div
					className="userpilot-target unassigned-task"
					id="user-pilot-unassigned-task"
					data-userpilot={'unassigned-task'}
				/>
				<div className="userpilot-target first-role" data-userpilot={'first-role'} />
				<div className="userpilot-target first-person" data-userpilot={'first-person'} />
				<div className="userpilot-target first-person-expand-icon" data-userpilot={'first-person-expand-icon'} />
				<div
					className="userpilot-target first-person-expand-icon-project"
					data-userpilot={'first-person-expand-icon-project'}
				/>
				<div className="userpilot-target resource-utilization" data-userpilot={'resource-utilization'} />
				<div className="userpilot-target heatmap-first-project" data-userpilot={'heatmap-first-project'} />
				<div
					className="userpilot-target heatmap-fully-allocated"
					id="userpilot-heatmap-fully-allocated"
					data-userpilot={'heatmap-fully-allocated'}
				/>
				<div
					className="userpilot-target heatmap-over-allocated"
					id="userpilot-heatmap-over-allocated"
					data-userpilot={'heatmap-over-allocated'}
				/>
			</>
		);
	}
}

CanvasPeopleScheduling.propTypes = {
	viewer: PropTypes.object.isRequired,
	setTimeline: PropTypes.func.isRequired,
};

export default injectIntl(withRouter(CanvasPeopleScheduling));
