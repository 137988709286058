import {
	drawArrows,
	drawBorderLines,
	GROUP_SECTION_WIDTH,
	GROUP_TYPE,
	ITEM_TYPE,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import Util from '../../../../forecast-app/shared/util/util';
import EventManager from '../../EventManager';

class PersonAllocationsGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PEOPLE_SCHEDULING_PERSON_ALLOCATIONS, data);
		this.groupIds = data.groupIds;
	}

	get itemRowHeight() {
		return 39;
	}

	set itemRowHeight(itemRowHeight) {
		this._itemRowHeight = itemRowHeight;
	}

	get height() {
		// The height of the rows are 4 pixels larger than the item itself (2 on top/bottom)
		// This gives 4 pixels between all item rows (overlapping items), but only two pixel at the top/bottom of the group
		// Add 2 pixels more at the top (items adjusted in adjustItemY) and 13 pixels for the bottom padding.
		return this.itemRowCount * this.itemRowHeight + 2 + 13;
	}

	set height(height) {
		this._height = height;
	}

	adjustItemY(item, y) {
		return Math.floor(y + (this.itemRowHeight - 1 - item.height) / 2) + 2;
	}

	onItemCreate(startDate, endDate) {
		EventManager.onItemCreate(this.pageComponent, this, startDate, endDate);
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);

		canvasContext.font = '500 10px ' + Util.getFontFamily();
		canvasContext.fillStyle = '#535353';
		canvasContext.fillText(data.name, x + 66, y + 20);

		const allocations = this.allItems.filter(item =>
			[ITEM_TYPE.PROJECT_ALLOCATION, ITEM_TYPE.TASK].includes(item.itemType)
		).length;
		canvasContext.font = '700 12px ' + Util.getFontFamily();
		canvasContext.fillText(allocations, x + width - canvasContext.measureText(allocations).width - 40, y + 20);
		if (allocations > 0) {
			drawArrows(
				canvasContext,
				null,
				x + 241,
				y + 10,
				cacheManager.getCommonImage(COMMON_IMAGE.GO_TO_PREVIOUS),
				cacheManager.getCommonImage(COMMON_IMAGE.GO_TO_NEXT)
			);
		}

		drawBorderLines(canvasContext, x, y, width, height, true);
	}
}

export default PersonAllocationsGroup;
