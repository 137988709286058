/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RateCardDropdown_rateCards$ref: FragmentReference;
declare export opaque type RateCardDropdown_rateCards$fragmentType: RateCardDropdown_rateCards$ref;
export type RateCardDropdown_rateCards = $ReadOnlyArray<{|
  +node: ?{|
    +id: string,
    +name: ?string,
    +currency: ?string,
    +parentRateCardId: ?string,
    +disabled: ?boolean,
  |},
  +$refType: RateCardDropdown_rateCards$ref,
|}>;
export type RateCardDropdown_rateCards$data = RateCardDropdown_rateCards;
export type RateCardDropdown_rateCards$key = $ReadOnlyArray<{
  +$data?: RateCardDropdown_rateCards$data,
  +$fragmentRefs: RateCardDropdown_rateCards$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RateCardDropdown_rateCards",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RateCard",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentRateCardId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RateCardTypeEdge"
};
// prettier-ignore
(node/*: any*/).hash = '67be2dade080fba19ad9e31ecaebca9e';

module.exports = node;
