import React from 'react';
import {Table} from 'web-components';
import {getQueryRenderer} from '../TaskReportUtil';
import GroupColumns from './GroupColumns';

const GroupRow = (
	data,
	intl,
	rowIndex,
	searchQuery,
	groupings,
	enabledColumns,
	sortValue,
	hasNextPage,
	fetchMore,
	nextLevelProps,
	tableHeader,
	convertToProjectCurrency,
	projectCurrency,
	isInPersonGroup
) => {
	return (
		<Table.Rows
			data={data}
			canExpand={true}
			autoload={hasNextPage && rowIndex === 1}
			loadMore={hasNextPage ? fetchMore : false}
			{...nextLevelProps}
		>
			{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
				return (
					<>
						<Table.Row key={rowData.id} {...tableColumnsProps}>
							{GroupColumns(
								rowData,
								intl,
								data.currency,
								tableHeader.revenueRecognitionNotEqualToBudget,
								tableHeader.customFieldDefinitions?.length,
								isInPersonGroup
							)}
						</Table.Row>
						{expanded &&
							getQueryRenderer(
								tableHeader,
								groupings,
								searchQuery,
								rowIndex,
								enabledColumns,
								data.groupingFilter(rowData),
								nextLevelProps,
								sortValue,
								convertToProjectCurrency,
								projectCurrency,
								isInPersonGroup,
								rowData
							)}
					</>
				);
			}}
		</Table.Rows>
	);
};

export default GroupRow;
