import Styled from 'styled-components';

const HeaderColumnContainer = Styled.div`
	display: flex;
	flex: 1;
	gap: 4px;;
	align-items: center;
`;

export default HeaderColumnContainer;
