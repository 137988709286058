import React, {useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import BudgetData from './money/work_and_expenses/BudgetData';
import Details from './money/work_and_expenses/Details';
import InvoiceDetails from './money/invoice_plan/InvoiceDetails';
import TimeDetails from './time/TimeDetails';
import TimeBudgetData from './time/TimeBudgetData';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import BudgetPageHeader from './BudgetPageHeader';
import Util from '../../../../forecast-app/shared/util/util';
import {BUDGET_MONEY_VIEWS, BUDGET_VIEWS} from '../../../../constants';
import {DetailsWrapper} from './Details.styled';
import {Collapsible} from 'web-components';
import {useIntl} from 'react-intl';
import ExpenseDetails from './money/expenses/ExpenseDetails';
import InvoiceBudgetData from './money/invoice_plan/InvoiceBudgetData';
import {createToast} from '../../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {FixedPriceContainer} from './FixedPriceContainer';
import FixedPriceUpdateToast from './FixedPriceUpdateToast';

import {LoadMore} from '../../../loaders/LoadMore';
import FixedPriceContainerLoader, {fixedPriceContainerLoaderQuery} from './FixedPriceContainerLoader';
import * as tracking from '../../../../tracking';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import CompanySetupUtil from '../../../../forecast-app/shared/util/CompanySetupUtil';
import {useTrackPage} from '../../../../tracking/amplitude/hooks/useTrackPage';

const BudgetPage = ({
	project,
	company,
	hasFinancials,
	hasBaseline,
	hasRevenueWithoutCostAccess,
	retry,
	timestamp,
	hasExpenses,
}) => {
	useTrackPage('Project Financials Overview', null, {budgetType: project.budgetType});
	const [financialQueryTimestamp, setFinancialQueryTimestamp] = useState(timestamp);
	if (timestamp !== financialQueryTimestamp) {
		setFinancialQueryTimestamp(timestamp);
		retry();
	}

	const hasNoTimeRegFeature = ProjectUtil.projectUsesManualProgress(project);
	const onlyShowTime = hasRevenueWithoutCostAccess && !ProjectUtil.projectTracksRevenue(project);

	const [selectedAggregateLevel, setSelectedAggregateLevel] = useState(
		Util.localStorageGetItem('budget.selectedAggregateLevel') || 'MONTH'
	);
	const [selectedValueType, setSelectedValueType] = useState(
		onlyShowTime
			? BUDGET_VIEWS.TIME
			: hasNoTimeRegFeature
			? BUDGET_VIEWS.MONEY
			: Util.localStorageGetItem('budget.selectedValueType') || BUDGET_VIEWS.MONEY
	);
	const [selectedViewType, setSelectedViewType] = useState(
		Util.localStorageGetItem('budget.selectedViewType') &&
			(Util.localStorageGetItem('budget.selectedViewType') !== BUDGET_MONEY_VIEWS.EXPENSES ||
				CompanySetupUtil.hasExpenses())
			? Util.localStorageGetItem('budget.selectedViewType')
			: BUDGET_MONEY_VIEWS.WORK_AND_EXPENSES
	);
	const [eyeOptionMap, setEyeOptionMap] = useState({});
	const [showFixedPriceContainer, setShowFixedPriceContainer] = useState(false);
	const [showFixedPriceUpdateToast, setShowFixedPriceUpdateToast] = useState(false);
	const intl = useIntl();

	const onAggregateLevelChange = item => {
		setSelectedAggregateLevel(item.value);
		tracking.trackEvent('budget_day_month_dropdown', {value: item.value});
		trackEvent('Budget Aggregate Level Dropdown', 'Changed', {value: item.value});
		Util.localStorageSetItem('budget.selectedAggregateLevel', item.value);
	};

	const onValueTypeChange = value => {
		setSelectedValueType(value);
		Util.localStorageSetItem('budget.selectedValueType', value);
	};

	const onViewTypeChange = value => {
		setSelectedViewType(value);
		Util.localStorageSetItem('budget.selectedViewType', value);
	};

	let budgetData, details;

	const renderOverviewAndDetailsSection = (selectValueType, selectedViewType) => {
		if (BUDGET_VIEWS.MONEY === selectValueType) {
			budgetData = (
				<BudgetData
					project={project}
					company={company}
					selectValueType={selectValueType}
					selectedViewType={selectedViewType}
					eyeOptionMap={eyeOptionMap}
					hasBaseline={hasBaseline}
					retry={retry}
					timestamp={timestamp}
					hasExpenses={hasExpenses}
				/>
			);
			if (BUDGET_MONEY_VIEWS.WORK_AND_EXPENSES === selectedViewType) {
				details = (
					<Details
						key={'work_expense_details_' + selectedAggregateLevel}
						company={company}
						project={project}
						selectedAggregateLevel={selectedAggregateLevel}
						selectedViewType={selectedViewType}
						eyeOptionMap={eyeOptionMap}
					/>
				);
			} else if (BUDGET_MONEY_VIEWS.EXPENSES === selectedViewType) {
				details = (
					<ExpenseDetails
						key={'expense_details_' + selectedAggregateLevel}
						project={project}
						selectedAggregateLevel={selectedAggregateLevel}
						selectedViewType={selectedViewType}
						eyeOptionMap={eyeOptionMap}
					/>
				);
			} else if (BUDGET_MONEY_VIEWS.INVOICE_PLAN === selectedViewType) {
				budgetData = (
					<InvoiceBudgetData
						project={project}
						company={company}
						selectedViewType={selectedViewType}
						selectValueType={selectValueType}
						eyeOptionMap={eyeOptionMap}
						hasBaseline={hasBaseline}
					/>
				);
				details = (
					<InvoiceDetails
						key={'invoice_details_' + selectedAggregateLevel}
						project={project}
						selectedAggregateLevel={selectedAggregateLevel}
						eyeOptionMap={eyeOptionMap}
					/>
				);
			}
		} else if (BUDGET_VIEWS.TIME === selectValueType) {
			budgetData = (
				<TimeBudgetData project={project} selectedAggregateLevel={selectedAggregateLevel} eyeOptionMap={eyeOptionMap} />
			);
			details = (
				<TimeDetails
					key={'time_details_' + selectedAggregateLevel}
					company={company}
					project={project}
					selectedAggregateLevel={selectedAggregateLevel}
					eyeOptionMap={eyeOptionMap}
				/>
			);
		}

		return (
			<>
				{!!budgetData && (
					<Collapsible
						cy="budget-collapsible-budget-data"
						title={intl.formatMessage({id: 'project_budget.budget_data'})}
					>
						{budgetData}
					</Collapsible>
				)}
				{!!details && (
					<DetailsWrapper>
						<Collapsible
							cy="budget-details-collapse"
							title={intl.formatMessage({id: 'common.details'})}
							initiallyCollapsed
						>
							{details}
						</Collapsible>
					</DetailsWrapper>
				)}
			</>
		);
	};

	if (!project.financialDataAvailability) {
		// set to 0 if financial service is down, 1 if it is available. This is likely expanded in the future to inform on projects currently being updated.
		createToast({
			duration: 120000,
			message: intl.formatMessage({id: 'project_budget.financial_service_down_toast'}),
			isDisplayingDuration: true,
			callback: () => {
				location.reload();
			},
		});
	}
	return (
		<>
			{showFixedPriceUpdateToast ? <FixedPriceUpdateToast /> : null}
			{showFixedPriceContainer ? (
				<LoadMore
					key="fixed-price-container"
					query={fixedPriceContainerLoaderQuery}
					variables={{
						projectId: project.id,
					}}
					loader={<FixedPriceContainerLoader />}
				>
					{result => {
						return (
							<FixedPriceContainer
								company={result.viewer.company}
								project={result.viewer.project}
								setShowFixedPriceContainer={setShowFixedPriceContainer}
								intl={intl}
							/>
						);
					}}
				</LoadMore>
			) : null}
			<CustomScrollDiv>
				<BudgetPageHeader
					company={company}
					project={project}
					hasFinancials={hasFinancials}
					onlyShowTime={onlyShowTime}
					hasBaseline={hasBaseline}
					onAggregateLevelChange={onAggregateLevelChange}
					selectedAggregateLevel={selectedAggregateLevel}
					onValueTypeChange={onValueTypeChange}
					selectedValueType={selectedValueType}
					onViewTypeChange={onViewTypeChange}
					selectedViewType={selectedViewType}
					setEyeOptionMap={setEyeOptionMap}
					setShowFixedPriceContainer={setShowFixedPriceContainer}
					setShowFixedPriceUpdateToast={setShowFixedPriceUpdateToast}
				/>
				{renderOverviewAndDetailsSection(selectedValueType, selectedViewType)}
			</CustomScrollDiv>
		</>
	);
};

// TODO FixedPriceContainer_project should be loaded on demand and not with the main query - it loads financials
export default createFragmentContainer(BudgetPage, {
	project: graphql`
		fragment BudgetPage_project on ProjectType
		@argumentDefinitions(
			projectId: {type: "String"}
			currentYear: {type: "Int"}
			currentMonth: {type: "Int"}
			currentMonthLastDay: {type: "Int"}
		) {
			id
			financialDataAvailability
			...BudgetPageHeader_project
			...BudgetData_project
				@arguments(currentYear: $currentYear, currentMonth: $currentMonth, currentMonthLastDay: $currentMonthLastDay)
			...TimeBudgetData_project
			...Details_project
			...InvoiceDetails_project
			...TimeDetails_project
			...ExpenseDetails_project
			...InvoiceBudgetData_project
			manualProgressOnProjectEnabled
			manualProgressOnPhasesEnabled
			manualProgressOnTasksEnabled
			budgetType
		}
	`,
	company: graphql`
		fragment BudgetPage_company on Company {
			tier
			...BudgetPageHeader_company
			...BudgetData_company
			...Details_company
		}
	`,
});
