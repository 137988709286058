import React from 'react';
import {useIntl} from 'react-intl';
import {LoadMore} from '../../../loaders/LoadMore';
import {filtersToSearchQuery} from '../UtilizationReportUtils';
import {Table} from 'web-components';
import {exportToCsv} from './PeriodDownload';
import PeriodRow from './PeriodRow';
import UtilizationReportPeriodRoleLoader, {utilizationReportPeriodRoleLoaderQuery} from './UtilizationReportPeriodRoleLoader';
import PeriodPersonRows from './PeriodPersonRows';

const PeriodRoleRows = ({
	filters,
	startDate,
	endDate,
	setCsvDownloadFunction,
	pageSize,
	boolEyeOptions,
	allocationControlsOptions,
}) => {
	const intl = useIntl();
	return (
		<LoadMore
			key="query-render-person"
			query={utilizationReportPeriodRoleLoaderQuery}
			variables={{
				pageSize,
				searchQuery: filtersToSearchQuery(filters),
				startYear: startDate ? startDate.year() : undefined,
				startMonth: startDate ? startDate.month() + 1 : undefined,
				startDay: startDate ? startDate.date() : undefined,
				endYear: endDate ? endDate.year() : undefined,
				endMonth: endDate ? endDate.month() + 1 : undefined,
				endDay: endDate ? endDate.date() : undefined,
			}}
			loader={<UtilizationReportPeriodRoleLoader />}
		>
			{({data}) => {
				setCsvDownloadFunction(() => {
					exportToCsv(startDate, endDate, data.rows, boolEyeOptions, allocationControlsOptions, intl);
				});
				return (
					<Table.Rows data={data} canExpand={true}>
						{({rowData, tableColumnsProps, expanded, nextLevelProps}) => {
							return (
								<>
									<PeriodRow
										data={rowData}
										resourceData={rowData.resourceNumbers}
										tableColumnProps={tableColumnsProps}
										headerColumn={rowData.role.name}
										canExpandRow={true}
										allocationControlsOptions={allocationControlsOptions}
									/>
									{expanded && (
										<PeriodPersonRows
											filters={filters}
											startDate={startDate}
											endDate={endDate}
											pageSize={pageSize}
											roleId={rowData.role.id}
											levelProps={nextLevelProps}
											allocationControlsOptions={allocationControlsOptions}
										/>
									)}
								</>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default PeriodRoleRows;
