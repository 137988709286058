import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';

export const handleAddPersonToProject = (pageComponent, person) => {
	const {data} = pageComponent.state;
	const {projectGroups, projects, projectPersons, viewer, company} = data;
	const {actualPersonId} = viewer;

	showModal({
		type: MODAL_TYPE.ADD_PERSON_TO_PROJECT_MODAL,
		personId: person.id,
		projectGroups,
		projects,
		projectPersons,
		actualPersonId,
		company,
	});
};
