import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Filter from '../../filters/filter';

class IncludeDeactivatedPeopleFilterConfig extends Component {
	constructor(props) {
		super(props);

		const config = JSON.parse(props.config || '{}');
		this.state = {
			includeDeactivatedFilterValue: this.configToFilter(config),
		};
	}

	handleIncludeDeactivatedFilterChange(value) {
		this.setState({includeDeactivatedFilterValue: value});
		const config = JSON.parse(this.props.config || '{}');
		config.includeDeactivatedFilterValue = this.filterToConfig(value);
		if (this.props.updateComponentConfiguration) {
			this.props.updateComponentConfiguration(this.props.id, config);
		}
	}

	clearFilters() {
		this.setState({includeDeactivatedFilterValue: []});
		const config = JSON.parse(this.props.config || '{}');
		config.includeDeactivatedFilterValue = false;
		if (this.props.updateComponentConfiguration) {
			this.props.updateComponentConfiguration(this.props.id, config);
		}
	}

	configToFilter(config) {
		return config.includeDeactivatedFilterValue ? [{value: true, label: 'Include deactivated people'}] : [];
	}

	filterToConfig(filter) {
		return filter[0] ? filter[0].value : false;
	}

	render() {
		const {formatMessage} = this.props.intl;

		const filters = [];
		const includeDeactivatedPeopleString = formatMessage({id: 'insight.include_deactivated_people'});
		const includeDeactivatedFilters = {
			options: [{value: true, label: includeDeactivatedPeopleString}],
			onChange: this.handleIncludeDeactivatedFilterChange.bind(this),
			value: this.state.includeDeactivatedFilterValue,
			placeholder: includeDeactivatedPeopleString,
			label: includeDeactivatedPeopleString,
		};
		filters.push(includeDeactivatedFilters);
		return (
			<div className="forecast-actions-insights">
				{this.state.includeDeactivatedFilterValue.length ? (
					<h3 className="clear-filters" onClick={this.clearFilters.bind(this)}>
						{formatMessage({id: 'common.clear-filters'})}
					</h3>
				) : null}
				<Filter filters={filters} />
			</div>
		);
	}
}

IncludeDeactivatedPeopleFilterConfig.propTypes = {
	id: PropTypes.string.isRequired,
	updateComponentConfiguration: PropTypes.func.isRequired,
};

export default injectIntl(IncludeDeactivatedPeopleFilterConfig);
