import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import Moment from 'moment';
import HorizontalScrollbar from './canvas_timeline_horizontal_scrollbar';
import VerticalScrollbar from './canvas_timeline_vertical_scrollbar';
import PostProcessingRenderer from './canvas_timeline_post_processing_renderer';
import ZoomMenu from './canvas_timeline_zoom_menu';
import ExpandAllButton from './canvas_timeline_expand_all_button';
import {interactionManager} from './canvas_timeline_interaction_manager';
import {cacheManager, COMMON_IMAGE} from './canvas_timeline_cache_manager';

import {
	addOriginalCopyToItem,
	adjustForHiddenWeekend,
	calculateItemWidth,
	calculateItemX,
	clearDrawnStepDataArrayMap,
	clearDrawnStepsMap,
	createCacheCanvas,
	CURSOR,
	DATE_STEPS_SECTION_BACKGROUND_COLOR,
	DATE_STEPS_SECTION_HEIGHT,
	DATE_STEPS_SECTION_SHADOW_BLUR,
	DATE_STEPS_SECTION_SHADOW_COLOR,
	dateLabelSize,
	DRAG_HANDLE_VISIBILITY_THRESHOLD,
	DRAGGABLE_TYPE,
	drawRectangle,
	drawTimeOffBackground,
	getCanvasTimelineDateFromMoment,
	getDayData,
	getMaxDate,
	getMinDate,
	getStepDataArray,
	getStepDataArrayStartAndEndDate,
	getStepLabelStatic,
	getWeekendDaysBetween,
	getWeekendDaysDuringDiff,
	GROUP_SECTION_BACKGROUND_COLOR,
	GROUP_SECTION_MARGIN_LEFT,
	GROUP_SECTION_SHADOW_BLUR,
	GROUP_SECTION_SHADOW_COLOR,
	GROUP_SECTION_SPACING_LEVEL_ONE,
	GROUP_SECTION_WIDTH,
	GROUP_TYPE,
	hasDrawnStep,
	hasDrawnStepArray,
	ITEM_DRAG_POINT,
	markStepDataArrayDrawn,
	markStepDrawn,
	moveItem,
	resetThrottledRedraw,
	SCHEDULE_PEOPLE_HEATMAP_ITEM_SIZE,
	SCHEDULE_PEOPLE_HEATMAP_SHADOW_BLUR,
	SCHEDULE_PEOPLE_HEATMAP_SHADOW_COLOR,
	SECTION_SPLITTER_HEIGHT,
	TIMELINE_BACKGROUND_COLOR,
	TIMELINE_BAR_BORDER_RADIUS,
	TIMELINE_BORDER_COLOR,
	TIMELINE_EXPANDED_GROUND_HIGHLIGHT_COLOR,
	weekendAdjustedMovedDays,
} from './canvas_timeline_util';
import {trackPerformanceInitialRender} from './canvas_timeline_performance_track';
import SectionSplitter from './canvas_timeline_section_splitter';
import {EVENT_ID, subscribe, unsubscribe} from '../../../containers/event_manager';
import Util from '../../../forecast-app/shared/util/util';
import * as tracking from '../../../tracking';
import printJS from 'print-js';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {projectHoverColors} from '../../../constants';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {TimelineLoading} from '../loading/TimelineLoading';
import DataManager from '../DataManager';
import {EYE_OPTION_NAME} from '../constants';
import {TOTAL_RESOURCE_UTILIZATION_GROUP_ID} from '../IDManager';
import RecalculationManager from '../RecalculationManager';
import {isStepHidden} from '../loading/LoadMoreUtil';

//Settings for displaying dates at the top of the timeline
const debugStepDate = process.env.SCHEDULE_DEBUG_DAY_STEPS && hasFeatureFlag('static_day_data_generation');

export const STEP_LABEL_FORMATS = {
	DAY: 'DAY',
	WEEK: 'WEEK',
	MONTH: 'MONTH',
	QUARTER: 'QUARTER',
	DAY_CANVAS_DATE: 'DAY_CANVAS_DATE',
};
export const SCALE_SETTINGS = {
	DAY: {
		secondsPerPixelThreshold: 0,
		minorStep: 'day',
		minorStepLabelFormat: debugStepDate ? STEP_LABEL_FORMATS.DAY_CANVAS_DATE : STEP_LABEL_FORMATS.DAY,
		majorStep: 'month',
		majorStepLabelFormat: {month: 'long', year: 'numeric'},
	},
	WEEK: {
		secondsPerPixelThreshold: 1700,
		minorStep: 'week',
		minorStepLabelFormat: STEP_LABEL_FORMATS.WEEK, //Displaying "Week x" is not an option in formattedDate - handled in calculateStepDataArrays()
		majorStep: 'month',
		majorStepLabelFormat: {month: 'long', year: 'numeric'},
	},
	MONTH: {
		secondsPerPixelThreshold: 9000,
		minorStep: 'month',
		minorStepLabelFormat: STEP_LABEL_FORMATS.MONTH,
		majorStep: 'year',
		majorStepLabelFormat: {year: 'numeric'},
	},
	QUARTER: {
		secondsPerPixelThreshold: 27000,
		minorStep: 'quarter',
		minorStepLabelFormat: STEP_LABEL_FORMATS.QUARTER,
		majorStep: 'year',
		majorStepLabelFormat: {year: 'numeric'},
	},
};
//Bottom scrollbar
const HORIZONTAL_SCROLLBAR_THUMB_WIDTH = 60; //Pixels
const HORIZONTAL_SCROLLBAR_SENSITIVITY = 5;
//Maximum and minimum zoom levels measured in seconds per pixel
const ZOOM_LEVEL_MIN = 300;
const ZOOM_LEVEL_MAX = 65000;
const ZOOM_ANIMATION_FRAME_COUNT = 3;
//How many frames it should take to animate scrolling
const SCROLL_ANIMATION_FRAME_COUNT = 5;
//How close do you need to get the section edge to start scrolling
const EDGE_SCROLLING_THRESHOLD = 40;
//How fast should edge scrolling be
const EDGE_SCROLLING_SPEED = 20;

const SECONDS_PER_DAY = 86400;

const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

class CanvasTimeline extends Component {
	// Used for identifying minified components in cypress tests
	static displayName = 'CanvasTimeline';

	constructor(props) {
		super(props);

		this.minDate = getMinDate();
		this.maxDate = getMaxDate();
		this.maxDay = getCanvasTimelineDateFromMoment(this.maxDate);

		if (hasFeatureFlag('static_day_data_generation')) {
			this.dayData = this.props.dayData;
		} else {
			if (this.props.dayData) {
				this.dayData = this.props.dayData;
			} else {
				this.dayData = getDayData();
			}
		}

		this.startDate = props.initialStartDate;

		const mainSectionContentHeight = props.groups.reduce((total, group) => total + group.height, 0);
		const collapsableSectionContentHeight = props.collapsableSectionGroups
			? props.collapsableSectionGroups.reduce((total, group) => total + group.height, 0) + SECTION_SPLITTER_HEIGHT
			: 0;
		const initialZoomLevel = props.initialZoomLevel;
		const zoomLevel =
			initialZoomLevel !== undefined
				? initialZoomLevel
				: localStorage.getItem('canvas-scheduling-zoom-level') !== null &&
				  localStorage.getItem('canvas-scheduling-zoom-level') !== undefined
				? parseInt(localStorage.getItem('canvas-scheduling-zoom-level'), 10)
				: 7;

		this.state = {
			canvasHeight: 0,
			canvasWidth: 0,
			zoomLevel,
			mainSectionContentHeight,
			collapsableSectionContentHeight,
			mainSectionScrollTop: 0,
			collapsableSectionScrollTop: 0,
			collapsableSectionHeightPercentage:
				localStorage.getItem('canvas-scheduling-collapsable-section-height-percentage') || 50,
			isSectionSplitterBeingDragged: false,
			expandedGroupCount: 0,
			showLoader: false,
			weekendOptions: props.weekendOptions,
		};

		this.hideWeekendEyeOption = this.props.eyeOptions?.find(option => option.name === EYE_OPTION_NAME.SHOW_WEEKENDS);

		this.onForceRedrawEventReceived = this.onForceRedrawEventReceived.bind(this);

		this.onResize = this.onResize.bind(this);
		this.onWindowMouseDown = this.onWindowMouseDown.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onGroupSectionWheel = this.onGroupSectionWheel.bind(this);
		this.onForegroundWheel = this.onForegroundWheel.bind(this);
		this.isWheelScrolling = null;

		this.previousMouseTargetData = {};
		this.mouseTargetData = {}; //Making sure it is never undefined (would be if user refreshed a page, then clicked on it without ever moving the mouse)

		this.todayIndex = getCanvasTimelineDateFromMoment(Moment());

		this.scrollStartDate = this.startDate;

		this.collapsableMainSectionOffscreenCanvas = createCacheCanvas(0, 0);
		this.collapsableGroupSectionOffscreenCanvas = createCacheCanvas(0, 0);

		this.hasCombinedModePerformanceImprovements = hasFeatureFlag('combined_mode_performance_improvements');
	}

	componentDidMount() {
		window.addEventListener('resize', this.onResize);
		if (!hasFeatureFlag('scheduling_mousedown_only_canvas')) {
			window.addEventListener('mousedown', this.onWindowMouseDown);
		}
		window.addEventListener('mousemove', this.onMouseMove);
		window.addEventListener('mouseup', this.onMouseUp);
		window.addEventListener('keydown', this.onKeyDown);

		document.getElementById('group-section-canvas').addEventListener('wheel', this.onGroupSectionWheel, {passive: false});
		document.getElementById('foreground-canvas').addEventListener('wheel', this.onForegroundWheel, {passive: false});

		//Need to be sure css is loaded
		if (document.readyState === 'complete') {
			this.handleLoad();
		} else {
			this.handleLoad = this.handleLoad.bind(this);
			window.addEventListener('load', this.handleLoad);
		}

		subscribe(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, this.onForceRedrawEventReceived);
		subscribe(EVENT_ID.SIDENAV_STATE_CHANGED, this.onResize);

		this.onPrint = this.onPrint.bind(this);
		subscribe(EVENT_ID.CANVAS_TIMELINE_PRINT, this.onPrint);

		this.postProcessingRenderer = new PostProcessingRenderer(
			this.getPostProcessingCanvasContext(),
			this.props.schedulingView
		);

		this.postProcessingRenderer.formatDate = this.props.intl.formatDate;

		this.setInitialZoomLevel();

		//Some interactions can happen before handleLoad() is completed, so ensure initial data
		this.minorStepDataArray = [];
		this.majorStepDataArray = [];
	}

	componentDidUpdate(prevProps) {
		if (this.props.recalculateSteps) {
			this.calculateStepDataArrays();
			this.props.resetRecalculateSteps();
		}

		// collapsable area expansion changes
		const collapsableAreaExpansionChanges =
			prevProps.isCollapsableSectionExpanded !== this.props.isCollapsableSectionExpanded;
		if (collapsableAreaExpansionChanges) {
			this.redraw({preventFiltering: false});
		}
	}

	componentWillUnmount() {
		this.isTimelineAboutToUnmount = true;
		window.removeEventListener('resize', this.onResize);
		window.removeEventListener('load', this.handleLoad);
		window.removeEventListener('mousedown', this.onWindowMouseDown);
		window.removeEventListener('mousemove', this.onMouseMove);
		window.removeEventListener('mouseup', this.onMouseUp);
		window.removeEventListener('keydown', this.onKeyDown);
		document.getElementById('group-section-canvas').removeEventListener('wheel', this.onGroupSectionWheel);
		document.getElementById('foreground-canvas').removeEventListener('wheel', this.onForegroundWheel);
		unsubscribe(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, this.onForceRedrawEventReceived);
		unsubscribe(EVENT_ID.CANVAS_TIMELINE_PRINT, this.onPrint);
		unsubscribe(EVENT_ID.SIDENAV_STATE_CHANGED, this.onResize);
		cacheManager.clear();
	}

	setInitialZoomLevel() {
		if (this.props.simulationMode && this.props.initialEndDate && this.props.initialStartDate) {
			const width = this.canvasContainer.getBoundingClientRect().width;
			const daysInSchedule = this.props.initialEndDate - this.props.initialStartDate;
			const minimumSecondsPerPixel = (daysInSchedule * SECONDS_PER_DAY) / (width - 100);
			let zoomLevel = 8;
			for (let i = 0; i <= 8; i++) {
				if (this.zoomLevels[i] < minimumSecondsPerPixel) {
					if (i > 0) {
						zoomLevel = i - 1;
						break;
					} else {
						zoomLevel = 0;
						break;
					}
				}
			}
			this.setZoomLevel(zoomLevel, false, 0.5, () => {
				this.scrollToCanvasDate((this.props.initialStartDate + this.props.initialEndDate) / 2);
			});
		}
	}

	getCanvasOffset() {
		const canvasTimelineClientRect = this.canvasTimelineComponentRef?.getBoundingClientRect();
		const canvasTimelineTop = canvasTimelineClientRect?.top || 0;
		const canvasTimelineLeft = canvasTimelineClientRect?.left || 0;

		return {
			top: canvasTimelineTop + DATE_STEPS_SECTION_HEIGHT,
			left: canvasTimelineLeft,
		};
	}

	getDefaultStartDate() {
		return Moment().diff(this.minDate, 'days');
	}

	getStartDate() {
		return this.startDate;
	}

	getEndDate() {
		const canvasDays = this.state.canvasWidth / this.pixelsPerDay;
		if (this.isHideWeekendsSelected()) {
			return this.startDate + canvasDays + getWeekendDaysDuringDiff(this.startDate, canvasDays);
		} else {
			return this.startDate + canvasDays;
		}
	}

	handleLoad() {
		this.calculateSize();
		this.props.onRangeChange && this.props.onRangeChange(this.startDate, this.getEndDate());
	}

	onResize() {
		this.calculateSize();
	}

	setSecondsPerPixel(value) {
		this.secondsPerPixel = value;
	}

	getNormalizedSecondsPerDay() {
		if (this.isHideWeekendsSelected()) {
			const weekendFactor = (SECONDS_PER_DAY * 2) / 5;
			return SECONDS_PER_DAY + weekendFactor;
		}

		return SECONDS_PER_DAY;
	}

	setPixelsPerDay() {
		this.pixelsPerDay = this.getNormalizedSecondsPerDay() / this.secondsPerPixel;
	}

	calculateSize() {
		const {width, height, top, left} = this.canvasContainer.getBoundingClientRect();

		this.calculateZoomLevels(width);
		this.setSecondsPerPixel(this.zoomLevels[this.state.zoomLevel]);
		this.setPixelsPerDay();

		const canvasHeight = height - DATE_STEPS_SECTION_HEIGHT;
		const canvasWidth = width;

		this.setState(
			{
				canvasHeight,
				canvasWidth,
				canvasOffsetTop: top,
				canvasOffsetLeft: left - GROUP_SECTION_WIDTH,
			},
			() => {
				if (this.startDate === undefined || this.startDate === null) {
					this.startDate = this.getDateToCentralizeDate(this.todayIndex);
					this.props.onRangeChange && this.props.onRangeChange(this.startDate, this.getEndDate());
				}

				this.startDate = this.cappedStartDate(this.startDate);

				// Adjust startDate to skip weekend days if necessary
				if (this.isHideWeekendsSelected()) {
					this.startDate = adjustForHiddenWeekend(this.startDate, 0);
				}

				const {devicePixelRatio} = window;
				this.getBackgroundCanvasContext().setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);
				this.getForegroundCanvasContext().setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);
				this.getGroupSectionCanvasContext().setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);
				this.getPostProcessingCanvasContext().setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);

				this.collapsableMainSectionOffscreenCanvas.width = canvasWidth * devicePixelRatio;
				this.collapsableMainSectionOffscreenCanvas.height = Math.max(
					(Math.floor((canvasHeight * this.state.collapsableSectionHeightPercentage) / 100) -
						SECTION_SPLITTER_HEIGHT) *
						devicePixelRatio,
					1
				);
				this.collapsableMainSectionOffscreenCanvas
					.getContext('2d')
					.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);
				this.collapsableGroupSectionOffscreenCanvas.width = GROUP_SECTION_WIDTH * devicePixelRatio;
				this.collapsableGroupSectionOffscreenCanvas.height = Math.max(
					(Math.floor((canvasHeight * this.state.collapsableSectionHeightPercentage) / 100) -
						SECTION_SPLITTER_HEIGHT) *
						devicePixelRatio,
					1
				);
				this.collapsableGroupSectionOffscreenCanvas
					.getContext('2d')
					.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);

				cacheManager.clear(); //Cached entries would be cached with the previous devicePixelRatio so need to clear the cache
				this.calculateStepDataArrays();
				this.redraw({preventFiltering: false});
			}
		);
	}

	calculateZoomLevels(canvasWidth) {
		const zoomLevels = [];

		const roundToDays = secondsPerPixel => {
			return (Math.ceil((canvasWidth * secondsPerPixel) / SECONDS_PER_DAY) * SECONDS_PER_DAY) / canvasWidth;
		};

		zoomLevels[0] = ZOOM_LEVEL_MAX;
		zoomLevels[1] =
			SCALE_SETTINGS.QUARTER.secondsPerPixelThreshold +
			(ZOOM_LEVEL_MAX - SCALE_SETTINGS.QUARTER.secondsPerPixelThreshold) * 0.5;
		zoomLevels[2] = SCALE_SETTINGS.QUARTER.secondsPerPixelThreshold;
		zoomLevels[3] =
			SCALE_SETTINGS.MONTH.secondsPerPixelThreshold +
			((SCALE_SETTINGS.QUARTER.secondsPerPixelThreshold - SCALE_SETTINGS.MONTH.secondsPerPixelThreshold) * 2) / 3;
		zoomLevels[4] =
			SCALE_SETTINGS.MONTH.secondsPerPixelThreshold +
			(SCALE_SETTINGS.QUARTER.secondsPerPixelThreshold - SCALE_SETTINGS.MONTH.secondsPerPixelThreshold) / 3;
		zoomLevels[5] = SCALE_SETTINGS.MONTH.secondsPerPixelThreshold;
		zoomLevels[6] =
			SCALE_SETTINGS.WEEK.secondsPerPixelThreshold +
			((SCALE_SETTINGS.MONTH.secondsPerPixelThreshold - SCALE_SETTINGS.WEEK.secondsPerPixelThreshold) * 2) / 3;
		zoomLevels[7] =
			SCALE_SETTINGS.WEEK.secondsPerPixelThreshold +
			(SCALE_SETTINGS.MONTH.secondsPerPixelThreshold - SCALE_SETTINGS.WEEK.secondsPerPixelThreshold) / 3;
		zoomLevels[8] = SCALE_SETTINGS.WEEK.secondsPerPixelThreshold;
		zoomLevels[9] = ZOOM_LEVEL_MIN + ((SCALE_SETTINGS.WEEK.secondsPerPixelThreshold - ZOOM_LEVEL_MIN) * 2) / 3;
		zoomLevels[10] = ZOOM_LEVEL_MIN + (SCALE_SETTINGS.WEEK.secondsPerPixelThreshold - ZOOM_LEVEL_MIN) / 3;
		zoomLevels[11] = ZOOM_LEVEL_MIN;

		for (let i = 0; i < zoomLevels.length; i++) {
			zoomLevels[i] = roundToDays(zoomLevels[i]);
		}

		this.zoomLevels = zoomLevels;
	}

	getBackgroundCanvasContext() {
		return document.getElementById('background-canvas').getContext('2d');
	}

	getForegroundCanvasContext() {
		return document.getElementById('foreground-canvas').getContext('2d');
	}

	getGroupSectionCanvasContext() {
		return document.getElementById('group-section-canvas').getContext('2d');
	}

	getPostProcessingCanvasContext() {
		return document.getElementById('post-processing-canvas').getContext('2d');
	}

	getCollapsableSectionHeight() {
		let {canvasHeight, collapsableSectionHeightPercentage} = this.state;
		const {isCollapsableSectionExpanded} = this.props;
		return isCollapsableSectionExpanded ? Math.floor((canvasHeight * collapsableSectionHeightPercentage) / 100) : 0;
	}

	getMainSectionHeight() {
		let {canvasHeight} = this.state;
		return canvasHeight - this.getCollapsableSectionHeight();
	}

	setZoomLevel(zoomLevel, setLocalStorage = true, zoomCenterOffset = 0.5, finishCallback = null) {
		if (this.dragData) return; //Do not allow zooming while dragging

		let validatedZoomLevel = zoomLevel;
		if (zoomLevel < 0) {
			validatedZoomLevel = 0;
		} else if (zoomLevel > 11) {
			validatedZoomLevel = 11;
		}

		if (validatedZoomLevel === this.state.zoomLevel) return;

		cancelAnimationFrame(this.zoomFrame);

		const secondsDifference = (this.secondsPerPixel - this.zoomLevels[validatedZoomLevel]) * this.state.canvasWidth;
		const initialSecondsPerPixel = this.secondsPerPixel;

		if (setLocalStorage) {
			Util.localStorageSetItem('canvas-scheduling-zoom-level', validatedZoomLevel);
		}

		this.setState({zoomLevel: validatedZoomLevel});

		let finishedZoomAnimationFrames = 0;
		let dateDiff = 0;
		const animateZooming = () => {
			this.setSecondsPerPixel(
				initialSecondsPerPixel +
					((this.zoomLevels[validatedZoomLevel] - initialSecondsPerPixel) / ZOOM_ANIMATION_FRAME_COUNT) *
						(finishedZoomAnimationFrames + 1)
			);

			++finishedZoomAnimationFrames;

			const animationDone = finishedZoomAnimationFrames === ZOOM_ANIMATION_FRAME_COUNT;
			if (animationDone) {
				this.setSecondsPerPixel(this.zoomLevels[validatedZoomLevel]);
			}

			this.setPixelsPerDay();

			const newStartDate = this.cappedStartDate(
				this.startDate + (secondsDifference * zoomCenterOffset) / ZOOM_ANIMATION_FRAME_COUNT / SECONDS_PER_DAY
			);

			dateDiff = newStartDate - this.startDate;
			this.startDate = newStartDate;

			// Adjust startDate to skip weekend days if necessary
			if (this.isHideWeekendsSelected()) {
				this.startDate = adjustForHiddenWeekend(this.startDate, dateDiff);
			}

			const previousMinorStep = this.minorStep;

			this.calculateStepDataArrays();

			this.redraw({
				preventFiltering: true,
				isTriggeredByZoom: animationDone,
				preventHeatmapCalculation: previousMinorStep === this.minorStep,
			});

			if (animationDone) {
				if (finishCallback) {
					finishCallback();
				}
				return;
			}

			this.zoomFrame = requestAnimationFrame(animateZooming);
			this.props.onRangeChange && this.props.onRangeChange(this.startDate, this.getEndDate());
		};

		this.zoomFrame = requestAnimationFrame(animateZooming);
	}

	getScaleSettings() {
		let scaleSettings = Object.values(SCALE_SETTINGS)[0];

		//Determine which step scale to use based on density of what the user sees
		for (const value of Object.values(SCALE_SETTINGS)) {
			if (
				value.secondsPerPixelThreshold > scaleSettings.secondsPerPixelThreshold &&
				value.secondsPerPixelThreshold < this.secondsPerPixel
			) {
				scaleSettings = value;
			}
		}

		return scaleSettings;
	}

	calculateStepDataArrays() {
		const {minorStep, majorStep, minorStepLabelFormat, majorStepLabelFormat} = this.getScaleSettings();
		this.minorStep = minorStep;

		this.setPixelsPerDay();

		this.minorStepDataArray = getStepDataArray(
			minorStep,
			minorStepLabelFormat,
			this.dayData,
			this.state.canvasWidth,
			this.startDate,
			this.pixelsPerDay,
			this.isHideWeekendsSelected(),
			this.props.intl
		);
		this.majorStepDataArray = getStepDataArray(
			majorStep,
			majorStepLabelFormat,
			this.dayData,
			this.state.canvasWidth,
			this.startDate,
			this.pixelsPerDay,
			this.isHideWeekendsSelected(),
			this.props.intl
		);
	}

	getStepLabelFromCanvasDate(canvasDate) {
		const stepLabelFormat = SCALE_SETTINGS[this.minorStep.toUpperCase()].minorStepLabelFormat;
		const momentStartDate = this.dayData[parseInt(canvasDate, 10)];

		return getStepLabelStatic(stepLabelFormat, momentStartDate, this.props.intl, dateLabelSize.full);
	}

	getMinorStep() {
		return this.minorStep.toUpperCase();
	}

	isGroupDrawnDueToSpecialCondition(group) {
		return (
			(group.forceDrawCondition && group.forceDrawCondition()) ||
			(this.dragData &&
				this.dragData.isCreatingDependency &&
				this.dragData.groupData &&
				this.dragData.groupData.group === group)
		);
	}

	drawBackground() {
		const {canvasWidth, canvasHeight} = this.state;
		const canvas = this.getBackgroundCanvasContext();
		canvas.clearRect(0, 0, canvasWidth, canvasHeight + DATE_STEPS_SECTION_HEIGHT);
		canvas.strokeStyle = '#ebebee';
		canvas.beginPath();

		// draw blue background color
		canvas.fillStyle = TIMELINE_BACKGROUND_COLOR;
		canvas.fillRect(0, DATE_STEPS_SECTION_HEIGHT, canvasWidth, canvasHeight);

		// set text color
		canvas.fillStyle = '#a7adb5';

		//Drawing horizontal lines across whole canvas
		canvas.moveTo(0, 27.5);
		canvas.lineTo(canvasWidth, 27.5);
		canvas.moveTo(0, 55.5);
		canvas.lineTo(canvasWidth, 55.5);

		const {minorStepDataArray, majorStepDataArray} = this;
		//Drawing major step labels
		canvas.font = '12px ' + Util.getFontFamily();
		for (const stepData of majorStepDataArray || []) {
			canvas.moveTo(stepData.position, 0);
			canvas.lineTo(stepData.position, 27.5);
			let labelPosition = stepData.position + 12;
			if (stepData.position < 0) {
				if (stepData.width + stepData.position < 120) continue;
				labelPosition = 12;
			}
			canvas.fillText(stepData.label, labelPosition, 18);
		}
		//Drawing minor step lines and vertical lines across whole canvas
		canvas.font = '10px ' + Util.getFontFamily();
		let currentPeriodArea = null;

		for (const stepData of minorStepDataArray || []) {
			if (stepData.isCurrentPeriod) {
				currentPeriodArea = {
					x: stepData.position,
					y: 28,
					width: stepData.width,
					height: DATE_STEPS_SECTION_HEIGHT,
				};
			} else if (
				stepData.isWeekend ||
				(this.minorStep === 'day' && this.props.holidaysEntries.includes(stepData.startDate))
			) {
				canvas.fillStyle = canvas.createPattern(
					cacheManager.getCommonImageSafariWorkaround(COMMON_IMAGE.FREEDAY),
					'repeat'
				);
				canvas.fillRect(stepData.position, 28, stepData.width, canvasHeight + DATE_STEPS_SECTION_HEIGHT);
				// set the color for the label text
				canvas.fillStyle = '#abb1b9';
			} else if (stepData.isWeekend) {
				canvas.fillStyle = '#f8f8f8';
				canvas.fillRect(stepData.position, 28, stepData.width, canvasHeight + DATE_STEPS_SECTION_HEIGHT);
				// set the color for the label text
				canvas.fillStyle = '#a7adb5';
			}

			// draw column label date
			canvas.moveTo(stepData.position, 28);
			canvas.lineTo(stepData.position, canvasHeight + DATE_STEPS_SECTION_HEIGHT);
			let labelPosition = stepData.position + 12;
			if (stepData.position < 0) {
				if (stepData.width + stepData.position < 120) continue;
				labelPosition = 12;
			}
			canvas.fillText(stepData.label, labelPosition, 45);
		}
		canvas.closePath();
		canvas.stroke();

		if (currentPeriodArea) {
			// draw the top portion of the current period highlight, which is on the minorStepDataArray
			const {x, y, width, height} = currentPeriodArea;

			canvas.strokeStyle = '#6e0fea';

			// top border
			canvas.lineWidth = 3;

			canvas.beginPath();
			canvas.moveTo(x, y);
			canvas.lineTo(x + width, y);
			canvas.stroke();

			canvas.lineWidth = 1;
			// left border
			canvas.beginPath();
			canvas.moveTo(x, y - 1.5); // - 1.5 to compensate for the 3 pixel top border. without this the side lines would start in the middle of the top border
			canvas.lineTo(x, height);
			canvas.stroke();

			// right border
			canvas.beginPath();
			canvas.moveTo(x + width, y - 1.5); // - 1.5 to compensate for the 3 pixel top border. without this the side lines would start in the middle of the top border
			canvas.lineTo(x + width, height);
			canvas.stroke();
		}
	}

	hasVisibleChildren(group, screenY) {
		if (group?.expanded) {
			return screenY + group.totalHeight > 0;
		}

		return false;
	}

	drawGroups(isTriggeredByVerticalScrolling) {
		this.visibleMainSectionGroupMap = new Map();
		this.hiddenGroupsToCalculate = new Map();
		this.visibleCollapsableSectionGroupMap = new Map();
		const {canvasHeight} = this.state;
		const {items, collapsableSectionGroups, isCollapsableSectionExpanded} = this.props;
		const {startDate} = this;
		const canvas = this.getGroupSectionCanvasContext();
		const collapsableSectionHeight = this.getCollapsableSectionHeight();
		const mainSectionHeight = this.getMainSectionHeight();
		canvas.clearRect(0, 0, GROUP_SECTION_WIDTH, canvasHeight);

		//Map all items to groups they belong to
		const groupItemMap = new Map();
		const groupAllItemMap = new Map();
		for (const item of items) {
			//Put item into allItemMap no matter what
			const groupAllItems = groupAllItemMap.get(item.groupId);
			if (!groupAllItems) {
				groupAllItemMap.set(item.groupId, [item]);
			} else {
				groupAllItems.push(item);
			}
			//Only put item into item map if it is currently visible
			if (item.startDate > this.getEndDate() || item.endDate < startDate - 1) {
				item.resetItemRow();
				continue;
			}
			const groupItems = groupItemMap.get(item.groupId);
			if (!groupItems) {
				groupItemMap.set(item.groupId, [item]);
			} else {
				groupItems.push(item);
			}
		}

		//Used to check if a group should be visible on the screen if it has hideIfEmpty prop
		const areGroupChildrenEmpty = parentGroup => {
			let areAllChildrenEmpty = true;
			const areChildrenEmpty = group => {
				if (group.groups) {
					for (const childGroup of group.groups) {
						//Check if there are any items or if user is dragging to create a new item in the group
						if (
							(groupItemMap.get(childGroup.id) || []).length ||
							(this.dragData && this.dragData.newItemX && this.dragData.groupData.group.id === childGroup.id)
						) {
							areAllChildrenEmpty = false;
							break;
						}
						areChildrenEmpty(childGroup);
					}
				}
			};
			areChildrenEmpty(parentGroup);
			return areAllChildrenEmpty;
		};

		//y is offset in pixels describing where the vertical position of the group would be if the section had scrollTop value of 0
		let y = collapsableSectionHeight;
		//Get current values of scrollTop, let instead of const because they might be modified by y-axis locking functionality below
		let {mainSectionScrollTop, collapsableSectionScrollTop} = this.state;
		let expandedGroupCount = 0;
		//Compute some values for all relevant groups before they can be drawn, check for changes in height and apply y-axis locking if relevant
		const processGroup = (group, isInCollapsableSection = false) => {
			let groupItems = group.groupIds
				? group.groupIds.reduce((items, groupId) => items.concat(groupItemMap.get(groupId) || []), [])
				: this.isGroupDrawnDueToSpecialCondition(group)
				? groupAllItemMap.get(group.id) || []
				: groupItemMap.get(group.id) || [];
			group.y = y;
			group.isInCollapsableSection = isInCollapsableSection;

			// add heatmap height as margin on canvas timeline
			// if in project allocation mode and with heatmap eye option checked
			const heatmapEyeOption = this.props.eyeOptions.find(option => option.name === EYE_OPTION_NAME.SHOW_HEATMAP);
			const showHeatmap = !heatmapEyeOption || heatmapEyeOption.checked;

			const displayItemsWithHeatmapMargin = showHeatmap && group.displayItemsWithHeatmap;
			const itemsWithHeatmapMargin = displayItemsWithHeatmapMargin ? group.getHeatmapHeight() : 0;

			//composeItemRows updates group's itemRowCount
			group.composeItemRows(groupItems);
			const groupMargin = group.marginTop + group.marginBottom;
			group.height = group.itemRowCount * group.itemRowHeight + groupMargin + itemsWithHeatmapMargin;
			group.totalHeight = group.height;
			y += group.height;

			//If the redraw was not triggered by vertical scrolling, lock y-axis on the top element
			//Compare current element's (group) position to previous element's (firstVisibleXSectionGroupData) position to determine what the scrollTop value should be
			if (!isTriggeredByVerticalScrolling && !group.filtered) {
				if (isInCollapsableSection) {
					if (
						this.firstVisibleCollapsableSectionGroupData &&
						group.id === this.firstVisibleCollapsableSectionGroupData.id
					) {
						collapsableSectionScrollTop =
							group.y -
							this.firstVisibleCollapsableSectionGroupData.screenY -
							this.firstVisibleCollapsableSectionGroupData.height +
							group.height;
					}
				} else {
					if (this.firstVisibleMainSectionGroupData && group.id === this.firstVisibleMainSectionGroupData.id) {
						mainSectionScrollTop =
							group.y -
							this.firstVisibleMainSectionGroupData.screenY -
							this.firstVisibleMainSectionGroupData.height +
							group.height +
							this.firstVisibleMainSectionGroupData.collapsableSectionHeight -
							collapsableSectionHeight;
					}
				}
			}

			//If the group is expanded, also process the children
			if (group.groups && group.expanded && !group.filtered) {
				for (const childGroup of group.groups) {
					childGroup.parentGroup = group;
					processGroup(childGroup, isInCollapsableSection);
					childGroup.parentGroup.totalItemCount += childGroup.totalItemCount;
					group.totalHeight += childGroup.totalHeight;
				}
			}

			// This needs to be at the end since it is the margin goes after all the expanded children of the group that has expansionMarginBottom
			// expansion margin bottom
			if (group.expanded && !group.filtered) {
				y += group.expansionMarginBottom;
				group.totalHeight += group.expansionMarginBottom;

				const parentGroup = group.parentGroup;
				const parentGroupHasParent = parentGroup?.parentGroup;

				if (!parentGroup && !group.preventExpansion) {
					expandedGroupCount++;
				} else if (parentGroup && parentGroup.preventExpansion && !parentGroupHasParent) {
					expandedGroupCount++;
				}
			}

			let isHideIfEmptyConditionMet =
				group.filtered ||
				(group.hideIfEmpty &&
					!groupItems.filter(item => item.visible || item.isBeingDragged).length &&
					areGroupChildrenEmpty(group));

			//If user is dragging to create a new item, do not allow hiding the group
			if (
				isHideIfEmptyConditionMet &&
				this.dragData &&
				this.dragData.newItemX &&
				this.dragData.groupData.group.id === group.id
			) {
				isHideIfEmptyConditionMet = false;
			}

			//TODO: Figure out non-shady way to not hide the project group when the only thing that should be visible is a non-filled part of task allocation
			// if (isHideIfEmptyConditionMet && group.groupType === 1 && group.composeItems) {
			// 	const taskAllocationProjectGroupItems = groupAllItemMap.get(group.groups[0].id) || [];
			// 	isHideIfEmptyConditionMet = !(taskAllocationProjectGroupItems.find(item => item.endDate > startDate) && taskAllocationProjectGroupItems.find(item => item.endDate < startDate));
			// }
			if (!isHideIfEmptyConditionMet) {
				if (group.hideCondition && group.hideCondition(group, this.getStartDate(), this.getEndDate())) {
					isHideIfEmptyConditionMet = true;
				}
			}
			if (isHideIfEmptyConditionMet) {
				if (this.isGroupDrawnDueToSpecialCondition(group)) {
					isHideIfEmptyConditionMet = false;
				}
			}
			const isShowOnlyIfNoSiblingsVisibleConditionMet =
				group.showOnlyIfNoSiblingsVisible &&
				group.parentGroup &&
				group.parentGroup.groups.filter(siblingGroup => {
					return siblingGroup.id !== group.id && !siblingGroup.hidden;
				}).length;
			group.hidden = isHideIfEmptyConditionMet || isShowOnlyIfNoSiblingsVisibleConditionMet;
			if (group.hidden) {
				y -= group.totalHeight;
				group.totalHeight = 0;
			} else {
				// Producing the groupItems is the most expensive operation when rendering groups.
				// Use the already generated groupItems from above instead of calculating again.
				// If this causes problems then revert that.
				//group.allItems = group.groupIds ? group.groupIds.reduce((items, groupId) => items.concat(groupAllItemMap.get(groupId) || []), []) : groupAllItemMap.get(group.id) || [];
				group.allItems = groupItems;
			}
		};
		//Loop through all groups in both sections
		for (const group of this.props.groups) {
			processGroup(group);
		}
		const mainSectionContentHeight = y - collapsableSectionHeight;
		y = 0; //Reset y value, collapsable section always starts at the top of the timeline
		if (collapsableSectionGroups && isCollapsableSectionExpanded) {
			for (const group of collapsableSectionGroups) {
				processGroup(group, true);
			}
		}
		const collapsableSectionContentHeight = y + SECTION_SPLITTER_HEIGHT - 1;

		//Add space between sections as a fake group so that the mouse events can still trigger if the section is larger than its content
		if (collapsableSectionHeight && collapsableSectionContentHeight < collapsableSectionHeight) {
			interactionManager.addGroup(
				{
					groups: collapsableSectionGroups,
					data: {},
					isInCollapsableSection: true,
				},
				collapsableSectionContentHeight - SECTION_SPLITTER_HEIGHT + 1,
				collapsableSectionHeight - collapsableSectionContentHeight
			);
		}

		//Reset y-axis locking values
		this.firstVisibleMainSectionGroupData = null;
		this.firstVisibleCollapsableSectionGroupData = null;
		//This updates the section heights based on data received from all the groups in current tick
		if (
			mainSectionContentHeight !== this.state.mainSectionContentHeight ||
			collapsableSectionContentHeight !== this.state.collapsableSectionContentHeight ||
			expandedGroupCount !== this.state.expandedGroupCount
		) {
			this.setState({mainSectionContentHeight, collapsableSectionContentHeight, expandedGroupCount});
		}
		//Update scrollTop values if they were changed by y-axis locking
		const maxMainSectionScrollTop =
			mainSectionContentHeight - mainSectionHeight < 0 ? 0 : mainSectionContentHeight - mainSectionHeight;
		if (mainSectionScrollTop < 0) {
			mainSectionScrollTop = 0;
		} else if (mainSectionScrollTop > maxMainSectionScrollTop) {
			mainSectionScrollTop = maxMainSectionScrollTop;
		}
		if (collapsableSectionScrollTop < 0 || collapsableSectionContentHeight < collapsableSectionHeight) {
			collapsableSectionScrollTop = 0;
		} else if (
			collapsableSectionContentHeight > collapsableSectionHeight &&
			collapsableSectionScrollTop > collapsableSectionContentHeight - collapsableSectionHeight
		) {
			collapsableSectionScrollTop = collapsableSectionContentHeight - collapsableSectionHeight;
		}
		if (
			mainSectionScrollTop !== this.state.mainSectionScrollTop ||
			collapsableSectionScrollTop !== this.state.collapsableSectionScrollTop
		) {
			this.setState({mainSectionScrollTop, collapsableSectionScrollTop});
		}

		//drawGroup returns false if the drawing function should continue drawing, and returns true if the drawing function should stop drawing
		const drawGroup = (group, isCollapsableSection, canvasContext = this.getGroupSectionCanvasContext()) => {
			if (group.hidden || group.filtered) {
				return false;
			}

			const scrollTop = isCollapsableSection ? collapsableSectionScrollTop : mainSectionScrollTop;
			const screenY = group.y - scrollTop;
			group.screenY = screenY;

			//Check if group is on the screen
			const groupBelowView = screenY > (isCollapsableSection ? collapsableSectionHeight : canvasHeight);
			const groupAboveView = screenY + group.height < (isCollapsableSection ? 0 : collapsableSectionHeight);

			const isGroupOnScreen = !(groupAboveView || groupBelowView);
			let isDrawnDueToSpecialCondition = false;
			if (!isGroupOnScreen) {
				if (group.drawIfAnyChildVisible && this.hasVisibleChildren(group, screenY)) {
					isDrawnDueToSpecialCondition = true;
				} else if (this.isGroupDrawnDueToSpecialCondition(group)) {
					isDrawnDueToSpecialCondition = true;
				}
			}

			const addGroupForRecalculation = group => {
				// If total utilization group is already set to calculate, no need to add further groups - since it will recalculate everything.
				if (group.calculateHeatmapCache) {
					this.hiddenGroupsToCalculate.set(group.id, group);
				}
			};

			group.isDrawnDueToSpecialCondition = isDrawnDueToSpecialCondition;
			if (isGroupOnScreen || isDrawnDueToSpecialCondition) {
				//Group is on the screen, draw it
				const parentGroup = group.parentGroup;
				if (parentGroup && !this.visibleMainSectionGroupMap.has(parentGroup.id)) {
					// Set parentGroup to recalculate, since it's not visible or otherwise calculated.
					addGroupForRecalculation(parentGroup);
				}
				group.draw(canvasContext, 0, screenY, collapsableSectionHeight);
				//Add group to map of visible groups so that we only need to look it up in the map instead of looping through array when dragging items
				//Set data about the first visible group in the section
				if (isCollapsableSection) {
					this.visibleCollapsableSectionGroupMap.set(group.id, group);
					if (!this.firstVisibleCollapsableSectionGroupData && !isDrawnDueToSpecialCondition) {
						this.firstVisibleCollapsableSectionGroupData = {id: group.id, screenY, height: group.height};
					}
				} else {
					this.visibleMainSectionGroupMap.set(group.id, group);
					if (!this.firstVisibleMainSectionGroupData && !isDrawnDueToSpecialCondition) {
						this.firstVisibleMainSectionGroupData = {
							id: group.id,
							screenY,
							height: group.height,
							collapsableSectionHeight,
						};
					}
				}
			} else if (this.hasCombinedModePerformanceImprovements) {
				addGroupForRecalculation(group);
			}
			if (group.groups) {
				if (group.expanded) {
					for (const childGroup of group.groups) {
						if (!childGroup.preventDrawing) {
							drawGroup(childGroup, isCollapsableSection, canvasContext);
						}
					}
				} else if (this.hasCombinedModePerformanceImprovements && !group.calculateHeatmapCache) {
					// Collapsed group does not calculate heatmap - check if any child groups needs to calculate.
					group.groups.forEach(childGroup => {
						addGroupForRecalculation(childGroup);
					});
				}
			}

			return false;
		};

		for (const group of this.props.groups) {
			drawGroup(group);
		}

		if (collapsableSectionGroups && collapsableSectionHeight) {
			canvas.clearRect(0, 0, GROUP_SECTION_WIDTH, collapsableSectionHeight);
			//Draw everything on offscreen canvas
			const context = this.collapsableGroupSectionOffscreenCanvas.getContext('2d');
			context.clearRect(0, 0, GROUP_SECTION_WIDTH, collapsableSectionHeight);
			for (const group of this.props.collapsableSectionGroups) {
				const shouldBreak = drawGroup(group, true, context);
				if (shouldBreak) break;
			}
			this.getGroupSectionCanvasContext().drawImage(
				this.collapsableGroupSectionOffscreenCanvas,
				0,
				0,
				GROUP_SECTION_WIDTH,
				collapsableSectionHeight - SECTION_SPLITTER_HEIGHT
			);
		}

		if (this.hiddenGroupsToCalculate.size > 0) {
			for (const groupToCalculate of this.hiddenGroupsToCalculate.values()) {
				groupToCalculate?.calculateHeatmapCache(groupToCalculate, this.minorStepDataArray, this.minorStep);
			}
		}

		this.verticalScrollFrame = null;
	}

	drawHeatmapShadow(group) {
		if (!group) return;

		const canvas = this.getForegroundCanvasContext();
		const {canvasWidth} = this.state;

		const hasSubGroups = group.groups?.length > 0;
		const isExpanded = group.expanded || group._expanded;
		const isPlaceholderGroupingGroup = group.groupType === GROUP_TYPE.PLACEHOLDERS_SCHEDULING_PLACEHOLDER_GROUPING_GROUP;

		if (
			isExpanded &&
			hasSubGroups &&
			((!isPlaceholderGroupingGroup && (!group?.parentGroup || group.parentGroup.expanded)) || isPlaceholderGroupingGroup)
		) {
			canvas.shadowColor = SCHEDULE_PEOPLE_HEATMAP_SHADOW_COLOR;
			canvas.shadowBlur = SCHEDULE_PEOPLE_HEATMAP_SHADOW_BLUR;
			canvas.fillStyle = TIMELINE_BORDER_COLOR;

			// draw row shadow
			canvas.fillRect(
				0,
				group.screenY + SCHEDULE_PEOPLE_HEATMAP_SHADOW_BLUR,
				canvasWidth,
				SCHEDULE_PEOPLE_HEATMAP_ITEM_SIZE - SCHEDULE_PEOPLE_HEATMAP_SHADOW_BLUR
			);

			// reset shadow properties
			canvas.shadowColor = undefined;
			canvas.shadowBlur = 0;
		}
	}

	// Makes sure that when the heatmap is disabled, task items etc. are shown,
	// even though the group has a compose items function which is used for the heatmap numbers,
	// which are needed for the Total Resource utilization
	drawComposedItems(canvas, visibleGroup, hideWeekends) {
		if (visibleGroup.composeItems) {
			const items = visibleGroup.composeItems(
				visibleGroup,
				this.minorStepDataArray,
				this.startDate,
				this.pixelsPerDay,
				this.minorStep,
				hideWeekends
			);

			drawTimeOffBackground(canvas, items);

			if (this.props.isPeopleScheduling || this.props.isPlaceholdersScheduling) {
				this.drawHeatmapShadow(visibleGroup);
			}

			for (const item of items) {
				// draw item
				item.draw(canvas);

				const adjustedY = Math.floor(item.data.y + (visibleGroup.itemRowHeight - 1 - item.height) / 2);
				item.x = item.data.x;
				item.y = adjustedY;
				item.isInCollapsableSection = visibleGroup.isInCollapsableSection;
				interactionManager.addItem(item, item.data.x + GROUP_SECTION_WIDTH, adjustedY, item.data.width, item.height);

				// drag handle interaction
				if (item.draggableType) {
					interactionManager.addCursorStyleArea(
						item.data.x + GROUP_SECTION_WIDTH,
						adjustedY,
						16,
						item.height,
						CURSOR.RESIZE_HORIZONTAL
					);
					interactionManager.addCursorStyleArea(
						item.data.x + GROUP_SECTION_WIDTH + item.data.width - 16,
						adjustedY,
						16,
						item.height,
						CURSOR.RESIZE_HORIZONTAL
					);
				}

				//Will overlap with drag handles but since drag handles are put into the array first, they will be found first in the interaction manager and will take priority
				if (item.onClick && item.isOnClickEnabled()) {
					interactionManager.addCursorStyleArea(
						item.data.x + GROUP_SECTION_WIDTH,
						adjustedY,
						item.data.width,
						item.height,
						CURSOR.POINTER
					);
				} else {
					interactionManager.addCursorStyleArea(
						item.data.x + GROUP_SECTION_WIDTH,
						adjustedY,
						item.data.width,
						item.height,
						CURSOR.DEFAULT
					);
				}
			}
		}
	}

	drawGroupItems(canvas, visibleGroup, showHeatmap, hideWeekends, canvasHeight, canvasWidth, collapsableSectionHeight) {
		for (const item of visibleGroup.items) {
			if (item.filtered || (!item.visible && !item.isBeingDragged)) continue;

			const x = calculateItemX(item, this.startDate, this.pixelsPerDay, hideWeekends);

			const composeItemsMarginTop =
				showHeatmap && visibleGroup.displayItemsWithHeatmap ? visibleGroup.getHeatmapHeight() : 0;

			const y =
				visibleGroup.screenY +
				visibleGroup.itemRowHeight * (visibleGroup.groupIds ? item.groupedItemRow : item.itemRow) +
				composeItemsMarginTop;

			if (!visibleGroup.isDrawnDueToSpecialCondition && (y + item.height < collapsableSectionHeight || y > canvasHeight))
				continue; //Don't draw if out of view, even if group is visible

			const width = calculateItemWidth(item, this.pixelsPerDay, hideWeekends);

			//Adjust y value to center item within item row
			const adjustedY = visibleGroup.adjustItemY(item, y) + visibleGroup.marginTop;
			item.x = x;
			item.y = adjustedY;
			item.width = width;
			item.isInCollapsableSection = visibleGroup.isInCollapsableSection;

			if (item.isBeingDragged) continue;

			item.onDraw && item.onDraw(x, adjustedY, width, item.height);

			if (width >= 0) {
				item.draw(
					canvas,
					x,
					adjustedY,
					width,
					canvasWidth,
					visibleGroup.groupType === GROUP_TYPE.PEOPLE_SCHEDULING_PERSON_ALLOCATIONS
				);
			}

			const interactionX = x + GROUP_SECTION_WIDTH + (item.draggableArea ? width * item.draggableArea.start : 0);
			const interactionWidth = width * (item.draggableArea ? item.draggableArea.end - item.draggableArea.start : 1);
			interactionManager.addItem(item, interactionX, adjustedY, interactionWidth, item.height);

			// drag handles interaction
			if (item.draggableType) {
				const showDragHandles = interactionWidth > DRAG_HANDLE_VISIBILITY_THRESHOLD;
				if (showDragHandles) {
					interactionManager.addCursorStyleArea(interactionX, adjustedY, 16, item.height, CURSOR.RESIZE_HORIZONTAL);
					interactionManager.addCursorStyleArea(
						interactionX + interactionWidth - 16,
						adjustedY,
						16,
						item.height,
						CURSOR.RESIZE_HORIZONTAL
					);
				}
			}

			// Will overlap with drag handles but since drag handles are put into the array first,
			// they will be found first in the interaction manager and will take priority
			if (item.onClick && item.isOnClickEnabled()) {
				interactionManager.addCursorStyleArea(interactionX, adjustedY, interactionWidth, item.height, CURSOR.POINTER);
			} else if (item.isGhost) {
				interactionManager.addCursorStyleArea(interactionX, adjustedY, interactionWidth, item.height, CURSOR.DEFAULT);
			}
		}
	}

	drawGroupStepsSectionShadow(canvas, canvasHeight, canvasWidth) {
		// draw shadow from group section
		canvas.shadowColor = GROUP_SECTION_SHADOW_COLOR;
		canvas.shadowBlur = GROUP_SECTION_SHADOW_BLUR;
		canvas.fillStyle = GROUP_SECTION_BACKGROUND_COLOR;
		canvas.fillRect(-5, 0, 4, canvasHeight);

		// draw shadow from date steps section
		canvas.shadowColor = DATE_STEPS_SECTION_SHADOW_COLOR;
		canvas.shadowBlur = DATE_STEPS_SECTION_SHADOW_BLUR;
		canvas.fillStyle = DATE_STEPS_SECTION_BACKGROUND_COLOR;
		canvas.fillRect(0, 0 - DATE_STEPS_SECTION_HEIGHT, canvasWidth, DATE_STEPS_SECTION_HEIGHT);

		// reset shadow properties
		canvas.shadowColor = undefined;
		canvas.shadowBlur = 0;
	}

	drawExpandedGroupHighlight(canvas, canvasWidth, group) {
		drawRectangle(canvas, 0, group.screenY, canvasWidth, group.totalHeight, {
			backgroundColor: TIMELINE_EXPANDED_GROUND_HIGHLIGHT_COLOR,
		});
	}

	setRedrawRecalculationProperties() {
		const {disableLoadMore, leftLoadMoreDate, rightLoadMoreDate} = this.props;

		this.hasDrawnStepDataArray = hasDrawnStepArray(this.minorStep, this.minorStepDataArray);

		const {overallStartDate, overallEndDate} = getStepDataArrayStartAndEndDate(this.minorStepDataArray);
		this.anyGroupNeedsRecalculation = RecalculationManager.anyGroupNeedsRecalculation(
			this.minorStep,
			overallStartDate,
			overallEndDate
		);

		for (const step of this.minorStepDataArray) {
			const {startDate, endDate} = step;

			step.isHidden = !disableLoadMore && isStepHidden(step, leftLoadMoreDate, rightLoadMoreDate);
			step.recalculationNeededInStep =
				!hasDrawnStep(this.minorStep, startDate) ||
				RecalculationManager.anyGroupNeedsRecalculation(this.minorStep, startDate, endDate);
		}
	}

	drawForeground(isInitialLoad, preventHeatmapCalculation) {
		resetThrottledRedraw();
		const canvas = this.getForegroundCanvasContext();
		const {canvasWidth, canvasHeight} = this.state;
		const {onDrawForegroundStart, getDependencyData, getDependencyChainTaskIdSet, onDrawForegroundEnd} = this.props;
		const collapsableSectionHeight = this.getCollapsableSectionHeight();
		const hideWeekends = this.isHideWeekendsSelected();
		onDrawForegroundStart && onDrawForegroundStart();
		canvas.clearRect(0, 0, canvasWidth, canvasHeight);

		if (this.visibleMainSectionGroupMap.get(TOTAL_RESOURCE_UTILIZATION_GROUP_ID)) {
			// Move company utilization to end, to make sure that heatmap is recalculated first
			const companyUtilizationGroup = this.visibleMainSectionGroupMap.get(TOTAL_RESOURCE_UTILIZATION_GROUP_ID);
			this.visibleMainSectionGroupMap.delete(TOTAL_RESOURCE_UTILIZATION_GROUP_ID);
			this.visibleMainSectionGroupMap.set(TOTAL_RESOURCE_UTILIZATION_GROUP_ID, companyUtilizationGroup);
		}

		if (!this.hasCombinedModePerformanceImprovements && this.props.isPeopleScheduling && !preventHeatmapCalculation) {
			const personGroupGroups = this.props.groups.filter(
				group => group.groupType === GROUP_TYPE.PERSON_GROUPING_GROUP && !group.filtered
			);
			const personGroups = this.props.groups.filter(group => group.groupType === GROUP_TYPE.PERSON && !group.filtered);
			personGroupGroups.forEach(personGroupGroup => {
				personGroupGroup.groups.forEach(group => {
					if (group.groupType === GROUP_TYPE.PERSON && !group.filtered) {
						personGroups.push(group);
					}
				});
			});

			personGroups.forEach(personGroup => {
				if (personGroup.composeItems && !this.visibleMainSectionGroupMap[personGroup.id]) {
					personGroup.composeItems(
						personGroup,
						this.minorStepDataArray,
						this.startDate,
						this.pixelsPerDay,
						this.minorStep,
						hideWeekends
					);
				}
			});
		}

		this.drawGroupStepsSectionShadow(canvas, canvasHeight, canvasWidth);

		// heatmap eye option
		const heatmapEyeOption = this.props.eyeOptions.find(option => option.name === EYE_OPTION_NAME.SHOW_HEATMAP);
		const showHeatmap = !heatmapEyeOption || heatmapEyeOption.checked;

		if (this.hasCombinedModePerformanceImprovements) {
			this.setRedrawRecalculationProperties();
		}

		let shownGroups = 0;
		for (const visibleGroup of this.visibleMainSectionGroupMap.values()) {
			if (
				(visibleGroup.groupType === GROUP_TYPE.PERSON && this.props.isPeopleScheduling) ||
				(visibleGroup.groupType === GROUP_TYPE.PROJECT && this.props.isProjectScheduling) ||
				(visibleGroup.groupType === GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP && this.props.isPlaceholdersScheduling)
			) {
				shownGroups++;
			}

			// expanded project group highlight
			if (
				this.props.isProjectScheduling &&
				visibleGroup.expanded &&
				!visibleGroup.parentGroup &&
				(visibleGroup.groupType === GROUP_TYPE.PROJECT || visibleGroup.groupType === GROUP_TYPE.PROGRAM)
			) {
				this.drawExpandedGroupHighlight(canvas, canvasWidth, visibleGroup);
			}

			// Draw group lines
			if (visibleGroup.renderRowLines) {
				const {screenY, height} = visibleGroup;
				canvas.beginPath();
				canvas.lineWidth = 1;
				canvas.strokeStyle = '#ebebee';
				canvas.moveTo(0, screenY - 0.5);
				canvas.lineTo(canvasWidth, screenY - 0.5);
				canvas.moveTo(0, screenY + height - 0.5);
				canvas.lineTo(canvasWidth, screenY + height - 0.5);
				canvas.stroke();
				canvas.restore(); //Remove clipping path
			}

			if (showHeatmap && visibleGroup.composeItems) {
				this.drawComposedItems(canvas, visibleGroup, hideWeekends);

				if (visibleGroup.items && visibleGroup.displayItemsWithHeatmap) {
					this.drawGroupItems(
						canvas,
						visibleGroup,
						true,
						hideWeekends,
						canvasHeight,
						canvasWidth,
						collapsableSectionHeight
					);
				}
			} else {
				this.drawGroupItems(
					canvas,
					visibleGroup,
					false,
					hideWeekends,
					canvasHeight,
					canvasWidth,
					collapsableSectionHeight
				);
			}
		}

		// track event
		if (isInitialLoad && this.props.debugData) {
			if (this.props.isPeopleScheduling) {
				const activePeople = this.props.groups.filter(group => group.groupType === 0);
				tracking.trackEvent('People Scheduling, People loaded', {
					allPeople: this.props.debugData.persons.length,
					activePeople: activePeople.length,
					shownPeople: shownGroups,
				});
				trackEvent('People Scheduling People', 'Loaded', {
					allPeople: this.props.debugData.persons.length,
					activePeople: activePeople.length,
					shownPeople: shownGroups,
				});
			} else if (this.props.isProjectScheduling) {
				const notFilteredProjects = this.props.groups.filter(group => group.groupType === 8 && !group.filtered);
				tracking.trackEvent('Project Scheduling, Projects loaded', {
					allProjects: this.props.debugData.projects.length,
					notFilteredProjects: notFilteredProjects.length,
					shownProjects: shownGroups,
				});
				trackEvent('Project Scheduling Projects', 'Loaded', {
					allProjects: this.props.debugData.projects.length,
					notFilteredProjects: notFilteredProjects.length,
					shownProjects: shownGroups,
				});
			} else if (this.props.isProjectScheduling) {
				const placeholders = this.props.groups.filter(
					group =>
						group.groupType === GROUP_TYPE.PLACEHOLDERS_SCHEDULING_PLACEHOLDER_GROUPING_GROUP && !group.filtered
				);
				tracking.trackEvent('Placeholders Scheduling, Placeholders loaded', {
					placeholders,
				});
				trackEvent('Placeholders Scheduling Placeholders', 'Loaded', {
					placeholders,
				});
			}
		}

		// If unassigned tasks is open
		if (collapsableSectionHeight) {
			canvas.clearRect(0, 0, canvasWidth, collapsableSectionHeight);
			const offscreenCanvas = this.collapsableMainSectionOffscreenCanvas;
			const context = offscreenCanvas.getContext('2d');
			context.clearRect(0, 0, canvasWidth, collapsableSectionHeight);
			for (const visibleGroup of this.visibleCollapsableSectionGroupMap.values()) {
				if (visibleGroup.composeItems) {
					const items = visibleGroup.composeItems(
						visibleGroup,
						this.minorStepDataArray,
						this.startDate,
						this.pixelsPerDay,
						this.minorStep
					);
					for (const item of items) {
						item.draw(context);
						item.isInCollapsableSection = visibleGroup.isInCollapsableSection;
						const adjustedY = Math.floor(item.data.y + (visibleGroup.itemRowHeight - 1 - item.height) / 2);
						interactionManager.addItem(
							item,
							item.data.x + GROUP_SECTION_WIDTH,
							adjustedY,
							item.data.width,
							item.height
						);
						if (item.draggableType) {
							const showDragHandles = item.data.width > DRAG_HANDLE_VISIBILITY_THRESHOLD;
							if (showDragHandles) {
								interactionManager.addCursorStyleArea(
									item.data.x + GROUP_SECTION_WIDTH,
									adjustedY,
									16,
									item.height,
									CURSOR.RESIZE_HORIZONTAL
								);
								interactionManager.addCursorStyleArea(
									item.data.x + GROUP_SECTION_WIDTH + item.data.width - 16,
									adjustedY,
									16,
									item.height,
									CURSOR.RESIZE_HORIZONTAL
								);
							}
						}
						//Will overlap with drag handles but since drag handles are put into the array first, they will be found first in the interaction manager and will take priority
						if (item.onClick && item.isOnClickEnabled()) {
							interactionManager.addCursorStyleArea(
								item.data.x + GROUP_SECTION_WIDTH,
								adjustedY,
								item.data.width,
								item.height,
								CURSOR.POINTER
							);
						}
					}
				} else {
					for (const item of visibleGroup.items) {
						if (item.filtered || (!item.visible && !item.isBeingDragged)) continue;

						const x = calculateItemX(item, this.startDate, this.pixelsPerDay, hideWeekends);

						const y = visibleGroup.screenY + visibleGroup.itemRowHeight * item.itemRow;
						if (y + item.height < 0 || y > collapsableSectionHeight - SECTION_SPLITTER_HEIGHT) continue; //Don't draw if out of view, even if group is visible

						const width = calculateItemWidth(item, this.pixelsPerDay, hideWeekends);

						const adjustedY = Math.floor(y + (visibleGroup.itemRowHeight - 1 - item.height) / 2);
						item.x = x;
						item.y = adjustedY;
						item.width = width;
						item.isInCollapsableSection = visibleGroup.isInCollapsableSection;
						if (item.isBeingDragged) continue;
						item.draw(context, x, adjustedY, width, canvasWidth);
						interactionManager.addItem(
							item,
							x + GROUP_SECTION_WIDTH,
							y + (visibleGroup.itemRowHeight - item.height) / 2,
							width,
							item.height
						);
						if (item.draggableType) {
							const showDragHandles = width > DRAG_HANDLE_VISIBILITY_THRESHOLD;
							if (showDragHandles) {
								interactionManager.addCursorStyleArea(
									x + GROUP_SECTION_WIDTH,
									adjustedY,
									16,
									item.height,
									CURSOR.RESIZE_HORIZONTAL
								);
								interactionManager.addCursorStyleArea(
									x + GROUP_SECTION_WIDTH + width - 16,
									adjustedY,
									16,
									item.height,
									CURSOR.RESIZE_HORIZONTAL
								);
							}
						}
						//Will overlap with drag handles but since drag handles are put into the array first, they will be found first in the interaction manager and will take priority
						if (item.onClick && item.isOnClickEnabled()) {
							interactionManager.addCursorStyleArea(
								x + GROUP_SECTION_WIDTH,
								adjustedY,
								width,
								item.height,
								CURSOR.POINTER
							);
						}
					}
				}
			}

			this.getForegroundCanvasContext().drawImage(
				offscreenCanvas,
				0,
				0,
				canvasWidth,
				collapsableSectionHeight - SECTION_SPLITTER_HEIGHT
			);
		}

		// task highlight
		if (
			this.mouseTargetData &&
			this.mouseTargetData.itemData &&
			this.mouseTargetData.itemData.item.data.task &&
			this.mouseTargetData.groupData
		) {
			const {getVisibleTasksInHierarchy} = this.props;
			const projectColor = this.mouseTargetData.groupData.group.data.color;

			if (getVisibleTasksInHierarchy) {
				const mouseOverTask = this.mouseTargetData.itemData.item.data.task;
				const tasksInHierarchy = getVisibleTasksInHierarchy(mouseOverTask);
				if (tasksInHierarchy && tasksInHierarchy.length > 0) {
					for (const taskToHighlight of tasksInHierarchy) {
						const taskDone = taskToHighlight?.task?.done;
						const hoverColor = taskDone ? '#2D6C33' : projectHoverColors[projectColor.toLowerCase()] || '#AEAEBC';

						const options = {
							backgroundOpacity: 0,
							borderThickness: 1,
							borderColor: hoverColor,
							borderRadius: TIMELINE_BAR_BORDER_RADIUS,
						};

						drawRectangle(
							canvas,
							taskToHighlight.x,
							taskToHighlight.y,
							taskToHighlight.width,
							taskToHighlight.height,
							options
						);
					}
					canvas.setLineDash([]);
				}
			}
		}

		// draw dependency lines between tasks
		if (getDependencyData) {
			canvas.lineWidth = 1.5;
			const {dependencies, visibleTaskData, isDraggingWholeDependencyChainModeOn} = getDependencyData(this.dragData);
			let dependencyChainTaskIdSet = null;
			if (isDraggingWholeDependencyChainModeOn) {
				if (this.dragData && this.dragData.itemData) {
					if (this.dragData.itemData.item.data.task) {
						dependencyChainTaskIdSet = getDependencyChainTaskIdSet(this.dragData.itemData.item.data.task.id);
					}
				} else {
					if (this.mouseTargetData && this.mouseTargetData.itemData && this.mouseTargetData.itemData.item.data.task) {
						dependencyChainTaskIdSet = getDependencyChainTaskIdSet(this.mouseTargetData.itemData.item.data.task.id);
					}
				}
			}

			for (const dependencyDataEntry of dependencies) {
				const {fromX, fromY, toX, toY, isCannotStartType, sourceTaskId, targetTaskId} = dependencyDataEntry;

				let shouldBeHighlighted = false;
				if (
					dependencyChainTaskIdSet &&
					(dependencyChainTaskIdSet.has(sourceTaskId) || dependencyChainTaskIdSet.has(targetTaskId))
				) {
					shouldBeHighlighted = true;
				}

				canvas.beginPath();
				//toX - fromX > 0.001 instead of toX > fromX to avoid floating point errors
				canvas.strokeStyle = shouldBeHighlighted ? '#6e0fea' : toX - fromX > 0.001 ? '#ff0000' : '#a1a1a1';
				canvas.fillStyle = shouldBeHighlighted ? '#6e0fea' : toX - fromX > 0.001 ? '#ff0000' : '#a1a1a1';

				if (isCannotStartType) {
					const middleX = (fromX + toX) / 2;
					const middleY = (fromY + toY) / 2;

					canvas.moveTo(fromX, fromY);
					canvas.quadraticCurveTo(fromX - 20, fromY, middleX, middleY);
					canvas.quadraticCurveTo(toX + 20, toY, toX, toY);
					canvas.stroke();

					const angle = Math.atan2(toY - fromY - 20, toX + 20 - fromX) + (Math.PI * 3) / 4;
					canvas.beginPath();
					canvas.moveTo(middleX, middleY);
					canvas.lineTo(middleX - 8 * Math.cos(angle), middleY - 8 * Math.sin(angle));
					canvas.lineTo(middleX + 8 * Math.sin(angle), middleY - 8 * Math.cos(angle));
					canvas.closePath();
					canvas.fill();
				} else {
					canvas.moveTo(fromX, fromY);
					canvas.bezierCurveTo(fromX + 20, fromY, fromX + 20, toY, toX, toY);
					canvas.stroke();

					canvas.beginPath();

					const distance = Math.sqrt(Math.pow(fromX - toX, 2) + Math.pow(fromY - toY, 2));

					const yDifferenceFactor = Util.clamp(Math.sqrt(20 * Math.abs(fromY - toY)) / 100, 0, 1);
					const lowDistanceFactor = Util.clamp(150 - distance, 0, 150) / 150;

					const t = 0.8 - 0.3 * yDifferenceFactor - 0.3 * lowDistanceFactor;
					const centerX =
						Math.pow(1 - t, 3) * fromX +
						3 * Math.pow(1 - t, 2) * t * (fromX + 20) +
						3 * (1 - t) * Math.pow(t, 2) * (fromX + 20) +
						Math.pow(t, 3) * toX;
					const centerY =
						Math.pow(1 - t, 3) * fromY +
						3 * Math.pow(1 - t, 2) * t * fromY +
						3 * (1 - t) * Math.pow(t, 2) * toY +
						Math.pow(t, 3) * toY;

					const angle = Math.atan2(toY - fromY, toX - fromX) + (Math.PI * 3) / 4;
					canvas.beginPath();
					canvas.moveTo(centerX, centerY);
					canvas.lineTo(centerX - 8 * Math.cos(angle), centerY - 8 * Math.sin(angle));
					canvas.lineTo(centerX + 8 * Math.sin(angle), centerY - 8 * Math.cos(angle));
					canvas.closePath();
					canvas.fill();
				}

				canvas.fillStyle = shouldBeHighlighted ? '#6e0fea' : '#a1a1a1';
				canvas.beginPath();
				canvas.arc(
					fromX,
					fromY,
					5,
					(isCannotStartType ? 0.5 : 1.5) * Math.PI,
					(isCannotStartType ? 1.5 : 0.5) * Math.PI
				);
				canvas.fill();
				canvas.beginPath();
				canvas.arc(toX, toY, 5, 1.5 * Math.PI, 0.5 * Math.PI);
				canvas.fill();
			}

			if (dependencyChainTaskIdSet && dependencyChainTaskIdSet.size > 1) {
				const tasksToHighlight = visibleTaskData.filter(task => dependencyChainTaskIdSet.has(task.id));
				for (const taskToHighlight of tasksToHighlight) {
					if (taskToHighlight.width > 0) {
						drawRectangle(
							canvas,
							taskToHighlight.x,
							taskToHighlight.y,
							taskToHighlight.width,
							taskToHighlight.height,
							{
								backgroundOpacity: 0,
								borderThickness: 2,
								borderColor: '#6e0fea',
								borderRadius: 4,
							}
						);
					}
				}
			}

			if (this.dragData && this.dragData.isCreatingDependency) {
				if (this.props.simulationMode) return;

				this.dragData.visibleTaskData = visibleTaskData;
				canvas.fillStyle = '#a1a1a1';
				for (const visibleTask of visibleTaskData) {
					if (visibleTask.y + visibleTask.height / 2 === this.dragData.fromY || !visibleTask.allowSnapping) continue;
					canvas.beginPath();
					canvas.arc(visibleTask.x, visibleTask.y + visibleTask.height / 2, 5, 0, Math.PI * 2);
					canvas.arc(visibleTask.x + visibleTask.width, visibleTask.y + visibleTask.height / 2, 5, 0, Math.PI * 2);
					canvas.fill();
				}
			}
		}

		// draw current step highlight
		const currentPeriodStep = this.minorStepDataArray.find(step => step.isCurrentPeriod);
		if (currentPeriodStep) {
			const x = currentPeriodStep.position;
			const y = 0;
			const width = currentPeriodStep.width;
			const height = canvasHeight;

			canvas.lineWidth = 1;
			canvas.strokeStyle = '#6e0fea';

			canvas.beginPath();
			canvas.moveTo(x, y);
			canvas.lineTo(x, height);
			canvas.stroke();

			canvas.beginPath();
			canvas.moveTo(x + width, y);
			canvas.lineTo(x + width, height);
			canvas.stroke();
		}

		if (this.hasCombinedModePerformanceImprovements) {
			for (const step of this.minorStepDataArray) {
				if (!step.isHidden) {
					markStepDrawn(this.minorStep, step.startDate, step.endDate);
				}
			}

			markStepDataArrayDrawn(this.minorStep, this.minorStepDataArray);

			const {overallStartDate, overallEndDate} = getStepDataArrayStartAndEndDate(this.minorStepDataArray);
			RecalculationManager.clearGlobalNeedsRecalculation(this.minorStep, overallStartDate, overallEndDate);
		}

		onDrawForegroundEnd && onDrawForegroundEnd(canvas, this.mouseTargetData, this.minorStepDataArray);
	}

	getDateToCentralizeDate(canvasDate) {
		const {canvasWidth} = this.state;
		const {pixelsPerDay} = this;
		// Place the target date at 25% of the canvas width
		return canvasDate - 0.25 * (canvasWidth / pixelsPerDay);
	}

	onForceRedrawEventReceived(
		options = {
			preventFiltering: false,
			isInitialLoad: false,
			preventHeatmapCalculation: false,
		}
	) {
		//Don't redraw if the timeline hasn't had the chance to fully mount yet
		if (!this.minorStepDataArray?.length || !this.majorStepDataArray?.length) return;
		this.redraw(options);
	}

	redraw(options = {}) {
		if (options.allowRedraw) {
			this.preventRedraw = false;
		}

		if (this.isTimelineAboutToUnmount || this.preventRedraw) return;

		if (options.clearHasDrawnCaches) {
			clearDrawnStepDataArrayMap();
			clearDrawnStepsMap();
		}

		const {skipBackground, skipPostProcessing, isTriggeredByVerticalScrolling} = options;
		interactionManager.clearGroupSection();
		this.drawGroups(isTriggeredByVerticalScrolling);
		interactionManager.clearMainSection();

		if (!skipBackground) {
			this.drawBackground();
		}

		this.drawForeground(options.isInitialLoad, options.preventHeatmapCalculation);

		if (this.postProcessingRenderer.isInUse && !skipPostProcessing) {
			this.postProcessingRenderer.isInUse = false;
			this.postProcessingRenderer.clear();
		}

		if (this.state.showLoader) {
			this.setState({showLoader: false});
		}

		trackPerformanceInitialRender();
	}

	showLoader(callback) {
		this.setState({showLoader: true}, () => setTimeout(callback, 1));
	}

	scrollToCanvasDate(canvasDate, applyEasing = true) {
		const {canvasWidth} = this.state;
		const {startDate, pixelsPerDay} = this;
		const currentMiddleDate = startDate + canvasWidth / pixelsPerDay / 2;
		const daysToTarget = canvasDate - currentMiddleDate;
		const scrollAmount = daysToTarget / (canvasWidth / pixelsPerDay);
		this.scroll(scrollAmount, {applyEasing});
	}

	scrollToToday(screenOffsetPercentage) {
		const {canvasWidth} = this.state;
		const {startDate, pixelsPerDay, todayIndex} = this;
		const currentMiddleDate = startDate + canvasWidth / pixelsPerDay / 2;
		const daysToTarget = todayIndex + screenOffsetPercentage * (canvasWidth / pixelsPerDay) - currentMiddleDate;
		const scrollAmount = daysToTarget / (canvasWidth / pixelsPerDay);
		this.scroll(scrollAmount, {applyEasing: true});
	}

	scroll(scrollAmount, options = {}) {
		if (this.props.onBeforeHorizontalScroll) {
			const continueScrolling = this.props.onBeforeHorizontalScroll(scrollAmount, this.minorStepDataArray);
			if (!continueScrolling) return;
		}

		//Scroll timeline by the provided amount (for example 0.5 will scroll the timeline 50% to the future)
		//Negative value for scrolling to the past
		const {secondsPerPixel} = this;
		const {canvasWidth} = this.state;
		const deltaSeconds = Math.max(secondsPerPixel * canvasWidth * scrollAmount, (0 - this.startDate) * SECONDS_PER_DAY);
		const newStartDate = this.cappedStartDate(this.startDate + deltaSeconds / SECONDS_PER_DAY);

		cancelAnimationFrame(this.horizontalScrollFrame);

		const oldFirstMinorStepStartDate = this.minorStepDataArray[0].startDate;
		const oldLastMinorStepEndDate = this.minorStepDataArray[this.minorStepDataArray.length - 1].endDate;
		const hasMinorStepsChanged = () =>
			oldFirstMinorStepStartDate !== this.minorStepDataArray[0].startDate ||
			oldLastMinorStepEndDate !== this.minorStepDataArray[this.minorStepDataArray.length - 1].endDate;

		if (options.applyEasing) {
			let finishedAnimationFrames = 0;
			const animateScrolling = () => {
				this.startDate = this.cappedStartDate(
					this.startDate + (deltaSeconds * (1 / SCROLL_ANIMATION_FRAME_COUNT)) / SECONDS_PER_DAY
				);
				++finishedAnimationFrames;
				const animationDone = finishedAnimationFrames === SCROLL_ANIMATION_FRAME_COUNT;
				if (animationDone) {
					this.startDate = newStartDate;
					this.props.onRangeChange && this.props.onRangeChange(this.startDate, this.getEndDate());
				}
				this.calculateStepDataArrays();
				this.redraw({
					preventFiltering: true,
					isTriggeredByHorizontalScrolling: animationDone && hasMinorStepsChanged(),
					preventHeatmapCalculation: true,
				});
				if (animationDone) return;
				this.horizontalScrollFrame = requestAnimationFrame(animateScrolling);
			};
			this.horizontalScrollFrame = requestAnimationFrame(animateScrolling);
		} else {
			this.startDate = newStartDate;
			this.props.onRangeChange && this.props.onRangeChange(this.startDate, this.getEndDate());
			this.horizontalScrollFrame = requestAnimationFrame(() => {
				this.calculateStepDataArrays();
				this.redraw({
					preventFiltering: true,
					isTriggeredByHorizontalScrolling: hasMinorStepsChanged(),
					preventHeatmapCalculation: true,
				});
				this.onMouseMove({clientX: this.lastKnownMouseX, clientY: this.lastKnownMouseY});
			});
		}

		// Adjust startDate to skip weekend days if necessary
		if (this.isHideWeekendsSelected()) {
			this.startDate = adjustForHiddenWeekend(this.startDate, deltaSeconds);
		}

		if (this.props.onHorizontalScroll) {
			this.props.onHorizontalScroll();
		}
	}

	cappedStartDate(newStartDate) {
		const canvasDateWidth = (this.secondsPerPixel * this.state.canvasWidth) / SECONDS_PER_DAY;
		const newEndDate = newStartDate + canvasDateWidth;
		if (newStartDate < 0) {
			return 0;
		} else if (newEndDate > this.maxDay) {
			return this.maxDay - canvasDateWidth;
		}
		return newStartDate;
	}

	scrollToTop() {
		this.scrollVertically(-this.state.mainSectionScrollTop, false);
	}

	scrollVertically(deltaY, isCollapsableSection = false) {
		//Do not allow scrolling vertically when user is dragging on empty space to create a new item
		if (this.dragData && !this.dragData.itemData) return false;
		if (this.verticalScrollFrame) return false;
		const {mainSectionContentHeight, mainSectionScrollTop, collapsableSectionContentHeight, collapsableSectionScrollTop} =
			this.state;
		const mainSectionHeight = this.getMainSectionHeight();
		const collapsableSectionHeight = this.getCollapsableSectionHeight();

		const sectionScrollTop = isCollapsableSection ? collapsableSectionScrollTop : mainSectionScrollTop;
		const sectionHeight = isCollapsableSection ? collapsableSectionHeight : mainSectionHeight;
		const sectionContentHeight = isCollapsableSection ? collapsableSectionContentHeight : mainSectionContentHeight;
		//Round to avoid drawing prerendered groups on non integer pixels which makes them blurry
		let newScrollTop = sectionScrollTop + Math.round(deltaY);
		if (sectionHeight > sectionContentHeight) return false;
		if (newScrollTop < 0) {
			newScrollTop = 0;
		} else if (newScrollTop > sectionContentHeight - sectionHeight) {
			newScrollTop = sectionContentHeight - sectionHeight;
		}
		if (newScrollTop !== sectionScrollTop) {
			if (isCollapsableSection) {
				this.setState({collapsableSectionScrollTop: newScrollTop});
			} else {
				this.setState({mainSectionScrollTop: newScrollTop});
			}
			if (this.props.onVerticalScroll) {
				this.props.onVerticalScroll(sectionScrollTop - newScrollTop);
			}
			this.verticalScrollFrame = requestAnimationFrame(() => {
				this.redraw({
					skipBackground: true,
					isTriggeredByVerticalScrolling: true,
					preventFiltering: true,
					preventHeatmapCalculation: true,
				});
				this.onMouseMove({clientX: this.lastKnownMouseX, clientY: this.lastKnownMouseY});
			});
		}
		return true;
	}

	onKeyDown(e) {
		if (
			document.activeElement.tagName.toLowerCase() === 'input' ||
			document.getElementsByClassName('layer').length !== 0 ||
			document.getElementsByClassName('generic-modal').length !== 0
		)
			return;
		const {top: timelineClientY} = this.canvasContainer.getBoundingClientRect();
		const collapsableSectionHeight = this.getCollapsableSectionHeight();
		const isMouseOverCollapsableSection =
			collapsableSectionHeight &&
			this.lastKnownMouseY - timelineClientY - DATE_STEPS_SECTION_HEIGHT < collapsableSectionHeight;
		switch (e.key) {
			case 'ArrowRight':
				this.scroll(0.05);
				break;
			case 'ArrowLeft':
				this.scroll(-0.05);
				break;
			case 'ArrowUp':
				this.scrollVertically(-60, isMouseOverCollapsableSection);
				break;
			case 'ArrowDown':
				this.scrollVertically(60, isMouseOverCollapsableSection);
				break;
		}

		// On Mac "Command + P" or all other platforms "CTRL + P"
		if ((isMac && e.keyCode === 80 && e.metaKey) || (!isMac && e.keyCode === 80 && e.ctrlKey)) {
			this.onPrint(e);
		}
	}

	onPrint(e) {
		if (e) {
			e.preventDefault();
		}

		const backgroundCanvas = this.getBackgroundCanvasContext().canvas;
		const foregroundCanvas = this.getForegroundCanvasContext().canvas;
		const groupSectionCanvas = this.getGroupSectionCanvasContext().canvas;

		const groupOffset = groupSectionCanvas.width;
		const foregroundOffset = backgroundCanvas.height - foregroundCanvas.height;

		const cacheCanvas = document.createElement('canvas');
		cacheCanvas.height = backgroundCanvas.height + foregroundOffset;
		cacheCanvas.width = backgroundCanvas.width + groupOffset;

		this.combineCanvas(0, foregroundOffset, groupSectionCanvas, cacheCanvas);
		this.combineCanvas(groupOffset, 0, backgroundCanvas, cacheCanvas);
		this.combineCanvas(groupOffset, foregroundOffset, foregroundCanvas, cacheCanvas);

		let resultImage = cacheCanvas.toDataURL();

		printJS({
			printable: resultImage,
			type: 'image',
			style: '@page { size:landscape; }',
		});
	}

	combineCanvas(x, y, canvas, cacheCanvas) {
		cacheCanvas.getContext('2d').drawImage(canvas, x, y, canvas.width, canvas.height);
	}

	onGroupSectionWheel(e) {
		this.onWheel(true, e);
	}

	onForegroundWheel(e) {
		this.onWheel(false, e);
	}

	onWheel(isGroupSectionEvent, e) {
		e.preventDefault();
		const {deltaY, deltaX, ctrlKey, clientX, deltaMode} = e;
		const {zoomLevel, canvasWidth} = this.state;
		const {left: timelineClientX, top: timelineClientY} = this.canvasContainer.getBoundingClientRect();

		if (ctrlKey) {
			if (!isGroupSectionEvent && Math.abs(deltaY) > 1 && (!isMac || !this.preventZoomTrigger)) {
				//Mouse wheel with ctrl pressed or touchpad pinch/unpinch
				const zoomCenterOffset = (clientX - timelineClientX) / canvasWidth;
				this.setZoomLevel(zoomLevel + (deltaY > 0 ? -1 : 1), true, zoomCenterOffset);
				if (isMac) {
					this.preventZoomTrigger = true;
					setTimeout(() => (this.preventZoomTrigger = false), 200);
				}
			}
		} else {
			//Delta mode is 0 in all browsers except for firefox which put lines instead of pixels as delta value
			if (deltaX && !isGroupSectionEvent) {
				this.scroll((deltaX * (deltaMode ? 10 : 1)) / 5000);
			}
			if (deltaY) {
				const isMouseOverCollapsableSection =
					e.clientY - timelineClientY - DATE_STEPS_SECTION_HEIGHT < this.getCollapsableSectionHeight();
				this.scrollVertically(deltaY * (deltaMode ? 10 : 1), isMouseOverCollapsableSection);
				requestAnimationFrame(() => {
					if (this.hoveredEntity && this.hoveredEntity.onMouseLeave) {
						this.hoveredEntity.onMouseLeave();
						this.hoveredEntity = null;
					}
					this.onMouseMove({clientX: this.lastKnownMouseX, clientY: this.lastKnownMouseY});
				});
			}
		}

		if (this.props.onScrollEnd) {
			// Clear our timeout throughout the scroll
			window.clearTimeout(this.isWheelScrolling);
			// Set a timeout to run after scrolling ends
			this.isWheelScrolling = setTimeout(() => {
				// Run the callback
				this.props.onScrollEnd(
					this.visibleMainSectionGroupMap,
					this.minorStepDataArray,
					this.minorStep,
					this.startDate
				);
			}, 500);
		}
	}

	getStartDateFromMouseX(mouseX) {
		const {left} = this.canvasContainer.getBoundingClientRect();
		// we need to add logic that accounts for hidden weekends
		return Math.floor(this.startDate + (mouseX - left) / this.pixelsPerDay);
	}

	onWindowMouseDown(e) {
		if (typeof e.target.id === 'string' && e.target.id.includes('canvas')) {
			//Prevent default behavior if mouse downing on canvas
			//This is here to ensure that the browser does not begin doing the normal html drag stuff when you start dragging shit on canvas
			e.preventDefault();
			//If filters dropdown is open, clicking on canvas will not trigger onBlur due to preventDefault, we want to close it
			if (document.activeElement && document.activeElement.blur) {
				document.activeElement.blur();
			}
		}

		this.lastKnownMouseDownX = e.clientX;
		this.lastKnownMouseDownY = e.clientY;

		this.scrollStartDate = this.startDate;
		const {itemData, groupData} = this.mouseTargetData;
		const {isScrollClickIconVisible, canvasOffsetLeft} = this.state;
		//Only start dragging if there is item under cursor, and left mouse button was pressed
		//Don't start dragging if you mouse down while scrolling through middle mouse click
		if (e.button === 0 && !isScrollClickIconVisible && e.clientX > GROUP_SECTION_WIDTH) {
			if (itemData && itemData.item.allowDependencyCreation) {
				const startXDelta = e.clientX - itemData.x - canvasOffsetLeft;
				const endXDelta = itemData.x + itemData.width - e.clientX + canvasOffsetLeft;
				if (startXDelta < 0 || endXDelta < 0) {
					// create dependency when dragging the link
					this.dragData = {
						itemData,
						isCreatingDependency: true,
						fromX: startXDelta < 0 ? itemData.x : itemData.x + itemData.width,
						fromY: itemData.y + itemData.height / 2,
						initialFromX: startXDelta < 0 ? itemData.x : itemData.x + itemData.width,
						initialFromY: itemData.y + itemData.height / 2,
						groupData,
						previousItemData: this.previousMouseTargetData.itemData,
						previousGroupData: this.previousMouseTargetData.groupData,
					};
					return; //Don't want to test for any other dragging actions, end the function execution
				}
			}
			if (itemData && itemData.item.draggableType && groupData) {
				let dragPoint = ITEM_DRAG_POINT.CENTER;
				//Check if drawing by the drag handles, if so, then the item is being resized not dragged
				if (itemData.width >= DRAG_HANDLE_VISIBILITY_THRESHOLD) {
					if (e.clientX - itemData.x - canvasOffsetLeft < 16) {
						dragPoint = ITEM_DRAG_POINT.LEFT;
					} else if (itemData.x + itemData.width - e.clientX + canvasOffsetLeft < 16) {
						dragPoint = ITEM_DRAG_POINT.RIGHT;
					}
				}
				this.dragData = {
					itemData,
					groupData,
					initialX: e.clientX,
					initialY: e.clientY,
					initialStartDate: itemData.item.startDate,
					initialEndDate: itemData.item.endDate,
					dayDifference: 0,
					dragPoint,
					mouseDown: true,
				};

				if (dragPoint === ITEM_DRAG_POINT.CENTER && itemData.item.draggableType === DRAGGABLE_TYPE.DATE_AND_GROUP) {
					this.dragData.initialGroup = groupData.group;
					this.dragData.initialGroupData = groupData;
				}
			} else if (itemData && itemData.item.onMoveAttempt) {
				this.dragData = {
					itemData,
					isMoveAttempt: true,
					moveAttemptInitialX: e.clientX,
				};
			} else if (
				groupData &&
				groupData.group &&
				groupData.group.onItemCreate &&
				!itemData &&
				e.clientX > GROUP_SECTION_WIDTH + canvasOffsetLeft
			) {
				if (this.props.simulationMode) return;

				this.dragData = {
					groupData,
					initialX: e.clientX,
					initialY: e.clientY,
					dayDifference: 0,
					startDate: this.getStartDateFromMouseX(e.clientX),
				};
			}
		}

		if (this.hoveredEntity && this.hoveredEntity.onMouseLeave && this.dragData) {
			this.hoveredEntity.onMouseLeave();
			this.hoveredEntity = null;
		}

		if (!isScrollClickIconVisible) return;
		this.setState({isScrollClickIconVisible: false});
	}

	onMainSectionMouseDown(e) {
		if (hasFeatureFlag('scheduling_mousedown_only_canvas')) {
			this.onWindowMouseDown(e);
		}
		if (e.button === 1) {
			if (this.state.isScrollClickIconVisible) return;
			e.stopPropagation();
			this.setState({
				isScrollClickIconVisible: true,
				isScrollClickOverCollapsableSection:
					e.clientY - this.canvasContainer.getBoundingClientRect().top - DATE_STEPS_SECTION_HEIGHT <
					this.getCollapsableSectionHeight(),
				scrollClickIconPositionLeft: e.clientX,
				scrollClickIconPositionTop: e.clientY,
				scrollClickDeltaX: 0,
				scrollClickDeltaY: 0,
			});
			requestAnimationFrame(this.scrollClickScroll.bind(this));
		}
	}

	onMouseMove(e) {
		this.lastKnownMouseX = e.clientX;
		this.lastKnownMouseY = e.clientY;
		const {
			isScrollClickIconVisible,
			scrollClickIconPositionLeft,
			scrollClickIconPositionTop,
			canvasOffsetTop,
			canvasOffsetLeft,
			canvasWidth,
		} = this.state;

		const mainSectionHeight = this.getMainSectionHeight();
		const collapsableSectionHeight = this.getCollapsableSectionHeight();

		this.previousMouseTargetData = this.mouseTargetData;
		this.mouseTargetData = interactionManager.getDataAtPosition(
			e.clientX - canvasOffsetLeft,
			e.clientY - canvasOffsetTop - DATE_STEPS_SECTION_HEIGHT
		);

		if (this.props.onMouseMoveEnd) {
			this.props.onMouseMoveEnd(this.mouseTargetData);
		}

		const groupData = this.mouseTargetData.groupData;
		let group = groupData?.group;

		if (
			group &&
			((group.isInCollapsableSection && group.screenY > collapsableSectionHeight) ||
				(!group.isInCollapsableSection && group.screenY + group.height < collapsableSectionHeight))
		) {
			group = null;
		}

		const isHoveringGroupButton = group?.onButtonClick ? this.isMouseWithinButton(groupData, e) : null;
		const groupButtonOnHoverActive = group?.isHoveringButton;

		if (
			group?.onButtonClick &&
			e.clientX <= canvasOffsetLeft + GROUP_SECTION_WIDTH &&
			isHoveringGroupButton !== groupButtonOnHoverActive
		) {
			if (group) {
				if (isHoveringGroupButton && !group.isHoveringButton) {
					group.isHoveringButton = true;
					this.redraw({skipPostProcessing: true, skipBackground: true, preventFiltering: true});
				} else if (group.isHoveringButton && !isHoveringGroupButton) {
					group.isHoveringButton = false;
					this.redraw({skipPostProcessing: true, skipBackground: true, preventFiltering: true});
				}
			}
		}

		if (group?.onMouseEnter && e.clientX <= canvasOffsetLeft + GROUP_SECTION_WIDTH) {
			if (this.hoveredEntity !== group) {
				if (this.hoveredEntity && this.hoveredEntity.onMouseLeave) {
					this.hoveredEntity.onMouseLeave();
				}
				this.hoveredEntity = group;
				if (group && !this.dragData) {
					group.onMouseEnter({
						x: 0,
						y: DATE_STEPS_SECTION_HEIGHT + group.screenY,
						width: GROUP_SECTION_WIDTH,
						height: group.height,
						group,
					});
				}
			}
		} else if (
			e.target &&
			e.target.id === 'foreground-canvas' &&
			this.mouseTargetData.itemData &&
			this.mouseTargetData.itemData.item.onMouseEnter &&
			e.clientX > canvasOffsetLeft + GROUP_SECTION_WIDTH
		) {
			const isMouseOverCollapsableSection =
				e.clientY - canvasOffsetTop - DATE_STEPS_SECTION_HEIGHT < this.getCollapsableSectionHeight();
			if (this.mouseTargetData.itemData.item.isInCollapsableSection !== isMouseOverCollapsableSection) {
				return false;
			}
			const item = this.mouseTargetData.itemData.item;
			if (this.hoveredEntity !== item) {
				if (this.hoveredEntity && this.hoveredEntity.onMouseLeave) {
					this.hoveredEntity.onMouseLeave();
				}
				this.hoveredEntity = item;
				const x = item.x + GROUP_SECTION_WIDTH + canvasOffsetLeft;
				if (!this.dragData) {
					item.onMouseEnter(
						{
							x: x > GROUP_SECTION_WIDTH ? x : GROUP_SECTION_WIDTH + canvasOffsetLeft,
							y: DATE_STEPS_SECTION_HEIGHT + item.y + canvasOffsetTop,
							width: item.width,
							height: item.height,
						},
						e
					);
				}
			}
		} else {
			if (this.hoveredEntity && this.hoveredEntity.onMouseLeave) {
				this.hoveredEntity.onMouseLeave();
				this.hoveredEntity = null;
			}
		}

		// Mouse move functionality
		if (
			e.target &&
			e.target.id === 'foreground-canvas' &&
			this.mouseTargetData.itemData &&
			this.mouseTargetData.itemData.item.onMouseMove &&
			e.clientX > canvasOffsetLeft + GROUP_SECTION_WIDTH
		) {
			const isMouseOverCollapsableSection =
				e.clientY - canvasOffsetTop - DATE_STEPS_SECTION_HEIGHT < this.getCollapsableSectionHeight();
			if (this.mouseTargetData.itemData.item.isInCollapsableSection !== isMouseOverCollapsableSection) {
				return false;
			}

			const item = this.mouseTargetData.itemData.item;
			this.hoveredEntity = item;
			const x = item.x + GROUP_SECTION_WIDTH + canvasOffsetLeft;
			if (!this.dragData) {
				item.onMouseMove(
					{
						item,
						x: x > GROUP_SECTION_WIDTH ? x : GROUP_SECTION_WIDTH + canvasOffsetLeft,
						y: DATE_STEPS_SECTION_HEIGHT + item.y,
						width: item.width,
						height: item.height,
					},
					e
				);
			}
		}
		//Change cursor style if necessary, based on mouseTargetData
		this.setCursorStyle();

		if (isScrollClickIconVisible) {
			this.setState({
				scrollClickDeltaX: e.clientX - scrollClickIconPositionLeft,
				scrollClickDeltaY: e.clientY - scrollClickIconPositionTop,
			});
		}
		if (this.postProcessingRenderer.isInUse) {
			this.postProcessingRenderer.isInUse = false;
			this.postProcessingRenderer.clear();
		}
		this.postProcessingRenderer.processMouseMoveData(this.mouseTargetData, this.dragData, collapsableSectionHeight);

		const displaySplitAllocation = this.props.splitAllocationBarData && this.props.splitAllocationBarData.visible;
		const displaySplitPlaceholderAllocation =
			this.props.splitPlaceholderAllocationBarData && this.props.splitPlaceholderAllocationBarData.visible;

		const hideWeekend = this.isHideWeekendsSelected();
		if (displaySplitAllocation || displaySplitPlaceholderAllocation) {
			const splitData = displaySplitAllocation
				? this.props.splitAllocationBarData
				: this.props.splitPlaceholderAllocationBarData;

			const splitDate = splitData.canvasDate;
			// draw the split allocation bar if the user if hovering the split option in the context menu
			let numberOfWeekendDaysBeforeSplitDay = 0;

			// in this if() we are removing the weekends that are not visible to be able to draw the split line in the right spot
			// (basically we are undoing what we did in the if() statement in the this.onForegroundContextMenu)
			if (hideWeekend) {
				// some hack to make the split date accurate. We should get rid of code like this and update the getStartDateFromMouseX to support hidden weekends
				let steps = 1;
				while (Math.floor(this.startDate) + steps <= splitDate) {
					if (this.dayData[Math.floor(this.startDate) + steps].isoWeekday > 5) {
						numberOfWeekendDaysBeforeSplitDay++;
					}
					steps += 1;
				}
			}

			const x = (splitDate - this.startDate - numberOfWeekendDaysBeforeSplitDay) * this.pixelsPerDay;
			const {
				y,
				height,
				data: {color: allocationColor},
			} = splitData.item;

			this.postProcessingRenderer.drawSplitAllocationBar(x, y, height, allocationColor);
		}

		const deltaX = Math.abs(this.lastKnownMouseDownX - this.lastKnownMouseX);
		const deltaY = Math.abs(this.lastKnownMouseDownY - this.lastKnownMouseY);
		if (this.dragData && deltaX + deltaY > 5) {
			//Check if mouse is near the edge and if it is, begin scrolling
			this.performEdgeScrollingCheck(mainSectionHeight, collapsableSectionHeight);

			this.dragData.currentX = e.clientX;
			this.dragData.currentY = e.clientY;

			let scrollDiff = this.startDate - this.scrollStartDate;
			if (hideWeekend) {
				const weekendDays = getWeekendDaysBetween(this.getStartDate(), this.scrollStartDate, this.dayData);
				scrollDiff = scrollDiff > 0 ? scrollDiff - weekendDays : scrollDiff + weekendDays;
			}
			const dayDifference = Math.round(
				(this.dragData.currentX - this.dragData.initialX) / this.pixelsPerDay + scrollDiff
			);
			const hasDateChanged = dayDifference !== this.dragData.dayDifference;
			//Store if date has been changed at least once, used for not drawing date indicators when you just move your mouse by 1 px as it was getting triggered on click when you had a shitty apple mouse
			if (hasDateChanged) {
				this.dragData.hasDateChanged = true;
			}

			if (this.dragData.isCreatingDependency) {
				this.dragData.toX = e.clientX - canvasOffsetLeft;
				this.dragData.toY = e.clientY - canvasOffsetTop - DATE_STEPS_SECTION_HEIGHT;
				if (!this.dragData.hasMouseMoved) {
					this.redraw({skipPostProcessing: true, skipBackground: true, preventFiltering: true});
				}
			} else if (this.dragData.itemData) {
				const {itemData} = this.dragData;
				const {item} = itemData;

				if (!this.dragData.moveAttemptTriggered && item.isDisabled() && item.onMoveAttempt) {
					if (Math.abs(this.dragData.moveAttemptInitialX - e.clientX) > 10) {
						item.onMoveAttempt(item);
						this.dragData.moveAttemptTriggered = true;
					}
				} else if (
					!item.isDisabled() &&
					[ITEM_DRAG_POINT.LEFT, ITEM_DRAG_POINT.RIGHT].includes(this.dragData.dragPoint)
				) {
					if (hasDateChanged) {
						item.resetItemRow(); //Need to reset the item row in case it will collide with other items after resizing
						const isResizingEndDate = this.dragData.dragPoint === ITEM_DRAG_POINT.RIGHT;
						const movedDays = dayDifference - this.dragData.dayDifference;
						let newStartDate = item.startDate;
						let newEndDate = item.endDate;
						let startDifference = 0;
						let endDifference = 0;

						if (isResizingEndDate) {
							endDifference = weekendAdjustedMovedDays(newEndDate, movedDays, hideWeekend);
							newEndDate += endDifference;
							if (newEndDate - item.startDate < 0) {
								newEndDate = item.startDate;
								endDifference = item.endDate - newEndDate;
							}
						} else {
							startDifference = weekendAdjustedMovedDays(newStartDate, movedDays, hideWeekend);
							newStartDate += startDifference;
							if (item.endDate - newStartDate < 0) {
								newStartDate = item.endDate;
								startDifference = item.startDate - newStartDate;
							}
						}

						this.dragData.newStartDate = newStartDate;
						this.dragData.newEndDate = newEndDate;
						if (
							!item.onMoving ||
							item.onMoving(
								item,
								this.dragData.groupData.group,
								startDifference,
								endDifference,
								this.dragData,
								movedDays
							)
						) {
							item.endDate = newEndDate;
							item.startDate = newStartDate;
						}
						//Skip redrawing post processing canvas to avoid having the row hover effect flicker
						if (!this.mouseMoveRedrawFrame) {
							this.mouseMoveRedrawFrame = requestAnimationFrame(() => {
								this.redraw({skipPostProcessing: true, skipBackground: true, preventFiltering: false});
								this.mouseMoveRedrawFrame = null;
							});
						}
					}
					this.dragData.dayDifference = dayDifference;
				} else if (
					!item.isDisabled() &&
					(item.draggableType === DRAGGABLE_TYPE.DATE_ONLY ||
						(Math.abs(this.dragData.currentY - this.dragData.initialY) < 50 && !this.dragData.hasItemDislodged))
				) {
					if (hasDateChanged) {
						addOriginalCopyToItem(item);

						item.resetItemRow(); //Need to reset the item row in case it will collide with other items after resizing

						const movedDays = dayDifference - this.dragData.dayDifference;
						const {startDifference, endDifference} = moveItem(item, movedDays, hideWeekend);
						item.shift(movedDays, startDifference, endDifference);
						if (item.onMoving) {
							item.onMoving(
								item,
								this.dragData.groupData.group,
								startDifference,
								endDifference,
								this.dragData,
								movedDays
							);
						}
						this.dragData.dayDifference = dayDifference;

						//Skip redrawing post processing canvas to avoid having the row hover effect flicker
						if (!this.mouseMoveRedrawFrame) {
							this.mouseMoveRedrawFrame = requestAnimationFrame(() => {
								this.redraw({skipPostProcessing: true, skipBackground: true, preventFiltering: false});
								this.mouseMoveRedrawFrame = null;
							});
						}
					}
				} else if (!item.isDisabled() && item.draggableType === DRAGGABLE_TYPE.DATE_AND_GROUP) {
					addOriginalCopyToItem(item);

					if (!this.dragData.hasItemDislodged) {
						// initialize item ghosting
						if (this.dragData.itemData.item.canGhost && this.dragData.itemData.item.startGhost) {
							this.dragData.itemData.item.startGhost(this.dragData.itemData.item, this.props.items);
							this.dragData.itemData.item.isBeingGhosted = true;
						}
						const cacheCanvas = createCacheCanvas(
							this.dragData.itemData.width,
							this.dragData.initialGroupData.height
						);
						const canvasContext = cacheCanvas.getContext('2d');
						this.dragData.itemData.item.isBeingDragged = true;
						this.dragData.itemData.item.draw(canvasContext, 0, 0, itemData.width);
						this.dragData.itemData.item.hide();
						this.dragData.initialGroup.minimumItemRowCount = this.dragData.initialGroup.itemRowCount;
						this.dragData.cacheCanvas = cacheCanvas;
						this.dragData.hasItemDislodged = true;
						this.dragData.innermostVisibleParentGroup = this.dragData.initialGroup;
						if (this.dragData.itemData.item.onMoveStart) {
							this.dragData.itemData.item.onMoveStart(
								this.dragData.itemData.item,
								[ITEM_DRAG_POINT.LEFT, ITEM_DRAG_POINT.RIGHT].includes(this.dragData.dragPoint),
								this.dragData
							);
						}
						this.redraw({skipPostProcessing: true, skipBackground: true, preventFiltering: false});
					}
					let previousGroupId = this.dragData.groupData ? this.dragData.groupData.group.id : null;
					//Dragging existing item
					if (this.mouseTargetData.groupData) {
						this.dragData.groupData = this.mouseTargetData.groupData;
						this.dragData.previousGroupData = this.previousMouseTargetData.groupData;
						this.dragData.previousItemData = this.previousMouseTargetData.itemData;

						if (this.dragData.itemData.item.updateFreeDragData && this.dragData.mouseDown) {
							this.dragData.itemData.item.updateFreeDragData(this.dragData);
						} else {
							// eslint-disable-next-line no-console
							console.warn('updateFreeDragData is undefined on an item that can be freely dragged');
						}
					}
					const movedDays = dayDifference - this.dragData.dayDifference;
					const {startDifference, endDifference} = moveItem(item, movedDays, hideWeekend);
					const hasGroupChanged = this.dragData.groupData && this.dragData.groupData.group.id !== previousGroupId;
					if (hasDateChanged) {
						item.shift(movedDays, startDifference, endDifference);
						this.dragData.dayDifference = dayDifference;
					}
					if (item.onMoving && (hasDateChanged || hasGroupChanged) && this.dragData.groupData) {
						item.onMoving(
							item,
							this.dragData.groupData.group,
							startDifference,
							endDifference,
							this.dragData,
							movedDays
						);
					}
					if (hasDateChanged || hasGroupChanged) {
						if (!this.mouseMoveRedrawFrame) {
							this.mouseMoveRedrawFrame = requestAnimationFrame(() => {
								this.redraw({skipPostProcessing: true, skipBackground: true});
								this.mouseMoveRedrawFrame = null;
							});
						}
					}
				}
			} else {
				//Creating new item
				if (hasDateChanged) {
					this.dragData.dayDifference = dayDifference;
					this.dragData.newItemWidth = (dayDifference >= 0 ? dayDifference : -dayDifference) * this.pixelsPerDay;
					this.dragData.newItemX =
						GROUP_SECTION_WIDTH +
						(this.dragData.startDate + (dayDifference >= 0 ? 0 : 1) - this.startDate) * this.pixelsPerDay -
						(dayDifference >= 0 ? 0 : this.dragData.newItemWidth);
				}
			}
			this.dragData.hasMouseMoved = true;

			const heatmapEyeOption = this.props.eyeOptions.find(option => option.name === EYE_OPTION_NAME.SHOW_HEATMAP);
			const showHeatmap = heatmapEyeOption?.checked;

			this.postProcessingRenderer.processDragData(
				this.dragData,
				collapsableSectionHeight,
				{
					startDate: this.startDate,
					endDate: this.getEndDate(),
					canvasWidth,
				},
				showHeatmap
			);
		}
	}

	setCursorStyle() {
		const {cursorStyle, isSectionSplitterBeingDragged, canvasOffsetLeft} = this.state;
		let newCursorStyle = this.mouseTargetData.cursorData ? this.mouseTargetData.cursorData.cursorStyle : undefined;
		if (
			!newCursorStyle &&
			this.mouseTargetData.groupData &&
			this.mouseTargetData.groupData.group &&
			this.mouseTargetData.groupData.group.onItemCreate &&
			this.mouseTargetData.groupData.group.data?.hasItemCreate &&
			canvasOffsetLeft + GROUP_SECTION_WIDTH < this.lastKnownMouseX
		) {
			newCursorStyle = this.props.simulationMode ? CURSOR.DEFAULT : CURSOR.CREATE;
		}
		if (isSectionSplitterBeingDragged) {
			newCursorStyle = CURSOR.RESIZE;
		}
		if (newCursorStyle !== cursorStyle) {
			this.setState({cursorStyle: newCursorStyle});
		}
	}

	performEdgeScrollingCheck(mainSectionHeight, collapsableSectionHeight) {
		const mouseX = this.lastKnownMouseX;
		const mouseY = this.lastKnownMouseY;
		const {
			canvasOffsetLeft,
			canvasOffsetTop,
			canvasWidth,
			canvasHeight,
			mainSectionScrollTop,
			collapsableSectionScrollTop,
			mainSectionContentHeight,
			collapsableSectionContentHeight,
		} = this.state;
		const xScrollVal = EDGE_SCROLLING_SPEED / canvasWidth; // This makes it scroll by x pixels per step
		const {top: timelineClientY} = this.canvasContainer.getBoundingClientRect();
		const isMouseOverCollapsableSection =
			collapsableSectionHeight && mouseY - timelineClientY - DATE_STEPS_SECTION_HEIGHT < collapsableSectionHeight;
		const performScroll = (value, isVertical) => {
			if (this.initialEdgeScrollingTimeout || this.edgeScrollingFrame) return;
			this.initialEdgeScrollingTimeout = setTimeout(() => {
				cancelAnimationFrame(this.edgeScrollingFrame);
				this.performEdgeScrolling(value, isVertical, isMouseOverCollapsableSection);
				this.initialEdgeScrollingTimeout = null;
			}, 200);
		};
		if (mouseX - canvasOffsetLeft - GROUP_SECTION_WIDTH < EDGE_SCROLLING_THRESHOLD) {
			performScroll(-xScrollVal, false);
		} else if (canvasOffsetLeft + canvasWidth + GROUP_SECTION_WIDTH - mouseX < EDGE_SCROLLING_THRESHOLD) {
			performScroll(xScrollVal, false);
		} else if (
			isMouseOverCollapsableSection &&
			mouseY - canvasOffsetTop - DATE_STEPS_SECTION_HEIGHT < EDGE_SCROLLING_THRESHOLD &&
			collapsableSectionScrollTop !== 0
		) {
			performScroll(-EDGE_SCROLLING_SPEED, true, true);
		} else if (
			!isMouseOverCollapsableSection &&
			mouseY - canvasOffsetTop - collapsableSectionHeight - DATE_STEPS_SECTION_HEIGHT < EDGE_SCROLLING_THRESHOLD &&
			mainSectionScrollTop !== 0
		) {
			performScroll(-EDGE_SCROLLING_SPEED, true, false);
		} else if (
			isMouseOverCollapsableSection &&
			mouseY - canvasOffsetTop - collapsableSectionHeight + SECTION_SPLITTER_HEIGHT > EDGE_SCROLLING_THRESHOLD &&
			collapsableSectionScrollTop < collapsableSectionContentHeight - collapsableSectionHeight
		) {
			performScroll(EDGE_SCROLLING_SPEED, true, true);
		} else if (
			!isMouseOverCollapsableSection &&
			mouseY - canvasOffsetTop - canvasHeight > EDGE_SCROLLING_THRESHOLD &&
			mainSectionScrollTop < mainSectionContentHeight - mainSectionHeight
		) {
			performScroll(EDGE_SCROLLING_SPEED, true, false);
		} else {
			cancelAnimationFrame(this.edgeScrollingFrame);
			this.edgeScrollingFrame = null;
			clearTimeout(this.initialEdgeScrollingTimeout);
			this.initialEdgeScrollingTimeout = null;
		}
	}

	performEdgeScrolling(value, isVertical, isCollapsableSection) {
		if (!this.dragData) {
			cancelAnimationFrame(this.edgeScrollingFrame);
			this.edgeScrollingFrame = null;
			return;
		}
		if (!isVertical) {
			this.scroll(value);
		} else {
			this.scrollVertically(value, isCollapsableSection);
		}
		this.edgeScrollingFrame = requestAnimationFrame(() => {
			this.performEdgeScrolling(value, isVertical, isCollapsableSection);
		});
	}

	// returns the amount of parentGroups that the group has
	getGroupLevel(group, level = 0) {
		if (group.parentGroup) {
			return this.getGroupLevel(group.parentGroup, level + 1);
		}

		return level;
	}

	onMouseUp(e) {
		if (this.dragData) {
			const {
				cacheCanvas,
				itemData,
				initialX,
				initialY,
				currentX,
				currentY,
				initialGroup,
				groupData,
				hasItemDislodged,
				isCreatingDependency,
				isDependencyCreationSnapped,
				isFinishToStartDependency,
				dependencyTargetTask,
			} = this.dragData;

			this.dragData.mouseDown = false;
			let startDate = this.dragData.startDate;
			let dayDifference = this.dragData.dayDifference;
			if (this.isHideWeekendsSelected() && startDate && dayDifference) {
				let steps = 1;
				while (Math.floor(this.startDate) + steps <= startDate) {
					if (this.dayData[Math.floor(this.startDate) + steps].isoWeekday > 5) {
						startDate += 1;
					}
					steps += 1;
				}

				if (dayDifference > 0) {
					steps = 1;
					while (startDate + steps < startDate + dayDifference) {
						if (this.dayData[startDate + steps].isoWeekday > 5) {
							dayDifference += 1;
						}
						steps += 1;
					}
				} else if (dayDifference < 0) {
					steps = -1;
					while (startDate + steps > startDate + dayDifference) {
						if (this.dayData[startDate + steps].isoWeekday > 5) {
							dayDifference -= 1;
						}
						steps -= 1;
					}
				}
			}
			const {mainSectionScrollTop, collapsableSectionScrollTop} = this.state;
			const mainSectionHeight = this.getMainSectionHeight();
			const collapsableSectionHeight = this.getCollapsableSectionHeight();
			if (itemData) {
				const {item} = itemData;

				// create dependency when connecting 2 tasks using the link
				if (isCreatingDependency) {
					if (isDependencyCreationSnapped) {
						this.props.onDependencyCreation &&
							this.props.onDependencyCreation(
								item.data.task.id,
								dependencyTargetTask.id,
								isFinishToStartDependency
							);
						requestAnimationFrame(() => {
							this.dragData = null; //Putting this in timeout to prevent onCanvasClick from triggering
							this.redraw({preventFiltering: false});
						});
					} else {
						requestAnimationFrame(() => {
							this.dragData = null; //Putting this in timeout to prevent onCanvasClick from triggering
							this.redraw({preventFiltering: false});
						});
					}
				} else {
					if (itemData.item.draggableType === DRAGGABLE_TYPE.DATE_ONLY || !hasItemDislodged) {
						if (item.onMoveEnd && dayDifference) {
							item.onMoveEnd(item, groupData.group, initialGroup, this.dragData);
						} else {
							DataManager.undoCurrentTemporaryMoves(this.props.pageComponent);
						}
						requestAnimationFrame(() => {
							this.dragData = null; //Putting this in timeout to prevent onCanvasClick from triggering
							this.redraw({preventFiltering: false});
						});
					} else if (itemData.item.draggableType === DRAGGABLE_TYPE.DATE_AND_GROUP) {
						if (item.onMoveEnd) {
							item.onMoveEnd(item, this.dragData.destinationGroup, initialGroup, this.dragData);
							if (item.canGhost && item.endGhost) {
								item.endGhost(item, this.props.items);
							}
							if (item.isBeingGhosted) {
								item.isBeingGhosted = false;
							}
						}
						const {innermostVisibleParentGroup} = this.dragData;
						item.resetItemRow();
						if (this.dragData.hasMouseMoved) {
							initialGroup.minimumItemRowCount = undefined;
							requestAnimationFrame(() => {
								this.dragData = null; //Putting this in timeout to prevent onCanvasClick from triggering
								this.redraw({skipPostProcessing: true, preventFiltering: false});

								// If the actual group the item will land in is visible, use item data since it will be correct, otherwise use innermost visible parent data
								const targetGroup = innermostVisibleParentGroup || initialGroup;
								const isTargetGroupVisible = targetGroup.groupIds
									? targetGroup.groupIds.includes(item.groupId)
									: targetGroup.id === item.groupId;
								const scrollTop = targetGroup.isInCollapsableSection
									? collapsableSectionScrollTop
									: mainSectionScrollTop;
								let y = isTargetGroupVisible
									? targetGroup.y + item.itemRow * targetGroup.itemRowHeight - scrollTop
									: targetGroup.screenY + (targetGroup.height - item.height) / 2;

								// If the group is out of the screen, animate item to out of screen position since item.y will not be correct because the item is not drawn
								if (isTargetGroupVisible && y + targetGroup.itemRowHeight < 0) {
									y = -100;
								} else if (
									isTargetGroupVisible &&
									y >
										(targetGroup.isInCollapsableSection
											? collapsableSectionHeight
											: collapsableSectionHeight + mainSectionHeight)
								) {
									y =
										(targetGroup.isInCollapsableSection
											? collapsableSectionHeight
											: collapsableSectionHeight + mainSectionHeight) + 100;
								}
								let dayDifference = item.startDate - this.getStartDate();
								if (this.isHideWeekendsSelected()) {
									const weekendDays = getWeekendDaysBetween(
										this.getStartDate(),
										item.startDate,
										this.dayData
									);
									dayDifference =
										dayDifference > 0 ? dayDifference - weekendDays : dayDifference + weekendDays;
								}
								const x = GROUP_SECTION_WIDTH + dayDifference * this.pixelsPerDay;
								this.postProcessingRenderer.animateItemPositionChange(
									item,
									cacheCanvas,
									itemData.x + (currentX - initialX),
									itemData.y + (currentY - initialY),
									x + (item.draggableArea ? item.width * item.draggableArea.start : 0),
									y,
									targetGroup,
									this.getCollapsableSectionHeight(),
									() => {
										item.isBeingDragged = false;
										item.show();
										if (item.onMoveAnimationEnd) {
											item.onMoveAnimationEnd(item);
										}
										this.redraw({preventFiltering: false});
										this.onMouseMove(e);
									}
								);
							});
						} else {
							requestAnimationFrame(() => {
								this.dragData = null;
								item.isBeingDragged = false;
								item.show();
								this.redraw({preventFiltering: false});
								this.onMouseMove(e);
							});
						}
					}

					if (item.canGhost) {
						item.originalItem = undefined;
					}
				}
			} else {
				//Should be here only if user is creating a new item
				//Clear item creation object from post processing canvas
				const {group} = groupData;
				if (group.onItemCreate && dayDifference) {
					//Reducing dayDifference by one since if for example the day difference is 1, we want the start and end date to be the same day instead of end date being 1 day later
					const adjustedDayDifference = dayDifference > 0 ? dayDifference - 1 : dayDifference + 1;
					const itemStartDate = dayDifference > 0 ? startDate : startDate + adjustedDayDifference;
					group.onItemCreate(
						itemStartDate,
						itemStartDate + (adjustedDayDifference < 0 ? -adjustedDayDifference : adjustedDayDifference),
						group
					);
				}
				requestAnimationFrame(() => {
					this.dragData = null; //Putting this in timeout to prevent onCanvasClick from triggering
					this.postProcessingRenderer.clear();
					this.postProcessingRenderer.processMouseMoveData(
						this.mouseTargetData,
						null,
						this.getCollapsableSectionHeight()
					);
				});
			}
		} else {
			if (this.props.onMouseUpEnd) {
				this.props.onMouseUpEnd(this.mouseTargetData);
			}
		}
	}

	isMouseWithinButton(groupData, event) {
		const {group} = groupData;
		const {clientX, clientY} = event;
		const {buttonHeight, buttonWidth, buttonX, buttonY} = group;
		const canvasOffset = this.getCanvasOffset();
		const mouseY = clientY - canvasOffset.top;
		const mouseX = clientX - canvasOffset.left;

		const isWithinHorizontally = mouseX >= buttonX && mouseX <= buttonX + buttonWidth;
		const isWithinVertically = mouseY >= buttonY && mouseY <= buttonY + buttonHeight;

		return isWithinHorizontally && isWithinVertically;
	}

	onCanvasClick(e) {
		//Don't trigger clicks on mouse up after dragging
		if (this.dragData) {
			if (this.dragData.hasMouseMoved) {
				return;
			}
			this.dragData = null;
		}
		const {canvasOffsetTop, canvasOffsetLeft} = this.state;
		const {onGroupExpansionToggle} = this.props;
		const {itemData, groupData} = interactionManager.getDataAtPosition(
			e.clientX - canvasOffsetLeft,
			e.clientY - canvasOffsetTop - DATE_STEPS_SECTION_HEIGHT
		);
		const isGroupSectionClick = e.clientX < canvasOffsetLeft + GROUP_SECTION_WIDTH;
		const collapsableSectionHeight = this.getCollapsableSectionHeight();

		if (isGroupSectionClick) {
			if (groupData) {
				const {group} = groupData;

				// calculate
				const groupLevel = this.getGroupLevel(group);
				const expandArea = GROUP_SECTION_SPACING_LEVEL_ONE + GROUP_SECTION_MARGIN_LEFT * groupLevel;
				const isGroupExpandClick = e.clientX <= expandArea + canvasOffsetLeft;

				//The fake collapsable section group will not have an id
				if (
					!group.id ||
					(group.isInCollapsableSection && group.screenY > collapsableSectionHeight) ||
					(!group.isInCollapsableSection && group.screenY + group.height < collapsableSectionHeight)
				)
					return;

				if (group.onButtonClick && this.isMouseWithinButton(groupData, e)) {
					if (!group.isButtonDisabled()) {
						group.onButtonClick(e, groupData);
					}
				} else {
					if (group.onClick && !isGroupExpandClick) {
						group.onClick(e, groupData);
					} else {
						if (!this.props.isProjectTimeline || group.parentGroup) {
							group.toggleExpansion();
						}
						onGroupExpansionToggle && onGroupExpansionToggle(group, false);
					}

					this.redraw({skipBackground: true, preventFiltering: false});
				}
			}
		} else {
			if (itemData && itemData.item.onClick && itemData.item.isOnClickEnabled()) {
				itemData.item.onClick(itemData.item, e);
			}
		}
	}

	scrollClickScroll() {
		const {scrollClickDeltaX, scrollClickDeltaY, isScrollClickIconVisible, isScrollClickOverCollapsableSection} =
			this.state;
		if (!isScrollClickIconVisible) return;
		const absoluteDeltaX = scrollClickDeltaX < 0 ? -scrollClickDeltaX : scrollClickDeltaX;
		const absoluteDeltaY = scrollClickDeltaY < 0 ? -scrollClickDeltaY : scrollClickDeltaY;
		if (absoluteDeltaX > 20 || absoluteDeltaY > 20) {
			if (absoluteDeltaX > absoluteDeltaY) {
				this.scroll((scrollClickDeltaX < 0 ? -0.4 : 0.4) * (1 - Math.exp(-0.0001 * absoluteDeltaX)), {
					applyEasing: false,
				});
			} else {
				this.scrollVertically(
					(scrollClickDeltaY < 0 ? -400 : 400) * (1 - Math.exp(-0.0001 * absoluteDeltaY)),
					isScrollClickOverCollapsableSection
				);
			}
		}
		requestAnimationFrame(this.scrollClickScroll.bind(this));
	}

	// updates the ration between the collapsable section and the main section and saves it in the localStorage
	onSectionSplitterPositionChange(clientY) {
		const {top} = this.canvasContainer.getBoundingClientRect();
		const {canvasWidth, canvasHeight, collapsableSectionHeightPercentage} = this.state;
		let barPositionPercentage = ((clientY - top - DATE_STEPS_SECTION_HEIGHT) / canvasHeight) * 100;

		const minBarPositionPercentage = 20;
		const maxBarPositionPercentage = 80;

		// if the new position of the bar is below or above the limits, it will take the value of the closest limit
		if (barPositionPercentage < minBarPositionPercentage) {
			barPositionPercentage = minBarPositionPercentage;
		} else if (barPositionPercentage > maxBarPositionPercentage) {
			barPositionPercentage = maxBarPositionPercentage;
		}

		// if the value in the state is different from the new value, update the state and save in the localStorage
		if (barPositionPercentage !== collapsableSectionHeightPercentage) {
			this.setState({collapsableSectionHeightPercentage: barPositionPercentage}, () => {
				const {devicePixelRatio} = window;
				this.collapsableMainSectionOffscreenCanvas.width = canvasWidth * devicePixelRatio;
				this.collapsableMainSectionOffscreenCanvas.height = Math.max(
					(Math.floor((canvasHeight * barPositionPercentage) / 100) - SECTION_SPLITTER_HEIGHT) * devicePixelRatio,
					1
				);
				this.collapsableMainSectionOffscreenCanvas
					.getContext('2d')
					.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);
				this.collapsableGroupSectionOffscreenCanvas.width = GROUP_SECTION_WIDTH * devicePixelRatio;
				this.collapsableGroupSectionOffscreenCanvas.height = Math.max(
					(Math.floor((canvasHeight * barPositionPercentage) / 100) - SECTION_SPLITTER_HEIGHT) * devicePixelRatio,
					1
				);
				this.collapsableGroupSectionOffscreenCanvas
					.getContext('2d')
					.setTransform(devicePixelRatio, 0, 0, devicePixelRatio, 0, 0);

				this.redraw({preventFiltering: false, preventHeatmapCalculation: true});
				Util.localStorageSetItem('canvas-scheduling-collapsable-section-height-percentage', barPositionPercentage);
			});
		}
	}

	onForegroundContextMenu(e) {
		if (!this.props.onForegroundContextMenu) return;

		let splitDate = this.getStartDateFromMouseX(e.clientX);

		// here we are adding the weekends that are not visible in the canvas so that the splitDate we sent to the this.props.onForegroundContextMenu is accurate
		if (this.isHideWeekendsSelected()) {
			// some hack to make the split date accurate. We should get rid of code like this and update the getStartDateFromMouseX to support hidden weekends
			let steps = 1;
			while (Math.floor(this.startDate) + steps <= splitDate) {
				if (this.dayData[Math.floor(this.startDate) + steps].isoWeekday > 5) {
					splitDate += 1;
				}
				steps += 1;
			}
		}

		this.props.onForegroundContextMenu(e, this.mouseTargetData, splitDate);
	}

	isHideWeekendsSelected() {
		const isEyeOptionChecked = () => {
			return this.hideWeekendEyeOption && !this.hideWeekendEyeOption.checked;
		};

		if (this.state.weekendOptions.weekendDisplayShowAlways !== null) {
			return !this.state.weekendOptions.weekendDisplayShowAlways;
		} else {
			return isEyeOptionChecked();
		}
	}

	render() {
		const {
			canvasHeight,
			canvasWidth,
			zoomLevel,
			isScrollClickIconVisible,
			scrollClickIconPositionLeft,
			scrollClickIconPositionTop,
			mainSectionContentHeight,
			collapsableSectionContentHeight,
			mainSectionScrollTop,
			collapsableSectionScrollTop,
			cursorStyle,
			isSectionSplitterBeingDragged,
			expandedGroupCount,
		} = this.state;
		const {startDate} = this;
		const {topLeftComponent, groups, isSingleGroup, onGroupExpansionToggle} = this.props;
		const {devicePixelRatio} = window;
		return (
			<div
				className="canvas-timeline-component"
				data-cy={'scheduling-page'}
				ref={e => (this.canvasTimelineComponentRef = e)}
			>
				<TimelineLoading
					id="timeline-loading-overlay"
					shouldShow={this.state.showLoader}
					style={{
						height: canvasHeight,
						width: '100%',
						top: DATE_STEPS_SECTION_HEIGHT,
						left: 0,
					}}
				/>
				<ExpandAllButton
					groups={groups}
					expandedGroupCount={expandedGroupCount}
					redrawTimeline={this.redraw.bind(this)}
					isSingleGroup={isSingleGroup}
					onGroupExpansionToggle={onGroupExpansionToggle}
					doFetchFullData={this.props.doFetchFullData}
					isUsingNewLazyLoad={this.props.isUsingNewLazyLoad}
					isProjectTimeline={this.props.isProjectTimeline}
				/>
				<SectionSplitter
					onSplitterMouseDown={() => this.setState({isSectionSplitterBeingDragged: true})}
					onSplitterMouseUp={() => this.setState({isSectionSplitterBeingDragged: false})}
					isBeingDragged={isSectionSplitterBeingDragged}
					onPositionChange={this.onSectionSplitterPositionChange.bind(this)}
					collapsableSectionHeight={this.getCollapsableSectionHeight() + DATE_STEPS_SECTION_HEIGHT}
					isCollapsableSectionExpanded={this.props.isCollapsableSectionExpanded}
				/>
				<div className="group-section">
					<div className="top-left-container">{topLeftComponent || null}</div>
					<canvas
						id="group-section-canvas"
						style={{cursor: cursorStyle, width: GROUP_SECTION_WIDTH, height: canvasHeight}}
						height={canvasHeight * devicePixelRatio}
						width={GROUP_SECTION_WIDTH * devicePixelRatio}
						onClick={this.onCanvasClick.bind(this)}
						data-cy={'group-section-canvas'}
					/>
				</div>
				<div className="main-section">
					<ZoomMenu setZoomLevel={this.setZoomLevel.bind(this)} zoomLevel={zoomLevel} />
					<div
						className="canvas-section"
						style={{cursor: cursorStyle}}
						ref={e => (this.canvasContainer = e)}
						onMouseDown={this.onMainSectionMouseDown.bind(this)}
					>
						<canvas
							id="foreground-canvas"
							height={canvasHeight * devicePixelRatio}
							width={canvasWidth * devicePixelRatio}
							onClick={this.onCanvasClick.bind(this)}
							style={{marginTop: DATE_STEPS_SECTION_HEIGHT, width: canvasWidth, height: canvasHeight}}
							onContextMenu={this.onForegroundContextMenu.bind(this)}
							data-userpilot={!this.props.isProjectTimeline ? 'schedule-heatmap' : null}
							data-cy={'foreground-canvas'}
						/>
						<canvas
							id="background-canvas"
							width={canvasWidth * devicePixelRatio}
							height={(canvasHeight + DATE_STEPS_SECTION_HEIGHT) * devicePixelRatio}
							style={{width: canvasWidth, height: canvasHeight + DATE_STEPS_SECTION_HEIGHT}}
							data-cy={'background-canvas'}
						/>
					</div>
					<HorizontalScrollbar
						scrollbarSensitivity={HORIZONTAL_SCROLLBAR_SENSITIVITY}
						scrollbarThumbWidth={HORIZONTAL_SCROLLBAR_THUMB_WIDTH}
						scroll={this.scroll.bind(this)}
						canvasWidth={canvasWidth}
						timelineStartDate={startDate}
						onScrollEnd={() => {
							if (this.props.onScrollEnd)
								this.props.onScrollEnd(
									this.visibleMainSectionGroupMap,
									this.minorStepDataArray,
									this.minorStep,
									this.startDate
								);
						}}
					/>
				</div>
				<canvas
					id="post-processing-canvas"
					width={(canvasWidth + GROUP_SECTION_WIDTH) * devicePixelRatio}
					height={canvasHeight * devicePixelRatio}
					style={{
						top: DATE_STEPS_SECTION_HEIGHT,
						width: canvasWidth + GROUP_SECTION_WIDTH,
						height: canvasHeight,
					}}
					data-cy={'post-processing-canvas'}
				/>
				<VerticalScrollbar
					scroll={this.scrollVertically.bind(this)}
					onScrollEnd={() => {
						if (this.props.onScrollEnd)
							this.props.onScrollEnd(
								this.visibleMainSectionGroupMap,
								this.minorStepDataArray,
								this.minorStep,
								this.startDate
							);
					}}
					mainSectionHeight={this.getMainSectionHeight()}
					collapsableSectionHeight={this.getCollapsableSectionHeight()}
					mainSectionContentHeight={mainSectionContentHeight}
					collapsableSectionContentHeight={collapsableSectionContentHeight}
					mainSectionScrollTop={mainSectionScrollTop}
					collapsableSectionScrollTop={collapsableSectionScrollTop}
					dateStepsSectionHeight={DATE_STEPS_SECTION_HEIGHT}
				/>
				{isScrollClickIconVisible ? (
					<div
						className="scroll-click-icon-container"
						style={{left: scrollClickIconPositionLeft, top: scrollClickIconPositionTop}}
					>
						<div className="scroll-click-icon" />
					</div>
				) : null}
			</div>
		);
	}
}

CanvasTimeline.propTypes = {
	topLeftComponent: PropTypes.element,
	isCollapsableSectionExpanded: PropTypes.bool,
	groups: PropTypes.array.isRequired,
	collapsableSectionGroups: PropTypes.array,
	onRangeChange: PropTypes.func,
	onBeforeHorizontalScroll: PropTypes.func,
	initialZoomLevel: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]),
	initialStartDate: PropTypes.number,
	initialEndDate: PropTypes.number,
	weekendOptions: PropTypes.object,
};

export default injectIntl(CanvasTimeline, {forwardRef: true});
