import Util from '../../../../forecast-app/shared/util/util';
import {EXPENSE_GROUPINGS, TIME_REG_GROUPINGS} from './invoicing_generate_lines';

const getEnumValue = (localStorageKey, possibleValues) => {
	const value = Util.localStorageGetItem(localStorageKey);
	if (value != null && possibleValues[value]) {
		return value;
	}
	return null;
};

export const getDefaultConfig = () => {
	const timeRegGrouping = getEnumValue('invoicing_time_reg_grouping', TIME_REG_GROUPINGS) || TIME_REG_GROUPINGS.NO_GROUPING;
	const includeExpenses =
		Util.localStorageGetItem('invoicing_include_expenses') !== null
			? Util.localStorageGetItem('invoicing_include_expenses') === 'true'
			: true;
	const expenseGrouping = getEnumValue('invoicing_expense_grouping', EXPENSE_GROUPINGS) || EXPENSE_GROUPINGS.NO_GROUPING;

	return {
		timeRegGrouping,
		includeExpenses,
		expenseGrouping,
		selectedProjectsIds: [],
		selectedProjectClientIds: [],
	};
};

export const saveConfig = config => {
	Util.localStorageSetItem('invoicing_time_reg_grouping', config.timeRegGrouping);
	Util.localStorageSetItem('invoicing_include_expenses', config.includeExpenses);
	Util.localStorageSetItem('invoicing_expense_grouping', config.expenseGrouping);
};
