import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';

const TimeRevenueRecognitionTooltips = {
	recognitionPercentageAccumulated: [
		{
			description: (
				<FormattedHTMLMessage
					id={'project_budget.revenue_recognition.time_recognition_percentage_accumulated_description'}
				/>
			),
		},
		{
			details: [
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.accumulated_monthly_billable_tm_time'} />,
				'/',
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.total_billable_tm_time_completion'} />,
			],
		},
	],
	recognitionAmountAccumulated: [
		{
			description: (
				<FormattedHTMLMessage
					id={'project_budget.revenue_recognition.time_recognition_amount_accumulated_description'}
				/>
			),
		},
		{
			details: [
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.monthly_cumulative_percent_of_time'} />,
				'x',
				<FormattedHTMLMessage id={'project_budget.time_budget'} />,
			],
		},
	],
	recognitionAmount: [
		{
			description: <FormattedHTMLMessage id={'project_budget.revenue_recognition.time_recognition_amount_description'} />,
		},
		{
			details: [
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.current_month_cumulative_spend_time'} />,
				'-',
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.previous_month_cumulative_spend_time'} />,
			],
		},
	],
	recognitionPercentage: [
		{
			description: (
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.time_recognition_percent_description'} />
			),
		},
		{
			details: [
				<FormattedHTMLMessage id={'project_budget.recognition_amount.recognition_time_table'} />,
				'/',
				<FormattedHTMLMessage id={'project_budget.time_budget'} />,
			],
		},
	],
	//recognitionProfit: [],
	//recognitionProfitPercentage: [],
	billableTimeAccumulated: [
		{
			description: (
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.cumulative_billable_time_description'} />
			),
		},
	],
	//billableTime: [],
	surplus: [
		{
			description: (
				<FormattedHTMLMessage
					id={'project_budget.revenue_recognition.variance_billable_tm_vs_revenue_recognition_description'}
				/>
			),
		},
		{
			details: [
				<FormattedHTMLMessage id={'project_budget.recognition_amount.recognition_time_table'} />,
				'-',
				<FormattedHTMLMessage id={'project_budget.billable_time_spent'} />,
			],
		},
	],
	surplusPercentage: [
		{
			description: <FormattedHTMLMessage id={'project_budget.revenue_recognition.percent_adjustment'} />,
		},
		{
			details: [
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.variance_billable_tm_vs_revenue_recognition'} />,
				'/',
				<FormattedHTMLMessage id={'project_budget.billable_time_spent'} />,
			],
		},
	],
	//estimatedCostPercentageAccumulated: [],
	//estimatedCostAccumulated: [],
	//estimatedCost: [],
	//estimatedCostPercentage: [],
};

const ExpenseRevenueRecognitionTooltips = {
	recognitionPercentageAccumulated: [
		{
			description: (
				<FormattedHTMLMessage
					id={'project_budget.revenue_recognition.expense_recognition_percentage_accumulated_description'}
				/>
			),
		},
		{
			details: [
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.monthly_cumulative_expenses'} />,
				'/',
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.total_billable_expenses'} />,
			],
		},
	],
	recognitionAmountAccumulated: [
		{
			description: (
				<FormattedHTMLMessage
					id={'project_budget.revenue_recognition.time_recognition_amount_accumulated_description'}
				/>
			),
		},
		{
			details: [
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.monthly_cumulative_percent_of_expenses'} />,
				'x',
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.total_billable_expenses'} />,
			],
		},
	],
	recognitionAmount: [
		{
			description: (
				<FormattedHTMLMessage id={'project_budget.revenue_recognition.expense_recognition_amount_description'} />
			),
		},
	],
	//expenseRevenuePercentage: [],
	//recognitionProfit: [],
	//recognitionProfitPercentage: [],
	//estimatedCostPercentageAccumulated: [],
	//estimatedCostAccumulated: [],
	//estimatedCost: [],
	//expenseCostPercentage: [],
};
const TotalRevenueRecognitionTooltips = {};

export const RevenueRecognitionTooltips = {
	TimeRevenueRecognitionTooltips,
	ExpenseRevenueRecognitionTooltips,
	TotalRevenueRecognitionTooltips,
};
