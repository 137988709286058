import {FormattedMessage, injectIntl} from 'react-intl';
import GenericModal from '../../../../../containers/modal/generic_modal';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../../constants';
import React, {useState} from 'react';
import Styled from 'styled-components';
import moment from 'moment';
import {Dropdown} from 'web-components';
import Util from '../../../../../forecast-app/shared/util/util';
import CreateBulkFixedPriceLockMutation from '../../../../../mutations/create_bulk_fixed_price_lock_mutation';
import {calculateOptions, getNextAvailableLock} from '../util/PeriodLocks';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';

const ContentWrapper = Styled.div`
	display: flex;
	align-items: baseline;
	height: 100px;
`;

const DropdownLabel = Styled.div`
	font-size: 13px;
	margin-right: 16px;
	flex: 0 0 auto;
	white-space: nowrap;
`;

const DropdownWrapper = Styled.div`
	width: 150px;
`;

const formatDateISO = date => (date ? date.format('YYYY-MM-DD') : '');

const fixedPriceLockModal = ({closeModal, project, month, lockedAmounts, retry, intl: {formatMessage}}) => {
	const hasManualRecognition = hasFeatureFlag('manual_revenue_recognition');
	const nextAvailableLock = getNextAvailableLock(project);
	const options = calculateOptions(project, nextAvailableLock, true);
	const preSelectedMonth = options.find(option => moment(option.value).format('YYYY-MM') === month);

	const [selectedOption, setSelectedOption] = useState(
		hasManualRecognition
			? preSelectedMonth
				? preSelectedMonth.value
				: options[0].value
			: nextAvailableLock
			? formatDateISO(nextAvailableLock.clone())
			: null
	);
	const [saving, setSaving] = useState(false);

	const onSuccess = () => {
		// Reload data after bulk create
		if (retry) {
			retry();
		}
		closeModal();
	};

	const createFixedPriceLock = input => {
		Util.CommitMutation(CreateBulkFixedPriceLockMutation, input, onSuccess);
	};

	const onSave = () => {
		if (selectedOption && nextAvailableLock) {
			setSaving(true);
			const selectedPeriod = moment(selectedOption);
			const locks = [];

			if (hasManualRecognition) {
				const totalLockedAmount = lockedAmounts.total.find(
					lockedAmount => lockedAmount.startDate.format('YYYY-MM') === selectedPeriod.format('YYYY-MM')
				).amount;
				const timeLockedAmount = lockedAmounts.time.find(
					lockedAmount => lockedAmount.startDate.format('YYYY-MM') === selectedPeriod.format('YYYY-MM')
				).amount;
				locks.push({
					startDate: formatDateISO(selectedPeriod.clone().startOf('month')),
					endDate: formatDateISO(selectedPeriod.clone().endOf('month')),
					locked: true,
					amount: totalLockedAmount,
					timeAmount: timeLockedAmount,
				});
			} else {
				let currentPeriod = nextAvailableLock.clone();
				while (currentPeriod.isSameOrBefore(selectedPeriod)) {
					const totalLockedAmount = lockedAmounts.total.find(
						lockedAmount => lockedAmount.startDate.format('YYYY-MM') === selectedPeriod.format('YYYY-MM')
					).amount;
					const timeLockedAmount = lockedAmounts.time.find(
						lockedAmount => lockedAmount.startDate.format('YYYY-MM') === selectedPeriod.format('YYYY-MM')
					).amount;
					locks.push({
						startDate: formatDateISO(currentPeriod.clone().startOf('month')),
						endDate: formatDateISO(currentPeriod.clone().endOf('month')),
						locked: true,
						amount: totalLockedAmount,
						timeAmount: timeLockedAmount,
					});
					currentPeriod.add(1, 'month').startOf('month');
				}
			}
			createFixedPriceLock({projectId: project.id, locks: locks});
		}
	};

	const onPeriodSelect = value => {
		setSelectedOption(value);
	};

	const content = (
		<ContentWrapper>
			<DropdownLabel>
				<FormattedMessage
					id={
						hasManualRecognition
							? 'fixed_price_lock.revenue_recognition_lock_message'
							: 'fixed_price_lock.lock_message'
					}
				/>
			</DropdownLabel>
			<DropdownWrapper>
				<Dropdown
					usePortal={true}
					disabled={saving}
					name={''}
					selectedItems={[selectedOption]}
					onSelect={value => onPeriodSelect(value[0])}
				>
					{options.map(option => (
						<Dropdown.SingleText key={option.value} value={option.value} searchString={option.label}>
							{option.label}
						</Dropdown.SingleText>
					))}
				</Dropdown>
			</DropdownWrapper>
		</ContentWrapper>
	);

	return (
		<GenericModal
			className="fixed-price-lock-modal-budget"
			closeModal={closeModal}
			buttons={[
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					callback: closeModal,
				},
				{
					text: formatMessage({id: 'fixed_price_lock.lock_button'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.GREEN,
					preventDefaultClose: true,
					disabled: saving,
					callback: onSave,
				},
			]}
			headerText={formatMessage({id: 'fixed_price_lock.lock_modal_button'})}
			content={content}
		/>
	);
};

export default injectIntl(fixedPriceLockModal);
