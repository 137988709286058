import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {LockIcon, Table} from 'web-components';
import {LoadMore} from '../../../../../loaders/LoadMore';
import DetailsPhaseLoader, {detailsPhaseLoaderQuery} from '../../loaders/DetailsPhaseLoader';
import {
	getExpenseCategoryName,
	getPhaseName,
} from '../../../../../insights/project-portfolio-report/ProjectPortfolioReportUtils';
import {PHASE_BREAKDOWN_TYPE} from '../../../../../../constants';
import {useIntl} from 'react-intl';
import {DetailsHeaderColumn, DetailsTableWrapper, DetailsTitleWrapper, DetailsWrapper} from '../../DetailsTable.styled';
import {getTotalsParams} from '../../util/BudgetUtils';
import getAggregateRowTitle from '../../util/UseAggregateIntl';
import DetailsExpenseCategoryLoader, {detailsExpenseLoaderQuery} from '../../loaders/DetailsExpenseCategoryLoader';
import DetailsTableRow from '../work_and_expenses/DetailsTableRow';
import {getProjectDatesOrDefault} from '../../util/ChartsUtil';
import {hasFeatureFlag} from '../../../../../../forecast-app/shared/util/FeatureUtil';

const ExpenseDetailsTable = ({
	project,
	selectedAggregateLevel,
	aggregates,
	title,
	titleButtons,
	data,
	searchQuery,
	isColumnLocked = () => false,
	tooltips,
	cy,
}) => {
	const intl = useIntl();
	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');
	const filteredData = {...data, rows: data.rows.filter(row => aggregates.includes(row.aggregate))};
	const missingProjectDates = !project.projectFirstDateYear || !project.projectLastDateYear;
	const {
		projectFirstDateYear,
		projectFirstDateMonth,
		projectFirstDateDay,
		projectLastDateYear,
		projectLastDateMonth,
		projectLastDateDay,
	} = getProjectDatesOrDefault(project);
	return (
		<>
			<DetailsWrapper data-cy={cy}>
				<DetailsTitleWrapper>
					<h3>{title}</h3>
					{titleButtons && <div className="titleButtons">{titleButtons}</div>}
				</DetailsTitleWrapper>
				<DetailsTableWrapper>
					<Table paddedCells>
						<Table.Header>
							<Table.HeaderColumn visible flex={1} width="EXTRA_LARGE" />
							{filteredData.columns.map(column => (
								<Table.HeaderColumn
									key={title + column.name}
									usePadding
									align="right"
									visible={true}
									width="MEDIUM_SMALL"
								>
									<Table.HeaderColumn.Title>
										<DetailsHeaderColumn>
											{isColumnLocked(column.startDate) && (
												<div style={{marginRight: 10}}>
													<LockIcon color={'#C4C4CC'} size={LockIcon.SIZE.STANDARD} />
												</div>
											)}
											<div>{column.name}</div>
										</DetailsHeaderColumn>
									</Table.HeaderColumn.Title>
								</Table.HeaderColumn>
							))}
						</Table.Header>
						<Table.Rows data={filteredData} canExpand={!missingProjectDates}>
							{({rowData, tableColumnsProps, expanded, nextLevelProps: phaseLevelProps}) => {
								return (
									<>
										<DetailsTableRow
											data={data}
											rowData={rowData}
											tableColumnProps={tableColumnsProps}
											aggregateLevel={selectedAggregateLevel}
											isColumnLocked={isColumnLocked}
											headerColumn={
												<Table.Column width="LARGE">
													{getAggregateRowTitle(intl, rowData.aggregate)}
													{hasFinancialCategoriesUpdate
														? ' (' + intl.formatMessage({id: 'common.expenses'}) + ')'
														: undefined}
												</Table.Column>
											}
											tooltips={tooltips}
										/>
										{expanded && (
											<LoadMore
												key="query-render-details-phase"
												query={detailsPhaseLoaderQuery}
												loader={
													<DetailsPhaseLoader
														aggregateLevel={selectedAggregateLevel}
														aggregate={rowData.aggregate}
													/>
												}
												variables={{
													projectId: project.id,
													startYear: projectFirstDateYear,
													startMonth: projectFirstDateMonth,
													startDay: projectFirstDateDay,
													endYear: projectLastDateYear,
													endMonth: projectLastDateMonth,
													endDay: projectLastDateDay,
													aggregateLevel: selectedAggregateLevel,
													aggregate: rowData.aggregate,
													searchQuery: searchQuery,
													...getTotalsParams([rowData.aggregate]),
												}}
											>
												{({data: phaseBreakdownData}) => {
													return (
														<Table.Rows
															data={phaseBreakdownData}
															{...phaseLevelProps}
															canExpand={true}
														>
															{({
																rowData: phaseRowData,
																tableColumnsProps: phaseTableColumnProps,
																expanded: phaseExpanded,
																nextLevelProps: roleLevelProps,
															}) => {
																return (
																	<>
																		<DetailsTableRow
																			data={data}
																			rowData={phaseRowData}
																			tableColumnProps={phaseTableColumnProps}
																			headerColumn={getPhaseName(intl, phaseRowData)}
																			isColumnLocked={isColumnLocked}
																			canExpand={
																				phaseRowData.breakdownType ===
																					PHASE_BREAKDOWN_TYPE.PHASE ||
																				phaseRowData.breakdownType ===
																					PHASE_BREAKDOWN_TYPE.NO_PHASE
																			}
																		/>
																		{phaseExpanded && (
																			<LoadMore
																				key="query-render-details-role"
																				query={detailsExpenseLoaderQuery}
																				loader={
																					<DetailsExpenseCategoryLoader
																						aggregateLevel={selectedAggregateLevel}
																						aggregate={phaseRowData.aggregate}
																					/>
																				}
																				variables={{
																					projectId: project.id,
																					startYear: projectFirstDateYear,
																					startMonth: projectFirstDateMonth,
																					startDay: projectFirstDateDay,
																					endYear: projectLastDateYear,
																					endMonth: projectLastDateMonth,
																					endDay: projectLastDateDay,
																					phaseId:
																						phaseRowData.breakdownType ===
																						PHASE_BREAKDOWN_TYPE.PHASE
																							? phaseRowData.phase.id
																							: phaseRowData.breakdownType ===
																							  PHASE_BREAKDOWN_TYPE.NO_PHASE
																							? 0
																							: null,
																					aggregateLevel: selectedAggregateLevel,
																					aggregate: phaseRowData.aggregate,
																					searchQuery: searchQuery,
																					...getTotalsParams([rowData.aggregate]),
																				}}
																			>
																				{({data: roleBreakdownData}) => {
																					return (
																						<Table.Rows
																							data={roleBreakdownData}
																							{...roleLevelProps}
																							canExpand={false}
																						>
																							{({
																								rowData: roleRowData,
																								tableColumnsProps:
																									roleTableColumnProps,
																							}) => {
																								return (
																									<DetailsTableRow
																										data={data}
																										rowData={roleRowData}
																										tableColumnProps={
																											roleTableColumnProps
																										}
																										isColumnLocked={
																											isColumnLocked
																										}
																										headerColumn={getExpenseCategoryName(
																											intl,
																											roleRowData
																										)}
																									/>
																								);
																							}}
																						</Table.Rows>
																					);
																				}}
																			</LoadMore>
																		)}
																	</>
																);
															}}
														</Table.Rows>
													);
												}}
											</LoadMore>
										)}
									</>
								);
							}}
						</Table.Rows>
					</Table>
				</DetailsTableWrapper>
			</DetailsWrapper>
		</>
	);
};

export default createFragmentContainer(ExpenseDetailsTable, {
	project: graphql`
		fragment ExpenseDetailsTable_project on ProjectType {
			id
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
		}
	`,
});
