/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectContactDropdown_projects$ref: FragmentReference;
declare export opaque type ProjectContactDropdown_projects$fragmentType: ProjectContactDropdown_projects$ref;
export type ProjectContactDropdown_projects = $ReadOnlyArray<{|
  +node: ?{|
    +id: string,
    +projectPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +isContactPerson: ?boolean,
          +person: ?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +profilePictureId: ?string,
          |},
        |}
      |}>
    |},
  |},
  +$refType: ProjectContactDropdown_projects$ref,
|}>;
export type ProjectContactDropdown_projects$data = ProjectContactDropdown_projects;
export type ProjectContactDropdown_projects$key = $ReadOnlyArray<{
  +$data?: ProjectContactDropdown_projects$data,
  +$fragmentRefs: ProjectContactDropdown_projects$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ProjectContactDropdown_projects",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "contactsOnly",
              "value": true
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 1000000
            }
          ],
          "concreteType": "ProjectPersonTypeConnection",
          "kind": "LinkedField",
          "name": "projectPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectPersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectPerson",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "isContactPerson",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "firstName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lastName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "profilePictureId",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "projectPersons(contactsOnly:true,first:1000000)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectTypeEdge"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c65027460078341ee692c365a90937f5';

module.exports = node;
