/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Unit4LogPage_viewer$ref = any;
export type Unit4LogPageRefetchQueryVariables = {||};
export type Unit4LogPageRefetchQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +$fragmentRefs: Unit4LogPage_viewer$ref,
  |}
|};
export type Unit4LogPageRefetchQuery = {|
  variables: Unit4LogPageRefetchQueryVariables,
  response: Unit4LogPageRefetchQueryResponse,
|};
*/


/*
query Unit4LogPageRefetchQuery {
  viewer {
    id
    ...Unit4LogPage_viewer
  }
}

fragment Unit4LogPage_viewer on Viewer {
  unit4LogItems {
    id
    type
    unit4Identifier
    personId
    person
    projectId
    project
    workorder
    taskId
    task
    date
    minutesRegistered
    notes
    errorMessage
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Unit4LogPageRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Unit4LogPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Unit4LogPageRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Unit4LogItemType",
            "kind": "LinkedField",
            "name": "unit4LogItems",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unit4Identifier",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "personId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "person",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "project",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workorder",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taskId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "task",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minutesRegistered",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "errorMessage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "Unit4LogPageRefetchQuery",
    "operationKind": "query",
    "text": "query Unit4LogPageRefetchQuery {\n  viewer {\n    id\n    ...Unit4LogPage_viewer\n  }\n}\n\nfragment Unit4LogPage_viewer on Viewer {\n  unit4LogItems {\n    id\n    type\n    unit4Identifier\n    personId\n    person\n    projectId\n    project\n    workorder\n    taskId\n    task\n    date\n    minutesRegistered\n    notes\n    errorMessage\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1dbdaa5bf7836f27de47e7bd5a6ec2e0';

module.exports = node;
