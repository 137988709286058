import React, { useMemo, useState } from 'react';
import { Alert, FlexColumn, FlexRow, Modal, NumberField } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { createToast } from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import BulkUpdateExpenseItemsMutation from '../../../mutations/bulk_update_expense_items_mutation';
import Util from '../../../forecast-app/shared/util/util';
import { partitionArray } from '../../../forecast-app/shared/util/array-utils';
import { FieldLabel } from './ModalComponents';
const ChangeExpensesMarkupModal = ({ selectedRows, closeModal }) => {
    const { formatMessage } = useIntl();
    const initialMarkupValue = selectedRows.length === 1 && selectedRows[0].markup ? selectedRows[0].markup * 100 : 0;
    const [markup, setMarkup] = useState(initialMarkupValue);
    const [lockedRows, unlockedRows] = useMemo(() => partitionArray(selectedRows, item => item.locked), [selectedRows]);
    const unlockedAndBillableRows = useMemo(() => unlockedRows.filter(row => row.billable), unlockedRows);
    const numLockedExpenses = lockedRows.length;
    const numTotalExpenses = selectedRows.length;
    const onUpdate = () => {
        closeModal();
        if (unlockedAndBillableRows.length < 1)
            return;
        const onSuccess = () => {
            createToast({
                duration: 5000,
                message: formatMessage({
                    id: unlockedAndBillableRows.length > 1
                        ? 'expense_management.has_been_updated_bulk'
                        : 'expense_management.has_been_updated',
                }),
            });
        };
        Util.CommitMutation(BulkUpdateExpenseItemsMutation, {
            ids: unlockedAndBillableRows.map(({ id }) => id),
            markup: markup / 100,
        }, onSuccess);
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "expense_management.modal.adjust_markup" })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 'l' },
                React.createElement(FieldLabel, { helperTextId: "expense_management.modal.adjust_markup_only_billable", labelId: "expense_management.modal.markup" },
                    React.createElement(NumberField, { value: markup, onChange: setMarkup })),
                lockedRows.length ? (selectedRows.length > 1 ? (React.createElement(FlexRow, null,
                    React.createElement(Alert, { text: formatMessage({ id: 'expense_management.change_markup.locked_alert' }, { numLockedExpenses, numTotalExpenses }), type: Alert.TYPE.INFORMATION }))) : (React.createElement(FlexRow, null,
                    React.createElement(Alert, { text: formatMessage({ id: 'expense_management.modal.expense_locked_cannot_be_changed' }), type: Alert.TYPE.INFORMATION })))) : null)),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: onUpdate },
                React.createElement(FormattedMessage, { id: "expense_management.actions.confirm_changes" })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default ChangeExpensesMarkupModal;
