import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import ChartJsLine from '../../chartjs/chart_js_line';

class SprintBurndownDummy extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}
	render() {
		const {formatMessage} = this.props.intl;
		const data = {
			labels: [],
			datasets: [],
		};
		const dates = ['20180115', '20180415', '20180715', '20180915', '20181115', '20190215'];
		dates.forEach(dateString => {
			const momentDate = moment(dateString, 'YYYYMMDD');
			const date = momentDate.toDate();
			data.labels.push(date);
		});
		data.datasets.push({
			label: formatMessage({id: 'insights.component.burndown.legend_cards_count'}),
			borderColor: '#28AEFB',
			fill: false,
			stack: 'Stack 0',
			data: [210, 174, 110, 74, 41, 15],
		});
		data.datasets.push({
			label: formatMessage({id: 'insights.component.burnUp.ideal_velocity'}),
			borderColor: '#F6C4FC',
			fill: false,
			stack: 'Stack 1',
			lineTension: 0,
			spanGaps: true,
			data: [210, null, null, null, null, 0],
		});
		return (
			<ChartJsLine
				data={data}
				height={360}
				useTimeLineChart={true}
				yAxesTitle={formatMessage({id: 'insights.component.number.sprintBurndown'})}
			/>
		);
	}
}
export default injectIntl(SprintBurndownDummy);
