import React, {useMemo} from 'react';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';
import ForecastTooltip from '../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {ForecastTooltipFormulaRenderer} from '../../../ForecastTooltipFormulaRenderer';
import {UtilizationReportToolTips} from '../UtilizationReportTooltips';
import PeriodRow from './PeriodRow';
import PeriodPersonRows from './PeriodPersonRows';
import PeriodRoleRows from './PeriodRoleRows';
import PeriodDepartmentRows from './PeriodDepartmentRows';
import {GROUP_BY} from '../UtilizationReport';

export const PeriodUtilizationTable = ({
	theEyeOptionsNestedTree,
	filters,
	startDate,
	endDate,
	totalResourceNumbers,
	isUsingAllocations,
	setCsvDownloadFunction,
	groupBy,
	allocationControlsOptions,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const boolEyeOptions = useMemo(() => {
		const bools = {};
		theEyeOptionsNestedTree.forEach(eyeOption => {
			bools[eyeOption.name] = eyeOption.checked;
			if (eyeOption.nestedOptions) {
				eyeOption.nestedOptions.forEach(nestedOption => {
					bools[nestedOption.name] = nestedOption.checked;
				});
			}
		});
		return bools;
	}, [theEyeOptionsNestedTree]);

	const pageSize = 200;

	return (
		<Table paddedCells>
			<Table.Header>
				<Table.HeaderColumn flex={1} visible width="EXTRA_LARGE" />
				{/* Actuals */}
				<Table.HeaderGrouping name={formatMessage({id: 'project_budget.actual'})} minWidth={'250px'}>
					<Table.HeaderColumn
						key={'billableProjectHours'}
						usePadding
						align="right"
						visible={boolEyeOptions.billableProjectHours}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualBillableProjectTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'nonBillableProjectHours'}
						usePadding
						align="right"
						visible={boolEyeOptions.nonBillableProjectHours}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualNonBillableProjectTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'internalHours'}
						usePadding
						align="right"
						visible={boolEyeOptions.internalHours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualInternalTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.internal_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'totalRegisteredHours'}
						usePadding
						align="right"
						visible={boolEyeOptions.totalRegisteredHours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualTotalTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.total_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'overHours'}
						usePadding
						align="right"
						visible={boolEyeOptions.overHours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualOverTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.over_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'actualBillableUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.actualBillableUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'performance'}
						usePadding
						align="right"
						visible={boolEyeOptions.performance}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PerformanceToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.performance'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'actualNonBillableUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.actualNonBillableUtilization}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualNonBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.non_billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'actualInternalUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.actualInternalUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualInternalUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'actualResourceUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.actualResourceUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualResourceUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.resource_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Planned Utilization */}
				<Table.HeaderGrouping
					name={
						isUsingAllocations
							? formatMessage({id: 'common.planned_allocation_time'})
							: formatMessage({id: 'common.planned'})
					}
					minWidth={'250px'}
				>
					<Table.HeaderColumn
						key={'billableProjectAllocation'}
						usePadding
						align="right"
						visible={boolEyeOptions.billableProjectAllocation}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedBillableProjectTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'nonBillableProjectAllocation'}
						usePadding
						align="right"
						visible={boolEyeOptions.nonBillableProjectAllocation}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedNonBillableProjectTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'internalAllocation'}
						usePadding
						align="right"
						visible={boolEyeOptions.internalAllocation}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedInternalTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.internal_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'totalAllocation'}
						usePadding
						align="right"
						visible={boolEyeOptions.totalAllocation}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedTotalTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.total_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'overAllocation'}
						usePadding
						align="right"
						visible={boolEyeOptions.overAllocation}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedOverTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.over_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedBillableUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedBillableUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedNonBillableUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedNonBillableUtilization}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedNonBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.non_billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedInternalUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedInternalUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedInternalUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedResourceUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedResourceUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedResourceUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.resource_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Planned Combined Utilization */}
				<Table.HeaderGrouping name={formatMessage({id: 'utilization.planned_combined'})} minWidth={'400px'}>
					<Table.HeaderColumn
						key={'plannedBillableTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedCombinedBillableProjectHours}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedCombinedBillableProjectHoursToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedNonBillableTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedCombinedNonBillableProjectHours}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedCombinedNonBillableProjectHoursToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedInternalTimeTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedCombinedInternalHours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedCombinedInternalHoursToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.internal_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedTotalTimeTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedCombinedTotalRegisteredHours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedCombinedTotalRegisteredHoursToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.total_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedBillableUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedCombinedBillableUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedCombinedBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedNonBillableUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedCombinedNonBillableUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedCombinedNonBillableUtilizationToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.non_billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedInternalUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedCombinedInternalUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedCombinedInternalUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedResourceUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedCombinedResourceUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedCombinedResourceUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.resource_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Planned Tasks Utilization */}
				<Table.HeaderGrouping name={formatMessage({id: 'common.planned_task_time'})} minWidth={'250px'}>
					<Table.HeaderColumn
						key={'billableProjectTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.billableProjectTasks}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedBillableProjectTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'nonBillableProjectTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.nonBillableProjectTasks}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedNonBillableProjectTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'internalAllocationTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.internalAllocationTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedInternalTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.internal_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'totalTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.totalTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedTotalTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.total_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'overTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.overTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedOverTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.over_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedBillableTasksUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedBillableTasksUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedNonBillableTasksUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedNonBillableTasksUtilization}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedNonBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.non_billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedInternalUtilizationTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedInternalUtilizationTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedInternalUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedResourceTasksUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedResourceTasksUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedResourceUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.resource_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Actual vs Plan */}
				<Table.HeaderGrouping
					name={
						isUsingAllocations
							? formatMessage({id: 'utilization.actual_vs_plan_allocation_time'})
							: formatMessage({id: 'utilization.actual_vs_plan'})
					}
					minWidth={'250px'}
				>
					<Table.HeaderColumn
						key={'billableActualVsPlan'}
						usePadding
						align="right"
						visible={boolEyeOptions.billableActualVsPlan}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualVsPlanBillableToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_actual_vs_plan'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'nonBillableActualVsPlan'}
						usePadding
						align="right"
						visible={boolEyeOptions.nonBillableActualVsPlan}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualVsPlanNonBillableToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_actual_vs_plan'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'internalActualVsPlan'}
						usePadding
						align="right"
						visible={boolEyeOptions.internalActualVsPlan}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualVsPlanInternalToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_actual_vs_plan'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'totalActualVsPlan'}
						usePadding
						align="right"
						visible={boolEyeOptions.totalActualVsPlan}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualVsPlanTotalToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.total_actual_vs_plan'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Actual vs Plan Task */}
				<Table.HeaderGrouping name={formatMessage({id: 'utilization.actual_vs_plan_task_time'})} minWidth={'250px'}>
					<Table.HeaderColumn
						key={'billableActualVsPlanTask'}
						usePadding
						align="right"
						visible={boolEyeOptions.billableActualVsPlanTask}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualVsPlanBillableToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_actual_vs_plan'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'nonBillableActualVsPlanTask'}
						usePadding
						align="right"
						visible={boolEyeOptions.nonBillableActualVsPlanTask}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualVsPlanNonBillableToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_actual_vs_plan'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'totalActualVsPlanTask'}
						usePadding
						align="right"
						visible={boolEyeOptions.totalActualVsPlanTask}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ActualVsPlanTotalToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.total_actual_vs_plan'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Remaining Utilization */}
				<Table.HeaderGrouping
					name={
						isUsingAllocations
							? formatMessage({id: 'common.remaining_allocation_time'})
							: formatMessage({id: 'common.remaining'})
					}
					minWidth={'250px'}
				>
					<Table.HeaderColumn
						key={'billableRemaining'}
						usePadding
						align="right"
						visible={boolEyeOptions.billableRemaining}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingBillableProjectTimeToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'nonBillableRemaining'}
						usePadding
						align="right"
						visible={boolEyeOptions.nonBillableRemaining}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingNonBillableProjectTimeToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'internalRemaining'}
						usePadding
						align="right"
						visible={boolEyeOptions.internalRemaining}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingInternalTimeToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.internal_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'totalRemaining'}
						usePadding
						align="right"
						visible={boolEyeOptions.totalRemaining}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingTotalTimeToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.total_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'overtimeRemaining'}
						usePadding
						align="right"
						visible={boolEyeOptions.overtimeRemaining}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingOverTimeToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.over_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingBillableUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingBillableUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingBillableUtilizationToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingNonBillableUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingNonBillableUtilization}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingNonBillableUtilizationToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.non_billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingInternalUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingInternalUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingInternalUtilizationToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingResourceUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingResourceUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingResourceUtilizationToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.resource_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Remaining Combined Utilization */}
				<Table.HeaderGrouping name={formatMessage({id: 'utilization.remaining_combined'})} minWidth={'400px'}>
					<Table.HeaderColumn
						key={'remainingBillableTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingCombinedBillableProjectHours}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingCombinedBillableProjectHoursToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingNonBillableTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingCombinedNonBillableProjectHours}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingCombinedNonBillableProjectHoursToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingInternalTimeTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingCombinedInternalHours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingCombinedInternalHoursToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.internal_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingTotalTimeTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingCombinedTotalRegisteredHours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="600px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingCombinedTotalRegisteredHoursToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.total_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingBillableUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingCombinedBillableUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingCombinedBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingNonBillableUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingCombinedNonBillableUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingCombinedNonBillableUtilizationToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.non_billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingInternalUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingCombinedInternalUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingCombinedInternalUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingResourceUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingCombinedResourceUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingCombinedResourceUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.resource_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Remaining Tasks Utilization */}
				<Table.HeaderGrouping name={formatMessage({id: 'common.remaining_task_time'})} minWidth={'250px'}>
					<Table.HeaderColumn
						key={'billableRemainingTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.billableRemainingTasks}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingBillableProjectTimeToolTip(false, formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'nonBillableRemainingTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.nonBillableRemainingTasks}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingNonBillableProjectTimeToolTip(
										false,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'internalRemainingTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.internalRemainingTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingInternalTimeToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.internal_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'totalRemainingTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.totalRemainingTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingTotalTimeToolTip(false, formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.total_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'overtimeRemainingTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.overtimeRemainingTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingOverTimeToolTip(false, formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.over_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingBillableTasksUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingBillableTasksUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingBillableUtilizationToolTip(false, formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingNonBillableTasksUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingNonBillableTasksUtilization}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingNonBillableUtilizationToolTip(
										false,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.non_billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingInternalUtilizationTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingInternalUtilizationTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingInternalUtilizationToolTip(
										isUsingAllocations,
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingResourceTasksUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingResourceTasksUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingResourceUtilizationToolTip(false, formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.resource_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Forecast Utilization */}
				<Table.HeaderGrouping
					name={
						isUsingAllocations
							? formatMessage({id: 'common.forecasted_allocation_time'})
							: formatMessage({id: 'common.forecasted'})
					}
					minWidth={'250px'}
				>
					<Table.HeaderColumn
						key={'billableForecast'}
						usePadding
						align="right"
						visible={boolEyeOptions.billableForecast}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedBillableProjectTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'nonBillableForecast'}
						usePadding
						align="right"
						visible={boolEyeOptions.nonBillableForecast}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedNonBillableProjectTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'internalForecast'}
						usePadding
						align="right"
						visible={boolEyeOptions.internalForecast}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedInternalTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.internal_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'totalForecast'}
						usePadding
						align="right"
						visible={boolEyeOptions.totalForecast}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedTotalTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.total_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'overtimeForecast'}
						usePadding
						align="right"
						visible={boolEyeOptions.overtimeForecast}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedOverTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.over_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastBillableUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastBillableUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastNonBillableUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastNonBillableUtilization}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedNonBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.non_billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastInternalUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastInternalUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedInternalUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastResourceUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastResourceUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedResourceUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.resource_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Forecast Combined Utilization */}
				<Table.HeaderGrouping name={formatMessage({id: 'utilization.forecasted_combined'})} minWidth={'400px'}>
					<Table.HeaderColumn
						key={'forecastBillableTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastedCombinedBillableProjectHours}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="600px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedCombinedBillableProjectHoursToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastNonBillableTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastedCombinedNonBillableProjectHours}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="600px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedCombinedNonBillableProjectHoursToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastInternalTimeTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastedCombinedInternalHours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedCombinedInternalHoursToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.internal_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastTotalTimeTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastedCombinedTotalRegisteredHours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="600px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedCombinedTotalRegisteredHoursToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.total_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastBillableUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastedCombinedBillableUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedCombinedBillableUtilizationToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastNonBillableUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastedCombinedNonBillableUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedCombinedNonBillableUtilizationToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.non_billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastInternalUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastedCombinedInternalUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedCombinedInternalUtilizationToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastResourceUtilizationTaskAndAllocationsCombined'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastedCombinedResourceUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedCombinedResourceUtilizationToolTip(
										formatMessage
									)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.resource_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Forecast Tasks Utilization */}
				<Table.HeaderGrouping name={formatMessage({id: 'common.forecasted_task_time'})} minWidth={'250px'}>
					<Table.HeaderColumn
						key={'billableForecastTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.billableForecastTasks}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedBillableProjectTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'nonBillableForecastTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.nonBillableForecastTasks}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedNonBillableProjectTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'internalForecastTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.internalForecastTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedInternalTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.internal_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'totalForecastTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.totalForecastTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="400px"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedTotalTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.total_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'overtimeForecastTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.overtimeForecastTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedOverTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'utilization.over_time'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastBillableTasksUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastBillableTasksUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastNonBillableTasksUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastNonBillableTasksUtilization}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedNonBillableUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.non_billable_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastInternalUtilizationTasks'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastInternalUtilizationTasks}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedInternalUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.internal_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'forecastResourceTasksUtilization'}
						usePadding
						align="right"
						visible={boolEyeOptions.forecastResourceTasksUtilization}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.ForecastedResourceUtilizationToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'common.resource_utilization'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				{/* Availability */}
				<Table.HeaderGrouping name={formatMessage({id: 'utilization.availability'})}>
					<Table.HeaderColumn
						key={'workinghours'}
						usePadding
						align="right"
						visible={boolEyeOptions.workinghours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.WorkingTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.working_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn key={'timeOff'} usePadding align="right" visible={boolEyeOptions.timeOff} width="SMALL">
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.TimeOffToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>{formatMessage({id: 'common.time_off'})}</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'availableHours'}
						usePadding
						align="right"
						visible={boolEyeOptions.availableHours}
						width="SMALL"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.AvailableTimeToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.available_time'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>

				{/* Planned Allocation vs Task */}
				<Table.HeaderGrouping name={formatMessage({id: 'utilization.planned_allocation_vs_task'})} minWidth={'250px'}>
					<Table.HeaderColumn
						key={'plannedBillableAllocationVsTask'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedBillableAllocationVsTask}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedBillableAllocationVsTaskToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_allocation_vs_task'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedNonBillableAllocationVsTask'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedNonBillableAllocationVsTask}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedNonBillableAllocationVsTaskToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_allocation_vs_task'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'plannedTotalAllocationVsTask'}
						usePadding
						align="right"
						visible={boolEyeOptions.plannedTotalAllocationVsTask}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.PlannedTotalAllocationVsTaskToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.total_allocation_vs_task'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>

				{/* Remaining Allocation vs Task */}
				<Table.HeaderGrouping name={formatMessage({id: 'utilization.remaining_allocation_vs_task'})} minWidth={'250px'}>
					<Table.HeaderColumn
						key={'remainingBillableAllocationVsTask'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingBillableAllocationVsTask}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingBillableAllocationVsTaskToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.billable_allocation_vs_task'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingNonBillableAllocationVsTask'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingNonBillableAllocationVsTask}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingNonBillableAllocationVsTaskToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.non_billable_allocation_vs_task'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						key={'remainingTotalAllocationVsTask'}
						usePadding
						align="right"
						visible={boolEyeOptions.remainingTotalAllocationVsTask}
						width="MEDIUM"
					>
						<ForecastTooltip
							maxWidth="none"
							content={
								<ForecastTooltipFormulaRenderer
									items={UtilizationReportToolTips.RemainingTotalAllocationVsTaskToolTip(formatMessage)}
									translatedMessage={true}
								/>
							}
						>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'utilization.total_allocation_vs_task'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
			</Table.Header>
			<Table.Rows data={{rows: [totalResourceNumbers]}} canExpand={false} hasHeader>
				{({rowData, tableColumnsProps}) => {
					return (
						<PeriodRow
							resourceData={rowData}
							tableColumnProps={tableColumnsProps}
							allocationControlsOptions={allocationControlsOptions}
							headerColumn={<Table.Column>{intl.formatMessage({id: 'common.totals'})}</Table.Column>}
						/>
					);
				}}
			</Table.Rows>
			{groupBy === GROUP_BY.NONE ? (
				<PeriodPersonRows
					filters={filters}
					startDate={startDate}
					endDate={endDate}
					setCsvDownloadFunction={setCsvDownloadFunction}
					hasGroupingFeature={true}
					pageSize={pageSize}
					boolEyeOptions={boolEyeOptions}
					allocationControlsOptions={allocationControlsOptions}
				/>
			) : groupBy === GROUP_BY.ROLE ? (
				<PeriodRoleRows
					filters={filters}
					startDate={startDate}
					endDate={endDate}
					setCsvDownloadFunction={setCsvDownloadFunction}
					pageSize={pageSize}
					boolEyeOptions={boolEyeOptions}
					allocationControlsOptions={allocationControlsOptions}
				/>
			) : (
				<PeriodDepartmentRows
					filters={filters}
					startDate={startDate}
					endDate={endDate}
					setCsvDownloadFunction={setCsvDownloadFunction}
					pageSize={pageSize}
					boolEyeOptions={boolEyeOptions}
					allocationControlsOptions={allocationControlsOptions}
				/>
			)}
		</Table>
	);
};
