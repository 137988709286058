import {getAllGroupsOfType, GROUP_TYPE} from '../canvas-timeline/canvas_timeline_util';
import Util from '../../../forecast-app/shared/util/util';
import {
	filterGroupsItems,
	filterGroupsItemsCached,
	isPersonFiltered,
	isPersonFilteredNew,
	isPlaceholderFiltered,
	isPlaceholderFilteredNew,
	isProjectFiltered,
} from '../FilterUtils';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {STAFFING_GROUPING_TYPE} from '../IDManager';

const isFilteredBySearch = (pageComponent, group) => {
	const {searchFilterValue, staffingModeActive} = pageComponent.state;
	let visibleBySearch = true;

	if (searchFilterValue?.length) {
		let searchOrigin = '';

		if (staffingModeActive) {
			const personName = group.data.name || '';
			const personRole = group.data.roleName || '';

			let projectNames = '';
			group.groups.forEach(subGroup => {
				if (subGroup.groupType === GROUP_TYPE.PROJECT) {
					const projectName = subGroup.data.name;
					projectNames += projectName;
				}
			});

			searchOrigin += personName + personRole + projectNames;
		} else {
			const placeholderName = group.data.name;
			const placeholderRoleName = group.data.role || '';
			const placeholderProjectName = group.data.project.name;

			searchOrigin += placeholderName + placeholderRoleName + placeholderProjectName;
		}

		visibleBySearch = Util.normalizedIncludes(searchOrigin, searchFilterValue);
	}

	return !visibleBySearch;
};

export const filterPlaceholderGroupingGroups = pageComponent => {
	const {filterFunctions, data, groups, items} = pageComponent.state;

	// get only parent groups
	const placeholderGroupingGroups = getAllGroupsOfType(groups, GROUP_TYPE.PLACEHOLDERS_SCHEDULING_PLACEHOLDER_GROUPING_GROUP);

	if (filterFunctions) {
		placeholderGroupingGroups.forEach(groupingGroup => {
			if (groupingGroup.groups) {
				// go through subGroups of groupingGroup
				groupingGroup.groups.forEach(subGroup => {
					const isPlaceholderGroup = subGroup.groupType === GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP;

					if (subGroup.data.id && isPlaceholderGroup) {
						const placeholder = data.placeholders.find(placeholder => placeholder.id === subGroup.id);

						subGroup.filtered =
							isFilteredBySearch(pageComponent, subGroup) ||
							isProjectFiltered(pageComponent, placeholder.projectId || placeholder.projectGroupId) ||
							isPlaceholderFiltered(pageComponent, placeholder.id);
					}
				});
				filterGroupsItems(pageComponent, items, groupingGroup.groups);

				// if placeholderGroupingGroup has no placeholderGroups we mark it as filtered
				groupingGroup.filtered = !groupingGroup.groups.find(subGroup => !subGroup.filtered);
			}
		});
	}
};

export const filterPlaceholderGroupingGroupsCached = pageComponent => {
	const {filterFunctions, data, groups, items} = pageComponent.state;

	// get only parent groups
	const placeholderGroupingGroups = getAllGroupsOfType(groups, GROUP_TYPE.PLACEHOLDERS_SCHEDULING_PLACEHOLDER_GROUPING_GROUP);

	if (filterFunctions) {
		const placeholderGroupsFilteredCache = new Map();

		for (let i = 0; i < placeholderGroupingGroups.length; i++) {
			const groupingGroup = placeholderGroupingGroups[i];
			const subGroups = groupingGroup?.groups;

			// go through subGroups of groupingGroup
			if (subGroups) {
				for (let j = 0; j < subGroups.length; j++) {
					const subGroup = subGroups[j];

					const isPlaceholderGroup = subGroup.groupType === GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP;
					if (subGroup.data.id && isPlaceholderGroup) {
						const placeholder = data.placeholderMap.get(subGroup.id);

						subGroup.filtered =
							isFilteredBySearch(pageComponent, subGroup) ||
							isProjectFiltered(pageComponent, placeholder.projectId || placeholder.projectGroupId) ||
							isPlaceholderFilteredNew(pageComponent, placeholder.id);
						placeholderGroupsFilteredCache.set(subGroup.id, subGroup.filtered);
					}
				}

				// if placeholderGroupingGroup has no placeholderGroups we mark it as filtered
				groupingGroup.filtered = !groupingGroup.groups.find(subGroup => !subGroup.filtered);
			}
		}

		filterGroupsItemsCached(pageComponent, items, placeholderGroupsFilteredCache);
	}
};

const filterPersonGroups = pageComponent => {
	const {filterFunctions, groups, items} = pageComponent.state;

	const peopleGroupingGroups = groups.find(group => group.id === STAFFING_GROUPING_TYPE.FILTERED_MATCHES);
	const personGroups = peopleGroupingGroups?.groups?.filter(group => group.groupType === GROUP_TYPE.PERSON);

	if (filterFunctions && personGroups) {
		personGroups.forEach(personGroup => {
			const isPersonGroupFiltered =
				isPersonFiltered(pageComponent, personGroup) || isFilteredBySearch(pageComponent, personGroup);

			// filter project groups
			personGroup.groups.forEach(projectGroup => {
				projectGroup.filtered = isPersonGroupFiltered;
			});
			filterGroupsItems(pageComponent, items, personGroup.groups);

			personGroup.filtered = isPersonGroupFiltered;
		});
	}
};

const filterPersonGroupsCached = pageComponent => {
	const {filterFunctions, groups, items} = pageComponent.state;

	const peopleGroupingGroups = groups.find(group => group.id === STAFFING_GROUPING_TYPE.FILTERED_MATCHES);
	const personGroups = peopleGroupingGroups?.groups?.filter(group => group.groupType === GROUP_TYPE.PERSON);

	if (filterFunctions && personGroups) {
		const projectGroupsFilteredCache = new Map();

		for (let i = 0; i < personGroups.length; i++) {
			const personGroup = personGroups[i];

			const isPersonGroupFiltered =
				isPersonFilteredNew(pageComponent, personGroup) || isFilteredBySearch(pageComponent, personGroup);

			// filter project groups
			const projectGroups = personGroup.groups;
			for (let j = 0; j < projectGroups.length; j++) {
				const projectGroup = projectGroups[j];

				projectGroup.filtered = isPersonGroupFiltered;
				projectGroupsFilteredCache.set(projectGroup.id, projectGroup.filtered);
			}

			personGroup.filtered = isPersonGroupFiltered;
		}

		filterGroupsItemsCached(pageComponent, items, projectGroupsFilteredCache);
	}
};

export const filterGroupsAndItems = pageComponent => {
	const {staffingModeActive} = pageComponent.state;
	const useNewFiltering = hasFeatureFlag('improving_heatmap_frontend_performance');

	if (staffingModeActive) {
		if (useNewFiltering) {
			filterPersonGroupsCached(pageComponent);
		} else {
			filterPersonGroups(pageComponent);
		}
	} else {
		if (useNewFiltering) {
			filterPlaceholderGroupingGroupsCached(pageComponent);
		} else {
			filterPlaceholderGroupingGroups(pageComponent);
		}
	}
};
