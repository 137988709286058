export default class InsightsUtil {
	static GetlistHeight(numOfElements) {
		return InsightsUtil.PixelsToGridHeight(InsightsUtil.GetListHeightPx(numOfElements));
	}

	static GetListHeightPx(numOfElements) {
		const padding = 20;
		const headerHeight = 101;
		const elementHeight = 51;
		return padding + headerHeight + elementHeight * numOfElements;
	}

	static PixelsToGridHeight(pixels) {
		return Math.ceil(pixels / 210);
	}
}
