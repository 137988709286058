import React, {Component} from 'react';
import {CSSTransition} from 'react-transition-group';
import {createFragmentContainer, graphql} from 'react-relay';
import PropTypes from 'prop-types';
import AssignedDropdown from '../../../forecast-app/shared/components/dropdowns/assigned-dropdown/assigned_dropdown';
import {injectIntl} from 'react-intl';
import GenericModal from '../../../containers/modal/generic_modal';
import {BUTTON_COLOR} from '../../../constants';
import Checkbox from '../../../components/new-ui/check_box';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import UpdateTasksBulkMutation from '../../../mutations/update_tasks_bulk_scheduling_mutation';
import Util from '../../../forecast-app/shared/util/util';
import {ESTIMATION_UNIT, DATE_PICKER_STYLE} from '../../../constants';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import Moment from 'moment';
import Info from '../../../components/info';
import RadioButton from '../../../components/new-ui/radio_button';
import NestedDropdown from '../../../forecast-app/shared/components/dropdowns/nested_dropdown';
import {PROJECT_STATUS} from '../../../constants';
import GenerateLowHighSuggestionsMutation from '../../../mutations/generate_low_high_suggestions_mutation';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import _ from 'lodash';
import Nova02Image from '../../../images/graphics/Nova02Image';
import ExpandIcon from '../../../images/v2/components/dropdown/expand_icon';
import InformationIcon from '../../../images/information-icon';
import AiIcon from '../../../images/ico-ai';
import DatePicker from '../../../forecast-app/shared/components/date-picker/date_picker_v3';
import AiLoader from '../../loaders/ai_loader';
import * as tracking from '../../../tracking';
import TooltipContainer from '../../../forecast-app/shared/components/tooltips/tooltip_container';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import DirectApi from '../../../directApi';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const getItemStyle = (isDragging, draggableStyle) => ({
	// styles we need to apply on draggables
	...draggableStyle,
});

class TaskItem extends Component {
	constructor(props) {
		super(props);
		const {isEstimatedInHours, task, roles, suggestedEstimate} = props;
		const {estimateForecast} = task;
		const taskRole = roles.find(role => role.id === task.roleId);
		const estimate = suggestedEstimate ? suggestedEstimate : estimateForecast;
		this.state = {
			estimateForecast: isEstimatedInHours ? estimate / 60 : estimate,
			estimateChanged: false,
			taskRole: taskRole ? {id: task.roleId, name: taskRole.name} : null,
			extraPersonsAdded: [],
		};
	}

	onEstimateChange(value) {
		const {isEstimatedInHours} = this.props;
		let estimate = value || 0;
		estimate = isEstimatedInHours ? Util.getHourEstimate(estimate) : Math.round(parseFloat(estimate * 100)) / 100;

		this.setState({estimateForecast: estimate, estimateChanged: true});
	}

	onRoleAssign(roleId) {
		const taskNewRole = this.props.roles.find(role => role.id === roleId);
		this.setState({taskRole: taskNewRole});
		this.props.onTaskUpdate(this.props.task.id, {role: taskNewRole});
		tracking.trackEvent('Auto Schedule - update task role');
		trackEvent('Auto Schedule Task Role', 'Updated');
	}

	render() {
		const {
			task,
			roles,
			roleSuggestion,
			isRoleFromUser,
			isEstimatesFromUser,
			isEstimatedInHours,
			isOverrideAssigneesSelected,
			isSkippingUnestimatedTasks,
			cy,
		} = this.props;
		const {estimateForecast, estimateChanged, taskRole} = this.state;

		const shouldShowRoleInput = isOverrideAssigneesSelected ? !task.roleId : !task.assignedPersons.length && !task.roleId;
		return (
			<div className="task-item-container" data-cy={cy}>
				<div className="task-info-container">
					<div className="task-id">T{task.companyTaskId}</div>
					<div className="task-name remove-low-high">{task.name}</div>
					{shouldShowRoleInput ? (
						<AssignedDropdown
							task={task}
							assignableRoles={roles.map(role => {
								return {
									node: role, // make roles list compatible with the prop the dropdown is expecting
								};
							})}
							assignedRole={
								taskRole ? taskRole : roleSuggestion ? roles.find(role => role.id === roleSuggestion.id) : null
							}
							shouldShowAiIcon={!taskRole && roleSuggestion && !isRoleFromUser}
							useNewAiIcon
							assignRole={this.onRoleAssign.bind(this)}
							shortInput={true}
							showSuggestions={true}
							id={task.id}
							hideDelete
							disabled={isSkippingUnestimatedTasks}
						/>
					) : (
						<div className="role-placeholder"></div>
					)}
					{!task.estimateForecast ? (
						<HoursInput
							id={`${task.id}-auto-scheduling-modal-estimate`}
							placeholder={'0'}
							value={estimateForecast || ''}
							mutation={this.onEstimateChange.bind(this)}
							onBlur={() => {
								this.props.onTaskUpdate(task.id, {
									estimate: this.state.estimateForecast * (isEstimatedInHours ? 60 : 1),
								});
								if (estimateChanged) {
									tracking.trackEvent('Auto Schedule - update estimate');
									trackEvent('Auto Schedule Estimate', 'Updated');
								}
							}}
							onClick={e => e.focus()}
							showAI={this.props.suggestedEstimate && !estimateChanged && !isEstimatesFromUser}
							cy={`${cy}-estimate-forecast`}
							disabled={isSkippingUnestimatedTasks}
							noMaxWidth={true}
							customClassName={'task-estimate'}
						></HoursInput>
					) : (
						<div className="estimate-placeholder"></div>
					)}
				</div>
				<div className="corner-cover" />
			</div>
		);
	}
}

class autoSchedulingModal extends Component {
	constructor(props) {
		super(props);

		const phaseSortOrderMap = new Map();

		Util.sortPhases(props.phases).forEach((phase, index) => {
			phaseSortOrderMap.set(phase.id, index);
		});

		const isOverrideAssigneesSelected = localStorage.getItem('isOverrideAssigneesSelected') !== 'false';
		const isUseProjectTeamOptionSelected = localStorage.getItem('isUseProjectTeamOptionSelected') !== 'false';
		const isUseEntireCompanyOptionSelected = localStorage.getItem('isUseEntireCompanyOptionSelected') === 'true';

		const {personsToAssign, unqualifiedPersons} = this.getPersonsToAssign(
			this.props.project,
			false,
			isUseEntireCompanyOptionSelected,
			false,
			isOverrideAssigneesSelected
		);
		const emptyProject = personsToAssign.length === 0;

		this.state = {
			isOverrideAssigneesSelected,
			phaseSortOrderMap,
			hasPhaseOrderChanged: false,
			unselectedPhasesIds:
				props.phases && props.phases.length && props.tasks.some(task => !task.phaseId) ? [btoa('PhaseType:-1')] : [], //array that will contain the ids of the phases that are not selected. deselect no phase if there are other phases

			isUseProjectTeamOptionSelected: isUseProjectTeamOptionSelected && !emptyProject,
			isUseEntireCompanyOptionSelected: isUseEntireCompanyOptionSelected || emptyProject,
			isAddProjectTeamOptionSelected: false,

			isAllowPhaseNotOverlapSelected: localStorage.getItem('isAllowPhaseNotOverlapSelected') !== 'false',

			isHighPriorityFirstOptionSelected: localStorage.getItem('isHighPriorityFirstOptionSelected') === 'true',
			isBlockedTasksOptionSelected: localStorage.getItem('isBlockedTasksOptionSelected') === 'true',

			isOverridePhaseDatesSelected: true,
			isShowingSimilarProjectModal: true, // true because this is the first modal
			isShowingPhaseOrderModal: false,
			isShowingUnestimatedTaskListModal: false,
			isLastStep:
				this.props.phases &&
				!this.props.phases.length &&
				props.tasks.every(task => task.estimateForecast && task.roleId), //the first modal is last step if there are no phases and all tasks have estimates and roles
			selectedSimilarProject: null,
			roleSuggestionsLoaded: false,
			lowHighSuggestionsLoaded: false,
			// tasksWithNoRoleOrEstimations: props.tasks.filter(task => (isOverrideAssigneesSelected && !task.assignedPersons.length && !task.roleId) || !task.estimateForecast),
			tasksWithNoRoleOrEstimations: isOverrideAssigneesSelected
				? props.tasks.filter(task => !task.roleId || !task.estimateForecast)
				: props.tasks.filter(task => (!task.assignedPersons.length && !task.roleId) || !task.estimateForecast),
			tasksWithSuggestionsAndUserUpdates: [],
			unestimatedTasksUserUpdates: {}, // store the fields updated by the user to prevent overwritting when updating based on the similar project
			skipUnestimatedTasks: false,
			isSettingsExpanded: false,
			projectStartDate: Moment(),
			emptyProject,
			personsToAssign,
			unqualifiedPersons,
		};
	}

	async fetchSuggestedRolePromise(data = {}) {
		let requestUrl = DirectApi.graphqlServerEndpoint('tasks_suggested_role');
		const headers = new Headers();
		headers.append('Content-Type', 'application/json');
		if (window.AbortController) {
			//Not supported by IE
			this.abortController = new window.AbortController();
		}
		const signal = this.abortController ? this.abortController.signal : undefined;
		const init = {method: 'POST', headers, signal, credentials: 'include', body: JSON.stringify({data})};
		return await fetch(requestUrl, init)
			.then(response => response.json())
			.then(data => {
				return data;
			})
			.catch(error => {
				//Most likely aborted in unmount
				if (!('' + error).includes('abort')) {
					throw error;
				}
			});
	}

	getUpdatedSuggestedRoles = selectedSimilarProject => {
		const {unestimatedTasksUserUpdates} = this.state;
		const tasksWithNoRoleId = this.props.tasks
			.filter(
				task =>
					!task.roleId &&
					(unestimatedTasksUserUpdates[task.id] ? !unestimatedTasksUserUpdates[task.id].includes('role') : true)
			)
			.map(task => task.id);

		if (tasksWithNoRoleId.length) {
			this.fetchSuggestedRolePromise({
				tasksWithNoRoleId,
				similarProjectId: selectedSimilarProject ? selectedSimilarProject.id : null,
			}).then(data => {
				for (const taskId in data) {
					if (data[taskId] && data[taskId].length) {
						// update task with the suggested role
						if (this.state.tasksWithSuggestionsAndUserUpdates.some(task => task.taskId === taskId)) {
							// if task already exists in the state, update it
							let taskToUpdate = this.state.tasksWithSuggestionsAndUserUpdates.find(
								task => task.taskId === taskId
							);
							taskToUpdate = {...taskToUpdate, role: data[taskId][0]};
							const updatedState = [
								...this.state.tasksWithSuggestionsAndUserUpdates.filter(task => task.taskId !== taskId),
								taskToUpdate,
							];
							this.setState({tasksWithSuggestionsAndUserUpdates: updatedState});
						} else {
							// if task does not exist in the state add it
							const updatedState = [
								...this.state.tasksWithSuggestionsAndUserUpdates,
								{taskId, role: data[taskId][0]},
							];
							this.setState({tasksWithSuggestionsAndUserUpdates: updatedState});
						}
					}
				}
				this.setState({roleSuggestionsLoaded: true});
			});
		} else {
			this.setState({roleSuggestionsLoaded: true});
		}
	};

	getUpdatedLowHighEst = selectedSimilarProject => {
		const {unestimatedTasksUserUpdates, tasksWithSuggestionsAndUserUpdates} = this.state;
		const tasksWithNoEstimates = this.props.tasks.filter(
			task =>
				!task.estimateForecast &&
				(unestimatedTasksUserUpdates[task.id]
					? !unestimatedTasksUserUpdates[task.id].includes('low') &&
					  !unestimatedTasksUserUpdates[task.id].includes('high')
					: true)
		);

		if (tasksWithNoEstimates.length) {
			// Fetch Low/High suggestions based on the suggested roles and similar project
			Util.CommitSchedulingModalUpdate(
				GenerateLowHighSuggestionsMutation,
				{
					projectId: selectedSimilarProject ? selectedSimilarProject.id : null,
					tasks: tasksWithNoEstimates.map(task => {
						// include the sugested or user chosen role in the request for better low high suggestions
						const taskWithSuggestionsAndUserUpdates = tasksWithSuggestionsAndUserUpdates.find(
							t => t.taskId === task.id
						);
						return {
							taskId: task.id,
							roleId:
								taskWithSuggestionsAndUserUpdates && taskWithSuggestionsAndUserUpdates.role
									? taskWithSuggestionsAndUserUpdates.role.id
									: task.roleId,
						};
					}),
				},
				response => {
					// On success
					if (response.generateLowHighSuggestions) {
						this.onLowHighSuggestionsResponse(response.generateLowHighSuggestions.suggestions);
					}
					this.setState({lowHighSuggestionsLoaded: true});
				}
			);
		} else {
			this.setState({lowHighSuggestionsLoaded: true});
		}
	};

	onLowHighSuggestionsResponse(data) {
		for (let task of data) {
			if ((task.low && task.high) || task.estimate) {
				if (this.state.tasksWithSuggestionsAndUserUpdates.some(t => t.taskId === task.taskId)) {
					let taskToUpdate = this.state.tasksWithSuggestionsAndUserUpdates.find(t => t.taskId === task.taskId);
					taskToUpdate = {...taskToUpdate, low: task.low, high: task.high, estimate: task.estimate};
					const updatedState = [
						...this.state.tasksWithSuggestionsAndUserUpdates.filter(t => t.taskId !== task.taskId),
						taskToUpdate,
					];
					this.setState({tasksWithSuggestionsAndUserUpdates: updatedState});
				} else {
					const updatedState = [
						...this.state.tasksWithSuggestionsAndUserUpdates,
						{taskId: task.taskId, low: task.low, high: task.high, estimate: task.estimate},
					];
					this.setState({tasksWithSuggestionsAndUserUpdates: updatedState});
				}
			}
		}
	}

	async onContinue() {
		const {
			isOverridePhaseDatesSelected,
			isOverrideAssigneesSelected,
			isShowingSimilarProjectModal,
			isShowingPhaseOrderModal,
			isLastStep,
			selectedSimilarProject,
			isHighPriorityFirstOptionSelected,
			isBlockedTasksOptionSelected,
			projectStartDate,
			unselectedPhasesIds,
			tasksWithNoRoleOrEstimations,
			skipUnestimatedTasks,
			personsToAssign,
			unqualifiedPersons,
			tasksWithSuggestionsAndUserUpdates,
		} = this.state;
		const {project, phases, notQualifiedPhasesNames} = this.props;
		const selectedTasks = this.props.tasks;
		const tasksToBeUpdated = tasksWithNoRoleOrEstimations.filter(task => {
			// check if the task's phase is excluded
			if (task.phaseId) {
				return !unselectedPhasesIds.includes(task.phaseId);
			}
			// if the task does not have a phase check if the noPhase is selected
			return !unselectedPhasesIds.some(phaseId => atob(phaseId).replace('PhaseType:', '') === '-1');
		});
		const shouldShowUnestimatedTaskList = tasksToBeUpdated.length > 0;

		if (isLastStep) {
			// make autoSchedule req only if it is last step
			// filter the tasks that do not have any time left
			const filteredTasks = selectedTasks.filter(task => task.timeLeft || (isBlockedTasksOptionSelected && task.blocked));
			let taskIds = filteredTasks.map(task => +atob(task.id).replace('Task:', ''));
			const taskDeactivatedUsersMap = new Map();
			const deactivatedCompanyPersons = this.props.companyPersons.filter(companyPerson => !companyPerson.active);
			if (!isOverrideAssigneesSelected) {
				filteredTasks.forEach(task => {
					task.assignedPersons.forEach(assignedPersonId => {
						const person = deactivatedCompanyPersons.find(companyPerson => companyPerson.id === assignedPersonId);
						if (person) {
							taskDeactivatedUsersMap.set(task.id, person);
						}
					});
				});
			}
			const noAutoScheduleTasksIds = this.state.tasksWithNoRoleOrEstimations.map(t => +atob(t.id).replace('Task:', ''));
			// if the user chooses to skip unestimated tasks we substract them from the list of tasks to be autoscheduled
			taskIds = skipUnestimatedTasks
				? _.difference(taskIds, noAutoScheduleTasksIds)
				: _.uniq(taskIds.concat(noAutoScheduleTasksIds));

			const phaseIds = [];
			this.state.phaseSortOrderMap.forEach(function (val, key) {
				phaseIds.splice(val, 0, +atob(key).replace('PhaseType:', ''));
			});
			// add no phase
			phaseIds.push(-1);

			// Don't autoschedule if there are no tasks with remaining time. Backend crashes
			if (!taskIds.length) {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'No tasks were selected. Schedule could not be created.'}),
				});
				this.props.closeModal();
				return;
			}

			// zeroRemainingTasks are tasks that are about to be updated and do not have any timeleft
			let zeroRemainingTasks = selectedTasks
				.filter(task => {
					// check if the task's phase is excluded
					if (task.phaseId) {
						return !unselectedPhasesIds.includes(task.phaseId);
					}
					// if the task does not have a phase check if the noPhase is selected
					return !unselectedPhasesIds.some(phaseId => atob(phaseId).replace('PhaseType:', '') === '-1');
				})
				.filter(task => !task.timeLeft);

			let tasksToUpdate = [];

			// do not update tasks if the skip option is selected
			if (!skipUnestimatedTasks) {
				// update tasks that have suggestions or were updated by the user
				for (let task of tasksToBeUpdated) {
					const taskWithSuggestions = tasksWithSuggestionsAndUserUpdates.find(t => t.taskId === task.id);
					if (taskWithSuggestions) {
						let taskToUpdate = {taskId: taskWithSuggestions.taskId};
						if (taskWithSuggestions.estimate) {
							taskToUpdate.estimateForecast = taskWithSuggestions.estimate;
						}
						if (taskWithSuggestions.role) {
							taskToUpdate.roleId = taskWithSuggestions.role.id;
						}
						tasksToUpdate.push(taskToUpdate);
					}
				}
				await Util.CommitSchedulingModalUpdatePromise(UpdateTasksBulkMutation, {tasks: tasksToUpdate});
			} else {
				// if the user is skipping the unestimated tasks remove them from the list of tasks that have no remaining
				// because we do not want to show the in the error message
				zeroRemainingTasks = _.difference(zeroRemainingTasks, tasksToBeUpdated);
			}

			const doneOrEmptyPhasesIds = phases
				.filter(phase => {
					const hasNotDoneTask = this.props.tasks.some(task => task.phaseId === phase.id && !task.done);
					return !hasNotDoneTask;
				})
				.map(phase => phase.id);

			const autoSchedulingRequestConfig = {
				projectId: +atob(project.id).replace('ProjectType:', ''),
				startDay: projectStartDate.date(),
				startMonth: projectStartDate.month() + 1,
				startYear: projectStartDate.year(),
				taskIds,
				personRole: personsToAssign.filter(person => !person.isClientUser && person.active && person.roleId),
				keepPhaseDates: !isOverridePhaseDatesSelected,
				phaseIds: phaseIds.filter(pId => !unselectedPhasesIds.includes(btoa(`PhaseType:${pId}`))),
				keepAssignees: !isOverrideAssigneesSelected,
				similarProjectId: selectedSimilarProject ? +atob(selectedSimilarProject.id).replace('ProjectType:', '') : null,
				scheduleHighPriorityFirst: isHighPriorityFirstOptionSelected,
				scheduleBlockedTasks: !isBlockedTasksOptionSelected,
				allowPhaseOverlap: !this.state.isAllowPhaseNotOverlapSelected,
			};

			this.props.onAutoSchedulingConfig({
				requestNumber: 1,
				autoSchedulingRequestConfig,
				doneOrEmptyPhasesIds,
				extraPersonsAdded: this.state.extraPersonsAdded,
				taskDeactivatedUsersMap,
				skipUnestimatedTasks,
				tasksWithNoRoleOrEstimations,
				selectedTasks,
				unselectedPhasesIds,
				isOverridePhaseDatesSelected,
				project,
				projectStartDate,
				unqualifiedPersons,
				notQualifiedPhasesNames,
				zeroRemainingTasks,
			});

			this.props.closeModal();
		} else if (isShowingSimilarProjectModal) {
			this.setState({
				isShowingSimilarProjectModal: false, // hide current modal and
				isShowingPhaseOrderModal: phases && phases.length, // show the phases modal if there are phases
				isShowingUnestimatedTaskListModal: shouldShowUnestimatedTaskList && !(phases && phases.length),
				isLastStep: !shouldShowUnestimatedTaskList || (!(phases && phases.length) && shouldShowUnestimatedTaskList), // if next step is the last step
			});

			// get role suggestions for all taks with no roles based on the similar project
			this.getUpdatedSuggestedRoles(this.state.selectedSimilarProject);

			// Fetch Low/High suggestions based on the suggested roles and similar project
			this.getUpdatedLowHighEst(this.state.selectedSimilarProject);

			if (this.props.fetchPersonHeatmapData) {
				this.props.fetchPersonHeatmapData(
					personsToAssign
						.filter(person => !person.isClientUser && person.active)
						.map(p => btoa(`Person:${p.personId}`))
				);
			}
		} else if (isShowingPhaseOrderModal) {
			this.setState({
				isShowingPhaseOrderModal: false,
				isShowingUnestimatedTaskListModal: shouldShowUnestimatedTaskList, // if there is no phases warning, check if we should show the modal after that
				isLastStep: shouldShowUnestimatedTaskList, //if there is no phases warning jump to the unestimated tasks modal which is the last
				notQualifiedPhasesNames,
			});
		}
	}

	onBack() {
		const {isShowingPhaseOrderModal, isShowingUnestimatedTaskListModal} = this.state;

		if (isShowingPhaseOrderModal) {
			this.setState({
				isShowingPhaseOrderModal: false,
				isShowingSimilarProjectModal: true,
				isLastStep: false,
			});
		} else if (isShowingUnestimatedTaskListModal) {
			this.setState({
				isShowingPhaseOrderModal: true,
				isShowingUnestimatedTaskListModal: false,
				isLastStep: false,
			});
		}
	}

	// function that returns team members
	getPersonsToAssign(
		project,
		selectedSimilarProject,
		isUseEntireCompanyOptionSelected,
		isAddProjectTeamOptionSelected,
		isOverrideAssigneesSelected
	) {
		let personsToAssign = [];
		if (isUseEntireCompanyOptionSelected) {
			// get the persons assigned to the current project
			const currentProjectAssignees = this.props.projectPersons.filter(pp => pp.projectId === project.id);
			// Add company person that were not already on the project
			const remainingCompanyPersons = this.props.companyPersons
				.filter(
					companyPerson => companyPerson.active && !currentProjectAssignees.some(p => p.personId === companyPerson.id)
				)
				.map(p => ({
					personId: p.id,
					roleId: p.roleId,
				}));
			personsToAssign = [...currentProjectAssignees, ...remainingCompanyPersons];
		} else if (isAddProjectTeamOptionSelected) {
			// get the persons assigned to the current project
			const currentProjectAssignees = this.props.projectPersons.filter(pp => pp.projectId === project.id);
			const companyDeactivatedPersonIds = this.props.companyPersons.filter(p => !p.active).map(p => p.id); // used for filtering deactivated persons

			// get the persons assigned to the selected project but exclude those that are in the current project are deactivated
			const selectedProjectAssignees = this.props.projectPersons.filter(
				pp =>
					!companyDeactivatedPersonIds.includes(pp.personId) &&
					!currentProjectAssignees.some(p => p.personId === pp.personId) &&
					pp.projectId === selectedSimilarProject.id
			);

			personsToAssign = [...currentProjectAssignees, ...selectedProjectAssignees];
		} else {
			const companyDeactivatedPersonIds = this.props.companyPersons.filter(p => !p.active).map(p => p.id); // used for filtering deactivated persons
			// get the persons assigned to the current project that are active
			personsToAssign = this.props.projectPersons.filter(
				pp => !companyDeactivatedPersonIds.includes(pp.personId) && pp.projectId === project.id
			);
		}

		// Filter out client users
		const clientUserIds = this.props.companyPersons
			.filter(companyPerson => companyPerson.clientId)
			.map(companyPerson => companyPerson.id);
		personsToAssign = personsToAssign.filter(p => !clientUserIds.includes(p.personId));

		// Add people assigned to incomplete tasks, but no longer on project. Only if we are not overriding already assigned persons

		if (!isOverrideAssigneesSelected) {
			const allTaskAssignedPersonIds = [];
			this.props.tasks
				.filter(t => !t.done)
				.forEach(task => {
					if (task.assignedPersons && task.assignedPersons.length) {
						task.assignedPersons.forEach(personId => {
							if (!allTaskAssignedPersonIds.includes(personId)) {
								allTaskAssignedPersonIds.push(personId);
							}
						});
					}
				});
			const personIdsToAssign = personsToAssign.map(pp => pp.personId);
			const newPersonIdsToAssign = allTaskAssignedPersonIds.filter(personId => !personIdsToAssign.includes(personId));
			if (newPersonIdsToAssign.length) {
				const extraPersonsAdded = [];
				newPersonIdsToAssign.forEach(personId => {
					const person = this.props.companyPersons.find(companyPerson => companyPerson.id === personId);
					personsToAssign.push({
						personId,
						roleId: person.roleId,
						active: person.active,
					});
					// Don't add deactivated or client users to the "extraPersonsAdded" array
					if (!clientUserIds.includes(personId) && person.active) {
						extraPersonsAdded.push(person);
					}
				});
				this.setState({extraPersonsAdded});
			}
		}

		const personsWithoutRoleIds = personsToAssign.filter(p => !p.roleId).map(p => p.personId);
		const unqualifiedPersons = personsWithoutRoleIds
			.map(id => {
				const companyPerson = this.props.companyPersons.find(p => p.id === id);
				return {
					personId: companyPerson.id,
					fullName: companyPerson.fullName,
					clientId: companyPerson.clientId,
				};
			})
			.filter(p => !p.clientId);

		return {
			unqualifiedPersons,
			personsToAssign: personsToAssign.map(pp => ({
				personId: +atob(pp.personId).replace('Person:', ''),
				roleId: pp.roleId ? +atob(pp.roleId).replace('Role:', '') : null,
				active: pp.active != null ? pp.active : true,
				isClientUser: clientUserIds.includes(pp.personId),
			})),
		};
	}

	onPhaseDragEnd(data) {
		const {phaseSortOrderMap} = this.state;
		const phaseId = data.draggableId;
		const sourceIndex = data.source.index;
		const destinationIndex = data.destination ? data.destination.index : sourceIndex;
		phaseSortOrderMap.set(phaseId, destinationIndex);

		if (sourceIndex < destinationIndex) {
			for (const entry of phaseSortOrderMap.entries()) {
				if (entry[0] === phaseId) continue;
				if (entry[1] > sourceIndex && entry[1] <= destinationIndex) {
					phaseSortOrderMap.set(entry[0], --entry[1]);
				}
			}
		} else if (sourceIndex > destinationIndex) {
			for (const entry of phaseSortOrderMap.entries()) {
				if (entry[0] === phaseId) continue;
				if (entry[1] < sourceIndex && entry[1] >= destinationIndex) {
					phaseSortOrderMap.set(entry[0], ++entry[1]);
				}
			}
		}
		this.setState({phaseSortOrderMap, hasPhaseOrderChanged: true});

		tracking.trackEvent(`Auto Schedule Modal - Changed Phases order`);
		trackEvent('Auto Schedule Phase Order', 'Changed');
	}

	handlePhaseCheckboxClick(phaseId) {
		const {tasksWithNoRoleOrEstimations} = this.state;

		let isThisLastStep = false; // this is last step if there are no unqualified phases and there are tasks that do not have role/estimations and belong to the selected milesones
		let updatedUnselectedPhaseIds = [];

		// if the clicked phase is unselected add it to the list otherwise remove it
		if (this.state.unselectedPhasesIds.includes(phaseId)) {
			updatedUnselectedPhaseIds = this.state.unselectedPhasesIds.filter(id => id !== phaseId);
			tracking.trackElementClicked(`Auto Schedule Modal - Unselect Phase to be Auto Scheduled`);
			trackEvent('Auto Schedule Phase', 'Unselected');
		} else {
			updatedUnselectedPhaseIds = this.state.unselectedPhasesIds.concat(phaseId);
			tracking.trackElementClicked(`Auto Schedule Modal - Select Phase to be Auto Scheduled`);
			trackEvent('Auto Schedule Phase', 'Reselected');
		}

		isThisLastStep = !tasksWithNoRoleOrEstimations.some(task => !updatedUnselectedPhaseIds.includes(task.phaseId));
		this.setState({
			unselectedPhasesIds: updatedUnselectedPhaseIds,
			isLastStep: isThisLastStep,
		});
	}
	// similar to the dropdown from the header
	getProjectsDropdownOptions(intl, projects) {
		let projectOptions = [];
		const {formatMessage} = intl;
		const running_projects = [],
			planning_projects = [],
			halted_projects = [],
			done_projects = [];

		projects
			.filter(project => project.node.id !== this.props.project.id)
			.forEach(project => {
				const el = {
					id: project.node.id,
					projectColor: project.node.projectColor,
					name: project.node.name,
					companyProjectId: project.node.companyProjectId,
					label: project.node.name,
				};
				if (project.node.status === PROJECT_STATUS.PLANNING) {
					planning_projects.push(el);
				} else if (project.node.status === PROJECT_STATUS.RUNNING) {
					running_projects.push(el);
				} else if (project.node.status === PROJECT_STATUS.HALTED) {
					halted_projects.push(el);
				} else if (project.node.status === PROJECT_STATUS.DONE) {
					done_projects.push(el);
				}
			});

		const sortProjects = (a, b) => {
			if (a.sortValue < b.sortValue) return -1;
			if (a.sortValue > b.sortValue) return 1;
			if (a.sortValue === b.sortValue) {
				if (a.isInProjectGroup && !b.isInProjectGroup) {
					return 1;
				}
			}
			return 0;
		};
		running_projects.sort(sortProjects);
		planning_projects.sort(sortProjects);
		halted_projects.sort(sortProjects);
		projectOptions.push(
			{
				value: 'planning_projects',
				label: formatMessage({id: 'project_status.planning'}),
				nestedOptions: planning_projects,
				collapseByDefault: true,
			},
			{
				value: 'running_projects',
				label: formatMessage({id: 'project_status.running'}),
				nestedOptions: running_projects,
				collapseByDefault: true,
			},
			{value: 'done_projects', label: formatMessage({id: 'project_status.done'}), nestedOptions: done_projects},
			{
				value: 'halted_projects',
				label: formatMessage({id: 'project_status.halted'}),
				nestedOptions: halted_projects,
				collapseByDefault: true,
			}
		);

		return projectOptions;
	}

	onSimilarProjectSelectUnestTasksModal(selectedSimilarProject) {
		this.onSimilarProjectSelect(selectedSimilarProject);
		// callback that updated the suggestions when we change the similar project
		// show loader
		this.setState({roleSuggestionsLoaded: false, lowHighSuggestionsLoaded: false});
		// get role suggestions for all taks with no roles
		this.getUpdatedSuggestedRoles(selectedSimilarProject);
		// Fetch Low/High suggestions based on the suggested roles and similar project
		this.getUpdatedLowHighEst(selectedSimilarProject);
	}

	onSimilarProjectSelect(selectedSimilarProject) {
		const {isUseEntireCompanyOptionSelected, isAddProjectTeamOptionSelected, isOverrideAssigneesSelected} = this.state;

		this.setState({
			selectedSimilarProject,
		});

		if (!selectedSimilarProject && isAddProjectTeamOptionSelected) {
			// if there is no similar project selected reset the team options
			this.setState({
				isUseProjectTeamOptionSelected: true,
				isUseEntireCompanyOptionSelected: false,
				isAddProjectTeamOptionSelected: false,
			});
			// update the selected persons and the ones that are unqualified
			const {personsToAssign, unqualifiedPersons} = this.getPersonsToAssign(
				this.props.project,
				selectedSimilarProject,
				false,
				false,
				isOverrideAssigneesSelected
			);
			this.setState({personsToAssign, unqualifiedPersons});
		} else {
			// update team if similar project is selected
			const {personsToAssign, unqualifiedPersons} = this.getPersonsToAssign(
				this.props.project,
				selectedSimilarProject,
				isUseEntireCompanyOptionSelected,
				isAddProjectTeamOptionSelected,
				isOverrideAssigneesSelected
			);
			this.setState({personsToAssign, unqualifiedPersons});
		}

		tracking.trackElementClicked('Auto Schedule Modal - similar project selected from unestimated tasks modal');
		trackEvent('Auto Schedule Similar Project', 'Selected');
	}

	onTaskUpdate(taskId, updatedProperties) {
		const updatedPropertiesNames = Object.keys(updatedProperties);
		const userUpdates = {};

		if (_.intersection(updatedPropertiesNames, ['low', 'high', 'estimate']).length > 0) {
			userUpdates.estimatesByUser = true;
		}

		if (updatedPropertiesNames.includes('role')) {
			userUpdates.roleByUser = true;
		}

		if (this.state.tasksWithSuggestionsAndUserUpdates.some(task => task.taskId === taskId)) {
			let taskToUpdate = this.state.tasksWithSuggestionsAndUserUpdates.filter(task => task.taskId === taskId)[0];
			taskToUpdate = {...taskToUpdate, ...updatedProperties, ...userUpdates};
			const updatedState = [
				...this.state.tasksWithSuggestionsAndUserUpdates.filter(task => task.taskId !== taskId),
				taskToUpdate,
			];
			this.setState({tasksWithSuggestionsAndUserUpdates: updatedState});
		} else {
			const updatedState = [
				...this.state.tasksWithSuggestionsAndUserUpdates,
				{taskId, ...updatedProperties, ...userUpdates},
			];
			this.setState({tasksWithSuggestionsAndUserUpdates: updatedState});
		}

		// log change
		const updatedField = Object.keys(updatedProperties)[0];
		const updatedUnestimatedTasksUserUpdates = {
			...this.state.unestimatedTasksUserUpdates,
			[taskId]: _.union([updatedField], this.state.unestimatedTasksUserUpdates[taskId]),
		};
		this.setState({unestimatedTasksUserUpdates: updatedUnestimatedTasksUserUpdates});
	}

	onProjectTeamOptionSelect(option) {
		if (option === 'project_team') {
			Util.localStorageSetItem('isUseProjectTeamOptionSelected', true);
			Util.localStorageSetItem('isUseEntireCompanyOptionSelected', false);
			this.setState({
				isUseProjectTeamOptionSelected: true,
				isUseEntireCompanyOptionSelected: false,
				isAddProjectTeamOptionSelected: false,
			});
			tracking.trackElementClicked('Auto Schedule Modal - use/add project team option selected');
			trackEvent('Auto Schedule Use Project Team Option', 'Selected');
		} else if (option === 'entire_company') {
			Util.localStorageSetItem('isUseProjectTeamOptionSelected', false);
			Util.localStorageSetItem('isUseEntireCompanyOptionSelected', true);
			this.setState({
				isUseProjectTeamOptionSelected: false,
				isUseEntireCompanyOptionSelected: true,
				isAddProjectTeamOptionSelected: false,
			});
			tracking.trackElementClicked('Auto Schedule Modal - use/add entire company option selected');
			trackEvent('Auto Schedule Use Entire Company Option', 'Selected');
		} else if (option === 'similar_project') {
			Util.localStorageSetItem('isUseProjectTeamOptionSelected', true);
			Util.localStorageSetItem('isUseEntireCompanyOptionSelected', false);
			this.setState({
				isUseProjectTeamOptionSelected: false,
				isUseEntireCompanyOptionSelected: false,
				isAddProjectTeamOptionSelected: true,
			});
			tracking.trackElementClicked('Auto Schedule Modal - use/add similar project team option selected');
			trackEvent('Auto Schedule Use Similar Project Team Option', 'Selected');
		}

		// update the selected persons and the ones that are unqualified
		const {personsToAssign, unqualifiedPersons} = this.getPersonsToAssign(
			this.props.project,
			this.state.selectedSimilarProject,
			option === 'entire_company',
			option === 'similar_project',
			this.state.isOverrideAssigneesSelected
		);
		this.setState({personsToAssign, unqualifiedPersons});
	}

	handleProjectStartDateChange(projectStartDate) {
		// projects cannot start in the past
		if (!projectStartDate.isBefore(Moment())) {
			this.setState({projectStartDate});
		}
		tracking.trackEvent(`Auto Schedule Modal - Project Start date updated`);
		trackEvent('Auto Schedule Project Start Date', 'Updated');
	}

	handlePhaseOverlapSetting(value) {
		Util.localStorageSetItem('isAllowPhaseNotOverlapSelected', value);
		this.setState({isAllowPhaseNotOverlapSelected: value});
		tracking.trackElementClicked(
			`Auto Schedule Modal - ${
				value ? 'allow Milesones to overlap option selected' : "don't allow Milesones to overlap option selected"
			}`
		);
		trackEvent('Auto Schedule Allow Phase Overlap', value ? 'Selected' : 'Not Selected');
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {phases, project, roles, tasks} = this.props;
		const {
			isOverrideAssigneesSelected,
			isOverridePhaseDatesSelected,
			phaseSortOrderMap,
			hasPhaseOrderChanged,
			isShowingSimilarProjectModal,
			isShowingPhaseOrderModal,
			isShowingUnestimatedTaskListModal,
			unselectedPhasesIds,
			selectedSimilarProject,
			tasksWithNoRoleOrEstimations,
			isSettingsExpanded,
			isUseProjectTeamOptionSelected,
			isUseEntireCompanyOptionSelected,
			isAddProjectTeamOptionSelected,
			isAllowPhaseNotOverlapSelected,
			isHighPriorityFirstOptionSelected,
			isBlockedTasksOptionSelected,
			roleSuggestionsLoaded,
			lowHighSuggestionsLoaded,
			projectStartDate,
			tasksWithSuggestionsAndUserUpdates,
			skipUnestimatedTasks,
		} = this.state;

		const isEstimatedInHours = project.estimationUnit === ESTIMATION_UNIT.HOURS;
		const showCancel = isShowingSimilarProjectModal;

		if (document.activeElement) {
			document.activeElement.blur();
		}

		let phasesWithNotDoneTasks = phases.filter(phase => {
			const hasNotDoneTask = tasks.some(task => task.phaseId === phase.id && !task.done);
			return hasNotDoneTask;
		});
		let phaseCount = phasesWithNotDoneTasks.length;

		phasesWithNotDoneTasks.sort((a, b) => {
			const aIndex = phaseSortOrderMap.get(a.id) || 0;
			const bIndex = phaseSortOrderMap.get(b.id) || 0;
			if (aIndex < bIndex) return -1;
			if (bIndex < aIndex) return 1;
			return 0;
		});

		// add no phase option if there are tasks with no phase
		if (tasks.some(task => !task.phaseId)) {
			phaseCount++;
			phasesWithNotDoneTasks = phasesWithNotDoneTasks.concat({
				projectId: project.id,
				id: btoa('PhaseType:-1'),
				name: formatMessage({id: 'project_scopes.no-scope'}),
				noPhase: true,
			});
		}

		const roleTooltipText = [
			{
				title: formatMessage({id: 'auto_scheduling.role_tooltip_title'}),
				titleIcon: <AiIcon useNewIcon />,
				list: [formatMessage({id: 'common.task_name'}), formatMessage({id: 'common.task_description'})],
			},
		];

		const content = isShowingSimilarProjectModal ? (
			<div className="auto-scheduling-modal similar-project-modal">
				<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={'48vh'}>
					<div className="warning-description">
						{formatMessage({id: 'auto_scheduling.select_similar_project_description'})}
					</div>
					<div className="similar-project-modal-content">
						<NestedDropdown
							useBlackColor={true}
							placeholder={formatMessage({id: 'auto_scheduling.select_similar_input_default'})}
							showPlaceHolderWhenExpanded
							options={this.getProjectsDropdownOptions(this.props.intl, this.props.viewer.projects.edges)}
							value={selectedSimilarProject ? selectedSimilarProject.name : null}
							projectIdLabel={
								selectedSimilarProject
									? {
											companyProjectId: selectedSimilarProject.companyProjectId,
											projectColor: selectedSimilarProject.projectColor,
									  }
									: null
							}
							onChange={this.onSimilarProjectSelect.bind(this)}
							showArrowWhenCollapsed={true}
							id={'similar-project-dropdown'}
						/>
						<div className="checkbox-row checkbox-row-first">
							<Checkbox
								isChecked={isOverrideAssigneesSelected}
								onChange={() => {
									Util.localStorageSetItem('isOverrideAssigneesSelected', !isOverrideAssigneesSelected);
									const updatedIsOverrideAssigneesSelected = !isOverrideAssigneesSelected;
									this.setState({
										isOverrideAssigneesSelected: updatedIsOverrideAssigneesSelected,
										tasksWithNoRoleOrEstimations: updatedIsOverrideAssigneesSelected
											? tasks.filter(task => !task.roleId || !task.estimateForecast)
											: tasks.filter(
													task =>
														(!task.assignedPersons.length && !task.roleId) || !task.estimateForecast
											  ),
									});
								}}
							/>
							<div className="checkbox-label">
								{formatMessage({id: 'auto_scheduling.change_assignees_option_label'})}
							</div>
							<Info
								medium={true}
								infoMessage={formatMessage({id: 'auto_scheduling.change_current_assignees_info'})}
							/>
						</div>
						<div className="separation-line"></div>
						<div className="more-settings-button">
							<span
								id={'more-settings-button'}
								onClick={() => this.setState({isSettingsExpanded: !isSettingsExpanded})}
							>
								{formatMessage({
									id: isSettingsExpanded
										? 'project_settings.fewer_settings'
										: 'project_settings.more_settings',
								})}
							</span>
							<ExpandIcon
								expanded={isSettingsExpanded}
								onClick={() => this.setState({isSettingsExpanded: !isSettingsExpanded})}
							/>
						</div>

						<CSSTransition in={isSettingsExpanded} timeout={10} classNames="expand">
							<div key="extra-settings" className="extra-settings">
								<div id={'settings-group-project-team'} className="settings-group">
									<div className="category">
										{formatMessage({id: 'scheduling.project_scheduling.project_team'})}
									</div>
									<div className="checkbox-row checkbox-row-first">
										<RadioButton
											onSelected={this.onProjectTeamOptionSelect.bind(this, 'project_team')}
											value={true}
											stateValue={isUseProjectTeamOptionSelected}
										/>
										<div className="checkbox-label">
											{formatMessage({id: 'auto_scheduling.use_project_team_members'})}
										</div>
									</div>
									<div className="checkbox-row">
										<RadioButton
											onSelected={this.onProjectTeamOptionSelect.bind(this, 'entire_company')}
											value={true}
											stateValue={isUseEntireCompanyOptionSelected}
										/>
										<div className="checkbox-label">
											{formatMessage({id: 'auto_scheduling.use_entire_company'})}
										</div>
									</div>
									<div className="checkbox-row">
										<RadioButton
											onSelected={this.onProjectTeamOptionSelect.bind(this, 'similar_project')}
											value={true}
											stateValue={isAddProjectTeamOptionSelected}
											locked={!selectedSimilarProject}
										/>
										<div className={`checkbox-label ${!selectedSimilarProject ? 'locked' : ''}`}>
											{formatMessage({id: 'auto_scheduling.add_project_team_members'})}
										</div>
									</div>
								</div>
								<div id={'settings-group-project-phase'} className="settings-group">
									<div className="category">{formatMessage({id: 'common.phase'})}</div>
									<div className="checkbox-row checkbox-row-first">
										<RadioButton
											onSelected={this.handlePhaseOverlapSetting.bind(this, false)}
											value={true}
											stateValue={!isAllowPhaseNotOverlapSelected}
										/>
										<div className="checkbox-label">
											{formatMessage({id: 'auto_scheduling.allow_phase_overlap'})}
										</div>
									</div>
									<div className="checkbox-row">
										<RadioButton
											onSelected={this.handlePhaseOverlapSetting.bind(this, true)}
											value={true}
											stateValue={isAllowPhaseNotOverlapSelected}
										/>
										<div className="checkbox-label">
											{formatMessage({id: 'auto_scheduling.dont_allow_phase_overlap'})}
										</div>
									</div>
								</div>
								<div id={'settings-group-project-advanced'} className="settings-group">
									<div className="category">{formatMessage({id: 'auto_scheduling.advanced_logic'})}</div>
									<div className="checkbox-row checkbox-row-first">
										<Checkbox
											isChecked={isHighPriorityFirstOptionSelected}
											onChange={() => {
												Util.localStorageSetItem(
													'isHighPriorityFirstOptionSelected',
													!isHighPriorityFirstOptionSelected
												);
												this.setState({
													isHighPriorityFirstOptionSelected: !isHighPriorityFirstOptionSelected,
												});
												tracking.trackElementClicked(
													`Auto Schedule Modal - Auto Schedule High Priority tasks first option selected`
												);
												trackEvent('Auto Schedule High Priority Tasks First Option', 'Selected');
											}}
										/>
										<div className="checkbox-label">
											{formatMessage({id: 'auto_scheduling.highest_priority_first'})}
										</div>
									</div>
									<div className="checkbox-row">
										<Checkbox
											isChecked={isBlockedTasksOptionSelected}
											onChange={() => {
												Util.localStorageSetItem(
													'isBlockedTasksOptionSelected',
													!isBlockedTasksOptionSelected
												);
												this.setState({isBlockedTasksOptionSelected: !isBlockedTasksOptionSelected});
												tracking.trackElementClicked(
													`Auto Schedule Modal - Do not Auto Schedule Blocked Tasks option selected`
												);
												trackEvent(
													'Auto Schedule Do Not Auto Schedule Blocked Tasks Option',
													'Selected'
												);
											}}
										/>
										<div className="checkbox-label">
											{formatMessage({id: 'auto_scheduling.show_and_schedule_blocked_tasks'})}
										</div>
									</div>
								</div>
								<div id={'settings-group-project-startdate'} className="settings-group">
									<div className="category">
										{formatMessage({id: 'insights.component.list.column.projectStartDate'})}
									</div>
									<div className="checkbox-row checkbox-row-first">
										<DatePicker
											startDate={projectStartDate}
											handleDateRangeChange={this.handleProjectStartDateChange.bind(this)}
											datePickerStyle={DATE_PICKER_STYLE.STANDARD}
											isSingleDatePicker={true}
										/>
									</div>
								</div>
							</div>
						</CSSTransition>
					</div>
				</CustomScrollDiv>
			</div>
		) : isShowingPhaseOrderModal ? (
			<div className={'auto-scheduling-modal'}>
				<div className="checkbox-row">
					<RadioButton
						onSelected={() => {
							this.setState({isOverridePhaseDatesSelected: false});
							tracking.trackElementClicked(`Auto Schedule Modal - keep Phases dates option selected`);
							trackEvent('Auto Schedule Keep Phase Dates Option', 'Selected');
						}}
						value={false}
						stateValue={isOverridePhaseDatesSelected}
					/>
					<div className="checkbox-label">{formatMessage({id: 'auto_scheduling.keep_phase_dates_option_label'})}</div>
					<Info
						medium={true}
						infoMessage={formatMessage({
							id: 'auto_scheduling.keep_phase_dates_option_info',
						})}
					/>
				</div>
				<div className="checkbox-row">
					<RadioButton
						onSelected={() => {
							this.setState({isOverridePhaseDatesSelected: true});
							tracking.trackElementClicked(`Auto Schedule Modal - Reschedule Phases dates option selected`);
							trackEvent('Auto Schedule Reschedule Phase Dates Option', 'Selected');
						}}
						value={true}
						stateValue={isOverridePhaseDatesSelected}
					/>
					<div className="checkbox-label">
						{formatMessage({id: 'auto_scheduling.reschedule_phase_dates_option_label'})}
					</div>
					<Info
						medium={true}
						infoMessage={formatMessage({
							id: 'auto_scheduling.reschedule_phase_dates_option_info',
						})}
					/>
				</div>

				<div className="checkbox-row">
					<div className="phase-order-label">{formatMessage({id: 'auto_scheduling.phase_select_and_order'})}</div>
					<Info
						medium={true}
						infoMessage={formatMessage({
							id: 'auto_scheduling.phase_order_info',
						})}
					/>
				</div>
				{hasPhaseOrderChanged && !isOverridePhaseDatesSelected ? (
					<div className="warning">
						<span>{formatMessage({id: 'common.warning'})}:</span>{' '}
						{formatMessage({id: 'auto_scheduling.phase_order_warning'})}
					</div>
				) : null}
				<div className="phase-order-container">
					<DragDropContext onDragEnd={this.onPhaseDragEnd.bind(this)}>
						<Droppable droppableId="subtasks">
							{(provided, snapshot) => (
								<div ref={provided.innerRef} style={{}}>
									{phasesWithNotDoneTasks.map((phase, index) => (
										<Draggable
											key={phase.id}
											draggableId={phase.id}
											index={index}
											isDragDisabled={
												!isOverridePhaseDatesSelected ||
												unselectedPhasesIds.includes(phase.id) ||
												phase.noPhase
											}
										>
											{(provided, snapshot) => (
												<div
													provided={provided}
													ref={provided.innerRef}
													style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
													key={phase.id}
													className={`phase-order-item ${
														!isOverridePhaseDatesSelected || unselectedPhasesIds.includes(phase.id)
															? 'unselected'
															: ''
													}  `}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
												>
													<Checkbox
														isChecked={!unselectedPhasesIds.includes(phase.id)}
														onChange={this.handlePhaseCheckboxClick.bind(this, phase.id)}
													/>
													{!unselectedPhasesIds.includes(phase.id) &&
													phase.id !== -1 &&
													isOverridePhaseDatesSelected ? (
														<svg
															className="drag-handle"
															width="16"
															height="16"
															xmlns="http://www.w3.org/2000/svg"
														>
															<g fill={'white'} fillRule="evenodd">
																<path d="M5 1h2v2H5zM9 1h2v2H9zM5 5h2v2H5zM9 5h2v2H9zM5 9h2v2H5zM9 9h2v2H9zM5 13h2v2H5zM9 13h2v2H9z" />
															</g>
														</svg>
													) : null}
													<svg height="30px" width="420px">
														<polygon
															points="10,0 400,0 410,5 410,30 0,30 0,5"
															style={{
																fill: unselectedPhasesIds.includes(phase.id)
																	? '#e9e9e9'
																	: phase.noPhase
																	? '#fff'
																	: '#6e0fea',
																stroke:
																	phase.noPhase && !unselectedPhasesIds.includes(phase.id)
																		? '#a1a1a1'
																		: '#ffffff',
																strokeWidth: 2,
															}}
														/>
														<text
															x="30"
															y="20"
															fill={`${
																unselectedPhasesIds.includes(phase.id)
																	? '#a1a1a1'
																	: phase.noPhase
																	? 'black'
																	: 'white'
															}`}
														>
															{phase.name}
														</text>
													</svg>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		) : isShowingUnestimatedTaskListModal ? (
			<div
				className={
					'auto-scheduling-modal unestimated-tasks-modal' +
					(!roleSuggestionsLoaded || !lowHighSuggestionsLoaded ? ' ai-loader-active' : '')
				}
			>
				<div className="estimates-project-container">
					<div className="warning-description">
						{formatMessage({id: 'auto_scheduling.unestimated_tasks_projects_dropdown_info'})}
					</div>
					<NestedDropdown
						useBlackColor={true}
						placeholder={formatMessage({id: 'auto_scheduling.select_similar_input_default'})}
						showPlaceHolderWhenExpanded
						options={this.getProjectsDropdownOptions(this.props.intl, this.props.viewer.projects.edges)}
						value={selectedSimilarProject ? selectedSimilarProject.name : null}
						preselectedOption={selectedSimilarProject ? selectedSimilarProject : null}
						projectIdLabel={
							selectedSimilarProject
								? {
										companyProjectId: selectedSimilarProject.companyProjectId,
										projectColor: selectedSimilarProject.projectColor,
								  }
								: null
						}
						onChange={this.onSimilarProjectSelectUnestTasksModal.bind(this)}
						showArrowWhenCollapsed={true}
						id={'similar-project-dropdown'}
					/>
				</div>
				<div className="warning-description">
					{formatMessage({id: 'auto_scheduling.unestimated_tasks_description'})}
				</div>

				{roleSuggestionsLoaded && lowHighSuggestionsLoaded ? (
					<>
						<div className="checkbox-row checkbox-row-first">
							<Checkbox
								isChecked={skipUnestimatedTasks}
								onChange={() => {
									this.setState({
										skipUnestimatedTasks: !skipUnestimatedTasks,
									});
									tracking.trackElementClicked(
										'Auto Schedule Modal - skip unestimated tasks option selected'
									);
									trackEvent('Auto Schedule Skip Unestimated Tasks Option', 'Selected');
								}}
							/>
							<div className="checkbox-label">
								{formatMessage({id: 'auto_scheduling.skip_unestimated_tasks'})}
							</div>
							<Info
								medium={true}
								infoMessage={formatMessage({id: 'auto_scheduling.skip_unestimated_task_info'})}
							/>
						</div>
						<div className={'table-heading-row remove-low-high'}>
							<span className="id">{formatMessage({id: 'common.id'})}</span>
							<span className="name">{formatMessage({id: 'common.name'})}</span>
							<span className="role">
								{formatMessage({id: 'common.role'})}
								<TooltipContainer infoText={roleTooltipText} tooltipDuration={6000}>
									<InformationIcon />
								</TooltipContainer>
							</span>
							<span className="forecast">{formatMessage({id: 'common.estimate'})}</span>
						</div>
						<div className="task-items-container">
							<CustomScrollDiv autoHeight={true} autoHeightMin={1} autoHeightMax={500}>
								{tasksWithNoRoleOrEstimations
									.filter(task => {
										// check if the task's mileston is excluded
										if (task.phaseId) {
											return !unselectedPhasesIds.includes(task.phaseId);
										}
										// if the task does not have a phase check if the noPhase is selected
										return !unselectedPhasesIds.some(
											phaseId => atob(phaseId).replace('PhaseType:', '') === '-1'
										);
									})
									.map(task => {
										const taskWithSuggestionsAndUpdates = tasksWithSuggestionsAndUserUpdates.find(
											t => t.taskId === task.id
										);
										return (
											<TaskItem
												cy={'unestimated-unassigned-task'}
												key={task.id}
												isEstimatedInHours={isEstimatedInHours}
												roles={roles}
												task={task}
												intl={this.props.intl}
												formatMessage={formatMessage}
												roleSuggestion={
													!task.roleId &&
													taskWithSuggestionsAndUpdates &&
													taskWithSuggestionsAndUpdates.role
														? taskWithSuggestionsAndUpdates.role
														: null
												}
												isRoleFromUser={
													taskWithSuggestionsAndUpdates && taskWithSuggestionsAndUpdates.roleByUser
														? taskWithSuggestionsAndUpdates.roleByUser
														: false
												}
												suggestedLow={
													taskWithSuggestionsAndUpdates ? taskWithSuggestionsAndUpdates.low : null
												}
												suggestedHigh={
													taskWithSuggestionsAndUpdates ? taskWithSuggestionsAndUpdates.high : null
												}
												suggestedEstimate={
													taskWithSuggestionsAndUpdates
														? taskWithSuggestionsAndUpdates.estimate
														: null
												}
												isEstimatesFromUser={
													taskWithSuggestionsAndUpdates &&
													taskWithSuggestionsAndUpdates.estimatesByUser
														? taskWithSuggestionsAndUpdates.estimatesByUser
														: false
												}
												onTaskUpdate={this.onTaskUpdate.bind(this)}
												isOverrideAssigneesSelected={isOverrideAssigneesSelected}
												isSkippingUnestimatedTasks={skipUnestimatedTasks}
											/>
										);
									})}
							</CustomScrollDiv>
						</div>
					</>
				) : (
					<AiLoader />
				)}
			</div>
		) : null;

		const modalId = `auto-schedule-modal${
			isShowingSimilarProjectModal
				? '-similar-projects'
				: isShowingPhaseOrderModal
				? '-phase-order'
				: isShowingUnestimatedTaskListModal
				? '-unestimated-tasks'
				: ''
		}`;

		return (
			<GenericModal
				cy={'autoschedule-modal'}
				id={modalId}
				closeModal={this.props.closeModal}
				buttons={[
					{
						text: showCancel ? formatMessage({id: 'common.cancel'}) : formatMessage({id: 'common.back'}),
						color: BUTTON_COLOR.WHITE,
						callback: this.onBack.bind(this),
						preventDefaultClose: !showCancel,
					},
					{
						id: 'auto-schedule-continue-button',
						cy: 'auto-schedule-continue-button',
						text:
							isShowingUnestimatedTaskListModal && !skipUnestimatedTasks
								? formatMessage({id: 'auto_scheduling.save_continue'})
								: formatMessage({id: 'common.continue'}),
						callback: this.onContinue.bind(this),
						preventDefaultClose: true,
						autoScheduleStyle: true,
						disabled:
							(isShowingPhaseOrderModal && phaseCount === unselectedPhasesIds.length) ||
							(isShowingUnestimatedTaskListModal &&
								!skipUnestimatedTasks &&
								(!(roleSuggestionsLoaded && lowHighSuggestionsLoaded) ||
									tasksWithSuggestionsAndUserUpdates.some(task => task.low > task.high))),
					},
				]}
				content={content}
				coverImage={isShowingSimilarProjectModal ? <Nova02Image /> : null}
				headerText={formatMessage({id: 'auto_scheduling.auto_schedule'})}
			/>
		);
	}
}

autoSchedulingModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired,
	phases: PropTypes.array.isRequired,
	tasks: PropTypes.array.isRequired,
	onAutoSchedulingConfig: PropTypes.func.isRequired,
	fetchPersonHeatmapData: PropTypes.func.isRequired,
};

const autoSchedulingModalQuery = graphql`
	query autoSchedulingModal_Query {
		viewer {
			actualPersonId
			component(name: "auto_scheduling_modal")
			...autoSchedulingModal_viewer
		}
	}
`;

export {autoSchedulingModalQuery};

export default injectIntl(
	createFragmentContainer(autoSchedulingModal, {
		viewer: graphql`
			fragment autoSchedulingModal_viewer on Viewer {
				id
				actualPersonId
				language
				projects(first: 100000) {
					edges {
						node {
							status
							id
							name
							projectColor
							companyProjectId
						}
					}
				}
				availableFeatureFlags {
					key
				}
			}
		`,
	})
);
