import {
	getMomentFromCanvasTimelineDate,
	GROUP_TYPE,
	ITEM_TYPE,
	setSimulationMode,
} from '../../canvas-timeline/canvas_timeline_util';
import Util from '../../../../forecast-app/shared/util/util';
import ApplyAutoScheduleChangesMutation from '../../../../mutations/apply_auto_schedule_changes_mutation';
import * as tracking from '../../../../tracking';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {recalculateGroupHeatmapCache} from '../../heatmap/HeatmapLogic';
import {PROJECT_SUB_GROUP_TYPE} from '../../constants';

export const revertSimulationChanges = pageComponent => {
	const {originalItems, groups, simulationChangeMap, project, footerPersons} = pageComponent.state;

	const itemsToRemove = [];
	originalItems.forEach(item => {
		// If the dates were changed, revert them to the initial dates
		if (item.startDate !== item.data.startDate || item.endDate !== item.data.endDate) {
			item.startDate = item.data.startDate;
			item.endDate = item.data.endDate;
			item.resetItemRow();
		}

		// Remove the assigned person from the task, if it has one
		if (item.data && item.data.task && item.data.task.autoSchedulingPerson) {
			item.data.task.autoSchedulingPerson = undefined;
			item.data.task.isOverAllocated = undefined;
		}

		// Revert data
		const startDate = getMomentFromCanvasTimelineDate(item.data.startDate);
		const endDate = getMomentFromCanvasTimelineDate(item.data.endDate);

		if (item.itemType === ITEM_TYPE.TASK) {
			item.data.task.startYear = startDate.year();
			item.data.task.startMonth = startDate.month() + 1;
			item.data.task.startDay = startDate.date();
			item.data.task.deadlineYear = endDate.year();
			item.data.task.deadlineMonth = endDate.month() + 1;
			item.data.task.deadlineDay = endDate.date();
		} else if (item.itemType === ITEM_TYPE.PROJECT_ALLOCATION) {
			item.data.allocation.startYear = startDate.year();
			item.data.allocation.startMonth = startDate.month() + 1;
			item.data.allocation.startDay = startDate.date();
			item.data.allocation.endYear = endDate.year();
			item.data.allocation.endMonth = endDate.month() + 1;
			item.data.allocation.endDay = endDate.date();
		} else if (item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PHASE) {
			const phase = pageComponent.getData().phases.find(phase => phase.id === item.groupId);
			const simulationData = simulationChangeMap.get('phases').get(item.groupId);
			if (simulationData && simulationData.initialHidden) {
				itemsToRemove.push(item);
				phase.startYear = null;
				phase.startMonth = null;
				phase.startDay = null;
				phase.deadlineYear = null;
				phase.deadlineMonth = null;
				phase.deadlineDay = null;
			} else {
				phase.startYear = startDate.year();
				phase.startMonth = startDate.month() + 1;
				phase.startDay = startDate.date();
				phase.deadlineYear = endDate.year();
				phase.deadlineMonth = endDate.month() + 1;
				phase.deadlineDay = endDate.date();
			}
		} else if (item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PROJECT) {
			const project = pageComponent.getData().projects.find(project => project.id === item.groupId);
			const simulationData = simulationChangeMap.get('projects').get(item.groupId);
			if (simulationData && simulationData.initialHidden) {
				itemsToRemove.push(item);
				project.projectStartYear = null;
				project.projectStartMonth = null;
				project.projectStartDay = null;
				project.projectEndYear = null;
				project.projectEndMonth = null;
				project.projectEndDay = null;
			} else if (project) {
				project.projectStartYear = startDate.year();
				project.projectStartMonth = startDate.month() + 1;
				project.projectStartDay = startDate.date();
				project.projectEndYear = endDate.year();
				project.projectEndMonth = endDate.month() + 1;
				project.projectEndDay = endDate.date();
			}
		}
	});

	itemsToRemove.forEach(item => {
		originalItems.splice(originalItems.indexOf(item), 1);
	});

	const projectGroup = groups.find(group => group.id === project.id);
	const teamMembersGroup = projectGroup.groups.find(
		group =>
			group.groupType === GROUP_TYPE.PROJECT_SCHEDULING_PROJECT_SUB_GROUP &&
			group.id &&
			group.id.includes(PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM)
	);
	teamMembersGroup.groups = teamMembersGroup.originalSubGroups;
	delete teamMembersGroup.originalSubGroups;

	// Update heatmap values in cache
	footerPersons.forEach(person => {
		recalculateGroupHeatmapCache(pageComponent, person.id);
	});

	pageComponent.setState({items: originalItems, originalItems: undefined}, () =>
		pageComponent.redrawCanvasTimeline({preventFiltering: false})
	);
};

export const disableSimulationMode = (pageComponent, shouldRevertSimulationChanges) => {
	if (pageComponent.state.simulationMode) {
		if (shouldRevertSimulationChanges) {
			revertSimulationChanges(pageComponent);
			tracking.trackEvent('Cancel Auto Schedule');
			trackEvent('Auto Schedule', 'Cancelled');
		}
		setSimulationMode(false);
		pageComponent.setState({simulationMode: false});
	}
};

export const onApplySimulationChanges = pageComponent => {
	const {simulationChangeMap, footerPersons} = pageComponent.state;
	const personMap = simulationChangeMap.get('persons');
	const taskMap = simulationChangeMap.get('tasks');
	const phaseMap = simulationChangeMap.get('phases');
	const allocationMap = simulationChangeMap.get('allocations');
	const projectMap = simulationChangeMap.get('projects');

	let projectId,
		projectStartYear,
		projectStartMonth,
		projectStartDay,
		projectEndYear,
		projectEndMonth,
		projectEndDay = undefined;

	if (projectMap.size) {
		projectId = projectMap.keys().next().value;
		const project = projectMap.get(projectId);
		if (project.startDate !== null && project.startDate !== undefined) {
			const startDate = getMomentFromCanvasTimelineDate(project.startDate);
			projectStartYear = startDate.year();
			projectStartMonth = startDate.month() + 1;
			projectStartDay = startDate.date();
		}
		if (project.endDate !== null && project.endDate !== undefined) {
			const endDate = getMomentFromCanvasTimelineDate(project.endDate);
			projectEndYear = endDate.year();
			projectEndMonth = endDate.month() + 1;
			projectEndDay = endDate.date();
		}
	}

	const tasks = [];
	const phases = [];
	const allocations = [];

	for (const taskEntry of taskMap.entries()) {
		const id = taskEntry[0];
		const data = taskEntry[1];
		const startDate = getMomentFromCanvasTimelineDate(data.startDate);
		const endDate = getMomentFromCanvasTimelineDate(data.endDate);
		const startYear = startDate.year();
		const startMonth = startDate.month() + 1;
		const startDay = startDate.date();
		const deadlineYear = endDate.year();
		const deadlineMonth = endDate.month() + 1;
		const deadlineDay = endDate.date();
		const assignedPersons = personMap.get(id) || [];
		tasks.push({
			id,
			startYear,
			startMonth,
			startDay,
			deadlineYear,
			deadlineMonth,
			deadlineDay,
			assignedPersons,
		});
	}

	for (const phaseEntry of phaseMap.entries()) {
		const id = phaseEntry[0];
		const data = phaseEntry[1];
		const startDate = getMomentFromCanvasTimelineDate(data.startDate);
		const endDate = getMomentFromCanvasTimelineDate(data.endDate);
		const startYear = startDate.year();
		const startMonth = startDate.month() + 1;
		const startDay = startDate.date();
		const deadlineYear = endDate.year();
		const deadlineMonth = endDate.month() + 1;
		const deadlineDay = endDate.date();
		phases.push({
			id,
			startYear,
			startMonth,
			startDay,
			deadlineYear,
			deadlineMonth,
			deadlineDay,
		});
	}

	for (const allocationEntry of allocationMap.entries()) {
		const id = allocationEntry[0];
		const data = allocationEntry[1];
		const startDate = getMomentFromCanvasTimelineDate(data.startDate);
		const endDate = getMomentFromCanvasTimelineDate(data.endDate);
		const startYear = startDate.year();
		const startMonth = startDate.month() + 1;
		const startDay = startDate.date();
		const endYear = endDate.year();
		const endMonth = endDate.month() + 1;
		const endDay = endDate.date();
		allocations.push({
			id,
			startYear,
			startMonth,
			startDay,
			endYear,
			endMonth,
			endDay,
		});
	}

	const projectPersons = footerPersons.map(footerPerson => {
		return {
			personId: footerPerson.id,
			roleId: footerPerson.roleId,
		};
	});

	Util.CommitMutation(ApplyAutoScheduleChangesMutation, {
		projectId,
		projectStartYear,
		projectStartMonth,
		projectStartDay,
		projectEndYear,
		projectEndMonth,
		projectEndDay,
		tasks,
		phases,
		allocations,
		projectPersons,
	});
	// Update project persons, filter out old and merge in new
	pageComponent.getData().projectPersons = [
		...projectPersons.map(pp => ({
			projectId: projectId,
			personId: pp.personId,
			roleId: pp.roleId,
		})),
		...pageComponent.getData().projectPersons.filter(pp => pp.projectId !== projectId),
	];

	for (const item of pageComponent.state.items) {
		if (item.data && item.data.task && item.data.task.autoSchedulingPerson) {
			item.data.task.autoSchedulingPerson = undefined;
		}
	}

	disableSimulationMode(pageComponent, false);
};
