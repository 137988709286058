import {BUDGET_TYPE} from '../../../constants';
import {
	createInvoiceProjectObject,
	createDataObject,
	addInvoicedAmount,
	addExpensesAmount,
	searchFilter,
	addProjectToData,
} from './InvoicingUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

const prepareTimeMaterial = (projects, company, currency, timeMaterialStartDate, timeMaterialEndDate, isProjectInvoicing) => {
	let data = createDataObject();

	projects.forEach(project => {
		let invoiceProject = createInvoiceProjectObject(project, currency);

		addInvoicedAmount(invoiceProject, project, timeMaterialStartDate, timeMaterialEndDate);
		addExpensesAmount(invoiceProject, project, timeMaterialStartDate, timeMaterialEndDate);

		invoiceProject.uninvoicedMinutes = project.unInvoicedTotal ? project.unInvoicedTotal.unInvoicedMinutes : 0;
		invoiceProject.uninvoicedPrice = project.unInvoicedTotal ? project.unInvoicedTotal.unInvoicedPrice : 0;

		addProjectToData(data, invoiceProject, company, project, isProjectInvoicing);
	});

	return data;
};

const prepareFixedPrice = (projects, company, currency, isProjectInvoicing) => {
	let data = createDataObject();

	projects.forEach(project => {
		let invoiceProject = createInvoiceProjectObject(project, currency);
		addInvoicedAmount(invoiceProject, project);
		addExpensesAmount(invoiceProject, project);
		if (hasFeatureFlag('Expense_Modal_Update_22Q3')) {
			invoiceProject.fixedAmount = invoiceProject.fixedAmountIncludingBillableExpenses;
		}
		invoiceProject.uninvoicedFixedPrice +=
			invoiceProject.fixedAmount - invoiceProject.invoicedAmount - invoiceProject.uninvoicedExpenses;
		addProjectToData(data, invoiceProject, company, project, isProjectInvoicing);
	});

	return data;
};

const prepareRetainer = (projects, company, currency, isProjectInvoicing) => {
	let data = createDataObject();

	projects.forEach(project => {
		let invoiceProject = createInvoiceProjectObject(project, currency);

		addInvoicedAmount(invoiceProject, project);
		addExpensesAmount(invoiceProject, project);

		invoiceProject.uninvoicedMinutes = project.unInvoicedTotal ? project.unInvoicedTotal.unInvoicedMinutes : 0;
		invoiceProject.uninvoicedPrice = project.unInvoicedTotal ? project.unInvoicedTotal.unInvoicedPrice : 0;

		addProjectToData(data, invoiceProject, company, project, isProjectInvoicing);
	});

	return data;
};

export const prepareProjectData = (
	projects,
	company,
	currency,
	timeMaterialStartDate,
	timeMaterialEndDate,
	searchFilterValue,
	isProjectInvoicing
) => {
	let projectData = {
		totalUninvoiced: 0.0,
		totalInvoiced: 0.0,
		time_material: {},
		fixed_price: {
			totalUninvoicedPrice: 0.0,
			totalUninvoicedExpenses: 0.0,
			totalInvoicedAmount: 0.0,
			projects: [],
		},
		retainer: {
			totalUninvoicedMinutes: 0.0,
			totalUninvoicedPrice: 0.0,
			totalUninvoicedExpenses: 0.0,
			totalInvoicedAmount: 0.0,
			projects: [],
		},
	};
	const relevantProjects = projects.filter(project => searchFilter(project, searchFilterValue));

	const timeMaterialProjects = relevantProjects.filter(project => project.budgetType === BUDGET_TYPE.TIME_AND_MATERIALS);
	const fixedPriceProjects = relevantProjects.filter(
		project => project.budgetType === BUDGET_TYPE.FIXED_PRICE || project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2
	);
	const retainerProjects = relevantProjects.filter(project => project.budgetType === BUDGET_TYPE.RETAINER);

	projectData.time_material = prepareTimeMaterial(
		timeMaterialProjects,
		company,
		currency,
		timeMaterialStartDate,
		timeMaterialEndDate,
		isProjectInvoicing
	);
	projectData.fixed_price = prepareFixedPrice(fixedPriceProjects, company, currency, isProjectInvoicing);
	projectData.retainer = prepareRetainer(retainerProjects, company, currency, isProjectInvoicing);

	projectData.totalUninvoiced =
		projectData.time_material.totalUninvoicedPrice +
		projectData.time_material.totalUninvoicedExpenses +
		projectData.fixed_price.totalUninvoicedPrice +
		projectData.fixed_price.totalUninvoicedExpenses +
		projectData.retainer.totalUninvoicedPrice +
		projectData.retainer.totalUninvoicedExpenses;
	projectData.totalInvoiced =
		projectData.time_material.totalInvoicedAmountUnfiltered +
		projectData.fixed_price.totalInvoicedAmountUnfiltered +
		projectData.retainer.totalInvoicedAmountUnfiltered;

	return projectData;
};
