/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type HighLevelBudgetNumbers_company$ref: FragmentReference;
declare export opaque type HighLevelBudgetNumbers_company$fragmentType: HighLevelBudgetNumbers_company$ref;
export type HighLevelBudgetNumbers_company = {|
  +tier: ?TierType,
  +id: string,
  +currency: ?string,
  +$refType: HighLevelBudgetNumbers_company$ref,
|};
export type HighLevelBudgetNumbers_company$data = HighLevelBudgetNumbers_company;
export type HighLevelBudgetNumbers_company$key = {
  +$data?: HighLevelBudgetNumbers_company$data,
  +$fragmentRefs: HighLevelBudgetNumbers_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HighLevelBudgetNumbers_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = 'c77081e59b3f2db9f138a9b36dfaa806';

module.exports = node;
