import React from 'react';
import styled from 'styled-components';
import {FormattedHTMLMessage} from 'react-intl';

import {isSimulationMode} from '../../canvas-timeline/canvas_timeline_util';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';

export const handleAutoSchedulingErrors = (pageComponent, autoSchedulingErrors) => {
	const {formatMessage} = pageComponent.props.intl;
	const AutoScheduleErrorsModalStyled = styled.div`
		.errors-list-wrapper {
			display: flex;
			flex-direction: column;
			font-size: 13px;
			max-width: 550px;
			span {
				margin-bottom: 5px;
			}
			.bold-font-weight {
				font-weight: 700;
			}
		}
	`;
	if (isSimulationMode()) {
		const roleMissingRoleNames = [];
		const roleMissingTaskNames = [];
		const noPhaseTimeRoleNames = [];
		const noPhaseTimeTaskNames = [];
		const noPeriodTimeRoleNames = [];
		const noPeriodTimeTaskNames = [];
		const dateTooLatePhase = [];
		const deactivatedUserAssignedTaskNames = [];
		const deactivatedUserAssignedUserNames = [];
		const PrereqUnscheduledTaskNames = [];
		const noApprovedPhaseNames = [];
		const noRolePersons = [];
		const zeroRemainingTaskNames = [];
		const content = [];
		autoSchedulingErrors.forEach(err => {
			//expand switch when new errorType needs to be handled
			switch (err.errorType) {
				case 'NO_PERSON_WITH_ROLE_FOUND':
					if (err.roleId) {
						const roleMissingRole = pageComponent
							.getData()
							.roles.find(roleMissingRole => roleMissingRole.id === err.roleId);
						if (roleMissingRole && !roleMissingRoleNames.includes(roleMissingRole.name)) {
							roleMissingRoleNames.push(roleMissingRole.name);
						}
						err.affectedTaskIds.forEach(taskId => {
							const task = pageComponent.getData().taskMap.get(taskId);
							if (task && !roleMissingTaskNames.includes(task.name)) {
								roleMissingTaskNames.push(task.name);
							}
						});
					}
					break;
				case 'NO_TIME_IN_THE_PHASE':
					const noPhaseRole = pageComponent.getData().roles.find(noPhaseRole => noPhaseRole.id === err.roleId);
					if (noPhaseRole && !noPhaseTimeRoleNames.includes(noPhaseRole.name)) {
						noPhaseTimeRoleNames.push(noPhaseRole.name);
					}
					err.affectedTaskIds.forEach(taskId => {
						const task = pageComponent.getData().taskMap.get(taskId);
						if (task && !noPhaseTimeTaskNames.includes(task.name)) {
							noPhaseTimeTaskNames.push(task.name);
						}
					});
					break;
				case 'NO_TIME_IN_THE_PERIOD':
					const noPeriodRole = pageComponent.getData().roles.find(noPeriodRole => noPeriodRole.id === err.roleId);
					if (noPeriodRole && !noPeriodTimeRoleNames.includes(noPeriodRole.name)) {
						noPeriodTimeRoleNames.push(noPeriodRole.name);
					}
					err.affectedTaskIds.forEach(taskId => {
						const task = pageComponent.getData().taskMap.get(taskId);
						if (task && !noPeriodTimeTaskNames.includes(task.name)) {
							noPeriodTimeTaskNames.push(task.name);
						}
					});
					break;
				case 'UNSCHEDULED_PREREQUISITES':
					err.affectedTaskIds.forEach(taskId => {
						const task = pageComponent.getData().taskMap.get(taskId);
						if (task && !PrereqUnscheduledTaskNames.includes(task.name)) {
							PrereqUnscheduledTaskNames.push(task.name);
						}
					});
					break;
				case 'START_DATE_TOO_LATE':
					dateTooLatePhase.push(err.details);
					break;
				case 'NO_APPROVED_TASKS':
					err.affectedPhases.forEach(phase => {
						noApprovedPhaseNames.push(phase);
					});
					break;
				case 'NO_ROLE_PERSONS':
					err.affectedPersons.forEach(person => {
						noRolePersons.push(person.fullName);
					});
					break;
				case 'DEACTIVATED_USER_ASSIGNED':
					err.affectedTaskIds.forEach(taskId => {
						const task = pageComponent.getData().taskMap.get(taskId);
						if (task && !deactivatedUserAssignedTaskNames.includes(task.name)) {
							deactivatedUserAssignedTaskNames.push(task.name);
						}
					});
					const deactivatedUser = pageComponent.getData().persons.find(person => person.id === err.recordId);
					const deactivatedUserName = deactivatedUser
						? `${deactivatedUser.firstName} ${deactivatedUser.lastName}`
						: null;
					if (deactivatedUserName !== null && !deactivatedUserAssignedUserNames.includes(deactivatedUserName)) {
						deactivatedUserAssignedUserNames.push(deactivatedUserName);
					}
					break;
				case 'ZERO_REMAINING_TASKS':
					err.zeroRemainingTasks.forEach(task => {
						zeroRemainingTaskNames.push(task.name);
					});
					break;
				default:
				//unknown error
			}
		});
		if (roleMissingTaskNames.length !== 0) {
			const roles = roleMissingRoleNames.join(', ');
			const tasks = roleMissingTaskNames.join(', ');
			content.push(
				<p>
					<FormattedHTMLMessage
						key={0}
						id="auto_scheduling.errors_modal.role_missing_error"
						values={{
							tasks,
							taskCount: roleMissingTaskNames.length,
							roles,
							roleCount: roleMissingRoleNames.length,
						}}
					/>
				</p>
			);
		}
		if (noPhaseTimeTaskNames.length !== 0) {
			const roles = noPhaseTimeRoleNames.join(', ');
			const tasks = noPhaseTimeTaskNames.join(', ');
			content.push(
				<p>
					<FormattedHTMLMessage
						key={1}
						id="auto_scheduling.errors_modal.not_enough_people_with_role_error"
						values={{
							tasks,
							taskCount: noPhaseTimeTaskNames.length,
							roles,
							roleCount: noPhaseTimeRoleNames.length,
						}}
					/>
				</p>
			);
		}
		if (noPeriodTimeTaskNames.length !== 0) {
			const roles = noPeriodTimeRoleNames.join(', ');
			const tasks = noPeriodTimeTaskNames.join(', ');
			content.push(
				<p>
					<FormattedHTMLMessage
						key={2}
						id="auto_scheduling.errors_modal.not_enough_people_with_role_error"
						values={{
							tasks,
							taskCount: noPeriodTimeTaskNames.length,
							roles,
							roleCount: noPeriodTimeRoleNames.length,
						}}
					/>
				</p>
			);
		}
		if (noApprovedPhaseNames.length !== 0) {
			const phases = noApprovedPhaseNames.join(', ');
			content.push(
				<p>
					<FormattedHTMLMessage
						key={0}
						id="auto_scheduling.errors_modal.empty_phases_warning"
						values={{
							phases,
							phaseCount: noApprovedPhaseNames.length,
						}}
					/>
				</p>
			);
		}
		if (PrereqUnscheduledTaskNames.length !== 0) {
			const tasks = PrereqUnscheduledTaskNames.join(', ');
			content.push(
				<p>
					<FormattedHTMLMessage
						key={0}
						id="auto_scheduling.errors_modal.unscheduled_prerequisites"
						values={{
							tasks,
							taskCount: PrereqUnscheduledTaskNames.length,
						}}
					/>
				</p>
			);
		}
		if (noRolePersons.length !== 0) {
			const persons = noRolePersons.join(', ');
			content.push(
				<p>
					<FormattedHTMLMessage
						key={0}
						id="auto_scheduling.errors_modal.no_role_persons_warning"
						values={{
							persons,
							personCount: noRolePersons.length,
						}}
					/>
				</p>
			);
		}
		if (dateTooLatePhase.length !== 0) {
			const phases = dateTooLatePhase.join(', ');
			content.push(
				<p>
					<FormattedHTMLMessage
						key={0}
						id="auto_scheduling.errors_modal.start_date_too_late"
						values={{
							phases,
							phaseCount: phases.length,
						}}
					/>
				</p>
			);
		}
		if (deactivatedUserAssignedTaskNames.length !== 0) {
			const deactivatedUsers = deactivatedUserAssignedUserNames.join(', ');
			const tasks = deactivatedUserAssignedTaskNames.join(', ');
			content.push(
				<p>
					<FormattedHTMLMessage
						key={3}
						id="auto_scheduling.errors_modal.deactivated_user_assigned_error"
						values={{
							tasks,
							taskCount: deactivatedUserAssignedTaskNames.length,
							deactivatedUsers,
							deactivatedUsersCount: deactivatedUserAssignedUserNames.length,
						}}
					/>
				</p>
			);
		}
		if (zeroRemainingTaskNames.length !== 0) {
			const tasksNamesString = zeroRemainingTaskNames.join(', ');
			content.push(
				<FormattedHTMLMessage
					key={3}
					id="auto_scheduling.errors_modal.zero_remaining_tasks_error"
					values={{
						taskCount: zeroRemainingTaskNames.length,
						tasks: tasksNamesString,
					}}
				/>
			);
		}

		showModal({
			type: MODAL_TYPE.GENERIC,
			headerText: formatMessage({id: 'auto_scheduling.auto_schedule'}),
			content: (
				<AutoScheduleErrorsModalStyled>
					<div className="errors-list-wrapper">{content}</div>
				</AutoScheduleErrorsModalStyled>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.filter-close'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
					callback: () => {
						pageComponent.setState({autoSchedulingErrors: []});
					},
				},
			],
		});
	}
};
