import React from 'react';
import {ProjectPortfolioReportChartsQueryRenderer} from '../ProjectPortfolioReportChartsQueryRenderer';
import {FlexRow, OutlineBox, PageWrapper, Section} from '../ProjectPortfolioReport.styled';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {ProjectPortfolioMonthByMonthTable} from './ProjectPortfolioMonthByMonthTable';
import {ProjectPortfolioMonthByMonthTitle} from './ProjectPortfolioMonthByMonthTitle';

export const ProjectPortfolioReportMonthByMonthPage = ({
	selectedSingleValue,
	filterValue,
	selectedDateRange,
	hasFinance,
	setCsvDownloadFunction,
	hasBaseline,
	theEyeOptionsNestedTree,
	groupBy,
}) => {
	return (
		<PageWrapper>
			<CustomScrollDiv horizontalandverticalcontent>
				<ProjectPortfolioReportChartsQueryRenderer
					selectedSingleValue={selectedSingleValue}
					selectedDateRange={selectedDateRange}
					filterValue={filterValue}
					hasFinance={hasFinance}
				/>

				<Section marginTop={28} data-userpilot={'project-portfolio-month-by-month-table'}>
					<FlexRow growable>
						<OutlineBox growable marginRight={24} marginLeft={24} marginBottom={24}>
							<ProjectPortfolioMonthByMonthTitle selectedSingleValue={selectedSingleValue} />
							<ProjectPortfolioMonthByMonthTable
								filters={filterValue}
								selectedDateRange={selectedDateRange}
								selectedSingleValue={selectedSingleValue}
								setCsvDownloadFunction={setCsvDownloadFunction}
								hasBaseline={hasBaseline}
								theEyeOptionsNestedTree={theEyeOptionsNestedTree}
								groupBy={groupBy}
							/>
						</OutlineBox>
					</FlexRow>
				</Section>
			</CustomScrollDiv>
		</PageWrapper>
	);
};
