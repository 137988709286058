import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Insight, Tooltip, FlexRow } from '@forecast-it/design-system';
import Util from '../../forecast-app/shared/util/util';
import { TooltipFormulaRenderer } from '../TooltipFormulaRenderer';
const getExpenseTotals = expenses => {
    const expenseTotals = {
        count: 0,
        revenue: 0,
        cost: 0,
        profit: 0,
        margin: 0.0,
        markup: 0.0,
    };
    for (const expense of expenses) {
        expenseTotals.count += 1;
        if (expense.approved) {
            const quantity = expense.quantity || 0;
            expenseTotals.revenue += quantity * (expense.priceBaseCurrency || 0);
            expenseTotals.cost += quantity * (expense.costBaseCurrency || 0);
        }
    }
    expenseTotals.profit = expenseTotals.revenue - expenseTotals.cost;
    expenseTotals.margin = expenseTotals.revenue !== 0 ? expenseTotals.profit / expenseTotals.revenue : 0;
    expenseTotals.markup = expenseTotals.cost !== 0 ? expenseTotals.profit / expenseTotals.cost : 0;
    return expenseTotals;
};
export const ExpenseManagementTotals = ({ expenses, currency }) => {
    const intl = useIntl();
    const formatMessage = intl.formatMessage;
    const currencySymbol = Util.GetCurrencySymbol(currency);
    const expenseTotals = useMemo(() => getExpenseTotals(expenses), [expenses]);
    return (React.createElement(FlexRow, { alignItems: 'flex-start', "data-cy": 'summary-cards' },
        React.createElement(Insight, { height: 'm' },
            React.createElement(Insight.Section, { "data-cy": 'count', title: formatMessage({ id: 'expense_management.total_expenses' }), tooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'expense_management.total_expenses' }), details: formatMessage({ id: 'expense_management.total_expenses_tooltip' }) }), value: expenseTotals.count.toString(), size: "l" })),
        React.createElement(Insight, { height: 'm' },
            React.createElement(Insight.Section, { "data-cy": 'revenue', title: formatMessage({ id: 'project_budget.revenue' }), tooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'project_budget.revenue' }), details: formatMessage({ id: 'expense_management.revenue_tooltip' }) }), value: Util.getFormattedNumberWithCurrency(currencySymbol, expenseTotals.revenue, intl), size: "l" })),
        React.createElement(Insight, { height: 'm' },
            React.createElement(Insight.Section, { "data-cy": 'cost', title: formatMessage({ id: 'common.cost' }), tooltip: React.createElement(Tooltip.Item, { title: formatMessage({ id: 'common.cost' }), details: formatMessage({ id: 'expense_management.cost_tooltip' }) }), value: Util.getFormattedNumberWithCurrency(currencySymbol, expenseTotals.cost, intl), size: "l" })),
        React.createElement(Insight, { height: 'm' },
            React.createElement(Insight.Section, { "data-cy": 'profit', title: formatMessage({ id: 'common.profit' }), tooltip: React.createElement(TooltipFormulaRenderer, { items: [
                        {
                            title: formatMessage({ id: 'common.profit' }),
                            description: formatMessage({ id: 'expense_management.profit_tooltip' }),
                            details: [
                                formatMessage({ id: 'project_budget.revenue' }),
                                '-',
                                formatMessage({ id: 'common.cost' }),
                            ],
                        },
                    ] }), value: Util.getFormattedNumberWithCurrency(currencySymbol, expenseTotals.profit, intl), size: "l" })),
        React.createElement(Insight, { height: 'm' },
            React.createElement(Insight.Section, { "data-cy": 'margin', title: formatMessage({ id: 'common.margin' }), tooltip: React.createElement(TooltipFormulaRenderer, { items: [
                        {
                            title: formatMessage({ id: 'common.margin' }),
                            description: formatMessage({ id: 'expense_management.margin_tooltip' }),
                            details: [
                                formatMessage({ id: 'common.profit' }),
                                '/',
                                formatMessage({ id: 'project_budget.revenue' }),
                                'x',
                                '100',
                            ],
                        },
                    ] }), value: Util.getFormattedPercentage(expenseTotals.margin, intl), size: "l" })),
        React.createElement(Insight, { height: 'm' },
            React.createElement(Insight.Section, { "data-cy": 'markup', title: formatMessage({ id: 'common.markup' }), tooltip: React.createElement(TooltipFormulaRenderer, { items: [
                        {
                            title: formatMessage({ id: 'common.markup' }),
                            description: formatMessage({ id: 'expense_management.markup_tooltip' }),
                            details: [
                                formatMessage({ id: 'common.profit' }),
                                '/',
                                formatMessage({ id: 'common.cost' }),
                                'x',
                                '100',
                            ],
                        },
                    ] }), value: Util.getFormattedPercentage(expenseTotals.markup, intl), size: "l" }))));
};
