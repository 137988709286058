import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {injectIntl, FormattedMessage} from 'react-intl';
import Moment from 'moment';
import Calendar from '../../../forecast-app/shared/components/date-picker/calendar';
import DatePickerButton from './date_picker_button';

class DateRangePicker extends Component {
	constructor(props) {
		super(props);
		const viewedDates = this.getViewedDates(props.startDate, props.endDate);
		this.state = {
			showCalendar: false,
			selectingStartDate: true,
			viewedMonthFirst: viewedDates.viewedMonthFirst,
			viewedMonthSecond: viewedDates.viewedMonthSecond,
			viewedYearFirst: viewedDates.viewedYearFirst,
			viewedYearSecond: viewedDates.viewedYearSecond,
			startDate: props.startDate ? props.startDate.clone() : null,
			endDate: props.endDate ? props.endDate.clone() : null,
			hoveredDate: null,
			firstCalendarRefsMap: null,
			secondCalendarRefsMap: null,
			cancelUpdate: false,
			keyCodeUsed: null,
			previouslyFocusedDate: null,
			disabledDates: props.disabledDates,
		};
		this.collapseCalendar = this.collapseCalendar.bind(this);
	}

	componentDidMount() {
		this.offset = this.date_range_picker_v2.getBoundingClientRect();
		document.addEventListener('scroll', this.collapseCalendar, true);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		// if component receives new dates from props update state
		if (
			(nextProps.startDate && !this.props.startDate) ||
			(!nextProps.startDate && this.props.startDate) ||
			(nextProps.startDate && this.props.startDate && !nextProps.startDate.isSame(this.props.startDate)) ||
			(nextProps.endDate && !this.props.endDate) ||
			(!nextProps.endDate && this.props.endDate) ||
			(nextProps.endDate && this.props.endDate && !nextProps.endDate.isSame(this.props.endDate))
		) {
			this.setState({startDate: nextProps.startDate, endDate: nextProps.endDate});
		}
	}
	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// if caused by pressing escapse dont update
		if (!nextState.showCalendar && this.state.showCalendar && !nextState.cancelUpdate) {
			if (nextState.startDate && nextState.endDate) {
				this.props.handleDateRangeChange(nextState.startDate, nextState.endDate);
			} else {
				this.setState({startDate: nextProps.startDate, endDate: nextProps.endDate});
			}
		}
		if (nextState.cancelUpdate) {
			this.setState({cancelUpdate: false, startDate: nextProps.startDate, endDate: nextProps.endDate});
		}
	}

	componentDidUpdate() {
		this.offset = this.date_range_picker_v2.getBoundingClientRect();
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.collapseCalendar, true);
	}

	collapseCalendar() {
		this.setState({showCalendar: false, selectingStartDate: true});
	}

	resetPreviouslyFocusedDate() {
		this.setState({previouslyFocusedDate: null, keyCodeUsed: null});
	}

	getViewedDates(startDate, endDate) {
		let viewedMonthFirst, viewedMonthSecond, viewedYearFirst, viewedYearSecond;
		if (startDate) {
			viewedMonthFirst = startDate.clone().month();
			viewedYearFirst = startDate.clone().year();
			const nextMonthDate = startDate.clone().startOf('month').add(1, 'month');
			viewedMonthSecond = nextMonthDate.month();
			viewedYearSecond = nextMonthDate.year();
		} else if (endDate) {
			const previousMonthDate = endDate.clone().startOf('month').add(-1, 'month');
			viewedMonthFirst = previousMonthDate.month();
			viewedYearFirst = previousMonthDate.year();
			viewedMonthSecond = endDate.clone().month();
			viewedYearSecond = endDate.clone().year();
		} else {
			//no dates select current month and current month + 1
			viewedMonthFirst = Moment().month();
			viewedYearFirst = Moment().year();
			const date = Moment().startOf('month').add(1, 'month');
			viewedMonthSecond = date.month();
			viewedYearSecond = date.year();
		}
		const viewedDates = {
			viewedMonthFirst,
			viewedYearFirst,
			viewedMonthSecond,
			viewedYearSecond,
		};
		return viewedDates;
	}
	toggleCalendar = e => {
		e.preventDefault();
		e.stopPropagation();
		const newState = {
			showCalendar: !this.state.showCalendar,
			selectingStartDate: true,
		};
		this.setState(newState);
	};

	handleDateClick(moment) {
		let startDate, endDate, hoveredDate, selectingStartDate, showCalendar;
		if (this.state.selectingStartDate) {
			if (this.state.endDate) {
				if (moment.isAfter(this.state.endDate)) {
					if (!this.state.startDate) {
						startDate = this.state.endDate;
						endDate = moment;
					} else {
						startDate = moment;
						endDate = moment;
					}
				} else {
					//Close Calendars
					startDate = moment;
					hoveredDate = null;
				}
			} else {
				startDate = moment;
				hoveredDate = null;
			}
			selectingStartDate = false;
		} else {
			if (this.state.startDate && this.state.endDate) {
				if (moment.isBefore(this.state.startDate)) {
					startDate = moment;
					selectingStartDate = false;
				} else {
					endDate = moment;
					selectingStartDate = false;
					showCalendar = false;
				}
				hoveredDate = null;
			} else if (this.state.startDate && moment.isBefore(this.state.startDate)) {
				startDate = moment;
				endDate = this.state.startDate;
				selectingStartDate = false;
				hoveredDate = null;
			} else {
				endDate = moment;
				hoveredDate = null;
				selectingStartDate = true;
				showCalendar = !this.state.startDate;
			}
		}
		const newState = {};
		if (startDate !== undefined) {
			newState.startDate = startDate;
		}
		if (endDate !== undefined) {
			newState.endDate = endDate;
		}
		if (hoveredDate !== undefined) {
			newState.hoveredDate = hoveredDate;
		}
		if (showCalendar !== undefined) {
			newState.showCalendar = showCalendar;
		}
		newState.selectingStartDate = selectingStartDate;
		this.setState(newState);
	}

	addContiguousDate(moment, addAsStartDate) {
		if (addAsStartDate) {
			this.setState({startDate: moment});
			if (this.state.endDate) {
			}
		} else {
			this.setState({endDate: moment});
		}
	}

	viewPreviousMonth(keyCodeUsed, previouslyFocusedDate) {
		let viewedMonthFirst = this.state.viewedMonthFirst,
			viewedMonthSecond = this.state.viewedMonthSecond,
			viewedYearFirst = this.state.viewedYearFirst,
			viewedYearSecond = this.state.viewedYearSecond;
		if (viewedMonthFirst === 0) {
			viewedMonthFirst = 11;
			viewedYearFirst--;
		} else {
			viewedMonthFirst--;
		}
		if (viewedMonthSecond === 0) {
			viewedMonthSecond = 11;
			viewedYearSecond--;
		} else {
			viewedMonthSecond--;
		}
		this.setState({
			viewedMonthFirst,
			viewedMonthSecond,
			viewedYearFirst,
			viewedYearSecond,
			previouslyFocusedDate,
			keyCodeUsed,
		});
	}

	viewNextMonth(keyCodeUsed, previouslyFocusedDate) {
		let viewedMonthFirst = this.state.viewedMonthFirst,
			viewedMonthSecond = this.state.viewedMonthSecond,
			viewedYearFirst = this.state.viewedYearFirst,
			viewedYearSecond = this.state.viewedYearSecond;
		if (viewedMonthFirst === 11) {
			viewedMonthFirst = 0;
			viewedYearFirst++;
		} else {
			viewedMonthFirst++;
		}
		if (viewedMonthSecond === 11) {
			viewedMonthSecond = 0;
			viewedYearSecond++;
		} else {
			viewedMonthSecond++;
		}
		this.setState({
			viewedMonthFirst,
			viewedMonthSecond,
			viewedYearFirst,
			viewedYearSecond,
			previouslyFocusedDate,
			keyCodeUsed,
		});
	}

	viewNextYear(keyCodeUsed, previouslyFocusedDate) {
		this.setState({
			viewedYearFirst: this.state.viewedYearFirst + 1,
			viewedYearSecond: this.state.viewedYearSecond + 1,
			keyCodeUsed,
			previouslyFocusedDate,
		});
	}
	viewPreviousYear(keyCodeUsed, previouslyFocusedDate) {
		this.setState({
			viewedYearFirst: this.state.viewedYearFirst - 1,
			viewedYearSecond: this.state.viewedYearSecond - 1,
			keyCodeUsed,
			previouslyFocusedDate,
		});
	}

	onDayMouseEnter(day) {
		this.setState({hoveredDate: day});
	}

	onTableBodyMouseLeave() {
		this.setState({hoveredDate: null});
	}

	setRefs(refsMap, isFirstCalendar) {
		if (isFirstCalendar) {
			this.setState({firstCalendarRefsMap: refsMap});
		} else {
			this.setState({secondCalendarRefsMap: refsMap});
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		// return if element being focused is part of calendar
		if (
			newTarget &&
			(newTarget.className === 'previous-control' ||
				newTarget.className === 'next-control' ||
				newTarget.className === 'clear-button' ||
				newTarget.className.includes('calendars-wrapper') ||
				newTarget.className.includes('date-picker-button') ||
				newTarget.className.includes('calendar-day'))
		)
			return;
		const newState = {showCalendar: false};
		this.setState(newState);
	}

	handleKeyDown(e) {
		// close calendar on escapse click
		if (e && e.keyCode === 27) {
			const newState = {showCalendar: false, cancelUpdate: true};
			this.setState(newState);
		} else if (
			e &&
			e.keyCode === 13 &&
			this.state.selectingStartDate &&
			((this.state.startDate && !this.props.startDate) ||
				(!this.state.startDate && this.props.startDate) ||
				(this.state.startDate && this.props.startDate && !this.props.startDate.isSame(this.state.startDate)))
		) {
			// if both start and end date selected and different from initial value enter works as confirm button. Save changes and close calendar
			this.setState({showCalendar: false});
		}
	}

	clearDates(e) {
		e.stopPropagation();
		this.props.handleDateRangeChange(null, null);
		this.setState({showCalendar: false, startDate: null, endDate: null});
	}

	render() {
		const {formatMessage} = this.props.intl;
		const style = {};
		const leftOffset = this.props.calendarOffsetX ? this.props.calendarOffsetX : 0;
		if (this.offset) {
			if (this.offset.top + 312 > window.innerHeight) {
				style.bottom = window.innerHeight - this.offset.top + 15;
			} else {
				style.top = this.offset.top + this.offset.height + 15;
			}
			style.left = this.offset.left + leftOffset;
		}
		const {clearable} = this.props;
		return (
			<div
				className={'date-range-picker-wrapper-new' + (this.props.useCompactStyle ? ' compact' : '')}
				ref={div => (this.date_range_picker_v2 = div)}
				id="date_range_picker_v2"
			>
				<DatePickerButton
					cy={this.props.cy}
					onClick={this.toggleCalendar.bind(this)}
					startDate={
						this.state.hoveredDate &&
						(this.state.selectingStartDate ||
							(this.state.startDate && this.state.startDate.isAfter(this.state.hoveredDate)))
							? this.state.hoveredDate &&
							  !this.state.startDate &&
							  this.state.endDate &&
							  this.state.endDate.isBefore(this.state.hoveredDate)
								? this.state.endDate
								: this.state.hoveredDate
							: this.state.startDate
					}
					endDate={
						!this.state.selectingStartDate &&
						this.state.hoveredDate &&
						(!this.state.startDate || !this.state.startDate.isAfter(this.state.hoveredDate))
							? this.state.hoveredDate
							: !this.state.startDate &&
							  this.state.endDate &&
							  this.state.hoveredDate &&
							  this.state.hoveredDate.isAfter(this.state.endDate)
							? this.state.hoveredDate
							: !this.state.endDate && this.state.startDate && !this.state.selectingStartDate
							? this.state.startDate
							: this.state.endDate
					}
					isDateRangePicker={true}
					locked={this.props.locked}
					onBlur={this.props.handleBlur}
					label={this.props.label}
				/>
				{this.state.showCalendar
					? ReactDOM.createPortal(
							<div className={'calendars-dropdown-wrapper' + (clearable ? ' clearable' : '')} style={style}>
								<div
									className="calendars-wrapper"
									tabIndex="0"
									onBlur={this.handleBlur.bind(this)}
									ref={div => (this.calendars_wrapper = div)}
									onKeyDown={this.handleKeyDown.bind(this)}
								>
									{this.props.hidePointerArrow ? null : (
										<div
											className="triangle-up"
											style={
												this.props.calendarOffsetX
													? {
															left: this.state.selectingStartDate
																? 25 - this.props.calendarOffsetX + 'px'
																: 138 - this.props.calendarOffsetX + 'px',
													  }
													: {left: this.state.selectingStartDate ? '25px' : '138px'}
											}
										>
											<svg width="24" height="10">
												<polygon
													style={{
														fill: 'white',
														stroke: '#e6e6e6',
														strokeWidth: 1,
														fillRule: 'evenodd',
													}}
													points="0,10 12,0 24,10"
												/>
											</svg>
											<div className="border-cover" />
										</div>
									)}
									<Calendar
										isDateRangePicker={true}
										first={true}
										startDate={this.state.startDate}
										endDate={this.state.endDate}
										selectingStartDate={this.state.selectingStartDate}
										onDateClick={this.handleDateClick.bind(this)}
										viewedMonth={this.state.viewedMonthFirst}
										viewedYear={this.state.viewedYearFirst}
										viewPreviousMonth={this.viewPreviousMonth.bind(this)}
										onDayMouseEnter={this.onDayMouseEnter.bind(this)}
										onTableBodyMouseLeave={this.onTableBodyMouseLeave.bind(this)}
										hoveredDate={this.state.hoveredDate}
										refsMap={this.state.secondCalendarRefsMap}
										setRefs={this.setRefs.bind(this)}
										addContiguousDate={this.addContiguousDate.bind(this)}
										previouslyFocusedDate={this.state.previouslyFocusedDate}
										resetPreviouslyFocusedDate={this.resetPreviouslyFocusedDate.bind(this)}
										keyCodeUsed={this.state.keyCodeUsed}
										viewNextYear={this.viewNextYear.bind(this)}
										viewPreviousYear={this.viewPreviousYear.bind(this)}
										disabledDates={this.state.disabledDates}
									/>
									<div className="spacer" />
									<Calendar
										isDateRangePicker={true}
										first={false}
										startDate={this.state.startDate}
										endDate={this.state.endDate}
										selectingStartDate={this.state.selectingStartDate}
										onDateClick={this.handleDateClick.bind(this)}
										viewedMonth={this.state.viewedMonthSecond}
										viewedYear={this.state.viewedYearSecond}
										viewNextMonth={this.viewNextMonth.bind(this)}
										onDayMouseEnter={this.onDayMouseEnter.bind(this)}
										onTableBodyMouseLeave={this.onTableBodyMouseLeave.bind(this)}
										hoveredDate={this.state.hoveredDate}
										refsMap={this.state.firstCalendarRefsMap}
										setRefs={this.setRefs.bind(this)}
										addContiguousDate={this.addContiguousDate.bind(this)}
										previouslyFocusedDate={this.state.previouslyFocusedDate}
										resetPreviouslyFocusedDate={this.resetPreviouslyFocusedDate.bind(this)}
										keyCodeUsed={this.state.keyCodeUsed}
										viewNextYear={this.viewNextYear.bind(this)}
										viewPreviousYear={this.viewPreviousYear.bind(this)}
										disabledDates={this.state.disabledDates}
									/>
								</div>
								{clearable ? (
									<button className="clear-button" onClick={this.clearDates.bind(this)}>
										{formatMessage({id: 'common.clear_date'})}
									</button>
								) : null}
								{this.props.startFrom || this.props.endFrom ? (
									<div className="dates-from">
										<span className="star">*</span>
										<FormattedMessage
											id="card_modal.dates_from"
											values={{name: this.props.startFrom || this.props.endFrom}}
										/>
									</div>
								) : null}
							</div>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</div>
		);
	}
}
DateRangePicker.propTypes = {
	startDate: PropTypes.object,
	endDate: PropTypes.object,
	startFrom: PropTypes.string,
	endFrom: PropTypes.string,
	handleDateRangeChange: PropTypes.func,
	locked: PropTypes.bool,
	calendarOffsetX: PropTypes.number,
	hideLabel: PropTypes.bool,
	customBackgroundColor: PropTypes.string,
	whiteText: PropTypes.bool,
	showEmptyDate: PropTypes.bool,
	handleBlur: PropTypes.func,
	clearable: PropTypes.bool,
	useCompactStyle: PropTypes.bool,
	hidePointerArrow: PropTypes.bool,
	label: PropTypes.string,
	disabledDates: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};
export default injectIntl(DateRangePicker);
