/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type projectRelayWrapper_viewer$ref: FragmentReference;
declare export opaque type projectRelayWrapper_viewer$fragmentType: projectRelayWrapper_viewer$ref;
export type projectRelayWrapper_viewer = {|
  +company: ?{|
    +currency: ?string,
    +allProjects: ?{|
      +pageInfo: {|
        +endCursor: ?string,
        +hasNextPage: boolean,
      |},
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +fullAccessToProject: ?boolean,
          +readOnlyAccess: ?boolean,
          +totalMinutesRegistered: ?number,
          +id: string,
          +name: ?string,
          +projectColor: ?string,
          +companyProjectId: ?number,
          +client: ?{|
            +name: ?string
          |},
          +projectPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +role: ?{|
                  +name: ?string
                |},
                +person: ?{|
                  +fullName: ?string,
                  +role: ?{|
                    +name: ?string
                  |},
                |},
              |}
            |}>
          |},
        |}
      |}>,
    |},
  |},
  +$refType: projectRelayWrapper_viewer$ref,
|};
export type projectRelayWrapper_viewer$data = projectRelayWrapper_viewer;
export type projectRelayWrapper_viewer$key = {
  +$data?: projectRelayWrapper_viewer$data,
  +$fragmentRefs: projectRelayWrapper_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "validateAccess",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "projectRelayWrapper_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "pageSize"
            },
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "validateAccess",
              "value": false
            }
          ],
          "concreteType": "ProjectTypeConnection",
          "kind": "LinkedField",
          "name": "allProjects",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullAccessToProject",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "readOnlyAccess",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        (v0/*: any*/)
                      ],
                      "kind": "ScalarField",
                      "name": "totalMinutesRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "projectColor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "companyProjectId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Client",
                      "kind": "LinkedField",
                      "name": "client",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "projectPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ProjectPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "fullName",
                                      "storageKey": null
                                    },
                                    (v3/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '731d3f325b75131b9bce2a645c599faf';

module.exports = node;
