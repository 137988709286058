import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

export const PageTitle = ({children}) => {
	return <TitleStyle>{children}</TitleStyle>;
};

PageTitle.propTypes = {
	children: PropTypes.string.isRequired,
};

const TitleStyle = Styled.div`
    padding: 0;
    margin: 0;
    color: #535353;
    font-size: 24;
    font-weight: 600;
`;
