import React from 'react';
import {FormattedHTMLMessage} from 'react-intl';

export const progressTooltip = ({formatMessage}) => [
	{
		header: formatMessage({id: 'project_budget.if_you_estimate_tasks'}),
	},
	{
		title: formatMessage({id: 'project_budget.done_scope'}),
		details: [
			formatMessage({id: 'project_budget.done_forecast'}),
			'+',
			formatMessage({id: 'project_budget.time_registrations_not_done'}),
		],
	},
	{
		title: formatMessage({id: 'common.total-scope'}),
		details: [
			formatMessage({id: 'project_budget.done_forecast'}),
			'+',
			formatMessage({id: 'project_budget.time_registrations_not_done'}),
			'+',
			formatMessage({id: 'common.remaining'}),
		],
	},
	{
		title: formatMessage({id: 'common.progress'}),
		details: [formatMessage({id: 'project_budget.done_scope'}), '/', formatMessage({id: 'common.total-scope'}), 'x', '100'],
	},
	{
		header: <FormattedHTMLMessage id="project_budget.if_you_do_not_estimate_tasks" />,
	},
	{
		title: formatMessage({id: 'common.progress'}),
		details: [
			formatMessage({id: 'project_budget.done_tasks'}),
			'/',
			formatMessage({id: 'project_budget.total_tasks'}),
			'x',
			'100',
		],
	},
];

export const fixedPriceTooltip = ({formatMessage}) => [
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [formatMessage({id: 'common.fixed_price'})],
	},
	{
		title: formatMessage({id: 'common.cost'}),
		details: [formatMessage({id: 'insights.component.list.column.costForecast'})],
	},
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'common.fixed_price'}),
			'-',
			formatMessage({id: 'insights.component.list.column.costForecast'}),
		],
	},
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.fixed_price_profit'}),
			'/',
			formatMessage({id: 'project_budget.fixed_price_revenue'}),
			'X',
			'100',
		],
	},
];

export const baselineTooltip = ({formatMessage}) => [
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [
			formatMessage({id: 'common.total_revenue'}),
			'-',
			formatMessage({id: 'common.role'}),
			'x',
			formatMessage({id: 'common.days'}),
			'x',
			formatMessage({id: 'common.rate-card'}),
		],
	},
	{
		title: formatMessage({id: 'common.cost'}),
		details: [
			formatMessage({id: 'common.role'}),
			'x',
			formatMessage({id: 'common.days'}),
			'x',
			formatMessage({id: 'project_budget.internal_cost'}),
		],
	},
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'project_budget.baseline_revenue'}),
			'-',
			formatMessage({id: 'project_budget.baseline_cost'}),
		],
	},
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.baseline_profit'}),
			'/',
			formatMessage({id: 'project_budget.baseline_revenue'}),
			'X',
			'100',
		],
	},
];

export const actualPriceTooltip = ({formatMessage}) => {
	const tooltip = [];
	tooltip.push(
		{
			title: formatMessage({id: 'project_budget.revenue'}),
			details: [
				formatMessage({id: 'project_budget.all_billable_time_entries'}),
				'x',
				formatMessage({id: 'common.rate-card'}),
				'+',
				formatMessage({id: 'project_budget.price_on_actual_billable_expenses'}),
			],
		},
		{
			title: formatMessage({id: 'common.cost'}),
			details: [
				formatMessage({id: 'project_budget.all_time_entries'}),
				'x',
				formatMessage({id: 'project_budget.internal_cost'}),
				'+',
				formatMessage({id: 'project_budget.cost_on_all_actual_expenses'}),
			],
		},
		{
			title: formatMessage({id: 'common.profit'}),
			details: [
				formatMessage({id: 'project_budget.actual_revenue'}),
				'-',
				formatMessage({id: 'insights.component.list.column.actualCost'}),
			],
		},
		{
			title: formatMessage({id: 'common.margin'}),
			details: [
				formatMessage({id: 'project_budget.actual_profit'}),
				'/',
				formatMessage({id: 'project_budget.actual_revenue'}),
				'X',
				'100',
			],
		}
	);
	return tooltip;
};

export const planPriceTooltip = ({formatMessage}) => {
	const tooltip = [];
	tooltip.push(
		{
			title: formatMessage({id: 'project_budget.revenue'}),
			details: [
				formatMessage({id: 'project_budget.all_billable_forecast_hours'}),
				'x',
				formatMessage({id: 'common.rate-card'}),
				'+',
				formatMessage({id: 'project_budget.price_on_all_planned_billable_expenses'}),
			],
		},
		{
			title: formatMessage({id: 'common.cost'}),
			details: [
				formatMessage({id: 'project_budget.all_forecast_hours'}),
				'x',
				formatMessage({id: 'project_budget.internal_cost'}),
				'+',
				formatMessage({id: 'project_budget.cost_on_all_planned_expenses'}),
			],
		},
		{
			title: formatMessage({id: 'common.profit'}),
			details: [
				formatMessage({id: 'project_budget.planned_revenue'}),
				'-',
				formatMessage({id: 'project_budget.planned_cost'}),
			],
		},
		{
			title: formatMessage({id: 'common.margin'}),
			details: [
				formatMessage({id: 'project_budget.planned_profit'}),
				'/',
				formatMessage({id: 'project_budget.planned_revenue'}),
				'X',
				'100',
			],
		}
	);
	return tooltip;
};

export const remainingPriceTooltip = ({formatMessage}) => {
	const tooltip = [];
	tooltip.push(
		{
			title: formatMessage({id: 'project_budget.revenue'}),
			details: [
				formatMessage({id: 'project_budget.all_remaining_billable_hours'}),
				'x',
				formatMessage({id: 'common.rate-card'}),
				'+',
				formatMessage({id: 'project_budget.price_on_all_remaining_billable_expenses'}),
			],
		},
		{
			title: formatMessage({id: 'common.cost'}),
			details: [
				formatMessage({id: 'project_budget.all_remaining_hours'}),
				'x',
				formatMessage({id: 'project_budget.internal_cost'}),
				'+',
				formatMessage({id: 'project_budget.cost_on_all_remaining_expenses'}),
			],
		},
		{
			title: formatMessage({id: 'common.profit'}),
			details: [
				formatMessage({id: 'project_budget.remaining_revenue'}),
				'-',
				formatMessage({id: 'project_budget.remaining_cost'}),
			],
		},
		{
			title: formatMessage({id: 'common.margin'}),
			details: [
				formatMessage({id: 'project_budget.remaining_profit'}),
				'/',
				formatMessage({id: 'project_budget.remaining_revenue'}),
				'X',
				'100',
			],
		}
	);
	return tooltip;
};

export const forecastPriceTooltip = ({formatMessage}) => {
	const tooltip = [];
	tooltip.push(
		{
			title: formatMessage({id: 'project_budget.revenue'}),
			details: [
				formatMessage({id: 'project_budget.actual_revenue'}),
				'+',
				formatMessage({id: 'project_budget.remaining_revenue'}),
			],
		},
		{
			title: formatMessage({id: 'common.cost'}),
			details: [
				formatMessage({id: 'insights.component.list.column.actualCost'}),
				'+',
				formatMessage({id: 'project_budget.remaining_cost'}),
			],
		},
		{
			title: formatMessage({id: 'common.profit'}),
			details: [
				formatMessage({id: 'project_budget.forecast_revenue'}),
				'-',
				formatMessage({id: 'project_budget.forecast_cost'}),
			],
		},
		{
			title: formatMessage({id: 'common.margin'}),
			details: [
				formatMessage({id: 'insights.component.list.column.forecastProfit'}),
				'/',
				formatMessage({id: 'project_budget.forecast_revenue'}),
				'X',
				'100',
			],
		}
	);
	return tooltip;
};

export const actualVarianceToPlanPriceTooltip = ({formatMessage}) => [
	{
		title: formatMessage({id: 'project_budget.variance_definition'}),
		description: formatMessage({id: 'project_budget.variance_definition_description'}),
	},
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [
			formatMessage({id: 'project_budget.actual_revenue'}),
			'-',
			formatMessage({id: 'project_budget.planned_revenue'}),
		],
	},
	{
		title: formatMessage({id: 'common.cost'}),
		details: [
			formatMessage({id: 'insights.component.list.column.actualCost'}),
			'-',
			formatMessage({id: 'project_budget.planned_cost'}),
		],
	},
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'project_budget.variance_revenue'}),
			'-',
			formatMessage({id: 'project_budget.variance_cost'}),
		],
	},
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.variance_profit'}),
			'/',
			formatMessage({id: 'project_budget.variance_revenue'}),
			'X',
			'100',
		],
	},
];

export const forecastVarianceToPlanPriceTooltip = ({formatMessage}) => [
	{
		title: formatMessage({id: 'project_budget.variance_definition'}),
		description: formatMessage({id: 'project_budget.variance_definition_description'}),
	},
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [
			formatMessage({id: 'project_budget.forecast_revenue'}),
			'-',
			formatMessage({id: 'project_budget.planned_revenue'}),
		],
	},
	{
		title: formatMessage({id: 'common.cost'}),
		details: [formatMessage({id: 'project_budget.forecast_cost'}), '-', formatMessage({id: 'project_budget.planned_cost'})],
	},
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'project_budget.variance_revenue'}),
			'-',
			formatMessage({id: 'project_budget.variance_cost'}),
		],
	},
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.variance_profit'}),
			'/',
			formatMessage({id: 'project_budget.variance_revenue'}),
			'X',
			'100',
		],
	},
];

export const companyFinancialsTooltip = ({formatMessage}) => [
	{
		title: formatMessage({id: 'project_budget.revenue'}),
		details: [
			formatMessage({id: 'project_budget.all_fixed_price_revenue'}),
			'+',
			formatMessage({id: 'project_budget.all_tm_revenue'}),
		],
	},
	{
		title: formatMessage({id: 'common.cost'}),
		details: [
			formatMessage({id: 'project_budget.all_fixed_price_costs'}),
			'+',
			formatMessage({id: 'project_budget.all_tm_costs'}),
		],
	},
	{
		title: formatMessage({id: 'common.profit'}),
		details: [
			formatMessage({id: 'project_budget.financial_number_revenue'}),
			'-',
			formatMessage({id: 'project_budget.financial_number_cost'}),
		],
	},
	{
		title: formatMessage({id: 'common.margin'}),
		details: [
			formatMessage({id: 'project_budget.financial_number_profit'}),
			'/',
			formatMessage({id: 'project_budget.financial_number_revenue'}),
			'X',
			'100',
		],
	},
];

export const registeredHoursTooltip = ({formatMessage}) => [
	{details: [formatMessage({id: 'project_budget.all_time_entries'})]},
];

export const remainingHoursTooltip = ({formatMessage}) => [
	{details: [formatMessage({id: 'project_budget.all_remaining_hours'})]},
];

export const forecastHoursTooltip = ({formatMessage}) => [
	{details: [formatMessage({id: 'project_budget.actuals'}), '+', formatMessage({id: 'common.remaining'})]},
];

export const scopeTooltip = () => null;

export const lowTooltip = () => null;

export const highTooltip = () => null;

export const allocationTooltip = () => null;

export const invoiceTooltip = ({formatMessage}) => [
	{
		title: formatMessage({id: 'project_budget.fixed_price_invoiced_percentage'}),
		details: [formatMessage({id: 'common.invoiced'}), '/', formatMessage({id: 'common.fixed_price'}), 'X', '100'],
	},
];
