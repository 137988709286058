import Styled from 'styled-components';
import React, {useEffect, useState} from 'react';

const Message = Styled.div`
	display: flex;
	width: 317px;
	height: 28px;
	background-color: #FFECB4;
	align-self: center;
	align-items: center;
	font-size: 13px;
	padding-left: 16px;
	span {
		font-weight: bold;
		padding-right: 10px;
	}
`;

function fixedPriceUpdateToast() {
	const [timeLeft, setTimeLeft] = useState(60);

	useEffect(() => {
		const interval = setInterval(() => {
			if (timeLeft <= 0) {
				location.reload();
			} else {
				setTimeLeft(timeLeft - 1);
			}
		}, 1000);
		return () => clearInterval(interval);
	}, [timeLeft]);

	return (
		<Message>
			<span>Update in Progress</span> – Page will refresh in {timeLeft}
		</Message>
	);
}

export default fixedPriceUpdateToast;
