import {
	CURSOR,
	GROUP_SECTION_WIDTH,
	GROUP_TYPE,
	GROUP_SECTION_SPACING_LEVEL_TWO,
	GROUP_SECTION_PADDING_LEFT,
	GROUP_SECTION_TEXT_GREY_DARK,
	getVisualizationMode,
	VISUALIZATION_MODE,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {SCHEDULING_VIEW} from '../../../../constants';
import EventManager from '../../EventManager';

class NonProjectTimeGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.NON_PROJECT_TIME, data);
	}

	onItemCreate(startDate, endDate) {
		EventManager.onItemCreate(this.pageComponent, this, startDate, endDate);
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height, expanded, groupType} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);
		const {id} = data;
		const cacheId = `${groupType}-${id}-${!!expanded}`;

		const {schedulingView} = this.pageComponent.props;
		const {schedulingOptions} = this.pageComponent.state;
		const {company} = this.pageComponent.state.data;

		const isCapacityOverviewScheduling = schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;
		const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;
		const isCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

		const capacityOverviewOffsetX = isCapacityOverviewScheduling ? GROUP_SECTION_SPACING_LEVEL_TWO : 0;
		const combinedModeOffsetX = isPeopleScheduling && isCombinedMode ? GROUP_SECTION_PADDING_LEFT : 0;

		interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);

		const cachedData = cacheManager.get(groupType, cacheId);
		if (cachedData) {
			canvasContext.drawImage(cachedData, x, y, width, this.itemRowHeight);
			return;
		}

		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
		canvasContext.font = '500 13px ' + Util.getFontFamily();
		canvasContext.fillText(data.name, x + 26 + this.marginX + capacityOverviewOffsetX + combinedModeOffsetX, y + 23);
	}
}

export default NonProjectTimeGroup;
