import {
	clearDrawnStepDataArrayMap,
	clearDrawnStepsMap,
	clearPersonHeatmapMetaDataStepCache,
	createCanvasDefaultHolidayCalendarEntries,
	createRelayTeamDataStructure,
	getCombinedTaskMode,
	getVisualizationMode,
	GROUP_TYPE,
	hasDrawnAnySteps,
	isTaskVisualizationMode,
	isTimeOffAllocation,
	VISUALIZATION_MODE,
} from './canvas-timeline/canvas_timeline_util';
import IntervalTree from 'node-interval-tree';
import {isPlaceholderAllocationItem, isProjectAllocationItem, isTaskItem} from './SchedulingUtils';
import {isItemWithinStep, recalculateGroupHeatmapCache} from './heatmap/HeatmapLogic';
import {SCHEDULING_VIEW} from '../../constants';
import RecalculationManager, {interval, MAX_CANVAS_DATE, MIN_CANVAS_DATE} from './RecalculationManager';
import {toggleItemVisibility} from './components/allocation_controls/AllocationControlsCanvasUtils';
import {calculateNonWorkingDaysMap} from './heatmap/NonWorkingDaysLogic';
import {getAllocationTotalNew} from '../scheduling/project_allocation_logic';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import Util from '../../forecast-app/shared/util/util';
import IDManager from './IDManager';
import {BinarySearchTree} from 'binary-search-tree';

export const DATA_ENTITIES = {
	HEATMAPS: 'heatMaps',
	PLACEHOLDER_HEATMAPS: 'placeholderHeatMaps',
	NO_ACCESS_HEATMAPS: 'noAccessHeatMaps',
	PERSONS: 'persons',
	PROJECTS: 'projects',
	PROJECT_PERSONS: 'projectPersons',
	PERSON_LABELS: 'personLabels',
	PERSON_SKILLS: 'personSkills',
	TEAM_PERSONS: 'teamPersons',
	PROJECT_GROUPS: 'projectGroups',
	PROJECT_STATUSES: 'projectStatuses',
	STATUS_COLUMNS: 'statusColumns',
	TASKS: 'tasks',
	TIME_REGISTRATIONS: 'timeRegistrations',
	PROGRAMS: 'programs',
	HOLIDAY_CALENDARS: 'holidayCalendars',
	HOLIDAY_CALENDAR_ENTRIES: 'holidayCalendarEntries',
	CLIENTS: 'clients',
	PROJECT_LABELS: 'projectLabels',
	ALLOCATIONS: 'allocations',
	ROLES: 'roles',
	DEPARTMENTS: 'departments',
	PLACEHOLDERS: 'placeholders',
	IDLE_TIMES: 'idleTimes',
	PLACEHOLDER_ALLOCATIONS: 'placeholderAllocations',
	PLACEHOLDER_SKILLS: 'placeholderSkills',
	PHASES: 'phases',
	PLACEHOLDER_TEAMS: 'placeholderTeams',
	ANONYMIZED_TIME_REGS: 'anonymizedTimeRegistrations',
	ANONYMIZED_TASKS: 'anonymizedTasks',
};

export const ENTITY_FIELDS = {
	ID: 'id',
	PROJECT_GROUP_ID: 'projectGroupId',
	PROJECT_ID: 'projectId',
	PERSON_ID: 'personId',
	TEAM_ID: 'teamId',
	TASK_ID: 'taskId',
	HOLIDAY_CALENDAR_ID: 'holidayCalendarId',
	PLACEHOLDER_ID: 'placeholderId',
	PHASE_ID: 'phaseId',
	PARENT_TASK_ID: 'parentTaskId',
	PROGRAM_PREFIX: 'programPrefix',
};

export const LOOKUP_MAP_TYPE = {
	MAP: 'MAP',
	GROUP_BY: 'GROUP_BY',
};

export const LOOKUP_MAPS = {
	PERSON_MAP: {
		KEY: 'personMap',
		DATA_ENTITY: DATA_ENTITIES.PERSONS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	PROJECT_MAP: {
		KEY: 'projectMap',
		DATA_ENTITY: DATA_ENTITIES.PROJECTS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	PROJECT_BY_PROJECT_GROUP_MAP: {
		KEY: 'projectByProjectGroupMap',
		DATA_ENTITY: DATA_ENTITIES.PROJECTS,
		FIELD: ENTITY_FIELDS.PROJECT_GROUP_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	PROJECT_BY_PROGRAM_MAP: {
		KEY: 'projectByProgramMap',
		DATA_ENTITY: DATA_ENTITIES.PROJECTS,
		FIELD: ENTITY_FIELDS.PROGRAM_PREFIX,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	PROJECT_PERSON_BY_PROJECT_MAP: {
		KEY: 'projectPersonByProjectMap',
		DATA_ENTITY: DATA_ENTITIES.PROJECT_PERSONS,
		FIELD: ENTITY_FIELDS.PROJECT_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	PROJECT_PERSON_BY_PERSON_MAP: {
		KEY: 'projectPersonByPersonMap',
		DATA_ENTITY: DATA_ENTITIES.PROJECT_PERSONS,
		FIELD: ENTITY_FIELDS.PERSON_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	PROJECT_LABEL_BY_PROJECT_MAP: {
		KEY: 'projectLabelByProjectMap',
		DATA_ENTITY: DATA_ENTITIES.PROJECT_LABELS,
		FIELD: ENTITY_FIELDS.PROJECT_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	PERSON_LABEL_BY_PERSON_MAP: {
		KEY: 'personLabelByPersonMap',
		DATA_ENTITY: DATA_ENTITIES.PERSON_LABELS,
		FIELD: ENTITY_FIELDS.PERSON_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	PERSON_SKILLS_BY_PERSON_MAP: {
		KEY: 'personSkillsByPersonMap',
		DATA_ENTITY: DATA_ENTITIES.PERSON_SKILLS,
		FIELD: ENTITY_FIELDS.PERSON_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	TEAM_PERSON_BY_TEAM_MAP: {
		KEY: 'teamPersonByTeamMap',
		DATA_ENTITY: DATA_ENTITIES.TEAM_PERSONS,
		FIELD: ENTITY_FIELDS.TEAM_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	PROJECT_GROUP_MAP: {
		KEY: 'projectGroupMap',
		DATA_ENTITY: DATA_ENTITIES.PROJECT_GROUPS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	PROJECTS_BY_PROJECT_GROUP: {
		KEY: 'projectGroupMap',
		DATA_ENTITY: DATA_ENTITIES.PROJECT_GROUPS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	PROJECT_STATUS_MAP: {
		KEY: 'projectStatusMap',
		DATA_ENTITY: DATA_ENTITIES.PROJECT_STATUSES,
		FIELD: ENTITY_FIELDS.PROJECT_ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	STATUS_COLUMN_MAP: {
		KEY: 'statusColumnMap',
		DATA_ENTITY: DATA_ENTITIES.STATUS_COLUMNS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	TASK_MAP: {
		KEY: 'taskMap',
		DATA_ENTITY: DATA_ENTITIES.TASKS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	TIME_REGS_BY_TASK_MAP: {
		KEY: 'timeRegsByTaskMap',
		DATA_ENTITY: DATA_ENTITIES.TIME_REGISTRATIONS,
		FIELD: ENTITY_FIELDS.TASK_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	TIME_REGS_BY_PERSON_MAP: {
		KEY: 'timeRegsByPersonMap',
		DATA_ENTITY: DATA_ENTITIES.TIME_REGISTRATIONS,
		FIELD: ENTITY_FIELDS.PERSON_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	ANONYMIZED_TIME_REGS_BY_PERSON_MAP: {
		KEY: 'anonymizedTimeRegistrationsByPersonMap',
		DATA_ENTITY: DATA_ENTITIES.ANONYMIZED_TIME_REGS,
		FIELD: ENTITY_FIELDS.PERSON_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	ANONYMIZED_ID_TASK_MAP: {
		KEY: 'anonymizedTaskMap',
		DATA_ENTITY: DATA_ENTITIES.ANONYMIZED_TASKS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	PROGRAM_MAP: {
		KEY: 'programMap',
		DATA_ENTITY: DATA_ENTITIES.PROGRAMS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	HOLIDAY_CALENDAR_MAP: {
		KEY: 'holidayCalendarMap',
		DATA_ENTITY: DATA_ENTITIES.HOLIDAY_CALENDARS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	HOLIDAY_CALENDAR_ENTRIES_BY_CALENDAR: {
		KEY: 'holidayCalendarEntriesByCalendar',
		DATA_ENTITY: DATA_ENTITIES.HOLIDAY_CALENDAR_ENTRIES,
		FIELD: ENTITY_FIELDS.HOLIDAY_CALENDAR_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	PERSON_ALLOCATION_MAP: {
		KEY: 'personAllocationMap',
		DATA_ENTITY: DATA_ENTITIES.ALLOCATIONS,
		FIELD: ENTITY_FIELDS.PERSON_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	ROLE_MAP: {
		KEY: 'roleMap',
		DATA_ENTITY: DATA_ENTITIES.ROLES,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	DEPARTMENT_MAP: {
		KEY: 'departmentMap',
		DATA_ENTITY: DATA_ENTITIES.DEPARTMENTS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	ALLOCATIONS_BY_PROJECT_OR_PROJECT_GROUP: {
		KEY: 'allocationsByProjectOrProjectGroup',
		DATA_ENTITY: DATA_ENTITIES.ALLOCATIONS,
		FIELDS: [ENTITY_FIELDS.PROJECT_ID, ENTITY_FIELDS.PROJECT_GROUP_ID],
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	PLACEHOLDERS_BY_PROJECT_OR_PROJECT_GROUP: {
		KEY: 'placeholdersByProjectOrProjectGroup',
		DATA_ENTITY: DATA_ENTITIES.PLACEHOLDERS,
		FIELDS: [ENTITY_FIELDS.PROJECT_ID, ENTITY_FIELDS.PROJECT_GROUP_ID],
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	IDLE_TIME_MAP: {
		KEY: 'idleTimeMap',
		DATA_ENTITY: DATA_ENTITIES.IDLE_TIMES,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	PLACEHOLDER_MAP: {
		KEY: 'placeholderMap',
		DATA_ENTITY: DATA_ENTITIES.PLACEHOLDERS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	CLIENT_MAP: {
		KEY: 'clientMap',
		DATA_ENTITY: DATA_ENTITIES.CLIENTS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	PLACEHOLDER_ALLOCATIONS_BY_PLACEHOLDER: {
		KEY: 'placeholderAllocationsByPlaceholder',
		DATA_ENTITY: DATA_ENTITIES.PLACEHOLDER_ALLOCATIONS,
		FIELD: ENTITY_FIELDS.PLACEHOLDER_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	PLACEHOLDER_SKILLS_BY_PLACEHOLDER: {
		KEY: 'placeholderSkillsByPlaceholder',
		DATA_ENTITY: DATA_ENTITIES.PLACEHOLDER_SKILLS,
		FIELD: ENTITY_FIELDS.PLACEHOLDER_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	TASK_IDS_BY_PHASE_ID: {
		KEY: 'phaseIdToTaskIdsMap',
		DATA_ENTITY: DATA_ENTITIES.TASKS,
		FIELDS: [ENTITY_FIELDS.PHASE_ID, ENTITY_FIELDS.PROJECT_ID],
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
		SINGLE_PROPERTY: ENTITY_FIELDS.ID,
		CONDITION: task => !task.parentTaskId,
	},
	TASKS_BY_PHASE_ID: {
		KEY: 'phaseIdToTasksMap',
		DATA_ENTITY: DATA_ENTITIES.TASKS,
		FIELDS: [ENTITY_FIELDS.PHASE_ID, ENTITY_FIELDS.PROJECT_ID],
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
		CONDITION: task => !task.parentTaskId,
	},
	PARENT_TASK_TO_SUB_TASK_MAP: {
		KEY: 'parentTaskToSubtaskMap',
		DATA_ENTITY: DATA_ENTITIES.TASKS,
		FIELD: ENTITY_FIELDS.PARENT_TASK_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
		SINGLE_PROPERTY: ENTITY_FIELDS.ID,
		CONDITION: task => task.parentTaskId,
	},
	PHASE_MAP: {
		KEY: 'phaseMap',
		DATA_ENTITY: DATA_ENTITIES.PHASES,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
	PROJECT_ID_TO_PHASE_IDS_MAP: {
		KEY: 'projectIdToPhaseIdsMap',
		DATA_ENTITY: DATA_ENTITIES.PHASES,
		FIELD: ENTITY_FIELDS.PROJECT_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
		SINGLE_PROPERTY: ENTITY_FIELDS.ID,
	},
	PLACEHOLDER_TEAMS_BY_PLACEHOLDER: {
		KEY: 'placeholderTeamsByPlaceholder',
		DATA_ENTITY: DATA_ENTITIES.PLACEHOLDER_TEAMS,
		FIELD: ENTITY_FIELDS.PLACEHOLDER_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	TEAM_PERSON_BY_PERSON_MAP: {
		KEY: 'teamPersonByPersonMap',
		DATA_ENTITY: DATA_ENTITIES.TEAM_PERSONS,
		FIELD: ENTITY_FIELDS.PERSON_ID,
		TYPE: LOOKUP_MAP_TYPE.GROUP_BY,
	},
	TIME_REG_MAP: {
		KEY: 'timeRegMap',
		DATA_ENTITY: DATA_ENTITIES.TIME_REGISTRATIONS,
		FIELD: ENTITY_FIELDS.ID,
		TYPE: LOOKUP_MAP_TYPE.MAP,
	},
};

const groupByChildGroupId = new Map();
const temporaryVisibleItemsMap = new Map();

export default class DataManager {
	static isValidIdentifier(identifier) {
		return identifier !== null && identifier !== undefined;
	}

	static followsCondition(entity, condition) {
		if (condition !== undefined) {
			return condition(entity);
		}

		return true;
	}

	static getFirstValidIdentifier(entity, identifiers) {
		if (identifiers) {
			for (const identifier of identifiers) {
				if (this.isValidIdentifier(entity[identifier])) {
					return entity[identifier];
				}
			}
		}

		return null;
	}

	static getIdentifier(entity, lookupMapDetails) {
		const {FIELD, FIELDS} = lookupMapDetails;
		return FIELD ? entity[FIELD] : this.getFirstValidIdentifier(entity, FIELDS);
	}

	static createGroupBy(entities, lookupMapDetails) {
		if (entities) {
			return entities.reduce((groupBy, entity) => {
				this.addToGroupBy(groupBy, entity, lookupMapDetails);
				return groupBy;
			}, {});
		}

		return {};
	}

	static addToGroupBy(groupBy, entity, lookupMapDetails) {
		const {SINGLE_PROPERTY, CONDITION} = lookupMapDetails;

		if (this.followsCondition(entity, CONDITION)) {
			const identifier = this.getIdentifier(entity, lookupMapDetails);

			if (this.isValidIdentifier(identifier)) {
				const mapValue = SINGLE_PROPERTY ? entity[SINGLE_PROPERTY] : entity;

				if (mapValue) {
					(groupBy[identifier] = groupBy[identifier] || []).push(mapValue);
				}
			}
		}
	}

	static addToMap(map, entity, lookupMapDetails) {
		const {SINGLE_PROPERTY, CONDITION} = lookupMapDetails;

		if (this.followsCondition(entity, CONDITION)) {
			const identifier = this.getIdentifier(entity, lookupMapDetails);

			if (this.isValidIdentifier(identifier)) {
				const mapValue = SINGLE_PROPERTY ? entity[SINGLE_PROPERTY] : entity;

				if (mapValue) {
					map.set(identifier, mapValue);
				}
			}
		}
	}

	static removeFromGroupBy(groupBy, entity, lookupMapDetails) {
		const {SINGLE_PROPERTY, CONDITION} = lookupMapDetails;

		if (this.followsCondition(entity, CONDITION)) {
			const identifier = this.getIdentifier(entity, lookupMapDetails);

			if (this.isValidIdentifier(identifier)) {
				const mapValue = SINGLE_PROPERTY ? entity[SINGLE_PROPERTY] : entity;

				if (mapValue && groupBy[identifier]) {
					let objectIndex = groupBy[identifier].findIndex(obj => obj.id === entity.id);
					if (objectIndex > -1) {
						groupBy[identifier].splice(objectIndex, 1);
					}
				}
			}
		}
	}

	static removeFromMap(map, entity, lookupMapDetails) {
		const {SINGLE_PROPERTY, CONDITION} = lookupMapDetails;

		if (this.followsCondition(entity, CONDITION)) {
			const identifier = this.getIdentifier(entity, lookupMapDetails);

			if (this.isValidIdentifier(identifier)) {
				const mapValue = SINGLE_PROPERTY ? entity[SINGLE_PROPERTY] : entity;

				if (mapValue) {
					map.delete(identifier);
				}
			}
		}
	}

	static mergeEntity(pageComponent, entity, dataEntity) {
		if (Object.values(DATA_ENTITIES).includes(dataEntity)) {
			const {data} = pageComponent.state;

			// add as part of data entities array (e.g. projects)
			const exists = data[dataEntity]?.some(existingEntity => existingEntity.id === entity.id);
			if (!exists) {
				data[dataEntity].push(entity);
			}

			// add to lookup maps as entry for entity and add entity to list of pointers
			for (const lookupMapDetails of Object.values(LOOKUP_MAPS)) {
				const {KEY, DATA_ENTITY, TYPE} = lookupMapDetails;

				if (DATA_ENTITY === dataEntity) {
					if (TYPE === LOOKUP_MAP_TYPE.MAP) {
						this.addToMap(data[KEY], entity, lookupMapDetails);
					} else {
						this.addToGroupBy(data[KEY], entity, lookupMapDetails);
					}
				}
			}
		}
	}

	static createMap(entities, lookupMapDetails) {
		const map = new Map();

		if (entities) {
			for (const entity of entities) {
				this.addToMap(map, entity, lookupMapDetails);
			}
		}

		return map;
	}

	static updateLookupMap(pageComponent, dataEntity, newEntity) {
		const {data} = pageComponent.state;

		const lookupMapValues = Object.values(LOOKUP_MAPS);
		for (let i = 0; i < lookupMapValues.length; i++) {
			const lookupMapDetails = lookupMapValues[i];
			const {KEY, DATA_ENTITY, TYPE} = lookupMapDetails;

			const lookupMap = data[KEY];
			if (lookupMap !== undefined && DATA_ENTITY === dataEntity) {
				if (TYPE === LOOKUP_MAP_TYPE.MAP) {
					this.addToMap(lookupMap, newEntity, lookupMapDetails);
				} else if (TYPE === LOOKUP_MAP_TYPE.GROUP_BY) {
					this.addToGroupBy(lookupMap, newEntity, lookupMapDetails);
				}
			}
		}
	}

	static removeFromLookupMaps(pageComponent, dataEntity, newEntity) {
		const {data} = pageComponent.state;

		const lookupMapValues = Object.values(LOOKUP_MAPS);
		for (let i = 0; i < lookupMapValues.length; i++) {
			const lookupMapDetails = lookupMapValues[i];
			const {KEY, DATA_ENTITY, TYPE} = lookupMapDetails;

			const lookupMap = data[KEY];
			if (lookupMap !== undefined && DATA_ENTITY === dataEntity) {
				if (TYPE === LOOKUP_MAP_TYPE.MAP) {
					this.removeFromMap(lookupMap, newEntity, lookupMapDetails);
				} else if (TYPE === LOOKUP_MAP_TYPE.GROUP_BY) {
					this.removeFromGroupBy(lookupMap, newEntity, lookupMapDetails);
				}
			}
		}
	}

	static createLookupMap(data, lookupMapDetails) {
		const {DATA_ENTITY, TYPE} = lookupMapDetails;

		const isCreatingMap = TYPE === LOOKUP_MAP_TYPE.MAP;
		const entities = data[DATA_ENTITY];

		return isCreatingMap ? this.createMap(entities, lookupMapDetails) : this.createGroupBy(entities, lookupMapDetails);
	}

	static setLookupMaps(pageComponent, data, initData) {
		for (const lookupMapDetails of Object.values(LOOKUP_MAPS)) {
			const {KEY} = lookupMapDetails;
			data[KEY] = this.createLookupMap(data, lookupMapDetails);
		}

		data.teamsRelay = createRelayTeamDataStructure(data);
		data.holidaysEntriesCanvasFormat = createCanvasDefaultHolidayCalendarEntries(data);
		data.featureFlags = initData?.featureFlags || [];

		this.setTimeRegLookupMaps(pageComponent, data);
	}

	static createEntitySet(entities, field = 'id') {
		const entitySet = new Set();

		if (entities?.length > 0) {
			for (let i = 0; i < entities.length; i++) {
				entitySet.add(entities[i][field]);
			}
		}

		return entitySet;
	}

	static addEntitiesToData(pageComponent, entity, entityData) {
		const data = pageComponent.getData();

		for (const entityToAdd of entityData) {
			if (!data[entity]) {
				data[entity] = [entity];
			} else {
				data[entity].push(entityToAdd);
			}
		}
	}

	static initTimeRegSearchTree() {
		return new BinarySearchTree({
			checkValueEquality: (timeRegA, timeRegB) => {
				return timeRegA.id === timeRegB.id;
			},
		});
	}

	static setTimeRegLookupMaps(pageComponent, data) {
		const timeRegSearchTreeMap = new Map();

		if (data.timeRegistrations && hasFeatureFlag('combined_mode_performance_improvements')) {
			const {todayDate} = pageComponent.state;

			for (const timeReg of data.timeRegistrations) {
				const {personId, taskId, projectId, canvasTimelineDate} = timeReg;

				if (canvasTimelineDate < todayDate) {
					let personTimeRegMap = timeRegSearchTreeMap.get(personId);
					if (!personTimeRegMap) {
						personTimeRegMap = new Map();
						timeRegSearchTreeMap.set(personId, personTimeRegMap);
					}

					if (projectId || taskId) {
						const task = taskId && !projectId ? data[LOOKUP_MAPS.TASK_MAP.KEY].get(taskId) : null;
						timeReg.projectOrTaskProjectId = projectId || task?.projectId;
					}

					let searchTree = personTimeRegMap.get(timeReg.projectOrTaskProjectId || null);
					if (!searchTree) {
						searchTree = this.initTimeRegSearchTree();
						personTimeRegMap.set(timeReg.projectOrTaskProjectId || null, searchTree);
					}

					searchTree.insert(timeReg.canvasTimelineDate, timeReg);
				}
			}
		}

		data.timeRegSearchTreeMap = timeRegSearchTreeMap;
	}

	static getTimeRegSearchTree(pageComponent, personId, projectId) {
		const {timeRegSearchTreeMap} = pageComponent.getData();

		const personTimeRegMap = timeRegSearchTreeMap.get(personId);
		if (personTimeRegMap) {
			const key = projectId || null;
			let searchTree = personTimeRegMap.get(key);

			if (!searchTree) {
				searchTree = this.initTimeRegSearchTree();
				personTimeRegMap.set(key, searchTree);
			}

			return searchTree;
		}

		return null;
	}

	static addTimeRegToLookupMaps(pageComponent, timeReg) {
		if (hasFeatureFlag('combined_mode_performance_improvements')) {
			const searchTree = this.getTimeRegSearchTree(pageComponent, timeReg.personId, timeReg.projectOrTaskProjectId);
			if (searchTree) {
				searchTree.insert(timeReg.canvasTimelineDate, timeReg);
			}

			const timeRegGroupId = IDManager.getTimeRegHeatmapGroupId(pageComponent, timeReg);
			if (timeRegGroupId) {
				const heatmapGroup = this.findHeatmapGroupById(timeRegGroupId);

				if (heatmapGroup) {
					this.addTimeRegToDataHeatmapGroupCache(pageComponent, heatmapGroup.id, timeReg);
				}
			}
		}
	}

	static updateTimeRegInLookupMaps(pageComponent, timeReg) {
		if (hasFeatureFlag('combined_mode_performance_improvements')) {
			const searchTree = this.getTimeRegSearchTree(pageComponent, timeReg.personId, timeReg.projectOrTaskProjectId);
			if (searchTree) {
				searchTree.delete(timeReg.canvasTimelineDate, timeReg);
				searchTree.insert(timeReg.canvasTimelineDate, timeReg);
			}

			const timeRegGroupId = IDManager.getTimeRegHeatmapGroupId(pageComponent, timeReg);
			if (timeRegGroupId) {
				const heatmapGroup = this.findHeatmapGroupById(timeRegGroupId);

				if (heatmapGroup) {
					this.removeTimeRegFromDataHeatmapGroupCache(pageComponent, heatmapGroup.id, timeReg);
					this.addTimeRegToDataHeatmapGroupCache(pageComponent, heatmapGroup.id, timeReg);
				}
			}
		}
	}

	static removeTimeRegFromLookupMaps(pageComponent, timeReg) {
		if (hasFeatureFlag('combined_mode_performance_improvements')) {
			const searchTree = this.getTimeRegSearchTree(pageComponent, timeReg.personId, timeReg.projectOrTaskProjectId);

			if (searchTree) {
				searchTree.delete(timeReg.canvasTimelineDate, timeReg);
			}
		}
	}

	/***
	 *
	 * @param pageComponent
	 * @param data
	 * @param mergeStartDate - canvasTimelineDate of load more start period
	 * @param mergeEndDate - canvasTimelineDate of load more end period
	 */
	static mergeLoadMoreData(pageComponent, data, mergeStartDate, mergeEndDate) {
		const {company} = pageComponent.getData();
		const {schedulingOptions} = pageComponent.state;
		const isTaskMode = isTaskVisualizationMode(schedulingOptions, company);
		const isAllocationMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);
		const isCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
		const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(company);

		const mergeStart = performance.now();

		for (const entity of Object.values(DATA_ENTITIES)) {
			const entityData = data[entity];

			if (entityData) {
				const createdEntities = new Map();
				const createdItems = new Set();
				const heatmapGroupIdsToRecalculate = new Set();

				switch (entity) {
					case DATA_ENTITIES.PLACEHOLDER_HEATMAPS:
					case DATA_ENTITIES.NO_ACCESS_HEATMAPS:
					case DATA_ENTITIES.HEATMAPS:
						this.mergeHeatmapData(pageComponent, entityData, entity);
						break;
					case DATA_ENTITIES.PLACEHOLDER_ALLOCATIONS:
					case DATA_ENTITIES.ALLOCATIONS:
					case DATA_ENTITIES.TASKS:
					case DATA_ENTITIES.TIME_REGISTRATIONS:
						const isTaskEntities = entity === DATA_ENTITIES.TASKS || entity === DATA_ENTITIES.TIME_REGISTRATIONS;
						const isAllocationEntities =
							entity === DATA_ENTITIES.ALLOCATIONS || entity === DATA_ENTITIES.PLACEHOLDER_ALLOCATIONS;

						if (isCombinedMode || (isTaskEntities && isTaskMode) || (isAllocationEntities && isAllocationMode)) {
							this.createUniqueEntities(
								pageComponent,
								entityData,
								entity,
								createdEntities,
								createdItems,
								heatmapGroupIdsToRecalculate
							);

							this.mergeUniqueEntities(
								pageComponent,
								createdEntities,
								createdItems,
								heatmapGroupIdsToRecalculate,
								mergeStartDate,
								mergeEndDate
							);
						} else if (isMixedAllocationModeEnabled) {
							this.addEntitiesToData(pageComponent, entity, entityData);
						}

						break;
					default:
						break;
				}
			}
		}

		console.log('DONE load more merge: ', Math.round(performance.now() - mergeStart));
	}

	static isMultipleGroupItem(pageComponent, item) {
		const {schedulingView} = pageComponent.props;
		const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;

		if (!isProjectScheduling) {
			return false;
		}

		const isAssignedToMultiple = item.data?.task?.assignedPersons?.length > 1;
		return isTaskItem(item) && isAssignedToMultiple;
	}

	static insertLazyLoadedItems(
		pageComponent,
		itemsToInsert,
		heatmapGroupIdsToRecalculate = new Set(),
		mergeStartDate,
		mergeEndDate
	) {
		const {groups} = pageComponent.state;

		let recalculationStart = mergeStartDate < mergeEndDate ? mergeStartDate : mergeEndDate;
		let recalculationEnd = mergeEndDate > mergeStartDate ? mergeEndDate : mergeStartDate;

		if (itemsToInsert.size > 0) {
			const heatmapGroups = this.findHeatmapGroups(groups);

			if (heatmapGroups?.length > 0) {
				for (let i = 0; i < heatmapGroups.length; i++) {
					const group = heatmapGroups[i];

					const visibleItemsTree = group.getVisibleItemsTree();

					for (const item of itemsToInsert) {
						if (group.isHeatmapItem(item)) {
							const {startDate, endDate} = item;
							const insertSuccess = visibleItemsTree.insert(startDate, endDate, item);

							if (insertSuccess) {
								item.setVisibleItemsTreeDates();
								item.addHeatmapGroupId(group.id);
								heatmapGroupIdsToRecalculate.add(group.id);

								if (startDate < recalculationStart) {
									recalculationStart = startDate;
								}

								if (endDate > recalculationEnd) {
									recalculationEnd = endDate;
								}

								if (!this.isMultipleGroupItem(pageComponent, item)) {
									itemsToInsert.delete(item);
								}
							}
						}
					}

					group.setVisibleItemsTree(visibleItemsTree);
				}
			}
		}

		if (heatmapGroupIdsToRecalculate?.size > 0) {
			for (const groupId of heatmapGroupIdsToRecalculate) {
				RecalculationManager.setNeedsRecalculation(groupId, interval(recalculationStart, recalculationEnd));
			}
		}
	}

	static mergeUniqueEntities(
		pageComponent,
		createdEntities,
		createdItems,
		heatmapGroupIdsToRecalculate,
		mergeStartDate,
		mergeEndDate
	) {
		const {data, items} = pageComponent.state;

		for (const [entity, entities] of createdEntities) {
			if (!data[entity]) {
				data[entity] = entities;
			} else {
				data[entity] = data[entity].concat(entities);
			}
		}

		for (const item of createdItems) {
			toggleItemVisibility(pageComponent, item);
			items.push(item);
		}

		this.insertLazyLoadedItems(pageComponent, createdItems, heatmapGroupIdsToRecalculate, mergeStartDate, mergeEndDate);
	}

	static createUniqueEntities(
		pageComponent,
		entityData,
		entity,
		createdEntities,
		createdItems,
		heatmapGroupIdsToRecalculate
	) {
		if (entityData?.length > 0) {
			const {data, schedulingOptions} = pageComponent.state;
			const {company} = data;
			const isInActualMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.TASK_ACTUAL);
			const isUsingCombinedMode = getVisualizationMode(schedulingOptions, data.company, VISUALIZATION_MODE.COMBINATION);
			const isCombinedActualMode =
				isUsingCombinedMode && getCombinedTaskMode(data.company) === VISUALIZATION_MODE.TASK_ACTUAL;

			// if we are creating time registrations, we want to set needsToRecalculate
			const isTimeRegHeatmapReflected =
				(isInActualMode || isCombinedActualMode) && entity === DATA_ENTITIES.TIME_REGISTRATIONS;
			const addedTimeRegs = isTimeRegHeatmapReflected ? new Set() : null;

			const currentEntityIds = this.createEntitySet(data[entity]);
			if (!createdEntities.has(entity)) {
				createdEntities.set(entity, []);
			}

			const createdEntityList = createdEntities.get(entity);
			for (let i = 0; i < entityData.length; i++) {
				const newEntity = entityData[i];

				if (!currentEntityIds.has(newEntity.id)) {
					createdEntityList.push(newEntity);

					this.updateLookupMap(pageComponent, entity, newEntity);

					if (isTimeRegHeatmapReflected) {
						addedTimeRegs.add(newEntity);
					}
				}
			}

			// create items after updating data and lookup maps as some compose logic requires
			// data of other newly merged data, e.g. tasks and sub tasks in project scheduling
			// TODO: once all compose methods has been refactored, this should be replaced
			for (let i = 0; i < createdEntityList.length; i++) {
				pageComponent.createEntityItem(createdItems, entity, createdEntityList[i]);
			}

			// TODO: once all compose methods has been refactored, this should be replaced
			if (isTimeRegHeatmapReflected && addedTimeRegs?.size > 0) {
				for (const timeReg of addedTimeRegs) {
					const groupId = pageComponent.getTimeRegGroupId(timeReg);

					if (groupId) {
						heatmapGroupIdsToRecalculate.add(groupId);
					}
				}
			}
		}
	}

	static mergeHeatmapData(pageComponent, entityData, entity) {
		const {isProjectTimeline} = pageComponent.props;
		const {data, timelineHeatmapData} = pageComponent.state;

		const isHeatMaps = entity === DATA_ENTITIES.HEATMAPS;
		const isTimelineHeatmap = isProjectTimeline && isHeatMaps;
		const heatmapData = isTimelineHeatmap ? timelineHeatmapData : data[entity];

		if (!heatmapData) {
			if (isTimelineHeatmap) {
				pageComponent.setState({timelineHeatmapData: entityData});
			} else {
				data[entity] = entityData;
			}
		} else {
			for (const [entityId, newHeatmapData] of Object.entries(entityData)) {
				const currentHeatmapData = heatmapData[entityId];

				if (!currentHeatmapData) {
					heatmapData[entityId] = newHeatmapData;
					continue;
				}

				const concatToCurrentHeatmap = currentHeatmapData.length >= newHeatmapData.length;
				heatmapData[entityId] = concatToCurrentHeatmap
					? currentHeatmapData.concat(newHeatmapData)
					: newHeatmapData.concat(currentHeatmapData);
			}
		}
	}

	static isHeatmapGroup(group) {
		return group && group.composeItems !== undefined && group.validHeatmapItemTypes?.size > 0;
	}

	static resetVisibleItemsMaps() {
		groupByChildGroupId.clear();
		this.clearTemporaryVisibleItemsMap();
	}
	static removeGroupFromCache(groupId) {
		groupByChildGroupId.delete(groupId);
	}

	static clearTemporaryVisibleItemsMap() {
		temporaryVisibleItemsMap.clear();
	}

	static undoCurrentTemporaryMoves(pageComponent) {
		for (const item of temporaryVisibleItemsMap.values()) {
			const group = this.findHeatmapGroupById(item.groupId);
			if (group) {
				// If anything (e.g. weekend adjustments) messed with the dates, be sure we reset them:
				const {startDate, endDate} = item.getVisibleItemsTreeDates();
				item.startDate = startDate;
				item.endDate = endDate;

				this.addVisibleItem(pageComponent, group, item, false);
			}
		}
		this.clearTemporaryVisibleItemsMap();
	}

	static hasTemporaryVisibleItem() {
		return temporaryVisibleItemsMap.size > 0;
	}

	static initEmptyHeatmapGroupsCache(pageComponent) {
		const data = pageComponent.getData();
		data['heatmapGroupDataCache'] = new Map();
	}

	static addItemToDataHeatmapGroupCache(pageComponent, groupId, item) {
		const cache = this.getHeatmapGroupDataCache(pageComponent);

		let groupTree = cache.get(groupId);
		if (!groupTree) {
			groupTree = new IntervalTree();
			cache.set(groupId, groupTree);
		}

		const {startDate, endDate} = item;
		groupTree.insert(startDate, endDate, item);
	}

	static removeItemFromDataHeatmapGroupCache(pageComponent, groupId, item) {
		const cache = this.getHeatmapGroupDataCache(pageComponent);

		const groupTree = cache.get(groupId);
		if (groupTree) {
			const {startDate, endDate} = item.getVisibleItemsTreeDates();
			const removed = groupTree.remove(startDate, endDate, item);

			if (removed) {
				this.deleteDataHeatmapGroupEntryIfEmpty(cache, groupTree, groupId);
			}
		}
	}

	static addTimeRegToDataHeatmapGroupCache(pageComponent, groupId, timeReg) {
		const cache = this.getHeatmapGroupDataCache(pageComponent);

		let groupTree = cache.get(groupId);
		if (!groupTree) {
			groupTree = new IntervalTree();
			cache.set(groupId, groupTree);
		}

		const {canvasTimelineDate} = timeReg;
		groupTree.insert(canvasTimelineDate, canvasTimelineDate, timeReg);
	}

	static removeTimeRegFromDataHeatmapGroupCache(pageComponent, groupId, timeReg) {
		const cache = this.getHeatmapGroupDataCache(pageComponent);

		const groupTree = cache.get(groupId);
		if (groupTree) {
			const {canvasTimelineDate} = timeReg;
			const removed = groupTree.remove(canvasTimelineDate, canvasTimelineDate, timeReg);

			if (removed) {
				this.deleteDataHeatmapGroupEntryIfEmpty(cache, groupTree, groupId);
			}
		}
	}

	static deleteDataHeatmapGroupEntryIfEmpty(cache, groupTree, groupId) {
		const existingHeatmapData = groupTree.search(MIN_CANVAS_DATE, MAX_CANVAS_DATE);

		if (existingHeatmapData.length === 0) {
			cache.delete(groupId);
		}
	}

	static addDataHeatmapGroupToCache(pageComponent, groupId, items, timeRegs) {
		const hasItems = items?.length > 0;
		const hasTimeRegs = timeRegs?.length > 0;

		if (hasItems || hasTimeRegs) {
			const cache = this.getHeatmapGroupDataCache(pageComponent);

			let groupTree = cache.get(groupId);
			if (!groupTree) {
				groupTree = new IntervalTree();
				cache.set(groupId, groupTree);
			}

			if (hasItems) {
				for (const item of items) {
					const {startDate, endDate} = item;
					groupTree.insert(startDate, endDate, item);
				}
			}

			if (hasTimeRegs) {
				for (const timeReg of timeRegs) {
					const {canvasTimelineDate} = timeReg;
					groupTree.insert(canvasTimelineDate, canvasTimelineDate, timeReg);
				}
			}
		}
	}

	static constructVisibleItemTrees(pageComponent) {
		const {groups} = pageComponent.state;

		const startFindingGroups = performance.now();

		this.resetVisibleItemsMaps();

		const heatmapGroups = this.findHeatmapGroups(groups);

		console.log('done finding groups: ', Math.round(performance.now() - startFindingGroups));

		const startTree = performance.now();

		const hasCombinedPerformanceImprovements = hasFeatureFlag('combined_mode_performance_improvements');

		if (hasCombinedPerformanceImprovements) {
			this.initEmptyHeatmapGroupsCache(pageComponent);
		}

		if (heatmapGroups?.length > 0) {
			const {items} = pageComponent.state;

			if (hasCombinedPerformanceImprovements) {
				const groupItemsMap = new Map();

				for (const item of items) {
					const groupId = IDManager.getHeatmapGroupIdFromItem(pageComponent, item);

					if (groupId) {
						let groupItems = groupItemsMap.get(groupId);
						if (!groupItems) {
							groupItems = [];
							groupItemsMap.set(groupId, groupItems);
						}

						groupItems.push(item);
					}
				}

				for (const group of heatmapGroups) {
					const intervalTree = new IntervalTree();

					const groupItems = groupItemsMap.get(group.id);
					if (groupItems?.length > 0) {
						for (const item of groupItems) {
							const {startDate, endDate} = item;

							const insertSuccess = intervalTree.insert(startDate, endDate, item);
							if (insertSuccess) {
								item.setVisibleItemsTreeDates();
								item.addHeatmapGroupId(group.id);
							}
						}
					}

					let timeRegs;
					if (group.groupType === GROUP_TYPE.PERSON) {
						const {personId} = group.data;

						timeRegs = DataManager.searchTimeRegsSearchTree(
							pageComponent,
							personId,
							null,
							MIN_CANVAS_DATE,
							MAX_CANVAS_DATE
						);
					} else if (group.groupType === GROUP_TYPE.PROJECT) {
						const {personId, projectId, projectIds} = group.data;

						timeRegs = projectId
							? DataManager.searchTimeRegsSearchTree(
									pageComponent,
									personId,
									projectId,
									MIN_CANVAS_DATE,
									MAX_CANVAS_DATE
							  )
							: [];

						if (projectIds) {
							for (const projectId of projectIds) {
								timeRegs = timeRegs.concat(
									DataManager.searchTimeRegsSearchTree(
										pageComponent,
										personId,
										projectId,
										MIN_CANVAS_DATE,
										MAX_CANVAS_DATE
									)
								);
							}
						}
					}

					this.addDataHeatmapGroupToCache(pageComponent, group.id, groupItems, timeRegs);

					group.setVisibleItemsTree(intervalTree);
				}
			} else {
				const {items} = pageComponent.state;
				const groupItems = new Set(items);

				for (let i = 0; i < heatmapGroups.length; i++) {
					const group = heatmapGroups[i];

					const visibleItemsTree = new IntervalTree();

					for (const item of groupItems) {
						if (group.isHeatmapItem(item)) {
							const {startDate, endDate} = item;
							const insertSuccess = visibleItemsTree.insert(startDate, endDate, item);

							if (insertSuccess) {
								item.setVisibleItemsTreeDates();
								item.addHeatmapGroupId(group.id);

								if (!this.isMultipleGroupItem(pageComponent, item)) {
									groupItems.delete(item);
								}
							}
						}
					}

					group.setVisibleItemsTree(visibleItemsTree);
				}
			}
		}

		console.log('done constructing tree: ', Math.round(performance.now() - startTree));
	}

	static getHeatmapGroupForGroup(group) {
		if (this.isHeatmapGroup(group)) {
			return group;
		} else if (this.isHeatmapGroup(group?.parentGroup)) {
			return group.parentGroup;
		}
	}

	static updateVisibleItemKey(pageComponent, group, item, updateItem = true) {
		const heatmapGroup = this.getHeatmapGroupForGroup(group);
		if (heatmapGroup) {
			item.addHeatmapGroupId(heatmapGroup.id);
			heatmapGroup.updateVisibleItemKey(item, updateItem);
			this.clearTemporaryVisibleItemsMap();

			if (hasFeatureFlag('combined_mode_performance_improvements')) {
				this.removeItemFromDataHeatmapGroupCache(pageComponent, heatmapGroup.id, item);
				this.addItemToDataHeatmapGroupCache(pageComponent, heatmapGroup.id, item);
			}
		}
	}

	static addVisibleItem(pageComponent, group, item, updateItem = true) {
		const heatmapGroup = this.getHeatmapGroupForGroup(group);
		if (heatmapGroup) {
			item.addHeatmapGroupId(heatmapGroup.id);
			heatmapGroup.insertVisibleItem(item, updateItem);
			this.clearTemporaryVisibleItemsMap();

			if (hasFeatureFlag('combined_mode_performance_improvements')) {
				this.addItemToDataHeatmapGroupCache(pageComponent, heatmapGroup.id, item);
			}
		}
	}

	static removeVisibleItem(pageComponent, group, item) {
		const heatmapGroup = this.getHeatmapGroupForGroup(group);
		if (heatmapGroup) {
			item.removeHeatmapGroupId(heatmapGroup.id);

			if (hasFeatureFlag('combined_mode_performance_improvements')) {
				this.removeItemFromDataHeatmapGroupCache(pageComponent, heatmapGroup.id, item);
			}

			return heatmapGroup.removeVisibleItem(item);
		}

		return false;
	}

	static addItem(pageComponent, item) {
		const {groups, collapsableSectionGroups, items} = pageComponent.state;
		const group = this.findHeatmapGroupById(item.groupId, groups, collapsableSectionGroups);

		if (group) {
			this.recalculateGroupHeatmap(pageComponent, group, item);
			this.addVisibleItem(pageComponent, group, item);
		}

		items.push(item);
	}

	static addItemForMultipleGroups(pageComponent, item, groupPredicate = null) {
		const {groups, items} = pageComponent.state;
		const heatmapGroups = this.findHeatmapGroups(groups, groupPredicate);

		if (heatmapGroups?.length > 0) {
			for (const heatmapGroup of heatmapGroups) {
				this.addVisibleItem(pageComponent, heatmapGroup, item);
				this.recalculateGroupHeatmap(pageComponent, heatmapGroup, item);
			}
		}

		items.push(item);
	}

	static removeItemForMultipleGroups(pageComponent, itemPredicate, groupPredicate = null) {
		const {groups, items} = pageComponent.state;
		const heatmapGroups = this.findHeatmapGroups(groups, groupPredicate);

		const index = items.findIndex(itemPredicate);
		const item = items[index];

		if (heatmapGroups?.length > 0) {
			for (const heatmapGroup of heatmapGroups) {
				this.removeVisibleItem(pageComponent, heatmapGroup, item);
				this.recalculateGroupHeatmap(pageComponent, heatmapGroup, item);
			}
		}

		items.splice(index, 1);
	}

	static removeItem(pageComponent, itemPredicate) {
		const {groups, collapsableSectionGroups, items} = pageComponent.state;

		const index = items.findIndex(itemPredicate);
		const item = items[index];

		if (item) {
			const group = this.findHeatmapGroupById(item.groupId, groups, collapsableSectionGroups);

			if (group) {
				this.removeVisibleItem(pageComponent, group, item);
				this.recalculateGroupHeatmap(pageComponent, group, item);
			}

			items.splice(index, 1);
		}
	}

	static removeMultipleItems(pageComponent, itemPredicate) {
		const {groups, collapsableSectionGroups, items} = pageComponent.state;

		const newItemsList = [];
		for (let i = 0; i < items.length; i++) {
			const item = items[i];

			if (itemPredicate(item)) {
				const group = this.findHeatmapGroupById(item.groupId, groups, collapsableSectionGroups);
				this.removeVisibleItem(pageComponent, group, item);
				this.recalculateGroupHeatmap(pageComponent, group, item);
			} else {
				newItemsList.push(item);
			}
		}

		pageComponent.setState({items: newItemsList});
	}

	static moveItem(pageComponent, item, itemData, group, newGroup = null, updateItem = false) {
		if (itemData) {
			const isMovingToNewGroup = newGroup && group && newGroup.id !== group.id;

			if (isMovingToNewGroup) {
				DataManager.removeVisibleItem(pageComponent, group, item);
			}

			if (group) {
				// If item startDate/endDate were to change, we need to call recalculateGroupHeatmap both before and after updateData.
				this.recalculateGroupHeatmap(pageComponent, group, item);
			}

			item.resetItemRow();
			item.updateData(itemData, updateItem);

			if (group) {
				this.recalculateGroupHeatmap(pageComponent, newGroup || group, item);

				if (isMovingToNewGroup) {
					DataManager.addVisibleItem(pageComponent, newGroup, item);
				} else {
					DataManager.updateVisibleItemKey(pageComponent, group, item);
				}
			}
		}
	}

	static moveItemGroupTemporarily(pageComponent, group, heatmapGroup, item) {
		if (this.isHeatmapGroup(heatmapGroup) && !temporaryVisibleItemsMap.has(heatmapGroup.id)) {
			const {groups, collapsableSectionGroups} = pageComponent.state;
			const currentGroup = this.findHeatmapGroupById(item.groupId, groups, collapsableSectionGroups);

			if (this.isHeatmapGroup(currentGroup)) {
				const currentVisibleItem = temporaryVisibleItemsMap.get(currentGroup.id);

				if (currentVisibleItem) {
					temporaryVisibleItemsMap.delete(currentGroup.id);
					this.recalculateGroupHeatmap(pageComponent, currentGroup, currentVisibleItem);
				} else {
					this.removeVisibleItem(pageComponent, currentGroup, item);
					this.recalculateGroupHeatmap(pageComponent, currentGroup, item);
				}
			} else {
				// clearing for item group that no longer exists
				this.clearTemporaryVisibleItemsMap();
			}

			item.groupId = group.id;
			item.resetItemRow();

			temporaryVisibleItemsMap.set(heatmapGroup.id, item);
		}
	}

	static recalculateGroupHeatmap(pageComponent, group, item, intervalOverride = undefined) {
		if (group) {
			const itemInterval = intervalOverride || interval(item.startDate, item.endDate);
			recalculateGroupHeatmapCache(pageComponent, group.id, itemInterval);
			if (this.isHeatmapGroup(group.parentGroup)) {
				recalculateGroupHeatmapCache(pageComponent, group.parentGroup.id, itemInterval);
			}
		}
	}

	static moveItemTemporarily(pageComponent, item) {
		const {groups, collapsableSectionGroups} = pageComponent.state;
		const heatmapGroup = this.findHeatmapGroupById(item.groupId, groups, collapsableSectionGroups);

		if (heatmapGroup && !temporaryVisibleItemsMap.has(heatmapGroup.id)) {
			this.removeVisibleItem(pageComponent, heatmapGroup, item);
			temporaryVisibleItemsMap.set(heatmapGroup.id, item);
		}
	}

	static getVisibleItemsData(pageComponent, group, startDate, endDate) {
		const includeFilteredItems = !pageComponent.state.heatmapFiltering;

		const taskItems = [];
		const allocationItems = [];
		const placeholderAllocationItems = [];

		let visibleItems = group.searchVisibleItemsTree(startDate, endDate);

		const temporaryVisibleItem = temporaryVisibleItemsMap.get(group.id);
		if (temporaryVisibleItem) {
			visibleItems.push(temporaryVisibleItem);
		}

		for (let i = 0; i < visibleItems.length; i++) {
			const visibleItem = visibleItems[i];

			if (!includeFilteredItems && visibleItem.filtered) {
				continue;
			}

			if (isTaskItem(visibleItem)) {
				taskItems.push(visibleItem);
				continue;
			}

			if (isProjectAllocationItem(visibleItem)) {
				allocationItems.push(visibleItem);
				continue;
			}

			if (isPlaceholderAllocationItem(visibleItem)) {
				placeholderAllocationItems.push(visibleItem);
			}
		}

		return {
			allocationItems,
			placeholderAllocationItems,
			taskItems,
		};
	}

	static getLookupMap(pageComponent, key) {
		const {lightWeightData, data} = pageComponent.state;
		const availableData = data || lightWeightData;

		if (availableData) {
			return availableData[key];
		}

		return null;
	}

	static getProjectPersonsByPersonId(pageComponent, personId) {
		const key = LOOKUP_MAPS.PROJECT_PERSON_BY_PERSON_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.[personId] || [];
	}

	static getProjectPersonsByProjectId(pageComponent, projectId) {
		const key = LOOKUP_MAPS.PROJECT_PERSON_BY_PROJECT_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.[projectId] || [];
	}

	static getProjectById(pageComponent, projectId) {
		const key = LOOKUP_MAPS.PROJECT_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(projectId);
	}

	static getProjectGroupById(pageComponent, projectGroupId) {
		const key = LOOKUP_MAPS.PROJECT_GROUP_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(projectGroupId);
	}

	static getProjectOrProjectGroupById(pageComponent, projectId, projectGroupId) {
		if (projectId) {
			return this.getProjectById(pageComponent, projectId);
		}

		if (projectGroupId) {
			return this.getProjectGroupById(pageComponent, projectGroupId);
		}

		return null;
	}

	static getIdleTimeById(pageComponent, idleTimeId) {
		const key = LOOKUP_MAPS.IDLE_TIME_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(idleTimeId);
	}

	static getTaskById(pageComponent, taskId) {
		const key = LOOKUP_MAPS.TASK_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(taskId);
	}

	static findHeatmapGroupByPredicate(predicate, groups = []) {
		return this.findHeatmapGroupRecursively(predicate, groups);
	}

	static findHeatmapGroupById(groupId, groups = [], additionalGroups = []) {
		let group = groupByChildGroupId.get(groupId);

		if (!group) {
			group = this.findHeatmapGroupRecursively(
				group => group.id === groupId || group.groupIds?.includes(groupId),
				groups
			);
			if (!group) {
				group = this.findHeatmapGroupRecursively(
					group => group.id === groupId || group.groupIds?.includes(groupId),
					additionalGroups
				);
			}
			groupByChildGroupId.set(groupId, group);
		}

		return group;
	}

	static findHeatmapGroupRecursively(predicate, groups = [], parentGroup = null) {
		if (groups?.length > 0) {
			for (const group of groups) {
				const {groups: subGroups} = group;

				if (predicate(group)) {
					const targetGroup = this.isHeatmapGroup(group)
						? group
						: this.isHeatmapGroup(parentGroup)
						? parentGroup
						: null;

					if (targetGroup) {
						return targetGroup;
					}
				}

				if (subGroups?.length > 0) {
					const heatmapGroup = this.findHeatmapGroupRecursively(predicate, subGroups, group);

					if (heatmapGroup) {
						return heatmapGroup;
					}
				}
			}
		}

		return null;
	}

	static findHeatmapGroups(groups, predicate = () => true, heatmapGroups = []) {
		if (groups?.length > 0) {
			for (const group of groups) {
				if (this.isHeatmapGroup(group) && predicate(group)) {
					heatmapGroups.push(group);
				}

				if (group.groups?.length > 0) {
					this.findHeatmapGroups(group.groups, predicate, heatmapGroups);
				}
			}
		}

		return heatmapGroups;
	}

	static getProjectPersonsByProjectGroupId(pageComponent, projectGroupId) {
		if (projectGroupId) {
			const projects = this.getProjectsByProjectGroupId(pageComponent, projectGroupId);

			if (projects) {
				const {isProjectTimeline, projectId} = pageComponent.props;

				let projectPersonProjectId;
				if (isProjectTimeline && projectId) {
					const {data} = pageComponent.state;

					const viewedProjectId = parseInt(pageComponent.props.projectId, 10);
					const viewedProject = data.projects.find(
						project =>
							project.customProjectId === pageComponent.props.projectId ||
							project.companyProjectId === viewedProjectId
					);

					if (viewedProject) {
						projectPersonProjectId = viewedProject.id;
					}
				} else {
					projectPersonProjectId = projects[0].id;
				}

				if (projectPersonProjectId) {
					return this.getProjectPersonsByProjectId(pageComponent, projectPersonProjectId);
				}
			}
		}

		return [];
	}

	static getProjectsByProjectGroupId(pageComponent, projectGroupId) {
		const key = LOOKUP_MAPS.PROJECT_BY_PROJECT_GROUP_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.[projectGroupId] || [];
	}

	static getProjectsByProgramPrefix(pageComponent, programPrefix) {
		const key = LOOKUP_MAPS.PROJECT_BY_PROGRAM_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.[programPrefix] || [];
	}

	static getProgram(pageComponent, programId) {
		const key = LOOKUP_MAPS.PROGRAM_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(programId);
	}

	static getRoleById(pageComponent, roleId) {
		const key = LOOKUP_MAPS.ROLE_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(roleId);
	}

	static getDepartmentById(pageComponent, departmentId) {
		const key = LOOKUP_MAPS.DEPARTMENT_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(departmentId);
	}

	static getPersonLabelsByPersonId(pageComponent, personId) {
		const key = LOOKUP_MAPS.PERSON_LABEL_BY_PERSON_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.[personId] || [];
	}

	static getPersonSkillsByPersonId(pageComponent, personId) {
		const key = LOOKUP_MAPS.PERSON_SKILLS_BY_PERSON_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.[personId] || [];
	}

	static getAllocationsByPersonId(pageComponent, personId) {
		const key = LOOKUP_MAPS.PERSON_ALLOCATION_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.[personId] || [];
	}

	static getAllocationsByProject(pageComponent, projectId, projectGroupId) {
		const key = LOOKUP_MAPS.ALLOCATIONS_BY_PROJECT_OR_PROJECT_GROUP.KEY;
		return this.getLookupMap(pageComponent, key)?.[projectId || projectGroupId] || [];
	}

	static getPersonById(pageComponent, personId) {
		const key = LOOKUP_MAPS.PERSON_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(personId);
	}

	static getPlaceholderAllocationByPlaceholderId(pageComponent, placeholderId) {
		const key = LOOKUP_MAPS.PLACEHOLDER_ALLOCATIONS_BY_PLACEHOLDER.KEY;
		return this.getLookupMap(pageComponent, key)?.[placeholderId] || [];
	}

	static getPlaceholderSkillsByPlaceholder(pageComponent, placeholderId) {
		const key = LOOKUP_MAPS.PLACEHOLDER_SKILLS_BY_PLACEHOLDER.KEY;
		return this.getLookupMap(pageComponent, key)?.[placeholderId] || [];
	}

	static getClientById(pageComponent, clientId) {
		const key = LOOKUP_MAPS.CLIENT_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(clientId);
	}

	static getPlaceholderById(pageComponent, placeholderId) {
		const key = LOOKUP_MAPS.PLACEHOLDER_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(placeholderId);
	}

	static getPhaseById(pageComponent, phaseId) {
		const key = LOOKUP_MAPS.PHASE_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(phaseId);
	}

	static getSubTasksByParentTaskId(pageComponent, parentTaskId) {
		const key = LOOKUP_MAPS.PARENT_TASK_TO_SUB_TASK_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.[parentTaskId] || [];
	}

	static getTasksByPhaseId(pageComponent, phaseId) {
		const key = LOOKUP_MAPS.TASKS_BY_PHASE_ID.KEY;
		return this.getLookupMap(pageComponent, key)?.[phaseId] || [];
	}

	static getPhaseIdsByProjectId(pageComponent, projectId) {
		const key = LOOKUP_MAPS.PROJECT_ID_TO_PHASE_IDS_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.[projectId] || [];
	}

	static getPlaceholdersByProjectOrProjectGroupId(pageComponent, projectId, projectGroupId) {
		const key = LOOKUP_MAPS.PLACEHOLDERS_BY_PROJECT_OR_PROJECT_GROUP.KEY;
		return this.getLookupMap(pageComponent, key)?.[projectId || projectGroupId] || [];
	}

	static getTimeRegsByPersonId(pageComponent, personId) {
		const key = LOOKUP_MAPS.TIME_REGS_BY_PERSON_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.[personId] || [];
	}

	static getTimeRegsByPersonProjectId(pageComponent, personId, projectId) {
		return this.getLookupMap(pageComponent, 'timeRegsByPersonProjectMap')?.get(`${personId}-${projectId}`) || [];
	}

	static getPersonTimeRegsByPersonId(pageComponent, personId) {
		return this.getLookupMap(pageComponent, 'timeRegsByPersonIdMap')?.get(personId) || [];
	}

	static getTimeRegById(pageComponent, timeRegId) {
		const key = LOOKUP_MAPS.TIME_REG_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(timeRegId);
	}

	static searchTimeRegsSearchTree(pageComponent, personId, projectId, startDate, endDate, timeRegMap = null) {
		const personTimeRegMap = timeRegMap || this.getLookupMap(pageComponent, 'timeRegSearchTreeMap')?.get(personId);
		if (personTimeRegMap) {
			const searchTree = personTimeRegMap.get(projectId || null);

			if (searchTree) {
				return searchTree.betweenBounds({$gte: startDate, $lte: endDate});
			}
		}

		return [];
	}

	static getHeatmapGroupDataCache(pageComponent) {
		const data = pageComponent.getData();
		return data['heatmapGroupDataCache'];
	}

	static hasAnyHeatmapRelevantDataInStep(pageComponent, groupId, startDate, endDate) {
		const {timeline} = pageComponent;
		if (timeline.dragData && temporaryVisibleItemsMap.size > 0) {
			let temporaryVisibleItem = temporaryVisibleItemsMap.get(groupId);

			if (temporaryVisibleItem && isItemWithinStep(temporaryVisibleItem, startDate, endDate)) {
				return true;
			}
		}

		const cache = this.getLookupMap(pageComponent, 'heatmapGroupDataCache');
		if (cache) {
			const groupTree = cache.get(groupId);

			if (groupTree) {
				const relevantData = groupTree.search(startDate, endDate);
				return relevantData?.length > 0;
			}

			return false;
		}

		// if cache is not set we expect this to be because the performance ff is not enabled
		return true;
	}

	static getAnonymizedTaskById(pageComponent, taskId) {
		const key = LOOKUP_MAPS.ANONYMIZED_ID_TASK_MAP.KEY;
		return this.getLookupMap(pageComponent, key)?.get(taskId);
	}

	static recalculatePersonNonWorkingDaysMap(pageComponent, personId) {
		calculateNonWorkingDaysMap(pageComponent, personId);
	}

	static constructNonWorkingDaysMap(pageComponent) {
		calculateNonWorkingDaysMap(pageComponent, null);
	}

	static updateItemsAffectedByTimeOff(pageComponent, personId, groupId, deltaInterval) {
		const {schedulingOptions} = pageComponent.state;
		const {company, nonWorkingDaysMap} = pageComponent.getFilterData();
		const isInCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

		DataManager.recalculatePersonNonWorkingDaysMap(pageComponent, personId);
		clearPersonHeatmapMetaDataStepCache(personId, true);

		const heatmapGroup = DataManager.findHeatmapGroupById(groupId, pageComponent.state.groups);
		if (heatmapGroup) {
			const updateVisibleItemsForGroup = group => {
				const visibleItems = group.searchVisibleItemsTree(deltaInterval[0], deltaInterval[1], isInCombinedMode);

				for (let i = 0; i < visibleItems.length; i++) {
					const visibleItem = visibleItems[i];

					if (isProjectAllocationItem(visibleItem) && !isTimeOffAllocation(visibleItem.data.allocation)) {
						visibleItem.data.totalHours =
							getAllocationTotalNew(visibleItem.data.allocation, nonWorkingDaysMap) / 60;
						visibleItems[i].updateData(visibleItem.data);
						DataManager.recalculateItemGroupHeatmap(pageComponent, visibleItem);
					} else if (isTaskItem(visibleItem)) {
						DataManager.recalculateItemGroupHeatmap(pageComponent, visibleItem);
					}
				}
			};

			updateVisibleItemsForGroup(heatmapGroup);

			if (isInCombinedMode && hasFeatureFlag('combined_mode_performance_improvements')) {
				heatmapGroup.groups.forEach(projectGroup => updateVisibleItemsForGroup(projectGroup));
			}
		}
	}

	static recalculateItemGroupHeatmap(pageComponent, item, interval = undefined) {
		const {groups, collapsableSectionGroups} = pageComponent.state;
		const itemGroup = DataManager.findHeatmapGroupById(item.groupId, groups, collapsableSectionGroups);
		DataManager.recalculateGroupHeatmap(pageComponent, itemGroup, item, interval);
	}

	static recalculateDrawnStepsForNewGroup(pageComponent, group) {
		if (hasFeatureFlag('combined_mode_performance_improvements')) {
			if (hasDrawnAnySteps() && group.calculateHeatmapCache !== undefined) {
				const {timeline} = pageComponent;
				clearDrawnStepDataArrayMap();
				clearDrawnStepsMap();
				timeline.setRedrawRecalculationProperties();
			}
		}
	}
}
