/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ProjectPortfolioReport_viewer$ref = any;
export type ProjectPortfolioReport_QueryVariables = {||};
export type ProjectPortfolioReport_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: ProjectPortfolioReport_viewer$ref,
  |}
|};
export type ProjectPortfolioReport_Query = {|
  variables: ProjectPortfolioReport_QueryVariables,
  response: ProjectPortfolioReport_QueryResponse,
|};
*/


/*
query ProjectPortfolioReport_Query {
  viewer {
    actualPersonId
    component(name: "project_portfolio_report")
    ...ProjectPortfolioReport_viewer
    id
  }
}

fragment ProjectPortfolioReportHeaderBar_viewer on Viewer {
  id
  availableFeatureFlags {
    key
    id
  }
  company {
    tier
    modules {
      moduleType
      id
    }
    priorityLevels {
      edges {
        node {
          id
        }
      }
    }
    id
  }
  filters(first: 1000000, filterSection: FINANCIAL_PORTFOLIO_REPORT) {
    edges {
      node {
        id
        name
        section
        value
        updatedAt
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ProjectPortfolioReportTitleBar_viewer on Viewer {
  email
  actualPersonId
}

fragment ProjectPortfolioReport_viewer on Viewer {
  id
  actualPersonId
  company {
    createdAt
    isFinancialServiceAvailable
    modules {
      moduleType
      id
    }
    priorityLevels {
      edges {
        node {
          id
        }
      }
    }
    id
  }
  ...ProjectPortfolioReportTitleBar_viewer
  ...ProjectPortfolioReportHeaderBar_viewer
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "project_portfolio_report"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"project_portfolio_report\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "filterSection",
    "value": "FINANCIAL_PORTFOLIO_REPORT"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000000
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectPortfolioReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProjectPortfolioReport_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProjectPortfolioReport_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isFinancialServiceAvailable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moduleType",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PriorityLevelTypeConnection",
                "kind": "LinkedField",
                "name": "priorityLevels",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PriorityLevelTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriorityLevel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tier",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FeatureFlag",
            "kind": "LinkedField",
            "name": "availableFeatureFlags",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "FilterTypeConnection",
            "kind": "LinkedField",
            "name": "filters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FilterTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Filter",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "section",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "filters(filterSection:\"FINANCIAL_PORTFOLIO_REPORT\",first:1000000)"
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [],
            "handle": "connection",
            "key": "Viewer_filters",
            "kind": "LinkedHandle",
            "name": "filters"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "ProjectPortfolioReport_Query",
    "operationKind": "query",
    "text": "query ProjectPortfolioReport_Query {\n  viewer {\n    actualPersonId\n    component(name: \"project_portfolio_report\")\n    ...ProjectPortfolioReport_viewer\n    id\n  }\n}\n\nfragment ProjectPortfolioReportHeaderBar_viewer on Viewer {\n  id\n  availableFeatureFlags {\n    key\n    id\n  }\n  company {\n    tier\n    modules {\n      moduleType\n      id\n    }\n    priorityLevels {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    id\n  }\n  filters(first: 1000000, filterSection: FINANCIAL_PORTFOLIO_REPORT) {\n    edges {\n      node {\n        id\n        name\n        section\n        value\n        updatedAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ProjectPortfolioReportTitleBar_viewer on Viewer {\n  email\n  actualPersonId\n}\n\nfragment ProjectPortfolioReport_viewer on Viewer {\n  id\n  actualPersonId\n  company {\n    createdAt\n    isFinancialServiceAvailable\n    modules {\n      moduleType\n      id\n    }\n    priorityLevels {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    id\n  }\n  ...ProjectPortfolioReportTitleBar_viewer\n  ...ProjectPortfolioReportHeaderBar_viewer\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'beb4ca88e65b57711d761401b6972d28';

module.exports = node;
