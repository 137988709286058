/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaskReportTable_viewer$ref: FragmentReference;
declare export opaque type TaskReportTable_viewer$fragmentType: TaskReportTable_viewer$ref;
export type TaskReportTable_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string,
    +taskTotals: ?{|
      +registeredMinutes: ?number,
      +estimatePoints: ?number,
      +estimateMinutes: ?number,
      +remainingPoints: ?number,
      +remainingMinutes: ?number,
      +plannedRevenue: ?number,
      +actualRevenue: ?number,
      +remainingRevenue: ?number,
      +forecastRevenue: ?number,
      +recognitionLockedRevenue: ?number,
      +recognitionOpenRevenue: ?number,
      +recognitionForecastRevenue: ?number,
      +totalActualRevenueRecognition: ?number,
      +totalForecastRevenueToComplete: ?number,
      +surplus: ?number,
      +numberOfTasks: ?number,
    |},
  |},
  +$refType: TaskReportTable_viewer$ref,
|};
export type TaskReportTable_viewer$data = TaskReportTable_viewer;
export type TaskReportTable_viewer$key = {
  +$data?: TaskReportTable_viewer$data,
  +$fragmentRefs: TaskReportTable_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskReportTable_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            }
          ],
          "concreteType": "TaskTotals",
          "kind": "LinkedField",
          "name": "taskTotals",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "registeredMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "estimatePoints",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "estimateMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remainingPoints",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remainingMinutes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plannedRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actualRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "remainingRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forecastRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionLockedRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionOpenRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionForecastRevenue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalActualRevenueRecognition",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalForecastRevenueToComplete",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "surplus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "numberOfTasks",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = 'c4d20723ef05f80d8bd60a5a60ef3338';

module.exports = node;
