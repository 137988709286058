import React, {Component} from 'react';
import {oldColors} from '../../constants';
import PropTypes from 'prop-types';
import {findDOMNode} from 'react-dom';

class TimelinePhaseItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showPercentage: true,
			showDone: true,
			showTriangles: true,
		};
	}

	componentDidMount() {
		//need to do this because items are not updated correctly when they are out of view and then user scrolls to them
		setInterval(this.updatePhaseItemText.bind(this), 500);
	}

	UNSAFE_componentWillReceiveProps() {
		if (!this.state.isInitialized) {
			//Launch immediately at start so numbers show up as soon as you expand phase
			this.updatePhaseItemText();
		}
	}

	updatePhaseItemText() {
		const phaseItemContainerBoundingRect = findDOMNode(this.phaseItemContainer).getBoundingClientRect();
		const phaseItemContainerWidth = Math.abs(phaseItemContainerBoundingRect.left - phaseItemContainerBoundingRect.right);
		if (phaseItemContainerWidth === 0) return;
		let showDone = phaseItemContainerWidth > 160;
		let showPercentage = phaseItemContainerWidth > 80;
		let showTriangles = phaseItemContainerWidth > 50;
		this.setState({showDone, showPercentage, showTriangles, isInitialized: true});
	}

	render() {
		const item = this.props.item;
		const tasks = this.props.tasks;

		const approvedPhaseForecast = tasks.reduce((total, tmpTask) => total + tmpTask.estimateForecast, 0);
		const approvedPhaseRemaining = tasks.reduce((total, tmpTask) => total + tmpTask.timeLeft, 0);
		const numberOfDoneTasks = tasks.filter(task => task.statusColumn.category === 'DONE').length;
		let progress = 0;
		if (approvedPhaseForecast === 0) {
			progress = tasks.length !== 0 ? parseInt((numberOfDoneTasks / tasks.length) * 100, 10) : 0;
		} else {
			progress = Math.round(((approvedPhaseForecast - approvedPhaseRemaining) * 100) / approvedPhaseForecast);
		}

		const backgroundColor = oldColors[item.phaseOrder % oldColors.length];
		const rightTriangleOpacity = progress === 100 ? 1 : 0.5;
		const leftTriangleOpacity = progress === 0 ? 0.5 : 1;
		return (
			<div
				className="project-timeline-phase-item"
				ref={e => {
					this.phaseItemContainer = e;
				}}
			>
				{item.id === 'phase-item-out-of-scope' ? (
					<div className="outer-container no-scope">
						<div className="inner-container">
							<div className="text-container">
								<h3>&nbsp;</h3>
								<h3>&nbsp;</h3>
							</div>
							<div className="progress-container">
								<div className="progress" />
							</div>
							{this.state.showTriangles ? (
								<div className="triangle-container">
									<div className="triangle" />
									<div className="triangle" />
								</div>
							) : (
								<div className="triangle-placeholder" />
							)}
						</div>
					</div>
				) : (
					<div className="outer-container">
						<div className="inner-container">
							<div className="text-container">
								<h3>{this.state.showPercentage ? progress + '%' : ''}</h3>
								<h3>{this.state.showDone ? 'done' : ''}</h3>
							</div>
							<div className="progress-container">
								<div className="progress" style={{width: progress + '%', backgroundColor}} />
								<div className="fill" style={{backgroundColor}} />
							</div>
							{this.state.showTriangles ? (
								<div className="triangle-container">
									<div
										className="triangle"
										style={{borderTopColor: backgroundColor, opacity: leftTriangleOpacity}}
									/>
									<div
										className="triangle"
										style={{borderTopColor: backgroundColor, opacity: rightTriangleOpacity}}
									/>
								</div>
							) : (
								<div className="triangle-placeholder" />
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

TimelinePhaseItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default TimelinePhaseItem;
