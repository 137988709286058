import React, {useEffect, useMemo, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {createFragmentContainer, graphql} from 'react-relay';
import {SubNavigation} from 'web-components';
import {FormattedMessage, useIntl} from 'react-intl';
import {PageWrapper, StandardMargin} from './UtilizationReport.styled';
import {HeaderBar} from './HeaderBar';
import UtilizationReportTotals, {UtilizationReportTotalsQuery} from './UtilizationReportTotals';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import {convertProjectAndPersonSearchFilters, filtersToSearchQuery} from './UtilizationReportUtils';
import Util from '../../../forecast-app/shared/util/util';
import * as tracking from '../../../tracking';
import {useGenericReportContext} from '../../../forecast-app/reports-tab/saved-report/GenericReportContext';
import {
	TopHeaderAllReportsWrapper,
	TopHeaderBar,
	TopHeaderBarWrapper,
} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {getPeriodEyeOptions} from './Period';
import {getInitialOptionsFromCheckedOptions} from '../../../the_eye_util';
import moment from 'moment';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {isFeatureHidden} from '../../../forecast-app/shared/util/CompanySetupUtil';
import {HIDDEN_FEATURES} from '../../../constants';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackCSVExport, trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {PRESET_DATE_RANGE_PICKER_OPTIONS} from '../../../forecast-app/shared/components/date-picker/preset_date_range_picker';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';

export const UTILIZATION_PAGES = {
	PERIOD: 'period',
	MONTH_BY_MONTH: 'month-by-month',
};

export const UTILIZATION_DROPDOWN = {
	BILLABLE_UTILIZATION: 'forecastBillableUtilization',
	RESOURCE_UTILIZATION: 'forecastResourceUtilization',
};

export const GROUP_BY = {
	NONE: 'none',
	ROLE: 'role',
	DEPARTMENT: 'department',
};

const getPlainLabelFromActivePage = (activePage, options) => {
	const activePageOption = options.find(page => page.value === activePage);
	return activePageOption ? activePageOption.labelPlain : 'Period';
};

export const UtilizationReport = ({viewer}) => {
	const history = useHistory();
	const {pathname} = useLocation();
	const intl = useIntl();
	const getActivePage = () => {
		const pathIndex = pathname.lastIndexOf('/');
		return pathname.substr(pathIndex + 1);
	};

	const [showDatePicker, setShowDatePicker] = useState(true);

	const {
		getTopReportHeader,
		getBottomReportHeader,
		reportName,
		getHasOwnerPermission,
		startDate,
		endDate,
		periodDate,
		theEyeOptionsChecked,
		filterValue,
		firstDropdownValue,
		secondDropdownValue,
		thirdDropdownValue: selectedDateRangeValue,
		fourthDropdownValue,
		handleSetFilters,
		handleSetFilterValue: defaultHandleSetFilterValue,
		handleSetStartDate,
		handleSetEndDate,
		handleSetPeriodDate,
		handleSetFirstDropdownValue,
		handleSetSecondDropdownValue,
		handleSetThirdDropdownValue: handleSetSingleDateRangeValue,
		handleSetFourthDropdownValue,
		isReportService,
	} = useGenericReportContext();

	const hasOwnerPermission = getHasOwnerPermission(viewer.actualPersonId);

	const isUsingAllocations = viewer.company.isUsingProjectAllocation || Util.isMixedAllocationModeEnabled(viewer.company);

	const defaultOptions = getPeriodEyeOptions(
		isUsingAllocations,
		viewer.company.isUsingSchedulingPlanMode,
		Util.isMixedAllocationModeEnabled(viewer.company)
	);

	const theEyeOptionsNestedTree = getInitialOptionsFromCheckedOptions(defaultOptions, theEyeOptionsChecked, isReportService);

	// VARIABLES AND CONSTANTS ------------------------------------------------
	const UTILIZATION_PAGES_NAVIGATION_OPTIONS = [
		{
			value: 'period',
			labelPlain: 'Period',
			label: intl.formatMessage({id: 'common.period'}),
		},
		{
			value: 'month-by-month',
			labelPlain: 'Month By Month',
			label: intl.formatMessage({id: 'common.month_by_month'}),
		},
	];

	const getStartDateAndEndDateByActivePage = () => {
		const activePage = getActivePage();
		if (activePage === UTILIZATION_PAGES.PERIOD) {
			return {
				startDate: startDate,
				endDate: endDate,
			};
		} else {
			return {
				startDate: moment.utc({year: periodDate, month: 0, date: 1}),
				endDate: moment.utc({year: periodDate, month: 11, date: 31}),
			};
		}
	};

	const activePage = getActivePage();

	// STATE ------------------------------------------------------------------
	const selectedDates = getStartDateAndEndDateByActivePage();
	// in report service the selected year is stored in fourthDropdownValue and periodDate is null.
	// for old saved reports the selected year is stored in periodDate (and fourthDropdownValue is actually used to indicate that a report has been converted / moved to report service).
	const selectedYear = periodDate
		? periodDate
		: fourthDropdownValue
		? parseInt(fourthDropdownValue, 10)
		: moment().utc().year();
	const showResourceUtilization =
		isFeatureHidden(HIDDEN_FEATURES.REVENUE) || firstDropdownValue === UTILIZATION_DROPDOWN.RESOURCE_UTILIZATION;
	const groupBy = secondDropdownValue || GROUP_BY.NONE;

	let defaultAllocationControlsOptions = {
		hideSoft: false,
		hideHard: false,
		calcWin: false,
		taskFallback:
			!viewer.company.isUsingProjectAllocation &&
			viewer.company.isUsingMixedAllocation &&
			!hasFeatureFlag('tp_heatmap_spike'),
	};

	// These options should only be active if in Project Allocation mode
	if (fourthDropdownValue && hasFeatureFlag('placeholders') && isUsingAllocations) {
		defaultAllocationControlsOptions = JSON.parse(fourthDropdownValue);
	}

	// ALLOCATION CONTROLS
	const [allocationControlsOptions, setAllocationControlsOptions] = useState(defaultAllocationControlsOptions);

	const onToggleHideSoft = checked => {
		const toggledOptions = {...allocationControlsOptions, hideSoft: checked};
		setAllocationControlsOptions(toggledOptions);
		handleSetFourthDropdownValue(JSON.stringify(toggledOptions));
	};

	const onToggleHideHard = checked => {
		const toggledOptions = {...allocationControlsOptions, hideHard: checked};
		setAllocationControlsOptions(toggledOptions);
		handleSetFourthDropdownValue(JSON.stringify(toggledOptions));
	};

	const onToggleCalcWin = checked => {
		const toggledOptions = {...allocationControlsOptions, calcWin: checked};
		setAllocationControlsOptions(toggledOptions);
		handleSetFourthDropdownValue(JSON.stringify(toggledOptions));
	};

	const setPage = value => {
		const activePage = getActivePage();
		let newPage;
		// If UtilizationReportTotals has not finished loading yet, the page is missing the "period" or "month-by-month"
		// part of url, which will cause history.push to update the url in a wrong way. So if the activePage is not "period" or "month-by-month"
		// it is the reportId, in which case we append "period" or "month-by-month" to it.
		if (activePage !== UTILIZATION_PAGES.PERIOD && activePage !== UTILIZATION_PAGES.MONTH_BY_MONTH) {
			newPage = activePage + '/' + value;
		} else {
			newPage = value;
		}

		history.push(newPage);
	};

	// HANDLERS ---------------------------------------------------------------
	const handleSetActivePage = value => {
		if (activePage !== value) {
			trackEvent(`${getPlainLabelFromActivePage(value, UTILIZATION_PAGES_NAVIGATION_OPTIONS)} Tab`, 'Selected');
			setPage(value);
		}
	};

	const handlePresetDateRangeChange = selectedDateRange => {
		trackEvent('PresetDateRange', 'Selected', {dropdownDateRangeType: selectedDateRange?.option});

		if (selectedDateRange && selectedDateRange.option) {
			handleSetSingleDateRangeValue(selectedDateRange.option);
		}
		if (selectedDateRange && selectedDateRange.option !== PRESET_DATE_RANGE_PICKER_OPTIONS.CUSTOM_RANGE) {
			if (selectedDateRange.option === PRESET_DATE_RANGE_PICKER_OPTIONS.ALL_TIME) {
				// When the selected period is all time
				handleSetStartDate(moment.unix(viewer.company.createdAt / 1000));
				handleSetEndDate(moment().local().endOf('year'));
			} else {
				handleSetStartDate(selectedDateRange.startDate);
				handleSetEndDate(selectedDateRange.endDate);
			}
			setShowDatePicker(false);
		} else {
			setShowDatePicker(true);
		}
	};

	const handleChangeDates = (selectedStartDate, selectedEndDate) => {
		tracking.trackElementClicked('Date selected', {
			startDate: Util.GetYearMonthDateFromMomentDate(selectedStartDate),
			endDate: Util.GetYearMonthDateFromMomentDate(selectedEndDate),
			dateRange: selectedEndDate.diff(selectedStartDate, 'days') + 1,
		});
		trackEvent('Date', 'Selected');
		handleSetStartDate(selectedStartDate);
		handleSetEndDate(selectedEndDate);
	};

	const handleChangeSelectedYear = selectedYear => {
		handleSetPeriodDate(selectedYear);
	};

	useEffect(() => {
		if (!hasPermission(PERMISSION_TYPE.INSIGHTS_READ)) {
			// if user doesnt have access rights to view this page redirect to no access page
			history.push('/not-authorized');
		}
	}, []);

	useEffect(() => {
		tracking.trackPage('Utilisation Report_NEW', null, null, {
			'Tab name': getActivePage(),
		});
	}, [pathname]);

	let csvDownloadFunction = null;
	const handleCsvDownload = () => {
		tracking.trackElementClicked('CSV download');
		trackCSVExport('Utilization Report');
		csvDownloadFunction && csvDownloadFunction();
	};
	const setCsvDownloadFunction = downloadFunction => (csvDownloadFunction = downloadFunction);

	const getTitleHeaderBar = () => {
		const content = [];
		const reportNameProps = {
			defaultTitle:
				intl.formatMessage({id: 'utilization.utilization_report'}) + ', ' + intl.formatMessage({id: 'common.untitled'}),
			placeholder: intl.formatMessage({id: 'time_reg_report.report_name'}),
		};
		if (hasOwnerPermission) {
			content.push(...getTopReportHeader(reportNameProps));
		}

		const onboardingFlows = [
			{
				id: 'Utilization Report - Overview',
				title: 'Overview',
				description: null,
				contentId: '1615119474bItz0383',
			},
		];
		if (activePage === 'period') {
			onboardingFlows.push({
				id: 'Utilization Report - Period',
				title: 'Period View',
				description: null,
				contentId: '1615199238qDiv893',
			});
		}
		if (activePage === 'month-by-month') {
			onboardingFlows.push({
				id: 'Utilization Report Month by Month',
				title: 'Month by Month View',
				description: null,
				contentId: '1615199848xEgx5442',
			});
		}
		content.push({
			id: 'onboarding-component',
			type: TopHeaderBar.TYPE.ONBOARDING,
			title: 'Utilization Report',
			options: onboardingFlows,
			helpCenterLink: 'https://support.forecast.app/hc/en-us/articles/5286588674065',
		});

		return (
			<>
				<TopHeaderAllReportsWrapper>
					<Link to={'/reports'}>
						<FormattedMessage id="common.all_reports" />
					</Link>
				</TopHeaderAllReportsWrapper>
				<TopHeaderBarWrapper>
					<TopHeaderBar title={hasOwnerPermission ? null : reportName} content={content} />
				</TopHeaderBarWrapper>
			</>
		);
	};

	const handleSetFilterValue = changedFilters => {
		defaultHandleSetFilterValue(changedFilters);
		if (hasFeatureFlag('reports_redesign')) {
			const updatedFilters = convertProjectAndPersonSearchFilters(changedFilters);
			handleSetFilters(updatedFilters);
		}
	};

	const getHeaderBar = () => {
		return (
			<HeaderBar
				getBottomReportHeader={getBottomReportHeader}
				viewer={viewer}
				activePage={activePage}
				handlePresetDateRangeChange={handlePresetDateRangeChange}
				initialPresetDateRange={selectedDateRangeValue}
				showDatePicker={showDatePicker}
				onChangeDates={handleChangeDates}
				selectedDates={selectedDates}
				selectedYear={selectedYear}
				onChangeYear={handleChangeSelectedYear}
				utilizationPages={UTILIZATION_PAGES}
				showResourceUtilization={showResourceUtilization}
				groupBy={groupBy}
				handleSetFirstDropdownValue={handleSetFirstDropdownValue}
				handleSetSecondDropdownValue={handleSetSecondDropdownValue}
				handleCsvDownload={handleCsvDownload}
				hideSoft={allocationControlsOptions.hideSoft}
				onToggleHideSoft={onToggleHideSoft}
				hideHard={allocationControlsOptions.hideHard}
				onToggleHideHard={onToggleHideHard}
				calcWin={allocationControlsOptions.calcWin}
				onToggleCalcWin={onToggleCalcWin}
				isReportService={isReportService}
				handleSetFilterValue={handleSetFilterValue}
				theEyeOptionsNestedTree={theEyeOptionsNestedTree}
			/>
		);
	};

	const selectedStartDate = selectedDates.startDate;
	const selectedEndDate = selectedDates.endDate;

	const activePagePlainLabel = useMemo(() => {
		return getPlainLabelFromActivePage(activePage, UTILIZATION_PAGES_NAVIGATION_OPTIONS);
	}, [activePage]);

	useTrackPage('Utilization Report', {tabName: activePagePlainLabel});

	return (
		<PageWrapper>
			{getTitleHeaderBar()}

			<div style={{marginTop: '16px'}}>
				<StandardMargin>
					<SubNavigation>
						{UTILIZATION_PAGES_NAVIGATION_OPTIONS.map(option => (
							<SubNavigation.NavigationItem
								active={activePage === option.value}
								onClick={() => handleSetActivePage(option.value)}
								key={option.value}
								userpilot={'utilization-' + option.value + '-tab'}
							>
								{option.label}
							</SubNavigation.NavigationItem>
						))}
					</SubNavigation>
				</StandardMargin>
			</div>
			<StandardMargin>{getHeaderBar()}</StandardMargin>
			<ForecastQueryRenderer
				query={UtilizationReportTotalsQuery}
				showLoader={false}
				variables={{
					searchQuery: filtersToSearchQuery(filterValue),
					startYear: selectedStartDate ? selectedStartDate.year() : undefined,
					startMonth: selectedStartDate ? selectedStartDate.month() + 1 : undefined,
					startDay: selectedStartDate ? selectedStartDate.date() : undefined,
					endYear: selectedEndDate ? selectedEndDate.year() : undefined,
					endMonth: selectedEndDate ? selectedEndDate.month() + 1 : undefined,
					endDay: selectedEndDate ? selectedEndDate.date() : undefined,
				}}
				render={relayProps => {
					return (
						<UtilizationReportTotals
							theEyeOptionsNestedTree={theEyeOptionsNestedTree}
							startDate={selectedStartDate}
							endDate={selectedEndDate}
							filters={filterValue}
							showResourceUtilization={showResourceUtilization}
							setCsvDownloadFunction={setCsvDownloadFunction}
							intl={intl}
							availableFeatureFlags={viewer.availableFeatureFlags}
							groupBy={groupBy}
							allocationControlsOptions={allocationControlsOptions}
							{...relayProps}
						/>
					);
				}}
			/>
		</PageWrapper>
	);
};

const UtilizationReportQuery = graphql`
	query UtilizationReport_Query {
		viewer {
			actualPersonId
			component(name: "utilization_report")
			...UtilizationReport_viewer
		}
	}
`;

export {UtilizationReportQuery};

export default createFragmentContainer(UtilizationReport, {
	viewer: graphql`
		fragment UtilizationReport_viewer on Viewer {
			id
			actualPersonId
			email
			availableFeatureFlags {
				id
				key
			}
			company {
				createdAt
				isUsingMixedAllocation
				isUsingProjectAllocation
				isUsingSchedulingPlanMode
				modules {
					moduleType
				}
			}
			filters(first: 1000000, filterSection: UTILIZATION_REPORT) @connection(key: "Viewer_filters", filters: []) {
				edges {
					node {
						id
						name
						section
						value
						updatedAt
					}
				}
			}
		}
	`,
});
