/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RoleDropdown_roles$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type RoleRelayWrapper_viewer$ref: FragmentReference;
declare export opaque type RoleRelayWrapper_viewer$fragmentType: RoleRelayWrapper_viewer$ref;
export type RoleRelayWrapper_viewer = {|
  +company: ?{|
    +currency: ?string,
    +taskTotals: ?{|
      +registeredMinutes: ?number,
      +billableActualMinutes: ?number,
      +nonBillableActualMinutes: ?number,
      +estimatePoints: ?number,
      +estimateMinutes: ?number,
      +remainingPoints: ?number,
      +remainingMinutes: ?number,
      +plannedRevenue: ?number,
      +actualRevenue: ?number,
      +remainingRevenue: ?number,
      +forecastRevenue: ?number,
      +recognitionLockedRevenue: ?number,
      +recognitionOpenRevenue: ?number,
      +recognitionForecastRevenue: ?number,
      +totalActualRevenueRecognition: ?number,
      +totalForecastRevenueToComplete: ?number,
      +surplus: ?number,
      +numberOfTasks: ?number,
    |},
    +allRolesGlobalSearch: ?{|
      +pageInfo: {|
        +endCursor: ?string,
        +hasNextPage: boolean,
      |},
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +taskTotals: ?{|
            +registeredMinutes: ?number,
            +billableActualMinutes: ?number,
            +nonBillableActualMinutes: ?number,
            +estimatePoints: ?number,
            +estimateMinutes: ?number,
            +remainingPoints: ?number,
            +remainingMinutes: ?number,
            +plannedRevenue: ?number,
            +actualRevenue: ?number,
            +remainingRevenue: ?number,
            +forecastRevenue: ?number,
            +recognitionLockedRevenue: ?number,
            +recognitionOpenRevenue: ?number,
            +recognitionForecastRevenue: ?number,
            +totalActualRevenueRecognition: ?number,
            +totalForecastRevenueToComplete: ?number,
            +surplus: ?number,
            +numberOfTasks: ?number,
          |},
        |},
        +$fragmentRefs: RoleDropdown_roles$ref,
      |}>,
    |},
  |},
  +$refType: RoleRelayWrapper_viewer$ref,
|};
export type RoleRelayWrapper_viewer$data = RoleRelayWrapper_viewer;
export type RoleRelayWrapper_viewer$key = {
  +$data?: RoleRelayWrapper_viewer$data,
  +$fragmentRefs: RoleRelayWrapper_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Variable",
  "name": "convertToProjectCurrency",
  "variableName": "convertToProjectCurrency"
},
v1 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "registeredMinutes",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "billableActualMinutes",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "nonBillableActualMinutes",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "estimatePoints",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "estimateMinutes",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remainingPoints",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remainingMinutes",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "plannedRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "actualRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remainingRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "forecastRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recognitionLockedRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recognitionOpenRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "recognitionForecastRevenue",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalActualRevenueRecognition",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalForecastRevenueToComplete",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "surplus",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "numberOfTasks",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "convertToProjectCurrency",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleRelayWrapper_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "noRole",
              "value": true
            },
            (v1/*: any*/)
          ],
          "concreteType": "TaskTotals",
          "kind": "LinkedField",
          "name": "taskTotals",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "first",
              "variableName": "pageSize"
            },
            (v1/*: any*/)
          ],
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "allRolesGlobalSearch",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        (v0/*: any*/),
                        (v1/*: any*/)
                      ],
                      "concreteType": "TaskTotals",
                      "kind": "LinkedField",
                      "name": "taskTotals",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RoleDropdown_roles"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e449ac5907501b1181d5df2eb79119b';

module.exports = node;
