import React from 'react';
import {getQueryRenderer} from '../reported_time_page_util';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../constants';
import {CalculationLoader, ProjectIndicatorText, Table} from 'web-components';
import Util from '../../../../forecast-app/shared/util/util';
import {getNumberOfCustomFields} from '../reported_time_table_util';

export const getProjectRow = (
	data,
	currency,
	intl,
	groupIndex,
	searchQuery,
	groupings,
	enabledColumns,
	sortValue,
	hasNextPage,
	fetchMore,
	nextLevelProps,
	tableHeader
) => {
	const numberOfCustomFields = getNumberOfCustomFields(tableHeader);
	const getColumns = project => {
		const hasAccessToProject = project.isNoProject ? true : project.fullAccessToProject;
		const formattedPrice =
			project.totalPrice !== null ? intl.formatNumber(project.totalPrice, {format: 'always_two_decimal'}) : null;
		const formattedCost =
			project.totalCost !== null ? intl.formatNumber(project.totalCost, {format: 'always_two_decimal'}) : null;
		const projectName =
			project.isNoProject === true ? (
				<Table.Column>{intl.formatMessage({id: 'time_reg_report.no_project'})}</Table.Column>
			) : (
				<Table.Column>
					<ProjectIndicatorText
						clearSubPath={true}
						project={project}
						disableLink={project.readOnlyAccess}
						text={hasAccessToProject ? project.name : intl.formatMessage({id: 'timesheets.no_access'})}
					/>
				</Table.Column>
			);

		return [
			projectName,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>{Util.convertMinutesToFullHour(project.totalMinutesRegistered, intl)}</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>
				<span>
					{hasAccessToProject ? (
						formattedPrice !== null ? (
							Util.GetFormattedCurrencySymbol(currency, formattedPrice)
						) : (
							<CalculationLoader />
						)
					) : (
						'-'
					)}
				</span>
			</Table.Column>,
			<Table.Column>
				<span>
					{hasAccessToProject ? (
						formattedCost !== null ? (
							Util.GetFormattedCurrencySymbol(currency, formattedCost)
						) : (
							<CalculationLoader />
						)
					) : (
						'-'
					)}
				</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			[...Array(numberOfCustomFields)].map(_ => <Table.Column />),
		];
	};

	return (
		<Table.Rows
			data={data}
			canExpand={true}
			autoload={hasNextPage && groupIndex === 1}
			loadMore={hasNextPage ? fetchMore : false}
			{...nextLevelProps}
		>
			{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
				const filterField =
					rowData.isNoProject === true
						? GLOBAL_FILTER_FIELD.NON_PROJECT_TIME_REG
						: GLOBAL_FILTER_FIELD.PROJECT_TIME_REG;

				return (
					<>
						<Table.Row canExpandRow={rowData.canExpand} {...tableColumnsProps}>
							{getColumns(rowData)}
						</Table.Row>
						{expanded &&
							getQueryRenderer(
								tableHeader,
								groupings,
								searchQuery,
								groupIndex,
								enabledColumns,
								{field: filterField, operator: GLOBAL_FILTER_OPERATOR.IS, value: [rowData.id]},
								nextLevelProps,
								sortValue
							)}
					</>
				);
			}}
		</Table.Rows>
	);
};
