import Styled from 'styled-components/macro';

export const BudgetDataWrapper = Styled.div`   
    margin-top: 24px;
    margin-bottom: 48px;
`;

export const RevenueRecognitionWrapper = Styled.div`
    margin-top: 40px;
`;

export const ChartAndHighLevelNumbers = Styled.div`
    display: flex;
    margin-bottom: 26px;
`;

export const TimeChartWrapper = Styled.div`
    display: flex;
    margin-bottom: 26px;
`;
