import Util from '../../../../../forecast-app/shared/util/util';
import {createToast} from '../../../../../forecast-app/shared/components/toasts/another-toast/toaster';
import moment from 'moment';
import {isDateInLockedPeriod} from './PeriodLocks';
import ProjectUtil from '../../../../../forecast-app/shared/util/project_util';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';

const REVENUE_AGGREGATES = [
	'baselineRevenue',
	'baselineVsBillableActualTimeAndExpenses',
	'baselineVsBillableTotalTimeAndExpensesAtCompletion',
	'billableActualTimeAndExpenses',
	'billablePlannedTimeAndExpenses',
	'planVsBillableActualTimeAndExpenses',
	'billableForecastTimeAndExpensesToComplete',
	'billableTotalTimeAndExpensesAtCompletion',
	'planVsTotalBillableTimeAndExpensesAtCompletion',
];

const isCloseToZero = value => {
	return Math.round(value * 100) / 100 === 0.0;
};

export const createProjectUpdateToast = (intl, duration) => {
	createToast({
		duration: duration * 1.2 + 5000,
		message: intl.formatMessage({id: 'project_budget.project_update_toast'}),
		isDisplayingDuration: true,
		callback: () => {
			location.reload();
		},
	});
};

export const filterAggregateFields = (project, fields) => {
	return fields
		.filter(
			field =>
				ProjectUtil.projectTracksRevenue(project) ||
				!(REVENUE_AGGREGATES.includes(field) || field.includes('Profit') || field.includes('Margin'))
		)
		.filter(
			field =>
				!Util.hasRevenueWithoutCostAccess() ||
				!(field.includes('Cost') || field.includes('Profit') || field.includes('Margin'))
		);
};

export const getCurrency = (company, project) => {
	return project.rateCard && project.rateCard.currency ? project.rateCard.currency : company.currency;
};

export const renderCurrency = (currencySymbol, value, intl) => {
	if (value && !isCloseToZero(value)) {
		return Util.getFormattedNumberWithCurrency(currencySymbol, value, intl);
	} else {
		return '–';
	}
};

export const renderMinutes = (minutes, intl) => {
	if (minutes) {
		return Util.convertMinutesToFullHour(minutes, intl);
	} else {
		return '–';
	}
};

export const renderPercentage = (percentage, intl) => {
	if (percentage) {
		return Util.getFormattedPercentage(percentage, intl);
	} else {
		return '–';
	}
};

export const isColumnLocked = (fixedPriceLocks, object) => {
	const lockedRanges = fixedPriceLocks.edges
		.map(edge => edge.node)
		.filter(fixedPriceLock => fixedPriceLock.locked)
		.map(fixedPriceLock => ({
			startDate: moment(fixedPriceLock.startDate).startOf('day'),
			endDate: moment(fixedPriceLock.endDate).endOf('day'),
		}));
	if (object) {
		return isDateInLockedPeriod(object.startDate, lockedRanges);
	} else {
		return false;
	}
};

export const columnHasUnlockedRecognitionAmount = (fixedPriceLocks, object) => {
	const lockedRanges = fixedPriceLocks.edges
		.map(edge => edge.node)
		.filter(fixedPriceLock => !fixedPriceLock.locked)
		.map(fixedPriceLock => ({
			startDate: moment(fixedPriceLock.startDate).startOf('day'),
			endDate: moment(fixedPriceLock.endDate).endOf('day'),
		}));
	if (object) {
		return isDateInLockedPeriod(object.startDate, lockedRanges);
	} else {
		return false;
	}
};

export const getTotalsParams = aggregates => {
	const result = {
		baselineRevenue: false,
		baselineCost: false,
		baselineProfit: false,
		billableActualTimeAndExpenses: false,
		actualCost: false,
		actualProfit: false,
		billablePlannedTimeAndExpenses: false,
		plannedCost: false,
		plannedProfit: false,
		billableForecastTimeAndExpensesToComplete: false,
		forecastCostToComplete: false,
		forecastProfitToComplete: false,
		billableTotalTimeAndExpensesAtCompletion: false,
		totalCostAtCompletion: false,
		totalProfitAtCompletion: false,
		planVsBillableActualTimeAndExpenses: false,
		planVsActualCost: false,
		planVsActualProfit: false,
		planVsTotalBillableTimeAndExpensesAtCompletion: false,
		planVsTotalCostAtCompletion: false,
		planVsTotalProfitAtCompletion: false,
		baselineVsBillableActualTimeAndExpenses: false,
		baselineVsActualCost: false,
		baselineVsActualProfit: false,
		baselineVsBillableTotalTimeAndExpensesAtCompletion: false,
		baselineVsTotalCostAtCompletion: false,
		baselineVsTotalProfitAtCompletion: false,
		invoiced: false,
		paid: false,
		baselineMinutes: false,
		baselineVsRegisteredMinutes: false,
		baselineVsTotalTimeAtCompletion: false,
		registeredMinutes: false,
		billableActualMinutes: false,
		nonBillableActualMinutes: false,
		scopeApprovedMinutes: false,
		registeredVsScopeApprovedMinutes: false,
		scopeApprovedVsRegisteredMinutes: false,
		forecastTimeToComplete: false,
		totalTimeAtCompletion: false,
		totalTimeAtCompletionVsScopeApprovedMinutes: false,
		scopeApprovedVsTotalTimeAtCompletion: false,
		baselineTimeAndExpenses: false,
		plannedRevenueMargin: false,
		plannedRevenueProfit: false,
		totalRevenueProfitAtCompletion: false,
		forecastRevenueProfitToComplete: false,
		actualRevenueProfit: false,
		baselineMargin: false,
		forecastRevenueMarginToComplete: false,
		totalForecastRevenueToComplete: false,
		actualRevenueMargin: false,
		totalActualRevenueRecognition: false,
		baselineVsActualMargin: false,
		baselineVsTotalRevenueAtCompletion: false,
		baselineVsTotalMarginAtCompletion: false,
		planVsActualMargin: false,
		planVsTotalRevenueAtCompletion: false,
		baselineVsActualRevenueMargin: false,
		baselineVsActualRevenueProfit: false,
		baselineVsTotalRevenueMarginAtCompletion: false,
		baselineVsTotalRevenueProfitAtCompletion: false,
		planVsActualRevenue: false,
		planVsActualRevenueMargin: false,
		planVsActualRevenueProfit: false,
		planVsTotalRevenueMarginAtCompletion: false,
		planVsTotalRevenueProfitAtCompletion: false,
		baselineVsActualRevenueRecognition: false,
		planVsActualRevenueRecognition: false,
	};

	for (let i = 0; i < aggregates.length; i++) {
		result[aggregates[i]] = true;
	}

	return result;
};

export const NUMBER_TYPE = {
	MONEY: 'MONEY',
	PERCENTAGE: 'PERCENTAGE',
	TIME: 'TIME',
};

export const TABLE_TYPE = {
	REVENUE_RECOGNITION: 'REVENUE_RECOGNITION',
	TIME_REVENUE_RECOGNITION: 'TIME_REVENUE_RECOGNITION',
	EXPENSE_REVENUE_RECOGNITION: 'EXPENSE_REVENUE_RECOGNITION',
	INVOICE_DETAILS: 'INVOICE_DETAILS',
	TIME_DETAILS: 'TIME_DETAILS',
};

export const TIME_AGGREGATES = [
	'baselineMinutes',
	'baselineVsRegisteredMinutes',
	'baselineVsTotalTimeAtCompletion',
	'registeredMinutes',
	'billableActualMinutes',
	'nonBillableActualMinutes',
	'scopeApprovedMinutes',
	'scopeApprovedVsRegisteredMinutes',
	'forecastTimeToComplete',
	'totalTimeAtCompletion',
	'scopeApprovedVsTotalTimeAtCompletion',
];

const shouldBeAddedToFixedPrice = expense => {
	return (
		expense.approved &&
		expense.billable &&
		((hasFeatureFlag('Expense_Modal_Update_22Q3') && !hasFeatureFlag('Expense_Modal_Update_23Q1')) ||
			(hasFeatureFlag('Expense_Modal_Update_23Q1') && !expense.partOfFixedPrice))
	);
};
export const getFixedPriceExpenseTotals = expenseItemsEdges => {
	let expenseBillableTotalTimeAndExpensesAtCompletion = 0;

	if (expenseItemsEdges) {
		expenseItemsEdges.forEach(edge => {
			const expense = edge.node;
			if (shouldBeAddedToFixedPrice(expense)) {
				expenseBillableTotalTimeAndExpensesAtCompletion += expense.price * expense.quantity;
			}
		});
	}
	return expenseBillableTotalTimeAndExpensesAtCompletion;
};

export const getBillableExpenseTotals = expenseItemsEdges => {
	let billableExpenseTotal = 0;

	if (expenseItemsEdges) {
		expenseItemsEdges.forEach(edge => {
			const expense = edge.node;
			if (expense.billable) {
				billableExpenseTotal += expense.price * expense.quantity;
			}
		});
	}
	return billableExpenseTotal;
};
