/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type utilizationMonthlyComponent_viewer$ref: FragmentReference;
declare export opaque type utilizationMonthlyComponent_viewer$fragmentType: utilizationMonthlyComponent_viewer$ref;
export type utilizationMonthlyComponent_viewer = {|
  +insightComponentsData: ?{|
    +utilizationMonthly: ?{|
      +jan: ?number,
      +feb: ?number,
      +mar: ?number,
      +apr: ?number,
      +may: ?number,
      +jun: ?number,
      +jul: ?number,
      +aug: ?number,
      +sep: ?number,
      +oct: ?number,
      +nov: ?number,
      +dec: ?number,
      +janBillable: ?number,
      +febBillable: ?number,
      +marBillable: ?number,
      +aprBillable: ?number,
      +mayBillable: ?number,
      +junBillable: ?number,
      +julBillable: ?number,
      +augBillable: ?number,
      +sepBillable: ?number,
      +octBillable: ?number,
      +novBillable: ?number,
      +decBillable: ?number,
      +people: ?$ReadOnlyArray<?{|
        +id: string,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
        +firstName: ?string,
        +lastName: ?string,
        +role: ?string,
        +jan: ?number,
        +feb: ?number,
        +mar: ?number,
        +apr: ?number,
        +may: ?number,
        +jun: ?number,
        +jul: ?number,
        +aug: ?number,
        +sep: ?number,
        +oct: ?number,
        +nov: ?number,
        +dec: ?number,
        +janBillable: ?number,
        +febBillable: ?number,
        +marBillable: ?number,
        +aprBillable: ?number,
        +mayBillable: ?number,
        +junBillable: ?number,
        +julBillable: ?number,
        +augBillable: ?number,
        +sepBillable: ?number,
        +octBillable: ?number,
        +novBillable: ?number,
        +decBillable: ?number,
      |}>,
    |}
  |},
  +$refType: utilizationMonthlyComponent_viewer$ref,
|};
export type utilizationMonthlyComponent_viewer$data = utilizationMonthlyComponent_viewer;
export type utilizationMonthlyComponent_viewer$key = {
  +$data?: utilizationMonthlyComponent_viewer$data,
  +$fragmentRefs: utilizationMonthlyComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jan",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feb",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mar",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apr",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "may",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jun",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jul",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sep",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "oct",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nov",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dec",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "janBillable",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "febBillable",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "marBillable",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aprBillable",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mayBillable",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "junBillable",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "julBillable",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "augBillable",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sepBillable",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "octBillable",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "novBillable",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decBillable",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "year",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "selectValue",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "componentId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "roleIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "labelIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectStatusesBackend",
      "type": "[ID]"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "utilizationMonthlyComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "componentId",
              "variableName": "componentId"
            },
            {
              "kind": "Variable",
              "name": "labelIds",
              "variableName": "labelIds"
            },
            {
              "kind": "Variable",
              "name": "personIds",
              "variableName": "personIds"
            },
            {
              "kind": "Variable",
              "name": "projectStatusesBackend",
              "variableName": "projectStatusesBackend"
            },
            {
              "kind": "Variable",
              "name": "roleIds",
              "variableName": "roleIds"
            },
            {
              "kind": "Variable",
              "name": "selectValue",
              "variableName": "selectValue"
            },
            {
              "kind": "Variable",
              "name": "teamIds",
              "variableName": "teamIds"
            },
            {
              "kind": "Variable",
              "name": "year",
              "variableName": "year"
            }
          ],
          "concreteType": "UtilizationMonthly",
          "kind": "LinkedField",
          "name": "utilizationMonthly",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            (v17/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/),
            (v20/*: any*/),
            (v21/*: any*/),
            (v22/*: any*/),
            (v23/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UtilizationPerson",
              "kind": "LinkedField",
              "name": "people",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureDefaultId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                },
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                (v17/*: any*/),
                (v18/*: any*/),
                (v19/*: any*/),
                (v20/*: any*/),
                (v21/*: any*/),
                (v22/*: any*/),
                (v23/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '78c94ff02889e21d97e646ea46c77ec8';

module.exports = node;
