import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {Checkbox} from 'web-components';
class TaskTableHeader extends Component {
	toggleTasks(selected) {
		if (selected) {
			this.props.onDeselectAllTasks && this.props.onDeselectAllTasks();
		} else {
			this.props.onSelectAllTasks && this.props.onSelectAllTasks();
		}
	}
	render() {
		const {formatMessage} = this.props.intl;
		const height = this.props.height != null ? this.props.height : '';
		let taskIdElemEnabled = !!this.props.availableColumns.find(
			col => col.checked && col.name === 'task-id' && !col.independant
		);
		let selectorElemEnabled = !!this.props.availableColumns.find(
			col => col.checked && col.name === 'selector' && !col.independant
		);
		const content = (
			<div
				data-cy="task-table-header-v3"
				className={'task-table-header-v3' + (this.props.noPaddingLeft ? ' no-left' : '')}
				style={{maxHeight: height, height: height, minHeight: height}}
			>
				{this.props.availableColumns.map((col, index) => {
					const name = col.translationId ? formatMessage({id: col.translationId}).toUpperCase() : '';
					const title = col.title ? formatMessage({id: col.title}) : '';
					const sortable = col.sortable && this.props.onSortValueChange;
					const sortName = sortable
						? ' sortable ' +
						  (this.props.sortValue === col.name + '-asc'
								? 'ascending'
								: this.props.sortValue === col.name + '-dsc'
								? 'descending'
								: '')
						: '';
					if (col.checked || col.maintainSpace) {
						if (col.notAnElem) {
							return null;
						}
						if (col.name === 'selector') {
							return (
								<div
									key={index}
									style={{
										maxWidth: col.maxWidth,
										minWidth: col.minWidth,
										textAlign: col.align,
										paddingLeft: 16,
									}}
									className={'row-' + col.name}
								></div>
							);
						}
						if (
							(col.name === 'price' ||
								col.name === 'actual-price' ||
								col.name === 'planned-cost' ||
								col.name === 'actual-cost') &&
							!this.props.hasFinancialAccess
						) {
							return null;
						}
						if (col.name === 'task-id') {
							return col.independant ? (
								<div
									key={index}
									style={{
										maxWidth: col.maxWidth,
										minWidth: col.minWidth,
										textAlign: col.align,
										paddingLeft: 16,
									}}
									className={'row-' + col.name + (!col.maxWidth ? ' growable' : '') + sortName}
									onClick={
										sortable
											? this.props.onSortValueChange.bind(
													this,
													this.props.sortValue === col.name + '-dsc'
														? 'updated-at'
														: col.name +
																(this.props.sortValue === col.name + '-asc' ? '-dsc' : '-asc')
											  )
											: null
									}
									title={title}
								>
									{name}
									{sortable ? <span>&nbsp;&nbsp;</span> : null}
								</div>
							) : null;
						}
						if (col.name === 'task-name') {
							return (
								<div
									key={'task-group-' + index}
									className={
										'task-group' +
										(!col.maxWidth ? ' growable' : '') +
										(this.props.showProjectIndicator ? ' not-first' : '')
									}
									style={{
										maxWidth: this.props.taskCellMaxWidth,
										minWidth: this.props.taskCellMaxWidth,
										textAlign: col.align,
									}}
								>
									{selectorElemEnabled ? (
										<div key={'selector-' + index} className={'task-selector-row-elem'}>
											{!(this.props.groupByPerson || this.props.groupByRole || this.props.isEmpty) && (
												<Checkbox
													checked={this.props.allTasksSelected}
													onClick={this.toggleTasks.bind(this, this.props.allTasksSelected)}
												/>
											)}
										</div>
									) : null}
									{taskIdElemEnabled ? (
										<div
											key={'task-id-' + index}
											className={
												'task-id-row-elem' +
												(sortable
													? ' sortable ' +
													  (this.props.sortValue === 'task-id-asc'
															? 'ascending'
															: this.props.sortValue === 'task-id-dsc'
															? 'descending'
															: '')
													: '')
											}
											onClick={
												sortable
													? this.props.onSortValueChange.bind(
															this,
															this.props.sortValue === 'task-id-dsc'
																? 'updated-at'
																: 'task-id' +
																		(this.props.sortValue === 'task-id-asc'
																			? '-dsc'
																			: '-asc')
													  )
													: null
											}
											title={title}
										>
											{formatMessage({id: 'project_board.card_details_selector_task_id'}).toUpperCase()}
											{sortable ? <span>&nbsp;&nbsp;</span> : null}
										</div>
									) : null}
									<div
										key={'task-name-' + index}
										className={'row-' + col.name + sortName}
										onClick={
											sortable
												? this.props.onSortValueChange.bind(
														this,
														this.props.sortValue === col.name + '-dsc'
															? 'updated-at'
															: col.name +
																	(this.props.sortValue === col.name + '-asc'
																		? '-dsc'
																		: '-asc')
												  )
												: null
										}
										title={title}
									>
										{col.name === 'controls'
											? ''
											: col.name === 'done-percentage'
											? formatMessage({id: 'common.assigned'})
											: name}
										{sortable ? <span>&nbsp;&nbsp;</span> : null}
									</div>
								</div>
							);
						}
						if (col.name === 'harvest') {
							return this.props.harvestEnabled ? (
								<div
									key={index}
									style={{
										maxWidth: col.maxWidth,
										minWidth: col.minWidth,
										textAlign: col.align,
									}}
									className={'row-' + col.name + (!col.maxWidth ? ' growable' : '')}
									title={title}
								>
									{name}
									{sortable ? <span>&nbsp;&nbsp;</span> : null}
								</div>
							) : null;
						}
						return col.multiLine ? (
							<div
								key={index}
								style={{maxWidth: col.maxWidth, minWidth: col.minWidth, textAlign: col.align}}
								className={'row-' + col.name + (!col.maxWidth ? ' growable' : '')}
							>
								<div className={'multiline-table-header'}>
									<div className={'text-line-1'}>{name.split(' ')[0]}</div>
									<div className={'text-line-2'}>{name.split(' ')[1]}</div>
								</div>
							</div>
						) : (
							<div
								key={index}
								style={{
									maxWidth: col.maxWidth,
									minWidth: col.minWidth,
									textAlign: col.align,
									paddingRight: col.paddingRight ? col.paddingRight : null,
								}}
								className={'row-' + col.name + (!col.maxWidth ? ' growable' : '') + sortName}
								onClick={
									sortable
										? this.props.onSortValueChange.bind(
												this,
												this.props.sortValue === col.name + '-dsc'
													? 'updated-at'
													: col.name + (this.props.sortValue === col.name + '-asc' ? '-dsc' : '-asc')
										  )
										: null
								}
								title={title}
							>
								{!col.checked && col.maintainSpace
									? ''
									: col.name.includes('chip') || col.name === 'controls' || col.hide
									? ''
									: name}
								{sortable ? <span>&nbsp;&nbsp;</span> : null}
							</div>
						);
					} else return null;
				})}
			</div>
		);
		return this.props.withMargin ? <div className={'task-table-header-v3-margin-wrapper'}>{content}</div> : content;
	}
}

TaskTableHeader.propTypes = {
	hasFinancialAccess: PropTypes.bool.isRequired,
};

export default injectIntl(TaskTableHeader);
