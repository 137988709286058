import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import Util from '../../../forecast-app/shared/util/util';

class ProjectSchedulingModal extends Component {
	render() {
		const {formatDate, formatMessage} = this.props.intl;
		const {roleData, projectColor, startDate, endDate, mainLabel, projectId, isProjectGroup, subLabel, isPhase} =
			this.props;

		const useDarkText = Util.getTextColor(projectColor) === '#000000';

		return (
			<div className="project-scheduling-modal-v2" onMouseDown={this.props.closeModal.bind(this)}>
				<div
					className="project-scheduling-modal-content"
					onMouseDown={e => {
						e.stopPropagation();
					}}
					data-userpilot={'schedule-capacity-modal'}
				>
					<button className="close-button" onClick={this.props.closeModal.bind(this)} />
					<div className="top-section">
						<div className="modal-title">
							{formatMessage({
								id: isPhase
									? 'scheduling.phase_capacity'
									: isProjectGroup
									? 'scheduling.connected_project_capacity'
									: 'scheduling.project_capacity',
							}).toUpperCase()}
						</div>
						<div className="date-section">
							<div className="start-date">
								{formatDate(startDate, {
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
								})}
							</div>
							<div className="separator">-</div>
							<div className="end-date">
								{formatDate(endDate, {
									year: 'numeric',
									month: 'numeric',
									day: 'numeric',
								})}
							</div>
						</div>
					</div>
					<div className="name-section">
						<div
							className={
								'project-id-chip' + (isProjectGroup ? ' project-group' : '') + (useDarkText ? ' dark-text' : '')
							}
							style={{backgroundColor: projectColor}}
						>
							{`${isProjectGroup ? 'X' : 'P'}${projectId}`}
						</div>
						<div className="label-section">
							<div className="main-label">{mainLabel}</div>
							<div className="sub-label">{subLabel}</div>
						</div>
					</div>
					{roleData.length ? (
						<table>
							<thead>
								<tr>
									<td className="role-name-column">{formatMessage({id: 'common.role'})}</td>
									<td className="number-column">
										{formatMessage({id: 'project_overview.remaining_capacity_tile'})}
									</td>
									<td className="number-column">{formatMessage({id: 'scheduling.remaining_tasks'})}</td>
									<td className="number-column">{formatMessage({id: 'scheduling.capacity_difference'})}</td>
									{/*<td className="button-column" />*/}
									<td className="button-column-placeholder" />
								</tr>
							</thead>
							<tbody>
								{roleData.map((entry, index) => (
									<tr key={`project-scheduling-modal-entry-${index}`}>
										<td className="role-name-column">{entry.name}</td>
										<td className="number-column">{entry.allocated}</td>
										<td className="number-column">{entry.remaining}</td>
										<td className="number-column">{entry.difference}</td>
										{/*<td className="button-column">
											<Button onClick={() => alert('Not implemented')} buttonStyle={BUTTON_STYLE.OUTLINE} colorTheme={BUTTON_COLOR.PURPLE} text={'Find'} />
										</td>*/}
										<td className="button-column-placeholder">
											<div className="button-placeholder" />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : null}
				</div>
			</div>
		);
	}
}

ProjectSchedulingModal.propTypes = {
	roleData: PropTypes.array.isRequired,
	closeModal: PropTypes.func.isRequired,
	mainLabel: PropTypes.string.isRequired,
	projectColor: PropTypes.string.isRequired,
	startDate: PropTypes.object.isRequired,
	endDate: PropTypes.object.isRequired,
};

export default injectIntl(ProjectSchedulingModal);
