import React from 'react';
import {useIntl} from 'react-intl';
import {getGradients} from '../../util/ChartsUtil';
import {Chart} from 'web-components';
import {BUDGET_TYPE} from '../../../../../../constants';
import {remapAndFormatFinancialMessage} from '../../../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import {hasFeatureFlag} from '../../../../../../forecast-app/shared/util/FeatureUtil';
import {chartTokens} from '@forecast-it/design-system';

const CostChart = ({project, data, hasBaseline, eyeOptionMap, modifyChartOptions, isUsingNewFixedPriceCalculation}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const isFixedPriceProject = [BUDGET_TYPE.FIXED_PRICE, BUDGET_TYPE.FIXED_PRICE_V2].includes(project.budgetType);
	const {
		actualCostChartData,
		plannedCostChartData,
		totalCostAtCompletionChartData,
		baselineCostChartData,
		fixedPriceChartData,
	} = data;
	const {
		gradientStroke,
		gradientFill,
		gradientStrokeActual,
		gradientFillActual,
		gradientStrokeBaseline,
		gradientStrokeFixedPrice,
	} = getGradients();
	const dataSets = [];

	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');
	const chartColors = chartTokens.chart.color;

	if (isFixedPriceProject && !isUsingNewFixedPriceCalculation && fixedPriceChartData.length > 0) {
		dataSets.unshift({
			name: formatMessage({id: 'common.fixed_price'}),
			color: gradientStrokeFixedPrice,
			backgroundColor: isUsingNewFixedPriceCalculation ? 'transparent' : '#fff',
			data: fixedPriceChartData,
		});
	}

	if (hasBaseline && eyeOptionMap.baseline) {
		dataSets.unshift({
			name: formatMessage({id: hasFinancialCategoriesUpdate ? 'project_budget.baseline_cost' : 'baseline.baseline'}),
			color: hasFinancialCategoriesUpdate ? chartColors['4'] : gradientStrokeBaseline,
			filled: !hasFinancialCategoriesUpdate,
			backgroundColor: 'transparent',
			data: baselineCostChartData,
			dashed: !hasFinancialCategoriesUpdate,
			borderWidth: hasFinancialCategoriesUpdate ? 1 : undefined,
		});
	}

	if (eyeOptionMap.plan) {
		dataSets.unshift({
			name: remapAndFormatFinancialMessage({id: 'project_budget.planned_cost'}),
			color: hasFinancialCategoriesUpdate ? chartColors['7'] : gradientStroke,
			filled: !hasFinancialCategoriesUpdate,
			backgroundColor: isUsingNewFixedPriceCalculation ? 'transparent' : gradientFill,
			data: plannedCostChartData,
			borderWidth: hasFinancialCategoriesUpdate ? 3 : undefined,
		});
	}

	if (!hasFinancialCategoriesUpdate && eyeOptionMap.actual) {
		dataSets.unshift({
			name: formatMessage({id: 'project_budget.actual_cost_to_date'}),
			color: gradientStrokeActual,
			filled: !hasFinancialCategoriesUpdate,
			backgroundColor: isUsingNewFixedPriceCalculation ? 'transparent' : gradientFillActual,
			data: actualCostChartData,
		});
	}

	if (totalCostAtCompletionChartData.length && eyeOptionMap.forecast) {
		dataSets.unshift({
			name: remapAndFormatFinancialMessage({id: 'project_budget.forecast_cost'}),
			color: hasFinancialCategoriesUpdate ? chartColors['2'] : gradientStrokeActual,
			filled: !hasFinancialCategoriesUpdate,
			backgroundColor: 'transparent',
			data: totalCostAtCompletionChartData,
			dashed: !hasFinancialCategoriesUpdate,
			borderWidth: hasFinancialCategoriesUpdate ? 2 : undefined,
		});
	}

	return <Chart dataSets={dataSets} modifyOptions={modifyChartOptions} usePointStyle={hasFinancialCategoriesUpdate} />;
};

export default CostChart;
