import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../css_variables';
import closeModal from '../../../images/components/close-modal.svg';

export const DatePickerGroup = styled.div`
	height: 30px;
	display: flex;
	align-items: center;
	.date-range-picker-wrapper-new {
		margin-right: 8px;
		.date-picker-button-container-v3 {
			.date-picker-button {
				&.date-picker-button-standard {
					height: 30px;
				}
			}
		}
	}
`;

export const LockTimeSection = styled.div`
	margin-bottom: 32px;
`;

export const ExceptionTable = styled.div`
	height: ${props => !props.flexHeight && '200px'};
	margin-top: 16px;
`;

export const ExceptionsSection = styled.div`
	border-top: 1px solid ${CSS_CONSTANTS.v2_border_gray};
	padding-top: 24px;
	.time-lock-table {
		width: 584px;
		.name-column {
			width: 466px;
		}
		th,
		td {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding: 0 0 0 16px;
		}
		th {
			padding-bottom: 8px;
		}
		tr {
			&:hover {
				background-color: ${CSS_CONSTANTS.v2_list_hover_purple};
			}
			&:last-child {
				border-bottom: 1px solid ${CSS_CONSTANTS.app_border_color};
			}
		}
	}
	.time-lock-row {
		border-top: 1px solid ${CSS_CONSTANTS.app_border_color};
		height: 48px;
		user-select: none;
		font-size: 13px;
		color: ${CSS_CONSTANTS.v2_text_gray};
		.forecast-person {
			width: 266px;
			.name {
				font-size: 13px;
			}
			.role {
				font-size: 12px;
				color: ${CSS_CONSTANTS.v2_text_light_gray};
				font-weight: normal;
			}
		}
		.delete-person-button {
			width: 12px;
			height: 12px;
			background-size: 12px;
			background-image: url(${closeModal});
			background-repeat: no-repeat;
			border: none;
			opacity: 0;
			margin-left: 32px;
		}
		&:hover {
			.delete-person-button {
				opacity: 1;
			}
			color: ${CSS_CONSTANTS.v2_branding_purple};
			.forecast-person {
				.name {
					color: ${CSS_CONSTANTS.v2_branding_purple};
				}
				.role {
					color: ${CSS_CONSTANTS.v2_branding_purple};
				}
			}
		}
	}
`;

export const Title = styled.div`
	font-size: 13px;
	font-weight: 500;
	margin-bottom: 16px;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

export const Text = styled.div`
	color: ${CSS_CONSTANTS.v2_text_gray};
	font-size: 13px;
	margin-bottom: ${props => `${props.margin || 8}px`};
`;

export const AddPersonSection = styled.div`
	width: 228px;
	margin-bottom: 24px;
`;
