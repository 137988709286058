import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import PieChart from '../../d3/pie_chart';

class workflowStatusComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			projectId: null,
		};
	}

	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}

	render() {
		// Parse config string
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};

		// Create a dataobject based on the config mode
		const data = this.props.viewer.project.tasks.edges.reduce((total, task) => {
			if (task.node.statusColumn) {
				if (total[task.node.statusColumn.id] === undefined) {
					total[task.node.statusColumn.id] = {name: task.node.statusColumn.name, count: 0};
				}
				if (configObject.mode === 'forecast') total[task.node.statusColumn.id].count += task.node.estimateForecast;
				else if (configObject.mode === 'cards') total[task.node.statusColumn.id].count += 1;
			}
			return total;
		}, {});

		// Order columns
		const orderedColumns = this.props.viewer.project.statusColumns.sort((a, b) => {
			return a.order > b.order;
		});

		// Create a data array of the ordered columns and their data.
		const dataArray = [];
		for (var i = 0; i < orderedColumns.length; i++) {
			dataArray.push(data[orderedColumns[i].id] ? data[orderedColumns[i].id] : {name: orderedColumns[i].name, count: 0});
		}

		return (
			<PieChart
				title={this.props.title}
				width={140}
				height={140}
				colors={['#44B4FF', '#44B4FF', '#FF7C75', '#FF7C75']}
				opacities={[1, 0.5, 1, 0.5]}
				data={dataArray}
			/>
		);
	}
}

const workflowStatusComponentQuery = graphql`
	query workflowStatusComponent_Query($shareKey: String, $projectId: ID) {
		viewer {
			actualPersonId
			component(name: "insight_workflow_status")
			...workflowStatusComponent_viewer @arguments(shareKey: $shareKey, projectId: $projectId)
		}
	}
`;

export {workflowStatusComponentQuery};

export default createFragmentContainer(workflowStatusComponent, {
	viewer: graphql`
		fragment workflowStatusComponent_viewer on Viewer
		@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}) {
			project(internalId: $projectId) {
				estimationUnit
				statusColumns {
					id
					name
					order
					category
				}
				tasks(first: 10000, approvedOnly: true) {
					edges {
						node {
							id
							timeLeft
							estimateForecast
							statusColumn {
								id
								name
							}
						}
					}
				}
			}
		}
	`,
});
