import styled from 'styled-components';
import {CSS_CONSTANTS} from './css_variables';

export const Text = styled.div`
	font-size: 13px;
	color: ${CSS_CONSTANTS.v2_text_gray};
`;

export const TextLight = styled.div`
	font-size: 11px;
	color: ${CSS_CONSTANTS.v2_text_light_gray};
`;

export const TextBold = styled(Text)`
	font-weight: 500;
`;
