import React from 'react';
import {ProjectPortfolioReportChartsQueryRenderer} from '../ProjectPortfolioReportChartsQueryRenderer';
import {FlexRow, OutlineBox, PageWrapper, Section} from '../ProjectPortfolioReport.styled';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {ProjectPortfolioPeriodTable} from './ProjectPortfolioPeriodTable';
import {ProjectPortfolioPeriodTitle} from './ProjectPortfolioPeriodTitle';

export const ProjectPortfolioReportPeriodPage = ({
	theEyeOptionsNestedTree,
	selectedSingleValue,
	filterValue,
	selectedDateRange,
	hasFinance,
	setCsvDownloadFunction,
	hasBaseline,
	groupBy,
	selectedDateRangeValue,
}) => {
	return (
		<PageWrapper>
			<CustomScrollDiv horizontalandverticalcontent>
				<ProjectPortfolioReportChartsQueryRenderer
					selectedSingleValue={selectedSingleValue}
					selectedDateRange={selectedDateRange}
					filterValue={filterValue}
					hasFinance={hasFinance}
				/>

				<Section marginTop={28} data-userpilot={'project-portfolio-period-table'}>
					<FlexRow growable>
						<OutlineBox growable marginRight={24} marginLeft={24} marginBottom={24}>
							<ProjectPortfolioPeriodTitle
								dateRangeLabel={selectedDateRangeValue}
								dateRange={selectedDateRange}
							/>
							<ProjectPortfolioPeriodTable
								theEyeOptionsNestedTree={theEyeOptionsNestedTree}
								filters={filterValue}
								selectedDateRange={selectedDateRange}
								setCsvDownloadFunction={setCsvDownloadFunction}
								hasBaseline={hasBaseline}
								groupBy={groupBy}
							/>
						</OutlineBox>
					</FlexRow>
				</Section>
			</CustomScrollDiv>
		</PageWrapper>
	);
};
