import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import util from '../../../forecast-app/shared/util/util';

class ClientListDummy extends Component {
	createDummyClient(
		id,
		name,
		totalProjects,
		haltedProjects,
		planningProjects,
		runningProjects,
		doneProjects,
		spend,
		budget,
		registered,
		forecast,
		remaining,
		progress,
		avgRatePrHourRegistered,
		avgRatePrHourPlanned,
		avgRatePrHourFromBudget
	) {
		const client = {};
		client.id = id;
		client.name = name;
		client.totalProjects = totalProjects;
		client.haltedProjects = haltedProjects;
		client.planningProjects = planningProjects;
		client.runningProjects = runningProjects;
		client.doneProjects = doneProjects;
		client.budget = util.getFormattedCurrencyValue(util.GetCurrencySymbol('USD'), this.props.intl.formatNumber(budget));
		client.spend = util.getFormattedCurrencyValue(util.GetCurrencySymbol('USD'), this.props.intl.formatNumber(spend));
		client.registered = util.convertMinutesToFullHour(registered * 60, this.props.intl);
		client.forecast = util.convertMinutesToFullHour(forecast * 60, this.props.intl);
		client.remaining = util.convertMinutesToFullHour(remaining * 60, this.props.intl);
		client.progress = this.props.intl.formatMessage({id: 'common.x_percentage'}, {percentage: progress});
		client.avgRatePrHourRegistered = util.getFormattedCurrencyValue(
			util.GetCurrencySymbol('USD'),
			this.props.intl.formatNumber(avgRatePrHourRegistered)
		);
		client.avgRatePrHourPlanned = util.getFormattedCurrencyValue(
			util.GetCurrencySymbol('USD'),
			this.props.intl.formatNumber(avgRatePrHourPlanned)
		);
		client.avgRatePrHourFromBudget = util.getFormattedCurrencyValue(
			util.GetCurrencySymbol('USD'),
			this.props.intl.formatNumber(avgRatePrHourFromBudget)
		);
		return client;
	}

	render() {
		let clients = [];
		clients.push(this.createDummyClient(1, 'Example client 1', 5, 0, 2, 2, 1, 1200, 20000, 55, 80, 20, 25, 15, 18, 21));
		clients.push(this.createDummyClient(2, 'Example client 1', 5, 0, 2, 2, 1, 1200, 20000, 55, 80, 20, 25, 15, 18, 21));
		clients.push(this.createDummyClient(3, 'Example client 1', 5, 0, 2, 2, 1, 1200, 20000, 55, 80, 20, 25, 15, 18, 21));
		return (
			<InsightList
				data={clients}
				componentName={this.props.componentName}
				allColumns={this.props.allColumns}
				activeColumns={this.props.activeColumns}
				scrollElement={this.props.scrollElement}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

ClientListDummy.propTypes = {
	title: PropTypes.string,
};

export default injectIntl(ClientListDummy);
