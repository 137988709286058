import {saveConfig} from './invoicing_generate_lines_config';
import {generateLinesForTimeRegs} from './invoicing_generate_lines_timeregs';
import {generateLinesForExpenses} from './invoicing_generate_lines_expenses';
import {generateLinesForFixedPrice} from './invoicing_generate_lines_fixedprice';
import {generateLinesForFixedHoursRetainer} from './invoicing_generate_lines_fixedhours_retainer';

export const TIME_REG_GROUPINGS = {
	NO_GROUPING: 'NO_GROUPING',
	PROJECT: 'PROJECT',
	TASK: 'TASK',
	DONE_TASK: 'DONE_TASK',
	PERSON: 'PERSON',
	ROLE: 'ROLE',
	PHASE: 'PHASE',
	SPRINTS: 'SPRINTS',
	NO_GROUPING_NOTES: 'NO_GROUPING_NOTES',
};

export const EXPENSE_GROUPINGS = {
	NO_GROUPING: 'NO_GROUPING',
	PROJECT: 'PROJECT',
	PERSON: 'PERSON',
	PHASE: 'PHASE',
};

export const getPossibleTimeRegGroupings = () => {
	return Object.keys(TIME_REG_GROUPINGS);
};

export const getPossibleExpenseGroupings = () => {
	return Object.keys(EXPENSE_GROUPINGS);
};

const filterProjects = (projects, config) => {
	return projects.filter(edge => config.selectedProjectsIds.indexOf(edge.id) > -1);
};

/**
 * Generate invoice lines, based on configuration input and project data
 */
export const generateLines = (config, intl, invoice, unInvoicedTimeRegistrations, projects, projectPersonsMap, company) => {
	saveConfig(config);

	const filteredProjects = filterProjects(projects, config);

	let invoiceLines = [];

	const unInvoicedTimeRegistrationsByProject = unInvoicedTimeRegistrations.reduce((map, timeRegistration) => {
		const projectId = timeRegistration.project ? timeRegistration.project.id : timeRegistration.task.project.id;
		let projectTimeRegs = map[projectId];
		if (!projectTimeRegs) {
			projectTimeRegs = [];
			map[projectId] = projectTimeRegs;
		}
		projectTimeRegs.push(timeRegistration);
		return map;
	}, {});

	invoiceLines.push(
		...generateLinesForTimeRegs(
			config,
			intl,
			invoice,
			unInvoicedTimeRegistrations,
			filteredProjects,
			company,
			projectPersonsMap
		)
	);
	invoiceLines.push(...generateLinesForFixedPrice(intl, invoice, filteredProjects, company));
	invoiceLines.push(
		...generateLinesForFixedHoursRetainer(
			intl,
			invoice,
			unInvoicedTimeRegistrationsByProject,
			filteredProjects,
			company,
			unInvoicedTimeRegistrations
		)
	);

	if (config.includeExpenses) {
		invoiceLines.push(...generateLinesForExpenses(config, intl, invoice, filteredProjects, company));
	}

	return invoiceLines;
};
