import PropTypes from 'prop-types';
import React, {useRef, useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {QueryRenderer} from 'react-relay';
import {FormattedMessage, injectIntl} from 'react-intl';
import Environment from './RelayEnvironment';
import FlexModal from './containers/modal/flex_modal';
import {MODAL_TYPE, showModal} from './forecast-app/shared/components/modals/generic_modal_conductor';
import UploadingOverlay from './forecast-app/shared/components/uploading-overlay/uploading_overlay';
import ErrorBoundary from './ErrorBoundary';
import Util from './forecast-app/shared/util/util';
import {isEqual} from 'lodash';
import {projectUrl} from './directApi';
import {getTaskPathFromUrlIfExists} from './forecast-app/shared/util/UrlUtil';

const ForecastQueryRenderer = props => {
	const hasShownError = useRef(false);
	const prevVariables = useRef(props.variables);
	const [realRelayVariables, setRealRelayVariables] = useState(props.variables || {});

	useEffect(() => {
		if (!isEqual(prevVariables.current, props.variables)) {
			prevVariables.current = props.variables;
			setRealRelayVariables(props.variables);
		}
	}, [props.variables]);

	const {showLoader = true} = props;

	return (
		<QueryRenderer
			environment={props.isMc ? Environment.getInstanceMC() : Environment.getInstance()}
			query={props.query}
			//cacheConfig={{force: true}} // Maybe only apply to latest relay version
			fetchPolicy={props.fetchPolicy ? props.fetchPolicy : 'store-and-network'}
			variables={realRelayVariables || {}}
			render={({error, props: relayProps, retry}) => {
				if (error) {
					// eslint-disable-next-line no-console
					console.log('error', error);
					if (!error.message.includes('Internal Error') && !error.message.includes('404:')) {
						// Show error modal
						return (
							<FlexModal
								options={[]}
								headerText={props.intl.formatMessage({id: 'network_error_modal.header'})}
								content={
									<p>
										<FormattedMessage id="network_error_modal.description" />
									</p>
								}
								defaultCallback={() => (window.location.href = '/')}
								closeModal={() => (window.location.href = '/')}
							/>
						);
					}

					// Show error modal
					const errorMsg = error.message.toString();
					let correlationId;
					let notFound = false;
					if (errorMsg.includes('Internal Error: ')) {
						const split = errorMsg.split('Internal Error: ');
						correlationId = split[1];
					} else if (errorMsg.includes('404:')) {
						correlationId = errorMsg.split('404: ')[1];
						notFound = true;
					}

					// Show error modal
					if (!hasShownError.current) {
						if (notFound && props.onCloseErrorModal) {
							showModal({
								type: MODAL_TYPE.MUTATION_ERROR_NON_CRITICAL,
								errorType: '404',
								onCloseModal: props.onCloseErrorModal,
								correlationId: correlationId,
								endpoint: props.query?.params?.name,
							});
						} else {
							showModal({
								type: MODAL_TYPE.MUTATION_ERROR,
								reload_to_upcoming: true,
								correlationId: correlationId,
								endpoint: props.query?.params?.name,
							});
						}
					}
					hasShownError.current = true;
					return null;
				}

				if (relayProps) {
					if (!props.renderIfNotLoggedIn && (relayProps.viewer == null || relayProps.viewer.actualPersonId == null)) {
						// User not logged in
						if (props.redirectIfNotLoggedIn) {
							let redirectDest = window.location.pathname;
							const searchParams = window.location.search;
							if (searchParams) {
								redirectDest += searchParams;
							}
							window.location =
								'/login' + (redirectDest !== '/' ? '?retURL=' + encodeURIComponent(redirectDest) : '');
						}
						if (process.env.FORECAST_ENV !== 'production') {
							console.error('Missing viewer.actualPersonId');
						}
						return <div />;
					}

					if (
						(props.authorizeAccessRoute &&
							relayProps.viewer &&
							!Util.AuthorizeViewerAccess(props.authorizeAccessRoute, relayProps.viewer.projectOwner)) ||
						(props.authorizeModule && props.authorizeModule() === false)
					) {
						if (props.variables) {
							const taskPath = getTaskPathFromUrlIfExists();
							if (props.variables.groupId) {
								return <Redirect to={`/connected/X-${props.variables.groupId}/task-board${taskPath}`} />;
							} else if (props.variables.projectId || props.variables.customProjectId) {
								return (
									<Redirect
										to={`${projectUrl(
											props.variables.projectId,
											props.variables.projectId
										)}/task-board${taskPath}`}
									/>
								);
							}
						}
						const taskPath = getTaskPathFromUrlIfExists();
						if (taskPath) {
							return <Redirect to={taskPath} />;
						} else {
							return <Redirect to="/not-authorized" />;
						}
					}

					if (
						props.requireProject &&
						relayProps.viewer &&
						!relayProps.viewer.project &&
						!relayProps.viewer.projectGroup
					) {
						if (process.env.FORECAST_ENV !== 'production') {
							console.error('Missing required viewer.project');
						}
						return (
							<Redirect
								to={`/not-found${props.variables.projectId ? `?projectId=${props.variables.projectId}` : ''}`}
							/>
						);
					}

					if (
						props.requireProjectAccess &&
						!(
							(relayProps.viewer?.project?.fullAccessToProject && !relayProps.viewer?.project?.readOnlyAccess) ||
							(relayProps.viewer?.projectGroup && !relayProps.viewer?.projectGroup?.readOnlyAccess)
						)
					) {
						if (process.env.FORECAST_ENV !== 'production') {
							console.error('User does not have access to this project');
						}
						return <Redirect to="/not-authorized" />;
					}

					return <ErrorBoundary modal={props.modal}>{props.render(relayProps, retry)}</ErrorBoundary>;
				}

				if (props.alwaysRender) {
					return <ErrorBoundary modal={props.modal}>{props.render(null, retry)}</ErrorBoundary>;
				}

				if (showLoader) {
					if (props.customLoader) {
						return props.customLoader();
					} else {
						return <UploadingOverlay />;
					}
				}
				return null;
			}}
		/>
	);
};

ForecastQueryRenderer.propTypes = {
	query: PropTypes.object.isRequired,
	render: PropTypes.func.isRequired,
	showLoader: PropTypes.bool,
	customLoader: PropTypes.func,
	onlyAdmin: PropTypes.bool,
	redirectIfNotLoggedIn: PropTypes.bool,
	authorizeAccessRoute: PropTypes.string,
	onCloseErrorModal: PropTypes.func,
	requireProject: PropTypes.bool,
	requireProjectAccess: PropTypes.bool,
	variables: PropTypes.object,
	modal: PropTypes.bool,
};

export default injectIntl(ForecastQueryRenderer);
