import React, {useState} from 'react';
import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';
import {ProjectSubColumn} from './ProjectSubColumn';
import {BreakdownLines} from './BreakdownLines';
import * as tracking from '../../../../tracking';
import {ROW_TYPE} from './ValueCell';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';

const ProjectLineStyle = styled.tr`
	border-right: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};

	&.canExpandProject {
		cursor: pointer;
	}
`;

export const ProjectLine = ({pageStructure, projectLine, currencySymbol, stickyColumnsIndexes, isFirstLine, isLastLine}) => {
	const [expanded, setExpanded] = useState(false);
	const projectBreakdown = pageStructure.projectBreakdowns[projectLine.id];
	const isConnectedProject = projectLine.isConnectedProject;

	const expandProject = shouldExpand => {
		setExpanded(shouldExpand);
		tracking.trackElementClicked('Expand Project');
		trackEvent('Project', 'Expanded');
	};

	return (
		<>
			{isConnectedProject ? (
				<>
					<ProjectLineStyle
						className={`${projectLine.canExpand ? 'canExpandProject' : ''}`}
						onClick={projectLine.canExpand ? () => expandProject(!expanded) : null}
						data-userpilot={isFirstLine ? 'first-project-row' : ''}
					>
						{pageStructure.columns.map((column, columnIndex) =>
							column.subColumns.map((subColumn, subColumnIndex) => {
								return (
									<ProjectSubColumn
										isSticky={
											column.id === 'projectOverview' && stickyColumnsIndexes.includes(subColumnIndex)
										}
										index={subColumnIndex}
										key={subColumn.id}
										currencySymbol={currencySymbol}
										highlighted={column.highlighted}
										drawBorder={subColumnIndex === 0}
										project={projectLine}
										subColumn={subColumn}
										expanded={expanded}
										canExpand={projectLine.canExpand}
										rowType={ROW_TYPE.PROJECT_GROUP}
										isOnFirstLine={isFirstLine}
										isOnLastLine={isLastLine}
										columnId={column.id}
									/>
								);
							})
						)}
					</ProjectLineStyle>
					{expanded
						? projectLine.subProjects.map((subProjectLine, subProjectIndex) => (
								<ProjectLine
									key={subProjectIndex}
									projectLine={subProjectLine}
									currencySymbol={currencySymbol}
									pageStructure={pageStructure}
									stickyColumnsIndexes={stickyColumnsIndexes}
									isOnFirstLine={subProjectIndex === 0}
									isOnLastLine={isLastLine}
								/>
						  ))
						: null}
				</>
			) : (
				<>
					<ProjectLineStyle
						className={`${projectLine.canExpand ? 'canExpandProject' : ''}`}
						onClick={projectLine.canExpand ? () => expandProject(!expanded) : null}
						data-userpilot={isFirstLine ? 'first-project-row' : ''}
					>
						{pageStructure.columns.map(column =>
							column.subColumns.map((subColumn, index) => (
								<ProjectSubColumn
									isSticky={column.id === 'projectOverview' && stickyColumnsIndexes.includes(index)}
									index={index}
									key={subColumn.id}
									currencySymbol={currencySymbol}
									highlighted={column.highlighted}
									drawBorder={index === 0}
									project={projectLine}
									subColumn={subColumn}
									expanded={expanded}
									canExpand={projectLine.canExpand}
									rowType={ROW_TYPE.PROJECT}
									isOnFirstLine={isFirstLine}
									isOnLastLine={isLastLine}
									columnId={column.id}
								/>
							))
						)}
					</ProjectLineStyle>
					{expanded ? (
						<BreakdownLines
							pageStructure={pageStructure}
							projectBreakdown={projectBreakdown}
							project={projectLine}
							currencySymbol={currencySymbol}
						/>
					) : null}
				</>
			)}
		</>
	);
};
