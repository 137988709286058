import {
	GROUP_TYPE,
	GROUP_SECTION_WIDTH,
	CURSOR,
	getTrimmedText,
	GROUP_SECTION_PADDING_LEFT,
	GROUP_SECTION_SPACING_LEVEL_ONE,
	drawBorder,
	GROUP_SECTION_TEXT_GREY_DARK,
	GROUP_SECTION_SPACING_LEVEL_TWO,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	GROUP_SECTION_PROJECT_BADGE_WIDTH,
	GROUP_SECTION_PROJECT_BADGE_HEIGHT,
	GROUP_SECTION_PADDING_LEFT_SMALL,
	GROUP_SECTION_PROGRAM_BADGE_PREFIX_GAP,
	GROUP_SECTION_ACTIONS_MENU,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {drawExpandedGroupHighlight, drawProgramBadge} from '../../DrawingUtils';
import {SCHEDULING_ACTION_MENU_TYPE, SCHEDULING_VIEW} from '../../../../constants';

class ProgramGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PROGRAM, data);
	}

	onMouseEnter(groupData) {
		const {isProjectTimeline, sharedContext} = this.pageComponent.props;
		const {group} = groupData;

		if (group && !isProjectTimeline && !sharedContext) {
			const {y, height} = groupData;
			const {program} = group.data;

			const collapsedActionMenuY = y + height / 2 - GROUP_SECTION_ACTIONS_MENU;

			this.pageComponent.setState({
				showCollapsedActionMenu: true,
				collapsedActionMenuY,
				collapsedActionMenuData: program,
				actionMenuType: SCHEDULING_ACTION_MENU_TYPE.PROGRAM,
			});
		}
	}

	onMouseLeave() {
		if (!this.pageComponent.state.showCollapsedActionMenu) return;
		this.pageComponent.setState({showCollapsedActionMenu: false});
	}

	draw(canvasContext, x, y) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height, expanded, pageComponent} = this;
		const {isProjectTimeline, schedulingView} = pageComponent.props;
		const {program} = data;
		const {color, name, prefix} = program;

		super.draw(x, y, width, height, this);

		const borderThickness = 1;
		const expandable = !isProjectTimeline;
		const center = y + height / 2;
		const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;

		if (expandable) {
			interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);
		}

		const xOffset = expandable ? GROUP_SECTION_SPACING_LEVEL_TWO : GROUP_SECTION_SPACING_LEVEL_ONE;

		// expanded background color
		if (expanded && isProjectScheduling && !isProjectTimeline) {
			drawExpandedGroupHighlight(canvasContext, x, y, height, width);
		}

		// top border
		if (isProjectTimeline || !isProjectTimeline) {
			const schedulingGroupOffset = !isProjectTimeline ? 1 : 0;
			drawBorder(canvasContext, x, y - schedulingGroupOffset, width, false, '#E7E7F3', borderThickness);
		}

		// bottom border
		if (!expanded) {
			drawBorder(canvasContext, x, y - 1 + height, width, false, '#E7E7F3', borderThickness);
		}

		// expand icon
		if (expandable) {
			canvasContext.drawImage(
				cacheManager.getCommonImage(expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON),
				x + GROUP_SECTION_SPACING_LEVEL_ONE - GROUP_SECTION_EXPAND_ICON_WIDTH / 2,
				center - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
				GROUP_SECTION_EXPAND_ICON_WIDTH,
				GROUP_SECTION_EXPAND_ICON_HEIGHT
			);
		}

		this.badgeX = x + xOffset;
		this.badgeY = center - GROUP_SECTION_PROJECT_BADGE_HEIGHT / 2;
		drawProgramBadge(canvasContext, this.badgeX, this.badgeY, {
			backgroundColor: color,
		});

		// program prefix
		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
		let fontSize = 14;
		canvasContext.font = `600 ${fontSize}px ` + Util.getFontFamily();
		const prefixWidth = canvasContext.measureText(prefix).width;
		canvasContext.fillText(
			prefix,
			x + xOffset + GROUP_SECTION_PROJECT_BADGE_WIDTH + GROUP_SECTION_PROGRAM_BADGE_PREFIX_GAP,
			center + fontSize / 3
		);

		// program name
		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
		fontSize = 14;
		canvasContext.font = `400 ${fontSize}px ` + Util.getFontFamily();

		const programNameX =
			x +
			xOffset +
			GROUP_SECTION_PROGRAM_BADGE_PREFIX_GAP +
			GROUP_SECTION_PROJECT_BADGE_WIDTH +
			prefixWidth +
			GROUP_SECTION_PADDING_LEFT_SMALL;
		const maxNameWidth = GROUP_SECTION_WIDTH - programNameX - GROUP_SECTION_ACTIONS_MENU - GROUP_SECTION_PADDING_LEFT * 2;
		const formattedName = getTrimmedText(canvasContext, name, maxNameWidth);
		const nameWidth = canvasContext.measureText(formattedName).width;

		this.nameEndX = programNameX + nameWidth;

		canvasContext.fillText(formattedName, programNameX, center + fontSize / 3);
	}
}

export default ProgramGroup;
