import React from 'react';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../../warning';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';

const handleDisplayDefaultWarning = (pageComponent, warningMessage, revertMove, sendMutation) => {
	const {intl} = pageComponent.props;
	const {formatMessage} = intl;

	showModal({
		type: MODAL_TYPE.GENERIC,
		content: (
			<div>
				<Warning message={warningMessage} />
			</div>
		),
		className: 'default-warning-modal',
		defaultCallback: revertMove,
		buttons: [
			{
				text: formatMessage({id: 'common.cancel'}),
				callback: revertMove,
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: formatMessage({id: 'common.continue'}),
				callback: sendMutation,
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.GREEN,
			},
		],
	});
};

export default handleDisplayDefaultWarning;
