const permutations = [
	[false, false, false],
	[false, false, true],
	[false, true, false],
	[false, true, true],
	[true, true, false],
	[true, false, false],
];

export const getMinutesAllocatedKey = (hideSoft, hideHard, calcWin) => {
	const includeWin = !hideSoft && calcWin;

	if (hideSoft) {
		if (hideHard) {
			if (includeWin) {
				return 'true-true-true';
			} else {
				return 'true-true-false';
			}
		} else {
			if (includeWin) {
				return 'true-false-true';
			} else {
				return 'true-false-false';
			}
		}
	} else {
		if (hideHard) {
			if (includeWin) {
				return 'false-true-true';
			} else {
				return 'false-true-false';
			}
		} else {
			if (includeWin) {
				return 'false-false-true';
			} else {
				return 'false-false-false';
			}
		}
	}
};

export const mergeMinutesAllocatedVariationValue = (minutesAllocatedVariations, key, value, useImproved = false) => {
	if (useImproved) {
		const {allocatedMinutes, minutes, minutesHard} = value;

		const minutesVariation = minutesAllocatedVariations[key];
		if (minutesVariation === undefined) {
			minutesAllocatedVariations[key] = {
				minutes,
				minutesHard,
				allocatedMinutes,
			};
		} else {
			minutesVariation.minutes += minutes;
			minutesVariation.minutesHard += minutesHard;
			minutesVariation.allocatedMinutes += allocatedMinutes;
		}
	} else {
		if (minutesAllocatedVariations[key] === undefined) {
			minutesAllocatedVariations[key] = {...value};
		} else {
			minutesAllocatedVariations[key].minutes += value.minutes;
			minutesAllocatedVariations[key].minutesHard += value.minutesHard;
			minutesAllocatedVariations[key].allocatedMinutes += value.allocatedMinutes;
		}
	}
};

export const getMinutesAllocatedVariation = (minutesAllocatedVariations, hideSoft, hideHard, calcWin) =>
	minutesAllocatedVariations?.[getMinutesAllocatedKey(hideSoft, hideHard, calcWin)]?.minutes;

export const getMinutesHard = (minutesAllocatedVariations, hideSoft, hideHard, calcWin) =>
	minutesAllocatedVariations?.[getMinutesAllocatedKey(hideSoft, hideHard, calcWin)]?.minutesHard;

export const getMinutesAllocatedWithoutTasksVariation = (minutesAllocatedVariations, hideSoft, hideHard, calcWin) =>
	minutesAllocatedVariations[getMinutesAllocatedKey(hideSoft, hideHard, calcWin)]?.allocatedMinutes;

export const getMinutesAllocated = (utilizationData, hideSoft, hideHard, calcWin) => {
	let allocatedMinutes = 0;

	if (!hideHard) {
		allocatedMinutes += utilizationData.plannedTotalMinutesHard;
	}
	if (!hideSoft) {
		if (calcWin) {
			allocatedMinutes += utilizationData.plannedTotalMinutesSoftWin;
		} else {
			allocatedMinutes += utilizationData.plannedTotalMinutesSoft;
		}
	}
	return allocatedMinutes;
};

export const getMinutesAllocatedVariations = (utilizationData, hideSoft, hideHard, calcWin) => {
	let allocatedMinutes = 0;

	if (!hideHard) {
		allocatedMinutes += utilizationData.plannedTotalMinutesHard;
	}

	if (!hideSoft) {
		if (calcWin) {
			allocatedMinutes += utilizationData.plannedTotalMinutesSoftWin;
		} else {
			allocatedMinutes += utilizationData.plannedTotalMinutesSoft;
		}
	}

	return {allocatedMinutes, allocatedMinutesHard: utilizationData.plannedTotalMinutesHard};
};

const addMinutesAllocatedVariation = (minutesAllocatedVariations, utilizationData, hideSoft, hideHard, calcWin = false) => {
	const taskMinutesWithTimeRegs = utilizationData.taskMinutesAllocated;
	const timeRegMinutes = utilizationData.timeRegMinutes || 0;
	const allocatedMinutes = getMinutesAllocated(utilizationData, hideSoft, hideHard, calcWin);
	const allocatedMinutesHard = hideSoft ? allocatedMinutes : getMinutesAllocated(utilizationData, true, hideHard, calcWin);

	const allocatedMinutesWithTimeRegs = allocatedMinutes + timeRegMinutes;
	const useTaskMinutes =
		taskMinutesWithTimeRegs > allocatedMinutesWithTimeRegs || (taskMinutesWithTimeRegs > 0 && !allocatedMinutes);

	const value = useTaskMinutes
		? {
				minutes: taskMinutesWithTimeRegs,
				minutesHard: taskMinutesWithTimeRegs,
				allocatedMinutes: 0,
		  }
		: {
				minutes: allocatedMinutesWithTimeRegs,
				minutesHard: allocatedMinutesHard + timeRegMinutes,
				allocatedMinutes: allocatedMinutesWithTimeRegs,
		  };

	const key = getMinutesAllocatedKey(hideSoft, hideHard, calcWin);
	mergeMinutesAllocatedVariationValue(minutesAllocatedVariations, key, value);
};

export const initMinutesAllocatedVariations = (minutes, minutesWin, minutesSoft, minutesSoftWin, minutesHard, task) => {
	const variations = {};

	// Currently does not support soft/hard fractions
	const addVariation = (hideSoft, hideHard, calcWin, currentMinutes) => {
		const key = getMinutesAllocatedKey(hideSoft, hideHard, calcWin);
		mergeMinutesAllocatedVariationValue(variations, key, {
			minutes: currentMinutes,
			minutesHard: currentMinutes,
			allocatedMinutes: 0,
		});
	};

	addVariation(false, false, false, minutes);
	addVariation(false, false, true, minutesWin);
	addVariation(false, true, false, minutesSoft);
	addVariation(false, true, true, minutesSoftWin);
	addVariation(true, true, false, task);
	addVariation(true, false, false, minutesHard);
	return variations;
};

export const addMinutesAllocatedVariations = (minutesAllocatedVariations, utilizationData) => {
	addMinutesAllocatedVariation(minutesAllocatedVariations, utilizationData, false, false, false);
	addMinutesAllocatedVariation(minutesAllocatedVariations, utilizationData, false, false, true);
	addMinutesAllocatedVariation(minutesAllocatedVariations, utilizationData, false, true, false);
	addMinutesAllocatedVariation(minutesAllocatedVariations, utilizationData, false, true, true);
	addMinutesAllocatedVariation(minutesAllocatedVariations, utilizationData, true, true);
	addMinutesAllocatedVariation(minutesAllocatedVariations, utilizationData, true, false);
};

export const addMinutesAllocatedVariationsNew = (minutesAllocatedVariations, utilizationData) => {
	const taskMinutesWithTimeRegs = utilizationData.taskMinutesAllocated;
	const timeRegMinutes = utilizationData.timeRegMinutes || 0;

	for (const permutation of permutations) {
		const [hideSoft, hideHard, calcWin] = permutation;

		const {allocatedMinutes, allocatedMinutesHard} = getMinutesAllocatedVariations(
			utilizationData,
			hideSoft,
			hideHard,
			calcWin
		);

		const allocatedMinutesWithTimeRegs = allocatedMinutes + timeRegMinutes;
		const useTaskMinutes =
			taskMinutesWithTimeRegs > allocatedMinutesWithTimeRegs || (taskMinutesWithTimeRegs > 0 && !allocatedMinutes);

		const value = useTaskMinutes
			? {
					minutes: taskMinutesWithTimeRegs,
					minutesHard: taskMinutesWithTimeRegs,
					allocatedMinutes: 0,
			  }
			: {
					minutes: allocatedMinutesWithTimeRegs,
					minutesHard: allocatedMinutesHard + timeRegMinutes,
					allocatedMinutes: allocatedMinutesWithTimeRegs,
			  };

		const key = getMinutesAllocatedKey(hideSoft, hideHard, calcWin);
		mergeMinutesAllocatedVariationValue(minutesAllocatedVariations, key, value, true);
	}
};

export function mergeMinutesAllocatedVariations(minutesAllocatedVariationsSummary, minutesAllocatedVariations) {
	Object.entries(minutesAllocatedVariations).forEach(entry => {
		const [key, value] = entry;
		mergeMinutesAllocatedVariationValue(minutesAllocatedVariationsSummary, key, value);
	});
}
