import styled from 'styled-components/macro';
export const PageWrapper = styled.div`
	overflow: hidden;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
	padding-bottom: 75px;
`;

export const SectionWrapper = styled.div`
	flex: 1;
	margin: 0 24px 16px;
	display: flex;
	flex-direction: column;
`;

export const TableWrapper = styled.div`
	padding: 0px 16px;
`;

export const TimesheetsIconWrapper = styled.div`
	display: none;
	align-self: center;
	cursor: pointer;
	margin-left: 4px;
	min-width: 24px;
	height: 24px;
	background-size: 24px;
`;
