import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import ChartJsLine from '../../chartjs/chart_js_line';

class sprintVelocityComponent extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}

	render() {
		if (this.props.viewer.insightComponentsData.sprintVelocity) {
			const {formatMessage} = this.props.intl;
			const useHours = this.props.viewer.insightComponentsData.sprintVelocity.useHours;
			const yAxesTitle = useHours ? formatMessage({id: 'common.hours'}) : formatMessage({id: 'common.points'});
			const selectedSprintIds = this.props.selectedSprints ? this.props.selectedSprints.map(el => el.value) : [];
			const sprints = this.props.viewer.insightComponentsData.sprintVelocity.sprints.filter(sprint =>
				this.props.projectGroupId
					? selectedSprintIds.includes(sprint.projectGroupSprintId)
					: selectedSprintIds.includes(sprint.sprintId)
			);
			const labels = sprints.map(sprint => sprint.title);
			const values = sprints.map(sprint => sprint.value);
			const data = {
				labels: labels,
				datasets: [
					{
						data: values,
						label: formatMessage({
							id: useHours
								? 'insights.component.sprintVelocity.hours-burned'
								: 'insights.component.sprintVelocity.points-burned',
						}),
						borderColor: '#44B4FF',
						backgroundColor: '#44B4FF',
						fill: false,
						lineTension: 0,
						pointRadius: 4,
						pointBackgroundColor: '#44B4FF',
						pointHoverRadius: 6,
					},
				],
			};
			return <ChartJsLine data={data} height={360} yAxesTitle={yAxesTitle} />;
		} else {
			return null;
		}
	}
}

const sprintVelocityComponentQuery = graphql`
	query sprintVelocityComponent_Query($shareKey: String, $projectId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_sprint_velocity")
			...sprintVelocityComponent_viewer
				@arguments(shareKey: $shareKey, projectId: $projectId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {sprintVelocityComponentQuery};

export default createFragmentContainer(injectIntl(sprintVelocityComponent), {
	viewer: graphql`
		fragment sprintVelocityComponent_viewer on Viewer
		@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
			insightComponentsData(shareKey: $shareKey) {
				sprintVelocity(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
					useHours
					sprints {
						title
						sprintId
						projectGroupSprintId
						value
					}
				}
			}
		}
	`,
});
