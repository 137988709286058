import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import CurrencyInput from '../../components/inputs/currency_input';
import Util from '../../forecast-app/shared/util/util';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import CreateCostPeriodMutationModern from '../../mutations/create_cost_period_mutation.modern';
import FixedRangePicker from '../../components/new-ui/date-controls/fixed_range_picker';
import moment from 'moment';

class AddCostPeriod extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cost: null,
			startDate: null,
			committing: false,
		};
	}

	updateDateRange(date) {
		this.setState({startDate: date});
	}

	handleCostChange(cost) {
		this.setState({cost});
	}

	createCostPeriod() {
		const {startDate, cost} = this.state;

		const onFinally = () => {
			this.setState({committing: false});
		};

		const onSuccess = () => {
			this.setState({cost: null, startDate: null});
			createToast({duration: 2000, message: this.props.intl.formatMessage({id: 'common.changes_saved'})});
			onFinally();
		};

		const onError = (transaction, defaultFailure) => {
			defaultFailure(transaction);
			onFinally();
		};

		if (startDate && Util.isNumber(cost)) {
			const startDay = this.state.startDate.date();
			const startMonth = this.state.startDate.month() + 1;
			const startYear = this.state.startDate.year();
			this.setState({committing: true});
			// Mutation
			Util.CommitMutation(
				CreateCostPeriodMutationModern,
				{
					startDay,
					startMonth,
					startYear,
					cost,
					personId: this.props.person.id,
				},
				onSuccess,
				true,
				null,
				onError
			);
		}
	}

	render() {
		const {formatMessage} = this.props.intl;
		const {viewer, periods} = this.props;
		const {startDate, cost, committing} = this.state;
		const isCostValid = Util.isNumber(cost);

		const periodsStartDates = periods.map(period => period.startDate);

		const currencySymbol = Util.GetCurrencySymbol(viewer.company.currency);
		const isDollarOrEuro = Util.CurrencyIsPrefixed(currencySymbol);

		const addDisabled = !startDate || !isCostValid || committing;
		return (
			<div className="add-cost-period-container">
				<div className="add-start-date start-date">
					<FixedRangePicker
						customDateFormat={'D. MMM YYYY'}
						customPlaceholder={this.state.startDate ? null : formatMessage({id: 'common.start_date'})}
						disabledDates={periodsStartDates}
						showAsText={true}
						usePropsDate={true}
						useCompactStyle={true}
						calendarOffsetX={0}
						hideLabel={true}
						startDate={this.state.startDate || moment()}
						locale={this.props.locale}
						handleDateRangeChange={val => this.updateDateRange(val)}
						clearable={false}
						cy={'periods-new-start-date-picker'}
					/>
				</div>
				<div className="line">-</div>
				<div className="add-end-date end-date" />
				<div className="add-internal-cost internal-cost">
					<CurrencyInput
						value={cost}
						showCurrencyOnLeft={isDollarOrEuro}
						onChange={this.handleCostChange.bind(this)}
						placeholder={formatMessage({id: 'common.cost'})}
						currency={viewer.company.currency}
						allowDecimals={true}
						blurOnEnter={false}
						onEnter={this.createCostPeriod.bind(this)}
						dataCy={'periods-new-cost-input'}
					/>
				</div>
				<div className="add-button">
					<button
						data-cy="periods-new-add-button"
						onClick={this.createCostPeriod.bind(this)}
						disabled={addDisabled}
						className={addDisabled ? 'disabled' : ''}
					>
						+
					</button>
				</div>
			</div>
		);
	}
}

export default injectIntl(AddCostPeriod);
