import React from 'react';
import Styled from 'styled-components';
import {useIntl} from 'react-intl';
import {RadioButton} from 'web-components';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const Container = Styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Header = Styled.h4`
	margin: 0 0 5px 0;
	font-weight: 600;
	font-size: 13px;
`;

const Description = Styled.span`
	font-weight: 400;
	font-size: 12px;
`;

const AllocationTypeContainer = Styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
`;

const AllocationTypeOption = Styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin-top: 20px 0 5px 0;
`;

const DescriptionContainer = Styled.div`
	padding-left: 47px;
`;

const AllocationType = Styled.h5`
	font-weight: 400;
	font-size: 13px;
	margin: 0;
`;

const RadioButtonContainer = Styled.div`
	display: flex;
  
	:not(:last-child) {
		margin-left: 10px;
	  	margin-right: 15px;
	}
`;

export default ({onChange, isSoft, disabled}) => {
	const {formatMessage} = useIntl();

	const changeHandler = newValue => () => {
		trackEvent((newValue ? 'Soft' : 'Hard') + ' Allocation Type', 'Selected');
		onChange(newValue);
	};

	return (
		<Container>
			<Header>{formatMessage({id: 'allocation.type_selector.header'})}</Header>
			<Description>{formatMessage({id: 'allocation.type_selector.description'})}</Description>

			<AllocationTypeContainer>
				<AllocationTypeOption>
					<RadioButtonContainer>
						<RadioButton checked={isSoft === true} onClick={changeHandler(true)} disabled={disabled} />
					</RadioButtonContainer>

					<AllocationType>{formatMessage({id: 'allocation.soft_allocation'})}</AllocationType>
				</AllocationTypeOption>

				<DescriptionContainer>
					<Description>{formatMessage({id: 'allocation.type_selector.soft_allocation_implications'})}</Description>
				</DescriptionContainer>
			</AllocationTypeContainer>

			<AllocationTypeContainer>
				<AllocationTypeOption>
					<RadioButtonContainer>
						<RadioButton checked={isSoft === false} onClick={changeHandler(false)} disabled={disabled} />
					</RadioButtonContainer>

					<AllocationType>{formatMessage({id: 'allocation.hard_allocation'})}</AllocationType>
				</AllocationTypeOption>

				<DescriptionContainer>
					<Description>{formatMessage({id: 'allocation.type_selector.hard_allocation_implications'})}</Description>
				</DescriptionContainer>
			</AllocationTypeContainer>
		</Container>
	);
};
