import React from 'react';
import {REPORT_GROUPINGS, HIDDEN_FEATURES} from '../../../constants';
import {ProjectIndicatorText} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';

// TODO: Figure out a way to pass down project role without destroying the graphQL service
export const getRolename = timeReg => {
	const project = timeReg.task ? timeReg.task.project : timeReg.project;
	const companyRole = timeReg.person.role ? timeReg.person.role.name : '';
	if (!project) {
		return companyRole;
	}

	return companyRole;
};

export const getClientName = timeReg => {
	if (timeReg.task) {
		if (timeReg.task.project.client) {
			return timeReg.task.project.client.name;
		}
	} else if (timeReg.project) {
		if (timeReg.project.client) {
			return timeReg.project.client.name;
		}
	} else return null;
};

export const getTimeRegTypeAndDetailDom = (timeReg, intl) => {
	if (timeReg.project) {
		return [
			<ProjectIndicatorText
				clearSubPath={true}
				project={timeReg.project}
				disableLink={timeReg.project.readOnlyAccess}
				text={
					timeReg.project.fullAccessToProject
						? timeReg.project.name
						: intl.formatMessage({id: 'timesheets.no_access'})
				}
			/>,
			<span></span>,
		];
	} else if (timeReg.task) {
		return [
			<ProjectIndicatorText
				clearSubPath={true}
				project={timeReg.task.project}
				disableLink={timeReg.task.project.readOnlyAccess}
				text={
					timeReg.task.project.fullAccessToProject
						? timeReg.task.project.name
						: intl.formatMessage({id: 'timesheets.no_access'})
				}
			/>,
			timeReg.task.project.fullAccessToProject ? timeReg.task.name : ' ',
		];
	} else if (timeReg.idleTime && timeReg.idleTime.isInternalTime === true) {
		return [intl.formatMessage({id: 'common.internal_time'}), timeReg.idleTime.name];
	} else if (timeReg.idleTime && timeReg.idleTime.isInternalTime === false) {
		return [intl.formatMessage({id: 'common.time_off'}), timeReg.idleTime.name];
	}
};

export const getTimeRegTypeAndDetail = (timeReg, intl) => {
	if (timeReg.project) {
		return [
			timeReg.project.fullAccessToProject ? timeReg.project.name : intl.formatMessage({id: 'timesheets.no_access'}),
			'',
			timeReg.project.companyProjectId,
		];
	} else if (timeReg.task) {
		return [
			timeReg.task.project.fullAccessToProject
				? timeReg.task.project.name
				: intl.formatMessage({id: 'timesheets.no_access'}),
			timeReg.task.project.fullAccessToProject ? timeReg.task.name : ' ',
			timeReg.task.project.companyProjectId,
		];
	} else if (timeReg.idleTime && timeReg.idleTime.isInternalTime === true) {
		return [intl.formatMessage({id: 'common.internal_time'}), timeReg.idleTime.name, ''];
	} else if (timeReg.idleTime && timeReg.idleTime.isInternalTime === false) {
		return [intl.formatMessage({id: 'common.time_off'}), timeReg.idleTime.name, ''];
	}
};

export const noGroupingFirstDropdownOptions = intl => {
	let groupings = [
		{value: REPORT_GROUPINGS.PERSON, label: intl.formatMessage({id: 'common.group_by_person'})},
		{value: REPORT_GROUPINGS.PROJECT, label: intl.formatMessage({id: 'common.group_by_project'})},
		{value: REPORT_GROUPINGS.TASK, label: intl.formatMessage({id: 'common.group_by_task'})},
		...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
			? [{value: REPORT_GROUPINGS.CLIENT, label: intl.formatMessage({id: 'common.group_by_client'})}]
			: []),
	];

	groupings.push({value: REPORT_GROUPINGS.DEPARTMENT, label: intl.formatMessage({id: 'common.group_by_department'})});

	groupings.push({value: REPORT_GROUPINGS.ROLE, label: intl.formatMessage({id: 'common.group_by_role'})});

	groupings.push({value: null, label: intl.formatMessage({id: 'project_sprints.group_by_people_disable'})});

	return groupings;
};

export const getNumberOfCustomFields = tableHeader =>
	Util.hasCustomFields() && tableHeader.customFieldDefinitions ? tableHeader.customFieldDefinitions.length : 0;
