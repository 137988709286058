import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {Table} from 'web-components';
import {LoadMore} from '../../../../../loaders/LoadMore';
import DetailsLoader, {detailsLoaderQuery} from '../../loaders/DetailsLoader';
import {useIntl} from 'react-intl';
import DetailsTableRow from '../work_and_expenses/DetailsTableRow';
import DetailsPhaseLoader, {detailsPhaseLoaderQuery} from '../../loaders/DetailsPhaseLoader';
import {getPhaseName} from '../../../../../insights/project-portfolio-report/ProjectPortfolioReportUtils';
import getAggregateRowTitle from '../../util/UseAggregateIntl';
import {getTotalsParams, TABLE_TYPE} from '../../util/BudgetUtils';
import {DetailsTableWrapper, DetailsTitleWrapper, DetailsWrapper} from '../../DetailsTable.styled';
import {getProjectDatesOrDefault} from '../../util/ChartsUtil';

const InvoiceDetailsTable = ({
	project,
	selectedAggregateLevel,
	aggregates,
	title,
	isColumnLocked = () => false,
	tooltips,
	cy,
}) => {
	const intl = useIntl();
	const missingProjectDates = !project.projectFirstDateYear || !project.projectLastDateYear;
	const {
		projectFirstDateYear,
		projectFirstDateMonth,
		projectFirstDateDay,
		projectLastDateYear,
		projectLastDateMonth,
		projectLastDateDay,
	} = getProjectDatesOrDefault(project);
	return (
		<>
			<DetailsWrapper data-cy={cy}>
				<DetailsTitleWrapper>
					<h3>{title}</h3>
				</DetailsTitleWrapper>
				<DetailsTableWrapper>
					<LoadMore
						key="query-render-details"
						query={detailsLoaderQuery}
						variables={{
							projectId: project.id,
							startYear: projectFirstDateYear,
							startMonth: projectFirstDateMonth,
							startDay: projectFirstDateDay,
							endYear: projectLastDateYear,
							endMonth: projectLastDateMonth,
							endDay: projectLastDateDay,
							aggregateLevel: selectedAggregateLevel,
							aggregates: aggregates,
						}}
						loader={<DetailsLoader aggregateLevel={selectedAggregateLevel} aggregates={aggregates} />}
					>
						{({data}) => {
							return (
								<Table paddedCells>
									<Table.Header>
										<Table.HeaderColumn visible flex={1} width="EXTRA_LARGE" />
										{data.columns.map(column => (
											<Table.HeaderColumn
												key={title + column.name}
												usePadding
												align="right"
												visible={true}
												width="MEDIUM_SMALL"
											>
												<Table.HeaderColumn.Title>{column.name}</Table.HeaderColumn.Title>
											</Table.HeaderColumn>
										))}
									</Table.Header>
									<Table.Rows data={data} canExpand={!missingProjectDates}>
										{({rowData, tableColumnsProps, expanded, nextLevelProps: phaseLevelProps}) => {
											return (
												<>
													<DetailsTableRow
														data={data}
														rowData={rowData}
														tableColumnProps={tableColumnsProps}
														aggregateLevel={selectedAggregateLevel}
														isColumnLocked={isColumnLocked}
														headerColumn={getAggregateRowTitle(
															intl,
															rowData.aggregate,
															TABLE_TYPE.INVOICE_DETAILS
														)}
														tooltips={tooltips}
													/>
													<LoadMore
														key="time-query-render-details-phase"
														query={detailsPhaseLoaderQuery}
														loader={
															<DetailsPhaseLoader
																aggregateLevel={selectedAggregateLevel}
																aggregate={rowData.aggregate}
																onlyAccumulated={true}
															/>
														}
														variables={{
															projectId: project.id,
															startYear: projectFirstDateYear,
															startMonth: projectFirstDateMonth,
															startDay: projectFirstDateDay,
															endYear: projectLastDateYear,
															endMonth: projectLastDateMonth,
															endDay: projectLastDateDay,
															aggregateLevel: selectedAggregateLevel,
															aggregate: rowData.aggregate,
															...getTotalsParams(aggregates),
														}}
													>
														{({data: phaseBreakdownData}) => {
															return (
																<Table.Rows
																	data={phaseBreakdownData}
																	{...phaseLevelProps}
																	canExpand={false}
																>
																	{({
																		rowData: phaseRowData,
																		tableColumnsProps: phaseTableColumnProps,
																	}) => {
																		return (
																			<>
																				<DetailsTableRow
																					data={data}
																					rowData={phaseRowData}
																					tableColumnProps={phaseTableColumnProps}
																					isColumnLocked={isColumnLocked}
																					headerColumn={getPhaseName(
																						intl,
																						phaseRowData
																					)}
																					canExpand={false}
																					tooltips={tooltips}
																				/>
																			</>
																		);
																	}}
																</Table.Rows>
															);
														}}
													</LoadMore>
												</>
											);
										}}
									</Table.Rows>
								</Table>
							);
						}}
					</LoadMore>
				</DetailsTableWrapper>
			</DetailsWrapper>
		</>
	);
};

export default createFragmentContainer(InvoiceDetailsTable, {
	project: graphql`
		fragment InvoiceDetailsTable_project on ProjectType {
			id
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
		}
	`,
});
