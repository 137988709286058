import {createFragmentContainer, graphql} from 'react-relay';
import {getCurrency} from '../util/BudgetUtils';

const TimeTotalsLoader = ({viewer, children}) => {
	const rows = [];
	const {financialNumbers} = viewer.project;

	const timeData = [
		{
			type: 'baselineMinutes',
			value: financialNumbers.baselineMinutes,
		},
		{
			type: 'scopeApprovedMinutes',
			value: financialNumbers.scopeApprovedMinutes,
		},
		{
			type: 'registeredMinutes',
			value: financialNumbers.registeredMinutes,
		},
		{
			type: 'forecastTimeToComplete',
			value: financialNumbers.forecastTimeToComplete,
		},
		{
			type: 'totalTimeAtCompletion',
			value: financialNumbers.totalTimeAtCompletion,
		},
		{
			type: 'baselineVsRegisteredMinutes',
			value: financialNumbers.baselineVsRegisteredMinutes,
		},
		{
			type: 'baselineVsTotalTimeAtCompletion',
			value: financialNumbers.baselineVsTotalTimeAtCompletion,
		},
		{
			type: 'scopeApprovedVsRegisteredMinutes',
			value: financialNumbers.scopeApprovedVsRegisteredMinutes,
		},
		{
			type: 'scopeApprovedVsTotalTimeAtCompletion',
			value: financialNumbers.scopeApprovedVsTotalTimeAtCompletion,
		},
	];

	const timeRow = {
		dataArray: timeData,
		currency: getCurrency(viewer.company, viewer.project),
		rowType: 'time',
	};
	rows.push(timeRow);

	return children({data: {rows: [...rows]}});
};

export const timeTotalsLoaderQuery = graphql`
	query TimeTotalsLoader_Query(
		$projectId: ID!
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
	) {
		viewer {
			actualPersonId
			component(name: "time_totals_loader")
			...TimeTotalsLoader_viewer
				@arguments(
					projectId: $projectId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createFragmentContainer(TimeTotalsLoader, {
	viewer: graphql`
		fragment TimeTotalsLoader_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID!"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				rateCard {
					currency
				}
				financialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				) {
					baselineMinutes
					baselineVsRegisteredMinutes
					baselineVsTotalTimeAtCompletion
					registeredMinutes
					scopeApprovedMinutes
					scopeApprovedVsRegisteredMinutes
					forecastTimeToComplete
					totalTimeAtCompletion
					scopeApprovedVsTotalTimeAtCompletion
				}
			}
		}
	`,
});
