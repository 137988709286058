import React from 'react';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
import { Button, ExportType } from '@forecast-it/design-system';
export const ExpenseManagementExportButton = ({ exportData, includeColumns, }) => {
    const onExportCsv = () => {
        trackEvent('CSV File', 'Exported');
        exportData('financials-breakdown', ExportType.CSV, includeColumns);
    };
    return React.createElement(Button, { emphasis: 'medium', icon: 'download', onClick: onExportCsv, "data-cy": 'export-expenses-csv' });
};
export default ExpenseManagementExportButton;
