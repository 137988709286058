import Util from '../../../../forecast-app/shared/util/util';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import {useEffect} from 'react';
import {getAggregatedResourceNumbersByMonth, mapCompressedAggregatedResourceNumbers} from '../UtilizationReportUtils';

export const exportToCsv = (
	year,
	resourceData,
	getUtilizationNumber,
	showResourceUtilization,
	intl,
	isMixedAllocationModeEnabled,
	allocationControlsOptions
) => {
	if (resourceData.length > 0) {
		const monthByMonthData = resourceData.map(rowData => {
			const row = {};
			const firstColumnName = rowData.person ? 'Person' : rowData.role ? 'Role' : rowData.department ? 'Department' : '';
			const firstColumnValue = rowData.person
				? `${rowData.person.firstName} ${rowData.person.lastName}`
				: rowData.role
				? rowData.role.name
				: rowData.department
				? rowData.department.name
				: '';

			const months = [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			];
			row[firstColumnName] = firstColumnValue;
			months.forEach((month, index) => {
				row[month] = Util.getFormattedPercentage(
					getUtilizationNumber(
						rowData,
						index,
						showResourceUtilization,
						isMixedAllocationModeEnabled,
						allocationControlsOptions
					),
					intl
				);
			});

			return row;
		});

		const headerRow = Object.keys(monthByMonthData[0]).reduce((map, column) => {
			map[column] = column;
			return map;
		}, {});
		monthByMonthData.splice(0, 0, headerRow);

		const csv = Util.JSONToCSV(monthByMonthData);
		Util.exportToCSV(csv, `utilization_month_by_month_${year}.csv`);
	}
};

const MonthByMonthDownload = ({
	viewer,
	closeModal,
	getUtilizationNumber,
	year,
	showResourceUtilization,
	isMixedAllocationModeEnabled,
	allocationControlsOptions,
}) => {
	const intl = useIntl();
	const formatData = viewer => {
		const resourceData =
			viewer.company.resourceBasedPersons || viewer.company.resourceBasedRoles || viewer.company.resourceBasedDepartments;
		const dataType = viewer.company.resourceBasedPersons
			? 'Person'
			: viewer.company.resourceBasedRoles
			? 'Role'
			: viewer.company.resourceBasedDepartments
			? 'Departments'
			: '';

		return resourceData.edges
			.map(edge => edge.node)
			.map(row => {
				const aggregatedResourceNumbers = mapCompressedAggregatedResourceNumbers(row.aggregatedResourceNumbers, [
					'forecastBillableUtilization',
					'forecastBillableUtilizationWin',
					'forecastBillableUtilizationSoft',
					'forecastBillableUtilizationSoftWin',
					'forecastBillableUtilizationHard',
					'forecastResourceUtilization',
					'forecastResourceUtilizationWin',
					'forecastResourceUtilizationSoft',
					'forecastResourceUtilizationSoftWin',
					'forecastResourceUtilizationHard',
					'forecastBillableUtilizationTaskAndAllocationsCombined',
					'forecastBillableUtilizationTaskAndAllocationsCombinedWin',
					'forecastBillableUtilizationTaskAndAllocationsCombinedSoft',
					'forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin',
					'forecastBillableUtilizationTaskAndAllocationsCombinedHard',
					'forecastResourceUtilizationTaskAndAllocationsCombined',
					'forecastResourceUtilizationTaskAndAllocationsCombinedWin',
					'forecastResourceUtilizationTaskAndAllocationsCombinedSoft',
					'forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin',
					'forecastResourceUtilizationTaskAndAllocationsCombinedHard',
				]);
				const aggregatedResourceNumbersByMonth = getAggregatedResourceNumbersByMonth(aggregatedResourceNumbers);
				const formattedData = {aggregatedResourceNumbersByMonth};
				switch (dataType) {
					case 'Person':
						formattedData.person = {
							id: row.id,
							firstName: row.firstName,
							lastName: row.lastName,
							profilePictureId: row.profilePictureId,
						};
						break;
					case 'Role':
						formattedData.role = {
							id: row.id,
							name: row.name,
						};
						break;
					case 'Department':
						formattedData.department = {
							id: row.id,
							name: row.name,
						};
						break;
				}
				return formattedData;
			});
	};

	useEffect(() => {
		if (viewer) {
			const formattedData = formatData(viewer);
			exportToCsv(
				year,
				formattedData,
				getUtilizationNumber,
				showResourceUtilization,
				intl,
				isMixedAllocationModeEnabled,
				allocationControlsOptions
			);
			closeModal();
		}
	}, [viewer]);

	return null;
};

export const monthByMonthDownloadQuery = graphql`
	query MonthByMonthDownload_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$searchQuery: TaskSearchQueryType!
		$pageSize: Int!
		$cursor: String
	) {
		viewer {
			actualPersonId
			component(name: "utilization_report_month_by_month_person_csv_loader")
			...MonthByMonthDownload_viewer
				@arguments(
					searchQuery: $searchQuery
					pageSize: $pageSize
					cursor: $cursor
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createFragmentContainer(MonthByMonthDownload, {
	viewer: graphql`
		fragment MonthByMonthDownload_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
			pageSize: {type: "Int"}
			cursor: {type: "String"}
		) {
			company {
				resourceBasedPersons(
					first: $pageSize
					after: $cursor
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: MONTH
					aggregates: [
						"forecastBillableUtilization"
						"forecastBillableUtilizationWin"
						"forecastBillableUtilizationSoft"
						"forecastBillableUtilizationSoftWin"
						"forecastBillableUtilizationHard"
						"forecastResourceUtilization"
						"forecastResourceUtilizationWin"
						"forecastResourceUtilizationSoft"
						"forecastResourceUtilizationSoftWin"
						"forecastResourceUtilizationHard"
						"forecastBillableUtilizationTaskAndAllocationsCombined"
						"forecastBillableUtilizationTaskAndAllocationsCombinedWin"
						"forecastBillableUtilizationTaskAndAllocationsCombinedSoft"
						"forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin"
						"forecastBillableUtilizationTaskAndAllocationsCombinedHard"
						"forecastResourceUtilizationTaskAndAllocationsCombined"
						"forecastResourceUtilizationTaskAndAllocationsCombinedWin"
						"forecastResourceUtilizationTaskAndAllocationsCombinedSoft"
						"forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin"
						"forecastResourceUtilizationTaskAndAllocationsCombinedHard"
					]
				) {
					edges {
						node {
							id
							firstName
							lastName
							profilePictureId
							aggregatedResourceNumbers
						}
					}
				}
			}
		}
	`,
});
