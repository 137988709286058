/*global vis*/
import React, {Component} from 'react';
import moment from 'moment';
import {injectIntl} from 'react-intl';
import util from '../../forecast-app/shared/util/util';
//import vis from "vis";
// The timeline needs the css included
//require('../../../node_modules/vis/dist/vis-timeline-graph2d.min.css');

class Timeline extends Component {
	constructor(props) {
		super(props);

		this.scrollMouseUp = this.scrollMouseUp.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.scrollWheel = this.scrollWheel.bind(this);

		this.state = {
			scrollFromWheel: false,
			scrollDelta: 0,
			scrollOffset: 0,
			scrollThumbPos: 0,
			scrolling: false,
			mouseOut: false,
			emptyGroupFilter: false,
		};
	}

	componentDidMount() {
		// Initialize the timeline on mount
		this.init();

		// Event listeners to handle events
		window.addEventListener('wheel', this.scrollWheel);
		window.addEventListener('mousemove', this.handleMouseMove);
		if (window.PointerEvent) {
			window.addEventListener('pointerup', this.scrollMouseUp);
		} else {
			window.addEventListener('mouseup', this.scrollMouseUp);
		}
		// Initialize the scroll position
		this.setState({scrollThumbPos: this.scrollOuter.offsetWidth / 2 - 25});
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// Update scroll position and set timeline window
		if (nextState.scrollThumbPos !== this.state.scrollThumbPos && (nextState.scrolling || nextState.scrollFromWheel)) {
			const timeWindow = this.timeline.getWindow();
			const start = moment(timeWindow.start);
			const end = moment(timeWindow.end);
			const days_diff = end.diff(start, 'minutes');
			start.add((nextState.scrollDelta * days_diff) / 200, 'minutes');
			end.add((nextState.scrollDelta * days_diff) / 200, 'minutes');
			this.timeline.setWindow(start.toDate(), end.toDate(), {animation: false});
		}
	}

	componentWillUnmount() {
		// Unregister events
		window.removeEventListener('wheel', this.scrollWheel);
		if (window.PointerEvent) {
			window.removeEventListener('mouseup', this.scrollMouseUp);
		} else {
			window.removeEventListener('pointerup', this.scrollMouseUp);
		}
		window.removeEventListener('mousemove', this.handleMouseMove);
		this.timeline.destroy();
	}

	// Handle scrolling
	handleMouseMove(e) {
		if (this.state.scrolling) {
			let newScroll;
			const x = e.pageX - this.state.scrollOffset - this.scrollOuter.getBoundingClientRect().left;
			if (x < 0) {
				newScroll = 0;
			} else if (x > this.scrollOuter.getBoundingClientRect().width - 50) {
				newScroll = this.scrollOuter.getBoundingClientRect().width - 50;
			} else {
				newScroll = x;
			}

			this.setState({
				scrollThumbPos: newScroll,
				scrollDelta: newScroll - this.state.scrollThumbPos,
				scrollFromWheel: false,
			});
		}
	}

	// Handle wheel scrolling
	scrollWheel(e) {
		if (e.deltaX === 0) return;
		if (!this.props.scroll) return;
		const x = e.deltaX / 15 + this.state.scrollThumbPos;
		let newScroll;
		if (x < 0) {
			newScroll = 200;
		} else if (x > this.scrollOuter.offsetWidth - 50) {
			newScroll = this.scrollOuter.offsetWidth - 200;
		} else {
			newScroll = x;
		}

		e.stopPropagation();

		this.setState({scrollThumbPos: newScroll, scrollFromWheel: true, scrollDelta: e.deltaX / 15});
	}

	scrollMouseDown(e) {
		if (e.stopPropagation) e.stopPropagation();
		if (e.preventDefault) e.preventDefault();
		e.cancelBubble = true;
		e.returnValue = false;
		this.setState({scrolling: true, scrollOffset: e.nativeEvent.offsetX});
	}

	scrollMouseUp(e) {
		if (this.state.scrolling) {
			if (this.state.scrollThumbPos > this.scrollOuter.offsetWidth - 60) {
				this.setState({scrolling: false, scrollThumbPos: this.scrollOuter.offsetWidth - 200, scrollDelta: 0});
			} else if (this.state.scrollThumbPos < 10) {
				this.setState({scrolling: false, scrollThumbPos: 200, scrollDelta: 0});
			} else {
				this.setState({scrolling: false, scrollDelta: 0});
			}
		}
	}

	//region Methods called from parent components
	getWindow() {
		return this.timeline.getWindow();
	}

	setOptions(options) {
		this.timeline.setOptions(options);
	}

	setWindow(start, end) {
		this.timeline.setWindow(start, end);
	}

	zoomIn(percentage) {
		this.timeline.zoomIn(percentage, {}, () => {
			// Zoom in callback function
			this.setScale(this.timeline.getWindow());
		});
	}

	zoomOut(percentage) {
		this.timeline.zoomOut(percentage, {}, () => {
			// Zoom out callback function
			this.setScale(this.timeline.getWindow());
		});
	}

	resetScroller() {
		this.setState({scrollThumbPos: this.scrollOuter.offsetWidth / 2 - 25});
	}

	setScale(windowObject) {
		const start = new Date(windowObject.start);
		const end = new Date(windowObject.end);

		const scale = util.calculateTimelineScale(start, end);

		if (this._currentScale !== scale) {
			this.timeline.setOptions({
				timeAxis: {
					scale,
					step: 1,
				},
			});
			this._currentScale = scale;
			if (this.props.onScaleChanged) {
				this.props.onScaleChanged(scale);
			}
		}
	}

	//endregion

	init() {
		const {formatDate, formatMessage} = this.props.intl;

		// Get groups and items from props
		this._groups = this.props.groups;
		this._items = this.props.items;

		// Snap to days
		const snap = (date, scale, step) => {
			let hour = 60 * 60 * 1000 * 24;
			return Math.round(date / hour) * hour;
		};

		const startDate = util.getTimelineStartDate(this.props.startDate);
		const endDate = util.getTimelineEndDate(this.props.endDate);
		this._currentScale = util.calculateTimelineScale(startDate, endDate);

		const options = {
			editable: {
				add: true, // Must be set to be able to drop unassigned card in card scheduling
				updateTime: true, // drag items horizontally
				updateGroup: false, // drag items from one group to another
				remove: false, // delete an item by tapping the delete button top right
				overrideItems: false, // allow these options to override item.editable
			},
			margin: {
				axis: 4,
				item: {
					vertical: 4,
					horizontal: -10,
				},
			},
			orientation: 'top',
			itemsAlwaysDraggable: false,
			tooltipOnItemUpdateTime: {
				template: function (item) {
					const startDate = moment.utc(item.start);
					const endDate = moment.utc(item.end).subtract(1, 'days');
					return `<div>${formatDate(startDate.toDate())} - ${formatDate(endDate.toDate())}<br /></div>`;
				},
			},
			selectable: true,
			start: startDate,
			end: endDate,
			moveable: this.props.moveable ? this.props.moveable : false,
			zoomMin: 86400000 * 7,
			zoomMax: 86400000 * 7 * 200,
			zoomKey: 'altKey',
			multiselect: false,
			verticalScroll: true,
			//height: '100%',
			maxHeight: '100%',
			showCurrentTime: this.props.showCurrentTime ? this.props.showCurrentTime : false,
			tooltip: {followMouse: true, overflowMethod: 'cap'},
			timeAxis: {
				scale: this._currentScale,
				step: 1,
			},
			locale: moment.locale(),
			format: {
				minorLabels: {
					day: 'ddd D',
					week: '[' + formatMessage({id: 'timeline.week'}) + '] w',
					weekday: 'ddd D',
					month: 'MMM',
				},
				majorLabels: {
					weekday: 'MMMM',
					//day: 'MMMM',
					month: 'YYYY',
				},
			},
			snap,
			moment: date => {
				return moment(date).utc();
			},
			onMoving: (item, callback) => {
				this._moving = true;
				if (item.end && item.start.getTime() >= item.end.getTime()) {
					callback(null);
				} else {
					if (this.props.onMoving) {
						this.props.onMoving(item, callback);
					} else {
						callback(item);
					}
				}
			},
			template: (item, element, data) => {
				if (this.props.template) {
					return this.props.template(item, element);
				}
			},
			visibleFrameTemplate: (item, element) => {
				if (this.props.visibleFrameTemplate) {
					return this.props.visibleFrameTemplate(item, element);
				}
			},
			onMove: (item, callback) => {
				this._moving = false;
				if (this.props.onMove) {
					this.props.onMove(item, callback);
				} else {
					callback(item);
				}
			},
			onAdd: (item, callback) => {
				if (this.props.onAdd) {
					this.props.onAdd(item, callback);
				} else {
					callback(null);
				}
			},
		};

		if (this.props.groupTemplate) {
			options.groupTemplate = (group, element) => this.props.groupTemplate(group, element);
		}

		this.timeline = new vis.Timeline(this.container, this._items, this._groups, options);

		this.timeline.on('itemover', properties => {
			const selectedItem = this.timeline.getSelection().length === 1 ? this.timeline.getSelection()[0] : null;
			if (!this._moving && selectedItem !== properties.item && !properties.item.startsWith('event')) {
				this.timeline.setSelection(properties.item);
			}
		});

		if (this.props.mouseDown) {
			this.timeline.on('mouseDown', properties => {
				this.props.mouseDown(properties);
			});
		}

		if (this.props.mouseUp) {
			this.timeline.on('mouseUp', properties => {
				this.props.mouseUp(properties);
			});
		}

		if (this.props.mouseMove) {
			this.timeline.on('mouseMove', properties => {
				this.props.mouseMove(properties);
			});
		}

		if (this.props.onClick) {
			this.timeline.on('click', properties => {
				this.props.onClick(properties);
			});
		}

		if (this.props.onItemOver) {
			this.timeline.on('itemover', properties => {
				this.props.onItemOver(properties);
			});
		}

		if (this.props.onItemOut) {
			this.timeline.on('itemout', properties => {
				this.props.onItemOut(properties);
			});
		}

		if (this.props.onRangeChanged) {
			this.timeline.on('rangechanged', properties => {
				this.props.onRangeChanged(properties);
			});
		}

		if (this.props.onSelect) {
			this.timeline.on('select', properties => {
				this.props.onSelect(properties);
			});
		}
	}

	render() {
		const style = {left: this.state.scrollThumbPos};

		return (
			<div className={'scheduling-timeline-wrapper' + (this.state.emptyGroupFilter || this.props.hide ? ' hide' : '')}>
				<div
					className={'scheduling-container ' + (moment.locale() === 'en' ? 'week-start-sunday' : 'week-start-monday')}
					ref={con => (this.container = con)}
				>
					<div
						className={'scroll-outer' + (this.state.emptyGroupFilter || this.props.hide ? ' hide' : '')}
						ref={con => (this.scrollOuter = con)}
					>
						<div className="scroll-thumb" style={style} onMouseDown={this.scrollMouseDown.bind(this)} />
					</div>
					{this.props.children}
				</div>
			</div>
		);
	}
}

export default injectIntl(Timeline, {forwardRef: true});
