/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type timeRegisteredPerPersonPortfolio_viewer$ref: FragmentReference;
declare export opaque type timeRegisteredPerPersonPortfolio_viewer$fragmentType: timeRegisteredPerPersonPortfolio_viewer$ref;
export type timeRegisteredPerPersonPortfolio_viewer = {|
  +insightComponentsData: ?{|
    +timeRegisteredPerPersonPortfolio: ?{|
      +id: string,
      +startWeekOnMonday: ?boolean,
      +mondayRegistered: ?number,
      +mondayWorkingHours: ?number,
      +tuesdayRegistered: ?number,
      +tuesdayWorkingHours: ?number,
      +wednesdayRegistered: ?number,
      +wednesdayWorkingHours: ?number,
      +thursdayRegistered: ?number,
      +thursdayWorkingHours: ?number,
      +fridayRegistered: ?number,
      +fridayWorkingHours: ?number,
      +saturdayRegistered: ?number,
      +saturdayWorkingHours: ?number,
      +sundayRegistered: ?number,
      +sundayWorkingHours: ?number,
      +totalRegistered: ?number,
      +totalWorkingHours: ?number,
      +listData: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +role: ?string,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
        +mondayRegistered: ?number,
        +mondayWorkingHours: ?number,
        +tuesdayRegistered: ?number,
        +tuesdayWorkingHours: ?number,
        +wednesdayRegistered: ?number,
        +wednesdayWorkingHours: ?number,
        +thursdayRegistered: ?number,
        +thursdayWorkingHours: ?number,
        +fridayRegistered: ?number,
        +fridayWorkingHours: ?number,
        +saturdayRegistered: ?number,
        +saturdayWorkingHours: ?number,
        +sundayRegistered: ?number,
        +sundayWorkingHours: ?number,
        +totalRegistered: ?number,
        +totalWorkingHours: ?number,
        +nestedListData: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
          +mondayRegistered: ?number,
          +tuesdayRegistered: ?number,
          +wednesdayRegistered: ?number,
          +thursdayRegistered: ?number,
          +fridayRegistered: ?number,
          +saturdayRegistered: ?number,
          +sundayRegistered: ?number,
          +totalRegistered: ?number,
        |}>,
      |}>,
    |}
  |},
  +$refType: timeRegisteredPerPersonPortfolio_viewer$ref,
|};
export type timeRegisteredPerPersonPortfolio_viewer$data = timeRegisteredPerPersonPortfolio_viewer;
export type timeRegisteredPerPersonPortfolio_viewer$key = {
  +$data?: timeRegisteredPerPersonPortfolio_viewer$data,
  +$fragmentRefs: timeRegisteredPerPersonPortfolio_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mondayRegistered",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mondayWorkingHours",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesdayRegistered",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesdayWorkingHours",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesdayRegistered",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesdayWorkingHours",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursdayRegistered",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursdayWorkingHours",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fridayRegistered",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fridayWorkingHours",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturdayRegistered",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturdayWorkingHours",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sundayRegistered",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sundayWorkingHours",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalRegistered",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalWorkingHours",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "weekPickerSelectedDate",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "componentId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "roleIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "labelIds",
      "type": "[ID]"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "timeRegisteredPerPersonPortfolio_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "componentId",
              "variableName": "componentId"
            },
            {
              "kind": "Variable",
              "name": "labelIds",
              "variableName": "labelIds"
            },
            {
              "kind": "Variable",
              "name": "personIds",
              "variableName": "personIds"
            },
            {
              "kind": "Variable",
              "name": "roleIds",
              "variableName": "roleIds"
            },
            {
              "kind": "Variable",
              "name": "teamIds",
              "variableName": "teamIds"
            },
            {
              "kind": "Variable",
              "name": "weekPickerSelectedDate",
              "variableName": "weekPickerSelectedDate"
            }
          ],
          "concreteType": "TimeRegisteredPerPersonPortfolio",
          "kind": "LinkedField",
          "name": "timeRegisteredPerPersonPortfolio",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startWeekOnMonday",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/),
            (v16/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeRegisteredPerPersonPortfolioListElement",
              "kind": "LinkedField",
              "name": "listData",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v17/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureDefaultId",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v11/*: any*/),
                (v12/*: any*/),
                (v13/*: any*/),
                (v14/*: any*/),
                (v15/*: any*/),
                (v16/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TimeRegisteredPerPersonPortfolioListElement",
                  "kind": "LinkedField",
                  "name": "nestedListData",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v17/*: any*/),
                    (v1/*: any*/),
                    (v3/*: any*/),
                    (v5/*: any*/),
                    (v7/*: any*/),
                    (v9/*: any*/),
                    (v11/*: any*/),
                    (v13/*: any*/),
                    (v15/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d8f5ac0d0d97b7058a2d0c4839371f5';

module.exports = node;
