export const PROJECT_PORTFOLIO_DATE_RANGE = {
	ALL_TIME: 'AllTime',
	PREVIOUS_MONTH: 'PreviousMonth',
	CURRENT_MONTH: 'CurrentMonth',
	PREVIOUS_YEAR: 'PreviousYear',
	CURRENT_YEAR: 'CurrentYear',
	YEAR_TO_DATE: 'YearToDate',
	CUSTOM_RANGE: 'CustomRange',
};

export const getDateRangeLabel = (singleNumber, intl) => {
	switch (singleNumber) {
		case PROJECT_PORTFOLIO_DATE_RANGE.ALL_TIME:
			return intl.formatMessage({id: 'common.all_time'});
		case PROJECT_PORTFOLIO_DATE_RANGE.PREVIOUS_MONTH:
			return intl.formatMessage({id: 'common.previous_month'});
		case PROJECT_PORTFOLIO_DATE_RANGE.CURRENT_MONTH:
			return intl.formatMessage({id: 'common.current_month'});
		case PROJECT_PORTFOLIO_DATE_RANGE.PREVIOUS_YEAR:
			return intl.formatMessage({id: 'common.previous_year'});
		case PROJECT_PORTFOLIO_DATE_RANGE.CURRENT_YEAR:
			return intl.formatMessage({id: 'common.current_year'});
		case PROJECT_PORTFOLIO_DATE_RANGE.YEAR_TO_DATE:
			return intl.formatMessage({id: 'common.year_to_date'});
		case PROJECT_PORTFOLIO_DATE_RANGE.CUSTOM_RANGE:
			return intl.formatMessage({id: 'common.custom_range'});
	}
};
