import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {FormattedHTMLMessage} from 'react-intl';

const Triangle = () => (
	<svg width="30" height="13" viewBox="0 0 87 38" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8.8404 27.1077L43.5 1.24767L78.1596 27.1077H8.8404Z" fill="#EBEBEB" stroke="#CBCBCB" strokeWidth="2" />
		<path fillRule="evenodd" clipRule="evenodd" d="M75.2611 26.1077L77.69 28.1077H9.42L11.8489 26.1077H75.2611Z" />
		<path
			d="M75.2611 26.1077L76.5324 24.5638L75.9786 24.1077H75.2611V26.1077ZM77.69 28.1077V30.1077H83.2652L78.9613 26.5638L77.69 28.1077ZM9.42 28.1077L8.14868 26.5638L3.84479 30.1077H9.42V28.1077ZM11.8489 26.1077V24.1077H11.1314L10.5775 24.5638L11.8489 26.1077ZM73.9898 27.6516L76.4187 29.6516L78.9613 26.5638L76.5324 24.5638L73.9898 27.6516ZM77.69 26.1077H9.42V30.1077H77.69V26.1077ZM10.6913 29.6516L13.1202 27.6516L10.5775 24.5638L8.14868 26.5638L10.6913 29.6516ZM11.8489 28.1077H75.2611V24.1077H11.8489V28.1077Z"
			fill="#EBEBEB"
		/>
	</svg>
);
class Tooltip extends Component {
	constructor(props) {
		super(props);

		this.state = {
			keepOpened: false,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.calculateTooltipPosition && !prevProps.shown && this.props.shown) {
			this.props.calculateTooltipPosition();
		}
	}
	render() {
		let style = {
			top: this.props.top === null ? undefined : `${this.props.top}px`,
			bottom: this.props.bottom === null ? undefined : `${this.props.bottom}px`,
			left: this.props.left === null ? undefined : `${this.props.left}px`,
			right: this.props.right === null ? undefined : `${this.props.right}px`,
		};

		const {infoText} = this.props;
		const textIsArray = Array.isArray(infoText);
		const triangleLocation = this.props.triangleLocation || '';
		if (this.props.width) {
			style.width = this.props.width + 'px';
		}

		if (this.props.customMaxWidth) {
			style.maxWidth = this.props.customMaxWidth + 'px';
		}

		const doNotShowChecked = true; // Util.localStorageGetItem('do_not_show_hours_input_tooltip_anymore') === 'true';
		return (this.props.shown && infoText) ||
			(this.props.shown && this.props.onFocusText && !doNotShowChecked) ||
			this.state.keepOpened ? (
			ReactDOM.createPortal(
				<div
					ref={div => (this.component = div)}
					className={
						'info-box-component' +
						(this.props.bordered ? ' bordered' : '') +
						(this.props.grey ? ' grey' : '') +
						(this.props.autoPlace ? ' fixed' : '') +
						(triangleLocation.includes('right') ? ' flex' : '')
					}
					style={style}
					onMouseEnter={e => this.setState({keepOpened: true})}
					onMouseLeave={e => this.setState({keepOpened: false})}
				>
					<div className="box-content-relative-wrapper">
						{triangleLocation ? (
							<div className={`triangle ${triangleLocation}`}>
								<Triangle />
							</div>
						) : null}
						{/* {triangleLocation ? <div className={`triangle ${triangleLocation}`} /> : null}
						{triangleLocation ? <div className={`triangleBorder ${triangleLocation}`} /> : null} */}
						<div className={'info-box-content'}>
							{textIsArray ? (
								<div className={'info-box-text-container'}>
									{infoText.map((item, index) => {
										return item.hide ? null : (
											<div key={index} className="wrapper">
												{item.header ? (
													<div className={`header${index === 0 ? ' first' : ''}`}>{item.header}</div>
												) : (
													<>
														{item.title ? (
															<div className="title">
																{item.titleIcon ? (
																	<div className="title-icon">{item.titleIcon}</div>
																) : null}
																<div className="title-text">
																	{this.props.translatedMessage ? (
																		<span>{item.title}</span>
																	) : (
																		<FormattedHTMLMessage id={item.title} />
																	)}
																</div>
															</div>
														) : null}
														{item.description ? (
															<div className="description">
																<div className="description-text">{item.description}</div>
															</div>
														) : (
															<div className="details">
																{item.details ? (
																	item.details.map((detail, index) => {
																		return (
																			<div
																				key={`${detail}-${index}`}
																				className={
																					'detail' +
																					(index % 2 === 0 && !item.noBox
																						? ' boxed'
																						: '')
																				}
																			>
																				{this.props.translatedMessage ? (
																					<span>{detail}</span>
																				) : (
																					<FormattedHTMLMessage id={detail} />
																				)}
																			</div>
																		);
																	})
																) : item.list ? (
																	<ul>
																		{item.list.map(listItem => (
																			<li>{listItem}</li>
																		))}
																	</ul>
																) : item.unstyledList ? (
																	<ul
																		style={{
																			listStyleType: 'none',
																			margin: '0',
																			padding: '8px',
																			overflow: 'hidden',
																		}}
																	>
																		{item.unstyledList.map(listItem => (
																			<li>{listItem}</li>
																		))}
																	</ul>
																) : null}
															</div>
														)}
													</>
												)}
											</div>
										);
									})}
								</div>
							) : (
								<div className={'info-box-text'}>
									{this.props.dontShowAgain ? (
										<div className={'do-not-show-again'}>
											{this.props.onFocusText}

											<div className={'link'} onClick={this.props.onTooltipOnClick.bind(this)}>
												<FormattedHTMLMessage id={'common.do_not_show_again'}></FormattedHTMLMessage>
											</div>
										</div>
									) : (
										infoText
									)}
								</div>
							)}
						</div>
					</div>
				</div>,
				document.querySelector('#root-portal-container')
			)
		) : this.props.noHidden ? null : (
			<div className={'hidden'} />
		);
	}
}
Tooltip.propTypes = {
	infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]).isRequired,
	triangleLocation: PropTypes.oneOf(['bottomMiddle', 'topMiddle', 'topRight', 'right', 'left', 'lowerLeft', 'lowerRight']),
	shown: PropTypes.bool.isRequired,
	autoPlaceOffset: PropTypes.object,
	timer: PropTypes.number, // prop that sets how long should pass unitl the tooltip is shown
	duration: PropTypes.number, // props that sets how long time should the tooltip be shown for after it has appeared
	infinteDuration: PropTypes.bool, // prop that will show the tooltip for as long as the element is hovered,
	translatedMessage: PropTypes.bool, // should be set to true if detail messages you are passing are not language keys but already translated messages
};
export default Tooltip;
