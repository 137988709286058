import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexColumn, Label, Text, FlexRow } from '@forecast-it/design-system';
export const FieldLabel = ({ labelId, helperTextId, optional, children }) => (React.createElement(FlexColumn, { gap: 'xs' },
    React.createElement(Label, null,
        React.createElement(FormattedMessage, { id: labelId }),
        " ",
        optional && React.createElement(FormattedMessage, { id: "common.field_optional_label" })),
    !!helperTextId && (React.createElement(Text, { color: Text.Color.medium },
        React.createElement(FormattedMessage, { id: helperTextId }))),
    children));
export const PlaceholderItem = ({ messageId }) => (React.createElement(FlexRow, null,
    React.createElement(Text, { color: Text.Color.medium },
        React.createElement(FormattedMessage, { id: messageId }))));
