import React from 'react';
import {Table} from 'web-components';
import {
	CUSTOM_FIELD_PREFIX,
	getCustomFieldColumnName,
} from '../../../forecast-app/project-tab/projects/scoping-page/ProjectScopingUtil';
import Util from '../../../forecast-app/shared/util/util';

export const getBaseLevelTableHeader = (columns, customFieldDefinitions) => {
	const customFields = Util.hasCustomFields()
		? customFieldDefinitions
				.map(cfd => {
					const key = Object.keys(columns).find(
						key => key === CUSTOM_FIELD_PREFIX + '.' + getCustomFieldColumnName('TIME_REG', cfd.key)
					);
					return {
						displayName: cfd.displayName,
						visible: columns[key] || false,
					};
				})
				.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
		: [];

	return (
		<Table.Header>
			<Table.HeaderColumn usePadding flex={1} align="left" visible={true} width="MEDIUM">
				<Table.HeaderColumn.Title>{'Project'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="left" visible={true} width="MEDIUM">
				<Table.HeaderColumn.Title>{'Task'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn usePadding align="left" visible={columns.phaseName > 0} width="MEDIUM">
				<Table.HeaderColumn.Title>{'Phase'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={true} width="SMALL">
				<Table.HeaderColumn.Title>{'Reported'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.notes > 0} width="MEDIUM">
				<Table.HeaderColumn.Title>{'Notes'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.date > 0} width="SMALL">
				<Table.HeaderColumn.Title>{'Date'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={true} width="MEDIUM">
				<Table.HeaderColumn.Title>{'Person'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.roleName > 0} width="MEDIUM">
				<Table.HeaderColumn.Title>{'Role'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.clientName > 0} width="MEDIUM">
				<Table.HeaderColumn.Title>{'Client'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.billable > 0} width="SMALL">
				<Table.HeaderColumn.Title>{'Billable'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns.price > 0} width="MEDIUM">
				<Table.HeaderColumn.Title>{'Actual Billable Time Spent To Date'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="right" usePadding visible={columns.cost > 0} width="MEDIUM">
				<Table.HeaderColumn.Title>{'Actual Cost To Date'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.invoiced > 0} width="SMALL">
				<Table.HeaderColumn.Title>{'Invoiced'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			<Table.HeaderColumn align="left" usePadding visible={columns.approvalStatus > 0} width="SMALL">
				<Table.HeaderColumn.Title>{'Approved'}</Table.HeaderColumn.Title>
			</Table.HeaderColumn>
			{customFields.map(customField => (
				<Table.HeaderColumn align="center" usePadding visible={customField.visible} width="MEDIUM">
					<Table.HeaderColumn.Title>{customField.displayName}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
			))}
		</Table.Header>
	);
};
