import Group from '../../canvas-timeline/canvas_timeline_group';
import {
	GROUP_TYPE,
	GROUP_SECTION_WIDTH,
	drawBackground,
	GROUP_SECTION_TEXT_GREY_DARK,
	drawBorderLines,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_PADDING_LEFT,
	drawRectangle,
	GROUP_SECTION_BADGE_PADDING_HORIZONTAL,
	EXPAND_ICON_WIDTH,
	getTrimmedText,
	drawBorder,
	GROUP_SECTION_TEXT_GREY,
	GROUP_SECTION_SPACING_LEVEL_ONE,
	CANVAS_GROUP_LINES_STROKE_COLOR_LIGHT,
	GROUP_SECTION_PROJECT_BADGE_WIDTH,
	GROUP_SECTION_PROJECT_BADGE_HEIGHT,
	GROUP_SECTION_PADDING_LEFT_SMALL,
} from '../../canvas-timeline/canvas_timeline_util';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {PLACEHOLDERS_GROUP_BY} from '../../placeholders-scheduling/CanvasPlaceholderSchedulingConstants';
import {drawConnectedProjectBadge, drawProjectBadge} from '../../DrawingUtils';
import {getProjectOrProjectGroupIndicatorString} from '../../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';

class PlaceholdersGroupingGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PLACEHOLDERS_SCHEDULING_PLACEHOLDER_GROUPING_GROUP, data);
		this.renderRowLines = true;
		this.hideIfAllChildrenAreFiltered = true;
		this.includeInRowHover = false;
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {height, expanded, data} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);
		const {groupBy, role, project, id, totalMinutesMap} = data;
		const {intl} = this.pageComponent.props;
		const isProjectGroup = !!project?.companyProjectGroupId;

		// group by
		const groupByProject = groupBy === PLACEHOLDERS_GROUP_BY.PROJECT;

		// positioning
		const xOffset = x + GROUP_SECTION_SPACING_LEVEL_ONE + EXPAND_ICON_WIDTH;
		const center = y + height / 2;

		drawBackground(canvasContext, x, y, width, height, true);

		// right border
		drawBorder(canvasContext, x + width - 1, y - 1, height, true);

		// expand icon
		canvasContext.drawImage(
			cacheManager.getCommonImage(expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON),
			x + GROUP_SECTION_PADDING_LEFT,
			y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
			GROUP_SECTION_EXPAND_ICON_WIDTH,
			GROUP_SECTION_EXPAND_ICON_HEIGHT
		);

		// Calculate total minutes badge width
		canvasContext.font = '500 10px ' + Util.getFontFamily();
		const totalMinutes = Util.convertMinutesToFullHour(totalMinutesMap.get(id) || 0, intl);
		const totalMinutesBadgeWidth = canvasContext.measureText(totalMinutes).width + GROUP_SECTION_BADGE_PADDING_HORIZONTAL;
		const totalMinutesBadgeHeight = 20;
		const totalMinutesBadgeXPos = GROUP_SECTION_WIDTH - GROUP_SECTION_PADDING_LEFT - totalMinutesBadgeWidth;

		if (groupByProject) {
			const projectColor = project.color || project.projectColor;

			// project badge
			const projectId = getProjectOrProjectGroupIndicatorString({
				companyProjectId: project.companyProjectId,
				companyProjectGroupId: project.companyProjectGroupId,
				customProjectId: project.customProjectId,
			});
			canvasContext.font = `600 13px ` + Util.getFontFamily();
			const projectIdWidth = canvasContext.measureText(projectId).width;

			// size
			const badgeWidth = GROUP_SECTION_PROJECT_BADGE_WIDTH;
			const badgeHeight = GROUP_SECTION_PROJECT_BADGE_HEIGHT;

			// position
			const badgeXPos = x + xOffset;
			const badgeYPos = center - badgeHeight / 2;

			this.badgeX = badgeXPos;
			this.badgeY = badgeYPos;

			if (isProjectGroup) {
				drawConnectedProjectBadge(canvasContext, badgeXPos, badgeYPos, {
					backgroundColor: projectColor,
				});
			} else {
				drawProjectBadge(canvasContext, badgeXPos, badgeYPos, {
					backgroundColor: projectColor,
				});
			}

			canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
			const projectNameAndIdPosY = project.client ? center - 2 : center + 4;
			const textStartX = badgeXPos + badgeWidth + GROUP_SECTION_PADDING_LEFT;

			canvasContext.font = `600 13px ` + Util.getFontFamily();
			canvasContext.fillText(projectId, textStartX, projectNameAndIdPosY);
			canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY;

			// project name
			canvasContext.font = '500 13px ' + Util.getFontFamily();
			const projectNameXPos = textStartX + projectIdWidth + GROUP_SECTION_PADDING_LEFT_SMALL;
			const maxNameWidth = width - (projectNameXPos + totalMinutesBadgeWidth + GROUP_SECTION_PADDING_LEFT * 2);
			const formattedProjectName = getTrimmedText(canvasContext, project.name, maxNameWidth);
			const titleWidth = canvasContext.measureText(formattedProjectName).width;
			this.nameEndX = projectNameXPos + titleWidth;
			canvasContext.fillText(formattedProjectName, projectNameXPos, projectNameAndIdPosY);

			// client name
			if (project.client) {
				canvasContext.font = '500 10px ' + Util.getFontFamily();
				const formattedClientName = getTrimmedText(canvasContext, project.client.name, maxNameWidth);

				canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY;

				canvasContext.fillText(formattedClientName, textStartX, center + 10);
			}
		} else {
			const roleNameXPos = x + xOffset;
			const maxNameWidth =
				GROUP_SECTION_WIDTH -
				roleNameXPos -
				(GROUP_SECTION_PADDING_LEFT + totalMinutesBadgeWidth + GROUP_SECTION_PADDING_LEFT);

			// role name
			canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
			canvasContext.font = '500 12px ' + Util.getFontFamily();
			const formattedRoleName = getTrimmedText(canvasContext, role.name, maxNameWidth);
			canvasContext.fillText(formattedRoleName, roleNameXPos, y + 28);
			drawBorderLines(canvasContext, 0, y, width, height, true);
		}

		// Total minutes badge
		drawRectangle(
			canvasContext,
			totalMinutesBadgeXPos,
			center - totalMinutesBadgeHeight / 2,
			totalMinutesBadgeWidth,
			totalMinutesBadgeHeight,
			{
				backgroundColor: CANVAS_GROUP_LINES_STROKE_COLOR_LIGHT,
				borderRadius: 10,
			}
		);

		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
		canvasContext.font = '500 10px ' + Util.getFontFamily();
		canvasContext.fillText(totalMinutes, totalMinutesBadgeXPos + GROUP_SECTION_BADGE_PADDING_HORIZONTAL / 2, center + 4);
	}
}

export default PlaceholdersGroupingGroup;
