import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import Gauge from '../../d3/gauge';

class overallVelocityComponent extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}

	render() {
		if (this.props.viewer.insightComponentsData.overallVelocity) {
			return (
				<Gauge
					title={this.props.title}
					width={280}
					height={360}
					value={this.props.viewer.insightComponentsData.overallVelocity.velocity}
				/>
			);
		} else {
			return null;
		}
	}
}

const overallVelocityComponentQuery = graphql`
	query overallVelocityComponent_Query($shareKey: String, $projectId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_overall_velocity")
			...overallVelocityComponent_viewer
				@arguments(shareKey: $shareKey, projectId: $projectId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {overallVelocityComponentQuery};

export default createFragmentContainer(overallVelocityComponent, {
	viewer: graphql`
		fragment overallVelocityComponent_viewer on Viewer
		@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
			insightComponentsData(shareKey: $shareKey) {
				overallVelocity(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
					velocity
				}
			}
		}
	`,
});
