// Implementation forked from https://github.com/jaketodaro/discrete-interval-tree

import Interval from './interval';

export default class DiscreteIntervalNode {
	constructor(start, end, parent) {
		this.interval = new Interval(start, end);

		this.parent = parent;

		this.left = null;
		this.right = null;
	}

	inOrderTraverse(visit) {
		if (this.left) {
			this.left.inOrderTraverse(visit);
		}
		visit(this.interval);
		if (this.right) {
			this.right.inOrderTraverse(visit);
		}
	}

	overlaps(start, end) {
		if (this.interval.overlaps(start, end)) {
			return true;
		} else if (this.left && end < this.interval.start) {
			return this.left.overlaps(start, end);
		} else if (this.right && start > this.interval.end) {
			return this.right.overlaps(start, end);
		} else {
			return false;
		}
	}

	getLimitedInterval(start, end) {
		const limitStart = start > this.interval.start;
		const limitEnd = end < this.interval.end;

		if (limitStart || limitEnd) {
			return new Interval(limitStart ? start : this.interval.start, limitEnd ? end : this.interval.end);
		}

		return this.interval;
	}

	getOverlappingIntervals(start, end, intervals, limitToStartAndEnd) {
		if (this.interval.overlaps(start, end)) {
			const interval = limitToStartAndEnd ? this.getLimitedInterval(start, end) : this.interval;
			intervals.push(interval);
		}

		if (this.left && start < this.interval.start) {
			this.left.getOverlappingIntervals(start, end, intervals, limitToStartAndEnd);
		}

		if (this.right && end > this.interval.end) {
			this.right.getOverlappingIntervals(start, end, intervals, limitToStartAndEnd);
		}
	}

	isLeaf() {
		return !this.left && !this.right;
	}

	hasOneChild() {
		return (this.left && !this.right) || (!this.left && this.right);
	}

	getLeftMostLeaf() {
		return this.left ? this.left.getLeftMostLeaf() : this;
	}

	getRightMostLeaf() {
		return this.right ? this.right.getRightMostLeaf() : this;
	}

	replaceParent(node) {
		this.left = node.left;
		this.right = node.right;

		if (node.left) {
			node.left.parent = this;
		}
		if (node.right) {
			node.right.parent = this;
		}
	}

	absorbLeft(node) {
		this.interval.start = node.interval.start;
		this.left = node.left;

		if (this.left) {
			this.left.parent = this;
		}

		return this;
	}

	absorbRight(node) {
		this.interval.end = node.interval.end;
		this.right = node.right;

		if (this.right) {
			this.right.parent = this;
		}

		return this;
	}
}
