import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {injectIntl} from 'react-intl';
import TooltipContainer from '../../../forecast-app/shared/components/tooltips/tooltip_container';
import * as tracking from '../../../tracking';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

class ExpandAllButton extends Component {
	getIsCollapseAllAction(groups, expandedGroupCount, isSingleGroup, isProjectTimeline) {
		const unFilteredGroups = groups.filter(group => !group.filtered);
		const minimumGroupCount = isSingleGroup ? 0 : unFilteredGroups.length > 1 ? 1 : 0;

		return isSingleGroup && isProjectTimeline
			? !this.isRootOnlyExpanded(groups[0])
			: expandedGroupCount > minimumGroupCount;
	}
	onClick() {
		const {
			groups,
			redrawTimeline,
			expandedGroupCount,
			isSingleGroup,
			onGroupExpansionToggle,
			doFetchFullData,
			isUsingNewLazyLoad,
			isProjectTimeline,
		} = this.props;

		this.isCollapseAllAction = this.getIsCollapseAllAction(groups, expandedGroupCount, isSingleGroup, isProjectTimeline);

		const processGroup = group => {
			const isRootProject = !group.parentGroup;
			// We will only process groups that have not been filtered
			const notFiltered = !group.filtered;
			const shouldChangeGroup =
				(group.expanded && this.isCollapseAllAction) || (!group.expanded && !this.isCollapseAllAction);
			//If collapsing, process children first so that they can propagate their height change to the parent before parent height difference is calculated
			//If expanding, process children after the parent group because if they were processed last, like in collapsing, the height difference would be accounted for multiple times (first for the child and then the parent again)
			if (!this.isCollapseAllAction && shouldChangeGroup && notFiltered) {
				group.toggleExpansion();
				onGroupExpansionToggle && onGroupExpansionToggle(group, true);
			}
			// We check notFiltered here, because when a parent is marked as filtered
			// it does not necessarily mean that children are also marked as filtered.
			if (group.groups && notFiltered) {
				for (const childGroup of group.groups) {
					//parentGroup is normally set on groups when the parent is first expanded
					//when doing expand all, groups nested two or more levels deep would not have the prop on them yet so need to set it here
					//maybe a bit questionable but don't have a better idea at the moment, let Jacek K know if you read this and have a better idea
					if (!childGroup.parentGroup) {
						childGroup.parentGroup = group;
					}
					processGroup(childGroup);
				}
			}
			if (
				this.isCollapseAllAction &&
				shouldChangeGroup &&
				notFiltered &&
				(!isSingleGroup || !isProjectTimeline || !isRootProject)
			) {
				group.toggleExpansion();
				onGroupExpansionToggle && onGroupExpansionToggle(group, true);
			}
		};

		for (const group of groups) {
			processGroup(group);
		}
		redrawTimeline();
		if (isUsingNewLazyLoad) {
			doFetchFullData();
		}
		tracking.trackEvent('Expand Timeline', {hasExpanded: !this.isCollapseAllAction});
		trackEvent('Timelind Expand All', 'Toggled', {hasExpanded: !this.isCollapseAllAction});
	}

	isRootOnlyExpanded(root) {
		for (const group of root.groups) {
			if (!group.filtered && group.expanded) {
				return false;
			}
		}

		return true;
	}

	render() {
		const portalDestination = document.getElementById('expand-all-button-container');
		if (!portalDestination) return null;
		const {groups} = this.props;
		if (!groups || groups.length === 0) {
			return null;
		}

		return ReactDOM.createPortal(
			<div className={'expansion-button-container'}>
				<TooltipContainer
					infoText={this.props.intl.formatMessage({
						id: this.isCollapseAllAction ? 'common.collapse_all' : 'common.expand_all',
					})}
				>
					<button
						className={'expansion-button ' + (this.isCollapseAllAction ? 'collapse' : '')}
						onClick={this.onClick.bind(this)}
					/>
				</TooltipContainer>
			</div>,
			portalDestination
		);
	}
}

ExpandAllButton.propTypes = {
	groups: PropTypes.array.isRequired,
	expandedGroupCount: PropTypes.number.isRequired,
	redrawTimeline: PropTypes.func.isRequired,
	isSingleGroup: PropTypes.bool.isRequired, // There is only one root group, which should not be collapsed
};

export default injectIntl(ExpandAllButton);
