/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type APPROVAL_STATUS = "APPROVED" | "NOT_SUBMITTED" | "SUBMITTED" | "%future added value";
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TimeApprovalSection_viewer$ref: FragmentReference;
declare export opaque type TimeApprovalSection_viewer$fragmentType: TimeApprovalSection_viewer$ref;
export type TimeApprovalSection_viewer = {|
  +component: ?string,
  +id: string,
  +company: ?{|
    +id: string,
    +useTimeApproval: ?boolean,
    +useTimeOffApproval: ?boolean,
    +useInternalTimeApproval: ?boolean,
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +timeRegistrations: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +approvalStatus: ?APPROVAL_STATUS,
          +noApprovalStatusChange: ?string,
          +day: ?number,
          +month: ?number,
          +year: ?number,
          +minutesRegistered: ?number,
          +billableMinutesRegistered: ?number,
          +billable: ?boolean,
          +notes: ?string,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
          +idleTime: ?{|
            +id: string,
            +name: ?string,
            +isInternalTime: ?boolean,
          |},
          +person: ?{|
            +id: string,
            +firstName: ?string,
            +lastName: ?string,
            +fullName: ?string,
            +email: ?string,
            +profilePictureId: ?string,
            +startDate: ?string,
            +endDate: ?string,
            +monday: ?number,
            +tuesday: ?number,
            +wednesday: ?number,
            +thursday: ?number,
            +friday: ?number,
            +saturday: ?number,
            +sunday: ?number,
            +department: ?{|
              +id: string
            |},
            +role: ?{|
              +id: string
            |},
          |},
          +project: ?{|
            +id: string,
            +name: ?string,
            +projectColor: ?string,
            +companyProjectId: ?number,
            +customProjectId: ?string,
            +fullAccessToProject: ?boolean,
            +budgetType: ?BUDGET_TYPE,
            +billable: ?boolean,
            +client: ?{|
              +id: string,
              +name: ?string,
            |},
            +projectPersons: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +person: ?{|
                    +id: string,
                    +fullName: ?string,
                    +profilePictureId: ?string,
                  |}
                |}
              |}>
            |},
          |},
          +task: ?{|
            +id: string,
            +name: ?string,
            +companyTaskId: ?number,
            +estimateForecast: ?number,
            +billable: ?boolean,
            +estimateForecastMinutes: ?number,
            +totalMinutesRegistered: ?number,
            +project: ?{|
              +id: string,
              +name: ?string,
              +companyProjectId: ?number,
              +customProjectId: ?string,
              +projectColor: ?string,
              +fullAccessToProject: ?boolean,
              +estimationUnit: ?string,
              +minutesPerEstimationPoint: ?number,
              +budgetType: ?BUDGET_TYPE,
              +billable: ?boolean,
              +client: ?{|
                +id: string,
                +name: ?string,
              |},
              +projectPersons: ?{|
                +edges: ?$ReadOnlyArray<?{|
                  +node: ?{|
                    +person: ?{|
                      +id: string,
                      +fullName: ?string,
                      +profilePictureId: ?string,
                    |}
                  |}
                |}>
              |},
            |},
          |},
        |}
      |}>
    |},
  |},
  +$refType: TimeApprovalSection_viewer$ref,
|};
export type TimeApprovalSection_viewer$data = TimeApprovalSection_viewer;
export type TimeApprovalSection_viewer$key = {
  +$data?: TimeApprovalSection_viewer$data,
  +$fragmentRefs: TimeApprovalSection_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profilePictureId",
  "storageKey": null
},
v6 = [
  (v0/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customProjectId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullAccessToProject",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "budgetType",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Client",
  "kind": "LinkedField",
  "name": "client",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "contactsOnly",
      "value": true
    },
    {
      "kind": "Literal",
      "name": "first",
      "value": 100
    }
  ],
  "concreteType": "ProjectPersonTypeConnection",
  "kind": "LinkedField",
  "name": "projectPersons",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectPersonTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectPerson",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Person",
              "kind": "LinkedField",
              "name": "person",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "projectPersons(contactsOnly:true,first:100)"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDateString",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDateString",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "approvableTime",
      "type": "Boolean"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "approvableTimeOff",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "timeRegistrations"
        ]
      }
    ]
  },
  "name": "TimeApprovalSection_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "time-approval-section"
        }
      ],
      "kind": "ScalarField",
      "name": "component",
      "storageKey": "component(name:\"time-approval-section\")"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeOffApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useInternalTimeApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "timeRegistrations",
          "args": null,
          "concreteType": "TimeRegTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_timeRegistrations_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeRegTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TimeRegType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "approvalStatus",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "noApprovalStatusChange",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "day",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "month",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "year",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "minutesRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableMinutesRegistered",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "notes",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Role",
                      "kind": "LinkedField",
                      "name": "role",
                      "plural": false,
                      "selections": (v3/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IdleTime",
                      "kind": "LinkedField",
                      "name": "idleTime",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isInternalTime",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "firstName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "lastName",
                          "storageKey": null
                        },
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        },
                        (v5/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "endDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "monday",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "tuesday",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "wednesday",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "thursday",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "friday",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "saturday",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "sunday",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "DepartmentType",
                          "kind": "LinkedField",
                          "name": "department",
                          "plural": false,
                          "selections": (v6/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Role",
                          "kind": "LinkedField",
                          "name": "role",
                          "plural": false,
                          "selections": (v6/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/),
                        (v1/*: any*/),
                        (v12/*: any*/),
                        (v13/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Task",
                      "kind": "LinkedField",
                      "name": "task",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "companyTaskId",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "estimateForecast",
                          "storageKey": null
                        },
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "estimateForecastMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalMinutesRegistered",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectType",
                          "kind": "LinkedField",
                          "name": "project",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v2/*: any*/),
                            (v8/*: any*/),
                            (v9/*: any*/),
                            (v7/*: any*/),
                            (v10/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "estimationUnit",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "minutesPerEstimationPoint",
                              "storageKey": null
                            },
                            (v11/*: any*/),
                            (v1/*: any*/),
                            (v12/*: any*/),
                            (v13/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '15aa48bc77999df19a61a5beaafb92ce';

module.exports = node;
