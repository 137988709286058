/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": {
            "plural": true
        },
        "name": "LabelDropdown_labels",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
            },
            (v0 /*: any*/),
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "LabelCategory",
                "kind": "LinkedField",
                "name": "category",
                "plural": false,
                "selections": [
                    (v1 /*: any*/),
                    (v0 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "type": "Label"
    };
})();
node.hash = 'bb65af02bff90a4782cdd103727b3a83';
export default node;
