/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type InvoiceChart_project$ref = any;
type Totals_project$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type InvoiceBudgetData_project$ref: FragmentReference;
declare export opaque type InvoiceBudgetData_project$fragmentType: InvoiceBudgetData_project$ref;
export type InvoiceBudgetData_project = {|
  +$fragmentRefs: InvoiceChart_project$ref & Totals_project$ref,
  +$refType: InvoiceBudgetData_project$ref,
|};
export type InvoiceBudgetData_project$data = InvoiceBudgetData_project;
export type InvoiceBudgetData_project$key = {
  +$data?: InvoiceBudgetData_project$data,
  +$fragmentRefs: InvoiceBudgetData_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceBudgetData_project",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceChart_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Totals_project"
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = '898feed8f19dcc03ecf0e535174138a9';

module.exports = node;
