import {
	PLACEHOLDERS_EYE_OPTIONS,
	PLACEHOLDERS_FILTER_STORAGE_KEY,
	PLACEHOLDERS_GROUP_BY,
	PLACEHOLDERS_STAFFING_FILTER_STORAGE_KEY,
	PLACEHOLDERS_STAFFING_SORT_BY,
	STAFFING_CHANGE_LIST_ENTITIES,
	STAFFING_CREATED_ENTITY_ID_PREFIX,
} from './CanvasPlaceholderSchedulingConstants';
import CapacityPlaceholderGroup from '../components/groups/capacity_placeholder_group';
import {
	createPersonProjectGroups,
	getCanvasTimelineDateFromMoment,
	setInitialExpansion,
} from '../canvas-timeline/canvas_timeline_util';
import PlaceholderGroupingGroup from '../components/groups/PlaceholderGroupingGroup';
import {getAllocationTotalNew} from '../../scheduling/project_allocation_logic';
import PlaceholderAllocationItem from '../components/items/placeholder_allocation_item';
import Moment from 'moment';
import _ from 'lodash';
import {
	FILTER_OPERATOR,
	getEntityOperatorKey,
	getFilterFunctions,
} from '../../../forecast-app/shared/components/filters/filter_logic';
import StaffingGroupingGroup from '../components/groups/StaffingGroupingGroup';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import NonProjectTimeGroup from '../components/groups/non_project_time_group';
import {v4} from 'uuid';
import Util from '../../../forecast-app/shared/util/util';
import {removeFromArray} from '../utils';
import {SCHEDULE_ITEM_GHOST} from '../constants';
import {isProjectAllocationItem} from '../SchedulingUtils';
import {updateAllocationItemsVisibility} from '../components/allocation_controls/AllocationControlsCanvasUtils';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/FilterWrapper';
import {FILTER_TYPE} from '../../../constants';
import DataManager from '../DataManager';
import NoContentGroup from '../components/groups/no_content_group';
import PersonGroup from '../components/groups/person_group';
import IDManager, {NO_ROLE, STAFFING_GROUPING_TYPE} from '../IDManager';
import {generateNoAccessHeatmap} from '../heatmap/AnonymizedHeatmapLogic';
import ComposeManager from '../ComposeManager';

export const isEyeOptionChecked = (eyeOptions, name) => {
	return eyeOptions.find(option => option.name === name)?.checked;
};

const sortByRanking = (filteredPersons, ranking) => {
	return [...filteredPersons].sort((a, b) => a.ranks[ranking] - b.ranks[ranking]).map(person => person.personId);
};

const constructTrackingInfo = (pageComponent, changeListEntity, dataEntityPersonId, changeListOptions) => {
	if (changeListEntity !== STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE) {
		return null;
	}

	const personAlreadyAllocated = pageComponent.state[changeListEntity].some(
		changeListEntity => changeListEntity.personId === dataEntityPersonId
	);
	const filteredPersonIds = pageComponent.heatmapCache ? [...pageComponent.heatmapCache.keys()] : [];
	const suggestedPersons = pageComponent.state.data.suggestedPersons || [];
	const sortBy = pageComponent.state.sortBy ? pageComponent.state.sortBy.toUpperCase() : '';
	const filteredPersons = suggestedPersons.filter(suggestedPerson => filteredPersonIds.includes(suggestedPerson.personId));
	const bucketRanking = sortByRanking(filteredPersons, 'buckets');
	const suitabilityRanking = sortByRanking(filteredPersons, 'suitability');
	const availabilityRanking = sortByRanking(filteredPersons, 'availability');
	const filters = pageComponent.state.filters;
	const staffingOperation = changeListOptions?.staffingOperation;

	return {
		sortingType: personAlreadyAllocated ? 'ALREADY_ALLOCATED' : sortBy,
		filteredAvailabilityRank: personAlreadyAllocated ? 0 : availabilityRanking.indexOf(dataEntityPersonId) + 1,
		filteredSuitabilityRank: personAlreadyAllocated ? 0 : suitabilityRanking.indexOf(dataEntityPersonId) + 1,
		filteredBucketRank: personAlreadyAllocated ? 0 : bucketRanking.indexOf(dataEntityPersonId) + 1,
		filteredPersonIds: filteredPersons.map(person => person.personId),
		filters: filters,
		staffingOperation,
	};
};

const isChangeListEntityValid = changeListEntity => {
	return Object.values(STAFFING_CHANGE_LIST_ENTITIES).includes(changeListEntity);
};

const addOrReplaceStaffingOperation = (pageComponent, changeList, changeListEntity, dataEntity, options) => {
	const {state} = pageComponent;

	if (!isChangeListEntityValid(changeListEntity)) return;

	const index = state[changeListEntity].findIndex(changeListEntity => changeListEntity.id === dataEntity.id);

	dataEntity.trackingInfo = constructTrackingInfo(pageComponent, changeListEntity, dataEntity.personId, options);

	if (index !== -1) {
		changeList.splice(index, 1, dataEntity);
	} else {
		changeList.push(dataEntity);
	}

	return changeList;
};

export const deleteStaffingOperation = (pageComponent, changeList, changeListEntity, dataEntity) => {
	const {state} = pageComponent;

	if (!isChangeListEntityValid(changeListEntity)) return;

	const index = state[changeListEntity].findIndex(changeListEntity => changeListEntity.id === dataEntity.id);

	if (index !== -1) {
		changeList.splice(index, 1);
	}

	return changeList;
};

export const addToChangeList = (pageComponent, changeListEntity, dataEntity, options = {}) => {
	if (!isChangeListEntityValid(changeListEntity)) return;

	const changeList = pageComponent.state[changeListEntity];

	const newChangeList = addOrReplaceStaffingOperation(pageComponent, changeList, changeListEntity, dataEntity, options);

	pageComponent.setState({
		[changeListEntity]: newChangeList,
	});
};

export const addMultipleDataEntitiesToChangeList = (pageComponent, changeListEntity, dataEntities) => {
	if (!isChangeListEntityValid(changeListEntity)) return;

	const changeList = pageComponent.state[changeListEntity];

	dataEntities.forEach(dataEntity => {
		addOrReplaceStaffingOperation(pageComponent, changeList, changeListEntity, dataEntity);
	});

	pageComponent.setState({
		[changeListEntity]: changeList,
	});
};

export const removeFromChangeList = (pageComponent, changeListEntity, placeholderAllocation) => {
	if (!isChangeListEntityValid(changeListEntity)) return;

	const changeList = pageComponent.state[changeListEntity];

	const newChangeList = deleteStaffingOperation(pageComponent, changeList, changeListEntity, placeholderAllocation);

	pageComponent.setState({
		[changeListEntity]: newChangeList,
	});
};

export const getChangeListEntities = (pageComponent, changeListEntity) => {
	if (isChangeListEntityValid(changeListEntity)) {
		return pageComponent.state[changeListEntity];
	}

	return null;
};

export const getChangeListEntityById = (pageComponent, changeListEntity, id) => {
	if (isChangeListEntityValid(changeListEntity)) {
		return pageComponent.state[changeListEntity]?.find(entity => entity.id === id);
	}

	return null;
};

export const generateStaffingId = () => {
	const uuid = v4();
	return `${uuid}_${STAFFING_CREATED_ENTITY_ID_PREFIX}`;
};

export const isStaffingGeneratedId = id => {
	return id.endsWith(STAFFING_CREATED_ENTITY_ID_PREFIX);
};

export const getStaffingLocalStorageKey = pageComponent => {
	const {staffingModeActive, staffingPlaceholder} = pageComponent.state;

	if (!staffingModeActive || !staffingPlaceholder) return;

	return `${PLACEHOLDERS_STAFFING_FILTER_STORAGE_KEY}_${staffingPlaceholder.id}`;
};

export const getProjectIdFromGroup = projectGroup => {
	return projectGroup?.data.id?.split('-')[1];
};

export const getPlaceholderGroup = (pageComponent, placeholder) => {
	const {groups} = pageComponent.state;
	const groupIdToFind = IDManager.getPlaceholderGroupId(pageComponent, placeholder);
	return groups.find(group => group.id === groupIdToFind);
};

export const getPlaceholderGroupingGroup = (pageComponent, placeholder) => {
	const {groups} = pageComponent.state;
	const groupIdToFind = IDManager.getPlaceholderGroupingId(
		pageComponent,
		placeholder.roleId,
		placeholder.projectId || placeholder.projectGroupId
	);
	return groups.find(group => group.id === groupIdToFind);
};

export const getPersonGroup = (pageComponent, person) => {
	const {groups} = pageComponent.state;

	for (const personGroupingGroup of groups) {
		const personGroup = personGroupingGroup.groups.find(
			group => group.id === IDManager.getPersonGroupId(pageComponent, person.id)
		);

		if (personGroup) {
			return personGroup;
		}
	}

	return null;
};

const subtractFromMap = (key, value, map) => {
	if (key) {
		if (map.has(key)) {
			const currentValue = map.get(key);
			map.set(key, currentValue - value);
		}
	}
};

const addToMap = (key, value, map) => {
	if (key) {
		if (!map.has(key)) {
			map.set(key, value);
		} else {
			const currentValue = map.get(key);
			map.set(key, currentValue + value);
		}
	}
};

export const refreshPlaceholderGroups = (pageComponent, placeholder) => {
	const {groups, totalMinutesMap, staffingModeActive} = pageComponent.state;

	if (staffingModeActive) {
		const placeholderGroup = groups.find(group => group.id === IDManager.getPlaceholderGroupId(pageComponent, placeholder));
		if (placeholderGroup) {
			placeholderGroup.refreshData({
				...placeholderGroup.data,
				totalMinutesMap,
			});
		}
	} else {
		const placeholderGroupingGroup = groups.find(
			group =>
				group.id ===
				IDManager.getPlaceholderGroupingId(
					pageComponent,
					placeholder.roleId,
					placeholder.projectId || placeholder.projectGroupId
				)
		);

		if (placeholderGroupingGroup) {
			const placeholderGroup = placeholderGroupingGroup.groups.find(
				group => group.id === IDManager.getPlaceholderGroupId(pageComponent, placeholder)
			);

			if (placeholderGroup) {
				placeholderGroup.refreshData({
					...placeholderGroup.data,
					totalMinutesMap,
				});
			}
		}
	}
};

export const adjustTotalMinutesMap = (
	placeholder,
	placeholderAllocation,
	totalMinutesMap,
	isDeletion = false,
	isRemoval = false,
	isAddition = false
) => {
	// Subtract current total minutes from all relevant aggregates
	const placeholderAllocationId = placeholderAllocation.id;
	const placeholderId = placeholder.id;
	const projectId = placeholder.projectId || placeholder.projectGroupId;
	const roleId = placeholder.roleId || NO_ROLE;
	const isGhost = placeholderAllocationId.endsWith(SCHEDULE_ITEM_GHOST);

	if (totalMinutesMap.has(placeholderAllocationId) && !isGhost) {
		if ((!placeholderAllocation.personId && !isAddition) || (placeholderAllocation.personId && isRemoval)) {
			const currentTotalMinutes = totalMinutesMap.get(placeholderAllocationId);

			subtractFromMap(placeholderId, currentTotalMinutes, totalMinutesMap);
			subtractFromMap(projectId, currentTotalMinutes, totalMinutesMap);
			subtractFromMap(roleId, currentTotalMinutes, totalMinutesMap);
		}

		totalMinutesMap.delete(placeholderAllocationId);
	}

	if (!isDeletion) {
		const totalMinutes = getAllocationTotalNew(placeholderAllocation);
		totalMinutesMap.set(placeholderAllocationId, totalMinutes);

		if (!placeholderAllocation.personId && !isGhost) {
			addToMap(placeholderId, totalMinutes, totalMinutesMap);
			addToMap(projectId, totalMinutes, totalMinutesMap);
			addToMap(roleId, totalMinutes, totalMinutesMap);
		}
	}
};

export const createGroupsAndItems = pageComponent => {
	const {props} = pageComponent;
	const {formatMessage} = props.intl;
	const {data, groupBy, eyeOptions} = pageComponent.state;

	const groups = [];
	const items = [];

	const groupByRole = groupBy === PLACEHOLDERS_GROUP_BY.ROLE;

	// create placeholder groups
	const placeholderGroups = [];
	for (const placeholder of data.placeholders) {
		const placeholderAllocations = data.placeholderAllocationsByPlaceholder[placeholder.id];
		const hasAllocations = placeholderAllocations?.length;

		if (!isEyeOptionChecked(eyeOptions, PLACEHOLDERS_EYE_OPTIONS.SHOW_PLACEHOLDERS_WITH_NO_ALLOCATIONS) && !hasAllocations)
			continue;

		// create placeholder allocation items
		if (hasAllocations) {
			placeholderAllocations.forEach(placeholderAllocation => {
				const allocationItem = ComposeManager.composePlaceholderAllocation(pageComponent, placeholderAllocation);

				if (allocationItem) {
					items.push(new PlaceholderAllocationItem(pageComponent, allocationItem));
				}
			});
		}

		placeholderGroups.push(
			new CapacityPlaceholderGroup(
				pageComponent,
				ComposeManager.composeCapacityPlaceholderGroup(pageComponent, placeholder)
			)
		);
	}

	// create grouping groups
	if (groupByRole) {
		const placeholderWithoutRoleExists = data.placeholders.find(placeholder => !placeholder.roleId);

		// group by role
		const placeholderRoles = data.roles.filter(role =>
			data.placeholders.find(placeholder => placeholder.roleId === role.id)
		);

		// add a 'no role' grouping group if there are placeholders without a role
		if (placeholderWithoutRoleExists) {
			placeholderRoles.push({
				id: undefined,
				name: formatMessage({id: 'common.no_role'}),
			});
		}

		placeholderRoles.forEach(role => {
			const rolePlaceholderGroups = placeholderGroups.filter(
				placeholderGroup =>
					placeholderGroup.groupId === IDManager.getPlaceholderGroupingId(pageComponent, role.id, null)
			);

			groups.push(
				new PlaceholderGroupingGroup(
					pageComponent,
					ComposeManager.composePlaceholderGroupingGroup(pageComponent, null, role, rolePlaceholderGroups)
				)
			);
		});
	} else {
		// connected projects
		const placeholderProjectGroups = data.projectGroups.filter(projectGroup =>
			data.placeholders.find(placeholder => placeholder.projectGroupId === projectGroup.id)
		);

		placeholderProjectGroups.forEach(projectGroup => {
			const projectPlaceholderGroups = placeholderGroups.filter(
				placeholderGroup =>
					placeholderGroup.groupId === IDManager.getPlaceholderGroupingId(pageComponent, null, projectGroup.id)
			);

			groups.push(
				new PlaceholderGroupingGroup(
					pageComponent,
					ComposeManager.composePlaceholderGroupingGroup(pageComponent, projectGroup, null, projectPlaceholderGroups)
				)
			);
		});

		// projects
		const placeholderProjects = data.projects.filter(project =>
			data.placeholders.find(placeholder => placeholder.projectId === project.id)
		);

		placeholderProjects.forEach(project => {
			const projectPlaceholderGroups = placeholderGroups.filter(
				placeholderGroup =>
					placeholderGroup.groupId === IDManager.getPlaceholderGroupingId(pageComponent, null, project.id)
			);

			groups.push(
				new PlaceholderGroupingGroup(
					pageComponent,
					ComposeManager.composePlaceholderGroupingGroup(pageComponent, project, null, projectPlaceholderGroups)
				)
			);
		});
	}

	return {
		groups,
		items,
	};
};

export const cleanUpGhosting = (pageComponent, placeholderAllocation) => {
	const {items} = pageComponent.state;

	const ghostItemId = placeholderAllocation.id + SCHEDULE_ITEM_GHOST;
	const ghostItem = items.find(item => item.data.placeholderAllocation?.id === ghostItemId);

	if (ghostItem) {
		removeFromChangeList(pageComponent, STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_GHOSTS, ghostItem);
		removeFromArray(items, item => item.data.placeholderAllocation?.id === ghostItemId);
	}
};

export const createGhost = (pageComponent, items, placeholderAllocation) => {
	const allocationClone = {...placeholderAllocation};
	allocationClone.id += SCHEDULE_ITEM_GHOST;

	const allocationItemData = ComposeManager.composePlaceholderAllocation(pageComponent, allocationClone, true);
	if (allocationItemData) {
		items.push(new PlaceholderAllocationItem(pageComponent, allocationItemData));
	}
};

export const calculateStartDateFromPlaceholder = (pageComponent, placeholder) => {
	const {data} = pageComponent.state;
	const {placeholderAllocationsByPlaceholder} = data;
	const staffingPlaceholderAllocations = placeholderAllocationsByPlaceholder[placeholder.id];

	if (!(staffingPlaceholderAllocations?.length > 0)) return null;

	let startDate;
	staffingPlaceholderAllocations.forEach(placeholderAllocation => {
		const allocationStartDate = Moment(placeholderAllocation.startDate);
		if (!startDate || allocationStartDate.isBefore(startDate)) {
			startDate = allocationStartDate;
		}
	});

	return startDate;
};

export const initializeData = (pageComponent, data) => {
	if (!data) return;

	DataManager.setLookupMaps(pageComponent, data);

	pageComponent.setState(
		{
			data,
			groups: [],
			items: [],
		},
		() => {
			const {viewer} = data;

			pageComponent.setState({
				initialDataLoaded: true,
				allDataLoaded: false,
				allDataLoading: false,
			});

			const {groups, items} = createGroupsAndItems(pageComponent);

			setInitialExpansion(pageComponent, groups);

			const localStorageFilters = localStorage.getItem(PLACEHOLDERS_FILTER_STORAGE_KEY);

			const filters = localStorageFilters ? JSON.parse(localStorageFilters) : {project: {}, placeholder: {}};

			if (localStorageFilters === 'undefined') {
				localStorage.removeItem(PLACEHOLDERS_FILTER_STORAGE_KEY);
			}

			const filterFunctions = getFilterFunctions(filters);

			pageComponent.setState(
				{
					viewer,
					groups,
					items,
					filters,
					filterFunctions,
				},
				() => {
					if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
						DataManager.constructVisibleItemTrees(pageComponent);
					}

					pageComponent.redrawCanvasTimeline({preventFiltering: false, isInitialLoad: true});

					if (pageComponent.state.enteringStaffingMode) {
						pageComponent.activateStaffingMode(pageComponent.props.staffingPlaceholderId);
					}
				}
			);
		}
	);
};

const staffingGroupSortingFunc = pageComponent => (a, b) => {
	const {data, sortBy} = pageComponent.state;
	const suggestedPersons = data.suggestedPersons.map(suggestedPerson => suggestedPerson.personId);
	if (sortBy === PLACEHOLDERS_STAFFING_SORT_BY.ALPHABETICALLY) {
		const nameA = a.data.name;
		const nameB = b.data.name;
		return Util.sortAlphabetically(nameA, nameB);
	} else if (sortBy === PLACEHOLDERS_STAFFING_SORT_BY.SUITABILITY) {
		if (!suggestedPersons.includes(a.data.id)) return 1;
		if (!suggestedPersons.includes(b.data.id)) return -1;
		return suggestedPersons.indexOf(a.data.id) - suggestedPersons.indexOf(b.data.id);
	}
	return 0;
};

export const createStaffingGroupsAndItems = pageComponent => {
	const {data, staffingPlaceholder} = pageComponent.state;
	const groups = [];
	const items = [];

	const placeholderAllocations = data.placeholderAllocationsByPlaceholder[staffingPlaceholder.id];

	// create placeholder allocation items
	if (placeholderAllocations?.length > 0) {
		placeholderAllocations.forEach(placeholderAllocation => {
			const allocationItem = ComposeManager.composePlaceholderAllocation(pageComponent, placeholderAllocation);

			if (allocationItem) {
				items.push(new PlaceholderAllocationItem(pageComponent, allocationItem));
			}
		});
	}

	// create placeholder allocations allocated to people
	const allocatedPlaceholderAllocations = data.placeholderAllocations.filter(
		placeholderAllocation =>
			placeholderAllocation.personId && placeholderAllocation.placeholderId === staffingPlaceholder.id
	);
	if (allocatedPlaceholderAllocations?.length) {
		allocatedPlaceholderAllocations.forEach(placeholderAllocation => {
			const allocationItem = ComposeManager.composePlaceholderAllocation(pageComponent, placeholderAllocation);

			if (allocationItem) {
				items.push(new PlaceholderAllocationItem(pageComponent, allocationItem));
			}
		});
	}

	// create placeholder group
	groups.push(
		new CapacityPlaceholderGroup(
			pageComponent,
			ComposeManager.composeCapacityPlaceholderGroup(pageComponent, staffingPlaceholder)
		)
	);

	// create person groups
	let allocatedPeople = [];
	let matches = [];

	const project = DataManager.getProjectOrProjectGroupById(
		pageComponent,
		staffingPlaceholder.projectId,
		staffingPlaceholder.projectGroupId
	);
	data.persons.forEach(person => {
		// exclude clients
		if (!person.clientId) {
			const allocations = data.personAllocationMap[person.id];

			// creating allocation items
			if (allocations) {
				allocations.forEach(allocation => {
					const projectAllocationItemData = ComposeManager.composeProjectAllocation(pageComponent, allocation);

					if (projectAllocationItemData) {
						items.push(new ProjectAllocationItem(pageComponent, projectAllocationItemData));
					}
				});
			}

			// get projects and non-project items related to person
			const nonProjectTimeItemExists = items.find(
				item => item.groupId === IDManager.getNonProjectTimeGroupId(pageComponent, person.id)
			);

			// create project groups
			const projectGroups = createPersonProjectGroups(pageComponent, person);

			// create non project time group (internal / timeoff allocations)
			if (nonProjectTimeItemExists) {
				projectGroups.push(
					new NonProjectTimeGroup(pageComponent, ComposeManager.composeNonProjectTimeGroup(pageComponent, person))
				);
			}

			// create non project group
			projectGroups.push(
				new NoContentGroup(pageComponent, ComposeManager.composeNoContentGroup(pageComponent, person, null, null))
			);

			const personGroup = new PersonGroup(
				pageComponent,
				ComposeManager.composePersonGroup(pageComponent, person, project, projectGroups)
			);

			const isAllocatedByPlaceholderAllocation = allocatedPlaceholderAllocations.find(
				placeholderAllocation => placeholderAllocation.personId === person.id
			);

			// create person group
			if (isAllocatedByPlaceholderAllocation) {
				allocatedPeople.push(personGroup);
			} else {
				matches.push(personGroup);
			}
		}
	});

	matches.sort(staffingGroupSortingFunc(pageComponent));

	// create allocated people grouping group
	groups.push(
		new StaffingGroupingGroup(
			pageComponent,
			ComposeManager.composeStaffingGroupingGroup(
				pageComponent,
				STAFFING_GROUPING_TYPE.ALLOCATED_PEOPLE,
				allocatedPeople
			),
			staffingGroupSortingFunc
		)
	);

	// create filtered matches grouping group
	groups.push(
		new StaffingGroupingGroup(
			pageComponent,
			ComposeManager.composeStaffingGroupingGroup(pageComponent, STAFFING_GROUPING_TYPE.FILTERED_MATCHES, matches),
			staffingGroupSortingFunc
		)
	);

	return {
		groups,
		items,
	};
};

export const getStaffingFilters = (pageComponent, placeholder) => {
	const {data} = pageComponent.state;
	const filters = {
		[FILTER_SECTIONS.PEOPLE]: {},
	};

	if (placeholder.roleId) {
		filters[FILTER_SECTIONS.PEOPLE][FILTER_TYPE.ROLE] = [placeholder.roleId];
	}

	if (placeholder.departmentId) {
		filters[FILTER_SECTIONS.PEOPLE][FILTER_TYPE.DEPARTMENT] = [placeholder.departmentId];
	}

	const placeholderTeams = data.placeholderTeamsByPlaceholder[placeholder.id] || [];
	if (placeholderTeams?.length > 0) {
		filters[FILTER_SECTIONS.PEOPLE][FILTER_TYPE.TEAM] = placeholderTeams.map(placeholderTeam => placeholderTeam.teamId);
	}

	const placeholderSkills = data.placeholderSkillsByPlaceholder[placeholder.id] || [];
	if (placeholderSkills?.length > 0) {
		filters[FILTER_SECTIONS.PEOPLE][FILTER_TYPE.SKILL] = placeholderSkills.map(placeholderSkill => ({
			skillId: placeholderSkill.skillId,
			skillLevelId: placeholderSkill.skillLevelId,
		}));

		filters[FILTER_SECTIONS.PEOPLE][getEntityOperatorKey(FILTER_TYPE.SKILL)] = {
			[FILTER_OPERATOR.REQUIRE_ALL]: true,
			[FILTER_OPERATOR.EXCLUDE]: false,
		};
	}

	return filters;
};

const recoverStaffingItems = (pageComponent, items) => {
	const recoveredItems = [];
	const deprecatedAllocationIdsTo = [];

	pageComponent.state[STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_GHOSTS].forEach(allocationItem =>
		recoveredItems.push(allocationItem)
	);

	pageComponent.state[STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE].forEach(allocation => {
		const allocationItemData = ComposeManager.composeProjectAllocation(pageComponent, allocation);

		if (allocationItemData) {
			recoveredItems.push(new ProjectAllocationItem(pageComponent, allocationItemData));

			if (!isStaffingGeneratedId(allocation.id)) {
				deprecatedAllocationIdsTo.push(allocation.id);
			}
		}
	});

	pageComponent.state[STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_DELETE].forEach(allocation =>
		deprecatedAllocationIdsTo.push(allocation.id)
	);

	_.remove(items, item => isProjectAllocationItem(item) && deprecatedAllocationIdsTo.includes(item.data.allocation.id));
	recoveredItems.forEach(item => items.push(item));
};

export const initializeStaffingData = (pageComponent, data, isResetState) => {
	if (!data) return;

	if (!pageComponent.state.staffingPlaceholder) {
		pageComponent.exitStaffingMode();
	}

	const fullData = {
		...pageComponent.state.data,
		...data,
	};

	if (!isResetState) {
		// convert person start and end dates to canvas timeline dates
		// OBS: required for heatmap calculations
		fullData.persons.forEach(person => {
			person.startDate = person.startDate
				? getCanvasTimelineDateFromMoment(Moment.utc(person.startDate, 'YYYY-MM-DD'))
				: null;
			person.endDate = person.endDate ? getCanvasTimelineDateFromMoment(Moment.utc(person.endDate, 'YYYY-MM-DD')) : null;
		});
	}

	// lookup maps
	DataManager.setLookupMaps(pageComponent, fullData);

	pageComponent.setState(
		{
			data: fullData,
		},
		() => {
			if (!hasFeatureFlag('inverted_pto_non_working_days')) {
				DataManager.constructNonWorkingDaysMap(pageComponent);
			}

			generateNoAccessHeatmap(pageComponent);

			const stateData = {
				staffingDataLoaded: true,
				loadingStaffingData: false,
				allDataLoading: false,
			};

			if (!isResetState) {
				stateData.allDataLoaded = pageComponent.state.allDataLoading && !!pageComponent.state.staffingDataLoaded;
			}

			pageComponent.setState(stateData);

			const {groups, items} = createStaffingGroupsAndItems(pageComponent);

			if (!isResetState) {
				recoverStaffingItems(pageComponent, items);
			}

			setInitialExpansion(pageComponent, groups);

			const filterFunctions = getFilterFunctions(pageComponent.state.filters);

			pageComponent.setState({groups, items, filterFunctions}, () => {
				updateAllocationItemsVisibility(pageComponent);

				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					DataManager.constructVisibleItemTrees(pageComponent);
				}

				pageComponent.redrawCanvasTimeline({preventFiltering: false, isInitialLoad: false});
			});
		}
	);
};
