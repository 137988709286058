import React, {useEffect, useMemo, useState} from 'react';
import {injectIntl} from 'react-intl';
import _ from 'lodash';

import Util from '../../../forecast-app/shared/util/util';
import Style from '../../../styles/v2/canvas_create_modal_styled';
import Button from '../../../forecast-app/shared/components/buttons/button/button';
import Input from '../../../components/new-ui/input_field';
import {BUTTON_COLOR, BUTTON_STYLE, MODULE_TYPES} from '../../../constants';
import GenericModal from '../../../containers/modal/generic_modal';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import DateRangePicker from '../../../components/new-ui/date-controls/date_range_picker';
import RichTextField from '../../../components/new-ui/rich_text_field';
import AssignedDropdown from '../../../forecast-app/shared/components/dropdowns/assigned-dropdown/assigned_dropdown';
import RadioButton from '../../new-ui/radio_button';

import {createAllocation, createTask, deleteAllocation, updateAllocation} from '../../scheduling/scheduling_mutations';

import {getAllocationTotal} from '../../scheduling/project_allocation_logic';
import ExpandIcon from '../../../images/v2/components/dropdown/expand_icon';
import moment from 'moment';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {AffixedInputWrapper} from '../../../forecast-app/shared/components/inputs/AffixedInputWrapper';
import {canApproveAllocation, hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import AllocationProjectDropdown from '../components/allocation_project_dropdown';
import {generateStaffingId} from '../placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import CheckboxRow from '../components/CheckboxRow';
import {isDateDisabled} from '../../../forecast-app/shared/util/DateUtil';
import AllocationTypeSelector from '../components/AllocationTypeSelector';
import {allowAllocationEdit, getVisualizationMode, VISUALIZATION_MODE} from '../canvas-timeline/canvas_timeline_util';

const canvasCreateModal = props => {
	const onSuccess = response => {
		Util.dispatchScheduleEvent(response);
	};

	const getPersonsAvailability = (persons, startDate, endDate) => {
		const {holidayCalendars, holidayCalendarEntries, company} = props;

		let unavailablePersons = [];
		for (const person of persons) {
			const holidayCalendar = person.holidayCalendarId
				? holidayCalendars.find(holidayCalendar => person.holidayCalendarId === holidayCalendar.id)
				: company.defaultHolidayCalendarId
				? holidayCalendars.find(holidayCalendar => company.defaultHolidayCalendarId === holidayCalendar.id)
				: holidayCalendars.length === 1
				? holidayCalendars[0]
				: null;

			let entries = [];
			if (holidayCalendar) {
				entries = holidayCalendarEntries
					.filter(holidayCalendarEntry => holidayCalendarEntry.holidayCalendarId === holidayCalendar.id)
					.map(elem => ({node: elem}));
			}
			if (!Util.getMinutesInPeriod(person, startDate, endDate, entries)) {
				unavailablePersons.push(person);
			}
		}
		return unavailablePersons;
	};

	const getAssignablePersons = () => {
		const {persons, roles} = props;

		let personDropdownOptions = [];

		for (const person of persons) {
			if (!person.active || person.clientId !== undefined) continue;
			const role = roles.find(role => person.roleId === role.id);
			personDropdownOptions.push({
				node: {
					person,
					role,
				},
			});
		}
		return personDropdownOptions;
	};

	// INITIAL CALCULATIONS
	const {
		selectedPersonId,
		selectedProject,
		projectGroups,
		persons,
		allocation,
		idleTimes,
		intl,
		actualPersonId,
		isCreatingInNonProjectTime,
		preselectTaskTab,
		schedulingOptions,
		company,
		staffingModeActive,
		canOnlyModifyOwnTimeOff,
	} = props;

	const {formatMessage, formatDate} = intl;
	const {
		registerTime,
		startYear,
		startMonth,
		startDay,
		endYear,
		endMonth,
		endDay,
		monday,
		tuesday,
		wednesday,
		thursday,
		friday,
		saturday,
		sunday,
	} = allocation;

	// if allocation.id is defined that means that the user is editing an existing allocation, if undefined, the user is creating an allocation
	const isUserEditingAllocation = !!allocation.id;

	const startDate = Util.CreateNonUtcMomentDate(startYear, startMonth, startDay);
	const endDate = Util.CreateNonUtcMomentDate(endYear, endMonth, endDay);

	const initialDistributionArray = [monday, tuesday, wednesday, thursday, friday, saturday, sunday];
	const weeklyAllocationMinutes = initialDistributionArray.reduce((total, value) => total + value, 0);
	const selectedPerson = persons.find(person => person.id === selectedPersonId);
	const weeklyPersonMinutes =
		selectedPerson.monday +
		selectedPerson.tuesday +
		selectedPerson.wednesday +
		selectedPerson.thursday +
		selectedPerson.friday +
		selectedPerson.saturday +
		selectedPerson.sunday;
	const initialDistributionPercentage = (weeklyAllocationMinutes / weeklyPersonMinutes) * 100;

	const isInitialPersonAvalability = getPersonsAvailability([selectedPerson], startDate, endDate);

	const assignablePersons = getAssignablePersons();

	const preSelectedPerson = [
		assignablePersons.find(projectPerson => {
			return projectPerson.node.person.id === props.selectedPersonId;
		}).node.person,
	];

	let preSelectedProject =
		selectedProject && selectedProject.isInProjectGroup
			? projectGroups.find(group => group.id === selectedProject.projectGroupId)
			: selectedProject;

	const tabs = {
		ALLOCATION: 'ALLOCATION',
		TASK: 'TASK',
		INTERNAL_TIME: 'INTERNAL_TIME',
		TIME_OFF: 'TIME_OFF',
	};

	const availableTabs = {...tabs};

	const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);
	const isInCombinedMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
	if (!isInCombinedMode) {
		if (isUsingProjectAllocation) {
			delete availableTabs.TASK;
		} else {
			delete availableTabs.ALLOCATION;
		}
	}

	if (!isUserEditingAllocation && !hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE)) {
		delete availableTabs.ALLOCATION;
		delete availableTabs.INTERNAL_TIME;
		if (!canOnlyModifyOwnTimeOff) {
			delete availableTabs.TIME_OFF;
		}
	}

	if (canOnlyModifyOwnTimeOff) {
		delete availableTabs.TASK;
	}

	// Fix to mitigate possible edge case found under task T48693.
	if (_.isEmpty(availableTabs)) {
		props.closeModal();
		return null;
	}

	// preselect tab based on props
	let preselectedTab = isCreatingInNonProjectTime
		? tabs.INTERNAL_TIME
		: (isUsingProjectAllocation || isInCombinedMode) && !preselectTaskTab
		? tabs.ALLOCATION
		: tabs.TASK;

	let isEditedAllocationIdleTimeAndDisabled = false;
	if (isUserEditingAllocation) {
		if (allocation.idleTimeId) {
			const idleTime = idleTimes.find(idleTime => idleTime.id === allocation.idleTimeId);
			isEditedAllocationIdleTimeAndDisabled = idleTime.disabled;
			if (idleTime.isInternalTime) {
				preselectedTab = tabs.INTERNAL_TIME;
			} else {
				preselectedTab = tabs.TIME_OFF;
			}
		}
		if (allocation.projectId || allocation.projectGroupId) {
			preselectedTab = tabs.ALLOCATION;
		}
	}

	// STATE
	const [selectedTab, setSelectedTab] = useState(
		availableTabs[preselectedTab] ? preselectedTab : Object.values(availableTabs)[0]
	);
	const [selectedParentIds, setSelectedParentIds] = useState({
		ALLOCATION:
			preselectedTab === tabs.ALLOCATION
				? allocation.projectId
					? allocation.projectId
					: allocation.projectGroupId
					? allocation.projectGroupId
					: preSelectedProject
					? preSelectedProject.id
					: null
				: null,
		TASK: preselectedTab === tabs.TASK ? (preSelectedProject ? preSelectedProject.id : null) : null,
		INTERNAL_TIME: preselectedTab === tabs.INTERNAL_TIME ? allocation.idleTimeId : null,
		TIME_OFF: preselectedTab === tabs.TIME_OFF ? allocation.idleTimeId : null,
	});
	const [selectedPersons, setSelectedPersons] = useState({
		ALLOCATION: preSelectedPerson,
		TASK: preSelectedPerson,
		INTERNAL_TIME: preSelectedPerson,
		TIME_OFF: preSelectedPerson,
	});
	const [selectedPhaseId, setSelectedPhaseId] = useState(null);
	const [isSoft, setAllocationSoftness] = useState(
		hasFeatureFlag('placeholders') && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
			? isUserEditingAllocation
				? allocation.isSoft
				: !canApproveAllocation()
			: false
	);

	const phaseOptions = useMemo(() => {
		// phases dropdown
		const options = [];
		if (selectedTab === tabs.TASK) {
			const selectedProjectId = selectedParentIds[selectedTab];
			const {phases} = props;

			options.push({value: null, label: props.intl.formatMessage({id: 'project_scopes.no-scope'})});

			for (const phase of phases) {
				if (phase.projectId === selectedProjectId) {
					options.push({value: phase.id, label: phase.name});
				}
			}
		}
		return options;
	}, [selectedTab, selectedParentIds, props.phases]);
	const [dates, setDates] = useState({startDate, endDate});
	const [total, setTotal] = useState(getAllocationTotal(allocation)); // Allocation per person field
	const [distributionArray, setDistributionArray] = useState(initialDistributionArray);
	const [distributionPercentage, setDistributionPercentage] = useState(initialDistributionPercentage);
	const [personsAvailability, setPersonsAvailability] = useState({
		ALLOCATION: isInitialPersonAvalability.length ? preSelectedPerson : [],
		TASK: isInitialPersonAvalability.length ? preSelectedPerson : [],
		INTERNAL_TIME: isInitialPersonAvalability.length ? preSelectedPerson : [],
		TIME_OFF: isInitialPersonAvalability.length ? preSelectedPerson : [],
	});
	const [isDistributionExpanded, setIsDistributionExpanded] = useState(false);
	const [shouldCreateTimeRegs, setShouldCreateTimeRegs] = useState(
		hasFeatureFlag('pto_timesheet_allocation_linking') || registerTime || false
	);
	const [descriptionText, setDescriptionText] = useState({
		ALLOCATION: isUserEditingAllocation ? allocation.description : '',
		INTERNAL_TIME: isUserEditingAllocation ? allocation.description : '',
		TIME_OFF: isUserEditingAllocation ? allocation.description : '',
	});
	const [taskName, setTaskName] = useState('');
	const [estimateForecast, setEstimateForecast] = useState(0);
	const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);

	const project = props.projects && props.projects.find(project => project.id === selectedParentIds[tabs.TASK]);
	const estimationUnit = project && project.estimationUnit;

	const checkNonTaskInputFilled = tab => {
		return selectedParentIds[tab] && selectedPersons[tab] && dates.startDate && dates.endDate;
	};

	const checkTaskInputFileds = tab => {
		return selectedParentIds[tab] && dates.startDate && dates.endDate && taskName.length;
	};

	// if the user is a collaborator and the tab is Allocation/TimeOff/InternalTime he should not be able to edit
	const isOwnAllocation = selectedPersons[selectedTab].every(person => person.id === actualPersonId);
	const allowTimeReg =
		isOwnAllocation ||
		hasPermission(PERMISSION_TYPE.TIME_REGISTRATION_CREATE_ALL) ||
		hasFeatureFlag('pto_timesheet_allocation_linking');
	const cannotCreateTimeReg = !allowTimeReg && shouldCreateTimeRegs;
	const cannotEditTimeReg = !allowTimeReg && allocation.registerTime && allocation.personId !== actualPersonId;
	const allUsersModifyTimeOff = company.allUsersModifyTimeOff;
	const cannotEditHardAllocation = !allowAllocationEdit(allocation);
	let cannotEditAllocation =
		isUserEditingAllocation &&
		(!hasPermission(PERMISSION_TYPE.ALLOCATION_UPDATE) || cannotEditTimeReg || cannotEditHardAllocation) &&
		(preselectedTab === tabs.ALLOCATION ||
			preselectedTab === tabs.INTERNAL_TIME ||
			preselectedTab === tabs.TIME_OFF ||
			!isOwnAllocation);

	// BambooHR handling. If allocation from bamboo, it cannot be edited or deleted
	if (isUserEditingAllocation && allocation.bambooHRLinked) {
		// Find out if allocation is linked to bamboo... Somehow...
		cannotEditAllocation = true;
	}

	const cannotEditAssignee = cannotEditAllocation || isEditedAllocationIdleTimeAndDisabled;

	if (
		selectedTab === tabs.TIME_OFF &&
		cannotEditAllocation &&
		hasFeatureFlag('pto_all_users_can_modify_time_off') &&
		isOwnAllocation
	) {
		cannotEditAllocation = !allUsersModifyTimeOff;
	}

	useEffect(() => {
		// check if create button should be disabled
		switch (selectedTab) {
			case tabs.ALLOCATION: {
				const isFormFilled = checkNonTaskInputFilled(tabs.ALLOCATION);
				setIsCreateButtonDisabled(!isFormFilled);
				return;
			}
			case tabs.INTERNAL_TIME: {
				const isFormFilled = checkNonTaskInputFilled(tabs.INTERNAL_TIME);
				setIsCreateButtonDisabled(!isFormFilled);
				return;
			}
			case tabs.TIME_OFF: {
				const isFormFilled = checkNonTaskInputFilled(tabs.TIME_OFF);
				setIsCreateButtonDisabled(!isFormFilled);
				return;
			}
			case tabs.TASK: {
				const isFormFilled = checkTaskInputFileds(tabs.TASK);
				setIsCreateButtonDisabled(!isFormFilled);
				return;
			}
			default:
				return;
		}
	}, [checkNonTaskInputFilled, checkTaskInputFileds, selectedTab, selectedParentIds, selectedPersons, dates, total]);

	const handleTabChange = tab => {
		setSelectedTab(tab);
	};

	const getParentDropdownOptions = () => {
		const {idleTimes} = props;

		let options = [];

		if (selectedTab === tabs.TIME_OFF) {
			options = options.concat(
				idleTimes
					// if the allocation that is being edited is disabled add it to the list in order to show it in the dropdown placeholder, if not editing, do not add it to the list
					.filter(
						idleTime => isEditedAllocationIdleTimeAndDisabled || (!idleTime.disabled && !idleTime.isInternalTime)
					)
					.map(idleTime => ({
						label: idleTime.name,
						value: idleTime.id,
						idleTime: true,
					}))
			);
		} else if (selectedTab === tabs.INTERNAL_TIME) {
			options = options.concat(
				idleTimes
					// if the allocation that is being edited is disabled add it to the list in order to show it in the dropdown placeholder, if not editing, do not add it to the list
					.filter(
						idleTime => isEditedAllocationIdleTimeAndDisabled || (!idleTime.disabled && idleTime.isInternalTime)
					)
					.map(idleTime => ({
						label: idleTime.name,
						value: idleTime.id,
						idleTime: true,
					}))
			);
		}
		return options;
	};

	const handleParentDropdownChange = option => {
		// this.setState({selectedParentId: option.value, isIdleTimeSelected: option.idleTime, registerTime: option.idleTime && (!this.state.isIdleTimeSelected || this.state.registerTime)});
		const updatedselectedParentIds = {...selectedParentIds};
		updatedselectedParentIds[selectedTab] = option.value;
		setSelectedParentIds(updatedselectedParentIds);

		if (
			hasFeatureFlag('placeholders') &&
			hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) &&
			selectedTab === tabs.ALLOCATION &&
			!isUserEditingAllocation
		) {
			setAllocationSoftness(!canApproveAllocation());
		}
		setSelectedPhaseId(null);
	};

	const parentLabel =
		selectedTab === tabs.TIME_OFF
			? formatMessage({id: 'common.time_off'})
			: selectedTab === tabs.INTERNAL_TIME
			? formatMessage({id: 'common.internal_time'})
			: formatMessage({id: 'common.project'});

	const handlePhaseDropdownChange = option => {
		setSelectedPhaseId(option.value);
	};

	const getTotal = (distributionArray, startDate, endDate) => {
		return getAllocationTotal(
			{
				monday: distributionArray[0],
				tuesday: distributionArray[1],
				wednesday: distributionArray[2],
				thursday: distributionArray[3],
				friday: distributionArray[4],
				saturday: distributionArray[5],
				sunday: distributionArray[6],
			},
			null,
			startDate,
			endDate
		);
	};

	const handleDateRangeChange = (startDate, endDate) => {
		// if there is one person selected use the distribution array to calculate the total
		setTotal(getTotal(distributionArray, startDate, endDate));
		setDates({startDate, endDate});
	};

	const getDistributionPercentage = (distributionArray, person) => {
		const weeklyAllocationMinutes = distributionArray.reduce((total, value) => total + value, 0);
		const weeklyPersonMinutes =
			person.monday +
			person.tuesday +
			person.wednesday +
			person.thursday +
			person.friday +
			person.saturday +
			person.sunday;
		return (weeklyAllocationMinutes / weeklyPersonMinutes) * 100;
	};

	const handleAllocationsTotalChange = value => {
		let total = (value / selectedPersons[selectedTab].length) * 60;

		setTotal(total);
	};

	const handleAllocationChange = value => {
		const {persons} = props;
		const {startDate, endDate} = dates;
		const newTotal = value * 60;

		// update the distribution only when the value changes because the distribution could change just by focusing and bluring the total field even if the value did not change
		if (newTotal !== total) {
			// if there is only one person selected
			const firstSelectedPersonId = selectedPersons[selectedTab][0].id;
			const person = persons.find(person => person.id === firstSelectedPersonId);
			let personWorkingMinutes = [
				allocation.monday,
				allocation.tuesday,
				allocation.wednesday,
				allocation.thursday,
				allocation.friday,
				allocation.saturday,
				allocation.sunday,
			];
			// If allocation had 0 hours distribute time over persons working days
			if (personWorkingMinutes.every(day => day === 0)) {
				personWorkingMinutes = [
					person.monday,
					person.tuesday,
					person.wednesday,
					person.thursday,
					person.friday,
					person.saturday,
					person.sunday,
				];
			}
			const allocationWeekdayCountArray = [1, 2, 3, 4, 5, 6, 7].map(e => Util.weekdaysBetween(startDate, endDate, e));
			const allocationWorkingDayCount = allocationWeekdayCountArray.reduce((newTotal, weekdayCount, index) => {
				return newTotal + (personWorkingMinutes[index] ? weekdayCount : 0);
			}, 0);
			const minutesPerDay = Math.round(newTotal / allocationWorkingDayCount);
			const distributionArray = [];
			for (let i = 0; i < 7; i++) {
				distributionArray.push(personWorkingMinutes[i] ? minutesPerDay : 0);
			}
			const distributionPercentage = getDistributionPercentage(distributionArray, person);

			setDistributionArray(distributionArray);
			setDistributionPercentage(distributionPercentage);
		}
		setTotal(newTotal);
	};

	const getPercentage = (distributionArray, personId) => {
		const person = persons.find(person => person.id === personId);
		const weeklyAllocationMinutes = distributionArray.reduce((total, value) => total + value, 0);
		const weeklyPersonMinutes =
			person.monday +
			person.tuesday +
			person.wednesday +
			person.thursday +
			person.friday +
			person.saturday +
			person.sunday;
		return (weeklyAllocationMinutes / weeklyPersonMinutes) * 100;
	};

	const handleDistributionChange = (index, adjustedDistributionArray, value) => {
		const distributionArrayUpdated = adjustedDistributionArray || distributionArray;
		const {startDate, endDate} = dates;

		if (index !== null && index !== undefined) {
			distributionArrayUpdated[index] = Math.round(value * 60);
		}

		let percentage = getPercentage(distributionArrayUpdated, selectedPersonId);
		if (adjustedDistributionArray) {
			percentage = Math.round(percentage * 100) / 100;
		}

		const total = getTotal(distributionArrayUpdated, startDate, endDate);

		setTotal(total);
		setDistributionArray(distributionArrayUpdated);
		setDistributionPercentage(percentage);
	};

	const getPersonAverageWorkingMinutes = personId => {
		const {persons} = props;

		const person = persons.find(person => person.id === personId);
		const personWorkingMinutes = [
			person.monday,
			person.tuesday,
			person.wednesday,
			person.thursday,
			person.friday,
			person.saturday,
			person.sunday,
		];
		const totalWorkingDays = personWorkingMinutes.reduce((total, minutes) => total + (minutes ? 1 : 0), 0);
		return (
			(person.monday +
				person.tuesday +
				person.wednesday +
				person.thursday +
				person.friday +
				person.saturday +
				person.sunday) /
			totalWorkingDays
		);
	};

	const handlePercentageChange = percentage => {
		const {startDate, endDate} = dates;
		const person = persons.find(person => person.id === selectedPersons[selectedTab][0].id);
		const personWorkingMinutes = [
			person.monday,
			person.tuesday,
			person.wednesday,
			person.thursday,
			person.friday,
			person.saturday,
			person.sunday,
		];
		const distributionArray = personWorkingMinutes.map(minutes => {
			return Math.floor((minutes * percentage) / 100);
		});
		const total = getTotal(distributionArray, startDate, endDate);

		setTotal(total);
		setDistributionArray(distributionArray);
		setDistributionPercentage(percentage);
	};

	const getProjectPersons = projectId => {
		const {projectPersons, persons, roles} = props;
		const personsOnProject = projectPersons.filter(projectPerson => projectPerson.projectId === projectId);

		let projectPersonList = [];
		for (const projectPerson of personsOnProject) {
			const person = persons.find(person => person.id === projectPerson.personId);
			if (!person.active || person.clientId !== undefined) continue;
			const role = roles.find(role => role.id === projectPerson.roleId);
			projectPersonList.push({node: {person, role}});
		}
		return projectPersonList;
	};

	const assignPerson = personIds => {
		const assignablePersons = getAssignablePersons();

		const persons = personIds.map(
			personId =>
				assignablePersons.find(projectPerson => {
					return projectPerson.node.person.id === personId;
				}).node.person
		);

		let updatedSelectedPersons = {...selectedPersons};
		updatedSelectedPersons[selectedTab] = persons;

		const updatedPersonsAvailability = {...personsAvailability};
		updatedPersonsAvailability[selectedTab] = getPersonsAvailability(persons, startDate, endDate);

		setPersonsAvailability(updatedPersonsAvailability);
		setSelectedPersons(updatedSelectedPersons);

		if (personIds.length === 1) {
			// if there is one person assigned calculate the total using the custom distribution
			setTotal(getTotal(distributionArray, startDate, endDate));
		}
	};

	const unassignPerson = personId => {
		const persons = selectedPersons[selectedTab].filter(person => person.id !== personId);

		let updatedSelectedPersons = {...selectedPersons};
		updatedSelectedPersons[selectedTab] = persons;

		if (persons.length === 1) {
			// if there is one person selected use the distribution array to calculate the total
			setTotal(getTotal(distributionArray, startDate, endDate));
		}

		setSelectedPersons(updatedSelectedPersons);
	};

	const assignTeam = teamId => {
		const {teamPersons} = props;

		const assignablePersons = getAssignablePersons();
		const personsInTeam = teamPersons
			.filter(teamPerson => teamPerson.teamId === teamId)
			.map(teamPerson => teamPerson.personId);
		const personsToBeAdded = assignablePersons
			.filter(person => personsInTeam.includes(person.node.person.id))
			.map(person => person.node.person);
		// merge arrays but avoid duplicates, in case a person from the team is already assigned
		const persons = _.unionWith(selectedPersons[selectedTab], personsToBeAdded, _.isEqual);

		let updatedSelectedPersons = {...selectedPersons};
		updatedSelectedPersons[selectedTab] = persons;

		setSelectedPersons(updatedSelectedPersons);
	};

	const updateDescription = description => {
		let updatedDescription = {...descriptionText};
		updatedDescription[selectedTab] = description;
		setDescriptionText(updatedDescription);
	};

	//used for weekday names in distribution row
	const loopDate = moment().startOf('week').subtract(1, 'day');
	const onSaveClick = () => {
		const decodedParentId = atob(selectedParentIds[selectedTab]);

		if (selectedTab === tabs.TASK) {
			const {estimationUnit} = props.projects.find(project => project.id === selectedParentIds[tabs.TASK]);

			const mutationData = {
				projectId: selectedParentIds[tabs.TASK],
				name: taskName.trim(),
				deadlineDay: dates.endDate.date(),
				deadlineMonth: dates.endDate.month() + 1,
				deadlineYear: dates.endDate.year(),
				startDay: dates.startDate.date(),
				startMonth: dates.startDate.month() + 1,
				startYear: dates.startDate.year(),
				csrfToken: Util.getCsrfValue(),
				socketClientId: Util.getClientId(),
				phaseId: selectedPhaseId,
				assignedPersons: selectedPersons[tabs.TASK].map(person => person.id),
			};

			const estimate =
				estimationUnit === 'HOURS'
					? Util.getHourEstimate(estimateForecast * 60)
					: Math.round(parseFloat(estimateForecast * 100)) / 100;
			mutationData.forecast = estimate;

			createTask(mutationData, onSuccess);
		} else {
			// create allocation for each person. could be replaced with a mutation that accepts multiple persons ids. do that if you have time

			const preSelectedPersonId = preSelectedPerson[0].id;

			for (const person of selectedPersons[selectedTab]) {
				let mutationData = {
					personId: person.id,
					startYear: dates.startDate.year(),
					startMonth: dates.startDate.month() + 1,
					startDay: dates.startDate.date(),
					endYear: dates.endDate.year(),
					endMonth: dates.endDate.month() + 1,
					endDay: dates.endDate.date(),
					idleTimeId: decodedParentId.startsWith('Idle') ? selectedParentIds[selectedTab] : null,
					projectId: decodedParentId.startsWith('ProjectType') ? selectedParentIds[selectedTab] : null,
					projectGroupId: decodedParentId.startsWith('ProjectGroup') ? selectedParentIds[selectedTab] : null,
					// if only one person is selected then use their distribution.
					// If more people are selected use their distribution. If the total is greater than the available allocate them 100%, else use total
					monday: distributionArray[0],
					tuesday: distributionArray[1],
					wednesday: distributionArray[2],
					thursday: distributionArray[3],
					friday: distributionArray[4],
					saturday: distributionArray[5],
					sunday: distributionArray[6],
					description: descriptionText[selectedTab],
					registerTime: selectedTab === tabs.TIME_OFF && shouldCreateTimeRegs,
				};

				if (selectedTab === tabs.ALLOCATION) {
					mutationData.isSoft = isSoft;
				}

				// if the person id is the same as the preselected person, edit the allocation, otherwise it means that the user added a person to the allocation and we need to create a new one
				if (isUserEditingAllocation && person.id === preSelectedPersonId) {
					mutationData.id = allocation.id;
					if (staffingModeActive) {
						onSuccess({updateAllocation: {allocation: mutationData}});
					} else {
						updateAllocation(mutationData, onSuccess);
					}
				} else {
					if (staffingModeActive) {
						const response = {createAllocation: {allocation: mutationData}};
						response.createAllocation.allocation.id = generateStaffingId();
						onSuccess(response);
					} else {
						createAllocation(mutationData, onSuccess);
					}
				}
			}
			if (allocation.id && !selectedPersons[selectedTab].some(person => person.id === preSelectedPersonId)) {
				deleteAllocation(
					{
						id: allocation.id,
					},
					onSuccess
				);
			}
		}
	};

	const onDeleteClick = () => {
		if (staffingModeActive) {
			onSuccess({deleteAllocation: allocation});
		} else {
			deleteAllocation(
				{
					id: allocation.id,
				},
				onSuccess
			);
		}
		props.closeModal();
	};

	const getTotalAllocation = peopleWithLessWorkingHours => {
		const {startDate, endDate} = dates;

		const selectedWeekDays = Util.getMomentDaysBetweenDates(startDate, endDate);

		const totalAllocationMinutes = selectedPersons[selectedTab].reduce((acc, person) => {
			const isCurrentPersonUnderAllocated = peopleWithLessWorkingHours.find(p => p.id === person.id);

			const personTotalInPeriod = isCurrentPersonUnderAllocated
				? // if the person has more ours available use total, else use all the hours he/she has available in that day
				  total
				: selectedWeekDays.reduce((ac, day) => {
						return ac + person[day.format('dddd').toLowerCase()];
				  }, 0);

			return acc + personTotalInPeriod;
		}, 0);

		return totalAllocationMinutes / 60;
	};

	// people that have less working hours per day than the value in the allocation per day input
	const peopleWithLessWorkingHours = selectedPersons[selectedTab].filter(person => {
		const {startDate, endDate} = dates;

		// get the day names of the first 7 days because the extra days would be redundant
		const weekDaysInPeriod = Util.getMomentDaysBetweenDates(startDate, endDate)
			.slice(0, 7)
			.map(date => date.format('dddd').toLowerCase());
		const numberOfSelectedDays = weekDaysInPeriod.length;
		return !!weekDaysInPeriod.find(day => person[day] > total / numberOfSelectedDays);
	});

	const content = (
		<Style>
			{!isUserEditingAllocation && (
				<div className="tabs">
					{Object.values(availableTabs).map(tab => (
						<Button
							key={tab}
							text={formatMessage({id: `common.${tab.toLocaleLowerCase()}`})}
							buttonStyle={BUTTON_STYLE.FILLED}
							colorTheme={selectedTab === tab ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.FADEDGREY}
							isDisabled={
								tab === tabs.TIME_OFF &&
								company.bambooHREnabled &&
								!(
									hasFeatureFlag('pto_all_users_can_modify_time_off') &&
									isOwnAllocation &&
									allUsersModifyTimeOff
								)
							}
							onClick={() => handleTabChange(tab)}
						/>
					))}
				</div>
			)}

			<div className="parent-dropdown-container">
				{[tabs.ALLOCATION, tabs.TASK].includes(selectedTab) ? (
					<AllocationProjectDropdown
						label={parentLabel}
						projects={props.projects}
						projectGroups={projectGroups}
						projectPersons={props.projectPersons}
						selectedValue={selectedParentIds[selectedTab]}
						onChange={selectedId => handleParentDropdownChange({value: selectedId})}
						actualPersonId={actualPersonId}
						includeChildProjects={selectedTab === tabs.TASK}
						includeConnectedProjects={selectedTab === tabs.ALLOCATION}
						disabled={cannotEditAllocation || isEditedAllocationIdleTimeAndDisabled}
						includeJiraProjects
						sortAlphabetically
					/>
				) : (
					<Dropdown
						onChange={handleParentDropdownChange.bind(this)}
						options={getParentDropdownOptions()}
						value={selectedParentIds[selectedTab]}
						label={parentLabel}
						placeholder={formatMessage({id: 'common.select'})}
						disabled={cannotEditAllocation || isEditedAllocationIdleTimeAndDisabled}
					/>
				)}
			</div>

			<div className="person-date-row">
				{selectedTab === tabs.TASK ? (
					<Dropdown
						onChange={handlePhaseDropdownChange.bind(this)}
						options={phaseOptions}
						value={selectedPhaseId}
						label={formatMessage({id: 'common.scope-group'})}
						isPersonSelector={false}
						disabled={cannotEditAllocation || isEditedAllocationIdleTimeAndDisabled}
					/>
				) : (
					<AssignedDropdown
						assignablePersons={getAssignablePersons()}
						assignableTeams={props.teams}
						assignedPersons={selectedPersons[selectedTab]}
						assignPerson={assignPerson.bind(this)}
						unassignPerson={unassignPerson.bind(this)}
						assignTeam={assignTeam.bind(this)}
						isMultiSelect={true}
						label={formatMessage({id: 'common.assignees'})}
						projectId={selectedParentIds[selectedTab]}
						showSuggestions={false}
						maxPeopleIconsShown={10}
						disabled={cannotEditAssignee}
					/>
				)}

				<DateRangePicker
					startDate={dates.startDate}
					endDate={dates.endDate}
					handleDateRangeChange={handleDateRangeChange.bind(this)}
					calendarOffsetX={-374}
					label={formatMessage({id: 'common.date'})}
					locked={cannotEditAllocation || isEditedAllocationIdleTimeAndDisabled}
					disabledDates={isDateDisabled}
				/>
			</div>
			<div className="task-name-duration-container">
				{selectedTab === tabs.TASK ? (
					<div className="task-name-row">
						<Input
							placeholder={formatMessage({id: 'common.task_name'})}
							type="text"
							label={formatMessage({id: 'common.task'})}
							value={taskName}
							onChange={taskName => setTaskName(taskName)}
							disabled={cannotEditAllocation || isEditedAllocationIdleTimeAndDisabled}
							maxLength={191}
							// autoFocus={true}
							// onEnter={this.onNameFieldEnter.bind(this)}
							// required={true}
						/>
					</div>
				) : (
					<>
						<div className="total-percentage-row">
							<div className="input-allocation-container">
								<HoursInput
									id="allocation-modal-input"
									placeholder="0"
									label={formatMessage({id: 'scheduling.allocation_per_person'})}
									value={total / 60 || ''}
									mutation={handleAllocationChange.bind(this)}
									disabled={
										cannotEditAllocation ||
										personsAvailability[selectedTab].length > 0 ||
										isEditedAllocationIdleTimeAndDisabled
									}
									modalView={true}
								></HoursInput>
							</div>
							{/* only show the percentage input if one person is selected */}
							{selectedPersons[selectedTab].length === 1 ? (
								<div className="percentage-input-container">
									<Input
										id="allocation-modal-percentage"
										placeholder="0"
										type="number"
										label={`% ${formatMessage(
											{id: 'scheduling.allocation_modal.hours_pr_day'},
											{
												hours: selectedPersons[selectedTab].length
													? Util.getHourEstimate(
															getPersonAverageWorkingMinutes(selectedPersons[selectedTab][0].id) /
																60
													  )
													: 0,
											}
										)}`}
										suffix={'%'}
										value={distributionPercentage ? Math.round(distributionPercentage * 100) / 100 : ''}
										onChange={handlePercentageChange.bind(this)}
										locked={
											!selectedPersons[selectedTab].length ||
											cannotEditAllocation ||
											isEditedAllocationIdleTimeAndDisabled
										}
										// locked={disabled}
									/>
								</div>
							) : (
								// show the accumulated allocation value
								<div className="input-total-container">
									<HoursInput
										id="allocations-modal-total"
										placeholder="0"
										label={formatMessage({id: 'scheduling.allocation_total'})}
										value={getTotalAllocation(peopleWithLessWorkingHours) || ''}
										mutation={handleAllocationsTotalChange.bind(this)}
										// value={(total * selectedPersons[selectedTab].length) / 60 || ''}
										disabled={true} // lock it for now until we figure out the right behaviour
										modalView={true}
									></HoursInput>
								</div>
							)}
							{personsAvailability[selectedTab].length > 0 ? (
								<div className="warning">
									{formatMessage(
										{id: 'allocation_modal.persons_unavailable_warning'},
										{
											personsList: personsAvailability[selectedTab].map(p => p.fullName).join(', '),
											personsCount: personsAvailability[selectedTab].length,
										}
									)}
								</div>
							) : null}
						</div>
						{hasFeatureFlag('capacity_planning_beta_2_improvements') &&
						hasFeatureFlag('placeholders') &&
						hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) &&
						selectedTab === tabs.ALLOCATION ? (
							<div className="allocation-type-selector-container">
								<AllocationTypeSelector
									onChange={() => {
										setAllocationSoftness(!isSoft);
									}}
									isSoft={isSoft}
									disabled={!canApproveAllocation()}
								/>
							</div>
						) : null}
						<div
							className="distribution-button-container"
							onClick={() => setIsDistributionExpanded(!isDistributionExpanded)}
						>
							{formatMessage({id: 'common.distribution'})}
							<ExpandIcon expanded={isDistributionExpanded} />
						</div>
					</>
				)}
			</div>
			{isDistributionExpanded && selectedTab !== tabs.TASK && (
				<div className="distribution-row">
					{[null, null, null, null, null, null, null].map((element, index) => (
						<HoursInput
							id={`distribution-item-${index}`}
							key={`distribution-item-${index}`}
							placeholder="0"
							label={formatDate(loopDate.add(1, 'day'), {
								weekday: 'short',
							})}
							value={distributionArray[loopDate.isoWeekday() - 1] / 60 || ''}
							mutation={handleDistributionChange.bind(this, loopDate.isoWeekday() - 1, undefined)}
							disabled={
								!selectedPersons[selectedTab].length ||
								cannotEditAllocation ||
								isEditedAllocationIdleTimeAndDisabled
							}
							modalView={true}
							// locked={disabled}
						></HoursInput>
					))}
				</div>
			)}

			{selectedTab === tabs.TIME_OFF && !hasFeatureFlag('pto_timesheet_allocation_linking') ? (
				<div className="time-registration-row">
					<div className="time-registration-label">{formatMessage({id: 'header.time'})}</div>
					<div className="time-registration-description">
						{formatMessage({
							id: isOwnAllocation
								? 'scheduling.allocation_modal.timesheet_description'
								: 'scheduling.allocation_modal.timesheet_description_other_person',
						})}
					</div>
					<div className="time-registration-choice-yes">
						<RadioButton
							locked={cannotEditAllocation || isEditedAllocationIdleTimeAndDisabled || !allowTimeReg}
							text={formatMessage({id: 'common.yes'})}
							value={true}
							stateValue={shouldCreateTimeRegs}
							onSelected={setShouldCreateTimeRegs.bind(this)}
						/>
					</div>
					<div className="time-registration-choice-no">
						<RadioButton
							locked={cannotEditAllocation || isEditedAllocationIdleTimeAndDisabled || !allowTimeReg}
							text={formatMessage({id: 'common.no'})}
							value={false}
							stateValue={shouldCreateTimeRegs}
							onSelected={setShouldCreateTimeRegs.bind(this)}
						/>
					</div>
					{!allowTimeReg && (
						<div className={'info ' + (cannotCreateTimeReg ? 'warning' : '')}>
							{formatMessage({
								id: cannotCreateTimeReg
									? 'scheduling.allocation_modal.no_edit_missing_timesheet_permission'
									: 'scheduling.allocation_modal.missing_timesheet_permission',
							})}
						</div>
					)}
				</div>
			) : null}

			{selectedTab === tabs.TASK ? (
				<div className="task-data-row">
					<div className="person-dropdown-container">
						<AssignedDropdown
							assignablePersons={
								!hasPermission(PERMISSION_TYPE.PROJECT_PERSON_CREATE)
									? selectedParentIds[tabs.TASK]
										? getProjectPersons(selectedParentIds[tabs.TASK])
										: []
									: getAssignablePersons()
							}
							assignableTeams={props.teams}
							assignedPersons={selectedPersons[tabs.TASK]}
							assignPerson={assignPerson.bind(this)}
							unassignPerson={unassignPerson.bind(this)}
							assignTeam={assignTeam.bind(this)}
							isMultiSelect={true}
							label={formatMessage({id: 'common.assignees'})}
							projectId={selectedParentIds[tabs.TASK]}
							showSuggestions={false}
							maxPeopleIconsShown={10}
						/>
					</div>
					{estimationUnit === 'POINTS' ? (
						<AffixedInputWrapper
							label={formatMessage({id: 'common.estimate'})}
							modalView={true}
							value={estimateForecast}
							callback={value => setEstimateForecast(value)}
							affix={formatMessage({id: 'common.points.short'})}
						/>
					) : (
						<HoursInput
							placeholder="0"
							label={formatMessage({id: 'common.estimate'})}
							value={estimateForecast}
							mutation={value => setEstimateForecast(value)}
							modalView={true}
							noMaxWidth={true}
						></HoursInput>
					)}
				</div>
			) : (
				<div className="notes-container">
					<RichTextField
						textFieldLabel={formatMessage({id: 'common.notes'})}
						text={descriptionText[selectedTab] || undefined}
						placeholder={formatMessage({id: 'common.notes'})}
						handleTextChange={description => updateDescription(description)}
						readOnly={cannotEditAllocation || isEditedAllocationIdleTimeAndDisabled}
					/>
				</div>
			)}

			{!hasFeatureFlag('capacity_planning_beta_2_improvements') &&
			hasFeatureFlag('placeholders') &&
			hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) &&
			selectedTab === tabs.ALLOCATION ? (
				<div className={'checkbox-row-allocate-modal'}>
					<CheckboxRow
						cy={'allocate-modal-soft'}
						checked={isSoft}
						highlightChecked
						onChange={() => {
							setAllocationSoftness(!isSoft);
						}}
						disabled={!canApproveAllocation() || cannotEditAllocation || isEditedAllocationIdleTimeAndDisabled}
						label={formatMessage({id: 'allocation.soft_allocation'})}
					/>
				</div>
			) : null}
		</Style>
	);
	const modalButtons = [{text: formatMessage({id: 'common.cancel'}), color: BUTTON_COLOR.WHITE}];

	if (!cannotEditAllocation && !isEditedAllocationIdleTimeAndDisabled) {
		modalButtons.push({
			text: isUserEditingAllocation
				? formatMessage({id: 'common.save'})
				: formatMessage({id: 'new_insight.button.create'}),
			disabled: (!isUserEditingAllocation && isCreateButtonDisabled) || cannotCreateTimeReg,
			callback: onSaveClick.bind(this),
		});
	}

	return (
		<GenericModal
			closeModal={props.closeModal}
			buttons={modalButtons}
			content={content}
			headerText={
				isUserEditingAllocation
					? preselectedTab === tabs.ALLOCATION
						? cannotEditAllocation
							? formatMessage({id: 'common.allocation'})
							: formatMessage({id: 'scheduling.allocation_modal.edit_allocation'})
						: preselectedTab === tabs.INTERNAL_TIME
						? formatMessage({id: 'scheduling.allocation_modal.edit_internal_time'})
						: formatMessage({id: 'scheduling.allocation_modal.edit_time_off'})
					: formatMessage({id: 'scheduling.create_new'})
			}
			actionsMenuOptions={
				allocation.id && !cannotEditAllocation
					? [{text: formatMessage({id: 'common.delete'}), onClick: onDeleteClick.bind(this)}]
					: undefined
			}
		/>
	);
};

export default injectIntl(canvasCreateModal);
