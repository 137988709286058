import React, {useState} from 'react';
import {AddItemWrapper, TextWrapper, DropdownWrapperStyled, InputWrapperStyled} from './InitialPlan.styled';
import {AffixedInput, Button, PlusIcon} from 'web-components';
import {handleAddRoleRow, handleAddExpenseRow} from './InitialPlanLogic';
import {CurrencyInputWrapper} from '../inputs/currency_input_wrapper';
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {PercentageInputWrapper} from '../inputs/percentage_input_wrapper';
import {Dropdown} from 'web-components';
import {ITEM_CATEGORY} from './InitialPlanUtil';

export const InitialPlanAddItem = ({
	intl,
	minutesPerDay,
	projectId,
	phaseId,
	roles,
	expenseCategoryOptions,
	currencySymbol,
	nonBillable,
	itemCategory,
}) => {
	const [selectedRoleId, setSelectedRoleId] = useState(null);
	const [selectedExpenseCategory, setSelectedExpenseCategory] = useState(null);
	const [amount, setAmount] = useState(null);
	const [expenseCost, setExpenseCost] = useState(null);
	const [inputValueMissing, setInputValueMissing] = useState(false);

	let inputRef = null;

	const [expenseMarkup, setExpenseMarkup] = useState(null);
	const [expenseRevenue, setExpenseRevenue] = useState(null);

	const [itemCreating, setItemCreating] = useState(false);

	const isExpense = itemCategory === ITEM_CATEGORY.EXPENSE;

	const resetInputs = () => {
		setSelectedRoleId(null);
		setAmount(null);
		setSelectedExpenseCategory(null);
		setExpenseCost(null);
		setExpenseMarkup(null);
		setExpenseRevenue(null);
		setInputValueMissing(false);
		setItemCreating(false);
	};

	const selectRole = roleId => {
		setSelectedRoleId(roleId[0]);
	};

	const handleSetWorkDays = value => {
		if (isNaN(value)) {
			setInputValueMissing(true);
		} else if (value !== null) {
			setInputValueMissing(false);
			setAmount(value * 60);
		}
	};

	const addRole = value => {
		if (itemCreating || selectedRoleId === null || value === null || isNaN(value) || value < 0) {
			setInputValueMissing(true);
			inputRef.focus();
			return;
		}

		setItemCreating(true);
		handleAddRoleRow(projectId, phaseId, selectedRoleId, value, resetInputs);
	};

	const addExpense = (value, revenue) => {
		if (expenseCost === null || expenseCost < 0) {
			setInputValueMissing(true);
			inputRef.focus();
			return;
		}

		if (itemCreating || selectedExpenseCategory === null || value === null || value < 0) return;

		const expenseRevenue = revenue != null ? revenue : value; // Fallback: Set revenue to value if revenue is null

		setItemCreating(true);
		handleAddExpenseRow(projectId, phaseId, selectedExpenseCategory, value, expenseRevenue, resetInputs);
	};

	const handleAddRoleHoursOnEnter = (e, currentInput) => {
		if (e?.keyCode === 13 && currentInput?.error === false) {
			addRole(currentInput.float * 60);
		}
	};

	const handleAddRoleDaysOnEnter = (e, currentInput) => {
		if (e?.keyCode === 13) {
			addRole(currentInput.float * minutesPerDay);
		}
	};

	const handleExpenseCost = (value, _, isDirty) => {
		if (isDirty) {
			setExpenseCost(value);
			setInputValueMissing(false);
			if (expenseMarkup != null) {
				setExpenseRevenue(value != null ? value * (expenseMarkup + 1) : null);
			} else {
				setExpenseMarkup(0);
				setExpenseRevenue(value);
			}
		}
	};

	const handleExpenseMarkup = value => {
		setExpenseMarkup(value);
		if (expenseCost != null) {
			setExpenseRevenue(expenseCost * (value + 1));
		}
	};

	const handleExpenseRevenue = (value, _, isDirty) => {
		if (isDirty) {
			setExpenseRevenue(value);
			if (expenseCost != null) {
				if (expenseCost === 0) {
					setExpenseMarkup(0);
				} else {
					setExpenseMarkup(value / expenseCost - 1);
				}
			} else {
				setExpenseCost(value);
				setExpenseMarkup(0);
			}
		}
	};

	const handleSelectExpenseCategory = values => {
		setSelectedExpenseCategory(values[0]);
	};

	return (
		<AddItemWrapper data-userpilot={'baseline-add-new'}>
			{isExpense ? (
				<>
					<DropdownWrapperStyled width={220}>
						<Dropdown
							usePortal
							width={220}
							dropdownAlignment={'none'}
							name={intl.formatMessage({id: 'common.select_expense_category'})}
							selectedItems={[selectedExpenseCategory]}
							onSelect={handleSelectExpenseCategory}
						>
							{expenseCategoryOptions.map(option => (
								<Dropdown.SingleText key={option.value} value={option.value} searchString={option.label}>
									{option.label}
								</Dropdown.SingleText>
							))}
						</Dropdown>
					</DropdownWrapperStyled>
					<TextWrapper>{intl.formatMessage({id: 'common.cost_colon'})}</TextWrapper>
					<InputWrapperStyled>
						<CurrencyInputWrapper
							intl={intl}
							value={expenseCost}
							currencySymbol={currencySymbol}
							callback={handleExpenseCost}
							placeholder={currencySymbol}
							hasError={inputValueMissing}
							innerRef={input => (inputRef = input)}
							disabled={!selectedExpenseCategory}
						/>
					</InputWrapperStyled>
					{!nonBillable && (
						<>
							<TextWrapper>{intl.formatMessage({id: 'common.markup_colon'})}</TextWrapper>
							<PercentageInputWrapper
								intl={intl}
								value={expenseMarkup != null ? expenseMarkup * 100 : null}
								callback={value => handleExpenseMarkup(value)}
								placeholder={'%'}
								width={'90px'}
								disabled={!selectedExpenseCategory}
							/>
							<TextWrapper>{intl.formatMessage({id: 'project_budget.revenue_colon'})}</TextWrapper>
							<InputWrapperStyled>
								<CurrencyInputWrapper
									intl={intl}
									value={expenseRevenue}
									currencySymbol={currencySymbol}
									callback={handleExpenseRevenue}
									placeholder={currencySymbol}
									disabled={!selectedExpenseCategory}
								/>
							</InputWrapperStyled>
						</>
					)}
				</>
			) : (
				<>
					<DropdownWrapperStyled width={220}>
						<Dropdown
							cy={'baseline-add-role-dropdown'}
							width={220}
							dropdownAlignment={'none'}
							name={intl.formatMessage({id: 'scope.select-role'})}
							selectedItems={[selectedRoleId]}
							onSelect={selectRole}
							usePortal
						>
							{roles.map((option, idx) => {
								return (
									<Dropdown.SingleText
										key={option.node.id}
										value={option.node.id}
										searchString={option.node.name}
										cy={'role-option-' + idx}
									>
										{option.node.name}
									</Dropdown.SingleText>
								);
							})}
						</Dropdown>
					</DropdownWrapperStyled>
					<TextWrapper>{intl.formatMessage({id: 'common.for'})}</TextWrapper>
					<InputWrapperStyled>
						{minutesPerDay !== null ? (
							<AffixedInput
								value={amount / minutesPerDay}
								callback={value => handleSetWorkDays((value * minutesPerDay) / 60)}
								affix={` ${intl
									.formatMessage({
										id:
											Math.abs(amount / minutesPerDay) <= 1 && amount / minutesPerDay !== 0
												? 'overview_time.day'
												: 'common.days',
									})
									.toLowerCase()}`}
								placeholder={intl.formatMessage({id: 'days_input.placeholder'})}
								innerRef={input => (inputRef = input)}
								parentKeyDown={handleAddRoleDaysOnEnter}
								disabled={!selectedRoleId}
								hasError={inputValueMissing}
							/>
						) : (
							<HoursInput
								cy="add-item-hours-input"
								placeholder={intl.formatMessage({id: 'hours_input.placeholder'})}
								value={amount !== null ? amount / 60 : null}
								mutation={value => handleSetWorkDays(value)}
								parentKeyDown={handleAddRoleHoursOnEnter}
								hasError={inputValueMissing}
								innerRef={input => (inputRef = input)}
								disabled={!selectedRoleId}
							/>
						)}
					</InputWrapperStyled>
				</>
			)}
			<Button
				cy={isExpense ? 'add-expense-button' : 'add-role-button'}
				disabled={itemCreating || isExpense ? selectedExpenseCategory === null : selectedRoleId === null}
				iconPosition={Button.ICON_POSITION.FIRST}
				icon={color => <PlusIcon color={color} />}
				onClick={() => {
					isExpense ? addExpense(expenseCost, expenseRevenue) : addRole(amount);
				}}
				//SIZE={Button.SIZE.SMALL}
				variant={Button.VARIANT.GREEN_FILLED}
				isSquare={true}
			></Button>
		</AddItemWrapper>
	);
};
