import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import {remapOptionTranslationIds} from '../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';
import CompanySetupUtil from '../../../../forecast-app/shared/util/CompanySetupUtil';

export const BudgetEyeOptions = {
	FIXED_PRICE: 'fixed_price',
	BASELINE: 'baseline',
	ACTUAL: 'actual',
	PLAN: 'plan',
	REMAINING: 'remaining',
	FORECAST: 'forecast',
	INVOICE: 'invoice',
	ACTUAL_VS_PLAN: 'actual_vs_plan',
	PLAN_VS_ACTUAL: 'plan_vs_actual',
	FORECAST_VS_PLAN: 'forecast_vs_plan',
	PLAN_VS_FORECAST: 'plan_vs_forecast',
	INVOICE_VS_ACTUAL: 'invoice_vs_actual',
	BASELINE_VS_ACTUAL: 'baseline_vs_actual',
	BASELINE_VS_FORECAST: 'baseline_vs_forecast',
	REVENUE_RECOGNITION: 'revenue_recognition',
};

export const getBudgetEyeOptions = (hasBaseline, project, isInvoicePlan) => {
	const renderAsBillable = ProjectUtil.projectTracksRevenue(project);
	const isFixedPriceV1 = ProjectUtil.isFixedPriceV1(project);
	const isUsingManualProgress = ProjectUtil.projectUsesManualProgress(project);

	const eyeOptions = [
		hasBaseline
			? {
					name: BudgetEyeOptions.BASELINE,
					translationId: 'project_budget.baseline',
					checked: true,
					sortOrder: 1,
			  }
			: null,
		hasBaseline
			? {
					name: BudgetEyeOptions.BASELINE_VS_ACTUAL,
					translationId: 'project_budget.baseline_vs_actual',
					checked: false,
					sortOrder: 7,
			  }
			: null,
		hasBaseline
			? {
					name: BudgetEyeOptions.BASELINE_VS_FORECAST,
					translationId: 'project_budget.baseline_vs_forecast',
					checked: false,
					sortOrder: 8,
			  }
			: null,
		!isUsingManualProgress
			? {name: BudgetEyeOptions.PLAN, translationId: 'common.planned', checked: true, sortOrder: 2}
			: null,
		{name: BudgetEyeOptions.ACTUAL, translationId: 'project_budget.actual', checked: true, sortOrder: 3},
		{
			name: BudgetEyeOptions.REMAINING,
			translationId: 'project_budget.forecast_to_complete',
			checked: true,
			sortOrder: 4,
		},
		{
			name: BudgetEyeOptions.FORECAST,
			translationId: 'project_budget.total_at_completion',
			checked: true,
			sortOrder: 5,
		},
		ProjectUtil.projectTracksRevenue(project) &&
		hasFeatureFlag('revenue_recognition_with_all_budget_types') &&
		!hasFeatureFlag('financial_categories_update') &&
		CompanySetupUtil.hasFinance()
			? {
					name: BudgetEyeOptions.REVENUE_RECOGNITION,
					translationId: 'common.revenue_recognition',
					checked: true,
					sortOrder: 6,
			  }
			: null,
		!isUsingManualProgress
			? {
					name: BudgetEyeOptions.PLAN_VS_ACTUAL,
					translationId: 'project_budget.plan_vs_actual',
					checked: false,
					sortOrder: 9,
			  }
			: null,
		!isUsingManualProgress
			? {
					name: BudgetEyeOptions.PLAN_VS_FORECAST,
					translationId: 'project_budget.plan_vs_forecast',
					checked: false,
					sortOrder: 10,
			  }
			: null,
		renderAsBillable && CompanySetupUtil.hasFinance() && isInvoicePlan
			? {
					name: BudgetEyeOptions.INVOICE_VS_ACTUAL,
					translationId: 'project_budget.accrued',
					checked: false,
					sortOrder: 11,
			  }
			: null,
		renderAsBillable && CompanySetupUtil.hasFinance() && isInvoicePlan
			? {name: BudgetEyeOptions.INVOICE, translationId: 'project_budget.invoice', checked: true, sortOrder: 12}
			: null,
		isFixedPriceV1
			? {
					name: BudgetEyeOptions.FIXED_PRICE,
					translationId: 'project_budget.fixed_price',
					checked: true,
					sortOrder: 13,
			  }
			: null,
	].filter(Boolean);

	remapOptionTranslationIds(eyeOptions);
	return eyeOptions;
};
