import {
	calculateAllocationHeatmapStepData,
	clearNeedsRecalculation,
	getAllocationHeatmapData,
	getCachedHeatmapData,
	getGroupCache,
	getStepCache,
	getVisibleItemsData,
	initializeHeatmapCache,
	isRecalculationNeeded,
	setStepCachedItem,
} from './HeatmapLogic';
import {isGlobalRecalculationNeeded, ITEM_TYPE} from '../canvas-timeline/canvas_timeline_util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import DataManager from '../DataManager';
import RecalculationManager from '../RecalculationManager';
import ComposeManager from '../ComposeManager';
import {HEATMAP_TYPE} from '../components/items/Heatmap/HeatmapItem';
import {isStepHiddenBehindLoadMore} from '../loading/LoadMoreUtil';

export const getPlaceholderHeatMapData = (
	pageComponent,
	placeholderGroup,
	overallStartDate,
	overallEndDate,
	timelineMinorStep,
	fetchVisibleItemsData
) => {
	const placeholder = placeholderGroup.data;
	const placeholderGroupId = placeholderGroup.id;
	const {data, schedulingOptions} = pageComponent.state;

	const groupCache = getGroupCache(pageComponent, placeholderGroupId);
	const stepCache = getStepCache(groupCache, timelineMinorStep);

	let cachedItem = stepCache.get(overallStartDate);

	if (
		!cachedItem ||
		isRecalculationNeeded(placeholderGroupId, groupCache, timelineMinorStep, overallStartDate, overallEndDate)
	) {
		const startDate = placeholder.startDate ? Math.max(overallStartDate, placeholder.startDate) : overallStartDate;
		const endDate = placeholder.endDate ? Math.min(overallEndDate, placeholder.endDate) : overallEndDate;

		let minutesAllocated = 0;
		let plannedTotalMinutesHard = 0;
		let plannedTotalMinutesSoft = 0;
		let plannedTotalMinutesSoftWin = 0;
		let distributionMap;

		const visibleItemsData = fetchVisibleItemsData();

		[minutesAllocated, plannedTotalMinutesHard, plannedTotalMinutesSoft, plannedTotalMinutesSoftWin, distributionMap] =
			getAllocationHeatmapData(
				schedulingOptions,
				data,
				visibleItemsData,
				startDate,
				endDate,
				minutesAllocated,
				plannedTotalMinutesHard,
				plannedTotalMinutesSoft,
				plannedTotalMinutesSoftWin
			);

		// cache item
		cachedItem = {
			minutesAllocated,
			plannedTotalMinutesHard,
			plannedTotalMinutesSoft,
			plannedTotalMinutesSoftWin,
			distributionMap,
		};

		setStepCachedItem(placeholderGroup.id, stepCache, timelineMinorStep, cachedItem, overallStartDate, overallEndDate);

		RecalculationManager.clearNeedsRecalculation(placeholderGroupId, timelineMinorStep, overallStartDate, overallEndDate);
	}

	return cachedItem;
};

export const calculatePlaceholderHeatmapCache = (pageComponent, group, stepDataArray, timelineMinorStep) => {
	if (isGlobalRecalculationNeeded(pageComponent)) {
		const childGroupIds = group.groups.map(childGroup => childGroup.id);
		const itemPredicate = item => childGroupIds.includes(item.groupId);

		// Initialize heatmapCache if it does not exist
		const placeholderGroupingCache = pageComponent.heatmapCache.get(group.id);

		let visibleItemsData;
		const fetchVisibleItemsData = () => {
			if (!visibleItemsData) {
				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					visibleItemsData = DataManager.getVisibleItemsData(
						pageComponent,
						group,
						stepDataArray[0].startDate,
						stepDataArray[stepDataArray.length - 1].endDate
					);
				} else {
					visibleItemsData = getVisibleItemsData(
						pageComponent,
						stepDataArray[0].startDate,
						stepDataArray[stepDataArray.length - 1].endDate,
						[ITEM_TYPE.PLACEHOLDER_ALLOCATION],
						itemPredicate
					);
				}
			}

			return visibleItemsData;
		};

		if (!placeholderGroupingCache) {
			initializeHeatmapCache(pageComponent, group, stepDataArray, timelineMinorStep, fetchVisibleItemsData);
		}

		for (const stepData of stepDataArray) {
			if (isStepHiddenBehindLoadMore(pageComponent, stepData)) {
				continue;
			}

			let {startDate, endDate, recalculationNeededInStep} = stepData;

			if (recalculationNeededInStep === false) {
				continue;
			}

			// fetch cached data
			let heatmapData = getCachedHeatmapData(pageComponent, group, timelineMinorStep, startDate, endDate);

			if (
				!heatmapData &&
				!isRecalculationNeeded(group.id, placeholderGroupingCache, timelineMinorStep, startDate, endDate)
			) {
				// Try to fetch from data, if recalculations are not needed
				heatmapData = calculateAllocationHeatmapStepData(
					pageComponent,
					group,
					fetchVisibleItemsData,
					timelineMinorStep,
					startDate,
					endDate
				);
			}

			if (!heatmapData) {
				getPlaceholderHeatMapData(pageComponent, group, startDate, endDate, timelineMinorStep, fetchVisibleItemsData);
			}
		}

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			clearNeedsRecalculation(placeholderGroupingCache, timelineMinorStep);
		}
	}
};

export const composePlaceholderGroupingHeatmapItems = (pageComponent, group, stepDataArray, timelineMinorStep) => {
	group.calculateHeatmapCache(group, stepDataArray, timelineMinorStep);

	const heatMapItems = [];
	for (const stepData of stepDataArray) {
		if (isStepHiddenBehindLoadMore(pageComponent, stepData)) {
			continue;
		}

		let {startDate, endDate} = stepData;
		let heatmapData = getCachedHeatmapData(pageComponent, group, timelineMinorStep, startDate, endDate);
		heatMapItems.push(ComposeManager.createHeatmapItem(pageComponent, HEATMAP_TYPE.DEMAND, group, stepData, heatmapData));
	}

	return heatMapItems;
};
