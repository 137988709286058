import React from 'react';
import Styled from 'styled-components';

const NoShrinkContainer = Styled.div`
	flex: 0 0 ${({flexBasis}) => (flexBasis ? flexBasis : 'auto')};
 `;

const NoShrinkFlexItem = ({children, flexBasis}) => {
	return <NoShrinkContainer flexBasis={flexBasis}>{children}</NoShrinkContainer>;
};

export default NoShrinkFlexItem;
