import Company0XS from '../images/avatars/company_xs_0.png';
import Company0S from '../images/avatars/company_s_0.png';
import Company0M from '../images/avatars/company_m_0.png';

export const getDefaultMediumCompanyLogo = id => {
	switch (id) {
		case 0:
			return Company0M;
		default:
			return Company0M;
	}
};

export const getDefaultMediumClientLogo = id => {
	switch (id) {
		case 0:
			return Company0M;
		default:
			return Company0M;
	}
};

export const getDefaultSmallClientLogo = id => {
	switch (id) {
		case 0:
			return Company0S;
		default:
			return Company0S;
	}
};

export const getDefaultExtraSmallClientLogo = id => {
	switch (id) {
		case 0:
			return Company0XS;
		default:
			return Company0XS;
	}
};
