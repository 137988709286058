import {
	GROUP_TYPE,
	CURSOR,
	getTextColor,
	GROUP_SECTION_WIDTH,
	drawBorderLines,
	getTrimmedText,
	GROUP_SECTION_SPACING_LEVEL_ONE,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_SPACING_LEVEL_TWO,
	GROUP_SECTION_PADDING_LEFT,
	getMomentFromCanvasTimelineDate,
	isPeopleScheduleCombinedModeWithLazyLoading,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';

class UnassignedRoleGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PEOPLE_SCHEDULING_UNASSIGNED_ROLE, data);
	}

	onItemCreate(startDate, endDate) {
		const {role} = this.data;
		const {viewer, roles, persons, projects, phases, projectPersons, rateCards} = this.pageComponent.getData();

		showModal({
			type: MODAL_TYPE.CANVAS_CREATE_NEW_MODAL,
			roles,
			persons,
			projects,
			phases,
			projectPersons,
			startDate: getMomentFromCanvasTimelineDate(startDate),
			endDate: getMomentFromCanvasTimelineDate(endDate),
			viewerId: viewer.actualPersonId,
			selectedRole: role,
			rateCards,
		});
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height, expanded, groupType} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);
		const {id, name, roleId} = data;

		const disableTaskCount = isPeopleScheduleCombinedModeWithLazyLoading(this.pageComponent);
		const taskCount = disableTaskCount ? 0 : this.pageComponent.getUnassignedTaskCount(roleId);

		const textColor = getTextColor();

		canvasContext.fillStyle = textColor;
		canvasContext.font = '500 12px ' + Util.getFontFamily();
		const taskCountTextWidth = disableTaskCount ? 0 : canvasContext.measureText(taskCount).width;

		if (!disableTaskCount) {
			canvasContext.fillText(taskCount, x + GROUP_SECTION_SPACING_LEVEL_TWO, y + 28);
		}

		const cacheId = `${groupType}-${id}-${!!expanded}`;
		interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);

		const cachedData = cacheManager.get(groupType, cacheId);
		if (cachedData) {
			canvasContext.drawImage(cachedData, x, y, width, this.itemRowHeight);
			return;
		}

		drawBorderLines(canvasContext, x, y, width, height, true);

		// expand icon
		canvasContext.drawImage(
			cacheManager.getCommonImage(expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON),
			x + GROUP_SECTION_SPACING_LEVEL_ONE,
			y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
			GROUP_SECTION_EXPAND_ICON_WIDTH,
			GROUP_SECTION_EXPAND_ICON_HEIGHT
		);

		canvasContext.fillStyle = textColor;
		canvasContext.font = 'normal 12px ' + Util.getFontFamily();
		canvasContext.fillText(
			getTrimmedText(canvasContext, name),
			x + GROUP_SECTION_SPACING_LEVEL_TWO + taskCountTextWidth + GROUP_SECTION_PADDING_LEFT,
			y + 28
		);
	}
}

export default UnassignedRoleGroup;
