/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type expenseReportProjectComponent_viewer$ref = any;
export type expenseReportProjectComponent_QueryVariables = {|
  shareKey?: ?string,
  projectId?: ?string,
  isProjectGroupType?: ?boolean,
|};
export type expenseReportProjectComponent_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: expenseReportProjectComponent_viewer$ref,
  |}
|};
export type expenseReportProjectComponent_Query = {|
  variables: expenseReportProjectComponent_QueryVariables,
  response: expenseReportProjectComponent_QueryResponse,
|};
*/


/*
query expenseReportProjectComponent_Query(
  $shareKey: String
  $projectId: ID
  $isProjectGroupType: Boolean
) {
  viewer {
    actualPersonId
    component(name: "insight_expense_report_project")
    ...expenseReportProjectComponent_viewer_2fJgLr
    id
  }
}

fragment expenseReportProjectComponent_viewer_2fJgLr on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    expenseReportProject(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
      id
      currency
      project {
        id
        name
      }
      projectGroup {
        id
        name
      }
      client {
        id
        name
      }
      data {
        name
        cost
        price
        markupPercentage
        quantity
        expenseYear
        expenseMonth
        expenseDay
        expenseCategory {
          name
          id
        }
        notes
        billable
        person {
          firstName
          lastName
          profilePictureId
          profilePictureDefaultId
          id
        }
        createdAt
        createdBy {
          id
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
        }
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isProjectGroupType",
    "type": "Boolean"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "insight_expense_report_project"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"insight_expense_report_project\")"
},
v3 = {
  "kind": "Variable",
  "name": "isProjectGroupType",
  "variableName": "isProjectGroupType"
},
v4 = {
  "kind": "Variable",
  "name": "projectId",
  "variableName": "projectId"
},
v5 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v11 = [
  (v6/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "expenseReportProjectComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "expenseReportProjectComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "expenseReportProjectComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              (v5/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "ExpenseReportProject",
                "kind": "LinkedField",
                "name": "expenseReportProject",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectGroupType",
                    "kind": "LinkedField",
                    "name": "projectGroup",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Client",
                    "kind": "LinkedField",
                    "name": "client",
                    "plural": false,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExpenseReportListElement",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cost",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "markupPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expenseYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expenseMonth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expenseDay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExpenseCategory",
                        "kind": "LinkedField",
                        "name": "expenseCategory",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "notes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "person",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureDefaultId",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updatedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "updatedBy",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "expenseReportProjectComponent_Query",
    "operationKind": "query",
    "text": "query expenseReportProjectComponent_Query(\n  $shareKey: String\n  $projectId: ID\n  $isProjectGroupType: Boolean\n) {\n  viewer {\n    actualPersonId\n    component(name: \"insight_expense_report_project\")\n    ...expenseReportProjectComponent_viewer_2fJgLr\n    id\n  }\n}\n\nfragment expenseReportProjectComponent_viewer_2fJgLr on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    expenseReportProject(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {\n      id\n      currency\n      project {\n        id\n        name\n      }\n      projectGroup {\n        id\n        name\n      }\n      client {\n        id\n        name\n      }\n      data {\n        name\n        cost\n        price\n        markupPercentage\n        quantity\n        expenseYear\n        expenseMonth\n        expenseDay\n        expenseCategory {\n          name\n          id\n        }\n        notes\n        billable\n        person {\n          firstName\n          lastName\n          profilePictureId\n          profilePictureDefaultId\n          id\n        }\n        createdAt\n        createdBy {\n          id\n          firstName\n          lastName\n        }\n        updatedAt\n        updatedBy {\n          id\n          firstName\n          lastName\n        }\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '261f42f9a966a1690c3f088ab1dac56b';

module.exports = node;
