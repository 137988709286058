import DiscreteIntervalTree from './discrete-interval-tree/discrete-interval-tree';

const cacheIntervalMap = new Map();

export default class CacheIntervalManager {
	static clearAll() {
		cacheIntervalMap.clear();
	}

	static getCacheIntervalTree(groupId, timelineMinorStep, initIfNotExist = false) {
		let groupCache = cacheIntervalMap.get(groupId);
		if (!groupCache) {
			if (!initIfNotExist) {
				return null;
			}

			groupCache = new Map();
			cacheIntervalMap.set(groupId, groupCache);
		}

		let tree = groupCache.get(timelineMinorStep);
		if (!tree) {
			if (!initIfNotExist) {
				return null;
			}

			tree = new DiscreteIntervalTree();
			groupCache.set(timelineMinorStep, tree);
		}

		return tree;
	}

	static checkConsistency(groupId, timelineMinorStep) {
		const tree = this.getCacheIntervalTree(groupId, timelineMinorStep);

		if (tree) {
			return tree.checkConsistency();
		}
	}

	static addCacheInterval(groupId, timelineMinorStep, startDate, endDate) {
		const tree = this.getCacheIntervalTree(groupId, timelineMinorStep, true);
		tree.add(startDate, endDate);
	}

	static getCacheIntervalsInRange(groupId, timelineMinorStep, startDate, endDate) {
		const tree = this.getCacheIntervalTree(groupId, timelineMinorStep);

		if (tree) {
			return tree.getOverlappingIntervals(startDate, endDate, true);
		}

		return [];
	}
}
