import React from 'react';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';
import Util from '../../../../../forecast-app/shared/util/util';
import {renderCurrency, renderPercentage, isColumnLocked} from '../util/BudgetUtils';
import ForecastTooltip from '../../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {ForecastTooltipFormulaRenderer} from '../../../../ForecastTooltipFormulaRenderer';

const RevenueRecognitionTableRow = ({data, rowData, project, tableColumnProps, headerColumn, canExpand, tooltips}) => {
	const intl = useIntl();
	const currencySymbol = Util.GetCurrencySymbol(rowData.currency);
	const fieldName = rowData.aggregate;
	const percentageRows = [
		'recognitionPercentage',
		'recognitionManualPercentage',
		'surplusPercentage',
		'recognitionProfitPercentage',
		'recognitionPercentageAccumulated',
		'estimatedCostPercentage',
		'estimatedCostPercentageAccumulated',
		'expenseRevenuePercentage',
		'expenseCostPercentage',
		'totalMarginAtCompletion',
		'suggestedRevenuePercentage',
		'suggestedProfitPercentage',
	];
	const lockedRows = ['recognitionTotal'];

	const renderValue = value => {
		if (percentageRows.includes(fieldName)) {
			return renderPercentage(value, intl);
		} else {
			return renderCurrency(currencySymbol, value, intl);
		}
	};

	if (canExpand !== undefined) {
		tableColumnProps.canExpand = canExpand;
	}

	const tooltipItem = tooltips && tooltips[fieldName] ? tooltips[fieldName] : null;

	return (
		<Table.Row cy={'row-' + fieldName} key={fieldName} {...tableColumnProps}>
			{tooltipItem ? (
				<ForecastTooltip
					maxWidth="600px"
					content={<ForecastTooltipFormulaRenderer items={tooltipItem} translatedMessage={true} />}
				>
					{headerColumn}
				</ForecastTooltip>
			) : (
				headerColumn
			)}
			{rowData.dataArray.map((object, index) => (
				<Table.Column
					cy={'revenue-recognition-row-' + fieldName + '-column-' + data?.columns[index]?.name}
					key={fieldName + object.value}
					bold={index === rowData.dataArray.length - 1}
					negative={object.value < 0}
					greyedOut={
						isColumnLocked && isColumnLocked(project.fixedPriceLocks, object) && lockedRows.includes(fieldName)
					}
				>
					{renderValue(object.value)}
				</Table.Column>
			))}
		</Table.Row>
	);
};

export default RevenueRecognitionTableRow;
