import React from 'react';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';
import Util from '../../../../forecast-app/shared/util/util';
import ForecastTooltip from '../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {ProjectPortfolioReportTooltips} from '../ProjectPortfolioReportTooltips';
import {ForecastTooltipFormulaRenderer} from '../../../ForecastTooltipFormulaRenderer';
import {PROJECT_STATUS, BUDGET_TYPE} from '../../../../constants';
import {
	renderBudgetType,
	renderClient,
	renderDate,
	renderOwners,
	renderRateCard,
	renderStage,
	renderStatus,
	renderPercentage,
	getProjectData,
	renderPriorityLevel,
	renderDescription,
} from '../period/ProjectPortfolioPeriodRow';
import * as budgetUtils from '../../../new-ui/project/project-budget-v3/util/BudgetUtils';
import moment from 'moment';
import {CurrencyColumn} from '../CurrencyColumn';

const ProjectPortfolioMonthByMonthRow = ({
	rowData,
	tableColumnProps,
	headerColumn,
	canExpand,
	hasBaseline,
	selectedSingleValue,
	fixedPriceLocks,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const currencySymbol = Util.GetCurrencySymbol(rowData.currency);
	const projectData = getProjectData(rowData);
	const hasLowerWinChance =
		hasBaseline &&
		rowData.project?.useBaseline &&
		rowData.project?.status === PROJECT_STATUS.OPPORTUNITY &&
		rowData.project?.baselineWinChance < 1;
	const lowerWinChanceTooltip =
		selectedSingleValue === 'billableTotalTimeAndExpensesAtCompletion'
			? ProjectPortfolioReportTooltips.BillableTotalTimeAndExpensesAtCompletionLowerWinChanceTooltip(formatMessage)
			: selectedSingleValue === 'totalCostAtCompletion'
			? ProjectPortfolioReportTooltips.TotalCostAtCompletionLowerWinChanceTooltip(formatMessage)
			: [];
	const showWinChanceTooltip = hasLowerWinChance && lowerWinChanceTooltip.length;

	const monthsData = [];
	const totalData = [];
	const timeData = [];
	const expenseData = [];

	for (const period of Object.values(rowData.aggregatedFinancialNumbers)) {
		const periodStartDate = period.split(',')[0];
		let startMonth = parseInt(periodStartDate.split('-')[1]);
		const periodEndDate = period.split(',')[1];
		const endMonth = parseInt(periodEndDate.split('-')[1]);
		const isColumnLocked =
			selectedSingleValue === 'recognitionAmount' && fixedPriceLocks
				? budgetUtils.isColumnLocked(fixedPriceLocks, {
						startDate: moment(periodStartDate),
						endDate: moment(periodEndDate),
				  })
				: false;
		while (startMonth <= endMonth) {
			const monthAmount = parseFloat(period.split(',')[2]);
			totalData.push({monthAmount, isColumnLocked});
			startMonth += 1;
		}
	}

	for (const period of Object.values(rowData.timeAggregatedFinancialNumbers)) {
		const periodStartDate = period.split(',')[0];
		let startMonth = parseInt(periodStartDate.split('-')[1]);
		const periodEndDate = period.split(',')[1];
		const endMonth = parseInt(periodEndDate.split('-')[1]);
		const isColumnLocked =
			selectedSingleValue === 'recognitionAmount' && fixedPriceLocks
				? budgetUtils.isColumnLocked(fixedPriceLocks, {
						startDate: moment(periodStartDate),
						endDate: moment(periodEndDate),
				  })
				: false;
		while (startMonth <= endMonth) {
			const monthAmount = parseFloat(period.split(',')[2]);
			timeData.push({monthAmount, isColumnLocked});
			startMonth += 1;
		}
	}

	for (const period of Object.values(rowData.expenseAggregatedFinancialNumbers)) {
		const periodStartDate = period.split(',')[0];
		let startMonth = parseInt(periodStartDate.split('-')[1]);
		const periodEndDate = period.split(',')[1];
		const endMonth = parseInt(periodEndDate.split('-')[1]);
		const isColumnLocked =
			selectedSingleValue === 'recognitionAmount' && fixedPriceLocks
				? budgetUtils.isColumnLocked(fixedPriceLocks, {
						startDate: moment(periodStartDate),
						endDate: moment(periodEndDate),
				  })
				: false;
		while (startMonth <= endMonth) {
			const monthAmount = parseFloat(period.split(',')[2]);
			expenseData.push({monthAmount, isColumnLocked});
			startMonth += 1;
		}
	}

	for (let i = 0; i < totalData.length; i++) {
		monthsData.push(expenseData[i]);
		monthsData.push(timeData[i]);
		monthsData.push(totalData[i]);
	}

	const renderCurrency = (value, showZero) => {
		if (value || showZero) {
			return Util.getFormattedNumberWithCurrency(currencySymbol, value, intl);
		} else {
			return '–';
		}
	};

	const isFixedPriceProject = () => {
		return [BUDGET_TYPE.FIXED_PRICE, BUDGET_TYPE.FIXED_PRICE_V2].includes(projectData.budgetType);
	};

	if (canExpand !== undefined) {
		tableColumnProps.canExpand = canExpand;
	}

	return (
		<Table.Row {...tableColumnProps}>
			<Table.Column>{headerColumn}</Table.Column>
			{/* Project Overview */}
			<Table.Column>{renderPriorityLevel(projectData.priorityLevel)}</Table.Column>
			<Table.Column>{renderPercentage(projectData.baselineWinChance)}</Table.Column>
			<Table.Column>{renderDate(projectData.startDate)}</Table.Column>
			<Table.Column>{renderDate(projectData.endDate)}</Table.Column>
			<Table.Column>{renderClient(projectData.client)}</Table.Column>
			<Table.Column>{renderStatus(projectData.currentProjectStatus)}</Table.Column>
			<Table.Column>{renderDescription(projectData.currentProjectStatus)}</Table.Column>
			<Table.Column>{renderOwners(projectData.projectPersons)}</Table.Column>
			<Table.Column>{renderStage(projectData.status)}</Table.Column>
			<Table.Column>{renderBudgetType(projectData)}</Table.Column>
			<Table.Column>{renderRateCard(projectData.rateCard)}</Table.Column>
			<Table.Column>{renderPercentage(projectData.completion / 100)}</Table.Column>
			<CurrencyColumn value={isFixedPriceProject() ? projectData.budgetBaseCurrency : null} currency={rowData.currency} />
			{/* Month by month financials */}
			{monthsData.map(monthData => (
				<Table.Column greyedOut={monthData.isColumnLocked}>
					<ForecastTooltip
						maxWidth="400px"
						content={<ForecastTooltipFormulaRenderer items={lowerWinChanceTooltip} translatedMessage={true} />}
						disabled={!showWinChanceTooltip}
					>
						{showWinChanceTooltip
							? renderCurrency(monthData.monthAmount, true) + '*'
							: renderCurrency(monthData.monthAmount, false)}
					</ForecastTooltip>
				</Table.Column>
			))}
		</Table.Row>
	);
};

export default ProjectPortfolioMonthByMonthRow;
