import {
	adjustTotalMinutesMap,
	cleanUpGhosting,
	getPersonGroup,
	getPlaceholderGroup,
	getPlaceholderGroupingGroup,
	getProjectIdFromGroup,
	refreshPlaceholderGroups,
} from './CanvasPlaceholdersSchedulingUtil';
import {PLACEHOLDERS_GROUP_BY} from './CanvasPlaceholderSchedulingConstants';
import PlaceholderAllocationItem from '../components/items/placeholder_allocation_item';
import {removeFromArray} from '../utils';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import NonProjectTimeGroup from '../components/groups/non_project_time_group';
import {getRecalculationInterval, recalculateGroupHeatmapCache} from '../heatmap/HeatmapLogic';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import DataManager, {LOOKUP_MAPS} from '../DataManager';
import ProjectGroup from '../components/groups/project_group';
import IDManager, {STAFFING_GROUPING_TYPE} from '../IDManager';
import ComposeManager from '../ComposeManager';
import CapacityPlaceholderGroup from '../components/groups/capacity_placeholder_group';
import PlaceholderGroupingGroup from '../components/groups/PlaceholderGroupingGroup';

// PLACEHOLDERS

export const transferPlaceholderAllocations = (
	pageComponent,
	existingPlaceholder,
	previousPlaceholderGroupingGroup,
	newPlaceholderGroup,
	newPlaceholderGroupingGroup
) => {
	const {items} = pageComponent.state;
	const hasHeatmapImprovements = hasFeatureFlag('improving_heatmap_frontend_performance');

	const updatedItems = items.map(item => {
		if (item.groupId === IDManager.getPlaceholderGroupId(pageComponent, existingPlaceholder)) {
			if (hasHeatmapImprovements) {
				DataManager.moveItem(
					pageComponent,
					item,
					{
						...item.data,
						groupId: newPlaceholderGroup.id,
					},
					previousPlaceholderGroupingGroup,
					newPlaceholderGroupingGroup,
					true
				);
			} else {
				item.groupId = newPlaceholderGroup.id;
			}
		}

		return item;
	});

	pageComponent.setState({items: updatedItems});
};

export const addPlaceholderGroupingGroup = (pageComponent, placeholderGroup, project, role) => {
	const {groups} = pageComponent.state;

	const placeholderGroups = placeholderGroup ? [placeholderGroup] : [];
	const placeholderGroupingGroup = new PlaceholderGroupingGroup(
		pageComponent,
		ComposeManager.composePlaceholderGroupingGroup(pageComponent, project, role, placeholderGroups)
	);

	// add new group and set expanded
	placeholderGroupingGroup.setExpanded(true);
	groups.push(placeholderGroupingGroup);

	return placeholderGroupingGroup;
};

export const addCapacityPlaceholderGroup = (pageComponent, formattedPlaceholder, existingPlaceholder) => {
	const {data, groups, groupBy} = pageComponent.state;

	const project = formattedPlaceholder.projectGroupId
		? data.projectGroups.find(projectGroup => projectGroup.id === formattedPlaceholder.projectGroupId)
		: data.projects.find(project => project.id === formattedPlaceholder.projectId);

	const role = formattedPlaceholder.roleId ? data.roles.find(role => role.id === formattedPlaceholder.roleId) : null;

	const placeholderGroupToAdd = new CapacityPlaceholderGroup(
		pageComponent,
		ComposeManager.composeCapacityPlaceholderGroup(pageComponent, formattedPlaceholder)
	);

	// if role is changed we need to update placeholderGroupingGroup from old role
	if (
		groupBy === PLACEHOLDERS_GROUP_BY.ROLE &&
		existingPlaceholder &&
		existingPlaceholder.roleId !== formattedPlaceholder.roleId
	) {
		const previousPlaceholderGroupingGroup = groups.find(
			group => group.id === IDManager.getPlaceholderGroupingId(pageComponent, existingPlaceholder.roleId, project.id)
		);

		const placeholderGroupingGroup = groups.find(
			group => group.id === IDManager.getPlaceholderGroupingId(pageComponent, formattedPlaceholder.roleId, project.id)
		);

		// add new placeholderGroupingGroup if not created yet
		let newPlaceholderGroupingGroup;
		if (!placeholderGroupingGroup) {
			newPlaceholderGroupingGroup = addPlaceholderGroupingGroup(pageComponent, placeholderGroupToAdd, project, role);
		} else {
			placeholderGroupingGroup.addChildGroup(placeholderGroupToAdd);
		}

		transferPlaceholderAllocations(
			pageComponent,
			existingPlaceholder,
			previousPlaceholderGroupingGroup,
			placeholderGroupToAdd,
			placeholderGroupingGroup || newPlaceholderGroupingGroup
		);

		// remove from old placeholderGroupingGroup and update heatmap cache
		if (previousPlaceholderGroupingGroup) {
			const existingPlaceholderGroup = previousPlaceholderGroupingGroup.groups.find(
				group => group.id === IDManager.getPlaceholderGroupId(pageComponent, existingPlaceholder)
			);

			previousPlaceholderGroupingGroup.removeChildGroup(existingPlaceholderGroup.id);
		}

		// recalculate heatmap cache of new group
		recalculateGroupHeatmapCache(pageComponent, placeholderGroupingGroup?.id || newPlaceholderGroupingGroup.id);

		// recalculate heatmap cache of old group
		if (previousPlaceholderGroupingGroup) {
			recalculateGroupHeatmapCache(pageComponent, previousPlaceholderGroupingGroup.id);
		}
	} else {
		let placeholderGroupingGroup = groups.find(
			group => group.id === IDManager.getPlaceholderGroupingId(pageComponent, role.id, project.id)
		);

		if (!placeholderGroupingGroup) {
			placeholderGroupingGroup = addPlaceholderGroupingGroup(pageComponent, placeholderGroupToAdd, project, role);
		}

		const placeholderGroup = placeholderGroupingGroup.groups.find(
			group => group.id === IDManager.getPlaceholderGroupId(pageComponent, formattedPlaceholder)
		);

		if (!placeholderGroup) {
			placeholderGroupingGroup.addChildGroup(placeholderGroupToAdd);
		} else {
			placeholderGroupingGroup.replaceChildGroup(placeholderGroup, placeholderGroupToAdd);
		}
	}
};

const movePersonGroup = (pageComponent, personGroup, newGroupId) => {
	const {groups} = pageComponent.state;
	const oldGroupId = personGroup.groupId;
	if (newGroupId === oldGroupId) {
		return;
	}
	personGroup.groupId = newGroupId;

	const oldGroupingGroups = groups.find(group => group.id === oldGroupId);
	const newGroupingGroups = groups.find(group => group.id === newGroupId);

	// remove from old people group
	oldGroupingGroups.removeChildGroup(personGroup.id);

	// add to new people group and sort
	newGroupingGroups.addChildGroup(personGroup);
	newGroupingGroups.sort?.(pageComponent);
};

export const createOrUpdatePlaceholderAllocation = (pageComponent, responsePlaceholderAllocation) => {
	const {data, items, groups, staffingModeActive, totalMinutesMap} = pageComponent.state;

	const startDate = responsePlaceholderAllocation.startDate
		? new Date(responsePlaceholderAllocation.startDate)
		: new Date(
				responsePlaceholderAllocation.startYear,
				responsePlaceholderAllocation.startMonth - 1,
				responsePlaceholderAllocation.startDay
		  );

	const endDate = responsePlaceholderAllocation.endDate
		? new Date(responsePlaceholderAllocation.endDate)
		: new Date(
				responsePlaceholderAllocation.endYear,
				responsePlaceholderAllocation.endMonth - 1,
				responsePlaceholderAllocation.endDay
		  );

	const formattedAllocation = {
		id: responsePlaceholderAllocation.id,
		description: responsePlaceholderAllocation.description,
		monday: responsePlaceholderAllocation.monday,
		tuesday: responsePlaceholderAllocation.tuesday,
		wednesday: responsePlaceholderAllocation.wednesday,
		thursday: responsePlaceholderAllocation.thursday,
		friday: responsePlaceholderAllocation.friday,
		saturday: responsePlaceholderAllocation.saturday,
		sunday: responsePlaceholderAllocation.sunday,
		startDate: startDate,
		endDate: endDate,
		startYear: startDate ? startDate.getUTCFullYear() : responsePlaceholderAllocation.startYear,
		startMonth: startDate ? startDate.getUTCMonth() + 1 : responsePlaceholderAllocation.startMonth,
		startDay: startDate ? startDate.getUTCDate() : responsePlaceholderAllocation.startDay,
		endYear: endDate ? endDate.getUTCFullYear() : responsePlaceholderAllocation.endYear,
		endMonth: endDate ? endDate.getUTCMonth() + 1 : responsePlaceholderAllocation.endMonth,
		endDay: endDate ? endDate.getUTCDate() : responsePlaceholderAllocation.endDay,
		placeholderId: responsePlaceholderAllocation.placeholderId || responsePlaceholderAllocation.placeholder.id,
		personId: responsePlaceholderAllocation.personId || responsePlaceholderAllocation.person?.id,
		isSoft: responsePlaceholderAllocation.isSoft,
		projectId: responsePlaceholderAllocation.projectId || responsePlaceholderAllocation.project?.id,
		customProjectId:
			responsePlaceholderAllocation.customProjectId || responsePlaceholderAllocation.project?.customProjectId,
		projectGroupId: responsePlaceholderAllocation.projectGroupId,
	};

	const placeholder = data.placeholders.find(placeholder => placeholder.id === formattedAllocation.placeholderId);
	const placeholderAllocationItemData = ComposeManager.composePlaceholderAllocation(pageComponent, formattedAllocation);

	if (placeholderAllocationItemData) {
		const changedAllocation = data.placeholderAllocations.find(
			placeholderAllocation => placeholderAllocation.id === responsePlaceholderAllocation.id
		);

		const isMovingFromPlaceholderToPerson = !!(
			(changedAllocation && formattedAllocation.personId && !changedAllocation.personId) ||
			(!changedAllocation && formattedAllocation.personId)
		);
		const isMovingFromPersonToPlaceholder = !!(
			changedAllocation &&
			!formattedAllocation.personId &&
			changedAllocation.personId
		);
		const isMovingBetweenPersons = !!(
			changedAllocation &&
			formattedAllocation.personId &&
			changedAllocation.personId &&
			formattedAllocation.personId !== changedAllocation.personId
		);
		const isMovingBetweenPersonAndPlaceholder = !!(isMovingFromPlaceholderToPerson || isMovingFromPersonToPlaceholder);

		if (
			staffingModeActive &&
			(isMovingBetweenPersonAndPlaceholder || isMovingBetweenPersons) &&
			(formattedAllocation.projectId || formattedAllocation.projectGroupId)
		) {
			// get previous person group
			const prevPerson = changedAllocation?.personId
				? data.persons.find(person => person.id === changedAllocation.personId)
				: null;
			const prevPersonGroup = prevPerson ? getPersonGroup(pageComponent, prevPerson) : null;

			// get new person group
			const newPerson = formattedAllocation.personId
				? data.persons.find(person => person.id === formattedAllocation.personId)
				: null;
			const newPersonGroup = newPerson ? getPersonGroup(pageComponent, newPerson) : null;
			const newPersonGroupIsStaffed = newPersonGroup
				? newPersonGroup.groupId === STAFFING_GROUPING_TYPE.ALLOCATED_PEOPLE
				: null;

			// update data
			removeFromArray(
				data.placeholderAllocations,
				placeholderAllocation => placeholderAllocation.id === formattedAllocation.id,
				formattedAllocation
			);

			if (isMovingFromPlaceholderToPerson) {
				const allocationsForOldPlaceholder =
					data.placeholderAllocationsByPlaceholder[formattedAllocation.placeholderId] || [];

				// remove from lookup map
				data.placeholderAllocationsByPlaceholder[formattedAllocation.placeholderId] =
					allocationsForOldPlaceholder.filter(allocation => allocation.id !== formattedAllocation.id);
			}

			let placeholderAllocationItem = items.find(item => item.data.placeholderAllocation?.id === formattedAllocation.id);

			if (placeholderAllocationItem) {
				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					const placeholderGroup = isMovingBetweenPersonAndPlaceholder
						? groups.find(group => group.id === IDManager.getPlaceholderGroupId(pageComponent, placeholder))
						: null;

					const fromGroup = isMovingFromPlaceholderToPerson ? placeholderGroup : prevPersonGroup;
					const toGroup = newPersonGroup || placeholderGroup;

					DataManager.moveItem(
						pageComponent,
						placeholderAllocationItem,
						placeholderAllocationItemData,
						fromGroup,
						toGroup,
						true
					);
				} else {
					placeholderAllocationItem.resetItemRow();
					placeholderAllocationItem.updateData(placeholderAllocationItemData, true);
				}
			} else {
				// add to data
				data.placeholderAllocations.push(formattedAllocation);
				placeholderAllocationItem = new PlaceholderAllocationItem(pageComponent, placeholderAllocationItemData);

				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					DataManager.addItem(pageComponent, placeholderAllocationItem);
				} else {
					// add new placeholder allocation item to items
					items.push(placeholderAllocationItem);
				}
			}

			const prevPersonGroupIsStillStaffed =
				isMovingBetweenPersons || isMovingFromPersonToPlaceholder
					? data.placeholderAllocations.find(
							placeholderAllocation =>
								placeholderAllocation.personId && placeholderAllocation.personId === prevPerson.id
					  )
					: null;

			// update staffing grouping groups for new person group
			if ((isMovingFromPlaceholderToPerson || isMovingBetweenPersons) && !newPersonGroupIsStaffed) {
				// do this so that allocated people do not get filtered
				newPersonGroup.filtered = false;
				movePersonGroup(pageComponent, newPersonGroup, STAFFING_GROUPING_TYPE.ALLOCATED_PEOPLE);

				const projectGroupIdOrProjectId = formattedAllocation.projectGroupId || formattedAllocation.projectId;
				const hasProjectGroup = newPersonGroup.groups.find(
					group => getProjectIdFromGroup(group) === projectGroupIdOrProjectId
				);

				if (!hasProjectGroup) {
					const project = formattedAllocation.projectId
						? DataManager.getProjectById(pageComponent, formattedAllocation.projectId)
						: null;
					const projectGroup = formattedAllocation.projectGroupId
						? DataManager.getProjectGroupById(pageComponent, formattedAllocation.projectGroupId)
						: null;
					const projectGroupData = ComposeManager.composeProjectGroup(
						pageComponent,
						newPerson,
						null,
						project,
						projectGroup
					);

					if (projectGroupData) {
						newPersonGroup.addChildGroup(new ProjectGroup(pageComponent, projectGroupData));
					}
				}
			}

			// update staffing grouping groups for previous person group
			if ((isMovingFromPersonToPlaceholder || isMovingBetweenPersons) && !prevPersonGroupIsStillStaffed) {
				movePersonGroup(pageComponent, prevPersonGroup, STAFFING_GROUPING_TYPE.FILTERED_MATCHES);
			}

			if (isMovingFromPersonToPlaceholder) {
				cleanUpGhosting(pageComponent, formattedAllocation);
			}

			if (changedAllocation) {
				adjustTotalMinutesMap(
					placeholder,
					formattedAllocation,
					totalMinutesMap,
					false,
					isMovingFromPlaceholderToPerson,
					isMovingFromPersonToPlaceholder
				);

				refreshPlaceholderGroups(pageComponent, placeholder);
			}

			if (!hasFeatureFlag('scheduling_recalculation_tree')) {
				if (prevPerson) {
					recalculateGroupHeatmapCache(pageComponent, prevPerson.id);
				}

				if (newPerson) {
					recalculateGroupHeatmapCache(pageComponent, newPerson.id);
				}

				placeholderAllocationItem.data.recalculateHeatmapCache();
			}
		} else {
			if (changedAllocation) {
				removeFromArray(
					data.placeholderAllocations,
					placeholderAllocation => placeholderAllocation.id === changedAllocation.id,
					formattedAllocation
				);

				if (!formattedAllocation.personId) {
					const allocationsForOldPlaceholder =
						data.placeholderAllocationsByPlaceholder[changedAllocation.placeholderId] || [];
					data.placeholderAllocationsByPlaceholder[changedAllocation.placeholderId] =
						allocationsForOldPlaceholder.filter(allocation => allocation.id !== changedAllocation.id);
				}

				const placeholderAllocationItem = items.find(
					item =>
						item.data.placeholderAllocation &&
						item.data.placeholderAllocation.id === responsePlaceholderAllocation.id
				);

				if (placeholderAllocationItem) {
					if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
						const person = changedAllocation.personId
							? data.persons.find(person => person.id === changedAllocation.personId)
							: null;
						const placeholderVisibleTreeGroup = changedAllocation?.personId
							? getPersonGroup(pageComponent, person)
							: staffingModeActive
							? getPlaceholderGroup(pageComponent, placeholder)
							: getPlaceholderGroupingGroup(pageComponent, placeholder);

						DataManager.moveItem(
							pageComponent,
							placeholderAllocationItem,
							placeholderAllocationItemData,
							placeholderVisibleTreeGroup,
							null,
							true
						);
					} else {
						placeholderAllocationItem.resetItemRow();
						placeholderAllocationItem.updateData(placeholderAllocationItemData, true);
					}

					if (!hasFeatureFlag('scheduling_recalculation_tree')) {
						placeholderAllocationItem.data.recalculateHeatmapCache();
					}
				}
			} else {
				// add to data
				data.placeholderAllocations.push(formattedAllocation);
				const placeholderAllocationItem = new PlaceholderAllocationItem(pageComponent, placeholderAllocationItemData);

				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					DataManager.addItem(pageComponent, placeholderAllocationItem);
				} else {
					items.push(placeholderAllocationItem);
				}

				if (!staffingModeActive && !hasFeatureFlag('scheduling_recalculation_tree')) {
					placeholderAllocationItem.data.recalculateHeatmapCache();
				}
			}
			adjustTotalMinutesMap(placeholder, formattedAllocation, totalMinutesMap, false, false);
			refreshPlaceholderGroups(pageComponent, placeholder);
		}

		// add to allocations by placeholder map
		if (!formattedAllocation.personId) {
			const allocationsForNewPlaceholder =
				data.placeholderAllocationsByPlaceholder[formattedAllocation.placeholderId] || [];
			allocationsForNewPlaceholder.push(formattedAllocation);
			data.placeholderAllocationsByPlaceholder[formattedAllocation.placeholderId] = allocationsForNewPlaceholder;
		}
	}
};

export const createOrUpdateMultiplePlaceholderAllocations = (pageComponent, placeholderAllocations) => {
	if (!placeholderAllocations) return;
	placeholderAllocations.forEach(placeholderAllocation =>
		createOrUpdatePlaceholderAllocation(pageComponent, placeholderAllocation)
	);
};

export const deletePlaceholderAllocation = (pageComponent, placeholderAllocationId) => {
	const {data, items, groups, staffingModeActive, totalMinutesMap} = pageComponent.state;

	const allocationToDelete = data.placeholderAllocations.find(allocation => allocation.id === placeholderAllocationId);
	const placeholder = data.placeholders.find(placeholder => placeholder.id === allocationToDelete.placeholderId);

	adjustTotalMinutesMap(placeholder, allocationToDelete, totalMinutesMap, true);
	refreshPlaceholderGroups(pageComponent, placeholder);

	// remove from placeholderAllocationsByPlaceholder map
	const allocationsByPlaceholderId = data.placeholderAllocationsByPlaceholder[allocationToDelete.placeholderId] || [];
	data.placeholderAllocationsByPlaceholder[allocationToDelete.placeholderId] = allocationsByPlaceholderId.filter(
		allocation => allocation.id !== placeholderAllocationId
	);

	// remove from placeholder allocations data
	data.placeholderAllocations = data.placeholderAllocations.filter(allocation => allocation.id !== placeholderAllocationId);

	if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
		DataManager.removeItem(pageComponent, item => item.data.placeholderAllocation?.id === placeholderAllocationId);
	} else {
		// remove from items
		const index = items.findIndex(
			item => item.data.placeholderAllocation && placeholderAllocationId === item.data.placeholderAllocation.id
		);
		items.splice(index, 1);
	}

	const staffedPersonId = allocationToDelete.personId;

	// recalculate heatmap
	if (!staffingModeActive) {
		const placeholderGroupingGroup = groups.find(
			group =>
				group.id ===
				IDManager.getPlaceholderGroupingId(
					pageComponent,
					placeholder.roleId,
					placeholder.projectId || placeholder.projectGroupId
				)
		);

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			recalculateGroupHeatmapCache(pageComponent, placeholderGroupingGroup.id);
		}
	} else if (staffedPersonId) {
		const prevPersonGroupStillStaffed = data.placeholderAllocations.some(
			placeholderAllocation => placeholderAllocation.personId === staffedPersonId
		);
		if (!prevPersonGroupStillStaffed) {
			const person = data.personMap.get(staffedPersonId);
			if (person) {
				const personGroup = getPersonGroup(pageComponent, person);
				if (personGroup) {
					movePersonGroup(pageComponent, personGroup, STAFFING_GROUPING_TYPE.FILTERED_MATCHES);
				}
			}
		}

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			recalculateGroupHeatmapCache(pageComponent, staffedPersonId);
		}
	}
};

export const deleteMultiplePlaceholderAllocations = (pageComponent, placeholderAllocationIds) => {
	placeholderAllocationIds.forEach(placeholderAllocationId => {
		deletePlaceholderAllocation(pageComponent, placeholderAllocationId);
	});
};

export const deletePlaceholder = (pageComponent, placeholderId) => {
	const {data, groups} = pageComponent.state;

	// find deleted placeholder
	const placeholderToDelete = data.placeholders.find(placeholder => placeholder.id === placeholderId);

	// remove placeholder allocations
	const placeholderAllocations = data.placeholderAllocationsByPlaceholder[placeholderId];

	if (placeholderAllocations) {
		const placeholderAllocationIds = placeholderAllocations.map(placeholderAllocation => placeholderAllocation.id);
		deleteMultiplePlaceholderAllocations(pageComponent, placeholderAllocationIds);
	}

	// remove placeholders and placeholder skills
	removeFromArray(data.placeholderSkills, placeholderSkill => placeholderSkill.placeholderId === placeholderId);

	// remove from placeholder allocations map
	const placeholderToDeleteAllocations = data.placeholderAllocationsByPlaceholder[placeholderId];
	if (placeholderToDeleteAllocations) {
		delete data.placeholderAllocationsByPlaceholder[placeholderId];
	}

	// remove from skills map
	const placeholderToDeleteSkills = data.placeholderSkillsByPlaceholder[placeholderId];
	if (placeholderToDeleteSkills) {
		delete data.placeholderSkillsByPlaceholder[placeholderId];
	}

	// remove from groups
	const placeholderGroupingGroup = groups.find(
		group =>
			group.id ===
			IDManager.getPlaceholderGroupingId(
				pageComponent,
				placeholderToDelete.roleId,
				placeholderToDelete.projectId || placeholderToDelete.projectGroupId
			)
	);

	// remove placeholder from data
	removeFromArray(data.placeholders, placeholder => placeholder.id === placeholderToDelete.id);

	if (placeholderGroupingGroup?.groups) {
		removeFromArray(placeholderGroupingGroup.groups, group => group.id === placeholderToDelete.id);
	}

	// recalculate heatmap cache
	if (!hasFeatureFlag('scheduling_recalculation_tree')) {
		recalculateGroupHeatmapCache(pageComponent, placeholderGroupingGroup.id);
	}
};

export const createOrUpdatePlaceholder = (pageComponent, responsePlaceholder) => {
	const {data} = pageComponent.state;

	const formattedPlaceholder = {
		id: responsePlaceholder.id,
		projectId: responsePlaceholder.project?.id,
		projectGroupId: responsePlaceholder.projectGroupId,
		name: responsePlaceholder.name,
		roleId: responsePlaceholder.role?.id,
		departmentId: responsePlaceholder.departmentId,
	};

	const project = formattedPlaceholder.projectId
		? data.projects.find(project => project.id === formattedPlaceholder.projectId)
		: data.projectGroups.find(projectGroup => projectGroup.id === formattedPlaceholder.projectGroupId);

	if (!project) return;

	const existingPlaceholder = data.placeholders.find(placeholder => placeholder.id === formattedPlaceholder.id);
	const placeholderSkills = responsePlaceholder.skillPlaceholders.map(sp => ({
		skillId: sp.skill.id,
		skillLevelId: sp.level?.id,
		placeholderId: formattedPlaceholder.id,
	}));

	if (!existingPlaceholder) {
		// add to placeholders data
		data.placeholders.push(formattedPlaceholder);
		data.placeholderMap.set(formattedPlaceholder.id, formattedPlaceholder);

		const projectOrProjectGroupId = formattedPlaceholder.projectId || formattedPlaceholder.projectGroupId;
		const projectGroupBy = data.placeholdersByProjectOrProjectGroup[projectOrProjectGroupId];

		if (!projectGroupBy) {
			data.placeholdersByProjectOrProjectGroup[projectOrProjectGroupId] = [];
		}

		projectGroupBy.push(formattedPlaceholder);
	} else {
		// update placeholder in data
		const existingPlaceholderIndex = data.placeholders.indexOf(existingPlaceholder);
		data.placeholders.splice(existingPlaceholderIndex, 1, formattedPlaceholder);
	}
	// replace teams
	data.placeholderTeamsByPlaceholder[formattedPlaceholder.id] = responsePlaceholder.teamPlaceholders;

	// replace skills
	data.placeholderSkills = data.placeholderSkills
		.filter(ps => ps.placeholderId !== formattedPlaceholder.id)
		.concat(...placeholderSkills);

	// add to skills map
	data.placeholderSkillsByPlaceholder[formattedPlaceholder.id] = placeholderSkills;

	addCapacityPlaceholderGroup(pageComponent, formattedPlaceholder, existingPlaceholder);

	const placeholderAllocations = responsePlaceholder.placeholderAllocations?.edges.map(edge => edge.node);
	createOrUpdateMultiplePlaceholderAllocations(pageComponent, placeholderAllocations);
};

// PERSONS

export const createOrUpdateAllocation = (pageComponent, responseAllocation) => {
	const {data, items} = pageComponent.state;

	const startDate = responseAllocation.startDate
		? new Date(responseAllocation.startDate)
		: new Date(responseAllocation.startYear, responseAllocation.startMonth - 1, responseAllocation.startDay);
	const endDate = responseAllocation.endDate
		? new Date(responseAllocation.endDate)
		: new Date(responseAllocation.endYear, responseAllocation.endMonth - 1, responseAllocation.endDay);

	const idleTime = responseAllocation.idleTime;
	const formattedAllocation = {
		id: responseAllocation.id,
		idleTimeId: responseAllocation.idleTimeId || idleTime?.id,
		idleTimeName: responseAllocation.idleTimeName || idleTime?.name,
		isIdleTimeInternal: responseAllocation.isIdleTimeInternal || idleTime?.isInternalTime,
		description: responseAllocation.description,
		monday: responseAllocation.monday,
		tuesday: responseAllocation.tuesday,
		wednesday: responseAllocation.wednesday,
		thursday: responseAllocation.thursday,
		friday: responseAllocation.friday,
		saturday: responseAllocation.saturday,
		sunday: responseAllocation.sunday,
		startYear: startDate.getFullYear(),
		startMonth: startDate.getMonth() + 1,
		startDay: startDate.getDate(),
		endYear: endDate.getFullYear(),
		endMonth: endDate.getMonth() + 1,
		endDay: endDate.getDate(),
		personId: responseAllocation.personId || responseAllocation.person?.id,
		projectId: responseAllocation.projectId || responseAllocation.project?.id,
		customProjectId: responseAllocation.customProjectId || responseAllocation.project?.customProjectId,
		projectGroupId: responseAllocation.projectGroupId,
		projectGroupColor: responseAllocation.projectGroupColor,
		projectGroupCompanyProjectGroupId: responseAllocation.projectGroupCompanyProjectGroupId,
		projectGroupName: responseAllocation.projectGroupName,
		isSoft: responseAllocation.isSoft,
	};

	const allocationItemData = ComposeManager.composeProjectAllocation(pageComponent, formattedAllocation);

	if (allocationItemData) {
		const changedAllocation = data.allocations.find(allocation => allocation.id === formattedAllocation.id);

		if (changedAllocation) {
			const isMovingToNewPerson = changedAllocation.personId !== formattedAllocation.personId;

			if (isMovingToNewPerson) {
				data.personAllocationMap = DataManager.createGroupBy(data?.allocations, LOOKUP_MAPS.PERSON_ALLOCATION_MAP);
			}

			const allocationItem = items.find(item => item.data.allocation?.id === formattedAllocation.id);

			if (allocationItem) {
				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					let newPersonGroup;
					if (isMovingToNewPerson) {
						const newPerson = data.persons.find(person => person.id === formattedAllocation.personId);
						newPersonGroup = getPersonGroup(pageComponent, newPerson);
					}

					const person = data.persons.find(person => person.id === formattedAllocation.personId);
					const personGroup = getPersonGroup(pageComponent, person);

					DataManager.moveItem(pageComponent, allocationItem, allocationItemData, personGroup, newPersonGroup, true);
				} else {
					allocationItem.resetItemRow();
					allocationItem.updateData(allocationItemData, true);
				}

				allocationItem.data.recalculateHeatmapCache(getRecalculationInterval(allocationItem));
			}

			// update state data
			data.allocations.splice(data.allocations.indexOf(changedAllocation), 1, formattedAllocation);
		} else {
			// add to data
			data.allocations.push(formattedAllocation);
			data.personAllocationMap = DataManager.createGroupBy(data?.allocations, LOOKUP_MAPS.PERSON_ALLOCATION_MAP);

			const allocationItem = new ProjectAllocationItem(pageComponent, allocationItemData);

			if (!hasFeatureFlag('improving_heatmap_frontend_performance')) {
				items.push(allocationItem);
			}

			const person = data.persons.find(person => person.id === formattedAllocation.personId);
			const personGroup = getPersonGroup(pageComponent, person);

			// create project group if none
			if (personGroup) {
				const projectGroupId = formattedAllocation.idleTimeId
					? IDManager.getNonProjectTimeGroupId(pageComponent, person.id)
					: formattedAllocation.projectGroupId || formattedAllocation.projectId;

				const hasProjectGroup = personGroup.groups.find(group =>
					formattedAllocation.idleTimeId
						? group.id === IDManager.getNonProjectTimeGroupId(pageComponent, person.id)
						: getProjectIdFromGroup(group) === projectGroupId
				);

				if (!hasProjectGroup) {
					if (formattedAllocation.idleTimeId) {
						const nonProjectTimeGroupData = ComposeManager.composeNonProjectTimeGroup(pageComponent, person);

						if (nonProjectTimeGroupData) {
							personGroup.addChildGroup(new NonProjectTimeGroup(pageComponent, nonProjectTimeGroupData));
						}
					} else {
						const project = formattedAllocation.projectId
							? DataManager.getProjectById(pageComponent, formattedAllocation.projectId)
							: null;
						const projectGroup = formattedAllocation.projectGroupId
							? DataManager.getProjectGroupById(pageComponent, formattedAllocation.projectGroupId)
							: null;
						const projectGroupData = ComposeManager.composeProjectGroup(
							pageComponent,
							person,
							null,
							project,
							projectGroup
						);

						if (projectGroupData) {
							personGroup.addChildGroup(new ProjectGroup(pageComponent, projectGroupData));
						}
					}
				}
			}

			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				DataManager.addItem(pageComponent, allocationItem);
			}

			allocationItem.data.recalculateHeatmapCache(getRecalculationInterval(allocationItem));
		}
	}
};

export const createOrUpdateMultipleAllocations = (pageComponent, allocations) => {
	if (!allocations) return;
	allocations.forEach(allocation => createOrUpdateAllocation(pageComponent, allocation));
};

export const deleteAllocation = (pageComponent, allocationToDelete) => {
	const {data, items} = pageComponent.state;

	data.allocations = data.allocations.filter(allocation => allocation.id !== allocationToDelete.id);

	if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
		DataManager.removeItem(pageComponent, item => item.data.allocation?.id === allocationToDelete.id);
	} else {
		removeFromArray(items, item => item.data.allocation?.id === allocationToDelete.id);
	}

	if (!hasFeatureFlag('scheduling_recalculation_tree')) {
		recalculateGroupHeatmapCache(pageComponent, IDManager.getPersonGroupId(pageComponent, allocationToDelete.personId));
	}
};
