/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MonthByMonthTotalsLoader_viewer$ref: FragmentReference;
declare export opaque type MonthByMonthTotalsLoader_viewer$fragmentType: MonthByMonthTotalsLoader_viewer$ref;
export type MonthByMonthTotalsLoader_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string,
    +aggregatedFinancialNumbers: $ReadOnlyArray<?string>,
    +timeAggregatedFinancialNumbers: $ReadOnlyArray<?string>,
    +expenseAggregatedFinancialNumbers: $ReadOnlyArray<?string>,
  |},
  +$refType: MonthByMonthTotalsLoader_viewer$ref,
|};
export type MonthByMonthTotalsLoader_viewer$data = MonthByMonthTotalsLoader_viewer;
export type MonthByMonthTotalsLoader_viewer$key = {
  +$data?: MonthByMonthTotalsLoader_viewer$data,
  +$fragmentRefs: MonthByMonthTotalsLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "Literal",
  "name": "aggregateLevel",
  "value": "MONTH"
},
v1 = {
  "items": [
    {
      "kind": "Variable",
      "name": "aggregates.0",
      "variableName": "selectedSingleValue"
    }
  ],
  "kind": "ListValue",
  "name": "aggregates"
},
v2 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v3 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v4 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v5 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v6 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v7 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "timeSearchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "expenseSearchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "selectedSingleValue",
      "type": "FinancialAggregatedNumber!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "MonthByMonthTotalsLoader_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            },
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "aggregatedFinancialNumbers",
          "storageKey": null
        },
        {
          "alias": "timeAggregatedFinancialNumbers",
          "args": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "timeSearchQuery"
            },
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "aggregatedFinancialNumbers",
          "storageKey": null
        },
        {
          "alias": "expenseAggregatedFinancialNumbers",
          "args": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "expenseSearchQuery"
            },
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "aggregatedFinancialNumbers",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be87641e313655d70a7cd75ef3db3359';

module.exports = node;
