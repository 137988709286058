import DirectApi from '../../../../directApi';

export const fetchAutoSchedulingProposal = autoSchedulingConfig => {
	const {
		autoSchedulingRequestConfig,
		doneOrEmptyPhasesIds,
		tasksWithNoRoleOrEstimations,
		taskDeactivatedUsersMap,
		skipUnestimatedTasks,
		selectedTasks,
		unselectedPhasesIds,
		isOverridePhaseDatesSelected,
		project,
		projectStartDate,
		unqualifiedPersons,
		notQualifiedPhasesNames,
		zeroRemainingTasks,
	} = autoSchedulingConfig;

	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const init = {
		method: 'POST',
		headers,
		credentials: 'include',
		body: JSON.stringify(autoSchedulingRequestConfig),
	};
	return fetch(DirectApi.graphqlServerEndpoint('requestAutoScheduleProposal'), init)
		.then(response =>
			response.json().then(autoScheduleData => {
				let tasks = autoScheduleData.tasks;

				if (autoScheduleData.excludedTasks && autoScheduleData.excludedTasks.length) {
					tasks = tasks.concat(
						autoScheduleData.excludedTasks.map(task => {
							return {...task.task, id: btoa(`Task:${task.task.id}`)};
						})
					);
				}

				//adding assigned deactivated users to the error list if change assignee option was disabled
				if (
					autoSchedulingConfig.keepAssignees &&
					taskDeactivatedUsersMap &&
					taskDeactivatedUsersMap.keys().length !== 0
				) {
					taskDeactivatedUsersMap.forEach((value, key, map) => {
						autoScheduleData.errors.push({
							errorType: 'DEACTIVATED_USER_ASSIGNED',
							affectedTaskIds: [key],
							recordId: value.id,
							details: '',
						});
					});
				}

				if (notQualifiedPhasesNames.length) {
					autoScheduleData.errors.push({
						errorType: 'NO_APPROVED_TASKS',
						affectedPhases: notQualifiedPhasesNames,
						affectedTaskIds: [],
						details: '',
					});
				}
				if (unqualifiedPersons.length) {
					autoScheduleData.errors.push({
						errorType: 'NO_ROLE_PERSONS',
						affectedPersons: unqualifiedPersons,
						affectedTaskIds: [],
						details: '',
					});
				}
				if (zeroRemainingTasks.length) {
					autoScheduleData.errors.push({
						errorType: 'ZERO_REMAINING_TASKS',
						affectedTaskIds: zeroRemainingTasks.map(task => task.id),
						details: '',
						zeroRemainingTasks,
					});
				}
				autoSchedulingConfig.notQualifiedPhasesNames = [];
				autoSchedulingConfig.unqualifiedPersons = [];

				let excludedTaskIds =
					autoScheduleData.excludedTasks && autoScheduleData.excludedTasks.length
						? autoScheduleData.excludedTasks.map(task => btoa(`Task:${task.task.id}`))
						: [];

				if (skipUnestimatedTasks) {
					// add the skipped tasks to the list of excluded tasks and to the list of tasks to fade them
					const skippedTasksIds = tasksWithNoRoleOrEstimations.map(t => t.id);
					excludedTaskIds = excludedTaskIds.concat(skippedTasksIds);

					tasks = tasks.concat(
						skippedTasksIds.map(id => {
							return selectedTasks.find(t => t.id === id);
						})
					);
				}

				let autoSchedulingProposalData = {
					requestNumber: autoSchedulingConfig.requestNumber,
					status: response.status,
					errors: autoScheduleData.errors,
					taskData: tasks,
					excludedTaskIds,
					excludedPhasesIds: unselectedPhasesIds,
					doneOrEmptyPhasesIds,
					isOverridePhaseDatesSelected,
					project,
					autoScheduleStartDay: projectStartDate.date(),
					autoScheduleStartMonth: projectStartDate.month() + 1,
					autoScheduleStartYear: projectStartDate.year(),
				};
				autoSchedulingConfig.requestNumber++;
				return autoSchedulingProposalData;
			})
		)
		.catch(error => {
			throw error;
		});
};
