import React from 'react';
import {useIntl} from 'react-intl';
import {Table} from 'web-components';
import ProgramLoader, {programLoaderQuery} from './ProgramLoader';
import {filtersToSearchQuery} from '../ProjectPortfolioReportUtils';
import ProjectPortfolioPeriodProgramDownload, {
	projectPortfolioPeriodProgramDownloadQuery,
} from './ProjectPortfolioPeriodProgramDownload';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {LoadMore} from '../../../loaders/LoadMore';
import ProjectPortfolioPeriodRow from './ProjectPortfolioPeriodRow';
import ProjectPortfolioPeriodProjectRows from './ProjectPortfolioPeriodProjectRows';
import {PROGRAM_BUDGET_TYPE} from '../../../../constants';
import {hasTopDownProgramBudgetFeature} from '../../../../forecast-app/shared/util/ProgramFinancialLogic';
import ProgramUtil from '../../../../forecast-app/shared/util/ProgramUtil';

const ProjectPortfolioPeriodProgramRows = ({
	setCsvDownloadFunction,
	hasBaseline,
	pageSize,
	startDate,
	endDate,
	filters,
	boolEyeOptions,
}) => {
	const intl = useIntl();

	return (
		<LoadMore
			key="query-render-program"
			query={programLoaderQuery}
			variables={{
				searchQuery: filtersToSearchQuery(filters),
				startYear: startDate ? startDate.year() : undefined,
				startMonth: startDate ? startDate.month() + 1 : undefined,
				startDay: startDate ? startDate.date() : undefined,
				endYear: endDate ? endDate.year() : undefined,
				endMonth: endDate ? endDate.month() + 1 : undefined,
				endDay: endDate ? endDate.date() : undefined,
				pageSize: pageSize,
			}}
			loader={<ProgramLoader />}
		>
			{({data, loadMore}) => {
				setCsvDownloadFunction(() => {
					showModal({
						type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
						componentName: 'project_portfolio_period_program_csv_loader',
						query: projectPortfolioPeriodProgramDownloadQuery,
						variables: {
							searchQuery: filtersToSearchQuery(filters),
							startYear: startDate ? startDate.year() : undefined,
							startMonth: startDate ? startDate.month() + 1 : undefined,
							startDay: startDate ? startDate.date() : undefined,
							endYear: endDate ? endDate.year() : undefined,
							endMonth: endDate ? endDate.month() + 1 : undefined,
							endDay: endDate ? endDate.date() : undefined,
							pageSize: 1000000,
						},
						createCsvDownload: <ProjectPortfolioPeriodProgramDownload />,
						startDate,
						endDate,
						boolEyeOptions,
					});
				});
				return (
					<Table.Rows data={data} canExpand={true} loadMore={loadMore} autoload={true}>
						{({rowData, tableColumnsProps, expanded, nextLevelProps}) => {
							const footerRowData = hasTopDownProgramBudgetFeature() &&
								rowData.program.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE && {
									name: 'Unallocated revenue',
									currency: rowData.currency,
									financialNumbers: {
										totalRevenueRecognition: rowData.financialNumbers.unallocatedRevenue,
										recognitionProfit: rowData.financialNumbers.unallocatedRevenue,
									},
								};

							return (
								<>
									<ProjectPortfolioPeriodRow
										financialNumbers={rowData.financialNumbers}
										rowData={rowData}
										tableColumnProps={tableColumnsProps}
										headerColumn={
											rowData.program.name
												? rowData.program.name
												: intl.formatMessage(
														{id: 'common.no_program'},
														{program: ProgramUtil.programText(intl.formatMessage)}
												  )
										}
										hasBaseline={hasBaseline}
									/>
									{expanded && (
										<ProjectPortfolioPeriodProjectRows
											setCsvDownloadFunction={setCsvDownloadFunction}
											hasBaseline={hasBaseline}
											pageSize={pageSize}
											startDate={startDate}
											endDate={endDate}
											filters={filters}
											boolEyeOptions={boolEyeOptions}
											levelProps={nextLevelProps}
											programId={rowData.program.id}
											footerRowData={footerRowData}
										></ProjectPortfolioPeriodProjectRows>
									)}
								</>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default ProjectPortfolioPeriodProgramRows;
