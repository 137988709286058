import {
	CURSOR,
	drawRectangle,
	getTextColor,
	getTrimmedText,
	GROUP_SECTION_PADDING_LEFT,
	GROUP_SECTION_SPACING_LEVEL_FOUR,
	GROUP_SECTION_WIDTH,
	GROUP_TYPE,
	GROUP_SECTION_PADDING_LEFT_SMALL,
	GROUP_SECTION_TEXT_GREY,
	EXPAND_ICON_WIDTH,
	drawBorder,
	GROUP_SECTION_PROJECT_BORDER_WIDTH,
	GROUP_SECTION_ACTION_BUTTON_SIZE,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	isProjectDoneOrHalted,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {drawExpandedGroupHighlight} from '../../DrawingUtils';
import EventManager from '../../EventManager';

class TaskGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.TASK, data);

		if (data.subTaskLevel === undefined) {
			data.subTaskLevel = 0;
		}
	}

	addSubTaskLevel(subTaskLevel) {
		this.data.subTaskLevel = subTaskLevel;
		if (this.data.groups) {
			this.data.groups.forEach(group => {
				group.addSubTaskLevel(subTaskLevel + 1);
			});
		}
	}

	refreshData(updatedTask) {
		this.data.name = updatedTask.name;
	}

	onItemCreate(startDate, endDate) {
		const {sharedContext} = this.pageComponent.props;
		const {project} = this.data;

		if (!sharedContext && !isProjectDoneOrHalted(project.status)) {
			EventManager.onItemCreate(this.pageComponent, this, startDate, endDate);
		}
	}

	onClick() {
		const {sharedContext, showTaskModal} = this.pageComponent.props;

		if (!sharedContext) {
			const {task, disabled} = this.data;
			if (!disabled) {
				showTaskModal(task.companyTaskId);
			}
		}
	}

	draw(canvasContext, x, y) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height, groupType, expanded} = this;
		super.draw(x, y, width, height, this);
		const {name, id, color, isInProjectGroup, isProjectTimeline} = data;
		const cacheId = `${groupType}-${id}`;
		interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);
		const taskDone = data.task.done;
		const connectedProjectOffset = isInProjectGroup ? GROUP_SECTION_PADDING_LEFT : 0;

		const cachedData = cacheManager.get(groupType, cacheId);
		if (cachedData) {
			canvasContext.drawImage(cachedData, x, y, width, this.itemRowHeight);
			return;
		}

		const fontSize = 11;
		const textColor = getTextColor();

		const drawGroup = () => {
			const subTaskLevelOffset = data.subTaskLevel * GROUP_SECTION_PADDING_LEFT;
			const topBottomBorderThickness = 1;
			const smallestTaskOffsetX =
				GROUP_SECTION_SPACING_LEVEL_FOUR +
				GROUP_SECTION_PADDING_LEFT * 2 +
				GROUP_SECTION_PADDING_LEFT_SMALL +
				EXPAND_ICON_WIDTH;
			const maxNameWidth =
				GROUP_SECTION_WIDTH -
				connectedProjectOffset -
				smallestTaskOffsetX -
				subTaskLevelOffset -
				GROUP_SECTION_PADDING_LEFT -
				GROUP_SECTION_ACTION_BUTTON_SIZE;

			if (!isProjectTimeline) {
				drawExpandedGroupHighlight(canvasContext, x, y, height, width);
			}

			// right project colored border
			drawBorder(
				canvasContext,
				x + width - GROUP_SECTION_PROJECT_BORDER_WIDTH,
				y - 2 - topBottomBorderThickness * 2,
				height + topBottomBorderThickness * 2,
				true,
				color,
				GROUP_SECTION_PROJECT_BORDER_WIDTH
			);

			if (data.expandable) {
				canvasContext.drawImage(
					cacheManager.getCommonImage(
						expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON
					),
					x +
						connectedProjectOffset +
						GROUP_SECTION_SPACING_LEVEL_FOUR +
						subTaskLevelOffset -
						GROUP_SECTION_EXPAND_ICON_WIDTH / 2,
					y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
					GROUP_SECTION_EXPAND_ICON_WIDTH,
					GROUP_SECTION_EXPAND_ICON_HEIGHT
				);
			}

			// task icon
			const iconHeight = 16;
			const iconWidth = 16;
			const taskIcon =
				data.subTaskLevel > 0
					? taskDone
						? COMMON_IMAGE.TASK_DONE_BLACK
						: data.isStarted
						? COMMON_IMAGE.SUB_TASK_BLACK
						: COMMON_IMAGE.SUB_TASK_NOT_STARTED
					: taskDone
					? COMMON_IMAGE.TASK_DONE_BLACK
					: data.isStarted
					? COMMON_IMAGE.TASK_BLACK
					: COMMON_IMAGE.TASK_NOT_STARTED;
			canvasContext.drawImage(
				cacheManager.getCommonImage(taskIcon),
				x +
					connectedProjectOffset +
					GROUP_SECTION_SPACING_LEVEL_FOUR +
					GROUP_SECTION_PADDING_LEFT +
					GROUP_SECTION_EXPAND_ICON_WIDTH / 2 +
					subTaskLevelOffset,
				y + height / 2 - iconHeight / 2,
				iconWidth,
				iconHeight
			);

			// task name
			canvasContext.fillStyle = data.isStarted ? textColor : GROUP_SECTION_TEXT_GREY;
			canvasContext.font = `600 ${fontSize}px ` + Util.getFontFamily();
			const nameTrimmed = getTrimmedText(canvasContext, name, maxNameWidth);
			canvasContext.fillText(
				nameTrimmed,
				x +
					connectedProjectOffset +
					GROUP_SECTION_SPACING_LEVEL_FOUR +
					GROUP_SECTION_PADDING_LEFT * 2 +
					GROUP_SECTION_PADDING_LEFT_SMALL +
					EXPAND_ICON_WIDTH +
					subTaskLevelOffset,
				y + height / 2 + fontSize / 3
			);

			// task done name strikethrough
			if (taskDone) {
				const strikethroughPadding = 4;
				drawRectangle(
					canvasContext,
					x +
						connectedProjectOffset +
						GROUP_SECTION_SPACING_LEVEL_FOUR +
						GROUP_SECTION_PADDING_LEFT * 2 +
						GROUP_SECTION_PADDING_LEFT_SMALL +
						EXPAND_ICON_WIDTH +
						subTaskLevelOffset -
						strikethroughPadding / 2,
					y + height / 2,
					canvasContext.measureText(nameTrimmed).width + strikethroughPadding,
					0.75,
					{
						backgroundColor: textColor,
					}
				);
			}
		};
		drawGroup();
	}
}

export default TaskGroup;
