import React from 'react';
import {useIntl} from 'react-intl';
import {Table} from 'web-components';
import Util from '../../../../forecast-app/shared/util/util';
import {getAdjustedValues} from '../UtilizationReportUtils';

const PeriodRow = ({
	data,
	resourceData,
	tableColumnProps,
	headerColumn,
	canExpandRow,
	allocationControlsOptions,
	isPersonRow,
}) => {
	const intl = useIntl();

	const {
		plannedOverMinutes,
		plannedBillableUtilization,
		plannedNonBillableUtilization,
		plannedResourceUtilization,
		plannedBillableProjectMinutes,
		plannedNonBillableProjectMinutes,
		plannedTotalMinutes,
		remainingBillableUtilization,
		remainingNonBillableUtilization,
		remainingResourceUtilization,
		remainingBillableProjectMinutes,
		remainingNonBillableProjectMinutes,
		remainingTotalMinutes,
		remainingOverMinutes,
		billableActualVsPlan,
		nonBillableActualVsPlan,
		totalActualVsPlan,
		forecastBillableProjectMinutes,
		forecastNonBillableProjectMinutes,
		forecastTotalMinutes,
		forecastOverMinutes,
		forecastBillableUtilization,
		forecastNonBillableUtilization,
		forecastResourceUtilization,
		plannedBillableAllocationVsTask,
		plannedNonBillableAllocationVsTask,
		plannedAllocationVsTask,
		remainingBillableAllocationVsTask,
		remainingNonBillableAllocationVsTask,
		remainingAllocationVsTask,
		plannedBillableTaskAndAllocationsCombined,
		plannedNonBillableTaskAndAllocationsCombined,
		plannedTotalTimeTaskAndAllocationsCombined,
		plannedBillableUtilizationTaskAndAllocationsCombined,
		plannedNonBillableUtilizationTaskAndAllocationsCombined,
		plannedResourceUtilizationTaskAndAllocationsCombined,
		remainingBillableTaskAndAllocationsCombined,
		remainingNonBillableTaskAndAllocationsCombined,
		remainingTotalTimeTaskAndAllocationsCombined,
		remainingBillableUtilizationTaskAndAllocationsCombined,
		remainingNonBillableUtilizationTaskAndAllocationsCombined,
		remainingResourceUtilizationTaskAndAllocationsCombined,
		forecastBillableTaskAndAllocationsCombined,
		forecastNonBillableTaskAndAllocationsCombined,
		forecastTotalTimeTaskAndAllocationsCombined,
		forecastBillableUtilizationTaskAndAllocationsCombined,
		forecastNonBillableUtilizationTaskAndAllocationsCombined,
		forecastResourceUtilizationTaskAndAllocationsCombined,
	} = getAdjustedValues(resourceData, allocationControlsOptions);

	const renderMinutes = minutes => {
		if (minutes) {
			return Util.convertMinutesToFullHour(minutes, intl);
		} else {
			return '–';
		}
	};

	const renderPercentage = percentage => {
		if (percentage) {
			return Util.getFormattedPercentage(percentage, intl);
		} else {
			return '–';
		}
	};

	return (
		<Table.Row {...tableColumnProps} canExpandRow={canExpandRow || !!data?.rows} cy={'report-row'}>
			<Table.Column>{headerColumn}</Table.Column>
			{/* Actuals */}
			<Table.Column>{renderMinutes(resourceData.actualBillableProjectMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.actualNonBillableProjectMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.actualInternalMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.actualTotalMinutes)}</Table.Column>
			<Table.Column>{isPersonRow ? renderMinutes(resourceData.actualOverMinutes) : null}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.actualBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.performance)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.actualNonBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.actualInternalUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.actualResourceUtilization)}</Table.Column>
			{/* Plan */}
			<Table.Column>{renderMinutes(plannedBillableProjectMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(plannedNonBillableProjectMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.plannedInternalMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(plannedTotalMinutes)}</Table.Column>
			<Table.Column>{isPersonRow ? renderMinutes(plannedOverMinutes) : null}</Table.Column>
			<Table.Column>{renderPercentage(plannedBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(plannedNonBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.plannedInternalUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(plannedResourceUtilization)}</Table.Column>
			{/* Plan Combined*/}
			<Table.Column>{renderMinutes(plannedBillableTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderMinutes(plannedNonBillableTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.plannedInternalTimeTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderMinutes(plannedTotalTimeTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(plannedBillableUtilizationTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(plannedNonBillableUtilizationTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.plannedInternalUtilizationTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(plannedResourceUtilizationTaskAndAllocationsCombined)}</Table.Column>
			{/* Plan Tasks*/}
			<Table.Column>{renderMinutes(resourceData.tasksEstimateBillableMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.tasksEstimateNonBillableMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.plannedInternalMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.tasksEstimateTotalMinutes)}</Table.Column>
			<Table.Column>{isPersonRow ? renderMinutes(resourceData.tasksOverMinutes) : null}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.tasksBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.tasksNonBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.plannedInternalUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.tasksResourceUtilization)}</Table.Column>
			{/* Actual vs. Plan */}
			<Table.Column>{renderMinutes(billableActualVsPlan)}</Table.Column>
			<Table.Column>{renderMinutes(nonBillableActualVsPlan)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.internalActualVsPlan)}</Table.Column>
			<Table.Column>{renderMinutes(totalActualVsPlan)}</Table.Column>
			{/* Actual vs. Plan Task */}
			<Table.Column>{renderMinutes(resourceData.tasksBillableActualVsPlan)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.tasksNonBillableActualVsPlan)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.tasksTotalActualVsPlan)}</Table.Column>
			{/* Remaining */}
			<Table.Column>{renderMinutes(remainingBillableProjectMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(remainingNonBillableProjectMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.remainingInternalMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(remainingTotalMinutes)}</Table.Column>
			<Table.Column>{isPersonRow ? renderMinutes(remainingOverMinutes) : null}</Table.Column>
			<Table.Column>{renderPercentage(remainingBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(remainingNonBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.remainingInternalUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(remainingResourceUtilization)}</Table.Column>
			{/* Remaining Combined*/}
			<Table.Column>{renderMinutes(remainingBillableTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderMinutes(remainingNonBillableTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.remainingInternalTimeTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderMinutes(remainingTotalTimeTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(remainingBillableUtilizationTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(remainingNonBillableUtilizationTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.remainingInternalUtilizationTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(remainingResourceUtilizationTaskAndAllocationsCombined)}</Table.Column>
			{/* Remaining Tasks */}
			<Table.Column>{renderMinutes(resourceData.tasksRemainingBillableMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.tasksRemainingNonBillableMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.remainingInternalMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.tasksRemainingTotalMinutes)}</Table.Column>
			<Table.Column>{isPersonRow ? renderMinutes(resourceData.tasksRemainingOverMinutes) : null}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.tasksRemainingBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.tasksRemainingNonBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.remainingInternalUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.tasksRemainingResourceUtilization)}</Table.Column>
			{/* Forecast */}
			<Table.Column>{renderMinutes(forecastBillableProjectMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(forecastNonBillableProjectMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.forecastInternalMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(forecastTotalMinutes)}</Table.Column>
			<Table.Column>{isPersonRow ? renderMinutes(forecastOverMinutes) : null}</Table.Column>
			<Table.Column>{renderPercentage(forecastBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(forecastNonBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.forecastInternalUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(forecastResourceUtilization)}</Table.Column>
			{/* Forecast Combined*/}
			<Table.Column>{renderMinutes(forecastBillableTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderMinutes(forecastNonBillableTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.forecastInternalTimeTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderMinutes(forecastTotalTimeTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(forecastBillableUtilizationTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(forecastNonBillableUtilizationTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.forecastInternalUtilizationTaskAndAllocationsCombined)}</Table.Column>
			<Table.Column>{renderPercentage(forecastResourceUtilizationTaskAndAllocationsCombined)}</Table.Column>
			{/* Forecast Tasks */}
			<Table.Column>{renderMinutes(resourceData.tasksForecastBillableMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.tasksForecastNonBillableMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.forecastInternalMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.tasksForecastTotalMinutes)}</Table.Column>
			<Table.Column>{isPersonRow ? renderMinutes(resourceData.tasksForecastOverMinutes) : null}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.tasksForecastBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.tasksForecastNonBillableUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.forecastInternalUtilization)}</Table.Column>
			<Table.Column>{renderPercentage(resourceData.tasksForecastResourceUtilization)}</Table.Column>
			{/* Availability */}
			<Table.Column>{renderMinutes(resourceData.workingMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.timeOffMinutes)}</Table.Column>
			<Table.Column>{renderMinutes(resourceData.availableMinutes)}</Table.Column>
			{/* Planned Allocation vs Task */}
			<Table.Column>{renderMinutes(plannedBillableAllocationVsTask)}</Table.Column>
			<Table.Column>{renderMinutes(plannedNonBillableAllocationVsTask)}</Table.Column>
			<Table.Column>{renderMinutes(plannedAllocationVsTask)}</Table.Column>
			{/* Remaining Allocation vs Task */}
			<Table.Column>{renderMinutes(remainingBillableAllocationVsTask)}</Table.Column>
			<Table.Column>{renderMinutes(remainingNonBillableAllocationVsTask)}</Table.Column>
			<Table.Column>{renderMinutes(remainingAllocationVsTask)}</Table.Column>
		</Table.Row>
	);
};

export default PeriodRow;
