import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import BaseLevelWrapper from './BaselevelWrapper';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../constants';
import GroupRow from '../rows/GroupRow';
import {ProjectIndicatorText} from 'web-components';

const ProjectRelayWrapper = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	rowIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
	convertToProjectCurrency,
	projectCurrency,
}) => {
	const projects = viewer.company.allProjects.edges;
	const currency = convertToProjectCurrency && projectCurrency ? projectCurrency : viewer.company.currency;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const hasNextPage = viewer.company.allProjects.pageInfo.hasNextPage;
	/**
	 * Grouping filter for tasks inside projects
	 *
	 * @param projectID The project ID
	 */
	const projectGroupingFilter = projectID => {
		return {
			field: GLOBAL_FILTER_FIELD.PROJECT,
			operator: GLOBAL_FILTER_OPERATOR.IS,
			value: [projectID],
		};
	};

	/**
	 * Return the project name
	 */
	const generatedProjectName = project => {
		const hasAccessToProject = project.fullAccessToProject;
		return (
			<ProjectIndicatorText
				clearSubPath={true}
				project={project}
				disableLink={project.readOnlyAccess}
				text={hasAccessToProject ? project.name : intl.formatMessage({id: 'timesheets.no_access'})}
			/>
		);
	};

	/**
	 * This will build the data rows, containing the project data, task totals and
	 * if the project is expandable.
	 */
	const dataRows = projects.map(project => {
		const hasAccessToProject = project.node.fullAccessToProject;
		return {
			...project.node,
			generatedName: generatedProjectName(project.node),
			canExpand: hasAccessToProject,
		};
	});

	const estimationUnit = rowData => rowData.estimationUnit;

	const data = {
		rows: dataRows,
		currency,
		groupingFilter: rowData => projectGroupingFilter(rowData.id),
		estimationUnit: estimationUnit,
	};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = projects.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const projectRows = () =>
		GroupRow(
			data,
			intl,
			rowIndex,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader,
			convertToProjectCurrency,
			projectCurrency
		);

	return rowIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={projectRows}
			currentLength={projects.length}
			hasNextPage={viewer.company.allProjects.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		projectRows()
	);
};

const ProjectRelayWrapperQuery = graphql`
	query ProjectRelayWrapper_Query(
		$searchQuery: TaskSearchQueryType
		$pageSize: Int
		$validateAccess: Boolean
		$convertToProjectCurrency: Boolean
	) {
		viewer {
			actualPersonId

			component(name: "project_relay_wrapper")
			...ProjectRelayWrapper_viewer
				@arguments(
					searchQuery: $searchQuery
					pageSize: $pageSize
					validateAccess: $validateAccess
					convertToProjectCurrency: $convertToProjectCurrency
				)
		}
	}
`;
export {ProjectRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			ProjectRelayWrapper,
			{
				viewer: graphql`
					fragment ProjectRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						validateAccess: {type: "Boolean"}
						convertToProjectCurrency: {type: "Boolean"}
					) {
						company {
							currency
							allProjects(first: $pageSize, searchQuery: $searchQuery, validateAccess: false) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										readOnlyAccess
										fullAccessToProject
										id
										name
										projectColor
										companyProjectId
										estimationUnit
										client {
											name
										}
										projectPersons {
											edges {
												node {
													role {
														name
													}
													person {
														fullName
														role {
															name
														}
													}
												}
											}
										}
										taskTotals(
											searchQuery: $searchQuery
											convertToProjectCurrency: $convertToProjectCurrency
										) {
											registeredMinutes
											billableActualMinutes
											nonBillableActualMinutes
											estimatePoints
											estimateMinutes
											remainingPoints
											remainingMinutes
											plannedRevenue
											actualRevenue
											remainingRevenue
											forecastRevenue
											recognitionLockedRevenue
											recognitionOpenRevenue
											recognitionForecastRevenue
											totalActualRevenueRecognition
											totalForecastRevenueToComplete
											surplus
											numberOfTasks
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query ProjectRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$convertToProjectCurrency: Boolean
				) {
					viewer {
						...ProjectRelayWrapper_viewer
							@arguments(
								pageSize: $pageSize
								searchQuery: $searchQuery
								convertToProjectCurrency: $convertToProjectCurrency
							)
					}
				}
			`
		)
	)
);
