import React, {useRef, useState} from 'react';
import {SettingsPageWrapper, BackNavigationWrapper, BackNav} from './InitialPlan.styled';
import {FormattedHTMLMessage, useIntl} from 'react-intl';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {CaretIcon, RadioButton, Switch} from 'web-components';

import {changeBaselineWinChance} from './InitialPlanLogic';
import ForecastTooltip from '../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {BUDGET_TYPE, MODULE_TYPES, PROJECT_STATUS, TASK_COST_CALCULATION_TYPE} from '../../constants';
import {PercentageInputWrapper} from '../inputs/percentage_input_wrapper';
import {
	CheckBoxDescription,
	SelectionElementLineWrapper,
	RadioWithDescriptionWrapper,
	InputDescription,
	SubSection,
	SelectionElementLabel,
} from '../../containers/project/project_settings/ProjectSettings_styled';
import {CurrencyInputWrapper} from '../inputs/currency_input_wrapper';
import RateCardDropdown, {changeRateCard} from '../dropdowns/rate-card-dropdown/Rate_card_dropdown';
import {
	changeBaselineTarget,
	changeTaskCostCalculationType,
	changeUnassignedTaskHourlyCost,
	changeBudget,
	changeBaselineMinutesPerDay,
} from './InitialPlanSettingsMutations';
import {useHistory, useLocation} from 'react-router-dom';
import HoursInput from '../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {FixedCostWrapper, FixedCostInput} from '../../containers/project/project_settings/ProjectSettings_styled';
import ProjectUtil from '../../forecast-app/shared/util/project_util';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {
	changeSynchBaselineAndScopingDates,
	changeUseFixedPriceForBaselineRevenue,
} from '../../containers/project/project_settings/ProjectSettingsMutations.js';
import Styled from 'styled-components';
import Checkbox from '../../components/new-ui/check_box';
import {FlexColumn, FlexRow, DeprecatedText as Text} from '@forecast-it/design-system';
import {HelpIcon} from 'web-components/icons';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';

const ExtraSubSection = Styled.div`
	margin-bottom: 16px;
`;

const CheckBoxContainer = Styled.div`
	margin-top: 20px;
`;
const CheckBoxHolder = Styled.div`
 width: 15px;
 margin-right: 10px;
`;

export const Settings = ({
	baselineMinutesPerDay,
	baselineTarget,
	budgetType,
	budget,
	unassignedTaskHourlyCost,
	projectDoneOrHalted,
	taskCostCalculationType,
	selectedRateCardId,
	rateCards,
	projectStage,
	company,
	project,
	currency,
	baselineWinChance,
	salesforceOpportunity,
}) => {
	const intl = useIntl();
	const timeEstimationUnitRef = useRef();
	const baselineTargetRef = useRef();
	const history = useHistory();
	const location = useLocation();
	const [winChanceError, setWinChanceError] = useState(false);
	const [useFixedPriceForBaselineRevenue, setUseFixedPriceForBaselineRevenue] = useState(
		project.useFixedPriceForBaselineRevenue
	);
	const [minutesPerDay, setMinutesPerDay] = useState(baselineMinutesPerDay);
	const [fixedPrice, setFixedPrice] = useState(budget);

	const isCompanyCurrency = currency === company.currency;
	const exchangeRate = isCompanyCurrency
		? 1
		: company.exchangeRates.edges.find(exchangeRate => exchangeRate.node.currency === currency).node.rate;

	const handleWinChanceChange = percentage => {
		if (percentage < 0 || percentage > 1) {
			setWinChanceError(true);
			return;
		}
		setWinChanceError(false);
		if (percentage !== baselineWinChance) {
			changeBaselineWinChance(percentage, project.id);
		}
	};

	const onBackClick = () => {
		history.push(`${location.pathname.substr(0, location.pathname.lastIndexOf('/'))}`);
	};

	const handleEnableBaselineTarget = () => {
		const onSuccess = () => {
			baselineTargetRef.current && baselineTargetRef.current.focus();
		};
		changeBaselineTarget(project.id, 0, onSuccess);
	};

	const handleChangeBudget = value => {
		setFixedPrice(value);
		if (value > 0) {
			changeBudget(project.id, value);
		}
	};

	const handleEnableDayEstimation = () => {
		const onSuccess = () => {
			timeEstimationUnitRef.current && timeEstimationUnitRef.current.focus();
		};
		setMinutesPerDay(8 * 60);
		changeBaselineMinutesPerDay(project.id, 8 * 60, onSuccess);
	};
	const handleChangeMinutesPerDay = value => {
		setMinutesPerDay(value * 60);
		if (value > 0) {
			changeBaselineMinutesPerDay(project.id, value * 60);
		}
	};

	const handleChangeFixedCost = (value, isProjectCurrency) => {
		if (isProjectCurrency) {
			value /= exchangeRate;
		}
		changeUnassignedTaskHourlyCost(project.id, value);
	};

	const handleCheckBoxChange = projectId => {
		setUseFixedPriceForBaselineRevenue(!useFixedPriceForBaselineRevenue);
		changeUseFixedPriceForBaselineRevenue(projectId, !useFixedPriceForBaselineRevenue);
	};

	return (
		<FlexColumn gap={'s'}>
			<BackNavigationWrapper>
				<TopHeaderBarWrapper sidePadding={0}>
					<TopHeaderBar title={intl.formatMessage({id: 'project_section.baseline'})} />
				</TopHeaderBarWrapper>
				<BackNav onClick={onBackClick}>
					<div>
						<CaretIcon size={CaretIcon.SIZE.STANDARD} color={'#535353'} direction={CaretIcon.DIRECTION.LEFT} />
					</div>
					<div>{intl.formatMessage({id: 'baseline.settings_go_back'})}</div>
				</BackNav>
			</BackNavigationWrapper>
			<CustomScrollDiv className="custom-scrollbar-div">
				<SettingsPageWrapper>
					{ProjectUtil.projectTracksRevenue(project) && (
						<SubSection title={intl.formatMessage({id: 'baseline.project_win_propability'})}>
							<div style={{width: '120px'}}>
								{!!salesforceOpportunity ? (
									<ForecastTooltip
										content={intl.formatMessage({
											id: 'baseline.probability.linked_to_salesforce_opportunity',
										})}
									>
										<PercentageInputWrapper
											disabled={true}
											hasError={winChanceError}
											value={baselineWinChance * 100}
											zeroToHundred={true}
										/>
									</ForecastTooltip>
								) : (
									<PercentageInputWrapper
										disabled={projectStage !== PROJECT_STATUS.OPPORTUNITY}
										hasError={winChanceError}
										value={baselineWinChance * 100}
										callback={handleWinChanceChange}
										zeroToHundred={true}
									/>
								)}
							</div>
							<FormattedHTMLMessage
								id={
									hasModule(MODULE_TYPES.CALC_WIN_PERCENTAGE)
										? 'project_settings.win_chance_description_calc_win'
										: 'project_settings.win_chance_description'
								}
							/>
						</SubSection>
					)}
					{ProjectUtil.projectTracksRevenue(project) &&
						budgetType !== BUDGET_TYPE.FIXED_PRICE &&
						budgetType !== BUDGET_TYPE.FIXED_PRICE_V2 && (
							<SubSection
								title={intl.formatMessage({id: 'baseline.baseline_target'})}
								description={intl.formatMessage({id: 'baseline.target_description'})}
							>
								<SelectionElementLineWrapper>
									<RadioButton
										checked={baselineTarget === null}
										onClick={() => changeBaselineTarget(project.id, null)}
									/>
									<SelectionElementLabel>
										{intl.formatMessage({id: 'baseline.no_target'})}
									</SelectionElementLabel>
								</SelectionElementLineWrapper>
								<RadioWithDescriptionWrapper>
									<SelectionElementLineWrapper>
										<RadioButton checked={baselineTarget !== null} onClick={handleEnableBaselineTarget} />
										<SelectionElementLabel>
											{intl.formatMessage({id: 'baseline.target_price'})}
										</SelectionElementLabel>
									</SelectionElementLineWrapper>
									<CheckBoxDescription>
										<CurrencyInputWrapper
											innerRef={baselineTargetRef}
											intl={intl}
											value={baselineTarget}
											callback={value => changeBaselineTarget(project.id, value)}
											currency={currency}
											width={'130px'}
											disabled={baselineTarget === null}
										/>
									</CheckBoxDescription>
								</RadioWithDescriptionWrapper>
							</SubSection>
						)}
					{ProjectUtil.projectTracksRevenue(project) &&
						(budgetType === BUDGET_TYPE.FIXED_PRICE || budgetType === BUDGET_TYPE.FIXED_PRICE_V2) && (
							<SubSection
								title={intl.formatMessage({id: 'new_project_modal.budget_type_fixed_price'})}
								description={intl.formatMessage({id: 'baseline.fixed_price_description'})}
							>
								{hasFeatureFlag('baseline_financial_service') && (
									<ExtraSubSection>
										<FlexColumn gap={'s'}>
											<Text type={'heading'} variant={'s'}>
												{intl.formatMessage({id: 'baseline.fixed_price_label'})}
											</Text>
											<Text variant={'s'} color="secondary">
												{intl.formatMessage({id: 'baseline.fixed_price_label_description'})}
											</Text>
										</FlexColumn>
									</ExtraSubSection>
								)}
								<CurrencyInputWrapper
									hasError={fixedPrice <= 0}
									intl={intl}
									value={budget}
									callback={handleChangeBudget}
									currency={currency}
									width={'130px'}
								/>
								{hasFeatureFlag('baseline_financial_service') && (
									<CheckBoxContainer>
										<FlexRow alignitems="center" gap={'s'}>
											<CheckBoxHolder>
												<Checkbox
													isChecked={useFixedPriceForBaselineRevenue}
													onChange={() => handleCheckBoxChange(project.id)}
												/>
											</CheckBoxHolder>
											<Text variant={'s'} color="primary">
												{intl.formatMessage({id: 'baseline.fixed_price_use_fixed_price_checkbox'})}
											</Text>
											<TooltipContainer
												tooltipInfinteDuration={true}
												triangleLocation={'topMiddle'}
												infoText={[
													{
														title: ['baseline.fixed_price_use_fixed_price_checkbox_tooltip_title'],
														details: [
															'baseline.fixed_price_use_fixed_price_checkbox_tooltip_content',
														],
													},
												]}
											>
												<HelpIcon />
											</TooltipContainer>
										</FlexRow>
									</CheckBoxContainer>
								)}
							</SubSection>
						)}
					{ProjectUtil.projectTracksRevenue(project) && (
						<SubSection
							title={intl.formatMessage({id: 'common.rate-card'})}
							description={intl.formatMessage({id: 'baseline.rate_card_description'})}
						>
							<div style={{width: '250px'}}>
								<RateCardDropdown
									rateCards={rateCards}
									company={company}
									project={project}
									selectedItems={selectedRateCardId ? [selectedRateCardId] : []}
									dropdownAlignment="none"
									onSelect={value => changeRateCard(project.id, value, rateCards, project)}
									onRemove={() => false}
								/>
							</div>
						</SubSection>
					)}
					{hasFeatureFlag('new_phase_date_synch_setting') && (
						<SubSection
							title={intl.formatMessage({id: 'baseline.phase_dates_setting.title'})}
							description={intl.formatMessage({id: 'baseline.phase_dates_setting.description'})}
						>
							<SelectionElementLineWrapper>
								<Switch
									checked={project.synchBaselineAndScopingDates}
									onChange={value =>
										changeSynchBaselineAndScopingDates(
											project.id,
											value,
											project.phases.edges.map(phase => phase.node)
										)
									}
								/>
								<SelectionElementLabel>
									{intl.formatMessage({id: 'baseline.phase_dates_setting.label'})}
								</SelectionElementLabel>
							</SelectionElementLineWrapper>
						</SubSection>
					)}
					<SubSection title={intl.formatMessage({id: 'baseline.time_estimation_unit'})}>
						<SelectionElementLineWrapper>
							<RadioButton
								checked={baselineMinutesPerDay === null}
								onClick={() => changeBaselineMinutesPerDay(project.id, null)}
							/>
							<SelectionElementLabel>{intl.formatMessage({id: 'common.hours'})}</SelectionElementLabel>
						</SelectionElementLineWrapper>
						<SelectionElementLineWrapper>
							<RadioButton checked={baselineMinutesPerDay !== null} onClick={handleEnableDayEstimation} />
							<SelectionElementLabel>{intl.formatMessage({id: 'common.days'})}</SelectionElementLabel>
						</SelectionElementLineWrapper>
						<InputDescription>{intl.formatMessage({id: 'baseline.days_input_description'})}</InputDescription>
						<div style={{width: '80px'}}>
							<HoursInput
								disabled={baselineMinutesPerDay === null}
								hasError={minutesPerDay <= 0}
								innerRef={timeEstimationUnitRef}
								value={baselineMinutesPerDay / 60}
								mutation={handleChangeMinutesPerDay}
								mutationValidChange
							/>
						</div>
					</SubSection>

					{ProjectUtil.projectTracksCost() && (
						<SubSection
							title={intl.formatMessage({id: 'project_settings.internal_hourly_cost_calculation'})}
							description={intl.formatMessage({id: 'baseline.internal_cost_description'})}
						>
							<RadioWithDescriptionWrapper>
								<SelectionElementLineWrapper>
									<RadioButton
										checked={
											taskCostCalculationType ===
											TASK_COST_CALCULATION_TYPE.COMPANY_AVERAGE_UNASSIGNED_TASKS
										}
										onClick={() =>
											changeTaskCostCalculationType(
												project.id,
												TASK_COST_CALCULATION_TYPE.COMPANY_AVERAGE_UNASSIGNED_TASKS
											)
										}
										disabled={projectDoneOrHalted}
									/>
									<SelectionElementLabel>
										<FormattedHTMLMessage id={'project_settings.company_averages'} />
									</SelectionElementLabel>
								</SelectionElementLineWrapper>
								<CheckBoxDescription>
									{intl.formatMessage({id: 'project_settings.company_averages_desc'})}
								</CheckBoxDescription>
							</RadioWithDescriptionWrapper>
							<RadioWithDescriptionWrapper>
								<SelectionElementLineWrapper>
									<RadioButton
										checked={
											taskCostCalculationType ===
											TASK_COST_CALCULATION_TYPE.PROJECT_TEAM_AVERAGE_UNASSIGNED_TASKS
										}
										onClick={() =>
											changeTaskCostCalculationType(
												project.id,
												TASK_COST_CALCULATION_TYPE.PROJECT_TEAM_AVERAGE_UNASSIGNED_TASKS
											)
										}
										disabled={projectDoneOrHalted}
									/>
									<SelectionElementLabel>
										<FormattedHTMLMessage id={'project_settings.project_team_averages'} />
									</SelectionElementLabel>
								</SelectionElementLineWrapper>
								<CheckBoxDescription>
									{intl.formatMessage({id: 'project_settings.project_team_averages_desc'})}
								</CheckBoxDescription>
							</RadioWithDescriptionWrapper>
							<RadioWithDescriptionWrapper>
								<SelectionElementLineWrapper>
									<RadioButton
										checked={taskCostCalculationType === TASK_COST_CALCULATION_TYPE.FIXED_UNASSIGNED_TASKS}
										onClick={() =>
											changeTaskCostCalculationType(
												project.id,
												TASK_COST_CALCULATION_TYPE.FIXED_UNASSIGNED_TASKS
											)
										}
										disabled={projectDoneOrHalted}
									/>
									<SelectionElementLabel>
										<FormattedHTMLMessage id={'project_settings.set_fixed_internal_cost'} />
									</SelectionElementLabel>
								</SelectionElementLineWrapper>
								<CheckBoxDescription>
									<FixedCostWrapper>
										<FixedCostInput>
											<CurrencyInputWrapper
												intl={intl}
												value={unassignedTaskHourlyCost}
												callback={value => handleChangeFixedCost(value, false)}
												currency={company.currency}
												width={'160px'}
												disabled={
													projectDoneOrHalted ||
													taskCostCalculationType !==
														TASK_COST_CALCULATION_TYPE.FIXED_UNASSIGNED_TASKS
												}
												label={`${intl.formatMessage({
													id: 'common.company_currency',
												})} (${company.currency})`}
											/>
										</FixedCostInput>
										{!isCompanyCurrency && (
											<FixedCostInput>
												<CurrencyInputWrapper
													intl={intl}
													value={unassignedTaskHourlyCost * exchangeRate}
													callback={value => handleChangeFixedCost(value, true)}
													currency={currency}
													width={'160px'}
													disabled={
														projectDoneOrHalted ||
														taskCostCalculationType !==
															TASK_COST_CALCULATION_TYPE.FIXED_UNASSIGNED_TASKS
													}
													label={`${intl.formatMessage({
														id: 'common.project_currency',
													})} (${currency})`}
												/>
											</FixedCostInput>
										)}
									</FixedCostWrapper>
								</CheckBoxDescription>
							</RadioWithDescriptionWrapper>
						</SubSection>
					)}
				</SettingsPageWrapper>
			</CustomScrollDiv>
		</FlexColumn>
	);
};
