import React, {useEffect, useState} from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import TdLink from '../../../../td_link';
import Dropdown from '../../../../../forecast-app/shared/components/dropdowns/dropdown';
import PersonInitials from '../../../../../forecast-app/shared/components/person/person_initials';
import {INITIALS_SIZE, PROJECT_STATUS} from '../../../../../constants';
import Util from '../../../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import UpdateProjectPersonMutation from '../../../../../mutations/update_project_person_mutation';
import ActionsMenu from '../../../../../forecast-app/shared/components/action-menu/actions_menu';
import {hasPermission} from '../../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../../Permissions';
import {DeprecatedIcon} from '@forecast-it/design-system';
import ForecastTooltip from '../../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {profilePicSrc} from '../../../../../directApi';

const RatesAndInternalCostPage = props => {
	const {projectPerson, project, company, enabledRoles, disabledRoleIds, canViewInternalCostRate} = props;
	const {formatMessage, formatNumber} = props.intl;
	const {person} = projectPerson;
	const isDoneOrHalted = project.status === PROJECT_STATUS.DONE || project.status === PROJECT_STATUS.HALTED;
	const hasProjectPersonUpdatePermission = hasPermission(PERMISSION_TYPE.PROJECT_PERSON_UPDATE);
	const isAdmin = hasPermission(PERMISSION_TYPE.MANAGE_ACCOUNT_SETTINGS);
	const isRoleDisabled = disabledRoleIds.includes(projectPerson.role?.id);

	const currentCostPeriod = Util.getCurrentCostPeriod(person.costPeriods.edges);
	const [cost, setCost] = useState(currentCostPeriod ? currentCostPeriod.node.cost : null);

	const [showActionMenu, setShowActionMenu] = useState(false);

	useEffect(() => {
		if (currentCostPeriod) {
			setCost(currentCostPeriod.node.cost);
		}
	}, [currentCostPeriod]);

	const getLinkPath = () => {
		if (person.isViewer) {
			return '/my-profile/account-configuration';
		} else {
			return '/admin/team/view-user/' + person.id;
		}
	};

	const onRoleChange = role => {
		if (!projectPerson || !role) return;

		// Prevent if the role is the same
		if (projectPerson.role && projectPerson.role.id === role.value) return;

		Util.CommitMutation(UpdateProjectPersonMutation, {
			id: projectPerson.id,
			roleId: role.value,
		});
	};

	const showInternalCostPeriodsModal = () => {
		showModal({
			type: MODAL_TYPE.COST_PERIODS,
			personId: person.id,
		});
	};

	const getActionsMenuOptions = () => {
		return [{text: formatMessage({id: 'settings_people.internal_cost_periods'}), onClick: showInternalCostPeriodsModal}];
	};

	const getFormattedNumberWithCurrency = (number, isCompanyCurrency) => {
		const currencySymbol = Util.GetCurrencySymbol(
			project.rateCard && !isCompanyCurrency ? project.rateCard.currency : company.currency
		);

		return Util.getFormattedCurrencyValue(
			currencySymbol,
			formatNumber(Math.round(number > -0.005 && number < 0 ? 0 : number * 100) / 100, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}) //compare the value with 0.005 to prevent math.round to return -0
		);
	};

	const getRoleOptions = () => {
		const options = enabledRoles.map(role => {
			return {label: role.node.name, value: role.node.id};
		});
		if (projectPerson.role && disabledRoleIds.includes(projectPerson.role.id)) {
			options.push({label: projectPerson.role.name, value: projectPerson.role.id, disabled: true});
		}
		return options;
	};

	const companyCurrency = company.currency;
	const projectCurrency = project.rateCard ? project.rateCard.currency : null;

	let exchangedRateCost = cost;

	if (companyCurrency !== projectCurrency) {
		const exchangeRate = company.exchangeRates.edges
			.map(edge => edge.node)
			.find(exchangeRateEdge => exchangeRateEdge.currency === projectCurrency);
		exchangedRateCost = exchangeRate ? exchangedRateCost * exchangeRate.rate : exchangedRateCost;
	}
	return (
		<tr
			key={person.id}
			onMouseEnter={() => {
				setShowActionMenu(true);
			}}
			onMouseLeave={() => {
				setShowActionMenu(false);
			}}
			className="row-member"
		>
			<TdLink
				className="person person-width"
				path={getLinkPath(person)}
				locked={!isAdmin}
				content={
					<div className="wrapper">
						<div className="image-container">
							<div className="profile-picture">
								{person.profilePictureId ? (
									<img
										crossOrigin="use-credentials"
										src={profilePicSrc(person.profilePictureId)}
										width="36"
										height="36"
										alt=""
									/>
								) : (
									<PersonInitials initials={person.initials} size={INITIALS_SIZE.EXTRA_SMALL} />
								)}
							</div>
						</div>
						<div className="name-tile">
							<h3 className="name">{person.fullName}</h3>
						</div>
					</div>
				}
			/>

			<td className="role-width">
				{isRoleDisabled && (
					<ForecastTooltip content={<FormattedHTMLMessage id="project_person.deactivated_role" />}>
						<div className="icon-wrapper">
							<DeprecatedIcon icon="warning" size="m" color="error" />
						</div>
					</ForecastTooltip>
				)}
				<Dropdown
					focusOnClick={true}
					optionClickEvent={true}
					onChange={role => {
						onRoleChange(role);
					}}
					options={getRoleOptions()}
					value={projectPerson.role ? projectPerson.role.id : ''}
					hideLabel={true}
					disabled={isDoneOrHalted || !hasProjectPersonUpdatePermission}
				/>
			</td>

			{isAdmin && canViewInternalCostRate ? (
				<td
					onClick={() => {
						if (!isDoneOrHalted && isAdmin) {
							showInternalCostPeriodsModal();
						}
					}}
					className={'cost cost-modal-link' + (isDoneOrHalted || !isAdmin ? ' disabled' : '')}
					data-cy="budget-internal-hourly-cost-column"
				>
					{getFormattedNumberWithCurrency(currentCostPeriod ? currentCostPeriod.node.cost : 0, true)}
					{project.rateCard && companyCurrency !== projectCurrency ? (
						<div className="exchanged-rate-cost">{`/ ${getFormattedNumberWithCurrency(
							exchangedRateCost,
							false
						)}`}</div>
					) : null}
				</td>
			) : null}

			{isAdmin && canViewInternalCostRate ? (
				<td className="person-action-menu controls-width">
					{isDoneOrHalted ? null : (
						<ActionsMenu
							customClass={showActionMenu ? '' : 'hidden'}
							disabled={false}
							customWidth={20}
							whiteInner={true}
							isWhite={false}
							options={getActionsMenuOptions()}
						/>
					)}
				</td>
			) : null}
		</tr>
	);
};

export default injectIntl(RatesAndInternalCostPage);
