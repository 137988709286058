import {
	createOrUpdateMultiplePlaceholderAllocations,
	createOrUpdatePlaceholder,
	createOrUpdatePlaceholderAllocation,
	deleteMultiplePlaceholderAllocations,
	deletePlaceholder,
	deletePlaceholderAllocation,
} from './PlaceholdersSchedulingSuccessLogic';
import {isEventFromScheduling} from '../utils';

export const onSavedFiltersUpdate = (pageComponent, response) => {
	const stateData = pageComponent.getData();

	if (response.createFilter) {
		stateData.placeholderFilters.push(response.createFilter.filter.node);
	} else if (response.deleteFilter) {
		stateData.placeholderFilters = stateData.placeholderFilters.filter(
			filter => filter.id !== response.deleteFilter.deletedFilterId
		);
	}

	pageComponent.setState({data: stateData});
};

export const handlePlaceholdersSchedulingMutation = (pageComponent, response, args) => {
	if (!isEventFromScheduling(args)) {
		return;
	}

	if (response.splitPlaceholderAllocation || response.bulkPlaceholderAllocations) {
		const placeholderAllocations =
			response.splitPlaceholderAllocation?.placeholderAllocations ||
			response.bulkPlaceholderAllocations.placeholderAllocations;
		createOrUpdateMultiplePlaceholderAllocations(pageComponent, placeholderAllocations);
	} else if (
		response.createPlaceholderAllocation ||
		response.updatePlaceholderAllocation ||
		response.duplicatePlaceholderAllocation
	) {
		const operation =
			response.createPlaceholderAllocation ||
			response.updatePlaceholderAllocation ||
			response.duplicatePlaceholderAllocation;
		const placeholderAllocation = operation.placeholderAllocation.node || operation.placeholderAllocation;
		createOrUpdatePlaceholderAllocation(pageComponent, placeholderAllocation);
	} else if (response.deletePlaceholderAllocation) {
		deletePlaceholderAllocation(pageComponent, response.deletePlaceholderAllocation.deletedAllocationId);
	} else if (response.createPlaceholder || response.updatePlaceholder || response.duplicatePlaceholder) {
		const operation = response.updatePlaceholder || response.createPlaceholder || response.duplicatePlaceholder;
		const responsePlaceholder = operation.placeholder.node;
		createOrUpdatePlaceholder(pageComponent, responsePlaceholder);
	} else if (response.deletePlaceholder) {
		const deletedPlaceholderId = response.deletePlaceholder.deletedPlaceholderId;
		deletePlaceholder(pageComponent, deletedPlaceholderId);
	} else if (response.replacePlaceholder) {
		const placeholderId = response.replacePlaceholder.placeholderId;
		const shouldDeletePlaceholder = response.replacePlaceholder.deletePlaceholder;
		const deletedPlaceholderAllocationIds = response.replacePlaceholder.deletedPlaceholderAllocationIds;

		if (shouldDeletePlaceholder) {
			deletePlaceholder(pageComponent, placeholderId);
		} else {
			deleteMultiplePlaceholderAllocations(pageComponent, deletedPlaceholderAllocationIds);
		}
	} else if (response.transferPlaceholder) {
		if (response.transferPlaceholder.placeholderAllocation) {
			createOrUpdatePlaceholderAllocation(pageComponent, response.transferPlaceholder.placeholderAllocation);
		} else if (response.transferPlaceholder.deletedPlaceholderAllocationId) {
			deletePlaceholderAllocation(pageComponent, response.transferPlaceholder.deletedPlaceholderAllocationId);
		}
	} else if (response.assignPlaceholderAllocationsToPerson) {
		const {deletedPlaceholderAllocationIds, placeholderWasDeleted, placeholderId} =
			response.assignPlaceholderAllocationsToPerson;

		deleteMultiplePlaceholderAllocations(pageComponent, deletedPlaceholderAllocationIds);

		if (placeholderWasDeleted) {
			deletePlaceholder(pageComponent, placeholderId);
		}
	}

	pageComponent.redrawCanvasTimeline({preventFiltering: false});

	requestAnimationFrame(() => {
		pageComponent.redrawCanvasTimeline({preventFiltering: false});
	});
};

export const onSchedulingMutationSuccess = (pageComponent, response, args) => {
	if (!isEventFromScheduling(args)) {
		return;
	}

	const data = pageComponent.getData();

	if (!data) {
		pageComponent.mutationResponse = response;
		pageComponent.setState({savingMutation: true});
		return;
	}

	handlePlaceholdersSchedulingMutation(pageComponent, response, args);
};
