import PlaceholderAllocationDetailBox from '../components/placeholder_allocation_detail_box';
import PlaceholderInformationTooltip from '../components/placeholder_information_tooltip';
import React from 'react';
import ActionMenu from '../action_menu';
import ContextMenu from '../../../forecast-app/shared/components/context-menus/context_menu';
import AllocationDetailBox from '../components/allocation_detail_box';
import HeatmapCellDetailBox, {getHeatmapCellDetailBoxCoords} from '../components/heatmap_cell_detail_box';
import {HEATMAP_CELL_DETAIL_BOX_HEIGHT, HEATMAP_CELL_DETAIL_BOX_WIDTH} from '../canvas-timeline/canvas_timeline_util';
import CanvasTooltip from '../components/CanvasTooltip';
import EventManager from '../EventManager';

export default ({pageComponent}) => {
	const {
		data,
		showDetailBox,
		detailBoxData,
		detailBoxX,
		detailBoxY,
		showPlaceholderInformation,
		placeholderInformationData,
		placeholderInformationY,
		placeholderInformationX,
		showCollapsedActionMenu,
		showExpandedActionMenu,
		collapsedActionMenuX,
		collapsedActionMenuY,
		expandedActionMenuX,
		expandedActionMenuY,
		actionMenuOptions,
		contextMenuX,
		contextMenuY,
		contextMenuOptions,
		staffingModeActive,
		showCanvasTooltip,
		canvasTooltipData,
		canvasTooltipX,
		canvasTooltipY,
		schedulingOptions,
	} = pageComponent.state;

	// heatmap cell detail box
	const {
		heatmapCellDetailBoxX,
		heatmapCellDetailBoxY,
		showHeatmapDetailBoxRight,
		showHeatmapDetailBoxCenter,
		showHeatmapDetailBoxBottom,
	} = getHeatmapCellDetailBoxCoords(detailBoxData?.heatmapData);

	return (
		<div className={'hover-effects-container' + (staffingModeActive ? ' staffing-mode' : '')}>
			{showCollapsedActionMenu && (!showExpandedActionMenu || collapsedActionMenuY !== expandedActionMenuY) ? (
				<div
					className="placeholders-scheduling-actions-menu"
					style={{top: collapsedActionMenuY, left: collapsedActionMenuX}}
				>
					<ActionMenu
						options={actionMenuOptions}
						onExpansionToggle={() => EventManager.onExpandActionMenu(pageComponent)}
						expanded={false}
						useGreyIcon={true}
					/>
				</div>
			) : null}
			{showExpandedActionMenu ? (
				<div
					className="placeholders-scheduling-actions-menu"
					style={{top: expandedActionMenuY, left: expandedActionMenuX}}
				>
					<ActionMenu
						options={actionMenuOptions}
						onExpansionToggle={() => EventManager.hideExpandedActionMenu(pageComponent)}
						expanded={true}
						useGreyIcon={true}
					/>
				</div>
			) : null}
			{showDetailBox && (detailBoxData.placeholderAllocation || detailBoxData.allocation) ? (
				<div
					className="placeholders-scheduling-detail-box"
					style={{
						left: detailBoxX + 200 < window.innerWidth ? detailBoxX + 10 : detailBoxX - 235,
						top: detailBoxY + 350 < window.innerHeight ? detailBoxY - 5 : 'unset',
						bottom: detailBoxY + 350 < window.innerHeight ? 'unset' : window.innerHeight - detailBoxY - 40,
					}}
				>
					{detailBoxData.placeholderAllocation ? (
						<PlaceholderAllocationDetailBox
							placeholderAllocation={detailBoxData.placeholderAllocation}
							project={detailBoxData.project}
							projectGroup={detailBoxData.projectGroup}
							globalData={data}
							positionX={detailBoxX}
							positionY={detailBoxY}
							schedulingOptions={schedulingOptions}
						/>
					) : (
						<AllocationDetailBox
							pageComponent={pageComponent}
							allocation={detailBoxData.allocation}
							globalData={data}
							positionX={detailBoxX}
							positionY={detailBoxY}
							schedulingOptions={schedulingOptions}
						/>
					)}
				</div>
			) : showDetailBox && detailBoxData.heatmapData ? (
				<div
					className="heatmap-cell-detail-box"
					style={{
						width: HEATMAP_CELL_DETAIL_BOX_WIDTH,
						height: HEATMAP_CELL_DETAIL_BOX_HEIGHT,
						left: heatmapCellDetailBoxX,
						top: heatmapCellDetailBoxY,
					}}
				>
					<HeatmapCellDetailBox
						heatmapData={detailBoxData.heatmapData}
						globalData={data}
						positionX={detailBoxX}
						positionY={detailBoxY}
						showCenter={showHeatmapDetailBoxCenter}
						showBottom={showHeatmapDetailBoxBottom}
						showRight={showHeatmapDetailBoxRight}
						isPlaceholdersScheduling
					/>
				</div>
			) : null}
			{showPlaceholderInformation && placeholderInformationData?.placeholder && (
				<PlaceholderInformationTooltip
					placeholder={placeholderInformationData.placeholder}
					top={placeholderInformationY}
					left={placeholderInformationX}
				/>
			)}
			{contextMenuX && contextMenuY && contextMenuOptions && contextMenuOptions.length ? (
				// we are setting the key to be the sum of the coordinates because this will cause a rerender of the context menu if the user right clicks on another item
				// solves the issue of the context menu not updating the position
				<ContextMenu
					key={contextMenuX + contextMenuY}
					cy="canvas-placeholders-scheduling-context-menu"
					options={contextMenuOptions}
					contextMenuPosition={{x: contextMenuX, y: contextMenuY}}
				/>
			) : null}
			{showCanvasTooltip && canvasTooltipData && (
				<CanvasTooltip
					mouseData={{x: canvasTooltipX, y: canvasTooltipY}}
					data={canvasTooltipData}
					pageComponent={pageComponent}
				/>
			)}
		</div>
	);
};
