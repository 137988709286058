import React from 'react';
import {Title} from './MonthByMonthTitle.styled';
import {useIntl} from 'react-intl';

export const MonthByMonthTitle = ({showResourceUtilization}) => {
	const intl = useIntl();
	return (
		<Title>
			{showResourceUtilization
				? intl.formatMessage({id: 'common.forecast_resource_utilization'})
				: intl.formatMessage({id: 'common.forecast_billable_utilization'})}
		</Title>
	);
};
