import React from 'react';

const settingsIcon = props => (
	<div className={props.className} title={props.title ? props.title : ''}>
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
			<path
				fill="#A1A1A1"
				fillRule="evenodd"
				d="M8 10.667A2.67 2.67 0 0 1 5.333 8 2.67 2.67 0 0 1 8 5.333 2.67 2.67 0 0 1 10.667 8 2.67 2.67 0 0 1 8 10.667zm8-1.334V6.667h-2.856a5.22 5.22 0 0 0-.567-1.359L14.6 3.285 12.715 1.4l-2.023 2.023a5.22 5.22 0 0 0-1.359-.567V0H6.667v2.856a5.22 5.22 0 0 0-1.359.567L3.285 1.4 1.4 3.285l2.023 2.023a5.22 5.22 0 0 0-.567 1.359H0v2.666h2.856c.125.484.317.939.567 1.359L1.4 12.715 3.285 14.6l2.023-2.023c.42.25.875.442 1.359.567V16h2.666v-2.856a5.22 5.22 0 0 0 1.359-.567l2.023 2.023 1.885-1.885-2.023-2.023c.25-.42.442-.875.567-1.359H16z"
			/>
		</svg>
	</div>
);

export default settingsIcon;
