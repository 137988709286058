import {getVisualizationMode, GROUP_TYPE, VISUALIZATION_MODE} from '../canvas-timeline/canvas_timeline_util';
import {GROUP_BY} from './people_scheduling_header';
import {
	filterGroupsItems,
	filterGroupsItemsCached,
	isFilteredByIdleTimes,
	isPersonFiltered,
	isPersonFilteredNew,
	isProjectFilteredCached,
	isTaskFiltered,
	isTaskItemProjectFilteredCached,
} from '../FilterUtils';
import {getProjectIdFromProjectGroup} from './PeopleSchedulingUtils';
import Util from '../../../forecast-app/shared/util/util';
import {isTaskItem} from '../SchedulingUtils';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import RecalculationManager from '../RecalculationManager';
import {TOTAL_RESOURCE_UTILIZATION_GROUP_ID} from '../IDManager';
import DataManager from '../DataManager';

const isFilteredBySearch = (pageComponent, group) => {
	const {searchFilterValue} = pageComponent.state;

	let visibleBySearch = true;
	if (searchFilterValue?.length > 0) {
		const name = group.data.name || '';
		const roleName = group.data.roleName || '';
		const origin = name + roleName;
		visibleBySearch = Util.normalizedIncludes(origin, searchFilterValue);
	}

	return !visibleBySearch;
};

const filterTotalUtilizationGroup = pageComponent => {
	const {groups, eyeOptions} = pageComponent.state;

	const companyUtilizationGroup = groups.find(group => group.groupType === GROUP_TYPE.TOTAL_RESOURCE_UTILIZATION);
	if (companyUtilizationGroup) {
		const totalResourceUtilizationEyeOption = eyeOptions?.find(
			option => option.name === TOTAL_RESOURCE_UTILIZATION_GROUP_ID
		);

		const shouldRecalculate = companyUtilizationGroup.filtered && totalResourceUtilizationEyeOption?.checked === true;
		companyUtilizationGroup.filtered = !totalResourceUtilizationEyeOption?.checked;

		if (shouldRecalculate) {
			DataManager.recalculateDrawnStepsForNewGroup(pageComponent, companyUtilizationGroup);
		}
	}
};

const filterPersonGroups = (pageComponent, personGroups) => {
	if (personGroups?.length > 0) {
		const {items} = pageComponent.state;

		const projectFilteredCache = new Map();
		const isItemFiltered = item =>
			isTaskItemProjectFilteredCached(pageComponent, projectFilteredCache, item) ||
			isFilteredByIdleTimes(pageComponent, item);

		personGroups.forEach(personGroup => {
			const isPersonGroupFiltered =
				isPersonFiltered(pageComponent, personGroup) || isFilteredBySearch(pageComponent, personGroup);

			personGroup.groups?.forEach(personSubGroup => {
				if (personSubGroup.groupType === GROUP_TYPE.PROJECT) {
					personSubGroup.filtered =
						isPersonGroupFiltered ||
						isProjectFilteredCached(
							pageComponent,
							projectFilteredCache,
							getProjectIdFromProjectGroup(personSubGroup)
						);
				} else {
					personSubGroup.filtered = isPersonGroupFiltered;
				}
			});

			filterGroupsItems(pageComponent, items, personGroup.groups, isItemFiltered);

			personGroup.filtered = isPersonGroupFiltered;
		});
	}
};

const filterPersonGroupsCached = (pageComponent, personGroups) => {
	if (personGroups?.length > 0) {
		const {items} = pageComponent.state;

		const projectFilteredCache = new Map();
		const itemsGroupFilteredCache = new Map();

		for (let i = 0; i < personGroups.length; i++) {
			const personGroup = personGroups[i];

			const isPersonGroupFiltered =
				isPersonFilteredNew(pageComponent, personGroup) || isFilteredBySearch(pageComponent, personGroup);

			for (let j = 0; j < personGroup.groups.length; j++) {
				const personSubGroup = personGroup.groups[j];

				if (personSubGroup.groupType === GROUP_TYPE.PROJECT) {
					personSubGroup.filtered =
						isPersonGroupFiltered ||
						isProjectFilteredCached(
							pageComponent,
							projectFilteredCache,
							getProjectIdFromProjectGroup(personSubGroup)
						);
					personSubGroup.groups?.forEach(group => itemsGroupFilteredCache.set(group.id, personSubGroup.filtered));
				} else {
					personSubGroup.filtered = isPersonGroupFiltered;
				}

				itemsGroupFilteredCache.set(personSubGroup.id, personSubGroup.filtered);
			}

			personGroup.filtered = isPersonGroupFiltered;
		}

		const isItemFiltered = item =>
			isTaskItemProjectFilteredCached(pageComponent, projectFilteredCache, item) ||
			isFilteredByIdleTimes(pageComponent, item);

		filterGroupsItemsCached(pageComponent, items, itemsGroupFilteredCache, isItemFiltered);
	}
};

const filterUnassignedTaskGroups = pageComponent => {
	const {collapsableSectionGroups, items, heatmapFiltering} = pageComponent.state;

	if (collapsableSectionGroups) {
		const unassignedGroupIdsFilteredMap = new Map();
		for (const unassignedRoleGroup of collapsableSectionGroups) {
			for (const unassignedRoleTaskGroup of unassignedRoleGroup.groups) {
				unassignedGroupIdsFilteredMap.set(unassignedRoleTaskGroup.id, unassignedRoleGroup);
			}
		}

		const nonFilteredTaskGroupIds = new Set();
		const projectFilteredCache = new Map();

		for (const item of items) {
			if (isTaskItem(item) && unassignedGroupIdsFilteredMap.has(item.groupId)) {
				const filtered =
					isProjectFilteredCached(pageComponent, projectFilteredCache, item.data?.task?.projectId) ||
					isTaskFiltered(pageComponent, item);
				if (!item.filtered !== !filtered && heatmapFiltering) {
					RecalculationManager.recalculateItemHeatmap(item);
				}
				item.filtered = filtered;
				if (!item.filtered) {
					nonFilteredTaskGroupIds.add(item.groupId);
				}
			}
		}

		for (let [groupId, group] of unassignedGroupIdsFilteredMap) {
			group.filtered = !nonFilteredTaskGroupIds.has(groupId);
		}
	}
};

export const filterGroupsAndItems = pageComponent => {
	const {groups, groupBy} = pageComponent.state;
	const data = pageComponent.getFilterData();
	const {company} = data;
	const isUsingProjectAllocation = getVisualizationMode(
		pageComponent.state.schedulingOptions,
		company,
		VISUALIZATION_MODE.ALLOCATION
	);
	const useImprovedFiltering = hasFeatureFlag('improving_heatmap_frontend_performance');

	if (!isUsingProjectAllocation) {
		filterUnassignedTaskGroups(pageComponent);
		pageComponent.clearCachedUnassignedTasksCount();
	}

	filterTotalUtilizationGroup(pageComponent);

	if (groupBy === GROUP_BY.NONE) {
		const personGroups = groups.filter(group => group.groupType === GROUP_TYPE.PERSON);

		if (useImprovedFiltering) {
			filterPersonGroupsCached(pageComponent, personGroups);
		} else {
			filterPersonGroups(pageComponent, personGroups);
		}
	} else {
		const groupingGroups = groups.filter(group => group.groupType === GROUP_TYPE.PERSON_GROUPING_GROUP);
		groupingGroups?.forEach(groupingGroup => {
			if (useImprovedFiltering) {
				filterPersonGroupsCached(pageComponent, groupingGroup.groups);
			} else {
				filterPersonGroups(pageComponent, groupingGroup.groups);
			}

			const hasUnfilteredPersonGroup = groupingGroup.groups?.find(group => !group.filtered);
			groupingGroup.filtered = !hasUnfilteredPersonGroup;
		});
	}
};
