import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DeleteFileMutation from '../mutations/delete_file_mutation.modern';
import UpdateFileMutation from '../mutations/update_file_mutation.modern';
import {fileExtensions} from '../constants';
import {injectIntl} from 'react-intl';
import {createToast} from '../forecast-app/shared/components/toasts/another-toast/toaster';
import ActionMenu from '../forecast-app/shared/components/action-menu/actions_menu';
import {MODAL_TYPE, showModal} from '../forecast-app/shared/components/modals/generic_modal_conductor';
import Util from '../forecast-app/shared/util/util';
import DirectApi from '../directApi';

class FilePreview extends Component {
	constructor(props) {
		super(props);

		this.nameInput = React.createRef();

		this.state = {
			isRenaming: false,
			fileName: this.props.file.node.name,
		};
	}

	showRenameFileInput() {
		this.setState({isRenaming: true}, () => this.nameInput.current.select());
	}

	getFileActionMenuOptions() {
		const {
			intl: {formatMessage},
		} = this.props;

		const options = [];

		options.push({
			text: formatMessage({id: 'common.rename'}),
			onClick: this.showRenameFileInput.bind(this),
		});

		options.push({
			text: formatMessage({id: 'common.delete'}),
			onClick: this.deleteFile.bind(this),
		});

		return options;
	}

	deleteFile() {
		if (this.props.file.node.id) {
			const onSuccess = () => {
				createToast({duration: 5000, message: this.props.intl.formatMessage({id: 'file.has-been-deleted'})});
			};
			const obj = {
				files: [this.props.file.node.id],
			};
			if (this.props.taskId) {
				obj.taskId = this.props.taskId;
			}
			if (this.props.expenseItemId) {
				obj.expenseItemId = this.props.expenseItemId;
			}
			Util.CommitMutation(DeleteFileMutation, obj, onSuccess);
		} else if (this.props.removeLocalFile) {
			// local read file
			this.props.removeLocalFile(this.props.index);
		}
	}

	enlarge() {
		const file = this.props.file.node.id ? DirectApi.fileSrc(this.props.file.node.id) : this.props.file.node.src;
		showModal({
			type: MODAL_TYPE.EXPAND_FILE,
			file,
			files: this.props.files
				? this.props.files
						.filter(edge => Util.FileHasPreview(edge.node))
						.map(edge => (edge.node.id ? DirectApi.fileSrc(edge.node.id) : edge.node.src))
				: [file],
		});
	}

	handleInputChange(e) {
		this.setState({fileName: e.target.value});
	}

	renameFile() {
		const {file, index, renameLocalFile} = this.props;
		const {fileName} = this.state;
		if (fileName.length) {
			if (file.node.id) {
				const fileId = file.node.id;
				Util.CommitMutation(UpdateFileMutation, {
					ids: [fileId],
					name: fileName,
				});
			} else {
				if (renameLocalFile) {
					renameLocalFile(index, fileName);
				}
			}
		} else {
			this.setState({fileName: file.name.split('.').shift()});
		}
		this.setState({isRenaming: false});
	}

	handleKeyPress(e) {
		if (e && e.key === 'Enter') {
			this.renameFile();
		}
	}

	render() {
		const file = this.props.file.node;
		const {isRenaming, fileName} = this.state;
		const hasPreview = Util.FileHasPreview(file);

		return (
			<div className="file-preview">
				<div className={'image-preview'}>
					{hasPreview ? (
						<img
							crossOrigin="use-credentials"
							className="preview"
							src={file.id ? DirectApi.fileSrc(file.id) : file.src}
							alt="file-preview"
						/>
					) : (
						<div
							className={
								fileExtensions.includes(fileName.toLowerCase().split('.').pop())
									? 'file-format ' + fileName.toLowerCase().split('.').pop()
									: 'file-format file'
							}
						>
							<span>{file.mimeType}</span>
						</div>
					)}
					{hasPreview ? (
						<div className="file-options">
							<div className="file-option-container" onClick={this.enlarge.bind(this)}>
								<div className="full-screen-icon" />
							</div>
						</div>
					) : null}
				</div>
				<div className="file-name">
					{isRenaming ? (
						<input
							ref={this.nameInput}
							onKeyPress={this.handleKeyPress.bind(this)}
							onChange={this.handleInputChange.bind(this)}
							onBlur={this.renameFile.bind(this)}
							type="text"
							value={fileName}
						/>
					) : (
						<>
							<a
								className="download"
								href={file.id ? DirectApi.fileSrc(file.id) + '?1111' : file.src}
								download={fileName}
							>
								<div className="download-icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18">
										<path
											fill="#292929"
											fillRule="evenodd"
											d="M6.3 0h1.8v9.527l2.964-2.963 1.273 1.272L7.2 12.972 2.064 7.836l1.273-1.272L6.3 9.527V0zm6.3 14.4h1.8v1.8c0 .993-.807 1.8-1.8 1.8H1.8C.807 18 0 17.193 0 16.2v-1.8h1.8v1.8h10.8v-1.8z"
										/>
									</svg>
								</div>
							</a>
							<p data-id={file.id} data-cy="file-name">{`${fileName}`}</p>
						</>
					)}
					{!isRenaming && !this.props.readOnly ? (
						<ActionMenu disabled={false} options={this.getFileActionMenuOptions()} whiteInner={true} />
					) : null}
				</div>
			</div>
		);
	}
}

FilePreview.propTypes = {
	file: PropTypes.object.isRequired,
	files: PropTypes.array,
	taskId: PropTypes.string,
	expenseItemId: PropTypes.string,
	setAsTaskCover: PropTypes.func,
	isCover: PropTypes.bool,
	locked: PropTypes.bool,
	readOnly: PropTypes.bool,
	removeLocalFile: PropTypes.func,
};

export default injectIntl(FilePreview);
