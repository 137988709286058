/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MonthByMonthPersonLoader_viewer$ref: FragmentReference;
declare export opaque type MonthByMonthPersonLoader_viewer$fragmentType: MonthByMonthPersonLoader_viewer$ref;
export type MonthByMonthPersonLoader_viewer = {|
  +company: ?{|
    +resourceBasedPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
          +aggregatedResourceNumbers: $ReadOnlyArray<?string>,
        |}
      |}>
    |}
  |},
  +$refType: MonthByMonthPersonLoader_viewer$ref,
|};
export type MonthByMonthPersonLoader_viewer$data = MonthByMonthPersonLoader_viewer;
export type MonthByMonthPersonLoader_viewer$key = {
  +$data?: MonthByMonthPersonLoader_viewer$data,
  +$fragmentRefs: MonthByMonthPersonLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "company",
          "resourceBasedPersons"
        ]
      }
    ]
  },
  "name": "MonthByMonthPersonLoader_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": "resourceBasedPersons",
          "args": [
            {
              "kind": "Literal",
              "name": "aggregateLevel",
              "value": "MONTH"
            },
            {
              "kind": "Literal",
              "name": "aggregates",
              "value": [
                "forecastBillableUtilization",
                "forecastBillableUtilizationWin",
                "forecastBillableUtilizationSoft",
                "forecastBillableUtilizationSoftWin",
                "forecastBillableUtilizationHard",
                "forecastResourceUtilization",
                "forecastResourceUtilizationWin",
                "forecastResourceUtilizationSoft",
                "forecastResourceUtilizationSoftWin",
                "forecastResourceUtilizationHard",
                "forecastBillableUtilizationTaskAndAllocationsCombined",
                "forecastBillableUtilizationTaskAndAllocationsCombinedWin",
                "forecastBillableUtilizationTaskAndAllocationsCombinedSoft",
                "forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin",
                "forecastBillableUtilizationTaskAndAllocationsCombinedHard",
                "forecastResourceUtilizationTaskAndAllocationsCombined",
                "forecastResourceUtilizationTaskAndAllocationsCombinedWin",
                "forecastResourceUtilizationTaskAndAllocationsCombinedSoft",
                "forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin",
                "forecastResourceUtilizationTaskAndAllocationsCombinedHard"
              ]
            },
            {
              "kind": "Variable",
              "name": "endDay",
              "variableName": "endDay"
            },
            {
              "kind": "Variable",
              "name": "endMonth",
              "variableName": "endMonth"
            },
            {
              "kind": "Variable",
              "name": "endYear",
              "variableName": "endYear"
            },
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            },
            {
              "kind": "Variable",
              "name": "startDay",
              "variableName": "startDay"
            },
            {
              "kind": "Variable",
              "name": "startMonth",
              "variableName": "startMonth"
            },
            {
              "kind": "Variable",
              "name": "startYear",
              "variableName": "startYear"
            }
          ],
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_resourceBasedPersons_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "aggregatedResourceNumbers",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '58e05b2faf9f97eef4675e2c0c6a74ee';

module.exports = node;
