import {removeFromArray} from '../utils';
import PlaceholderAllocationItem from '../components/items/placeholder_allocation_item';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import NonProjectTimeGroup from '../components/groups/non_project_time_group';
import {getProjectIdFromGroup} from './CanvasCapacityOverviewDataUtil';
import {adjustTotalMinutesMap} from '../placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import {getProject, getProjectGroup, isTimeOffAllocation} from '../canvas-timeline/canvas_timeline_util';
import CapacityEntityGroupingGroup from '../components/groups/capacity_entity_grouping_group';
import {getPersonGroup, getPlaceholderGroup} from './CanvasCapacityOverviewUtil';
import {getAllocationInterval, getRecalculationInterval, recalculateGroupHeatmapCache} from '../heatmap/HeatmapLogic';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import DataManager, {DATA_ENTITIES, LOOKUP_MAPS} from '../DataManager';
import ProjectGroup from '../components/groups/project_group';
import ComposeManager from '../ComposeManager';
import IDManager, {ROLE_GROUPING_SUB_GROUP_TYPE} from '../IDManager';
import CapacityPlaceholderGroup from '../components/groups/capacity_placeholder_group';

export const addProjectGroup = (pageComponent, project, projectGroup, person, placeholder) => {
	// create new project group
	const groupCreated = new ProjectGroup(
		pageComponent,
		ComposeManager.composeProjectGroup(pageComponent, person, placeholder, project, projectGroup)
	);

	// add to grouping
	const groupingGroup = person ? getPersonGroup(pageComponent, person) : getPlaceholderGroup(pageComponent, placeholder);
	groupingGroup.addChildGroup(groupCreated);
};

// PLACEHOLDERS
export const transferPlaceholderAllocations = (pageComponent, existingPlaceholderGroup, newPlaceholderGroup) => {
	const {items} = pageComponent.state;

	const updatedItems = items.map(item => {
		if (item.groupId === existingPlaceholderGroup.id) {
			item.groupId = newPlaceholderGroup.id;
		}

		return item;
	});

	pageComponent.setState({items: updatedItems});
};

export const addRoleGroupingGroup = (pageComponent, placeholderGroup, personGroup, role) => {
	const {groups} = pageComponent.state;

	// create person grouping
	const personGroups = personGroup ? [personGroup] : [];
	const personGroupingGroup = new CapacityEntityGroupingGroup(
		pageComponent,
		ComposeManager.composeRoleGroupingGroup(
			pageComponent,
			role,
			personGroups,
			ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING
		)
	);

	// create placeholder grouping
	const placeholderGroups = placeholderGroup ? [placeholderGroup] : [];
	const placeholderGroupingGroup = new CapacityEntityGroupingGroup(
		pageComponent,
		ComposeManager.composeRoleGroupingGroup(
			pageComponent,
			role,
			placeholderGroups,
			ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND
		)
	);

	// create role grouping group
	const subGroups = [personGroupingGroup, placeholderGroupingGroup];
	const roleGroupingGroup = new CapacityEntityGroupingGroup(
		pageComponent,
		ComposeManager.composeRoleGroupingGroup(pageComponent, role, subGroups)
	);

	// add new group and set expanded
	roleGroupingGroup.setExpanded(true);

	if (placeholderGroup) {
		placeholderGroup.setExpanded(true);
	}

	if (personGroup) {
		personGroup.setExpanded(true);
	}

	groups.push(roleGroupingGroup);

	return roleGroupingGroup;
};

export const addCapacityPlaceholderGroup = (pageComponent, formattedPlaceholder, existingPlaceholder) => {
	const {data, groups} = pageComponent.state;

	const project = formattedPlaceholder.projectId
		? DataManager.getProjectById(pageComponent, formattedPlaceholder.projectId)
		: null;
	const projectGroup = formattedPlaceholder.projectGroupId
		? DataManager.getProjectGroupById(pageComponent, formattedPlaceholder.projectGroupId)
		: null;

	const role = formattedPlaceholder.roleId ? data.roles.find(role => role.id === formattedPlaceholder.roleId) : null;

	// create project group
	const group = new ProjectGroup(
		pageComponent,
		ComposeManager.composeProjectGroup(pageComponent, null, formattedPlaceholder, project, projectGroup)
	);

	const placeholderGroupToAdd = new CapacityPlaceholderGroup(
		pageComponent,
		ComposeManager.composeCapacityPlaceholderGroup(pageComponent, formattedPlaceholder, [group])
	);

	// if role is changed we need to update placeholderGroupingGroup from old role
	if (existingPlaceholder && existingPlaceholder.roleId !== formattedPlaceholder.roleId) {
		const previousRole = existingPlaceholder.roleId
			? data.roles.find(role => role.id === existingPlaceholder.roleId)
			: null;

		const previousRoleGroupingGroup = groups.find(
			group => group.id === IDManager.getRoleGroupingGroupId(pageComponent, previousRole, null)
		);
		const previousPlaceholderGroupingGroup = previousRoleGroupingGroup.groups.find(
			group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND
		);

		// remove from old placeholderGroupingGroup and update heatmap cache
		if (previousPlaceholderGroupingGroup) {
			previousPlaceholderGroupingGroup.removeChildGroup(
				IDManager.getPlaceholderGroupId(pageComponent, existingPlaceholder)
			);

			// TODO: update heatmap cache

			// remove role grouping group if completely empty
			if (previousPlaceholderGroupingGroup.groups.length <= 0) {
				const personGroupingGroup = previousRoleGroupingGroup.groups.find(
					group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING
				);

				if (personGroupingGroup?.groups.length <= 0) {
					removeFromArray(groups, group => group.id === previousRoleGroupingGroup.id);
				}
			}
		}

		transferPlaceholderAllocations(pageComponent, existingPlaceholder, placeholderGroupToAdd);

		let roleGroupingGroup = groups.find(group => group.id === IDManager.getRoleGroupingGroupId(pageComponent, role, null));

		if (!roleGroupingGroup) {
			roleGroupingGroup = addRoleGroupingGroup(pageComponent, placeholderGroupToAdd, null, role);
		}

		const placeholderGroupingGroup = roleGroupingGroup.groups.find(
			group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND
		);

		const placeholderGroup = placeholderGroupingGroup.groups.find(
			group => group.id === IDManager.getPlaceholderGroupId(pageComponent, formattedPlaceholder)
		);

		if (!placeholderGroup) {
			placeholderGroupingGroup.addChildGroup(placeholderGroupToAdd);
		} else {
			placeholderGroupingGroup.replaceChildGroup(placeholderGroup, placeholderGroupToAdd);
		}

		// TODO: recalculate heatmap cache of new group

		if (previousPlaceholderGroupingGroup) {
			// TODO: recalculate heatmap cache of old group
		}
	} else {
		let roleGroupingGroup = groups.find(group => group.id === IDManager.getRoleGroupingGroupId(pageComponent, role, null));

		if (!roleGroupingGroup) {
			roleGroupingGroup = addRoleGroupingGroup(pageComponent, placeholderGroupToAdd, null, role);
		}

		const placeholderGroupingGroup = roleGroupingGroup.groups.find(
			group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND
		);

		const placeholderGroup = placeholderGroupingGroup.groups.find(
			group => group.id === IDManager.getPlaceholderGroupId(pageComponent, formattedPlaceholder)
		);

		if (!placeholderGroup) {
			placeholderGroupingGroup.addChildGroup(placeholderGroupToAdd);
		} else {
			placeholderGroupingGroup.replaceChildGroup(placeholderGroup, placeholderGroupToAdd);
		}
	}
};

export const createOrUpdatePlaceholderAllocation = (pageComponent, responsePlaceholderAllocation) => {
	const {data, items, totalMinutesMap} = pageComponent.state;

	const startDate = responsePlaceholderAllocation.startDate
		? new Date(responsePlaceholderAllocation.startDate)
		: new Date(responsePlaceholderAllocation.startYear, responsePlaceholderAllocation.startMonth - 1);

	const endDate = responsePlaceholderAllocation.endDate
		? new Date(responsePlaceholderAllocation.endDate)
		: new Date(
				responsePlaceholderAllocation.endYear,
				responsePlaceholderAllocation.endMonth - 1,
				responsePlaceholderAllocation.endDay
		  );

	const formattedAllocation = {
		id: responsePlaceholderAllocation.id,
		description: responsePlaceholderAllocation.description,
		monday: responsePlaceholderAllocation.monday,
		tuesday: responsePlaceholderAllocation.tuesday,
		wednesday: responsePlaceholderAllocation.wednesday,
		thursday: responsePlaceholderAllocation.thursday,
		friday: responsePlaceholderAllocation.friday,
		saturday: responsePlaceholderAllocation.saturday,
		sunday: responsePlaceholderAllocation.sunday,
		startDate: startDate,
		endDate: endDate,
		startYear: startDate ? startDate.getUTCFullYear() : responsePlaceholderAllocation.startYear,
		startMonth: startDate ? startDate.getUTCMonth() + 1 : responsePlaceholderAllocation.startMonth,
		startDay: startDate ? startDate.getUTCDate() : responsePlaceholderAllocation.startDay,
		endYear: endDate ? endDate.getUTCFullYear() : responsePlaceholderAllocation.endYear,
		endMonth: endDate ? endDate.getUTCMonth() + 1 : responsePlaceholderAllocation.endMonth,
		endDay: endDate ? endDate.getUTCDate() : responsePlaceholderAllocation.endDay,
		placeholderId: responsePlaceholderAllocation.placeholderId || responsePlaceholderAllocation.placeholder.id,
		personId: responsePlaceholderAllocation.personId || responsePlaceholderAllocation.person?.id,
		projectId: responsePlaceholderAllocation.projectId || responsePlaceholderAllocation.project?.id,
		projectGroupId: responsePlaceholderAllocation.projectGroupId,
	};

	const placeholder = DataManager.getPlaceholderById(pageComponent, formattedAllocation.placeholderId);
	const placeholderAllocationItemData = ComposeManager.composePlaceholderAllocation(pageComponent, formattedAllocation);

	if (placeholderAllocationItemData) {
		const changedAllocation = data.placeholderAllocations.find(
			placeholderAllocation => placeholderAllocation.id === responsePlaceholderAllocation.id
		);

		if (changedAllocation) {
			removeFromArray(
				data.placeholderAllocations,
				placeholderAllocation => placeholderAllocation.id === changedAllocation.id,
				formattedAllocation
			);

			if (!formattedAllocation.personId) {
				const allocationsForOldPlaceholder =
					data.placeholderAllocationsByPlaceholder[changedAllocation.placeholderId] || [];
				data.placeholderAllocationsByPlaceholder[changedAllocation.placeholderId] = allocationsForOldPlaceholder.filter(
					allocation => allocation.id !== changedAllocation.id
				);
			}

			const placeholderAllocationItem = items.find(
				item =>
					item.data.placeholderAllocation && item.data.placeholderAllocation.id === responsePlaceholderAllocation.id
			);

			if (placeholderAllocationItem) {
				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					const {groups} = pageComponent.state;
					const placeholderGroup = DataManager.findHeatmapGroupById(placeholderAllocationItem.groupId, groups);
					DataManager.moveItem(
						pageComponent,
						placeholderAllocationItem,
						placeholderAllocationItemData,
						placeholderGroup,
						null,
						true
					);
				} else {
					placeholderAllocationItem.resetItemRow();
					placeholderAllocationItem.updateData(placeholderAllocationItemData, true);
				}

				placeholderAllocationItem.data.recalculateHeatmapCache(getRecalculationInterval(placeholderAllocationItem));
			}

			adjustTotalMinutesMap(placeholder, formattedAllocation, totalMinutesMap, false, false);
		} else {
			// add to data
			data.placeholderAllocations.push(formattedAllocation);

			const placeholderAllocationItem = new PlaceholderAllocationItem(pageComponent, placeholderAllocationItemData);

			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				DataManager.addItem(pageComponent, placeholderAllocationItem);
			} else {
				items.push(placeholderAllocationItem);
			}

			placeholderAllocationItem.data.recalculateHeatmapCache(getRecalculationInterval(placeholderAllocationItem));
		}

		// add to allocations by placeholder map
		const allocationsForNewPlaceholder = data.placeholderAllocationsByPlaceholder[formattedAllocation.placeholderId] || [];
		allocationsForNewPlaceholder.push(formattedAllocation);
		data.placeholderAllocationsByPlaceholder[formattedAllocation.placeholderId] = allocationsForNewPlaceholder;
	}
};

export const createOrUpdateMultiplePlaceholderAllocations = (pageComponent, placeholderAllocations) => {
	if (!placeholderAllocations) return;
	placeholderAllocations.forEach(placeholderAllocation =>
		createOrUpdatePlaceholderAllocation(pageComponent, placeholderAllocation)
	);
};

export const deletePlaceholderAllocation = (pageComponent, placeholderAllocationId) => {
	const {data, items, totalMinutesMap} = pageComponent.state;

	const allocationToDelete = data.placeholderAllocations.find(allocation => allocation.id === placeholderAllocationId);
	const placeholder = data.placeholders.find(placeholder => placeholder.id === allocationToDelete.placeholderId);

	adjustTotalMinutesMap(placeholder, allocationToDelete, totalMinutesMap, true);

	// remove from placeholderAllocationsByPlaceholder map
	const allocationsByPlaceholderId = data.placeholderAllocationsByPlaceholder[allocationToDelete.placeholderId] || [];
	data.placeholderAllocationsByPlaceholder[allocationToDelete.placeholderId] = allocationsByPlaceholderId.filter(
		allocation => allocation.id !== placeholderAllocationId
	);

	// remove from placeholder allocations data
	data.placeholderAllocations = data.placeholderAllocations.filter(allocation => allocation.id !== placeholderAllocationId);

	if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
		DataManager.removeItem(pageComponent, item => item.data.placeholderAllocation?.id === placeholderAllocationId);
	} else {
		const index = items.findIndex(
			item => item.data.placeholderAllocation && placeholderAllocationId === item.data.placeholderAllocation.id
		);
		items.splice(index, 1);
	}

	if (!hasFeatureFlag('scheduling_recalculation_tree')) {
		recalculateGroupHeatmapCache(pageComponent, IDManager.getPlaceholderGroupId(pageComponent, placeholder));
	}
};

export const deletePlaceholder = (pageComponent, placeholderId) => {
	const data = pageComponent.getData();

	// find deleted placeholder
	const placeholderToDelete = data.placeholders.find(placeholder => placeholder.id === placeholderId);

	if (!placeholderToDelete) return;

	// remove placeholder allocations
	const placeholderAllocations = data.placeholderAllocationsByPlaceholder[placeholderId];
	if (placeholderAllocations) {
		placeholderAllocations.forEach(allocation => deletePlaceholderAllocation(pageComponent, allocation.id));
	}

	// remove from data
	removeFromArray(data.placeholders, placeholder => placeholder.id === placeholderId);
	removeFromArray(data.placeholderSkills, placeholderSkill => placeholderSkill.placeholderId === placeholderId);

	// remove from placeholder allocations map
	const placeholderToDeleteAllocations = data.placeholderAllocationsByPlaceholder[placeholderId];
	if (placeholderToDeleteAllocations) {
		delete data.placeholderAllocationsByPlaceholder[placeholderId];
	}

	// remove from skills map
	const placeholderToDeleteSkills = data.placeholderSkillsByPlaceholder[placeholderId];
	if (placeholderToDeleteSkills) {
		delete data.placeholderSkillsByPlaceholder[placeholderId];
	}

	// remove from groups
	const placeholderGroup = getPlaceholderGroup(pageComponent, placeholderToDelete);
	const placeholderGroupingGroup = placeholderGroup.parentGroup;

	if (placeholderGroupingGroup?.groups) {
		removeFromArray(placeholderGroupingGroup.groups, group => group.id === placeholderGroup.id);

		if (placeholderGroupingGroup.groups.length <= 0) {
			// get person grouping group
			const roleGroupingGroup = placeholderGroupingGroup.parentGroup;
			const personGroupingGroup = roleGroupingGroup.groups.find(
				group => group.subGroupType === ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING
			);

			if (personGroupingGroup?.groups.length <= 0) {
				// remove role grouping group all together
				removeFromArray(pageComponent.state.groups, group => group.id === roleGroupingGroup.id);
			}
		}
	}

	// recalculate heatmap cache
	recalculateGroupHeatmapCache(pageComponent, placeholderGroupingGroup.id);
};

export const createOrUpdatePlaceholder = (pageComponent, responsePlaceholder) => {
	const {data} = pageComponent.state;

	const formattedPlaceholder = {
		id: responsePlaceholder.id,
		projectId: responsePlaceholder.project?.id,
		projectGroupId: responsePlaceholder.projectGroupId,
		name: responsePlaceholder.name,
		roleId: responsePlaceholder.role?.id,
		departmentId: responsePlaceholder.departmentId,
	};

	const project = formattedPlaceholder.projectId
		? data.projects.find(project => project.id === formattedPlaceholder.projectId)
		: data.projectGroups.find(projectGroup => projectGroup.id === formattedPlaceholder.projectGroupId);

	if (!project) return;

	const existingPlaceholder = data.placeholders.find(placeholder => placeholder.id === formattedPlaceholder.id);
	const placeholderSkills = responsePlaceholder.skillPlaceholders.map(sp => ({
		skillId: sp.skill.id,
		skillLevelId: sp.level?.id,
		placeholderId: formattedPlaceholder.id,
	}));

	if (!existingPlaceholder) {
		// add to placeholders data
		data.placeholders.push(formattedPlaceholder);
	} else {
		// update placeholder in data
		const existingPlaceholderIndex = data.placeholders.indexOf(existingPlaceholder);
		data.placeholders.splice(existingPlaceholderIndex, 1, formattedPlaceholder);
	}

	// add to placeholderMap
	data.placeholderMap.set(formattedPlaceholder.id, formattedPlaceholder);

	// replace teams
	data.placeholderTeamsByPlaceholder[formattedPlaceholder.id] = responsePlaceholder.teamPlaceholders;

	// replace skills
	data.placeholderSkills = data.placeholderSkills
		.filter(ps => ps.placeholderId !== formattedPlaceholder.id)
		.concat(...placeholderSkills);

	// add to skills map
	data.placeholderSkillsByPlaceholder[formattedPlaceholder.id] = placeholderSkills;

	addCapacityPlaceholderGroup(pageComponent, formattedPlaceholder, existingPlaceholder);

	const placeholderAllocations = responsePlaceholder.placeholderAllocations?.edges.map(edge => edge.node);
	createOrUpdateMultiplePlaceholderAllocations(pageComponent, placeholderAllocations);
};

// PERSONS

export const createOrUpdateAllocation = (pageComponent, responseAllocation) => {
	const {data, items} = pageComponent.state;

	const startDate = responseAllocation.startDate
		? new Date(responseAllocation.startDate)
		: new Date(responseAllocation.startYear, responseAllocation.startMonth - 1, responseAllocation.startDay);
	const endDate = responseAllocation.endDate
		? new Date(responseAllocation.endDate)
		: new Date(responseAllocation.endYear, responseAllocation.endMonth - 1, responseAllocation.endDay);

	const idleTime = responseAllocation.idleTime;
	const formattedAllocation = {
		id: responseAllocation.id,
		idleTimeId: responseAllocation.idleTimeId || idleTime?.id,
		idleTimeName: idleTime?.name,
		isIdleTimeInternal: idleTime?.isInternalTime,
		description: responseAllocation.description,
		monday: responseAllocation.monday,
		tuesday: responseAllocation.tuesday,
		wednesday: responseAllocation.wednesday,
		thursday: responseAllocation.thursday,
		friday: responseAllocation.friday,
		saturday: responseAllocation.saturday,
		sunday: responseAllocation.sunday,
		startYear: startDate.getFullYear(),
		startMonth: startDate.getMonth() + 1,
		startDay: startDate.getDate(),
		endYear: endDate.getFullYear(),
		endMonth: endDate.getMonth() + 1,
		endDay: endDate.getDate(),
		personId: responseAllocation.personId || responseAllocation.person?.id,
		projectId: responseAllocation.projectId || responseAllocation.project?.id,
		projectGroupId: responseAllocation.projectGroupId,
		projectGroupColor: responseAllocation.projectGroupColor,
		projectGroupCompanyProjectGroupId: responseAllocation.projectGroupCompanyProjectGroupId,
		projectGroupName: responseAllocation.projectGroupName,
		isSoft: responseAllocation.isSoft,
	};

	const allocationItemData = ComposeManager.composeProjectAllocation(pageComponent, formattedAllocation);

	if (allocationItemData) {
		const changedAllocation = data.allocations.find(allocation => allocation.id === formattedAllocation.id);

		if (changedAllocation) {
			const movedToNewPerson = changedAllocation.personId !== formattedAllocation.personId;

			// update state data
			data.allocations.splice(data.allocations.indexOf(changedAllocation), 1, formattedAllocation);
			if (!hasFeatureFlag('inverted_pto_non_working_days')) {
				DataManager.removeFromLookupMaps(pageComponent, DATA_ENTITIES.ALLOCATIONS, changedAllocation);
				DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.ALLOCATIONS, formattedAllocation);
			}

			if (movedToNewPerson) {
				data.personAllocationMap = DataManager.createGroupBy(data?.allocations, LOOKUP_MAPS.PERSON_ALLOCATION_MAP);
			}

			const allocationItem = items.find(item => item.data.allocation?.id === formattedAllocation.id);

			if (allocationItem) {
				if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
					const {groups} = pageComponent.state;
					const personGroup = DataManager.findHeatmapGroupById(allocationItem.groupId, groups);

					let newPersonGroup = null;
					if (movedToNewPerson) {
						const newPerson = data.personMap.get(formattedAllocation.personId);
						newPersonGroup = getPersonGroup(pageComponent, newPerson);
					}

					DataManager.moveItem(pageComponent, allocationItem, allocationItemData, personGroup, newPersonGroup, true);
				} else {
					allocationItem.resetItemRow();
					allocationItem.updateData(allocationItemData, true);
				}

				allocationItem.data.recalculateHeatmapCache(getRecalculationInterval(allocationItem));
			}
		} else {
			// add to data
			data.allocations.push(formattedAllocation);

			if (hasFeatureFlag('inverted_pto_non_working_days')) {
				data.personAllocationMap = DataManager.createGroupBy(data?.allocations, LOOKUP_MAPS.PERSON_ALLOCATION_MAP);
			} else {
				DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.ALLOCATIONS, formattedAllocation);
				if (isTimeOffAllocation(formattedAllocation)) {
					const interval = getAllocationInterval(formattedAllocation);
					DataManager.updateItemsAffectedByTimeOff(
						pageComponent,
						formattedAllocation.personId,
						formattedAllocation.personId,
						interval
					);
				}
			}

			const allocationItem = new ProjectAllocationItem(pageComponent, allocationItemData);

			if (!hasFeatureFlag('improving_heatmap_frontend_performance')) {
				items.push(allocationItem);
			}

			const person = data.persons.find(person => person.id === formattedAllocation.personId);
			const personGroup = getPersonGroup(pageComponent, person);

			// create project group if none
			if (personGroup) {
				const projectGroupId = formattedAllocation.idleTimeId
					? IDManager.getNonProjectTimeGroupId(pageComponent, person.id)
					: formattedAllocation.projectGroupId || formattedAllocation.projectId;

				const hasProjectGroup = personGroup.groups.find(group =>
					formattedAllocation.idleTimeId
						? group.id === IDManager.getNonProjectTimeGroupId(pageComponent, person.id)
						: getProjectIdFromGroup(group) === projectGroupId
				);

				if (!hasProjectGroup) {
					if (formattedAllocation.idleTimeId) {
						const nonProjectTimeGroupData = ComposeManager.composeNonProjectTimeGroup(pageComponent, person);

						if (nonProjectTimeGroupData) {
							personGroup.addChildGroup(new NonProjectTimeGroup(pageComponent, nonProjectTimeGroupData));
						}
					} else {
						const projectGroup = formattedAllocation.projectGroupId
							? DataManager.getProjectGroupById(pageComponent, formattedAllocation.projectGroupId)
							: null;
						const project = formattedAllocation.projectId
							? DataManager.getProjectById(pageComponent, formattedAllocation.projectId)
							: null;

						const projectGroupData = ComposeManager.composeProjectGroup(
							pageComponent,
							person,
							null,
							project,
							projectGroup
						);
						if (projectGroupData) {
							personGroup.addChildGroup(new ProjectGroup(pageComponent, projectGroupData));
						}
					}
				}
			}

			if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
				DataManager.addItem(pageComponent, allocationItem);
			}

			allocationItem.data.recalculateHeatmapCache(getRecalculationInterval(allocationItem));
		}
	}
};

export const deleteAllocation = (pageComponent, allocationToDeleteId) => {
	const {data, items} = pageComponent.state;
	const allocationToDelete = data.allocations.find(allocation => allocation.id === allocationToDeleteId);

	data.allocations = data.allocations.filter(allocation => allocation.id !== allocationToDeleteId);
	if (!hasFeatureFlag('inverted_pto_non_working_days')) {
		DataManager.removeFromLookupMaps(pageComponent, DATA_ENTITIES.ALLOCATIONS, allocationToDelete);
		if (isTimeOffAllocation(allocationToDelete)) {
			const interval = getAllocationInterval(allocationToDelete);
			DataManager.updateItemsAffectedByTimeOff(
				pageComponent,
				allocationToDelete.personId,
				allocationToDelete.personId,
				interval
			);
		}
	}

	if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
		DataManager.removeItem(pageComponent, item => item.data.allocation?.id === allocationToDeleteId);
	} else {
		removeFromArray(items, item => item.data.allocation?.id === allocationToDeleteId);
	}

	if (allocationToDelete && !hasFeatureFlag('scheduling_recalculation_tree')) {
		const person = data.persons.find(person => person.id === allocationToDelete.personId);
		recalculateGroupHeatmapCache(pageComponent, IDManager.getPersonGroupId(pageComponent, person.id));
	}
};

export const createProjectPerson = (pageComponent, projectId, projectGroupId, projectPerson) => {
	const data = pageComponent.getData();

	const personId = projectPerson.person.id;
	const projectPersonProjectId = projectPerson.project.id;
	const person = DataManager.getPersonById(pageComponent, personId);
	const newProjectPerson = {
		id: projectPerson.id,
		personId: person.id,
		roleId: person.roleId,
		projectId: projectPersonProjectId,
	};

	const alreadyExists = data.projectPersons.some(pp => pp.personId === person.id && pp.projectId === projectPersonProjectId);

	if (!alreadyExists) {
		data.projectPersons.push(newProjectPerson);
		DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECT_PERSONS, newProjectPerson);
	}

	const project = projectId ? getProject(projectId, data.projects) : null;
	const projectGroup = projectGroupId ? getProjectGroup(projectGroupId, data.projectGroups) : null;

	addProjectGroup(pageComponent, project, projectGroup, person, null);
};

export const createProject = (pageComponent, project) => {
	const alreadyExists = DataManager.getProjectById(pageComponent, project.id);

	if (alreadyExists) {
		return;
	}

	const {data} = pageComponent.state;

	const formattedProject = {
		id: project.id,
		status: project.status,
		companyProjectId: project.companyProjectId,
		customProjectId: project.customProjectId,
		name: project.name,
		projectColor: project.projectColor,
		useManualAllocations: project.useManualAllocations,
		completion: project.completion,
		remaining: project.remaining,
		projectStartDay: project.projectStartDay,
		projectStartMonth: project.projectStartMonth,
		projectStartYear: project.projectStartYear,
		projectEndDay: project.projectEndDay,
		projectEndMonth: project.projectEndMonth,
		projectEndYear: project.projectEndYear,
		estimationUnit: project.estimationUnit,
		minutesPerEstimationPoint: project.minutesPerEstimationPoint,
		isInProjectGroup: project.isInProjectGroup,
		projectGroupId: project.projectGroupId,
		forecast: project.forecast,
		clientId: project.client ? project.client.id : null,
		fullAccessToProject: true,
	};

	const newProjectStatus = {
		color: '#33cc33',
		projectId: project.id,
	};

	data.projects.push(formattedProject);
	DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECTS, formattedProject);

	data.projectStatuses.push(newProjectStatus);
	DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECT_STATUSES, newProjectStatus);

	if (project.projectLabels) {
		for (const projectLabelEdge of project.projectLabels.edges) {
			const projectLabel = projectLabelEdge.node;
			const newProjectLabel = {
				id: projectLabel.id,
				labelId: projectLabel.label.id,
				projectId: project.id,
			};

			data.projectLabels.push(newProjectLabel);
			DataManager.updateLookupMap(pageComponent, DATA_ENTITIES.PROJECT_LABELS, newProjectLabel);
		}
	}

	if (project.projectPersons) {
		for (const projectPersonEdge of project.projectPersons.edges) {
			createProjectPerson(pageComponent, project.id, project.projectGroupId, projectPersonEdge.node);
		}
	}
};
