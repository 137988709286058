import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {removeFromArray} from '../utils';
import Util from '../../../forecast-app/shared/util/util';
import DeleteProjectMutation from '../../../mutations/delete_project_mutation';
import DataManager from '../DataManager';
import IDManager from '../IDManager';

export const handleDeleteProject = (pageComponent, projectId) => {
	const {intl} = pageComponent.props;
	const {formatMessage} = intl;

	showModal({
		type: MODAL_TYPE.CONFIRM_PROJECT_DELETION,
		confirmText: formatMessage({id: 'settings.confirm'}),
		onDelete: () => {
			const {groups} = pageComponent.state;
			const data = pageComponent.getData();

			const project = DataManager.getProjectById(pageComponent, projectId);
			const projectRelatedProjectPersons = DataManager.getProjectPersonsByProjectId(pageComponent, projectId);

			// Clear the heatmap cache
			for (const projectPerson of projectRelatedProjectPersons) {
				const personGroupId = IDManager.getPersonGroupId(
					pageComponent,
					projectPerson.personId,
					project.projectGroupId || project.id
				);
				pageComponent.clearHeatmapCache(personGroupId);
			}

			data.projectPersons = data.projectPersons.filter(projectPerson => projectPerson.projectId !== project.id);
			data.allocations = data.allocations.filter(allocation => allocation.projectId !== project.id);
			data.timeRegistrations = data.timeRegistrations.filter(
				timeRegistration =>
					timeRegistration.taskId || timeRegistration.idleTimeId || timeRegistration.projectId !== project.id
			);
			data.tasks = data.tasks.filter(task => task.projectId !== project.id);

			pageComponent.setState({showLoadingOverlay: true});

			const onSuccess = result => {
				if (
					result.deleteProject?.errors?.length === 1 &&
					result.deleteProject.errors[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD'
				) {
					showModal({
						type: MODAL_TYPE.WARNING_DATE_LOCKED,
						nonTimeRegWarning: true,
					});
				} else {
					const isInProjectGroup = !!project.projectGroupId;
					const isInProgram = !!project.isInProgram;

					if (isInProjectGroup || isInProgram) {
						const projectOrProgramGroup = isInProjectGroup
							? groups.find(group => group.id === project.projectGroupId)
							: groups.find(group => group.data.program?.prefix === project.programPrefix);

						if (projectOrProgramGroup) {
							removeFromArray(projectOrProgramGroup.groups, group => group.id === project.id);

							if (isInProjectGroup && projectOrProgramGroup.groups.length <= 1) {
								removeFromArray(groups, group => group.id === projectOrProgramGroup.id);
							}
						}
					} else {
						removeFromArray(groups, group => group.id === project.id);
					}

					removeFromArray(data.projects, dataProject => dataProject.id === project.id);

					if (project.projectGroupId) {
						const projectGroupProjects = data.projects.filter(p => p.projectGroupId === project.projectGroupId);

						if (projectGroupProjects.length === 0) {
							removeFromArray(data.projectGroups, projectGroup => projectGroup.id === project.projectGroupId);
						}
					}

					pageComponent.redrawCanvasTimeline({preventFiltering: true});
				}

				pageComponent.setState({showLoadingOverlay: false});
			};

			Util.CommitSchedulingModalUpdate(
				DeleteProjectMutation,
				{
					id: projectId,
					projectGroupId: project.projectGroupId,
				},
				onSuccess
			);
		},
	});
};
