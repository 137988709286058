import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

export const handleUnsavedChanges = (onExit, onSave) => {
	trackEvent('Staffing Mode Unsaved Changes Modal', 'Opened');
	const unsavedChanges = () => {
		showModal({
			type: MODAL_TYPE.PLACEHOLDER_STAFFING_UNSAVED_CHANGES,
			onExit,
			onSave,
		});
	};

	unsavedChanges();
};
