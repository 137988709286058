import React from 'react';
import {useIntl} from 'react-intl';
import {LoadMore} from '../../../loaders/LoadMore';
import {filtersToSearchQuery} from '../UtilizationReportUtils';
import UtilizationReportMonthByMonthDepartmentLoader, {
	utilizationReportMonthByMonthDepartmentLoaderQuery,
} from './UtilizationReportMonthByMonthDepartmentLoader';
import {exportToCsv} from './MonthByMonthDownload';
import {Table} from 'web-components';
import {MonthByMonthUtilizationMeter, getUtilizationNumber} from './MonthByMonthTable';
import MonthByMonthPersonRows from './MonthByMonthPersonRows';

const MonthByMonthDepartmentRows = ({
	filters,
	startDate,
	endDate,
	setCsvDownloadFunction,
	pageSize,
	showResourceUtilization,
	isMixedAllocationModeEnabled,
	allocationControlsOptions,
}) => {
	const intl = useIntl();
	return (
		<LoadMore
			key="query-render-month-by-month-department"
			query={utilizationReportMonthByMonthDepartmentLoaderQuery}
			loader={<UtilizationReportMonthByMonthDepartmentLoader />}
			variables={{
				pageSize,
				searchQuery: filtersToSearchQuery(filters),
				startYear: startDate ? startDate.year() : undefined,
				startMonth: startDate ? startDate.month() + 1 : undefined,
				startDay: startDate ? startDate.date() : undefined,
				endYear: endDate ? endDate.year() : undefined,
				endMonth: endDate ? endDate.month() + 1 : undefined,
				endDay: endDate ? endDate.date() : undefined,
			}}
		>
			{({data, loadMore}) => {
				setCsvDownloadFunction(() => {
					exportToCsv(
						startDate.year(),
						data.rows,
						getUtilizationNumber,
						showResourceUtilization,
						intl,
						isMixedAllocationModeEnabled,
						allocationControlsOptions
					);
				});
				return (
					<Table.Rows data={data} loadMore={loadMore} autoload={true} canExpand={true}>
						{({rowData, tableColumnsProps, expanded, nextLevelProps}) => {
							return (
								<>
									<Table.Row {...tableColumnsProps} cy={'report-row'}>
										<Table.Column>{rowData.department.name}</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													0,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													1,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													2,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													3,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													4,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													5,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													6,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													7,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													8,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													9,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													10,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
										<Table.Column>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													11,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
									</Table.Row>
									{expanded && (
										<MonthByMonthPersonRows
											filters={filters}
											startDate={startDate}
											endDate={endDate}
											pageSize={pageSize}
											showResourceUtilization={showResourceUtilization}
											departmentId={rowData.department.id}
											levelProps={nextLevelProps}
											isMixedAllocationModeEnabled={isMixedAllocationModeEnabled}
											allocationControlsOptions={allocationControlsOptions}
										></MonthByMonthPersonRows>
									)}
								</>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default MonthByMonthDepartmentRows;
