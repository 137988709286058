import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import InsightsUtil from '../insights_util';
import Person from '../../../forecast-app/shared/components/person/person';
import Util from '../../../forecast-app/shared/util/util';
import {profilePicSrc} from '../../../directApi';

class utilizationListComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sortBy: {column: null, ascending: true},
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.setExportColumns) {
			const configObject = this.props.config ? JSON.parse(this.props.config) : {};
			let exportColumns = [];
			if (configObject) {
				this.props.columns.forEach(col => {
					if (configObject.columns.indexOf(col) > -1) {
						if (col === 'role') {
							exportColumns.push(col);
						} else {
							exportColumns.push(col + '_raw');
						}
					}
				});
			}
			this.props.setExportColumns(exportColumns);
		}
		if (this.props.dateSpan) {
			if (this.props.dateSpan.key === 'custom-date-range') {
				if (this.props.dateSpan.value && this.props.dateSpan.value.start && this.props.dateSpan.value.end) {
					this.props.relay.refetch({
						dateCriteria:
							this.props.dateSpan.value.start.format('DD/MM/YYYY') +
							'_' +
							this.props.dateSpan.value.end.format('DD/MM/YYYY'),
					});
				}
			} else {
				this.props.relay.refetch({
					dateCriteria: this.props.dateSpan.value,
				});
			}
		} else {
			//no date span, current month is deault
			this.props.relay.refetch({
				dateCriteria: 'current-month',
			});
		}

		const listHeight = InsightsUtil.GetlistHeight(
			this.props.viewer.insightComponentsData.utilizationList.utilizationListData.length
		);
		if (this.props.height !== listHeight) {
			this.props.updateComponentHeight(this.props.id, listHeight);
		}
		this.setData(this.props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.dateCriteria !== nextProps.dateCriteria) {
			this.props.relay.refetch({
				dateCriteria: nextProps.dateCriteria,
			});

			const listHeight = InsightsUtil.GetlistHeight(
				this.props.viewer.insightComponentsData.utilizationList.utilizationListData.length
			);
			if (this.props.height !== listHeight) {
				this.props.updateComponentHeight(this.props.id, listHeight);
			}
			this.setData(this.props, this.state);
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (
			nextState.sortBy.column !== this.state.sortBy.column ||
			nextState.sortBy.ascending !== this.state.sortBy.ascending
		) {
			this.sort(nextState.sortBy);
		}
		this.setData(nextProps, nextState);
		const listHeight = InsightsUtil.GetlistHeight(this.data.length);
		this.props.updateComponentHeight(this.props.id, listHeight);
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	setData(props, state) {
		const {formatMessage, formatNumber} = props.intl;
		const utilizationList = props.viewer.insightComponentsData.utilizationList;
		const availableHoursTotal = utilizationList.availableMinutesTotal / 60.0;
		const scheduledHoursTotal = utilizationList.scheduledMinutesTotal / 60.0;
		const scheduledPercentageTotal = availableHoursTotal === 0 ? 0 : (scheduledHoursTotal * 100) / availableHoursTotal;
		const actualBillableHoursTotal = utilizationList.billableMinutesRegisteredTotal / 60.0;
		const plannedBillableHoursTotal = utilizationList.billableMinutesPlannedTotal / 60.0;
		const projectTimeHoursTotal = utilizationList.scheduledProjectTimeMinutes / 60.0;
		const projectTimePercentageTotal = scheduledHoursTotal === 0 ? 0 : (projectTimeHoursTotal * 100) / scheduledHoursTotal;
		const nonProjectTimeHoursTotal = utilizationList.scheduledNonProjectTimeMinutes / 60.0;
		const nonProjectTimePercentageTotal =
			scheduledHoursTotal === 0 ? 0 : (nonProjectTimeHoursTotal * 100) / scheduledHoursTotal;
		const registeredHoursTotal = utilizationList.reportedTotal / 60.0;
		const overTimeHoursTotal = utilizationList.overTimeMinutesTotal / 60.0;
		const overtimePercentageTotal =
			overTimeHoursTotal === 0 || availableHoursTotal === 0 ? 0 : (overTimeHoursTotal * 100) / availableHoursTotal;
		this.totals = {
			availableHours: formatMessage({id: 'common.x_hours'}, {hours: Math.round(availableHoursTotal * 100) / 100}),
			scheduledHours: formatMessage({id: 'common.x_hours'}, {hours: Math.round(scheduledHoursTotal * 100) / 100}),
			scheduledPercentage: formatMessage(
				{id: 'common.x_percentage'},
				{percentage: Math.round(scheduledPercentageTotal * 100) / 100}
			),
			actualBillableHours: formatMessage(
				{id: 'common.x_hours'},
				{hours: Math.round(actualBillableHoursTotal * 100) / 100}
			),
			projectTimeHours: formatMessage({id: 'common.x_hours'}, {hours: Math.round(projectTimeHoursTotal * 100) / 100}),
			projectTimePercentage: formatMessage(
				{id: 'common.x_percentage'},
				{percentage: Math.round(projectTimePercentageTotal * 100) / 100}
			),
			nonProjectTimeHours: formatMessage(
				{id: 'common.x_hours'},
				{hours: Math.round(nonProjectTimeHoursTotal * 100) / 100}
			),
			nonProjectTimePercentage: formatMessage(
				{id: 'common.x_percentage'},
				{percentage: Math.round(nonProjectTimePercentageTotal * 100) / 100}
			),
			registered: formatMessage({id: 'common.x_hours'}, {hours: registeredHoursTotal}),
			billabilityHours: formatMessage({id: 'common.x_hours'}, {hours: Math.round(plannedBillableHoursTotal * 100) / 100}),
			overtimeHours: formatMessage({id: 'common.x_hours'}, {hours: Math.round(overTimeHoursTotal * 100) / 100}),
			overtimePercentage: formatMessage(
				{id: 'common.x_percentage'},
				{percentage: Math.round(overtimePercentageTotal * 100) / 100}
			),
		};
		this.data = [];
		utilizationList.utilizationListData.forEach((el, index) => {
			const person = {};
			person.name = (
				<Person
					key={index}
					name={el.name}
					showName={true}
					showRole={false}
					imageSrc={profilePicSrc(el.profilePictureId)}
					imageSize="medium"
				/>
			);
			person.name_raw = el.name;
			person.role = el.roleName;
			person.role_raw = el.roleName;

			const availableHours = Util.minutesToHours(el.availableMinutes);
			person.availableHours = formatMessage({id: 'common.x_hours'}, {hours: availableHours});
			person.availableHours_raw = availableHours;

			const scheduledHours = Util.minutesToHours(el.scheduledMinutes);
			person.scheduledHours = formatMessage({id: 'common.x_hours'}, {hours: scheduledHours});
			person.scheduledHours_raw = scheduledHours;

			const scheduledPercentage = Util.getPercentageTime(scheduledHours, availableHours);
			person.scheduledPercentage = formatNumber(scheduledPercentage, {style: 'percent', maximumFractionDigits: 2});
			person.scheduledPercentage_raw = scheduledPercentage * 100;

			const actualBillableHours = Util.minutesToHours(el.billableMinutesRegistered);
			person.actualBillableHours = formatMessage({id: 'common.x_hours'}, {hours: actualBillableHours});
			person.actualBillableHours_raw = actualBillableHours;

			const actualBillablePercentage = Util.getPercentageTime(actualBillableHours, availableHours);
			person.actualBillablePercentage = formatNumber(actualBillablePercentage, {
				style: 'percent',
				maximumFractionDigits: 2,
			});
			person.actualBillablePercentage_raw = actualBillablePercentage * 100;

			const projectTimeHours = Util.minutesToHours(el.scheduledProjectTimeMinutes);
			person.projectTimeHours = formatMessage({id: 'common.x_hours'}, {hours: projectTimeHours});
			person.projectTimeHours_raw = projectTimeHours;

			const projectTimePercentage = Util.getPercentageTime(projectTimeHours, scheduledHours);
			person.projectTimePercentage = formatNumber(projectTimePercentage, {style: 'percent', maximumFractionDigits: 2});
			person.projectTimePercentage_raw = projectTimePercentage * 100;

			const nonProjectTimeHours = Util.minutesToHours(el.scheduledNonProjectTimeMinutes);
			person.nonProjectTimeHours = formatMessage({id: 'common.x_hours'}, {hours: nonProjectTimeHours});
			person.nonProjectTimeHours_raw = nonProjectTimeHours;

			const nonProjectTimePercentage = Util.getPercentageTime(nonProjectTimeHours, scheduledHours);
			person.nonProjectTimePercentage = formatNumber(nonProjectTimePercentage, {
				style: 'percent',
				maximumFractionDigits: 2,
			});
			person.nonProjectTimePercentage_raw = nonProjectTimePercentage * 100;

			const registeredHours = Util.minutesToHours(el.reported);
			person.registered = formatMessage({id: 'common.x_hours'}, {hours: registeredHours});
			person.registered_raw = registeredHours;

			let overtimeHours = registeredHours > availableHours ? registeredHours - availableHours : 0;
			person.overtimeHours = formatMessage({id: 'common.x_hours'}, {hours: overtimeHours});
			person.overtimeHours_raw = overtimeHours;

			const overtimePercentage = Util.getPercentageTime(overtimeHours, availableHours);
			person.overtimePercentage = formatNumber(overtimePercentage, {style: 'percent', maximumFractionDigits: 2});
			person.overtimePercentage_raw = overtimePercentage * 100;

			const billabilityHours = Util.minutesToHours(el.billableMinutesPlanned);
			person.billabilityHours = formatMessage({id: 'common.x_hours'}, {hours: billabilityHours});
			person.billabilityHours_raw = billabilityHours;

			const billabilityPercentage = Util.getPercentageTime(
				Util.minutesToHours(el.billableMinutesPlanned),
				availableHours
			);
			person.billabilityPercentage = formatNumber(billabilityPercentage, {style: 'percent', maximumFractionDigits: 2});
			person.billabilityPercentage_raw = billabilityPercentage * 100;

			/*const cardUtilization = (registeredHours + el.cardMinutesPlanned / 60.0) / availableHours;
			person.cardUtilization = formatNumber(cardUtilization, {style: 'percent', maximumFractionDigits: 2});
			person.cardUtilization_raw = cardUtilization * 100;*/

			this.data.push(person);
		});
		this.sort(state.sortBy);
		if (props.setExportData) {
			props.setExportData(this.data);
		}
	}

	sort(sortBy) {
		if (sortBy.column) {
			this.data = this.data.sort((a, b) => {
				let column = sortBy.column + '_raw';
				let returnValue = 0;
				if (a[column] < b[column]) returnValue = 1;
				if (a[column] > b[column]) returnValue = -1;
				if (a[column] === '') returnValue = -1;
				if (a[column] === null) returnValue = -1;
				return sortBy.ascending ? returnValue * -1 : returnValue;
			});
		}
	}

	render() {
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};
		return (
			<InsightList
				componentId={this.props.id}
				data={this.data}
				componentName={'utilizationList'}
				activeColumns={configObject ? configObject.columns : []}
				allColumns={this.props.columns}
				sortBy={this.state.sortBy}
				setSortBy={this.setSortBy.bind(this)}
				totals={this.totals}
				scrollElement={this.props.scrollElement}
				scrollElementFullScreen={this.props.scrollElementFullScreen}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}
utilizationListComponent.propTypes = {
	title: PropTypes.string,
};

const utilizationListComponentQuery = graphql`
	query utilizationListComponent_Query(
		$shareKey: String
		$dateCriteria: String
		$componentId: ID
		$personIds: [ID]
		$teamIds: [ID]
		$roleIds: [ID]
		$labelIds: [ID]
		$projectStatusesBackend: [ID]
	) {
		viewer {
			actualPersonId
			component(name: "insight_utilization_list")
			...utilizationListComponent_viewer
				@arguments(
					shareKey: $shareKey
					dateCriteria: $dateCriteria
					componentId: $componentId
					personIds: $personIds
					teamIds: $teamIds
					roleIds: $roleIds
					labelIds: $labelIds
					projectStatusesBackend: $projectStatusesBackend
				)
		}
	}
`;

export {utilizationListComponentQuery};

export default injectIntl(
	createRefetchContainer(
		utilizationListComponent,
		{
			viewer: graphql`
				fragment utilizationListComponent_viewer on Viewer
				@argumentDefinitions(
					shareKey: {type: "String"}
					dateCriteria: {type: "String"}
					componentId: {type: "ID"}
					personIds: {type: "[ID]"}
					teamIds: {type: "[ID]"}
					roleIds: {type: "[ID]"}
					labelIds: {type: "[ID]"}
					projectStatusesBackend: {type: "[ID]"}
				) {
					insightComponentsData(shareKey: $shareKey) {
						utilizationList(
							dateCriteria: $dateCriteria
							personIds: $personIds
							componentId: $componentId
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
							projectStatusesBackend: $projectStatusesBackend
						) {
							id
							availableMinutesTotal
							scheduledMinutesTotal
							scheduledProjectTimeMinutes
							scheduledNonProjectTimeMinutes
							reportedTotal
							assignedTasksForecastTotal
							overTimeMinutesTotal
							billableMinutesRegisteredTotal
							billableMinutesPlannedTotal
							utilizationListData {
								id
								name
								profilePictureId
								profilePictureDefaultId
								roleName
								availableMinutes
								scheduledMinutes
								scheduledNonProjectTimeMinutes
								scheduledProjectTimeMinutes
								reported
								billableMinutesRegistered
								billableMinutesPlanned
								cardMinutesPlanned
							}
						}
					}
				}
			`,
		},
		graphql`
			query utilizationListComponentRefetchQuery(
				$shareKey: String
				$dateCriteria: String
				$componentId: ID
				$personIds: [ID]
				$teamIds: [ID]
				$roleIds: [ID]
				$labelIds: [ID]
				$projectStatusesBackend: [ID]
			) {
				viewer {
					...utilizationListComponent_viewer
						@arguments(
							shareKey: $shareKey
							dateCriteria: $dateCriteria
							componentId: $componentId
							personIds: $personIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
							projectStatusesBackend: $projectStatusesBackend
						)
				}
			}
		`
	)
);
