// Implementation forked from https://github.com/jaketodaro/discrete-interval-tree
import DiscreteIntervalNode from './discrete-interval-node';

export default class DiscreteIntervalTree {
	constructor() {
		this.root = null;
	}

	overlaps(start, end) {
		if (!this.root) {
			return false;
		}

		return this.root.overlaps(start, end);
	}

	toString() {
		const intervals = [];

		if (this.root) {
			this.root.inOrderTraverse(interval => {
				intervals.push('[' + interval.start + ',' + interval.end + ']');
			});
		}

		return intervals.join(',');
	}

	getOverlappingIntervals(start, end, limitToStartAndEnd = false) {
		const intervals = [];

		if (this.root) {
			this.root.getOverlappingIntervals(start, end, intervals, limitToStartAndEnd);
		}

		return intervals;
	}

	checkConsistency() {
		const intervals = [];

		if (this.root) {
			this.root.inOrderTraverse(interval => {
				intervals.push(interval);
			});
		}

		for (let i = 0; i < intervals.length; i++) {
			for (let j = 0; j < intervals.length; j++) {
				if (i === j) {
					continue;
				}

				const interval1 = intervals[i];
				const interval2 = intervals[j];

				if (interval1.overlaps(interval2.start, interval2.end)) {
					return false;
				}
			}
		}

		return true;
	}

	add(start, end) {
		if (!this.root) {
			return (this.root = new DiscreteIntervalNode(start, end));
		}

		let curr = this.root;
		let valueNode = null;

		while (!valueNode) {
			if (curr.left && curr.interval.start <= curr.left.interval.end + 1) {
				// Absorb children if intervals changed to contain them
				curr.left.interval.start = Math.min(curr.interval.start, curr.left.interval.start);
				curr.absorbLeft(curr.left);
			} else if (curr.right && curr.interval.end >= curr.right.interval.start - 1) {
				// Absorb children if intervals changed to contain them
				curr.right.interval.end = Math.max(curr.interval.end, curr.right.interval.end);
				curr.absorbRight(curr.right);
			} else if (curr.interval.contains(start, end)) {
				// Found node completely containing the new interval, so stop
				valueNode = curr;
			} else if (curr.interval.overlaps(start, end)) {
				// Node overlaps with new interval, but doesn't completely contain, so extend.
				if (curr.interval.start > start) {
					curr.interval.start = start;
					const rightMost = curr.left?.getRightMostLeaf();
					if (rightMost && rightMost.interval.end >= start - 1) {
						curr.interval.start = rightMost.interval.start;
						if (rightMost.parent !== curr) {
							rightMost.parent.right = null;
						}
					}
				}
				if (curr.interval.end < end) {
					curr.interval.end = end;
					const leftMost = curr.right?.getLeftMostLeaf();
					if (leftMost && leftMost.interval.start <= end + 1) {
						curr.interval.end = leftMost.interval.end;
						if (leftMost.parent !== curr) {
							leftMost.parent.left = null;
						}
					}
				}
			} else if (end < curr.interval.start - 1) {
				// value is somewhere to the left

				if (curr.left) {
					curr = curr.left;
				} else {
					curr = curr.left = new DiscreteIntervalNode(start, end, curr);
				}
			} else if (end === curr.interval.start - 1) {
				// value borders left

				if (curr.left && start === curr.left.interval.end + 1) {
					// absorb left child
					curr.absorbLeft(curr.left);
				} else {
					// just extend 1 to the left
					curr.interval.start = start;
				}
			} else if (start === curr.interval.end + 1) {
				// value borders right

				if (curr.right && end === curr.right.interval.start - 1) {
					// absorb right child
					curr.absorbRight(curr.right);
				} else {
					// just extend 1 to the right
					curr.interval.end = start;
				}
			} else if (start > curr.interval.end + 1) {
				// value is somewhere to the right
				if (curr.right) {
					curr = curr.right;
				} else {
					curr = curr.right = new DiscreteIntervalNode(start, end, curr);
				}
			}
		}
	}

	removeNode(node) {
		if (node.isLeaf()) {
			if (node === this.root) {
				this.root = null;
			} else {
				if (node === node.parent.left) {
					node.parent.left = null;
				} else if (node === node.parent.right) {
					node.parent.right = null;
				}
			}

			return null;
		} else if (node.hasOneChild()) {
			const child = node.left || node.right;
			node.interval = child.interval;
			node.replaceParent(child);

			return node;
		} else {
			const replacement = node.getLeftMostLeaf();
			node.interval = replacement.interval;
			this.removeNode(replacement);
			return node;
		}
	}

	remove(start, end) {
		return this.removeFromNode(this.root, start, end);
	}
	removeFromNode(node, start, end) {
		let curr = node;

		while (curr) {
			if (start > curr.interval.start && end < curr.interval.end) {
				// split node
				const prevEnd = curr.interval.end;
				curr.interval.end = start - 1;
				this.add(end + 1, prevEnd);
				return true;
			} else if (end < curr.interval.start) {
				// look left
				curr = curr.left;
			} else if (start > curr.interval.end) {
				// look right
				curr = curr.right;
			} else if (start <= curr.interval.start && end >= curr.interval.end) {
				// remove entire node
				curr = this.removeNode(curr);
				//curr = curr.right;
			} else if (start <= curr.interval.start && end < curr.interval.end) {
				// prune left
				curr.interval.start = end + 1;
				curr = curr.left;
			} else if (start > curr.interval.start && end >= curr.interval.end) {
				// prune right
				curr.interval.end = start - 1;
				curr = curr.right;
			}
		}

		return false;
	}
}
