import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CanvasTimeline from './../canvas-timeline/canvas_timeline';
import {FormattedMessage, injectIntl} from 'react-intl';
import UploadingOverlay from '../../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import {BUTTON_COLOR, BUTTON_STYLE, MODULE_TYPES, SCHEDULING_ACTION_MENU_TYPE, WorkflowCategories} from '../../../constants';
import {
	AUTO_SCHEDULING_PERSON_WIDTH,
	drawHexagon,
	drawRectangle,
	getCanvasTimelineDateFromMoment,
	getInitialEyeOptions,
	getMomentFromCanvasTimelineDate,
	getSchedulingOptionVisualizationMode,
	getVisualizationMode,
	GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE,
	GROUP_SECTION_SINGLE_ACTION_IMAGE_SIZE,
	GROUP_SECTION_WIDTH,
	GROUP_TYPE,
	hasViewOnlyAccess,
	HEATMAP_CELL_DETAIL_BOX_HEIGHT,
	HEATMAP_CELL_DETAIL_BOX_WIDTH,
	isSimulationMode,
	ITEM_TYPE,
	moveItem,
	setSimulationMode,
	UTILIZATION_FORMAT,
	VISUALIZATION_MODE,
} from '../canvas-timeline/canvas_timeline_util';
import {
	trackPerformance,
	trackPerformanceDataLoad,
	trackPerformanceInit,
} from '../canvas-timeline/canvas_timeline_performance_track';
import Util from '../../../forecast-app/shared/util/util';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import ActionMenu from '../action_menu';
import DistributionBox from './canvas_projects_scheduling_distribution_box';
import Moment, * as moment from 'moment';
import {dispatch, EVENT_ID, subscribe, unsubscribe} from '../../../containers/event_manager';
import ContextMenu from '../../../forecast-app/shared/components/context-menus/context_menu';
import TaskDetailBox from '../components/task_detail_box';
import AllocationDetailBox from '../components/allocation_detail_box';
import Person from '../../../forecast-app/shared/components/person/person';
import {cacheManager} from '../canvas-timeline/canvas_timeline_cache_manager';
import AutoSchedulingDropdown from './autoscheduling/auto_scheduling_dropdown';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import * as tracking from '../../../tracking';
import {handleProjectsSchedulingMutation} from './projects_scheduling_mutation_success';
import {getGroupsAndItemsFromProps} from './projects_scheduling_data';
import {handleAutoSchedulingErrors} from './autoscheduling/handle_errors';
import {
	addFooterPerson,
	fetchPersonHeatmapData,
	getFilteredFooterPersons,
	handleFooterPersonMouseEnter,
	handleFooterPersonMouseLeave,
	onPersonMouseDown,
	removeFooterPerson,
	updateDropdownSearchCriteria,
} from './autoscheduling/footer_persons';
import {getDependencyChainTaskIdSet, getDependencyData, onDependencyCreation} from './projects_scheduling_dependencies';
import {prepareAutoScheduleData} from './autoscheduling/prepare_autoscheduling_data';
import {disableSimulationMode, onApplySimulationChanges} from './autoscheduling/auto_scheduling_apply';
import Button from '../../../forecast-app/shared/components/buttons/button/button';
import {createHeader, getTopHeaderContent} from './projects_scheduling_header';
import {fetchData, fetchLightWeightData} from './projects_scheduling_fetch';
import {fetchInit} from '../scheduling_fetch';
import {fetchAutoSchedulingData} from './autoscheduling/auto_scheduling_fetch';
import {fetchAutoSchedulingProposal} from './autoscheduling/auto_scheduling_proposal';
import AiLoader from '../../loaders/ai_loader';
import {handleNotification} from './projects_scheduling_socket';
import {createLightWeightProjectsGroupsAndItems} from './projects_scheduling_lightweight';
import {getFilterFunctions} from '../../../forecast-app/shared/components/filters/filter_logic';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {hasPermission, isClientUser, setPermissions} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {
	getFilterString,
	getPhaseCompletion,
	getProjectGroups,
	getSubTasks,
	getTopParentId,
	isTaskInHierarchy,
} from './projects_scheduling_util';
import {hasFeatureFlag, setAvailableFeatureFlags} from '../../../forecast-app/shared/util/FeatureUtil';
import targetPhaseIcon from '../../../images/components/scheduling/target-phase.svg';
import addIcon from '../../../images/components/scheduling/add-icon.svg';
import HeatmapCellDetailBox, {getHeatmapCellDetailBoxCoords} from '../components/heatmap_cell_detail_box';
import PlaceholderInformationTooltip from '../components/placeholder_information_tooltip';
import PlaceholderAllocationDetailBox from '../components/placeholder_allocation_detail_box';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {trackComplexEvent, trackEvent, trackPage, unregisterPageInfo} from '../../../tracking/amplitude/TrackingV2';
import {
	ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN,
	ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD,
	ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT,
	PROJECT_SUB_GROUP_TYPE,
} from '../constants';
import {findProject, isEventFromScheduling, removeFromArray} from '../utils';
import {updateAllocationItemsVisibility} from '../components/allocation_controls/AllocationControlsCanvasUtils';
import {filterGroupsAndItems} from './ProjectsSchedulingFilterLogic';
import {getRecalculationInterval, recalculateGroupHeatmapCache} from '../heatmap/HeatmapLogic';
import CanvasTooltip from '../components/CanvasTooltip';
import ProjectHeader from '../../../forecast-app/project-tab/projects/shared/ProjectHeader';
import IncrementalLoadingOverlay, {LOAD_MORE, LOAD_MORE_DIRECTION} from '../loading/IncrementalLoadingOverlay';
import {
	drawIncrementalLoadMoreOverlay,
	getOverlayCanvasDate,
	preventScrollingPastLoadMoreOverlay,
} from '../loading/LoadMoreUtil';
import DataManager, {DATA_ENTITIES} from '../DataManager';
import PlaceholderAllocationItem from '../components/items/placeholder_allocation_item';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import TaskItem from '../components/items/task_item';
import TaskGroup from '../components/groups/task_group';
import {PROJECTS_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY} from './ProjectsSchedulingConstants';
import EventManager from '../EventManager';
import ComposeManager from '../ComposeManager';
import IDManager, {PLACEHOLDERS_PROJECT_SUB_GROUP} from '../IDManager';
import RecalculationManager from '../RecalculationManager';
import {profilePicSrc} from '../../../directApi';

class CanvasProjectsScheduling extends Component {
	// Used for identifying minified components in cypress tests
	static displayName = 'CanvasProjectsScheduling';

	constructor(props) {
		super(props);
		const pageName = this.props.isProjectTimeline ? 'Schedule Timeline' : 'Schedule Projects';
		if (!props.sharedContext) {
			trackPerformanceInit(pageName);
		}

		const todayDate = getCanvasTimelineDateFromMoment(Moment());

		const labelFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-label')) || [];
		const projectFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-project')) || [];
		const clientFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-client')) || [];
		const roleFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-role')) || [];
		const projectStatusFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-projectStatus')) || [];
		const projectStageFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-projectStage')) || [];
		const personFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-person')) || [];
		const teamFilterValue = JSON.parse(localStorage.getItem('canvas-scheduling-filter-value-team')) || [];

		const visualizationMode = getSchedulingOptionVisualizationMode(
			props.viewer.company,
			undefined,
			!hasFeatureFlag('combined_heatmap_logic_extensions')
		);

		this.state = {
			heatmapFiltering: hasFeatureFlag('people_scheduling_enable_filter_heatmap_issue'),
			actionsMenuData: null,
			dataLoaded: false,
			simulationMode: false,
			assignedPersonHoverData: null,
			todayDate,
			footerPersons: [],
			footerPersonHoverData: null,
			autoSchedulingDropdownSearchCriteria: '',
			shouldShowScrollFade: false,
			timelineHeatmapData: this.props.timelineHeatmapData,
			isDraggingWholeDependencyChainModeOn: localStorage.getItem('isDraggingWholeDependencyChainModeOn') === 'true',
			labelFilterValue,
			projectFilterValue,
			clientFilterValue,
			roleFilterValue,
			projectStatusFilterValue,
			projectStageFilterValue,
			personFilterValue,
			teamFilterValue,
			searchFilterValue: '',
			splitAllocationBarData: {},
			splitPlaceholderAllocationBarData: {},
			showLoadingOverlay: false,
			totalMinutesMap: new Map(),
			schedulingOptions: {
				utilizationFormat: localStorage.getItem(PROJECTS_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY)
					? localStorage.getItem(PROJECTS_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY)
					: UTILIZATION_FORMAT.HOURS,
				calcWin: hasModule(MODULE_TYPES.CALC_WIN_PERCENTAGE)
					? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_CALC_WIN) === 'true'
					: false,
				hideSoft: hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
					? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_SOFT) === 'true'
					: true,
				hideHard: hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)
					? localStorage.getItem(ALLOCATION_CONTROL_BOX_ALLOCATION_HIDE_HARD) === 'true'
					: false,
				visualizationMode,
			},
			weekendOptions: props.weekendOptions,
			eyeOptions: getInitialEyeOptions(this),
			[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.LEFT]: false,
			[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.RIGHT]: false,
			[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]: getOverlayCanvasDate(this, null, LOAD_MORE_DIRECTION.LEFT),
			[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]: getOverlayCanvasDate(this, null, LOAD_MORE_DIRECTION.RIGHT),
		};

		this.onSchedulingModalMutationSuccess = this.onSchedulingModalMutationSuccess.bind(this);
		subscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingModalMutationSuccess);

		this.reloadData = this.reloadData.bind(this);
		subscribe(EVENT_ID.SCHEDULING_RELOAD_DATA, this.reloadData);

		this.onClick = event => EventManager.onClick(this, event);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
		this.onMouseMove = event => EventManager.onMouseMove(this, event);
		this.updateSimulationChangeMap = this.updateSimulationChangeMap.bind(this);

		this.personUtilizationCache = new Map();
		this.heatmapCache = new Map();
		this.fetchedProjectIds = new Set();
		RecalculationManager.clearAll();

		this.visibleTaskData = [];

		this.onSavedFiltersUpdate = this.onSavedFiltersUpdate.bind(this);
		subscribe(EVENT_ID.SAVED_FILTERS_UPDATE, this.onSavedFiltersUpdate);
		this.handleSocketNotify = this.handleSocketNotify.bind(this);

		fetchInit(props.shareUrl).then(initData => {
			setAvailableFeatureFlags(initData.featureFlags);
			if (initData.projectSchedulingShare && initData.permissions) {
				setPermissions(initData.permissions);
			}
			this.setState(
				{
					initData,
				},
				() => {
					this.isUsingNewLazyLoad = this.state.initData.featureFlags.some(
						featureFlag => featureFlag.key === 'new_project_schedule_lazy_load'
					);

					// disable lazy load for timeline
					if (this.props.isProjectTimeline) {
						this.isUsingNewLazyLoad = false;
					}

					// Disable lazy load for sharing
					if (this.props.sharedContext) {
						this.isUsingNewLazyLoad = false;
					}

					if (!props.isProjectTimeline) {
						this.doFetchMinimalData(props);
					} else {
						this.doFetchFullData(props);
					}
				}
			);
		});

		this.superPropertyChecksum = trackPage(pageName);
	}

	componentDidMount() {
		if (!this.props.sharedContext) {
			const pageName = this.props.isProjectTimeline ? 'scheduling-timeline' : 'scheduling-projects';
			tracking.trackPage(pageName);
		}

		window.addEventListener('click', this.onClick);
		window.addEventListener('keyup', this.onKeyUp);
		window.addEventListener('mousemove', this.onMouseMove);
		document.body.addEventListener('keydown', this.onKeyDown);

		subscribe(EVENT_ID.SOCKET_NOTIFY, this.handleSocketNotify);

		if (this.state.simulationMode) {
			this.setScrollFade();
		}
	}

	componentDidUpdate() {
		if (this.state.dataFetched && this.state.movedItem) {
			this.onProjectItemMoveEnd(
				this.state.movedItem.item,
				this.state.movedItem.group,
				this.state.movedItem.initialGroup,
				this.state.movedItem.dragData
			);
		}
	}

	componentWillUnmount() {
		unregisterPageInfo(this.superPropertyChecksum);

		this.heatmapCache.clear();

		window.removeEventListener('click', this.onClick);
		window.removeEventListener('keyup', this.onKeyUp);
		window.removeEventListener('mousemove', this.onMouseMove);
		document.body.removeEventListener('keydown', this.onKeyDown);

		unsubscribe(EVENT_ID.SCHEDULING_MODAL_MUTATION_SUCCESS, this.onSchedulingModalMutationSuccess);
		unsubscribe(EVENT_ID.SCHEDULING_RELOAD_DATA, this.reloadData);
		unsubscribe(EVENT_ID.SOCKET_NOTIFY, this.handleSocketNotify);

		if (this.state.simulationMode) {
			setSimulationMode(false);
		}
	}

	redrawCanvasTimeline(args = {}) {
		if (!args.preventFiltering) {
			trackPerformance('Filtered', () => filterGroupsAndItems(this));
		}

		dispatch(EVENT_ID.CANVAS_TIMELINE_FORCE_REDRAW, args);
	}

	handleSocketNotify(dataList) {
		if (this.state.initData) {
			handleNotification(this, dataList);
		}
	}

	getTimeRegGroupId(timeReg) {
		const {data} = this.state;
		const {taskMap} = data;

		const task = taskMap.get(timeReg.taskId);
		if (task) {
			const {projectId} = task;
			const {personId} = timeReg;
			return IDManager.getPersonGroupId(this, personId, projectId);
		}

		return null;
	}

	createEntityItem(createdItems, entity, entityData) {
		const {groups} = this.state;

		let itemData;
		switch (entity) {
			case DATA_ENTITIES.PLACEHOLDER_ALLOCATIONS:
				itemData = ComposeManager.composePlaceholderAllocation(this, entityData);

				if (itemData) {
					createdItems.add(new PlaceholderAllocationItem(this, itemData));
				}

				break;
			case DATA_ENTITIES.ALLOCATIONS:
				itemData = ComposeManager.composeProjectAllocation(this, entityData);

				if (itemData) {
					createdItems.add(new ProjectAllocationItem(this, itemData));
				}

				break;
			case DATA_ENTITIES.TASKS:
				itemData = ComposeManager.composeTaskItem(this, entityData);

				if (itemData) {
					if (!entityData.parentTaskId) {
						const projectGroups = getProjectGroups(groups);
						const projectGroup = projectGroups.find(projectGroup => projectGroup.id === entityData.projectId);
						const phasesGroup = projectGroup.groups.find(subGroup =>
							subGroup.id.includes(PROJECT_SUB_GROUP_TYPE.PHASES)
						);
						const phaseGroupId = IDManager.getPhaseGroupId(this, entityData.phaseId, entityData.projectId);
						const phaseGroup = phasesGroup.groups.find(phaseGroup => phaseGroup.id === phaseGroupId);

						if (phaseGroup) {
							const taskGroupData = ComposeManager.composeTaskGroup(this, entityData, 0, true);

							if (taskGroupData) {
								phaseGroup.addChildGroup(new TaskGroup(this, taskGroupData));
								createdItems.add(new TaskItem(this, itemData));
							}
						}
					} else {
						createdItems.add(new TaskItem(this, itemData));
					}
				}

				break;
			default:
				break;
		}
	}

	doFetchMinimalData(props) {
		trackPerformanceDataLoad(fetchLightWeightData(props)).then(lightWeightDataArray => {
			const lightWeightData = lightWeightDataArray[0];
			const timelineHeatmapData = lightWeightDataArray[1] || undefined;
			const shareUrl = props.match.params.shareUrl;

			if (
				!lightWeightData ||
				lightWeightData.error ||
				(props.isProjectTimeline && !shareUrl && !props.groupId && (!timelineHeatmapData || timelineHeatmapData.error))
			) {
				showModal({
					type: MODAL_TYPE.MUTATION_ERROR,
					isNetworkError: false,
					reload_to_upcoming: true,
				});
			} else {
				DataManager.setLookupMaps(this, lightWeightData);

				if (this.isUsingNewLazyLoad) {
					lightWeightData.persons.forEach(person => {
						person.startDate =
							this.isUsingResourceService && person.startDate
								? getCanvasTimelineDateFromMoment(moment.utc(person.startDate, 'YYYY-MM-DD'))
								: null;
						person.endDate =
							this.isUsingResourceService && person.endDate
								? getCanvasTimelineDateFromMoment(moment.utc(person.endDate, 'YYYY-MM-DD'))
								: null;
					});
					lightWeightData.personAllocationMap = lightWeightData.allocations
						? _.groupBy(lightWeightData.allocations, 'personId')
						: {};
					lightWeightData.placeholderAllocationsByPlaceholder = new Map();
					lightWeightData.placeholderSkillsByPlaceholder = new Map();
					lightWeightData.placeholderSkills = [];
					lightWeightData.taskLabelsByTask = new Map();
					lightWeightData.timeRegsByTaskMap = lightWeightData.timeRegistrations
						? _.groupBy(lightWeightData.timeRegistrations, 'taskId')
						: {};
					lightWeightData.timeRegsByPersonMap = lightWeightData.timeRegistrations
						? _.groupBy(lightWeightData.timeRegistrations, 'personId')
						: {};
					lightWeightData.phaseIdToTaskIdsMap = new Map();
					lightWeightData.taskMap = new Map();
					lightWeightData.phases = [];
					lightWeightData.tasks = [];
					lightWeightData.timeRegistrations = [];
					lightWeightData.dependencies = [];
				}

				if (isClientUser()) {
					this.props.history.push(
						props.isProjectTimeline
							? `/project/${props.match.params.projectGroupId ? 'X' : 'P'}-${
									props.match.params.projectGroupId || props.projectId
							  }/workflow`
							: '/'
					);
					return;
				}

				let projectTimelineProjectStatus = null;
				if (props.isProjectTimeline && props.projectId != null) {
					let project = DataManager.getProjectById(this, props?.viewer?.project?.id);
					if (project) {
						projectTimelineProjectStatus = project.status;
					}
				}

				let isShowingAutoScheduleOnLoad =
					hasFeatureFlag('auto_schedule_access') && window.location.hash === '#auto-schedule';
				let userCanAutoSchedule = true;

				if (!hasPermission(PERMISSION_TYPE.SCHEDULING_ACCESS)) {
					isShowingAutoScheduleOnLoad = false;
					userCanAutoSchedule = false;
				}

				this.setState(
					{
						lightWeightData,
					},
					() => {
						const {groups, items} = createLightWeightProjectsGroupsAndItems(this);

						this.setInitialExpansion(groups);

						const filters = this.getInitFilters(lightWeightData, shareUrl);

						const {viewer} = lightWeightData;

						const filterFunctions = getFilterFunctions(filters);

						this.setState(
							{
								viewer,
								lightweightDataLoaded: true,
								groups,
								items,
								filters,
								project: DataManager.getProjectById(this, viewer?.project?.id),
								projectTimelineProjectStatus,
								timelineHeatmapData,
								isShowingAutoScheduleOnLoad: isShowingAutoScheduleOnLoad,
								userCanAutoSchedule: userCanAutoSchedule,
								filterFunctions,
							},
							() => {
								if (!this.isUsingNewLazyLoad) {
									this.doFetchFullData(props);
								}
							}
						);
					}
				);
			}
			this.redrawCanvasTimeline({preventFiltering: false});
		});
	}

	async expandLazyLoadGroup(group, isExpandAll) {
		const lazyLoadedGroups = [GROUP_TYPE.PROJECT, GROUP_TYPE.PROGRAM];

		if (
			this.isUsingNewLazyLoad &&
			!isExpandAll &&
			!group.fullDataLoaded &&
			!group.loadInitialized &&
			!group.parentGroup &&
			lazyLoadedGroups.includes(group.groupType)
		) {
			const {companyProjectGroupId, isInProjectGroup, companyProjectId, program, isInProgram} = group.data;
			group.loadInitialized = true;

			if (companyProjectGroupId) {
				await this.fetchProjectData(this.props, null, companyProjectGroupId);
			} else if (program?.prefix) {
				await this.fetchProjectData(this.props, null, null, program.prefix);
			} else if (companyProjectId && !isInProjectGroup && !isInProgram) {
				await this.fetchProjectData(this.props, companyProjectId, null);
			}
		}

		// ensure that if a new dependency appeared on the screen upon expansion,
		// we redraw the timeline so that it shows all tasks within that dependency chain
		if (!this.groupExpansionToggleRedrawFrame) {
			this.groupExpansionToggleRedrawFrame = requestAnimationFrame(() => {
				if (this.visibleTaskData.length !== this.previousVisibleTaskDataLength) {
					this.redrawCanvasTimeline({preventFiltering: true});
				}

				this.groupExpansionToggleRedrawFrame = null;
			});
		}
	}

	fetchProjectData(props, companyProjectId, companyProjectGroupId, programPrefix) {
		this.setState({loadingProject: true});

		const projectIdentifier = companyProjectId || companyProjectGroupId || programPrefix;
		return Promise.resolve(
			fetchData(this, props, companyProjectId, companyProjectGroupId, programPrefix).then(dataArray => {
				const newData = dataArray[0];
				if (!newData || newData.error) {
					showModal({
						type: MODAL_TYPE.MUTATION_ERROR,
						isNetworkError: false,
						reload_to_upcoming: true,
					});
				} else if (!hasFeatureFlag('all_timelines_duplication_fix') || !this.fetchedProjectIds.has(projectIdentifier)) {
					this.fetchedProjectIds.add(projectIdentifier);

					const data = this.getFilterData();

					if (newData.placeholders?.length > 0) {
						data.placeholders = data.placeholders
							? data.placeholders.concat(newData.placeholders)
							: newData.placeholders;
					}

					if (newData.placeholderAllocations?.length > 0) {
						data.placeholderAllocations = data.placeholderAllocations
							? data.placeholderAllocations.concat(newData.placeholderAllocations)
							: newData.placeholderAllocations;
					}

					data.phases.push(...(newData.phases || []));
					data.allocations.push(...(newData.allocations || []));
					data.tasks.push(...(newData.tasks || []));
					data.timeRegistrations.push(...(newData.timeRegistrations || []));
					data.dependencies.push(...(newData.dependencies || []));

					if (companyProjectId) {
						const projectIndex = data.projects.findIndex(project => project.companyProjectId === companyProjectId);
						data.projects[projectIndex] = newData.projects.find(
							project => project.companyProjectId === companyProjectId
						);
					} else if (companyProjectGroupId) {
						const projectGroupIndex = data.projectGroups.findIndex(
							projectGroup => projectGroup.companyProjectGroupId === companyProjectGroupId
						);
						data.projectGroups[projectGroupIndex] = newData.projectGroups.find(
							projectGroup => projectGroup.companyProjectGroupId === companyProjectGroupId
						);
						newData.projects.forEach(newProject => {
							const projectIndex = data.projects.findIndex(
								project => project.companyProjectId === newProject.companyProjectId
							);
							data.projects[projectIndex] = newProject;
						});
					} else if (programPrefix) {
						const programPredicate = program => program.prefix === programPrefix;
						const newProgram = newData.programs.find(programPredicate);
						removeFromArray(data.programs, programPredicate, newProgram);
						newData.projects.forEach(newProject => {
							removeFromArray(
								data.projects,
								project => project.companyProjectId === newProject.companyProjectId,
								newProject
							);
						});
					}

					DataManager.setLookupMaps(this, data);

					this.setState(
						{
							data: data,
							dataFetched: true,
							lightWeightData: null,
						},
						() => {
							const {groups: projectGroups, items: projectItems} = getGroupsAndItemsFromProps(
								this,
								props,
								newData
							);
							this.setInitialExpansion(projectGroups);
							const groups = this.state.groups;

							if (companyProjectId) {
								const newProjectGroup = projectGroups.find(
									projectGroup => projectGroup.data.companyProjectId === companyProjectId
								);
								const projectGroupIndex = groups.findIndex(
									group => group.data.companyProjectId === companyProjectId
								);
								groups[projectGroupIndex] = newProjectGroup;
							} else if (companyProjectGroupId) {
								const newProjectGroup = projectGroups.find(
									projectGroup => projectGroup.data.companyProjectGroupId === companyProjectGroupId
								);
								const projectGroupIndex = groups.findIndex(
									group => group.data.companyProjectGroupId === companyProjectGroupId
								);
								groups[projectGroupIndex] = newProjectGroup;
							} else if (programPrefix) {
								const programPredicate = group => group.data.program?.prefix === programPrefix;
								const newProgramGroup = projectGroups.find(programPredicate);
								const programGroupIndex = groups.findIndex(programPredicate);
								groups[programGroupIndex] = newProgramGroup;
							}

							const items = this.state.items;
							const newProjectItems = projectItems.filter(
								item => item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PROJECT
							);
							newProjectItems.forEach(projectItem => {
								const oldProjectItemIndex = items.findIndex(item => item.groupId === projectItem.groupId);
								if (oldProjectItemIndex > -1) {
									items.splice(oldProjectItemIndex, 1);
								}
							});
							items.push(...projectItems);

							this.setState(
								{
									groups,
									items,
									savingMutation: false,
									dependencies: data.dependencies,
									loadingProject: false,
								},
								() => {
									if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
										DataManager.constructVisibleItemTrees(this);
									}

									updateAllocationItemsVisibility(this);
									this.redrawCanvasTimeline({preventFiltering: false, isInitialLoad: true});
								}
							);
						}
					);
				}
			})
		);
	}

	doFetchFullData(props) {
		trackPerformanceDataLoad(fetchData(this, props)).then(dataArray => {
			const data = dataArray[0];
			const timelineHeatmapData = dataArray[1] || undefined;

			const {company} = this.props.viewer;
			const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(company);

			if (
				!data ||
				data.error ||
				(!props.shareUrl &&
					!props.programPrefix &&
					props.isProjectTimeline &&
					!isMixedAllocationModeEnabled &&
					!props.groupId &&
					(!timelineHeatmapData || timelineHeatmapData.error))
			) {
				showModal({
					type: MODAL_TYPE.MUTATION_ERROR,
					isNetworkError: false,
					reload_to_upcoming: true,
				});
			} else {
				data.persons.forEach(person => {
					person.startDate = person.startDate
						? getCanvasTimelineDateFromMoment(moment.utc(person.startDate, 'YYYY-MM-DD'))
						: null;
					person.endDate = person.endDate
						? getCanvasTimelineDateFromMoment(moment.utc(person.endDate, 'YYYY-MM-DD'))
						: null;
				});
				this.resetState(props, data, timelineHeatmapData);
			}
		});
	}

	resetState(props, data, timelineHeatmapData) {
		const resetProps = props || this.props;
		const resetData = data || this.state.data;
		const shareUrl = resetProps.match.params.shareUrl;

		DataManager.setLookupMaps(this, resetData);

		let isShowingAutoScheduleOnLoad = this.state.isShowingAutoScheduleOnLoad;
		if (isShowingAutoScheduleOnLoad === undefined) {
			isShowingAutoScheduleOnLoad = hasFeatureFlag('auto_schedule_access') && window.location.hash === '#auto-schedule';
		}
		let userCanAutoSchedule = true;
		if (!hasPermission(PERMISSION_TYPE.SCHEDULING_ACCESS)) {
			isShowingAutoScheduleOnLoad = false;
			userCanAutoSchedule = false;
		}

		this.setState(
			{
				data: resetData,
				dataFetched: true,
				lightWeightData: null,
			},
			() => {
				const {viewer} = resetData;

				if (!hasFeatureFlag('inverted_pto_non_working_days')) {
					DataManager.constructNonWorkingDaysMap(this);
				}

				const {groups, items} = getGroupsAndItemsFromProps(this, resetProps, resetData);

				this.setInitialExpansion(groups);

				const filters = this.getInitFilters(resetData, shareUrl);

				const filterFunctions = getFilterFunctions(filters);

				let project = DataManager.getProjectById(this, resetProps?.viewer?.project?.id);

				if (!project) {
					// When sharing a project timeline, the project id is saved as companyProjectId and we
					// have no viewer.project.id, so we need to get the project from the companyProjectId
					project = findProject(resetData.projects, this.props.projectId);
				}

				this.setState(
					{
						viewer,
						dataLoaded: true,
						groups,
						items,
						filters,
						savingMutation: false,
						project: project,
						dependencies: resetData.dependencies,
						isShowingProjectTimelineSimulationMode: false,
						isShowingAutoScheduleLoader: false,
						projectTimelineProjectStatus: project?.status,
						timelineHeatmapData: timelineHeatmapData ?? this.state.timelineHeatmapData,
						isShowingAutoScheduleOnLoad: isShowingAutoScheduleOnLoad,
						userCanAutoSchedule: userCanAutoSchedule,
						filterFunctions,
					},
					() => {
						const {isProjectTimeline, sharedContext, programPrefix} = this.props;
						if (
							isProjectTimeline &&
							!sharedContext &&
							!programPrefix &&
							hasFeatureFlag('improving_heatmap_frontend_performance')
						) {
							DataManager.constructVisibleItemTrees(this);
						}

						if (this.mutationResponse) {
							this.onSchedulingModalMutationSuccess(this.mutationResponse);
						}
						updateAllocationItemsVisibility(this);
						this.redrawCanvasTimeline({preventFiltering: false, isInitialLoad: true, allowRedraw: true});
					}
				);
			}
		);
	}

	reloadData() {
		this.personUtilizationCache.clear();
		this.heatmapCache.clear();
		this.doFetchFullData(this.props);
	}

	getData() {
		return this.state.data;
	}

	// function used by the old filter which is shared with people schedule scheduling_old_filter.js
	getFilterData() {
		return this.state.data ? this.state.data : this.state.lightWeightData;
	}

	getInitFilters(data, shareUrl) {
		let filters;

		const filterString = getFilterString(this);

		if (localStorage.getItem(filterString) === 'undefined') {
			localStorage.removeItem(filterString);
		}

		if (!shareUrl && localStorage.getItem(filterString)) {
			const localStorageFilters = JSON.parse(localStorage.getItem(filterString));

			if (localStorageFilters && Object.keys(localStorageFilters).length > 0) {
				filters = localStorageFilters;
			}
		} else {
			filters = {
				person: {},
				task: {},
				project: {},
			};
		}

		return filters;
	}

	showAutoSchedulingModal() {
		if (this.state.dataLoaded) {
			window.history.pushState('', document.title, window.location.pathname + window.location.search);
			tracking.trackElementClicked('Auto Schedule project from the project Timeline');
			trackComplexEvent('Auto Schedule Modal', 'Shown', {location: 'From Project Timeline'});

			const project = DataManager.getProjectById(this, this?.props?.viewer?.project?.id);
			const phases = this.getData().phases.filter(phase => phase.projectId === project.id);
			const tasks = this.getData().tasks.filter(task => task.projectId === project.id);
			const projectPersons = this.getData().projectPersons;

			// array that will contain the phases that will not be scheduled
			let notQualifiedPhasesNames = [];
			// check if there are phases that do not contain any task or any approved tasks to show a warning
			for (let phase of phases) {
				if (!tasks.some(task => task.phaseId === phase.id && task.approved)) {
					notQualifiedPhasesNames.push(phase.name);
				}
			}

			showModal({
				type: MODAL_TYPE.AUTO_SCHEDULING,
				phases,
				tasks,
				projectPersons,
				companyPersons: this.state.data.persons,
				project,
				roles: this.state.data.roles,
				notQualifiedPhasesNames,
				onAutoSchedulingConfig: this.handleAutoScheduleProposalRequest.bind(this),
				fetchPersonHeatmapData: personIds => {
					fetchPersonHeatmapData(this, personIds).then(data => {
						this.setState({timelineHeatmapData: data});
					});
				},
			});

			// Fetch data needed on the preview screen. This is to give feedback on
			// over-allocation, when dragging tasks around.
			fetchAutoSchedulingData().then(data => {
				const stateData = this.state.data || {};
				stateData.allTasks = data.tasks;
				stateData.allTimeRegistrations = data.timeRegistrations;
				stateData.allAllocations = data.allocations;
				this.setState({data: stateData});
			});
			this.setState({
				loadingAutoSchedule: false,
				showLoader: false,
			});
		} else {
			this.setState({
				loadingAutoSchedule: true,
				showLoader: true,
			});
		}
	}

	onRescheduleButtonPress() {
		this.handleAutoScheduleProposalRequest(this.state.autoSchedulingConfig);
	}

	handleAutoScheduleProposalRequest(autoSchedulingConfig) {
		const {formatMessage} = this.props.intl;

		this.setState({isShowingAutoScheduleLoader: true});
		return fetchAutoSchedulingProposal(autoSchedulingConfig).then(autoScheduleProposalData => {
			if (autoScheduleProposalData.status !== 200) {
				return;
			}
			setSimulationMode(true);

			const {
				extraPersonsAdded,
				autoSchedulingRequestConfig: {personRole},
			} = autoSchedulingConfig;

			const {groups, items} = this.state;
			const originalItems = [...items];

			const autoScheduleProjectTeam = personRole.map(personRole => ({
				personId: btoa('Person:' + personRole.personId),
				roleId: personRole.roleId ? btoa('Role:' + personRole.roleId) : null,
			}));

			const {autoSchedulingErrors, footerPersons, footerWarnings, simulationChangeMap} = prepareAutoScheduleData(
				this,
				autoScheduleProposalData,
				autoScheduleProjectTeam,
				groups,
				items
			);

			this.setState(
				{
					originalItems,
					simulationMode: true,
					isShowingAutoScheduleLoader: false,
					isShowingProjectTimelineSimulationMode: true,
					autoScheduleProposalData,
					autoSchedulingConfig,
					autoSchedulingErrors,
					footerPersons,
					footerWarnings,
					simulationChangeMap,
					autoScheduleProjectTeam,
				},
				() => {
					this.setInitialExpansion(groups);
					this.timeline.setInitialZoomLevel();
				}
			);

			if (extraPersonsAdded && extraPersonsAdded.length) {
				//Show modal telling user that extra persons were added to the project
				showModal({
					type: MODAL_TYPE.GENERIC,
					className: 'auto-schedule-extra-persons',
					content: (
						<FormattedMessage
							id="auto_scheduling.modal.extra_people_added"
							values={{
								numOfPeople: extraPersonsAdded.length,
								people: (
									<strong>
										<ul>
											{extraPersonsAdded.map(person => (
												<li>{person.fullName}</li>
											))}
										</ul>
									</strong>
								),
							}}
						/>
					),
					buttons: [
						{
							text: formatMessage({id: 'common.ok'}),
							style: BUTTON_STYLE.FILLED,
							color: BUTTON_COLOR.WHITE,
						},
					],
				});
			}

			if (autoSchedulingErrors && autoSchedulingErrors.length !== 0) {
				handleAutoSchedulingErrors(this, autoSchedulingErrors);
			}
		});
	}

	setScrollFade() {
		// Is content scrollable
		if (this.scrollbar) {
			const offset = -20;
			const scrollWidth = this.scrollbar.scrollbars.getScrollWidth() + offset;
			const divWidth = this.scrollbar.scrollbars.getClientWidth() + offset;

			if (scrollWidth > divWidth) {
				if (!this.state.shouldShowScrollFade) {
					this.setState({shouldShowScrollFade: true});
				}
			} else {
				if (this.state.shouldShowScrollFade) {
					this.setState({shouldShowScrollFade: false});
				}
			}
		}
	}

	onSavedFiltersUpdate(response) {
		const stateData = this.getFilterData();
		if (response.createFilter) {
			if (this.props.isProjectTimeline) {
				if (this.state.data) {
					const newFilter = {
						id: response.createFilter.filter.node.id,
						name: response.createFilter.filter.node.name,
						section: response.createFilter.filter.node.section,
						value: response.createFilter.filter.node.value,
						personId: this.state.data.viewer.actualPersonId,
						projectId: this.state.data.project ? this.state.data.project.id : null,
						projectGroupId: this.state.data.projectGroup ? this.state.projectGroup.id : null,
					};
					stateData.projectTimelineFilters.push(newFilter);
				}
			} else {
				stateData.projectFilters.push(response.createFilter.filter.node);
			}
			this.setState({data: stateData});
		} else if (response.updateFilter) {
			this.setState({data: stateData});
		} else if (response.deleteFilter) {
			if (this.props.isProjectTimeline) {
				stateData.projectTimelineFilters = stateData.projectTimelineFilters.filter(
					filter => filter.id !== response.deleteFilter.deletedFilterId
				);
			} else {
				stateData.projectFilters = stateData.projectFilters.filter(
					filter => filter.id !== response.deleteFilter.deletedFilterId
				);
			}
			this.setState({data: stateData});
		}
	}

	onDrawForegroundStart() {
		this.previousVisibleTaskDataLength = this.visibleTaskData.length;
		this.visibleTaskData = [];
		this.relevantDependencyTaskIdSet = null;
	}

	onDrawForegroundEnd(context, mouseTargetData) {
		const {autoSchedulingDragPersonId} = this.state;
		const {itemData} = mouseTargetData;
		if (autoSchedulingDragPersonId && itemData && itemData.item.itemType === ITEM_TYPE.TASK) {
			drawRectangle(context, itemData.x - GROUP_SECTION_WIDTH, itemData.y, itemData.width, itemData.height, {
				backgroundOpacity: 0,
				borderThickness: 2,
				borderColor: this.state.project && this.state.project.projectColor,
				isCornerTopRightCutOff: true,
			});
			const cachedImage = cacheManager.get('personImage', autoSchedulingDragPersonId);
			let img = null;

			if (cachedImage) {
				img = cachedImage;
			} else {
				const person = this.getData().persons.find(person => person.id === autoSchedulingDragPersonId);
				if (person.profilePictureId) {
					img = new Image();
					img.crossOrigin = 'use-credentials';
					img.onload = () => {
						cacheManager.set('personImage', autoSchedulingDragPersonId, img);
						EventManager.onPersonImageLoad(this);
					};
					img.src = profilePicSrc(person.profilePictureId);
				} else {
					const initialsXCoor = itemData.x - GROUP_SECTION_WIDTH + itemData.width;
					drawHexagon(context, initialsXCoor, itemData.y, itemData.height, '#6e0fea', 1);

					context.font = '500 11px ' + Util.getFontFamily();
					context.fillStyle = 'white';
					context.textAlign = 'center';
					context.fillText(person.initials.toUpperCase(), initialsXCoor + 12, itemData.y + 18);
					context.textAlign = 'start';
				}
			}

			img &&
				context.drawImage(
					img,
					itemData.x - GROUP_SECTION_WIDTH + itemData.width,
					itemData.y,
					AUTO_SCHEDULING_PERSON_WIDTH,
					itemData.height
				);
		}
	}

	onMouseMoveEnd(mouseTargetData) {
		const {autoSchedulingDragPersonId} = this.state;
		const {itemData} = mouseTargetData;
		const lastHoveredItem = this.lastHoveredItem;
		const hoveredItem = itemData ? itemData.item : undefined;
		if (autoSchedulingDragPersonId && lastHoveredItem !== hoveredItem) {
			this.redrawCanvasTimeline({preventFiltering: true});
		}
		this.lastHoveredItem = hoveredItem;
	}

	getVisibleTasksInHierarchy(task) {
		const {taskMap} = this.state.data;
		if (isTaskInHierarchy(this, task)) {
			const topParentId = getTopParentId(taskMap, task);
			const topParent = taskMap.get(topParentId);
			const tasksHierarchyIds = getSubTasks(this, topParent).map(task => {
				return task.id;
			});

			tasksHierarchyIds.push(topParentId);

			return tasksHierarchyIds
				? this.visibleTaskData.filter(item => {
						return tasksHierarchyIds.includes(item.task.id);
				  })
				: [];
		}
	}

	onMouseUpEnd(mouseTargetData) {
		const {autoSchedulingDragPersonId, simulationChangeMap, items} = this.state;
		if (autoSchedulingDragPersonId) {
			if (
				mouseTargetData &&
				mouseTargetData.itemData &&
				mouseTargetData.itemData.item &&
				mouseTargetData.itemData.item.itemType === ITEM_TYPE.TASK
			) {
				simulationChangeMap
					.get('persons')
					.set(mouseTargetData.itemData.item.data.task.id, [autoSchedulingDragPersonId]);

				const taskItem = items.find(
					item => item.itemType === ITEM_TYPE.TASK && item.data.task.id === mouseTargetData.itemData.item.data.task.id
				);

				if (taskItem) {
					const data = this.getData();
					const {persons, projects} = data;
					const task = taskItem.data.task;
					const person = persons.find(person => person.id === autoSchedulingDragPersonId);
					const project = projects.find(project => project.id === task.projectId);
					const projectOrProjectGroupId = project.projectGroupId || project.id;

					if (task.autoSchedulingPerson) {
						this.clearHeatmapCache(
							IDManager.getPersonGroupId(this, task.autoSchedulingPerson.id, projectOrProjectGroupId)
						);
					}

					task.autoSchedulingPerson = person;
					taskItem.horizontalExtension = 26;

					this.clearHeatmapCache(IDManager.getPersonGroupId(this, person.id, projectOrProjectGroupId));
				}
			}
			requestAnimationFrame(() => {
				this.redrawCanvasTimeline({preventFiltering: false});
			});

			this.setState({
				autoSchedulingDragPersonId: null,
				autoSchedulingDragTranslateX: null,
				autoSchedulingDragTranslateY: null,
				simulationChangeMap,
			});
		}
	}

	setInitialExpansion(groups) {
		const {simulationMode} = this.state;
		if (this.props.expansionMap?.size) {
			const processGroup = group => {
				const isPhasesGroup =
					group.data.groups && group.data.groups.find(group => group.groupType === GROUP_TYPE.PHASE);
				const shouldChangeGroup =
					!isPhasesGroup &&
					(this.props.expansionMap.get(group.id) ||
						(simulationMode && group.data.id.includes(PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM)) ||
						false) !== group.expanded; //if simulation mode is active, expand all the phases expect for the ones that are set not to be expanded
				if (shouldChangeGroup) {
					group.toggleExpansion();
				}
				if (group.groups) {
					for (const childGroup of group.groups) {
						if (!childGroup.parentGroup) {
							childGroup.parentGroup = group;
						}
						processGroup(childGroup);
					}
				}
			};
			for (const group of groups) {
				processGroup(group);
			}
			requestAnimationFrame(() => {
				this.redrawCanvasTimeline({preventFiltering: true});
			});
			return;
		}
		if (this.props.isProjectTimeline) {
			// Expands the timeline by n number of levels by default
			// When in simulation mode, all the levels are expanded
			const defaultProjectNestLevel = simulationMode ? 3 : 2;
			const defaultConnectedProjectNestLevel = simulationMode ? 4 : 3;
			const maxNestLevel = this.props.groupId ? defaultConnectedProjectNestLevel : defaultProjectNestLevel;

			const processGroup = (group, nestLevel) => {
				const isProjectTeam = !simulationMode && group.id.includes(PROJECT_SUB_GROUP_TYPE.PROJECT_TEAM);
				const isPlaceholders = !simulationMode && group.id.includes(PLACEHOLDERS_PROJECT_SUB_GROUP);
				if (nestLevel >= maxNestLevel || isProjectTeam || isPlaceholders) return;
				const shouldChangeGroup =
					!group.expanded && (!this.props.programPrefix || group.groupType === GROUP_TYPE.PROGRAM);
				if (shouldChangeGroup) {
					group.toggleExpansion();
					EventManager.onGroupExpansionToggle(this, group, false);
				}

				if (group.groups) {
					for (const childGroup of group.groups) {
						if (!childGroup.parentGroup) {
							childGroup.parentGroup = group;
						}
						processGroup(childGroup, nestLevel + 1);
					}
				}
			};

			for (const group of groups) {
				processGroup(group, 0);
			}
		}
	}

	onSchedulingModalMutationSuccess(response, args) {
		if (!isEventFromScheduling(args)) {
			return;
		}
		const data = this.getData();
		if (!data) {
			this.mutationResponse = response;
			this.setState({savingMutation: true});
			return;
		}
		handleProjectsSchedulingMutation(this, response, args);
	}

	onKeyDown(e) {
		if (e.key === 'Shift' && !this.isShiftDown) {
			this.isShiftDown = true;
			this.redrawCanvasTimeline({preventFiltering: false});
		}
		//Disable shortcuts in simulation mode
		if (e.altKey && e.shiftKey && this.state.simulationMode) {
			e.stopPropagation();
		}
	}

	onKeyUp(e) {
		if (e.key === 'Shift' && this.isShiftDown) {
			this.isShiftDown = false;
			this.redrawCanvasTimeline({preventFiltering: false});
		}
	}

	activateStaffingMode(placeholderId) {
		this.props.enterStaffingMode(placeholderId);
	}

	isTaskStarted(taskStatusColumnId) {
		//Status column will not be there if a project that contains tasks is duplicated
		//Since all tasks are put in the todo column on duplication, this should not be a problem
		const statusColumn = this.getData().statusColumns.find(statusColumn => statusColumn.id === taskStatusColumnId);
		return statusColumn && statusColumn.category !== WorkflowCategories.TODO;
	}

	onPhasesGroupArrowClick(isPrevious) {
		const {hoveredPhaseGroupIds} = this.state;
		if (!hoveredPhaseGroupIds || !hoveredPhaseGroupIds.length) return;
		const timeline = this.timeline;
		const screenCenterDate = timeline.startDate + timeline.state.canvasWidth / timeline.pixelsPerDay / 2;
		//Make minimum scroll distance 3 days because it makes little sense to fire the animation just to scroll by a few pixels
		const phaseItems = this.state.items.filter(
			item =>
				item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PHASE &&
				hoveredPhaseGroupIds.includes(item.groupId) &&
				(isPrevious ? item.startDate - screenCenterDate < -2 : item.startDate - screenCenterDate > 2)
		);
		if (!phaseItems.length) return; //Already scrolled to furthermost phase
		//Sort by startDate descending, we want to scroll to the item that is the closest to the center in the scrolling direction
		phaseItems.sort((a, b) => {
			if (a.startDate < b.startDate) return isPrevious ? 1 : -1;
			if (b.startDate < a.startDate) return isPrevious ? -1 : 1;
			return 0;
		});
		timeline.scrollToCanvasDate(phaseItems[0].startDate);
		tracking.trackEvent('Scheduling left/right arrow navigation used');
		trackEvent('Scheduling Arrow Navigation', 'Used');
	}

	targetPhase() {
		const {targetPhase} = this.state;

		if (!targetPhase) return;

		// get phase item
		const phaseItem = this.state.items?.find(
			item => item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PHASE && targetPhase.id === item.groupId
		);

		if (!phaseItem) return;

		// scroll phase into view
		const timeline = this.timeline;
		timeline.scrollToCanvasDate(phaseItem.startDate);

		tracking.trackEvent('Scheduling target phase used');
		trackEvent('Scheduling Target Phase', 'Used');
	}

	addPeopleToProjectTeam(project) {
		showModal({
			type: MODAL_TYPE.NEW_PROJECT_V2,
			projectIdToAddPeople: project.id,
			projectGroupId: project.projectGroupId,
		});
		this.setState({
			showExpandedActionMenu: false,
		});
	}

	addPlaceholder(project) {
		if (project) {
			trackEvent('Add Placeholder', 'Clicked');
			showModal({
				type: MODAL_TYPE.NEW_PLACEHOLDER,
				projectId: project.companyProjectGroupId ? null : project.id,
				projectGroupId: project.companyProjectGroupId ? project.id : null,
			});
			this.setState({
				showExpandedActionMenu: false,
			});
		}
	}

	deleteTimelineHeatmapCacheForTaskAssignees(item, deltaStart, deltaEnd) {
		const data = this.getData();
		const task = item.data.task;
		const isUsingProjectAllocation = getVisualizationMode(
			this.state.schedulingOptions,
			data.company,
			VISUALIZATION_MODE.ALLOCATION
		);

		const recalculateInterval = getRecalculationInterval(item, deltaStart, deltaEnd);

		if (!isUsingProjectAllocation && this.props.isProjectTimeline && task.assignedPersons) {
			const project = data.projects.find(project => project.id === task.projectId);
			const projectId = project.projectGroupId || project.id;

			if (isSimulationMode()) {
				if (task.autoSchedulingPerson) {
					this.clearHeatmapCache(
						IDManager.getPersonGroupId(this, task.autoSchedulingPerson.id, projectId),
						recalculateInterval
					);
				}
			} else {
				task.assignedPersons.forEach(personId => {
					this.clearHeatmapCache(IDManager.getPersonGroupId(this, personId, projectId), recalculateInterval);
				});
			}
		}
	}

	clearHeatmapCache(groupId, interval) {
		if (this.props.isProjectTimeline && groupId) {
			recalculateGroupHeatmapCache(this, groupId, interval);
		}
	}

	updateSimulationChangeMap(type, item) {
		const id = type === 'allocations' ? item.data.allocation.id : item.groupId;

		if (this.state.simulationMode) {
			const {simulationChangeMap} = this.state;
			let simulationChangeItem = simulationChangeMap.get(type).get(id);
			if (!simulationChangeItem) {
				simulationChangeItem = {};
			}
			simulationChangeItem.initialStartDate = item.data.startDate;
			simulationChangeItem.initialEndDate = item.data.endDate;
			simulationChangeItem.startDate = item.startDate;
			simulationChangeItem.endDate = item.endDate;
			simulationChangeMap.get(type).set(id, simulationChangeItem);

			this.setState({simulationChangeMap});

			return false;
		}
	}

	moveItem(item, movedDays) {
		const hideWeekend = this.timeline.isHideWeekendsSelected();
		const {startDifference, endDifference} = moveItem(item, movedDays, hideWeekend);
		item.shift(movedDays, startDifference, endDifference);
	}

	hasTask(group) {
		return group.groups?.some(group => group.groupType === GROUP_TYPE.TASK);
	}

	updateTaskDataDates(item, canvasStartDate, canvasEndDate, startDelta, endDelta) {
		const task = item.data.task;
		if (canvasStartDate) {
			const startDate = getMomentFromCanvasTimelineDate(canvasStartDate);
			task.startYear = startDate.year();
			task.startMonth = startDate.month() + 1;
			task.startDay = startDate.date();
		}
		if (canvasEndDate) {
			const endDate = getMomentFromCanvasTimelineDate(canvasEndDate);
			task.deadlineYear = endDate.year();
			task.deadlineMonth = endDate.month() + 1;
			task.deadlineDay = endDate.date();
		}
		// Clear timeline heatmap cache for affected persons
		this.deleteTimelineHeatmapCacheForTaskAssignees(item, startDelta, endDelta);
	}

	getProjectItems(projectItem) {
		const {data, items} = this.state;

		const hasPlaceholdersModule = hasFeatureFlag('placeholders') && hasModule(MODULE_TYPES.PLACEHOLDERS);

		return items.filter(stateItem => {
			if (hasPlaceholdersModule && stateItem.itemType === ITEM_TYPE.PLACEHOLDER_ALLOCATION) {
				const placeholder = data.placeholderMap.get(stateItem.data.placeholderAllocation.placeholderId);
				return placeholder?.projectId === projectItem.groupId;
			} else if (
				stateItem.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PHASE ||
				stateItem.itemType === ITEM_TYPE.TASK ||
				stateItem.itemType === ITEM_TYPE.PROJECT_SCHEDULING_TEAM_ITEM
			) {
				return stateItem.data.projectId === projectItem.groupId;
			} else if (stateItem.itemType === ITEM_TYPE.PROJECT_ALLOCATION) {
				return stateItem.data.allocation.projectId === projectItem.groupId;
			}

			return false;
		});
	}

	toggleDependencyDragMode() {
		Util.localStorageSetItem('isDraggingWholeDependencyChainModeOn', !this.state.isDraggingWholeDependencyChainModeOn);
		this.setState({isDraggingWholeDependencyChainModeOn: !this.state.isDraggingWholeDependencyChainModeOn});
	}

	resetRecalculateSteps() {
		this.setState({recalculateSteps: false});
	}

	setPreventFiltering(preventFiltering) {
		this.setState({preventFiltering});
	}

	render() {
		const {dayData, isProjectTimeline, sharedContext, projectId, groupId} = this.props;
		if (this.state.isShowingAutoScheduleOnLoad) {
			this.setState({
				isShowingAutoScheduleOnLoad: false,
			});
			this.showAutoSchedulingModal();
		}
		let loading = true;
		if (this.state.initData) {
			if (this.state.lightweightDataLoaded || this.state.dataLoaded) {
				loading = false;
			}
		}
		if (loading === true) {
			return (
				<div key={'c1'} className="canvas-scheduling">
					<div className="is-loading" />
				</div>
			);
		}

		const {formatMessage} = this.props.intl;
		const {
			data,
			lightWeightData,
			groups,
			items,
			showCollapsedActionMenu,
			collapsedActionMenuX,
			collapsedActionMenuY,
			showExpandedActionMenu,
			expandedActionMenuX,
			expandedActionMenuY,
			showArrowClickableArea,
			showTargetPhaseButton,
			showCreatePlaceholderButton,
			createPlaceholderProject,
			targetButtonY,
			targetButtonX,
			showAddPeopleButton,
			addPeopleData,
			showPlaceholderInformation,
			placeholderInformationY,
			placeholderInformationX,
			placeholderInformationData,
			arrowClickableAreaY,
			showDistributionBox,
			distributionBoxX,
			distributionBoxY,
			distributionBoxData,
			contextMenuX,
			contextMenuY,
			contextMenuOptions,
			showDetailBox,
			detailBoxX,
			detailBoxY,
			detailBoxData,
			autoScheduleProposalData,
			autoSchedulingDragPersonId,
			autoSchedulingDragTranslateX,
			autoSchedulingDragTranslateY,
			isShowingAutoScheduleLoader,
			project,
			assignedPersonHoverData,
			shouldShowScrollFade,
			dataLoaded,
			dependencies,
			clickedItem,
			loadingAutoSchedule,
			showLoader,
			recalculateSteps,
			splitAllocationBarData,
			splitPlaceholderAllocationBarData,
			showCanvasTooltip,
			canvasTooltipData,
			canvasTooltipX,
			canvasTooltipY,
			schedulingOptions,
			actionMenuOptions,
		} = this.state;
		const availableData = data || lightWeightData;
		const isUsingProjectAllocation = getVisualizationMode(
			this.state.schedulingOptions,
			availableData.company,
			VISUALIZATION_MODE.ALLOCATION
		);

		if (isClientUser()) return null; //Do not render anything if client tried accessing the page

		let startDate = this.props.startDate,
			endDate = 0;
		if (autoScheduleProposalData && this.state.simulationMode) {
			if (autoScheduleProposalData.earliestStartDate) {
				startDate = autoScheduleProposalData.earliestStartDate;
			}
			if (autoScheduleProposalData.latestEndDate) {
				endDate = autoScheduleProposalData.latestEndDate;
			}
		}

		const filteredFooterPersons = getFilteredFooterPersons(this);

		const isApplyAutoScheduleDisabled =
			!this.state.autoScheduleProposalData ||
			!this.state.autoScheduleProposalData.taskData ||
			this.state.autoScheduleProposalData.taskData.length === 0;
		const topLeftComponent =
			isProjectTimeline || sharedContext ? (
				<div className={'project-scheduling-top-left-restyled'} />
			) : (
				<div className="project-scheduling-top-left">
					{hasPermission(PERMISSION_TYPE.PROJECTS_CREATE) && (
						<Button
							className={'new-project-button-v2'}
							text={formatMessage({id: 'new_project.title'})}
							buttonStyle={BUTTON_STYLE.OUTLINE}
							colorTheme={BUTTON_COLOR.PURPLE}
							onClick={() => {
								this.setState({
									clickedItem: null,
									movedItem: null,
									showLoader: false,
								});
								tracking.trackElementClicked('Add new project');
								trackEvent('Add New Project', 'Clicked');
								showModal({type: MODAL_TYPE.NEW_PROJECT_V2});
							}}
							userpilot={'schedule-new-project'}
						/>
					)}
				</div>
			);

		const isCollapsableSectionExpanded = false;

		if ((dataLoaded || this.isUsingNewLazyLoad) && clickedItem) {
			EventManager.onDistributionItemClick(
				clickedItem.itemType,
				clickedItem.parentId,
				clickedItem.childId,
				clickedItem.item
			);
		}
		if (hasFeatureFlag('auto_schedule_access') && dataLoaded && loadingAutoSchedule) {
			this.showAutoSchedulingModal();
		}

		let width, height, top, left;
		if (this.timeline && this.timeline) {
			width = window.innerWidth;
			height = window.innerHeight - this.timeline.state.canvasOffsetTop;
			top = this.timeline.state.canvasOffsetTop;
			left = 0;
		}

		// find id of project/project group viewed in project timeline, needed for save filter action
		let actualProjectId = null;
		let actualProjectGroupId = null;
		if (projectId) {
			if (project) {
				actualProjectId = project.id;
			} else if (availableData && availableData.projects) {
				const matchingProject = findProject(availableData.projects, projectId);
				actualProjectId = matchingProject ? matchingProject.id : null;
			}
		} else if (groupId && availableData && availableData.projectGroups) {
			const matchingProjectGroup = availableData.projectGroups.find(el => el.companyProjectGroupId === parseInt(groupId));
			actualProjectGroupId = matchingProjectGroup ? matchingProjectGroup.id : null;
		}

		// heatmap cell detail box
		const {
			heatmapCellDetailBoxX,
			heatmapCellDetailBoxY,
			showHeatmapDetailBoxRight,
			showHeatmapDetailBoxCenter,
			showHeatmapDetailBoxBottom,
		} = getHeatmapCellDetailBoxCoords(detailBoxData?.heatmapData);

		const updateTimelineRef = timeline => {
			this.props.setTimeline(timeline);
			this.timeline = timeline;
		};

		const isLoadMoreTimeline = hasFeatureFlag('incremental_load_more_include_items')
			? isProjectTimeline
			: !this.props.programPrefix && (this.props.projectId || this.props.groupId);
		const isUsingLoadMore = !sharedContext && !this.state.simulationMode && isLoadMoreTimeline;

		return (
			<>
				{this.state.showLoadingOverlay ? <UploadingOverlay /> : null}
				{isShowingAutoScheduleLoader ? <AiLoader absolute /> : null}
				{this.props.viewer && (this.props.viewer.project || this.props.viewer.projectGroup) ? (
					<ProjectHeader
						title={this.props.intl.formatMessage({
							id: isProjectTimeline ? 'project_timeline.title' : 'scheduling.menu.schedule_projects',
						})}
						project={this.props.viewer.project}
						projectGroup={this.props.viewer.projectGroup}
						psProject={this.props.viewer.psProject}
					/>
				) : (
					<>
						{!sharedContext && !this.props.programPrefix && (
							<TopHeaderBarWrapper sidePadding={24} bottomPadding={0}>
								<TopHeaderBar title="All Timelines" content={getTopHeaderContent(isUsingProjectAllocation)} />
							</TopHeaderBarWrapper>
						)}
					</>
				)}
				<div className="control-bar">
					{createHeader(this, isProjectTimeline, projectId, groupId, actualProjectId, actualProjectGroupId)}
				</div>
				<div
					id="project-timeline"
					className={'canvas-projects-scheduling' + (this.state.simulationMode ? ' simulation-mode' : '')}
				>
					{this.state.simulationMode && assignedPersonHoverData ? (
						<div
							className="person-info-box"
							style={{
								height: assignedPersonHoverData.data.height,
								top: assignedPersonHoverData.data.y + 'px',
								left:
									assignedPersonHoverData.data.x +
									assignedPersonHoverData.data.width +
									AUTO_SCHEDULING_PERSON_WIDTH +
									(assignedPersonHoverData.data.item.data.task.isOverAllocated
										? AUTO_SCHEDULING_PERSON_WIDTH
										: AUTO_SCHEDULING_PERSON_WIDTH / 2) +
									'px',
							}}
						>
							<div className="name">
								{assignedPersonHoverData.overAllocated
									? formatMessage({id: 'scheduling.over_allocated'})
									: assignedPersonHoverData.data.item.data.task.autoSchedulingPerson.firstName +
									  ' ' +
									  assignedPersonHoverData.data.item.data.task.autoSchedulingPerson.lastName}
							</div>
						</div>
					) : null}
					{this.state.simulationMode ? (
						<>
							{this.state.footerPersonHoverData && this.state.footerPersonHoverData.person ? (
								<div
									ref={div => (this.footerHoverDiv = div)}
									className="footer-person-tooltip-container"
									style={{
										opacity: this.state.footerPersonHoverData.opacity,
										top: this.state.footerPersonHoverData.top + 'px',
										left: this.state.footerPersonHoverData.left + 'px',
									}}
								>
									{this.state.footerPersonHoverData.person.firstName +
										' ' +
										this.state.footerPersonHoverData.person.lastName}
								</div>
							) : null}
							<div className="bar left" style={{backgroundColor: project.projectColor}} />
							<div className="bar right" style={{backgroundColor: project.projectColor}} />

							<div
								className="simulation-header"
								style={{backgroundColor: project.projectColor}}
								data-cy="simulation-mode-header"
							>
								<div className="title">{formatMessage({id: 'auto_scheduling.auto_schedule'})}</div>
								<div className="project-details">
									<div className="project-id">P{project.companyProjectId}</div>
									<div className="project-name">{project.name}</div>
								</div>
								<div
									className="close-simulation close-button"
									onClick={() => disableSimulationMode(this, true)}
								/>
							</div>
						</>
					) : null}
					<div className="hover-effects-container">
						{showCollapsedActionMenu &&
						(!showExpandedActionMenu || collapsedActionMenuY !== expandedActionMenuY) &&
						((!this.state.collapsedActionMenuData.project && !this.state.collapsedActionMenuData.projectGroup) ||
							!hasViewOnlyAccess(
								this,
								this.state.collapsedActionMenuData.project?.id,
								this.state.collapsedActionMenuData.projectGroup?.id
							)) &&
						(this.state.actionMenuType !== SCHEDULING_ACTION_MENU_TYPE.PROGRAM ||
							hasPermission(PERMISSION_TYPE.PROGRAMS_CREATE)) ? (
							<div
								className={'project-scheduling-actions-menu-new-width'}
								style={{top: collapsedActionMenuY, left: collapsedActionMenuX}}
							>
								<ActionMenu
									options={actionMenuOptions}
									onExpansionToggle={() => EventManager.onExpandActionMenu(this)}
									expanded={false}
									useGreyIcon={true}
								/>
							</div>
						) : null}
						{showExpandedActionMenu &&
						(!this.state.collapsedActionMenuData.project ||
							!hasViewOnlyAccess(
								this,
								this.state.collapsedActionMenuData.project?.id,
								this.state.collapsedActionMenuData.projectGroup?.id
							)) ? (
							<div
								className={'project-scheduling-actions-menu-new-width'}
								style={{top: expandedActionMenuY, left: expandedActionMenuX}}
							>
								<ActionMenu
									options={actionMenuOptions}
									onExpansionToggle={() => EventManager.hideExpandedActionMenu(this)}
									expanded={true}
									useGreyIcon={true}
								/>
							</div>
						) : null}
						{showArrowClickableArea ? (
							<div
								className="project-scheduling-arrow-clickable-area previous"
								onClick={this.onPhasesGroupArrowClick.bind(this, true)}
								style={{top: arrowClickableAreaY}}
							/>
						) : null}
						{showArrowClickableArea ? (
							<div
								className="project-scheduling-arrow-clickable-area next"
								onClick={this.onPhasesGroupArrowClick.bind(this, false)}
								style={{top: arrowClickableAreaY}}
							/>
						) : null}
						{showTargetPhaseButton ? (
							<div
								className="project-scheduling-single-action-button"
								onClick={this.targetPhase.bind(this)}
								style={{
									top: targetButtonY,
									left: targetButtonX,
									height: GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE,
									width: GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE,
								}}
							>
								<img
									src={targetPhaseIcon}
									alt="Target project phase"
									height={GROUP_SECTION_SINGLE_ACTION_IMAGE_SIZE}
									width={GROUP_SECTION_SINGLE_ACTION_IMAGE_SIZE}
								/>
							</div>
						) : null}
						{showAddPeopleButton ? (
							<div
								className="project-scheduling-single-action-button"
								onClick={this.addPeopleToProjectTeam.bind(this, addPeopleData)}
								style={{
									top: targetButtonY,
									left: targetButtonX,
									height: GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE,
									width: GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE,
								}}
							>
								<img
									src={addIcon}
									alt="Add people to project team"
									height={GROUP_SECTION_SINGLE_ACTION_IMAGE_SIZE}
									width={GROUP_SECTION_SINGLE_ACTION_IMAGE_SIZE}
								/>
							</div>
						) : null}
						{!sharedContext && showCreatePlaceholderButton ? (
							<div
								className="project-scheduling-single-action-button"
								onClick={this.addPlaceholder.bind(this, createPlaceholderProject)}
								style={{
									top: targetButtonY,
									left: targetButtonX,
									height: GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE,
									width: GROUP_SECTION_SINGLE_ACTION_BUTTON_SIZE,
								}}
							>
								<img
									src={addIcon}
									alt="Create placeholder"
									height={GROUP_SECTION_SINGLE_ACTION_IMAGE_SIZE}
									width={GROUP_SECTION_SINGLE_ACTION_IMAGE_SIZE}
								/>
							</div>
						) : null}
						{showDistributionBox ? (
							<DistributionBox
								positionX={distributionBoxX}
								positionY={distributionBoxY}
								distributionBoxData={distributionBoxData}
								data={data}
								getPhaseCompletion={phaseId => getPhaseCompletion(this, phaseId)}
							/>
						) : null}
						{contextMenuX && contextMenuY && contextMenuOptions && contextMenuOptions.length ? (
							// we are setting the key to be the sum of the coordinates because this will cause a rerender of the context menu if the user right clicks on another item
							// solves the issue of the context menu not updating the position
							<ContextMenu
								key={contextMenuX + contextMenuY}
								cy="canvas-project-scheduling-context-menu"
								options={contextMenuOptions}
								contextMenuPosition={{x: contextMenuX, y: contextMenuY}}
							/>
						) : null}
						{showDetailBox &&
						detailBoxData &&
						(detailBoxData.task || detailBoxData.allocation || detailBoxData.placeholderAllocation) ? (
							<div
								className="project-scheduling-detail-box"
								style={{
									left: detailBoxX + 200 < window.innerWidth ? detailBoxX + 10 : detailBoxX - 235,
									top: detailBoxY + 350 < window.innerHeight ? detailBoxY - 5 : 'unset',
									bottom:
										detailBoxY + 350 < window.innerHeight ? 'unset' : window.innerHeight - detailBoxY - 40,
								}}
							>
								{detailBoxData.task ? (
									<TaskDetailBox
										task={detailBoxData.task}
										globalData={data}
										positionX={detailBoxX}
										positionY={detailBoxY}
										pageComponent={this}
									/>
								) : detailBoxData.allocation ? (
									<AllocationDetailBox
										pageComponent={this}
										taskAllocationTotalEstimate={detailBoxData.totalEstimates}
										allocation={detailBoxData.allocation}
										globalData={data}
										positionX={detailBoxX}
										positionY={detailBoxY}
										schedulingOptions={schedulingOptions}
									/>
								) : (
									<PlaceholderAllocationDetailBox
										placeholderAllocation={detailBoxData.placeholderAllocation}
										project={detailBoxData.project}
										projectGroup={detailBoxData.projectGroup}
										globalData={data}
										positionX={detailBoxX}
										positionY={detailBoxY}
										schedulingOptions={schedulingOptions}
									/>
								)}
							</div>
						) : showDetailBox && detailBoxData.heatmapData ? (
							<div
								className="heatmap-cell-detail-box"
								style={{
									width: HEATMAP_CELL_DETAIL_BOX_WIDTH,
									height: HEATMAP_CELL_DETAIL_BOX_HEIGHT,
									left: heatmapCellDetailBoxX,
									top: heatmapCellDetailBoxY,
								}}
							>
								<HeatmapCellDetailBox
									heatmapData={detailBoxData.heatmapData}
									globalData={data}
									positionX={detailBoxX}
									positionY={detailBoxY}
									showCenter={showHeatmapDetailBoxCenter}
									showBottom={showHeatmapDetailBoxBottom}
									showRight={showHeatmapDetailBoxRight}
								/>
							</div>
						) : null}
						{showPlaceholderInformation && placeholderInformationData?.placeholder && (
							<PlaceholderInformationTooltip
								placeholder={placeholderInformationData.placeholder}
								top={placeholderInformationY}
								left={placeholderInformationX}
							/>
						)}
						{showCanvasTooltip && canvasTooltipData && (
							<CanvasTooltip
								mouseData={{x: canvasTooltipX, y: canvasTooltipY}}
								data={canvasTooltipData}
								pageComponent={this}
							/>
						)}
					</div>
					<CanvasTimeline
						ref={updateTimelineRef}
						pageComponent={this}
						key={this.state.simulationMode}
						groups={groups}
						items={items}
						topLeftComponent={topLeftComponent}
						isCollapsableSectionExpanded={isCollapsableSectionExpanded}
						collapsableSectionGroups={[]}
						dayData={dayData}
						holidaysEntries={this.state.data?.holidaysEntriesCanvasFormat || []}
						isSingleGroup={true}
						onBeforeHorizontalScroll={
							isUsingLoadMore
								? scrollAmount => preventScrollingPastLoadMoreOverlay(this, scrollAmount)
								: undefined
						}
						onDrawForegroundEnd={
							!isUsingLoadMore
								? this.onDrawForegroundEnd.bind(this)
								: (canvasContext, _, stepDataArray) => drawIncrementalLoadMoreOverlay(this, stepDataArray)
						}
						onDrawForegroundStart={this.onDrawForegroundStart.bind(this)}
						getDependencyData={dependencies ? dragData => getDependencyData(this, dragData) : null}
						getVisibleTasksInHierarchy={this.getVisibleTasksInHierarchy.bind(this)}
						onDependencyCreation={(thisDependsOnTaskId, taskIdDependsOnThis, isFinishToStartDependency) =>
							onDependencyCreation(this, thisDependsOnTaskId, taskIdDependsOnThis, isFinishToStartDependency)
						}
						onForegroundContextMenu={(event, mouseTargetData, canvasDate) =>
							EventManager.onForegroundContextMenu(this, event, mouseTargetData, canvasDate)
						}
						disableLoadMore={this.disableLoadMore}
						leftLoadMoreDate={this.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]}
						rightLoadMoreDate={this.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]}
						initialStartDate={startDate}
						initialEndDate={endDate}
						getDependencyChainTaskIdSet={taskId => getDependencyChainTaskIdSet(this, taskId)}
						onMouseUpEnd={this.onMouseUpEnd.bind(this)}
						onMouseMoveEnd={this.onMouseMoveEnd.bind(this)}
						simulationMode={this.state.simulationMode}
						onGroupExpansionToggle={(group, isExpandAll) =>
							EventManager.onGroupExpansionToggle(this, group, isExpandAll)
						}
						onVerticalScroll={() => EventManager.onTimelineVerticalScroll(this)}
						onHorizontalScroll={delta => EventManager.onTimelineHorizontalScroll(this, delta)}
						debugData={data}
						eyeOptions={this.state.eyeOptions}
						isProjectTimeline={this.props.isProjectTimeline}
						isUsingProjectAllocation={isUsingProjectAllocation}
						recalculateSteps={recalculateSteps}
						resetRecalculateSteps={this.resetRecalculateSteps.bind(this)}
						splitAllocationBarData={splitAllocationBarData.visible ? splitAllocationBarData : null}
						splitPlaceholderAllocationBarData={
							splitPlaceholderAllocationBarData.visible ? splitPlaceholderAllocationBarData : null
						}
						isProjectScheduling={!this.props.isProjectTimeline}
						setPreventFiltering={this.setPreventFiltering.bind(this)}
						initialZoomLevel={this.state.initData.projectSchedulingShare?.zoomLevel || this.props.initialZoomLevel}
						isUsingNewLazyLoad={this.isUsingNewLazyLoad}
						doFetchFullData={this.doFetchFullData.bind(this, this.props)}
						weekendOptions={this.state.weekendOptions}
						schedulingView={this.props.schedulingView}
					/>
					{dataLoaded ? (
						this.state.simulationMode ? (
							<div className="simulation-footer" style={{borderColor: project.projectColor}}>
								<div className="assign-persons-container">
									<AutoSchedulingDropdown
										useSmallerStyling={true}
										assignablePersons={this.getData().persons.filter(
											person => person.active && !person.clientId
										)}
										assignedPersons={this.state.footerPersons}
										assignableRoles={this.getData().roles}
										assignPerson={person => addFooterPerson(this, person)}
										unassignPerson={person => removeFooterPerson(this, person)}
										autoFocus={false}
										disabled={false}
										isMultiSelect={true}
										viewer={data.viewer}
										updateDropdownSearchCriteria={searchCriteria =>
											updateDropdownSearchCriteria(this, searchCriteria)
										}
										onPersonsChange={this.setScrollFade.bind(this)}
									/>
								</div>
								{shouldShowScrollFade ? (
									<div className="faded-overlay left">
										<div className="fade" />
									</div>
								) : null}

								<div style={{display: 'flex', flexGrow: 1, height: '55px'}}>
									<CustomScrollDiv ref={div => (this.scrollbar = div)} horizontalContent={true}>
										<div className="project-persons">
											{filteredFooterPersons.length !== 0
												? filteredFooterPersons.map(person => (
														<React.Fragment key={person.id}>
															{autoSchedulingDragPersonId &&
															autoSchedulingDragPersonId === person.id ? (
																<div style={{width: '34px'}} />
															) : null}
															<div
																// title={person.firstName + ' ' + person.lastName}
																className="project-person drag"
																id={person.id}
																draggable="true"
																onMouseDown={e => onPersonMouseDown(this, person.id, e)}
																onMouseEnter={e =>
																	handleFooterPersonMouseEnter(this, person, e)
																}
																onMouseLeave={() => handleFooterPersonMouseLeave(this)}
																style={
																	autoSchedulingDragPersonId === person.id
																		? {
																				position: 'fixed',
																				top: 0,
																				left: 0,
																				transform: `translate(${autoSchedulingDragTranslateX}px, ${autoSchedulingDragTranslateY}px)`,
																		  }
																		: undefined
																}
															>
																<Person
																	name={`${person.firstName} ${person.lastName}`}
																	showName={false}
																	showRole={false}
																	imageSrc={profilePicSrc(person.profilePictureId)}
																	imageSize="large"
																/>
															</div>
														</React.Fragment>
												  ))
												: null}
										</div>
									</CustomScrollDiv>
								</div>

								{this.state.footerWarnings.length > 0 ? (
									<div className="footer-warnings">
										{this.state.footerWarnings.map(w => (
											<p>{w}</p>
										))}
									</div>
								) : (
									''
								)}

								<div className="actions">
									<Button
										text={formatMessage({id: 'common.cancel'})}
										onClick={() => disableSimulationMode(this, true)}
										buttonStyle={BUTTON_STYLE.FILLED}
										colorTheme={BUTTON_COLOR.WHITE}
									/>
									<Button
										text={formatMessage({id: 'auto_scheduling.reschedule'})}
										onClick={this.onRescheduleButtonPress.bind(this)}
										buttonStyle={BUTTON_STYLE.OUTLINE}
										colorTheme={BUTTON_COLOR.AI_BLUE}
									/>
									<Button
										isDisabled={isApplyAutoScheduleDisabled}
										text={formatMessage({id: 'settings_subscription.apply'})}
										onClick={() => onApplySimulationChanges(this)}
										buttonStyle={BUTTON_STYLE.FILLED}
										autoScheduleStyle
										buttonTitle={
											isApplyAutoScheduleDisabled
												? formatMessage({id: 'auto_scheduling.apply_button_disabled_info'})
												: undefined
										}
									/>
								</div>
							</div>
						) : null
					) : null}
					{this.state.savingMutation ? (
						<div className="is-saving">
							<div className="backdrop" style={{width, height, top, left}} />
							<div className="description">Saving. Please wait until it is finished.</div>
						</div>
					) : null}
					{showLoader ? <div className="is-loading" /> : null}
				</div>

				{isUsingLoadMore && <IncrementalLoadingOverlay onWheel={e => this.timeline.onForegroundWheel(e)} />}

				{/* userpilot targets */}
				<div className="userpilot-target first-project" data-userpilot={'first-project'} />
				<div className="userpilot-target first-project-progress" data-userpilot={'first-project-progress'} />
				<div className="userpilot-target first-project-expand-icon" data-userpilot={'first-project-expand-icon'} />
				<div className="userpilot-target first-project-phases" data-userpilot={'first-project-phases'} />
				<div
					className="userpilot-target first-project-phases-expand"
					data-userpilot={'first-project-milestones-expand'}
				/>
				<div className="userpilot-target first-project-first-phase" data-userpilot={'first-project-first-milestone'} />
				<div className="userpilot-target first-project-first-phase" data-userpilot={'first-project-first-milestone'} />
				<div
					className="userpilot-target first-project-first-phase-expand"
					data-userpilot={'first-project-first-milestone-expand'}
				/>
				<div className="userpilot-target first-task" data-userpilot={'first-task'} />
				<div className="userpilot-target first-project-team" data-userpilot={'first-project-team'} />
				<div className="userpilot-target first-project-team-expand" data-userpilot={'first-project-team-expand'} />
				<div className="userpilot-target project-team-heatmap" data-userpilot={'project-team-heatmap'} />
				<div className="userpilot-target all-projects-timeline" data-userpilot={'all-projects-timeline'} />
				<div
					className="userpilot-target project-scheduling-task"
					id="user-pilot-project-scheduling-task"
					data-userpilot={'project-scheduling-task'}
				/>
			</>
		);
	}
}

CanvasProjectsScheduling.propTypes = {
	viewer: PropTypes.object.isRequired,
	setTimeline: PropTypes.func.isRequired,
	sharedContext: PropTypes.bool,
};
export default injectIntl(CanvasProjectsScheduling, {forwardRef: true});
