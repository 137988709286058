import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';

import {ValueCell} from './ValueCell';
import {CSS_CONSTANTS} from '../../../../css_variables';
import {PROJECT_STATUS} from '../../../../constants';
import {useIntl} from 'react-intl';

const ProjectSubColumnStyles = styled.td`
	padding-right: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
	overflow: hidden;
	border-top: ${({isOnFirstLine}) => (isOnFirstLine ? 0 : '1px')} solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	border-bottom: ${({isOnLastLine}) => (isOnLastLine ? '1px' : 0)} solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	${({isSticky, index, isSafari}) =>
		isSticky
			? {
					background: 'white',
					position: 'sticky',
					top: isSafari ? '0px' : '79px',
					left: index === 0 ? '0px' : index === 1 ? '45px' : index === 2 ? '121px' : '',
			  }
			: ''};

	&.highlighted {
		background-color: ${CSS_CONSTANTS.v2_list_background_purple};
	}

	&.drawBorder {
		border-left: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
		padding-left: 16px;
	}

	&:last-child {
		border-right: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
	}

	.value {
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: ${CSS_CONSTANTS.v2_text_gray};

		&.time,
		&.percentage,
		&.price,
		&.progress {
			text-align: right;
		}
		&.projectId {
			text-align: center;
		}
		&.name {
			width: 250px;
			div {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&.connectedProject {
				font-weight: 500;
			}
			&.inProjectGroup {
				padding-left: 8px;
			}
		}
	}
`;

export const ProjectSubColumn = ({
	currencySymbol,
	project,
	subColumn,
	highlighted,
	drawBorder,
	expanded,
	canExpand,
	isSticky,
	index,
	rowType,
	isOnFirstLine,
	isOnLastLine,
	columnId,
}) => {
	const intl = useIntl();
	const type = subColumn.type;
	const projectValues = subColumn.projectValues;
	const value = projectValues[project.id];

	// add sticky columns fix for safari
	const userAgent = navigator.userAgent.toLowerCase();
	const isSafari = userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1;

	const baselineBasedPriceColumns = [
		'actualPrice.per',
		'actualPrice.tot',
		'planPrice.per',
		'planPrice.tot',
		'remainingPrice.per',
		'remainingPrice.tot',
		'forecastPrice.per',
		'forecastPrice.tot',
	];
	let baselineTooltip = null;

	if (
		project.details.status === PROJECT_STATUS.OPPORTUNITY &&
		project.details.baselineWinChance < 1 &&
		baselineBasedPriceColumns.includes(columnId)
	) {
		let tooltipTitle = null;
		let tooltipCalculation = null;
		let tooltipText = null;
		let tooltipText2 = null;
		if (subColumn.id.includes('.rev')) {
			if (subColumn.id.includes('actualPrice.')) {
				tooltipTitle = intl.formatMessage({id: 'project_budget.actual_revenue'});
				tooltipText = intl.formatMessage({id: 'insights.baseline_tooltip_actual_text_1'});
				tooltipText2 = intl.formatMessage({id: 'insights.baseline_tooltip_actual_text_2'});
				baselineTooltip = [{title: tooltipTitle}, {description: tooltipText}, {description: tooltipText2}];
			} else {
				tooltipText = intl.formatMessage({id: 'insights.baseline_tooltip_text_revenue'});
				tooltipCalculation = [
					intl.formatMessage({id: 'project.win_chance'}),
					'x',
					intl.formatMessage({id: 'project_budget.baseline_revenue'}),
				];
				if (subColumn.id.includes('planPrice.')) {
					tooltipTitle = intl.formatMessage({id: 'insights.baseline_tooltip_plan_revenue'});
				} else if (subColumn.id.includes('remainingPrice.')) {
					tooltipTitle = intl.formatMessage({id: 'insights.baseline_tooltip_remaining_revenue'});
				} else if (subColumn.id.includes('forecastPrice.')) {
					tooltipTitle = intl.formatMessage({id: 'insights.baseline_tooltip_forecast_revenue'});
				}
				baselineTooltip = [{title: tooltipTitle, details: tooltipCalculation}, {description: tooltipText}];
			}
		} else if (subColumn.id.includes('.cos')) {
			if (subColumn.id.includes('actualPrice.')) {
				tooltipTitle = intl.formatMessage({id: 'insights.component.list.column.actualCost'});
				tooltipText = intl.formatMessage({id: 'insights.baseline_tooltip_actual_text_1'});
				tooltipText2 = intl.formatMessage({id: 'insights.baseline_tooltip_actual_text_2'});
				baselineTooltip = [{title: tooltipTitle}, {description: tooltipText}, {description: tooltipText2}];
			} else {
				tooltipText = intl.formatMessage({id: 'insights.baseline_tooltip_text_cost'});
				tooltipCalculation = [
					intl.formatMessage({id: 'project.win_chance'}),
					'x',
					intl.formatMessage({id: 'project_budget.baseline_cost'}),
				];
				if (subColumn.id.includes('planPrice.')) {
					tooltipTitle = intl.formatMessage({id: 'insights.baseline_tooltip_plan_cost'});
				} else if (subColumn.id.includes('remainingPrice.')) {
					tooltipTitle = intl.formatMessage({id: 'insights.baseline_tooltip_remaining_cost'});
				} else if (subColumn.id.includes('forecastPrice.')) {
					tooltipTitle = intl.formatMessage({id: 'insights.baseline_tooltip_forecast_cost'});
				}
				baselineTooltip = [{title: tooltipTitle, details: tooltipCalculation}, {description: tooltipText}];
			}
		}
	}

	return (
		<ProjectSubColumnStyles
			className={`${type}${highlighted ? ' highlighted' : ''}${drawBorder ? ' drawBorder' : ''}`}
			isSticky={isSticky}
			index={index}
			isSafari={isSafari}
			isOnFirstLine={isOnFirstLine}
			isOnLastLine={isOnLastLine}
			data-userpilot={isOnFirstLine && index === 0 ? 'expand-row-button' : ''}
		>
			<div
				className={`value ${type}${project.isConnectedProject ? ' connectedProject' : ''}${
					project.details.isInProjectGroup ? ' inProjectGroup' : ''
				}`}
			>
				<ValueCell
					currencySymbol={currencySymbol}
					type={type}
					value={value}
					project={project}
					expanded={expanded}
					canExpand={canExpand}
					rowType={rowType}
					baselineTooltip={baselineTooltip}
				/>
			</div>
		</ProjectSubColumnStyles>
	);
};
