import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

class Warning extends Component {
	render() {
		const {messageId, message, useInfoIcon, important} = this.props;
		return (
			<div className="warning-message-component">
				<div className={'icon-container' + (useInfoIcon ? ' info-icon' : '')} />
				<div className={'message' + (important ? ' red' : '')}>
					{messageId ? <FormattedMessage id={messageId} /> : <span>{message}</span>}
				</div>
			</div>
		);
	}
}

Warning.propTypes = {
	messageId: PropTypes.string,
	message: PropTypes.string,
	useInfoIcon: PropTypes.bool,
};

export default Warning;
