import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import Chart from './Chart';
import Totals from './Totals';
import RevenueRecognition from '../../revenue_recognition/RevenueRecognition';
import {BudgetDataWrapper, ChartAndHighLevelNumbers, RevenueRecognitionWrapper} from '../../BudgetData.styled';
import HighLevelBudgetNumbers from '../../HighLevelBudgetNumbers';
import ProjectUtil from '../../../../../../forecast-app/shared/util/project_util';
import {hasFeatureFlag} from '../../../../../../forecast-app/shared/util/FeatureUtil';

const BudgetData = ({
	project,
	company,
	selectValueType,
	selectedViewType,
	eyeOptionMap,
	hasBaseline,
	retry,
	timestamp,
	hasExpenses,
}) => {
	return (
		<>
			<BudgetDataWrapper>
				<ChartAndHighLevelNumbers>
					<Chart
						project={project}
						selectedViewType={selectedViewType}
						eyeOptionMap={eyeOptionMap}
						hasBaseline={hasBaseline}
					/>
					{(ProjectUtil.projectTracksRevenue(project) ||
						hasFeatureFlag('revenue_recognition_with_all_budget_types')) && (
						<HighLevelBudgetNumbers key={'HighLevelBudgetNumbers'} project={project} company={company} />
					)}
				</ChartAndHighLevelNumbers>
				<Totals
					company={company}
					project={project}
					eyeOptionMap={eyeOptionMap}
					selectValueType={selectValueType}
					hasBaseline={hasBaseline}
				/>
				{ProjectUtil.projectHasRevenueRecognition(project.budgetType) &&
					!hasFeatureFlag('financial_categories_update') && (
						<RevenueRecognitionWrapper>
							<RevenueRecognition
								project={project}
								retry={retry}
								timestamp={timestamp}
								hideLocking={true}
								hasExpenses={hasExpenses}
							/>
						</RevenueRecognitionWrapper>
					)}
			</BudgetDataWrapper>
		</>
	);
};

export default createFragmentContainer(BudgetData, {
	company: graphql`
		fragment BudgetData_company on Company {
			tier
			...HighLevelBudgetNumbers_company
		}
	`,
	project: graphql`
		fragment BudgetData_project on ProjectType
		@argumentDefinitions(currentYear: {type: "Int"}, currentMonth: {type: "Int"}, currentMonthLastDay: {type: "Int"}) {
			...Chart_project
			...HighLevelBudgetNumbers_project
				@arguments(currentYear: $currentYear, currentMonth: $currentMonth, currentMonthLastDay: $currentMonthLastDay)
			...Totals_project
			...RevenueRecognition_project
			id
			billable
			budgetType
		}
	`,
});
