/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RevenueRecognitionLoader_viewer$ref: FragmentReference;
declare export opaque type RevenueRecognitionLoader_viewer$fragmentType: RevenueRecognitionLoader_viewer$ref;
export type RevenueRecognitionLoader_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string
  |},
  +project: ?{|
    +rateCard: ?{|
      +currency: ?string
    |},
    +aggregatedFinancialNumbers: $ReadOnlyArray<?string>,
    +financialNumbers: ?{|
      +recognitionAmount: ?number,
      +estimatedCost: ?number,
      +recognitionProfit: ?number,
      +recognitionProfitPercentage: ?number,
    |},
    +workAggregatedFinancialNumbers?: $ReadOnlyArray<?string>,
    +workFinancialNumbers?: ?{|
      +recognitionAmount: ?number
    |},
    +expenseAggregatedFinancialNumbers?: $ReadOnlyArray<?string>,
    +expenseFinancialNumbers?: ?{|
      +billableTotalTimeAndExpensesAtCompletion: ?number,
      +allTotalTimeAndExpensesAtCompletion: ?number,
    |},
  |},
  +$refType: RevenueRecognitionLoader_viewer$ref,
|};
export type RevenueRecognitionLoader_viewer$data = RevenueRecognitionLoader_viewer;
export type RevenueRecognitionLoader_viewer$key = {
  +$data?: RevenueRecognitionLoader_viewer$data,
  +$fragmentRefs: RevenueRecognitionLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  }
],
v1 = {
  "kind": "Literal",
  "name": "addAccumulatedNumbers",
  "value": true
},
v2 = {
  "kind": "Variable",
  "name": "aggregateLevel",
  "variableName": "aggregateLevel"
},
v3 = {
  "kind": "Variable",
  "name": "aggregates",
  "variableName": "aggregates"
},
v4 = {
  "kind": "Literal",
  "name": "convertToProjectCurrency",
  "value": true
},
v5 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v6 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v7 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v8 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v9 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v10 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recognitionAmount",
  "storageKey": null
},
v12 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v13 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "expenseSearchQuery"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregateLevel",
      "type": "AggregateLevel!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregates",
      "type": "[FinancialAggregatedNumber]!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "expenseAggregates",
      "type": "[FinancialAggregatedNumber]!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "expenseSearchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "hasExpenses",
      "type": "Boolean!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RevenueRecognitionLoader_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "aggregatedFinancialNumbers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "financialNumbers",
          "plural": false,
          "selections": [
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "estimatedCost",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionProfit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "recognitionProfitPercentage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "condition": "hasExpenses",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "alias": "workAggregatedFinancialNumbers",
              "args": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v12/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/)
              ],
              "kind": "ScalarField",
              "name": "aggregatedFinancialNumbers",
              "storageKey": null
            },
            {
              "alias": "workFinancialNumbers",
              "args": [
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v12/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/)
              ],
              "concreteType": "FinancialNumbers",
              "kind": "LinkedField",
              "name": "financialNumbers",
              "plural": false,
              "selections": [
                (v11/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": "expenseAggregatedFinancialNumbers",
              "args": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "Variable",
                  "name": "aggregates",
                  "variableName": "expenseAggregates"
                },
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v13/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/)
              ],
              "kind": "ScalarField",
              "name": "aggregatedFinancialNumbers",
              "storageKey": null
            },
            {
              "alias": "expenseFinancialNumbers",
              "args": [
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v13/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/)
              ],
              "concreteType": "FinancialNumbers",
              "kind": "LinkedField",
              "name": "financialNumbers",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billableTotalTimeAndExpensesAtCompletion",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allTotalTimeAndExpensesAtCompletion",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f224e7e4d9c06a237d3e89a615d2b0a4';

module.exports = node;
