import {createFragmentContainer, graphql} from 'react-relay';
import {sortBreakdownRoles} from '../ProjectPortfolioReportUtils';
import {useIntl} from 'react-intl';

const RoleLoader = ({relay, viewer, children, ...props}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const formatData = () => {
		const currency = viewer.company.currency;
		const roleBreakdownData = viewer.project.roleFinancialBreakdown.map(roleFinancialBreakdown => {
			const data = {...roleFinancialBreakdown, currency};
			if (!data.role) {
				data.role = {
					name: formatMessage({id: 'project_portfolio_report.deleted_role'}),
				};
			}
			return data;
		});

		sortBreakdownRoles(roleBreakdownData);
		return roleBreakdownData;
	};
	return children({data: {rows: formatData()}, ...props});
};

export const roleLoaderQuery = graphql`
	query RoleLoader_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$projectId: ID!
		$phaseId: ID!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_period_role_loader")
			...RoleLoader_viewer
				@arguments(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					projectId: $projectId
					phaseId: $phaseId
				)
		}
	}
`;

export default createFragmentContainer(RoleLoader, {
	viewer: graphql`
		fragment RoleLoader_viewer on Viewer
		@argumentDefinitions(
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
			projectId: {type: "ID!"}
			phaseId: {type: "ID!"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				budgetBaseCurrency
				roleFinancialBreakdown(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					phaseId: $phaseId
					filterRowsWithNoValues: true
					ignoreBaselineWinChance: false
				) {
					breakdownType
					role {
						id
						name
					}
					financialNumbers {
						...ProjectPortfolioPeriodRow_financialNumbers
					}
				}
			}
		}
	`,
});
