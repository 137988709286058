import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import {withRouter} from 'react-router-dom';
import {
	BUTTON_COLOR,
	BUTTON_STYLE,
	DATE_PICKER_STYLE,
	ELEMENT_TYPE,
	FILTER_SECTION,
	FILTER_TYPE,
	GLOBAL_FILTER_FIELD,
	GLOBAL_FILTER_OPERATOR,
	REPORT_GROUPINGS,
} from '../../../constants';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/dropdown_section';
import {getFiltersAlphabetically} from '../../../forecast-app/shared/components/filters/filter_util';
import {buildHeaderBar} from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {TopHeaderBar, TopHeaderBarWrapper} from '../../../forecast-app/shared/components/headers/top-header-bar/TopHeaderBar';
import {getEnabledColumns, getTheEyeOptionsNoGrouping} from './TaskReportTheEyeUtil';
import TaskReportTable, {TaskReportTableQuery} from './TaskReportTable';
import {
	getSearchQueryFiltersValue,
	getSecondDropdownOptionsByGroupings,
	noGroupingFirstDropdownOptions,
} from './TaskReportUtil';
import ForecastQueryRenderer from '../../../ForecastQueryRenderer';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import ExportTaskReportCSV, {ExportTaskReportCSVQuery} from './ExportTaskReportCSV';
import {useGenericReportContext} from '../../../forecast-app/reports-tab/saved-report/GenericReportContext';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import tracking from '../../../tracking';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';

const TaskReportPage = ({viewer}) => {
	const intl = useIntl();
	const {
		reportName,
		handleSetReportName,
		startDate,
		handleSetStartDate,
		endDate,
		handleSetEndDate,
		// eslint-disable-next-line no-unused-vars
		theEyeOptionsChecked,
		filterValue,
		firstDropdownValue,
		handleSetFirstDropdownValue,
		secondDropdownValue,
		handleSetSecondDropdownValue,
		handleSetFilterValue,
		// eslint-disable-next-line no-unused-vars
		getBottomReportHeader,
		getHasOwnerPermission,
	} = useGenericReportContext();

	useEffect(() => {
		tracking.trackPage('Standard Task Report');
	}, []);

	const defaultSearchQueryFilters = [
		{field: GLOBAL_FILTER_FIELD.WITH_TASKS, operator: GLOBAL_FILTER_OPERATOR.IS, value: true.toString()},
		{
			field: GLOBAL_FILTER_FIELD.START_DATE,
			operator: GLOBAL_FILTER_OPERATOR.GREATER_OR_EQUAL,
			value: [startDate.format('YYYY-MM-DD')],
		},
		{
			field: GLOBAL_FILTER_FIELD.END_DATE,
			operator: GLOBAL_FILTER_OPERATOR.LESS_OR_EQUAL,
			value: [endDate.format('YYYY-MM-DD')],
		},
	];

	const hasOwnerPermission = getHasOwnerPermission(viewer.actualPersonId);
	const initialSearchQueryFilters = getSearchQueryFiltersValue(defaultSearchQueryFilters, filterValue);
	const [searchQueryFilters, setSearchQueryFilters] = useState(initialSearchQueryFilters);

	const customFieldDefinitions = viewer.company.customFieldDefinitions.edges
		.map(e => e.node)
		.filter(n => n.entityType === 'TASK');
	const enabledColumns = getEnabledColumns(theEyeOptionsChecked, null, customFieldDefinitions);

	// Options for grouping
	const firstDropdownOptions = noGroupingFirstDropdownOptions(intl);
	const [secondDropdownOptions, setSecondDropdownOptions] = useState(
		getSecondDropdownOptionsByGroupings(firstDropdownValue, intl)
	);

	// Handle of grouping in Task Report
	const groupings = [];
	if (firstDropdownValue !== null) {
		groupings.push(firstDropdownValue);
		if (secondDropdownValue !== null) {
			groupings.push(secondDropdownValue);
		}
	}
	groupings.push(REPORT_GROUPINGS.TASK);

	/**
	 * This method is called when the grouping option is changed.
	 * @param option The selected grouping option.
	 */
	const handleFirstDropdownChange = option => {
		handleSetFirstDropdownValue(option.value);
		handleSetSecondDropdownValue(null);
		setSecondDropdownOptions(getSecondDropdownOptionsByGroupings(option.value, intl));
	};

	const handleSecondDropdownChange = option => {
		handleSetSecondDropdownValue(option.value);
	};

	/**
	 * This will handle changes in the selected dates.
	 *
	 * It will filter the tasks on the end-date between the two dates.
	 *
	 * @param startDate The new start date
	 * @param endDate The new end date
	 */
	const handleDateRangeChange = (newStartDate, newEndDate) => {
		handleSetStartDate(newStartDate);
		handleSetEndDate(newEndDate);
		const updatedSearchQueryFilters = searchQueryFilters.map(filter => {
			const updatedFilterObject = {
				field: filter.field,
				operator: filter.operator,
			};
			if (filter.field === GLOBAL_FILTER_FIELD.START_DATE) {
				updatedFilterObject.value = [newStartDate.format('YYYY-MM-DD')];
			} else if (filter.field === GLOBAL_FILTER_FIELD.END_DATE) {
				updatedFilterObject.value = [newEndDate.format('YYYY-MM-DD')];
			} else {
				updatedFilterObject.value = filter.value;
			}
			return updatedFilterObject;
		});
		setSearchQueryFilters(updatedSearchQueryFilters);
	};

	/**
	 * Search query used for filtering.
	 */
	const searchQuery = {
		filters: searchQueryFilters,
	};

	const handleCSVClick = () => {
		showModal({
			type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
			componentName: 'export_task_report_csv',
			query: ExportTaskReportCSVQuery,
			variables: {
				pageSize: 100000,
				searchQuery: searchQuery,
			},
			enabledColumns: enabledColumns,
			createCsvDownload: <ExportTaskReportCSV />,
			intl: intl,
			reportName: reportName,
		});
	};

	/**
	 * This method is called when the filter is changing.
	 *
	 * @param filters The selected filters.
	 */
	const onFiltersChange = filters => {
		const updatedFilters = getSearchQueryFiltersValue(defaultSearchQueryFilters, filters);
		handleSetFilterValue(filters);
		setSearchQueryFilters(updatedFilters);
	};

	/**
	 * Creates the title bar with project name (editable)
	 */
	const getHeaderTitleBar = () => {
		const content = [];
		const defaultTitle =
			intl.formatMessage({id: 'project_overview.task_overview'}) + ', ' + intl.formatMessage({id: 'common.untitled'});

		if (hasOwnerPermission) {
			content.push({
				type: TopHeaderBar.TYPE.INPUT_REPORT_TITLE,
				defaultTitle,
				value: reportName,
				onChange: handleSetReportName,
				placeHolder: intl.formatMessage({id: 'time_reg_report.report_name'}),
				userpilot: 'task-report-rename-input',
			});
		}

		return (
			<TopHeaderBarWrapper>
				<TopHeaderBar title={hasOwnerPermission ? null : reportName} content={content} />
			</TopHeaderBarWrapper>
		);
	};

	/**
	 * Creates the Navigation bar, with:
	 *
	 * - Date picker
	 * - CSV downloader
	 * - Groupings
	 * - Eye options
	 * - Filtering
	 */
	const getNavBar = () => {
		const leftContent = [];
		const rightContent = [];

		leftContent.push({
			type: ELEMENT_TYPE.DATE_PICKER,
			userpilot: 'navbar-date-picker',
			isNewDateRange: true,
			startDate: startDate,
			endDate: endDate,
			handleDateRangeChange: (startDate, endDate) => handleDateRangeChange(startDate, endDate),
			colorInherited: true,
			compactShowYear: true,
			datePickerStyle: DATE_PICKER_STYLE.SLIM,
			calendarOffsetX: 0,
			clearable: false,
		});

		rightContent.push({
			type: ELEMENT_TYPE.CSV,
			callback: handleCSVClick,
		});

		/**
		 * Add grouping options
		 */
		rightContent.push({
			sameTypeOrder: 1,
			type: ELEMENT_TYPE.DROPDOWN,
			userpilot: 'taskreport-first-grouping',
			dropdownOptions: firstDropdownOptions,
			value: firstDropdownValue,
			callback: option => {
				handleFirstDropdownChange(option);
			},
			style: BUTTON_STYLE.OUTLINE,
			color: BUTTON_COLOR.PURPLE,
		});

		/**
		 * Add second grouping options
		 */
		if (secondDropdownOptions !== null) {
			rightContent.push({
				sameTypeOrder: 2,
				type: ELEMENT_TYPE.DROPDOWN,
				dropdownOptions: secondDropdownOptions,
				value: secondDropdownValue,
				callback: option => {
					handleSecondDropdownChange(option);
				},
				style: BUTTON_STYLE.OUTLINE,
				color: BUTTON_COLOR.PURPLE,
			});
		}

		/**
		 * Add 'eye' options
		 */
		const additionalProps = {
			eyeProps: {eyeOptions: Object.values(getTheEyeOptionsNoGrouping(null, customFieldDefinitions, false))},
		};
		const filteredHeaderElements = getBottomReportHeader(additionalProps);
		rightContent.push(...filteredHeaderElements);

		/**
		 * Available Filters
		 */
		const taskFilters = [
			FILTER_TYPE.CLIENT,
			FILTER_TYPE.PERSON,
			FILTER_TYPE.PROJECT,
			FILTER_TYPE.ROLE,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.STATUS_CATEGORY,
			FILTER_TYPE.SPRINT_CATEGORY,
			FILTER_TYPE.TEAM,
			FILTER_TYPE.INDICATOR_FILTERED,
			FILTER_TYPE.RECENT_ACTIVITY,
			FILTER_TYPE.PHASE_CATEGORY,
			FILTER_TYPE.PROJECT_STAGE,
			FILTER_TYPE.DEPENDENCIES,
			FILTER_TYPE.OWNER,
			FILTER_TYPE.DEADLINE,
			FILTER_TYPE.PROJECT_TYPE,
		];

		/**
		 * Add filter selector
		 */
		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			operatorOptions: {
				allowExclude: hasFeatureFlag('unified_filters_reports'),
				allowRequireAll: hasFeatureFlag('unified_filters_reports'),
			},
			defaultSection: FILTER_SECTIONS.TASKS,
			projectFilters: [],
			peopleFilters: [],
			timeRegFilters: [],
			taskFilters: getFiltersAlphabetically(taskFilters, intl.formatMessage),
			primaryFilters: {
				[FILTER_SECTIONS.TASKS]: [
					FILTER_TYPE.PROJECT,
					FILTER_TYPE.PERSON,
					FILTER_TYPE.CLIENT,
					FILTER_TYPE.ROLE,
					FILTER_TYPE.STATUS_CATEGORY,
					FILTER_TYPE.LABEL,
					FILTER_TYPE.PROJECT_STAGE,
					FILTER_TYPE.DEADLINE,
					FILTER_TYPE.TEAM,
				],
			},
			viewer: viewer,
			filterSection: FILTER_SECTION.TASK_REPORT,
			appliedFiltersName: `task-report-filters`,
			noMenu: false,
			useSavedReport: true,
			preAppliedFilters: filterValue,
			onFiltersChange,
			userpilot: 'report-filter-button',
		});
		return buildHeaderBar(leftContent, rightContent);
	};

	useTrackPage('Standard Task Report');

	return (
		<>
			{getHeaderTitleBar()}
			{getNavBar()}

			<ForecastQueryRenderer
				key="query-render-taskReportTable"
				query={TaskReportTableQuery}
				// This is basically initialVariables
				variables={{
					searchQuery: searchQuery,
				}}
				render={relayProps => {
					return (
						<TaskReportTable
							{...relayProps}
							relayProps={relayProps}
							enabledColumns={enabledColumns}
							groupings={groupings}
							searchQuery={searchQuery}
							customFieldDefinitions={customFieldDefinitions}
						/>
					);
				}}
			/>
		</>
	);
};

export const taskReportPageQuery = graphql`
	query TaskReportPage_Query($savedReportId: String) {
		viewer {
			actualPersonId
			component(name: "task_report_page")
			...TaskReportPage_viewer @arguments(savedReportId: $savedReportId)
		}
	}
`;

export default withRouter(
	createFragmentContainer(TaskReportPage, {
		viewer: graphql`
			fragment TaskReportPage_viewer on Viewer @argumentDefinitions(savedReportId: {type: "String"}) {
				actualPersonId
				id
				email
				company {
					customFieldDefinitions(first: 1000) @connection(key: "Company_customFieldDefinitions") {
						edges {
							node {
								id
								key
								entityType
								displayName
							}
						}
					}
				}
				filters(first: 10000, filterSection: TASK_REPORT) @connection(key: "Viewer_filters", filters: []) {
					edges {
						node {
							id
							name
							section
							value
							updatedAt
						}
					}
				}
			}
		`,
	})
);
