import {Table} from 'web-components';
import React from 'react';
import {ForecastTooltipFormulaRenderer} from '../../ForecastTooltipFormulaRenderer';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {renderPercentage} from './period/ProjectPortfolioPeriodRow';

export const PercentageColumn = ({value, tooltip, valuePostfix = '', showZero = false}) => {
	return (
		<Table.Column align={'right'} usePadding negative={value < 0}>
			{tooltip ? (
				<ForecastTooltip
					maxWidth="600px"
					content={<ForecastTooltipFormulaRenderer items={tooltip} translatedMessage={true} />}
				>
					{renderPercentage(value, showZero) + valuePostfix}
				</ForecastTooltip>
			) : (
				renderPercentage(value, showZero) + valuePostfix
			)}
		</Table.Column>
	);
};
