import {ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE} from '../../../constants';
import HeaderBar from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import React from 'react';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/dropdown_section';
import {CAPACITY_OVERVIEW_FILTER_STORAGE_KEY} from './CanvasCapacityOverviewConstants';
import {
	onToggleCalcWin,
	onToggleHideHard,
	onToggleHideSoft,
} from '../components/allocation_controls/AllocationControlsCanvasUtils';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import EventManager from '../EventManager';

const mapFiltersToGraphQLType = pageComponent => {
	const {capacityOverviewFilters} = pageComponent.getData();
	return {
		filters: {edges: [...capacityOverviewFilters].map(filter => ({node: {...filter}}))},
	};
};

export default ({pageComponent}) => {
	const hasWinAndSoftFeatureFlag = hasFeatureFlag('placeholders');

	const getHeader = () => {
		const {eyeOptions, initialDataLoaded, filters} = pageComponent.state;

		const leftContent = [],
			rightContent = [];

		leftContent.push({
			type: ELEMENT_TYPE.EMPTY_SPACE,
			width: 14,
		});

		leftContent.push({
			type: ELEMENT_TYPE.DATE_CHANGER,
			onlyTodayButton: true,
			handleTodayButtonClick: () => EventManager.onScrollToToday(pageComponent),
			handleMoveDateButtonClick: moveForward => EventManager.onMoveDateButtonClick(pageComponent, moveForward),
			userpilot: 'capacity-overview-date-changer',
			cy: 'capacity-overview-date-changer',
		});

		leftContent.push({
			type: ELEMENT_TYPE.ZOOM_MENU,
		});

		if (eyeOptions?.length > 0) {
			rightContent.push({
				type: ELEMENT_TYPE.THE_EYE,
				options: eyeOptions,
				onSelect: selected => EventManager.onEyeOptionsChange(pageComponent, selected),
				expandLeft: true,
				disableTooltip: true,
			});
		}

		if (initialDataLoaded) {
			const data = pageComponent.getData();
			const {viewer} = data;

			if (hasWinAndSoftFeatureFlag) {
				if (hasFeatureFlag('capacity_planning_beta_2_improvements')) {
					rightContent.push({
						type: ELEMENT_TYPE.WIN_PROBABILITY,
						calcWin: pageComponent.state.schedulingOptions.calcWin,
						onToggleCalcWin: checked => onToggleCalcWin(pageComponent, checked),
						cy: 'canvas-win-probability',
					});

					rightContent.push({
						type: ELEMENT_TYPE.ALLOCATION_CONTROLS,
						hideSoft: pageComponent.state.schedulingOptions.hideSoft,
						onToggleHideSoft: checked => onToggleHideSoft(pageComponent, checked),
						hideHard: pageComponent.state.schedulingOptions.hideHard,
						onToggleHideHard: checked => onToggleHideHard(pageComponent, checked),
						cy: 'canvas-allocation-controls',
					});
				} else {
					rightContent.push({
						type: ELEMENT_TYPE.ALLOCATION_CONTROLS,
						hideSoft: pageComponent.state.schedulingOptions.hideSoft,
						onToggleHideSoft: checked => onToggleHideSoft(pageComponent, checked),
						hideHard: pageComponent.state.schedulingOptions.hideHard,
						onToggleHideHard: checked => onToggleHideHard(pageComponent, checked),
						calcWin: pageComponent.state.schedulingOptions.calcWin,
						onToggleCalcWin: checked => onToggleCalcWin(pageComponent, checked),
						cy: 'canvas-allocation-controls',
					});
				}
			}

			const filterV4Viewer = mapFiltersToGraphQLType(pageComponent);

			const filterContent = {
				type: ELEMENT_TYPE.FILTER_V4,
				viewer: filterV4Viewer,
				appliedFiltersName: CAPACITY_OVERVIEW_FILTER_STORAGE_KEY,
				filterSection: FILTER_SECTION.SCHEDULING_CAPACITY_OVERVIEW,
				defaultSection: FILTER_SECTIONS.RESOURCES,
				onFiltersChange: (filterValues, filterFunctions) =>
					EventManager.onFilterChange(pageComponent, filterValues, filterFunctions),
				projectId: viewer.project ? viewer.project.id : null,
				projectGroupId: viewer.projectGroup ? viewer.projectGroup.id : null,
				userpilot: 'filter-button',
				cy: 'capacity-overview-schedule-filters',
				preAppliedFilters: filters ? filters : undefined,
				enableFilterMode: true,
			};

			filterContent.resourceFilters = [FILTER_TYPE.PROJECT, FILTER_TYPE.ROLE, FILTER_TYPE.SKILL];
			if (hasFeatureFlag('capacity_planning_beta_2_improvements')) {
				filterContent.resourceFilters.push(FILTER_TYPE.DEPARTMENT, FILTER_TYPE.TEAM);
			}

			filterContent.projectFilters = [
				FILTER_TYPE.PROJECT,
				FILTER_TYPE.PROJECT_TYPE,
				FILTER_TYPE.CLIENT,
				FILTER_TYPE.PROJECT_STAGE,
				FILTER_TYPE.PROJECT_STATUS,
				FILTER_TYPE.PROJECT_OWNER,
				FILTER_TYPE.LABEL,
				FILTER_TYPE.PERSON,
				FILTER_TYPE.TIME_OFF,
				FILTER_TYPE.INTERNAL_TIME,
				...(hasWinAndSoftFeatureFlag ? [FILTER_TYPE.PROJECT_WIN_CHANCE] : []),
			];

			rightContent.push(filterContent);
		}

		return <HeaderBar scheduling={true} noPadding={true} leftContent={leftContent} rightContent={rightContent} />;
	};

	return <div className="control-bar">{getHeader()}</div>;
};
