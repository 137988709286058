import React from 'react';
import {Dropdown} from 'web-components';
import RadioButton from '../../components/new-ui/radio_button';
import WarningIcon from '../../images/components/warning.svg';
import {FormattedMessage} from 'react-intl';
import {GithubRepositoriesWrapper, RadioButtonWrapper, WarningWrapper} from './project_settings_styled';
import InlineLoader from '../../forecast-app/shared/components/inline-loader/inline_loader';

const GithubIntegrationSection = ({
	onSelect,
	dropdownPlaceholder,
	options,
	projectRepositories,
	intl,
	githubInstallationId,
	syncGitHubIssuesRepo,
	setSelectedIssuesSyncTarget,
	fetchingGithubRepositories,
}) => {
	const onSelectDropdown = value => {
		const updatedRepoList = [...projectRepositories, value[0]];
		onSelect(updatedRepoList);
	};

	const onRemoveDropdown = arrayValuesToRemove => {
		const valueToRemove = arrayValuesToRemove[0].value;
		const updatedRepoList = projectRepositories.filter(option => option.value !== valueToRemove);
		onSelect(updatedRepoList);
		if (syncGitHubIssuesRepo && syncGitHubIssuesRepo === valueToRemove) {
			setSelectedIssuesSyncTarget(null);
		}
	};

	return fetchingGithubRepositories ? (
		<InlineLoader />
	) : (
		<>
			<Dropdown
				isClearable
				isNested
				isMultiSelect
				selectedGroupName={intl.formatMessage({id: 'common.applied'})}
				selectedItems={projectRepositories}
				width={275}
				dropdownAlignment={'left'}
				name={intl.formatMessage({id: 'github.add_repository_placeholder'})}
				onSelect={v => onSelectDropdown(v)}
				onRemove={v => onRemoveDropdown(v)}
			>
				<Dropdown.Group name={intl.formatMessage({id: 'github.repositories'})} key={'dropdown-options'}>
					{options.map(repo => {
						return (
							<Dropdown.SingleText key={repo.value} value={repo} searchString={repo.label}>
								{repo.label}
							</Dropdown.SingleText>
						);
					})}
				</Dropdown.Group>
			</Dropdown>

			{projectRepositories &&
				(projectRepositories.length > 0 ? (
					<GithubRepositoriesWrapper>
						<div className="github-flex-container">
							<table className="github-repository-table">
								<thead>
									<td className="github-repository-header">
										{intl.formatMessage({id: 'github.added_repositories'})}
									</td>
									{githubInstallationId ? (
										<RadioButtonWrapper>
											<td className="github-sync-header">{intl.formatMessage({id: 'github.sync'})}</td>
										</RadioButtonWrapper>
									) : null}
								</thead>
								<tbody>
									{projectRepositories.map(r => (
										<tr>
											<td>{r.label}</td>
											{githubInstallationId ? (
												<td>
													<RadioButtonWrapper>
														<RadioButton
															onSelected={() => {
																return syncGitHubIssuesRepo === r.value
																	? setSelectedIssuesSyncTarget(null)
																	: setSelectedIssuesSyncTarget(r.value);
															}}
															value={syncGitHubIssuesRepo}
															stateValue={r.value}
															useOnClick={true}
														/>
													</RadioButtonWrapper>
												</td>
											) : null}
										</tr>
									))}
								</tbody>
							</table>
							{githubInstallationId ? (
								<WarningWrapper>
									<div className="github-sync-warning">
										<img alt="warning" title="warning" src={WarningIcon} />
										<FormattedMessage id={'github.sync_warning'} />
									</div>
								</WarningWrapper>
							) : null}
						</div>
					</GithubRepositoriesWrapper>
				) : null)}
		</>
	);
};

export default GithubIntegrationSection;
