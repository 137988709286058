import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Person from '../../../forecast-app/shared/components/person/person';
import InsightsUtil from '../insights_util';
import Util from '../../../forecast-app/shared/util/util';
import {cloneDeep} from 'lodash';
import {profilePicSrc} from '../../../directApi';

class cardListPortfolioComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sortBy: {column: null, ascending: true},
		};
	}

	UNSAFE_componentWillMount() {
		const listHeight = InsightsUtil.GetlistHeight(this.props.viewer.insightComponentsData.cardListPortfolio.tasks.length);
		if (this.props.height !== listHeight) {
			this.props.updateComponentHeight(this.props.id, listHeight);
		}
		if (this.props.setExportColumns) {
			const configObject = this.props.config ? JSON.parse(this.props.config) : {};
			let exportColumns = [];
			if (configObject) {
				this.props.columns.forEach(col => {
					if (configObject.columns.indexOf(col) > -1) {
						if (
							[
								'progress',
								'forecast',
								'reported',
								'remaining',
								'projected',
								'assigned',
								'labels',
								'projectStatusColor',
								'costForecast',
								'low',
								'high',
							].includes(col)
						) {
							exportColumns.push(col + '_raw');
						} else {
							exportColumns.push(col);
						}
					}
				});
			}
			this.props.setExportColumns(exportColumns);
		}
		this.setData(this.props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		// Calculate height based on amount of cards in nextProps
		const listHeight = InsightsUtil.GetlistHeight(nextProps.viewer.insightComponentsData.cardListPortfolio.tasks.length);
		if (this.props.height !== listHeight) this.props.updateComponentHeight(this.props.id, listHeight);
		if (this.props.approvedFilterValue !== nextProps.approvedFilterValue)
			this.props.relay.refetch({approvedFilterValue: nextProps.approvedFilterValue});
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		this.setData(nextProps, nextState);
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	setData(props, state) {
		const {formatMessage, formatDate} = props.intl;
		this.cards = [];
		const {cardListPortfolio} = cloneDeep(props.viewer.insightComponentsData);

		// Boolean variables to check if we have any tasks using hours or points
		let usingHours = false;
		let usingPoints = false;

		// Used for calculating the totals
		let totalForecastMinutes = 0;
		let totalForecastPoints = 0;
		let totalRemainingMinutes = 0;
		let totalRemainingPoints = 0;
		let totalReportedMinutes = 0;
		let totalLowMinutes = 0;
		let totalLowPoints = 0;
		let totalHighMinutes = 0;
		let totalHighPoints = 0;
		cardListPortfolio.tasks.forEach(task => {
			const card = {};
			card.id = 'T' + task.companyTaskId;
			card.companyTaskId = task.companyTaskId;
			card.name = task.name;
			card.companyProjectId = 'P-' + task.companyProjectId;
			card.projectName = task.projectName;
			card.sprint = task.sprintName || formatMessage({id: 'project_sprints.backlog'});
			card.phase = task.phaseName || '';
			card.cardStatus = task.statusColumnName || '';
			card.forecast_raw = task.useHours ? Math.round((task.estimateForecast / 60.0) * 100) / 100 : task.estimateForecast;
			card.forecast = task.useHours
				? Util.convertMinutesToFullHour(task.estimateForecast, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: task.estimateForecast});
			card.costForecast_raw = task.costForecast;
			card.costForecast = Util.GetFormattedCurrencySymbol(
				cardListPortfolio.currency,
				Math.round(task.costForecast * 100) / 100
			);
			card.remaining_raw = task.useHours ? Math.round((task.timeLeft / 60.0) * 100) / 100 : task.timeLeft;
			card.remaining = task.useHours
				? Util.convertMinutesToFullHour(task.timeLeft, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: task.timeLeft});
			card.reported_raw = Math.round((task.reportedMinutes / 60.0) * 100) / 100;
			card.reported = Util.convertMinutesToFullHour(task.reportedMinutes, props.intl);
			const projected =
				card.reported_raw + (task.useHours ? card.remaining_raw : (task.minutesPerPoint * card.remaining_raw) / 60.0);
			card.projected_raw = projected;
			card.projected = Util.convertMinutesToFullHour(projected, props.intl);
			// Card dates
			card.startDate = task.startYear ? formatDate(new Date(task.startYear, task.startMonth - 1, task.startDay)) : null;
			card.deadline = task.deadlineYear
				? formatDate(new Date(task.deadlineYear, task.deadlineMonth - 1, task.deadlineDay))
				: null;
			card.startDate_raw = task.startYear ? new Date(task.startYear, task.startMonth - 1, task.startDay) : null;
			card.deadline_raw = task.deadlineYear
				? new Date(task.deadlineYear, task.deadlineMonth - 1, task.deadlineDay)
				: null;
			// Project dates
			card.projectStartDate = task.projectStartYear
				? formatDate(new Date(task.projectStartYear, task.projectStartMonth - 1, task.projectStartDay))
				: null;
			card.projectDeadline = task.projectDeadlineYear
				? formatDate(new Date(task.projectDeadlineYear, task.projectDeadlineMonth - 1, task.projectDeadlineDay))
				: null;
			card.projectStartDate_raw = task.projectStartYear
				? new Date(task.projectStartYear, task.projectStartMonth - 1, task.projectStartDay)
				: null;
			card.projectDeadline_raw = task.projectDeadlineYear
				? new Date(task.projectDeadlineYear, task.projectDeadlineMonth - 1, task.projectDeadlineDay)
				: null;
			// Phase dates
			card.phaseStartDate = task.phaseStartYear
				? formatDate(new Date(task.phaseStartYear, task.phaseStartMonth - 1, task.phaseStartDay))
				: null;
			card.phaseDeadline = task.phaseDeadlineYear
				? formatDate(new Date(task.phaseDeadlineYear, task.phaseDeadlineMonth - 1, task.phaseDeadlineDay))
				: null;
			card.phaseStartDate_raw = task.phaseStartYear
				? new Date(task.phaseStartYear, task.phaseStartMonth - 1, task.phaseStartDay)
				: null;
			card.phaseDeadline_raw = task.phaseDeadlineYear
				? new Date(task.phaseDeadlineYear, task.phaseDeadlineMonth - 1, task.phaseDeadlineDay)
				: null;
			// Sprint dates
			card.sprintStartDate = task.sprintStartYear
				? formatDate(new Date(task.sprintStartYear, task.sprintStartMonth - 1, task.sprintStartDay))
				: null;
			card.sprintDeadline = task.sprintDeadlineYear
				? formatDate(new Date(task.sprintDeadlineYear, task.sprintDeadlineMonth - 1, task.sprintDeadlineDay))
				: null;
			card.sprintStartDate_raw = task.sprintStartYear
				? new Date(task.sprintStartYear, task.sprintStartMonth - 1, task.sprintStartDay)
				: null;
			card.sprintDeadline_raw = task.sprintDeadlineYear
				? new Date(task.sprintDeadlineYear, task.sprintDeadlineMonth - 1, task.sprintDeadlineDay)
				: null;

			card.client = task.client;
			card.role = task.role;
			card.rateCard = task.rateCard;

			card.projectStage =
				task.projectStage === 'PLANNING'
					? formatMessage({id: 'project_status.planning'})
					: task.projectStage === 'RUNNING'
					? formatMessage({id: 'project_status.running'})
					: task.projectStage === 'HALTED'
					? formatMessage({id: 'project_status.halted'})
					: formatMessage({id: 'project_status.done'});
			card.projectStatusColor = <div className="status-color" style={{backgroundColor: task.projectStatusColor}} />;
			card.projectStatusColor_raw = Util.getProjectStatusColorName(task.projectStatusColor);
			card.projectStatusColor_hex = task.projectStatusColor;
			card.projectStatusDescription = task.projectStatusDescription
				? Util.ConvertDraftJsToPlainText(task.projectStatusDescription)
				: null;

			card.assigned_raw = task.assignedPersons
				? task.assignedPersons.map(person => {
						return person.firstName + ' ' + person.lastName;
				  })
				: [];
			card.assigned = task.assignedPersons
				.sort((a, b) => {
					const name_a =
						a && a.firstName ? a.firstName.toLowerCase() + ' ' + (a.lastName ? a.lastName.toLowerCase() : '') : '';
					const name_b =
						b && b.firstName ? b.firstName.toLowerCase() + ' ' + (b.lastName ? b.lastName.toLowerCase() : '') : '';
					if (name_a < name_b) return -1;
					if (name_a > name_b) return 1;
					return 0;
				})
				.slice(0, 3)
				.map((person, index) =>
					index === 2 ? (
						<div className="person-elipsis-view" key={person.id}>
							<Person
								key={person.id}
								name={person.firstName + ' ' + person.lastName}
								role={''}
								showName={false}
								showRole={false}
								imageSrc={profilePicSrc(person.profilePictureId)}
								imageSize="medium"
							/>
							<span>...</span>
						</div>
					) : (
						<Person
							key={person.id}
							name={person.firstName + ' ' + person.lastName}
							role={''}
							showName={false}
							showRole={false}
							imageSrc={profilePicSrc(person.profilePictureId)}
							imageSize="medium"
						/>
					)
				);
			card.approved = formatMessage({id: task.approved ? 'common.yes' : 'common.no'});
			const progress = Util.calculateElementProgress(
				task.estimateForecast,
				task.reportedMinutes,
				task.timeLeft,
				null,
				null,
				task.useHours,
				task.minutesPerPoint,
				true,
				task.statusDone
			);

			card.progress_raw = progress;
			card.progress = (
				<div title={progress + '%'} className="progress-container" style={{border: '1px solid rgb(68, 180, 255)'}}>
					<div className="progress-indicator" style={{width: progress + '%', backgroundColor: 'rgb(68, 180, 255)'}} />
				</div>
			);

			card.bug = task.bug ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'});
			card.billable = task.billable ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'});
			card.blocked = task.blocked ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'});
			card.labels = task.labels;
			card.labels_raw = task.labels.map((label, index) => {
				return label.name;
			});

			totalForecastMinutes += task.useHours ? task.estimateForecast : 0;
			totalForecastPoints += task.useHours ? 0 : task.estimateForecast;
			totalRemainingMinutes += task.useHours ? task.timeLeft : 0;
			totalRemainingPoints += task.useHours ? 0 : task.timeLeft;
			totalReportedMinutes += task.reportedMinutes;

			if (!usingHours && task.useHours) {
				usingHours = true;
			}
			if (!usingPoints && !task.useHours) {
				usingPoints = true;
			}

			this.cards.push(card);
		});

		this.totals = {
			reported: Util.convertMinutesToFullHour(totalReportedMinutes, props.intl),
		};
		if (usingHours && usingPoints) {
			this.totals.low =
				Util.convertMinutesToFullHour(totalLowMinutes, props.intl) +
				' / ' +
				formatMessage({id: 'common.x_points'}, {points: totalLowPoints});
			this.totals.high =
				Util.convertMinutesToFullHour(totalHighMinutes, props.intl) +
				' / ' +
				formatMessage({id: 'common.x_points'}, {points: totalHighPoints});
			this.totals.forecast =
				Util.convertMinutesToFullHour(totalForecastMinutes, props.intl) +
				' / ' +
				formatMessage({id: 'common.x_points'}, {points: totalForecastPoints});
			this.totals.remaining =
				Util.convertMinutesToFullHour(totalRemainingMinutes, props.intl) +
				' / ' +
				formatMessage({id: 'common.x_points'}, {points: totalRemainingPoints});
		} else if (usingHours) {
			this.totals.low = Util.convertMinutesToFullHour(totalLowMinutes, props.intl);
			this.totals.high = Util.convertMinutesToFullHour(totalHighMinutes, props.intl);
			this.totals.forecast = Util.convertMinutesToFullHour(totalForecastMinutes, props.intl);
			this.totals.remaining = Util.convertMinutesToFullHour(totalRemainingMinutes, props.intl);
		} else if (usingPoints) {
			this.totals.low = formatMessage({id: 'common.x_points'}, {points: totalLowPoints});
			this.totals.high = formatMessage({id: 'common.x_points'}, {points: totalHighPoints});
			this.totals.forecast = formatMessage({id: 'common.x_points'}, {points: totalForecastPoints});
			this.totals.remaining = formatMessage({id: 'common.x_points'}, {points: totalRemainingPoints});
		}

		if (state.sortBy.column) {
			this.cards = this.cards.sort((a, b) => {
				let column = state.sortBy.column;
				switch (column) {
					case 'low':
					case 'high':
					case 'forecast':
					case 'remaining':
					case 'reported':
					case 'progress':
					case 'assigned':
					case 'startDate':
					case 'deadline':
					case 'projectStartDate':
					case 'projectDeadline':
					case 'phaseStartDate':
					case 'phaseDeadline':
					case 'sprintStartDate':
					case 'sprintDeadline':
					case 'costForecast':
						column += '_raw';
						break;
					case 'id':
						column = 'companyTaskId';
						break;
					default:
						break;
				}

				let returnValue = 0;
				if (a[column] < b[column]) returnValue = 1;
				if (a[column] > b[column]) returnValue = -1;
				if (a[column] === '') returnValue = -1;

				return state.sortBy.ascending ? returnValue * -1 : returnValue;
			});
		}
		if (props.setExportData) {
			props.setExportData(this.cards);
		}
	}

	render() {
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};
		return (
			<InsightList
				componentId={this.props.id}
				data={this.cards}
				componentName={'cardListPortfolio'}
				activeColumns={configObject ? configObject.columns : []}
				allColumns={this.props.columns}
				sortBy={this.state.sortBy}
				setSortBy={this.setSortBy.bind(this)}
				showTaskModal={this.props.shareKey !== '' && this.props.shareKey != null ? null : this.props.showTaskModal}
				totals={this.totals}
				disableSort="labels"
				scrollElement={this.props.scrollElement}
				scrollElementFullScreen={this.props.scrollElementFullScreen}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

cardListPortfolioComponent.propTypes = {
	title: PropTypes.string,
};

const cardListPortfolioComponentQuery = graphql`
	query cardListPortfolioComponent_Query($shareKey: String, $componentId: ID, $approvedFilterValue: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_card_list_portfolio")
			...cardListPortfolioComponent_viewer
				@arguments(shareKey: $shareKey, componentId: $componentId, approvedFilterValue: $approvedFilterValue)
		}
	}
`;

export {cardListPortfolioComponentQuery};

export default injectIntl(
	createRefetchContainer(
		cardListPortfolioComponent,
		{
			viewer: graphql`
				fragment cardListPortfolioComponent_viewer on Viewer
				@argumentDefinitions(
					shareKey: {type: "String"}
					componentId: {type: "ID"}
					approvedFilterValue: {type: Boolean}
				) {
					availableFeatureFlags {
						key
					}
					insightComponentsData(shareKey: $shareKey) {
						cardListPortfolio(componentId: $componentId, approvedFilterValue: $approvedFilterValue) {
							currency
							tasks {
								id
								approved
								companyTaskId
								projectName
								companyProjectId
								name
								description
								timeLeft
								startYear
								startMonth
								startDay
								deadlineDay
								deadlineMonth
								deadlineYear
								useHours
								minutesPerPoint
								estimateForecast
								costForecast
								blocked
								bug
								billable
								sprintName
								phaseName
								assignedPersons {
									id
									firstName
									lastName
									profilePictureId
									profilePictureDefaultId
								}
								statusColumnName
								statusDone
								subTaskCount
								doneSubTaskCount
								fileCount
								commentCount
								reportedMinutes
								labels {
									id
									name
									color
								}
								role
								projectStartYear
								projectStartMonth
								projectStartDay
								projectDeadlineYear
								projectDeadlineMonth
								projectDeadlineDay
								projectStage
								projectStatusColor
								projectStatusDescription
								rateCard
								client
								phaseStartYear
								phaseStartMonth
								phaseStartDay
								phaseDeadlineYear
								phaseDeadlineMonth
								phaseDeadlineDay
								sprintStartYear
								sprintStartMonth
								sprintStartDay
								sprintDeadlineYear
								sprintDeadlineMonth
								sprintDeadlineDay
							}
						}
					}
				}
			`,
		},
		graphql`
			query cardListPortfolioComponentRefetchQuery($shareKey: String, $componentId: ID, $approvedFilterValue: Boolean) {
				viewer {
					...cardListPortfolioComponent_viewer
						@arguments(shareKey: $shareKey, componentId: $componentId, approvedFilterValue: $approvedFilterValue)
				}
			}
		`
	)
);
