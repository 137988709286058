/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query ExpenseItemChangePersonModalQuery {
  viewer {
    actualPersonId
    component(name: "ExpenseItemChangePersonModal")
    ...ExpenseItemChangePersonModal_viewer
    id
  }
}

fragment ExpenseItemChangePersonModal_viewer on Viewer {
  company {
    allPersons(first: 10000, excludeClientUsers: true) {
      edges {
        node {
          id
          fullName
          profilePictureId
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "ExpenseItemChangePersonModal"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"ExpenseItemChangePersonModal\")"
    }, v2 = [
        {
            "kind": "Literal",
            "name": "excludeClientUsers",
            "value": true
        },
        {
            "kind": "Literal",
            "name": "first",
            "value": 10000
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ExpenseItemChangePersonModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ExpenseItemChangePersonModal_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ExpenseItemChangePersonModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "concreteType": "PersonTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "allPersons",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PersonTypeEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Person",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "fullName",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "profilePictureId",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "__typename",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "allPersons(excludeClientUsers:true,first:10000)"
                                },
                                {
                                    "alias": null,
                                    "args": (v2 /*: any*/),
                                    "filters": [],
                                    "handle": "connection",
                                    "key": "Company_allPersons",
                                    "kind": "LinkedHandle",
                                    "name": "allPersons"
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "ExpenseItemChangePersonModalQuery",
            "operationKind": "query",
            "text": "query ExpenseItemChangePersonModalQuery {\n  viewer {\n    actualPersonId\n    component(name: \"ExpenseItemChangePersonModal\")\n    ...ExpenseItemChangePersonModal_viewer\n    id\n  }\n}\n\nfragment ExpenseItemChangePersonModal_viewer on Viewer {\n  company {\n    allPersons(first: 10000, excludeClientUsers: true) {\n      edges {\n        node {\n          id\n          fullName\n          profilePictureId\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = 'e6a98568a5e374f18b5458dd05c633bc';
export default node;
