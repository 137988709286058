import React from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown, SingleText} from 'web-components';

export const IdleTimeDropdown = ({
	timeOff,
	idleTimes,
	dropdownAlignment,
	width,
	name,
	optionsName,
	selectedItems,
	onSelect,
	onRemove,
	selectedGroupName,
	userpilot,
	showBulkActions,
	emphasizeEmptyState,
	headerLines,
}) => {
	const sortedIdleTimes = [...idleTimes]
		.filter(idleTime => !idleTime.node.disabled)
		.sort((idleTimeA, idleTimeB) => {
			if (idleTimeA.node.id === null) return -1;
			if (idleTimeB.node.id === null) return 1;
			if (idleTimeA.node.name.toLowerCase() < idleTimeB.node.name.toLowerCase()) return -1;
			if (idleTimeA.node.name.toLowerCase() > idleTimeB.node.name.toLowerCase()) return 1;
			return 0;
		});

	const getElements = () => {
		let idleTimesOptions = [];

		if (timeOff) {
			idleTimesOptions = sortedIdleTimes.filter(it => !it.node.isInternalTime);
		} else {
			idleTimesOptions = sortedIdleTimes.filter(it => it.node.isInternalTime);
		}

		return idleTimesOptions.map(edge => {
			const {id, name} = edge.node;
			return (
				<SingleText key={id} value={id}>
					{name}
				</SingleText>
			);
		});
	};
	return (
		<Dropdown
			isNested
			isMultiSelect
			headerLines={headerLines}
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
		>
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{getElements()}
			</Dropdown.Group>
		</Dropdown>
	);
};

IdleTimeDropdown.propTypes = {
	idleTimes: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				name: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	headerLines: PropTypes.element,
};

IdleTimeDropdown.defaultProps = {
	optionsName: 'IdleTimes',
	name: 'IdleTimes',
	selectedGroupName: 'Selected',
	onSelect: () => false,
	onRemove: () => false,
};

export default injectIntl(
	createFragmentContainer(IdleTimeDropdown, {
		idleTimes: graphql`
			fragment IdleTimeDropdown_idleTimes on IdleTimeTypeEdge @relay(plural: true) {
				node {
					id
					name
					isInternalTime
					disabled
				}
			}
		`,
	})
);
