import Styled from 'styled-components';
import logoGray from '../../../images/logo-gray.svg';

export default Styled.div`
    width: 35px;
    height: 35px;
    background-size: 35px 35px;
    background-image: url(${logoGray});
    background-repeat: no-repeat;
    background-position: center;
    animation: pulse 750ms linear 0s infinite;

    @keyframes pulse {
        0% {
            -ms-transform: scale(1);
            transform: scale(1);
        }
        50% {
            -ms-transform: scale(1.9);
            transform: scale(1.1);
        }
        100% {
            -ms-transform: scale(1);
            transform: scale(1);
        }
    }
`;
