import React from 'react';
import Util from '../../../../forecast-app/shared/util/util';
import {HighLevelBudgetNumbersBox, HighLevelBudgetNumbersContainer} from './HighLevelBudgetNumbers.styled';
import {getCurrency, getFixedPriceExpenseTotals, NUMBER_TYPE} from './util/BudgetUtils';
import {BUDGET_TYPE, PERIOD_BUDGET_TYPE} from '../../../../constants';
import {createFragmentContainer, graphql} from 'react-relay';
import {HighLevelNumber} from './HighLevelNumber';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import ProjectUtil from '../../../../forecast-app/shared/util/project_util';
import {useIntl} from 'react-intl';
import CompanySetupUtil from '../../../../forecast-app/shared/util/CompanySetupUtil';

const HighLevelBudgetNumbers = ({project, company}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const projectTracksRevenueRecognition = ProjectUtil.projectHasRevenueRecognition();

	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();

	const {budget, budgetType, defaultPeriodBudgetType, financialNumbers, financialNumbersToCurrentMonth} = project;
	const {
		billableActualTimeAndExpenses,
		actualCost,
		forecastCostToComplete,
		totalCostAtCompletion,
		billableForecastTimeAndExpensesToComplete,
		billableTotalTimeAndExpensesAtCompletion,
		recognitionProfit,
		recognitionProfitPercentage,
		totalRevenueRecognition,
		projectTotalForecastTimeAndExpensesToCompleteInPast,
		projectTotalForecastCostToCompleteInPast,
	} = financialNumbers;
	const {
		recognitionLockedProfit: recognitionLockedProfitToCurrentMonth,
		recognitionLockedProfitPercentage: recognitionLockedProfitPercentageToCurrentMonth,
		recognitionLockedRevenue: recognitionLockedRevenueToCurrentMonth,
	} = financialNumbersToCurrentMonth;

	const expenseBillableTotalTimeAndExpensesAtCompletion = getFixedPriceExpenseTotals(project.expenseItems?.edges);

	const currency = getCurrency(company, project);
	const currencySymbol = Util.GetCurrencySymbol(currency);
	const projectTracksRevenue = ProjectUtil.projectTracksRevenue(project);
	const isRetainerFixedPrice =
		budgetType === BUDGET_TYPE.RETAINER && defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE;
	const isFixedPrice =
		projectTracksRevenue &&
		(budgetType === BUDGET_TYPE.FIXED_PRICE || budgetType === BUDGET_TYPE.FIXED_PRICE_V2 || isRetainerFixedPrice);
	const isFixedPriceV2 =
		projectTracksRevenue &&
		(budgetType === BUDGET_TYPE.FIXED_PRICE_V2 ||
			(hasFeatureFlag('revenue_recognition_with_all_budget_types') &&
				(isRetainerFixedPrice || budgetType === BUDGET_TYPE.FIXED_PRICE)));
	const totalBudget = budget + expenseBillableTotalTimeAndExpensesAtCompletion;
	const hasExpenseRevenue = expenseBillableTotalTimeAndExpensesAtCompletion > 0;
	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');

	const ExpenseRelatedNumbers = () =>
		!!hasExpenseRevenue && (
			<>
				<HighLevelNumber
					currencySymbol={currencySymbol}
					labelTranslationId={'project_budget.revenue_from_expenses'}
					value={expenseBillableTotalTimeAndExpensesAtCompletion}
					numberType={NUMBER_TYPE.MONEY}
					cy={'revenue-from-expenses'}
				/>
				<HighLevelNumber
					currencySymbol={currencySymbol}
					labelTranslationId={'common.projected_total_revenue'}
					value={totalBudget}
					numberType={NUMBER_TYPE.MONEY}
					cy={'revenue-from-expenses'}
				/>
			</>
		);

	const RevenueRelatedNumbers = () =>
		!hasRevenueWithoutCostAccess && (
			<>
				<HighLevelNumber
					currencySymbol={currencySymbol}
					labelTranslationId={'common.projected_total_cost'}
					value={totalCostAtCompletion}
					numberType={NUMBER_TYPE.MONEY}
					cy={'projected-total-cost'}
				/>
				<HighLevelNumber
					currencySymbol={currencySymbol}
					labelTranslationId={'common.projected_total_profit'}
					value={recognitionProfit}
					numberType={NUMBER_TYPE.MONEY}
					cy={'projected-total-profit'}
				/>
				<HighLevelNumber
					labelTranslationId={'common.projected_total_margin'}
					value={recognitionProfitPercentage}
					numberType={NUMBER_TYPE.PERCENTAGE}
					cy={'projected-total-margin'}
				/>
			</>
		);

	return (
		<HighLevelBudgetNumbersContainer data-cy={'summary-table'}>
			{hasFinancialCategoriesUpdate && isFixedPriceV2 ? (
				isRetainerFixedPrice ? (
					<>
						<HighLevelBudgetNumbersBox wide>
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'retainer.total_price_of_periods'}
								value={budget}
								numberType={NUMBER_TYPE.MONEY}
								cy={'fixed-price-budget'}
							/>
							<ExpenseRelatedNumbers />
							<RevenueRelatedNumbers />
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'retainer.average_actual_hours'}
								value={'-' /* TODO: Depends on T53000 */}
								numberType={undefined}
								cy={'fixed-price-budget'}
							/>
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'retainer.average_profit'}
								value={'-' /* TODO: Depends on T53000 */}
								numberType={undefined}
								cy={'fixed-price-budget'}
							/>
						</HighLevelBudgetNumbersBox>
					</>
				) : (
					<>
						<HighLevelBudgetNumbersBox wide>
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'project_budget.fixed_price'}
								value={budget}
								numberType={NUMBER_TYPE.MONEY}
								cy={'fixed-price-budget'}
							/>
							<ExpenseRelatedNumbers />
							<RevenueRelatedNumbers />
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'common.projected_total_billable_value_of_service'}
								value={billableTotalTimeAndExpensesAtCompletion}
								numberType={NUMBER_TYPE.MONEY}
								cy={'projected-total-billable-value-of-service'}
							/>
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'common.projected_remaining_budget'}
								value={totalBudget - billableTotalTimeAndExpensesAtCompletion}
								numberType={NUMBER_TYPE.MONEY}
								cy={'projected-remaining-budget'}
								tooltipItems={[
									{
										title: formatMessage({id: 'common.projected_remaining_budget'}),
										description: formatMessage({
											id: 'project_budget.projected_remaining_budget.tooltip',
										}),
										details: [
											formatMessage({
												id: hasExpenseRevenue
													? 'common.projected_total_revenue'
													: 'project_budget.fixed_price',
											}),
											'-',
											formatMessage({id: 'common.projected_total_billable_value_of_service'}),
										],
									},
								]}
							/>
						</HighLevelBudgetNumbersBox>
					</>
				)
			) : isFixedPriceV2 ? (
				<>
					<HighLevelBudgetNumbersBox>
						<HighLevelNumber
							currencySymbol={currencySymbol}
							labelTranslationId={'project_budget.project_fixed_price'}
							value={budget}
							numberType={NUMBER_TYPE.MONEY}
							cy={'fixed-price-budget'}
							tooltipItems={[{description: formatMessage({id: 'project_budget.project_fixed_price_tooltip'})}]}
						/>
						{hasFeatureFlag('Expense_Modal_Update_22Q3') && hasExpenseRevenue && (
							<>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.revenue_from_expenses'}
									value={expenseBillableTotalTimeAndExpensesAtCompletion}
									numberType={NUMBER_TYPE.MONEY}
									cy={'billable-expenses'}
									tooltipItems={[
										{description: formatMessage({id: 'project_budget.revenue_from_expenses_tooltip'})},
									]}
								/>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.total_revenue'}
									value={totalBudget}
									numberType={NUMBER_TYPE.MONEY}
									cy={'total-budget'}
									tooltipItems={[
										{
											title: formatMessage({id: 'project_budget.total_revenue'}),
											details: [
												formatMessage({id: 'project_budget.project_fixed_price'}),
												'+',
												formatMessage({id: 'project_budget.revenue_from_expenses'}),
											],
										},
									]}
								/>
							</>
						)}
						<HighLevelNumber
							currencySymbol={currencySymbol}
							labelTranslationId={'project_budget.actual_billable_spend_to_date'}
							value={billableActualTimeAndExpenses}
							numberType={NUMBER_TYPE.MONEY}
							cy={'actual-spend-to-date'}
						/>
						<HighLevelNumber
							currencySymbol={currencySymbol}
							labelTranslationId={'project_budget.budget_remaining'}
							value={
								hasFeatureFlag('Expense_Modal_Update_22Q3')
									? totalBudget - billableActualTimeAndExpenses
									: budget - billableActualTimeAndExpenses
							}
							numberType={NUMBER_TYPE.MONEY}
							cy={'budget-remaining'}
							bold
						/>
					</HighLevelBudgetNumbersBox>
					<HighLevelBudgetNumbersBox>
						<HighLevelNumber
							currencySymbol={currencySymbol}
							labelTranslationId={'project_budget.high_level_numbers.forecast_to_complete'}
							value={billableForecastTimeAndExpensesToComplete}
							numberType={NUMBER_TYPE.MONEY}
							cy={'remaining-revenue'}
						/>
						<HighLevelNumber
							currencySymbol={currencySymbol}
							labelTranslationId={'project_budget.high_level_numbers.total_at_completion'}
							value={billableTotalTimeAndExpensesAtCompletion}
							numberType={NUMBER_TYPE.MONEY}
							cy={'forecast-revenue'}
						/>
						<HighLevelNumber
							currencySymbol={currencySymbol}
							labelTranslationId={
								hasExpenseRevenue && hasFeatureFlag('Expense_Modal_Update_22Q3')
									? 'project_budget.variance_forecast_total_revenue'
									: 'project_budget.variance_forecast_budget'
							}
							value={
								hasFeatureFlag('Expense_Modal_Update_22Q3')
									? totalBudget - billableTotalTimeAndExpensesAtCompletion
									: budget - billableTotalTimeAndExpensesAtCompletion
							}
							numberType={NUMBER_TYPE.MONEY}
							cy={'variance-forecast-budget'}
							bold
						/>
					</HighLevelBudgetNumbersBox>

					{!hasRevenueWithoutCostAccess && (
						<HighLevelBudgetNumbersBox>
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'project_budget.actual_cost_to_date'}
								value={actualCost}
								numberType={NUMBER_TYPE.MONEY}
								cy={'actual-cost-to-date'}
							/>
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'project_budget.high_level_numbers.forecast_cost_to_complete'}
								value={forecastCostToComplete}
								numberType={NUMBER_TYPE.MONEY}
								cy={'remaining-cost'}
							/>
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'project_budget.total_cost_at_completion'}
								value={totalCostAtCompletion}
								numberType={NUMBER_TYPE.MONEY}
								cy={'forecast-cost'}
							/>
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'project_budget.high_level_numbers.forecast_profit_at_completion'}
								value={recognitionProfit}
								numberType={NUMBER_TYPE.MONEY}
								cy={'forecast-profit'}
							/>
							<HighLevelNumber
								currencySymbol={currencySymbol}
								labelTranslationId={'project_budget.high_level_numbers.forecast_profit_at_completion_percent'}
								value={recognitionProfitPercentage}
								numberType={NUMBER_TYPE.PERCENTAGE}
								cy={'forecast-profit-percentage'}
							/>
						</HighLevelBudgetNumbersBox>
					)}
					<HighLevelBudgetNumbersBox>
						<HighLevelNumber
							currencySymbol={currencySymbol}
							labelTranslationId={'project_budget.forecast_time_in_the_past'}
							value={projectTotalForecastTimeAndExpensesToCompleteInPast}
							numberType={NUMBER_TYPE.MONEY}
							cy={'budget-forecast-time-in-past'}
							red={projectTotalForecastTimeAndExpensesToCompleteInPast > 0}
						/>
					</HighLevelBudgetNumbersBox>
				</>
			) : (
				<>
					{isFixedPrice ? (
						<>
							<HighLevelBudgetNumbersBox>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.fixed_price_revenue_brackets'}
									value={budget}
									numberType={NUMBER_TYPE.MONEY}
									cy={'fixed-price-revenue'}
								/>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.actual_revenue'}
									value={billableActualTimeAndExpenses}
									numberType={NUMBER_TYPE.MONEY}
									cy={'actual-revenue'}
								/>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.remaining_revenue'}
									value={billableForecastTimeAndExpensesToComplete}
									numberType={NUMBER_TYPE.MONEY}
									cy={'remaining-revenue'}
								/>
								{!hasRevenueWithoutCostAccess && (
									<HighLevelNumber
										currencySymbol={currencySymbol}
										labelTranslationId={'common.cost'}
										value={totalCostAtCompletion}
										numberType={NUMBER_TYPE.MONEY}
										cy={'cost'}
									/>
								)}
								{!hasRevenueWithoutCostAccess && (
									<HighLevelNumber
										currencySymbol={currencySymbol}
										labelTranslationId={'common.profit'}
										value={budget - totalCostAtCompletion}
										numberType={NUMBER_TYPE.MONEY}
										cy={'profit'}
									/>
								)}
							</HighLevelBudgetNumbersBox>
							<HighLevelBudgetNumbersBox>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.forecast_time_in_the_past'}
									value={projectTotalForecastTimeAndExpensesToCompleteInPast}
									numberType={NUMBER_TYPE.MONEY}
									cy={'budget-forecast-time-in-past'}
									red={projectTotalForecastTimeAndExpensesToCompleteInPast > 0}
								/>
							</HighLevelBudgetNumbersBox>
						</>
					) : !ProjectUtil.projectTracksRevenue(project) ? (
						<>
							{!hasRevenueWithoutCostAccess && (
								<HighLevelBudgetNumbersBox>
									<HighLevelNumber
										currencySymbol={currencySymbol}
										labelTranslationId={'project_budget.actual_cost_to_date'}
										value={actualCost}
										numberType={NUMBER_TYPE.MONEY}
										cy={'actual-cost-to-date'}
									/>
									<HighLevelNumber
										currencySymbol={currencySymbol}
										labelTranslationId={'project_budget.high_level_numbers.forecast_cost_to_complete'}
										value={forecastCostToComplete}
										numberType={NUMBER_TYPE.MONEY}
										cy={'remaining-cost'}
									/>
									<HighLevelNumber
										currencySymbol={currencySymbol}
										labelTranslationId={'project_budget.total_cost_at_completion'}
										value={totalCostAtCompletion}
										numberType={NUMBER_TYPE.MONEY}
										cy={'forecast-cost'}
									/>
								</HighLevelBudgetNumbersBox>
							)}
							<HighLevelBudgetNumbersBox>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.forecast_cost_in_the_past'}
									value={projectTotalForecastCostToCompleteInPast}
									numberType={NUMBER_TYPE.MONEY}
									cy={'budget-forecast-cost-in-past'}
									red={projectTotalForecastCostToCompleteInPast > 0}
								/>
							</HighLevelBudgetNumbersBox>
						</>
					) : (
						<>
							<HighLevelBudgetNumbersBox>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.actual_billable_spend_to_date'}
									value={billableActualTimeAndExpenses}
									numberType={NUMBER_TYPE.MONEY}
									cy={'actual-spend-to-date'}
									bold
								/>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.high_level_numbers.forecast_to_complete'}
									value={billableForecastTimeAndExpensesToComplete}
									numberType={NUMBER_TYPE.MONEY}
									cy={'remaining-revenue'}
								/>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.high_level_numbers.total_at_completion'}
									value={billableTotalTimeAndExpensesAtCompletion}
									numberType={NUMBER_TYPE.MONEY}
									cy={'forecast-revenue'}
								/>
							</HighLevelBudgetNumbersBox>
							{!hasRevenueWithoutCostAccess && (
								<HighLevelBudgetNumbersBox>
									<HighLevelNumber
										currencySymbol={currencySymbol}
										labelTranslationId={'project_budget.actual_cost_to_date'}
										value={actualCost}
										numberType={NUMBER_TYPE.MONEY}
										cy={'actual-cost-to-date'}
										bold
									/>
									<HighLevelNumber
										currencySymbol={currencySymbol}
										labelTranslationId={'project_budget.high_level_numbers.forecast_cost_to_complete'}
										value={forecastCostToComplete}
										numberType={NUMBER_TYPE.MONEY}
										cy={'remaining-cost'}
									/>
									<HighLevelNumber
										currencySymbol={currencySymbol}
										labelTranslationId={'project_budget.total_cost_at_completion'}
										value={totalCostAtCompletion}
										numberType={NUMBER_TYPE.MONEY}
										cy={'forecast-cost'}
									/>
								</HighLevelBudgetNumbersBox>
							)}
							{CompanySetupUtil.hasFinance() && projectTracksRevenueRecognition && (
								<>
									<HighLevelBudgetNumbersBox>
										<HighLevelNumber
											currencySymbol={currencySymbol}
											labelTranslationId={
												'project_budget.high_level_numbers.revenue_recognition_locked_to_current_month'
											}
											value={recognitionLockedRevenueToCurrentMonth}
											numberType={NUMBER_TYPE.MONEY}
											cy={'forecast-profit'}
											bold
										/>
										<HighLevelNumber
											currencySymbol={currencySymbol}
											labelTranslationId={'project_budget.high_level_numbers.forecast_profit_to_date'}
											value={recognitionLockedProfitToCurrentMonth}
											numberType={NUMBER_TYPE.MONEY}
											cy={'forecast-profit'}
										/>
										<HighLevelNumber
											currencySymbol={currencySymbol}
											labelTranslationId={
												'project_budget.high_level_numbers.forecast_profit_to_date_percent'
											}
											value={recognitionLockedProfitPercentageToCurrentMonth}
											numberType={NUMBER_TYPE.PERCENTAGE}
											cy={'forecast-profit-percentage'}
										/>
									</HighLevelBudgetNumbersBox>
									<HighLevelBudgetNumbersBox>
										<HighLevelNumber
											currencySymbol={currencySymbol}
											labelTranslationId={'project_budget.recognition_amount'}
											value={totalRevenueRecognition}
											numberType={NUMBER_TYPE.MONEY}
											cy={'forecast-profit'}
											bold
										/>
										<HighLevelNumber
											currencySymbol={currencySymbol}
											labelTranslationId={
												'project_budget.high_level_numbers.forecast_profit_at_completion'
											}
											value={recognitionProfit}
											numberType={NUMBER_TYPE.MONEY}
											cy={'forecast-profit'}
										/>
										<HighLevelNumber
											currencySymbol={currencySymbol}
											labelTranslationId={
												'project_budget.high_level_numbers.forecast_profit_at_completion_percent'
											}
											value={recognitionProfitPercentage}
											numberType={NUMBER_TYPE.PERCENTAGE}
											cy={'forecast-profit-percentage'}
										/>
									</HighLevelBudgetNumbersBox>
								</>
							)}
							<HighLevelBudgetNumbersBox>
								<HighLevelNumber
									currencySymbol={currencySymbol}
									labelTranslationId={'project_budget.forecast_time_in_the_past'}
									value={projectTotalForecastTimeAndExpensesToCompleteInPast}
									numberType={NUMBER_TYPE.MONEY}
									cy={'budget-forecast-time-in-past'}
									red={projectTotalForecastTimeAndExpensesToCompleteInPast > 0}
								/>
							</HighLevelBudgetNumbersBox>
						</>
					)}
				</>
			)}
		</HighLevelBudgetNumbersContainer>
	);
};

export default createFragmentContainer(HighLevelBudgetNumbers, {
	company: graphql`
		fragment HighLevelBudgetNumbers_company on Company {
			tier
			id
			currency
		}
	`,
	project: graphql`
		fragment HighLevelBudgetNumbers_project on ProjectType
		@argumentDefinitions(currentYear: {type: "Int"}, currentMonth: {type: "Int"}, currentMonthLastDay: {type: "Int"}) {
			id
			budget
			budgetType
			defaultPeriodBudgetType
			timeRegBasedPlusDoneScopeCompletion
			rateCard {
				currency
			}
			financialNumbers(convertToProjectCurrency: true) {
				billableActualTimeAndExpenses
				actualCost
				billableForecastTimeAndExpensesToComplete
				forecastCostToComplete
				totalCostAtCompletion
				billableTotalTimeAndExpensesAtCompletion
				invoiced
				paid
				recognitionProfit
				recognitionProfitPercentage
				totalRevenueRecognition
				projectTotalForecastTimeAndExpensesToCompleteInPast
				projectTotalForecastCostToCompleteInPast
			}
			financialNumbersToCurrentMonth: financialNumbers(
				endYear: $currentYear
				endMonth: $currentMonth
				endDay: $currentMonthLastDay
				convertToProjectCurrency: true
			) {
				recognitionLockedProfit
				recognitionLockedProfitPercentage
				recognitionLockedRevenue
			}
			expenseItems(first: 10000) @connection(key: "Project_expenseItems") {
				edges {
					node {
						approved
						billable
						partOfFixedPrice
						expenseYear
						expenseMonth
						expenseDay
						price
						cost
						quantity
					}
				}
			}
		}
	`,
});
