/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FinancialCalculationTrigger_viewer$ref = any;
export type FinancialCalculationTrigger_QueryVariables = {|
  projectId: string
|};
export type FinancialCalculationTrigger_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: FinancialCalculationTrigger_viewer$ref,
  |}
|};
export type FinancialCalculationTrigger_Query = {|
  variables: FinancialCalculationTrigger_QueryVariables,
  response: FinancialCalculationTrigger_QueryResponse,
|};
*/


/*
query FinancialCalculationTrigger_Query(
  $projectId: ID!
) {
  viewer {
    actualPersonId
    component(name: "financial_calculation_trigger")
    ...FinancialCalculationTrigger_viewer_2CgS8h
    id
  }
}

fragment FinancialCalculationTrigger_viewer_2CgS8h on Viewer {
  project(internalId: $projectId) {
    id
    financialCalculationTrigger {
      timestamp
      duration
      isSmallProject
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "financial_calculation_trigger"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"financial_calculation_trigger\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FinancialCalculationTrigger_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "FinancialCalculationTrigger_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FinancialCalculationTrigger_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FinancialCalculationTrigger",
                "kind": "LinkedField",
                "name": "financialCalculationTrigger",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "timestamp",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isSmallProject",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "FinancialCalculationTrigger_Query",
    "operationKind": "query",
    "text": "query FinancialCalculationTrigger_Query(\n  $projectId: ID!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"financial_calculation_trigger\")\n    ...FinancialCalculationTrigger_viewer_2CgS8h\n    id\n  }\n}\n\nfragment FinancialCalculationTrigger_viewer_2CgS8h on Viewer {\n  project(internalId: $projectId) {\n    id\n    financialCalculationTrigger {\n      timestamp\n      duration\n      isSmallProject\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2165857a37ae7de1e48f5f743621a8a6';

module.exports = node;
