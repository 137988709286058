import React from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import {BUDGET_TYPE, BUTTON_COLOR, BUTTON_STYLE, HIDDEN_FEATURES, PERIOD_BUDGET_TYPE, PERIODICITY} from '../../../constants';
import Button from '../../../forecast-app/shared/components/buttons/button/button';
import InputFieldV2 from '../input_field';
import DropdownV2 from '../../../forecast-app/shared/components/dropdowns/dropdown';
import Util from '../../../forecast-app/shared/util/util';
import {ButtonsStyled, ComponentContainer, SpecificValuesStyled} from '../../../styles/v2/project/project_budget_type_controls';
import HoursInput from '../../../forecast-app/shared/components/inputs/hours-input/hours_input_view';
import {CurrencyInput, Dropdown, InputLabel} from 'web-components';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';
import {FlexColumn, DeprecatedLink as Link, DeprecatedText as Text} from '@forecast-it/design-system';
import {useHistory} from 'react-router-dom';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';
import ProgramUtil from '../../../forecast-app/shared/util/ProgramUtil';

const ProjectBudgetTypeControls = props => {
	const {
		intl,
		budgetType,
		project,
		currency,
		defaultPeriodBudgetType,
		handledefaultPeriodChange,
		defaultPeriodHoursAmount,
		defaultPeriodPriceAmount,
		onPeriodBudgetTypeChange,
		defaultPeriodPeriodicity,
		wrongPeriodLength,
		wrongPeriodAmount,
		errorMessage,
		defaultPeriodLength,
		handlePeriodLengthChange,
		onPeriodicityChange,
		handleFixedPriceBudget,
		fixedPriceBudget,
		rateCardSelected,
		invalidFixedPriceFormat,
		onBudgetTypeChange,
		handleSelectRateChange,
		rate_card_options,
		useRetainers,
		isInFixedPriceProgram,
		isInCappedRevenueProgram,
		fixedPriceCappedBudgetOverflow,
		program,
		closeModal,
		isOverProgramCap,
		fixedPriceLockedRevenueCap,
		cannotChangeToFixedPriceBudget,
	} = props;

	const history = useHistory();
	const {formatMessage} = intl;
	const isFixedPriceType = budgetType => {
		return budgetType === BUDGET_TYPE.FIXED_PRICE || budgetType === BUDGET_TYPE.FIXED_PRICE_V2;
	};

	if (isFixedPriceType(budgetType) && isInCappedRevenueProgram && fixedPriceCappedBudgetOverflow) {
		trackEvent('Project Budget', 'Changed', {
			error: 'Program Over-Allocated',
		});
	}

	const isFinancialOnLiteTimeAndMaterial =
		CompanySetupUtil.hasFinanceLight() && budgetType === BUDGET_TYPE.TIME_AND_MATERIALS;
	const getDefaultFixedPriceBudget = () => {
		return project && isFixedPriceType(project.budgetType) && project.budget !== undefined ? project.budget : '';
	};
	const onBudgetTypeClick = selectedBudgetType => {
		if (selectedBudgetType !== budgetType) {
			const newStateObject = {
				budgetType: selectedBudgetType,
			};
			if (!isFixedPriceType(selectedBudgetType) && isFixedPriceType(budgetType)) {
				newStateObject.invalidFixedPriceFormat = false;
				newStateObject.fixedPriceBudget = getDefaultFixedPriceBudget();
			}
			if (selectedBudgetType !== BUDGET_TYPE.RETAINER && budgetType === BUDGET_TYPE.RETAINER) {
				newStateObject.defaultPeriodPriceAmount = project ? project.defaultPeriodPriceAmount : null;
				newStateObject.defaultPeriodHoursAmount = project ? project.defaultPeriodHoursAmount : null;
				newStateObject.defaultPeriodBudgetType =
					project && project.defaultPeriodBudgetType
						? project.defaultPeriodBudgetType
						: PERIOD_BUDGET_TYPE.FIXED_HOURS;
				newStateObject.defaultPeriodLength = project && project.defaultPeriodLength ? project.defaultPeriodLength : 1;
				newStateObject.defaultPeriodPeriodicity =
					project && project.defaultPeriodPeriodicity ? project.defaultPeriodPeriodicity : PERIODICITY.MONTHLY;
				newStateObject.wrongPeriodLength = false;
				newStateObject.wrongPeriodAmount = false;
			}
			onBudgetTypeChange(newStateObject);
		}
	};
	const onDefaultPeriodHoursChange = value => {
		handledefaultPeriodChange('defaultPeriodHoursAmount', value);
	};
	const onDefaultPeriodPriceChange = value => {
		handledefaultPeriodChange('defaultPeriodPriceAmount', value);
	};
	const onPeriodLengthChange = value => {
		let newObject;
		if (value === null || value === undefined || value === '' || value < 1) {
			newObject = {
				defaultPeriodLength: value,
				wrongPeriodLength: true,
				errorMessage: formatMessage({id: 'retainer.period_lenght_error'}),
			};
		} else {
			newObject = {wrongPeriodLength: false, defaultPeriodLength: Math.round(value)};
		}
		handlePeriodLengthChange(newObject);
	};
	const getInvalidFixedPriceBudgetErrorMessage = () => {
		return !isNaN(fixedPriceBudget) && fixedPriceBudget <= 0
			? formatMessage({id: 'common.must_be_greater_than_zero'})
			: formatMessage({id: 'common.invalid_format'});
	};
	const validateFixedPriceBudget = () => {
		const invalidFixedPriceFormat = !parseFloat(fixedPriceBudget) || fixedPriceBudget <= 0;
		return {
			invalidFixedPriceFormat,
			errorMessage: invalidFixedPriceFormat ? getInvalidFixedPriceBudgetErrorMessage() : null,
		};
	};
	const handleEditProgramBudgetClick = () => {
		trackEvent('Edit Program Budget Link', 'Clicked', {location: 'From Project Budget Type Controls'});
		history.push(`${ProgramUtil.programLink()}/${program.prefix}/budget/edit`);
		closeModal();
	};

	const options_billing_period = [
		{value: PERIODICITY.MONTHLY, label: 'Monthly'},
		{value: PERIODICITY.WEEKLY, label: 'Weekly'},
		{value: PERIODICITY.DAILY, label: 'Daily'},
	];
	const options_period_budget_type = [
		!isInFixedPriceProgram && {
			value: PERIOD_BUDGET_TYPE.FIXED_HOURS,
			label: Util.getPeriodBudgetTypeTranslation(PERIOD_BUDGET_TYPE.FIXED_HOURS, intl),
			disabled: isOverProgramCap,
		},
		{
			value: PERIOD_BUDGET_TYPE.FIXED_PRICE,
			label: Util.getPeriodBudgetTypeTranslation(PERIOD_BUDGET_TYPE.FIXED_PRICE, intl),
		},
		!isInFixedPriceProgram && {
			value: PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS,
			label: Util.getPeriodBudgetTypeTranslation(PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS, intl),
			disabled: isOverProgramCap,
		},
	].filter(option => option);

	const programName = program?.name;

	// Users with no time registration
	const onlyNonBillable = Util.isFeatureHidden(HIDDEN_FEATURES.BUDGET_TYPES);
	const retainerCreatePermission = hasPermission(PERMISSION_TYPE.RETAINER_PERIOD_CREATE);
	const lockFinance = !props.hasFinance;
	return (
		<ComponentContainer>
			<div className="project-budget-type-controls-container">
				<ButtonsStyled>
					<div className={'budget-type-buttons'}>
						{!onlyNonBillable && (
							<Button
								text={formatMessage({id: 'new_project_modal.budget_type_fixed_price'})}
								buttonStyle={BUTTON_STYLE.FILLED}
								colorTheme={isFixedPriceType(budgetType) ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.MEDIUMGREY}
								onClick={() => {
									onBudgetTypeClick(BUDGET_TYPE.FIXED_PRICE_V2);
								}}
								cy={'budget-type-buttons-' + BUDGET_TYPE.FIXED_PRICE}
								locked={lockFinance}
								isDisabled={lockFinance}
								tooltipProps={
									lockFinance
										? {infoText: formatMessage({id: 'common.requires_financial_module'}), growable: true}
										: null
								}
							/>
						)}
						{!onlyNonBillable && (
							<Button
								text={formatMessage({id: 'new_project_modal.budget_type_time_materials'})}
								buttonStyle={BUTTON_STYLE.FILLED}
								colorTheme={
									budgetType === BUDGET_TYPE.TIME_AND_MATERIALS
										? BUTTON_COLOR.PURPLE
										: BUTTON_COLOR.MEDIUMGREY
								}
								onClick={() => {
									onBudgetTypeClick(BUDGET_TYPE.TIME_AND_MATERIALS);
								}}
								cy={'budget-type-buttons-' + BUDGET_TYPE.TIME_AND_MATERIALS}
								isDisabled={isInFixedPriceProgram || isOverProgramCap}
							/>
						)}
						{props.hasInvoice ? null : (
							<Button
								text={formatMessage({id: 'common.non-billable'})}
								buttonStyle={BUTTON_STYLE.FILLED}
								colorTheme={
									budgetType === BUDGET_TYPE.NON_BILLABLE ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.MEDIUMGREY
								}
								onClick={() => {
									onBudgetTypeClick(BUDGET_TYPE.NON_BILLABLE);
								}}
								cy={'budget-type-buttons-' + BUDGET_TYPE.NON_BILLABLE}
							/>
						)}
						{!onlyNonBillable && retainerCreatePermission && (
							<Button
								text={'Retainer'}
								buttonStyle={BUTTON_STYLE.FILLED}
								colorTheme={budgetType === BUDGET_TYPE.RETAINER ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.MEDIUMGREY}
								onClick={() => {
									onBudgetTypeClick(BUDGET_TYPE.RETAINER);
								}}
								userpilot={'budget-type-buttons-retainer'}
								cy={'budget-type-buttons-' + BUDGET_TYPE.RETAINER}
								locked={lockFinance}
								isDisabled={lockFinance || !useRetainers}
								tooltipProps={
									lockFinance
										? {infoText: formatMessage({id: 'common.requires_financial_module'}), growable: true}
										: null
								}
							/>
						)}
					</div>
				</ButtonsStyled>
				<SpecificValuesStyled>
					<div className="budget-type-specific-values">
						<div
							className={
								'price-section ' +
								(isFinancialOnLiteTimeAndMaterial
									? `${budgetType.toLowerCase()}_lite`
									: budgetType.toLowerCase()) +
								(project ? ' edit-mode' : '')
							}
						>
							{isFixedPriceType(budgetType) && !isInFixedPriceProgram ? (
								<div className={'fixed-price'}>
									<InputFieldV2
										id={'fixed-price-value'}
										autoFocus={true}
										type="number"
										value={fixedPriceBudget}
										label={formatMessage({id: 'common.price'})}
										onChange={handleFixedPriceBudget}
										onBlur={validateFixedPriceBudget}
										invalidInput={invalidFixedPriceFormat || fixedPriceCappedBudgetOverflow}
										required={true}
										errorMessage={errorMessage}
										placeholder="0"
										currency={rateCardSelected ? rateCardSelected.currency : currency}
										cy={'fixed-price-input'}
										locked={cannotChangeToFixedPriceBudget}
									/>
								</div>
							) : isFixedPriceType(budgetType) && isInFixedPriceProgram ? (
								<div style={{width: '100%'}}>
									<FlexColumn gap={'s'}>
										<Text>
											{
												<FormattedHTMLMessage
													id={formatMessage(
														{id: 'project_settings.change_program_project_budget_type_message'},
														{programName: programName}
													)}
												/>
											}
										</Text>
										<Link onClick={handleEditProgramBudgetClick}>
											{formatMessage({id: 'common.edit_program_budget'})}
										</Link>{' '}
									</FlexColumn>
								</div>
							) : null}
							{!project && budgetType !== BUDGET_TYPE.NON_BILLABLE && CompanySetupUtil.hasFinance() ? (
								<div className={'rate-card'}>
									<DropdownV2
										customHeight={30}
										options={rate_card_options}
										onChange={handleSelectRateChange}
										value={rateCardSelected ? rateCardSelected.value : null}
										multi={false}
										label={formatMessage({id: 'empty_state_project_rates.select-placeholder'})}
										clearable={true}
										inputName={'role-input'}
										inputCy={'rate-cards-input-select'}
										listDataCy={'rate-card'}
									/>
								</div>
							) : null}
							{budgetType === BUDGET_TYPE.RETAINER ? (
								<div className={'retainer-section'} data-userpilot={'retainer-settings-section'}>
									{project && project.retainerPeriods.edges.length > 0 ? (
										<div className="existing_periods_warning">
											{formatMessage({id: 'retainer.settings.change_budget_existing_periods'})}
										</div>
									) : null}
									<div className={'retainer-length-and-value'}>
										<div className={'retainer-container billing-period'}>
											<div className={'dropdown-custom'}>
												<DropdownV2
													customHeight={30}
													key={'billing-period'}
													options={options_billing_period}
													onChange={onPeriodicityChange}
													clearable={false}
													value={defaultPeriodPeriodicity}
													label={formatMessage({id: 'retainer.settings.billing-period'})}
													inputCy={'billing-period-select'}
													listDataCy={'billing-period'}
												/>
											</div>
											<InputFieldV2
												id={'billing-period-amount'}
												type="number"
												onChange={onPeriodLengthChange}
												value={defaultPeriodLength}
												label={formatMessage({id: 'common.every'})}
												required={true}
												invalidInput={wrongPeriodLength}
												errorMessage={wrongPeriodLength ? errorMessage : null}
												placeholder="0"
												step={1}
												suffix={
													defaultPeriodPeriodicity === PERIODICITY.DAILY
														? formatMessage({id: 'common.days'}).toLowerCase()
														: defaultPeriodPeriodicity === PERIODICITY.WEEKLY
														? formatMessage({id: 'common.weeks'}).toLowerCase()
														: formatMessage({id: 'common.months'}).toLowerCase()
												}
												cy={'billing-period-amount-input'}
											/>
										</div>
										<div className={'retainer-container budget-type-period'}>
											<div className={'dropdown-custom'}>
												<label className="label">
													<span>{formatMessage({id: 'retainer.settings.set-period-type'})}</span>
												</label>
												<Dropdown
													selectedItems={[defaultPeriodBudgetType]}
													placeholder={defaultPeriodBudgetType}
													onSelect={onPeriodBudgetTypeChange}
													cy={'period-budget-type-select'}
													name={formatMessage({id: 'retainer.settings.set-period-type'})}
												>
													{options_period_budget_type.map(option => (
														<Dropdown.SingleText
															key={option.value}
															value={option.value}
															searchString={option.label}
															cy={'period-budget-type'}
															disabled={option.disabled}
														>
															{option.label}
														</Dropdown.SingleText>
													))}
												</Dropdown>
											</div>
										</div>
										{defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS ? (
											<div className={'input-container-v2'}>
												<HoursInput
													value={defaultPeriodHoursAmount}
													mutation={onDefaultPeriodHoursChange}
													placeholder={formatMessage({id: 'hours_input.placeholder'})}
													cy={'billing-period-amount-hours-input'}
													label={formatMessage({id: 'retainer.target'})}
													invalidInput={wrongPeriodAmount}
												/>
											</div>
										) : defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS ? (
											<div className={'input-container-v2'}>
												<HoursInput
													value={defaultPeriodHoursAmount}
													mutation={onDefaultPeriodHoursChange}
													placeholder={formatMessage({id: 'hours_input.placeholder'})}
													cy={'billing-period-amount-hours-input'}
													required={true}
													label={formatMessage({id: 'common.hours'})}
													extension={' *'}
													invalidInput={wrongPeriodAmount}
												/>
											</div>
										) : defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE ? (
											<div className={'input-container-v2'}>
												<InputLabel
													text={formatMessage({id: 'common.price'})}
													required
													child={
														<CurrencyInput
															id={'period-price'}
															value={defaultPeriodPriceAmount}
															callback={onDefaultPeriodPriceChange}
															placeholder={'0'}
															currencySymbole={
																rateCardSelected ? rateCardSelected.currency : currency
															}
															blurOnEnter={true}
															sendValueOnBlur={true}
															cy={'billing-period-amount-price-input'}
															hasError={wrongPeriodAmount}
														/>
													}
												/>
											</div>
										) : null}
									</div>
								</div>
							) : null}
						</div>
						{isFixedPriceType(budgetType) &&
							isInCappedRevenueProgram &&
							(fixedPriceCappedBudgetOverflow ||
								fixedPriceLockedRevenueCap ||
								cannotChangeToFixedPriceBudget) && (
								<div style={{width: '300px'}}>
									<FlexColumn>
										<Text color={'destructive'}>
											{
												<FormattedHTMLMessage
													id={formatMessage({
														id: cannotChangeToFixedPriceBudget
															? 'project_settings.capped_revenue_locked_cannot_change_budget_type.short'
															: fixedPriceLockedRevenueCap
															? 'project_settings.capped_revenue_locked_program_revenue_cap.short'
															: 'project_settings.capped_revenue_program_cap_exceeded.short',
													})}
												/>
											}
										</Text>
									</FlexColumn>
								</div>
							)}
					</div>
				</SpecificValuesStyled>
			</div>
		</ComponentContainer>
	);
};
export default injectIntl(ProjectBudgetTypeControls);
