import React, {Component} from 'react';
import {DropTarget} from 'react-dnd';
import {FormattedMessage, injectIntl} from 'react-intl';
import {BUTTON_COLOR, BUTTON_STYLE, ItemTypes} from '../../constants';
import Util from '../../forecast-app/shared/util/util';
import CreateFolderMutation from '../../mutations/create_folder_mutation';
import UpdateGoogleDriveFileMutation from '../../mutations/update_google_drive_file_mutation';
import UpdateFolderMutation from '../../mutations/update_folder_mutation';
import UpdateFileMutation from '../../mutations/update_file_mutation.modern';
import DeleteFolderMutation from '../../mutations/delete_folder_mutation';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {createToast} from '../../forecast-app/shared/components/toasts/another-toast/toaster';
import GooglePicker from '../../forecast-app/shared/components/google-picker/google_picker';
import Warning from '../warning';

const folderTarget = {
	drop(props, monitor) {
		if (
			(monitor.getItem().parentFolder === null && props.folder.value === null) ||
			(monitor.getItem().parentFolder !== null &&
				props.folder.value !== null &&
				monitor.getItem().parentFolder.id === props.folder.value)
		)
			return;
		let source_name = '';
		const isDragginMultipleFiles = monitor.getItem().files && monitor.getItem().files.length > 1;
		const parentFolder = monitor.getItem().parentFolder ? monitor.getItem().parentFolder : null;
		const onSuccess = () => {
			let message;
			if (parentFolder === null && props.folder.value !== null) {
				// moved from top level to folder
				if (isDragginMultipleFiles) {
					message = props.intl.formatMessage(
						{id: 'project_files.drop_toast_folder_message_multiple'},
						{
							source: source_name,
							new: props.folder.label,
						}
					);
				} else {
					message = props.intl.formatMessage(
						{id: 'project_files.drop_toast_folder_message'},
						{
							source: source_name,
							new: props.folder.label,
						}
					);
				}
			} else if (parentFolder !== null && props.folder.value === null) {
				// moved from folder to top level
				if (isDragginMultipleFiles) {
					message = props.intl.formatMessage(
						{id: 'project_files.drop_toast_top_level_message_multiple'},
						{source: source_name}
					);
				} else {
					message = props.intl.formatMessage(
						{id: 'project_files.drop_toast_top_level_message'},
						{source: source_name}
					);
				}
			} else if (parentFolder !== null && props.folder.value !== null) {
				// moved from folder to another folder
				if (isDragginMultipleFiles) {
					message = props.intl.formatMessage(
						{id: 'project_files.drop_toast_message_multiple'},
						{
							source: source_name,
							initial: parentFolder.name,
							new: props.folder.label,
						}
					);
				} else {
					message = props.intl.formatMessage(
						{id: 'project_files.drop_toast_message'},
						{
							source: source_name,
							initial: parentFolder.name,
							new: props.folder.label,
						}
					);
				}
			}
			props.removeAllFromBulkUpdate();
			createToast({
				duration: 5000,
				message: message,
			});
		};

		if (monitor.getItem().isGoogleDriveFile) {
			const file = monitor.getItem().file;
			source_name = props.intl.formatMessage({id: 'common.file'});
			Util.CommitMutation(
				UpdateGoogleDriveFileMutation,
				{
					id: file.node.id,
					folderId: props.folder.value,
					projectId: props.projectId,
					taskId: file.node.taskId,
					personId: file.node.person.id,
					name: file.node.name,
					link: file.node.link,
					thumb: file.node.thumb,
					createdAt: file.node.createdAt,
				},
				onSuccess
			);
		} else if (monitor.getItem().folder && (monitor.getItem().files == null || monitor.getItem().files.length < 1)) {
			//folder dropped on folder
			if (monitor.getItem().folder.node.id !== props.folder.value) {
				const draggedFolder = monitor.getItem().folder;
				source_name = props.intl.formatMessage({id: 'common.folder'});
				Util.CommitMutation(
					UpdateFolderMutation,
					{
						id: draggedFolder.node.id,
						folderId: props.folder.value,
					},
					onSuccess
				);
			}
		} else {
			// Update the folders and files separately
			const allItems = monitor.getItem().files.map(file => {
				return props.elements.find(element => element.object.node.id === file);
			});
			const files = allItems.filter(item => !item.isFolder);
			const folders = allItems.filter(item => item.isFolder);
			const fileIds = files.map(file => file.object.node.id);
			const folderIds = folders.map(folder => folder.object.node.id);
			if (fileIds.length > 0) {
				Util.CommitMutation(
					UpdateFileMutation,
					{
						ids: fileIds,
						folderId: props.folder.value,
					},
					onSuccess
				);
			}
			for (const folderId of folderIds) {
				Util.CommitMutation(
					UpdateFolderMutation,
					{
						id: folderId,
						folderId: props.folder.value,
					},
					onSuccess
				);
			}
		}
	},
};

function collectTarget(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
	};
}

class PathLinkItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showActionDropdown: false,
			focusAtIndex: 0,
		};
	}

	handleKeyDown(option, e) {
		if (e.keyCode === 27) {
			//escape
			this.setState({focusAtIndex: 0, showActionDropdown: false});
		} else if (e.keyCode === 32 || e.keyCode === 13) {
			if (this.state.showActionDropdown) {
				//enter or space
				if (this.state.focusAtIndex === 0) {
					this.handleRename();
				} else if (this.state.focusAtIndex === 2) {
					this.handleAddFolder();
				} else if (this.state.focusAtIndex === 3) {
					this.handleDelete();
				}
			}
		} else if (e.keyCode === 38) {
			//arrow up
			if (this.state.focusAtIndex !== 0) {
				this.setState({focusAtIndex: this.state.focusAtIndex - 1});
			}
		} else if (e.keyCode === 40) {
			//arrow down
			//delete is last option, if order of options is changed this also needs to be updated to represent last option name
			if (option !== 'delete') {
				this.setState({focusAtIndex: this.state.focusAtIndex + 1});
			}
		}
	}

	handleClick() {
		if (this.props.showActionDropdown) {
			this.setState({showActionDropdown: !this.state.showActionDropdown, focusAtIndex: 0});
		} else {
			this.props.moveToFolder(this.props.folder);
		}
	}

	handleRename(e) {
		this.setState({showActionDropdown: false});
		if (e) {
			e.preventDefault();
		}
		const callbackPositive = value => {
			const onSuccess = () => {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'project_files.folder_renamed'}),
				});
			};
			Util.CommitMutation(
				UpdateFolderMutation,
				{
					id: this.props.folder.value,
					name: value,
				},
				onSuccess
			);
		};
		showModal({
			type: MODAL_TYPE.USER_INPUT,
			initialValue: this.props.folder.label,
			handleConfirm: callbackPositive,
			titleText: <FormattedMessage id="project_files.edit_folder" />,
			confirmText: <FormattedMessage id="common.save" />,
			placeholder: this.props.intl.formatMessage({id: 'project_files.enter-folder-name'}),
			label: this.props.intl.formatMessage({id: 'project_files.folder_name'}),
			focusOnMount: true,
		});
	}

	handleAddFile(files, e) {
		this.setState({showActionDropdown: false});
		if (e) {
			e.preventDefault();
		}
		this.props.uploadFiles(this._file.files.item(0));
	}

	uploadFilesGoogleDrive(data) {
		this.props.uploadFilesGoogleDrive(data);
	}

	handleAddFolder(e) {
		this.setState({showActionDropdown: false});
		if (e) {
			e.preventDefault();
		}
		const callbackPositive = value => {
			const onSuccess = () => {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'project_files.folder_added'}),
				});
			};
			Util.CommitMutation(
				CreateFolderMutation,
				{
					name: value,
					folderId: this.props.folder.value,
					projectId: this.props.projectId,
				},
				onSuccess
			);
		};
		showModal({
			type: MODAL_TYPE.USER_INPUT,
			initialValue: '',
			handleConfirm: callbackPositive,
			titleText: <FormattedMessage id="project_files.add_folder" />,
			confirmText: <FormattedMessage id="common.add" />,
			placeholder: this.props.intl.formatMessage({id: 'project_files.enter-folder-name'}),
			label: this.props.intl.formatMessage({id: 'project_files.folder_name'}),
			focusOnMount: true,
		});
	}

	handleDelete(e) {
		const {formatMessage} = this.props.intl;
		this.setState({showActionDropdown: false});
		if (e) {
			e.preventDefault();
		}
		const callbackPositive = () => {
			const onSuccess = () => {
				createToast({
					duration: 5000,
					message: this.props.intl.formatMessage({id: 'project_files.folder_deleted'}),
				});
			};
			Util.CommitMutation(
				DeleteFolderMutation,
				{
					id: this.props.folder.value,
					projectId: this.props.projectId,
				},
				onSuccess
			);
		};
		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div className="default-warning-modal">
					<Warning messageId="common.delete-confirmation-title" />
					<div className="warning-part-2">{formatMessage({id: 'common.warning.this_action_can_not_be_undone'})}</div>
				</div>
			),
			buttons: [
				{
					text: formatMessage({id: 'common.cancel'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
				{
					text: formatMessage({id: 'common.delete'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.RED,
					callback: callbackPositive,
				},
			],
		});
	}

	render() {
		const {connectDropTarget, isOver} = this.props;
		return connectDropTarget(
			<div className={'level-button-wrapper' + (this.props.showActionDropdown ? ' active' : '')}>
				{this.props.folder.value === null ? null : <div className="separator" />}
				<button
					className={'title' + (isOver || this.state.showActionDropdown ? ' is-over' : '')}
					onClick={this.handleClick.bind(this)}
					onKeyDown={this.handleKeyDown.bind(this, null)}
				>
					{this.props.folder.label} {this.props.showActionDropdown ? <div className="action-arrow" /> : null}
				</button>
				{this.state.showActionDropdown ? (
					<div className="action-dropdown">
						<div
							className="option has-border files-path-link add-padding"
							onMouseDown={this.handleRename.bind(this)}
						>
							<FormattedMessage id="common.rename" />
						</div>
						<div className="option has-border files-path-link">
							<input
								name="add-file"
								id="add-file"
								className="input-file"
								type="file"
								onClick={e => {
									e.target.value = null;
								}}
								onChange={this.handleAddFile.bind(this)}
								ref={input => (this._file = input)}
								disabled={false}
							/>
							<label htmlFor="add-file" className="input-file-label">
								<FormattedMessage id="cards.add-file" />
							</label>
						</div>
						{this.props.gdriveEnabled ? (
							<div className="google-input option">
								<GooglePicker onChange={this.uploadFilesGoogleDrive.bind(this)} multiselect={true}>
									<div
										className="option add-padding has-border"
										onClick={() => {
											this.setState({showActionDropdown: false});
										}}
									>
										<FormattedMessage id="project_files.google_drive_add_file" />
									</div>
								</GooglePicker>
							</div>
						) : null}
						<div
							className="option has-border files-path-link add-padding"
							onMouseDown={this.handleAddFolder.bind(this)}
						>
							<FormattedMessage id="project_files.add_folder" />
						</div>
						<div className="option files-path-link add-padding" onMouseDown={this.handleDelete.bind(this)}>
							<FormattedMessage id="common.delete" />
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

export default injectIntl(DropTarget(ItemTypes.PROJECT_FILE, folderTarget, collectTarget)(PathLinkItem));
