import React, {Component} from 'react';
import PropTypes from 'prop-types';

class RadioButton extends Component {
	keyPressHandler(e) {
		e.preventDefault();
		if (e.key === 'Enter' || e.key === ' ') this.props.onSelected(this.props.value);
	}

	render() {
		return (
			<div className={'radio-button-wrapper' + (this.props.locked ? ' disabled' : '')}>
				<div
					className={'radio-button ' + (this.props.locked ? 'radio-button-disabled' : '')}
					tabIndex={0}
					onKeyPress={e => this.keyPressHandler(e)}
					onClick={() => (this.props.useOnClick ? this.props.onSelected(this.props.value) : null)}
				>
					<div
						className={
							'radio-button-container ' +
							(this.props.value === this.props.stateValue ? 'radio-button-checked' : '')
						}
					/>
					<input
						type="radio"
						id={this.props.id}
						disabled={this.props.locked}
						checked={this.props.value === this.props.stateValue}
						className={'radio-button-input'}
						onChange={() => (this.props.useOnClick ? null : this.props.onSelected(this.props.value))}
						tabIndex={-1}
						data-cy={this.props.cy}
					/>
				</div>
				{this.props.text ? <div className="text">{this.props.text}</div> : null}
			</div>
		);
	}
}

RadioButton.propTypes = {
	locked: PropTypes.bool,
	onSelected: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	stateValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	id: PropTypes.string,
	text: PropTypes.string,
	useOnClick: PropTypes.bool,
};

export default RadioButton;
