import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {getAllocationTotal} from '../../scheduling/project_allocation_logic';
import {MONTH_NAMES_SHORT} from '../canvas-timeline/canvas_timeline_util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../constants';
import AllocationDetailUtil from './allocation_detail_util';
import ProgramUtil from '../../../forecast-app/shared/util/ProgramUtil';
import {getProjectOrProjectGroupIndicatorString} from '../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';

class AllocationDetailBox extends Component {
	getAllocationType(allocation) {
		const {formatMessage} = this.props.intl;

		if (allocation.holidayCalendarId) {
			return formatMessage({id: 'common.holiday'});
		}

		if (allocation.idleTimeId) {
			return formatMessage({id: allocation.isIdleTimeInternal ? 'common.internal_time' : 'common.time_off'});
		}

		if (allocation.projectId || allocation.projectGroupId) {
			const project = this.props.globalData.projects.find(project =>
				allocation.projectGroupId
					? project.projectGroupId === allocation.projectGroupId
					: project.id === allocation.projectId
			);
			if (project && !project.useManualAllocations) {
				return formatMessage({id: 'scheduling.task_allocation'});
			}

			if (allocation.isSoft) {
				return (
					formatMessage({id: 'allocation.soft_allocation'}) + ' /' + Math.round(project.baselineWinChance * 100) + '%'
				);
			}
			return formatMessage({id: 'scheduling.project_allocation'});
		}
		if (allocation.parent) {
			return formatMessage({id: 'scheduling.task_allocation'});
		}
		return '';
	}

	getProject(allocation) {
		const {globalData} = this.props;
		return allocation.projectId && globalData.projectMap.get(allocation.projectId);
	}

	getProgram(project) {
		if (project?.programId) {
			const {globalData} = this.props;
			return globalData.programMap.get(project.programId);
		} else {
			return null;
		}
	}

	getClientName(project) {
		const {globalData} = this.props;
		return project
			? project.clientId
				? globalData.clients.find(client => client.id === project.clientId).name
				: null
			: null;
	}

	render() {
		const {allocation, globalData, positionX, positionY, schedulingOptions, pageComponent} = this.props;
		const {formatMessage} = this.props.intl;
		let totalEstimate = this.props.taskAllocationTotalEstimate;
		let totalEstimateWin;
		const showRight = positionX + 200 < window.innerWidth;
		const showTop = positionY + 350 < window.innerHeight;
		const project = this.getProject(allocation);
		const clientName = this.getClientName(project);
		const program = this.getProgram(project);
		const hasBetaChanges = hasFeatureFlag('capacity_planning_beta_2_improvements');
		const calcWin = schedulingOptions.calcWin;
		const useWinChance = allocation.isSoft && project?.baselineWinChance && project.baselineWinChance < 1;
		const allocationLabel = formatMessage({id: 'common.allocation'});
		const allocationLabelExtension =
			hasBetaChanges && useWinChance && calcWin ? formatMessage({id: 'scheduling.calc_win_probability'}) : null;

		if (
			(totalEstimate === undefined || totalEstimate === null) &&
			allocation.wednesday !== null &&
			allocation.wednesday !== undefined
		) {
			const total = getAllocationTotal(allocation);
			const winChance = useWinChance ? project.baselineWinChance : 1;

			totalEstimate = hasBetaChanges
				? AllocationDetailUtil.getAllocationTimeTextWithWinChance(
						total,
						formatMessage,
						winChance,
						allocation,
						globalData.company,
						calcWin
				  )
				: AllocationDetailUtil.getAllocationTimeTextWithWinChanceOld(
						total,
						formatMessage,
						winChance,
						allocation,
						globalData.company
				  );

			totalEstimateWin = AllocationDetailUtil.getAllocationTimeTextWithWinChance(
				total,
				formatMessage,
				winChance,
				allocation,
				globalData.company,
				false
			);
		}

		return (
			<>
				<div className={'white-triangle ' + (showRight ? 'right ' : 'left ') + (showTop ? 'top' : 'bottom')} />
				<div className="detail-box-inner-container detail-box-allocation-container">
					<div className="detail-box-allocation-item detail-box-inner-headline">
						{formatMessage({id: 'common.allocation'})}
					</div>
					<div className="detail-box-allocation-item detail-box-allocation-name">
						<div className="label">{formatMessage({id: 'common.project-name'})}</div>
						<div className="content">
							{allocation.holidayCalendarId
								? allocation.name
								: allocation.parent
								? `${getProjectOrProjectGroupIndicatorString({
										companyProjectId: allocation.parent.companyProjectId,
										customProjectId: allocation.parent.customProjectId,
										companyProjectGroupId: allocation.parent.companyProjectGroupId,
								  })} ${allocation.parent.name}`
								: AllocationDetailUtil.getParentTextFromAllocation(pageComponent, allocation)}
						</div>
					</div>
					{program ? (
						<div className="detail-box-allocation-item">
							<div className="label">
								{formatMessage(
									{id: 'scheduling.part_of_program'},
									{program: ProgramUtil.programText(formatMessage)}
								)}
							</div>
							<div className="content">
								{program.prefix} {program.name}
							</div>
						</div>
					) : null}
					{clientName ? (
						<div className="detail-box-allocation-item">
							<div className="label">{formatMessage({id: 'common.client'})}</div>
							<div className="content">{clientName}</div>
						</div>
					) : null}
					<div className="detail-box-allocation-item detail-box-allocation-dates">
						<div className="label">{formatMessage({id: 'common.date'})}</div>
						<div className={'content'}>{`${allocation.startDay} ${formatMessage({
							id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[allocation.startMonth - 1],
						})} ${allocation.startYear} - ${allocation.endDay} ${formatMessage({
							id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[allocation.endMonth - 1],
						})} ${allocation.endYear}`}</div>
					</div>
					{hasBetaChanges && (
						<div className="detail-box-allocation-item detail-box-allocation-alloc-type">
							<div className="label">{formatMessage({id: 'common.allocation_type'})}</div>
							<div className="content">{AllocationDetailUtil.getAllocationType(allocation, formatMessage)}</div>
						</div>
					)}
					{hasBetaChanges && calcWin && project && hasModule(MODULE_TYPES.CALC_WIN_PERCENTAGE) && (
						<div className="detail-box-allocation-item detail-box-allocation-win-probability">
							<div className="label">{formatMessage({id: 'project.win_chance'})}</div>
							<div className="content">{AllocationDetailUtil.getWinProbability(project)}</div>
						</div>
					)}
					{totalEstimate !== null && totalEstimate !== undefined ? (
						<div className="detail-box-allocation-item detail-box-allocation-total">
							<div className="label">{allocationLabel}</div>
							{allocationLabelExtension && <div className="label">{allocationLabelExtension}</div>}
							<div className="content">{hasBetaChanges ? <b>{totalEstimate}</b> : <>{totalEstimate}</>}</div>
							{allocationLabelExtension && (
								<div className="content">
									{formatMessage({id: 'scheduling.minutes_if_win'}, {minutes: totalEstimateWin})}
								</div>
							)}
						</div>
					) : null}
					{!hasBetaChanges && (
						<div className="detail-box-allocation-item detail-box-allocation-type">
							<div className="label">{formatMessage({id: 'common.type'})}</div>
							<div className="content">{this.getAllocationType(allocation)}</div>
						</div>
					)}
				</div>
			</>
		);
	}
}

AllocationDetailBox.propTypes = {
	allocation: PropTypes.object.isRequired,
	globalData: PropTypes.object.isRequired,
	pageComponent: PropTypes.object.isRequired,
	schedulingOptions: PropTypes.object.isRequired,
};

export default injectIntl(AllocationDetailBox);
