import DirectApi from '../../directApi';
import {LOAD_MORE, LOAD_MORE_DIRECTION} from './loading/IncrementalLoadingOverlay';
import DataManager from './DataManager';
import {DATE_FORMAT_DAY} from '../../constants';
import {
	getCanvasTimelineDateFromMoment,
	getMomentFromCanvasTimelineDate,
	useItemsLazyLoading,
} from './canvas-timeline/canvas_timeline_util';
import {SCALE_SETTINGS} from './canvas-timeline/canvas_timeline';
import {
	MIN_LOAD_MORE_MONTHS,
	MAX_LOAD_MORE_MONTHS,
	MONTHS_TO_LOAD_AS_BUFFER,
	setIsLoadingMoreData,
} from './loading/LoadMoreUtil';

let abortController = null;

export const fetchDataPromises = requestObjects => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	if (window.AbortController) {
		//Not supported by IE
		abortController = new window.AbortController();
	}
	const signal = abortController ? abortController.signal : undefined;
	const promises = [];

	for (const requestObject of requestObjects) {
		const init = {
			headers,
			signal,
			credentials: 'include',
			method: requestObject.method,
			body: requestObject.body ? JSON.stringify(requestObject.body) : undefined,
		};
		promises.push(
			fetch(requestObject.url, init)
				.then(response => {
					if (response.ok) {
						return response.json();
					} else {
						return {error: true};
					}
				})
				.catch(error => {
					//Most likely aborted in unmount
					if (!('' + error).includes('abort')) {
						throw error;
					}
				})
		);
	}

	return Promise.all(promises);
};

export const fetchInit = shareUrl => {
	const headers = new Headers();
	if (shareUrl) {
		headers.append('X-FORECAST-PROJECT-SCHEDULING-SHARE', shareUrl);
	}
	const init = {headers, method: 'GET'};
	if (!shareUrl) {
		init.credentials = 'include';
	}
	return DirectApi.Fetch_WithErrorHandling('scheduling/init', init).then(response => response.json());
};

const calculateMonthsToFetch = (pageComponent, stepsToFetch) => {
	const {timeline} = pageComponent;
	const {minorStep} = timeline;

	const isViewingInMonths = minorStep === SCALE_SETTINGS.MONTH.minorStep;
	const isViewingInQuarters = minorStep === SCALE_SETTINGS.QUARTER.minorStep;

	if (isViewingInMonths || isViewingInQuarters) {
		const quarterFactor = isViewingInQuarters ? 3 : 1;
		const monthsToFetch = stepsToFetch * quarterFactor + MONTHS_TO_LOAD_AS_BUFFER;
		return Math.min(MAX_LOAD_MORE_MONTHS, Math.max(MIN_LOAD_MORE_MONTHS, monthsToFetch));
	}

	return MIN_LOAD_MORE_MONTHS;
};

const getLoadMoreInit = (pageComponent, fetchMoreDate, direction, monthsToFetch) => {
	const {isProjectTimeline, schedulingView} = pageComponent.props;
	const {staffingModeActive} = pageComponent.state;

	const reqBody = {
		fetchMoreDate,
		direction,
		monthsToFetch,
		schedulingView,
		staffingModeActive,
	};

	if (isProjectTimeline) {
		const {projectId, groupId, programPrefix} = pageComponent.props;

		if (programPrefix && useItemsLazyLoading(pageComponent)) {
			const {data} = pageComponent.state;
			reqBody.programProjectIds = data.projects.map(project => project.rawId);
		}

		reqBody.excludedCompanyProjectId = projectId;
		reqBody.excludedCompanyProjectGroupId = groupId;
		reqBody.excludeIdleTimes = true;
	}

	const headers = new Headers();
	headers.append('Content-Type', 'application/json');

	return {
		headers,
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify(reqBody),
	};
};

const getLoadMoreHistoryCacheKey = (canvasDate, direction) => {
	return `${canvasDate}-${direction}`;
};

const loadMoreDataInDirection = (pageComponent, direction, fromCanvasDate, stepsHidden) => {
	const {loadMoreHistory} = pageComponent.props;
	const historyKey = getLoadMoreHistoryCacheKey(fromCanvasDate, direction);

	if (!loadMoreHistory.has(historyKey)) {
		loadMoreHistory.add(historyKey);

		pageComponent.setState(
			{
				[LOAD_MORE.IS_LOADING + direction]: true,
			},
			() => {
				const fetchMoreMomentDate = getMomentFromCanvasTimelineDate(fromCanvasDate);
				const fetchMoreDate = fetchMoreMomentDate.format(DATE_FORMAT_DAY);

				const monthsToFetch = calculateMonthsToFetch(pageComponent, stepsHidden);
				const fetchMorePastInit = getLoadMoreInit(pageComponent, fetchMoreDate, direction, monthsToFetch);

				DirectApi.Fetch_WithErrorHandling('scheduling/heatmap/fetch_more/', fetchMorePastInit).then(async response => {
					if (!pageComponent.timeline) {
						// While waiting for fetch-more, user navigated away, so don't try to apply the response.
						return;
					}

					const mergeStartDate = getCanvasTimelineDateFromMoment(fetchMoreMomentDate);
					const newLoadMoreDate =
						direction === LOAD_MORE_DIRECTION.LEFT
							? fetchMoreMomentDate.subtract(monthsToFetch, 'months')
							: fetchMoreMomentDate.add(monthsToFetch, 'months');
					const mergeEndDate = getCanvasTimelineDateFromMoment(newLoadMoreDate);

					DataManager.mergeLoadMoreData(pageComponent, await response.json(), mergeStartDate, mergeEndDate);

					setIsLoadingMoreData(pageComponent, direction, false, getCanvasTimelineDateFromMoment(newLoadMoreDate));
				});
			}
		);
	}
};

export const fetchMoreData = (pageComponent, leftCanvasDate, rightCanvasDate, leftStepsHidden, rightStepsHidden) => {
	const isLoadingLeft = pageComponent.state[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.LEFT];
	const isLoadingRight = pageComponent.state[LOAD_MORE.IS_LOADING + LOAD_MORE_DIRECTION.RIGHT];

	const hasLeftStepsHidden = leftStepsHidden > 0;
	const hasRightStepsHidden = rightStepsHidden > 0;

	if (!isLoadingLeft && hasLeftStepsHidden) {
		loadMoreDataInDirection(pageComponent, LOAD_MORE_DIRECTION.LEFT, leftCanvasDate, leftStepsHidden);
	}

	if (!isLoadingRight && hasRightStepsHidden) {
		loadMoreDataInDirection(pageComponent, LOAD_MORE_DIRECTION.RIGHT, rightCanvasDate, rightStepsHidden);
	}
};
