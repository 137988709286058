import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import InsightList from './list';

class ReportedTimePeopleDummy extends Component {
	createDummyElement(date, person, projectName, cardName, role, reported, price, cost, billable, client) {
		const element = {};
		element.date = date;
		element.person = person;
		element.projectName = projectName;
		element.cardName = cardName;
		element.role = role;
		element.reported = reported + ' h';
		element.price = price;
		element.cost = cost;
		element.billable = billable;
		element.client = client;

		element.roleOrIdleTime = role;
		element.projectOrIdleTime = projectName;
		element.cardOrIdleTime = cardName;

		return element;
	}

	render() {
		let elements = [];
		elements.push(
			this.createDummyElement(
				'01/02/2018',
				'Example person 1',
				'Example project 1',
				'Example card 1',
				'Developer',
				6,
				'$400',
				'$350',
				'Yes',
				'Our client'
			)
		);
		elements.push(
			this.createDummyElement(
				'02/03/2018',
				'Example person 2',
				'Example project 2',
				'Example card 2',
				'Designer',
				6,
				'$250',
				'$200',
				'Yes',
				'Our client'
			)
		);
		elements.push(
			this.createDummyElement(
				'04/05/2018',
				'Example person 3',
				'Example project 3',
				'Example card 3',
				'Developer',
				8,
				'$600',
				'$500',
				'No',
				'Our client'
			)
		);

		return (
			<InsightList
				data={elements}
				componentName={this.props.componentName}
				allColumns={this.props.allColumns}
				activeColumns={this.props.activeColumns}
				scrollElement={this.props.scrollElement}
			/>
		);
	}
}

ReportedTimePeopleDummy.propTypes = {
	title: PropTypes.string,
};

export default injectIntl(ReportedTimePeopleDummy);
