import {
	CURSOR,
	getTextColor,
	getTrimmedText,
	GROUP_SECTION_WIDTH,
	GROUP_TYPE,
	GROUP_SECTION_TEXT_GREY_DARK,
	GROUP_SECTION_PADDING_LEFT,
	GROUP_SECTION_MARGIN_LEFT,
	GROUP_SECTION_ACTION_BUTTON_SIZE,
	drawBorder,
	GROUP_SECTION_ACTIONS_MENU_LEFT_SCHEDULE_PEOPLE,
	isProjectPlanning,
	GROUP_SECTION_SPACING_LEVEL_TWO,
	GROUP_SECTION_SPACING_LEVEL_ONE,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	GROUP_SECTION_SPACING_LEVEL_THREE,
	GROUP_SECTION_PROJECT_BADGE_WIDTH,
	GROUP_SECTION_PROJECT_BADGE_HEIGHT,
	GROUP_SECTION_ACTIONS_MENU,
	VISUALIZATION_MODE,
	getVisualizationMode,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {HIDDEN_FEATURES, SCHEDULING_ACTION_MENU_TYPE, SCHEDULING_VIEW} from '../../../../constants';
import {drawConnectedProjectBadge, drawExpandedGroupHighlight, drawProjectBadge} from '../../DrawingUtils';
import EventManager from '../../EventManager';

class ProjectGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PROJECT, data);
	}

	refreshData(updatedProject) {
		this.data.name = updatedProject.name;
		this.data.color = updatedProject.projectColor;
		this.data.clientName = updatedProject.client ? updatedProject.client.name : null;
		this.data.status = updatedProject.status;
	}

	shouldDisplayMouseHover() {
		const {schedulingView, sharedContext, isProjectTimeline, projectId, groupId} = this.pageComponent.props;

		if (sharedContext) {
			return false;
		}

		if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
			const {isInProjectGroup, companyProjectGroupId, isInProgram} = this.data;

			// do not show for single projects on timeline
			if (!(isInProjectGroup || companyProjectGroupId || isInProgram) && isProjectTimeline) {
				return false;
			}

			// do not show for connected projects on timeline when viewing connected project
			if (!isInProjectGroup && isProjectTimeline && !projectId && groupId) {
				return false;
			}
		}

		const viewsWithMouseHover = [SCHEDULING_VIEW.PROJECTS, SCHEDULING_VIEW.PEOPLE];
		return viewsWithMouseHover.includes(schedulingView);
	}

	onMouseEnter(groupData) {
		if (this.shouldDisplayMouseHover()) {
			const {project, projectGroup, person} = this.data;

			this.pageComponent.setState({
				showCollapsedActionMenu: true,
				collapsedActionMenuX: groupData.width - GROUP_SECTION_ACTIONS_MENU - GROUP_SECTION_PADDING_LEFT,
				collapsedActionMenuY: groupData.y + groupData.height / 2 - GROUP_SECTION_ACTIONS_MENU,
				collapsedActionMenuData: {person, project, projectGroup},
				actionMenuType: SCHEDULING_ACTION_MENU_TYPE.PROJECT,
			});
		}
	}

	onMouseLeave() {
		if (this.shouldDisplayMouseHover()) {
			const {showCollapsedActionMenu} = this.pageComponent.state;

			if (showCollapsedActionMenu) {
				this.pageComponent.setState({showCollapsedActionMenu: false});
			}
		}
	}

	onItemCreate(startDate, endDate) {
		EventManager.onItemCreate(this.pageComponent, this, startDate, endDate);
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height, expanded} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);
		const {clientName, name, color, isConnectedProject, isInProjectGroup, isFirstProjectGroup, isInProgram} = data;

		const {schedulingView, isProjectTimeline} = this.pageComponent.props;
		const {schedulingOptions} = this.pageComponent.state;
		const {company} = this.pageComponent.getFilterData();

		const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;
		const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;
		const isCapacityOverviewScheduling = schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;
		const isCombinedMode =
			isPeopleScheduling && getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

		// offset left
		const capacityOverviewOffsetX = isCapacityOverviewScheduling ? GROUP_SECTION_SPACING_LEVEL_TWO : 0;
		const combinationModeOffsetX = isCombinedMode ? GROUP_SECTION_SPACING_LEVEL_ONE : 0;

		const left = x + this.marginX + capacityOverviewOffsetX + combinationModeOffsetX;
		const planning = isProjectPlanning(data.status);
		const center = y + height / 2;
		const expandable = !isProjectTimeline || !isFirstProjectGroup;
		const scheduleProjectsOffsetX =
			isInProjectGroup || isInProgram
				? expandable
					? GROUP_SECTION_SPACING_LEVEL_THREE
					: GROUP_SECTION_SPACING_LEVEL_TWO
				: expandable
				? GROUP_SECTION_SPACING_LEVEL_TWO
				: GROUP_SECTION_SPACING_LEVEL_ONE;

		if (expandable) {
			interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);
		}

		if (isProjectScheduling) {
			const isWithinGrouping = isInProjectGroup || isInProgram;

			if (!isProjectTimeline && (expanded || isWithinGrouping)) {
				drawExpandedGroupHighlight(canvasContext, x, y, height, width);
			}

			const projectGroupBorderOffsetY = isInProjectGroup ? 1 : 0;
			const schedulingTopBorderOffset = !isFirstProjectGroup && !isProjectTimeline ? 1 : 0;

			const topBottomBorderOffset = 0;
			const horizontalBorderWidth = width - topBottomBorderOffset;

			// top border
			drawBorder(
				canvasContext,
				x + topBottomBorderOffset,
				y - projectGroupBorderOffsetY - schedulingTopBorderOffset,
				horizontalBorderWidth,
				false,
				'#E7E7F3'
			);

			// bottom border
			if (!expanded && !isInProjectGroup) {
				drawBorder(canvasContext, x + topBottomBorderOffset, y - 1 + height, horizontalBorderWidth, false, '#E7E7F3');
			}
		}

		// expand arrow icon
		if (expandable && !this.preventExpansion) {
			const levelOffset =
				isProjectScheduling && (isInProjectGroup || isInProgram)
					? expandable
						? GROUP_SECTION_SPACING_LEVEL_TWO
						: GROUP_SECTION_SPACING_LEVEL_ONE
					: expandable
					? GROUP_SECTION_SPACING_LEVEL_ONE
					: GROUP_SECTION_SPACING_LEVEL_ONE;
			canvasContext.drawImage(
				cacheManager.getCommonImage(expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON),
				x + levelOffset - GROUP_SECTION_EXPAND_ICON_WIDTH / 2,
				y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
				GROUP_SECTION_EXPAND_ICON_WIDTH,
				GROUP_SECTION_EXPAND_ICON_HEIGHT
			);
		}

		let fontSize = 13;
		canvasContext.font = `700 ${fontSize}px ` + Util.getFontFamily();

		// size
		const badgeWidth = GROUP_SECTION_PROJECT_BADGE_WIDTH;
		const badgeHeight = GROUP_SECTION_PROJECT_BADGE_HEIGHT;

		// position
		this.badgeX = isProjectScheduling ? x + scheduleProjectsOffsetX : x + left + GROUP_SECTION_MARGIN_LEFT;
		this.badgeY = center - badgeHeight / 2;

		const backgroundColor = planning ? undefined : color;

		// draw badge
		if (isConnectedProject) {
			drawConnectedProjectBadge(canvasContext, this.badgeX, this.badgeY, {
				backgroundColor: color,
			});
		} else {
			drawProjectBadge(canvasContext, this.badgeX, this.badgeY, {
				backgroundColor: color,
			});
		}

		const textColor = backgroundColor ? getTextColor(backgroundColor) : GROUP_SECTION_TEXT_GREY_DARK;
		canvasContext.fillStyle = textColor;

		const maxNameWidth =
			GROUP_SECTION_ACTIONS_MENU_LEFT_SCHEDULE_PEOPLE -
			this.badgeX -
			(badgeWidth + GROUP_SECTION_PADDING_LEFT) -
			GROUP_SECTION_ACTION_BUTTON_SIZE;

		const drawClientName = clientName && !Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT);
		const projectNameAndClientX = this.badgeX + badgeWidth + GROUP_SECTION_PADDING_LEFT;

		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;

		// draw project name
		canvasContext.font = '500 13px ' + Util.getFontFamily();
		const formattedProjectName = getTrimmedText(canvasContext, name, maxNameWidth);

		const projectNameY = drawClientName ? center - 2 : center + 5;
		canvasContext.fillText(formattedProjectName, projectNameAndClientX, projectNameY);

		// draw client name (if present)
		if (drawClientName) {
			// format client name
			canvasContext.font = '400 11px ' + Util.getFontFamily();
			const formattedClientName = getTrimmedText(canvasContext, clientName, maxNameWidth);

			// draw client name
			canvasContext.fillText(formattedClientName, projectNameAndClientX, center + 12);
		}
	}
}

export default ProjectGroup;
