import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import Moment from 'moment';
import DateSpanPicker from '../date_span_picker';
import util from '../../forecast-app/shared/util/util';
import Select from '../inputs/select_input';
import Info from '../info';
import Filter from '../filters/filter';
import DatePicker from '../inputs/date_picker';
import Util from '../../forecast-app/shared/util/util';
import {filterPeopleLabels} from '../../forecast-app/shared/util/LabelUtil';

class ComponentHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			actionsMenuExpanded: false,
			exportMenuExpanded: false,
		};
		this.collapseExportMenu = this.collapseExportMenu.bind(this);
	}

	UNSAFE_componentWillMount() {
		// Select the current sprint for the sprint burndown insight
		if (this.props.selectedSprint === null && this.props.componentName === 'sprintBurndownV2') {
			const today = Moment();
			if (this.props.projectOptions.find(project => project.id === this.props.selectedProjectId)) {
				this.props.projectOptions
					.find(project => project.id === this.props.selectedProjectId)
					.sprints.edges.forEach(sprint => {
						const sprintStartDate = util.CreateNonUtcMomentDate(
							sprint.node.startYear,
							sprint.node.startMonth,
							sprint.node.startDay
						);
						const sprintEndDate = util.CreateNonUtcMomentDate(
							sprint.node.endYear,
							sprint.node.endMonth,
							sprint.node.endDay
						);
						if (today.isSameOrAfter(sprintStartDate, 'day') && today.isSameOrBefore(sprintEndDate, 'day')) {
							this.props.onSelectSprintChange({value: sprint.node.id});
						}
					});
			} else if (this.props.sprintOptions) {
				this.props.sprintOptions.forEach(sprint => {
					const sprintStartDate = util.CreateNonUtcMomentDate(sprint.startYear, sprint.startMonth, sprint.startDay);
					const sprintEndDate = util.CreateNonUtcMomentDate(sprint.endYear, sprint.endMonth, sprint.endDay);
					if (today.isSameOrAfter(sprintStartDate, 'day') && today.isSameOrBefore(sprintEndDate, 'day')) {
						this.props.onSelectSprintChange({value: sprint.id});
					}
				});
			}
		}
		document.removeEventListener('scroll', this.collapseExportMenu, true);
	}

	componentDidMount() {
		document.addEventListener('scroll', this.collapseExportMenu, true);
	}

	collapseExportMenu() {
		if (this.state.exportMenuExpanded) this.setState({exportMenuExpanded: false});
	}

	toggleExportMenu() {
		this.setState({exportMenuExpanded: !this.state.exportMenuExpanded});
	}

	handleDateSpanChange(selected) {
		this.props.handleDateSpanChange(selected);
	}

	handleTasksFlowCritChange(selected) {
		this.props.handleTasksFlowCritChange(selected);
	}

	handleBudgetGroupByCritChange(selected) {
		this.props.handleBudgetGroupByCritChange(selected);
	}

	handleBackendGroupByChange(selected) {
		this.props.handleBackendGroupByChange(selected);
	}

	handleFilterChange(filterType, value) {
		this.props.handleFilterChange(filterType, value);
	}

	handleWeekPickerMoveToPreviousWeek() {
		const newWeekPickerDate = this.props.weekPickerSelectedDate ? this.props.weekPickerSelectedDate.clone() : Moment();
		newWeekPickerDate.add(-7, 'days');
		this.props.handleWeekPickerChange(newWeekPickerDate);
	}
	handleWeekPickerMoveToNextWeek() {
		const newWeekPickerDate = this.props.weekPickerSelectedDate ? this.props.weekPickerSelectedDate.clone() : Moment();
		newWeekPickerDate.add(7, 'days');
		this.props.handleWeekPickerChange(newWeekPickerDate);
	}

	clearFilters() {
		this.props.clearFilters();
	}

	getSprintOptions(includeBacklog) {
		const sprintOptions = [];
		if (this.props.projectOptions.find(project => project.id === this.props.selectedProjectId)) {
			this.props.projectOptions
				.find(project => project.id === this.props.selectedProjectId)
				.sprints.edges.forEach(sprint => {
					const sprintEndDate = util.CreateNonUtcMomentDate(
						sprint.node.endYear,
						sprint.node.endMonth,
						sprint.node.endDay
					);
					if (this.props.onlyDoneSprints) {
						const today = Moment();
						if (today.isAfter(sprintEndDate, 'day')) {
							sprintOptions.push({value: sprint.node.id, label: sprint.node.name, endDate: sprintEndDate});
						}
					} else {
						sprintOptions.push({value: sprint.node.id, label: sprint.node.name, endDate: sprintEndDate});
					}
				});
		} else if (
			this.props.projectGroupId !== null &&
			this.props.projectGroupId !== undefined &&
			this.props.projectGroupOptions.find(group => group.id === this.props.projectGroupId)
		) {
			this.props.projectGroupOptions
				.find(group => group.id === this.props.projectGroupId)
				.projects.edges[0].node.sprints.edges.forEach(sprint => {
					if (sprint.node.isProjectGroupSprint) {
						const sprintEndDate = util.CreateNonUtcMomentDate(
							sprint.node.endYear,
							sprint.node.endMonth,
							sprint.node.endDay
						);
						if (this.props.onlyDoneSprints) {
							const today = Moment();
							if (today.isAfter(sprintEndDate, 'day')) {
								sprintOptions.push({
									value: sprint.node.projectGroupSprintId,
									label: sprint.node.name,
									endDate: sprintEndDate,
								});
							}
						} else {
							sprintOptions.push({
								value: sprint.node.projectGroupSprintId,
								label: sprint.node.name,
								endDate: sprintEndDate,
							});
						}
					}
				});
		} else if (this.props.sprintOptions) {
			//shared insight options
			this.props.sprintOptions.forEach(sprint => {
				const sprintEndDate = util.CreateNonUtcMomentDate(sprint.endYear, sprint.endMonth, sprint.endDay);
				sprintOptions.push({
					value: sprint.isProjectGroupSprint ? sprint.projectGroupSprintId : sprint.id,
					label: sprint.name,
					endDate: sprintEndDate,
				});
			});
		}

		// Sort by end date so most recent sprints show up first.
		sprintOptions.sort((a, b) => {
			if (a.endDate === null && b.endDate === null) {
				return 0;
			} else if (a.endDate === null) {
				return 1;
			} else if (b.endDate === null) {
				return -1;
			} else {
				return b.endDate.diff(a.endDate);
			}
		});

		if (includeBacklog) {
			sprintOptions.unshift({
				value: null,
				label: this.props.intl.formatMessage({id: 'project_sprints.backlog'}),
			});
		}
		return sprintOptions;
	}

	getFilters() {
		// Disgusting, but it is in good company.
		const useDepartments =
			(this.props.shareKey && this.props.departmentOptions && this.props.departmentOptions.length > 0) ||
			(!this.props.shareKey && Util.hasDepartments());
		const useSkills = Util.hasSkills();

		const filters = [];
		if (this.props.componentName === 'reportedTimeProject') {
			filters.push(
				this.getPersonFilter(),
				this.getTeamFilter(),
				this.getRoleFilter(),
				this.getLabelFilter(filterPeopleLabels)
			);
			if (this.props.canViewFinancial) filters.push(this.getBillableFilter());
			if (useDepartments) filters.push(this.getDepartmentFilter());
			if (useSkills) filters.push(this.getSkillFilter());
		} else if (this.props.componentName === 'reportedTimePeople') {
			filters.push(this.getProjectFilter());
			if (this.props.canViewFinancial) filters.push(this.getBillableFilter());
			if (useDepartments) filters.push(this.getDepartmentFilter());
		} else if (this.props.componentName === 'reportedTimeBusiness') {
			filters.push(
				this.getProjectFilter(),
				this.getPersonFilter(),
				this.getTeamFilter(),
				this.getRoleFilter(),
				this.getLabelFilter(filterPeopleLabels)
			);
			if (this.props.canViewFinancial) filters.push(this.getBillableFilter());
			if (useDepartments) filters.push(this.getDepartmentFilter());
			if (useSkills) filters.push(this.getSkillFilter());
		} else if (this.props.componentName === 'reportedTimePortfolio') {
			filters.push(
				this.getProjectFilter(),
				this.getPersonFilter(),
				this.getTeamFilter(),
				this.getRoleFilter(),
				this.getLabelFilter(filterPeopleLabels)
			);
			if (this.props.canViewFinancial) filters.push(this.getBillableFilter());
			if (useDepartments) filters.push(this.getDepartmentFilter());
			if (useSkills) filters.push(this.getSkillFilter());
		} else if (this.props.componentName.startsWith('utilizationMonthly')) {
			filters.push(this.getPersonFilter(), this.getTeamFilter(), this.getRoleFilter(), this.getLabelFilter());
			filters.push(this.getProjectStageFilter(true));
			if (useSkills) filters.push(this.getSkillFilter());
		} else if (
			this.props.componentName.startsWith('utilizationBarChart') ||
			this.props.componentName.startsWith('utilizationList')
		) {
			filters.push(this.getPersonFilter(), this.getTeamFilter(), this.getRoleFilter(), this.getLabelFilter());
			if (useSkills) filters.push(this.getSkillFilter());
			if (this.props.componentName.startsWith('utilizationList')) {
				filters.push(this.getProjectStageFilter(true));
			}
		} else if (this.props.componentName === 'cardList') {
			filters.push(this.getSprintFilter());
			filters.push(this.getApprovedFilter());
		} else if (this.props.componentName === 'cardListPortfolio') {
			filters.push(this.getApprovedFilter());
		}
		if (
			['peopleListProjects', 'peopleListPortfolio', 'timeRegisteredPerPersonPortfolio'].includes(this.props.componentName)
		) {
			filters.push(
				this.getPersonFilter(),
				this.getTeamFilter(),
				this.getRoleFilter(),
				this.getLabelFilter(filterPeopleLabels)
			);
			if (useSkills) filters.push(this.getSkillFilter());
		}

		if (['peopleListProjects', 'peopleListPortfolio'].includes(this.props.componentName) && useDepartments) {
			filters.push(this.getDepartmentFilter());
		}
		return filters;
	}

	getProjectFilter() {
		const projectFilterOptions = this.props.projectOptions.map(project => {
			return {value: project.id, label: project.name};
		});
		return {
			options: projectFilterOptions,
			onChange: this.handleFilterChange.bind(this, 'project'),
			value: this.props.projectFilterValue,
			placeholder: ['reportedTimeBusiness'].includes(this.props.componentName)
				? this.props.intl.formatMessage({id: 'common.filter-by-project'})
				: this.props.intl.formatMessage({id: 'insights.component.list.column.projectOrIdleTime'}),
			label: this.props.intl.formatMessage({id: 'common.project'}),
		};
	}

	getPersonFilter() {
		let personOptions = [...this.props.personOptions];

		try {
			const config = JSON.parse(this.props.config);
			if (config.includeDeactivatedFilterValue !== true) {
				personOptions = personOptions.filter(person => person.active);
			}
		} catch (error) {}

		personOptions = personOptions.map(person => ({value: person.id, label: person.firstName + ' ' + person.lastName}));
		return {
			options: personOptions,
			onChange: this.handleFilterChange.bind(this, 'person'),
			value: this.props.personFilterValue,
			placeholder: this.props.intl.formatMessage({id: 'common.filter-by-persons'}),
			label: this.props.intl.formatMessage({id: 'common.persons'}),
		};
	}

	getDepartmentFilter() {
		return {
			options: this.props.departmentOptions
				? this.props.departmentOptions.map(dep => ({value: dep.id, label: dep.name}))
				: [],
			onChange: this.handleFilterChange.bind(this, 'department'),
			value: this.props.departmentFilterValue,
			placeholder: this.props.intl.formatMessage({id: 'common.filter-by-department'}),
			label: this.props.intl.formatMessage({id: 'common.department'}),
		};
	}

	getRoleFilter() {
		const roleOptions = this.props.roleOptions.map(role => ({value: role.id, label: role.name}));
		return {
			options: roleOptions,
			onChange: this.handleFilterChange.bind(this, 'role'),
			value: this.props.roleFilterValue,
			placeholder: this.props.intl.formatMessage({id: 'common.filter-by-role'}),
			label: this.props.intl.formatMessage({id: 'settings.roles'}),
		};
	}

	getTeamFilter() {
		const teamOptions = this.props.teamOptions.map(team => ({value: team.id, label: team.name}));
		return {
			options: teamOptions,
			onChange: this.handleFilterChange.bind(this, 'team'),
			value: this.props.teamFilterValue,
			placeholder: this.props.intl.formatMessage({id: 'common.filter_by_team'}),
			label: this.props.intl.formatMessage({id: 'common.team'}),
		};
	}

	getYearOptions() {
		const yearOptions = [];
		const currentYear = new Date().getFullYear();
		for (var i = currentYear - 5; i < currentYear + 6; i++) {
			yearOptions.push({value: i, label: i.toString()});
		}
		return yearOptions;
	}
	getSkillFilter() {
		const skillFilterOptions = this.props.skillsOptions.map(skill => ({value: skill.id, label: skill.name}));
		return {
			options: skillFilterOptions,
			onChange: this.handleFilterChange.bind(this, 'skill'),
			value: this.props.skillFilterValue,
			placeholder: this.props.intl.formatMessage({id: 'common.filter-by-skill'}),
			label: this.props.intl.formatMessage({id: 'common.skill'}),
		};
	}
	getLabelFilter(filter = () => true) {
		const labelFilterOptions = this.props.labelsOptions.filter(filter).map(label => ({value: label.id, label: label.name}));
		return {
			options: labelFilterOptions,
			onChange: this.handleFilterChange.bind(this, 'label'),
			value: this.props.labelFilterValue,
			placeholder: this.props.intl.formatMessage({id: 'common.filter-by-label'}),
			label: this.props.intl.formatMessage({id: 'common.label'}),
		};
	}
	getProjectStageFilter(isBackendFilter) {
		const {formatMessage} = this.props.intl;
		const projectStatusOptions = [
			{value: 'PLANNING', label: formatMessage({id: 'project_status.planning'})},
			{value: 'RUNNING', label: formatMessage({id: 'project_status.running'})},
			{value: 'HALTED', label: formatMessage({id: 'project_status.halted'})},
			{value: 'DONE', label: formatMessage({id: 'project_status.done'})},
			{value: 'NON-PROJECT-TIME', label: formatMessage({id: 'common.non-project-time'})},
		];
		return {
			options: projectStatusOptions,
			onChange: this.handleFilterChange.bind(this, isBackendFilter ? 'project_status_backend' : 'project_status'),
			value: isBackendFilter ? this.props.project_status_backendFilterValue : this.props.project_statusFilterValue,
			placeholder: `${formatMessage({id: 'common.filter_by_project_stage'})} / ${formatMessage({
				id: 'common.non-project-time',
			})}`,
			label: `${formatMessage({id: 'settings.project-stage'})} / ${formatMessage({id: 'common.non-project-time'})}`,
		};
	}

	getSprintFilter() {
		const sprintOptions = this.getSprintOptions(true);
		return {
			options: sprintOptions,
			onChange: this.handleFilterChange.bind(this, 'sprint'),
			value: this.props.sprintFilterValue,
			placeholder: this.props.intl.formatMessage({id: 'common.filter-by-sprint'}),
			label: this.props.intl.formatMessage({id: 'sprints.title'}),
		};
	}

	getBillableFilter() {
		const billableOptions = [
			{value: false, label: this.props.intl.formatMessage({id: 'common.non-billable'})},
			{value: true, label: this.props.intl.formatMessage({id: 'common.billable'})},
		];
		return {
			options: billableOptions,
			onChange: this.handleFilterChange.bind(this, 'billable'),
			value: this.props.billableFilterValue,
			placeholder: this.props.intl.formatMessage({id: 'common.filter-by-billable'}),
			label: this.props.intl.formatMessage({id: 'common.billable'}),
		};
	}

	getApprovedFilter() {
		const {formatMessage} = this.props.intl;
		const approvedOptions = [
			{value: false, label: formatMessage({id: 'common.unapproved'})},
			{value: true, label: formatMessage({id: 'common.approved'})},
		];
		return {
			options: approvedOptions,
			onChange: this.handleFilterChange.bind(this, 'approved'),
			value: this.props.approvedFilterValue,
			placeholder: formatMessage({id: 'common.filter_by_approved'}),
			label: formatMessage({id: 'common.approved'}),
		};
	}

	render() {
		const {formatMessage} = this.props.intl;
		const language = 'en';
		const filters = this.getFilters();
		const isAnyFilterUsed =
			this.props.project_statusFilterValue.length || // is actually stage
			this.props.project_status_backendFilterValue.length ||
			this.props.labelFilterValue.length ||
			this.props.clientFilterValue.length ||
			this.props.projectFilterValue.length ||
			this.props.roleFilterValue.length ||
			this.props.teamFilterValue.length ||
			this.props.sprintFilterValue.length ||
			this.props.personFilterValue.length ||
			this.props.departmentFilterValue.length ||
			this.props.billableFilterValue.length ||
			this.props.approvedFilterValue.length ||
			this.props.rateCardFilterValue.length ||
			this.props.contactPersonFilterValue ||
			this.props.projectStatusFilterValue; // Current status
		const sprintOptions = this.getSprintOptions(false);
		const isTimeLineComponent = this.props.componentName === 'projectTimeline';

		// week picker start, end dates
		let weekPickerSelectedDate, weekPickerStartDate, weekPickerEndDate;
		if (this.props.canUseWeekPicker) {
			if (this.props.weekPickerSelectedDate) {
				weekPickerSelectedDate = this.props.weekPickerSelectedDate;
			} else {
				weekPickerSelectedDate = Moment();
				weekPickerSelectedDate.locale(util.GetLocaleFromPersonLanguage(language));
			}
			weekPickerStartDate = weekPickerSelectedDate.clone().startOf('week');
			weekPickerEndDate = weekPickerSelectedDate.clone().endOf('week');
		}
		const exportMenuStyle = {position: 'absolute', zIndex: 99};

		const buttonBounds = this.exportIcon ? this.exportIcon.getBoundingClientRect() : null;

		if (buttonBounds) {
			exportMenuStyle.top = buttonBounds.top + 30;
			exportMenuStyle.left = buttonBounds.left - 35;
		}

		return (
			<div className={this.props.canUseFilter ? 'header-large' : 'header'}>
				<div className="title" data-cy="title">
					{this.props.title}
				</div>
				{isTimeLineComponent ? (
					<div className={'share-info-text'}>
						{
							<FormattedHTMLMessage
								id="insights.shared_scheduling_info"
								values={{link: window.location.origin + '/scheduling#projects'}}
							/>
						}
					</div>
				) : null}
				<div id={'insight-component-header-portal-target-' + this.props.id} />
				{this.props.canUseFilter ? (
					<div className="forecast-actions-insights">
						{isAnyFilterUsed ? (
							<h3 className="clear-filters" onClick={this.clearFilters.bind(this)}>
								{formatMessage({id: 'common.clear-filters'})}
							</h3>
						) : null}
						<Filter filters={filters} disableRenderCalculations={true} />
					</div>
				) : null}
				{this.props.backendGroupOptions ? (
					<Select
						options={this.props.backendGroupOptions}
						onChange={this.handleBackendGroupByChange.bind(this)}
						multi={false}
						label=""
						hideLabel={true}
						value={this.props.groupBy || null}
						clearable={true}
						placeholder={formatMessage({id: 'insights.component.reportedTime.grouping_method_placeholder'})}
						disableRenderCalculations={true}
					/>
				) : null}
				{this.props.canUseDatePicker ? (
					<DateSpanPicker
						onChange={this.handleDateSpanChange.bind(this)}
						value={
							this.props.componentName === 'utilizationList' || this.props.componentName === 'utilizationBarChart'
								? !this.props.dateSpan
									? {label: 'date_span_picker.current_month', value: 'current-month', key: 'current-month'}
									: this.props.dateSpan
								: this.props.dateSpan
						}
						locale={util.GetLocaleFromPersonLanguage(language)}
						showFutureOptions={this.props.componentName === 'utilizationBarChart'}
						placeholder={formatMessage({id: 'insights.date_span_placeholder'})}
						disableRenderCalculations={true}
					/>
				) : null}
				{this.props.canUseWeekPicker ? (
					<DatePicker
						onConfirm={this.props.handleWeekPickerChange.bind(this)}
						weekPicker={true}
						value={weekPickerSelectedDate}
						startDate={weekPickerStartDate}
						endDate={weekPickerEndDate}
						rightAligned={true}
						moveToPreviousWeek={this.handleWeekPickerMoveToPreviousWeek.bind(this)}
						moveToNextWeek={this.handleWeekPickerMoveToNextWeek.bind(this)}
						locale={util.GetLocaleFromPersonLanguage(language)}
					/>
				) : null}
				{this.props.isTasksFlowComponent ? (
					<Select
						options={[
							{value: 'count', label: formatMessage({id: 'insights_header_select.cards_count'})},
							{value: 'forecast', label: formatMessage({id: 'insights_header_select.cards_forecast'})},
						]}
						onChange={this.handleTasksFlowCritChange.bind(this)}
						multi={false}
						label="display-citeria"
						hideLabel={true}
						value={this.props.tasksFlowCrit ? this.props.tasksFlowCrit.value : 'count'}
						disableRenderCalculations={true}
					/>
				) : null}
				{this.props.isProjectBudgetComponent ? (
					<Select
						options={[
							this.props.projectId
								? {value: 'phases', label: formatMessage({id: 'project_budget.group_by_phase'})}
								: {
										value: 'phases',
										label: formatMessage({id: 'insights_component_header.group_by_project'}),
								  },
							{value: 'roles', label: formatMessage({id: 'project_budget.group_by_role'})},
						]}
						onChange={this.handleBudgetGroupByCritChange.bind(this)}
						multi={false}
						label=""
						hideLabel={true}
						value={this.props.budgetGroupByCrit ? this.props.budgetGroupByCrit.value : 'phases'}
						disableRenderCalculations={true}
					/>
				) : null}
				{this.props.selectOptions ? (
					<Select
						options={this.props.selectOptions}
						onChange={this.props.onSelectChange}
						hideLabel={true}
						label=""
						value={this.props.selectValue}
						multi={false}
						disableRenderCalculations={true}
					/>
				) : null}

				{this.props.onSelectSprintChange ? (
					<Select
						options={sprintOptions}
						onChange={this.props.onSelectSprintChange}
						hideLabel={true}
						label=""
						value={this.props.selectedSprint}
						multi={false}
						placeholder={formatMessage({id: 'insights.component.burndown.sprint_select_placeholder'})}
						disableRenderCalculations={true}
					/>
				) : null}

				{
					//this is used by sprint velocity component where user can select multiple sprints and view data for them
					this.props.onSelectSprintsChange ? (
						<Select
							options={sprintOptions}
							onChange={this.props.onSelectSprintsChange}
							emptyText={formatMessage({id: 'common.no_done_sprints'})}
							hideLabel={true}
							label=""
							value={this.props.selectedSprints}
							multi={true}
							placeholder={formatMessage({id: 'insights.component.burndown.sprint_select_placeholder'})}
							disableRenderCalculations={true}
							showValuesInline={true}
							clearable={true}
						/>
					) : null
				}

				{this.props.onYearChange ? (
					<Select
						options={this.getYearOptions()}
						onChange={this.props.onYearChange}
						hideLabel={true}
						label=""
						value={this.props.selectedYear}
						multi={false}
						placeholder={formatMessage({id: 'insights.component.burndown.sprint_select_placeholder'})}
						disableRenderCalculations={true}
					/>
				) : null}

				<Info infoMessage={this.props.infoText} leftAligned={true} />
				{this.props.canExport ? (
					<div
						className="download-icon"
						title={formatMessage({id: 'common.export-csv'})}
						onClick={this.toggleExportMenu.bind(this)}
						tabIndex="0"
						ref={div => (this.exportIcon = div)}
						onBlur={e => {
							const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
							if (!newTarget || newTarget.className !== 'export-menu-button') {
								this.setState({exportMenuExpanded: false});
							}
						}}
					/>
				) : null}
				{this.state.exportMenuExpanded
					? ReactDOM.createPortal(
							<div className="insight-header-export-menu" style={exportMenuStyle}>
								<div
									className="export-menu-button"
									onClick={() => {
										this.props.export('csv');
										this.setState({exportMenuExpanded: false});
									}}
									tabIndex="0"
								>
									CSV
								</div>
								<div
									className="export-menu-button"
									onClick={() => {
										this.props.export('xlsx');
										this.setState({exportMenuExpanded: false});
									}}
									tabIndex="0"
								>
									XLSX
								</div>
							</div>,
							document.querySelector('#root-portal-container')
					  )
					: null}
			</div>
		);
	}
}

ComponentHeader.propTypes = {
	title: PropTypes.string.isRequired,
	infoText: PropTypes.string.isRequired,
	canUseDatePicker: PropTypes.bool.isRequired,
	isTasksFlowComponent: PropTypes.bool,
};

export default injectIntl(ComponentHeader);
