import React from 'react';
import styled from 'styled-components';
import {FormattedMessage} from 'react-intl';

import {CSS_CONSTANTS} from '../../../../css_variables';

const PeriodLabelHeaderElementStyles = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: ${CSS_CONSTANTS.v2_text_gray};

	.periodSquare {
		width: 20px;
		height: 20px;
		border: solid 1px ${CSS_CONSTANTS.v2_button_very_light_grey};
		background-color: ${CSS_CONSTANTS.v2_list_hover_purple};
	}

	div {
		margin-right: 8px;
	}
`;

export const PeriodLabelHeaderElement = () => {
	return (
		<PeriodLabelHeaderElementStyles>
			<div className="periodSquare" />
			<div>=</div>
			<div>
				<FormattedMessage id="period_label_header_element.title" />
			</div>
		</PeriodLabelHeaderElementStyles>
	);
};
