/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SprintDropdown_sprints$ref: FragmentReference;
declare export opaque type SprintDropdown_sprints$fragmentType: SprintDropdown_sprints$ref;
export type SprintDropdown_sprints = $ReadOnlyArray<{|
  +node: ?{|
    +name: ?string,
    +id: string,
    +projectGroupSprintId: ?string,
    +isProjectGroupSprint: ?boolean,
    +endYear: ?number,
    +endMonth: ?number,
    +endDay: ?number,
  |},
  +$refType: SprintDropdown_sprints$ref,
|}>;
export type SprintDropdown_sprints$data = SprintDropdown_sprints;
export type SprintDropdown_sprints$key = $ReadOnlyArray<{
  +$data?: SprintDropdown_sprints$data,
  +$fragmentRefs: SprintDropdown_sprints$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SprintDropdown_sprints",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Sprint",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "projectGroupSprintId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isProjectGroupSprint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDay",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SprintTypeEdge"
};
// prettier-ignore
(node/*: any*/).hash = '792a58f5b868b02a831547b6305a099f';

module.exports = node;
