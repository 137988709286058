import React from 'react';
import styled from 'styled-components';
import LoaderTop from '../../images/loaders/ai-loader-top.svg';
import LoaderMiddle from '../../images/loaders/ai-loader-middle.svg';
import LoaderBottom from '../../images/loaders/ai-loader-bottom.svg';
const AiLoaderContainer = styled.div`
	display: flex;
	margin: 15px auto;
	height: 70px;
	width: 70px;
	position: ${props => (props.absolute ? `absolute; ` : '')};
	top: ${props => (props.absolute ? `50%; ` : '')};
	left: ${props => (props.absolute ? `50%; ` : '')};
	.ai-loader-container {
		width: 100%;
		transform: ${props => (props.absolute ? 'translate(50%, 0)' : 'translate(0, 0)')};
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 999;
		@keyframes scaleMiddleLoader {
			0%,
			100% {
				transform: scaleX(0.8);
			}
			50% {
				transform: scaleX(1);
			}
		}
		@keyframes moveTopLoader {
			0% {
				transform: translateY(10px);
				opacity: 0.7;
			}
			50% {
				transform: translateY(0x);
				opacity: 1;
			}
			100% {
				transform: translateY(-10px);
				opacity: 0.7;
			}
		}

		.background-circle {
			position: absolute;
			border-radius: 50%;
			background: #ccc;
			width: 100%;
			height: 100%;
			opacity: 0.15;
		}

		.icon {
			display: flex;
			flex-direction: column;
			align-items: center;
			z-index: 1;
			.loader-top,
			.loader-middle,
			.loader-bottom {
				background-repeat: no-repeat;
				background-position: center;
			}
			.loader-top {
				width: 13px;
				height: 18px;
				background-size: 13px 18px;
				background-image: url(${LoaderTop});
				animation: moveTopLoader 0.5s infinite;
			}
			.loader-middle {
				width: 24px;
				height: 11px;
				background-size: 24px 11px;
				background-image: url(${LoaderMiddle});

				animation: scaleMiddleLoader 0.5s infinite;
			}
			.loader-bottom {
				width: 30px;
				height: 20px;
				background-size: 30px 20px;
				background-image: url(${LoaderBottom});
			}
		}
	}
`;

const AiLoader = props => {
	return (
		<AiLoaderContainer absolute={props.absolute}>
			<div className="ai-loader-container">
				<div className="background-circle"></div>
				<div className="icon">
					<div className="loader-top" />
					<div className="loader-middle" />
					<div className="loader-bottom" />
				</div>
			</div>
		</AiLoaderContainer>
	);
};
export default AiLoader;
