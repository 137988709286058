import {createFragmentContainer, graphql} from 'react-relay';
import {ChartStyle, ChartTabOption, ChartTabs} from '../../money/work_and_expenses/Chart.styled';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {decompressAggregatedData} from '../../util/DataDecompressUtil';
import {Chart} from 'web-components';
import {getGradients, getTimeTick, getTimeUnit} from '../../util/ChartsUtil';
import Util from '../../../../../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../../../../../forecast-app/shared/util/FeatureUtil';
import {chartTokens} from '@forecast-it/design-system';
import {remapAndFormatFinancialMessage} from '../../../../../../forecast-app/shared/util/FinancialInternationalisationUtil';

const PlanActualTimeChart = ({viewer, aggregateLevel, eyeOptionMap}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const {project} = viewer;
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const aggregatedFinancialNumbers = decompressAggregatedData(project.aggregatedFinancialNumbers, [
		'scopeApprovedMinutes',
		'registeredMinutes',
		'forecastMinutes',
		'baselineMinutes',
	]);

	const data = {
		planMinutesChartData: [],
		actualMinutesChartData: [],
		forecastMinutesChartData: [],
		baselineMinutesChartData: [],
	};

	aggregatedFinancialNumbers.forEach(financialNumbers => {
		data.planMinutesChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.scopeApprovedMinutesAccumulated,
		});
		data.actualMinutesChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.registeredMinutesAccumulated,
		});
		data.forecastMinutesChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.forecastMinutesAccumulated,
		});
		data.baselineMinutesChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.baselineMinutesAccumulated,
		});
	});

	const {planMinutesChartData, actualMinutesChartData, forecastMinutesChartData, baselineMinutesChartData} = data;
	const {gradientStroke, gradientFill, gradientStrokeActual, gradientFillActual, gradientStrokeBaseline} = getGradients();

	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');
	const chartColors = chartTokens.chart.color;

	const dataSets = [];
	if (eyeOptionMap.plan) {
		dataSets.push({
			name: remapAndFormatFinancialMessage({id: 'project_budget.planned_time'}),
			color: hasFinancialCategoriesUpdate ? chartColors['7'] : gradientStroke,
			filled: !hasFinancialCategoriesUpdate,
			backgroundColor: gradientFill,
			data: planMinutesChartData,
			borderWidth: hasFinancialCategoriesUpdate ? 3 : undefined,
		});
	}
	if (!hasFinancialCategoriesUpdate && eyeOptionMap.actual) {
		dataSets.push({
			name: formatMessage({id: 'project_budget.actual_time_to_date'}),
			color: gradientStrokeActual,
			filled: true,
			backgroundColor: gradientFillActual,
			data: actualMinutesChartData,
		});
	}
	if (hasFinancialCategoriesUpdate && eyeOptionMap.forecast) {
		dataSets.push({
			name: formatMessage({id: 'common.projected_total_hours'}),
			color: chartColors['2'],
			data: forecastMinutesChartData,
			borderWidth: 2,
		});
	}
	if (eyeOptionMap.baseline) {
		dataSets.push({
			name: remapAndFormatFinancialMessage({id: 'project_budget.baseline_time'}),
			color: hasFinancialCategoriesUpdate ? chartColors['4'] : gradientStrokeBaseline,
			filled: !hasFinancialCategoriesUpdate,
			backgroundColor: 'transparent',
			data: baselineMinutesChartData,
			dashed: !hasFinancialCategoriesUpdate,
			borderWidth: hasFinancialCategoriesUpdate ? 1 : undefined,
		});
	}

	const projectFirstDate = Util.CreateNonUtcMomentDate(
		project.projectFirstDateYear,
		project.projectFirstDateMonth,
		project.projectFirstDateDay
	);
	const projectLastDate = Util.CreateNonUtcMomentDate(
		project.projectLastDateYear,
		project.projectLastDateMonth,
		project.projectLastDateDay
	);

	const modifyChartOptions = options => {
		options.animation = undefined;
		options.scales.x.time.unit = getTimeUnit(projectFirstDate, projectLastDate);
		options.scales.y.ticks.callback = minutes => getTimeTick(minutes, intl);
		options.plugins.tooltip.callbacks.label = ctx => {
			const item = ctx.dataset;
			const dataItem = ctx.parsed;
			if (dataItem.customData && dataItem.customData.hideLabel) {
				return '';
			} else {
				const dataSetLabel = item.label ? `${item.label}: ` : '';
				return `${dataSetLabel}${getTimeTick(dataItem.y, intl)}`;
			}
		};
		return options;
	};

	const charts = [
		{
			name: formatMessage({id: `project-economic.th_time`}),
			render: () => (
				<Chart dataSets={dataSets} modifyOptions={modifyChartOptions} usePointStyle={hasFinancialCategoriesUpdate} />
			),
		},
	];

	return (
		<>
			<ChartTabs>
				{charts.map((item, index) => {
					return (
						<ChartTabOption
							key={'time_chart_' + index}
							active={index === activeTabIndex}
							onClick={() => setActiveTabIndex(index)}
						>
							{item.name}
						</ChartTabOption>
					);
				})}
			</ChartTabs>
			<ChartStyle>{charts[activeTabIndex].render()}</ChartStyle>
		</>
	);
};

const timeChartsQuery = graphql`
	query PlanActualTimeChart_Query(
		$projectId: ID
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
	) {
		viewer {
			id
			actualPersonId
			component(name: "project_budget_v3_time_charts")
			...PlanActualTimeChart_viewer
				@arguments(
					projectId: $projectId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export {timeChartsQuery};

export default createFragmentContainer(PlanActualTimeChart, {
	viewer: graphql`
		fragment PlanActualTimeChart_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
		) {
			project(internalId: $projectId) {
				projectFirstDateYear
				projectFirstDateMonth
				projectFirstDateDay
				projectLastDateYear
				projectLastDateMonth
				projectLastDateDay
				estimationUnit
				budget
				budgetType
				aggregatedFinancialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregates: [scopeApprovedMinutes, registeredMinutes, forecastMinutes, baselineMinutes]
					aggregateLevel: DAY
					addAccumulatedNumbers: true
					convertToProjectCurrency: true
				)
			}
		}
	`,
});
