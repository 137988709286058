import React, {useMemo} from 'react';
import {Table} from 'web-components';
import {LoadMore} from '../../../loaders/LoadMore';
import MonthByMonthTotalsLoader, {monthByMonthTotalsLoaderQuery} from './MonthByMonthTotalsLoader';
import {useIntl} from 'react-intl';
import {filtersToSearchQuery} from '../ProjectPortfolioReportUtils';
import ProjectPortfolioMonthByMonthRow from './ProjectPortfolioMonthByMonthRow';
import {SPECIAL_ROLE} from '../../../../constants';
import ForecastTooltip from '../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {ProjectPortfolioReportTooltips} from '../ProjectPortfolioReportTooltips';
import {GROUP_BY} from '../ProjectPortfolioReport';
import ProjectPortfolioMonthByMonthProjectRows from './ProjectPortfolioMonthByMonthProjectRows';
import ProjectPortfolioMonthByMonthProgramRows from './ProjectPortfolioMonthByMonthProgramRows';
import ProjectPortfolioMonthByMonthConnectedProjectRows from './ProjectPortfolioMonthByMonthConnectedProjectRows';
import ProjectPortfolioMonthByMonthClientRows from './ProjectPortfolioMonthByMonthClientRows';

export const ProjectPortfolioMonthByMonthTable = ({
	filters,
	selectedDateRange: {startDate, endDate},
	selectedSingleValue,
	setCsvDownloadFunction,
	hasBaseline,
	theEyeOptionsNestedTree,
	groupBy,
}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const pageSize = 100;

	const boolEyeOptions = useMemo(() => {
		const bools = {};
		theEyeOptionsNestedTree.forEach(eyeOption => {
			bools[eyeOption.name] = eyeOption.checked;
			if (eyeOption.nestedOptions) {
				eyeOption.nestedOptions.forEach(nestedOption => {
					bools[nestedOption.name] = nestedOption.checked;
					if (nestedOption.nestedOptions) {
						nestedOption.nestedOptions.forEach(subNestedOption => {
							bools[subNestedOption.name] = subNestedOption.checked;
						});
					}
				});
			}
		});
		return bools;
	}, [theEyeOptionsNestedTree]);

	const timeSearchQuery = filtersToSearchQuery(filters);
	timeSearchQuery.filters.push({
		field: 'ROLE',
		operator: 'NOT_IN',
		value: SPECIAL_ROLE.EXPENSE,
	});

	const expenseSearchQuery = filtersToSearchQuery(filters);
	expenseSearchQuery.filters.push({
		field: 'ROLE',
		operator: 'IN',
		value: SPECIAL_ROLE.EXPENSE,
	});

	return (
		<Table paddedCells>
			<Table.Header>
				<Table.HeaderColumn visible flex={1} width="EXTRA_LARGE" />

				{/* Project Overview */}
				<Table.HeaderGrouping name={formatMessage({id: 'project_budget.project_overview'})}>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_priorityLevel} usePadding align="left" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'project_settings.priority_level'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_winProbability} usePadding align="right" width="SMALL">
						<ForecastTooltip content={ProjectPortfolioReportTooltips.WinProbabilityTooltip(formatMessage)}>
							<Table.HeaderColumn.Title>
								{formatMessage({id: 'project_portfolio_report.win_prob'})}
							</Table.HeaderColumn.Title>
						</ForecastTooltip>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_dates} usePadding align="left" width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.start'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_dates} usePadding align="left" width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.end'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_client} usePadding align="left" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'insights.component.list.column.client'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_status} usePadding align="center" width="EXTRA_SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.status'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn
						visible={boolEyeOptions.mbm_statusDescription}
						usePadding
						align="left"
						width="SUPER_LARGE"
					>
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.statusDescription'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_projectOwner} usePadding align="left" width="MEDIUM">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.project_owner'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_stage} usePadding align="left" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'insights.component.list.column.status'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>

					<Table.HeaderColumn visible={boolEyeOptions.mbm_budgetType} usePadding align="left" width="MEDIUM">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'new_project_modal.budget_type'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_rateCard} usePadding align="left" width="LARGE">
						<Table.HeaderColumn.Title>
							{formatMessage({id: 'insights.component.list.column.rateCard'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_progress} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.progress'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn visible={boolEyeOptions.mbm_fixedPriceRevenue} usePadding align="right" width="MEDIUM">
						<Table.HeaderColumn.Title>{formatMessage({id: 'project_budget.fixed_price'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>

				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.jan'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.feb'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.mar'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.apr'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.may'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.jun'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.jul'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.aug'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.sep'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.oct'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.nov'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
				<Table.HeaderGrouping name={`${formatMessage({id: 'insights.component.list.column.dec'})}`}>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_expense} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.expenses'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_time} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.time'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
					<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.mbm_total} width="SMALL">
						<Table.HeaderColumn.Title>{formatMessage({id: 'common.total'})}</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				</Table.HeaderGrouping>
			</Table.Header>
			<LoadMore
				key="query-render-month-by-month-totals"
				query={monthByMonthTotalsLoaderQuery}
				variables={{
					searchQuery: filtersToSearchQuery(filters),
					timeSearchQuery: timeSearchQuery,
					expenseSearchQuery: expenseSearchQuery,
					startYear: startDate.year(),
					startMonth: startDate.month() + 1,
					startDay: startDate.date(),
					endYear: endDate.year(),
					endMonth: endDate.month() + 1,
					endDay: endDate.date(),
					selectedSingleValue: selectedSingleValue,
				}}
				loader={<MonthByMonthTotalsLoader />}
			>
				{({data}) => {
					return (
						<Table.Rows data={data} canExpand={false} hasHeader>
							{({rowData, tableColumnsProps}) => {
								return (
									<ProjectPortfolioMonthByMonthRow
										rowData={rowData}
										tableColumnProps={tableColumnsProps}
										headerColumn={<Table.Column>{formatMessage({id: 'common.totals'})}</Table.Column>}
									/>
								);
							}}
						</Table.Rows>
					);
				}}
			</LoadMore>
			{groupBy === GROUP_BY.PROGRAM ? (
				<ProjectPortfolioMonthByMonthProgramRows
					setCsvDownloadFunction={setCsvDownloadFunction}
					hasBaseline={hasBaseline}
					pageSize={pageSize}
					startDate={startDate}
					endDate={endDate}
					filters={filters}
					boolEyeOptions={boolEyeOptions}
					timeSearchQuery={timeSearchQuery}
					expenseSearchQuery={expenseSearchQuery}
					selectedSingleValue={selectedSingleValue}
				></ProjectPortfolioMonthByMonthProgramRows>
			) : groupBy === GROUP_BY.CONNECTED_PROJECT ? (
				<ProjectPortfolioMonthByMonthConnectedProjectRows
					setCsvDownloadFunction={setCsvDownloadFunction}
					hasBaseline={hasBaseline}
					pageSize={pageSize}
					startDate={startDate}
					endDate={endDate}
					filters={filters}
					boolEyeOptions={boolEyeOptions}
					timeSearchQuery={timeSearchQuery}
					expenseSearchQuery={expenseSearchQuery}
					selectedSingleValue={selectedSingleValue}
				></ProjectPortfolioMonthByMonthConnectedProjectRows>
			) : groupBy === GROUP_BY.CLIENT ? (
				<ProjectPortfolioMonthByMonthClientRows
					setCsvDownloadFunction={setCsvDownloadFunction}
					hasBaseline={hasBaseline}
					pageSize={pageSize}
					startDate={startDate}
					endDate={endDate}
					filters={filters}
					boolEyeOptions={boolEyeOptions}
					timeSearchQuery={timeSearchQuery}
					expenseSearchQuery={expenseSearchQuery}
					selectedSingleValue={selectedSingleValue}
				></ProjectPortfolioMonthByMonthClientRows>
			) : (
				<ProjectPortfolioMonthByMonthProjectRows
					setCsvDownloadFunction={setCsvDownloadFunction}
					hasBaseline={hasBaseline}
					pageSize={pageSize}
					startDate={startDate}
					endDate={endDate}
					filters={filters}
					boolEyeOptions={boolEyeOptions}
					timeSearchQuery={timeSearchQuery}
					expenseSearchQuery={expenseSearchQuery}
					selectedSingleValue={selectedSingleValue}
				></ProjectPortfolioMonthByMonthProjectRows>
			)}
		</Table>
	);
};
