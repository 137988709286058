import moment from 'moment';
import {getCanvasTimelineDateFromMoment, getMomentFromCanvasTimelineDate} from '../canvas-timeline/canvas_timeline_util';
import {LOAD_MORE, LOAD_MORE_DIRECTION, OVERLAY_PREFIX} from './IncrementalLoadingOverlay';
import {fetchMoreData} from '../scheduling_fetch';
import {DATE_FORMAT_DAY} from '../../../constants';

export const MONTHS_TO_LOAD_AS_BUFFER = 1;
export const MAX_LOAD_MORE_MONTHS = 6;
export const MIN_LOAD_MORE_MONTHS = 1;
export const INITIAL_LOAD_MORE_MONTHS = {
	PAST: 3,
	FUTURE: 6,
};

export const LOAD_MORE_DATE_PAST = 'load_more_date_past';
export const LOAD_MORE_DATE_FUTURE = 'load_more_date_future';

export const getFetchDates = pageComponent => {
	return {
		[LOAD_MORE_DATE_PAST]: getMomentFromCanvasTimelineDate(
			pageComponent.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT]
		).format(DATE_FORMAT_DAY),
		[LOAD_MORE_DATE_FUTURE]: getMomentFromCanvasTimelineDate(
			pageComponent.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT]
		).format(DATE_FORMAT_DAY),
	};
};

export const isStepHidden = (step, dataStartDate, dataEndDate) => {
	return (dataStartDate && step.startDate <= dataStartDate) || (dataEndDate && step.endDate >= dataEndDate);
};

export const getDataStartDate = initDate => {
	const date = initDate ? moment(initDate) : moment();
	return getCanvasTimelineDateFromMoment(date.subtract(3, 'months'));
};

export const getDataEndDate = initDate => {
	const date = initDate ? moment(initDate) : moment();
	return getCanvasTimelineDateFromMoment(date.add(6, 'months'));
};

export const preventScrollingPastLoadMoreOverlay = (pageComponent, scrollAmount, predicate) => {
	if ((predicate === undefined || predicate) && !pageComponent.state.allDataLoaded) {
		if (scrollAmount < 0) {
			const leftElement = document.getElementById('load-more-overlay-left');
			if (leftElement.clientWidth > 500) {
				return false;
			}
		}
		if (scrollAmount > 0) {
			const rightElement = document.getElementById('load-more-overlay-right');
			if (rightElement.clientWidth > 500) {
				return false;
			}
		}
	}
	return true;
};

export const isStepHiddenByLoadMore = (pageComponent, step) => {
	if (pageComponent.state.allDataLoaded) return false;
	let initDate = undefined;
	if (pageComponent.state.staffingModeActive) {
		initDate = pageComponent.state.staffingInitialDate;
	}
	return isStepHidden(step, getDataStartDate(initDate), getDataEndDate(initDate));
};

const hideElement = element => {
	element.style.left = '0';
	element.style.top = '0';
	element.style.height = '0';
	element.style.width = '0';
	element.style.display = 'none';
};

export const updateElementPosition = (element, minX, maxX, leftPadding, rightPadding) => {
	if (minX !== Number.MAX_SAFE_INTEGER && maxX !== 0) {
		const foregroundCanvas = document.getElementById('foreground-canvas');
		const canvasBounds = foregroundCanvas.getBoundingClientRect();

		const left = Math.max(minX, 0) + leftPadding;
		const width = Math.min(canvasBounds.width, maxX) - left + rightPadding;
		element.style.left = `${left + canvasBounds.x}px`;
		element.style.top = `${canvasBounds.y}px`;
		element.style.height = `${canvasBounds.height}px`;
		element.style.width = `${width}px`;
		element.style.display = 'grid';
	} else {
		hideElement(element);
	}
};
export const isStepHiddenBehindLoadMore = (pageComponent, step) => {
	if (pageComponent.disableLoadMore) {
		return false;
	}
	const leftLoadMoreDate = pageComponent.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT];
	const rightLoadMoreDate = pageComponent.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT];
	return isStepHidden(step, leftLoadMoreDate, rightLoadMoreDate);
};

export const getOverlayCanvasDate = (pageComponent, overlayDate, direction) => {
	if (overlayDate) {
		return overlayDate;
	}
	const isDirectionPast = direction === LOAD_MORE_DIRECTION.LEFT;
	const calculatedDate = isDirectionPast
		? moment().subtract(INITIAL_LOAD_MORE_MONTHS.PAST, 'months').startOf('month')
		: moment().add(INITIAL_LOAD_MORE_MONTHS.FUTURE, 'months').endOf('month');

	return getCanvasTimelineDateFromMoment(calculatedDate);
};

export const drawIncrementalLoadMoreOverlay = (pageComponent, stepDataArray, shouldDraw = () => true) => {
	if (shouldDraw()) {
		const leftElement = document.getElementById(OVERLAY_PREFIX + LOAD_MORE_DIRECTION.LEFT);
		const rightElement = document.getElementById(OVERLAY_PREFIX + LOAD_MORE_DIRECTION.RIGHT);

		const leftDate = pageComponent.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.LEFT];
		const rightDate = pageComponent.state[LOAD_MORE.DATE + LOAD_MORE_DIRECTION.RIGHT];

		const leftCanvasDate = getOverlayCanvasDate(pageComponent, leftDate, LOAD_MORE_DIRECTION.LEFT);
		const rightCanvasDate = getOverlayCanvasDate(pageComponent, rightDate, LOAD_MORE_DIRECTION.RIGHT);

		let [leftMinX, rightMinX] = Array(2).fill(Number.MAX_SAFE_INTEGER);
		let [leftMaxX, rightMaxX, leftStepsHidden, rightStepsHidden] = Array(4).fill(0);

		stepDataArray.forEach(step => {
			if (isStepHidden(step, leftCanvasDate, null)) {
				if (step.position < leftMinX) {
					leftMinX = step.position;
				}
				if (step.position + step.width > leftMaxX) {
					leftMaxX = step.position + step.width;
				}

				leftStepsHidden++;
			} else if (isStepHidden(step, null, rightCanvasDate)) {
				if (step.position < rightMinX) {
					rightMinX = step.position;
				}
				if (step.position + step.width > rightMaxX) {
					rightMaxX = step.position + step.width;
				}

				rightStepsHidden++;
			}
		});

		fetchMoreData(pageComponent, leftCanvasDate, rightCanvasDate, leftStepsHidden, rightStepsHidden);

		updateElementPosition(leftElement, leftMinX, leftMaxX, 0, -1);
		updateElementPosition(rightElement, rightMinX, rightMaxX, 1, 0);
	}
};

export const setIsLoadingMoreData = (pageComponent, direction, isLoading, date = null) => {
	const isCurrentlyLoadingInDirection = pageComponent.state[LOAD_MORE.IS_LOADING + direction];

	if (isCurrentlyLoadingInDirection !== isLoading) {
		const stateUpdateData = {
			[LOAD_MORE.IS_LOADING + direction]: isLoading,
		};

		if (date) {
			stateUpdateData[LOAD_MORE.DATE + direction] = date;
		}

		const hasLoadedMore = isCurrentlyLoadingInDirection === true && isLoading === false;

		pageComponent.setState(stateUpdateData, () => {
			if (hasLoadedMore) {
				const {timeline} = pageComponent;
				drawIncrementalLoadMoreOverlay(pageComponent, timeline.minorStepDataArray);
				pageComponent.redrawCanvasTimeline({preventFiltering: false, skipPostProcessing: true, skipBackground: true});
			}
		});
	}
};
