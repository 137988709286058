import {createFragmentContainer, graphql} from 'react-relay';
import {useEffect} from 'react';
import {GROUP_BY, PROJECT_PORTFOLIO_REPORT_PAGES} from '../ProjectPortfolioReport';
import {exportToCsv} from '../ProjectPortfolioReportCsvDownload';
import {useIntl} from 'react-intl';
import {PROGRAM_BUDGET_TYPE} from '../../../../constants';
import {hasTopDownProgramBudgetFeature} from '../../../../forecast-app/shared/util/ProgramFinancialLogic';

const ProjectPortfolioPeriodDownload = ({viewer, closeModal, startDate, endDate, boolEyeOptions}) => {
	const intl = useIntl();
	const currency = viewer.company.currency;
	const projects = viewer.company.financialBasedProjects.edges.map(edge => edge.node);

	useEffect(() => {
		if (viewer) {
			if (hasTopDownProgramBudgetFeature()) {
				const programUnallocatedRevenue = viewer.company.programs.edges.reduce((acc, programEdge) => {
					return programEdge.node.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE
						? programEdge.node.budgetValue - programEdge.node.totalAllocation + acc
						: acc;
				}, 0);
				projects.push({
					name: 'Unallocated program revenue',
					currency: currency,
					financialNumbers: {
						totalRevenueRecognition: programUnallocatedRevenue,
					},
					isFooter: true,
				});
			}
			exportToCsv({
				rows: projects,
				grouping: GROUP_BY.NO_GROUPING,
				page: PROJECT_PORTFOLIO_REPORT_PAGES.PERIOD,
				currency,
				boolEyeOptions,
				startDate,
				endDate,
				intl,
			});
			closeModal();
		}
	}, [viewer]);

	return null;
};

export const projectPortfolioPeriodDownloadQuery = graphql`
	query ProjectPortfolioPeriodDownload_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$searchQuery: TaskSearchQueryType!
		$pageSize: Int!
		$cursor: String
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_period_project_csv_loader")
			...ProjectPortfolioPeriodDownload_viewer
				@arguments(
					searchQuery: $searchQuery
					pageSize: $pageSize
					cursor: $cursor
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createFragmentContainer(ProjectPortfolioPeriodDownload, {
	viewer: graphql`
		fragment ProjectPortfolioPeriodDownload_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
			pageSize: {type: "Int!"}
			cursor: {type: "String"}
		) {
			id
			company {
				currency
				programs {
					edges {
						node {
							budgetType
							budgetValue
							totalAllocation
						}
					}
				}
				financialBasedProjects(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					first: $pageSize
					after: $cursor
				) {
					edges {
						node {
							id
							companyProjectId
							customProjectId
							name
							budgetType
							budgetBaseCurrency
							defaultPeriodBudgetType
							fullAccessToProject
							projectColor
							status
							projectStartDay
							projectStartMonth
							projectStartYear
							projectEndDay
							projectEndMonth
							projectEndYear
							baselineWinChance
							priorityLevel {
								id
								name
							}
							completion
							progressEndOfTheMonth
							useBaseline
							rateCard {
								id
								name
								currency
								parentRateCardId
							}
							client {
								id
								name
								logoId
								logoDefaultId
							}
							projectLabels(first: 1000) {
								edges {
									node {
										label {
											name
											color
										}
									}
								}
							}
							projectPersons(first: 1000000, contactsOnly: true) {
								edges {
									node {
										id
										isContactPerson
										person {
											id
											fullName
											firstName
											lastName
											profilePictureId
											profilePictureDefaultId
											initials
										}
									}
								}
							}
							currentProjectStatus {
								id
								color
								description
								person {
									id
									firstName
									lastName
									fullName
									profilePictureId
								}
							}
							financialNumbers(
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
							) {
								billableActualTimeAndExpenses
								totalActualRevenueRecognition
								actualCost
								actualProfit
								actualRevenueProfit
								actualMargin
								actualRevenueMargin
								billablePlannedTimeAndExpenses
								plannedRevenue
								plannedCost
								plannedProfit
								plannedRevenueProfit
								plannedMargin
								plannedRevenueMargin
								billableForecastTimeAndExpensesToComplete
								totalForecastRevenueToComplete
								forecastCostToComplete
								forecastProfitToComplete
								forecastRevenueProfitToComplete
								forecastMarginToComplete
								forecastRevenueMarginToComplete
								billableTotalTimeAndExpensesAtCompletion
								nonBillableTotalTimeAndExpensesAtCompletion
								allTotalTimeAndExpensesAtCompletion
								totalRevenueRecognition
								totalCostAtCompletion
								totalProfitAtCompletion
								totalRevenueProfitAtCompletion
								totalMarginAtCompletion
								totalRevenueMarginAtCompletion
								baselineRevenue
								baselineTimeAndExpenses
								baselineCost
								baselineProfit
								baselineMargin
								baselineMinutes
								planVsBillableActualTimeAndExpenses
								planVsActualCost
								planVsActualProfit
								planVsTotalBillableTimeAndExpensesAtCompletion
								planVsTotalCostAtCompletion
								planVsTotalProfitAtCompletion
								registeredMinutes
								forecastTimeToComplete
								totalTimeAtCompletion
								scopeApprovedMinutes
								scopeTotalMinutes
								allocationMinutes
								invoiced
								paid
								accruedDeferred
								retainerPeriodTargetMinutes
								retainerPeriodTargetPrice
								recognitionLockedRevenue
								recognitionOpenRevenue
								totalRevenueRecognition
								recognitionProfit
								recognitionProfitPercentage
								projectedTotalRevenueVsProjectedTotalBillableValueOfService
								projectedTotalRevenueVsProjectedTotalValueOfService
							}
						}
					}
				}
			}
		}
	`,
});
