import React from 'react';
import {getClientName, getRolename, getTimeRegTypeAndDetailDom} from '../reported_time_table_util';
import Util from '../../../../forecast-app/shared/util/util';
import {APPROVAL_STATUS} from '../../../../constants';
import moment from 'moment';
import {HexagonText, Table} from 'web-components';
import {hasSomePermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {profilePicSrc} from '../../../../directApi';

export const getTimeRegRow = (currency, intl, timeReg, enabledColumns, fetchMore) => {
	const enabledColumnsCopy = {...enabledColumns};
	const hasAccessToProject = timeReg.project
		? timeReg.project.fullAccessToProject
		: timeReg.task
		? timeReg.task.project.fullAccessToProject
		: true;
	const [project, task] = getTimeRegTypeAndDetailDom(timeReg, intl);
	const phaseName = timeReg.task?.phase?.name;
	const date = moment({M: timeReg.month - 1, d: timeReg.day, y: timeReg.year}).format('DD MMM YYYY');
	const roleName = hasAccessToProject ? getRolename(timeReg) : null;
	const clientName = hasAccessToProject ? getClientName(timeReg) : null;
	const billable = hasAccessToProject
		? timeReg.billable
			? intl.formatMessage({id: 'common.yes'})
			: intl.formatMessage({id: 'common.no'})
		: null;
	const invoiced = hasAccessToProject
		? timeReg.invoiced
			? intl.formatMessage({id: 'common.yes'})
			: intl.formatMessage({id: 'common.no'})
		: null;
	const approvalStatus = hasAccessToProject
		? timeReg.approvalStatus === APPROVAL_STATUS.APPROVED
			? intl.formatMessage({id: 'common.yes'})
			: intl.formatMessage({id: 'common.no'})
		: null;
	const reportedTime = Util.convertMinutesToFullHour(timeReg.minutesRegistered, intl);
	const formattedPrice =
		hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE]) &&
		(timeReg.billable
			? intl.formatNumber(timeReg.priceBaseCurrency, {format: 'always_two_decimal'})
			: intl.formatNumber(0, {format: 'always_two_decimal'}));
	const formattedCost =
		hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE]) &&
		intl.formatNumber(timeReg.cost, {format: 'always_two_decimal'});
	if (
		!(
			enabledColumns.hasOwnProperty('timeRegType') ||
			enabledColumns.hasOwnProperty('timeRegDetail') ||
			enabledColumns.hasOwnProperty('taskName')
		)
	) {
		enabledColumnsCopy.needingSpace = true;
	}
	const customFields = Util.hasCustomFields()
		? timeReg.customFieldValues.edges.map(edge => edge.node).sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
		: [];

	return [
		<Table.Column>
			<Table.Column.Cell title={timeReg.idleTime ? project : null}>{project}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={task}>{task}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>{phaseName}</Table.Column>,
		<Table.Column>{reportedTime}</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={timeReg.notes}>{timeReg.notes}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>{date}</Table.Column>,
		<Table.Column>
			{<HexagonText text={timeReg.person.fullName} imgUrl={profilePicSrc(timeReg.person.profilePictureId)} />}
		</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={roleName}>{roleName}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>
			<Table.Column.Cell title={clientName}>{clientName}</Table.Column.Cell>
		</Table.Column>,
		<Table.Column>{billable}</Table.Column>,
		<Table.Column>
			{hasSomePermission([
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
			]) && Util.GetFormattedCurrencySymbol(currency, formattedPrice)}
		</Table.Column>,
		<Table.Column>
			{hasSomePermission([
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
				PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
			]) && Util.GetFormattedCurrencySymbol(currency, formattedCost)}
		</Table.Column>,
		<Table.Column>{invoiced}</Table.Column>,
		<Table.Column>{approvalStatus}</Table.Column>,
		...customFields.map(customField => (
			<Table.Column key={timeReg.id + '_' + customField.key}>
				<Table.Column.Cell title={customField.value}>{customField.value}</Table.Column.Cell>
			</Table.Column>
		)),
	];
};
