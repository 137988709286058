import React, {useState, useEffect} from 'react';
import {injectIntl} from 'react-intl';
import {NumericTilesContainerStyle, NumericTilesStyle, PopupContainer} from './numeric_tiles_styled';
import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';
import Util from '../../../../forecast-app/shared/util/util';

const NumericTiles = ({id, intl, valueInMinutes, infoText, hasError, customClassName, asterisk, timeRegPopup, cy}) => {
	const formattedValue = Util.convertMinutesToFullHour(valueInMinutes, intl);
	const [currentInputValue, setCurrentInputValue] = useState(formattedValue);

	useEffect(() => {
		const formattedValue = Util.convertMinutesToFullHour(valueInMinutes, intl);
		setCurrentInputValue(formattedValue);
	}, [valueInMinutes]);

	const inputState = hasError ? ' error' : '';

	return (
		<NumericTilesContainerStyle className={customClassName}>
			<TooltipContainer tooltipInfinteDuration={true} infoText={infoText}>
				{timeRegPopup ? <PopupContainer>{timeRegPopup}</PopupContainer> : null}
				<NumericTilesStyle
					id={id}
					className={inputState + (valueInMinutes < 0 ? ' negative' : 'null')}
					data-cy={cy + '-value'}
				>
					{currentInputValue}
					{asterisk ? '*' : ''}
				</NumericTilesStyle>
			</TooltipContainer>
		</NumericTilesContainerStyle>
	);
};

export default injectIntl(NumericTiles);
