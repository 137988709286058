import {
	createPersonProjectGroups,
	getCanvasTimelineDateFromMoment,
	setInitialExpansion,
} from '../canvas-timeline/canvas_timeline_util';
import PlaceholderAllocationItem from '../components/items/placeholder_allocation_item';
import CapacityPlaceholderGroup from '../components/groups/capacity_placeholder_group';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import NonProjectTimeGroup from '../components/groups/non_project_time_group';
import {CAPACITY_OVERVIEW_FILTER_STORAGE_KEY} from './CanvasCapacityOverviewConstants';
import CapacityEntityGroupingGroup from '../components/groups/capacity_entity_grouping_group';
import Moment from 'moment';
import {getFilterFunctions} from '../../../forecast-app/shared/components/filters/filter_logic';
import TimelineGraphGroup from '../components/groups/timeline_graph_group';
import {updateAllocationItemsVisibility} from '../components/allocation_controls/AllocationControlsCanvasUtils';
import DataManager from '../DataManager';
import NoContentGroup from '../components/groups/no_content_group';
import PersonGroup from '../components/groups/person_group';
import ProjectGroup from '../components/groups/project_group';
import PersonGroupingGroup from '../components/groups/PersonGroupingGroup';
import IDManager, {ROLE_GROUPING_SUB_GROUP_TYPE} from '../IDManager';
import ComposeManager from '../ComposeManager';
import {generateNoAccessHeatmap} from '../heatmap/AnonymizedHeatmapLogic';

export const getPlaceholderIdFromGroup = group => {
	return group.id;
};

export const getPersonIdFromPersonGroup = personGroup => {
	return personGroup.id;
};

export const getProjectIdFromGroup = group => {
	const ids = group.id.split('-');
	return ids[1];
};

export const createGroupsAndItems = pageComponent => {
	const {props} = pageComponent;
	const {eyeOptions} = pageComponent.state;
	const {formatMessage} = props.intl;
	const data = pageComponent.getData();

	const groups = [];
	const items = [];

	// graph
	const showGraph = eyeOptions.find(option => option.name === 'show-graph')?.checked;
	if (hasFeatureFlag('placeholders') && showGraph) {
		const timelineGraphData = ComposeManager.composeTimelineGraph(pageComponent);

		if (timelineGraphData) {
			groups.push(new TimelineGraphGroup(pageComponent, timelineGraphData));
		}
	}

	// ROLE GROUPING GROUPS
	const roles = data.roles.filter(
		role =>
			data.placeholders.find(placeholder => placeholder.roleId === role.id) ||
			data.persons.find(person => person.roleId === role.id)
	);

	roles.push({
		id: undefined,
		name: formatMessage({id: 'common.no_role'}),
	});

	roles.forEach(role => {
		// PLACEHOLDER GROUPS
		const placeholderGroups = [];
		const placeholdersWithRole = data.placeholders.filter(placeholder => placeholder.roleId === role.id);
		placeholdersWithRole.forEach(placeholder => {
			const placeholderAllocations = data.placeholderAllocationsByPlaceholder[placeholder.id];
			const hasAllocations = placeholderAllocations?.length;

			// create placeholder allocation items
			if (hasAllocations) {
				placeholderAllocations.forEach(placeholderAllocation => {
					const allocationItem = ComposeManager.composePlaceholderAllocation(pageComponent, placeholderAllocation);

					if (allocationItem) {
						items.push(new PlaceholderAllocationItem(pageComponent, allocationItem));
					}
				});
			}

			const project = placeholder.projectId ? DataManager.getProjectById(pageComponent, placeholder.projectId) : null;
			const projectGroup = placeholder.projectGroupId
				? DataManager.getProjectGroupById(pageComponent, placeholder.projectGroupId)
				: null;

			// create placeholder project group
			const placeholderProjectGroup = new ProjectGroup(
				pageComponent,
				ComposeManager.composeProjectGroup(pageComponent, null, placeholder, project, projectGroup)
			);
			const projectGroups = [placeholderProjectGroup];
			placeholderGroups.push(
				new CapacityPlaceholderGroup(
					pageComponent,
					ComposeManager.composeCapacityPlaceholderGroup(pageComponent, placeholder, projectGroups)
				)
			);
		});

		// PERSON GROUPS
		const personGroups = [];
		const personsWithRole = data.persons.filter(person => person.roleId === role.id);
		personsWithRole.forEach(person => {
			// exclude clients
			if (!person.clientId) {
				const allocations = data.personAllocationMap[person.id];

				// creating allocation items
				if (allocations) {
					allocations.forEach(allocation => {
						const projectAllocationItemData = ComposeManager.composeProjectAllocation(pageComponent, allocation);

						if (projectAllocationItemData) {
							items.push(new ProjectAllocationItem(pageComponent, projectAllocationItemData));
						}
					});
				}

				// get projects and non-project items related to person
				const nonProjectTimeItemExists = items.find(
					item => item.groupId === IDManager.getNonProjectTimeGroupId(pageComponent, person.id)
				);

				// create project groups
				const projectGroups = createPersonProjectGroups(pageComponent, person);

				// create non project time group (internal / time off allocations)
				if (nonProjectTimeItemExists) {
					projectGroups.push(
						new NonProjectTimeGroup(pageComponent, ComposeManager.composeNonProjectTimeGroup(pageComponent, person))
					);
				}

				// add no current projects group (only visible when none visible)

				projectGroups.push(
					new NoContentGroup(pageComponent, ComposeManager.composeNoContentGroup(pageComponent, person, null, null))
				);

				const personGroup = new PersonGroup(
					pageComponent,
					ComposeManager.composePersonGroup(pageComponent, person, null, projectGroups)
				);

				personGroups.push(personGroup);
			}
		});

		// add persons to person grouping
		const personGroupingGroup = new CapacityEntityGroupingGroup(
			pageComponent,
			ComposeManager.composeRoleGroupingGroup(
				pageComponent,
				role,
				personGroups,
				ROLE_GROUPING_SUB_GROUP_TYPE.PEOPLE_REMAINING
			)
		);

		// add placeholders to placeholder grouping
		const placeholderGroupingGroup = new CapacityEntityGroupingGroup(
			pageComponent,
			ComposeManager.composeRoleGroupingGroup(
				pageComponent,
				role,
				placeholderGroups,
				ROLE_GROUPING_SUB_GROUP_TYPE.PLACEHOLDERS_DEMAND
			)
		);

		// add groupings to new role group
		const roleGroups = [personGroupingGroup, placeholderGroupingGroup];
		const roleGroup = new PersonGroupingGroup(
			pageComponent,
			ComposeManager.composeRoleGroupingGroup(pageComponent, role, roleGroups)
		);

		// add to groups
		groups.push(roleGroup);
	});

	return {
		groups,
		items,
	};
};

export const initializeData = (pageComponent, data, isResetState) => {
	if (!data) return;

	if (!isResetState) {
		// convert person start and end dates to canvas timeline dates
		// OBS: required for heatmap calculations
		data.persons.forEach(person => {
			person.startDate = person.startDate
				? getCanvasTimelineDateFromMoment(Moment.utc(person.startDate, 'YYYY-MM-DD'))
				: null;
			person.endDate = person.endDate ? getCanvasTimelineDateFromMoment(Moment.utc(person.endDate, 'YYYY-MM-DD')) : null;
		});
	}

	DataManager.setLookupMaps(pageComponent, data);

	pageComponent.setState(
		{
			data,
			groups: [],
			items: [],
		},
		() => {
			const {viewer} = data;

			if (!hasFeatureFlag('inverted_pto_non_working_days')) {
				DataManager.constructNonWorkingDaysMap(pageComponent);
			}

			generateNoAccessHeatmap(pageComponent);

			const {groups, items} = createGroupsAndItems(pageComponent);

			setInitialExpansion(pageComponent, groups);

			const localStorageFilters = localStorage.getItem(CAPACITY_OVERVIEW_FILTER_STORAGE_KEY);

			const filters = localStorageFilters
				? JSON.parse(localStorageFilters)
				: {
						person: {},
						project: {},
						placeholder: {},
				  };

			if (localStorageFilters === 'undefined') {
				localStorage.removeItem(CAPACITY_OVERVIEW_FILTER_STORAGE_KEY);
			}

			const filterFunctions = getFilterFunctions(filters);

			let allDataLoaded = false;
			if (!isResetState) {
				allDataLoaded = pageComponent.state.allDataLoading;
			}

			pageComponent.setState(
				{
					viewer,
					groups,
					items,
					filters,
					filterFunctions,
					initialDataLoaded: true,
					allDataLoaded,
				},
				() => {
					if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
						DataManager.constructVisibleItemTrees(pageComponent);
					}
					updateAllocationItemsVisibility(pageComponent);
					pageComponent.redrawCanvasTimeline({preventFiltering: false, isInitialLoad: true});
				}
			);
		}
	);
};
