import React from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown, HexagonText} from 'web-components';
import {getDefaultSmallClientLogo} from '../../default_avatars';
import {clientLogoSrc} from '../../../directApi';
export const ClientDropdown = ({
	clients,
	emphasizeEmptyState,
	dropdownAlignment,
	width,
	name,
	selectedItems,
	onRemove,
	onSelect,
	optionsName,
	selectedGroupName,
	userpilot,
	isClearable,
	customPlaceholder,
	isNested,
	isMultiSelect,
	headerLines,
	cy,
}) => {
	const sortedClients = [...clients].sort((a, b) => {
		if (a.node.id === null) return -1;
		if (b.node.id === null) return 1;
		if (a.node.name.toLowerCase() < b.node.name.toLowerCase()) return -1;
		if (a.node.name.toLowerCase() > b.node.name.toLowerCase()) return 1;
		return 0;
	});
	return (
		<Dropdown
			isNested={isNested}
			isMultiSelect={isMultiSelect}
			headerLines={headerLines}
			onSelect={onSelect}
			onRemove={onRemove}
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
			isClearable={isClearable}
			customPlaceholder={customPlaceholder}
			cy={cy}
		>
			<Dropdown.Group name={optionsName} key={'client-group'}>
				{sortedClients.map(edge => {
					return (
						<HexagonText
							key={edge.node.id}
							value={edge.node.id}
							searchString={edge.node.name}
							text={edge.node.name}
							imgUrl={edge.node.logoId ? clientLogoSrc(edge.node.logoId) : getDefaultSmallClientLogo(0)}
						/>
					);
				})}
			</Dropdown.Group>
		</Dropdown>
	);
};

ClientDropdown.propTypes = {
	clients: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				logoId: PropTypes.string,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	headerLines: PropTypes.element,
};

ClientDropdown.defaultProps = {
	optionsName: 'Client',
	name: 'Clients',
	selectedGroupName: 'Selected',
	onSelect: () => false,
	onRemove: () => false,
	isNested: true,
	isMultiSelect: true,
};

export default createFragmentContainer(ClientDropdown, {
	clients: graphql`
		fragment ClientDropdown_clients on ClientTypeEdge @relay(plural: true) {
			node {
				id
				name
				logoId
			}
		}
	`,
});
