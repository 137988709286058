import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {BUTTON_COLOR, BUTTON_STYLE} from '../constants';
import {EVENT_ID, subscribe, unsubscribe} from '../containers/event_manager';
import Button from '../forecast-app/shared/components/buttons/button/button';
import PropTypes from 'prop-types';
import TooltipContainer from '../forecast-app/shared/components/tooltips/tooltip_container';

const ButtonRow = styled.div`
	display: flex;
	justify-content: center;
	height: 30px;
	white-space: nowrap;
	.button-wrapper {
		margin-left: 8px;
		position: relative;
	}
`;

const EmptyMessage = styled.div`
	font-size: 12px;
	color: #535353;
	padding: 10px 16px;
	font-weight: 500;
	position: absolute;
	border-radius: 4px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
	border: solid 1px #dbdbdb;
	background-color: #ffffff;
	z-index: 10;
	top: 35px;
`;

const ButtonToggleGroup = ({intl: {formatMessage}, defaultOptions, selectedSwitch, onSwitchSelection, groupLocked}) => {
	const [_selectedButton, setSelectedButton] = useState(selectedSwitch);
	const [showMessage, setShowMessage] = useState(false);
	const [nothingReturned, setNothingReturned] = useState('');
	const handleButtonChange = buttonType => {
		setSelectedButton(_selectedButton === buttonType ? null : buttonType);
		onSwitchSelection(_selectedButton === buttonType ? null : buttonType);
	};

	const resetButtons = useCallback(() => {
		setSelectedButton(null);
	});

	let timeout;
	let showMessageTimeout;
	const emptyState = key => {
		clearTimeout(timeout);

		timeout = setTimeout(() => {
			setNothingReturned(key);
			if (key !== '') {
				clearTimeout(showMessageTimeout);
				setShowMessage(true);
				showMessageTimeout = setTimeout(() => setShowMessage(false), 2000);
			}
		}, 500);
	};

	useEffect(() => {
		subscribe(EVENT_ID.RESET_SWITCH, resetButtons);
		subscribe(EVENT_ID.SWITCH_EMPTY, emptyState);
		return () => {
			unsubscribe(EVENT_ID.RESET_SWITCH, resetButtons);
			unsubscribe(EVENT_ID.SWITCH_EMPTY, emptyState);
		};
	}, []);

	useEffect(() => {
		setSelectedButton(selectedSwitch);
	}, [selectedSwitch]);

	/**
	 * set button style depending on if it is selected
	 * @param {*} buttonKey
	 * @returns {string} BUTTON_STYLE
	 */
	const getButtonStyle = buttonKey => (_selectedButton === buttonKey ? BUTTON_STYLE.FILLED : BUTTON_STYLE.OUTLINE_THICK);

	/**
	 * set button color depending on if it is selected
	 * @param {*} buttonKey
	 * @returns {string} BUTTON_COLOR
	 */
	const getButtonFill = buttonKey => (_selectedButton === buttonKey ? BUTTON_COLOR.PURPLE : BUTTON_COLOR.VERYLIGHTGREY);

	return (
		<ButtonRow>
			<>
				{Object.entries(defaultOptions).map(([key, switchType]) => {
					return (
						<div className={'button-wrapper'} key={key}>
							<TooltipContainer
								customMaxWidth={1000}
								triangleLocation={'topMiddle'}
								infoText={formatMessage({id: switchType.infoMessageId})}
								tooltipInfinteDuration={true}
							>
								<Button
									id={switchType.id}
									text={formatMessage({id: switchType.messageId})}
									buttonStyle={getButtonStyle(key)}
									colorTheme={getButtonFill(key)}
									onClick={handleButtonChange.bind(this, key)}
									isDisabled={groupLocked}
									cy={'switch-button' + switchType.cy}
									userpilot={'timesheets-switch-button' + switchType.cy}
								/>
							</TooltipContainer>
							{showMessage && nothingReturned === key ? (
								<EmptyMessage className={'animated faster fadeInDown'} data-cy={'switch-empty-state'}>
									<div>{formatMessage({id: switchType.nothingReturnedMessageId})}</div>
								</EmptyMessage>
							) : null}
						</div>
					);
				})}
			</>
		</ButtonRow>
	);
};

ButtonToggleGroup.propTypes = {
	intl: PropTypes.object.isRequired,
	defaultOptions: PropTypes.objectOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			messageId: PropTypes.string.isRequired,
			nothingReturnedMessageId: PropTypes.string.isRequred,
		})
	).isRequired,
	groupLocked: PropTypes.bool.isRequired,
	selectedSwitch: PropTypes.string,
	onSwitchSelection: PropTypes.func.isRequired,
};

export default ButtonToggleGroup;
