import util from '../../../forecast-app/shared/util/util';
import {getFilterFunctions} from '../../../forecast-app/shared/components/filters/filter_logic';

export const defaultFilter = hasBaseline => {
	const filter = {
		project: {
			projectStage: ['PLANNING', 'RUNNING'],
		},
	};
	if (hasBaseline) {
		filter.project.projectStage.push('OPPORTUNITY');
	}
	return filter;
};

export const getInitialFilters = hasBaseline => {
	const locallySavedFilters = localStorage.getItem('financial-budget-report-filters');
	if (locallySavedFilters) {
		return JSON.parse(locallySavedFilters);
	} else {
		return defaultFilter(hasBaseline);
	}
};

export const handleChangedFilters = (changedFilters, savedReport) => {
	!savedReport && util.localStorageSetItem('financial-budget-report-filters', JSON.stringify(changedFilters));
	return Object.assign({}, changedFilters);
};

/**
 * Almost a straight copy of the logic on the overview_projects.js page. This is not ideal, but the solution is
 * to create a real self-contained filter components that handles everything including loading of projects, filtering etc.
 */
export const projectFilter = (project, filters, calculatedDataMap) => {
	if (filters) {
		const filterFunctions = getFilterFunctions(filters);
		if (filterFunctions) {
			// options used for the progress filter
			const options = {completion: calculatedDataMap.has(project.id) ? calculatedDataMap.get(project.id).completion : 0};
			return filterFunctions.projectFilter(project, options);
		}
	} else {
		return true;
	}
};
