import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import Person from '../../../forecast-app/shared/components/person/person';
import {SCHEDULING_VIEW} from '../../../constants';
import Util from '../../../forecast-app/shared/util/util';
import {TaskIcon, SubTaskIcon} from 'web-components/icons';
import {MONTH_NAMES_SHORT} from '../canvas-timeline/canvas_timeline_util';
import DataManager from '../DataManager';
import {profilePicSrc} from '../../../directApi';
import {getProjectIndicatorString} from '../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';

class TaskDetailBox extends Component {
	render() {
		const {formatMessage} = this.props.intl;
		const {task, globalData, pageComponent, positionX, positionY} = this.props;
		const {schedulingView} = pageComponent.props;
		const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;

		const {taskMap} = globalData;
		const parentTask = task.parentTaskId ? taskMap.get(task.parentTaskId) : null;
		const subTaskCount = DataManager.getSubTasksByParentTaskId(pageComponent, task.id)?.length;
		const project = task.projectId && globalData.projects.find(project => project.id === task.projectId);
		const client = project
			? project.clientId
				? globalData.clients.find(client => client.id === project.clientId)
				: null
			: null;
		const clientName = client?.name;
		const isInHours = project?.estimationUnit === 'HOURS';
		let remainingTimePerAssigned =
			task.timeLeft === 0 || task.assignedPersons.length === 0 ? 0 : task.timeLeft / task.assignedPersons.length;
		const estimate = task.estimateForecast / 60;
		const showRight = positionX + 200 < window.innerWidth;
		const showTop = positionY + 350 < window.innerHeight;

		if (isInHours) {
			remainingTimePerAssigned /= 60.0;
		}
		return (
			<>
				<div className={'white-triangle ' + (showRight ? 'right ' : 'left ') + (showTop ? 'top' : 'bottom')} />
				<div className="detail-box-inner-container detail-box-task-container">
					<div className="detail-box-task-item detail-box-inner-headline">
						<div className="detail-box-inner-headline-left">
							{parentTask ? (
								<>
									<div className="task-icon">
										<SubTaskIcon />
									</div>
									{formatMessage({id: 'common.sub_task'})}
								</>
							) : (
								<>
									<div className="task-icon">
										<TaskIcon />
									</div>
									{formatMessage({id: 'common.task'})}
								</>
							)}
						</div>
						{subTaskCount ? (
							<div className="detail-box-inner-headline-right">
								<SubTaskIcon />
								{subTaskCount}
							</div>
						) : (
							<></>
						)}
					</div>
					<div className="detail-box-task-item detail-box-task-name">
						<div className="label">{formatMessage({id: 'common.task_name'})}</div>
						<div className="content">{`T${task.companyTaskId}${task.name ? ' - ' + task.name : ''}`}</div>
					</div>
					{parentTask ? (
						<div className="detail-box-task-item detail-box-task-name">
							<div className="label">{formatMessage({id: 'common.parent_task_name'})}</div>
							<div className="content">{`T${parentTask.companyTaskId}${
								parentTask.name ? ' - ' + parentTask.name : ''
							}`}</div>
						</div>
					) : (
						<></>
					)}

					{clientName ? (
						<div className="detail-box-task-item">
							<div className="label">{formatMessage({id: 'common.client'})}</div>
							<div className="content">{clientName}</div>
						</div>
					) : null}
					{isPeopleScheduling && project ? (
						<div className="detail-box-task-item detail-box-task-project">
							<div className="label">{formatMessage({id: 'common.project'})}</div>
							<div className={'content'}>{`${getProjectIndicatorString(
								project.companyProjectId,
								project.customProjectId
							)} - ${project.name}`}</div>
						</div>
					) : null}
					<div className="detail-box-task-item detail-box-task-dates">
						<div className="label">{formatMessage({id: 'common.date'})}</div>
						<div className={'content'}>{`${task.startDay} ${formatMessage({
							id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[task.startMonth - 1],
						})} ${task.startYear} - ${task.deadlineDay} ${formatMessage({
							id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[task.deadlineMonth - 1],
						})} ${task.deadlineYear}`}</div>
					</div>
					{estimate !== 0 ? (
						<div className="detail-box-task-item detail-box-task-estimate">
							<div className="label">{formatMessage({id: 'common.total_estimate'})}</div>
							<div className={'content'}>
								{isInHours
									? Util.convertMinutesToFullHour(estimate * 60, this.props.intl, true)
									: formatMessage({id: 'common.x_points'}, {points: estimate})}
							</div>
						</div>
					) : null}
					{remainingTimePerAssigned ? (
						<div className="detail-box-task-item detail-box-task-remaining-time-per-assigned">
							<div className="label">{formatMessage({id: 'task_detail_box.remaining_per_assignee'})}</div>
							<div className="content">
								{isInHours
									? Util.convertMinutesToFullHour(remainingTimePerAssigned * 60, this.props.intl, true)
									: formatMessage({id: 'common.x_points'}, {points: remainingTimePerAssigned})}
							</div>
						</div>
					) : null}
					{task.assignedPersons.length > 0 ? (
						<div className="detail-box-task-item">
							<div className="label">{formatMessage({id: 'task_detail_box.assigned_persons'})}</div>
							<div className="content detail-box-assigned-person-container">
								{task.assignedPersons
									.map(assignedPersonId => globalData.persons.find(person => person.id === assignedPersonId))
									.map(person => {
										return person ? (
											<Person
												key={person.id}
												name={`${person.firstName} ${person.lastName}`}
												showName={false}
												showRole={false}
												imageSize={'medium'}
												imageSrc={profilePicSrc(person.profilePictureId)}
											/>
										) : null;
									})}
							</div>
						</div>
					) : null}
				</div>
			</>
		);
	}
}

TaskDetailBox.propTypes = {
	task: PropTypes.object.isRequired,
	globalData: PropTypes.object.isRequired,
	pageComponent: PropTypes.object.isRequired,
};

export default injectIntl(TaskDetailBox);
