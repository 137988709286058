import React from 'react';
import {FormattedHTMLMessage, injectIntl} from 'react-intl';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import Util from '../../../../forecast-app/shared/util/util';
import ExpandIcon from '../../../../images/v2/components/dropdown/expand_icon';
import Person from '../../../../forecast-app/shared/components/person/person';
import {BUDGET_TYPE} from '../../../../constants';
import {ProjectIndicator} from '../../../../forecast-app/shared/components/project-indicator/project_group_indicator';
import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';
import InformationIcon from '../../../../images/information-icon';
import LabelGroup from '../../../../forecast-app/shared/components/labels/label_group';
import {profilePicSrc} from '../../../../directApi';

const ValueCellStyles = styled.div`
	white-space: nowrap;

	&.projectId {
		width: 60px;
		text-align: center;
		display: flex;
		flex-direction: row;
		align-items: center;

		.spacer {
			background-color: #fff;
			border: none;
			width: 7px;
			height: 8px;
			border-left: solid 2px #a1a1a1;
			border-bottom: solid 2px #a1a1a1;
			margin-right: 3px;
		}
	}

	.status-color-wrapper {
		display: flex;
		justify-content: center;

		.status-color {
			border-radius: 50%;
			width: 12px;
			height: 12px;
		}
	}

	&.projectContact {
		display: flex;
		flex-direction: row;

		.forecast-person {
			.image-container {
				position: static;
			}
		}

		.testtest {
			margin-left: 8px;
		}

		.testtest:first-child {
			margin-left: 0;
		}
	}
`;

export const ROW_TYPE = {
	PROJECT: 'PROJECT',
	PROJECT_GROUP: 'PROJECT_GROUP',
	PHASE: 'PHASE',
	NO_PHASE: 'NO_PHASE',
	PROJECT_TIME_REGS: 'PROJECT_TIME_REGS',
	ROLE: 'ROLE',
	EXPENSE: 'EXPENSE',
};

export const ValueCell = injectIntl(
	({type, value, currencySymbol, intl, project, expanded, canExpand, rowType, baselineTooltip}) => {
		switch (type) {
			case 'price':
				return (
					<ValueCellStyles>
						{value !== undefined && value !== null ? (
							baselineTooltip ? (
								<TooltipContainer
									translatedMessage={true}
									infoText={baselineTooltip}
									tooltipDuration={5 * 60 * 1000}
								>
									{Util.getFormattedCurrencyValue(
										currencySymbol,
										intl.formatNumber(Math.round(value), {
											minimumFractionDigits: 0,
											maximumFractionDigits: 0,
										})
									) + '*'}
								</TooltipContainer>
							) : (
								Util.getFormattedCurrencyValue(
									currencySymbol,
									intl.formatNumber(Math.round(value), {minimumFractionDigits: 0, maximumFractionDigits: 0})
								)
							)
						) : null}
					</ValueCellStyles>
				);
			case 'percentage':
				return (
					<ValueCellStyles>
						{value !== undefined && value !== null ? Util.getFormattedPercentage(value, intl, 0) : null}
					</ValueCellStyles>
				);
			case 'progress':
				return (
					<ValueCellStyles>
						{value !== undefined && value !== null ? (
							Util.getFormattedPercentage(value, intl, 0)
						) : rowType === ROW_TYPE.PROJECT || rowType === ROW_TYPE.PHASE || rowType === ROW_TYPE.NO_PHASE ? (
							<TooltipContainer
								infoText={
									<FormattedHTMLMessage id="project_budget.period_progress_data_start_date_description" />
								}
								tooltipDuration={5 * 60 * 1000}
							>
								<InformationIcon />
							</TooltipContainer>
						) : null}
					</ValueCellStyles>
				);
			case 'time':
				return (
					<ValueCellStyles>
						{value !== undefined ? Util.convertMinutesToFullHour(value * 60, intl) : null}
					</ValueCellStyles>
				);
			case 'client':
				return <ValueCellStyles>{value && value.name}</ValueCellStyles>;
			case 'projectId':
				return (
					<ValueCellStyles
						onClick={e => {
							e.stopPropagation();
						}}
						className={`${type}`}
					>
						{value ? (
							project.isConnectedProject ? (
								<TooltipContainer infoText={intl.formatMessage({id: 'common.go_to_project'})}>
									<ProjectIndicator project={value} clearSubPath={true} noTitle={true} />
								</TooltipContainer>
							) : (
								<>
									{project.details.isInProjectGroup ? <div className="spacer" /> : null}
									<TooltipContainer infoText={intl.formatMessage({id: 'common.go_to_project'})}>
										<ProjectIndicator project={value} clearSubPath={true} noTitle={true} />
									</TooltipContainer>
								</>
							)
						) : null}
					</ValueCellStyles>
				);
			case 'name':
				return <ValueCellStyles title={value}>{value}</ValueCellStyles>;
			case 'dates':
				let dateString = '';
				if (value) {
					if (value.startDate) {
						dateString = `${intl.formatDate(value.startDate.toDate(), {
							year: 'numeric',
							month: 'short',
							day: '2-digit',
						})} - `;
					}
					if (value.endDate) {
						dateString += intl.formatDate(value.endDate.toDate(), {
							year: 'numeric',
							month: 'short',
							day: '2-digit',
						});
					}
				}
				return <ValueCellStyles>{dateString}</ValueCellStyles>;
			case 'expandCollapse':
				return (
					<ValueCellStyles>
						{canExpand ? <ExpandIcon expanded={expanded} useDarkIcon={true} /> : null}
					</ValueCellStyles>
				);
			case 'labels':
				return (
					<ValueCellStyles>
						<LabelGroup
							labels={value ? value.edges.map(entry => entry.node) : []}
							noWrap={false}
							lowZIndex={true}
						/>
					</ValueCellStyles>
				);
			case 'budgetType':
				let label = '';
				if (value) {
					const {budgetType, defaultPeriodBudgetType} = value;
					label = Util.getBudgetTypeTranslation(budgetType, intl);
					if (budgetType === BUDGET_TYPE.RETAINER && defaultPeriodBudgetType) {
						label += ` (${Util.getPeriodBudgetTypeTranslation(defaultPeriodBudgetType, intl)})`;
					}
				}
				return <ValueCellStyles>{label}</ValueCellStyles>;
			case 'status':
				return (
					<ValueCellStyles>
						{value ? (
							<div className="status-color-wrapper">
								<div className="status-color" style={{backgroundColor: value.color}} />
							</div>
						) : null}
					</ValueCellStyles>
				);
			case 'stage':
				return <ValueCellStyles>{Util.getProjectStageTranslation(value, intl)}</ValueCellStyles>;
			case 'projectContact':
				let contactPersons = [];
				if (value && value.edges) {
					contactPersons = value.edges
						.map(edge => edge.node)
						.filter(projectPerson => projectPerson.isContactPerson)
						.map(projectPerson => projectPerson.person);
				}

				return (
					<ValueCellStyles className="projectContact">
						{contactPersons.map((contactPerson, index) => (
							<TooltipContainer
								key={index}
								translatedMessage={true}
								infoText={contactPerson.fullName}
								timer={5 * 60 * 1000}
							>
								<Person
									name={contactPerson.fullName}
									imageSrc={profilePicSrc(contactPerson.profilePictureId)}
									showName={contactPersons.length === 1}
									showRole={false}
									hideTitle={true}
								/>
							</TooltipContainer>
						))}
					</ValueCellStyles>
				);
			case 'rateCard':
				return <ValueCellStyles>{value && value.name}</ValueCellStyles>;
			default:
				return <ValueCellStyles>{value}</ValueCellStyles>;
		}
	}
);

ValueCell.propTypes = {
	type: PropTypes.string.isRequired,
	value: PropTypes.any,
	currencySymbol: PropTypes.string,
	expanded: PropTypes.bool,
	canExpand: PropTypes.bool,
	rowType: PropTypes.oneOf(Object.keys(ROW_TYPE)),
};
