/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type burnUpComponent_viewer$ref: FragmentReference;
declare export opaque type burnUpComponent_viewer$fragmentType: burnUpComponent_viewer$ref;
export type burnUpComponent_viewer = {|
  +insightComponentsData: ?{|
    +burnUp: ?{|
      +useHours: ?boolean,
      +data: ?$ReadOnlyArray<?{|
        +dateString: ?string,
        +scope: ?number,
        +velocity: ?number,
      |}>,
    |}
  |},
  +$refType: burnUpComponent_viewer$ref,
|};
export type burnUpComponent_viewer$data = burnUpComponent_viewer;
export type burnUpComponent_viewer$key = {
  +$data?: burnUpComponent_viewer$data,
  +$fragmentRefs: burnUpComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProjectGroupType",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "burnUpComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "isProjectGroupType",
              "variableName": "isProjectGroupType"
            },
            {
              "kind": "Variable",
              "name": "projectId",
              "variableName": "projectId"
            }
          ],
          "concreteType": "BurnUp",
          "kind": "LinkedField",
          "name": "burnUp",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "useHours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "BurnUpElement",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dateString",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "scope",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "velocity",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '815525cc606d3f78e579affd0ae09344';

module.exports = node;
