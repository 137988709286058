import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {NativeTypes} from 'react-dnd-html5-backend';
import {DropTarget} from 'react-dnd';
import {MODAL_TYPE, showModal} from '../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_STYLE, BUTTON_COLOR} from '../constants';

const fileTarget = {
	drop(props, monitor) {
		if (!props.locked) {
			const files = monitor.getItem().files;

			let checkFiles = () => {
				let p = new Promise((resolve, reject) => {
					let fileslength = files.length;
					monitor.getItem().files.forEach((file, index) => {
						let reader = new FileReader();
						reader.onload = result => {
							if (index === fileslength - 1) {
								return resolve(true);
							}
						};
						reader.onerror = result => {
							return reject(result);
						};
						reader.readAsText(file);
					});
				})
					.then(data => {
						if (data === true) {
							props.uploadFiles(files);
						}
					})
					.catch(err => {
						showModal({
							type: MODAL_TYPE.WARNING,
							warningMessageId: 'common.invalid_action_modal_title',
							warningInformation: [props.folderExceptionMsg],
							buttons: [
								{
									text: props.commonOkMsg,
									style: BUTTON_STYLE.FILLED,
									color: BUTTON_COLOR.WHITE,
								},
							],
						});
					});
				return p;
			};
			checkFiles();
		}
	},
};

class FileDropZone extends Component {
	onButtonUpload() {
		let file = this._file.files.item(0);
		this.props.uploadFiles(file);
	}

	showChooseFileWindow() {
		if (this._file && document.createEvent) {
			var evt = document.createEvent('MouseEvents');
			evt.initEvent('click', true, false);
			this._file.dispatchEvent(evt);
		}
	}

	render() {
		const {connectDropTarget, isOver, locked} = this.props;

		return connectDropTarget(
			<div className={isOver && !locked ? 'new-file-over' : 'new-file' + (locked ? ' locked' : '')}>
				<span className="info">
					<div>Drop files here</div>
					<div>or</div>
				</span>
				<div className={this.props.isUploading ? 'upload-overlay' : 'upload-overlay-hidden'}>
					<div className={this.props.isUploading ? 'uploading-gif' : 'uploading-gif-hidden'}> </div>
				</div>
				<span
					className="file-link"
					disabled={(this.props.taskId === null && !this.props.isExpenseItemDropZone) || this.props.locked}
				>
					<input
						type="file"
						onClick={e => {
							e.target.value = null;
						}}
						disabled={(this.props.taskId === null && !this.props.isExpenseItemDropZone) || this.props.locked}
						onChange={this.onButtonUpload.bind(this)}
						ref={input => (this._file = input)}
					/>
					<span onClick={this.showChooseFileWindow.bind(this)}>click to upload</span>
				</span>
			</div>
		);
	}
}

FileDropZone.propTypes = {
	taskId: PropTypes.string,
	isUploading: PropTypes.bool.isRequired,
	uploadFiles: PropTypes.func.isRequired,
	commonOkMsg: PropTypes.string.isRequired,
	folderExceptionMsg: PropTypes.string.isRequired,
	locked: PropTypes.bool,
	isExpenseItemDropZone: PropTypes.bool,
};

export default DropTarget(NativeTypes.FILE, fileTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	canDrop: monitor.canDrop(),
}))(FileDropZone);
