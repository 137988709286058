import {ESTIMATION_UNIT, PROJECT_STATUS} from '../../../constants';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import Util from '../../../forecast-app/shared/util/util';
import {areItemDatesValid, createCanvasTimelineDate, GROUP_TYPE} from '../canvas-timeline/canvas_timeline_util';
import {PROJECTS_SCHEDULING_FILTER_STRING} from './ProjectsSchedulingConstants';
import DataManager, {LOOKUP_MAPS} from '../DataManager';
import {isTaskItem} from '../SchedulingUtils';

export const getFilterString = pageComponent => {
	const {projectId, groupId, programPrefix} = pageComponent.props;

	if (programPrefix) {
		return `${PROJECTS_SCHEDULING_FILTER_STRING}-projects-programPrefix-${programPrefix}`;
	}

	if (projectId) {
		return `${PROJECTS_SCHEDULING_FILTER_STRING}-projects-projectId-${projectId}`;
	}

	if (groupId) {
		return `${PROJECTS_SCHEDULING_FILTER_STRING}-projects-groupId-${groupId}`;
	}

	return `${PROJECTS_SCHEDULING_FILTER_STRING}-projects`;
};

export const addSubTaskToMap = (pageComponent, subTask) => {
	if (subTask.parentTaskId) {
		DataManager.addToGroupBy(
			DataManager.getLookupMap(pageComponent, LOOKUP_MAPS.PARENT_TASK_TO_SUB_TASK_MAP.KEY),
			subTask,
			LOOKUP_MAPS.PARENT_TASK_TO_SUB_TASK_MAP
		);
	}
};

export const getProjectGroups = groups => {
	const groupArray = [];
	for (const group of groups) {
		if (group.data.isConnectedProject || group.data.program) {
			for (const projectGroup of group.groups) {
				groupArray.push(projectGroup);
			}
		} else {
			groupArray.push(group);
		}
	}
	return groupArray;
};

export const getProgramCompletion = (pageComponent, programId) => {
	const {projects} = pageComponent.getFilterData();
	const programProjects = projects.filter(project => project.programId === programId);
	let completion = 0;
	if (programProjects?.length > 0) {
		completion = Math.round(
			programProjects.reduce((acc, project) => acc + project.completion, 0) / programProjects?.length
		);
	}
	return completion;
};

const calculateElementProgress = (project, tasks) => {
	let timeRegistered = 0;
	let remaining = 0;
	let forecast = 0;
	let doneTasksCount = 0;
	for (const task of tasks) {
		timeRegistered += task.totalMinutesRegistered || 0;
		forecast += task.estimateForecast;
		remaining += task.done ? 0 : task.timeLeft;
		if (task.done) {
			++doneTasksCount;
		}
	}

	return Util.calculateElementProgress(
		forecast,
		timeRegistered,
		remaining,
		tasks.length,
		doneTasksCount,
		project.estimationUnit === ESTIMATION_UNIT.HOURS,
		project.minutesPerEstimationPoint,
		false,
		false
	);
};

export const getPhaseCompletion = (pageComponent, phaseId) => {
	const data = pageComponent.getData();
	const phase = DataManager.getPhaseById(pageComponent, phaseId);
	const project = DataManager.getProjectById(pageComponent, phase.projectId);
	// Task lookup not using DataManager.getTasksByPhaseId, because we need to find sub-tasks too
	const tasks = data.tasks.filter(task => task.phaseId === phaseId);
	return calculateElementProgress(project, tasks);
};

export const getProjectCompletion = (pageComponent, projectId) => {
	const data = pageComponent.getData();
	const project = DataManager.getProjectById(pageComponent, projectId);
	const tasks = data.tasks.filter(task => task.projectId === projectId);
	return calculateElementProgress(project, tasks);
};

export const changeSubTaskDates = (pageComponent, taskItem) => {
	const {task} = taskItem.data;
	const subTasksIds = DataManager.getSubTasksByParentTaskId(pageComponent, task.id);

	if (subTasksIds?.length > 0) {
		const {items} = pageComponent.state;
		const subTaskIdSet = new Set(subTasksIds);

		for (const item of items) {
			if (isTaskItem(item)) {
				const {task} = item.data;

				if (subTaskIdSet.has(task.id) && !!task.startFrom) {
					// Used for drawing item
					item.startDate = taskItem.startDate;
					item.endDate = taskItem.endDate;

					// Used for item tooltip
					item.data.task.startYear = taskItem.data.task.startYear;
					item.data.task.startMonth = taskItem.data.task.startMonth;
					item.data.task.startDay = taskItem.data.task.startDay;
					item.data.task.endYear = taskItem.data.task.endYear;
					item.data.task.endMonth = taskItem.data.task.endMonth;
					item.data.task.endDay = taskItem.data.task.endDay;

					changeSubTaskDates(pageComponent, item);
				}
			}
		}
	}
};

export const insertTaskInGroupTree = (groupToTraverse, subTaskGroup) => {
	if (groupToTraverse.groups) {
		groupToTraverse.groups.forEach(group => {
			if (group?.data?.task?.id === subTaskGroup.data.task.parentTaskId) {
				group.data.expandable = true;
				subTaskGroup.addSubTaskLevel(group.data.subTaskLevel + 1);
				group.addChildGroup(subTaskGroup);
			} else {
				insertTaskInGroupTree(group, subTaskGroup);
			}
		});
	}
};

export const findTaskGroupInTree = (groupToTraverse, taskId) => {
	let result;
	for (let i = 0; i < groupToTraverse.groups.length; i++) {
		const group = groupToTraverse.groups[i];
		if (group?.data?.task?.id === taskId) {
			return group;
		}
		const innerResult = findTaskGroupInTree(group, taskId);
		if (innerResult) {
			result = innerResult;
		}
	}
	return result;
};

export const getSubTasks = (pageComponent, task, isInheritingDate) => {
	const subTasks = [];

	const subTaskIds = DataManager.getSubTasksByParentTaskId(pageComponent, task.id);
	subTaskIds.forEach(taskId => {
		const subTask = DataManager.getTaskById(pageComponent, taskId);
		if (!!subTask?.startFrom) {
			subTasks.push(subTask);
		}
	});

	const result = [];
	subTasks.forEach(subTask => {
		result.push(...getSubTasks(pageComponent, subTask, isInheritingDate));
	});

	result.push(...subTasks);

	return result;
};

export const addDependenciesFromTasks = (tasks, dependencies) => {
	tasks.forEach(taskNode => {
		const task = taskNode.node;
		if (task.thisTaskDependsOn) {
			task.thisTaskDependsOn.edges.forEach(dep => {
				const thisTaskDependsOn = dep.node;

				const dependency = {
					completed: thisTaskDependsOn.completed,
					id: thisTaskDependsOn.id,
					taskIdDependsOnThis: task.id,
					thisDependsOnTaskId: thisTaskDependsOn.thisDependsOnTask.id,
					type: thisTaskDependsOn.type,
				};

				dependencies.push(dependency);
			});
		}
	});
};

export const updateTaskSummarizedProgress = (pageComponent, task) => {
	if (hasFeatureFlag('Timeline_progress_for_parent_tasks')) {
		let totalRegisteredMinutes = task.totalMinutesRegistered;
		let totalRemainingMinutes = task.timeLeft;

		const subTasks = getSubTasks(pageComponent, task);
		if (subTasks?.length > 0) {
			subTasks.forEach(subTask => {
				totalRegisteredMinutes += subTask.totalMinutesRegistered || 0;
				totalRemainingMinutes += subTask.timeLeft;
			});
		}

		let summarizedProgress = 0;

		if (totalRegisteredMinutes + totalRemainingMinutes !== 0) {
			summarizedProgress = Math.max(
				Math.round((totalRegisteredMinutes * 100) / (totalRegisteredMinutes + totalRemainingMinutes)),
				0
			);
		}

		task.summarizedProgress = summarizedProgress;

		if (task.parentTaskId) {
			const parentTask = DataManager.getTaskById(pageComponent, task.parentTaskId);

			if (parentTask) {
				updateTaskSummarizedProgress(pageComponent, parentTask);
			}
		}
	}
};

export const getTopParentId = (taskMap, task) => {
	if (!task.parentTaskId) {
		return task.id;
	} else {
		return getTopParentId(taskMap, taskMap.get(task.parentTaskId));
	}
};

export const isTaskInHierarchy = (pageComponent, task) => {
	const hasSubTasks = DataManager.getSubTasksByParentTaskId(pageComponent, task.id)?.length > 0;
	return !!(task && (task.parentTaskId || hasSubTasks));
};

export const getProjectIdFromProjectGroup = group => {
	return group.id;
};

export const sortTaskGroupsByDate = taskGroups => {
	taskGroups.sort((a, b) => {
		// order tasks
		if (a.groupType !== GROUP_TYPE.TASK || b.groupType !== GROUP_TYPE.TASK) return 0;
		const aTask = a.data.task;
		const bTask = b.data.task;
		const aEndDate = createCanvasTimelineDate(aTask.deadlineYear, aTask.deadlineMonth, aTask.deadlineDay);
		const bEndDate = createCanvasTimelineDate(bTask.deadlineYear, bTask.deadlineMonth, bTask.deadlineDay);
		const aStartDate = createCanvasTimelineDate(aTask.startYear, aTask.startMonth, aTask.startDay);
		const bStartDate = createCanvasTimelineDate(bTask.startYear, bTask.startMonth, bTask.startDay);
		const aHasDates = aStartDate && aEndDate && areItemDatesValid(aStartDate, aEndDate);
		const bHasDates = bStartDate && bEndDate && areItemDatesValid(bStartDate, bEndDate);
		if (!aHasDates || !bHasDates) return aHasDates ? 1 : bHasDates ? -1 : 0;
		if (aStartDate > bStartDate) return 1;
		if (aStartDate < bStartDate) return -1;
		if (aEndDate > bEndDate) return 1;
		if (aEndDate < bEndDate) return -1;
		return 0;
	});
};

export const sortPhaseGroupsByDate = phaseGroups => {
	for (const phaseGroup of phaseGroups) {
		sortTaskGroupsByDate(phaseGroup.groups);
	}
	// order phases
	Util.sortPhases(phaseGroups, true);
};

export const sortProjectGroups = (pageComponent, groups) => {
	const data = pageComponent.getFilterData();

	const projectGroups = groups.filter(group => !group.data.companyProjectGroupId);
	const runningGroups = projectGroups.filter(group => group.data.status === PROJECT_STATUS.RUNNING);
	const planningGroups = projectGroups.filter(group => group.data.status === PROJECT_STATUS.PLANNING);
	const opportunityGroups = projectGroups.filter(group => group.data.status === PROJECT_STATUS.OPPORTUNITY);
	const haltedGroups = projectGroups.filter(group => group.data.status === PROJECT_STATUS.HALTED);
	const doneGroups = projectGroups.filter(group => group.data.status === PROJECT_STATUS.DONE);
	const projectGroupGroups = groups.filter(group => group.data.companyProjectGroupId);
	const programGroups = groups.filter(group => group.groupType === GROUP_TYPE.PROGRAM);

	const getStatusPriority = status => {
		switch (status) {
			case PROJECT_STATUS.RUNNING:
				return 0;
			case PROJECT_STATUS.PLANNING:
				return 1;
			case PROJECT_STATUS.OPPORTUNITY:
				return 2;
			case PROJECT_STATUS.HALTED:
				return 3;
			case PROJECT_STATUS.DONE:
				return 4;
			default:
				// eslint-disable-next-line no-console
				console.warn('Unrecognized project status: ' + status);
				return 50;
		}
	};

	const groupingGroups = [...projectGroupGroups, ...programGroups];

	for (const group of groupingGroups) {
		const projectPredicate =
			group.groupType === GROUP_TYPE.PROGRAM
				? project => project.programPrefix === group.data.program?.prefix
				: project => project.projectGroupId === group.id;
		const projects = data.projects.filter(projectPredicate);

		let highestStatusPriority = 99;
		for (const project of projects) {
			const statusPriority = getStatusPriority(project.status);
			if (statusPriority < highestStatusPriority) {
				highestStatusPriority = statusPriority;
			}
		}

		switch (highestStatusPriority) {
			case 0:
				runningGroups.push(group);
				break;
			case 1:
				planningGroups.push(group);
				break;
			case 2:
				opportunityGroups.push(group);
				break;
			case 3:
				haltedGroups.push(group);
				break;
			case 4:
				doneGroups.push(group);
				break;
			default:
				if (
					group.groupType === GROUP_TYPE.PROGRAM &&
					(!pageComponent.props.isProjectTimeline || pageComponent.props.programPrefix)
				) {
					doneGroups.push(group);
				}
				break;
		}
	}

	const getProjectGroupDates = (projectGroupId, programPrefix) => {
		let startDate = null;
		let endDate = null;

		const projectPredicate = programPrefix
			? project => project.programPrefix === programPrefix
			: project => project.projectGroupId === projectGroupId;
		const projects = data.projects.filter(projectPredicate);

		for (const project of projects) {
			const projectStartDate = project.projectStartDay
				? Util.CreateNonUtcMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay)
				: null;
			const projectEndDate = project.projectEndDay
				? Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay).endOf(
						'day'
				  )
				: null;

			if (projectStartDate && (!startDate || startDate.isAfter(projectStartDate))) {
				startDate = projectStartDate;
			}

			if (projectEndDate && (!endDate || endDate.isBefore(projectEndDate))) {
				endDate = projectEndDate;
			}
		}

		return {startDate, endDate};
	};

	const getProjectOrProgramDates = (projectId, programPrefix) => {
		const project = projectId ? data.projects.find(project => project.id === projectId) : null;
		const program = programPrefix ? data.programs.find(program => program.prefix === programPrefix) : null;

		let startDate = null,
			endDate = null;

		if (project) {
			startDate = project.projectStartDay
				? Util.CreateNonUtcMomentDate(project.projectStartYear, project.projectStartMonth, project.projectStartDay)
				: null;

			endDate = project.projectEndDay
				? Util.CreateNonUtcMomentDate(project.projectEndYear, project.projectEndMonth, project.projectEndDay).endOf(
						'day'
				  )
				: null;
		} else if (program) {
			startDate = program.startDate ? Util.CreateNonUtcMomentDateFromString(program.startDate) : null;
			endDate = program.endDate ? Util.CreateNonUtcMomentDateFromString(program.endDate) : null;
		}

		return {startDate, endDate};
	};

	const isInProjectGroupOrProgram = group => group.data.companyProjectGroupId || group.data.isInProgram;

	for (const group of [runningGroups, planningGroups, opportunityGroups, haltedGroups, doneGroups]) {
		group.sort((a, b) => {
			const aProgram = a.data.program;
			const bProgram = b.data.program;

			const aDates = isInProjectGroupOrProgram(a)
				? getProjectGroupDates(a.id, aProgram?.programPrefix)
				: getProjectOrProgramDates(a.id, aProgram?.programPrefix);
			const bDates = isInProjectGroupOrProgram(b)
				? getProjectGroupDates(b.id, bProgram?.programPrefix)
				: getProjectOrProgramDates(b.id, bProgram?.programPrefix);

			const aHasDates = aDates.startDate && aDates.endDate;
			const bHasDates = bDates.startDate && bDates.endDate;

			if (aHasDates && !bHasDates) return -1;
			if (bHasDates && !aHasDates) return 1;
			if (!aHasDates && !bHasDates) return 0;

			if (aDates.startDate && (!bDates.startDate || aDates.startDate.isBefore(bDates.startDate))) return -1;
			if (bDates.startDate && (!aDates.startDate || bDates.startDate.isBefore(aDates.startDate))) return 1;

			return 0;
		});
	}

	const sortedGroups = runningGroups.concat(planningGroups).concat(opportunityGroups).concat(haltedGroups).concat(doneGroups);

	if (sortedGroups[0]) {
		sortedGroups[0].data.isFirstProjectGroup = true;
	}

	return sortedGroups;
};
