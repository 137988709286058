import React from 'react';
import {BUTTON_STYLE, BUTTON_COLOR} from '../../../constants';
import {CSS_CONSTANTS} from '../../../css_variables';

import Button from '../../../forecast-app/shared/components/buttons/button/button';
import styled from 'styled-components';
import FixedRangePicker from './fixed_range_picker';

import {injectIntl} from 'react-intl';
import {CaretIcon} from 'web-components';
import {trackEvent} from '../../../tracking/amplitude/TrackingV2';

const DateChangerStyle = styled.div`
	display: flex;
	flex-direction: row;
	height: 30px;
	.date-picker-button-container {
		.date-picker-button {
			padding: 0 16px;
			height: 30px;
			width: 175px;
			border-radius: 0;
			background-position: left 16px top 6px;
			&.text-version {
				border: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey};
				border-width: ${props => !props.noAdjacentButton && '1px 0'};
				font-size: 15px;
				text-align: center;
				color: #535353;
			}
			.placeholder {
				font-weight: bold;
			}
			&:hover {
				border: 1px solid ${CSS_CONSTANTS.v2_date_picker_button_border_color};
			}
		}
	}

	.change-date-button {
		height: 30px;
		border: solid 1px ${CSS_CONSTANTS.v2_button_very_light_grey};

		width: 26px;
		background-repeat: no-repeat;
		background-position: center;
		padding: 0;
		display: flex;

		justify-content: center;

		&:hover,
		&:active,
		&:focus {
			border-color: ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
		}
		&:disabled {
			background-color: #f3f3f3;
			border: solid 1px ${CSS_CONSTANTS.v2_button_very_light_grey};
		}
	}
	.today-button {
		:not(&.left) {
			margin-left: 8px;
		}
	}
	.this-week-button {
		margin-right: 8px;
	}
	.today-button,
	.this-week-button {
		margin-right: 8px;
		&:hover,
		&:active,
		&:focus {
			border: 1px solid ${CSS_CONSTANTS.v2_button_very_light_grey_hovered};
		}
		.input-container {
			.text {
				font-size: 10px;
			}
		}
	}
`;

const DateChangerV2 = ({
	id,
	useTodayButton,
	useThisWeekButton,
	handleTodayButtonClick,
	handleThisWeekButtonClick,
	handleAdjacentDateButtonClick,
	tooltipProps,
	selectorTooltipProps,
	timePeriod,
	currentViewingDate,
	updateDateRange,
	intl,
	userpilot,
	startDateLimite,
	noAdjacentButton,
	todayButtonLeft,
}) => {
	const {formatMessage, formatDate} = intl;
	const getTextFormatDate = () => {
		const date = currentViewingDate.clone();

		switch (timePeriod) {
			case 'day':
				return formatDate(date, {
					day: '2-digit',
					year: 'numeric',
					month: 'short',
				});
			case 'week':
				const firstDay = date.clone().weekday(0).date();

				const lastDay = date.clone().weekday(6);
				return (
					firstDay +
					' - ' +
					lastDay.date() +
					' ' +
					formatDate(lastDay, {
						year: 'numeric',
						month: 'short',
					})
				);
			case 'month':
				return formatDate(date, {
					year: 'numeric',
					month: 'long',
				});
			default:
				//just in case
				return formatDate(date, {
					day: '2-digit',
					year: 'numeric',
					month: 'short',
				});
		}
	};

	const adjacentNextClicked = () => {
		trackEvent('Date Changer Adjacent Button', 'Clicked', {direction: 'next'});
		handleAdjacentDateButtonClick(true);
	};

	const adjacentPreviousClicked = () => {
		trackEvent('Date Changer Adjacent Button', 'Clicked', {direction: 'previous'});
		handleAdjacentDateButtonClick(false);
	};

	const todayButtonClicked = () => {
		trackEvent('Date Changer Today Button', 'Clicked');
		handleTodayButtonClick();
	};

	const thisWeekButtonClicked = () => {
		trackEvent('Date Changer This Week Button', 'Clicked');
		handleThisWeekButtonClick();
	};

	const disabledPreviousButton = startDateLimite
		? currentViewingDate.clone().add(-1, timePeriod).startOf(timePeriod).isBefore(startDateLimite)
		: false;

	return (
		<DateChangerStyle data-userpilot={userpilot ? userpilot : ''} noAdjacentButton={noAdjacentButton}>
			{useThisWeekButton ? (
				<Button
					id={id + '-this-week'}
					text={formatMessage({id: 'overview_upcomming.this-week'})}
					className="this-week-button"
					onClick={thisWeekButtonClicked}
					buttonStyle={BUTTON_STYLE.OUTLINE_THICK}
					colorTheme={BUTTON_COLOR.VERYLIGHTGREY}
					tooltipEnabled={tooltipProps}
					tooltipProps={tooltipProps}
				/>
			) : null}
			{useTodayButton && todayButtonLeft ? (
				<Button
					id={id + '-today'}
					text={formatMessage({id: 'common.today'})}
					className="today-button left"
					onClick={todayButtonClicked}
					buttonStyle={BUTTON_STYLE.OUTLINE_THICK}
					colorTheme={BUTTON_COLOR.VERYLIGHTGREY}
					tooltipEnabled={tooltipProps}
					tooltipProps={tooltipProps}
				/>
			) : null}
			{noAdjacentButton ? null : (
				<button
					id={id + '-previous'}
					title={formatMessage({id: 'common.previous'})}
					className={'change-date-button previous-date'}
					onClick={() => adjacentPreviousClicked()}
					data-cy="new-ui-time-change-date-button-previous-date"
					disabled={disabledPreviousButton}
				>
					<CaretIcon direction={CaretIcon.DIRECTION.LEFT} color={disabledPreviousButton ? '#a1a1a1' : '#535353'} />
				</button>
			)}
			<FixedRangePicker
				id={id + '-picker'}
				useTextFormat={true}
				hideDay={false}
				showAsText={true}
				useSinglePicker={true}
				usePropsDate={true}
				disableHoverColor={true}
				rangeType={timePeriod}
				useCompactStyle={true}
				hideLabel={true}
				calendarOffsetX={-84}
				startDate={currentViewingDate.clone()}
				showEmptyDate={true}
				customDateFormat={'D. MMM YYYY'}
				customPlaceholder={getTextFormatDate()}
				handleDateRangeChange={start => updateDateRange(start)}
				tooltipProps={selectorTooltipProps}
				wideButton={true}
				startDateLimite={startDateLimite}
			/>
			{noAdjacentButton ? null : (
				<button
					id={id + '-next'}
					title={formatMessage({id: 'common.next'})}
					className=" change-date-button next-date"
					onClick={() => adjacentNextClicked()}
					data-cy="new-ui-time-change-date-button-next-date"
				>
					<CaretIcon direction={CaretIcon.DIRECTION.RIGHT} />
				</button>
			)}
			{useTodayButton && !todayButtonLeft ? (
				<Button
					id={id + '-today'}
					text={formatMessage({id: 'common.today'})}
					className="today-button"
					onClick={todayButtonClicked}
					buttonStyle={BUTTON_STYLE.OUTLINE_THICK}
					colorTheme={BUTTON_COLOR.VERYLIGHTGREY}
					tooltipEnabled={tooltipProps}
					tooltipProps={tooltipProps}
				/>
			) : null}
		</DateChangerStyle>
	);
};

export default injectIntl(DateChangerV2);
