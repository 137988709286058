import React from 'react';
import {findSameTimeRegistrations} from '../../../forecast-app/my-work-tab/my-timesheets-page/timesheets_time_registrations';
import EntityLine from './EntityLine';
import PersonLine from './PersonLine';
import RoleLine from './RoleLine';

export const LINE_TYPE = {
	PERSON: 'PERSON',
	ENTITY: 'ENTITY',
	ROLE: 'ROLE',
};

const TimeRegistrationLine = ({
	id,
	viewer,
	lastOne,
	person,
	firstDayOfWeek,
	timeRegistrations,
	parentId,
	timeRegs,
	lockedDate,
	lineType,
	role,
}) => {
	const timeRegistrations_cpy = lineType === LINE_TYPE.PERSON ? [...timeRegs] : []; // TODO SORT AND GROUP
	if (person && !person.active && timeRegistrations_cpy.length === 0) {
		return <></>;
	}

	const timeRegistration = timeRegistrations ? timeRegistrations[0]?.node : null;

	switch (lineType) {
		case LINE_TYPE.PERSON:
			const sortTimeReg = (t1, t2) => {
				const time1 = t1[0].node;
				const time2 = t2[0].node;
				const projectId1 = parseInt(
					time1.project ? time1.project.companyProjectId : time1.task ? time1.task.project.companyProjectId : 111111
				);
				const projectId2 = parseInt(
					time2.project ? time2.project.companyProjectId : time2.task ? time2.task.project.companyProjectId : 111111
				);

				//Same project ?
				if (projectId1 === projectId2) {
					// Same task ?
					const time1TaskName = time1.task ? time1.task.name : '';
					const time2TaskName = time2.task ? time2.task.name : '';

					if (time1TaskName === time2TaskName) {
						//idle times, time off
						const internalTime1 = time1.idleTime ? (!time1.idleTime.isInternalTime ? time1.idleTime.name : '') : '';
						const internalTime2 = time2.idleTime ? (!time2.idleTime.isInternalTime ? time2.idleTime.name : '') : '';
						if (internalTime1 === internalTime2) {
							//idle times, internal time
							const timeOff1 = time1.idleTime ? time1.idleTime.name : '';
							const timeOff2 = time2.idleTime ? time2.idleTime.name : '';
							return timeOff1 > timeOff2;
						}
						return internalTime1 > internalTime2 ? 1 : -1;
					}

					return time1TaskName > time2TaskName ? 1 : -1;
				}
				return projectId1 - projectId2;
			};

			const tr = [];

			// All time regs grouped per entity (project, task, idle)
			timeRegistrations_cpy.forEach(t => {
				if (t) {
					tr.push(findSameTimeRegistrations(timeRegistrations_cpy, t));
				}
			});

			const filtered = tr.filter(t => t !== null).sort(sortTimeReg);

			return (
				<PersonLine
					id={id}
					timeRegistrations={timeRegistrations}
					timeRegistrations_cpy={timeRegistrations_cpy}
					timeRegistration={timeRegistration}
					viewer={viewer}
					parentId={parentId}
					lastOne={lastOne}
					person={person}
					firstDayOfWeek={firstDayOfWeek}
					lockedDate={lockedDate}
					timeRegs={timeRegs}
					filtered={filtered}
				/>
			);
		case LINE_TYPE.ENTITY:
			return (
				<EntityLine
					id={id}
					timeRegistrations={timeRegistrations}
					timeRegistrations_cpy={timeRegistrations_cpy}
					timeRegistration={timeRegistration}
					parentId={parentId}
					viewer={viewer}
					lastOne={lastOne}
					person={person}
					firstDayOfWeek={firstDayOfWeek}
					lockedDate={lockedDate}
					timeRegs={timeRegs}
				/>
			);
		case LINE_TYPE.ROLE:
			return (
				<RoleLine
					id={id}
					timeRegistrations={timeRegistrations}
					timeRegistrations_cpy={timeRegistrations_cpy}
					timeRegs={timeRegs}
					timeRegistration={timeRegistration}
					parentId={parentId}
					lastOne={lastOne}
					role={role}
					firstDayOfWeek={firstDayOfWeek}
					person={person}
					lockedDate={lockedDate}
					viewer={viewer}
				/>
			);
	}
};

export default TimeRegistrationLine;
