/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TimeTotalsLoader_viewer$ref = any;
export type TimeTotalsLoader_QueryVariables = {|
  projectId: string,
  startYear: number,
  startMonth: number,
  startDay: number,
  endYear: number,
  endMonth: number,
  endDay: number,
|};
export type TimeTotalsLoader_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: TimeTotalsLoader_viewer$ref,
  |}
|};
export type TimeTotalsLoader_Query = {|
  variables: TimeTotalsLoader_QueryVariables,
  response: TimeTotalsLoader_QueryResponse,
|};
*/


/*
query TimeTotalsLoader_Query(
  $projectId: ID!
  $startYear: Int!
  $startMonth: Int!
  $startDay: Int!
  $endYear: Int!
  $endMonth: Int!
  $endDay: Int!
) {
  viewer {
    actualPersonId
    component(name: "time_totals_loader")
    ...TimeTotalsLoader_viewer_3oGw83
    id
  }
}

fragment TimeTotalsLoader_viewer_3oGw83 on Viewer {
  id
  company {
    currency
    id
  }
  project(internalId: $projectId) {
    rateCard {
      currency
      id
    }
    financialNumbers(startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay) {
      baselineMinutes
      baselineVsRegisteredMinutes
      baselineVsTotalTimeAtCompletion
      registeredMinutes
      scopeApprovedMinutes
      scopeApprovedVsRegisteredMinutes
      forecastTimeToComplete
      totalTimeAtCompletion
      scopeApprovedVsTotalTimeAtCompletion
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startYear",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startMonth",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startDay",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endYear",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endMonth",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endDay",
    "type": "Int!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "time_totals_loader"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"time_totals_loader\")"
},
v3 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v4 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v5 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v6 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v7 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v8 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeTotalsLoader_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "TimeTotalsLoader_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TimeTotalsLoader_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "RateCard",
                "kind": "LinkedField",
                "name": "rateCard",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "concreteType": "FinancialNumbers",
                "kind": "LinkedField",
                "name": "financialNumbers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "baselineMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "baselineVsRegisteredMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "baselineVsTotalTimeAtCompletion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registeredMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scopeApprovedMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scopeApprovedVsRegisteredMinutes",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "forecastTimeToComplete",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalTimeAtCompletion",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scopeApprovedVsTotalTimeAtCompletion",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "TimeTotalsLoader_Query",
    "operationKind": "query",
    "text": "query TimeTotalsLoader_Query(\n  $projectId: ID!\n  $startYear: Int!\n  $startMonth: Int!\n  $startDay: Int!\n  $endYear: Int!\n  $endMonth: Int!\n  $endDay: Int!\n) {\n  viewer {\n    actualPersonId\n    component(name: \"time_totals_loader\")\n    ...TimeTotalsLoader_viewer_3oGw83\n    id\n  }\n}\n\nfragment TimeTotalsLoader_viewer_3oGw83 on Viewer {\n  id\n  company {\n    currency\n    id\n  }\n  project(internalId: $projectId) {\n    rateCard {\n      currency\n      id\n    }\n    financialNumbers(startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay) {\n      baselineMinutes\n      baselineVsRegisteredMinutes\n      baselineVsTotalTimeAtCompletion\n      registeredMinutes\n      scopeApprovedMinutes\n      scopeApprovedVsRegisteredMinutes\n      forecastTimeToComplete\n      totalTimeAtCompletion\n      scopeApprovedVsTotalTimeAtCompletion\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9dac824898211190ca44868a7f9e5a49';

module.exports = node;
