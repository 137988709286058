/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type RateCardDropdown_rateCards$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ratesAndInternalCostPage_company$ref: FragmentReference;
declare export opaque type ratesAndInternalCostPage_company$fragmentType: ratesAndInternalCostPage_company$ref;
export type ratesAndInternalCostPage_company = {|
  +id: string,
  +currency: ?string,
  +roles: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
      |}
    |}>
  |},
  +exchangeRates: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +currency: ?string,
        +rate: ?number,
      |}
    |}>
  |},
  +rateCards: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +defaultRate: ?number,
        +currency: ?string,
        +parentRateCardId: ?string,
        +rates: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +rate: ?number,
              +defaultRate: ?boolean,
              +startDate: ?string,
              +role: ?{|
                +id: string,
                +name: ?string,
              |},
            |}
          |}>
        |},
      |},
      +$fragmentRefs: RateCardDropdown_rateCards$ref,
    |}>
  |},
  +$refType: ratesAndInternalCostPage_company$ref,
|};
export type ratesAndInternalCostPage_company$data = ratesAndInternalCostPage_company;
export type ratesAndInternalCostPage_company$key = {
  +$data?: ratesAndInternalCostPage_company$data,
  +$fragmentRefs: ratesAndInternalCostPage_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v0/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultRate",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ratesAndInternalCostPage_company",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "RoleTypeConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "roles(first:10000)"
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "ExchangeRateTypeConnection",
      "kind": "LinkedField",
      "name": "exchangeRates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExchangeRateTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExchangeRate",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "exchangeRates(first:10000)"
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "RateCardTypeConnection",
      "kind": "LinkedField",
      "name": "rateCards",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCardTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RateCard",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                (v6/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parentRateCardId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": (v2/*: any*/),
                  "concreteType": "RateTypeConnection",
                  "kind": "LinkedField",
                  "name": "rates",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "RateTypeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Rate",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "startDate",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Role",
                              "kind": "LinkedField",
                              "name": "role",
                              "plural": false,
                              "selections": (v4/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "rates(first:10000)"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RateCardDropdown_rateCards"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "rateCards(first:10000)"
    }
  ],
  "type": "Company"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'df1a607cd9df391ae07d2d7d1c92a83f';

module.exports = node;
