import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import Util from '../../../forecast-app/shared/util/util';

const personDropdownRelayWrapper = props => {
	const {formatMessage} = props.intl;

	const personDropdownOptions = Util.getPersonsForDropdown(props.viewer.company.allPersons);

	return (
		<Dropdown
			dataCy={props.cy}
			key={`dropdown-person-${props.index}`}
			isPersonSelector={true}
			hideLabel={true}
			label={formatMessage({id: 'overview_time.people-select-label'})}
			placeholder={formatMessage({id: 'common.select_person_placeholder'})}
			options={personDropdownOptions}
			onChange={props.callback ? props.callback : null}
			value={props.selectedPerson}
			customHeight={30}
			customWidth={props.customWidth}
			personImageSize={25}
			personNameStyle={{
				fontSize: '12px',
				fontWeight: 'normal',
				fontStyle: 'normal',
				fontStretch: 'normal',
				lineHeight: 'normal',
				letterSpacing: 'normal',
			}}
			tooltipEnabled={props.tooltipEnabled}
			tooltipProps={props.tooltipProps}
			userpilot={props.userpilot}
		/>
	);
};

const personDropdownRelayWrapperQuery = graphql`
	query personDropdownRelayWrapper_Query {
		viewer {
			actualPersonId
			component(name: "person_dropdown")
			...personDropdownRelayWrapper_viewer
		}
	}
`;

export {personDropdownRelayWrapperQuery};

export default injectIntl(
	createFragmentContainer(personDropdownRelayWrapper, {
		viewer: graphql`
			fragment personDropdownRelayWrapper_viewer on Viewer {
				id
				actualPersonId
				company {
					harvestEnabled
					allPersons(first: 10000, excludeClientUsers: true) {
						edges {
							node {
								id
								role {
									id
									name
								}
								email
								firstName
								lastName
								createdAt
								systemUser
								initials
								active
								profilePictureId
								profilePictureDefaultId
							}
						}
					}
				}
			}
		`,
	})
);
