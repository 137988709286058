/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type PROGRAM_BUDGET_TYPE = "CAPPED" | "FIXED_PRICE" | "UNCAPPED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectPortfolioPeriodProgramDownload_viewer$ref: FragmentReference;
declare export opaque type ProjectPortfolioPeriodProgramDownload_viewer$fragmentType: ProjectPortfolioPeriodProgramDownload_viewer$ref;
export type ProjectPortfolioPeriodProgramDownload_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string,
    +financialBasedPrograms: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +color: ?string,
          +budgetType: ?PROGRAM_BUDGET_TYPE,
          +budgetValue: ?number,
          +projectsFinancialsTotals: ?{|
            +billableActualTimeAndExpenses: ?number,
            +totalActualRevenueRecognition: ?number,
            +actualCost: ?number,
            +actualProfit: ?number,
            +actualRevenueProfit: ?number,
            +actualMargin: ?number,
            +actualRevenueMargin: ?number,
            +billablePlannedTimeAndExpenses: ?number,
            +plannedRevenue: ?number,
            +plannedCost: ?number,
            +plannedProfit: ?number,
            +plannedRevenueProfit: ?number,
            +plannedMargin: ?number,
            +plannedRevenueMargin: ?number,
            +billableForecastTimeAndExpensesToComplete: ?number,
            +totalForecastRevenueToComplete: ?number,
            +forecastCostToComplete: ?number,
            +forecastProfitToComplete: ?number,
            +forecastRevenueProfitToComplete: ?number,
            +forecastMarginToComplete: ?number,
            +forecastRevenueMarginToComplete: ?number,
            +billableTotalTimeAndExpensesAtCompletion: ?number,
            +nonBillableTotalTimeAndExpensesAtCompletion: ?number,
            +allTotalTimeAndExpensesAtCompletion: ?number,
            +totalRevenueRecognition: ?number,
            +totalCostAtCompletion: ?number,
            +totalProfitAtCompletion: ?number,
            +totalRevenueProfitAtCompletion: ?number,
            +totalMarginAtCompletion: ?number,
            +totalRevenueMarginAtCompletion: ?number,
            +baselineRevenue: ?number,
            +baselineTimeAndExpenses: ?number,
            +baselineCost: ?number,
            +baselineProfit: ?number,
            +baselineMargin: ?number,
            +baselineMinutes: ?number,
            +planVsBillableActualTimeAndExpenses: ?number,
            +planVsActualCost: ?number,
            +planVsActualProfit: ?number,
            +planVsTotalBillableTimeAndExpensesAtCompletion: ?number,
            +planVsTotalCostAtCompletion: ?number,
            +planVsTotalProfitAtCompletion: ?number,
            +registeredMinutes: ?number,
            +forecastTimeToComplete: ?number,
            +totalTimeAtCompletion: ?number,
            +scopeApprovedMinutes: ?number,
            +scopeTotalMinutes: ?number,
            +allocationMinutes: ?number,
            +invoiced: ?number,
            +paid: ?number,
            +accruedDeferred: ?number,
            +retainerPeriodTargetMinutes: ?number,
            +retainerPeriodTargetPrice: ?number,
            +recognitionLockedRevenue: ?number,
            +recognitionOpenRevenue: ?number,
            +recognitionProfit: ?number,
            +recognitionProfitPercentage: ?number,
            +projectedTotalRevenueVsProjectedTotalBillableValueOfService: ?number,
            +projectedTotalRevenueVsProjectedTotalValueOfService: ?number,
          |},
        |}
      |}>
    |},
  |},
  +$refType: ProjectPortfolioPeriodProgramDownload_viewer$ref,
|};
export type ProjectPortfolioPeriodProgramDownload_viewer$data = ProjectPortfolioPeriodProgramDownload_viewer;
export type ProjectPortfolioPeriodProgramDownload_viewer$key = {
  +$data?: ProjectPortfolioPeriodProgramDownload_viewer$data,
  +$fragmentRefs: ProjectPortfolioPeriodProgramDownload_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPortfolioPeriodProgramDownload_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "endDay",
              "variableName": "endDay"
            },
            {
              "kind": "Variable",
              "name": "endMonth",
              "variableName": "endMonth"
            },
            {
              "kind": "Variable",
              "name": "endYear",
              "variableName": "endYear"
            },
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            },
            {
              "kind": "Variable",
              "name": "startDay",
              "variableName": "startDay"
            },
            {
              "kind": "Variable",
              "name": "startMonth",
              "variableName": "startMonth"
            },
            {
              "kind": "Variable",
              "name": "startYear",
              "variableName": "startYear"
            }
          ],
          "concreteType": "ProjectServiceProgramTypeConnection",
          "kind": "LinkedField",
          "name": "financialBasedPrograms",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectServiceProgramTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProgramType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "budgetType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "budgetValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FinancialNumbers",
                      "kind": "LinkedField",
                      "name": "projectsFinancialsTotals",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableActualTimeAndExpenses",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalActualRevenueRecognition",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualCost",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualRevenueProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualMargin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "actualRevenueMargin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billablePlannedTimeAndExpenses",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedCost",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedRevenueProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedMargin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "plannedRevenueMargin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableForecastTimeAndExpensesToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalForecastRevenueToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastCostToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastProfitToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastRevenueProfitToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastMarginToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastRevenueMarginToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "billableTotalTimeAndExpensesAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "nonBillableTotalTimeAndExpensesAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "allTotalTimeAndExpensesAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalRevenueRecognition",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalCostAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalProfitAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalRevenueProfitAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalMarginAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalRevenueMarginAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineTimeAndExpenses",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineCost",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineMargin",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "baselineMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsBillableActualTimeAndExpenses",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsActualCost",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsActualProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsTotalBillableTimeAndExpensesAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsTotalCostAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "planVsTotalProfitAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "registeredMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "forecastTimeToComplete",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalTimeAtCompletion",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "scopeApprovedMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "scopeTotalMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "allocationMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "invoiced",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "paid",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "accruedDeferred",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "retainerPeriodTargetMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "retainerPeriodTargetPrice",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionLockedRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionOpenRevenue",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionProfit",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "recognitionProfitPercentage",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectedTotalRevenueVsProjectedTotalBillableValueOfService",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "projectedTotalRevenueVsProjectedTotalValueOfService",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd0e5a3456e744fd6d2bdb183e4915dcd';

module.exports = node;
