import React from 'react';
import {PercentageInput} from 'web-components';

export const PercentageInputWrapper = ({
	value,
	disabled,
	callback,
	showAsterisk,
	width,
	placeholder,
	forceUpdate,
	zeroToHundred,
	modalView,
	hasError,
	innerRef,
}) => {
	return (
		<PercentageInput
			innerRef={innerRef}
			value={value}
			callback={percentage => callback(percentage)}
			modalView={modalView}
			hasError={hasError}
			disabled={disabled}
			showAsterisk={showAsterisk}
			width={width}
			placeholder={placeholder}
			forceUpdate={forceUpdate}
			zeroToHundred={zeroToHundred}
		/>
	);
};
