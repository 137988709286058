export const PEOPLE_SCHEDULING_FILTERS = 'people_scheduling_filters';
export const PEOPLE_SCHEDULING_UTILIZATION_FORMAT_STORAGE_KEY = 'peopleScheduling_utilizationFormat';
export const PEOPLE_SCHEDULING_VISUALIZATION_MODE_STORAGE_KEY = 'peopleScheduling_visualizationMode';
export const PEOPLE_SCHEDULING_EYE_OPTIONS_STORAGE_KEY = 'canvas-people-scheduling-layout-options-v0.3';
export const PEOPLE_SCHEDULING_GROUP_BY_STORAGE_KEY = 'peopleScheduling_groupBy';
export const PEOPLE_SCHEDULING_GROUP_BY_TRACKING_MESSAGE = 'Schedule People Group By';
export const PEOPLE_SCHEDULING_FILTER_CHANGE_TRACKING_MESSAGE = 'Schedule People Filter Change';
export const PEOPLE_SCHEDULING_GROUP_BY = {
	NONE: 'none',
	ROLE: 'role',
	DEPARTMENT: 'department',
};
