import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import RevenueChart from './RevenueChart';
import {ChartStyle, ChartTabOption, ChartTabs} from '../work_and_expenses/Chart.styled';
import {createFragmentContainer, graphql} from 'react-relay';
import {decompressAggregatedData} from '../../util/DataDecompressUtil';
import CostChart from './CostChart';
import ProfitChart from './ProfitChart';
import Util from '../../../../../../forecast-app/shared/util/util';
import {getCurrency} from '../../util/BudgetUtils';
import {getTimeUnit} from '../../util/ChartsUtil';
import {BUDGET_MONEY_VIEWS, BUDGET_TYPE, PERIOD_BUDGET_TYPE} from '../../../../../../constants';
import {hasFeatureFlag} from '../../../../../../forecast-app/shared/util/FeatureUtil';
import moment from 'moment';
import * as tracking from '../../../../../../tracking';
import {trackEvent} from '../../../../../../tracking/amplitude/TrackingV2';
import ProjectUtil from '../../../../../../forecast-app/shared/util/project_util';

const RevenueCostProfitCharts = ({viewer, eyeOptionMap, hasBaseline, selectedViewType}) => {
	const intl = useIntl();
	const {formatMessage} = intl;
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const {project} = viewer;
	const currencySymbol = Util.GetCurrencySymbol(getCurrency(viewer.company, viewer.project));
	const projectFirstDate =
		Util.CreateNonUtcMomentDate(project.projectFirstDateYear, project.projectFirstDateMonth, project.projectFirstDateDay) ||
		moment();
	const projectLastDate =
		Util.CreateNonUtcMomentDate(project.projectLastDateYear, project.projectLastDateMonth, project.projectLastDateDay) ||
		moment();
	const isRetainerFixedPriceV2 =
		hasFeatureFlag('revenue_recognition_with_all_budget_types') &&
		project.budgetType === BUDGET_TYPE.RETAINER &&
		project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE;
	const showFixedPriceGraphs =
		[BUDGET_TYPE.FIXED_PRICE, BUDGET_TYPE.FIXED_PRICE_V2].includes(project.budgetType) || isRetainerFixedPriceV2;
	const isUsingNewFixedPriceCalculation = isRetainerFixedPriceV2 || project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2;
	const isExpenseView = BUDGET_MONEY_VIEWS.EXPENSES === selectedViewType;
	const isBillableProject = ProjectUtil.projectTracksRevenue(project);
	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();

	const aggregatedFinancialNumbers = decompressAggregatedData(project.aggregatedFinancialNumbers, [
		'billableActualTimeAndExpenses',
		'billablePlannedTimeAndExpenses',
		'billableTotalTimeAndExpensesAtCompletion',
		'baselineRevenue',
		'baselineTimeAndExpenses',
		'actualCost',
		'plannedCost',
		'totalCostAtCompletion',
		'baselineCost',
		'actualProfit',
		'plannedProfit',
		'totalProfitAtCompletion',
		'baselineProfit',
		'totalRevenueRecognition',
		'recognitionProfit',
	]);

	const data = {
		billableActualTimeAndExpensesChartData: [],
		billablePlannedTimeAndExpensesChartData: [],
		billableTotalTimeAndExpensesAtCompletionChartData: [],
		baselineRevenueChartData: [],
		baselineTimeAndExpensesChartData: [],

		actualCostChartData: [],
		plannedCostChartData: [],
		totalCostAtCompletionChartData: [],
		baselineCostChartData: [],

		actualProfitChartData: [],
		plannedProfitChartData: [],
		totalProfitAtCompletionChartData: [],
		baselineProfitChartData: [],

		fixedPriceChartData: [],
		rateBasedBillableTotalTimeAndExpensesAtCompletionChartData: [],
		totalRevenueRecognitionChartData: [],
	};
	const todayDate = moment();
	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');
	aggregatedFinancialNumbers.forEach(financialNumbers => {
		const isTodayDate = financialNumbers.date.isSame(todayDate, 'days');
		if (isExpenseView || !isUsingNewFixedPriceCalculation) {
			data.billableActualTimeAndExpensesChartData.push({
				date: financialNumbers.date.clone(),
				value: financialNumbers.billableActualTimeAndExpensesAccumulated,
			});
		}
		data.actualCostChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.actualCostAccumulated,
		});
		data.actualProfitChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.actualProfitAccumulated,
		});
		data.billablePlannedTimeAndExpensesChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.billablePlannedTimeAndExpensesAccumulated,
		});
		data.plannedCostChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.plannedCostAccumulated,
		});
		data.plannedProfitChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.plannedProfitAccumulated,
		});
		data.baselineRevenueChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.baselineRevenueAccumulated,
		});
		data.baselineTimeAndExpensesChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.baselineTimeAndExpensesAccumulated,
		});
		data.baselineCostChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.baselineCostAccumulated,
		});
		data.baselineProfitChartData.push({
			date: financialNumbers.date.clone(),
			value: financialNumbers.baselineProfitAccumulated,
		});
		const isFuture = !financialNumbers.date.isBefore(todayDate, 'days');
		if (isFuture) {
			data.billableTotalTimeAndExpensesAtCompletionChartData.push({
				date: financialNumbers.date.clone(),
				value: isTodayDate
					? financialNumbers.billableActualTimeAndExpensesAccumulated
					: financialNumbers.billableTotalTimeAndExpensesAtCompletionAccumulated,
			});
		}
		if (isFuture || hasFinancialCategoriesUpdate) {
			data.totalCostAtCompletionChartData.push({
				date: financialNumbers.date.clone(),
				value: isTodayDate ? financialNumbers.actualCostAccumulated : financialNumbers.totalCostAtCompletionAccumulated,
			});
			data.totalProfitAtCompletionChartData.push({
				date: financialNumbers.date.clone(),
				value: isTodayDate
					? financialNumbers.actualProfitAccumulated
					: financialNumbers.totalProfitAtCompletionAccumulated,
			});
		}
		if (isUsingNewFixedPriceCalculation && !isExpenseView) {
			data.rateBasedBillableTotalTimeAndExpensesAtCompletionChartData.push({
				date: financialNumbers.date.clone(),
				value: financialNumbers.billableTotalTimeAndExpensesAtCompletionAccumulated,
			});
		}
		if (isUsingNewFixedPriceCalculation && !isExpenseView) {
			data.totalRevenueRecognitionChartData.push({
				date: financialNumbers.date.clone(),
				value: financialNumbers.totalRevenueRecognitionAccumulated,
			});
		}
	});

	if (!isExpenseView) {
		data.fixedPriceChartData.push({date: projectFirstDate, value: project.budget});
		data.fixedPriceChartData.push({date: projectLastDate, value: project.budget});
	}

	const modifyChartOptions = options => {
		options.animation = undefined;
		options.scales.x.time.unit = getTimeUnit(projectFirstDate, projectLastDate);
		options.scales.y.ticks.callback = label => Util.getFormattedNumberWithCurrency(currencySymbol, label, intl);
		options.plugins.tooltip.callbacks.label = ctx => {
			const item = ctx.dataset;
			const dataItem = ctx.parsed;
			if (dataItem.customData && dataItem.customData.hideLabel) {
				return '';
			} else {
				const dataSetLabel = item.label ? `${item.label}: ` : '';
				return `${dataSetLabel}${Util.getFormattedNumberWithCurrency(currencySymbol, dataItem.y, intl)}`;
			}
		};
		return options;
	};

	const charts = [
		isBillableProject
			? {
					name: formatMessage({
						id: `project_budget.revenue`,
					}),
					id: 'revenue_chart',
					render: () => (
						<RevenueChart
							data={data}
							company={viewer.company}
							project={project}
							hasBaseline={hasBaseline}
							eyeOptionMap={eyeOptionMap}
							modifyChartOptions={modifyChartOptions}
							showFixedPriceGraphs={showFixedPriceGraphs}
							isUsingNewFixedPriceCalculation={isUsingNewFixedPriceCalculation}
							isExpenseView={isExpenseView}
						/>
					),
			  }
			: null,
		!hasRevenueWithoutCostAccess
			? {
					name: formatMessage({id: `common.cost`}),
					id: 'cost_chart',
					render: () => (
						<CostChart
							data={data}
							project={project}
							hasBaseline={hasBaseline}
							eyeOptionMap={eyeOptionMap}
							modifyChartOptions={modifyChartOptions}
							isUsingNewFixedPriceCalculation={isUsingNewFixedPriceCalculation}
						/>
					),
			  }
			: null,
		!hasRevenueWithoutCostAccess && isBillableProject
			? {
					name: formatMessage({id: `common.profit`}),
					id: 'profit_chart',
					render: () => (
						<ProfitChart
							data={data}
							project={project}
							hasBaseline={hasBaseline}
							eyeOptionMap={eyeOptionMap}
							modifyChartOptions={modifyChartOptions}
							isUsingNewFixedPriceCalculation={isUsingNewFixedPriceCalculation}
						/>
					),
			  }
			: null,
	].filter(Boolean);

	const handleActiveTabChange = index => {
		tracking.trackElementClicked('project_budget_chart_tab.' + charts[index].id);
		trackEvent('Project Budget Chart Tab', 'Clicked');
		setActiveTabIndex(index);
	};

	return (
		<>
			<ChartTabs>
				{charts.length > 1 &&
					charts.map((item, index) => {
						return (
							<ChartTabOption
								key={'money_chart_' + index}
								active={index === activeTabIndex}
								onClick={() => handleActiveTabChange(index)}
							>
								{item.name}
							</ChartTabOption>
						);
					})}
			</ChartTabs>
			{charts.length > 0 && <ChartStyle>{charts[activeTabIndex].render()}</ChartStyle>}
		</>
	);
};

const revenueCostProfitChartsQuery = graphql`
	query RevenueCostProfitCharts_Query(
		$projectId: ID
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
		$searchQuery: TaskSearchQueryType
		$aggregateLevel: AggregateLevel!
	) {
		viewer {
			id
			actualPersonId
			component(name: "project_budget_v3_revenue_cost_profit_charts")
			...RevenueCostProfitCharts_viewer
				@arguments(
					projectId: $projectId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					searchQuery: $searchQuery
					aggregateLevel: $aggregateLevel
				)
		}
	}
`;

export {revenueCostProfitChartsQuery};

export default createFragmentContainer(RevenueCostProfitCharts, {
	viewer: graphql`
		fragment RevenueCostProfitCharts_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
			searchQuery: {type: "TaskSearchQueryType"}
			aggregateLevel: {type: "AggregateLevel!"}
		) {
			company {
				tier
				currency
			}
			project(internalId: $projectId) {
				projectFirstDateYear
				projectFirstDateMonth
				projectFirstDateDay
				projectLastDateYear
				projectLastDateMonth
				projectLastDateDay
				rateCard {
					currency
				}
				estimationUnit
				budget
				budgetType
				billable
				defaultPeriodBudgetType
				aggregatedFinancialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregates: [
						billableActualTimeAndExpenses
						billablePlannedTimeAndExpenses
						billableTotalTimeAndExpensesAtCompletion
						baselineRevenue
						baselineTimeAndExpenses
						actualCost
						plannedCost
						totalCostAtCompletion
						baselineCost
						actualProfit
						plannedProfit
						totalProfitAtCompletion
						baselineProfit
						totalRevenueRecognition
						recognitionProfit
					]
					aggregateLevel: $aggregateLevel
					addAccumulatedNumbers: true
					searchQuery: $searchQuery
					convertToProjectCurrency: true
				)
			}
		}
	`,
});
