import {interactionManager} from './canvas_timeline_interaction_manager';
import {PLACEHOLDERS_GROUP_BY} from '../placeholders-scheduling/CanvasPlaceholderSchedulingConstants';
import {
	GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_HEIGHT,
	GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_WIDTH,
	GROUP_SECTION_PROJECT_BADGE_HEIGHT,
	GROUP_TYPE,
	TOOLTIP_DISPLAY_DELAY_MS,
} from './canvas_timeline_util';
import Moment from 'moment';
import {DATE_FORMAT_DAY} from '../../../constants';
import ProgramUtil from '../../../forecast-app/shared/util/ProgramUtil';
import {getProjectIndicatorString} from '../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';

const getProjectInformationData = (pageComponent, group) => {
	const {intl} = pageComponent.props;
	const {data, lightWeightData} = pageComponent.state;
	const availableData = data || lightWeightData;
	const projectInformationData = {
		items: [],
	};

	const {program, project, projectGroup} = group.data;

	projectInformationData.title = program?.name || projectGroup?.name || project?.name || null;

	const client = program?.client || project?.client || null;
	if (client || group.data.clientName) {
		projectInformationData.subTitle = group.data.clientName || client?.name;
	}

	if (project?.programPrefix) {
		const {programMap} = availableData;
		const program = programMap.get(project.programId);

		if (program) {
			const partOfProgramValue = `${program.prefix} ${program.name}`;
			projectInformationData.items.push({
				subTitle: intl.formatMessage(
					{id: 'scheduling.part_of_program'},
					{program: ProgramUtil.programText(intl.formatMessage)}
				),
				values: [partOfProgramValue],
				boldValueText: false,
			});
		}
	}

	if (projectGroup) {
		const projects = availableData.projectByProjectGroupMap[projectGroup.id];
		let projectValues = [];
		let startDate;
		let endDate;

		projects?.forEach(projectGroupProject => {
			projectValues.push(
				`${getProjectIndicatorString(projectGroupProject.companyProjectId, projectGroupProject.customProjectId)} ${
					projectGroupProject.name
				}`
			);

			const projectStartDate = projectGroupProject?.projectStartYear
				? Moment.utc({
						y: projectGroupProject.projectStartYear,
						M: projectGroupProject.projectStartMonth - 1,
						d: projectGroupProject.projectStartDay,
				  })
				: null;
			const projectEndDate = projectGroupProject?.projectEndYear
				? Moment.utc({
						y: projectGroupProject.projectEndYear,
						M: projectGroupProject.projectEndMonth - 1,
						d: projectGroupProject.projectEndDay,
				  })
				: null;

			if (projectStartDate) {
				const shouldReplace = !startDate || projectStartDate.isBefore(startDate);

				if (shouldReplace) {
					startDate = projectStartDate;
				}
			}

			if (projectEndDate) {
				const shouldReplace = !endDate || projectEndDate.isAfter(endDate);

				if (shouldReplace) {
					endDate = projectEndDate;
				}
			}
		});

		if (startDate || endDate) {
			const connectedProjectDateValue = `${startDate?.format(DATE_FORMAT_DAY) || ''} - ${
				endDate?.format(DATE_FORMAT_DAY) || ''
			}`;
			projectInformationData.items.push({
				subTitle: intl.formatMessage({id: 'common.dates'}),
				values: [connectedProjectDateValue],
				boldValueText: false,
			});
		}

		projectInformationData.items.push({
			subTitle: intl.formatMessage({id: 'common.projects'}),
			values: projectValues,
			boldValueText: false,
		});
	}

	if (project?.isInProjectGroup) {
		const projectGroup = availableData.projectGroups.find(projectGroup => projectGroup.id === project.projectGroupId);

		if (projectGroup) {
			const partOfProjectGroup = `X${projectGroup.companyProjectGroupId} ${projectGroup.name}`;
			projectInformationData.items.push({
				subTitle: intl.formatMessage({id: 'scheduling.part_of'}),
				values: [partOfProjectGroup],
				boldValueText: false,
			});
		}
	}

	const projectStartDate = project?.projectStartYear
		? Moment.utc({
				y: project.projectStartYear,
				M: project.projectStartMonth - 1,
				d: project.projectStartDay,
		  })
		: null;
	const projectEndDate = project?.projectEndYear
		? Moment.utc({
				y: project.projectEndYear,
				M: project.projectEndMonth - 1,
				d: project.projectEndDay,
		  })
		: null;

	const startDate = program?.startDate || projectStartDate?.format(DATE_FORMAT_DAY) || null;
	const endDate = program?.endDate || projectEndDate?.format(DATE_FORMAT_DAY) || null;
	if (startDate || endDate) {
		const datesValue = `${startDate || ' '} - ${endDate || ' '}`;
		projectInformationData.items.push({
			subTitle: intl.formatMessage({id: 'common.dates'}),
			values: [datesValue],
			boldValueText: false,
		});
	}

	if (project?.status === 'OPPORTUNITY' && project?.baselineWinChance !== undefined) {
		const winPercentage = Math.min(100, Math.round(project.baselineWinChance * 100));

		projectInformationData.items.push({
			subTitle: intl.formatMessage({id: 'project.win_chance'}),
			values: [`${winPercentage}%`],
			boldValueText: false,
		});
	}

	return projectInformationData;
};

let previousShowTimeout;
export const displayProjectInformation = (pageComponent, event) => {
	const {showCanvasTooltip, projectInformationGroup} = pageComponent.state;
	const {timeline} = pageComponent;

	if (!timeline) return;

	const timelineOffset = timeline.getCanvasOffset();
	const mouseX = event.clientX - timelineOffset.left;
	const mouseY = event.clientY - timelineOffset.top;

	const mouseTargetData = interactionManager.getDataAtPosition(mouseX, mouseY);

	const noGroup = !mouseTargetData?.groupData?.group;
	const wrongGroup = projectInformationGroup && mouseTargetData?.groupData?.group !== projectInformationGroup;
	const isItem = !!mouseTargetData?.itemData;
	if (noGroup || wrongGroup || isItem) {
		if (projectInformationGroup) {
			pageComponent.setState({
				showCanvasTooltip: false,
				canvasTooltipData: null,
				canvasTooltipX: null,
				canvasTooltipY: null,
				projectInformationGroup: null,
			});
		}

		return;
	}

	const group = mouseTargetData.groupData.group;

	const groupTypes = [GROUP_TYPE.PROGRAM, GROUP_TYPE.PROJECT];

	const isProjectOrProgram = groupTypes.includes(group.groupType);
	const isPlaceholderProjectGrouping =
		group.groupType === GROUP_TYPE.PLACEHOLDERS_SCHEDULING_PLACEHOLDER_GROUPING_GROUP &&
		pageComponent.state.groupBy === PLACEHOLDERS_GROUP_BY.PROJECT;

	if (isProjectOrProgram || isPlaceholderProjectGrouping) {
		// cursor within badge and name
		const mouseWithinHorizontal = mouseX >= group.badgeX && mouseX <= group.nameEndX;
		const mouseWithinVertical = mouseY >= group.badgeY && mouseY <= group.badgeY + GROUP_SECTION_PROJECT_BADGE_HEIGHT;

		// display tooltip
		if (mouseWithinHorizontal && mouseWithinVertical) {
			const isBeingDisplayed = showCanvasTooltip && pageComponent.state.projectInformationGroup?.id === group.id;

			if (!isBeingDisplayed) {
				if (previousShowTimeout) {
					clearTimeout(previousShowTimeout);
				}
				previousShowTimeout = setTimeout(() => {
					pageComponent.setState({
						canvasTooltipX: mouseX,
						canvasTooltipY: mouseY,
						projectInformationGroup: group,
						showCanvasTooltip: true,
						canvasTooltipData: getProjectInformationData(pageComponent, group),
					});
				}, TOOLTIP_DISPLAY_DELAY_MS);
			}
		} else if (showCanvasTooltip && projectInformationGroup) {
			pageComponent.setState({
				showCanvasTooltip: false,
				canvasTooltipData: null,
				canvasTooltipX: null,
				canvasTooltipY: null,
				projectInformationGroup: null,
			});
		}
	}
};
export const displayPlaceholderInformation = (pageComponent, event) => {
	const {showPlaceholderInformation} = pageComponent.state;
	const {timeline} = pageComponent;

	if (!timeline) return;

	let hoveringInformationIcon = false;
	const timelineOffset = timeline.getCanvasOffset();
	const mouseX = event.clientX - timelineOffset.left;
	const mouseY = event.clientY - timelineOffset.top;

	const mouseTargetData = interactionManager.getDataAtPosition(mouseX, mouseY);

	if (!mouseTargetData?.groupData?.group) return;

	const group = mouseTargetData.groupData.group;

	const isPlaceholderGroupType = group.groupType === GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP;

	if (!group.data.skills || group.data.skills.length === 0) return;

	if (isPlaceholderGroupType) {
		// cursor within placeholder information icon start x and width
		const mouseWithinHorizontal =
			mouseX >= group.placeholderInformationIconX &&
			mouseX <= group.placeholderInformationIconX + GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_WIDTH;

		// cursor within placeholder information icon start y and height
		const mouseWithinVertical =
			mouseY >= group.placeholderInformationIconY &&
			mouseY <= group.placeholderInformationIconY + GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_HEIGHT;

		// display tooltip
		if (mouseWithinHorizontal && mouseWithinVertical) {
			hoveringInformationIcon = true;

			pageComponent.setState({
				showPlaceholderInformation: true,
				placeholderInformationY: group.placeholderInformationIconY + timelineOffset.top,
				placeholderInformationX:
					group.placeholderInformationIconX + GROUP_SECTION_PLACEHOLDER_INFORMATION_ICON_WIDTH + 13,
				placeholderInformationData: {placeholder: group.data},
			});
		}
	}

	if (!hoveringInformationIcon && showPlaceholderInformation) {
		pageComponent.setState({showPlaceholderInformation: false});
	}
};
