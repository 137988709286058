import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';

export const handleReplacePlaceholderWithPerson = (
	placeholderId,
	staffingModeActive = false,
	placeholderInput = undefined,
	placeholderAllocationsInput = undefined,
	skillIdsInput = undefined,
	suggestedPersonIdsInput = undefined
) => {
	const replacePlaceholderWithPerson = () => {
		showModal({
			type: MODAL_TYPE.REPLACE_PLACEHOLDER_WITH_PERSON,
			placeholderId,
			staffingModeActive,
			placeholderInput,
			placeholderAllocationsInput,
			skillIdsInput,
			suggestedPersonIdsInput,
		});
	};

	replacePlaceholderWithPerson();
};
