import React, { useMemo, useState } from 'react';
import { Alert, FlexColumn, FlexRow, Modal, RadioGroupHandler, SubHeading, Text } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExpenseBoolean } from '../ExpenseTypes';
import BulkUpdateExpenseItemsMutation from '../../../mutations/bulk_update_expense_items_mutation';
import Util from '../../../forecast-app/shared/util/util';
import ProjectUtil from '../../../forecast-app/shared/util/project_util';
const ChangeExpenseFixedPriceBillingModal = ({ expenseItems, closeModal, }) => {
    const { formatMessage } = useIntl();
    const unLockedItems = useMemo(() => expenseItems.filter(expenseItem => !expenseItem.locked), [expenseItems]);
    const updateableItems = useMemo(() => expenseItems.filter(expenseItem => !expenseItem.locked && ProjectUtil.isFixedPriceProject(expenseItem.project)), [expenseItems]);
    const [newPartOfFixedPrice, setNewPartOfFixedPrice] = useState('');
    const count = expenseItems.length;
    const lockedCount = expenseItems.length - unLockedItems.length;
    const showAlert = lockedCount > 0;
    const allowSave = newPartOfFixedPrice && count > lockedCount;
    const saveChanges = () => {
        const onSuccess = () => {
            closeModal();
        };
        Util.CommitMutation(BulkUpdateExpenseItemsMutation, {
            ids: updateableItems.map(expenseItem => expenseItem.id),
            partOfFixedPrice: newPartOfFixedPrice === ExpenseBoolean.TRUE,
        }, onSuccess);
    };
    return (React.createElement(Modal, { size: Modal.SIZE.SMALL, onCloseModal: closeModal },
        React.createElement(Modal.Title, null,
            React.createElement(FormattedMessage, { id: "expense_management.fixed_price_billing_details" })),
        React.createElement(Modal.Content, null,
            React.createElement(FlexColumn, { gap: 's' },
                React.createElement(FlexRow, null,
                    React.createElement(SubHeading, null,
                        React.createElement(FormattedMessage, { id: 'expense_management.billing_details' }))),
                React.createElement(FlexRow, null,
                    React.createElement(Text, { color: 'medium' },
                        React.createElement(FormattedMessage, { id: 'expense_management.bulk_change_fixed_price_billing_info' })))),
            React.createElement(FlexColumn, { gap: 'xl' },
                React.createElement(RadioGroupHandler, { onValueChange: setNewPartOfFixedPrice, value: newPartOfFixedPrice },
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(RadioGroupHandler.Item, { value: ExpenseBoolean.TRUE }),
                        React.createElement(FlexColumn, null,
                            React.createElement(Text, null, formatMessage({ id: 'expense_management.change_fixed_price_billing_part_of_fixed_price' })))),
                    React.createElement(FlexRow, { gap: 's' },
                        React.createElement(RadioGroupHandler.Item, { value: ExpenseBoolean.FALSE }),
                        React.createElement(Text, null, formatMessage({ id: 'expense_management.change_fixed_price_billing_on_top_of_fixed_price' })))),
                showAlert && (React.createElement(Alert, { text: formatMessage({ id: 'expense_management.change_fixed_price_billing.locked_alert' }, { count, lockedCount }), type: Alert.TYPE.INFORMATION })))),
        React.createElement(Modal.Footer, null,
            React.createElement(Modal.PrimaryFooterButton, { onPress: saveChanges, disabled: !allowSave, dataCy: 'confirm' },
                React.createElement(FormattedMessage, { id: 'expense_management.actions.confirm_changes' })),
            React.createElement(Modal.SecondaryFooterButton, { onPress: closeModal, dataCy: 'cancel' },
                React.createElement(FormattedMessage, { id: 'common.cancel' })))));
};
export default ChangeExpenseFixedPriceBillingModal;
