import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';

export const getTotalWeekTime = (timeRegistrations, firstDayOfWeek) => {
	let totalWeekTime = 0;
	let totalWeekBillableTime = 0;

	for (let i = 0; i < 7; i++) {
		const day = firstDayOfWeek.clone().add(i, 'day');

		totalWeekTime += timeRegistrations.reduce((sum, t) => {
			if (t.node.day === day.date()) {
				return t.node.minutesRegistered + sum;
			}
			return sum;
		}, 0);

		totalWeekBillableTime += timeRegistrations.reduce((sum, t) => {
			if (t.node.day === day.date()) {
				return t.node.billableMinutesRegistered + sum;
			}
			return sum;
		}, 0);
	}

	return {totalWeekTime, totalWeekBillableTime};
};

export const hasNoAccessToEditProjectTimeReg = (actualPersonId, dayRegNodes) => {
	const containsProjectTimeRegFromOtherPerson = dayRegNodes.some(
		item => (item.node.project || item.node.task) && item.node.person.id !== actualPersonId
	);
	return containsProjectTimeRegFromOtherPerson && !hasPermission(PERMISSION_TYPE.TIME_REGISTRATION_UPDATE_ALL);
};

export const hasNoAccessToTimeRegProject = timeReg => {
	return !!(
		timeReg?.task?.project?.fullAccessToProject === false ||
		timeReg?.project?.fullAccessToProject === false ||
		timeReg?.project?.readOnlyAccess === true ||
		timeReg?.task?.project?.readOnlyAccess === true
	);
};
