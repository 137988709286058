import React from 'react';
import {useIntl} from 'react-intl';
import {MONTH_NAMES_SHORT} from '../canvas-timeline/canvas_timeline_util';
import {getAllocationTotalNew} from '../../scheduling/project_allocation_logic';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../constants';
import AllocationDetailUtil from './allocation_detail_util';
import ProgramUtil from '../../../forecast-app/shared/util/ProgramUtil';
import {getProjectOrProjectGroupIndicatorString} from '../../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';

export default ({placeholderAllocation, globalData, positionX, positionY, project, projectGroup, schedulingOptions}) => {
	const {formatMessage} = useIntl();
	const showRight = positionX + 200 < window.innerWidth;
	const showTop = positionY + 350 < window.innerHeight;
	const calcWin = schedulingOptions.calcWin;
	const hasBetaChanges = hasFeatureFlag('capacity_planning_beta_2_improvements');

	const projectName = getProjectOrProjectGroupIndicatorString({
		companyProjectId: project?.companyProjectId,
		customProjectId: project?.customProjectId,
		companyProjectGroupId: projectGroup?.companyProjectGroupId,
		name: project?.name || projectGroup?.name,
	});

	const baselineWinChance = project?.baselineWinChance || 1;

	const client = project?.clientId ? globalData.clientMap.get(project.clientId) : null;

	const clientName = client?.name;

	const program = project?.programId ? globalData.programMap.get(project.programId) : null;

	const total = getAllocationTotalNew(placeholderAllocation);
	const allocationText = hasBetaChanges
		? AllocationDetailUtil.getAllocationTimeTextWithWinChance(
				total,
				formatMessage,
				baselineWinChance,
				placeholderAllocation,
				globalData.company,
				calcWin
		  )
		: AllocationDetailUtil.getAllocationTimeTextWithWinChanceOld(
				total,
				formatMessage,
				baselineWinChance,
				placeholderAllocation,
				globalData.company
		  );

	const allocationTextWin = AllocationDetailUtil.getAllocationTimeTextWithWinChance(
		total,
		formatMessage,
		baselineWinChance,
		placeholderAllocation,
		globalData.company,
		false
	);

	const useWinChance = project?.baselineWinChance && project.baselineWinChance < 1;
	const allocationLabel = formatMessage({id: 'common.allocation'});
	const allocationLabelExtension =
		hasBetaChanges && useWinChance && calcWin ? formatMessage({id: 'scheduling.calc_win_probability'}) : null;

	return (
		<>
			<div className={'white-triangle ' + (showRight ? 'right ' : 'left ') + (showTop ? 'top' : 'bottom')} />
			<div className="detail-box-inner-container detail-box-allocation-container">
				<div className="detail-box-allocation-item detail-box-inner-headline">
					{formatMessage({id: 'common.allocation'})}
				</div>
				<div className="detail-box-allocation-item detail-box-allocation-name">
					<div className="label">{formatMessage({id: 'common.project-name'})}</div>
					<div className="content">{projectName}</div>
				</div>
				{program ? (
					<div className="detail-box-allocation-item">
						<div className="label">
							{formatMessage(
								{id: 'scheduling.part_of_program'},
								{program: ProgramUtil.programText(formatMessage)}
							)}
						</div>
						<div className="content">
							{program.prefix} {program.name}
						</div>
					</div>
				) : null}
				{clientName ? (
					<div className="detail-box-allocation-item">
						<div className="label">{formatMessage({id: 'common.client'})}</div>
						<div className="content">{clientName}</div>
					</div>
				) : null}
				<div className="detail-box-allocation-item detail-box-allocation-dates">
					<div className="label">{formatMessage({id: 'common.date'})}</div>
					<div className={'content'}>{`${placeholderAllocation.startDay} ${formatMessage({
						id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[placeholderAllocation.startMonth - 1],
					})} ${placeholderAllocation.startYear} - ${placeholderAllocation.endDay} ${formatMessage({
						id: 'insights.component.list.column.' + MONTH_NAMES_SHORT[placeholderAllocation.endMonth - 1],
					})} ${placeholderAllocation.endYear}`}</div>
				</div>
				{hasBetaChanges && (
					<div className="detail-box-allocation-item detail-box-allocation-alloc-type">
						<div className="label">{formatMessage({id: 'common.allocation_type'})}</div>
						<div className="content">
							{AllocationDetailUtil.getAllocationType(placeholderAllocation, formatMessage)}
						</div>
					</div>
				)}
				{hasBetaChanges && calcWin && project && hasModule(MODULE_TYPES.CALC_WIN_PERCENTAGE) && (
					<div className="detail-box-allocation-item detail-box-allocation-win-probability">
						<div className="label">{formatMessage({id: 'project.win_chance'})}</div>
						<div className="content">{AllocationDetailUtil.getWinProbability(project)}</div>
					</div>
				)}
				<div className="detail-box-allocation-item detail-box-allocation-total">
					<div className="label">{allocationLabel}</div>
					{allocationLabelExtension && <div className="label">{allocationLabelExtension}</div>}
					<div className="content">{hasBetaChanges ? <b>{allocationText}</b> : <>{allocationText}</>}</div>
					{allocationLabelExtension && (
						<div className="content">
							{formatMessage({id: 'scheduling.minutes_if_win'}, {minutes: allocationTextWin})}
						</div>
					)}
				</div>
				{!hasBetaChanges && (
					<div className="detail-box-allocation-item detail-box-allocation-type">
						<div className="label">{formatMessage({id: 'common.type'})}</div>
						<div className="content">{formatMessage({id: 'scheduling.placeholder_allocation'})}</div>
					</div>
				)}
			</div>
		</>
	);
};
