/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type FixedPriceContainerLoader_viewer$ref: FragmentReference;
declare export opaque type FixedPriceContainerLoader_viewer$fragmentType: FixedPriceContainerLoader_viewer$ref;
export type FixedPriceContainerLoader_viewer = {|
  +project: ?{|
    +id: string,
    +companyProjectId: ?number,
    +budgetType: ?BUDGET_TYPE,
    +budget: ?number,
    +originalForecastBillableHoursPrice: ?number,
    +forecastBillableHoursPrice: ?number,
    +expenseFinancialNumbers: ?{|
      +billablePlannedTimeAndExpenses: ?number
    |},
    +rateCard: ?{|
      +id: string,
      +currency: ?string,
      +parentRateCardId: ?string,
      +rates: ?{|
        +edges: ?$ReadOnlyArray<?{|
          +node: ?{|
            +id: string,
            +rate: ?number,
            +defaultRate: ?boolean,
            +startDate: ?string,
            +role: ?{|
              +id: string
            |},
          |}
        |}>
      |},
    |},
  |},
  +company: ?{|
    +id: string,
    +currency: ?string,
    +rateCards: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +currency: ?string,
          +rates: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +rate: ?number,
                +defaultRate: ?boolean,
                +startDate: ?string,
                +role: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
  |},
  +$refType: FixedPriceContainerLoader_viewer$ref,
|};
export type FixedPriceContainerLoader_viewer$data = FixedPriceContainerLoader_viewer;
export type FixedPriceContainerLoader_viewer$key = {
  +$data?: FixedPriceContainerLoader_viewer$data,
  +$fragmentRefs: FixedPriceContainerLoader_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "concreteType": "RateTypeConnection",
  "kind": "LinkedField",
  "name": "rates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RateTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Rate",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "defaultRate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Role",
              "kind": "LinkedField",
              "name": "role",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "rates(first:10000)"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "FixedPriceContainerLoader_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "internalId",
          "variableName": "projectId"
        }
      ],
      "concreteType": "ProjectType",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyProjectId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budgetType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "budget",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalForecastBillableHoursPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "forecastBillableHoursPrice",
          "storageKey": null
        },
        {
          "alias": "expenseFinancialNumbers",
          "args": [
            {
              "kind": "Literal",
              "name": "convertToProjectCurrency",
              "value": true
            },
            {
              "kind": "Literal",
              "name": "searchQuery",
              "value": {
                "filters": [
                  {
                    "field": "ROLE",
                    "operator": "IS",
                    "value": "Um9sZTotMQ=="
                  }
                ]
              }
            }
          ],
          "concreteType": "FinancialNumbers",
          "kind": "LinkedField",
          "name": "financialNumbers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billablePlannedTimeAndExpenses",
              "storageKey": null
            }
          ],
          "storageKey": "financialNumbers(convertToProjectCurrency:true,searchQuery:{\"filters\":[{\"field\":\"ROLE\",\"operator\":\"IS\",\"value\":\"Um9sZTotMQ==\"}]})"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "parentRateCardId",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": (v2/*: any*/),
          "concreteType": "RateCardTypeConnection",
          "kind": "LinkedField",
          "name": "rateCards",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RateCardTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "RateCard",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v3/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "rateCards(first:10000)"
        },
        {
          "alias": null,
          "args": (v2/*: any*/),
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(first:10000)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '19d16e5670f3536bb4ec713982c40476';

module.exports = node;
