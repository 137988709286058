import {createFragmentContainer, graphql} from 'react-relay';
import {getCurrency} from '../util/BudgetUtils';
import Util from '../../../../../forecast-app/shared/util/util';
import ProjectUtil from '../../../../../forecast-app/shared/util/project_util';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';

const BudgetTotalsLoader = ({viewer, children}) => {
	const rows = [];
	const currency = getCurrency(viewer.company, viewer.project);
	const {financialNumbers, budget} = viewer.project;
	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();
	const billable = ProjectUtil.projectTracksRevenue(viewer.project);
	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');
	let valueOfServiceData;
	if (billable) {
		valueOfServiceData = [
			{
				type: 'fixedPriceRevenue',
				value: budget,
			},
			hasFinancialCategoriesUpdate
				? {
						type: 'baselineTimeAndExpenses',
						value: financialNumbers.baselineTimeAndExpenses,
				  }
				: {
						type: 'baselineRevenue',
						value: financialNumbers.baselineRevenue,
				  },
			{
				type: 'billablePlannedTimeAndExpenses',
				value: financialNumbers.billablePlannedTimeAndExpenses,
			},
			{
				type: 'billableActualTimeAndExpenses',
				value: financialNumbers.billableActualTimeAndExpenses,
			},
			{
				type: 'billableForecastTimeAndExpensesToComplete',
				value: financialNumbers.billableForecastTimeAndExpensesToComplete,
			},
			{
				type: 'billableTotalTimeAndExpensesAtCompletion',
				value: financialNumbers.billableTotalTimeAndExpensesAtCompletion,
			},
			{
				type: 'baselineVsBillableActualTimeAndExpenses',
				value: financialNumbers.baselineVsBillableActualTimeAndExpenses,
			},
			{
				type: 'baselineVsBillableTotalTimeAndExpensesAtCompletion',
				value: financialNumbers.baselineVsBillableTotalTimeAndExpensesAtCompletion,
			},
			{
				type: 'planVsBillableActualTimeAndExpenses',
				value: financialNumbers.planVsBillableActualTimeAndExpenses,
			},
			{
				type: 'planVsTotalBillableTimeAndExpensesAtCompletion',
				value: financialNumbers.planVsTotalBillableTimeAndExpensesAtCompletion,
			},
			{
				type: 'recognitionAmount',
				value: financialNumbers.recognitionAmount,
			},
		];
		const revenueData = [
			{
				type: 'fixedPriceRevenue',
				value: budget,
			},
			{
				type: 'baselineRevenue',
				value: financialNumbers.baselineRevenue,
			},
			{
				type: 'plannedRevenue',
				value: financialNumbers.plannedRevenue,
			},
			{
				type: 'totalActualRevenueRecognition',
				value: financialNumbers.totalActualRevenueRecognition,
			},
			{
				type: 'totalForecastRevenueToComplete',
				value: financialNumbers.totalForecastRevenueToComplete,
			},
			{
				type: 'totalRevenueRecognition',
				value: financialNumbers.totalRevenueRecognition,
			},
			{
				type: 'baselineVsActualRevenueRecognition',
				value: financialNumbers.baselineVsActualRevenueRecognition,
			},
			{
				type: 'baselineVsTotalRevenueAtCompletion',
				value: financialNumbers.baselineVsTotalRevenueAtCompletion,
			},
			{
				type: 'planVsActualRevenueRecognition',
				value: financialNumbers.planVsActualRevenueRecognition,
			},
			{
				type: 'planVsTotalRevenueAtCompletion',
				value: financialNumbers.planVsTotalRevenueAtCompletion,
			},
			{
				type: 'recognitionAmount',
				value: financialNumbers.recognitionAmount,
			},
		];

		if (hasFinancialCategoriesUpdate) {
			const revenueRow = {
				dataArray: revenueData,
				currency: currency,
				rowType: 'revenue',
			};
			rows.push(revenueRow);
		} else {
			const revenueRow = {
				dataArray: valueOfServiceData,
				currency: currency,
				rowType: 'revenue',
			};
			rows.push(revenueRow);
		}
	}

	const costData = [
		{
			type: 'fixedPriceCost',
			value: financialNumbers.totalCostAtCompletion,
		},
		{
			type: 'baselineCost',
			value: financialNumbers.baselineCost,
		},
		{
			type: 'plannedCost',
			value: financialNumbers.plannedCost,
		},
		{
			type: 'actualCost',
			value: financialNumbers.actualCost,
		},
		{
			type: 'forecastCostToComplete',
			value: financialNumbers.forecastCostToComplete,
		},
		{
			type: 'totalCostAtCompletion',
			value: financialNumbers.totalCostAtCompletion,
		},
		{
			type: 'baselineVsActualCost',
			value: financialNumbers.baselineVsActualCost,
		},
		{
			type: 'baselineVsTotalCostAtCompletion',
			value: financialNumbers.baselineVsTotalCostAtCompletion,
		},
		{
			type: 'planVsActualCost',
			value: financialNumbers.planVsActualCost,
		},
		{
			type: 'planVsTotalCostAtCompletion',
			value: financialNumbers.planVsTotalCostAtCompletion,
		},
		{
			type: 'estimatedCost',
			value: financialNumbers.estimatedCost,
		},
	];

	const costRow = {
		dataArray: costData,
		currency: currency,
		rowType: 'cost',
	};
	if (!hasRevenueWithoutCostAccess) {
		rows.push(costRow);
	}

	if (billable) {
		const profitData = [
			{
				type: 'fixedPriceProfit',
				value: budget - financialNumbers.totalCostAtCompletion,
			},
			{
				type: 'baselineProfit',
				value: financialNumbers.baselineProfit,
			},
			hasFinancialCategoriesUpdate
				? {
						type: 'plannedRevenueProfit',
						value: financialNumbers.plannedRevenueProfit,
				  }
				: {
						type: 'plannedProfit',
						value: financialNumbers.plannedProfit,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'actualRevenueProfit',
						value: financialNumbers.actualRevenueProfit,
				  }
				: {
						type: 'actualProfit',
						value: financialNumbers.actualProfit,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'forecastRevenueProfitToComplete',
						value: financialNumbers.forecastRevenueProfitToComplete,
				  }
				: {
						type: 'forecastProfitToComplete',
						value: financialNumbers.forecastProfitToComplete,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'totalRevenueProfitAtCompletion',
						value: financialNumbers.totalRevenueProfitAtCompletion,
				  }
				: {
						type: 'totalProfitAtCompletion',
						value: financialNumbers.totalProfitAtCompletion,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'baselineVsActualRevenueProfit',
						value: financialNumbers.baselineVsActualRevenueProfit,
				  }
				: {
						type: 'baselineVsActualProfit',
						value: financialNumbers.baselineVsActualProfit,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'baselineVsTotalRevenueProfitAtCompletion',
						value: financialNumbers.baselineVsTotalRevenueProfitAtCompletion,
				  }
				: {
						type: 'baselineVsTotalProfitAtCompletion',
						value: financialNumbers.baselineVsTotalProfitAtCompletion,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'planVsActualRevenueProfit',
						value: financialNumbers.planVsActualRevenueProfit,
				  }
				: {
						type: 'planVsActualProfit',
						value: financialNumbers.planVsActualProfit,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'planVsTotalRevenueProfitAtCompletion',
						value: financialNumbers.planVsTotalRevenueProfitAtCompletion,
				  }
				: {
						type: 'planVsTotalProfitAtCompletion',
						value: financialNumbers.planVsTotalProfitAtCompletion,
				  },
			{
				type: 'recognitionProfit',
				value: financialNumbers.recognitionProfit,
			},
		];

		const profitRow = {
			dataArray: profitData,
			currency: currency,
			rowType: 'profit',
		};
		if (!hasRevenueWithoutCostAccess) {
			rows.push(profitRow);
		}
	}

	if (billable) {
		const marginData = [
			{
				type: 'fixedPriceMargin',
				value: (budget - financialNumbers.totalCostAtCompletion) / budget,
			},
			{
				type: 'baselineMargin',
				value: financialNumbers.baselineMargin,
			},
			hasFinancialCategoriesUpdate
				? {
						type: 'plannedRevenueMargin',
						value: financialNumbers.plannedRevenueMargin,
				  }
				: {
						type: 'plannedMargin',
						value: financialNumbers.plannedMargin,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'actualRevenueMargin',
						value: financialNumbers.actualRevenueMargin,
				  }
				: {
						type: 'actualRevenueMargin',
						value: financialNumbers.actualMargin,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'forecastRevenueMarginToComplete',
						value: financialNumbers.forecastRevenueMarginToComplete,
				  }
				: {
						type: 'forecastMarginToComplete',
						value: financialNumbers.forecastMarginToComplete,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'totalRevenueMarginAtCompletion',
						value: financialNumbers.totalRevenueMarginAtCompletion,
				  }
				: {
						type: 'totalMarginAtCompletion',
						value: financialNumbers.totalMarginAtCompletion,
				  },
			hasFinancialCategoriesUpdate
				? {
						type: 'baselineVsActualRevenueMargin',
						value: financialNumbers.baselineVsActualRevenueMargin,
				  }
				: {value: null},
			hasFinancialCategoriesUpdate
				? {
						type: 'baselineVsTotalRevenueMarginAtCompletion',
						value: financialNumbers.baselineVsTotalRevenueMarginAtCompletion,
				  }
				: {value: null},
			hasFinancialCategoriesUpdate
				? {
						type: 'planVsActualRevenueMargin',
						value: financialNumbers.planVsActualRevenueMargin,
				  }
				: {value: null},
			hasFinancialCategoriesUpdate
				? {
						type: 'planVsTotalRevenueMarginAtCompletion',
						value: financialNumbers.planVsTotalRevenueMarginAtCompletion,
				  }
				: {value: null},
			{
				type: 'recognitionProfitPercentage',
				value: financialNumbers.recognitionProfitPercentage,
			},
		];

		const marginRow = {
			dataArray: marginData,
			currency: currency,
			rowType: 'margin',
		};
		if (!hasRevenueWithoutCostAccess) {
			rows.push(marginRow);
		}
	}

	if (hasFinancialCategoriesUpdate && valueOfServiceData) {
		const valueOfServiceRow = {
			dataArray: valueOfServiceData,
			currency: currency,
			rowType: 'billableValueOfService',
		};
		rows.push(valueOfServiceRow);
	}

	const result = {data: {rows: [...rows]}};

	return children(result);
};
export const budgetTotalsLoaderQuery = graphql`
	query BudgetTotalsLoader_Query(
		$projectId: ID!
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
	) {
		viewer {
			actualPersonId
			component(name: "project_budget_v3_totals_loader")
			...BudgetTotalsLoader_viewer
				@arguments(
					projectId: $projectId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createFragmentContainer(BudgetTotalsLoader, {
	viewer: graphql`
		fragment BudgetTotalsLoader_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID!"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				# Fixed price v1
				budget

				rateCard {
					currency
				}
				billable
				useBaseline
				budgetType
				useFixedPriceForBaselineRevenue
				financialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					convertToProjectCurrency: true
				) {
					# Revenue
					plannedRevenue
					totalActualRevenueRecognition
					totalForecastRevenueToComplete
					totalRevenueRecognition
					baselineVsActualRevenueRecognition
					baselineVsTotalRevenueAtCompletion
					planVsActualRevenueRecognition
					planVsTotalRevenueAtCompletion
					baselineRevenue
					baselineVsBillableActualTimeAndExpenses
					baselineVsBillableTotalTimeAndExpensesAtCompletion
					billableActualTimeAndExpenses
					billablePlannedTimeAndExpenses
					planVsBillableActualTimeAndExpenses
					planVsTotalBillableTimeAndExpensesAtCompletion
					billableForecastTimeAndExpensesToComplete
					billableTotalTimeAndExpensesAtCompletion
					recognitionAmount
					baselineTimeAndExpenses

					# Cost
					baselineCost
					baselineVsActualCost
					baselineVsTotalCostAtCompletion
					actualCost
					plannedCost
					planVsActualCost
					planVsTotalCostAtCompletion
					forecastCostToComplete
					totalCostAtCompletion

					# Profit
					baselineProfit
					baselineVsActualProfit
					baselineVsTotalProfitAtCompletion
					actualProfit
					plannedProfit
					planVsActualProfit
					planVsTotalProfitAtCompletion
					forecastProfitToComplete
					totalProfitAtCompletion

					# Revenue Profit
					baselineVsActualRevenueProfit
					baselineVsTotalRevenueProfitAtCompletion
					actualRevenueProfit
					plannedRevenueProfit
					planVsActualRevenueProfit
					planVsTotalRevenueProfitAtCompletion
					forecastRevenueProfitToComplete
					totalRevenueProfitAtCompletion

					# Margin
					baselineMargin
					actualMargin
					plannedMargin
					forecastMarginToComplete
					totalMarginAtCompletion
					baselineVsActualRevenueMargin
					baselineVsTotalRevenueMarginAtCompletion
					planVsActualRevenueMargin
					planVsTotalRevenueMarginAtCompletion

					# Revenue Margin
					actualRevenueMargin
					plannedRevenueMargin
					forecastRevenueMarginToComplete
					totalRevenueMarginAtCompletion

					# Revenue Recognition
					recognitionAmount
					estimatedCost
					recognitionProfit
					recognitionProfitPercentage
				}
			}
		}
	`,
});
