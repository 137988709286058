const ActualBillableProjectTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.billable_time'}),
		details: [formatMessage({id: 'utilization.actual_billable_project_time.description'})],
	},
];

const ActualNonBillableProjectTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.non_billable_time'}),
		details: [formatMessage({id: 'utilization.actual_non_billable_project_time.description'})],
	},
];

const ActualInternalTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.internal_time'}),
		details: [formatMessage({id: 'utilization.actual_internal_time.description'})],
	},
];

const ActualTotalTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.total_time'}),
		details: [formatMessage({id: 'utilization.actual_total_time.description'})],
	},
];

const ActualOverTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.over_time'}),
		details: [
			formatMessage({id: 'utilization.actual_total_time.description'}),
			'–',
			formatMessage({id: 'utilization.available_time'}),
		],
	},
];

const ActualBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.billable_utilization'}),
		details: [
			formatMessage({id: 'utilization.actual_billable_project_time.description'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const ActualNonBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.non_billable_utilization'}),
		details: [
			formatMessage({id: 'utilization.actual_non_billable_project_time.description'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const ActualInternalUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.internal_utilization'}),
		details: [
			formatMessage({id: 'utilization.actual_internal_time.description'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const ActualResourceUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.resource_utilization'}),
		details: [
			formatMessage({id: 'utilization.actual_total_time.description'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const PlannedBillableProjectTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.billable_time'}),
		details: [formatMessage({id: 'utilization.planned_billable_project_time.description'})],
	},
];

const PlannedNonBillableProjectTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.non_billable_time'}),
		details: [formatMessage({id: 'utilization.planned_non_billable_project_time.description'})],
	},
];

const PlannedInternalTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.internal_time'}),
		details: [formatMessage({id: 'utilization.planned_internal_time.description'})],
	},
];

const PlannedTotalTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.total_time'}),
		details: [formatMessage({id: 'utilization.planned_total_time.description'})],
	},
];

const PlannedOverTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.over_time'}),
		details: [
			formatMessage({id: 'utilization.planned_total_time.description'}),
			'–',
			formatMessage({id: 'utilization.available_time'}),
		],
	},
];

const PlannedBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.billable_utilization'}),
		details: [
			formatMessage({id: 'utilization.planned_billable_project_time.description'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const PlannedNonBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.non_billable_utilization'}),
		details: [
			formatMessage({id: 'utilization.planned_non_billable_project_time.description'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const PlannedInternalUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.internal_utilization'}),
		details: [
			formatMessage({id: 'utilization.planned_internal_time.description'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const PlannedResourceUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.resource_utilization'}),
		details: [
			formatMessage({id: 'utilization.planned_total_time.description'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const PlannedCombinedBillableProjectHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.billable_time'}),
		description: formatMessage({id: 'utilization.planned_billable_time_combined_tooltip_description'}),
	},
];

const PlannedCombinedNonBillableProjectHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.non_billable_time'}),
		description: formatMessage({id: 'utilization.planned_non_billable_time_combined_tooltip_description'}),
	},
];

const PlannedCombinedInternalHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.internal_time'}),
		description: formatMessage({id: 'utilization.planned_internal_time_combined_tooltip_description'}),
	},
];

const PlannedCombinedTotalRegisteredHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.total_time'}),
		footer: formatMessage({id: 'utilization.planned_total_time_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.billable_time'}),
			'+',
			formatMessage({id: 'utilization.non_billable_time'}),
			'+',
			formatMessage({id: 'common.internal_time'}),
		],
	},
];

const PlannedCombinedBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.billable_utilization'}),
		footer: formatMessage({id: 'utilization.planned_billable_utilization_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.billable_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const PlannedCombinedNonBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.non_billable_utilization'}),
		footer: formatMessage({id: 'utilization.planned_non_billable_utilization_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.non_billable_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const PlannedCombinedInternalUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.internal_utilization'}),
		footer: formatMessage({id: 'utilization.planned_internal_utilization_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'common.internal_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const PlannedCombinedResourceUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.resource_utilization'}),
		footer: formatMessage({id: 'utilization.planned_resource_utilization_combined_tooltip_description'}),
		details: [formatMessage({id: 'utilization.total_time'}), '/', formatMessage({id: 'common.capacity'}), 'x', '100'],
	},
];

const ActualVsPlanBillableToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.billable_actual_vs_plan'}),
		details: [
			formatMessage({id: 'utilization.actual_billable_project_time.description'}),
			'–',
			formatMessage({id: 'utilization.planned_billable_project_time.description'}),
		],
	},
];

const ActualVsPlanNonBillableToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.non_billable_actual_vs_plan'}),
		details: [
			formatMessage({id: 'utilization.actual_non_billable_project_time.description'}),
			'–',
			formatMessage({id: 'utilization.planned_non_billable_project_time.description'}),
		],
	},
];

const ActualVsPlanInternalToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.internal_actual_vs_plan'}),
		details: [
			formatMessage({id: 'utilization.actual_internal_time.description'}),
			'–',
			formatMessage({id: 'utilization.planned_internal_time.description'}),
		],
	},
];

const ActualVsPlanTotalToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.total_actual_vs_plan'}),
		details: [
			formatMessage({id: 'utilization.actual_total_time.description'}),
			'–',
			formatMessage({id: 'utilization.planned_total_time.description'}),
		],
	},
];

const RemainingBillableProjectTimeToolTip = (isUsingAllocations, formatMessage) => [
	{
		title: formatMessage({id: 'utilization.billable_time'}),
		details: isUsingAllocations
			? [formatMessage({id: 'utilization.remaining_billable_project_time.project_allocation'})]
			: [formatMessage({id: 'utilization.remaining_billable_project_time.task_allocation'})],
	},
];

const RemainingNonBillableProjectTimeToolTip = (isUsingAllocations, formatMessage) => [
	{
		title: formatMessage({id: 'utilization.non_billable_time'}),
		details: isUsingAllocations
			? [formatMessage({id: 'utilization.remaining_non_billable_project_time.project_allocation'})]
			: [formatMessage({id: 'utilization.remaining_non_billable_project_time.task_allocation'})],
	},
];

const RemainingInternalTimeToolTip = (isUsingAllocations, formatMessage) => [
	{
		title: formatMessage({id: 'common.internal_time'}),
		details: isUsingAllocations
			? [formatMessage({id: 'utilization.remaining_internal_time.project_allocation'})]
			: [formatMessage({id: 'utilization.remaining_internal_time.task_allocation'})],
	},
];

const RemainingTotalTimeToolTip = (isUsingAllocations, formatMessage) => [
	{
		title: formatMessage({id: 'utilization.total_time'}),
		details: isUsingAllocations
			? [formatMessage({id: 'utilization.remaining_total_time.project_allocation'})]
			: [formatMessage({id: 'utilization.remaining_total_time.task_allocation'})],
	},
];

const RemainingOverTimeToolTip = (isUsingAllocations, formatMessage) => [
	{
		title: formatMessage({id: 'utilization.over_time'}),
		details: [
			isUsingAllocations
				? formatMessage({id: 'utilization.all_remaining_time'})
				: formatMessage({id: 'utilization.remaining_total_time.task_allocation'}),
			'–',
			formatMessage({id: 'utilization.available_time'}),
		],
	},
];

const RemainingBillableUtilizationToolTip = (isUsingAllocations, formatMessage) => [
	{
		title: formatMessage({id: 'common.billable_utilization'}),
		details: [
			isUsingAllocations
				? formatMessage({id: 'utilization.all_remaining_billable_time'})
				: formatMessage({id: 'utilization.remaining_billable_project_time.task_allocation'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const RemainingNonBillableUtilizationToolTip = (isUsingAllocations, formatMessage) => [
	{
		title: formatMessage({id: 'common.non_billable_utilization'}),
		details: [
			isUsingAllocations
				? formatMessage({id: 'utilization.all_remaining_non_billable_time'})
				: formatMessage({id: 'utilization.remaining_non_billable_project_time.task_allocation'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const RemainingInternalUtilizationToolTip = (isUsingAllocations, formatMessage) => [
	{
		title: formatMessage({id: 'utilization.internal_utilization'}),
		details: [
			isUsingAllocations
				? formatMessage({id: 'utilization.all_remaining_internal_time'})
				: formatMessage({id: 'utilization.remaining_internal_time.task_allocation'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const RemainingResourceUtilizationToolTip = (isUsingAllocations, formatMessage) => [
	{
		title: formatMessage({id: 'common.resource_utilization'}),
		details: [
			isUsingAllocations
				? formatMessage({id: 'utilization.all_remaining_time'})
				: formatMessage({id: 'utilization.remaining_total_time.task_allocation'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const RemainingCombinedBillableProjectHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.billable_time'}),
		description: formatMessage({id: 'utilization.remaining_billable_time_combined_tooltip_description'}),
	},
];

const RemainingCombinedNonBillableProjectHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.non_billable_time'}),
		description: formatMessage({id: 'utilization.remaining_non_billable_time_combined_tooltip_description'}),
	},
];

const RemainingCombinedInternalHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.internal_time'}),
		description: formatMessage({id: 'utilization.remaining_internal_time_combined_tooltip_description'}),
	},
];

const RemainingCombinedTotalRegisteredHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.total_time'}),
		footer: formatMessage({id: 'utilization.remaining_total_time_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.remaining_billable_time'}),
			'+',
			formatMessage({id: 'utilization.remaining_non_billable_time'}),
			'+',
			formatMessage({id: 'utilization.remaining_internal_time'}),
		],
	},
];

const RemainingCombinedBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.billable_utilization'}),
		footer: formatMessage({id: 'utilization.remaining_billable_utilization_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.billable_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const RemainingCombinedNonBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.non_billable_utilization'}),
		footer: formatMessage({id: 'utilization.remaining_non_billable_utilization_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.non_billable_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const RemainingCombinedInternalUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.internal_utilization'}),
		footer: formatMessage({id: 'utilization.remaining_internal_utilization_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'common.internal_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const RemainingCombinedResourceUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.resource_utilization'}),
		footer: formatMessage({id: 'utilization.remaining_resource_utilization_combined_tooltip_description'}),
		details: [formatMessage({id: 'utilization.total_time'}), '/', formatMessage({id: 'common.capacity'}), 'x', '100'],
	},
];

const ForecastedBillableProjectTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.billable_time'}),
		details: [
			formatMessage({id: 'utilization.actual_billable_project_time.description'}),
			'+',
			formatMessage({id: 'utilization.all_remaining_billable_time'}),
		],
	},
];

const ForecastedNonBillableProjectTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.non_billable_time'}),
		details: [
			formatMessage({id: 'utilization.actual_non_billable_project_time.description'}),
			'–',
			formatMessage({id: 'utilization.all_remaining_non_billable_time'}),
		],
	},
];

const ForecastedInternalTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.internal_time'}),
		details: [
			formatMessage({id: 'utilization.actual_internal_time.description'}),
			'+',
			formatMessage({id: 'utilization.all_remaining_internal_time'}),
		],
	},
];

const ForecastedTotalTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.total_time'}),
		details: [
			formatMessage({id: 'utilization.actual_total_time.description'}),
			'+',
			formatMessage({id: 'utilization.all_remaining_time'}),
		],
	},
];

const ForecastedOverTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.over_time'}),
		details: [
			formatMessage({id: 'utilization.all_forecasted_time'}),
			'–',
			formatMessage({id: 'utilization.available_time'}),
		],
	},
];

const ForecastedBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.billable_utilization'}),
		details: [
			formatMessage({id: 'utilization.forecasted_billable_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const ForecastedNonBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.non_billable_utilization'}),
		details: [
			formatMessage({id: 'utilization.forecasted_non_billable_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const ForecastedInternalUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.internal_utilization'}),
		details: [
			formatMessage({id: 'utilization.forecasted_internal_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const ForecastedResourceUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.resource_utilization'}),
		details: [
			formatMessage({id: 'utilization.all_forecasted_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const ForecastedCombinedBillableProjectHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.billable_time'}),
		footer: formatMessage({id: 'utilization.forecasted_billable_time_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.actual_billable_time'}),
			'+',
			formatMessage({id: 'utilization.remaining_tasks_allocations_billable_time'}),
		],
	},
];

const ForecastedCombinedNonBillableProjectHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.non_billable_time'}),
		footer: formatMessage({id: 'utilization.forecasted_non_billable_time_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.actual_non_billable_time'}),
			'+',
			formatMessage({id: 'utilization.remaining_tasks_allocations_non_billable_time'}),
		],
	},
];

const ForecastedCombinedInternalHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.internal_time'}),
		footer: formatMessage({id: 'utilization.forecasted_internal_time_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.actual_internal_time'}),
			'+',
			formatMessage({id: 'utilization.remaining_internal_time'}),
		],
	},
];

const ForecastedCombinedTotalRegisteredHoursToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.total_time'}),
		footer: formatMessage({id: 'utilization.forecasted_total_time_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.actual_total_time'}),
			'+',
			formatMessage({id: 'utilization.remaining_total_time'}),
		],
	},
];

const ForecastedCombinedBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.billable_utilization'}),
		footer: formatMessage({id: 'utilization.forecasted_billable_utilization_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.billable_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const ForecastedCombinedNonBillableUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.non_billable_utilization'}),
		footer: formatMessage({id: 'utilization.forecasted_non_billable_utilization_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'utilization.non_billable_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const ForecastedCombinedInternalUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.internal_utilization'}),
		footer: formatMessage({id: 'utilization.forecasted_internal_utilization_combined_tooltip_description'}),
		details: [
			formatMessage({id: 'common.internal_time'}),
			'/',
			formatMessage({id: 'utilization.available_time'}),
			'x',
			'100',
		],
	},
];

const ForecastedCombinedResourceUtilizationToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.resource_utilization'}),
		footer: formatMessage({id: 'utilization.forecasted_resource_utilization_combined_tooltip_description'}),
		details: [formatMessage({id: 'utilization.total_time'}), '/', formatMessage({id: 'common.capacity'}), 'x', '100'],
	},
];

const WorkingTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.working_time'}),
		details: [
			formatMessage({id: 'utilization.working_time.description'}),
			'-',
			formatMessage({id: 'utilization.public_holidays.description'}),
		],
	},
];

const TimeOffToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.time_off'}),
		details: [formatMessage({id: 'utilization.time_off.description'})],
	},
];

const AvailableTimeToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.available_time'}),
		details: [formatMessage({id: 'utilization.working_time'}), '–', formatMessage({id: 'common.time_off'})],
	},
];

const PerformanceToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.performance'}),
		details: [
			formatMessage({id: 'utilization.planned_time_on_done_tasks'}),
			'/',
			formatMessage({id: 'utilization.actual_time_on_done_tasks'}),
			'x',
			'100',
		],
	},
];

const PlannedBillableAllocationVsTaskToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.billable_allocation_vs_task'}),
		details: [
			formatMessage({id: 'utilization.planned_billable_project_allocation_time.description'}),
			'–',
			formatMessage({id: 'utilization.planned_billable_project_task_time.description'}),
		],
	},
];

const PlannedNonBillableAllocationVsTaskToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.non_billable_allocation_vs_task'}),
		details: [
			formatMessage({id: 'utilization.planned_non_billable_project_allocation_time.description'}),
			'–',
			formatMessage({id: 'utilization.planned_non_billable_project_task_time.description'}),
		],
	},
];

const PlannedTotalAllocationVsTaskToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.total_allocation_vs_task'}),
		details: [
			formatMessage({id: 'utilization.planned_total_allocation_time.description'}),
			'–',
			formatMessage({id: 'utilization.planned_total_task_time.description'}),
		],
	},
];

const RemainingBillableAllocationVsTaskToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.billable_allocation_vs_task'}),
		details: [
			formatMessage({id: 'utilization.remaining_billable_project_allocation_time.project_allocation'}),
			'–',
			formatMessage({id: 'utilization.remaining_billable_project_task_time.project_allocation'}),
		],
	},
];

const RemainingNonBillableAllocationVsTaskToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.non_billable_allocation_vs_task'}),
		details: [
			formatMessage({id: 'utilization.remaining_non_billable_project_allocation_time.project_allocation'}),
			'–',
			formatMessage({id: 'utilization.remaining_non_billable_project_task_time.project_allocation'}),
		],
	},
];

const RemainingTotalAllocationVsTaskToolTip = formatMessage => [
	{
		title: formatMessage({id: 'utilization.total_allocation_vs_task'}),
		details: [
			formatMessage({id: 'utilization.remaining_total_allocation_time.project_allocation'}),
			'–',
			formatMessage({id: 'utilization.remaining_total_task_time.project_allocation'}),
		],
	},
];

export const UtilizationReportToolTips = {
	ActualBillableProjectTimeToolTip,
	ActualNonBillableProjectTimeToolTip,
	ActualInternalTimeToolTip,
	ActualTotalTimeToolTip,
	ActualOverTimeToolTip,
	ActualBillableUtilizationToolTip,
	ActualNonBillableUtilizationToolTip,
	ActualInternalUtilizationToolTip,
	ActualResourceUtilizationToolTip,
	PlannedBillableProjectTimeToolTip,
	PlannedNonBillableProjectTimeToolTip,
	PlannedInternalTimeToolTip,
	PlannedTotalTimeToolTip,
	PlannedOverTimeToolTip,
	PlannedBillableUtilizationToolTip,
	PlannedNonBillableUtilizationToolTip,
	PlannedInternalUtilizationToolTip,
	PlannedResourceUtilizationToolTip,
	PlannedCombinedBillableProjectHoursToolTip,
	PlannedCombinedNonBillableProjectHoursToolTip,
	PlannedCombinedInternalHoursToolTip,
	PlannedCombinedTotalRegisteredHoursToolTip,
	PlannedCombinedBillableUtilizationToolTip,
	PlannedCombinedNonBillableUtilizationToolTip,
	PlannedCombinedInternalUtilizationToolTip,
	PlannedCombinedResourceUtilizationToolTip,
	ActualVsPlanBillableToolTip,
	ActualVsPlanNonBillableToolTip,
	ActualVsPlanInternalToolTip,
	ActualVsPlanTotalToolTip,
	RemainingBillableProjectTimeToolTip,
	RemainingNonBillableProjectTimeToolTip,
	RemainingInternalTimeToolTip,
	RemainingTotalTimeToolTip,
	RemainingOverTimeToolTip,
	RemainingBillableUtilizationToolTip,
	RemainingNonBillableUtilizationToolTip,
	RemainingInternalUtilizationToolTip,
	RemainingResourceUtilizationToolTip,
	RemainingCombinedBillableProjectHoursToolTip,
	RemainingCombinedNonBillableProjectHoursToolTip,
	RemainingCombinedInternalHoursToolTip,
	RemainingCombinedTotalRegisteredHoursToolTip,
	RemainingCombinedBillableUtilizationToolTip,
	RemainingCombinedNonBillableUtilizationToolTip,
	RemainingCombinedInternalUtilizationToolTip,
	RemainingCombinedResourceUtilizationToolTip,
	ForecastedBillableProjectTimeToolTip,
	ForecastedNonBillableProjectTimeToolTip,
	ForecastedInternalTimeToolTip,
	ForecastedTotalTimeToolTip,
	ForecastedOverTimeToolTip,
	ForecastedBillableUtilizationToolTip,
	ForecastedNonBillableUtilizationToolTip,
	ForecastedInternalUtilizationToolTip,
	ForecastedResourceUtilizationToolTip,
	ForecastedCombinedBillableProjectHoursToolTip,
	ForecastedCombinedNonBillableProjectHoursToolTip,
	ForecastedCombinedInternalHoursToolTip,
	ForecastedCombinedTotalRegisteredHoursToolTip,
	ForecastedCombinedBillableUtilizationToolTip,
	ForecastedCombinedNonBillableUtilizationToolTip,
	ForecastedCombinedInternalUtilizationToolTip,
	ForecastedCombinedResourceUtilizationToolTip,
	WorkingTimeToolTip,
	TimeOffToolTip,
	AvailableTimeToolTip,
	PerformanceToolTip,
	PlannedBillableAllocationVsTaskToolTip,
	PlannedNonBillableAllocationVsTaskToolTip,
	PlannedTotalAllocationVsTaskToolTip,
	RemainingBillableAllocationVsTaskToolTip,
	RemainingNonBillableAllocationVsTaskToolTip,
	RemainingTotalAllocationVsTaskToolTip,
};
