import _ from 'lodash';
import {
	getPlaceholderAllocationsByProjectId,
	ITEM_TYPE,
	HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE,
	GROUP_TYPE,
	useItemsLazyLoading,
} from './canvas-timeline/canvas_timeline_util';
import {isStepHiddenByLoadMore} from './loading/LoadMoreUtil';
import {isProjectAllocationItem, isTaskItem} from './SchedulingUtils';
import {getCachedHeatmapData} from './heatmap/HeatmapLogic';
import DataManager from './DataManager';
import RecalculationManager from './RecalculationManager';

export const filterGroupsItems = (pageComponent, items, groups, isItemFilteredPredicate = () => false) => {
	const groupIdToFilteredMap = new Map();
	const {heatmapFiltering} = pageComponent.state;
	groups?.forEach(group => {
		groupIdToFilteredMap.set(group.data.id, !!group.filtered);
	});
	items?.forEach(item => {
		const groupIsFiltered = groupIdToFilteredMap.get(item.groupId);
		if (groupIsFiltered !== undefined) {
			const filtered = groupIsFiltered || isItemFilteredPredicate(item);
			if (!item.filtered !== !filtered && heatmapFiltering) {
				RecalculationManager.recalculateItemHeatmap(item);
			}
			item.filtered = filtered;
		}
	});
};

export const filterGroupsItemsCached = (pageComponent, items, filteredGroupCache, isItemFilteredPredicate = () => false) => {
	const {heatmapFiltering} = pageComponent.state;
	if (items?.length > 0) {
		for (let i = 0; i < items.length; i++) {
			const item = items[i];

			const groupIsFiltered = filteredGroupCache.get(item.groupId);
			if (groupIsFiltered !== undefined) {
				const filtered = groupIsFiltered || isItemFilteredPredicate(item);
				if (!item.filtered !== !filtered && heatmapFiltering) {
					RecalculationManager.recalculateItemHeatmap(item);
				}
				item.filtered = filtered;
			}
		}
	}
};

// maps data to match the structure the filter expects
export const mapAllocationForFilter = allocation => {
	return {
		...allocation,
		idleTime: allocation.idleTimeId
			? {
					id: allocation.idleTimeId,
			  }
			: undefined,
		project: allocation.projectId
			? {
					id: allocation.projectId,
			  }
			: undefined,
		projectGroup: allocation.projectGroupId
			? {
					id: allocation.projectGroupId,
			  }
			: undefined,
	};
};

const mapProjectAllocation = allocation => {
	const {id, isSoft} = allocation;

	return {
		node: {id, isSoft},
	};
};

const mapPlaceholderAllocation = allocation => {
	const {id} = allocation;

	return {
		node: {id},
	};
};

export const isProjectFiltered = (pageComponent, projectId) => {
	const {filterFunctions, filters} = pageComponent.state;

	if (filterFunctions && filters?.project) {
		const data = pageComponent.getFilterData() || pageComponent.getData() || pageComponent.state.data;

		let budgetType;
		let assignedPersons = [];
		let currentProjectStatus;
		let projectPersons = {};
		let projectLabels = [];
		let projectAllocations = [];
		const isConnectedProject = atob(projectId).startsWith('ProjectGroup');
		let project;
		let subProjects;

		if (isConnectedProject) {
			project = data.projectGroupMap.get(projectId);
			subProjects = data.projectByProjectGroupMap[projectId] || [];

			if (subProjects.length > 0) {
				const subProjectsIds = subProjects.map(p => p.id);
				const firstProjectId = subProjectsIds[0];

				budgetType = subProjects.map(p => p.budgetType);
				const projectPersonsOnProject = data.projectPersonByProjectMap[firstProjectId] || [];
				assignedPersons = projectPersonsOnProject.map(pp => ({id: pp.personId}));
				currentProjectStatus = _.uniq(
					subProjectsIds.map(projectId => data.projectStatusMap.get(projectId)).filter(projectStatus => projectStatus)
				);
				projectPersons = {
					edges: projectPersonsOnProject.map(pp => ({
						node: {isContactPerson: pp.isContactPerson, person: {id: pp.personId}},
					})),
				};
				projectLabels = {
					edges: _.uniq(
						_.flatten(subProjectsIds.map(projectId => data.projectLabelByProjectMap[projectId] || []))
					).map(pl => ({node: {label: {id: pl.labelId}}})),
				};

				if (data.allocations) {
					const allocations = data.allocationsByProjectOrProjectGroup[projectId];

					if (allocations) {
						projectAllocations = {
							edges: allocations.map(allocation => mapProjectAllocation(allocation)),
						};
					}
				}
			}
		} else {
			project = data.projectMap.get(projectId);

			budgetType = project.budgetType;
			const projectPersonsOnProject = data.projectPersonByProjectMap[project.id] || [];
			assignedPersons = projectPersonsOnProject.map(pp => ({id: pp.personId}));
			currentProjectStatus = data.projectStatusMap.get(project.id);
			projectPersons = {
				edges: projectPersonsOnProject.map(pp => ({
					node: {isContactPerson: pp.isContactPerson, person: {id: pp.personId}},
				})),
			};
			const projectLabelsOnProject = data.projectLabelByProjectMap[project.id] || [];
			projectLabels = {
				edges: projectLabelsOnProject.map(pl => ({node: {label: {id: pl.labelId}}})),
			};

			if (data.allocations) {
				const allocations = data.allocationsByProjectOrProjectGroup[projectId];

				if (allocations) {
					projectAllocations = {
						edges: allocations.map(allocation => mapProjectAllocation(allocation)),
					};
				}
			}
		}

		const mapProject = project => {
			project.client = {id: project.clientId};
			return project;
		};

		const groupFilterData = {
			isIdleTime: false,
			budgetType,
			baselineWinChance: project.baselineWinChance,
			projects: isConnectedProject ? subProjects.map(mapProject) : undefined,
			project: isConnectedProject ? undefined : mapProject(project),
			assignedPersons,
			currentProjectStatus,
			projectPersons,
			projectLabels,
			allocations: projectAllocations,
		};

		if (data.placeholders && data.placeholderAllocations) {
			groupFilterData.placeholderAllocations = getPlaceholderAllocationsByProjectId(pageComponent, projectId).map(
				allocation => mapPlaceholderAllocation(allocation)
			);
		}

		return !filterFunctions.projectFilter(groupFilterData);
	}

	return false;
};

export const isProjectFilteredCached = (pageComponent, projectFilteredCache, projectId) => {
	if (projectId) {
		let isFiltered = projectFilteredCache.get(projectId);
		if (isFiltered === undefined) {
			isFiltered = isProjectFiltered(pageComponent, projectId);
			projectFilteredCache.set(projectId, isFiltered);
		}
		return isFiltered;
	}
	return false;
};

export const isFilteredByIdleTimes = (pageComponent, item) => {
	const {filters, filterFunctions} = pageComponent.state;
	const isFilteringIdleTimes = !!filters?.project?.internalTime || !!filters?.project?.timeOff;

	if (isFilteringIdleTimes) {
		const isAllowedItem = item => item.itemType === ITEM_TYPE.PROJECT_ALLOCATION;

		if (isAllowedItem(item) && item.data.allocation?.idleTimeId) {
			const allocationItemData = mapAllocationForFilter(item.data.allocation);
			const isFiltered = !filterFunctions.allocationFilter(allocationItemData);

			if (isFiltered) {
				return true;
			}
		}
	}

	return false;
};

export const isPlaceholderFiltered = (pageComponent, placeholderId) => {
	const {filterFunctions, data, filters} = pageComponent.state;

	if (filterFunctions && filters?.placeholder) {
		const placeholder = data.placeholders.find(placeholder => placeholder.id === placeholderId);

		// ROLE
		const role = placeholder.roleId ? data.roles.find(role => role.id === placeholder.roleId) : undefined;

		// SKILLS
		const placeholderSkillsFilters = (data.placeholderSkillsByPlaceholder[placeholderId] || []).map(ps => ({
			skill: {skillId: ps.skillId, skillLevelId: ps.skillLevelId || null},
		}));

		// TEAMS
		const teamIds = (data.placeholderTeamsByPlaceholder[placeholderId] || []).map(
			placeholderTeam => placeholderTeam.teamId
		);

		// PROJECT
		const project = placeholder.projectId
			? data.projects.find(project => project.id === placeholder.projectId)
			: data.projectGroups.find(projectGroup => projectGroup.id === placeholder.projectGroupId);

		const groupFilterData = {
			role,
			personSkills: placeholderSkillsFilters,
			project,
			departmentId: placeholder.departmentId,
			teamIds,
		};

		return !filterFunctions.placeholderFilter(groupFilterData);
	}

	return false;
};

export const isPlaceholderFilteredNew = (pageComponent, placeholderId) => {
	const {filterFunctions, data, filters} = pageComponent.state;

	if (filterFunctions && filters?.placeholder) {
		const placeholder = data.placeholderMap.get(placeholderId);
		const role = placeholder.roleId ? data.roleMap.get(placeholder.roleId) : undefined;

		// SKILLS
		const placeholderSkillsFilters = (data.placeholderSkillsByPlaceholder[placeholderId] || []).map(ps => ({
			skill: {skillId: ps.skillId, skillLevelId: ps.skillLevelId || null},
		}));

		// PROJECT
		const project = placeholder.projectId
			? data.projectMap.get(placeholder.projectId)
			: data.projectGroupMap.get(placeholder.projectGroupId);

		// TEAMS
		const teamIds = (data.placeholderTeamsByPlaceholder[placeholderId] || []).map(
			placeholderTeam => placeholderTeam.teamId
		);

		const groupFilterData = {
			role,
			personSkills: placeholderSkillsFilters,
			project,
			departmentId: placeholder.departmentId,
			teamIds,
		};

		return !filterFunctions.placeholderFilter(groupFilterData);
	}

	return false;
};

const addToWorkloadArray = (workloadArray, minutesAllocated, minutesAvailable) => {
	const isNotAllocated = minutesAllocated === 0;
	const isBookedInStep = minutesAllocated > 0;
	const isAvailable = minutesAvailable > 0;
	const isOverallocated = minutesAllocated > minutesAvailable;
	const isWithinCheckmarkRange = isOverallocated
		? minutesAllocated / minutesAvailable - 1 < HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE / 100
		: minutesAllocated / minutesAvailable - 1 > -HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE / 100;

	if (isOverallocated && !isWithinCheckmarkRange) {
		workloadArray.push('overbooked');
	}

	if (isAvailable && !isOverallocated && !isWithinCheckmarkRange) {
		workloadArray.push('underbooked');
	}

	if (isWithinCheckmarkRange) {
		workloadArray.push('fullybooked');
	}

	if (isBookedInStep) {
		workloadArray.push('booked');
	}

	if (isNotAllocated && isAvailable) {
		workloadArray.push('noworkload');
	}
};

export const isPersonFiltered = (pageComponent, group) => {
	const {data, filterFunctions, filters, items} = pageComponent.state;

	let visibleByFilter = true;
	if (filterFunctions && filters?.person) {
		const {
			minorStepDataArray: stepDataArray,
			minorStep: timelineMinorStep,
			startDate,
			state: {canvasWidth},
			pixelsPerDay,
		} = pageComponent.timeline;

		const groupPersonId = group.data?.personId || group.id;

		// WORKLOAD FILTER
		let workloadArray = []; // array that contains the workload capacity for each step in the canvas
		if (filters?.person?.capacityWorkload) {
			group.calculateHeatmapCache(group, stepDataArray, timelineMinorStep);

			for (const stepData of stepDataArray) {
				if (isStepHiddenByLoadMore(pageComponent, stepData)) continue;

				const heatMapData = getCachedHeatmapData(
					pageComponent,
					group,
					timelineMinorStep,
					stepData.startDate,
					stepData.endDate
				);

				if (!heatMapData) {
					continue;
				}

				const {minutesAllocated, minutesAvailable} = heatMapData;
				addToWorkloadArray(workloadArray, minutesAllocated, minutesAvailable);
			}
		}

		const isFilteringPersonProjects = filters.person?.project?.length > 0;
		const isFilteringPersonInternalTime = filters.person?.internalTime?.length > 0;
		const isFilteringPersonTimeOffs = filters.person?.timeOff?.length > 0;

		let allocatedToIds = [];
		if (isFilteringPersonProjects || isFilteringPersonInternalTime || isFilteringPersonTimeOffs) {
			const endDate = startDate + canvasWidth / pixelsPerDay;

			const projectItemsAndIdle = items.filter(item => {
				if (!isProjectAllocationItem(item)) {
					return false;
				}

				const {personId} = item.data.allocation;
				return groupPersonId === personId && !(item.startDate > endDate || item.endDate < startDate - 1);
			});

			// Check task allocation projects
			const taskAllocationProjectIds = group.groups
				.filter(
					projectGroup => projectGroup.groupType === GROUP_TYPE.PROJECT && !projectGroup.data.useManualAllocations
				)
				.map(projectGroup => projectGroup.id + '-task');

			const taskItems = items.filter(
				item =>
					item.itemType === ITEM_TYPE.TASK &&
					!(item.startDate > endDate || item.endDate < startDate - 1) &&
					!item.groupId.includes('unassignedtask') &&
					taskAllocationProjectIds.includes(`${item.groupId}-task`)
			);

			allocatedToIds = [
				...projectItemsAndIdle.map(item => {
					const {projectId, projectGroupId, idleTimeId} = item.data.allocation;
					// return id based on the allocation type. It could be project, projectgroup or idle time
					return projectId || projectGroupId || idleTimeId;
				}),
				...taskItems.map(taskItem => taskItem.data.projectId),
			];
		}

		// ALLOCATIONS
		const allocations = data.allocations?.filter(allocation => allocation.personId === groupPersonId);

		// LABELS
		const personLabelsForPerson = data.personLabelByPersonMap[groupPersonId] || [];
		const labels = personLabelsForPerson.map(pl => ({label: {...pl, id: pl.labelId}}));

		// SKILLS
		const personSkillsForPerson = data.personSkillsByPersonMap[groupPersonId] || [];
		const skills = personSkillsForPerson.map(ps => ({skill: {...ps}}));

		// PROJECTS
		const projectPeriodsForPerson = data.projectPersonByPersonMap[groupPersonId] || [];
		const personProjects = projectPeriodsForPerson.map(entry => ({id: entry.projectId}));

		const personGroup = {
			id: groupPersonId,
			allocatedToIds,
			departmentId: group.data.departmentId,
			role: {id: group.data.roleId},
			projects: personProjects,
			workloadArray: _.uniq(workloadArray),
			personLabels: {edges: labels},
			personSkills: skills,
			allocations,
		};

		// TEAMS
		const options = {
			teams: data.teamsRelay,
		};

		visibleByFilter = filterFunctions.personFilter(personGroup, options);
	}

	return !visibleByFilter;
};

export const isPersonFilteredNew = (pageComponent, group) => {
	const {data, filterFunctions, filters} = pageComponent.state;

	let visibleByFilter = true;
	if (filterFunctions && filters?.person) {
		const {
			minorStepDataArray: stepDataArray,
			minorStep: timelineMinorStep,
			startDate,
			state: {canvasWidth},
			pixelsPerDay,
		} = pageComponent.timeline;

		const groupPersonId = group.data?.personId || group.id;

		// WORKLOAD FILTER
		let workloadArray = []; // array that contains the workload capacity for each step in the canvas
		if (filters?.person?.capacityWorkload) {
			group.calculateHeatmapCache(group, stepDataArray, timelineMinorStep);

			for (const stepData of stepDataArray) {
				if (isStepHiddenByLoadMore(pageComponent, stepData)) continue;

				const heatMapData = getCachedHeatmapData(
					pageComponent,
					group,
					timelineMinorStep,
					stepData.startDate,
					stepData.endDate
				);

				if (!heatMapData) {
					continue;
				}

				const {minutesAllocated, minutesAvailable} = heatMapData;
				addToWorkloadArray(workloadArray, minutesAllocated, minutesAvailable);
			}
		}

		// ALLOCATIONS
		const allocations = data.personAllocationMap[groupPersonId] || [];

		// LABELS
		const personLabelsForPerson = data.personLabelByPersonMap[groupPersonId] || [];
		const labels = personLabelsForPerson.map(pl => ({label: {...pl, id: pl.labelId}}));

		// SKILLS
		const personSkillsForPerson = data.personSkillsByPersonMap[groupPersonId] || [];
		const skills = personSkillsForPerson.map(ps => ({skill: {...ps}}));

		// PROJECTS
		const projectPeriodsForPerson = data.projectPersonByPersonMap[groupPersonId] || [];
		const personProjects = projectPeriodsForPerson.map(entry => ({id: entry.projectId}));

		// ALLOCATED TO
		const isFilteringPersonProjects = filters.person?.project?.length > 0;
		const isFilteringPersonInternalTime = filters.person?.internalTime?.length > 0;
		const isFilteringPersonTimeOffs = filters.person?.timeOff?.length > 0;
		const hasAllocatedToFilterApplied =
			isFilteringPersonProjects || isFilteringPersonInternalTime || isFilteringPersonTimeOffs;

		const allocatedToIds = [];
		if (hasAllocatedToFilterApplied) {
			const endDate = startDate + canvasWidth / pixelsPerDay;

			// Check task allocation projects
			const projectGroups = group.groups;
			const projectsNotUsingManualAllocations = new Set();
			for (let i = 0; i < projectGroups.length; i++) {
				const projectGroup = projectGroups[i];

				const isProjectGroup = projectGroup.groupType === GROUP_TYPE.PROJECT;
				const isUsingManualAllocations = projectGroup?.data?.useManualAllocations;

				if (isProjectGroup && !isUsingManualAllocations) {
					projectsNotUsingManualAllocations.add(projectGroup.id);
				}
			}

			const groupsItems = DataManager.getVisibleItemsData(pageComponent, group, startDate, endDate);

			for (const allocationItem of groupsItems.allocationItems) {
				const {projectId, projectGroupId, idleTimeId} = allocationItem.data.allocation;
				allocatedToIds.push(projectId || projectGroupId || idleTimeId);
			}

			for (const taskItem of groupsItems.taskItems) {
				if (projectsNotUsingManualAllocations.has(taskItem.groupId)) {
					const {projectId} = taskItem.data;
					allocatedToIds.push(projectId);
				}
			}
		}

		const personGroup = {
			id: groupPersonId,
			allocatedToIds,
			departmentId: group.data.departmentId,
			role: {id: group.data.roleId},
			projects: personProjects,
			workloadArray: _.uniq(workloadArray),
			personLabels: {edges: labels},
			personSkills: skills,
			allocations,
		};

		// TEAMS
		const options = {
			teams: data.teamsRelay,
		};

		visibleByFilter = filterFunctions.personFilter(personGroup, options);
	}

	return !visibleByFilter;
};

export const isTaskFiltered = (pageComponent, taskItem) => {
	const {data, filterFunctions, filters} = pageComponent.state;

	if (filterFunctions && filters?.task && isTaskItem(taskItem)) {
		const {task} = taskItem.data;

		// maps data to match the structure the filter expects
		const statusColumn = data.statusColumnMap.get(task.statusColumnId);
		const assignedPersons = task.assignedPersons?.map(assignedId => data.personMap.get(assignedId)) || [];
		const ownerId = task.ownerId ? task.ownerId : null;
		const taskLabelsOnTask = task.taskLabels || [];
		const taskLabels = taskLabelsOnTask.map(label => ({label: {id: label.labelId}}));
		const followers = task.followers ? task.followers.map(fId => ({id: fId})) : [];

		const taskFilterData = {
			role: {
				id: task.roleId,
			},
			statusColumnV2: {
				category: statusColumn && statusColumn.category ? statusColumn.category : null,
				id: task.statusColumnId,
			},
			project: {
				id: task.projectId,
			},
			phase: {
				id: task.phaseId,
			},
			owner: {id: ownerId},
			followers,
			taskLabels,
			assignedPersons,
			bug: task.bug,
			blocked: task.blocked,
			hasDependency: task.hasDependency,
			highPriority: task.highPriority,
			billable: task.billable,
			favoured: task.favoured,
		};

		if (!useItemsLazyLoading(pageComponent)) {
			taskFilterData.latestUiUpdateAt = task.latestUiUpdateAt;
		}

		return !filterFunctions.taskFilter(taskFilterData);
	}

	return false;
};

export const isTaskItemProjectFilteredCached = (pageComponent, projectFilterCache, taskItem) => {
	return (
		isTaskFiltered(pageComponent, taskItem) ||
		(isTaskItem(taskItem) && isProjectFilteredCached(pageComponent, projectFilterCache, taskItem.data?.task?.projectId))
	);
};

export const filterFreeDragCreatedGroups = (pageComponent, item, dragData) => {
	const previouslyHoveredGroup = dragData.previousGroupData?.group;

	if (previouslyHoveredGroup) {
		const hoveredGroup = dragData.groupData?.group;
		const isHoveringSameGroup = previouslyHoveredGroup === hoveredGroup;

		if (item.isFreeDragDropOffGroup(previouslyHoveredGroup) && !isHoveringSameGroup) {
			const previouslyHoveredOuterGroup = item.isFreeDragOuterGroup(previouslyHoveredGroup)
				? previouslyHoveredGroup
				: previouslyHoveredGroup.parentGroup;

			const currentlyHoveredOuterGroup = hoveredGroup
				? item.isFreeDragDropOffGroup(hoveredGroup)
					? hoveredGroup
					: hoveredGroup.parentGroup
				: null;

			if (!currentlyHoveredOuterGroup || previouslyHoveredOuterGroup.id !== currentlyHoveredOuterGroup.id) {
				const hasItems = group => group.items?.length;
				const onlyItemIsDraggingItem = group => group.items.length === 1 && group.items[0] === dragData.itemData?.item;

				previouslyHoveredOuterGroup.groups.forEach(subGroup => {
					if (subGroup.createdByFreeDrag && (!hasItems(subGroup) || onlyItemIsDraggingItem(subGroup))) {
						previouslyHoveredOuterGroup.removeChildGroup(subGroup.id);
					}
				});
			}
		}
	}
};
