import {cloneDeep} from 'lodash';
import React from 'react';
import Moment from 'moment';
import TooltipContainer from '../../../forecast-app/shared/components/tooltips/tooltip_container';
import {
	actualPriceTooltip,
	actualVarianceToPlanPriceTooltip,
	allocationTooltip,
	baselineTooltip,
	companyFinancialsTooltip,
	fixedPriceTooltip,
	forecastHoursTooltip,
	forecastPriceTooltip,
	forecastVarianceToPlanPriceTooltip,
	highTooltip,
	invoiceTooltip,
	lowTooltip,
	planPriceTooltip,
	registeredHoursTooltip,
	remainingHoursTooltip,
	remainingPriceTooltip,
	scopeTooltip,
} from './financial_report_page_tooltips';

const transposeEyeOptions = eyeOptions => {
	const eyeOptionsClone = cloneDeep(eyeOptions);

	return eyeOptionsClone.reduce((transposed, option) => {
		if (option.name === 'periodTotals' || option.name === 'projectTotals') {
			option.nestedOptions.forEach(nestedOption => {
				//nestedOption.checked = true;
				if (nestedOption.checked) {
					if (!transposed[nestedOption.name]) {
						transposed[nestedOption.name] = {};
					}
					transposed[nestedOption.name][option.name] = nestedOption;
					if (Array.isArray(nestedOption.nestedOptions)) {
						nestedOption.nestedOptions = transposeEyeOptions(nestedOption.nestedOptions);
					}
				}
			});
		} else {
			//option.checked = true;
			if (option.checked) {
				transposed[option.name] = option;
				if (Array.isArray(option.nestedOptions)) {
					option.nestedOptions = transposeEyeOptions(option.nestedOptions);
				}
			}
		}

		return transposed;
	}, {});
};

const selectRootField = field => subField => project => project[field] ? project[field][subField] : null;
const selectRootPeriodTotalField = field => periodTotal => subField => project =>
	project[field] && project[field][subField] ? project[field][subField][periodTotal] : null;
const selectDetails = field => project => field ? project.details[field] : project.details;

const selectTotals = field => subField => totals =>
	totals !== undefined && totals[field] !== undefined ? totals[field][subField] : null;
const selectPeriodProjectTotals = field => periodTotal => subField => totals =>
	totals[field] && totals[field][subField] ? totals[field][subField][periodTotal] : null;

const breakdownSelector = field => project => project[field] ? project[field].breakdown : null;
const breakdownRevCosProMarPerTotSelector = periodTotal => subField => value =>
	value !== undefined && value[subField] !== undefined ? value[subField][periodTotal] : null;
const breakdownPerTotSelector = periodTotal => value => value !== undefined ? value[periodTotal] : null;
const breakdownAppAllPerTotSelector = periodTotal => appAll => value =>
	value !== undefined && value[appAll] !== undefined ? value[appAll][periodTotal] : null;
const breakdownEmptyValueSelector = () => () => () => null;

const traverseBreakdown = (breakdown, breakdownValueSelector, phaseCallback, roleCallback) => {
	if (breakdown) {
		Object.keys(breakdown).forEach(phaseId => {
			const phaseBreakdown = breakdown[phaseId];
			const phaseValue = breakdownValueSelector(phaseBreakdown.tot);
			phaseCallback(phaseId, phaseValue);
			const roleBreakdown = phaseBreakdown.brk;
			Object.keys(roleBreakdown).forEach(roleId => {
				const roleValue = breakdownValueSelector(roleBreakdown[roleId]);
				roleCallback(phaseId, roleId, roleValue);
			});
		});
	}
};

const addSubColumn = (
	parentColumn,
	id,
	type,
	title,
	options,
	checkOption,
	totals,
	totalSelector,
	projects,
	projectFieldSelector,
	breakdownSelector,
	breakdownValueSelector
) => {
	let shouldAdd = (checkOption && options[checkOption]) || !checkOption;
	if (shouldAdd) {
		const allProjects = projects.reduce((flatArray, project) => {
			flatArray.push(project);
			if (project.subProjects) {
				flatArray.push(...project.subProjects);
			}
			return flatArray;
		}, []);

		const breakdownValues = allProjects.reduce((breakdownMap, project) => {
			if (breakdownSelector) {
				let projectBreakdown = breakdownMap[project.id];
				if (!projectBreakdown) {
					projectBreakdown = {};
					breakdownMap[project.id] = projectBreakdown;
				}

				traverseBreakdown(
					breakdownSelector(project),
					breakdownValueSelector,
					(phaseId, phaseValue) => {
						let phaseBreakdown = projectBreakdown[phaseId];
						if (!phaseBreakdown) {
							phaseBreakdown = {
								value: phaseValue,
								roleBreakdown: {},
							};
							projectBreakdown[phaseId] = phaseBreakdown;
						}
					},
					(phaseId, roleId, roleValue) => {
						projectBreakdown[phaseId].roleBreakdown[roleId] = roleValue;
					}
				);
			}
			return breakdownMap;
		}, {});

		const projectValues = allProjects.reduce((projectMap, project) => {
			// special case because future progress is an array that needs to be mapped to format we want to display
			if (id === 'future_progress') {
				const valuesArray = projectFieldSelector(project);
				if (valuesArray && valuesArray.length) {
					projectMap[project.id] = (
						<div style={{display: 'flex'}} id={valuesArray}>
							{valuesArray.map((el, index) => (
								<TooltipContainer
									tooltipInfinteDuration={true}
									infoText={Moment()
										.startOf('month')
										.add(1 + index, 'month')
										.format('MMMM YYYY')}
								>
									{' '}
									{Math.round(el * 100) + (index === valuesArray.length - 1 ? '%' : '% / ')}
								</TooltipContainer>
							))}
						</div>
					);
				}
			} else {
				projectMap[project.id] = projectFieldSelector(project);
			}

			return projectMap;
		}, {});
		parentColumn.subColumns.push({
			id,
			type,
			title,
			total: totalSelector ? totalSelector(totals) : null,
			projectValues,
			breakdownValues,
		});
	}
};

const addSimpleColumn = (pageStructure, id, type, title, options, checkOption, projectSelector) => {
	if (options[checkOption]) {
		const column = {
			id,
			title: null,
			toolTip: null,
			subColumns: [],
		};
		pageStructure.columns.push(column);
		addSubColumn(
			column,
			id + '.simple',
			type,
			title,
			options,
			null,
			null,
			null,
			pageStructure.projectLines,
			projectSelector
		);
	}
};

const addRevenueCostProfitMarginSubColumn = (
	intl,
	parentColumn,
	id,
	type,
	options,
	totals,
	totalSelector,
	projects,
	projectFieldSelector,
	breakdownSelector,
	breakdownValueSelector
) => {
	const {formatMessage} = intl;
	addSubColumn(
		parentColumn,
		id + '.rev',
		type,
		formatMessage({id: 'project_budget.revenue'}).toUpperCase(),
		options,
		'revenue',
		totals,
		totalSelector('rev'),
		projects,
		projectFieldSelector('rev'),
		breakdownSelector,
		breakdownValueSelector('rev')
	);
	addSubColumn(
		parentColumn,
		id + '.cos',
		type,
		formatMessage({id: 'common.cost'}).toUpperCase(),
		options,
		'cost',
		totals,
		totalSelector('cos'),
		projects,
		projectFieldSelector('cos'),
		breakdownSelector,
		breakdownValueSelector('cos')
	);
	addSubColumn(
		parentColumn,
		id + '.pro',
		type,
		formatMessage({id: 'common.profit'}).toUpperCase(),
		options,
		'profit',
		totals,
		totalSelector('pro'),
		projects,
		projectFieldSelector('pro'),
		breakdownSelector,
		breakdownValueSelector('pro')
	);
	addSubColumn(
		parentColumn,
		id + '.mar',
		'percentage',
		formatMessage({id: 'common.margin'}).toUpperCase(),
		options,
		'margin',
		totals,
		totalSelector('mar'),
		projects,
		projectFieldSelector('mar'),
		breakdownSelector,
		breakdownValueSelector('mar')
	);
};

const addRevenueCostProfitMarginColumn = (
	intl,
	pageStructure,
	id,
	type,
	title,
	toolTip,
	options,
	checkOption,
	totals,
	totalSelector,
	projectSelector,
	highlighted,
	breakdownSelector,
	breakdownValueSelector
) => {
	if (options[checkOption]) {
		const revenueCostProfitMarginColumn = {
			id,
			title,
			toolTip,
			subColumns: [],
			highlighted,
		};
		addRevenueCostProfitMarginSubColumn(
			intl,
			revenueCostProfitMarginColumn,
			id,
			'price',
			options[checkOption].nestedOptions,
			totals,
			totalSelector,
			pageStructure.projectLines,
			projectSelector,
			breakdownSelector,
			breakdownValueSelector
		);
		pageStructure.columns.push(revenueCostProfitMarginColumn);
	}
};

const addRevenueCostProfitMarginPeriodTotalColumn = (
	intl,
	pageStructure,
	id,
	type,
	title,
	toolTip,
	options,
	checkOption,
	totals,
	totalSelector,
	projectSelector,
	breakdownSelector,
	breakdownValueSelector
) => {
	if (options[checkOption]) {
		const {formatMessage} = intl;
		addRevenueCostProfitMarginColumn(
			intl,
			pageStructure,
			id + '.per',
			type,
			`${title} (${formatMessage({id: 'common.period'})})`,
			toolTip,
			options[checkOption],
			'periodTotals',
			totals,
			totalSelector('per'),
			projectSelector('per'),
			true,
			breakdownSelector,
			breakdownValueSelector('per')
		);
		addRevenueCostProfitMarginColumn(
			intl,
			pageStructure,
			id + '.tot',
			type,
			`${title} (${formatMessage({id: 'common.total'})})`,
			toolTip,
			options[checkOption],
			'projectTotals',
			totals,
			totalSelector('tot'),
			projectSelector('tot'),
			false,
			breakdownSelector,
			breakdownValueSelector('tot')
		);
	}
};

const addFutureProgressTotalColumn = (intl, pageStructure, id, checkOption, title, type, options) => {
	if (options[checkOption]) {
		const totalColumn = {
			id: `${id} tot`,
			title: intl.formatMessage({id: 'insights.component.list.column.progressFutureArray'}),
			toolTip: null,
			subColumns: [],
		};
		addSubColumn(
			totalColumn,
			id,
			type,
			null,
			options[checkOption],
			false,
			null,
			null,
			pageStructure.projectLines,
			selectRootField('calculatedFields')('progressPredictionArray'),
			null,
			null
		);
		pageStructure.columns.push(totalColumn);
	}
};

const addPeriodTotalColumn = (
	intl,
	pageStructure,
	id,
	type,
	title,
	toolTip,
	options,
	checkOption,
	totals,
	totalSelector,
	projectSelector,
	breakdownSelector,
	breakdownValueSelector
) => {
	if (options[checkOption]) {
		const {formatMessage} = intl;
		if (options[checkOption].periodTotals) {
			const periodColumn = {
				id: id + '.per',
				title: `${title} (${formatMessage({id: 'common.period'})})`,
				toolTip,
				subColumns: [],
				highlighted: true,
			};
			addSubColumn(
				periodColumn,
				id + '.per',
				type,
				`(${formatMessage({id: 'common.hours.short'})})`,
				options[checkOption],
				false,
				totals,
				totalSelector('per'),
				pageStructure.projectLines,
				projectSelector('per'),
				breakdownSelector,
				breakdownValueSelector('per')
			);
			pageStructure.columns.push(periodColumn);
		}
		if (options[checkOption].projectTotals) {
			const totalColumn = {
				id: id + '.tot',
				title: `${title} (${formatMessage({id: 'common.total'})})`,
				toolTip,
				subColumns: [],
			};
			addSubColumn(
				totalColumn,
				id + '.tot',
				type,
				`(${formatMessage({id: 'common.hours.short'})})`,
				options[checkOption],
				false,
				totals,
				totalSelector('tot'),
				pageStructure.projectLines,
				projectSelector('tot'),
				breakdownSelector,
				breakdownValueSelector('tot')
			);
			pageStructure.columns.push(totalColumn);
		}
	}
};

const addApprovedAllColumn = (
	intl,
	pageStructure,
	id,
	type,
	title,
	toolTip,
	options,
	checkOption,
	totals,
	totalSelector,
	projects,
	projectSelector,
	highlighted,
	breakdownSelector,
	breakdownValueSelector
) => {
	if (options[checkOption]) {
		const {formatMessage} = intl;
		const column = {
			id,
			title,
			toolTip,
			subColumns: [],
			highlighted,
		};
		addSubColumn(
			column,
			id + '.approved',
			type,
			formatMessage({id: 'common.approved'}).toUpperCase(),
			options[checkOption].nestedOptions,
			'approved',
			totals,
			totalSelector('app'),
			projects,
			projectSelector('app'),
			breakdownSelector,
			breakdownValueSelector('app')
		);
		addSubColumn(
			column,
			id + '.total',
			type,
			formatMessage({id: 'common.total'}).toUpperCase(),
			options[checkOption].nestedOptions,
			'total',
			totals,
			totalSelector('all'),
			projects,
			projectSelector('all'),
			breakdownSelector,
			breakdownValueSelector('all')
		);
		pageStructure.columns.push(column);
	}
};

const addApprovedAllPeriodTotalColumn = (
	intl,
	pageStructure,
	id,
	type,
	title,
	toolTip,
	options,
	checkOption,
	totals,
	totalSelector,
	projectSelector,
	breakdownSelector,
	breakdownValueSelector
) => {
	if (options[checkOption]) {
		const {formatMessage} = intl;
		addApprovedAllColumn(
			intl,
			pageStructure,
			id + '.per',
			type,
			`${title} (${formatMessage({id: 'common.period'})})`,
			toolTip,
			options[checkOption],
			'periodTotals',
			totals,
			totalSelector('per'),
			pageStructure.projectLines,
			projectSelector('per'),
			true,
			breakdownSelector,
			breakdownValueSelector('per')
		);
		addApprovedAllColumn(
			intl,
			pageStructure,
			id + '.tot',
			type,
			`${title} (${formatMessage({id: 'common.total'})})`,
			toolTip,
			options[checkOption],
			'projectTotals',
			totals,
			totalSelector('tot'),
			pageStructure.projectLines,
			projectSelector('tot'),
			false,
			breakdownSelector,
			breakdownValueSelector('tot')
		);
	}
};

const addInvoiceColumn = (
	intl,
	pageStructure,
	id,
	type,
	title,
	toolTip,
	options,
	checkOption,
	totals,
	totalSelector,
	projects,
	projectSelector,
	highlighted
) => {
	if (options[checkOption]) {
		const {formatMessage} = intl;
		const column = {
			id,
			title,
			toolTip,
			subColumns: [],
			highlighted,
		};
		addSubColumn(
			column,
			id + '.invoicedPrice',
			type,
			formatMessage({id: 'common.invoiced'}).toUpperCase(),
			options[checkOption].nestedOptions,
			'invoicedMoney',
			totals,
			totalSelector('invoicedPrice'),
			projects,
			projectSelector('invoicedPrice')
		);
		addSubColumn(
			column,
			id + '.invoicedOnFixedPrice',
			'percentage',
			formatMessage({id: 'project_budget.fixed_price_invoiced_percentage'}).toUpperCase(),
			options[checkOption].nestedOptions,
			'invoicedOnFixedPrice',
			totals,
			totalSelector('invoicedOnFixedPrice'),
			projects,
			projectSelector('invoicedOnFixedPrice')
		);
		addSubColumn(
			column,
			id + '.invoicedPaid',
			type,
			formatMessage({id: 'project_budget.invoice_paid'}).toUpperCase(),
			options[checkOption].nestedOptions,
			'invoicePaid',
			totals,
			totalSelector('invoicedPaid'),
			projects,
			projectSelector('invoicedPaid')
		);
		pageStructure.columns.push(column);
	}
};

const addInvoicePeriodTotalColumn = (
	intl,
	pageStructure,
	id,
	type,
	title,
	toolTip,
	options,
	checkOption,
	totals,
	totalSelector,
	projectSelector
) => {
	if (options[checkOption]) {
		const {formatMessage} = intl;
		addInvoiceColumn(
			intl,
			pageStructure,
			id + '.per',
			type,
			`${title} (${formatMessage({id: 'common.period'})})`,
			toolTip,
			options[checkOption],
			'periodTotals',
			totals,
			totalSelector('per'),
			pageStructure.projectLines,
			projectSelector('per'),
			true
		);
		addInvoiceColumn(
			intl,
			pageStructure,
			id + '.tot',
			type,
			`${title} (${formatMessage({id: 'common.total'})})`,
			toolTip,
			options[checkOption],
			'projectTotals',
			totals,
			totalSelector('tot'),
			pageStructure.projectLines,
			projectSelector('tot'),
			false
		);
	}
};

const addProjectOverview = (intl, pageStructure, options, availableFeatureFlags, hasBaseline) => {
	const {formatMessage} = intl;
	const projectOverviewColumn = {
		id: 'projectOverview',
		title: '',
		subColumns: [],
	};

	// Expand Collapse
	const canExpandCollapse = pageStructure.projectLines.find(projectLine => projectLine.canExpand);
	if (canExpandCollapse) {
		addSubColumn(
			projectOverviewColumn,
			'expandCollapse',
			'expandCollapse',
			null,
			options,
			false,
			null,
			null,
			pageStructure.projectLines,
			selectRootField('expanded')
		);
	}
	// Project / Project Group ID
	addSubColumn(
		projectOverviewColumn,
		'projectId',
		'projectId',
		'P-ID'.toUpperCase(),
		options,
		'projectId',
		null,
		null,
		pageStructure.projectLines,
		selectDetails()
	);
	// Name
	addSubColumn(
		projectOverviewColumn,
		'name',
		'name',
		formatMessage({id: 'common.name'}).toUpperCase(),
		options,
		null,
		null,
		null,
		pageStructure.projectLines,
		selectDetails('name')
	);
	// Dates
	addSubColumn(
		projectOverviewColumn,
		'dates',
		'dates',
		formatMessage({id: 'project_overview.project_dates'}).toUpperCase(),
		options,
		'dates',
		null,
		null,
		pageStructure.projectLines,
		selectDetails(),
		project => {
			if (project.phaseMap) {
				return Object.keys(project.phaseMap).reduce((breakdown, phaseId) => {
					breakdown[phaseId] = {
						tot: project.phaseMap[phaseId],
						brk: {},
					};
					return breakdown;
				}, {});
			}
			return null;
		},
		value => {
			return value;
		}
	);
	// Client calculatedFields
	addSubColumn(
		projectOverviewColumn,
		'client',
		'client',
		formatMessage({id: 'common.client'}).toUpperCase(),
		options,
		'client',
		null,
		null,
		pageStructure.projectLines,
		selectDetails('client')
	);

	// Progress
	addSubColumn(
		projectOverviewColumn,
		'progress',
		'percentage',
		formatMessage({id: 'common.progress'}).toUpperCase(),
		options,
		'progress',
		null,
		null,
		pageStructure.projectLines,
		selectRootField('progress')('tot'),
		breakdownSelector('progress'),
		breakdownPerTotSelector('tot')
	);

	addSubColumn(
		projectOverviewColumn,
		'progress_EOM',
		'percentage',
		formatMessage({id: 'insights.component.list.column.progressEndOfMonth'}).toUpperCase(),
		options,
		'progress_EOM', //check option
		null, // totals
		null, // total selector
		pageStructure.projectLines, // projects
		selectRootField('calculatedFields')('progressEndOfTheMonth'), // projectFieldSelector
		null,
		null
	);
	// Budget Type
	addSubColumn(
		projectOverviewColumn,
		'budgetType',
		'budgetType',
		formatMessage({id: 'new_project_modal.budget_type'}).toUpperCase(),
		options,
		'BudgetType',
		null,
		null,
		pageStructure.projectLines,
		selectDetails()
	);

	if (hasBaseline) {
		//addWinChance(intl, pageStructure, transposedEyeOptions, 'win_chance', false, viewerProjects, hasBaseline);
		addSubColumn(
			projectOverviewColumn,
			'winChance',
			'percentage',
			formatMessage({id: 'project.win_chance'}).toUpperCase(),
			options,
			'win_chance',
			null,
			null,
			pageStructure.projectLines,
			selectDetails('baselineWinChance')
		);
	}
	pageStructure.columns.push(projectOverviewColumn);
};

const specialPhaseIds = ['no-phase', 'project-time-regs', 'offset'];
const sortPhases = projectLookupMap => {
	projectLookupMap.sortedPhaseIds.sort((phaseIdA, phaseIdB) => {
		let compare = 0;
		if (phaseIdA === phaseIdB) {
			compare = 0;
		} else if (specialPhaseIds.indexOf(phaseIdA) > -1 && specialPhaseIds.indexOf(phaseIdB) === -1) {
			compare = 1;
		} else if (specialPhaseIds.indexOf(phaseIdA) === -1 && specialPhaseIds.indexOf(phaseIdB) > -1) {
			compare = -1;
		} else if (specialPhaseIds.indexOf(phaseIdA) > -1 && specialPhaseIds.indexOf(phaseIdB) > -1) {
			compare = specialPhaseIds.indexOf(phaseIdA) - specialPhaseIds.indexOf(phaseIdB);
		} else {
			const phaseStartDateA = projectLookupMap.phases[phaseIdA] ? projectLookupMap.phases[phaseIdA].startDate : null;
			const phaseStartDateB = projectLookupMap.phases[phaseIdB] ? projectLookupMap.phases[phaseIdB].startDate : null;

			if (!phaseStartDateA) compare = 1;
			else if (!phaseStartDateB) compare = -1;
			else if (phaseStartDateA < phaseStartDateB) compare = 1;
			else if (phaseStartDateA > phaseStartDateB) compare = -1;
		}
		return compare;
	});
};

const sortRoles = projectLookupMap => {
	Object.keys(projectLookupMap.sortedRoleIds).forEach(phaseId => {
		const sortedRoleIds = projectLookupMap.sortedRoleIds[phaseId];
		sortedRoleIds.sort((roleIdA, roleIdB) => {
			let compare = 0;
			if (roleIdA === roleIdB) {
				compare = 0;
			} else if (roleIdA === 'no-role' && roleIdB !== 'no-role') {
				compare = 1;
			} else if (roleIdA !== 'no-role' && roleIdB === 'no-role') {
				compare = -1;
			} else {
				const roleNameA = projectLookupMap.roles[roleIdA];
				const roleNameB = projectLookupMap.roles[roleIdB];

				if (roleNameA < roleNameB) compare = -1;
				if (roleNameA > roleNameB) compare = 1;
			}
			return compare;
		});
	});
};

const addProjectBreakdowns = (pageStructure, projects, options, intl, hasFinance) => {
	pageStructure.projectBreakdowns = projects.reduce((lookupMap, project) => {
		const projectLookupMap = {
			sortedPhaseIds: [],
			phases: {},
			showPhases: false,
			sortedRoleIds: [],
			roles: {},
			showRoles: false,
			expenses: {},
			showExpenses: false,
			hasContent: false,
		};
		lookupMap[project.id] = projectLookupMap;

		Object.keys(project).forEach(field => {
			if (project[field] && project[field].breakdown) {
				Object.keys(project[field].breakdown).forEach(phaseId => {
					if (phaseId === 'expenses') {
						projectLookupMap.expenses[phaseId] = 'Expenses';
					} else {
						if (phaseId === 'no-phase') {
							projectLookupMap.phases[phaseId] = {
								name: intl.formatMessage({id: 'project_scopes.no-scope'}),
							};
						} else if (phaseId === 'project-time-regs') {
							projectLookupMap.phases[phaseId] = {
								name: intl.formatMessage({id: 'project_budget.project_time_entries_with_no_tasks'}),
							};
						} else if (phaseId === 'offset') {
							projectLookupMap.phases[phaseId] = {
								name: intl.formatMessage({id: 'common.offset'}),
							};
						} else {
							projectLookupMap.phases[phaseId] = project.phaseMap[phaseId];
						}

						Object.keys(project[field].breakdown[phaseId].brk).forEach(roleId => {
							let roleLookupMap = projectLookupMap.roles[phaseId];
							if (!roleLookupMap) {
								roleLookupMap = {};
								projectLookupMap.roles[phaseId] = roleLookupMap;
							}
							if (roleId === 'no-role') {
								roleLookupMap[roleId] = intl.formatMessage({id: 'team.no-role-group-title'});
							} else if (roleId === 'not-planned') {
								roleLookupMap[roleId] = intl.formatMessage({id: 'common.unplanned_capitalized'});
							} else if (roleId === 'expense') {
								if (hasFinance) {
									roleLookupMap[roleId] = intl.formatMessage({id: 'project_budget.expenses'});
								}
							} else {
								roleLookupMap[roleId] = project.roleMap[roleId];
							}
						});
					}
				});

				const nestedOptions =
					options.projectBreakdown && options.projectBreakdown.nestedOptions
						? options.projectBreakdown.nestedOptions
						: {};
				projectLookupMap.showPhases = nestedOptions.phases;
				projectLookupMap.showRoles = nestedOptions.roles;
				projectLookupMap.showExpenses = nestedOptions.expenses;
			}
		});

		projectLookupMap.sortedPhaseIds = Object.keys(projectLookupMap.phases);
		sortPhases(projectLookupMap);

		projectLookupMap.sortedRoleIds = Object.keys(projectLookupMap.roles).reduce((sortedRoleIds, phaseId) => {
			sortedRoleIds[phaseId] = Object.keys(projectLookupMap.roles[phaseId]);
			return sortedRoleIds;
		}, {});
		sortRoles(projectLookupMap);

		projectLookupMap.hasContent =
			projectLookupMap.sortedRoleIds.length > 0 ||
			projectLookupMap.sortedPhaseIds.length > 0 ||
			Object.keys(projectLookupMap.expenses).length > 0;

		return lookupMap;
	}, {});
};

const sortProjects = projects => {
	projects.sort((projectA, projectB) => {
		const dateA = projectA.details.startDate;
		const dateB = projectB.details.startDate;

		if (dateA < dateB) return 1;
		if (dateA === dateB) return 0;
		if (dateA > dateB) return -1;
		return 0;
	});
};

const addProjectLines = (pageStructure, projectGroups, projects, options) => {
	let projectLines = [];
	const projectMap = projects.reduce((lookupMap, project) => {
		lookupMap[project.id] = project;
		return lookupMap;
	}, {});
	const insertedIds = [];

	if (options.connectedProjects) {
		projectGroups.edges
			.map(projectGroup => projectGroup.node)
			.forEach(projectGroup => {
				const projectGroupProjectsIds = projectGroup.projects.edges
					.filter(project => projectMap[project.node.id])
					.map(project => project.node.id);
				// Only add the connected project if it has project that are not filtered away
				if (projectGroupProjectsIds.length > 0) {
					insertedIds.push(projectGroup.id);
					insertedIds.push(...projectGroupProjectsIds);

					const sortedProjects = projectGroup.projects.edges
						.filter(project => projectMap[project.node.id])
						.map(project => {
							const fullProject = projectMap[project.node.id];
							fullProject.details.isInProjectGroup = fullProject.details.origIsInProjectGroup;
							fullProject.canExpand =
								!!options.projectBreakdown && pageStructure.projectBreakdowns[fullProject.projectId].hasContent;
							return fullProject;
						});
					sortProjects(sortedProjects);
					projectGroup.subProjects = sortedProjects;
					projectGroup.canExpand = true;

					projectLines.push(projectGroup);
				}
			});
	}
	projects
		.filter(project => insertedIds.indexOf(project.id) === -1)
		.forEach(project => {
			if (!options.connectedProjects) {
				project.details.isInProjectGroup = false;
			}

			project.canExpand = !!options.projectBreakdown && pageStructure.projectBreakdowns[project.projectId].hasContent;

			projectLines.push(project);
		});

	// Sort 1. level project lines (project groups and projects not in project group)
	sortProjects(projectLines);

	pageStructure.projectLines = projectLines;
};

/**
 * Converts the eye options and the report data into a page structure ready to be rendered.
 *
 * This method should be unit tested as it determines that the correct columns and data is shown.
 */
export const prepareStructure = (
	reportData,
	eyeOptions,
	intl,
	availableFeatureFlags,
	hasFinance,
	viewerProjects,
	hasBaseline,
	hasTimeApproval
) => {
	const pageStructure = {
		columns: [],
		projectBreakdowns: {},
		projectLines: [],
	};

	if (!reportData.loading) {
		const totals = reportData.totals;
		const projects = reportData.projects;
		const projectGroups = reportData.projectGroups;
		const {formatMessage} = intl;

		const transposedEyeOptions = transposeEyeOptions(eyeOptions);

		/*
		 * PROJECT BREAKDOWNS
		 */
		addProjectBreakdowns(pageStructure, projects, transposedEyeOptions, intl, hasFinance);

		/*
		 * PROJECT LINES
		 *
		 * A project lines is a row in the table and either a connected project or real project
		 */
		addProjectLines(pageStructure, projectGroups, projects, transposedEyeOptions);

		/*
		 * COLUMNS
		 */
		addProjectOverview(intl, pageStructure, transposedEyeOptions, availableFeatureFlags, hasBaseline);

		addRevenueCostProfitMarginColumn(
			intl,
			pageStructure,
			'fixedPrice',
			'price',
			formatMessage({id: 'common.fixed_price'}),
			fixedPriceTooltip(intl),
			transposedEyeOptions,
			'fixedPrice',
			totals,
			selectTotals('fixedPrice'),
			selectRootField('fixedPrice'),
			false,
			null,
			breakdownEmptyValueSelector
		);
		addRevenueCostProfitMarginPeriodTotalColumn(
			intl,
			pageStructure,
			'baseline',
			'price',
			formatMessage({id: 'baseline.baseline'}),
			baselineTooltip(intl),
			transposedEyeOptions,
			'baseline',
			totals,
			selectPeriodProjectTotals('baseline'),
			selectRootPeriodTotalField('baseline'),
			breakdownSelector('baseline'),
			breakdownRevCosProMarPerTotSelector
		);
		addRevenueCostProfitMarginPeriodTotalColumn(
			intl,
			pageStructure,
			'actualPrice',
			'price',
			formatMessage({id: 'project_budget.actual'}),
			actualPriceTooltip(intl),
			transposedEyeOptions,
			'actuals',
			totals,
			selectPeriodProjectTotals('actualPrice'),
			selectRootPeriodTotalField('actualPrice'),
			breakdownSelector('actualPrice'),
			breakdownRevCosProMarPerTotSelector
		);
		addRevenueCostProfitMarginPeriodTotalColumn(
			intl,
			pageStructure,
			'planPrice',
			'price',
			formatMessage({id: 'common.planned'}),
			planPriceTooltip(intl),
			transposedEyeOptions,
			'plan',
			totals,
			selectPeriodProjectTotals('planPrice'),
			selectRootPeriodTotalField('planPrice'),
			breakdownSelector('planPrice'),
			breakdownRevCosProMarPerTotSelector
		);
		addRevenueCostProfitMarginPeriodTotalColumn(
			intl,
			pageStructure,
			'remainingPrice',
			'price',
			formatMessage({id: 'common.remaining'}),
			remainingPriceTooltip(intl),
			transposedEyeOptions,
			'remainingMoney',
			totals,
			selectPeriodProjectTotals('remainingPrice'),
			selectRootPeriodTotalField('remainingPrice'),
			breakdownSelector('remainingPrice'),
			breakdownRevCosProMarPerTotSelector
		);
		addRevenueCostProfitMarginPeriodTotalColumn(
			intl,
			pageStructure,
			'forecastPrice',
			'price',
			formatMessage({id: 'common.forecast'}),
			forecastPriceTooltip(intl),
			transposedEyeOptions,
			'forecastMoney',
			totals,
			selectPeriodProjectTotals('forecastPrice'),
			selectRootPeriodTotalField('forecastPrice'),
			breakdownSelector('forecastPrice'),
			breakdownRevCosProMarPerTotSelector
		);
		addRevenueCostProfitMarginPeriodTotalColumn(
			intl,
			pageStructure,
			'actualVarianceToPlanPrice',
			'price',
			formatMessage({id: 'project_budget.actual_vs_plan'}),
			actualVarianceToPlanPriceTooltip(intl),
			transposedEyeOptions,
			'actualsVPlan',
			totals,
			selectPeriodProjectTotals('actualVarianceToPlanPrice'),
			selectRootPeriodTotalField('actualVarianceToPlanPrice'),
			breakdownSelector('actualVarianceToPlanPrice'),
			breakdownRevCosProMarPerTotSelector
		);
		addRevenueCostProfitMarginPeriodTotalColumn(
			intl,
			pageStructure,
			'forecastVarianceToPlanPrice',
			'price',
			formatMessage({id: 'project_budget.forecast_vs_plan'}),
			forecastVarianceToPlanPriceTooltip(intl),
			transposedEyeOptions,
			'forecastVPlan',
			totals,
			selectPeriodProjectTotals('forecastVarianceToPlanPrice'),
			selectRootPeriodTotalField('forecastVarianceToPlanPrice'),
			breakdownSelector('forecastVarianceToPlanPrice'),
			breakdownRevCosProMarPerTotSelector
		);
		addRevenueCostProfitMarginColumn(
			intl,
			pageStructure,
			'companyFinancials',
			'price',
			`${formatMessage({id: 'project_budget.projected_company_financials'})} (${formatMessage({id: 'common.total'})})`,
			companyFinancialsTooltip(intl),
			transposedEyeOptions,
			'companyFinancials',
			totals,
			selectTotals('companyFinancials'),
			selectRootField('companyFinancials'),
			false,
			null,
			breakdownEmptyValueSelector
		);
		addPeriodTotalColumn(
			intl,
			pageStructure,
			'registeredHours',
			'time',
			formatMessage({id: 'common.registered'}),
			registeredHoursTooltip(intl),
			transposedEyeOptions,
			'registeredTime',
			totals,
			selectTotals('registeredHours'),
			selectRootField('registeredHours'),
			breakdownSelector('registeredHours'),
			breakdownPerTotSelector
		);
		addPeriodTotalColumn(
			intl,
			pageStructure,
			'remainingHours',
			'time',
			formatMessage({id: 'common.remaining'}),
			remainingHoursTooltip(intl),
			transposedEyeOptions,
			'remainingTime',
			totals,
			selectTotals('remainingHours'),
			selectRootField('remainingHours'),
			breakdownSelector('remainingHours'),
			breakdownPerTotSelector
		);
		addPeriodTotalColumn(
			intl,
			pageStructure,
			'forecastHours',
			'time',
			formatMessage({id: 'common.forecast'}),
			forecastHoursTooltip(intl),
			transposedEyeOptions,
			'forecastTime',
			totals,
			selectTotals('forecastHours'),
			selectRootField('forecastHours'),
			breakdownSelector('forecastHours'),
			breakdownPerTotSelector
		);
		addApprovedAllPeriodTotalColumn(
			intl,
			pageStructure,
			'scope',
			'time',
			formatMessage({id: 'common.scope'}),
			scopeTooltip(intl),
			transposedEyeOptions,
			'scopeTime',
			totals,
			selectPeriodProjectTotals('estimatedHours'),
			selectRootPeriodTotalField('estimatedHours'),
			breakdownSelector('estimatedHours'),
			breakdownAppAllPerTotSelector
		);
		addApprovedAllPeriodTotalColumn(
			intl,
			pageStructure,
			'low',
			'time',
			formatMessage({id: 'common.low'}),
			lowTooltip(intl),
			transposedEyeOptions,
			'lowTime',
			totals,
			selectPeriodProjectTotals('lowHours'),
			selectRootPeriodTotalField('lowHours'),
			breakdownSelector('lowHours'),
			breakdownAppAllPerTotSelector
		);
		addApprovedAllPeriodTotalColumn(
			intl,
			pageStructure,
			'high',
			'time',
			formatMessage({id: 'common.high'}),
			highTooltip(intl),
			transposedEyeOptions,
			'highTime',
			totals,
			selectPeriodProjectTotals('highHours'),
			selectRootPeriodTotalField('highHours'),
			breakdownSelector('highHours'),
			breakdownAppAllPerTotSelector
		);
		// Next 12 months progress
		addFutureProgressTotalColumn(
			intl,
			pageStructure,
			'future_progress',
			'future_progress',
			formatMessage({id: 'insights.component.list.column.progressFutureArray'}),
			'',
			transposedEyeOptions
		);

		addPeriodTotalColumn(
			intl,
			pageStructure,
			'allocation',
			'time',
			formatMessage({id: 'scheduling.project_allocation'}),
			allocationTooltip(intl),
			transposedEyeOptions,
			'projectAllocation',
			totals,
			selectTotals('allocation'),
			selectRootField('allocation'),
			null,
			breakdownEmptyValueSelector
		);
		addInvoicePeriodTotalColumn(
			intl,
			pageStructure,
			'invoice',
			'price',
			formatMessage({id: 'project_section.invoice'}),
			invoiceTooltip(intl),
			transposedEyeOptions,
			'invoice',
			totals,
			selectPeriodProjectTotals('invoice'),
			selectRootPeriodTotalField('invoice')
		);
		addSimpleColumn(
			pageStructure,
			'status',
			'status',
			'Status'.toUpperCase(),
			transposedEyeOptions,
			'status',
			selectDetails('currentProjectStatus')
		);
		addSimpleColumn(
			pageStructure,
			'projectContact',
			'projectContact',
			hasTimeApproval ? 'Project Owner'.toUpperCase() : 'Project Contact'.toUpperCase(),
			transposedEyeOptions,
			'projectContact',
			selectDetails('projectPersons')
		);
		addSimpleColumn(
			pageStructure,
			'stage',
			'stage',
			'Stage'.toUpperCase(),
			transposedEyeOptions,
			'stage',
			selectDetails('status')
		);
		addSimpleColumn(
			pageStructure,
			'labels',
			'labels',
			'Labels'.toUpperCase(),
			transposedEyeOptions,
			'Labels',
			selectDetails('projectLabels')
		);
		addSimpleColumn(
			pageStructure,
			'rateCard',
			'rateCard',
			'Rate Card'.toUpperCase(),
			transposedEyeOptions,
			'rateCards',
			selectDetails('rateCard')
		);
	}

	return pageStructure;
};
