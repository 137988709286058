import {HexagonText, Table} from 'web-components';
import {WarningIcon} from 'web-components/icons';
import {
	totals,
	totalsBillableReported,
	totalsDifference,
	totalsEstimate,
	totalsNonBillableReported,
	totalsProjected,
	totalsRemaining,
	totalsReported,
} from '../renders/TaskTotalsHeaders';
import React from 'react';
import {ESTIMATION_UNIT} from '../../../../constants';
import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import Util from '../../../../forecast-app/shared/util/util';
import {profilePicSrc} from '../../../../directApi';

const GroupColumns = (row, intl, currency, revenueRecognitionNotEqualToBudget, numberOfCustomFields = 0) => {
	if (!Util.hasCustomFields()) {
		numberOfCustomFields = 0;
	}
	return [
		<Table.Column>
			{row && row.isPerson ? (
				<HexagonText intl={intl} active={row.active} text={row.fullName} imgUrl={profilePicSrc(row.profilePictureId)} />
			) : (
				<span>{row && (row.generatedName || row.name)}</span>
			)}
		</Table.Column>,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column />,
		<Table.Column>{row.taskTotals && totalsEstimate(row.taskTotals, intl, ESTIMATION_UNIT.HOURS)}</Table.Column>,
		<Table.Column>{row.taskTotals && totalsEstimate(row.taskTotals, intl, ESTIMATION_UNIT.POINTS)}</Table.Column>,
		<Table.Column>{row.taskTotals && totalsProjected(row.taskTotals, intl)}</Table.Column>,
		<Table.Column>{row.taskTotals && totalsReported(row.taskTotals, intl)}</Table.Column>,
		<Table.Column>{row.taskTotals && totalsBillableReported(row.taskTotals, intl)}</Table.Column>,
		<Table.Column>{row.taskTotals && totalsNonBillableReported(row.taskTotals, intl)}</Table.Column>,
		<Table.Column>{row.taskTotals && totalsRemaining(row.taskTotals, intl, ESTIMATION_UNIT.HOURS)}</Table.Column>,
		<Table.Column>{row.taskTotals && totalsRemaining(row.taskTotals, intl, ESTIMATION_UNIT.POINTS)}</Table.Column>,
		<Table.Column>{row.taskTotals && totalsDifference(row.taskTotals, intl, ESTIMATION_UNIT.HOURS)}</Table.Column>,
		<Table.Column>{row.taskTotals && totalsDifference(row.taskTotals, intl, ESTIMATION_UNIT.POINTS)}</Table.Column>,
		<Table.Column negative={row.taskTotals?.plannedRevenue && row.taskTotals.plannedRevenue < 0}>
			{row.taskTotals && totals(row.taskTotals.plannedRevenue, currency, intl)}
		</Table.Column>,
		<Table.Column negative={row.taskTotals?.actualRevenue && row.taskTotals.actualRevenue < 0}>
			{row.taskTotals && totals(row.taskTotals.actualRevenue, currency, intl)}
		</Table.Column>,
		<Table.Column negative={row.taskTotals?.remainingRevenue && row.taskTotals.remainingRevenue < 0}>
			{row.taskTotals && totals(row.taskTotals.remainingRevenue, currency, intl)}
		</Table.Column>,
		<Table.Column negative={row.taskTotals?.forecastRevenue && row.taskTotals.forecastRevenue < 0}>
			{row.taskTotals && totals(row.taskTotals.forecastRevenue, currency, intl)}
		</Table.Column>,
		<Table.Column negative={row.taskTotals?.recognitionLockedRevenue && row.taskTotals.recognitionLockedRevenue < 0}>
			{row.taskTotals && totals(row.taskTotals.recognitionLockedRevenue, currency, intl)}
		</Table.Column>,
		<Table.Column
			negative={row.taskTotals?.totalActualRevenueRecognition && row.taskTotals.totalActualRevenueRecognition < 0}
		>
			{row.taskTotals && totals(row.taskTotals.totalActualRevenueRecognition, currency, intl)}
		</Table.Column>,
		<Table.Column negative={row.taskTotals?.recognitionOpenRevenue && row.taskTotals.recognitionOpenRevenue < 0}>
			{row.taskTotals && totals(row.taskTotals.recognitionOpenRevenue, currency, intl)}
		</Table.Column>,
		<Table.Column
			negative={row.taskTotals?.totalForecastRevenueToComplete && row.taskTotals.totalForecastRevenueToComplete < 0}
		>
			{row.taskTotals && totals(row.taskTotals.totalForecastRevenueToComplete, currency, intl)}
		</Table.Column>,
		<Table.Column negative={row.taskTotals?.recognitionForecastRevenue && row.taskTotals.recognitionForecastRevenue < 0}>
			{revenueRecognitionNotEqualToBudget && hasFeatureFlag('revenue_recognition_with_all_budget_types') && (
				<TooltipContainer infoText={intl.formatMessage({id: 'warning.project_time_registrations_affecting_total'})}>
					<WarningIcon variant={WarningIcon.VARIANT.WARNING} />
				</TooltipContainer>
			)}
			{row.taskTotals && totals(row.taskTotals.recognitionForecastRevenue, currency, intl)}
		</Table.Column>,
		<Table.Column negative={row.taskTotals?.surplus && row.taskTotals.surplus < 0}>
			{row.taskTotals && totals(row.taskTotals.surplus, currency, intl)}
		</Table.Column>,
		<Table.Column />,
		<Table.Column />,
		...new Array(numberOfCustomFields).fill(<Table.Column />),
	];
};

export default GroupColumns;
