import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AutosizeInput from 'react-input-autosize';
import NumberFormat from 'react-number-format';
import {FormattedMessage} from 'react-intl';
import util from '../../forecast-app/shared/util/util';

class InputField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFocused: false,
			focusAfterRender: false,
		};
	}

	componentDidMount() {
		if (this.props.shouldFocus) {
			this._input_field_.select();
			this.setState({focusAfterRender: false});
		}
		if (this.props.handleRef) {
			this.props.handleRef(this._input_field_);
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.type === 'formatted') {
			return (
				this.props.value !== nextProps.value ||
				this.state.isFocused !== nextState.isFocused ||
				this.props.locked !== nextProps.locked
			);
		}
		return true;
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.focusAfterRender || (prevProps.shouldFocus !== this.props.shouldFocus && this.props.shouldFocus)) {
			this._input_field_.select();
			this.setState({focusAfterRender: false});
		}
	}

	handleFocus(e) {
		if (this.props.readOnly) {
			return;
		}
		this.setState({isFocused: true});
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	}

	focus() {
		this._input_field_.select();
	}

	handleBlur() {
		this.setState({isFocused: false});
		if (this.props.type === 'email' && this.props.checkEmailValidity) {
			const valid = this._input_field_.checkValidity();
			this.props.checkEmailValidity(valid);
			if (this.props.onBlur) {
				this.props.onBlur(valid);
			}
		} else if (this.props.onBlur) {
			this.props.onBlur();
		}
	}

	handleChange(e) {
		if (this.props.type === 'number') {
			const value = this.props.validateHours
				? util.getMinuteEstimate(this._input_field_.value)
				: this._input_field_.value;
			if (value > 2147483646 || value < -2147483646) {
				return;
			}
		}
		if (this.props.onChange) {
			this.props.onChange(e.target.value);
		}
	}

	handleChangeFormatted(values, e) {
		this.props.onChange(values.floatValue || null);
	}

	handleOnClick(e) {
		if (this.props.onClick) {
			this.props.onClick(e);
		}
	}

	handleKeyPress(e, target) {
		if (this.props.onKeyPress) {
			this.props.onKeyPress(target);
		}
		if (this.props.onEnter && e.key === 'Enter') {
			this.props.onEnter();
		}
		if (this.props.blurOnEnter && e.key === 'Enter') {
			this._input_field_.blur();
		}
	}

	setFocusAfterRender(value) {
		this.setState({focusAfterRender: value});
	}

	render() {
		const validInput = this.props.inputValid !== undefined && this.props.inputValid !== null ? this.props.inputValid : true;
		let extension = this.props.required ? ' *' : this.props.optional ? ' (optional)' : '';

		return (
			<div className={this.props.locked ? 'wrapper-locked' : validInput ? 'wrapper' : 'wrapper-error'}>
				{!this.props.hideLabel ? (
					<label
						className={
							this.props.alignRight
								? this.state.isFocused
									? 'label-right-focused'
									: 'label-right'
								: this.state.isFocused
								? 'label-focused'
								: 'label'
						}
					>
						{this.props.label} {extension}
					</label>
				) : null}
				<div className={this.state.isFocused ? 'input-wrapper-focused' : 'input-wrapper'}>
					{this.props.prefix ? <span className="suffix">{this.props.prefix}</span> : null}
					{this.props.type === 'formatted' ? (
						<NumberFormat
							data-cy={this.props.cy}
							id={this.props.id ? this.props.id : null}
							decimalScale={2}
							disabled={this.props.locked ? this.props.locked : false}
							value={this.props.value}
							thousandSeparator={this.props.language === 'ENGLISH_EU' ? '.' : ','}
							decimalSeparator={this.props.language === 'ENGLISH_EU' ? ',' : '.'}
							onFocus={this.handleFocus.bind(this)}
							onValueChange={this.handleChangeFormatted.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							placeholder={this.props.placeholder}
							className={
								this.props.alignRight
									? this.state.isFocused
										? 'input-right-focused'
										: 'input-right'
									: this.state.isFocused
									? 'input-focused'
									: 'input'
							}
						/>
					) : this.props.type === 'number' ? (
						<input
							data-cy={this.props.cy}
							id={this.props.id ? this.props.id : null}
							ref={input => (this._input_field_ = input)}
							type={this.props.type}
							className={
								this.props.alignRight
									? this.state.isFocused
										? 'input-right-focused'
										: 'input-right'
									: this.state.isFocused
									? 'input-focused'
									: 'input'
							}
							onFocus={this.handleFocus.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							disabled={this.props.locked ? this.props.locked : false}
							placeholder={this.props.placeholder}
							onChange={this.handleChange.bind(this)}
							value={this.props.value}
							onClick={this.handleOnClick.bind(this)}
							autoFocus={this.props.autoFocus}
							onKeyPress={this.handleKeyPress.bind(this)}
							step={this.props.step ? this.props.step : 1}
							max={this.props.maxNumber !== undefined ? this.props.maxNumber : 2147483646}
							min={this.props.minNumber !== undefined ? this.props.minNumber : -2147483646}
							readOnly={this.props.readOnly}
						/>
					) : this.props.autosize ? (
						<AutosizeInput
							id={this.props.id ? this.props.id : null}
							data-cy={this.props.cy}
							ref={input => (this._input_field_ = input)}
							type={this.props.type}
							className={
								this.props.alignRight
									? this.state.isFocused
										? 'input-right-focused'
										: 'input-right'
									: this.state.isFocused
									? 'input-focused'
									: 'input'
							}
							onFocus={this.handleFocus.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							disabled={this.props.locked ? this.props.locked : false}
							placeholder={this.props.placeholder}
							placeholderIsMinWidth={this.props.placeholderIsMinWidth || false}
							onChange={this.handleChange.bind(this)}
							value={this.props.value}
							onClick={this.handleOnClick.bind(this)}
							autoFocus={this.props.autoFocus}
							onKeyPress={this.handleKeyPress.bind(this)}
							step={this.props.step ? this.props.step : 1}
							maxLength={this.props.maxLength ? this.props.maxLength : 191}
						/>
					) : (
						<input
							id={this.props.id ? this.props.id : null}
							data-cy={this.props.cy}
							ref={input => (this._input_field_ = input)}
							type={this.props.type}
							className={
								this.props.alignRight
									? this.state.isFocused
										? 'input-right-focused'
										: 'input-right'
									: this.state.isFocused
									? 'input-focused'
									: 'input'
							}
							onFocus={this.handleFocus.bind(this)}
							onBlur={this.handleBlur.bind(this)}
							disabled={this.props.locked ? this.props.locked : false}
							placeholder={this.props.placeholder}
							onChange={this.handleChange.bind(this)}
							value={this.props.value}
							onClick={this.handleOnClick.bind(this)}
							autoFocus={this.props.autoFocus}
							onKeyPress={this.handleKeyPress.bind(this)}
							step={this.props.step ? this.props.step : 1}
							maxLength={this.props.maxLength}
							readOnly={this.props.readOnly}
						/>
					)}
					{this.props.suffix ? <span className="suffix">{this.props.suffix}</span> : null}
				</div>
				<span className="bar" />
				{!validInput && this.props.errorMessage ? (
					<span className="error-message">
						<FormattedMessage id="common.error_colon" /> {this.props.errorMessage}
					</span>
				) : null}
			</div>
		);
	}
}

InputField.propTypes = {
	id: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	locked: PropTypes.bool,
	placeholder: PropTypes.string,
	placeholderIsMinWidth: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onClick: PropTypes.func,
	onFocus: PropTypes.func,
	alignRight: PropTypes.bool,
	errorMessage: PropTypes.string,
	inputValid: PropTypes.bool,
	focusOnRender: PropTypes.bool,
	autoFocus: PropTypes.bool,
	optional: PropTypes.bool,
	required: PropTypes.bool,
	onKeyPress: PropTypes.func,
	shouldFocus: PropTypes.bool,
	validateHours: PropTypes.bool,
	hideLabel: PropTypes.bool,
	autosize: PropTypes.bool,
	maxLength: PropTypes.number,
	readOnly: PropTypes.bool,
	minNumber: PropTypes.number,
	maxNumber: PropTypes.number,
	step: PropTypes.string,
	blurOnEnter: PropTypes.bool,
	handleRef: PropTypes.func,
	suffix: PropTypes.string,
	prefix: PropTypes.string,
};

export default InputField;
