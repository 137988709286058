/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RevenueRecognitionPage_company$ref: FragmentReference;
declare export opaque type RevenueRecognitionPage_company$fragmentType: RevenueRecognitionPage_company$ref;
export type RevenueRecognitionPage_company = {|
  +id: string,
  +currency: ?string,
  +$refType: RevenueRecognitionPage_company$ref,
|};
export type RevenueRecognitionPage_company$data = RevenueRecognitionPage_company;
export type RevenueRecognitionPage_company$key = {
  +$data?: RevenueRecognitionPage_company$data,
  +$fragmentRefs: RevenueRecognitionPage_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RevenueRecognitionPage_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    }
  ],
  "type": "Company"
};
// prettier-ignore
(node/*: any*/).hash = '0ac70c35a565cad5ff2ee074fc3dfe21';

module.exports = node;
