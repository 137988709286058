export const PERMISSION_TYPE = {
	IS_CLIENT_USER: 'IS_CLIENT_USER',
	CLIENT_HIDE_TIME_ENTRIES: 'CLIENT_HIDE_TIME_ENTRIES',
	CLIENT_HIDE_ESTIMATES: 'CLIENT_HIDE_ESTIMATES',
	CLIENT_SEE_ALL_TASKS: 'CLIENT_SEE_ALL_TASKS',
	IS_VIRTUAL_USER: 'IS_VIRTUAL_USER',
	MANAGE_ACCOUNT_SETTINGS: 'MANAGE_ACCOUNT_SETTINGS',
	ALLOCATION_CREATE: 'ALLOCATION_CREATE',
	ALLOCATION_DELETE: 'ALLOCATION_DELETE',
	ALLOCATION_UPDATE: 'ALLOCATION_UPDATE',
	ALLOCATION_APPROVE: 'ALLOCATION_APPROVE',
	BASELINE_READ: 'BASELINE_READ',
	BASELINE_CREATE: 'BASELINE_CREATE',
	BASELINE_UPDATE: 'BASELINE_UPDATE',
	BASELINE_DELETE: 'BASELINE_DELETE',
	CLIENT_CREATE: 'CLIENT_CREATE',
	CLIENT_UPDATE: 'CLIENT_UPDATE',
	COMMENT_UPDATE_ALL: 'COMMENT_UPDATE_ALL',
	COMMENT_DELETE_ALL: 'COMMENT_DELETE_ALL',
	INSIGHTS_READ: 'INSIGHTS_READ',
	INSIGHTS_CREATE: 'INSIGHTS_CREATE',
	INSIGHTS_UPDATE: 'INSIGHTS_UPDATE',
	INSIGHTS_DELETE: 'INSIGHTS_DELETE',
	INVOICE_READ: 'INVOICE_READ',
	INVOICE_CREATE: 'INVOICE_CREATE',
	INVOICE_UPDATE: 'INVOICE_UPDATE',
	INVOICE_DELETE: 'INVOICE_DELETE',
	SKILLS_CREATE_ALL: 'SKILLS_CREATE_ALL',
	SKILLS_UPDATE_ALL: 'SKILLS_UPDATE_ALL',
	SKILLS_DELETE_ALL: 'SKILLS_DELETE_ALL',
	LOCK_TIME_REGISTRATIONS: 'LOCK_TIME_REGISTRATIONS',
	PHASE_CREATE: 'PHASE_CREATE',
	PHASE_UPDATE: 'PHASE_UPDATE',
	PHASE_DELETE: 'PHASE_DELETE',
	PERSON_COST_PERIOD_READ: 'PERSON_COST_PERIOD_READ',
	PERSON_CREATE: 'PERSON_CREATE',
	PROGRAMS_CREATE: 'PROGRAMS_CREATE',
	PROGRAMS_UPDATE: 'PROGRAMS_UPDATE',
	PROGRAMS_DELETE: 'PROGRAMS_DELETE',
	PROGRAMS_MANAGE_ALL: 'PROGRAMS_MANAGE_ALL',
	PROJECTS_READ_ALL: 'PROJECTS_READ_ALL',
	PROJECTS_READ_ALL_VIEW_ONLY: 'PROJECTS_READ_ALL_VIEW_ONLY',
	PROJECTS_CREATE: 'PROJECTS_CREATE',
	PROJECTS_UPDATE: 'PROJECTS_UPDATE',
	PROJECTS_DELETE: 'PROJECTS_DELETE',
	PROJECT_PERSON_CREATE: 'PROJECT_PERSON_CREATE',
	PROJECT_PERSON_UPDATE: 'PROJECT_PERSON_UPDATE',
	PROJECT_PERSON_DELETE: 'PROJECT_PERSON_DELETE',
	PROJECT_SCHEDULING_SHARE_CREATE: 'PROJECT_SCHEDULING_SHARE_CREATE',
	RATE_CARDS_READ: 'RATE_CARDS_READ',
	RATE_CARDS_UPDATE: 'RATE_CARDS_UPDATE',
	RETAINER_PERIOD_READ: 'RETAINER_PERIOD_READ',
	RETAINER_PERIOD_CREATE: 'RETAINER_PERIOD_CREATE',
	RETAINER_PERIOD_UPDATE: 'RETAINER_PERIOD_UPDATE',
	RETAINER_PERIOD_DELETE: 'RETAINER_PERIOD_DELETE',
	SPRINT_CREATE: 'SPRINT_CREATE',
	SPRINT_UPDATE: 'SPRINT_UPDATE',
	SPRINT_DELETE: 'SPRINT_DELETE',
	STATUS_COLUMN_CREATE: 'STATUS_COLUMN_CREATE',
	STATUS_COLUMN_UPDATE: 'STATUS_COLUMN_UPDATE',
	STATUS_COLUMN_DELETE: 'STATUS_COLUMN_DELETE',
	TIME_REGISTRATION_CREATE_ALL: 'TIME_REGISTRATION_CREATE_ALL',
	TIME_REGISTRATION_UPDATE_ALL: 'TIME_REGISTRATION_UPDATE_ALL',
	TIME_REGISTRATION_DELETE_ALL: 'TIME_REGISTRATION_DELETE_ALL',
	VIEW_FINANCIAL_INFORMATION: 'VIEW_FINANCIAL_INFORMATION',
	VIEW_FINANCIAL_INFORMATION_REVENUE: 'VIEW_FINANCIAL_INFORMATION_REVENUE',
	MANUAL_REVENUE_RECOGNITION: 'MANUAL_REVENUE_RECOGNITION',
	SCHEDULING_ACCESS: 'SCHEDULING_ACCESS',
	SISENSE_READ: 'SISENSE_READ',
	SISENSE_CREATE: 'SISENSE_CREATE',
	MANAGE_TIME_OFF: 'MANAGE_TIME_OFF',
	ALL_TIMELINES_VIEW_ONLY: 'ALL_TIMELINES_VIEW_ONLY',
	PEOPLE_SCHEDULE_VIEW_ONLY: 'PEOPLE_SCHEDULE_VIEW_ONLY',
	DEMAND_VIEW_ONLY: 'DEMAND_VIEW_ONLY',
	CAPACITY_OVERVIEW_VIEW_ONLY: 'CAPACITY_OVERVIEW_VIEW_ONLY',
	CLIENT_RESTRICTED_TASK_ACTIONS: 'CLIENT_RESTRICTED_TASK_ACTIONS',
	CLIENT_RESTRICTED_TASK_VIEW: 'CLIENT_RESTRICTED_TASK_VIEW',
};
