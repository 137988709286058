export const toPlaceholderObject = (placeholder, placeholderSkills, roles, placeholderAllocations) => {
	const skills = placeholderSkills
		? placeholderSkills
				.filter(node => node.placeholderId === placeholder.id)
				.map(placeholderSkill => ({
					node: {id: placeholderSkill.skillId},
				}))
		: [];
	const role = roles.find(role => role.id === placeholder.roleId);
	const allocations = placeholderAllocations
		? placeholderAllocations
				.filter(allocation => allocation.placeholderId === placeholder.id)
				.map(placeholderAllocation => ({
					node: placeholderAllocation,
				}))
		: [];
	return {...placeholder, skills: {edges: skills}, role: role, placeholderAllocations: {edges: allocations}};
};
