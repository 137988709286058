import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import {getDefaultExtraSmallClientLogo} from '../components/default_avatars';
import {findDOMNode} from 'react-dom';
import {clientLogoSrc} from '../directApi';

class ClientSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isExpanded: false,
			inputValue: '',
			marked: null,
		};
	}

	handleOptionMouseEnter(index) {
		this.setState({marked: index});
	}

	handleOptionMouseLeave() {
		this.setState({marked: null});
	}

	handleArrowClick() {
		this.setState({isExpanded: !this.state.isExpanded});
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (newTarget && (newTarget.id === 'client-selector-input' || newTarget.id === 'client-selector-container')) return;
		this.setState({isExpanded: false});
	}

	handleSelectItem(itemId) {
		if (this.props.handleSelectItem) {
			this.props.handleSelectItem(itemId);
		}
		this.setState({isExpanded: false});
	}

	handleKeyPress(e) {
		if (this.state.isExpanded) {
			if (e.keyCode === 13) {
				if (this.state.marked !== null) {
					let options = this.props.clients;
					if (this.state.inputValue) {
						options = options.filter(client => {
							return client.node.name.toUpperCase().includes(this.state.inputValue.trim().toUpperCase());
						});
					}
					if (!options[this.state.marked]) return;
					this.handleSelectItem(options[this.state.marked].node.id);
				}
			} else if (e.keyCode === 40) {
				//down arrow
				if (this.state.marked === null) {
					this.setState({marked: 0});
				} else {
					let optionsLength = this.props.clients.length;
					if (this.state.inputValue) {
						optionsLength = this.props.clients.filter(client => {
							return client.node.name.toUpperCase().includes(this.state.inputValue.trim().toUpperCase());
						}).length;
					}
					let markIndex = this.state.marked + 1;
					if (markIndex > optionsLength - 1) return;
					this.setState({marked: markIndex});
					this.scrollElementIntoView();
				}
			} else if (e.keyCode === 38 && this.state.marked !== null) {
				//up arrow
				if (this.state.marked === 0) {
					this.setState({marked: null});
				} else {
					this.setState({marked: this.state.marked - 1});
					this.scrollElementIntoView();
				}
			}
		}
	}

	scrollElementIntoView() {
		const markedElements = findDOMNode(this.refs.dropdown).getElementsByClassName('marked');
		if (markedElements.length) {
			markedElements[0].scrollIntoView(false);
			if (this.refs.dropdown.scrollTop !== 0) {
				this.refs.dropdown.scrollTop += 53;
			}
		}
	}

	handleNewClick() {
		this.setState({inputValue: ''});
		this.props.showAddClientModal();
	}

	render() {
		const {formatMessage} = this.props.intl;

		let clients = this.props.clients.sort((a, b) => {
			if (!a.node.id) return -1;
			if (!b.node.id) return 1;
			if (a.node.name.toLowerCase() > b.node.name.toLowerCase()) return 1;
			if (a.node.name.toLowerCase() < b.node.name.toLowerCase()) return -1;
			return 0;
		});
		if (
			this.props.showNoClient &&
			clients.filter(client => {
				return client.node.id === null;
			}).length === 0
		) {
			clients.splice(0, 0, {node: {name: formatMessage({id: 'client_selector.no_client_text'}), id: null}});
		}
		if (this.state.inputValue) {
			clients = clients.filter(client => {
				return client.node.name.toUpperCase().includes(this.state.inputValue.trim().toUpperCase());
			});
		}
		const boxY = this.container ? this.container.getBoundingClientRect().bottom : 0;
		const height = window.innerHeight;
		let neededHeight = 332;
		const clientCount = clients.length;
		if (clientCount < 4) {
			neededHeight = 68 + 55 * clientCount;
		} else if (clientCount === 4) {
			neededHeight = 288;
		}
		const isSpace = height - boxY > neededHeight;

		const selector = 'client-selector-input';
		return (
			<div
				className="client-selector-container"
				tabIndex="0"
				onBlur={this.handleBlur.bind(this)}
				id="client-selector-container"
				ref={c => (this.container = c)}
			>
				<div className={'input-container ' + (this.state.isExpanded ? 'focused' : '')}>
					<input
						type="text"
						placeholder={this.state.isExpanded ? '' : formatMessage({id: 'client_selector.placeholder'})}
						ref={input => (this.input = input)}
						onClick={() => this.setState({isExpanded: true})}
						id={selector}
						data-cy={selector}
						value={this.state.inputValue}
						onChange={e => this.setState({inputValue: e.target.value, marked: null})}
						onKeyDown={this.handleKeyPress.bind(this)}
					/>
					<div
						className={'arrow ' + (this.state.isExpanded ? 'expanded' : 'collapsed')}
						onClick={this.handleArrowClick.bind(this)}
					/>
				</div>
				<div
					className={'dropdown ' + (this.state.isExpanded ? 'expanded' : 'collapsed')}
					style={{top: isSpace ? '32px' : `${-18 - neededHeight}px`}}
				>
					<div className="dropdown-inner" ref="dropdown">
						{clients.map((client, index) => (
							<div
								data-cy="client"
								className={'client ' + (index === this.state.marked ? 'marked' : '')}
								key={client.node.id}
								onMouseDown={this.handleSelectItem.bind(this, client.node.id)}
								onMouseEnter={this.handleOptionMouseEnter.bind(this, index)}
								onMouseLeave={this.handleOptionMouseLeave.bind(this)}
							>
								{!client.node.id ? (
									<div className="image-container">
										<div className="no-logo" />
									</div>
								) : (
									<div className="image-container">
										{client.node.logoId ? (
											<img
												crossOrigin="use-credentials"
												src={clientLogoSrc(client.node.logoId)}
												width="34"
												height="34"
											/>
										) : (
											<img src={getDefaultExtraSmallClientLogo(client.node.clientLogoDefaultId)} />
										)}
									</div>
								)}
								<div className="text-container">
									<div className="name">{client.node.name}</div>
								</div>
							</div>
						))}
						<div className="button-container">
							{this.props.canChangeClient ? (
								<div className="button-new" data-cy="add-new-client" onClick={this.handleNewClick.bind(this)}>
									{formatMessage({id: 'client_selector.add_button_text'})}
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ClientSelector.propTypes = {
	clients: PropTypes.array.isRequired,
	showAddClientModal: PropTypes.func.isRequired,
	canChangeClient: PropTypes.bool.isRequired,
};

export default injectIntl(ClientSelector);
