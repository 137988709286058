import React from 'react';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkify = linkifyIt();
linkify.tlds(tlds);

export default readOnly => {
	return {
		decorators: [
			{
				// Normal links
				strategy: (contentBlock, callback) => {
					const links = linkify.match(contentBlock.get('text'));
					if (typeof links !== 'undefined' && links !== null) {
						for (let i = 0; i < links.length; i += 1) {
							callback(links[i].index, links[i].lastIndex);
						}
					}
				},
				component: props => {
					const links = linkify.match(props.decoratedText);
					const href = links && links[0] ? links[0].url : '';
					return (
						<a
							href={href}
							target="_blank"
							rel="noopener noreferrer"
							style={{cursor: 'pointer'}}
							onClick={() => {
								if (!readOnly) {
									window.open(href, '_blank');
								}
							}}
						>
							{props.children}
						</a>
					);
				},
			},
			{
				// LINK entity ranges
				strategy: (contentBlock, callback, contentState) => {
					contentBlock.findEntityRanges(character => {
						const entityKey = character.getEntity();
						return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
					}, callback);
				},
				component: props => {
					const {href} = props.contentState.getEntity(props.entityKey).getData();
					return (
						<a
							href={href}
							target="_blank"
							style={{cursor: 'pointer'}}
							onClick={() => {
								if (!readOnly) {
									window.open(href, '_blank');
								}
							}}
						>
							{props.children}
						</a>
					);
				},
			},
			{
				// JIRA inlinecard links
				strategy: (contentBlock, callback, contentState) => {
					contentBlock.findEntityRanges(character => {
						const entityKey = character.getEntity();
						return entityKey !== null && contentState.getEntity(entityKey).getType() === 'JIRA_INLINECARD';
					}, callback);
				},
				component: props => {
					const {href} = props.contentState.getEntity(props.entityKey).getData();
					return (
						<a
							href={href}
							target="_blank"
							style={{cursor: 'pointer'}}
							onClick={() => {
								if (!readOnly) {
									window.open(href, '_blank');
								}
							}}
						>
							{props.children}
						</a>
					);
				},
			},
		],
	};
};
