import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import ReactDOM from 'react-dom';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import Util from '../../forecast-app/shared/util/util';

class DatePicker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isFocused: this.props.inline,
			yearView: false,
			viewedMonth: this.props.value ? moment(this.props.value).month() + 1 : moment().month() + 1,
			viewedYear: this.props.value ? moment(this.props.value).year() : moment().year(),
			selected: this.props.value
				? this.props.value
				: Util.CreateNonUtcMomentDate(moment().year(), moment().month() + 1, moment().date()),
			years: null,
			showOnTop: false,
		};
	}

	UNSAFE_componentWillMount() {
		const thisYear = moment().year();
		let years = [];
		for (let i = thisYear - 100; i <= thisYear + 100; i++) {
			years.push(i);
		}
		this.setState({years: years});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (!nextProps.value) {
			this.setState({selected: moment().startOf('day')});
		} else if (!nextProps.value.isSame(this.props.value)) {
			this.setState({selected: nextProps.value});
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		const element = this.props.weekPicker ? this.week_picker : this.date_label;
		if (
			!this.props.disablePlacementCalc &&
			!this.props.inline &&
			nextState.isFocused &&
			this.state.showOnTop !==
				(ReactDOM.findDOMNode(element).getBoundingClientRect().bottom < 500 &&
					ReactDOM.findDOMNode(element).getBoundingClientRect().top > 300)
		) {
			this.setState({
				showOnTop:
					this.state.isFocused &&
					ReactDOM.findDOMNode(element).getBoundingClientRect().bottom < 500 &&
					ReactDOM.findDOMNode(element).getBoundingClientRect().top > 300,
			});
		}
	}

	componentDidUpdate() {
		if (this.state.yearView) {
			const yearList = ReactDOM.findDOMNode(this.year_list);
			const yearListTop = yearList.getBoundingClientRect().top;
			this.state.years.forEach((year, index) => {
				if (year === this.state.selected.year()) {
					const yearTop = ReactDOM.findDOMNode(this.selected_year).getBoundingClientRect().top - 80;
					this.year_list_scrollbars.setScrollTop(yearTop - yearListTop);
				}
			});
		}
	}

	handleBlur(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (
			this.props.inline ||
			(newTarget && newTarget.className === 'date-picker-wrapper') ||
			(e.target && e.target.className === 'calendar-wrapper ') ||
			(newTarget && newTarget.className === 'select-wrapper')
		) {
			return;
		}
		this.handleCancel();
	}

	toggleFocus() {
		if (!this.props.locked && !this.props.inline) {
			this.setState({isFocused: !this.state.isFocused});
		}
	}

	handleCancel() {
		if (this.props.value) {
			this.setState({
				isFocused: false,
				yearView: false,
				selected: this.props.value,
				viewedMonth: moment(this.props.value).month() + 1,
				viewedYear: moment(this.props.value).year(),
			});
		} else {
			this.setState({
				isFocused: false,
				yearView: false,
				selected: moment(),
				viewedMonth: moment(new Date()).month() + 1,
				viewedYear: moment().year(),
			});
		}
	}

	handleSelectDate(date) {
		this.props.onConfirm(date);
		if (this.props.inline) {
			this.setState({selected: date});
		} else {
			this.setState({selected: date, isFocused: false});
		}
	}

	switchToYearView() {
		this.setState({yearView: !this.state.yearView});
	}

	switchToCalendarView() {
		this.setState({yearView: false});
	}

	handleSelectYear(year) {
		let selectedDate = moment(this.state.selected);
		selectedDate.set('year', year);
		this.props.onConfirm(selectedDate);
		this.setState({viewedYear: year, selected: selectedDate, yearView: false});
	}

	viewNextMonth() {
		if (this.state.viewedMonth === 12) {
			this.setState({viewedMonth: 1, viewedYear: this.state.viewedYear + 1});
		} else {
			this.setState({viewedMonth: this.state.viewedMonth + 1});
		}
	}

	viewPastMonth() {
		if (this.state.viewedMonth === 1) {
			this.setState({viewedMonth: 12, viewedYear: this.state.viewedYear - 1});
		} else {
			this.setState({viewedMonth: this.state.viewedMonth - 1});
		}
	}

	weeks(m) {
		const startDate = m.clone().startOf('month').startOf('week');
		const endDate = m.clone().endOf('month').endOf('week');
		const calendar = [];
		while (startDate.isBefore(endDate)) {
			calendar.push({
				days: Array(7)
					.fill(0)
					.map((n, i) => startDate.clone().add(i, 'day')),
			});
			startDate.add(7, 'day');
		}
		return calendar;
	}

	getFormatFromLocale() {
		if (this.props.locale === 'en-US') {
			return 'MM-DD-YYYY';
		} else {
			return 'DD-MM-YYYY';
		}
	}

	handleMoveToPreviousWeek() {
		if (this.props.moveToPreviousWeek) {
			this.props.moveToPreviousWeek();
		}
	}
	handleMoveToNextWeek() {
		if (this.props.moveToNextWeek) {
			this.props.moveToNextWeek();
		}
	}

	clearDate(e) {
		e.stopPropagation();
		if (this.props.clearDate) {
			this.props.clearDate();
		}
	}

	render() {
		const calendar = this.weeks(moment(this.state.viewedMonth + '/' + this.state.viewedYear, 'MM/YYYY'));
		const weekDaysShort = moment.weekdaysMin(true);
		const isDateRange = this.props.startDate && this.props.endDate;
		let style;
		if (this.props.calculatePositionOnRender && this.date_label) {
			style = {top: this.date_label.getBoundingClientRect().top - 90, left: this.date_label.getBoundingClientRect().left};
		}
		return (
			<div
				className={'date-picker-wrapper' + (this.props.locked ? ' locked' : '')}
				tabIndex="0"
				onBlur={this.handleBlur.bind(this)}
			>
				{this.props.label && !this.props.inline ? (
					<label className={this.state.isFocused ? 'label-focused' : 'label'}>{this.props.label}</label>
				) : null}
				{!this.props.inline ? (
					<div className="input-wrapper">
						{this.props.weekPicker ? (
							<div
								className="week-picker"
								ref={div => {
									this.week_picker = div;
								}}
							>
								<button className="previous-week-control" onClick={this.handleMoveToPreviousWeek.bind(this)} />
								<button className="week-value-button" onClick={this.toggleFocus.bind(this)}>
									{this.props.startDate.format('DD/MM') + '-' + this.props.endDate.format('DD/MM')}
								</button>
								<button className="next-week-control" onClick={this.handleMoveToNextWeek.bind(this)} />
							</div>
						) : (
							<span
								className={
									(this.props.locked
										? 'date-input-locked'
										: this.state.isFocused
										? 'date-input-focused'
										: 'date-input') + (!this.props.value && this.props.placeholder ? ' placeholder' : '')
								}
								type="text"
								onClick={this.toggleFocus.bind(this)}
								ref={label => {
									this.date_label = label;
								}}
							>
								{this.props.value
									? this.props.locale
										? this.props.value.format(
												this.props.dateFormat ? this.props.dateFormat : this.getFormatFromLocale()
										  )
										: this.props.value.format(
												this.props.dateFormat ? this.props.dateFormat : this.getFormatFromLocale()
										  )
									: this.props.placeholder
									? this.props.placeholder
									: ''}
								{this.props.locked || !this.props.clearable ? null : (
									<span className="clear-icon" onClick={this.clearDate.bind(this)} />
								)}
							</span>
						)}
					</div>
				) : null}
				<ReactCSSTransitionGroup transitionName="appear" transitionEnterTimeout={500} transitionLeaveTimeout={300}>
					{this.state.isFocused ? (
						<div
							className={
								'calendar-wrapper' +
								(this.props.weekPicker ? ' weekPicker' : '') +
								(this.props.rightAligned ? ' right' : ' left') +
								(!this.props.inline ? ' absolute' : '') +
								(this.state.showOnTop ? ' top' : '')
							}
							style={this.props.calculatePositionOnRender ? style : null}
						>
							<div className="calendar-header">
								<span
									className={'year-text' + (this.state.yearView ? ' ' : ' inactive')}
									onClick={this.switchToYearView.bind(this)}
								>
									{this.state.selected.year()}
								</span>
								<span
									className={'date-text' + (this.state.yearView ? ' inactive' : '')}
									onClick={this.switchToCalendarView.bind(this)}
								>
									{this.props.locale
										? this.state.selected.format('ddd, MMM DD')
										: this.state.selected.format('ddd, MMM DD')}
								</span>
							</div>
							<div className="calendar-body">
								{this.state.yearView ? (
									<div
										className={'year-view-body' + (this.props.inline ? ' inline' : '')}
										ref={div => {
											this.year_list = div;
										}}
									>
										<CustomScrollDiv
											ref={div => {
												this.year_list_scrollbars = div;
											}}
										>
											<div className="years-list">
												{this.state.years.map(year => (
													<span
														key={year}
														className={
															'year-span' +
															(this.state.selected.year() === year ? ' selected' : '')
														}
														onClick={this.handleSelectYear.bind(this, year)}
														ref={
															year === this.state.selected.year()
																? div => {
																		this.selected_year = div;
																  }
																: null
														}
													>
														{year}
													</span>
												))}
											</div>
										</CustomScrollDiv>
									</div>
								) : (
									<div className={'calendar-controls' + (this.props.inline ? ' inline' : '')}>
										<div className="month-controls">
											<span className="previous-control" onClick={this.viewPastMonth.bind(this)} />
											<span className="month-text">
												{moment(this.state.viewedMonth, 'MM').format('MMMM') +
													' ' +
													this.state.viewedYear}
											</span>
											<span className="next-control" onClick={this.viewNextMonth.bind(this)} />
										</div>
										<div className="week-days">
											{weekDaysShort.map((weekDay, index) => (
												<span key={index}>{weekDay}</span>
											))}
										</div>
										<div className="calendar">
											{calendar.map((week, index) => (
												<div key={index} className="week">
													{week.days.map((day, index) =>
														day.month() + 1 === this.state.viewedMonth ? (
															<div
																data-cy={this.props.cy}
																key={index}
																className={
																	(isDateRange &&
																		day.isSameOrAfter(this.props.startDate, 'day') &&
																		day.isSameOrBefore(this.props.endDate, 'day')) ||
																	(this.state.viewedYear === this.state.selected.year() &&
																		this.state.selected.isSame(day, 'day'))
																		? 'day-selected'
																		: this.state.viewedYear ===
																				this.state.selected.year() &&
																		  moment().dayOfYear() === day.dayOfYear()
																		? 'today'
																		: 'day'
																}
																onMouseDown={this.handleSelectDate.bind(this, day)}
															>
																<div className="circle" />
																<span className="calendar-day">{day.date()}</span>
															</div>
														) : (
															<span className="empty" key={index} />
														)
													)}
												</div>
											))}
										</div>
									</div>
								)}
							</div>
						</div>
					) : null}
				</ReactCSSTransitionGroup>
			</div>
		);
	}
}

DatePicker.propTypes = {
	label: PropTypes.string,
	value: PropTypes.object,
	onConfirm: PropTypes.func.isRequired,
	inline: PropTypes.bool,
	locked: PropTypes.bool,
	dateFormat: PropTypes.string,
	locale: PropTypes.string,
	placeholder: PropTypes.string,
	startDate: PropTypes.object,
	endDate: PropTypes.object,
	disablePlacementCalc: PropTypes.bool,
};
export default injectIntl(DatePicker);
