/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type reportedTimeComponent_viewer$ref = any;
export type reportedTimeComponent_QueryVariables = {|
  shareKey?: ?string,
  clientId?: ?string,
  personId?: ?string,
  projectId?: ?string,
  isProjectGroupType?: ?boolean,
  dateCriteria?: ?string,
  groupBy?: ?string,
  projectIds?: ?$ReadOnlyArray<?string>,
  billableFilterValue?: ?boolean,
  personIds?: ?$ReadOnlyArray<?string>,
  departmentIds?: ?$ReadOnlyArray<?string>,
  teamIds?: ?$ReadOnlyArray<?string>,
  roleIds?: ?$ReadOnlyArray<?string>,
  labelIds?: ?$ReadOnlyArray<?string>,
  skillIds?: ?$ReadOnlyArray<?string>,
  componentId?: ?string,
|};
export type reportedTimeComponent_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: reportedTimeComponent_viewer$ref,
  |}
|};
export type reportedTimeComponent_Query = {|
  variables: reportedTimeComponent_QueryVariables,
  response: reportedTimeComponent_QueryResponse,
|};
*/


/*
query reportedTimeComponent_Query(
  $shareKey: String
  $clientId: ID
  $personId: ID
  $projectId: ID
  $isProjectGroupType: Boolean
  $dateCriteria: String
  $groupBy: String
  $projectIds: [ID]
  $billableFilterValue: Boolean
  $personIds: [ID]
  $departmentIds: [ID]
  $teamIds: [ID]
  $roleIds: [ID]
  $labelIds: [ID]
  $skillIds: [ID]
  $componentId: ID
) {
  viewer {
    actualPersonId
    component(name: "insight_reported_time")
    ...reportedTimeComponent_viewer_1UO2N4
    id
  }
}

fragment reportedTimeComponent_viewer_1UO2N4 on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    reportedTimeList(projectId: $projectId, isProjectGroupType: $isProjectGroupType, clientId: $clientId, personId: $personId, dateCriteria: $dateCriteria, groupBy: $groupBy, projectIds: $projectIds, billableFilterValue: $billableFilterValue, personIds: $personIds, departmentIds: $departmentIds, teamIds: $teamIds, roleIds: $roleIds, labelIds: $labelIds, skillIds: $skillIds, componentId: $componentId) {
      id
      currency
      timeRegistrations {
        id
        personName
        departmentName
        projectName
        roleNameOrNonProjTime
        taskNameOrNonProjTime
        groupingElementName
        minutesRegistered
        dateYear
        dateMonth
        dateDay
        price
        cost
        billable
        notes
        clientName
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clientId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isProjectGroupType",
    "type": "Boolean"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateCriteria",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "groupBy",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "billableFilterValue",
    "type": "Boolean"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "departmentIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roleIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "labelIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skillIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "componentId",
    "type": "ID"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "insight_reported_time"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"insight_reported_time\")"
},
v3 = {
  "kind": "Variable",
  "name": "billableFilterValue",
  "variableName": "billableFilterValue"
},
v4 = {
  "kind": "Variable",
  "name": "clientId",
  "variableName": "clientId"
},
v5 = {
  "kind": "Variable",
  "name": "componentId",
  "variableName": "componentId"
},
v6 = {
  "kind": "Variable",
  "name": "dateCriteria",
  "variableName": "dateCriteria"
},
v7 = {
  "kind": "Variable",
  "name": "departmentIds",
  "variableName": "departmentIds"
},
v8 = {
  "kind": "Variable",
  "name": "groupBy",
  "variableName": "groupBy"
},
v9 = {
  "kind": "Variable",
  "name": "isProjectGroupType",
  "variableName": "isProjectGroupType"
},
v10 = {
  "kind": "Variable",
  "name": "labelIds",
  "variableName": "labelIds"
},
v11 = {
  "kind": "Variable",
  "name": "personId",
  "variableName": "personId"
},
v12 = {
  "kind": "Variable",
  "name": "personIds",
  "variableName": "personIds"
},
v13 = {
  "kind": "Variable",
  "name": "projectId",
  "variableName": "projectId"
},
v14 = {
  "kind": "Variable",
  "name": "projectIds",
  "variableName": "projectIds"
},
v15 = {
  "kind": "Variable",
  "name": "roleIds",
  "variableName": "roleIds"
},
v16 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v17 = {
  "kind": "Variable",
  "name": "skillIds",
  "variableName": "skillIds"
},
v18 = {
  "kind": "Variable",
  "name": "teamIds",
  "variableName": "teamIds"
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reportedTimeComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "reportedTimeComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reportedTimeComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              (v16/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/)
                ],
                "concreteType": "ReportedTimeList",
                "kind": "LinkedField",
                "name": "reportedTimeList",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportedTimeListTimeRegType",
                    "kind": "LinkedField",
                    "name": "timeRegistrations",
                    "plural": true,
                    "selections": [
                      (v19/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "personName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "departmentName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "roleNameOrNonProjTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "taskNameOrNonProjTime",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "groupingElementName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "minutesRegistered",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dateYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dateMonth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dateDay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "price",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cost",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "billable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "notes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clientName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v19/*: any*/)
            ],
            "storageKey": null
          },
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "reportedTimeComponent_Query",
    "operationKind": "query",
    "text": "query reportedTimeComponent_Query(\n  $shareKey: String\n  $clientId: ID\n  $personId: ID\n  $projectId: ID\n  $isProjectGroupType: Boolean\n  $dateCriteria: String\n  $groupBy: String\n  $projectIds: [ID]\n  $billableFilterValue: Boolean\n  $personIds: [ID]\n  $departmentIds: [ID]\n  $teamIds: [ID]\n  $roleIds: [ID]\n  $labelIds: [ID]\n  $skillIds: [ID]\n  $componentId: ID\n) {\n  viewer {\n    actualPersonId\n    component(name: \"insight_reported_time\")\n    ...reportedTimeComponent_viewer_1UO2N4\n    id\n  }\n}\n\nfragment reportedTimeComponent_viewer_1UO2N4 on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    reportedTimeList(projectId: $projectId, isProjectGroupType: $isProjectGroupType, clientId: $clientId, personId: $personId, dateCriteria: $dateCriteria, groupBy: $groupBy, projectIds: $projectIds, billableFilterValue: $billableFilterValue, personIds: $personIds, departmentIds: $departmentIds, teamIds: $teamIds, roleIds: $roleIds, labelIds: $labelIds, skillIds: $skillIds, componentId: $componentId) {\n      id\n      currency\n      timeRegistrations {\n        id\n        personName\n        departmentName\n        projectName\n        roleNameOrNonProjTime\n        taskNameOrNonProjTime\n        groupingElementName\n        minutesRegistered\n        dateYear\n        dateMonth\n        dateDay\n        price\n        cost\n        billable\n        notes\n        clientName\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b5592ff850653b0beb7cc8ee6c08019c';

module.exports = node;
