/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type expenseReportProjectComponent_viewer$ref: FragmentReference;
declare export opaque type expenseReportProjectComponent_viewer$fragmentType: expenseReportProjectComponent_viewer$ref;
export type expenseReportProjectComponent_viewer = {|
  +insightComponentsData: ?{|
    +expenseReportProject: ?{|
      +id: string,
      +currency: ?string,
      +project: ?{|
        +id: string,
        +name: ?string,
      |},
      +projectGroup: ?{|
        +id: string,
        +name: ?string,
      |},
      +client: ?{|
        +id: string,
        +name: ?string,
      |},
      +data: ?$ReadOnlyArray<?{|
        +name: ?string,
        +cost: ?number,
        +price: ?number,
        +markupPercentage: ?number,
        +quantity: ?number,
        +expenseYear: ?number,
        +expenseMonth: ?number,
        +expenseDay: ?number,
        +expenseCategory: ?{|
          +name: ?string
        |},
        +notes: ?string,
        +billable: ?boolean,
        +person: ?{|
          +firstName: ?string,
          +lastName: ?string,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
        |},
        +createdAt: ?string,
        +createdBy: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
        |},
        +updatedAt: ?string,
        +updatedBy: ?{|
          +id: string,
          +firstName: ?string,
          +lastName: ?string,
        |},
      |}>,
    |}
  |},
  +$refType: expenseReportProjectComponent_viewer$ref,
|};
export type expenseReportProjectComponent_viewer$data = expenseReportProjectComponent_viewer;
export type expenseReportProjectComponent_viewer$key = {
  +$data?: expenseReportProjectComponent_viewer$data,
  +$fragmentRefs: expenseReportProjectComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isProjectGroupType",
      "type": "Boolean"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "expenseReportProjectComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "isProjectGroupType",
              "variableName": "isProjectGroupType"
            },
            {
              "kind": "Variable",
              "name": "projectId",
              "variableName": "projectId"
            }
          ],
          "concreteType": "ExpenseReportProject",
          "kind": "LinkedField",
          "name": "expenseReportProject",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "project",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectGroupType",
              "kind": "LinkedField",
              "name": "projectGroup",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Client",
              "kind": "LinkedField",
              "name": "client",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ExpenseReportListElement",
              "kind": "LinkedField",
              "name": "data",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cost",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "markupPercentage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantity",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseYear",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseMonth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expenseDay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExpenseCategory",
                  "kind": "LinkedField",
                  "name": "expenseCategory",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "notes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billable",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "person",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureDefaultId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "createdBy",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "updatedBy",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '4188519ae5ea5f91cb1620f2b2eafad5';

module.exports = node;
