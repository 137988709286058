import {
	clearNeedsRecalculation,
	getGroupCache,
	getGroupingUtilizationData,
	getStepCache,
	setStepCachedItem,
} from '../HeatmapLogic';
import {getGroupsBy, isGlobalRecalculationNeeded} from '../../canvas-timeline/canvas_timeline_util';
import {isStepHiddenBehindLoadMore} from '../../loading/LoadMoreUtil';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import RecalculationManager from '../../RecalculationManager';

export const calculateGroupingGroupHeatmapCache = (
	pageComponent,
	group,
	stepDataArray,
	timelineMinorStep,
	subGroupPredicate = null
) => {
	if (isGlobalRecalculationNeeded(pageComponent)) {
		const subGroups = subGroupPredicate ? getGroupsBy(pageComponent, subGroupPredicate) : null;
		const groups = subGroups || group.groups;

		// We depend on the cache generated by child groups, so ensure we calculate them before fetching from cache
		groups.forEach(childGroup => childGroup.calculateHeatmapCache(childGroup, stepDataArray, timelineMinorStep));

		const groupCache = getGroupCache(pageComponent, group.id);
		const stepCache = getStepCache(groupCache, timelineMinorStep);

		for (const stepData of stepDataArray) {
			if (isStepHiddenBehindLoadMore(pageComponent, stepData)) {
				continue;
			}

			let {startDate, endDate, recalculationNeededInStep} = stepData;

			if (recalculationNeededInStep === false) {
				continue;
			}

			const heatmapData = getGroupingUtilizationData(pageComponent, groups, timelineMinorStep, startDate, endDate);

			setStepCachedItem(group.id, stepCache, timelineMinorStep, heatmapData, startDate, endDate);

			RecalculationManager.clearNeedsRecalculation(group.id, timelineMinorStep, startDate, endDate);
		}

		if (!hasFeatureFlag('scheduling_recalculation_tree')) {
			clearNeedsRecalculation(groupCache, timelineMinorStep);
		}
	}
};
