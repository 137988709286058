import {createRefetchContainer, graphql} from 'react-relay';

const MonthByMonthProgramLoader = ({viewer, children, ...props}) => {
	const formatData = () => {
		return viewer.company.financialBasedPrograms.edges.map(program => {
			const {aggregatedFinancialNumbers, timeAggregatedFinancialNumbers, expenseAggregatedFinancialNumbers} =
				program.node;
			return {
				program: {
					...program.node,
				},
				currency: viewer.company.currency,
				aggregatedFinancialNumbers: {
					...aggregatedFinancialNumbers,
				},
				timeAggregatedFinancialNumbers: {
					...timeAggregatedFinancialNumbers,
				},
				expenseAggregatedFinancialNumbers: {
					...expenseAggregatedFinancialNumbers,
				},
			};
		});
	};

	return children({data: {rows: formatData()}, ...props});
};

export const monthByMonthProgramLoaderQuery = graphql`
	query MonthByMonthProgramLoader_Query(
		$startYear: Int!
		$startMonth: Int!
		$startDay: Int!
		$endYear: Int!
		$endMonth: Int!
		$endDay: Int!
		$searchQuery: TaskSearchQueryType!
		$timeSearchQuery: TaskSearchQueryType!
		$expenseSearchQuery: TaskSearchQueryType!
		$selectedSingleValue: FinancialAggregatedNumber!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_month_by_month_program_loader")
			...MonthByMonthProgramLoader_viewer
				@arguments(
					searchQuery: $searchQuery
					timeSearchQuery: $timeSearchQuery
					expenseSearchQuery: $expenseSearchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					selectedSingleValue: $selectedSingleValue
				)
		}
	}
`;

export default createRefetchContainer(MonthByMonthProgramLoader, {
	viewer: graphql`
		fragment MonthByMonthProgramLoader_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType!"}
			timeSearchQuery: {type: "TaskSearchQueryType!"}
			expenseSearchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int!"}
			startMonth: {type: "Int!"}
			startDay: {type: "Int!"}
			endYear: {type: "Int!"}
			endMonth: {type: "Int!"}
			endDay: {type: "Int!"}
			selectedSingleValue: {type: "FinancialAggregatedNumber!"}
		) {
			id
			company {
				currency
				financialBasedPrograms(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: MONTH
					aggregates: [$selectedSingleValue]
				) {
					edges {
						node {
							id
							name
							color
							aggregatedFinancialNumbers(
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								aggregateLevel: MONTH
								aggregates: [$selectedSingleValue]
							)
							timeAggregatedFinancialNumbers: aggregatedFinancialNumbers(
								searchQuery: $timeSearchQuery
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								aggregateLevel: MONTH
								aggregates: [$selectedSingleValue]
								fetchOwnData: true
							)
							expenseAggregatedFinancialNumbers: aggregatedFinancialNumbers(
								searchQuery: $expenseSearchQuery
								startYear: $startYear
								startMonth: $startMonth
								startDay: $startDay
								endYear: $endYear
								endMonth: $endMonth
								endDay: $endDay
								aggregateLevel: MONTH
								aggregates: [$selectedSingleValue]
								fetchOwnData: true
							)
						}
					}
				}
			}
		}
	`,
});
