import React from 'react';
import Util from '../../forecast-app/shared/util/util';
import {deletePhase} from './InitialPlanLogic';
import {createToast} from '../../forecast-app/shared/components/toasts/toast';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../constants';
import {TooltipFormulaRenderer} from '../TooltipFormulaRenderer';
import {remapOptionTranslationIds} from '../../forecast-app/shared/util/FinancialInternationalisationUtil';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';

export const ITEM_CATEGORY = {
	EXPENSE: 'expense',
	ROLE: 'role',
};

export const showModalDeletePhase = (phaseId, projectId, formatMessage) => {
	const onSuccess = () => {
		createToast({duration: 5000, message: formatMessage({id: 'scope.has-been-deleted'})});
	};
	const callbackPositive = params => {
		deletePhase(phaseId, projectId, onSuccess);
	};

	showModal({
		type: MODAL_TYPE.WARNING,
		warningMessageId: 'project_scoping.delete_confirmation',
		warningInformation: [
			formatMessage({id: 'baseline.delete_confirmation_information'}),
			formatMessage({id: 'common.warning.this_action_can_not_be_undone'}),
		],
		buttons: [
			{
				text: formatMessage({id: 'common.cancel'}),
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.WHITE,
			},
			{
				text: formatMessage({id: 'common.delete'}),
				callback: callbackPositive,
				style: BUTTON_STYLE.FILLED,
				color: BUTTON_COLOR.RED,
				cy: 'modal-btn-confirm-delete',
			},
		],
	});
};

export const theEyeOptions = (useFixedPriceForBaselineRevenue, isFixedPrice, noRevenue) => {
	const options = [];

	const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();

	options.push(
		{
			name: 'dates',
			checked: true,
			translationId: 'baseline.phase_dates',
			width: 250,
			align: 'left',
			leftOffset: 8,
			nestedOptions: null,
		},
		{
			name: 'estimate',
			checked: true,
			translationId: 'common.work_estimate',
			translationIdProjectSection: 'common.total_estimate',
			tooltipText: 'common.work_estimate_tootlip',
			width: 135,
			align: 'right',
			nestedOptions: null,
			hide: true,
		}
	);
	if (!noRevenue) {
		options.push({
			name: 'ratePH',
			checked: false,
			translationId: 'common.rate_per_hour',
			tooltipText: 'common.rate_per_hour_tooltip',
			width: 135,
			align: 'right',
			nestedOptions: null,
		});
	}

	if (!noRevenue && hasFeatureFlag('baseline_financial_service')) {
		options.push(
			...(isFixedPrice
				? [
						{
							name: 'value_of_service',
							checked: true,
							translationId: 'common.value_of_service',
							translationIdProjectSection: 'common.value_of_service',
							tooltipText: 'common.revenue_tooltip',
							width: 155,
							align: 'right',
							nestedOptions: null,
						},
				  ]
				: []),
			...(!isFixedPrice || (isFixedPrice && useFixedPriceForBaselineRevenue)
				? [
						{
							name: 'revenue',
							checked: true,
							translationId: 'baseline.revenue',
							translationIdProjectSection: 'common.total_revenue',
							tooltipText: 'common.revenue_tooltip',
							width: 155,
							align: 'right',
							nestedOptions: null,
							hide: false,
						},
				  ]
				: [])
		);
	}

	if (!hasRevenueWithoutCostAccess) {
		options.push(
			{
				name: 'costPH',
				checked: false,
				translationId: 'common.cost_per_hour',
				tooltipText: 'common.cost_per_hour_tooltip',
				width: 135,
				align: 'right',
				nestedOptions: null,
			},
			{
				name: 'cost',
				checked: true,
				translationId: 'common.cost',
				translationIdProjectSection: 'expense_item_modal.total_cost',
				tooltipText: 'common.cost_tooltip',
				width: 135,
				align: 'right',
				nestedOptions: null,
				hide: true,
			}
		);
	}

	if (!noRevenue) {
		options.push(
			{
				name: 'markup',
				checked: false,
				translationId: 'common.markup',
				width: 135,
				align: 'right',
				nestedOptions: null,
			},
			...(!hasFeatureFlag('baseline_financial_service')
				? [
						{
							name: 'revenue',
							checked: true,
							translationId: 'project_budget.revenue',
							translationIdProjectSection: 'common.total_revenue',
							tooltipText: 'common.revenue_tooltip',
							width: 155,
							align: 'right',
							nestedOptions: null,
							hide: true,
						},
				  ]
				: [])
		);
		if (!hasRevenueWithoutCostAccess) {
			options.push(
				{
					name: 'profit',
					checked: true,
					translationId: 'common.profit',
					translationIdProjectSection: 'common.total_profit',
					tooltipText: 'common.profit_tooltip',
					width: 135,
					align: 'right',
					nestedOptions: null,
					hide: true,
				},
				// Add margin to theEye
				{
					name: 'margin',
					checked: false,
					translationId: 'common.margin',
					tooltipText: 'common.margin_tooltip',
					width: 135,
					align: 'right',
					nestedOptions: null,
				}
			);
		}
	}
	remapOptionTranslationIds(options);
	return options;
};

const isEmpty = obj => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

export const toggleAllPhases = (projectId, shouldBeCollapsed) => {
	const localStorageName = projectId + '-baseline-map';
	const baselineMap = JSON.parse(Util.localStorageGetItem(localStorageName) || null);
	const map = new Map(baselineMap && !isEmpty(baselineMap) ? baselineMap : null);
	map.forEach((v, k) => map.set(k, shouldBeCollapsed));
	Util.localStorageSetItem(localStorageName, JSON.stringify(map));
	return map;
};

export const setShouldCollapseAll = (projectId, collapseAll, setCollapseAll) => {
	const localStorageName = projectId + '-baseline-map';
	const baselineMap = JSON.parse(Util.localStorageGetItem(localStorageName) || null);
	const map = new Map(baselineMap && !isEmpty(baselineMap) ? baselineMap : null);
	// If any phase is expanded: collapse all; otherwise expand all
	const shouldClose = !![...map.values()].find(bool => bool);
	if (collapseAll !== shouldClose) {
		setCollapseAll(shouldClose);
	}
};

export const initExpandedPhasedLocalStorage = (projectId, phases) => {
	const localStorageName = projectId + '-baseline-map';
	const baselineMap = JSON.parse(Util.localStorageGetItem(localStorageName) || null);
	const map = new Map(baselineMap && !isEmpty(baselineMap) ? baselineMap : null);
	let newMap = new Map();

	phases.forEach(p => {
		const phase = p.node ? p.node : p;
		if (map.has(phase.id)) {
			newMap.set(phase.id, map.get(phase.id));
		} else {
			newMap.set(phase.id, true);
		}
	});
	Util.localStorageSetItem(localStorageName, JSON.stringify(newMap));
	return newMap;
};

export const togglePhase = (projectId, phaseId) => {
	const localStorageName = projectId + '-baseline-map';
	const baselineMap = JSON.parse(Util.localStorageGetItem(localStorageName) || null);
	const map = new Map(baselineMap && !isEmpty(baselineMap) ? baselineMap : null);
	const isCollapsed = map.get(phaseId);
	map.set(phaseId, !isCollapsed);
	Util.localStorageSetItem(localStorageName, JSON.stringify(map));
	return map;
};

const getCostToolTip = (formatMessage, header) => [
	{
		title: formatMessage({id: header ? 'baseline.baseline_cost' : 'common.cost'}),
		description: formatMessage({
			id: header ? 'baseline.cost_header_tooltip_description' : 'baseline.cost_tooltip_description',
		}),
		details: [
			formatMessage({id: 'common.work_estimate'}),
			'x',
			formatMessage({id: 'common.cost_per_hour'}),
			'+',
			formatMessage({id: 'baseline.cost_expenses'}),
		],
	},
];

const getFixedPriceRevenueToolTip = (formatMessage, header) => [
	{
		title: header ? formatMessage({id: 'baseline.baseline_revenue'}) : formatMessage({id: 'baseline.revenue'}),
		description: header
			? formatMessage({id: 'baseline.total_baseline_revenue_tooltip_fixed_price'})
			: formatMessage({id: 'baseline.revenue_tooltip'}),
	},
];

const getRevenueToolTip = (formatMessage, header) => [
	{
		title: header ? formatMessage({id: 'baseline.baseline_revenue'}) : formatMessage({id: 'baseline.revenue'}),
		description: formatMessage({id: 'baseline.revenue_description'}),
		details: [
			formatMessage({id: 'baseline.work_estimate'}),
			'x',
			formatMessage({id: 'baseline.rate_per_hour'}),
			'+',
			formatMessage({id: 'project_budget.revenue_from_expenses'}),
		],
	},
];

const getProfitToolTip = (formatMessage, isFixedPrice, useFixedPriceCalculation, header) => [
	{
		title: formatMessage({id: header ? 'baseline.baseline_profit' : 'common.profit'}),
		details:
			isFixedPrice && !useFixedPriceCalculation
				? [
						formatMessage({
							id: header ? 'baseline.value_of_service' : 'common.value_of_service',
						}),
						'-',
						formatMessage({id: header ? 'baseline.baseline_cost' : 'common.cost'}),
				  ]
				: [
						formatMessage({
							id: header ? 'project_budget.baseline_revenue' : 'baseline.revenue',
						}),
						'-',
						formatMessage({id: header ? 'baseline.baseline_cost' : 'common.cost'}),
				  ],
	},
];

const getValueOfServiceToolTip = (formatMessage, header) => [
	header
		? {
				title: formatMessage({id: 'baseline.value_of_service'}),
				description: formatMessage({id: 'baseline.total_baseline_value_of_service_header_tooltip_description'}),
		  }
		: {
				title: formatMessage({id: 'common.value_of_service'}),
				description: formatMessage({id: 'baseline.total_baseline_value_of_service_tooltip_description'}),
				details: [
					formatMessage({id: 'baseline.work_estimate'}),
					'x',
					formatMessage({id: 'baseline.rate_per_hour'}),
					'+',
					formatMessage({id: 'common.expenses'}),
				],
		  },
];

const getCostPHToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.cost_per_hour'}),
		description: formatMessage({id: 'baseline.cost_per_hour'}),
	},
];

const getMarkupToolTip = formatMessage => [
	{
		title: formatMessage({id: 'common.markup'}),
		description: formatMessage({id: 'baseline.markup_on_expenses'}),
		details: [
			'(',
			formatMessage({id: 'project_budget.expense_revenue'}),
			'-',
			formatMessage({id: 'project_budget.expense_cost'}),
			')',
			'/',
			formatMessage({id: 'project_budget.expense_cost'}),
			'x',
			'100',
		],
	},
];

const getMarginToolTip = (formatMessage, isFixedPrice, useFixedPriceCalculation, header) => [
	header
		? {
				title: formatMessage({id: 'baseline.baseline_margin'}),
				details: [
					formatMessage({id: 'baseline.baseline_profit'}),
					'/',
					formatMessage({
						id:
							isFixedPrice && !useFixedPriceCalculation
								? 'baseline.value_of_service'
								: 'project_budget.baseline_revenue',
					}),
					'x',
					'100',
				],
		  }
		: {
				title: formatMessage({id: 'common.margin'}),
				details: [
					formatMessage({id: 'common.profit'}),
					'/',
					formatMessage({
						id: isFixedPrice && !useFixedPriceCalculation ? 'common.value_of_service' : 'baseline.revenue',
					}),
					'x',
					'100',
				],
		  },
];

export const getTooltipForHeader = (column, formatMessage, isFixedPrice, useFixedPriceCalculation, header) => {
	switch (column) {
		case 'cost':
			return <TooltipFormulaRenderer items={getCostToolTip(formatMessage, header)} />;
		case 'revenue':
			if (isFixedPrice) {
				return <TooltipFormulaRenderer items={getFixedPriceRevenueToolTip(formatMessage, header)} />;
			} else {
				return <TooltipFormulaRenderer items={getRevenueToolTip(formatMessage, header)} />;
			}
		case 'profit':
			return (
				<TooltipFormulaRenderer
					items={getProfitToolTip(formatMessage, isFixedPrice, useFixedPriceCalculation, header)}
				/>
			);
		case 'costPH':
			return <TooltipFormulaRenderer items={getCostPHToolTip(formatMessage, header)} />;
		case 'margin':
			return (
				<TooltipFormulaRenderer
					items={getMarginToolTip(formatMessage, isFixedPrice, useFixedPriceCalculation, header)}
				/>
			);
		case 'value_of_service':
			return <TooltipFormulaRenderer items={getValueOfServiceToolTip(formatMessage, header)} />;
		case 'markup':
			return <TooltipFormulaRenderer items={getMarkupToolTip(formatMessage, header)} />;
		default:
			return null;
	}
};

export const getGroupedFinancialNumbersByPhaseAndBaselineId = groupedFinancialNumbers => {
	const financialNumbersMap = new Map();
	const totalFinancialNumbers = {
		totalBaselineRevenue: 0,
		totalBaselineTimeAndExpenses: 0,
		totalCost: 0,
		totalProfit: 0,
		totalMargin: 0,
	};
	groupedFinancialNumbers.forEach(financialNumbers => {
		const {phaseId, phaseBaselineRoleId, phaseBaselineExpenseId} = financialNumbers;
		const phaseFinancials = financialNumbersMap.get(phaseId) || {
			phaseId,
			totalBaselineRevenue: 0,
			totalBaselineTimeAndExpenses: 0,
			totalCost: 0,
			totalProfit: 0,
			totalMargin: 0.0,
			roles: new Map(),
			expenses: new Map(),
		};
		phaseFinancials.totalBaselineRevenue += financialNumbers.baselineRevenue;
		totalFinancialNumbers.totalBaselineRevenue += financialNumbers.baselineRevenue;
		phaseFinancials.totalBaselineTimeAndExpenses += financialNumbers.baselineTimeAndExpenses;
		totalFinancialNumbers.totalBaselineTimeAndExpenses += financialNumbers.baselineTimeAndExpenses;
		phaseFinancials.totalCost += financialNumbers.baselineCost;
		totalFinancialNumbers.totalCost += financialNumbers.baselineCost;
		phaseFinancials.totalProfit += financialNumbers.baselineProfit;
		totalFinancialNumbers.totalProfit += financialNumbers.baselineProfit;
		if (phaseBaselineRoleId) {
			const financialsByPhaseBaselineRoleId = phaseFinancials.roles;
			financialsByPhaseBaselineRoleId.set(phaseBaselineRoleId, financialNumbers);
			phaseFinancials.roles = financialsByPhaseBaselineRoleId;
		} else if (phaseBaselineExpenseId) {
			const financialsByPhaseBaselineExpenseId = phaseFinancials.expenses;
			financialsByPhaseBaselineExpenseId.set(phaseBaselineExpenseId, financialNumbers);
			phaseFinancials.expenses = financialsByPhaseBaselineExpenseId;
		}
		financialNumbersMap.set(phaseId, phaseFinancials);
	});

	financialNumbersMap.forEach(phaseFinancials => {
		const {totalBaselineRevenue, totalProfit} = phaseFinancials;
		phaseFinancials.totalMargin = totalProfit / totalBaselineRevenue;
	});
	totalFinancialNumbers.totalMargin = totalFinancialNumbers.totalProfit / totalFinancialNumbers.totalBaselineRevenue;

	return {totalFinancialNumbers, financialNumbersMap};
};
