import Moment from 'moment';
import {
	getHolidaysOfTheDay,
	workingHourForTheDay,
} from '../../../forecast-app/my-work-tab/my-timesheets-page/timesheets_person_data';
import {BillableTimeText, HiddenText, PersonDayCell, PersonDayCellInner} from './timesheets_team_page_styled';
import {
	getTimeRegistrationsOfTheDay,
	separateWorkingTimeAndTimeOff,
} from '../../../forecast-app/my-work-tab/my-timesheets-page/timesheets_time_registrations';
import Util from '../../../forecast-app/shared/util/util';
import CheckmarkIcon from '../../../images/checkmark_icon';
import React from 'react';
import {useIntl} from 'react-intl';
import {isBillableSplitAllowed} from '../../../forecast-app/shared/util/cache/TimeRegistrationSettingsUtil';
import {
	getBambooHRLockErrorMessage,
	getLockErrorMessage,
	updateTimeRegistration,
} from '../../../forecast-app/shared/components/edit-time-entry/EditTimeEntriesLogic';
import {getCompanyLockedDate} from '../time-lock/TimeLockUtil';
import EditTimeEntries from '../../../forecast-app/shared/components/edit-time-entry/EditTimeEntries';
import {hasNoAccessToEditProjectTimeReg, hasNoAccessToTimeRegProject} from './TimesheetsUtil';

const TimesheetsDayCell = ({
	index,
	viewer,
	firstDayOfWeek,
	lockedDate,
	person,
	timeRegs,
	timeRegistrations = [],
	timeRegClassName,
}) => {
	const intl = useIntl();

	const {company} = viewer;

	const billableSplitAllowed = isBillableSplitAllowed();
	const day = firstDayOfWeek.clone().add(index, 'day');
	const today = Moment(day).isSame(Moment(), 'day');
	const lockedDay = lockedDate && lockedDate.isSameOrAfter(day, 'd');
	const companyLockedDate = getCompanyLockedDate(company);
	let workingHours = 0;
	let totalTime = 0;
	let totalBillableTime = 0;
	let timeWorked;
	let timeRegsOfTheDay = [];
	let numberOfTimeRegistrations = 0;
	let numberOfAccessibleTimeRegistrations = 0;
	let excludeFromCompanyLockedPeriod = false;
	if (person) {
		workingHours = workingHourForTheDay(person, day);
		timeRegsOfTheDay = getTimeRegistrationsOfTheDay(day, timeRegs);
		numberOfTimeRegistrations = timeRegsOfTheDay.length;
		numberOfAccessibleTimeRegistrations = timeRegsOfTheDay.filter(
			timeReg => !hasNoAccessToTimeRegProject(timeReg.node)
		).length;
		timeWorked = separateWorkingTimeAndTimeOff(timeRegsOfTheDay);
		totalTime = timeWorked.timeOff + timeWorked.workingTime;
		totalBillableTime = timeWorked.billableTime;
		excludeFromCompanyLockedPeriod = person.excludeFromCompanyLockedPeriod;
	} else {
		totalTime = timeRegistrations?.reduce((sum, t) => {
			if (t.node.day === day.date()) {
				numberOfTimeRegistrations++;
				if (!hasNoAccessToTimeRegProject(t.node)) {
					numberOfAccessibleTimeRegistrations++;
				}
				timeRegsOfTheDay.push(t);
				return t.node.minutesRegistered + sum;
			}
			return sum;
		}, 0);

		totalBillableTime = timeRegistrations?.reduce((sum, t) => {
			if (t.node.day === day.date()) {
				return t.node.billableMinutesRegistered + sum;
			}
			return sum;
		}, 0);
		/* if any time-reg has a person that is not excluded, then we do not exclude from the company locked period */
		excludeFromCompanyLockedPeriod = !timeRegistrations.some(t => !t?.node?.person?.excludeFromCompanyLockedPeriod);
	}
	const holidayOfTheDay = person ? getHolidaysOfTheDay(person, day) : [];

	const nonWorkingDay = person ? holidayOfTheDay.length > 0 || workingHours === 0 : false;

	//the max is used for the extra time, it would stay 0 for the time registration rows
	const percentageTimeOff = workingHours > 0 ? (timeWorked.timeOff * 100) / Math.max(workingHours, totalTime) : 0;
	const percentageWorked = workingHours > 0 ? (timeWorked.workingTime * 100) / Math.max(workingHours, totalTime) : 0;

	const totalDailyTimeInHours = Util.convertMinutesToFullHour(totalTime, intl, true);
	const totalDailyBillableTimeInHours = Util.convertMinutesToFullHour(totalBillableTime, intl, true);

	const showBillableTime =
		billableSplitAllowed && totalDailyBillableTimeInHours && totalDailyTimeInHours !== totalDailyBillableTimeInHours;

	const timeRegIsLockedByBambooMessage = getBambooHRLockErrorMessage(
		timeRegsOfTheDay,
		company.bambooHREnabled,
		intl.formatMessage({id: 'integrations.bamboohr.edit_time_off_tooltip'})
	);

	const editTimeRegError = getLockErrorMessage(
		day.toDate(),
		companyLockedDate?.toDate(),
		timeRegsOfTheDay,
		intl.formatMessage,
		timeRegIsLockedByBambooMessage,
		excludeFromCompanyLockedPeriod
	);

	const editDisabled = !!editTimeRegError || hasNoAccessToEditProjectTimeReg(viewer.actualPersonId, timeRegsOfTheDay);

	return (
		<PersonDayCell
			day={index}
			className={timeRegClassName + (today ? ' today' : '') + (nonWorkingDay ? ' non-working-day' : '')}
		>
			<PersonDayCellInner
				timeWorkedHeight={percentageWorked}
				timeOffHeight={percentageTimeOff}
				whiteSpaceHeight={100 - (percentageWorked + percentageTimeOff)}
			>
				{person && !nonWorkingDay ? (
					<div className={'background'}>
						<div className={'white-space-background'}></div>
						<div className={'time-worked-background'}></div>
						<div className={'time-off-background'}></div>
					</div>
				) : null}
				{numberOfAccessibleTimeRegistrations > 0 ? (
					<EditTimeEntries
						dayRegNodes={timeRegsOfTheDay}
						dayOfInput={day?._isAMomentObject ? day?.toDate() : day}
						infoText={editTimeRegError}
						disabled={editDisabled}
						mutation={updateTimeRegistration}
						showBillableTime={showBillableTime}
					/>
				) : null}
				<div className={'time-worked' + timeRegClassName}>
					<div>
						<div data-cy={'total-hours'} className={'total' + (lockedDay ? ' locked' : '')}>
							{numberOfTimeRegistrations > 0 ? (
								<span style={{display: 'flex', justifyContent: 'flex-end'}}>
									{person && totalTime >= workingHours && !nonWorkingDay ? (
										<CheckmarkIcon locked={lockedDay} />
									) : (
										''
									)}
									{totalDailyTimeInHours}
									{billableSplitAllowed ? <HiddenText> bil.</HiddenText> : null}
								</span>
							) : (
								''
							)}
						</div>
						{billableSplitAllowed ? (
							<BillableTimeText data-cy={'billable-hours'}>
								{showBillableTime ? `${totalDailyBillableTimeInHours} bil.` : <HiddenText> bil.</HiddenText>}
							</BillableTimeText>
						) : null}
					</div>
				</div>
			</PersonDayCellInner>
		</PersonDayCell>
	);
};

export default TimesheetsDayCell;
