/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectPortfolioMonthByMonthClientDownload_viewer$ref: FragmentReference;
declare export opaque type ProjectPortfolioMonthByMonthClientDownload_viewer$fragmentType: ProjectPortfolioMonthByMonthClientDownload_viewer$ref;
export type ProjectPortfolioMonthByMonthClientDownload_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string,
    +financialBasedClients: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +aggregatedFinancialNumbers: $ReadOnlyArray<?string>,
          +timeAggregatedFinancialNumbers: $ReadOnlyArray<?string>,
          +expenseAggregatedFinancialNumbers: $ReadOnlyArray<?string>,
        |}
      |}>
    |},
  |},
  +$refType: ProjectPortfolioMonthByMonthClientDownload_viewer$ref,
|};
export type ProjectPortfolioMonthByMonthClientDownload_viewer$data = ProjectPortfolioMonthByMonthClientDownload_viewer;
export type ProjectPortfolioMonthByMonthClientDownload_viewer$key = {
  +$data?: ProjectPortfolioMonthByMonthClientDownload_viewer$data,
  +$fragmentRefs: ProjectPortfolioMonthByMonthClientDownload_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "aggregateLevel",
  "value": "MONTH"
},
v2 = {
  "items": [
    {
      "kind": "Variable",
      "name": "aggregates.0",
      "variableName": "selectedSingleValue"
    }
  ],
  "kind": "ListValue",
  "name": "aggregates"
},
v3 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v4 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v5 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v6 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v7 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v8 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
},
v9 = {
  "kind": "Literal",
  "name": "fetchOwnData",
  "value": true
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "timeSearchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "expenseSearchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "selectedSingleValue",
      "type": "FinancialAggregatedNumber!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPortfolioMonthByMonthClientDownload_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "concreteType": "ClientTypeConnection",
          "kind": "LinkedField",
          "name": "financialBasedClients",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClientTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Client",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/)
                      ],
                      "kind": "ScalarField",
                      "name": "aggregatedFinancialNumbers",
                      "storageKey": null
                    },
                    {
                      "alias": "timeAggregatedFinancialNumbers",
                      "args": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v9/*: any*/),
                        {
                          "kind": "Variable",
                          "name": "searchQuery",
                          "variableName": "timeSearchQuery"
                        },
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/)
                      ],
                      "kind": "ScalarField",
                      "name": "aggregatedFinancialNumbers",
                      "storageKey": null
                    },
                    {
                      "alias": "expenseAggregatedFinancialNumbers",
                      "args": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v9/*: any*/),
                        {
                          "kind": "Variable",
                          "name": "searchQuery",
                          "variableName": "expenseSearchQuery"
                        },
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/)
                      ],
                      "kind": "ScalarField",
                      "name": "aggregatedFinancialNumbers",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1f40f1cf44783efe0bf7d888aa69bf65';

module.exports = node;
