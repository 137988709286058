import React from 'react';
import {OverlayLoaderStyle} from '../../styles/components/loaders/overlay_loader_styled';
/**
 * Overlay loader which fills out the parent div with
 * a transparent grey color and a centered loader animation
 */
const OverlayLoader = () => {
	return (
		<OverlayLoaderStyle>
			<div></div>
		</OverlayLoaderStyle>
	);
};

export default OverlayLoader;
