import { graphql } from 'react-relay';
export const ExpenseManagementPageQuery = graphql `
	query ExpenseManagementPageQuery($startDate: String, $endDate: String) {
		viewer {
			actualPersonId
			component(name: "expense_management")
			...ExpenseManagementPage_viewer @arguments(startDate: $startDate, endDate: $endDate)
		}
	}
`;
