import React from 'react';
import {DeprecatedText as Text} from '@forecast-it/design-system';
import NoShrinkFlexItem from './NoShrinkFlexItem';

const ProjectPrefixFlexItem = ({children}) => {
	return (
		<NoShrinkFlexItem>
			<Text variant={'bold'}>{children}</Text>
		</NoShrinkFlexItem>
	);
};

export default ProjectPrefixFlexItem;
