import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Util from '../../../forecast-app/shared/util/util';

class SprintListDummy extends Component {
	createDummySprint(
		id,
		name,
		startDate,
		endDate,
		percentDone,
		cardsTodo,
		cardsInProgress,
		cardsDone,
		forecast,
		remaining,
		totalAllocated,
		remainingAllocated,
		forecastPrice,
		actualPrice,
		reported
	) {
		const {formatDate} = this.props.intl;
		const sprint = {};
		sprint.id = id;
		sprint.name = name;
		sprint.startDate = formatDate(startDate);
		sprint.endDate = formatDate(endDate);
		sprint.percentDone = percentDone + '%';
		sprint.cardsTodo = cardsTodo;
		sprint.cardsInProgress = cardsInProgress;
		sprint.cardsDone = cardsDone;
		sprint.cardsTotal = cardsTodo + cardsInProgress + cardsDone;
		sprint.forecast = Util.convertMinutesToFullHour(forecast * 60, this.props.intl);
		sprint.remaining = Util.convertMinutesToFullHour(remaining * 60, this.props.intl);
		sprint.totalAllocated = Util.convertMinutesToFullHour(totalAllocated * 60, this.props.intl);
		sprint.remainingAllocated = Util.convertMinutesToFullHour(remainingAllocated * 60, this.props.intl);
		sprint.hoursDiff = Util.convertMinutesToFullHour((remainingAllocated - remaining) * 60, this.props.intl);
		sprint.reported = Util.convertMinutesToFullHour(reported * 60, this.props.intl);
		sprint.diff = Util.convertMinutesToFullHour((forecast - (reported + remaining)) * 60, this.props.intl);
		sprint.progress = (
			<div className="progress-container" style={{border: '1px solid rgb(68, 180, 255)'}}>
				<div className="progress-indicator" style={{width: percentDone + '%', backgroundColor: 'rgb(68, 180, 255)'}} />
			</div>
		);
		sprint.forecastPrice = forecastPrice;
		sprint.actualPrice = actualPrice;
		return sprint;
	}

	render() {
		let sprints = [];
		sprints.push(
			this.createDummySprint(
				1,
				'Example sprint 1',
				new Date(),
				new Date(),
				100,
				0,
				0,
				10,
				150,
				0,
				200,
				0,
				'$250',
				'$200',
				5
			)
		);
		sprints.push(
			this.createDummySprint(
				2,
				'Example sprint 2',
				new Date(),
				new Date(),
				50,
				1,
				3,
				4,
				120,
				50,
				130,
				40,
				'$400',
				'$450',
				10
			)
		);
		sprints.push(
			this.createDummySprint(
				3,
				'Example sprint 3',
				new Date(),
				new Date(),
				0,
				6,
				0,
				0,
				100,
				100,
				80,
				80,
				'$400',
				'$400',
				0
			)
		);

		return (
			<InsightList
				data={sprints}
				componentName={this.props.componentName}
				allColumns={this.props.allColumns}
				activeColumns={this.props.activeColumns}
				scrollElement={this.props.scrollElement}
			/>
		);
	}
}

SprintListDummy.propTypes = {
	title: PropTypes.string,
};

export default injectIntl(SprintListDummy);
