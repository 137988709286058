import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {ChartWrapper} from './Chart.styled';
import RevenueCostProfitCharts, {revenueCostProfitChartsQuery} from '../charts/RevenueCostProfitCharts';
import ForecastQueryRenderer from '../../../../../../ForecastQueryRenderer';
import {BUDGET_MONEY_VIEWS, SPECIAL_ROLE} from '../../../../../../constants';
import InlineLoader from '../../../../../../forecast-app/shared/components/inline-loader/inline_loader';
import {getProjectDatesOrDefault} from '../../util/ChartsUtil';

const Chart = ({project, selectedViewType, eyeOptionMap, hasBaseline}) => {
	const isExpenseView = BUDGET_MONEY_VIEWS.EXPENSES === selectedViewType;
	const {
		projectFirstDateYear,
		projectFirstDateMonth,
		projectFirstDateDay,
		projectLastDateYear,
		projectLastDateMonth,
		projectLastDateDay,
	} = getProjectDatesOrDefault(project);

	const startDate = new Date(projectFirstDateYear, projectFirstDateMonth - 1, projectFirstDateDay);
	const endDate = new Date(projectLastDateYear, projectLastDateMonth - 1, projectLastDateDay);

	const monthsDifference =
		(endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
	const isLongProject = monthsDifference >= 18;

	const expenseFilter = {
		field: 'ROLE',
		operator: 'IN',
		value: SPECIAL_ROLE.EXPENSE,
	};

	const searchQuery = {
		filters: [],
	};

	if (isExpenseView) {
		searchQuery.filters.push(expenseFilter);
	}

	return (
		<>
			<ChartWrapper>
				<ForecastQueryRenderer
					query={revenueCostProfitChartsQuery}
					variables={{
						projectId: project.id,
						startYear: projectFirstDateYear,
						startMonth: projectFirstDateMonth,
						startDay: projectFirstDateDay,
						endYear: projectLastDateYear,
						endMonth: projectLastDateMonth,
						endDay: projectLastDateDay,
						searchQuery: searchQuery,
						aggregateLevel: isLongProject ? 'MONTH' : 'DAY',
					}}
					authorizeAccessRoute={'project-budget'}
					customLoader={() => <InlineLoader />}
					render={relayProps => {
						return (
							<RevenueCostProfitCharts
								key={'RevenueCostProfitCharts'}
								{...relayProps}
								eyeOptionMap={eyeOptionMap}
								hasBaseline={hasBaseline}
								selectedViewType={selectedViewType}
							/>
						);
					}}
				/>
			</ChartWrapper>
		</>
	);
};

export default createFragmentContainer(Chart, {
	project: graphql`
		fragment Chart_project on ProjectType {
			id
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
		}
	`,
});
