import {
	GROUP_TYPE,
	CURSOR,
	GROUP_SECTION_WIDTH,
	getTrimmedText,
	SCHEDULE_PEOPLE_HEATMAP_SHADOW_COLOR,
	SCHEDULE_PEOPLE_HEATMAP_SHADOW_BLUR,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	GROUP_SECTION_PADDING_LEFT,
	drawBorder,
	GROUP_SECTION_TEXT_GREY,
	GROUP_SECTION_SPACING_LEVEL_ONE,
	CANVAS_BUTTON_GROUP_SECTION_PADDING_RIGHT,
	GROUP_SECTION_SPACING_LEVEL_TWO,
	GROUP_SECTION_PADDING_LEFT_SMALL,
	GROUP_SECTION_PROJECT_BORDER_WIDTH,
	GROUP_SECTION_MARGIN_LEFT,
	GROUP_SECTION_BACKGROUND_COLOR,
	GROUP_SECTION_SPACING_LEVEL_FOUR,
	GROUP_SECTION_ACTIONS_MENU,
	GROUP_SECTION_TEXT_GREY_DARK,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import {SCHEDULING_ACTION_MENU_TYPE, SCHEDULING_VIEW} from '../../../../constants';
import Util from '../../../../forecast-app/shared/util/util';
import {drawButton, drawCanvasImage, drawExpandedGroupHighlight} from '../../DrawingUtils';
import {getCachedMessage} from '../../../../translations/TranslationCache';
import {hasPermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import EventManager from '../../EventManager';
import {trackEvent} from '../../../../tracking/amplitude/TrackingV2';
import {handleAssignToPerson} from '../../actions/handle_assign_to_person';
import DataManager from '../../DataManager';
import {profilePicSrc} from '../../../../directApi';

class PersonGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PERSON, data);

		this.isProjectScheduling = this.pageComponent.props.schedulingView === SCHEDULING_VIEW.PROJECTS;

		if (!this.isProjectScheduling) {
			this.groupId = data.groupId;
		}
	}

	get groupIds() {
		if (this.isProjectScheduling || this.data.isHeatMapDisabled()) {
			return this.data.groupIds;
		}

		return undefined;
	}

	get renderRowLines() {
		return !this.isProjectScheduling;
	}

	set renderRowLines(renderRowLines) {}

	get preventExpansion() {
		return this.data.isHeatMapDisabled() ? false : this._preventExpansion;
	}

	set preventExpansion(preventExpansion) {
		this._preventExpansion = preventExpansion;
	}

	get expanded() {
		return this.data.isHeatMapDisabled() ? false : this._expanded;
	}

	set expanded(expanded) {
		this._expanded = expanded;
	}

	get itemRowHeight() {
		if (!this.isProjectScheduling && this.data.isHeatMapDisabled()) {
			return 39;
		} else {
			return this._itemRowHeight;
		}
	}

	set itemRowHeight(itemRowHeight) {
		this._itemRowHeight = itemRowHeight;
	}

	get height() {
		if (!this.isProjectScheduling && this.data.isHeatMapDisabled()) {
			// The height of the rows are 4 pixels larger than the item itself (2 on top/bottom)
			// This gives 4 pixels between all item rows (overlapping items), but only two pixel at the top/bottom of the group
			// Add 2 pixels more at the top (items adjusted in adjustItemY) and 13 pixels for the bottom padding.
			return this.itemRowCount * this.itemRowHeight + 2 + 13;
		}

		return this._height;
	}

	set height(height) {
		this._height = height;
	}

	adjustItemY(item, y) {
		let adjustedY = Math.floor(y + (this.itemRowHeight - 1 - item.height) / 2);
		if (!this.isProjectScheduling && this.data.isHeatMapDisabled()) {
			// Add 2 extra pixels at the top.
			adjustedY += 8;
		}
		return adjustedY;
	}

	addGroup(group) {
		// create the groupIds array if it does not exist to make sure we show the first created allocation of a newly added user
		if (!this.data.groupIds) this.data.groupIds = [];
		if (this.data.groupIds.indexOf(group.id) === -1) {
			const newGroupIndex = this.groups.length - 2; //Insert before non project time group and placeholder group
			this.groups.addChildGroup(group, newGroupIndex);
			this.data.groupIds.push(group.id);
			this.data.groupIds.push(`${group.id}-task`);
		}
	}

	onMouseEnter(groupData) {
		const {showExpandedActionMenu, staffingModeActive} = this.pageComponent.state;

		if (!showExpandedActionMenu && !staffingModeActive) {
			const {person, project, projectGroup} = this.data;

			this.pageComponent.setState({
				showCollapsedActionMenu: hasPermission(PERMISSION_TYPE.PROJECT_PERSON_CREATE),
				collapsedActionMenuX: groupData.width - GROUP_SECTION_ACTIONS_MENU - GROUP_SECTION_PADDING_LEFT,
				collapsedActionMenuY: groupData.y + groupData.height / 2 - GROUP_SECTION_ACTIONS_MENU,
				collapsedActionMenuData: {person, project, projectGroup},
				actionMenuType: SCHEDULING_ACTION_MENU_TYPE.PERSON,
				actionMenuOptions: [],
			});
		}
	}

	onMouseLeave() {
		EventManager.hideCollapsedActionMenu(this.pageComponent);
	}

	onButtonClick(event, groupData) {
		const {drawAssignButton} = this.data;

		if (drawAssignButton) {
			const {staffingPlaceholder, data} = this.pageComponent.state;

			const placeholderAllocations = DataManager.getPlaceholderAllocationByPlaceholderId(
				this.pageComponent,
				staffingPlaceholder.id
			);
			const placeholderAllocationIds = placeholderAllocations?.map(alloc => alloc.id) || [];
			const suggestedPersonIds = data.suggestedPersons?.map(personRank => personRank.personId) || [];
			const personId = groupData.group.data.personId;

			trackEvent('Assign To Button', 'Clicked');

			handleAssignToPerson(
				this.pageComponent,
				staffingPlaceholder,
				placeholderAllocationIds,
				suggestedPersonIds,
				personId
			);
		}
	}

	onItemCreate(startDate, endDate) {
		EventManager.onItemCreate(this.pageComponent, this, startDate, endDate);
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height, groupType, expanded} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);
		const {
			id,
			roleName,
			profilePictureId,
			name,
			onPersonImageLoad,
			groups,
			drawAssignButton,
			intl,
			projectGroupColor,
			color,
			isInProjectGroup,
			isInProgram,
		} = data;

		const {isProjectTimeline, schedulingView} = this.pageComponent.props;
		const isCapacityOverviewScheduling = schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;

		const cacheId = `${groupType}-${id}-${!!expanded}`;
		const personAvatarSize = 30;

		if (!this.data.isHeatMapDisabled()) {
			interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);
		}

		drawCanvasImage(canvasContext, groupType, cacheId, x, y, width, this.itemRowHeight);

		const drawGroup = personImage => {
			if (expanded && groups?.length > 0) {
				// draw person row shadow
				canvasContext.shadowColor = SCHEDULE_PEOPLE_HEATMAP_SHADOW_COLOR;
				canvasContext.shadowBlur = SCHEDULE_PEOPLE_HEATMAP_SHADOW_BLUR;
				canvasContext.fillStyle = GROUP_SECTION_BACKGROUND_COLOR;
				const shadowHeight = 10;
				canvasContext.fillRect(x, y + height - shadowHeight, width, shadowHeight);

				// reset shadow properties
				canvasContext.shadowColor = undefined;
				canvasContext.shadowBlur = 0;

				// create layer over shadow
				canvasContext.fillRect(x, y, width, height);
			}

			if (this.isProjectScheduling && !isProjectTimeline) {
				drawExpandedGroupHighlight(canvasContext, x, y, height, width);
			}

			const capacityOverviewOffsetX = isCapacityOverviewScheduling ? GROUP_SECTION_SPACING_LEVEL_ONE : 0;
			let left = this.isProjectScheduling
				? isInProgram
					? GROUP_SECTION_SPACING_LEVEL_FOUR
					: GROUP_SECTION_SPACING_LEVEL_TWO + GROUP_SECTION_PADDING_LEFT
				: x + this.marginX + capacityOverviewOffsetX;
			const center = y + height / 2;

			if (!this.preventExpansion && !this.data.isHeatMapDisabled()) {
				left += GROUP_SECTION_PADDING_LEFT;

				const expandCacheId = expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON;
				drawCanvasImage(
					canvasContext,
					null,
					expandCacheId,
					left,
					center - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
					GROUP_SECTION_EXPAND_ICON_WIDTH,
					GROUP_SECTION_EXPAND_ICON_HEIGHT
				);
			}

			left += GROUP_SECTION_PADDING_LEFT_SMALL;

			// Avatar
			if (personImage) {
				left += personAvatarSize / 2;
				canvasContext.drawImage(personImage, left, center - personAvatarSize / 2, personAvatarSize, personAvatarSize);
				left += personAvatarSize / 2;
			} else {
				left += personAvatarSize;

				//draw hexagon with initials
				canvasContext.beginPath();
				const xOrigin = left,
					yOrigin = center - personAvatarSize / 2;
				canvasContext.moveTo(xOrigin, yOrigin);
				canvasContext.lineTo(xOrigin, yOrigin);
				canvasContext.lineTo(xOrigin + 13, yOrigin + 7, 575);
				canvasContext.lineTo(xOrigin + 13, yOrigin + 22, 425);
				canvasContext.lineTo(xOrigin, yOrigin + 30);
				canvasContext.lineTo(xOrigin, yOrigin + 30);
				canvasContext.lineTo(xOrigin - 13, yOrigin + 22, 425);
				canvasContext.lineTo(xOrigin - 13, yOrigin + 7, 575);
				canvasContext.fillStyle = '#6e0fea';
				canvasContext.fill();

				if (name) {
					canvasContext.font = '700 10px ' + Util.getFontFamily();
					canvasContext.fillStyle = 'white';
					const initials = Util.getInitials(name);
					canvasContext.textAlign = 'center';
					canvasContext.fillText(getTrimmedText(canvasContext, initials.toUpperCase()), xOrigin, yOrigin + 18);
				}

				canvasContext.textAlign = 'start';
			}

			left += personAvatarSize;
			const nameFontSize = 13;
			const nameRoleGap = 2.5;

			// Person name
			canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;
			canvasContext.font = `500 ${nameFontSize}px ` + Util.getFontFamily();
			const personNameY = roleName ? center - nameRoleGap : center + nameFontSize / 3;
			canvasContext.fillText(getTrimmedText(canvasContext, name), left, personNameY);

			// Person role
			if (roleName) {
				const roleFontSize = 12;
				canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY;
				canvasContext.font = `400 ${roleFontSize}px ` + Util.getFontFamily();
				const roleY = center + roleFontSize;
				canvasContext.fillText(getTrimmedText(canvasContext, roleName), left, roleY);
			}

			if (drawAssignButton) {
				const buttonProperties = drawButton(
					canvasContext,
					x + width - CANVAS_BUTTON_GROUP_SECTION_PADDING_RIGHT,
					y + height / 2,
					getCachedMessage(intl, 'common.assign'),
					this.isHoveringButton,
					this.isButtonDisabled()
				);

				this.buttonX = buttonProperties.buttonX;
				this.buttonY = buttonProperties.buttonY;
				this.buttonWidth = buttonProperties.buttonWidth;
				this.buttonHeight = buttonProperties.buttonHeight;
			}

			const borderThickness = 1;
			if (this.isProjectScheduling) {
				if (!isInProjectGroup) {
					// draw project border color
					const projectColoredBorderX = projectGroupColor
						? x + GROUP_SECTION_MARGIN_LEFT - GROUP_SECTION_PROJECT_BORDER_WIDTH
						: x + width - GROUP_SECTION_PROJECT_BORDER_WIDTH;

					drawBorder(
						canvasContext,
						projectColoredBorderX,
						y - 2 - borderThickness * 2,
						height + borderThickness * 2,
						true,
						projectGroupColor || color,
						GROUP_SECTION_PROJECT_BORDER_WIDTH
					);
				}
			} else {
				// draw borderlines (top, right and bottom)
				drawBorder(canvasContext, x, y - borderThickness, width, false);
				drawBorder(canvasContext, x, y + height - borderThickness, width, false);
				drawBorder(canvasContext, x + width - borderThickness, y, height, true);
			}
		};

		const cachedImage = cacheManager.get('personImage', id);

		if (cachedImage && profilePictureId) {
			drawGroup(cachedImage);
		} else {
			if (profilePictureId) {
				const personImage = new Image();
				personImage.crossOrigin = 'use-credentials';
				personImage.onload = () => {
					cacheManager.set('personImage', id, personImage);
					onPersonImageLoad();
				};
				personImage.src = profilePicSrc(profilePictureId);
			}
			drawGroup(null);
		}
	}
}

export default PersonGroup;
