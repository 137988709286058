// Implementation forked from https://github.com/jaketodaro/discrete-interval-tree
export default class Interval {
	constructor(start, end) {
		this.start = start;
		this.end = end;
	}

	contains(start, end) {
		return start >= this.start && end <= this.end;
	}
	overlaps(start, end) {
		return start <= this.end && end >= this.start;
	}
}
