import React, {useEffect, useRef, useState} from 'react';
import Styled from 'styled-components';
import ReactDOM from 'react-dom';
import ToggleItem from './ToggleItem';
import {BarsIcon, BarsPartialIcon} from 'web-components/icons';
import {useIntl} from 'react-intl';
import {hasModule} from '../../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../../constants';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';

const AllocationControls = ({disabled, width, hideSoft, onToggleHideSoft, hideHard, onToggleHideHard, maxHeight = 300}) => {
	const hasBetaChanges = hasFeatureFlag('capacity_planning_beta_2_improvements');

	const [expanded, setExpanded] = useState(false);

	const intl = useIntl();
	const inputRef = useRef();
	const dropdownRef = useRef(null);
	const dropdownContainerRef = useRef(null);

	const active = (hasModule(MODULE_TYPES.SOFT_ALLOCATIONS) && hideSoft) || hideHard;

	const items = [];
	if (onToggleHideSoft && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)) {
		items.push(
			<ToggleItem
				key={'hide_soft'}
				name={intl.formatMessage({
					id: hasBetaChanges
						? 'placeholder.allocation_control.exclude_soft'
						: 'placeholder.allocation_control.hide_soft',
				})}
				description={hasBetaChanges ? intl.formatMessage({id: 'placeholder.allocation_control.hide_soft_desc'}) : null}
				onToggle={checked => onToggleHideSoft(checked)}
				state={hideSoft}
			/>
		);
	}

	if (onToggleHideHard && hasModule(MODULE_TYPES.SOFT_ALLOCATIONS)) {
		items.push(
			<ToggleItem
				key={'hide_hard'}
				name={intl.formatMessage({
					id: hasBetaChanges
						? 'placeholder.allocation_control.exclude_hard'
						: 'placeholder.allocation_control.hide_hard',
				})}
				description={hasBetaChanges ? intl.formatMessage({id: 'placeholder.allocation_control.hide_hard_desc'}) : null}
				onToggle={checked => onToggleHideHard(checked)}
				state={hideHard}
			/>
		);
	}

	const collapseDropdown = () => {
		inputRef.current && inputRef.current.blur();
		setExpanded(false);
	};

	const handleClickOutside = event => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
				collapseDropdown();
			}
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	if (items.length === 0) {
		return null;
	}

	const openDropdown = () => {
		if (!disabled) {
			setExpanded(true);
			inputRef.current && inputRef.current.focus();
		}
	};

	const handleDropdownContainerClick = () => {
		return !expanded && openDropdown();
	};

	const getDropdownContent = (style, width) => {
		return (
			<DropdownContainer ref={dropdownContainerRef} style={style} width={width} maxHeight={maxHeight}>
				{items}
			</DropdownContainer>
		);
	};

	const getDropdown = width => {
		if (!expanded) return null;

		const offset = dropdownRef.current.getBoundingClientRect();
		let style = {};

		if (offset) {
			style = {};
			// Check if there is room to expand right, otherwise expand left
			if (offset.left + width < window.innerWidth) {
				style.left = offset.left;
			} else {
				style.left = offset.right - width;
			}

			style.top = offset.top + offset.height - 1;
		}
		return ReactDOM.createPortal(getDropdownContent(style, width), document.querySelector('#root-portal-container'));
	};

	return (
		<>
			<Container
				disabled={disabled}
				expanded={expanded}
				ref={dropdownRef}
				onClick={handleDropdownContainerClick}
				active={active}
			>
				<TitleContainer>
					<div className={'bars-icon'}>{active ? <BarsPartialIcon color={'#6e0fea'} /> : <BarsIcon />}</div>
					<Title>
						{intl.formatMessage({
							id: hasBetaChanges
								? 'placeholder.allocation_control.exclude_allocations'
								: 'placeholder.allocation_control.allocations',
						})}
					</Title>
				</TitleContainer>
				{getDropdown(width)}
			</Container>
		</>
	);
};

const TitleContainer = Styled.div`
	display: flex;
	width: 158px;
	.bars-icon {
		padding-top: 4px;
    	padding-left: 8px;
	}
`;

const Title = Styled.div`
	flex: 1;
	padding: 6px;
`;

const Container = Styled.div`
	cursor: pointer;
	box-sizing: border-box;
	position: relative;
	font-size: 13px;
	height: 30px;
	background-color: ${props => (props.active ? '#F0E7FE' : '#fff')};
	font-family: ${({theme}) => theme.fontFamily};
	color: ${props => (props.active ? '#6e0fea' : '#535353')};
	border-width: 1px;
	border-style: solid;
	border-color: ${props => {
		if (props.expanded) {
			return '#6e0fea';
		}
		if (props.active) {
			return '#6e0fea';
		} else {
			return '#e6e6e6';
		}
	}};
	width: auto;
	border-radius: ${props => (props.expanded ? '4px 4px 0px 0px' : '4px')};
	&:hover {
		border-color: ${props => !props.expanded && !props.disabled && '#a1a1a1'};
	}
`;

const DropdownContainer = Styled.div`
	overflow: hidden;
	z-index: 1000;
	position: absolute;
	border: 1px solid #6e0fea;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
	box-sizing: border-box;
	background-color: #fff;
	max-height: ${props => `${props.maxHeight}px`};
	width: ${props => props.width && `${props.width}px`};
`;

export default AllocationControls;
