import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../inputs/select_input';

class DropdownFilter extends Component {
	handleSelectFilterChange(value) {
		this.props.onValuesChange(value);
	}

	validateFilterValue() {
		//Remove invalid filter values (saved in localStorage and then deleted or just deleted when socket is working)
		const optionValues = this.props.options.map(option => {
			return option.value;
		});
		return this.props.value
			.filter(filterValue => {
				return optionValues.includes(filterValue.value);
			})
			.map(v => ({...v, label: this.props.options.find(o => o.value === v.value).label}));
	}

	render() {
		return (
			<div className="filter dropdown-filter filter-select">
				<SelectInput
					placeholder={this.props.placeholder}
					options={this.props.options}
					onChange={this.handleSelectFilterChange.bind(this)}
					value={this.validateFilterValue()}
					multi={true}
					label={this.props.label ? this.props.label : ''}
					projectStatusFilter={this.props.projectStatusFilter}
					disableRenderCalculations={this.props.disableRenderCalculations || false}
				/>
			</div>
		);
	}
}

DropdownFilter.propTypes = {
	onValuesChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	label: PropTypes.string.isRequired,
	disableRenderCalculations: PropTypes.bool,
};

export default DropdownFilter;
