import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import Util from '../../../../../forecast-app/shared/util/util';
import {BUDGET_TYPE} from '../../../../../constants';

class BudgetRoleRate extends Component {
	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
		};
	}

	getFormattedNumberWithCurrency(number) {
		const {project, company} = this.props;
		const {formatNumber} = this.props.intl;
		const currencySymbol = Util.GetCurrencySymbol(project.rateCard ? project.rateCard.currency : company.currency);

		return Util.getFormattedCurrencyValue(
			currencySymbol,
			formatNumber(Math.round(number > -0.005 && number < 0 ? 0 : number * 100) / 100, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			}) //compare the value with 0.005 to prevent math.round to return -0
		);
	}

	toggleExpanded() {
		if (!this.props.canViewInternalCostRate) return;

		this.setState({expanded: !this.state.expanded});
	}

	render() {
		const {roleWithRate, project, company, canViewInternalCostRate} = this.props;
		const {originalRate} = roleWithRate;
		const {expanded} = this.state;
		const isFixedPrice = !!project.budget;
		const isFixedPriceV2 = project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2;
		const companyCurrency = company.currency;
		const projectCurrency = project.rateCard ? project.rateCard.currency : null;

		const averageInternalHourlyCost =
			roleWithRate.rolePersons.reduce((total, person) => {
				const currentCostPeriod = Util.getCurrentCostPeriod(person.costPeriods.edges);

				let costPeriodValue = currentCostPeriod ? currentCostPeriod.node.cost : 0;

				if (companyCurrency !== projectCurrency) {
					const exchangeRate = company.exchangeRates.edges
						.map(edge => edge.node)
						.find(exchangeRateEdge => exchangeRateEdge.currency === projectCurrency);
					costPeriodValue = exchangeRate ? costPeriodValue * exchangeRate.rate : costPeriodValue;
				}

				return total + costPeriodValue;
			}, 0) / roleWithRate.rolePersons.length;

		return (
			<>
				<tr
					className={'row-member' + (roleWithRate.rolePersons.length === 0 ? ' no-persons' : '')}
					onClick={this.toggleExpanded.bind(this)}
				>
					<td>
						<div className="inner-td">{`${roleWithRate.name} (${roleWithRate.rolePersons.length})`}</div>
					</td>
					{isFixedPrice && originalRate ? (
						<td>
							<div className="inner-td">{this.getFormattedNumberWithCurrency(roleWithRate.originalRate)}</div>
						</td>
					) : null}
					<td>
						<div className={isFixedPriceV2 ? 'inner-td' : 'inner-td active'}>
							{this.getFormattedNumberWithCurrency(roleWithRate.rate)}
						</div>
					</td>
					{canViewInternalCostRate && (
						<td>
							<div className="inner-td">
								{roleWithRate.rolePersons.length === 0
									? ''
									: this.getFormattedNumberWithCurrency(averageInternalHourlyCost) + ' (Avg.)'}
							</div>
						</td>
					)}
					{canViewInternalCostRate && (
						<td>
							<div className="inner-td">
								{roleWithRate.rolePersons.length === 0 ? null : (
									<div className="controls">
										<div className={'expand-control' + (expanded ? ' expand' : ' collapse')} />
									</div>
								)}
							</div>
						</td>
					)}
				</tr>
				{expanded
					? roleWithRate.rolePersons.map(person => {
							const currentCostPeriod = Util.getCurrentCostPeriod(person.costPeriods.edges);
							let costPeriodValue = currentCostPeriod ? currentCostPeriod.node.cost : 0;

							if (companyCurrency !== projectCurrency) {
								const exchangeRate = company.exchangeRates.edges
									.map(edge => edge.node)
									.find(exchangeRateEdge => exchangeRateEdge.currency === projectCurrency);
								costPeriodValue = exchangeRate ? costPeriodValue * exchangeRate.rate : costPeriodValue;
							}
							return (
								<tr key={person.id} className="row-member team-member">
									<td>
										<div className="inner-td">{person.fullName}</div>
									</td>
									{isFixedPrice && originalRate ? (
										<td>
											<div className="inner-td">
												{this.getFormattedNumberWithCurrency(roleWithRate.originalRate)}
											</div>
										</td>
									) : null}
									<td>
										<div className="inner-td active">
											{this.getFormattedNumberWithCurrency(roleWithRate.rate)}
										</div>
									</td>
									<td>
										<div className="inner-td">{this.getFormattedNumberWithCurrency(costPeriodValue)}</div>
									</td>
									<td>
										<div className="inner-td"></div>
									</td>
								</tr>
							);
					  })
					: null}
			</>
		);
	}
}

export default injectIntl(BudgetRoleRate);
