import React from 'react';
import { FlexRow, Icon, Text, Tooltip } from '@forecast-it/design-system';
import { FormattedMessage } from 'react-intl';
export const ContentWarning = ({ children, showWarning, hasRemaining, }) => {
    let warningId;
    if (showWarning) {
        warningId = hasRemaining ? 'scheduling.utilization.warning.demand' : 'scheduling.utilization.warning.time_entries';
    }
    return showWarning ? (React.createElement(Tooltip, { interactable: false, content: React.createElement(Text, null,
            React.createElement(FormattedMessage, { id: warningId })) },
        React.createElement(FlexRow, { gap: 'xs' },
            React.createElement(Icon, { icon: 'alert', size: 's', color: 'error' }),
            React.createElement(Text, { color: 'error' }, children)))) : (React.createElement(Text, null, children));
};
