import Util from '../../forecast-app/shared/util/util';
import UpdateInitialPlanProjectMutation from '../../mutations/UpdateInitialPlanProjectMutation';

export const changeTaskCostCalculationType = (projectId, taskCostCalculationType) => {
	Util.localStorageDeleteFincancialMap();
	Util.CommitMutation(UpdateInitialPlanProjectMutation, {
		id: projectId,
		taskCostCalculationType,
	});
};

export const changeUnassignedTaskHourlyCost = (projectId, unassignedTaskHourlyCost) => {
	Util.CommitMutation(UpdateInitialPlanProjectMutation, {
		id: projectId,
		unassignedTaskHourlyCost,
	});
};

export const changeBaselineTarget = (projectId, baselineTarget, onSuccess) => {
	Util.CommitMutation(
		UpdateInitialPlanProjectMutation,
		{
			id: projectId,
			baselineTarget,
		},
		onSuccess
	);
};

export const changeBaselineMinutesPerDay = (id, baselineMinutesPerDay, onSuccess) => {
	const baselineMinutesRounded = baselineMinutesPerDay != null ? Math.round(baselineMinutesPerDay) : null;

	Util.CommitMutation(
		UpdateInitialPlanProjectMutation,
		{
			id,
			baselineMinutesPerDay: baselineMinutesRounded,
		},
		onSuccess
	);
};

export const changeBudget = (id, budget) => {
	Util.CommitMutation(UpdateInitialPlanProjectMutation, {
		id,
		budget,
	});
};
