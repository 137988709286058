/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type utilizationMonthlyComponent_viewer$ref = any;
export type utilizationMonthlyComponentRefetchQueryVariables = {|
  shareKey?: ?string,
  year?: ?number,
  selectValue?: ?string,
  componentId?: ?string,
  personIds?: ?$ReadOnlyArray<?string>,
  teamIds?: ?$ReadOnlyArray<?string>,
  roleIds?: ?$ReadOnlyArray<?string>,
  labelIds?: ?$ReadOnlyArray<?string>,
  projectStatusesBackend?: ?$ReadOnlyArray<?string>,
|};
export type utilizationMonthlyComponentRefetchQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: utilizationMonthlyComponent_viewer$ref
  |}
|};
export type utilizationMonthlyComponentRefetchQuery = {|
  variables: utilizationMonthlyComponentRefetchQueryVariables,
  response: utilizationMonthlyComponentRefetchQueryResponse,
|};
*/


/*
query utilizationMonthlyComponentRefetchQuery(
  $shareKey: String
  $year: Int
  $selectValue: String
  $componentId: ID
  $personIds: [ID]
  $teamIds: [ID]
  $roleIds: [ID]
  $labelIds: [ID]
  $projectStatusesBackend: [ID]
) {
  viewer {
    ...utilizationMonthlyComponent_viewer_3hjJhe
    id
  }
}

fragment utilizationMonthlyComponent_viewer_3hjJhe on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    utilizationMonthly(year: $year, selectValue: $selectValue, personIds: $personIds, roleIds: $roleIds, teamIds: $teamIds, componentId: $componentId, labelIds: $labelIds, projectStatusesBackend: $projectStatusesBackend) {
      jan
      feb
      mar
      apr
      may
      jun
      jul
      aug
      sep
      oct
      nov
      dec
      janBillable
      febBillable
      marBillable
      aprBillable
      mayBillable
      junBillable
      julBillable
      augBillable
      sepBillable
      octBillable
      novBillable
      decBillable
      people {
        id
        profilePictureId
        profilePictureDefaultId
        firstName
        lastName
        role
        jan
        feb
        mar
        apr
        may
        jun
        jul
        aug
        sep
        oct
        nov
        dec
        janBillable
        febBillable
        marBillable
        aprBillable
        mayBillable
        junBillable
        julBillable
        augBillable
        sepBillable
        octBillable
        novBillable
        decBillable
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "year",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectValue",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "componentId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roleIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "labelIds",
    "type": "[ID]"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectStatusesBackend",
    "type": "[ID]"
  }
],
v1 = {
  "kind": "Variable",
  "name": "componentId",
  "variableName": "componentId"
},
v2 = {
  "kind": "Variable",
  "name": "labelIds",
  "variableName": "labelIds"
},
v3 = {
  "kind": "Variable",
  "name": "personIds",
  "variableName": "personIds"
},
v4 = {
  "kind": "Variable",
  "name": "projectStatusesBackend",
  "variableName": "projectStatusesBackend"
},
v5 = {
  "kind": "Variable",
  "name": "roleIds",
  "variableName": "roleIds"
},
v6 = {
  "kind": "Variable",
  "name": "selectValue",
  "variableName": "selectValue"
},
v7 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v8 = {
  "kind": "Variable",
  "name": "teamIds",
  "variableName": "teamIds"
},
v9 = {
  "kind": "Variable",
  "name": "year",
  "variableName": "year"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jan",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "feb",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mar",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apr",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "may",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jun",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jul",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aug",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sep",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "oct",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nov",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dec",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "janBillable",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "febBillable",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "marBillable",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aprBillable",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mayBillable",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "junBillable",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "julBillable",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "augBillable",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sepBillable",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "octBillable",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "novBillable",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "decBillable",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "utilizationMonthlyComponentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "utilizationMonthlyComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "utilizationMonthlyComponentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              (v7/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "concreteType": "UtilizationMonthly",
                "kind": "LinkedField",
                "name": "utilizationMonthly",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v28/*: any*/),
                  (v29/*: any*/),
                  (v30/*: any*/),
                  (v31/*: any*/),
                  (v32/*: any*/),
                  (v33/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UtilizationPerson",
                    "kind": "LinkedField",
                    "name": "people",
                    "plural": true,
                    "selections": [
                      (v34/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profilePictureDefaultId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/),
                      (v24/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v29/*: any*/),
                      (v30/*: any*/),
                      (v31/*: any*/),
                      (v32/*: any*/),
                      (v33/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v34/*: any*/)
                ],
                "storageKey": null
              },
              (v34/*: any*/)
            ],
            "storageKey": null
          },
          (v34/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "utilizationMonthlyComponentRefetchQuery",
    "operationKind": "query",
    "text": "query utilizationMonthlyComponentRefetchQuery(\n  $shareKey: String\n  $year: Int\n  $selectValue: String\n  $componentId: ID\n  $personIds: [ID]\n  $teamIds: [ID]\n  $roleIds: [ID]\n  $labelIds: [ID]\n  $projectStatusesBackend: [ID]\n) {\n  viewer {\n    ...utilizationMonthlyComponent_viewer_3hjJhe\n    id\n  }\n}\n\nfragment utilizationMonthlyComponent_viewer_3hjJhe on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    utilizationMonthly(year: $year, selectValue: $selectValue, personIds: $personIds, roleIds: $roleIds, teamIds: $teamIds, componentId: $componentId, labelIds: $labelIds, projectStatusesBackend: $projectStatusesBackend) {\n      jan\n      feb\n      mar\n      apr\n      may\n      jun\n      jul\n      aug\n      sep\n      oct\n      nov\n      dec\n      janBillable\n      febBillable\n      marBillable\n      aprBillable\n      mayBillable\n      junBillable\n      julBillable\n      augBillable\n      sepBillable\n      octBillable\n      novBillable\n      decBillable\n      people {\n        id\n        profilePictureId\n        profilePictureDefaultId\n        firstName\n        lastName\n        role\n        jan\n        feb\n        mar\n        apr\n        may\n        jun\n        jul\n        aug\n        sep\n        oct\n        nov\n        dec\n        janBillable\n        febBillable\n        marBillable\n        aprBillable\n        mayBillable\n        junBillable\n        julBillable\n        augBillable\n        sepBillable\n        octBillable\n        novBillable\n        decBillable\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd224dc3c291e25cc02d17e78d2c15741';

module.exports = node;
