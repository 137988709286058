import React from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';
export const SprintDropdown = ({
	sprints,
	dropdownAlignment,
	width,
	name,
	selectedItems,
	optionsName,
	onSelect,
	onRemove,
	selectedGroupName,
	isProjectGroup,
	userpilot,
	emphasizeEmptyState,
	headerLines,
}) => {
	const sortedSprints = [...sprints]
		.filter(sprint => (isProjectGroup ? sprint.node.isProjectGroupSprint : true))
		.sort((a, b) => {
			if (a.node.id === null) return -1;
			if (b.node.id === null) return 1;
			const aDate = Util.CreateNonUtcMomentDate(a.node.endYear, a.node.endMonth, a.node.endDay);
			const bDate = Util.CreateNonUtcMomentDate(b.node.endYear, b.node.endMonth, b.node.endDay);
			if (aDate.isAfter(bDate)) return -1;
			if (bDate.isAfter(aDate)) return 1;
			return 0;
		});
	return (
		<Dropdown
			isNested
			isMultiSelect
			headerLines={headerLines}
			selectedGroupName={selectedGroupName}
			selectedItems={selectedItems}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
		>
			<Dropdown.Group name={optionsName} key={'dropdown-options'}>
				{sortedSprints.map(edge => {
					const {id, name, projectGroupSprintId} = edge.node;
					let workingSprintId = isProjectGroup ? projectGroupSprintId : id;
					return (
						<Dropdown.SingleText key={workingSprintId} value={workingSprintId} searchString={name}>
							{name}
						</Dropdown.SingleText>
					);
				})}
			</Dropdown.Group>
		</Dropdown>
	);
};

SprintDropdown.propTypes = {
	sprints: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				name: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
				endYear: PropTypes.number.isRequired,
				endMonth: PropTypes.number.isRequired,
				endDay: PropTypes.number.isRequired,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	headerLines: PropTypes.element,
};

SprintDropdown.defaultProps = {
	optionsName: 'Sprints',
	name: 'Sprints',
	selectedGroupName: 'Selected',
	onSelect: () => false,
	onRemove: () => false,
};

export default createFragmentContainer(SprintDropdown, {
	sprints: graphql`
		fragment SprintDropdown_sprints on SprintTypeEdge @relay(plural: true) {
			node {
				name
				id
				projectGroupSprintId
				isProjectGroupSprint
				endYear
				endMonth
				endDay
			}
		}
	`,
});
