import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Line} from 'react-chartjs-2';
import {defaultTimeDisplayFormats} from './constants';
import {Chart as ChartJS, TimeScale} from 'chart.js';

import 'chartjs-adapter-moment';

ChartJS.register(TimeScale);

class ChartJsLineBurndown extends Component {
	constructor(props) {
		super(props);
		this.onResize = this.onResize.bind(this);
	}

	componentDidMount() {
		this.onResize();
		window.addEventListener('resize', this.onResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	onResize() {
		if (this.line) {
			const chart = this.line;
			const xScale = chart.scales.x;
			chart.options.scales.x.ticks.minor.labelOffset = (xScale.getPixelForTick(1) - xScale.getPixelForTick(0)) / 2;
			chart.update();
		}
	}

	render() {
		const tooltipSuffix = this.props.tooltipSuffix ? this.props.tooltipSuffix : '';
		let options = {
			plugins: {
				title: {
					display: false,
				},
				tooltip: {
					mode: 'nearest',
					intersect: false,
					external: ctx => {
						if (!ctx?.tooltip) return;
						// disable displaying the color box;
						ctx.tooltip.options.displayColors = false;
					},
					callbacks: {
						label: ctx => {
							const tooltip = [];
							tooltip.push(ctx.dataset.label + ': ' + +parseFloat(ctx.parsed.y).toFixed(2) + tooltipSuffix);
							const changes = ctx.raw.changes;
							if (changes) {
								tooltip.push(...changes);
							}

							return tooltip;
						},
					},
				},
				datalabels: {
					display: false,
				},
			},
			responsive: true,
			maintainAspectRatio: false,
			legend: {
				position: 'bottom',
			},
			scales: {
				y: {
					titleIcontext: {
						display: this.props.yAxesTitle !== null && this.props.yAxesTitle !== undefined,
						labelString: this.props.yAxesTitle,
					},
					ticks: {
						min: 0,
					},
				},
			},
		};
		options.scales.x = {
			type: 'time',
			time: {
				unit: 'day',
				displayFormats: defaultTimeDisplayFormats,
				min: this.props.min !== null ? this.props.min : undefined,
				max: this.props.max !== null ? this.props.max : undefined,
			},
			ticks: {
				callback: function (dataLabel, index, values) {
					// Hide the last label
					return index < values.length - 1 ? dataLabel : '';
				},
			},
		};
		return (
			<div style={{height: this.props.height + 'px'}}>
				<Line ref={r => (this.line = r)} data={this.props.data} options={options} />
			</div>
		);
	}
}
export default injectIntl(ChartJsLineBurndown);
