import {
	createOrUpdateAllocation,
	createOrUpdateMultiplePlaceholderAllocations,
	createOrUpdatePlaceholder,
	createOrUpdatePlaceholderAllocation,
	createProject,
	createProjectPerson,
	deleteAllocation,
	deletePlaceholder,
	deletePlaceholderAllocation,
} from './CanvasCapacityOverviewSuccessLogic';
import {isEventFromScheduling} from '../utils';

export const onSavedFiltersUpdate = (pageComponent, response) => {
	const stateData = pageComponent.getData();
	if (response.createFilter) {
		stateData.capacityOverviewFilters.push(response.createFilter.filter.node);
	} else if (response.deleteFilter) {
		stateData.capacityOverviewFilters = stateData.capacityOverviewFilters.filter(
			filter => filter.id !== response.deleteFilter.deletedFilterId
		);
	}
	pageComponent.setState({data: stateData});
};

export const handleCapacityOverviewSchedulingMutation = (pageComponent, response, args) => {
	if (!isEventFromScheduling(args)) {
		return;
	}
	if (response.splitPlaceholderAllocation || response.bulkPlaceholderAllocations) {
		const placeholderAllocations =
			response.splitPlaceholderAllocation?.placeholderAllocations ||
			response.bulkPlaceholderAllocations.placeholderAllocations;
		createOrUpdateMultiplePlaceholderAllocations(pageComponent, placeholderAllocations);
	} else if (
		response.createPlaceholderAllocation ||
		response.updatePlaceholderAllocation ||
		response.duplicatePlaceholderAllocation
	) {
		const operation =
			response.createPlaceholderAllocation ||
			response.updatePlaceholderAllocation ||
			response.duplicatePlaceholderAllocation;
		const placeholderAllocation = operation.placeholderAllocation.node || operation.placeholderAllocation;
		createOrUpdatePlaceholderAllocation(pageComponent, placeholderAllocation);
	} else if (response.deletePlaceholderAllocation) {
		deletePlaceholderAllocation(pageComponent, response.deletePlaceholderAllocation.deletedAllocationId);
	} else if (response.createPlaceholder || response.updatePlaceholder || response.duplicatePlaceholder) {
		const operation = response.updatePlaceholder || response.createPlaceholder || response.duplicatePlaceholder;
		const responsePlaceholder = operation.placeholder.node;
		createOrUpdatePlaceholder(pageComponent, responsePlaceholder);
	} else if (response.deletePlaceholder) {
		deletePlaceholder(pageComponent, response.deletePlaceholder.deletedPlaceholderId);
	} else if (response.replacePlaceholder) {
		const placeholderId = response.replacePlaceholder.placeholderId;
		const shouldDeletePlaceholder = response.replacePlaceholder.deletePlaceholder;
		const deletedPlaceholderAllocationIds = response.replacePlaceholder.deletedPlaceholderAllocationIds;
		const allocations = response.replacePlaceholder.allocations?.map(edge => edge.node);

		if (allocations) {
			for (const allocation of allocations) {
				createOrUpdateAllocation(pageComponent, allocation);
			}
		}

		if (shouldDeletePlaceholder) {
			deletePlaceholder(pageComponent, placeholderId);
		} else {
			deletedPlaceholderAllocationIds.forEach(deletedPlaceholderAllocationId =>
				deletePlaceholderAllocation(pageComponent, deletedPlaceholderAllocationId)
			);
		}
	} else if (response.assignPlaceholderAllocationsToPerson) {
		const {
			allocations,
			placeholderWasDeleted,
			placeholderId,
			deletedPlaceholderAllocationIds: deletedPlaceholderAllocationIds1,
		} = response.assignPlaceholderAllocationsToPerson;
		const deletedPlaceholderAllocationIds = deletedPlaceholderAllocationIds1;
		const createdProjectAllocations = allocations?.map(edge => edge.node);
		for (const allocation of createdProjectAllocations) {
			createOrUpdateAllocation(pageComponent, allocation);
		}
		deletedPlaceholderAllocationIds.forEach(deletedPlaceholderAllocationId =>
			deletePlaceholderAllocation(pageComponent, deletedPlaceholderAllocationId)
		);
		if (placeholderWasDeleted) {
			deletePlaceholder(pageComponent, placeholderId);
		}
	} else if (response.transferPlaceholder) {
		const transfer = response.transferPlaceholder;

		if (transfer.personAllocation) {
			createOrUpdateAllocation(pageComponent, transfer.personAllocation);
		}

		if (transfer.placeholderAllocation) {
			createOrUpdatePlaceholderAllocation(pageComponent, transfer.placeholderAllocation);
		} else if (transfer.deletedPlaceholderAllocationId) {
			deletePlaceholderAllocation(pageComponent, transfer.deletedPlaceholderAllocationId);
		}
	} else if (response.createAllocation || response.updateAllocation) {
		const operation = response.createAllocation || response.updateAllocation;
		const allocation = operation.allocation.node || operation.allocation;
		createOrUpdateAllocation(pageComponent, allocation);
	} else if (response.deleteAllocation) {
		deleteAllocation(pageComponent, response.deleteAllocation.deletedAllocationId);
	} else if (response.createProjectPerson) {
		const projectId = response.createProjectPerson.project?.id;
		const projectGroupId = response.createProjectPerson.projectGroup?.id;

		if (response.createProjectPerson.projectPersons) {
			for (const projectPerson of response.createProjectPerson.projectPersons) {
				createProjectPerson(pageComponent, projectId, projectGroupId, projectPerson);
			}
		}
	} else if (response.createMultipleAllocations) {
		const project = response.createMultipleAllocations.project || response.createMultipleAllocations.projectGroup;

		if (project?.allocations?.edges) {
			for (const allocationEdge of project.allocations.edges) {
				createOrUpdateAllocation(pageComponent, allocationEdge.node);
			}
		}
	} else if (response.createProject || response.duplicateProject) {
		const project = response.duplicateProject?.project?.node || response.createProject.project.node;
		createProject(pageComponent, project);
	}

	pageComponent.redrawCanvasTimeline({preventFiltering: false});

	requestAnimationFrame(() => {
		pageComponent.redrawCanvasTimeline({preventFiltering: false});
	});
};

export const onSchedulingMutationSuccess = (pageComponent, response, args) => {
	if (!isEventFromScheduling(args)) {
		return;
	}

	const data = pageComponent.getData();
	if (!data) {
		pageComponent.mutationResponse = response;
		pageComponent.setState({savingMutation: true});
		return;
	}

	handleCapacityOverviewSchedulingMutation(pageComponent, response, args);
};
