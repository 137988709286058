import React, {useEffect, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {getQueryRenderer} from './reported_time_page_util';
import EmptyState from '../../../forecast-app/shared/components/empty-states/empty_state';
import {fetchData} from './reported_time_financials_fetch';
import Util from '../../../forecast-app/shared/util/util';
import {CalculationLoader} from 'web-components';
import {hasSomePermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';

const reportedTimeTable = ({enabledColumns, groupings, searchQuery, viewer, intl, customFieldDefinitions}) => {
	const wholeTableReportedTime = viewer.company.totalMinutesRegistered;
	const currency = viewer.company.currency;
	const [wholeTableTotalCost, setWholeTableTotalCost] = useState(null);
	const [wholeTableTotalPrice, setWholeTableTotalPrice] = useState(null);
	const sortValue = null;

	//get the whole table total financials
	useEffect(() => {
		if (
			hasSomePermission([PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION, PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE])
		) {
			fetchData(searchQuery, 'total_time_regs_financials').then(data => {
				setWholeTableTotalPrice(data.totalTimeRegistrationsPrice);
				setWholeTableTotalCost(data.totalTimeRegistrationsCost);
			});
		}
	}, [searchQuery]);

	const getEmptyState = () => {
		if (searchQuery.filters.length > 3) return <EmptyState pageName={EmptyState.EMPTY_STATE.NO_RESULT_FOUND} />;
		else return <EmptyState pageName={EmptyState.EMPTY_STATE.REPORTED_TIME_TABLE} />;
	};

	const formattedPrice = wholeTableTotalPrice && intl.formatNumber(wholeTableTotalPrice, {format: 'always_two_decimal'});
	const formattedCost = wholeTableTotalCost && intl.formatNumber(wholeTableTotalCost, {format: 'always_two_decimal'});

	const tableHeader = {
		customFieldDefinitions,
		wholeTableReportedTime: Util.convertMinutesToFullHour(wholeTableReportedTime, intl),
		wholeTableTotalCost:
			formattedCost !== null ? Util.GetFormattedCurrencySymbol(currency, formattedCost) : <CalculationLoader />,
		wholeTableTotalPrice:
			formattedPrice !== null ? Util.GetFormattedCurrencySymbol(currency, formattedPrice) : <CalculationLoader />,
	};

	//the nextLevelProps are just an empty object because the first TableRows in the table component do not have nextLevelProps
	return wholeTableReportedTime === 0
		? getEmptyState()
		: getQueryRenderer(tableHeader, groupings, searchQuery, 0, enabledColumns, null, {}, sortValue);
};

const reportedTimeTableQuery = graphql`
	query reportedTimeTable_Query($searchQuery: TaskSearchQueryType) {
		viewer {
			actualPersonId
			component(name: "reported_time_table")
			...reportedTimeTable_viewer @arguments(searchQuery: $searchQuery)
		}
	}
`;
export {reportedTimeTableQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(reportedTimeTable, {
			viewer: graphql`
				fragment reportedTimeTable_viewer on Viewer @argumentDefinitions(searchQuery: {type: "TaskSearchQueryType"}) {
					id
					company {
						totalMinutesRegistered(searchQuery: $searchQuery)
						currency
					}
				}
			`,
		})
	)
);
