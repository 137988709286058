import React from 'react';
import {getQueryRenderer} from './TaskReportUtil';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import EmptyState from '../../../forecast-app/shared/components/empty-states/empty_state';

const TaskReportTable = ({enabledColumns, groupings, searchQuery, viewer, customFieldDefinitions}) => {
	const sortValue = null;

	const taskTotals = viewer.company.taskTotals;
	const currency = viewer.company.currency;

	const tableHeader = {
		taskTotals,
		currency,
		customFieldDefinitions,
	};

	/**
	 * Returns the empty state.
	 *
	 * if any filters are applied and no task are found,
	 * return that no result is found. If no filters
	 * are used, state that there aren't any tasks.
	 *
	 * @returns EmptyState element
	 */
	const getEmptyState = () => {
		if (searchQuery.filters.length > 0) {
			return <EmptyState pageName={EmptyState.EMPTY_STATE.NO_RESULT_FOUND} />;
		} else {
			return <EmptyState pageName={EmptyState.EMPTY_STATE.TASK_REPORT} />;
		}
	};

	// If no tasks are found - use empty state
	if (taskTotals.numberOfTasks === 0) {
		return getEmptyState();
	} else {
		return getQueryRenderer(tableHeader, groupings, searchQuery, 0, enabledColumns, null, {}, sortValue);
	}
};

const TaskReportTableQuery = graphql`
	query TaskReportTable_Query($searchQuery: TaskSearchQueryType) {
		viewer {
			actualPersonId
			component(name: "task_report_table")
			...TaskReportTable_viewer @arguments(searchQuery: $searchQuery)
		}
	}
`;
export {TaskReportTableQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(TaskReportTable, {
			viewer: graphql`
				fragment TaskReportTable_viewer on Viewer @argumentDefinitions(searchQuery: {type: "TaskSearchQueryType"}) {
					id
					company {
						currency
						taskTotals(searchQuery: $searchQuery) {
							registeredMinutes
							estimatePoints
							estimateMinutes
							remainingPoints
							remainingMinutes
							plannedRevenue
							actualRevenue
							remainingRevenue
							forecastRevenue
							recognitionLockedRevenue
							recognitionOpenRevenue
							recognitionForecastRevenue
							totalActualRevenueRecognition
							totalForecastRevenueToComplete
							surplus
							numberOfTasks
						}
					}
				}
			`,
		})
	)
);
