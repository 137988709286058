import {createRefetchContainer, graphql} from 'react-relay';

const ProgramLoader = ({viewer, children, ...props}) => {
	const formatData = () => {
		return viewer.company.financialBasedPrograms.edges.map(program => {
			const {projectsFinancialsTotals} = program.node;

			return {
				program: {
					...program.node,
				},
				currency: viewer.company.currency,
				financialNumbers: {
					...projectsFinancialsTotals,
				},
			};
		});
	};

	return children({data: {rows: formatData()}, ...props});
};

export const programLoaderQuery = graphql`
	query ProgramLoader_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$searchQuery: TaskSearchQueryType!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_period_program_loader")
			...ProgramLoader_viewer
				@arguments(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createRefetchContainer(ProgramLoader, {
	viewer: graphql`
		fragment ProgramLoader_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
		) {
			id
			company {
				id
				currency
				financialBasedPrograms(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				) {
					edges {
						node {
							id
							name
							budgetType
							color
							projectsFinancialsTotals {
								...ProjectPortfolioPeriodRow_financialNumbers
								unallocatedRevenue
							}
						}
					}
				}
			}
		}
	`,
});
