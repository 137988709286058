import React, {Component} from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import util from '../../../forecast-app/shared/util/util';
import InsightsUtil from '../insights_util';
import Moment from 'moment';
import Util from '../../../forecast-app/shared/util/util';

class reportedTimeComponent extends Component {
	constructor(props) {
		super(props);

		const hasRevenueWithoutCostAccess = Util.hasRevenueWithoutCostAccess();

		this.state = {
			sortBy: {column: null, ascending: true},
			hasRevenueWithoutCostAccess,
		};

		const isShowingIdleTimeData =
			props.componentName === 'reportedTimePortfolio' || props.componentName === 'reportedTimePeople';
		this.projectColumnName = isShowingIdleTimeData ? 'projectOrIdleTime' : 'projectName';
		this.roleColumnName = isShowingIdleTimeData ? 'roleOrIdleTime' : 'role';
		this.taskColumnName = isShowingIdleTimeData ? 'cardOrIdleTime' : 'cardName';
		this.phaseColumnName = 'phase';
	}

	UNSAFE_componentWillMount() {
		if (this.props.viewer.insightComponentsData.reportedTimeList.timeRegistrations == null) return;

		// Calculate height based on amount of elements in nextProps
		const listHeight = InsightsUtil.GetlistHeight(
			this.props.viewer.insightComponentsData.reportedTimeList.timeRegistrations.length
		);
		if (this.props.height !== listHeight) {
			this.props.updateComponentHeight(this.props.id, listHeight);
		}
		this.setData(this.props);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const projectIds = this.props.projectFilterValue.length !== 0 ? this.props.projectFilterValue.map(el => el.value) : [];
		const isProjectGroupType =
			(this.props.projectId === null || this.props.projectId === undefined) &&
			this.props.projectGroupId !== null &&
			this.props.projectGroupId !== undefined;
		const projectId = !isProjectGroupType ? this.props.projectId : this.props.projectGroupId;
		if (
			this.props.clientId !== nextProps.clientId ||
			this.props.projectGroupId !== nextProps.projectGroupId ||
			this.props.projectId !== nextProps.projectId ||
			this.props.dateCriteria !== nextProps.dateCriteria
		) {
			this.props.relay.refetch({
				clientId: nextProps.clientId,
				dateCriteria: nextProps.dateCriteria,
				groupBy: this.props.groupBy || null,
				projectIds: projectIds,
				billableFilterValue: nextProps.billableFilterValue,
				projectId: projectId,
				isProjectGroupType: isProjectGroupType,
			});
		}

		if (nextProps.viewer.insightComponentsData.reportedTimeList.timeRegistrations == null) return;

		// Calculate height based on amount of elements in nextProps
		const listHeight = InsightsUtil.GetlistHeight(
			nextProps.viewer.insightComponentsData.reportedTimeList.timeRegistrations.length
		);
		if (this.props.height !== listHeight) {
			this.props.updateComponentHeight(this.props.id, listHeight);
		}
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	setData(props) {
		const {formatMessage} = this.props.intl;
		this.prevDataLength = this.data ? this.data.length : 0;
		const currencySymbol = util.GetCurrencySymbol(props.viewer.insightComponentsData.reportedTimeList.currency);
		const isDollarOrEuro = util.CurrencyIsPrefixed(currencySymbol);
		const reportedSum = props.viewer.insightComponentsData.reportedTimeList.timeRegistrations.reduce(
			(total, reg) => total + reg.minutesRegistered,
			0
		);
		const priceSum = props.viewer.insightComponentsData.reportedTimeList.timeRegistrations
			.filter(reg => reg.billable)
			.reduce((total, reg) => total + reg.price, 0);
		const costSum = props.viewer.insightComponentsData.reportedTimeList.timeRegistrations.reduce(
			(total, reg) => total + reg.cost,
			0
		);
		this.totals = {
			reported: Util.convertMinutesToFullHour(reportedSum, this.props.intl),
			price: util.getFormattedNumberWithCurrency(currencySymbol, Math.round(priceSum * 100.0) / 100.0, this.props.intl),
			cost: util.getFormattedNumberWithCurrency(currencySymbol, Math.round(costSum * 100.0) / 100.0, this.props.intl),
		};
		this.data = [];
		props.viewer.insightComponentsData.reportedTimeList.timeRegistrations.forEach(node => {
			const timeReg = {};
			if (props.groupBy === 'project') {
				timeReg[this.projectColumnName] = node.groupingElementName;
			} else {
				timeReg[this.projectColumnName] = node.projectName;
			}
			if (props.groupBy === 'card') {
				timeReg[this.taskColumnName] = node.groupingElementName;
			}
			if (props.groupBy === 'role') {
				timeReg[this.roleColumnName] = node.groupingElementName;
			}
			if (props.groupBy === 'phase') {
				timeReg[this.phaseColumnName] =
					node.groupingElementName === 'no_phase_element_name'
						? formatMessage({id: 'project_scopes.no-scope'})
						: node.groupingElementName;
			}
			timeReg[this.roleColumnName] = node.roleNameOrNonProjTime;
			timeReg[this.taskColumnName] = node.taskNameOrNonProjTime;

			timeReg.person = node.personName;
			timeReg.department = node.departmentName;
			timeReg.notes = node.notes ? node.notes : null;
			timeReg.reported = Util.convertMinutesToFullHour(node.minutesRegistered, this.props.intl);
			timeReg.reported_raw = node.minutesRegistered / 60.0;
			timeReg.price = !node.billable
				? 0
				: isDollarOrEuro
				? currencySymbol + this.props.intl.formatNumber(Math.round(node.price * 100.0) / 100.0)
				: this.props.intl.formatNumber(Math.round(node.price * 100.0) / 100.0) + ' ' + currencySymbol;
			timeReg.price_raw = !node.billable ? 0 : node.price;
			timeReg.cost = isDollarOrEuro
				? currencySymbol + this.props.intl.formatNumber(Math.round(node.cost * 100.0) / 100.0)
				: this.props.intl.formatNumber(Math.round(node.cost * 100.0) / 100.0) + ' ' + currencySymbol;
			timeReg.cost_raw = node.cost;
			timeReg.billable = node.billable ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'});
			//Don't want utc date here because we care about what day the time was registered on, regardless of timezone
			timeReg.date = this.props.intl.formatDate(Moment({y: node.dateYear, M: node.dateMonth - 1, d: node.dateDay}));
			timeReg.dateDay = node.dateDay;
			timeReg.dateMonth = node.dateMonth;
			timeReg.dateYear = node.dateYear;
			timeReg.client = node.clientName;
			this.data.push(timeReg);
		});

		if (props.setExportData) {
			const configObject = this.props.config ? JSON.parse(this.props.config) : {};
			const activeColumns = [];
			props.columns.forEach(col => {
				if (configObject.columns.indexOf(col) > -1) {
					if (col === 'reported') {
						activeColumns.push(col + '_raw');
					} else if (
						(col === 'cost' || col === 'price') &&
						this.props.canViewFinancial &&
						!this.state.hasRevenueWithoutCostAccess
					) {
						activeColumns.push(col + '_raw');
					} else if (
						col === 'person' ||
						col === 'client' ||
						col === this.roleColumnName ||
						(col === this.taskColumnName && (!props.groupBy || props.groupBy === 'card')) ||
						(col === this.projectColumnName &&
							(!props.groupBy ||
								props.groupBy === 'card' ||
								props.groupBy === 'project' ||
								props.groupBy === 'phase'))
					) {
						activeColumns.push(col);
					} else if (col === 'date' && !props.groupBy) {
						activeColumns.push('date');
					} else if (col === 'notes' && !props.groupBy) {
						activeColumns.push('notes');
					} else if (col === 'billable' && !props.reportedTimeGroupingMethod) {
						activeColumns.push('billable');
					} else if (col === 'department') {
						activeColumns.push('department');
					}
				}
			});
			if (props.groupBy === 'phase') {
				activeColumns.splice(1, 0, this.phaseColumnName);
			}
			props.setExportColumns(activeColumns);
			props.setExportData(this.data);
		}
		// this.props.recalculateListsPosition();
	}

	sort(sortBy) {
		if (sortBy.column) {
			this.data = this.data.sort((a, b) => {
				let column = sortBy.column;
				switch (column) {
					case 'reported':
					case 'price':
					case 'cost':
						column += '_raw';
						break;
					default:
						break;
				}
				let returnValue = 0;
				if (column === 'date') {
					//cannot compare date as a string so need this
					const aDate = util.CreateNonUtcMomentDate(a.dateYear, a.dateMonth, a.dateDay);
					const bDate = util.CreateNonUtcMomentDate(b.dateYear, b.dateMonth, b.dateDay);
					if (aDate.isBefore(bDate)) returnValue = 1;
					if (bDate.isBefore(aDate)) returnValue = -1;
				} else if (typeof a[column] === 'string') {
					const bValue = b[column] || '';
					//date and person/card/project/role names are strings, other values are numbers and date is accounted for above
					if (a[column].toLowerCase() < bValue.toLowerCase()) returnValue = 1;
					if (a[column].toLowerCase() > bValue.toLowerCase()) returnValue = -1;
				} else if (column === 'department') {
					const aVal = a[column] || '';
					const bVal = b[column] || '';
					returnValue = aVal.toLowerCase() < bVal.toLowerCase() ? 1 : -1;
				} else {
					//sort by number value
					if (a[column] < b[column]) returnValue = 1;
					if (a[column] > b[column]) returnValue = -1;
				}

				return sortBy.ascending ? returnValue * -1 : returnValue;
			});
		}
	}

	render() {
		if (this.props.viewer.insightComponentsData.reportedTimeList.timeRegistrations == null)
			return <div>This report has been removed. Please use the new reported time report.</div>;

		this.setData(this.props);
		this.sort(this.state.sortBy);
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};
		const allColumns = [...this.props.columns];
		//Remove grouping columns which should not be shown based on the selected grouping type
		if (configObject.columns) {
			const configColumns = configObject.columns;
			const removeIfPresent = columnArray => {
				for (const column of columnArray) {
					const indexToRemove = configColumns.indexOf(column);
					if (indexToRemove > -1) {
						configColumns.splice(indexToRemove, 1);
					}
				}
			};

			if (this.props.groupBy === 'project') {
				removeIfPresent([this.taskColumnName]);
			} else if (this.props.groupBy === 'role') {
				removeIfPresent([this.projectColumnName, this.taskColumnName]);
			} else if (this.props.groupBy === 'person') {
				removeIfPresent([this.projectColumnName, this.taskColumnName, this.roleColumnName]);
			}

			//Hide date, notes and billable columns if grouping is selected
			if (this.props.groupBy) {
				removeIfPresent(['date', 'notes']);
			}
			if (!this.props.canViewFinancial || this.state.hasRevenueWithoutCostAccess) {
				removeIfPresent(['cost', 'price']);
			}
			if (this.props.groupBy === 'phase') {
				removeIfPresent([this.taskColumnName]);
				const projectColName =
					this.props.componentName === 'reportedTimeProject' || this.props.componentName === 'reportedTimeBusiness'
						? 'projectName'
						: 'projectOrIdleTime';
				const isProjectCol = el => el === projectColName;
				const indexAfterProjectCol = configColumns.filter(el => el !== 'date').findIndex(isProjectCol);
				const indexAfterProjectColAllColumn = allColumns.findIndex(isProjectCol);
				//its -1 if no element in array matches find criteria
				configObject.columns.splice(
					indexAfterProjectCol === -1 ? 0 : indexAfterProjectCol + 1,
					0,
					this.phaseColumnName
				);
				allColumns.splice(
					indexAfterProjectColAllColumn === -1 ? 0 : indexAfterProjectColAllColumn + 1,
					0,
					this.phaseColumnName
				);
			}
		}

		return (
			<InsightList
				data={this.data}
				componentId={this.props.id}
				componentName={'reportedTime'}
				activeColumns={configObject ? configObject.columns : []}
				allColumns={allColumns}
				sortBy={this.state.sortBy}
				setSortBy={this.setSortBy.bind(this)}
				disableSort="notes"
				totals={this.totals}
				scrollElement={this.props.scrollElement}
				scrollElementFullScreen={this.props.scrollElementFullScreen}
				shouldListsRecalculatePosition={this.props.shouldListsRecalculatePosition}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

const reportedTimeComponentQuery = graphql`
	query reportedTimeComponent_Query(
		$shareKey: String
		$clientId: ID
		$personId: ID
		$projectId: ID
		$isProjectGroupType: Boolean
		$dateCriteria: String
		$groupBy: String
		$projectIds: [ID]
		$billableFilterValue: Boolean
		$personIds: [ID]
		$departmentIds: [ID]
		$teamIds: [ID]
		$roleIds: [ID]
		$labelIds: [ID]
		$skillIds: [ID]
		$componentId: ID
	) {
		viewer {
			actualPersonId
			component(name: "insight_reported_time")
			...reportedTimeComponent_viewer
				@arguments(
					shareKey: $shareKey
					projectId: $projectId
					clientId: $clientId
					personId: $personId
					isProjectGroupType: $isProjectGroupType
					dateCriteria: $dateCriteria
					groupBy: $groupBy
					projectIds: $projectIds
					billableFilterValue: $billableFilterValue
					personIds: $personIds
					departmentIds: $departmentIds
					teamIds: $teamIds
					roleIds: $roleIds
					labelIds: $labelIds
					skillIds: $skillIds
					componentId: $componentId
				)
		}
	}
`;

export {reportedTimeComponentQuery};

export default injectIntl(
	createRefetchContainer(
		reportedTimeComponent,
		{
			viewer: graphql`
				fragment reportedTimeComponent_viewer on Viewer
				@argumentDefinitions(
					shareKey: {type: "String"}
					clientId: {type: "ID"}
					personId: {type: "ID"}
					projectId: {type: "ID"}
					isProjectGroupType: {type: "Boolean"}
					dateCriteria: {type: "String"}
					groupBy: {type: "String"}
					projectIds: {type: "[ID]"}
					billableFilterValue: {type: "Boolean"}
					personIds: {type: "[ID]"}
					departmentIds: {type: "[ID]"}
					teamIds: {type: "[ID]"}
					roleIds: {type: "[ID]"}
					labelIds: {type: "[ID]"}
					skillIds: {type: "[ID]"}
					componentId: {type: "ID"}
				) {
					insightComponentsData(shareKey: $shareKey) {
						reportedTimeList(
							projectId: $projectId
							isProjectGroupType: $isProjectGroupType
							clientId: $clientId
							personId: $personId
							dateCriteria: $dateCriteria
							groupBy: $groupBy
							projectIds: $projectIds
							billableFilterValue: $billableFilterValue
							personIds: $personIds
							departmentIds: $departmentIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
							skillIds: $skillIds
							componentId: $componentId
						) {
							id
							currency
							timeRegistrations {
								id
								personName
								departmentName
								projectName
								roleNameOrNonProjTime
								taskNameOrNonProjTime
								groupingElementName
								minutesRegistered
								dateYear
								dateMonth
								dateDay
								price
								cost
								billable
								notes
								clientName
							}
						}
					}
				}
			`,
		},
		graphql`
			query reportedTimeComponentRefetchQuery(
				$shareKey: String
				$clientId: ID
				$personId: ID
				$projectId: ID
				$isProjectGroupType: Boolean
				$dateCriteria: String
				$groupBy: String
				$projectIds: [ID]
				$billableFilterValue: Boolean
				$personIds: [ID]
				$departmentIds: [ID]
				$teamIds: [ID]
				$roleIds: [ID]
				$labelIds: [ID]
				$skillIds: [ID]
				$componentId: ID
			) {
				viewer {
					...reportedTimeComponent_viewer
						@arguments(
							shareKey: $shareKey
							clientId: $clientId
							personId: $personId
							projectId: $projectId
							isProjectGroupType: $isProjectGroupType
							dateCriteria: $dateCriteria
							groupBy: $groupBy
							projectIds: $projectIds
							billableFilterValue: $billableFilterValue
							personIds: $personIds
							departmentIds: $departmentIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
							skillIds: $skillIds
							componentId: $componentId
						)
				}
			}
		`
	)
);
