import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {Dropdown} from 'web-components';
import _ from 'lodash';
import Util from '../../../forecast-app/shared/util/util';
import {useIntl} from 'react-intl';
import LabelNamed from '../../../forecast-app/shared/components/labels/label_named';
import Styled from 'styled-components/macro';
import {useForecastFetchQuery} from '../../../forecast-app/shared/hooks/useForecastFetchQuery';

export const LabelsWrapper = Styled.div`
    display: flex;
    flex-direction:row;
    flex-wrap:wrap;
    &:first-child {
    	margin-bottom: 5px;
    }
    &:last-child {
    	margin-top: 5px;
    }
`;

const sortByName = (a, b) => {
	if (!a.id) return 1;
	if (!b.id) return -1;
	return Util.sortAlphabetically(a.name, b.name);
};

const suggestionsQuery = graphql`
	query LabelDropdownQuery($taskId: ID) {
		viewer {
			component(name: "suggested_label_dropdown")
			company {
				availableMLModels {
					labelModel
				}
			}
			task(id: $taskId) {
				labelSuggestion
			}
		}
	}
`;

export const LabelDropdown = ({
	labels,
	selectedItems,
	showLabelsOnTop,
	showLabelsBelow,
	onRemove,
	onSelect,
	taskId,
	useSuggestions,
	usePortal,
	...rest
}) => {
	const {formatMessage} = useIntl();
	const sortedLabels = labels.sort(sortByName);
	const NO_CATEGORY = {id: undefined, name: formatMessage({id: 'settings_labels.no_category'})};
	const {fetch, data} = useForecastFetchQuery(suggestionsQuery);

	const isFetching = useRef(false);

	const handleFetch = variables => {
		const onFetchSuccess = () => {
			isFetching.current = false;
		};

		if (!isFetching.current) {
			isFetching.current = true;
			fetch(variables, onFetchSuccess);
		}
	};

	const handleExpand = () => {
		if (taskId && useSuggestions) {
			handleFetch({taskId});
		}
	};

	const labelsByCategoryId = _.groupBy(sortedLabels, label => label.category?.id);
	const labelCategories = Object.values(
		_.keyBy(
			sortedLabels.map(label => label.category || NO_CATEGORY),
			category => category.id
		)
	).sort(sortByName);

	const labelsPreview = (
		<LabelsWrapper>
			{labels
				.filter(label => selectedItems?.includes(label.id))
				.map(label => (
					<LabelNamed key={label.id} label={label} removeLabel={() => onRemove(label.id)} />
				))}
		</LabelsWrapper>
	);

	const suggestedLabels =
		data &&
		data.viewer &&
		data.viewer.company.availableMLModels.labelModel &&
		data.viewer.task.labelSuggestion?.length > 0 &&
		labels.filter(label => data.viewer.task.labelSuggestion.includes(label.id)).slice(0, 3);

	const showSuggestedLabels = suggestedLabels && suggestedLabels.length > 0;

	return (
		<>
			{showLabelsOnTop && labelsPreview}
			<Dropdown
				{...rest}
				selectedItems={selectedItems}
				onExpand={handleExpand}
				onRemove={value => onRemove(value[0])}
				onSelect={value => onSelect(value[0])}
				isNested
				isMultiSelect
				hideEmptyGroups
				usePortal={usePortal}
			>
				{showSuggestedLabels && (
					<Dropdown.Group name={formatMessage({id: 'common.suggestions'})}>
						{suggestedLabels.map(label => (
							<Dropdown.LabelLine
								key={label.id}
								value={label.id}
								searchString={label.name}
								name={label.name}
								color={label.color}
								cy={rest.cy + '-suggestion-option'}
							/>
						))}
					</Dropdown.Group>
				)}
				{labelCategories.map(labelCategory => {
					const labels = labelsByCategoryId[labelCategory.id];
					if (!labels) return null;
					return (
						<Dropdown.Group name={labelCategory.name} key={`label-category-${labelCategory.id}`} initiallyCollapsed>
							{labels.map(label => (
								<Dropdown.LabelLine
									key={label.id}
									value={label.id}
									searchString={label.name}
									name={label.name}
									color={label.color}
									cy={rest.cy + '-option'}
								/>
							))}
						</Dropdown.Group>
					);
				})}
			</Dropdown>
			{showLabelsBelow && labelsPreview}
		</>
	);
};

LabelDropdown.propTypes = {
	labels: PropTypes.arrayOf(
		PropTypes.shape({
			node: PropTypes.shape({
				name: PropTypes.string.isRequired,
				id: PropTypes.string.isRequired,
				color: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	usePortal: PropTypes.bool,
};

LabelDropdown.defaultProps = {
	name: 'Labels',
	selectedGroupName: 'Selected',
	onSelect: () => false,
	onRemove: () => false,
};

export default createFragmentContainer(LabelDropdown, {
	labels: graphql`
		fragment LabelDropdown_labels on Label @relay(plural: true) {
			color
			name
			id
			category {
				id
				name
			}
		}
	`,
});
