import React, {useEffect, useState} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import Moment from 'moment';
import {withRouter} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import CustomScrollDiv from '../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {BUTTON_COLOR, BUTTON_STYLE, ELEMENT_TYPE, FILTER_SECTION, FILTER_TYPE, REPORT_TYPE} from '../../../constants';
import HeaderBar from '../../../forecast-app/shared/components/headers/header-bar/header_bar';
import {getInitialOptions, handleChangedOptions} from './financial_report_page_eye_options';
import {
	BannerWrapper,
	HeaderContainer,
	PageContent,
	PageTitle,
	PageTitleWrapper,
	TableWrapper,
} from '../../../styles/components/financial-report-page/financial_report_page_styled';
import {ProjectLine} from './components/ProjectLine';
import {getInitialFilters, handleChangedFilters} from './financial_report_page_filter';
// eslint-disable-next-line no-unused-vars
import {fetchData} from './financial_report_page_data_fetch';
import {TableHeader} from './components/TableHeader';
import {prepareStructure} from './financial_report_page_structure';
import Util from '../../../forecast-app/shared/util/util';
import {PeriodLabelHeaderElement} from './components/PeriodLabelHeaderElement';
import {handleCSVDownload} from './financial_report_page_download';
import UploadingOverlay from '../../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import * as tracking from '../../../tracking';
import {getOrderedFilters} from '../../../forecast-app/shared/components/filters/filter_util';
import {FILTER_SECTIONS} from '../../../forecast-app/shared/components/filters/FilterWrapper';
import {CachedHeaderItem} from './components/CachedHeaderItem';
import {processSavedReport} from '../../../forecast-app/reports-tab/saved-report/GenericSavedReportConductor';
import {hasTimeApproval} from '../../../forecast-app/shared/util/FeatureUtil';
import UpdateSavedReportMutation from '../../../mutations/ts/reporting/update_saved_report_mutation';
import {createToast} from '../../../forecast-app/shared/components/toasts/another-toast/toaster';
import {MODAL_TYPE, showModal} from '../../../forecast-app/shared/components/modals/generic_modal_conductor';
import CreateSavedReportMutation from '../../../mutations/reporting/create_saved_report_mutation';
import {
	getEyeApplied,
	getGroupingOne,
	getPeriodDate,
	getPeriodType,
} from '../../../containers/modal/create-new-report/create-new-report-util';
import {getSavedReportURLByReportType} from '../saved_report_util';
import {useTrackPage} from '../../../tracking/amplitude/hooks/useTrackPage';
import {trackCSVExport, trackEvent} from '../../../tracking/amplitude/TrackingV2';
import {
	DeprecatedLink as Link,
	DeprecatedNoticeBanner as NoticeBanner,
	DeprecatedText as Text,
	FlexColumn,
} from '@forecast-it/design-system';
import {buildUpdatedEyeOptions} from '../../../forecast-app/reports-tab/saved-report/GenericReportContext';
import CompanySetupUtil from '../../../forecast-app/shared/util/CompanySetupUtil';
import {ReportState} from '../../../forecast-app/reports-tab/reports-page/ReportTypes';

const financialReportPage = ({
	intl,
	viewer,
	history,
	viewer: {
		projects: viewerProjects,
		projectGroups,
		company,
		availableFeatureFlags,
		company: {currency},
	},
	savedReportRef,
}) => {
	useTrackPage('Financial Report');

	// Authorization. Same restrictions as project-budget
	useEffect(() => {
		if (!Util.AuthorizeViewerAccess('project-budget')) {
			history.push('/not-authorized');
			Util.localStorageSetItem('project-section-last-viewed', 'workflow');
		} else {
			tracking.trackPage('Financial Report');
		}
	}, []);

	const {formatMessage} = intl;
	const savedReport = processSavedReport(savedReportRef);
	const [reportName, setReportName] = useState(savedReport ? savedReport.name : null);
	const [isStateModified, setIsStateModified] = useState(false);

	const disableOldPpr = availableFeatureFlags.find(featureFlag => featureFlag.key === 'disable_old_ppr');

	const hasFinance = CompanySetupUtil.hasFinance();
	const hasBaseline = Util.hasBaselineModule();
	const useTimeApproval = hasTimeApproval();

	const [theEyeOptions, setTheEyeOptions] = useState(
		savedReport
			? JSON.parse(savedReport.eyeApplied)
			: getInitialOptions(availableFeatureFlags, hasFinance, hasBaseline, savedReport, useTimeApproval)
	);
	const [filters, setFilters] = useState(
		savedReport ? JSON.parse(savedReport.filterApplied) : getInitialFilters(hasBaseline)
	);
	const [reportData, setReportData] = useState({
		loading: true,
	});
	const [pageStructure, setPageStructure] = useState(null);
	const [dateRangeItem, setDateRangeItem] = useState({
		startDate: savedReport ? Moment(savedReport.startDate) : new Moment().startOf('month'),
		endDate: savedReport ? Moment(savedReport.endDate) : new Moment().endOf('month'),
	});
	const [ignoreCache, setIgnoreCache] = useState(false);

	const handleTheEyeOptionSelect = (_, __, ___, newOptions) => {
		setTheEyeOptions(handleChangedOptions(newOptions, savedReport));
		savedReport && setIsStateModified(true);
	};

	const csvDownload = () => {
		handleCSVDownload(pageStructure);
		tracking.trackElementClicked('CSV Download Button');
		trackCSVExport('Financial Report');
	};

	// Fetch the data for this page, including filtering and getting financial numbers
	useEffect(() => {
		if (!disableOldPpr) {
			fetchData(company, viewerProjects, cloneDeep(projectGroups), filters, dateRangeItem, setReportData, ignoreCache);
		} else {
			setReportData({loading: false});
		}
	}, [filters, dateRangeItem, ignoreCache]);

	useEffect(() => {
		if (!disableOldPpr) {
			setPageStructure(
				prepareStructure(
					reportData,
					theEyeOptions,
					intl,
					availableFeatureFlags,
					hasFinance,
					viewerProjects,
					hasBaseline,
					useTimeApproval
				)
			);
		}
	}, [reportData, theEyeOptions]);

	useEffect(() => {
		if (disableOldPpr) {
			showModal({
				type: MODAL_TYPE.GENERIC,
				headerText: 'Use our new Portfolio Report',
				content: (
					<div style={{width: 500}}>
						Our new and improved Project Portfolio Report gives you access to all same numbers you have today and
						more!
						<br />
						<br />
						Click the button below to create it.
					</div>
				),
				defaultCallback: () => history.push('/insights'),
				buttons: [
					{
						text: 'Back to report list',
						callback: () => history.push('/insights'),
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.WHITE,
					},
					{
						text: 'Create your own NEW Project Portfolio Report',
						callback: () => {
							const category = REPORT_TYPE.PROJECT_PORTFOLIO_REPORT;
							const onSuccess = response => {
								const report = response.createSavedReport.savedReport.node;
								history.push(getSavedReportURLByReportType(report.type, report.id));
							};
							Util.CommitMutation(
								CreateSavedReportMutation,
								{
									name: category + ', Untitled',
									type: category,
									eyeApplied: JSON.stringify(
										getEyeApplied(
											category,
											company.isUsingProjectAllocation,
											company.isUsingSchedulingPlanMode,
											Util.isMixedAllocationModeEnabled(company)
										)
									),
									startDate: Moment().startOf('month').format('YYYY-MM-DD'),
									endDate: Moment().endOf('month').format('YYYY-MM-DD'),
									filterApplied: JSON.stringify(null),
									groupingOne: getGroupingOne(category),
									periodType: getPeriodType(category),
									periodDate: getPeriodDate(category),
									reportService: false,
								},
								onSuccess
							);
						},
						style: BUTTON_STYLE.FILLED,
						color: BUTTON_COLOR.GREEN,
					},
				],
			});
		}
	}, []);

	const onFilterChange = changedFilters => {
		setReportData({loading: true});
		setFilters(handleChangedFilters(changedFilters, savedReport));
		savedReport && setIsStateModified(true);
	};

	const onDateRangeChange = dateRange => {
		setReportData({loading: true});
		setDateRangeItem(dateRange);
		savedReport && setIsStateModified(true);
		tracking.trackEvent('Date Range Updated');
		trackEvent('Date Range', 'Updated');
	};

	const disableCache = () => {
		setReportData({loading: true});
		setIgnoreCache(true);
	};

	const onSuccess = () => {
		setIsStateModified(false);
		createToast({
			duration: 5000,
			message: formatMessage({id: 'save_report.report_updated'}),
		});
	};

	const handlePublishReportSelect = () => {
		Util.CommitMutation(
			UpdateSavedReportMutation,
			{
				id: savedReport.id,
				eyeApplied: JSON.stringify(buildUpdatedEyeOptions(theEyeOptions, JSON.parse(savedReport.eyeApplied))),
				startDate: dateRangeItem.startDate.format('YYYY-MM-DD'),
				endDate: dateRangeItem.endDate.format('YYYY-MM-DD'),
				state: ReportState.SAVED,
				filterApplied: JSON.stringify(filters),
				name: reportName,
				reportService: savedReport.reportService,
			},
			onSuccess
		);
	};

	const handleReportNameChange = newName => {
		setReportName(newName);
		setIsStateModified(true);
	};

	const onDeprecationLinkClick = () => {
		trackEvent('Old PPR Deprecation Alert', 'Link Clicked');
		history.push('/reports/new/step1');
	};

	const getHeader = () => {
		const leftContent = [],
			rightContent = [];

		savedReport &&
			leftContent.push({
				type: ELEMENT_TYPE.INPUT_REPORT_TITLE,
				intl: intl,
				width: '225px',
				value: reportName,
				onChange: handleReportNameChange,
			});

		savedReport &&
			rightContent.push({
				type: ELEMENT_TYPE.BUTTON,
				color: BUTTON_COLOR.PURPLE,
				disabled: !isStateModified,
				text: intl.formatMessage({id: 'common.save'}),
				callback: handlePublishReportSelect,
			});

		leftContent.push({
			type: ELEMENT_TYPE.THE_EYE,
			options: theEyeOptions,
			onSelect: handleTheEyeOptionSelect,
			openRight: true,
		});

		leftContent.push({
			type: ELEMENT_TYPE.CSV,
			callback: csvDownload,
		});

		if (reportData.cachedTime) {
			leftContent.push({
				type: ELEMENT_TYPE.CUSTOM_CONTENT,
				render: key => <CachedHeaderItem key={key} cachedTime={reportData.cachedTime} />,
			});
			leftContent.push({
				type: ELEMENT_TYPE.BUTTON,
				text: 'Disable cache',
				style: BUTTON_STYLE.OUTLINE_THICK,
				color: BUTTON_COLOR.LIGHTGREY,
				callback: disableCache,
			});
		}

		if (theEyeOptions.find(option => option.name === 'periodTotals').checked) {
			rightContent.push({
				type: ELEMENT_TYPE.CUSTOM_CONTENT,
				render: key => <PeriodLabelHeaderElement key={key} />,
			});
			rightContent.push({
				type: ELEMENT_TYPE.DATE_RANGE_PICKER_WITH_QUICK_RANGES,
				defaultRangeItem: dateRangeItem,
				onRangeItemChange: onDateRangeChange,
				clearable: false,
				userpilot: 'date-range-picker',
				calendarOffsetX: -400,
			});
		}

		const importantFilters = []; // Populate this with the 3 filters that should be shown at the top for the new filter.
		const projectFilters = [
			FILTER_TYPE.PROJECT_STATUS,
			FILTER_TYPE.CONTACT,
			FILTER_TYPE.PROJECT_STAGE,
			FILTER_TYPE.CLIENT,
			FILTER_TYPE.LABEL,
			FILTER_TYPE.PROGRESS,
			FILTER_TYPE.DEADLINE,
		];
		if (hasFinance) {
			projectFilters.push(FILTER_TYPE.PROJECT_TYPE, FILTER_TYPE.RATE_CARD);
		}

		rightContent.push({
			type: ELEMENT_TYPE.FILTER_V4,
			operatorOptions: {allowExclude: false, allowRequireAll: false},
			defaultSection: FILTER_SECTIONS.PROJECTS,
			projectFilters: getOrderedFilters(importantFilters, projectFilters, formatMessage),
			onFiltersChange: onFilterChange,
			viewer: viewer,
			useSavedReport: !!savedReport || !!filters,
			preAppliedFilters: !!savedReport ? JSON.parse(savedReport.filterApplied) : !!filters ? filters : null,
			filterSection: FILTER_SECTION.FINANCIAL_PORTFOLIO_REPORT,
			userpilot: 'finance-report-filter',
		});

		return <HeaderBar parentGroup={null} leftContent={leftContent} rightContent={rightContent} />;
	};

	// array that contains the indexes of the columns that should be stickyColumnsIndexes. This array can change depending on the selected elements in the eye
	const stickyColumnsIndexes = theEyeOptions.find(option => option.name === 'projectId').checked ? [0, 1, 2] : [0, 1];
	return (
		<>
			{savedReport ? null : (
				<PageTitleWrapper>
					<FlexColumn gap={'s'}>
						<PageTitle>{formatMessage({id: 'financial_portfolio_report.title'})}</PageTitle>
						<BannerWrapper>
							<NoticeBanner
								type={'alert'}
								children={
									<>
										<Text>
											<span style={{fontWeight: '600'}}>
												{formatMessage({
													id: 'financial_portfolio_report.new_deprecation_message_1',
												})}
											</span>
											{formatMessage({id: 'financial_portfolio_report.new_deprecation_message_2'})}
										</Text>
										<Link onClick={onDeprecationLinkClick}>
											{formatMessage({
												id: 'financial_portfolio_report.new_deprecation_message_link',
											})}
										</Link>
									</>
								}
							></NoticeBanner>
						</BannerWrapper>
					</FlexColumn>
				</PageTitleWrapper>
			)}

			<HeaderContainer>{!disableOldPpr && getHeader()}</HeaderContainer>
			<CustomScrollDiv className="custom-scrollbar-div">
				<PageContent>
					{disableOldPpr ? (
						<div />
					) : reportData.loading ? (
						<UploadingOverlay />
					) : (
						<TableWrapper>
							<table>
								<TableHeader
									currencySymbol={Util.GetCurrencySymbol(currency)}
									pageStructure={pageStructure}
									stickyColumnsIndexes={stickyColumnsIndexes}
								/>
								<tbody>
									{pageStructure.projectLines.map((projectLine, index) => (
										<ProjectLine
											key={index}
											projectLine={projectLine}
											currencySymbol={Util.GetCurrencySymbol(currency)}
											pageStructure={pageStructure}
											stickyColumnsIndexes={stickyColumnsIndexes}
											isFirstLine={index === 0}
											isLastLine={index === pageStructure.projectLines.length - 1}
										/>
									))}
								</tbody>
							</table>
						</TableWrapper>
					)}
				</PageContent>
			</CustomScrollDiv>
		</>
	);
};

const financialReportPageQuery = graphql`
	query financialReportPage_Query {
		viewer {
			actualPersonId
			component(name: "financial_report_page")
			...financialReportPage_viewer
		}
	}
`;

export {financialReportPageQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(financialReportPage, {
			viewer: graphql`
				fragment financialReportPage_viewer on Viewer {
					...financialReportPageDataFetch_viewer @relay(mask: false)
				}
			`,
		})
	)
);
