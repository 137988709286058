import Group from '../../canvas-timeline/canvas_timeline_group';
import {CURSOR, GROUP_SECTION_WIDTH, GROUP_TYPE} from '../../canvas-timeline/canvas_timeline_util';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import Util from '../../../../forecast-app/shared/util/util';
import {CSS_CONSTANTS} from '../../../../css_variables';

export class LoadingGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.LOADING, data);
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {height} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);
		interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);

		canvasContext.font = '12px ' + Util.getFontFamily();
		canvasContext.fillStyle = CSS_CONSTANTS.color_white;
		canvasContext.fillText('Loading', x + 66, y + 23);
	}
}

export default LoadingGroup;
