import React, { memo } from 'react';
import { Document } from '@react-pdf/renderer';
import { PageStandard, TextAuxiliary, ViewRow } from './layout/PDFLayout';
import BaselinePDFHeader from './BaselinePDFHeader';
import BaselinePDFMeta from './BaselinePDFMeta';
import BaselinePDFPhase from './BaselinePDFPhase';
import BaselinePDFSummary from './BaselinePDFSummary';
const BaselinePDF = memo(({ client, project, phases, creatorName, companyName, date, intl }) => {
    return (React.createElement(Document, null,
        React.createElement(PageStandard, null,
            companyName ? (React.createElement(ViewRow, { fixed: true },
                React.createElement(TextAuxiliary, null, companyName))) : null,
            React.createElement(BaselinePDFHeader, { project: project }),
            React.createElement(BaselinePDFMeta, { client: client, creatorName: creatorName, date: date, intl: intl }),
            React.createElement(BaselinePDFSummary, { phases: phases, projectTotal: project.total, intl: intl }),
            phases
                ? phases
                    .filter(phase => phase.items.length > 0)
                    .map(phase => {
                    return React.createElement(BaselinePDFPhase, { key: phase.id, phase: phase, intl: intl });
                })
                : null)));
});
export default BaselinePDF;
