/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DeprecatedProjectIndicatorJS_project$ref = any;
export type APPROVAL_STATUS = "APPROVED" | "NOT_SUBMITTED" | "SUBMITTED" | "%future added value";
export type FILTER_SECTION = "FINANCIAL_PORTFOLIO_REPORT" | "INVOICING_INVOICES" | "INVOICING_OVERVIEW" | "LABELS" | "MY_LIST" | "MY_TASKS" | "MY_TIMESHEETS" | "MY_WORK" | "PROJECT" | "PROJECTS_OVERVIEW" | "PROJECT_TIMELINE" | "REPORTED_TIME_TABLE" | "SCHEDULING_CAPACITY_OVERVIEW" | "SCHEDULING_PEOPLE" | "SCHEDULING_PLACEHOLDERS" | "SCHEDULING_PROJECTS" | "SCOPING" | "SKILLS" | "SPRINT" | "SPRINT_BACKLOG" | "TASK_REPORT" | "TEAM_TIMESHEET" | "TIME_APPROVAL" | "UTILIZATION_REPORT" | "WORKFLOW" | "%future added value";
export type LANGUAGE = "DANISH" | "ENGLISH_EU" | "ENGLISH_UK" | "ENGLISH_US" | "FRENCH" | "POLISH" | "SPANISH" | "%future added value";
export type TierType = "CORE" | "ENTERPRISE" | "FREE_FOREVER" | "LITE" | "PRO" | "TRIAL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type timesheetsTeamPage_viewer$ref: FragmentReference;
declare export opaque type timesheetsTeamPage_viewer$fragmentType: timesheetsTeamPage_viewer$ref;
export type timesheetsTeamPage_viewer = {|
  +component: ?string,
  +id: string,
  +harvestUser: ?boolean,
  +actualPersonId: ?string,
  +email: ?string,
  +backendId: ?number,
  +availableFeatureFlags: ?$ReadOnlyArray<?{|
    +key: ?string
  |}>,
  +filters: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +section: ?FILTER_SECTION,
        +value: ?string,
        +updatedAt: ?string,
      |}
    |}>
  |},
  +language: ?LANGUAGE,
  +projectOwner: ?boolean,
  +company: ?{|
    +id: string,
    +harvestEnabled: ?boolean,
    +bambooHREnabled: ?boolean,
    +characterLimit: ?number,
    +lockedPeriodYear: ?number,
    +lockedPeriodMonth: ?number,
    +lockedPeriodDay: ?number,
    +useTimeApproval: ?boolean,
    +useTimeLocking: ?boolean,
    +sageIntacctConnectionEnabled: ?boolean,
    +modules: ?$ReadOnlyArray<?{|
      +moduleType: ?string
    |}>,
    +tier: ?TierType,
    +idleTimes: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +isInternalTime: ?boolean,
        |}
      |}>
    |},
    +timeRegistrations: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +day: ?number,
          +month: ?number,
          +year: ?number,
          +minutesRegistered: ?number,
          +billableMinutesRegistered: ?number,
          +billable: ?boolean,
          +lockedInPeriod: ?boolean,
          +approvalStatus: ?APPROVAL_STATUS,
          +invoiced: ?boolean,
          +person: ?{|
            +id: string,
            +startDate: ?string,
            +endDate: ?string,
            +monday: ?number,
            +tuesday: ?number,
            +wednesday: ?number,
            +thursday: ?number,
            +friday: ?number,
            +saturday: ?number,
            +sunday: ?number,
            +excludeFromCompanyLockedPeriod: ?boolean,
          |},
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
          +notes: ?string,
          +project: ?{|
            +id: string,
            +name: ?string,
            +projectColor: ?string,
            +companyProjectId: ?number,
            +fullAccessToProject: ?boolean,
            +readOnlyAccess: ?boolean,
            +billable: ?boolean,
            +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
          |},
          +task: ?{|
            +id: string,
            +name: ?string,
            +companyTaskId: ?number,
            +billable: ?boolean,
            +estimateForecastMinutes: ?number,
            +totalMinutesRegistered: ?number,
            +project: ?{|
              +id: string,
              +name: ?string,
              +companyProjectId: ?number,
              +customProjectId: ?string,
              +projectColor: ?string,
              +fullAccessToProject: ?boolean,
              +readOnlyAccess: ?boolean,
              +$fragmentRefs: DeprecatedProjectIndicatorJS_project$ref,
            |},
          |},
          +idleTimeId: ?string,
          +idleTime: ?{|
            +id: string,
            +name: ?string,
            +isInternalTime: ?boolean,
          |},
        |}
      |}>
    |},
    +teams: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +teamPersons: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +person: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
        |}
      |}>
    |},
    +roles: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +labels: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
          +color: ?string,
        |}
      |}>
    |},
    +departments: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +name: ?string,
        |}
      |}>
    |},
    +allPersons: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +holidayCalendar: ?{|
            +id: string,
            +holidayCalendarEntries: ?{|
              +edges: ?$ReadOnlyArray<?{|
                +node: ?{|
                  +id: string,
                  +day: ?number,
                  +month: ?number,
                  +year: ?number,
                  +name: ?string,
                |}
              |}>
            |},
            +name: ?string,
          |},
          +id: string,
          +role: ?{|
            +id: string,
            +name: ?string,
          |},
          +personLabels: ?{|
            +edges: ?$ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +label: ?{|
                  +id: string
                |},
              |}
            |}>
          |},
          +department: ?{|
            +id: string,
            +name: ?string,
          |},
          +firstName: ?string,
          +createdAt: ?string,
          +startDate: ?string,
          +endDate: ?string,
          +lastName: ?string,
          +systemUser: ?boolean,
          +initials: ?string,
          +active: ?boolean,
          +monday: ?number,
          +tuesday: ?number,
          +wednesday: ?number,
          +thursday: ?number,
          +friday: ?number,
          +saturday: ?number,
          +sunday: ?number,
          +excludeFromCompanyLockedPeriod: ?boolean,
          +submitLockedDateYear: ?number,
          +submitLockedDateMonth: ?number,
          +submitLockedDateDay: ?number,
          +harvestUser: ?boolean,
          +economicUser: ?boolean,
          +profilePictureId: ?string,
          +profilePictureDefaultId: ?number,
          +assignedProjectIds: ?$ReadOnlyArray<?string>,
          +email: ?string,
        |}
      |}>
    |},
  |},
  +$refType: timesheetsTeamPage_viewer$ref,
|};
export type timesheetsTeamPage_viewer$data = timesheetsTeamPage_viewer;
export type timesheetsTeamPage_viewer$key = {
  +$data?: timesheetsTeamPage_viewer$data,
  +$fragmentRefs: timesheetsTeamPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "harvestUser",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInternalTime",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "day",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "month",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billable",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "monday",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tuesday",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "wednesday",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thursday",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "friday",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "saturday",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sunday",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "excludeFromCompanyLockedPeriod",
  "storageKey": null
},
v22 = [
  (v0/*: any*/),
  (v3/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "role",
  "plural": false,
  "selections": (v22/*: any*/),
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "projectColor",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "companyProjectId",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullAccessToProject",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readOnlyAccess",
  "storageKey": null
},
v28 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DeprecatedProjectIndicatorJS_project"
},
v29 = {
  "kind": "Literal",
  "name": "first",
  "value": 1000000
},
v30 = [
  (v29/*: any*/)
],
v31 = [
  (v0/*: any*/)
],
v32 = {
  "kind": "Literal",
  "name": "first",
  "value": 100000
},
v33 = [
  (v32/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDateString",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDateString",
      "type": "String"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "filters"
        ]
      },
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "company",
          "timeRegistrations"
        ]
      }
    ]
  },
  "name": "timesheetsTeamPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "team-timesheet-page"
        }
      ],
      "kind": "ScalarField",
      "name": "component",
      "storageKey": "component(name:\"team-timesheet-page\")"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actualPersonId",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backendId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FeatureFlag",
      "kind": "LinkedField",
      "name": "availableFeatureFlags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "filters",
      "args": null,
      "concreteType": "FilterTypeConnection",
      "kind": "LinkedField",
      "name": "__Viewer_filters_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FilterTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Filter",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "section",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "harvestEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bambooHREnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "characterLimit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lockedPeriodDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeApproval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "useTimeLocking",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sageIntacctConnectionEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Module",
          "kind": "LinkedField",
          "name": "modules",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "moduleType",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tier",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IdleTimeTypeConnection",
          "kind": "LinkedField",
          "name": "idleTimes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IdleTimeTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IdleTime",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": "timeRegistrations",
          "args": null,
          "concreteType": "TimeRegTypeConnection",
          "kind": "LinkedField",
          "name": "__Company_timeRegistrations_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TimeRegTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TimeRegType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v8/*: any*/),
                    (v9/*: any*/),
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "minutesRegistered",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "billableMinutesRegistered",
                      "storageKey": null
                    },
                    (v11/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lockedInPeriod",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "approvalStatus",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "invoiced",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Person",
                      "kind": "LinkedField",
                      "name": "person",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v12/*: any*/),
                        (v13/*: any*/),
                        (v14/*: any*/),
                        (v15/*: any*/),
                        (v16/*: any*/),
                        (v17/*: any*/),
                        (v18/*: any*/),
                        (v19/*: any*/),
                        (v20/*: any*/),
                        (v21/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v23/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "notes",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ProjectType",
                      "kind": "LinkedField",
                      "name": "project",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        (v24/*: any*/),
                        (v25/*: any*/),
                        (v26/*: any*/),
                        (v27/*: any*/),
                        (v11/*: any*/),
                        (v28/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Task",
                      "kind": "LinkedField",
                      "name": "task",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "companyTaskId",
                          "storageKey": null
                        },
                        (v11/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "estimateForecastMinutes",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "totalMinutesRegistered",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ProjectType",
                          "kind": "LinkedField",
                          "name": "project",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v3/*: any*/),
                            (v25/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "customProjectId",
                              "storageKey": null
                            },
                            (v24/*: any*/),
                            (v26/*: any*/),
                            (v27/*: any*/),
                            (v28/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "idleTimeId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "IdleTime",
                      "kind": "LinkedField",
                      "name": "idleTime",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v3/*: any*/),
                        (v7/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v4/*: any*/)
                  ],
                  "storageKey": null
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v30/*: any*/),
          "concreteType": "TeamTypeConnection",
          "kind": "LinkedField",
          "name": "teams",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TeamTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": (v30/*: any*/),
                      "concreteType": "TeamPersonTypeConnection",
                      "kind": "LinkedField",
                      "name": "teamPersons",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "TeamPersonTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "TeamPerson",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Person",
                                  "kind": "LinkedField",
                                  "name": "person",
                                  "plural": false,
                                  "selections": (v31/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "teamPersons(first:1000000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "teams(first:1000000)"
        },
        {
          "alias": null,
          "args": (v30/*: any*/),
          "concreteType": "RoleTypeConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Role",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v22/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "roles(first:1000000)"
        },
        {
          "alias": null,
          "args": (v33/*: any*/),
          "concreteType": "LabelTypeConnection",
          "kind": "LinkedField",
          "name": "labels",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "LabelTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Label",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "labels(first:100000)"
        },
        {
          "alias": null,
          "args": (v33/*: any*/),
          "concreteType": "DepartmentTypeConnection",
          "kind": "LinkedField",
          "name": "departments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "DepartmentTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DepartmentType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v22/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "departments(first:100000)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "excludeClientUsers",
              "value": true
            },
            (v29/*: any*/)
          ],
          "concreteType": "PersonTypeConnection",
          "kind": "LinkedField",
          "name": "allPersons",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PersonTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Person",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "HolidayCalendar",
                      "kind": "LinkedField",
                      "name": "holidayCalendar",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": [
                            {
                              "kind": "Variable",
                              "name": "endDate",
                              "variableName": "endDateString"
                            },
                            (v32/*: any*/),
                            {
                              "kind": "Variable",
                              "name": "startDate",
                              "variableName": "startDateString"
                            }
                          ],
                          "concreteType": "HolidayCalendarEntryTypeConnection",
                          "kind": "LinkedField",
                          "name": "holidayCalendarEntries",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "HolidayCalendarEntryTypeEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "HolidayCalendarEntry",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    (v8/*: any*/),
                                    (v9/*: any*/),
                                    (v10/*: any*/),
                                    (v3/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    (v23/*: any*/),
                    {
                      "alias": null,
                      "args": (v33/*: any*/),
                      "concreteType": "PersonLabelTypeConnection",
                      "kind": "LinkedField",
                      "name": "personLabels",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PersonLabelTypeEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "PersonLabel",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Label",
                                  "kind": "LinkedField",
                                  "name": "label",
                                  "plural": false,
                                  "selections": (v31/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "personLabels(first:100000)"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DepartmentType",
                      "kind": "LinkedField",
                      "name": "department",
                      "plural": false,
                      "selections": (v22/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    (v12/*: any*/),
                    (v13/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "systemUser",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "initials",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "active",
                      "storageKey": null
                    },
                    (v14/*: any*/),
                    (v15/*: any*/),
                    (v16/*: any*/),
                    (v17/*: any*/),
                    (v18/*: any*/),
                    (v19/*: any*/),
                    (v20/*: any*/),
                    (v21/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "submitLockedDateYear",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "submitLockedDateMonth",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "submitLockedDateDay",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "economicUser",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "profilePictureDefaultId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "assignedProjectIds",
                      "storageKey": null
                    },
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "allPersons(excludeClientUsers:true,first:1000000)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd875c219beffc3843652ce67dabe8f01';

module.exports = node;
