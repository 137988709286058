import React, {useState, useEffect} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {graphql, createRefetchContainer} from 'react-relay';
import {getTaskRow} from '../rows/task_row';
import BaseLevelWrapper from './BaselevelWrapper';
import {fetchMapData, fetchData} from '../reported_time_financials_fetch';
import {hasSomePermission, hasPermission} from '../../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../../Permissions';
import {useDidObjectChange} from '../../../../forecast-app/shared/hooks/useDidObjectChange';

const taskRelayWrapper = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	groupIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
}) => {
	const tasks = viewer.company.allTasks.edges;
	const currency = viewer.company.currency;
	const hasNextPage = viewer.company.allTasks.pageInfo.hasNextPage;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	const [nonTaskTotalMinutesRegistered, setNonTaskTotalMinutesRegistered] = useState(null);
	const [nonTaskTotalFinancials, setNonTaskTotalFinancials] = useState(null);
	const [tasksFinancialMap, setTasksFinancialMap] = useState(null);
	const [noAccessTotalMinutesRegistered, setNoAccessTotalMinutesRegistered] = useState(null);

	const tasksChanged = useDidObjectChange(tasks, null);
	const searchQueryChanged = useDidObjectChange(searchQuery, null);
	const shouldRefetchFinancials = tasksChanged || searchQueryChanged;

	useEffect(() => {
		if (shouldRefetchFinancials) {
			if (!hasNextPage && !hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL)) {
				fetchData(searchQuery, 'no_access_task_total_minutes_registered').then(data => {
					if (data.total) {
						setNoAccessTotalMinutesRegistered(data.total);
					}
				});
			}
			const tasksIds = tasks.map(task => task.node.id);

			fetchMapData(tasksIds, searchQuery, 'total_task_time_regs_financials').then(data => {
				const tasksFinancialMap = new Map();
				Object.keys(data).forEach(key => {
					tasksFinancialMap.set(key, data[key]);
				});
				setTasksFinancialMap(tasksFinancialMap);
			});

			if (!hasNextPage) {
				fetchData(searchQuery, 'non_task_total_minutes_registered').then(data => {
					if (data.total !== undefined) {
						setNonTaskTotalMinutesRegistered(data.total);
					}
				});
				if (
					hasSomePermission([
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION,
						PERMISSION_TYPE.VIEW_FINANCIAL_INFORMATION_REVENUE,
					])
				) {
					fetchData(searchQuery, 'total_non_task_time_regs_financials').then(data => {
						setNonTaskTotalFinancials(data.totals);
					});
				}
			}
		}
	}, [shouldRefetchFinancials]);

	const dataRows = tasks.map(task => {
		return {
			...task.node,
			hasAccessToTask: true,
			totalPrice:
				tasksFinancialMap !== null && tasksFinancialMap.has(task.node.id)
					? tasksFinancialMap.get(task.node.id).totalPrice
					: null,
			totalCost:
				tasksFinancialMap !== null && tasksFinancialMap.has(task.node.id)
					? tasksFinancialMap.get(task.node.id).totalCost
					: null,
		};
	});

	if (nonTaskTotalMinutesRegistered !== null) {
		dataRows.push({
			isNoTask: true,
			name: intl.formatMessage({id: 'time_reg_report.no_task'}),
			hasAccessToTask: true,
			totalMinutesRegistered: nonTaskTotalMinutesRegistered,
			totalPrice: nonTaskTotalFinancials ? nonTaskTotalFinancials.totalPrice : null,
			totalCost: nonTaskTotalFinancials ? nonTaskTotalFinancials.totalCost : null,
		});
	}

	if (noAccessTotalMinutesRegistered !== null) {
		dataRows.push({
			name: intl.formatMessage({id: 'time_reg_report.no_tasks_access'}),
			canExpand: false,
			hasAccessToTask: false,
			totalMinutesRegistered: noAccessTotalMinutesRegistered,
			totalPrice: null,
			totalCost: null,
		});
	}

	const data = {rows: dataRows};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = tasks.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const taskRows = () => {
		return getTaskRow(
			data,
			currency,
			intl,
			groupIndex,
			tasksFinancialMap,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader
		);
	};

	return groupIndex === 1 ? (
		<BaseLevelWrapper
			intl={intl}
			enabledColumns={enabledColumns}
			tableHeader={tableHeader}
			data={data}
			getRows={taskRows}
			currentLength={tasks.length}
			hasNextPage={viewer.company.allTasks.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		taskRows()
	);
};

const taskRelayWrapperQuery = graphql`
	query taskRelayWrapperQuery_Query($searchQuery: TaskSearchQueryType, $pageSize: Int, $validateAccess: Boolean) {
		viewer {
			actualPersonId
			component(name: "task_relay_wrapper")
			...taskRelayWrapper_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, validateAccess: $validateAccess)
		}
	}
`;
export {taskRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			taskRelayWrapper,
			{
				viewer: graphql`
					fragment taskRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						validateAccess: {type: "Boolean"}
					) {
						company {
							currency
							allTasks(first: $pageSize, searchQuery: $searchQuery) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									node {
										id
										name
										totalMinutesRegistered(searchQuery: $searchQuery)
										project {
											readOnlyAccess
											fullAccessToProject
										}
										phase {
											name
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query taskRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$validateAccess: Boolean
				) {
					viewer {
						...taskRelayWrapper_viewer
							@arguments(pageSize: $pageSize, searchQuery: $searchQuery, validateAccess: $validateAccess)
					}
				}
			`
		)
	)
);
