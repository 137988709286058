import Util from '../../../forecast-app/shared/util/util';
import {BUDGET_TYPE, PERIOD_BUDGET_TYPE, PAYMENT_STATUS} from '../../../constants';

export const createInvoiceProjectObject = (project, currency) => {
	return {
		id: project.id,
		name: project.name,
		client: project.client,
		companyProjectId: project.companyProjectId,
		customProjectId: project.customProjectId,
		projectColor: project.projectColor,
		isInProjectGroup: project.isInProjectGroup,
		currency: project.rateCard ? project.rateCard.currency : currency,
		uninvoicedMinutes: 0,
		uninvoicedPrice: 0,
		uninvoicedExpenses: 0,
		uninvoicedFixedPrice: 0,
		invoicedAmount: 0,
		invoicedAmountUnfiltered: 0,
		fixedAmount: project.budget,
		fixedAmountIncludingBillableExpenses: project.budget,
		relayProject: project,
	};
};

export const createDataObject = () => {
	return {
		totalUninvoicedMinutes: 0.0,
		totalUninvoicedPrice: 0.0,
		totalUninvoicedExpenses: 0.0,
		totalInvoicedAmount: 0.0,
		totalInvoicedAmountUnfiltered: 0.0,
		projects: [],
	};
};

export const getProjectExchangeRate = (project, company, exchangeRateMap) => {
	let exchangeRate = 1;
	let projectCurrency = company.currency;

	// convert entry to the company currency if it differs
	if (project.rateCard && project.rateCard.currency !== company.currency) {
		projectCurrency = project.rateCard.currency;
		if (project.currencyExchangeRate) {
			exchangeRate = project.currencyExchangeRate;
		} else {
			exchangeRate = exchangeRateMap[projectCurrency];
		}
	}

	return exchangeRate;
};

export const addInvoicedAmount = (invoiceProject, project, startDate, endDate) => {
	project.invoiceTotals.forEach(invoiceTotal => {
		invoiceProject.invoicedAmountUnfiltered += invoiceTotal.invoicedWithoutTaxProjectCurrency;
		if (!startDate || !endDate) {
			invoiceProject.invoicedAmount += invoiceTotal.invoicedWithoutTaxProjectCurrency;
		} else {
			const invoiceCreateDay = Util.CreateNonUtcMomentDate(
				invoiceTotal.createdYear,
				invoiceTotal.createdMonth,
				invoiceTotal.createdDay
			);
			if (invoiceCreateDay >= startDate && invoiceCreateDay <= endDate) {
				invoiceProject.invoicedAmount += invoiceTotal.invoicedWithoutTaxProjectCurrency;
			}
		}
	});
};

export const addExpensesAmount = (invoiceProject, project, startDate, endDate) => {
	const isFixedPriceProject =
		project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 ||
		(project.budgetType === BUDGET_TYPE.RETAINER && project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE);
	project.expenseItems.edges.forEach(expense => {
		if (
			expense.node.billable &&
			expense.node.approved &&
			!expense.node.invoiced &&
			(expense.node.partOfFixedPrice === false || !isFixedPriceProject)
		) {
			const expenseTotalPrice = expense.node.price * expense.node.quantity;
			invoiceProject.fixedAmountIncludingBillableExpenses += expenseTotalPrice;
			if (!startDate || !endDate) {
				invoiceProject.uninvoicedExpenses += expenseTotalPrice;
			} else {
				invoiceProject.uninvoicedExpensesUnfiltered += expenseTotalPrice;
				const expenseDate = Util.CreateNonUtcMomentDate(
					expense.node.expenseYear,
					expense.node.expenseMonth,
					expense.node.expenseDay
				);
				if (expenseDate >= startDate && expenseDate <= endDate) {
					invoiceProject.uninvoicedExpenses += expenseTotalPrice;
				}
			}
		}
	});
};

export const addTimeRegs = (invoiceProject, timeRegs, startDate, endDate) => {
	timeRegs.forEach(timeReg => {
		const timeRegDate = Util.CreateNonUtcMomentDate(timeReg.year, timeReg.month, timeReg.day);
		if (!timeRegDate.isBefore(startDate, 'days') && !timeRegDate.isAfter(endDate, 'days')) {
			invoiceProject.uninvoicedMinutes += timeReg.minutesRegistered;
			invoiceProject.uninvoicedPrice += timeReg.price;
		}
	});
};

export const addProjectToData = (data, invoiceProject, company, project, preventSkippingZeroProjects) => {
	const exchangeRateMap = Util.getCompanyExchangeRateMap(company);
	const exchangeRate = getProjectExchangeRate(project, company, exchangeRateMap);

	data.totalInvoicedAmountUnfiltered += invoiceProject.invoicedAmountUnfiltered / exchangeRate;
	if (
		preventSkippingZeroProjects ||
		invoiceProject.uninvoicedMinutes !== 0 ||
		invoiceProject.uninvoicedFixedPrice >= 0.1 ||
		invoiceProject.uninvoicedFixedPrice <= -0.1 ||
		invoiceProject.uninvoicedPrice >= 0.1 ||
		invoiceProject.uninvoicedPrice <= -0.1 ||
		invoiceProject.uninvoicedExpenses >= 0.1 ||
		invoiceProject.uninvoicedExpenses <= -0.1
	) {
		data.totalUninvoicedMinutes += invoiceProject.uninvoicedMinutes;
		data.totalUninvoicedPrice += invoiceProject.uninvoicedPrice / exchangeRate;
		data.totalUninvoicedExpenses += invoiceProject.uninvoicedExpenses / exchangeRate;
		data.totalUninvoicedPrice += invoiceProject.uninvoicedFixedPrice / exchangeRate;
		data.totalInvoicedAmount += invoiceProject.invoicedAmount / exchangeRate;
		data.projects.push(invoiceProject);
	}
};

export const searchFilter = (project, searchFilterValue) => {
	if (searchFilterValue !== null) {
		const origin =
			'P' +
			project.companyProjectId +
			project.customProjectId +
			project.name +
			(project.client ? project.client.name : '');
		return Util.normalizedIncludes(origin, searchFilterValue);
	}
	return true;
};

export const beautifyExternalStatusText = exStatus => {
	const statusArray = exStatus.split('_');

	if (statusArray[2]) {
		return statusArray[1].charAt(0) + statusArray[1].slice(1).toLowerCase() + ' ' + statusArray[2]?.toLowerCase();
	} else if (statusArray[1]) {
		return statusArray[1].charAt(0) + statusArray[1].slice(1).toLowerCase();
	} else {
		return exStatus.charAt(0) + exStatus.slice(1).toLowerCase();
	}
};

export const getPaymentStatusText = (paymentStatus, intl) => {
	switch (paymentStatus) {
		case PAYMENT_STATUS.UNPAID:
			return intl.formatMessage({id: 'filter_type.unpaid'});
		case PAYMENT_STATUS.PARTIALLY_PAID:
			return intl.formatMessage({id: 'filter_type.partially_paid'});
		case PAYMENT_STATUS.PAID:
			return intl.formatMessage({id: 'filter_type.paid'});
		case PAYMENT_STATUS.OVERDUE:
			return intl.formatMessage({id: 'filter_type.overdue'});
		default:
			return '';
	}
};
