export const EXPENSE_MANAGEMENT_COLUMNS = {
    NAME: 'name',
    APPROVAL_STATUS: 'approval_status',
    BILLING_OPTION: 'billing_option',
    CATEGORY: 'category',
    CLIENT: 'client',
    DATE: 'date',
    FILES: 'files',
    MARKUP: 'markup',
    PERSON: 'person',
    PROJECT: 'project',
    MONTH: 'month',
    TOTAL_COST: 'total_cost',
    TOTAL_PRICE: 'total_price',
};
