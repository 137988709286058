import {createFragmentContainer, graphql} from 'react-relay';

const FinancialCalculationTrigger = ({retry, viewer, children}) => {
	return children(
		{
			timestamp: viewer.project.financialCalculationTrigger.timestamp,
			duration: viewer.project.financialCalculationTrigger.duration,
			isSmallProject: viewer.project.financialCalculationTrigger.isSmallProject,
		},
		retry
	);
};
export const financialCalculationTriggerQuery = graphql`
	query FinancialCalculationTrigger_Query($projectId: ID!) {
		viewer {
			actualPersonId
			component(name: "financial_calculation_trigger")
			...FinancialCalculationTrigger_viewer @arguments(projectId: $projectId)
		}
	}
`;

export default createFragmentContainer(FinancialCalculationTrigger, {
	viewer: graphql`
		fragment FinancialCalculationTrigger_viewer on Viewer @argumentDefinitions(projectId: {type: "ID!"}) {
			project(internalId: $projectId) {
				id
				financialCalculationTrigger {
					timestamp
					duration
					isSmallProject
				}
			}
		}
	`,
});
