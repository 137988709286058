/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RoleRow_phaseBaselineRole$ref: FragmentReference;
declare export opaque type RoleRow_phaseBaselineRole$fragmentType: RoleRow_phaseBaselineRole$ref;
export type RoleRow_phaseBaselineRole = {|
  +id: string,
  +phaseId: ?number,
  +role: ?{|
    +id: string,
    +name: ?string,
  |},
  +baselineMinutes: ?number,
  +baselinePrice: ?number,
  +baselineRatePerHour: ?number,
  +baselineCost: ?number,
  +baselineCostPerHour: ?number,
  +baselineProfit: ?number,
  +startYear: ?number,
  +startMonth: ?number,
  +startDay: ?number,
  +deadlineYear: ?number,
  +deadlineMonth: ?number,
  +deadlineDay: ?number,
  +$refType: RoleRow_phaseBaselineRole$ref,
|};
export type RoleRow_phaseBaselineRole$data = RoleRow_phaseBaselineRole;
export type RoleRow_phaseBaselineRole$key = {
  +$data?: RoleRow_phaseBaselineRole$data,
  +$fragmentRefs: RoleRow_phaseBaselineRole$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RoleRow_phaseBaselineRole",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Role",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselinePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineRatePerHour",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineCostPerHour",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deadlineYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deadlineMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deadlineDay",
      "storageKey": null
    }
  ],
  "type": "PhaseBaselineRoleType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '44518b91e2a91a3d8ed52499cb7d9ccf';

module.exports = node;
