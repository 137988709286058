/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type utilizationListComponent_viewer$ref: FragmentReference;
declare export opaque type utilizationListComponent_viewer$fragmentType: utilizationListComponent_viewer$ref;
export type utilizationListComponent_viewer = {|
  +insightComponentsData: ?{|
    +utilizationList: ?{|
      +id: string,
      +availableMinutesTotal: ?number,
      +scheduledMinutesTotal: ?number,
      +scheduledProjectTimeMinutes: ?number,
      +scheduledNonProjectTimeMinutes: ?number,
      +reportedTotal: ?number,
      +assignedTasksForecastTotal: ?number,
      +overTimeMinutesTotal: ?number,
      +billableMinutesRegisteredTotal: ?number,
      +billableMinutesPlannedTotal: ?number,
      +utilizationListData: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +profilePictureId: ?string,
        +profilePictureDefaultId: ?number,
        +roleName: ?string,
        +availableMinutes: ?number,
        +scheduledMinutes: ?number,
        +scheduledNonProjectTimeMinutes: ?number,
        +scheduledProjectTimeMinutes: ?number,
        +reported: ?number,
        +billableMinutesRegistered: ?number,
        +billableMinutesPlanned: ?number,
        +cardMinutesPlanned: ?number,
      |}>,
    |}
  |},
  +$refType: utilizationListComponent_viewer$ref,
|};
export type utilizationListComponent_viewer$data = utilizationListComponent_viewer;
export type utilizationListComponent_viewer$key = {
  +$data?: utilizationListComponent_viewer$data,
  +$fragmentRefs: utilizationListComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledProjectTimeMinutes",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scheduledNonProjectTimeMinutes",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shareKey",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "dateCriteria",
      "type": "String"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "componentId",
      "type": "ID"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "personIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "teamIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "roleIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "labelIds",
      "type": "[ID]"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectStatusesBackend",
      "type": "[ID]"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "utilizationListComponent_viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "shareKey",
          "variableName": "shareKey"
        }
      ],
      "concreteType": "InsightComponentsDataType",
      "kind": "LinkedField",
      "name": "insightComponentsData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "componentId",
              "variableName": "componentId"
            },
            {
              "kind": "Variable",
              "name": "dateCriteria",
              "variableName": "dateCriteria"
            },
            {
              "kind": "Variable",
              "name": "labelIds",
              "variableName": "labelIds"
            },
            {
              "kind": "Variable",
              "name": "personIds",
              "variableName": "personIds"
            },
            {
              "kind": "Variable",
              "name": "projectStatusesBackend",
              "variableName": "projectStatusesBackend"
            },
            {
              "kind": "Variable",
              "name": "roleIds",
              "variableName": "roleIds"
            },
            {
              "kind": "Variable",
              "name": "teamIds",
              "variableName": "teamIds"
            }
          ],
          "concreteType": "UtilizationList",
          "kind": "LinkedField",
          "name": "utilizationList",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "availableMinutesTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scheduledMinutesTotal",
              "storageKey": null
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reportedTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "assignedTasksForecastTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "overTimeMinutesTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableMinutesRegisteredTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billableMinutesPlannedTotal",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UtilizationListPerson",
              "kind": "LinkedField",
              "name": "utilizationListData",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "profilePictureDefaultId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "roleName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "availableMinutes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "scheduledMinutes",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reported",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billableMinutesRegistered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "billableMinutesPlanned",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cardMinutesPlanned",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = '0dff6f407ea9779185c04afc38a311fd';

module.exports = node;
