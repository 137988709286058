import * as tracking from '../../tracking';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';

import {MODULE_TYPES, SCHEDULING_VIEW} from '../../constants';
import {getDayData} from './canvas-timeline/canvas_timeline_util';
import CanvasPeopleScheduling from './people-scheduling/canvas_people_scheduling';
import CanvasProjectsScheduling from './projects-scheduling/canvas_projects_scheduling';
import Util from '../../forecast-app/shared/util/util';
import {hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import CanvasPlaceholdersScheduling from './placeholders-scheduling/CanvasPlaceholdersScheduling';
import {createFragmentContainer, graphql} from 'react-relay';
import CanvasCapacityOverview from './capacity-overview/CanvasCapacityOverview';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import {initializeStaticDayData} from './static/serializationUtil';
import {navigateUnauthorizedCheckTaskLink} from '../../forecast-app/shared/util/UrlUtil';

export const SCHEDULING_PAGE_URL = {
	TIMELINE: 'timeline',
	ALL_TIMELINES: 'all-timelines',
	PEOPLE_SCHEDULE: 'people',
	DEMAND: 'demand',
	CAPACITY_OVERVIEW: 'capacity-overview',
	MY_SCHEDULE: 'my-schedule',
};

export const canViewSchedulingPage = tab => {
	if (hasPermission(PERMISSION_TYPE.SCHEDULING_ACCESS)) {
		return true;
	}

	if (tab) {
		if (tab === SCHEDULING_PAGE_URL.MY_SCHEDULE) {
			return true;
		}

		if (hasFeatureFlag('scheduling_read_only_permissions') && tab) {
			switch (tab) {
				case SCHEDULING_PAGE_URL.ALL_TIMELINES:
					return hasPermission(PERMISSION_TYPE.ALL_TIMELINES_VIEW_ONLY);
				case SCHEDULING_PAGE_URL.PEOPLE_SCHEDULE:
					return hasPermission(PERMISSION_TYPE.PEOPLE_SCHEDULE_VIEW_ONLY);
				case SCHEDULING_PAGE_URL.DEMAND:
					return hasPermission(PERMISSION_TYPE.DEMAND_VIEW_ONLY);
				case SCHEDULING_PAGE_URL.CAPACITY_OVERVIEW:
					return hasPermission(PERMISSION_TYPE.CAPACITY_OVERVIEW_VIEW_ONLY);
				default:
					break;
			}
		}
	}

	return window.location.href.endsWith(SCHEDULING_PAGE_URL.TIMELINE);
};

class CanvasScheduling extends Component {
	constructor(props) {
		super(props);

		this.isUsingStaticDayData = hasFeatureFlag('static_day_data_generation');

		this.setShowSecondaryNavigation = props.setShowSecondaryNavigation;

		let dayData = this.isUsingStaticDayData ? null : getDayData();

		if (this.isUsingStaticDayData) {
			initializeStaticDayData(result => {
				this.setState({dayData: result});
			});
		}

		const isUsingProjectAllocation = this.props.isProjectTimeline
			? null
			: this.props.viewer.company?.isUsingProjectAllocation ||
			  Util.isMixedAllocationModeEnabled(this.props.viewer.company);

		const weekendDisplayPerUser = this.props.viewer.company.weekendDisplayPerUser;

		const weekendDisplayShowAlways = this.props.viewer.company.weekendDisplayShowAlways;

		const weekendOptions = {weekendDisplayPerUser, weekendDisplayShowAlways};

		this.peopleSchedulingExpansionMap = new Map();
		this.projectSchedulingExpansionMap = new Map();
		this.placeholdersSchedulingExpansionMap = new Map();
		this.capacityOverviewSchedulingExpansionMap = new Map();

		this.loadMoreHistory = new Set();

		// local storage
		this.schedulingViewKey = 'schedulingViewSelected';
		let localStorageSchedulingView = localStorage.getItem(this.schedulingViewKey);

		// TODO: remove after a while (backwards compatibility)
		if (localStorageSchedulingView === SCHEDULING_PAGE_URL.ALL_TIMELINES) {
			localStorageSchedulingView = SCHEDULING_VIEW.PROJECTS;
		}

		const isLocalStorageTabValid = localStorageSchedulingView
			? Object.values(SCHEDULING_VIEW).includes(localStorageSchedulingView)
			: false;
		let schedulingView = isLocalStorageTabValid ? localStorageSchedulingView : SCHEDULING_VIEW.PEOPLE;

		let isProjectSchedulingSelected = localStorage.getItem('schedulingRedesignIsProjectSchedulingSelected') === 'true';

		let tab = window.location.hash?.substring(1) || null;
		if (tab) {
			if (hasFeatureFlag('placeholders')) {
				if (tab === SCHEDULING_PAGE_URL.PEOPLE_SCHEDULE || tab === SCHEDULING_PAGE_URL.MY_SCHEDULE) {
					schedulingView = SCHEDULING_VIEW.PEOPLE;
				}

				if (tab === SCHEDULING_PAGE_URL.TIMELINE || tab === SCHEDULING_PAGE_URL.ALL_TIMELINES) {
					schedulingView = SCHEDULING_VIEW.PROJECTS;
				}

				if (tab === SCHEDULING_PAGE_URL.DEMAND && isUsingProjectAllocation) {
					schedulingView = SCHEDULING_VIEW.PLACEHOLDERS;
				}

				if (
					tab === SCHEDULING_PAGE_URL.CAPACITY_OVERVIEW &&
					isUsingProjectAllocation &&
					hasModule(MODULE_TYPES.CAPACITY_OVERVIEW) &&
					hasFeatureFlag('placeholders')
				) {
					schedulingView = SCHEDULING_VIEW.CAPACITY_OVERVIEW;
				}
			} else {
				if (tab === SCHEDULING_PAGE_URL.PEOPLE_SCHEDULE) {
					isProjectSchedulingSelected = false;
				}

				if (tab === SCHEDULING_PAGE_URL.TIMELINE || tab === SCHEDULING_PAGE_URL.ALL_TIMELINES) {
					isProjectSchedulingSelected = true;
				}
			}
		}

		if (!canViewSchedulingPage(tab)) {
			if (hasFeatureFlag('placeholders')) {
				if (canViewSchedulingPage(SCHEDULING_PAGE_URL.PEOPLE_SCHEDULE)) {
					schedulingView = SCHEDULING_VIEW.PEOPLE;
				} else {
					this.navigateUnauthorized();
				}
			} else {
				isProjectSchedulingSelected = false;
			}
		}

		if (props.isProjectTimeline) {
			if (hasFeatureFlag('placeholders')) {
				schedulingView = SCHEDULING_VIEW.PROJECTS;
			} else {
				isProjectSchedulingSelected = true;
			}
		}

		if (hasFeatureFlag('placeholders')) {
			Util.localStorageSetItem(this.schedulingViewKey, schedulingView);
		} else {
			Util.localStorageSetItem(
				'schedulingRedesignIsProjectSchedulingSelected',
				isProjectSchedulingSelected && !props.isProjectTimeline
			);
		}

		this.state = {
			dayData,
			isProjectSchedulingSelected,
			browserMessageHidden: false,
			schedulingView,
			isUsingProjectAllocation,
			staffingPlaceholderId: undefined,
			initialStartDate: undefined,
			weekendOptions,
		};
	}

	componentDidMount() {
		document.title = 'Scheduling - Forecast';
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const {isUsingProjectAllocation} = this.state;
		if (window.location.hash) {
			const tab = window.location.hash.substring(1);
			let isProjectSchedulingSelected = this.state.isProjectSchedulingSelected;
			let schedulingView = this.state.schedulingView;

			this.clearLoadMoreHistory();

			if (hasFeatureFlag('placeholders')) {
				if (tab === SCHEDULING_PAGE_URL.PEOPLE_SCHEDULE || tab === SCHEDULING_PAGE_URL.MY_SCHEDULE) {
					schedulingView = SCHEDULING_VIEW.PEOPLE;
				}
				if (tab === SCHEDULING_PAGE_URL.TIMELINE || tab === SCHEDULING_PAGE_URL.ALL_TIMELINES) {
					schedulingView = SCHEDULING_VIEW.PROJECTS;
				}
				if (tab === SCHEDULING_PAGE_URL.DEMAND && isUsingProjectAllocation) {
					schedulingView = SCHEDULING_VIEW.PLACEHOLDERS;
				}
				if (
					tab === SCHEDULING_PAGE_URL.CAPACITY_OVERVIEW &&
					isUsingProjectAllocation &&
					hasModule(MODULE_TYPES.CAPACITY_OVERVIEW) &&
					hasFeatureFlag('placeholders')
				) {
					schedulingView = SCHEDULING_VIEW.CAPACITY_OVERVIEW;
				}
			} else {
				if (tab === SCHEDULING_PAGE_URL.PEOPLE_SCHEDULE) {
					isProjectSchedulingSelected = false;
				}
				if (tab === SCHEDULING_PAGE_URL.TIMELINE || tab === SCHEDULING_PAGE_URL.ALL_TIMELINES) {
					isProjectSchedulingSelected = true;
				}
			}

			if (!canViewSchedulingPage(tab)) {
				if (hasFeatureFlag('placeholders')) {
					if (canViewSchedulingPage(SCHEDULING_PAGE_URL.PEOPLE_SCHEDULE)) {
						schedulingView = SCHEDULING_VIEW.PEOPLE;
					} else {
						this.navigateUnauthorized();
					}
				} else {
					isProjectSchedulingSelected = false;
				}
			}
			if (nextProps.isProjectTimeline) {
				if (hasFeatureFlag('placeholders')) {
					schedulingView = SCHEDULING_VIEW.PROJECTS;
				} else {
					isProjectSchedulingSelected = true;
				}
			}
			if (hasFeatureFlag('placeholders')) {
				if (schedulingView !== this.state.schedulingView) {
					Util.localStorageSetItem(this.schedulingViewKey, schedulingView);
					const filterString = `canvas-scheduling-filters-v3-${schedulingView}`;

					if (this.state.data && localStorage.getItem(filterString)) {
						const filters = JSON.parse(localStorage.getItem(filterString));
						this.setState({schedulingView, filters});
					} else {
						this.setState({schedulingView});
					}
				}
			} else {
				if (isProjectSchedulingSelected !== this.state.isProjectSchedulingSelected) {
					Util.localStorageSetItem('schedulingRedesignIsProjectSchedulingSelected', isProjectSchedulingSelected);
					const filterString = `canvas-scheduling-filters-v3-${isProjectSchedulingSelected ? 'projects' : 'people'}`;
					if (this.state.data && localStorage.getItem(filterString)) {
						const filters = JSON.parse(localStorage.getItem(filterString));
						this.setState({isProjectSchedulingSelected, filters});
					} else {
						this.setState({isProjectSchedulingSelected});
					}
				}
			}
		}
	}

	componentWillUnmount() {
		if (this.setShowSecondaryNavigation) {
			this.setShowSecondaryNavigation(true);
		}
	}

	navigateUnauthorized() {
		navigateUnauthorizedCheckTaskLink(this.props.history);
	}

	setTimeline(timeline) {
		this.timeline = timeline;
	}

	getTimelineStartDate() {
		return this.timeline?.startDate;
	}

	geTimeLineZoomLevel() {
		return this.timeline?.state.zoomLevel;
	}

	clearLoadMoreHistory() {
		this.loadMoreHistory.clear();
	}

	onTabClick(isProjectTab) {
		let {isProjectSchedulingSelected} = this.state;
		Util.localStorageSetItem('schedulingRedesignIsProjectSchedulingSelected', isProjectTab);
		if (isProjectTab === isProjectSchedulingSelected) return;
		isProjectSchedulingSelected = isProjectTab;
		window.location.hash = isProjectSchedulingSelected
			? SCHEDULING_PAGE_URL.ALL_TIMELINES
			: SCHEDULING_PAGE_URL.PEOPLE_SCHEDULE;
		this.setState({
			isProjectSchedulingSelected,
		});
	}

	enterStaffingMode(placeholderId) {
		if (this.setShowSecondaryNavigation) {
			this.setShowSecondaryNavigation(false);
		}
		this.setState({
			staffingPlaceholderId: placeholderId,
			originalZoomLevel: this.geTimeLineZoomLevel(),
			originalStartDate: this.getTimelineStartDate(),
		});
	}

	exitStaffingMode() {
		if (this.setShowSecondaryNavigation) {
			this.setShowSecondaryNavigation(true);
		}
		this.clearLoadMoreHistory();
		this.projectSchedulingExpansionMap.clear();
		this.setState({
			staffingPlaceholderId: undefined,
			initialZoomLevel: this.state.originalZoomLevel,
			initialStartDate: this.state.originalStartDate,
		});
	}

	selectTab(tab) {
		const {isUsingProjectAllocation} = this.state;
		let {schedulingView} = this.state;

		if (!Object.values(SCHEDULING_VIEW).includes(tab)) return;

		const projectAllocationRequired = [SCHEDULING_VIEW.PLACEHOLDERS, SCHEDULING_VIEW.CAPACITY_OVERVIEW];
		if (projectAllocationRequired.includes(tab) && !isUsingProjectAllocation) return;

		Util.localStorageSetItem(this.schedulingViewKey, tab);
		if (tab === schedulingView) return;

		schedulingView = tab;
		window.location.hash = tab;

		this.setState({
			schedulingView,
			initialStartDate: undefined,
		});
	}

	hideBrowserMessage() {
		this.setState({browserMessageHidden: true});
	}

	getChromeWarning() {
		const browserSpecs = Util.getBrowserSpecs();
		if (!this.state.browserMessageHidden && browserSpecs.name === 'Chrome' && browserSpecs.version === '77') {
			tracking.trackEvent('scheduling-chrome-warning-shown');
			trackEvent('Scheduling Chrome Warning', 'Shown');
			return (
				<div className="scheduling-browser-message-background">
					<div className="scheduling-browser-message">
						<button className="close-button" onClick={this.hideBrowserMessage.bind(this)} />
						<div className="browser-message-text">
							Please restart your browser to upgrade to the latest version of Google Chrome.
						</div>
						<div className="browser-message-text">This will resolve the current issues with Scheduling.</div>
						<div className="browser-message-text">
							If the problem remains please make sure you have the latest version (v78).
						</div>
						<a target="_blank" href="https://support.google.com/chrome/answer/95414" rel="noopener noreferrer">
							Guide to update Chrome
						</a>
					</div>
				</div>
			);
		}
	}

	getSelectedSchedulingView() {
		const shareUrl = this.props.match.params.shareUrl;
		const {isProjectTimeline} = this.props;
		const {isProjectSchedulingSelected, data, dayData, isPeopleSchedulingInActualMode, schedulingView, weekendOptions} =
			this.state;
		const projectId = this.props.match.params.projectId ? this.props.match.params.projectId : this.props.projectId;
		const groupId = this.props.match.params.groupId ? this.props.match.params.groupId : this.props.groupId;
		const programPrefix = this.props.match.params.prefix ? this.props.match.params.prefix : this.props.programPrefix;

		let locationHash = window.location.hash ? window.location.hash.substring(1) : null;
		if (locationHash?.includes('/')) {
			locationHash = locationHash.split('/')[0];
		}

		const tab = locationHash || null;
		const isMySchedule = tab === SCHEDULING_PAGE_URL.MY_SCHEDULE;

		if (hasFeatureFlag('placeholders')) {
			if (schedulingView === SCHEDULING_VIEW.PLACEHOLDERS || this.state.staffingPlaceholderId) {
				return (
					<CanvasPlaceholdersScheduling
						viewer={this.props.viewer}
						data={data}
						setTimeline={this.setTimeline.bind(this)}
						dayData={dayData}
						isInActualMode={isPeopleSchedulingInActualMode}
						startDate={this.state.initialStartDate}
						initialZoomLevel={this.state.initialZoomLevel}
						onTabClick={this.selectTab.bind(this)}
						schedulingView={SCHEDULING_VIEW.PLACEHOLDERS}
						expansionMap={this.placeholdersSchedulingExpansionMap}
						staffingPlaceholderId={this.state.staffingPlaceholderId}
						enterStaffingMode={this.enterStaffingMode.bind(this)}
						exitStaffingMode={this.exitStaffingMode.bind(this)}
						weekendOptions={weekendOptions}
						loadMoreHistory={this.loadMoreHistory}
						history={this.props.history}
					/>
				);
			} else if (schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW) {
				return (
					<CanvasCapacityOverview
						viewer={this.props.viewer}
						onTabClick={this.selectTab.bind(this)}
						setTimeline={this.setTimeline.bind(this)}
						startDate={this.state.initialStartDate}
						initialZoomLevel={this.state.initialZoomLevel}
						data={data}
						dayData={dayData}
						isInActualMode={isPeopleSchedulingInActualMode}
						schedulingView={SCHEDULING_VIEW.CAPACITY_OVERVIEW}
						expansionMap={this.capacityOverviewSchedulingExpansionMap}
						enterStaffingMode={this.enterStaffingMode.bind(this)}
						weekendOptions={weekendOptions}
						loadMoreHistory={this.loadMoreHistory}
						history={this.props.history}
					/>
				);
			} else if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
				return (
					<CanvasProjectsScheduling
						match={this.props.match}
						data={data}
						startDate={this.state.initialStartDate}
						initialZoomLevel={this.state.initialZoomLevel}
						setTimeline={this.setTimeline.bind(this)}
						searchFilterValue={this.state.searchFilterValue}
						dayData={dayData}
						showTaskModal={taskId => Util.showTaskModal(taskId, this.props.history)}
						isProjectTimeline={isProjectTimeline}
						timelineHeatmapData={this.state.timelineHeatmapData}
						projectId={projectId}
						groupId={groupId}
						programPrefix={programPrefix}
						shareUrl={shareUrl}
						expansionMap={this.projectSchedulingExpansionMap}
						onTabClick={this.selectTab.bind(this)}
						enterStaffingMode={this.enterStaffingMode.bind(this)}
						schedulingView={SCHEDULING_VIEW.PROJECTS}
						weekendOptions={weekendOptions}
						viewer={this.props.viewer}
						loadMoreHistory={this.loadMoreHistory}
						history={this.props.history}
					/>
				);
			} else {
				return (
					<CanvasPeopleScheduling
						key={'people-schedule-' + tab}
						viewer={this.props.viewer}
						data={data}
						setTimeline={this.setTimeline.bind(this)}
						dayData={dayData}
						showTaskModal={taskId => Util.showTaskModal(taskId, this.props.history)}
						isInActualMode={isPeopleSchedulingInActualMode}
						options={this.state.schedulingOptions}
						expansionMap={this.peopleSchedulingExpansionMap}
						onTabClick={this.selectTab.bind(this)}
						schedulingView={SCHEDULING_VIEW.PEOPLE}
						isPeopleScheduling={true}
						weekendOptions={weekendOptions}
						loadMoreHistory={this.loadMoreHistory}
						isUsingProjectAllocation={this.state.isUsingProjectAllocation}
						history={this.props.history}
						isMySchedule={isMySchedule}
					/>
				);
			}
		} else {
			if (isProjectSchedulingSelected) {
				return (
					<CanvasProjectsScheduling
						match={this.props.match}
						data={data}
						setTimeline={this.setTimeline.bind(this)}
						searchFilterValue={this.state.searchFilterValue}
						dayData={dayData}
						showTaskModal={taskId => Util.showTaskModal(taskId, this.props.history)}
						isProjectTimeline={isProjectTimeline}
						timelineHeatmapData={this.state.timelineHeatmapData}
						projectId={projectId}
						groupId={groupId}
						shareUrl={shareUrl}
						programPrefix={programPrefix}
						expansionMap={this.projectSchedulingExpansionMap}
						onTabClick={this.onTabClick.bind(this)}
						isProjectSchedulingSelected={true}
						enterStaffingMode={this.enterStaffingMode.bind(this)}
						weekendOptions={weekendOptions}
						schedulingView={SCHEDULING_VIEW.PROJECTS}
						viewer={this.props.viewer}
						loadMoreHistory={this.loadMoreHistory}
						history={this.props.history}
					/>
				);
			}

			return (
				<CanvasPeopleScheduling
					viewer={this.props.viewer}
					data={data}
					setTimeline={this.setTimeline.bind(this)}
					dayData={dayData}
					showTaskModal={taskId => Util.showTaskModal(taskId, this.props.history)}
					isInActualMode={isPeopleSchedulingInActualMode}
					options={this.state.schedulingOptions}
					startDate={this.timeline ? this.timeline.startDate : undefined}
					expansionMap={this.peopleSchedulingExpansionMap}
					onTabClick={this.onTabClick.bind(this)}
					isProjectSchedulingSelected={false}
					isPeopleScheduling={true}
					weekendOptions={weekendOptions}
					schedulingView={SCHEDULING_VIEW.PEOPLE}
					loadMoreHistory={this.loadMoreHistory}
					isUsingProjectAllocation={this.state.isUsingProjectAllocation}
					history={this.props.history}
				/>
			);
		}
	}

	render() {
		const {dayData} = this.state;
		const shareUrl = this.props.match.params.shareUrl;

		if (this.isUsingStaticDayData && !dayData) return null;

		return (
			<div key={'c2'} className={`canvas-scheduling ${shareUrl ? 'shared' : ''}`}>
				{this.props.children}
				{this.getChromeWarning()}
				{this.getSelectedSchedulingView()}
			</div>
		);
	}
}

const CanvasSchedulingQuery = graphql`
	query canvasScheduling_Query($projectId: String, $groupId: String) {
		viewer {
			actualPersonId
			component(name: "canvas_scheduling")
			...canvasScheduling_viewer @arguments(projectId: $projectId, groupId: $groupId)
		}
	}
`;

export {CanvasSchedulingQuery};

export default injectIntl(
	withRouter(
		createFragmentContainer(CanvasScheduling, {
			viewer: graphql`
				fragment canvasScheduling_viewer on Viewer
				@argumentDefinitions(projectId: {type: "String"}, groupId: {type: "String"}) {
					id
					actualPersonId
					company {
						id
						weekendDisplayPerUser
						weekendDisplayShowAlways
						isUsingProjectAllocation
						isUsingSchedulingPlanMode
						isUsingMixedAllocation
					}
					project(id: $projectId) {
						id
						...ProjectHeader_project
						...SecondaryNavigation_project
					}
					projectGroup(id: $groupId) {
						...ProjectHeader_projectGroup
						...SecondaryNavigation_projectGroup
					}
					psProject(companyProjectId: $projectId) {
						...ProjectHeader_psProject
					}
				}
			`,
		})
	)
);
