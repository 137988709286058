/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ExpenseRevenueRecognition_project$ref = any;
type RevenueRecognition_project$ref = any;
type TimeRevenueRecognition_project$ref = any;
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type RevenueRecognitionPage_project$ref: FragmentReference;
declare export opaque type RevenueRecognitionPage_project$fragmentType: RevenueRecognitionPage_project$ref;
export type RevenueRecognitionPage_project = {|
  +id: string,
  +rateCard: ?{|
    +id: string,
    +currency: ?string,
  |},
  +budgetType: ?BUDGET_TYPE,
  +$fragmentRefs: RevenueRecognition_project$ref & TimeRevenueRecognition_project$ref & ExpenseRevenueRecognition_project$ref,
  +$refType: RevenueRecognitionPage_project$ref,
|};
export type RevenueRecognitionPage_project$data = RevenueRecognitionPage_project;
export type RevenueRecognitionPage_project$key = {
  +$data?: RevenueRecognitionPage_project$data,
  +$fragmentRefs: RevenueRecognitionPage_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RevenueRecognitionPage_project",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RateCard",
      "kind": "LinkedField",
      "name": "rateCard",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RevenueRecognition_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TimeRevenueRecognition_project"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExpenseRevenueRecognition_project"
    }
  ],
  "type": "ProjectType"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4afd171c478762011a66a87081f9cf9';

module.exports = node;
