import {createFragmentContainer, graphql} from 'react-relay';
import {PROGRAM_BUDGET_TYPE} from '../../../../constants';

const TotalsLoader = ({relay, viewer, children, ...props}) => {
	let unallocatedRevenueTotal = 0;
	viewer.company.financialBasedPrograms?.edges.forEach(program => {
		const {projectsFinancialsTotals} = program.node;
		unallocatedRevenueTotal +=
			projectsFinancialsTotals.unallocatedRevenue && program.node.budgetType === PROGRAM_BUDGET_TYPE.FIXED_PRICE
				? projectsFinancialsTotals.unallocatedRevenue
				: 0;
	});

	const formatData = () => {
		return [
			{
				currency: viewer.company.currency,
				isTotalsRow: true,
				unallocatedRevenueTotal: unallocatedRevenueTotal,
				financialNumbers: {
					...viewer.company.financialNumbers,
				},
			},
		];
	};
	return children({data: {rows: formatData()}, ...props});
};

export const totalsLoaderQuery = graphql`
	query TotalsLoader_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$searchQuery: TaskSearchQueryType!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_period_totals_loader")
			...TotalsLoader_viewer
				@arguments(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export default createFragmentContainer(TotalsLoader, {
	viewer: graphql`
		fragment TotalsLoader_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
		) {
			id
			company {
				currency
				financialNumbers(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				) {
					billableActualTimeAndExpenses
					totalActualRevenueRecognition
					actualCost
					actualProfit
					actualRevenueProfit
					actualMargin
					actualRevenueMargin
					billablePlannedTimeAndExpenses
					plannedRevenue
					plannedCost
					plannedProfit
					plannedRevenueProfit
					plannedMargin
					plannedRevenueMargin
					billableForecastTimeAndExpensesToComplete
					totalForecastRevenueToComplete
					forecastCostToComplete
					forecastProfitToComplete
					forecastRevenueProfitToComplete
					forecastMarginToComplete
					forecastRevenueMarginToComplete
					billableTotalTimeAndExpensesAtCompletion
					nonBillableTotalTimeAndExpensesAtCompletion
					allTotalTimeAndExpensesAtCompletion
					totalRevenueRecognition
					totalCostAtCompletion
					totalProfitAtCompletion
					totalRevenueProfitAtCompletion
					totalMarginAtCompletion
					totalRevenueMarginAtCompletion
					baselineRevenue
					baselineTimeAndExpenses
					baselineCost
					baselineProfit
					baselineMargin
					baselineMinutes
					planVsBillableActualTimeAndExpenses
					planVsActualCost
					planVsActualProfit
					planVsTotalBillableTimeAndExpensesAtCompletion
					planVsTotalCostAtCompletion
					planVsTotalProfitAtCompletion
					registeredMinutes
					forecastTimeToComplete
					totalTimeAtCompletion
					totalSuggestedRevenue
					scopeApprovedMinutes
					scopeTotalMinutes
					allocationMinutes
					invoiced
					paid
					accruedDeferred
					retainerPeriodTargetMinutes
					retainerPeriodTargetPrice
					recognitionLockedRevenue
					recognitionOpenRevenue
					totalRevenueRecognition
					unallocatedRevenue
					programBudgetValue
					recognitionProfit
					recognitionProfitPercentage
					projectedTotalRevenueVsProjectedTotalBillableValueOfService
					projectedTotalRevenueVsProjectedTotalValueOfService
				}
				financialBasedPrograms(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				) {
					edges {
						node {
							budgetType
							projectsFinancialsTotals {
								unallocatedRevenue
							}
						}
					}
				}
			}
		}
	`,
});
