import {
	GROUP_TYPE,
	GROUP_SECTION_WIDTH,
	drawBorderLines,
	drawBackground,
	GROUP_SECTION_TEXT_GREY_DARK,
	GROUP_SECTION_PADDING_LEFT,
	GROUP_SECTION_EXPAND_ICON_HEIGHT,
	GROUP_SECTION_EXPAND_ICON_WIDTH,
	GROUP_SECTION_MARGIN_LEFT,
	CURSOR,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import Util from '../../../../forecast-app/shared/util/util';
import {cacheManager, COMMON_IMAGE} from '../../canvas-timeline/canvas_timeline_cache_manager';
import {interactionManager} from '../../canvas-timeline/canvas_timeline_interaction_manager';
import {SCHEDULING_VIEW} from '../../../../constants';

class PersonGroupingGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.PERSON_GROUPING_GROUP, data);

		this.renderRowLines = true;
		this.hideIfAllChildrenAreFiltered = true;
		this.includeInRowHover = false;
	}

	draw(canvasContext, x, y, collapsableSectionHeight) {
		const width = GROUP_SECTION_WIDTH;
		const {height, expanded} = this;
		super.draw(x, y, width, height, this, collapsableSectionHeight);

		const {schedulingView} = this.pageComponent.props;
		const isCapacityOverview = schedulingView === SCHEDULING_VIEW.CAPACITY_OVERVIEW;
		const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;

		drawBackground(canvasContext, x, y, width, height, true);

		interactionManager.addCursorStyleArea(x, y, width, height, CURSOR.POINTER, true);

		let left = 0;

		if (isCapacityOverview || isPeopleScheduling) {
			left += GROUP_SECTION_PADDING_LEFT;
		}

		if (!this.preventExpansion) {
			canvasContext.drawImage(
				cacheManager.getCommonImage(expanded ? COMMON_IMAGE.EXPAND_GROUP_ICON : COMMON_IMAGE.EXPAND_GROUP_CLOSED_ICON),
				left,
				y + height / 2 - GROUP_SECTION_EXPAND_ICON_HEIGHT / 2,
				GROUP_SECTION_EXPAND_ICON_WIDTH,
				GROUP_SECTION_EXPAND_ICON_HEIGHT
			);
		}

		left += GROUP_SECTION_MARGIN_LEFT;

		this.drawRoleName(canvasContext, height, width, left, y);
	}

	drawRoleName(canvasContext, height, width, left, y) {
		const {name} = this.data;

		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;

		const fontSize = 12;
		canvasContext.font = `500 ${fontSize}px ` + Util.getFontFamily();
		const roleNameY = y + height / 2 + fontSize / 2;

		canvasContext.fillText(name, left, roleNameY);

		drawBorderLines(canvasContext, 0, y, width, height, true);
	}
}

export default PersonGroupingGroup;
