import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import {createRefetchContainer, graphql} from 'react-relay';
import BaseLevelWrapper from './BaselevelWrapper';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../constants';
import GroupRow from '../rows/GroupRow';

const RoleRelayWrapper = ({
	relay,
	tableHeader,
	groupings,
	searchQuery,
	viewer,
	intl,
	rowIndex,
	enabledColumns,
	nextLevelProps,
	sortValue,
	convertToProjectCurrency,
	projectCurrency,
}) => {
	const roles = viewer.company.allRolesGlobalSearch.edges;
	const company = viewer.company;
	const currency = convertToProjectCurrency && projectCurrency ? projectCurrency : viewer.company.currency;
	const hasNextPage = viewer.company.allRolesGlobalSearch.pageInfo.hasNextPage;
	const [modalTasksFetching, setModalTasksFetching] = useState(false);
	/**
	 * Grouping filter for task with specific role.
	 *
	 * @param roleID The role ID
	 */
	const roleGroupingFilter = roleID => {
		return {
			field: GLOBAL_FILTER_FIELD.ROLE,
			operator: GLOBAL_FILTER_OPERATOR.IS,
			value: [roleID],
		};
	};

	/**
	 * Grouping filter for task without a role.
	 */
	const noRoleGroupingFilter = () => {
		return {
			field: GLOBAL_FILTER_FIELD.ROLE,
			operator: GLOBAL_FILTER_OPERATOR.IS,
		};
	};

	const dataRows = roles.map(role => {
		return {
			...role.node,
		};
	});

	/**
	 * Row for 'No-Role' tasks.
	 */
	if (company.taskTotals?.numberOfTasks) {
		dataRows.push({
			isNoRole: true,
			name: intl.formatMessage({id: 'common.no_role'}),
			taskTotals: company.taskTotals,
		});
	}

	const data = {
		rows: dataRows,
		currency,
		groupingFilter: rowData => (rowData.isNoRole ? noRoleGroupingFilter() : roleGroupingFilter(rowData.id)),
	};

	const fetchMore = () => {
		if (!modalTasksFetching) {
			const refetchObject = {};
			setModalTasksFetching(true);
			refetchObject.pageSize = roles.length + 30;
			relay.refetch(refetchObject, null, () => setModalTasksFetching(false));
		}
	};

	const roleRows = () => {
		return GroupRow(
			data,
			intl,
			rowIndex,
			searchQuery,
			groupings,
			enabledColumns,
			sortValue,
			hasNextPage,
			fetchMore,
			nextLevelProps,
			tableHeader,
			convertToProjectCurrency,
			projectCurrency
		);
	};

	return rowIndex === 1 ? (
		<BaseLevelWrapper
			tableHeader={tableHeader}
			intl={intl}
			enabledColumns={enabledColumns}
			getRows={roleRows}
			currentLength={roles.length}
			hasNextPage={viewer.company.allRolesGlobalSearch.pageInfo.hasNextPage}
			refetch={relay.refetch}
		/>
	) : (
		roleRows()
	);
};

const RoleRelayWrapperQuery = graphql`
	query RoleRelayWrapper_Query($searchQuery: TaskSearchQueryType, $pageSize: Int, $convertToProjectCurrency: Boolean) {
		viewer {
			actualPersonId
			component(name: "role_relay_wrapper")
			...RoleRelayWrapper_viewer
				@arguments(searchQuery: $searchQuery, pageSize: $pageSize, convertToProjectCurrency: $convertToProjectCurrency)
		}
	}
`;
export {RoleRelayWrapperQuery};

export default injectIntl(
	withRouter(
		createRefetchContainer(
			RoleRelayWrapper,
			{
				viewer: graphql`
					fragment RoleRelayWrapper_viewer on Viewer
					@argumentDefinitions(
						searchQuery: {type: "TaskSearchQueryType"}
						pageSize: {type: "Int"}
						convertToProjectCurrency: {type: "Boolean"}
					) {
						company {
							currency
							taskTotals(
								searchQuery: $searchQuery
								noRole: true
								convertToProjectCurrency: $convertToProjectCurrency
							) {
								registeredMinutes
								billableActualMinutes
								nonBillableActualMinutes
								estimatePoints
								estimateMinutes
								remainingPoints
								remainingMinutes
								plannedRevenue
								actualRevenue
								remainingRevenue
								forecastRevenue
								recognitionLockedRevenue
								recognitionOpenRevenue
								recognitionForecastRevenue
								totalActualRevenueRecognition
								totalForecastRevenueToComplete
								surplus
								numberOfTasks
							}
							allRolesGlobalSearch(first: $pageSize, searchQuery: $searchQuery) {
								pageInfo {
									endCursor
									hasNextPage
								}
								edges {
									...RoleDropdown_roles
									node {
										id
										name
										taskTotals(
											searchQuery: $searchQuery
											convertToProjectCurrency: $convertToProjectCurrency
										) {
											registeredMinutes
											billableActualMinutes
											nonBillableActualMinutes
											estimatePoints
											estimateMinutes
											remainingPoints
											remainingMinutes
											plannedRevenue
											actualRevenue
											remainingRevenue
											forecastRevenue
											recognitionLockedRevenue
											recognitionOpenRevenue
											recognitionForecastRevenue
											totalActualRevenueRecognition
											totalForecastRevenueToComplete
											surplus
											numberOfTasks
										}
									}
								}
							}
						}
					}
				`,
			},
			graphql`
				query RoleRelayWrapperRefetchQuery(
					$pageSize: Int
					$searchQuery: TaskSearchQueryType
					$convertToProjectCurrency: Boolean
				) {
					viewer {
						...RoleRelayWrapper_viewer
							@arguments(
								pageSize: $pageSize
								searchQuery: $searchQuery
								convertToProjectCurrency: $convertToProjectCurrency
							)
					}
				}
			`
		)
	)
);
