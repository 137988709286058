import {
	GROUP_TYPE,
	GROUP_SECTION_WIDTH,
	GROUP_SECTION_SPACING_LEVEL_FOUR,
	GROUP_SECTION_PADDING_LEFT,
	drawBorder,
	GROUP_SECTION_PROJECT_BORDER_WIDTH,
	GROUP_SECTION_TEXT_GREY,
	GROUP_SECTION_SPACING_LEVEL_TWO,
} from '../../canvas-timeline/canvas_timeline_util';
import Group from '../../canvas-timeline/canvas_timeline_group';
import Util from '../../../../forecast-app/shared/util/util';
import {SCHEDULING_VIEW} from '../../../../constants';
import {drawExpandedGroupHighlight} from '../../DrawingUtils';
import EventManager from '../../EventManager';

class NoContentGroup extends Group {
	constructor(pageComponent, data) {
		super(pageComponent, GROUP_TYPE.NO_CONTENT, data);
	}

	onItemCreate(startDate, endDate) {
		EventManager.onItemCreate(this.pageComponent, this, startDate, endDate);
	}

	draw(canvasContext, x, y) {
		const width = GROUP_SECTION_WIDTH;
		const {data, height} = this;
		super.draw(x, y, width, height, this);
		const {color, isInProjectGroup} = data;
		const {schedulingView, isProjectTimeline} = this.pageComponent.props;

		const isProjectsScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;
		const borderThickness = 1;

		if (isProjectsScheduling) {
			if (!isProjectTimeline) {
				drawExpandedGroupHighlight(canvasContext, x, y, height, width);
			}

			// right project color
			const drawRightBorder = !isInProjectGroup;
			if (drawRightBorder) {
				drawBorder(
					canvasContext,
					x + width - GROUP_SECTION_PROJECT_BORDER_WIDTH,
					y - 2 - borderThickness * 2,
					height + borderThickness * 2,
					true,
					color,
					GROUP_SECTION_PROJECT_BORDER_WIDTH
				);
			}
		}

		// placeholder text
		const fontSize = 11;
		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY;
		canvasContext.font = `500 ${fontSize}px ` + Util.getFontFamily();

		const offsetX = isInProjectGroup
			? GROUP_SECTION_SPACING_LEVEL_FOUR
			: GROUP_SECTION_SPACING_LEVEL_FOUR - GROUP_SECTION_PADDING_LEFT;
		const textX = isProjectsScheduling ? x + offsetX + GROUP_SECTION_PADDING_LEFT : x + GROUP_SECTION_SPACING_LEVEL_TWO;

		canvasContext.fillText(data.name, textX, y + height / 2 + fontSize / 3);
	}
}

export default NoContentGroup;
