import styled from 'styled-components';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const NumericTilesContainerStyle = styled.div`
	display: flex;
	margin-left: auto;
`;

export const NumericTilesStyle = styled.div`
	height: 30px;

	padding: 7px 0 7px 10px;
	font-size: 13px;
	text-align: right;
	color: ${CSS_CONSTANTS.v2_text_gray};

	&.error {
		border-radius: 4px;
		border: 1px solid ${CSS_CONSTANTS.negative_value};
	}

	&.negative {
		color: ${CSS_CONSTANTS.negative_value};
	}
`;

export const PopupContainer = styled.div`
	padding-top: 5px;
`;
