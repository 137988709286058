import {Checkbox} from 'web-components';
import Button from '../../../forecast-app/shared/components/buttons/button/button';
import {BUTTON_COLOR, BUTTON_STYLE} from '../../../constants';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
import KeepEmptyPlaceholder from '../components/KeepEmptyPlaceholder';
import EventManager from '../EventManager';

export default ({pageComponent}) => {
	const {deletePlaceholderOnSave, staffingPlaceholder, keepEmptyPlaceholder} = pageComponent.state;
	const data = pageComponent.getData();
	const {formatMessage} = useIntl();

	const hasBeta2Improvements = hasFeatureFlag('capacity_planning_beta_2_improvements');

	const placeholderAllocations = data.placeholderAllocationsByPlaceholder[staffingPlaceholder?.id];
	const noAllocationsLeft = placeholderAllocations === undefined || placeholderAllocations.length === 0;

	const [isOverridden, setOverridden] = useState(false);

	const checkAllocations = () => {
		if (!hasBeta2Improvements) {
			if (hasFeatureFlag('placeholders_beta_changes')) {
				pageComponent.setState({
					deletePlaceholderOnSave: false,
				});
			} else {
				if (!isOverridden) {
					if (deletePlaceholderOnSave !== noAllocationsLeft) {
						EventManager.onDeletePlaceholderOnSaveChange(pageComponent);
					}
				}
			}
		}
	};

	useEffect(() => {
		checkAllocations();
	}, [noAllocationsLeft]);

	const setManual = () => {
		if (hasBeta2Improvements) {
			pageComponent.setState({
				keepEmptyPlaceholder: !keepEmptyPlaceholder,
			});
		} else {
			setOverridden(true);
			EventManager.onDeletePlaceholderOnSaveChange(pageComponent);
		}
	};

	return (
		<div className="staffing-mode-footer">
			<div className="actions">
				{hasBeta2Improvements ? (
					<KeepEmptyPlaceholder isChecked={keepEmptyPlaceholder} changeIsChecked={setManual} />
				) : !hasFeatureFlag('placeholders_beta_changes') ? (
					<div className="checkbox-wrapper">
						<Checkbox checked={deletePlaceholderOnSave} onChange={setManual} />
						<span className="delete-placeholder-text">{formatMessage({id: 'placeholder.delete_placeholder'})}</span>
					</div>
				) : null}

				<Button
					text={formatMessage({id: 'common.exit'})}
					onClick={pageComponent.exitStaffingMode.bind(pageComponent)}
					buttonStyle={BUTTON_STYLE.FILLED}
					colorTheme={BUTTON_COLOR.WHITE}
				/>

				<Button
					text={formatMessage({id: 'scheduling.placeholders.staffing.save_and_exit'})}
					onClick={pageComponent.saveStaffingMode.bind(pageComponent)}
					buttonStyle={BUTTON_STYLE.FILLED}
					colorTheme={BUTTON_COLOR.GREEN_APPROVE}
				/>
			</div>
		</div>
	);
};
