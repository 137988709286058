import React from 'react';
import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
const styles = StyleSheet.create({
    viewSection: {
        padding: '16px',
    },
    viewLine: {
        flexDirection: 'row',
        paddingVertical: '8px',
        borderBottom: '1px solid #d3d3df',
    },
    viewLineNoBorder: {
        flexDirection: 'row',
        paddingVertical: '8px',
    },
    viewRow: {
        padding: '16px',
        flexDirection: 'row',
    },
    viewColumn: {
        flexDirection: 'column',
    },
    textMain: {
        color: '#232334',
        fontSize: '10px',
        fontFamily: 'Helvetica',
    },
    textMainBold: {
        color: '#232334',
        fontSize: '10px',
        fontFamily: 'Helvetica-Bold',
    },
    textAuxiliary: {
        color: '#6b6d7b',
        fontFamily: 'Helvetica-Bold',
        fontSize: '10px',
    },
    textHeader: {
        color: '#0004db',
        fontSize: '20px',
        fontFamily: 'Helvetica-Bold',
    },
    textHeaderSecondary: {
        color: '#0004db',
        fontSize: '16px',
        fontFamily: 'Helvetica-Bold',
    },
    textSubHeader: {
        color: '#0004db',
        fontSize: '14px',
        fontFamily: 'Helvetica-Bold',
    },
    textTotal: {
        color: '#0004db',
        fontSize: '12px',
        fontFamily: 'Helvetica-Bold',
    },
});
/**
 * These components could likely be re-used across future PDFs as well. Move to a global context if so.
 */
export const PageStandard = ({ style = {}, children }) => {
    return (React.createElement(Page, { size: "A4", style: [{ paddingVertical: '24px', paddingHorizontal: '16px' }, style] }, children));
};
export const TextMain = ({ bold = false, style = {}, children }) => {
    const textStyle = bold ? styles.textMainBold : styles.textMain;
    return React.createElement(Text, { style: [textStyle, style] }, children);
};
export const TextAuxiliary = ({ style = {}, children }) => {
    return React.createElement(Text, { style: [styles.textAuxiliary, style] }, children);
};
export const TextHeader = ({ style = {}, children }) => {
    return React.createElement(Text, { style: [styles.textHeader, style] }, children);
};
export const TextHeaderSecondary = ({ style = {}, children }) => {
    return React.createElement(Text, { style: [styles.textHeaderSecondary, style] }, children);
};
export const TextSubHeader = ({ style = {}, children }) => {
    return React.createElement(Text, { style: [styles.textSubHeader, style] }, children);
};
export const TextTotal = ({ style = {}, children }) => {
    return React.createElement(Text, { style: [styles.textTotal, style] }, children);
};
export const ViewSection = ({ style = {}, children }) => {
    return React.createElement(View, { style: [styles.viewSection, style] }, children);
};
export const ViewRow = ({ style = {}, children, fixed = false }) => {
    return (React.createElement(View, { fixed: fixed, style: [styles.viewRow, style] }, children));
};
export const ViewColumn = ({ style = {}, children }) => {
    return React.createElement(View, { style: [styles.viewColumn, style] }, children);
};
export const ViewLine = ({ noBorder = false, style = {}, children }) => {
    const lineStyle = noBorder ? styles.viewLineNoBorder : styles.viewLine;
    return React.createElement(View, { style: [lineStyle, style] }, children);
};
