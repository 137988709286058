import React from 'react';
import {createRefetchContainer, graphql} from 'react-relay';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {PeriodPage} from './Period';
import {MonthByMonthPage} from './MonthByMonth';
import {UTILIZATION_PAGES} from './UtilizationReport';
import {mapCompressedAggregatedResourceNumbers} from './UtilizationReportUtils';
import Util from '../../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

const UtilizationReportTotals = ({
	theEyeOptionsNestedTree,
	startDate,
	endDate,
	filters,
	showResourceUtilization,
	setCsvDownloadFunction,
	groupBy,
	allocationControlsOptions,
	viewer,
	viewer: {
		company: {
			companyTarget,
			isUsingProjectAllocation,
			isUsingSchedulingPlanMode,
			aggregatedResourceNumbersByDay: compressedAggregatedResourceNumbersByDay,
			aggregatedResourceNumbersByMonth: compressedAggregatedResourceNumbersByMonth,
			resourceNumbers: totalResourceNumbers,
		},
	},
	intl,
}) => {
	const isMixedAllocationModeEnabled = Util.isMixedAllocationModeEnabled(viewer.company);
	const isUsingAllocations = isUsingProjectAllocation || isMixedAllocationModeEnabled;
	const {url} = useRouteMatch();
	const aggregatedResourceNumbersByDay = mapCompressedAggregatedResourceNumbers(compressedAggregatedResourceNumbersByDay, [
		'availableMinutes',
		'forecastBillableProjectMinutes',
		'forecastBillableProjectMinutesWin',
		'forecastBillableProjectMinutesSoft',
		'forecastBillableProjectMinutesSoftWin',
		'forecastBillableProjectMinutesHard',
		'forecastTotalMinutes',
		'forecastTotalMinutesWin',
		'forecastTotalMinutesSoft',
		'forecastTotalMinutesSoftWin',
		'forecastTotalMinutesHard',
		'forecastBillableTaskAndAllocationsCombined',
		'forecastBillableTaskAndAllocationsCombinedWin',
		'forecastBillableTaskAndAllocationsCombinedSoft',
		'forecastBillableTaskAndAllocationsCombinedSoftWin',
		'forecastBillableTaskAndAllocationsCombinedHard',
		'forecastTotalTimeTaskAndAllocationsCombined',
		'forecastTotalTimeTaskAndAllocationsCombinedWin',
		'forecastTotalTimeTaskAndAllocationsCombinedSoft',
		'forecastTotalTimeTaskAndAllocationsCombinedSoftWin',
		'forecastTotalTimeTaskAndAllocationsCombinedHard',
	]);
	const aggregatedResourceNumbersByMonth = mapCompressedAggregatedResourceNumbers(
		compressedAggregatedResourceNumbersByMonth,
		[
			'forecastBillableUtilization',
			'forecastBillableUtilizationWin',
			'forecastBillableUtilizationSoft',
			'forecastBillableUtilizationSoftWin',
			'forecastBillableUtilizationHard',
			'forecastResourceUtilization',
			'forecastResourceUtilizationWin',
			'forecastResourceUtilizationSoft',
			'forecastResourceUtilizationSoftWin',
			'forecastResourceUtilizationHard',
			'forecastBillableUtilizationTaskAndAllocationsCombined',
			'forecastBillableUtilizationTaskAndAllocationsCombinedWin',
			'forecastBillableUtilizationTaskAndAllocationsCombinedSoft',
			'forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin',
			'forecastBillableUtilizationTaskAndAllocationsCombinedHard',
			'forecastResourceUtilizationTaskAndAllocationsCombined',
			'forecastResourceUtilizationTaskAndAllocationsCombinedWin',
			'forecastResourceUtilizationTaskAndAllocationsCombinedSoft',
			'forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin',
			'forecastResourceUtilizationTaskAndAllocationsCombinedHard',
		]
	);

	return (
		<>
			<Switch>
				<Route path={`${url}`} exact>
					<Redirect to={`${url}/${UTILIZATION_PAGES.PERIOD}`} />
				</Route>
				<Route
					path={`${url}/${UTILIZATION_PAGES.PERIOD}`}
					exact
					render={() => (
						<PeriodPage
							theEyeOptionsNestedTree={theEyeOptionsNestedTree}
							startDate={startDate}
							endDate={endDate}
							filters={filters}
							aggregatedResourceNumbersByDay={aggregatedResourceNumbersByDay}
							totalResourceNumbers={totalResourceNumbers}
							companyTarget={companyTarget}
							showResourceUtilization={showResourceUtilization}
							isUsingAllocations={isUsingAllocations}
							isUsingSchedulingPlanMode={isUsingSchedulingPlanMode}
							setCsvDownloadFunction={setCsvDownloadFunction}
							intl={intl}
							groupBy={groupBy}
							allocationControlsOptions={allocationControlsOptions}
							isMixedAllocationModeEnabled={
								isMixedAllocationModeEnabled && hasFeatureFlag('combined_heatmap_logic_extensions')
							}
						/>
					)}
				/>
				<Route
					path={`${url}/${UTILIZATION_PAGES.MONTH_BY_MONTH}`}
					exact
					render={() => (
						<MonthByMonthPage
							startDate={startDate}
							endDate={endDate}
							filters={filters}
							aggregatedResourceNumbersByDay={aggregatedResourceNumbersByDay}
							aggregatedResourceNumbersByMonth={aggregatedResourceNumbersByMonth}
							totalResourceNumbers={totalResourceNumbers}
							companyTarget={companyTarget}
							showResourceUtilization={showResourceUtilization}
							setCsvDownloadFunction={setCsvDownloadFunction}
							intl={intl}
							groupBy={groupBy}
							isMixedAllocationModeEnabled={
								isMixedAllocationModeEnabled && hasFeatureFlag('combined_heatmap_logic_extensions')
							}
							allocationControlsOptions={allocationControlsOptions}
						/>
					)}
				/>
			</Switch>
		</>
	);
};

const UtilizationReportTotalsQuery = graphql`
	query UtilizationReportTotals_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$searchQuery: TaskSearchQueryType!
	) {
		viewer {
			actualPersonId
			component(name: "utilization_report_totals")
			...UtilizationReportTotals_viewer
				@arguments(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
				)
		}
	}
`;

export {UtilizationReportTotalsQuery};

export default createRefetchContainer(
	UtilizationReportTotals,
	{
		viewer: graphql`
			fragment UtilizationReportTotals_viewer on Viewer
			@argumentDefinitions(
				searchQuery: {type: "TaskSearchQueryType!"}
				startYear: {type: "Int"}
				startMonth: {type: "Int"}
				startDay: {type: "Int"}
				endYear: {type: "Int"}
				endMonth: {type: "Int"}
				endDay: {type: "Int"}
			) {
				company {
					companyTarget
					isUsingMixedAllocation
					isUsingProjectAllocation
					resourceNumbers(
						searchQuery: $searchQuery
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
					) {
						# Actuals
						actualBillableProjectMinutes
						actualNonBillableProjectMinutes
						actualInternalMinutes
						actualTotalMinutes
						actualOverMinutes
						actualDoneTaskMinutes
						actualBillableUtilization
						actualNonBillableUtilization
						actualInternalUtilization
						actualResourceUtilization

						# Plan
						plannedBillableProjectMinutes
						plannedBillableProjectMinutesWin
						plannedBillableProjectMinutesSoft
						plannedBillableProjectMinutesSoftWin
						plannedBillableProjectMinutesHard
						plannedNonBillableProjectMinutes
						plannedNonBillableProjectMinutesWin
						plannedNonBillableProjectMinutesSoft
						plannedNonBillableProjectMinutesSoftWin
						plannedNonBillableProjectMinutesHard
						plannedInternalMinutes
						plannedTotalMinutes
						plannedTotalMinutesWin
						plannedTotalMinutesSoft
						plannedTotalMinutesSoftWin
						plannedTotalMinutesHard
						plannedOverMinutes
						plannedOverMinutesWin
						plannedOverMinutesSoft
						plannedOverMinutesSoftWin
						plannedOverMinutesHard
						plannedDoneTaskMinutes
						plannedBillableUtilization
						plannedBillableUtilizationWin
						plannedBillableUtilizationSoft
						plannedBillableUtilizationSoftWin
						plannedBillableUtilizationHard
						plannedNonBillableUtilization
						plannedNonBillableUtilizationWin
						plannedNonBillableUtilizationSoft
						plannedNonBillableUtilizationSoftWin
						plannedNonBillableUtilizationHard
						plannedInternalUtilization
						plannedResourceUtilization
						plannedResourceUtilizationWin
						plannedResourceUtilizationSoft
						plannedResourceUtilizationSoftWin
						plannedResourceUtilizationHard
						plannedBillableTaskAndAllocationsCombined
						plannedNonBillableTaskAndAllocationsCombined
						plannedInternalTimeTaskAndAllocationsCombined
						plannedTotalTimeTaskAndAllocationsCombined
						plannedBillableUtilizationTaskAndAllocationsCombined
						plannedNonBillableUtilizationTaskAndAllocationsCombined
						plannedInternalUtilizationTaskAndAllocationsCombined
						plannedResourceUtilizationTaskAndAllocationsCombined
						plannedBillableTaskAndAllocationsCombinedWin
						plannedNonBillableTaskAndAllocationsCombinedWin
						plannedTotalTimeTaskAndAllocationsCombinedWin
						plannedBillableUtilizationTaskAndAllocationsCombinedWin
						plannedNonBillableUtilizationTaskAndAllocationsCombinedWin
						plannedResourceUtilizationTaskAndAllocationsCombinedWin
						plannedBillableTaskAndAllocationsCombinedSoft
						plannedNonBillableTaskAndAllocationsCombinedSoft
						plannedTotalTimeTaskAndAllocationsCombinedSoft
						plannedBillableUtilizationTaskAndAllocationsCombinedSoft
						plannedNonBillableUtilizationTaskAndAllocationsCombinedSoft
						plannedResourceUtilizationTaskAndAllocationsCombinedSoft
						plannedBillableTaskAndAllocationsCombinedSoftWin
						plannedNonBillableTaskAndAllocationsCombinedSoftWin
						plannedTotalTimeTaskAndAllocationsCombinedSoftWin
						plannedBillableUtilizationTaskAndAllocationsCombinedSoftWin
						plannedNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
						plannedResourceUtilizationTaskAndAllocationsCombinedSoftWin
						plannedBillableTaskAndAllocationsCombinedHard
						plannedNonBillableTaskAndAllocationsCombinedHard
						plannedTotalTimeTaskAndAllocationsCombinedHard
						plannedBillableUtilizationTaskAndAllocationsCombinedHard
						plannedNonBillableUtilizationTaskAndAllocationsCombinedHard
						plannedResourceUtilizationTaskAndAllocationsCombinedHard

						# Actuals vs. Plan
						billableActualVsPlan
						billableActualVsPlanWin
						billableActualVsPlanSoft
						billableActualVsPlanSoftWin
						billableActualVsPlanHard
						nonBillableActualVsPlan
						nonBillableActualVsPlanWin
						nonBillableActualVsPlanSoft
						nonBillableActualVsPlanSoftWin
						nonBillableActualVsPlanHard
						internalActualVsPlan
						totalActualVsPlan
						totalActualVsPlanWin
						totalActualVsPlanSoft
						totalActualVsPlanSoftWin
						totalActualVsPlanHard

						# Remaining
						remainingBillableProjectMinutes
						remainingBillableProjectMinutesWin
						remainingBillableProjectMinutesSoft
						remainingBillableProjectMinutesSoftWin
						remainingBillableProjectMinutesHard
						remainingNonBillableProjectMinutes
						remainingNonBillableProjectMinutesWin
						remainingNonBillableProjectMinutesSoft
						remainingNonBillableProjectMinutesSoftWin
						remainingNonBillableProjectMinutesHard
						remainingInternalMinutes
						remainingTotalMinutes
						remainingTotalMinutesWin
						remainingTotalMinutesSoft
						remainingTotalMinutesSoftWin
						remainingTotalMinutesHard
						remainingOverMinutes
						remainingBillableUtilization
						remainingBillableUtilizationWin
						remainingBillableUtilizationSoft
						remainingBillableUtilizationSoftWin
						remainingBillableUtilizationHard
						remainingNonBillableUtilization
						remainingNonBillableUtilizationWin
						remainingNonBillableUtilizationSoft
						remainingNonBillableUtilizationSoftWin
						remainingNonBillableUtilizationHard
						remainingInternalUtilization
						remainingResourceUtilization
						remainingResourceUtilizationWin
						remainingResourceUtilizationSoft
						remainingResourceUtilizationSoftWin
						remainingResourceUtilizationHard
						remainingBillableTaskAndAllocationsCombined
						remainingNonBillableTaskAndAllocationsCombined
						remainingInternalTimeTaskAndAllocationsCombined
						remainingTotalTimeTaskAndAllocationsCombined
						remainingBillableUtilizationTaskAndAllocationsCombined
						remainingNonBillableUtilizationTaskAndAllocationsCombined
						remainingInternalUtilizationTaskAndAllocationsCombined
						remainingResourceUtilizationTaskAndAllocationsCombined
						remainingBillableTaskAndAllocationsCombinedWin
						remainingNonBillableTaskAndAllocationsCombinedWin
						remainingTotalTimeTaskAndAllocationsCombinedWin
						remainingBillableUtilizationTaskAndAllocationsCombinedWin
						remainingNonBillableUtilizationTaskAndAllocationsCombinedWin
						remainingResourceUtilizationTaskAndAllocationsCombinedWin
						remainingBillableTaskAndAllocationsCombinedSoft
						remainingNonBillableTaskAndAllocationsCombinedSoft
						remainingTotalTimeTaskAndAllocationsCombinedSoft
						remainingBillableUtilizationTaskAndAllocationsCombinedSoft
						remainingNonBillableUtilizationTaskAndAllocationsCombinedSoft
						remainingResourceUtilizationTaskAndAllocationsCombinedSoft
						remainingBillableTaskAndAllocationsCombinedSoftWin
						remainingNonBillableTaskAndAllocationsCombinedSoftWin
						remainingTotalTimeTaskAndAllocationsCombinedSoftWin
						remainingBillableUtilizationTaskAndAllocationsCombinedSoftWin
						remainingNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
						remainingResourceUtilizationTaskAndAllocationsCombinedSoftWin
						remainingBillableTaskAndAllocationsCombinedHard
						remainingNonBillableTaskAndAllocationsCombinedHard
						remainingTotalTimeTaskAndAllocationsCombinedHard
						remainingBillableUtilizationTaskAndAllocationsCombinedHard
						remainingNonBillableUtilizationTaskAndAllocationsCombinedHard
						remainingResourceUtilizationTaskAndAllocationsCombinedHard

						# Forecast
						forecastBillableProjectMinutes
						forecastBillableProjectMinutesWin
						forecastBillableProjectMinutesSoft
						forecastBillableProjectMinutesSoftWin
						forecastBillableProjectMinutesHard
						forecastNonBillableProjectMinutes
						forecastNonBillableProjectMinutesWin
						forecastNonBillableProjectMinutesSoft
						forecastNonBillableProjectMinutesSoftWin
						forecastNonBillableProjectMinutesHard
						forecastInternalMinutes
						forecastTotalMinutes
						forecastTotalMinutesWin
						forecastTotalMinutesSoft
						forecastTotalMinutesSoftWin
						forecastTotalMinutesHard
						forecastOverMinutes
						forecastOverMinutesWin
						forecastOverMinutesSoft
						forecastOverMinutesSoftWin
						forecastOverMinutesHard
						forecastBillableUtilization
						forecastBillableUtilizationWin
						forecastBillableUtilizationSoft
						forecastBillableUtilizationSoftWin
						forecastBillableUtilizationHard
						forecastNonBillableUtilization
						forecastNonBillableUtilizationWin
						forecastNonBillableUtilizationSoft
						forecastNonBillableUtilizationSoftWin
						forecastNonBillableUtilizationHard
						forecastInternalUtilization
						forecastResourceUtilization
						forecastResourceUtilizationWin
						forecastResourceUtilizationSoft
						forecastResourceUtilizationSoftWin
						forecastResourceUtilizationHard
						forecastBillableTaskAndAllocationsCombined
						forecastNonBillableTaskAndAllocationsCombined
						forecastInternalTimeTaskAndAllocationsCombined
						forecastTotalTimeTaskAndAllocationsCombined
						forecastBillableUtilizationTaskAndAllocationsCombined
						forecastNonBillableUtilizationTaskAndAllocationsCombined
						forecastInternalUtilizationTaskAndAllocationsCombined
						forecastResourceUtilizationTaskAndAllocationsCombined
						forecastBillableTaskAndAllocationsCombinedWin
						forecastNonBillableTaskAndAllocationsCombinedWin
						forecastTotalTimeTaskAndAllocationsCombinedWin
						forecastBillableUtilizationTaskAndAllocationsCombinedWin
						forecastNonBillableUtilizationTaskAndAllocationsCombinedWin
						forecastResourceUtilizationTaskAndAllocationsCombinedWin
						forecastBillableTaskAndAllocationsCombinedSoft
						forecastNonBillableTaskAndAllocationsCombinedSoft
						forecastTotalTimeTaskAndAllocationsCombinedSoft
						forecastBillableUtilizationTaskAndAllocationsCombinedSoft
						forecastNonBillableUtilizationTaskAndAllocationsCombinedSoft
						forecastResourceUtilizationTaskAndAllocationsCombinedSoft
						forecastBillableTaskAndAllocationsCombinedSoftWin
						forecastNonBillableTaskAndAllocationsCombinedSoftWin
						forecastTotalTimeTaskAndAllocationsCombinedSoftWin
						forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin
						forecastNonBillableUtilizationTaskAndAllocationsCombinedSoftWin
						forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin
						forecastBillableTaskAndAllocationsCombinedHard
						forecastNonBillableTaskAndAllocationsCombinedHard
						forecastTotalTimeTaskAndAllocationsCombinedHard
						forecastBillableUtilizationTaskAndAllocationsCombinedHard
						forecastNonBillableUtilizationTaskAndAllocationsCombinedHard
						forecastResourceUtilizationTaskAndAllocationsCombinedHard

						# Tasks estimates (Planned)
						tasksEstimateBillableMinutes
						tasksEstimateNonBillableMinutes
						tasksEstimateTotalMinutes
						tasksBillableUtilization
						tasksNonBillableUtilization
						tasksResourceUtilization
						tasksOverMinutes

						# Tasks Remaining
						tasksRemainingBillableMinutes
						tasksRemainingNonBillableMinutes
						tasksRemainingTotalMinutes
						tasksRemainingOverMinutes
						tasksRemainingBillableUtilization
						tasksRemainingNonBillableUtilization
						tasksRemainingResourceUtilization

						# Tasks Forecast
						tasksForecastBillableMinutes
						tasksForecastNonBillableMinutes
						tasksForecastTotalMinutes
						tasksForecastOverMinutes
						tasksForecastBillableUtilization
						tasksForecastNonBillableUtilization
						tasksForecastResourceUtilization

						# Tasks Actual vs. Plan
						tasksBillableActualVsPlan
						tasksNonBillableActualVsPlan
						tasksTotalActualVsPlan

						# Planned Allocation vs. Task
						plannedBillableAllocationVsTask
						plannedBillableAllocationVsTaskWin
						plannedBillableAllocationVsTaskSoft
						plannedBillableAllocationVsTaskSoftWin
						plannedBillableAllocationVsTaskHard
						plannedNonBillableAllocationVsTask
						plannedNonBillableAllocationVsTaskWin
						plannedNonBillableAllocationVsTaskSoft
						plannedNonBillableAllocationVsTaskSoftWin
						plannedNonBillableAllocationVsTaskHard
						plannedAllocationVsTask
						plannedAllocationVsTaskWin
						plannedAllocationVsTaskSoft
						plannedAllocationVsTaskSoftWin
						plannedAllocationVsTaskHard

						# Remaining Allocation vs. Task
						remainingBillableAllocationVsTask
						remainingBillableAllocationVsTaskWin
						remainingBillableAllocationVsTaskSoft
						remainingBillableAllocationVsTaskSoftWin
						remainingBillableAllocationVsTaskHard
						remainingNonBillableAllocationVsTask
						remainingNonBillableAllocationVsTaskWin
						remainingNonBillableAllocationVsTaskSoft
						remainingNonBillableAllocationVsTaskSoftWin
						remainingNonBillableAllocationVsTaskHard
						remainingAllocationVsTask
						remainingAllocationVsTaskWin
						remainingAllocationVsTaskSoft
						remainingAllocationVsTaskSoftWin
						remainingAllocationVsTaskHard

						# Availability
						workingMinutes
						timeOffMinutes
						availableMinutes

						#Performance
						performance
					}
					aggregatedResourceNumbersByDay: aggregatedResourceNumbers(
						searchQuery: $searchQuery
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
						aggregates: [
							"availableMinutes"
							"forecastBillableProjectMinutes"
							"forecastBillableProjectMinutesWin"
							"forecastBillableProjectMinutesSoft"
							"forecastBillableProjectMinutesSoftWin"
							"forecastBillableProjectMinutesHard"
							"forecastTotalMinutes"
							"forecastTotalMinutesWin"
							"forecastTotalMinutesSoft"
							"forecastTotalMinutesSoftWin"
							"forecastTotalMinutesHard"
							"forecastBillableTaskAndAllocationsCombined"
							"forecastBillableTaskAndAllocationsCombinedWin"
							"forecastBillableTaskAndAllocationsCombinedSoft"
							"forecastBillableTaskAndAllocationsCombinedSoftWin"
							"forecastBillableTaskAndAllocationsCombinedHard"
							"forecastTotalTimeTaskAndAllocationsCombined"
							"forecastTotalTimeTaskAndAllocationsCombinedWin"
							"forecastTotalTimeTaskAndAllocationsCombinedSoft"
							"forecastTotalTimeTaskAndAllocationsCombinedSoftWin"
							"forecastTotalTimeTaskAndAllocationsCombinedHard"
						]
						aggregateLevel: DAY
					)
					aggregatedResourceNumbersByMonth: aggregatedResourceNumbers(
						searchQuery: $searchQuery
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
						aggregates: [
							"forecastBillableUtilization"
							"forecastBillableUtilizationWin"
							"forecastBillableUtilizationSoft"
							"forecastBillableUtilizationSoftWin"
							"forecastBillableUtilizationHard"
							"forecastResourceUtilization"
							"forecastResourceUtilizationWin"
							"forecastResourceUtilizationSoft"
							"forecastResourceUtilizationSoftWin"
							"forecastResourceUtilizationHard"
							"forecastBillableUtilizationTaskAndAllocationsCombined"
							"forecastBillableUtilizationTaskAndAllocationsCombinedWin"
							"forecastBillableUtilizationTaskAndAllocationsCombinedSoft"
							"forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin"
							"forecastBillableUtilizationTaskAndAllocationsCombinedHard"
							"forecastResourceUtilizationTaskAndAllocationsCombined"
							"forecastResourceUtilizationTaskAndAllocationsCombinedWin"
							"forecastResourceUtilizationTaskAndAllocationsCombinedSoft"
							"forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin"
							"forecastResourceUtilizationTaskAndAllocationsCombinedHard"
						]
						aggregateLevel: MONTH
					)
				}
			}
		`,
	},
	graphql`
		query UtilizationReportTotalsRefetchQuery(
			$startYear: Int
			$startMonth: Int
			$startDay: Int
			$endYear: Int
			$endMonth: Int
			$endDay: Int
			$searchQuery: TaskSearchQueryType!
		) {
			viewer {
				...UtilizationReportTotals_viewer
					@arguments(
						startYear: $startYear
						startMonth: $startMonth
						startDay: $startDay
						endYear: $endYear
						endMonth: $endMonth
						endDay: $endDay
						searchQuery: $searchQuery
					)
			}
		}
	`
);
