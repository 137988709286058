import {createFragmentContainer, graphql} from 'react-relay';
import {decompressData} from '../util/DataDecompressUtil';
import {getCurrency} from '../util/BudgetUtils';
import {useState} from 'react';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';

const RevenueRecognitionLoader = ({
	viewer,
	aggregateLevel,
	aggregates,
	expenseAggregates,
	children,
	retry,
	timestamp,
	hasExpenses,
}) => {
	const [financialQueryTimestamp, setFinancialQueryTimestamp] = useState(timestamp);
	if (timestamp !== financialQueryTimestamp) {
		setFinancialQueryTimestamp(timestamp);
		retry();
	}
	const {
		aggregatedFinancialNumbers,
		financialNumbers,
		workAggregatedFinancialNumbers,
		workFinancialNumbers,
		expenseAggregatedFinancialNumbers,
		expenseFinancialNumbers,
	} = viewer.project;
	const currency = getCurrency(viewer.company, viewer.project);
	const processedData = decompressData(
		aggregatedFinancialNumbers,
		financialNumbers,
		currency,
		aggregateLevel,
		aggregates,
		false
	);
	const rows = [...processedData.rows];

	const recognitionAmountIndex = rows.findIndex(row => row.aggregate === 'recognitionAmount');
	rows[recognitionAmountIndex].aggregate = 'recognitionTotal';
	rows.splice(recognitionAmountIndex + 1, 0, {dataArray: []});

	if (hasExpenses) {
		const processedWorkData = decompressData(
			workAggregatedFinancialNumbers,
			workFinancialNumbers,
			currency,
			aggregateLevel,
			aggregates,
			false
		);
		const processedExpenseData = decompressData(
			expenseAggregatedFinancialNumbers,
			expenseFinancialNumbers,
			currency,
			aggregateLevel,
			expenseAggregates,
			false
		);

		const workRow = processedWorkData.rows[0];

		let expenseRow;
		if (hasFeatureFlag('Expense_Modal_Update_22Q3') && !hasFeatureFlag('Expense_Modal_Update_23Q1')) {
			expenseRow = processedExpenseData.rows.find(row => row.aggregate === 'allTotalTimeAndExpensesAtCompletion');
		} else {
			expenseRow = processedExpenseData.rows.find(row => row.aggregate === 'billableTotalTimeAndExpensesAtCompletion');
		}
		expenseRow.aggregate = 'expenses';

		rows.splice(recognitionAmountIndex + 1, 0, expenseRow);
		rows.splice(recognitionAmountIndex + 1, 0, workRow);
	}

	const result = {data: {rows: rows, columns: processedData.columns}};

	return children(result);
};

export const revenueRecognitionLoaderQuery = graphql`
	query RevenueRecognitionLoader_Query(
		$projectId: ID!
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$aggregateLevel: AggregateLevel!
		$aggregates: [FinancialAggregatedNumber]!
		$searchQuery: TaskSearchQueryType
		$expenseAggregates: [FinancialAggregatedNumber]!
		$expenseSearchQuery: TaskSearchQueryType
		$hasExpenses: Boolean!
	) {
		viewer {
			actualPersonId
			component(name: "revenue_recognition_loader")
			...RevenueRecognitionLoader_viewer
				@arguments(
					projectId: $projectId
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: $aggregates
					searchQuery: $searchQuery
					expenseAggregates: $expenseAggregates
					expenseSearchQuery: $expenseSearchQuery
					hasExpenses: $hasExpenses
				)
		}
	}
`;

export default createFragmentContainer(RevenueRecognitionLoader, {
	viewer: graphql`
		fragment RevenueRecognitionLoader_viewer on Viewer
		@argumentDefinitions(
			projectId: {type: "ID!"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
			aggregateLevel: {type: "AggregateLevel!"}
			aggregates: {type: "[FinancialAggregatedNumber]!"}
			searchQuery: {type: "TaskSearchQueryType"}
			expenseAggregates: {type: "[FinancialAggregatedNumber]!"}
			expenseSearchQuery: {type: "TaskSearchQueryType"}
			hasExpenses: {type: "Boolean!"}
		) {
			id
			company {
				currency
			}
			project(internalId: $projectId) {
				rateCard {
					currency
				}
				aggregatedFinancialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: $aggregates
					convertToProjectCurrency: true
					addAccumulatedNumbers: true
				)
				financialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					convertToProjectCurrency: true
				) {
					recognitionAmount
					estimatedCost
					recognitionProfit
					recognitionProfitPercentage
				}

				workAggregatedFinancialNumbers: aggregatedFinancialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: $aggregates
					convertToProjectCurrency: true
					addAccumulatedNumbers: true
					searchQuery: $searchQuery
				) @include(if: $hasExpenses)
				workFinancialNumbers: financialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					convertToProjectCurrency: true
					searchQuery: $searchQuery
				) @include(if: $hasExpenses) {
					recognitionAmount
				}

				expenseAggregatedFinancialNumbers: aggregatedFinancialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: $expenseAggregates
					convertToProjectCurrency: true
					addAccumulatedNumbers: true
					searchQuery: $expenseSearchQuery
				) @include(if: $hasExpenses)
				expenseFinancialNumbers: financialNumbers(
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					convertToProjectCurrency: true
					searchQuery: $expenseSearchQuery
				) @include(if: $hasExpenses) {
					billableTotalTimeAndExpensesAtCompletion
					allTotalTimeAndExpensesAtCompletion
				}
			}
		}
	`,
});
