import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TooltipContainer from '../../forecast-app/shared/components/tooltips/tooltip_container';
import {injectIntl} from 'react-intl';

class ViewChanger extends Component {
	render() {
		return (
			<TooltipContainer
				infoText={
					this.props.currentView.includes('grid')
						? this.props.intl.formatMessage({id: 'common.view_toggle_grid'})
						: this.props.intl.formatMessage({id: 'common.view_toggle_list'})
				}
			>
				<button
					key={'toggle-view-type-button'}
					className={'toggle-view-type-button ' + this.props.currentView}
					data-cy="view-type-button"
					onClick={this.props.onClick}
				/>
			</TooltipContainer>
		);
	}
}
ViewChanger.propTypes = {
	currentView: PropTypes.string,
	onClick: PropTypes.func,
};
export default injectIntl(ViewChanger);
