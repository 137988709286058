import React, {useMemo} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {useIntl} from 'react-intl';
import moment from 'moment';
import {Chart} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';
import {PROJECT_PORTFOLIO_SINGLE_NUMBER} from './ProjectPortfolioSingleNumberOptions';
import {remapAndFormatFinancialMessage} from '../../../forecast-app/shared/util/FinancialInternationalisationUtil';

const createDate = (dateString, aggregateLevel) => {
	switch (aggregateLevel) {
		case 'YEAR':
			return moment(dateString, 'YYYY-01-01');
		case 'MONTH':
			return moment(dateString, 'YYYY-MM-01');
		case 'DAY':
			return moment(dateString, 'YYYY-MM-DD');
		default:
			throw new Error(`Unsupported aggregateLevel ${aggregateLevel}`);
	}
};

const mapAggregatedFinancialNumbers = (allAggregatedFinancialNumbers, aggregateLevel, aggregates) => {
	return allAggregatedFinancialNumbers.map(aggregatedFinancialNumbers => {
		const split = aggregatedFinancialNumbers.split(',');
		const mappedObject = {
			start: createDate(split[0], aggregateLevel),
			end: createDate(split[1], aggregateLevel),
		};
		for (let i = 0; i < aggregates.length; i++) {
			mappedObject[aggregates[i]] = split[i + 2];
		}
		return mappedObject;
	});
};

const rollOutFinancialNumbers = (mappedFinancialNumbers, aggregateLevel, aggregates) => {
	const rolledOutFinancialNumbers = [];

	const accumulatedFinancialNumbers = {};
	const accumulate = financialNumbers => {
		aggregates.forEach(aggregate => {
			const value = +financialNumbers[aggregate];
			accumulatedFinancialNumbers[aggregate] = (accumulatedFinancialNumbers[aggregate] || 0) + value;
		});

		return {...accumulatedFinancialNumbers};
	};

	mappedFinancialNumbers.forEach(financialNumbers => {
		let date = financialNumbers.start.clone();
		while (date.isSameOrBefore(financialNumbers.end)) {
			const financialNumbersOnDate = accumulate(financialNumbers);
			financialNumbersOnDate.date = createDate(date.format('YYYY-MM-DD'), aggregateLevel);
			rolledOutFinancialNumbers.push(financialNumbersOnDate);

			switch (aggregateLevel) {
				case 'DAY':
					date.add(1, 'day');
					break;
				case 'MONTH':
					date.add(1, 'month');
					break;
				case 'YEAR':
					date.add(1, 'year');
					break;
				default:
					throw new Error(`Unsupported aggregateLevel ${aggregateLevel}`);
			}
		}
	});

	return rolledOutFinancialNumbers;
};

/**
 * For Chart.js to render the lines correctly, it is required to align the data sets.
 * F.ex. all data sets should have an equal amount of points, and if one data set contains
 * a date than isn't in another, then a null entry must be used as a replacement.
 */
const alignDataSets = dataSets => {
	const allDates = [];
	dataSets.forEach(dataSet => {
		dataSet.data.forEach(dataItem => {
			const dateString = dataItem.date.format('YYYY-MM-DD');
			if (allDates.indexOf(dateString) === -1) {
				allDates.push(dateString);
			}
		});
	});
	allDates.sort();

	dataSets.forEach(dataSet => {
		const lookUpMap = dataSet.data.reduce((map, dataItem) => {
			const dateString = dataItem.date.format('YYYY-MM-DD');
			map[dateString] = dataItem;
			return map;
		}, {});

		dataSet.data = allDates.map(
			dateString => lookUpMap[dateString] || {date: moment(dateString, 'YYYY-MM-DD'), value: null}
		);
	});
	return dataSets;
};

const createDataSets = (selectedSingleValue, rolledOutFinancialNumbers) => {
	const formatMessage = remapAndFormatFinancialMessage;
	const dataSets = [];

	const now = moment();
	switch (selectedSingleValue) {
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_ACTUAL_TIME_AND_EXPENSES:
			const billableActualTimeAndExpensesDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.billableActualTimeAndExpenses,
			}));
			dataSets.push({
				name: formatMessage({id: 'project_budget.actual_revenue'}),
				color: '#8AC7FF',
				filled: true,
				backgroundColor: 'transparent',
				data: billableActualTimeAndExpensesDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_COST:
			const actualCostDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.actualCost,
			}));
			dataSets.push({
				name: formatMessage({id: 'common.actual_cost'}),
				color: '#8AC7FF',
				filled: true,
				backgroundColor: 'transparent',
				data: actualCostDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.ACTUAL_PROFIT:
			const actualProfitDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.actualProfit,
			}));
			dataSets.push({
				name: formatMessage({id: 'project_budget.actual_profit'}),
				color: '#8AC7FF',
				filled: true,
				backgroundColor: 'transparent',
				data: actualProfitDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_REVENUE:
			const planRevenueDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.billablePlannedTimeAndExpenses,
			}));
			dataSets.push({
				name: formatMessage({id: 'project_budget.plan_revenue'}),
				color: '#7fe5b2',
				filled: true,
				backgroundColor: 'transparent',
				data: planRevenueDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_COST:
			const planCostDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.plannedCost,
			}));
			dataSets.push({
				name: formatMessage({id: 'project_budget.plan_cost'}),
				color: '#7fe5b2',
				filled: true,
				backgroundColor: 'transparent',
				data: planCostDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.PLAN_PROFIT:
			const planProfitDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.plannedProfit,
			}));
			dataSets.push({
				name: formatMessage({id: 'project_budget.plan_profit'}),
				color: '#7fe5b2',
				filled: true,
				backgroundColor: 'transparent',
				data: planProfitDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_FORECAST_TIME_AND_EXPENSES_TO_COMPLETE:
			const billableForecastTimeAndExpensesToCompleteDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.billableForecastTimeAndExpensesToComplete,
			}));
			dataSets.push({
				name: formatMessage({id: 'project_budget.remaining_revenue'}),
				color: '#ffaf4d',
				filled: true,
				backgroundColor: 'transparent',
				data: billableForecastTimeAndExpensesToCompleteDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_COST_TO_COMPLETE:
			const forecastCostToCompleteDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.forecastCostToComplete,
			}));
			dataSets.push({
				name: formatMessage({id: 'project_budget.remaining_cost'}),
				color: '#ffaf4d',
				filled: true,
				backgroundColor: 'transparent',
				data: forecastCostToCompleteDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.FORECAST_PROFIT_TO_COMPLETE:
			const forecastProfitToCompleteDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.forecastProfitToComplete,
			}));
			dataSets.push({
				name: formatMessage({id: 'project_budget.remaining_profit'}),
				color: '#ffaf4d',
				filled: true,
				backgroundColor: 'transparent',
				data: forecastProfitToCompleteDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.BILLABLE_TOTAL_TIME_AND_EXPENSES_AT_COMPLETION:
			const pastBillableActualTimeAndExpensesDataset = rolledOutFinancialNumbers
				.filter(financialNumbers => financialNumbers.date.isSameOrBefore(now))
				.map(financialNumbers => ({
					date: financialNumbers.date,
					value: financialNumbers.billableActualTimeAndExpenses,
				}));

			let firstBillableTotalTimeAndExpensesAtCompletionValue;
			if (pastBillableActualTimeAndExpensesDataset.length > 0) {
				firstBillableTotalTimeAndExpensesAtCompletionValue = {
					...pastBillableActualTimeAndExpensesDataset[pastBillableActualTimeAndExpensesDataset.length - 1],
				};
			} else {
				firstBillableTotalTimeAndExpensesAtCompletionValue = {date: moment(), value: 0};
			}

			firstBillableTotalTimeAndExpensesAtCompletionValue.customData = {hideLabel: true};

			const billableTotalTimeAndExpensesAtCompletionDataset = [firstBillableTotalTimeAndExpensesAtCompletionValue];
			billableTotalTimeAndExpensesAtCompletionDataset.push(
				...rolledOutFinancialNumbers
					.filter(financialNumbers => financialNumbers.date.isAfter(now))
					.map(financialNumbers => ({
						date: financialNumbers.date,
						value: financialNumbers.billableTotalTimeAndExpensesAtCompletion,
					}))
			);
			dataSets.push({
				name: formatMessage({id: 'project_budget.forecast_revenue'}),
				color: '#CE93D8',
				filled: true,
				backgroundColor: 'transparent',
				dashed: true,
				data: billableTotalTimeAndExpensesAtCompletionDataset,
			});
			dataSets.push({
				name: formatMessage({id: 'project_budget.actual_revenue'}),
				color: '#8AC7FF',
				filled: true,
				backgroundColor: 'transparent',
				data: pastBillableActualTimeAndExpensesDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_COST_AT_COMPLETION:
			const pastActualCostDataset = rolledOutFinancialNumbers
				.filter(financialNumbers => financialNumbers.date.isSameOrBefore(now))
				.map(financialNumbers => ({date: financialNumbers.date, value: financialNumbers.actualCost}));
			let firstTotalCostAtCompletionValue;
			if (pastActualCostDataset.length > 0) {
				firstTotalCostAtCompletionValue = {...pastActualCostDataset[pastActualCostDataset.length - 1]};
			} else {
				firstTotalCostAtCompletionValue = {date: moment(), value: 0};
			}

			firstTotalCostAtCompletionValue.customData = {hideLabel: true};

			const totalCostAtCompletionDataset = [firstTotalCostAtCompletionValue];
			totalCostAtCompletionDataset.push(
				...rolledOutFinancialNumbers
					.filter(financialNumbers => financialNumbers.date.isAfter(now))
					.map(financialNumbers => ({date: financialNumbers.date, value: financialNumbers.totalCostAtCompletion}))
			);
			dataSets.push({
				name: formatMessage({id: 'project_budget.forecast_cost'}),
				color: '#CE93D8',
				filled: true,
				backgroundColor: 'transparent',
				dashed: true,
				data: totalCostAtCompletionDataset,
			});
			dataSets.push({
				name: formatMessage({id: 'common.actual_cost'}),
				color: '#8AC7FF',
				filled: true,
				backgroundColor: 'transparent',
				data: pastActualCostDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.TOTAL_PROFIT_AT_COMPLETION:
			const pastActualProfitDataset = rolledOutFinancialNumbers
				.filter(financialNumbers => financialNumbers.date.isSameOrBefore(now))
				.map(financialNumbers => ({date: financialNumbers.date, value: financialNumbers.actualProfit}));
			let firstTotalProfitAtCompletionValue;
			if (pastActualProfitDataset.length > 0) {
				firstTotalProfitAtCompletionValue = {...pastActualProfitDataset[pastActualProfitDataset.length - 1]};
			} else {
				firstTotalProfitAtCompletionValue = {date: moment(), value: 0};
			}

			firstTotalProfitAtCompletionValue.customData = {hideLabel: true};

			const totalProfitAtCompletionDataset = [firstTotalProfitAtCompletionValue];
			totalProfitAtCompletionDataset.push(
				...rolledOutFinancialNumbers
					.filter(financialNumbers => financialNumbers.date.isAfter(now))
					.map(financialNumbers => ({date: financialNumbers.date, value: financialNumbers.totalProfitAtCompletion}))
			);
			dataSets.push({
				name: formatMessage({id: 'project_budget.forecast_profit'}),
				color: '#CE93D8',
				filled: true,
				backgroundColor: 'transparent',
				dashed: true,
				data: totalProfitAtCompletionDataset,
			});
			dataSets.push({
				name: formatMessage({id: 'project_budget.actual_profit'}),
				color: '#8AC7FF',
				filled: true,
				backgroundColor: 'transparent',
				data: pastActualProfitDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.INVOICED:
			const invoicedDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.invoiced,
			}));
			dataSets.push({
				name: formatMessage({id: 'common.invoiced'}),
				color: '#FBE96A',
				filled: true,
				backgroundColor: 'transparent',
				data: invoicedDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.INVOICE_PAID:
			const invoicePaidDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.paid,
			}));
			dataSets.push({
				name: formatMessage({id: 'project_budget.invoice_paid'}),
				color: '#F98BD2',
				filled: true,
				backgroundColor: 'transparent',
				data: invoicePaidDataset,
			});
			break;
		case PROJECT_PORTFOLIO_SINGLE_NUMBER.RECOGNITION_AMOUNT:
			const recognitionAmountDataset = rolledOutFinancialNumbers.map(financialNumbers => ({
				date: financialNumbers.date,
				value: financialNumbers.recognitionAmount,
			}));
			dataSets.push({
				name: formatMessage({id: 'project_budget.recognition_amount'}),
				color: '#8AC7FF',
				filled: true,
				backgroundColor: 'transparent',
				data: recognitionAmountDataset,
			});
			break;
	}

	alignDataSets(dataSets);

	return dataSets;
};

const ProjectPortfolioReportCharts = ({
	aggregateLevel,
	aggregates,
	selectedSingleValue,
	startDate,
	endDate,
	viewer: {
		company: {currency, aggregatedFinancialNumbers},
	},
}) => {
	const intl = useIntl();
	const currencySymbol = Util.GetCurrencySymbol(currency);

	const rolledOutFinancialNumbers = useMemo(() => {
		const mappedFinancialNumbers = mapAggregatedFinancialNumbers(aggregatedFinancialNumbers, aggregateLevel, aggregates);
		return rollOutFinancialNumbers(mappedFinancialNumbers, aggregateLevel, aggregates);
	}, [aggregatedFinancialNumbers]);

	const dataSets = createDataSets(selectedSingleValue, rolledOutFinancialNumbers);

	const modifyChartOptions = options => {
		let timeUnit;
		if ((!startDate && !endDate) || endDate.diff(startDate, 'months', true) > 2) {
			timeUnit = 'month';
		} else {
			timeUnit = 'day';
		}
		options.scales.x.time.unit = timeUnit;
		options.scales.y.ticks.callback = label => Util.getFormattedNumberWithCurrency(currencySymbol, label, intl);
		options.plugins.tooltip.callbacks.title = tooltipItem => {
			const date = moment(tooltipItem[0].label);
			if (aggregateLevel === 'MONTH') {
				return date.format('MMMM, YYYY');
			} else {
				return date.format('DD MMMM, YYYY');
			}
		};
		options.plugins.tooltip.callbacks.label = ctx => {
			const item = ctx.dataset;
			const dataItem = ctx.parsed;
			if (dataItem.customData && dataItem.customData.hideLabel) {
				return '';
			} else {
				const dataSetLabel = item.label ? `${item.label}: ` : '';
				return `${dataSetLabel}${Util.getFormattedNumberWithCurrency(currencySymbol, dataItem.y, intl)}`;
			}
		};
		return options;
	};

	return <Chart dataSets={dataSets} modifyOptions={modifyChartOptions} />;
};

const ProjectPortfolioReportChartsQuery = graphql`
	query ProjectPortfolioReportCharts_Query(
		$startYear: Int
		$startMonth: Int
		$startDay: Int
		$endYear: Int
		$endMonth: Int
		$endDay: Int
		$searchQuery: TaskSearchQueryType!
		$aggregateLevel: AggregateLevel!
		$aggregates: [FinancialAggregatedNumber]!
	) {
		viewer {
			actualPersonId
			component(name: "project_portfolio_report_charts")
			...ProjectPortfolioReportCharts_viewer
				@arguments(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: $aggregates
				)
		}
	}
`;

export {ProjectPortfolioReportChartsQuery};

export default createFragmentContainer(ProjectPortfolioReportCharts, {
	viewer: graphql`
		fragment ProjectPortfolioReportCharts_viewer on Viewer
		@argumentDefinitions(
			searchQuery: {type: "TaskSearchQueryType!"}
			startYear: {type: "Int"}
			startMonth: {type: "Int"}
			startDay: {type: "Int"}
			endYear: {type: "Int"}
			endMonth: {type: "Int"}
			endDay: {type: "Int"}
			aggregateLevel: {type: "AggregateLevel!"}
			aggregates: {type: "[FinancialAggregatedNumber]!"}
		) {
			id
			company {
				currency
				aggregatedFinancialNumbers(
					searchQuery: $searchQuery
					startYear: $startYear
					startMonth: $startMonth
					startDay: $startDay
					endYear: $endYear
					endMonth: $endMonth
					endDay: $endDay
					aggregateLevel: $aggregateLevel
					aggregates: $aggregates
				)
			}
		}
	`,
});
