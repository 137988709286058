import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Util from '../../../forecast-app/shared/util/util';

class sprintListComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sortBy: {column: null, ascending: true},
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.setExportColumns) {
			const configObject = this.props.config ? JSON.parse(this.props.config) : {};
			let exportColumns = [];
			if (configObject) {
				this.props.columns.forEach(col => {
					if (configObject.columns.indexOf(col) > -1) {
						if (col === 'forecast' || col === 'remaining' || col === 'percentDone') {
							exportColumns.push(col + '_raw');
						} else if (col !== 'progress') {
							exportColumns.push(col);
						}
					}
				});
			}
			this.props.setExportColumns(exportColumns);
		}
		// Calculate height based on amount of sprints in nextProps
		const numOfCards = this.props.viewer.insightComponentsData.sprintList.sprints.length;
		const padding = 20;
		const headerHeight = 90;
		const elementHeight = 51;
		const height = padding + headerHeight + elementHeight * numOfCards;
		const gridHeight = Math.ceil(height / 200);
		this.props.updateComponentHeight(this.props.id, gridHeight);

		this.setData(this.props, this.state);
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		this.setData(nextProps, nextState);
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	setData(props, state) {
		const {formatMessage, formatDate, formatNumber} = props.intl;
		const isInHours = props.viewer.insightComponentsData.sprintList.useHours;
		const sprintList = props.viewer.insightComponentsData.sprintList;
		this.totals = {
			cardsTodo: formatNumber(sprintList.todoTasksCountTotal),
			cardsInProgress: formatNumber(sprintList.progressTasksCountTotal),
			cardsDone: formatNumber(sprintList.doneTasksCountTotal),
			cardsTotal: formatNumber(sprintList.tasksCountTotal),
			forecast: isInHours
				? Util.convertMinutesToFullHour(sprintList.forecastTotal, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: sprintList.forecastTotal}),
			remaining: isInHours
				? Util.convertMinutesToFullHour(sprintList.remainingTotal, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: sprintList.remainingTotal}),
			actualPrice: Util.getFormattedCurrencyValue(
				Util.GetCurrencySymbol(sprintList.currency),
				formatNumber(Math.round(sprintList.actualPriceTotal * 100.0) / 100.0)
			),
			forecastPrice: Util.getFormattedCurrencyValue(
				Util.GetCurrencySymbol(sprintList.currency),
				formatNumber(Math.round(sprintList.forecastedPriceTotal * 100.0) / 100.0)
			),
			reported: Util.convertMinutesToFullHour(sprintList.reportedTotal, props.intl),
		};

		this.sprints = [];
		props.viewer.insightComponentsData.sprintList.sprints.forEach(node => {
			const sprint = {};
			sprint.id = node.id;
			sprint.name = node.name;
			sprint.startDate = node.startYear
				? formatDate(Util.CreateNonUtcMomentDate(node.startYear, node.startMonth, node.startDay).toDate())
				: null;
			sprint.startYear = node.startYear;
			sprint.startMonth = node.startMonth;
			sprint.startDay = node.startDay;
			sprint.endDate = node.endYear
				? formatDate(Util.CreateNonUtcMomentDate(node.endYear, node.endMonth, node.endDay).toDate())
				: null;
			sprint.endYear = node.endYear;
			sprint.endMonth = node.endMonth;
			sprint.endDay = node.endDay;
			sprint.percentDone = node.completion ? node.completion + '%' : '';
			sprint.percentDone_raw = node.completion;
			sprint.progress = (
				<div className="progress-container" style={{border: '1px solid rgb(68, 180, 255)'}}>
					<div
						className="progress-indicator"
						style={{width: (node.completion ? node.completion : 0) + '%', backgroundColor: 'rgb(68, 180, 255)'}}
					/>
				</div>
			);
			sprint.cardsTodo = node.cardsTodo;
			sprint.cardsInProgress = node.cardsInProgress;
			sprint.cardsDone = node.cardsDone;
			sprint.cardsTotal = node.cardsTotal;
			sprint.forecast_raw = isInHours ? ((node.forecast / 60.0) * 100) / 100 : node.forecast;
			sprint.forecast = isInHours
				? Util.convertMinutesToFullHour(node.forecast, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: node.forecast});
			sprint.remaining_raw = isInHours ? node.remaining / 60 : node.remaining;
			sprint.remaining = isInHours
				? Util.convertMinutesToFullHour(node.remaining, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: node.remaining});
			sprint.reported = Util.convertMinutesToFullHour(node.reported, props.intl);
			sprint.reported_raw = node.reported / 60;
			sprint.diff_raw = isInHours ? node.diff / 60 : node.diff;
			sprint.diff = isInHours
				? Util.convertMinutesToFullHour(node.diff, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: node.diff});
			if (this.canViewFinancial()) {
				sprint.actualPrice_raw = node.actualCost;
				sprint.actualPrice = Util.getFormattedCurrencyValue(
					Util.GetCurrencySymbol(sprintList.currency),
					formatNumber(Math.round(node.actualCost * 100.0) / 100.0)
				);
				sprint.forecastPrice_raw = node.forecastCost;
				sprint.forecastPrice = Util.getFormattedCurrencyValue(
					Util.GetCurrencySymbol(sprintList.currency),
					formatNumber(Math.round(node.forecastCost * 100.0) / 100.0)
				);
			}
			this.sprints.push(sprint);
		});
		if (state.sortBy.column !== this.state.sortBy.column || state.sortBy.ascending !== this.state.sortBy.ascending) {
			this.sort(state.sortBy);
		}
		if (props.setExportData) {
			props.setExportData(this.sprints);
		}
	}

	sort(sortBy) {
		if (sortBy.column) {
			this.sprints = this.sprints.sort((a, b) => {
				let column = sortBy.column;
				switch (column) {
					case 'forecast':
					case 'remaining':
					case 'percentDone':
					case 'actualPrice':
					case 'forecastPrice':
					case 'reported':
					case 'diff':
						column += '_raw';
						break;
					case 'progress':
						column = 'percentDone_raw';
						break;
				}

				let returnValue = 0;

				if (sortBy.column === 'name') {
					if (a[column].toLowerCase() < b[column].toLowerCase()) returnValue = 1;
					if (a[column].toLowerCase() > b[column].toLowerCase()) returnValue = -1;
					if (a[column] === '') returnValue = -1;
				} else if (sortBy.column === 'startDate') {
					if (!a.startYear) returnValue = 1;
					if (!b.startYear) returnValue = -1;
					const aDate = Util.CreateNonUtcMomentDate(a.startYear, a.startMonth, a.startDay);
					const bDate = Util.CreateNonUtcMomentDate(b.startYear, b.startMonth, b.startDay);
					if (aDate.isBefore(bDate)) returnValue = 1;
					if (aDate.isAfter(bDate)) returnValue = -1;
				} else if (sortBy.column === 'endDate') {
					if (!a.endYear) returnValue = 1;
					if (!b.endYear) returnValue = -1;
					const aDate = Util.CreateNonUtcMomentDate(a.endYear, a.endMonth, a.endDay);
					const bDate = Util.CreateNonUtcMomentDate(b.endYear, b.endMonth, b.endDay);
					if (aDate.isBefore(bDate)) returnValue = 1;
					if (aDate.isAfter(bDate)) returnValue = -1;
				} else {
					if (a[column] < b[column]) returnValue = 1;
					if (a[column] > b[column]) returnValue = -1;
					if (a[column] === '') returnValue = -1;
				}
				return sortBy.ascending ? returnValue * -1 : returnValue;
			});
		}
	}

	canViewFinancial() {
		return this.props.canViewFinancial !== false;
	}

	render() {
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};

		return (
			<InsightList
				data={this.sprints}
				componentId={this.props.id}
				componentName={'sprintList'}
				activeColumns={configObject ? configObject.columns : []}
				allColumns={this.props.columns}
				sortBy={this.state.sortBy}
				setSortBy={this.setSortBy.bind(this)}
				totals={this.totals}
				scrollElement={this.props.scrollElement}
				scrollElementFullScreen={this.props.scrollElementFullScreen}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

sprintListComponent.propTypes = {
	title: PropTypes.string,
};

const sprintListComponentQuery = graphql`
	query sprintListComponent_Query($shareKey: String, $projectId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_sprint_list")
			...sprintListComponent_viewer
				@arguments(shareKey: $shareKey, projectId: $projectId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {sprintListComponentQuery};

export default injectIntl(
	createFragmentContainer(sprintListComponent, {
		viewer: graphql`
			fragment sprintListComponent_viewer on Viewer
			@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
				insightComponentsData(shareKey: $shareKey) {
					sprintList(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
						useHours
						currency
						todoTasksCountTotal
						progressTasksCountTotal
						doneTasksCountTotal
						tasksCountTotal
						forecastTotal
						remainingTotal
						forecastedPriceTotal
						actualPriceTotal
						reportedTotal
						sprints {
							id
							name
							startYear
							startMonth
							startDay
							endYear
							endMonth
							endDay
							completion
							cardsTodo
							cardsInProgress
							cardsDone
							cardsTotal
							forecast
							remaining
							actualCost
							forecastCost
							reported
							diff
						}
					}
				}
			}
		`,
	})
);
