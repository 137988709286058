/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type projectTimelineComponent_viewer$ref = any;
export type projectTimelineComponent_QueryVariables = {|
  shareKey?: ?string,
  projectId?: ?string,
  isProjectGroupType?: ?boolean,
|};
export type projectTimelineComponent_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: projectTimelineComponent_viewer$ref,
  |}
|};
export type projectTimelineComponent_Query = {|
  variables: projectTimelineComponent_QueryVariables,
  response: projectTimelineComponent_QueryResponse,
|};
*/


/*
query projectTimelineComponent_Query(
  $shareKey: String
  $projectId: ID
  $isProjectGroupType: Boolean
) {
  viewer {
    actualPersonId
    component(name: "insight_project_timeline")
    ...projectTimelineComponent_viewer_2fJgLr
    id
  }
}

fragment projectTimelineComponent_viewer_2fJgLr on Viewer {
  insightComponentsData(shareKey: $shareKey) {
    projectTimeline(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
      project {
        name
        projectColor
        projectEndYear
        projectEndMonth
        projectEndDay
        projectStartYear
        projectStartMonth
        projectStartDay
        completion
        id
      }
      phases {
        id
        name
        startYear
        startMonth
        startDay
        deadlineYear
        deadlineMonth
        deadlineDay
      }
      tasks {
        id
        companyTaskId
        name
        startYear
        startMonth
        startDay
        startFrom
        deadlineYear
        deadlineMonth
        deadlineDay
        deadlineFrom
        estimateForecast
        timeLeft
        phase {
          id
          name
          startYear
          startMonth
          startDay
          deadlineYear
          deadlineMonth
          deadlineDay
        }
        assignedPersons {
          id
          firstName
          lastName
          profilePictureId
          profilePictureDefaultId
        }
        statusColumn {
          category
          id
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shareKey",
    "type": "String"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isProjectGroupType",
    "type": "Boolean"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "insight_project_timeline"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"insight_project_timeline\")"
},
v3 = {
  "kind": "Variable",
  "name": "isProjectGroupType",
  "variableName": "isProjectGroupType"
},
v4 = {
  "kind": "Variable",
  "name": "projectId",
  "variableName": "projectId"
},
v5 = {
  "kind": "Variable",
  "name": "shareKey",
  "variableName": "shareKey"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startYear",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startMonth",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDay",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineYear",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineMonth",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deadlineDay",
  "storageKey": null
},
v14 = [
  (v7/*: any*/),
  (v6/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "projectTimelineComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "projectTimelineComponent_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "projectTimelineComponent_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              (v5/*: any*/)
            ],
            "concreteType": "InsightComponentsDataType",
            "kind": "LinkedField",
            "name": "insightComponentsData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "concreteType": "ProjectTimeline",
                "kind": "LinkedField",
                "name": "projectTimeline",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectTimelineProjectType",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectColor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectEndYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectEndMonth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectEndDay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectStartYear",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectStartMonth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectStartDay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completion",
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectTimelinePhaseType",
                    "kind": "LinkedField",
                    "name": "phases",
                    "plural": true,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectTimelineTaskType",
                    "kind": "LinkedField",
                    "name": "tasks",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyTaskId",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startFrom",
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deadlineFrom",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "estimateForecast",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "timeLeft",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhaseType",
                        "kind": "LinkedField",
                        "name": "phase",
                        "plural": false,
                        "selections": (v14/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "assignedPersons",
                        "plural": true,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureDefaultId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "StatusColumn",
                        "kind": "LinkedField",
                        "name": "statusColumn",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "category",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "projectTimelineComponent_Query",
    "operationKind": "query",
    "text": "query projectTimelineComponent_Query(\n  $shareKey: String\n  $projectId: ID\n  $isProjectGroupType: Boolean\n) {\n  viewer {\n    actualPersonId\n    component(name: \"insight_project_timeline\")\n    ...projectTimelineComponent_viewer_2fJgLr\n    id\n  }\n}\n\nfragment projectTimelineComponent_viewer_2fJgLr on Viewer {\n  insightComponentsData(shareKey: $shareKey) {\n    projectTimeline(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {\n      project {\n        name\n        projectColor\n        projectEndYear\n        projectEndMonth\n        projectEndDay\n        projectStartYear\n        projectStartMonth\n        projectStartDay\n        completion\n        id\n      }\n      phases {\n        id\n        name\n        startYear\n        startMonth\n        startDay\n        deadlineYear\n        deadlineMonth\n        deadlineDay\n      }\n      tasks {\n        id\n        companyTaskId\n        name\n        startYear\n        startMonth\n        startDay\n        startFrom\n        deadlineYear\n        deadlineMonth\n        deadlineDay\n        deadlineFrom\n        estimateForecast\n        timeLeft\n        phase {\n          id\n          name\n          startYear\n          startMonth\n          startDay\n          deadlineYear\n          deadlineMonth\n          deadlineDay\n        }\n        assignedPersons {\n          id\n          firstName\n          lastName\n          profilePictureId\n          profilePictureDefaultId\n        }\n        statusColumn {\n          category\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12a6444104396452933f42c031a4b169';

module.exports = node;
