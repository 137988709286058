var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import JSZip from 'jszip';
import { getNotNullishNodes } from '../../forecast-app/shared/util/NotNullPredicate';
import { trackEvent } from '../../tracking/amplitude/TrackingV2';
import DirectApi from '../../directApi.js';
import { getProjectIndicatorString } from '../../forecast-app/shared/components/project-indicator/support/ProjectIndicatorLogic';
const fetchFile = (file) => fetch(`${DirectApi.fileSrc(file.id)}?1111`, {
    credentials: 'include', // Send along cookies (sessionKey)
});
const addFileCount = (fileName, number) => {
    const pieces = fileName.split('.');
    const hasPieces = pieces.length;
    const firstPart = hasPieces > 1 ? pieces.slice(0, -1).join('.') : fileName;
    const extension = hasPieces > 1 ? '.' + pieces.slice(-1) : '';
    return firstPart + ' (' + number + ')' + extension;
};
const projectPrefix = (expense) => { var _a, _b; return `${getProjectIndicatorString((_a = expense.project) === null || _a === void 0 ? void 0 : _a.companyProjectId, (_b = expense.project) === null || _b === void 0 ? void 0 : _b.customProjectId)}`; };
export const downloadExpenseFiles = (expenses, bulk) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    const hasFilesToDownload = expenses.some(expense => { var _a; return getNotNullishNodes((_a = expense.files) === null || _a === void 0 ? void 0 : _a.edges).length > 0; });
    if (!hasFilesToDownload) {
        return;
    }
    const trackEventName = bulk ? 'Bulk Expense Download All Files Button' : 'Expense Actions Download Files';
    if (expenses.length === 1) {
        const expense = expenses[0];
        const files = ((_b = (_a = expense.files) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(file => file === null || file === void 0 ? void 0 : file.node)) || [];
        if (files.length === 1) {
            const file = files[0];
            if (!file)
                return;
            const fileContent = yield fetchFile(file).then(response => response.blob());
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(fileContent);
            downloadLink.download = `${getProjectIndicatorString((_c = expense.project) === null || _c === void 0 ? void 0 : _c.companyProjectId, (_d = expense.project) === null || _d === void 0 ? void 0 : _d.customProjectId)}_${file.name}`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            trackEvent(trackEventName, 'Clicked', { totalExpenses: expenses.length, totalFiles: files.length });
            return;
        }
    }
    const zip = new JSZip();
    const sameProject = expenses.every((expense, index) => {
        var _a, _b;
        return index === 0 || ((_a = expense.project) === null || _a === void 0 ? void 0 : _a.companyProjectId) === ((_b = expenses[index - 1].project) === null || _b === void 0 ? void 0 : _b.companyProjectId);
    });
    const fileNameCountMap = new Map();
    const getUniqueFileName = (rawFileName) => {
        let fileNameCount = fileNameCountMap.get(rawFileName) || 0;
        const fileName = fileNameCount ? addFileCount(rawFileName, fileNameCount) : rawFileName;
        fileNameCountMap.set(rawFileName, ++fileNameCount);
        return fileName;
    };
    const fetchAndAddToZip = (file, expense) => __awaiter(void 0, void 0, void 0, function* () {
        const fileContent = yield fetchFile(file).then(response => response.arrayBuffer());
        const fileNameProjectPrefix = sameProject ? '' : projectPrefix(expense) + '_';
        const fileNameExpenseName = expense.name ? expense.name.replace(/ /g, '-') + '_' : '';
        const rawFileName = fileNameProjectPrefix + fileNameExpenseName + file.name || 'unnamed-file';
        zip.file(getUniqueFileName(rawFileName), fileContent);
    });
    yield Promise.all(expenses.map(expense => { var _a; return Promise.all(getNotNullishNodes((_a = expense.files) === null || _a === void 0 ? void 0 : _a.edges).map(file => fetchAndAddToZip(file, expense))); }));
    zip.generateAsync({ type: 'blob' }).then(content => {
        const downloadLink = document.createElement('a');
        if (sameProject) {
            downloadLink.download = `${projectPrefix(expenses[0])}_expense_files.zip`;
        }
        else {
            const timestamp = new Date().toISOString().split('.')[0];
            downloadLink.download = `${timestamp}_expense_files.zip`;
        }
        downloadLink.href = URL.createObjectURL(content);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        trackEvent(trackEventName, 'Clicked', {
            totalExpenses: expenses.length,
            totalFiles: zip.files ? Object.keys(zip.files).length : 0,
        });
    });
});
