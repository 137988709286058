import React from 'react';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';
import Util from '../../../../../../forecast-app/shared/util/util';
import {renderCurrency, renderPercentage} from '../../util/BudgetUtils';
import {Tooltip, Text} from '@forecast-it/design-system';
import {TooltipFormulaRenderer} from '../../../../../TooltipFormulaRenderer';

const TotalsTableRow = ({rowData, tableColumnProps, headerColumn, canExpand, tooltips}) => {
	const intl = useIntl();
	const currencySymbol = Util.GetCurrencySymbol(rowData.currency);
	const rowType = rowData.rowType;

	const renderValue = value => {
		if (rowType === 'margin') {
			return renderPercentage(value, intl);
		} else {
			return renderCurrency(currencySymbol, value, intl);
		}
	};

	if (canExpand !== undefined) {
		tableColumnProps.canExpand = canExpand;
	}

	const getTooltipForAggregate = (tooltips, aggregate) => {
		const tooltipItems = tooltips ? tooltips[aggregate] : null;
		if (!tooltipItems) {
			console.log('Could not find tooltip: ' + aggregate);
		}
		return tooltipItems ? tooltipItems : null;
	};

	return (
		<Table.Row cy={'row-' + rowType} key={rowType} {...tableColumnProps}>
			<Table.Column>{headerColumn}</Table.Column>
			{rowData.dataArray.map(object => {
				const tooltipItem = getTooltipForAggregate(tooltips, object.type);
				return tooltipItem ? (
					<Table.Column negative={object.value < 0} cy={'column-' + object.type} key={rowType + object.value}>
						<Tooltip width={'xl'} content={<TooltipFormulaRenderer items={tooltipItem} />} interactable={false}>
							<Text size={'3'}>{renderValue(object.value)}</Text>
						</Tooltip>
					</Table.Column>
				) : (
					<Table.Column negative={object.value < 0} cy={'column-' + object.type} key={rowType + object.value}>
						<Text size={3}>{renderValue(object.value)}</Text>
					</Table.Column>
				);
			})}
		</Table.Row>
	);
};

export default TotalsTableRow;
