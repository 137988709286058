import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {BudgetDataWrapper, ChartAndHighLevelNumbers} from '../../BudgetData.styled';
import Totals from '../work_and_expenses/Totals';
import InvoiceChart from './InvoiceChart';

const InvoiceBudgetData = ({project, selectedViewType, selectValueType, eyeOptionMap, hasBaseline}) => {
	return (
		<>
			<BudgetDataWrapper>
				<ChartAndHighLevelNumbers>
					<InvoiceChart
						project={project}
						selectedViewType={selectedViewType}
						eyeOptionMap={eyeOptionMap}
						hasBaseline={hasBaseline}
					/>
				</ChartAndHighLevelNumbers>
				<Totals project={project} eyeOptionMap={eyeOptionMap} selectValueType={selectValueType} />
			</BudgetDataWrapper>
		</>
	);
};

export default createFragmentContainer(InvoiceBudgetData, {
	project: graphql`
		fragment InvoiceBudgetData_project on ProjectType {
			...InvoiceChart_project
			...Totals_project
		}
	`,
});
