import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import moment from 'moment';
import {injectIntl} from 'react-intl';
import {colors} from '../../../constants';
import ChartJsStackedArea from '../../chartjs/chart_js_stacked_area';

class cummulativeFlowDiagramComponent extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}

	render() {
		const {formatMessage} = this.props.intl;
		const showForecast = this.props.selectValue && this.props.selectValue === 'forecast';
		const useHours = this.props.viewer.insightComponentsData.tasksFlow.useHours;
		const tooltipSuffix = showForecast
			? useHours
				? formatMessage({id: 'common.hours.short'})
				: formatMessage({id: 'common.points.short'})
			: '';
		const data = {
			labels: [],
			datasets: [],
		};
		const labels = [];
		const dateStringsMap = new Map();
		const columnIds = [];
		const columnNameMap = new Map();
		this.props.viewer.insightComponentsData.tasksFlow.data.forEach(el => {
			const momentDate = moment(el.dateString, 'YYYYMMDD');
			const date = momentDate.toDate();
			labels.push(date);
			const columnValueMap = new Map();
			el.columnsData.forEach(colData => {
				const value = showForecast ? (useHours ? colData.forecastSum / 60 : colData.forecastSum) : colData.count;
				columnValueMap.set(colData.statusColumnId, value);
				if (!columnIds.includes(colData.statusColumnId)) {
					columnIds.push(colData.statusColumnId);
				}
				if (!columnNameMap.has(colData.statusColumnId)) {
					columnNameMap.set(colData.statusColumnId, colData.name);
				}
			});
			dateStringsMap.set(el.dateString, columnValueMap);
		});
		const columnDataSet = new Map();
		const columnLatestValueMap = new Map();
		columnIds.forEach(columnId => {
			columnDataSet.set(columnId, []);
		});
		dateStringsMap.forEach((value, key, map) => {
			const colDataMap = value;
			columnIds.forEach(columnId => {
				//if date doesnt have value for column set its value to the same it had on the previous date
				const value = colDataMap.has(columnId)
					? colDataMap.get(columnId)
					: columnLatestValueMap.has(columnId)
					? columnLatestValueMap.get(columnId)
					: null;
				const columnDataArray = columnDataSet.get(columnId);
				columnDataArray.push(value);
				columnDataSet.set(columnId, columnDataArray);
				columnLatestValueMap.set(columnId, value);
			});
		});
		let index = 0;
		columnDataSet.forEach((value, key, map) => {
			const columnName = columnNameMap.get(key);
			data.datasets.push({
				label: columnName ? columnName : formatMessage({id: 'cumulative_flow.deleted_column'}),
				backgroundColor: colors.length > index ? colors[index] : colors[0],
				data: value,
				spanGaps: true,
				lineTension: 0,
			});
			index++;
		});
		data.labels = labels;
		return (
			<div>
				<ChartJsStackedArea
					data={data}
					height={360}
					yAxesTitle={formatMessage({id: showForecast ? 'common.hours' : 'insights_header_select.cards_count'})}
					tooltipSuffix={tooltipSuffix}
				/>
			</div>
		);
	}
}

const cummulativeFlowDiagramComponentQuery = graphql`
	query cummulativeFlowDiagramComponent_Query($shareKey: String, $projectId: ID, $isProjectGroupType: Boolean) {
		viewer {
			actualPersonId
			component(name: "insight_cummulative_flow_diagram")
			...cummulativeFlowDiagramComponent_viewer
				@arguments(shareKey: $shareKey, projectId: $projectId, isProjectGroupType: $isProjectGroupType)
		}
	}
`;

export {cummulativeFlowDiagramComponentQuery};

export default injectIntl(
	createFragmentContainer(cummulativeFlowDiagramComponent, {
		viewer: graphql`
			fragment cummulativeFlowDiagramComponent_viewer on Viewer
			@argumentDefinitions(shareKey: {type: "String"}, projectId: {type: "ID"}, isProjectGroupType: {type: "Boolean"}) {
				insightComponentsData(shareKey: $shareKey) {
					tasksFlow(projectId: $projectId, isProjectGroupType: $isProjectGroupType) {
						useHours
						data {
							dateString
							columnsData {
								id
								statusColumnId
								name
								count
								sortOrder
								forecastSum
							}
						}
					}
				}
			}
		`,
	})
);
