import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';
import {remapOptionTranslationIds} from '../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import Util from '../../../../forecast-app/shared/util/util';
import {HIDDEN_FEATURES} from '../../../../constants';

export const getPeriodEyeOptions = (
	hasFinance,
	hasBaseline,
	hasRevenueWithoutCostAccess,
	hasRetainerPeriodRead,
	hasPriorityLevels
) => {
	const addChainingMethods = array => {
		array.add = nextOptions => {
			const newArray = array.concat(nextOptions);
			addChainingMethods(newArray);
			return newArray;
		};

		array.addIfHasFinance = nextOptions => {
			if (hasFinance) {
				const newArray = array.concat(nextOptions);
				addChainingMethods(newArray);
				return newArray;
			}
			return array;
		};
		array.addIfShowingRevenue = nextOptions => {
			if (!Util.isFeatureHidden(HIDDEN_FEATURES.REVENUE)) {
				const newArray = array.concat(nextOptions);
				addChainingMethods(newArray);
				return newArray;
			}
			return array;
		};
		array.addIfShowingRevenueAndFinancialCategories = nextOptions => {
			if (!Util.isFeatureHidden(HIDDEN_FEATURES.REVENUE) && hasFeatureFlag('financial_categories_update')) {
				const newArray = array.concat(nextOptions);
				addChainingMethods(newArray);
				return newArray;
			}
			return array;
		};
		array.addIfHavingRevenueRecognition = nextOptions => {
			if (!Util.isFeatureHidden(HIDDEN_FEATURES.REVENUE)) {
				const newArray = array.concat(nextOptions);
				addChainingMethods(newArray);
				return newArray;
			}
			return array;
		};
		array.addIfNotLimitedFinancialAccess = nextOptions => {
			if (!hasRevenueWithoutCostAccess) {
				const newArray = array.concat(nextOptions);
				addChainingMethods(newArray);
				return newArray;
			}
			return array;
		};
		array.addIfShowingInvoicedRevenue = nextOptions => {
			if (!hasRevenueWithoutCostAccess && !Util.isFeatureHidden(HIDDEN_FEATURES.REVENUE)) {
				const newArray = array.concat(nextOptions);
				addChainingMethods(newArray);
				return newArray;
			}
			return array;
		};
		array.addIfHasBaseline = nextOptions => {
			if (hasBaseline) {
				const newArray = array.concat(nextOptions);
				addChainingMethods(newArray);
				return newArray;
			}
			return array;
		};
		array.addIfHasRetainerPeriodRead = nextOptions => {
			if (hasRetainerPeriodRead && !Util.isFeatureHidden(HIDDEN_FEATURES.REVENUE)) {
				const newArray = array.concat(nextOptions);
				addChainingMethods(newArray);
				return newArray;
			}
			return array;
		};
		array.addIfHasFeatureFlag = (featureFlag, nextOptions) => {
			if (hasFeatureFlag(featureFlag)) {
				const newArray = array.concat(nextOptions);
				addChainingMethods(newArray);
				return newArray;
			}
			return array;
		};
	};

	const createArray = () => {
		const options = [];
		addChainingMethods(options);
		return options;
	};

	const options = createArray()
		.add([
			{
				name: 'projectOverview',
				translationId: 'project_budget.project_overview',
				disabled: false,
				checked: true,
				nestedOptions: [
					...(hasPriorityLevels
						? [
								{
									name: 'priorityLevel',
									checked: true,
									disabled: false,
									translationId: 'project_settings.priority_level',
								},
						  ]
						: []),
					{
						name: 'winProbability',
						checked: true,
						disabled: false,
						translationId: 'project.win_chance',
					},
					{
						name: 'dates',
						checked: true,
						disabled: false,
						translationId: 'common.dates',
					},
					...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
						? [
								{
									name: 'client',
									checked: false,
									disabled: false,
									translationId: 'insights.component.list.column.client',
								},
						  ]
						: []),
					{
						name: 'status',
						checked: false,
						disabled: false,
						translationId: 'common.status',
					},
					{
						name: 'statusDescription',
						checked: false,
						disabled: false,
						translationId: 'common.statusDescription',
					},
					{
						name: 'projectOwner',
						checked: false,
						disabled: false,
						translationId: 'common.project_owner',
					},
					{
						name: 'stage',
						checked: false,
						disabled: false,
						translationId: 'insights.component.list.column.status',
					},
					{
						name: 'allocations',
						checked: false,
						disabled: false,
						translationId: 'scheduling.menu.allocations',
					},
					...(Util.isFeatureHidden(HIDDEN_FEATURES.REVENUE)
						? []
						: [
								{
									name: 'budgetType',
									checked: false,
									disabled: false,
									translationId: 'new_project_modal.budget_type',
								},
								{
									name: 'rateCard',
									checked: false,
									disabled: false,
									translationId: 'insights.component.list.column.rateCard',
								},
						  ]),
					{
						name: 'progress',
						checked: false,
						disabled: false,
						translationId: 'common.progress',
					},
					{
						name: 'progressEom',
						checked: false,
						disabled: false,
						translationId: 'common.progress.eom',
					},
				],
			},
			{
				name: 'projectHours',
				translationId: 'project_portfolio_report.project_hours',
				disabled: false,
				checked: false,
				nestedOptions: createArray()
					.add([
						...(!Util.isFeatureHidden(HIDDEN_FEATURES.TIME_REGISTRATIONS)
							? [
									{
										name: 'registeredHours',
										checked: false,
										disabled: false,
										translationId: 'project_portfolio_report.registered_hours',
									},
							  ]
							: []),
						{
							name: 'remainingHours',
							checked: false,
							disabled: false,
							translationId: 'project_portfolio_report.remaining_hours',
						},
						{
							name: 'forecastedHours',
							checked: false,
							disabled: false,
							translationId: 'project_portfolio_report.forecasted_hours',
						},
						{
							name: 'totalScope',
							checked: false,
							disabled: false,
							translationId: 'project_portfolio_report.total_scope',
						},
						{
							name: 'approvedScope',
							checked: false,
							disabled: false,
							translationId: 'project_portfolio_report.approved_scope',
						},
					])
					.addIfHasBaseline([
						{
							name: 'baselineHours',
							checked: false,
							disabled: false,
							translationId: 'baseline.baseline',
						},
					]),
			},
			{
				name: 'labels',
				checked: false,
				disabled: false,
				translationId: 'common.labels',
			},
		])
		.addIfHasFinance([
			{
				name: 'financials',
				translationId: 'project_budget.financials',
				disabled: false,
				checked: false,
				nestedOptions: createArray()
					.addIfHasBaseline([
						{
							name: 'baseline',
							translationId: 'project_section.baseline',
							disabled: false,
							checked: false,
							nestedOptions: createArray()
								.addIfShowingRevenue([
									{
										name: 'baselineRevenue',
										checked: false,
										disabled: false,
										translationId: 'baseline.revenue',
									},
								])
								.addIfShowingRevenueAndFinancialCategories([
									{
										name: 'baselineValueOfService',
										checked: false,
										disabled: false,
										translationId: 'project_budget.billable_time_and_expenses',
									},
								])
								.addIfNotLimitedFinancialAccess([
									{
										name: 'baselineCost',
										checked: false,
										disabled: false,
										translationId: 'project_budget.cost',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'baselineProfit',
										checked: false,
										disabled: false,
										translationId: 'project_budget.profit',
									},
									{
										name: 'baselineMargin',
										checked: false,
										disabled: false,
										translationId: 'project_budget.margin',
									},
								]),
						},
					])
					.addIfShowingRevenue([
						{
							name: 'invoice',
							translationId: 'common.invoiced',
							disabled: false,
							checked: false,
							nestedOptions: [
								{
									name: 'invoiced',
									checked: false,
									disabled: false,
									translationId: 'common.invoiced',
								},
								{
									name: 'paid',
									checked: false,
									disabled: false,
									translationId: 'common.invoice.paid',
								},
							],
						},
					])
					.addIfHavingRevenueRecognition([
						{
							name: 'revenueRecognition',
							translationId: 'project_budget.revenue_recognition',
							disabled: false,
							checked: true,
							nestedOptions: createArray().add([
								{
									name: 'recognitionLockedRevenue',
									checked: true,
									disabled: false,
									translationId: 'project_budget.from_locked_months',
								},
								{
									name: 'recognitionOpenRevenue',
									checked: true,
									disabled: false,
									translationId: 'project_budget.from_open_months',
								},
								{
									name: 'recognitionAmount',
									checked: true,
									disabled: false,
									translationId: 'common.total',
								},
								{
									name: 'recognitionProfit',
									checked: false,
									disabled: false,
									translationId: 'project_budget.profit',
								},
								{
									name: 'recognitionMargin',
									checked: false,
									disabled: false,
									translationId: 'project_budget.margin',
								},
							]),
						},
					])
					.addIfShowingRevenue([
						{
							name: 'fixedPrice',
							translationId: 'project_budget.fixed_price',
							disabled: false,
							checked: true,
							nestedOptions: [
								{
									name: 'fixedPriceRevenue',
									checked: true,
									disabled: false,
									translationId: 'project_budget.fixed_price',
								},
							],
						},
					])
					.add([
						{
							name: 'actual',
							translationId: 'project_budget.actuals',
							disabled: false,
							checked: true,
							nestedOptions: createArray()
								.addIfShowingRevenueAndFinancialCategories([
									{
										name: 'actualRevenue',
										checked: true,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'billableActualTimeAndExpenses',
										checked: true,
										disabled: false,
										translationId: 'project_budget.billable_time_and_expenses',
									},
								])
								.addIfNotLimitedFinancialAccess([
									{
										name: 'actualCost',
										checked: true,
										disabled: false,
										translationId: 'project_budget.cost',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'actualProfit',
										checked: true,
										disabled: false,
										translationId: 'project_budget.profit',
									},
									{
										name: 'actualMargin',
										checked: false,
										disabled: false,
										translationId: 'project_budget.margin',
									},
								]),
						},
						{
							name: 'planned',
							translationId: 'common.planned',
							disabled: false,
							checked: true,
							nestedOptions: createArray()
								.addIfShowingRevenueAndFinancialCategories([
									{
										name: 'estimatedRevenue',
										checked: true,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'planRevenue',
										checked: true,
										disabled: false,
										translationId: 'project_budget.billable_time_and_expenses',
									},
								])
								.addIfNotLimitedFinancialAccess([
									{
										name: 'planCost',
										checked: true,
										disabled: false,
										translationId: 'project_budget.cost',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'planProfit',
										checked: true,
										disabled: false,
										translationId: 'project_budget.profit',
									},
									{
										name: 'planMargin',
										checked: true,
										disabled: false,
										translationId: 'project_budget.margin',
									},
								]),
						},
						{
							name: 'remaining',
							translationId: 'project_budget.forecast_to_complete',
							disabled: false,
							checked: false,
							nestedOptions: createArray()
								.addIfShowingRevenueAndFinancialCategories([
									{
										name: 'remainingWorkRevenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'billableForecastTimeAndExpensesToComplete',
										checked: false,
										disabled: false,
										translationId: 'project_budget.billable_time_and_expenses',
									},
								])
								.addIfNotLimitedFinancialAccess([
									{
										name: 'forecastCostToComplete',
										checked: false,
										disabled: false,
										translationId: 'project_budget.cost',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'forecastProfitToComplete',
										checked: false,
										disabled: false,
										translationId: 'project_budget.profit',
									},
									{
										name: 'forecastMarginToComplete',
										checked: false,
										disabled: false,
										translationId: 'project_budget.margin',
									},
								]),
						},
						{
							name: 'forecasted',
							translationId: 'project_budget.total_at_completion',
							disabled: false,
							checked: true,
							nestedOptions: createArray()
								.addIfShowingRevenueAndFinancialCategories([
									{
										name: 'projectTotalRevenue',
										checked: true,
										disabled: false,
										translationId: 'project_budget.revenue',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'forecastedRevenue',
										checked: true,
										disabled: false,
										translationId: 'project_budget.billable_time_and_expenses',
									},
								])
								.addIfNotLimitedFinancialAccess([
									{
										name: 'forecastedCost',
										checked: true,
										disabled: false,
										translationId: 'project_budget.cost',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'forecastedProfit',
										checked: true,
										disabled: false,
										translationId: 'project_budget.profit',
									},
									{
										name: 'forecastedMargin',
										checked: false,
										disabled: false,
										translationId: 'project_budget.margin',
									},
								]),
						},
						{
							name: 'planActual',
							translationId: 'project_budget.plan_vs_actual',
							disabled: false,
							checked: false,
							nestedOptions: createArray()
								.addIfShowingRevenue([
									{
										name: 'planBillableActualTimeAndExpenses',
										checked: false,
										disabled: false,
										translationId: 'project_budget.billable_time_and_expenses',
									},
								])
								.addIfNotLimitedFinancialAccess([
									{
										name: 'planActualCost',
										checked: false,
										disabled: false,
										translationId: 'project_budget.cost',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'planActualProfit',
										checked: false,
										disabled: false,
										translationId: 'project_budget.profit',
									},
								]),
						},
						{
							name: 'planForecasted',
							translationId: 'project_budget.plan_vs_forecast',
							disabled: false,
							checked: false,
							nestedOptions: createArray()
								.addIfShowingRevenue([
									{
										name: 'planForecastedRevenue',
										checked: false,
										disabled: false,
										translationId: 'project_budget.billable_time_and_expenses',
									},
								])
								.addIfNotLimitedFinancialAccess([
									{
										name: 'planForecastedCost',
										checked: false,
										disabled: false,
										translationId: 'project_budget.cost',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'planForecastedProfit',
										checked: false,
										disabled: false,
										translationId: 'project_budget.profit',
									},
								]),
						},
					])
					.addIfShowingInvoicedRevenue([
						{
							name: 'invoicedActual',
							translationId: 'project_budget.accrued',
							disabled: false,
							checked: false,
							nestedOptions: createArray().add([
								{
									name: 'invoicedActualInvoiced',
									checked: false,
									disabled: false,
									translationId: 'project_budget.invoiced',
								},
								{
									name: 'invoicedRecognitionLockedRevenue',
									checked: false,
									disabled: false,
									translationId: 'project_budget.revenue_recognition_from_locked_months',
								},
								{
									name: 'accruedDeferred',
									checked: false,
									disabled: false,
									translationId: 'project_budget.accrued',
								},
							]),
						},
					])
					.addIfHasRetainerPeriodRead([
						{
							name: 'retainerPeriodTarget',
							translationId: 'project_budget.retainer_period_target',
							disabled: false,
							checked: false,
							nestedOptions: createArray()
								.add([
									{
										name: 'retainerPeriodTargetMinutes',
										checked: false,
										disabled: false,
										translationId: 'project_budget.retainer_period_target_minutes',
									},
								])
								.addIfShowingRevenue([
									{
										name: 'retainerPeriodTargetPrice',
										checked: false,
										disabled: false,
										translationId: 'project_budget.retainer_period_target_price',
									},
								]),
						},
					])
					.addIfShowingRevenueAndFinancialCategories([
						{
							name: 'overUnderServicing',
							translationId: 'project_portfolio_report.over_under_servicing',
							disabled: false,
							checked: false,
							nestedOptions: createArray().add([
								{
									name: 'projectedTotalRevenue',
									checked: false,
									disabled: false,
									translationId: 'common.projected_total_revenue',
								},
								{
									name: 'projectedTotalBillableValueOfService',
									checked: false,
									disabled: false,
									translationId: 'common.projected_total_billable_value_of_service',
								},
								{
									name: 'projectedTotalNonBillableValueOfService',
									checked: false,
									disabled: false,
									translationId: 'common.projected_total_non_billable_value_of_service',
								},
								{
									name: 'projectedTotalValueOfService',
									checked: false,
									disabled: false,
									translationId: 'common.projected_total_value_of_service',
								},
								{
									name: 'projectedBudgetRemaining',
									checked: false,
									disabled: false,
									translationId: 'common.projected_budget_remaining',
								},
								{
									name: 'projectedOverUnderServicing',
									checked: false,
									disabled: false,
									translationId: 'project_portfolio_report.projected_over_under_servicing',
								},
							]),
						},
					]),
			},
		]);

	remapOptionTranslationIds(options);
	return options;
};

export const getMonthByMonthEyeOptions = hasPriorityLevels => {
	return [
		{
			name: 'mbm_ProjectOverview',
			translationId: 'project_budget.project_overview',
			disabled: false,
			checked: false,
			nestedOptions: [
				...(hasPriorityLevels
					? [
							{
								name: 'mbm_priorityLevel',
								checked: false,
								disabled: false,
								translationId: 'project_settings.priority_level',
							},
					  ]
					: []),
				{
					name: 'mbm_winProbability',
					checked: false,
					disabled: false,
					translationId: 'project.win_chance',
				},
				{
					name: 'mbm_dates',
					checked: false,
					disabled: false,
					translationId: 'common.dates',
				},

				...(!Util.isFeatureHidden(HIDDEN_FEATURES.CLIENT)
					? [
							{
								name: 'mbm_client',
								checked: false,
								disabled: false,
								translationId: 'insights.component.list.column.client',
							},
					  ]
					: []),
				{
					name: 'mbm_status',
					checked: false,
					disabled: false,
					translationId: 'common.status',
				},
				{
					name: 'mbm_statusDescription',
					checked: false,
					disabled: false,
					translationId: 'common.statusDescription',
				},
				{
					name: 'mbm_projectOwner',
					checked: false,
					disabled: false,
					translationId: 'common.project_owner',
				},
				{
					name: 'mbm_stage',
					checked: false,
					disabled: false,
					translationId: 'insights.component.list.column.status',
				},
				{
					name: 'mbm_budgetType',
					checked: false,
					disabled: false,
					translationId: 'new_project_modal.budget_type',
				},
				{
					name: 'mbm_rateCard',
					checked: false,
					disabled: false,
					translationId: 'insights.component.list.column.rateCard',
				},
				{
					name: 'mbm_progress',
					checked: false,
					disabled: false,
					translationId: 'common.progress',
				},
			],
		},
		{
			name: 'mbm_expense',
			checked: true,
			disabled: false,
			translationId: 'common.expenses',
		},
		{
			name: 'mbm_time',
			checked: true,
			disabled: false,
			translationId: 'common.time',
		},
		{
			name: 'mbm_total',
			checked: true,
			disabled: false,
			translationId: 'common.total',
		},
		{
			name: 'mbm_fixedPriceRevenue',
			checked: false,
			disabled: false,
			translationId: 'project_budget.fixed_price',
		},
	];
};
