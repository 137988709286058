import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';
import DirectApi from '../../directApi';

class DownloadButton extends Component {
	render() {
		const file = this.props.file;
		if (file.node.link) {
			return (
				<a
					id="download-button"
					className="download-action"
					href={file.node.link}
					target="_blank"
					rel="noopener noreferrer"
				>
					<div className="download-button-container">
						<FormattedMessage id="project_files.google_drive_link_open" />
					</div>
				</a>
			);
		} else {
			return (
				<a
					id="download-button"
					className="download-action"
					//for some reason when '?123 is added after file id it downloads file with correct filename otherwise filename is ignored'
					href={DirectApi.fileSrc(file.node.id) + '?123'}
				>
					<div className="download-button-container">
						<FormattedMessage id="common.download" />
					</div>
				</a>
			);
		}
	}
}

DownloadButton.propTypes = {
	file: PropTypes.object.isRequired,
};

export default injectIntl(DownloadButton);
