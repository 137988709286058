import Util from '../../../forecast-app/shared/util/util';

export const handleCSVDownload = pageStructure => {
	if (pageStructure) {
		const columns = [],
			data = new Map();
		const projectGroupIds = pageStructure.projectLines
			.filter(projectLine => projectLine.companyProjectGroupId !== undefined)
			.map(projectLine => projectLine.id);
		pageStructure.columns.forEach(col => {
			col.subColumns.forEach(subCol => {
				const subColTitle = `${col.title ? col.title.toLowerCase().replace('-', '_') + '_' : ''}${
					subCol.title ? subCol.title.toLowerCase().replace('-', '_') : ''
				}`.replace(/ /g, '');
				const subColId = subCol.id.includes('.') ? subCol.id.substring(0, subCol.id.indexOf('.')) : subCol.id;
				if (subColTitle !== '') {
					if (subColId === 'dates') {
						columns.push('start_date');
						columns.push('end_date');
					} else {
						columns.push(subColTitle);
					}

					for (var projectId in subCol.projectValues) {
						if (!projectGroupIds.includes(projectId) && subCol.projectValues.hasOwnProperty(projectId)) {
							let projectData = data.has(projectId) ? data.get(projectId) : {};
							if (subColId === 'dates') {
								projectData['start_date'] = subCol.projectValues[projectId].startDate
									? subCol.projectValues[projectId].startDate.format('DD MMM YYYY')
									: '';
								projectData['end_date'] = subCol.projectValues[projectId].endDate
									? subCol.projectValues[projectId].endDate.format('DD MMM YYYY')
									: '';
								data.set(projectId, projectData);
							} else {
								let value;

								switch (subColId) {
									case 'client':
									case 'rateCard':
										value = subCol.projectValues[projectId] ? subCol.projectValues[projectId].name : '';
										break;
									case 'projectId':
										value = `P${subCol.projectValues[projectId].companyProjectId}`;
										break;
									case 'status':
										value = subCol.projectValues[projectId]
											? Util.getProjectStatusColorName(subCol.projectValues[projectId].color)
											: '';
										break;
									case 'projectContact':
										value =
											subCol.projectValues[projectId] &&
											subCol.projectValues[projectId].edges[0] &&
											subCol.projectValues[projectId].edges[0].node.person
												? subCol.projectValues[projectId].edges[0].node.person.fullName
												: '';
										break;
									case 'labels':
										value =
											subCol.projectValues[projectId] && subCol.projectValues[projectId].edges
												? subCol.projectValues[projectId].edges.reduce(
														(reducedStringValue, projectLabel) =>
															`${reducedStringValue}${reducedStringValue === '' ? '' : ','} ${
																projectLabel.node.label.name
															}`,
														''
												  )
												: '';
										break;
									case 'budgetType':
										value = subCol.projectValues[projectId].budgetType;
										break;
									case 'future_progress':
										value =
											subCol.projectValues[projectId].props && subCol.projectValues[projectId].props.id
												? subCol.projectValues[projectId].props.id.join(' / ')
												: '';
										break;
									default:
										if (subCol.type === 'time') {
											value =
												subCol.projectValues[projectId] === null ||
												subCol.projectValues[projectId] === undefined ||
												isNaN(subCol.projectValues[projectId])
													? 0
													: Math.round(subCol.projectValues[projectId] * 60);
										} else {
											const roundingNumber = subCol.type === 'percentage' ? 1000 : 100;
											value =
												subCol.projectValues[projectId] === null ||
												subCol.projectValues[projectId] === undefined
													? ''
													: isNaN(subCol.projectValues[projectId])
													? subCol.projectValues[projectId]
													: Math.round(subCol.projectValues[projectId] * roundingNumber) /
													  roundingNumber;
										}
								}
								projectData[subColTitle] = value;
								data.set(projectId, projectData);
							}
						}
					}
				}
			});
		});
		const mapValues = [];
		data.forEach((value, key, map) => mapValues.push(value));
		const columnsFormatted = columns.map(column => {
			var splitStr = column.toLowerCase().split(/[ \\_\\]/g);
			for (var i = 0; i < splitStr.length; i++) {
				splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
			}
			return splitStr.join(' ');
		});
		let exportData = columnsFormatted + '\r\n';
		exportData += Util.JSONToCSV(mapValues);
		Util.exportToCSV(exportData, 'financial-portfolio-report.csv');
	}
};
