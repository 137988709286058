import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import ChartJsLine from '../../chartjs/chart_js_line';

class BurnUpDummy extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}
	render() {
		const {formatMessage} = this.props.intl;
		const data = {
			labels: [],
			datasets: [],
		};
		const dates = ['20180115', '20180415', '20180715', '20180915', '20181115', '20190215'];
		dates.forEach(dateString => {
			const momentDate = moment(dateString, 'YYYYMMDD');
			const date = momentDate.toDate();
			data.labels.push(date);
		});
		data.datasets.push({
			label: formatMessage({id: 'insights.component.burnUp.scope'}),
			borderColor: '#28AEFB',
			fill: false,
			lineTension: 0,
			stack: 'Stack 0',
			data: [0, 71, 151, 171, 207, 210],
		});
		data.datasets.push({
			label: formatMessage({id: 'insights.component.burnUp.work_completed'}),
			borderColor: '#FF746C',
			fill: false,
			lineTension: 0,
			stack: 'Stack 1',
			data: [0, 24, 120, 158, null, null],
		});
		data.datasets.push({
			label: formatMessage({id: 'insights.component.burnUp.ideal_velocity'}),
			borderColor: '#F6C4FC',
			fill: false,
			lineTension: 0,
			stack: 'Stack 0',
			spanGaps: true,
			data: [0, null, null, null, null, 210],
		});
		return (
			<ChartJsLine data={data} height={360} useTimeLineChart={true} yAxesTitle={formatMessage({id: 'common.hours'})} />
		);
	}
}
export default injectIntl(BurnUpDummy);
