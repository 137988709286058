import {
	createOrUpdateAllocation,
	createOrUpdateMultipleAllocations,
	createOrUpdateMultiplePlaceholderAllocations,
	createOrUpdatePlaceholderAllocation,
	deleteAllocation,
	deletePlaceholderAllocation,
} from './PlaceholdersSchedulingSuccessLogic';
import {
	addMultipleDataEntitiesToChangeList,
	addToChangeList,
	createGhost,
	isStaffingGeneratedId,
	removeFromChangeList,
} from './CanvasPlaceholdersSchedulingUtil';
import {STAFFING_CHANGE_LIST_ENTITIES, STAFFING_OPERATION} from './CanvasPlaceholderSchedulingConstants';
import {isEventFromScheduling} from '../utils';

export const onSavedFiltersStaffingUpdate = (pageComponent, response) => {
	const stateData = pageComponent.getData();

	if (response.createFilter) {
		stateData.peopleFilters.push(response.createFilter.filter.node);
	} else if (response.deleteFilter) {
		stateData.peopleFilters = stateData.peopleFilters.filter(filter => filter.id !== response.deleteFilter.deletedFilterId);
	}

	pageComponent.setState({data: stateData});
};

function removePlaceholderAllocationFromStaffing(pageComponent, placeholderAllocation) {
	if (isStaffingGeneratedId(placeholderAllocation.id)) {
		// remove staffing placeholder allocation
		removeFromChangeList(
			pageComponent,
			STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_CREATE_UPDATE,
			placeholderAllocation
		);
	} else {
		// add placeholder allocation to remove
		addToChangeList(pageComponent, STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_DELETE, placeholderAllocation);
	}
}

const deletePlaceholderAllocations = (data, pageComponent, deletedPlaceholderAllocationIds) => {
	deletedPlaceholderAllocationIds.forEach(deletedPlaceholderAllocationId => {
		const placeholderAllocation = data.placeholderAllocations.find(
			placeholderAllocation => placeholderAllocation.id === deletedPlaceholderAllocationId
		);

		if (placeholderAllocation.personId) {
			// If placeholder allocation was already moved, deletion has already been handled
			return;
		}

		deletePlaceholderAllocation(pageComponent, deletedPlaceholderAllocationId);
		removePlaceholderAllocationFromStaffing(pageComponent, placeholderAllocation);

		createGhost(pageComponent, pageComponent.state.items, placeholderAllocation);
	});
};

const createProjectAllocations = (pageComponent, createdProjectAllocations) => {
	createdProjectAllocations.forEach(allocation => {
		createOrUpdatePlaceholderAllocation(pageComponent, allocation.node);
		addToChangeList(pageComponent, STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE, allocation.node, {
			staffingOperation: STAFFING_OPERATION.TRANSFER,
		});
	});
};

export const handlePlaceholdersSchedulingStaffingOperation = (pageComponent, response, args) => {
	if (!isEventFromScheduling(args)) {
		return;
	}

	const data = pageComponent.getData();

	if (response.splitPlaceholderAllocation || response.bulkPlaceholderAllocations) {
		const placeholderAllocations =
			response.splitPlaceholderAllocation?.placeholderAllocations ||
			response.bulkPlaceholderAllocations.placeholderAllocations;
		createOrUpdateMultiplePlaceholderAllocations(pageComponent, placeholderAllocations);
		if (response.personId) {
			addMultipleDataEntitiesToChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE,
				placeholderAllocations
			);
		} else {
			addMultipleDataEntitiesToChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_CREATE_UPDATE,
				placeholderAllocations
			);
		}
	} else if (response.duplicatePlaceholderAllocation) {
		const newPlaceholderAllocation = response.duplicatePlaceholderAllocation.placeholderAllocation.node;
		createOrUpdatePlaceholderAllocation(pageComponent, newPlaceholderAllocation);
		if (newPlaceholderAllocation.personId) {
			addToChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE,
				newPlaceholderAllocation
			);
		} else {
			addToChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_CREATE_UPDATE,
				newPlaceholderAllocation
			);
		}
	} else if (response.deletePlaceholderAllocation) {
		const placeholderAllocation = response.deletePlaceholderAllocation.placeholderAllocation;
		deletePlaceholderAllocation(pageComponent, placeholderAllocation.id);

		if (isStaffingGeneratedId(placeholderAllocation.id)) {
			removeFromChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_CREATE_UPDATE,
				placeholderAllocation
			);
		} else {
			addToChangeList(pageComponent, STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_DELETE, placeholderAllocation);
		}
	} else if (response.createPlaceholderAllocation || response.updatePlaceholderAllocation) {
		const operation = response.createPlaceholderAllocation || response.updatePlaceholderAllocation;
		const placeholderAllocation = operation.placeholderAllocation.node || operation.placeholderAllocation;

		createOrUpdatePlaceholderAllocation(pageComponent, placeholderAllocation);
		if (placeholderAllocation.personId) {
			addToChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE,
				placeholderAllocation
			);
		} else {
			addToChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_CREATE_UPDATE,
				placeholderAllocation
			);
		}
	} else if (response.staffingPlaceholderAllocation) {
		const placeholderAllocation = response.staffingPlaceholderAllocation.placeholderAllocation;
		createOrUpdatePlaceholderAllocation(pageComponent, placeholderAllocation);

		if (placeholderAllocation.personId) {
			removePlaceholderAllocationFromStaffing(pageComponent, placeholderAllocation);

			// add placeholder allocation to project allocation create
			addToChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE,
				placeholderAllocation,
				{
					staffingOperation: STAFFING_OPERATION.DRAG,
				}
			);
		} else {
			// add placeholder allocation to remove
			removeFromChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_DELETE,
				placeholderAllocation
			);

			// add placeholder allocation to project allocation create
			removeFromChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE,
				placeholderAllocation
			);

			// add to update list
			addToChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_CREATE_UPDATE,
				placeholderAllocation
			);
		}
	} else if (response.updateAllocation || response.createAllocation) {
		const operation = response.updateAllocation || response.createAllocation;
		const allocation = operation.allocation?.node || operation.allocation;
		createOrUpdateAllocation(pageComponent, allocation);
		addToChangeList(pageComponent, STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE, allocation);
	} else if (response.duplicateAllocation) {
		const newAllocation = response.duplicateAllocation.allocation.node;
		createOrUpdateAllocation(pageComponent, newAllocation);
		addToChangeList(pageComponent, STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE, newAllocation);
	} else if (response.splitAllocation) {
		const allocations = response.splitAllocation.allocations;
		createOrUpdateMultipleAllocations(pageComponent, allocations);
		addMultipleDataEntitiesToChangeList(
			pageComponent,
			STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE,
			allocations
		);
	} else if (response.deleteAllocation) {
		const allocation = response.deleteAllocation;
		deleteAllocation(pageComponent, allocation);

		removeFromChangeList(pageComponent, STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE, allocation);

		if (!isStaffingGeneratedId(allocation.id)) {
			addToChangeList(pageComponent, STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_DELETE, allocation);
		}
	} else if (response.transferPlaceholder) {
		if (response.transferPlaceholder.placeholderAllocation) {
			const placeholderAllocation = response.transferPlaceholder.placeholderAllocation;
			createOrUpdatePlaceholderAllocation(pageComponent, placeholderAllocation);
			addToChangeList(
				pageComponent,
				STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_CREATE_UPDATE,
				placeholderAllocation
			);
		} else if (response.transferPlaceholder.deletedPlaceholderAllocationId) {
			const deletedPlaceholderAllocationId = response.transferPlaceholder.deletedPlaceholderAllocationId;
			deletePlaceholderAllocations(data, pageComponent, [deletedPlaceholderAllocationId]);
		}

		// create project allocation
		const allocation = response.transferPlaceholder.personAllocation;
		if (allocation) {
			// reference for ghosting
			if (response.transferPlaceholder.deletedPlaceholderAllocationId) {
				allocation.id = response.transferPlaceholder.deletedPlaceholderAllocationId;
			}

			createOrUpdatePlaceholderAllocation(pageComponent, allocation);
			addToChangeList(pageComponent, STAFFING_CHANGE_LIST_ENTITIES.PROJECT_ALLOCATIONS_CREATE_UPDATE, allocation, {
				staffingOperation: STAFFING_OPERATION.TRANSFER,
			});
		}
	} else if (response.replacePlaceholder) {
		const deletedPlaceholderAllocationIds = response.replacePlaceholder.deletedPlaceholderAllocationIds;
		const createdProjectAllocations = response.replacePlaceholder.allocations;
		deletePlaceholderAllocations(data, pageComponent, deletedPlaceholderAllocationIds);
		createProjectAllocations(pageComponent, createdProjectAllocations);
	} else if (response.assignPlaceholderAllocationsToPerson) {
		const deletedPlaceholderAllocationIds = response.assignPlaceholderAllocationsToPerson.deletedPlaceholderAllocationIds;
		const createdProjectAllocations = response.assignPlaceholderAllocationsToPerson.allocations;
		deletePlaceholderAllocations(data, pageComponent, deletedPlaceholderAllocationIds);
		createProjectAllocations(pageComponent, createdProjectAllocations);
	}

	pageComponent.redrawCanvasTimeline({preventFiltering: false});

	requestAnimationFrame(() => {
		pageComponent.redrawCanvasTimeline({preventFiltering: false});
	});
};

export const onSchedulingStaffingSuccess = (pageComponent, response, args) => {
	handlePlaceholdersSchedulingStaffingOperation(pageComponent, response, args);
};
