import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import ChartJsPie from '../../chartjs/chart_js_pie';

class SprintCompletedDummy extends Component {
	componentDidMount() {
		this.props.notifyOnReady(this.props.id);
	}
	render() {
		const {formatMessage} = this.props.intl;
		const data = {
			labels: [
				formatMessage({id: 'insights.component.sprint.sprints_done'}, {count: 3}),
				formatMessage({id: 'insights.component.sprint.sprints_left'}, {count: 5}),
			],
			datasets: [
				{
					data: [3, 5],
					backgroundColor: ['#FF7C75', '#FFBAB6'],
				},
			],
		};
		return <ChartJsPie data={data} />;
	}
}
export default injectIntl(SprintCompletedDummy);
