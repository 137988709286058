import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Person from '../../../forecast-app/shared/components/person/person';
import InsightsUtil from '../insights_util';
import Util from '../../../forecast-app/shared/util/util';
import {cloneDeep} from 'lodash';
import {profilePicSrc} from '../../../directApi';

class cardListComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sortBy: {column: null, ascending: true},
		};
	}

	UNSAFE_componentWillMount() {
		const listHeight = InsightsUtil.GetlistHeight(this.props.viewer.insightComponentsData.cardList.tasks.length);
		if (this.props.height !== listHeight) {
			this.props.updateComponentHeight(this.props.id, listHeight);
		}
		if (this.props.setExportColumns) {
			const configObject = this.props.config ? JSON.parse(this.props.config) : {};
			let exportColumns = [];
			if (configObject) {
				this.props.columns.forEach(col => {
					if (configObject.columns.indexOf(col) > -1) {
						if (
							[
								'progress',
								'forecast',
								'reported',
								'remaining',
								'projected',
								'assigned',
								'labels',
								'diff',
								'low',
								'high',
							].includes(col)
						) {
							exportColumns.push(col + '_raw');
						} else {
							exportColumns.push(col);
						}
					}
				});
			}
			this.props.setExportColumns(exportColumns);
		}
		this.setData(this.props, this.state);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.projectId !== nextProps.projectId) {
			this.props.relay.refetch({projectId: nextProps.projectId, isProjectGroupType: false});
		}
		if (this.props.projectGroupId !== nextProps.projectGroupId) {
			this.props.relay.refetch({projectId: nextProps.projectGroupId, isProjectGroupType: true});
		}
		if (this.props.sprintFilterValue.length !== nextProps.sprintFilterValue.length) {
			const sprintIds = nextProps.sprintFilterValue.length !== 0 ? nextProps.sprintFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({sprintIds});
		}
		if (this.props.approvedFilterValue !== nextProps.approvedFilterValue) {
			this.props.relay.refetch({approvedFilterValue: nextProps.approvedFilterValue});
		}
		// Calculate height based on amount of cards in nextProps
		const listHeight = InsightsUtil.GetlistHeight(nextProps.viewer.insightComponentsData.cardList.tasks.length);
		if (this.props.height !== listHeight) {
			this.props.updateComponentHeight(this.props.id, listHeight);
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		this.setData(nextProps, nextState);
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	setData(props, state) {
		const {formatMessage, formatDate, formatNumber} = props.intl;
		const {cardList} = cloneDeep(this.props.viewer.insightComponentsData);
		const isInHours = cardList.useHours;
		const minutesPerPoint = cardList.minutesPerPoint;
		const reportedTotal = cardList.reportedTotal / 60.0;
		const remainingTotal = isInHours ? cardList.remainingTotal / 60.0 : cardList.remainingTotal;
		const projectedTotal = reportedTotal + (isInHours ? remainingTotal : (remainingTotal * minutesPerPoint) / 60.0);
		const currencySymbol = Util.GetCurrencySymbol(cardList.currency);
		const isDollarOrEuro = Util.CurrencyIsPrefixed(currencySymbol);
		this.cards = [];
		this.totals = {
			forecast: isInHours
				? Util.convertMinutesToFullHour(cardList.forecastTotal, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: cardList.forecastTotal}),
			remaining: isInHours
				? Util.convertMinutesToFullHour(remainingTotal * 60, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: remainingTotal}),
			reported: Util.convertMinutesToFullHour(reportedTotal * 60, props.intl),
			projected: Util.convertMinutesToFullHour(projectedTotal * 60, props.intl),
			diff: Util.convertMinutesToFullHour(cardList.differenceTotal, props.intl),
		};
		cardList.tasks.forEach(task => {
			const card = {};
			card.id = 'T' + task.companyTaskId;
			card.companyTaskId = task.companyTaskId;
			card.name = task.name;
			card.companyProjectId = 'P-' + task.companyProjectId;
			card.projectName = task.projectName;
			card.sprint = task.sprintName || formatMessage({id: 'project_sprints.backlog'});
			card.phase = task.phaseName || '';
			card.cardStatus = task.statusColumnName || '';
			card.forecast_raw = isInHours ? Math.round((task.estimateForecast / 60.0) * 100) / 100 : task.estimateForecast;
			card.forecast = isInHours
				? Util.convertMinutesToFullHour(task.estimateForecast, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: task.estimateForecast});
			card.costForecast = isDollarOrEuro
				? currencySymbol + formatNumber(task.costForecast)
				: formatNumber(task.costForecast) + currencySymbol;
			card.remaining_raw = isInHours ? Math.round((task.timeLeft / 60.0) * 100) / 100 : task.timeLeft;
			card.remaining = isInHours
				? Util.convertMinutesToFullHour(task.timeLeft, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: task.timeLeft});
			card.reported_raw = Math.round((task.reportedMinutes / 60.0) * 100) / 100;
			card.reported = Util.convertMinutesToFullHour(task.reportedMinutes, props.intl);
			const projected =
				card.reported_raw + (isInHours ? card.remaining_raw : (minutesPerPoint * card.remaining_raw) / 60.0);
			card.projected_raw = projected;
			card.projected = Util.convertMinutesToFullHour(projected * 60, props.intl);
			card.diff_raw = isInHours ? Math.round((task.diff / 60.0) * 100) / 100 : task.diff;
			card.diff = isInHours
				? Util.convertMinutesToFullHour(task.diff, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: task.diff});
			card.startDate_raw = task.startYear ? new Date(task.startYear, task.startMonth - 1, task.startDay) : null;
			card.startDate = task.startYear ? formatDate(new Date(task.startYear, task.startMonth - 1, task.startDay)) : null;
			card.deadline_raw = task.deadlineYear
				? new Date(task.deadlineYear, task.deadlineMonth - 1, task.deadlineDay)
				: null;
			card.deadline = task.deadlineYear
				? formatDate(new Date(task.deadlineYear, task.deadlineMonth - 1, task.deadlineDay))
				: null;
			card.assigned_raw = task.assignedPersons
				? task.assignedPersons.map(person => {
						return person.firstName + ' ' + person.lastName;
				  })
				: [];
			card.assigned = task.assignedPersons
				.sort((a, b) => {
					const name_a = a ? a.firstName.toLowerCase() + ' ' + a.lastName.toLowerCase() : '';
					const name_b = b ? b.firstName + ' ' + b.lastName : '';
					if (name_a < name_b) return -1;
					if (name_a > name_b) return 1;
					return 0;
				})
				.slice(0, 3)
				.map((person, index) =>
					index === 2 ? (
						<div className="person-elipsis-view" key={person.id}>
							<Person
								key={person.id}
								name={person.firstName + ' ' + person.lastName}
								role={''}
								showName={false}
								showRole={false}
								imageSrc={profilePicSrc(person.profilePictureId)}
								imageSize="medium"
							/>
							<span>...</span>
						</div>
					) : (
						<Person
							key={person.id}
							name={person.firstName + ' ' + person.lastName}
							role={''}
							showName={false}
							showRole={false}
							imageSrc={profilePicSrc(person.profilePictureId)}
							imageSize="medium"
						/>
					)
				);
			card.approved = formatMessage({id: task.approved ? 'common.yes' : 'common.no'});
			const progress = Util.calculateElementProgress(
				task.estimateForecast,
				task.reportedMinutes,
				task.timeLeft,
				null,
				null,
				isInHours,
				minutesPerPoint,
				true,
				task.statusDone
			);

			card.progress_raw = progress;
			card.progress = (
				<div title={progress + '%'} className="progress-container" style={{border: '1px solid rgb(68, 180, 255)'}}>
					<div className="progress-indicator" style={{width: progress + '%', backgroundColor: 'rgb(68, 180, 255)'}} />
				</div>
			);

			card.bug = task.bug ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'});
			card.billable = task.billable ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'});
			card.blocked = task.blocked ? formatMessage({id: 'common.yes'}) : formatMessage({id: 'common.no'});
			card.labels = task.labels;
			card.labels_raw = task.labels.map((label, index) => {
				return label.name;
			});
			this.cards.push(card);
		});

		if (state.sortBy.column) {
			this.cards = this.cards.sort((a, b) => {
				let column = state.sortBy.column;
				switch (column) {
					case 'low':
					case 'high':
					case 'forecast':
					case 'remaining':
					case 'reported':
					case 'projected':
					case 'progress':
					case 'assigned':
					case 'startDate':
					case 'deadline':
					case 'diff':
						column += '_raw';
						break;
					case 'id':
						column = 'companyTaskId';
						break;
					default:
						break;
				}

				let returnValue = 0;
				if (a[column] < b[column]) returnValue = 1;
				if (a[column] > b[column]) returnValue = -1;
				if (a[column] === '') returnValue = -1;

				return state.sortBy.ascending ? returnValue * -1 : returnValue;
			});
		}
		if (props.setExportData) {
			props.setExportData(this.cards);
		}
		// this.props.recalculateListsPosition();
	}

	render() {
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};
		const columns = this.props.projectGroupId
			? this.props.columns
			: this.props.columns.filter(col => col !== 'projectName' && col !== 'companyProjectId');
		return (
			<InsightList
				componentId={this.props.id}
				data={this.cards}
				componentName={'cardList'}
				activeColumns={configObject ? configObject.columns : []}
				allColumns={columns}
				sortBy={this.state.sortBy}
				setSortBy={this.setSortBy.bind(this)}
				showTaskModal={this.props.shareKey !== '' && this.props.shareKey != null ? null : this.props.showTaskModal}
				totals={this.totals}
				disableSort="labels"
				scrollElement={this.props.scrollElement}
				scrollElementFullScreen={this.props.scrollElementFullScreen}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

cardListComponent.propTypes = {
	title: PropTypes.string,
};

const cardListComponentQuery = graphql`
	query cardListComponent_Query(
		$shareKey: String
		$componentId: ID
		$projectId: ID
		$isProjectGroupType: Boolean
		$sprintIds: [ID]
		$approvedFilterValue: Boolean
	) {
		viewer {
			actualPersonId
			component(name: "insight_card_list")
			...cardListComponent_viewer
				@arguments(
					shareKey: $shareKey
					componentId: $componentId
					projectId: $projectId
					isProjectGroupType: $isProjectGroupType
					sprintIds: $sprintIds
					approvedFilterValue: $approvedFilterValue
				)
		}
	}
`;

export {cardListComponentQuery};

export default injectIntl(
	createRefetchContainer(
		cardListComponent,
		{
			viewer: graphql`
				fragment cardListComponent_viewer on Viewer
				@argumentDefinitions(
					shareKey: {type: "String"}
					componentId: {type: "ID"}
					projectId: {type: "ID"}
					isProjectGroupType: {type: "Boolean"}
					sprintIds: {type: "[ID]"}
					approvedFilterValue: {type: Boolean}
				) {
					availableFeatureFlags {
						key
					}
					insightComponentsData(shareKey: $shareKey) {
						cardList(
							componentId: $componentId
							projectId: $projectId
							isProjectGroupType: $isProjectGroupType
							sprintIds: $sprintIds
							approvedFilterValue: $approvedFilterValue
						) {
							useHours
							minutesPerPoint
							forecastTotal
							reportedTotal
							remainingTotal
							differenceTotal
							currency
							tasks {
								id
								approved
								companyTaskId
								projectName
								companyProjectId
								name
								description
								timeLeft
								diff
								startYear
								startMonth
								startDay
								deadlineDay
								deadlineMonth
								deadlineYear
								estimateForecast
								costForecast
								blocked
								bug
								billable
								sprintName
								phaseName
								assignedPersons {
									id
									firstName
									lastName
									profilePictureId
									profilePictureDefaultId
								}
								statusColumnName
								statusDone
								subTaskCount
								doneSubTaskCount
								fileCount
								commentCount
								reportedMinutes
								labels {
									id
									name
									color
								}
							}
						}
					}
				}
			`,
		},
		graphql`
			query cardListComponentRefetchQuery(
				$shareKey: String
				$componentId: ID
				$projectId: ID
				$isProjectGroupType: Boolean
				$sprintIds: [ID]
				$approvedFilterValue: Boolean
			) {
				viewer {
					...cardListComponent_viewer
						@arguments(
							shareKey: $shareKey
							componentId: $componentId
							projectId: $projectId
							isProjectGroupType: $isProjectGroupType
							sprintIds: $sprintIds
							approvedFilterValue: $approvedFilterValue
						)
				}
			}
		`
	)
);
