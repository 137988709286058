import React, {useState} from 'react';
import {Table} from 'web-components';
import {getBaseLevelTableHeader} from '../BaseLevelTableHeader';
import CustomScrollDiv from '../../../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {PageContent} from '../styling/reported_time_table_styled';
import {getBaseLevelTableHeaderTotals} from '../BaseLevelTableHeaderTotals';
const BaseLevelWrapper = ({tableHeader, getRows, enabledColumns, currentLength, hasNextPage, refetch}) => {
	const [modalTasksFetching, setModalTasksFetching] = useState(false);

	const fetchMore = () => {
		const refetchObject = {};
		if (hasNextPage) {
			setModalTasksFetching(true);
			refetchObject.showLoader = true;
			refetchObject.pageSize = currentLength + 30;
			refetch(refetchObject, null, () => setModalTasksFetching(false));
		} else if (modalTasksFetching) {
			setModalTasksFetching(false);
		}
	};

	const handleScroll = e => {
		if (!modalTasksFetching && e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 300) {
			fetchMore();
		}
	};

	return (
		<CustomScrollDiv className="custom-scrollbar-div" handleScroll={handleScroll}>
			<PageContent>
				<Table>
					{/* <BaseLevelTableHeader intl {...tableHeader} /> */}
					{getBaseLevelTableHeader(enabledColumns, tableHeader.customFieldDefinitions)}
					{getBaseLevelTableHeaderTotals(tableHeader)}
					{getRows()}
				</Table>
			</PageContent>
		</CustomScrollDiv>
	);
};

export default BaseLevelWrapper;
