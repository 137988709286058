import React from 'react';
import {useIntl} from 'react-intl';
import Styled from 'styled-components';
import moment from 'moment';

export const LastEditInfoStyled = Styled.div`
    color:#A5A5AE;
    font-size:10px;
`;

const LastEditInfo = ({name, date, byYou}) => {
	const intl = useIntl();

	const editedBy = byYou ? intl.formatMessage({id: 'common.you'}) : name;

	const dateMoment = moment(date);
	const diff = moment().diff(dateMoment, 'days');
	return (
		<LastEditInfoStyled title={dateMoment.format('LL')}>
			{diff === 0
				? intl.formatMessage(
						{id: 'saved_report.edited_today_at_by'},
						{hour: dateMoment.format('HH:mm'), name: editedBy}
				  )
				: diff === 1
				? intl.formatMessage(
						{id: 'saved_report.edited_yesterday_at_by'},
						{hour: dateMoment.format('HH:mm'), name: editedBy}
				  )
				: intl.formatMessage({id: 'saved_report.edited_the_by'}, {date: dateMoment.format('ll'), name: editedBy})}
		</LastEditInfoStyled>
	);
};

export default LastEditInfo;
