import React from 'react';
import Styled from 'styled-components';
import {Checkbox, Switch} from 'web-components';
import {hasFeatureFlag} from '../../../../forecast-app/shared/util/FeatureUtil';

const ToggleItem = ({name, description, onToggle, state, locked = false}) => {
	const hasBetaChanges = hasFeatureFlag('capacity_planning_beta_2_improvements');
	const onChange = () => {
		onToggle(!state);
	};

	return (
		<Container>
			<FlexContainer>
				{!hasBetaChanges && <Switch onChange={onChange} checked={state && !locked} locked={locked} />}
				{hasBetaChanges && <Checkbox onChange={onChange} checked={state && !locked} disabled={locked} />}
				<ToggleName>{name}</ToggleName>
			</FlexContainer>
			{description && <Description>{description}</Description>}
		</Container>
	);
};

const FlexContainer = Styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding-bottom: 4px;
`;

const Container = Styled.div`
	background-color: transparent;
	padding: 12px;
	box-sizing: border-box;
	width: 100%;
	border-bottom: 1px solid #e6e6e6;
`;

const ToggleName = Styled.span`
	margin-left: 8px;
`;

const Description = Styled.div`
	font-size: 11px;
	padding-left: 26px;
`;

export default ToggleItem;
