import React, {Component} from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import util from '../../../forecast-app/shared/util/util';
import InsightsUtil from '../insights_util';

class expenseReportPortfolioComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sortBy: {column: null, ascending: true},
		};
	}

	UNSAFE_componentWillMount() {
		const listHeight = InsightsUtil.GetlistHeight(
			this.props.viewer.insightComponentsData.expenseReportPortfolio.data.length
		);
		this.props.updateComponentHeight(this.props.id, listHeight);
		if (this.props.setExportColumns) {
			let exportColumns = [];
			this.props.columns.forEach(col => {
				if (
					[
						'name',
						'billable',
						'projectName',
						'client',
						'expenseCategory',
						'createdBy',
						'createdAt',
						'updatedBy',
						'updatedAt',
						'companyProjectId',
						'date',
					].includes(col)
				) {
					exportColumns.push(col);
				} else {
					exportColumns.push(col + '_raw');
				}
			});
			this.props.setExportColumns(exportColumns);
		}
	}

	formatAmount(amount, currency) {
		const {formatNumber} = this.props.intl;
		const currencySymbol = util.GetCurrencySymbol(currency);
		const isSymbolInFront = util.CurrencyIsPrefixed(currencySymbol);
		return `${isSymbolInFront ? currencySymbol : ''}${formatNumber(amount)}${!isSymbolInFront ? currencySymbol : ''}`;
	}

	formatDate(epoch) {
		const dateObject = new Date(parseInt(epoch, 10));
		const dateString = dateObject.toLocaleDateString();
		const timeString = dateObject.toLocaleString([], {hour: '2-digit', minute: '2-digit'});
		return `${dateString} ${timeString}`;
	}

	getData() {
		const {formatMessage, formatNumber, formatDate} = this.props.intl;
		const globalData = this.props.viewer.insightComponentsData.expenseReportPortfolio;
		const data = globalData.data;
		const result = [];
		const currency = globalData.currency;
		for (const expense of data) {
			const row = {};
			row.id = expense.id;
			row.name = expense.name;
			row.cost = this.formatAmount(expense.cost, currency);
			row.cost_raw = expense.cost;
			row.price = expense.price ? this.formatAmount(expense.price, currency) : '-';
			row.price_raw = expense.price;
			row.markup = expense.markupPercentage ? formatNumber(expense.markupPercentage) + '%' : '-';
			row.markup_raw = expense.markupPercentage;
			row.quantity = formatNumber(expense.quantity);
			row.quantity_raw = expense.quantity;
			row.expenseCategory = expense.expenseCategory.name;
			const expenseDate = expense.expenseYear
				? util.CreateNonUtcMomentDate(expense.expenseYear, expense.expenseMonth, expense.expenseDay)
				: null;
			row.date = expenseDate ? formatDate(expenseDate) : null;
			row.date_raw = expenseDate !== null ? expenseDate.toDate() : null;
			row.notes = expense.notes;
			row.notes_raw = expense.notes ? util.ConvertDraftJsToPlainText(expense.notes) : null;
			row.billable = formatMessage({id: expense.billable ? 'common.yes' : 'common.no'});
			row.person = expense.person;
			row.person_raw = expense.person ? `${expense.person.firstName} ${expense.person.lastName}` : null;
			row.createdAt = this.formatDate(expense.createdAt);
			row.createdAt_raw = expense.createdAt;
			row.updatedAt = this.formatDate(expense.updatedAt);
			row.updatedAt_raw = expense.updatedAt;
			row.createdBy = expense.createdBy ? `${expense.createdBy.firstName} ${expense.createdBy.lastName}` : '';
			row.updatedBy = expense.updatedBy ? `${expense.updatedBy.firstName} ${expense.updatedBy.lastName}` : '';
			row.companyProjectId = expense.project.customProjectId
				? expense.project.customProjectId
				: `P-${expense.project.companyProjectId}`;
			row.companyProjectId_raw = expense.project.customProjectId
				? expense.project.customProjectId
				: expense.project.companyProjectId;
			row.projectName = expense.project.name;
			row.client = expense.client ? expense.client.name : '';
			result.push(row);
		}
		const sortBy = this.state.sortBy;
		if (sortBy.column) {
			result.sort((a, b) => {
				let column = sortBy.column;
				switch (column) {
					case 'id':
					case 'name':
					case 'billable':
					case 'createdBy':
					case 'updatedBy':
					case 'expenseCategory':
					case 'projectName':
					case 'client':
						break;
					default:
						column += '_raw';
						break;
				}
				let res = 0;
				if (a[column] < b[column]) res = 1;
				if (a[column] > b[column]) res = -1;

				return sortBy.ascending ? res * -1 : res;
			});
		}
		if (this.props.setExportData) {
			this.props.setExportData(result);
		}
		return result;
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	render() {
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};
		return (
			<InsightList
				componentId={this.props.id}
				data={this.getData()}
				componentName={'ExpenseReportPortfolio'}
				activeColumns={configObject ? configObject.columns : []}
				allColumns={this.props.columns}
				sortBy={this.state.sortBy}
				setSortBy={this.setSortBy.bind(this)}
				scrollElement={this.props.scrollElement}
				scrollElementFullScreen={this.props.scrollElementFullScreen}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

const expenseReportPortfolioComponentQuery = graphql`
	query expenseReportPortfolioComponent_Query($shareKey: String) {
		viewer {
			actualPersonId
			component(name: "insight_expense_report_portfolio")
			...expenseReportPortfolioComponent_viewer @arguments(shareKey: $shareKey)
		}
	}
`;

export {expenseReportPortfolioComponentQuery};

export default injectIntl(
	createFragmentContainer(expenseReportPortfolioComponent, {
		viewer: graphql`
			fragment expenseReportPortfolioComponent_viewer on Viewer @argumentDefinitions(shareKey: {type: "String"}) {
				insightComponentsData(shareKey: $shareKey) {
					expenseReportPortfolio {
						id
						currency
						data {
							id
							name
							cost
							price
							markupPercentage
							quantity
							expenseYear
							expenseMonth
							expenseDay
							expenseCategory {
								name
							}
							notes
							billable
							person {
								firstName
								lastName
								profilePictureId
								profilePictureDefaultId
							}
							createdAt
							createdBy {
								id
								firstName
								lastName
							}

							updatedAt
							updatedBy {
								id
								firstName
								lastName
							}

							project {
								id
								name
								companyProjectId
								customProjectId
							}
							client {
								id
								name
							}
						}
					}
				}
			}
		`,
	})
);
