import React from 'react';
import {getQueryRenderer} from '../reported_time_page_util';
import {GLOBAL_FILTER_FIELD, GLOBAL_FILTER_OPERATOR} from '../../../../constants';
import {CalculationLoader, Table} from 'web-components';
import Util from '../../../../forecast-app/shared/util/util';
import {getNumberOfCustomFields} from '../reported_time_table_util';

export const getRoleRow = (
	data,
	currency,
	intl,
	groupIndex,
	rolesFinancialMap,
	searchQuery,
	groupings,
	enabledColumns,
	sortValue,
	hasNextPage,
	fetchMore,
	nextLevelProps,
	tableHeader
) => {
	const numberOfCustomFields = getNumberOfCustomFields(tableHeader);
	const getColumns = role => {
		const formattedPrice = role.totalPrice && intl.formatNumber(role.totalPrice, {format: 'always_two_decimal'});
		const formattedCost = role.totalCost && intl.formatNumber(role.totalCost, {format: 'always_two_decimal'});

		return [
			<Table.Column>
				<span>{role.name}</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>{Util.convertMinutesToFullHour(role.totalMinutesRegistered, intl)}</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column />,
			<Table.Column>
				<span>
					{formattedPrice !== null ? (
						Util.GetFormattedCurrencySymbol(currency, formattedPrice)
					) : (
						<CalculationLoader />
					)}
				</span>
			</Table.Column>,
			<Table.Column>
				<span>
					{formattedCost !== null ? Util.GetFormattedCurrencySymbol(currency, formattedCost) : <CalculationLoader />}
				</span>
			</Table.Column>,
			<Table.Column />,
			<Table.Column />,
			[...Array(numberOfCustomFields)].map(_ => <Table.Column />),
		];
	};

	return (
		<Table.Rows
			data={data}
			canExpand={true}
			autoload={hasNextPage && groupIndex === 1}
			loadMore={hasNextPage ? fetchMore : false}
			{...nextLevelProps}
		>
			{({rowData, tableColumnsProps, nextLevelProps, expanded}) => {
				const filterField =
					rowData.isNoRole === true ? GLOBAL_FILTER_FIELD.NON_ROLE_TIME_REG : GLOBAL_FILTER_FIELD.ROLE_TIME_REG;
				return (
					<>
						<Table.Row {...tableColumnsProps}>{getColumns(rowData)}</Table.Row>
						{expanded &&
							getQueryRenderer(
								tableHeader,
								groupings,
								searchQuery,
								groupIndex,
								enabledColumns,
								{field: filterField, operator: GLOBAL_FILTER_OPERATOR.IS, value: [rowData.id]},
								nextLevelProps,
								sortValue
							)}
					</>
				);
			}}
		</Table.Rows>
	);
};
