import React from 'react';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import {Chart} from 'web-components';
import {selectValueWithOptions} from './UtilizationReportUtils';

const getData = (
	startDate,
	endDate,
	companyTarget,
	aggregatedResourceNumbersByDay,
	showResourceUtilization,
	intl,
	isMixedAllocationModeEnabled,
	allocationControlsOptions
) => {
	const dataSets = [];

	if (!showResourceUtilization && companyTarget) {
		const companyTargetDataSet = [];
		const addedWeeks = {};

		let date = startDate ? moment(startDate.format('YYYY-MM-DD'), 'YYYY-MM-DD') : moment([2000, 0, 1], 'YYYY-MM-DD');
		while (date.isBefore(endDate) || (endDate && date.format('YYYY-MM-DD') === endDate.format('YYYY-MM-DD'))) {
			const yearWeek = `${date.weekYear()}-${date.week()}`;
			if (!addedWeeks[yearWeek]) {
				addedWeeks[yearWeek] = true;
				companyTargetDataSet.push({date: date.clone().startOf('week'), value: companyTarget * 100});
			}
			date = moment(date.clone().add(1, 'day').format('YYYY-MM-DD'), 'YYYY-MM-DD');
		}
		dataSets.push({
			name: intl.formatMessage({id: 'settings.billable_utilization_target'}),
			color: '#0080FF',
			filled: true,
			backgroundColor: 'transparent',
			dashed: true,
			data: companyTargetDataSet,
		});
	}

	const aggregatedResourceNumbersByWeek = {};

	if (aggregatedResourceNumbersByDay.length === 0) {
		aggregatedResourceNumbersByDay.push({
			startDate,
			endDate,
			availableMinutes: 0,
			forecastBillableProjectMinutes: 0,
			forecastTotalMinutes: 0,
		});
	}

	aggregatedResourceNumbersByDay.forEach(resourceNumberPeriod => {
		const periodStartDate = resourceNumberPeriod.startDate;
		const periodEndDate = resourceNumberPeriod.endDate;

		if (periodStartDate.isBefore(periodEndDate) || periodStartDate.isSame(periodEndDate)) {
			const availableMinutes = resourceNumberPeriod.availableMinutes;
			const forecastBillableProjectMinutes = resourceNumberPeriod.forecastBillableProjectMinutes;
			const forecastBillableProjectMinutesSoft = resourceNumberPeriod.forecastBillableProjectMinutesSoft;
			const forecastBillableProjectMinutesWin = resourceNumberPeriod.forecastBillableProjectMinutesWin;
			const forecastBillableProjectMinutesSoftWin = resourceNumberPeriod.forecastBillableProjectMinutesSoftWin;
			const forecastBillableProjectMinutesHard = resourceNumberPeriod.forecastBillableProjectMinutesHard;
			const forecastTotalMinutes = resourceNumberPeriod.forecastTotalMinutes;
			const forecastTotalMinutesSoft = resourceNumberPeriod.forecastTotalMinutesSoft;
			const forecastTotalMinutesWin = resourceNumberPeriod.forecastTotalMinutesWin;
			const forecastTotalMinutesSoftWin = resourceNumberPeriod.forecastTotalMinutesSoftWin;
			const forecastTotalMinutesHard = resourceNumberPeriod.forecastTotalMinutesHard;
			const forecastBillableTaskAndAllocationsCombined = resourceNumberPeriod.forecastBillableTaskAndAllocationsCombined;
			const forecastBillableTaskAndAllocationsCombinedSoft =
				resourceNumberPeriod.forecastBillableTaskAndAllocationsCombinedSoft;
			const forecastBillableTaskAndAllocationsCombinedWin =
				resourceNumberPeriod.forecastBillableTaskAndAllocationsCombinedWin;
			const forecastBillableTaskAndAllocationsCombinedSoftWin =
				resourceNumberPeriod.forecastBillableTaskAndAllocationsCombinedSoftWin;
			const forecastBillableTaskAndAllocationsCombinedHard =
				resourceNumberPeriod.forecastBillableTaskAndAllocationsCombinedHard;
			const forecastTotalTimeTaskAndAllocationsCombined =
				resourceNumberPeriod.forecastTotalTimeTaskAndAllocationsCombined;
			const forecastTotalTimeTaskAndAllocationsCombinedSoft =
				resourceNumberPeriod.forecastTotalTimeTaskAndAllocationsCombinedSoft;
			const forecastTotalTimeTaskAndAllocationsCombinedWin =
				resourceNumberPeriod.forecastTotalTimeTaskAndAllocationsCombinedWin;
			const forecastTotalTimeTaskAndAllocationsCombinedSoftWin =
				resourceNumberPeriod.forecastTotalTimeTaskAndAllocationsCombinedSoftWin;
			const forecastTotalTimeTaskAndAllocationsCombinedHard =
				resourceNumberPeriod.forecastTotalTimeTaskAndAllocationsCombinedHard;

			let date = periodStartDate;
			while (date.isBefore(periodEndDate) || date.isSame(periodEndDate)) {
				const yearWeek = `${date.weekYear()}-${date.week()}`;

				const weekResourceNumbers = aggregatedResourceNumbersByWeek[yearWeek] || {
					date,
					availableMinutes: 0,
					forecastBillableProjectMinutes: 0,
					forecastBillableProjectMinutesSoft: 0,
					forecastBillableProjectMinutesWin: 0,
					forecastBillableProjectMinutesSoftWin: 0,
					forecastBillableProjectMinutesHard: 0,
					forecastTotalMinutes: 0,
					forecastTotalMinutesSoft: 0,
					forecastTotalMinutesWin: 0,
					forecastTotalMinutesSoftWin: 0,
					forecastTotalMinutesHard: 0,
					forecastBillableTaskAndAllocationsCombined: 0,
					forecastBillableTaskAndAllocationsCombinedWin: 0,
					forecastBillableTaskAndAllocationsCombinedSoft: 0,
					forecastBillableTaskAndAllocationsCombinedSoftWin: 0,
					forecastBillableTaskAndAllocationsCombinedHard: 0,
					forecastTotalTimeTaskAndAllocationsCombined: 0,
					forecastTotalTimeTaskAndAllocationsCombinedWin: 0,
					forecastTotalTimeTaskAndAllocationsCombinedSoft: 0,
					forecastTotalTimeTaskAndAllocationsCombinedSoftWin: 0,
					forecastTotalTimeTaskAndAllocationsCombinedHard: 0,
				};
				aggregatedResourceNumbersByWeek[yearWeek] = weekResourceNumbers;

				weekResourceNumbers.availableMinutes += availableMinutes;
				weekResourceNumbers.forecastBillableProjectMinutes += forecastBillableProjectMinutes;
				weekResourceNumbers.forecastBillableProjectMinutesSoft += forecastBillableProjectMinutesSoft;
				weekResourceNumbers.forecastBillableProjectMinutesWin += forecastBillableProjectMinutesWin;
				weekResourceNumbers.forecastBillableProjectMinutesSoftWin += forecastBillableProjectMinutesSoftWin;
				weekResourceNumbers.forecastBillableProjectMinutesHard += forecastBillableProjectMinutesHard;
				weekResourceNumbers.forecastTotalMinutes += forecastTotalMinutes;
				weekResourceNumbers.forecastTotalMinutesSoft += forecastTotalMinutesSoft;
				weekResourceNumbers.forecastTotalMinutesWin += forecastTotalMinutesWin;
				weekResourceNumbers.forecastTotalMinutesSoftWin += forecastTotalMinutesSoftWin;
				weekResourceNumbers.forecastTotalMinutesHard += forecastTotalMinutesHard;
				weekResourceNumbers.forecastBillableTaskAndAllocationsCombined += forecastBillableTaskAndAllocationsCombined;
				weekResourceNumbers.forecastBillableTaskAndAllocationsCombinedSoft +=
					forecastBillableTaskAndAllocationsCombinedSoft;
				weekResourceNumbers.forecastBillableTaskAndAllocationsCombinedWin +=
					forecastBillableTaskAndAllocationsCombinedWin;
				weekResourceNumbers.forecastBillableTaskAndAllocationsCombinedSoftWin +=
					forecastBillableTaskAndAllocationsCombinedSoftWin;
				weekResourceNumbers.forecastBillableTaskAndAllocationsCombinedHard +=
					forecastBillableTaskAndAllocationsCombinedHard;
				weekResourceNumbers.forecastTotalTimeTaskAndAllocationsCombined += forecastTotalTimeTaskAndAllocationsCombined;
				weekResourceNumbers.forecastTotalTimeTaskAndAllocationsCombinedSoft +=
					forecastTotalTimeTaskAndAllocationsCombinedSoft;
				weekResourceNumbers.forecastTotalTimeTaskAndAllocationsCombinedWin +=
					forecastTotalTimeTaskAndAllocationsCombinedWin;
				weekResourceNumbers.forecastTotalTimeTaskAndAllocationsCombinedSoftWin +=
					forecastTotalTimeTaskAndAllocationsCombinedSoftWin;
				weekResourceNumbers.forecastTotalTimeTaskAndAllocationsCombinedHard +=
					forecastTotalTimeTaskAndAllocationsCombinedHard;

				date = date.clone().add(1, 'day');
			}
		}
	});

	const utilizationDataSet = Object.keys(aggregatedResourceNumbersByWeek).map(weekNumber => {
		const weekResourceNumbers = aggregatedResourceNumbersByWeek[weekNumber];
		const {
			date,
			availableMinutes,
			forecastBillableProjectMinutes,
			forecastBillableProjectMinutesWin,
			forecastBillableProjectMinutesSoft,
			forecastBillableProjectMinutesSoftWin,
			forecastBillableProjectMinutesHard,
			forecastTotalMinutes,
			forecastTotalMinutesWin,
			forecastTotalMinutesSoft,
			forecastTotalMinutesSoftWin,
			forecastTotalMinutesHard,
			forecastBillableTaskAndAllocationsCombined,
			forecastBillableTaskAndAllocationsCombinedWin,
			forecastBillableTaskAndAllocationsCombinedSoft,
			forecastBillableTaskAndAllocationsCombinedSoftWin,
			forecastBillableTaskAndAllocationsCombinedHard,
			forecastTotalTimeTaskAndAllocationsCombined,
			forecastTotalTimeTaskAndAllocationsCombinedWin,
			forecastTotalTimeTaskAndAllocationsCombinedSoft,
			forecastTotalTimeTaskAndAllocationsCombinedSoftWin,
			forecastTotalTimeTaskAndAllocationsCombinedHard,
		} = weekResourceNumbers;

		// We are calculating the utilization in the frontend, which is no ideal, as it duplicates the business logic
		// in the frontend.
		// The reason is that we want to aggregate on a week level, and that is difficult to do on the resource backend,
		// as it depends on the requesting users locale. If we come up with a good way to communicate the locale from
		// frontend to backend, we might change this back again.
		const forecastBillableUtilization =
			availableMinutes !== 0
				? (isMixedAllocationModeEnabled
						? selectValueWithOptions(
								forecastBillableTaskAndAllocationsCombined,
								forecastBillableTaskAndAllocationsCombinedWin,
								forecastBillableTaskAndAllocationsCombinedSoft,
								forecastBillableTaskAndAllocationsCombinedSoftWin,
								forecastBillableTaskAndAllocationsCombinedHard,
								allocationControlsOptions
						  )
						: selectValueWithOptions(
								forecastBillableProjectMinutes,
								forecastBillableProjectMinutesWin,
								forecastBillableProjectMinutesSoft,
								forecastBillableProjectMinutesSoftWin,
								forecastBillableProjectMinutesHard,
								allocationControlsOptions
						  )) / availableMinutes
				: 0;
		const forecastResourceUtilization =
			availableMinutes !== 0
				? (isMixedAllocationModeEnabled
						? selectValueWithOptions(
								forecastTotalTimeTaskAndAllocationsCombined,
								forecastTotalTimeTaskAndAllocationsCombinedWin,
								forecastTotalTimeTaskAndAllocationsCombinedSoft,
								forecastTotalTimeTaskAndAllocationsCombinedSoftWin,
								forecastTotalTimeTaskAndAllocationsCombinedHard,
								allocationControlsOptions
						  )
						: selectValueWithOptions(
								forecastTotalMinutes,
								forecastTotalMinutesWin,
								forecastTotalMinutesSoft,
								forecastTotalMinutesSoftWin,
								forecastTotalMinutesHard,
								allocationControlsOptions
						  )) / availableMinutes
				: 0;
		const utilization = showResourceUtilization ? forecastResourceUtilization : forecastBillableUtilization;
		const value = Math.round(utilization * 100);

		return {date: date.startOf('week'), value};
	});

	dataSets.push({
		name: showResourceUtilization
			? intl.formatMessage({id: 'common.forecast_resource_utilization'})
			: intl.formatMessage({id: 'common.forecast_billable_utilization'}),
		color: '#02D5FF',
		filled: false,
		data: utilizationDataSet,
	});

	return dataSets;
};

export const BillableUtilizationChart = injectIntl(
	({
		startDate,
		endDate,
		companyTarget,
		aggregatedResourceNumbersByDay,
		showResourceUtilization,
		intl,
		isMixedAllocationModeEnabled,
		allocationControlsOptions,
	}) => {
		const chartData = getData(
			startDate,
			endDate,
			companyTarget,
			aggregatedResourceNumbersByDay,
			showResourceUtilization,
			intl,
			isMixedAllocationModeEnabled,
			allocationControlsOptions
		);

		const modifyChartOptions = options => {
			options.scales.y.ticks.suggestedMax = 100;
			options.scales.x.time.unit = 'week';
			options.plugins.tooltip.callbacks.title = tooltipItem => {
				const weekStartDate = moment(tooltipItem[0].label);
				const weekEndDate = weekStartDate.clone().endOf('week');
				return `${weekStartDate.format('DD MMMM, YYYY')} - ${weekEndDate.format('DD MMMM, YYYY')}`;
			};
			return options;
		};

		return <Chart dataSets={chartData} yAxisAffix={{position: 'SUFFIX', value: ' %'}} modifyOptions={modifyChartOptions} />;
	}
);
