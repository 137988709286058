import DirectApi from '../../../directApi';

export const fetchData = (searchQuery, path) => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const body = {
		method: 'POST',
		headers,
		credentials: 'include',
		body: JSON.stringify({
			searchQuery: searchQuery,
		}),
	};
	return fetch(DirectApi.graphqlServerEndpoint(path), body)
		.then(response => {
			return response.json();
		})
		.then(data => {
			return data;
		});
};

export const fetchMapData = (ids, searchQuery, path) => {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const body = {
		method: 'POST',
		headers,
		credentials: 'include',
		body: JSON.stringify({
			searchQuery: searchQuery,
			ids: ids,
		}),
	};
	return fetch(DirectApi.graphqlServerEndpoint(path), body)
		.then(response => response.json())
		.then(data => {
			return data;
		});
};
