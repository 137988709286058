/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BUDGET_TYPE = "FIXED_PRICE" | "FIXED_PRICE_V2" | "NON_BILLABLE" | "RETAINER" | "TIME_AND_MATERIALS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type HeaderSection_project$ref: FragmentReference;
declare export opaque type HeaderSection_project$fragmentType: HeaderSection_project$ref;
export type HeaderSection_project = {|
  +baselineEstimationMinutesPerDay: ?number,
  +projectStartYear: ?number,
  +projectStartMonth: ?number,
  +projectStartDay: ?number,
  +projectEndYear: ?number,
  +projectEndMonth: ?number,
  +projectEndDay: ?number,
  +baselineTargetMinutes: ?number,
  +baselineTarget: ?number,
  +baselineCost: ?number,
  +baselineProfit: ?number,
  +baselineAdjustPercentage: ?number,
  +baselineAdjustPrice: ?number,
  +baselineTargetPrice: ?number,
  +budgetType: ?BUDGET_TYPE,
  +budget: ?number,
  +useFixedPriceForBaselineRevenue: ?boolean,
  +$refType: HeaderSection_project$ref,
|};
export type HeaderSection_project$data = HeaderSection_project;
export type HeaderSection_project$key = {
  +$data?: HeaderSection_project$data,
  +$fragmentRefs: HeaderSection_project$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderSection_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineEstimationMinutesPerDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectStartYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectStartMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectStartDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectEndYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectEndMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectEndDay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineTargetMinutes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineTarget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineAdjustPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineAdjustPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "baselineTargetPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budgetType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "budget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useFixedPriceForBaselineRevenue",
      "storageKey": null
    }
  ],
  "type": "ProjectType"
};
// prettier-ignore
(node/*: any*/).hash = '55fa97bfc3eabc99c682ea11b8f5d988';

module.exports = node;
