import Styled from 'styled-components/macro';
import {CSS_CONSTANTS} from '../../../../css_variables';

export const DetailsWrapper = Styled.div`    
    flow-grow: 1;
    
    padding: 24px;
    margin-top: 20px;
    
    background-color: ${CSS_CONSTANTS.color_white};

    border: 1px solid #e6e6ed;
    border-radius: 4px;
`;

export const DetailsTitleWrapper = Styled.div`
    display: flex;
    
    h3 {
        margin-top: 0;
        margin-bottom: 0;
        flex-grow: 1;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        color: #4C4C58;
    }
    
    .titleButtons {
      display: flex;
    }
`;

export const DetailsTableWrapper = Styled.div`
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
`;

export const DetailsHeaderColumn = Styled.div`
    display: flex;
    align-items: center;
`;

export const DetailsValue = Styled.div`
    color: ${props => (props.locked ? '#C4C4CC' : 'inherit')};
`;
