import React from 'react';
import {Button} from 'web-components';
import {createToast} from '../../../../forecast-app/shared/components/toasts/toast';

export const CopyLinkMenuItem = ({link}) => {
	const onCopyLink = () => {
		navigator.clipboard.writeText(link).then(
			() => createToast({duration: 3000, message: 'Link copied to clipboard'}),
			() =>
				createToast({
					duration: 5000,
					message: 'Not allowed to copy link to clipboard. You can copy it from the address bar.',
				})
		);
	};

	return (
		<Button variant={Button.VARIANT.VERY_LIGHT_GRAY_OUTLINE} onClick={onCopyLink}>
			Copy Link
		</Button>
	);
};
