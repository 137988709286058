import React, {useState} from 'react';
import {FormattedHTMLMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {Scrollbars} from 'react-custom-scrollbars';
import RadioButton from '../../../../components/new-ui/radio_button';
import {BUDGET_TYPE, BUTTON_COLOR, BUTTON_STYLE} from '../../../../constants';
import Button from '../../../../forecast-app/shared/components/buttons/button/button';
import CurrencyInput from '../../../inputs/currency_input';
import Util from '../../../../forecast-app/shared/util/util';
import UpdateProjectMutation from '../../../../mutations/update_project_budget_page_mutation.js';
import GenericEmptyState from '../../../empty-state/generic_empty_state_v2';
import Input from '../../../new-ui/input_field';
import TooltipContainer from '../../../../forecast-app/shared/components/tooltips/tooltip_container';
import InformationIcon from '../../../../images/information-icon';
import {RateCardUtil} from '../../../../forecast-app/shared/util/RateCardUtil';
import moment from 'moment';
import {projectUrl} from '../../../../directApi';

const RATE_CARD_SETTING = {
	ADJUST_RATE_CARD: 0,
	KEEP_RATE_CARD: 1,
	BACK_TO_ORIGINAL_RATE_CARD: 2,
};

export const FixedPriceContainer = ({project, company, setShowFixedPriceContainer, intl}) => {
	const {formatMessage} = intl;
	const history = useHistory();

	const missingRateCard = project.rateCard === null;
	const roles = company.roles;
	const projectRateCard = project.rateCard;
	const currency = projectRateCard ? projectRateCard.currency : company.currency;
	const isFixedPrice = [BUDGET_TYPE.FIXED_PRICE, BUDGET_TYPE.FIXED_PRICE_V2].includes(project.budgetType);
	const rateCardIsAdjusted = project.rateCard && project.rateCard.parentRateCardId;
	const initialRateCardSetting =
		!rateCardIsAdjusted && (isFixedPrice || missingRateCard || !project.originalForecastBillableHoursPrice)
			? RATE_CARD_SETTING.KEEP_RATE_CARD
			: RATE_CARD_SETTING.ADJUST_RATE_CARD;

	const calculateMarkupPercentage = fixedPrice => {
		if (!fixedPrice) return '';

		if (!project.originalForecastBillableHoursPrice) return '';
		const totalExpenses = project.expenseFinancialNumbers.billablePlannedTimeAndExpenses;
		const oldPriceOfWork = project.originalForecastBillableHoursPrice + totalExpenses;
		const newPriceOfWork = fixedPrice;
		return Util.roundToDecimals((newPriceOfWork / oldPriceOfWork) * 100 - 100, 2);
	};

	const [fixedPriceInputValue, setFixedPriceInputValue] = useState(project.budget || null);
	const [rateCardSetting, setRateCardSetting] = useState(initialRateCardSetting);
	const [markupPercentageInputValue, setMarkupPercentageInputValue] = useState(
		project.budget ? calculateMarkupPercentage(project.budget) : 0
	);

	const canSave = fixedPriceInputValue > 0 || rateCardSetting === RATE_CARD_SETTING.BACK_TO_ORIGINAL_RATE_CARD;

	const rateCardSettings = [
		{
			value: RATE_CARD_SETTING.ADJUST_RATE_CARD,
			label: formatMessage({id: 'project_budget.adjust_rate_card'}),
			disabled: !fixedPriceInputValue || !project.rateCard || !project.originalForecastBillableHoursPrice,
			description: formatMessage({id: 'project_budget.adjust_rate_card_description'}),
		},
		{
			value: RATE_CARD_SETTING.KEEP_RATE_CARD,
			label: formatMessage({id: 'project_budget.keep_rate_card'}),
			disabled: !fixedPriceInputValue,
			description: formatMessage({id: 'project_budget.keep_rate_card_description'}),
		},
		{
			value: RATE_CARD_SETTING.BACK_TO_ORIGINAL_RATE_CARD,
			label: formatMessage({id: 'project_budget.go_back_to_original_rate_card'}),
			disabled: !isFixedPrice,
		},
	];

	const handleFixedPriceSave = () => {
		const onSuccess = fixedPrice => {
			setFixedPriceInputValue(fixedPrice);
			setShowFixedPriceContainer(false);
		};
		let fixedPrice = null;
		const mutationObject = {
			project: project,
			fixedPriceRateCardSetting: rateCardSetting,
		};
		if (fixedPriceInputValue !== null) {
			// Will truncate up to first decimal point (e.g. 123.55 => 123)
			fixedPrice = Math.round(parseFloat(fixedPriceInputValue) * 100) / 100;
			mutationObject.budgetType = BUDGET_TYPE.FIXED_PRICE;
		}
		mutationObject.budget = fixedPrice;
		Util.CommitMutation(UpdateProjectMutation, mutationObject, onSuccess(fixedPrice));
	};

	const handleInputValueChange = val => {
		const newMarkup = calculateMarkupPercentage(val);
		setFixedPriceInputValue(val);
		setMarkupPercentageInputValue(newMarkup);
	};

	const handleRateCardSettingChange = radioInput => {
		setRateCardSetting(radioInput.value);
	};

	const getOriginalRateCard = () => {
		if (project.rateCard !== null) {
			if (project.rateCard.parentRateCardId !== null) {
				return company.rateCards.edges.filter(rateCard => rateCard.node.id === project.rateCard.parentRateCardId)[0]
					.node;
			} else {
				return project.rateCard;
			}
		} else {
			return null;
		}
	};

	const getAdjustedRateCard = adjustRate => {
		if (project.rateCard !== null && project.rateCard.parentRateCardId !== null) {
			return project.rateCard;
		} else if (project.rateCard !== null && adjustRate) {
			return project.rateCard;
		} else {
			return null;
		}
	};

	const mapRateCard = (rateCard, rateConverter) => {
		const rateCardMap = {};
		if (rateCard) {
			const rateCardUtil = new RateCardUtil(rateCard, roles);
			if (rateCardUtil !== null) {
				roles.edges.forEach(role => {
					const rate = rateCardUtil.getRate(role.node.id, moment());
					rateCardMap[role.node.id] = rateConverter(role.node.id, rate);
				});
				rateCardMap.default = rateConverter('default', rateCardUtil.getRate(null, moment()));
			}
			return rateCardMap;
		}
	};

	const calculateAdjustedRate = rate => {
		const forecastTotal = project.originalForecastBillableHoursPrice;
		const totalExpenses = project.expenseFinancialNumbers.billablePlannedTimeAndExpenses;
		const difference = fixedPriceInputValue - totalExpenses - forecastTotal;
		const percentageDifference = (difference / (forecastTotal === 0 ? 1 : forecastTotal)) * 100;

		const adjustedRate = rate + (rate === 0 ? 1 : rate) * (percentageDifference / 100);

		return adjustedRate;
	};

	const handleRedirectToSelectRateCard = () => {
		const path = `${projectUrl(project.companyProjectId, project.customProjectId)}/rates`;
		history.push(path);
	};

	const calculateFixedPrice = markupInput => {
		const totalExpenses = project.expenseFinancialNumbers.billablePlannedTimeAndExpenses;
		const priceOfWork = project.originalForecastBillableHoursPrice;
		const markupPercentage = markupInput + 100;
		return Util.roundToDecimals(((priceOfWork + totalExpenses) * markupPercentage) / 100, 2);
	};

	const onMarkupChange = value => {
		const actualValue = value || 0;
		const newFixedPrice = calculateFixedPrice(actualValue);
		setFixedPriceInputValue(newFixedPrice);
		setMarkupPercentageInputValue(actualValue);
	};

	const currencySymbol = Util.GetCurrencySymbol(projectRateCard ? projectRateCard.currency : company.currency);
	const isDollarOrEuro = Util.CurrencyIsPrefixed(currencySymbol);

	const originalRateCard = mapRateCard(getOriginalRateCard(), (_, rate) => rate);
	const adjustedRateCard = mapRateCard(
		getAdjustedRateCard(rateCardSetting === RATE_CARD_SETTING.ADJUST_RATE_CARD && fixedPriceInputValue > 0),
		(roleId, rate) =>
			rateCardSetting === RATE_CARD_SETTING.ADJUST_RATE_CARD && fixedPriceInputValue > 0
				? calculateAdjustedRate(originalRateCard[roleId])
				: rate
	);
	const animateClasses =
		project.rateCard !== null && project.rateCard.parentRateCardId !== null ? '' : ' animated fadeInLeft faster';
	const showAdjustedRates =
		rateCardSetting !== RATE_CARD_SETTING.BACK_TO_ORIGINAL_RATE_CARD &&
		((rateCardSetting === RATE_CARD_SETTING.ADJUST_RATE_CARD && fixedPriceInputValue > 0) ||
			getAdjustedRateCard(false) !== null);
	const planRevenue =
		project.originalForecastBillableHoursPrice + project.expenseFinancialNumbers.billablePlannedTimeAndExpenses;

	const planRevenueTooltip = [
		{
			title: formatMessage({id: 'project_budget.revenue'}),
			details: [
				formatMessage({id: 'project_budget.all_billable_forecast_hours'}),
				'x',
				formatMessage({id: 'project_budget.original_rate'}),
				'+',
				formatMessage({id: 'project_budget.price_on_all_planned_billable_expenses'}),
			],
		},
	];

	return (
		<div className="fixed-price-wrapper">
			<Scrollbars style={{height: '100%'}}>
				<div className="padder">
					<div className="back-wrapper" onClick={() => setShowFixedPriceContainer(false)} tabIndex={0}>
						<div className={'back-arrow-control'} />
						<div className="back-text">{`${formatMessage({id: 'common.budget'})} - ${formatMessage({
							id: 'common.project',
						})}`}</div>
					</div>
					<div className="fixed-price-content">
						<div className="fixed-price-container">
							<h1>
								{isFixedPrice
									? formatMessage({id: 'project_budget.edit_fixed_price'})
									: formatMessage({id: 'project_budget.set_fixed_price'})}
							</h1>
							<p>{formatMessage({id: 'project_budget.fixed_price_description'})}</p>
							<div className="form">
								<div className="fixed-price-numbers">
									<div className="plan-revenue">
										<div className="label">
											{formatMessage({id: 'project_budget.plan_revenue'})}
											<TooltipContainer infoText={planRevenueTooltip} translatedMessage={true}>
												<InformationIcon />
											</TooltipContainer>
										</div>
										<div className="content" data-cy="plan-revenue">
											{Util.getFormattedNumberWithCurrency(currencySymbol, planRevenue, intl)}
										</div>
									</div>
									<div className="markup">
										<div className="label">{formatMessage({id: 'common.markup'})}</div>
										<div className="input-control">
											<Input
												type="formatted"
												suffix="%"
												alignTextRight={true}
												value={markupPercentageInputValue || ''}
												placeholder={0}
												onChange={value => onMarkupChange(value)}
												hideLabel={true}
												locked={!project.originalForecastBillableHoursPrice}
												cy={'markup-percentage'}
											/>
										</div>
									</div>
									<div className="fixed-price">
										<div className="label">{formatMessage({id: 'common.fixed_price'})}</div>
										<div className="input-control">
											<CurrencyInput
												value={fixedPriceInputValue}
												showCurrencyOnLeft={isDollarOrEuro}
												onChange={value => handleInputValueChange(value)}
												placeholder=""
												currency={currency}
												allowDecimals={true}
												autoFocus={true}
												dataCy={'markup-fixed-price'}
											/>
										</div>
									</div>
								</div>
								<div className="input-control">
									<div className="label label-rate-card-change">
										{formatMessage({id: 'project_budget.rate_card_change'})}
									</div>
									{rateCardSettings.map((item, index) => {
										return (
											<div key={'first-section-item-' + index} className="slider-container">
												<div className="column-option">
													<RadioButton
														onSelected={() => handleRateCardSettingChange(item)}
														stateValue={rateCardSetting}
														value={item.value}
														locked={item.disabled}
														id={`rate_card_setting_${item.value}`}
													/>
												</div>
												<label
													htmlFor={`rate_card_setting_${item.value}`}
													className={`column-texts-label ${item.disabled}`}
												>
													<div className="column-texts">
														<div className={'column-label' + (item.disabled ? ' disabled' : '')}>
															{item.label}
														</div>
														{item.description ? (
															<div
																className={
																	'column-description' + (item.disabled ? ' disabled' : '')
																}
															>
																{item.description}
															</div>
														) : null}
													</div>
												</label>
											</div>
										);
									})}
								</div>
							</div>
							<div className="buttons">
								<Button
									className="payment-back-button"
									onClick={() => setShowFixedPriceContainer(false)}
									buttonStyle={BUTTON_STYLE.FILLED}
									colorTheme={BUTTON_COLOR.WHITE}
									text={formatMessage({id: 'common.back'})}
									cy={'back-button'}
								/>
								<Button
									className="save-button"
									onClick={handleFixedPriceSave}
									buttonStyle={BUTTON_STYLE.FILLED}
									isDisabled={!canSave}
									colorTheme={BUTTON_COLOR.GREEN}
									text={formatMessage({id: 'project_budget.save_changes'})}
									cy={'next-button'}
								/>
							</div>
						</div>

						<div className="fixed-price-rate-card">
							<div className="preview">{formatMessage({id: 'project_budget.preview'})}:</div>

							<div className={'rates' + (missingRateCard ? ' no-rate-card' : '')}>
								{missingRateCard ? (
									<GenericEmptyState
										text={formatMessage({id: 'project_budget.no_preview_because_no_rate_card'})}
										header={''}
										callback={handleRedirectToSelectRateCard}
										actionText={formatMessage({id: 'empty_state.project_rate_card.header_text'})}
									/>
								) : (
									<>
										<h2>{formatMessage({id: 'project_budget.role_rates'})}</h2>

										<table>
											<colgroup>
												<col className="column-role" />
												<col className="column-rate" />
												<col className="column-rate" />
											</colgroup>
											<thead>
												<tr>
													<th className="column-role role-width role-width-header">
														{formatMessage({id: 'common.role'})}
													</th>
													<th
														className={
															'column-rate rate-width rate-width-header' +
															(!showAdjustedRates ? ' full active' : '')
														}
													>
														{showAdjustedRates
															? formatMessage({id: 'project_budget.original_rate'})
															: formatMessage({id: 'common.rate'})}
													</th>
													{showAdjustedRates ? (
														<th
															className={
																'column-rate rate-width rate-width-header active' +
																animateClasses
															}
														>
															<FormattedHTMLMessage id="project_budget.fixed_price_adjusted_rate" />
														</th>
													) : null}
												</tr>
											</thead>

											<tbody>
												<tr data-cy="role-row">
													<td data-cy="role-name" className="column-role role-width">
														{formatMessage({id: 'settings.rate-card-default'})}
													</td>
													<td
														data-cy="role-rate"
														className={
															'column-rate rate-width rate-width-header' +
															(!showAdjustedRates ? ' full active' : '')
														}
													>
														{Util.getFormattedNumberWithCurrency(
															currencySymbol,
															originalRateCard.default,
															intl
														)}
													</td>
													{showAdjustedRates ? (
														<td
															data-cy="role-adjusted"
															className={'column-rate rate-width active' + animateClasses}
														>
															{Util.getFormattedNumberWithCurrency(
																currencySymbol,
																adjustedRateCard.default,
																intl
															)}
														</td>
													) : null}
												</tr>
												{roles.edges.map(role => (
													<tr data-cy="role-row" key={role.node.id}>
														<td data-cy="role-name" className="column-role role-width">
															{role.node.name}
														</td>
														<td
															data-cy="role-rate"
															className={
																'column-rate rate-width rate-width-header' +
																(!showAdjustedRates ? ' full active' : '')
															}
														>
															{Util.getFormattedNumberWithCurrency(
																currencySymbol,
																originalRateCard[role.node.id],
																intl
															)}
														</td>
														{showAdjustedRates ? (
															<td
																data-cy="role-adjusted"
																className={'column-rate rate-width active' + animateClasses}
															>
																{Util.getFormattedNumberWithCurrency(
																	currencySymbol,
																	adjustedRateCard[role.node.id],
																	intl
																)}
															</td>
														) : null}
													</tr>
												))}
											</tbody>
										</table>
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</Scrollbars>
		</div>
	);
};
