import React from 'react';
import {useIntl} from 'react-intl';
import {createFragmentContainer, graphql} from 'react-relay';
import DetailsTable from './DetailsTable';

const ActualDetailsTable = ({
	key,
	selectedAggregateLevel,
	selectedViewType,
	company,
	project,
	actualAggregates,
	data,
	tooltips,
}) => {
	const intl = useIntl();

	return (
		<DetailsTable
			key={key}
			cy={'details-actual'}
			title={intl.formatMessage({id: 'project_budget.actual'})}
			company={company}
			project={project}
			selectedAggregateLevel={selectedAggregateLevel}
			selectedViewType={selectedViewType}
			aggregates={actualAggregates}
			data={data}
			tooltips={tooltips}
		/>
	);
};

export default createFragmentContainer(ActualDetailsTable, {
	project: graphql`
		fragment ActualDetailsTable_project on ProjectType {
			fixedPriceLocks(first: 1000) @connection(key: "Project_fixedPriceLocks", filters: []) {
				edges {
					node {
						id
						startDate
						endDate
					}
				}
			}
			...DetailsTable_project
			...CloseMonthButtons_project
		}
	`,
});
