import React from 'react';
import {LoadMore} from '../../../loaders/LoadMore';
import {filtersToSearchQuery} from '../UtilizationReportUtils';
import MonthByMonthPersonLoader, {monthByMonthPersonLoaderQuery} from './MonthByMonthPersonLoader';
import MonthByMonthDownload, {monthByMonthDownloadQuery} from './MonthByMonthDownload';
import {HexagonText, Table} from 'web-components';
import {MonthByMonthUtilizationMeter, getUtilizationNumber} from './MonthByMonthTable';
import {MODAL_TYPE, showModal} from '../../../../forecast-app/shared/components/modals/generic_modal_conductor';
import {profilePicSrc} from '../../../../directApi';

const MonthByMonthPersonRows = ({
	filters,
	startDate,
	endDate,
	setCsvDownloadFunction,
	pageSize,
	showResourceUtilization,
	roleId,
	departmentId,
	levelProps,
	isMixedAllocationModeEnabled,
	allocationControlsOptions,
}) => {
	const addedFilters = [];
	if (roleId) {
		addedFilters.push({key: 'role', value: roleId});
	}
	if (departmentId) {
		addedFilters.push({key: 'department', value: departmentId});
	}

	return (
		<LoadMore
			key="query-render-month-by-month-person"
			query={monthByMonthPersonLoaderQuery}
			loader={<MonthByMonthPersonLoader />}
			variables={{
				pageSize,
				searchQuery: filtersToSearchQuery(filters, addedFilters),
				startYear: startDate ? startDate.year() : undefined,
				startMonth: startDate ? startDate.month() + 1 : undefined,
				startDay: startDate ? startDate.date() : undefined,
				endYear: endDate ? endDate.year() : undefined,
				endMonth: endDate ? endDate.month() + 1 : undefined,
				endDay: endDate ? endDate.date() : undefined,
			}}
		>
			{({data, loadMore}) => {
				if (setCsvDownloadFunction) {
					setCsvDownloadFunction(() => {
						showModal({
							type: MODAL_TYPE.DOWNLOADING_CSV_DATA,
							componentName: 'utilization_report_month_by_month_person_csv_loader',
							query: monthByMonthDownloadQuery,
							variables: {
								pageSize: 1000000,
								searchQuery: filtersToSearchQuery(filters, addedFilters),
								startYear: startDate ? startDate.year() : undefined,
								startMonth: startDate ? startDate.month() + 1 : undefined,
								startDay: startDate ? startDate.date() : undefined,
								endYear: endDate ? endDate.year() : undefined,
								endMonth: endDate ? endDate.month() + 1 : undefined,
								endDay: endDate ? endDate.date() : undefined,
							},
							createCsvDownload: <MonthByMonthDownload />,
							year: startDate.year(),
							getUtilizationNumber,
							showResourceUtilization,
							isMixedAllocationModeEnabled,
							allocationControlsOptions,
						});
					});
				}
				return (
					<Table.Rows data={data} canExpand={false} loadMore={loadMore} autoload={true} {...levelProps}>
						{({rowData, tableColumnsProps}) => {
							return (
								<Table.Row {...tableColumnsProps} cy={'report-row'}>
									<Table.Column visible>
										<HexagonText
											key={rowData.person.id}
											text={`${rowData.person.firstName} ${rowData.person.lastName}`}
											imgUrl={profilePicSrc(rowData.person.profilePictureId)}
										/>
									</Table.Column>
									{Array.from({length: 12}).map((_, index) => (
										<Table.Column visible key={index}>
											<MonthByMonthUtilizationMeter
												percentage={getUtilizationNumber(
													rowData,
													index,
													showResourceUtilization,
													isMixedAllocationModeEnabled,
													allocationControlsOptions
												)}
												theme="LIGHT"
											/>
										</Table.Column>
									))}
								</Table.Row>
							);
						}}
					</Table.Rows>
				);
			}}
		</LoadMore>
	);
};

export default MonthByMonthPersonRows;
