import {cloneDeep} from 'lodash';
import {EXPENSE_GROUPINGS} from './invoicing_generate_lines';
import {
	convertPriceToInvoiceCurrency,
	groupByProperties,
	visitGroups,
	addQuantityDecimals,
} from './invoicing_generate_lines_utils';
import {BUDGET_TYPE, PERIOD_BUDGET_TYPE} from '../../../../constants';
import Util from '../../../../forecast-app/shared/util/util';

const getAllExpenses = (config, projects, company, intl) => {
	const expenses = [];

	const {formatMessage} = intl;

	const personMap = company.allPersons.edges
		.map(edge => edge.node)
		.reduce((map, person) => {
			map[person.id] = person;
			return map;
		}, {});

	projects
		.filter(project => config.selectedProjectsIds.indexOf(project.id) > -1)
		.forEach(project => {
			const isFixedPriceProject =
				project.budgetType === BUDGET_TYPE.FIXED_PRICE_V2 ||
				(project.budgetType === BUDGET_TYPE.RETAINER &&
					project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_PRICE);
			project.expenseItems.edges
				.map(edge => edge.node)
				.filter(
					expense =>
						!expense.invoiced &&
						expense.approved &&
						expense.billable &&
						(expense.partOfFixedPrice === false || !isFixedPriceProject)
				)
				.filter(expense => {
					if (project.budgetType === BUDGET_TYPE.TIME_AND_MATERIALS) {
						const expenseDate = Util.CreateNonUtcMomentDate(
							expense.expenseYear,
							expense.expenseMonth,
							expense.expenseDay
						);

						return expenseDate >= config.startDate && expenseDate <= config.endDate;
					}

					return true;
				})
				.forEach(origExpense => {
					const expense = cloneDeep(origExpense);
					// Also flatten the expense, adding some of the data needed later directly to it,
					// like person name and project name
					expense.projectId = project.id;
					expense.projectName = project.name;
					if (expense.person && expense.person.id) {
						expense.personId = expense.person.id;
						const person = personMap[expense.person.id];
						expense.personName = `${person.firstName} ${person.lastName}`;
					} else {
						expense.personId = 'no-person';
						expense.personName = formatMessage({id: 'invoicing.no_person'});
					}
					if (expense.phase && expense.phase.id) {
						expense.phaseId = expense.phase.id;
						expense.phaseName = expense.phase.name;
					} else {
						expense.phaseId = 'no-phase';
						expense.phaseName = formatMessage({id: 'common.no_phase'});
					}
					if (project.rateCard && project.rateCard.currency) {
						expense.currency = project.rateCard.currency;
					} else {
						expense.currency = company.currency;
					}
					expenses.push(expense);
				});
		});
	return expenses;
};

const getGroupByPropertiesForConfig = config => {
	switch (config.expenseGrouping) {
		case EXPENSE_GROUPINGS.NO_GROUPING:
			return ['id'];
		case EXPENSE_GROUPINGS.PROJECT:
			return ['projectId'];
		case EXPENSE_GROUPINGS.PERSON:
			return ['projectId', 'personId'];
		case EXPENSE_GROUPINGS.PHASE:
			return ['projectId', 'phaseId'];
		default:
			throw new Error(`Unsupported expense grouping ${config.expenseGrouping}`);
	}
};

const createDescription = (expenses, config) => {
	const expenseNames = expenses
		.reduce((names, expense) => {
			names.push(expense.name);
			return names;
		}, [])
		.join(', ');
	switch (config.expenseGrouping) {
		case EXPENSE_GROUPINGS.NO_GROUPING:
			return `${expenseNames}`;
		case EXPENSE_GROUPINGS.PROJECT:
			return `${expenseNames}`;
		case EXPENSE_GROUPINGS.PERSON:
			return `${expenses[0].personName}: ${expenseNames}`;
		case EXPENSE_GROUPINGS.PHASE:
			return `${expenses[0].phaseName}: ${expenseNames}`;
		default:
			throw new Error(`Unsupported expense grouping ${config.expenseGrouping}`);
	}
};

const createQuantityAndUnitPrice = (expenses, company, invoice) => {
	let quantity, unitPrice;
	if (expenses.length === 1) {
		quantity = expenses[0].quantity;
		unitPrice = expenses.reduce((totalUnitPrice, expense) => totalUnitPrice + expense.price, 0.0);
	} else {
		// We can't aggregate quantities and unit prices across expenses as they
		// are most likely different units (apples and pears)
		quantity = 1;
		unitPrice = expenses.reduce((totalUnitPrice, expense) => totalUnitPrice + expense.price * expense.quantity, 0.0);
	}
	unitPrice = convertPriceToInvoiceCurrency(unitPrice, expenses[0].currency, company, invoice);
	return {
		quantity,
		unitPrice,
	};
};

export const generateLinesForExpenses = (config, intl, invoice, projects, company) => {
	const expenses = getAllExpenses(config, projects, company, intl);

	const groupByPropertyList = getGroupByPropertiesForConfig(config);
	const groupedExpenses = groupByProperties(expenses, groupByPropertyList);

	const invoiceLines = [];

	visitGroups(groupedExpenses, expenses => {
		const description = createDescription(expenses, config);
		const {quantity, unitPrice} = createQuantityAndUnitPrice(expenses, company, invoice);
		const projectId = expenses[0].projectId;
		const tax = invoice.tax;
		const discount = invoice.discount;
		const expenseItemIds = expenses.map(expense => expense.id);

		invoiceLines.push({
			description,
			quantity: addQuantityDecimals(quantity, company),
			unitPrice,
			projectId,
			tax,
			discount,
			expenseItemIds,
		});
	});
	return invoiceLines;
};
