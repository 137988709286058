import Util from '../../forecast-app/shared/util/util';
import {
	CANVAS_DATE_DAYS_OFFSET_FIRST_WEEK,
	createCanvasTimelineDate,
	DAY_INDEX,
	DAY_NAMES,
	isTimeOffAllocation,
} from '../canvas-scheduling/canvas-timeline/canvas_timeline_util';
import {getNonWorkingDaysDistribution} from '../canvas-scheduling/heatmap/NonWorkingDaysLogic';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';

//Get periods in which there are different amount of phases present
// -------  -------
//    ----------
// ^  ^  ^  ^  ^  ^
// |1 |2 |3 |4 |5 |
export const getPhaseFragments = (project, todayDate) => {
	const phaseDateDataArray = [];

	//If phase id is placed within already existing fragment, take the fragment's id and add it to the newly created one
	const addSurroundingPhaseIds = index => {
		if (index === 0 || index === phaseDateDataArray.length - 1) return;
		const surroundingIds = [...phaseDateDataArray[index - 1].phaseIds]
			.filter(element => phaseDateDataArray[index + 1].phaseIds.includes(element))
			.reverse();
		surroundingIds.forEach(phaseId => {
			phaseDateDataArray[index].phaseIds.splice(0, 0, phaseId);
		});
	};
	//Find where in the array phase belongs chronologically and whether or not there is something already on that date
	const getInsertData = date => {
		//Find where the date should be placed based on already existing data in phaseDateDataArray
		let minIndex = 0;
		let maxIndex = phaseDateDataArray.length - 1;
		let currentIndex;
		let currentDate;
		while (minIndex <= maxIndex) {
			currentIndex = ((minIndex + maxIndex) / 2) | 0;
			currentDate = phaseDateDataArray[currentIndex].date;
			if (currentDate.isBefore(date)) {
				minIndex = currentIndex + 1;
			} else if (currentDate.isAfter(date)) {
				maxIndex = currentIndex - 1;
			} else {
				//Date is same, fragment already exists on this date
				return {index: currentIndex, duplicate: true};
			}
		}
		//There is no date
		return {index: currentDate.isBefore(date) ? currentIndex + 1 : currentIndex, duplicate: false};
	};
	//Put phase data into the array
	const insertPhaseDate = (insertData, date, phaseId) => {
		//If same date is already present, just add the new phaseId to it
		if (insertData.duplicate) {
			phaseDateDataArray[insertData.index].phaseIds.push(phaseId);
		} else {
			//Otherwise make new entry
			phaseDateDataArray.splice(insertData.index, 0, {date, phaseIds: [phaseId]});
			addSurroundingPhaseIds(insertData.index);
		}
	};

	//Add data from a phase to the array
	const addPhaseData = (startDate, endDate, phaseId) => {
		//Skip some unnecessary logic on first iteration
		if (!phaseDateDataArray.length) {
			phaseDateDataArray.push({date: startDate, phaseIds: [phaseId]});
			phaseDateDataArray.push({date: endDate, phaseIds: [phaseId]});
			return;
		}
		const startInsertData = getInsertData(startDate);
		insertPhaseDate(startInsertData, startDate, phaseId);
		const endInsertData = getInsertData(endDate);
		//add phaseId to all entries between start index and end index
		for (let i = endInsertData.index - 1; i > startInsertData.index; i--) {
			phaseDateDataArray[i].phaseIds.push(phaseId);
		}
		insertPhaseDate(endInsertData, endDate, phaseId);
	};

	//quality code, make big fake phase to make sure there is at least 1 at all time to make life easier, will break if people plan their thing for before year -10000 or after 10000
	const fillerPhaseStartDate = Util.CreateNonUtcMomentDate(-10000, 1, 1);
	const fillerPhaseEndDate = Util.CreateNonUtcMomentDate(10000, 1, 1);
	addPhaseData(fillerPhaseStartDate, fillerPhaseEndDate, null);
	const phases = project.phases.edges
		.filter(phaseEdge => {
			const phaseStartDate = Util.CreateNonUtcMomentDate(
				phaseEdge.node.startYear,
				phaseEdge.node.startMonth,
				phaseEdge.node.startDay
			);
			const phaseEndDate = Util.CreateNonUtcMomentDate(
				phaseEdge.node.deadlineYear,
				phaseEdge.node.deadlineMonth,
				phaseEdge.node.deadlineDay
			);
			return phaseStartDate && phaseEndDate && phaseStartDate.isValid() && phaseEndDate.isValid();
		})
		.map(phase => {
			return phase.node;
		})
		.sort((a, b) => {
			const aEndDate = Util.CreateNonUtcMomentDate(a.deadlineYear, a.deadlineMonth, a.deadlineDay);
			const bEndDate = Util.CreateNonUtcMomentDate(b.deadlineYear, b.deadlineMonth, b.deadlineDay);
			const aStartDate = Util.CreateNonUtcMomentDate(a.startYear, a.startMonth, a.startDay);
			const bStartDate = Util.CreateNonUtcMomentDate(b.startYear, b.startMonth, b.startDay);
			if (aEndDate.isAfter(bEndDate)) return 1;
			if (aEndDate.isBefore(bEndDate)) return -1;
			if (aStartDate.isAfter(bStartDate)) return 1;
			if (aStartDate.isBefore(bStartDate)) return -1;
			return 0;
		});
	for (const phase of phases) {
		const phaseStartDate = Util.CreateNonUtcMomentDate(phase.startYear, phase.startMonth, phase.startDay);
		const phaseEndDate = Util.CreateNonUtcMomentDate(phase.deadlineYear, phase.deadlineMonth, phase.deadlineDay);
		addPhaseData(phaseStartDate, phaseEndDate.add(1, 'days'), phase.id);
	}
	const phaseFragments = [];
	//based on all dates put into phaseDateDataArray, get periods which describe how many phases are ongoing at given time
	for (let i = 0; i < phaseDateDataArray.length - 1; i++) {
		let currentPhaseDateEntry = phaseDateDataArray[i];
		const nextPhaseDateEntry = phaseDateDataArray[i + 1];
		if (currentPhaseDateEntry.date.isBefore(todayDate)) {
			//if whole period is before today and therefore irrelevant, go to next iteration
			if (nextPhaseDateEntry.date.isBefore(todayDate)) continue;
			//if fragments starts before today but ends after today, trim irrelevant period
			currentPhaseDateEntry.date = todayDate;
		}
		phaseFragments.push({
			start: currentPhaseDateEntry.date,
			end: nextPhaseDateEntry.date,
			phaseIds: currentPhaseDateEntry.phaseIds.filter(phaseId => {
				return nextPhaseDateEntry.phaseIds.includes(phaseId);
			}),
			availablePerRoleMap: new Map(),
		});
	}
	return phaseFragments;
};

const setRemainingRoleData = (tasks, roleDataMap, project, roles) => {
	const initialData = {remaining: 0, allocated: 0, minutesRegistered: 0, taskCount: 0, doneTaskCount: 0};
	if (!roleDataMap.has(null)) {
		roleDataMap.set(null, {...initialData});
	}
	for (const role of roles) {
		if (!roleDataMap.has(role.id)) {
			roleDataMap.set(role.id, {...initialData});
		}
	}
	for (const task of tasks) {
		const taskRoleId = task.role ? task.role.id : null;
		if (!roleDataMap.has(taskRoleId)) {
			roleDataMap.set(taskRoleId, {...initialData});
		}
		const data = roleDataMap.get(taskRoleId);
		let remaining = data.remaining;
		let allocated = data.allocated;
		let minutesRegistered = data.minutesRegistered;
		let taskCount = data.taskCount;
		let doneTaskCount = data.doneTaskCount;
		const taskRemaining =
			project.estimationUnit === 'HOURS' ? task.timeLeft : task.timeLeft * project.minutesPerEstimationPoint;

		remaining += taskRemaining;
		taskCount += 1;
		minutesRegistered += task.totalMinutesRegistered;
		doneTaskCount += task.done ? 1 : 0;

		roleDataMap.set(taskRoleId, {
			remaining,
			allocated,
			minutesRegistered,
			taskCount,
			doneTaskCount,
		});
	}
};

//Get how many minutes are there total in allocation
//If you use startMoment and endMoment param which are outside of the allocation, it will give you data based on those dates regardless of allocation start and end date
export const getAllocationTotal = (allocation, nonWorkingDaysMap, startMoment = null, endMoment = null) => {
	//names of allocation hours-per-weekday properties (allocation.monday etc)
	//if start/end params are specified, use those dates, otherwise just use allocation data
	const startDate = startMoment
		? startMoment
		: Util.CreateNonUtcMomentDate(allocation.startYear, allocation.startMonth, allocation.startDay);
	const endDate = endMoment
		? endMoment
		: Util.CreateNonUtcMomentDate(allocation.endYear, allocation.endMonth, allocation.endDay);

	let nonWorkingDaysDistribution = [0, 0, 0, 0, 0, 0, 0];
	if (!hasFeatureFlag('inverted_pto_non_working_days') && nonWorkingDaysMap && !isTimeOffAllocation(allocation)) {
		nonWorkingDaysDistribution = getNonWorkingDaysDistribution(
			createCanvasTimelineDate(startDate.year(), startDate.month() + 1, startDate.date()),
			createCanvasTimelineDate(endDate.year(), endDate.month() + 1, endDate.date()),
			nonWorkingDaysMap,
			allocation.personId
		);
	}

	//getting how many of each weekday there are in allocation, multiplying by how many hours are allocated per that day and then summing up.
	return [1, 2, 3, 4, 5, 6, 7]
		.map(
			dayIndex =>
				(Util.weekdaysBetween(startDate, endDate, dayIndex) - nonWorkingDaysDistribution[dayIndex - 1]) *
				allocation[DAY_NAMES[dayIndex - 1]]
		)
		.reduce((total, minutes) => (total += minutes), 0);
};

const getWeeksDiff = (startDate, endDate) => {
	const msInWeek = 1000 * 60 * 60 * 24 * 7;
	return Math.floor(Math.abs(endDate - startDate) / msInWeek);
};

export const getWeeksDiffFromCanvasTimelineDates = (startDate, endDate) => {
	return Math.floor(Math.abs(endDate - startDate) / 7);
};

const mondayIsFirstDayOfWeek = day => (day === 0 ? 7 : day);

export const getIsoWeekdayFromCanvasTimelineDate = canvasTimelineDate => {
	return ((canvasTimelineDate - CANVAS_DATE_DAYS_OFFSET_FIRST_WEEK) % 7) + 1;
};

const getWeekdaysOnDay = (startWeekday, endWeekday, dayOfWeekValue, fullWeeksBetween) => {
	const additionalWeekday =
		startWeekday <= endWeekday
			? dayOfWeekValue >= startWeekday && dayOfWeekValue <= endWeekday
			: dayOfWeekValue >= startWeekday || dayOfWeekValue <= endWeekday;

	return (additionalWeekday ? 1 : 0) + fullWeeksBetween;
};

export const allWeekdaysBetween = (d1, d2, nonWorkingDaysDistribution) => {
	const weekdaysBetween = {};
	const fullWeeksBetween = getWeeksDiff(d1, d2);
	const startWeekday = mondayIsFirstDayOfWeek(d1.getUTCDay());
	const endWeekday = mondayIsFirstDayOfWeek(d2.getUTCDay());

	for (let dayOfWeekValue = 1; dayOfWeekValue <= 7; dayOfWeekValue++) {
		weekdaysBetween[dayOfWeekValue] =
			getWeekdaysOnDay(startWeekday, endWeekday, dayOfWeekValue, fullWeeksBetween) -
			nonWorkingDaysDistribution[dayOfWeekValue - 1];
	}

	return weekdaysBetween;
};

const WEEKDAYS_BETWEEN_DATES_CACHE = new Map();
export const getWeekdaysBetweenDatesNew = (startDate, endDate, nonWorkingDaysMap, personId) => {
	if (endDate - startDate < 0) {
		return [0, 0, 0, 0, 0, 0, 0];
	}

	const key = `${startDate}-${endDate}`;
	let weekdaysBetween = WEEKDAYS_BETWEEN_DATES_CACHE.get(key);

	if (!weekdaysBetween) {
		weekdaysBetween = [0, 0, 0, 0, 0, 0, 0];

		const fullWeeksBetween = getWeeksDiffFromCanvasTimelineDates(startDate, endDate);
		const startWeekday = getIsoWeekdayFromCanvasTimelineDate(startDate);
		const endWeekday = getIsoWeekdayFromCanvasTimelineDate(endDate);

		for (let dayOfWeekValue = 1; dayOfWeekValue <= 7; dayOfWeekValue++) {
			weekdaysBetween[dayOfWeekValue - 1] = getWeekdaysOnDay(startWeekday, endWeekday, dayOfWeekValue, fullWeeksBetween);
		}

		WEEKDAYS_BETWEEN_DATES_CACHE.set(key, weekdaysBetween);
	}

	let nonWorkingDaysDistribution = [0, 0, 0, 0, 0, 0, 0];
	if (nonWorkingDaysMap && personId) {
		nonWorkingDaysDistribution = getNonWorkingDaysDistribution(startDate, endDate, nonWorkingDaysMap, personId);
	}

	return [
		weekdaysBetween[DAY_INDEX.MONDAY] - nonWorkingDaysDistribution[DAY_INDEX.MONDAY],
		weekdaysBetween[DAY_INDEX.TUESDAY] - nonWorkingDaysDistribution[DAY_INDEX.TUESDAY],
		weekdaysBetween[DAY_INDEX.WEDNESDAY] - nonWorkingDaysDistribution[DAY_INDEX.WEDNESDAY],
		weekdaysBetween[DAY_INDEX.THURSDAY] - nonWorkingDaysDistribution[DAY_INDEX.THURSDAY],
		weekdaysBetween[DAY_INDEX.FRIDAY] - nonWorkingDaysDistribution[DAY_INDEX.FRIDAY],
		weekdaysBetween[DAY_INDEX.SATURDAY] - nonWorkingDaysDistribution[DAY_INDEX.SATURDAY],
		weekdaysBetween[DAY_INDEX.SUNDAY] - nonWorkingDaysDistribution[DAY_INDEX.SUNDAY],
	];
};

const PERSON_WEEKDAYS_CACHE = new Map();
export const getWeekdaysBetweenDatesCached = (timelineMinorStep, startDate, endDate, nonWorkingDaysMap, personId) => {
	let personWeekdaysMap = PERSON_WEEKDAYS_CACHE.get(personId);
	if (!personWeekdaysMap) {
		personWeekdaysMap = new Map();
		PERSON_WEEKDAYS_CACHE.set(personId, personWeekdaysMap);
	}

	let stepCache = personWeekdaysMap.get(timelineMinorStep);
	if (!stepCache) {
		stepCache = new Map();
		personWeekdaysMap.set(timelineMinorStep, stepCache);
	}

	let weekdaysBetween = stepCache.get(startDate);
	if (!weekdaysBetween) {
		const weekdays = [0, 0, 0, 0, 0, 0, 0];

		if (endDate - startDate >= 0) {
			const fullWeeksBetween = getWeeksDiffFromCanvasTimelineDates(startDate, endDate);
			const startWeekday = getIsoWeekdayFromCanvasTimelineDate(startDate);
			const endWeekday = getIsoWeekdayFromCanvasTimelineDate(endDate);

			for (let dayOfWeekValue = 1; dayOfWeekValue <= 7; dayOfWeekValue++) {
				weekdays[dayOfWeekValue - 1] = getWeekdaysOnDay(startWeekday, endWeekday, dayOfWeekValue, fullWeeksBetween);
			}

			let nonWorkingDaysDistribution = [0, 0, 0, 0, 0, 0, 0];
			if (nonWorkingDaysMap && personId && !hasFeatureFlag('inverted_pto_non_working_days')) {
				nonWorkingDaysDistribution = getNonWorkingDaysDistribution(startDate, endDate, nonWorkingDaysMap, personId);
			}

			weekdaysBetween = [
				weekdays[DAY_INDEX.MONDAY] - nonWorkingDaysDistribution[DAY_INDEX.MONDAY],
				weekdays[DAY_INDEX.TUESDAY] - nonWorkingDaysDistribution[DAY_INDEX.TUESDAY],
				weekdays[DAY_INDEX.WEDNESDAY] - nonWorkingDaysDistribution[DAY_INDEX.WEDNESDAY],
				weekdays[DAY_INDEX.THURSDAY] - nonWorkingDaysDistribution[DAY_INDEX.THURSDAY],
				weekdays[DAY_INDEX.FRIDAY] - nonWorkingDaysDistribution[DAY_INDEX.FRIDAY],
				weekdays[DAY_INDEX.SATURDAY] - nonWorkingDaysDistribution[DAY_INDEX.SATURDAY],
				weekdays[DAY_INDEX.SUNDAY] - nonWorkingDaysDistribution[DAY_INDEX.SUNDAY],
			];
		} else {
			weekdaysBetween = weekdays;
		}

		stepCache.set(startDate, weekdaysBetween);
	}

	return weekdaysBetween;
};

export const clearPersonWeekdaysCache = (personId = null) => {
	if (personId) {
		const personWeekdays = PERSON_WEEKDAYS_CACHE.get(personId);

		if (personWeekdays) {
			personWeekdays.clear();
		}
	} else {
		PERSON_WEEKDAYS_CACHE.clear();
	}
};

export const getAllocationTotalNew = (allocation, nonWorkingDaysMap, esUTCStartDate, esUTCEndDate) => {
	const startDate = esUTCStartDate
		? esUTCStartDate
		: allocation.startYear
		? new Date(Date.UTC(allocation.startYear, allocation.startMonth - 1, allocation.startDay))
		: new Date(allocation.startDate);
	const endDate = esUTCEndDate
		? esUTCEndDate
		: allocation.endYear
		? new Date(Date.UTC(allocation.endYear, allocation.endMonth - 1, allocation.endDay))
		: new Date(allocation.endDate);

	let nonWorkingDaysDistribution = [0, 0, 0, 0, 0, 0, 0];
	if (!hasFeatureFlag('inverted_pto_non_working_days') && !isTimeOffAllocation(allocation)) {
		nonWorkingDaysDistribution = getNonWorkingDaysDistribution(
			createCanvasTimelineDate(startDate.getUTCFullYear(), startDate.getUTCMonth() + 1, startDate.getUTCDate()),
			createCanvasTimelineDate(endDate.getUTCFullYear(), endDate.getUTCMonth() + 1, endDate.getUTCDate()),
			nonWorkingDaysMap,
			allocation.personId
		);
	}

	//getting how many of each weekday there are in allocation, multiplying by how many hours are allocated per that day and then summing up.
	const weekdaysBetween = allWeekdaysBetween(startDate, endDate, nonWorkingDaysDistribution);
	return [1, 2, 3, 4, 5, 6, 7]
		.map(dayIndex => weekdaysBetween[dayIndex] * allocation[DAY_NAMES[dayIndex - 1]])
		.reduce((total, minutes) => total + minutes, 0);
};

//Get all project allocations and group them by role
export const mapAllocationsToProject = (project, allocations, todayDate) => {
	//just map all allocations by role to project without any regard to filling "any role"
	for (const allocation of allocations) {
		let allocationRoleId;
		if (allocation.project && allocation.project.projectPersons) {
			allocation.project.projectPersons.edges.forEach(projectPerson => {
				if (projectPerson.node.person.id === allocation.person.id && projectPerson.node.role) {
					allocationRoleId = projectPerson.node.role.id;
					if (isNaN(Util.getIdFromBase64String(allocationRoleId))) {
						allocationRoleId = null;
					}
				}
			});
		}
		if (allocationRoleId === undefined || allocationRoleId === null) {
			allocationRoleId = allocation.person.role ? allocation.person.role.id : null;
		}
		let allocationStartDate = Util.CreateNonUtcMomentDate(allocation.startYear, allocation.startMonth, allocation.startDay);
		const allocationEndDate = Util.CreateNonUtcMomentDate(allocation.endYear, allocation.endMonth, allocation.endDay);
		if (allocationEndDate.isBefore(todayDate)) continue;
		if (allocationStartDate.isBefore(todayDate)) {
			allocationStartDate = todayDate;
		}
		const allocationMinutes = getAllocationTotal(allocation, null, allocationStartDate, allocationEndDate);
		const initialValue = project.roleDataMap.get(allocationRoleId);
		project.roleDataMap.set(allocationRoleId, {
			remaining: initialValue.remaining,
			allocated: initialValue.allocated + allocationMinutes,
		});
	}
};
//Map to each phase fragment how much time per role is allocated during that period
export const mapAllocationsToPhaseFragments = (phaseFragments, allocations, todayDate) => {
	for (const allocation of allocations) {
		let allocationRoleId;
		if (allocation.project && allocation.project.projectPersons) {
			allocation.project.projectPersons.edges.forEach(projectPerson => {
				if (
					projectPerson.node.person.id === allocation.person.id &&
					projectPerson.node.role !== undefined &&
					projectPerson.node.role !== null
				) {
					allocationRoleId = projectPerson.node.role.id;
				}
			});
		}
		if (allocationRoleId === undefined || allocationRoleId === null) {
			allocationRoleId = allocation.person.role ? allocation.person.role.id : null;
		}
		const allocationStartDate = Util.CreateNonUtcMomentDate(
			allocation.startYear,
			allocation.startMonth,
			allocation.startDay
		);
		const allocationEndDate = Util.CreateNonUtcMomentDate(allocation.endYear, allocation.endMonth, allocation.endDay).add(
			1,
			'days'
		);
		//If allocation ended before today or starts after project end, skip
		if (allocationEndDate.isBefore(todayDate)) continue;
		//find in which phase fragment the allocation starts
		const findAllocationStartFragmentIndex = () => {
			let minIndex = 0;
			let maxIndex = phaseFragments.length - 1;
			let currentIndex;
			let currentDate;
			while (minIndex <= maxIndex) {
				currentIndex = ((minIndex + maxIndex) / 2) | 0;
				currentDate = phaseFragments[currentIndex].start;
				if (currentDate.isBefore(allocationStartDate)) {
					minIndex = currentIndex + 1;
				} else if (currentDate.isAfter(allocationStartDate)) {
					maxIndex = currentIndex - 1;
				} else {
					return currentIndex;
				}
			}
			return currentDate.isBefore(allocationStartDate) ? currentIndex : currentIndex - 1;
		};

		let phaseFragmentIndex = findAllocationStartFragmentIndex();
		let allocationFragmentStartDate = allocationStartDate.clone();
		let allocationFragmentEndDate = allocationEndDate.clone();
		//if allocation starts before today, trim irrelevant period
		if (phaseFragmentIndex === -1) {
			phaseFragmentIndex = 0;
			allocationFragmentStartDate = todayDate;
		}
		let phaseFragment = phaseFragments[phaseFragmentIndex];
		let allocationMinutes = 0;
		//if allocations spans over multiple fragments, divide it and put data into those fragments
		if (allocationEndDate.isAfter(phaseFragment.end)) {
			let allocationLoopFinished = false;
			while (!allocationLoopFinished) {
				phaseFragment = phaseFragments[phaseFragmentIndex];
				allocationFragmentEndDate = phaseFragment.end;
				if (allocationFragmentEndDate.isAfter(allocationEndDate)) {
					allocationFragmentEndDate = allocationEndDate;
					allocationLoopFinished = true;
				}
				allocationMinutes = getAllocationTotal(
					allocation,
					null,
					allocationFragmentStartDate,
					allocationFragmentEndDate.clone().subtract(1, 'days')
				);
				const initialValue = phaseFragments[phaseFragmentIndex].availablePerRoleMap.has(allocationRoleId)
					? phaseFragments[phaseFragmentIndex].availablePerRoleMap.get(allocationRoleId)
					: 0;
				phaseFragments[phaseFragmentIndex].availablePerRoleMap.set(allocationRoleId, initialValue + allocationMinutes);
				phaseFragmentIndex++;
				allocationFragmentStartDate = phaseFragment.end;
			}
		} else {
			//if just within 1 fragment, do the same but no need for looping
			allocationMinutes = getAllocationTotal(
				allocation,
				null,
				allocationFragmentStartDate,
				allocationEndDate.clone().subtract(1, 'days')
			);
			const initialValue = phaseFragments[phaseFragmentIndex].availablePerRoleMap.has(allocationRoleId)
				? phaseFragments[phaseFragmentIndex].availablePerRoleMap.get(allocationRoleId)
				: 0;
			phaseFragments[phaseFragmentIndex].availablePerRoleMap.set(allocationRoleId, initialValue + allocationMinutes);
		}
	}
};
//Take data from phaseFragments and then calculate how to spread the allocations based on remaining and allocated time
export const mapAllocationsToPhases = (phases, phaseFragments, phaseRoleDataMapMap) => {
	//First stage -> put allocations with specified roles to phases that need those specific roles
	for (const phaseFragment of phaseFragments) {
		for (const availableKey of phaseFragment.availablePerRoleMap.keys()) {
			//skip if there is nothing available
			if (phaseFragment.availablePerRoleMap.get(availableKey) === 0) continue;
			for (const phaseId of phaseFragment.phaseIds) {
				const phaseRoleDataMap = phaseRoleDataMapMap.get(phaseId);
				for (const roleKey of phaseRoleDataMap.keys()) {
					if (roleKey === availableKey) {
						const roleData = phaseRoleDataMap.get(roleKey);
						if (roleData.remaining - roleData.allocated > phaseFragment.availablePerRoleMap.get(availableKey)) {
							roleData.allocated += phaseFragment.availablePerRoleMap.get(availableKey);
							phaseFragment.availablePerRoleMap.set(availableKey, 0);
						} else {
							roleData.allocated = roleData.remaining;
							phaseFragment.availablePerRoleMap.set(
								availableKey,
								phaseFragment.availablePerRoleMap.get(availableKey) - (roleData.remaining - roleData.allocated)
							);
						}
					}
				}
			}
		}
	}
	//Second stage -> fill ANY ROLE row with remaining allocations
	for (const phaseFragment of phaseFragments) {
		for (const availableKey of phaseFragment.availablePerRoleMap.keys()) {
			//skip if there is nothing available
			if (phaseFragment.availablePerRoleMap.get(availableKey) === 0) continue;
			for (const phaseId of phaseFragment.phaseIds) {
				const phaseRoleDataMap = phaseRoleDataMapMap.get(phaseId);
				const roleData = phaseRoleDataMap.get(null);
				if (roleData.remaining - roleData.allocated > phaseFragment.availablePerRoleMap.get(availableKey)) {
					roleData.allocated += phaseFragment.availablePerRoleMap.get(availableKey);
					phaseFragment.availablePerRoleMap.set(availableKey, 0);
				} else {
					roleData.allocated = roleData.remaining;
					phaseFragment.availablePerRoleMap.set(
						availableKey,
						phaseFragment.availablePerRoleMap.get(availableKey) - (roleData.remaining - roleData.allocated)
					);
				}
			}
		}
	}
	//Third stage -> if there is still anything left, dump into corresponding roles to show overallocation
	for (const phaseFragment of phaseFragments) {
		for (const availableKey of phaseFragment.availablePerRoleMap.keys()) {
			//skip if there is nothing available
			if (phaseFragment.availablePerRoleMap.get(availableKey) === 0) continue;
			//Reverse so that phases with later end dates come first
			const phaseIds = phaseFragment.phaseIds.reverse();
			for (const phaseId of phaseIds) {
				//skip the filler phase
				if (phaseId === null) continue;
				const phaseRoleDataMap = phaseRoleDataMapMap.get(phaseId);
				for (const roleKey of phaseRoleDataMap.keys()) {
					if (roleKey === availableKey) {
						const roleData = phaseRoleDataMap.get(roleKey);
						roleData.allocated += phaseFragment.availablePerRoleMap.get(availableKey);
						phaseFragment.availablePerRoleMap.set(availableKey, 0);
					}
				}
			}
		}
	}
};
//Find how much remaining and allocated time is there on a project and each of its phases based on allocations within that project
export const getProjectAllocationData = (project, allocations, roles, todayMoment) => {
	const todayDate = todayMoment.startOf('day');

	const tasks = project.tasks.edges.map(task => {
		return task.node;
	});
	const phases = project.phases.edges.map(phase => {
		return phase.node;
	});

	//Initialize map for storing data about remaining/allocated per role in project
	const projectRoleDataMap = new Map();
	//get values for remaining
	setRemainingRoleData(
		project.tasks.edges.map(task => task.node),
		projectRoleDataMap,
		project,
		roles
	);

	project.roleDataMap = projectRoleDataMap;
	//get values for allocated
	mapAllocationsToProject(project, allocations, todayDate);
	//do the same thing for each phase
	const phaseRoleDataMapMap = new Map();
	for (const phase of phases) {
		phase.tasks = tasks.filter(task => {
			return task.phase && task.phase.id === phase.id;
		});
		const phaseRoleDataMap = new Map();
		setRemainingRoleData(phase.tasks, phaseRoleDataMap, project, roles);
		phase.roleDataMap = phaseRoleDataMap;
		phaseRoleDataMapMap.set(phase.id, phaseRoleDataMap);
	}
	const noPhaseTasks = tasks.filter(task => !task.phase);
	const roleDataMap = new Map();
	const noPhase = {id: null, tasks: noPhaseTasks, roleDataMap: roleDataMap};
	setRemainingRoleData(noPhaseTasks, roleDataMap, project, roles);
	phaseRoleDataMapMap.set(null, roleDataMap);
	phases.push(noPhase);

	const phaseFragments = getPhaseFragments(project, todayDate);
	mapAllocationsToPhaseFragments(phaseFragments, allocations, todayDate);
	mapAllocationsToPhases(phases, phaseFragments, phaseRoleDataMapMap);
	return {project, phases};
};

const getProjectFragments = (projectGroup, todayDate) => {
	const projectDateDataArray = [];
	const addSurroundingProjectIds = index => {
		if (index === 0 || index === projectDateDataArray.length - 1) return;
		const surroundingIds = [...projectDateDataArray[index - 1].projectIds]
			.filter(element => projectDateDataArray[index + 1].projectIds.includes(element))
			.reverse();
		surroundingIds.forEach(projectId => {
			projectDateDataArray[index].projectIds.splice(0, 0, projectId);
		});
	};
	const getInsertData = date => {
		let minIndex = 0;
		let maxIndex = projectDateDataArray.length - 1;
		let currentIndex;
		let currentDate;
		while (minIndex <= maxIndex) {
			currentIndex = ((minIndex + maxIndex) / 2) | 0;
			currentDate = projectDateDataArray[currentIndex].date;
			if (currentDate.isBefore(date)) {
				minIndex = currentIndex + 1;
			} else if (currentDate.isAfter(date)) {
				maxIndex = currentIndex - 1;
			} else {
				return {index: currentIndex, duplicate: true};
			}
		}
		return {index: currentDate.isBefore(date) ? currentIndex + 1 : currentIndex, duplicate: false};
	};
	const insertProjectDate = (insertData, date, projectId) => {
		if (insertData.duplicate) {
			projectDateDataArray[insertData.index].projectIds.push(projectId);
		} else {
			projectDateDataArray.splice(insertData.index, 0, {date, projectIds: [projectId]});
			addSurroundingProjectIds(insertData.index);
		}
	};

	const addProjectData = (startDate, endDate, projectId) => {
		if (!projectDateDataArray.length) {
			projectDateDataArray.push({date: startDate, projectIds: [projectId]});
			projectDateDataArray.push({date: endDate, projectIds: [projectId]});
			return;
		}
		const startInsertData = getInsertData(startDate);
		insertProjectDate(startInsertData, startDate, projectId);
		const endInsertData = getInsertData(endDate);
		for (let i = endInsertData.index - 1; i > startInsertData.index; i--) {
			projectDateDataArray[i].projectIds.push(projectId);
		}
		insertProjectDate(endInsertData, endDate, projectId);
	};

	const fillerProjectStartDate = Util.CreateNonUtcMomentDate(-10000, 1, 1);
	const fillerProjectEndDate = Util.CreateNonUtcMomentDate(10000, 1, 1);
	addProjectData(fillerProjectStartDate, fillerProjectEndDate, null);
	const projects = projectGroup.projects.edges
		.filter(projectEdge => {
			const projectStartDate = Util.CreateNonUtcMomentDate(
				projectEdge.node.projectStartYear,
				projectEdge.node.projectStartMonth,
				projectEdge.node.projectStartDay
			);
			const projectEndDate = Util.CreateNonUtcMomentDate(
				projectEdge.node.projectEndYear,
				projectEdge.node.projectEndMonth,
				projectEdge.node.projectEndDay
			);
			return projectStartDate && projectEndDate && projectStartDate.isValid() && projectEndDate.isValid();
		})
		.map(project => {
			return project.node;
		})
		.sort((a, b) => {
			const aEndDate = Util.CreateNonUtcMomentDate(a.projectEndYear, a.projectEndMonth, a.projectEndDay);
			const bEndDate = Util.CreateNonUtcMomentDate(b.projectEndYear, b.projectEndMonth, b.projectEndDay);
			const aStartDate = Util.CreateNonUtcMomentDate(a.projectStartYear, a.projectStartMonth, a.projectStartDay);
			const bStartDate = Util.CreateNonUtcMomentDate(b.projectStartYear, b.projectStartMonth, b.projectStartDay);
			if (aEndDate.isAfter(bEndDate)) return 1;
			if (aEndDate.isBefore(bEndDate)) return -1;
			if (aStartDate.isAfter(bStartDate)) return 1;
			if (aStartDate.isBefore(bStartDate)) return -1;
			return 0;
		});
	let projectGroupStartDate = null;
	let projectGroupEndDate = null;
	for (const project of projects) {
		if (project.projectStartYear) {
			const projectStartDate = Util.CreateNonUtcMomentDate(
				project.projectStartYear,
				project.projectStartMonth,
				project.projectStartDay
			);
			if (projectGroupStartDate === null || projectGroupStartDate.isAfter(projectStartDate)) {
				projectGroupStartDate = projectStartDate;
			}
		}
		if (project.projectEndYear) {
			const projectEndDate = Util.CreateNonUtcMomentDate(
				project.projectEndYear,
				project.projectEndMonth,
				project.projectEndDay
			);
			if (projectGroupEndDate === null || projectGroupEndDate.isBefore(projectEndDate)) {
				projectGroupEndDate = projectEndDate;
			}
		}
	}
	for (const project of projects) {
		const projectStartDate = Util.CreateNonUtcMomentDate(
			project.projectStartYear,
			project.projectStartMonth,
			project.projectStartDay
		);
		const projectEndDate = Util.CreateNonUtcMomentDate(
			project.projectEndYear,
			project.projectEndMonth,
			project.projectEndDay
		);
		addProjectData(projectStartDate, projectEndDate.add(1, 'days'), project.id);
	}
	const projectFragments = [];
	for (let i = 0; i < projectDateDataArray.length - 1; i++) {
		let currentProjectDateEntry = projectDateDataArray[i];
		const nextProjectDateEntry = projectDateDataArray[i + 1];
		if (currentProjectDateEntry.date.isBefore(todayDate)) {
			if (nextProjectDateEntry.date.isBefore(todayDate)) continue;
			currentProjectDateEntry.date = todayDate;
		}
		projectFragments.push({
			start: currentProjectDateEntry.date,
			end: nextProjectDateEntry.date,
			projectIds: currentProjectDateEntry.projectIds.filter(projectId => {
				return nextProjectDateEntry.projectIds.includes(projectId);
			}),
			availablePerRoleMap: new Map(),
		});
	}
	return projectFragments;
};

const mapAllocationsToProjectFragments = (projectFragments, allocations, todayDate, projectGroup) => {
	for (const allocation of allocations) {
		let allocationRoleId;
		if (allocation.projectGroupId && allocation.projectGroupId === projectGroup.id) {
			projectGroup.projects.edges[0].node.projectPersons.edges.forEach(projectPerson => {
				if (
					projectPerson.node.person.id === allocation.person.id &&
					projectPerson.node.role !== undefined &&
					projectPerson.node.role !== null
				) {
					allocationRoleId = projectPerson.node.role.id;
				}
			});
		}
		if (allocationRoleId === undefined || allocationRoleId === null) {
			allocationRoleId = allocation.person.role ? allocation.person.role.id : null;
		}
		const allocationStartDate = Util.CreateNonUtcMomentDate(
			allocation.startYear,
			allocation.startMonth,
			allocation.startDay
		);
		const allocationEndDate = Util.CreateNonUtcMomentDate(allocation.endYear, allocation.endMonth, allocation.endDay).add(
			1,
			'days'
		);
		if (allocationEndDate.isBefore(todayDate)) continue;
		const findAllocationStartFragmentIndex = () => {
			let minIndex = 0;
			let maxIndex = projectFragments.length - 1;
			let currentIndex;
			let currentDate;
			while (minIndex <= maxIndex) {
				currentIndex = ((minIndex + maxIndex) / 2) | 0;
				currentDate = projectFragments[currentIndex].start;
				if (currentDate.isBefore(allocationStartDate)) {
					minIndex = currentIndex + 1;
				} else if (currentDate.isAfter(allocationStartDate)) {
					maxIndex = currentIndex - 1;
				} else {
					return currentIndex;
				}
			}
			return currentDate.isBefore(allocationStartDate) ? currentIndex : currentIndex - 1;
		};

		let projectFragmentIndex = findAllocationStartFragmentIndex();
		let allocationFragmentStartDate = allocationStartDate.clone();
		let allocationFragmentEndDate = allocationEndDate.clone();
		//if allocation starts before today, trim irrelevant period
		if (projectFragmentIndex === -1) {
			projectFragmentIndex = 0;
			allocationFragmentStartDate = todayDate;
		}
		let projectFragment = projectFragments[projectFragmentIndex];
		let allocationMinutes = 0;
		//if allocations spans over multiple fragments, divide it and put data into those fragments
		if (allocationEndDate.isAfter(projectFragment.end)) {
			let allocationLoopFinished = false;
			while (!allocationLoopFinished) {
				projectFragment = projectFragments[projectFragmentIndex];
				allocationFragmentEndDate = projectFragment.end;
				if (allocationFragmentEndDate.isAfter(allocationEndDate)) {
					allocationFragmentEndDate = allocationEndDate;
					allocationLoopFinished = true;
				}
				allocationMinutes = getAllocationTotal(
					allocation,
					null,
					allocationFragmentStartDate,
					allocationFragmentEndDate.clone().subtract(1, 'days')
				);
				const initialValue = projectFragments[projectFragmentIndex].availablePerRoleMap.has(allocationRoleId)
					? projectFragments[projectFragmentIndex].availablePerRoleMap.get(allocationRoleId)
					: 0;
				projectFragments[projectFragmentIndex].availablePerRoleMap.set(
					allocationRoleId,
					initialValue + allocationMinutes
				);
				projectFragmentIndex++;
				allocationFragmentStartDate = projectFragment.end;
			}
		} else {
			//if just within 1 fragment, do the same but no need for looping
			allocationMinutes = getAllocationTotal(
				allocation,
				null,
				allocationFragmentStartDate,
				allocationEndDate.clone().subtract(1, 'days')
			);
			const initialValue = projectFragments[projectFragmentIndex].availablePerRoleMap.has(allocationRoleId)
				? projectFragments[projectFragmentIndex].availablePerRoleMap.get(allocationRoleId)
				: 0;
			projectFragments[projectFragmentIndex].availablePerRoleMap.set(allocationRoleId, initialValue + allocationMinutes);
		}
	}
};

export const mapAllocationsToProjects = (projects, projectFragments, projectsRoleDataMapMap) => {
	for (const projectFragment of projectFragments) {
		for (const availableKey of projectFragment.availablePerRoleMap.keys()) {
			if (projectFragment.availablePerRoleMap.get(availableKey) === 0) continue;
			for (const projectId of projectFragment.projectIds) {
				const projectRoleDataMap = projectsRoleDataMapMap.get(projectId);
				for (const roleKey of projectRoleDataMap.keys()) {
					if (roleKey === availableKey) {
						const roleData = projectRoleDataMap.get(roleKey);
						if (roleData.remaining - roleData.allocated > projectFragment.availablePerRoleMap.get(availableKey)) {
							roleData.allocated += projectFragment.availablePerRoleMap.get(availableKey);
							projectFragment.availablePerRoleMap.set(availableKey, 0);
						} else {
							roleData.allocated = roleData.remaining;
							projectFragment.availablePerRoleMap.set(
								availableKey,
								projectFragment.availablePerRoleMap.get(availableKey) -
									(roleData.remaining - roleData.allocated)
							);
						}
					}
				}
			}
		}
	}
	for (const projectFragment of projectFragments) {
		for (const availableKey of projectFragment.availablePerRoleMap.keys()) {
			if (projectFragment.availablePerRoleMap.get(availableKey) === 0) continue;
			for (const projectId of projectFragment.projectIds) {
				const projectRoleDataMap = projectsRoleDataMapMap.get(projectId);
				const roleData = projectRoleDataMap.get(null);
				if (roleData.remaining - roleData.allocated > projectFragment.availablePerRoleMap.get(availableKey)) {
					roleData.allocated += projectFragment.availablePerRoleMap.get(availableKey);
					projectFragment.availablePerRoleMap.set(availableKey, 0);
				} else {
					roleData.allocated = roleData.remaining;
					projectFragment.availablePerRoleMap.set(
						availableKey,
						projectFragment.availablePerRoleMap.get(availableKey) - (roleData.remaining - roleData.allocated)
					);
				}
			}
		}
	}
	for (const projectFragment of projectFragments) {
		for (const availableKey of projectFragment.availablePerRoleMap.keys()) {
			if (projectFragment.availablePerRoleMap.get(availableKey) === 0) continue;
			const projectIds = projectFragment.projectIds.reverse();
			for (const projectId of projectIds) {
				const projectRoleDataMap = projectsRoleDataMapMap.get(projectId);
				for (const roleKey of projectRoleDataMap.keys()) {
					if (roleKey === availableKey) {
						const roleData = projectRoleDataMap.get(roleKey);
						roleData.allocated += projectFragment.availablePerRoleMap.get(availableKey);
						projectFragment.availablePerRoleMap.set(availableKey, 0);
					}
				}
			}
		}
	}
};

//Find how much remaining and allocated time is there on a project group and each of its projects based on allocations within that project group
export const getProjectGroupAllocationData = (projectGroup, allocations, roles, todayMoment) => {
	const todayDate = todayMoment.startOf('day');

	const groupRolesDataMap = new Map();
	const projectsRoleDataMap = new Map();
	const temp = new Map();
	const initialData = {remaining: 0, allocated: 0, minutesRegistered: 0, taskCount: 0, doneTaskCount: 0};

	groupRolesDataMap.set(null, {...initialData});
	for (const role of roles) {
		temp.set(role.id, {...initialData});
		groupRolesDataMap.set(role.id, {...initialData});
	}
	temp.set(null, {...initialData});

	const projects = [];
	projectGroup.projects.edges.forEach(project => {
		projects.push(project.node);
	});
	projects.forEach(project => {
		const projectRoleDataMap = new Map();
		//get values for remaining
		setRemainingRoleData(
			project.tasks.edges.map(task => task.node),
			projectRoleDataMap,
			project,
			roles
		);
		project.roleDataMap = projectRoleDataMap;
		project.roleDataMap.forEach((value, key, map) => {
			if (groupRolesDataMap.has(key)) {
				const roleData = groupRolesDataMap.get(key);
				roleData.remaining += value.remaining;
			} else {
				groupRolesDataMap.set(key, {...initialData, remaining: value.remaining});
			}
		});
		projectsRoleDataMap.set(project.id, projectRoleDataMap);
	});
	projectGroup.roleDataMap = groupRolesDataMap;
	for (const allocation of allocations) {
		let allocationRoleId;
		if (allocation.projectGroupId && projectGroup.projects.edges[0].node.projectPersons) {
			projectGroup.projects.edges[0].node.projectPersons.edges.forEach(projectPerson => {
				if (projectPerson.node.person.id === allocation.person.id && projectPerson.node.role) {
					allocationRoleId = projectPerson.node.role.id;
				}
			});
		}
		if (allocationRoleId === undefined || allocationRoleId === null) {
			allocationRoleId = allocation.person.role ? allocation.person.role.id : null;
		}
		let allocationStartDate = Util.CreateNonUtcMomentDate(allocation.startYear, allocation.startMonth, allocation.startDay);
		const allocationEndDate = Util.CreateNonUtcMomentDate(allocation.endYear, allocation.endMonth, allocation.endDay);
		if (allocationEndDate.isBefore(todayDate)) continue;
		if (allocationStartDate.isBefore(todayDate)) {
			allocationStartDate = todayDate;
		}
		const allocationMinutes = getAllocationTotal(allocation, null, allocationStartDate, allocationEndDate);
		const initialValue = projectGroup.roleDataMap.get(allocationRoleId);
		initialValue.allocated += allocationMinutes;
	}
	projectsRoleDataMap.set(null, temp);
	const projectFragments = getProjectFragments(projectGroup, todayDate);
	mapAllocationsToProjectFragments(projectFragments, allocations, todayDate, projectGroup);

	mapAllocationsToProjects(projects, projectFragments, projectsRoleDataMap);
	return {projectGroup, projects};
};
