/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ProjectPortfolioReportCharts_viewer$ref: FragmentReference;
declare export opaque type ProjectPortfolioReportCharts_viewer$fragmentType: ProjectPortfolioReportCharts_viewer$ref;
export type ProjectPortfolioReportCharts_viewer = {|
  +id: string,
  +company: ?{|
    +currency: ?string,
    +aggregatedFinancialNumbers: $ReadOnlyArray<?string>,
  |},
  +$refType: ProjectPortfolioReportCharts_viewer$ref,
|};
export type ProjectPortfolioReportCharts_viewer$data = ProjectPortfolioReportCharts_viewer;
export type ProjectPortfolioReportCharts_viewer$key = {
  +$data?: ProjectPortfolioReportCharts_viewer$data,
  +$fragmentRefs: ProjectPortfolioReportCharts_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchQuery",
      "type": "TaskSearchQueryType!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "startDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endYear",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endMonth",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDay",
      "type": "Int"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregateLevel",
      "type": "AggregateLevel!"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "aggregates",
      "type": "[FinancialAggregatedNumber]!"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectPortfolioReportCharts_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "aggregateLevel",
              "variableName": "aggregateLevel"
            },
            {
              "kind": "Variable",
              "name": "aggregates",
              "variableName": "aggregates"
            },
            {
              "kind": "Variable",
              "name": "endDay",
              "variableName": "endDay"
            },
            {
              "kind": "Variable",
              "name": "endMonth",
              "variableName": "endMonth"
            },
            {
              "kind": "Variable",
              "name": "endYear",
              "variableName": "endYear"
            },
            {
              "kind": "Variable",
              "name": "searchQuery",
              "variableName": "searchQuery"
            },
            {
              "kind": "Variable",
              "name": "startDay",
              "variableName": "startDay"
            },
            {
              "kind": "Variable",
              "name": "startMonth",
              "variableName": "startMonth"
            },
            {
              "kind": "Variable",
              "name": "startYear",
              "variableName": "startYear"
            }
          ],
          "kind": "ScalarField",
          "name": "aggregatedFinancialNumbers",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer"
};
// prettier-ignore
(node/*: any*/).hash = '78cfce5c99ac62e6346fc53189bdd43c';

module.exports = node;
