import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedHTMLMessage} from 'react-intl';
import RichTextItem from '../../components/richtext_item';
import Info from '../../components/info';
import Util from '../../forecast-app/shared/util/util';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import {INSIGHTS_NEW_BUDGET_COLUMNS} from '../../constants';

class ComponentPlaceholder extends Component {
	constructor(props) {
		super(props);

		const displayColumns = {};
		if (this.props.config.columns) {
			this.props.config.columns.forEach(column => {
				displayColumns[column] = true;
			});
		}

		this.state = {
			columnsExpanded: false,
			configExpanded: false,
			displayColumns: displayColumns,
			showInfo: false,
		};
	}

	removeComponent() {
		this.props.remove(this.props.id);
	}

	toggleColumnsDropdown() {
		this.setState({columnsExpanded: !this.state.columnsExpanded});
	}

	toggleConfigDropdown() {
		this.setState({configExpanded: !this.state.configExpanded});
	}

	collapseDropdown(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (
			newTarget &&
			(newTarget.className.includes('config-input') || newTarget.className.includes('colors-picker-container'))
		) {
			return;
		}

		this.setState({columnsExpanded: false, configExpanded: false});
	}

	toggleColumn(column) {
		const config = this.props.config;
		if (config.columns) {
			if (config.columns.includes(column)) {
				const index = config.columns.indexOf(column);
				if (index > -1) {
					config.columns.splice(index, 1);
				}
			} else config.columns.push(column);
			this.props.updateComponentConfiguration(this.props.id, config);
		}
	}

	handleFreeTextChange(value) {
		const config = {text: value};
		this.props.updateComponentConfiguration(this.props.id, config);
	}

	render() {
		const config = this.props.config;
		const currency = Util.GetCurrencySymbol(this.props.currency);
		return (
			<div className="placeholder">
				<div className="inner">
					<div className="edit-header">
						<div className="title">{this.props.title}</div>
						{this.props.backendFilter ? this.props.backendFilter : null}
						<Info infoMessage={this.props.infoText} leftAligned={true} />
						{this.props.canSelectColumns ? (
							<div className="dropdown-wrapper">
								<div
									className={'columns ' + (this.state.columnsExpanded ? 'expanded' : 'collapsed')}
									onClick={this.toggleColumnsDropdown.bind(this)}
									tabIndex="0"
									onBlur={this.collapseDropdown.bind(this)}
								>
									<div
										className={'drop-down ' + (this.state.columnsExpanded ? 'expanded' : 'collapsed')}
										onClick={e => e.stopPropagation()}
									>
										<CustomScrollDiv autoHeight={true} autoHeightMax={350}>
											{this.props.columns
												? this.props.columns.map(column => {
														return (
															<div
																className="option"
																key={column}
																onClick={this.toggleColumn.bind(this, column)}
															>
																<input
																	type="checkbox"
																	readOnly={true}
																	checked={
																		config.columns ? config.columns.includes(column) : true
																	}
																	onClick={e => e.stopPropagation()}
																/>
																<h4>
																	{INSIGHTS_NEW_BUDGET_COLUMNS.includes(column) ? (
																		<FormattedHTMLMessage
																			id={`insights.component.list.column.${column}.newBudget`}
																		/>
																	) : (
																		<FormattedHTMLMessage
																			id={'insights.component.list.column.' + column}
																			values={{suffix: currency}}
																		/>
																	)}
																</h4>
															</div>
														);
												  })
												: null}
										</CustomScrollDiv>
									</div>
								</div>
							</div>
						) : null}
						{this.props.configContent ? (
							<div className="dropdown-wrapper">
								<div
									className={'configure ' + (this.state.configExpanded ? 'expanded' : 'collapsed')}
									onClick={this.toggleConfigDropdown.bind(this)}
									tabIndex="1"
								>
									<div
										className={'drop-down ' + (this.state.configExpanded ? 'expanded' : 'collapsed')}
										onClick={e => e.stopPropagation()}
									>
										{this.props.configContent}
									</div>
								</div>
							</div>
						) : null}
						<div className="remove" onClick={this.removeComponent.bind(this)} />
					</div>
					<div className={'body component-' + this.props.componentName}>
						{this.props.noAccess
							? React.cloneElement(this.props.children, {
									id: this.props.id,
									scrollElement: this.props.scrollElement,
									notifyOnReady: this.props.notifyOnReady,
									shouldListsRecalculatePosition: this.props.shouldListsRecalculatePosition,
							  })
							: null}
						{!this.props.noAccess && this.props.children
							? React.cloneElement(this.props.children, {
									id: this.props.id,
									allColumns: this.props.columns,
									activeColumns: config.columns,
									scrollElement: this.props.scrollElement,
									notifyOnReady: this.props.notifyOnReady,
									shouldListsRecalculatePosition: this.props.shouldListsRecalculatePosition,
							  })
							: null}
						{this.props.componentName === 'freeTextField' ? (
							<div className="richtext-container">
								<RichTextItem
									alwaysShowControls={true}
									text={config.text ? config.text : null}
									placeholder={''}
									handleTextChange={this.handleFreeTextChange.bind(this)}
									hideEmojiPicker={true}
								/>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

ComponentPlaceholder.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	componentName: PropTypes.string.isRequired,
	canConfigure: PropTypes.bool,
	remove: PropTypes.func.isRequired,
};

export default ComponentPlaceholder;
