import Styled from 'styled-components/macro';

export const PageWrapper = Styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const StandardMargin = Styled.div`
    margin: 0 24px;
    margin-bottom: ${props => props.marginBottom && `${props.marginBottom}px`};
`;

export const Section = Styled.div`
position:relative;
    display: flex;
    position: ${props => props.sticky && 'sticky'};
    left: ${props => props.sticky && '24px'};
    height: ${props => props.height && `${props.height}px`};
    margin-bottom: ${props => props.marginBottom && `${props.marginBottom}px`};
    margin-top: ${props => props.marginTop && `${props.marginTop}px`};
    margin-left: ${props => props.marginLeft && `${props.marginLeft}px`};
    margin-right: ${props => props.marginRight && `${props.marginRight}px`};
`;

export const SplitSection = Styled(Section)`
    justify-content: space-between;
    align-items: center;
`;

export const TablesSection = Styled.div`
`;

export const FlexRow = Styled.div`
    display: flex;
    flex-grow: ${({growable}) => growable && '1'};
    flex-direction: row;
`;

export const OutlineBox = Styled.div`
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    padding: 16px 20px;
    background-color: white;
    flex-grow: ${props => props.growable && '1'};
    margin-left: ${props => props.marginLeft && `${props.marginLeft}px`};
    margin-right: ${props => props.marginRight && `${props.marginRight}px`};
    margin-bottom: ${props => props.marginBottom && `${props.marginBottom}px`};
`;

export const TableToggleContainer = Styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #535353;
`;
