/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/*
query BaselineEditPageQuery(
  $projectId: String
) {
  viewer {
    actualPersonId
    component(name: "edit_baseline")
    project(id: $projectId) {
      id
    }
    ...BaselineEditPage_viewer_2CgS8h
    id
  }
}

fragment BaselineEditPage_viewer_2CgS8h on Viewer {
  id
  company {
    id
    name
    currency
  }
  project(id: $projectId) {
    ...ProjectIndicator_project
    useBaseline
    useDeliverables
    budgetType
    budget
    id
    status
    companyProjectId
    customProjectId
    projectColor
    name
    baselineEstimationMinutesPerDay
    projectStartYear
    projectStartMonth
    projectStartDay
    projectEndYear
    projectEndMonth
    projectEndDay
    baselineTargetMinutes
    baselineTarget
    baselineCost
    baselineProfit
    baselineAdjustPercentage
    baselineAdjustPrice
    baselineTargetPrice
    useFixedPriceForBaselineRevenue
    rateCard {
      id
      currency
      disabledRoles {
        id
      }
    }
    groupedFinancialNumbers(convertToProjectCurrency: true, groupBy: ["PHASE_BASELINE_ROLE", "PHASE_BASELINE_EXPENSE", "PHASE"]) {
      baselineMinutes
      baselineRevenue
      baselineTimeAndExpenses
      baselineCost
      baselineProfit
      baselineMargin
      baselineRatePerHour
      baselineCostPerHour
      phaseBaselineRoleId
      phaseBaselineExpenseId
      phaseId
    }
  }
}

fragment ProjectIndicator_project on ProjectType {
  name
  companyProjectId
  customProjectId
  projectColor
}
*/
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "projectId",
            "type": "String"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actualPersonId",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Literal",
                "name": "name",
                "value": "edit_baseline"
            }
        ],
        "kind": "ScalarField",
        "name": "component",
        "storageKey": "component(name:\"edit_baseline\")"
    }, v3 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "projectId"
        }
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = [
        (v4 /*: any*/)
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineCost",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineProfit",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "BaselineEditPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": (v3 /*: any*/),
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": (v5 /*: any*/),
                            "storageKey": null
                        },
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "projectId",
                                    "variableName": "projectId"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "BaselineEditPage_viewer"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query"
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "BaselineEditPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": (v3 /*: any*/),
                            "concreteType": "ProjectType",
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                (v6 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "companyProjectId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "customProjectId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "projectColor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "useBaseline",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "useDeliverables",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "budgetType",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "budget",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "status",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "baselineEstimationMinutesPerDay",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "projectStartYear",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "projectStartMonth",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "projectStartDay",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "projectEndYear",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "projectEndMonth",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "projectEndDay",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "baselineTargetMinutes",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "baselineTarget",
                                    "storageKey": null
                                },
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "baselineAdjustPercentage",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "baselineAdjustPrice",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "baselineTargetPrice",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "useFixedPriceForBaselineRevenue",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RateCard",
                                    "kind": "LinkedField",
                                    "name": "rateCard",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        (v9 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Role",
                                            "kind": "LinkedField",
                                            "name": "disabledRoles",
                                            "plural": true,
                                            "selections": (v5 /*: any*/),
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "convertToProjectCurrency",
                                            "value": true
                                        },
                                        {
                                            "kind": "Literal",
                                            "name": "groupBy",
                                            "value": [
                                                "PHASE_BASELINE_ROLE",
                                                "PHASE_BASELINE_EXPENSE",
                                                "PHASE"
                                            ]
                                        }
                                    ],
                                    "concreteType": "FinancialNumbers",
                                    "kind": "LinkedField",
                                    "name": "groupedFinancialNumbers",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "baselineMinutes",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "baselineRevenue",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "baselineTimeAndExpenses",
                                            "storageKey": null
                                        },
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "baselineMargin",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "baselineRatePerHour",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "baselineCostPerHour",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "phaseBaselineRoleId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "phaseBaselineExpenseId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "phaseId",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "groupedFinancialNumbers(convertToProjectCurrency:true,groupBy:[\"PHASE_BASELINE_ROLE\",\"PHASE_BASELINE_EXPENSE\",\"PHASE\"])"
                                }
                            ],
                            "storageKey": null
                        },
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                (v6 /*: any*/),
                                (v9 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": null,
            "metadata": {},
            "name": "BaselineEditPageQuery",
            "operationKind": "query",
            "text": "query BaselineEditPageQuery(\n  $projectId: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"edit_baseline\")\n    project(id: $projectId) {\n      id\n    }\n    ...BaselineEditPage_viewer_2CgS8h\n    id\n  }\n}\n\nfragment BaselineEditPage_viewer_2CgS8h on Viewer {\n  id\n  company {\n    id\n    name\n    currency\n  }\n  project(id: $projectId) {\n    ...ProjectIndicator_project\n    useBaseline\n    useDeliverables\n    budgetType\n    budget\n    id\n    status\n    companyProjectId\n    customProjectId\n    projectColor\n    name\n    baselineEstimationMinutesPerDay\n    projectStartYear\n    projectStartMonth\n    projectStartDay\n    projectEndYear\n    projectEndMonth\n    projectEndDay\n    baselineTargetMinutes\n    baselineTarget\n    baselineCost\n    baselineProfit\n    baselineAdjustPercentage\n    baselineAdjustPrice\n    baselineTargetPrice\n    useFixedPriceForBaselineRevenue\n    rateCard {\n      id\n      currency\n      disabledRoles {\n        id\n      }\n    }\n    groupedFinancialNumbers(convertToProjectCurrency: true, groupBy: [\"PHASE_BASELINE_ROLE\", \"PHASE_BASELINE_EXPENSE\", \"PHASE\"]) {\n      baselineMinutes\n      baselineRevenue\n      baselineTimeAndExpenses\n      baselineCost\n      baselineProfit\n      baselineMargin\n      baselineRatePerHour\n      baselineCostPerHour\n      phaseBaselineRoleId\n      phaseBaselineExpenseId\n      phaseId\n    }\n  }\n}\n\nfragment ProjectIndicator_project on ProjectType {\n  name\n  companyProjectId\n  customProjectId\n  projectColor\n}\n"
        }
    };
})();
node.hash = '8fdb98b3a2002690c63b1d6f33df9b10';
export default node;
