import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {createRefetchContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Util from '../../../forecast-app/shared/util/util';
import Person from '../../../forecast-app/shared/components/person/person';
import InsightsUtil from '../insights_util';
import {profilePicSrc} from '../../../directApi';

class peopleListProjectsComponent extends Component {
	constructor(props) {
		super(props);

		// Calculate height based on the amount of people
		const listHeight = InsightsUtil.GetlistHeight(props.viewer.insightComponentsData.peopleListProjects.people.length);
		this.props.updateComponentHeight(this.props.id, listHeight);

		this.state = {
			sortBy: {column: null, ascending: true},
		};
	}
	UNSAFE_componentWillMount() {
		if (this.props.setExportColumns) {
			const configObject = this.props.config ? JSON.parse(this.props.config) : {};
			let exportColumns = [];
			if (configObject) {
				this.props.columns.forEach(col => {
					if (configObject.columns.indexOf(col) > -1) {
						if (col === 'name' || col === 'labels' || col === 'reported' || col === 'spend') {
							exportColumns.push(col + '_raw');
						} else if (col === 'remaining') {
							exportColumns.push(col + '_hours');
							exportColumns.push(col + '_points');
						} else {
							exportColumns.push(col);
						}
					}
				});
			}
			this.props.setExportColumns(exportColumns);
		}
		this.setData(this.props, this.state);
	}
	UNSAFE_componentWillReceiveProps(nextProps, nextState) {
		if (this.props.projectId !== nextProps.projectId) {
			this.props.relay.refetch({projectId: nextProps.projectId, isProjectGroupType: false});
		}
		if (this.props.projectGroupId !== nextProps.projectGroupId) {
			this.props.relay.refetch({projectId: nextProps.projectGroupId, isProjectGroupType: true});
		}
		if (this.props.labelFilterValue.length !== nextProps.labelFilterValue.length) {
			const labelIds = nextProps.labelFilterValue.length !== 0 ? nextProps.labelFilterValue.map(el => el.value) : [];
			this.props.relay.refetch({labelIds});
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		this.setData(nextProps, nextState);
		const listHeight = InsightsUtil.GetlistHeight(this.people.length);
		this.props.updateComponentHeight(this.props.id, listHeight);
	}

	setSortBy(column) {
		let ascending = true;
		if (column === this.state.sortBy.column) {
			ascending = !this.state.sortBy.ascending;
		}
		this.setState({sortBy: {column: column, ascending: ascending}});
	}

	setData(props, state) {
		const {formatMessage, formatNumber} = props.intl;
		const peopleList = props.viewer.insightComponentsData.peopleListProjects;
		const isInHours = peopleList.useHours;
		this.people = [];
		this.totals = {
			todoCards: formatNumber(peopleList.todoTasksCountTotal),
			inProgressCards: formatNumber(peopleList.progressTasksCountTotal),
			doneCards: formatNumber(peopleList.doneTasksCountTotal),
			totalCards: formatNumber(peopleList.tasksCountTotal),
			reported: Util.convertMinutesToFullHour(peopleList.reportedTotal, props.intl),
			remaining: isInHours
				? Util.convertMinutesToFullHour(peopleList.remainingTotal, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: peopleList.remainingTotal}),
			spend: Util.getFormattedCurrencyValue(
				Util.GetCurrencySymbol(props.viewer.insightComponentsData.peopleListProjects.currency),
				formatNumber(Math.round(peopleList.spendTotal * 100.0) / 100.0)
			),
		};
		props.viewer.insightComponentsData.peopleListProjects.people.forEach(element => {
			const person = {};
			person.id = element.id;
			person.name = (
				<Person
					key={element.id}
					name={element.name}
					showName={true}
					showRole={false}
					imageSrc={profilePicSrc(element.profilePictureId)}
					imageSize="medium"
				/>
			);
			person.name_raw = element.name;
			person.defaultRole = element.defaultRole;
			person.projectRole = element.projectRole;
			person.email = element.email;
			person.labels = element.labels;
			person.labels_raw = element.labels.map(label => label.name);
			person.skills = element.skills.map(skill => skill.name);

			person.permission = element.profiles.edges.map(e => e.node.name).join(', ');
			person.todoCards = element.todoTasksCount;
			person.inProgressCards = element.progressTasksCount;
			person.doneCards = element.doneTasksCount;
			person.totalCards = element.tasksCount;
			person.reported = Util.convertMinutesToFullHour(element.reportedMinutes, props.intl);
			person.reported_raw = Math.round((element.reportedMinutes / 60.0) * 100) / 100;
			person.remaining = isInHours
				? Util.convertMinutesToFullHour(element.remaining, props.intl)
				: formatMessage({id: 'common.x_points'}, {points: element.remaining});
			person.remaining_raw = element.remaining;
			person.remaining_hours = isInHours ? Math.round((element.remaining / 60.0) * 100) / 100 : null;
			person.remaining_points = isInHours ? null : element.remaining;
			if (this.canViewFinancial()) {
				person.spend = Util.getFormattedCurrencyValue(
					Util.GetCurrencySymbol(props.viewer.insightComponentsData.peopleListProjects.currency),
					formatNumber(Math.round(element.spend * 100.0) / 100.0)
				);
				person.spend_raw = Math.round(element.spend * 100) / 100;
			}
			this.people.push(person);
		});
		if (state.sortBy.column !== this.state.sortBy.column || state.sortBy.ascending !== this.state.sortBy.ascending) {
			this.sort(state.sortBy);
		}
		if (props.setExportData) {
			props.setExportData(this.people);
		}
	}

	canViewFinancial() {
		return this.props.canViewFinancial !== false;
	}

	sort(sortBy) {
		if (sortBy.column) {
			this.people = this.people.sort((a, b) => {
				let column = sortBy.column;
				if (column === 'skills') {
					return null;
				}
				switch (column) {
					case 'remaining':
					case 'spend':
					case 'reported':
					case 'name':
						column += '_raw';
						break;
					case 'progress':
						column = 'percentDone';
						break;
				}

				let returnValue = 0;
				if (a[column] < b[column]) returnValue = 1;
				if (a[column] > b[column]) returnValue = -1;
				if (a[column] === '') returnValue = -1;

				return sortBy.ascending ? returnValue * -1 : returnValue;
			});
		}
	}

	render() {
		const configObject = this.props.config ? JSON.parse(this.props.config) : {};
		return (
			<InsightList
				componentId={this.props.id}
				data={this.people}
				componentName={'peopleListProjects'}
				activeColumns={configObject ? configObject.columns : []}
				allColumns={this.props.columns}
				sortBy={this.state.sortBy}
				setSortBy={this.setSortBy.bind(this)}
				disableSort="skills"
				totals={this.totals}
				scrollElement={this.props.scrollElement}
				scrollElementFullScreen={this.props.scrollElementFullScreen}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

peopleListProjectsComponent.propTypes = {
	title: PropTypes.string,
};

const peopleListProjectsComponentQuery = graphql`
	query peopleListProjectsComponent_Query(
		$shareKey: String
		$componentId: ID
		$isProjectGroupType: Boolean
		$projectId: ID
		$personIds: [ID]
		$teamIds: [ID]
		$roleIds: [ID]
		$labelIds: [ID]
		$skillIds: [ID]
	) {
		viewer {
			actualPersonId
			component(name: "insight_people_list_project")
			...peopleListProjectsComponent_viewer
				@arguments(
					shareKey: $shareKey
					componentId: $componentId
					isProjectGroupType: $isProjectGroupType
					projectId: $projectId
					personIds: $personIds
					teamIds: $teamIds
					roleIds: $roleIds
					labelIds: $labelIds
					skillIds: $skillIds
				)
		}
	}
`;

export {peopleListProjectsComponentQuery};

export default injectIntl(
	createRefetchContainer(
		peopleListProjectsComponent,
		{
			viewer: graphql`
				fragment peopleListProjectsComponent_viewer on Viewer
				@argumentDefinitions(
					shareKey: {type: "String"}
					componentId: {type: "ID"}
					isProjectGroupType: {type: "Boolean"}
					projectId: {type: "ID"}
					personIds: {type: "[ID]"}
					teamIds: {type: "[ID]"}
					roleIds: {type: "[ID]"}
					labelIds: {type: "[ID]"}
					skillIds: {type: "[ID]"}
				) {
					insightComponentsData(shareKey: $shareKey) {
						peopleListProjects(
							componentId: $componentId
							projectId: $projectId
							isProjectGroupType: $isProjectGroupType
							personIds: $personIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
							skillIds: $skillIds
						) {
							currency
							useHours
							todoTasksCountTotal
							progressTasksCountTotal
							doneTasksCountTotal
							tasksCountTotal
							remainingTotal
							reportedTotal
							spendTotal
							people {
								id
								name
								profilePictureId
								profilePictureDefaultId
								defaultRole
								projectRole
								email
								labels {
									id
									name
									color
								}
								skills {
									id
									name
								}
								profiles(first: 10000) {
									edges {
										node {
											id
											name
										}
									}
								}
								tasksCount
								todoTasksCount
								progressTasksCount
								doneTasksCount
								reportedMinutes
								remaining
								spend
							}
						}
					}
				}
			`,
		},
		graphql`
			query peopleListProjectsComponentRefetchQuery(
				$shareKey: String
				$componentId: ID
				$isProjectGroupType: Boolean
				$personIds: [ID]
				$teamIds: [ID]
				$roleIds: [ID]
				$labelIds: [ID]
				$skillIds: [ID]
			) {
				viewer {
					...peopleListProjectsComponent_viewer
						@arguments(
							shareKey: $shareKey
							componentId: $componentId
							isProjectGroupType: $isProjectGroupType
							personIds: $personIds
							teamIds: $teamIds
							roleIds: $roleIds
							labelIds: $labelIds
							skillIds: $skillIds
						)
				}
			}
		`
	)
);
