import {GROUP_SECTION_WIDTH} from './canvas_timeline_util';

class InteractionManager {
	constructor() {
		this.cursorStyleGroupAreaArray = [];
		this.cursorStyleMainAreaArray = [];
		this.itemDataArray = [];
		this.groupDataArray = [];
	}

	clearGroupSection() {
		this.cursorStyleGroupAreaArray = [];
		this.groupDataArray = [];
	}
	clearMainSection() {
		this.cursorStyleMainAreaArray = [];
		this.itemDataArray = [];
	}

	getDataAtPosition(x, y) {
		return {
			cursorData: (x > GROUP_SECTION_WIDTH ? this.cursorStyleMainAreaArray : this.cursorStyleGroupAreaArray).find(
				area => {
					return x >= area.x && x < area.x + area.width && y >= area.y && y < area.y + area.height;
				}
			),
			itemData:
				x > GROUP_SECTION_WIDTH &&
				this.itemDataArray.find(itemData => {
					const horizontalExtension = (itemData.item && itemData.item.horizontalExtension) || 0;
					return (
						x >= itemData.x &&
						x < itemData.x + itemData.width + horizontalExtension &&
						y >= itemData.y &&
						y < itemData.y + itemData.height
					);
				}),
			groupData: this.groupDataArray.find(groupData => {
				return y >= groupData.y && y < groupData.y + groupData.height;
			}),
		};
	}

	addItem(item, x, y, width, height) {
		this.itemDataArray.push({item, x, y, width, height});
	}

	addGroup(group, y, height) {
		this.groupDataArray.push({group, y, height});
	}

	addCursorStyleArea(x, y, width, height, cursorStyle, isGroupSection = false) {
		(isGroupSection ? this.cursorStyleGroupAreaArray : this.cursorStyleMainAreaArray).push({
			x,
			y,
			width,
			height,
			cursorStyle,
		});
	}
}

export const interactionManager = new InteractionManager();
