import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {FormattedMessage, injectIntl} from 'react-intl';
import ComponentHeader from './component_header';
import Util from '../../forecast-app/shared/util/util';
import {INSIGHTS_NEW_BUDGET_COLUMNS} from '../../constants';
import ForecastQueryRenderer from '../../ForecastQueryRenderer';
import * as tracking from '../../tracking';
import {Link} from 'react-router-dom';
import {trackEvent} from '../../tracking/amplitude/TrackingV2';

class ComponentRelayRenderer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			exportData: [],
			exportColumns: [],
			dateSpan: null,
			tasksFlowCrit: null,
			budgetGroupByCrit: null,
			budgetDisplayCrit: null,
			groupBy: null,
			labelFilterValue: [],
			skillFilterValue: [],
			projectStatusFilterValue: [],
			clientFilterValue: [],
			project_statusFilterValue: [],
			project_status_backendFilterValue: [],
			projectFilterValue: [],
			roleFilterValue: [],
			teamFilterValue: [],
			personFilterValue: [],
			departmentFilterValue: [],
			sprintFilterValue: [],
			billableFilterValue: [],
			approvedFilterValue: [],
			projectStageFilterValue: [],
			rateCardFilterValue: [],
			contactPersonFilterValue: [],
			selectValue: this.props.selectDefaultValue || null,
			updatingFilters: false,
			selectedSprint: null,
			selectedSprints: this.props.componentName === 'sprintVelocity' ? this.getSelectedSprints(props) : [],
			selectedYear: this.props.year || new Date().getFullYear(),
			weekPickerSelectedDate: null,
		};
		this.updatingFilters = false;
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (
			(nextProps.projectId !== this.props.projectId || nextProps.projectGroupId !== this.props.projectGroupId) &&
			nextProps.componentName === 'sprintVelocity'
		) {
			this.setState({selectedSprints: []});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const willUpdate =
			this.props.projectId !== nextProps.projectId ||
			this.props.projectGroupId !== nextProps.projectGroupId ||
			this.props.personId !== nextProps.personId ||
			this.props.clientId !== nextProps.clientId ||
			this.props.editMode !== nextProps.editMode ||
			this.state.dateSpan !== nextState.dateSpan ||
			this.state.tasksFlowCrit !== nextState.tasksFlowCrit ||
			this.state.budgetGroupByCrit !== nextState.budgetGroupByCrit ||
			this.state.budgetDisplayCrit !== nextState.budgetDisplayCrit ||
			this.state.groupBy !== nextState.groupBy ||
			this.state.labelFilterValue.length !== nextState.labelFilterValue.length ||
			this.state.skillFilterValue.length !== nextState.skillFilterValue.length ||
			this.state.projectStatusFilterValue.length !== nextState.projectStatusFilterValue.length ||
			this.state.clientFilterValue.length !== nextState.clientFilterValue.length ||
			this.state.project_statusFilterValue.length !== nextState.project_statusFilterValue.length ||
			this.state.project_status_backendFilterValue.length !== nextState.project_status_backendFilterValue.length ||
			this.state.projectFilterValue.length !== nextState.projectFilterValue.length ||
			this.state.personFilterValue.length !== nextState.personFilterValue.length ||
			this.state.departmentFilterValue.length !== nextState.departmentFilterValue.length ||
			this.state.roleFilterValue.length !== nextState.roleFilterValue.length ||
			this.state.teamFilterValue.length !== nextState.teamFilterValue.length ||
			this.state.sprintFilterValue.length !== nextState.sprintFilterValue.length ||
			this.state.billableFilterValue.length !== nextState.billableFilterValue.length ||
			this.state.approvedFilterValue.length !== nextState.approvedFilterValue.length ||
			this.state.projectStageFilterValue.length !== nextState.projectStageFilterValue.length ||
			this.state.rateCardFilterValue.length !== nextState.rateCardFilterValue.length ||
			this.state.contactPersonFilterValue.length !== nextState.contactPersonFilterValue.length ||
			this.state.selectValue !== nextState.selectValue ||
			this.state.selectedSprint !== nextState.selectedSprint ||
			(!this.props.scrollElement && nextProps.scrollElement) ||
			(!this.props.scrollElementFullScreen && nextProps.scrollElementFullScreen) ||
			this.state.selectedSprints.length !== nextState.selectedSprints.length ||
			(this.state.selectedSprints.length !== 0 &&
				nextState.selectedSprints.length !== 0 &&
				this.state.selectedSprints[0].value !== nextState.selectedSprints[0].value) ||
			this.state.selectedYear !== nextState.selectedYear ||
			(this.state.weekPickerSelectedDate === null && nextState.weekPickerSelectedDate !== null) ||
			(this.state.weekPickerSelectedDate !== null && !nextState.weekPickerSelectedDate === null) ||
			(this.state.weekPickerSelectedDate !== null &&
				nextState.weekPickerSelectedDate !== null &&
				(this.state.weekPickerSelectedDate.isBefore(nextState.weekPickerSelectedDate) ||
					this.state.weekPickerSelectedDate.isAfter(nextState.weekPickerSelectedDate)));
		return willUpdate;
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// All filters which are not filtered on the backend should be in this list.
		const filtersUpdate =
			this.state.clientFilterValue.length !== nextState.clientFilterValue.length ||
			this.state.project_statusFilterValue.length !== nextState.project_statusFilterValue.length ||
			this.state.labelFilterValue.length !== nextState.labelFilterValue.length ||
			this.state.skillFilterValue.length !== nextState.skillFilterValue.length ||
			this.state.projectFilterValue.length !== nextState.projectFilterValue.length ||
			this.state.rateCardFilterValue.length !== nextState.rateCardFilterValue.length ||
			this.state.contactPersonFilterValue.length !== nextState.contactPersonFilterValue.length ||
			this.state.projectStatusFilterValue.length !== nextState.projectStatusFilterValue.length ||
			this.state.personFilterValue.length !== nextState.personFilterValue.length ||
			this.state.roleFilterValue.length !== nextState.roleFilterValue.length ||
			this.state.teamFilterValue.length !== nextState.teamFilterValue.length ||
			this.state.sprintFilterValue.length !== nextState.sprintFilterValue.length ||
			this.state.billableFilterValue.length !== nextState.billableFilterValue.length ||
			this.state.approvedFilterValue.length !== nextState.approvedFilterValue.length ||
			this.state.projectStageFilterValue.length !== nextState.projectStageFilterValue.length;
		this.updatingFilters = filtersUpdate;
	}

	getSelectedSprints(props) {
		// for sprint velocity component set selectedSprints to 5 latest completed sprints. It is the only component using selectedSprints
		let sprints;
		const today = moment();

		if (props.projectOptions.find(project => project.id === props.projectId)) {
			sprints = props.projectOptions
				.find(project => project.id === props.projectId)
				.sprints.edges.map(sprint => sprint.node);
		} else if (
			props.projectGroupId !== null &&
			props.projectGroupId !== undefined &&
			props.projectGroupOptions.find(group => group.id === props.projectGroupId)
		) {
			sprints = props.projectGroupOptions
				.find(group => group.id === props.projectGroupId)
				.projects.edges[0].node.sprints.edges.map(sprint => sprint.node);
		} else if (props.sprintOptions) {
			//shared insight options
			sprints = props.sprintOptions;
		} else {
			return [];
		}

		let sprintsNumber = 0;
		sprints = [...sprints]
			.sort((a, b) => {
				const a_sprint_end = Util.CreateNonUtcMomentDate(a.endYear, a.endMonth, a.endDay);
				const b_sprint_end = Util.CreateNonUtcMomentDate(b.endYear, b.endMonth, b.endDay);
				if (b_sprint_end.isBefore(a_sprint_end)) {
					return -1;
				} else if (b_sprint_end.isAfter(a_sprint_end)) {
					return 1;
				} else {
					return 0;
				}
			})
			.filter(sprint => {
				const sprintsEndDate = Util.CreateNonUtcMomentDate(sprint.endYear, sprint.endMonth, sprint.endDay);
				const isValid =
					sprintsEndDate.isBefore(today) &&
					(props.projectGroupId === null || props.projectGroupId === undefined || sprint.isProjectGroupSprint);
				if (isValid) {
					sprintsNumber++;
				}
				return isValid && sprintsNumber < 6;
			});

		return sprints.map(sprint => ({
			value: props.projectGroupId ? sprint.projectGroupSprintId : sprint.id,
			label: sprint.name,
		}));
	}

	handleDateSpanChange(selected) {
		this.setState({dateSpan: selected});
	}

	handleTasksFlowCritChange(selected) {
		this.setState({tasksFlowCrit: selected});
	}

	handleBudgetGroupByCritChange(selected) {
		this.setState({budgetGroupByCrit: selected});
	}

	handleBudgetDisplayCritChange(selected) {
		this.setState({budgetDisplayCrit: selected});
	}

	handleBackendGroupByChange(selected) {
		this.setState({groupBy: selected});
	}

	handleFilterChange(filterType, value) {
		this.setState({[filterType + 'FilterValue']: value});
	}
	handleWeekPickerChange(value) {
		this.setState({weekPickerSelectedDate: value});
	}

	onSelectSprintChange(selected) {
		this.setState({selectedSprint: selected.value});
	}

	onSelectSprintsChange(selected) {
		this.setState({selectedSprints: selected});
	}

	onYearChange(year) {
		this.setState({selectedYear: year.value});
	}

	onSelectChange(selected) {
		this.setState({selectValue: selected ? selected.value : null});
	}

	clearFilters() {
		this.setState({
			labelFilterValue: [],
			skillFilterValue: [],
			projectStatusFilterValue: [],
			clientFilterValue: [],
			project_statusFilterValue: [],
			project_status_backendFilterValue: [],
			projectFilterValue: [],
			personFilterValue: [],
			departmentFilterValue: [],
			roleFilterValue: [],
			teamFilterValue: [],
			sprintFilterValue: [],
			billableFilterValue: [],
			approvedFilterValue: [],
			projectStageFilterValue: [],
			rateCardFilterValue: [],
			contactPersonFilterValue: [],
		});
	}

	setExportData(exportData) {
		this.setState({exportData});
	}

	setExportColumns(exportColumns) {
		this.setState({exportColumns});
	}

	exportTable(type) {
		const exportData = this.state.exportData.map(row => {
			return this.state.exportColumns.map(col => {
				if (row[col] == null) {
					return '';
				} else if (Array.isArray(row[col])) {
					return row[col].toString();
				} else {
					// Remove newlines from data if string
					if (typeof row[col] === 'string') {
						return row[col].replace('\n', ' ');
					}
					return row[col];
				}
			});
		});

		const columns = this.state.exportColumns;
		const colNames = columns.map(col => {
			const colTranslationId = col.endsWith('_raw') ? col.substring(0, col.length - 4) : col;
			const columnName = this.props.intl.formatMessage({
				id: `insights.component.list.column.${colTranslationId}${
					INSIGHTS_NEW_BUDGET_COLUMNS.includes(colTranslationId) ? '.newBudget' : ''
				}`,
			});
			return columnName.replace('({suffix})', '(money)');
		});
		if (type === 'csv') {
			Util.exportTable(columns, exportData, this.props.componentName, colNames);
		} else if (type === 'xlsx') {
			Util.exportTableXlsx(columns, exportData, this.props.componentName, colNames);
		}
	}

	refresh() {
		if (this.retry) {
			this.retry();
		}
	}

	render() {
		const groupBy = this.state.groupBy ? this.state.groupBy.value : this.props.initialGroupBy || null;
		const projectIds = this.state.projectFilterValue.map(el => el.value);
		const personIds = this.state.personFilterValue.map(el => el.value);
		const departmentIds = this.state.departmentFilterValue.map(el => el.value);
		const roleIds = this.state.roleFilterValue.map(el => el.value);
		const teamIds = this.state.teamFilterValue.map(el => el.value);
		const labelIds = this.state.labelFilterValue.map(el => el.value);
		const skillIds = this.state.skillFilterValue.map(el => el.value);
		const sprintIds = this.state.sprintFilterValue.map(el => el.value);
		const projectStatusesBackend = this.state.project_status_backendFilterValue.map(el => el.value);
		const billableFilterValue =
			this.state.billableFilterValue.length === 1 ? this.state.billableFilterValue[0].value : null;
		const approvedFilterValue =
			this.state.approvedFilterValue.length === 1 ? this.state.approvedFilterValue[0].value : null;
		const projectStageFilterValue =
			this.state.projectStageFilterValue.length === 1 ? this.state.projectStageFilterValue[0].value : null;

		let dateCriteria = this.props.defaultDateCriteria || null;
		if (this.state.dateSpan !== null) {
			dateCriteria = this.state.dateSpan.value;
			if (this.state.dateSpan.key === 'custom-date-range') {
				if (this.state.dateSpan.value && this.state.dateSpan.value.start && this.state.dateSpan.value.end) {
					dateCriteria =
						this.state.dateSpan.value.start.format('DD/MM/YYYY') +
						'_' +
						this.state.dateSpan.value.end.format('DD/MM/YYYY');
				}
			}
		}

		tracking.trackEvent('Insight rendered (With filter count)', {
			insight: this.props.componentName,
			groupBy: groupBy,
			projectsCount: projectIds.length,
			personsCount: personIds.length,
			departmentsCount: departmentIds.length,
			rolesCount: roleIds.length,
			teamsCount: teamIds.length,
			labelsCount: labelIds.length,
			skillsCount: skillIds.length,
			sprintsCount: sprintIds.length,
			projectStatusesCount: projectStatusesBackend.length,
			dateCriteria: dateCriteria,
			billable: billableFilterValue,
			approved: approvedFilterValue,
			projectStage: projectStageFilterValue,
		});

		trackEvent('Component Insight', 'Rendered', {
			insight: this.props.componentName,
			groupBy: groupBy,
			projectsCount: projectIds.length,
			personsCount: personIds.length,
			departmentsCount: departmentIds.length,
			rolesCount: roleIds.length,
			teamsCount: teamIds.length,
			labelsCount: labelIds.length,
			skillsCount: skillIds.length,
			sprintsCount: sprintIds.length,
			projectStatusesCount: projectStatusesBackend.length,
			dateCriteria: dateCriteria,
			billable: billableFilterValue,
			approved: approvedFilterValue,
			projectStage: projectStageFilterValue,
		});

		const variables = {
			shareKey: this.props.shareKey || '',
			projectId: this.props.projectId || this.props.projectGroupId || '',
			isProjectGroupType:
				(this.props.projectId === null || this.props.projectId === undefined) &&
				this.props.projectGroupId !== null &&
				this.props.projectGroupId !== undefined,
			personId: this.props.personId || '',
			clientId: this.props.clientId || '',
			componentId: this.props.id || '',
			dateCriteria,
			weekPickerSelectedDate: this.state.weekPickerSelectedDate,
			groupBy,
			projectIds,
			personIds,
			departmentIds,
			roleIds,
			teamIds,
			labelIds,
			skillIds,
			sprintIds,
			projectStatusesBackend,
			billableFilterValue,
			approvedFilterValue,
			projectStageFilterValue,
			year: this.state.selectedYear,
			selectValue: this.state.selectValue,
			sprintId: this.state.selectedSprint,
		};
		return (
			<ForecastQueryRenderer
				key={this.props.componentQueryName + '_component'}
				query={this.props.query}
				renderIfNotLoggedIn={true}
				variables={variables}
				render={(relayProps, retry) => {
					this.retry = retry;
					const componentProps = {
						dateSpan: this.state.dateSpan,
						dateCriteria,
						tasksFlowCrit: this.state.tasksFlowCrit,
						groupBy: groupBy,
						budgetDisplayCrit: this.state.budgetDisplayCrit,
						budgetGroupByCrit: this.state.budgetGroupByCrit,
						labelFilterValue: this.state.labelFilterValue,
						skillFilterValue: this.state.skillFilterValue,
						projectStatusFilterValue: this.state.projectStatusFilterValue,
						clientFilterValue: this.state.clientFilterValue,
						project_statusFilterValue: this.state.project_statusFilterValue,
						project_status_backendFilterValue: this.state.project_status_backendFilterValue,
						personFilterValue: this.state.personFilterValue,
						departmentFilterValue: this.state.departmentFilterValue,
						sprintFilterValue: this.state.sprintFilterValue,
						billableFilterValue:
							this.state.billableFilterValue.length === 1 ? this.state.billableFilterValue[0].value : null,
						approvedFilterValue:
							this.state.approvedFilterValue.length === 1 ? this.state.approvedFilterValue[0].value : null,
						projectStageFilterValue:
							this.state.projectStageFilterValue.length === 1
								? this.state.projectStageFilterValue[0].value
								: null,
						rateCardFilterValue: this.state.rateCardFilterValue,
						contactPersonFilterValue: this.state.contactPersonFilterValue,
						roleFilterValue: this.state.roleFilterValue,
						teamFilterValue: this.state.teamFilterValue,
						projectFilterValue: this.state.projectFilterValue,
						setExportData: this.setExportData.bind(this),
						selectedSprint: this.state.selectedSprint,
						selectedSprints: this.state.selectedSprints,
						selectValue: this.state.selectValue,
						currency: this.props.currency,
						setExportColumns: this.setExportColumns.bind(this),
						weekPickerSelectedDate: this.state.weekPickerSelectedDate,
						componentRenderer: this.componentRenderer,
					};
					return (
						<div className="component">
							<ComponentHeader
								{...this.props}
								export={this.exportTable.bind(this)}
								handleDateSpanChange={this.handleDateSpanChange.bind(this)}
								handleTasksFlowCritChange={this.handleTasksFlowCritChange.bind(this)}
								dateSpan={this.state.dateSpan}
								tasksFlowCrit={this.state.tasksFlowCrit}
								handleBudgetDisplayCritChange={this.handleBudgetDisplayCritChange.bind(this)}
								budgetDisplayCrit={this.state.budgetDisplayCrit}
								handleBudgetGroupByCritChange={this.handleBudgetGroupByCritChange.bind(this)}
								budgetGroupByCrit={this.state.budgetGroupByCrit}
								labelFilterValue={this.state.labelFilterValue}
								skillFilterValue={this.state.skillFilterValue}
								projectStatusFilterValue={this.state.projectStatusFilterValue}
								clientFilterValue={this.state.clientFilterValue}
								project_statusFilterValue={this.state.project_statusFilterValue}
								project_status_backendFilterValue={this.state.project_status_backendFilterValue}
								projectFilterValue={this.state.projectFilterValue}
								personFilterValue={this.state.personFilterValue}
								departmentFilterValue={this.state.departmentFilterValue}
								roleFilterValue={this.state.roleFilterValue}
								teamFilterValue={this.state.teamFilterValue}
								sprintFilterValue={this.state.sprintFilterValue}
								billableFilterValue={this.state.billableFilterValue}
								approvedFilterValue={this.state.approvedFilterValue}
								projectStageFilterValue={this.state.projectStageFilterValue}
								rateCardFilterValue={this.state.rateCardFilterValue}
								contactPersonFilterValue={this.state.contactPersonFilterValue}
								handleFilterChange={this.handleFilterChange.bind(this)}
								clearFilters={this.clearFilters.bind(this)}
								onSelectChange={this.onSelectChange.bind(this)}
								selectValue={this.state.selectValue}
								selectedSprint={this.state.selectedSprint}
								selectedSprints={this.state.selectedSprints}
								onSelectSprintChange={
									this.props.componentName === 'sprintBurndownV2'
										? this.onSelectSprintChange.bind(this)
										: null
								}
								onSelectSprintsChange={
									this.props.componentName === 'sprintVelocity' ? this.onSelectSprintsChange.bind(this) : null
								}
								onlyDoneSprints={this.props.componentName === 'sprintVelocity'}
								selectedProjectId={this.props.projectId}
								groupBy={groupBy}
								handleBackendGroupByChange={this.handleBackendGroupByChange.bind(this)}
								isProjectGroupType={
									(this.props.projectId === null || this.props.projectId === undefined) &&
									this.props.projectGroupId !== null &&
									this.props.projectGroupId !== undefined
								}
								selectedYear={this.state.selectedYear}
								onYearChange={
									this.props.componentName === 'utilizationMonthly' ? this.onYearChange.bind(this) : null
								}
								key={this.props.id}
								weekPickerSelectedDate={this.state.weekPickerSelectedDate}
								handleWeekPickerChange={this.handleWeekPickerChange.bind(this)}
							/>
							{this.props.componentName === 'utilizationMonthly' ||
							this.props.componentName === 'utilizationList' ||
							this.props.componentName === 'utilizationBarChart' ? (
								<div
									style={{
										border: '1px solid #e6e6e6',
										padding: 12,
										fontStyle: 'italic',
										backgroundColor: '#ffffdd',
										textAlign: 'center',
									}}
								>
									<FormattedMessage
										id="insights.old_utilization_reports_deprecated"
										values={{
											a: chunks => <Link to="new/step1">{chunks}</Link>,
										}}
									/>
								</div>
							) : (
								this.props.render(relayProps, componentProps)
							)}
						</div>
					);
				}}
			/>
		);
	}
}

ComponentRelayRenderer.propTypes = {
	id: PropTypes.string,
	projectId: PropTypes.string,
	projectGroupId: PropTypes.string,
	personId: PropTypes.string,
	render: PropTypes.func.isRequired,
	query: PropTypes.object.isRequired,
	editMode: PropTypes.bool,
	remove: PropTypes.func,
	canUseDatePicker: PropTypes.bool.isRequired,
	isTasksFlowComponent: PropTypes.bool,
};

export default injectIntl(ComponentRelayRenderer, {forwardRef: true});
