import React, { useEffect, useState } from 'react';
import { DateRangePicker, DropdownList, ButtonDropdownField, Icon, FlexRow } from '@forecast-it/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { getPreviousMonthRange, getMonthRange, dateObjectToIsoDate, getNextMonthRange, } from '../../forecast-app/shared/util/DateUtil';
const PredefinedDateRangePicker = ({ startDate, endDate, onChange }) => {
    const { formatMessage } = useIntl();
    const date = new Date();
    const preselectedRanges = [
        { name: formatMessage({ id: 'common.previous_month' }), range: getPreviousMonthRange(date) },
        { name: formatMessage({ id: 'common.current_month' }), range: getMonthRange(date) },
        { name: formatMessage({ id: 'date_span_picker.next_month' }), range: getNextMonthRange(date) },
        { name: formatMessage({ id: 'common.all_time' }), range: null },
    ];
    const [rangeIndex, setRangeIndex] = useState(null);
    const setDateRange = (index) => {
        setRangeIndex(index);
        const dateRange = preselectedRanges[index].range;
        onChange({ startDate: dateObjectToIsoDate(dateRange === null || dateRange === void 0 ? void 0 : dateRange.startDate), endDate: dateObjectToIsoDate(dateRange === null || dateRange === void 0 ? void 0 : dateRange.endDate) });
    };
    const setCustomDate = (range) => {
        setRangeIndex(null);
        onChange(range);
    };
    useEffect(() => {
        if (startDate && endDate) {
            const preselectedRangeIndex = preselectedRanges.findIndex(preselectedRange => {
                var _a, _b;
                return dateObjectToIsoDate((_a = preselectedRange.range) === null || _a === void 0 ? void 0 : _a.startDate) === startDate &&
                    dateObjectToIsoDate((_b = preselectedRange.range) === null || _b === void 0 ? void 0 : _b.endDate) === endDate;
            });
            if (preselectedRangeIndex !== -1) {
                setDateRange(preselectedRangeIndex);
            }
        }
        else {
            setDateRange(3);
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(DropdownList, null,
            React.createElement(DropdownList.Trigger, null,
                React.createElement(ButtonDropdownField, { width: "180px", "data-cy": 'predefined-date-range-picker' }, rangeIndex === null ? (React.createElement(FormattedMessage, { id: 'common.custom_range' })) : (preselectedRanges[rangeIndex].name))),
            React.createElement(DropdownList.Content, { offset: 10, align: 'start', width: "199px" }, preselectedRanges.map((range, index) => (React.createElement(DropdownList.Item, { key: index, onClick: () => setDateRange(index), "data-cy": 'predefined-date-range-picker-' + index },
                React.createElement(FlexRow, { justifyContent: 'space-between' },
                    React.createElement("div", null, range.name),
                    index === rangeIndex && React.createElement(Icon, { icon: "tick" }))))))),
        React.createElement(DateRangePicker, { startDate: startDate, endDate: endDate, onChange: setCustomDate, "aria-label": "Select date range", "data-cy": 'date-range-picker' })));
};
export default PredefinedDateRangePicker;
