import React, {Component} from 'react';
import 'draft-js-emoji-plugin/lib/plugin.css';
import {ContentState, convertFromHTML, convertFromRaw, convertToRaw, EditorState, Modifier, RichUtils} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin, {defaultSuggestionsFilter} from 'draft-js-mention-plugin';
import createLinkifyPlugin from './link_plugin';
import RichTextUtil from '../rich_text_util';
import createEmojiPlugin from 'draft-js-emoji-plugin';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import Button from '../../forecast-app/shared/components/buttons/button/button';
import {NativeTypes} from 'react-dnd-html5-backend';
import {DropTarget} from 'react-dnd';
import DraftJSUploadButtonWithGdrive from '../../forecast-app/shared/components/task-modal/draft_js_upload_button_with_gdrive';
import {Scrollbars} from 'react-custom-scrollbars';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import {Scrollbar} from '@forecast-it/design-system';

var INLINE_STYLES = [
	{label: 'bold', style: 'BOLD'},
	{label: 'italic', style: 'ITALIC'},
	{label: 'underline', style: 'UNDERLINE'},
	{label: 'strikethrough', style: 'STRIKETHROUGH'},
];

const BLOCK_TYPES = [
	{label: 'ul', style: 'unordered-list-item'},
	{label: 'ol', style: 'ordered-list-item'},
];

const fileTarget = {
	drop(props, monitor, component) {
		if (props.fileHandler) {
			let fileItems = monitor.getItem().files;
			const files = [];
			for (let i = props.single ? fileItems.length - 1 : 0; i < fileItems.length; i++) {
				if (fileItems[i].type) {
					//folders don't have a type
					files.push(fileItems[i]);
				}
			}
			props.fileHandler(files);
			component.setState({draggingFile: false});
		}
	},
};

class RichTextField extends Component {
	constructor(props) {
		super(props);

		this.mentionPlugin = createMentionPlugin();
		this.labelPlugin = createMentionPlugin({
			mentionPrefix: '#',
			mentionTrigger: '#',
			entityMutability: 'IMMUTABLE',
		});
		this.linkifyPlugin = createLinkifyPlugin(props.readOnly);
		this.emojiPlugin = createEmojiPlugin({
			useNativeArt: true,
		});
		this.plugins = [this.labelPlugin, this.mentionPlugin, this.linkifyPlugin, this.emojiPlugin];

		let content = null;
		if (props.text !== undefined && props.text !== null && props.text !== '') {
			try {
				if (props.text.startsWith('<div>') || props.text.startsWith('<p>')) {
					const blocksFromHTML = convertFromHTML(props.text);
					content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
				} else {
					const json = JSON.parse(props.text);
					if (json.blocks.length > 0) {
						content = convertFromRaw(json);
					} else {
						content = ContentState.createFromText('');
					}
				}
			} catch (err) {
				content = ContentState.createFromText(props.text);
			}
		}

		this.state = {
			editorState:
				props.text === undefined || props.text === null || props.text === ''
					? this.createEmptyWithFocus()
					: EditorState.createWithContent(content),
			suggestions: this.props.mentions !== null ? this.props.mentions : [],
			labels: this.props.mentionsLabels && !this.props.readOnly ? this.props.mentionsLabels : [],
			isEmojiModalOpen: false,
			hasFocus: false,
			isShowingNewMentionSuggestion: false,
			draggingFile: false,
			uuid: uuid.v4(),
			readOnly: this.props.readOnly,
			showUploadOptions: false,
		};

		this.listener = this.listener.bind(this);
		this.onChange = editorState => {
			this.setState({editorState}, () => {
				if (this.props.onChange) this.props.onChange(convertToRaw(this.state.editorState.getCurrentContent()));
			});
		};
		this.onChange = this.onChange.bind(this);
		this.handleKeyCommand = command => this._handleKeyCommand(command);

		const applyPersonMentionFilter = (value, mentions) => {
			return defaultSuggestionsFilter(value, mentions).sort((v1, v2) => {
				const lowerCaseValue = value.toLowerCase();
				const v1StartsWith = v1.name.toLowerCase().startsWith(lowerCaseValue);
				const v2StartsWith = v2.name.toLowerCase().startsWith(lowerCaseValue);

				return v1StartsWith === v2StartsWith ? 0 : !v2StartsWith ? -1 : 1;
			});
		};

		this.onSearchChange = ({value}) => {
			this.setState({
				suggestions: this.props.mentions !== null ? applyPersonMentionFilter(value, this.props.mentions) : [],
			});
		};
		this.onLabelSearchChange = ({value}) => {
			this.setState({
				labels: this.props.mentionsLabels
					? defaultSuggestionsFilter(
							value,
							this.props.mentionsLabels.filter(labelSuggestion => labelSuggestion.name)
					  )
					: [],
			});
		};
		this.toggleInlineStyle = style => this._toggleInlineStyle(style);
		this.toggleBlockType = type => this._toggleBlockType(type);
	}

	componentDidMount() {
		document.body.addEventListener('mousedown', this.listener);

		if (this.props.fileHandler) {
			document.body.addEventListener('dragover', this.onDragEnterHandler.bind(this));
			document.body.addEventListener('dragleave', this.onDragLeaveHandler.bind(this));
			this._ismounted = true;
		}

		//Can't spread or for/of on IE/Edge
		const emojiButtonList = document.getElementsByClassName('draftJsEmojiPlugin__emojiSelectButton__3sPol');
		for (let i = 0; i < emojiButtonList.length; i++) {
			emojiButtonList[i].tabIndex = -1;
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.ignoreComponentWillReceiveProps || this.state.hasFocus) return;
		if (nextProps.text !== this.props.text) {
			let content = null;
			try {
				if (nextProps.text.startsWith('<div>') || nextProps.text.startsWith('<p>')) {
					const blocksFromHTML = convertFromHTML(nextProps.text);
					content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
				} else {
					content = convertFromRaw(JSON.parse(nextProps.text));
				}
			} catch (err) {
				content = ContentState.createFromText(nextProps.text ? nextProps.text : '');
			}
			const editorState = EditorState.createWithContent(content);
			this.setState({editorState: editorState});
		}

		if (
			this.props.readOnly !== nextProps.readOnly &&
			nextProps.readOnly === false &&
			nextProps.mentionsLabels &&
			nextProps.mentionsLabels.length
		) {
			this.setState({labels: nextProps.mentionsLabels, readOnly: nextProps.readOnly});
		}

		if (this.props.readOnly !== nextProps.readOnly) {
			this.setState({readOnly: nextProps.readOnly});
		}

		// This breaks up and down arrow key functionality until a # has been input
		//Remove label from dropdown list if user starts typing another hashtag before the previous mutation is finished
		/*this.setState({
			labels: nextProps.mentionsLabels && !nextProps.readOnly ? this.filterLabels(this.state.labelsSearchValue, nextProps.mentionsLabels) : []
		});*/
	}

	componentWillUnmount() {
		document.body.removeEventListener('mousedown', this.listener);

		if (this.props.fileHandler) {
			document.body.removeEventListener('dragover', this.onDragEnterHandler.bind(this));
			document.body.removeEventListener('dragleave', this.onDragLeaveHandler.bind(this));
			this._ismounted = false;
		}
	}

	//region Limit Characters code
	_getLengthOfSelectedText = () => {
		const currentSelection = this.state.editorState.getSelection();
		const isCollapsed = currentSelection.isCollapsed();

		let length = 0;

		if (!isCollapsed) {
			const currentContent = this.state.editorState.getCurrentContent();
			const startKey = currentSelection.getStartKey();
			const endKey = currentSelection.getEndKey();
			const startBlock = currentContent.getBlockForKey(startKey);
			const isStartAndEndBlockAreTheSame = startKey === endKey;
			const startBlockTextLength = startBlock.getLength();
			const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
			const endSelectedTextLength = currentSelection.getEndOffset();
			const keyAfterEnd = currentContent.getKeyAfter(endKey);
			if (isStartAndEndBlockAreTheSame) {
				length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
			} else {
				let currentKey = startKey;

				while (currentKey && currentKey !== keyAfterEnd) {
					if (currentKey === startKey) {
						length += startSelectedTextLength + 1;
					} else if (currentKey === endKey) {
						length += endSelectedTextLength;
					} else {
						length += currentContent.getBlockForKey(currentKey).getLength() + 1;
					}

					currentKey = currentContent.getKeyAfter(currentKey);
				}
			}
		}

		return length;
	};

	_handleBeforeInput = (chars, editorState) => {
		if (this.props.characterLimit <= 0) {
			return;
		}
		const currentContent = this.state.editorState.getCurrentContent();
		const selectionState = this.state.editorState.getSelection();
		const currentContentLength = currentContent.getPlainText('').length;
		const selectedTextLength = this._getLengthOfSelectedText();

		if (currentContentLength - selectedTextLength > this.props.characterLimit - 1) {
			return 'handled';
		}

		// Potential solution to draftJS crashing taken from https://github.com/facebook/draft-js/issues/1320#issuecomment-476509968
		const currentStyle = editorState.getCurrentInlineStyle();
		this.onChange(EditorState.push(editorState, Modifier.replaceText(currentContent, selectionState, chars, currentStyle)));

		return 'handled';
	};

	_handlePastedText = pastedText => {
		if (this.props.characterLimit <= 0) {
			return;
		}
		const currentContent = this.state.editorState.getCurrentContent();
		const currentContentLength = currentContent.getPlainText('').length;
		const selectedTextLength = this._getLengthOfSelectedText();

		if (currentContentLength + pastedText.length - selectedTextLength > this.props.characterLimit) {
			return 'handled';
		}
	};
	//endregion

	filterLabels(searchValue, suggestions) {
		const value = searchValue.toLowerCase();
		if (suggestions) {
			let filteredSuggestions = suggestions.filter(function (suggestion) {
				return !value || (suggestion.name && suggestion.name.toLowerCase().indexOf(value) > -1);
			});
			const size = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
			if (size > 0 || searchValue.trim() === '') {
				this.setState({isShowingNewMentionSuggestion: false});
				return filteredSuggestions.slice(0, 5);
			} else {
				this.setState({isShowingNewMentionSuggestion: true});
				return [{id: 'richtext-new-label', name: searchValue}];
			}
		}
		return [];
	}

	createEmptyWithFocus() {
		const empty = EditorState.createEmpty();
		return empty;
		//return EditorState.moveFocusToEnd(empty);
	}

	_toggleInlineStyle(inlineStyle) {
		this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
	}

	_toggleBlockType(blockType) {
		this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
	}

	_handleKeyCommand(command, t, e) {
		if (command === 'bold' || command === 'underline' || command === 'italic') {
			this._toggleInlineStyle(command.toUpperCase());
		}
		const {editorState} = this.state;
		RichUtils.handleKeyCommand(editorState, command);
	}

	listener(event) {
		if (
			(event.target &&
				typeof event.target.className === 'string' &&
				(event.target.className === 'draftJsEmojiPlugin__emojiSelectButton__3sPol' ||
					event.target.className.includes('emojiSelectPopoverEntry'))) ||
			(event.target && typeof event.target.className === 'object')
		) {
			event.preventDefault();
		}
	}

	onDragEnterHandler(e) {
		e.preventDefault();
		if (!this.container) return null;

		const isHoveringOnDescription = this.container.contains(e.target);
		if (this._ismounted && !this.state.draggingFile && isHoveringOnDescription) this.setState({draggingFile: true});
	}

	onDragLeaveHandler(e) {
		e.preventDefault();
		if (!this.container) return null;

		const isHoveringOnDescription = this.container.contains(e.target);
		if (this._ismounted && this.state.draggingFile && !isHoveringOnDescription) this.setState({draggingFile: false});
	}

	clear() {
		const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
		this.setState({editorState});
	}

	setText(text) {
		let content;
		try {
			if (text.startsWith('<div>') || text.startsWith('<p>')) {
				const blocksFromHTML = convertFromHTML(text);
				content = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
			} else {
				content = convertFromRaw(JSON.parse(text));
			}
		} catch (err) {
			content = ContentState.createFromText(text);
		}
		const editorState = EditorState.push(this.state.editorState, content);
		this.setState({editorState});
	}

	handleEditorBlur(e) {
		const newTarget = e ? e.relatedTarget || e.explicitOriginalTarget || document.activeElement : null;

		if (
			this.props.preventBlurOnClasses &&
			newTarget.className.split(' ').some(className => this.props.preventBlurOnClasses.includes(className))
		)
			return;

		if (
			this.props.preventBlurTaskModal &&
			this.props.preventBlurTaskModal(newTarget, JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())))
		)
			return;

		if (this.props.handleTextChange)
			this.props.handleTextChange(JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())));

		this.handleBlur(e);

		if (this.props.handleFocusChange) {
			this.props.handleFocusChange(false);
		}
	}

	/* Specific check for safari */
	preventBlurSafari(e) {
		const userAgent = navigator.userAgent.toLowerCase();
		if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
			const newTarget = e ? e.relatedTarget || e.explicitOriginalTarget || document.activeElement : null;
			if (
				newTarget &&
				newTarget.children &&
				newTarget.children.length &&
				newTarget.children[0].id &&
				newTarget.children[0].id.includes(this.state.uuid)
			) {
				setTimeout(() => {
					this.setState({
						hasFocus: false,
					});
				}, 100);
				return true;
			}
		}
		return false;
	}

	handleBlur(e) {
		const newTarget = e ? e.relatedTarget || e.explicitOriginalTarget || document.activeElement : null; // IE11;
		if (newTarget && newTarget.id && newTarget.id.includes(this.state.uuid)) return;
		if (
			newTarget &&
			(newTarget.className.includes('upload-options-gdrive') ||
				newTarget.className.includes('upload-from-computer') ||
				newTarget.className.includes('file-upload'))
		)
			return;
		if (this.preventBlurSafari(e)) {
			return;
		}
		this.setState({
			hasFocus: false,
			showUploadOptions: false,
		});
		if (this.props.handleBlur) {
			this.props.handleBlur(e);
		}
	}

	onFileButtonClick() {
		if (this.props.gdriveEnabled) {
			this.setState({showUploadOptions: !this.state.showUploadOptions});
		} else if (this.props.onFileButtonClick) {
			this.props.onFileButtonClick();
		}
	}

	focusEditor() {
		if (this.richTextFieldEditor) {
			this.richTextFieldEditor.focus();
		}
	}

	handleFocusState(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (newTarget && newTarget.id && newTarget.id.includes('editor-button')) return;
		this.setState({
			hasFocus: true,
		});
		if (this.props.onFocus) {
			this.props.onFocus();
		}
		if (this.props.handleFocusChange) {
			this.props.handleFocusChange(true);
		}
	}

	openLink(href) {
		if (!this.state.readOnly) {
			window.open(href);
		}
	}

	uploadFile() {
		if (this.props.fileHandler) {
			let file = this._file.files.item(0);
			this.props.fileHandler(file);
		}
	}

	handleReturn(e) {
		//This need to return "handled" or "not-handled" depending on whether or not the draftjs should apply default behavior
		if (this.props.onEnter) {
			if (e.ctrlKey || e.shiftKey || e.altKey) return 'not-handled';
			if (this.suggestionsWrapper && this.suggestionsWrapper.children.length) {
				return 'not-handled';
			}
			this.props.onEnter(JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())));
			return 'handled';
		}
		return 'not-handled';
	}

	onTab(event) {
		this.onChange(RichUtils.onTab(event, this.state.editorState, 4));
	}

	render() {
		const {connectDropTarget} = this.props;
		const {editorState} = this.state;

		const {EmojiSelect} = this.emojiPlugin;
		const {MentionSuggestions} = this.mentionPlugin;
		const LabelSuggestions = this.labelPlugin.MentionSuggestions;
		const isListStyleOn =
			editorState.getCurrentContent().getBlockMap().first().getType() === 'ordered-list-item' ||
			editorState.getCurrentContent().getBlockMap().first().getType() === 'unordered-list-item';

		const entry = props => {
			const {mention, theme, ...parentProps} = props;
			return (
				<div {...parentProps}>
					<div className={theme.mentionSuggestionsEntryContainer + ' label-suggestion-new-entry'}>
						<div className={theme.mentionSuggestionsEntryContainerRight}>
							<div className={theme.mentionSuggestionsEntryText}>{mention.name}</div>
						</div>
					</div>
				</div>
			);
		};

		const getEditor = () => {
			return (
				<Editor
					readOnly={this.state.readOnly}
					onBlur={this.handleEditorBlur.bind(this)}
					onFocus={this.handleFocusState.bind(this)}
					onTab={this.onTab.bind(this)}
					editorState={this.state.editorState}
					placeholder={isListStyleOn || this.state.readOnly ? '' : this.props.placeholder}
					handleKeyCommand={this.handleKeyCommand}
					handleBeforeInput={this._handleBeforeInput.bind(this)}
					handlePastedText={this._handlePastedText.bind(this)}
					onChange={this.onChange}
					plugins={this.plugins}
					ref={richTextFieldEditor => (this.richTextFieldEditor = richTextFieldEditor)}
					id={this.state.uuid}
					handleReturn={this.handleReturn.bind(this)}
					blockRendererFn={RichTextUtil.getBlockRenderer()}
					blockStyleFn={RichTextUtil.getBlockStyleFn()}
					customStyleMap={RichTextUtil.getStyleMap()}
				/>
			);
		};

		return connectDropTarget(
			<div
				onClick={this.props.onClick}
				className={'rich-text-field-container' + (this.state.readOnly ? ' read-only' : '')}
				ref={ref => (this.container = ref)}
			>
				{this.props.textFieldLabel && this.props.textFieldLabel !== '' ? (
					<div className="rich-text-field-label-wrapper">
						<label className="rich-text-field-label" htmlFor={this.state.uuid}>
							{this.props.textFieldLabel}
						</label>
						{this.props.labelLink || null}
					</div>
				) : null}
				<div
					className={
						this.state.draggingFile
							? 'rich-text-field-input-container-file'
							: 'rich-text-field-input-container ' +
							  (this.props.fileHandler && !this.state.readOnly ? '' : 'file-upload-not-possible ') +
							  (editorState.getCurrentContent().getPlainText() === '' ? '' : 'rich-text-field-input-not-empty') +
							  (this.state.hasFocus || this.props.alwaysShowControls ? ' has-focus' : '')
					}
					onClick={this.focusEditor.bind(this)}
					ref={textFieldInputContainer => (this.textFieldInputContainer = textFieldInputContainer)}
					data-cy={this.props.cy}
				>
					<div
						data-cy={this.props.textCy}
						className={
							'rich-text-field-editor-wrapper' +
							(this.props.collapsed && !this.state.hasFocus ? ' collapsed' : '')
						}
						ref={richTextFieldEditorWrapper => (this.richTextFieldEditorWrapper = richTextFieldEditorWrapper)}
					>
						{this.props.maxHeight ? (
							<Scrollbar maxHeight={this.props.maxHeight} hasFocusableContent>
								{getEditor()}
							</Scrollbar>
						) : this.props.maxHeight ? (
							<Scrollbars autoHeight autoHeightMax={this.props.maxHeight}>
								{getEditor()}
							</Scrollbars>
						) : (
							getEditor()
						)}
					</div>
					<div
						className="rich-text-field-suggestion-wrapper"
						ref={suggestionsWrapper => (this.suggestionsWrapper = suggestionsWrapper)}
					>
						{this.state.suggestions ? (
							<MentionSuggestions onSearchChange={this.onSearchChange} suggestions={this.state.suggestions} />
						) : null}
						{this.state.labels ? (
							<LabelSuggestions
								onSearchChange={this.onLabelSearchChange}
								suggestions={this.state.labels}
								entryComponent={this.state.isShowingNewMentionSuggestion ? entry : null}
							/>
						) : null}
					</div>
					{this.state.readOnly || this.props.hideStylingOptions ? null : (
						<div
							className={
								'rich-text-field-editor-controls' +
								(this.props.collapsed && !this.state.hasFocus ? ' collapsed' : '')
							}
						>
							<div className="rich-text-field-styling">
								<BlockStyleControls editorState={editorState} onToggle={this.toggleBlockType} />
								<InlineStyleControls editorState={editorState} onToggle={this.toggleInlineStyle} />
								{this.props.fileHandler ? (
									<div className="rich-text-field-editor-control file-upload">
										<input
											id={'file-input-' + this.state.uuid}
											onBlur={this.handleBlur.bind(this)}
											type="file"
											onClick={e => {
												e.target.value = null;
											}}
											onChange={this.uploadFile.bind(this)}
											ref={input => (this._file = input)}
										/>
									</div>
								) : null}

								{this.props.showUploadFileIcon ? (
									<div
										className="rich-text-field-editor-control file-upload"
										onClick={this.onFileButtonClick.bind(this)}
										tabIndex="-1"
									/>
								) : null}

								{this.state.showUploadOptions ? (
									<DraftJSUploadButtonWithGdrive
										fileInput={this.props.fileInput}
										gdriveEnabled={true}
										taskId={this.props.taskId}
										personId={this.props.actualPersonId}
										coverFile={this.props.coverFile}
									/>
								) : null}

								{this.props.hideEmojiPicker ? null : (
									<div className="rich-text-field-emoji">
										<EmojiSelect />
									</div>
								)}
							</div>
							{this.props.editorButtons ? (
								<div className="rich-text-field-editor-buttons" tabIndex="0">
									{this.props.editorButtons.map((buttonSpecs, index) => (
										<Button
											id={'editor-button-' + index + '-' + this.state.uuid}
											key={index}
											onClick={buttonSpecs.onClick.bind(
												this,
												JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())),
												this.state.editorState.getCurrentContent().getPlainText()
											)}
											onBlur={this.handleBlur.bind(this)}
											buttonStyle={buttonSpecs.buttonStyle}
											colorTheme={buttonSpecs.colorTheme}
											text={buttonSpecs.label}
										/>
									))}
								</div>
							) : null}
						</div>
					)}
				</div>
			</div>
		);
	}
}

class RichTextStyleButton extends React.Component {
	constructor() {
		super();
		this.onToggle = e => {
			e.preventDefault();
			this.props.onToggle(this.props.style);
		};
	}

	render() {
		return <div className={'rich-text-field-editor-control ' + this.props.label} onMouseDown={this.onToggle} />;
	}
}

const InlineStyleControls = props => {
	var currentStyle = props.editorState.getCurrentInlineStyle();
	return (
		<div className="RichEditor-controls">
			{INLINE_STYLES.map(type => (
				<RichTextStyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={currentStyle.has(type.style) ? type.label + ' active' : type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
};

const BlockStyleControls = props => {
	const {editorState} = props;
	const selection = editorState.getSelection();
	const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

	return (
		<div className="RichEditor-controls">
			{BLOCK_TYPES.map(type => (
				<RichTextStyleButton
					key={type.label}
					active={type.style === blockType}
					label={type.style === blockType ? type.label + ' active' : type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
};

RichTextField.propTypes = {
	text: PropTypes.string,
	mentions: PropTypes.array,
	mentionsLabels: PropTypes.array,
	// prevents blur on targets with classes in the preventBlurOnClasses array
	preventBlurOnClasses: PropTypes.array,
	addLabelByHashtag: PropTypes.func,
	handleFocusChange: PropTypes.func,
	handleBlur: PropTypes.func,
	handleTextChange: PropTypes.func,
	onClick: PropTypes.func,
	onChange: PropTypes.func,
	clearCommentEditor: PropTypes.bool,
	isCommentEditor: PropTypes.bool,
	placeholder: PropTypes.string,
	readOnly: PropTypes.bool,
	fixedHeight: PropTypes.bool,
	hideEmojiPicker: PropTypes.bool,
	hideStylingOptions: PropTypes.bool,
	characterLimit: PropTypes.number,
	textFieldLabel: PropTypes.string,
	id: PropTypes.string,
	fileHandler: PropTypes.func,
	collapsed: PropTypes.bool,
	handleAddComment: PropTypes.func,
	labelLink: PropTypes.element,
	alwaysShowControls: PropTypes.bool,
	onEnter: PropTypes.func,
	maxHeight: PropTypes.number,
	editorButtons: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			buttonStyle: PropTypes.string.isRequired,
			colorTheme: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
		})
	),
};

export default DropTarget(NativeTypes.FILE, fileTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	isShallow: monitor.isOver({shallow: true}),
	canDrop: monitor.canDrop(),
}))(RichTextField);
