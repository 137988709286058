import ProjectItem from '../components/items/project_item';
import LoadingGroup from '../components/groups/loading_group';
import {hasPermission} from '../../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../../Permissions';
import {sortProjectGroups} from './projects_scheduling_util';
import ProjectAllocationItem from '../components/items/project_allocation_item';
import ProjectGroup from '../components/groups/project_group';
import ComposeManager from '../ComposeManager';
import DataManager from '../DataManager';
import ProgramGroup from '../components/groups/program_group';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';

export const createLightWeightProjectsGroupsAndItems = pageComponent => {
	const {lightWeightData: data} = pageComponent.state;

	let groups = [];
	const items = [];

	const hasReadAllPermission =
		hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL) ||
		(hasFeatureFlag('scheduling_read_only_permissions') && hasPermission(PERMISSION_TYPE.PROJECTS_READ_ALL_VIEW_ONLY));
	const projectEdges = data.projects.filter(project => {
		if (hasReadAllPermission) {
			return true;
		}

		const projectPersons = DataManager.getProjectPersonsByProjectId(pageComponent, project.id);
		for (const projectPerson of projectPersons) {
			if (projectPerson.personId === data.viewer.actualPersonId) {
				return true;
			}
		}

		return false;
	});

	const projectGroupTimelineGroups = new Map(),
		projectGroupTeamMap = new Map();
	for (const projectGroup of data.projectGroups) {
		const projectGroupData = ComposeManager.composeProjectGroup(pageComponent, null, null, null, projectGroup, [], false);
		if (projectGroupData) {
			projectGroupTimelineGroups.set(projectGroup.id, new ProjectGroup(pageComponent, projectGroupData));
			projectGroupTeamMap.set(projectGroup.id, []);
		}
	}

	const programGroupMap = new Map();
	for (const program of data.programs) {
		const programGroupData = ComposeManager.composeProgramGroup(pageComponent, program);
		if (programGroupData) {
			programGroupMap.set(program.prefix, new ProgramGroup(pageComponent, programGroupData));
		}
	}

	/*
	 * map through all projects.
	 * single projects should be added to array of returned timeline groups
	 * projects that belong to project group should be added to map that later will be casted into array of timeline groups for project group
	 */
	for (const project of projectEdges) {
		const projectItemData = ComposeManager.composeProjectItem(pageComponent, project, null, null);
		if (projectItemData) {
			items.push(new ProjectItem(pageComponent, projectItemData));
		}

		const projectSubGroups = [];
		const projectGroup = new ProjectGroup(
			pageComponent,
			ComposeManager.composeProjectGroup(pageComponent, null, null, project, null, projectSubGroups, false)
		);

		if (project.isInProjectGroup) {
			if (projectGroupTimelineGroups.has(project.projectGroupId)) {
				const projectGroupGroup = projectGroupTimelineGroups.get(project.projectGroupId);
				projectGroupGroup.addChildGroup(projectGroup);
				projectGroupTimelineGroups.set(project.projectGroupId, projectGroupGroup);
			}
		} else if (project.isInProgram) {
			if (programGroupMap.has(project.programPrefix)) {
				const programGroup = programGroupMap.get(project.programPrefix);
				programGroup.addChildGroup(projectGroup);
				programGroupMap.set(project.programPrefix, programGroup);
			}
		} else {
			groups.push(projectGroup);
		}

		projectGroup.addChildGroup(new LoadingGroup(pageComponent, {}));
	}

	projectGroupTimelineGroups.forEach((projectGroupGroup, key, map) => {
		//if project group is empty it means viewer was not assigned to any of its projects. Dont add these groups to the array
		if (projectGroupGroup.groups.length !== 0) {
			const {projectGroup} = projectGroupGroup.data;
			const projectItemData = ComposeManager.composeProjectItem(pageComponent, null, projectGroup, null);
			if (projectItemData) {
				items.push(new ProjectItem(pageComponent, projectItemData));
			}

			groups.push(projectGroupGroup);
		}

		if (pageComponent.isUsingNewLazyLoad) {
			projectGroupGroup.addChildGroup(new LoadingGroup(pageComponent, {}));
		}
	});

	programGroupMap.forEach((programGroup, programPrefix) => {
		const {program} = programGroup.data;

		groups.push(programGroup);

		// add program item
		const projectItemData = ComposeManager.composeProjectItem(pageComponent, null, null, program);
		if (projectItemData) {
			items.push(new ProjectItem(pageComponent, projectItemData));
		}

		if (pageComponent.isUsingNewLazyLoad) {
			programGroup.addChildGroup(new LoadingGroup(pageComponent, {}));
		}
	});

	groups = sortProjectGroups(pageComponent, groups);

	for (const allocation of data.allocations) {
		const allocationItemData = ComposeManager.composeProjectAllocation(pageComponent, allocation, data);
		if (allocationItemData) {
			items.push(new ProjectAllocationItem(pageComponent, allocationItemData));
		}
	}

	return {groups, items};
};
