import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl, FormattedHTMLMessage} from 'react-intl';
import LabelsCell from '../../../containers/overview/labels_cell';
import TableCellRichtextItem from '../../table_cell_richtext_item';
import Util from '../../../forecast-app/shared/util/util';
import Info from '../../info';
import {List, WindowScroller, AutoSizer} from 'react-virtualized';
import Person from '../../../forecast-app/shared/components/person/person';
import {INSIGHTS_NEW_BUDGET_COLUMNS} from '../../../constants';
import TooltipContainer from '../../../forecast-app/shared/components/tooltips/tooltip_container';
import InformationIcon from '../../../images/information-icon';
import {profilePicSrc} from '../../../directApi';

class InsightList extends Component {
	constructor(props) {
		super(props);
		let tooltipsMap = new Map();
		const {formatMessage} = this.props.intl;
		//forecast info
		const forecastCost = [
			{
				title: formatMessage({id: 'common.cost'}),
				details: [
					formatMessage({id: 'project_budget.all_forecast_hours'}),
					'x',
					formatMessage({id: 'project_budget.internal_cost'}),
					'+',
					formatMessage({id: 'project_budget.cost_on_all_actual_expenses'}),
				],
			},
		];
		const forecastMoney = [
			{
				title: formatMessage({id: 'project_budget.revenue'}),
				details: [
					formatMessage({id: 'project_budget.all_billable_forecast_hours'}),
					'x',
					formatMessage({id: 'common.rate-card'}),
					'+',
					formatMessage({id: 'project_budget.price_on_actual_billable_expenses'}),
				],
			},
		];
		const forecastProfit = [
			{
				title: formatMessage({id: 'profit_info.fixed_price_title'}),
				details: [
					formatMessage({id: 'insights.component.list.column.fixedPrice'}),
					'-',
					formatMessage({id: 'project_budget.planned_cost'}),
				],
			},
			{
				title: formatMessage({id: 'profit_info.no_fixed_price_title'}),
				details: [
					formatMessage({id: 'project_budget.planned_revenue'}),
					'-',
					formatMessage({id: 'project_budget.planned_cost'}),
				],
			},
		];
		const forecastMargin = [
			{
				title: formatMessage({id: 'margin_calculation_info_title.fixed_price'}),
				details: [
					`(${formatMessage({id: 'insights.component.list.column.fixedPrice'})} - ${formatMessage({
						id: 'project_budget.planned_cost',
					})})`,
					'/',
					formatMessage({id: 'project_budget.planned_cost'}),
				],
			},
			{
				title: formatMessage({id: 'margin_calculation_info_title.no_fixed_price'}),
				details: [
					`(${formatMessage({id: 'project_budget.planned_revenue'})} - ${formatMessage({
						id: 'project_budget.planned_cost',
					})})`,
					'/',
					formatMessage({id: 'project_budget.planned_cost'}),
				],
			},
		];
		//remaining info
		const remainingMoney = [
			{
				title: formatMessage({id: 'project_budget.revenue'}),
				details: [
					formatMessage({id: 'project_budget.all_remaining_billable_hours'}),
					'x',
					formatMessage({id: 'common.rate-card'}),
					'+',
					formatMessage({id: 'project_budget.price_on_all_remaining_billable_expenses'}),
				],
			},
		];
		const remainingCost = [
			{
				title: formatMessage({id: 'common.cost'}),
				details: [
					formatMessage({id: 'project_budget.all_remaining_hours'}),
					'x',
					formatMessage({id: 'project_budget.internal_cost'}),
					'+',
					formatMessage({id: 'project_budget.cost_on_all_remaining_expenses'}),
				],
			},
		];
		const remainingProfit = [
			{
				title: formatMessage({id: 'profit_info.fixed_price_title'}),
				details: [
					formatMessage({id: 'insights.component.list.column.fixedPrice'}),
					'-',
					formatMessage({id: 'project_budget.remaining_cost'}),
				],
			},
			{
				title: formatMessage({id: 'profit_info.no_fixed_price_title'}),
				details: [
					formatMessage({id: 'project_budget.remaining_revenue'}),
					'-',
					formatMessage({id: 'project_budget.remaining_cost'}),
				],
			},
		];
		const remainingMargin = [
			{
				title: formatMessage({id: 'margin_calculation_info_title.fixed_price'}),
				details: [
					`(${formatMessage({id: 'insights.component.list.column.fixedPrice'})} - ${formatMessage({
						id: 'project_budget.remaining_cost',
					})})`,
					'/',
					formatMessage({id: 'project_budget.remaining_cost'}),
				],
			},
			{
				title: formatMessage({id: 'margin_calculation_info_title.no_fixed_price'}),
				details: [
					`(${formatMessage({id: 'project_budget.remaining_revenue'})} - ${formatMessage({
						id: 'project_budget.remaining_cost',
					})})`,
					'/',
					formatMessage({id: 'project_budget.remaining_cost'}),
				],
			},
			{
				title: formatMessage({id: 'remaining_margin.not_available_info'}),
				details: [],
			},
		];
		//reported info
		const reportedMoney = [
			{
				title: formatMessage({id: 'project_budget.revenue'}),
				details: [
					formatMessage({id: 'project_budget.all_billable_time_entries'}),
					'x',
					formatMessage({id: 'common.rate-card'}),
					'+',
					formatMessage({id: 'project_budget.price_on_actual_billable_expenses'}),
				],
			},
		];
		const reportedCost = [
			{
				title: formatMessage({id: 'common.cost'}),
				details: [
					formatMessage({id: 'project_budget.all_time_entries'}),
					'x',
					formatMessage({id: 'project_budget.internal_cost'}),
					'+',
					formatMessage({id: 'project_budget.cost_on_all_actual_expenses'}),
				],
			},
		];
		const reportedProfit = [
			{
				title: formatMessage({id: 'profit_info.fixed_price_title'}),
				details: [
					formatMessage({id: 'insights.component.list.column.fixedPrice'}),
					'-',
					formatMessage({id: 'insights.component.list.column.reportedCost'}),
				],
			},
			{
				title: formatMessage({id: 'profit_info.no_fixed_price_title'}),
				details: [
					formatMessage({id: 'project_budget.actual_revenue'}),
					'-',
					formatMessage({id: 'insights.component.list.column.reportedCost'}),
				],
			},
		];
		const reportedMargin = [
			{
				title: formatMessage({id: 'margin_calculation_info_title.fixed_price'}),
				details: [
					`(${formatMessage({id: 'insights.component.list.column.fixedPrice'})} - ${formatMessage({
						id: 'insights.component.list.column.reportedCost',
					})})`,
					'/',
					formatMessage({id: 'insights.component.list.column.reportedCost'}),
				],
			},
			{
				title: formatMessage({id: 'margin_calculation_info_title.no_fixed_price'}),
				details: [
					`(${formatMessage({id: 'project_budget.actual_revenue'})} - ${formatMessage({
						id: 'insights.component.list.column.reportedCost',
					})})`,
					'/',
					formatMessage({id: 'insights.component.list.column.reportedCost'}),
				],
			},
			{
				title: formatMessage({id: 'reported_margin.not_available_info'}),
				details: [],
			},
		];
		const projectedMoney = [
			{
				title: formatMessage({id: 'project_budget.revenue'}),
				details: [
					formatMessage({id: 'project_budget.actual_revenue'}),
					'+',
					formatMessage({id: 'project_budget.remaining_revenue'}),
				],
			},
		];
		const projectedCost = [
			{
				title: formatMessage({id: 'common.cost'}),
				details: [
					formatMessage({id: 'insights.component.list.column.actualCost'}),
					'+',
					formatMessage({id: 'project_budget.remaining_cost'}),
				],
			},
		];
		const projectedProfit = [
			{
				title: formatMessage({id: 'profit_info.fixed_price_title'}),
				details: [
					formatMessage({id: 'insights.component.list.column.fixedPrice'}),
					'-',
					`(${formatMessage({id: 'insights.component.list.column.actualCost'})} + ${formatMessage({
						id: 'project_budget.remaining_cost',
					})})`,
				],
			},
			{
				title: formatMessage({id: 'profit_info.no_fixed_price_title'}),
				details: [
					`(${formatMessage({id: 'project_budget.actual_revenue'})} + ${formatMessage({
						id: 'project_budget.remaining_revenue',
					})}`,
					'-',
					`(${formatMessage({id: 'insights.component.list.column.actualCost'})} + ${formatMessage({
						id: 'project_budget.remaining_cost',
					})})`,
				],
			},
		];
		const projectedMargin = [
			{
				title: formatMessage({id: 'margin_calculation_info_title.fixed_price'}),
				details: [
					`(${formatMessage({id: 'insights.component.list.column.fixedPrice'})} - (${formatMessage({
						id: 'insights.component.list.column.reportedCost',
					})} + ${formatMessage({
						id: 'project_budget.remaining_cost',
					})}))`,
					'/',
					`(${formatMessage({id: 'insights.component.list.column.actualCost'})} + ${formatMessage({
						id: 'project_budget.remaining_cost',
					})})`,
				],
			},
			{
				title: formatMessage({id: 'margin_calculation_info_title.no_fixed_price'}),
				details: [
					`((${formatMessage({id: 'project_budget.actual_revenue'})} + ${formatMessage({
						id: 'project_budget.remaining_revenue',
					})}) - (${formatMessage({
						id: 'insights.component.list.column.actualCost',
					})} + ${formatMessage({id: 'project_budget.remaining_cost'})}))`,
					'/',
					`(${formatMessage({id: 'insights.component.list.column.actualCost'})} + ${formatMessage({
						id: 'project_budget.remaining_cost',
					})})`,
				],
			},
		];
		tooltipsMap = new Map([
			['forecastMoney', forecastMoney],
			['forecastCost', forecastCost],
			['forecastProfit', forecastProfit],
			['forecastMargin', forecastMargin],
			['remainingMoney', remainingMoney],
			['remainingCost', remainingCost],
			['remainingProfit', remainingProfit],
			['remainingMargin', remainingMargin],
			['reportedMoney', reportedMoney],
			['reportedCost', reportedCost],
			['reportedProfit', reportedProfit],
			['reportedMargin', reportedMargin],
			['projectedMoney', projectedMoney],
			['projectedCost', projectedCost],
			['projectedProfit', projectedProfit],
			['projectedMargin', projectedMargin],
		]);

		this.state = {
			showRawTable: false,
			tooltipsMap,
		};
	}

	componentDidMount() {
		const printListener = mql => {
			if (mql.matches) {
				this.setState({showRawTable: true});
			} else {
				this.setState({showRawTable: false});
			}
		};
		this.printListener = printListener;
		if (this.props.notifyOnReady) {
			const updateScroller = () => {
				setTimeout(() => {
					if (this.scroller) {
						this.scroller.updatePosition();
					}
				}, 1000);
			};
			this.props.notifyOnReady(this.props.componentId, updateScroller);
		}
		var mediaQueryList = window.matchMedia('print');
		mediaQueryList.addListener(this.printListener);
	}
	componentWillUnmount() {
		var mediaQueryList = window.matchMedia('print');
		mediaQueryList.removeListener(this.printListener);
	}

	onRowClick(selected) {
		if (this.props.showTaskModal && selected.companyTaskId) {
			this.props.showTaskModal(selected.companyTaskId);
		}
	}

	rowRenderer({index, isScrolling, isVisible, key, style}) {
		const columns = this.props.allColumns.filter(column => {
			return this.props.activeColumns ? this.props.activeColumns.includes(column) : false;
		});
		const cellWidth = {width: 100 / columns.length + '%'};
		if (index === 0) {
			const {formatMessage} = this.props.intl;
			const currency = Util.GetCurrencySymbol(this.props.currency || 'USD');
			const completionRateInfoText = [
				{
					title: formatMessage({id: 'scheduling.project_allocation_projects'}),
					details: [formatMessage({id: 'common.time_registered'}), '/', formatMessage({id: 'common.allocated_time'})],
				},
				{
					title: formatMessage({id: 'scheduling.task_allocation_projects'}),
					details: [
						formatMessage({id: 'common.time_registered'}),
						'/',
						`(${formatMessage({id: 'common.remaining'})}+ ${formatMessage({id: 'common.time_registered'})} )`,
					],
				},
			];
			return (
				<div className="insight-list header-row" key={key} style={style}>
					{columns.map((column, index) => {
						return column === 'color' ? (
							<div
								title={formatMessage({id: 'common.color'})}
								className="list-header"
								key={index}
								style={{width: 8, flex: '0 0 8px'}}
							/>
						) : (
							<div
								title={
									INSIGHTS_NEW_BUDGET_COLUMNS.includes(column)
										? formatMessage({id: `insights.component.list.column.${column}.newBudget`})
										: formatMessage(
												{id: 'insights.component.list.column.' + column},
												{suffix: currency}
										  ).replace('<br/>', ' ')
								}
								className={
									'list-header ' +
									(this.props.sortBy && this.props.disableSort !== column
										? 'sortable ' +
										  (this.props.sortBy.column === column
												? this.props.sortBy.ascending
													? 'ascending'
													: 'descending'
												: '')
										: '') +
									(column === 'diff' ? ' info' : '')
								}
								onClick={
									this.props.setSortBy && this.props.disableSort !== column
										? this.props.setSortBy.bind(this, column)
										: null
								}
								key={index}
								style={cellWidth}
							>
								<div className="header-container">
									<div className="header-title-wrapepr">
										{INSIGHTS_NEW_BUDGET_COLUMNS.includes(column) ? (
											<FormattedHTMLMessage
												tagName="div"
												id={`insights.component.list.column.${column}.newBudget`}
											/>
										) : (
											<FormattedHTMLMessage
												tagName={'div'}
												id={'insights.component.list.column.' + column}
												values={{suffix: currency}}
											/>
										)}
									</div>
									{this.props.sortBy &&
									this.props.disableSort !== column &&
									this.props.sortBy.column === column ? (
										<span>&nbsp;&nbsp;</span>
									) : null}
									{column === 'diff' ? (
										<Info
											className="info-bubble"
											infoMessage={formatMessage({id: 'insights.forecast_vs_projected_info'})}
											leftAligned={true}
										/>
									) : null}
									{column === 'completionRate' ? (
										<TooltipContainer
											triangleLocation={'right'}
											infoText={completionRateInfoText}
											tooltipDuration={300000}
										>
											<InformationIcon />
										</TooltipContainer>
									) : this.state.tooltipsMap.has(column) && this.state.tooltipsMap.get(column) !== null ? (
										<TooltipContainer
											triangleLocation={'right'}
											infoText={this.state.tooltipsMap.get(column)}
											tooltipDuration={300000}
										>
											<InformationIcon />
										</TooltipContainer>
									) : null}
								</div>
								<div className="header-totals">
									{this.props.totals && this.props.totals[column] != null ? this.props.totals[column] : ''}
								</div>
							</div>
						);
					})}
				</div>
			);
		}

		const card = this.props.data[index - 1];
		const getSkillsTooltipText = skills => (
			<ul>
				{skills.map(skill => (
					<li>{skill}</li>
				))}
			</ul>
		);

		return (
			<div
				className={(this.props.showTaskModal ? 'insight-list' : 'insight-list no-hover') + ' line-item-row'}
				onClick={this.onRowClick.bind(this, card)}
				key={key}
				style={style}
			>
				{columns.map((column, index) => {
					if (column === 'skills') {
						const skills = card[column].join(', ');
						return (
							<div className="table-cell person-skill-cell" key={index}>
								<TooltipContainer
									infoText={getSkillsTooltipText(card[column])}
									tooltipDuration={300000}
									className={'person-skill-cell-tooltip'}
								>
									<div className="person-skill-cell-content">{skills}</div>
								</TooltipContainer>
							</div>
						);
					} else if (column === 'labels') {
						const mapped = card[column].map(label => {
							return {...{id: label.id, label: {color: label.color, name: label.name}}};
						});
						return (
							<div key={index}>
								<LabelsCell labelList={mapped.slice(0, 5)} />
							</div>
						);
					} else if (column === 'statusColor' || column === 'projectStatusColor') {
						const fillColor = card[column + '_hex'];
						return (
							<div className="status-color-cell">
								<svg className="status-color" height="36px" width="36px">
									<circle cx="16" cy="16" r="15" style={{fill: fillColor}} />
								</svg>
							</div>
						);
					} else if (column === 'description' || column === 'statusDescription' || column === 'notes') {
						return (
							<div className="description-cell" key={index} style={cellWidth}>
								<TableCellRichtextItem text={card[column]} placeholder="" />
							</div>
						);
					} else if (column === 'person') {
						if (card['person'] && card['person'].firstName) {
							return (
								<div className="table-cell person" key={index}>
									{card['person'] ? (
										<Person
											name={card['person'].firstName + ' ' + card['person'].lastName}
											role=""
											showName={true}
											showRole={false}
											imageSrc={profilePicSrc(card['person'].profilePictureId)}
											imageSize="medium"
										/>
									) : (
										<div className="table-cell person" key={index} />
									)}
								</div>
							);
						} else if (card['person']) {
							return (
								<div className="table-cell person" key={index}>
									{card['person']}
								</div>
							);
						} else {
							return <div className="table-cell person" key={index} />;
						}
					} else if (column === 'progress' || column === 'assigned' || column === 'completionBar') {
						return (
							<div
								title={column === 'assigned' ? card[column + '_raw'] : null}
								key={index}
								className={'table-cell ' + column}
								style={{backgroundColor: card[column + '_backgroundColor'], width: cellWidth.width}}
							>
								{card[column]}
							</div>
						);
					} else if (column === 'color') {
						return (
							<div
								key={index}
								style={{
									backgroundColor: card[column],
									width: 8,
									flex: '0 0 8px',
									borderTopLeftRadius: 4,
									borderBottomLeftRadius: 2,
								}}
							/>
						);
					} else {
						return (
							<div
								key={index}
								className={'table-cell ' + column}
								style={{backgroundColor: card[column + '_backgroundColor'], width: cellWidth.width}}
							>
								<span title={card[column]} className="cell-content-span">
									{card[column]}
								</span>
							</div>
						);
					}
				})}
			</div>
		);
	}

	render() {
		if (this.scroller) {
			this.scroller.updatePosition();
		}
		if (this.props.data && this.props.data.length !== 0) {
			return (
				<WindowScroller
					ref={el => (this.scroller = el)}
					scrollElement={this.props.scrollElement ? this.props.scrollElement : this.props.scrollElementFullScreen}
				>
					{({height, isScrolling, registerChild, onChildScroll, scrollTop}) => (
						<div>
							<AutoSizer disableHeight>
								{({width}) => (
									<div ref={registerChild}>
										<List
											ref={el => {
												this['list_' + this.props.componentName] = el;
											}}
											autoHeight
											className="insight-virtualized-list"
											height={
												this.props.scrollElement
													? this.props.scrollElement.getBoundingClientRect().height
													: this.props.scrollElementFullScreen
													? this.props.scrollElementFullScreen.getBoundingClientRect().height
													: 0
											}
											onScroll={onChildScroll}
											isScrolling={isScrolling}
											overscanRowCount={this.state.showRawTable ? this.props.data.length + 1 : 10}
											rowCount={this.props.data.length + 1}
											rowHeight={51}
											scrollTop={scrollTop}
											rowRenderer={this.rowRenderer.bind(this)}
											width={width}
										/>
									</div>
								)}
							</AutoSizer>
						</div>
					)}
				</WindowScroller>
			);
		} else {
			return null;
		}
	}
}

InsightList.propTypes = {
	componentName: PropTypes.string.isRequired,
	totals: PropTypes.object,
	allColumns: PropTypes.array.isRequired,
	notifyOnReady: PropTypes.func,
	columnSettings: PropTypes.arrayOf(
		PropTypes.shape({
			width: PropTypes.string,
			span: PropTypes.number,
		})
	),
};

export default injectIntl(InsightList);
