import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'web-components';

export const SingleTextDropdown = ({
	options,
	dropdownAlignment,
	selectedItems,
	width,
	name,
	selectedGroupName,
	optionsName,
	onSelect,
	onRemove,
	userpilot,
	emphasizeEmptyState,
	cy,
	headerLines,
}) => {
	const getSearchString = option => {
		return option.label.toLowerCase();
	};

	return (
		<Dropdown
			isNested
			isMultiSelect
			selectedItems={selectedItems}
			headerLines={headerLines}
			selectedGroupName={selectedGroupName}
			width={width}
			dropdownAlignment={dropdownAlignment}
			name={name}
			onSelect={onSelect}
			onRemove={onRemove}
			userpilot={userpilot}
			emphasizeEmptyState={emphasizeEmptyState}
			cy={cy}
		>
			<Dropdown.Group name={optionsName} key={'single-text-options'}>
				{options.map(option => {
					return (
						<Dropdown.SingleText key={option.value} value={option.value} searchString={getSearchString(option)}>
							{option.label}
						</Dropdown.SingleText>
					);
				})}
			</Dropdown.Group>
		</Dropdown>
	);
};

SingleTextDropdown.propTypes = {
	dropDownAlignment: PropTypes.oneOf(['left', 'center', 'right']),
	width: PropTypes.number,
	name: PropTypes.string,
	optionsName: PropTypes.string,
	selectedItems: PropTypes.array,
	onSelect: PropTypes.func,
	onRemove: PropTypes.func,
	selectedGroupName: PropTypes.string,
	cy: PropTypes.string,
};

SingleTextDropdown.defaultProps = {
	onSelect: () => false,
	onRemove: () => false,
};

export default SingleTextDropdown;
