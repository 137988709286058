import Item from '../../../canvas-timeline/canvas_timeline_item';
import {
	drawBackground,
	drawRectangle,
	getTrimmedText,
	getNumber,
	GROUP_SECTION_TEXT_GREY,
	HEATMAP_CELL_PADDING_X,
	HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE,
	HEATMAP_FONT_SIZE,
	HEATMAP_OVER_ALLOCATED_COMPLETION_COLOR,
	HEATMAP_TIME_OFF_BACKGROUND_COLOR,
	ITEM_TYPE,
	TIMELINE_BACKGROUND_COLOR,
	TIMELINE_HEATMAP_BACKGROUND_COLOR,
	UTILIZATION_FORMAT,
	HEATMAP_CELL_BORDER_THICKNESS,
	GROUP_SECTION_TEXT_GREY_DARK,
	getVisualizationMode,
	VISUALIZATION_MODE,
	isAllocatedHoursNaN,
} from '../../../canvas-timeline/canvas_timeline_util';
import {cacheManager, COMMON_IMAGE} from '../../../canvas-timeline/canvas_timeline_cache_manager';
import Util from '../../../../../forecast-app/shared/util/util';
import {getCachedMessage} from '../../../../../translations/TranslationCache';
import HeatmapItemConfig from './HeatmapItemConfig';
import {drawDoneIcon, drawSoftAllocatedArea} from '../../../DrawingUtils';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';
import {
	getMinutesAllocatedVariation,
	getMinutesAllocatedWithoutTasksVariation,
	getMinutesHard,
} from '../../../heatmap/MinutesAllocatedVariationsUtils';

export const HEATMAP_TYPE = {
	UTILIZATION: 'UTILIZATION',
	DEMAND: 'DEMAND',
	PROJECT_ENTITY: 'PROJECT_ENTITY',
};

class HeatmapItem extends Item {
	hoursToShow;
	config;

	constructor(pageComponent, heatmapType, data, config = null) {
		super(pageComponent, ITEM_TYPE.HEATMAP_ITEM, data);

		this.intl = pageComponent.props.intl;
		this.heatmapType = heatmapType;

		if (!config) {
			this.config = new HeatmapItemConfig(
				TIMELINE_BACKGROUND_COLOR,
				HEATMAP_OVER_ALLOCATED_COMPLETION_COLOR,
				TIMELINE_HEATMAP_BACKGROUND_COLOR,
				null
			);
		} else {
			this.config = config;
		}

		this.updateWithData(data);
	}

	isUtilizationHeatmap() {
		return this.heatmapType === HEATMAP_TYPE.UTILIZATION;
	}

	isDemandHeatmap() {
		return this.heatmapType === HEATMAP_TYPE.DEMAND;
	}

	isProjectEntityHeatmap() {
		return this.heatmapType === HEATMAP_TYPE.PROJECT_ENTITY;
	}

	draw(canvasContext) {
		const {data, height} = this;
		const {x, y, width, isDayOffItem, isTimeOffItem} = data;

		this.xPos = x + HEATMAP_CELL_BORDER_THICKNESS;
		this.width = width - HEATMAP_CELL_BORDER_THICKNESS * 2;

		if (isTimeOffItem && !isDayOffItem) {
			this.drawTimeOff(canvasContext, data);
			return;
		}

		drawBackground(canvasContext, this.xPos, y, this.width, height - 1, false, this.config.getBackgroundColor(this));

		this.calculateHeatmapProperties();

		if (hasFeatureFlag('tp_heatmap_spike') && this.isProjectEntityHeatmap()) {
			this.drawProjectEntityHeatmap(canvasContext);
			return;
		}

		if (isDayOffItem && !this.isOverAllocated) {
			this.drawDayOffPattern(canvasContext, data);
			return;
		}

		if (this.isUtilizationHeatmap()) {
			this.drawAllocatedBackground(canvasContext);
			this.drawAllocatedText(canvasContext);
		} else if (this.isDemandHeatmap()) {
			this.drawHeatmapDemand(canvasContext);
		}
	}

	drawProjectEntityHeatmap(canvasContext) {
		const {intl} = this.pageComponent.props;
		const {y} = this.data;
		const {height} = this;

		const nothingAllocated = !this.plannedTotalMinutesHard && !this.plannedTotalMinutesSoft;

		let fontSize = 12;
		canvasContext.font = `600 ${fontSize}px ` + Util.getFontFamily();
		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY_DARK;

		if (nothingAllocated) {
			const lineText = '-';
			const lineTextWidth = canvasContext.measureText(lineText).width;

			const xCenter = this.xPos + (this.width - lineTextWidth) / 2;
			const yCenter = y + height / 2 + fontSize / 3;

			canvasContext.fillText(lineText, xCenter, yCenter);
		} else {
			// hours allocated
			const hoursAllocated = Util.convertMinutesToFullHour(this.plannedTotalMinutesAllocated, this.intl);
			const hoursAllocatedWidth = canvasContext.measureText(hoursAllocated).width;

			const hoursAllocatedXCenter = this.xPos + (this.width - hoursAllocatedWidth) / 2;
			const hoursAllocatedYCenter = y + height / 3 + fontSize / 3;

			canvasContext.fillText(hoursAllocated, hoursAllocatedXCenter, hoursAllocatedYCenter);

			// allocation, mixed, registered, or task estimate
			fontSize = 10;
			canvasContext.font = `500 ${fontSize}px ` + Util.getFontFamily();

			const translationId =
				this.data.timeRegMinutes === this.plannedTotalMinutesAllocated
					? 'common.registered'
					: this.data.timeRegMinutes
					? 'common.mixed'
					: this.useTaskAllocatedMinutes
					? 'scheduling.project_entity.task_estimate'
					: 'common.allocation';

			const entityText = getCachedMessage(intl, translationId);
			const allocatedFromWidth = canvasContext.measureText(entityText).width;

			const allocatedFromXCenter = this.xPos + (this.width - allocatedFromWidth) / 2;
			const allocatedFromYCenter = y + (height - height / 3) + fontSize / 3;

			canvasContext.fillText(entityText, allocatedFromXCenter, allocatedFromYCenter);
		}
	}

	calculateHeatmapProperties() {
		this.setHasMinutesAvailable();
		this.setPlannedMinutesAllocated();

		if (this.isDemandHeatmap()) {
			this.setMinutesDemand();
		}

		this.setIsOverAllocated();

		if (this.isUtilizationHeatmap()) {
			this.setFractionAllocated();
		}
	}

	setHasMinutesAvailable() {
		const {minutesAvailable} = this.data;
		this.hasMinutesAvailable = minutesAvailable !== undefined && minutesAvailable > 0;
	}

	setPlannedMinutesAllocated() {
		const {data, schedulingOptions} = this.pageComponent.state;
		const {company} = data;
		const {calcWin, hideSoft, hideHard} = schedulingOptions;
		const {minutesAllocatedVariations, taskMinutesAllocated} = this.data;

		if (minutesAllocatedVariations) {
			this.plannedTotalMinutesSoft = getMinutesAllocatedWithoutTasksVariation(
				minutesAllocatedVariations,
				hideSoft,
				true,
				calcWin
			);

			this.plannedTotalMinutesHard = getMinutesHard(minutesAllocatedVariations, hideSoft, hideHard, calcWin);

			this.hasSoftHardTotalMinutes = true;
			this.plannedTotalMinutesAllocated = getMinutesAllocatedVariation(
				minutesAllocatedVariations,
				hideSoft,
				hideHard,
				calcWin
			);

			if (this.isProjectEntityHeatmap()) {
				const allocatedMinutes = getMinutesAllocatedWithoutTasksVariation(
					minutesAllocatedVariations,
					hideSoft,
					hideHard,
					calcWin
				);
				const shouldConvertToUseTaskMinutes = allocatedMinutes < taskMinutesAllocated;
				if (shouldConvertToUseTaskMinutes) {
					this.plannedTotalMinutesAllocated = taskMinutesAllocated;
					this.useTaskAllocatedMinutes = true;
				} else {
					this.useTaskAllocatedMinutes = false;
				}
			}
		} else {
			const {minutesAllocated, plannedTotalMinutesSoftWin} = this.data;

			let {plannedTotalMinutesSoft, plannedTotalMinutesHard} = this.data;
			this.hasSoftHardTotalMinutes =
				!isAllocatedHoursNaN(plannedTotalMinutesSoft) && !isAllocatedHoursNaN(plannedTotalMinutesHard);

			const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);
			if (isUsingProjectAllocation && this.hasSoftHardTotalMinutes) {
				plannedTotalMinutesSoft = getNumber(plannedTotalMinutesSoft);
				plannedTotalMinutesHard = getNumber(plannedTotalMinutesHard);

				if (hideSoft && !this.isDemandHeatmap()) {
					plannedTotalMinutesSoft = 0;
				} else if (calcWin) {
					plannedTotalMinutesSoft = getNumber(plannedTotalMinutesSoftWin);
				}

				if (hideHard) {
					plannedTotalMinutesHard = 0;
				}
			} else {
				plannedTotalMinutesSoft = 0;
				plannedTotalMinutesHard = minutesAllocated;
			}

			this.plannedTotalMinutesSoft = plannedTotalMinutesSoft;
			this.plannedTotalMinutesHard = plannedTotalMinutesHard;
			this.plannedTotalMinutesAllocated = plannedTotalMinutesHard + plannedTotalMinutesSoft;
		}

		const doNotDrawSoftFractions =
			this.pageComponent.props.isProjectTimeline &&
			getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);
		if (doNotDrawSoftFractions) {
			this.plannedTotalMinutesSoft = 0;
			this.plannedTotalMinutesHard = this.plannedTotalMinutesAllocated;
		}
	}

	setMinutesDemand() {
		let {minutesAvailable} = this.data;
		this.minutesDemand = this.hasMinutesAvailable
			? minutesAvailable - this.plannedTotalMinutesAllocated
			: this.plannedTotalMinutesAllocated;
	}

	setIsOverAllocated() {
		const {minutesAvailable} = this.data;

		if (this.isDemandHeatmap()) {
			this.isOverAllocated = this.minutesDemand < 0;
		} else if (this.isUtilizationHeatmap()) {
			this.isOverAllocated = this.plannedTotalMinutesAllocated > minutesAvailable;
		}
	}

	setFractionAllocated() {
		const {minutesAvailable} = this.data;

		this.fractionAllocated = getNumber(this.plannedTotalMinutesAllocated / minutesAvailable);
		this.fractionOverAllocated = getNumber((this.plannedTotalMinutesAllocated - minutesAvailable) / minutesAvailable);
		this.fractionHardAllocated = 0;
		this.fractionSoftAllocated = 0;

		this.setIsWithinCheckmarkRange();

		if (this.isOverAllocated) {
			if (this.isWithinCheckmarkRange) {
				const totalAllocatedSum = getNumber(this.plannedTotalMinutesHard) + getNumber(this.plannedTotalMinutesSoft);
				this.fractionHardAllocated = getNumber(this.plannedTotalMinutesHard / totalAllocatedSum);
				this.fractionSoftAllocated = getNumber(this.plannedTotalMinutesSoft / totalAllocatedSum);
			} else if (this.hasMinutesAvailable) {
				if (this.plannedTotalMinutesHard >= minutesAvailable) {
					this.fractionHardAllocated = (this.plannedTotalMinutesHard - minutesAvailable) / minutesAvailable;
					this.fractionSoftAllocated = this.plannedTotalMinutesSoft / minutesAvailable;
				} else {
					this.fractionHardAllocated = 0;
					this.fractionSoftAllocated =
						(this.plannedTotalMinutesHard + this.plannedTotalMinutesSoft - minutesAvailable) / minutesAvailable;
				}
			}
		} else {
			this.fractionHardAllocated = getNumber(this.plannedTotalMinutesHard / minutesAvailable);
			this.fractionSoftAllocated = getNumber(this.plannedTotalMinutesSoft / minutesAvailable);
		}
	}

	setIsWithinCheckmarkRange() {
		const percentageAllocated = this.fractionAllocated - 1;
		const factor = this.isOverAllocated ? 1 : -1;
		const heatmapLeniency = (HEATMAP_CHECKMARK_LENIENCY_PERCENTAGE * factor) / 100;

		this.isWithinCheckmarkRange = this.isOverAllocated
			? percentageAllocated < heatmapLeniency
			: percentageAllocated > heatmapLeniency;
	}

	getAllocatedBarHeight() {
		if (!this.allocatedBarHeight) {
			const {height} = this;
			const fraction = this.isOverAllocated ? this.fractionOverAllocated : this.fractionAllocated;
			this.allocatedBarHeight = Math.round(Util.clamp(height * fraction, 0, height));
		}

		return this.allocatedBarHeight;
	}

	getHardAllocationBarHeight() {
		if (!this.hardAllocatedBarHeight) {
			const {height} = this;
			this.hardAllocatedBarHeight = Math.round(Util.clamp(height * this.fractionHardAllocated, 0, height));
		}

		return this.hardAllocatedBarHeight;
	}

	getSoftAllocationBarHeight() {
		if (!this.softAllocatedBarHeight) {
			const {height} = this;
			this.softAllocatedBarHeight = Math.round(Util.clamp(height * this.fractionSoftAllocated, 0, height));
		}

		return this.softAllocatedBarHeight;
	}

	getHoursToShow() {
		if (!this.hoursToShow) {
			const {minutesAvailable} = this.data;
			const minutesToShow = Math.abs(minutesAvailable - this.plannedTotalMinutesAllocated);
			this.hoursToShow = Util.convertMinutesToFullHour(minutesToShow, this.intl);
		}
		return this.hoursToShow;
	}

	getAllocationText() {
		if (!this.allocatedText) {
			const translationId = this.isOverAllocated ? 'scheduling.heatmap_time_over' : 'scheduling.heatmap_time_left';
			this.allocatedText = getCachedMessage(this.intl, translationId);
		}

		return this.allocatedText;
	}

	getPercentageToShow() {
		if (!this.percentageToShow) {
			const percentageAllocated = this.hasMinutesAvailable ? Math.round(this.fractionAllocated * 100) : 0;
			this.percentageToShow = Math.min(percentageAllocated, 200) + (percentageAllocated > 200 ? '+%' : '%');
		}

		return this.percentageToShow;
	}

	getHeatmapText(canvasContext) {
		if (!this.heatmapText) {
			if (this.isWithinCheckmarkRange) {
				return getCachedMessage(this.intl, 'scheduling.heatmap_completion_full');
			} else {
				const {schedulingOptions} = this.pageComponent.state;
				const {utilizationFormat} = schedulingOptions;

				const showInPercentage = utilizationFormat === UTILIZATION_FORMAT.PERCENTAGE;
				const maxTextWidth = this.width - HEATMAP_CELL_PADDING_X;

				const text =
					showInPercentage && this.isUtilizationHeatmap()
						? this.getPercentageToShow()
						: `${this.getHoursToShow()} ${this.getAllocationText()}`;

				this.heatmapText = getTrimmedText(canvasContext, text, maxTextWidth);
			}
		}

		return this.heatmapText;
	}

	setHeatmapFont(canvasContext) {
		canvasContext.font = `700 ${HEATMAP_FONT_SIZE}px ` + Util.getFontFamily();
		canvasContext.fillStyle = this.config.getFontColor();
	}

	drawDayOffPattern(canvasContext) {
		const {data, height} = this;
		const {y} = data;

		canvasContext.fillStyle = canvasContext.createPattern(
			cacheManager.getCommonImageSafariWorkaround(COMMON_IMAGE.FREEDAY),
			'repeat'
		);

		canvasContext.fillRect(this.xPos, y, this.width, height - 1);
	}

	drawTimeOff(canvasContext) {
		const {data, height} = this;
		const {y} = data;

		drawRectangle(canvasContext, this.xPos, y, this.width, height, {
			backgroundColor: HEATMAP_TIME_OFF_BACKGROUND_COLOR,
		});

		const fontSize = 11;
		canvasContext.font = `600 ${fontSize}px ` + Util.getFontFamily();
		canvasContext.fillStyle = GROUP_SECTION_TEXT_GREY;

		const text = getCachedMessage(this.intl, 'common.time_off');
		const textWidth = canvasContext.measureText(text).width;

		const yCenter = y + height / 2 + fontSize / 3;
		const xCenter = this.xPos + (this.width - textWidth) / 2;

		canvasContext.fillText(text, xCenter, yCenter);
	}

	drawAllocatedBackground(canvasContext) {
		const {data, height} = this;
		const {y, minutesAvailable} = data;

		const heatmapBackgroundColor = this.config.getBackgroundColor(this);
		const barColor = this.config.getBarColor(this);

		if (minutesAvailable <= 0 && this.plannedTotalMinutesAllocated > 0) {
			drawRectangle(canvasContext, this.xPos, y, this.width, height, {
				backgroundColor: barColor || heatmapBackgroundColor,
			});
		} else {
			drawRectangle(canvasContext, this.xPos, y, this.width, height, {
				backgroundColor: heatmapBackgroundColor,
			});

			if (barColor !== null) {
				if (this.hasSoftHardTotalMinutes) {
					let hardAllocationBarHeight = this.getHardAllocationBarHeight();
					let softAllocationBarHeight = this.getSoftAllocationBarHeight();

					if (this.isWithinCheckmarkRange || this.fractionAllocated > 2) {
						const hasHardAllocated = hardAllocationBarHeight > 0;
						const hasSoftAllocated = softAllocationBarHeight > 0;
						const heightDifference = height - hardAllocationBarHeight - softAllocationBarHeight;

						if (hasHardAllocated && hasSoftAllocated) {
							const dividedDifference = heightDifference / 2;
							hardAllocationBarHeight += dividedDifference;
							softAllocationBarHeight += dividedDifference;
						} else if (hasHardAllocated) {
							hardAllocationBarHeight += heightDifference;
						} else if (hasSoftAllocated) {
							softAllocationBarHeight += heightDifference;
						}
					}

					if (hardAllocationBarHeight > 0) {
						const hardAllocatedBar = {
							width: this.width,
							height: hardAllocationBarHeight,
							y: y + height - hardAllocationBarHeight,
						};

						drawRectangle(
							canvasContext,
							this.xPos,
							hardAllocatedBar.y,
							hardAllocatedBar.width,
							hardAllocatedBar.height,
							{
								backgroundColor: barColor,
							}
						);
					}

					if (softAllocationBarHeight > 0) {
						const softAllocatedBar = {
							width: this.width,
							height: softAllocationBarHeight,
							y: y + height - hardAllocationBarHeight - softAllocationBarHeight,
						};

						drawSoftAllocatedArea(
							canvasContext,
							this.xPos,
							softAllocatedBar.y,
							softAllocatedBar.height,
							softAllocatedBar.width,
							barColor,
							this.config.getSoftStripeBackgroundColor(this)
						);
					}
				} else {
					const allocatedBarHeight = this.isWithinCheckmarkRange ? height : this.getAllocatedBarHeight();

					if (allocatedBarHeight > 0) {
						const allocatedBar = {
							width: this.width,
							height: allocatedBarHeight,
							y: y + height - allocatedBarHeight,
						};

						drawRectangle(canvasContext, this.xPos, allocatedBar.y, allocatedBar.width, allocatedBar.height, {
							backgroundColor: barColor,
						});
					}
				}
			}
		}
	}

	drawAllocatedText(canvasContext) {
		const {data, height} = this;
		const {y} = data;

		this.setHeatmapFont(canvasContext);

		const iconTextSpacing = this.isWithinCheckmarkRange ? 5 : 0;
		const iconSize = this.isWithinCheckmarkRange ? 14 : 0;
		const totalIconWidth = iconSize + iconTextSpacing;

		const heatmapText = this.getHeatmapText(canvasContext);
		const textWidth = canvasContext.measureText(heatmapText).width + totalIconWidth;

		const heatmapTextX = this.xPos + this.width / 2 - textWidth / 2 + totalIconWidth;
		const heatmapTextY = y + height / 2 + HEATMAP_FONT_SIZE / 3;

		canvasContext.fillText(heatmapText, heatmapTextX, heatmapTextY);

		if (this.isWithinCheckmarkRange) {
			const doneIconXPos = this.xPos + this.width / 2 - textWidth / 2;
			drawDoneIcon(canvasContext, doneIconXPos, y + height / 2 - iconSize / 2, iconSize, this.config.getFontColor());
		}
	}

	drawHeatmapDemand(canvasContext) {
		const {data, height} = this;
		const {y} = data;

		if (this.isOverAllocated) {
			drawBackground(canvasContext, this.xPos, y, this.width, height, false, HEATMAP_OVER_ALLOCATED_COMPLETION_COLOR);
		}

		this.setHeatmapFont(canvasContext);

		const maxTextWidth = this.width - HEATMAP_CELL_PADDING_X;

		const rawText = this.minutesDemand !== 0 ? Util.convertMinutesToFullHour(this.minutesDemand, this.intl) : '-';
		const text = getTrimmedText(canvasContext, rawText, maxTextWidth);
		const textWidth = canvasContext.measureText(text).width;

		canvasContext.fillText(text, this.xPos + this.width / 2 - textWidth / 2, y + height / 2 + 4);
	}
}

export default HeatmapItem;
