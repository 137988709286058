/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExpenseRow_phaseBaselineExpense$ref: FragmentReference;
declare export opaque type ExpenseRow_phaseBaselineExpense$fragmentType: ExpenseRow_phaseBaselineExpense$ref;
export type ExpenseRow_phaseBaselineExpense = {|
  +id: string,
  +phaseId: ?number,
  +expenseCategory: ?{|
    +id: string,
    +name: ?string,
  |},
  +expenseCost: ?number,
  +expenseMarkup: ?number,
  +expenseRevenue: ?number,
  +expenseProfit: ?number,
  +$refType: ExpenseRow_phaseBaselineExpense$ref,
|};
export type ExpenseRow_phaseBaselineExpense$data = ExpenseRow_phaseBaselineExpense;
export type ExpenseRow_phaseBaselineExpense$key = {
  +$data?: ExpenseRow_phaseBaselineExpense$data,
  +$fragmentRefs: ExpenseRow_phaseBaselineExpense$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExpenseRow_phaseBaselineExpense",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phaseId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExpenseCategory",
      "kind": "LinkedField",
      "name": "expenseCategory",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expenseCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expenseMarkup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expenseRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expenseProfit",
      "storageKey": null
    }
  ],
  "type": "PhaseBaselineExpenseType"
};
})();
// prettier-ignore
(node/*: any*/).hash = '57e9921612ffb7d4945942e5876c037c';

module.exports = node;
