import React, {Component} from 'react';
import {Pie} from 'react-chartjs-2';
import Util from '../../forecast-app/shared/util/util';

import {Chart as ChartJS, ArcElement} from 'chart.js';

ChartJS.register(ArcElement);

class ChartJsPie extends Component {
	render() {
		const options = {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					position: 'right',
				},
				tooltip: {
					callbacks: {
						label: ctx => {
							const dataset = ctx.dataset;
							const total = dataset.data.reduce(
								(previousValue, currentValue, currentIndex, array) => previousValue + currentValue
							);
							const currentValue = ctx.parsed;
							const percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
							return percentage + '%';
						},
					},
				},
				datalabels: {
					display: context => context.dataset.data[context.dataIndex] > 0,
					formatter: (value, context) => {
						const total = context.dataset.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

						return parseFloat(((value / total) * 100).toFixed(1)) + '%';
					},
					color: context => Util.getTextColor(context.dataset.backgroundColor[context.dataIndex]),
				},
			},
		};
		return (
			<div>
				<Pie data={this.props.data} options={options} />
			</div>
		);
	}
}
export default ChartJsPie;
