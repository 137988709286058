import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import InsightList from './list';
import Person from '../../../forecast-app/shared/components/person/person';
import Util from '../../../forecast-app/shared/util/util';

class PeopleListPortfolioDummy extends Component {
	createDummyList(
		id,
		name,
		defaultRole,
		email,
		labels,
		skills,
		permission,
		planningProjects,
		runningProjects,
		haltedProjects,
		doneProjects,
		totalProjects,
		todoCards,
		inProgressCards,
		doneCards,
		totalCards,
		remaining,
		reported,
		spend
	) {
		const {formatNumber} = this.props.intl;
		const person = {};
		person.name = <Person key={id} name={name} showName={true} showRole={false} imageSrc={null} imageSize="medium" />;
		person.defaultRole = defaultRole;
		person.email = email;
		person.labels = labels;
		person.skills = skills;
		person.permission = permission;
		person.planningProjects = formatNumber(planningProjects);
		person.runningProjects = formatNumber(runningProjects);
		person.haltedProjects = formatNumber(haltedProjects);
		person.doneProjects = formatNumber(doneProjects);
		person.totalProjects = formatNumber(totalProjects);
		person.todoCards = formatNumber(todoCards);
		person.inProgressCards = formatNumber(inProgressCards);
		person.doneCards = formatNumber(doneCards);
		person.totalCards = formatNumber(totalCards);
		person.remaining = Util.convertMinutesToFullHour(remaining * 60, this.props.intl);
		person.reported = Util.convertMinutesToFullHour(reported * 60, this.props.intl);
		person.spend = '$' + formatNumber(spend);
		return person;
	}
	render() {
		const people = [];
		people.push(
			this.createDummyList(
				1,
				'Person one',
				'Developer',
				'mail@mymail.com',
				[
					{name: 'Java', color: '#4B9600'},
					{name: 'Python', color: '#48B8FF'},
				],
				['Java', 'Python'],
				'Controller',
				2,
				1,
				0,
				0,
				3,
				12,
				20,
				5,
				37,
				40,
				10,
				500
			)
		);
		people.push(
			this.createDummyList(
				2,
				'Person two',
				'Project Manager',
				'mail@mymail.com',
				[
					{name: 'SCRUM', color: '#ED7D1A'},
					{name: 'Agile', color: '#F99693'},
				],
				['SCRUM', 'Agile'],
				'Admin',
				2,
				1,
				0,
				0,
				3,
				12,
				20,
				5,
				37,
				40,
				10,
				500
			)
		);
		people.push(
			this.createDummyList(
				3,
				'Person three',
				'Developer',
				'mail@mymail.com',
				[
					{name: 'Java', color: '#4B9600'},
					{name: 'Python', color: '#48B8FF'},
				],
				['Java', 'Python'],
				'Controller',
				2,
				1,
				0,
				0,
				3,
				12,
				20,
				5,
				37,
				40,
				10,
				500
			)
		);

		return (
			<InsightList
				data={people}
				componentName={this.props.componentName}
				allColumns={this.props.allColumns}
				activeColumns={this.props.activeColumns}
				disableSort="skills"
				scrollElement={this.props.scrollElement}
				notifyOnReady={this.props.notifyOnReady}
			/>
		);
	}
}

PeopleListPortfolioDummy.propTypes = {};
export default injectIntl(PeopleListPortfolioDummy);
