import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';

export const SimpleTextInputTitle = ({children}) => {
	return <TitleStyle>{children}</TitleStyle>;
};

SimpleTextInputTitle.propTypes = {
	children: PropTypes.string.isRequired,
};

const TitleStyle = Styled.div`
    padding: 0;
    margin: 0;
    color: #535353;
    font-size: 20px;
	line-height: 28px;
    font-weight: 500;
    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
    max-width: 300px;
`;
