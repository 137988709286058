import React from 'react';
import {getGradients} from '../../util/ChartsUtil';
import {Chart} from 'web-components';
import {chartTokens} from '@forecast-it/design-system';
import {remapAndFormatFinancialMessage} from '../../../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import CompanySetupUtil from '../../../../../../forecast-app/shared/util/CompanySetupUtil';
import {hasFeatureFlag} from '../../../../../../forecast-app/shared/util/FeatureUtil';

const RevenueChart = ({
	project,
	data,
	hasBaseline,
	eyeOptionMap,
	modifyChartOptions,
	showFixedPriceGraphs,
	isUsingNewFixedPriceCalculation,
	isExpenseView,
}) => {
	const formatMessage = remapAndFormatFinancialMessage;
	const isEstimatedInPoints = project.estimationUnit === 'POINTS';
	const {
		billableActualTimeAndExpensesChartData,
		billablePlannedTimeAndExpensesChartData,
		billableTotalTimeAndExpensesAtCompletionChartData,
		baselineRevenueChartData,
		baselineTimeAndExpensesChartData,
		fixedPriceChartData,
		rateBasedBillableTotalTimeAndExpensesAtCompletionChartData,
		totalRevenueRecognitionChartData,
	} = data;

	const {
		gradientStroke,
		gradientFill,
		gradientStrokeActual,
		gradientFillActual,
		gradientStrokeFixedPrice,
		gradientStrokeBaseline,
		gradientStrokeVariance,
	} = getGradients();
	const dataSets = [];

	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');
	const chartColors = chartTokens.chart.color;

	// Baseline Value of Service
	if (hasBaseline && eyeOptionMap.baseline) {
		dataSets.unshift({
			name: formatMessage({
				id: hasFinancialCategoriesUpdate
					? 'baseline.value_of_service'
					: isExpenseView
					? 'project_budget.baseline_expenses'
					: 'baseline.baseline',
			}),
			color: hasFinancialCategoriesUpdate ? chartColors['4'] : gradientStrokeBaseline,
			filled: !hasFinancialCategoriesUpdate,
			backgroundColor: 'transparent',
			data: hasFinancialCategoriesUpdate ? baselineTimeAndExpensesChartData : baselineRevenueChartData,
			dashed: !hasFinancialCategoriesUpdate,
			borderWidth: hasFinancialCategoriesUpdate ? 1 : undefined,
		});
	}

	if (!hasFinancialCategoriesUpdate && showFixedPriceGraphs && fixedPriceChartData.length > 0) {
		dataSets.unshift({
			name: formatMessage({id: 'common.fixed_price'}),
			color: gradientStrokeFixedPrice,
			backgroundColor: isUsingNewFixedPriceCalculation ? 'transparent' : '#fff',
			data: fixedPriceChartData,
		});
	}

	// Estimated Billable Value of Service
	if (!(hasBaseline && hasFinancialCategoriesUpdate) && !isEstimatedInPoints && eyeOptionMap.plan) {
		dataSets.unshift({
			name: formatMessage({
				id: isExpenseView
					? 'project_budget.planned_billable_expenses'
					: CompanySetupUtil.hasExpenses()
					? 'project_budget.planned_billable_time_and_expenses'
					: 'project_budget.planned_billable_time',
			}),
			color: hasFinancialCategoriesUpdate ? chartColors['4'] : gradientStroke,
			filled: true,
			backgroundColor: isUsingNewFixedPriceCalculation ? 'transparent' : gradientFill,
			data: billablePlannedTimeAndExpensesChartData,
			borderWidth: hasFinancialCategoriesUpdate ? 1 : undefined,
		});
	}

	if (billableActualTimeAndExpensesChartData.length && eyeOptionMap.actual) {
		dataSets.unshift({
			name: formatMessage({
				id: isExpenseView
					? 'project_budget.actual_billable_expenses_to_date'
					: CompanySetupUtil.hasExpenses()
					? 'project_budget.actual_billable_time_and_expenses'
					: 'project_budget.actual_billable_time',
			}),
			color: gradientStrokeActual,
			filled: true,
			backgroundColor: isUsingNewFixedPriceCalculation ? 'transparent' : gradientFillActual,
			data: billableActualTimeAndExpensesChartData,
		});
	}

	// Projected Total Billable Value of Service
	if (
		showFixedPriceGraphs &&
		isUsingNewFixedPriceCalculation &&
		rateBasedBillableTotalTimeAndExpensesAtCompletionChartData.length
	) {
		dataSets.unshift({
			name: formatMessage({id: 'project_budget.rate_based_forecast_revenue'}),
			color: hasFinancialCategoriesUpdate ? chartColors['3'] : gradientStrokeVariance,
			backgroundColor: isUsingNewFixedPriceCalculation ? 'transparent' : '#fff',
			data: rateBasedBillableTotalTimeAndExpensesAtCompletionChartData,
			borderWidth: hasFinancialCategoriesUpdate ? 3 : undefined,
		});
	}

	// Projected Total Revenue
	if (showFixedPriceGraphs && isUsingNewFixedPriceCalculation && totalRevenueRecognitionChartData.length) {
		dataSets.unshift({
			name: formatMessage({id: 'project_budget.recognition_amount'}),
			color: hasFinancialCategoriesUpdate ? chartColors['2'] : gradientStrokeActual,
			filled: true,
			backgroundColor: isUsingNewFixedPriceCalculation ? 'transparent' : gradientFillActual,
			data: totalRevenueRecognitionChartData,
		});
	}

	if (billableTotalTimeAndExpensesAtCompletionChartData.length && eyeOptionMap.forecast && !isUsingNewFixedPriceCalculation) {
		dataSets.unshift({
			name: formatMessage({id: 'project_budget.forecast_revenue'}),
			color: gradientStrokeActual,
			filled: true,
			backgroundColor: 'transparent',
			data: billableTotalTimeAndExpensesAtCompletionChartData,
			dashed: true,
		});
	}

	return <Chart dataSets={dataSets} modifyOptions={modifyChartOptions} usePointStyle={hasFinancialCategoriesUpdate} />;
};

export default RevenueChart;
