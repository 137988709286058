import {TABLE_TYPE} from './BudgetUtils';
import {remapId} from '../../../../../forecast-app/shared/util/FinancialInternationalisationUtil';
import CompanySetupUtil from '../../../../../forecast-app/shared/util/CompanySetupUtil';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';

const translationMap = {
	cost: 'project_budget.cost',
	actualCost: 'project_budget.cost',
	planVsActualCost: 'project_budget.cost',
	baselineCost: 'project_budget.cost',
	baselineVsActualRevenueRecognition: 'project_budget.revenue',
	baselineVsActualCost: 'project_budget.cost',
	baselineVsTotalRevenueAtCompletion: 'project_budget.revenue',
	baselineVsTotalCostAtCompletion: 'project_budget.cost',
	plannedCost: 'project_budget.cost',
	totalCostAtCompletion: 'project_budget.cost',
	planVsTotalRevenueAtCompletion: 'project_budget.revenue',
	planVsTotalCostAtCompletion: 'project_budget.cost',
	forecastCostToComplete: 'project_budget.cost',
	revenue: 'project_budget.revenue',
	billableValueOfService: 'common.billable_value_of_service',
	baselineTimeAndExpenses: 'common.billable_value_of_service',
	billableActualTimeAndExpenses: 'project_budget.billable_time_and_expenses',
	billableActualTimeAndExpensesInvoice: 'project_budget.actual_billable_time_and_expenses_short',
	baselineRevenue: 'project_budget.revenue',
	baselineVsBillableActualTimeAndExpenses: 'project_budget.billable_time_and_expenses',
	baselineVsBillableTotalTimeAndExpensesAtCompletion: 'project_budget.billable_time_and_expenses',
	billablePlannedTimeAndExpenses: 'project_budget.billable_time_and_expenses',
	planVsBillableActualTimeAndExpenses: 'project_budget.billable_time_and_expenses',
	billableTotalTimeAndExpensesAtCompletion: 'project_budget.billable_time_and_expenses',
	planVsTotalBillableTimeAndExpensesAtCompletion: 'project_budget.billable_time_and_expenses',
	billableForecastTimeAndExpensesToComplete: 'project_budget.billable_time_and_expenses',
	profit: 'project_budget.profit',
	actualProfit: 'project_budget.profit',
	actualRevenueProfit: 'project_budget.profit',
	actualMargin: 'project_budget.margin',
	actualRevenueMargin: 'project_budget.margin',
	totalActualRevenueRecognition: 'project_budget.revenue',
	actualRevenue: 'project_budget.revenue',
	baselineProfit: 'project_budget.profit',
	baselineMargin: 'project_budget.margin',
	baselineVsActualProfit: 'project_budget.profit',
	baselineVsActualRevenueProfit: 'project_budget.profit',
	baselineVsActualMargin: 'project_budget.margin',
	baselineVsActualRevenueMargin: 'project_budget.margin',
	baselineVsTotalProfitAtCompletion: 'project_budget.profit',
	baselineVsTotalRevenueProfitAtCompletion: 'project_budget.profit',
	baselineVsTotalRevenueMarginAtCompletion: 'project_budget.margin',
	planVsActualProfit: 'project_budget.profit',
	planVsActualRevenueProfit: 'project_budget.profit',
	planVsActualRevenueMargin: 'project_budget.margin',
	planVsActualRevenue: 'project_budget.revenue',
	planVsActualRevenueRecognition: 'project_budget.revenue',
	plannedProfit: 'project_budget.profit',
	plannedRevenueProfit: 'project_budget.profit',
	plannedMargin: 'project_budget.margin',
	plannedRevenueMargin: 'project_budget.margin',
	plannedRevenue: 'project_budget.revenue',
	totalProfitAtCompletion: 'project_budget.profit',
	totalRevenueProfitAtCompletion: 'project_budget.profit',
	totalRevenueMarginAtCompletion: 'project_budget.margin',
	planVsTotalProfitAtCompletion: 'project_budget.profit',
	planVsTotalRevenueProfitAtCompletion: 'project_budget.profit',
	planVsTotalRevenueMarginAtCompletion: 'project_budget.margin',
	totalRevenueRecognition: 'project_budget.revenue',
	forecastProfitToComplete: 'project_budget.profit',
	forecastRevenueProfitToComplete: 'project_budget.profit',
	forecastMarginToComplete: 'project_budget.margin',
	forecastRevenueMarginToComplete: 'project_budget.margin',
	totalForecastRevenueToComplete: 'project_budget.revenue',
	invoiced: 'project_budget.invoiced',
	accruedDeferred: 'project_budget.accrued',
	retainerPeriodTargetMinutes: 'project_budget.retainer_period_target_minutes',
	retainerPeriodTargetPrice: 'project_budget.retainer_period_target_price',
	margin: 'project_budget.margin',
	paid: 'project_budget.paid',
	registeredMinutes: 'project_budget.time_entries',
	billableActualMinutes: 'utilization.billable_time',
	nonBillableActualMinutes: 'utilization.non_billable_time',
	forecastTimeToComplete: 'project_budget.remainingMinutes',
	totalTimeAtCompletion: 'project_budget.total_time_at_completion',
	allocatedMinutes: 'project_budget.plan',
	baselineMinutes: 'common.hours',
	scopeApprovedMinutes: 'project_budget.estimated_scope',
	time: 'project_budget.time',
	baselineVsRegisteredMinutes: 'project_budget.time_entries',
	baselineVsTotalTimeAtCompletion: 'project_budget.time_entries',
	registeredVsScopeApprovedMinutes: 'common.variance',
	totalTimeAtCompletionVsScopeApprovedMinutes: 'common.variance',
	scopeApprovedVsRegisteredMinutes: 'common.variance',
	scopeApprovedVsTotalTimeAtCompletion: 'common.variance',
	billableTime: 'project_budget.billable_time_spent',
	billableTimeAccumulated: 'project_budget.billable_time_spent_accumulated',
	recognitionPercentage: 'project_budget.recognition_percentage',
	recognitionManualPercentage: 'project_budget.recognition_manual_percentage',
	expenseRevenuePercentage: 'project_budget.recognition_percentage',
	recognitionAmountRecognition: 'project_budget.recognition_amount.recognition_table',
	recognitionAmountTimeRecognition: 'project_budget.recognition_amount.recognition_time_table',
	recognitionAmountExpenseRecognition: 'project_budget.recognition_amount.recognition_expense_table',
	recognitionPercentageAccumulatedTimeRecognition: 'project_budget.recognition_percentage_accumulated.recognition_time_table',
	recognitionPercentageAccumulatedExpenseRecognition:
		'project_budget.recognition_percentage_accumulated.recognition_expense_table',
	recognitionAmountAccumulatedTimeRecognition:
		'project_budget.recognition_amount_accumulated.recognition_time_table.recognition_time_table',
	recognitionAmountAccumulatedExpenseRecognition:
		'project_budget.recognition_amount_accumulated.recognition_time_table.recognition_expense_table',
	surplus: 'project_budget.time_material_vs_recognition',
	surplusPercentage: 'project_budget.surplus_percentage',
	estimatedCostRecognition: 'project_budget.estimated_cost.recognition_table',
	estimatedCostTimeRecognition: 'project_budget.estimated_cost.recognition_time_table',
	estimatedCostExpenseRecognition: 'project_budget.estimated_cost.recognition_expense_table',
	estimatedCostPercentage: 'project_budget.estimated_cost_percentage.recognition_time_table',
	expenseCostPercentage: 'project_budget.estimated_cost_percentage.recognition_expense_table',
	estimatedCostAccumulatedTimeRecognition: 'project_budget.estimated_cost_accumulated.recognition_time_table',
	estimatedCostAccumulatedExpenseRecognition: 'project_budget.estimated_cost_accumulated.recognition_expense_table',
	estimatedCostPercentageAccumulated: 'project_budget.estimated_cost_percentage_accumulated.recognition_time_table',
	expenseCostPercentageAccumulated: 'project_budget.estimated_cost_percentage_accumulated.recognition_expense_table',
	recognitionProfit: 'project_budget.recognition_profit',
	recognitionProfitPercentage: 'project_budget.recognition_profit_percentage',
	expenses: 'project_budget.expenses',
	recognitionTotal: 'project_budget.recognition_amount',
	totalProfitAtCompletionTimeRecognition: 'project_budget.recognition_profit',
	totalMarginAtCompletion: 'project_budget.recognition_profit_percentage',
	forecastMarginTimeRecognition: 'project_budget.recognition_profit_percentage',
	recognitionLockedRevenue: 'project_budget.revenue_recognition_from_locked_months',
	recognitionLockedRevenueInvoice: 'project_budget.locked_revenue_recognition',
	suggestedRevenue: 'project_budget.suggested_time_recognition',
	suggestedRevenuePercentage: 'project_budget.suggested_recognition_percentage',
	suggestedProfit: 'project_budget.recognition_profit',
	suggestedProfitPercentage: 'project_budget.recognition_profit_percentage',
};

const newFinancialsCategoriesMap = {
	[TABLE_TYPE.TIME_DETAILS]: {
		scopeApprovedMinutes: 'common.hours',
		registeredMinutes: 'common.hours',
		forecastTimeToComplete: 'common.hours',
		totalTimeAtCompletion: 'common.hours',
		baselineVsRegisteredMinutes: 'common.hours',
		baselineVsTotalTimeAtCompletion: 'common.hours',
		scopeApprovedVsRegisteredMinutes: 'common.hours',
		scopeApprovedVsTotalTimeAtCompletion: 'common.hours',
	},
};

const getTranslationId = (rowName, tableType) => {
	const rowsWithDifferentLabels = [
		'recognitionAmount',
		'recognitionAmountAccumulated',
		'recognitionPercentageAccumulated',
		'estimatedCost',
		'estimatedCostAccumulated',
		'totalProfitAtCompletion',
		'billableActualTimeAndExpenses',
		'recognitionLockedRevenue',
	];

	const revenueRows = [
		'revenue',
		'billablePlannedTimeAndExpenses',
		'billableActualTimeAndExpenses',
		'billableForecastTimeAndExpensesToComplete',
		'billableTotalTimeAndExpensesAtCompletion',
		'planVsBillableActualTimeAndExpenses',
		'planVsTotalBillableTimeAndExpensesAtCompletion',
	];

	if (revenueRows.includes(rowName) && !CompanySetupUtil.hasExpenses()) {
		return 'project_budget.billable_time';
	}

	if (hasFeatureFlag('financial_categories_update')) {
		const messageId = newFinancialsCategoriesMap[tableType]?.[rowName];
		if (messageId) {
			return messageId;
		}
	}

	let tableSuffix = '';
	if (rowsWithDifferentLabels.includes(rowName)) {
		switch (tableType) {
			case TABLE_TYPE.REVENUE_RECOGNITION:
				tableSuffix = 'Recognition';
				break;
			case TABLE_TYPE.TIME_REVENUE_RECOGNITION:
				tableSuffix = 'TimeRecognition';
				break;
			case TABLE_TYPE.EXPENSE_REVENUE_RECOGNITION:
				tableSuffix = 'ExpenseRecognition';
				break;
			case TABLE_TYPE.INVOICE_DETAILS:
				tableSuffix = 'Invoice';
				break;
		}
	}

	const translatedMessageId = translationMap.hasOwnProperty(rowName + tableSuffix)
		? translationMap[rowName + tableSuffix]
		: translationMap[rowName];

	const rowsSkippingFurtherTranslations = hasFeatureFlag('financial_categories_update')
		? [
				'revenue',
				'totalActualRevenueRecognition',
				'plannedRevenue',
				'totalForecastRevenueToComplete',
				'baselineRevenue',
				'totalRevenueRecognition',
				'baselineVsTotalRevenueAtCompletion',
				'baselineVsActualRevenueRecognition',
				'planVsActualRevenue',
				'planVsTotalRevenueAtCompletion',
		  ]
		: ['baselineRevenue'];
	if (rowsSkippingFurtherTranslations.includes(rowName)) {
		return translatedMessageId;
	}

	return remapId(translatedMessageId);
};

const getAggregateRowTitle = (intl, rowName, tableType) => {
	return rowName ? intl.formatMessage({id: getTranslationId(rowName, tableType) || rowName}) : '';
};

export default getAggregateRowTitle;
