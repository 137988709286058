import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';
import FlexModal from '../containers/modal/flex_modal';
import {pathIncludesTask, removeTaskLinkFromUrl} from '../forecast-app/shared/util/UrlUtil';

class BoundaryErrorModal extends Component {
	defaultCallback() {
		if (this.props.modalType === 'task') {
			const path = window.location.pathname;
			if (pathIncludesTask(path)) {
				// Task modal is open. Redirect.
				const newUrl = removeTaskLinkFromUrl(path);
				this.props.history.push(newUrl);
			}
		}
		this.props.closeModal();
	}

	render() {
		return (
			<FlexModal
				closeModal={this.defaultCallback.bind(this)}
				headerText={this.props.intl.formatMessage({id: 'mutation_error_modal.header'})}
				descriptionText={this.props.intl.formatMessage({id: 'mutation_error_modal.description'})}
				options={[{label: this.props.intl.formatMessage({id: 'common.ok'})}]}
				defaultCallback={this.defaultCallback.bind(this)}
			/>
		);
	}
}

export default injectIntl(withRouter(BoundaryErrorModal));
