/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type Phase_phase$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type PhasesSection_phases$ref: FragmentReference;
declare export opaque type PhasesSection_phases$fragmentType: PhasesSection_phases$ref;
export type PhasesSection_phases = $ReadOnlyArray<{|
  +node: ?{|
    +id: string,
    +startYear: ?number,
    +startMonth: ?number,
    +startDay: ?number,
    +deadlineYear: ?number,
    +deadlineMonth: ?number,
    +deadlineDay: ?number,
    +$fragmentRefs: Phase_phase$ref,
  |},
  +$refType: PhasesSection_phases$ref,
|}>;
export type PhasesSection_phases$data = PhasesSection_phases;
export type PhasesSection_phases$key = $ReadOnlyArray<{
  +$data?: PhasesSection_phases$data,
  +$fragmentRefs: PhasesSection_phases$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PhasesSection_phases",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PhaseType",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deadlineYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deadlineMonth",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deadlineDay",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Phase_phase"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PhaseTypeEdge"
};
// prettier-ignore
(node/*: any*/).hash = 'a3a1e41b337a74ca98ada39941800997';

module.exports = node;
