import * as tracking from '../../tracking';
import Util from '../../forecast-app/shared/util/util';
import 'whatwg-fetch'; //IE 11 fetch polyfill
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import DirectApi from '../../directApi';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import {dispatch, EVENT_ID} from '../../containers/event_manager';
import {hasModule} from '../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../constants';
import {trackCustomNameMutation} from '../../tracking/amplitude/TrackMutation';

const INTERNAL_ERROR_PREFIX = 'Internal Error: ';
const NOT_FOUND_PREFIX = '404: ';

const sendMutation = (query, input, onSuccess = () => {}) => {
	input.csrfToken = Util.getCsrfValue();
	input.socketClientId = Util.getClientId();
	fetch(DirectApi.graphqlServerEndpoint('graphql'), {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		credentials: 'include',
		body: JSON.stringify({
			query,
			variables: {
				input,
			},
		}),
	})
		.then(r => r.json())
		.then(response => {
			const responseError =
				response.data?.updateAllocation?.errors ||
				response.data?.deleteAllocation?.errors ||
				response.data?.createAllocation?.errors;
			if (responseError && responseError.length === 1 && responseError[0] === 'TIME_REGISTRATION_IN_LOCKED_PERIOD') {
				showModal({
					type: MODAL_TYPE.WARNING_DATE_LOCKED,
					nonTimeRegWarning: true,
				});
			} else if (response.errors && response.errors.length > 0) {
				let correlationId;
				let errorType;
				let onCloseModal;
				const internalError = response.errors.find(e => e.message && e.message.startsWith(INTERNAL_ERROR_PREFIX));
				const notFoundError = response.errors.find(e => e.message && e.message.startsWith(NOT_FOUND_PREFIX));
				let type = MODAL_TYPE.MUTATION_ERROR;
				if (internalError) {
					correlationId = internalError.message.split(INTERNAL_ERROR_PREFIX)[1];
				} else if (notFoundError) {
					correlationId = notFoundError.message.split(NOT_FOUND_PREFIX)[1];
					errorType = '404';
					onCloseModal = () => {
						dispatch(EVENT_ID.SCHEDULING_RELOAD_DATA);
					};
					type = MODAL_TYPE.MUTATION_ERROR_NON_CRITICAL;
				}
				showModal({
					type,
					errorType,
					correlationId,
					onCloseModal,
				});
			}
			onSuccess(response.data);
		});
};

export const createClient = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Client Created');
	trackCustomNameMutation('Create Client', input);
	const query = `mutation CreateClient($input: CreateClientInput!) {
				createClient(input: $input) {
   	 				client {
   	 					node {
	   	 					id
	   	 					name
	   	 				}
   	 				}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const createDependency = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Dependency Created', {_Type: input.type});
	trackCustomNameMutation('Create Dependency', input);
	const query = `mutation CreateDependency($input: CreateDependencyInput!) {
				createDependency(input: $input) {
					errors
   	 				dependency {
   	 					node {
	   	 					id
	   	 					type
	   	 					thisDependsOnTask {
	   	 						id
	   	 					}
	   	 					taskDependsOnThis {
	   	 						id
	   	 					}
	   	 				}
   	 				}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const deleteDependency = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Dependency Deleted', {_Type: input.type});
	trackCustomNameMutation('Delete Dependency', input);
	const query = `mutation DeleteDependency($input: DeleteDependencyInput!) {
				deleteDependency(input: $input) {
					deletedDependencyId
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const shiftDependencyChain = (input, onSuccess = () => {}) => {
	const query = `mutation ShiftDependencyChain($input: ShiftDependencyChainInput!) {
				shiftDependencyChain(input: $input) {
					updatedTasksIds
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const createTask = (input, onSuccess = () => {}) => {
	Util.localStorageDeleteFincancialMap();
	tracking.trackEvent('Card Created');
	trackCustomNameMutation('Create Task', input);
	const query = `mutation CreateTask($input: CreateTaskInput!) {
				createTask(input: $input) {
   	 				task {
   	 					node {
	   	 					id
	   	 					approved
	   	 					assignedPersons {
	   	 						id
	   	 					}
	   	 					billable
	   	 					blocked
	   	 					bug
	   	 					companyTaskId
	   	 					startDay
	   	 					startMonth
	   	 					startYear
	   	 					deadlineDay
	   	 					deadlineMonth
	   	 					deadlineYear
	   	 					done
	   	 					estimateForecast
	   	 					estimateForecastMinutes
	   	 					highPriority
	   	 					progress
							userCanDeleteTask
							userCantDeleteTaskReason
	   	 					project {
	   	 						id
	   	 						completion
	   	 						remaining
	   	 						forecast
	   	 						projectPersons(first: 10000) {
									edges {
										node {
											id
											person {
												id
											}
											project {
												id
											}
										}
									}
								}
	   	 					}
	   	 					role {
	   	 						id
	   	 					}
	   	 					phase {
	   	 						id
	   	 					}
	   	 					timeLeft
							timeLeftMinutesWithoutFutureTimeRegs
	   	 					name
	   	 					statusColumnV2 {
	   	 						id
	   	 					}
	   	 				}
   	 				}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const duplicateTask = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Card Duplicated');
	trackCustomNameMutation('Duplicate Task', input);
	const query = `mutation DuplicateTask($input: DuplicateTaskInput!) {
				duplicateTask(input: $input) {
					errors
   	 				task {
   	 					node {
	   	 					id
	   	 					approved
	   	 					assignedPersons {
	   	 						id
	   	 					}
	   	 					billable
	   	 					blocked
	   	 					bug
	   	 					companyTaskId
	   	 					startDay
	   	 					startMonth
	   	 					startYear
	   	 					deadlineDay
	   	 					deadlineMonth
	   	 					deadlineYear
	   	 					done
	   	 					estimateForecast
	   	 					estimateForecastMinutes
	   	 					highPriority
							progress
							userCanDeleteTask
							userCantDeleteTaskReason
	   	 					project {
	   	 						id
	   	 					}
	   	 					role {
	   	 						id
	   	 					}
	   	 					phase {
	   	 						id
	   	 					}
	   	 					statusColumnV2 {
	   	 						id
	   	 					}
	   	 					timeLeft
							timeLeftMinutesWithoutFutureTimeRegs
	   	 					name
							dependsOnThisTask(first: 1000) {
								edges {
									node {
										id
										type
										taskDependsOnThis {
											id
										}
										thisDependsOnTask {
											id
										}
									}
								}
							}
							thisTaskDependsOn(first: 1000) {
								edges {
									node {
										id
										type
										taskDependsOnThis {
											id
										}
										thisDependsOnTask {
											id
										}
									}
								}
							}
	   	 					
	   	 				}
   	 				}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const createAllocation = (input, onSuccess = () => {}) => {
	let on = 'Project';
	if (input.idleTimeId) {
		on = 'Non Project Time';
	} else if (input.projectGroupId) {
		on = 'Connected Project';
	}
	tracking.trackEvent('Allocation Created', {_On: on, _QuickBooking: false});
	trackCustomNameMutation('Create Allocation', input);

	const query = `mutation CreateAllocation($input: CreateAllocationInput!) {
				createAllocation(input: $input) {
   	 				allocation {
   	 					node {
	   	 					id
	   	 					monday
	   	 					tuesday
	   	 					wednesday
	   	 					thursday
	   	 					friday
	   	 					saturday
	   	 					sunday
	   	 					projectGroupId
	   	 					projectGroupColor
	   	 					description
	   	 					startYear
	   	 					startMonth
	   	 					startDay
	   	 					endYear
	   	 					endMonth
	   	 					endDay
   	 						registerTime
   	 						isSoft
	   	 					project {
	   	 						id
	   	 					}
	   	 					idleTime {
	   	 						id
	   	 						name
	   	 						isInternalTime
	   	 					}
	   	 					person {
	   	 						id
	   	 						role {
	   	 							id
	   	 						}
	   	 					}
	   	 				}
   	 				}
   	 				timeRegistrations {
   	 					id
						year
						month
						day
						canvasTimelineDate
						minutesRegistered
						createdAt
						updatedAt
						retainerConflictHandled
						allocationId
						person {
							id
						}
						idleTime {
							id
						}
					}
					project {
						id
						projectPersons(first: 10000) {
							edges {
								node {
									id
									person {
										id
									}
									project {
										id
									}
								}
							}
						}
					}
					projectGroup {
						id
						projects {
							edges {
								node {
									projectPersons(first: 10000) {
										edges {
											node {
												id
												person {
													id
												}
												project {
													id
												}
											}
										}
									}
								}
							}
						}
					}
					errors
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const updateAllocation = (input, onSuccess = () => {}) => {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['id'].includes(key) || input[key] === undefined) {
			continue;
		}

		changes.push(key);
	}

	tracking.trackEvent('Allocation Updated', {_Changed: changes});
	trackCustomNameMutation('Update Allocation', input, true);

	const query = `mutation UpdateAllocation($input: UpdateAllocationInput!) {
				updateAllocation(input: $input) {
   	 				allocation {
   	 					id
   	 					monday
   	 					tuesday
   	 					wednesday
   	 					thursday
   	 					friday
   	 					saturday
   	 					sunday
   	 					projectGroupId
   	 					projectGroupColor
   	 					description
   	 					startYear
   	 					startMonth
   	 					startDay
   	 					endYear
   	 					endMonth
   	 					endDay
   	 					registerTime
   	 					isSoft
   	 					project {
   	 						id
   	 					}
   	 					idleTime {
   	 						id
   	 						name
   	 						isInternalTime
   	 					}
   	 					person {
   	 						id
   	 						role {
   	 							id
   	 						}
   	 					}
   	 				}
   	 				timeRegistrations {
   	 					id
							year
							month
							day
							canvasTimelineDate
							minutesRegistered
							createdAt
							updatedAt
							retainerConflictHandled
							allocationId
							person {
								id
							}
							idleTime {
								id
							}
						}
					project {
						id
						projectPersons(first: 10000) {
							edges {
								node {
									id
									person {
										id
									}
									project {
										id
									}
								}
							}
						}
					}
					projectGroup {
						id
						projects {
							edges {
								node {
									projectPersons(first: 10000) {
										edges {
											node {
												id
												person {
													id
												}
												project {
													id
												}
											}
										}
									}
								}
							}
						}
					}
					errors
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const deleteAllocation = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Allocation Deleted');
	trackCustomNameMutation('Delete Allocation', input);

	const query = `mutation DeleteAllocation($input: DeleteAllocationInput!) {
				deleteAllocation(input: $input) {
						deletedAllocationId
						errors
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const deletePlaceholderAllocation = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Placeholder Allocation Deleted');
	trackCustomNameMutation('Delete Placeholder Allocation', input);

	const query = `mutation deletePlaceholderAllocationMutation($input: DeletePlaceholderAllocationInput!) {
		deletePlaceholderAllocation(input: $input) {
			deletedAllocationId
		}
	}`;

	sendMutation(query, input, onSuccess);
};

export const splitPlaceholderAllocationMutation = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Placeholder Allocation Split');
	trackCustomNameMutation('Split Placeholder Allocation', input);

	const query = `mutation splitPlaceholderAllocationMutation($input: SplitPlaceholderAllocationInput!) {
		splitPlaceholderAllocation(input: $input) {
			placeholderAllocations {
				id
				placeholder {
					id
				}
				description
				startDate
				endDate
				monday
				tuesday
				wednesday
				thursday
				friday
				saturday
				sunday
			}
		}
	}`;

	sendMutation(query, input, onSuccess);
};

export const duplicatePlaceholderAllocationMutation = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Placeholder Allocation Duplicate');
	trackCustomNameMutation('Duplicate Placeholder Allocation', input);

	const query = `mutation duplicatePlaceholderAllocationMutation($input: DuplicatePlaceholderAllocationInput!) {
		duplicatePlaceholderAllocation(input: $input) {
			placeholderAllocation {
				node {
					id
					placeholder {
						id
					}
					description
					startDate
					endDate
					monday
					tuesday
					wednesday
					thursday
					friday
					saturday
					sunday
				}
			}
		}
	}`;

	sendMutation(query, input, onSuccess);
};

export const updateTask = (input, onSuccess = () => {}) => {
	const changes = [];

	for (const key of Object.keys(input)) {
		if (['ids', 'id', 'aboveTaskOrder', 'viewerId'].includes(key) || input[key] === undefined) {
			continue;
		}

		if (key === 'aboveTaskId') {
			changes.push('sortOrder');
		} else {
			changes.push(key);
		}
	}

	tracking.trackEvent('Card Updated', {_Changed: changes});
	trackCustomNameMutation('Update Task', input, true);

	const query = `mutation UpdateTask($input: UpdateTaskInput!) {
				updateTask(input: $input) {
   	 				tasks {
   	 					id
   	 					startYear
   	 					startMonth
   	 					startDay
   	 					startFrom
   	 					deadlineYear
   	 					deadlineMonth
   	 					deadlineDay
   	 					deadlineFrom
   	 					approved
   	 					assignedPersons {
   	 						id
   	 					}
   	 					billable
   	 					blocked
   	 					bug
   	 					companyTaskId
   	 					done
   	 					estimateForecast
   	 					estimateForecastMinutes
   	 					totalMinutesRegistered
   	 					highPriority
   	 					progress
   	 					taskLabels {
   	 						label {
   	 							id
   	 						}
   	 					}
   	 					project {
							id
							completion
							remaining
							forecast
							projectPersons(first: 10000) {
								edges {
									node {
										id
										person {
											id
										}
										project {
											id
										}
									}
								}
							}
   	 					}
   	 					role {
   	 						id
   	 					}
   	 					phase {
   	 						id
   	 					}
   	 					timeLeft
						timeLeftMinutesWithoutFutureTimeRegs
   	 					name
   	 					statusColumnV2 {
   	 						id
						}
						userCanDeleteTask
						userCantDeleteTaskReason
						parentTaskId
   	 				}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const createPerson = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Person Created', {_ButtonLocation: 'Scheduling', _RoleSet: !!input.roleId});
	trackCustomNameMutation('Create Person', input);

	const query = `mutation CreatePerson($input: CreatePersonInput!) {
				createPerson(input: $input) {
					errors
   	 				person {
   	 					node {
	   	 					id
	   	 					firstName
	   	 					lastName
	   	 					email
	   	 					profilePictureId
	   	 					profilePictureDefaultId
	   	 					monday
	   	 					tuesday
	   	 					wednesday
	   	 					thursday
	   	 					friday
	   	 					saturday
	   	 					sunday
							permissions
							startDate
							endDate
	   	 					role {
	   	 						id
	   	 						name
	   	 					}
	   	 					personLabels(first: 10000) {
	   	 						edges {
	   	 							node {
	   	 								label {
	   	 									id
	   	 								}
	   	 							}
	   	 						}
	   	 					}
	   	 				}
   	 				}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

//TODO create, update, delete filter mutations

// const updateViewer = (input, onSuccess = () => {}) => {
// 	const query = `mutation UpdateViewer($input: UpdateViewerInput!) {
// 				updateViewer(input: $input) {
//    	 				viewer {
//    	 					savedFiltersJson
//    	 				}
// 				}
// 			}`;
// 	sendMutation(query, input, onSuccess);
// };

export const createFilter = (input, onSuccess = () => {}) => {};

export const updateFilter = (input, onSuccess = () => {}) => {};

export const deleteFilter = (input, onSuccess = () => {}) => {};

export const createProjectPerson = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Project Person Added');
	trackCustomNameMutation('Create Project Person', input);

	const query = `mutation CreateProjectPerson($input: CreateProjectPersonInput!) {
				createProjectPerson(input: $input) {
					project {
	   	 				projectPersons(first: 10000) {
	   	 					edges {
		   	 					node {
			   	 					id
			   	 					person {
			   	 						id
			   	 					}
			   	 					project {
			   	 						id
			   	 					}
			   	 				}
		   	 				}
	   	 				}
   	 				}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const createPhase = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Phase Created');
	trackCustomNameMutation('Create Phase', input);

	const query = `mutation CreatePhase($input: CreatePhaseInput!) {
				createPhase(input: $input) {
					phase {
						node {
							id
							deadlineDay
							deadlineMonth
							deadlineYear
							startDay
							startMonth
							startYear
							name
						}
					}
					project {
						id
					}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const createAllocations = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Multiple Allocations Created');
	trackCustomNameMutation('Create Multiple Allocations', input);

	const query = `mutation CreateMultipleAllocations($input: CreateMultipleAllocationsInput!) {
				createMultipleAllocations(input: $input) {
					project {
						id
						allocations(first: 100000) {
	   	 					edges {
	   	 						node {
	   	 							id
									monday
									tuesday
									wednesday
									thursday
									friday
									saturday
									sunday
									startYear
									startMonth
									startDay
									endYear
									endMonth
									endDay
									description
									person {
										id
									}
									project {
										id
									}
									isSoft									
	   	 						}
	   	 					}
	   	 				}
					}
					projectGroup {
						id
						allocations(first: 100000) {
	   	 					edges {
	   	 						node {
	   	 							id
									monday
									tuesday
									wednesday
									thursday
									friday
									saturday
									sunday
									startYear
									startMonth
									startDay
									endYear
									endMonth
									endDay
									description
									person {
										id
									}
									projectGroupId
									projectGroupColor
	   	 						}
	   	 					}
	   	 				}
					}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const createProject = (input, onSuccess = () => {}) => {
	tracking.trackEvent('Project Created', {
		_UseSprints: input.sprintTimeBox,
		_SprintLength: input.sprintLengthInDays,
		_StartDateSet: !!input.projectStartYear,
		_EndDateSet: !!input.projectEndYear,
		_ClientSet: !!input.clientId,
		_UseBaseline: input.useBaseline,
	});
	trackCustomNameMutation('Create Project', input);

	const query = `mutation CreateProject($input: CreateProjectInput!) {
				createProject(input: $input) {
					errors
   	 				project {
   	 					node {
	   	 					id
	   	 					name
	   	 					companyProjectId
	   	 					status
	   	 					projectColor
	   	 					useManualAllocations
	   	 					completion
	   	 					remaining
	   	 					projectStartDay
	   	 					projectStartMonth
	   	 					projectStartYear
	   	 					projectEndDay
	   	 					projectEndMonth
	   	 					projectEndYear
	   	 					estimationUnit
	   	 					minutesPerEstimationPoint
	   	 					isInProjectGroup
	   	 					projectGroupId
	   	 					forecast
	   	 					allocations(first: 1) {
	   	 						edges {
	   	 							node {
		   	 							id
		   	 						}
	   	 						}
	   	 					}
	   	 					currentProjectStatus {
	   	 						color
	   	 					}
							client {
								id
							}
	   	 					tasks(first: 10000) {
	   	 						edges {
	   	 							node {
	   	 								id
	   	 								companyTaskId
	   	 								approved
	   	 								assignedPersons {
	   	 									id
	   	 								}
	   	 								billable
	   	 								blocked
	   	 								bug
	   	 								deadlineDay
	   	 								deadlineMonth
	   	 								deadlineYear
	   	 								startDay
	   	 								startMonth
	   	 								startYear
	   	 								done
	   	 								estimateForecast
	   	 								highPriority
	   	 								taskLabels {
	   	 									id
	   	 									label {
	   	 										id
	   	 									}
	   	 								}
	   	 								role {
	   	 									id
	   	 								}
	   	 								phase {
	   	 									id
	   	 								}
	   	 								timeLeft
	   	 								name
	   	 								fullAccessToProject
	   	 							}
	   	 						}
	   	 					}
	   	 					projectLabels(first: 10000) {
	   	 						edges {
	   	 							node {
	   	 								label {
	   	 									id
	   	 								}
	   	 							}
	   	 						}
	   	 					}
	   	 					projectPersons {
	   	 						edges {
	   	 							node {
	   	 								id
	   	 								role {
	   	 									id
	   	 								}
	   	 								person {
	   	 									id
	   	 								}
	   	 								project {
	   	 									id
	   	 								}
	   	 							}
	   	 						}
	   	 					}
	   	 					phases {
	   	 						edges {
	   	 							node {
	   	 								id
	   	 								startDay
	   	 								startMonth
	   	 								startYear
	   	 								deadlineDay
	   	 								deadlineMonth
	   	 								deadlineYear
	   	 								name
	   	 							}
	   	 						}
	   	 					}
	   	 				}
   	 				}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const updateProject = (input, onSuccess = () => {}) => {
	const query = `mutation UpdateProject($input: UpdateProjectInput!) {
				updateProject(input: $input) {
   	 				project {
   	 					id
   	 					projectStartDay
   	 					projectStartMonth
   	 					projectStartYear
   	 					projectEndDay
   	 					projectEndMonth
   	 					projectEndYear
   	 					status
   	 					companyProjectId
   	 				}
				}
			}`;
	sendMutation(query, input, onSuccess);
};

export const duplicateProject = (input, onSuccess = () => {}) => {
	const trackingOptions = {
		_DuplicateSettings: input.duplicateSettings,
		_DuplicateTasks: input.duplicateTasks,
		_DuplicatePhases: input.duplicatePhases,
		_DuplicateProjectPersons: input.duplicateProjectPersons,
		_StartDateSet: !!input.projectStartYear,
		_EndDateSet: !!input.projectEndYear,
	};

	if (hasFeatureFlag('placeholders') && hasModule(MODULE_TYPES.PLACEHOLDERS)) {
		trackingOptions._DuplicatePlaceholders = input.duplicatePlaceholders;
	}

	tracking.trackEvent('Project Duplicated', trackingOptions);
	trackCustomNameMutation('Duplicate Project', input);

	const query = `mutation DuplicateProject($input: DuplicateProjectInput!) {
				duplicateProject(input: $input) {
   	 				project {
   	 					node {
	   	 					id
	   	 					name
	   	 					companyProjectId
	   	 					status
	   	 					projectColor
	   	 					useManualAllocations
	   	 					completion
	   	 					remaining
	   	 					projectStartDay
	   	 					projectStartMonth
	   	 					projectStartYear
	   	 					projectEndDay
	   	 					projectEndMonth
	   	 					projectEndYear
	   	 					estimationUnit
	   	 					minutesPerEstimationPoint
	   	 					isInProjectGroup
	   	 					projectGroupId
	   	 					forecast
	   	 					allocations(first: 1) {
	   	 						edges {
	   	 							node {
		   	 							id
		   	 						}
	   	 						}
	   	 					}
	   	 					currentProjectStatus {
	   	 						color
	   	 					}
							client {
								id
							}
	   	 					tasks(first: 10000) {
	   	 						edges {
	   	 							node {
	   	 								id
	   	 								companyTaskId
	   	 								approved
	   	 								assignedPersons {
	   	 									id
	   	 								}
	   	 								billable
	   	 								blocked
	   	 								bug
	   	 								deadlineDay
	   	 								deadlineMonth
	   	 								deadlineYear
	   	 								startFrom
	   	 								startDay
	   	 								startMonth
	   	 								startYear
	   	 								done
	   	 								estimateForecast
	   	 								highPriority
	   	 								taskLabels {
	   	 									id
	   	 									label {
	   	 										id
	   	 									}
	   	 								}
	   	 								role {
	   	 									id
	   	 								}
	   	 								phase {
	   	 									id
	   	 								}
	   	 								timeLeft
	   	 								name
	   	 								fullAccessToProject
	   	 								parentTaskId
										thisTaskDependsOn(first: 1000) {
											edges {
												node {
													id
													type
													completed
													thisDependsOnTask {
														id
													}
												}
											}
										}
	   	 							}
	   	 						}
	   	 					}
	   	 					projectLabels(first: 10000) {
	   	 						edges {
	   	 							node {
	   	 								label {
	   	 									id
	   	 								}
	   	 							}
	   	 						}
	   	 					}
	   	 					projectPersons {
	   	 						edges {
	   	 							node {
	   	 								id
	   	 								role {
	   	 									id
	   	 								}
	   	 								person {
	   	 									id
	   	 								}
	   	 								project {
	   	 									id
	   	 								}
	   	 							}
	   	 						}
	   	 					}
	   	 					phases {
	   	 						edges {
	   	 							node {
	   	 								id
	   	 								startDay
	   	 								startMonth
	   	 								startYear
	   	 								deadlineDay
	   	 								deadlineMonth
	   	 								deadlineYear
	   	 								name
	   	 							}
	   	 						}
	   	 					}
	   	 					placeholders {
	   	 						edges {
	   	 							node {
	   	 								id 
	   	 								projectGroupId
	   	 								project {
	   	 									id
	   	 								}
	   	 								name
	   	 								role {
	   	 									id
	   	 									name
	   	 								}
	   	 								skills {
	   	 									edges {
	   	 										node {
	   	 											id
	   	 											
	   	 										}	   	 									
	   	 									}
	   	 								}
	   	 								placeholderAllocations {
	   	 									edges {
	   	 										node {
	   	 											id
	   	 											placeholder {
															id
														}
	   	 											startDate
														endDate
														monday
														tuesday
														wednesday
														thursday
														friday
														saturday
														sunday
														description
	   	 										}
	   	 									}
	   	 								}
	   	 							}
	   	 						}
	   	 					}
	   	 				}
   	 				}
				}
			}`;

	sendMutation(query, input, onSuccess);
};
