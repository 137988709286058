/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type expenseRow_company$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type expensesPage_company$ref: FragmentReference;
declare export opaque type expensesPage_company$fragmentType: expensesPage_company$ref;
export type expensesPage_company = {|
  +id: string,
  +currency: ?string,
  +newXeroEnabled: ?boolean,
  +sageIntacctConnectionEnabled: ?boolean,
  +expenseCategories: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +disabled: ?boolean,
      |}
    |}>
  |},
  +$fragmentRefs: expenseRow_company$ref,
  +$refType: expensesPage_company$ref,
|};
export type expensesPage_company$data = expensesPage_company;
export type expensesPage_company$key = {
  +$data?: expensesPage_company$data,
  +$fragmentRefs: expensesPage_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "expensesPage_company",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "newXeroEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sageIntacctConnectionEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000
        }
      ],
      "concreteType": "ExpenseCategoryTypeConnection",
      "kind": "LinkedField",
      "name": "expenseCategories",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ExpenseCategoryTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExpenseCategory",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "disabled",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "expenseCategories(first:10000)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "expenseRow_company"
    }
  ],
  "type": "Company"
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e0f183f321cfc100f1822da6e2ab42f';

module.exports = node;
