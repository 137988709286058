import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {ChartWrapper} from '../money/work_and_expenses/Chart.styled';
import ForecastQueryRenderer from '../../../../../ForecastQueryRenderer';
import PlanActualTimeChart, {timeChartsQuery} from './charts/PlanActualTimeChart';
import InlineLoader from '../../../../../forecast-app/shared/components/inline-loader/inline_loader';
import {getProjectDatesOrDefault} from '../util/ChartsUtil';

const TimeChart = ({project, selectedAggregateLevel, eyeOptionMap}) => {
	const {
		projectFirstDateYear,
		projectFirstDateMonth,
		projectFirstDateDay,
		projectLastDateYear,
		projectLastDateMonth,
		projectLastDateDay,
	} = getProjectDatesOrDefault(project);
	return (
		<>
			<ChartWrapper>
				<ForecastQueryRenderer
					query={timeChartsQuery}
					variables={{
						projectId: project.id,
						startYear: projectFirstDateYear,
						startMonth: projectFirstDateMonth,
						startDay: projectFirstDateDay,
						endYear: projectLastDateYear,
						endMonth: projectLastDateMonth,
						endDay: projectLastDateDay,
					}}
					authorizeAccessRoute={'project-budget'}
					customLoader={() => <InlineLoader />}
					render={relayProps => {
						return (
							<PlanActualTimeChart
								key={'PlanActualTimeChart'}
								{...relayProps}
								aggregateLevel={selectedAggregateLevel}
								eyeOptionMap={eyeOptionMap}
							/>
						);
					}}
				/>
			</ChartWrapper>
		</>
	);
};

export default createFragmentContainer(TimeChart, {
	project: graphql`
		fragment TimeChart_project on ProjectType {
			id
			projectFirstDateYear
			projectFirstDateMonth
			projectFirstDateDay
			projectLastDateYear
			projectLastDateMonth
			projectLastDateDay
		}
	`,
});
