import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';

import UploadingOverlay from '../../../forecast-app/shared/components/uploading-overlay/uploading_overlay';
import {fetchData} from './standalone_utilization_modal_fetch';
import UtilizationModal from './utilization_modal';
import {getMomentFromCanvasTimelineDate} from '../canvas-timeline/canvas_timeline_util';

/**
 * Wrapper around UtilizationModal, based around a single project.
 *
 * Based on a projectId, it loads aggregated utilization data based on everything *but* the project itself.
 * That data is being given as input to the modal, as that is the varying part and will be added to it
 */
export const StandaloneUtilizationModal = props => {
	const {startDate, endDate, person} = props;
	const [data, setData] = useState({loading: true});

	const momentStartDate = getMomentFromCanvasTimelineDate(startDate);
	const momentEndDate = getMomentFromCanvasTimelineDate(endDate);

	useEffect(() => {
		fetchData(momentStartDate, momentEndDate, person.personId || person.id).then(data => {
			setData(
				Object.assign(data, {
					loading: false,
				})
			);
		});
	}, []);

	if (data.loading) {
		return <UploadingOverlay />;
	}

	if (!person.name && person.firstName) {
		person.name = `${person.firstName == null ? '' : person.firstName} ${person.lastName == null ? '' : person.lastName}`;
	}

	return (
		<UtilizationModal
			{...props}
			distributionMap={data.distributionMap}
			minutesAllocated={data.minutesAllocated}
			plannedTotalMinutesHard={data.minutesAllocatedHard}
			plannedTotalMinutesSoft={data.minutesAllocatedSoft}
			plannedTotalMinutesSoftWin={data.minutesAllocatedSoftWin}
		/>
	);
};

StandaloneUtilizationModal.propTypes = {
	startDate: PropTypes.number.isRequired,
	endDate: PropTypes.number.isRequired,
	project: PropTypes.object,
	actualPersonId: PropTypes.string.isRequired,
	closeModal: PropTypes.func.isRequired,
	person: PropTypes.object.isRequired,
	company: PropTypes.object.isRequired,
	projects: PropTypes.array.isRequired,
	projectGroups: PropTypes.array.isRequired,
	persons: PropTypes.array,
	projectPersons: PropTypes.array.isRequired,
	holidayCalendars: PropTypes.array.isRequired,
	holidayCalendarEntries: PropTypes.array.isRequired,
	idleTimes: PropTypes.array.isRequired,
	projectMinutesAllocated: PropTypes.number,
	minutesAvailable: PropTypes.number.isRequired,
	readOnly: PropTypes.bool.isRequired,
	phases: PropTypes.array,
	roles: PropTypes.array,
	teams: PropTypes.array,
	teamPersons: PropTypes.array,
};

StandaloneUtilizationModal.defaultProps = {
	readOnly: true,
};
