import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DropdownFilter from './dropdown_filter';

class Filter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: this.props.expandOnMount || false,
		};
	}

	toggleSearch() {
		if (this.state.expanded && this.props.onClose) {
			this.props.onClose();
		} else if (!this.state.expanded && this.props.onExpand) {
			this.props.onExpand();
		}
		this.setState({expanded: !this.state.expanded});
	}

	collapseSearch(e) {
		const newTarget = e.relatedTarget || e.explicitOriginalTarget || document.activeElement; // IE11
		if (
			newTarget &&
			(newTarget.className === 'action filter' ||
				newTarget.className === 'remove-icon' ||
				newTarget.className === 'input' ||
				newTarget.className === 'input-focused' ||
				newTarget.className === 'action-filter' ||
				newTarget.className.includes('select-wrapper') ||
				newTarget.className.includes('drop-down') ||
				(newTarget.firstChild &&
					(newTarget.firstChild.className === 'option' || newTarget.firstChild.className === 'option-multi')))
		)
			return;
		if (this.props.onClose) {
			this.props.onClose();
		}
		this.setState({expanded: false});
	}

	render() {
		const activeFilterCount = this.props.filters.filter(filter => {
			return filter.value.length > 0;
		}).length;

		return (
			<div onBlur={this.collapseSearch.bind(this)} tabIndex="0" className="action filter">
				<button
					onClick={this.toggleSearch.bind(this)}
					className={
						'forecast-icon-button ' +
						(this.state.expanded ? 'expanded' : 'collapsed') +
						(activeFilterCount !== 0 ? ' active' : '')
					}
				>
					{activeFilterCount !== 0 ? <div className="active-filter-count">{activeFilterCount}</div> : null}
				</button>
				<div
					className={
						(this.props.rightSide ? 'drop-down-right-side ' : 'drop-down ') +
						(this.state.expanded ? 'expanded' : 'collapsed') +
						(this.props.filters.length > 3
							? this.props.filters.length > 6 ||
							  (this.props.filters.length < 6 && this.props.filters.length !== 5)
								? ' long'
								: ' short'
							: '')
					}
					onClick={e => e.stopPropagation()}
				>
					{this.props.filters.map((filter, index) => (
						<DropdownFilter
							key={index}
							onValuesChange={filter.onChange}
							value={filter.value}
							options={filter.options}
							placeholder={filter.placeholder}
							label={filter.label}
							projectStatusFilter={filter.projectStatusFilter}
							disableRenderCalculations={this.props.disableRenderCalculations || false}
						/>
					))}
				</div>
			</div>
		);
	}
}

Filter.propTypes = {
	filters: PropTypes.arrayOf(
		PropTypes.shape({
			options: PropTypes.arrayOf(
				PropTypes.shape({
					label: PropTypes.any,
					value: PropTypes.any,
				})
			).isRequired,
			onChange: PropTypes.func.isRequired,
			placeholder: PropTypes.string.isRequired,
			value: PropTypes.array.isRequired,
			rightSide: PropTypes.bool,
		})
	).isRequired,
	disableRenderCalculations: PropTypes.bool,
};

export default Filter;
