import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import {KebabMenuIcon, ProjectIndicatorList, Table, Dropdown} from 'web-components';
import Util from '../../../forecast-app/shared/util/util';
import {hasFeatureFlag} from '../../../forecast-app/shared/util/FeatureUtil';
//import * as tracking from '../../../tracking';
import ForecastTooltip from '../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import UpdateInvoiceMutation from '../../../mutations/update_invoice_mutation';
import {ProjectNameTooltip} from '../../../styles/components/project_name_tooltip';
import {beautifyExternalStatusText, getPaymentStatusText} from './InvoicingUtil';
import {FlexRow, DeprecatedText as Text} from '@forecast-it/design-system';
import ExportInvoiceButton from '../../..//containers/project/budget/invoicing/ExportInvoiceButton';
import DeprecatedProjectIndicatorJS from '../../../forecast-app/shared/components/project-indicator/js/DeprecatedProjectIndicatorJS';
import {hasModule} from '../../../forecast-app/shared/util/ModuleUtil';
import {MODULE_TYPES} from '../../../constants';

export const InvoicesTable = ({
	theEyeOptions,
	data,
	totals,
	companyCurrency,
	handleOpenContextMenu,
	onEditInvoice,
	integrationSettings,
	hasIntegrations,
	isXeroUsed,
	isEconomicUsed,
	isQuickbooksUsed,
	isSageIntacctUsed,
	company,
	showExport,
}) => {
	const intl = useIntl();

	const headerData = {
		rows: [
			{
				...totals,
			},
		],
	};

	const tableData = {
		rows: data,
	};

	const boolEyeOptions = useMemo(() => {
		const bools = {};
		theEyeOptions.forEach(eyeOption => {
			bools[eyeOption.name] = eyeOption.checked;
			if (eyeOption.nestedOptions) {
				eyeOption.nestedOptions.forEach(nestedOption => {
					bools[nestedOption.name] = nestedOption.checked;
				});
			}
		});
		return bools;
	}, [theEyeOptions]);

	const getIntegrationsContent = (invoice, xeroTenantId) => {
		const buttons = [];
		let xeroShortCode;

		if (integrationSettings && integrationSettings.xeroTenantToShortCode) {
			xeroShortCode = integrationSettings?.xeroTenantToShortCode[xeroTenantId];
		}

		buttons.push(
			<span style={{marginLeft: '8px'}}>
				<ExportInvoiceButton company={company} invoice={invoice} xeroTenantToShortCode={xeroShortCode} />
			</span>
		);
		return buttons;
	};

	const changeStatus = invoiceId => selectedValue => {
		if (selectedValue && selectedValue.length === 1) {
			Util.CommitMutation(UpdateInvoiceMutation, {
				id: invoiceId,
				status: selectedValue[0],
			});
		}
	};

	const getActiveIntegrationName = (isXeroUsed, isEconomicUsed, isQuickbooksUsed, isSageIntacctUsed) => {
		if (isXeroUsed) {
			return 'Xero';
		} else if (isEconomicUsed) {
			return 'Economic';
		} else if (isQuickbooksUsed) {
			return 'Quickbooks';
		} else if (isSageIntacctUsed) {
			return 'Sage Intacct';
		}
	};

	return (
		<Table>
			<Table.Header>
				<Table.HeaderColumn usePadding align="left" visible width="SMALL">
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.id'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn flex={1} usePadding align="left" visible width="LARGE">
					<Table.HeaderColumn.Title>
						{intl.formatMessage({id: 'project_section.invoice_name'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align="left" visible={boolEyeOptions.client} width="LARGE">
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.client'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align="left" visible={boolEyeOptions.projects} width="LARGE">
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.projects'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align="left" visible={boolEyeOptions.status} width="MEDIUM">
					<Table.HeaderColumn.Title>
						{!hasIntegrations
							? intl.formatMessage({id: 'common.status'})
							: hasFeatureFlag('invoice_external_status')
							? hasModule(MODULE_TYPES.SAGE_INTACCT_RESTRICTED)
								? intl.formatMessage({id: 'invoicing.sage_psa_status'})
								: intl.formatMessage({id: 'invoicing.forecast_status'})
							: intl.formatMessage({id: 'common.status'})}
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				{hasIntegrations && hasFeatureFlag('invoice_external_status') ? (
					<Table.HeaderColumn usePadding align="left" visible={boolEyeOptions.status} width="MEDIUM">
						<Table.HeaderColumn.Title>
							{' '}
							{getActiveIntegrationName(isXeroUsed, isEconomicUsed, isQuickbooksUsed, isSageIntacctUsed)} Status
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				) : (
					<Table.HeaderColumn usePadding align="left" visible={boolEyeOptions.status} width="MEDIUM_SMALL">
						<Table.HeaderColumn.Title>
							{intl.formatMessage({id: 'invoicing.payment_status'})}
						</Table.HeaderColumn.Title>
					</Table.HeaderColumn>
				)}
				<Table.HeaderColumn usePadding align="left" visible={boolEyeOptions.reference} width="MEDIUM">
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.reference'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align="left" visible={boolEyeOptions.billFrom} width="MEDIUM">
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'filter_type.bill_from'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align="left" visible={boolEyeOptions.billTo} width="MEDIUM">
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'filter_type.bill_to'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.invoiceDate} width="MEDIUM">
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'invoicing.invoice_date'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.total_invoiced} width="MEDIUM">
					<Table.HeaderColumn.Title>
						<ForecastTooltip delay={[50, 100]} content={intl.formatMessage({id: 'invoicing.invoiced_tooltip'})}>
							{intl.formatMessage({id: 'common.total_invoiced'})}
						</ForecastTooltip>
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.paid} width="MEDIUM">
					<Table.HeaderColumn.Title>
						<ForecastTooltip delay={[50, 100]} content={intl.formatMessage({id: 'invoicing.paid_tooltip'})}>
							{intl.formatMessage({id: 'settings_subscription.paid'})}
						</ForecastTooltip>
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>

				<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.unpaid} width="MEDIUM">
					<Table.HeaderColumn.Title>
						<ForecastTooltip delay={[50, 100]} content={intl.formatMessage({id: 'invoicing.unpaid_tooltip'})}>
							{intl.formatMessage({id: 'settings_subscription.unpaid'})}
						</ForecastTooltip>
					</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align="right" visible={boolEyeOptions.dueDate} width="MEDIUM">
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.due_date'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn usePadding align={'left'} visible={boolEyeOptions.export} width={'SMALL'}>
					<Table.HeaderColumn.Title>{intl.formatMessage({id: 'common.export'})}</Table.HeaderColumn.Title>
				</Table.HeaderColumn>
				<Table.HeaderColumn
					usePadding
					align={'center'}
					visible={boolEyeOptions.integrationLink && showExport}
					width={'MEDIUM'}
				>
					{/* integration button */}
				</Table.HeaderColumn>
				<Table.HeaderColumn align={'center'} visible={true} width={'EXTRA_SMALL'}>
					{/* context  menu*/}
				</Table.HeaderColumn>
			</Table.Header>
			<Table.Rows data={headerData} canExpand={false} hasHeader>
				{({rowData, tableColumnsProps}) => {
					return (
						<Table.Row {...tableColumnsProps}>
							<Table.Column />
							<Table.Column>{intl.formatMessage({id: 'common.totals'})}</Table.Column>
							<Table.Column />
							<Table.Column />
							<Table.Column />
							<Table.Column />
							<Table.Column />
							<Table.Column />
							<Table.Column />
							<Table.Column />
							<Table.Column>
								<Table.Column.Cell>
									{Util.getFormattedNumberWithCurrency(
										Util.GetCurrencySymbol(companyCurrency),
										rowData.totalInvoiced,
										intl
									)}
								</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>
									{Util.getFormattedNumberWithCurrency(
										Util.GetCurrencySymbol(companyCurrency),
										rowData.totalPaid,
										intl
									)}
								</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>
									{Util.getFormattedNumberWithCurrency(
										Util.GetCurrencySymbol(companyCurrency),
										rowData.totalUnpaid,
										intl
									)}
								</Table.Column.Cell>
							</Table.Column>
							<Table.Column />
							<Table.Column />
							<Table.Column>{/* integration button */}</Table.Column>
							<Table.Column>{/* context  menu*/}</Table.Column>
						</Table.Row>
					);
				}}
			</Table.Rows>
			<Table.Rows data={tableData} canExpand={false}>
				{({rowData, tableColumnsProps}) => {
					return (
						<Table.Row {...tableColumnsProps}>
							<Table.Column>
								<Table.Column.Cell onClick={() => onEditInvoice(rowData.companyInvoiceId)}>
									{rowData.companyInvoiceId}
								</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell onClick={() => onEditInvoice(rowData.companyInvoiceId)}>
									{rowData.name}
								</Table.Column.Cell>
							</Table.Column>
							<Table.Column>{rowData?.client?.name}</Table.Column>
							<Table.Column>
								{rowData.projects.length === 1 && !!rowData.projects[0] ? (
									<FlexRow gap={'s'}>
										<ForecastTooltip placement={'bottom'} content={rowData.projects[0].name}>
											<DeprecatedProjectIndicatorJS project={rowData.projects[0]} />
										</ForecastTooltip>
										<Text noWrap variant="bold">
											{rowData.projects[0].name}
										</Text>
									</FlexRow>
								) : (
									<ProjectIndicatorList visibleCount={3}>
										{rowData.projects.map((project, index) => (
											<ForecastTooltip
												placement={'bottom'}
												content={<ProjectNameTooltip projects={rowData.projects} />}
											>
												<DeprecatedProjectIndicatorJS project={project} />
											</ForecastTooltip>
										))}
									</ProjectIndicatorList>
								)}
							</Table.Column>
							<Table.Column userpilot={'status-dropdown'}>
								<Dropdown
									size="large"
									disabled={rowData.isExported}
									usePortal
									onSelect={changeStatus(rowData.id)}
									dropdownAlignment={'center'}
									maxHeight={80}
									width={128}
									name={intl.formatMessage({id: 'common.status'})}
									selectedItems={
										!rowData.isExported
											? [rowData.status]
											: hasFeatureFlag('invoice_external_status')
											? 'EXPORTED'
											: [rowData.status]
									}
								>
									<Dropdown.SingleText value="DRAFT" searchString={intl.formatMessage({id: 'common.draft'})}>
										{intl.formatMessage({id: 'common.draft'})}
									</Dropdown.SingleText>
									<Dropdown.SingleText
										value="APPROVED"
										searchString={intl.formatMessage({id: 'common.approved'})}
									>
										{intl.formatMessage({id: 'common.approved'})}
									</Dropdown.SingleText>
									<Dropdown.SingleText value="SENT" searchString={intl.formatMessage({id: 'common.sent'})}>
										{intl.formatMessage({id: 'common.sent'})}
									</Dropdown.SingleText>
									{hasIntegrations && hasFeatureFlag('invoice_external_status') ? (
										<Dropdown.SingleText
											value="EXPORTED"
											searchString={intl.formatMessage({id: 'common.exported'})}
										>
											{intl.formatMessage({id: 'common.exported'})}
										</Dropdown.SingleText>
									) : (
										<></>
									)}
								</Dropdown>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>
									{hasIntegrations
										? rowData.isExported && hasFeatureFlag('invoice_external_status')
											? beautifyExternalStatusText(rowData.externalStatus)
											: ''
										: getPaymentStatusText(rowData.paymentStatus, intl)}
								</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>{rowData.invoiceReference}</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>{rowData.billTo?.billFrom?.name}</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>{rowData.billTo?.name}</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>{rowData.invoiceDate.format('D MMM YYYY')}</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>
									{Util.getFormattedNumberWithCurrency(
										Util.GetCurrencySymbol(rowData.currency),
										rowData.invoicedWithoutTax,
										intl
									)}
								</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>
									{Util.getFormattedNumberWithCurrency(
										Util.GetCurrencySymbol(rowData.currency),
										rowData.paidWithoutTax,
										intl
									)}
								</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>
									{Util.getFormattedNumberWithCurrency(
										Util.GetCurrencySymbol(rowData.currency),
										rowData.invoicedWithoutTax - rowData.paidWithoutTax,
										intl
									)}
								</Table.Column.Cell>
							</Table.Column>
							<Table.Column>
								<Table.Column.Cell>{rowData.dueDate.format('D MMM YYYY')}</Table.Column.Cell>
							</Table.Column>
							<Table.Column />
							<Table.Column>
								<div style={{display: 'flex', alignItems: 'center'}}>
									{getIntegrationsContent(rowData, rowData.xeroTenantId)}
								</div>
							</Table.Column>
							<Table.Column>
								<KebabMenuIcon
									onClick={e => handleOpenContextMenu(e, rowData.id, rowData.companyInvoiceId)}
									cy={'invoice-row-context-menu'}
									userpilot={tableColumnsProps.index === 0 ? 'context-menu-wrapper-invoices' : null}
								/>
							</Table.Column>
						</Table.Row>
					);
				}}
			</Table.Rows>
		</Table>
	);
};

InvoicesTable.propTypes = {
	integrationSettings: PropTypes.shape({
		xeroShortCode: PropTypes.string,
	}),
};
